import React from "react";

// COMPONENTS
import Password from "../../../individual/inputs/Password";
import { ErrorMessage } from "../../../individual/inputs/Inputs";

import PropTypes from 'prop-types';

/**
 * Insert two inputs to update the old password of the user
 * @component UpdatePassword
 * @tutorial updatePass
 * 
 * @param {function} error - error react-hook-form (yup)
 * @param {function} forwardRef - register react-hook-form (yup)
 */
function UpdatePassword({ errors, forwardRef }) {

  const showPasswords = (ids) => {
    ids.map((id) => {
      const input = document.getElementById(id);
      const inputType = document.getElementById(id).type;

      if (inputType == "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    });
  };

  return (
    <>
      <Password
        placeholderText="Escribe aqui"
        name="password1"
        id="password1"
        labelText="Nueva contraseña"
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors?.password1?.message} id="error_pass1" />

      <p className="py-2"></p>

      <Password
        placeholderText="Escribe aqui"
        name="password2"
        id="password2"
        labelText="Confirmar contraseña"
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors?.password2?.message} id="error_pass2" />

        <div className="mt-4 d-flex justify-content-end align-items-center">
        <input
        onClick={() => showPasswords(['password1','password2'])}
        type="checkbox"
        name="showPass"
        id ="showPass"
        value="Mostrar contraseña"
      />
        <label htmlFor="showPass">Mostrar contraseña</label>
        
        </div>
      
    </>
  );
}

UpdatePassword.propTypes = {
  /**
   * error react-hook-form (yup)
   */
  errors:PropTypes.func,

  /**
   * register react-hook-form (yup)
   */
  forwardRef:PropTypes.func
}

export default UpdatePassword;
