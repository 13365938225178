import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useMaterialReception from "customHooks/useMaterialReception";
import React, { createContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Grid from "./Grid";
import Header from "./Header";
import ui from "./styles.module.scss";
import useQueryParams from "customHooks/useQueryParams";

/**
 * @type {import("./types").ContextMaterialReception}
 */
export const ContextReception = createContext(undefined);

export default function Reception() {
  const { idOc } = useParams();

  const { idPosition } = useQueryParams();

  const hook = useMaterialReception(+idOc,+idPosition);

  return (
    <ContextReception.Provider value={hook}>
      <BreadCrumV2 path={hook.breadcrumb} />

      <div className={ui.container}>
        <Header />
        <Grid />
      </div>
    </ContextReception.Provider>
  );
}
