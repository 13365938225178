import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import Dialog from "structure/Dialog";
import { ContextContact } from "..";
import { useContext } from "react";
import { ContainerFormContact } from "pages/Directory/Contactos/FormContactStyles";
import { FormContact } from "pages/Directory/Contactos/FormContact";
import ButtonAddContact from "./ButtonAddContact";
import UenContact from "../UenContact";
import Icon from "components/individual/HeaderIcon";

export default function Add() {
  const hook = useContext(ContextContact);

  return (
    <>
      <ButtonV2 onClick={hook.dialogAdd.open}>
        <span>Agregar</span>
        <Icon icon="plus"/>
      </ButtonV2>

      <Dialog
        forwardRef={hook.dialogAdd.refDialog}
        title="Agregar contacto"
        closeCallback={hook.dialogAdd.close}
        footer={<ButtonAddContact />}
      >
        <ContainerFormContact>
          <FormContact
            idForm={hook.idFormAdd}
            onSubmit={hook.attemptCreateContact}
          >
            <FormContact.Name placeholder="Escribe aquí" />
            <FormContact.MiddleName placeholder="Escribe aquí" />
            <FormContact.Father placeholder="Escribe aquí" />
            <FormContact.Mother placeholder="Escribe aquí" />
            <FormContact.PhoneNumber />
            <FormContact.Cellphone />
            <FormContact.Email
              placeholder="Escribe aquí"
              onChange={hook.setHasValidEmail}
            />
            <FormContact.Position placeholder="Escribe aquí" />
            <div className="d-flex justify-content-around align-items-center">
              <FormContact.CollectionContact />
              <FormContact.ContactPayments />
            </div>
            <FormContact.Aniversary label="Aniversario" />
            <UenContact
              onChange={(items) => hook.updateUensContact(items)}
              uens={hook.uensContact}
              hasEmail={hook.hasEmail}
              displayBlockColumn={false}
            />
          </FormContact>
        </ContainerFormContact>
      </Dialog>
    </>
  );
}
