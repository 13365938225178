import useDialog from "customHooks/useDialog";
import { useEffect, useState } from "react";
import { getAccount, getMovementReport } from "helpers/Apis/Banks/index";
import {
  MONTHS,
  dateAtCeroHours,
  dateToDbFormat,
  getLowestDate,
  monthRange,
  parseDateToText,
} from "helpers/dates";
import { saveAs } from "file-saver";
import { getCards } from "helpers/Apis/Banks/index";
import { getBankMovementsExcel } from "helpers/Apis/Documents";

/**
 * @type {import("./types").StateBankAccountReportV2}
 */
const INITIAL_STATE = {
  isOpen: false,
  isLoading: true,
  account: null,
  accounts: [],
  minDate: dateAtCeroHours(monthRange().firstDay),
  from: dateAtCeroHours(monthRange().firstDay),
  to: dateAtCeroHours(new Date()),
  isDownloadingXlsx: false,
  isDownloadingPdf: false,
};

/**
 *
 * @param {number|null} [id=null] - Id of the bank account to operate
 * @returns {import("./types").ReturnUseBankAccountReportV2}
 */
export default function useMovementsReport(id) {
  const [state, setState] = useState(INITIAL_STATE);
  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      if (typeof id !== "number") return;

      const bankAccount = await getAccount(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        account: bankAccount,
      }));
    })();
  }, [state.isOpen]);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      if (typeof id === "number") return;

      const bankAccounts = await getCards();

      setState((current) => ({
        ...current,
        accounts: [
          { label: "-- SELECCIONA --", disabled: true, id: null },
          ...bankAccounts.map((bank) => ({
            ...bank,
            label: `${bank.bank.shortName} - ${bank.account}`,
          })),
        ],
        isLoading: false,
      }));
    })();
  }, [state.isOpen]);

  useEffect(() => {
    if (state.account === null) return;

    const lowestDate = getLowestDate(state.account.monthsClosed);

    setState((current) => ({
      ...current,
      minDate: new Date(`${lowestDate}:`),
    }));
  }, [state.account]);

  const handleModal = (isOpen) => {
    setState((current) => ({
      ...current,
      isOpen,
    }));

    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
      setState(INITIAL_STATE);
    }
  };

  const setBankAccount = (account) =>
    setState((current) => ({
      ...current,
      account,
    }));

  const handleDownloadPdf = async () => {
    setState((current) => ({
      ...current,
      isDownloadingPdf: true,
    }));

    const pdfReport = await getMovementReport(
      state.account.id,
      state.from,
      state.to
    );

    if (pdfReport instanceof Blob) {
      saveAs(
        pdfReport,
        `${state.account.bank.shortName} ${
          state.account.account
        } - Movimientos ${parseDateToText(state.from)} al ${parseDateToText(
          state.to
        )}.pdf`
      );
    }

    setState((current) => ({
      ...current,
      isDownloadingPdf: false,
    }));
  };

  const downloadExcelReport = async () => {
    setState((current) => ({
      ...current,
      isDownloadingXlsx: true,
    }));

    const excel = await getBankMovementsExcel(
      dateToDbFormat(state.from),
      dateToDbFormat(state.to),
      state.account.id
    );

    if (excel instanceof Blob) {
      saveAs(
        excel,
        `${state.account.bank.shortName} ${
          state.account.account
        } - Movimientos ${parseDateToText(state.to)} al ${parseDateToText(
          state.to
        )}.xlsx`
      );
    } else {
      handleModal(false);
    }

    setState((current) => ({
      ...current,
      isDownloadingXlsx: false,
    }));
  };

  /**
   * Update the range for the movement report
   * @param {Date} from - From date
   * @param {Date} to - To date
   * @returns {void}
   */
  const setRangeDate = (from,to) => setState(current=>({
    ...current,
    from,
    to
  }));

  return {
    ...state,
    dialog,
    handleModal,
    downloadExcelReport,
    handleDownloadPdf,
    setBankAccount,
    setRangeDate
  };
}
