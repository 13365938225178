import { CustomTutorial } from "components/general/CustomTutorial";
import useDialog from "customHooks/useDialog";
import { getPraxiaVersion } from "helpers/Apis/helpDesk";
import { useRef, useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Tutorial } from "structure/Tutorial";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";

const MySwal = withReactContent(Swal);

export default function useVersion(displayCookiesAlert = true) {
  const [version, setVersion] = useState(undefined);
  const idDom = useRef();
  const dialog = useDialog();
  const [isOpen,setIsOpen] = useState(false);

  const history = useHistory();

  const id = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      const apiVersion = await getPraxiaVersion();

      setVersion(apiVersion);
    })();
  }, []);

  const openVersionDetails = () => {
    const dom = document.getElementById(id.current);
    if (dom) dom.click();
  };

  useEffect(() => {
    if (version === undefined || displayCookiesAlert === false) return;

    const userVersion = window.localStorage.getItem("praxiaVersion");

    if (userVersion === null) {
      window.localStorage.setItem("praxiaVersion", version[0].name);
      return;
    }

    // User has the last version of the system
    if (userVersion === version[0].name) return;

    if(process.env.REACT_APP_FRONTEND!==window.location.href){
      history.push("/")
    }    

    MySwal.fire({
      title: <p>Favor de borrar las cookies</p>,
      allowEscapeKey:false,
      allowOutsideClick:false,   
      showCloseButton:false,   
      showConfirmButton:false,
      width: "70vw",
      html: (
        <>
          <div className="text-left">
            <p>
              Una nueva version del sistema ha sido liberada, porfavor, borra
              las cookies y refresca la página para hacer efectivos los cambios
              en la plataforma. <b>NO PODRAS INGRESAR A PRAXIA HASTA REALIZAR ESTE PROCESO</b>
            </p>

            <details open>
              <summary>¿Cómo borrar cookies?</summary>
              <Tutorial
                chrome={
                  <CustomTutorial
                    folder="images/cookies/chrome/"
                    labels={[
                      "Configuracion del navegador",
                      "Privacidad y seguridad",
                      "Datos de navegacion",
                    ]}
                  />
                }
                firefox={
                  <CustomTutorial
                    folder="images/cookies/firefox/"
                    labels={[
                      "Configuracion",
                      "Limpiar historial reciente",
                      "Borrar datos de exploracion",
                    ]}
                  />
                }
                edge={
                  <CustomTutorial
                    folder="images/cookies/edge/"
                    labels={[
                      "Configuracion",
                      "Datos de exploracion",
                      "Borrar datos de exploracion",
                    ]}
                  />
                }
              />
            </details>
            <hr />
            <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {version[0].body}
              </ReactMarkdown>
            </div>
          </div>
        </>
      ),
    });

  }, [version]);

  return {
    version,
    id,
    openVersionDetails,
    lastRelease: version === undefined ? undefined : version[0],
    idDom,
    isOpen,
    dialog
  };
}
