import React, { createContext, useState } from "react";
import { useEffect } from "react";

/**
 * Context for the toggles
 * @type {import("react").Context<import("./types").RemindersContext>}
 */
export const RemindersContext = createContext();
const { Provider } = RemindersContext;

/**
 * Render a list of toggles in order to indicate from where gonna receive the reminders of the system
 * @param {import("./types").RemindersProps} props - Props
 * @returns {JSX.Element}
 */
export default function Reminders({
  cellphone = false,
  whatsapp,
  sms,
  email,
  onChange = () => {},
  children,
}) {
  /**
   * State of the component
   * @type {import("./types").StateReminders,()=>void}
   */
  const [state, setState] = useState({
    cellphone,
    whatsapp,
    sms,
    email,
    numberOfOptionsActive: +whatsapp + +sms + +email,
  });

  /**
   * Update the flag in order to receive the flags
   * @param {boolean} whatsapp - Flag
   * @returns {void}
   */
  const updateWhatsapp = (whatsapp) =>
    setState({
      ...state,
      whatsapp,
    });

  /**
   * Update the flag in order to receive the flags
   * @param {boolean} email - Flag
   * @returns {void}
   */
  const updateEmail = (email) =>
    setState({
      ...state,
      email,
    });

  /**
   * Update the sms in order to receive the flags
   * @param {boolean} sms - Sms
   * @returns {void}
   */
  const updateSms = (sms) =>
    setState({
      ...state,
      sms,
    });

  useEffect(() => {
    setState({
      ...state,
      numberOfOptionsActive: +state.whatsapp + +state.sms + +state.email,
    });

    onChange({
      email: state.email,
      sms: state.sms,
      whatsapp: state.whatsapp,
    });
  }, [state.whatsapp, state.sms, state.email]);

  useEffect(() => {
    if (cellphone === false) {
      setState({
        ...state,
        sms: false,
        whatsapp: false,
        email: true,
      });
    }
  }, [cellphone]);

  return (
    <Provider
      value={{
        cellphone,
        whatsapp: state.whatsapp,
        sms: state.sms,
        email: state.email,
        numberOfOptionsActive: state.numberOfOptionsActive,
        updateWhatsapp,
        updateEmail,
        updateSms,
      }}
    >
      {children()}
    </Provider>
  );
}
