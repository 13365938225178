const MovementsTable = {
  idTable: "list-movements",
  idPagination: "pagination-list-movements",
  pages: 0,
  rows: [],
  actualPage: 0,
  headerConfig: [
    {
      text: "Folio",
      columnNameDB: "MovementID",
      idHeader: "movement-id",
      attributeToPrint: "Folio",
      sortable: true,
      css: "text-right",
      sortThis: true,
    },

    {
      text: "Fecha",
      columnNameDB: "MovementID",
      idHeader: "movement-date",
      attributeToPrint: "Fecha",
      sortable: true,
    },

    {
      text: "Referencia deposito",
      columnNameDB: "MovementID",
      idHeader: "movement-reference",
      attributeToPrint: "Referencia_deposito",
      sortable: true,
    },

    // { text: 'Razon social', columnNameDB: 'MovementID', idHeader: 'social-reason-customer', attributeToPrint: 'Razon_social', sortable: true },

    {
      text: "Concepto",
      columnNameDB: "MovementID",
      idHeader: "movement-concept",
      attributeToPrint: "Concepto",
      sortable: true,
    },

    /* { text: 'Método', columnNameDB: 'MovementID', idHeader: 'movement-method', attributeToPrint: 'Metodo', sortable: true }, */

    /* { text: 'Cheque', columnNameDB: 'checkNumber', idHeader: 'movement-check-number', attributeToPrint: 'Cheque', sortable: true,css:'text-right' }, */

    {
      text: "Ingreso",
      columnNameDB: "MovementID",
      idHeader: "movement-incoming",
      attributeToPrint: "Ingreso",
      sortable: true,
      css: "align-right",
    },

    {
      text: "Egreso",
      columnNameDB: "MovementID",
      idHeader: "movement-expense",
      attributeToPrint: "Egreso",
      sortable: true,
      css: "align-right",
    },

    {
      text: "Estatus",
      columnNameDB: "MovementID",
      idHeader: "movement-status",
      attributeToPrint: "status",
      sortable: true,
    },
  ],
  styleTable: "default",
  idRows: "Movimiento",
  sort: {
    sqlSort: true,
    sortASC: false,
  },
  attributesResponse: {
    pages: "pages",
    actualPage: "actualPage",
    data: "movements",
  },
};

export default MovementsTable;
