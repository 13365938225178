import CurrencySelectV2 from "components/general/Forms/Selects/CurrencySelectV2";
import React, { useContext } from "react";
import { ContextPareto } from "..";
import { Label } from "structure/Document";

export default function CustomCurrency() {
  const hook = useContext(ContextPareto);

  return (
    <div>
        <Label>Moneda</Label>
        <CurrencySelectV2
          criteriaToUse="code"
          displayAll={false}
          defaultValue={hook.currency}
          onChangeValue={(data) => hook.setCurrency(data)}
        />
    </div>
  );
}
