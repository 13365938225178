// @ts-check

import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { ContextIncomingComplement } from "..";

export default function ButtonDisassociate() {
  const hook = useContext(ContextIncomingComplement);

  
  if (!hook.canDisAssociateOrCancel || !hook.displayCheckboxs || hook.isLoading) return <></>;

  if (hook.isWaitingServerResponse)
    return (
      <Spinner hidden={false} text="Un momento" idSpinner="complementAction" />
    );

  return (
    <ButtonV2
      onClick={async () => await hook.attemptCancelIncoming()}
      theme="danger"
    >
      {hook.textReverse}
    </ButtonV2>
  );
}
