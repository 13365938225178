import { add } from "date-fns";
import { getMonthRanges } from "helpers/dates";
import { ACTIONS } from "./actions";

/**
 * @type {import("./types").StateUseConcilation}
 */
export const INITIAL_STATE = {
  operationStatus:{},
  originalStatus:{},
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  maxDate: new Date(),
  minDate: new Date(),
  isLoading: true,
  movements: [],
  toAccount: {},
  toDisaccount: {},
  pagination: {
    pages: 0,
    currentPage: 1,
  },
  toConciliate: {},
  toDesconciliate: {},
  bankAccount: null,
  dateToConciliate: new Date(),
  isUpdating: false,
  status: null,
  from: null,
  to: null,
  type: null,
  search: "",
  rowsPerPage: 100,
  isDownloadingExcel: false,
  accountingState:null,
  gridChanged:false
};

/**
 *
 * @param {import("./types").StateUseConcilation} state - State
 * @param {import("./types").DISPATCH} action - Actions
 * @returns {import("./types").StateUseConcilation}
 */
export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_UPDATING:
      return {
        ...state,
        isUpdating: action.payload,
      };

    case ACTIONS.SET_DOWNLOADING_EXCEL:
      return {
        ...state,
        isDownloadingExcel: action.payload,
      };


    case ACTIONS.SET_GRID_CHANGED:
      return {
        ...state,
        gridChanged:action.payload
      }

    case ACTIONS.SET_ROW_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case ACTIONS.TOGGLE_CONCILATION:{

      const newStatus = state.operationStatus[action.payload].conciliate === 3 ? 2 : 3;

      return {
        ...state,
        operationStatus:{
          ...state.operationStatus,
          [action.payload]:{
            ...state.operationStatus[action.payload],
            conciliate:newStatus
          }
        }
      }
    }

    case ACTIONS.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case ACTIONS.SET_TO:
      return {
        ...state,
        to: action.payload,
      };

      case ACTIONS.SET_FROM:
      return {
        ...state,
        from: action.payload,
      };

    case ACTIONS.SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };

    case ACTIONS.SET_MONTH:
      return {
        ...state,
        month: action.payload,
      };

    case ACTIONS.SET_BANK_ACCOUNT_CONCILATION:
      const monthsClosed = action.payload.monthsClosed.map((date) =>
        new Date(`${date}:`).getTime()
      );
      const lastDateClosed = new Date(Math.max(...monthsClosed));

      const nextMonthToClose = add(lastDateClosed, {
        days: 1,
      });

      const lastDate = new Date(
        nextMonthToClose.getFullYear(),
        nextMonthToClose.getMonth() + 1,
        0
      );

      return {
        ...state,
        bankAccount: action.payload,
        dateToConciliate: nextMonthToClose,
        minDate: nextMonthToClose,
        maxDate: lastDate,
        to: lastDate,
        month: nextMonthToClose.getMonth() + 1,
        year: nextMonthToClose.getFullYear(),
      };

    case ACTIONS.EMPTY_SEARCH:
      return {
        ...state,
        toConciliate: {},
        toDesconciliate: {},
        toAccount:{},
        toDisaccount:{},  
        pagination: {
          pages: 0,
          currentPage: 1,
        },
      };

    case ACTIONS.SET_YEAR:
      const months = getMonthRanges(
        state.minDate,
        new Date(`${action.payload.year}-${state.month}-1`),
        state.maxDate
      );

      const lastMonthAvailable = months.slice(-1);

      return {
        ...state,
        year: action.payload.year,
        month: lastMonthAvailable[0].value,
        // month: 1,
      };

    case ACTIONS.SET_DATA_FETCHED:

      const indexedStatus = action.payload.movimientos.reduce((indexed,mov)=>({
        ...indexed,
        [mov.id]:{
          conciliate:mov.status.id,
          accounting:mov.posted
        }
      }),{});

      return {
        ...state,
        isLoading: false,
        movements: action.payload.movimientos,
        operationStatus:indexedStatus,
        originalStatus:indexedStatus,
        pagination: {
          pages: action.payload.pages.pages,
          currentPage: action.payload.pages.actualPage,
        },
      };

    case ACTIONS.TOGGLE_ACCOUNTING:
      return {
        ...state,
        operationStatus:{
          ...state.operationStatus,
          [action.payload]:{
            ...state.operationStatus[action.payload],
            accounting:!state.operationStatus[action.payload].accounting
          }
        }
      }

    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ACTIONS.HANDLE_CLICK_CONCILATION:
      if (
        action.payload.movement.status.id === 4 ||
        action.payload.movement.status.id === 5
      )
        return state;

      let toConciliate = { ...state.toConciliate };
      let toDesconciliate = { ...state.toDesconciliate };

      // Esta asociado , ni tampoco marcado, se quiere quitar de la "cola"
      if (
        action.payload.movement.status.id === 2 &&
        !action.payload.isChecked
      ) {
        delete toConciliate[action.payload.movement.id];

        return {
          ...state,
          toConciliate,
        };
      }

      // Esta asociado, marcado, se quiere agregar a la cola
      if (action.payload.movement.status.id === 2 && action.payload.isChecked) {
        toConciliate = {
          ...toConciliate,
          [action.payload.movement.id]: action.payload.movement,
        };

        return {
          ...state,
          toConciliate,
        };
      }

      // Esta conciliado y marcado, se quiere des-conciliar (regresar a asociado)
      if (action.payload.movement.status.id === 3 && action.payload.isChecked) {
        toDesconciliate = {
          ...toDesconciliate,
          [action.payload.movement.id]: action.payload.movement,
        };
        return {
          ...state,
          toDesconciliate,
        };
      }

      // Esta conciliado y no marcado, se arrepienten de querer des-asociar
      if (
        action.payload.movement.status.id === 3 &&
        !action.payload.isChecked
      ) {
        delete toDesconciliate[action.payload.movement.id];
        return {
          ...state,
          toDesconciliate,
        };
      }

      return state;

    case ACTIONS.SET_ACCOUNTING_ITEM:


      const item = state.movements.find((item) => item.id === action.payload);

      // Item is not accounted yet, must add on the item requested to account
      if (!item.posted) {

        const isOnList = Object.keys(state.toAccount).includes(`${item.id}`);

        // Item no on list yet, meaning that must be accounted once update de data
        if(!isOnList){
          return {
            ...state,
            toAccount: {
              ...state.toAccount,
              [item.id]: item,
            },
          };
        }

        // Item already on list, quit it (toggle)
        let unref = {...state.toAccount}
        delete unref[item.id];

        return {
          ...state,
          toAccount:unref
        }
      }

      // Item already accounted, must reverse the action

      let unref = { ...state.toDisaccount };

      const isOnList = Object.keys(state.toDisaccount).includes(`${item.id}`);

      // Item not on list, append
      if(!isOnList){
        return {
          ...state,
          toDisaccount:{
            ...state.toDisaccount,
            [item.id]:item
          }
        }
      }

      // Item on list, disappend to cancel operation
      delete unref[item.id];
      return {
        ...state,
        toDisaccount: unref,
      };

    case ACTIONS.SET_MIN_DATE:
      return {
        ...state,
        minDate: action.payload,
      };

    case ACTIONS.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case ACTIONS.SET_STATUS_ACCOUNTING:
      return {
        ...state,
        accountingState:action.payload
      }

    default:
      return state;
  }
}
