import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import Dialog from "structure/Dialog";
import {
  BankAccount,
  SocialReason,
  BankAccountCuenta,
  BankCLABE,
  BankCurrency,
  BankDate,
  BankAmount,
  NextCheck,
  NextTransfer,
  BankCard,
  Description,
  SubmitAddBankAccount,
  Comment,
  ViewCLABE,
  ViewComment,
  ViewDescription,
} from "structure/BankAccount";
import scss from "structure/AddBankAccount/styles.module.scss";

/**
 * Render an ABC modal for the bank accounts
 * @param {import("./types").PropsAbcBank} props - Props
 * @returns {JSX.Element} Modal to perform update or add bank account
 */
export default function AbcBank({
  id = null,
  children = <></>,
  forwardRef = undefined,
  isForMoreInformation = false,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  /**
   * Handle open the modal
   * @param {boolean} isOpen - **true** to open and **false** to close
   */
  const handleModal = (isOpen) => {
    setIsOpen(isOpen);

    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  };

  // To avoid create the context more levels above (lazy)
  // better click a hidden button to refetch the cards
  // Check the file: client\src\pages\BanksV2\index.js
  const refetchBankCards = () => {
    handleModal(false);

    const idButton = "triggerRefetchAccounts";
    const domButton = document.getElementById(idButton);

    if (domButton) domButton.click();
  };

  return (
    <>
      <div ref={forwardRef} onClick={() => handleModal(true)}>
        {children}
      </div>

      <BankAccount id={id} onSuccess={refetchBankCards}>
        {() => (
          <Dialog
            closeCallback={() => handleModal(false)}
            title={
              isForMoreInformation
                ? "Más información"
                : typeof id === "number"
                ? "Editar cuenta de banco"
                : "Agregar cuenta de banco"
            }
            forwardRef={dialog.refDialog}
            footer={
              isForMoreInformation ? null : (
                <div className="d-flex justify-content-end align-items-center">
                  <SubmitAddBankAccount />
                </div>
              )
            }
          >
            {isOpen ? (
              <div className={scss.PositionForm}>
                <div className={scss.LefttSideModal}>
                  <SocialReason />
                  <BankAccountCuenta />
                  {isForMoreInformation ? <ViewCLABE /> : <BankCLABE />}

                  <BankCurrency />
                  <BankDate />
                  <BankAmount />
                  <NextTransfer />
                  <NextCheck />
                </div>
                <div className={scss.RightSideModal}>
                  <BankCard />
                  {isForMoreInformation ? <ViewDescription /> : <Description />}
                  {isForMoreInformation ? <ViewComment /> : <Comment />}
                </div>
              </div>
            ) : null}
          </Dialog>
        )}
      </BankAccount>
    </>
  );
}
