import { yupResolver } from "@hookform/resolvers/yup";
import { Iva } from "components/individual/IvasAvailable";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

/**
 * @type {import("./types").StateFormDocumentItems}
 */
const INITIAL_STATE = {
  isLoading: false,
  isNewItem: false,
  idCatalogueItem: undefined,
  cost: 0,
  sell: 0,
  iva: 0,
  discountCustomer: 0,
  discountSupplier: 0,
  costOperation: undefined,
  sellOperation: undefined,
  quantity: 0,
  ivaExempt: false,
};

/**
 * Handle add items for any document
 * @param {number|null} [id=null] - Id of the item to edit, in case exists on database
 * @param {import("../types").DocumentItemForm} [initialData=undefined] - Initial data to fill the form, in case of edit when data is stored on memory
 * @returns {import("./types").ReturnUseFormDocumentItems}
 */
export default function useFormDocumentItems(
  id = null,
  initialData = undefined
) {
  const [state, setState] = useState(INITIAL_STATE);

  const item = yup.object().shape({
    
    idCatalogue: yup
      .number()
      .nullable(true)
      .default(null)
      .transform((value, input) => {
        if (input === "") return null;

        return +input;
      }),
    currency: yup
      .string()
      .required("Campo requerido")
      .typeError("Selecciona una moneda válida"),
    description: yup
      .string()
      .matches(/^[^|]{1,1000}$/, "No se permiten caracteres especiales como |")
      .min(1, "Mínimo 1 caracter")
      .max(1000, "Máximo 1000 caracteres")
      .required("Campo requerido")
      .typeError("Ingresa texto válido"),
    sku: yup
      .string()
      .required("Campo requerido")
      .min(1, "Mínimo 1 caracter")
      .max(256, "Máximo 256 caracteres")
      .typeError("Ingresa texto válido"),
    quantity: yup
      .number()
      .min(1, "Mínimo 1 unidad")
      .required("Campo requerido")
      .typeError("Debe ser un número"),
    cost: yup
      .number()
      .moreThan(0, "Debe ser mayor a $0.00")
      .required("Campo requerido")
      .typeError("Debe ser un número"),
    sell: yup
      .number()
      .moreThan(0, "Debe ser mayor a $0.00")
      .required("Campo requerido")
      .min(yup.ref("cost"), "La venta debe ser mayor o igual al costo")
      .typeError("Debe ser un número"),
    discountCost: yup
      .number()
      .min(0, "Mínimo 0%")
      .max(100, "Máximo 100%")
      .typeError("Debe ser un número")
      .required("Obligatorio"),
    discountSell: yup
      .number()
      .min(0, "Mínimo 0%")
      .max(100, "Máximo 100%")
      .typeError("Debe ser un número")
      .required("Obligatorio"),
    iva: yup
      .number()
      .oneOf([0, 8, 16], "Ivas válidos: 0%, 8%, 16%")
      .required("Campo requerido")
      .typeError("Selecciona un IVA válido"),
    uen: yup
      .number()
      .required("Campo requerido")
      .typeError("Selecciona una opción válida")
      .min(1, "La UEN no es válida"),
    satCode: yup
      .string()
      .required("Campo requerido")
      .typeError("Selecciona un código SAT válido"),
    satUm: yup
      .string()
      .required("Campo requerido")
      .typeError("Selecciona una unidad de medida SAT válida"),
    ivaExempt: yup
      .boolean()
      .required("Campo requerido")
      .typeError("Selecciona una opción válida")
      .nullable()
      .default(false)
      .when("iva", (iva, schema) => {
        if (iva === 0)
          return yup
            .boolean()
            .required("Campo requerido")
            .typeError("Selecciona una opción válida");

        return schema;
      }),
  });

  /**
   * @type {import("./types").FormTyped}
   */
  const hook = useForm({
    resolver: yupResolver(item),
    criteriaMode: "all",
    mode: "all",
    shouldFocusError: true,
    defaultValues: {
      discountCost: 0,
      discountSell: 0,
      idCatalogue: null,
      sku: null,
      cost: null,
      sell: null,
      currency: initialData?.currency || null,
      quantity: null,
      ivaExempt: initialData?.ivaExempt || false,
      iva: initialData?.iva || null,
      // ivaExempt: false,
      // iva: 0,
      description: null,
    },
  });

  useEffect(()=>{
    if(initialData===undefined||initialData===null) return;

    hook.setValue('cost',initialData.cost);
    hook.setValue('description',initialData.description);
    hook.setValue('discountCost',initialData.discountCost);
    hook.setValue('discountSell',initialData.discountSell);
    hook.setValue('idCatalogue',initialData.idCatalogue);

    hook.setValue('quantity',initialData.quantity);
    hook.setValue('satCode',`${initialData.satCode} - ${initialData.satCodeDescription}`);
    hook.setValue('satUm',`${initialData.satUm} - ${initialData.satUmDescription}`);
    hook.setValue('sell',initialData.sell);
    hook.setValue('sku',initialData.sku);
    hook.setValue('uen',initialData.uen);

    setState(current=>({
      ...current,
      cost:initialData.cost,
      sell:initialData.sell,
      iva:initialData.iva,
      discountCustomer:initialData.discountSell,
      discountSupplier:initialData.discountCost,
      quantity:initialData.quantity,
    }));

    hook.setValue('iva',initialData.iva);
    hook.setValue('ivaExempt',initialData.ivaExempt);
    hook.setValue('currency',initialData.currency);


  },[initialData]);

  useEffect(() => {
    if (state.cost === 0 || state.sell === 0) {
      setState((current) => ({
        ...current,
        costOperation: undefined,
        sellOperation: undefined,
      }));
      return;
    }

    const quantity = hook.getValues("quantity") || 0;

    const costOperation =
      quantity * (state.cost - (state.cost * state.discountSupplier) / 100);
    const sellOperation =
      quantity * (state.sell - (state.sell * state.discountCustomer) / 100);

    setState((current) => ({
      ...current,
      costOperation,
      sellOperation,
    }));
  }, [
    state.cost,
    state.sell,
    state.iva,
    state.discountCustomer,
    state.discountSupplier,
    state.quantity,
  ]);

  /**
   * Set the id for the item selected on the catalogue
   * @param {number|undefined} id
   */
  const setIdCatalogueItem = (id) =>
    setState((current) => ({
      ...current,
      idCatalogueItem: id,
    }));

  const setCost = (cost) =>
    setState((current) => ({
      ...current,
      cost,
    }));

  const setSell = (sell) =>
    setState((current) => ({
      ...current,
      sell,
    }));

  const setIva = (iva) =>
    setState((current) => ({
      ...current,
      iva,
    }));

  const setDiscountCustomer = (discountCustomer) =>
    setState((current) => ({
      ...current,
      discountCustomer,
    }));

  const setDiscountSupplier = (discountSupplier) =>
    setState((current) => ({
      ...current,
      discountSupplier,
    }));

  const setQuantity = (quantity) =>
    setState((current) => ({
      ...current,
      quantity,
    }));

  return {
    form: hook,
    ...state,
    setIdCatalogueItem,
    setCost,
    setSell,
    setIva,
    setDiscountCustomer,
    setDiscountSupplier,
    setQuantity,
  };
}
