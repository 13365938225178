import React from "react";
import {  FooterInfoTex } from "../Footer/Containers";

/**
 * Fotter text component
 * @param {import("../../../../../../types/winQuote").FooterTextI} { label,
  text,info}
 * @returns {import("react").JSXElementConstructor}
 */
export const FooterLabelComponent = ({ label }) => {
  return (
    <FooterInfoTex>
      <p className="m-0 mr-2 text-center">
        <strong>{label}</strong>
      </p>
    </FooterInfoTex>
  );
};
