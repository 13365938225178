import React, { useContext } from "react";
import ComboDocument from "./ComboDocument";
import ui from "./styles.module.scss";
import Dates from "./Dates";
import ComboStatus from "./ComboStatus";
import NoDoc from "./NoDoc";
import { ContextDocuments } from "..";

export default function Filter() {
  const hook = useContext(ContextDocuments);

  return (
    <form
      className={ui.filter}
      id={hook.idForm}
      onSubmit={(e) => {
        e.preventDefault();
        hook.searchData();
      }}
    >
      <ComboDocument />
      <ComboStatus />
      <Dates />
      <NoDoc />
    </form>
  );
}
