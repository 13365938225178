import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextCancelCxp } from "..";

export const CancelCxps = () => {
  const {
    isCheckedParenToggle,
    isPerformingQuery,
    isGonnaCancel,
    attemtpDisassociation,
  } = useContext(ContextCancelCxp);

  const spinnerMessage = isGonnaCancel
    ? "Cancelando movimiento..."
    : "Des-asociando...";
  const buttonMessage = isGonnaCancel ? "Cancelar egreso" : "Des-asociar";

  return isPerformingQuery ? (
    <Spinner hidden={false} text={spinnerMessage} />
  ) : !isCheckedParenToggle ? null : (
    <ButtonV2 onClick={attemtpDisassociation} theme="danger">
      Des-asociar
    </ButtonV2>
  );
};
