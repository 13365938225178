import React from "react";
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Label } from "structure/Document";
import { ConcilationContext } from "..";
import { useContext } from "react";
registerLocale("es", es);

export default function RangeDate() {
  const hook = useContext(ConcilationContext);

  return (
    <>
      <div>
        <Label>Desde</Label>
        <ReactDatePicker
          selected={hook.from}
          onChange={hook.setFrom}
          isClearable={true}
          maxDate={hook.to}
          dateFormat={"dd/MMM/yy"}
          locale="es"
          placeholderText="Selecciona una fecha"
        />
      </div>

      <div>
        <Label>Hasta</Label>
        <ReactDatePicker
          selected={hook.to}
          onChange={hook.setTo}
          isClearable={true}
          maxDate={hook.maxDate}
          dateFormat={"dd/MMM/yy"}
          locale="es"
          placeholderText="Selecciona una fecha"
        />
      </div>
    </>
  );
}
