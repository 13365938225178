import React from "react";
import WinQuoteContext from "../../ContextWinQuote";
import { useContext } from "react";
import { ItemConteniner } from "../Containers";
import { ItemsBodySection } from "../ItemsBodySection";
import { ItemsFooterSection } from "../ItemsFooterSection";

export const CostItems = () => {
  const { documents, getNumbers, tc,customer } = useContext(WinQuoteContext);
  const isForeignSupplier =customer?.provider?.rfc === "XEXX010101000"; 
  return (
    <>
      {documents.odc.documentItems?.map((item, index) => (
        <ItemConteniner>
          <ItemsBodySection
            sku={item.sku}
            description={item.description}
            quantity={item.quantity}
            unitImport={getNumbers(item).unitary}
            totalImport={getNumbers(item).subTotal}
            currency={item.catalogue.currency.code}
          />
          <ItemsFooterSection
            discount={getNumbers(item).discount}
            ivaNumber={isForeignSupplier?0:getNumbers(item).iva}
            ivaPercentage={isForeignSupplier?'0%':getNumbers(item).ivaPercentage}
            totalImport={getNumbers(item).subTotal}
            unitImport={getNumbers(item).unitary}
            tc={tc.number}
            index={index}
          />
        </ItemConteniner>
      ))}
    </>
  );
};
