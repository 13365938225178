import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "..";

export default function SearchSku() {
  const hook = useContext(ContextCatalogueAdmin);

  return (
    <div>
      <Label>SKU</Label>
      <Search
        placeholder="Escribe aquí"
        onChange={hook.setSku}
        onDelete={hook.setSku}
        hideSearchIcon={true}
        onSearch={() => hook.attemptSearch()}
      />
    </div>
  );
}
