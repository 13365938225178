import Money from "components/general/Forms/Money";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCashFlow } from "..";

export default function TcCashFlow() {
  const hook = useContext(ContextCashFlow);

  if (hook.cashFlow.currency !== "Todos") return <></>;

  return (
    <div>
      <Label required={hook.cashFlow.tc <= 0}>TC</Label>
      <Money
        placeholder="Escribe aquí"
        value={hook.cashFlow.tc}
        maxDecimals={4}
        onChange={hook.updateTc}
      />
    </div>
  );
}
