import { useRef } from "react";

/**
 * Hook to handle a dialog html element
 * @returns {import("./types").ReturnUseDialog}
 */
export default function useDialog() {
  /**
   * @type {import("react").MutableRefObject<React.DetailedHTMLProps<React.DialogHTMLAttributes<HTMLDialogElement>, HTMLDialogElement>>}
   */
  const refDialog = useRef();

  const open = () => {
    try{
      refDialog.current.showModal();
    }catch(e){
      
    }
  };

  const close = () => {
    try{
      refDialog.current.close();
    }catch(e){
      
    }
  };

  return {
    refDialog,
    open,
    close,
  };
}
