import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useInnerNavigation(listeners = []) {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1); // Remove the '#' from the hash

      const element = document.getElementById(elementId);

      if (element !== undefined && element !== null) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 500);
      }
    }
  }, [location, listeners]);
}
