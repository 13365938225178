import React, { useContext } from "react";
import { ContextPareto } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function ExcelButton() {
  const hook = useContext(ContextPareto);

  if (hook.isDownloadingExcel)
    return <Spinner text={"Descargando"} hidden={false} />;

  return (
    <ButtonV2
      onClick={async () => await hook.attemptDownloadExcel()}
      type="button"
    >
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
