import LoginContext from "../context/Login/LoginContext";
import { useContext, useEffect , useState } from 'react';
import { AddComment, GetFileComments } from '../helpers/Apis/associateFiles';
import {v4 as uuid} from 'uuid';
import { Actions  } from '../pages/Directory/Documents/AssociateFiles/Actions';
import ReactDOM from 'react-dom';

function useAssociatedFiles(){

    const { userInfo , userId } = useContext(LoginContext);

    function AddActionsAssociateFile(files){
        
        const fullName = document.getElementById('profile-fullName').textContent;

        setTimeout(()=>{
          files.map((file) => {
            const id = uuid();
      
            const lastElementContainer = document.getElementById(
              `list-files-${file.id}`
            ).lastElementChild;
            lastElementContainer.setAttribute("id", id);
            lastElementContainer.setAttribute("class", "actions");
      
            const element = document.getElementById(id);
            ReactDOM.render(
                <Actions 
                    file = {file}
                    fullName = {fullName}
                />, element);
          });
        },10);
      } 

    /**
     * Request the next following comments of the file
     * 
     * @param {number|string} sinceRegister - Since which register start bringing the new comments
     * @param {number} idFile - Id of the file to fetch the next comments
     * @returns {CommentFile[]|[]} - Comments founded
     */
    async function fetchNextComments(sinceRegister,idFile){
        try {
            
            const comments = await GetFileComments(idFile,sinceRegister);
            
            return comments;

        } catch (error) {
            return [];
        }
    }

    /**
     * Add a new comment to the file
     * @param {object} data - Information of react-hook-form
     * @param {number} idFile - Id of the file
     * @returns {CommentFile} Comment added to the database
     */
    async function addNewComment(data,idFile){
        data = {
            ...data,
            executive:document.getElementById('profile-fullName').textContent,
            idExecutive:0,
            idFile
        }
        
        const comment = await AddComment(data);

        return comment;
    }

    /**
     * Blur with blue text the files that has at least, one comment
     * 
     * @param {FileInfo[]} files - Files fetched
     */
    function notifyFilesWithComments(files){
        setTimeout(()=>{
            files.forEach(file=>{
                const domRow = document.getElementById(`list-files-${file.id}`);

                const dateLoad = domRow.firstChild;

                if(file.noComments>0){
                    dateLoad.classList.add('text-primary');
                    dateLoad.classList.add('font-weight-bold');
                }
            });
        },[100]);
    }

    function shortcutOpenComments(files,state = null){
        setTimeout(()=>{
            files.forEach(file=>{
                const domRow = document.getElementById(`list-files-${file.id}`);

                const dateLoad = domRow.childNodes[0];

                dateLoad.addEventListener('click',function openModal(){
                    setTimeout(()=>{
                        const buttonModal = document.getElementById('openModal');

                        if(buttonModal===null) openModal();

                        buttonModal.click();
                    },[250])
                });
            });
        },[1000]);

        // const OpenComments = () => useEffect(()=>{
        //     document.getElementById('openModal').click();
        // },[state]);
    }

    /**
     * When a new comment it's added, mark the row with comments
     * 
     * @param {number} idFile - Id of the file
     */
    function blurNewComments(idFile){
        const row = document.getElementById(`list-files-${idFile}`);

        const dateLoad = row.firstChild;

        dateLoad.classList.add('text-primary');
        dateLoad.classList.add('font-weight-bold');
    }

    return {
        addNewComment,
        notifyFilesWithComments,
        fetchNextComments,
        blurNewComments,
        shortcutOpenComments,
        AddActionsAssociateFile
    }
}

export default useAssociatedFiles;