import React, { useContext } from "react";
import { ContextCreateEgress } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import Icon from "components/individual/HeaderIcon";

export default function AttemptQuery() {
  const hook = useContext(ContextCreateEgress);

  if (hook.isWaitingServer)
    return (
      <Spinner hidden={false} text="Agregando" idSpinner="egress-spinner" />
    );

  if (!hook.canPerformQuery) return <></>;

  return (
    <ButtonV2 onClick={hook.attemptCreateEgress}>
      <span>Agregar</span>
      <Icon icon="plus" />
    </ButtonV2>
  );
}
