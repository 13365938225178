import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextAddIncoming } from "..";
import CustomerCombo from "../CustomerCombo";

export default function CustomerComboSelect() {
  const contextAdd = useContext(ContextAddIncoming);

  if (contextAdd.state.beneficiary.description.length >=1 && typeof(contextAdd.state.customer)!=="number")
    return (
      <div>
        <Label>Cliente</Label>
        <p>NA</p>
      </div>
    );

  if (contextAdd.state.movement.info !== null) {
    if (contextAdd.state.movement.info.customer.id !== null)
      return (
        <div>
          <Label>Cliente</Label>
          <p>{contextAdd.state.movement.info.customer.socialReason}</p>
        </div>
      );

    return (
      <CustomerCombo
        onChange={(id, socialReason) => {
          contextAdd.setCustomer(id);
          contextAdd.setBeneficiarySocialReason(socialReason);
        }}
      />
    );
  }

  // if (contextAdd.state.ammount <= 0 || contextAdd.state.payMethod === null)
  //   return (
  //     <div>
  //       <Label>Cliente</Label>
  //       <p>Ingresa los datos obligatorios</p>
  //     </div>
  //   );

  return (
    <CustomerCombo
      onChange={(id, socialReason) => {
        contextAdd.setCustomer(id);
        contextAdd.setBeneficiarySocialReason(socialReason);
      }}
    />
  );
}
