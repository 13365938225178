import style from "styled-components";

/**
 * @type {import("./types").LayoutGrid}
 */
const GridLayout = {
  "pedidos":"5% 10% 10% 30% 5% 11.25% 11.25% 11.25%;",
  "facturas_emitidas":"5% 10% 10% 10% 20% 5% 11.25% 11.25% 11.25%;",
  "contabilidad":"10% 10% 35% 5% 10% 10% 10% 10%;"
}

const renderAccountingColumns = "10% 10% 35% 5% 10% 10% 10% 10%;";
const notRenderAccounting = "5% 10% 10% 30% 5% 11.25% 11.25% 11.25%;";

const Row = style.label`
    display: grid;  
    grid-template-columns:${(props) =>
      props.renderAccounting ? renderAccountingColumns : GridLayout[props.from]}
    font-weight:normal !important;
    border:1px solid var(--primaryColor);
    border-style:none none solid none;
    transition:background 0.25s ease;
    justify-content:space-between;
    align-items:center;
     > p{
        margin:0;
        padding:0.85rem 0;
    }
    &:hover{
        background:var(--secondaryBackground);
    }
    

    @media (max-width: 767px) {
        display:none;
      }
      
`;

/**
 * @typedef {{
 *  renderAccounting:boolean,
 *  renderInitials:boolean
 * }} ButtonProps
 */
      
/**
 * @type {import("styled-components").ThemedStyledFunction<'div',ButtonProps>}
 */
const Header = (style.div)`
    > p{
        margin:0;
        padding:0.85rem 0;
        text-align:center;
    }
    display: grid;
    align-items:center;
    justify-content:space-between;
    font-weight:bold;
    text-align:center;
    grid-template-columns:${(props) =>
      props.renderAccounting ? renderAccountingColumns : GridLayout[props.from]}
    position:sticky;
    top:0;
    @media (max-width: 767px) {
        display:none;
      }
`;

export { Row, Header };
