import React from "react";
import { useContext } from "react";
import { ContextAdvertisement } from "../Avisos";
import style from "../Avisos.module.scss";
import Create from "../Create";

export default function Options() {
  const hook = useContext(ContextAdvertisement);

  if (hook === null) return <div className={style.optionsAdvertisements}></div>;

  if (hook.advertisement === null || hook.advertisement === undefined)
    return <div className={style.optionsAdvertisements}></div>;

  return (
    <div className={style.optionsAdvertisements}>
      <Create onCreated={()=>hook.triggerFetchSearch()} id={hook.advertisement.advertisementID}/>
    </div>
  );
}
