import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";
const URL_HELP_DESK = `${URL_BASE}help-desk`;

/**
 * Get the current version of praxia
 * @returns {object}
 */
export async function getPraxiaVersion() {
  try {
    const response = await fetch(`${URL_HELP_DESK}/version`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw [];
  }
}

export async function getTickets() {
  try {
    const response = await fetch(`${URL_HELP_DESK}/tickets`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    return [];
  }
}
