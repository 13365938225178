import { InputDatePicker } from "components/Elements/InputDatePicker";
import React, { useContext } from "react";
import DocumentContext from "../ContextDocument";
import {
  ClaveContainer,
  ContractKeyContainer,
  DatesInputContainer,
  LabelDate,
  SwitchContract,
} from "./Conteiners";
import { transformDate } from "../helpers/documentHelper";
import { parseDateToText } from "helpers/dates";

export const InputSection = () => {
  /**
   * @type {import("../../../../../../types/documentActions").UseDocumentV2I}
   */
  const {
    generateContract,
    handleOnBeginDateChange,
    handleOnEndDateChange,
    handleOnReminderDateChange,
    documentType,
    contractKey,
    handleOnChangeContractKey,
    datesByDocumentType,
    actionType,
    placeHolderDate,
    id
  } = useContext(DocumentContext);
  const {
    endMinDate,
    beginDate,
    endDate,
    reminderDate,
    
    reminderMaxDate,
    endMaxDate,
  } = generateContract;

  const selectDateText = "Seleccione fecha fin";
  const selectRemiderText = "Seleccione recordatorio";

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const isActionTypeVer = actionType === "Ver";
  const available = isActionTypeVer ? false : true;
  const isDocumetnTypeContract = documentType === 6;
  const endDatePlaceHolder = isActionTypeVer
    ? datesByDocumentType?.endDate.placeHolder
    : datesByDocumentType?.endDate.disable
    ? selectDateText
    : datesByDocumentType?.endDate.placeHolder;

  const reminderDatePlaceHolder = isActionTypeVer
    ? placeHolderDate(reminderDate)
    : datesByDocumentType?.reminderDate.disable
    ? !!endDate
      ? selectRemiderText
      : selectDateText
    : datesByDocumentType?.reminderDate.placeHolder;

  return (
    <>
      {!isDocumetnTypeContract && <SwitchContract>Fechas</SwitchContract>}
      <DatesInputContainer>
        <div className="d-flex align-items-center w-100 justify-content-end">
          
          <LabelDate>{datesByDocumentType?.initialDate.label}</LabelDate>
          {isActionTypeVer ? (
            <div>
              <input type="text" disabled 
              value={id===null ? parseDateToText((new Date())) : parseDateToText(beginDate)} 
              />
            </div>
          ) : (
            <InputDatePicker
              selectedDate={beginDate}
              handleDateChange={handleOnBeginDateChange}
              disable={documentType === 6 ? true : false}
              minDate={datesByDocumentType?.initialDate.minDate}
              placeholderText={
                id===null ? parseDateToText((new Date())) : parseDateToText(beginDate)
              }

            />
          )}
        </div>

        <div className="d-flex align-items-center w-100 justify-content-end">
          <LabelDate>{datesByDocumentType?.endDate.label}</LabelDate>
          {isActionTypeVer ? (
            <div>
              <input type="text" disabled value={transformDate(endDate)} />
            </div>
          ) : (
            <InputDatePicker
              selectedDate={endDate}
              disable={available}
              minDate={endMinDate}
              maxDate={endMaxDate}
              placeholderText={endDatePlaceHolder}
              handleDateChange={handleOnEndDateChange}
            />
          )}
        </div>

        <div
          className="d-flex align-items-center flex-column justify-content-end"
          style={{
            gap: "1rem 0",
          }}
        >
          <div className="d-flex align-items-center justify-content-end">
            <LabelDate>{datesByDocumentType?.reminderDate.label}</LabelDate>
            {isActionTypeVer ? (
              <div>
                <input
                  type="text"
                  disabled
                  value={transformDate(reminderDate)}
                />
              </div>
            ) : (
              <InputDatePicker
                selectedDate={reminderDate}
                disable={available}
                minDate={tomorrow}
                maxDate={reminderMaxDate}
                handleDateChange={handleOnReminderDateChange}
                placeholderText={reminderDatePlaceHolder}
              />
            )}
          </div>
          {isDocumetnTypeContract && (
            <ContractKeyContainer>
              <ClaveContainer>Clave</ClaveContainer>
              <input
                type="text"
                placeholder="clave contrato"
                value={contractKey}
                disabled={isActionTypeVer}
                onChange={({ target: { value } }) => {
                  console.log(value);
                  handleOnChangeContractKey(value);
                }}
              />
            </ContractKeyContainer>
          )}
        </div>
      </DatesInputContainer>
    </>
  );
};
