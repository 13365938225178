import React, { useState } from "react";
import CreatableContact from "components/general/Forms/Selects/CreatableContact";
import * as yup from "yup";
import { cfdi, payForms, payMethods } from "Schemas/Directory/WinQuote";
import Form from "../Form/Form";
import {
  CFDIArea,
  ClientArea,
  DocInfo,
  DocReleated,
  Imports,
  PaymentMethodArea,
  PaymentTypeArea,
  SAT,
} from "../Form/Styles";
import CFDI from "components/general/Forms/Selects/CFDI";
import PayForm from "components/general/Forms/Selects/PayForm";
import PayMethods from "components/general/Forms/Selects/PayMethodsV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { updateInvoice as performUpdate } from "helpers/Apis/invoice";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Success } from "helpers/alerts";
import { useParams, useHistory } from "react-router-dom";
import {
  SET_DOC,
  SET_CUSTOMER_INVOICE,
  SET_CONTACT_INVOICE,
  SET_IS_UPDATING,
} from "../reducer";
import { Cliente } from "../../WinQuote/Invoice/Client";
import InvoiceCustomers from "../../WinQuote/Invoice/InvoiceCustomers";

export default function Invoice({ state, dispatch }) {
  const history = useHistory();

  const [contactsCreated, setContactsCreated] = useState([]);
  const { customerId } = useParams();

  const updateInvoice = async (info) => {
    dispatch({
      type: SET_IS_UPDATING,
      payload: true,
    });

    // console.log(info.document,
    //   state.comments,
    //   state.executive);
    const wasUpdated = await performUpdate(
      state.document,
      state.comments,
      state.executive
    );
    dispatch({
      type: SET_IS_UPDATING,
      payload: false,
    });

    if (wasUpdated) {
      Success(() => history.goBack(), "Pre-factura actualizada");
    }
  };

  /**
   * Add the contact to the list
   * @param {NewContactInformationI} contact - Info contact created
   */
  const createContact = (contact) =>
    setContactsCreated([
      {
        contactID: contact.id,
        customerID: +customerId,
        firstName: contact.nombre,
        middleName: contact.middleName,
        lastName1: contact.apellidoP,
        lastName2: contact.apellidoM,
        fullName: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        status: 1,
        label: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        email: contact.email,
        isActive: contact.isActive,
        lada_cellphone: contact.lada_cellphone,
        lada_phone: contact.lada_phone,
        number_cellphone: contact.number_cellphone,
        number_phone: contact.number_phone,
        puesto: contact.puesto,
      },
      ...contactsCreated,
    ]);

  return (
    <>
      <CreatableContact
        fullName={null}
        onSubmit={createContact}
        message="El contacto sera creado cuando se actualize la pre-factura"
      />
      <Form
        onSubmit={updateInvoice}
        onLoaded={(document) =>
          dispatch({
            type: SET_DOC,
            payload: document,
          })
        }
        schema={yup.object().shape({ cfdi, payForms, payMethods })}
      >
        {({ document, register, errors, setDocument }) => (
          <>
            {/* <div className="d-flex justify-content-end my-4">
              {isUpdating ? (
                <Spinner hidden={false} text="Actualizando pre-facutura..." />
              ) : (
                <ButtonV2 form="updateDocument">Actualizar</ButtonV2>
              )}
            </div> */}

            <DocInfo>
              <Form.Id />
              <Form.Type />
              <Form.No />
              <Form.Status />
              <Form.Executive />

              {state.document !== null ? (
                <>
                  {state.document.status.id === 9 ? (
                    <ClientArea>
                      <InvoiceCustomers
                        client={state.document.customer.id}
                        onChange={(id) =>
                          dispatch({
                            type: SET_CUSTOMER_INVOICE,
                            payload: +id,
                          })
                        }
                      />
                      {/* <Cliente
                        client={state.document.customer.id}
                        corporative={state.document.customer.corporative.id}
                        document={state.document}
                        onChange={(id) =>
                          dispatch({
                            type: SET_CUSTOMER_INVOICE,
                            payload: +id,
                          })
                        }
                      /> */}
                    </ClientArea>
                  ) : (
                    <Form.Client />
                  )}
                </>
              ) : null}

              {/* <Form.Client /> */}

              {state.document !== null ? (
                <Form.Contact
                  executeOnChange={false}
                  onChange={(value) =>
                    dispatch({
                      type: SET_CONTACT_INVOICE,
                      payload: value,
                    })
                  }
                  value={state.document.contact.id}
                  idCustomer={state.document.customer.id}
                  onLoaded={(options) => setContactsCreated(options)}
                  options={contactsCreated}
                />
              ) : (
                <p>Cargando contactos...</p>
              )}
            </DocInfo>

            <hr />
            <h3 className="my-2">Documentos relacionados</h3>
            <DocReleated>
              <Form.Origin />
              <Form.Quote />
              <Form.PreInvoice />
              <Form.Invoice />
              <Form.Contrato />
              <Form.OC />
              <Form.InvoiceProvider />

              <Form.NoContrato />
              <Form.CreationDate />
              <Form.ModifyDate />
              <Form.ExpirationDate />
              <Form.ReminderDate />
              <Form.PercentProgress />
              <Form.PercentProb />
            </DocReleated>

            <hr />
            <h3 className="my-2">Importes</h3>
            <Imports>
              <Form.Currency />
              <Form.TCP />
              <Form.Import />
              <Form.IVA />
              <Form.Total />
              <Form.Acredited />
              <Form.Recidue />
            </Imports>

            <SAT>
              {state.document === null ? null : (
                <>
                  {state.document.status.id === 9 ? (
                    <>
                      <CFDIArea>
                        <CFDI
                          forwardRef={register}
                          errors={errors}
                          css="w-100"
                          value={document.cfdi.id}
                          onChange={({ idCFDI }) =>
                            setDocument({
                              ...document,
                              cfdi: {
                                ...document.cfdi,
                                id: idCFDI,
                              },
                            })
                          }
                        />
                      </CFDIArea>

                      <PaymentTypeArea>
                        <PayForm
                          forwardRef={register}
                          value={document.payForm.id}
                          onChange={(idPayForm) =>
                            setDocument({
                              ...document,
                              payForm: {
                                ...document.payForm,
                                id: +idPayForm,
                              },
                            })
                          }
                        />
                      </PaymentTypeArea>

                      <PaymentMethodArea>
                        <PayMethods
                          forwardRef={register}
                          value={document.payMethod.id}
                          onChange={({ code }) =>
                            setDocument({
                              ...document,
                              payMethod: {
                                ...document.payMethod,
                                id: code,
                              },
                            })
                          }
                        />
                      </PaymentMethodArea>
                    </>
                  ) : (
                    <>
                      <Form.CFDI />
                      <Form.PaymentMethod />
                      <Form.PaymentType />
                    </>
                  )}
                </>
              )}

              <Form.Partialities />
              <Form.Partiality />
              <Form.CreditDays />
              <Form.Complement />
            </SAT>
          </>
        )}
      </Form>
    </>
  );
}
