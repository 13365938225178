import { Search } from "components/individual/Search";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextUenAdmin } from "..";

export default function SearchUen() {
  const hook = useContext(ContextUenAdmin);

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        placeholder="Código SAT, descripción, familia, sub-familia"
        onChange={(search) => hook.updateSearch(search)}
        onDelete={() => hook.updateSearch("")}
      />
    </div>
  );
}
