import { PdfOnTab } from "components/general/PdfOnTab";
import { RedirectButton } from "components/individual/buttons/Buttons";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { DownloadFile } from "components/individual/DownloadFile";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext, useRef } from "react";
import { ContextInvoiceReception } from "..";
import Icon from "components/individual/HeaderIcon";
import options from "./styles.module.scss";
import UpdateAccounting from "components/individual/UpdateAccounting";

export default function Options() {
  const {
    promptCancelInvoiceReception,
    infoRow,
    isForAccounting,
    isCancelling,
    gridAccounting,
  } = useContext(ContextInvoiceReception);

  const modulo = useRef(
    isForAccounting ? `?modulo=contabilidad` : `?modulo=administracion`
  );

  if (!infoRow)
    return (
      <div className={options.container}>
        <UpdateAccounting
          operationGrid={gridAccounting.operation}
          originalGrid={gridAccounting.original}
          type="FR"
        />
      </div>
    );

  return (
    <div className={options.container}>
      <RedirectButton
        id="associatedFilesInvoiceReception"
        text={
          <>
            <span>
              {!isForAccounting ? "Asociar archivos" : "Ver archivos asociados"}
            </span>
            <Icon icon="download" />
          </>
        }
        link={`/administracion/recepcion-facturas/archivos/${infoRow.id}${modulo.current}`}
      />

      <DownloadFile idFile={infoRow.xml} filename={`FR-${infoRow.noDocument}`}>
        <span>XML</span>
        <Icon icon="excel" />
      </DownloadFile>

      <DownloadFile
        idFile={infoRow.pdf}
        className="mr-2"
        filename={`FR-${infoRow.noDocument}.pdf`}
      >
        <span>PDF</span>
        <Icon icon="pdf" />
      </DownloadFile>

      <PdfOnTab idFile={infoRow.pdf} className="mr-2">
        <span>Ver PDF</span>
        <Icon icon="eye" />
      </PdfOnTab>

      {isCancelling ? (
        <Spinner
          hidden={false}
          idSpinner={window.crypto.randomUUID()}
          text="Cancelando recepción"
        />
      ) : infoRow.isCancellable &&
        infoRow.idLegalDocumentStatus !== 5 &&
        !isForAccounting ? (
        <ButtonV2 onClick={promptCancelInvoiceReception}>Cancelar</ButtonV2>
      ) : null}

      <UpdateAccounting
        operationGrid={gridAccounting.operation}
        originalGrid={gridAccounting.original}
        type="FR"
      />
    </div>
  );
}
