import { add } from "date-fns";
import { sub } from "date-fns/esm";
import { GetDocument } from "helpers/Apis/Documents";
import { dateToDbFormat } from "helpers/dates";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/**
 * custom hook edit document
 * @param {number|string} idDocParam - Id of the document to fetch information
 * @returns
 */
export default function useEditDocument(
  idDocParam = null,
  onLoaded = () => {},
  fetch = true
) {
  const { documentId } = useParams();

  /**
   * @type {[import("types/typedef/documents").DocumentI | null,()=>void]}
   */
  const [document, setDocument] = useState(null);

  /**
   * @type {[import("types/typedef/customHooks/useEditDocument").DocumentEditContactI, ()=>void]}
   */
  const [contact, setContact] = useState({
    id: null,
    info: null,
  });

  useEffect(() => {
    (async function () {
      let idToUse = documentId === undefined ? idDocParam : documentId;
      if (!idToUse || fetch === false) return;

      /**
       * @type {import("types/typedef/documents").DocumentI}
       */
      const apiDocInfo = await GetDocument(idToUse);

      setDocument({
        ...apiDocInfo,
        reminder: {
          ...apiDocInfo.reminder,
          Date: add(new Date(apiDocInfo.reminderDate.yyyymmdd), {
            days: 1,
          }),
        },
        expiration: {
          ...apiDocInfo.expiration,
          Date: add(new Date(apiDocInfo.expiration.yyyymmdd), {
            days: 1,
          }),
        },
      });

      setContact({
        id: apiDocInfo.contact.id,
        info: null,
      });

      onLoaded(apiDocInfo);
    })();
  }, [idDocParam]);

  /**
   * Set the expiration date of the document (also the reminder)
   * @param {Date} expirationDate - Expiration date to use
   */
  const setExpirationDate = (expirationDate) => {
    const reminderDate = sub(expirationDate, { days: 8 });

    let validReminderDate;

    if (reminderDate <= new Date()) {
      validReminderDate = sub(expirationDate, { days: 1 });
    } else {
      validReminderDate = reminderDate;
    }

    setDocument({
      ...document,
      reminder: {
        ...document.reminder,
        yyyymmdd: dateToDbFormat(validReminderDate),
        Date: validReminderDate,
      },
      expiration: {
        ...document.expiration,
        yyyymmdd: dateToDbFormat(expirationDate),
        Date: expirationDate,
      },
    });
  };

  /**
   * Set the reminder date
   * @param {Date} reminderDate - New reminder date to use
   */
  const setReminderDate = (reminderDate) =>
    setDocument({
      ...document,
      reminder: {
        ...document.reminder,
        yyyymmdd: dateToDbFormat(reminderDate),
        Date: reminderDate,
      },
    });

  /**
   *
   * Update the contract code
   * @param {string} contractCode - New contract code to use
   */
  const setContract = (contractCode) =>
    setDocument({
      ...document,
      documents: {
        ...document.documents,
        contract: {
          ...document.documents.contract,
          code: contractCode,
        },
      },
    });

  const setCreditDays = (creditDays) => setDocument({
    ...document,
    creditDays
  });

  return {
    document,
    contact,
    setDocument,
    setExpirationDate,
    setReminderDate,
    setContract,
    setCreditDays
  };
}
