import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// COMPONENTS
import { Select, ErrorMessage } from "../../../individual/inputs/Inputs";

// APIS
import { GetCFDI } from "../../../../helpers/Apis/Documents";
import { getInvoiceComboDefValue } from "helpers/Apis/parameters";
import { Label } from "structure/Document";

/**
 * Render a combo to display the cfdi options available on the system
 * @param {object} props - Props
 * @param {string} props.id - Id attribute to use for the input
 * @param {string} props.name - Name attribute to use for the input
 * @param {string} props.label - Label to display
 * @param {(object:import("types/typedef/components/CFDI").CfdiI)=>void} props.onChange - Information of the cfdi selected
 * @param {object} props.forwardRef - Ref for react-hook-form
 * @param {object} props.errors - Errors ref for react-hook-form
 * @param {string} props.css - Css
 * @param {number|null} props.value - Value to render by default
 * @returns {JSX.Element}
 */
function CFDI({
  id = "cfdi",
  name = "cfdi",
  label = "CFDI",
  onChange = () => {},
  forwardRef = {},
  errors = {},
  css = "",
  value = null,
}) {
  const [cfdis, setCfdis] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      const [
        apiCfdi,
        {
          value: { id },
        },
      ] = await Promise.all([GetCFDI(), getInvoiceComboDefValue("cfdi")]);

      if (value === null) {
        onChange(apiCfdi.find((cfdi) => cfdi.idCFDI === +id));
      }

      setCfdis(apiCfdi);
    }

    initialLoad();
  }, []);

  // useEffect(() => {
  //   if (value === null || cfdis.length <= 0) return;
  //   const cfdiToUse = cfdis.find((cfdi) => cfdi.code === value);
  //   onChange(cfdiToUse);
  // }, [value]);

  /**
   * Return the object info of the cfdi selected
   * @param {number} id - Id of the cfdi to look for
   * @returns {object} Information of the cfdi selected
   */
  const selectCfdiOption = (id) => cfdis.find((cfdi) => cfdi.idCFDI === id);

  return (
    <div className={`itemDocument d-inline-block ${css}`}>
      <Label>{label}</Label>
      <Select
        addTodos={false}
        idSelect={id}
        nameSelect={name}
        textJSON="infoCfdi"
        valueJSON="idCFDI"
        // label={label}
        forwardRef={forwardRef}
        options={cfdis}
        value={value}
        onChange={(value) => onChange(selectCfdiOption(+value))}
      />

      <ErrorMessage message={errors[name]?.message} />
    </div>
  );
}

CFDI.propTypes = {};

export default CFDI;
