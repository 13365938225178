import React, { useContext } from "react";
import { ContextCatalogueAdmin } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import style from "../Form/styles.module.scss";
import Description from "../Form/Description";
import UnitCost from "../Form/UnitCost";
import UnitPrice from "../Form/UnitPrice";
import SKU from "../Form/SKU";
import Currency from "../Form/Currency";
import SubmitFormButton from "../SubmitFormButton";
import StatusProduct from "../Form/Status";
import {
  UenV3,
  ModalClaveProd,
  ModalUm,
  UenSelect,
} from "components/general/Forms/Selects/UenV3";
import {
  IvasCatalogue,
  Options,
  Excento,
} from "components/general/Forms/Selects/IvasCatalogue";
import { Label } from "structure/Document";

export default function ModalEdit() {
  const hook = useContext(ContextCatalogueAdmin);

  return (
    <>
      <ButtonV2 onClick={() => hook.customShowEdit(true)}>Editar</ButtonV2>
      <Dialog
        width={35}
        title={
          typeof hook.product !== "object"
            ? "Editar producto"
            : `Editar ${hook.product.description}`
        }
        forwardRef={hook.editDialog.refDialog}
        closeCallback={() => hook.customShowEdit(false)}
        footer={<SubmitFormButton type="edit" />}
      >
        <form
          id={hook.idFormEdit}
          noValidate={true}
          className={style.formContainer}
          onSubmit={hook.formEdit.handleSubmit(hook.handleAttemptUpdate, (e) =>
            console.log(e)
          )}
        >
          {hook.isEditing ? (
            <>
              <Description operation="edit" />
              <UnitCost operation="edit" />
              <UnitPrice operation="edit" />
              <SKU operation="edit" />
              <Currency operation="edit" />
              <UenV3
                justActives={false}
                promptUpdate={false}
                id={hook.product.idUen}
                onChange={(data) =>
                  hook.updateSatData(data.SATUM, data.SATcode, data.UENID)
                }
              >
                {() => (
                  <>
                    <div>
                      <Label>UEN</Label>
                      <UenSelect />
                    </div>

                    <div>
                      <Label>Clave SAT</Label>
                      <ModalClaveProd />
                    </div>

                    <div>
                      <Label>UM SAT</Label>
                      <ModalUm />
                    </div>
                  </>
                )}
              </UenV3>

              <IvasCatalogue
                value={hook.product.iva}
                exempt={hook.product.isIvaExempt}
                onChange={(a) => hook.updateIvaData(a.excento, a.iva)}
              >
                {() => (
                  <>
                    <div>
                      <Label>IVA</Label>
                      <Options />
                    </div>

                    <div>
                      {hook.edition.iva === 0 ? (
                        <div>
                          <Label>IVA Excento</Label>
                          <Excento />
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </IvasCatalogue>

              <StatusProduct />
            </>
          ) : null}
        </form>
      </Dialog>
    </>
  );
}
