import usePermissions from "customHooks/usePermissions";
import React from "react";
import { useContext } from "react";
import { MenuContext } from "structure/Menu";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../index.module.scss";
import RedirectButton from "structure/Menu/Redirect";
import { Warning } from "helpers/alerts";
import CatalogueDirectory from "structure/CatalogueDirectory";
import MicrosoftCsp from "structure/MicrosoftCsp";
import MenuItem from "components/individual/MenuItem";
import { getControlReport } from "helpers/Apis/Documents";

export const PurchaseReports = () => {
  const { setMenuToDisplay } = useContext(MenuContext);

  const { checkPermission } = usePermissions();
  return (
    <>
      <p onClick={() => setMenuToDisplay("purchase")}>
        <b>
          <TextIcon icon="back" text="Compras > Reportes" />
        </b>
      </p>

      {checkPermission("b88e1bc8-a728-473a-b296-8093cb09da04") ? (
        <RedirectButton
          text={<TextIcon icon="document" text="Cuentas por pagar" />}
          // to={"/ventas/contratos"}
          onClick={() => {
            Warning(" Esta función esta en desarrollo 🛠️🛠️🛠️ ", () => {});
          }}
        />
      ) : // <p className={scss.item}>
      //   <TextIcon icon="document" text="Cuentas por pagar" />
      // </p>
      null}

      {checkPermission("5bd5044d-8aeb-41d4-997a-ace8e9325046") ? (
        <RedirectButton
          text={
            <TextIcon icon="administrationMoney" text="Saldos y movimientos" />
          }
          // to={"/ventas/contratos"}
          onClick={() => {
            Warning(" Esta función esta en desarrollo 🛠️🛠️🛠️ ", () => {});
          }}
        />
      ) : // <p className={scss.item}>
      //   <TextIcon icon="administrationMoney" text="Saldos y movimientos" />
      // </p>
      null}

      <RedirectButton
        text={<TextIcon icon="administrationMoney" text="Historico de pagos" />}
        // to={"/ventas/contratos"}
        onClick={() => {
          Warning(" Esta función esta en desarrollo 🛠️🛠️🛠️ ", () => {});
        }}
      />
      {/* <p className={scss.item}>
        <TextIcon icon="administrationMoney" text="Historico de pagos" />
      </p> */}

      <RedirectButton
        text={<TextIcon icon="administrationMoney" text="Cuentas por pagar" />}
        to={"/compras/cxp"}
      />

      <CatalogueDirectory type="providers">
        <p className={scss.item}>
          <TextIcon icon="administration" text="Catálogo proveedores" />
        </p>
      </CatalogueDirectory>

      <RedirectButton
        text={
          <TextIcon icon="administrationMoney" text="Saldos y movimientos" />
        }
        to={"/compras/saldos-y-movimientos"}
      />

      <RedirectButton
        text={
          <TextIcon
            icon="administrationMoney"
            text="Consecutivo de Ordenes de Compra"
          />
        }
        to={"/administracion/consecutivo-oc"}
      />

      <RedirectButton
        text={
          <TextIcon icon="administrationMoney" text="Antigüedad de saldos" />
        }
        to={"/v2/bancos/cxp/antiguedad-saldos"}
      />



      <MicrosoftCsp type="compras">
        <p className={scss.item}>
          <TextIcon icon="administration" text="BI" />
        </p>
      </MicrosoftCsp>

      <MenuItem
        icon="reports"
        children="Reporte de control"
        onClick={() => getControlReport()}
      />
    </>
  );
};
