import React, { createContext } from "react";
import useEmail from "customHooks/useCorreo";
import { From } from "structure/Email/From";
import { EmailAppend } from "structure/Email/EmailAppend";
import { Subject } from "structure/Email/Subject";
import { Files } from "structure/Email/Files";
import { TextBoxEmail } from "structure/Email/TextBox";
import { EmailExtensions } from "structure/Email/EmailExtensions";
import { FileFromPc } from "structure/Email/FileFromPc";
import { CloudFileBtn } from "structure/Email/CloudFiles";
import { EmailItem } from "structure/Email/EmailItem";
import { SendEmailBtn } from "structure/Email/SendEmailBtn";

/**
 * Context of the email
 * @type {import("react").Context<import("customHooks/useCorreo/types").EmailContextI|undefined>}
 */
export const ContextEmail = createContext(undefined);
const { Provider } = ContextEmail;

/**
 * Render the email component
 * @param {import("customHooks/useCorreo/types").EmailPropsI} props - Email props
 * @returns {JSX.Element} Email component
 */
export const Email = ({
  children,
  cc = [],
  message = null,
  subject = null,
  to = [],
  toInyection = async() => [],
  validExtensions = [],
  files = async () => {},
  onSendedEmail = () => {},
  refetch = false,
  idDocument = 0
}) => {
  const email = useEmail({
    cc,
    message,
    subject,
    to,
    validExtensions,
    files,
    onSendedEmail,
    toInyection,
    refetch,
    idDocument,
  });
  return (
    <Provider value={{ ...email, validExtensions }}>
      {children({ ...email })}
    </Provider>
  );
};

export {
  From,
  EmailAppend,
  Subject,
  Files,
  TextBoxEmail,
  EmailExtensions,
  FileFromPc,
  EmailItem,
  CloudFileBtn,
  SendEmailBtn,
};
