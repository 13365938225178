import InformativeTooltip from "components/individual/InfoTooltip";
import React, { useContext } from "react";
import Toggle from "react-toggle";
import { Label } from "structure/Document";
import { RemindersContext } from "..";

export default function ReminderEmail() {
  const { email, numberOfOptionsActive, updateEmail } =
    useContext(RemindersContext);

  const blockToggle = numberOfOptionsActive <= 1 && email ? true : false;

  return (
    <>
      <Label>
        Email
        {numberOfOptionsActive <= 1 ? (
          <InformativeTooltip>
            No puede deseleccionar esta opción. Al menos un metodo de
            recordatorio es necesario
          </InformativeTooltip>
        ) : null}
      </Label>
      <div className="d-flex align-items-center">
        <Toggle
          style={{
            cursor: blockToggle ? "not-allowed" : "pointer",
          }}
          disabled={blockToggle}
          checked={email}
          onChange={(e) => updateEmail(e.target.checked)}
        />
        <span style={{ margin: "0 0 0 10px" }}>
          <b>{email ? "Si" : "No"}</b>
        </span>
      </div>
    </>
  );
}
