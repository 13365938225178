import { Modal } from "components/general/Modal";
import React from "react";

import {
  Email,
  From,
  EmailAppend,
  Subject,
  Files,
  TextBoxEmail,
  EmailExtensions,
  FileFromPc,
  EmailItem,
  SendEmailBtn,
} from "structure/Email";

/**
 * Render a modal to send generic emails
 * @param {import("./types").PropsGenericEmailModal} props - Props
 * @returns {JSX.Element}
 * @example 
 * <GenericEmailModal
    propsOpenModal={{
      id: modalEmail.current,
      className: "d-none",
    }}
    to={["jperez@saiko.mx"]}
    cc={["aalardin@saiko.mx"]}
    message="Un mensaje de prueba generico"
    subject="Recordatorio"
    files={async () => downloadPdfDocument(2170, false)}
    validExtensions={["pdf", "png", "xml", "jpeg", "pdf", "gif", "txt"]}
  />
 */
export const GenericEmailModal = ({
  propsOpenModal = {},
  cc = [],
  to = [],
  message = null,
  subject = null,
  validExtensions = ["png", "jpg", "jpeg", "pdf", "xml"],
  onSendedEmail = async () => {},
  files = async () => {},
}) => {
  return (
    <Modal title="Enviar correo" width={50} propsOpenModal={propsOpenModal}>
      {({ closeModal }) => (
        <Email
          validExtensions={validExtensions}
          files={files}
          onSendedEmail={async () => {
            await onSendedEmail();
            closeModal();
          }}
          cc={cc}
          message={message}
          subject={subject}
          to={to}
        >
          {() => (
            <>
              <div className="d-flex">
                <From className="mr-3" />

                <FileFromPc className="mr-3" />
              </div>
              <EmailAppend type="to" atLeastOneEmail={true} />
              <EmailItem type="to" />
              <EmailAppend type="cc" />
              <EmailItem type="cc" />
              <Subject />
              <Files />
              <TextBoxEmail />

              <div className="d-flex justify-content-between align-items-center">
                <EmailExtensions />
                <SendEmailBtn />
              </div>
            </>
          )}
        </Email>
      )}
    </Modal>
  );
};
