import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import useEditRol from "customHooks/useEditRol";
import Steps from "rc-steps";
import React, { createContext } from "react";
import ButtonStep2 from "./Step1/ContinueButton";
import ButtonStep3 from "./Step2/ContinueButton";
import UpdateRol from "./Step3/ContinueButton";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import usePermissions from "customHooks/usePermissions";
import { useContext } from "react";
import { ContextRols } from "../Roles";
const NAVIGATION = {
  1: "1/3 - Descripcion del rol",
  2: "2/3 - Marcar permisos",
  3: "3/3 - Asignar roles que supervisa",
};
/**
 * @type {import("react").Context<import("types/typedef/customHooks/useEditRol").ContextEditRolI|undefined>}
 */
export const ContextEditRol = createContext(undefined);
const { Provider } = ContextEditRol;

const Step = {
  0: <Step1 />,
  1: <Step2 />,
  2: <Step3 />,
};

const ContinueStep = {
  0: <ButtonStep2 />,
  1: <ButtonStep3 />,
  2: <UpdateRol />,
};

/**
 * Update the information of the rol
 * @param {object} props - Props
 * @param {number} props.idRol - Id of the rol
 * @param {(()=>void)?} props.onEdited - Callback to execute when edition was success
 * @returns {JSX.Element}
 */
export default function EditRol({ idRol, onEdited = () => {} }) {
  const {
    isLoading,
    openModal,
    permissions,
    step,
    schemaTreePermissions,
    description,
    updateDescription,
    navigateToStep,
    updateMemoryPermissions,
    isValidPermissions,
    rols,
    closeModal,
    updateRol,
    handleUpdateCheckboxes,
    isAddingRol,
    isActive,
    handleActive,
    idCloseIcon,
    isUpdatingRol,
  } = useEditRol(idRol, onEdited);

  const hook = useContext(ContextRols);

  const { checkPermission } = usePermissions();

  if (!checkPermission("9b9d9d4c-e87c-4516-a971-f5251493c62d")) return <></>;

  return (
    <Provider
      value={{
        permissions,
        schemaTreePermissions,
        description,
        navigateToStep,
        isValidPermissions,
        updateMemoryPermissions,
        updateDescription,
        rols,
        updateRol,
        isAddingRol,
        isActive,
        handleActive,
        handleUpdateCheckboxes,
        isUpdatingRol,
      }}
    >
      <ModalV2
        title="Editar rol"
        idCloseIcon={idCloseIcon}
        close={"Cancelar"}
        aditionalClasses="mb-3"
        buttonText="Editar"
        width={60}
        idOpenModal={hook.idOpenEdit}
        footer={ContinueStep[step]}
        cbOpen={openModal}
        cbReject={closeModal}
        showCancelButton={false}
      >
        {isLoading ? (
          <Spinner text="Cargando rol" hidden={false} />
        ) : (
          <div className="m-3">
            <div className="containerSteps">
              <Steps current={step} className="mb-3">
                <Steps.Step
                  title="Descripcion del rol"
                  onClick={() => navigateToStep(0)}
                />
                <Steps.Step
                  title="Marcar permisos"
                  onClick={() => navigateToStep(1)}
                />
                <Steps.Step title="Asignar roles que supervisa" />
              </Steps>
            </div>

            <p className="mobileNavigation">{NAVIGATION[step+1]}</p>


            {Step[step]}
          </div>
        )}
      </ModalV2>
    </Provider>
  );
}
