import React from "react";
import { useContext } from "react";
import { ContextCreateEgress } from "..";
import { mnCurrency } from "helpers/money";
import { Label } from "structure/Document";

export default function NotAssociatedLabel() {
  const hook = useContext(ContextCreateEgress);

  return (
    <div>
      <Label className="justify-content-end mx-0">No asociado</Label>
      <p
        className={`text-right ${
          hook.amountNotAssociated < 0 ? "text-danger font-weight-bold" : ""
        }`}
      >
        {mnCurrency(hook.amountNotAssociated)}
      </p>
    </div>
  );
}
