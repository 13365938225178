const { default: styled } = require("styled-components");

export const DocumentContainer = styled.div`
  margin-bottom: 0.4rem;
  width: 100%;
  height: 100%;
  display: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0.8rem;
`;
export const RigthSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.3rem;
  justify-content: end;
  flex-direction: column;
  color:var(--primaryColor);
  position:sticky;
  right:0;
  top:0;
`;
export const LeftSection = styled.div`
  background-color: var(--cardBackground);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.4rem;
  padding: 0.5rem;
  color:var(--primaryColor);
  display: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  height:100%;
`;
export const BodySection = styled.div`
  display: grid;
  align-items:flex-start;
  grid-template-columns: 73% 25%;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    width: 100%;

    > *:nth-child(1) {
      height: 500px;
    }
  }
`;
