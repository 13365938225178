import { useReducer } from "react";
import ViewContext from "./ViewContext";
import ViewReducer from "./ViewReducer";

import { SET_VIEW, SET_LOADING_USER } from "../../types/index";

const ViewState = (props) => {
  const initialState = {
    view: "default",
    loadingUser: true,
  };

  const [state, dispatch] = useReducer(ViewReducer, initialState);

  const setView = (screen) => {
    dispatch({
      type: SET_VIEW,
      payload: screen,
    });
  };

  const setLoadingUser = () => {
    dispatch({
      type: SET_LOADING_USER,
    });
  };

  const values = {
    view: state.view,
    loadingUser: state.loadingUser,
    setView,
    setLoadingUser,
  };

  return (
    <ViewContext.Provider value={values}>{props.children}</ViewContext.Provider>
  );
};

export default ViewState;
