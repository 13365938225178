import React, { useState, useEffect } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { dateToDbFormat, getNumberDate } from "../../../helpers/dates";

import { Button, ModalDismissButton } from "../../individual/buttons/Buttons";

function Date({
  forwardRef,
  errors,
  defaultDate,
  cb = (value) => {},
  label = "Fecha",
  placeholder,
  name,
  id,
  maxDate = null,
  aditionalClasses,
}) {
  const [date, setDate] = useState(defaultDate);
  const [memoryDate, setMemoryDate] = useState(null);

  useEffect(() => {
    if (defaultDate === null) return;

    // Set the backup date
    const valueCopyDate = defaultDate;
    setMemoryDate(valueCopyDate);

    // GET INFO OF JS DATE
    const parsedDateUI = getNumberDate(defaultDate);
    const { day, monthName, year } = parsedDateUI;

    // PRINT ON UI
    document.getElementById(
      `fake_input_date_${id}`
    ).innerText = `${day}/${monthName}/${year}`;

    const parsedDate = dateToDbFormat(defaultDate);
    document.getElementById(id).value = parsedDate;
  }, [defaultDate]);

  const updateDate = (value, e) => {
    setDate(value);
    cb(value);
  };

  const confirmDate = () => {
    setMemoryDate(date);
    document.getElementById(`confirm_date_${id}`).click();

    // Set hidden input to DB date format (YYYY-MM-DD)
    const parsedDate = dateToDbFormat(date);
    document.getElementById(id).value = parsedDate;

    const { day, monthName, year } = getNumberDate(date);
    document.getElementById(
      `fake_input_date_${id}`
    ).innerText = `${day}/${monthName}/${year}`;
  };

  const cancel = () => {
    setDate(memoryDate);
  };

  return (
    <>
      <div className={aditionalClasses}>
        <label className="labelInput">{label}</label>
        <button
          type="button"
          className="fakeCalendar w-100"
          id={`fake_input_date_${id}`}
          data-toggle="modal"
          data-target={`#modal_date_${id}`}
        >
          {placeholder}
        </button>
      </div>

      <input
        type="text"
        className="d-none"
        id={id}
        name={name}
        ref={forwardRef}
      />

      <div
        className="modal fade"
        id={`modal_date_${id}`}
        tabindex="-1"
        aria-labelledby={`modal_date_${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {label}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={cancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mx-auto">
              <Calendar
                onChange={(value, e) => updateDate(value, e)}
                value={defaultDate}
                locale="es-MX"
                maxDate={maxDate}
              />
            </div>
            <div className="modal-footer">
              {/* <ModalDismissButton
                idBtn={`close_date_${id}`}
                text="Cancelar"
                callback={cancel}
              /> */}

              <ModalDismissButton
                idBtn={`confirm_date_${id}`}
                text="Cancelar"
                aditionalClasses="d-none"
              />

              <Button funcion={confirmDate} text="Aceptar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Date };
