import React from "react";
import { useContext } from "react";
import { ContextCancelInvoice } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Label } from "structure/Document";
import { add } from "date-fns";
import SwitchCancel from "../SwitchCancel";
import CancelationBody from "../CancelationBody";

registerLocale("es", es);

export default function BodyModal() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingCancellationInvoice"
        text="Cargando"
      />
    );

  if (hook.alreadyCancelled)
    return <h1 className="text-center py-3">La cotizacion ya fue cancelada</h1>;

  // if(hook.st)

  if (!hook.hasContract) return <CancelationBody/>

  if (hook.isWon)
    return (
      <h1 className="text-center py-3">
        Primero debes cancelar la orden de compra y el pedido de la cotizacion
      </h1>
    );

  return (
    <>
      <SwitchCancel />

      {!hook.cancelInvoice ? (
        <div className="row d-flex justify-content-around align-items-center">
          <div className="col-md-4 col-sm-12">
            <Label required={hook.startDate === undefined}>
              Fecha de inicio
            </Label>
            <ReactDatePicker
              dateFormat="dd/MMM/yyyy"
              locale="es"
              selected={hook.startDate}
              onChange={hook.updateStartDate}
              minDate={new Date()}
              placeholderText="Selecciona"
            />
          </div>

          <div className="col-md-4 col-sm-12">
            <Label required={hook.reminder === undefined}>
              Fecha de recordatorio
            </Label>
            {hook.startDate === undefined ? (
              <p>Selecciona una fecha de inicio</p>
            ) : (
              <ReactDatePicker
                dateFormat="dd/MMM/yyyy"
                locale="es"
                selected={hook.reminder}
                onChange={hook.updateReminderDate}
                placeholderText="Selecciona"
                minDate={add(hook.startDate, {
                  days: 1,
                })}
              />
            )}
          </div>

          <div className="col-md-4 col-sm-12">
            <Label required={hook.endDate === undefined}>Fecha de fin</Label>
            {hook.reminder === undefined ? (
              <p>Selecciona una fecha de recordatorio</p>
            ) : (
              <ReactDatePicker
                dateFormat="dd/MMM/yyyy"
                locale="es"
                selected={hook.endDate}
                onChange={hook.updateEndDate}
                minDate={hook.reminder}
                placeholderText="Selecciona"
              />
            )}
          </div>
        </div>
      ) : null}

      {/* <CancelationMotive /> */}
      <CancelationBody/>
    </>
  );
}
