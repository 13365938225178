import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import React, { useState } from "react";
import DocsRelated from "structure/DocsRelated";
import DocsRelatedV2 from "structure/DocsRelatedV2";

const initialState = {
  isOpen: false,
  title: undefined,
};

/**
 * @param {import('structure/DocsRelated/types').PropsDocsRelatedI}
 * @returns {JSX.Element}
 */
export const ModalDocsRelated = ({
  idDocument,
  idOpenModal = window.crypto.randomUUID(),
  fromLegal = false,
}) => {
  const [modal, setModal] = useState(initialState);

  const openModal = () =>
    setModal({
      ...modal,
      isOpen: true,
    });

  const closeModal = () => setModal(initialState);

  return (
    <ModalV2
      idOpenModal={idOpenModal}
      title="Documentos relacionados"
      cbOpen={openModal}
      cbReject={closeModal}
      showCancelButton={false}
      width={85}
      footer={null}
    >
      {!modal.isOpen ? (
        <Spinner
          idSpinner="loadingDocsRelated"
          hidden={false}
          text={<LoadingText>Cargando documento</LoadingText>}
        />
      ) : (
          <DocsRelatedV2 id={idDocument} isLegalDocumentId={fromLegal} />
      )}
    </ModalV2>
  );
};
