import ReceiveSatFiles from "components/general/ReceiveSatFiles";
import useAddXml from "customHooks/useAddXml";
import React, { createContext } from "react";
import { ContainerStep1 } from "./styles";
import {
  LabelUuuid,
  LabeRfcReceptor,
  LabelCurrency,
  LabelEmitedDate,
  LabelIVA,
  LabelImporte,
  LabelRFCEmitter,
  LabelRFCReceptor,
  LabelTotal,
  LabelFolio,
  LabelSocialReason,
  LabelDiscount,
  LabelImpuestosTrasladados,
  LabelImpuestos,
  LabelsAmounts,
} from "./labels";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export const Step1Context = createContext();
const { Provider } = Step1Context;

/**
 * Component to upload xml sat files
 * @param {import("./types").PropsAddXmlSat} props - Props
 * @returns {JSX.Element}
 */
export default function Add({
  children,
  onChange = () => {},
  onChangePdf = () => {},
  type = "I",
}) {
  const {
    setXmlInfo,
    errorMessages,
    isValidFileUpload,
    xmlInfo,
    isValidatingXml,
  } = useAddXml(onChange,type);

  return (
    <ContainerStep1>
      <ReceiveSatFiles onChange={setXmlInfo} onChangePdf={onChangePdf} type={type}/>
      {!errorMessages.status.isValid ? (
        <h1 className="text-center d-flex align-items-center">
          {errorMessages.status.message}
        </h1>
      ) : null}

      <Provider value={{ xmlInfo }}>
        <>
          {!isValidFileUpload ? (
            <h1 className="text-center d-flex align-items-center justify-content-center w-100">
              Carga un archivo xml valido
            </h1>
          ) : (
            <>
              {isValidatingXml ? (
                <div className="d-flex justify-content-center align-items-center w-100">
                  <Spinner
                    idSpinner="validatingXml"
                    text={<LoadingText>Validando xml</LoadingText>}
                    hidden={false}
                  />
                </div>
              ) : null}

              {xmlInfo === null
                ? null
                : !errorMessages.status.isValid
                ? null
                : children}
            </>
          )}
        </>
      </Provider>
    </ContainerStep1>
  );
}

Add.UUID = LabelUuuid;
Add.LabeRfcReceptor = LabeRfcReceptor;
Add.LabelCurrency = LabelCurrency;
Add.LabelEmitedDate = LabelEmitedDate;
Add.LabelIVA = LabelIVA;
Add.LabelImporte = LabelImporte;
Add.LabelRFCEmitter = LabelRFCEmitter;
Add.LabelRFCReceptor = LabelRFCReceptor;
Add.LabelTotal = LabelTotal;
Add.LabelSocialReason = LabelSocialReason;
Add.LabelFolio = LabelFolio;
Add.LabelDiscount = LabelDiscount;
Add.LabelImpuestosTrasladados = LabelImpuestosTrasladados;
Add.LabelImpuestos = LabelImpuestos;
Add.LabelsAmounts = LabelsAmounts;
