import { useBanks } from "components/individual/BankCard/hooks/useBanks";
import { sendBankCorrection } from "helpers/Apis/Banks/index";
import { getLastDayOfPreviousMonth } from "helpers/dates";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").State}
 */
const INITIAL_STATE = {
  banksAvailableToCorrect: [],
  canShowBankCorrectionOption: false,
  bankSelected: undefined,
  initialAmount: 0,
  month: undefined,
  year: undefined,
  apiLoaded: false,
  isValid: false,
  isValidDate: false,
  isUpdating: false,
};

/**
 * Get the information in order to check if there can do a bank correction
 * @returns {import("./types").ReturnUseBankCorrection}
 */
export default function useBankCorrecction() {
  const banks = useBanks();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    setState((current) => ({
      ...current,
      apiLoaded: false,
    }));

    /**
     * Retrieve the bank accoutns with only on month closed (the creation one)
     */
    const accountsToUse = banks.infocards.filter(
      (account) => account.monthsClosed.length === 1
    );

    setState((current) => ({
      ...current,
      banksAvailableToCorrect: accountsToUse.map((item) => ({
        ...item,
        label: `${item.bank.shortName} - ${item.currency} | ${item.account}`,
      })),
      canShowBankCorrectionOption: accountsToUse.length >= 1,
      apiLoaded: true,
    }));
  }, [banks.actives]);

  useEffect(() => {
    setState((current) => ({
      ...current,
      isValid: false,
      isValidDate: false,
    }));

    if (
      state.bankSelected === undefined ||
      state.year === undefined ||
      state.month === undefined
    )
      return;

    const lastMonth = getLastDayOfPreviousMonth();

    const isValidDate =
      new Date(`:${state.year}-${state.month}-15`) < lastMonth;

    setState((current) => ({
      ...current,
      isValid: true,
      isValidDate,
    }));
  }, [state.year, state.month, state.initialAmount, state.bankSelected]);

  const setBank = (bankAccount) =>
    setState((current) => ({
      ...current,
      bankSelected: bankAccount,
    }));

  /**
   * Set the initial amount of the account
   * @param {number} amount - New amount
   * @returns {void}
   */
  const setInitialAmount = (amount) =>
    setState((current) => ({
      ...current,
      initialAmount: +amount.toFixed(2),
    }));

  /**
   * Set year
   * @param {number} year - Year
   * @returns {void}
   */
  const setYear = (year) =>
    setState((current) => ({
      ...current,
      year,
    }));

  /**
   * Set month
   * @param {number} month - Month
   * @returns {void}
   */
  const setMonth = (month) =>
    setState((current) => ({
      ...current,
      month,
    }));

  const attemptBankCorrection = async () => {
    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    const wasUpdated = await sendBankCorrection({
      amount: state.initialAmount,
      idBankAccount: state.bankSelected.id,
      month: state.month,
      year: state.year,
    });

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));

    if (wasUpdated)
      setState((current) => ({
        ...INITIAL_STATE,
        apiLoaded: true,
        banksAvailableToCorrect:current.banksAvailableToCorrect
        // banksAvailableToCorrect: current.banksAvailableToCorrect.filter(
        //   (bank) => bank.id !== current.bankSelected.id
        // ),
      }));
  };

  return {
    ...banks,
    ...state,
    setBank,
    setInitialAmount,
    setYear,
    setMonth,
    attemptBankCorrection,
  };
}
