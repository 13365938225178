import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "../..";
import { Controller } from "react-hook-form";
import CurrencySelectV2 from "components/general/Forms/Selects/CurrencySelectV2";

/**
 * Render the unti cost input
 * @param {import("../types").NumberInput} props - Props
 * @returns {JSX.Element}
 */
export default function Currency(props) {
  const hook = useContext(ContextCatalogueAdmin);

  const reactHookForm =
    props.operation === "add" ? hook.formAdd : hook.formEdit;

  return (
    <div>
      <Label>Moneda</Label>

      <select className="select-css" name="currency" ref={reactHookForm.register}>
        <option value="0" disabled selected>--SELECCIONA--</option>
        <option value="1">MXN</option>
        <option value="2">USD</option>
      </select>
      {/* <Controller
        render={({ onChange, value }) => (
          <CurrencySelectV2
            defaultValue={value}
            criteriaToUse="id"
            onChangeValue={onChange}
          />
        )}
        name="currency"
        defaultValue={hook.currency === "USD" ? 2 : 1}
        control={reactHookForm.control}
      /> */}

      <p className="text-danger font-weight-bold">
        {reactHookForm.errors?.currency?.message}
      </p>
    </div>
  );
}
