import styled from 'styled-components';

export const ContainerUpload = styled.div`
    
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 300px;
    overflow-y: auto;
    grid-template-areas: 
    "addIcon items"
    ;

    .containerUpload{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .infoUpload{
        grid-area: addIcon;
        text-align: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        label{
            align-items: center;
            background-color: red;
            border:2px solid #000;
            background-color: #F9F9F9;
            width: 80px;
            height: 80px;
            border-radius:5px;
            margin:0 !important; 
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:40px;
            cursor: pointer;
            margin:0 !important;
        }
    }


    .fileItems{
        grid-area: items;
        overflow-y: auto;
        .fileItem{
            p{
                margin:0 0 0 10px;
            }
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 0 10px 20px;
            button{
                border-radius: 50%;
                width: 40px;
                height: 40px;
                border:2px solid #000;
                background-color: #F9F9F9;
            }
        }
        
    }
    
`;