import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import { ContextCommentOc } from "..";

export default function AddA() {
  const hook = useContext(ContextCommentOc);

  return (
    <ButtonV2
      onClick={async() => await hook.addComment(false)}
      disabled={hook.comment.length <= 0}
    >
      <Icon icon="plus" />
    </ButtonV2>
  );
}
