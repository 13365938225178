import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { createContext } from "react";
import useNotasDeCredito, {
  CONTEXT_USE_CREDIT_NOTES,
} from "./useNotasDeCredito";
import Search from "./Search";
import EmitCreditNote from "structure/EmitCreditNote";
import TableCreditNotesEmited from "./Table";
import CreditNoteOptions from "./Options";
import RangeDate from "./RangeDate";
import StatusFilter from "./StatsuFilter";
import usePermissions from "customHooks/usePermissions";
import ui from "../../Compras/NotasDeCreditoRecibidas/styles.module.scss";
import FilterMobile from "./FilterMobile";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export const ContextEmitedCreditNotes = createContext(CONTEXT_USE_CREDIT_NOTES);

export default function NotasDeCreditoEmitidas() {
  const hook = useNotasDeCredito();

  usePermissions('d91b10ef-8586-4073-857a-9829f8f0512d');

  return (
    <ContextEmitedCreditNotes.Provider value={hook}>
      <div className={ui.container}>
        <div className={ui.header}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={ui.filter}>
            <StatusFilter />
            <RangeDate />
            <Search />
            <ButtonV2 onClick={(e) => hook.attemptSearch()}>
              <span>Buscar</span>
              <Icon icon="search" />
            </ButtonV2>
            <EmitCreditNote onCreated={() => hook.refetchTable()} />
          </div>
          <FilterMobile />
        </div>

        <CreditNoteOptions />
        <TableCreditNotesEmited />
      </div>
    </ContextEmitedCreditNotes.Provider>
  );
}
