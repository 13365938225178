/**
 * @type{import('../../../../../../types/Partidas/partidas.interfaces').ActionsI}
 */
export const ACTIONS = {
  SET_LOAD_ITEM: "SET_LOAD_ITEM",
  SET_ITEM_DESCRIPTION: "SET_ITEM_DESCRIPTION",
  SET_ITEM_CURRENCY: "SET_ITEM_CURRENCY",
  SET_ITEM_IVA: "SET_ITEM_IVA",
  SET_ITEM_COST: "SET_ITEM_COST",
  SET_ITEM_PRICE: "SET_ITEM_PRICE",
  SET_ITEM_QUANTITY: "SET_ITEM_QUANTITY",
  SET_COST_DISCOUNT: "SET_COST_DISCOUNT",
  SET_PRICE_DISCOUNT: "SET_PRICE_DISCOUNT",
  SET_ITEM_UEN: "SET_ITEM_UEN",
  SET_OPEN_ITEM: "SET_OPEN_ITEM",
  SET_ITEM_SKU: "SET_ITEM_SKU",
  SET_NEW_ITEM_SKU: "SET_NEW_ITEM_SKU",
  SET_NEW_ITEM_DESCRIPTION: "SET_NEW_ITEM_DESCRIPTION",
};
