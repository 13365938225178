import React from "react";
import { useContext } from "react";
import { ContextCatalogueAdmin } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import style from "../Form/styles.module.scss";

/**
 * Render the button to submit the form
 * @param {import('./types').PropsSubmitFormButton} props - Props
 * @returns {JSX.Element}
 */
export default function SubmitFormButton({ type }) {
  const hook = useContext(ContextCatalogueAdmin);

  if (hook.isPerformingAbc)
    return (
      <div className={style.submitContainer}>
        <Spinner
          hidden={false}
          text={
            <LoadingText>
              {type === "add" ? "Agregando" : "Actualizando"}
            </LoadingText>
          }
        />
      </div>
    );

  return (
    <div className={style.submitContainer}>
      <ButtonV2
        type="submit"
        form={type === "add" ? hook.idFormAdd : hook.idFormEdit}
      >
        {type === "add" ? "Agregar" : "Actualizar"}
      </ButtonV2>
    </div>
  );
}
