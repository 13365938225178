import CFDI from "components/general/Forms/Selects/CFDI";
import ModalV2 from "components/individual/Modals/ModalV2";
import useCreateInvoice from "customHooks/useCreateInvoice";
import React, { createContext, useEffect } from "react";
import CurrencyExchange from "./CurrencyExchange";
import Partialities from "./Partialities";
import PayForm from "components/general/Forms/Selects/PayForm";
import PayMethods from "components/general/Forms/Selects/PayMethodsV2";
import scss from "./styles.module.scss";
import GridPartialities from "pages/Directory/Documents/Invoice/GridPartialities/GridPartialities";
import { Spinner } from "components/individual/Spinner/Spinner";
import TCRequest from "components/general/TC/Index";
import Footer from "./Footer";
import { addDays } from "date-fns";
import LimitTimeLabel from "./LimitTimeLabel";
import { CfdiUse } from "components/general/Forms/Selects/CfdiUse";
import { InvoiceComments } from "components/general/InvoiceComments";
import LoadingText from "components/individual/TextLoading";

/**
 * Context in order to attempt the creation of an invoice against sat
 * @type {import("react").Context<import("customHooks/useCreateInvoice/types").useCreateInvoiceValues>}
 */
export const ContextCreateInvoice = createContext();
const { Provider } = ContextCreateInvoice;

/**
 * Render a modal in order to create an invoice against SAT
 * @param {object} props - Props
 * @param {number} props.idInvoice - Id of the invoice to create
 * @param {()=>void} props.onSuccess - Callback when invoice was created or authorized successfully
 * @returns {JSX.Element} Button in order to open a modal to create an invoice
 */
export default function CreateInvoice({ idInvoice, onSuccess = () => {} }) {
  const invoice = useCreateInvoice(idInvoice, onSuccess);
  useEffect(() => {
    (async function () {
      if (!invoice.isOpen) return;

      await invoice.fetchInvoiceData();
    })();
  }, [invoice.isOpen]);

  return (
    <Provider value={invoice}>
      <ModalV2
        idCloseIcon={invoice.idCloseModal}
        title={invoice.title}
        aditionalClasses=""
        cbOpen={invoice.openModal}
        cbReject={invoice.closeModal}
        buttonText="Timbrar"
        showCancelButton={false}
        width={60}
        footer={<Footer />}
      >
        {invoice.invoice === null ? (
          <Spinner idSpinner="creatinInvoiceSpinner" text={<LoadingText>Cargando pedido</LoadingText>} hidden={false} />
        ) : invoice.isOnRevision ? (
          <h5 className="text-center py-5">{invoice.message}</h5>
        ) : (
          <div className="m-3">
            <LimitTimeLabel />

            {invoice.wasAuthorized ? null : <CurrencyExchange />}

            <InvoiceComments idInvoice={invoice.invoice.id} />

            {invoice.requireCurrencyExchange && !invoice.wasAuthorized ? (
              <TCRequest
                tcDocument={invoice.originalTcp}
                onChange={(tc) => invoice.updateTcp(tc)}
              />
            ) : null}

            <div className={scss.configurationInvoice}>
              {invoice.wasAuthorized ? null : <Partialities />}
              <div>
                <CfdiUse
                  idCustomer={invoice.invoice.customer.id}
                  cfdi={invoice.cfdi}
                  rfc={invoice.invoice.customer.rfc}
                  onChange={(cfdi) =>
                    invoice.updateCfdi({
                      code: cfdi.Value,
                      description: cfdi.Name,
                      idCFDI: cfdi.Value,
                      status: 1,
                      infoCfdi: cfdi,
                    })
                  }
                />
              </div>

              <PayForm
                value={invoice.invoice.payForm.id}
                onChangeV2={invoice.updatePayForm}
              />
              <PayMethods
                rfc={invoice.invoice.customer.rfc}
                value={invoice.invoice.payMethod.id}
                onChange={invoice.updatePayMethod}
              />
            </div>

            <GridPartialities
              partialities={invoice.partialities}
              tc={invoice.tcp}
              beginDate={addDays(new Date(), invoice.invoice.creditDays)}
              onChange={(cxcs) => invoice.updateCxc(cxcs)}
              currency={invoice.currency}
              totalAmountPreinvoice={invoice.total}
            />
          </div>
        )}
      </ModalV2>
    </Provider>
  );
}
