import React, { useContext } from "react";
import style from "../styles.module.scss";
import { Label } from "structure/Document";
import { parseDateToText } from "helpers/dates";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import { mnCurrency } from "helpers/money";
import { useRef } from "react";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import CancelInvoice from "structure/CancelInvoice";

/**
 * Render the html for the mobile view of the quotes on the index page
 * @param {import("../../../../../../server/models/documentsV2Types").PendingQuotesI} quote - Information of the quote
 * @returns {JSX.Element}
 */
export default function MobileItemHome(quote) {
  const hook = useContext(CONTEXT_HOME);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInfo = useRef();

    /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
    const emailDom = useRef();

    /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
    const domCancel = useRef();

  return (
    <div className={style.mobileItem}>
      <EmailDocumentsV2 forwardRef={emailDom} idDocument={quote.idDocument} />

      <div className={style.cancelMobile}><CancelInvoice
        id={quote.idDocument}
        status={1}
        onCancelated={hook.refetchCurrentPage}
        forwardRef={domCancel}
      /></div>
      

      <div className="d-flex justify-content-between">
        <div className={style.mobileLabel}>
          <div>
            <Label>Emisión</Label>
            <p>{parseDateToText(new Date(quote.createdDate))}</p>
          </div>

          <div>
            <Label>Vigencia</Label>
            <p>{parseDateToText(quote.expirationDate)}</p>
          </div>
        </div>

        <ElipsisMobile onShow={() => hook.selectQuote(quote)}>
          <CustomMenuItem onClick={() => domMoreInfo.current.click()}>
            Más información
          </CustomMenuItem>
          <CustomMenuItem onClick={hook.viewQuote}>
            Ver documento
          </CustomMenuItem>
          <CustomMenuItem onClick={hook.winDocument}>Ganar</CustomMenuItem>
          <CustomMenuItem onClick={hook.redirectAssociatedFiles}>
            Asociar archivos
          </CustomMenuItem>
          <CustomMenuItem onClick={hook.redirectToDo}>To Do</CustomMenuItem>
          <CustomMenuItem onClick={()=>emailDom.current.click()}>Enviar correo</CustomMenuItem>
          <CustomMenuItem
            disabled={hook.isDownloadingPdf}
            onClick={hook.downloadPdf}
          >
            {hook.isDownloadingPdf ? "Descargando PDF" : "PDF"}
          </CustomMenuItem>
          <CustomMenuItem onClick={hook.editDocument}>Editar</CustomMenuItem>
          <CustomMenuItem onClick={()=>domCancel.current.click()}>Cancelar</CustomMenuItem>
        </ElipsisMobile>
      </div>

      <div className={style.mobileLabel}>
        <div>
          <Label>Cotización</Label>
          <ModalDocsRelatedV2
            forwardRef={domMoreInfo}
            fromLegal={false}
            idDocument={+quote.idDocument}
          >
            <p>{quote.documentNumber}</p>
          </ModalDocsRelatedV2>
        </div>

        <div>
          <Label>Contrato</Label>
          <p>
            {typeof quote.contract.folio === "string"
              ? quote.contract.folio
              : "NA"}
          </p>
        </div>
      </div>

      <div>
        <Label>UEN</Label>
        <p className={style.uenContainer}>
          {quote.uen.map((item, j) => (
            <span key={window.crypto.randomUUID()}>{item.description}</span>
          ))}
        </p>
      </div>

      <div>
        <Label>Cliente</Label>
        <p>{quote.customer.socialReason}</p>
      </div>

      <div className={style.mobileLabel}>
        <div>
          <Label>Moneda</Label>
          <p>{quote.currency}</p>
        </div>

        <div>
          <Label>Total</Label>
          <p>{mnCurrency(quote.totalAmount)}</p>
        </div>
      </div>
    </div>
  );
}
