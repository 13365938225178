import React from "react";
import { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { BankAccountContext } from "..";
import rehypeRaw from "rehype-raw";
import { Label } from "structure/Document";

export default function ViewComment() {
  const { account } = useContext(BankAccountContext);
  return (
    <div>
      <Label>Comentario</Label>
      <ReactMarkdown children={account.comments} rehypePlugins={[rehypeRaw]} />
    </div>
  );
}
