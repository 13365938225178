import InformativeTooltip from "components/individual/InfoTooltip";
import ModalV2 from "components/individual/Modals/ModalV2";
import {
  getSocialReasonEntity,
  getTitleToDo,
  threadOverview,
} from "helpers/Apis/todo";
import React, { useEffect, useRef, useState } from "react";
import { ExpirationDate, Label, ReminderDate } from "structure/Document";
import Tags from "components/general/Forms/Selects/Tags";
import { createContext } from "react";
import CreateToDo from "./CreateToDo";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { dateWithCeroHours } from "helpers/dates";
import { InputText } from "components/individual/inputs/Inputs";
import Icon from "components/individual/HeaderIcon";
import ui from "./CreateToDo/styles.module.scss";

/**
 * Context to add a todo
 * @type {import("react").Context<import("./types").ContextAddTodo>}
 */
export const AddTodoContext = createContext();
const { Provider } = AddTodoContext;

/**
 * HTML to display for the type of section
 * @type {import("./types").SectionMessageI}
 */
const SECTION = {
  documentos: <span>Documentos</span>,
  contactos: <span>Contactos</span>,
  directorio: <span>Directorio</span>,
  personales: <span>Personales</span>,
};

/**
 * Modal in order to create a new To Do into system
 * @param {import("./types").AddProps} props - Props
 * @returns {JSX.Element}
 */
export default function Add({
  type,
  id,
  idEntity,
  parent = null,
  showButton = true,
  idOpenModal = window.crypto.randomUUID(),
  idToDo = null,
  onAdd = () => {},
}) {
  /**
   * State of the component in order to create a todo
   * @type {[import("./types").StateAddTodo,()=>void]}
   */

  const idCloseModal = useRef(window.crypto.randomUUID());

  const [state, setState] = useState({
    isOpen: false,
    title: "",
    reminder: null,
    expiration: null,
    note: "",
    isPerformingQuery: false,
    socialReason: undefined,
    idSocialReason: undefined,
    reminder: new Date(),
    expiration: new Date(),
    tag: {
      description: null,
      id: null,
    },
  });

  /**
   * @param {boolean} flag - Indicates if the modal is open
   */
  const setFlagOpen = (flag) =>
    setState({
      ...state,
      isOpen: flag,
      reminder: null,
      expiration: null,
      note: "",
      isPerformingQuery: false,
      reminder: new Date(),
      expiration: new Date(),
      tag: {
        description: null,
        id: null,
      },
    });

  useEffect(() => {
    (async function () {
      if (state.isOpen === false) return;

      if (type === "personales") {
        const threadInfo = await threadOverview(parent);
        setState({
          ...state,
          title: threadInfo.title,
          socialReason: "",
          idSocialReason: null,
          reminder: new Date(),
          expiration: new Date(),
          tag: {
            description: threadInfo?.tag.description,
            id: threadInfo?.tag.id,
          },
          note: "",
        });
      } else {
        if (parent === null) {
          const [title, { id: idSocialReason, socialReason }] =
            await Promise.all([
              getTitleToDo(type, id),
              getSocialReasonEntity(idEntity, type),
            ]);

          setState({
            ...state,
            title,
            socialReason,
            idSocialReason,
            reminder: new Date(),
            expiration: new Date(),
            tag: {
              description: null,
              id: null,
            },
            note: "",
          });
        } else {
          const [threadInfo, { id: idSocialReason, socialReason }] =
            await Promise.all([
              threadOverview(parent),
              getSocialReasonEntity(idEntity, type),
            ]);

          setState({
            ...state,
            title: threadInfo.title,
            socialReason,
            idSocialReason,
            reminder: new Date(),
            expiration: new Date(),
            tag: {
              description: threadInfo.tag?.description || null,
              id: threadInfo.tag?.id || null,
            },
            note: "",
          });
        }
      }
    })();
  }, [state.isOpen]);

  const setIsPerformingQuery = (flag) =>
    setState({ ...state, isPerformingQuery: flag });

  /**
   * Update the expiration date for the todo
   * @param {Date} expiration - Expiration dat
   * @returns {void}
   */
  const updateExpiration = (expiration) =>
    setState({ ...state, expiration: dateWithCeroHours(expiration) });

  /**
   * Update the reminder date for the todo
   * @param {Date} reminder - Expiration dat
   * @returns {void}
   */
  const updateReminder = (reminder) =>
    setState({ ...state, reminder: dateWithCeroHours(reminder) });

  const updateTag = (tag) => setState({ ...state, tag });

  return (
    <Provider
      value={{
        isPerformingQuery: state.isPerformingQuery,
        isValid:
          state.note !== "" &&
          state.tag.id !== null &&
          state.title.length >= 1 &&
          state.title.length <= 128
            ? true
            : false,
        setIsPerformingQuery,
        reminder: state.reminder,
        expiration: state.expiration,
        tag: state.tag,
        note: state.note,
        title: state.title,
        type,
        id,
        idCloseModal,
        parent,
        socialReason: state.idSocialReason,
        setFlagOpen,
        uuid: parent === null ? window.crypto.randomUUID() : parent,
        idToDo,
        onAdd,
      }}
    >
      <ModalV2
        buttonText={
          <>
            <span>Agregar</span>
            <Icon icon="plus" />
          </>
        }
        aditionalClasses={showButton ? "" : "d-none"}
        cbOpen={() => setFlagOpen(true)}
        cbReject={() => setFlagOpen(false)}
        title="Crear To Do"
        idCloseIcon={idCloseModal}
        showCancelButton={false}
        idOpenModal={idOpenModal}
        width={70}
        footer={<CreateToDo />}
      >
        <div className={ui.row}>
          <div>
            <Label>Seccion</Label>
            {SECTION[type]}
          </div>

          <div>
            <div className="d-flex">
              <Label required={state.title.length <= 0 ? true : false}>
                Título
              </Label>
              {type === "personales" ? (
                <span>{state.title.length}/128</span>
              ) : null}
            </div>

            {type === "personales" ? (
              <InputText
                defaultValue={state.title}
                labelTxt=""
                maxLength={128}
                onChange={(title) =>
                  setState({
                    ...state,
                    title,
                  })
                }
                placeholderTxt="Escribe aquí"
              />
            ) : (
              <span>{state.title}</span>
            )}
          </div>

          {type === "personales" ? null : (
            <div className="mr-3">
              <Label>Razón social</Label>
              <span>{state.socialReason}</span>
            </div>
          )}

          <div
            style={{
              maxWidth: "400px",
              minWidth: "300px",
            }}
          >
            <Label required={state.tag.id === null ? true : false}>Tag</Label>
            {parent === null ? (
              <Tags type={type} onChange={updateTag} />
            ) : (
              <p>{state.tag.description}</p>
            )}
          </div>
        </div>
        <div className={ui.row}>
          <div>
            <Label>
              Fecha de recordatorio
              <InformativeTooltip>
                De no terminar la tarea, se recibira un recordatorio por los
                medios programados según la configuración del perfil
              </InformativeTooltip>
            </Label>
            <ReminderDate
              allowToday={true}
              allowSameExpiration={true}
              expiration={state.expiration}
              selected={state.reminder}
              onChange={updateReminder}
            />
          </div>

          <div>
            <Label>
              Fecha de atención
              <InformativeTooltip>
                Fecha límite para realizar la tarea
              </InformativeTooltip>
            </Label>
            <ExpirationDate
              reminderDate={state.reminder}
              selected={state.expiration}
              onChange={updateExpiration}
              allowSameReminder={true}
            />
          </div>
        </div>
        
        <div className={ui.container}>
        <Label required={state.note === "" ? true : false}>Nota</Label>
        <ReactQuill
          theme="snow"
          style={{
            height: "60vh",
          }}
          value={state.note}
          onChange={(note) => setState({ ...state, note })}
          preserveWhitespace
        />
        </div>
      </ModalV2>
    </Provider>
  );
}
