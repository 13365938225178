import Money from "components/general/Forms/Money";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "../..";
import { Controller } from "react-hook-form";

/**
 * Render the unti cost input
 * @param {import("../types").NumberInput} props - Props
 * @returns {JSX.Element}
 */
export default function UnitPrice(props) {
  const hook = useContext(ContextCatalogueAdmin);

  const reactHookForm =
    props.operation === "add" ? hook.formAdd : hook.formEdit;

  return (
    <div>
      <Label>Precio unitario</Label>

      <Controller
        render={({ onChange , value }) => (
          <Money
            value={value}
            name="unitPrice"
            onChange={onChange}
            maxDecimals={2}
            placeholder="Escribe aquí"
            {...props}
          />
        )}
        name="unitPrice"
        control={reactHookForm.control}
        defaultValue={0}
      />

      <p className="text-danger font-weight-bold">
        {reactHookForm.errors?.unitPrice?.message}
      </p>
    </div>
  );
}
