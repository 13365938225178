/**
 * Win document action types
 * @type{import("../../../../../../types/winQuote").WinQuoteActionsI}
 */
export const ACTIONS = {
  SET_PROVIDER: "SET_PROVIDER",
  SET_CLIENT: "SET_CLIENT", // PODRIA NO SER NECESARIO
  SET_CREDIT_DAYS: "SET_CREDIT_DAYS",

  SET_INDEX_ACTIVE_MENU: "SET_INDEX_ACTIVE_MENU",

  SET_QUOTE_DATE1: "SET_QUOTE_DATE1",
  SET_QUOTE_DATE2: "SET_QUOTE_DATE2",
  SET_QUOTE_DATE3: "SET_QUOTE_DATE3",

  SET_ORDER_DATE1: "SET_ORDER_DATE1",
  SET_ORDER_DATE2: "SET_ORDER_DATE2",
  SET_ORDER_DATE3: "SET_ORDER_DATE3",

  SET_CONTRACT_DATE1: "SET_CONTRACT_DATE1",
  SET_CONTRACT_DATE2: "SET_CONTRACT_DATE2",
  SET_CONTRACT_DATE3: "SET_CONTRACT_DATE3",

  SET_ODC_DATE1: "SET_ODC_DATE1",
  SET_ODC_DATE2: "SET_ODC_DATE2",
  SET_ODC_DATE3: "SET_ODC_DATE3",

  SET_QUOTE_CONTACT: "SET_QUOTE_CONTACT",
  SET_ORDER_CONTACT: "SET_ORDER_CONTACT",
  SET_CONTRACT_CONTACT: "SET_CONTRACT_CONTACT",
  SET_ODC_CONTACT: "SET_ODC_CONTACT",

  SET_LOAD_CONTACTS_TO_DOCUMENTS: "SET_LOAD_CONTACTS_TO_DOCUMENTS",

  SET_EDIT_ITEM: "SET_EDIT_ITEM",
  SET_REMOVE_ITEM: "SET_REMOVE_ITEM",
  SET_CURRENT_ITEM: "SET_CURRENT_ITEM",

  SET_EDIT_CATALOGUE: "SET_EDIT_CATALOGUE",

  SET_GENERATE_CONTRACT: "SET_GENERATE_CONTRACT",

  SET_ACTIVE_MENU_OPTION: "SET_ACTIVE_MENU_OPTION",

  SET_TOTAL_IMPORT_PURSHACE: "SET_TOTAL_IMPORT_PURSHACE",
  SET_MARGIN: "SET_MARGIN",

  SET_DATE_LABLES: "SET_DATE_LABLES",
  SET_ITEM_HEADER: "SET_ITEM_HEADER",

  SET_LOAD_PROVIDER_CONTACT: "SET_LOAD_PROVIDER_CONTACT",
  SET_LOAD_CLIENT_CONTACT: "SET_LOAD_CLIENT_CONTACT",

  SET_ORDER_CURRENT_COMMENT: "SET_ORDER_CURRENT_COMMENT",
  SET_ODC_CURRENT_COMMENT: "SET_ODC_CURRENT_COMMENT",
  SET_CONTRACT_CURRENT_COMMENT: "SET_CONTRACT_CURRENT_COMMENT",

  SET_ORDER_COMMENTS: "SET_ORDER_COMMENTS",
  SET_ODC_COMMENTS: "SET_ODC_COMMENTS",
  SET_CONTRACT_COMMENTS: "SET_CONTRACT_COMMENTS",

  SET_DELETE_ORDER_COMMENT: "SET_DELETE_ORDER_COMMENT",
  SET_DELETE_ODC_COMMENT: "SET_DELETE_ODC_COMMENT",
  SET_DELETE_CONTRACT_COMMENT: "SET_DELETE_CONTRACT_COMMENT",

  SET_DELETE_ODC_ITEM: "SET_DELETE_ODC_ITEM",

  SET_EDIT_ORDER_COMMENT: "SET_EDIT_ORDER_COMMENT",
  SET_EDIT_ODC_COMMENT: "SET_EDIT_ODC_COMMENT",
  SET_EDIT_CONTRACT_COMMENT: "SET_EDIT_CONTRACT_COMMENT",

  SET_OPEN_TO_EDIT_ORDER_COMMENT: "SET_OPEN_TO_EDIT_ORDER_COMMENT",
  SET_OPEN_TO_EDIT_ODC_COMMENT: "SET_OPEN_TO_EDIT_ODC_COMMENT",
  SET_OPEN_TO_EDIT_CONTRACT_COMMENT: "SET_OPEN_TO_EDIT_CONTRACT_COMMENT",

  SET_WIN_QUOTE: "SET_WIN_QUOTE",
  SET_LOAD_INITIAL_DATA: "SET_LOAD_INITIAL_DATA",

  SET_EDIT_ODC_ITEMS: "SET_EDIT_ODC_ITEMS",
  SET_MONEY_INFORMATION: "SET_MONEY_INFORMATION",

  SET_FOOTER: "SET_FOOTER",

  SET_MULTIPLE_PROVIDERS: "SET_MULTIPLE_PROVIDERS",

  SET_BLOCK_ODC: "SET_BLOCK_ODC",
};
