import * as yup from "yup";

const password = yup.string()
.required('Campo obligatorio')
.min(10,'Almenos 10 caracteres')
.max(30,'Maximo 30 caracteres');

export const confirmIdentity = yup.object().shape({
    password
});

export const updatePass = yup.object().shape({
    password1:password,
    password2:password
});