import { getTags } from "helpers/Apis/todo";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

/**
 * Render the tags for the creation of a todo
 * @param {import("./types").TagsProps} props - Props
 * @returns {JSX.Element}
 */
export default function Tags({ type , onChange = () => {} }) {
  const [todo, setTodo] = useState([]);

  useEffect(() => {
    (async function () {
      const apiTags = await getTags(type);
      setTodo(apiTags.map(tag=>({
        value:tag.idTag,
        label:tag.description
      })))
    })();
  }, []);

  const handleOnChange = (tag) => {
    if (Object.keys(tag).includes("__isNew__")) {

      const id = window.crypto.randomUUID()

      setTodo([
        {
          label: tag.label,
          value: id,
        },
        ...todo,
      ]);

      onChange({
        id,
        description:tag.label
      });
    }else{
      onChange({
        id:tag.value,
        description:tag.label
      })
    }


  };

  return (
    <CreatableSelect
    options={todo}
      isClearable={false}
      classNamePrefix="saiko-select"
      className="saiko-select"
      placeholder="Selecciona o escribe un tag"
      onChange={handleOnChange}
    />
  );
}
