import React from "react";
import { useState } from "react";
import { Label } from "structure/Document";
import style from "./styles.module.scss";

const LOCK = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
    />
  </svg>
);

const UNLOCKED = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
    />
  </svg>
);

/**
 * Input password
 * @param {import("./types").PropsPasswordV2} props - Props
 * @returns {JSX.Element}
 */
export default function PasswordV2(props) {

  const { label = 'Contraseña' } = props;

  const [value, setValue] = useState({
    field: "",
    show: false,
  });

  const toggleDisplay = () =>
    setValue((current) => ({
      ...current,
      show: !current.show,
    }));

  return (
    <div className="inputUser">
      <div className={style.password}>
        <Label required={value.field === ""}>{label}</Label>
        <div>
          <input type={value.show ? "text" : "password"} {...props} />
          <button onClick={toggleDisplay} type="button">
            {value.show ? UNLOCKED : LOCK}
          </button>
        </div>
      </div>
    </div>
  );
}
