import React, { createContext, useEffect, useState } from "react";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import useCuotas from "../useCuotas";
import filter from "../Filter/styles.module.scss";
import Fee from "components/general/Forms/Selects/Fee";
import Currency from "components/individual/inputs/Currency";
import { Label } from "structure/Document";
import PowerOfTen from "components/general/Forms/Selects/PowerOfTen";
import { Spinner } from "components/individual/Spinner/Spinner";
import grid from "./styles.module.scss";
import { getFeeAbcParams } from "helpers/Apis/Documents";
import Year from "./Year";
import Add from "./Add";

/**
 * @type {import("react").Context<import("./types").ContextAbcFee|undefined>}
 */
export const ContextAbcFee = createContext(undefined);

const LABEL_TYPE = {
  contribution: "Contribución",
  invoice: "Facturación",
};

const POWERS = {
  0: "Unitario",
  2: "Cientos",
  3: "Miles",
  6: "Millones",
};

/**
 * Render a modal to create a fee
 * @param {import("./types").PropsAbcFee} props - Props
 * @returns {JSX.Element}
 */
export default function Abc({ onSuccess = () => {} }) {
  const dialog = useDialog();
  const hook = useCuotas();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async function () {
      hook.setCurrency(null);
      hook.setPower(null);
      hook.setFee(null);

      if (!isOpen || typeof hook.year !== "number") return;

      const defaultParams = await getFeeAbcParams(hook.year);

      hook.setCurrency(defaultParams.currency);
      hook.setPower(defaultParams.power);
      hook.setFee(defaultParams.type);
    })();
  }, [isOpen, hook.year]);

  const open = () => {
    dialog.open();
    setIsOpen(true);
  };

  const close = () => {
    dialog.close();
    setIsOpen(false);
    hook.resetComponent();
  };

  const success = () => {
    close();
    onSuccess();
  };

  return (
    <ContextAbcFee.Provider value={{ ...hook, success }}>
      <div>
        <ButtonV2 type="button" onClick={open}>
          <span>Agregar</span>
          <Icon icon="plus" />
        </ButtonV2>

        <Dialog
          width={50}
          title="Agregar cuota de ventas"
          forwardRef={dialog.refDialog}
          closeCallback={close}
          footer={<Add />}
        >
          {isOpen ? (
            <div className={grid.abcContainer}>
              <div
                className={filter.container}
                onSubmit={(e) => e.preventDefault()}
              >
                <Year />

                {typeof hook.year !== "number" || hook.year <= 0 ? (
                  <>
                    <div>
                      <Label required>Tipo de cuota</Label>
                      <p>Escribe un año</p>
                    </div>

                    <div>
                      <Label required>Moneda</Label>
                      <p>Escribe un año</p>
                    </div>

                    <div>
                      <Label required>Unidad</Label>
                      <p>Escribe un año</p>
                    </div>
                  </>
                ) : hook.years.includes(hook.year) ? (
                  <>
                    <div>
                      <Label required>Tipo de cuota</Label>
                      <p>{LABEL_TYPE[hook.type]}</p>
                    </div>

                    <div>
                      <Label required>Moneda</Label>
                      <p>{hook.currency}</p>
                    </div>

                    <div>
                      <Label required>Unidad</Label>
                      <p>{POWERS[hook.power]}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <Fee onChange={hook.setFee} />

                    <div>
                      <Label>Moneda</Label>
                      <Currency onChange={hook.setCurrency} selected={hook.currency}/>
                    </div>
                    <PowerOfTen onChange={hook.setPower} />
                  </>
                )}
              </div>
            </div>
          ) : null}
        </Dialog>
      </div>
    </ContextAbcFee.Provider>
  );
}
