import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextAttendInvoice } from "..";
import { CardContext } from "../../Card";
import ui from './styles.module.scss';

export default function Buttons() {
  const { approved, attemptAuthorizacion, repply } =
    useContext(ContextAttendInvoice);
  const { handleDirectDone } = useContext(CardContext);

  return (
    <div className={ui.alineacion}>
      <ButtonV2
        disabled={repply.length>=256}
        onClick={async () => {
          const wasDone = await attemptAuthorizacion(false);
          if (wasDone) handleDirectDone();
        }}
      >
        Rechazar
      </ButtonV2>

      {approved.limitTime !== null ? (
        <ButtonV2
        disabled={repply.length>=256}
          onClick={async () => {
            const wasDone = await attemptAuthorizacion(true);
            if (wasDone) handleDirectDone();
          }}
        >
          Aprobar
        </ButtonV2>
      ) : null}
    </div>
  );
}
