import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import CustomerInfo from "structure/Customer";
import { useState } from "react";

/**
 * Render more information about a customer on the directory
 * @param {import("./types").PropsMoreInfoDirectory} props - Props
 */
export default function MoreInfoDirectory({
  idCustomer = null,
  children = <></>,
  forwardRef = undefined
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  const handleOpen = () => {
    dialog.open();
    setIsOpen(true);
  };

  const handleClose = () => {
    dialog.close();
    setIsOpen(false);
  };

  return (
    <>
      <div ref={forwardRef} onClick={()=>handleOpen()}>{children}</div>

      <Dialog
        title="Más información"
        forwardRef={dialog.refDialog}
        closeCallback={handleClose}
      >
        {isOpen ? <CustomerInfo idCustomer={idCustomer} /> : null}
      </Dialog>
    </>
  );
}
