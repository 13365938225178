import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "./styles.scss";
registerLocale("es", es);

/**
 * Render the react date picker calendar on the spanish format
 * 
 * **Provide** `selected` (just that) in order to display the date formated
 * @param {import('react-datepicker').ReactDatePickerProps} props
 * @returns {JSX.Element}
 */
export const CustomReactDatePicker = (props) => {
  return <DatePicker dateFormat="dd/MMM/yyyy" locale="es" {...props}  />;
};
