import React, { createContext } from "react";
import useCatalogueAdmin, {
  InitialStateContextCatalogueAdmin,
} from "./useCatalogueAdmin";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ModalAdd from "./ModalAdd";
import TableCatalogue from "./TableCatalogue";
import FilterCurrency from "./FilterCurrency";
import style from "./styles.module.scss";
import OptionsCatalogue from "./Options";
import SearchButton from "./SearchButton";
import SearchBar from "./SearchBar";
import SearchSku from "./SearchSku";
import MobileFilter from "./MobileFilter";
import usePermissions from "customHooks/usePermissions";

export const ContextCatalogueAdmin = createContext(
  InitialStateContextCatalogueAdmin
);

export default function CatalogueAdmin() {
  const hook = useCatalogueAdmin();

  usePermissions('e456792d-b059-4988-8b78-618a0cfe8297');

  return (
    <div className={style.containerCatalogue}>
      <ContextCatalogueAdmin.Provider value={hook}>
        <div className={style.headerCatalogue}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={style.contentCatalogue}>
            <div className={style.filterCatalogue}>
              <FilterCurrency />
              <SearchBar />
              <SearchSku />
              <SearchButton />
              <ModalAdd />
            </div>

            <MobileFilter />
          </div>
        </div>

        <div className={style.contentCatalogue}>
          <OptionsCatalogue />

          <TableCatalogue />
        </div>
      </ContextCatalogueAdmin.Provider>
    </div>
  );
}
