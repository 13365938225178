import useDialog from "customHooks/useDialog";
import { createTicket, getTicket, getTickets } from "helpers/Apis/tickets";
import { parseDateToText, parseDateToTime } from "helpers/dates";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isCreatingTicket: false,
  tickets: [],
  currentPage: 1,
  pages: 0,
  filter: "allOfThem",
  files: [],
  isSearchingTickets: true,
  ticket: null,
  status: "pending",
  refetch: false,
  rowsPerPage: 10,
  isOpenMoreInfo:false,
  sortedBy:"created"
};

/**
 * Custom hook to handle the ticket
 * @param {(ticket:import("../../../../../../server/models/tickets/paginate/types").Ticket)=>void} onCreated - Callback executed when a ticket is created
 * @returns {import("./types").ReturnUseTickets}
 */
export default function useTickets(onCreated = () => {}) {
  const [state, setState] = useState(INITIAL_STATE);

  const labelsStatus = useRef({
    pending: "Pendientes",
    assigned: "En proceso",
    inProgress: "Por revisar",
    finished: "Terminados",
    evaluated: "Evaluados",
  });

  const dialog = useDialog();

  const setIsCreatingTicket = (isCreatingTicket) =>
    setState((state) => ({
      ...state,
      isCreatingTicket,
    }));

  /**
   *
   * @param {File[]} files
   * @returns
   */
  const updateFiles = (files) =>
    setState((state) => ({
      ...state,
      files,
    }));

  /**
   * @param {import('./types').CreateTicket} ticket - Information of the ticket
   */
  const attemptCreateTicket = async (ticket) => {
    setIsCreatingTicket(true);

    const ticketInfo = await createTicket(ticket, state.files);

    setIsCreatingTicket(false);

    if (ticketInfo !== null) {
      updateFiles([]);
      onCreated(ticketInfo);
    }
  };

  /**
   * Set the clicked ticket on the `UI`
   * @param {import("../../../../../../server/models/tickets/paginate/types").Ticket} ticket - Ticket
   * @param {number} clickedColumn - Clicked column
   */
  const selectTicket = (ticket,clickedColumn = 0) => {
    setState((state) => ({ ...state, ticket , isOpenMoreInfo: clickedColumn === 1 ? true : false }));
  };

  useEffect(()=>{
    if(state.isOpenMoreInfo){
      dialog.open()
    }else{
      dialog.close()
    }
  },[state.isOpenMoreInfo])

  useEffect(() => {
    (async function () {

      setState((current) => ({
        ...current,
        ticket: null,
        isSearchingTickets: true,
        isOpenMoreInfo:false
      }));

      const { currentPage, pages, tickets } = await getTickets(
        state.filter,
        state.currentPage,
        state.status,
        state.rowsPerPage
      );

      setState((state) => ({
        ...state,
        currentPage,
        pages,
        tickets,
        isSearchingTickets: false,
      }));
    })();
  }, [state.refetch,state.currentPage]);

  /**
   * Update the type of tickets to search for
   * @param {import("../../../../../../server/models/tickets/paginate/types").TicketsFilterBy} filter - Type of tickets to display
   * @returns
   */
  const changeFilterType = (filter) =>
    setState((current) => ({
      ...current,
      filter,
      currentPage: 1,
      refetch:!current.refetch
      
    }));

  /**
   * Update the type of tickets to search for
   * @param {import("../../../../../../server/models/tickets/paginate/types").TicketStatus} status - Type of tickets to display: ;
   * @returns
   */
  const changeStatusType = (status) =>
    setState((current) => ({
      ...current,
      status,
      currentPage: 1,
      refetch:!current.refetch
    }));

  /**
   * Append a ticket into the current list
   * @param {import("../../../../../../server/models/tickets/paginate/types").Ticket} ticket - Ticket info
   * @returns {void}
   */
  const appendTicket = (ticket) =>
    setState((state) => ({
      ...state,
      tickets: [ticket, ...state.tickets],
    }));


  /**
   * Fetch the next page for the tickets records
   * @param {number} page - Page to fetch
   * @returns {void}
   */
  const fetchPage = (page) =>
    setState((currentState) => ({
      ...currentState,
      currentPage: page
    }));


  const refetchTickets = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Set rows per page
   * @param {number} rowsPerPage - Rows per pege to fetch the information
   * @returns {void}
   */
  const setRowsPerPage = (rowsPerPage) =>
    setState((current) => ({
      ...current,
      rowsPerPage,
    }));

  const closeMoreInfo = ()=> setState(current=>({
    ...current,
    isOpenMoreInfo:false
  }))

  return {
    attemptCreateTicket,
    closeMoreInfo,
    refetchTickets,
    updateFiles,
    state,
    changeFilterType,
    selectTicket,
    changeStatusType,
    appendTicket,
    setRowsPerPage,
    dialog,
    fetchPage,
    breadcrum: [
      {
        route: `/inicio`,
        text: "Inicio",
      },
      {
        route: `/inicio`,
        text: "Procesos Especiales",
      },
      {
        route: `/help-desk`,
        text: "Help Desk",
      },
    ],
    columns: [
      {
        name: "Identificador",
        sortable: true,
        selector: (ticket) => ticket.folio,
      },
      {
        name: "Reporte",
        sortable: true,
        selector: (ticket) => ticket.title,
      },
      {
        name: "Creación",
        sortable: true,
        selector: (ticket) =>
          `${parseDateToText(ticket.reported.date)} ${parseDateToTime(
            new Date(ticket.reported.date)
          )}`,
      },
      {
        name: "Terminado",
        sortable: true,
        selector: (ticket) =>
          `${parseDateToText(ticket.finishedAt)} ${parseDateToTime(
            new Date(ticket.finishedAt)
          )}`,
      },
      {
        name: "Usuario",
        sortable: true,
        selector: (ticket) => ticket.reported.by.name,
      },
      {
        name: "Responsable",
        sortable: true,
        selector: (ticket) => ticket.assignedTo.name || "Sin responsable",
      },
      {
        name: "Estatus",
        sortable: false,
        selector: (ticket) => labelsStatus.current[ticket.status] || "ND",
      },
    ],
  };
}
