import Icon from "components/individual/HeaderIcon";
import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";
import { ContextInvoice } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function AddInvoice() {
  const { addDocument } = useContext(ContextInvoice);

  const { checkPermission } = usePermissions();
  return checkPermission("dcf3e4a1-40df-4c3f-84bd-9de21c2b5699") ? (
    <ButtonV2 onClick={addDocument}>
      <span>Agregar</span>
      <Icon icon="plus" />
    </ButtonV2>
  ) : null;
}
