import InformativeTooltip from "components/individual/InfoTooltip";
import { mnCurrency } from "helpers/money";
import React, { useRef } from "react";
import scss from "../Cancel/styles.module.scss";
import Details from "components/general/Details";

const HEADER = () => (
  <div className={scss.header}>
    <p>-</p>
    <p>Concepto</p>
    <p>Factura</p>
    <p>Parcialidad</p>
    <p>Proveedor</p>
    <p>Moneda</p>
    <p>Total</p>
    <p>Asociado</p>
    <p>Importe origen</p>
  </div>
);

/**
 * Render gastos
 * @param {import('./types').GastosProps} props - Props
 * @returns {JSX.Element}
 */
export default function Gastos({ items = [], hook, type = "gasto" }) {
  const title = type === "gasto" ? "Conceptos asociados" : "Facturas asociadas";

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (items.length <= 0)
    return (
      <Details summary={title}>
        <h4>
          No hay{" "}
          {type === "gasto" ? " conceptos asociados" : " facturas asociadas"}
        </h4>
      </Details>
    );

  return (
    <div className={scss.detailsAssociations}>
      <Details summary={title}>
        <HEADER />

        {items.map((item,i) => (
          <label
            htmlFor={`cxp-${item.id}`}
            className={scss.row}
            key={`${key.current}-${i}`}
          >
            <p className="d-flex justify-content-center">
              {item.type === "gasto" ? (
                <InformativeTooltip>
                  <p>
                    Para cancelar este concepto de gasto, debes cancelar todo el
                    movimiento de egreso
                  </p>
                </InformativeTooltip>
              ) : (
                <input
                  type="checkbox"
                  onClick={() => hook.handleCheckbox(item)}
                  id={`cxp-${item.id}`}
                  name={`cxp-${item.id}`}
                />
              )}
            </p>
            <p className="text-center">
              {item.concepts.reduce(
                (text, concept, i) =>
                  (text += `${concept.description} ${
                    i < item.concepts.length - 1 ? " , " : ""
                  }`),
                ""
              )}
            </p>
            <p className="text-center">{item.folio}</p>
            <p className="text-center">
              {item.partialitie}/{item.partialities}
            </p>

            <p className="text-left">{item.directory.socialReason}</p>

            <p className="text-center">{item.applied.currency}</p>
            <p className="text-right">
              {mnCurrency(item.total.amount)} {item.total.currency}
            </p>
            <p className="text-right">
              {mnCurrency(item.applied.amount)} {item.applied.currency}
            </p>
            <p className="text-right">
              {mnCurrency(item.paid.amount)} {item.paid.currency}
            </p>
          </label>
        ))}
      </Details>
    </div>
  );
}
