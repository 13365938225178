import { RangeDate, From, To } from "components/general/RangeDate";
import React, { useContext } from "react";
import { ContextContract } from "structure/Contract/List";

export default function RangeInput() {
  const { endDate, startDate, updateRangeDates } = useContext(ContextContract);

  return (
    <RangeDate>
      <From
        selected={startDate}
        onChange={(date) => updateRangeDates([date, endDate])}
      />
      <To
        selected={endDate}
        onChange={(date) => updateRangeDates([startDate, date])}
      />
    </RangeDate>
  );
}
