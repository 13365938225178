import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { BankForm } from "structure/Bank";
import { Modal } from "../Modal";
import { Edit } from "./Edit";

export const ContextEditBankAccount = createContext();
const { Provider } = ContextEditBankAccount;

/**
 * Modal in order to update a bank account of the system
 * @param {import('./types').PropsModalEditBankAccount} Props - Props
 * @returns {JSX.Element}
 */
export const ModalEditBankAccount = ({
  idBankAccount = null,
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    isPerformingQuery: false,
    isValid: false,
    info: null,
  });

  const setIsPerformingQuery = (isPerformingQuery) =>
    setState({
      ...state,
      isPerformingQuery,
    });

  return (
    <Modal
      title="Editar cuenta de banco"
      width={45}
      propsOpenModal={{
        children: "Editar",
      }}
    >
      {({ closeModal }) => (
        <Provider
          value={{
            ...state,
            closeModal,
            onUpdated,
            setIsPerformingQuery,
            idBankAccount,
          }}
        >
          <BankForm
            id={idBankAccount}
            onChange={(bank) =>
              setState({
                ...state,
                isValid: bank.isValid,
                info: bank,
              })
            }
          />
          <Edit />
        </Provider>
      )}
    </Modal>
  );
};
