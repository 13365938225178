import React from "react";
import { RedirectButton } from "components/individual/buttons/Buttons";
import Icon from "components/individual/HeaderIcon";

export default function Create() {
  return (
    <RedirectButton
      text={
        <>
          <span>Agregar</span>
          <Icon icon="plus" />
        </>
      }
      link={`/documentos/odc/agregar?modulo=administracion`}
    />
  );
}
