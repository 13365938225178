import React, { useRef, useContext } from "react";
import { MenuContainer, MenuItemContainer } from "./Containers";
import WinQuoteContext from "../ContextWinQuote";

export const Menu = () => {
  const { interfaceControl, handleMenuChange } = useContext(WinQuoteContext);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <MenuContainer>
      {interfaceControl?.menu.map((element, i) => (
        <MenuItemContainer
          id={element.idType}
          key={`${key.current}-${i}`}
          
          active={element.active}
          onClick={() => {
            handleMenuChange(element.idType);
          }}
        >
          {element.type}
        </MenuItemContainer>
      ))}
    </MenuContainer>
  );
};
