import { Spinner } from "components/individual/Spinner/Spinner";
import useContactsReport from "customHooks/useContactsReport";
import React from "react";

/**
 * Render a item to download the excel report on click action
 * @param {import('./types').PropsContactReport} props
 * @returns {JSX.Element}
 */
export default function ContactsReport({
  contactName = null,
  customerType = null,
  socialReason = null,
  status = null,
  children = <></>,
  renderSpinner = true,
}) {
  const hook = useContactsReport({
    contactName,
    customerType,
    socialReason,
    status,
  });

  if (hook.isLoading)
    return (
      <>
        {renderSpinner ? <Spinner text={"Descargando"} hidden={false} /> : null}
      </>
    );

  return <div onClick={hook.downloadAsExcel}>{children}</div>;
}
