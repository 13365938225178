import styled from "styled-components";

export const ContainerRadioBtns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: 1fr 1fr;
  grid-gap: 5%;

  .radioBtnElement {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 0.25rem 0;
  }
`;
