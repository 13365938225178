import CatalogueV2 from "components/general/Forms/Selects/Catalogue/Catalogue";
import ForwardInputField from "components/individual/InputField";
import React, { Fragment, useContext } from "react";
import useFormDocumentItems from "./useFormDocumentItems";
import CatalogSku from "components/general/Forms/Selects/Catalogue/CatalogueSku";
import { Label } from "structure/Document";
import { Controller } from "react-hook-form";
import Select from "components/individual/SelectV4";
import IvasAvailable, {
  Iva,
  IvaExempt,
} from "components/individual/IvasAvailable";
import UENV2 from "components/general/Forms/Selects/UENV2";
import SatCode from "structure/SatCode";
import SatUm from "structure/SatUm";
import { mnCurrency, parseInputDecimals } from "helpers/money";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

/**
 * @type {import("./types").ContextFormDocItems}
 */
const ContextFormDocumentItems = React.createContext(undefined);

/**
 * Render form in order to create items for documents
 * @param {import("./types").PropsFormDocumentItems} props - Props for FormDocumentItems
 * @returns {JSX.Element}
 */
export default function FormDocumentItems(props) {
  const hook = useFormDocumentItems(props?.idItem, props?.initialData);

  let formUnrefProps = { ...props };
  delete formUnrefProps.onCorrectSubmit;
  delete formUnrefProps.initialData;

  const { form } = hook;

  return (
    <ContextFormDocumentItems.Provider
      value={{ ...hook, initialData: props.initialData }}
    >
      <form
        {...formUnrefProps}
        noValidate={true}
        onSubmit={form.handleSubmit((data) => {
          const [satCode, satCodeDescription] = data.satCode.split(" - ");
          const [satUm, satUmDescription] = data.satUm.split(" - ");

          let dto = { ...data };
          delete dto.satCode;
          delete dto.satUm;

          /**
           * @type {import("./types").DocumentItemForm}
           */
          const realDto = {
            ...dto,
            satCode,
            satUm,
            satCodeDescription,
            satUmDescription,
          };

          props?.onCorrectSubmit(realDto);

          form.reset();
        },console.log)}
      >
        <ForwardInputField
          className="d-none"
          name="idCatalogue"
          ref={hook.form.register}
        />
        {props.children}
      </form>
    </ContextFormDocumentItems.Provider>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function Currency(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <Controller
        render={({ onChange, value }) => (
          <Fragment>
            <Label>Moneda</Label>
            <select className="select-css" name="currency" onChange={e=>onChange(e.target.value)} value={value}>
              <option value="" disabled selected>-- SELECCIONA --</option>
              <option value="MXN">MXN</option>
              <option value="USD">USD</option>
            </select>
            {/* <Select
              defaultSelected={{
                value,
                keyToCompare: "value",
              }}
              labelToDisplay="label"
              onChange={(data) => onChange(data.value)}
              options={async () => [
                {
                  label: "-- SELECCIONA --",
                  value: null,
                },
                {
                  label: "MXN",
                  value: "MXN",
                },
                {
                  label: "USD",
                  value: "USD",
                },
              ]}
            /> */}
            <p className="text-danger font-weight-bold">
              {hook.form.errors.currency?.message}
            </p>
          </Fragment>
        )}
        name="currency"
        control={hook.form.control}
      />
    </div>
  );
}
/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function Quantity(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="quantity"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label>Cantidad</Label>
          <input
            value={value}
            required={true}
            type="number"
            placeholder="Mínimo 1"
            onChange={(e) => {
              onChange(e.target.value.split(".")[0]);
              hook.setQuantity(+e.target.value);
            }}
          />

          <p className="text-danger font-weight-bold">
            {hook.form.errors.quantity?.message}
          </p>
        </div>
      )}
    />
  );

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        label="Cantidad"
        type="number"
        min={1}
        required={true}
        placeholder="Mínimo 1 unidad"
        name="quantity"
        onChange={(e) => e.target.value.split(".")[0]}
      />
      <p className="text-danger font-weight-bold">
        {hook.form.errors.quantity?.message}
      </p>
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function Cost(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="cost"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label>Costo unitario</Label>
          <input
            type="number"
            placeholder="Escribe aquí (Obligatorio)"
            min={0}
            required={true}
            value={value}
            onChange={(e) => {
              const [value, decimals] = e.target.value.split(".");
              const parsedDecimals = decimals?.slice(0, 2) || "";

              const data =
                `` + value + (parsedDecimals ? `.${parsedDecimals}` : "");

              onChange(data);
              hook.setCost(+data);
            }}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.cost?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function DiscountCost(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="discountCost"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div>
          <Label>Desc. proveedor</Label>
          <input
            type="number"
            value={value}
            required={true}
            min={0}
            max={100}
            placeholder="Escribe aquí (Obligatorio)"
            onChange={(e) => {
              const amount = parseInputDecimals(e.target.value, 2);
              onChange(amount);

              hook.setDiscountSupplier(+amount);
            }}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.discountCost?.message}
          </p>
        </div>
      )}
    />
  );

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        label="Desc. proveedor"
        type="number"
        min={0}
        max={100}
        required={true}
        placeholder="Escribe aquí (Obligatorio)"
        name="discountCost"
      />
      <p className="text-danger font-weight-bold">
        {hook.form.errors.discountCost?.message}
      </p>
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function DiscountSell(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="discountSell"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label>Desc. cliente</Label>
          <input
            type="number"
            min={0}
            max={100}
            required={true}
            placeholder="Escribe aquí (Obligatorio)"
            value={value}
            onChange={(e) => {
              const amount = parseInputDecimals(e.target.value, 2);
              onChange(amount);
              hook.setDiscountCustomer(+amount);
            }}
          />
        </div>
      )}
    />
  );

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        label="Desc. proveedor"
        type="number"
        min={0}
        max={100}
        required={true}
        placeholder="Escribe aquí (Obligatorio)"
        name="discountSell"
      />
      <p className="text-danger font-weight-bold">
        {hook.form.errors.discountSell?.message}
      </p>
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function Sell(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="sell"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label>Precio venta</Label>
          <input
            value={value}
            type="number"
            min={0}
            required={true}
            placeholder="Escribe aquí (Obligatorio)"
            onChange={(e) => {
              const [value, decimals] = e.target.value.split(".");
              const parsedDecimals = decimals?.slice(0, 2) || "";

              const data =
                `` + value + (parsedDecimals ? `.${parsedDecimals}` : "");

              onChange(data);

              hook.setSell(+data);
            }}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.sell?.message}
          </p>
        </div>
      )}
    />
  );

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        label="Precio venta"
        type="number"
        min={0}
        required={true}
        placeholder="Escribe aquí (Obligatorio)"
        name="sell"
      />
      <p className="text-danger font-weight-bold">
        {hook.form.errors.sell?.message}
      </p>
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function NewItemOption(props) {
  return (
    <div {...props}>
      <Label required={false}>Nueva partida</Label>
      <Select
        options={async () => [
          {
            label: "Si",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ]}
        labelToDisplay="label"
        onChange={console.log}
      />
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function CatalogueCombo(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="sku"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label required={true}>Clave</Label>

          {hook.initialData === undefined ? (
            <CatalogSku
              onChange={(data) => {
                console.log("sku cambio");

                const isNew = Object.keys(data).includes("__isNew__")
                  ? true
                  : false;

                const sku = isNew ? data.value : data.sku;

                onChange(sku);

                if (isNew) {
                  hook.form.setValue("idCatalogue", null);
                  hook.setIdCatalogueItem(null);
                  return;
                }

                hook.setIdCatalogueItem(data.id);

                hook.form.setValue("description", data.description);
                hook.form.setValue("idCatalogue", data.catalogue.id);

                hook.form.setValue("iva", data.iva.number);
                hook.setIva(data.iva.number);

                hook.form.setValue("ivaExempt", data.iva.exempt);
                hook.form.setValue("currency", data.currency.code);
                hook.form.setValue("uen", data.uen.id);
                hook.form.setValue(
                  "satCode",
                  `${data.satCode} - ${data.satCodeDescription}`
                );
                hook.form.setValue(
                  "satUm",
                  `${data.satUm} - ${data.satUmDescription}`
                );

                hook.form.setValue("cost", data.cu.number);
                hook.setCost(data.cu.number);

                hook.form.setValue("sell", data.pu.number);
                hook.setSell(data.pu.number);
              }}
              typeDocument="pedido"
              defaultValue={hook.idCatalogueItem}
            />
          ) : (
            <Fragment>
              <p className="m-0">{value}</p>
              <input type="text" className="d-none" value={value} />
            </Fragment>
          )}

          <p className="text-danger font-weight-bold">
            {hook.form.errors.sku?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function Description(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <Controller
        name="description"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <Fragment>
            <Label required={true}>Descripción</Label>

            {hook.initialData === undefined ? (
              <CatalogueV2
                typeDocument="pedido"
                defaultValue={hook.idCatalogueItem}
                onChange={(data) => {
                  const isNewItem = Object.keys(data).includes("__isNew__")
                    ? true
                    : false;

                  const description = isNewItem ? data.value : data.description;

                  onChange(description);

                  if (isNewItem) {
                    hook.form.setValue("idCatalogue", null);
                    hook.setIdCatalogueItem(null);
                    return;
                  }

                  hook.setIdCatalogueItem(data.id);
                  hook.form.setValue("sku", data.sku);

                  hook.form.setValue("idCatalogue", data.catalogue.id);

                  hook.form.setValue("iva", data.iva.number);
                  hook.setIva(data.iva.number);

                  hook.form.setValue("currency", data.currency.code);
                  hook.form.setValue("ivaExempt", data.iva.exempt);
                  hook.form.setValue("uen", data.uen.id);
                  hook.form.setValue(
                    "satCode",
                    `${data.satCode} - ${data.satCodeDescription}`
                  );
                  hook.form.setValue(
                    "satUm",
                    `${data.satUm} - ${data.satUmDescription}`
                  );

                  hook.form.setValue("cost", data.cu.number);
                  hook.setCost(data.cu.number);

                  hook.form.setValue("sell", data.pu.number);
                  hook.setSell(data.pu.number);
                }}
              />
            ) : (
              <input
                placeholder="Escribe aquí (Obligatorio)"
                value={value}
                onChange={(e) =>
                {console.log(e.target.value);
                  hook.form.setValue("description", e.target.value);}
                }
              />
            )}

            <p className="text-danger font-weight-bold">
              {hook.form.errors.description?.message}
            </p>
          </Fragment>
        )}
      />
    </div>
  );
}

/**
 *
 * @param {import("./types").PropsIvaContainer} props - Props for IvaContainer
 */
export function IvaContainer({ children = <></> }) {
  return <IvasAvailable>{children}</IvasAvailable>;
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function IvaField(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <Controller
        name="iva"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <Fragment>
            <Label>IVA</Label>
            <Iva onChange={onChange} value={value} />

            {/* {hook.initialData === undefined ? (
              <Iva onChange={onChange} value={value} />
            ) : (
              <Fragment>
                <p className="m-0">{hook.iva}%</p>
                <input onChange={e=>onChange(+e.target.value)} type="text" className="d-none" value={hook.iva} />
              </Fragment>
            )} */}

            <p className="text-danger font-weight-bold">
              {hook.form.errors.iva?.message}
            </p>
          </Fragment>
        )}
      />
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function IvaExemptField(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <Controller
        name="ivaExempt"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <Fragment>
            {hook.initialData === undefined ? (
              <IvaExempt onChange={onChange} value={value} />
            ) : (
              <Fragment>
                <p>{value}</p>
                <input type="text" className="d-none" value={value} />
              </Fragment>
            )}

            <p className="text-danger font-weight-bold">
              {hook.form.errors.ivaExempt?.message}
            </p>
          </Fragment>
        )}
      />
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function UenField(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <Controller
        render={({ onChange, value }) => (
          <Fragment>
            <UENV2
              value={value}
              onChange={(data) => {
                onChange(data.UENID);
                hook.form.setValue(
                  "satCode",
                  `${data.SATcode} - ${data.satCodeDescription}`
                );
                hook.form.setValue(
                  "satUm",
                  `${data.SATUM} - ${data.satUmDescription}`
                );
                hook.form.setValue("iva", data.iva);
                hook.form.setValue("ivaExempt", data.isExempt);

                hook.setIva(data.iva);

              }}
              disableInitlaOption={false}
            />

            <p className="text-danger font-weight-bold">
              {hook.form.errors.uen?.message}
            </p>
          </Fragment>
        )}
        name="uen"
        control={hook.form.control}
      />
    </div>
  );
}

/**
 * Sat code field
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function SatCodeField(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="satCode"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <SatCode
            description={value?.split(" - ")[1] || ""}
            name={value?.split(" - ")[0] || ""}
            onChange={(data) => {
              onChange(`${data.Value} - ${data.Name}`);
            }}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.satCode?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Sat code field
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function SatUmField(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <Controller
      name="satUm"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <SatUm
            description={value?.split(" - ")[1] || ""}
            name={value?.split(" - ")[0] || ""}
            onChange={(data) => onChange(`${data.Value} - ${data.Name}`)}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.satUm?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Sat code field
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function CostOperation(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <b>Costo U. de la operación</b>
      <p>
        {hook.costOperation === undefined || hook.costOperation <= 0
          ? "No disponible"
          : `${mnCurrency(hook.costOperation)} ${hook.form.getValues(
              "currency"
            )}`}
      </p>
    </div>
  );
}

/**
 * Sat code field
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function SellOperation(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <div {...props}>
      <b>Precio unitario de venta</b>
      <p>
        {hook.sellOperation === undefined || hook.sellOperation <= 0
          ? "No disponible"
          : `${mnCurrency(hook.sellOperation)} ${hook.form.getValues(
              "currency"
            )}`}
      </p>
    </div>
  );
}

/**
 * Button in order to submit the form of the items
 * @param {import("components/individual/ButtonsV2/types").ButtonV2Props} props
 */
export function SubmitItemCatalogue(props) {
  const hook = useContext(ContextFormDocumentItems);

  return (
    <ButtonV2 disabled={hook.costOperation >= hook.sellOperation}>
      <span>{props?.children||'Agregar'}</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}

export function MarginErrorMsg() {
  const hook = useContext(ContextFormDocumentItems);

  if (hook.costOperation === 0 || hook.sellOperation === 0) return <div></div>;

  if (hook.costOperation === undefined || hook.sellOperation === undefined)
    return <div></div>;

  if (hook.costOperation >= hook.sellOperation) {
    return (
      <p className="text-danger font-weight-bold m-0">
        El costo no puede ser mayor al precio de venta
      </p>
    );
  }

  return <div></div>;
}
