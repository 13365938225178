import usePermissions from "customHooks/usePermissions";
import { useRef } from "react";

export default function useParameters() {
  usePermissions("9213d840-d16a-4478-a80b-f616b065c726");

  /**
   * @type {import("./types").NavigationParams}
   */
  const schemaNavigation = useRef([
    {
      key: "31e6e685-78a4-45df-a718-5e72e1e82d1a",
      label: "Documentos",
      nodes: [
        {
          key: "67269a8c-5ce0-4532-b49a-f4c4decf15c2",
          label: "Facturación",
          nodes: [
            {
              key: "fd32036d-44c8-493f-b713-8031c04dc107",
              label: "Facturas emitidas",
            },
            {
              key: "297061f7-fb6c-4bc4-b5bc-4c819a557587",
              label: "Facturas recibidas",
            },
          ],
        },
      ],
    },
    {
      key: "8e8df98e-4738-40a0-9526-61fe96c021b2",
      label: "Empresa",
      nodes: [
        {
          key: "informacionEmpresa",
          label: "Datos generales",
        },
        {
          key: "03d0c27d-a17b-4ad3-8524-f4585d9cf4f2",
          label: "CSD (Certificado Sello Digital)",
        },
        {
          key: "6357329b-bc9e-42ff-9afe-cf7cc7b595cd",
          label: "Banner",
        },
        {
          key:'3be2d962-3aa7-440f-986e-1dc59528d5ae',
          label:'Robots'
        }
      ],
    },
    {
      key: "0a23b565-b864-40aa-8f21-2af81cfc5a73",
      label: "Correos",
      nodes: [
        {
          key: "f0c7fab8-3195-4042-909b-d2c81cf58ea8",
          label: "Recordatorio de pagos",
          nodes: [
            {
              key: "9c72f237-d7a7-48aa-88b3-19488e896b55",
              label: "Regla 1",
            },
            {
              key: "7e4d11cd-c60b-4a59-a5df-319edd7010d5",
              label: "Regla 2",
            },
            {
              key: "09af7421-b98f-434b-b9d6-19ea5b652249",
              label: "Regla 3",
            },
            {
              key: "d856d355-7e85-4d68-9df1-cb38f83a6d76",
              label: "Regla 4",
            },
          ],
        },
        {
          key: "1dfb7186-a728-4b9f-ad34-1b5bb7197c33",
          label: "Recordatorio contratos",
        },
        {
          key: "0f629cdf-a5e3-4480-ae4e-078813cd4408",
          label: "Facturas emitidas",
        },
        {
          key: "33b7fd0f-c15c-4aee-a9a8-01f18cb761df",
          label: "Complemento",
        },
      ],
    },
  ]);

  /**
   * @type {import("./types").Breadcrum}
   */
  const breadcrum = useRef([
    {
      route: `/inicio`,
      text: "Inicio",
    },

    {
      route: `/inicio`,
      text: "Administrador del sistema",
    },
    {
      route: `/inicio`,
      text: "Parámetros",
    },
  ]);

  return {
    breadcrum,
    schemaNavigation,
  };
}
