import React, { useContext } from "react";
import { ContextMoreInfoPaymentReminder } from "..";

/**
 * Render the footer for the more information
 * @param {import("./types").PropsFooter} props - Props
 * @returns {JSX.Element}
 */
export default function Footer({ socialReason = "" }) {
  const hook = useContext(ContextMoreInfoPaymentReminder);

  if (hook.isLoading) return null;

  return (
    <div className="d-flex align-items-center">
      <p className="m-0 text-warning font-weight-bold">
        {hook.contacts.length <= 0
          ? `🟡 No hay contactos de cobranza para el cliente (${socialReason})`
          : ""}
      </p>
    </div>
  );
}
