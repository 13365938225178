import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import {
  retrieveAttachedAdresses,
  retrieveEmailTemplate,
} from "helpers/Apis/email";
import { useContext, useEffect, useState } from "react";

const INITIAL_STATE = {
  subject: "",
  body: "",
  title: "Enviar correo",
  to: [],
  files: async () => {},
  cc: [],
};

/**
 * Hook to handle the information to put on the body of the complement email
 * @param {import("./types").ParamEmailComplement} props - Props
 * @returns
 */
export default function useEmailComplement({ idMovement, uuid }) {
  const dialog = useDialog();
  const { userInfo } = useContext(LoginContext);

  const [isOpen, setIsOpen] = useState(false);

  const [emailConfig, setEmailConfig] = useState(INITIAL_STATE);

  function handleOpen(isOpen) {
    if (isOpen) {
      setIsOpen(true);
      dialog.open();
    } else {
      setIsOpen(false);
      dialog.close();
      setEmailConfig(INITIAL_STATE);
    }
  }

  useEffect(() => {
    (async function () {
      if (!isOpen) return;
      dialog.open();

      const [cc,template] = await Promise.all([
        retrieveAttachedAdresses("cc", "complemento"),
        retrieveEmailTemplate("complemento",idMovement),
      ]);

      setEmailConfig((current) => ({
        ...current,
        cc: [...cc, userInfo[0].email],
        body:template.body,
        subject:template.subject
      }));
    })();
  }, [idMovement, uuid, isOpen]);

  return {
    isOpen,
    emailConfig,
    dialog,
    handleOpen,
  };
}
