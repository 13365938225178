import React from "react";
import { DatesContainer } from "../Dates/Conteiners";
import { InputSection } from "../Dates/InputSection";

export const DateComponent = () => {
  return (
    <DatesContainer>
      <InputSection />
    </DatesContainer>
  );
};
