import { GetCustomer } from "helpers/Apis/Directory";
import { GetDocument } from "helpers/Apis/Documents";
import {
  getMaterials,
  getMaterialsOdc,
  getProyect,
  receiveMaterials,
} from "helpers/Apis/proyects";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

/**
 * @type {import("./types").StateMaterialReception}
 */
const INITIAL_STATE = {
  position: undefined,
  state: "loading",
  refetch: false,
  materials: [],
  proyect: undefined,
  customer: undefined,
  isValidGrid: false,
  grid: {},
  performGrid: false,
  ocNumber: 0,
};

/**
 * Handle the reception of materials
 * @param {number} idOc - Id oc the oc to fetch the materials
 * @param {number} idPosition - Id of the position to receive their materials
 * @returns {import("./types").ReturnUseMaterialReception}
 */
export default function useMaterialReception(idOc, idPosition) {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("react").MutableRefObject<import("components/individual/breadCrums/types").BreadcrumI[]>}
   */
  const breadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/proyectos",
      text: "Proyectos",
    },
    {
      route: "/proyectos",
      text: "Posición",
    },
    {
      route: "/proyectos",
      text: "Materiales",
    },
    {
      route: "/proyectos",
      text: "Recepción",
    },
  ]);

  useEffect(() => {
    let mayInvalidGrid = Object.keys(state.grid).length <= 0;

    if (mayInvalidGrid === true) {
      setState((current) => ({
        ...current,
        isValidGrid: false,
      }));
      return;
    }

    Object.entries(state.grid).forEach(([key, value]) => {
      const itemRequested = state.materials.find(
        (material) => material.id === +key
      );

      const maxItemsAllowed =
        itemRequested.initialQuantity - itemRequested.receivedMaterials;

      if (value > maxItemsAllowed) {
        mayInvalidGrid = true;
      }
    });

    setState((current) => ({
      ...current,
      isValidGrid: !mayInvalidGrid ? true : false,
    }));
  }, [state.grid, state.performGrid]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        position: undefined,
        materials: [],
        proyect: undefined,
        customer: undefined,
        state: "loading",
      }));

      const proyect = await getProyect(null, idPosition);

      const materials = await getMaterialsOdc(idOc);

      const customer = await GetCustomer(proyect.idClient);

      const odc = await GetDocument(idOc);

      const position = proyect.positions.find(
        (position) => position.id === idPosition
      );

      breadcrum.current[3].route = `/proyectos/materiales/${position.id}`;

      setState((current) => ({
        ...current,
        materials: materials,
        customer,
        proyect,
        position,
        state: "none",
        grid: {},
        ocNumber: odc.documentNumber,
      }));
    })();
  }, [state.refetch]);

  /**
   * Update the amounts of invoice reception requested
   * @param {number} id - Id of the material
   * @param {string} quantity - Quantity requested
   */
  function setGridReception(id, quantity) {
    const itemRequested = state.materials.find(
      (material) => material.id === id
    );

    const deleteFromGrid = +quantity <= 0;

    let unref = state.grid;

    if (deleteFromGrid) deleteItem();
    else updateQuantity();

    setState((current) => ({
      ...current,
      grid: unref,
      performGrid: !current.performGrid,
    }));

    ////////////////////////////////////////////////////////////////////////////

    function deleteItem() {
      delete unref[id];
    }

    function updateQuantity() {
      unref[id] = +quantity;
    }
  }

  async function attemptReceiveMaterials() {
    setState((current) => ({
      ...current,
      state: "updating",
    }));

    //     idMaterial
    // idOdc
    // quantity

    const gridAssociation = Object.entries(state.grid).map(
      ([idMaterial, quantity]) => {
        return {
          idMaterial: +idMaterial,
          quantity: +quantity,
          idOdc: idOc,
        };
      }
    );

    const wasReceived = await receiveMaterials(gridAssociation);

    setState((current) => ({
      ...current,
      state: "none",
      refetch: !current.refetch,
    }));
  }

  return {
    ...state,
    breadcrumb: breadcrum.current,
    setGridReception,
    attemptReceiveMaterials,
  };
}
