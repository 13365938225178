import React, { useContext } from "react";
import { Search as SearchInput } from "components/individual/Search";
import { Label } from "structure/Document";
import { ContextCxpReport } from "..";


export default function Search() {
  const hook = useContext(ContextCxpReport);

  return (
    <div>
      <Label>Búsqueda</Label>
      <SearchInput
        hideSearchIcon={true}
        value={hook.search}
        onDelete={() => hook.updateSearchText("")}
        onChange={(search) => hook.updateSearchText(search)}
        placeholder="Razon social"
        onSearch={hook.refetchTable}
      />
    </div>
  );
}
