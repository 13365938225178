const documentType = {
    id: "documentType",
    name: "documentType",
    htmlFor: "documentType",
    lableText: "Tipo de documento",
    placeholder: "Cotizacion",
    readOnly:true,
    disable:false
  };
  const documentID = {
    id: "documentID",
    name: "documentID",
    htmlFor: "documentID",
    lableText: "ID Documento",
    placeholder: 1,
    readOnly:true,
    disable:false
  };
  const documentNumber = {
    id: "documentNumber",
    name: "documentNumber",
    htmlFor: "documentNumber",
    lableText: "Documento",
    placeholder: "0000002",
    readOnly:true,
    disable:false
  };
  const registerDate = {
    id: "registerDate",
    name: "registerDate",
    htmlFor: "registerDate",
    lableText: "Fecha de registro",
    placeholder: "15/Oct/2021",
    readOnly:true,
    disable:false
  };
  const userRegistered = {
    id: "userRegistered",
    name: "userRegistered",
    htmlFor: "userRegistered",
    lableText: "Usuario quien registra",
    placeholder: "Adrian Alardin Iracheta",
    readOnly:true,
    disable:false
  };
  const reminderDate = {
    id: "reminderDate",
    name: "reminderDate",
    htmlFor: "reminderDate",
    lableText: "Fecha Recordatorio",
    placeholder: "15/Oct/2021",
    readOnly:false,
    disable:false
  };
  const attentionDate = {
    id: "attentionDate",
    name: "attentionDate",
    htmlFor: "attentionDate",
    lableText: "Fecha atencion",
    placeholder: "15/Oct/2021",
    readOnly:false,
    disable:false
  };
  const comments = {
    id: "comments",
    name: "comments",
    htmlFor: "comments",
    lableText: "Comentario registrado",
    placeholder: "Escribir aquí",
    readOnly:true,
    disable:false
  };
  const newComments = {
    id: "newcomments",
    name: "newcomments",
    htmlFor: "newcomments",
    lableText: "Comentario nuevo registrado",
    placeholder: "Escribir aquí",
    readOnly:false,
    disable:false
  };
  const mustAttentedExecutive = {
    id: "attentedExecutive",
    name: "attentedExecutive",
    htmlFor: "attentedExecutive",
    lableText: "Ejecutivo atenderá",
    placeholder: "Adrian Alardin Iracheta",
    readOnly:false,
    disable:false
  };
  const attentedStatus = {
    id: "attentedStatus",
    name: "attentedStatus",
    htmlFor: "attentedStatus",
    lableText: "Estatus de atención",
    placeholder: "No atendida",
    readOnly:true,
    disable:false
  };
  const atentionRequired = {
    id: "attentionRequired",
    name: "attentionRequired",
    htmlFor: "attentionRequired",
    lableTextTrue: "Si requiere atención",
    lableTextFalse: "No requiere atención",
  };
  
  const reminderTag={
    id: "reminderType",
    name: "reminderType",
    htmlFor: "reminderType",
    lableText:'Tag'
  }
  
  const RemindersToAttentInputs = {
    documentType,
    documentID,
    documentNumber,
    registerDate,
    userRegistered,
    reminderDate,
    attentionDate,
    comments,
    mustAttentedExecutive,
    attentedStatus,
    atentionRequired,
    reminderTag,
    newComments
  };
  
  module.exports = RemindersToAttentInputs;
  