import { Modal } from "components/general/Modal";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import InformativeTooltip from "components/individual/InfoTooltip";
import { SearchInput } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import { searchUmSat } from "helpers/Apis/invoice";
import React, { useContext, useRef, useState } from "react";
import { Label } from "structure/Document";
import { UenContext } from "..";
import scss from "../styles.module.scss";
import LoadingText from "components/individual/TextLoading";

const INITIAL_STATE = {
  isLoading: false,
  search: "",
  results: [],
  itemSelected: undefined,
};

export const ModalUm = () => {
  const idModalOpen = useRef(window.crypto.randomUUID());

  /**
   * State of the modal in order to update the current SAT UM on the catalogue
   * @type {[import("../types").StateModalUm,()=>void]}
   */
  const [state, setState] = useState(INITIAL_STATE);

  const { selected, performUpdateUmSat } = useContext(UenContext);

  const openModal = () => document.getElementById(idModalOpen.current).click();

  const resetState = () => setState(INITIAL_STATE);

  /**
   * Update the search to use for the catalogue productos y servicios
   * @param {string} search - Search to use on the query for catalogue
   * @returns {void}
   */
  const updateSearchCriteria = (search) =>
    setState({
      ...state,
      search,
    });

  /**
   * Update the clave/producto for the item
   * @param {import("types/typedef/catalogue").UenI} itemSelected - Information of the UEN selected
   * @returns {void}
   */
  const setItemSelected = (itemSelected) =>
    setState({
      ...state,
      itemSelected,
    });

  const triggerSearch = async () => {
    setState({
      ...state,
      isLoading: true,
      results: [],
      itemSelected: undefined,
      description: "",
    });

    const optionsFounded = await searchUmSat(state.search);

    setState({
      ...state,
      isLoading: false,
      results: optionsFounded,
    });
  };

  if (selected === undefined) return <p>Selecciona una UEN o Código SAT</p>;

  return (
    <>
      <div className="d-flex">
        <p className={scss.containerClaveProd}>
          {selected.SATUM} - {selected.satUmDescription}
        </p>
        <button type="button" onClick={openModal} className={scss.editClave}>
          ✏
        </button>
      </div>

      <Modal
        title="Seleccionar unidad de medida"
        onCloseModal={resetState}
        propsOpenModal={{
          id: idModalOpen.current,
          className: "d-none",
          type: "button",
        }}
      >
        {({ closeModal }) => (
          <>
            <div className={scss.searchClave}>
              <div className={scss.searchBar}>
                <Label>
                  Buscar
                  <InformativeTooltip>
                    Tambien puedes
                    <a
                      href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                      target={"_blank"}
                      className="mx-1"
                    >
                      ir al catalogo del SAT
                    </a>
                    para encontrar esta informacion
                  </InformativeTooltip>
                </Label>
                <SearchInput
                  labelText=""
                  placeholderTxt="Escribe la unidad de medida o descripción, ejemplo: E20, Megabit por segundo"
                  showLabel
                  onChange={(e) => updateSearchCriteria(e.target.value)}
                />
              </div>
              <ButtonV2
                type="button"
                onClick={triggerSearch}
                disabled={state.search === "" ? true : false}
              >
                Buscar
              </ButtonV2>
            </div>

            {state.isLoading ? (
              <Spinner hidden={false} text={<LoadingText>Buscando</LoadingText>} />
            ) : state.results.length === 0 ? (
              <>
                {/* <h3 className="text-center my-5">
                No hay resultados de la busqueda
              </h3> */}
              </>
            ) : (
              <div className={scss.containerSearchFounded}>
                {state.results.map((result) => (
                  <label
                    className={scss.rowSearchFounded}
                    htmlFor={`${result.Value}`}
                  >
                    <input
                      type="radio"
                      name="umSatList"
                      id={`${result.Value}`}
                      onChange={() => setItemSelected(result)}
                    />
                    <p>
                      {result.Value} - {result.Name}
                    </p>
                  </label>
                ))}
              </div>
            )}

            {state.itemSelected !== undefined ? (
              <>
                <hr />
                <div className="d-flex justify-content-end">
                  <ButtonV2
                    type="button"
                    onClick={async () => {
                      await performUpdateUmSat(
                        state.itemSelected.Name,
                        state.itemSelected.Value
                      );

                      closeModal();
                    }}
                  >
                    Actualizar
                  </ButtonV2>
                </div>
              </>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
};
