import { ErrorAlertText, Success, YesNoAlert } from "helpers/alerts";
import { GetTCV2 } from "helpers/Apis/Banks";
import { mnCurrency, truncateDecimals } from "helpers/money";
import {
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { ACTIONS } from "./Actions";
import { documentReducer, loadDocumentInitialState } from "./documentReducer";
import { v4 as uuidv4 } from "uuid";
import { GetCustomer } from "helpers/Apis/Directory";
import { useHistory } from "react-router-dom";
import {
  addDocument,
  calculateDocumentMargin,
  editDocument,
  getDocumentData,
  handleFormItem,
  sumTotal,
  transformDate,
  transformToNumberAndCurrency,
} from "../helpers/documentHelper";
import LoginContext from "context/Login/LoginContext";
import {
  getDocumentsExpensesConcepts,
  getDocumentsIncomeConcepts,
} from "helpers/Apis/Documents";
import useQueryParams from "customHooks/useQueryParams";
import { dateAtCeroHours, parseDateToText, substractDays } from "helpers/dates";

/**
 * Hook for to handle the Document
 * @param {number} customerId Customer id
 * @param {number} documentType Document type
 * @param {number?} documentId The document id
 * @param {'Agregar' | 'Editar' | 'Ver'} action Document action
 * @returns {import("../../../../../../types/documentActions").UseDocumentI}
 */
export default function useDocument(
  customerId,
  documentType,
  action = "Agregar",
  documentId
) {
  const [tc, setTc] = useState(null);
  const [loadCustomer, setLoadCustomer] = useState(null);
  const [loadDocument, setLoadDocument] = useState(null);
  const { modulo } = useQueryParams();
  /**
   * @type{[documentConcepts:import("../../../../../../types/documentActions").Concepts[],setDocumentConcpets:React.SetStateAction]}
   */
  const [documentConcepts, setDocumentConcepts] = useState([]);
  const { userToRepresentId } = useContext(LoginContext);

  /**
   * @type{[import("../../../../../../types/documentActions").DatesByDocumnetTypeI,void]}
   */
  const [datesByDocumentType, setDatesByDocumentType] = useState(null);
  const history = useHistory();

  const documentInitialState = loadDocumentInitialState(documentType);

  const domInfo = useRef(null);

  const [document, dispatch] = useReducer(
    documentReducer,
    documentInitialState
  );

  useEffect(() => {
    if (!!documentType) {
      (async () => {
        const concepts = await loadDocumentConcepts(documentType);
        const formatedConcept = concepts.map(
          (
            /**
             * @type {import("../../../../../../types/documentActions").Concepts}
             */
            concept
          ) => ({
            ...concept,
            value: concept.id,
            label: concept.description,
          })
        );
        setDocumentConcepts(formatedConcept);
      })();
    }
  }, [documentType]);

  useEffect(() => {
    (async function () {
      const [tc] = await GetTCV2();
      setTc(tc);
    })();
  }, []);

  useEffect(() => {
    if (!!documentConcepts.length) {
      dispatch({
        type: ACTIONS.SET_LOAD_DOCUMENT_CONCEPTS,
        payload: documentConcepts,
      });
      const conceptDefault = documentConcepts.find(
        (element) => element.defaultToDocument === true
      );
      setDocumentConcepts([]);

      dispatch({
        type: ACTIONS.SET_CURRENT_CONCEPT,
        payload: conceptDefault,
      });
    }
  }, [documentConcepts]);

  useEffect(() => {
    (async function () {
      if (!!customerId) {
        const customer = await GetCustomer(customerId);

        /**
         * @type{import("../../../../../../types/documentActions").CustomerInfoI}
         */
        const documentCustomer = {
          comertialName: customer.name.commercial,
          id: customer.id,
          rfc: customer.rfc,
          shortName: customer.name.short,
          socialReason: customer.socialReason,
          isMexican: customer.adress.city === "Mexico" ? true : false,
        };
        setLoadCustomer(documentCustomer);
      }
    })();
  }, [customerId]);

  useEffect(() => {
    if (!!loadCustomer) {
      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: loadCustomer,
      });
    }
  }, [loadCustomer]);

  const documento = useMemo(() => {
    let tcNumber= tc?.enterprise.number;
    let tcText= tc?.enterprise.text;
    if(action==='Editar' || action==='Ver'){
      tcNumber=document.moneyInfo.tc.number;
      tcText=document.moneyInfo.tc.text;
    }
    // const tcNumber = tc?.enterprise.number;
    // const tcText = tc?.enterprise.text;
    /**
     * @type{import("../../../../../../types/documentActions").GeneralDocumentI}
     */
    return {
      moneyInfo: {
        ...document.moneyInfo,
        tc: {
          number: truncateDecimals(tcNumber, 2),
          text: truncateDecimals(tcText, 2),
        },
      },
    };
  }, [tc, document]);

  useEffect(() => {
    if (!!tc) {
      dispatch({
        type: ACTIONS.SET_INIT,
        payload: documento,
      });
      setTc(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tc]);

  useEffect(() => {
    if (!!document.currentItem.quantity) {
      if (!!document.currentItem.cu && !!document.currentItem.pu) {
        if (
          !!document.currentItem.cu.number &&
          !!document.currentItem.pu.number
        ) {
          // handleItemOperationSell();
          // handleItemOperationCost();
          handleTotalImports();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    document.currentItem.quantity,
    document.currentItem.cu,
    document.currentItem.providerDiscount,
    document.currentItem.pu,
    document.currentItem.clientDiscoount,
    document.customer,
  ]);

  const recalculateDocument = useCallback(() => {

    const recalulated = recalculate();
    return recalulated;
  }, [
    document.moneyInfo.tc.number,
    document.customer,
    document.moneyInfo.currency.id,
    document.recalculate,
    document.items.length,
  ]);

  useEffect(() => {
    const recalulated = recalculateDocument();

    dispatch({
      type: ACTIONS.SET_RECALCULATE_ITEMS,
      payload: recalulated,
    });
    // if (document.items.length > 0) {
    //   console.log("Recalculando moneda");

    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculateDocument]);

  useEffect(() => {
    // lastItemDocumetCurrency();

    if (document.items.length >= 1) {
      const newCureny = lastItemDocumetCurrency();
      // handleCalculateDocumentTotal(newCureny);
      handleInterface();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.recalculate, document.moneyInfo.tc.number]);

  useEffect(() => {
    if (!!action && (action === "Editar" || action==='Ver') && !!documentId) {
      (async () => {
        const { data } = await getDocumentData(documentId);
        console.log('Action del documento: ',action)
        console.log('Existe id documento: ',!!documentId)

        /**
         * @type{import("../../../../../../types/documentActions").GeneralDocumentI}
         */
        const fixinData = {
          ...data,
          generateContract: {
            beginDate: new Date(data.generateContract.beginDate),
            beginMaxDate: new Date(data.generateContract.beginDate),
            beginMinDate: new Date(data.generateContract.beginDate),
            endDate: new Date(data.generateContract.endDate),
            endMaxDate: new Date(data.generateContract.endMaxDate),
            endMinDate: new Date(data.generateContract.endMinDate),
            reminderDate: new Date(data.generateContract.reminderDate),
            reminderMaxDate: new Date(data.generateContract.reminderMaxDate),
            reminderMinDate: new Date(data.generateContract.reminderMinDate),
          },
          contact: !!data.contact ? data.contact : documentInitialState.contact,
          comments: !!data.comments
            ? data.comments
            : documentInitialState.comments,
        };
        setLoadDocument(fixinData);
      })();
    }
  }, [action, documentId]);

  useEffect(() => {
    if (!!loadDocument) {
      dispatch({
        type: ACTIONS.SET_INIT,
        payload: loadDocument,
      });
    }
    const { initialDate, reminderDate, endDate } =
      datesByDocuments(documentType);
    setDatesByDocumentType({ initialDate, reminderDate, endDate });
    /**
     * @type{import("../../../../../../types/documentActions").GenerateContractI}
     */
    const documnetDates = {
      beginDate: new Date(initialDate.date),
      beginMaxDate: new Date(initialDate.maxDate),
      beginMinDate: new Date(initialDate.minDate),
      endDate: new Date(endDate.date),
      endMaxDate: new Date(endDate.maxDate),
      endMinDate: new Date(endDate.minDate),
      reminderDate: new Date(reminderDate.date),
      reminderMaxDate: new Date(reminderDate.maxDate),
      reminderMinDate: new Date(reminderDate.minDate),
    };
  }, [loadDocument]);

  function reorderDocumentItems(items) {
    dispatch({
      type: ACTIONS.SET_ITEMS,
      payload: items,
    });
  }

  /**
   *
   * @param {import("../../../../../../types/documentActions").PreviusContact1I | import("../../../../../../types/documentActions").PreviusContact2I} contact
   */
  const handleContactOnChange = (contact) => {
    /**
     * @type{import("../../../../../../types/documentActions").PreviusContact1I}
     */
    let parseContact;

    /**
     * @type{import("../../../../../../types/documentActions").CustomerContactI}
     */
    let selectedContact;

    if (!!contact.data) {
      parseContact = contact.data;

      selectedContact = {
        anniversary: parseContact.anniversary,
        email: parseContact.email,
        id: parseContact.id,
        isCollectionContact: parseContact.isCollectionContact,
        isPaymentContact: parseContact.isPaymentContact,
        lastName1: parseContact.lastName1,
        lastName2: parseContact.lastName2,
        name: parseContact.name,
        workTitle: parseContact.workTitle,
        cell: {
          extention: "",
          numberPhone: "",
        },
        phone: {
          extention: "",
          numberPhone: "",
        },
        middleName: parseContact.middleName,
      };
    } else {
      parseContact = contact;
      selectedContact = {
        anniversary: parseContact.birthDay,
        email: parseContact.email,
        id: parseContact.id,
        isCollectionContact: parseContact.isForColletion,
        isPaymentContact: parseContact.isForPayment,
        lastName1: parseContact.apellidoP,
        lastName2: parseContact.apellidoM,
        name: parseContact.nombre,
        workTitle: parseContact.puesto,
        cell: {
          extention: parseContact.lada_cellphone,
          numberPhone: parseContact.number_cellphone,
        },
        phone: {
          extention: parseContact.lada_phone,
          numberPhone: parseContact.number_phone,
        },
        middleName: parseContact.middleName,
      };
    }

    dispatch({
      type: ACTIONS.SET_CONTACT,
      payload: selectedContact,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").GenerateContractI} dates
   */
  const handleOnChangeToggle = (toggle) => {
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 1);
    const endDate = initialDate;
    // const reminderDate = endDate;
    /**
     * @type{import("../../../../../../types/documentActions").GenerateContractI}
     */
    let toggleDate;

    if (toggle) {
      toggleDate = {
        generateContract: toggle,
        beginDate: initialDate,
        beginMinDate: initialDate,
        endDate: "",
        endMinDate: endDate,
        reminderDate: "",
        reminderMaxDate: "",
        reminderMinDate: initialDate,
      };
    } else {
      toggleDate = {
        generateContract: toggle,
        beginDate: "",
        beginMinDate: "",
        endDate: "",
        endMinDate: "",
        reminderDate: "",
        reminderMaxDate: "",
        reminderMinDate: "",
      };
    }
    dispatch({
      type: ACTIONS.SET_GENERATE_CONTRACT,
      payload: toggleDate,
    });
  };

  /**
   * Handle the change of the begin date
   * @param {string} date The begin date
   * @returns {()=>void}
   */
  const handleOnBeginDateChange = (date) => {
    /**
     *@type {import("../../../../../../types/documentActions").GeneralDocumentI}
     */
    const {
      generateContract: { endDate, reminderDate },
    } = document;

    /**
     * @type{import("../../../../../../types/documentActions").GenerateContractI}
     */
    let changeBeginDate;

    if (endDate !== "" && date > endDate) {
      changeBeginDate = {
        ...document.generateContract,
        beginDate: date,
        endDate: date,
        reminderDate: "",
        endMinDate: date,
        reminderMinDate: date,
        reminderMaxDate: date,
      };
    } else if (reminderDate !== "" && date > reminderDate) {
      // const newReminderDate = calculateReminderDate(date, endDate);

      changeBeginDate = {
        ...document.generateContract,
        beginDate: date,
        reminderDate: "",
        endMinDate: date,
        reminderMinDate: date,
        reminderMaxDate: endDate,
      };
    } else {
      changeBeginDate = {
        ...document.generateContract,
        beginDate: date,
        endMinDate: date,
        reminderMinDate: date,
        reminderMaxDate: endDate,
      };
    }
    /**
     * @type {import("../../../../../../types/documentActions").DatesByDocumnetTypeI}
     */
    const datesByDoc = {
      ...datesByDocumentType,
      endDate: {
        ...datesByDocumentType.endDate,
        disable: true,
      },
    };
    setDatesByDocumentType(datesByDoc);
    dispatch({
      type: ACTIONS.SET_GENERATE_CONTRACT,
      payload: changeBeginDate,
    });
  };

  /**
   * Handle the change of the end date
   * @param {string} date The end date
   */

  const handleOnEndDateChange = (date) => {
    /**
     * @type{import("../../../../../../types/documentActions").GenerateContractI}
     */
    let changeEndDate;
    // const newReminderDate = calculateReminderDate(beginDate, date);
    changeEndDate = {
      ...document.generateContract,
      endDate: date,
      // reminderDate: newReminderDate,
      reminderMaxDate: date,
    };
    /**
     * @type {import("../../../../../../types/documentActions").DatesByDocumnetTypeI}
     */
    const datesByDoc = {
      ...datesByDocumentType,
      reminderDate: {
        ...datesByDocumentType.reminderDate,
        disable: true,
      },
    };

    dispatch({
      type: ACTIONS.SET_GENERATE_CONTRACT,
      payload: changeEndDate,
    });
    setDatesByDocumentType(datesByDoc);
  };

  /**
   * Handle the change of the reminder date
   * @param {string} date  Reminder date
   */
  const handleOnReminderDateChange = (date) => {
    dispatch({
      type: ACTIONS.SET_GENERATE_CONTRACT,
      payload: {
        ...document.generateContract,
        reminderDate: date,
      },
    });
  };
  /**
   *
   * @param {import("../../../../../../types/documentActions").ItemSelectI } item
   */
  const handleItemDescriptionChange = (item) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI }
     */
    let currentItem;

    const isNew = !!item
      ? Object.keys(item).includes("__isNew__")
        ? true
        : false
      : false;
    const iva0 = {
      exempt: false,
      number: 0,
      text: "0%",
      withIva: {
        cu: {
          number: 0,
          text: "$0.00",
        },
        pu: {
          number: 0,
          text: "$0.00",
        },
      },
    };

    if (!!item) {
      if (isNew) {
        currentItem = {
          ...document.currentItem,
          ...item,
          isNewItem: isNew,
          isBy: "none",
          description: item.label,
          catalogue: {
            currency: "HOla",
          },
          iva: item.iva,
          // iva:
          //   document?.customer.rfc === "XEXX010101000" && documentType === 3
          //     ? iva0
          //     : item.iva,
        };
      } else {
        currentItem = {
          ...documentInitialState.currentItem,
          ...item,
          catalogue: {
            ...item.catalogue,
            currency: item.catalogue.currency,
          },

          isBy: "description",
          iva: item.iva,
          // iva:
          //   document?.customer.rfc === "XEXX010101000" && documentType === 3
          //     ? iva0
          //     : item.iva,
        };
      }
    } else {
      currentItem = {
        ...documentInitialState.currentItem,
        isBy: "none",
        iva: item.iva,
        // iva:
        //   document?.customer.rfc === "XEXX010101000" && documentType === 3
        //     ? iva0
        //     : item.iva,
      };
    }
    dispatch({
      type: ACTIONS.SET_CURRENT_ITEM,
      payload: currentItem,
    });
  };
  /**
   *
   * @param {import("../../../../../../types/documentActions").ItemSelectI } item
   */
  const handleItemSkuChange = (item) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI }
     */
    let currentItem;

    const isNew = !!item
      ? Object.keys(item).includes("__isNew__")
        ? true
        : false
      : false;

    if (!!item) {
      if (isNew) {
        currentItem = {
          ...document.currentItem,
          ...item,
          isNewItem: isNew,
          isBy: "none",
          sku: item.label,
        };
      } else {
        currentItem = {
          ...documentInitialState.currentItem,
          ...item,
          catalogue: {
            ...item.catalogue,
            currency: item.catalogue.currency,
          },
          isBy: "sku",
        };
      }
    } else {
      currentItem = {
        ...documentInitialState.currentItem,
        isBy: "none",
      };
    }
    dispatch({
      type: ACTIONS.SET_CURRENT_ITEM,
      payload: currentItem,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").ItemSelectI} item
   */
  const handleOnCraeteNewItem = (item) => {
    const currentNewItem = {
      ...document.currentItem,
      item,
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: currentNewItem,
    });
  };

  /**
   *
   * @param {string} key
   */
  const handleOnKeyItemChange = (key) => {
    const item = {
      ...document.currentItem,
      sku: key,
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").Currency} currency
   */
  const handleOnCurrencyItemChage = (currency) => {
    const currentCu = document.currentItem.cu;
    const currentPu = document.currentItem.pu;

    if (currency.code === "MXN") {
      /**
       * @type{import("../../../../../../types/documentActions").ItemSelectI}
       */
      const item = {
        ...document.currentItem,
        currency: {
          code: currency.code,
          symbol: currency.symbol,
          description: currency.description,
        },
        catalogue: {
          ...document.currentItem.catalogue,
          currency: {
            code: "MXN",
            description: "Modena mexicana",
            symbol: "$",
          },
        },
        mxn: {
          pu: {
            ...document.currentItem.mxn?.pu,
            ...currentPu,
          },
          cu: {
            ...document.currentItem.mxn?.cu,
            ...currentCu,
          },
        },
        usd: {
          pu: {
            ...document.currentItem.usd?.pu,
            number: calculateMxnToUsd(currentPu?.number),
            text: mnCurrency(calculateMxnToUsd(currentPu?.number)),
            total: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.pu.total.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.pu.total.number)
              ),
            },
            totalIva: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.pu.totalIva.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.pu.totalIva.number)
              ),
            },
            totalUnit: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.pu.totalUnit.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.pu.totalUnit.number)
              ),
            },
          },
          cu: {
            ...document.currentItem.usd?.cu,
            number: calculateMxnToUsd(currentCu?.number),
            text: mnCurrency(calculateMxnToUsd(currentCu?.number)),
            total: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.cu.total.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.cu.total.number)
              ),
            },
            totalIva: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.cu.totalIva.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.cu.totalIva.number)
              ),
            },
            totalUnit: {
              number: calculateMxnToUsd(
                document.currentItem.mxn?.cu.totalUnit.number
              ),
              text: mnCurrency(
                calculateMxnToUsd(document.currentItem.mxn?.cu.totalUnit.number)
              ),
            },
          },
        },
      };
      dispatch({
        type: ACTIONS.SET_NEW_CURRENT_ITEM,
        payload: item,
      });
    } else {
      const item = {
        ...document.currentItem,
        currency: {
          code: currency.code,
          symbol: currency.symbol,
          description: currency.description,
        },
        catalogue: {
          ...document.currentItem.catalogue,
          currency: {
            code: "USD",
            description: "Modena americana",
            symbol: "$",
          },
        },
        mxn: {
          pu: {
            ...document.currentItem.mxn?.pu,
            number: calculateUsdToMxn(currentPu?.number),
            text: mnCurrency(calculateUsdToMxn(currentPu?.number)),
            total: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.pu.total.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.pu.total.number)
              ),
            },
            totalIva: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.pu.totalIva.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.pu.totalIva.number)
              ),
            },
            totalUnit: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.pu.totalUnit.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.pu.totalUnit.number)
              ),
            },
          },
          cu: {
            ...document.currentItem.mxn?.cu,
            number: calculateUsdToMxn(currentCu?.number),
            text: mnCurrency(calculateUsdToMxn(currentCu?.number)),
            total: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.cu.total.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.cu.total.number)
              ),
            },
            totalIva: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.cu.totalIva.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.cu.totalIva.number)
              ),
            },
            totalUnit: {
              number: calculateUsdToMxn(
                document.currentItem.usd?.cu.totalUnit.number
              ),
              text: mnCurrency(
                calculateUsdToMxn(document.currentItem.usd?.cu.totalUnit.number)
              ),
            },
          },
        },
        usd: {
          pu: {
            ...document.currentItem.usd?.pu,
            ...currentPu,
          },
          cu: {
            ...document.currentItem.usd?.cu,
            ...currentCu,
          },
        },
      };
      dispatch({
        type: ACTIONS.SET_NEW_CURRENT_ITEM,
        payload: item,
      });
    }
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").UENSelectI} uen
   */
  const handleOnUenItemChange = (uen) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */
    const item = {
      ...document.currentItem,
      uen: {
        description: uen.description,
        family: uen.family,
        id: uen.UENID,
      },
      satCode: uen.SATcode,
      satCodeDescription: uen.satCodeDescription,
      satUm: uen.SATUM,
      satUmDescription: uen.satUmDescription,
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };
  /**
   *
   * @param {import("../../../../../../types/documentActions").Iva} iva
   */
  const handleOnIvaItemChange = (ivaSelected) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */

    const item = {
      ...document.currentItem,
      iva: {
        exempt: ivaSelected.excento,
        number: ivaSelected.iva,
        text: `${ivaSelected.iva}%`,
      },
    };

    // const { iva } = calculateIVA();
    // const item2 = {
    //   ...item,
    //   iva,
    // };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };

  /**
   *
   * @param {number} cost
   */
  const handleOnCostItemChange = (cost) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */

    const item = {
      ...document.currentItem,
      cu: {
        number: cost,
        text: mnCurrency(cost),
      },
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };
  /**
   *
   * @param {number} price
   */
  const handleOnPriceItemChange = (price) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */
    const item = {
      ...document.currentItem,
      pu: {
        number: price,
        text: mnCurrency(price),
      },
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };

  /**
   *
   * @param {number} quantity
   */
  const handleOnQuantityItemChange = (quantity) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */
    const item = {
      ...document.currentItem,
      quantity,
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").DiscauntInputI} discount
   */
  const handleOnProviderDiscountItemChange = (discount) => {
    const item = {
      ...document.currentItem,
      providerDiscount: {
        number: discount.floatValue || 0,
        text: discount.formattedValue || "0%",
      },
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };
  /**
   *
   * @param {import("../../../../../../types/documentActions").DiscauntInputI} discount
   */
  const handleOnClientDiscountItemChange = (discount) => {
    /**
     * @type{import("../../../../../../types/documentActions").ItemSelectI}
     */
    const item = {
      ...document.currentItem,
      clientDiscoount: {
        number: discount.floatValue || 0,
        text: discount.formattedValue || "0%",
      },
    };
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: item,
    });
  };

  const handleTotalImports = (decimals = 2) => {
    // debugger;

    const currentPu =
      truncateDecimals(document.currentItem?.pu, decimals) ||
      document.currentItem?.pu;
    const currentCu =
      truncateDecimals(document.currentItem?.cu, decimals) ||
      document.currentItem?.cu;

    const quantity = document.currentItem?.quantity;

    const puPercentageDiscount = truncateDecimals(
      document.currentItem?.clientDiscoount?.number / 100,
      decimals
    );
    const cuPercentageDiscount = truncateDecimals(
      document.currentItem?.providerDiscount?.number / 100,
      decimals
    );

    const puDiscount = truncateDecimals(
      puPercentageDiscount * currentPu.number,
      decimals
    );
    const cuDiscount = truncateDecimals(
      cuPercentageDiscount * currentCu.number,
      decimals
    );

    const puIVA = truncateDecimals(
      truncateDecimals(document.currentItem?.iva?.number / 100, decimals) *
        currentPu.number,
      decimals
    );
    const cuIVA = truncateDecimals(
      truncateDecimals(document.currentItem?.iva?.number / 100, decimals) *
        currentCu.number,
      decimals
    );

    const puSell = truncateDecimals(
      currentPu.number * truncateDecimals(1 - puPercentageDiscount, decimals),
      decimals
    );
    const cuCost = truncateDecimals(
      currentCu.number * truncateDecimals(1 - cuPercentageDiscount, decimals),
      decimals
    );

    const puTotal = truncateDecimals(puSell * quantity, decimals);
    const cuTotal = truncateDecimals(cuCost * quantity, decimals);

    const sellIvaTotal = truncateDecimals(
      truncateDecimals(document.currentItem?.iva?.number / 100, decimals) *
        puTotal,
      decimals
    );
    const costIvaTotal = truncateDecimals(
      truncateDecimals(document.currentItem?.iva?.number / 100, decimals) *
        cuTotal,
      decimals
    );
    /**
     * @type{import("../../../../../../types/documentActions").mxnANDusdI}
     */
    let mxn = {};
    /**
     * @type{import("../../../../../../types/documentActions").mxnANDusdI}
     */
    let usd = {};
    if (!!document.currentItem.currency) {
      if (document.currentItem.currency.code === "MXN") {
        mxn = {
          pu: {
            ...currentPu,
            iva: transformToNumberAndCurrency(puIVA),
            totalIva: transformToNumberAndCurrency(sellIvaTotal),
            discount: transformToNumberAndCurrency(puDiscount),
            totalUnit: transformToNumberAndCurrency(puSell),
            total: transformToNumberAndCurrency(puTotal),
            realUnit: transformToNumberAndCurrency(puTotal),
          },
          cu: {
            ...currentCu,
            iva: transformToNumberAndCurrency(cuIVA),
            totalIva: transformToNumberAndCurrency(costIvaTotal),
            discount: transformToNumberAndCurrency(cuDiscount),
            totalUnit: transformToNumberAndCurrency(cuCost),
            total: transformToNumberAndCurrency(cuTotal),
            realUnit: transformToNumberAndCurrency(cuTotal),
          },
        };
        usd = {
          pu: {
            number: calculateMxnToUsd(currentPu?.number),
            text: mnCurrency(calculateMxnToUsd(currentPu?.number)),
            iva: transformToNumberAndCurrency(calculateMxnToUsd(puIVA)),
            totalIva: transformToNumberAndCurrency(
              calculateMxnToUsd(sellIvaTotal)
            ),
            discount: transformToNumberAndCurrency(
              calculateMxnToUsd(puDiscount)
            ),
            totalUnit: transformToNumberAndCurrency(calculateMxnToUsd(puSell)),
            total: transformToNumberAndCurrency(calculateMxnToUsd(puTotal)),
            realUnit: transformToNumberAndCurrency(calculateMxnToUsd(puTotal)),
          },
          cu: {
            number: calculateMxnToUsd(currentCu?.number),
            text: mnCurrency(calculateMxnToUsd(currentCu?.number)),
            iva: transformToNumberAndCurrency(calculateMxnToUsd(cuIVA)),
            totalIva: transformToNumberAndCurrency(
              calculateMxnToUsd(costIvaTotal)
            ),
            discount: transformToNumberAndCurrency(
              calculateMxnToUsd(cuDiscount)
            ),
            totalUnit: transformToNumberAndCurrency(calculateMxnToUsd(cuCost)),
            total: transformToNumberAndCurrency(calculateMxnToUsd(cuTotal)),
            realUnit: transformToNumberAndCurrency(calculateMxnToUsd(cuTotal)),
          },
        };
      } else {
        mxn = {
          pu: {
            number: calculateUsdToMxn(currentPu?.number),
            text: mnCurrency(calculateUsdToMxn(currentPu?.number)),
            iva: transformToNumberAndCurrency(calculateUsdToMxn(puIVA)),
            totalIva: transformToNumberAndCurrency(
              calculateUsdToMxn(sellIvaTotal)
            ),
            discount: transformToNumberAndCurrency(
              calculateUsdToMxn(puDiscount)
            ),
            totalUnit: transformToNumberAndCurrency(calculateUsdToMxn(puSell)),
            total: transformToNumberAndCurrency(calculateUsdToMxn(puTotal)),
            realUnit: transformToNumberAndCurrency(calculateUsdToMxn(puTotal)),
          },
          cu: {
            number: calculateUsdToMxn(currentCu?.number),
            text: mnCurrency(calculateUsdToMxn(currentCu?.number)),
            iva: transformToNumberAndCurrency(calculateUsdToMxn(cuIVA)),
            totalIva: transformToNumberAndCurrency(
              calculateUsdToMxn(costIvaTotal)
            ),
            discount: transformToNumberAndCurrency(
              calculateUsdToMxn(cuDiscount)
            ),
            totalUnit: transformToNumberAndCurrency(calculateUsdToMxn(cuCost)),
            total: transformToNumberAndCurrency(calculateUsdToMxn(cuTotal)),
            realUnit: transformToNumberAndCurrency(calculateUsdToMxn(cuTotal)),
          },
        };
        usd = {
          pu: {
            ...currentPu,
            iva: transformToNumberAndCurrency(puIVA),
            totalIva: transformToNumberAndCurrency(sellIvaTotal),
            discount: transformToNumberAndCurrency(puDiscount),
            totalUnit: transformToNumberAndCurrency(puSell),
            total: transformToNumberAndCurrency(puTotal),
            realUnit: transformToNumberAndCurrency(puTotal),
          },
          cu: {
            ...currentCu,
            iva: transformToNumberAndCurrency(cuIVA),
            totalIva: transformToNumberAndCurrency(costIvaTotal),
            discount: transformToNumberAndCurrency(cuDiscount),
            totalUnit: transformToNumberAndCurrency(cuCost),
            total: transformToNumberAndCurrency(cuTotal),
            realUnit: transformToNumberAndCurrency(cuTotal),
          },
        };
      }
      const item = {
        ...document.currentItem,
        mxn,
        usd,
      };

      console.log({ item });

      dispatch({
        type: ACTIONS.SET_NEW_CURRENT_ITEM,
        payload: item,
      });
    }
  };

  /**
   *
   * @returns {import("../../../../../../types/documentActions").ImportsMoneyI}
   */

  const calculateExchangeTc = () => {
    const currentCu = document.currentItem.cu;
    const currentPu = document.currentItem.pu;
    const { cuWithIVa, puWithIVa } = calculateIVAV2();

    let mxn = {};
    let usd = {};
    if (!!document.currentItem.currency) {
      if (document.currentItem.currency.code === "MXN") {
        mxn = {
          pu: {
            ...currentPu,
            iva: {
              number: puWithIVa,
              text: mnCurrency(puWithIVa),
            },
          },
          cu: {
            ...currentCu,
            iva: {
              number: cuWithIVa,
              text: mnCurrency(cuWithIVa),
            },
          },
        };
        usd = {
          pu: {
            number: calculateMxnToUsd(currentPu?.number),
            text: mnCurrency(calculateMxnToUsd(currentPu?.number)),
            iva: {
              number: calculateMxnToUsd(puWithIVa),
              text: mnCurrency(calculateMxnToUsd(puWithIVa)),
            },
          },
          cu: {
            number: calculateMxnToUsd(currentCu?.number),
            text: mnCurrency(calculateMxnToUsd(currentCu?.number)),
            iva: {
              number: calculateMxnToUsd(cuWithIVa),
              text: mnCurrency(calculateMxnToUsd(cuWithIVa)),
            },
          },
        };
      } else {
        mxn = {
          pu: {
            number: calculateUsdToMxn(currentPu?.number),
            text: mnCurrency(calculateUsdToMxn(currentPu?.number)),
            iva: {
              number: calculateUsdToMxn(puWithIVa),
              text: mnCurrency(calculateUsdToMxn(puWithIVa)),
            },
          },
          cu: {
            number: calculateUsdToMxn(currentCu?.number),
            text: mnCurrency(calculateUsdToMxn(currentCu?.number)),
            iva: {
              number: calculateUsdToMxn(cuWithIVa),
              text: mnCurrency(calculateUsdToMxn(cuWithIVa)),
            },
          },
        };
        usd = {
          pu: {
            ...currentPu,
            iva: {
              number: puWithIVa,
              text: mnCurrency(puWithIVa),
            },
          },
          cu: {
            ...currentCu,
            iva: {
              number: cuWithIVa,
              text: mnCurrency(cuWithIVa),
            },
          },
        };
      }
      return {
        mxn,
        usd,
      };
    }
  };

  /**
   * @param {number} importe
   * @return {number}
   */
  const calculateMxnToUsd = (importe, decimals = 2) => {
    if (document.moneyInfo.tc.number !== undefined) {
      return truncateDecimals(importe / document.moneyInfo.tc.number, decimals);
    }
  };

  /**
   * @param {number} importe
   * @return {number}
   */
  const calculateUsdToMxn = (importe, decimals = 2) => {
    if (document.moneyInfo.tc.number !== undefined) {
      return truncateDecimals(importe * document.moneyInfo.tc.number, decimals);
    }
  };

  const onCloseCurrentItem = () => {
    dispatch({
      type: ACTIONS.SET_NEW_CURRENT_ITEM,
      payload: {
        ...documentInitialState.currentItem,
      },
    });
  };

  /**
   *
   * @returns {string[]}
   */
  const validateData = () => {
    const currency = document.currentItem?.currency?.code;

    const puMxn = document.currentItem?.mxn?.pu?.total.number;
    const puUSD = document.currentItem?.usd?.pu?.total.number;

    const cuMxn = document.currentItem?.mxn?.cu?.total.number;
    const cuUSD = document.currentItem?.usd?.cu?.total.number;

    let messageError = [];
    if (!document.currentItem.sku) {
      messageError.push("Falta la clave");
    }
    if (!document.currentItem.description) {
      messageError.push("Falta la descripcion");
    }
    if (!document.currentItem.currency) {
      messageError.push("Falta la moneda");
    }
    if (!document.currentItem.uen) {
      messageError.push("Falta la UEN");
    }
    if (!document.currentItem.cu?.number) {
      messageError.push("Falta costo unitario");
    }
    if (!document.currentItem.pu?.number) {
      messageError.push("Falta precio unitario");
    }
    if (!document.currentItem.quantity) {
      messageError.push("Falta cantidad");
    }

    if (currency === "MXN") {
      if (puMxn - cuMxn <= 0) {
        messageError.push(
          `El margen no es correcto ${mnCurrency(puMxn - cuMxn)}`
        );
      }
    } else {
      if (puUSD - cuUSD <= 0) {
        messageError.push(
          `El margen no es correcto ${mnCurrency(puUSD - cuUSD)}`
        );
      }
    }
    return messageError;
  };

  /**
   *
   * @param {string[]} messageError
   * @returns {string} html string
   */
  const htmlMessageError = (messageError) => {
    const liElemt = messageError.map((element) => {
      return `
     <li>
       ${element}
     </li>
     `;
    });

    const htmlError = `
    <ul>
      ${liElemt}
    </ul>
    `;
    return htmlError;
  };

  /**
   * Validates if the product in the catalog was edited the price, cost and / or IVA
   * @param {import("../../../../../../types/documentActions").ItemSelectI} item
   */
  const validateUpdateItem = (item) => {
    if (!!item) {
      if (
        item.catalogue?.cu !== item.cu.number ||
        item.catalogue?.pu !== item.pu.number ||
        item.catalogue?.iva !== item.iva.number
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const updateToCatague = (item) => {
    dispatch({
      type: ACTIONS.SET_NEW_ITEM,
      payload: [...document.newItems, item],
    });
  };

  const addItem = () => {
    console.log('Agregando item')
    const messageError = validateData();
    if (messageError.length > 0) {
      console.log('se encontro un error');
      const htmlError = htmlMessageError(messageError);
      ErrorAlertText(
        () => {},
        "Ups! No se puede agregar el articulo",
        "Faltan las siguientes porpiedades",
        htmlError
      );
      return false;
    } else {
      console.log("🚀 ~ addItem ~ document.currentItem.currency?.code", document.currentItem.currency?.code === "MXN" ? 1 : 2)
      if (document.items.length === 0) {

        dispatch({
          type: ACTIONS.SET_MONEY_INFORMATION,
          payload: {
            ...document.moneyInfo,
            currency: {
              id: document.currentItem.currency?.code === "MXN" ? 1 : 2,
              value: document.currentItem.currency?.code,
            },
          },
        });
      }

      const currentItem = document.currentItem;

      const isNewItem = Object.keys(currentItem).includes("id") ? false : true;

      const item = {
        ...currentItem,
        uuid: uuidv4(),
      };

      if (isNewItem) {
        // if (!currentItem.id) {
        // YesNoAlert(
        //   "Desea actualizar el articulo en el catalogo",
        //   () => {
        //     dispatch({
        //       type: ACTIONS.SET_NEW_ITEM,
        //       payload: [...document.newItems, item],
        //     });
        //   },
        //   () => {}
        // );
        dispatch({
          type: ACTIONS.SET_NEW_ITEM,
          payload: [item, ...document.newItems],
        });
        dispatch({
          type: ACTIONS.SET_ITEMS,
          payload: [item, ...document.items],
        });
        return true;
      } else {
        dispatch({
          type: ACTIONS.SET_ITEMS,
          payload: [item, ...document.items],
        });
      }

      if (validateUpdateItem(document.currentItem)) {
        YesNoAlert(
          "Desea actualizar el articulo en el catalogo.",
          () => {
            dispatch({
              type: ACTIONS.SET_EDIT_CATALOGUE,
              payload: [item, ...document.itemsToEdit],
            });
          },
          () => {}
        );
        return true;
      } else {
        // handleOnDeleteCatalogueChanges(document.currentItem.uuid);
        return true;
      }
    }
  };

  /**
   * Edit an item
   * @returns {boolean}
   */
  const editItem = () => {
    const messageError = validateData();
    if (messageError.length > 0) {
      const htmlError = htmlMessageError(messageError);
      ErrorAlertText(
        () => {},
        "Ups! No se puede actualizar el articulo",
        "Faltan las siguientes porpiedades",
        htmlError
      );
      return false;
    } else {
      dispatch({
        type: ACTIONS.SET_EDIT_ITEM,
        payload: document.currentItem,
      });

      if (document.currentItem?.isNewItem) {
        dispatch({
          type: ACTIONS.SET_EDIT_NEW_ITEM,
          payload: document.currentItem,
        });
        return true;
      } else {
        if (validateUpdateItem(document.currentItem)) {
          YesNoAlert(
            "Desea actualizar el articulo en el catalogo",
            () => {
              dispatch({
                type: ACTIONS.SET_EDIT_CATALOGUE,
                payload: [...document.itemsToEdit, document.currentItem],
              });
            },
            () => {}
          );
          return true;
        } else {
          handleOnDeleteCatalogueChanges(document.currentItem.uuid);
          return true;
        }
      }
    }
  };

  /**
   *
   * @returns {{puWithIVa:number,cuWithIVa:number}}
   */
  const calculateIVAV2 = () => {
    const isForeignRfc =
      document.customer.rfc === "XEXX010101000" && documentType === 3;

    const baseIva = isForeignRfc ? 0 : document.currentItem.iva?.number / 100;

    const currentIva = baseIva;
    const Pu = document.currentItem.pu?.number;
    const Cu = document.currentItem.cu?.number;

    const clientDiscountPercentage = !!document.currentItem.discount?.client
      .discount.number
      ? document.currentItem.discount?.client.discount.number
      : 0;
    const providerDiscountPercentage = !!document.currentItem.discount?.provider
      .discount.number
      ? document.currentItem.discount?.provider.discount.number
      : 0;

    const clientDiscount = 1 - clientDiscountPercentage / 100;
    const providerDiscount = 1 - providerDiscountPercentage / 100;

    const puWithIVa =
      Pu * clientDiscount * document.currentItem?.quantity * currentIva;
    const cuWithIVa =
      Cu * providerDiscount * document.currentItem?.quantity * currentIva;

    return {
      puWithIVa,
      cuWithIVa,
    };
  };

  /**
   * Opens the modal loading the current item with the item selected
   * @param {{target:{id:number}}} value
   */
  const handelOnLoadCurrentItem = ({ target: { id } }) => {
    const currentItem = document.items[id];

    dispatch({
      type: ACTIONS.SET_CURRENT_ITEM,
      payload: {
        ...currentItem,
        index: id,
      },
    });
    doOpenModal({ isEditing: true });
  };

  /**
   *
   * @param {boolean} isEditing
   */
  const doOpenModal = ({ isEditing = false }) => {
    dispatch({
      type: ACTIONS.SET_MODAL,
      payload: {
        openModal: true,
        isEditing,
      },
    });
  };

  const doCloseModal = ({ isEditing = false }) => {
    dispatch({
      type: ACTIONS.SET_MODAL,
      payload: {
        openModal: false,
        isEditing,
      },
    });
  };

  /**
   * Delte an item
   * @param {number} index - Index position of the item on the array of elements
   */
  const handleOnDeleteItem = (index) => {
    dispatch({
      type: ACTIONS.SET_DELETE_ITEM,
      payload: index,
    });
  };

  /**
   * Handle the change of the TC
   * @param {number} value
   */
  const handleOnChangeTc = (value) => {
    const moneyInfo = document.moneyInfo;

    const changeTc = {
      ...moneyInfo,
      tc: {
        number: truncateDecimals(value, 2),
        text: mnCurrency(value),
      },
    };
    console.log('cambiando el currency cuando se cambia el tipo de cambio');

    dispatch({
      type: ACTIONS.SET_MONEY_INFORMATION,
      payload: changeTc,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").HandleCurrencyExchange} currency
   */
  const handleOnDocumentCurrencyChange = (currency) => {


    // const moneyInfo = document.moneyInfo;
    /**
     * @type{import("../../../../../../types/documentActions").MoneyInformationI}
     */
    // const newCurrency = {
    //   ...moneyInfo,
    //   currency,
    // };

    dispatch({
      type: ACTIONS.SET_CURRENCY_MONEY_DOCUMENT,
      payload: currency,
    });

  };

  const recalculate = () => {
    /**
     * @type {import("../Items/types").CatalogueOperation[]}
     */
    const currentItems = document.items;

    return currentItems.map((item) =>
      handleFormItem(
        {
          cost: item.cu.number,
          currency: item.currency.code,
          description: item.description,
          discountCost: item.providerDiscount.number,
          discountSell: item.clientDiscoount.number,
          idCatalogue: item.catalogue.id,
          iva: item.iva.number,
          ivaExempt: item.iva.exempt,
          quantity: item.quantity,
          satCode: item.satCode,
          satUm: item.satUm,
          sell: item.pu.number,
          sku: item.sku,
          uen: item.uen.id,
          satCodeDescription: `${item.satCodeDescription}`,
          satUmDescription: `${item.satUmDescription}`,
        },
        document.moneyInfo.tc.number,
        2,
        {
          rfc: document?.customer.rfc,
          typeDocument: documentType,
        }
      )
    );

    ///////////////////////

    /**
     * @type {import("./types").CalculationItem[]}
     */
    const newItems = [];

    const isForeignRfc =
      document?.customer.rfc === "XEXX010101000" && documentType === 3;

    currentItems.forEach(
      /**
       *
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => {
        /**
         *
         * @type {import("../../../../../../types/documentActions").ItemSelectI} item
         */
        let recalculatedItem = item;

        if (item.currency.code === "MXN") {
          recalculatedItem = {
            ...item,
            usd: {
              cu: {
                number: calculateMxnToUsd(item.mxn.cu.number),
                text: mnCurrency(calculateMxnToUsd(item.mxn.cu.number)),
                discount: {
                  number: calculateMxnToUsd(item.mxn.cu.discount.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.cu.discount.number)
                  ),
                },
                iva: {
                  number: calculateMxnToUsd(item.mxn.cu.iva.number),
                  text: mnCurrency(calculateMxnToUsd(item.mxn.cu.iva.number)),
                },
                total: {
                  number: calculateMxnToUsd(item.mxn.cu.total.number),
                  text: mnCurrency(calculateMxnToUsd(item.mxn.cu.total.number)),
                },
                totalUnit: {
                  number: calculateMxnToUsd(item.mxn.cu.totalUnit.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.cu.totalUnit.number)
                  ),
                },
                totalIva: {
                  number: calculateMxnToUsd(item.mxn.cu.totalIva.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.cu.totalIva.number)
                  ),
                },
                realUnit: {
                  number: calculateMxnToUsd(item.mxn.cu.realUnit.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.cu.realUnit.number)
                  ),
                },
              },
              pu: {
                number: calculateMxnToUsd(item.mxn.pu.number),
                text: mnCurrency(calculateMxnToUsd(item.mxn.pu.number)),
                discount: {
                  number: calculateMxnToUsd(item.mxn.pu.discount.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.pu.discount.number)
                  ),
                },
                iva: {
                  number: calculateMxnToUsd(item.mxn.pu.iva.number),
                  text: mnCurrency(calculateMxnToUsd(item.mxn.pu.iva.number)),
                },
                totalIva: {
                  number: calculateMxnToUsd(item.mxn.pu.totalIva.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.pu.totalIva.number)
                  ),
                },
                total: {
                  number: calculateMxnToUsd(item.mxn.pu.total.number),
                  text: mnCurrency(calculateMxnToUsd(item.mxn.pu.total.number)),
                },
                totalUnit: {
                  number: calculateMxnToUsd(item.mxn.pu.totalUnit.number),
                  text: mnCurrency(calculateMxnToUsd(item.mxn.pu.total.number)),
                },
                realUnit: {
                  number: calculateMxnToUsd(item.mxn.pu.realUnit.number),
                  text: mnCurrency(
                    calculateMxnToUsd(item.mxn.pu.realUnit.number)
                  ),
                },
              },
            },
            mxn: {
              ...item.mxn,
              cu: {
                ...item.mxn.cu,
                totalIva: {
                  number: truncateDecimals(
                    item.mxn.cu.iva.number * item.quantity,
                    2
                  ),
                  text: mnCurrency(item.mxn.cu.iva.number * item.quantity, 2),
                },
              },
            },
          };
          newItems.push(recalculatedItem);
        } else {
          recalculatedItem = {
            ...item,
            mxn: {
              cu: {
                number: calculateUsdToMxn(item.usd.cu.number),
                text: mnCurrency(calculateUsdToMxn(item.usd.cu.number)),
                discount: {
                  number: calculateUsdToMxn(item.usd.cu.discount.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.cu.discount.number)
                  ),
                },
                iva: {
                  number: calculateUsdToMxn(item.usd.cu.iva.number),
                  text: mnCurrency(calculateUsdToMxn(item.usd.cu.iva.number)),
                },
                totalIva: {
                  number: calculateUsdToMxn(item.usd.cu.totalIva.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.cu.totalIva.number)
                  ),
                },
                total: {
                  number: calculateUsdToMxn(item.usd.cu.total.number),
                  text: mnCurrency(calculateUsdToMxn(item.usd.cu.total.number)),
                },
                totalUnit: {
                  number: calculateUsdToMxn(item.usd.cu.totalUnit.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.cu.totalUnit.number)
                  ),
                },
                realUnit: {
                  number: calculateUsdToMxn(item.usd.cu.realUnit?.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.cu.realUnit?.number)
                  ),
                },
              },
              pu: {
                number: calculateUsdToMxn(item.usd.pu.number),
                text: mnCurrency(calculateUsdToMxn(item.usd.pu.number)),
                discount: {
                  number: calculateUsdToMxn(item.usd.pu.discount.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.pu.discount.number)
                  ),
                },
                iva: {
                  number: calculateUsdToMxn(item.usd.pu.iva.number),
                  text: mnCurrency(calculateUsdToMxn(item.usd.pu.iva.number)),
                },
                totalIva: {
                  number: calculateUsdToMxn(item.usd.pu.totalIva.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.pu.totalIva.number)
                  ),
                },
                total: {
                  number: calculateUsdToMxn(item.usd.pu.total.number),
                  text: mnCurrency(calculateUsdToMxn(item.usd.pu.total.number)),
                },
                totalUnit: {
                  number: calculateUsdToMxn(item.usd.pu.totalUnit.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.pu.totalUnit.number)
                  ),
                },
                realUnit: {
                  number: calculateUsdToMxn(item.usd.pu.realUnit?.number),
                  text: mnCurrency(
                    calculateUsdToMxn(item.usd.pu.realUnit?.number)
                  ),
                },
              },
            },
            usd: {
              ...item.usd,
              cu: {
                ...item.usd.cu,
                totalIva: {
                  number: truncateDecimals(
                    item.usd.cu.iva.number * item.quantity,
                    2
                  ),
                  text: mnCurrency(item.usd.cu.iva.number * item.quantity, 2),
                },
              },
            },
          };
          newItems.push(recalculatedItem);
        }
      }
    );

    if (isForeignRfc) {
      return newItems.map((item) => ({
        ...item,
        mxn: {
          ...item.mxn,
          cu: {
            ...item.mxn.cu,
            totalIva: {
              number: 0,
              text: "$0.00",
            },
          },
          pu: {
            ...item.mxn.pu,
            totalIva: {
              number: 0,
              text: "$0.00",
            },
          },
        },
        usd: {
          ...item.usd,
          pu: {
            ...item.usd.pu,

            totalIva: {
              number: 0,
              text: "$0.00",
            },
          },
          cu: {
            ...item.usd.cu,
            totalIva: {
              number: 0,
              text: "$0.00",
            },
          },
        },
      }));
    }

    return newItems;
  };

  /**
   *Returns the sum of all the subtotals
   * @returns {{
   * totalPuMxn: number,totalPuUsd: number,totalCuMxn: number,totalCuUsd:number,realMxnMargin:number,realUsdMargin:number
   * }}
   */
  const ImportDocument = () => {
    const totalPuMxn = sumTotal(document.items, "mxn", "pu");
    const totalPuUsd = sumTotal(document.items, "usd", "pu");
    const totalCuMxn = sumTotal(document.items, "mxn", "cu");
    const totalCuUsd = sumTotal(document.items, "usd", "cu");
    const realMxnMargin = calculateDocumentMargin(document, "MXN");
    const realUsdMargin = calculateDocumentMargin(document, "USD");

    return {
      totalPuMxn: truncateDecimals(totalPuMxn, 2),
      totalPuUsd: truncateDecimals(totalPuUsd, 2),
      totalCuMxn: truncateDecimals(totalCuMxn, 2),
      totalCuUsd: truncateDecimals(totalCuUsd, 2),
      realMxnMargin: truncateDecimals(realMxnMargin, 2),
      realUsdMargin: truncateDecimals(realUsdMargin, 2),
    };
  };

  /**
   *Return the sum of all the ivas
   * @returns {{ivaPuMxn:number;ivaPuUsd:number;ivaCuMxn:number;ivaCuUsd:number;}}
   */
  const IvaDocument = () => {
    if (document.items.length === 0) {
      return {
        ivaPuMxn: 0,
        ivaPuUsd: 0,
        ivaCuMxn: 0,
        ivaCuUsd: 0,
      };
    }

    const isForeignRfc =
      document.customer?.rfc === "XEXX010101000" && documentType === 3;

    const ivaPuMxn = isForeignRfc
      ? 0
      : document.items
          .map(
            /**
             * @param {import("../../../../../../types/documentActions").ItemSelectI} item
             */
            (item) => item.mxn.pu.totalIva.number
          )
          .reduce((acumulator, item) => {
            return truncateDecimals(acumulator + item, 2);
          });
    const ivaPuUsd = isForeignRfc
      ? 0
      : document.items
          .map(
            /**
             * @param {import("../../../../../../types/documentActions").ItemSelectI} item
             */
            (item) => item.usd.pu.totalIva.number
          )
          .reduce((acumulator, item) => {
            return truncateDecimals(acumulator + item, 2);
          });
    const ivaCuMxn = isForeignRfc
      ? 0
      : document.items
          .map(
            /**
             * @param {import("../../../../../../types/documentActions").ItemSelectI} item
             */
            (item) => item.mxn.cu.totalIva.number
          )
          .reduce((acumulator, item) => {
            return truncateDecimals(acumulator + item, 2);
          });
    const ivaCuUsd = isForeignRfc
      ? 0
      : document.items
          .map(
            /**
             * @param {import("../../../../../../types/documentActions").ItemSelectI} item
             */
            (item) => item.usd.cu.totalIva.number
          )
          .reduce((acumulator, item) => {
            return truncateDecimals(acumulator + item, 2);
          });

    return {
      ivaPuMxn: truncateDecimals(ivaPuMxn, 2),
      ivaPuUsd: truncateDecimals(ivaPuUsd, 2),
      ivaCuMxn: truncateDecimals(ivaCuMxn, 2),
      ivaCuUsd: truncateDecimals(ivaCuUsd, 2),
    };
  };

  /**
   * Return de margin of the document
   * @returns {{marignMXN:number;marginUSD:number;}}
   */
  const MarginDocument = () => {
    const {
      totalCuMxn,
      totalCuUsd,
      totalPuMxn,
      totalPuUsd,
      realMxnMargin,
      realUsdMargin,
    } = ImportDocument();
    const margenMxn = totalPuMxn - totalCuMxn;
    const margenUsd = totalPuUsd - totalCuUsd;

    // const marignMXN = truncateDecimals(totalPuMxn - totalCuMxn, 2);
    // const marginUSD = truncateDecimals(totalPuUsd - totalCuUsd, 2);
    const marignMXN =
      realMxnMargin || truncateDecimals(totalPuMxn - totalCuMxn, 2);
    const marginUSD =
      realUsdMargin || truncateDecimals(totalPuUsd - totalCuUsd, 2);

    /**
     * @type {import("../Items/types").CatalogueOperation[]}
     */
    const items = document.items;

    const totals = items.reduce(
      (totals, item) => {
        return {
          marginMXN:
            totals.marginMXN +
            (item.mxn.pu.realUnit.number - item.mxn.cu.realUnit.number),
          marginUSD:
            totals.marginUSD +
            (item.usd.pu.realUnit.number - item.usd.cu.realUnit.number),
        };
      },
      {
        marginMXN: 0,
        marginUSD: 0,
      }
    );

    return {
      marginUSD: truncateDecimals(totals.marginUSD, 2),
      marignMXN: truncateDecimals(totals.marginMXN, 2),
    };

    return {
      marignMXN,
      marginUSD,
    };
  };

  /**
   * Returns the total of the document (import + iva)
   * @returns {{TotalCuMXN:number;TotalPuMXN:number;TotalCuUSD:number;TotalPuUSD:number;}}
   */
  const TotalDocument = () => {
    const { totalCuMxn, totalCuUsd, totalPuMxn, totalPuUsd } = ImportDocument();
    const { ivaCuMxn, ivaCuUsd, ivaPuMxn, ivaPuUsd } = IvaDocument();

    const TotalCuMXN = truncateDecimals(totalCuMxn + ivaCuMxn, 2);
    const TotalPuMXN = truncateDecimals(totalPuMxn + ivaPuMxn, 2);
    const TotalCuUSD = truncateDecimals(totalCuUsd + ivaCuUsd, 2);
    const TotalPuUSD = truncateDecimals(totalPuUsd + ivaPuUsd, 2);

    return {
      TotalCuMXN,
      TotalPuMXN,
      TotalCuUSD,
      TotalPuUSD,
    };
  };

  /**
   * Store the sum of the imports, ivas and margins
   */
  const handleCalculateDocumentTotal = (newCureny) => {
    const { totalCuMxn, totalCuUsd, totalPuMxn, totalPuUsd } = ImportDocument();
    const { ivaCuMxn, ivaCuUsd, ivaPuMxn, ivaPuUsd } = IvaDocument();
    const { marginUSD, marignMXN } = MarginDocument();
    const { TotalCuMXN, TotalCuUSD, TotalPuMXN, TotalPuUSD } = TotalDocument();

    /**
     * @type{import("../../../../../../types/documentActions").MoneyInformationI}
     */
    const moneyInfo = {
      ...document.moneyInfo,
      currency: newCureny,
      mxn: {
        importCost: {
          number: totalCuMxn,
          text: mnCurrency(totalCuMxn),
        },
        importSell: {
          number: totalPuMxn,
          text: mnCurrency(totalPuMxn),
        },
        ivaCost: {
          number: ivaCuMxn,
          text: mnCurrency(ivaCuMxn),
        },
        ivaSell: {
          number: ivaPuMxn,
          text: mnCurrency(ivaPuMxn),
        },
        margin: {
          number: marignMXN,
          text: mnCurrency(marignMXN),
        },
        totalCost: {
          number: TotalCuMXN,
          text: mnCurrency(TotalCuMXN),
        },
        totalSell: {
          number: TotalPuMXN,
          text: mnCurrency(TotalPuMXN),
        },
      },
      usd: {
        importCost: {
          number: totalCuUsd,
          text: mnCurrency(totalCuUsd),
        },
        importSell: {
          number: totalPuUsd,
          text: mnCurrency(totalPuUsd),
        },
        ivaCost: {
          number: ivaCuUsd,
          text: mnCurrency(ivaCuUsd),
        },
        ivaSell: {
          number: ivaPuUsd,
          text: mnCurrency(ivaPuUsd),
        },
        margin: {
          number: marginUSD,
          text: mnCurrency(marginUSD),
        },
        totalCost: {
          number: TotalCuUSD,
          text: mnCurrency(TotalCuUSD),
        },
        totalSell: {
          number: TotalPuUSD,
          text: mnCurrency(TotalPuUSD),
        },
      },
    };
    console.log('cambiando el currency cuando se calcula el total del documento');

    dispatch({
      type: ACTIONS.SET_MONEY_INFORMATION,
      payload: moneyInfo,
    });
  };

  /**
   * Get the proability value text
   * @param {number} value
   * @returns string
   */
  const probabilityValueText = (value) => {
    switch (value) {
      case 1:
        return "<50%";

      case 2:
        return "=50%";

      case 3:
        return ">50%";

      case 4:
        return ">70%";

      case 5:
        return "=100%";

      default:
        return "<50%";
    }
  };

  /**
   * Handle the change of the probability
   * @param {number} value The probability id
   */
  const handleOnProbabilityChange = (value) => {
    let probailityValue;
    switch (value) {
      case 1:
        probailityValue = "<50%";
        break;
      case 2:
        probailityValue = "=50%";
        break;
      case 3:
        probailityValue = ">50%";
        break;
      case 4:
        probailityValue = ">70%";
        break;
      case 5:
        probailityValue = "=100%";
        break;
      default:
        probailityValue = "<50%";
        break;
    }

    /**
     * @type{import("../../../../../../types/documentActions").MoneyInformationI}
     */
    const moneyInfo = {
      ...document.moneyInfo,
      probability: {
        id: value,
        value: probailityValue,
      },
    };
    console.log('Caundo cambia la probabilidad');
    dispatch({
      type: ACTIONS.SET_MONEY_INFORMATION,
      payload: moneyInfo,
    });
  };

  /**
   * Handle the change of the comment on the input
   * @param {string} comment
   */
  const handleOnCommentChange = (comment) => {
    /**
     * @type{import("../../../../../../types/documentActions").CommentsI}
     */
    const currentComment = {
      ...document.currentComment,
      isEditable: true,
      isRemovable: true,
      type: 1,
      comment: comment,
    };
    dispatch({
      type: ACTIONS.SET_CURRENT_COMMENT,
      payload: currentComment,
    });
  };
  /**
   * Handle the change of the comment on the input
   * @param {string} comment
   */
  const handleOnCommentChangeMotive = (comment) => {
    /**
     * @type{import("../../../../../../types/documentActions").CommentsI}
     */
    const currentComment = {
      ...document.currentComment,
      isEditable: true,
      isRemovable: true,
      type: 16,
      comment: comment,
    };
    dispatch({
      type: ACTIONS.SET_CURRENT_COMMENT,
      payload: currentComment,
    });
  };

  const addComment = () => {
    if (!!document.currentComment && !!document.currentComment.comment) {
      const comment = {
        ...document.currentComment,
        isEditing: false,
        uuid: uuidv4(),
      };
      dispatch({
        type: ACTIONS.SET_COMMENTS,
        payload: [...document.comments, comment],
      });

      dispatch({
        type: ACTIONS.SET_CURRENT_COMMENT,
        payload: documentInitialState.currentComment,
      });
    }
  };

  const handleEditingComment = (index) => {
    const commentToEdit = document.comments[index];
    dispatch({
      type: ACTIONS.SET_CURRENT_COMMENT,
      payload: {
        ...document.currentComment,
        ...commentToEdit,
        isEditing: true,
      },
    });
  };

  /**
   * Validate input to edit if is true it means needs to edit
   * @param {import("../../../../../../types/documentActions").CommentsI} elementFromTheArray
   * @param {import("../../../../../../types/documentActions").CommentsI} comment
   * @returns {boolean}
   */
  const validateCommentEditing = (elementFromTheArray, comment) => {
    if (comment !== {}) {
      if (!!comment.isEditing && elementFromTheArray.uuid === comment.uuid) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * Edit the current comment
   * @param {number} index Array index
   */
  const editComment = (index) => {
    if (document.currentComment !== {}) {
      if (document.currentComment.isEditing) {
        /**
         * @type{import("../../../../../../types/documentActions").CommentsI}
         */
        const editedComment = {
          ...document.comments[index],
          ...document.currentComment,
          isEditing: false,
          index,
        };

        dispatch({
          type: ACTIONS.SET_EDIT_COMMENT,
          payload: editedComment,
        });
        dispatch({
          type: ACTIONS.SET_CURRENT_COMMENT,
          payload: documentInitialState.currentComment,
        });
      }
    }
  };

  const cancelEditing = () => {
    if (document.currentComment !== {}) {
      if (document.currentComment.isEditing) {
        dispatch({
          type: ACTIONS.SET_CURRENT_COMMENT,
          payload: documentInitialState.currentComment,
        });
      }
    }
  };
  /**
   * Deletes a comment
   * @param {number} index Index value
   */
  const handleOnDeleteComment = (index) => {
    const uuid = document.comments[index].uuid;
    dispatch({
      type: ACTIONS.SET_DELETE_COMMENT,
      payload: index,
    });
  };

  /**
   * Handle the sku editig of new item that has not been added to the catalogue
   * @param {{target:{value:string}}} value
   */
  const handleEditNewItemSku = ({ target: { value } }) => {
    if (!!value) {
      dispatch({
        type: ACTIONS.SET_CURRENT_ITEM,
        payload: {
          ...document.currentItem,
          catalogue: "Nola",
          sku: value,
        },
      });
    }
  };
  /**
   * Handle the description editing of new item that has not been added to the catalogue
   * @param {{target:{value:string}}} value
   */
  const handleEditNewItemDescription = ({ target: { value } }) => {
    dispatch({
      type: ACTIONS.SET_CURRENT_ITEM,
      payload: {
        ...document.currentItem,
        description: value,
      },
    });

    // if (!!value) {
    //   dispatch({
    //     type: ACTIONS.SET_CURRENT_ITEM,
    //     payload: {
    //       ...document.currentItem,
    //       description: value,
    //     },
    //   });
    // }
  };

  const handleOnDeleteCatalogueChanges = (uuid) => {
    dispatch({
      type: ACTIONS.SET_DELETE_CATALOGUE_CHANGES,
      payload: uuid,
    });
  };

  /**
   * Handle the succes of the add a document
   * @param {boolean} wasAdded
   * @param {1|2|3|6} documentType
   * @returns {void}
   */
  const onSuccesAdded = (wasAdded, documentType) => {
    if (wasAdded) {
      switch (documentType) {
        case 1:
          history.push(`/directorio/documentos/${document.customer.id}`);

          break;
        case 2:
          const urls = {
            facturas_emitidas: "/administracion/facturas-emitidas",
            pedidos: "/administracion/pedidos",
          };

          history.push(
            urls[modulo] || `/directorio/documentos/${document.customer.id}`
          );

          break;
        case 3:
          history.push(`/administracion/ordenes-compra`);

          break;
        case 6:
          history.push(`/ventas/contratos`);

          break;

        default:
          break;
      }
      Success(() => {}, "Documento agregado");
      return;
    }

    return;
  };
  /**
   * Handle the succes of the add a document
   * @param {boolean} wasAdded
   * @param {1|2|3|6} documentType
   * @returns {void}
   */
  const onSuccesEdited = (wasAdded, documentType) => {
    if (wasAdded) {
      switch (documentType) {
        case 1:
          history.push(`/directorio/documentos/${document.customer.id}`);

          break;
        case 2:
          const urls = {
            facturas_emitidas: "/administracion/facturas-emitidas",
            pedidos: "/administracion/pedidos",
          };

          history.push(urls[modulo]);

          break;
        case 3:
          history.push(`/administracion/ordenes-compra`);

          break;
        case 6:
          history.push(`/ventas/contratos`);

          break;

        default:
          break;
      }
      Success(() => {}, "Documento actualizado");
      return;
    }

    return;
  };

  /**
   * Back to origin

   * @returns ()=>void
   */
  const backToOrigin = () => {
    switch (document.documentType) {
      case 1:
        history.push(`/directorio/documentos/${document.customer.id}`);

        break;
      case 2:
        const urls = {
          facturas_emitidas: "/administracion/facturas-emitidas",
          pedidos: "/administracion/pedidos",
        };

        history.push(
          urls[modulo] || `/directorio/documentos/${document.customer.id}`
        );

        break;
      case 3:
        !!modulo
          ? history.push(`/administracion/ordenes-compra`)
          : history.push(`/directorio/documentos/${document.customer.id}`);

        break;
      case 6:
        !!modulo
          ? history.push(`/ventas/contratos`)
          : history.push(`/directorio/documentos/${document.customer.id}`);

        break;

      default:
        break;
    }
    return;
  };

  /**
   *Add the document
   * @param {1|2|3|6} documentType
   */
  const handleOnAddDocument = async (documentType) => {
    const items = document.items.map((item) => ({
      ...item,
      uuid: window.crypto.randomUUID(),
      catalogue:
        typeof item.catalogue.id === "number"
          ? item.catalogue
          : {
              ...item.catalogue.currency,
            },
    }));

    const newItems = items
      .filter((item) => typeof item.catalogue.id !== "number")
      .map((item) => ({
        ...item,
        catalogue: {
          ...item.catalogue.currency,
        },
      }));

    const { canAddDocument, errorMessage } =
      validateCanAddDocument(documentType);
    try {
      if (canAddDocument) {
        const doc = {
          ...document,
          idToRepresent: userToRepresentId,
          // items: document.items.filter(
          //   (item) => typeof item.catalogue.id === "number"
          // ),
          items,
          newItems: newItems,
        };
        const documentAdded = await addDocument({ data: doc }, documentType);
        onSuccesAdded(documentAdded, documentType);
      } else {
        ErrorAlertText(
          () => {},
          "El documento no puede ser agregado",
          "Error en el documento",
          errorMessage
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Validate if the document can be added
   * @param {1|2|3|6} documentType
   * @returns {{canAddDocument:boolean,errorMessage:string}}
   */
  const validateCanAddDocument = (documentType) => {
    return { canAddDocument: true, errorMessage: "" };

    let errorMessage = "";
    let canAddDocument = false;
    const hasCustomer = !!document.customer?.id;
    if (!hasCustomer) {
      if (documentType === 3) {
        errorMessage += "<p>🚩Seleccione un provedor primero </p>";
      } else {
        errorMessage += "<p>🚩Seleccione un cliente primero </p>";
      }
    }
    // const generateContract =
    //   documentType === 6 ? true : document.generateContract.generateContract;
    const beginDate = document.generateContract.beginDate;
    const endDate = document.generateContract.endDate;
    const reminderDate = document.generateContract.reminderDate;
    let isValidContract = true;

    // if(beginDate)
    // if (generateContract) {
    if (!beginDate || !endDate || !reminderDate) {
      isValidContract = false;
      errorMessage += "<p>🚩Revisa que las fechas esten correctas</p> ";
    }
    // }

    const areItems = document.items.length > 0 ? true : false;
    if (!areItems) {
      errorMessage += "<p>🚩Revisa que exista almenos una partida</p> ";
    }
    const currency = document.moneyInfo.currency.value;
    const mxnUtility = document.moneyInfo.mxn.margin.number;
    const usdUtility = document.moneyInfo.usd.margin.number;
    const utilityIsAceptable =
      currency === "MXN"
        ? mxnUtility > 0
          ? true
          : false
        : usdUtility > 0
        ? true
        : false;

    if (!utilityIsAceptable) {
      errorMessage +=
        "<p>🚩El margen no es aceptable, porfavor modifica tus partidas</p> ";
    }
    if (areItems && utilityIsAceptable && isValidContract && hasCustomer) {
      canAddDocument = true;
    }

    return {
      canAddDocument,
      errorMessage,
    };
  };

  /**
   * Handle the customer change
   * @param {{id:number,socialReason:string,rfc:string}} customer
   */
  const handleOnChangeCustomer = (customer) => {
    /**
     * @type{import("../../../../../../types/documentActions").CustomerInfoI}
     */
    const newCustomer = {
      ...document.customer,
      id: customer.id,
      rfc: customer.rfc,
      socialRazon: customer.socialReason,
    };
    dispatch({
      type: ACTIONS.SET_CUSTOMER,
      payload: newCustomer,
    });
  };

  /**
   * Return the numbers to use
   * @param {import("../../../../../../types/documentActions").ItemSelectI} item
   * @param {1|2|3|6} documentType
   * @returns {import("../../../../../../types/documentActions").getNumberI}
   */
  const getNumbers = (item, documentType) => {
    const currency = document.moneyInfo?.currency?.value;

    /**
     * @type {import("../../../../../../types/documentActions").mxnANDusdI}
     */
    const amounts = item[`${currency}`.toLowerCase()];

    if (documentType === 3) {
      const data = {
        subTotal: amounts.cu.total.text,
        unitary: amounts.cu.totalUnit.text,
        discount: item.providerDiscount?.number,
        iva: amounts.cu.totalIva.text,
        ivaPercentage: amounts.cu.iva.number,
        realUnitary: amounts.cu.text,
        withDiscount: amounts.cu.realUnit?.text || amounts.cu.totalUnit.text,
      };
      return data;
    }

    const data = {
      subTotal: amounts.pu.total.text,
      unitary: amounts.pu.totalUnit.text,
      discount: item.clientDiscoount?.number,
      iva: amounts.pu.totalIva.text,
      ivaPercentage: amounts.pu.iva.number,
      realUnitary: amounts.pu.text,
      withDiscount: amounts.pu.realUnit?.text || amounts.pu.totalUnit.text,
    };

    return data;

    if (documentType === 3) {
      if (currency === "MXN") {
        return {
          subTotal: item.mxn?.cu.total.text,
          unitary: item.mxn?.cu.totalUnit.text,
          discount: item.providerDiscount?.number,
          iva: item.mxn?.cu.totalIva.text,
          ivaPercentage: item.iva?.number,
          realUnitary: item.mxn?.cu.text,
          withDiscount:
            item.mxn?.cu.realUnit?.text || item.mxn?.cu.totalUnit.text,
        };
      } else {
        return {
          subTotal: item.usd?.cu.total.text,
          unitary: item.usd?.cu.totalUnit.text,
          discount: item.providerDiscount?.number,
          iva: item.usd?.cu.totalIva.text,
          ivaPercentage: item.iva?.number,
          realUnitary: item.usd?.cu.text,
          withDiscount:
            item.usd?.cu.realUnit?.text || item.usd?.cu.totalUnit.text,
        };
      }
    } else {
      if (currency === "MXN") {
        return {
          subTotal: item.mxn?.pu.total.text,
          unitary: item.mxn?.pu.totalUnit.text,
          discount: item.clientDiscoount?.number,
          iva: item.mxn?.pu.totalIva.text,
          ivaPercentage: item.iva?.number,
          realUnitary: item.mxn?.pu.text,
          withDiscount:
            item.mxn?.pu.realUnit?.text || item.mxn?.pu.totalUnit.text,
        };
      } else {
        return {
          subTotal: item.usd?.pu.total.text,
          unitary: item.usd?.pu.totalUnit.text,
          discount: item.clientDiscoount?.number,
          iva: item.usd?.pu.totalIva.text,
          ivaPercentage: item.iva?.number,
          realUnitary: item.usd?.pu.text,
          withDiscount:
            item.usd?.pu.realUnit?.text || item.usd?.pu.totalUnit.text,
        };
      }
    }
  };

  /**
   * Handel contract key change
   * @param {string} key
   */
  const handleOnChangeContractKey = (key) => {
    dispatch({
      type: ACTIONS.SET_CONTRACT_KEY,
      payload: key,
    });
  };

  /**
   * Validates if creates a contract
   * @param {1|2|3|6} documentType
   * @returns {boolean}
   */
  const handleValidateDisableContract = (documentType) => {
    const createsContract = document.generateContract.generateContract;
    if (documentType === 6) {
      return true;
    } else {
      return createsContract;
    }
  };

  /**
   * Get the numbers of the document
   * @param {1|2|3|6} documentType
   * @returns {{import:string,iva:string,total:string}}
   */
  const getDocumentNumbers = (documentType) => {
    const currency = document.moneyInfo?.currency.value;

    if (documentType === 3) {
      if (currency === "MXN") {
        return {
          import: document.moneyInfo.mxn.importCost.text,
          iva: document.moneyInfo.mxn.ivaCost.text,
          total: document.moneyInfo.mxn.totalCost.text,
        };
      } else {
        return {
          import: document.moneyInfo.usd.importCost.text,
          iva: document.moneyInfo.usd.ivaCost.text,
          total: document.moneyInfo.usd.totalCost.text,
        };
      }
    } else {
      if (currency === "MXN") {
        return {
          import: document.moneyInfo.mxn.importSell.text,
          iva: document.moneyInfo.mxn.ivaSell.text,
          total: document.moneyInfo.mxn.totalSell.text,
        };
      } else {
        return {
          import: document.moneyInfo.usd.importSell.text,
          iva: document.moneyInfo.usd.ivaSell.text,
          total: document.moneyInfo.usd.totalSell.text,
        };
      }
    }
  };

  /**
   * PlaceHolderDate
   * @param {string} date
   * @returns srting
   */
  const placeHolderDate = (date) => {
    const dateHolder = new Date(date);
    const day =
      dateHolder.toLocaleDateString("en-US", { day: "numeric" }) < 10
        ? `0${dateHolder.toLocaleDateString("en-US", { day: "numeric" })}`
        : dateHolder.toLocaleDateString("en-US", { day: "numeric" });
    const month = dateHolder.toLocaleDateString("es-ES", { month: "short" });
    const year = dateHolder.toLocaleDateString("en-US", { year: "numeric" });
    const placeHolder = `${day}/${month}/${year}`;
    return placeHolder;
  };

  /**
   * Get the labes an placeholder for the input dates
   * @param {1|2|3|6} documnetType Document type
   * @returns {import("../../../../../../types/documentActions").DatesByDocumnetTypeI}
   */
  const datesByDocuments = (documnetType) => {
    const today = dateAtCeroHours(new Date());

    const placeHolder = parseDateToText(
      document.generateContract.beginDate || today
    );

    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    const dayEnd =
      +lastDayOfMonth.toLocaleDateString("en-US", { day: "numeric" }) < 10
        ? `0${lastDayOfMonth.toLocaleDateString("en-US", { day: "numeric" })}`
        : lastDayOfMonth.toLocaleDateString("en-US", { day: "numeric" });
    const monthEnd = lastDayOfMonth.toLocaleDateString("es-ES", {
      month: "short",
    });
    const yearEnd = lastDayOfMonth.toLocaleDateString("en-US", {
      year: "numeric",
    });

    const endPlaceHolder = `${dayEnd}/${monthEnd}/${yearEnd}`;

    const nextDay = new Date(today).setDate(today.getDate() + 1);
    switch (documnetType) {
      case 1:
        return {
          initialDate: {
            label: "Registro",
            placeHolder: placeHolder,
            disable: false,
            date: new Date(document.generateContract.beginDate),
            // date: today,
            minDate: null,
            maxDate: null,
          },
          endDate: {
            label: "Vigencia",
            placeHolder: endPlaceHolder,
            disable: false,
            date: today,
            minDate: null,
            maxDate: null,
          },
          reminderDate: {
            label: "Recordatorio",
            placeHolder: "",
            disable: true,
            date: new Date(today.setDate(today.getDate() + 1)),
            minDate: new Date(today.setDate(today.getDate() + 1)),
            maxDate: lastDayOfMonth,
          },
        };
      case 2:
        return {
          initialDate: {
            label: "Registro",
            placeHolder: placeHolder,
            disable: false,
            date: dateAtCeroHours(
              new Date(document.generateContract.beginDate)
            ),
            minDate: null,
            maxDate: null,
          },
          endDate: {
            label: "Facturar",
            placeHolder: placeHolder,
            disable: true,
            date: dateAtCeroHours(substractDays(new Date(), 1)),
            minDate: dateAtCeroHours(substractDays(new Date(), 1)),
            maxDate: null,
          },
          reminderDate: {
            label: "Recordatorio",
            placeHolder: "",
            disable: true,
            date: today,
            minDate: today,
            maxDate: today,
          },
        };
      case 3:
        return {
          initialDate: {
            label: "Registro",
            placeHolder: placeHolder,
            disable: false,
            date: today,
            minDate: null,
            maxDate: null,
          },
          endDate: {
            label: "Enviar",
            placeHolder: endPlaceHolder,
            disable: true,
            date: today,
            minDate: today,
            maxDate: null,
          },
          reminderDate: {
            label: "Recordatorio",
            placeHolder: "Seleccione envio",
            disable: true,
            date: nextDay,
            minDate: new Date(today.setDate(today.getDate() + 1)),
            maxDate: null,
          },
        };
      case 6:
        const contractDate = new Date(today.setDate(today.getDate() + 1));
        return {
          initialDate: {
            label: "Inicio",
            placeHolder: placeHolder,
            disable: true,
            date: contractDate,
            minDate: null,
            maxDate: null,
          },
          endDate: {
            label: "Fin",
            placeHolder: "Seleccione inicio",
            disable: true,
            date: contractDate,
            minDate: contractDate,
            maxDate: contractDate,
          },
          reminderDate: {
            label: "Recordatorio",
            placeHolder: "Seleccione fecha fin",
            disable: true,
            date: contractDate,
            minDate: contractDate,
            maxDate: null,
          },
        };

      default:
        return {
          initialDate: {
            label: "Inicio",
            placeHolder: placeHolder,
            disable: true,
            date: today.setDate(today.getDate() + 1),
            minDate: today.setDate(today.getDate() + 1),
            maxDate: null,
          },
          endDate: {
            label: "Fin",
            placeHolder: "Seleccione inicio",
            disable: false,
            date: today.setDate(today.getDate() + 1),
            minDate: today.setDate(today.getDate() + 1),
            maxDate: null,
          },
          reminderDate: {
            label: "Recordatorio",
            placeHolder: "Seleccione fecha fin",
            disable: false,
            date: today.setDate(today.getDate() + 1),
            minDate: today.setDate(today.getDate() + 1),
            maxDate: null,
          },
        };
    }
  };

  /**
   *Edit the document
   * @param {1|2|3|6} documentType
   */
  const handleOnEditDocument = async (documentType) => {
    /**
     * @type {import("../../../../../../types/documentActions").GeneralDocumentI}
     */
    const unrefState = JSON.parse(domInfo.current.textContent);

    const items = unrefState.items.map((item, i) => ({
      ...item,
      order: i + 1,
      uuid: window.crypto.randomUUID(),
      catalogue:
        typeof item.catalogue.id === "number"
          ? item.catalogue
          : {
              ...item.catalogue.currency,
            },
    }));

    const newItems = items
      .filter((item) => typeof item.catalogue.id !== "number")
      .map((item) => ({
        ...item,
        catalogue: {
          ...item.catalogue.currency,
        },
      }));

    try {
      const doc = {
        ...unrefState,
        items: items,
        newItems,
        idToRepresent: userToRepresentId,
        generateContract: {
          beginDate: dateAtCeroHours(unrefState.generateContract.beginDate),
          beginMaxDate: dateAtCeroHours(
            unrefState.generateContract.beginMaxDate
          ),
          beginMinDate: dateAtCeroHours(
            unrefState.generateContract.beginMinDate
          ),
          endDate: dateAtCeroHours(unrefState.generateContract.endDate),
          endMaxDate: dateAtCeroHours(unrefState.generateContract.endMaxDate),
          endMinDate: dateAtCeroHours(unrefState.generateContract.endMinDate),
          reminderDate: dateAtCeroHours(
            unrefState.generateContract.reminderDate
          ),
          reminderMaxDate: dateAtCeroHours(
            unrefState.generateContract.reminderMaxDate
          ),
          reminderMinDate: dateAtCeroHours(
            unrefState.generateContract.reminderMinDate
          ),
        },
      };
      const documentAdded = await editDocument({ data: doc }, documentType);

      onSuccesEdited(documentAdded, documentType);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Handle if it can be added a document that must required a concept
   * @param {1|2|3|6} documentType
   * @returns
   */
  const handleOnValidatedocumentWithConcept = (documentType) => {
    const { canAddDocument, errorMessage } =
      validateCanAddDocument(documentType);
    if (canAddDocument) {
      return true;
    } else {
      ErrorAlertText(
        () => {},
        "El documento no puede ser agregado",
        "Error en el documento",
        errorMessage
      );
      return false;
    }
  };

  /**
   *
   * @param {number} idTypeDocument
   * @returns {import("../../../../../../types/documentActions").Concepts[]|[]}
   */
  const loadDocumentConcepts = async (idTypeDocument) => {
    try {
      if (idTypeDocument === 3) {
        const { status, data } = await getDocumentsExpensesConcepts();

        return status === 200 ? data : [];
      } else {
        const { status, data } = await getDocumentsIncomeConcepts();
        return status === 200 ? data : [];
      }
    } catch (error) {
      console.log("Hubo un error");
      console.log(error);
      return [];
    }
  };
  /**
   * Handle the change of the concept
   * @param {import("../../../../../../types/documentActions").Concepts} concept
   */
  const handleOnConceptChange = (concept) => {
    dispatch({
      type: ACTIONS.SET_CURRENT_CONCEPT,
      payload: concept,
    });
  };

  const handleInterface = () => {
    const { items } = document;
    let countMXN = 0;
    let countUSD = 0;
    if (documentType === 3) {
      items.forEach((element) => {
        if (element.catalogue.currency.code === "MXN") {
          countMXN += 1;
        } else {
          countUSD += 1;
        }
      });

      if (countMXN > 0 && countUSD > 0) {
        dispatch({
          type: ACTIONS.SET_INTERFACE,
          payload: {
            disableTc: true,
            disableCurrency: true,
            disableTotals: true,
          },
        });
      } else {
        dispatch({
          type: ACTIONS.SET_INTERFACE,
          payload: {
            disableTc: false,
            disableCurrency: false,
            disableTotals: false,
          },
        });
      }
    } else {
      dispatch({
        type: ACTIONS.SET_INTERFACE,
        payload: {
          disableTc: false,
          disableCurrency: false,
          disableTotals: false,
        },
      });
    }
  };

  /**
   * Identify the currency to use in the odc Document
   * @returns {{id: number,value: 'MXN' | 'USD' | '***'}}
   */
  const lastItemDocumetCurrency = () => {
    const newCureny = {
      id: 3,
      value: "***",
    };
    if (documentType === 3) {
      newCureny.id = 3;
      newCureny.value = "***";
    } else {
      newCureny.id = document.moneyInfo.currency.id;
      newCureny.value = document.moneyInfo.currency.value;
    }
    const itemsLength = document.items.length;

    const countMXN = document.items.filter(
      (item) => item.currency.code === "MXN"
    ).length;
    const countUSD = document.items.filter(
      (item) => item.currency.code === "USD"
    ).length;

    if (countMXN === itemsLength) {
      newCureny.id = 1;
      newCureny.value = "MXN";
    }
    if (countUSD === itemsLength) {
      newCureny.id = 2;
      newCureny.value = "USD";
    }

    return newCureny;
  };

  /**
   *
   * @param {import("../Items/types").CatalogueOperation} item
   */
  async function handleSubmitItemForm(item) {
    const updatedItemsDocument = [...document.items, item];
    
    if (document.items.length === 0) {

        dispatch({
          type: ACTIONS.SET_MONEY_INFORMATION,
          payload: {
            ...document.moneyInfo,
            currency: {
              id: item.currency.code === "MXN" ? 1 : 2,
              value: item.currency.code,
            },
          },
        });
      }

    dispatch({
      type: ACTIONS.SET_ITEMS,
      payload: updatedItemsDocument,
    });
  }

  /**
   * Update the item on memory for the document items
   * @param {import("../Items/types").CatalogueOperation} item
   * @param {number} index - Position of the item on the array
   */
  async function handleEditItemForm(item, index) {
    dispatch({
      type: ACTIONS.SET_EDIT_ITEM,
      payload: {
        ...item,
        index,
      },
    });
  }

  const functions = {
    addComment,
    handleEditItemForm,
    addItem,
    handleSubmitItemForm,
    backToOrigin,
    calculateExchangeTc,
    cancelEditing,
    datesByDocumentType,
    doCloseModal,
    doOpenModal,
    editComment,
    editItem,
    getDocumentNumbers,
    getNumbers,
    reorderDocumentItems,
    handelOnLoadCurrentItem,
    handleContactOnChange,
    handleEditingComment,
    handleEditNewItemDescription,
    handleEditNewItemSku,
    handleItemDescriptionChange,
    handleItemSkuChange,
    handleOnAddDocument,
    handleOnBeginDateChange,
    handleOnChangeContractKey,
    handleOnChangeCustomer,
    handleOnChangeTc,
    handleOnChangeToggle,
    handleOnClientDiscountItemChange,
    handleOnCommentChange,
    handleOnConceptChange,
    handleOnCostItemChange,
    handleOnCraeteNewItem,
    handleOnCurrencyItemChage,
    handleOnDeleteComment,
    handleOnDeleteItem,
    handleOnDocumentCurrencyChange,
    handleOnEditDocument,
    handleOnEndDateChange,
    handleOnIvaItemChange,
    handleOnKeyItemChange,
    handleOnPriceItemChange,
    handleOnProbabilityChange,
    handleOnProviderDiscountItemChange,
    handleOnQuantityItemChange,
    handleOnReminderDateChange,
    handleOnUenItemChange,
    handleValidateDisableContract,
    onCloseCurrentItem,
    onSuccesAdded,
    placeHolderDate,
    probabilityValueText,
    validateCanAddDocument,
    validateCommentEditing,
    handleOnCommentChangeMotive,
    tc,
  };

  return {
    document,
    functions,
    domInfo,
  };
}
