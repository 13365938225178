import {
  RESET_PASSWORD,
  SET_SCREEN_LOGIN,
  LOG_USER,
  SET_PERMISSIONS,
  SET_SESSION_COOKIE,
  SET_FILTER,
  UPDATE_PROFILE_PICTURE,
  SET_USER_TO_REPRESENT,
  SHOW_OTP,
  TOGGLE_2FA,
  TOGGLE_SPINNER,
  SET_BANNER,
} from "../../types/index";

export default (state, action) => {
  switch (action.type) {

    case SET_BANNER:
      return {
        ...state,
        banner:action.payload
      }

    case TOGGLE_SPINNER:

      return{
        ...state,
        isFetchingServer:!state.isFetchingServer
      }

    case UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: {
          scale: action.payload.scale,
          position: action.payload.position,
          img: action.payload.img,
        },
      };

    case TOGGLE_2FA:
      let unrefUser = { ...state.userInfo[0] };
      unrefUser.has2faEnable = !unrefUser.has2faEnable;

      return {
        ...state,
        userInfo: [unrefUser],
      };

    case RESET_PASSWORD:
      return {
        ...state,
        screen: action.payload.screen,
        user: action.payload.user,
      };

    case SHOW_OTP:
      return {
        ...state,
        screen: "otp",
        user: action.payload,
      };

    case SET_SCREEN_LOGIN:
      return {
        ...state,
        screen: action.payload,
      };

    case SET_SESSION_COOKIE:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        user: action.payload.userInfo[0].userName,
        userId: action.payload.userInfo[0].userID,
        userToRepresentId: action.payload.userInfo[0].userID,
        rolId: action.payload.userInfo[0].rol,
        profilePicture: {
          scale: action.payload.userInfo[0].profilePicture.scale,
          position: action.payload.userInfo[0].profilePicture.position,
          img: action.payload.userInfo[0].profilePicture.img,
        },
      };

    case SET_PERMISSIONS:
      return {
        ...state,
        rolPermitions: action.payload,
      };
    case SET_USER_TO_REPRESENT:
      return {
        ...state,
        userToRepresentId: action.payload,
      };

    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    case LOG_USER:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        userInfo: action.payload.userInfo,
      };
    default:
      return state;
  }
};
