import React , {useEffect,useState} from 'react';
import { AllExecutives, AllRoles } from '../../../../helpers/apis';
import { ErrorMessage, Select } from '../../../individual/inputs/Inputs';

export default function SelectRoles({
    id = 'roleID',
    name = 'roles',
    label = 'Rol *',
    onChange = () => {},
    onLoaded = () => {},
    value = null,
    forwardRef = {},
    errors = {},
    addTodos=false
}){

    const [roles,setRoles] = useState([]);

    useEffect(()=>{
        console.log('Intentando cargar roles...');
        (async function(){
            const apiRoles = await AllRoles();
            setRoles(apiRoles);
        })();
    },[]);

    useEffect(()=>{
        if(roles.length===0) return;
        onLoaded(roles);
    },[roles])

    return(

        <div>
        <Select
            addTodos = { addTodos }
            idSelect = {id}
            nameSelect = {name}
            options = {roles}
            textJSON = 'description'
            forwardRef = {forwardRef}
            valueJSON = 'rolID'
            label = {label}
            initialOption = 'Seleccione un rol'
            onChange = {value => onChange(value)}
            value = {value}
        />

        <ErrorMessage
            message = {errors[name]?.message}
        />

        </div>
    )
}