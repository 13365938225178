import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import {
  downloadFile,
} from "helpers/Apis/associateFilesV2";
import React , { useState } from "react";
import { saveAs } from "file-saver";

/**
 * Render a button to open the PDF on a new tab
 * @param {import('./types').PropsPdfOnTab} props - Props
 * @returns {JSX.Element}
 */
export const PdfOnTab = (props) => {
  const [state, setState] = useState({
    isDownloading: false,
  });

  if (typeof props.idFile !== "number") return <></>;

  if (props.idFile <= 0) return <></>;

  if (state.isDownloading) return <Spinner hidden={false} text="Abriendo..." />;

  const attemptOpen = async () => {
    setState((state) => ({
      ...state,
      isDownloading: true,
    }));

    const file = await downloadFile(
      props.idFile,
      window.crypto.randomUUID(),
      false
    );

      saveAs(file,`Archivo.pdf`)
    // const url = URL.createObjectURL(file);
    // window.open(url, "_blank");

    setState((state) => ({
      ...state,
      isDownloading: false,
    }));
  };

  return <ButtonV2 {...props} onClick={attemptOpen} />;
};
