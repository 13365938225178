import { ContextMaterials } from "pages/Materials";
import React, { useContext } from "react";
import AmountValidationMessage from "../AmountValidationMessage";

/**
 * @param {import('../../../../../../types/proyects/materials').DtoListMaterials} material
 */
export default function Amount(material) {
  const materials = useContext(ContextMaterials);

  if (material.residueQuantity <= 0)
    return <p className="w-100 my-2">Comprado al 100%</p>;

  return (
    <div className="w-100 my-2">
      <input
        type="number"
        min={1}
        value={materials.grid[material.id]?.quantity || ""}
        max={material.residueQuantity}
        placeholder={
          typeof materials.grid[material.id]?.provider === "number"
            ? "Escribe aquí (Obligatorio)"
            : "Escribe aquí"
        }
        onChange={(e) => {
          const value = e.target.value.split(".")[0];
          materials.setQuantity(value, material.id);
        }}
      />

      <AmountValidationMessage {...material} />
    </div>
  );
}
