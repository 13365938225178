import React, { useContext } from "react";
import { CONTEXT_HOME } from "../Inicio";
import style from "../styles.module.scss";
import Options from "../Options";
import TableQuotes from "../TableQuotes";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export default function QuotesIndex() {
  const hook = useContext(CONTEXT_HOME);

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingPendingQuotes"
        text={<LoadingText>Cargando cotizaciones</LoadingText>}
      />
    );

  if (hook.quotes.length <= 0) return <></>;

  return (
    <div className={style.containerQuotes}>
      <h1 className={style.titleIndex}>Cotizaciones abiertas</h1>
      <Options />
      <TableQuotes />
    </div>
  );
}
