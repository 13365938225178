import React, { useRef } from "react";
import scss from "./styles.module.scss";
import InformativeTooltip from "components/individual/InfoTooltip";
import { mnCurrency } from "helpers/money";
import useEgressOverview from "customHooks/useEgressOverview";
import { Spinner } from "components/individual/Spinner/Spinner";
import Details from "components/general/Details";

const HEADER = () => (
  <div className={scss.header}>
    <p>-</p>
    <p>Concepto</p>
    <p>Factura</p>
    <p>Parcialidad</p>
    <p>Proveedor</p>
    <p>Moneda</p>
    <p>Total</p>
    <p>Asociado</p>
    <p>Importe egreso</p>
    <p>TC</p>
  </div>
);

/**
 * @param {import('./types').PropsMoreInfo} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfo({ movement }) {
  const { state, gastos, odc } = useEgressOverview(movement);
  
  const key = useRef(`${window.crypto.randomUUID()}`);

  if (state.isLoading)
    return <Spinner text={"Cargando egreso"} hidden={false} />;

  return (
    <div className={scss.containerOverviewEgress}>
      <Details summary="Conceptos asociados">
        {gastos.length + state.aditionalConcepts.length <= 0 ? (
          <h4
            style={{
              padding: "0 20px",
            }}
            className="text-left"
          >
            No hay conceptos asociados
          </h4>
        ) : (
          <>
            <HEADER />
            {[...state.aditionalConcepts, ...gastos].map((item,i) => (
              <div className={scss.row} key={`${key.current}-${i}-concepto`}>
                <p className="text-center">
                  {item.concepts.length > 1 ? (
                    <InformativeTooltip>
                      <ul>
                        {item.concepts.map((concept) => (
                          <li key={`${item.id}${concept.id}`}>
                            {concept.description} - {mnCurrency(concept.amount)}{" "}
                            {item.total.currency}
                          </li>
                        ))}
                      </ul>
                    </InformativeTooltip>
                  ) : (
                    "-"
                  )}
                </p>
                <p className="text-center">
                  {item.concepts.reduce(
                    (text, concept, i) =>
                      (text += `${concept.description} ${
                        i < item.concepts.length - 1 ? " , " : ""
                      }`),
                    ""
                  )}
                </p>
                <p className="text-center">{item.folio}</p>
                <p className="text-center">
                  {item.partialitie}/{item.partialities}
                </p>


                <p className="text-left">{item.directory.socialReason}</p>

                <p className="text-center">{item.applied.currency}</p>
                <p className="text-right">
                  {mnCurrency(item.total.amount)} {item.total.currency}
                </p>
                <p className="text-right">
                  {mnCurrency(item.applied.amount)} {item.applied.currency}
                </p>
                <p className="text-right">
                  {mnCurrency(item.paid.amount)} {item.paid.currency}
                </p>
                <p className="text-right">{mnCurrency(item.tc)}</p>
              </div>
            ))}
          </>
        )}
      </Details>

      <Details summary="Facturas asociados">
        {odc.length <= 0 ? (
          <h4
            style={{
              padding: "0 20px",
            }}
            className="text-left"
          >
            No hay facturas asociadas
          </h4>
        ) : (
          <>
            <HEADER />

            {odc.map((item,i) => (
              <div className={scss.row} key={`${key.current}-${i}-orden-compra`}>
                <p className="text-center">
                  {item.concepts.length > 1 ? (
                    <InformativeTooltip>
                      <ul>
                        {item.concepts.map((concept) => (
                          <li key={`${item.id}${concept.id}`}>
                            {concept.description} - {mnCurrency(concept.amount)}{" "}
                            {item.total.currency}
                          </li>
                        ))}
                      </ul>
                    </InformativeTooltip>
                  ) : (
                    "-"
                  )}
                </p>
                <p className="text-center">
                  {item.concepts.reduce(
                    (text, concept, i) =>
                      (text += `${concept.description} ${
                        i < item.concepts.length - 1 ? " , " : ""
                      }`),
                    ""
                  )}
                </p>
                <p className="text-center">{item.folio}</p>
                <p className="text-center">
                  {item.partialitie}/{item.partialities}
                </p>

                <p className="text-left">{item.directory.socialReason}</p>
                <p className="text-center">{item.applied.currency}</p>
                <p className="text-right">
                  {mnCurrency(item.total.amount)} {item.total.currency}
                </p>
                <p className="text-right">
                  {mnCurrency(item.applied.amount)} {item.applied.currency}
                </p>
                <p className="text-right">
                  {mnCurrency(item.paid.amount)} {item.paid.currency}
                </p>
                <p className="text-right">{mnCurrency(item.tc)}</p>
              </div>
            ))}
          </>
        )}
      </Details>
    </div>
  );
}
