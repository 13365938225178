import React, { createContext } from "react";
import InputRfc from "./InputRfc";
import LabelRfc from "./LabelRfc";
import CountryRfc from "./CountryRfc";
import LabelCountryRfc from "./LabelCountryRfc";
import { useRfc, INITIAL_STATE } from "customHooks/useRfc";

/**
 * @type {import("react").Context<import("customHooks/useRfc/types").ReturnUseRfc>}
 */
export const RfcContext = createContext({
  ...INITIAL_STATE,
  updateRfc: () => {},
  updateCountry: () => {},
});

/**
 * Render a field to ask for RFC customer
 * @param {import("./types").PropsRfc} props - Props
 * @returns {JSX.Element}
 */
export function RFC({
  value = "",
  children,
  onChange = () => {},
  country = "",
}) {
  const hook = useRfc({ onChange, rfc: value, country });

  return <RfcContext.Provider value={hook}>{children()}</RfcContext.Provider>;
}

export { InputRfc, LabelRfc, CountryRfc, LabelCountryRfc };
