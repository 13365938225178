import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Warning } from "helpers/alerts";
import React from "react";
import useCancelCreditNote from "./useCancelCreditNote";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { useRef } from "react";

/**
 * Cancel a credit note in praxia
 * @param {import("./types").PropsCancelCreditNote} props - Props
 * @returns {JSX.Element}
 */
export default function CancelCreditNoteEmited({
  idFacturamaCreditNote = null,
  uuidCreditNote = null,
  onCancelated = () => {},
  isCancelled = false
}) {
  const idSpinner = useRef(window.crypto.randomUUID());

  const hook = useCancelCreditNote({
    idFacturamaCreditNote,
    uuidCreditNote,
    onCancelated
  });

  if(isCancelled) return <></>

  if (hook.isCancelling)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cancelando nota de crédito</LoadingText>}
        idSpinner={idSpinner.current}
      />
    );

  return <ButtonV2 onClick={hook.attemptCancelComplement}>Cancelar</ButtonV2>;
}
