import React, { useContext } from "react";
import { ContextInvoiceReception } from "..";
import { Label } from "structure/Document";
import pill from "./styles.module.scss";
import ExcelInvoiceReception from "../Excel";
import FilterMobile from "./FilterMobile";
import UpdateAccounting from "components/individual/UpdateAccounting";

const NO_ACCOUNTING_STATUS = {
  1: "CxP",
  11: "CxP Parcial",
  2: "Pagadas",
  5: "Canceladas",
  20: "CxP/Parcial",
  "-1": "Todos",
};

const ACCOUNTING_STATUS = {
  null: "Todos",
  true: "Contabilizados",
  false: "No contabilizados",
};

export default function PillsFilter() {
  const hook = useContext(ContextInvoiceReception);
  return (
    <div className={pill.container}>
      <FilterMobile />

      <ExcelInvoiceReception />

      {!hook.isForAccounting ? (
        <div className={pill.item}>
          <Label>Estatus</Label>
          <span>{NO_ACCOUNTING_STATUS[hook.status] || "??"}</span>
        </div>
      ) : (
        <div className={pill.item}>
          <Label>Estatus</Label>
          <span>{ACCOUNTING_STATUS[hook.concilationStatus] || "??"}</span>
        </div>
      )}

      {hook.isForAccounting ? (
        <UpdateAccounting
          operationGrid={hook.gridAccounting.operation}
          originalGrid={hook.gridAccounting.original}
          type="FR"
        />
      ) : null}
    </div>
  );
}
