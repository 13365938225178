import React, { useState, useEffect } from "react";
import { useBanks } from "../BankCard/hooks/useBanks";
import { Spinner } from "../Spinner/Spinner";
import LoadingText from "../TextLoading";
import { Label } from "structure/Document";
import Select from "../SelectV4";

/**
 * Render a select for the bank accounts
 * @param {import("./types").PropsBankAccount} props - Props
 * @returns {JSX.Element}
 */
export default function BankAcconuntSelect({ onChange = () => {} , value = null }) {
  const hook = useBanks();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (hook.actives.length <= 0) return;

    const parsedOptions = hook.actives.map((opt) => (
      {
        ...opt,
        label: `${opt.bank.clave} - ${opt.bank.shortName} - ${opt.account}`,
      }
    ));

    if(value!==null){
      const accountFound = parsedOptions.find(account=>account.id===null);

      if(accountFound!==null && accountFound !== undefined){
        onChange(accountFound)
      }
    }

    setOptions([
      {
        id: null,
        label: "-- SELECCIONA --",
        disabled: true,
      },
      ...parsedOptions,
    ]);
  }, [hook.actives]);

  if (hook.isLoading)
    return (
      <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
    );

  if (options.length <= 0) return <></>;
  return (
    <div>
      <Label>Cuenta</Label>
      <Select
        labelToDisplay="label"
        options={async () => options}
        onChange={onChange}
        defaultSelected={{
          keyToCompare: "id",
          value,
        }}
      />
    </div>
  );
}
