// @ts-check

import { Error, Success } from "helpers/alerts";
import { dateToDbFormat, getLastDayOfDate } from "helpers/dates";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode, successRes } from "../fetch";
import { truncateDecimals } from "helpers/money";
import { parseToFolio } from "helpers/documents";
import { getErrorData } from "helpers/errors";

/**
 *
 * @returns {Promise<import("../../../../../server/models/banks/types").BankAccountI[]>}
 */
export async function getCards() {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/`, {
      credentials: "include",
      method: "GET",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Add an egress which gonna belong to **concepts only**
 * @param {import("./types").DtoEgressConcept} dto - Dto
 * @returns {Promise<boolean>}
 */
export async function addEgressConcepts(dto) {
  const total = dto.concepts.reduce((total, concept) => {
    const newTotal = total + truncateDecimals(concept.amount, 4);
    return truncateDecimals(newTotal, 4);
  }, 0);

  try {
    const res = await fetch(`${URL_BASE}bancos/v2/egreso`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...dto, amount: total }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Add an egress associated to invoices for providers
 * @param {import("../../../../../server/controllers/banks/types").DtoAddEgressInvoice} dto - DTO
 * @returns {Promise<boolean>}
 */
export async function addEgressCxp(dto) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/egreso/facturas`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Create a new bank account
 * @param {import("../../../../../server/models/banks/types").AddBankAccountI} bank - Information of the bank account to create
 * @returns {Promise<import("../../../../../server/models/banks/types").BankAccountI[]>}
 */
export async function addBankAccount(bank) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/cuenta`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bank),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return data.account;
    }

    Error(() => {}, data.message);
    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Get the information of a bank account
 * @param {number} id - Id of the bank account
 * @returns {Promise<import("../../../../../server/models/banks/types").BankAccountI|null>} Information of the bank account
 */
export async function getAccount(id) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/${id}`, {
      credentials: "include",
      method: "GET",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Update the information of a bank account
 * @param {import("../../../../../server/models/banks/types").UpdateBankAccountI} dto - DTO in order to update the bank account
 * @returns {Promise<import("../../../../../server/models/banks/types").BankAccountI|null>}
 */
export async function updateBankAccount(dto) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/cuenta`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return data;
    }

    Error(() => {}, data.message);
    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Attempt do a transference
 * @param {number} originAccount - Amount
 * @param {number} destinyAccount - Amount
 * @param {number} amounToTransfer - Amount
 * @param {number} amountToReceive - Amount
 * @param {string} date - Date of the transference performed.
 * It must be on YYYY-MM-DD
 * @returns {Promise<boolean>}
 */
export async function transferToBankAccount(
  originAccount,
  destinyAccount,
  amounToTransfer,
  amountToReceive,
  date
) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/transferencia`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        originAccount,
        date,
        destinyAccount,
        amounToTransfer: amounToTransfer
          .toLocaleString("es-MX", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
          .replaceAll(",", ""),
        amountToReceive: amountToReceive
          .toLocaleString("es-MX", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
          .replaceAll(",", ""),
      }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Create a new incoming into the system
 * @param {any} dto - DTO to create the incoming
 * @returns {Promise<boolean>} True if association was made
 */
export async function addIncoming(dto) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/ingreso`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 *
 * @param {import("../../../../../server/helpers/validations/bank/movements/types").GetMovementsI} dto - Dto in order to fethc the movements of a bank account
 * @returns {Promise<import("../../../../../server/models/banks/movements/types").MovementsListI>} - Information of the movements
 */
export async function getMovements({
  bankAccount,
  endDate,
  filterBy,
  page,
  startDate,
  justOnProcess = false,
  accounted = null,
  includeCancelatedMovements = true,
}) {
  const failedResponse = {
    movements: [],
    currentPage: 1,
    pages: 0,
  };

  try {
    const queryAccounted = accounted === null ? "" : `&accounted=${accounted}`;

    const queryIncludeCancelated = `&includeCancelatedMovements=${includeCancelatedMovements}`;

    const res = await fetch(
      `${URL_BASE}bancos/v2/cuenta/movimientos?bankAccount=${bankAccount}${queryAccounted}&endDate=${endDate}&filterBy=${filterBy}&page=${page}&startDate=${startDate}&justOnProcess=${+justOnProcess}${queryIncludeCancelated}`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return failedResponse;
  } catch (error) {
    return failedResponse;
  }
}

/**
 * Get the information of a movement
 * @param {number} id - Id
 * @returns {Promise<import("../../../../../server/helpers/validations/bank/movements/types").BankAccountMovementI>} Movement information
 */
export async function getMovement(id) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/cuenta/movimientos/${id}`, {
      credentials: "include",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return null;
  } catch (error) {
    return null;
  }
}

/**
 *
 * @param {number} id - Id of the movement
 * @returns {Promise<import("../../../../../server/models/banks/movements/types").ConceptMovementI[]>} Get the information of concepts
 */
export async function geMovementConcepts(id) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/cuenta/movimientos/conceptos/${id}`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Cancel a incoming movement
 * @param {string[]} uuids - UUIDs of the complements
 * @param {number[]} pues - Ids of the associations made to invoices PUE
 * @returns {Promise<boolean>} True if was cancelled
 */
export async function cancelIncoming(uuids = [], pues = []) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/ingreso/cancelar`, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify({ uuids, pue: pues }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Cancel a incoming movement which has only concepts
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<boolean>} True if was cancelled
 */
export async function cancelIncomingConcepts(idMovement) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/ingreso/cancelar/conceptos/${idMovement}`,
      {
        credentials: "include",
        method: "PUT",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Download the pdf report for the bank accounts
 * @param {number} id - Id of the bank account to request the report
 * @param {number} month - Between 1 and 12 to create the report
 * @param {number} year - Year of the requested report
 * @returns {Promise<Blob|null>} File pdf generated
 */
export async function bankAccountReport(id, month, year) {
  const beginDate = dateToDbFormat(new Date(year, month - 1, 1));

  const dateRequested = new Date(year, month - 1, 1);

  const lastDate = dateToDbFormat(getLastDayOfDate(dateRequested));

  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/estado-cuenta?beginDate=${beginDate}&idBankAccount=${id}&endDate=${lastDate}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }

    const data = await res.json();
    Error(() => {}, data?.body || "No se pudo descargar el archivo");
    return null;
  } catch (error) {
    Error(() => {}, error.message);
    return null;
  }
}

/**
 * Download the pdf report for the bank accounts
 * @param {number} id - Id of the bank account to request the report
 * @param {number} month - Between 1 and 12 to create the report
 * @param {number} year - Year of the requested report
 * @returns {Promise<Blob|null>} File pdf generated
 */
export async function bankAccountReportExcel(id, month, year) {
  const beginDate = dateToDbFormat(new Date(year, month - 1, 1));

  const dateRequested = new Date(year, month - 1, 1);

  const lastDate = dateToDbFormat(getLastDayOfDate(dateRequested));

  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/estado-cuenta/excel?beginDate=${beginDate}&idBankAccount=${id}&endDate=${lastDate}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }

    const data = await res.json();
    Error(() => {}, data?.body || "No se pudo descargar el archivo");
    return null;
  } catch (error) {
    Error(() => {}, error.message);
    return null;
  }
}

/**
 * Get the pdf file for the movements report
 * @param {number} bankAccount - Id of the bank account
 * @param {Date} startDate - Start date
 * @param {Date} endDate - End date
 * @returns {Promise<Blob|null>} PDF file
 */
export async function getMovementReport(bankAccount, startDate, endDate) {
  const startDateParsed = dateToDbFormat(startDate);
  const endDateParsed = dateToDbFormat(endDate);

  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/movimientos?beginDate=${startDateParsed}&idBankAccount=${bankAccount}&endDate=${endDateParsed}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }
    Error(() => {},
    `No se pudo descargar el pdf de movimientos, favor de reportar a soporte`);
    return null;
  } catch (error) {
    Error(() => {}, error.message);
    return null;
  }
}

/**
 * Get the complements of an incoming
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<import("../../../../../server/models/banks/movements/types").MovementComplementI[]>}
 */
export async function getIncomingComplements(idMovement) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/cuenta/movimientos/complementos/${idMovement}`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the complements of an incoming
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<import("../../../../../server/models/banks/egreses/types").EgressOverview[]>}
 */
export async function getEgressOverview(idMovement) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/cuenta/movimientos/egreso/${idMovement}`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Cancel a expense
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<boolean>}
 */
export async function cancelEgressExpense(idMovement) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/cancelar/egreso/concepto/${idMovement}`,
      {
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, "Egreso cancelado");
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}

/**
 * Cancel an association CxP made to invoices
 * @param {import("../../../../../server/helpers/validations/cxp/cancelCxp/types").DtoBodyCancelCxp} dto - Information in order to cancel associations made to invoices
 * @returns {Promise<boolean>} True if was cancellated correctly
 */
export async function cancelCxpAssociation(dto) {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/cancelar/egreso/cxp`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}

/**
 * Get the report for the bank accounts, the residues
 * @returns {Promise<import("../../../../../server/models/banks/types").BankResidueReportI|undefined>}
 */
export async function getResidueBankAccounts() {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/reportes/saldos`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 * Get the report for the bank accounts, the residues as excel file
 * @returns {Promise<Blob|undefined>}
 */
export async function getResidueBankAccountsExcel() {
  try {
    const res = await fetch(`${URL_BASE}bancos/v2/reportes/saldos/excel`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();
      return data;
    }
    const data = await res.json();
    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 * Get the information for the cash flow of the system
 * @param {"MXN"|"USD"|string} currency - Currency to use on the report
 * @param {"MXN"|"USD"|string|undefined} [currencyReport=undefined] - Currency to use on the report
 * @param {number} [tc=null] - Tc to use for the reports
 * @returns {Promise<import("../../../../../server/controllers/banks/types").ResponseCashFlow>} Information of the cash flow
 */
export async function getCashFlow(
  currency,
  currencyReport = undefined,
  tc = null
) {
  try {
    const queryParamCurrencyReport =
      currencyReport === undefined
        ? `?currencyToShow=${currency}`
        : `?currencyToShow=${currencyReport}`;
    const queryParamCurrency =
      currency === "USD" || currency === "MXN"
        ? `&currencyIWant=${currency}`
        : ``;
    const queryParamTc =
      tc === null || tc <= 0
        ? `&tc=1.0`
        : `&tc=${new Intl.NumberFormat("es-MX", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }).format(tc)}`;

    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/flujo-efectivo${queryParamCurrencyReport}${queryParamCurrency}${queryParamTc}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 * Get the following reports as excel
 * 1. Cash Flow
 * 2. Cash Flow Proyection
 * @param {import("pages/BanksV2/Reports/CashFlow/types").TypeCashFlow} type - Type of report to get
 * @param {"MXN"|"USD"|string} currency - Currency to use on the report
 * @param {"MXN"|"USD"|string|undefined} [currencyReport=undefined] - Currency to use on the report
 * @param {number} [tc=null] - Tc to use for the reports
 * @returns {Promise<Blob|undefined>} Excel with the cash flow
 */
export async function getCashFlowExcel(
  type,
  currency,
  currencyReport = undefined,
  tc = null
) {
  try {
    const urlToFetch =
      type === "flujo" ? "flujo-efectivo" : "prediccion-flujo-efectivo";

    const queryParamCurrencyReport =
      currencyReport === undefined
        ? `?currencyToShow=${currency}`
        : `?currencyToShow=${currencyReport}`;
    const queryParamCurrency =
      currency === "USD" || currency === "MXN"
        ? `&currencyIWant=${currency}`
        : ``;
    const queryParamTc =
      tc === null || tc <= 0
        ? `&tc=1.0`
        : `&tc=${new Intl.NumberFormat("es-MX", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          }).format(tc)}`;

    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/${urlToFetch}/excel${queryParamCurrencyReport}${queryParamCurrency}${queryParamTc}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const excel = await res.blob();
      return excel;
    }

    const data = await res.json();
    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 *
 * @param {import("pages/BanksV2/Reports/OldResidues/useOldResidues/types").OldResidueType} type - Type of report
 * @param {string} currencyToReport - Currency
 * @param {number} tc - Tc
 * @param {string|null} [currencyToUse=null] - Currency to use
 * @param {boolean} [excelFile=false] - **If true**, the response will be the excel file of the report
 * @param {boolean} [withDetails=true] - **Only applys for the excel report**
 * @returns {Promise<import("../../../../../types/Sells/sells").OldBalanceCxcFormatI[]|Blob>}
 */
export async function getOldResidues(
  type,
  currencyToReport,
  tc,
  currencyToUse = null,
  excelFile = false,
  withDetails = true
) {
  try {
    const urlType = type === "cxc" ? "ventas" : "compras";

    const urlDetails = `&withDetails=${withDetails}`;

    const currencyToUseParam =
      currencyToUse === "Todos" || currencyToUse === null
        ? ""
        : `&currencyToUse=${currencyToUse}`;

    const excelUrl = excelFile ? "/excel" : "";

    const url = `${URL_BASE}documentos/${urlType}/antiguedad-saldos${excelUrl}?currencyToReport=${currencyToReport}&tc=${tc}${currencyToUseParam}${urlDetails}`;

    const res = await fetch(url, {
      credentials: "include",
    });

    if (res.ok && isValidHttpResCode(res.status)) {
      if (excelFile) {
        const file = await res.blob();
        return file;
      }

      const invoices = await res.json();
      return invoices;
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 *
 * @param {number} idBankAccount - Id of the bank account
 * @param {string} from - On YYYY-MM-DD format
 * @param {string} to - On YYYY-MM-DD format
 * @returns {Promise<import("../../../../../server/controllers/banks/types").Movement[]>}
 */
export async function getMovementsReport(idBankAccount, from, to) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/estado-cuenta/local?beginDate=${from}&endDate=${to}&idBankAccount=${idBankAccount}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {import("../../../../../server/controllers/banks/types").BankAccountReportI}
       */
      const movements = await res.json();

      if (Array.isArray(movements.movements)) {
        return movements.movements;
      }
      return [];
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Update the information of the bank account
 * @param {import("./types").BankCorrectionParam} param - Param in order to apply bank account correction
 * @returns {Promise<boolean>}
 */
export async function sendBankCorrection({
  amount,
  idBankAccount,
  month,
  year,
}) {
  try {
    const monthParsed = String(month).padStart(2, "0");

    const res = await fetch(`${URL_BASE}bancos/v2/saldo-incial`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idBankAccount,
        initialAmount: amount,
        newKeyMonth: `${year}${monthParsed}`,
      }),
    });

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, "Cuenta actualizada");
      return true;
    }

    const error = await getErrorData(res);

    Error(() => {}, error.message);

    return false;
  } catch (error) {
    Error(() => {}, error.message);
  }
}

/**
 * Get the overview of a movement (more information)
 * @param {number} id - Id of the movement to retrieve the information
 * @returns {Promise<import("../../../../../server/models/banks/types").OverviewMovement[]>}
 */
export async function movementOverview(id) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/movimientos/mas-informacion/${id}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (successRes(res)) {
      const data = await res.json();
      return data.filter((item) => item !== null);
    }

    const error = await res.json();

    Error(() => {}, error.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);

    return [];
  }
}
