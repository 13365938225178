import { add, addDays, format } from "date-fns";
import { es } from "date-fns/locale";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";
import { mnCurrency } from "helpers/money";

export const RECEPTION_INVOICE = {
  SET_STEP: "SET_STEP",
  SET_PROVIDER: "SET_PROVIDER",
  SET_EXPIRATIONDATE: "SET_EXPIRATIONDATE",
  SET_XMLINFO: "SET_XMLINFO",
  SET_AMOUNTS: "SET_AMOUNTS",
  SHOW_GRID: "SHOW_GRID",
  SET_CONCEPT: "SET_CONCEPT",
  SET_ONLY_EXPENSES: "SET_ONLY_EXPENSES",
  SET_PARTIALITIES: "SET_PARTIALITIES",
  SET_IS_ADDING_RECEPCION: "SET_IS_ADDING_RECEPCION",
  SET_INITIAL_STATE: "SET_INITIAL_STATE",
  SET_STEP_2: "SET_STEP_2",
  SET_PDF_FILE: "SET_PDF_FILE",
  SET_EMITED_DATE: "SET_EMITED_DATE",
  SET_SOCIAL_REASON: "SET_SOCIAL_REASON",
  SET_FOLIO: "SET_FOLIO",
  SET_IVA: "SET_IVA",
  SET_CREDIT_DAYS: "SET_CREDIT_DAYS",
  SET_EXPIRATION_DATE: "SET_EXPIRATION_DATE",
  SET_RFC: "SET_RFC",
  SET_RFC_ENTERPRISE: "SET_RFC_ENTERPRISE",
  SET_TOTAL: "SET_TOTAL",
  SET_CURRENCY: "SET_CURRENCY",
};

export default function reducer(state, action) {
  switch (action.type) {
    case RECEPTION_INVOICE.SET_STEP_2:
      return {
        ...state,
        gridInfo: null,
        concept: null,
        partialities: 1,
        partialitiesInfo: [],
        isValidPartialitie: false,
      };

    case RECEPTION_INVOICE.SET_CURRENCY:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            currency: action.payload,
          },
        },
      };

    case RECEPTION_INVOICE.SET_TOTAL:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            total: {
              formatted: mnCurrency(action.payload),
              number: action.payload,
            },
            importe: {
              formatted: "$0.0",
              number: 0,
            },
          },
        },
      };

    case RECEPTION_INVOICE.SET_RFC:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            rfcEmitter: action.payload.rfc,
          },
        },
      };

    case RECEPTION_INVOICE.SET_RFC_ENTERPRISE:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            rfcReceptor: action.payload,
          },
        },
      };

    case RECEPTION_INVOICE.SET_EXPIRATION_DATE:
      return {
        ...state,
        expirationDate: {
          jsDate: action.payload,
          ui: new Intl.DateTimeFormat("es-MX", { dateStyle: "long" }).format(
            action.payload
          ),
        },
      };

    case RECEPTION_INVOICE.SET_CREDIT_DAYS:
      const newExpirationDate = addDays(
        state?.xmlInfo?.xmlInfo?.emitedDate?.jsDate || new Date(),
        action.payload
      );

      return {
        ...state,
        creditDays: action.payload,
        expirationDate: {
          jsDate: newExpirationDate,
          ui: new Intl.DateTimeFormat("es-MX", { dateStyle: "long" }).format(
            newExpirationDate
          ),
        },
      };

    case RECEPTION_INVOICE.SET_IVA:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            iva: {
              formatted: mnCurrency(action.payload),
              number: action.payload,
            },
          },
        },
      };

    case RECEPTION_INVOICE.SET_FOLIO:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            folio: action.payload,
          },
        },
      };

    case RECEPTION_INVOICE.SET_SOCIAL_REASON:
      console.log(action.payload);

      const expDate = addDays(new Date(), action.payload.creditDays);

      return {
        ...state,
        providerId: action.payload.id,
        creditDays: action.payload.creditDays,
        expirationDate: {
          jsDate: expDate,
          ui: new Intl.DateTimeFormat("es-MX", { dateStyle: "long" }).format(
            expDate
          ),
        },
        socialReasonExists:
          action.payload.id === null || action.payload.id === undefined
            ? false
            : true,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            socialReasonCustomer: action.payload.socialReason,
            rfcEmitter: action.payload.rfc,
          },
        },
      };

    case RECEPTION_INVOICE.SET_EMITED_DATE:
      const uiDate = new Intl.DateTimeFormat("es-MX", {
        dateStyle: "long",
      }).format(action.payload);

      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          xmlInfo: {
            ...state.xmlInfo.xmlInfo,
            emitedDate: {
              db: dateToDbFormat(dateWithCeroHours(action.payload)),
              jsDate: dateWithCeroHours(action.payload),
              ui: uiDate,
            },
          },
        },
      };

    case RECEPTION_INVOICE.SET_IS_ADDING_RECEPCION:
      return {
        ...state,
        isAddingReception: action.payload,
      };

    case RECEPTION_INVOICE.SET_INITIAL_STATE: {
      return {
        ...action.payload,
      };
    }

    case RECEPTION_INVOICE.SET_STEP:
      if (action.payload === 1) {
        return {
          ...state,
          step: action.payload,
          gridInfo: null,
          concept: null,
          partialities: 1,
          partialitiesInfo: [],
          isValidPartialitie: false,
        };
      }

      return {
        ...state,
        step: action.payload,
      };

    case RECEPTION_INVOICE.SET_XMLINFO:
      return {
        ...state,
        xmlInfo: {
          ...action.payload,
        },
        // xmlInfo: action.payload,
        xmlWasLoaded: !action.payload.isValid ? false : true,
      };

    case RECEPTION_INVOICE.SET_PDF_FILE:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          pdf: action.payload,
        },
      };

    case RECEPTION_INVOICE.SET_ONLY_EXPENSES:
      return {
        ...state,
        xmlInfo: {
          ...state.xmlInfo,
          itsOnlyExpenses: action.payload,
        },
      };

    case RECEPTION_INVOICE.SET_PROVIDER:
      const expirationDate = add(state.xmlInfo.xmlInfo.emitedDate.jsDate, {
        days: action.payload.creditDays,
      });

      return {
        ...state,
        creditDays: action.payload.creditDays,
        expirationDate: {
          jsDate: expirationDate,
          ui: format(expirationDate, "dd/MMM/yy", {
            locale: es,
          }),
        },
        providerId: action.payload.idProvider,
      };
    case RECEPTION_INVOICE.SET_AMOUNTS:
      return {
        ...state,
        gridInfo: action.payload,
      };
    case RECEPTION_INVOICE.SHOW_GRID:
      return {
        ...state,
        showExpenses: action.payload,
        concept: null,
      };
    case RECEPTION_INVOICE.SET_CONCEPT:
      return {
        ...state,
        concept: action.payload,
        gridInfo: null,
      };
    case RECEPTION_INVOICE.SET_PARTIALITIES:
      return {
        ...state,
        partialities: action.payload.partialities,
        partialitesInfo: action.payload.gridPartialities,
        isValidPartialitie: action.payload.isValidPartialitie,
      };
    default:
      return {
        ...state,
      };
  }
}

// partialitesInfo
// isValidPartialitie
