import React, { useContext, useState } from "react";
import { NotesAndAclarationsContext } from "..";

export default function Currency() {
  const { handleCurrency, currency } = useContext(NotesAndAclarationsContext);

  const [currencies, setCurrencies] = useState([
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "MXN",
      label: "MXN",
    },
    {
      value: "NA",
      label: "Ambas",
    },
  ]);

  return (
    <>
      {currencies.map((currencie, i) => (
        <div
          className="d-flex align-items-center mr-4"
          key={window.crypto.randomUUID()}
        >
          <input
            type="radio"
            name="currency"
            id={`currency-${i}`}
            checked={currency === currencie.value ? true : false}
            onChange={(e) => handleCurrency(currencie.value)}
            // onChange={(e) => console.log(e)}
          />
          <label
            className="cursorPointer d-inline-block"
            htmlFor={`currency-${i}`}
          >
            {currencie.label}
          </label>
        </div>
      ))}
    </>
  );
}
