import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import Dialog from "structure/Dialog";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import useTc, { CONTEXT_INITIAL_STATE } from "./useTc";
import { Label } from "structure/Document";
import style from "./styles.module.scss";
import { createContext } from "react";
import TcTable from "./TcTable";
import usePermissions from "customHooks/usePermissions";
import RangeFilter from "./RangeFilter";
import ExcelButton from "./ExcelButton";
import Icon from "components/individual/HeaderIcon";

registerLocale("es", es);

/**
 * @type {import("react").Context<import("./useTc/types").ReturnUseTc>}
 */
export const ContextTc = createContext(CONTEXT_INITIAL_STATE);

/**
 * Render a modal with the TC availables on the system.
 * This will use the permissions "deed93af-287e-4da3-8360-cfe9d9eba39b"
 * @param {import('./types').TcModalProps} props - Props
 * @returns {JSX.Element}
 */
export default function TcModal({ children }) {
  const hook = useTc();

  const { checkPermission } = usePermissions();

  if (!checkPermission("deed93af-287e-4da3-8360-cfe9d9eba39b")) return <></>

  return (
    <ContextTc.Provider value={hook}>
      <div onClick={hook.customOpen}>{children}</div>
      <Dialog
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.customClose}
        title="TC"
      >
        <form className={style.formTc}>
          <RangeFilter/>

          <ButtonV2
            disabled={!hook.canPerformSearch}
            onClick={hook.attemptSearch}
            type="button"
          >
            <span>Buscar</span>
            <Icon icon="search"/>
          </ButtonV2>

          <ExcelButton/>
        </form>

        <TcTable />
      </Dialog>
    </ContextTc.Provider>
  );
}
