import React from "react";
import { useContext } from "react";
import { ContextAdvertisement } from "../../Avisos";
import style from "../../Avisos.module.scss";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { add } from "date-fns";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { useRef } from "react";
import Create from "../../Create";
import MoreInfoAdvertisement from "../../MoreInfo";

/**
 *
 * @param {import('helpers/Apis/Advertisements/types').PropsRowAdvertisement} adv
 * @returns
 */
export default function Row(adv) {
  const hook = useContext(ContextAdvertisement);
  const key = useRef(window.crypto.randomUUID());

  /**
   * @type {React.MutableRefObject<HTMLElement>}
   */
  const domEdit = useRef(null);

  /**
   * @type {React.MutableRefObject<HTMLElement>}
   */
  const domMoreInfo = useRef(null);

  if (hook === null) return <></>;

  return (
    <div>
      <Create
        className="d-none"
        id={adv.advertisementID}
        onCreated={() => hook.triggerFetchSearch()}
        forwardRef={domEdit}
      />

      <input
        onChange={(e) => hook.setAdvertisement(adv)}
        name={adv.name}
        id={`${key.current}-${adv.advertisementID}`}
        type="radio"
        className="d-none"
      />
      <label
        htmlFor={`${key.current}-${adv.advertisementID}`}
        className={style.row}
      >
        <MoreInfoAdvertisement id={adv.advertisementID}>
          {`${adv.advertisementID}`}
        </MoreInfoAdvertisement>

        <p>
          {parseDateToText(add(getDateFromUtc(adv.startDate), { days: 1 }))}
        </p>
        <p>{parseDateToText(add(getDateFromUtc(adv.endDate), { days: 1 }))}</p>
        <p>{adv.description}</p>
        <p>{adv.message}</p>
        <p>{parseDateToText(getDateFromUtc(adv.createdDate))}</p>
        <p>{adv.lastUpdatedBy}</p>
        <p>{adv.descriptioStatus}</p>
      </label>

      <div className={style.mobileItem}>
        <MoreInfoAdvertisement
          forwardRef={domMoreInfo}
          id={adv.advertisementID}
        />

        <div className="d-flex justify-content-between align-items-center">
          <div className="my-2">
            <Label>Tipo</Label>
            <p>{adv.description}</p>
          </div>

          <ElipsisMobile onShow={() => hook.setAdvertisement(adv)}>
            <CustomMenuItem onClick={() => domMoreInfo.current?.click()}>
              Más información
            </CustomMenuItem>
            <CustomMenuItem onClick={() => domEdit.current?.click()}>
              Editar
            </CustomMenuItem>
          </ElipsisMobile>
        </div>

        <div className="mb-2">
        <Label>Fechas</Label>
        <p>
          Del {parseDateToText(add(getDateFromUtc(adv.startDate), { days: 1 }))}{" "}
          al {parseDateToText(add(getDateFromUtc(adv.endDate), { days: 1 }))}
        </p>
        </div>

        <Label>Mensaje</Label>
        <p>{adv.message}</p>
      </div>
    </div>
  );
}
