import React, { createContext, useContext, useState } from "react";

// COMPONENTS
import Executives from "../../../../components/general/Forms/Selects/Executives";
import {
  InputRfc,
  RFC,
  LabelRfc,
  CountryRfc,
  LabelCountryRfc,
} from "components/forms/RFC";
import { Submit } from "../../../../components/individual/buttons/Buttons";

// import AssociateCorporative from "../Add/AssociateCorporative";
import { BankInputs } from "../Add/BankInputs";
import CorporativeButton from "./Corporative";

import {
  AdressNumber,
  CellNumber,
  City,
  Colony,
  CommercialName,
  Corporative,
  CreditDays,
  Email,
  GenerateCxp,
  PhoneNumber,
  ShortName,
  SocialReason,
  State,
  Street,
  TypeCustomer,
} from "../Inputs/Inputs";

import LoginContext from "../../../../context/Login/LoginContext";

// STYLES
import {
  ContainerContact,
  ContainerCorporative,
  ContainerFiscal,
  ContainerIds,
  ContainerNames,
  ContainerTypeCustomers,
} from "../Inputs/Styles";

// LIBS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaAddClient } from "../Form/Schemas";

// HOOKS
import useAddCustomer from "../../../../customHooks/useAddCustomer";
import ModalCP from "../Add/ModalCP";
import { Spinner } from "../../../../components/individual/Spinner/Spinner";

import styled from "styled-components";
import { Informative } from "../../Documents/Inputs/General/Index";
import { createIndexedList } from "../../../../helpers/lists";
import Manufacturers from "structure/Manufacturers";

import RegimenFiscal from "components/individual/RegimenFiscal";
import { Label } from "structure/Document";
import AddTypeCustomer from "./AddTypeCustomer";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

const ContainerSpinner = styled.div`
  #spinnerCustomer {
    display: block !important;
  }
`;

export const AddContext = createContext(undefined);
const { Provider } = AddContext;

export function Addv2() {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaAddClient),
  });

  const { userId } = useContext(LoginContext);

  const [executiveToUse, setExecutiveToUse] = useState(userId);

  const [customerTypes, setCustomerTypes] = useState(null);

  const updateCustomerTypes = (customers) => {
    const indexedCustomers = createIndexedList(customers, "value");
    setCustomerTypes(indexedCustomers);
  };

  const [customer, setCustomer] = useState({
    type: 1,
    generateCxp:true,
    corporative: null,
    manufacturers: [],
    typeOfCustomer: undefined,
    fiscalRegime: "",
    bank: {
      mn: {
        value: null,
        label: "Selecciona o escribe un banco",
      },
      usd: {
        value: null,
        label: "Selecciona o escribe un banco",
      },
    },
    isValidRfc: false,
    customerRFC: "",
    customerCountry: "",
  });

  const { addCustomer, setLocationInfo, isSaving } = useAddCustomer(customer);

  /**
   * Update the type of customer that will have the record
   * @param {import("components/individual/TypeCustomerDirectory/types").TypeCustomerDirectoryData} typeOfCustomer - Information of the option
   * @returns {void}
   */
  const updateTypeOfCustomer = (typeOfCustomer) =>
    setCustomer((current) => ({
      ...current,
      typeOfCustomer,
    }));

  /**
   * Update the fiscal regimen to use
   * @param {string} fiscalRegime - Fiscal regimen
   * @returns {void}
   */
  const updateFiscalRegimen = (fiscalRegime) =>
    setCustomer({
      ...customer,
      fiscalRegime,
    });
  
  /**
   * Update the flag in order to generate the cxp
   * @param {boolean} generateCxp - Flag
   * @returns {void}
   */
  const setGenerateCxp = generateCxp => setCustomer(current=>({
    ...current,
    generateCxp
  }));

  const updateType = (id) => {
    setCustomer((customer) => ({
      ...customer,
      type: id,
    }));
  };

  const updateCorporative = (corporative) => {
    setCustomer({
      ...customer,
      corporative,
    });
  };

  const disasociateCorporative = () => {
    setCustomer({
      ...customer,
      corporative: null,
    });
  };

  /**
   * Update the rfc for the user
   * @param {boolean} isValid - Indicates if the rfc is valid
   * @param {string} country - Country selected
   * @param {string} rfc - RFC typed
   * @returns {void}
   */
  const updateRfc = (isValid, country, rfc) =>
    setCustomer((current) => ({
      ...current,
      isValidRfc: isValid,
      customerRFC: rfc,
      customerCountry: country,
    }));

  return (
    <div className={style.container}>
      <Provider
        value={{
          customer,
          disasociateCorporative,
          updateTypeOfCustomer,
          updateCorporative,
        }}
      >
        <BreadCrumV2
          path={[
            {
              route: "/inicio",
              text: "Inicio",
            },
            {
              route: "/directorio",
              text: "Directorio",
            },
            {
              route: "directorio/agregar",
              text: "Agregar",
            },
          ]}
        />
        <div className={style.abcDirectory}>
          <RFC
            onChange={(value) => {
              updateRfc(value.isValid, value.country, value.rfc);
            }}
          >
            {() => (
              <form onSubmit={handleSubmit(addCustomer, console.error)}>
                <div className={style.abcOptionsDirectory}>
                  {isSaving === true ? (
                    <ContainerSpinner>
                      <Spinner
                        hidden={false}
                        text={<LoadingText>Agregando</LoadingText>}
                      />
                    </ContainerSpinner>
                  ) : (
                    <>
                      {customer.type === 1 ? (
                        <Manufacturers
                          id={null}
                          onChange={(manufacturers) =>
                            setCustomer((current) => ({
                              ...current,
                              manufacturers,
                            }))
                          }
                        >
                          <ButtonV2 type="button">
                            <span>Fabricante</span>
                            <Icon icon="tools"/>
                          </ButtonV2>
                        </Manufacturers>
                      ) : null}

                      <CorporativeButton />

                      {customer.fiscalRegime === "" ||
                      !customer.isValidRfc ||
                      customer.typeOfCustomer === null ||
                      customer.typeOfCustomer === undefined ? null : (
                        <ButtonV2 type="submit" id="addCustomer">
                          <span>Agregar</span>
                          <Icon icon="plus"/>
                        </ButtonV2>
                      )}
                    </>
                  )}
                </div>
                <ContainerTypeCustomers>
                  <TypeCustomer
                    forwardRef={register}
                    optSelected={customer.type}
                    onClick={({ value }) => updateType(value)}
                    onLoaded={(customerTypes) =>
                      updateCustomerTypes(customerTypes)
                    }
                  />
                </ContainerTypeCustomers>

                <ContainerNames>
                  <SocialReason errors={errors} forwardRef={register} />

                  <AddTypeCustomer />
                  <CommercialName errors={errors} forwardRef={register} />
                  <ShortName errors={errors} forwardRef={register} />
                </ContainerNames>

                <ContainerIds>
                  {customerTypes !== null ? (
                    <>
                      <Informative
                        id="idCustomer"
                        name="idCustomer"
                        css="d-none"
                        readonly={true}
                        label={`ID ${customerTypes[customer.type]["text"]}`}
                      />

                      <div className="d-flex flex-column">
                        <Label
                          required={customer.fiscalRegime === "" ? true : false}
                        >
                          Régimen fiscal
                        </Label>
                        <RegimenFiscal
                          onChange={(regimen) =>
                            updateFiscalRegimen(regimen.value)
                          }
                        />
                      </div>
                    </>
                  ) : null}

                  <Executives
                    value={executiveToUse}
                    onChange={(id) => setExecutiveToUse(+id)}
                    errors={errors}
                    forwardRef={register}
                  />

<GenerateCxp
                        idTypeCustomer={customer.type}
                        value={customer.generateCxp}
                        onChange={setGenerateCxp}
                      />
                </ContainerIds>

                {/* {customer.type === 1 ? (
                  <div className="row">
                    <ProviderId>
                      <div className="col-md-6 col-sm-12 mt-3">
                        <ProviderCombo ref={register} />
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                        <ProviderKey ref={register} />
                      </div>
                    </ProviderId>
                  </div>
                ) : null} */}

                <h4 className="mt-3">Datos fiscales</h4>
                <ContainerFiscal>
                  <div className="rfc">
                    <LabelRfc />
                    <InputRfc placeholder="Escribe aquí" />
                  </div>
                  <Street errors={errors} forwardRef={register} />

                  <AdressNumber
                    errors={errors}
                    forwardRef={register}
                    css="noExt"
                    label="Número exterior *"
                    id="exteriorNumber"
                    name="exteriorNumber"
                  />
                  <AdressNumber
                    errors={errors}
                    forwardRef={register}
                    css="noInt"
                    label="Número interior"
                    id="insideNumber"
                    name="insideNumber"
                  />

                  <div className="cp">
                    <ModalCP
                      forwardRef={register}
                      onClick={(colony) => setLocationInfo(colony)}
                      errors={errors}
                    />
                  </div>

                  <Colony forwardRef={register} errors={errors} />
                  <City forwardRef={register} errors={errors} />
                  <State forwardRef={register} errors={errors} />

                  <div className="country">
                    <LabelCountryRfc />
                    <CountryRfc />
                  </div>
                </ContainerFiscal>

                <ContainerContact>
                  <PhoneNumber forwardRef={register} />
                  <CellNumber forwardRef={register} />
                  <Email errors={errors} forwardRef={register} />
                </ContainerContact>

                {customer.type === 2 || customer.type === 5 ? (
                  <div className="my-4">
                    <BankInputs
                      bank={customer.bank}
                      errors={errors}
                      forwardRef={register}
                    />
                  </div>
                ) : null}

                <ContainerCorporative>
                  <CreditDays forwardRef={register} errors={errors} />
                  <Corporative
                    forwardRef={register}
                    value={customer.corporative?.["Razon_social"]}
                    idCorporative={customer.corporative?.["ID_cliente"]}
                  />
                </ContainerCorporative>
              </form>
            )}
          </RFC>
        </div>
      </Provider>
    </div>
  );
}
