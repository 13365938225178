import styled from "styled-components";

const columns =
  "grid-template-columns: 7.5% 7.5% 7.5% 7.5% 30% 10% 10% 10% 10%;";
const columnsForAccounting =
  "grid-template-columns: 10% 10% 5% 25% 12% 12% 12% 12%;";

const Header = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 7.5% 7.5% 7.5% 7.5% 30% 10% 10% 10% 10%;
  ${(props) => (props.isForAccounting ? columnsForAccounting : columns)}
  font-weight: bold;
  text-align: center;
  align-items: center;
  p {
    margin: 0;
  }
  font-size: 0.75rem;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Row = styled.label`
  display: grid;
  justify-content: space-between;
  ${(props) => (props.isForAccounting ? columnsForAccounting : columns)}
  font-weight: normal;
  font-size: 0.75rem;
  padding: 15px 5px;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
`;

export { Header, Row };
