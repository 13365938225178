import React, { useContext } from "react";
import Table from "components/general/Table";
import { ContextMaterials } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import { mnCurrency } from "helpers/money";
import ui from "./styles.module.scss";
import Amount from "./Amount";
import { parseToFolio } from "helpers/documents";
import DetailsMaterial from "./DetailsMaterial";
import SocialReason from "./SocialReason";

/**
 * Table for the materials
 * @param {import("react-data-table-component").TableProps<import("../../../../../types/proyects/materials").DtoListMaterials>} props
 */
function TableMaterialGrid(props) {
  return <Table {...props} />;
}

export default function Grid() {
  const materials = useContext(ContextMaterials);

  if (materials.status === "adding")
    return <Spinner text={"Agregando materiales"} hidden={false} />;

  if (materials.materials.length <= 0)
    return (
      <>
        <hr />
        <p className="text-center">
          No hay materiales, usa el boton de "Agregar"
        </p>
        <p className="font-weight-bold text-center text-warning">
          Una vez surtido algun material no es posible agregar más articulos
        </p>
      </>
    );

  return (
    <TableMaterialGrid
      sortServer={false}
      highlightOnHover={false}
      pagination={false}
      className={ui.grid}
      progressPending={materials.status === "loading"}
      data={materials.materials}
      expandableRows={true}
      expandableRowsComponent={(data) => <DetailsMaterial {...data.data} />}
      columns={[
        {
          name: "Folio",
          selector: (item) => parseToFolio(item.id),
          sortable: true,
          width: "10%",
        },
        {
          name: "Descripción",
          selector: (item) =>
            item.description === null ? "No disponible" : item.description,
          sortable: true,
          width: "18%",
        },
        {
          name: "Presupuestado",
          selector: (item) => item.initialQuantity,
          sortable: true,
          width: "12%",
        },
        {
          name: "Comprado",
          selector: (item) => item.initialQuantity - item.residueQuantity,
          sortable: true,
          width: "12%",
        },
        {
          name: "Costo",
          selector: (item) => mnCurrency(item.cost),
          sortable: true,
          width: "12%",
        },
        {
          name: "Cant./OC",
          style: { width: "100%" },
          selector: (item) => <Amount {...item} />,
          width: "12%",
        },
        {
          name: "Proveedor",

          style: { display: "inline-grid" },
          selector: (item) => <SocialReason {...item} />,
          width: "20%",
        },
      ]}
    />
  );
}
