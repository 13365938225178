import React from "react";
import buttonMenu from "components/individual/HeaderIcon/index.module.scss";
import { useHistory } from "react-router-dom";
import Icon from "components/individual/HeaderIcon";

export default function ToDoQuickAccess() {
  const history = useHistory();

  return (
    <button className={buttonMenu.btn} onClick={() => history.push("/to-do")}>
      <Icon icon="bell" />
    </button>
  );
}
