import styled from "styled-components";

export const ContainerTypeCustomers = styled.div`
  div {
    display: flex;
    align-items: center;
  }
  .radioBtnElement {
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
  }
`;

export const ExampleSocialReason = styled.p`
  span:first-child:after {
    content: "➡";
  }
`;

export const ContainerIds = styled.div`
  display: grid;
  margin: 10px 0 0 0;
  grid-template-columns: 32% 32% 32%;
  grid-template-rows: 1fr;
  justify-content: space-between;
  align-items:flex-end;
  
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    > * {
      margin:0 0 1rem 0;
    }
  }
`;

export const ContainerNames = styled.div`
  display: grid;
  > * {
    margin:0 0 1rem 0;
  }
  grid-template-columns: repeat(6, 15.5%);
  grid-template-rows: repeat(2, 1fr);
  justify-content: space-between;
  grid-template-areas:
    "socRea socRea socRea socRea socRea socRea"
    "comName comName shortName shortName type type";

  .socialReason {
    grid-area: socRea;
  }

  .commercialName {
    grid-area: comName;
  }

  .shortName {
    grid-area: shortName;
  }

  .type {
    grid-area: type;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    > * {
      margin:0 0 1rem 0;
    }
  }
`;

export const ContainerFiscal = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 9%);
  grid-template-rows: repeat(3, 1fr);
  justify-content: space-between;
  grid-template-areas:
    "rfc rfc S S S S S S S S"
    "ext ext int int cp cp . . . ."
    "col col cd cd sta sta con con con con";

  .rfc {
    grid-area: rfc;
  }

  > * {
    margin:0 0 1rem 0;
  }

  .street {
    grid-area: S;
  }

  .noInt {
    grid-area: int;
  }

  .noExt {
    grid-area: ext;
  }

  .colony {
    grid-area: col;
  }

  .city {
    grid-area: cd;
  }

  .state {
    grid-area: sta;
  }

  .country {
    grid-area: con;
  }

  .cp {
    grid-area: cp;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    > * {
      margin:0 0 1rem 0;
    }
  }
`;

export const ContainerContact = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-template-rows: 1fr;
  justify-content: space-between;
  .form-control {
    width: 100%;
  }

  > * {
    margin:0 0 1rem 0;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    > * {
      margin:0 0 1rem 0;
    }
  }
`;

export const ContainerCorporative = styled.div`
  display: grid;
  grid-template-columns: 20% 79%;
  grid-template-rows: 1fr;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    > * {
      margin:0 0 1rem 0;
    }
  }
`;
