import ToggleComponent from "components/individual/inputs/Toggle";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextOldResidues } from "..";

export default function ShowDetails() {
  const hook = useContext(ContextOldResidues);

  return (
    <div>
      <Label>Detalle</Label>
      <ToggleComponent
        labelActive=""
        labelInactive=""
        isChecked={hook.showDetails}
        onChange={hook.setShowDetails}
      />
    </div>
  );
}
