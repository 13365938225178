import React, { useContext } from "react";
import { BankAccountContext } from "..";
import { Label } from "structure/Document";
import { InputToggle } from "components/individual/inputs/Inputs";

export default function Status() {
  const { register, errors, account } = useContext(BankAccountContext);

  return (
    <div>
      <Label>Estatus</Label>
      <InputToggle
        labelTrue="Activo"
        labelFalse="Inactivo"
        defaultChecked={account.isActive}
        forwardRef={register}
        name="status"
      />
      <p className="text-danger">{errors?.status?.message}</p>
    </div>
  );
}
