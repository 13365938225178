import React from "react";
import Dialog from "structure/Dialog";
import { Context2fa } from "..";
import { useContext } from "react";
import Footer from "./Footer";
import style from '../styles.module.scss';

export default function ModalCancel() {
  const hook = useContext(Context2fa);

  return (
    <Dialog
      title="Des-activar 2FA"
      forwardRef={hook.dialogCancel.refDialog}
      closeCallback={hook.customCloseCancel}
      footer={<Footer />}
    >
      <p className="my-3">
        Antes de des-activar el 2FA, se recomienda <b>NO</b> hacerlo por los
        siguientes motivos:
      </p>
      <ol className={style.twoFaExplain}>
        <li>
          <b>Mayor Seguridad</b>: El 2FA proporciona una capa adicional de
          seguridad al requerir un segundo factor de autenticación, como un
          código enviado al teléfono del usuario. Esto hace que sea mucho más
          difícil para los hackers acceder a la cuenta incluso si conocen la
          contraseña.
        </li>

        <li>
          <b>Protección contra Contraseñas Comprometidas</b>: Si la contraseña
          del usuario se ve comprometida debido a una filtración de datos u otro
          incidente, el 2FA sigue siendo una barrera efectiva. Los atacantes
          necesitarían el segundo factor para acceder a la cuenta.
        </li>

        <li>
          <b>Prevención del Acceso No Autorizado</b>: Incluso si alguien obtiene
          acceso a la contraseña del usuario, no podrán acceder a la cuenta sin
          el segundo factor. Esto protege la cuenta contra accesos no
          autorizados.
        </li>

        <li>
          <b>Defensa contra Phishing</b>: El 2FA puede ayudar a prevenir ataques
          de phishing. Incluso si un usuario cae en una trampa de phishing y
          proporciona su contraseña, el atacante aún necesitaría el segundo
          factor para acceder a la cuenta.
        </li>

        <li>
          <b>Cumplimiento y Estándares de Seguridad</b>: En muchas industrias y
          entornos regulados, el uso del 2FA es un requisito para cumplir con
          estándares de seguridad. No desactivar el 2FA ayuda a garantizar que
          se cumplan estas regulaciones y se mantenga la seguridad de los datos.
        </li>
      </ol>
    </Dialog>
  );
}
