import CorporativeCreateInvoice from "components/general/Forms/Selects/CorporativeCreateInvoice";
import React, { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";

export default function Receiver() {
  const hook = useContext(ContextCreateInvoiceV2);

  return (
    <CorporativeCreateInvoice
      value={hook.customer.id}
      onChange={hook.setReceiver}
    />
  );
}
