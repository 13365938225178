import React, { useEffect, useState } from "react";
import { getDirectory } from "helpers/Apis/Directory";
import { Spinner } from "components/individual/Spinner/Spinner";
import Select from "components/individual/SelectV4";

/**
 * Render a select
 * @param {import('./types').PropsSocialReason} props - Props
 * @returns {JSX.Element} Render a select
 */
export default function SocialReasonV2({
  type,
  includeInactives = false,
  id = null,
  onChange = () => {},
}) {
  const [state, setState] = useState({
    isLoading: true,
    directory: [],
  });

  useEffect(() => {
    (async function () {
      const apiCustomers = await getDirectory(type, includeInactives);

      const disblaedOption = {
        socialReason: "-- SELECCIONA --",
        id: null,
        isActive: false,
        rfc: "",
        creditDays: 0,
        disabled: true,
      };

      setState((current) => ({
        ...current,
        isLoading: false,
        directory: [{ ...disblaedOption }, ...apiCustomers],
      }));
    })();
  }, []);

  if (state.isLoading) return <Spinner hidden={false} text={"Cargando"} />;

  return (
    <Select
      labelToDisplay="socialReason"
      defaultSelected={{
        keyToCompare: "id",
        value: id,
      }}
      options={async () => state.directory}
      onChange={onChange}
    />
  );
}
