import React, { useEffect, useState } from "react";

import { Select, ErrorMessage } from "../../../individual/inputs/Inputs";
import { GetPayForms } from "../../../../helpers/Apis/Banks";
import { getInvoiceComboDefValue } from "helpers/Apis/parameters";
import { Label } from "structure/Document";

/**
 * Render a combo to display the cfdi options available on the system
 * @param {object} props - Props
 * @param {string} [props.id='payForms'] - Id attribute to use for the input
 * @param {string} [props.name='payForms'] - Name attribute to use for the input
 * @param {string} [props.label='Formas de pago'] - Label to display
 * @param {(payForm:import("./PayFormTypes").onChangePayForm)=>void} [props.onChangeV2=()=>{}] - Information of the cfdi selected
 * @param {object} [props.forwardRef={}] - Ref for react-hook-form
 * @param {object} [props.errors={}] - Errors ref for react-hook-form
 * @param {string} [props.css=''] - Css
 * @param {number|null|string} [props.value=null] - Value to render by default
 * @param {boolean} [props.readonly=false] - Indicates if the combo can be changed
 * @returns {JSX.Element}
 */
function PayForm({
  id = "payForms",
  name = "payForms",
  label = "Formas de pago",
  onChange = () => {},
  onChangeV2 = () => {},
  forwardRef = {},
  errors = {},
  readonly = false,
  css = "",
  value = null,
}) {
  const [payForms, setPayForms] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      const [
        apiPayForms,
        {
          value: { id },
        },
      ] = await Promise.all([
        GetPayForms(),
        getInvoiceComboDefValue("forma-pago"),
      ]);
      // const apiPayForms = await GetPayForms();
      // getInvoiceComboDefValue("forma-pago");
      setPayForms(apiPayForms);

      if (value === null) {
        onChangeV2(apiPayForms.find((payform) => payform.idPayForm === +id));
      }
    }

    initialLoad();
  }, []);

  const infoPayForm = (id) =>
    payForms.find((payform) => payform.idPayForm === id);

  return (
    <div className={`${css} itemDocument`}>
      <Label>{label}</Label>
      {!payForms.length ? null : (
        <>
          <Select
            addTodos={false}
            idSelect={id}
            value={value}
            nameSelect={name}
            // label={label}
            forwardRef={forwardRef}
            options={payForms}
            textJSON="payDescription"
            valueJSON="idPayForm"
            onChange={(value) => {
              onChange(value);
              onChangeV2(infoPayForm(+value));
            }}
            readonly={readonly}
          />

          <ErrorMessage message={errors[id]?.message} />
        </>
      )}
    </div>
  );
}

PayForm.propTypes = {};

export default PayForm;
