import React, { useRef, useState } from "react";
import { CloudFile } from "components/general/SendRelatedDocs/styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import ModalV2 from "components/individual/Modals/ModalV2";
import scss from "./styles.module.scss";
import { CloudFiles } from "structure/CloudFiles";
import { downloadFile } from "helpers/Apis/associateFilesV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import { fileArrayToFileList } from "helpers/files";
import Icon from "components/individual/HeaderIcon";

/**
 * Render a button to display the associated files to an entity and download them
 * @param {import("./types").PropsCloudFilesBtn} props - Props
 * @returns {JSX.Element}
 */
export const CloudFileBtn = ({
  idRegister = 2138,
  idTypeEntity = 1,
  onChange = () => {},
}) => {
  const idOpenModal = useRef(window.crypto.randomUUID());
  const idCloseModal = useRef(window.crypto.randomUUID());

  const [modal, setModal] = useState({
    isOpen: false,
    title: "Archivos de la nube",
    areFilesSelected: false,
    files: [],
    isDownloading: false,
  });

  const openModalFiles = () => {
    const domBtnModal = document.getElementById(idOpenModal.current);
    if (domBtnModal) {
      domBtnModal.click();
      setModal({
        ...modal,
        isOpen: true,
      });
    }
  };

  const closeModal = () => {
    const domBtnModal = document.getElementById(idCloseModal.current);
    if (domBtnModal) domBtnModal.click();
  };

  const handleOnChange = (areFilesSelected, files) =>
    setModal({
      ...modal,
      areFilesSelected,
      files,
    });

  const attemptDownload = async () => {
    setModal({
      ...modal,
      isDownloading: true,
    });

    const querysDownloadFile = modal.files.map((file) =>
      downloadFile(file.idAssocaitedFile, file.fileName, false)
    );

    const files = await Promise.all(querysDownloadFile);

    const fileListCreated = fileArrayToFileList(files);

    setModal({
      ...modal,
      isDownloading: false,
    });
    onChange({
      array: files,
      fileList: fileListCreated,
    });
    closeModal();
  };

  return (
    <>
      <ButtonV2 onClick={openModalFiles}>
        <span>Nube</span>
        <Icon icon="cloud" />
      </ButtonV2>

      <ModalV2
        showCancelButton={false}
        title="Archivos de la nube"
        idOpenModal={idOpenModal.current}
        idCloseIcon={idCloseModal.current}
        footer={
          <>
            {modal.areFilesSelected ? (
              <div>
                {modal.isDownloading ? (
                  <Spinner hidden={false} text="Adjuntando archivos" />
                ) : (
                  <ButtonV2 onClick={attemptDownload}>
                    <span>Adjuntar</span>
                    <Icon icon="send" />
                  </ButtonV2>
                )}
              </div>
            ) : null}
          </>
        }
      >
        {modal.isOpen ? (
          <CloudFiles
            idRegister={idRegister}
            idTypeEntity={idTypeEntity}
            onChange={({ areFilesSelected, files }) =>
              handleOnChange(areFilesSelected, files)
            }
          />
        ) : null}
      </ModalV2>
    </>
  );
};
