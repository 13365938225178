import React , { useContext } from "react";
import Select from "react-select";
import { Label } from "structure/Document";
import useBankTransfers from "customHooks/useBankTransfers";
import { InputCurrency } from "../inputs/Inputs";
import "./styles.css";
import { mnCurrency } from "helpers/money";
import accountComboContext from "./accountComboContext";
import DestinyImport from "./DestinyImport";
import { BankCard } from "components/individual/BankCard/BankCard";
import SubmitTransfer from "./SubmitTransfer";
import TC from "./TC";
import style from "./styles.module.scss";
import { InputDatePicker } from "components/Elements/InputDatePicker";

function BankTransferForm() {
  const hook = useContext(accountComboContext);

  return (
    <>
      <div className={style.date}>
        <Label required={hook.date === null}>Fecha</Label>
        <InputDatePicker
          maxDate={new Date()}
          placeholderText="Selecciona una fecha"
          selectedDate={hook.date}
          disable={true}
          handleDateChange={(date) => hook.updateDateTransference(date)}
        />
      </div>
      <div className={style.containerTransference}>
        <div className={style.origin} id="origen">
          <Label>Cuenta origen</Label>
          <Select
            classNamePrefix="saiko-select"
            className="saiko-select w-100"
            // menuPortalTarget={document.getElementById('origen')}
            placeholder="Selecciona"
            name="color"
            options={hook.accounts.filter((item) => item.isActive === true)}
            onChange={(value) => hook.selectaccount(value)}
          />
        </div>
        <div className={style.originAmounts}>
          <div>
            <Label>Importe</Label>
            <InputCurrency
              onChange={hook.updateOriginAmount}
              placeholder="Escribe aquí "
            />
          </div>
          <div>
            <Label>Saldo</Label>
            <p>
              {hook.originAccount.information === null
                ? "Selecciona una cuenta origen"
                : mnCurrency(hook.originAccount.information.currentBalance)}
            </p>
          </div>
          <div>
            <Label>Saldo nuevo</Label>
            <p
              className={hook.originAccount.newAmount < 0 ? "text-danger" : ""}
            >
              {mnCurrency(hook.originAccount.newAmount)}
            </p>
          </div>
        </div>
        <div className={style.originCard}>
          {hook.originAccount.information === null ? null : (
            <BankCard
              doFlip={false}
              account={hook.originAccount.information.account}
              clave={hook.originAccount.information.bank.clave}
              id={hook.originAccount.information.id}
              currency={hook.originAccount.information.currency}
              currentBalance={hook.originAccount.information.currentBalance}
              shortname={hook.originAccount.information.bank.shortName}
            />
          )}
        </div>

        <div className={style.destiny}>
          <Label>Cuenta destino</Label>
          <Select
            classNamePrefix="saiko-select"
            className="saiko-select w-100"
            placeholder="Selecciona"
            name="color"
            options={hook.newdestinyAccounts.filter(
              (item) => item.isActive === true
            )}
            onChange={(value) => hook.selectaccountdestiny(value)}
          />
        </div>

        <div className={style.destinyAmounts}>
          <div>
            <DestinyImport />
          </div>
          <div>
            <Label>Saldo</Label>
            <p>
              {hook.destinyAccount.information === null
                ? "Selecciona una cuenta destino"
                : mnCurrency(hook.destinyAccount.information.currentBalance)}
            </p>
          </div>
          <div>
            <Label>Saldo nuevo</Label>
            <p>
              {hook.destinyAccount.information === null
                ? "Selecciona una cuenta destino"
                : mnCurrency(hook.destinyAccount.newAmount)}
            </p>
          </div>
          <TC />
        </div>

        <div className={style.destinyCard}>
          {hook.destinyAccount.information === null ? null : (
            <BankCard
              doFlip={false}
              account={hook.destinyAccount.information.account}
              clave={hook.destinyAccount.information.bank.clave}
              id={hook.destinyAccount.information.id}
              currency={hook.destinyAccount.information.currency}
              currentBalance={hook.destinyAccount.information.currentBalance}
              shortname={hook.destinyAccount.information.bank.shortName}
            />
          )}
        </div>
      </div>
    </>
  );
}

function BankTransfer({ children, onSuccess = () => {} }) {
  const hook = useBankTransfers({
    onSuccess,
  });

  if (hook.accounts.length <= 0) return <></>;
  return (
    <>
      <accountComboContext.Provider value={hook}>
        {children()}
      </accountComboContext.Provider>
    </>
  );
}

export { BankTransfer, BankTransferForm, SubmitTransfer };
