import { convertPrice, percentage, truncateDecimals } from "../helpers/money";
import { useParams } from "react-router-dom";
import LoginContext from "../context/Login/LoginContext";
import { useContext } from "react";
import { substractDays } from "../helpers/dates";
import { add, sub } from "date-fns";

/**
 * Handle the repartition of the amount among the partialities to create
 * @param {number} partialities - Number of partialities to use
 * @param {number} noPartialities - Don't use
 * @param {number} total - Total amount to distribuite the amounts among the partialities
 * @param {number} reminderDays - Reminder days
 * @returns
 */
export default function usePartilities(
  partialities,
  noPartialities,
  total,
  reminderDays
) {
  const { customer } = useParams();
  const { userId } = useContext(LoginContext);

  /**
   * Update the exchange items of the document for the stamp invoice
   *
   * @param {DocumentItem[]} items - Items of the preinvoice
   * @param {number} newTcp - Tcp requested by the executive
   * @param {('MXN'|'USD')} currencyCode - currencyCode of 3 digits
   * @returns {DocumentItem[]} Items with the exchange applied
   */
  const updateTcpExchange = (items, newTcp, currencyCode) => {
    const itemsUpdated = items.map((item) => {
      if (item.currencyCode !== currencyCode) {
        return {
          ...item,
          ivaPrice: +convertPrice(
            item.ivaPrice,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          price: +convertPrice(
            item.price,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          puVenta: +convertPrice(
            item.puVenta,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          sellPrice: +convertPrice(
            item.sellPrice,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          subTotal: +convertPrice(
            item.totalImport,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          unitCost: +convertPrice(
            item.unitCost,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          unitPrice: +convertPrice(
            item.unitPrice,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
          totalImport: +convertPrice(
            item.totalImport,
            newTcp,
            item.currencyCode,
            currencyCode
          ).toFixed(2),
        };
      }

      return item;
    });

    return itemsUpdated;
  };

  /**
   * Get the total import of the document items
   * @param {DocumentItem[]} items - Items
   * @returns {number} Total import of the document items
   */
  const totalImportItems = (items) =>
    items.reduce((total, item) => (total += +item.totalImport.toFixed(2)), 0);

  const updatePartialities = (info) => {
    const newImport = +percentage(total, info.percentage).toFixed(2);

    const newPartialities = [...partialities];
    newPartialities[info.elementIndex]["price"] = newImport;
    newPartialities[info.elementIndex]["percentage"] = info.percentage;
    newPartialities[info.elementIndex]["date"] = info.date.toISOString();
    newPartialities[info.elementIndex]["reminder"] = substractDays(
      info.date,
      reminderDays
    ).toISOString();

    return newPartialities;
  };

  /**
   * Generate the partialities info on the mode of "Importe"
   *
   * @param {object} info - Partialities
   * @returns {Partialitie[]} Partialities updated
   */
  const updatePartialitiesImport = (info) => {
    const calculatedPercentage = +((100 * info.priceToUpdate) / total).toFixed(
      2
    );

    const copyPartialities = [...partialities];
    copyPartialities[info.elementIndex]["price"] = info.priceToUpdate;
    copyPartialities[info.elementIndex]["percentage"] = calculatedPercentage;
    copyPartialities[info.elementIndex]["date"] = info.date.toISOString();
    copyPartialities[info.elementIndex]["reminder"] = substractDays(
      info.date,
      reminderDays
    ).toISOString();

    return copyPartialities;
  };

  const validateCorrectImports = (partialities) => {
    const currentImport = partialities.reduce(
      (acumulator, curValue) => (acumulator += curValue.price),
      0
    );

    const importsMatch = currentImport !== total ? false : true;

    return importsMatch;
  };

  const validateCorrectPercentages = (partialities) => {
    // const currentPercentage = Math.fround(
    //   partialities.reduce(
    //     (acumulator, curValue) => (acumulator += curValue.percentage),
    //     0
    //   )
    // );

    const currentPercentage = +partialities
      .reduce((acumulator, curValue) => (acumulator += curValue.percentage), 0)
      .toFixed(2);

    const importsMatch = currentPercentage !== 100 ? false : true;

    return { importsMatch };
  };

  const updatePartialitiesDate = (date) => {
    const updatedDates = partialities.map((partialitie, i) => {
      // Get the date by value and not reference
      const dateUnreferenced = new Date(date).toISOString();

      // Get the data as object date
      const copyDate = new Date(dateUnreferenced);

      // Add one month per each partialitie

      return {
        ...partialitie,
        date: add(new Date(copyDate), {
          months: i + 1,
        }).toISOString(),
      };

      // return {
      //   ...partialitie,
      //   date: new Date(
      //     copyDate.setMonth(copyDate.getMonth() + i)
      //   ).toISOString(),
      // };
    });

    return updatedDates;
  };

  /**
   * Create the elements to show the grid of partialitites
   *
   * @param {number} total - Total amount of the invoice
   * @param {number} partialities - Number of partialities to create
   * @param {number} tc - TC used to calculate the partialities
   * @param {Date} defDateBegin - Default date to begin
   * @returns {object[]} Elements to create the grid partialities
   */
  const createGridPartialitites = (total, partialities, tc, defDateBegin) => {
    let gridElements = [];

    const remainPercentage = truncateDecimals(
      100 - Math.floor(100 / partialities) * (partialities - 1),
      2
    );

    const lastPercentage = () =>
      isClosedNumber ? 100 / partialities : remainPercentage;

    const lastPrice = (xPercentage) => percentage(total, xPercentage);

    /**
     * @type {boolean} Indicates if the percentage of the requested partialitites has decimales numbers
     */
    const isClosedNumber = Number.isInteger(100 / partialities);

    /**
     * Check what price to use on the partiality according the percentage result
     * @returns {number} Cost/Price to use on the partiality
     */
    const calculatePrice = () =>
      isClosedNumber
        ? truncateDecimals(total / partialities, 2)
        : truncateDecimals(
            percentage(total, Math.floor(100 / partialities)),
            2
          );

    /**
     * Check if the partiality must be adapted to the business rule (Interger percentages on load grid)
     * @returns {number} Percentage to use on the partiality
     */
    const calculatePercentage = () =>
      isClosedNumber ? 100 / partialities : Math.floor(100 / partialities);

    /**
     * Set the last cost/price of the percentage partiality
     * @returns {number} Cost/Price of the last partiality according the business rule
     */
    const priceLastPartialitie = () =>
      isClosedNumber
        ? truncateDecimals(total / partialities, 4)
        : truncateDecimals(lastPrice(remainPercentage), 4);

    for (let i = 0; i < partialities - 1; i++) {
      const actualDay = defDateBegin;

      // const expirationDate = new Date(
      //   actualDay.setMonth(actualDay.getMonth() + i)
      // ).toISOString();

      const expirationDate = add(actualDay, { months: i }).toISOString();

      const reminderDate = sub(new Date(expirationDate), {
        days: reminderDays,
      }).toISOString();

      // const reminderDate = substractDays(
      //   new Date(expirationDate),
      //   reminderDays
      // ).toISOString();

      gridElements.push({
        tc,
        executive: userId,
        customer: +customer,
        noPartialitie: i + 1,
        price: +calculatePrice().toFixed(2),
        percentage: calculatePercentage(),
        date: expirationDate,
        reminder: reminderDate,
      });
    }

    const actualDay = defDateBegin;

    const expirationDate = new Date(
      actualDay.setMonth(actualDay.getMonth() + (partialities - 1))
    ).toISOString();

    const reminderDate = substractDays(
      new Date(expirationDate),
      reminderDays
    ).toISOString();

    gridElements.push({
      tc,
      executive: userId,
      noPartialitie: partialities,
      customer: +customer,
      price: +priceLastPartialitie().toFixed(2),
      percentage: lastPercentage(),
      date: expirationDate,
      reminder: reminderDate,
    });

    return gridElements;
  };

  /**
   * Create the elements to show the grid of partialitites
   *
   * @param {number} total - Total amount of the invoice
   * @param {number} partialities - Number of partialities to create
   * @param {number} tc - TC used on the document
   * @returns {object[]} Elements to create the grid partialities
   */
  const gridImports = (total, partialities, tc, defDateBegin) => {
    let gridElements = [];

    const calculatePrice = truncateDecimals(total / partialities, 4);
    const calculatePercentage = 100 / partialities;

    for (let i = 0; i < partialities; i++) {
      const actualDay = defDateBegin;

      const expirationDate = new Date(
        actualDay.setMonth(actualDay.getMonth() + i)
      ).toISOString();

      const reminderDate = substractDays(
        new Date(expirationDate),
        reminderDays
      ).toISOString();

      gridElements.push({
        tc,
        executive: userId,
        customer: +customer,
        noPartialitie: i + 1,
        price: calculatePrice,
        percentage: calculatePercentage,
        date: expirationDate,
        reminder: reminderDate,
      });
    }

    return gridElements;
  };

  /**
   * Create the corresponding grid of partialities according the mode
   *
   * @param {number} total - Total amount of the invoice
   * @param {number} partialities - Number of partialities to create
   * @param {(1|2)} mode - Mode that wil be use to calculate the partialitites
   * @param {number} tc - TC used to calculate the partialities
   * @param {Date} beginDate - Date by default to start when component renders
   * @returns {object[]} Elements to create the grid partialities
   */
  const createGrid = (total, partialities, mode, tc, beginDate) => {
    if (mode === 1) {
      return createGridPartialitites(total, partialities, tc, beginDate);
    } else {
      return gridImports(total, partialities, tc, beginDate);
    }
  };

  return {
    updatePartialities,
    validateCorrectImports,
    updatePartialitiesDate,
    createGridPartialitites,
    validateCorrectPercentages,
    updateTcpExchange,
    totalImportItems,
    gridImports,
    createGrid,
    updatePartialitiesImport,
  };
}
