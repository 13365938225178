import React from "react";
import { Modal } from "components/general/Modal";
import {
  BankAccount,
  SocialReason,
  BankAccountCuenta,
  BankCard,
  BankCurrency,
  BankDate,
  BankAmount,
  NextTransfer,
  NextCheck,
  Description,
  Comment,
  ViewDescription,
  ViewCLABE,
  ViewComment,
} from "structure/BankAccount";

import scss from "structure/AddBankAccount/styles.module.scss";

export default function MoreInfoBankAccount({ id, onClose = () => {} }) {
  return (
    <>
      <BankAccount id={id}>
        {() => (
          <Modal
            isOpen={true}
            displayButton={false}
            onCloseModal={onClose}
            title="Más informacion"
          >
            {() => (
              <div className={scss.PositionForm}>
                <div className={scss.LefttSideModal}>
                  <SocialReason />
                  <BankAccountCuenta />
                  <ViewCLABE />
                  <BankCurrency />
                  <BankDate />
                  <BankAmount />
                  <NextTransfer />
                  <NextCheck />
                </div>
                <div className={scss.RightSideModal}>
                  <BankCard />
                  <ViewDescription />
                  <ViewComment />
                </div>
              </div>
            )}
          </Modal>
        )}
      </BankAccount>
    </>
  );
}
