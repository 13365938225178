import React, { useContext } from "react";
import { CuotasContext } from "../..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import LoadingText from "components/individual/TextLoading";

export default function ButtonUpdate() {
  const hook = useContext(CuotasContext);

  if (hook.isPerformingAbc)
    return (
      <Spinner text={<LoadingText>Actualizando</LoadingText>} hidden={false} />
    );

  if (
    typeof hook.year === "number" &&
    hook.executive !== null &&
    hook.executive !== undefined &&
    typeof hook.type === "string" &&
    typeof hook.power === "number" &&
    typeof hook.currency === "string"
  )
    return (
      <ButtonV2 onClick={hook.attemptUpdateFee}>
        <span>Actualizar</span>
        <Icon icon="save" />
      </ButtonV2>
    );

  return <></>;
}
