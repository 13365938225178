import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { cancelCreditNoteReceived } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { YesNoAlert } from "helpers/alerts";
import React from "react";
import { useState } from "react";

/**
 * Render a button that can cancell a credit note received
 * @param {import('./types').PropsCancelCreditNoteReceived} props - Props
 * @returns {JSX.Element}
 */
export default function CancelCreditNoteReceived({
  idCreditNoteReceived,
  isCancelated = false,
  onCancelated = () => {},
}) {
  const [state, setState] = useState({
    isCancelling: false,
  });

  const attemptCancellation = async () => {
    setState((current) => ({
      ...current,
      isCancelling: true,
    }));

    const wasCancelled = await cancelCreditNoteReceived(idCreditNoteReceived);

    if (wasCancelled) onCancelated();

    setState((current) => ({
      ...current,
      isCancelling: false,
    }));
  };

  const promptCancellation = async () =>
    YesNoAlert(
      "¿Deseas cancelar la recepcion de nota de crédito?",
      attemptCancellation
    );

  if (isCancelated) return <></>;

  if (state.isCancelling)
    return (
      <Spinner
        hidden={false}
        idSpinner="cancellingCreditNoteReceived"
        text={<LoadingText>Cancelando recepcion </LoadingText>}
      />
    );

  return <ButtonV2 onClick={promptCancellation}>Cancelar</ButtonV2>;
}
