import ButtonV2 from "components/individual/ButtonsV2/Button";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import React from "react";
import { useState } from "react";
import { saveAs } from "file-saver";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { openFileOnNewTab } from "helpers/dom";

/**
 * Render a button to download a pdf file from the blob storage
 * @param {import("pages/Ventas/NotasDeCreditoEmitidas/Options/DownloadPdf/types").PropsDownloadPdf} props - Props
 * @returns {JSX.Element}
 */
export default function PdfDownload({ filename, idBlobStorage }) {
  const [state, setState] = useState(false);

  const handleDownloadPdf = async () => {
    setState(true);
    const pdf = await downloadFileFromBlobV3(idBlobStorage);

    saveAs(pdf, `${filename}.pdf`);

    openFileOnNewTab(pdf);
    setState(false);
  };

  return (
    <div>
      {state ? (
        <Spinner
          hidden={false}
          idSpinner="downloadingPdf"
          text={<LoadingText>Descargando</LoadingText>}
        />
      ) : (
        <ButtonV2 onClick={handleDownloadPdf}>PDF</ButtonV2>
      )}
    </div>
  );
}
