import { BotonneraContainer } from "pages/Inicio/Modals/Styles";
import React, { useContext } from "react";
import { ContextInvoice } from "..";
import Edit from "./Edit";
import { CancelInvoiceButton } from "components/general/Documents/Buttons/CancelInvoiceButton";
import { CancelPreInvoice } from "components/general/CancelPreInvoice";
import AssociatedFiles from "./AssociatedFiles";
import Pdf from "./PDF";
import ModalCreateInvoiceV2 from "structure/ModalCreateInvoiceV2";
import UpdateAccounting from "components/individual/UpdateAccounting";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import { ModalEmail } from "structure/EmailV2";
import { downloadFeFiles } from "helpers/Apis/associateFilesV2";

export default function InvoiceOptions() {
  const {
    invoice,
    from,
    searchWithFilters,
    state,
    viewDocument,
    redirectToDo,
  } = useContext(ContextInvoice);

  const refreshSearching = () => {
    searchWithFilters();
  };

  if (!invoice)
    return (
      <div className={ui.container}>
        <BotonneraContainer>
          <UpdateAccounting
            type="FE"
            operationGrid={state.accountingStatusGrid.operation}
            originalGrid={state.accountingStatusGrid.original}
          />
        </BotonneraContainer>
      </div>
    );

  return (
    <div className={ui.container}>
      <BotonneraContainer>
        <ButtonV2 onClick={viewDocument}>Ver documento</ButtonV2>

        <ModalCreateInvoiceV2
          idOrder={invoice.id}
          idStatus={invoice.status.id}
          evaluatePermission={false}
          onCreated={refreshSearching}
        />

        <AssociatedFiles from={from} idDocument={invoice.idPreinvoice} />

        {invoice.status.id === 4 ? <Edit /> : null}

        <ButtonV2 onClick={redirectToDo}>To Do</ButtonV2>

        {from === "facturas_emitidas" ? (
          <ModalEmail
            idModule={invoice.id}
            files={() => downloadFeFiles(invoice.xml, invoice.pdf)}
            module="facturaEmitida"
            idDirectory={invoice.customer.id}
          >
            <ButtonV2>
              <span>Enviar correo</span>
            </ButtonV2>
          </ModalEmail>
        ) : (
          <EmailDocumentsV2 idDocument={invoice.idPreinvoice}>
            <ButtonV2>
              <span>Enviar correo</span>
            </ButtonV2>
          </EmailDocumentsV2>
        )}

        <Pdf
          id={invoice.idPreinvoice}
          type={from}
          idPdf={invoice.pdf}
          idXml={invoice.xml}
          documentNumber={invoice.numeroDocumento}
        />

        {invoice.status.id === 7 && from === "facturas_emitidas" ? (
          <CancelInvoiceButton
            uuid={invoice.uuid}
            onCancelled={searchWithFilters}
          />
        ) : invoice.status.id === 4 && from === "pedidos" ? (
          <CancelPreInvoice
            idPreinvoice={invoice.id}
            onCancelled={searchWithFilters}
          />
        ) : null}

        <UpdateAccounting
          type="FE"
          operationGrid={state.accountingStatusGrid.operation}
          originalGrid={state.accountingStatusGrid.original}
        />
      </BotonneraContainer>
    </div>
  );
}
