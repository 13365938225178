import React, { createContext } from "react";
import ModalV2 from "../../../../components/individual/Modals/ModalV2";
import Steps from "rc-steps";
import { ContainerSteps } from "./styles";
import useAddRol from "customHooks/useAddRol";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import ContinueButton from "./Step1/ContinueButton";
import ContinueButton2 from "./Step2/ContinueButton";
import AddRolButton from "./Step3/ContinueButton";
import usePermissions from "customHooks/usePermissions";
import Icon from "components/individual/HeaderIcon";

const NAVIGATION = {
  1: "1/3 - Descripcion del rol",
  2: "2/3 - Marcar permisos",
  3: "3/3 - Asignar roles que supervisa",
};

/**
 * @type {React.Context<import("types/typedef/customHooks/useRol").AddRolI|undefined>}
 */
export const AddRolContext = createContext(undefined);
const { Provider } = AddRolContext;

export default function AddRol({ onAdded = () => {} }) {
  const {
    description,
    rols,
    isActive,
    permissions,
    updateRoles,
    step,
    updateDescription,
    updatePermissions,
    handleAddRol,
    setStep,
    updateIsActive,
    isAddingRol,
    idCloseModalIcon,
    handleCloseModal,
  } = useAddRol(onAdded);

  const ButtonContinue = {
    1: <ContinueButton />,
    2: <ContinueButton2 />,
    3: <AddRolButton />,
  };

  const StepsUI = {
    1: <Step1 />,
    2: <Step3 />,
    3: <Step2 />,
  };

  const { checkPermission } = usePermissions();

  if (!checkPermission("9b9d9d4c-e87c-4516-a971-f5251493c62d")) return <></>;

  return (
    <Provider
      value={{
        description,
        rols,
        updateRoles,
        isActive,
        permissions,
        step,
        updateDescription,
        handleAddRol,
        updateIsActive,
        updatePermissions,
        isAddingRol,
        setStep,
      }}
    >
      <ModalV2
        title="Agregar un rol"
        showCancelButton={false}
        aditionalClasses=""
        buttonText={
          <>
            <span>Agregar</span>
            <Icon icon="plus" />
          </>
        }
        width={60}
        cbReject={handleCloseModal}
        footer={ButtonContinue[step]}
        idCloseIcon={idCloseModalIcon}
      >
        <ContainerSteps>
          <Steps current={step - 1}>
            <Steps.Step title="Descripcion del rol" />
            <Steps.Step title="Marcar permisos" />
            <Steps.Step title="Asignar roles que supervisa" />
          </Steps>
        </ContainerSteps>

        <p className="mobileNavigation">{NAVIGATION[step]}</p>

        {StepsUI[step]}
      </ModalV2>
    </Provider>
  );
}
