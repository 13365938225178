import React, { useContext } from "react";
import UENV2 from "components/general/Forms/Selects/UENV2";
import { ContextContract } from "structure/Contract/List";

export default function UenSelect() {
  const { updateUen, uen } = useContext(ContextContract);

  return (
    <div style={{ width: "20%" }}>
      <UENV2
        addTodos
        value={uen}
        isClearable={true}
        initialOption="Todos"
        disableInitlaOption={false}
        onChange={(uen) =>{
          updateUen(
            uen === null ? null : uen.UENID,
            uen === null ? "Todos" : uen.description
          )
        }
          
        }
      />
    </div>
  );
}
