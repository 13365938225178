import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { Options } from "./Options";
import { Warning } from "./Warning";
import { Excento } from "./Excento";
import { getIvasAvailables } from "helpers/Apis/parameters";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

const INITIAL_STATE = {
  isLoading: true,
  iva: 16,
  excento: false,
  canUseIva8: false,
  canUseIva0: false,
  canUseExempt: false,
};

/**
 * Context to handle the ivas available on the system
 * @type {import("react").Context<import("./types").ContextIvas>}
 */
export const IvasContext = createContext();
const { Provider } = IvasContext;

/**
 * Render the Combo of ivas available for the product code and postal code registered on the enterprise
 * @param {import("./types").PropsIvas} props - Props
 * @returns {JSX.Element}
 */
export const IvasCatalogue = ({
  value = 16,
  exempt = false,
  children,
  onChange = () => {},
}) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {

    setState((current) => ({
      ...current,
      iva: value,
      excento: exempt,
    }));
  }, [value, exempt]);

  /**
   * Update the iva to use on the combo
   * @param {import("./types").Ivas} iva - Information of the IVA
   */
  const updateIva = (iva) => {
    setState((current) => ({
      ...current,
      iva,
    }));
  };

  /**
   * Update if the iva is "excento"
   * @param {boolean} excento
   * @returns {void}
   */
  const updateExcento = (excento) =>
    setState((current) => ({
      ...current,
      excento,
    }));

  useEffect(() => {
    onChange({
      excento: state.excento,
      iva: state.iva,
    });
  }, [state]);

  useEffect(() => {

    (async function () {
      const ivas = await getIvasAvailables();
      
      setState((current) => ({
        ...current,
        isLoading: false,
        iva: value,
        excento: exempt,
        canUseIva8: ivas.canUseIva8,
        canUseIva0: ivas.canUseIva0,
        canUseExempt: ivas.canUseExempt,
      }));
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando IVA(s)</LoadingText>}
      />
    );

  return (
    <Provider
      value={{
        ...state,
        updateExcento,
        updateIva,
      }}
    >
      {children({ updateIva, displayExcento: state.iva === 0 })}
    </Provider>
  );
};

export { Options, Warning, Excento };
