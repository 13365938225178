import { saveAs } from "file-saver";
import { getResidueAndMovementsReport, getResidueAndMovementsXlsx } from "helpers/Apis/movements/movements";
import { dateToDbFormat, getYearRange, parseDateToText } from "helpers/dates";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateResidueAndMovements}
 */
const INITIAL_STATE = {
  idCustomerProvider: null,
  from: getYearRange().begin.js,
  to: new Date(),
  isLoading: false,
  refetch: false,
  records: [],
  socialReason: "",
  isDownloadingExcel: false,
};

/**
 * @type {import("./types").ContextResidueAndMovements}
 */
export const CONTEXT_RESIDUE_AND_MOVS = {
  ...INITIAL_STATE,
  setRange: () => {},
  attemptSearch: () => {},
  setCustomerProvider: () => {},
  breadcrum: [],
  type: "ventas",
  isValidFilter: false,
  attemptDownloadXlsx:async()=>{},
  isValidFrom: false,
  isValidTo: false,
};

/**
 * Hook to handle the report for the residues and movements
 * @param {import("../types").TypeResidueAndMovements} type - Type of report to display
 * @returns {import("./types").ReturnUseResidueAndMovements}
 */
export default function useResidueAndMovements(type) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      if (typeof state.idCustomerProvider !== "number" && typeof state.idCustomerProvider !=="string") {
        setState((current) => ({
          ...current,
          isLoading: false,
        }));
        return;
      }

      const apiData = await getResidueAndMovementsReport({
        beginDate: state.from === null ? null : dateToDbFormat(state.from),
        endDate: state.to === null ? null : dateToDbFormat(state.to),
        idSocialReason: state.idCustomerProvider,
        type,
        socialReason:state.socialReason
      });

      setState((current) => ({
        ...current,
        records: apiData.invoice,
        isLoading: false,
        socialReason: apiData.socialReason,
      }));
    })();
  }, [state.refetch, state.idCustomerProvider,state.from,state.to]);

  const attemptDownloadXlsx = async() => {

    setState(current=>({
      ...current,
      isDownloadingExcel:true
    }));

    const excel = await getResidueAndMovementsXlsx({
      beginDate:dateToDbFormat(state.from),
      endDate:dateToDbFormat(state.to),
      idSocialReason:state.idCustomerProvider,
      type:'compras',
      socialReason:state.socialReason
    });

    if(excel instanceof Blob){

      let filename = type === 'compras' ? 'Compras' : 'Ventas';
      filename+=` - Saldos y movimientos ${state.socialReason} (${parseDateToText(state.from)} - ${parseDateToText(state.to)}).xlsx`;

      saveAs(excel,filename);
    }

    setState(current=>({
      ...current,
      isDownloadingExcel:false
    }));
  }

  /**
   * Update the range date input filter search
   * @param {Date} from - From
   * @param {Date} to - To
   * @returns {void}
   */
  const setRange = (from, to) =>
    setState((current) => ({
      ...current,
      from,
      to,
    }));

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Set the customer/provider to use for the report
   * @param {number} id - Id of the customer or provider selected
   * @param {string} socialReason - Social reason of the customer
   * @returns {void}
   */
  const setCustomerProvider = (id,socialReason) =>
    setState((current) => ({
      ...current,
      idCustomerProvider: id,
      socialReason
    }));

  const checkIsValidFrom = () => {
    if (state.from === null && state.to === null) return true;

    if (state.from === null) return false;

    return true;
  };

  const checkIsValidTo = () => {
    if (state.from === null && state.to === null) return true;

    if (state.to === null) return false;

    return true;
  };

  const checkIsValidFilter = () =>
    (checkIsValidFrom() && checkIsValidTo()) ||
    typeof state.idCustomerProvider !== "number";

  const breadcrumToUse =
    type === "ventas"
      ? [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/saldos-y-movimientos",
            text: "Ventas",
          },
          {
            route: "/saldos-y-movimientos",
            text: "Saldos y movimientos",
          },
        ]
      : [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/compras/saldos-y-movimientos",
            text: "Compras",
          },
          {
            route: "/compras/saldos-y-movimientos",
            text: "Saldos y movimientos",
          },
        ];

  return {
    ...state,
    setRange,
    attemptSearch,
    setCustomerProvider,
    breadcrum: breadcrumToUse,
    isValidFrom: checkIsValidFrom(),
    isValidTo: checkIsValidTo(),
    attemptDownloadXlsx,
    isValidFilter: checkIsValidFilter(),
  };
}
