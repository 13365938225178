import React, { createContext, useEffect } from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import useAutocompleteEmail from "./useAutocomplete";
import OptionsFound from "./OptionsFound";
import CurrentList from "./CurrentList";
import { Search } from "../Search";

/**
 * @type {import("react").Context<import("./useAutocomplete/types").ReturnAutocompleteEmail>}
 */
export const ContextAutocompleteEmail = createContext(undefined);

/**
 * Render a input email with autocomplete
 * @param {import("./types").PropsAutocomplete} props - Props autocomplete
 * @returns {JSX.Element}
 */
export default function AutocompleteEmail({
  aditionalEmails = [],
  idCustomer = null,
  onChange = () => {},
  label = "Correo",
}) {
  const hook = useAutocompleteEmail({
    idCustomer,
    aditionalEmails,
  });

  useEffect(() => {
   onChange(hook.currentList.map((mail) => mail.email));
  }, [hook.currentList]);

  return (
    <ContextAutocompleteEmail.Provider value={hook}>
      <div className={ui.autocompleteEmail}>
        <Label maxLength={256} currentLength={hook.search.length}>
          {label}
        </Label>
        <Search
          value={hook.search}
          type="email"
          onFocus={(e) => hook.setFocus(true)}
          onBlur={(e) => hook.setFocus(false)}
          placeholder="Escribe aquí (obligatorio)"
          maxLength={256}
          minLength={1}
          onKeyDown={(e) => hook.handleNewEmail(e)}
          hideSearchIcon={true}
          onDelete={() => hook.updateSearch("")}
          onChange={(value) => hook.updateSearch(value)}
        />
        <OptionsFound />
        <CurrentList />
      </div>
    </ContextAutocompleteEmail.Provider>
  );
}
