import React from "react";
import { useContext } from "react";
import { MenuContext } from "..";
import TextIcon from "../TextIcon";

/**
 * Render the button to go back on the menu
 * @param {import("./types").BackMenuProps} props - Props
 * @returns {JSX.Element}
 */
export default function Back({ menu, text }) {
  const { setMenuToDisplay } = useContext(MenuContext);

  return (
    <p onClick={() => setMenuToDisplay(menu)}>
      <b>
        <TextIcon icon="back" text={text} />
      </b>
    </p>
  );
}
