import Executives from "components/individual/Executives";
import React, { useContext } from "react";
import { ContextContract } from "structure/Contract/List";

export default function ExecutiveSelect() {
  const { udpateExecutive, executive } = useContext(ContextContract);

  return (
    <Executives
      value={executive}
      onlyActives={true}
      
      includeAllOption={true}
      onChange={(data) => {
        udpateExecutive(data.value,data.TEXT);
      }}
    />
  );
}
