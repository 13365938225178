import React, { Fragment, createContext, useContext, useRef } from "react";
import useExchangeDirectory from "./useExchangeDirectory";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import Select from "react-select";

/**
 * @type {import("react").Context<import("./useExchangeDirectory/types").ReturnUseExchangeDirectory>}
 */
const ContextExchangeDirectory = createContext({
  customers: [],
  isLoading: true,
  isUpdating: false,
  targetExecutive: undefined,
  grid: [],
  availableExecutives: [],
  setNewAssociation: () => {},
});

/**
 * Render the HOC in order to create the layout to handle the change of customers on the directory beteen executives
 * @param {import("./types").PropsExchangeDirectory} props - Props
 */
export default function ExchangeDirectory({
  children = <></>,
  id,
  onChange = () => {},
}) {
  const hook = useExchangeDirectory(id, onChange);

  if (typeof id !== "number" || id <= 0) return <h1 className="text-center py-5">Proporciona un ejecutivo</h1>;

  if (hook.isLoading)
    return <Spinner text={"Cargando grid de clientes"} hidden={false} />;

  if (hook.customers.length <= 0)
    return (
      <h1 className="text-center py-5">Este ejecutivo no tiene clientes</h1>
    );

  return (
    <ContextExchangeDirectory.Provider value={hook}>
      {children}
    </ContextExchangeDirectory.Provider>
  );
}

export function GridExchange() {
  const hook = useContext(ContextExchangeDirectory);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div className={ui.exchangeExecutive}>
      <b>Ejecutivo</b>
      <b>Cliente</b>
      <b>Ejecutivo nuevo</b>

      {hook.customers.map((customer, i) => (
        <Fragment key={`${key.current}-${i}`}>
          <p>
            {hook.targetExecutive === undefined
              ? `${i+1}) - `
              : `${i+1}) ${hook.targetExecutive.shortName}`}
          </p>
          <p>{customer.socialReason}</p>
          <Select
            options={hook.availableExecutives}
            classNamePrefix="saiko-select"
            className="saiko-select"
            isClearable={true}
            placeholder="Selecciona o escribe"
            onChange={(data) => hook.setNewAssociation(customer, data, i)}
          />
        </Fragment>
      ))}
    </div>
  );
}
