import React, { useContext } from "react";
import { ContextDocuments } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ui from "./styles.module.scss";

export default function Customer() {
  const hook = useContext(ContextDocuments);

  if (hook.isLoadingCustomer)
    return <Spinner text={"Cargando"} hidden={false} />;

  return (
    <div className={ui.customerInfo}>
      <p>Razón social</p>
      <p>{hook.customer.socialReason}</p>
    </div>
  );
}
