import ModalV2 from "components/individual/Modals/ModalV2";
import useModalCatalogue from "customHooks/useModalCatalogue";
import { mnCurrency } from "helpers/money";
import React, { createContext, useContext } from "react";
import { FooterButtons } from "components/general/Forms/Selects/Catalogue/styles";
import { Label } from "structure/Document";
import { ContextCatalogue } from "..";
import Form, { PU, Cost, Quantity, Discount } from "../Form";
import InvalidDiscount from "../InvalidDiscount";
import NegativeMargin from "../NegativeMargin";
import AddItem from "./AddItem";
import { InputText } from "components/individual/inputs/Inputs";
import { ModalClaveProd, ModalUm, UenSelect, UenV3 } from "../../UenV3";
import { Excento, IvasCatalogue, Options } from "../../IvasCatalogue";

// TODO
// * Tipar los componentes
// * Renderizar dinamicamente las validaciones segun el tipo de documento
// * Editar partida
// * Agregar nueva partida

const ModalExistingItemContext = createContext();
const { Provider } = ModalExistingItemContext;

/**
 * Modal to fill the remaining data to add existing from the catalogue
 * @param {object} props - Props
 * @param {import('types/typedef/catalogue').CatalogueItemI} props.item - Information of the item in the catalogue
 * @param {"USD"|"MXN"|null} props.predominantCurrency - Currency that's being used on the document
 * @param {string} props.idOpenModal - Id of the modal to open the filling information
 * @param {(object:import("types/typedef/catalogue").onChangeAddItemI)=>void} props.onChange - New item attempt to add into system: ;
 * @param {()=>void} props.onClose - Callback to execute when modal is closed
 * @returns {JSX.Element} Modal to fill the information of item to append it to the document
 */
export default function ModalExistingItem({
  item,
  idOpenModal,
  predominantCurrency = null,
  onChange = () => {},
  onClose = () => {},
}) {
  const {
    handleOnChange,
    idCloseModal,
    updateIva,
    discount,
    isValid,
    updateUen,
    updateCu,
    updateDiscount,
    updatePu,
    updateQuantity,
    canDisplayForm,
    updateDiscountCu,
    updateDiscountPu,
    discountCu,
    isInvalidDiscount,
    isValidDiscount,
    discountPu,
    state,
    isNegativeMargin,
    isValidQuantity,
    isValidGrid,
    updateDescription,
    idDescriptionAddItem,
    isValidCostInputs,
  } = useModalCatalogue({
    item,
    idOpenModal,
    predominantCurrency,
    onChange,
  });

  const { typeDocument } = useContext(ContextCatalogue);

  return (
    <Provider
      value={{
        typeDocument,
        isValidDiscount,
      }}
    >
      <ModalV2
        title="Partida"
        showCancelButton={false}
        idOpenModal={idOpenModal}
        cbReject={() => onClose()}
        idCloseIcon={idCloseModal}
        footer={
          <FooterButtons
            displayErrors={
              isNegativeMargin || isInvalidDiscount || !isValidQuantity
            }
          >
            <div>
              <NegativeMargin isNegativeMargin={isNegativeMargin} />
              <InvalidDiscount isInvalidDiscount={isInvalidDiscount} />
            </div>

            <AddItem
              isValidGrid={
                typeDocument === "odc" ? isValidCostInputs : isValidGrid
              }
              typeDocument={typeDocument}
              onClick={handleOnChange}
            />
          </FooterButtons>
        }
      >
        {!canDisplayForm ? null : (
          <Form>
            {() => (
              <div className="container">
                <div className="row">
                  <h4 className="col-12">
                    Información del catalogo de productos y servicios
                  </h4>

                  <div className="col-sm-6 mb-1">
                    <Label>Clave</Label>
                    <span>{item.sku}</span>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <span className="d-flex">
                      <Label
                        required={
                          state.description === "" || state.description === null
                        }
                      >
                        Descripcion
                      </Label>
                      {state.description?.length}/1000
                    </span>
                    <InputText
                      defaultValue={item.description}
                      labelTxt=""
                      id={idDescriptionAddItem}
                      onChange={updateDescription}
                      maxLength={1000}
                      placeholderTxt="Escribe aquí (Obligatorio)"
                    />
                    {/* <p>{item.description}</p> */}
                  </div>
                  <div className="col-12 mb-1">
                    <Label>UEN</Label>
                    <span>{item.uen.description}</span>
                  </div>
                </div>

                <div className="row">
                  <UenV3 id={item.uen.id} onChange={updateUen}>
                    {() => (
                      <>
                        <div className="col-sm-6 mb-3">
                          <Label>Clave SAT</Label>
                          <ModalClaveProd />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <Label>UM SAT</Label>
                          <ModalUm />
                        </div>
                      </>
                    )}
                  </UenV3>
                </div>

                <IvasCatalogue
                  value={item.iva.number}
                  exempt={item.iva.exempt}
                  onChange={(iva) => updateIva(iva.iva, iva.excento)}
                >
                  {({ displayExcento }) => (
                    <div className="row mb-3">
                      <div className="col-4">
                        <Label>Moneda</Label>
                        {item.currency.code}
                      </div>
                      <div className="col-4">
                        <Label>IVA</Label>
                        <Options />
                        {/* {item.iva.number}% */}
                      </div>

                      {displayExcento ? (
                        <div className="col-4">
                          <Label>Excento</Label>
                          <Excento />
                        </div>
                      ) : null}
                    </div>
                  )}
                </IvasCatalogue>

                <div className="row">
                  <div className="col-4">
                    <Cost value={item.cu.number} onChange={updateCu} />
                  </div>

                  <div className="col-4">
                    <PU value={item.pu.number} onChange={updatePu} />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <hr />
                  </div>
                  <h4 className="col-12">Aplicable al documento</h4>
                  <div className="col-4">
                    <Quantity onChange={updateQuantity} />
                  </div>

                  <div className="col-4">
                    <Discount
                      value={discountCu}
                      onChange={({ value }) => updateDiscountCu(value)}
                    />
                  </div>

                  <div className="col-4">
                    <Discount
                      label="Desc. al cliente"
                      value={discountPu}
                      onChange={({ value }) => updateDiscountPu(value)}
                    />
                  </div>

                  <div className="col-12 d-flex mt-1">
                    <Label>Costo U. de la operacion</Label>
                    {mnCurrency(state.importCu)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex">
                    <Label>Precio unitario de venta</Label>
                    {mnCurrency(state.importPu)}
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </ModalV2>
    </Provider>
  );
}
