import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { ContextAuthsInvoice } from "..";

export default function NoLegalDocument() {
  const hook = useContext(ContextAuthsInvoice);

  return (
    <div>
      <b>No. factura</b>
      <Search
        onDelete={() => hook.setFolio("", "noLegalDocument")}
        hideSearchIcon={true}
        type="number"
        placeholder="Escribe aquí"
        onChange={(data) => hook.setFolio(data, "noLegalDocument")}
      />
    </div>
  );
}
