import { BankCard } from "components/individual/BankCard/BankCard";
import { useBanks } from "components/individual/BankCard/hooks/useBanks";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import { createContext } from "react";
import scss from "./styles.module.scss";
import usePermissions from "customHooks/usePermissions";
import MobileItems from "./MobileItems";
import { Forbidden } from "pages/403";

/**
 * @type {import("react").Context<import("components/individual/BankCard/hooks/types").ContextBank>}
 */
export const BankContext = createContext({ triggerRefetch: () => {} });

export default function BanksV2() {
  const { breadcrum, triggerRefetch, actives, inactives } = useBanks();

  usePermissions([
    "8e1659e5-740b-4cdb-a02d-373433d3e122",
    "23e03d36-ad09-4cb3-80b7-26f91e88ca81"
  ]);

  return (
    <div className={scss.containerBankModule}>
      <BankContext.Provider
        value={{
          triggerRefetch,
        }}
      >
        <BreadCrumV2 path={breadcrum} />
        <button
          id="triggerRefetchAccounts"
          onClick={triggerRefetch}
          className="d-none"
        ></button>

        <div className={scss.sectionCard}>
          <h2>Activos</h2>
          <MobileItems items={actives} />
          <div className={scss.containerBankAccounts}>
            {actives.map((item) => (
              <BankCard
                currency={item.currency}
                clave={item.bank.clave}
                shortname={item.bank.shortName}
                account={item.account}
                id={item.id}
                currentBalance={item.currentBalance}
                doFlip={true}
                bankAccountInfo={item}
                key={`active-accounts-${item.id}`}
              />
            ))}
          </div>
        </div>

        <hr />

        <div className={scss.sectionCard}>
          <h2>Inactivos</h2>
          <MobileItems items={inactives} />

          <div className={scss.containerBankAccounts}>
            {inactives.map((item) => (
              <BankCard
                currency={item.currency}
                clave={item.bank.clave}
                shortname={item.bank.shortName}
                account={item.account}
                id={item.id}
                currentBalance={item.currentBalance}
                doFlip={true}
                bankAccountInfo={item}
                key={`inactive-accounts-${item.id}`}
                isActive={false}
              />
            ))}
          </div>
        </div>
      </BankContext.Provider>
    </div>
  );
}
