import React, { useContext } from "react";
import { ContextEgressConcept } from "..";
import InformativeTooltip from "components/individual/InfoTooltip";

export default function AdvanceError({ id }) {
  const hook = useContext(ContextEgressConcept);

  if (id !== hook.idAdvance) return <></>;

  if (!hook.isValidAdvance)
    return (
      <div className="d-flex">
        <InformativeTooltip>
          <ol className="font-weight-bold">
            <li>Elimina el monto de "Anticipo proveedor"</li>
            <li>
              Solo puede haber anticipo cuando hay un proveedor seleccionado
            </li>
            <li>
              Elimina los montos de los conceptos que nos sean "Anticipo
              proveedor"
            </li>
          </ol>
        </InformativeTooltip>
        <p className="text-danger font-weight-bold"> 👈 Inválido</p>
      </div>
    );
}
