import React, { Fragment, useRef } from "react";
import ui from "./styles.module.scss";
import FormProyect, {
  Buyer,
  BuyerEmail,
  BuyerPhone,
  Comments,
  Link,
  NoRfq,
  Solped,
  Title,
  User,
  UserEmail,
  UserPhone,
  CustomersField,
  ContainerUpdateProyect,
} from "structure/FormProyect";
import Details from "components/general/Details";
import FormPositionStructured from "structure/FormPositionStructured";
import useFormProyectsStructured from "./useFormProyectsStructured";
import DeletePosition from "./DeletePosition";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import useInnerNavigation from "customHooks/useInnerNavigation";
import CancelPosition from "components/general/CancelPosition";
import Swal from "sweetalert2";

/**
 *
 * @param {import('structure/FormProyect/types').PropsFormProyect} props - Props for FormProyect
 */
export default function FormProyectsStructured(props) {
  const proyect = useFormProyectsStructured(props.idProyect);

  const key = useRef(`${window.crypto.randomUUID()}`);

  useInnerNavigation([proyect.loaded]);

  return (
    <div className={`${ui.formProyect}`}>
      <FormProyect
        {...props}
        className={ui.headerForm}
        id="proyects"
        positions={proyect.positions}
        onLoadedProyect={(dto) => {
          proyect.setRfq(dto?.noRFQ);
          proyect.setSolped(dto?.solped);
          proyect.bulkInsert(
            (dto?.positions || []).map((item) => ({
              id: item.id,
              cost: item.cost,
              description: item.description,
              iva: item.ivaCostRate,
              ocCustomer: item.ocCustomer,
              percentageOfCompletion: item.percentageOfCompletion,
              pos: item.pos,
              satCode: `${item.satKey} - ${item.satDescription}`,
              satUm: `${item.um} - ${item.umDescription}`,
              sell: item.sell,
              status: item.statusPosition,
              uen: item.idUen,
            }))
          );
          proyect.setLoaded(true);
        }}
      >
        <div className="row">
          <CustomersField className="col-12" />
          <Title className="col-md-12 col-sm-12" />
          <NoRfq
            className="col-sm-12 col-md-6"
            onChange={(rfq) => proyect.setRfq(rfq)}
          />
          <Solped
            className="col-md-6 col-sm-12"
            onChange={(solped) => proyect.setSolped(solped)}
          />
        </div>

        <Details summary={"Comprador"} open>
          <div className="row">
            <Buyer className="col-md-12 col-sm-12" />
            <BuyerEmail className="col-md-6 col-sm-12" />
            <BuyerPhone className="col-md-6 col-sm-12" />
          </div>
        </Details>

        <Details summary={"Usuario"} open>
          <div className="row">
            <User className="col-md-12 col-sm-12" />
            <UserEmail className="col-md-6 col-sm-12" />
            <UserPhone className="col-md-6 col-sm-12" />
          </div>
        </Details>

        <Comments />

        <div className="row">
          <Link className="col-sm-12" />
        </div>
      </FormProyect>

      <div id="posicion"></div>
      <Details summary={"Agregar posición"} open>
        <FormPositionStructured
          rfq={proyect.rfq}
          solped={proyect.solped}
          idProyect={props.idProyect}
          onCorrectSubmit={(position) => {
            proyect.addPositionIntoMemory(position);
            Swal.fire({
              icon: "success",
              title: "Posición agregada",
              text: "La posición se ha agregado, el cambio se reflejara cuando guardes el proyecto",
            });
          }}
        />
      </Details>

      {proyect.positions.map((position, i) => (
        <Fragment key={`${key.current}-${i}`}>
          <div id={`${position?.id || `${key.current}-${i}`}`}></div>
          <Details open summary={`#${i + 1} | ${position.pos}`}>
            <FormPositionStructured
              rfq={proyect.rfq}
              solped={proyect.solped}
              data={position}
              onCorrectSubmit={(pos) => {
                proyect.updatePosition(pos, i);
                Swal.fire({
                  icon: "success",
                  title: "Posición actualizada",
                  text: "La posición se ha actualizado, el cambio se reflejara cuando guardes el proyecto",
                });
              }}
              labelButtonSubmit="Actualizar posición"
            />
            {position.id <= 0 || typeof position.id !== "number" ? (
              <DeletePosition
                onConfirmed={() => proyect.deletePositionFromMemory(i)}
              />
            ) : (
              <CancelPosition
                className="my-2 w-100"
                idPosition={position.id}
                onCancelated={console.log}
              >
                <ButtonV2 className="w-100">
                  <span>Cancelar posición</span>
                  <Icon icon="trash" />
                </ButtonV2>
              </CancelPosition>
            )}
          </Details>
        </Fragment>
      ))}

      {proyect.positions.length === 0 ? (
        <p className="m-0 font-weight-bold text-danger">Al menos 1 posición</p>
      ) : null}

      <div className={ui.buttonContainer}>
        <ButtonV2 type="submit" form="proyects">
          <span>
            {props.idProyect > 0 ? "Actualizar proyecto" : "Crear proyecto"}
          </span>
          <Icon icon="save" />
        </ButtonV2>
      </div>
    </div>
  );
}
