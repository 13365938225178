import React, { useContext } from "react";
import Dialog from "structure/Dialog";
import { ContextTickets } from "..";
import Ticket from "components/general/Ticket";

export default function MoreInfo() {
  const hook = useContext(ContextTickets);

  if (!hook.state.isOpenMoreInfo) return;

  return (
    <Dialog
      width={85}
      title={`Ticket #${hook.state.ticket.folio}`}
      forwardRef={hook.dialog.refDialog}
      closeCallback={hook.closeMoreInfo}
    >
      <Ticket
        ticket={hook.state.ticket}
        id={hook.state.ticket.id}
        onChange={() => hook.refetchTickets()}
      />
    </Dialog>
  );
}
