import React, { createContext } from "react";
import useHistoricalContracts, { ContextState } from "./useHistoricalContracts";
import style from "./styles.module.scss";
import HistoricalContractsExecutive from "./ExecutiveFilter";
import UenFilter from "./UenFilter";
import SocialReasonFilter from "./SocialReasonFilter";
import SectorFilter from "./SectorFilter";
import SearchFilter from "./SearchFilter";
import ExcelButton from "./ExcelButton";
import Table from "./Table";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import FilterMobile from "structure/FilterMobile";

export const ContextHistoricalContracts = createContext(ContextState);

export default function HistoricalContracts() {
  const hook = useHistoricalContracts();

  return (
    <div className={style.historical}>
    <ContextHistoricalContracts.Provider value={hook}>
      <div className={style.headerModule}>
        <BreadCrumV2 path={hook.breadcrum} />

        <div className={style.margin}>
          <div className={style.filter}>
            <HistoricalContractsExecutive />
            <UenFilter />
            <SocialReasonFilter />
            <SectorFilter />
            <SearchFilter />
            <ExcelButton />
          </div>
        </div>

        <div className={style.margin}>
          <FilterMobile pills={hook.pillsFilterMobile}>
            <HistoricalContractsExecutive />
            <UenFilter />
            <SocialReasonFilter />
            <SectorFilter />
          </FilterMobile>
        </div>
      </div>

      <div className={style.margin}>
        <Table />
      </div>
    </ContextHistoricalContracts.Provider>
    </div>
  );
}
