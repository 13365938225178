/* LOGIN */
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_SCREEN_LOGIN = "SET_SCREEN_LOGIN";
export const LOG_USER = "LOG_USER";
export const SET_SESSION_COOKIE = "SET_SESSION_COOKIE";
export const SET_FILTER = "SET_FILTER";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const SET_USER_TO_REPRESENT = "SET_USER_TO_REPRESENT";
export const SHOW_OTP = "SHOW_OTP";
export const TOGGLE_SPINNER = "TOGGLE_SPINNER";
export const TOGGLE_2FA = "TOGGLE_2FA";
/* USERS SCREEN */
export const SET_DATA = "SET_DATA";
export const SET_LOADING_DATA = "SET_LOADING_DATA";

/* TABLE */
export const SET_FOUNDED_ROWS = "SET_FOUNDED_ROWS";
export const SET_PAGES = "SET_PAGES";
export const SET_INFO_ROW = "SET_INFO_ROW";
export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_CRYTERIAN_ORDER = "SET_CRYTERIAN_ORDER";
export const SET_ICON_ORDERING = "SET_ICON_ORDERING";
export const SET_ACTUAL_PAGE = "SET_ACTUAL_PAGE";
export const SET_RENDER = "SET_RENDER";
export const SET_RELOAD = "SET_RELOAD";
export const SET_CALL_API = "SET_CALL_API";
export const SET_LENGTH_DATA = "SET_LENGTH_DATA";

/* VIEW */
/* To set the view of our components */
export const SET_VIEW = "SET_VIEW";
export const SET_LOADING_USER = "SET_LOADING_USER";

/* PERMISSIONS */
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_BANNER = "SET_BANNER";

/* UsersCheckbox */
export const SET_TO_ADD = "SET_TO_ADD";
export const SET_TO_DELETE = "SET_TO_DELETE";

// ADD DOCUMENT
export const SET_SCREEN_ADD_DOC = "SET_SCREEN_ADD_DOC";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_EDIT_COMMENT = "SET_EDIT_COMMENT";
export const SET_IS_SORTING = "SET_IS_SORTING";
export const SET_CURRENCY_BEING_USED = "SET_CURRENCY_BEING_USED";
export const SET_ISFETCHING_DOCUMENT = "SET_ISFETCHING_DOCUMENT";

// DOCUMENTS
export const SET_DOCUMENT_INFO = "SET_DOCUMENT_INFO";
export const SET_RANGE_DATE_FILTER = "SET_RANGE_DATE_FILTER";
export const SET_TYPE_DOCUMENT = "SET_TYPE_DOCUMENT";
export const SAVE_STATUS_DOCUMENT = "SAVE_STATUS_DOCUMENT";
export const SET_IS_NEW_GAME = "SET_IS_NEW_GAME";
export const SET_GAME = "SET_GAME";
export const SET_IVAS = "SET_IVAS";
export const SET_GAMES = "SET_GAMES";
export const SET_TCP = "SET_TCP";
export const SET_CURRENCY_TYPE = "SET_CURRENCY_TYPE";
export const SET_UEN_LIST = "SET_UEN_LIST";
export const SET_NEW_IVAS_GAME = "SET_NEW_IVAS_GAME";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_CATALOGUE = "SET_CATALOGUE";
export const SET_CURRENCY_NAME = "SET_CURRENCY_NAME";
export const SET_RENDER_SCREEN = "SET_RENDER_SCREEN";
export const SET_SHOP_ITEM = "SET_SHOP_ITEM";
export const DELETE_COMMENTS = "DELETE_COMMENTS";
export const DELETE_DOC_ITEM = "DELETE_DOC_ITEM";
export const SET_DOC_INFO = "SET_DOC_INFO";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const SET_INDEXED_CURRENCIES = "SET_INDEXED_CURRENCIES";
export const SET_CURRENCY_INFO = "SET_CURRENCY_INFO";
export const SET_EXCHANGE_TCP = "SET_EXCHANGE_TCP";
export const RESET_ADD_DOCUMENT = "RESET_ADD_DOCUMENT";
export const SET_QUOTE = "SET_QUOTE";

// WIN QUOTE
export const SET_QUOTE_INFO = "SET_QUOTE_INFO";
export const SET_QUOTE_COMMENTS = "SET_QUOTE_COMMENTS";
export const SET_QUOTE_DOC_ITEMS = "SET_QUOTE_DOC_ITEMS";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_INVOICE_CREDIT_DAYS = "SET_INVOICE_CREDIT_DAYS";
export const SET_NO_CONTRACT = "SET_NO_CONTRACT";
export const RESET_TEMP_IMPORT = "RESET_TEMP_IMPORT";
export const RESET_TEMP_ITEMS = "RESET_TEMP_ITEMS";
export const SET_OC_COMMENTS = "SET_OC_COMMENTS";
export const SET_PREINVOICE_COMMENTS = "SET_PREINVOICE_COMMENTS";
export const RESET_COMMENTS_WINQUOTE = "RESET_COMMENTS_WINQUOTE";
export const SET_IVA_INFO = "SET_IVA_INFO";
export const SET_IMPORT_PRICE = "SET_IMPORT_PRICE";
export const SET_CONTRACT_COMMENTS = "SET_CONTRACT_COMMENTS";
export const SET_LOAD_COSTS = "SET_LOAD_COSTS";
export const SET_COST = "SET_COST";
export const SET_GENERATE_CONTRACT = "SET_GENERATE_CONTRACT";
export const SET_COST_ERRORS = "SET_COST_ERRORS";
export const RESET_STATE_WIN = "RESET_STATE_WIN";
export const SET_ARE_MIXED_CURRENCIES = "SET_ARE_MIXED_CURRENCIES";
export const SET_COSTS_WIN_QUOTE = "SET_COSTS_WIN_QUOTE";

export const WIN_QUOTE = {
  SET_REMINDER_CONTRACT: "SET_REMINDER_CONTRACT",
  UPDATE_OC_REMINDER_DATE: "UPDATE_OC_REMINDER_DATE",
  UPDATE_CONTRACT_EXPIRATION: "UPDATE_CONTRACT_EXPIRATION",
  UPDATE_CONTRACT_REMINDER: "UPDATE_CONTRACT_REMINDER",
  SET_COMMENTS_OC: "SET_COMMENTS_OC",
  INVOICE_SET_REMINDER: "INVOICE_SET_REMINDER",
  INVOICE_SET_EXPIRATION: "INVOICE_SET_EXPIRATION",
  UPDATE_CFDI: "UPDATE_CFDI",
  SET_ODC_ITEMS: "SET_ODC_ITEMS",
  UPDATE_PAY_FORM: "UPDATE_PAY_FORM",
  UPDATE_PAY_METHOD: "UPDATE_PAY_METHOD",
  SET_ODC_CURRENCY: "SET_ODC_CURRENCY",
  SET_CONTACTS_QUOTE: "SET_CONTACTS_QUOTE",
  SET_COMMENTS_INVOICE: "SET_COMMENTS_INVOICE",
  SET_CONTACT_DOCUMENT: "SET_CONTACT_DOCUMENT",
  SET_INFO: "SET_INFO",
  SET_CONTRACT_COMMENTS: "SET_CONTRACT_COMMENTS",
  SET_COST_VALIDATIONS: "SET_COST_VALIDATIONS",
  SET_VALIDATIONS_OC: "SET_VALIDATIONS_OC",
  SET_QUOTE_EXPIRATION: "SET_QUOTE_EXPIRATION",
  SET_QUOTE_REMINDER_DATE: "SET_QUOTE_REMINDER_DATE",
  SET_CLIENT_PREINVOICE: "SET_CLIENT_PREINVOICE",
  SET_OC_EXPIRATION: "SET_OC_EXPIRATION",
  SET_PROVIDER_OC: "SET_PROVIDER_OC",
  SET_DEF_CONTRACT_CONTACT: "SET_DEF_CONTRACT_CONTACT",
  SET_CONTACT_OC: "SET_CONTACT_OC",
  SET_CREDIT_DAYS_OC: "SET_CREDIT_DAYS_OC",
  SET_GENERATE_CXP: "SET_GENERATE_CXP",
  SET_GENERATE_CONTRACT: "SET_GENERATE_CONTRACT",
  SET_CONTACT_CONTRACT: "SET_CONTACT_CONTRACT",
  SET_EXPIRATION_CONTRACT: "SET_EXPIRATION_CONTRACT",
  SET_CODE_CONTRACT: "SET_CODE_CONTRACT",
  SET_DISCOUNT_COST: "SET_DISCOUNT_COST",
  SET_COST_ITEM: "SET_COST_ITEM",
  SET_FLAGS: "SET_FLAGS",
  SET_MEMORY_CONTACT: "SET_MEMORY_CONTACT",

  SET_CONTACT_DOCUMENT_EXISTS: "SET_CONTACT_DOCUMENT_EXISTS",
  SET_NEW_CONTACT: "SET_NEW_CONTACT",
  SET_CONTACT_QUOTE: "SET_CONTACT_QUOTE ",
  SET_CONTACT_PREINVOICE: "SET_CONTACT_PREINVOICE",

  SET_CONTACT_CONTRACT2: "SET_CONTACT_CONTRACT2",

  SET_CONTRACT_START_DATE: "SET_CONTRACT_START_DATE",
};

export const MOVEMENTS = {
  SET_RANGE_DATE: "SET_RANGE_DATE",
  SET_STATUS: "SET_STATUS",
};


