import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { Icon } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

const Percent = <FontAwesomeIcon icon={faPercent} />;

/**
 * Show an icon to warn if the percentage meta was not accomplished
 * @param {object} props - Props
 * @param {number} props.percentage - Percentage got on the item
 * @param {number} props.minPercentage - Min percentage to accomplished the meta of the item
 * @returns {JSX.Element}
 */
export default function WarningPercent({ percentage = 0, minPercentage = 0 }) {
  return percentage >= minPercentage ? null : (
    <Tooltip
      placement="top"
      trigger={["hover"]}
      overlay={
        <span>
          Margen esperado de {minPercentage}%, pero se obtuvó {percentage}%
        </span>
      }
    >
      <Icon color="#1d7600">{Percent}</Icon>
    </Tooltip>
  );
}
