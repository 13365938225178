import React, { useContext } from "react";
import ComboCancelation from "../ComboCancelation";
import CancelationMotive from "../CancelationMotive";
import { ContextCancelInvoice } from "..";

export default function CancelationBody() {
  const hook = useContext(ContextCancelInvoice);

  return (
    <>
      <ComboCancelation />

      {hook.idMotive === 7 ? <CancelationMotive /> : null}
    </>
  );
}
