import React, { useState, useEffect } from "react";

/**
 * Render a time counter base on ms
 * @param {import("./types").PropsTimeCounter} props - Props
 * @returns {JSX.Element}
 */
function TimeCounter({ initialTime = 1 }) {
    const [remainingTime, setRemainingTime] = useState(initialTime);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => {
          if (prevTime <= 0) {
            clearInterval(Number(intervalId));
            return 0;
          } else {
            return prevTime - 1000; // Decrease by 1 second (1000 milliseconds)
          }
        });
      }, 1000); // Update every second
  
      return () => clearInterval(Number(intervalId)); // Cleanup function to clear the interval on component unmount
    }, [initialTime]); // Add initialTime as a dependency to useEffect
  
    // Convert remaining time to minutes and seconds
    const minutes = Math.floor(remainingTime / (1000 * 60));
    const seconds = Math.floor((remainingTime / 1000) % 60);
  

  return (
    <div className="d-inline-block text-danger">
      {minutes} minutos {seconds} segundos
    </div>
  );
}

export default TimeCounter;
