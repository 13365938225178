import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";
import { dateToDbFormat } from "helpers/dates";

/**
 * Update the concilations
 * @param {number[]} toConciliate - Movements to conciliate
 * @param {number[]} toDesconcialite - Movement to desconciliate
 * @param {number[]} toAccount - Movements to mark as accounted
 * @param {number[]} toDisaccount - Movements to mark as disaccounted
 * @returns {Promise<boolean>}
 * @example
 * (async function(){
 *  const wasUpdated = await conciliate([1,2],[3,5]);
 * })()
 */
export async function conciliate(
  toConciliate = [],
  toDesconcialite = [],
  toAccount = [],
  toDisaccount = []
) {
  try {
    const response = await fetch(`${URL_BASE}bancos/conciliacion/consiliar`, {
      body: JSON.stringify({
        idMovementsToConciliate: toConciliate,
        idMovementsToAssociate: toDesconcialite,
        idMovementsToPoste: toAccount,
        idMovementsToUnposte: toDisaccount,
      }),
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();
      Success(() => {}, data.message);
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the overview information of the complement associated to a movement. You need the id of the movement to get this
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<import("../../../../../types/conciliations".DtoMovementOverview)|undefined>} Information of the complement requested
 */
export async function getComplementOverview(idMovement) {
  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/mas-informacion/${idMovement}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();

      return data;
    }
  } catch (error) {
    return {
      associations: [],
      complement: undefined,
    };
  }
}

/**
 * Attempt the query in order to close a period
 * @param {number} bankAccount - Id of the bank account to close
 * @param {number} amount - Amounth to validate against the incomings and egresses
 * @param {Date} date - Last day of the period to close
 * @returns {Promise<boolean>} True if period was close success
 */
export async function closePeriod(bankAccount, amount, date) {
  const DTO = {
    currentBankAmount: amount,
    idAccount: bankAccount,
    date: dateToDbFormat(date),
  };

  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/cerrar/periodo`,
      {
        body: JSON.stringify(DTO),
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the concept associated to a movement (it can be just one)
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<import("../../../../../types/conciliations").ConceptAssociation>} Information of the concilation
 */
export async function getConceptAssociatedToIncoming(idMovement) {
  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/concepto/${idMovement}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const concept = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return concept;
    }

    console.log(concept);

    return undefined;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

/**
 * Dis-associate a movement of type concept incoming
 * @param {number} idMovement - Id of the movement tried to be dis-associated
 */
export async function disassociateConcept(idMovement) {
  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/concepto/${idMovement}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the associations made to an egress of type non deductible
 * @param {number} idMovement - Id of the movement
 * @param {boolean?} includeInactives - True if you want to include the dis-associated movements
 * @returns {Promise<import("../../../../../types/conciliations").AssociationExpenseNonDeductible[]>}
 */
export async function getNonDeductibleAssociations(
  idMovement,
  includeInactives = false
) {
  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/gastos-no-deducibles/${idMovement}?incluirInactivos=${+includeInactives}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );

    const data = response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Disassociate an egress that is related with
 * @param {number} idMovement - Id of the movement to dis-associate
 * @param {number[]} deductible - Ids of deductibles to dis-associate
 * @param {number[]} noDeductible - Ids of the no deductible to dis-associate
 * @returns {Promise<boolean>}
 */
export async function disassociateEgressConcept(
  idMovement,
  deductible,
  noDeductible
) {
  /**
   * DTO in order to dis-associate a movement egress associated with concepts
   * @type {import("../../../../../types/banks").DisassociationConceptsExpensesI}
   */
  const DTO = {
    idMovement,
    dectuble: deductible,
    noDeductible,
  };

  try {
    const response = await fetch(
      `${URL_BASE}bancos/movimientos/desasociar/conceptos/egresos`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(DTO),
      }
    );

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);

      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get all the movements to conciliate on the bank account requested
 * @param {number} idBankAccount - Id of the bank account to fetch his movements
 * @param {string} date - Date of the month to get his movements to conciliate
 * @returns {Promise<Blob|undefined>}
 */
export async function getConcilationExcel(idBankAccount, date) {
  try {
    const response = await fetch(
      `${URL_BASE}bancos/conciliacion/movimientos/excel?idAccount=${idBankAccount}&date=${date}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      const excel = await response.blob();

      return excel;
    }

    const data = await response.json();

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get all the movements to conciliate on the bank account requested
 * @param {import("./types").AccountingType} type - Type of accounting to perform
 * @param {number[]} [toAccount=[]] - Id of the bank account to fetch his movements
 * @param {number[]} [toDisaccount=[]] - Date of the month to get his movements to conciliate
 * @returns {Promise<boolean>}
 */
export async function updateAccounting(type, toAccount, toDisaccount) {
  /**
   * @type {import("./types").AccountingIndexed}
   */
  const INDEXED_IDS = {
    FE: 1,
    FR: 2,
    I: 3,
    E: 4,
    OC: 5,
    OCDetails: 8,
    NCE:6,
    NCR:7,
  };

  try {
    if (INDEXED_IDS[type] === 8) {
      const body = JSON.stringify({
        accounted: toAccount,
        deaccounted: toDisaccount,
        idSummary: INDEXED_IDS[type],
      });

      const response = await fetch(`${URL_BASE}contabilidad/ocnr/detalle`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        body,
      });

      const data = await response.json();

      if (response.ok && isValidHttpResCode(response.status)) {
        Success(() => {}, "Actualizado");
        return true;
      }

      Error(() => {}, data.message);
      return false;
    }
  } catch (error) {
    return false;
  }

  try {
    const body = JSON.stringify({
      accounted: toAccount,
      deaccounted: toDisaccount,
      idFrom: INDEXED_IDS[type],
    });

    const response = await fetch(`${URL_BASE}contabilidad/actualizar`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body,
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, "Actualizado");
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the accounting for the OC
 * @param {number} year - Year
 * @returns {Promise<import("../../../../../types/accounted/accounted").GetOCNRRespI[]>}
 */
export async function getOcAccounting(year) {
  try {
    const response = await fetch(`${URL_BASE}contabilidad/ocnr?year=${year}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the accounting for the OC
 * @param {number} id - Year
 * @returns {Promise<import("../../../../../types/accounted/accounted").GetOCNRRespI[]>}
 */
export async function getDetailOcAccounting(id) {
  try {
    const response = await fetch(`${URL_BASE}contabilidad/ocnr-detalle/${id}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the available years for the accounting
 * @returns {Promise<number[]>}
 */
export async function getOcAccountingYears() {
  try {
    const response = await fetch(`${URL_BASE}contabilidad/ocnr/filtro`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the excel for the ivas report
 * @param {import("../../../../../server/models/banks/movements/types").MovementAnalysis} param - Param
 * @returns {Promise<Blob|null>}
 */
export async function ivaAccounting({ from , to }) {

  const queryFrom = typeof(from) === 'string' ? from : dateToDbFormat(from);
  const queryTo = typeof(to) === 'string' ? to : dateToDbFormat(to);

  try {
    const response = await fetch(`${URL_BASE}bancos/v2/sin-excel/analisis-iva?from=${queryFrom}&to=${queryTo}`, {
      credentials: "include",
      method: "GET",
    });

    const data = await response.blob();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    return null
  } catch (error) {
    Error(() => {}, error.message);
    return null
  }
}
