import React, { useContext, useRef } from "react";
import ModalV2 from "components/individual/Modals/ModalV2";
import useFiles from "customHooks/useFiles";
import FileInfo from "components/individual/SendEmail/FileInfo";
import { AddFileButton, FileUploadContainer } from "../ReceiveSatFiles/styles";
import ButtonV2, { iconList } from "components/individual/ButtonsV2/Button";
import { ValidExtension } from "./styles";
import { AssociateFilesContext } from "structure/AssociateFiles";
import useAssociateFileModal from "customHooks/useAssociateFileModal";
import { Spinner } from "components/individual/Spinner/Spinner";
import Icon from "components/individual/HeaderIcon";
import LoadingText from "components/individual/TextLoading";
import Dialog from "structure/Dialog";
import style from "./styles.module.scss";

export default function AddAssociateFile() {
  const validExtensions = ["pdf", "xml", "png", "jpg", "jpeg", "msg", "eml"];

  const key = useRef(window.crypto.randomUUID());

  const { idRegister, idTypeEntity, pathUpload, idCloseModal, search } =
    useContext(AssociateFilesContext);

  const { file, informative, addFiles, deleteFile, resetFiles } =
    useFiles(validExtensions);

  const {
    addFiles: addFilesApi,
    isLoading,
    dialog,
  } = useAssociateFileModal(
    idRegister,
    idTypeEntity,
    pathUpload,
    resetFiles,
    idCloseModal,
    search
  );

  const nodeInputFile = useRef(null);

  const close = () => {
    dialog.close();
    resetFiles();
  };

  return (
    <>
      <ButtonV2 onClick={dialog.open}>
        <span>Agregar</span>
        <Icon icon="plus" />
      </ButtonV2>

      <Dialog
        forwardRef={dialog.refDialog}
        title="Agregar archivos"
        closeCallback={close}
        footer={
          <div className={style.footerAddFiles}>
            {!isLoading ? (
              <ButtonV2
                disabled={informative.length <= 0}
                onClick={async () => {
                  const wasUploaded = await addFilesApi(file, informative);

                  if (wasUploaded) close();
                }}
              >
                <span>Agregar</span>
                <Icon icon="save"/>
              </ButtonV2>
            ) : (
              <Spinner
                hidden={false}
                text={<LoadingText>Agregando</LoadingText>}
              />
            )}
          </div>
        }
      >
        <div className={style.gridUploadFiles}>
          <FileUploadContainer>
            <h4>Archivos asociados</h4>

            <AddFileButton onClick={(e) => nodeInputFile.current.click()}>
              Agregar archivo(s)
            </AddFileButton>
            <input
              onChange={(e) => addFiles(e.target.files)}
              type="file"
              multiple
              className="d-none"
              accept={validExtensions.reduce(
                (acumulator, extension) => (acumulator += `.${extension},`),
                ""
              )}
              ref={nodeInputFile}
            />
          </FileUploadContainer>

          <div className={style.containerFilesQueue}>
            {informative.map((file, i) => (
              <FileInfo
                key={`${key.current}-${i}`}
                fileInfo={file}
                onDelete={(uuid) => deleteFile(uuid, i)}
              />
            ))}
          </div>

          <ValidExtension>
            Extensiones validas:
            {validExtensions.map((extension, i) => (
              <span key={`${key.current}-extensions-${i}`}>{extension}</span>
            ))}
          </ValidExtension>
        </div>
      </Dialog>
    </>
  );
}
