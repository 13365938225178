import styled from "styled-components";

export const Header = styled.div`
  font-weight: bold;
  text-align: center;
  background: var(--secondaryBackground);
  position: sticky;
  top: 0;
  padding: 0 0 5px 0;
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columns},
    ${(props) => 100 / props.columns}%
  );
  /* grid-gap: 10px; */
  gap: 10px 0;
  max-height: 45vh;
  overflow: auto;
  color:var(--primaryColor);
  background: var(--secondaryBackground);
`;
