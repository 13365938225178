import { useEffect, useState } from "react";
const validateRfc = require("validate-rfc");

/**
 * State to handle the custom hook
 * @type {import("./types").StateUseRfc}
 */
export const INITIAL_STATE = {
  rfc: "",
  isValid: true,
  isValidCountry: false,
  isRfcForForeigners: false,
  country: "",
};

/**
 * Handle the rfc
 * @param {import("./types").ParamUseRfc} param - Params to handle the rfc
 * @returns
 */
export function useRfc({ country = "", rfc = "", onChange = () => {} }) {
  const [state, setState] = useState({
    ...INITIAL_STATE,
    rfc,
    country,
  });

  useEffect(() => {
    const isRfcForForeigners = rfc === "XEXX010101000" ? true : false;

    const { isValid } = validateRfc(rfc, {
      omitVerificationDigit: true,
    });

    const parsedValid = rfc.length <=0 ? true :  isValid && rfc.length >= 12;

    setState((current) => ({
      ...current,
      rfc,
      country,
      isValid: parsedValid,
      isValidCountry: parsedValid,
      isRfcForForeigners,
    }));
  }, []);

  useEffect(() => {
    const isValid = state.isValid && state.isValidCountry;

    const isValidByLenght =
      state.rfc.length === 0 || state.rfc.length >= 12 ? true : false;

    const dto = {
      country: state.country,
      isValid: isValidByLenght && isValid,
      isValidCountry: state.country.length >= 1,
      isValidRfc: state.isValid,
      rfc: state.rfc,
    };

    onChange(dto);


  }, [state.isValid, state.isValidCountry,state.country,state.rfc]);

  /**
   * Update the rfc field input
   * @param {string} rfc - RFC
   */
  const updateRfc = (rfc) => {
    const isRfcForForeigners = rfc === "XEXX010101000" ? true : false;

    if(rfc==="XEXX010101000" || rfc==="XAXX010101000"){

      const countryToUse = rfc === "XAXX010101000" ? "Mexico" : "";
      const isValidCountry = countryToUse.length >= 1 ? true : false

      setState(current=>({
        ...current,
        rfc,
        isRfcForForeigners,
        isValid:true,
        country:countryToUse,
        isValidCountry
      }))
      return;
    }

    const { isValid } = validateRfc(rfc, {
      omitVerificationDigit: true,
    });

    const parsedValid =  rfc.length <= 0 ? true :  isValid && rfc.length >= 12;

    const countryToUse = !parsedValid ? "" : isRfcForForeigners ? "" : "Mexico";

    setState((current) => ({
      ...current,
      rfc,
      isRfcForForeigners,
      isValid: parsedValid,
      country: countryToUse,
      isValidCountry:countryToUse.length > 0
        
    }));
  };

  /**
   * Update the country when the rfc of for foreigners
   * @param {string} country - Country
   * @returns {void}
   */
  const updateCountry = (country) =>
    setState((current) => ({
      ...current,
      country,
      isValidCountry:country.length >= 1 ? true : false
    }));

  return {
    ...state,
    updateRfc,
    updateCountry,
  };
}
