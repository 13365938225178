import { Search } from "components/individual/Search";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { DirectoryContext } from "..";

export default function SearchDirectory() {
  const hook = useContext(DirectoryContext);

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        placeholder="Razón social"
        defaultValue={hook.search}
        value={hook.search}
        hideSearchIcon={true}
        onChange={hook.setSearch}
        onDelete={() => hook.setSearch("")}
      />
    </div>
  );
}
