import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { Icon } from "components/individual/CardV2/styles";
import { Pen, Save, Trash, XMark } from "components/individual/CardV2";

/**
 * Comments footer buttons
 * @param {import("../../../../../../types/winQuote").CommnetsButtonsI} param0
 * @returns {import("react").ReactComponentElement}
 */
export const CommentButtons = ({
  comment,
  index,
  idDocumentType,
  currentComment,
}) => {
  const {
    handleOpenCommentToEdit,
    handleOnDeleteComment,
    handleOnEditComment
  } = useContext(WinQuoteContext);
  return (
    <>
      {comment.isEditing ? (
        <>
          <Icon
            onClick={() =>
              handleOnEditComment(index, idDocumentType, currentComment)
            }
          >
            {Save}
          </Icon>
          <Icon
            onClick={() =>
              handleOpenCommentToEdit(index, idDocumentType, "close")
            }
          >
            {XMark}
          </Icon>
        </>
      ) : (
        <>
          <Icon
            onClick={() =>
              handleOpenCommentToEdit(index, idDocumentType, "open")
            }
          >
            {Pen}
          </Icon>
          <Icon onClick={() => handleOnDeleteComment(index, idDocumentType)}>
            {Trash}
          </Icon>
        </>
      )}
    </>
  );
};
