import { Spinner } from "components/individual/Spinner/Spinner";
import useEgressAssociationV2 from "customHooks/useEgressAssociationV2";
import GridImports from "../GridImports";

import React, { createContext, useContext } from "react";
import { EgressAssociationContext } from "pages/Banks/Movements/EgressAssociation";
import { mnCurrency } from "helpers/money";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";

/**
 * @type {React.Context<import("types/typedef/customHooks/useEgressAssociation").ContextI>}
 */
export const EgressAssociationsContact = createContext();
const { Provider } = EgressAssociationsContact;

/**
 *
 * @param {object} props - Props component
 * @param {"USD"|"MXN"} props.currencyMovement - Currency of the movement
 * @param {number} props.total - Total that can be used for the associations
 * @param {number?} props.tolerance - Tolerance for the input "Aplicacion"
 * @param {(object:import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI)=>void} props.onChange - Information of the grid when this changes
 * @returns {JSX.Element} Component
 */
export default function EgressAssociations({
  currencyMovement = "MXN",
  total = 0,
  onChange = () => {},
  tolerance = null,
}) {
  const { expenses, isLoading, handleSearchExpense } = useEgressAssociationV2({
    currencyMovement,
    total,
    onChange,
  });

  const {
    gridInvoice: { indexed },
  } = useContext(EgressAssociationContext);

  if (isLoading) return <Spinner hidden={false} text="Cargando gastos..." />;

  return (
    <>
      <div className="mb-5">
        <Label>Busqueda</Label>
        <Search
          placeholder="Proveedor, factura o concepto"
          onSearch={handleSearchExpense}
        />
      </div>

      <GridImports
        currency={currencyMovement}
        residue={total}
        amountsInfo={expenses}
        toleranceProp={tolerance}
        onChange={(value) => onChange(value)}
        headers={[
          "Proveedor",
          "Factura",
          "Concepto",
          "Parcialidad",
          "Moneda",
          "Total",
          "Pagos previos",
          "Pago a aplicar",
          "TC",
          "Saldo CxP",
          // "Saldo actual",
          // "Aplicacion",
          // "Importe aplicado",
          // "Saldo nuevo",
        ]}
      >
        {({ index }) =>
          expenses[index].display ? (
            <>
              <p className="d-flex justify-content-center align-items-center">
                {expenses[index].invoice.socialReason}
              </p>
              <p className="d-flex justify-content-center align-items-center">
                {expenses[index].invoice.folio}
              </p>
              <p className="d-flex justify-content-center align-items-center">
                {expenses[index].egress.description}
              </p>
              <p className="d-flex justify-content-center align-items-center">
                1/1
              </p>
              <GridImports.Currency index={index} />
              <GridImports.Total index={index} />

              <p className="d-flex justify-content-center align-items-center">
                {mnCurrency(
                  expenses[index].total.number - expenses[index].residue.number
                )}
              </p>

              <div className="d-flex flex-column justify-content-center">
                <GridImports.InputImport index={index} label="" />
              </div>

              <GridImports.InputTC
                index={index}
                label=""
                bankCode={currencyMovement}
              />
              <GridImports.Saldo index={index} />

              {/* <p className="d-flex justify-content-center align-items-center">
                  {expenses[index].invoice.socialReason}
                </p>
                <p className="d-flex justify-content-center align-items-center">
                  {expenses[index].egress.description}
                </p>

                <GridImports.Applied index={index} />
                <GridImports.AppliedImport index={index} />

                <GridImports.NewResidue
                  index={index}
                  showIsUsedAllResidue={false}
                />

                <GridImports.TC index={index} /> */}
            </>
          ) : null
        }
      </GridImports>
    </>
  );
}
