import React, { useContext } from "react";
import { ContextGestionOc } from "..";
import Status from "../Status";
import Customer from "../Customer";
import Provider from "../Provider";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import ui from "./styles.module.scss";
import Dates from "../Dates";

export default function Filter() {
  const hook = useContext(ContextGestionOc);

  return (
    <form id={hook.formId} onSubmit={hook.attemptSearch} className={ui.filter}>
      <Status />
      <div className="d-flex justify-content-between align-items-center">
        <div className={ui.combos}>
          <Customer />
          <Provider />
          <Dates />
          <ButtonV2 type="submit" form={hook.formId}>
            <span>Buscar</span>
            <Icon icon="search" />
          </ButtonV2>
        </div>

        <div className={ui.overviewRecords}>
          <p className="font-weight-bold d-flex align-items-center justify-content-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
            Ordenes de compra
          </p>
          <p className="text-right">
            {hook.managmentRecords.length} de {hook.totalRecords} registros
          </p>
        </div>
      </div>
    </form>
  );
}
