import {
  getBankAccount,
  getEgressOverview,
  getMovement,
} from "helpers/Apis/Banks";
import { mnCurrency } from "helpers/money";
import { useEffect, useState } from "react";

/**
 * @type {import("types/typedef/customHooks/useEgressOverview").StateI}
 */
const initialState = {
  isOpen: false,
  egresses: null,
  isLoading: true,
  bank: null,
  movement: null,
};

export default function useEgress(
  idMovement,
  idBankAccount,
  onLoaded = () => {}
) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      const [apiMovement, bankAccount, movement] = await Promise.all([
        getEgressOverview(idMovement),
        getBankAccount(+idBankAccount),
        getMovement(+idMovement),
      ]);

      setState({
        ...state,
        egresses: {
          deductible: apiMovement.deductible,
          nonDeductible: apiMovement.nonDeductible,
        },
        isLoading: false,
        bank: bankAccount,
        movement,
      });
    })();
  }, [idMovement]);

  const setOpen = () =>
    setState({
      ...state,
      isOpen: true,
    });

  const setClose = () => setState(initialState);

  const titleModal = !state.bank
    ? "Cargando..."
    : `Egreso ${idMovement} | Moneda ${
        state.bank.currencyCode
      } | Total egreso ${mnCurrency(
        state.movement.amount
      )} | Saldo ${mnCurrency(state.movement.saldo)}`;

  return {
    setClose,
    setOpen,
    state,
    titleModal,
  };
}
