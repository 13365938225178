import React, { useContext } from "react";
import { ContextEmitedCreditNotes } from "../..";
import { RedirectButton } from "components/individual/buttons/Buttons";

export default function LinkCreditNoteAssociatedFiles() {
  const hook = useContext(ContextEmitedCreditNotes);

  return (
    <div>
      <RedirectButton
        text="Archivos asociados"
        link={`/ventas/notas-de-credito/archivos-asociados/${hook.creditNote.id}`}
      />
    </div>
  );
}
