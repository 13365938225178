import React, { Fragment, useRef, useState } from "react";
import ui from "./styles.module.scss";
import Select from "../SelectV4";

/**
 * Render steps
 * @param {import("./types").PropsSteps} props - Props
 * @returns {JSX.Element}
 */
export default function Steps({
  steps = ["Paso 1", "Paso 2", "Paso 3"],
  onChange = () => {},
  currentStep = 1,
}) {
  const name = useRef(`${window.crypto.randomUUID()}`);

  const [stepValue, setStepValue] = useState(currentStep);

  /**
   * Handle the change of step
   * @param {number} step - Number of step clicked
   */
  const handleChange = (step) => {
    onChange(step);
    setStepValue(step);
  };

  return (
    <div>
      <div
        className={ui.steps}
        style={{
          gridTemplateColumns: `repeat(${steps.length},${100 / steps.length}%)`,
        }}
      >
        {steps.map((step, i) => (
          <Fragment key={`${name.current}-${i}`}>
            <input
              type="radio"
              name={name.current}
              onChange={(e) => handleChange(i + 1)}
              checked={stepValue === i + 1}
              id={`${name.current}-${i}`}
            />
            <label
              className={ui.stepContainer}
              htmlFor={`${name.current}-${i}`}
            >
              <div className={ui.step}>
                <span>{i + 1}</span>
              </div>
              <div className={ui.overviewStep}>
                <div>
                  <div></div>
                </div>
                <p>{step}</p>
              </div>
            </label>
          </Fragment>
        ))}
      </div>

      <div className={ui.mobileStatus}>
        <b>Estatus</b>
        <Select
          labelToDisplay="label"
          defaultSelected={{
            keyToCompare: "step",
            value: currentStep,
          }}
          onChange={(item) => handleChange(item.step)}
          options={async () =>
            steps.map((value, i) => ({
              label: value,
              step: i + 1,
            }))
          }
        />
      </div>
    </div>
  );
}
