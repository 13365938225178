import React, { useContext } from "react";
import { ContextAbcFee } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function Add() {
  const hook = useContext(ContextAbcFee);

  if (hook.isPerformingAbc)
    return (
      <div className="d-flex align-items-center justify-content-end">
        <Spinner text={<LoadingText>Agregando</LoadingText>} hidden={false} />
      </div>
    );

  if (typeof hook.year !== "number" || hook.year <= 0) return <></>;

  if (hook.years.includes(hook.year))
    return (
      <div className="d-flex align-items-center justify-content-center text-danger font-weight-bold">
        Ya existe la cuota, no puedes agregarla más de 1 vez
      </div>
    );

  return (
    <div className="d-flex align-items-center justify-content-end">
      <ButtonV2
        onClick={() =>
          hook
            .attemptAddFee()
            .then((wasAdded) => (wasAdded ? hook.success() : null))
            .catch((e) => e)
        }
        disabled={!hook.canPerformAbc}
      >
        <span>Agregar</span>
        <Icon icon="plus" />
      </ButtonV2>
    </div>
  );
}
