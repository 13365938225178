import React, { createContext, useState } from "react";
import { Modal } from "components/general/Modal";
import { BankForm } from "structure/Bank";
import { AddBank } from "./Add";

/**
 * Context
 * @type {import("react").Context<import("./types").ContextAddBank>}
 */
export const ContextAddBank = createContext();
const { Provider } = ContextAddBank;

/**
 * Render a modal in order to add a bank account into the system
 * @param {import("./types").PropsModalAddBank} props - Props
 * @returns {JSX.Element}
 */
export const ModalAddBank = ({ onAdded = () => {} }) => {
  /**
   * State for add a bank
   * @type {[import("./types").StateModalAddBank,()=>void]}
   */
  const [state, setState] = useState({
    isPerformingQuery: false,
    isValid: false,
    info: null,
  });

  const setIsPerformingQuery = (isPerformingQuery) =>
    setState({
      ...state,
      isPerformingQuery,
    });

  return (
    <Modal
      title="Agregar cuenta de banco"
      width={45}
      propsOpenModal={{
        children: "Agregar",
      }}
    >
      {({ closeModal }) => (
        <Provider
          value={{ ...state, closeModal, onAdded, setIsPerformingQuery }}
        >
          <BankForm
            onChange={(bank) =>
              setState({
                ...state,
                isValid: bank.isValid,
                info: bank,
              })
            }
          />
          <AddBank />
        </Provider>
      )}
    </Modal>
  );
};
