import { SocketContext } from "context/Socket";
import { useContext } from "react";

export default function useTicketsSocket() {
  const socket = useContext(SocketContext);

  /**
   * Alert all the logged users about a new bug
   * @param {string} title - Bug description
   * @param {string} description - Description of the bug
   * @param {string} reportedBy - User who reported the bug
   * @param {string|number} folio - Folio of the ticket
   */
  function notifyNewBug(title, description, reportedBy, folio) {
    socket.socketState.emit("newBug", { title, description, reportedBy, folio });
  }

  return {
    notifyNewBug,
  };
}
