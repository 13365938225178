import React, { useRef } from "react";
import ReactMarkdown from "react-markdown";
import { Modal } from "../Modal";
import styles from "./styles.module.scss";
import rehypeRaw from "rehype-raw";
import useVersion from "customHooks/useVersion";
import { useContext } from "react";
import { ContextConfig } from "App";

export default function FooterPraxia() {
  const { id, lastRelease } = useVersion();

  const { mode } = useContext(ContextConfig);
  const isEnviromentSet = useRef(typeof mode === "string");

  /**
   * @type {import("./types").LabelsFooter}
   */
  const labels = {
    test: "/images/test.png",
    produccion: " /images/produccion.png",
    beta: "/images/beta.png",
    alfa: "/images/alfa.png",
  };

  return (
    <>
      {lastRelease === undefined ? null : (
        <Modal
          title="Detalles de la version"
          propsOpenModal={{
            className: "d-none",
            id: id.current,
          }}
        >
          {() => (
            <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {lastRelease.body}
              </ReactMarkdown>
              <hr />
            </div>
          )}
        </Modal>
      )}

      <footer className={styles.praxia}>
        <img
          src={`${process.env.PUBLIC_URL}/images/praxia_clean.png`}
          alt="praxia_system_logo"
        />

        {isEnviromentSet && Object.keys(labels).includes(mode) ? (
          <>
            <span>-</span>
            <img
              className={styles.praxiaEnviroment}
              src={labels[mode]}
              alt="entorno_praxia"
            />
          </>
        ) : null}
      </footer>
    </>
  );
}
