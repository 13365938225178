import React from "react";
import { RedirectButton } from "components/individual/buttons/Buttons";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import { useContext } from "react";

export default function Win() {
  const hook = useContext(CONTEXT_HOME);

  return (
    <RedirectButton
      id="winQuote"
      text="Ganar"
      link={`directorio/documentos/${hook.quote.customer.id}/ganar2/${hook.quote.idDocument}`}
    />
  );
}
