import React from "react";
import { RecentTcps } from "structure/RecentTcps";
import { Modal } from "../Modal";

/**
 * Render a modal in order to check the recent tcp registered on the system
 * @param {import('../Modal/types').PropsModal} props - Props
 * @returns {JSX.Element}
 */
export const ModalRecentTcps = (props) => {
  return <Modal {...props}>{() => <RecentTcps />}</Modal>;
};
