import CreatableContact from "components/general/Forms/Selects/CreatableContact";
import React, { useEffect, useState } from "react";
import Form from "../Form/Form";
import { DocInfo, DocReleatedV2, Imports, SAT } from "../Form/Styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { schemaValidation } from "Schemas/Directory/EditDocument/contract";
import { updateContract } from "helpers/Apis/Documents";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Success } from "helpers/alerts";
import { useParams, useHistory } from "react-router-dom";
import { SET_DOC, SET_CONTRACT_PRICES } from "../reducer";
import { mnCurrency } from "helpers/money";

export default function Contract({
  state,
  dispatch,
  itemsToDelete = [],
  items = [],
  comments = [],
  commentsToDelete = [],
  idExecutive = null,
  fullNameExecutive = null,
  total = 0,
  importe = 0,
  iva = 0,
  residue = 0,
  acumulated = 0,
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  const [triggerCalculation, setTriggerCalculation] = useState(false);

  const history = useHistory();

  const [contactsCreated, setContactsCreated] = useState([]);
  const { customerId } = useParams();

  const update = async (info) => {
    setIsUpdating(true);

    const commentsSorted = comments.map((comment, i) => ({
      ...comment,
      order: i + 1,
    }));

    const toAdd = commentsSorted.filter(
      (comment) => typeof comment.id === "string"
    );
    const toUpdate = commentsSorted.filter(
      (comment) => typeof comment.id === "number"
    );

    const itemsSorted = items.map((item, i) => ({ ...item, order: i + 1 }));

    let itemsToUpdate = [];
    let itemsToAdd = [];
    let itemsToCreate = [];

    itemsSorted.forEach((item) => {
      const properties = Object.keys(item);

      if (properties.includes("mustBeAdded")) {
        if (typeof item.id === "string") {
          itemsToCreate.push(item);
          return;
        }

        itemsToAdd.push(item);
        return;
      }

      itemsToUpdate.push(item);
      return;
    });

    // console.log(info.document);

    const wasUpdated = await updateContract(
      {
        ...info.document,
        amount: {
          ...state.document.amount,
          total: {
            number: total,
            text: mnCurrency(total),
          },
          acumulated: {
            number: acumulated,
            text: mnCurrency(acumulated),
          },
          residue: {
            number: residue,
            text: mnCurrency(residue),
          },
          iva: {
            number: iva,
            text: mnCurrency(iva),
          },
          subtotal: {
            number: importe,
            text: mnCurrency(importe),
          },
        },
      },
      {
        toAdd,
        toUpdate,
        toDelete: commentsToDelete,
      },
      {
        id: state.executive.id === null ? idExecutive : state.executive.id,
        fullName:
          state.executive.fullName === null
            ? fullNameExecutive
            : state.executive.fullName,
      },
      {
        toUpdate: itemsToUpdate,
        toAdd: itemsToAdd,
        toDelete: itemsToDelete,
        toCreate: itemsToCreate,
      }
    );
    setIsUpdating(false);

    if (wasUpdated) {
      Success(() => history.goBack(), "Contrato actualizado");
    }
  };

  useEffect(() => {
    // console.log(state);
    if (state.document === null) return;

    let totals = items.reduce(
      (acumulator, item) => {
        return {
          importe: acumulator.importe + item.subTotal,
          total: acumulator.total + item.totalImport,
          iva: acumulator.iva + item.ivaPrice,
        };
      },
      {
        importe: 0,
        iva: 0,
        total: 0,
        // residue: 0,
        // acumulated: state.document.amount.acumulated.number,
      }
    );

    totals = {
      ...totals,
      residue: totals.total - state.document.amount.acumulated.number,
      acumulated: state.document.amount.acumulated.number,
    };

    dispatch({
      type: SET_CONTRACT_PRICES,
      payload: totals,
    });
  }, [items, triggerCalculation]);

  useEffect(() => {
    if (state.document === null || triggerCalculation === true) return;

    setTriggerCalculation(true);
  }, [state]);

  /**
   * Add the contact to the list
   * @param {NewContactInformationI} contact - Info contact created
   */
  const createContact = (contact) =>
    setContactsCreated([
      {
        contactID: contact.id,
        customerID: +customerId,
        firstName: contact.nombre,
        middleName: contact.middleName,
        lastName1: contact.apellidoP,
        lastName2: contact.apellidoM,
        fullName: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        status: 1,
        label: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        email: contact.email,
        isActive: contact.isActive,
        lada_cellphone: contact.lada_cellphone,
        lada_phone: contact.lada_phone,
        number_cellphone: contact.number_cellphone,
        number_phone: contact.number_phone,
        puesto: contact.puesto,
      },
      ...contactsCreated,
    ]);
  return (
    <>
      <CreatableContact
        fullName={null}
        onSubmit={createContact}
        message="El contacto sera creado cuando se actualize el contrato"
      />
      <Form
        onSubmit={update}
        onLoaded={(document) =>
          dispatch({
            type: SET_DOC,
            payload: document,
          })
        }
        schema={schemaValidation}
      >
        {({ document }) => (
          <>
            <div className="d-flex justify-content-end mb-3">
              {isUpdating ? (
                <Spinner hidden={false} text="Actualizando Contrato..." />
              ) : (
                <ButtonV2 form="updateDocument">Actualizar</ButtonV2>
              )}
            </div>

            <DocInfo>
              <Form.Id />
              <Form.Type />
              <Form.No />
              <Form.Status />
              <Form.Executive />
              <Form.Client />
              <Form.Contact
                value={document.contact.id}
                onLoaded={(options) => setContactsCreated(options)}
                options={contactsCreated}
              />
            </DocInfo>

            <hr />
            <h3 className="my-2">Documentos relacionados</h3>
            <DocReleatedV2>
              <Form.Origin />
              <Form.Quote />
              <Form.PreInvoice />
              <Form.Invoice />
              <Form.Contrato />
              <Form.OC />
              <Form.InvoiceProvider />

              <Form.EditContract />
              <Form.CreationDate />
              <Form.ModifyDate />
              {/* <Form.ExpirationDate /> */}
              {/* <Form.ReminderDate /> */}
              <Form.EditDates />
              <Form.PercentProgress />
              <Form.PercentProb />
            </DocReleatedV2>

            <hr />
            <h3 className="my-2">Importes</h3>
            <Imports>
              <Form.Currency />
              <Form.TCP />
              <Form.Import value={state.document?.amount.subtotal.text} />
              <Form.IVA value={state.document?.amount.iva.text} />
              <Form.Total value={state.document?.amount.total.text} />
              <Form.Acredited />
              <Form.Recidue value={state.document?.amount.residue.text} />
            </Imports>

            <SAT>
              <Form.CFDI value="NA" />
              <Form.PaymentMethod value="NA" />
              <Form.PaymentType value="NA" />
              <Form.Partialities />
              <Form.Partiality />
              <Form.CreditDays />
              <Form.Complement />
            </SAT>
          </>
        )}
      </Form>
    </>
  );
}
