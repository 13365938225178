import { getYearRange } from "helpers/dates";
import { Actions } from "./actions";

/**
 * State of the component that renders the list of contracts on the UI
 * @type {import("./types").StateI}
 */
export const initialState = {
  uen: null,
  uenName:"Todos",
  isOperable:false,
  page: 1,
  totalPages: undefined,
  search: null,
  customer: null,
  customerName:"Todos",
  date: {
    start: getYearRange().begin.js,
    end: getYearRange().end.js,
  },
  status: null,
  rows: [],
  isFetchingData: true,
  rowSelected: null,
  refetch: false,
  executive: -1,
  executiveName:"Todos",
  isDownloadingPdf:false,
  isDownloadingExcel:false
};

/**
 *
 * @param {import("./types").StateI} state - Information of the state to handle the render information
 * @param {import("./types").Actions} action - Types that can be executed to dispatch the state
 */
export function reducer(state, action) {
  switch (action.type) {
    case Actions.SET_IS_FETCHING_DATA: {
      return {
        ...state,
        isFetchingData: action.payload,
      };
    }

    case Actions.TRIGGER_SEARCH: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }

    case Actions.SET_NO_DOCUMENT: {
      const search = action.payload.length <= 0 ? null : action.payload;
      // const noDocument = action.payload.length <= 0 ? null : +action.payload;

      return {
        ...state,
        search,
      };
    }

    case Actions.SET_EXECUTIVE: {
      return {
        ...state,
        executive: action.payload === "-1" ? null : +action.payload,
      };
    }

    case Actions.SET_UEN: {
      return {
        ...state,
        uen: action.payload,
      };
    }

    case Actions.SET_ROW: {
      return {
        ...state,
        rowSelected: action.payload,
      };
    }

    case Actions.SET_DATES: {
      return {
        ...state,
        date: {
          start: action.payload[0],
          end: action.payload[1],
        },
      };
    }

    case Actions.SET_SEARCH_RESULTS: {
      return {
        ...state,
        rows: action.payload.rows,
        page: 1,
        totalPages: action.payload.totalPages,
        isFetchingData: false,
      };
    }

    case Actions.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case Actions.SET_SOCIAL_REASON: {
      return {
        ...state,
        customer: action.payload,
      };
    }

    default:
      return state;
  }
}
