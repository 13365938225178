import React from "react";
import { ContextCashFlow } from "../..";
import { useContext } from "react";
import style from "../../styles.module.scss";
import { mnCurrency } from "helpers/money";

export default function Residue() {
  const hook = useContext(ContextCashFlow);

  if (hook.type === "proyeccion")
    return (
      <div className={style.tableCashFlow}>
        <div className={style.totalDetails}>
          <p>Saldo proyectado</p>
          <p>
            {mnCurrency(
              hook.cashFlow.cashFlow.totalByPrediction.totalYesterday
            )}
          </p>
          <p>
            {mnCurrency(hook.cashFlow.cashFlow.totalByPrediction.totalToday)}
          </p>
          <p>
            {mnCurrency(hook.cashFlow.cashFlow.totalByPrediction.totalNextDay)}
          </p>
          <p>
            {mnCurrency(
              hook.cashFlow.cashFlow.totalByPrediction.totalNext7Days
            )}
          </p>
          <p>
            {mnCurrency(
              hook.cashFlow.cashFlow.totalByPrediction.totalNext14Days
            )}
          </p>
        </div>
      </div>
    );

  return (
    <div className={style.tableCashFlow}>
     <div className={style.totalCashFlow}>
     <p>Saldo disponible</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalYesterday)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalToday)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalNextDay)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalNext7Days)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalNext14Days)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByColumn.totalFull)}</p>
     </div>
    </div>
  );
}
