import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPen } from "@fortawesome/free-solid-svg-icons";

import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";
import useAddDocument from "../../../../customHooks/useAddDocuments";

import { useParams } from 'react-router';

function Comment({ comment = "", id = "", isNewComment = null }) {
  const { isSorting, setEdit } = useContext(AddDocumentContext);

  const { confirmDeleteComment , deleteComment} = useAddDocument();

  const { action } = useParams();

  const iconDelete = (
    <FontAwesomeIcon icon={faTimesCircle} className="iconCard" size="2x" />
  );

  const iconEdit = (
    <FontAwesomeIcon icon={faPen} className="iconCard ml-3" size="2x" />
  );

  const triggerEditComment = (comment, id) => {
    setEdit(id);
    const input = document.getElementById('inputAddComment');
    input.value = comment;
    input.focus();
  }



  const cssCard = `shadowBackground 
  py-4 
  d-flex 
  justify-content-between 
  align-items-center
  px-3
  mt-4`;

  return (
    <>
      <div
        id={id}
        className={`${cssCard} ${isSorting ? 'cursorDragging' : 'cursorMove'}`}
      >
        <div>{comment}</div>
        <div className="d-flex justify-content-center">
          <div
            className="cursorPointer iconCard"
            onClick={() => {
              if(action=='agregar'){
                deleteComment(id,isNewComment)
              }else{
                confirmDeleteComment(id)
              }
            }}
          >
            {iconDelete}
          </div>
          <div
            onClick={() => triggerEditComment(comment, id)}
            className="cursorPointer iconCard">
            {iconEdit}
          </div>
        </div>
      </div>
    </>
  );
}

export default Comment;
