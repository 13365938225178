import React from "react";
import { useContext } from "react";
import { ContextAdvertisement } from "../Avisos";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "../Avisos.module.scss";
import { useRef } from "react";
import { Pagination } from "components/individual/Pagination";
import Row from "./Row";

export default function Table() {
  const hook = useContext(ContextAdvertisement);

  const key = useRef(window.crypto.randomUUID());

  if (hook === null) return <></>;

  if (hook.isLoading) return <Spinner text={"Cargando"} hidden={false} />;

  return (
    <div className={style.tableAdvertisements}>
      <div className={style.header}>
        <p>ID</p>
        <p>Inicio</p>
        <p>Fin</p>
        <p>Tipo</p>
        <p>Aviso</p>
        <p>Registrado</p>
        <p>Registrado por</p>
        <p>Estatus</p>
      </div>

      <div className={style.containerRows}>
        {hook.advertisements.map((adv, i) => (
          <Row name={key.current} key={`${key.current}-${i}`} {...adv} />
        ))}
      </div>

      <Pagination
        actualPage={hook.page}
        pages={hook.pages}
        onChange={hook.setPage}
      />
    </div>
  );
}
