import React, { useContext } from "react";
import { Submit } from "../../individual/buttons/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import LoginContext from "../../../context/Login/LoginContext";
import { Spinner } from "../../individual/Spinner/Spinner";
import PasswordV2 from "../PasswordV2";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";

function RecoverPassword() {
  const { changeView, recover } = useContext(LoginContext);

  return (
    <div className="loginForm">
      <form className={style.formRecoverPassword} onSubmit={recover}>
        <div className="cursorHand" onClick={() => changeView("login")}>
          <p
            className="d-flex align-items-center 
              font-weight-bold text-center"
          >
            <FontAwesomeIcon
              className="mr-2"
              size="2x"
              icon={faArrowAltCircleLeft}
            />
            Regresar
          </p>
        </div>

        <p className="font-weight-bold text-center">Actualizar contraseña</p>

        <PasswordV2
          name="confirmPass1"
          id="confirmPass1"
          placeholder="Ingresa una nueva contraseña"
        />

        <PasswordV2
          name="confirmPass2"
          id="confirmPass2"
          placeholder="Confirma la contraseña"
        />

        <Spinner
          text={<LoadingText>Actualizando contraseñas</LoadingText>}
          idSpinner="spinnerUpdatePass"
        />
        <Submit
          id="btnUpdatePass"
          text="Cambiar contraseña"
          aditionalClasses="text-center"
        />
      </form>
    </div>
  );
}

export default RecoverPassword;
