import useDialog from "customHooks/useDialog";
import React, { createContext, useState } from "react";
import Dialog from "structure/Dialog";
import MoreInfoTable from "./MoreInfoTable";
import UpdateAccounting from "components/individual/UpdateAccounting";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isOpen: false,
  isLoading: true,
  grid: {
    original: {},
    operation: {},
  },
};

/**
 * @type {import("react").Context<import("./types").ContextMoreInfo|undefined>}
 */
export const ContextMoreInfo = createContext(undefined);

/**
 * Render more information of the pending oc to receive on the date selected
 * @param {import('./types').PropsMoreInfo} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfo({
  year = 0,
  month = 0,
  children = <></>,
  onClick = () => {},
  onUpdated = () => {}
}) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  const open = () => {
    dialog.open();
    setState({ ...INITIAL_STATE, isOpen: true });
  };

  const close = () => {
    dialog.close();
    setState(INITIAL_STATE);
  };

  const setGrid = (grid) =>
    setState((current) => ({
      ...current,
      grid,
    }));

  const handleUpdate = () => {
    close();
    onUpdated();
  }

  return (
    <ContextMoreInfo.Provider value={{ ...state, year, month }}>
      <div onClick={onClick}>
        <div onClick={open}>{children}</div>
        <Dialog
          footer={
            <div className="d-flex justify-content-end align-items-center">
              <UpdateAccounting
                type="OCDetails"
                operationGrid={state.grid.operation}
                originalGrid={state.grid.original}
                onUpdated={handleUpdate}
              />
            </div>
          }
          title="Más información"
          closeCallback={close}
          forwardRef={dialog.refDialog}
          width={70}
        >
          {state.isOpen ? <MoreInfoTable onChange={setGrid} /> : null}
        </Dialog>
      </div>
    </ContextMoreInfo.Provider>
  );
}
