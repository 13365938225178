const documentType = {
  id: "documentType",
  name: "documentType",
  htmlFor: "documentType",
  lableText: "Tipo de documento",
  placeholder: "Cotizacion",
  readOnly:true,
  disable:false
};
const documentID = {
  id: "documentID",
  name: "documentID",
  htmlFor: "documentID",
  lableText: "ID Documento",
  placeholder: 1,
  readOnly:true,
  disable:false
};
const documentNumber = {
  id: "documentNumber",
  name: "documentNumber",
  htmlFor: "documentNumber",
  lableText: "Documento",
  placeholder: "0000002",
  readOnly:true,
  disable:false
};
const registerDate = {
  id: "registerDate",
  name: "registerDate",
  htmlFor: "registerDate",
  lableText: "Fecha de registro",
  placeholder: "15/Oct/2021",
  readOnly:true,
  disable:false
};
const userRegistered = {
  id: "userRegistered",
  name: "userRegistered",
  htmlFor: "userRegistered",
  lableText: "Usuario quien registra",
  placeholder: "Adrian Alardin Iracheta",
  readOnly:true,
  disable:false
};
const reminderDate = {
  id: "reminderDate",
  name: "reminderDate",
  htmlFor: "reminderDate",
  lableText: "Fecha Recordatorio",
  placeholder: "15/Oct/2021",
  readOnly:false,
  disable:false
};
const attentionDate = {
  id: "attentionDate",
  name: "attentionDate",
  htmlFor: "attentionDate",
  lableText: "Fecha atencion",
  placeholder: "15/Oct/2021",
  readOnly:false,
  disable:false
};
const comments = {
  id: "comments",
  name: "comments",
  htmlFor: "comments",
  lableText: "Solicitud o comentario",
  placeholder: "Escribir aquí",
  readOnly:false,
  disable:false
};
const attentedExecutive = {
  id: "attentedExecutive",
  name: "attentedExecutive",
  htmlFor: "attentedExecutive",
  lableText: "Ejecutivo atenderá",
  placeholder: "Adrian Alardin Iracheta",
  readOnly:false,
  disable:false
};
const attentedStatus = {
  id: "attentedStatus",
  name: "attentedStatus",
  htmlFor: "attentedStatus",
  lableText: "Estatus de atención",
  placeholder: "No atendida",
  readOnly:true,
  disable:false
};
const atentionRequired = {
  id: "attentionRequired",
  name: "attentionRequired",
  htmlFor: "attentionRequired",
  lableTextTrue: "Si requiere atención",
  lableTextFalse: "No requiere atención",
};

const reminderType={
  id: "reminderType",
  name: "reminderType",
  htmlFor: "reminderType",
  lableText:'Tipo'
}

const ReminderInputs = {
  documentType,
  documentID,
  documentNumber,
  registerDate,
  userRegistered,
  reminderDate,
  attentionDate,
  comments,
  attentedExecutive,
  attentedStatus,
  atentionRequired,
  reminderType
};

module.exports = ReminderInputs;
