import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";
import React from "react";
import useEditRol from "customHooks/useEditRol";
import { Spinner } from "components/individual/Spinner/Spinner";
import ViewPermissions from "./Permissions";

export default function MoreInformation({ id }) {
  const dialog = useDialog();

  const hook = useEditRol(id);

  const handleOpen = () => {
    dialog.open();
    hook.openModal();
  };

  const handleClose = () => {
    dialog.close();
    hook.closeModal();
  };

  return (
    <>
      <p onClick={handleOpen}>{id}</p>

      <Dialog
        closeCallback={handleClose}
        title="Más informacion"
        forwardRef={dialog.refDialog}
      >
        {hook.isLoading ? (
          <Spinner
            hidden={false}
            idSpinner="loadingMoreInformationRols"
            text="Cargando"
          />
        ) : (
          <>
            <h1 className="text-center">{hook.description.content}</h1>
            <hr />
            <p className="text-left font-weight-bold mt-0">
              Roles que supervisa
            </p>
            <ol className="rolSupervised">
              {hook.rols.length <= 0 ? (
                <li>Ninguno</li>
              ) : (
                hook.rols.map((rol) => <li key={rol.id}>{rol.name}</li>)
              )}
            </ol>
            <hr />
            <p className="text-left font-weight-bold">Permisos</p>
            {hook.isLoading ? null : <ViewPermissions hook={hook} />}
          </>
        )}
      </Dialog>
    </>
  );
}
