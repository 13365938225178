import styled from "styled-components";

export const FormContainer = styled.form`
  display: grid;
  align-items: start;
  justify-content: space-evenly;
  grid-template-columns: 15% 20% 20% 20% 10% 10%;
`;

export const Container = styled.div`
  padding: 0 5%;
`;

export const ContainerCards = styled.div`
  max-height: 40vh;
  overflow: auto;
  padding: 20px;
`;
