import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// COMPONENTS
import { CustomTable } from "../../../../components/general/Table/CustomTable";
import ConfigTable from "../../../../static/StaticTables/Cxc";
// import Options from "./QuotesOptions";

// APIS
import { fetchDocuments } from "../../../../helpers/Apis/Documents";

// LIBS
import { DefaultTable } from "js-smart-table/dist/pagination";

import TableContext from "../../../../context/Table/TableContext";
import { Spinner } from "../../../../components/individual/Spinner/Spinner";

import useDocuments from "../../../../customHooks/useDocuments";
import { CXCOptions } from "./CXCOptions";
import { BotonneraContainer } from "../../../Inicio/Modals/Styles";

function CXC() {
  // let TableQuotes;

  // const [rowInfo, setRowInfo] = useState(null);

  // const { notifyDocumentsWithFiles, shortCutAssociateFiles, prepareExcelData } =
  //   useDocuments();

  // const { refetch, setLengthData } = useContext(TableContext);

  // let TableQuotesConfig = {
  //   ...ConfigTable,
  //   cbSelection: () => setRowInfo(TableQuotes.infoRow),
  // };

  // useEffect(() => {
  //   setRowInfo(null);

  //   async function initialLoad() {
  //     const { actualPage, documents, pages } = await fetchDocuments(
  //       1,
  //       "DESC",
  //       "idDocument"
  //     );

  //     TableQuotesConfig = {
  //       ...TableQuotesConfig,
  //       rows: documents,
  //       pages,
  //       actualPage,
  //       paginationFn: async function (
  //         page,
  //         order,
  //         columnOrdering,
  //         queryString
  //       ) {
  //         const documents = await fetchDocuments(
  //           page,
  //           order,
  //           columnOrdering,
  //           queryString
  //         );
  //         notifyDocumentsWithFiles(documents.documents);
  //         // shortCutAssociateFiles(documents.documents);
  //         prepareExcelData(documents.documents);
  //         return documents;
  //       },
  //     };

  //     TableQuotes = new DefaultTable(TableQuotesConfig);
  //     TableQuotes.printTable();
  //     TableQuotes.printPagination();
  //     setLengthData(documents.length);

  //     notifyDocumentsWithFiles(documents);
  //     // shortCutAssociateFiles(documents);

  //     prepareExcelData(documents);
  //   }

  //   initialLoad();
  // }, [refetch]);

  return (
    <>
      <CustomTable paginationID="pagination-quotes" tableID="documents" />
    </>
    // <div>
    //   <BotonneraContainer className="container d-flex justify-content-start aling-items-start my-2 px-0">
    //     {rowInfo === null ? null : <><CXCOptions rowInfo={rowInfo} /></>}
    //   </BotonneraContainer>
    //   <CustomTable paginationID="pagination-quotes" tableID="documents" />
    //   {rowInfo === null ? null : (
    //     <>
    //       <Options rowInfo={rowInfo} />
    //       <div className="d-flex justify-content-end">
    //         <Spinner idSpinner="pdfloading" text="Cargando PDF" />
    //       </div>
    //       <CancelModal rowInfo={rowInfo} />
    //     </>
    //   )}
    // </div>
  );
}

export default CXC;
