import React from "react";
import { ContextConsecutivePurchaseOrder } from "..";
import { useContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ExcelConsecutivePurchaseOrder() {
  const hook = useContext(ContextConsecutivePurchaseOrder);

  if (hook.isLoading) return <></>;

  if (hook.isDownloadingExcel)
    return (
      <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
    );

  return (
    <ButtonV2 type="button" onClick={hook.attemptDownloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
