import styled from "styled-components";

export const Step2Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 0 1rem 1rem 0;
  }

  @media (max-width: 767px) {
    flex-direction:column;
  }
`;
