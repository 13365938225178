import React, { useState } from "react";
import CreatableSimpleContact from "../Forms/Selects/CreatableSimpleContact";
import CreatableContact from "components/general/Forms/Selects/CreatableContact";
import { getInfoContact } from "helpers/Directory/Contact";
import { useEffect } from "react";

/**
 * Render a combo to use for the contacts according the social reason
 * @param {object} props - Props
 * @param {number} props.contact - Id of the contact to use for the document
 * @param {number} props.customer - Id of the customer to use for the document
 * @param {object[]} props.options - List of contacts to use on the combo
 * @param {()=>void} props.onLoaded - Options when the input loads
 * @param {()=>void} props.onChange - Information of the contact selected
 * @param {boolean} props.doCreate - Information of the contact selected
 * @returns {JSX.Element}
 */
export default function DynamicContactSocialReason({
  contact,
  customer,
  onLoaded = () => {},
  onChange = () => {},
  options = [],
  doCreate = true,
}) {
  const [stateOptions, setStateOptions] = useState(options);
  useEffect(() => {
    if (options.length > 0) {
      setStateOptions(options);
    }
  }, [options]);

  const openModalCreateContact = () => {
    const btn = document.getElementById("createContactBtn");
    if (btn) {
      btn.click();
    }
  };

  const handleOnChange = (contact) => {
    const infoContact = getInfoContact(contact);
    onChange(infoContact);
  };

  const appendContactOnMemory = (contact) => {
    const contactInfo = {
      ...contact,
      contactID: contact.id,
      value: contact.id,
      label: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
    };

    const newOpts = [contactInfo, ...stateOptions];
    setStateOptions(newOpts);
    onChange(contactInfo);
  };

  return customer === null ? null : (
    <>
      <CreatableSimpleContact
        idContact={contact}
        idCustomer={customer}
        // onLoaded={onLoaded}
        onLoaded={(list) => {
          setStateOptions(list);
        }}
        onNewContact={openModalCreateContact}
        options={stateOptions}
        onChange={handleOnChange}
        doCreate={doCreate}
      />
      {doCreate ? (
        <CreatableContact
          // onSubmit={appendContactOnMemory}
          // onSubmit={(e) => console.log(e)}
          onSubmit={appendContactOnMemory}
          fullName=""
          message="El contacto sera creado solo si agregas la cotizacion"
        />
      ) : null}
    </>
  );
}
