import React from "react";
import { useContext } from "react";
import { UsersContext } from "./UsuariosV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "./Usuarios.module.scss";
import { Pagination } from "components/individual/Pagination";
import UserInformation from "structure/UserInformation";
import { useRef } from "react";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";

export default function TableUsers() {
  const hook = useContext(UsersContext);

  const key = useRef(window.crypto.randomUUID());

  const openEdit = () => {
    const domBtn = document.getElementById(hook.idEditBtn);

    if(domBtn) domBtn.click();
  }

  if (hook.isLoading)
    return <Spinner hidden={false} idSpinner="loadingUsers" text="Cargando" />;

  if (hook.search !== "" && hook.users.length <= 0)
    return (
      <h1 className="text-center">
        No hay resultados con la busqueda solicitada
      </h1>
    );

  if (hook.users.length <= 0)
    return <h1 className="text-center">No hay usuarios cargados</h1>;

  return (
    <>
      <UserInformation
        onClose={hook.closeMoreInformation}
        id={hook.user?.userID}
        isOpen={hook.isOpenMoreInformation}
      />

      <div className={style.containerUserCards}>

      
      {hook.users.map((user, i) => (
        <div className={style.cardUser} key={`${key.current}-mobile-${i}`}>
          <div className="d-flex justify-content-between">
            <div>
              <Label>Nombre</Label>
              <p>
                {user.fullName} ({user.status})
              </p>
            </div>

            <ElipsisMobile onShow={() => hook.setUserInfoRow(user)}>
              <CustomMenuItem onClick={hook.openMoreInformation}>
                Más información
              </CustomMenuItem>

              <CustomMenuItem onClick={openEdit}>
                Editar
              </CustomMenuItem>
            </ElipsisMobile>
          </div>

          <Label>Rol</Label>
          <p>{user.rolDescription}</p>

          <Label>Usuario</Label>
          <p>{user.userName}</p>

          <Label>Email</Label>
          <p>{user.email}</p>
        </div>
      ))}
      </div>

      <div className={style.table}>
        <div className={style.header}>
          <p>ID</p>
          <p>Usuario</p>
          <p>Iniciales</p>
          <p>Nombre</p>
          <p>Email</p>
          <p>Rol</p>
          <p>Estatus</p>
        </div>

        <div className={style.containerRows}>
          {hook.users.map((user, i) => (
            <div key={`${key.current}-${i}-desktop`}>
              <input
                onChange={() => hook.setUserInfoRow(user)}
                type="radio"
                className="d-none"
                id={`user-${user.userID}`}
                name="usersCombo"
              />
              <label
                className={style.row}
                key={`user-${user.userID}`}
                htmlFor={`user-${user.userID}`}
              >
                <p onClick={hook.openMoreInformation}>{user.userID}</p>
                <p>{user.userName}</p>
                <p>{user.initials}</p>
                <p>{user.fullName}</p>
                <p>{user.email}</p>
                <p>{user.rolDescription}</p>
                <p>{user.status}</p>
              </label>
            </div>
          ))}
        </div>

        <Pagination
          actualPage={hook.actualPage}
          pages={hook.pages}
          onChange={hook.updatePage}
        />
      </div>
    </>
  );
}
