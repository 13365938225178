 import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useDocuments from "customHooks/useDocuments/index";
import React, { createContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TopModule from "structure/TopModule";
import Table from "./Table";
import Customer from "./Customer";
import Filter from "./Filter";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import AddQuote from "./AddQuote";
import Options from "./Options";
import FilterMobile from "structure/FilterMobile";

/**
 * @type {import("react").Context<import("customHooks/useDocuments/types").ReturnUseDocuments|undefined>}
 */
export const ContextDocuments = createContext(undefined);

export default function DocumentsV2() {
  const { customer } = useParams();

  const hook = useDocuments(+customer);

  return (
    <ContextDocuments.Provider value={hook}>
      <div className={ui.module}>
        <TopModule>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={ui.margin}>
            <Customer />
          </div>

          <div className={ui.margin}>
            <div className={ui.filter}>
              <div className={ui.desktop}>
                <Filter />
                <ButtonV2 type="submit" form={hook.idForm}>
                  <span>Buscar</span>
                  <Icon icon="search" />
                </ButtonV2>
              </div>
              <AddQuote />
            </div>
          </div>

          <div className={ui.margin}>
            <div className={ui.mobile}>
              <FilterMobile pills={hook.pills}>
                <Filter />
              </FilterMobile>
            </div>
          </div>
        </TopModule>

        <div className={ui.margin}>
          <Options />
        </div>

        <div className={ui.margin}>
          <Table />
        </div>
      </div>
    </ContextDocuments.Provider>
  );
}
