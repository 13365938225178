import React from "react";
import { ContextCatalogueAdmin } from "..";
import { useContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function SearchButton() {
  const hook = useContext(ContextCatalogueAdmin);

  return <ButtonV2 onClick={hook.attemptSearch}>
    <span>Buscar</span>
    <Icon icon="search"/>
  </ButtonV2>;
}
