import React, { useRef, useContext } from "react";
import scss from "../styles.module.scss";
import { ContextInvoiceReception } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Pagination } from "components/individual/Pagination";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import MobileInvoiceReception from "./MobileInvoiceReception";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { Header, Row } from "./styles";
import MoreInfoInvoiceReception from "components/general/MoreInfoInvoiceReception";
import { mnCurrency } from "helpers/money";

export default function TableInvoiceReception() {
  const hook = useContext(ContextInvoiceReception);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        text={"Cargando facturas"}
        idSpinner="loadingInvoiceReception"
      />
    );

  if (hook.documents.length <= 0)
    return (
      <h5 className="text-center my-3">No hay facturas con los filtros</h5>
    );

  return (
    <div className={scss.table}>
      <Header isForAccounting={hook.isForAccounting}>
        <p>No. factura</p>
        <p>Emision</p>

        {!hook.isForAccounting ? <p>Expiracion</p> : null}

        <p>Moneda</p>
        <p>Razon social</p>
        <p>Total</p>

        {!hook.isForAccounting ? (
          <>
            <p>Saldo</p>
            <p>Pagado</p>
            <p>Estatus</p>
          </>
        ) : (
          <>
            <p>Importe</p>
            <p>IVA</p>
            <p>Contabilizado</p>
          </>
        )}
      </Header>
      <div className={scss.containerRows}>
        {hook.documents.map((invoice, i) => (
          <div key={`${key.current}-${i}`}>
            <div className={scss.containerRow}>
              <input
                className="d-none"
                type="radio"
                name="invoices"
                id={`${invoice.id}`}
                onChange={(e) => hook.setInfoRow(invoice)}
              />
              <Row
                isForAccounting={hook.isForAccounting}
                className={scss.row}
                htmlFor={invoice.id}
              >
                <MoreInfoInvoiceReception
                  idLegalDocument={invoice.id}
                  uuid={invoice.uuid}
                >
                  {invoice.noDocument}
                </MoreInfoInvoiceReception>

                <div className="text-center">
                  {parseDateToText(new Date(invoice.emitedDate))}
                </div>

                {!hook.isForAccounting ? (
                  <div className="text-center">
                    {parseDateToText(`${invoice.expirationDate.split("T")[0]}:`)}
                  </div>
                ) : null}

                <div className="text-center">{invoice.currency}</div>
                <div>{invoice.socialReason}</div>
                <div className="text-right">{mnCurrency(invoice.total)}</div>

                {!hook.isForAccounting ? (
                  <>
                    <div className="text-right">
                      {mnCurrency(invoice.saldo)}
                    </div>
                    <div className="text-right">
                      {mnCurrency(invoice.cobrado)}
                    </div>
                    <div className="text-center">{invoice.description}</div>
                  </>
                ) : (
                  <>
                    <div className="text-right">
                      {mnCurrency(invoice.import)}
                    </div>
                    <div className="text-right">{mnCurrency(invoice.iva)}</div>
                    <label className="d-flex justify-content-center align-items-center">
                      <input
                        type="checkbox"
                        checked={hook.gridAccounting.operation[invoice.id]}
                        onChange={(e) => hook.toggleAccounting(invoice.id)}
                      />
                    </label>
                  </>
                )}
              </Row>
            </div>

            <MobileInvoiceReception {...invoice} />
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.pagination.currentPage}
        pages={hook.pagination.pages}
        onChange={hook.fetchPage}
      />
    </div>
  );
}
