import { getRol, getSchemaPermissions, UpdateRol } from "helpers/Apis/rolesApi";
import { useContext, useEffect, useState } from "react";
import TableContext from "context/Table/TableContext";
import { Success } from "helpers/alerts";
import { useRef } from "react";

const initialState = {
  schemaTreePermissions: [],
  description: {
    content: null,
    isValid: false,
  },
  rols: [],
  rolsUpdate: {
    active: [],
    granted: [],
    revoked: [],
  },
  isActive: undefined,
  permissions: [],
  step: 0,
  isLoading: true,
  isModalOpen: false,
  isUpdatingRol: false,
  treePermissions: {
    active: [],
    granted: [],
    revoked: [],
  },
};

/**
 * Handle the update of the permissions
 * @param {number} idRol - Id of the rol
 * @param {()=>void} onEdited - Function to execute when edition was success
 */
export default function useEditRol(idRol,onEdited = () => {}) {
  const [state, setState] = useState(initialState);

  const idCloseIcon = "updateRolModalIconClose";

  const idOpenEdit = useRef(window.crypto.randomUUID());

  const { refetch, setReload } = useContext(TableContext);

  /**
   * Handle the modal in order to be closed
   * @returns {void}
   */
  const closeModal = () => setState(initialState);

  useEffect(() => {
    (async function () {
      if (!idRol || !state.isModalOpen) return;

      const [
        { canSupervise, name, isActive, permissions },
        schemaTreePermissions,
      ] = await Promise.all([getRol(idRol), getSchemaPermissions()]);

      setState(current=>({
        ...current,
        description: {
          content: name,
          isValid: true,
        },
        rols: canSupervise,
        isActive,
        permissions,
        isLoading: false,
        schemaTreePermissions,
        treePermissions: {
          active: permissions,
          granted: permissions,
          revoked: [],
        },
      }));
    })();
  }, [idRol, state.isModalOpen]);

  /**
   * Update the state in order to indicate the modal it's open
   * @returns {void}
   */
  const openModal = () =>
    setState(current=>({
      ...current,
      isModalOpen: true,
    }));

  /**
   * Update the rol into database
   */
  const updateRol = async () => {
    /**
     * DTO for server in order to update rol
     * @type {import("types/typedef/roles").UpdateRolDtoI}
     */
    const dto = {
      description: state.description.content,
      isActive: +state.isActive,
      permissions: {
        toAdd: state.treePermissions.granted,
        toRemove: state.treePermissions.revoked,
      },
      rol: {
        toEdit: idRol,
        toAdd: state.rolsUpdate.granted,
        toRemove: state.rolsUpdate.revoked,
      },
    };

    setState(current=>({
      ...current,
      isUpdatingRol: true,
    }));

    const wasUpdated = await UpdateRol(dto);

    if (wasUpdated) {
      Success(() => {}, `${state.description.content} actualizado`);
      setState(initialState);
      closeModal();
      setReload(!refetch);
      onEdited();
    }

    setState(current=>({
      ...current,
      isUpdatingRol: false,
    }));

    function closeModal() {
      const dom = document.getElementById(idCloseIcon);

      if (dom) {
        dom.click();
      }
    }
  };

  /**
   * Update the description to use on the rol
   * @param {string} value - Value of the description typed
   */
  const updateDescription = (value) => {
    const regex = new RegExp(/^[A-Za-z ]*$/);
    const isValid = regex.test(value);
    const isEmpty = value.length === 0 ? true : false;

    setState(current=>({
      ...current,
      description: {
        content: value,
        isValid: isValid && !isEmpty,
      },
    }));
  };

  /**
   * Navigate thorught the modal
   * @param {number} step - Number step to navigate
   * @returns {void}
   */
  const navigateToStep = (step) =>
    setState(current=>({
      ...current,
      step,
    }));

  /**
   * Update the rols that can suppervise the current rol
   * @param {import("types/typedef/roles").RolI[]} rols - Rols that are checked on the UI
   */
  const handleUpdateCheckboxes = (rols) => {
    const initialRols = state.rols.map((rol) => rol.id);

    const grantedRols = rols.filter((rol) => !initialRols.includes(rol.id));

    const revokedRols = getRevokedRols();

    function getRevokedRols() {
      const indexedRols = rols.reduce(
        (indexed, currentRol) => ({
          ...indexed,
          [currentRol.id]: currentRol,
        }),
        {}
      );

      let revoked = [];

      state.rols.forEach((rol) => {
        if (indexedRols[rol.id] === undefined) {
          revoked.push(rol);
        }
      });

      return revoked;
    }

    setState(current=>({
      ...current,
      rolsUpdate: {
        active: rols.map((rol) => rol.id),
        granted: grantedRols.map((rol) => rol.id),
        revoked: revokedRols.map((rol) => rol.id),
      },
    }));
  };

  /**
   * Update the permissions (on memory) that will have the updated rol
   * @param {import("react-tree-permissions/dist/types/hooks/usePermissions").onChangeI} permissions
   */
  const updateMemoryPermissions = (permissions) => {

       setState(current=>({
      ...current,
      treePermissions: {
        active: current.treePermissions.active,
        granted: permissions.grantedPermissions,
        revoked: permissions.revokedPermissions,
      },
    }));
  }
    

  /**
   * Handle the toggle active/disactive
   * @returns {void}
   */
  const handleActive = () =>
    setState(current=>({
      ...current,
      isActive: !state.isActive,
    }));

  return {
    isLoading: state.isLoading,
    openModal,
    permissions: state.permissions,
    step: state.step,
    schemaTreePermissions: state.schemaTreePermissions,
    updateDescription,
    description: state.description,
    navigateToStep,
    isValidPermissions: state.treePermissions.granted.length > 0 ? true : false,
    updateMemoryPermissions,
    rols: state.rols,
    handleUpdateCheckboxes,
    closeModal,
    updateRol,
    isActive: state.isActive,
    idCloseIcon,
    handleActive,
    isUpdatingRol: state.isUpdatingRol,
    idOpenEdit:idOpenEdit.current
  };
}
