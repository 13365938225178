import React, { useContext, useRef } from "react";
import { ContextCorporative } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "./styles.module.scss";
import { Pagination } from "components/individual/Pagination";
import MoreInfoDirectory from "structure/MoreInfoDirectory";
import MobileCard from "./MobileCard";

export default function Table() {
  const hook = useContext(ContextCorporative);

  const key = useRef(window.crypto.randomUUID());

  if (!hook.isOpen) return <></>;

  if (hook.isLoading) return <Spinner text="Cargando" hidden={false} />;

  return (
    <>
      {hook.corporatives.map((corporative,i) => (
        <MobileCard {...corporative} key={`${key.current}-${i}-mobile`}/>
      ))}

      <div className={style.tableCorporatives}>
        <div className={style.header}>
          <p>ID</p>
          <p>Razón social</p>
          <p>RFC</p>
          <p>Nombre comercial</p>
          <p>Nombre corto</p>
        </div>

        <div className={style.rowsContainer}>
          {hook.corporatives.map((corporative, i) => (
            <div key={`${key.current}-${i}`}>
              <input
                onChange={(e) => hook.setCorporative(corporative)}
                name={key.current}
                type="radio"
                className="d-none"
                id={`${key.current}-${i}`}
              />
              <label className={style.row} htmlFor={`${key.current}-${i}`}>
                <MoreInfoDirectory idCustomer={corporative.ID_cliente}>
                  <p className={style.moreInfo}>{corporative.ID_cliente}</p>
                </MoreInfoDirectory>
                <p>{corporative.Razon_social}</p>
                <p>{corporative.RFC}</p>
                <p>{corporative.Nombre_comercial}</p>
                <p>{corporative.Nombre_corto}</p>
              </label>
            </div>
          ))}
        </div>

        <Pagination
          onChange={hook.setPage}
          actualPage={hook.page}
          pages={hook.pages}
        />
      </div>
    </>
  );
}
