import React, { createContext, useRef } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useInvoiceReception from "./Hooks/useInvoceReception";
import FilterSearch from "./Filter/";
import Options from "pages/Administration/InvoiceReception/Options";
import usePermissions from "customHooks/usePermissions";
import TableInvoiceReception from "./TableInvoiceReception";
import style from "./styles.module.scss";
import PillsFilter from "./PillsFilter";

export const ContextInvoiceReception = createContext(undefined);
const { Provider: ProviderInvoiceReception } = ContextInvoiceReception;

/**
 * Render a table for the invoice reception
 * @param {import("./types").PropsInvoiceReception} props - Props
 * @returns {JSX.Element}
 */
export default function InvoiceReception({ isForAccounting = false }) {
  const permissionToUse = useRef(
    isForAccounting
      ? "0703147b-0db4-4592-b806-80fa19248172"
      : "c082c7e9-441b-4368-9c56-3acb579a578f"
  );

  usePermissions(permissionToUse.current);

  const hook = useInvoiceReception(isForAccounting);

  return (
    <div className={style.moduleInvoiceReception}>
      <ProviderInvoiceReception value={{ ...hook, isForAccounting }}>
        <div className={style.headerModuleInvoiceReception}>
          <BreadCrumV2 path={hook.breadCrumPath} />
          <FilterSearch />
          <PillsFilter />
        </div>
        <Options />

        <TableInvoiceReception />
      </ProviderInvoiceReception>
    </div>
  );
}
