import React,{useState,useEffect} from 'react';

// Librarys
import CreatableSelect from 'react-select/creatable';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

// Styles
import '../Forms.scss';

// APIS
import { getBanksList, setBank } from '../../../../helpers/forms';

/**
 * This allows you inser a select that displays the list of banks on the sysmtem,
 * do a search on the client-side to find the banks (shortName, commercialName and
 * socialReason) and insert a new bank in case the user doesn't find his option
 * @component AddSelectBank
 * @tutorial addSelectBank
 * @param {object?} forwardRef - register object of the react-hook-form
 * @param {string} id - id attribute to be set ON THE HIDDEN INPUT
 * @param {string} label - Text that gonna be display on the label of the select
 */
function AddSelectBank({
    forwardRef = {},
    id,
    label,
    name,
    value = null,
    valueLabel = null
}){
    const configFuse = {
        keys:['label','social_reason','commercial_name']
    }

    const [banks,setBanks] = useState([]);
    const [selectedBank,setSelectedBank] = useState(null);
    const [defaultValue,setDefaultValue] = useState({
        value:null,
        label:'Selecciona o escribe un banco'
    });

    useEffect(()=>{
        async function initialLoad(){
            const dataFetch = await getBanksList();
            setBanks(dataFetch);    

            const copy = {
                banks:dataFetch
            }

            sessionStorage.setItem('banks',JSON.stringify(copy));

        }
        initialLoad();
    },[]);

    const searchCoincidences = e => {
        const ss = JSON.parse(sessionStorage.getItem('banks'));

        const localBanks = new Fuse(ss.banks,configFuse);

        const result = localBanks.search(e.target.value);

        result.map((search,i)=>{
            result[i] = search?.item
        });

        setBanks(result);
    }

    const handleInputChange = selectedOption => {
        if(selectedOption===null){
            setSelectedBank('')
        }else{
            setSelectedBank(selectedOption.value);
            setDefaultValue({
                value:selectedOption.value,
                label:selectedOption.label
            });
        }
    }

    const resetValues = () => {
        const ssData = JSON.parse(sessionStorage.getItem('banks'));
        setBanks(ssData.banks);
    }

    useEffect(()=>{
        if(banks!==null && value !== null && valueLabel !== null){
            document.getElementById(`${id}`).value = value;
            document.querySelector(`#user_select_${id} .saiko-select__single-value`).innerText = valueLabel;
        }
    },[banks]);

    return (
        <>

        <label htmlFor={id}>{label}</label>
        <CreatableSelect
            isClearable = {true}
            options = { banks }
            onChange = { handleInputChange }
            id = {`user_select_${id}`}
            classNamePrefix = 'saiko-select'
            className = 'saiko-select'
            placeholder = 'Selecciona o escribe un banco'
            defaultValue = {defaultValue}
            onKeyDown = {(e)=>searchCoincidences(e)}
            onMenuOpen = {()=>resetValues()}
        />

        <input 
            type="text" 
            value = {selectedBank}
            ref = {forwardRef}
            name = {name}
            id = {id}
            className = 'd-none'
        />

        </>
    )
}

AddSelectBank.propTypes = {
    /**
     * Text that gonna be display on the label of the select
     */
    label:PropTypes.string.isRequired,

    /**
     * id attribute to be set ON THE HIDDEN INPUT. There's where you can 
     * retrive the data at the moment of sending the form
     */
    id:PropTypes.string.isRequired,


    /**
     * If you want use react-hook-form, you can pass the register reference
     */
     forwardRef:PropTypes.object
}

export default AddSelectBank;