import { InputCurrency } from "components/individual/inputs/Inputs";
import { mnCurrency } from "helpers/money";
import React from "react";
import { useContext } from "react";
import { ContextGridCxC } from "..";
import Money from "components/general/Forms/Money";

export const Tc = () => {
  const { element, currency, updateTcApplied } = useContext(ContextGridCxC);

  if (element.import.number === 0) return "-";

  if (element.currency === currency) return element.tc.text;

  return (
    <>
      <Money
      onChange={(val) => updateTcApplied(val, element.uuidFrontend)}
      placeholder="Escribe aqui (mayor a 0)"
      maxDecimals={2}
      />

      {/* <InputCurrency
        showError={element.tc === 0}
        onChange={(val) => updateTcApplied(val, element.uuidFrontend)}
        placeholder="Escribe aqui (mayor a 0)"
        defaultValue={0}
      /> */}
      {element.tc === 0 ? (
        <p className="text-center text-danger">Escribe un TC</p>
      ) : null}
    </>
  );
};
