import { useContext, useEffect, useReducer, useRef } from "react";
import { INITIAL_STATE, reducer } from "./reducer";
import { ACTIONS } from "./actions";
import useFiles from "customHooks/useFiles";
import { sendEmailWithFiles } from "helpers/Apis/email";
import { Success } from "helpers/alerts";
import LoginContext from "context/Login/LoginContext";

/**
 * Handle send an email on praxia
 * @param {import("./types").EmailPropsI} params - Params
 * @returns
 */
export default function useEmailV2(
  params = {
    cc: [],
    message: null,
    subject: null,
    to: [],
    validExtensions: [],
    files: async () => {},
    onSendedEmail: async () => {},
    toInyection: async () => [],
    refetch: false,
    idDocument: 0,
  }
) {
  /**
   * @type {[import("./types").EmailStateI,()=>import("./types").ActionsI]}
   */
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const appendCc = (email) =>
    dispatch({
      type: ACTIONS.SET_CC,
      payload: email,
    });

  // useEffect(() => {
  //   appendCc(params.cc);
  // }, [params.cc]);

  const { userInfo } = useContext(LoginContext);

  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const domFilesPc = useRef();

  const openFilesComputer = () => domFilesPc.current.click();

  const { addFiles, deleteFile, file, informative, resetFiles } = useFiles();

  /**
   * Set the flag of sending email
   * @param {boolean} sending
   * @returns {void}
   */
  const setSendingEmail = (sending) =>
    dispatch({
      type: ACTIONS.SET_SENDING_EMAIL,
      payload: sending,
    });

  /**
   * Add the email into "To" list
   * @param {string[]} email - Email
   * @returns {void}
   */
  const appendTo = (email) =>
    dispatch({
      type: ACTIONS.SET_TO,
      payload: email,
    });

  const deleteTo = (email) =>
    dispatch({
      type: ACTIONS.DELETE_TO,
      payload: email,
    });

  /**
   * Position of the email selected to delete
   * @param {number} index - Index
   * @returns {void}
   */
  const deleteCc = (index) => {
    dispatch({
      type: ACTIONS.DELETE_CC,
      payload: index,
    });
  };

  /**
   * Update the subject of the email
   * @param {string} subject - Subject
   * @returns {void}
   */
  const updateSubject = (subject) =>
    dispatch({
      type: ACTIONS.SET_SUBJECT,
      payload: subject,
    });

  /**
   * Set the message to send on the email
   * @param {string} message - Message
   * @returns {void}
   */
  const updateMessage = (message) =>
    dispatch({
      type: ACTIONS.SET_MESSAGE,
      payload: message,
    });

  const sendEmail = async () => {
    setSendingEmail(true);

    const subject =
      state.subject === null
        ? `Praxia - ${userInfo[0].fullName}`
        : state.subject;
    const message = state.message === null ? "<p></p>" : state.message;

    const wasSended = await sendEmailWithFiles({
      cc: state.cc,
      idDocument: params.idDocument,
      message: message,
      subjet: subject,
      to: state.to,
      files: file,
      sendedBy: userInfo[0].fullName,
    });

    setSendingEmail(false);
    if (wasSended) {
      Success(() => {}, "Correo enviado");
      params.onSendedEmail();
    }
  };

  useEffect(() => {
    (async function () {
      resetFiles();
      dispatch({ type: ACTIONS.SET_INITIAL_STATE });

      const emailsToInyection = await params.toInyection();

      appendCc(params.cc);
      appendTo([...emailsToInyection, ...params.to]);

      updateSubject(params.subject);
      updateMessage(params.message);

      // params.subject

      const files = await params.files();

      addFiles(files);

      dispatch({ type: ACTIONS.SET_LOADED_FILES });
    })();
  }, [params.refetch]);

  return {
    ...state,
    appendTo,
    appendCc,
    uiFiles: informative,
    updateMessage,
    domFilesPc,
    openFilesComputer,
    addFiles,
    updateSubject,
    deleteTo,
    deleteCc,
    sendEmail,
    deleteFile,
    file,
  };
}
