import React from "react";
import { ConcilationContext } from "../Concilation";
import { useContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ExcelConciliation() {
  const hook = useContext(ConcilationContext);

  if (hook.movements.length <= 0) return <></>;

  if (hook.isDownloadingExcel)
    return (
      <Spinner
        text={<LoadingText>Descargando excel</LoadingText>}
        hidden={false}
      />
    );

  return (
    <ButtonV2 onClick={hook.attemptDownloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
