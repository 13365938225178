import React, { useContext } from "react";
import NoLegalDocument from "../NoLegalDocument";
import NoInvoice from "../NoInvoice";
import RangeDate from "../RangeDate";
import { ContextAuthsInvoice } from "..";

export default function Filter() {
  const hook = useContext(ContextAuthsInvoice);

  return (
    <form
      ref={hook.formDom}
      onSubmit={(e) => {
        hook.triggerSearch();
        e.preventDefault();
      }}
    >
      <RangeDate />
      <NoInvoice />
      <NoLegalDocument />
    </form>
  );
}
