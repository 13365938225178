import React, { useContext } from "react";
import { MenuContext } from "structure/Menu";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../index.module.scss";
import MenuItem from "components/individual/MenuItem";

export default function Reports() {
  const { setMenuToDisplay } = useContext(MenuContext);

  return (
    <>
      <p onClick={() => setMenuToDisplay("administracion")}>
        <b>
          <TextIcon icon="back" text="Administración > Reportes" />
        </b>
      </p>

      <p className={scss.item}>
        <TextIcon icon="historyAmount" text="Antigüedad de saldos CxP" />
      </p>

      <p className={scss.item}>
        <TextIcon icon="document" text="Cuentas por pagar" />
      </p>

      <p className={scss.item}>
        <TextIcon
          icon="administrationMoney"
          text="Saldos y movimientos de CxP"
        />
      </p>

      <MenuItem icon="reports" children="Reporte de control"></MenuItem>
    </>
  );
}
