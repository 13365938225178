import React from "react";
import { useContext } from "react";
import { BankAccountContext } from "..";
import { BankAccount as Cuenta } from "components/general/Forms/Text/BankAccount";
import { Label } from "structure/Document";

export default function BankAccountCuenta() {
  const { register, errors, id, updateCuenta, isValidCuenta, account } =
    useContext(BankAccountContext);

  return (
    <div>
      {id === null ? (
        <>
          <Cuenta
            required={!isValidCuenta}
            placeholder="Escribe aquí"
            forwardRef={register}
            name="account"
            onChange={(e) => updateCuenta(e.target.value)}
          />
          <p className="text-danger">{errors?.account?.message}</p>
        </>
      ) : (
        <>
          <Label>Cuenta</Label>
          <p>{account.account}</p>
        </>
      )}
    </div>
  );
}
