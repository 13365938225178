// @ts-check
import useComplementIncoming from "customHooks/useComplementIncoming";
import React from "react";
import { createContext } from "react";
import style from './styles.module.scss';

/**
 * @type {import("react").Context<import("customHooks/useComplementIncoming/types").ContextComplements>}
 */
export const ContextIncomingComplement = createContext(null);

/**
 * Render more information
 * @param {import("./types").PropsComplements} props - Props
 * @returns {JSX.Element}
 */
export default function IncomingComplements({
  idMovement,
  children = <></>,
  displayCheckboxs = false,
  onSuccess = () => {},
}) {
  const hook = useComplementIncoming(idMovement, onSuccess);

  return (
    <div className={style.containerMoreInfo}>
      <ContextIncomingComplement.Provider value={{ ...hook, displayCheckboxs }}>
        {children}
      </ContextIncomingComplement.Provider>
    </div>
  );
}
