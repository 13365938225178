import React, { useContext } from "react";
import AccoutingStatus from "components/general/Forms/Selects/AccountingStatus";
import { ContextInvoice } from "../..";

export default function AccountingStatusSelect() {
  const { from, setAccounting } = useContext(ContextInvoice);

  return from === "contabilidad" ? (
    <AccoutingStatus onChange={setAccounting} />
  ) : (
    <></>
  );
}
