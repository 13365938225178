import InvoiceCombo from "components/general/Forms/Selects/PendingInvoices";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";
import ReceptionOverview from "structure/ReceptionOverview";
import style from "../styles.module.scss";

export default function AvailableInvoices() {
  const hook = useContext(ContextEmitCreditNote);

  const dialog = useDialog();

  return (
    <>
      <div>
        <div className={style.labelInvoice}>
          <Label>
            Factura
            {hook.invoice !== undefined ? (
              <small onClick={dialog.open}>Ver más detalle de la factura</small>
            ) : null}
          </Label>
        </div>
        <InvoiceCombo onChange={hook.updateInvoice} />
      </div>

      <Dialog
        title={`Factura ${
          hook.invoice !== undefined ? hook.invoice.folio : ""
        } `}
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
        width={80}
      >
        {hook.invoice !== undefined ? (
          <ReceptionOverview uuid={hook.invoice.uuid} />
        ) : null}
      </Dialog>
    </>
  );
}
