import React, { useContext } from "react";
import FilterMobile from "structure/FilterMobile";
import { ContextGestionOc } from "..";
import Filter from "../Filter";
import { parseDateToText } from "helpers/dates";
import Status from "../Status";
import ui from "./styles.module.scss";

export default function ManagmentOcMobileFilter() {
  const hook = useContext(ContextGestionOc);

  return (
    <div className={ui.container}>
      <Status />
      <FilterMobile
        renderSearchBtn={true}
        onSearch={hook.attemptSearch}
        pills={[
          {
            label: "Ordenes de compra",
            value: `${hook.managmentRecords.length} de ${hook.totalRecords}`,
          },
          {
            label: "Cliente",
            value: hook.customer?.socialReason || "Todos",
          },
          {
            label: "Proveedor",
            value: hook.provider?.socialReason || "Todos",
          },
          {
            label: "Desde",
            value: parseDateToText(hook.from),
          },
          {
            label: "Hasta",
            value: parseDateToText(hook.to),
          },
        ]}
      >
        <Filter />
      </FilterMobile>
    </div>
  );
}
