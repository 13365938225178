import { Spinner } from "components/individual/Spinner/Spinner";
import { getColorScheme } from "helpers/dom";
import React from "react";
import DataTable from "react-data-table-component";

/**
 * Render the table
 * @param {import('./types').PropsTable} props
 * @returns {JSX.Element}
 * @example 
*           <Table
                selectableRowsHighlight
                pointerOnHover
                highlightOnHover
                responsive
                pagination
                onChangeRowsPerPage={(rowsPerPage) =>
                hook.setRowsPerPage(rowsPerPage)
                }
                progressPending={hook.state.isSearchingTickets}
                progressComponent={
                <Spinner text={"Buscando tickets"} hidden={false} />
                }
                selectableRowsSingle
                fixedHeader
                fixedHeaderScrollHeight="45vh"
                columns={hook.columns}
                data={hook.state.tickets}
                onChangePage={(page) => hook.fetchPage(page)}
                selectableRowSelected={(ticket) => ticket === hook.state.ticket}
                onRowClicked={(ticket, e) => {
                hook.selectTicket(ticket, getClickedColumn(e.target));
                }}
                paginationTotalRows={hook.state.pages * hook.state.rowsPerPage}
                paginationRowsPerPageOptions={[
                10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                ]}
                theme={getColorScheme()}
                paginationServer
                paginationServerOptions={{
                persistSelectedOnPageChange: false,
                persistSelectedOnSort: false,
                }}
                paginationPerPage={hook.state.rowsPerPage}
                paginationComponentOptions={{
                rowsPerPageText: "Filas por página",
                rangeSeparatorText: "de",
                }}
            />
 */
export default function Table(props) {
  return (
    <div>
      <DataTable
        customStyles={{
          headCells: {
            style: {
              fontWeight: "bolder",
            },
          },
        }}
        selectableRowsHighlight
        pointerOnHover
        noDataComponent={
          <p className="py-5 m-0">
            No hay registros con los filtros solicitados
          </p>
        }
        highlightOnHover
        responsive
        pagination
        progressComponent={
          <div className="py-5">
            <Spinner text={"Buscando"} hidden={false} />
          </div>
        }
        selectableRowsSingle
        fixedHeader
        fixedHeaderScrollHeight="45vh"
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        theme={getColorScheme()}
        paginationServer
        paginationServerOptions={{
          persistSelectedOnPageChange: false,
          persistSelectedOnSort: false,
        }}
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        {...props}
      />
    </div>
  );
}
