import { Error  } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "helpers/Apis/fetch";

/**
 * @param {number?} page - Page requested for the advances list
 * @param {("ingreso"|"egreso")} type - Filter by all of just some customer
 * @param {number?} customer - Id of the customer
 * @returns {Promise<import("customHooks/usePaymentAdvances/types").DtoAdvance>}
 */
export async function getCustomersAdvances(
  page = 1,
  type = "ingreso",
  customer = null
) {
  const errorFetchResponse = { advances: [], totalPages: 0, currentPage: 0 };

  const customerQueryParam = customer === null ? "" : `&id=${customer}`;
  const typeQueryParam =
    customer === null ? `&type=allOfThem` : `&type=byCustomer`;

  const movementType = `&movementType=${type}`;

  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/anticipos/clientes?page=${page}${typeQueryParam}${customerQueryParam}${movementType}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return errorFetchResponse;
  } catch (error) {
    return errorFetchResponse;
  }
}

/**
 * Get the options for the advance combo
 * @param {import("../../../../../../server/models/banks/types").AdvanceCombo} type - Type of customers to fetch from the directory
 * @returns {Promise<import("../../../../../../server/models/banks/types").AdvanceComboList[]>}
 */
export async function getComboAdvance(type = "clientes") {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/anticipos/clientes/lista?type=${type}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}
