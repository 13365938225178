import React, { useContext } from "react";
import { BankAccountContext } from "..";
import { BankCard as Card } from "components/individual/BankCard/BankCard";

export default function BankCard() {
  const context = useContext(BankAccountContext);

  return (
    <Card
      account={context.account?.account}
      currency={context.account?.currency}
      clave={context.account?.bank?.clave}
      shortname={context.account?.bank?.shortName}
      currentBalance={context.account?.currentBalance || 0}
    />
  );
}
