import { downloadPdfDocumentV3, getOdcPdf } from "helpers/Apis/Documents";
import { useState } from "react";
import { saveAs } from "file-saver";

/**
 *
 * @param {import("./types").Id} id - Id of the OC
 * @param {string} [fileName='Orden de compra'] - Filename for the pdf to download
 * @param {import("./types").Currency} [currency=null] - Currency of the document
 * @returns {import("./types").ReturnUseOcPdf} Values of the hook
 */
export default function useOcPdf(id, fileName, currency) {
  const [isDownloading, setIsDownloading] = useState(false);

  async function downloadMultiCurrencyPdf() {
    setIsDownloading(true);
    try {
      const pdf2 = await getOdcPdf(id);
      saveAs(pdf2, `${fileName}.pdf`);
    } catch (error) {
    } finally {
      setIsDownloading(false);
    }
  }

  async function downloadSingleCurrencyPdf() {
    setIsDownloading(true);
    try {
      const pdf2 = await downloadPdfDocumentV3(id, "intranet");

      saveAs(pdf2["0"], `${fileName}.pdf`);
    } catch (error) {
    } finally {
      setIsDownloading(false);
    }
  }

  const handleDownload = async () => {
    if (currency === "***") {
      await downloadMultiCurrencyPdf();
      return;
    }

    await downloadSingleCurrencyPdf();
  };

  return {
    handleDownload,
    isDownloading,
  };
}
