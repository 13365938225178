import styled from "styled-components";

export const HeaderToDo = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: space-between;
  padding: 0 0 15px 0;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
    padding: 0;
  }
`;

export const ContainerNote = styled.div`
  max-height: 50px;
  overflow: auto;

  @media (max-width: 767px) {
    display: none;
    max-height: 0;
  }
`;

export const Action = styled.button`
  border-radius: 50%;
  width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0;
  font-size: 0.85rem;
  cursor: default !important;
  background-color: var(--primaryBackground);
  border: none;
  transition: 0.25s ease-out;
  &:hover {
    border: 2px solid black;
  }
`;

export const CheckToDo = styled.button`
  background: url("${(props) =>
    props.isChecked
      ? "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E"
      : "white"}");
  border: 2px solid black;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0 3px 0 0;
  &:hover {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
  }

  :disabled {
    cursor: not-allowed;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z' clip-rule='evenodd' /%3E%3C/svg%3E");
  }
`;

export const InformationToDo = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1rem;
  @media (max-width: 767px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(10, 10%);
    gap: 1rem 0;
    > input {
      grid-column: 1/3;
      margin: 0;
    }

    > span:nth-of-type(1) {
      grid-column: 3/11;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > span:nth-of-type(n + 2) {
      grid-column: 1/11;
    }
    // flex-direction: column;
    // gap: 0.5rem 0;
    // align-items: start;
    // width: 100%;
  }
`;

export const Tag = styled.span`
  &:before {
    content: "#";
  }
`;

export const Title = styled.span`
  /* white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const SocialReason = styled.span`
  margin: 0 0 0 5px;
  &:before {
    content: " - ";
  }
  /* white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis; */
`;
