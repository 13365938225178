import React from "react";
import { useContext, useRef } from "react";
import { Label } from "structure/Document";
import { AddMovementContext } from "../../AddMovementsV2";

export const Reference = () => {
  const { reference, updateReference, referecenDom } =
    useContext(AddMovementContext);

  const maxLength = useRef(30);

  return (
    <div>
      <div className="d-flex">
        <Label>Referencia </Label>
        <span>
          {reference.length}/{maxLength.current}
        </span>
      </div>
      <input
        type="text"
        tabIndex={1}
        ref={referecenDom}
        value={reference}
        onChange={(e) =>
          updateReference(e.target.value.substring(0, maxLength.current))
        }
        maxLength={maxLength}
        placeholder="d7b21429-5a3d-4071-85f7"
      />
    </div>
  );
};
