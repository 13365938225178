import React from "react";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { useContext } from "react";
import { ContextRols } from "../Roles";

export default function SearchRol() {
  const hook = useContext(ContextRols);

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        placeholder="Nombre del rol"
        onChange={(value) => hook.updateSearch(value)}
        onDelete={(value) => hook.updateSearch("")}
        onSearch={value=>hook.triggerSearch()}
      />
    </div>
  );
}
