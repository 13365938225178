import { Icon } from "components/individual/CardV2/styles";
import Modal from "react-modal";
import React from "react";
import style from "../../../../structure/Partidas/styles.module.scss";


import { mnCurrency } from "helpers/money";
import { useItems } from "components/Sections/Documents/Hooks/useItems";
import { HeaderForm } from "components/Sections/Documents/Items/Containers";
import { Eye } from "components/individual/CardV2";



/**
 * 
 * @param {{item:import("../../../../../../types/documentActions").ItemSelectI,isForeignSupplier:boolean}} props 
 * @returns 
 */
export const MoreInfoSection = ({ item,isForeignSupplier=false },) => {
  const { handleOnMoreInfoModal, openModalMoreInfo } = useItems();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "84%",
      padding: "2rem",
    },
  };
  return (
    <>
      <Icon onClick={handleOnMoreInfoModal}>{Eye}</Icon>
      <Modal
        isOpen={openModalMoreInfo}
        style={customStyles}
        onRequestClose={handleOnMoreInfoModal}
        contentLabel={"Esto es un ejemplo"}
      >
        <HeaderForm>
          <h4>{item?.description}</h4>
          <button type="button" className="modal-form btn">
            <span
              className="btnCloseModal"
              onClick={handleOnMoreInfoModal}
            ></span>
          </button>
        </HeaderForm>
        <p className={style.informativeLabel}>
          <span>
            <strong>UEN: </strong>
          </span>
          <span>{item?.uen.description}</span>
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Clave SAT: </strong>
          </span>
          <span>{item?.satCode}</span>
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>UM SAT: </strong>
          </span>
          <span>{item?.satUm}</span>
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Cantidad: </strong>
          </span>
          <span>{item?.quantity}</span>
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Moneda: </strong>
          </span>
          <span>{item?.currency.code}</span>
        </p>
        <hr />
        <h5>Información de la compra </h5>

        <p className={style.informativeLabel}>
          <span>
            <strong>Costo unitario: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.cu.text}</span>
          ) : (
            <span>{item?.usd.cu.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Costo unitario de compra: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.cu.totalUnit.text}</span>
          ) : (
            <span>{item?.usd.cu.totalUnit.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Descuento: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.cu.discount.text}</span>
          ) : (
            <span>{item?.usd.cu.discount.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Importe: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.cu.total.text}</span>
          ) : (
            <span>{item?.usd.cu.total.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>IVA: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{isForeignSupplier?'0%':item?.mxn.cu.totalIva.text}</span>
          ) : (
            <span>{isForeignSupplier?'0%':item?.usd.cu.totalIva.text}</span>
          )}
        </p>
        <hr />
        <h5>Información de la venta </h5>

        <p className={style.informativeLabel}>
          <span>
            <strong>Precio unitario: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.pu.text}</span>
          ) : (
            <span>{item?.usd.pu.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Precio unitario de la venta: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.pu.totalUnit.text}</span>
          ) : (
            <span>{item?.usd.pu.totalUnit.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Descuento: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.pu.discount.text}</span>
          ) : (
            <span>{item?.usd.pu.discount.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>Importe: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{item?.mxn.pu.total.text}</span>
          ) : (
            <span>{item?.usd.pu.total.text}</span>
          )}
        </p>
        <p className={style.informativeLabel}>
          <span>
            <strong>IVA: </strong>
          </span>
          {item?.currency.code === "MXN" ? (
            <span>{}</span>
          ) : (
            <span>{item?.usd.pu.totalIva.text}</span>
          )}
        </p>
        {item?.currency.code === "MXN" ? (
          <div className="d-flex justify-content-end">
            <span>
              {mnCurrency(
                item?.mxn.pu.total.number -
                  item?.mxn.cu.total.number
              )}
            </span>
            <span> - </span>
            <span>
              {Math.round(
                (item?.mxn.pu.total.number /
                  item?.mxn.cu.total.number +
                  Number.EPSILON) *
                  100
              ) / 100}
              %
            </span>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <span>
              {mnCurrency(
                item?.usd.pu.total.number -
                  item?.usd.cu.total.number
              )}
            </span>
            <span> - </span>
            <span>
              {Math.round(
                (item?.usd.pu.total.number /
                  item?.usd.cu.total.number +
                  Number.EPSILON) *
                  100
              ) / 100}
              %
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};
