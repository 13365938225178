import { Spinner } from "components/individual/Spinner/Spinner";
import useBanks from "customHooks/useBanks";
import React, { createContext } from "react";
import { List } from "./List";
import { ClaveBank } from "./Clave";

/**
 * @type {import("react").Context<import("./types").ContextBanks>}
 */
const ContextBanks = createContext();
const { Provider } = ContextBanks;

/**
 * Render the combo in order to select a banks
 * @param {import("./types").PropsBanks} props - Props
 * @returns {JSX.Element}
 */
const Banks = ({ children, id = null, clave = null }) => {
  const banks = useBanks({ id, clave });

  if (banks.isLoading)
    return <Spinner hidden={false} text="Cargando bancos..." />;

  return <Provider value={{ ...banks }}>{children({ ...banks })}</Provider>;
};

export { List, ClaveBank, Banks, ContextBanks };
