import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { CancelEgressContext } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function PerformCancel() {
  const hook = useContext(CancelEgressContext);

  if (hook.state.isCancelling)
    return (
      <Spinner hidden={false} text="Cancelando" idSpinner="cancel-egress" />
    );

  if (!hook.state.isValidCancellation) return <></>;

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ButtonV2 onClick={hook.attemptCancel}>
        <span>Cancelar</span>
      </ButtonV2>
    </div>
  );
}
