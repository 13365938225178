import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextMoreInfoDocItem } from "..";
import ui from "./styles.module.scss";

export default function UenMoreInfo() {
  const hook = useContext(ContextMoreInfoDocItem);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const [uens, setUens] = useState([]);

  useEffect(() => {
    if (!Array.isArray(hook.uen)) return;

    const indexedUens = hook.uen.reduce(
      (indexed, uen) => ({
        ...indexed,
        [uen.catalogue.uen
          .id]: `${uen.catalogue.uen.family} - ${uen.catalogue.uen.description}`,
      }),
      {}
    );

    const uensToUse = Object.entries(indexedUens).map(([key, value]) => value);

    setUens(uensToUse);
  }, []);

  if (!Array.isArray(hook.uen)) return <></>;

  if (uens.length <= 0) return <></>;

  return (
    <div className={ui.uen}>
      <p className="m-0">UEN</p>
      <ol className={ui.uensList}>
        {uens.map((uen, i) => (
          <li key={`${key.current}-${i}`}>{uen}</li>
        ))}
      </ol>
    </div>
  );
}
