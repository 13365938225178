import React, { useContext } from "react";
import {
  NavHeaderButtom,
  NavItemButton,
} from "../../individual/buttons/Buttons";
import "./homeNavBar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import UserMenu from "../../general/userMenu/userMenu";
import { BreadCrums } from "../../individual/breadCrums/BreadCrums";
import PermissionsContext from "../../../context/Login/LoginContext";
import {
  evaluatePermission,
  textPermission,
} from "../../../helpers/permissions";

import ChangePassword from "../userMenu/ChangePassword";

/**
 *
 * @param {object} props - Props of the component
 * @param {boolean} props.mostrar - If true, will show the menu of the system
 * @param {object} props.bredcrum - This will contain an object with an array object property
 */
export default function HomeHeader({ mostrar = true, bredcrum }) {
  const notBorder = "botonera-container border-0";
  const botoneraContaier = "botonera-container bredcrum-container";

  const { rolPermitions } = useContext(PermissionsContext);

  return (
    <div className="header-container mb-0">
      <div className="row">
        <div className="col-12">
          {mostrar ? (
            <>
              <div className={botoneraContaier}>
                <div className="botonera-barra1"></div>
                <div className="botomera-barra2">
                  {/* <!--En este apartado iria los bredcrums--> */}
                  <p className="headerTitle">
                    Inicio | Alertas que requieren atención{" "}
                  </p>
                </div>
                <ul className="nav nav-pills nav-fill ml-auto navPosition">
                  <li className="nav-item dropdown">
                    <NavItemButton text="Directorio" direction="/directorio" />
                  </li>

                  {evaluatePermission([2], rolPermitions) ? (
                    <li className="nav-item dropdown">
                      <NavItemButton
                        text={textPermission(2, rolPermitions)}
                        direction="/bancos"
                      />
                    </li>
                  ) : null}

                  {evaluatePermission([3], rolPermitions) ? (
                    <li className="nav-item dropdown">
                      {/* Ventas */}
                      <NavHeaderButtom text="Ventas" />
                      <div className="dropdown-content">
                        <NavItemButton
                          text="Administración cuotas"
                          direction="#"
                        />

                        <NavItemButton
                          text="Contratos"
                          direction="/ventas/contratos"
                        />

                        <NavItemButton
                          text="Antigüedad de saldos CxC"
                          direction="#"
                        />

                        <NavItemButton
                          text="Cuentas por cobrar"
                          direction="#"
                        />

                        <NavItemButton
                          text="Saldos y movimientos de CxC"
                          direction="#"
                        />

                        <NavItemButton text="Reportes" direction="#" />
                      </div>
                    </li>
                  ) : null}

                  {evaluatePermission([4], rolPermitions) ? (
                    <li id="drop-admin-reports" className="nav-item dropdown">
                      {/* Administracion */}
                      <NavHeaderButtom text="Administración" />
                      <div className="dropdown-content">
                        <NavItemButton
                          text="Facturas emitidas"
                          direction="/administracion/facturas-emitidas"
                        />

                        <NavItemButton
                          text="Notas de crédito emitidas"
                          direction="/administracion/notas-credito-emitidas"
                        />

                        <NavItemButton
                          text="Pedidos"
                          direction="/administracion/pedidos"
                        />

                        <NavItemButton
                          text="Facturas recibidas"
                          direction="/administracion/recepcion-facturas"
                        />

                        <NavItemButton
                          text="Notas de crédito recibidas"
                          direction="/administracion/notas-credito-recibidas"
                        />

                        <NavItemButton
                          text="Ordenes de compra"
                          direction="/administracion/ordenes-compra"
                        />

                        <NavItemButton
                          text="Antigüedad de saldos CxP"
                          direction="#"
                        />

                        <NavItemButton text="Cuentas por pagar" direction="#" />

                        <NavItemButton
                          text="Saldos y movimientos de CxP"
                          direction="/administracion/ordenes-compra"
                        />
                      </div>
                    </li>
                  ) : null}

                  <li className="nav-item dropdown">
                    <NavHeaderButtom text={textPermission(5, rolPermitions)} />
                    <div className="dropdown-content">
                      {evaluatePermission([113, 114, 115], rolPermitions) ? (
                        <NavItemButton text="Avisos" direction="/avisos" />
                      ) : null}

                      {/* What about this? */}
                      {false ? (
                        <NavItemButton text="Alertas" direction="/alertas" />
                      ) : null}

                      {/* Roles */}
                      {evaluatePermission([111], rolPermitions) ? (
                        <NavItemButton text="Roles" direction="/roles" />
                      ) : null}

                      <NavItemButton
                        text="Parámetros"
                        direction="/procesos-especiales/parametros"
                      />

                      {/* Usuarios */}
                      {evaluatePermission([112], rolPermitions) ? (
                        <NavItemButton text="Usuarios" direction="/usuarios" />
                      ) : null}
                    </div>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <div className={notBorder}>
              <div className="botonera-barra1"></div>
              <div className="bredcrums-barra2 ">
                <BreadCrums myBredCrum={bredcrum} />
              </div>
            </div>
          )}
        </div>
      </div>

      <ChangePassword />
    </div>
  );
}
