import React from "react";
import NumberFormat from "react-number-format";

/**
 * Render a percentage input
 * @param {object} props - Props
 * @param {string} props.label - Label for percentage input
 * @param {string} props.name - name attribute for the input
 * @param {string?} props.id - id attribute for the input
 * @param {string?} props.placeholder - placeholder attribute for the input
 * @param {number?} props.min - Minimum value for the input
 * @param {string?} props.additionalClasses - classname attribute
 * @param {number?} props.value - Default value to load for the input
 * @param {object} props.forwardRef - ref input
 * @param {(value:import("react-number-format").NumberFormatValues)=>void} props.onValueChange - Values types on the input
 * @returns {JSX.Element}
 */
function Percentage({
  label,
  name,
  id = window.crypto.randomUUID(),
  placeholder = "Escribe aqui",
  min = 0,
  onValueChange = () => {},
  additionalClasses = "",
  forwardRef = {},
  value = null,
}) {
  return (
    <div className={additionalClasses}>
      <label id={`lbl_${id}`} htmlFor={id}>
        {label}
      </label>

      <NumberFormat
        className="rounded px-2"
        value={value}
        onValueChange={(value) => onValueChange(value)}
        allowNegative={false}
        suffix="%"
        id={id}
        name={name}
        placeholder={placeholder}
        getInputRef={forwardRef}
        min={min}
      />
    </div>
  );
}

export default Percentage;
