import React, { useContext, useEffect, useState } from "react";
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { CustomTable } from "../../../components/general/Table/CustomTable";
import { BanksNavigation } from "../../../static/StaticBreadCrums";

import { DefaultTable } from "js-smart-table/dist/pagination";
import {
  configurationTableBankAccounts,
  getBankAccont,
  parseInitialAccount,
} from "./TableConfiguration";
import Options from "./Options";
import TableContext from "../../../context/Table/TableContext";
import { functionalityPopUp } from "../../../helpers/Apis/Directory";
import { setInfoModal } from "../../../helpers/Banks/banks";
import { ModalInfo } from "./ModalInfo";
import { ModalAddBank } from "components/general/ModalAddBank";
import usePermissions from "customHooks/usePermissions";

export const Bancos = () => {
  const { refetch, setReload } = useContext(TableContext);
  let bankAccountTable;
  let configTable = {
    ...configurationTableBankAccounts,
    paginationFn: async function (
      page,
      order,
      columnOrdering,
      additionalQuery = ""
    ) {
      let bankAccounts = await getBankAccont(
        page,
        order,
        columnOrdering,
        additionalQuery
      );
      const cuentasConfiguradas = await parseInitialAccount(
        bankAccounts.banckAccount
      );

      bankAccounts = {
        ...bankAccounts,
        banckAccount: cuentasConfiguradas,
      };
      setinfoRow(null);
      return bankAccounts;
    },
  };

  const [infoRow, setinfoRow] = useState(null);

  function getInfoRow() {
    const fila = bankAccountTable.infoRow;
    setinfoRow(fila);
    setInfoModal(fila);
    console.log(fila);
  }
  useEffect(() => {
    async function getTable() {
      const bankAccounts = await getBankAccont(1, "ASC", "1");
      const cuentasConfiguradas = await parseInitialAccount(
        bankAccounts.banckAccount
      );

      configTable = {
        ...configTable,
        rows: cuentasConfiguradas,
        pages: bankAccounts.pages,
        actualPage: bankAccounts.actualPage,
        cbSelection: getInfoRow,
      };
      bankAccountTable = new DefaultTable(configTable);
      bankAccountTable.printTable();
      bankAccountTable.printPagination();
      functionalityPopUp(0, "bankAccountId");
    }
    getTable();
  }, [refetch]);
  useEffect(() => {
    functionalityPopUp(0, "bankAccountId");
  }, [infoRow]);

  const { checkPermission } = usePermissions(
    "78dc3e8a-6c74-45a1-b732-d598542f3439"
  );

  return (
    <>
      <HomeHeader mostrar={false} bredcrum={BanksNavigation} />
      <div className="container">
        {checkPermission("78dc3e8a-6c74-45a1-b732-d598542f3439") ? (
          <ModalAddBank onAdded={() => setReload(!refetch)} />
        ) : null}
      </div>
      <div className="container d-flex justify-content-start pl-3 spacingOptions">
        {infoRow === null ? null : <Options row={infoRow} />}
      </div>
      <div className="container mt-3">
        <CustomTable
          tableID="bankAccountId"
          paginationID="bankAccountPagination"
        />
      </div>

      <button
        id="triggerViewInfo"
        type="button"
        class="d-none"
        data-toggle="modal"
        data-target="#modalInfoId"
      ></button>
      <ModalInfo idModal="modalInfoId" titleModal="Mas Informacion" />
    </>
  );
};
