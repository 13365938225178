import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { createContext } from "react";
import Dialog from "structure/Dialog";
import useTerminateContract from "./useTerminateContract";
import { Label } from "structure/Document";
import Footer from "./Footer";
import ui from "./styles.module.scss";
/**
 * @type {import("react").Context<import("./useTerminateContract/types").ReturnTerminateContract|undefined>}
 */
export const ContextTerminateContract = createContext(undefined);

/**
 * Render a button to terminate the contract
 * @param {import("./types").PropsTerminateContract} props - Props
 * @returns {JSX.Element}
 */
export default function TerminateContract({
  idContract,
  idStatus = null,
  children = <></>,
  forwardRef,
  onCancelled = () => {}
}) {
  const hook = useTerminateContract(idContract, onCancelled);

  if (idStatus !== 7) return <></>;

  return (
    <ContextTerminateContract.Provider value={hook}>
      <div>
        <div onClick={hook.open} ref={forwardRef}>
          {children}
        </div>

        <Dialog
          width={40}
          footer={<Footer />}
          title="Terminar contrato"
          forwardRef={hook.dialog.refDialog}
          closeCallback={hook.close}
        >
          <div className={ui.label}>
            <Label required={hook.motive.length <= 0}>
              Mótivo de cancelación
            </Label>
            <span>{hook.motive.length}</span>
            <span>256</span>
          </div>
          <textarea
            maxLength={256}
            onChange={(e) => hook.setMotive(e.target.value)}
            value={hook.motive}
            placeholder="Escribe aquí (Obligatorio)"
          ></textarea>
        </Dialog>
      </div>
    </ContextTerminateContract.Provider>
  );
}
