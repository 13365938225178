import React, { useState } from "react";
import { Label } from "structure/Document";
import scss from "../TypeTicket/styles.module.scss";
import Select from "react-select";

/**
 * @param {import("./types").PropsTicketStatus} props - Props
 * @returns {JSX.Element}
 */
export default function TicketStatus({
  onChange = () => {},
  selected = "pending",
}) {
  const [options, setOptions] = useState([
    {
      value: "pending",
      label: "Pendientes",
    },
    {
      value: "assigned",
      label: "En proceso",
    },
    {
      value: "inProgress",
      label: "Por revisar",
    },
    {
      value: "finished",
      label: "Terminados",
    },
    {
      value: "evaluated",
      label: "Evaluados",
    },
  ]);

  // const defaultValue = options.find((option) => option.value === selected);

  const handleOnChange = (status) => {
    if (status === selected) return;

    onChange(status);
  };

  return (
    <div className={scss.typeTickets}>
      <Label>Estatus</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select w-100"
        placeholder={`Selecciona`}
        defaultValue={options.find((option) => option.value === selected)}
        selected={options.find((option) => option.value === selected)}
        value={options.find((option) => option.value === selected)}
        isClearable={false}
        isSearchable={true}
        options={options}
        onChange={(option) => handleOnChange(option.value)}
      />

    </div>
  );
}
