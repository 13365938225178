import React, { useContext } from "react";
import EditDirectory from "./EditDirectory";
import style from "../styles.module.scss";
import ContactsDirectory from "./ContactsDirectory";
import ToDoDirectory from "./ToDoDirectory";
import DocumentsDirectory from "./DocumentsDirectory";
import EstadoDeCuenta from "./EstadoDeCuenta";
import { DirectoryContext } from "..";

export default function OptionsDirectory() {
  const hook = useContext(DirectoryContext);

  return (
    <div className={style.desktopOptions}>
      <EditDirectory />
      <ContactsDirectory />
      <ToDoDirectory />
      <DocumentsDirectory />
      {hook.selected !== null && (
        <EstadoDeCuenta idCustomer={hook.selected?.customerID} />
      )}
    </div>
  );
}
