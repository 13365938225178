import React from "react";

import {
  InputNumber,
  InputText,
  ErrorMessage,
  ToggleButton,
  InputCurrency,
} from "../../../components/individual/inputs/Inputs";

import { Date as DateInput } from "../../../components/individual/inputs/Date";
import PayMethods from "../../../components/general/Forms/Selects/PayMethods";
import { MovementDate } from "components/general/MovementDate";
import { Label } from "structure/Document";
import { useContext } from "react";
import { EditContext } from "./Edit/EditMovement";
import { mnCurrency } from "helpers/money";
import InformativeTooltip from "components/individual/InfoTooltip";

function FormDefault({ forwardRef, errors, date }) {
  const { movementInfo, updateAmount } = useContext(EditContext);

  return (
    <>
      <InputNumber
        decimal={false}
        forwardRef={forwardRef}
        id="id_movement"
        htmlFor="id_movement"
        name="id_movement"
        aditionalClasses="d-none"
      />

      <DateInput
        id="registerDate"
        defaultDate={date}
        name="registerDate"
        forwardRef={forwardRef}
        maxDate={new Date()}
      />

      <InputText
        id="check"
        labelTxt="Cheque"
        forwardRef={forwardRef}
        htmlFor="check"
        name="check"
        placeholderTxt="Escribe aqui"
      />
      <ErrorMessage message={errors["check"]?.message} id="error_check" />

      <InputText
        id="reference"
        htmlFor="reference"
        labelTxt="Referencia"
        name="reference"
        placeholderTxt="Escribe aqui"
        forwardRef={forwardRef}
      />
      <ErrorMessage
        message={errors["reference"]?.message}
        id="error_reference"
      />

      <InputText
        id="concept"
        htmlFor="concept"
        name="concept"
        forwardRef={forwardRef}
        labelTxt="Concepto"
        placeholderTxt="Escribe aqui"
      />
      <ErrorMessage
        message={errors["concept"]?.message}
        id="error_id_movement"
      />

      <div className="my-2">
        {movementInfo.status === 1 ? (
          <>
            <Label required={movementInfo.amount===0}>Importe</Label>
            <InputCurrency
              defaultValue={movementInfo.amount}
              onChange={(amount) => updateAmount(amount)}
            />
          </>
        ) : (
          <>
            <Label>
              Importe
              <InformativeTooltip>
                <p>
                  Para actualizar el importe, el movimiento debe estar en
                  estatus <b>Activo</b>
                </p>
              </InformativeTooltip>
            </Label>
            <p>{mnCurrency(movementInfo.amount)}</p>
          </>
        )}
      </div>

      <PayMethods forwardRef={forwardRef} />
    </>
  );
}

export default FormDefault;
