import { RedirectButton } from "components/individual/buttons/Buttons";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import React, { useContext } from "react";

export default function Todo() {
  const hook = useContext(CONTEXT_HOME);

  return (
    <RedirectButton
      id="todo"
      text="To Do"
      link={`directorio/documentos/reminders/${hook.quote.customer.id}/${hook.quote.idDocument}`}
    />
  );
}
