import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import { cancelDocument } from "helpers/Apis/Documents";
import { useContext, useState } from "react";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isCancelling: false,
  motive: "",
};

/**
 * Handle the termination of the contract
 * @param {number} idContract - Id of the contract to cancel
 * @param {()=>void} [onCancelled=()=>{}]
 * @returns {import("./types").ReturnTerminateContract}
 */
export default function useTerminateContract(idContract, onCancelled = () => {}) {
  const dialog = useDialog();
  const { userInfo } = useContext(LoginContext);

  const [state, setState] = useState(INITIAL_STATE);

  const setMotive = (motive) =>
    setState((current) => ({
      ...current,
      motive,
    }));

  const open = () => {
    setState(INITIAL_STATE);
    dialog.open();
  };

  const close = () => {
    setState(INITIAL_STATE);
    dialog.close();
  };

  const cancelContract = async () => {
    setState((current) => ({
      ...current,
      isCancelling: true,
    }));

    /**
     * @type{import("helpers/Apis/typesDocuments").CancelQuote}
     */
    const cancelDocumet = {
      quoteId: -1,
      ocId: "",
      preInvoiceID: "",
      invoiceID: "",
      contractId: `${idContract}`,
      executiveId: userInfo[0]?.userID,
      motive: state.motive,
      docuemntType: 6,
      modifyBy: userInfo[0]?.fullName,
    };

    const wasCancelled = await cancelDocument(cancelDocumet);

    if(wasCancelled) onCancelled();

    setState((current) => ({
      ...current,
      isCancelling: false,
    }));

    close();
  };

  return {
    dialog,
    ...state,
    cancelContract,
    setMotive,
    open,
    close,
  };
}
