import styled from "styled-components";

export const WinQuoteContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  gap: 1rem 0;
`;
export const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;
export const RightContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: scroll;
`;

export const BodyContrainer = styled.div`
  display: grid;
  height: 40vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  grid-template-columns: 10% 85%;
  justify-content: space-between;
  width: 100%;
  background: var(--cardBackground);
  padding: 15px;
  border-radius: 20px 15px;
  color: var(--primaryColor);

  @media (max-width: 767px) {
    flex-direction: column;
    height:auto;
    display:flex;
    gap:1.2rem 0;
  }

`;
export const TopContainer = styled.div`
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: var(--cardBackground);
  width: 100%;
  border-radius: 15px;
  color: var(--primaryColor);
  padding: 15px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 1rem 0;

    button{
      width:100%;
    }
  }
`;
export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background: var(--cardBackground);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
`;

export const MenuOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width:100%;
  }

  


`;
export const HeaderContrainer = styled.div`
  width: 90%;
  height: 100%;
  border-radius: 10px;
  background: var(--cardBackground);
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 1rem 0;

    > * {
      width: 100% !important;
    }
  }
`;

export const SocialResonContainer = styled.div`
  width: 40%;
  margin-left: 5px;
  margin-right: 5px;
`;
export const MultiplesSocialResons = styled.div`
  width: 10%;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
`;
export const CreditDaysContainer = styled.div`
  width: 5%;
  margin-right: 5px;
  margin-left: 5px;
  justify-content: center;
`;
