import { Pagination } from "components/individual/Pagination";
import { Spinner } from "components/individual/Spinner/Spinner";
import useCloudFiles from "customHooks/useCloudFiles";
import React, { createContext, useEffect, useRef } from "react";
import { FilesAttached } from "./FilesAttached";
import scss from "./styles.module.scss";

export const ContextCloudFiles = createContext();
const { Provider } = ContextCloudFiles;

export const CloudFiles = ({
  idRegister = 2138,
  idTypeEntity = 1,
  onChange = () => {},
}) => {
  const { cloud, updatePage, handleToggleCheck, listFiles } = useCloudFiles({
    idRegister,
    idTypeEntity,
    onChange,
  });

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <Provider value={{ files: listFiles, handleToggleCheck }}>
      {cloud.isLoading ? (
        <Spinner hidden={false} text="Cargando archivos de la nube" />
      ) : (
        <>
          {listFiles.length > 0 ? <p>Archivos adjuntos</p> : null}
          <div className={scss.containerFilesAttached}>
            <FilesAttached />
          </div>

          {listFiles.length > 0 ? <hr /> : null}

          <div className={scss.containerTable}>
            <div className={scss.headerTable}>
              <span>Archivo</span>
            </div>
            {cloud.files.map((file, i) => (
              <label
                htmlFor={`cloudFile-${i}`}
                className={scss.row}
                key={`${key.current}-${i}`}
              >
                <input
                  checked={cloud.selecteds[file.idAssocaitedFile] !== undefined}
                  type="checkbox"
                  id={`cloudFile-${i}`}
                  onChange={(e) => handleToggleCheck(e.target.checked, file)}
                />
                <span>{file.fileName}</span>
              </label>
            ))}
            <Pagination
              onChange={updatePage}
              actualPage={cloud.pagination.actualPage}
              pages={cloud.pagination.pages}
            />
          </div>
        </>
      )}
    </Provider>
  );
};
