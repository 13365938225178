import React, { useContext } from "react";
import PayMethods from "components/general/Forms/Selects/PayMethodsV2";
import { ContextCreateInvoiceV2 } from "..";

export default function CustomPayMethod() {
  const hook = useContext(ContextCreateInvoiceV2);

  if (hook.receiverData === null || hook.receiverData === undefined)
    return <></>;
  return (
    <PayMethods
      rfc={hook.receiverData.Rfc}
      value={+hook.payMethod}
      onChange={(payMethod) => hook.updatePayMethod(payMethod.code)}
    />
  );
}
