import React, { useContext, useRef } from "react";
import { ContextIncomingComplement } from "..";
import styles from "../Concept/styles.module.scss";
import { mnCurrency } from "helpers/money";

/**
 *
 * @returns {JSX.Element}
 */
export default function PueAssociations() {
  const hook = useContext(ContextIncomingComplement);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const ppd = hook.isForCancel === false ? hook.complements.map(item=>item.associations.map(ppd=>ppd)).flat() : [];

  // if (hook.associations.length <= 0)
  if(hook.isForCancel && hook.associations.length <= 0)
    return (
      <details>
        <summary className={styles.summary}>Facturas asociadas</summary>
        <h4
          style={{
            padding: "0 20px",
          }}
          className="text-left"
        >
          No hay facturas asociadas
        </h4>
      </details>
    );

  return (
    <details>
      <summary className={styles.summary}>Facturas asociadas</summary>
      {hook.concepts.length > 0 ? null : (
        <div className={styles.header}>
          <p>-</p>
          <p>Concepto</p>
          <p>Factura</p>
          <p>Parcialidad</p>
          <p>Moneda</p>
          <p>Total</p>
          <p>Asociado</p>
          <p>Importe ingreso</p>
          <p>TC</p>
        </div>
      )}

      <div className={styles.contentAssociations}>
        {[...ppd,...hook.associations].map((cxc,i) => (
          <label key={`${key.current}-${i}`} className={styles.row} htmlFor={`pue-option-${cxc.id}`}>
            {hook.displayCheckboxs ? (
              <p className="d-flex justify-content-center align-content-center">
                <input
                  id={`pue-option-${cxc.id}`}
                  name={`pue-option-${cxc.id}`}
                  onChange={() => hook.handleCheckPue(cxc)}
                  type={"checkbox"}
                />
              </p>
            ) : (
              <p>-</p>
            )}
            <p>{cxc.invoice.concept.description}</p>
            <p>{cxc.invoice.folio}</p>
            <p>{cxc.cxc.partialitie.ui}</p>
            <p>{cxc.invoice.currency}</p>
            <p>
              {cxc.invoice.total.text} {cxc.invoice.currency}
            </p>
            <p>
              {cxc.paid.text} {cxc.invoice.currency}
            </p>
            <p>
              {cxc.applied.text} {cxc.applied.currency}
            </p>
            <p>{mnCurrency(cxc?.tc||cxc?.applied?.tc?.number)}</p>
          </label>
        ))}
      </div>
    </details>
  );
}
