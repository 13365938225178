import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../individual/buttons/Buttons";
import { Error, Success, YesNoAlert } from "../../../helpers/alerts";
import {
  DeleteFileFromDb,
  DeleteFromBlobStorage,
} from "../../../helpers/Apis/associateFiles";
import { Spinner } from "../../individual/Spinner/Spinner";

/**
 * Delete a file from database and azure blob storage
 *
 * @param {object} props - Props component
 * @property {string} props.id - id attribute of the button
 * @property {string} props.text - Text to display on delete button
 * @property {string} props.alertMessage - Alert message to display on confirmation prompt
 * @property {string} props.pathFile - Path where it's saved the file resource on blob storage
 * @property {number} props.idFile - Id of the register on the database
 * @property {string} props.deletedBy - Name of the person who executed delete
 * @property {Function} props.onDeleted - Cb to execute when the delete it's OK
 * @returns {JSX.Element} React component
 */
function DeleteFile({
  id = "deleteFileBtn",
  text = "Eliminar",
  alertMessage = "¿Eliminar archivo?",
  pathFile = null,
  idFile = null,
  deletedBy = "na",
  onDeleted = () => {},
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const promptConfirmDelete = () => {
    YesNoAlert(alertMessage, deleteFile);

    async function deleteFile() {
      try {

        setIsDeleting(true);

        await DeleteFileFromDb(idFile, deletedBy);

        await DeleteFromBlobStorage(pathFile);

        setIsDeleting(false);

        Success(() => {}, "Archivo eliminado");

        onDeleted();
      } catch (error) {

        setIsDeleting(false);

        Error(() => {}, error);
      }
    }
  };

  return (
    <>
      {pathFile !== null ? (
        <>
          {isDeleting ? (
            <Spinner
              hidden={false}
              idSpinner="spinnerDeleteFile"
              text="Eliminando archivo..."
            />
          ) : (
            <Button
              id={id}
              text={text}
              key={id}
              funcion={promptConfirmDelete}
            />
          )}
        </>
      ) : null}
    </>
  );
}

DeleteFile.propTypes = {};

export default DeleteFile;