import React from "react";
import BankContext from "context/Bank/BankContext";
import { BankContext as BankHomeContext } from "pages/BanksV2";
import { useContext, useState, useRef } from "react";
import BankAccountReportV2 from "structure/BankAccountReportV2";
import EditBankAccount from "structure/EditBankAccount";
import MoreInfoBankAccount from "structure/MoreInformationBankAccount";
import { RedirectButton } from "../buttons/Buttons";
import ButtonV2 from "../ButtonsV2/Button";
import Icon from "../Icon";
import "./styles.css";
import usePermissions from "customHooks/usePermissions";
import MovementReportV2 from "structure/MovementReportV2";
import { useHistory } from "react-router-dom";

/**
 *
 * @param {import("./types").BankAccountCard} props - Props
 * @returns {JSX.Element}
 */
export const BankCard = (props) => {
  const { isActive = true } = props;

  const contextBank = useContext(BankContext);
  const bankHomeContext = useContext(BankHomeContext);
  const { checkPermission } = usePermissions();

  const history = useHistory();

  const redirectToMovements = () =>
    history.push(`/movimientos?cuenta=${props.id}`);

  const [isOpen, setIsOpen] = useState({
    edit: false,
    moreInfo: false,
    bankAccountReport: false,
    movementReport: false,
  });

  /**
   *
   * @param {import("./types").Modals} key
   * @returns
   */
  const close = (key) =>
    setIsOpen((state) => ({
      ...state,
      [key]: false,
    }));

  /**
   *
   * @param {import("./types").Modals} key
   * @returns
   */
  const open = (key) =>
    setIsOpen((state) => ({
      ...state,
      [key]: true,
    }));

  const handleEdit = () => {
    close("edit");
    bankHomeContext.triggerRefetch();
  };

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domBankAccount = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMovementReport = useRef();

  return (
    <>
      {isOpen.edit ? (
        <EditBankAccount
          id={props.id}
          onClose={() => close("edit")}
          onSuccess={handleEdit}
        />
      ) : null}

      <BankAccountReportV2 forwardRef={domBankAccount} id={props.id} />
      <MovementReportV2 id={props.id} forwardRef={domMovementReport} />

      {isOpen.moreInfo ? (
        <MoreInfoBankAccount id={props.id} onClose={() => close("moreInfo")} />
      ) : null}

      <article className={props.doFlip ? "flip-card" : "no-flip"}>
        <div className="flip-card-inner">
          <div className="Card frontSide flip-card-front">
            <div className="headerCard">
              <div className="currency">{props.currency}</div>
              <div className="bankcode">
                {props.clave === undefined ? null : (
                  <div className="infoclave">
                    {props.clave} - {props.shortname}
                  </div>
                )}
              </div>
            </div>
            <div className="bodyCard">{props.account}</div>
            <div className="footerCard">
              <b>Saldo</b>
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(props.currentBalance)}
            </div>
          </div>
          <div className="flip-card-back containercardback">
            <div className="moreinfo" onClick={() => open("moreInfo")}>
              i
            </div>
            {/* <MoreInfoBankAccount id={props.id} /> */}
            <div className="buttonsContainer">
              {checkPermission("802701fc-c731-40d0-ba4f-50e65dfdb8a8") ? (
                <ButtonV2 onClick={() => open("edit")}>
                  <Icon nameIcon="pen" />
                  Editar
                </ButtonV2>
              ) : null}

              {isActive && (
                <RedirectButton
                  onClick={() => contextBank.setAccountToUse(props.id)}
                  text={
                    <>
                      <Icon nameIcon="handHoldingDollar" />
                      Ingresos
                    </>
                  }
                  link={`/v2/bancos/ingresos?cuenta=${props.id}`}
                />
              )}

              {isActive && (
                <RedirectButton
                  text={
                    <>
                      <Icon nameIcon="dollar" />
                      Egresos
                    </>
                  }
                  link={`/v2/bancos/egresos?cuenta=${props.id}`}
                />
              )}

              {checkPermission("1ccbafd1-86fe-4a1c-9cb0-bad57fd18e9a") &&
              isActive ? (
                <RedirectButton
                  text={
                    <>
                      <Icon nameIcon="coins" /> Conciliacion
                    </>
                  }
                  link={`/v2/bancos/conciliacion?cuenta=${props.id}`}
                />
              ) : null}

              {isActive && (
                <ButtonV2 onClick={() => domBankAccount.current.click()}>
                  <Icon nameIcon="fileInvoiceDollar" />
                  Estado de cuenta
                </ButtonV2>
              )}

              {isActive && (
                <ButtonV2 onClick={redirectToMovements}>
                  <Icon nameIcon="wallet" />
                  Movimiento
                </ButtonV2>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
