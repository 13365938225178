import React, { useContext, useEffect, useReducer } from "react";
import { breadEditDoc } from "static/StaticBreadCrums";
import { ExchangeInformative } from "components/general/ExchangeInformative/ExchangeInformative";
import { initialState, reducer, SET_COMMENTS, SET_EXECUTIVE } from "./reducer";
import { Tabs } from "components/general/Tabs/Tabs";
import { useParams } from "react-router-dom";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import DocItems from "./DocItems";
import EditContract from "./Contract";
import EditInvoice from "./Invoice";
import EditOc from "./OC";
import LoginContext from "context/Login/LoginContext";
import { Comments } from "components/general/Comments";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function EditDocument() {
  const { typeDocument, documentId, customerId } = useParams();

  const { userInfo } = useContext(LoginContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const ButtonSubmit = (
    <div className="d-flex justify-content-end my-4">
      {state.isUpdating ? (
        <Spinner hidden={false} text="Actualizando pre-facutura..." />
      ) : (
        <ButtonV2 form="updateDocument">Actualizar</ButtonV2>
      )}
    </div>
  );

  // 2 - Preinvoice
  // 3 - OC
  // 6 - Contrato

  /**
   * @typedef {import("types/typedef/customHooks/useEditDocument").TabEditDocI}
   */
  const formEdition = {
    2: {
      component: <EditInvoice state={state} dispatch={dispatch} />,
      nameDocument: "Pre-factura",
    },
    3: {
      component: <EditOc state={state} dispatch={dispatch} />,
      nameDocument: "Orden de compra",
    },
    6: {
      component: <EditContract state={state} dispatch={dispatch} />,
      nameDocument: "Contrato",
    },
  };

  useEffect(() => {
    if (!userInfo[0]) return;

    dispatch({
      type: SET_EXECUTIVE,
      payload: {
        id: userInfo[0]["userID"],
        fullName: userInfo[0].fullName,
      },
    });
  }, [userInfo]);

  const isUpdating=false

  return (
    <>
      <BreadCrumV2 path={breadEditDoc(typeDocument, customerId)} />
      

      <div className="container">
        <ExchangeInformative>
          <ExchangeInformative.Dof /> |
          <ExchangeInformative.Bank /> |
          <ExchangeInformative.Enterprise /> |
          <ExchangeInformative.Date />
        </ExchangeInformative>
      </div>
      <div className="container shadowBackground px-5 py-5">
      
        <Tabs
          idContainerContent="documentInfo"
          idContainerTabs="tab"
          tabButtons={{
            document: {
              text: formEdition[typeDocument]["nameDocument"],
              selected: true,
              component: (
                <>
                  {state.document !== null ? ButtonSubmit : null}
                  {formEdition[typeDocument]["component"]}
                </>
              ),
            },
            documentItems: {
              text: "Partidas",
              selected: false,
              component: (
                <>
                  {state.document !== null ? ButtonSubmit : null}
                  <DocItems
                    idDocument={documentId}
                    isOc={+typeDocument === 3 ? true : false}
                    currencyDoc={state.document?.currency.code}
                  />
                </>
              ),
            },
            documentComments: {
              text: "Comentarios",
              selected: false,
              component: (
                <>
                  {state.document !== null ? ButtonSubmit : null}
                  <Comments
                    idDocument={+documentId}
                    onChange={(comments) =>
                      dispatch({
                        type: SET_COMMENTS,
                        payload: comments,
                      })
                    }
                  />
                </>
              ),
            },
          }}
        />
      </div>
    </>
  );
}
