import Currency from "components/individual/inputs/Currency";
import React from "react";
import { useContext } from "react";
import CurrencyInput from "react-currency-input-field";
import DocumentContext from "../ContextDocument";
import {
  InputLabelPercentageSection,
  InputLabelSection,
  InputsSection,
} from "./Conteiners";
import { InputCurrency, InputText } from "components/individual/inputs/Inputs";
import { Probability } from "structure/Document";
import Money from "components/general/Forms/Money";

export const InputSections = () => {
  /**
   * @type{import("../../../../../../types/documentActions").UseDocumentV2I}
   */
  const {
    moneyInfo,
    handleOnChangeTc,
    handleOnDocumentCurrencyChange,
    handleOnProbabilityChange,
    probabilityValueText,
    actionType,
    documentType,
    interfaceControl,
  } = useContext(DocumentContext);
  const moneyCurrency = moneyInfo.currency.value;
  const hasNotCurrency = moneyCurrency !== "MXN" && moneyCurrency !== "USD";
  const isViewAction = actionType === "Ver" || hasNotCurrency;
  const isDisabled = isViewAction && interfaceControl.disableTc;
  const placeholderTxt = isDisabled ? "* * *" : moneyInfo.currency?.value;

  const handleCurrencyChange = (value) => {
    console.log("value", value);
    /**
     * @type{id: number, value: string}
     */
    const currency = {
      id: value === "MXN" ? 1 : 2,
      value,
    };
    handleOnDocumentCurrencyChange(currency);
  };
  return (
    <InputsSection>
      {documentType === 3 ? (
        <InputLabelPercentageSection></InputLabelPercentageSection>
      ) : (
        <InputLabelPercentageSection>
          <p>Probabilidad</p>
          {actionType === "Ver" ? (
            // <p>{moneyInfo.probability.value}</p>
            <InputText
              disabled={true}
              placeholderTxt={probabilityValueText(moneyInfo.probability.value)}
            />
          ) : (
            <Probability
              selected={moneyInfo.probability.id}
              onChange={handleOnProbabilityChange}
            />
          )}
        </InputLabelPercentageSection>
      )}
      <InputLabelSection>
        <p>TC</p>
        {actionType === "Ver" ? (
          interfaceControl.disableTc ? (
            <InputText disabled={true} placeholderTxt={"NA"} />
          ) : (
            <InputText disabled={true} placeholderTxt={moneyInfo.tc?.text} />
          )
        ) : interfaceControl.disableTc ? (
          <InputText disabled={true} placeholderTxt={"NA"} />
        ) : (
          // <InputCurrency
          //   labelTxt=""
          //   placeholder="Escribe aquí"
          //   defaultValue={moneyInfo.tc?.number}
          //   maxLength={5}
          //   onChange={handleOnChangeTc}
          //   disabled={actionType === "Ver"}
          // />
          <Money
            maxDecimals={2}
            value={moneyInfo.tc?.number}
            placeholder="0.00"
            onChange={handleOnChangeTc}
          />
        )}
      </InputLabelSection>
      <InputLabelSection>
        <p>Moneda</p>
        {/* <Label required={true}>Moneda</Label> */}
        {isViewAction ? (
          <InputText disabled={true} placeholderTxt={placeholderTxt} />
        ) : (
          <Currency
            selected={moneyInfo.currency?.value}
            claseName="w-100"
            onChange={handleCurrencyChange}
          />
        )}
      </InputLabelSection>
    </InputsSection>
  );
};
