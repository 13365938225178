import { Input } from "components/individual/Input";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { BankAccountContext } from "..";

export const NextCheck = () => {
  const { register, id, errors, account } = useContext(BankAccountContext);

  return (
    <>
      {id === null ? (
        <>
          <div>
            <Label>No. siguiente cheque:</Label>
            <Input
              forwardRef={register}
              name="nextBankCheckNumber"
              placeholder="Escribe aquí"
              type="number"
            />
            <p className="text-danger">
              {errors?.nextBankCheckNumber?.message}
            </p>
          </div>
        </>
      ) : (
        <>
          <Label>No. siguiente cheque:</Label>
          <p>{account.currentBankCheckNumber + 1}</p>
        </>
      )}
    </>
  );
};
