import { getAssociatedFilesV2 } from "helpers/Apis/associateFilesV2";
import { useEffect, useState } from "react";

export default function useCloudFiles({
  idRegister,
  idTypeEntity,
  onChange = () => {},
}) {
  /**
   * State of the component
   * @type {[import("./types").StateCloudFiles,()=>void]}
   */
  const [modal, setModal] = useState({
    page: 1,
    pagination: {
      actualPage: 1,
      pages: 1,
    },
    files: [],
    isLoading: true,
    selecteds: {},
    selected: undefined,
  });

  useEffect(() => {
    (async function () {
      setModal({
        ...modal,
        isLoading: true,
        selected: undefined,
      });

      const { actualPage, files, pages } = await getAssociatedFilesV2(
        idTypeEntity,
        idRegister,
        modal.page,
        1
      );

      setModal({
        ...modal,
        files,
        isLoading: false,
        pagination: {
          actualPage,
          pages,
        },
      });
    })();
  }, [modal.page]);

  /**
   * Update the page that will be used to fetch the information
   * @param {number} page - Page
   * @returns {void}
   */
  const updatePage = (page) =>
    setModal({
      ...modal,
      page,
      selected: undefined,
    });

  /**
   * Handle the checkbox click
   * @param {boolean} isChecked - Indicates if the input is marked or not
   * @param {import("types/typedef/files").AssociatedFileI} file - Id of the cloud file
   */
  function handleToggleCheck(isChecked, file) {
    if (isChecked) {
      appendFile();
      return;
    }

    deleteFile();

    function appendFile() {
      let unrefFile = { ...modal.selecteds };
      unrefFile = {
        ...unrefFile,
        [file.idAssocaitedFile]: file,
      };

      onChange({
        areFilesSelected: Object.keys(unrefFile).length > 0 ? true : false,
        files: Object.entries(unrefFile).map(([key, value]) => value),
      });

      setModal({
        ...modal,
        selecteds: unrefFile,
      });
    }

    function deleteFile() {
      let unrefFiles = { ...modal.selecteds };
      delete unrefFiles[file.idAssocaitedFile];
      onChange({
        areFilesSelected: Object.keys(unrefFiles).length > 0 ? true : false,
        files: Object.entries(unrefFiles).map(([key, value]) => value),
      });
      setModal({
        ...modal,
        selecteds: unrefFiles,
      });
    }
  }

  return {
    cloud: modal,
    updatePage,
    handleToggleCheck,
    listFiles: Object.entries(modal.selecteds).map(([key, value]) => value),
  };
}
