import { Spinner } from "components/individual/Spinner/Spinner";
import React, { createContext } from "react";
// import Select from "react-select";
import Select from 'react-select/creatable';

import useItemSku from "./Hooks/useItemSku";

/**
 * Context of the component
 * @type {React.Context<import("./types").CatalogueContextI>}
 */
export const ContextCatalogueSku = createContext(undefined);
const { Provider } = ContextCatalogueSku;

/**
 * Render select combo of the catalogue
 * @param {object} props - Props
 * @param {(object:import("types/typedef/catalogue").CalculatedItemI)=>void} [props.onSelect=()=>{}] - Information of the item selected (existing or created)
 * @param {(item:import("./types").OnChangeSku)=>void} [props.onChange = () => {}] props.onChange - Function to execute when the item is changed
 * @param {"cotizacion"|"pedido"|"contrato"|"odc"} props.typeDocument - Type of document that's being created
 * @param {number|null|undefined} [props.defaultValue=null] - Default value for the combo or item
 * @returns {JSX.Element} Combo catalogue
 */
export default function CatalogSku({
  onSelect = () => {},
  onChange = () => {},
  typeDocument,
  defaultValue=null
}) {
  const {
    catalogue,
    isLoading,
    idBtnOpenNewItemCatalogue,
    handleNewItemV2,
    setItem,
    item,
  } = useItemSku(defaultValue);

  /**
   *
   * @param {import("types/typedef/catalogue").CatalogueItemI|{
   *  label:string,
   *  value:string,
   *  __isNew__?:true
   * }} item
   */
  const handleOnChange = (item) => {
    if (!item) {
      setItem(null);
      return;
    }

    const isNewItem = Object.keys(item).includes("__isNew__") ? true : false;

    let currentItem;
    if (!item) {
      currentItem = {
        value: null,
        isNewItem,
      };
    } else {
      currentItem = {
        ...item,
        isNewItem,
      };
    }

    if (isNewItem) {
      handleNewItemV2(currentItem);
    } else {
      setItem(currentItem);
    }
    onSelect(currentItem);
  };

  return (
    <Provider
      value={{
        catalogue,
        isLoading,
        idBtnOpenNewItemCatalogue,
        item,
        typeDocument,
      }}
    >
      {isLoading ? (
        <Spinner hidden={false} text="Cargando catálogo" />
      ) : (
        <Select
          isClearable={false}
          options={catalogue}
          
          placeholder="Selecciona"
          onChange={(value) => {
            handleOnChange(value);
            onChange(value);
          }}
          value={item}
          className="saiko-select"
          classNamePrefix="saiko-select"
        />
      )}
    </Provider>
  );
}
