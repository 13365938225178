import React from "react";
import Add from "./Add";
import CardsToDo from "./CardsToDo";
import panel from "pages/ToDo/styles.module.scss";
import { NavToDoItem } from "pages/ToDo/Navigation/styles";
import useToDoStructure from "customHooks/useToDoStructure";
import Select from "components/individual/SelectV4";
import ui from './styles.module.scss';
/**
 * Render the screen that displays the to do in order to view them and add/edit them
 * @param {import('./types').ToDoStructureParamsI} props - Props
 * @example
 * // Render the todos of the directory, customer is suda51
 * <ToDo id={294} typeToDo="contactos" idEntity={294} />
 * @returns {JSX.Element} Component to do
 */
export default function ToDo({
  typeToDo = "documentos",
  id = 993,
  idEntity = 216,
  showGlobalImportants = false,
}) {
  const { displayImportants, handleDone, state, triggerFlagToRefetch } =
    useToDoStructure();

  return (
    <>
      <div className={panel.containerToDos}>
        <div className={ui.options}>
          <Add
            parent={null}
            type={typeToDo}
            id={id}
            idEntity={idEntity}
            onAdd={triggerFlagToRefetch}
          />

          <div className={ui.filterMobile}>
            <b>To Do</b>
            <Select
              labelToDisplay="label"
              onChange={optionSelected=>{
                if(optionSelected.value === "closed") {
                  handleDone(true)
                  return
                }
                if(optionSelected.value === "open") {
                  handleDone(false)
                  return
                }
                displayImportants();
              }}
              defaultSelected={{
                keyToCompare: "value",
                value: state.option,
              }}
              options={async () => [
                {
                  label: "Cerrados",
                  value: "closed",
                },
                {
                  label: "Abiertos",
                  value: "open",
                },
                {
                  label: "Urgentes",
                  value: "urgent",
                },
              ]}
            />
          </div>

          <div className={panel.navigationToDos}>
            <NavToDoItem
              onClick={() => handleDone(true)}
              isSelected={
                state.diplayImportants === false && state.displayDones === true
              }
            >
              Cerrados
            </NavToDoItem>

            <NavToDoItem
              onClick={() => handleDone(false)}
              isSelected={
                state.diplayImportants === false && state.displayDones === false
              }
            >
              Abiertos
            </NavToDoItem>

            <NavToDoItem
              onClick={displayImportants}
              isSelected={state.diplayImportants === true}
            >
              Urgentes
            </NavToDoItem>
          </div>
        </div>

        <div className={panel.containerCards}>
          <CardsToDo
            showGlobalImportants={showGlobalImportants}
            type={typeToDo}
            id={id}
            displayDones={state.displayDones}
            displayImportants={false}
            refetchFlag={state.refetch}
            fetchJustImportants={state.diplayImportants}
          />
        </div>
      </div>
    </>
  );
}
