import React from "react";
import "./Styles.scss";

/**
 * Table component
 *
 * @param {object} props - Component props
 * @param {string} props.tableID - Table id
 * @param {string} props.paginationID - Pagination container id
 * @param {string} [props.paginationClass="default-pagination"] - Custom ccs for the pagination
 */
export const CustomTable = ({
  tableID,
  paginationID,
  paginationClass = "default-pagination",
}) => {
  return (
    <div className="pb-5">
      <div className="shadowBackgroundTable containerTable py-2 px-4 ">
        <div className="tableContent">
          <table id={tableID} className="default w-100"></table>
        </div>
        <div
          id={paginationID}
          className={`my-3 w-100 ${paginationClass}`}
        ></div>
      </div>
    </div>
  );
};
