import InformativeTooltip from "components/individual/InfoTooltip";
import React from "react";
import { useContext } from "react";
import { IvasContext } from "..";

/**
 * Display a warning message according the IVA selected by the user, this notifies that must select carefully the iva according some parameters
 * @returns {JSX.Element}
 */
export const Warning = () => {
  const { iva, excento } = useContext(IvasContext);

  switch (iva) {
    case 16:
      return;

    case 8:
      return (
        <InformativeTooltip>
          <p>Asegurate de...</p>
          <ol>
            <li>
              Estar facturando con un codigo postal considerado como fronterizo
              <a
                className="ml-2"
                href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/catCFDI_V_4_08092022.xls"
                target={"_blank"}
              >
                Revisar lista de codigos postales
              </a>
            </li>

            <li>
              Utilizar una clave de producto/servicio con estimulo fronterizo
              aplicable.
              <a
                className="ml-2"
                href="http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/catCFDI_V_4_08092022.xls"
                target={"_blank"}
              >
                Revisar lista de productos/servicios
              </a>
            </li>
          </ol>

          <hr />

          <a
            href="https://www.sat.gob.mx/consultas/22035/facturacion-con-estimulo-en-la-region-fronteriza-"
            target={"_blank"}
          >
            Más informacion de facturación con estímulo en la región fronteriza
          </a>
        </InformativeTooltip>
      );

    case 0:
      return (
        <InformativeTooltip>
          <p>
            Asegurate de utilizar un código o producto que pueda ser excento del
            IVA.
          </p>
          <a
            className="ml-2"
            href="https://facturama.mx/blog/que-actividades-estan-extentas-iva-mexico/#Tasa_del_0_del_IVA"
          >
            Lista de tasa del 0% del IVA
          </a>

          {excento ? (
            <>
              <hr />
              <p>Asi mismo que este sea excento de IVA</p>
              <a
                className="ml-2"
                href="https://facturama.mx/blog/que-actividades-estan-extentas-iva-mexico/#Exentos_de_IVA"
              >
                Lista excentos de IVA
              </a>
            </>
          ) : null}
        </InformativeTooltip>
      );

    default:
      return;
  }
};
