import { InputCurrency } from "components/individual/inputs/Inputs";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextAttendInvoice } from "..";

export default function TC() {
  const { isValid , approved } = useContext(ContextAttendInvoice);

  return !isValid.tc ? (
    <div className="mr-2">
      <Label>TC</Label>
      <InputCurrency placeholder="Escribe aqui" defaultValue={approved.tc}/>
    </div>
  ) : null;
}
