import React from "react";
import { ContextCashFlow } from "../..";
import { useContext } from "react";
import { mnCurrency } from "helpers/money";
import style from "../../styles.module.scss";

export default function TotalBanks() {
  const hook = useContext(ContextCashFlow);

  return (
    <div className={style.totalBankAccounts}>
      <p></p>
      <p>Total bancos</p>
      <p>-</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByPrediction.totalNextDay)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByPrediction.totalNext7Days)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow.totalByPrediction.totalNext14Days)}</p>
    </div>
  );
}
