import * as yup from "yup";

export const comment = yup
  .string()
  .required("Ingresa un comentario")
  .max(1000, "Máximo 1000 caracteres")
  .nullable(false)
  .typeError("Ingresa comentario");

export const commentSchema = yup.object().shape({ comment });
