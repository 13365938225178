import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { createToDo } from "helpers/Apis/todo";
import { dateToDbFormat } from "helpers/dates";
import React, { useContext } from "react";
import { AddTodoContext } from "..";
import { IDS_TYPE_SECTION } from "helpers/Apis/todo";
import { Error, Success } from "helpers/alerts";
import Icon from "components/individual/HeaderIcon";

export default function CreateToDo() {
  const {
    isPerformingQuery,
    isValid,
    setIsPerformingQuery,
    expiration,
    reminder,
    tag,
    note,
    title,
    type,
    id,
    idCloseModal,
    socialReason,
    setFlagOpen,
    uuid,
    idToDo,
    onAdd,
  } = useContext(AddTodoContext);

  const triggerCreateToDo = async () => {
    setIsPerformingQuery(true);

    const wasCreated = await createToDo({
      atentionDate: dateToDbFormat(expiration),
      reminderDate: dateToDbFormat(reminder),
      tag,
      todoNote: note,
      title,
      sectionId: IDS_TYPE_SECTION[type],
      fromId: id,
      customerId: socialReason,
      parent: uuid,
      todoId: idToDo,
    });

    setIsPerformingQuery(false);

    if (wasCreated) {
      Success(() => {}, "To Do creado");
      const btnClose = document.getElementById(idCloseModal);
      setFlagOpen(false);
      onAdd();
      if (btnClose) {
        btnClose.click();
      }
    } else {
      Error(() => {}, "No se pudo crear el To Do");
    }
  };

  return !isValid ? null : isPerformingQuery ? (
    <Spinner hidden={false} text="Agregando" />
  ) : (
    <ButtonV2 onClick={triggerCreateToDo}>
      <span>Agregar</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
