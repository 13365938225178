import React from "react";
import {
  FormUser,
  FormContact as Field,
} from "pages/Directory/Contactos/FormContact";
import { userValidationSchema } from "pages/ProcesosEspeciales/Usuarios/Schema";
import { useContext } from "react";
import { ProfileContext } from "..";
import LoginContext from "context/Login/LoginContext";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import style from "./styles.module.scss";
import DobleFactorAuthentication from "components/individual/2FA";

export default function Edit() {
  const hook = useContext(ProfileContext);
  const { userInfo } = useContext(LoginContext);

  if (!hook.isOpenEdit) return <></>;

  return (
    <Dialog
      forwardRef={hook.dialogEdit.refDialog}
      closeCallback={hook.close}
      title="Perfil"
      width={30}
    >
      <div className={style.containerProfile}>
        <FormUser validation={userValidationSchema}>
          <Field.Name defValue={userInfo[0].firstName} />
          <Field.MiddleName defValue={userInfo[0].middleName} />
          <Field.Father defValue={userInfo[0].lastName1} />
          <Field.Mother defValue={userInfo[0].lastName2} />
          <Field.Email defValue={userInfo[0].email} />
          <Field.Cellphone defValue={userInfo[0].phone.number} />
          <DobleFactorAuthentication />

          <div>
            <Label>Supervisor</Label>
            <p>
              {userInfo[0].chiefId <= 0
                ? "No hay supervisor asignado"
                : userInfo[0].supervisorFullName}
            </p>
          </div>

          <div>
            <Label>Administrador</Label>
            <p>{userInfo[0].isAdmin ? "Si" : "No"}</p>
          </div>
        </FormUser>
      </div>
    </Dialog>
  );
}
