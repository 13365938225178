import React, { createContext, useContext } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import GridImports from "components/general/GridImports";
import { mnCurrency } from "helpers/money";
import Create from "./Create";
import useStep2 from "./useSte2";
import { Invoice } from "./Styles";
import { ContextAssociateCxP } from "../Associate";
import { CustomerInfo } from "components/individual/CustomerInfo";
import useValidateConcilationCxc from "customHooks/useValidateConcilationCxc";

/**
 * @type {import("react").Context<import("./types").Step2>}
 */
export const ContextStep2 = createContext();
const { Provider } = ContextStep2;

/**
 * Grid to associate the CxC of a customer
 * @param {object} props - Props
 * @param {number} props.customerId - Id of the customer associated
 * @returns {JSX.Element} Component
 */
export default function Step2V2({ customerId, bankAccountId, idMovement }) {
  const { applied, residue, state, updateGridInfo, attemptCreate } = useStep2({
    customerId,
    bankAccountId,
    idMovement,
  });

  const canAddConcilation = useValidateConcilationCxc(idMovement, residue);

  const { customer } = useContext(ContextAssociateCxP);

  return (
    <Provider value={{ ...state, attemptCreate }}>
      {state.isLoading ? (
        <Spinner hidden={false} text="Cargando CxC(s)..." />
      ) : (
        <>
          <h4>Informacion del ingreso</h4>
          <p>
            <span>
              <b>Ingreso No: </b>
            </span>
            {state.movement.MovementID}|
            <span>
              <b>Cobrado a: </b>

              <CustomerInfo idCustomer={customer.ID}>
                {customer.Razon_social}
              </CustomerInfo>
            </span>{" "}
            |
            <span>
              <b>Importe total: </b> {mnCurrency(state.movement.amount)}
            </span>
            |
            <span>
              <b>Aplicado:</b> {applied}
            </span>
            |
            <span>
              <b>Saldo: </b>
              {residue}
            </span>
          </p>

          <hr />

          {state.movement.paymentMethod == "99" &&
          state.gridInfo?.newResidue === 0 ? (
            <p className="text-danger font-weight-bold">
              🛑 No puedes asociar el ingreso porque el metodo de pago tiene
              "Por definir",
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${process.env.REACT_APP_FRONTEND}bancos/movimientos/${bankAccountId}/editar/${state.movement.MovementID}`}
              >
                clic, para ir a actualizar el ingreso
              </a>
              (Refresca esta página para reflejar los cambios)
            </p>
          ) : (
            <Create />
          )}

          {/* <Create /> */}

          <GridImports
            headers={[
              "Factura",
              "Parcialidad",
              "Moneda",
              "Total",
              "Pagos previos",
              "Pago a aplicar",
              "TC",
              "Saldo CxC",
            ]}
            currency={state.bank.currencyCode}
            residue={state.movement.saldo}
            onChange={updateGridInfo}
            toleranceProp={0.99}
            amountsInfo={state.cxc}
          >
            {({ index }) => (
              <>
                <Invoice
                  target={"_blank"}
                  to={`../../../../directorio/documentos/123/ver/${state.cxc[index].idOfTheInvoice}`}
                >
                  <p className="d-flex justify-content-center align-items-center m-0">
                    {state.cxc[index].invoice.folio}
                  </p>
                </Invoice>
                <p className="d-flex justify-content-center align-items-center m-0">
                  {state.cxc[index].partialitie}
                </p>
                <GridImports.Currency index={index} />

                <GridImports.Total index={index} />

                <p className="d-flex justify-content-end px-3 align-items-center m-0">
                  {mnCurrency(
                    state.cxc[index].total.number -
                      state.cxc[index].residue.number
                  )}
                </p>
                <GridImports.InputImport index={index} label="" />

                <GridImports.InputTC
                  bankCode={state.bank.currencyCode}
                  label=""
                  index={index}
                />

                <GridImports.Saldo index={index} />
              </>
            )}
          </GridImports>
        </>
      )}
    </Provider>
  );
}
