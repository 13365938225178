import YearSelect from "components/general/Forms/Selects/Year";
import React, { useContext } from "react";
import { ContextCreditNoteAccounting } from "..";
import { lastDayOfMonth } from "date-fns";
import { getFirstDayOfMonth } from "helpers/dates";

export default function YearFilter() {

  const hook = useContext(ContextCreditNoteAccounting);

  /**
   * Year
   * @param {number} year - Year
   */
  const handleYearChange = (year) => {

    const dateToEvaluate = new Date(`${year}-${hook.beginDate.getUTCMonth()+1}-3`);

    const lastDay = lastDayOfMonth(dateToEvaluate);
    const firstDay = getFirstDayOfMonth(dateToEvaluate);

    hook.updateBeginDate(firstDay);
    hook.updateEndDate(lastDay);
  }
 

  return (
    <div>
      <YearSelect
        from={new Date().getUTCFullYear() - 10}
        to={new Date().getUTCFullYear()}
        onChange={handleYearChange}
        value={hook.beginDate.getUTCFullYear()}
      />
    </div>
  );
}
