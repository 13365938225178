
import { useContext, useReducer } from "react";
import reducer from "./reducer";
import {
  doSetStep,
  doSetXmlInfo,
  doSetAmounts,
  doShowGrid,
  doSetConcept,
  doSetProvider,
  doSetItsOnlyExpenses,
  doSetPartialities,
  doSetIsAddingReception,
  doSetResetState,
  doSetPdfFile,
  doSetEmittedDate,
  doSetSocialReason,
  doSetFolio,
  doSetIva,
  doSetCreditDays,
  doSetExpirationDate,
  doSetRfc,
  doSetRfcEnterprise,
  doSetTotal,
  doSetCurrency,
} from "../useAddReceptionInvoice/doActions";
import {
  addInvoiceReceptionEgress,
  addReceptionInvoice,
} from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";

import LoginContext from "context/Login/LoginContext";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";
import { SuccessHtml } from "helpers/alerts";
import { useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const validateRfc = require("validate-rfc");
const MySwal = withReactContent(Swal)

const INITIAL_STATE = {
  step: 0,
  creditDays: null,
  socialReasonExists:false,
  expirationDate: {
    jsDate: null,
    ui: null,
  },
  xmlInfo: {
    isValid: false,
    xmlInfo:{
      emitedDate:{
        db:dateToDbFormat(dateWithCeroHours(new Date())),
        jsDate:new Date()
      }
    }
  },
  gridInfo: null,
  showExpenses: false,
  concept: null,
  providerId: null,
  partialities: 1,
  partialitesInfo: [],
  isValidPartialitie: false,
  isAddingReception: false,
  showPdfView: false,
  xmlWasLoaded: false,
};

export default function useAddReceptionInvoice() {
  /**
   * @type {[import("types/typedef/customHooks/useAddReceptionInvoice").StateAddReceptionInvoiceI,()=>void]}
   */
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const idCloseModal = window.crypto.randomUUID();

  const { userInfo } = useContext(LoginContext);

  /**
   * Set the step screen to render on add reception invoice
   * @param {number} stepNumber - Step to
   * @returns {void}
   */
  const setStep = (stepNumber) => dispatch(doSetStep(stepNumber));

  /**
   * Set credit days
   * @param {number} creditDays - Credit days
   * @param {number} idProvider - Id of the provider selected
   * @returns {void}
   */
  const setProvider = (creditDays, idProvider) =>
    dispatch(doSetProvider({ creditDays, idProvider }));

  /**
   * Set xml info
   * @param {import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI} xmlInfo - XML Info
   * @returns {void}
   */
  const setXmlInfo = (xmlInfo) => dispatch(doSetXmlInfo(xmlInfo));

  /**
   * 
   * @param {Date} expirationDate 
   * @returns {void}
   */
  const setExpirationDate = expirationDate => dispatch(doSetExpirationDate(expirationDate));

  /**
   * Update the pdf file used for the invoice reception
   * @param {import("types/typedef/customHooks/useReceiveFiles").FileInfoSatI} pdf - Pdf file information
   * @returns {void}
   */
  const setPdfFile = (pdf) => dispatch(doSetPdfFile(pdf));

  const uploadEgress = async (idCloseModal) => {
    /**
     * Dto in order to upload the egress
     * @type {import("types/typedef/customHooks/useAddReceptionInvoice").AddEgressDto}
     */
    const dto = {
      xml: state.xmlInfo.xmlInfo,
      concept: state.concept,
      creditDays: state.creditDays,
      expiration: dateToDbFormat(state.expirationDate.jsDate),
      provider: state.providerId,
      executive: {
        id: userInfo[0].userID,
        fullName: userInfo[0].fullName,
      },
    };

    dispatch(doSetIsAddingReception(true));

    const wasUploaded = await addInvoiceReceptionEgress(
      dto,
      state.xmlInfo?.xml?.file || new Blob(),
      state.xmlInfo?.pdf?.file || null
    );

    dispatch(doSetIsAddingReception(false));

    if (wasUploaded) {
      const domCloseBtn = document.getElementById(idCloseModal);

      if (domCloseBtn !== null) domCloseBtn.click();

      SuccessHtml({
        title: "Recepcion de factura registrada",
        html: `
        <ul>
          <li>Recepcion de factura agregada</li>
          <li>Archivos cargados</li>
          <li>Gasto generado</li>
        <ul>
        `,
      });
      dispatch(doSetResetState(INITIAL_STATE));
    }
  };

  /**
   * Set the grid info
   * @param {import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI} gridInfo - Information of the grid
   */
  const setGridInfo = (gridInfo) => dispatch(doSetAmounts(gridInfo));

  /**
   * Display or not the grid
   * @param {boolean} showGrid - Flag to display
   * @returns {void}
   */
  const setShowGrid = (showGrid) => dispatch(doShowGrid(showGrid));

  /**
   * Set the information of the concept
   * @param {object:null} concept - Information of the concept
   * @returns {void}
   */
  const setConcept = (concept) => dispatch(doSetConcept(concept));

  /**
   * Set only expenses flag
   * @param {boolean} itsOnlyExpenses - Indicates its just for expenses
   * @returns {void}
   */
  const setItsOnlyExpenses = (itsOnlyExpenses) =>
    dispatch(doSetItsOnlyExpenses(itsOnlyExpenses));

  /**
   * Update the emited date for the invoice
   * @param {Date} date 
   * @returns {void}
   */
  const setEmittedDate = date => dispatch(doSetEmittedDate(date));

  /**
   * @param {number} creditDays
   */
  const setCreditDays = creditDays => dispatch(doSetCreditDays(creditDays));

  /**
   * 
   * @param {string} folio 
   * @returns {void}
   */
  const setFolio = folio => dispatch(doSetFolio(folio));

  /**
   * 
   * @param {string} socialReason - Social reason selected from the combo
   * @param {number|null|undefined} id - Id of the social reason (in case exists on the system)
   * @param {string|null|undefined} rfc - RFC of the social reason selected
   * @param {number} creditDays - Credit days to use on the customer
   * @returns {void}
   */
  const setSocialReason = (socialReason,id,rfc,creditDays) => dispatch(doSetSocialReason(socialReason,id,rfc,creditDays));

  /**
   * Update the information of the grid partialities
   * @param {number} partialities - Number of partialities to use on the reception invoice
   * @param {object[]} gridPartialities - Information of the grid partialities
   * @param {boolean} isValidPartialitie - Flag that indicates if the grid partialitie it's correct
   * @returns {void}
   */
  const setPartialities = (
    partialities,
    gridPartialities,
    isValidPartialitie
  ) =>
    dispatch(
      doSetPartialities({ partialities, gridPartialities, isValidPartialitie })
    );

  /**
   * 
   * @param {number} iva 
   * @returns {void}
   */
  const setIva = iva => dispatch(doSetIva(iva));

  /**
   * Update the rfc to use for the no fiscal invoice odc
   * @param {string} rfc - RFC typed by the user
   * @param {boolean} isValid - Indicates if the rfc typed by the user is valid
   * @returns {void}
   */
  const setRfc = (rfc,isValid) => dispatch(doSetRfc(rfc,isValid));

  /**
   * Update the currency to use on the no fiscal invoice
   * @param {"USD"|"MXN"} currency - currency
   * @returns {void}
   */
  const setCurrency = currency => dispatch(doSetCurrency(currency));

  /**
   * Set the rfc of the enterprise
   * @param {string} rfc 
   * @returns {void}
   */
  const setRfcEnterprise = rfc => dispatch(doSetRfcEnterprise(rfc));
  
  /**
   * Update the total amount of the invoice no fiscal
   * @param {number} total - Total
   * @returns {void}
   */
  const setTotal = total => dispatch(doSetTotal(total));

  /**
   * Add the invoice recepiton into the system
   * @returns {boolean} True if invoice reception was created
   */
  const AddInvoiceReception = async () => {
    dispatch(doSetIsAddingReception(true));

    /**
     * @type {import("types/typedef/customHooks/useAddReceptionInvoice").AddReceptionInvoiceDTO}
     */
    const dto = {
      executive: {
        fullName: userInfo[0].fullName,
        id: userInfo[0].userID,
      },
      idProvider: state.providerId,
      creditDays: state.creditDays,
      // expirationDate: dateToDbFormat(state.expirationDate.jsDate),
      expirationDate:state.partialitesInfo[state.partialitesInfo.length-1].date.split('T')[0],
      partialitiesInfo: state.partialitesInfo,
      xmlInfo: {
        ...state.xmlInfo.xmlInfo,
        pdf: state.xmlInfo?.pdf?.informative || null,
        xml: state.xmlInfo?.xml?.informative || null,
      },
      concept: state.concept,
      odcs: state.gridInfo?.filteredGrid,
    };

    const domCloseBtn = document.getElementById("closeAddReceptionXml");

    const wasCreated = await addReceptionInvoice(
      dto,
      state.xmlInfo?.xml?.file || new Blob(),
      state.xmlInfo?.pdf?.file || null
    );

    dispatch(doSetIsAddingReception(false));

    if (wasCreated) {
      if (domCloseBtn !== null) {
        domCloseBtn.click();
      }

      SuccessHtml({
        title: "Recepcion de factura registrada",
        html: `
        <ul>
          <li>Recepcion de factura agregada</li>
          <li>Archivos cargados</li>
          <li>CxP generadas</li>
        <ul>
        `,
      });

      dispatch(doSetResetState(INITIAL_STATE));

      return true;
    }

    return false;
  };

  /**
   * Set the state of the full progress upload invoice reception to his initial state
   * @returns {void}
   */
  const resetProgress = () => dispatch(doSetResetState(INITIAL_STATE));

  /**
   * Check if the rfc given on the input of the user is valid
   * @returns {boolean}
   */
  const checkValidRfc = () => {
    if(state.xmlInfo?.xmlInfo?.rfcEmitter === undefined) return false

    const { isValid } =  validateRfc(state.xmlInfo.xmlInfo.rfcEmitter, {
      omitVerificationDigit: true,
    });

    return isValid;
  }

  const checkIsValidRfcNoLegal = () => {


    if(state.xmlInfo?.xmlInfo?.rfcEmitter === undefined) return true

    if(state.xmlInfo.xmlInfo.rfcEmitter.length===0) return true

    const { isValid } =  validateRfc(state.xmlInfo.xmlInfo.rfcEmitter, {
      omitVerificationDigit: true,
    });

    return isValid;
  }

  const idFiscalOpen = useRef(window.crypto.randomUUID());
  const idNoFiscalOpen = useRef(window.crypto.randomUUID());

  /**
   * Open the modal of fiscal loads
   */
  const openFiscalLoad = () => {
    const domModal = document.getElementById(idFiscalOpen.current);
    if(domModal)domModal.click()
  }

  /**
   * Open the modal of non fiscal loads
   */
  const openNoFiscalLoad = () => {
    const domModal = document.getElementById(idNoFiscalOpen.current);
    if(domModal)domModal.click()
  }

  const openModalInvoiceLoads = () => MySwal.fire({
    title:"Selecciona el tipo de carga",
    allowOutsideClick:true,
    allowEscapeKey:true,
    icon:"question",
    showCancelButton:true,
    confirmButtonText:"Factura fiscal",
    cancelButtonText:"Cerrar",
    cancelButtonColor:"#d14529",
    denyButtonColor:"#3085d6",
    confirmButtonColor:"#3085d6",
    showDenyButton:true,
    denyButtonText:"Factura no fiscal",
  }).then(({ isConfirmed , isDenied })=>{
    if(isConfirmed) openFiscalLoad();

    if(isDenied) openNoFiscalLoad();
  })

  const isValidRfc = checkValidRfc();
  const isValidRfcNoLegal = checkIsValidRfcNoLegal();
  const isValidFolio = state.xmlInfo?.xmlInfo?.folio === undefined ? false : state.xmlInfo.xmlInfo.folio.length > 0 ? true : false;
  const isValidTotal = state.xmlInfo?.xmlInfo?.total === undefined ? false : state.xmlInfo.xmlInfo.total.number > 0 ? true : false
  const isValidCurrency = state.xmlInfo?.xmlInfo?.currency === undefined ? false : true;
  const isValidCreditDays = state.creditDays === null ? false : true;
  const isValidExpirationDate = state.expirationDate.jsDate === null ? false : true;

  const canContinueNonFiscalInvoice = !isValidRfcNoLegal || !isValidFolio || !isValidTotal || !isValidCurrency || !isValidCreditDays || !isValidExpirationDate ? false : true;
  return {
    state,
    setStep,
    setProvider,
    setXmlInfo,
    setGridInfo,
    setShowGrid,
    setConcept,
    setPartialities,
    setItsOnlyExpenses,
    AddInvoiceReception,
    showPdfView: state.showPdfView,
    setPdfFile,
    uploadEgress,
    resetProgress,
    isPdfLoaded: !!state.xmlInfo.pdf?.file ? true : false,
    setEmittedDate,
    setSocialReason,
    setFolio,
    setIva,
    setCreditDays,
    setExpirationDate,
    setRfc,
    setRfcEnterprise,
    setTotal,
    setCurrency,
    
    isValidRfc,
    isValidFolio,
    isValidTotal,
    isValidCurrency,
    isValidCreditDays,
    isValidExpirationDate,
    canContinueNonFiscalInvoice,

    idCloseModal,

    idFiscalOpen,
    idNoFiscalOpen,
    openFiscalLoad,
    openNoFiscalLoad,
    openModalInvoiceLoads
  };
}
