import React, { useContext } from "react";
import { ContextCancelInvoice } from "../CancelInvoiceButton";

export default function CancelationWarn() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.state.invoiceToReplace === undefined) return <p></p>;

  return (
    <p className="text-danger m-0 font-weight-bold">
      {hook.state.invoice.receptor.rfc !==
      hook.state.invoiceToReplace.receptor.rfc
        ? `No puedes relacionar con un RFC diferente. RFC "A" (${hook.state.invoice.receptor.rfc}) es diferente a RFC "B" (${hook.state.invoiceToReplace.receptor.rfc})`
        : ""}

      {hook.state.invoice.folio === hook.state.invoiceToReplace.folio
        ? "No puedes utilizar el mismo folio para la relación de cancelación"
        : ""}
    </p>
  );
}
