import { useEffect, useState } from "react";

export default function useToDoStructure() {
  /**
   * @type {import("./types").StateToDoStructure}
   */
  const INITIAL_STATE = {
    diplayImportants: true,
    displayDones: false,
    refetch: false,
    option: "urgent",
  };

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (state.diplayImportants === true && state.displayDones === false) {
      setState((current) => ({
        ...current,
        option: "urgent",
      }));
      return;
    }

    if (state.diplayImportants === false && state.displayDones === true) {
      setState((current) => ({
        ...current,
        option: "closed",
      }));
      return;
    }

    if (state.displayDones === false && state.diplayImportants === false) {
      setState((current) => ({
        ...current,
        option: "open",
      }));
    }

    if(state.diplayImportants === true && state.displayDones === true){
      setState((current) => ({
        ...current,
        option: "urgent",
      }));
      return;
    }
  }, [state.diplayImportants, state.displayDones, state.refetch]);

  /**
   * Update the state in order to know if fetch dones or undones
   * @param {boolean} displayDones - Flag in order to know if display dones or undones
   * @returns {void}
   */
  const handleDone = (displayDones) =>
    setState((current) => ({
      ...current,
      diplayImportants: false,
      displayDones,
      refetch: !current.refetch,
    }));

  /**
   * Update in order to display just importants
   * @returns {void}
   */
  const displayImportants = () =>
    setState((current) => ({
      ...current,
      diplayImportants: true,
      refetch: !current.refetch,
    }));

  /**
   * Function that toggles a boolean value in order to indicate that can re-execute a useEffect in order to fetch
   * to do list with the appended value
   * @returns {void}
   */
  const triggerFlagToRefetch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  return {
    handleDone,
    displayImportants,
    triggerFlagToRefetch,
    state,
    setState,
  };
}
