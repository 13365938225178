import { URL_BASE } from "routes/routes";
import axios from "axios";
import { Error, Success } from "helpers/alerts";
import { isValidHttpResCode } from "helpers/Apis/fetch";
import { GetConsolation } from "helpers/Apis/Banks";
import { getMovementAssociationTolerance } from "helpers/Apis/parameters";
import { truncateDecimals } from "helpers/money";
import { getErrorData } from "helpers/errors";

/**
 * Validate the xml file against praxia business rules
 * @param {string} rfcReceptor - RFC Receptor from the xml
 * @param {string} rfcEmitter - RFC Emitter from the xml
 * @param {string} uuid - UUID from the xml
 * @returns {Promise<import("types/typedef/customHooks/useAddXml").LegalDocumentPraxiaValidationI>} Validation information
 */
export const validateXmlPraxia = async (rfcReceptor, rfcEmitter, uuid) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/recepcion-factura/validar?rfcReceptor=${rfcReceptor}&rfcEmisor=${rfcEmitter}&uuid=${uuid}`
    );

    return data;
  } catch (e) {
    Error(() => {}, "No se pudo validar el XML. Intenta mas tarde");
  }
};

/**
 * Obtain the invoice reception
 * @param {number} page - Page to request
 * @param {string} querySearch - Seach input info
 * @param {number|"-1"} status - Status to filter on the search
 * @returns {Promise<import("types/typedef/invoiceReception").InvoiceReceptionDataI>} Data fetched
 */
export const fetchInvoiceReceptionTable = async (
  page,
  querySearch,
  status = "-1"
) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/recepcion-factura?pagina=${page}&busqueda=${querySearch}&estatus=${status}`,
      {
        withCredentials: true,
      }
    );
    return {
      page: data.actualPage,
      totalPages: data.pages,
      data: data.data,
    };
  } catch (error) {
    console.log(error);

    return {
      page: 0,
      totalPages: 0,
      data: [],
    };
  }
};

/**
 * Get the information to fetch the invoices reception
 * @param {import("./types").InvoiceReceptionParams} params - Params in order to fetch a page
 * @returns {Promise<import("../../../../../../types/invoiceReception").DtoInvoiceReception>}
 */
export const fetchInvoiceReception = async ({
  page = 1,
  status = "-1",
  querySearch = "-1",
  accounting = null,
  year = null,
  month = null,
}) => {
  const ERROR_OBJECT = {
    actualPage: 1,
    data: [],
    noRegisters: 0,
    pages: 1,
  };

  const queryAccounting =
    accounting === null ? "" : `&accounting=${accounting}`;

  const dateQuery =
    typeof year === "number" && typeof month === "number"
      ? `&year=${year}&month=${month}`
      : "";

  try {
    const response = await fetch(
      `${URL_BASE}administracion/recepcion-factura?pagina=${page}&busqueda=${querySearch}&estatus=${status}${queryAccounting}${dateQuery}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return result;
    }

    return ERROR_OBJECT;
  } catch (error) {
    return ERROR_OBJECT;
  }
};

/**
 * Add a invoice reception that was made for an egress
 * @param {import("types/typedef/customHooks/useAddReceptionInvoice").AddEgressDto} dto - Information to send requested by backend
 * @param {File|Blob} xml - XML file
 * @param {File|null} pdf - PDF file
 */
export const addInvoiceReceptionEgress = async (
  dto,
  xml = new Blob(),
  pdf = null
) => {
  try {
    let formData = new FormData();

    formData.append("data", JSON.stringify(dto));

    if (xml.size > 0) {
      formData.append("xml", xml, `FR-${dto?.xml?.folio || "No fiscal"}.xml`);
    }

    if (pdf !== null) {
      formData.append("pdf", pdf, `FR-${dto.xml.folio}.pdf`);
    }

    const response = await fetch(
      `${URL_BASE}administracion/recepcion-factura/gasto`,
      {
        credentials: "include",
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    console.log(error);

    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
};

/**
 * Validate xml is valid against the sat
 * @param {string} rfcEmitter - RFC Emitter
 * @param {string} rfcReceptor  -RFC Receptor
 * @param {number} total - Total with cents
 * @param {string} uuid - uuid xml
 * @returns {import("types/typedef/invoiceReception").ValidationSatI} Validation info
 */
export const validateXmlAgainstSat = async (
  rfcEmitter,
  rfcReceptor,
  total,
  uuid
) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}facturacion/validar-xml/${rfcEmitter}&&${rfcReceptor}&&${total}&&${uuid}`
    );

    return {
      isValid:
        data["s:Envelope"]["s:Body"]["ConsultaResponse"]["ConsultaResult"][
          "a:Estado"
        ]["_text"] === "Vigente"
          ? true
          : false,
      status:
        data["s:Envelope"]["s:Body"]["ConsultaResponse"]["ConsultaResult"][
          "a:Estado"
        ]["_text"],
    };
  } catch (e) {
    Error(() => {}, "No se pudo validar el XML. Intenta mas tarde");

    return false;
  }
};

/**
 * Get all the concepts/expenses of the enterprise
 * @returns {Promise<import("types/typedef/customHooks/useConcepts").ConceptI[]>}
 */
export const getAllExpenses = async () => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/recepcion-factura/conceptos`
    );

    return data;
  } catch (error) {
    return [];
  }
};

/**
 * Create the reception invoice
 * @param {import("types/typedef/customHooks/useAddReceptionInvoice").AddReceptionInvoiceDTO} dto - Information in order to create the invoice reception
 * @param {File|Blob} xml - File instace of the xml
 * @param {File|null} pdf - File instnace of the pdf
 * @returns {Promise<boolean>} True if was created successfully
 */
export const addReceptionInvoice = async (
  dto,
  xml = new Blob(),
  pdf = null
) => {
  try {
    let information = new FormData();

    information.append("receptionInvoice", JSON.stringify(dto));

    if (xml.size > 0) {
      information.append("xmlFile", xml);
    }

    if (pdf !== null) information.append("pdfFile", pdf);

    const response = await fetch(
      `${URL_BASE}administracion/recepcion-factura`,
      {
        credentials: "include",
        method: "POST",
        body: information,
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      return true;
    }

    return false;
    // const { data } = await axios.post(
    //   `${URL_BASE}administracion/recepcion-factura`,
    //   information,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );
  } catch (error) {
    Error(() => {}, "Error");
    return false;
  }
};

/**
 * Get the status to filter on the combo
 * @returns {Promise<import("types/typedef/customHooks/useInvoiceReception").InvoiceReceptionStatusI[]>} Status to render on the combo
 */
export const getStatusInvoiceReception = async () => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/recepcion-factura/estatus`
    );

    return data;
  } catch (error) {
    const { response } = error;

    console.log(error);

    // Error(() => {}, response.data.message);

    return [];
  }
};
export const getReceptionInvoicePopUp = async (uuid, receptionId) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/recepcion-factura/popup?receptionId=${receptionId}&uuid=${uuid}`
    );

    return data;
  } catch (error) {
    const { response } = error;

    Error(() => {}, response.data.message);

    return [];
  }
};

/**
 * Get the CxPs that must pay the provider
 * @param {number|null} idProvider - Id of the provider
 * @param {boolean?} includeExpenses - Flag
 * @returns {Promise<import("types/typedef/cxp").CxpI[]>}
 */
export const getCxps = async (idProvider, includeExpenses = false) => {
  const idProviderToUse = idProvider === null ? 0 : idProvider;

  try {
    /**
     * @type {import("axios").AxiosResponse<import("types/typedef/cxp").CxpInvoice[]>}
     */
    const res = await axios.get(
      `${URL_BASE}administracion/cxps/${idProviderToUse}?includeExpenses=${includeExpenses}`,
      {
        withCredentials: true,
      }
    );

    const parsedData = res.data.map((cxp) => ({
      ...cxp,
      applied: {
        number: 0,
        text: "$0.0",
      },
      import: {
        number: 0,
        text: "$0.0",
      },
      isValidImport: true,
      isValidResidueOdc: true,
      newResidue: {
        number: cxp.residue.number,
        text: cxp.residue.text,
      },
      tc: {
        number: 1,
        text: "$1.0",
      },
    }));

    return parsedData;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return [];
  }
};

/**
 * Get the cxcs pending to pay of a customer
 * @param {number} idCustomer - Id of the customer
 * @returns {Promise<object[]>}
 */
export const getCxcs = async (idCustomer) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}administracion/cxcs/${idCustomer}`,
      {
        withCredentials: true,
      }
    );

    const parsedData = data.map((cxp) => ({
      ...cxp,
      applied: {
        number: 0,
        text: "$0.0",
      },
      import: {
        number: 0,
        text: "$0.0",
      },
      isValidImport: true,
      isValidResidueOdc: true,
      newResidue: {
        number: cxp.residue.number,
        text: cxp.residue.text,
      },
      tc: {
        number: 1,
        text: "$1.0",
      },
    }));

    return parsedData;
  } catch (error) {
    return [];
  }
};

/**
 * Cancel the invoice reception
 * @param {number} id - Id of the legal document to cancel
 * @param {string} executiveName - Name of the executive who performed the cancelation
 * @returns {Promise<boolean>} True if the legal document was cancelled
 * @example
 * (async function(){
 *  // some more code...
 *  const wasCancelled = await cancelInvoiceReception(45);
 *  // some more code...
 * })()
 */
export const cancelInvoiceReception = async (id, executiveName) => {
  try {
    const response = await fetch(
      `${URL_BASE}administracion/recepcion-factura/cancelar/${id}`,
      {
        body: JSON.stringify({ executiveName }),
        credentials: "include",
        method: "PUT",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      return true;
    }

    return false;

    // await axios.put(
    //   `${URL_BASE}administracion/recepcion-factura/cancelar/${id}`,
    //   {
    //     executiveName,
    //   },
    //   {
    //     withCredentials: true,
    //   }
    // );

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
};

/**
 *
 * @param {number} idCustomer - Id of the customer
 * @param {"USD"|"MXN"} predominantCurrency - Currency that is the bank account
 * @returns {Promise<import("./types").CxcV2[]>} List of cxc pending to pay
 */
export async function getCxcV2(idCustomer, predominantCurrency) {
  try {
    if (idCustomer === null) return [];

    const idCurrency = predominantCurrency === "MXN" ? 1 : 2;

    const [cxcs, associations, tolerance] = await Promise.all([
      getCxcs(idCustomer),
      GetConsolation(idCustomer, idCurrency, predominantCurrency),
      getMovementAssociationTolerance(),
    ]);

    console.log({cxcs});

    const cxcParsed = cxcs.map((cxc, i) => {
      return {
        ...cxc,
        invoice: {
          ...cxc.invoice,
          ...associations[i].invoice,
          id: cxc.idOfTheInvoice,
          folio: cxc.invoice.folio,
        },
      };
    });


    const residuesByInvoice = cxcParsed.reduce(
      (indexed, cxc) => ({
        ...indexed,
        [cxc.invoice.id]:
          indexed[cxc.invoice.id] === undefined
            ? truncateDecimals(cxc.residue.number, 2)
            : truncateDecimals(indexed[cxc.invoice.id] + cxc.residue.number, 2),
      }),
      {}
    );

    return cxcParsed.filter(
      (cxc) => residuesByInvoice[cxc.invoice.id] > tolerance
    );

    return cxcParsed;
  } catch (error) {
    return [];
  }
}

/**
 * Get the excel in order to make the client fill the information
 * @param {string|string[]} uuids - Uuid of the invoices to validate
 * @returns {Promise<import("./types").DtoValidateCreditNote>}
 */
export async function validateCreditNoteReception(uuids = []) {
  const arrayToUse = typeof uuids === "string" ? [uuids] : uuids;

  try {
    const res = await fetch(
      `${URL_BASE}administracion/recepcion-nota-de-credito`,
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ uuids: arrayToUse }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return {
        canAdd: true,
        message: "",
      };
    }

    Error(() => {}, data.message);

    return {
      canAdd: false,
      message: data.message,
    };
  } catch (error) {
    return {
      canAdd: false,
      message:
        "Refresca la página y vuelve a intentar recepcionar la nota de crédito",
    };
  }
}

/**
 * Create a credit note
 * @param {number} amount - Importe
 * @param {string} concept - Concept
 * @param {string} uuid - UUID
 * @returns {Promise<boolean>} True if credit note was added
 */
export async function createCreditNote(amount, concept, uuid) {
  try {
    const res = await fetch(`${URL_BASE}nce/agregar`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        dto: {
          importe: amount,
          concept,
          invoiceUuid: uuid,
        },
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Create a credit note
 * @param {import("../../../../../../server/types/administration").AddReceptionInvoiceModelI} creditNote - Information to create the credit note
 * @param {Blob|File} xml - Xml file for the credit note reception
 * @param {Blob|File|null?} pdf - PDF of the credit note reception
 * @returns {Promise<boolean>} True if credit note was added
 */
export async function receiveCreditNote(creditNote, xml, pdf = null) {
  try {
    let formData = new FormData();

    formData.append("creditNote", JSON.stringify(creditNote));
    formData.append("xml", xml, `NCR-${creditNote.noDocument}.xml`);

    if (pdf !== null)
      formData.append("pdf", pdf, `NCR-${creditNote.noDocument}.pdf`);

    const response = await fetch(`${URL_BASE}ncr/agregar`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }
    Error(() => {}, data.message);

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the credit notes received
 * @param {import("./types").ParamGetCreditNotesReceived} dto - Information in order to request the information
 * @returns {Promise<import("../../../../../../types/NC/ncr").ResponseGetCreditNotesReceived>}
 */
export async function getCreditNotesReceived(dto) {
  /**
   * @type {import("../../../../../../types/NC/ncr").ResponseGetCreditNotesReceived}
   */
  const ERROR_DUMMY = {
    message: "No se pudieron obtener la notas crédito recibidas",
    paginatedNcr: {
      ncr: [],
      pages: {
        actualPage: 1,
        noRegisters: 0,
        pages: 0,
      },
    },
  };

  const queryParamCustomer =
    typeof dto.customerId === "number" ? `&customerId=${dto.customerId}` : ``;
  const querySearch = dto.search === "" ? "" : `&search=${dto.search}`;
  const queryStatus =
    typeof dto.statusId === "number" ? `&statusId=${dto.statusId}` : ``;

  const queryContabilidad =
    typeof dto.contabilidad !== "boolean"
      ? ``
      : `&contabilidad=${dto.contabilidad}`;

  try {
    const res = await fetch(
      `${URL_BASE}ncr/tabla?beginDate=${dto.beginDate}&endDate=${dto.endDate}&pageRequested=${dto.pageRequested}${queryParamCustomer}${querySearch}${queryStatus}${queryContabilidad}`,
      {
        credentials: "include",
        method: "GET",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    /**
     * @type {import("../../../../../../types/NC/ncr").ResponseGetCreditNotesReceived}
     */
    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);

    return ERROR_DUMMY;
  } catch (error) {
    return ERROR_DUMMY;
  }
}

/**
 * Cancel a credit note received into praxia
 * @param {number} idCreditNote - Id of the credit note received to cancel
 * @returns {Promise<boolean>}
 */
export async function cancelCreditNoteReceived(idCreditNote) {
  try {
    const res = await fetch(`${URL_BASE}ncr?idNcr=${idCreditNote}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);

      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Download the excel report for the invoice reception
 * @param {import("./types").InvoiceReceptionParams} query - Information in order to fetch the report
 * @returns {Promise<Blob|undefined>}
 */
export async function downloadInvoiceReceptionXlsx(query) {
  try {
    const queryParam = generateQueryParamUrl();

    const res = await fetch(
      `${URL_BASE}administracion/recepcion-factura/reporte${queryParam}&pagina=${query.page}&estatus=${query.status}&isForAccounting=${query.isForAccounting}&year=${query.year}&month=${query.month}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const excel = await res.blob();
      return excel;
    }

    const resData = await getErrorData(res);

    Error(() => {}, resData.message);

    return undefined;
  } catch (error) {
    return undefined;
  }

  function generateQueryParamUrl() {
    const { status: estatus, querySearch: busqueda } = query;

    const queryParamSearch =
      busqueda === "" ||
      busqueda === null ||
      busqueda === "-1" ||
      busqueda === -1
        ? ""
        : `busqueda=${busqueda}`;
    const queryParamStatus = estatus === null ? "" : `estatus=${estatus}`;

    if (queryParamSearch === "" && queryParamStatus === "") return "";

    let url = "?";

    if (queryParamSearch !== "") url += `${queryParamSearch}`;

    if (queryParamStatus !== "")
      url += `${url !== "?" ? "&" : ""}${queryParamStatus}`;

    return url;
  }
}

/**
 * Get the credit notes excel
 * @param {import("./types").ExcelParamGetNc} param - Params
 * @returns {Promise<Blob|void>}
 */
export async function excelNc(param) {
  try {
    const status = param.type === "recibidasContabilidad" ? `&statusId=13` : "";

    const res = await fetch(
      `${URL_BASE}ncr/tabla/excel?beginDate=${param.beginDate}&endDate=${param.endDate}&tipo=${param.type}${status}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const file = await res.blob();
      return file;
    }

    const error = await getErrorData(res);

    Error(() => {}, error.message);
  } catch (error) {}
}
