import SocialReason from "components/general/Forms/Selects/SocialReason";
import React, { useContext } from "react";
import { ContextGestionOc } from "..";

export default function Customer() {
  const hook = useContext(ContextGestionOc);

  return (
    <div>
      <b>Cliente</b>
      <SocialReason
        type="cliente"
        isClearable={true}
        onChange={(customer) => hook.setCustomerProvider("customer", customer)}
      />
    </div>
  );
}
