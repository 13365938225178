import React from "react";
import ButtonV2 from "../ButtonsV2/Button";
import { useHistory } from "react-router-dom";

/**
 * Button to redirect to another page
 * @param {import("./types").PropsRedirectBtn} props - Props
 */
export default function RedirectBtn(props) {
  const history = useHistory();

  let btnProps = { ...props };
  delete btnProps.link;

  return (
    <a href={props.link} onClick={(e)=>{
      e.preventDefault();
      history.push(props.link)
    }}>
      <ButtonV2 type="button" {...btnProps} />
    </a>
  );
}
