import { Spinner } from "components/individual/Spinner/Spinner";
import useConcilationCxC from "customHooks/useConcilationCxc";
import { mnCurrency } from "helpers/money";
import React from "react";
import scss from "./styles.module.scss";

export const ConcilationCxC = ({
  idMovement = 177,
  idBankAccount = null,
  currencyBankAccount = null,
}) => {
  const { isLoading, concilations, bank, concepts } = useConcilationCxC(
    idMovement,
    () => {},
    idBankAccount
  );

  if (isLoading)
    return <Spinner hidden={false} text="Cargando las conciliaciones..." />;

  return (
    <div>
      <div className={scss.header}>
        <span>Cliente</span>
        <span>Concepto</span>
        <span>Factura</span>
        <span>Parcialidad</span>
        <span>Moneda</span>
        <span>Total</span>
        <span className={scss.headerAmount}>
          <span>Asociado</span>
        </span>
        <span className={scss.headerAmount}>
          <span>Importe origen</span>
        </span>
      </div>

      {concilations.map((cxc, i) => (
        <div className={scss.row} key={`association-${i}`}>
          <span className="text-center">{cxc.invoice.socialReason}</span>
          <span>{cxc.invoice.concept.description}</span>
          <span>
            <a
              target={`_blank`}
              href={`${process.env.REACT_APP_FRONTEND}directorio/documentos/0/ver/${cxc.invoice.id}?modulo=movimientos&cuentaBanco=${idBankAccount}`}
            >
              {cxc.invoice.folio}
            </a>
          </span>
          <span>{cxc.cxc.partialitie.ui}</span>
          <span>{cxc.invoice.currency}</span>
          <span>
            {cxc.invoice.total.text} {cxc.invoice.currency}
          </span>
          <span className="d-flex justify-content-end">{`${cxc.applied.text} ${currencyBankAccount}`}</span>
          <span className="d-flex justify-content-end">{`${cxc.paid.text} ${cxc.invoice.currency}`}</span>
        </div>
      ))}

      {concepts
        .filter(
          (concept) =>
            concept.concept.id !== 7 &&
            concept.concept.id !== 8 &&
            concept.isActive
        )
        .map((concept) => (
          <div className={scss.row}>
            <span className="text-center">
              {concept.customer.socialReason || "NA"}
            </span>
            <span className="text-center">{concept.concept.description}</span>
            <span>NA</span>
            <span>NA</span>
            <span>{currencyBankAccount}</span>
            <span className="text-right">
              {mnCurrency(concept.applied)} {currencyBankAccount}
            </span>
            <span className="text-right">
              {mnCurrency(concept.applied)} {currencyBankAccount}
            </span>
            <span className="text-right">
              {mnCurrency(concept.applied)} {currencyBankAccount}
            </span>
          </div>
        ))}
    </div>
  );
};
