import {
  AddCommentContainer,
  Comment,
  CommentItem,
} from "components/Sections/Documents/Comments/Containers";
import { AddPlus} from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import { InputText } from "components/individual/inputs/Inputs";

import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { CommentButtons } from "./CommentButtons";

export const OrderCommentSection = () => {
  const {
    documents,
    handleOnCurrentOrderCommentChange,
    handleOnAddOrderComments,
    defaultOrderCommentValue,
    validateCommentEditing,
    handleOnEditComment,
  } = useContext(WinQuoteContext);
  return (
    <>
      <AddCommentContainer>
        <div className="d-flex w-100 justify-content-center align-items-center">
          <InputText
            placeholderTxt={
              documents.order.isCommentEditing
                ? "Deshabilitado"
                : "Escribe aqui"
            }
            labelTxt="Comentario"
            aditionalClasses="w-100"
            onChange={handleOnCurrentOrderCommentChange}
            defaultValue={defaultOrderCommentValue()}
            onEnter={() => {
              handleOnAddOrderComments();
            }}
          />
          {/* <Icon
            onKeyUp={(value) => {
              console.log(value);
            }}
          >
            {AddPlus}
          </Icon> */}
        </div>
        {!!documents.order.comments.length ? (
          documents.order.comments.map((comment, index) => (
            <CommentItem>
              <Comment>
                {validateCommentEditing(
                  comment,
                  documents.order.currentComment
                ) ? (
                  <InputText
                    placeholderTxt={
                      documents.order.isCommentEditing
                        ? "Deshabilitado"
                        : "Escribe aqui"
                    }
                    labelTxt="Comentario"
                    aditionalClasses="commnetStyle"
                    onChange={handleOnCurrentOrderCommentChange}
                    defaultValue={comment.comment}
                    onEnter={() => {
                      handleOnEditComment(
                        index,
                        2,
                        documents.order.currentComment
                      );
                    }}
                  />
                ) : (
                  <p>{comment.comment}</p>
                )}
              </Comment>
              <CommentButtons
                comment={comment}
                idDocumentType={2}
                index={index}
                currentComment={documents.order.currentComment}
              />
            </CommentItem>
          ))
        ) : (
          <h2 className="text-center m-4">No hay comentarios para mostrar</h2>
        )}
      </AddCommentContainer>
    </>
  );
};
