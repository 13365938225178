import React from "react";
import { useContext } from "react";
import { ContextOldResidues } from "../..";
import { mnCurrency } from "helpers/money";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import style from "../../styles.module.scss";

/**
 * Render the row for the details of an invoice
 * @param {import("./types").PropsDetailRow} props - Props
 * @returns {JSX.Element}
 */
export default function DetailRow({
  expiration,
  noDocument,
  partialitie,
  residue,
  type,
  total = 0
}) {
  const hook = useContext(ContextOldResidues);

  if (!hook.showDetails) return;

  return (
    <>
      <p
        style={{
          background: "var(--primaryBackground)",
        }}
        className="text-left"
      >
        Factura {noDocument} {partialitie}
      </p>
      <p
        style={{
          background: "var(--primaryBackground)",
        }}
        className="text-center"
      >
        {parseDateToText(getDateFromUtc(expiration))}
      </p>

      <div
style={{
  background: "var(--primaryBackground)",
}}
        className={style.rowResiduesInvoice}
      >
        <p className="text-right">
          {type === "inTime" ? mnCurrency(residue) : "-"}{" "}
        </p>
        <p className="text-right">
          {type === "1to7days" ? mnCurrency(residue) : "-"}{" "}
        </p>
        <p className="text-right">
          {type === "8to14days" ? mnCurrency(residue) : "-"}{" "}
        </p>
        <p className="text-right">
          {type === "more14days" ? mnCurrency(residue) : "-"}{" "}
        </p>
        <p className="text-right">{mnCurrency(total)}</p>
      </div>
    </>
  );
}
