import { dateToDbFormat } from "helpers/dates";
import React from "react";
import ReactDatePicker from "react-datepicker";

export const DateRange = ({
  id = "dateRangeId",
  name = "dateRange",
  labelText = "Rango de fechas",
  readOnly = false,
  initialDate = new Date(),
  forwardRef,
  startDate,
  endDate,
  onChange = () => {},

}) => {
  return (
    <>
      {labelText === null ? null : (
        <label className="labelInput" htmlFor={`nameFake_${name}`}>
          {labelText}
        </label>
      )}

      <ReactDatePicker
        readOnly={readOnly}
        id={`idFake_${id}`}
        name={`nameFake_${name}`}
        selected={initialDate}
        dateFormat="dd/MMM/yyyy"
        className={`${readOnly === true ? "inputBlock" : ""}`}
        locale="es"
        onChange={(dates) => {
          onChange(dates);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
            showMonthDropdown
            showYearDropdown
            // dropdownMode="select"
      />

      <input
        type="hidden"
        ref={forwardRef}
        name={name}
        id={id}
        onChange={(e) => {
          onChange(e);
        }}
        // value={dateToDbFormat(new Date(date))}
        value={dateToDbFormat(new Date(initialDate))}
      />
    </>
  );
};
