import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";

/**
 * Send a new product/service to server in order to be created
 * @param {import("pages/ProcesosEspeciales/CatalogueAdmin/useCatalogueAdmin/types").SchemaAddProduct} product - Information of the product to create into catalogue
 * @returns {Promise<boolean>}
 */
export async function createProduct(product) {
  try {
    const res = await fetch(`${URL_BASE}catalogo`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ productData: product }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Send a new product/service to server in order to be created
 * @param {number} page - Page to fetch
 * @param {string|null} currency - Currency to get
 * @param {string} description - Description of the product to search
 * @param {string} sku - Sku of the product
 * @returns {Promise<import("../../../../../server/types/Catalogue/catalogue").GetProductTableI>}
 */
export async function getCatalogue(page, currency,description,sku) {
  /**
   * @type {import("../../../../../server/types/Catalogue/catalogue").GetProductTableI}
   */
  const dummyData = {
    pages: {
      actualPage: 1,
      noRegisters: 0,
      pages: 0,
    },
    products: [],
  };

  try {
    const queryParamPage = `?pageRequested=${page}`;
    const queryParamCurrency = currency === null ? "" : `&currency=${currency}`;
    const queryParamDescription = description === '' ? '' : `&description=${description}`;
    const queryParamSku = sku === "" ? '' : `&sku=${sku}`;

    const res = await fetch(
      `${URL_BASE}catalogo/tabla${queryParamPage}${queryParamCurrency}${queryParamDescription}${queryParamSku}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {{result:import("../../../../../server/types/Catalogue/catalogue").GetProductTableI}}
       */
      const data = await res.json();


      return data.result;
    }

    return dummyData;
  } catch (error) {
    return dummyData;
  }
}

/**
 * Send a new product/service to server in order to be created
 * @param {import("pages/ProcesosEspeciales/CatalogueAdmin/useCatalogueAdmin/types").SchemaUpdateProduct} product - Information of the product to create into catalogue
 * @returns {Promise<boolean>}
 */
export async function updateProduct(product) {
  try {
    const res = await fetch(`${URL_BASE}catalogo`, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify({ productData: product }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}