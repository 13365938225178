import AutocompleteEmail from "components/individual/AutocompleteEmail";
import { Search } from "components/individual/Search";
import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import { Files, DropZone, FileList, Upload } from "structure/Files";
import CommentBox from "components/individual/CommentBox";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import useEmailParameters from "pages/ProcesosEspeciales/Parameters/EmailParameters/useEmailParameters";
import { CloudFileBtn } from "structure/Email/CloudFiles";
import { sendEmailWithFiles } from "helpers/Apis/email";
import { Success } from "helpers/alerts";

/**
 * @type {import('./types').EmailState}
 */
const INITIAL_STATE = {
  allowedExtensions: [],
  body: "",
  cc: [],
  to: [],
  files: [],
  subject: "",
  isSendingEmail: false,
  isLoadingComponent: true,
};

/**
 * @type {import("react").Context<import("./types").ContextEmail|undefined>}
 */
const ContextEmail = createContext(undefined);

/**
 * Render an email component
 * @param {import("./types").EmailV2Props} props - Props
 * @returns {JSX.Element}
 */
export default function EmailV2({
  children,
  idModule = null,
  module = null,
  idDirectory = null,
  files = [],
  onSendedEmail = () => {},
  onLoaded = () => {},
}) {
  const [state, setState] = useState(INITIAL_STATE);

  const { userInfo } = useContext(LoginContext);

  const params = useEmailParameters({ module, id: idModule });

  const attemptSendEmail = async () => {
    setState((current) => ({
      ...current,
      isSendingEmail: true,
    }));

    const wasSent = await sendEmailWithFiles({
      cc: state.cc,
      idDocument: idModule,
      message: state.body,
      subjet: state.subject,
      to: state.to,
      files: state.files,
      sendedBy: userInfo[0].fullName,
    });

    if (wasSent) {
      onSendedEmail();
      Success(() => {}, "Correo enviado");
    }

    setState((current) => ({
      ...current,
      isSendingEmail: false,
    }));
  };

  const setCc = (cc) =>
    setState((current) => ({
      ...current,
      cc,
    }));

  const setTo = (to) =>
    setState((current) => ({
      ...current,
      to,
    }));
  /**
   *
   * @param {File[]} files
   */
  const setFiles = (files) => {
    setState((current) => ({
      ...current,
      files,
    }));
  };

  const appendFile = (files) =>
    setState((current) => ({
      ...current,
      files: [...files, ...current.files],
    }));

  const setSubject = (subject) =>
    setState((current) => ({
      ...current,
      subject,
    }));

  const setBody = (body) =>
    setState((current) => ({
      ...current,
      body,
    }));

  useEffect(() => {
    (async function () {
      if (!params.loaded) return;

      const fetchedFiles = Array.isArray(files) ? files : await files();

      console.log({params});
      
      setState((current) => ({
        ...current,
        body: params.body,
        cc: params.cc,
        subject: params.subject,
        isLoadingComponent: false,
        files: fetchedFiles,
        to: params.to,
      }));

      onLoaded();
    })();
  }, [params.loaded]);

  if (state.isLoadingComponent)
    return (
      <div className={ui.loadingEmailComponent}>
        <Spinner text={"Cargando"} hidden={false} />;
      </div>
    );

  return (
    <ContextEmail.Provider
      value={{
        ...state,
        idDirectory,
        idModule,
        setCc,
        setTo,
        setSubject,
        setBody,
        setFiles,
        attemptSendEmail,
        appendFile,
      }}
    >
      {children}
    </ContextEmail.Provider>
  );
}

export function Subject() {
  const hook = useContext(ContextEmail);

  return (
    <div>
      <Label required={hook.subject.length <= 0}>Asunto</Label>
      <Search
        value={hook.subject}
        hideSearchIcon={true}
        placeholder="Escribe aquí"
        onChange={(value) => hook.setSubject(value)}
        onDelete={(value) => hook.setSubject("")}
      />
    </div>
  );
}

export function SendEmail() {
  const hook = useContext(ContextEmail);

  if (hook.isSendingEmail) return <Spinner text={"Enviando"} hidden={false} />;

  return (
    <ButtonV2 type="button" onClick={hook.attemptSendEmail}>
      <span>Enviar correo</span>
      <Icon icon="send" />
    </ButtonV2>
  );
}

export function Cc() {
  const hook = useContext(ContextEmail);

  const { userInfo } = useContext(LoginContext);

  if(hook.isLoadingComponent) return <></>

  return (
    <AutocompleteEmail
      idCustomer={hook.idDirectory}
      label={"CC"}
      aditionalEmails={[userInfo[0].email, ...hook.cc]}
      onChange={hook.setCc}
    />
  );
}

export function To() {
  const hook = useContext(ContextEmail);

  return (
    <AutocompleteEmail
      idCustomer={hook.idDirectory}
      label={"TO"}
      aditionalEmails={[...hook.to]}
      onChange={hook.setTo}
    />
  );
}

export function Send() {
  const hook = useContext(ContextEmail);

  if (hook.isLoadingComponent) return <></>;

  return (
    <div className={ui.emailFooterModal}>
      <ButtonV2>
        <span>Enviar correo</span>
        <Icon icon="send" />
      </ButtonV2>
    </div>
  );
}

export function FilesEmail() {
  const hook = useContext(ContextEmail);

  return (
    <Files
      onFilesSelected={hook.setFiles}
      allowedExtensions={["png", "jpg", "jpeg", "webp", "pdf", "xml"]}
      files={hook.files}
    >
      <DropZone />
      <FileList />
    </Files>
  );
}

export function Cloud() {
  const hook = useContext(ContextEmail);

  return (
    <CloudFileBtn
      idRegister={hook.idModule}
      idTypeEntity={5}
      onChange={(files) => {
        hook.appendFile(files.array);
      }}
    />
  );
}

export function From() {
  return (
    <div>
      <b>FROM</b>
      <p>{process.env.REACT_APP_EMAIL_FROM}</p>
    </div>
  );
}

export function Body() {
  const hook = useContext(ContextEmail);

  return <CommentBox value={hook.body} onChange={hook.setBody} />;
}

/**
 * Render a modal for the emails
 * @param {import("./types").PropsModalEmail} props - Props
 * @returns {JSX.Element}
 */
export function ModalEmail(props) {
  const [state, setState] = useState({
    isOpen: false,
  });

  const dialog = useDialog();

  const handleModal = (isOpen) => {
    if (isOpen) {
      setState((current) => ({
        ...current,
        isOpen: true,
      }));
    } else {
      setState((current) => ({
        ...current,
        isOpen: false,
      }));
    }
  };

  useEffect(() => {
    if (state.isOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  }, [state.isOpen]);

  const indexedTitles = useRef({
    pedido: "Enviar pedido",
    oc: "Enviaro orden de compra",
    notaDeCredito: "Enviar nota de crédito",
    facturaEmitida: "Enviar factura emitida",
    cotizacion: "Enviar cotización",
    contrato: "Enviar contrato",
    complemento: "Enviar complemento",
  });

  return (
    <Fragment>
      <div ref={props?.forwardRef} onClick={(e) => handleModal(true)}>
        {props?.children}
      </div>

      {state.isOpen ? (
        <Dialog
          title={indexedTitles.current[props?.module] || "Enviar correo"}
          forwardRef={dialog.refDialog}
          closeCallback={() => handleModal(false)}
          width={55}
        >
          <EmailV2
            idDirectory={props?.idDirectory}
            idModule={props?.idModule}
            module={props?.module}
            files={props?.files}
            onLoaded={() => handleModal(true)}
            onSendedEmail={() =>
              setState((current) => ({
                ...current,
                isOpen: false,
              }))
            }
          >
            <div className={ui.fieldsContainer}>
              <div className="d-flex justify-content-between align-items-center">
                <From />
                <Cloud />
              </div>

              <To />
              <Cc />
              <Subject />
              <FilesEmail />
              <Body />
              <div className={ui.footerModalEmail}>
                <SendEmail />
              </div>
            </div>
          </EmailV2>
        </Dialog>
      ) : null}
    </Fragment>
  );
}
