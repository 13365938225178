import useDialog from "customHooks/useDialog";
import { GetMyActivCustomersContacts } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateMoreInfoPaymentReminder}
 */
const INITIAL_STATE = {
  isLoading: true,
  isOpen: false,
  contacts: [],
};

/**
 * Get more information for the module payment reminder
 * @param {number} idCustomer - Id of the customer to fetch their contacts
 * @returns {import("./types").ReturnUseMoreInfoPaymentReminder}
 */
export default function useMoreInfoPaymentReminder(idCustomer) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (state.isOpen) {

        dialog.open();

        const responseContacts = await GetMyActivCustomersContacts(idCustomer);

        const collectionsConctact = responseContacts.filter(contact=>contact.isCollectionContact);

        setState((current) => ({
          ...current,
          isLoading: false,
          contacts: collectionsConctact,
        }));

        return;
      }

      setState(INITIAL_STATE);
    })();
  }, [state.isOpen]);

  /**
   * Handle the open or close modal
   * @param {boolean} isOpen - Flag to display modal    
   * @returns {void}
   */
  const handleModal = isOpen => setState(current=>({
    ...current,
    isOpen
  }));

  return {
    ...state,
    dialog,
    handleModal
  };
}
