import { InputCurrency } from "components/individual/inputs/Inputs";
import React, { useContext } from "react";
import { NonDeductibleContext } from "..";

export default function TC() {
  const {
    isQuantityTyped,
    isSameCurrency,
    nonDeductibleList,
    concept,
    updateTc,
  } = useContext(NonDeductibleContext);

  return (
    <>
      {isQuantityTyped ? (
        isSameCurrency ? (
          <p className="text-right">$1.0</p>
        ) : (
          <InputCurrency
            labelTxt=""
            aditionalClasses="mx-4"
            placeholder="Escribe aqui (obligatorio)"
            onChange={(value) => updateTc(value, nonDeductibleList[concept.id])}
          />
        )
      ) : (
        <p className="text-center">-</p>
      )}
    </>
  );
}
