import { mnCurrency } from "helpers/money";

export const calculateMxnToUsd = (importe, tc) => {
  if (tc !== undefined) {
    return importe / tc;
  }
};
export const calculateUsdToMxn = (importe, tc) => {
  if (tc !== undefined) {
    return importe * tc;
  }
};

/**
 * Recaltulate the totals of the item
 * @param {import("../../../../../../types/documentActions").ItemSelectI} item
 * @returns{import("../../../../../../types/Partidas/partidas.interfaces").ItemsI}
 */
export const recaltulateTotals = (item, tc) => {
  const currentPu = item?.pu;
  const currentCu = item?.cu;

  const quantity = item?.quantity;

  const puPercentageDiscount = item?.priceDiscount?.number / 100;
  const cuPercentageDiscount = item?.costDiscount?.number / 100;

  const puDiscount = puPercentageDiscount * currentPu?.number;
  const cuDiscount = cuPercentageDiscount * currentCu?.number;

  const puIVA = (item?.iva?.number / 100) * currentPu?.number;
  const cuIVA = (item?.iva?.number / 100) * currentCu?.number;

  const puSell = currentPu?.number * (1 - puPercentageDiscount);
  const cuCost = currentCu?.number * (1 - cuPercentageDiscount);

  const puTotal = puSell * quantity;
  const cuTotal = cuCost * quantity;

  const sellIvaTotal = (item?.iva?.number / 100) * puTotal;
  const costIvaTotal = (item?.iva?.number / 100) * cuTotal;
  /**
   * @type{import("../../../../../../types/documentActions").mxnANDusdI}
   */
  let mxn = {};
  /**
   * @type{import("../../../../../../types/documentActions").mxnANDusdI}
   */
  let usd = {};
  if (!!item.currency) {
    if (item.currency.code === "MXN") {
      mxn = {
        pu: {
          ...currentPu,
          iva: {
            number: puIVA,
            text: mnCurrency(puIVA),
          },
          totalIva: {
            number: sellIvaTotal,
            text: mnCurrency(sellIvaTotal),
          },
          discount: {
            number: puDiscount,
            text: mnCurrency(puDiscount),
          },
          totalUnit: {
            number: puSell,
            text: mnCurrency(puSell),
          },
          total: {
            number: puTotal,
            text: mnCurrency(puTotal),
          },
        },
        cu: {
          ...currentCu,
          iva: {
            number: cuIVA,
            text: mnCurrency(cuIVA),
          },
          totalIva: {
            number: costIvaTotal,
            text: mnCurrency(costIvaTotal),
          },
          discount: {
            number: cuDiscount,
            text: mnCurrency(cuDiscount),
          },
          totalUnit: {
            number: cuCost,
            text: mnCurrency(cuCost),
          },
          total: {
            number: cuTotal,
            text: mnCurrency(cuTotal),
          },
        },
      };
      usd = {
        pu: {
          number: calculateMxnToUsd(currentPu?.number, tc),
          text: mnCurrency(calculateMxnToUsd(currentPu?.number, tc)),
          iva: {
            number: calculateMxnToUsd(puIVA, tc),
            text: mnCurrency(calculateMxnToUsd(puIVA, tc)),
          },
          totalIva: {
            number: calculateMxnToUsd(sellIvaTotal, tc),
            text: mnCurrency(calculateMxnToUsd(sellIvaTotal, tc)),
          },
          discount: {
            number: calculateMxnToUsd(puDiscount, tc),
            text: mnCurrency(calculateMxnToUsd(puDiscount, tc)),
          },
          totalUnit: {
            number: calculateMxnToUsd(puSell, tc),
            text: mnCurrency(calculateMxnToUsd(puSell, tc)),
          },
          total: {
            number: calculateMxnToUsd(puTotal, tc),
            text: mnCurrency(calculateMxnToUsd(puTotal, tc)),
          },
        },
        cu: {
          number: calculateMxnToUsd(currentCu?.number, tc),
          text: mnCurrency(calculateMxnToUsd(currentCu?.number, tc)),
          iva: {
            number: calculateMxnToUsd(cuIVA, tc),
            text: mnCurrency(calculateMxnToUsd(cuIVA, tc)),
          },
          totalIva: {
            number: calculateMxnToUsd(costIvaTotal, tc),
            text: mnCurrency(calculateMxnToUsd(costIvaTotal, tc)),
          },
          discount: {
            number: calculateMxnToUsd(cuDiscount, tc),
            text: mnCurrency(calculateMxnToUsd(cuDiscount, tc)),
          },
          totalUnit: {
            number: calculateMxnToUsd(cuCost, tc),
            text: mnCurrency(calculateMxnToUsd(cuCost, tc)),
          },
          total: {
            number: calculateMxnToUsd(cuTotal, tc),
            text: mnCurrency(calculateMxnToUsd(cuTotal, tc)),
          },
        },
      };
    } else {
      mxn = {
        pu: {
          number: calculateUsdToMxn(currentPu?.number, tc),
          text: mnCurrency(calculateUsdToMxn(currentPu?.number, tc)),
          iva: {
            number: calculateUsdToMxn(puIVA, tc),
            text: mnCurrency(calculateUsdToMxn(puIVA, tc)),
          },
          totalIva: {
            number: calculateUsdToMxn(sellIvaTotal, tc),
            text: mnCurrency(calculateUsdToMxn(sellIvaTotal, tc)),
          },
          discount: {
            number: calculateUsdToMxn(puDiscount, tc),
            text: mnCurrency(calculateUsdToMxn(puDiscount, tc)),
          },
          totalUnit: {
            number: calculateUsdToMxn(puSell, tc),
            text: mnCurrency(calculateUsdToMxn(puSell, tc)),
          },
          total: {
            number: calculateUsdToMxn(puTotal, tc),
            text: mnCurrency(calculateUsdToMxn(puTotal, tc)),
          },
        },
        cu: {
          number: calculateUsdToMxn(currentCu?.number, tc),
          text: mnCurrency(calculateUsdToMxn(currentCu?.number, tc)),
          iva: {
            number: calculateUsdToMxn(cuIVA, tc),
            text: mnCurrency(calculateUsdToMxn(cuIVA, tc)),
          },
          totalIva: {
            number: calculateUsdToMxn(costIvaTotal, tc),
            text: mnCurrency(calculateUsdToMxn(costIvaTotal, tc)),
          },
          discount: {
            number: calculateUsdToMxn(cuDiscount, tc),
            text: mnCurrency(calculateUsdToMxn(cuDiscount, tc)),
          },
          totalUnit: {
            number: calculateUsdToMxn(cuCost, tc),
            text: mnCurrency(calculateUsdToMxn(cuCost, tc)),
          },
          total: {
            number: calculateUsdToMxn(cuTotal, tc),
            text: mnCurrency(calculateUsdToMxn(cuTotal, tc)),
          },
        },
      };
      usd = {
        pu: {
          ...currentPu,
          iva: {
            number: puIVA,
            text: mnCurrency(puIVA),
          },
          totalIva: {
            number: sellIvaTotal,
            text: mnCurrency(sellIvaTotal),
          },
          discount: {
            number: puDiscount,
            text: mnCurrency(puDiscount),
          },
          totalUnit: {
            number: puSell,
            text: mnCurrency(puSell),
          },
          total: {
            number: puTotal,
            text: mnCurrency(puTotal),
          },
        },
        cu: {
          ...currentCu,
          iva: {
            number: cuIVA,
            text: mnCurrency(cuIVA),
          },
          totalIva: {
            number: costIvaTotal,
            text: mnCurrency(costIvaTotal),
          },
          discount: {
            number: cuDiscount,
            text: mnCurrency(cuDiscount),
          },
          totalUnit: {
            number: cuCost,
            text: mnCurrency(cuCost),
          },
          total: {
            number: cuTotal,
            text: mnCurrency(cuTotal),
          },
        },
      };
    }
    const fomratedItem = {
      ...item,
      mxn,
      usd,
    };
    return fomratedItem;
  }
};
