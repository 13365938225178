import { InputCurrency } from "components/individual/inputs/Inputs";
import React from "react";
import { useState } from "react";
import { Label } from "structure/Document";

/**
 * Render P.U. input
 * @param {object} props - Props
 * @param {number?} props.value - Default pu for the input
 * @param {(number)=>void} props.onChange - Value when input changes
 * @param {number?} props.maxLength - Max number of CHARACTERS allowed to type
 * @param {boolean?} props.informative - True if user is on the overview mode
 * @param {import("../../types").typeDocument} props.typeDocument - Type of document that's being handled
 * @returns {JSX.Element}
 */
export default function Cost({
  onChange = () => {},
  value = 0,
  maxLength = 10,
  informative = false,
  typeDocument = "cotizacion",
}) {
  const [val, setVal] = useState(value);

  const handleChange = (amount) => {
    onChange(amount);
    setVal(amount);
  };

  return (
    <>
      <Label required={val <= 0}>Costo unitario</Label>
      {informative && typeDocument !== "odc" ? (
        <p>{value}</p>
      ) : (
        <InputCurrency
          labelTxt=""
          placeholder="Escribe aquí"
          defaultValue={value}
          maxLength={maxLength}
          onChange={handleChange}
        />
      )}
    </>
  );
}
