import React, { useContext, useRef } from "react";
import ui from "./styles.module.scss";
import { ContextDocuments } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Pagination } from "components/individual/Pagination";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import MobileItem from "./MobileItem";
import { parseToFolio } from "helpers/documents";
import TableComponent from "components/general/Table";

/**
 * @param {import("react-data-table-component").TableProps<import("types/typedef/documents").DocumentListInfoI>} props
 */
function TableParsed(props) {
  return <TableComponent {...props} />;
}

const Header = () => (
  <div className={ui.header}>
    <p>No. doc.</p>
    <p>Moneda</p>
    <p>Importe</p>
    <p>IVA</p>
    <p>Total</p>
    <p>Registro</p>
    <p>Vigencia</p>
    <p>Ejecutivo</p>
    <p>Estatus</p>
  </div>
);

export default function Table() {
  const hook = useContext(ContextDocuments);

  return (
    <div className={ui.table}>
      <TableParsed
        selectableRowsSingle
        onChangePage={hook.setPage}
        onRowClicked={(data) => hook.setDocumentSelected(data)}
        selectableRowSelected={(item) =>
          item.idDocument === hook.documentSelected?.idDocument
        }
        paginationTotalRows={hook.pages * 10}
        progressPending={hook.isLoadingTable}
        paginationServer={true}
        customStyles={{
          head: {
            style: { textAlign: "center", fontWeight: "bold" },
          },
        }}
        data={hook.documents}
        columns={[
          {
            name: "No. doc.",
            selector: (data) => (
              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={data.idDocument}
              >
                {parseToFolio(data.documentNumber)}
              </ModalDocsRelatedV2>
            ),
          },
          {
            name: "Moneda",
            selector: (data) => data.Moneda,
          },
          {
            name: "Importe",
            selector: (data) => data.subtotal,
          },
          {
            name: "IVA",
            selector: (data) => data.ivaAmount,
          },
          {
            name: "Total",
            selector: (data) => data.totalAmount,
          },
          {
            name: "Registro",
            selector: (data) => data.createdDate,
          },
          {
            name: "Vigencia",
            selector: (data) => data.expirationDate,
          },
          {
            name: "Ejecutivo",
            selector: (data) => data.Ejecutivo,
          },
          {
            name: "Estatus",
            selector: (data) => data.Estatus,
          },
        ]}
      />

      {/* <Header />
      <div className={ui.containerRows}>
        {hook.documents.map((document) => (
          <div key={`${key.current}-${document.idDocument}`}>
            <input
              id={`${key.current}-${document.idDocument}`}
              type="radio"
              name={key.current}
              className="d-none"
              onChange={(e) => hook.setDocumentSelected(document)}
            />
            <label
              className={ui.row}
              htmlFor={`${key.current}-${document.idDocument}`}
            >
              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={document.idDocument}
              >
                <p
                  className={`${ui.moreInfo} ${
                    document.filesAssociated > 0 ? "hyperlink" : ""
                  }`}
                >
                  {parseToFolio(document.documentNumber)}
                  
                </p>
              </ModalDocsRelatedV2>

              <p className="text-center">{document.Moneda}</p>
              <p className="text-right">{document.subtotal}</p>
              <p className="text-right">{document.ivaAmount}</p>
              <p className="text-right">{document.totalAmount}</p>
              <p className="text-center">{document.createdDate}</p>
              <p className="text-center">{document.expirationDate}</p>
              <p className="text-left">{document.Ejecutivo}</p>
              <p className="text-left">{document.Estatus}</p>
            </label>
            <MobileItem {...document}/>
          </div>
        ))}
      </div>

      <Pagination actualPage={hook.page} pages={hook.pages} onChange={page=>hook.setPage(page)}/> */}
    </div>
  );
}
