import MoreInfo from "components/individual/MoreInfo";
import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import useFeeYears from "customHooks/useFeeYears";
import { getFeeOverview } from "helpers/Apis/Documents";
import React, { useContext, useEffect } from "react";
import { Label } from "structure/Document";
import { CuotasContext } from "..";

/**
 * Combo with the available years for the fee
 * @param {import("./types").PropsAvailableYears} props - Props
 * @returns {JSX.Element}
 */
export default function AvailableYears({
  onChange = () => {},
  refetch = false,
  value
}) {
  const state = useFeeYears({ onChange, refetch });

  const hook = useContext(CuotasContext);

  useEffect(() => {
    (async function () {
      if (typeof hook.year !== "number") return;

      const overview = await getFeeOverview(hook.year);

      if (overview !== undefined) {
        hook.setCurrency(overview.currency);
        hook.setPower(overview.power);
        hook.setFee(overview.type);
      } else {
        hook.setCurrency(undefined);
        hook.setPower(undefined);
        hook.setFee(undefined);
      }
    })();
  }, [state.year,hook.year]);

  if (state.isLoading)
    return (
      <div>
        <Label>Año</Label>
        <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <div>
      <div className="d-flex align-items-center">
        <Label required={state.year === null}>Año</Label>
        {state.years.length <= 1 ? (
          <MoreInfo title="No hay cuotas">
            <p>
              Si el combo esta vacío, significa que no hay ninguna cuota en el
              sistema. En este caso se debe agregar al menos un registro con el
              boton de <b>Agregar</b>
            </p>
          </MoreInfo>
        ) : null}
      </div>
      <Select
        labelToDisplay="label"
        options={async () => state.years}
        onChange={(value) => {
          onChange(+value.value);
          state.setState((current) => ({
            ...current,
            year: +value.value,
          }));
        }}
        defaultSelected={{
          keyToCompare: "value",
          value,
        }}
      />
    </div>
  );
}
