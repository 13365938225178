import { Modal } from "components/general/Modal";
import {
  downloadPdfDocument,
  downloadPdfDocumentV2,
} from "helpers/Apis/Documents";
import React, { useEffect } from "react";

import {
  Email,
  From,
  EmailAppend,
  Subject,
  Files,
  TextBoxEmail,
  EmailExtensions,
  FileFromPc,
  CloudFileBtn,
  EmailItem,
  SendEmailBtn,
} from "structure/Email";

/**
 * Render a modal to send generic emails
 * @param {import("./types").PropsGenericEmailModal} props - Props
 * @returns {JSX.Element}
 * @example 
 * <GenericEmailModal
    propsOpenModal={{
      id: modalEmail.current,
      className: "d-none",
    }}
    to={["jperez@saiko.mx"]}
    cc={["aalardin@saiko.mx"]}
    message="Un mensaje de prueba generico"
    subject="Recordatorio"
    files={async () => downloadPdfDocument(2170, false)}
    validExtensions={["pdf", "png", "xml", "jpeg", "pdf", "gif", "txt"]}
  />
 */
export const EmailDocuments = ({
  propsOpenModal = {},
  cc = [],
  to = [],
  message = null,
  subject = null,
  validExtensions = ["png", "jpg", "jpeg", "pdf", "xml", "eml"],
  onSendedEmail = async () => {},
  files = async () => {},
  idDocument = null,
  title = "Enviar correo",
  toInyection = async() => []
}) => {
  return (
    <Modal
      title={title}
      width={50}
      propsOpenModal={propsOpenModal}
      footer={
        <a
          target={"_blank"}
          href="https://scribehow.com/shared/_Como_enviar_un_correo___9I_JmVQIT4OZpYA1sWFarQ"
        >
          ¿Cómo enviar un correo?
        </a>
      }
    >
      {({ closeModal }) => (
        <Email
          validExtensions={validExtensions}
          files={files}
          onSendedEmail={async () => {
            await onSendedEmail();
            closeModal();
          }}
          cc={cc}
          message={message}
          subject={subject}
          to={to}
          toInyection={toInyection}
        >
          {({ addFiles }) => (
            <>
              <div className="d-flex">
                <From className="mr-3" />

                <FileFromPc className="mr-3" />

                {idDocument !== null ? (
                  <CloudFileBtn
                    idRegister={idDocument}
                    idTypeEntity={1}
                    onChange={({ fileList }) => {
                      addFiles(fileList);
                    }}
                  />
                ) : null}
              </div>
              <EmailAppend type="to" atLeastOneEmail={true} />
              <EmailItem type="to" />
              <EmailAppend type="cc" />
              <EmailItem type="cc" />
              <Subject />
              <Files />
              <TextBoxEmail />

              <div className="d-flex justify-content-between align-items-center">
                <EmailExtensions />
                <SendEmailBtn />
              </div>
            </>
          )}
        </Email>
      )}
    </Modal>
  );
};
