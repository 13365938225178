import SocialReason from "components/general/Forms/Selects/SocialReason";
import React from "react";
import { Label } from "structure/Document";
import {
  CreditDaysContainer,
  HeaderContrainer,
  MultiplesSocialResons,
  SocialResonContainer,
} from "../Containers";
import { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import ToggleComponent from "components/individual/inputs/Toggle";

export const HeaderComponent = () => {
  const {
    handleCreditDaysChange,
    handleOnProviderChange,
    customer,
    handleMultipleProviders,
    interfaceControl,
  } = useContext(WinQuoteContext);
  return (
    <HeaderContrainer>
      <SocialResonContainer>
        <p className="m-0">
          <strong>{customer.client?.socialRazon}</strong>
        </p>
      </SocialResonContainer>
      <MultiplesSocialResons>
        <span>
          <strong>Multiples</strong>
        </span>
        <ToggleComponent
          labelActive="Si"
          labelInactive="No"
          isChecked={interfaceControl.multiplesProviders}
          onChange={handleMultipleProviders}
        />
      </MultiplesSocialResons>
      <SocialResonContainer>
        <Label>Razon social proveedor</Label>
        {interfaceControl.multiplesProviders ? (
          <span>Multiples proveedores</span>
        ) : (
          <SocialReason
            aditionalOptions={[
              {
                id: null,
                isActive: true,
                label: "Seleccione",
                socialReason: "Seleccione",
                value: null,
              },
            ]}
            includeNoActives={false}
            type="proveedor"
            onChange={handleOnProviderChange}
            // value={socialReason}
          />
        )}
      </SocialResonContainer>
      <CreditDaysContainer>
        {/* <Label>Razon social proveedor</Label> */}
        <p className="m-0">
          <strong>Credito</strong>
        </p>
        <input
          type="number"
          placeholder="30"
          max={"50"}
          onChange={({ target: { value } }) => {
            handleCreditDaysChange(+value);
          }}
        />
      </CreditDaysContainer>
    </HeaderContrainer>
  );
};
