import Select from "react-select";
import React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { getPendingInvoices } from "helpers/Apis/Documents";

/**
 * @type {import("./types").StatePendingInvoices}
 */
const INITIAL_STATE = {
  isLoading: true,
  invoices: [],
  invoice: undefined,
};

/**
 * Render a combo of the invoices pending to pay
 * @param {import("./types").PropsPendingInvoices} props - Props
 * @returns {JSX.Element}
 */
export default function AvailableInoivces({ onChange = () => {} }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const pendingInvoices = await getPendingInvoices();

      const parsedForCombo = pendingInvoices.map((invoice) => ({
        ...invoice,
        label: `${invoice.folio} - ${invoice.socialReason}`,
        value: invoice.uuid,
      }));

      setState((current) => ({
        ...current,
        invoices: parsedForCombo,
        isLoading: false,
      }));
    })();
  }, []);

  const handleOnChange = (item) => {
    setState((current) => ({
      ...current,
      invoice: item,
    }));

    onChange(item);
  };

  if (state.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingPendingInvoices"
        text={<LoadingText>Cargando facturas</LoadingText>}
      />
    );

  return (
    <Select
      className="saiko-select"
      classNamePrefix="saiko-select"
      placeholder="Selecciona una factura"
      isClearable={false}
      isSearchable={true}
      onChange={handleOnChange}
      options={state.invoices}
      value={state.invoice}
    />
  );
}
