// import { RedirectButton } from "components/individual/buttons/Buttons";
import EditDocumentButton from "components/individual/buttons/EditButtonDocument";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

export default function Edit() {
  const hook = useContext(CONTEXT_HOME);
  const history = useHistory();

  return (
    <EditDocumentButton goEdit={() => {
      history.push(
        `documentos/cotizacion/editar?idCliente=${hook.quote.customer.id}&documento=${hook.quote.idDocument}`
      );
    }} idDocument={hook.quote.idDocument} />
    // <RedirectButton
    //   id="editQuote"
    //   text="Editar1234"
    //   link={`documentos/cotizacion/editar?idCliente=${hook.quote.customer.id}&documento=${hook.quote.idDocument}`}
    // />
  );
}
