import React, { createContext, useEffect, useRef, useState } from "react";
import { Table, Header } from "./styles";
import {
  IdRegister,
  Applied,
  Currency,
  Import,
  NewResidue,
  Saldo,
  TC,
  DocNumber,
  Total,
  InputImport,
  InputTC,
  AppliedImport,
} from "./labels";
import {
  isValidResidueOdc as validateResidueOdc,
  mnCurrency,
  validateInvoiceReceptionResidue,
} from "helpers/money";
import { getInvoiceReceptionTolerance } from "helpers/Apis/parameters";

export const GridImportsContext = createContext(null);
const { Provider } = GridImportsContext;

/**
 * Grid to associate amounts
 * @param {object} props - Props
 * @param {JSX.Element[]} props.children - React components
 * @param {import("types/typedef/documents").OcsProvider[]} props.amountsInfo - Information of the amounts to render
 * @param {string[]} props.headers - Headers to render on the table
 * @param {number?} props.toleranceProp - Tolerance to use for the input "Aplicacion"
 * @param {('USD'|'MXN')} props.currency - Predominant currency above the entitys in order to recalcualte items (if needed)
 * @param {number} props.residue - Residue predominant of the register
 * @param {(object:import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI)=>void} props.onChange - Information of the grid when info changes
 * @example
 * <GridImports
          residue={1000}
          headers={[
            "ODC",
            "Moneda",
            "Total",
            "Saldo ODC",
            "Acreditar ODC",
            "Saldo nuevo ODC",
            "Acreditar a factura",
            "TC",
          ]}
          amountsInfo={[...]}
          onChange={handleOnChange}
          currency={'USD'}
        >
          {({ index }) => (
            <>
              <GridImports.DocNumber index={index} />
              <GridImports.Currency index={index} />

              <GridImports.Total index={index} />
              <GridImports.Saldo index={index} />
              <GridImports.InputImport index={index} />
              <GridImports.NewResidue index={index} />
              <GridImports.Applied index={index} />
              <GridImports.TC index={index} />
            </>
          )}
        </GridImports>
 * @returns {JSX.Element} React component
 */
export default function GridImports({
  children,
  amountsInfo = [],
  headers = [],
  currency = "MXN",
  residue = 0,
  toleranceProp = null,
  onChange = () => {},
}) {
  /**
   * @type {[import("types/typedef/documents").OcsProvider[],()=>void]}
   */
  const [amounts, setAmounts] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [tolerance, setTolerance] = useState(0);

  const key = useRef(`${window.crypto.randomUUID()}`);

  useEffect(() => {
    (async function () {
      if (toleranceProp === null) {
        const apiTolerance = await getInvoiceReceptionTolerance();
        setTolerance(apiTolerance);
      } else {
        setTolerance(toleranceProp);
      }
    })();
  }, []);

  useEffect(() => {
    const newAmount = amountsInfo.map((amount) => ({
      ...amount,
      tc: {
        number: currency === amount.currency ? 1 : 0,
        text: currency === amount.currency ? "$1.00" : "$0.00",
      },
    }));
    setAmounts(newAmount);
  }, [amountsInfo]);

  useEffect(() => {
    if (amounts.length <= 0) return;

    let isValidImport = true;
    let isValidResidue = true;
    let isValidApplied = true;
    let isNegativeResidue = false;
    let isPayable = true;
    let isUsedAllResidue = false;
    let isValidTolerance = true;
    let totalApplied = 0;
    let newResidue = residue;
    let filteredGrid = {
      array: [],
      indexed: {},
    };

    let unreferencedAmounts = [...amounts];

    unreferencedAmounts.forEach((amount, index) => {
      const newResidueElement = +(
        +amount.residue.number.toFixed(2) - +amount.import.number.toFixed(2)
      ).toFixed(2);

      // const isValidResidueOdc = validateInvoiceReceptionResidue(
      //   newResidueElement,
      //   tolerance,
      //   amount.import.number
      // );

      // validateInvoiceReceptionResidue

      const isValidResidueOdc = validateInvoiceReceptionResidue(
        newResidueElement,
        tolerance,
        amount.import.number
      );

      const elementValidTolerance = validateResidueOdc(
        newResidueElement,
        tolerance,
        amount.import.number
      );

      if (elementValidTolerance === false) {
        isValidTolerance = false;
      }

      unreferencedAmounts[index] = {
        ...unreferencedAmounts[index],
        newResidue: {
          number: newResidueElement,
          text: mnCurrency(newResidueElement),
        },
        isValidResidueOdc,
      };

      if (isValidResidueOdc === false) {
        isValidResidue = false;
      }

      newResidue -= +amount.applied.number.toFixed(2);

      if (+newResidueElement.toFixed(2) >= -tolerance === false) {
        isPayable = false;
      }

      if (newResidue === 0) {
        isUsedAllResidue = true;
      }

      if (newResidue < 0) {
        isNegativeResidue = true;
      }

      if (amount.import.number !== 0 && amount.applied.number === 0) {
        isValidApplied = false;
      }

      totalApplied += +amount.applied.number.toFixed(2);

      if (
        amount.total.number > 0 &&
        amount.tc.number > 0 &&
        amount.applied.number > 0
      ) {
        filteredGrid = {
          array: [
            {
              ...amount,
              newResidue: {
                number: newResidueElement,
                text: mnCurrency(newResidueElement),
              },
              isValidResidueOdc,
            },
            ...filteredGrid.array,
          ],
          indexed: {
            ...filteredGrid.indexed,
            [amount.id]: {
              ...amount,
              newResidue: {
                number: newResidueElement,
                text: mnCurrency(newResidueElement),
              },
              isValidResidueOdc,
            },
          },
        };
      }
    });

    const parsedResidue = +newResidue.toFixed(2);

    setAmounts(unreferencedAmounts);

    onChange({
      isValidImport,
      isUsedAllResidue: parsedResidue === 0 ? true : false,
      isNegativeResidue,
      newResidue: parsedResidue,
      isValidResidue,
      filteredGrid,
      isValidApplied,
      totalApplied,
      isValidTolerance,
      isPayable,
      tolerance: tolerance,
    });
  }, [refetch]);

  /**
   * Set import of element
   * @param {number} index - Index of the array
   * @param {number} newImport - New import
   */
  const setImport = (index, newImport) => {
    // 1. Unreferenced copy
    const amountsUnreferenced = [...amounts];

    // 2. Validate import of the entity
    const isValidImport = validateImport();
    // 3. Get the applied for the entity
    const applied = calculateApplied();

    // 3. Update entity
    updateEntity();
    setRefetch(!refetch);

    // FINISH

    /**
     * Validate the "importe" is more than the "saldo" remaining.
     * @returns {boolean}
     */
    function validateImport() {
      if (newImport > amountsUnreferenced[index].residue.number) {
        return false;
      }

      return true;
    }

    function updateEntity() {
      amountsUnreferenced[index] = {
        ...amountsUnreferenced[index],
        import: {
          number: newImport,
          text: mnCurrency(newImport),
        },
        isValidImport,
        applied: {
          number: applied,
          text: mnCurrency(applied),
        },
      };
      // if (!!newImport) {
      //   amountsUnreferenced[index] = {
      //     ...amountsUnreferenced[index],
      //     import: {
      //       number: newImport,
      //       text: mnCurrency(newImport),
      //     },
      //     isValidImport,
      //     applied: {
      //       number: applied,
      //       text: mnCurrency(applied),
      //     },
      //   };
      // }

      setAmounts(amountsUnreferenced);
    }

    /**
     * Calculate the applied for the entity
     * @returns {number} Applied according the TC and Importe
     */
    function calculateApplied() {
      if (newImport === 0 || amountsUnreferenced[index].tc.number === 0) {
        return 0;
      }

      if (amountsUnreferenced[index].currency === currency) {
        return newImport;
      } else {
        return changeAppliedV2(
          amountsUnreferenced,
          index,
          newImport,
          amountsUnreferenced[index].tc.number
        );
        // return amountsUnreferenced[index].applied.number;
      }
    }
  };

  const setTC = (index, tcApplied) => {
    let unreferencedAmounts = [...amounts];

    if (unreferencedAmounts[index].import === 0) {
      unreferencedAmounts[index] = {
        ...unreferencedAmounts[index],
        tc: {
          number: 0,
          text: "$0.0",
        },
        applied: {
          number: 0,
          text: "$0.0",
        },
      };
      setAmounts(unreferencedAmounts);
    }

    let importApplide = changeApplied(unreferencedAmounts, index, tcApplied);

    unreferencedAmounts[index] = {
      ...unreferencedAmounts[index],
      tc: {
        number: tcApplied,
        text: mnCurrency(tcApplied),
      },
      applied: {
        number: importApplide,
        text: mnCurrency(importApplide),
      },
    };

    setAmounts(unreferencedAmounts);
    setRefetch(!refetch);
  };

  /**
   * Set the applied import on the grid
   * @param {number} index - Index of the element on the array
   * @param {number} applied - Amount to apply
   */
  const setApplied = (index, applied) => {
    let unreferencedAmounts = [...amounts];
    let tcNumber = unreferencedAmounts[index].currency === currency ? 1 : 0;
    let tcText =
      unreferencedAmounts[index].currency === currency ? "$1.0" : "$0.0";

    if (unreferencedAmounts[index].applied === 0) {
      unreferencedAmounts[index] = {
        ...unreferencedAmounts[index],
        tc: {
          number: tcNumber,
          text: tcText,
        },
        applied: {
          number: 0,
          text: "$0.0",
        },
      };
      setAmounts(unreferencedAmounts);
    }

    let tc = 0;

    if (unreferencedAmounts[index].currency === "USD") {
      tc = applied / unreferencedAmounts[index].import.number;
    } else {
      tc = unreferencedAmounts[index].import.number / applied;
    }

    unreferencedAmounts[index] = {
      ...unreferencedAmounts[index],
      tc: {
        number: tc,
        text: mnCurrency(tc),
      },
      applied: {
        number: applied,
        text: mnCurrency(applied),
      },
    };

    setAmounts(unreferencedAmounts);
    setRefetch(!refetch);
  };

  const changeApplied = (unreferencedAmounts, index, tcApplied) => {
    if (!tcApplied) return 0;
    if (unreferencedAmounts[index].currency === currency) {
      return unreferencedAmounts[index].import.number;
    } else if (currency === "USD") {
      // Significa que la moneda de la CXP es diferente a la del movimiento
      //Entonces si la moneda del movimiento es USD significa que la moneda de la CXP es MXN
      return unreferencedAmounts[index].import.number / tcApplied;
    } else {
      //Entonces si la moneda del movimiento es MXN significa que la moneda de la CXP es USD
      return unreferencedAmounts[index].import.number * tcApplied;
    }
  };
  const changeAppliedV2 = (
    unreferencedAmounts,
    index,
    newImport,
    tcApplied
  ) => {
    if (unreferencedAmounts[index].currency === currency) {
      return unreferencedAmounts[index].import.number;
    } else if (currency === "USD") {
      // Significa que la moneda de la CXP es diferente a la del movimiento
      //Entonces si la moneda del movimiento es USD significa que la moneda de la CXP es MXN
      return newImport / tcApplied;
    } else {
      //Entonces si la moneda del movimiento es MXN significa que la moneda de la CXP es USD
      return newImport * tcApplied;
    }
  };

  return (
    <Table columns={headers.length}>
      {amounts.length > 0
        ? headers.map((header, i) => (
            <Header key={`${key.current}-${i}-dynamicHeader`}>{header}</Header>
          ))
        : null}

      <Provider
        value={{
          amountsInfo: amounts,
          setImport,
          setTC,
          predominantCurrency: currency,
          setApplied,
        }}
      >
        {amounts.map((amount, i) =>
          children({
            index: i,
          })
        )}
      </Provider>
    </Table>
  );
}

GridImports.IdRegister = IdRegister;
GridImports.Applied = Applied;
GridImports.Currency = Currency;
GridImports.Import = Import;
GridImports.NewResidue = NewResidue;
GridImports.Saldo = Saldo;
GridImports.TC = TC;
GridImports.Total = Total;
GridImports.DocNumber = DocNumber;
GridImports.InputImport = InputImport;
GridImports.InputTC = InputTC;
GridImports.AppliedImport = AppliedImport;
