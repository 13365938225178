import { Modal } from "components/general/Modal";
import { ExcelInput } from "components/individual/ExcelInput";
import React from "react";
import { useState } from "react";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../../structure/Menu/index.module.scss";
import { Footer } from "./Footer";
import modalScss from "./styles.module.scss";

/**
 * Render the menu item for concept load
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>} props - Props
 * @returns {JSX.Element}
 */
export const IVASetup = (props) => {
  const [file, setFile] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={modalScss.container}>
        <Modal
          isOpen={isOpen}
          title="IVA"
          onCloseModal={() => setIsOpen(false)}
          footer={<Footer file={file} />}
          displayButton={false}
        >
          {() => (
            <div className="d-flex flex-column align-items-center">
              <h1 className="text-center">
                Por favor, carga la plantilla de excel
              </h1>

              <ExcelInput onChange={(e) => setFile(e)} />
            </div>
          )}
        </Modal>
      </div>

      <p {...props} className={scss.item} onClick={() => setIsOpen(true)}>
        <TextIcon icon="excel" text="IVA" />
      </p>
    </>
  );
};
