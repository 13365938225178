import { useEffect } from "react";
import { GetUEN } from "helpers/Apis/Documents";
import { useState } from "react";
import useDialog from "customHooks/useDialog";
import { createIndexedList } from "helpers/lists";

/**
 * @type {import("../types").StateUensContact}
 */
const INITIAL_STATE = {
  isLoadingUens: true,
  uens: [],
  isLoadingUensContact: true,
  uensContact: {},
  uensMarked: [],
  isOpen: false,
};

/**
 * @type {import("../types").ReturnUseUenContact}
 */
export const CONTEXT_UEN_CONTACT = {
  ...INITIAL_STATE,
  dialog: {
    open: () => {},
    close: () => {},
    refDialog: undefined,
  },
  customOpen: () => {},
  handleContactCheckbox: () => {},
  handleEmailCheckbox: () => {},
  customClose: () => {},
  handleOnConfirm: () => {},
  displayBlockColumn:true
  
};

/**
 *
 * @param {import("../types").PropsUenContact} param - Params
 * @returns
 */
export default function useUenContact({
  idContact = null,
  onChange = () => {},
  uens = [],
}) {
  const dialog = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      const uensApi = await GetUEN();

      setState((current) => ({
        ...current,
        uens: uensApi,
        isLoadingUens: false,
      }));

      const uensContact = createIndexedList(uens, "idUen");

      setState((current) => ({
        ...current,
        uensMarked: uens,
        uensContact,
        isLoadingUensContact:false
      }));
    })();
  }, [state.isOpen]);

  const customOpen = () => {
    dialog.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const customClose = () => {
    dialog.close();
    setState(INITIAL_STATE);
  };

  /**
   * Handle the checkbox for the uen selection grid
   * @param {import("types/typedef/catalogue").UenI} uen - Uen marked/unmarked
   */
  function handleContactCheckbox(uen) {
    const isOnList = state.uensContact[uen.UENID] === undefined ? false : true;

    if (isOnList) {
      deleteFromList();
      return;
    }

    appendIntoList();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function appendIntoList() {
      setState((current) => ({
        ...current,
        uensContact: {
          ...current.uensContact,
          [uen.UENID]: {
            email: false,
            id: null,
            idUen: uen.UENID,
            label: `${uen.family} - ${uen.description}`,
          },
        },
      }));
    }

    function deleteFromList() {
      const newList = { ...state.uensContact };

      delete newList[uen.UENID];

      setState((current) => ({
        ...current,
        uensContact: newList,
      }));
    }
  }

  /**
   * Update the checkbox email combo for the uen's selected
   * @param {number} uenId - Id of the uen that's already marked and needs to be toggled
   */
  function handleEmailCheckbox(uenId) {
    const unref = { ...state.uensContact[uenId] };

    const newValue =
      unref.email === true
        ? { ...unref, email: false }
        : { ...unref, email: true };

    setState((current) => ({
      ...current,
      uensContact: {
        ...current.uensContact,
        [uenId]: newValue,
      },
    }));
  }

  const handleOnConfirm = () => {
    const items = Object.entries(state.uensContact).map(([key, item]) => item);
    onChange(items);
    customClose();
  };

  return {
    ...state,
    dialog,
    customOpen,
    handleContactCheckbox,
    customClose,
    handleEmailCheckbox,
    handleOnConfirm,
  };
}
