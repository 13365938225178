import React from "react";
import scss from "./styles.module.scss";
import Select from "../SelectV4";

/**
 * Render a pagination interface
 * @param {import("./types").PaginationPropsI} props - Props
 * @returns {JSX.Element}
 * @example
 * export default function InvoiceReception() {

  // ...

  return (
  <Pagination
    actualPage={1}
    pages={2}
    onChange={async()=>await miApi()}
  />      
  )
}

 */
export const Pagination = ({
  actualPage = 1,
  pages = 0,
  onChange = () => {},
  displayRowsPerPage = false,
  defaultRowsPerPage = {
    keyToCompare: "value",
    value: 25,
  },
}) => {
  const lastPage = () => onChange(pages);
  const firstPage = () => onChange(1);
  const nextPage = () => onChange(actualPage + 1);
  const previousPage = () => onChange(actualPage - 1);

  const disabledNextPage = actualPage === pages || pages === 0 ? true : false;
  const disabledPreviousPage = actualPage === 1 ? true : false;

  /**
   *
   * @param {import("./types").KeyboardEvent} e
   * @param {string} page
   */
  const handleOnTypeing = (e, page) => {
    if (e.key !== "Enter") return;

    const pageNumber = +page;

    if (typeof pageNumber !== "number") return;

    if (pageNumber <= pages) {
      onChange(pageNumber);
    }
  };

  return (
    <div className={scss.pagination}>
      <div className={scss.mobilePagination}>
        {pages >= 1 ? (
          <>
            <button type="button" disabled={disabledPreviousPage} onClick={previousPage}>
              Anterior
            </button>
            <button type="button" disabled={disabledNextPage} onClick={nextPage}>
              Siguiente
            </button>
          </>
        ) : null}
      </div>

      <div className={scss.searchPage}>
        <input
          type="number"
          max={pages}
          placeholder="Ir a página"
          min={1}
          onKeyDownCapture={(e) => handleOnTypeing(e, e.target.value)}
        />
        {displayRowsPerPage ? (
          <Select
            defaultSelected={defaultRowsPerPage}
            labelToDisplay="label"
            options={async () => [
              {
                value: 25,
                label: "25",
              },
              {
                value: 50,
                label: "50",
              },
              {
                value: 75,
                label: "75",
              },
              {
                value: 100,
                label: "100",
              },
            ]}
          />
        ) : null}
      </div>

      <div className={scss.page}>
        <button type="button" disabled={disabledPreviousPage} onClick={firstPage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
        </button>
        <button type="button" disabled={disabledPreviousPage} onClick={previousPage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <span>{actualPage}</span>
        <span>{pages}</span>
        <button type="button" disabled={disabledNextPage} onClick={nextPage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
        <button type="button" disabled={disabledNextPage} onClick={lastPage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
