import { Modal } from "components/general/Modal";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { updateStatusTodo } from "helpers/Apis/todo";
import React, { useContext } from "react";
import { useRef } from "react";
import { CardsToDoContext } from "..";
import { CardContext } from "../Card";
import { CheckToDo } from "../styles";

export default function DoneToggle() {
  const {
    isUpdating,
    todo,
    openHistory,
    idAuthorizationModal,
    handleDirectDone,
    setIsUpdating,
    index,
  } = useContext(CardContext);

  const { updateDone } = useContext(CardsToDoContext);

  const openModalAuthorization = () => {
    const dom = document.getElementById(idAuthorizationModal);
    if (dom) dom.click();
  };

  const idDom = useRef(window.crypto.randomUUID());

  const confirmThreadFollow = async () => {
    setIsUpdating(true);

    const wasUpdated = await updateStatusTodo(
      todo.id,
      todo.isOpen === true ? "done" : "undone"
    );

    if (wasUpdated) updateDone(index, !todo.isOpen);

    setIsUpdating(false);
  };

  const openModalPrompt = () => document.getElementById(idDom.current)?.click();

  const toggle = {
    ["-100"]: (
      <input
        name={`${idDom.current}`}
        type="checkbox"
        checked={!todo.isOpen}
        onClick={openModalAuthorization}
        disabled={isUpdating ? true : false}
      />
      // <CheckToDo
      //   disabled={isUpdating ? true : false}
      //   onClick={openModalAuthorization}
      //   isChecked={!todo.isOpen}
      // ></CheckToDo>
    ),
    ["-200"]: (
      <input
        name={`${idDom.current}`}
        type="checkbox"
        checked={true}
        onClick={handleDirectDone}
        disabled={isUpdating ? true : false}
      />
      // <CheckToDo
      //   disabled={isUpdating ? true : false}
      //   isChecked={false}
      //   onClick={handleDirectDone}
      // ></CheckToDo>
    ),
  };

  return todo.isOpen
    ? toggle[`${todo.tag.id}`] || (
        <>
          <input
            name={idDom.current}
            type="checkbox"
            checked={!todo.isOpen}
            onClick={openModalPrompt}
            disabled={isUpdating ? true : false}
          />
          <Modal
            title="To Do"
            width={50}
            propsOpenModal={{
              style: {
                display: "none",
              },
              id: idDom.current,
            }}
          >
            {({ closeModal }) => (
              <>
                <h1 className="text-center py-5">
                  ¿Deseas dar seguimiento al To Do?
                </h1>

                <div className="d-flex justify-content-center">
                  <ButtonV2
                    onClick={() => {
                      openHistory();
                      closeModal();
                    }}
                    className="mr-3"
                  >
                    Si
                  </ButtonV2>
                  <ButtonV2
                    theme="danger"
                    onClick={async () => {
                      closeModal();
                      await confirmThreadFollow();
                    }}
                  >
                    No
                  </ButtonV2>
                </div>
              </>
            )}
          </Modal>

          {/* <CheckToDo
            disabled={isUpdating ? true : false}
            onClick={openModalPrompt}
            isChecked={!todo.isOpen}
          ></CheckToDo> */}
        </>
      )
    : null;
}
