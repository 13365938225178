import ModalV2 from "components/individual/Modals/ModalV2";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import useEgressOptions from "customHooks/useEgressOptions";
import Pdf from "./Pdf";
import Associate from "./Associate";
import Cancel from "./Cancel";
import EditEgress from "./Edit";
import EgressOverview from "../EgressOverview";
import { CancelCxp } from "./CancelCxp";
import TableContext from "context/Table/TableContext";
import { CancelMovement } from "../CancelMovement";
import { MovementContext } from "../Movements";
import { useContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ModalDisassociateEgressConcept } from "structure/ModalDisassociateEgressConcept";
import usePermissions from "customHooks/usePermissions";

/**
 * @type { import("react").Context<import("types/typedef/context/ContextOptionsEgress").ContextI> }
 */
export const ContextOptionsEgress = createContext();
const { Provider } = ContextOptionsEgress;

/**
 *
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} props.infoRow - Information of the row selected
 * @returns {JSX.Element} Component
 */
export default function OptionsEgress({ infoRow }) {
  const { account } = useParams();
  const idModalBtn = window.crypto.randomUUID();

  const { refetch, setReload } = useContext(TableContext);
  const { emptyInfoRow } = useContext(MovementContext);

  const { checkPermission } = usePermissions();

  const refetchData = () => {
    setReload(!refetch);
    emptyInfoRow();
  };

  const {
    isCancelling,
    promptConfirmCancell,
    promptConfirmDisassociate,
    isDisasociating,
  } = useEgressOptions(infoRow);

  return (
    <Provider
      value={{
        idStatus: infoRow.statusValue,
        idCustomerAssociated: infoRow.customerAssociated,
        idMovement: infoRow.Movimiento,
        idBankAccount: +account,
        isDisasociating,
        isCancelling,
        promptConfirmCancell,
        promptConfirmDisassociate,
      }}
    >
      <ModalV2
        close="Cerrar"
        title="Tipo de asociacion"
        idOpenModal={idModalBtn}
      ></ModalV2>

      {checkPermission("0e42281e-fa77-4fcb-8931-ae1c2714f312") ? <Pdf /> : null}

      {checkPermission("2502dcac-764a-46ee-920f-e30159057769") ? (
        <Associate />
      ) : null}

      {(infoRow.statusValue === 2 || infoRow.statusValue === 3) &&
      infoRow.typeAssociation.id === 3 ? (
        <CancelCxp
          idMovement={infoRow.Movimiento}
          onDisassociated={refetchData}
          idBank={account}
        />
      ) : null}

      {(infoRow.statusValue === 2 || infoRow.statusValue === 3) &&
      infoRow.typeAssociation.id === 4 ? (
        <ModalDisassociateEgressConcept idMovement={infoRow.Movimiento} />
      ) : null}

      {infoRow.statusValue === 1 &&
      checkPermission("5388c282-33e5-41b9-a429-f892025723a3") ? (
        <CancelMovement
          currentStatus={infoRow.statusValue}
          id={infoRow.Movimiento}
          type={infoRow.typeAssociation.id}
        />
      ) : null}

      <EditEgress />
    </Provider>
  );
}
