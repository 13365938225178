import useDialog from "customHooks/useDialog";
import { getCorporatives } from "helpers/Apis/Directory";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateCorporative}
 */
const INITIAL_STATE = {
  page: 1,
  pages: 0,
  corporatives: [],
  corporative: null,
  isOpen: false,
  isLoading: true,
  search: "",
  listenerSearch: false,
};

/**
 * @type {import("./types").ContextAssociateCorporative}
 */
export const ContextCorporativeValue = {
  ...INITIAL_STATE,
  dialog: undefined,
  openModal: () => {},
  closeModal: () => {},
  setPage: () => {},
  setSearch: () => {},
  attemptSearch:()=>{},
  setCorporative:()=>{},
  confirmSelection:()=>{}
};

/**
 * Handle the corporative of a customer or provider on the system
 * @param {number|null} [idCorporative=null] Id of the corporative
 * @returns {import("./types").ReturnUseAssociateCorporative}
 */
export default function useAssociateCorporative(idCorporative = null) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      setState((current) => ({
        ...current,
        isLoading: true,
        corporatives: [],
        corporative: null,
      }));

      const fetchedCorporative = await getCorporatives(
        state.page,
        "DESC",
        "customerID",
        state.search === "" ? "" : `&search=${state.search}`
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        corporatives: fetchedCorporative.corporatives,
        page: fetchedCorporative.actualPage,
        pages: fetchedCorporative.pages,
      }));
    })();
  }, [state.isOpen, state.page, state.listenerSearch]);

  const openModal = () => {
    dialog.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const closeModal = () => {
    dialog.close();
    setState((current) => INITIAL_STATE);
  };

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

    const setCorporative = corporative => setState(current=>({
        ...current,
        corporative
    }));

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      listenerSearch: !current.listenerSearch,
      corporative: null,
      corporatives: [],
      page: 1,
      pages: 0,
    }));

  return {
    ...state,
    setPage,
    setSearch,
    dialog,
    openModal,
    attemptSearch,
    setCorporative,
    closeModal,
  };
}
