
import { useState } from "react";


export const useItems = () => {


  // const {item:selectedItem} = useContext(ContextCatalogue);

  // useEffect(() => {
  //   // handleItemDescriptionChange(selectedItem);
  //   console.log('Abriendo el catalogo');
  //   console.log(selectedItem);
  // }, [selectedItem]);

  const [item, setItem] = useState({});

  const [openModalMoreInfo, setOpenModalMoreInfo] = useState(false)

  const [onNewItem, setOnNewItem] = useState(false);

  const doOpenModal = () => {
    
    setItem({
      ...item,
      openModal: true,
    });
  };

  const doCloseModal = () => {
    setItem({
      ...item,
      openModal: false,
    });
  };

  const handleOnMoreInfoModal=(doOpen)=>{
    setOpenModalMoreInfo(!openModalMoreInfo);
  }

  const handleOnNewItem=()=>{
    setOnNewItem(!onNewItem);
  }

  return {
    item,
    doOpenModal,
    doCloseModal,
    handleOnMoreInfoModal,
    openModalMoreInfo,
    handleOnNewItem,
    onNewItem
  };
};
