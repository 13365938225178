import { getOcsPaginated, updateGenerateCxp } from "helpers/Apis/odc";
import { dateToDbFormat } from "helpers/dates";
import { useEffect, useReducer, useRef } from "react";
import { Actions } from "./actions";
import { initialState, reducer } from "./reducer";
import { useHistory } from "react-router-dom";
import { Warning, YesNoAlert } from "helpers/alerts";
import useOcPdf from "customHooks/useOcPdf";
import { updateOdcStatusToSended } from "helpers/Apis/Documents";
import { saveAs } from "file-saver";

/**
 * @type {import("./types").StatusOcIndexed}
 */
const STATUS_INDEXED = {
  10: "active",
  11: "sent",
  12: "cancelated",
  13: "partialReceived",
  17: "received",
  18: "closed",
};

/**
 * Custom hook to handle the information to display on the odc specials
 * @param {import("./types").ParamUseOdcAdm} param - Params for the initial filters
 * @returns {import("./types").useAdministrationOdcValuesI}
 */
export default function useAdministrationOdc(param) {
  const history = useHistory();

  const STATUS = useRef(STATUS_INDEXED);

  const { from, status, to, onChangeGrid } = param || {
    from: null,
    status: null,
    to: null,
    onChangeGrid: () => {},
  };

  /**
   * Information in order to display
   * @type {[import("./types").StateI,()=>void]}
   */
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    date: {
      start:
        from instanceof Date && to instanceof Date
          ? from
          : initialState.date.start,
      end:
        from instanceof Date && to instanceof Date ? to : initialState.date.end,
    },
    status,
  });

  const watchDocument = () =>
    history.push(
      `../directorio/documentos/${state.rowSelected.customer.id}/ver/${state.rowSelected.id}?modulo=odc`
    );

  const goToDo = () =>
    history.push(
      `../administracion/ordenes-compra/todo?documento=${state.rowSelected.id}&cliente=${state.rowSelected.customer.id}`
    );

  const goFiles = () =>
    history.push(`ordenes-compra/archivos-asociados/${state.rowSelected.id}`);

  //
  const goEdit = () =>
    state.rowSelected.status.id === 10 || state.rowSelected.status.id === 11
      ? history.push(
          `/documentos/odc/editar?documento=${state.rowSelected.id}&modulo=administracion&idCliente=${state.rowSelected.customer.id}`
        )
      : Warning("No se puede editar la OC");

  const idModalRelatedDocs = useRef(`${window.crypto.randomUUID()}`);

  const pdf = useOcPdf(
    state.rowSelected?.id,
    `Orden de compra - ${state.rowSelected?.numeroDocumento}`,
    state.rowSelected?.moneda
  );

  useEffect(() => {
    onChangeGrid(state.grid);
  }, [state.grid]);

  const setIsFetchingData = (isFetching) =>
    dispatch({
      type: Actions.SET_IS_FETCHING_DATA,
      payload: isFetching,
    });

  const setRowSelected = (odc) =>
    dispatch({
      type: Actions.SET_ROW,
      payload: odc,
    });

  useEffect(() => {
    (async function () {
      setIsFetchingData(true);
      setRowSelected(null);

      const { documents, pages, actualPage } = await getOcsPaginated({
        customer: state.customer,
        endDate: dateToDbFormat(state.date.end),
        startDate: dateToDbFormat(state.date.start),
        page: state.page,
        search: state.search,
        status: state.status,
      });

      dispatch({
        type: Actions.SET_SEARCH_RESULTS,
        payload: {
          totalPages: pages,
          rows: documents,
          actualPage,
        },
      });

      setIsFetchingData(false);
    })();
  }, [state.refetch, state.page]);

  /**
   * Set the spinner for downloading the excel
   * @param {boolean} isDownloading - Flag
   * @returns {void}
   */
  const setDownloadingExcel = (isDownloading) =>
    dispatch({
      type: Actions.SET_EXCEL_DOWNLOADING,
      payload: isDownloading,
    });

  const downloadExcel = async () => {
    setDownloadingExcel(true);

    const excel = await getOcsPaginated({
      customer: state.customer,
      endDate: dateToDbFormat(state.date.end),
      startDate: dateToDbFormat(state.date.start),
      page: state.page,
      search: state.search,
      status: state.status,
      isExcelReport: true,
    });

    if (excel instanceof Blob) {
      saveAs(excel, `Ordenes de compra.xlsx`);
    }

    setDownloadingExcel(false);
  };

  /**
   * Update the social reason to search on filter
   * @param {number|null} id - Social reason
   * @param {string} socialReason - Social reason of the item selected
   * @returns {void}
   */
  const updateSocialReason = (id, socialReason) =>
    dispatch({
      type: Actions.SET_SOCIAL_REASON,
      payload: { id, socialReason },
    });

  const setPage = (page) =>
    dispatch({
      type: Actions.SET_PAGE,
      payload: page,
    });

  /**
   * Update the status to search on the filter table
   * @param {number|null} status - Status
   * @returns {void}
   */
  const updateStatus = (status) =>
    dispatch({
      type: Actions.SET_STATUS,
      payload: status,
    });

  /**
   * Update the range dates for the filter
   * @param {[Date,Date]} range - Range of the dates to search
   * @returns {void}
   */
  const updateRangeDates = (range) =>
    dispatch({
      type: Actions.SET_DATES,
      payload: range,
    });

  /**
   * Trigger the button to re-search information of the buttons
   * @returns {void}
   */
  const triggerSearch = () =>
    dispatch({
      type: Actions.TRIGGER_SEARCH,
    });

  /**
   * Update the number of document
   * @param {number} noDocument - Number of document to search
   * @returns {void}
   */
  const updateSearch = (noDocument) =>
    dispatch({
      type: Actions.SET_NO_DOCUMENT,
      payload: noDocument > 0 ? noDocument : null,
    });

  const toggleAccounting = (id) =>
    dispatch({
      type: Actions.TOGGLE_ACCOUNTING,
      payload: id,
    });

  const updateOc = async () => {
    await updateOdcStatusToSended(state.rowSelected.id);
    triggerSearch();
  };

  const attemptUpdateCxp = async () => {
    dispatch({
      type: Actions.SET_IS_UPDATING_CXP,
      payload: true,
    });

    const flagToUse =
      STATUS.current[state.rowSelected.status.id] === "active" ? false : true;

    const wasUpdated = await updateGenerateCxp({
      generaCxp: flagToUse,
      oc: state.rowSelected.id,
    });

    if (wasUpdated) triggerSearch();

    dispatch({
      type: Actions.SET_IS_UPDATING_CXP,
      payload: false,
    });
  };

  /**
   * Prompt an alert to update
   * @returns {void}
   */
  const attemptGenerateCxp = () => {
    if (state.rowSelected === null || state.rowSelected === undefined) return;

    const message =
      STATUS.current[state.rowSelected.status.id] === "active"
        ? `¿Deseas que la OC ${state.rowSelected.numeroDocumento} no genere CxP?`
        : `¿Deseas que la OC ${state.rowSelected.numeroDocumento} genere CxP?`;

    YesNoAlert(message, attemptUpdateCxp);
  };

  return {
    isFetchingData: state.isFetchingData,
    STATUS: STATUS.current,
    attemptGenerateCxp,
    socialReason: state.customer,
    startDate: state.date.start,
    ...state,
    endDate: state.date.end,
    recordsWereFounded: state.rows.length > 0 ? true : false,
    updateSocialReason,
    updateStatus,
    updateOc,
    updateRangeDates,
    triggerSearch,
    setRowSelected,
    toggleAccounting,
    setPage,
    updateSearch,
    odc: state.rowSelected,
    pdf,
    goToDo,
    goFiles,
    goEdit,
    odcs: state.rows,
    watchDocument,
    idModalRelatedDocs: idModalRelatedDocs.current,
    downloadExcel,
    breadcrumPath: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/inicio",
        text: "Compras",
      },
      {
        route: "",
        text: "Ordenes de compra",
      },
    ],
  };
}
