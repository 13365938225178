import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import useContact from "customHooks/useContact";
import { GetMyActivCustomersContacts } from "helpers/Apis/Documents";
import React, { useEffect, useState, MouseEventHandler } from "react";
import Select from "react-select";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;

  .saiko-select {
    width: 100%;
    margin: 0 15px 0 0;
  }
`;

/**
 * Render a select (and creatable) of the contacts that has a customer
 * @param {object} props - Props
 * @param {number} props.idCustomer - Id of the customer to fetch his contacts
 * @param {number} props.idContact - Id of the contact to show as default when component loads
 * @param {(object:OnChangeSelect)=>void} props.onChange - Information of the contact when select changes
 * @param {()=>void} props.onLoaded - Callbackk executed when list of contacts load
 * @param {object[]} props.options - Options to be rendered on the select
 * @param {object[]} props.contactsMemory - Contacts that should be appended that are not in database
 * @param {(object:MouseEventHandler)=>void} props.onNewContact - Callback executed when user press the plus icon to add a new one
 * @param {boolean} props.doCreate - Craeteas a contract or not
 * @returns {JSX.Element}
 */
export default function CreatableSimpleContact({
  idCustomer,
  idContact = null,
  onChange = () => {},
  onLoaded = () => {},
  onNewContact = () => {},
  options = [],
  contactsMemory = [],
  doCreate = true,
}) {
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      setContacts([]);

      const apiContacts = await GetMyActivCustomersContacts(idCustomer);

      const contactsFormatted = apiContacts.map((contact) => ({
        ...contact,
        label: contact.fullName,
      }));

      // console.log([...contactsMemory, ...contactsFormatted]);

      setContacts([...contactsMemory, ...contactsFormatted]);

      if (Object.keys(contactsFormatted).length === 0) {
        setContact(null);
        setIsLoading(false);
        return;
      }

      const contactToUse = contactsFormatted.find(
        (contact) => contact.contactID === idContact
      );

      setContact(contactToUse);

      onLoaded([...contactsMemory, ...contactsFormatted]);

      setIsLoading(false);
    })();
  }, [idCustomer]);

  useEffect(() => {
    if (options.length <= 0) return;

    setContacts(options);
    // setContact(options[0]);
  }, [options]);

  useEffect(() => {
    // setContacts(options);
    if (contacts.length <= 0) return;

    const contactToUse = contacts.find(
      (contact) => contact.contactID === idContact
    );

    setContact(contactToUse);
  }, [idContact, contacts]);

  return (
    <Container>
      {isLoading ? (
        <Spinner hidden={false} text={<LoadingText>Cargando</LoadingText>} />
      ) : (
        <>
          <Select
            options={contacts}
            value={contact}
            placeholder="Selecciona o crea"
            isClearable={true}
            classNamePrefix="saiko-select"
            className="saiko-select"
            onChange={(contact) => onChange(contact)}
          />
          {doCreate ? (
            <ButtonV2
              onClick={(e) => {
                e.preventDefault();
                onNewContact(e);
              }}
            >
              <span>+</span>
              <Icon icon="plus" />
            </ButtonV2>
          ) : null}
        </>
      )}
    </Container>
  );
}
