import React, { useState } from "react";
import { Label } from "structure/Document";
import scss from "./styles.module.scss";
import Select from "react-select";

/**
 * @param {import("./types").PropsTypeTicket} props - Props
 * @returns {JSX.Element}
 */
export default function TypeTicket({
  onChange = () => {},
  selected = "allOfThem",
}) {

  const [value,setValue] = useState(selected);

  const [options, setOptions] = useState([
    {
      value: "personals",
      label: "Mis tickets",
    },
    {
      value: "allOfThem",
      label: "Todos",
    }    
  ]);

  // const defaultValue = 

  /**
   * Handle when user changes the filter
   * @param {import("../../../../../server/models/tickets/paginate/types").TicketsFilterBy} type
   */
  const handleOnChange = (type) => {
    if (type === selected) return;
    onChange(type);
  };

  return (
    <div className={scss.typeTickets}>
      <Label>Tipo</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select w-100"
        placeholder={`Selecciona`}
        defaultValue={options.find((option) => option.value === selected)}
        value={options.find((option) => option.value === selected)}
        selected={options.find((option) => option.value === selected)}
        isClearable={false}
        isSearchable={true}
        options={options}
        onChange={(option) => handleOnChange(option.value)}
      />
    </div>
  );
}
