import { getBanks } from "helpers/Apis/Banks";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "../Spinner/Spinner";
import Creatable from "react-select/creatable";

/**
 * @param {import("./types").PropsBankSocialReason} props - Props
 * @returns {JSX.Element}
 */
export default function BankSocialReason({ onChange = () => {} }) {
  const [state, setState] = useState({
    isLoading: true,
    banks: [],
  });

  useEffect(() => {
    (async function () {
      const apiBanks = await getBanks();

      setState({
        ...state,
        isLoading: false,
        banks: apiBanks.map((bank) => ({
          ...bank,
          label: bank.shortName,
          value: bank.claveSat,
        })),
      });
    })();
  }, []);

  if (state.isLoading) return <Spinner hidden={false} text="Cargando bancos" />;

  return (
    <Creatable
      placeholder="Selecciona o escribe para crear"
      isClearable={false}
      classNamePrefix="saiko-select"
      className="saiko-select w-100"
      options={state.banks}
      onChange={(socialReason) => onChange(socialReason)}
      //   value={selected}
    />
  );
}
