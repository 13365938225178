import React, { useContext } from "react";
import { ContextContact } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { useRef } from "react";
import { Pagination } from "components/individual/Pagination";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { CustomerOverviewModal } from "components/general/CustomerOverviewModal";
import MoreInfoContact from "../MoreInfoContact";
export default function TableContacts() {
  const hook = useContext(ContextContact);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando contactos</LoadingText>}
      />
    );

  return (
    <div className={style.tableContacts}>
      <div className={style.header}>
        <p>Nombre</p>
        <p>Teléfono</p>
        <p>Móvil</p>
        <p>Email</p>
        <p>Puesto</p>
        <p>Estatus</p>
      </div>

      <div className={style.containerRows}>
        {hook.contacts.map((contact, i) => (
          <div key={`${key.current}-${i}`}>
            <input
              name={`${key.current}`}
              id={`${key.current}-${i}`}
              type="radio"
              className="d-none"
              onChange={(e) => hook.setContact(contact)}
            />
            <label htmlFor={`${key.current}-${i}`} className={style.row}>
              <MoreInfoContact id={contact.id}>
                <p className={`${style.moreInfo} hyperlink`}>{contact.name}</p>
              </MoreInfoContact>
              <p>
                {contact.phone.fullNumber === null
                  ? "ND"
                  : contact.phone.fullNumber}
              </p>
              <p>
                {contact.movil.fullNumber === null
                  ? "ND"
                  : contact.movil.fullNumber}
              </p>
              <p>{contact.email}</p>
              <p>{contact.position}</p>
              <p>{contact.isActive ? "Activo" : "Inactivo"}</p>
            </label>

            <div className={style.mobileItem}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Label>Nombre</Label>
                  <p>
                    {contact.name}{" "}
                    {contact.isActive ? "(Activo)" : "(Inactivo)"}{" "}
                  </p>
                </div>

                <ElipsisMobile onShow={() => hook.setContact(contact)}>
                  <CustomMenuItem>Más información</CustomMenuItem>
                  <CustomMenuItem onClick={hook.openEdit}>
                    Editar
                  </CustomMenuItem>
                  <CustomMenuItem onClick={hook.redirectToDo}>
                    To Do
                  </CustomMenuItem>
                </ElipsisMobile>
              </div>

              <div className="d-flex">
                <div className="mr-2">
                  <Label>Telefono</Label>
                  <p>
                    {contact.phone.fullNumber === null
                      ? "ND"
                      : contact.phone.fullNumber}
                  </p>
                </div>

                <div>
                  <Label>Movil</Label>
                  <p>
                    {contact.movil.fullNumber === null
                      ? "ND"
                      : contact.movil.fullNumber}
                  </p>
                </div>
              </div>

              <div>
                <Label>Email</Label>
                <p>{contact.email}</p>
              </div>

              <div>
                <Label>Posicion</Label>
                <p>{contact.position}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.currentPage}
        pages={hook.totalPages}
        onChange={hook.setPage}
      />
    </div>
  );
}
