import { ContextMaterials } from "pages/Materials";
import React, { useContext } from "react";

/**
 *
 * @param {import("../../../../../../../types/proyects/materials").DtoListMaterials} material
 */
export default function ValidationMessage(material) {
  const materials = useContext(ContextMaterials);

  if (
    typeof materials.grid[material.id]?.quantity === "string" &&
    materials.grid[material.id]?.provider === undefined
  )
    return <p className="text-danger font-weight-bold">Obligatorio</p>;

  return <></>;
}
