import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useAdministrationOdc from "customHooks/useAdministrationOdc";
import React, { createContext } from "react";
import Filter from "./Filter";
import Options from "./Options";
import Table from "./Table";
import usePermissions from "customHooks/usePermissions";
import ui from "./styles.module.scss";
import MobileFilter from "./MobileFilter";

/**
 * @type {import("react").Context<import("customHooks/useAdministrationOdc/types").useAdministrationOdcValuesI|undefined>}
 */
export const ContextOdc = createContext(undefined);
const { Provider } = ContextOdc;

export default function ODC() {
  const administrationOdc = useAdministrationOdc(undefined);

  usePermissions("f56602a3-be22-41c3-95a9-56bdcc4175bd");

  return (
    <div className={ui.container}>
      <Provider value={administrationOdc}>
        <div className={ui.header}>
          <BreadCrumV2 path={administrationOdc.breadcrumPath} />
          <Filter />
          <MobileFilter/>
        </div>

        <div className={ui.content}>
          <Options />
          <Table />
        </div>
      </Provider>
    </div>
  );
}
