import Icon from "components/individual/HeaderIcon";
import React, {
  useState,
  useRef,
  createContext,
  useEffect,
  useContext,
} from "react";
import buttonMenu from "components/individual/HeaderIcon/index.module.scss";
import styles from "structure/Menu/index.module.scss";
import SpecialProcessMenu from "structure/Menu/SpecialProcess";
import GeneralMenu from "./General";
import Sells from "./Sells";
import Reports from "./Sells/Reports";
import Administration from "./Administration";
import AdministrationReports from "structure/Menu/Administration/Reports";
import Profile from "structure/Profile";
import { Tools } from "./Tools";
import { GenericEmailModal } from "components/general/GenericEmailModal";
import LoginContext from "context/Login/LoginContext";
import { ModalRecentTcps } from "components/general/ModalRecentTcps";
import { HelpDesk } from "./HelpDesk";
import { Modal } from "components/general/Modal";
import { Tickets } from "structure/Tickets";
import { Setup } from "./Setup";
import { AdminSystem } from "./AdminSystem/AdminSystem";
import { Purchase } from "./Purchase/purchase";
import { PurchaseReports } from "./Purchase/Reports/purchaseReports";
import BanksMenu from "./Banks";
import Anticipos from "./Banks/Anticipos";
import BankReports from "./Banks/Reports";
import Add from "./Banks/Add";
import ConcilationMenu from "./Concilation";

const CONTEXT_INITIAL_VALUE = {
  closeMenu:()=>{},

  setMenuToDisplay:()=>{},
  openProfileConfiguration:()=>{},
  openTcpModal:()=>{},
  openTicketModal:()=>{},
  openTickets:()=>{},
  openEmailModal:()=>{},
}

/**
 * Context in order to control the menu of the system
 * @type {import("react").Context<import("./types").ContextMenuI>}
 */
export const MenuContext = createContext(CONTEXT_INITIAL_VALUE);
const { Provider } = MenuContext;

export default function Menu() {
  const [displayMenu, setDisplayMenu] = useState(false);
  const idModalProfile = useRef(window.crypto.randomUUID());

  const { userInfo } = useContext(LoginContext);

  /**
   * @type {import("./types").IndexedMenuI}
   */
  const MENUS = {
    general: <GeneralMenu />,
    banks:  <BanksMenu />,
    anticipos: <Anticipos />,
    procesos_especiales: <SpecialProcessMenu />,
    ventas: <Sells />,
    ventasReportes: <Reports />,
    purchase:  <Purchase />,
    purchaseReports: <PurchaseReports />,
    administracion: <Administration />,
    administracionReportes: <AdministrationReports />,
    herramientas: <Tools />,
    helpDesk: <HelpDesk />,
    datosExcel: <Setup />,
    adminSistemas: <AdminSystem />,
    conciliacion:<ConcilationMenu/>,
    reportBanks: <BankReports />,
  };
  /**
   * @type {React.MutableRefObject<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>}
   */
  const modalEmail = useRef(window.crypto.randomUUID());

  /**
   * @type {[import("./types").Menus,(toDisplay:import("./types").Menus)=>void]}
   */
  const [menuToDisplay, setMenuToDisplay] = useState("general");
  const refButton = useRef(null);
  const containerMenu = useRef(null);
  const idModalTcp = useRef(window.crypto.randomUUID());
  const idTickets = useRef(window.crypto.randomUUID());

  /**
   * Handle display the menu
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - Event
   */
  const handleDisplayMenu = (e) => {
    if (e.target === refButton.current) {
      setDisplayMenu(!displayMenu);
    }
  };

  const closeMenu = () => {
    setDisplayMenu(false);
    setMenuToDisplay("general");
  };

  const openProfileConfiguration = () => {
    const btn = document.getElementById(idModalProfile.current);
    if (btn) {
      btn.click();
      closeMenu();
    }
  };

  const openTicketModal = () => {
    const dom = document.getElementById("helpDeskModal");
    if (dom) dom.click();
    closeMenu();
  };

  const openEmailModal = () => {
    const dom = document.getElementById(modalEmail.current);

    if (dom) dom.click();
  };

  const openTickets = () => {
    document.getElementById(idTickets.current).click();
    closeMenu();
  };

  const openTcpModal = () => {
    const dom = document.getElementById(idModalTcp.current);
    if (dom) {
      dom.click();
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (containerMenu.current === null) return;

      let wasClickOnMenu = false;

      const pathDomHtml = e.composedPath();

      pathDomHtml.forEach((path) => {
        if (path === refButton.current) {
          wasClickOnMenu = true;
        }
      });

      if (!wasClickOnMenu) closeMenu();
    });
  }, [containerMenu]);

  return (
    <>
      <Provider
        value={{
          closeMenu,
          setMenuToDisplay,
          openProfileConfiguration,
          openEmailModal,
          openTicketModal,
          openTcpModal,
          openTickets,
        }}
      >
        <button
          ref={refButton}
          className={buttonMenu.btn}
          onClick={(e) => handleDisplayMenu(e)}
        >
          <Icon icon="menu" />
          {displayMenu ? (
            <div ref={containerMenu} className={styles.containerMenu}>
              {MENUS[menuToDisplay]}
            </div>
          ) : null}
        </button>

        {userInfo.length > 0 ? (
          <>
            <Profile idOpenModal={idModalProfile.current} />

            <GenericEmailModal
              propsOpenModal={{
                id: modalEmail.current,
                className: "d-none",
              }}
              cc={[userInfo[0].email]}
              validExtensions={[
                "pdf",
                "png",
                "xml",
                "jpeg",
                "pdf",
                "gif",
                "txt",
              ]}
            />
          </>
        ) : null}

        <Add />

        <ModalRecentTcps
          title="TC"
          width={50}
          propsOpenModal={{
            className: "d-none",
            id: idModalTcp.current,
          }}
        />

        <Modal
          title="Tickets pendientes"
          propsOpenModal={{
            className: "d-none",
            id: idTickets.current,
          }}
        >
          {() => <Tickets />}
        </Modal>
      </Provider>
      
    </>
  );
}
