import React from "react";
import { EditCustomer as Edit } from "../Editv2/EditCustomer";
import style from "../styles.module.scss";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";

export default function EditCustomer() {
  return (
    <div className={style.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: "/directorio/editar",
            text: "Editar",
          },
        ]}
      />

      <Edit />
    </div>
  );
}
