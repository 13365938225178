import React from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext } from "react";
import { parseDateToText } from "helpers/dates";

export default function Cotizacion() {
  const hook = useContext(ContextDocsRelated);

  const quote = hook.popUpInfo[1];

  if (quote === undefined || quote === null)
    return <EmptyCardMoreInfo label="cotización" />;

  return (
    <CardDocRelated
      id={quote.id}
      folio={quote.docNumber}
      endDate={parseDateToText(`${quote.expiration.yyyymmdd}:`)}
      customer={quote.customer.commercialName}
      startDate={parseDateToText(`${quote.startDate.yyyymmdd}:`)}
      email={quote.customer.email}
      phone={quote.customer.phone}
      importe={quote.amount.subtotal.text}
      iva={quote.amount.iva.text}
      total={quote.amount.total.text}
      currency={quote.currency.code}
      label="Cotizacion"
      concept={"#Factura"}
    />
  );
}
