import React, { useContext } from 'react';
import { Button } from '../../../../components/individual/buttons/Buttons';
import { Select } from '../../../../components/individual/inputs/Inputs';

import TableContext from '../../../../context/Table/TableContext';

export default function Filter() {

    const { refetch , setReload } = useContext(TableContext);

    const search = () => setReload(!refetch);

    return (
        <div className="d-flex align-items-end">
            <Select
                addTodos = {false}
                idSelect = 'filterFiles'
                nameSelect = 'filterFiles'
                options = {[
                    {value:1,text:'Disponible'},
                    {value:0,text:'Eliminado'}
                ]}
                textJSON = 'text'
                valueJSON = 'value'
                label = 'Estatus'
                className = "mr-3"
            />
            <Button
                id = 'searchFiles'
                text = 'Buscar'
                aditionalClasses = "mr-3"
                funcion = {search}
            />
        </div>
    )
}
