import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";

// BUTTONS
import {
  Button,
  Submit,
} from "../../../../components/individual/buttons/Buttons";

function EditOptions() {
  return (
    <div className="d-flex justify-content-end mt-4">
      <ButtonV2 className="w-100" type="submit">
        Actualizar
      </ButtonV2>
      {/* <Submit id="submitForm" text="Actualizar" /> */}
    </div>
  );
}

export default EditOptions;
