import { useEffect, useReducer } from "react";
import { reducer, INITIAL_STATE } from "./reducer";
import { Actions } from "./actions";
import {
  disassociateCxp,
  getBankAccountV2,
  getCxpsMovement,
  getMovement,
} from "helpers/Apis/Banks";
import { useRef } from "react";
import { Success } from "helpers/alerts";

/**
 *
 * @param {number} idMovement - Id of the movement
 */
export default function useCancelCxp(idMovement,onDisassociated = () => {},idBank=null) {
  /**
   * @type {[import("./types").StateCancelCxp,(object:import("./types").Action)=>{}]}
   */
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const id = useRef(window.crypto.randomUUID());

  /**
   * Set the spinner when client is fetching server dis-association
   * @param {boolean} isFetching - True if the client is waiting for the server response
   * @returns {void}
   */
  const setIsFetchingServer = (isFetching) =>
    dispatch({
      type: Actions.SET_IS_FETCHING_DISASSOCIATE,
      payload: isFetching,
    });

  const attemtpDisassociation = async () => {
    setIsFetchingServer(true);

    const idsCxps = Object.keys(state.itemsSelected).map(
      (idString) => +idString
    );

    const wasDisassociated = await disassociateCxp(idsCxps, idMovement);

    if (wasDisassociated) {
      document.getElementById(id.current)?.click();
      onDisassociated();
      Success(()=>{},'Des-asociado')
    }

    setIsFetchingServer(false);
  };

  /**
   * Open the modal in order to fetch the information
   * @returns {void}
   */
  const openModal = () =>
    dispatch({
      type: Actions.SET_OPEN_MODAL,
      payload: null,
    });

  useEffect(() => {
    (async function () {
      if (!state.isModalOpen) return;

      const [apiMovement, apiAssociations,apiBank] = await Promise.all([
        getMovement(idMovement),
        getCxpsMovement(idMovement),
        getBankAccountV2(idBank)
      ]);

      const parsedCxps = apiAssociations.map((association) => ({
        ...association,
        uuidFrontend: window.crypto.randomUUID(),
      }))

      dispatch({
        type:Actions.SET_BANK,
        payload:apiBank
      })

      dispatch({
        type: Actions.SET_INITIAL_INFO,
        payload: {
          associations: parsedCxps,
          movement: apiMovement,
        },
      });
    })();
  }, [state.isModalOpen, idMovement]);

  /**
   * Update the item selected in order to cancel the association(s)
   * @param {boolean} isChecked - True if the item is checked
   * @param {import("../../../../../../../types/movements").DtoCxpMovement} association - Information of the item
   */
  const handleOnClickAssociation = (isChecked, association) =>
    dispatch({
      type: Actions.SET_ITEM_SELECTED,
      payload: {
        isChecked,
        association,
      },
    });

  /**
   * Check if the item is on the list of cancelation queues
   * @param {number} idAssociation - Id of the association
   */
  const isCheckedItem = (idAssociation) =>
    state.itemsSelected[idAssociation] === undefined ? false : true;

  /**
   * Handle toggle/des-toggle all the items on the list
   * @param {boolean} isChecked - Value of the checkbox
   * @returns {void}
   */
  const handleToggleAll = (isChecked) =>
    dispatch({
      type: Actions.SET_CHECK_ALL,
      payload: isChecked,
    });

  /**
   * Check if the parent toggle must be selected according the children checkboxes of the associations
   * @returns {boolean}
   */
  const isCheckedParenToggle =
    Object.keys(state.itemsSelected).length > 0 ? true : false;

  /**
   * If true, means the user will attempt to dis-associate all the associations and cancel the movement, otherwise, will just dis-associate but not cancelling the egress
   * @type {boolean}
   */
  const isGonnaCancel =
    Object.keys(state.itemsSelected).length === state.associations.length
      ? true
      : false;
  return {
    openModal,
    associations: state.associations,
    movement: state.movement,
    isFetching: state.isFetching,
    title: state.title,
    handleOnClickAssociation,
    isCheckedItem,
    handleToggleAll,
    isCheckedParenToggle,
    isPerformingQuery: state.isPerformingQuery,
    isGonnaCancel,
    attemtpDisassociation,
    id,
    bank:state.bank
  };
}
