import { AllExecutives } from "helpers/apis";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "structure/Document";
import { Spinner } from "../Spinner/Spinner";

/**
 * Render a combo of the executives on the system
 * @param {import("./types").PropsExecutive} props - Props
 * @returns {JSX.Element}
 */
export default function ExecutivesV2({ onChange = () => {}, value = null }) {
  const [state, setState] = useState({
    isLoading: true,
    executives: [],
    executive: undefined,
  });

  const handleOnChange = (executive) => {
    setState((current) => ({
      ...current,
      executive,
    }));

    onChange({
      id: executive.value,
      name: executive.label.trim(),
    });
  };

  useEffect(() => {
    (async function () {
      const apiExecutives = await AllExecutives();

      const executive =
        value === null
          ? undefined
          : apiExecutives.find((executive) => executive.value === value);

      setState((current) => ({
        ...current,
        isLoading: false,
        executive,
        executives: apiExecutives.map((executive) => ({
          ...executive,
          label: executive.TEXT,
          value: executive.value,
        })),
      }));
    })();
  }, []);

  if (state.isLoading)
    return <Spinner text={"Cargando ejecutivos"} hidden={false} />;

  return (
    <div>
      <Label>Ejecutivo</Label>
      <Select
        options={state.executives}
        classNamePrefix="saiko-select"
        className="saiko-select"
        placeholder="Selecciona o escribe"
        value={state.executive}
        onChange={handleOnChange}
      />
    </div>
  );
}
