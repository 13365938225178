import { RedirectButton } from "components/individual/buttons/Buttons";
import BankContext from "context/Bank/BankContext";
import React, { useContext, useEffect } from "react";
import { IncomingContext } from "..";
import usePermissions from "customHooks/usePermissions";
import MoreInfoMovement from "structure/MoreInfoMovement";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import UpdateAccounting from "components/individual/UpdateAccounting";
import AssociateFiles from "./AssociateFiles";
import { ModalEmail } from "structure/EmailV2";
import { emailComplementFiles, getComplement } from "helpers/Apis/Banks";

/**
 * Render the options available for a selected movement
 * @returns {JSX.Element} Render the options available for a selected movement
 */
export default function Options() {
  const hook = useContext(IncomingContext);
  const context = useContext(BankContext);
  const { checkPermission } = usePermissions();

  useEffect(() => {
    if (hook.movement !== undefined && hook.movement !== null) {
      getComplement("pdf", hook.movement.id);
    }
  }, [hook.movement]);

  if (hook.isForAccounting)
    return (
      <>
        <UpdateAccounting
          type="I"
          operationGrid={hook.accountingGrid.operation}
          originalGrid={hook.accountingGrid.original}
        />

        <AssociateFiles />
      </>
    );

  if (hook.movement === null) return <></>;

  return (
    <>
      {hook.canCancel ? (
        <MoreInfoMovement
          onCancelated={() => hook.refetchData()}
          id={hook.movement.id}
          isForCancel={true}
        >
          <ButtonV2>Cancelar</ButtonV2>
        </MoreInfoMovement>
      ) : null}

      {hook.movement.status.id === 5 &&
      checkPermission("80e2c85e-c46e-4b13-850a-047ffb88cca7") ? (
        <RedirectButton
          text="Editar"
          link={`/v2/bancos/${
            hook.type === "ingreso" ? "ingresos" : "egresos"
          }/agregar?cuenta=${context.id}&movimiento=${hook.movement.id}`}
        />
      ) : null}

      <AssociateFiles />

      <ModalEmail
        files={async () => await emailComplementFiles(hook.movement.id)}
        idModule={hook.movement.id}
        idDirectory={hook.movement.customer.id}
        module="complemento"
      >
        <ButtonV2>
          <span>Enviar correo</span>
        </ButtonV2>
      </ModalEmail>
    </>
  );
}
