import InformativeTooltip from "components/individual/InfoTooltip";
import { Spinner } from "components/individual/Spinner/Spinner";
import useInvoiceUuid from "customHooks/useInvoiceUuid";
import React from "react";
import CustomerInfo from "structure/Customer";
import scss from "./styles.module.scss";
import { getDateFromUtc } from "helpers/dates";
import DocsRelatedV2 from "structure/DocsRelatedV2";
import { Tabs } from "components/general/Tabs/Tabs";
import LoadingText from "components/individual/TextLoading";
import { mnCurrency } from "helpers/money";
import { parseToFolio } from "helpers/documents";

/**
 * Render the information of the invoice reception
 * @param {import("./types").PropsReceptionOverview} props - Props
 * @returns {JSX.Element}
 */
export default function ReceptionOverview({
  uuid = "f4097d8b-1f9d-46d9-8a0d-b235045cd97d",
}) {
  const hook = useInvoiceUuid(uuid);

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando factura</LoadingText>}
        idSpinner="loadingDocument"
      />
    );

  if (hook.invoice !== undefined)
    return (
      <div className={scss.containerTabs}>
        <Tabs
          idContainerContent="overview"
          idContainerTabs="documents"
          tabButtons={{
            invoice: {
              text: hook.invoice.association.description,
              selected: true,
              component: (
                <div className={scss.overviewInvoiceReception}>
                  <div className={scss.containerInvoice}>
                    <h2>{hook.invoice.association.description}</h2>

                    <div className={scss.info}>
                      <span>Recepcionado por</span>
                      <span>{hook.invoice.uploadedBy}</span>
                    </div>

                    <div className={scss.info}>
                      <span>Recepcionado el</span>
                      <span>
                        {new Intl.DateTimeFormat("es-MX", {
                          dateStyle: "long",
                          timeStyle: "medium",
                          hour12: true,
                        }).format(getDateFromUtc(hook.invoice.uploadedDate))}
                      </span>
                    </div>

                    <div className={scss.info}>
                      <span>
                        Razon social
                        <InformativeTooltip>
                          Razon social de la empresa que <b>EMITIÓ</b> la
                          factura
                        </InformativeTooltip>
                      </span>

                      <span>
                        {hook.invoice.type.id === 2
                          ? hook.invoice.customer.socialReason
                          : hook.invoice.emiter.socialReason}
                      </span>
                    </div>
                    <div className={scss.info}>
                      <span>Folio</span>
                      <span>
                        {hook.invoice.type.id === 2
                          ? parseToFolio(+hook.invoice.folio)
                          : hook.invoice.folio}
                      </span>
                    </div>
                    <div className={scss.info}>
                      <span>Importe</span>
                      <span>
                        {hook.invoice.importe.text} {hook.invoice.currency}
                      </span>
                    </div>
                    <div className={scss.info}>
                      <span>Descuento</span>
                      <span>
                        {hook.invoice.discount.text} {hook.invoice.currency}
                      </span>
                    </div>
                    <div className={scss.info}>
                      <span>IVA</span>
                      <span>
                        {hook.invoice.iva.text} {hook.invoice.currency}
                      </span>
                    </div>
                    <div className={scss.info}>
                      <span>Total</span>
                      <span>
                        {hook.invoice.total.text} {hook.invoice.currency}
                      </span>
                    </div>

                    <div className={scss.info}>
                      <span>Saldo</span>
                      <span>
                        {mnCurrency(hook.invoice.saldo)} {hook.invoice.currency}
                      </span>
                    </div>

                    <div className={scss.info}>
                      <span>Concepto(s)</span>
                      <span>
                        {hook.invoice.concepts.map(
                          (concept) =>
                            `[${concept.type.description}] ${concept.description}`
                        )}
                      </span>
                    </div>
                  </div>

                  <div className={scss.containerCustomer}>
                    {hook.hasRelatedOc ? (
                      <CustomerInfo idCustomer={hook.idProvider} />
                    ) : (
                      <div className={scss.emptyCustomer}>
                        <h2>
                          {hook.hasRelatedOc
                            ? "El proveedor de la OC no esta registrado en el directorio"
                            : "No hay proveedor ni orden de compra relacionada a la factura"}
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              ),
            },
            docsRelated: {
              text: "Documentos relacionados",
              selected: false,
              component: (
                <DocsRelatedV2
                  id={typeof(hook.invoice.idPreinvoice)==='number' ? hook.invoice.idPreinvoice : hook.invoice.idOc}
                  isLegalDocumentId={false}
                />
              ),
            },
          }}
        />
      </div>
    );

  return <p>No hay factura disponible</p>;
}
