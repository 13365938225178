import React, { useContext, useEffect, useState } from "react";

import { Currency } from "../Inputs/EditQuote";
import useAddDocument from "../../../../../customHooks/useAddDocuments";

// INPUTS
import {
  SocialReason,
  TCP,
  Probability,
  CreditDays,
  CustomerContacts,
  InformativeReminderDate,
  ImportPrice,
  IvaPrice,
  TotalPrice,
  InformativeTCP,
} from "../Inputs/General";

import { Progress } from "../Inputs/General";

import DocExpirationDate from "../../../../../components/general/Forms/Dates/DocExpirationDate";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";

import { useParams } from "react-router";
import { ContainerDocInfo } from "../../InfoDocument/Styles";
import { Informative } from "../../Inputs/General/Index";
import { FourthRow, SevenItems } from "../../Inputs/General/Styles";

export default function EditQuote({ forwardRef, errors }) {
  const { LoadDocument, docInfo } = useAddDocument();

  LoadDocument();

  const {
    customerInfo,
    setCurrencyType,
    setCurrencyName,
    setExchangeTcp,
    tcp,
  } = useContext(AddDocumentContext);
  const { customerId } = useParams();

  const [expDate, setExpDate] = useState(null);

  useEffect(() => {
    if (docInfo === null) return;
    setExpDate(new Date(docInfo.parsedExpDate));
    setCurrencyType(docInfo.currencyID);
    setCurrencyName(docInfo.currencyDesc);
    setExchangeTcp(docInfo.protected);
  }, [docInfo, tcp]);

  return (
    <div className="container pb-4">
      {docInfo !== null ? (
        <>
          <ContainerDocInfo>
            <Informative label="ID" value={docInfo.document.id} />
            <Informative label="Tipo" value="Cotizacion" />
            <Informative label="No." value={docInfo.document.documentNumber} />
            <Informative
              label="status"
              value={docInfo.document.status.description}
            />
            <Informative
              label="Ejecutivo"
              value={docInfo.document.executive.fullName}
            />
          </ContainerDocInfo>

          <div className="row">
            <div className="col-6">
              <SocialReason
                disabled={true}
                label="Cliente"
                customer={customerInfo?.commercialName}
              />
            </div>
            <div className="col-6">
              <CustomerContacts
                idCustomer={+customerId}
                forwardRef={forwardRef}
                selected={docInfo?.idContact}
                label="Contactos *"
              />
            </div>
          </div>

          <h4 className="mt-3">Documentos relacionados</h4>
          <SevenItems>
            <Informative label="Origen" placeholder="ND" />

            <Informative
              value={docInfo.document.documentNumber}
              label="Cotizacion"
            />

            <Informative
              label="Pre-factura"
              value={docInfo.document.documents.preInvoice.id}
            />

            <Informative
              label="Factura"
              value={docInfo.document.documents.invoice.number}
            />

            <Informative
              label="Contrato"
              value={docInfo.document.documents.contract.number}
            />

            <Informative
              label="OC"
              value={docInfo.document.documents.oc.number}
            />

            <Informative label="Fact. proveedor" />
          </SevenItems>

          <SevenItems>
            <Informative
              label="No. contrato"
              value={docInfo.document.documents.contract.number}
            />

            <Informative
              label="Creacion"
              value={docInfo.document.createdDate.parsed}
            />

            <Informative
              label="Modificacion"
              value={docInfo.document.updatedDate.parsed}
              placeholder="Sin editar"
            />

            {expDate !== null ? (
              <DocExpirationDate
                id="expirationDate"
                name="expirationDate"
                selected={expDate}
                label="Vencimiento *"
                onChange={(date) => setExpDate(date)}
                forwardRef={forwardRef}
              />
            ) : null}

            <InformativeReminderDate date={expDate} forwardRef={forwardRef} />

            <Progress
              selected={docInfo?.idProgress}
              forwardRef={forwardRef}
              label="% Avanze *"
            />

            <Probability
              forwardRef={forwardRef}
              label="% Probabilidad *"
              selected={docInfo?.idProbability}
            />
          </SevenItems>

          <h4 className="mt-3">Importes</h4>
          <SevenItems>
            <Informative
              label="Moneda"
              value={docInfo.document.currency.code}
            />

            <TCP
              tcp={tcp.test}
              label="Tipo de cambio *"
              forwardRef={forwardRef}
            />

            <ImportPrice />

            <IvaPrice />

            <TotalPrice />

            <Informative label="Acreditado" value="$0.0" />

            <TotalPrice />
          </SevenItems>

          <FourthRow>
            <Informative label="Uso de CFDI" placeholder="NA" />

            <Informative id="payForm" label="Forma pago" placeholder="NA" />

            <Informative id="methodPay" label="Método pago" placeholder="NA" />

            <Informative id="partials" label="Parcialidades" placeholder="NA" />

            <Informative id="partiality" label="Parcialidad" placeholder="NA" />

            <CreditDays
              label="Dias de credito *"
              creditDays={docInfo?.creditDays}
              forwardRef={forwardRef}
              errors={errors}
            />

            <Informative
              id="isComplementQuote"
              label="Complemento"
              placeholder="No"
            />
          </FourthRow>
        </>
      ) : null}
    </div>
  );
}
