import useQueryParams from "customHooks/useQueryParams";
import { saveAs } from "file-saver";
import { getHistoricContracts } from "helpers/Apis/Documents";
import { useState, useEffect } from "react";

/**
 * @type {import("./types").StateHistoricalContracts}
 */
const INITIAL_STATE = {
  executive: null,
  executiveName: "Todos",
  status: null,
  uen: null,
  uenName:"Todos",
  sector: null,
  sectorName:"Todos",
  socialReason: "",
  isLoading: true,
  queryParamsLoaded: false,
  isDownloadingExcel: false,
  historicContracts: [],
  refetch: true,
};

/**
 * @type {import("./types").ReturnHistoricalContracts}
 */
export const ContextState = {
  ...INITIAL_STATE,
  setExecutive: () => {},
  setStatus: () => {},
  setUen: () => {},
  attemptSearch: () => {},
  setSocialReason: () => {},
  breadcrum: [],
  attemptDownloadExcel: async () => {},
  setSector: () => {},
  pillsFilterMobile: [],
};

/**
 *
 * @returns {import("./types").ReturnHistoricalContracts}
 */
export default function useHistoricalContracts() {
  const [state, setState] = useState(INITIAL_STATE);
  /**
   * @type {import("./types").DefaultFilters}
   */
  const defaultFilters = useQueryParams();

  useEffect(() => {
    const idExecutive =
      typeof defaultFilters.ejecutivo === "string"
        ? +defaultFilters.ejecutivo
        : null;

    const status =
      typeof defaultFilters.estatus === "string"
        ? defaultFilters.estatus
        : null;

    setState((current) => ({
      ...current,
      executive: idExecutive,
      queryParamsLoaded: true,
      status,
    }));
  }, []);

  useEffect(() => {
    (async function () {
      if (!state.queryParamsLoaded) return;

      setState((current) => ({
        ...current,
        historicContracts: [],
        isLoading: true,
      }));

      const apiHistoricContracts = await getHistoricContracts({
        executive: state.executive,
        sector: state.sector,
        socialReason: state.socialReason,
        uen: state.uen,
      });

      setState((current) => ({
        ...current,
        isLoading: false,
        historicContracts: Array.isArray(apiHistoricContracts)
          ? apiHistoricContracts
          : [],
      }));
    })();
  }, [state.queryParamsLoaded, state.refetch]);

  const attemptDownloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const excelHistoricContracts = await getHistoricContracts({
      executive: state.executive,
      sector: state.sector,
      socialReason: state.socialReason,
      uen: state.uen,
      excel: true,
    });

    if (excelHistoricContracts instanceof Blob) {
      saveAs(excelHistoricContracts, `Histórico de contratos.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  };

  const setExecutive = (executive, name) =>
    setState((current) => ({
      ...current,
      executive,
      executiveName: typeof executive === "number" ? name : "Todos",
    }));

  const setStatus = (status) =>
    setState((current) => ({
      ...current,
      status,
    }));

  const setUen = (uen,name) =>
    setState((current) => ({
      ...current,
      uen,
      uenName:name
    }));

  const setSocialReason = (socialReason) =>
    setState((current) => ({
      ...current,
      socialReason,
    }));

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const setSector = (sector,description) =>
    setState((current) => ({
      ...current,
      sector,
      sectorName:description
    }));

  /**
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/inicio",
      text: "Incio",
    },
    {
      route: "/ventas/historico-contratos",
      text: "Ventas",
    },
    {
      route: "/ventas/historico-contratos",
      text: "Histórico de contratos",
    },
  ];

  return {
    ...state,
    attemptDownloadExcel,
    setExecutive,
    breadcrum,
    setStatus,
    setUen,
    setSocialReason,
    attemptSearch,
    setSector,
    pillsFilterMobile: [
      {
        label: "Ejecutivo",
        value: state.executiveName,
      },
      {
        label: "UEN",
        value: state.uenName,
      },
      {
        label: "Sector de mercado",
        value: state.sectorName,
      },
    ],
  };
}
