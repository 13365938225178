import LoginContext from "context/Login/LoginContext";
import { Success, YesNoAlert } from "helpers/alerts";
import {
  deleteFilesBlobStorage,
  getAssociatedFilesV2,
} from "helpers/Apis/associateFilesV2";
import { useContext, useEffect, useState } from "react";
import { downloadFile } from "helpers/Apis/associateFilesV2";
import { toggleIndexedObject } from "helpers/lists";
import useDialog from "./useDialog";

/**
 * Information of the state
 * @type {import('types/typedef/customHooks/useAssociateFiles').stateUseAssocaiteFilesI}
 */
const initialState = {
  /**
   * Status in to fetch in the combo
   */
  status: 1,

  infoRow: null,

  refetch: false,

  actualPage:1,
  pages:0,

  files: [],

  isLoading: true,

  Table: null,

  isDeleting: false,

  isDownloading: false,

  filesSelected: {},
};

/**
 * Custom hoook to associate files
 * @param {import('types/typedef/customHooks/useAssociateFiles').useAsscoaiteFilesPropsI} props - Props to make work the association files
 */
export default function useAssociateFiles({ idTypeEntity, idRegister }) {
  const [state, setState] = useState(initialState);

  const dialogDelete = useDialog();

  const { userInfo } = useContext(LoginContext);

  useEffect(() => {
    const numberOfFilesSelected = Object.keys(state.filesSelected).length;

    if (numberOfFilesSelected === 1) {
      const keyOfFile = Object.keys(state.filesSelected)[0];

      setState((current) => ({
        ...current,
        infoRow: state.filesSelected[keyOfFile],
      }));
      return;
    }

    setState((current) => ({
      ...current,
      infoRow: null,
    }));
  }, [state.filesSelected]);

  /**
   * Update the status for the associated files
   * @param {-1|1|0} status - New status to use when filter
   * @returns {void}
   */
  const updateStatus = (status) => {
    setState({
      ...state,
      status,
      refetch: !state.refetch,
      filesSelected: {},
      infoRow: null,
    });
  };

  /**
   * Set the state in order to indicate if the system is deleting a file
   * @param {boolean} isDeleting - Flag
   * @returns {void}
   */
  const setIsDeleting = (isDeleting) =>
    setState({
      ...state,
      isDeleting,
    });

  /**
   * Update the flag "isDownloading"
   * @param {boolean} isDownloading - Flag to display a feedback on the spinner
   * @returns {void}
   */
  const setIsDownloading = (isDownloading) =>
    setState({
      ...state,
      isDownloading,
    });

  /**
   * Trigger search button
   * @returns {void}
   */
  const search = () => {
    setState({
      ...state,
      refetch: !state.refetch,
      filesSelected: {},
      actualPage:1,
      infoRow: null,
    });
  };

  useEffect(() => {
    (async function () {
      setState({
        ...state,
        files: [],
        isLoading: true,
      });

      const { actualPage, files, pages } = await getAssociatedFilesV2(
        idTypeEntity,
        idRegister,
        state.actualPage,
        state.status
      );

      setState({
        ...state,
        files,
        isLoading: false,
        actualPage,
        pages
      });
    })();
  }, [state.refetch,state.actualPage]);

  const deleteFiles = async () => {
    setIsDeleting(true);

    const idsOfFiles = Object.entries(state.filesSelected).map(
      ([key, file]) => file.idAssocaitedFile
    );

    const wasDeleted = await deleteFilesBlobStorage(idsOfFiles, {
      id: userInfo[0].userID,
      fullName: userInfo[0].fullName,
    });

    setIsDeleting(false);

    if (wasDeleted) search();
  };

  const promptDeleteFile = async () =>
    YesNoAlert(
      `¿Estas seguro de borrar ${state.infoRow.fileName}?`,
      async () => await deleteFiles()
    );

  const downloadFileAssociated = async () => {
    setIsDownloading(true);

    // const
    const querysToExecute = Object.entries(state.filesSelected).map(
      ([key, file]) =>
        downloadFile(file.idAssocaitedFile, file.fileName)
          .then((file) => {})
          .catch((e) => console.log(e))
    );

    const filesDownloaded = await Promise.all(querysToExecute);

    // await downloadFile(state.infoRow.idAssocaitedFile, state.infoRow.fileName);
    setIsDownloading(false);
  };

  /**
   *
   * @param {import("types/typedef/files").AssociatedFileI} file
   */
  const toggleFile = (file) => {
    const list = toggleIndexedObject(
      file.idAssocaitedFile,
      file,
      state.filesSelected
    );

    setState((current) => ({
      ...current,
      filesSelected: list,
    }));
  };

  const isAllowDownload =
    Object.keys(state.filesSelected).length >= 1 && state.status === 1;

  function checkIsDeletable() {
    if (Object.keys(state.filesSelected).length <= 0) return false;

    const isDeletableAvaialble = Object.entries(state.filesSelected).reduce(
      (isDeletable, [key, file]) => isDeletable && file.isFileRemovable && file.isAvailable,
      true
    );

    return isDeletableAvaialble;
  }

  const setPage = page => setState(current=>({
    ...current,
    actualPage:page
  }));

  return {
    status: state.status,
    setPage,
    updateStatus,
    isLoading: state.isLoading,
    areFiles: state.files.length >= 1 ? true : false,
    search,
    infoRow: state.infoRow,
    toggleFile,
    promptDeleteFile,
    isDeleting: state.isDeleting,
    downloadFileAssociated,
    isDownloading: state.isDownloading,
    table: state.Table,
    files: state.files,
    checked: state.filesSelected,
    isAllowDownload,
    isDeletable: checkIsDeletable(),
    dialogDelete,
    numberOfFilesChecked: Object.keys(state.filesSelected).length,
    deleteFiles,
    state
  };
}
