import { parseDateToText } from "helpers/dates";
import React from "react";
import { useContext } from "react";
import { IncomingContext } from "..";
// import MoreInfoV2 from "../MoreInfoV2";
import scss from "../styles.module.scss";
import { mnCurrency } from "helpers/money";
import MobileMovement from "pages/BanksV2/MobileMovement";
import MoreInfoMovement from "structure/MoreInfoMovement";
import ModalOverviewMovement from "structure/ModalOverviewMovement";

/**
 *
 * @param {import("../../../../../../server/helpers/validations/bank/movements/types").BankAccountMovementI} movement - Information of the movement
 * @returns {JSX.Element} A row
 */
export default function MovementRow(movement) {
  const hook = useContext(IncomingContext);

  return (
    <>
      <MobileMovement {...movement} />

      <input
        type="radio"
        name="incomings"
        className="d-none"
        id={`incoming-${movement.id}`}
        onChange={() => hook.selectMovement(movement)}
      />
      <label htmlFor={`incoming-${movement.id}`} className={scss.row}>
        {/* <ModalOverviewMovement idMovement={movement.id}>
          <p>{movement.folio}</p>
        </ModalOverviewMovement> */}

        <MoreInfoMovement
          id={movement.id}
          isForCancel={false}
          onCancelated={() => hook.refetchData()}
        >
          <p style={{ textDecoration: "underline" }}>{movement.folio}</p>
        </MoreInfoMovement>

        <p>{parseDateToText(`${movement.date}:`)}</p>
        <p>{mnCurrency(movement.import)}</p>
        {/* <p>{(movement.customer.socialReason || "NA")} / {movement.beneficiary.description || movement.customer.socialReason || "ND"}</p> */}
        <p>
          {movement.customer.socialReason || "NA"} /{" "}
          {movement.beneficiary.description || "NA"}
        </p>

        <p>{movement.status.description}</p>
      </label>
    </>
  );
}
