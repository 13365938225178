import React from "react";
import TextIcon from "../TextIcon";
import RedirectButton from "../Redirect";
import DocumentsLog from "structure/DocumentsLog";
import scss from "../index.module.scss";
import Back from "../Back";

export const AdminSystem = () => {
  return (
    <>
      <Back menu="general" text="Inicio > Administrador del sistema" />

      <RedirectButton
        permission="2e1d85de-44e6-4b14-800f-0452f090681d"
        text={<TextIcon icon="news" text="Avisos" />}
        to={"/avisos"}
      />

      <RedirectButton
        permission="9213d840-d16a-4478-a80b-f616b065c726"
        text={<TextIcon icon="gear" text="Parámetros" />}
        to={"/procesos-especiales/parametros"}
      />
      <RedirectButton
        permission="9b9d9d4c-e87c-4516-a971-f5251493c62d"
        text={<TextIcon icon="rols" text="Roles" />}
        to={"/roles"}
      />
      <RedirectButton
        permission="bb8b4c5a-ed0d-476c-9e36-b1b66b326105"
        text={<TextIcon icon="users" text="Usuarios" />}
        to={"/usuarios"}
      />

      {/* <RedirectButton
        text={<TextIcon icon="users" text="HTTPS Logs" />}
        to={"/logs"}
      /> */}

      <DocumentsLog>
        <p className={scss.item}>
          <TextIcon icon="chartBar" text="Log Sistema" />
        </p>
      </DocumentsLog>
    </>
  );
};
