import useDialog from "customHooks/useDialog";
import { saveAs } from "file-saver";
import { getRecentTcps, getTcExcel, searchTc } from "helpers/Apis/Banks";
import { dateToDbFormat } from "helpers/dates";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateTc}
 */
const INITIAL_STATE = {
  date: null,
  isLoading: true,
  isSearching: false,
  isOpen: false,
  tcs: [],
  searched: false,
  from: null,
  to: null,
  isDownloadingExcel: false,
};

const dummyFunction = () => {};

/**
 * @type {import("./types").ReturnUseTc}
 */
export const CONTEXT_INITIAL_STATE = {
  ...INITIAL_STATE,
  dialog: {},
  customClose: dummyFunction,
  customOpen: dummyFunction,
  updateDate: dummyFunction,
  setTo: () => {},
  setFrom: () => {},
  attemptSearch: () => {},
  canPerformSearch: false,
  downloadExcel:async()=>{}
};

export default function useTc() {
  const dialog = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      if (state.to !== null || state.from !== null) return;

      const tcs = await getRecentTcps();

      setState((current) => ({
        ...current,
        isLoading: false,
        tcs,
      }));
    })();
  }, [state.isOpen, state.to, state.from]);

  const customClose = () => {
    dialog.close();
    setState(INITIAL_STATE);
  };

  const customOpen = () => {
    dialog.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  /**
   * Update the date for the range on the search TC
   * @param {Date} date - Date
   * @returns {void}
   */
  const updateDate = (date) =>
    setState((current) => ({
      ...current,
      date,
    }));

  const attemptSearch = async () => {
    if (state.from === null || state.to === null) return;

    setState((current) => ({
      ...current,
      isSearching: true,
      searched: true,
    }));

    const tcs = await searchTc(
      dateToDbFormat(state.from),
      dateToDbFormat(state.to)
    );

    setState((current) => ({
      ...current,
      isSearching: false,
      tcs,
    }));
  };

  /**
   * Update the range from
   * @param {Date|null} from - Date for the range
   * @returns {void}
   */
  const setFrom = (from) => {
    if (from === null) {
      setState((current) => ({
        ...current,
        from,
        to: null,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      from,
    }));
  };

  /**
   * Update the range filter for to
   * @param {Date|null} to - To date range
   * @returns {void}
   */
  const setTo = (to) => {
    if (to === null) {
      setState((current) => ({
        ...current,
        to,
        from: null,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      to,
    }));
  };

  function canPerformSearch() {
    if (state.from === null) return true;

    if (state.to === null) return false;

    return true;
  }

  /**
   * Attempt download the excel report for TC
   */
  const downloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    /**
     * @type {import("../../../../../server/helpers/excel/types").ExcelTcType}
     */
    const type = state.to === null ? "recent" : "rangeDate";
    const from = state.from === null ? null : dateToDbFormat(state.from);
    const to = state.to === null ? null : dateToDbFormat(state.to);

    const excel = await getTcExcel(type, from, to);

    if (excel !== undefined) {
      saveAs(excel, "TC.xlsx");
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  };

  return {
    ...state,
    dialog,
    customClose,
    customOpen,
    updateDate,
    setFrom,
    setTo,
    attemptSearch,
    canPerformSearch: canPerformSearch(),
    downloadExcel
  };
}
