import Money from "components/general/Forms/Money";
import React from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";
import { useContext } from "react";

export default function Importe() {
  const hook = useContext(ContextEmitCreditNote);

  return (
    <div>
      <Label required={hook.importe <= 0}>Importe</Label>

      {hook.invoice === undefined ? (
        <p>Primero selecciona una factura</p>
      ) : (
        <Money
          onChange={(amount) => hook.updateAmount(amount)}
          placeholder="Escribe aquí, por ejemplo: $9.5"
          maxDecimals={4}
          min={0}
        />
      )}
    </div>
  );
}
