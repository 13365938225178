import { Modal } from "components/general/Modal";
import React from "react";
import useUser from "./hook";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Label } from "structure/Document";
import { parseDateToText } from "helpers/dates";

export default function UserInformation({ id, isOpen , onClose = () => {} }) {
  const hook = useUser({
    id,
    isOpen,
  });

  const title = hook.isLoading ? 'Cargando...' : `Más información - ${hook.user.fullname}`

  return (
    <Modal isOpen={isOpen} displayButton={false} title={title} onCloseModal={onClose}>
      {() =>
        hook.isLoading ? (
          <Spinner hidden={false} idSpinner="userLoading" text="Cargando" />
        ) : (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Label>Nombre</Label>
              <p>{hook.user.firstname}</p>
            </div>
            <div className="col-md-6 col-sm-12">
              <Label>Segundo nombre</Label>
              <p>{hook.user.middlename}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Apellido paterno</Label>
              <p>{hook.user.lastname1}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Apellido materno</Label>
              <p>{hook.user.lastname2}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Email</Label>
              <p>{hook.user.contact.email}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Nombre de usuario</Label>
              <p>{hook.user.username}</p>
            </div>

            <div className="col-12">
              <Label>Fecha nacimiento</Label>
              <p>{parseDateToText(hook.user.birthday,"long")}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Jefe directo</Label>
              <p>
                {hook.user.chief === null || hook.user.chief === undefined
                  ? "Sin jefe directo"
                  : hook.user.chief.fullname}
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <Label>Rol</Label>
              <p>{hook.user.rol.description}</p>
            </div>

            <div className="col-md-6 col-sm-12">
              <Label>Número de celular</Label>
              <p>+{hook.user.contact.phone.lada} {hook.user.contact.phone.number}</p>
            </div>
            <div className="col-md-6 col-sm-12">
              <Label>Rol tickets</Label>
              <p>{hook.user.rol.ticket}</p>
            </div>
          </div>
        )
      }
    </Modal>
  );
}
