import { mnCurrency } from "helpers/money";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import accountComboContext from "../accountComboContext";

export default function TC() {
  const context = useContext(accountComboContext);

  if (
    context.originAccount.information === null ||
    context.destinyAccount.information === null
  )
    return <></>;

  if (
    context.originAccount.information.currency ===
    context.destinyAccount.information.currency
  )
    return <></>;

  return (
    <div>
      <Label>TC</Label>
      <p>
        {context.destinyAccount.ammount === null ||
        context.destinyAccount.ammount === 0
          ? "Ingresa un importe destino"
          : mnCurrency(context.destinyAccount.tc)}
      </p>
    </div>
  );
}
