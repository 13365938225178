import useDialog from "customHooks/useDialog";
import { saveAs } from "file-saver";
import { getDocumentsLogs } from "helpers/Apis/Documents";
import { useState } from "react";

export default function useDocumentsLogs() {
  const dialog = useDialog();

  const [isDownloading, setIsDownloading] = useState(false);

  const attemptDownload = async () => {
    setIsDownloading(true);

    const excel = await getDocumentsLogs();

    if (excel !== undefined) {
      saveAs(excel, "Log Documentos.xlsx",);
    }

    setIsDownloading(false);
  };

  return {
    dialog,
    isDownloading,
    attemptDownload,
    setIsDownloading,
  };
}
