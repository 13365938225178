import React, { useContext } from "react";
import { ContextCreditNoteAccounting } from "..";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import UpdateAccounting from "components/individual/UpdateAccounting";

export default function Options() {
  const hook = useContext(ContextCreditNoteAccounting);

  if (hook.creditNote === undefined || hook.creditNote === null)
    return (
      <div className={ui.options}>
        <UpdateAccounting
          operationGrid={hook.gridAccounting.operation}
          originalGrid={hook.gridAccounting.original}
          type="NCE"
          onUpdated={() => hook.attemptSearch()}
        />
      </div>
    );

  return (
    <div className={ui.options}>
      <ButtonV2 onClick={hook.redirectToFiles}>
        <span>Archivos asociados</span>
      </ButtonV2>

      <ButtonV2
        disabled={hook.isDownloadingPdf}
        onClick={hook.handleDownloadPdf}
      >
        <span>PDF</span>
      </ButtonV2>

      <ButtonV2
        disabled={hook.isDownloadingXml}
        onClick={hook.handleDownloadXml}
      >
        <span>XML</span>
      </ButtonV2>

      <UpdateAccounting
        operationGrid={hook.gridAccounting.operation}
        originalGrid={hook.gridAccounting.original}
        type="NCE"
        onUpdated={() => hook.attemptSearch()}
      />
    </div>
  );
}
