import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React from "react";
import FormPosition, {
  Oc,
  Pos,
  DescriptionPosition,
  Cost,
  Sell,
  UenField,
  SatCodeField,
  SatUmField,
  FieldProyectStatus,
  Percentage,
  IvaContainer,
  IvaExemptField,
  IvaField,
  TotalCost,
  TotalSell,
  TotalCostIva,
  TotalSellIva,
  Id,
} from "structure/FormPosition";
import ui from "./styles.module.scss";

/**
 * Return the schema to create a position into a proyect
 * @param {import('structure/FormPosition/types').PropsFormPosition} props
 */
export default function FormPositionStructured(props) {
  const { labelButtonSubmit = "Agregar posición" } = props;

  return (
    <FormPosition {...props}>
      <Id />
      <div className="row">
        <Pos className="col-sm-12 col-md-6 mb-3" />
        <Oc className="col-sm-12 col-md-6 mb-3" />
      </div>

      <div className="row">
        <UenField className="col-sm-12 col-md-4 mb-3" />
        <SatCodeField className="col-sm-12 col-md-4 mb-3" />
        <SatUmField className="col-sm-12 col-md-4 mb-3" />
      </div>

      <div className="row">
        <DescriptionPosition className="col-12 mb-3" />
      </div>

      <IvaContainer>
        <div className="row align-items-start">
          <Cost className="col-sm-12 col-md-4 mb-3" />
          <div className={`col-sm-12 col-md-4 mb-3 ${ui.ivaContainer}`}>
            <IvaField className="d-none" />
            <TotalCostIva />
          </div>
          <TotalCost className="col-sm-12 col-md-4 mb-3" />
        </div>

        <div className="row align-items-start">
          <Sell className="col-sm-12 col-md-4 mb-3" />
          <div className={`col-sm-12 col-md-4 mb-3 ${ui.ivaContainer}`}>
            <IvaField className="d-none" />
            <TotalSellIva />
          </div>
          <TotalSell className="col-sm-12 col-md-4 mb-3" />
        </div>

        <div className="row">
          <div className={`col-sm-12 ${ui.positionStatus} mb-3`}>
            <FieldProyectStatus />
            <Percentage />
            <div>
              <IvaExemptField />
            </div>
          </div>
        </div>
      </IvaContainer>

      <div className={ui.buttonContainer}>
        <ButtonV2>
          <span>{labelButtonSubmit}</span>
          <Icon icon="save" />
        </ButtonV2>
      </div>
    </FormPosition>
  );
}
