import React from "react";
import { MobileMenuContext } from "../..";
import { useContext } from "react";
import NavigationItem from "../../NavigationItem";
import TcModal from "structure/TC";
import AddTc from "structure/AddTc";
import SendEmail from "structure/SendEmail";

export default function SpecialProcess() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "procesos_especiales") return <></>;

  return (
    <>

      <NavigationItem icon="home" label="Inicio > Procesos especiales" subMenu="general" />


      <SendEmail onSendedEmail={()=>hook.closeMenu()}>
        <NavigationItem icon="email" label="Enviar correo" />
      </SendEmail>
      
      <NavigationItem
        icon="support"
        label="Help Desk"
        link="/procesos-especiales/help-desk/tickets"
      />
      <NavigationItem icon="user" label="UEN" link="/uen" />
      <NavigationItem
        icon="user"
        label="Catalogo productos y servicios"
        link="/catalogo"
      />
      <NavigationItem icon="excel" label="Plantillas" subMenu="datosExcel"/>
      <TcModal>
        <NavigationItem icon="chartBar" label="TC" />
      </TcModal>

      <AddTc>
        <NavigationItem icon="chartBar" label="Agregar TC" />
      </AddTc>
    </>
  );
}
