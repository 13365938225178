/**
 * @type{import("./../../../../../../types/documentActions").DocumentActionsI}
 */
export const ACTIONS = {
  SET_INIT: "SET_INIT",
  SET_CONTACT: "SET_CONTACT",
  SET_CUSTOMER: "SET_CUSTOMER",
  SET_GENERATE_CONTRACT: "SET_GENERATE_CONTRACT",
  SET_MONEY_INFORMATION: "SET_MONEY_INFORMATION",
  SET_ITEMS: "SET_ITEMS",
  SET_COMMENTS: "SET_COMMENTS",
  SET_UNIT_COST: "SET_UNIT_COST",
  SET_MODAL: "SET_MODAL",
  SET_EDIT_ITEM: "SET_EDIT_ITEM",
  SET_DELETE_ITEM: "SET_DELETE_ITEM",
  SET_RECALCULATE_ITEMS: "SET_RECALCULATE_ITEMS",
  SET_CURRENT_COMMENT: "SET_CURRENT_COMMENT",
  SET_EDIT_COMMENT: "SET_EDIT_COMMENT",
  SET_DELETE_COMMENT: "SET_DELETE_COMMENT",
  SET_NEW_ITEM: "SET_NEW_ITEM",
  SET_EDIT_NEW_ITEM: "SET_EDIT_NEW_ITEM",
  SET_EDIT_CATALOGUE: "SET_EDIT_CATALOGUE",
  SET_DELETE_CATALOGUE_CHANGES: "SET_DELETE_CATALOGUE_CHANGES",
  SET_LOADING: "SET_LOADING",
  SET_CONTRACT_KEY: "SET_CONTRACT_KEY",
  SET_LOAD_DOCUMENT_CONCEPTS: "SET_LOAD_DOCUMENT_CONCEPTS",
  SET_CURRENT_CONCEPT: "SET_CURRENT_CONCEPT",
  SET_INTERFACE:"SET_INTERFACE",
  SET_CURRENCY_MONEY_DOCUMENT: "SET_CURRENCY_MONEY_DOCUMENT",
  SET_TC:"SET_TC",  
};
