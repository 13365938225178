import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import useCancelOdc from "customHooks/useCancelOdc";
import Icon from "components/individual/HeaderIcon";

/**
 * Render a button to cancel a preinvoice
 * @param {object} props - Props
 * @param {number} props.idOdc - Id of the document
 * @param {()=>void} props.onCancelled - Callback to execute when invoice is cancelled
 * @param {import("components/individual/ButtonsV2/types").ButtonV2Props} [props.props={}] - Props for the button html tag
 * @param {number|null} [props.status=null] - Status of the document
 * @returns {JSX.Element}
 */
export const CancelOdc = ({
  idOdc = null,
  onCancelled = () => {},
  props = {},
  status = null,
}) => {
  const state = useCancelOdc(idOdc, onCancelled);

  if (state.isCancelling) return <Spinner hidden={false} text="Cancelar" />;

  if (status === 10 || status === 11 || status === null)
    return (
      <ButtonV2 onClick={state.promptCancelOdc} {...props}>
        <span>Cancelar</span>
        <Icon icon="cancel" />
      </ButtonV2>
    );

  return <></>;
};
