import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MenuContext } from "..";
import scss from "../index.module.scss";
import usePermissions from "customHooks/usePermissions";

/**
 * Redirect button when click on the menu
 * @param {import('../types').RedirectButtonI} props
 * @returns {JSX.Element}
 */
export default function RedirectButton(props) {
  const { closeMenu, setMenuToDisplay } = useContext(MenuContext);

  const { checkPermission } = usePermissions();

  if (Object.keys(props).includes("permission")) {
    if (!checkPermission(props.permission)) return <></>;
  }

  return (
    <Link {...props} data-menu={true} className={scss.item}>
      <p
        className={scss.item}
        id={props.id}
        data-menu={true}
        onClick={() => {
          closeMenu();
          setMenuToDisplay("general");
        }}
      >
        {props.text}
      </p>
    </Link>
  );
}
