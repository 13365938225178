import { Search } from "components/individual/Search";
import { Spinner } from "components/individual/Spinner/Spinner";
import useConcepts from "customHooks/useConcepts";
import { mnCurrency } from "helpers/money";
import React from "react";
import { useState } from "react";
import { Label } from "structure/Document";
import scss from "./index.module.scss";
import Fuse from "fuse.js";
import { useEffect } from "react";

/**
 * Render a list of options to select the concept for the invoice reception
 * @param {import('./types').PropsInvoiceReceptionConcept} props - Props
 * @returns {JSX.Element}
 */
export const InvoiceReceptionConcept = ({
  currency = "USD",
  importe = 100,
  onChange = () => {},
}) => {
  const { isLoading, concepts, setConceptSelected, conceptSelected } =
    useConcepts(importe, currency, onChange);

  const [listParsed, setListParsed] = useState(concepts);

  useEffect(() => {
    setListParsed(concepts);
  }, [concepts]);

  useEffect(() => {
    if (conceptSelected === null) return;

    const parsedConcept = {
      ...conceptSelected,
      applied: {
        text: mnCurrency(importe),
        number: importe,
      },
      tc: {
        text: mnCurrency(1),
        number: 1,
      },
      importe: {
        text: mnCurrency(importe),
        number: importe,
      },
      currency: {
        code: currency,
        symbol: "$",
        description: `Moneda ${
          currency === "USD" ? "estadounidense" : "mexicana"
        }`,
      },
      isValid: true,
    };

    onChange(parsedConcept);
  }, [conceptSelected]);

  const handleOnSearch = (text) => {
    setConceptSelected(null);

    if (text === "") {
      setListParsed(concepts);

      return;
    }

    /**
     * @type {Fuse.IFuseOptions<T>}
     */
    const options = {
      keys: ["description"],
    };

    const fuse = new Fuse(concepts, options);

    const result = fuse.search(text);

    const parsed = result.map((searched) => searched.item);

    setListParsed(parsed);
  };

  if (isLoading) return <Spinner text="Cargando conceptos..." hidden={false} />;

  return (
    <div>
      <Label>Concepto</Label>
      <Search
        placeholder="Escribe aquí"
        onDelete={() => handleOnSearch("")}
        onSearch={(text) => handleOnSearch(text)}
      />
      <div className={scss.header}>
        <p>-</p>
        <p>Concepto</p>
        <p>Tipo</p>
        <p>Acreditar a factura</p>
      </div>

      {listParsed.map((concept) => (
        <label className={scss.row}>
          <p>
            <input
              type="radio"
              name="conceptList"
              checked={
                conceptSelected === null
                  ? false
                  : conceptSelected.id === concept.id
              }
              onChange={(e) => setConceptSelected(concept)}
            />
          </p>
          <p>{concept.description}</p>
          <p>{concept.type.description}</p>
          {conceptSelected === null ? (
            <p>-</p>
          ) : (
            <p>
              {conceptSelected.id === concept.id
                ? `${mnCurrency(importe)} ${currency}`
                : "-"}
            </p>
          )}
        </label>
      ))}
    </div>
  );
};
