import React from 'react';
import { ErrorMessage } from '../../../components/individual/inputs/Inputs';


function ErrosAdd({ errors }) {
    return (
        <>  
            <div className="col-2">

            </div>

            <div className="col-2">

                <ErrorMessage
                    message={errors["reference"]?.message}
                    id="error_reference"
                />
            </div>

            <div className=" col-4">

                <ErrorMessage message={errors["concept"]?.message} id="error_concept" />
            </div>

            <div className="col-2">
                <ErrorMessage
                    message={errors["importConcept"]?.message}
                    id="error_importConcept"
                />
            </div>

        </>
    )
}

export default ErrosAdd;