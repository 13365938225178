import React, { useContext } from "react";
import { ContextInvoice } from "../..";
import { Label } from "structure/Document";
import { Search } from "components/individual/Search";
import { noDecimals } from "helpers/money";

export default function DocumentNumber() {
  const { updateNoDocument, from, state } = useContext(ContextInvoice);

  return (
    <div>
      <Label>No. {from === "pedidos" ? "pedido" : "factura"}</Label>
      <Search
        type="number"
        value={state.search}
        hideSearchIcon={true}
        placeholder="Escribe aquí"
        onDelete={()=>updateNoDocument(null)}
        onChange={(data) => updateNoDocument(noDecimals(data))}
      />
    </div>
  );
}
