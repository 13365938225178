import Details from "components/general/Details";
import React, { Fragment, createContext, useRef } from "react";
import useEmailParameters from "../EmailParameters/useEmailParameters";
import { Spinner } from "components/individual/Spinner/Spinner";
import CommentBox from "components/individual/CommentBox";
import Update from "./Update";
import AutocompleteEmail from "components/individual/AutocompleteEmail";

/**
 * @type {import("react").Context<import("../EmailParameters/useEmailParameters/types").ReturnUseEmailParameters|undefined>}
 */
export const ContextReminderPayments = createContext(undefined);

/**
 * Render the body for the email reminder payments body
 * @param {import("./types").PropsReminderPayments} props - Props
 * @returns {JSX.Element}
 */
export default function EmailReminderPayments({ type = "recordatorioPagos1" }) {
  const hook = useEmailParameters({
    module: type,
  });

  /**
   * @type {import("./types").IndexedTitles}
   */
  const indexedName = useRef({
    recordatorioPagos1: "Regla 1",
    recordatorioPagos2: "Regla 2",
    recordatorioPagos3: "Regla 3",
    recordatorioPagos4: "Regla 4",
  });

  if (hook.isLoading)
    return <Spinner text={"Cargando plantilla"} hidden={false} />;

  return (
    <ContextReminderPayments.Provider value={hook}>
      <Details summary={indexedName.current[type]} open>
        <b>TO</b>
        <p>1. Contactos de la razón social marcados como "Contacto de pagos"</p>
        <p>2. Si no existe algun contacto, por default al correo del cliente</p>

        <b>CC</b>
        <p>1. Ejecutivo que realizó el timbre del pedido</p>
        <p>2. Correos atachados en el campo inferior</p>
        <AutocompleteEmail
          aditionalEmails={hook.cc}
          onChange={(emails) => hook.setCc(emails)}
          label="Correos con copia"
        />

        <b>Cuerpo del correo</b>
        <CommentBox
          value={hook.body}
          type={type}
          onChange={(content) => hook.setBody(content)}
        />

        <Update />
      </Details>
    </ContextReminderPayments.Provider>
  );
}
