import React from "react";
import { CuotasContext } from "..";
import { useContext } from "react";
import { Label } from "structure/Document";

export default function FeeCurrency() {
  const hook = useContext(CuotasContext);

  return (
    <div>
      <Label>Moneda</Label>
      <p>{hook.currency || "ND"}</p>
    </div>
  );
}
