import InformativeTooltip from "components/individual/InfoTooltip";
import React, { useContext } from "react";
import Toggle from "react-toggle";
import { Label } from "structure/Document";
import { RemindersContext } from "..";

export default function ReminderSms() {
  const { cellphone, sms, numberOfOptionsActive, updateSms } =
    useContext(RemindersContext);

  const blockToggle = numberOfOptionsActive <= 1 && sms ? true : false;

  return cellphone === true ? (
    <>
      <Label>
        SMS
        {numberOfOptionsActive <= 1 ? (
          <InformativeTooltip>
            No puede deseleccionar esta opción. Al menos un metodo de
            recordatorio es necesario
          </InformativeTooltip>
        ) : null}
      </Label>
      <div className="d-flex align-items-center">
        <Toggle
          style={{
            cursor: "not-allowed",
          }}
          disabled={blockToggle}
          checked={sms}
          onChange={(e) => updateSms(e.target.checked)}
        />
        <span style={{ margin: "0 0 0 10px" }}>
          <b>{sms ? "Si" : "No"}</b>
        </span>
      </div>
    </>
  ) : (
    <>
      <Label>
        SMS
        <InformativeTooltip>
          Debes registrar un número telefonico valido para esta opcion
        </InformativeTooltip>
      </Label>
      <div className="d-flex align-items-center">
        <Toggle
          style={{
            cursor: "not-allowed",
          }}
          checked={false}
          disabled={true}
        />
        <span style={{ margin: "0 0 0 10px" }}>
          <b>No</b>
        </span>
      </div>
    </>
  );
}
