import React, { createContext, useRef } from "react";
import Breadcrum from "pages/Administration/Invoice/Breadcrum";
import useAdministrationInvoice from "customHooks/useAdministrationInvoice";
import InvoiceFilter from "./Filter";
import InvoiceTable from "./Table";
import Options from "./Options";
import usePermissions from "customHooks/usePermissions";
import ui from "./styles.module.scss";
import FilterMobile from "structure/FilterMobile";
import SocialReasonSelect from "./Filter/SocialReasonSelect";
import DateFilter from "./Filter/DateFilter";
import StatusSelect from "./Filter/StatusSelect";
import DocumentNumber from "./Filter/DocumentNumber";
import AccountingStatusSelect from "./Filter/AccountingStatusSelect";
import { MONTHS, parseDateToText } from "helpers/dates";

const STATUS_LABEL = {
  4: "No facturado",
  5: "Facturado",
  6: "Cancelado",
  7: "CxC",
  8: "Cancelada",
  9: "Parcialmente cobrada",
  10: "Cobrada",
  20: "CxC/Parcial",
};

/**
 * Context of the component
 * @type {import("react").Context<import("customHooks/useAdministrationInvoice/types").ContextInvoice|undefined>}
 */
export const ContextInvoice = createContext(undefined);
const { Provider } = ContextInvoice;

/**
 * Render the list of
 * @param {import("./Table/types").InvoiceProps} props - Props
 * @returns {JSX.Element}
 */
export default function Invoice({
  from = "pedidos",
  renderAccounting = false,
}) {
  const valuesUseAdministrationInvoice = useAdministrationInvoice(from);

  /**
   * @type {import("./Table/types").IdsPermission}
   */
  const idToEvaluate = useRef({
    "pedidos":"dcf3e4a1-40df-4c3f-84bd-9de21c2b5699",
    "facturas_emitidas":"7d769fc2-aaee-4a22-a4e6-3b509a6ce4b6",
    "contabilidad":"d76c49d3-ead2-4776-bdde-9668f665c45f"
  });

  usePermissions(idToEvaluate.current[from||"contabilidad"]);

  /**
   * @type {import("components/individual/PillFilterMobile/types").PropsPillFilterMobile[]}
   */
  const filters =
    from === "contabilidad"
      ? [
          {
            label: "Año",
            value: valuesUseAdministrationInvoice.startDate.getUTCFullYear(),
          },
          {
            label: "Mes",
            value:
              MONTHS[valuesUseAdministrationInvoice.startDate.getUTCMonth()],
          },
          {
            label: "Estatus",
            value:
              valuesUseAdministrationInvoice.state.accountingStatus === null
                ? "Todos"
                : valuesUseAdministrationInvoice.state.accountingStatus === true
                ? "Contabilizados"
                : "No contabilizados",
          },
          {
            label: "Razón social",
            value:
              valuesUseAdministrationInvoice.state.customer === ""
                ? "Todos"
                : valuesUseAdministrationInvoice.state.customer,
          },
          {
            label: `No. factura`,
            value:
              valuesUseAdministrationInvoice.state.search >= 1
                ? valuesUseAdministrationInvoice.state.search
                : "Todos",
          },
        ]
      : [
          {
            label: "Desde",
            value: parseDateToText(valuesUseAdministrationInvoice.startDate),
          },
          {
            label: "Hasta",
            value: parseDateToText(valuesUseAdministrationInvoice.endDate),
          },
          {
            label: "Estatus",
            value:
              STATUS_LABEL[valuesUseAdministrationInvoice.status] || "Todos",
          },
          {
            label: `No. ${from === "pedidos" ? "pedido" : "factura"}`,
            value:
              valuesUseAdministrationInvoice.state.search >= 1
                ? valuesUseAdministrationInvoice.state.search
                : "Todos",
          },
        ];

  return (
    <div className={ui.module}>
      <Provider
        value={{ ...valuesUseAdministrationInvoice, from, renderAccounting }}
      >
        <div className={ui.header}>
          <Breadcrum from={from} />
          <div className={ui.margin}>
            <InvoiceFilter />
            <FilterMobile pills={filters}>
              <SocialReasonSelect />
              <DateFilter />
              <StatusSelect />
              <AccountingStatusSelect />
              <DocumentNumber />
            </FilterMobile>
          </div>
        </div>

        <div className={ui.margin}>
          <Options />
          <InvoiceTable />
        </div>
      </Provider>
    </div>
  );
}
