import React, { useContext } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Label } from "structure/Document";
import { ContextConsecutivePurchaseOrder } from "..";

registerLocale("es", es);

export default function RangeConsecutivePurchaseOrder() {
  const hook = useContext(ContextConsecutivePurchaseOrder);

  return (
    <>
      <div>
        <Label>Desde</Label>
        <ReactDatePicker
          selected={hook.from}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona"
          maxDate={hook.to}
          onChange={(date) => hook.updateStartRange(date)}
          isClearable={false}
        />
      </div>

      <div>
        <Label>Hasta</Label>
        <ReactDatePicker
          selected={hook.to}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona"
          minDate={hook.from}
          maxDate={new Date()}
          onChange={(date) => hook.updateEndRange(date)}
          isClearable={false}
        />
      </div>
    </>
  );
}
