import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { useParams } from "react-router-dom";
import React from "react";
import { AssociateFiles } from "structure/AssociateFiles";

export default function QuoteAssociatedFiles() {
  const { idCustomer, idDocument, nameDocument } = useParams();

  return (
    <div
      style={{
        height: "100%",
        overflow:"auto",
        padding:"0 0 1rem 0"
      }}
    >
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: `/directorio/documentos/${idCustomer}`,
            text: nameDocument.charAt(0).toUpperCase() + nameDocument.slice(1),
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]}
      />

      <div className="container">
        <AssociateFiles
          idRegister={+idDocument}
          idTypeEntity={1}
          pathUpload={`documentos/${nameDocument}/${nameDocument}-id-${idDocument}`}
        />
      </div>
    </div>
  );
}
