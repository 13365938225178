import React, { useContext, useEffect, useState } from "react";
import { IvasContextReport } from "..";
import { mnCurrency } from "helpers/money";

export default function IvaResult() {
  const hook = useContext(IvasContextReport);

  const [operation, setOperation] = useState(0);

  useEffect(() => {
    if (hook.totalIncomes === undefined || hook.totalOutcomes === undefined)
      return;

    setOperation(hook.totalIncomes.tcIva - hook.totalOutcomes.tcIva);
  }, [hook.totalIncomes, hook.totalOutcomes]);

  return (
    <p className={`font-weight-bold m-0`}>
      {hook.totalIncomes?.tcIva > hook.totalOutcomes?.tcIva ? "IVA por pagar " : "A favor "}
      {mnCurrency(operation)}
    </p>
  );
}
