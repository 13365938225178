import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ConcilationContext } from "..";

import { Spinner } from "components/individual/Spinner/Spinner";
import Icon from "components/individual/HeaderIcon";

export const UpdateConcilations = () => {
  const {
    canDisplayUpdateButton,
    gridChanged,
    attemptConcilationUpdate,
    isUpdating,
    triggerSearch,
  } = useContext(ConcilationContext);

  if (isUpdating)
    return (
      <Spinner
        hidden={false}
        idSpinner="concilation-spinner"
        text="Actualizando"
      />
    );

  // if (!canDisplayUpdateButton) return;

  if(!gridChanged) return <></>

  return (
    <ButtonV2
      onClick={async () => {
        const updated = await attemptConcilationUpdate();
        if (updated) {
          triggerSearch();
        }
      }}
    >
      <span>Actualizar</span>
      <Icon icon="save"/>
    </ButtonV2>
  );
};
