import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextPaymentReminder } from "../..";

export default function Search() {
  const hook = useContext(ContextPaymentReminder);

  return (
    <ButtonV2 onClick={hook.refetchReminders} disabled={hook.isLoading}>
      <span>Buscar</span>
    </ButtonV2>
  );
}
