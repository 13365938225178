import DynamicContactSocialReason from "components/general/DynamicContactSocialReason";
import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";

export const ContactsSections = () => {
  const {
    interfaceControl,
    customer,
    handleDocumentsContactChange,
    documents,
  } = useContext(WinQuoteContext);

  const ContactInput = {
    1: (
      <DynamicContactSocialReason
        contact={documents.quote.contact?.id}
        customer={customer.client.id}
        onChange={handleDocumentsContactChange}
        options={interfaceControl.contacts.client}
        doCreate={false}
      />
    ),
    2: (
      <DynamicContactSocialReason
        contact={documents.order.contact?.id}
        customer={customer.client.id}
        onChange={handleDocumentsContactChange}
        options={interfaceControl.contacts.client}
        doCreate={false}
      />
    ),
    3: (
      <DynamicContactSocialReason
        contact={documents.odc.contact?.id}
        customer={customer.provider?.id}
        onChange={handleDocumentsContactChange}
        options={interfaceControl.contacts.provider}
        doCreate={false}
      />
    ),
    6: interfaceControl.needContract ? (
      <DynamicContactSocialReason
        contact={documents.contract.contact?.id}
        customer={customer.client.id}
        onChange={handleDocumentsContactChange}
        options={interfaceControl.contacts.client}
        doCreate={false}
      />
    ) : (
      <input
        type="text"
        className="disable"
        placeholder="No contrato"
        // value={formateDate(new Date())}
        disabled
      />
    ),
  };
  return (
    <>
      {
        ContactInput[
          interfaceControl.menu[interfaceControl.indexActiveMenu].idType
        ]
      }
    </>
  );
};
