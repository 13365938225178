import React from "react";
import { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";

import { QuoteDateSection } from "./Dates/QuoteDateSection";
import { OrderDateSection } from "./Dates/OrderDateSection";
import { OdcDateSection } from "./Dates/OdcDateSection";
import { ContractDateSection } from "./Dates/ContractDateSection";

export const DatesSections = () => {
  const { interfaceControl } = useContext(WinQuoteContext);

  const DatesInputs = {
    1: <QuoteDateSection />,
    2: <OrderDateSection />,
    3: <OdcDateSection />,
    6: <ContractDateSection />,
  };
  return (
    <>
      {
        DatesInputs[
          interfaceControl.menu[interfaceControl.indexActiveMenu].idType
        ]
      }
    </>
  );
};
