import React, { useState } from "react";
import { ModalDismissButton } from "../../../../components/individual/buttons/Buttons";
import {
  ErrorMessage,
  InputNumber,
} from "../../../../components/individual/inputs/Inputs";
import { getCP } from "../../../../helpers/apis";
import { v4 as uuid } from "uuid";
import { Label } from "structure/Document";

export default function ModalCP({
  value = null,
  forwardRef = {},
  name = "postalCode",
  id = uuid(),
  onClick = () => {},
  onChange = () => {},
  errors = {},
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [cpInfo, setCpInfo] = useState({
    municipio: "",
    estado: "",
    cp: 0,
  });

  const selectInfoCp = (suggestion) => {
    onClick({
      colonia: suggestion.colonia,
      municipio: cpInfo.municipio,
      estado: cpInfo.estado,
      cp: cpInfo.cp,
    });
    // resetState();
    document.getElementById("closeCP").click();
  };

  const resetState = () => {
    setCpInfo("");
    setSuggestions([]);
  };

  return (
    <>
      <button
        id="openListCP"
        type="button"
        className="d-none"
        data-toggle="modal"
        data-target="#listCP"
      ></button>

      <div>
        <Label>Código postal</Label>
        <input
          type={"text"}
          maxLength="5"
          id={id}
          name={name}
          ref={forwardRef}
          placeholder="Escribe aquí"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {/* <InputNumber
          decimal={false}
          defaultValue={value}
          labelTxt={label}
          placeholderTxt={placeholder}
          forwardRef={forwardRef}
          name={name}
          id={id}
          // keyUp={(e) => showSuggestions(e.target.value)}
        /> */}
        <ErrorMessage id={uuid()} message={errors[name]?.message} />
      </div>

      <div
        className="modal fade"
        id="listCP"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <p
                id="infoCPSelection"
                className="font-weight-bold my-0"
              >{`${cpInfo.estado} - ${cpInfo.municipio} - ${cpInfo.cp}`}</p>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id="listColonys">
              {suggestions.map((colony) => {
                return (
                  <p
                    className="cp-option"
                    onClick={(e) => selectInfoCp(colony)}
                  >
                    {colony.colonia}
                  </p>
                );
              })}
            </div>
            <div className="modal-footer">
              <ModalDismissButton
                idBtn="closeCP"
                text="Cancelar"
                callback={resetState}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
