import React from "react";
import style from "../styles.module.scss";
import SocialReason from "../SocialReason";
import TotalInvoice from "../TotalInvoice";
import Residue from "../Residue";
import Currency from "../Currency";

export default function InvoiceOverview() {
  return (
    <div className={style.invoiceOverview}>
      <SocialReason />
      <TotalInvoice />
      <Residue />
      <Currency />
    </div>
  );
}
