import React, { useContext, useRef } from "react";
import { ContextOdc } from "..";
import GridLaoding from "components/individual/GridLoading";
import ui from "./styles.module.scss";
import { parseDateToText } from "helpers/dates";
import { add } from "date-fns";
import { Pagination } from "components/individual/Pagination";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import MobileItem from "./Mobile";
import LoginContext from "context/Login/LoginContext";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

const Header = () => (
  <div className={ui.header}>
    <p>No. OC</p>
    <p>Cliente</p>
    <p>Proveedor</p>
    <p>Ejecutivo</p>
    <p>Moneda</p>
    <p>Total</p>
    <p>Registro</p>
    <p>Enviada</p>
    <p>Estatus</p>
  </div>
);

export default function Table() {
  const {
    isFetchingData,
    recordsWereFounded,
    odcs,
    setRowSelected,
    page,
    setPage,
    totalPages,
  } = useContext(ContextOdc);

  const key = useRef(window.crypto.randomUUID());

  const { userInfo } = useContext(LoginContext);

  if (Array.isArray(userInfo) === false) return <></>;

  if (userInfo.length <= 0) return <></>;

  if (isFetchingData)
    return (
      <>
        <div className={ui.loadingSpinner}>
          <Spinner text={<LoadingText>Buscando</LoadingText>} hidden={false} />
        </div>

        <div className={ui.table}>
          <Header />
          <div className={ui.containerRows}>
            <GridLaoding />
          </div>
        </div>
      </>
    );

  if (!recordsWereFounded)
    return (
      <h1 className="text-center">
        No se encontraron ordenes de compra con los filtros solicitados
      </h1>
    );

  return (
    <div className={ui.table}>
      <Header />

      <div className={ui.containerRows}>
        {odcs.map((odc, i) => (
          <div key={`${key.current}-${i}`}>
            <input
              type="radio"
              className="d-none"
              onChange={(e) => setRowSelected(odc)}
              name={`${key.current}`}
              id={`${key.current}-${i}`}
            />
            <label htmlFor={`${key.current}-${i}`} className={ui.row}>
              <ModalDocsRelatedV2 fromLegal={false} idDocument={odc.id}>
                <p className={ui.moreInfo}>{odc.numeroDocumento}</p>
              </ModalDocsRelatedV2>
              <p>{odc?.orderInfo?.shortName||"NA"}</p>
              <p>{odc.customer.socialReason}</p>
              <p>{odc.iniciales}</p>
              <p className="text-center">{odc.moneda}</p>
              <p className="text-right">{odc.total.texto}</p>
              <p className="text-center">
                {parseDateToText(
                  add(new Date(odc.registro.yyyymmdd), {
                    days: 1,
                  })
                )}
              </p>
              <p className="text-center">
                {odc.envio.yyyymmdd === "ND"
                  ? "ND"
                  : parseDateToText(
                      add(new Date(odc.envio.yyyymmdd), {
                        days: 1,
                      })
                    )}
              </p>
              <p>{odc.estatus}</p>
            </label>

            <MobileItem {...odc} />
          </div>
        ))}
      </div>

      <Pagination actualPage={page} pages={totalPages} onChange={setPage} />
    </div>
  );
}
