import { useEffect, useState } from "react";
import { getCxcV2 } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { addIncoming, getMovement } from "helpers/Apis/Banks/index";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";
import { useHistory } from "react-router-dom";
import { truncateDecimals } from "helpers/money";
import { getMovementAssociationTolerance } from "helpers/Apis/parameters";

/**
 * @type {import("./types").StateCreateIncoming} State of the component
 */
const INITIAL_STATE = {
  factoraje:0,
  applyFactoraje:false,
  beneficiary: {
    id: null,
    description: "",
  },
  tolerance:0,
  concepts: {
    egresoPorVenta: 0,
    ventaActivos: 0,
    others: {},
    total: 0,
    invoices: [],
  },
  amounts: {
    toAssociate: 0,
    associated: 0,
  },
  ammount: 0,
  toAssociate: 0,
  associated: 0,
  customer: undefined,
  cxcs: [],
  isLoading: false,
  search: "",
  gridAssociations: {},
  isCreating: false,
  payMethod: null,
  date: new Date(),
  maxDate: new Date(),
  movement: {
    id: null,
    info: null,
  },
};

/**
 * Handle the creation of an incoming
 * @param {import("../../../../server/models/banks/types").BankAccountI} account - Information of the bank account that's being used
 * @param {number} id - Id of the bank account
 * @param {number} movement - Id of the movement
 * @param {import("./types").ModuloIngreso} modulo - Indicate from which module of the system was accesed this screen to create the redirections once operation it's finished
 * @returns {import("./types").ReturnUseCreateIncoming}
 */
export default function useCreateIncoming(
  account = null,
  id = null,
  movement = null,
  modulo = undefined
) {
  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  const setBeneficiary = (id, beneficiary) =>
    setState((current) => ({
      ...current,
      beneficiary: {
        id: null,
        description: beneficiary,
      },
    }));

  /**
   *
   * @param {number} ammount - Amount to use on the grid
   * @returns
   */
  const updateAmount = (ammount) => {
    setState((current) => ({
      ...current,
      ammount,
    }));
  };

  useEffect(() => {
    (async function () {
      if (movement === null || movement === undefined || isNaN(movement))
        return;

      const apiMovement = await getMovement(movement);

      const customerToUse =
        apiMovement.customer.id === null ? undefined : apiMovement.customer.id;

      setState((current) => ({
        ...current,
        ammount: apiMovement.residue,
        customer: customerToUse,
        date: new Date(apiMovement.date),
        payMethod: apiMovement.payMethod.id,
        movement: {
          id: movement,
          info: apiMovement,
        },
      }));
    })();
  }, [movement]);

  useEffect(() => {
    if (state.ammount > 0) return;

    setState((current) => ({
      ...current,
      customer: undefined,
      cxcs: [],
      gridAssociations: {},
      concepts: {
        egresoPorVenta: 0,
        ventaActivos: 0,
        others: {},
        total: 0,
        invoices: [],
      },
    }));
  }, [state.ammount]);

  useEffect(()=>{
    const cxcTab = document.getElementById('tab-cxcs');
    const conceptTab = document.getElementById('tab-concepts');

    if(state.beneficiary.description.length>0 && typeof(state.customer)!=="number"){
      cxcTab?.classList.add('d-none');
      conceptTab?.click();
      return
    }


    cxcTab?.click();
    cxcTab?.classList.remove('d-none');
    // conceptTab?.classList

  },[state.beneficiary,state.customer,state.ammount,state.payMethod])

  useEffect(() => {
    (async function () {
      if (
        account === null ||
        state.customer === undefined ||
        state.customer === null
      ) {
        return;
      }

      setState((current) => ({
        ...current,
        cxcs: [],
        isLoading: true,
      }));

      const cxcs = await getCxcV2(state.customer, account.currency);

      console.log({cxcs});

      setState((current) => ({
        ...current,
        cxcs,
        isLoading: false,
      }));
    })();
  }, [account, state.customer]);

  useEffect(() => {
    const isGrid = Object.keys(state.gridAssociations).includes("filteredGrid");

    if (!isGrid) return;

    const conceptsAssociations =
      state.gridAssociations.filteredGrid.array.reduce(
        (indexed, association) => {
          if (association.invoice.concept.id === 7) {
            return {
              ...indexed,
              ventaActivos: indexed.ventaActivos + association.applied.number,
            };
          }

          if (association.invoice.concept.id === 8) {
            return {
              ...indexed,
              egresoPorVenta:
                indexed.egresoPorVenta + association.applied.number,
            };
          }

          return indexed;
        },
        {
          egresoPorVenta: 0,
          ventaActivos: 0,
        }
      );

    setState((current) => ({
      ...current,
      concepts: {
        ...current.concepts,
        ...conceptsAssociations,
      },
    }));
  }, [state.gridAssociations]);

  useEffect(()=>{
    (async function(){
      const tolerance = await getMovementAssociationTolerance();

      setState(current=>({
        ...current,
        tolerance
      }));
    })()
  },[]);

  /**
   * Update the id of the customer
   * @param {number|null} customer - Id of customer
   */
  const setCustomer = (customer) => {
    setState((current) => ({
      ...current,
      customer: customer === null ? undefined : customer,
      toAssociate: 0,
      associated: 0,
      cxcs: customer === null ? [] : current.cxcs,
      gridAssociations: customer === null ? {} : current.gridAssociations,
      concepts: {
        ...current.concepts,
        egresoPorVenta: customer === null ? 0 : current.concepts.egresoPorVenta,
        ventaActivos: customer === null ? 0 : current.concepts.ventaActivos,
        invoices: customer === null ? [] : current.concepts.invoices,
      },
    }));
  };

  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  /**
   * Update the date the incoming
   * @param {Date} date - Date to use for the incoming created
   * @returns {void}
   */
  const updateDate = (date) =>
    setState((current) => ({
      ...current,
      date,
    }));

  const updateGridAssociations = (gridAssociations) => {
    const concepts = gridAssociations.filteredGrid.array.map((cxc) => ({
      id: cxc.invoice.concept.id,
      import: {
        ammount: cxc.import.number,
        currency: cxc.currency,
      },
      applied: {
        ammount: cxc.applied.number,
        currency: account.currency,
      },
      partialitie: cxc.partialitie,
      idInvoice: cxc.invoice.id,
    }));

    setState((current) => ({
      ...current,
      gridAssociations,
      associated:
        gridAssociations.filteredGrid.array.length <= 0
          ? 0
          : state.ammount - gridAssociations.newResidue,
      toAssociate:
        gridAssociations.filteredGrid.array.length <= 0
          ? 0
          : gridAssociations.newResidue,
      concepts: {
        ...current.concepts,
        invoices: concepts,
      },
    }));
  };

  const attemptCreateAssociation = async () => {
    const DtoMovement = {
      amount: state.ammount,
      bankAccount: id,
      concept: "Ingreso",
      createdBy: "Test",
      movementDate: dateToDbFormat(dateWithCeroHours(state.date)),
      movementType: "ingreso",
      customer: state.customer,
      payMethod: state.payMethod,
      reference: "",
      beneficiary:state.beneficiary,
      factoraje:state.factoraje
    };

    const concepts = Object.entries(state.concepts.others).map(
      ([key, value]) => ({
        id: +key,
        import: value,
      })
    );

    if (state.concepts.egresoPorVenta > 0) {
      concepts.push({
        id: 7,
        import: state.concepts.egresoPorVenta,
      });
    }

    if (state.concepts.ventaActivos > 0) {
      concepts.push({
        id: 8,
        import: state.concepts.ventaActivos,
      });
    }

    const DTO = {
      cxc:
        state.gridAssociations?.filteredGrid?.array?.map((item) => ({
          ...item,
          TC: item.tc.number,
          aplicacion: item.applied.number,
          uuid: item.uuid,
          importeAplicado: item.import.number,
          importe: { number: item.total.number },
          saldoActual: { number: item.residue.number },
          saldoNuevo: { number: item.newResidue.number },
          acumulated: {
            number: item.total.number - item.residue.number,
            text: item.total.number - item.residue.number,
          },
        })) || [],
      idClient: state.customer === undefined ? null : state.customer,
      movement: DtoMovement,
      concepts,
      idMovement: movement,
      ammount: state.ammount,
      idBankAccount: id,
      beneficiary:state.beneficiary,
      movementDate: dateToDbFormat(dateWithCeroHours(state.date)),
      factoraje:state.factoraje
    };

    setState((current) => ({
      ...current,
      isCreating: true,
    }));

    const wasCreated = await addIncoming(DTO);

    const redirectLink =
      modulo === "movimientos" || modulo === undefined
        ? `/v2/bancos/ingresos?cuenta=${id}`
        : `/v2/bancos/anticipos/clientes`;

    if (wasCreated) {
      history.push(redirectLink);
    }

    setState((current) => ({
      ...current,
      isCreating: false,
    }));
  };

  /**
   * Update the pay method
   * @param {number} code - Code
   * @returns {void}
   */
  const updatePayMethod = (code) => {
    setState((current) => ({
      ...current,
      payMethod: code,
    }));
  };

  /**
   * Check if the concept of "No identificado" is correct (business rules)
   * @returns {boolean}
   */
  function checkIsValidNoIdentified() {
    // Busca anticipo no identificado
    const isConceptNoIdentified = Object.keys(state.concepts.others).includes(
      "10"
    );

    // No hay concepto "No identificado" , OK
    if (!isConceptNoIdentified) return true;

    // Escogieron un cliente, no es valido
    if (state.customer !== undefined) return false;

    // El 100% del importe no es igual al "No identificado"
    if (
      state.concepts.total !== state.concepts.others["10"] ||
      state.concepts.others["10"] !== state.ammount
    )
      return false;

    return true;
  }

  function checkIsValidConceptCustomer() {
    let conceptList = { ...state.concepts.others };

    delete conceptList["10"];
    delete conceptList["12"];
    delete conceptList["20"];

    const remainConceptsCounter = Object.keys(conceptList).length;

    // No customer, this is valid
    if (state.customer === undefined) return true;

    // Customer selected and is associating to a concept different of the business rules, invalid
    if (state.customer !== undefined && remainConceptsCounter > 0) return false;

    // OK
    return true;
  }

  /**
   * Check if the concept of "Anticipo" is correct (business rules)
   * @returns {boolean}
   */
  function checkIsValidAdvance() {
    // Busca concepto de anticipo
    const isAdvanceOnList = Object.keys(state.concepts.others).includes("12");

    // No hay concepto de anticipo
    if (!isAdvanceOnList) return true;

    // No ha seleccionado cliente para el anticipo
    if (state.customer === undefined) return false;

    return true;
  }

  function checkIfIsValidAssociation() {
    if (state.payMethod === null) return false;

    if (
      state.ammount === 0 &&
      truncateDecimals(state.amounts.toAssociate, 2) === 0
    )
      return false;

    if (
      state.ammount > 0 &&
      truncateDecimals(state.amounts.toAssociate, 2) === 0
    )
      return true;
    return false;
  }

  /**
   * Update the ammount to apply to the concept
   * @param {number} id - Id of the concept
   * @param {number} ammount - Ammount to apply to the concept
   * @returns {void}
   */
  const updateConceptAmmount = (id, ammount) => {

    if (ammount === 0) {
      setState((current) => {
        let unrefCurrent = { ...current.concepts.others };
        delete unrefCurrent[id];

        return {
          ...current,
          concepts: {
            ...current.concepts,
            others: unrefCurrent,
          },
        };
      });
      return;
    }

    setState((current) => ({
      ...current,
      concepts: {
        ...current.concepts,
        others: {
          ...current.concepts.others,
          [id]: ammount,
        },
      },
    }));
  };

  useEffect(() => {
    const summatory = Object.entries(state.concepts.others).reduce(
      (summatory, [key, value]) => summatory + value,
      0
    );

    setState((current) => ({
      ...current,

      concepts: {
        ...current.concepts,
        total: summatory,
      },
    }));
  }, [state.concepts.others]);

  const setBeneficiarySocialReason = socialReason => setState(current=>({
    ...current,
    beneficiary:{
      id:null,
      description:socialReason
    }
  }))

  useEffect(() => {
    const finalAssociated = truncateDecimals(state.associated + state.concepts.total,2);
    const finalToAssociate = truncateDecimals(state.ammount - finalAssociated,2);

    setState((current) => ({
      ...current,
      amounts: {
        associated: finalAssociated,
        toAssociate: finalToAssociate,
      },
    }));
  }, [
    state.toAssociate,
    state.associated,
    state.concepts.total,
    state.ammount,
  ]);

  function checkIsDisplayConcepts() {
    if (state.ammount <= 0 || state.payMethod === null) return false;
    return true;
  }

  function checkIfCanAssociateConcepts() {
    if (state.payMethod === null || state.ammount <= 0) return false;

    return true;
  }

  function checkIsValidConceptsTab() {
    return (
      checkIsValidAdvance() &&
      checkIsValidNoIdentified() &&
      checkIsValidConceptCustomer()
    );
  }

  function getTabCxcLabel() {
    if (Object.keys(state.gridAssociations).length <= 0) return "CxC";

    if (state.gridAssociations.isValidApplied) return "CxC";

    return "CxC 🔴";
  }

  function isValidComplement(){

    if(!Array.isArray(state.gridAssociations?.filteredGrid?.array)) return true;

    const itemsThatWillGenerateComplement = state.gridAssociations?.filteredGrid?.array.filter(item=>item.willGenerateComplement===true);

    if(itemsThatWillGenerateComplement.length<=0) return true;

    let isValidResidue = true;

    itemsThatWillGenerateComplement.forEach(item=>{
      if(item.newResidue.number < 0) {
        isValidResidue = false;
      }
    })

    return isValidResidue;

  }

  function isValidFactoring(){
    if(state.applyFactoraje===false) return true;

    if(state.factoraje <= 0) return false;

    if(state.factoraje > state.ammount) return false;

    return true
  }

  function willGenerateComplement(){
    if(!Array.isArray(state.gridAssociations?.filteredGrid?.array)) return false;

    const itemsThatWillGenerateComplement = state.gridAssociations?.filteredGrid?.array.filter(item=>item.willGenerateComplement===true);

    if(itemsThatWillGenerateComplement.length >= 1) return true;

    return false
  }

  /**
   * Set the `factoraje` for the operation to perform
   * @param {number} factoraje 
   */
  const setFactoraje = (factoraje) => setState((current) => ({
    ...current,
    factoraje
  }));

  /**
   * If true, apply factoraje
   * @param {boolean} applyFactoraje 
   */
  const setApplyFactoraje = applyFactoraje => setState(current=>({
    ...current,
    applyFactoraje,
    factoraje:0
  }))

  return {
    state,
    updateAmount,
    setApplyFactoraje,
    setFactoraje,
    setCustomer,
    updateSearch,
    updateGridAssociations,
    attemptCreateAssociation,
    setBeneficiarySocialReason,
    updatePayMethod,
    updateConceptAmmount,
    updateDate,
    willGenerateComplement:willGenerateComplement(),
    cxcLabelTab: getTabCxcLabel(),
    isValidAdance: checkIsValidAdvance(),
    setBeneficiary,
    isValidNoIdentified: checkIsValidNoIdentified(),
    canDisplayConcepts: checkIsDisplayConcepts(),
    isValidConceptsTab: checkIsValidConceptsTab(),
    isValidConceptCustomer: checkIsValidConceptCustomer(),
    isValidAssociation:
    isValidFactoring() &&
      checkIfIsValidAssociation() &&
      checkIsValidAdvance() &&
      isValidComplement() &&
      checkIsValidNoIdentified() &&
      state.payMethod !== 99 &&
      checkIsValidConceptCustomer(),
    canAssociateConcepts: checkIfCanAssociateConcepts(),
    canDisplayGrid:
      state.ammount > 0 &&
      (typeof(state.payMethod)==="number") &&
      (typeof(state.customer)==="number" || state.beneficiary.description.length > 0) ? true : false,
    breadcrum:
      modulo === "movimientos" || modulo === undefined
        ? [
            {
              route: "/inicio",
              text: "Inicio",
            },
            {
              route: "/v2/bancos",
              text: "Bancos",
            },
            {
              route: `/v2/bancos/ingresos?cuenta=${id}`,
              text: "Ingresos",
            },
            {
              route: "/",
              text: "Agregar",
            },
          ]
        : [
            {
              route: "/inicio",
              text: "Inicio",
            },
            {
              route: "/v2/bancos",
              text: "Bancos",
            },
            {
              route: "/v2/bancos/anticipos/clientes",
              text: `Anticipo `,
            },
            {
              route: "/v2/bancos",
              text: `Cliente`,
            },
          ],
  };
}
