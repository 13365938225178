import { AddPlus, Pen } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import Modal from "react-modal";
import React from "react";
import {
  BodyForm,
  BodyFormPart1,
  BodyFormPart2,
  BodyFormPart3,
  BodyFormPart4,
  BodyFormPart5,
  HeaderForm,
  QuantityContainer,
} from "../Documents/Items/Containers";
import { Label } from "structure/Document";
import CatalogSku from "components/general/Forms/Selects/Catalogue/CatalogueSku";
import CatalogueV2 from "components/general/Forms/Selects/Catalogue/Catalogue";
import Currency from "components/individual/CardV2/Labels/Currency";
import {
  ModalClaveProd,
  ModalUm,
  UenSelect,
  UenV3,
} from "components/general/Forms/Selects/UenV3";
import {
  Excento,
  IvasCatalogue,
  Options,
  Warning,
} from "components/general/Forms/Selects/IvasCatalogue";
import { Cost, PU } from "components/general/Forms/Selects/Catalogue/Form";
import Quantity from "components/individual/CardV2/Labels/Quantity";
import Discount from "components/individual/CardV2/Labels/Discount";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { customStyleDiv, customStyles } from "./Containers";
import PartidasContext from "./PartidasContext";
import { usePartidas } from "./Hooks/usePartidas";
import { useContext } from "react";
import WinQuoteContext from "../WinQuote/ContextWinQuote";
import { useWinQuote } from "../WinQuote/Hooks/useWinQuote";

const { Provider } = PartidasContext;

export const PartidasComponet = ({ action = "Editar", tc, index }) => {
  const { partidas, functions } = usePartidas(tc);

  const {
    documents: {
      odc: { documentItems },
    },
  } = useContext(WinQuoteContext);

  const {
    handleChangeItemDescription,
    handleChangeQuantity,
    handleChangeCost,
    handleOnCostdiscountChange,
    handleOnCurrecyChange,
    handleOnchangeSku,
    doOpenModal,
    doCloseModal,
    handleUpdate,
    handleOnIvaItemChange
  } = functions;

  return (
    // <Provider
    //   value={{
    //     ...partidas,
    //     ...functions,
    //   }}
    // >
    <>
      {action === "Editar" ? (
        <>
          <Icon
            onClick={() => {
              doOpenModal(index);
              console.log("Abriendo modal para editar...");
              console.log(documentItems[index]);
              // handleOnSetcurrentItem(documentItems[index]);
            }}
          >
            {action === "Agregar" ? AddPlus : Pen}
          </Icon>
          <Modal
            isOpen={partidas?.openModal}
            style={customStyles}
            onRequestClose={() => {
              // doCloseModal(false);
              // onCloseCurrentItem();
              doCloseModal();
            }}
            contentLabel={"Esto es un ejemplo"}
          >
            <HeaderForm>
              <h2>Informacion de del catalogo de productos y servicios</h2>
              <button type="button" className="modal-form btn">
                <span
                  className="btnCloseModal"
                  onClick={() => {
                    // doCloseModal(false);
                    // onCloseCurrentItem();
                    doCloseModal();
                  }}
                ></span>
              </button>
            </HeaderForm>
            <BodyForm>
              <BodyFormPart1>
                <div className="d-flex flex-column w-25">
                  {partidas.isBy === "none" || partidas.isBy === "sku" ? (
                    // modal.isEditing ? (
                    true ? (
                      partidas.isNewItem ? (
                        <>
                          <Label>Clave</Label>
                          <input
                            type="text"
                            value={partidas.sku}
                            onChange={handleOnchangeSku}
                          />
                        </>
                      ) : (
                        <>
                          <Label>Clave</Label>
                          {partidas.sku}
                        </>
                      )
                    ) : (
                      <>
                        <Label>Clave</Label>
                        <CatalogSku
                          predominantCurrency="MXN"
                          tc={20.21}
                          typeDocument={1}
                          onChange={handleOnchangeSku}
                        />
                      </>
                    )
                  ) : (
                    <>
                      <Label>Clave</Label>
                      {partidas.sku}
                    </>
                  )}
                </div>
                <div className="d-flex flex-column w-75">
                  <Label required={true}>Descripción</Label>
                  {/* {modal.isEditing ? ( */}
                  {true ? (
                    <>
                      <input
                        type="text"
                        value={partidas.description}
                        onChange={({ target: { value } }) =>
                          handleChangeItemDescription(value)
                        }
                      />
                    </>
                  ) : partidas.isBy === "none" ||
                    partidas.isBy === "description" ? (
                    <CatalogueV2
                      predominantCurrency="MXN"
                      tc={20.21}
                      typeDocument={1}
                      onChange={handleChangeItemDescription}
                      // onSelect={handleItemDescriptionChange}
                    />
                  ) : (
                    <>{partidas.description}</>
                  )}
                </div>
                <div style={customStyleDiv}>
                  {/* {currentItem.isNewItem === undefined || currentItem.isNewItem ? ( */}
                  {false ? (
                    <>
                      <Label required={true}>Moneda</Label>
                      <Currency
                        selected={partidas.currency?.code}
                        onChange={(value) => {
                          /**
                           * @type{import("../../../../../../types/documentActions").Currency}
                           */
                          const currency = {
                            code: value,
                            description:
                              value === "MXN"
                                ? "Moneda mexicana"
                                : "Moneda estadounidense",
                            symbol: "$",
                          };
                          handleOnCurrecyChange(currency);
                        }}
                      />
                    </>
                  ) : (
                    <div className="d-flex flex-column">
                      <Label>Moneda</Label>
                      {partidas.currency.code}
                    </div>
                  )}
                </div>
              </BodyFormPart1>
              <UenV3
              // onChange={handleOnUenItemChange}
              >
                {({ selected }) => (
                  <BodyFormPart2>
                    <div>
                      <Label required={selected === undefined}>UEN</Label>
                      <UenSelect
                        value={partidas.uen?.id}
                        // disableFirstOption={!currentItem.isNewItem}
                        firstOption="Seleccione"
                      />
                    </div>
                    <div>
                      <Label required={selected === undefined}>Clave SAT</Label>
                      <ModalClaveProd />
                    </div>
                    <div>
                      <Label required={selected === undefined}>UM SAT</Label>
                      <ModalUm />
                    </div>
                  </BodyFormPart2>
                )}
              </UenV3>
              <BodyFormPart3>
                <IvasCatalogue
                  value={partidas.iva?.number}
                  exempt={false}
                  onChange={(value) => {
                    handleOnIvaItemChange(value);
                    // console.log(value);
                  }}
                >
                  {({ displayExcento }) => (
                    <>
                      <div>
                        <div>
                          <Label>
                            IVA <Warning />
                          </Label>
                          <Options />
                        </div>
                      </div>

                      {displayExcento ? (
                        <div>
                          <Label>Excento</Label>
                          <Excento />
                        </div>
                      ) : null}
                    </>
                  )}
                </IvasCatalogue>

                <div>
                  <Cost
                    onChange={handleChangeCost}
                    maxLength={10}
                    value={partidas.cu?.number}
                  />
                </div>

                <div>
                  <PU
                    onChange={handleChangeQuantity}
                    maxLength={10}
                    value={partidas.pu?.number}
                    typeDocument="odc"
                    informative={true}
                  />
                </div>
              </BodyFormPart3>
              <h4>Aplicable al documento</h4>
              <BodyFormPart4>
                <QuantityContainer>
                  <p>
                    <strong>Cantidad</strong>
                  </p>
                  <input
                    type="text"
                    value={partidas.quantity}
                    onChange={({ target: { value } }) =>
                      handleChangeQuantity(value)
                    }
                  />
                  {/* <Quantity
                informative={false}
                onChange={handleChangeQuantity}
                value={partidas.quantity}
                required={true}
              /> */}
                </QuantityContainer>
                <div>
                  <p>
                    <strong>Desc.Provedor</strong>
                  </p>
                  <input
                    type="number"
                    value={partidas.costDiscount.number}
                    onChange={({ target: { value } }) =>
                      handleOnCostdiscountChange(+value)
                    }
                  />
                  {/* <Discount
                onChange={handleOnCostdiscountChange}
                value={partidas.costDiscount?.number}
              /> */}
                </div>
                <div>
                  <p>
                    <strong>Desc.Cliente</strong>
                  </p>
                  <input
                    type="text"
                    value={partidas.priceDiscount.number}
                    onChange={({ target: { value } }) =>
                      handleOnCostdiscountChange(value)
                    }
                    disabled={true}
                  />
                  {/* <Discount
                label="Desc.Cliente"
                // onChange={handleOnClientDiscountItemChange}
                value={partidas.priceDiscount?.number}
              /> */}
                </div>
                <div className="d-flex flex-column">
                  <Label>Costo U. de la operacion</Label>
                  {partidas.currency?.code === "MXN"
                    ? partidas.mxn?.cu?.total?.text
                    : partidas.usd?.cu?.total?.text}{" "}
                  {partidas.currency?.code}
                </div>
                <div className="d-flex flex-column">
                  <Label>Precio unitario de venta</Label>
                  {partidas.currency?.code === "MXN"
                    ? partidas.mxn?.pu?.total?.text
                    : partidas.usd?.pu?.total?.text}{" "}
                  {partidas.currency?.code}
                </div>
              </BodyFormPart4>
              <BodyFormPart5>
                <ButtonV2
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  {/* {modal.isEditing ? "Actualizar" : "Agregar"} */}
                  Actualizar
                </ButtonV2>
              </BodyFormPart5>
            </BodyForm>
          </Modal>
        </>
      ) : (
        <></>
      )}
      {/* // </Provider> */}
    </>
  );
};
