import {
  getPaymentReminderDates,
  getPaymentReminders,
} from "helpers/Apis/Documents";
import { dateToDbFormat, getMondayDate, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { useEffect, useRef, useState } from "react";
import useMoreInfoPaymentReminder from "../MoreInfo/useMoreInfoPaymentReminder";
import useDialog from "customHooks/useDialog";
import { parseToFolio } from "helpers/documents";
import { saveAs } from "file-saver";

/**
 * @type {import("./types").StatePaymentReminder}
 */
const INITIAL_STATE = {
  isLoading: true,
  orderBy: true,
  rowsPerPage: 100,
  columnOrder: "folio",
  page: 1,
  pages: 1,
  reminders: [],
  reminderSelected: null,
  date: getMondayDate(),
  // date: new Date(),
  refetch: false,
  isLoadingExcel: false,
  maxDate: new Date(),
  isOpenTag: false,
  availableDates: [],
  tag: {
    id: null,
    descripcion: "",
  },
};

/**
 * Hook to handle payment reminders
 * @returns {import("./types").ReturnUsePaymentReminder}
 */
export default function usePaymentReminder() {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("react").MutableRefObject<import("./types").SortIndexed|import("./types").SortIndexedString>}
   */
  const indexedColumnsSort = useRef({
    1: "folio",
    3: "expedicion",
    4: "expiracion",
    5: "vencidos",
    6: "regla",
    7: "cliente",
    11: "total",
    12: "total",
    13: "tag",
  });

  const dialogTag = useDialog();

  const moreInfo = useMoreInfoPaymentReminder(
    state.reminderSelected?.customer?.id
  );

  /**
   * @type {import("react").MutableRefObject<import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]>}
   */
  const breadcrum = useRef([
    {
      route: "/",
      text: "Inicio",
    },
    {
      route: "/",
      text: "Ventas",
    },
    {
      route: "/",
      text: "Cobranza",
    },
  ]);

  useEffect(() => {
    if (state.isOpenTag) {
      dialogTag.open();
    }
  }, [state.isOpenTag]);

  useEffect(() => {
    (async function () {
      const dates = await getPaymentReminderDates();

      const dateToUse = dates.length <= 0 ? getMondayDate() : dates[0];

      setState((current) => ({
        ...current,
        availableDates: dates,
        date: dateToUse,
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (state.availableDates.length <= 0) return;

      setState((current) => ({
        ...current,
        isLoading: true,
        reminders: [],
        reminderSelected: null,
      }));

      const response = await getPaymentReminders({
        date: dateToDbFormat(state.date),
        page: state.page,
        tag: state.tag.id,
        columnOrder: state.columnOrder,
        orderBy: state.orderBy,
        rowsPerPage: state.rowsPerPage,
      });

      if(response instanceof Blob) return

      setState((current) => ({
        ...current,
        isLoading: false,
        reminders: response.reminders,
        pages: response.pages,
      }));
    })();
  }, [
    state.refetch,
    state.availableDates,
    state.rowsPerPage,
    state.tag,
    state.page,
    state.columnOrder,
    state.orderBy,
  ]);

  const refetchReminders = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
      page: 1,
    }));

  /**
   * Handle the sort of the table
   * @param {number} sort Id of the column being sorted
   */
  const handleSort = (sort) => {
    /**
     * @type {import("../../../../../../types/reminderPayments").SortReminderTags}
     */
    const sortBy = indexedColumnsSort.current[sort];

    if (typeof sortBy !== "string") return;

    const isSameColumn = sortBy === state.columnOrder;

    setState((current) => ({
      ...current,
      page: 1,
      columnOrder: sortBy,
      orderBy: isSameColumn ? !current.orderBy : current.orderBy,
    }));
  };

  const downloadExcel = async() => {

    setState(current=>({
      ...current,
      isLoadingExcel:true
    }))

    const response = await getPaymentReminders({
      date: dateToDbFormat(state.date),
      page: state.page,
      tag: state.tag.id,
      columnOrder: state.columnOrder,
      orderBy: state.orderBy,
      rowsPerPage: state.rowsPerPage,
    },"excel");

    if(response instanceof Blob) {
      saveAs(response,`Cobranza ${parseDateToText(state.date)}.xlsx`);
    }

    setState(current=>({
      ...current,
      isLoadingExcel:false
    }))
  }

  /**
   * Handle the click of one record
   * @param {import("../../../../../../types/reminderPayments").ReminderPaymentI} invoice - Reminder
   * @param {number} columnClicked - Column clicked
   */
  const setInvoice = (invoice, columnClicked) => {
    setState((current) => ({
      ...current,
      reminderSelected: invoice,
    }));

    if (columnClicked === 1) moreInfo.handleModal(true);
  };

  /**
   * Update the date for  the reminder payments
   * @param {Date} date - Date
   * @returns {void}
   */
  const setDate = (date) =>
    setState((current) => ({
      ...current,
      date,
    }));

  /**
   * Set the rows per page
   * @param {number} rowsPerPage - Rows per page
   * @returns {void}
   */
  const setRowsPerPage = (rowsPerPage) =>
    setState((current) => ({
      ...current,
      rowsPerPage,
      page: 1,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  const setColumnOrder = (columnOrder) =>
    setState((current) => ({
      ...current,
      columnOrder,
    }));

  const handleTagOpen = (isOpen) =>
    setState((current) => ({
      ...current,
      isOpenTag: isOpen,
    }));

  /**
   * Set the tag for the filters
   * @param {import("../../../../../../types/reminderPayments").TagReminderI} tag
   * @returns {void}
   */
  const setTag = (tag) =>
    setState((current) => ({
      ...current,
      tag,
      page: 1,
    }));

  return {
    ...state,
    handleTagOpen,
    setTag,
    breadcrum: breadcrum.current,
    setPage,
    refetchReminders,
    setColumnOrder,
    setRowsPerPage,
    setDate,
    setInvoice,
    moreInfo,
    downloadExcel,
    dialogTag,
    handleSort,
    indexedColumnsSort: indexedColumnsSort.current,
    columns: [
      {
        name: "# Factura",
        center:true,
        sortable: true,
        reorder:true,
        selector: (invoice) => parseToFolio(+invoice.folio),
      },
      {
        name:"Parcialidad",
        sortable:false,
        reorder:true,
        selector:invoice=>invoice.partialitie
      },
      {
        name: "Fecha",
        center: true,
        sortable: true,
        reorder:true,
        selector: (invoice) => parseDateToText(invoice.createdDate),
      },
      {
        name: "Expiración",
        center: true,
        reorder:true,
        sortable: true,
        selector: (invoice) => parseDateToText(invoice.expiration),
      },
      {
        name: "D. Venc.",
        reorder:true,
        sortable: true,
        selector: (invoice) => invoice.expirationDays,
      },
      {
        name: "Regla",
        reorder:true,
        sortable: true,
        selector: (invoice) => invoice.rule,
      },
      {
        name: "Cliente",
        
        reorder:true,
        sortable: true,
        selector: (invoice) => invoice.customer.shortName,
      },
      {
        name: "Contacto",
        reorder:true,
        sortable: false,
        selector: (invoice) =>
          invoice.contact === null ? "ND" : invoice.contact.fullName,
      },
      {
        name: "Teléfono",
        reorder:true,
        sortable: false,
        selector: (invoice) =>
          invoice.contact === null
            ? invoice.customer.phone
            : invoice.contact.phone,
      },
      {
        name: "Email",
        sortable: false,
        reorder:true,
        selector: (invoice) =>
          invoice.contact === null
            ? invoice.customer.email
            : invoice.contact.email,
      },
      {
        name: "Total MXN",
        sortable: true,
        reorder:true,
        right: true,
        selector: (invoice) =>
          invoice.currency === "MXN" ? mnCurrency(invoice.total) : null,
      },
      {
        name: "Total USD",
        right: true,
        reorder:true,
        sortable: true,
        selector: (invoice) =>
          invoice.currency === "USD" ? mnCurrency(invoice.total) : null,
      },
      {
        name: "Tag",
        reorder:true,
        sortable: true,
        selector: (invoice) => invoice.tag,
      },
      {
        name: "Ejecutivo",
        sortable: false,
        reorder:true,
        selector: (invoice) => invoice.executive,
      },
    ],
  };
}
