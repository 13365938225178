import { Error, Success } from "helpers/alerts";
import { GetDocument, getDocumentComments } from "helpers/Apis/Documents";
import { addInvoice, updateInvoiceV2 } from "helpers/Apis/invoice";
import { reorderComments } from "helpers/comments";
import { getDefaultValuesDocument } from "helpers/Directory/Documents";
import { getDtoPeriocity } from "helpers/documents";
import { useEffect, useReducer } from "react";
import { reducerInvoice, initialState } from "./reducer";
import { ActionType } from "./typesJs";
import { useHistory } from "react-router-dom";
import { dateWithCeroHours } from "helpers/dates";
import { addDays } from "date-fns";
import { GetCustomer, getCustomer } from "helpers/Apis/Directory";

/**
 * Get the methods and state to manage an invoice
 * @param {object} params
 * @param {number?} params.idInvoice - Id of the invoice in case it's editing the document
 * @param {"administracion"|"documentos"} params.redirectPath - When the update it's perform, this indicates (also for the bradcrum) to which part of the system must be redirected the user when query it success
 * @param {number?} params.idCustomer - Id of the customer that came from the user (from documents) in order to update the document
 */
export default function useAddInvoice({
  idInvoice = null,
  redirectPath = "administracion",
  idCustomer = null,
}) {
  const history = useHistory();
  const redirectPathSuccess =
    redirectPath === "administracion"
      ? "../../administracion/pedidos"
      : `../../directorio/documentos/${idCustomer}`;

  const textLastBreadcrumItem = `${
    idInvoice === null ? "Agregar " : "Editar "
  }`;

  const breadcrumToUse =
    redirectPath === "administracion"
      ? [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/administracion/pedidos",
            text: "Administracion",
          },
          {
            route: "/administracion/pedidos",
            text: "Pedidos",
          },
          {
            route: "",
            text: textLastBreadcrumItem,
          },
        ]
      : [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: `/directorio/documentos/${idCustomer}`,
            text: "Documentos",
          },
          {
            route: "",
            text: textLastBreadcrumItem,
          },
        ];

  /**
   * State of the component in order to update/add a new invoice into the system
   * @type {[import("./types").InvoiceStateI,(object:import("./types").ACTION)=>{}]}
   */
  const [state, dispatch] = useReducer(reducerInvoice, initialState);

  /**
   * Set the state that indicates the server is performing the query request
   * @param {boolean} isPerforming - True if the query it's being performed by the server
   * @returns {void}
   */
  const setIsPerformingQuery = (isPerforming) =>
    dispatch({
      type: ActionType.SET_IS_PERFORMING_QUERY,
      payload: isPerforming,
    });

  /**
   * Indicates if the button to add or edit document can be display
   * @type {boolean}
   */
  const canPerformQuery =
    state.partidas.items.length > 0 && state.customer !== null ? true : false;

  useEffect(() => {
    (async function () {
      if (idInvoice === null) {
        getDefaultValues();
      } else {
        getInformationDocument();
      }
    })();

    async function getDefaultValues() {
      const { tc, expiration, reminder } = await getDefaultValuesDocument();

      dispatch({
        type: ActionType.SET_INITAL_CREATE_DOCUMENT,
        payload: {
          tc,
          expiration,
          reminder,
        },
      });
    }

    async function getInformationDocument() {
      const [apiDocument, apiComments] = await Promise.all([
        GetDocument(idInvoice),
        getDocumentComments(idInvoice),
      ]);

      const idPeriocity = apiDocument.periocity.type.id;

      const payload = {
        customer: apiDocument.customer.id,
        customerSocialReason: apiDocument.customer.socialReason,
        comments: {
          notesAndConsiderations: [],
          regulars: apiComments,
        },
        isDocumentFetched: true,
        isPerformingQuery: false,
        document: {
          cfdi: apiDocument.cfdi.code,
          number: apiDocument.docNumber,
          contact: {
            data: null,
            id: apiDocument.contact.id,
          },
          reminder: {
            js: dateWithCeroHours(
              addDays(new Date(apiDocument.reminder.yyyymmdd), 1)
            ),
            db: apiDocument.reminder.yyyymmdd,
          },
          currency: apiDocument.currency.code,
          import: apiDocument.amount.subtotal.number,
          iva: apiDocument.amount.iva.number,
          payForm: apiDocument.payForm.id,
          payMethod: apiDocument.payMethod.id,
          tc: apiDocument.tcp.number,
          total: apiDocument.amount.total.number,
          periocity: {
            id: apiDocument.periocity.type.id,
            begin:
              idPeriocity === null
                ? null
                : new Date(apiDocument.periocity.start),
            end:
              idPeriocity === null ? null : new Date(apiDocument.periocity.end),
            message: null,
            value: idPeriocity === null ? null : apiDocument.periocity.value,
          },
          expiration: {
            js: dateWithCeroHours(
              addDays(new Date(apiDocument.expiration.yyyymmdd), 1)
            ),
            db: apiDocument.expiration.yyyymmdd,
          },
        },
      };

      dispatch({
        type: ActionType.SET_INITIAL_EDIT_DOCUMENT,
        payload,
      });
    }
  }, []);

  const dto = {
    document: {
      cfdi: state.document.cfdi,
      contact: state.document.contact,
      createdBy: "dfsdfsdfsd",
      creditDays: 30,
      currency: state.document.currency,
      idCustomer: state.customer,
      idQuote: null,
      importe: state.document.import,
      iva: state.document.iva,
      payForm: state.document.payForm,
      payMethod: state.document.payMethod,
      periocity: getDtoPeriocity(
        state.document.periocity.id,
        state.document.periocity.value,
        state.document.periocity.begin,
        state.document.periocity.end
      ),
      tcp: state.document.tc,
      total: state.document.total,
      reminder: state.document.reminder.db,
      expiration: state.document.expiration.db,
    },
    items: state.partidas.items.map((item, i) => ({
      ...item,
      order: i + 1,
    })),
    comments: reorderComments([
      ...state.comments?.regulars,
      ...state.comments?.notesAndConsiderations,
    ]),
  };

  /**
   * Perform to query in order to add an invoice into system
   */
  const performAddInvoice = async () => {
    setIsPerformingQuery(true);

    const customer = await GetCustomer(state.customer);

    const wasAdded = await addInvoice({
      ...dto,
      document:{
        ...dto.document,
        creditDays:customer.creditDays
      }
    });

    if (wasAdded) {
      Success(() => {}, "Pedido agregado");
      history.push("/administracion/pedidos");
    } else {
      Error(() => {},
      "No se pudo agregar el pedido. Codigo de error: ??????????????");
    }

    setIsPerformingQuery(false);
  };

  const performUpdateInvoice = async () => {
    setIsPerformingQuery(true);

    const customer = await GetCustomer(state.customer);

    const wasUpdated = await updateInvoiceV2({
      ...dto,
      document: {
        ...dto.document,
        idDocument: +idInvoice,
        creditDays:customer.creditDays
      },
    });
    if (wasUpdated) {
      Success(() => {}, "Pedido actualizado");
      history.push(redirectPathSuccess);
    } else {
      Error(() => {},
      "No se pudo actualizar el pedido. Código de error: ???????????");
    }

    setIsPerformingQuery(false);
  };

  /**
   * Update the social reason to use on the document
   * @param {number} id - Id of the social reason to use on the document
   * @param {string} socialReason - Social reason of the id selected on the combo
   * @param {string} rfc - RFC customer
   * @returns {void}
   */
  const updateSocialReason = (id, socialReason, rfc) =>
    dispatch({
      type: ActionType.SET_SOCIAL_REASON,
      payload: { id, socialReason, rfc },
    });

  /**
   * Update the information of the items that has been added into document throught the component
   * @param {import("structure/Partidas/types").onChangeI} items
   * @returns {void}
   */
  const updateItems = (items) =>
    dispatch({
      type: ActionType.SET_ITEMS,
      payload: items,
    });

  /**
   * Update the contact to use on the document
   * @param {object} contact - Information of the contact
   * @returns {void}
   */
  const updateContact = (contact) =>
    dispatch({
      type: ActionType.SET_CONTACT,
      payload: contact,
    });

  /**
   * Update the tc used for the document
   * @param {number} tc - Tc to use for the document
   * @returns {void}
   */
  const updateTc = (tc) =>
    dispatch({
      type: ActionType.SET_TC,
      payload: tc,
    });

  /**
   * Update the currency to use for the document
   * @param {string} currency - Currency
   * @returns {void}
   */
  const updateCurrency = (currency) =>
    dispatch({
      type: ActionType.SET_CURRENCY,
      payload: currency,
    });

  /**
   * Update the cfdi to use on the document
   * @param {number} cfdi - Cfdi to use for the invoice
   * @returns {void}
   */
  const updateCfdi = (cfdi) =>
    dispatch({
      type: ActionType.SET_CFDI,
      payload: cfdi,
    });

  /**
   * Update the pay form to use on the document
   * @param {number} id - Id of the payform to use on the document
   * @returns {void}
   */
  const updatePayForm = (id) =>
    dispatch({
      type: ActionType.SET_PAY_FORM,
      payload: id,
    });

  /**
   * Update the pay method to use on the document
   * @param {number} id - Id of the pay method to use on the document
   * @returns {void}
   */
  const updatePayMethod = (id) =>
    dispatch({
      type: ActionType.SET_PAY_METHOD,
      payload: id,
    });

  /**
   * Update the periocity to use on the invoice
   * @param {import("structure/Document/Period/types").onChangePeriocityI} periocity
   */
  const updatePeriocity = (periocity) =>
    dispatch({
      type: ActionType.SET_PERIOCITY,
      payload: periocity,
    });

  /**
   * Update the notes and considerations of the document
   * @param {object[]} notes - Notes and considerations to use on the invoice
   * @returns {void}
   */
  const updateNotesAndConsiderations = (notes) =>
    dispatch({
      type: ActionType.SET_NOTES_AND_CONSIDERATIONS,
      payload: notes,
    });

  /**
   * Update the comments of the invoice
   * @param {object[]} comments - Regular comments
   * @returns {void}
   */
  const updateComments = (comments) =>
    dispatch({
      type: ActionType.SET_COMMENTS,
      payload: comments,
    });

  /**
   * Update the expiration date for the invoice
   * @param {string} db - Expiration date
   * @param {Date} js - Expiration date as Date instance
   * @returns {void}
   */
  const updateExpirationDate = (js, db) => {
    dispatch({
      type: ActionType.SET_EXPIRATION,
      payload: {
        js,
        db,
      },
    });
  };

  /**
   * Update the expiration date for the invoice
   * @param {string} db - Expiration date
   * @param {Date} js - Expiration date as Date instance
   * @returns {void}
   */
  const updateReminderDate = (js, db) =>
    dispatch({
      type: ActionType.SET_REMINDER,
      payload: {
        js,
        db,
      },
    });

  return {
    isDocumentFetched: state.isDocumentFetched,
    isPerformingQuery: state.isPerformingQuery,
    breadcrumPath: breadcrumToUse,
    updateItems,
    tc: state.document.tc,
    updateTc,
    currency: state.document.currency,
    updateCurrency,
    importe: state.document.import,
    iva: state.document.iva,
    total: state.document.total,
    updateCfdi,
    updatePayForm,
    updatePayMethod,
    socialReason: state.customer,
    updateSocialReason,
    updateContact,
    cfdi: state.document.cfdi,
    payForm: state.document.payForm,
    payMethod: state.document.payMethod,
    updatePeriocity,
    periocity: state.document.periocity,
    canPerformQuery,
    customerSocialReason: state.customerSocialReason,
    updateNotesAndConsiderations,
    performAddInvoice,
    updateComments,
    uens: state.partidas.uens,
    customer: state.customer,
    contact: state.document.contact.id,
    performUpdateInvoice,
    expiration: state.document.expiration.js,
    reminder: state.document.reminder.js,
    updateExpirationDate,
    updateReminderDate,
    docNumber: state.document.number,
    rfc: state.customerRfc,
  };
}
