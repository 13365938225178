import { useEffect, useState } from "react";
import { getReminderDays } from "../helpers/Apis/parameters";

export default function useReminderDays() {
  const [reminderDays, setReminderDays] = useState(null);

  useEffect(() => {
    (async function () {
      const apiReminderDays = await getReminderDays();
      setReminderDays(apiReminderDays);
    })();
  }, []);

  return [reminderDays, setReminderDays];
}
