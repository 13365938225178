import React, { useState } from "react";
import {
  ContactContainers,
  DateContainers,
  DateLabel,
  HederDocumentContainer,
} from "../Header/Containers";

import { DatesSections } from "../Header/DatesSections";
import { ContactsSections } from "../Header/ContactsSections";

export const HeaderDocumentsComponent = () => {
  return (
    <HederDocumentContainer>
      <ContactContainers>
        <ContactsSections />
      </ContactContainers>

      <DatesSections />
    </HederDocumentContainer>
  );
};
