import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, ModalDismissButton } from "../buttons/Buttons";
import { Spinner } from "../Spinner/Spinner";
import { v4 as uuid } from "uuid";

function Modal({
  idOpenModal = "openModal",
  idCloseIcon = uuid(),
  children,
  title = "[Insert a title here]",
  close = null,
  accpet = null,
  buttonText = "Abrir modal",
  cbAccept = () => {},
  cbReject = () => {},
  idCloseModal = "closeModal",
  idAccetModal = "acceptModal",
  idFooter = "footerModal",
  showSpinner = false,
  textSpinner = "Cargando...",
  subtitle = "",
  cbOpen = () => {},
  aditionalClasses = "",
  footer = <></>,
}) {
  /**
   * True if the modal must be show on the screen
   * @type {[boolean,Function]}
   */
  const [show, setShow] = useState(false);
  const [stateShowSpinner, stateSetShowSpiner] = useState(showSpinner);

  useEffect(() => {
    stateSetShowSpiner(showSpinner);
  }, [showSpinner]);

  const acceptModal = async () => {
    await cbAccept();
    setShow(false);
  };

  const closeModal = async () => {
    await cbReject();
    setShow(false);
  };

  const openModal = () => {
    cbOpen();
    setShow(true);
  };

  return (
    <>
      <Button
        id={idOpenModal}
        key={idOpenModal}
        text={buttonText}
        aditionalClasses={aditionalClasses}
        funcion={openModal}
      />

      {show === true ? (
        <>
          <div
            className="modal fade show d-block  "
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title font-weight-bold"
                    id="staticBackdropLabel"
                  >
                    {title}
                  </h5>
                  <h5 className="my-0">{subtitle}</h5>
                  {stateShowSpinner === true ? null : (
                    <>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id={idCloseIcon}
                        onClick={closeModal}
                      >
                        <span onClick={closeModal} aria-hidden="true">
                          &times;
                        </span>
                      </button>
                    </>
                  )}
                </div>
                <div className="modal-body">{children}</div>

                <div className="modal-footer" id={idFooter}>
                  {footer}

                  {stateShowSpinner === true ? (
                    <Spinner
                      hidden={false}
                      idSpinner="loading"
                      text={textSpinner}
                    />
                  ) : (
                    <>
                      {close !== null ? (
                        <ModalDismissButton
                          idBtn={idCloseModal}
                          text={close}
                          key={idCloseModal}
                          callback={closeModal}
                        />
                      ) : null}

                      {accpet === null ? null : (
                        <Button
                          id={idAccetModal}
                          key={idAccetModal}
                          text={accpet}
                          funcion={acceptModal}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-backdrop fade show"></div>
        </>
      ) : null}
    </>
  );
}

Modal.propTypes = {};

export default Modal;
