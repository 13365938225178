import {useReducer} from 'react';
import TableContext from './TableContext';
import TableReducer from './TableReducer';
import axios from 'axios';
import {URL_BASE} from '../../routes/routes'
import {
  faSortAmountDownAlt
} from "@fortawesome/free-solid-svg-icons";

import {
    SET_DATA,
    SET_LOADING_DATA,
    SET_FOUNDED_ROWS,
    SET_PAGES,
    SET_INFO_ROW,
    SET_ORDER_BY,
    SET_CRYTERIAN_ORDER,
    SET_ICON_ORDERING,
    SET_ACTUAL_PAGE,
    SET_RENDER,
    SET_RELOAD,
    SET_CALL_API,
    SET_LENGTH_DATA
    
} from '../../types/index';

const TableState = (props) => {
    const initialState = {
        data:[],
        rowBegin:1,
        rowEnd:25,
        actualPage:1,
        pages:0,
        rows:0,
        infoRow:null,
        orderBy:null,
        criterianOrder:`DESC`,
        iconOrdering:faSortAmountDownAlt,
        isFetching:true,
        render:0,
        refetch:false,
        callAPI:false,
        lengthData:0
    }

    const [state,dispatch] = useReducer(TableReducer,initialState);

    const setInfoRow = info => {
        dispatch({
            type:SET_INFO_ROW,
            payload:info
        })
    }
    const setLengthData= data=>{
        dispatch({
            type:SET_LENGTH_DATA,
            payload:data
        })
    }

    const setData = data => {
        dispatch({
            type:SET_DATA,
            payload:data
        })
    }

    const setFoundedRows = rows => {
        dispatch({
            type:SET_FOUNDED_ROWS,
            payload:rows
        });
    }

    const setPages = () => {
        dispatch({
            type:SET_PAGES
        })
    }
 
    const setIsFetching = loading => {
        dispatch({
            type:SET_LOADING_DATA,
            payload:loading
        })
    }

    const setOrderBy = dbColumn => {
        dispatch({
            type:SET_ORDER_BY,
            payload:dbColumn
        });
    }

    const setCriterianOrder = order => {
        dispatch({
            type:SET_CRYTERIAN_ORDER,
            payload:order
        })
    } 

    const setIconOrdering = text => {
        dispatch({
            type:SET_ICON_ORDERING,
            payload:text
        })
    }

    const setActualPage = page => {
        dispatch({
            type:SET_ACTUAL_PAGE,
            payload:page
        })
    }

    const setRender = count => {
        dispatch({
            type:SET_RENDER,
            payload:count
        })
    }

    const setCallAPI = bool => {
        dispatch({
            type:SET_CALL_API,
            payload:bool
        })
    }

    const setReload = boolean => {
        dispatch({
            type:SET_RELOAD,
            payload:boolean
        })
    }

    const fetchUsers = async (begin, end, columnOrdering='userID',orderFetch = `DESC`) => {
        try {
          setIsFetching(true);  
            
          const { data } = await axios.get(`${URL_BASE}usuarios/${begin}/${end}/${orderFetch}/${columnOrdering}`);
          
          /* SET THE ROWS FETCHED FROM THE DB TO USERS STATE */
          setData(data.data[0].users);
    
          /* FETCH ENDED */
          setIsFetching(false);
    
          /* SET FOUNDED ROWS TO CALCULATE TOTAL PAGES */
          setFoundedRows(data.data[0].rows);
          setPages();
          setOrderBy(0);
          return data.data[0];
    
        } catch (error) {
          console.log(`There wan an error: ${error}`);
        }
      };

    const values = {
        data:state.data,
        infoRow:state.infoRow,
        isFetching:state.isFetching,
        rowBegin:state.rowBegin,
        rowEnd:state.rowEnd,
        rows:state.rows,
        actualPage:state.actualPage,
        pages:state.pages,
        orderBy:state.orderBy,
        criterianOrder:state.criterianOrder,
        iconOrdering:state.iconOrdering,
        render:state.render,
        refetch:state.refetch,
        callAPI:state.callAPI,
        lengthData:state.lengthData,
        setRender,
        setData,
        setIsFetching,
        setFoundedRows,
        setPages,
        setInfoRow,
        setOrderBy,
        setCriterianOrder,
        setIconOrdering,
        setActualPage,
        fetchUsers,
        setReload,
        setCallAPI,
        setLengthData
    }

    return(
        <TableContext.Provider value={values}>
            {props.children}
        </TableContext.Provider>
    )
}

export default TableState;