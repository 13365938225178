import * as yup from "yup";

const contractCode = yup.string().required('Contrato obligatorio').max(30,'Maximo 30 caracteres');
const expirationDate = yup.date().required('Expiracion obligatoria')
const reminderDate = yup.date().required('Expiracion obligatoria')

export const schemaValidation = yup.object().shape({
    contractCode,
    expirationDate,
    reminderDate
});