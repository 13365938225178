import React, { useEffect, useState } from "react";
import { GetTCP } from "../../../helpers/Apis/Documents";
import { mnCurrency } from "../../../helpers/money";
import { RadioButton } from "../../individual/inputs/Radio";
import { InputCurrency } from "../../individual/inputs/Inputs";

import { ContainerRadioBtns } from "./Styles";

/**
 * Render a radio button with the tc of the system
 * @param {import("./types").TcSystem} props - Props
 * @returns {JSX.Element}
 */
export default function Index({ tcDocument = 0, onChange = () => {} }) {
  const [tc, setTc] = useState([]);

  const [customTc, setCustomTc] = useState(0);

  useEffect(() => {
    (async function () {
      const apiTc = await GetTCP();

      setCustomTc(apiTc.test);

      setTc([
        {
          value: apiTc["test"],
          label: `${mnCurrency(apiTc["test"])} - Institucional`,
          id: "enterprise",
        },
        {
          value: apiTc["sales"],
          label: `${mnCurrency(apiTc["sales"])} - Banco (venta)`,
          id: "bank",
        },
        {
          value: tcDocument,
          label: `${mnCurrency(tcDocument)} - Documento`,
          id: "document",
        },
        {
          value: apiTc["DOF"],
          label: `${mnCurrency(apiTc["DOF"])} - DOF`,
          id: "dof",
        },
      ]);
    })();
  }, []);

  const updateTcToUse = (tc) => {
    setCustomTc(tc);
    onChange(tc);
  };

  return (
    <ContainerRadioBtns>
      <RadioButton
        options={tc}
        attributeText="label"
        attributeId="value"
        id="optionTc"
        idElements="tcElement"
        attributeValue="value"
        name="tcInvoice"
        optSelected={tcDocument}
        onClick={(tc) => updateTcToUse(tc.value)}
      />
      <div className="radioBtnElement">
        <input
          type="radio"
          id="tcInvoice-other"
          name="tcInvoice"
          onClick={() => updateTcToUse(customTc)}
          value={customTc}
        />
        <label className="cursorPointer mr-2" htmlFor="tcInvoice-other">
          Otro
        </label>
      
        <InputCurrency
          placeholder="Escribe aqui"
          id="otherTc"
          name="tcInvoice"
          defaultValue={customTc}
          onChange={(value) => updateTcToUse(+value)}
        />
      </div>
    </ContainerRadioBtns>
  );
}
