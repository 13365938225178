import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { ContextBankCorrection } from "context/BankCorrection";
import { MONTHS_ABREVIATION, getLastDayOfPreviousMonth } from "helpers/dates";
import React, { useContext } from "react";

export default function SubmitCorrection() {
  const hook = useContext(ContextBankCorrection);

  if (!hook.isValidDate && hook.isValid)
    return (
      <div>
        <p className="text-danger font-weight-bold m-0">
          🔴 Máximo puedes escoger{" "}
          {MONTHS_ABREVIATION[getLastDayOfPreviousMonth().getMonth()]}
          {"/"}
          {getLastDayOfPreviousMonth().getUTCFullYear()}
        </p>
      </div>
    );

  return (
    <>
      <ButtonV2 onClick={hook.attemptBankCorrection} disabled={!hook.isValid}>
        <span>Corregir</span>
        <Icon icon="save" />
      </ButtonV2>
    </>
  );
}
