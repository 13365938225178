import { getAllExpenses } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { getInformativeIncomes } from "helpers/Apis/Banks";
import { mnCurrency } from "helpers/money";
import { useEffect, useState } from "react";

/**
 *
 * @param {number} importe - Importe
 * @param {("USD"|"MXN")} predominantCurrency - Currency
 * @param {(object:import("types/typedef/customHooks/useConcepts").ConceptI)=>void} onChange - Information of the concept selected
 * @param {1|2} typeConcept - Type of concept to fetch (1 - Gastos | 2 - Conceptos)
 * @example
 * import useConcepts from "customHooks/useConcepts";
 *
 * function MyComponent(){
 * const { concepts } = useConcepts(1000, "MXN", () => {}, 2);
 * returns (
 *  <div>Hi</div>
 * )
 * }
 * @returns
 */
export default function useConcepts(
  importe,
  predominantCurrency,
  onChange = () => {},
  typeConcept = 1
) {
  /**
   * @type {[import("types/typedef/customHooks/useConcepts").StateI,()=>void]}
   */
  const [state, setState] = useState({
    concepts: [],
    conceptSelected: null,
    isLoading: true,
    amountsCalculated: null,
  });

  useEffect(() => {
    (async function () {
      let apiConcepts;

      if (typeConcept === 1) {
        apiConcepts = await getAllExpenses();
      } else {
        apiConcepts = await getInformativeIncomes();
      }

      const parseApiConcepts = apiConcepts.map((concept) => {
        if (concept.currency.code === predominantCurrency) {
          return {
            ...concept,
            applied: {
              text: mnCurrency(importe),
              number: importe,
            },
            importe: {
              text: mnCurrency(importe),
              number: importe,
            },
            tc: {
              text: mnCurrency(1),
              number: 1,
            },
          };
        } else {
          return {
            ...concept,
            applied: {
              text: mnCurrency(0),
              number: 0,
            },
            importe: {
              text: mnCurrency(importe),
              number: importe,
            },
            tc: {
              text: mnCurrency(0),
              number: 0,
            },
          };
        }
      });

      setState({
        ...state,
        concepts: parseApiConcepts,
        isLoading: false,
      });
    })();
  }, [predominantCurrency, typeConcept]);

  useEffect(() => {
    if (state.conceptSelected === null) {
      onChange({
        ...state.conceptSelected,
        isValid: false,
      });

      return;
    }

    onChange({
      ...state.conceptSelected,
      isValid: state.conceptSelected.tc.number > 0 ? true : false,
    });
  }, [state.conceptSelected]);

  /**
   *
   * @param {import("types/typedef/customHooks/useConcepts").ConceptI} concept
   * @returns {void}
   */
  const setConceptSelected = (concept) =>
    setState({
      ...state,
      conceptSelected: concept,
    });

  /**
   * Set the TC when currency are differents
   * @param {number} applied - Applied types
   */
  const calculateTc = (applied) => {
    let tc = 0;

    if (predominantCurrency === "USD") {
      tc = applied / importe;
    } else {
      tc = importe / applied;
    }

    tc = tc.toFixed(2);

    setState({
      ...state,
      conceptSelected: {
        ...state.conceptSelected,
        tc: {
          text: mnCurrency(tc),
          number: tc,
        },
        applied: {
          text: mnCurrency(applied),
          number: applied,
        },
      },
    });
  };

  return {
    concepts: state.concepts,
    conceptSelected: state.conceptSelected,
    isLoading: state.isLoading,
    setConceptSelected,
    calculateTc,
  };
}
