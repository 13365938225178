import React from "react";
import useDistributionList from "./useDistributionList";
import { createContext } from "react";
import Dialog from "structure/Dialog";
import { UenV4, UenSelect } from "components/general/Forms/Selects/UenV4";
import style from "./styles.module.scss";
import { Label } from "structure/Document";
import Select from "components/individual/SelectV4";
import TypeCustomerDirectory from "components/individual/TypeCustomerDirectory";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import usePermissions from "customHooks/usePermissions";

export const ContextDistributionList = createContext({});

/**
 * Render an option to display a modal to generate the distribution list for mailjet
 * @param {import('./types').PropsDistributionList} props - Props
 * @returns {JSX.Element}
 */
export default function DistributionList({ children = <></> }) {
  const hook = useDistributionList();

  const { checkPermission } = usePermissions();

  if (!checkPermission("126fcb32-b499-409f-a241-2fd80e9e15d9")) return <></>;

  return (
    <>
      <div onClick={() => hook.dialog.open()}>{children}</div>

      <Dialog
        title="Lista de distribución"
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
        footer={
          <div className={style.containerExcelDownload}>
            {hook.isDownloading ? (
              <Spinner
                hidden={false}
                text={<LoadingText>Descargando</LoadingText>}
              />
            ) : (
              <ButtonV2 onClick={hook.attemptDownload}>Excel</ButtonV2>
            )}
          </div>
        }
      >
        <div className={style.filter}>
          <UenV4 addAllOption={true}>
            <div>
              <Label>UEN</Label>
              <UenSelect onChange={(uen) => hook.setUen(uen)} />
            </div>
          </UenV4>

          <TypeCustomerDirectory
            type="todos"
            isFilter={true}
            onChange={hook.updateSectorType}
          />

          <div>
            <Label>Tipo</Label>
            <Select
              onChange={(item) => hook.updateTypeQuery(item.value)}
              labelToDisplay="label"
              options={async () => [
                {
                  value: "report",
                  label: "Reporte",
                },
                {
                  value: "csv",
                  label: "Carga",
                },
              ]}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
