import React from "react";

/**
 * Options to opens the ODC grid or the expense concepts
 * @param {{checked:boolean,onChange:()=>void}} {checked,onChange}
 * @returns {import("react").ReactComponentElement}
 */
export const OptionsToGird = ({ checked, onChange }) => {
  return (
    <div className="d-flex">
      <div className="radio ">
        <label className="d-flex align-items-center">
          <input
            type="radio"
            value={"Orden de compra"}
            checked={!checked}
            onChange={() => onChange(false)}
          />
          Orden de compra
        </label>
      </div>
      <div className="radio">
        <label className="d-flex align-items-center">
          <input
            type="radio"
            value={"Otros egresos"}
            checked={checked}
            onChange={() => onChange(true)}
          />
          Otros egresos
        </label>
      </div>
    </div>
  );
};
