import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextReceiveCredtNotes } from "..";
import YearSelect from "components/general/Forms/Selects/Year";
import MonthSelect from "components/general/Forms/Selects/Month";
import { lastDayOfMonth } from "date-fns";
import { getFirstDayOfMonth } from "helpers/dates";
registerLocale("es", es);

export default function RangeDate() {
  const hook = useContext(ContextReceiveCredtNotes);

    /**
   * Year
   * @param {number} year - Year
   */
    const handleYearChange = (year) => {

      const dateToEvaluate = new Date(`${year}-${hook.beginDate.getUTCMonth()+1}-3`);
  
      const lastDay = lastDayOfMonth(dateToEvaluate);
      const firstDay = getFirstDayOfMonth(dateToEvaluate);
  
      hook.updateBeginDate(firstDay);
      hook.updateEndDate(lastDay);
    }

      /**
   * Update the range
   * @param {number} month - Month
   */
  const handleMonthUpdate = (month) => {
    const dateToEvaluate = new Date(
      `${hook.beginDate.getUTCFullYear()}-${month}-3`
    );

    const lastDay = lastDayOfMonth(dateToEvaluate);
    const firstDay = getFirstDayOfMonth(dateToEvaluate);

    hook.updateBeginDate(firstDay);
    hook.updateEndDate(lastDay);
  };

  if (hook.module === "contabilidad")
    return (
      <>
        <YearSelect
          from={new Date().getUTCFullYear() - 10}
          to={new Date().getUTCFullYear()}
          onChange={handleYearChange}
          value={hook.beginDate.getUTCFullYear()}
        />

        <MonthSelect onChange={handleMonthUpdate} value={hook.beginDate.getUTCMonth()+1}/>
      </>
    );

  return (
    <>
      <div>
        <Label>Inicio</Label>
        <ReactDatePicker
          selected={hook.beginDate}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona"
          maxDate={hook.endDate}
          onChange={(date) => hook.updateBeginDate(date)}
          isClearable={false}
        />
      </div>

      <div>
        <Label>Fin</Label>
        <ReactDatePicker
          selected={hook.endDate}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          minDate={hook.beginDate}
          placeholderText="Selecciona"
          onChange={(date) => hook.updateEndDate(date)}
          maxDate={new Date()}
          isClearable={false}
        />
      </div>
    </>
  );
}
