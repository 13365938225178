import React from "react";
import style from "./styles.module.scss";
import { useContext } from "react";
import { ContextCxpReport } from "..";
import { parseDateToText } from "helpers/dates";
import { mnCurrency, truncateDecimals } from "helpers/money";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export default function TableReportCxp() {
  const hook = useContext(ContextCxpReport);

  if (hook.isSearching)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingCxpReport"
        text={
          <LoadingText>
            Buscando {hook.type === "cxp" ? "CxP" : "CxC"}
          </LoadingText>
        }
      />
    );

  if (hook.cxp.length <= 0) return;

  return (
    <div className={style.tableContainer}>
      <div className={style.header}>
        <p>{hook.type === "cxp" ? "Proveedor" : "Cliente"}</p>
        <p>Factura</p>
        <p>Emisión</p>
        <p>Parcialidad</p>
        <p>Vencimiento</p>
        <div>
          <p>Total</p>
          <p>MXN</p>
          <p>USD</p>
        </div>
        <div>
          <p>Saldo</p>
          <p>MXN</p>
          <p>USD</p>
        </div>
        <p>%</p>
      </div>

      <div className={style.contentTable}>
        {hook.cxp.map((parent) =>
          parent[`${hook.type}s`].map((item, j) => (
            <>
              <div className={style.row}>
                <p>{item.socialReason}</p>

                <ModalInvoiceReception uuid={item.uuid}>
                  {item.factura}
                </ModalInvoiceReception>

                <p>{parseDateToText(new Date(item.emited))}</p>
                <p>
                  {item.partialitie}/{item.partialities}
                </p>
                <p>{parseDateToText(new Date(item.expiration))}</p>
                <div className={style.amountCxp}>
                  <p>
                    {item.currency === "MXN"
                      ? mnCurrency(item["totalAmount"])
                      : "$0.00"}
                  </p>
                  <p>
                    {item.currency === "USD"
                      ? mnCurrency(item["totalAmount"])
                      : "$0.00"}
                  </p>
                </div>
                <div className={style.amountCxp}>
                  <p>
                    {item.currency === "MXN"
                      ? mnCurrency(item.residue)
                      : "$0.00"}
                  </p>
                  <p>
                    {item.currency === "USD"
                      ? mnCurrency(item.residue)
                      : "$0.00"}
                  </p>
                </div>
                <p>{truncateDecimals(item.percentagePayed, 2)}</p>
              </div>

              {j === parent[`${hook.type}s`].length - 1 ? (
                <div className={style.totals}>
                  <p></p>

                  <p></p>

                  <p></p>
                  <p></p>
                  <p></p>
                  <div className={style.amountCxp}>
                    <p>{mnCurrency(parent.totalMxn)}</p>
                    <p>{mnCurrency(parent.totalUsd)}</p>
                  </div>
                  <div className={style.amountCxp}>
                    <p>{mnCurrency(parent.totalMxnResidue)}</p>
                    <p>{mnCurrency(parent.totalUsdResidue)}</p>
                  </div>
                  <p></p>
                </div>
              ) : null}
            </>
          ))
        )}
      </div>

      <div className={style.totalReportRow}>
        <p></p>

        <div></div>

        <p></p>
        <p></p>
        <p></p>
        <div className={style.amountCxp}>
          <p>{mnCurrency(hook.total.mxn)}</p>
          <p>{mnCurrency(hook.total.usd)}</p>
        </div>
        <div className={style.amountCxp}>
          <p>{mnCurrency(hook.residue.mxn)}</p>
          <p>{mnCurrency(hook.residue.usd)}</p>
        </div>
        <p>NA</p>
      </div>
    </div>
  );
}
