import React from "react";
import Select from "components/individual/SelectV4";
import { Label } from "structure/Document";
import { ConcilationContext } from "..";
import { useContext } from "react";

export default function TypeMovement() {
  const hook = useContext(ConcilationContext);

  return (
    <div>
      <Label>Ingreso/Egreso</Label>
      <Select
        labelToDisplay="type"
        options={async () => [
          {
            id: null,
            type: "Todos",
          },
          {
            id: 1,
            type: "Ingresos",
          },

          {
            id: 2,
            type: "Egresos",
          },
        ]}
        onChange={(option) => hook.setType(option.id)}
      />
    </div>
  );
}
