import React, { useState } from "react";
import ModalV2 from "components/individual/Modals/ModalV2";
import { RadioButton } from "components/individual/inputs/Inputs";
import { RedirectButton } from "components/individual/buttons/Buttons";

/**
 *
 * @param {object} props - Props
 * @param {string} props.cxp - Where to redirect if the option selected its cxp
 * @param {string} props.concept - Where to redirect if the option selected its concept
 * @returns
 */
export default function EgressModal({ concept, cxp }) {
  const [typeAssociation, setTypeAssociation] = useState(null);

  return (
    <ModalV2
      title="Egreso"
      aditionalClasses=""
      buttonText="Asociar"
      close="Cancelar"
      showCancelButton={false}
      cbReject={() => setTypeAssociation(null)}
      footer={
        <>
          {typeAssociation !== null ? (
            <RedirectButton
              text="Continuar"
              id="redirectAssociate"
              link={typeAssociation === 1 ? cxp : concept}
            />
          ) : null}
        </>
      }
    >
      <h3 className="text-center mb-3">Selecciona el tipo de asociacion</h3>

      <div className="d-flex justify-content-center">
        <RadioButton
          options={[
            {
              label: "Orden de compra",
              value: 1,
            },
            {
              label: "Gasto",
              value: 2,
            },
          ]}
          name="typeAssociation"
          attributeValue="value"
          attributeText="label"
          cb={(e) => setTypeAssociation(+e.target.value)}
          attributeID="value"
        />
      </div>
    </ModalV2>
  );
}
