import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { parseHttpError, successRes } from "../fetch";

/**
 * Retrieve the logs made on the system
 * @param {import("../../../../../server/controllers/typesControllerLogs").QueryGetLog} query - Params in order to fetch data
 * @returns {Promise<import("../../../../../server/controllers/typesControllerLogs").ResGetLog>}
 */
export async function retrieveLogs(query) {
  /**
   * @type {import("../../../../../server/controllers/typesControllerLogs").ResGetLog}
   */
  const ERROR_RESPONSE = {
    logs: [],
    page: 1,
    pages: 1,
    recordsFound: 0,
  };

  let queryParams = [
    `?pagina=${query.pagina}`,
    `&registrosPorPagina=${query.registrosPorPagina}`,
    `&desde=${query.desde}`,
    `&hasta=${query.hasta}`,
    `&tipo=${query.tipo}`,
    `&proveedor=${query.proveedor}`,
    `&orden=${query.orden}`,
    `&ordenAsc=${query.ordenAsc}`,
  ];

  if (query.busqueda !== "") queryParams.push(`&busqueda=${query.busqueda}`);

  const queryString = queryParams.reduce((url, query) => (url += query), "");

  try {
    const response = await fetch(`${URL_BASE}logs${queryString}`, {
      credentials: "include",
    });

    if (successRes(response)) {
      /**
       * @type {import("../../../../../server/controllers/typesControllerLogs").ResGetLog}
       */
      const data = await response.json();
      return data;
    }

    const error = await parseHttpError(response);

    Error(() => {}, error.message);
    return ERROR_RESPONSE;
  } catch (error) {
    return ERROR_RESPONSE;
  }
}

export async function getServicesLogs() {
  try {
    const response = await fetch(`${URL_BASE}logs/servicios`, {
      credentials: "include",
    });

    if (successRes(response)) {
      /**
       * @type {import("../../../../../server/controllers/typesControllerLogs").ResLogsServices[]}
       */
      const data = await response.json();
      return [{id:null,name:"Todos"},...data];
    }

    return [];
  } catch (error) {
    return [];
  }
}
