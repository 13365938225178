import React from "react";
import { useState } from "react";
import Steps from "rc-steps";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import scss from "./styles.module.scss";

/**
 * Render a tutorial made by the system
 * @param {import("./types").PropsTutorial} props - Props
 * @returns {JSX.Element}
 * @example
 *  // Images must be named as 1.png , 2.png , 3.png , etc...
 *  <CustomTutorial
      folder="images/cookies/firefox/"
      labels={[
        "Configuracion",
        "Limpiar historial reciente",
        "Borrar datos de exploracion",
      ]}
    />
 */
export const CustomTutorial = ({ folder, labels }) => {
  const [step, setStep] = useState(0);

  return (
    <div className={scss.container}>
      <p>
        Paso {step + 1} de {labels.length} - {labels[step]}
      </p>

      <div className={scss.tutorialGuideStep}>
        <img
          src={`${process.env.REACT_APP_FRONTEND}${folder}${step + 1}.png`}
          alt={`tutorial-step-${step + 1}`}
        />

        <button
          disabled={step === 0}
          type="button"
          onClick={() => setStep(step - 1)}
        >
          {step===0 ? "-" : <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>}
          
        </button>
        <button
          disabled={step >= labels.length - 1}
          type="button"
          onClick={() => setStep(step + 1)}
        >
          {step >= labels.length - 1 ? "-" : <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
              clipRule="evenodd"
            />
          </svg>}
        </button>
      </div>
      {/* <div className="d-flex justify-content-center">
        <ButtonV2
          className="mr-3"
          type="button"
          disabled={step === 0}
          onClick={() => setStep(step - 1)}
        >
          ⬅
        </ButtonV2>
        <ButtonV2
          disabled={step >= labels.length - 1}
          onClick={() => setStep(step + 1)}
          type="button"
        >
          ➡
        </ButtonV2>
      </div> */}
    </div>
  );
};
