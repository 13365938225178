import React, { useState } from "react";
import { Label } from "structure/Document";
import styles from "./styles.module.scss";
import { ContainerEmail, StyledEmail } from "./styles";

/**
 * Render an email input and validate it on real-time
 * @param {import("./types").PropsEmailI} props
 * @returns {JSX.Element}
 */
export default function Email(props) {
  /**
   * State of the email component
   * @type {[import("./types").StateI,()=>void]}
   */
  const [state, setState] = useState({
    value: props?.value || "",
    isValid: !props.required ? true : props?.value === "" ? false : true,
  });

  /**
   * Handle the email when changes on the input
   * @param {string} email - Email to use
   */
  const handleOnChange = (email) => {
    const regEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "g");
    const isValid = regEmail.test(email);

    const isValidEmail = props.required
      ? isValid
      : email.length <= 0
      ? true
      : isValid;

    props.onChange({
      isValid: isValidEmail,
      email: email === "" ? null : email,
    });

    setState({
      value: email,
      isValid: isValidEmail,
    });
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Label required={state.isValid ? false : true}>{props.label}</Label>
        {props.maxLength === null ? null : (
          <span>
            {state.value.length}/{props.maxLength}
          </span>
        )}
      </div>

      <ContainerEmail>
        <StyledEmail
          type="email"
          {...props}
          value={
            props.maxLength === null
              ? state.value
              : state.value.substring(0, props.maxLength)
          }
          onChange={(e) => handleOnChange(e.target.value)}
          className={styles.email}
        />
        {props.renderCleanButton ? <button>X</button> : null}
      </ContainerEmail>

      <div>
        {props.required && state.value.length <= 0 ? (
          <p className="text-danger">Email requerido</p>
        ) : null}
        {state.isValid ? null : <p className="text-danger">Email inválido</p>}
      </div>
    </>
  );
}
