import { useState } from "react";

export default function useToDo() {
  /**
   * State of to do
   * @type {[import("./types").StateUseToDo,()=>void]}
   */
  const [state, setState] = useState({
    navSelected: "documentos",
    refetch: false,
  });

  /**
   * Update the navigation of the cards selected
   * @param {import("./types").NavToDoHome} navSelected - Navigation selected
   * @returns {void}
   */
  const changeNavigation = (navSelected) =>
    setState({
      ...state,
      navSelected,
    });

  const refetchPersonalAdded = () =>
    setState({
      ...state,
      refetch: !state.refetch,
    });

  return {
    navSelected: state.navSelected,
    changeNavigation,
    refetchPersonalAdded,
    refetch: state.refetch,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/",
        text: "To Do",
      },
    ],
  };
}
