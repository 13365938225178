import React from "react";
import { useContext } from "react";
import NumberFormat from "react-number-format";
import { Label } from "structure/Document";
import { AddMovementContext } from "../../AddMovementsV2";

export const Amount = () => {
  const { amount, updateAmount } = useContext(AddMovementContext);

  return (
    <div>
      <Label required={amount === 0 || amount === undefined}>Importe</Label>

      <NumberFormat
        tabIndex={3}
        value={amount}
        prefix="$"
        decimalScale={2}
        min={0}
        onValueChange={(value) => updateAmount(value.floatValue)}
      />
    </div>
  );
};
