import { useBanks } from "components/individual/BankCard/hooks/useBanks";
import { addDays, sub } from "date-fns";
import { saveAs } from "file-saver";
import { getCashFlow, getCashFlowExcel } from "helpers/Apis/Banks/index";
import { GetTcp } from "helpers/Apis/Documents";
import { parseDateToText } from "helpers/dates";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateCashFlow}
 */
const INITIAL_STATE = {
  isLoading: false,
  currency: "Todos",
  tc: 0,
  currencyReports: "MXN",
  displayDetails: false,
  cashFlow: undefined,
  refetch: true,
  institutionalTc: 0,
  bankAccountId: undefined,
  isDownloadingExcel:false
};

/**
 * @type {import("./types").ReturnCashFlow}
 */
export const INITIAL_STATE_CONTEXT = {
  cashFlow: INITIAL_STATE,
  downloadExcelReport:async()=>{},
  within14Days: "",
  within7Days: "",
  yesterDayColumn: "",
  setBankAccount: () => {},
  todayColumn: "",
  tomorrowColumn: "",
  areValidFilters: false,
  setToggleDetails: () => {},
  breadcrum: [],
  banks: {
    breadcrum: [],
    infocards: [],
    isLoading: true,
    refetch: false,
    triggerRefetch: () => {},
  },
  updateCurrency: () => {},
  updateTc: () => {},
  updateCurrencyReport: () => {},
  updateDetails: () => {},
  research: () => {},
};

/**
 * Handle the report for the cash flow
 * @param {import("../types").TypeCashFlow} [type='proyeccion'] - Type of cash flow being displayed
 * @returns {import("./types").ReturnCashFlow}
 */
export default function useCashFlow(type = "proyeccion") {
  const banks = useBanks();

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * Update the currency to use on the cash flow report
   * @param {string} currency - 3 digit code of the currency to use for the reports
   * @returns {void}
   */
  const updateCurrency = (currency) =>
    setState((current) => ({
      ...current,
      currency,
      cashFlow: undefined,
    }));

  const downloadExcelReport = async() => {
    setState(current=>({
      ...current,
      isDownloadingExcel:true
    }))

    const excel =  await getCashFlowExcel(type,state.currency,state.currencyReports,state.tc);

    const filename = type === 'flujo' ? 'Flujo de efectivo' : 'Proyección flujo de efectivo';

    if(excel!==undefined){
      saveAs(excel,`${filename}.xlsx`);
    }

    setState(current=>({
      ...current,
      isDownloadingExcel:false
    }))
  }

  /**
   * Update the tc to use for the records that need exchange rate
   * @param {number} tc - Tc to use
   * @returns {void}
   */
  const updateTc = (tc) =>
    setState((current) => ({
      ...current,
      tc,
    }));
  /**
   * Update the currency to use on the cash flow report generated with different currencies
   * @param {string} currency - 3 digit code of the currency to use for the reports
   * @returns {void}
   */
  const updateCurrencyReport = (currency) =>
    setState((current) => ({
      ...current,
      currencyReports: currency,
    }));

  /**
   * Update if must display the details of the cash flow
   * @param {boolean} displayDetails - Flag
   * @returns {void}
   */
  const updateDetails = (displayDetails) =>
    setState((current) => ({
      ...current,
      displayDetails,
    }));

  const research = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const areValidFilters = () => {
    if (state.currency === undefined) return false;

    if (state.currency !== "Todos") return true;

    if (state.tc <= 0) return false;

    if (state.currencyReports === undefined) return false;

    return true;
  };

  const setToggleDetails = () =>
    setState((current) => ({
      ...current,
      displayDetails: !current.displayDetails,
    }));

  /**
   * Set the id of the bank account
   * @param {number} id - Id of the bank account
   * @returns {void}
   */
  const setBankAccount = (id) =>
    setState((current) => ({
      ...current,
      bankAccountId: id,
    }));

  useEffect(() => {
    if (state.currency !== "Todos") {
      setState((current) => ({
        ...current,
        currencyReports: undefined,
      }));
    }
  }, [state.currency]);

  useEffect(() => {
    (async function () {
      if (
        state.currency === undefined ||
        state.institutionalTc === 0 ||
        state.tc === 0
      )
        return;

      setState((current) => ({
        ...current,
        cashFlow: undefined,
        isLoading: true,
      }));

      const apiCashFlow = await getCashFlow(
        state.currency,
        state.currencyReports,
        state.tc
      );

      setState((current) => ({
        ...current,
        cashFlow: apiCashFlow,
        isLoading: false,
      }));
    })();
  }, [state.refetch, state.institutionalTc]);

  useEffect(() => {
    (async function () {
      const tcPraxia = await GetTcp();

      setState((current) => ({
        ...current,
        tc: tcPraxia.saiko,
        institutionalTc: tcPraxia.saiko,
      }));
    })();
  }, []);

  return {
    banks,
    cashFlow: state,
    updateCurrencyReport,
    setBankAccount,
    updateCurrency,
    research,
    setToggleDetails,
    yesterDayColumn: parseDateToText(
      sub(new Date(), {
        days: 1,
      }),
      "medium"
    ),
    todayColumn: parseDateToText(new Date(), "medium"),
    tomorrowColumn: parseDateToText(addDays(new Date(), 1), "medium"),
    within7Days: parseDateToText(addDays(new Date(), 7), "medium"),
    within14Days: parseDateToText(addDays(new Date(), 14), "medium"),
    areValidFilters: areValidFilters(),
    updateTc,
    downloadExcelReport,
    updateDetails,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: `${
          type === "proyeccion"
            ? "/v2/bancos/proyeccion-flujo-efectivo"
            : "/v2/bancos/flujo-efectivo"
        }`,
        text: `${
          type === "proyeccion"
            ? "Proyección de flujo de efectivo"
            : "Flujo de efectivo"
        }`,
      },
    ],
  };
}
