import LoginContext from "context/Login/LoginContext";
import useDeviceInfo from "customHooks/useDeviceInfo";
import { validateSession } from "helpers/apis";
import { getUserPermissions } from "helpers/Apis/rolesApi";
import { useContext, useEffect, useState } from "react";
import { URL_CLIENT } from "routes/routes";

export default function useHeader() {
  const [showScreen, setShowScreen] = useState(true);

  const { setSessionCookie, setPermissions, profilePicture, userInfo } =
    useContext(LoginContext);

  const { browser, isMobile, os, isLoadedData } = useDeviceInfo();

  useEffect(() => {
    (async function () {
      if (!isLoadedData) return;

      const fetchedData = await validateSession({
        browser,
        isMobile,
        os,
      });

      if (!fetchedData) {
        window.location.href = URL_CLIENT;
        return;
      }

      if (fetchedData.userInfo.length === 0) {
        window.location.href = URL_CLIENT;
        return;
      }

      setSessionCookie(fetchedData);

      const permissions = await getUserPermissions();

      setPermissions(permissions.permissions);

      setShowScreen(false);
    })();
  }, [isLoadedData]);

  return {
    showScreen,
  };
}
