import React, { Fragment, useContext, useRef } from "react";
import { ContextPareto } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ui from "./styles.module.scss";
import { mnCurrency } from "helpers/money";

/**
 * 
 * @param {number} pareto - Percentage
 * @returns 
 */
function colorBg(pareto){

  if (pareto <= 80) {
    return "🟢";
  } else if (pareto <= 95) {
    return "🟡";
  } else if (pareto > 95) {
    return "🔴";
  } else {
    return ui.bgDanger;
  }
 
}

export default function Table() {
  const hook = useContext(ContextPareto);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (hook.isLoading) return <Spinner text={"Cargando"} hidden={false} />;

  return (
    <>
      <div className={ui.table}>
    <div className={ui.topHeader}>
    <p></p>
    <p>Moneda original de las facturas</p>
    <p>Moneda de la consulta</p>
    </div>

        <div className={ui.header}>
          <p>-</p>
          <p>Ejecutivo</p>
          <p>RFC</p>
          <p>Cliente</p>
          <p>{hook.base.id=== 2 ? 'Margen' : 'Facturación'} MXN</p>
          <p>{hook.base.id=== 2 ? 'Margen' : 'Facturación'} USD</p>
          <p>{hook.base.id=== 2 ? 'Margen' : 'Facturación'} {hook.currency}</p>
          <p>%</p>
          <p>% acumulado</p>
        </div>

        {hook.data.map((item, i) => (
          <Fragment key={`${key.current}-${i}`}>
            <div className={`${ui.row}`}>
              <p className="text-center justify-content-center">{colorBg(item.pareto)}</p>
              <p>{item.executive}</p>
              <p>{item.rfc}</p>
              <p className="text-left justify-content-start">{item.socialReason}</p>
              <p>{hook.base.id === 2 ? mnCurrency(item.mxnMargin) : mnCurrency(item.mxnSell)}</p>
              <p>{hook.base.id === 2 ? mnCurrency(item.usdMargin): mnCurrency(item.usdSell)}</p>
              <p>{hook.base.id === 2 ? mnCurrency(item.reportMargin) : mnCurrency(item.reportInvoiced)}</p>
              <p>{hook.base.id === 2 ? item.marginPercentage.toFixed(1) : item.invoicedPercentage.toFixed(1) }</p>
              <p>{item.pareto.toFixed(1)}</p>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}
