import React, { useContext, useEffect } from "react";
import { FormContext } from "pages/Directory/Documents/Edit/Form/Form";
import { DatesArea } from "./Styles";
import { DateSectionInputsV2 } from "../../Reminders/Inputs/RemindersInputs";
import { add } from "date-fns";

export default function EditDates({
  value,
  className = "",
  nameReminder = "reminderDate",
  nameExpiration = "expirationDate",
}) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document, register, setExpirationDate, setReminderDate } =
    useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <DatesArea>
          <DateSectionInputsV2
            forwardRef={register}
            dateRemainder={document.reminder.Date}
            reminderName={nameReminder}
            attentionName={nameExpiration}
            dateAttention={document.expiration.Date}
            attentionLabel="Vencimiento*"
            onReminderChange={setReminderDate}
            onAttenChange={setExpirationDate}
          />
        </DatesArea>
      ) : (
        <DatesArea>
          <DateSectionInputsV2
            forwardRef={register}
            dateRemainder={document.reminder.Date}
            reminderName={nameReminder}
            attentionName={nameExpiration}
            dateAttention={document.expiration.Date}
            attentionLabel="Vencimiento*"
            onReminderChange={setReminderDate}
            onAttenChange={setExpirationDate}
          />
        </DatesArea>
      )}
    </>
  );
}
