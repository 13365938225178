import React from "react";
import { useContext } from "react";
import { ContextUenAdmin } from "../..";
import style from "../../ModalAdd/ButtonAdd/styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";
// OK

export default function ButtonUpdate() {
  const hook = useContext(ContextUenAdmin);

  if (hook.isUpdatingUen)
    return (
      <div className={style.containerAddUen}>
        <p></p>
        <Spinner
          text={<LoadingText>Actualizando</LoadingText>}
          hidden={false}
        />
      </div>
    );

  return (
    <div className={style.containerAddUen}>
      <p className="text-danger">
        {!hook.isValidFormUpdate ? "🟥 Faltan campos por llenar" : null}
      </p>
      <ButtonV2
        form={hook.idFormUpdate}
        type="submit"
        disabled={!hook.isValidFormUpdate}
      >
        <span>Actualizar</span>
        <Icon icon="save" />
      </ButtonV2>
    </div>
  );
}
