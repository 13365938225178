import React, { useEffect, useState } from "react";
import { Select } from "../../../../../components/individual/inputs/Inputs";
import { GetInvoiceCustomersAvailable } from "../../../../../helpers/Apis/Directory";

/**
 * Load the associated clients of a customer
 * @param {object} props - Props component
 * @param {(number|string)} props.client - Id of the client
 * @param {object} props.document - Information of the document
 * @param {Function} props.onChange - Information of the client when select changes
 * @param {Function} props.onLoaded - Information when the clients load
 * @param {object} props.forwardRef - ref of the select input
 * @returns {JSX.Element} React component
 */
export default function InvoiceCustomers({
  css = "na",
  client = null,
  onChange = () => {},
  onLoaded = () => {},
  forwardRef = {},
}) {
  const [corporatives, setCorporatives] = useState([]);

  useEffect(() => {
    (async function () {
      // Customer has corporative, fetch them
      // const apiCorporatives = await GetCorporativesRelated(corporative);
      const apiCustomers = await GetInvoiceCustomersAvailable(client);

      // Update corporatives
      setCorporatives(apiCustomers);
    })();
  }, []);

  useEffect(() => {
    if (corporatives.length === 0) return;

    onLoaded(corporatives);
  }, [corporatives]);

  return (
    <div className={`${css} itemDocument`}>
      <Select
        addTodos={false}
        idSelect="customerPreinvoice"
        nameSelect="customerPreinvoice"
        value={client}
        textJSON="socialReason"
        valueJSON="id"
        onChange={(value) => {
          // console.log(value);
          onChange(value);
        }}
        label=""
        options={corporatives}
        forwardRef={forwardRef}
      />
    </div>
  );
}
