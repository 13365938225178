import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { updateBankAccount } from "helpers/Apis/Banks";
import React from "react";
import { useContext } from "react";
import { ContextEditBankAccount } from "..";

export const Edit = () => {
  const {
    isPerformingQuery,
    isValid,
    info,
    closeModal,
    setIsPerformingQuery,
    onUpdated,
    idBankAccount,
  } = useContext(ContextEditBankAccount);

  const attemptUpdateBankAccount = async () => {
    /**
     * @type {import("../../../../../../types/banks").DtoUpdateBankAccount}
     */
    const dto = {
      CLABE: info.CLABE,
      claveSat: info.bank.clave === undefined ? null : info.bank.clave,
      cuenta: info.cuenta,
      idBank: info.bank.id,
      socialReason: info.bank.info.label,
      typeAccount: info.typeAccount,
      id: idBankAccount,
      active: info.active,
    };

    setIsPerformingQuery(true);

    const wasUpdated = await updateBankAccount(dto);

    if (wasUpdated) {
      closeModal();
      onUpdated();
    }

    setIsPerformingQuery(false);
  };

  if (!isValid) return;

  if (isPerformingQuery)
    return (
      <>
        <hr />
        <div className="d-flex justify-content-end">
          <Spinner hidden={false} text="Editando cuenta..." />
        </div>
      </>
    );

  return (
    <>
      <hr />
      <div className="d-flex justify-content-end mt-2">
        <ButtonV2 onClick={attemptUpdateBankAccount}>Actualizar</ButtonV2>
      </div>
    </>
  );
};
