import { Select } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import { getTypeBankAccounts } from "helpers/Apis/Banks";
import React, { useState } from "react";
import { useEffect } from "react";

export const TypeBankAccount = ({ value = null, onChange = () => {} }) => {
  const [state, setState] = useState({
    isLoading: true,
    types: [],
  });

  useEffect(() => {
    (async function () {
      const apiTypeBankAccounts = await getTypeBankAccounts();

      setState({
        ...state,
        isLoading: false,
        types: apiTypeBankAccounts,
      });
    })();
  }, []);

  if (state.isLoading)
    <Spinner hidden={false} text="Cargando tipos de cuenta..." />;

  return (
    <Select
      options={state.types}
      textJSON="description"
      valueJSON="id"
      value={value}
      initialOption="Selecciona un tipo de cuenta"
      onChange={(value) => onChange(+value)}
    />
  );
};
