import React, { useEffect, useRef, useState } from "react";
import ModalV2 from "components/individual/Modals/ModalV2";
import { ContainerItem, Header } from "./styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";

/**
 * XML List providers
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useAddXml").LegalDocumentProvidersI} [props.providers=[]] - Providers list to show
 * @param {string} [props.idOpenModal=null] - Id of the button to open the modal
 * @param {(number:creditDays)=>void} [props.onConfirm=()=>{}] - Credit days to use
 * @returns {JSX.Element}
 */
export default function XmlProviders({
  providers = [],
  idOpenModal = null,
  onConfirm = () => {},
}) {
  const [provider, setProvider] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const key = useRef(window.crypto.randomUUID());

  useEffect(() => {
    const iconClose = document.getElementById("xmlListIconClose");
    const close = document.getElementById("cancelXmlList");

    if (!iconClose || !close) return;

    iconClose.remove();
    close.classList.add("d-none");
  }, [provider, providers, isOpen]);

  return (
    <ModalV2
      title="Selecciona un proveedor"
      idCloseIcon="xmlListIconClose"
      idCloseModal="cancelXmlList"
      cbOpen={() => setIsOpen(!isOpen)}
      close="sadasdsa"
      footer={
        <>
          {provider === null ? null : (
            <ButtonV2
              onClick={() => {
                document.getElementById("cancelXmlList").click();
                onConfirm({
                  creditDays: provider.creditDays,
                  idProvider: provider.id,
                });
              }}
            >
              Seleccionar
            </ButtonV2>
          )}
        </>
      }
      idOpenModal={idOpenModal}
      buttonText="Aceptar proveedor"
    >
      <Header>
        <b></b>
        <b>Razon social</b>
        <b>Dias de credito</b>
      </Header>
      {providers.map((provider,i) => (
        <ContainerItem key={`${key.current}-${i}`}>
          <input
            type="radio"
            name="provider"
            onClick={() => setProvider(provider)}
          />
          <p>{provider.socialReason}</p>
          <p>{provider.creditDays}</p>
        </ContainerItem>
      ))}
    </ModalV2>
  );
}
