import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
// Comentario

/**
 * @type {import("./types").StatePositionForm}
 */
const INITIAL_STATE = {
  positions: [],
  cost: 0,
  sell: 0,
  iva: undefined,
  isLoading: true,
};

/**
 * Handle the creation of multiple positions for the proyects
 * @param {import("./types").ParamFormPosition} params - Params to handle the information of the form
 * @returns {import("./types").ReturnUseFormPosition}
 */
export default function useFormPositionProyect({
  idPosition = 0,
  idProyect = 0,
  onCorrectSubmit = () => {},
  data = undefined,
  rfq = "",
  solped = "",
}) {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("react").MutableRefObject<import("components/individual/ProyectStatus/types").ProyectStatusOptionI[]>}
   */
  const options = useRef([
    {
      label: "Todos",
      value: null,
    },
    {
      label: "Urgente",
      value: "SolicitudUrgente",
    },
    {
      label: "Solicitud",
      value: "SolicitudNormal",
    },
    {
      label: "Propuesta",
      value: "Propuesta",
    },
    {
      label: "Activo",
      value: "Activo",
    },
    {
      label: "Terminado",
      value: "Terminado",
    },
    {
      label: "No Asignado",
      value: "NoAsignado",
    },
    {
      label: "Cancelado",
      value: "Cancelar",
    },
  ]);

  const positionSchema = yup.object().shape({
    id: yup
      .number()
      .min(1, "El id de la posición debe ser un número mayor a 0")
      .nullable(true)
      .default(null)
      .typeError("El id de la posición debe ser un número")
      .transform((data, original) => {
        if (isNaN(data)) return null;

        return +original;
      }),
    description: yup
      .string()
      .required("Obligatorio")
      .max(500, "La descripción es muy larga, porfavor borrar contenido")
      .typeError("Ingresa una descripción valida"),
    uen: yup
      .number()
      .required("Obligatorio")
      .min(1, "Selecciona una UEN valida")
      .typeError("Selecciona una UEN valida"),
    iva: yup
      .number()
      .required("Obligatorio")
      .min(0, "Selecciona un IVA valido")
      .typeError("Selecciona un IVA valido")
      .max(16, "Selecciona un IVA valido"),
    satCode: yup
      .string()
      .required("Obligatorio")
      .max(256, "Máximo 256 caracteres")
      .min(10, "Minimo 10 caracteres")
      .typeError("Selecciona una clave SAT valida"),
    satUm: yup
      .string()
      .required("Obligatorio")
      .max(256, "Máximo 256 caracteres")
      .min(6, "Minimo 6 caracteres")
      .typeError("Selecciona una unidad de medida SAT valida"),
    status: yup
      .string()
      .oneOf(
        [
          "SolicitudUrgente",
          "SolicitudNormal",
          "Propuesta",
          "Activo",
          "Terminado",
          "NoAsignado",
          "Cancelar",
        ],
        "Estatus inválido, selecciona una opción válida"
      )
      .required("Obligatorio")
      .transform((original, value) => {
        // if (solped === "" && data !== undefined) return "SolicitudUrgente";

        if(solped === "" || rfq==="") return "SolicitudUrgente";

        return original;
      })
      .typeError("La opcion no es un dato válido"),
    pos: yup.string().max(256, "Maximo 256 caracteres").required("Obligatorio"),

    ocCustomer: yup
      .string()
      .max(256, "Maximo 256 caracteres")
      .nullable(true)
      .transform((value, original) => {
        if (value === "") return null;
        return value;
      })
      .default(null),
    percentageOfCompletion: yup
      .number()
      .min(0, "Mínimo 0%")
      .max(100, "Máximo 100%")
      .required("Obligatorio")
      .typeError("Debe ser un número entre 0 y 100"),
    cost: yup
      .number()
      .required("Obligatorio")
      .min(0, "Mínimo $0.00")
      .when("ocCustomer", (ocCustomer, schema) => {
        if (ocCustomer === null || ocCustomer === "") return schema;

        return yup
          .number()
          .moreThan(
            0,
            "El costo debe ser mayor a $0.00 cuando tienes una OC del cliente"
          )
          .required("Obligatorio")
          .typeError(
            "El costo debe ser mayor a $0.00 cuando tienes una OC del cliente"
          );
      })
      .typeError("Debe ser un número válido"),
    sell: yup
      .number()
      .required("Obligatorio")
      .min(yup.ref("cost"), "Debe ser mayor o igual al costo de mano de obra")
      .when("ocCustomer", (ocCustomer, schema) => {
        if (ocCustomer === null || ocCustomer === "") return schema;

        return yup
          .number()
          .moreThan(yup.ref("cost"), "La venta debe ser mayor al costo")
          .required("Obligatorio")
          .typeError("La venta debe ser mayor al costo");
      })
      .typeError("Debe ser un número válido"),
  });

  /**
   * @type {import("./types").FormPositionDto}
   */
  const hook = useForm({
    resolver: yupResolver(positionSchema),
    shouldFocusError: true,
    defaultValues: {
      status: data?.status || retrieveStatusValueCombo(),
      percentageOfCompletion: data?.percentageOfCompletion || 0,
      cost: data?.cost || 0,
      sell: data?.sell || 0,
      uen: data?.uen || null,
      satCode: data?.satCode || null,
      satUm: data?.satUm || null,
      id: data?.id || null,
      description: data?.description || null,
      ocCustomer: data?.ocCustomer || null,
      pos: data?.pos || null,
    },
  });

  const setSell = (sell) =>
    setState((current) => ({ ...current, sell: sell === 0 ? "" : sell }));
  const setCost = (cost) =>
    setState((current) => ({ ...current, cost: cost === 0 ? "" : cost }));
  const setIva = (iva) =>
    setState((current) => ({ ...current, iva: iva === 0 ? "" : iva }));

  useEffect(() => {
    const value = retrieveStatusValueCombo();
    hook.setValue("status", value);
  }, [solped]);

  useEffect(() => {
    setState((current) => ({
      ...current,
      isLoading: true,
    }));

    hook.setValue("status", data?.status || retrieveStatusValueCombo());
    hook.setValue("percentageOfCompletion", data?.percentageOfCompletion || 0);
    hook.setValue("cost", data?.cost || 0);
    hook.setValue("sell", data?.sell || 0);
    hook.setValue("uen", data?.uen || null);
    hook.setValue("satCode", data?.satCode || null);
    hook.setValue("satUm", data?.satUm || null);
    hook.setValue("id", data?.id || null);
    hook.setValue("description", data?.description || null);
    hook.setValue("ocCustomer", data?.ocCustomer || null);
    hook.setValue("pos", data?.pos || null);

    setState((current) => ({
      ...current,
      isLoading: false,
      cost: data?.cost || 0,
      sell: data?.sell || 0,
      iva: data?.iva || 0,
    }));
  }, [data]);

  /**
   * Information submited correctly
   * @param {import("./types").PositionForm} dto - Information submited correctly on the form
   */
  const handleSubmitForm = (dto) => {
    if (idPosition === 0 || idProyect === 0) {

      const status = rfq ==="" || solped==="" ? 'SolicitudUrgente' : 'SolicitudNormal';

      onCorrectSubmit({...dto,status});
      hook.reset();
      hook.setValue("status", "SolicitudNormal");
      hook.setValue("cost", 0);
      hook.setValue("sell", 0);
      hook.setValue('iva',0);
      
      setState({
        ...INITIAL_STATE,
        isLoading: false,
        iva:0
      });

      return;
    }
  };

  function getStatus() {
    if (typeof data?.id !== "number") {
      if (solped.length > 0) {
        return "Solicitud normal";
      } else {
        return "Solicitud urgente";
      }
    }

    const status = hook.getValues("status");

    return (
      options.current.find((item) => item.value === status)?.label ||
      "No disponible"
    );
  }

  function retrieveStatusValueCombo() {
    const positionAlreadyCreatedOnDb = typeof data?.id === "number";

    if (positionAlreadyCreatedOnDb === false) {
      if (solped === "") return "SolicitudUrgente";
      return "SolicitudNormal";
    }

    if (data?.status === "SolicitudUrgente" && solped !== "")
      return "SolicitudNormal";

    return hook.getValues("status");
  }

  return {
    form: hook,
    ...state,
    getStatus,
    setSell,
    setCost,
    setIva,
    handleSubmitForm,
  };
}
