import InformativeTooltip from "components/individual/InfoTooltip";
import React, { forwardRef, useEffect, useState } from "react";
import { Label } from "structure/Document";
import {
  ErrorMessage,
  InputEmail,
  InputNumber,
  InputText,
  Phone,
} from "../../../../components/individual/inputs/Inputs";
import { RadioButton } from "../../../../components/individual/inputs/Radio";
import { typeCustomers } from "../../../../helpers/apis";
import { ExampleSocialReason } from "./Styles";
import ToggleComponent from "components/individual/inputs/Toggle";

export function TypeCustomer({
  optSelected = null,
  onClick = () => {},
  onLoaded = () => {},
  forwardRef = {},
  disabled = false
}) {
  const [types, setTypeCustomer] = useState([]);

  useEffect(() => {
    (async function () {
      const apiTypeCustomer = await typeCustomers();

      setTypeCustomer(apiTypeCustomer);
      onLoaded(apiTypeCustomer);
    })();
  }, []);

  return (
    <>
      <label htmlFor="">Tipo de cliente *</label>
      <div>
        <RadioButton
          attributeValue="value"
          attributeId="value"
          attributeText="text"
          name="typeCustomer"
          id="typeCustomer"
          options={types}
          idElements="typeCustomerContainer"
          optSelected={optSelected}
          onClick={(value) => onClick(value)}
          forwardRef={forwardRef}
          disabled={disabled}
        />
      </div>
    </>
  );
}

/**
 * Render a toggle input to indicate if generates CxP or not
 * @param {import("./types").PropsGenerateCxP} props - Props  
 * @returns {JSX.Element}
 */
export function GenerateCxp({value = false , idTypeCustomer = 1 ,  onChange = () => {}}){

  if(idTypeCustomer===1) return <></>

  return <div>
    <Label>Genera CxP</Label>
    <ToggleComponent
      labelActive="Si, genera"
      labelInactive="No, no genera"
      onChange={onChange}
      isChecked={value}
      
    />
  </div>
}

/**
 * Request for the social reason of the user
 * @param {object} props - Props
 * @returns {JSX.Element}
 */
export function SocialReason({ value = null, forwardRef = {}, errors = {} }) {

  const [socialReason,setSocialReason] = useState(value);

  return (
    <div className="socialReason">
      <Label required={socialReason==="" ? true : false}>Razón social
      
      <InformativeTooltip>
        <p>Se debe registrar en mayusculas y sin el régimen societario</p>
        <p><b>Tal y como se encuentra en la Cédula de Identificación Fiscal y Constancia de Situación Fiscal</b></p>
        <p>Respetando números, espacios y signos de puntuación</p>
        <p><b>Ejemplo</b></p>
        <ExampleSocialReason>
          <span>Empresa Receptora S.A. DE C.V</span>
          <span>EMPRESA RECEPTORA</span>
        </ExampleSocialReason>
      </InformativeTooltip>
      
      </Label>
      <InputText
        // labelTxt="Razon social *"
        id="socialReason"
        name="socialReason"
        defaultValue={socialReason === null ? socialReason : socialReason.toUpperCase()}
        onChange={value=>setSocialReason(value.toUpperCase())}
        placeholderTxt="Escribe aquí (Obligatorio)"
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors["socialReason"]?.message} />
    </div>
  );
}

export function CommercialName({ value = null, forwardRef = {}, errors = {} }) {
  return (
    <div className="commercialName">
      <InputText
        labelTxt="Nombre comercial *"
        id="commercialName"
        name="commercialName"
        defaultValue={value}
        forwardRef={forwardRef}
        placeholderTxt="Escribe aquí (Obligatorio)"
      />

      <ErrorMessage message={errors["commercialName"]?.message} />
    </div>
  );
}

export function ShortName({ value = null, forwardRef = {}, errors = {} }) {
  return (
    <div className="shortName">
      <InputText
        labelTxt="Nombre corto *"
        id="shortName"
        name="shortName"
        defaultValue={value}
        placeholderTxt="Escribe aquí (Obligatorio)"
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors["shortName"]?.message} />
    </div>
  );
}

export function Street({
  value = null,
  label = "Calle *",
  id = "streetCustomer",
  name = "streetCustomer",
  placeholder = "Escribe aquí",
  css = "street",
  forwardRef = {},
  errors = {},
}) {
  return (
    <div className={css}>
      <InputText
        defaultValue={value}
        id={id}
        forwardRef={forwardRef}
        name={name}
        labelTxt={label}
        placeholderTxt={placeholder}
      />

      <ErrorMessage message={errors[id]?.message} />
    </div>
  );
}

export function AdressNumber({
  placeholder = "Escribe aqui",
  label = "??????",
  value = null,
  name,
  id,
  css,
  forwardRef = {},
  errors = {},
}) {
  return (
    <div className={css}>
      <InputText
        defaultValue={value}
        id={id}
        name={name}
        placeholderTxt={placeholder}
        labelTxt={label}
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors[id]?.message} />
    </div>
  );
}

export function Colony({
  value = null,
  id = "customerColony",
  name = "customerColony",
  forwardRef = {},
  errors = {},
}) {
  return (
    <div className="colony">
      <InputText
        defaultValue={value}
        id={id}
        name={name}
        labelTxt="Colonia *"
        placeholderTxt="Escribe aquí"
        // aditionalClasses="colony"
        forwardRef={forwardRef}
      />
      <ErrorMessage id="errorCustomerColony" message={errors[name]?.message} />
    </div>
  );
}

export function City({
  id = "customerCity",
  value = null,
  name = "customerCity",
  forwardRef = {},
  errors = {},
}) {
  return (
    <div className="city">
      <InputText
        id={id}
        name={name}
        placeholderTxt="Escribe aquí"
        defaultValue={value}
        labelTxt="Ciudad *"
        // aditionalClasses="city"
        forwardRef={forwardRef}
      />
      <ErrorMessage id="errorCustomerCity" message={errors[name]?.message} />
    </div>
  );
}

export function State({
  id = "customerState",
  value = null,
  name = "customerState",
  forwardRef = {},
  errors = { errors },
}) {
  return (
    <div className="state">
      <InputText
        id={id}
        // aditionalClasses="state"
        labelTxt="Estado *"
        placeholderTxt="Escribe aqui"
        aditionalClasses="state"
        name={name}
        defaultValue={value}
        forwardRef={forwardRef}
      />
      <ErrorMessage id="errorCustomerState" message={errors[name]?.message} />
    </div>
  );
}

export function PhoneNumber({
  value = 52,
  forwardRef = {},
  lada = "",
  number = "",
}) {
  useEffect(() => {
    document.getElementById("isValid_phone").value = 1;
  }, []);

  return (
    <div className="phone">
      <Phone
        htmlFor="phone"
        labelText="Telefono fijo"
        nameInput="phone"
        idInput="phone"
        placeholderTxt="Lada + Numero"
        forwardRef={forwardRef}
        value={value}
        valueLada={lada}
        valueNumber={number}
      />

      <ErrorMessage id="error_phone" />
    </div>
  );
}

export function CellNumber({
  value = 52,
  forwardRef = {},
  lada = "",
  number = "",
}) {
  useEffect(() => {
    document.getElementById("isValid_movil").value = 1;
  }, []);

  return (
    <div className="cellphone">
      <Phone
        htmlFor="phone"
        labelText="Celular"
        nameInput="movil"
        idInput="movil"
        placeholderTxt="Lada + Numero"
        forwardRef={forwardRef}
        value={value}
        valueLada={lada}
        valueNumber={number}
      />

      <ErrorMessage id="error_movil" />
    </div>
  );
}

export function Email({
  value = null,
  forwardRef = {},
  label = "Email facturacion *",
  name = "emailCustomer",
  id = "emailCustomer",
  errors = {},
}) {
  return (
    <div>
      <InputEmail
        labelTxt={label}
        name={name}
        idEmail={id}
        value={value}
        placeholderTxt="Escribe aquí"
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors[name]?.message} />
    </div>
  );
}

export function CreditDays({ value = null, forwardRef = {}, errors = {} }) {
  return (
    <div>
      <InputNumber
        defaultValue={value}
        decimal={false}
        htmlFor="creditDays"
        id="creditDays"
        name="creditDays"
        labelTxt="Dias credito"
        placeholderTxt="Escribe aqui"
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors["creditDays"]?.message} />
    </div>
  );
}

export function Corporative({
  value = null,
  forwardRef = {},
  idCorporative = null,
}) {
  return (
    <>
      <InputText
        id="fakeCorporative"
        htmlFor="fakeCorporative"
        name="fakeCorporative"
        labelTxt="Corporativo"
        defaultValue={value}
        placeholderTxt="Sin corporativo asociado"
        readonly={true}
      />

      <input
        type="hidden"
        id="idCorporative"
        name="idCorporative"
        ref={forwardRef}
        readOnly={true}
        value={
          idCorporative === null || idCorporative === undefined
            ? ""
            : idCorporative
        }
      />
    </>
  );
}

// export function AssociateCorporative(){

// }
