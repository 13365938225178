import React from "react";
import { MobileMenuContext } from "../..";
import { useContext } from "react";
import NavigationItem from "../../NavigationItem";
import ExcelTemplate from "structure/SetUpExcel/ExcelTemplate";

export default function Setup() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "datosExcel") return <></>;

  return (
    <>
      <NavigationItem
        icon="home"
        label="Inicio > Procesos especiales > Plantillas"
        subMenu="procesos_especiales"
      />

      <ExcelTemplate
        title="Conceptos de egreso"
        type="conceptosEgresos"
        children={<NavigationItem icon="excel" label="Egresos" />}
      />

      <ExcelTemplate
        title="Tipos de egreso"
        type="tiposDeEgreso"
        children={<NavigationItem icon="excel" label="Egresos Tipos" />}
      />

      <ExcelTemplate
        title="Conceptos de ingreso"
        type="conceptosIngresos"
        children={<NavigationItem icon="excel" label="Ingresos" />}
      />

      <ExcelTemplate
        title="Tipos de ingreso"
        type="tiposDeIngreso"
        children={<NavigationItem icon="excel" label="Ingresos Tipos" />}
      />

      <ExcelTemplate
        title="Catalogo"
        type="catalogo"
        children={
          <NavigationItem icon="excel" label="Catalogo productos y servicios" />
        }
      />

      <ExcelTemplate
        title="Contactos"
        type="contactos"
        children={<NavigationItem icon="excel" label="Contactos" />}
      />

      <ExcelTemplate title="Directorio" type="directorio">
        <NavigationItem icon="excel" label="Directorio" />
      </ExcelTemplate>

      <ExcelTemplate title="Sector de mercado" type="sectorMercado">
        <NavigationItem icon="excel" label="Sector de mercado" />
      </ExcelTemplate>
    </>
  );
}
