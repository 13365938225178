import MonthSelect from "components/general/Forms/Selects/Month";
import YearSelect from "components/general/Forms/Selects/Year";
import React from "react";
import { ContextInvoiceReception } from "../..";
import { useContext } from "react";

export default function DateFilter() {
  const hook = useContext(ContextInvoiceReception);

  if (!hook.isForAccounting) return <></>;

  return (
    <>
      <YearSelect onChange={hook.setYear} from={new Date().getFullYear() - 10} value={hook.year}/>

      <MonthSelect onChange={hook.setMonth} value={hook.month}/>
    </>
  );
}
