import React, { useContext } from "react";
import Table from "components/general/Table";

/**
 *
 * @param {import('../usePaymentReminder/types').TableReminderPayments} props
 * @returns {JSX.Element}
 */
export default function TableReminderPayments(props) {
  return <Table {...props} />;
}
    