import React, { useContext } from "react";
import { ContextTerminateContract } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import Icon from "components/individual/HeaderIcon";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function Footer() {
  const hook = useContext(ContextTerminateContract);

  if (hook.isCancelling)
    return (
      <div className="d-flex justify-content-end align-items-center">
        <Spinner hidden={false} text={"Cancelando"} />
      </div>
    );

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ButtonV2 onClick={hook.cancelContract} disabled={hook.motive.length<=0}>
        <span>Confirmar cancelación</span>
        <Icon icon="save" />
      </ButtonV2>
    </div>
  );
}
