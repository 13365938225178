import { ACTIONS } from "./Actions";

/**
 * @type{import("../../../../../../types/winQuote").WinQuoteStateI}
 */
export const initialState = {
  creditDays: 0,
  idContractParent: null,
  tc: {
    number: 0,
    text: "$0.00",
  },
  customer: {
    client: {
      id: 0,
      rfc: "Initial data client",
      socialRazon: "Initial data client",
      isActive: false,
    },
    provider: {
      id: 0,
      rfc: "Initial data provider",
      socialRazon: "Initial data provider",
      isActive: false,
    },
  },
  documents: {
    quote: {
      comments: [],
      items: [],
      contact: {
        anniversary: null,
        email: null,
        id: null,
        isCollectionContact: null,
        isPaymentContact: null,
        lastName1: null,
        name: null,
        workTitle: null,
        cell: null,
        lastName2: null,
        middleName: null,
        phone: null,
      },
      currentComment: null,
      isCommentEditing: false,
      dates: {
        createdDate: null,
        expirationDate: null,
        reminderDate: null,
      },
    },
    order: {
      comments: [],
      documentItems: [],
      currentComment: null,
      contact: {
        anniversary: null,
        email: null,
        id: null,
        isCollectionContact: null,
        isPaymentContact: null,
        lastName1: null,
        name: null,
        workTitle: null,
        cell: null,
        lastName2: null,
        middleName: null,
        phone: null,
      },
      isCommentEditing: false,
      dates: {
        createdDate: null,
        invoiceDate: null,
        reminderDate: null,
      },
    },
    contract: {
      comments: [],
      documentItems: [],
      currentComment: null,
      contact: {
        anniversary: null,
        email: null,
        id: null,
        isCollectionContact: null,
        isPaymentContact: null,
        lastName1: null,
        name: null,
        workTitle: null,
        cell: null,
        lastName2: null,
        middleName: null,
        phone: null,
      },
      isCommentEditing: false,
      dates: {
        endDate: new Date(),
        initialDate: new Date(),
        reminderDate: new Date(),
      },
    },
    odc: {
      comments: [],
      documentItems: [],
      currentComment: null,
      contact: {
        anniversary: null,
        email: null,
        id: null,
        isCollectionContact: null,
        isPaymentContact: null,
        lastName1: null,
        name: null,
        workTitle: null,
        cell: null,
        lastName2: null,
        middleName: null,
        phone: null,
      },
      isCommentEditing: false,
      dates: {
        createdDate: null,
        reminderDate: null,
        sendDate: null,
      },
    },
  },
  footer: {
    currency: {
      code: "MXN",
      id: 1,
    },
    margin: {
      number: 0,
      text: "$0.00",
    },
    pursharse: {
      number: 0,
      text: "$0.00",
    },
    sell: {
      number: 0,
      text: "$0.00",
    },
    tc: {
      number: 0,
      text: "$0.0000",
    },
  },
  items: [],
  currentItem: {},
  interfaceControl: {
    disableCost: false,
    disableCurrency: false,
    datesLabels: {
      date1: "Creación",
      date2: "Vigencia",
      date3: "Recordatorio",
    },
    menu: [
      {
        active: true,
        idType: 1,
        type: "Cotización",
      },
      {
        active: false,
        idType: 2,
        type: "Pedido",
      },
      {
        active: false,
        idType: 3,
        type: "Orden de compra",
      },
      {
        active: false,
        idType: 6,
        type: "Contrato",
      },
    ],
    needContract: false,
    providers: [],
    contacts: {
      client: [],
      provider: [],
    },
    indexActiveMenu: 0,
    itemHeaderLabel: "Precio",
    multiplesProviders: false,
  },
};

/**
 * Win reducer
 * @param {import("../../../../../../types/winQuote").WinQuoteStateI} state
 * @param {import("../../../../../../types/winQuote").WinQuoteActionsTypes} action
 * @returns {import("../../../../../../types/winQuote").WinQuoteStateI}
 */
export const winReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_PROVIDER:
      return {
        ...state,
        customer: {
          ...state.customer,
          provider: action.payload,
        },
      };
    case ACTIONS.SET_CLIENT:
      return {
        ...state,
        customer: {
          ...state.customer,
          client: action.payload,
        },
      };
    case ACTIONS.SET_INDEX_ACTIVE_MENU:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          indexActiveMenu: action.payload,
        },
      };
    case ACTIONS.SET_CREDIT_DAYS:
      return {
        ...state,
        creditDays: action.payload,
      };
    case ACTIONS.SET_ORDER_DATE2:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            dates: {
              ...state.documents.order.dates,
              invoiceDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_ORDER_DATE3:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            dates: {
              ...state.documents.order.dates,
              reminderDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_CONTRACT_DATE1:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            dates: {
              ...state.documents.contract.dates,
              initialDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_CONTRACT_DATE2:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            dates: {
              ...state.documents.contract.dates,
              endDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_CONTRACT_DATE3:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            dates: {
              ...state.documents.contract.dates,
              reminderDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_ODC_DATE2:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            dates: {
              ...state.documents.odc.dates,
              sendDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_ODC_DATE3:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            dates: {
              ...state.documents.odc.dates,
              reminderDate: action.payload,
            },
          },
        },
      };
    case ACTIONS.SET_QUOTE_CONTACT:
      return {
        ...state,
        documents: {
          ...state.documents,
          quote: {
            ...state.documents.quote,
            contact: action.payload,
          },
        },
      };
    case ACTIONS.SET_ORDER_CONTACT:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            contact: action.payload,
          },
        },
      };
    case ACTIONS.SET_CONTRACT_CONTACT:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            contact: action.payload,
          },
        },
      };
    case ACTIONS.SET_ODC_CONTACT:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            contact: action.payload,
          },
        },
      };
    case ACTIONS.SET_MONEY_INFORMATION:
      return {
        ...state,
        moneyInfo: action.payload,
      };
    // case ACTIONS.SET_EDIT_ITEM:
    //   return {
    //     ...state,
    //     documents: {
    //       ...state.documents,
    //       odc: {
    //         ...state.documents.odc,
    //         items: action.payload,
    //         hola: "hola",
    //       },
    //     },
    //   };
    case ACTIONS.SET_EDIT_ODC_ITEMS:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            documentItems: action.payload,
          },
        },
      };
    case ACTIONS.SET_REMOVE_ITEM:
      return {
        ...state,
        items: action.payload,
      };
    case ACTIONS.SET_ORDER_CURRENT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            currentComment: action.payload,
          },
        },
      };
    case ACTIONS.SET_ODC_CURRENT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            currentComment: action.payload,
          },
        },
      };
    case ACTIONS.SET_CONTRACT_CURRENT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            currentComment: action.payload,
          },
        },
      };
    case ACTIONS.SET_ORDER_COMMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_ODC_COMMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            comments: action.payload,
          },
        },
      };

    case ACTIONS.SET_CONTRACT_COMMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            comments: action.payload,
          },
        },
      };

    case ACTIONS.SET_DELETE_ORDER_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_DELETE_ODC_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_DELETE_CONTRACT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_OPEN_TO_EDIT_ORDER_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            comments: action.payload.comment,
            isCommentEditing: action.payload.isEditing,
          },
        },
      };
    case ACTIONS.SET_OPEN_TO_EDIT_ODC_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            comments: action.payload.comment,
            isCommentEditing: action.payload.isEditing,
          },
        },
      };
    case ACTIONS.SET_OPEN_TO_EDIT_CONTRACT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            comments: action.payload.comment,
            isCommentEditing: action.payload.isEditing,
          },
        },
      };
    case ACTIONS.SET_EDIT_ORDER_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          order: {
            ...state.documents.order,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_EDIT_ODC_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            comments: action.payload,
          },
        },
      };
    case ACTIONS.SET_EDIT_CONTRACT_COMMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          contract: {
            ...state.documents.contract,
            comments: action.payload,
          },
        },
      };

    case ACTIONS.SET_EDIT_CATALOGUE:
      return {
        ...state,
        catalogue: action.payload,
      };
    case ACTIONS.SET_GENERATE_CONTRACT:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          needContract: action.payload,
        },
      };
    case ACTIONS.SET_ACTIVE_MENU_OPTION:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          menu: action.payload,
        },
      };
    case ACTIONS.SET_TOTAL_IMPORT_PURSHACE:
      return {
        ...state,
        footer: {
          ...state.footer,
          pursharse: action.payload,
        },
      };
    case ACTIONS.SET_MARGIN:
      return {
        ...state,
        footer: {
          ...state.footer,
          margin: action.payload,
        },
      };
    case ACTIONS.SET_DATE_LABLES:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          datesLabels: action.payload,
        },
      };
    case ACTIONS.SET_ITEM_HEADER:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          itemHeaderLabel: action.payload,
        },
      };
    case ACTIONS.SET_WIN_QUOTE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SET_LOAD_PROVIDER_CONTACT:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          contacts: {
            ...state.interfaceControl.contacts,
            provider: action.payload,
          },
        },
      };
    case ACTIONS.SET_LOAD_CLIENT_CONTACT:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          contacts: {
            ...state.interfaceControl.contacts,
            client: action.payload,
          },
        },
      };
    case ACTIONS.SET_FOOTER:
      return {
        ...state,
        footer: action.payload,
      };
    case ACTIONS.SET_LOAD_INITIAL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };
    case ACTIONS.SET_DELETE_ODC_ITEM:
      return {
        ...state,
        documents: {
          ...state.documents,
          odc: {
            ...state.documents.odc,
            documentItems: action.payload,
          },
        },
      };
    case ACTIONS.SET_MULTIPLE_PROVIDERS:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          multiplesProviders: action.payload,
        },
      };
    case ACTIONS.SET_BLOCK_ODC:
      return {
        ...state,
        interfaceControl: {
          ...state.interfaceControl,
          disableCost: action.payload.disableCost,
          disableCurrency: action.payload.disableCurrency,
        },
      };

    default:
      return initialState;
  }
};
