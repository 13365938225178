import React, { useContext } from "react";
import { AssociateFileCommentsContext } from "..";
import style from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function BoxComment() {
  const { setComment, comment, addNewFileComment } = useContext(
    AssociateFileCommentsContext
  );

  return (
    <div className={style.boxComment}>
      <textarea
        placeholder="Escribe aquí"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />

      <ButtonV2 disabled={comment.length <= 0} onClick={addNewFileComment}>
        <span>Comentar</span>
        <Icon icon="send" />
      </ButtonV2>

      {comment.length >= 1001 ? (
        <p className="text-danger">Maximo 1000 caracteres</p>
      ) : null}
    </div>
  );
}
