import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Warning } from "helpers/alerts";
import React from "react";
import { Link } from "react-router-dom";

/**
 *
 * @param {{customer:number,rowInfo:object,socialReason:string, rfc:string }} { customer, rowInfo,socialReason, rfc }
 * @returns {React.JSXElementConstructor}
 */

export const WinQuoteButton = ({ customer, rowInfo }) => {
  return (
    <>
      {/* {rowInfo.idStatus === 1 ? (
        <ButtonV2
          id="winQuote"
          className={`button mr-3`}
          onClick={() => {
            Warning(" Esta función esta en mantenimiento 🛠️🛠️🛠️ ", () => {});
          }}
        >
          Ganar
        </ButtonV2>
      ) : null} */}
      {rowInfo.idStatus === 1 ? (
        <Link
          to={`./${customer}/ganar2/${rowInfo.idDocument}`}
        >
          <ButtonV2 id="winQuote" className={`button mr-3`}>
            Ganar
          </ButtonV2>
        </Link>
      ) : null}
    </>
  );
};
