import React, { useContext, useEffect } from "react";
import DocumentContext from "../../ContextDocument";
import { Label } from "structure/Document";
import Select from "components/individual/SelectV4";
import { ContextModalConcept } from "../ConceptsSection";

export default function ConceptCombo() {
  const hookDoc = useContext(DocumentContext);

  const hook = useContext(ContextModalConcept);

  useEffect(() => {
    if (hook.isOpen && hookDoc.actionType === "Editar") {
      hook.setConcept(hookDoc.documentConcepts.currentConcept);
    }
  }, [hook.isOpen]);

  if(hookDoc.actionType === "Editar" && hook.concept === undefined) return <></>

  return (
    <>
      <Label>
        Concepto de {hookDoc.documentType === 3 ? "egreso" : "ingreso"}
      </Label>
      <Select
        labelToDisplay="description"
        onChange={(item) => hook.setConcept(item)}
        defaultSelected={{
          keyToCompare: "id",
          value: hook.concept?.id||null,
        }}
        options={async () => [
          {
            id: null,
            disabled: true,
            description: "-- SELECCIONA --",
          },

          ...hookDoc.documentConcepts?.concepts,
        ]}
      />
    </>
  );
}
