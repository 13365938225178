
/**
 * @type {string}
 * @example http://localhost:8080/api/ 
 */
export const URL_BASE = process.env.REACT_APP_BACKEND;

export const URL_CLIENT = process.env.REACT_APP_FRONTEND;

// Don't delete!
export const ULR_MIZAR =
  "http://mizarefac.com.mx:8080/WsFactExternos33/Comprobantes.asmx?WSDL";

/**
 * @example 'http://localhost:3000/api'
 */
export const URL_GRUPO_AMPLUS = 'https://praxiaapmlusback.azurewebsites.net/api';