import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useBankAccount } from "structure/BankAccount/hooks/useBankAccount";
import BankContext from "./BankContext";

/**
 * State to handle the bank account of the application
 * @type {import("./types").BankStateI}
 */
const INITIAL_STATE = {
  account: null,
  isLoadingAccount: true,
  id: null,
};

export default function BankState({ children }) {
  const [state, setState] = useState(INITIAL_STATE);
  const hook = useBankAccount(state.id);

  const query = useQueryParams();

  const setAccountToUse = (account) => {
    setState((current) => ({
      ...current,
      id: account,
    }));
  };

  useEffect(() => {
    const id = query.cuenta === undefined ? null : +query.cuenta;

    setAccountToUse(id);
  }, [query]);

  useEffect(() => {
    if (hook.account === null) return;

    if (Object.keys(hook.account).length <= 2) return;

    setState((current) => ({
      ...current,
      account: hook.account,
      // id:hook.account.id
    }));
  }, [hook.account]);

  useEffect(() => {
    let isLoading = true;

    if (Object.keys(hook.account).length >= 2) {
      isLoading = false;
    }

    setState((current) => ({
      ...current,
      isLoadingAccount: isLoading,
    }));
  }, [hook.isLoading, hook.account]);

  return (
    <BankContext.Provider
      value={{
        ...state,
        setAccountToUse,
      }}
    >
      {children}
    </BankContext.Provider>
  );
}
