import { SET_TO_ADD,SET_TO_DELETE } from '../../types/index';

export default function(state,action){
    switch(action.type){
        case SET_TO_ADD:
            return {
                ...state,
                addFilter:action.payload
            }
        
        // Check whick ones are mustErase = true
        case SET_TO_DELETE:
            return {
                ...state,
                quitFilter:action.payload.filter(executive=>executive.mustErase===true)
            }
    }
}