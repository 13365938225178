import React from "react";
import useContract from "customHooks/useContract/ContractState";
import useQueryParams from "customHooks/useQueryParams";
import { ContractContext } from "customHooks/useContract/ContractContext";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import ui from "structure/ODC/styles.module.scss";

const { Provider } = ContractContext;

export default function EditOdc() {
  const { idCliente, modulo, documento } = useQueryParams();
  const useContractValues = useContract({
    idCliente: +idCliente || null,
    module: modulo,
    idOdc: +documento,
  });

  return (
    <Provider value={{ ...useContractValues, module: modulo }}>
      <div className={ui.container}>
        <BreadCrumV2 path={useContractValues.odcBreadcrum} />

        <AddDocument
          actionType="Editar"
          customerId={+idCliente}
          documentType={3}
          documentId={+documento}
        />
      </div>
    </Provider>
  );
}
