import { Modal } from "components/general/Modal";
import useVersion from "customHooks/useVersion";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../index.module.scss";
import DeleteCookie from "structure/DeleteCookies";
import Dialog from "structure/Dialog";

export const Version = () => {
  const { id, openVersionDetails, version, lastRelease, dialog } = useVersion();

  return (
    <>
      {/* <Dialog title="Favor de borrar las cookies" forwardRef={dialog.refDialog} closeCallback={dialog.close}>
        {lastRelease === undefined ? null : <DeleteCookie versionInfo={lastRelease.body}/>}
      </Dialog> */}

      {lastRelease === undefined ? null : (
        <Modal
          title="Detalles de la version"
          propsOpenModal={{
            className: "d-none",
            id: id.current,
          }}
        >
          {() => (
            <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {lastRelease.body}
              </ReactMarkdown>
              <hr />
            </div>
          )}
        </Modal>
      )}

      {version === undefined ? null : (
        <p className={scss.item} onClick={openVersionDetails}>
          <TextIcon icon="info" text={`Acerca de ${lastRelease.name}`} />
        </p>
      )}
    </>
  );
};
