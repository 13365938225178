import { Spinner } from "components/individual/Spinner/Spinner";
import useItems from "customHooks/useItems";
import { calculateItemInfo } from "helpers/money";
import React, { createContext } from "react";
import CreatableSelect from "react-select/creatable";
import ModalExistingItem from "./ModalExistingItem";
import ModalNewItem from "./ModalNewItem";

/**
 * Context of the component
 * @type {React.Context<import("./types").CatalogueContextI>}
 */
export const ContextCatalogue = createContext();
const { Provider } = ContextCatalogue;

/**
 * Render select combo of the catalogue
 * @param {object} props - Props
 * @param {(object:import("types/typedef/catalogue").CalculatedItemI)=>void} props.onSelect - Information of the item selected (existing or created)
 * @param {"MXN"|"USD"|null} props.predominantCurrency - Predominant currency to use
 * @param {number?} props.tc - TC to use in case element selected has a different currency from the predominant
 * @param {"cotizacion"|"pedido"|"contrato"|"odc"} props.typeDocument - Type of document that's being created
 * @returns {JSX.Element} Combo catalogue
 */
export default function Catalogue({
  onSelect = () => {},
  predominantCurrency = null,
  typeDocument,
  tc = 0,
}) {
  const idOpenExistingItem = window.crypto.randomUUID();

  const {
    catalogue,
    isLoading,
    idBtnOpenNewItemCatalogue,
    handleNewItem,
    setItem,
    item,
    addToCatalogue,
  } = useItems();

  /**
   * Handle the new item added into combo
   * @param {import("./types").NewItemCatalogue} item - Information of the item selected
   */
  const handleNewItemCatalogue = (item) => {
    addToCatalogue(item);
    onSelect(calculateItemInfo(item));
    // setItem(null);
  };

  const handleExistingItem = (item) => {
    onSelect(calculateItemInfo(item, predominantCurrency, tc));
    setItem(null);
  };

  /**
   *
   * @param {import("types/typedef/catalogue").CatalogueItemI|{
   *  label:string,
   *  value:string,
   *  __isNew__?:true
   * }} item
   */
  const handleOnChange = (item) => {
    if (!item) {
      setItem(null);
      return;
    }

    const isNewItem = Object.keys(item).includes("__isNew__") ? true : false;

    if (isNewItem) {
      handleNewItem({
        ...item,
        isNewItem,
      });
    } else {
      setItem({ ...item, isNewItem });
      openModalSpecifications();
    }

    function openModalSpecifications() {
      document.getElementById(idOpenExistingItem).click();
    }
  };

  return (
    <Provider
      value={{
        catalogue,
        isLoading,
        idBtnOpenNewItemCatalogue,
        item,
        typeDocument,
      }}
    >
      {isLoading ? (
        <Spinner hidden={false} text="Cargando catálogo..." />
      ) : (
        <CreatableSelect
          isClearable={true}
          options={catalogue}
          placeholder="Selecciona o crea"
          onChange={handleOnChange}
          value={item}
        />
      )}

      <ModalNewItem
        onNewItem={(item) => {
          console.log(item);
          handleNewItemCatalogue(item);
        }}
        onClose={() => setItem(null)}
      />
      <ModalExistingItem
        item={item}
        idOpenModal={idOpenExistingItem}
        onClose={() => setItem(null)}
        onChange={(item) => {
          handleExistingItem(item);
        }}
      />
    </Provider>
  );
}
