import useDialog from "customHooks/useDialog";
import React, { useRef } from "react";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import PillFilterMobile from "components/individual/PillFilterMobile";

/**
 * Render the filter for mobile view
 * @param {import("./types").PropsFilterMobile} props - Props
 * @returns {JSX.Element}
 */
export default function FilterMobile({
  children = <></>,
  pills = [],
  renderSearchBtn = true,
  onSearch = () => {},
  props = {}
}) {
  const dialog = useDialog();

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div  {...props} className={`${ui.filterMobile} ${props.className}`}>
      <div>
      <ButtonV2 onClick={dialog.open}>
        <Icon icon="filter" />
      </ButtonV2>

      </div>

      {pills.map((pill, i) => (
        <PillFilterMobile
          label={pill.label}
          value={pill.value}
          key={`${key.current}-${i}`}
        />
      ))}

      <Dialog
        footer={
          <div className={ui.footerFilter}>
            {renderSearchBtn ? (
              <ButtonV2
                onClick={() => {
                  dialog.close();
                  onSearch();
                }}
              >
                <Icon icon="search" />
              </ButtonV2>
            ) : null}
          </div>
        }
        title="Filtros"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
      >
        <div className={ui.contentFilter}>{children}</div>
      </Dialog>
    </div>
  );
}
