/**
 * @type {import("./types").ActionsI}
 */
export const ActionType = {
  SET_REGULAR_COMMENTS: "SET_REGULAR_COMMENTS",
  SET_TC: "SET_TC",
  SET_CURRENCY: "SET_CURRENCY",
  SET_CFDI: "SET_CFDI",
  SET_PAY_FORM: "SET_PAY_FORM",
  SET_PAY_METHOD: "SET_PAY_METHOD",
  SET_INITAL_CREATE_DOCUMENT: "SET_INITAL_CREATE_DOCUMENT",
  SET_SOCIAL_REASON: "SET_SOCIAL_REASON",
  SET_CONTACT: "SET_CONTACT",
  SET_PERIOCITY: "SET_PERIOCITY",
  SET_NOTES_AND_CONSIDERATIONS: "SET_NOTES_AND_CONSIDERATIONS",
  SET_COMMENTS: "SET_COMMENTS",
  SET_IS_PERFORMING_QUERY: "SET_IS_PERFORMING_QUERY",
  SET_INITIAL_EDIT_DOCUMENT: "SET_INITIAL_EDIT_DOCUMENT",
  SET_EXPIRATION: "SET_EXPIRATION",
  SET_REMINDER: "SET_REMINDER",
};
