import React from "react";
import { ContextCashFlow } from "..";
import { useContext } from "react";
import { Label } from "structure/Document";
import ToggleComponent from "components/individual/inputs/Toggle";

export default function DetailsCashFlow() {
  const hook = useContext(ContextCashFlow);

  return (
    <div>
      <Label>Detalle</Label>
      <ToggleComponent
        labelActive=""
        labelInactive=""
        isChecked={hook.cashFlow.displayDetails}
        onChange={hook.setToggleDetails}
      />
    </div>
  );
}
