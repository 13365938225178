import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, InputNumber, InputText } from '../../../../../components/individual/inputs/Inputs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Get/Put the Id of a particular document
 * 
 * @component IdDocument
 * @property {string?} id - id attribute for the input
 * @property {string?} css - Class name styles for the container 
 * @property {string?} name - name attribute for the input
 * @property {number?} value - default number value when it renders
 * @property {object?} forwardRef - register for react-hook-form
 * @property {object?} errors - error for react-hook-form
 * @property {boolean?} readonly - True if the user can edit the input
 * @property {string?} placeholder - placeholder attribute for the input
 * @property {number?} width - width of the input
 * @returns {JSX.Element} Rendered component
 */
export function Informative({
    id = uuidv4(),
    css = `${id}-na`,
    name = `${id}-na`,
    value = null,
    forwardRef = {},
    errors = {},
    readonly = true,
    placeholder = 'ND',
    label = ' ??? '
}){
    return(
        <div className = {`${css} itemDocument`}>
            <InputText
                id = { id }
                labelTxt = { label }
                placeholderTxt = { placeholder }
                defaultValue = { value }
                forwardRef = { forwardRef }
                readonly = { readonly }
                name = { name }
                aditionalClasses = { readonly ? 'inputBlock' : '---' }
            />
        </div>
    );
}
