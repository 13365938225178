import { getCxcCxpReport, getCxpReport } from "helpers/Apis/Documents";
import { dateToDbFormat, monthRange, rangeFullYear } from "helpers/dates";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateCxpReportI}
 */
const INITIAL_STATE = {
  isSearching: true,
  search: "",
  cxp: [],
  from: null,
  to: null,
  refetch: false,
  residue:{
    mxn:0,
    usd:0
  },
  total:{
    mxn:0,
    usd:0
  }
};

/**
 * @type {import("./types").ContextReportCxcAndCxp}
 */
export const CONTEXT_CXC_CXP_REPORT = {
  ...INITIAL_STATE,
  updateBeginDate: () => {},
  updateEndDate: () => {},
  refetchTable: () => {},
  updateSearchText: () => {},
  type: "cxc",
  isValidFrom:()=>false,
  isValidTo:()=>false,

};

/**
 *
 * @param {import("helpers/Apis/typesDocuments").CxcCxpTypeReport} type - Type of report to fetch
 * @returns
 */
export default function useCxpReport(type = "cxp") {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        cxp: [],
        isSearching: true,
      }));

      const items = await getCxcCxpReport(type, {
        endDate: state.to !== null ? dateToDbFormat(state.to) : null,
        startDate: state.from !== null ? dateToDbFormat(state.from) : null,
        socialReason: state.search,
      });

      setState((current) => ({
        ...current,
        cxp: items.report,
        total:items.total,
        residue:items.residue,
        isSearching: false,
      }));
    })();
  }, [state.refetch]);

  const updateBeginDate = (from) =>
    setState((current) => ({
      ...current,
      from,
    }));
  const updateEndDate = (to) =>
    setState((current) => ({
      ...current,
      to,
    }));

  const refetchTable = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Update the search for the report of CxP
   * @param {string} search - Search typed by the user
   * @returns {void}
   */
  const updateSearchText = (search) =>
    setState((current) => ({
      ...current,
      search: search.toUpperCase(),
    }));

  const isValidFrom = () => {
    if(state.from === null && state.to === null) return true;

    if(state.from === null) return false

    return true
  }

  const isValidTo = () => {
    if(state.from === null && state.to === null) return true;

    if(state.to === null) return false

    return true
  }

  function isValidDate() {
    if (state.from === null && state.to === null) return true;

    if (state.from !== null && state.to !== null) return true;

    return false;
  }

  return {
    ...state,
    updateBeginDate,
    updateEndDate,
    isValidFrom,
    isValidDate:isValidDate(),
    isValidTo,
    updateSearchText,
    refetchTable,
    breadcrum:
      type === "cxp"
        ? [
            {
              route: "/",
              text: "Inicio",
            },
            {
              route: "/compras/cxp",
              text: "Compras",
            },
            {
              route: "/",
              text: "Reporte CxP",
            },
          ]
        : [
            {
              route: "/",
              text: "Inicio",
            },
            {
              route: "/ventas/cxc",
              text: "Ventas",
            },
            {
              route: "/",
              text: "Reporte CxC",
            },
          ],
  };
}
