import { parseInputDecimals } from "helpers/money";
import React, { useContext } from "react";
import { ContextReception } from "../..";
import ErrorFieldReception from "./ErrorFieldReception";

/**
 * Render a field for reception of materials
 * @param {import("../../../../../../../types/proyects/materials").DtoListMaterials} item
 * @returns {JSX.Element}
 */
export default function FieldReception(item) {
  const hook = useContext(ContextReception);

  if (item.initialQuantity - item.receivedMaterials === 0)
    return <div>Recibido al 100%</div>;

  return (
    <div className="my-2">
      <input
        type="number"
        placeholder="Escribe aquí"
        value={hook.grid[item.id] || ""}
        max={item.initialQuantity - item.receivedMaterials}
        onChange={(e) => {
          const receptionTyped = parseInputDecimals(e.target.value, 0);
          hook.setGridReception(item.id, receptionTyped);
        }}
      />

      <ErrorFieldReception
        id={item.id}
        received={item.receivedMaterials}
        total={item.initialQuantity}
      />
    </div>
  );
}
