import React, { forwardRef } from "react";
import { InputV2 } from "../Input";
import { Label } from "structure/Document";

/**
 * @type {import("../Input/types").ForwardRefInput}
 */
const ForwardInputField = forwardRef((props, ref) => {

  let unrefedProps = {...props};

  delete unrefedProps.maxLength
  delete unrefedProps.label
  delete unrefedProps.required
  delete unrefedProps.forwardRef

  return (
    <div>
      <Label required={props?.required} maxLength={props.maxLength}>{props.label}</Label>
      <InputV2 {...unrefedProps} ref={ref} />
    </div>
  )
});

export default ForwardInputField;
