import React, { useContext } from "react";
import Toggle from "react-toggle";
import { ContextEditRol } from "..";
import Description from "../../Description";

export default function Step1() {
  const {
    description: { isValid, content },
    updateDescription,
    isActive,
    handleActive,
  } = useContext(ContextEditRol);

  return (
    <>
      <Description
        isValid={isValid}
        updateDescription={updateDescription}
        description={content}
      />

      <label
        htmlFor=""
        className="d-flex align-items-center cursorPointer my-3"
      >
        <Toggle defaultChecked={isActive} onChange={handleActive} />
        <span className="ml-3">{isActive ? "Activo" : "Inactivo"}</span>
      </label>
    </>
  );
}
