import React, { createContext } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import useFileComments from "customHooks/useFileComments";
import BoxComment from "./BoxComment";
import { ContainerComments, NoComments } from "./styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import Icon from "components/individual/HeaderIcon";
import LoadingText from "components/individual/TextLoading";

export const AssociateFileCommentsContext = createContext();

/**
 * Render the modal to add comments to an associated file
 * @param {object} props - Props
 * @param {number} props.idFile - Id of the file to associate a comment
 * @returns {JSX.Element} Component
 */
export default function AssociateFilesComments({ idFile = 0 }) {
  const {
    comments,
    isAddingComment,
    isLoadingComments,
    setComment,
    comment,
    addNewFileComment,
    setModalOpen,
    dialog,
  } = useFileComments(idFile);

  return (
    <AssociateFileCommentsContext.Provider
      value={{
        setComment,
        comment,
        addNewFileComment,
      }}
    >
      <ButtonV2 onClick={() => setModalOpen(true)}>
        <span>Comentarios</span>
        <Icon icon="send" />
      </ButtonV2>

      <Dialog
        closeCallback={() => setModalOpen(false)}
        title="Comentarios"
        forwardRef={dialog.refDialog}
        footer={<BoxComment />}
      >
        {isLoadingComments ? (
          <NoComments>
            <Spinner
              hidden={false}
              text={<LoadingText>Cargando comentarios</LoadingText>}
            />
          </NoComments>
        ) : null}

        {isAddingComment ? (
          <NoComments>
            <Spinner
              hidden={false}
              text={<LoadingText>Agregando comentario</LoadingText>}
            />
          </NoComments>
        ) : null}

        {comments.length <= 0 && !isLoadingComments ? (
          <NoComments>
            <h1>No hay comentarios</h1>
          </NoComments>
        ) : null}

        {comments.length >= 1 && !isAddingComment ? (
          <ContainerComments>
            {comments.map((comment, i) => (
              <>
                <div key={window.crypto.randomUUID()}>
                  <span>{comment.createdBy} - </span>
                  <b>{comment.createdDate.formated}</b>
                  <p>{comment.comment}</p>
                </div>
                {i === comments.length - 1 ? null : <hr />}
              </>
            ))}
          </ContainerComments>
        ) : null}
      </Dialog>
    </AssociateFileCommentsContext.Provider>
  );
}
