import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { Error } from "../alerts";
import "../../types/typedef/contact";
import { arrayToQueryParam, isValidHttpResCode } from "./fetch";

export const getCustomerSocialRazon = async (customerID) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}contactos/getcustomersocialrazon?customerID=${customerID}`
    );

    if (data.status === 200) {
      return data.data[0].socialReason;
    }
  } catch (error) {
    return null;
  }
};

export const addInfoContact = async (cotactInfo) => {
  // const successCallback = () => closeForm('btnDismiss', 'addCustomerInfoContact');

  try {
    console.log(
      "🚀 ~ file: contactInfoApi.js:23 ~ addInfoContact ~ cotactInfo:",
      cotactInfo
    );
    const data = await axios.post(
      `${URL_BASE}contactos/addcontact`,
      {
        cotactInfo,
      },
      {
        withCredentials: true,
      }
    );

    if (data.status === 200) {
      // Success(successCallback, data.message);
      return true;
    }
    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error);

    return false;
  }
};
export const editInfoContact = async (contactInfo) => {
  try {
    const data = await axios.put(
      `${URL_BASE}contactos/editcontactinfo`,
      {
        contactInfo,
      },
      {
        withCredentials: true,
      }
    );
    if (data.status === 200) {
      return {
        status: true,
        mensaje: data.message,
      };
    }
    return {
      status: false,
      mensaje: data.message,
    };
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
};

export const fetchInfoContact = async (
  customerID,
  status,
  setIsFetching,
  setData,
  setFoundedRows,
  setPages,
  begin,
  end
) => {
  try {
    setIsFetching(true);
    const { data } = await axios.get(
      `${URL_BASE}contactos/getinfocontact?customerID=${customerID}&estatus=${status}&beginRange=${begin}&endRange=${end}`
    );

    setData(data.data[0].contactInfo);
    setIsFetching(false);
    setFoundedRows(data.data[0].rows[0][""]);
    setPages();
    return data.data[0].contactInfo;
  } catch (error) {}
};

/**
 * Fetch the information of an specific contact
 * * @param {number} id - ID of the contact to fetch information
 * @returns {Promise<import("../../../../server/models/contactsModelTypes").ContactInformationI>} Information of the contact
 */
export async function getInfoContact(id) {
  const phoneData = {
    fullNumber: null,
    lada: null,
    number: null,
  };

  /**
   * Dummy information in case the api fails
   * @type {import("../../../../server/models/contactsModelTypes").ContactInformationI}
   */
  const EMPTY_DATA = {
    birthDay: "",
    customerId: 0,
    email: "",
    firstName: "",
    id: 0,
    isActive: false,
    isForColletion: false,
    isForPayments: false,
    lastName1: "",
    lastName2: "",
    middleName: "",
    movil: phoneData,
    phone: phoneData,
    name: "",
    position: "",
    uens: [],
  };

  try {
    const apiRes = await fetch(`${URL_BASE}contactos/${id}`, {
      credentials: "include",
    });

    const data = await apiRes.json();
    if (apiRes.ok && isValidHttpResCode(apiRes.status)) {
      return data;
    }

    return EMPTY_DATA;
  } catch (error) {
    return EMPTY_DATA;
  }
}

/**
 * Get the contacts table of a customer
 * @param {number} customer - Id of the customer
 * @param {number} page - Page to request
 * @param {boolean|null} status - Status for the filter
 * @param {string} search - Search for the filter
 * @returns {Promise<any>}
 */
export async function getContacts(customer, page, status = null, search = "") {
  try {
    const customerParam = `?customer=${customer}`;
    const pageParam = `&page=${page}`;
    const statusParam =
      typeof status === "boolean" ? `&isActive=${status}` : "";
    const searchParam = search === "" ? `` : `&search=${search}`;

    const res = await fetch(
      `${URL_BASE}contactos/${customerParam}${pageParam}${statusParam}${searchParam}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 * Get the contacts report
 * @param {import("../../../../server/models/contactsModelTypes").DtoContactReportVerionB} param - Params to fetch the report
 * @returns {Promise<import("../../../../server/models/contactsModelTypes").ContactReportItem[]>}
 */
export async function getContactsReport({
  contactName = null,
  customerType = null,
  socialReason = null,
  status = null,
}) {
  try {
    const queryParam = arrayToQueryParam([
      contactName === null ? null : `contactName=${contactName}`,
      customerType === null ? null : `customerType=${customerType}`,
      socialReason === null ? null : `socialReason=${socialReason}`,
      status === null ? null : `status=${status}`,
    ]);

    const res = await fetch(
      `${URL_BASE}contactos/reporte/excel-B${queryParam}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}
