import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { createContext } from "react";
import FilterCuotas from "./Filter";
import GridUen from "./GridUen";
import useCuotas from "./useCuotas";
import fee from "./styles.module.scss";
import TopModule from "structure/TopModule";

/**
 * @type {import("react").Context<undefined|import("./useCuotas/types").ReturnUseCuotas>}
 */
export const CuotasContext = createContext(undefined);

export default function Cuotas() {
  const hook = useCuotas();

  return (
    <CuotasContext.Provider value={hook}>
      <div className={fee.container}>
        <TopModule>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={fee.margin}>
            <FilterCuotas />
          </div>
        </TopModule>

        <div className={fee.margin}>
          <div className={fee.body}>
            <GridUen
              canDisplayGrid={hook.canDisplayGrid}
              uens={hook.uen.uens}
              fee={hook.fee}
              onChange={hook.setFeeOperation}
              isLoading={hook.uen.isLoading || hook.isSearchingFee}
            />
          </div>
        </div>
      </div>
    </CuotasContext.Provider>
  );
}
