import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import React, { useContext, useRef } from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import { parseDateToText } from "helpers/dates";
import { add } from "date-fns";
import { ContextOdc } from "../..";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import LoginContext from "context/Login/LoginContext";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";

/**
 * Render the odc for the mobile view
 * @param {import("types/typedef/odc").OdcI} odc - Information of the odc
 * @returns {JSX.Element}
 */
export default function MobileItem(odc) {
  const hook = useContext(ContextOdc);
  const { userInfo } = useContext(LoginContext);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const ref = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const refMoreInfo = useRef();

  const sendEmail = () => ref.current.click();
  const viewMoreInfo = () => refMoreInfo.current.click();

  return (
    <>
      <ModalDocsRelatedV2
        fromLegal={false}
        idDocument={odc.id}
        forwardRef={refMoreInfo}
      />

      <div className={ui.mobileItem}>
        <div className={ui.header}>
          <div>
            <Label>No. OC.</Label>
            <p>{odc.numeroDocumento}</p>
          </div>

          <ElipsisMobile onShow={() => hook.setRowSelected(odc)}>
            <CustomMenuItem onClick={viewMoreInfo}>
              Más información
            </CustomMenuItem>
            <CustomMenuItem onClick={hook.watchDocument}>
              Ver documento
            </CustomMenuItem>
            <CustomMenuItem onClick={hook.goFiles}>
              Archivos asociados
            </CustomMenuItem>
            <CustomMenuItem onClick={hook.goToDo}>To Do</CustomMenuItem>
            {hook.isEditable ? (
              <CustomMenuItem onClick={hook.goEdit}>Editar</CustomMenuItem>
            ) : null}

            <CustomMenuItem
              disabled={hook.pdf.isDownloading ? true : false}
              onClick={hook.pdf.handleDownload}
            >
              {hook.pdf.isDownloading ? "Descargando PDF" : "PDF"}
            </CustomMenuItem>

            {hook.isSendableEmail ? (
              <CustomMenuItem onClick={sendEmail}>Enviar correo</CustomMenuItem>
            ) : null}
          </ElipsisMobile>
        </div>

        <div>
          <Label>Razón social</Label>
          <p>{odc.customer.socialReason}</p>
        </div>

        <div>
          <Label>Ejecutivo</Label>
          <p>{odc.iniciales}</p>
        </div>

        <div className={ui.amount}>
          <div>
            <Label>Moneda</Label>
            <p>{odc.moneda}</p>
          </div>

          <div>
            <Label>Total</Label>
            <p>{odc.total.texto}</p>
          </div>
        </div>

        <div className={ui.dates}>
          <div>
            <Label>Registro</Label>
            <p>
              {parseDateToText(
                add(new Date(odc.registro.yyyymmdd), {
                  days: 1,
                })
              )}
            </p>
          </div>

          <div>
            <Label>Enviada</Label>
            <p>
              {odc.envio.yyyymmdd === "ND"
                ? "ND"
                : parseDateToText(
                    add(new Date(odc.envio.yyyymmdd), {
                      days: 1,
                    })
                  )}
            </p>
          </div>

          <div>
            <Label>Estatus</Label>
            <p>{odc.estatus}</p>
          </div>
        </div>
      </div>

      <EmailDocumentsV2
        forwardRef={ref}
        idDocument={odc.id}
        subject={`Praxia - Orden de compra ${odc.numeroDocumento}`}
        message={`Se le adjunta la informacion de la orden de compra ${odc.numeroDocumento} (pdf)`}
        cc={[userInfo[0].email]}
        to={[odc.email]}
        onSendedEmail={hook.updateOc}
      />
    </>
  );
}
