import { dateAtCeroHours, getFirstDayOfMonth, getLastDayOfDate, getYearRange } from "helpers/dates";
import { Actions } from "./actions";

/**
 * State of the component
 * @type {import("./types").StateI}
 */
export const initialState = {
  canPerformAccountingUpdate:false,
  isCancelling:false,
  isDownloadingPdf:false,
  page: 1,
  accountingStatus:null,
  totalPages: undefined,
  search: null,
  customer: "",
  date: {
    start: getYearRange().begin.js,
    end: getYearRange().end.js,
  },
  accountingStatusGrid:{
    original:{},
    operation:{}
  },
  status: 7,
  rows: [],
  isFetchingData: true,
  rowSelected: null,
  refetch: false,
  isDownloadingXlsx:false
};

/**
 *
 * @param {import("./types").StateI} state - State of the component that renders the invoices
 * @param {import("./types").Action} action - Action that can be executed on the reducer
 */
export function reducer(state, action) {
  switch (action.type) {
    case Actions.SET_IS_FETCHING_DATA: {
      return {
        ...state,
        isFetchingData: action.payload,
      };
    }

    case Actions.SET_DOWNLOAD_PDF:{
      return {
        ...state,
        isDownloadingPdf:action.payload
      }
    }

    case Actions.SET_MONTH:{

      const dateForCalculations = new Date(`${state.date.start.getFullYear()}-${action.payload}-10`)

      return {
        ...state,
        date: {
          start: dateAtCeroHours(getFirstDayOfMonth(dateForCalculations)),
          end: dateAtCeroHours(getLastDayOfDate(dateForCalculations)),
        },
      }
    }

    case Actions.SET_CAN_UPDATE_ACCOUNTING:{
      return {
        ...state,
        canPerformAccountingUpdate:action.payload
      }
    }
      

    case Actions.SET_ACCOUNTING:{
      return {
        ...state,
        accountingStatus:action.payload
      }
    }

    case Actions.SET_ACCOUNTING_CHECK:
      return {
        ...state,
        accountingStatusGrid:{
          ...state.accountingStatusGrid,
          operation:{
            ...state.accountingStatusGrid.operation,
            [action.payload]:!state.accountingStatusGrid.operation[action.payload]
          }
        }
      }

    case Actions.SET_PAGE:
      return {
        ...state,
        page:action.payload
      }

    case Actions.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case Actions.TRIGGER_SEARCH: {
      return {
        ...state,
        refetch: !state.refetch,
      };
    }

    case Actions.SET_DOWNLOAD_XLSX:
      return {
        ...state,
        isDownloadingXlsx:action.payload
      }

    case Actions.SET_DOCUMENT: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case Actions.SET_DATES: {
      return {
        ...state,
        date: {
          start: action.payload[0],
          end: action.payload[1],
        },
      };
    }

    case Actions.SET_SOCIAL_REASON: {
      return {
        ...state,
        customer: action.payload,
      };
    }

    case Actions.SET_ROW: {
      return {
        ...state,
        rowSelected: action.payload,
      };
    }

    case Actions.SET_YEAR:{
      return {

        ...state,
        date:{
          start: new Date(`${action.payload}-${state.date.start.getMonth()+1}-${state.date.start.getDate()}`),
          end: new Date(`${action.payload}-${state.date.end.getMonth()+1}-${state.date.end.getDate()}`),
        }
      }
    }

    case Actions.SET_FIRST_PAGE_DATA: {

      const indexedAccounting = action.payload.rows.reduce((indexed,inv)=>({
        ...indexed,
        [inv.id]:inv.isAccounted
      }),{})

      return {
        ...state,
        canPerformAccountingUpdate:false,
        accountingStatusGrid:{
          original:indexedAccounting,
          operation:indexedAccounting
        },
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        search: action.payload.search,
        customer: action.payload.customer,
        date: {
          start: action.payload.startDate,
          end: action.payload.endDate,
        },

        rows: action.payload.rows,
        isFetchingData: false,
      };
    }

    default:
      return state;
  }
}
