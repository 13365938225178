import React, { useContext } from "react";
import { IVASetup } from "structure/SetUpExcel/IVA";
import { UENSetup } from "structure/SetUpExcel/UEN";
import { MovementsUpload } from "structure/SetUpExcel/MovementsUpload";
import { MenuContext } from "..";
import TextIcon from "../TextIcon";
import scss from "../index.module.scss";
import ExcelTemplate from "structure/SetUpExcel/ExcelTemplate";
import Back from "../Back";

export const Setup = () => {
  const { closeMenu } = useContext(MenuContext);

  return (
    <>
      <Back menu="procesos_especiales" text="Inicio > Procesos especiales"/>


      <IVASetup onClick={closeMenu} />
      <UENSetup onClick={closeMenu} />
      <MovementsUpload onClick={closeMenu} />

      <ExcelTemplate
        title="Conceptos de egreso"
        type="conceptosEgresos"
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Egresos" />
          </p>
        }
      />

      <ExcelTemplate
        title="Tipos de egreso"
        type="tiposDeEgreso"
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Egresos Tipos" />
          </p>
        }
      />

      <ExcelTemplate
        title="Conceptos de ingreso"
        type="conceptosIngresos"
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Ingresos" />
          </p>
        }
      />

      <ExcelTemplate
        title="Tipos de ingreso"
        type="tiposDeIngreso"
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Ingresos Tipos" />
          </p>
        }
      />

      <ExcelTemplate
        title="Catalogo"
        type="catalogo"
        isOnlyForUpload={true}
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Catalogo productos y servicios" />
          </p>
        }
      />

      <ExcelTemplate
        title="Contactos"
        type="contactos"
        children={
          <p className={scss.item}>
            <TextIcon icon="excel" text="Contactos" />
          </p>
        }
      />

      <ExcelTemplate title="Directorio" type="directorio">
        <p className={scss.item}>
          <TextIcon icon="excel" text="Directorio" />
        </p>
      </ExcelTemplate>

      <ExcelTemplate title="Sector de mercado" type="sectorMercado">
        <p className={scss.item}>
          <TextIcon icon="excel" text="Sector de mercado" />
        </p>
      </ExcelTemplate>
    </>
  );
};
