import { Error, Success } from "helpers/alerts";
import { GetTCV2 } from "helpers/Apis/Banks";
import { GetCustomer } from "helpers/Apis/Directory";
import {
  GetDocument,
  getDocumentComments,
  GetParameters,
  parseCommentsApi,
} from "helpers/Apis/Documents";
import { addQuote, editQuote } from "helpers/Apis/quote";
import { addDays, dateToDbFormat, getNumberDate, substractDays } from "helpers/dates";
import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import reducer from "customHooks/useQuote/reducer/reducer";
import {
  APPEND_CONTACT_MEMORY,
  GET_CONTACTS,
  SET_CONTACT,
  SET_INITIAL_STATE,
  SET_IS_PERFORMING_QUERY,
  UPDATE_CURRENCY,
  UPDATE_DOCUMENT_ITEMS_INFO,
  UPDATE_EXPIRATION_DATE,
  UPDATE_NOTES_AND_CONSIDERATIONS,
  UPDATE_PERIOCITY,
  UPDATE_PROBABILITY,
  UPDATE_REGULAR_COMMENTS,
  UPDATE_REMINDER_DATE,
  UPDATE_TC,
} from "./useQuote/reducer/types";
import { reorderComments } from "helpers/comments";
// import EditQuote from "pages/Directory/Documents/QuoteV2/EditQuote";

/**
 * State when manipulating a quote
 * @type {import("types/typedef/customHooks/useQuote").StateI}
 */
const initialState = {
  document: {
    contact: {
      id: null,
      data: {
        apellidoM: null,
        apellidoP: null,
        email: null,
        id: null,
        isActive: true,
        lada_cellphone: null,
        lada_phone: null,
        middleName: null,
        nombre: null,
        number_cellphone: null,
        number_phone: null,
        puesto: null,
      },
    },
    tc: 0,
    import: undefined,
    iva: undefined,
    total: undefined,
    currency: null,
    number: undefined,
    periocity: {
      id: "-1",
      begin: null,
      end: null,
      message: null,
      value: null,
    },
    expiration: {
      db: null,
      js: null,
    },
    reminder: {
      db: null,
      js: null,
    },
    probability: 1,
  },
  partidas: {
    items: [],
    uens: [],
  },
  comments: {
    regulars: [],
    notesAndConsiderations: [],
  },
  isPerformingQuery: false,
  customer: undefined,
  isDocumentFetched: false,
  contactsOnMemory: [],
};

export default function useQuote({ idQuote = null, idCustomer }) {
  const history = useHistory();
  const existIdQuote = idQuote !== null ? true : false;

  /**
   * State of the component
   * @type {[import("types/typedef/customHooks/useQuote").StateI,()=>void]}
   */
  const [state2, dispatch] = useReducer(reducer, initialState);

  /**
   * Set if the user is attemping add or edit the document
   * @param {boolean} state - True of false
   * @returns {void}
   */
  const setIsPerformingQuery = (state) =>
    dispatch({
      type: SET_IS_PERFORMING_QUERY,
      payload: state,
    });

  const updateContact = (contact) =>
    dispatch({
      type: SET_CONTACT,
      payload: contact,
    });

  const appendContactOnMemory = (contact) =>
    dispatch({
      type: APPEND_CONTACT_MEMORY,
      payload: contact,
    });

  /**
   * Update the comments to use with the document
   * @param {import("types/typedef/customHooks/useCommentsDocument").CommentI} comments
   * @returns {void}
   */
  const updateRegularComments = (comments) =>
    dispatch({
      type: UPDATE_REGULAR_COMMENTS,
      payload: comments,
    });

  const setContacts = (contacts) =>
    dispatch({
      type: GET_CONTACTS,
      payload: contacts,
    });

  useEffect(() => {
    (async function () {
      const customer = await GetCustomer(idCustomer);

      // Fill the data with the existing data on database
      if (existIdQuote) {
        const [
          {
            amount,
            currency,
            tcp,
            expiration,
            reminder,
            probability,
            contact,
            periocity,
            documentNumber,
            docNumber,
          },
          comments,
        ] = await Promise.all([
          GetDocument(idQuote),
          getDocumentComments(idQuote),
        ]);

        const reminderUpdated = addDays(new Date(reminder.yyyymmdd), 1);
        const expirationUpdated = addDays(new Date(expiration.yyyymmdd), 1);

        dispatch({
          type: SET_INITIAL_STATE,
          payload: {
            ...state2,
            customer,
            isDocumentFetched: true,
            document: {
              ...state2.document,
              import: amount.subtotal.number,
              number: docNumber,
              iva: amount.iva.number,
              total: amount.total.number,
              tc: tcp.number,
              currency: currency.code,
              probability: probability.id,

              expiration: {
                db: expiration.yyyymmdd,
                js: expirationUpdated,
              },
              reminder: {
                db: reminder.yyyymmdd,
                js: reminderUpdated,
              },
              contact: {
                id: contact.id,
                data: { ...contact },
              },
              periocity: {
                begin: new Date(periocity.start),
                id: periocity.type.id === null ? "-1" : periocity.type.id,
                end: new Date(periocity.end),
                message: "",
                value: periocity.value,
              },
            },
            comments: {
              regulars: parseCommentsApi(comments),
              notesAndConsiderations: [],
            },
          },
        });
        return;
      }

      // Fill the data with the default parameters to add a new quote
      const [{ enterprise }] = await GetTCV2();

      const { parameters } = await GetParameters();

      const { value: expirationDays } = parameters.find(
        (parameter) => parameter.parameter === 1
      );

      const { value: reminderDays } = parameters.find(
        (parameter) => parameter.parameter === 30
      );

      const expirationDate = addDays(new Date(), +expirationDays);
      const reminderDate = substractDays(expirationDate, reminderDays);

      dispatch({
        type: SET_INITIAL_STATE,
        payload: {
          ...state2,
          customer,
          isDocumentFetched: true,
          document: {
            ...state2.document,
            tc: enterprise.number,
            number: null,
            expiration: {
              // db: dateToDbFormat(expirationDate),
              db: getNumberDate(expirationDate).isoDate,
              js: expirationDate,
            },
            reminder: {
              // db: dateToDbFormat(reminderDate),
              db: getNumberDate(reminderDate).isoDate,
              js: reminderDate,
            },
          },
        },
      });
    })();
  }, []);

  /**
   *
   * Update the information of the document items
   * @param {import("structure/Partidas/types").onChangeI} infoItems - Info of the items
   */
  const updateDocumentItemsInfo = (infoItems) =>
    dispatch({
      payload: {
        ...state2,
        partidas: {
          ...state2.partidas,
          items: infoItems.items,
          uens: infoItems.uens,
        },
        document: {
          ...state2.document,
          currency: infoItems.currency,
          import: infoItems.import,
          iva: infoItems.iva,
          total: infoItems.total,
        },
      },
      type: UPDATE_DOCUMENT_ITEMS_INFO,
    });

  const triggerAddQuote = async () => {
    setIsPerformingQuery(true);

    const dto = {
      autorizationFlag: 1,
      contact: state2.document.contact,
      creditDays: state2.customer.creditDays,
      currency: state2.document.currency,
      expirationDate: state2.document.expiration.db,
      idCustomer: state2.customer.id,
      // TODO
      // * Utilizar la funcion que se encargue de retornar la interface que pide el servidor
      periocity: {
        end: state2.document.periocity.end,
        start: state2.document.periocity.begin,
        type:
          typeof state2.document.periocity.id === "string"
            ? null
            : state2.document.periocity.id,
        value: state2.document.periocity.value,
      },
      // idPeriocityType:
      //   typeof state2.document.periocity.id === "string"
      //     ? null
      //     : state2.document.periocity.id,
      idProbability: state2.document.probability,
      iva: state2.document.iva,
      subtotal: state2.document.import,
      totalAmount: state2.document.total,
      periocityValue: state2.document.periocity.value,
      tc: state2.document.tc,
      reminderDate: state2.document.reminder.db,
      items: state2.partidas.items.map((item, i) => ({
        ...item,
        order: i + 1,
      })),
      comments: reorderComments([
        ...state2.comments.regulars,
        ...state2.comments.notesAndConsiderations,
      ]),
    };

    const wasAdded = await addQuote(dto);

    if (wasAdded) {
      history.push(`/directorio/documentos/${idCustomer}`);
      Success(() => {}, "Cotización agregada");
      return;
    }

    Error(() => {}, "No se pudo actualizar la cotizacion");

    setIsPerformingQuery(false);
  };

  const triggerEditQuote = async () => {
    setIsPerformingQuery(true);

    const dto = {
      autorizationFlag: 1,
      contact: state2.document.contact,
      creditDays: state2.customer.creditDays,
      currency: state2.document.currency,
      expirationDate: state2.document.expiration.db,
      idCustomer: state2.customer.id,
      // TODO
      // * Utilizar la funcion que se encargue de retornar la interface que pide el servidor
      periocity: {
        end: dateToDbFormat(state2.document.periocity.end),
        start: dateToDbFormat(state2.document.periocity.begin),
        type:
          typeof state2.document.periocity.id === "string"
            ? null
            : state2.document.periocity.id,
        value: state2.document.periocity.value,
      },
      // idPeriocityType:
      //   typeof state2.document.periocity.id === "string"
      //     ? null
      //     : state2.document.periocity.id,
      idProbability: state2.document.probability,
      iva: state2.document.iva,
      subtotal: state2.document.import,
      totalAmount: state2.document.total,
      periocityValue: state2.document.periocity.value,
      tc: state2.document.tc,
      reminderDate: state2.document.reminder.db,
      items: state2.partidas.items.map((item, i) => ({
        ...item,
        order: i + 1,
      })),
      idDocument: idQuote,
      comments: [
        ...state2.comments.regulars,
        ...state2.comments.notesAndConsiderations,
      ],
    };

    const wasUpdated = await editQuote(dto);

    if (wasUpdated) {
      history.push(`/directorio/documentos/${idCustomer}`);
      Success(() => {}, "Cotización actualizada");
      return;
    }

    Error(() => {}, "No se pudo agregar la cotizacion");

    setIsPerformingQuery(false);
  };

  /**
   * Update the probability that will be use on the document
   * @param {1|2|3|4} probability - Probability to use on the document
   * @returns {void}
   */
  const updateProbability = (probability) =>
    dispatch({
      type: UPDATE_PROBABILITY,
      payload: probability,
    });

  const openModalCreateContact = () => {
    const domBtn = document.getElementById("createContactBtn");

    if (domBtn) {
      domBtn.click();
    }
  };

  /**
   * Update the reminder date to use for the document
   * @param {string} date - Reminder date selected
   */
  const updateReminderDate = (date) =>
    dispatch({
      type: UPDATE_REMINDER_DATE,
      payload: date,
    });

  /**
   * Update the expiration date to use for the document
   * @param {string} date - Expiration date selected
   */
  const updateExpirationDate = (date) =>
    dispatch({
      type: UPDATE_EXPIRATION_DATE,
      payload: date,
    });

  /**
   * Update the tc to use on the document
   * @param {number} tc - Tc to user
   * @returns {void}
   */
  const updateTc = (tc) =>
    dispatch({
      type: UPDATE_TC,
      payload: tc,
    });

  /**
   * Update the currency to use on the document
   * @param {"USD"|"MXN"} currency - Currency to use for the document
   * @returns {void}
   */
  const updateCurrency = (currency) =>
    dispatch({
      type: UPDATE_CURRENCY,
      payload: currency,
    });

  /**
   * Update the notes and considerations to use into document
   * @param {any} notes - Notes and considerations
   * @returns {void}
   */
  const updateNotesAndConsiderations = (notes) =>
    dispatch({
      type: UPDATE_NOTES_AND_CONSIDERATIONS,
      payload: notes,
    });

  /**
   * Update the periocity for the document
   * @param {import("structure/Document/Period/types").onChangePeriocityI} periocity - Information of the periocity choosen
   */
  const updatePeriocity = (periocity) => {
    dispatch({
      type: UPDATE_PERIOCITY,
      payload: periocity,
    });
  };

  const pathBreadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${idCustomer}`,
      text: "Documentos",
    },
    {
      route: "/",
      text: existIdQuote ? "Editar cotizacion" : "Agregar cotizacion",
    },
  ];

  return {
    idOpenCreatableContact: "createContactBtn",
    openModalCreateContact,
    document: state2.document,
    updateTc,
    updateReminderDate,
    updateCurrency,
    updatePeriocity,
    updateDocumentItemsInfo,
    uens: state2.partidas.uens,
    importe: state2.document.import,
    iva: state2.document.iva,
    subtotal: state2.document.subtotal,
    customer: state2.customer,
    updateProbability,
    updateRegularComments,
    updateExistingContact: () => {},
    partidas: state2.partidas.items,
    moreThanOneUen: state2.partidas.uens.length > 1 ? true : false,
    triggerAddQuote,
    updateNotesAndConsiderations,
    pathBreadcrum,
    isPerformingQuery: state2.isPerformingQuery,
    isDocumentFetched: state2.isDocumentFetched,
    updateExpirationDate,
    appendContactOnMemory,
    updateContact,
    contactsOnMemory: state2.contactsOnMemory,
    triggerEditQuote,
    setContacts,
    canPerformQuery:
      state2.partidas.items.length < 1 || document.tc <= 0 ? false : true,
  };
}
