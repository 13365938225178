import axios from "axios";
import { URL_BASE } from "routes/routes";
import { arrayToQueryParam } from "../fetch";
import { Error } from "helpers/alerts";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { parseToFolio } from "helpers/documents";
import { base64ToFile, base64ToFileV2 } from "helpers/files";

/**
 * @param {import('../../../../../types/proyects').GetProyectsReq} dto - Information to do the request of proyects
 * @returns {Promise<import("../../../../../types/proyects").TabulatedData<import("../../../../../types/proyects").DtoProjectTabulated>>}
 */
export const getProyects = async (dto) => {
  /**
   * @type {import("../../../../../types/proyects").TabulatedData<import("../../../../../types/proyects").DtoProjectTabulated>}
   */
  const ERROR_DATA = {
    data: [],
    noRecordsFound: 0,
    pages: 0,
  };

  const queryParams = arrayToQueryParam([
    `page=${dto.page}`,
    `limit=${dto.limit}`,
    dto.search.length <= 0 ? null : `search=${dto.search}`,
    dto.status === null ? null : `status=${dto.status}`,
    `orderBy=${dto.orderBy}`,
  ]);

  const urlApi = `${URL_BASE}proyecto${queryParams}`;

  try {
    /**
     * @type {import("axios").AxiosResponse<import("../../../../../types/proyects").TabulatedData<import("../../../../../types/proyects").DtoProjectTabulated>>}
     */
    const res = await axios.get(urlApi, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    Error(() => {}, error.response.data.message);

    return ERROR_DATA;
  }
};

/**
 * Create a new proyect into system
 * @param {import("../../../../../types/proyects").DtoCreateProyect} dto
 * @returns {Promise<boolean>}
 */
export async function addProyect(dto) {
  try {
    await axios.post(
      `${URL_BASE}proyecto`,
      { data: { ...dto } },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    Error(() => {}, error.response.data.message);
    return false;
  }
}

/**
 * Update the information of a proyect
 * @param {import("../../../../../types/proyects").DtoUpdateProyect} dto
 */
export async function updateProyect(dto) {
  try {
    const res = await axios.put(
      `${URL_BASE}proyecto`,
      { data: dto },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    Error(() => {}, error.response.data.message);

    return false;
  }
}

/**
 * Get the information of a proyect
 * @param {number} [idProyect=null] - Id of the proyect
 * @param {number} [idPosition=null] - Id of the position
 * @returns {Promise<import("../../../../../types/proyects").DtoProyectI>}
 */
export async function getProyect(idProyect, idPosition) {
  const queryParams = arrayToQueryParam([
    typeof idProyect !== "number" ? null : `idProyect=${idProyect}`,
    typeof idPosition !== "number" ? null : `idPosition=${idPosition}`,
  ]);

  try {
    const res = await axios.get(`${URL_BASE}proyecto/id${queryParams}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return null;
  }
}

/**
 * Create a new position into the proyect
 * @param {import("../../../../../types/proyects").DtoUpdateProyect} dto - Information to create the position
 */
export async function addPosition(dto) {
  try {
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Cancel the position
 * @param {number} idPosition - Id of the position
 * @returns {Promise<boolean>}
 */
export async function cancelPosition(idPosition) {
  const queryParam = arrayToQueryParam([`idPosition=${idPosition}`]);

  try {
    await axios.patch(`${URL_BASE}posicion/cancelar${queryParam}`, null, {
      withCredentials: true,
    });
    return true;
  } catch (error) {
    Swal.fire({
      title: "No se puede cancelar",
      text: error.response.data.message,
      icon: "error",
      confirmButtonText: "Entendido, cerrar mensaje",
    });

    return false;
  }
}

/**
 * Get the materials of a position
 * @param {number} idPosition - Id of the position
 * @param {number} idProyect - Id of the proyect
 */
export async function getMaterials(idPosition, idProyect) {
  const queryParam = arrayToQueryParam([
    `idPosition=${idPosition}`,
    `idProyect=${idProyect}`,
  ]);
  const url = `${URL_BASE}materiales${queryParam}`;
  try {
    /**
     * @type {import("axios").AxiosResponse<import("../../../../../types/proyects/materials").DtoGetMaterialsRes>}
     */
    const res = await axios.get(url, {
      withCredentials: true,
    });

    const materialsParsed = res.data.materials.map((material) => ({
      ...material,
      receivedMaterials: material.receivedMaterials || 0,
    }));

    const dataParsed = {
      ...res.data,
      materials: materialsParsed,
    };

    return dataParsed;
  } catch (error) {
    return {
      header: {
        solped: "",
        pos: "",
        buyer: "",
        totalCost: 0,
        residueCost: 0,
        currentCost: 0,
      },
      materials: [],
    };
  }
}

/**
 * Get the materials associated into an ODC
 * @param {number} idOdc - Id of the ODC to fethc it's materials
 */
export async function getMaterialsOdc(idOdc) {
  const queryParam = arrayToQueryParam([`idOdc=${idOdc}`]);

  try {
    const res = await axios.get(
      `${URL_BASE}materiales/odc/partidas${queryParam}`,
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    return [];
  }
}

/**
 * Add material into a position
 * @param {import("../../../../../types/proyects/materials").DtoCreateMaterial} dto - Dto information to create the material
 * @param {import("structure/FormDocumentItems/types").DocumentItemForm|null} [productOverview=null] - Overview of the product from the form
 */
export async function addMaterial(dto, productOverview = null) {
  const parsedDto =
    typeof dto.idCatalogue !== "number"
      ? {
          ...dto,
          newProduct: productOverview,
        }
      : dto;

  try {
    await axios.post(
      `${URL_BASE}materiales`,
      { data: parsedDto },
      {
        withCredentials: true,
      }
    );
    return true;
  } catch (error) {
    Swal.fire({
      title: "Error",
      icon: "error",
      text: error.response.data.message,
    });
    return false;
  }
}

export async function receiveMaterials(reception = []) {
  try {
    await axios.patch(
      `${URL_BASE}materiales/odc/recibidos`,
      {
        data: reception,
      },
      {
        withCredentials: true,
      }
    );

    Swal.fire({
      title: "Éxito",
      icon: "success",
      text: "Material recibido correctamente",
    });

    return true;
  } catch (error) {
    Swal.fire({
      title: "Error",
      icon: "error",
      text: error.response.data.message,
    });
    return false;
  }
}

/**
 * Create the oc for the materials requested
 * @param {import("../../../../../types/proyects/materials").DtoMaterialsToOc[]} materials - Materials requested to crate the first oc
 * @returns {Promise<boolean>} True if `OC` was created, false if not
 */
export async function generateOcMaterial(materials) {
  try {
    await axios.post(
      `${URL_BASE}materiales/odc`,
      { data: materials },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    Swal.fire({
      title: "Error",
      icon: "error",
      text: error.response.data.message,
    });

    return false;
  }
}

/**
 *
 * @param {*} param0
 * @returns {Promise<import("./types").OcProyecs>}
 */
export async function getOcsProyect({
  idProyect = null,
  rowsPerPage = 100,
  page = 1,
  orderAsc = false,
}) {
  const queryParams = arrayToQueryParam([
    `idProyect=${idProyect}`,
    `limit=${rowsPerPage}`,
    `page=${page}`,
    `orderBy=${orderAsc ? "ASC" : "DESC"}`,
  ]);

  try {
    const res = await axios.get(`${URL_BASE}proyecto/odc${queryParams}`, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return {
      data: [],
      noRecordsFound: 0,
      pages: 1,
    };
  }
}

/**
 * Create the remitions for the positions requested
 * @param {number} idPosition - Id of the position
 * @param {number} idUen - Id of the uen
 * @param {number} [amount=0] - Amount
 * @param {string} [description='No disponible'] - Description for the remition
 * @returns {Promise<boolean>}
 */
export async function createRemition(
  idPosition,
  idUen,
  amount = 0,
  description = "No disponible"
) {
  try {
    const res = await axios.post(
      `${URL_BASE}posicion/remision`,
      {
        data: {
          unitPrice: amount,
          idPosition,
          description,
          idUen,
        },
      },
      {
        withCredentials: true,
      }
    );

    Swal.fire({
      title: "Éxito",
      text: res.data.message,
      icon: "success",
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 *
 * @param {object} param0
 * @returns {Promise<import("../../../../../types/proyects/remisionProyect").TabulatedRemisionProyects>}
 */
export async function getRemitions({
  idPosition = 0,
  limit = 100,
  page = 1,
  orderBy = "??",
}) {
  const queryParam = arrayToQueryParam([
    `idPosition=${idPosition}`,
    `limit=${limit}`,
    `page=${page}`,
    `orderBy=${orderBy}`,
  ]);

  const url = `${URL_BASE}posicion/remisiones${queryParam}`;

  try {
    const res = await axios.get(url, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return {
      data: [],
      noRecordsFound: 0,
      pages: 1,
    };
  }
}

/**
 * Retrieve the file of the pdf
 * @param {number} idRemition - Id of the document `type = 2`
 * @param {string} [filename='Remision'] - Name of the file
 * @returns {Promise<File>}
 */
export async function downloadPdfRemition(idRemition, filename = "Remision") {
  try {
    const res = await axios.post(
      `${URL_BASE}posicion/remision/pdf?idRemision=${idRemition}`,
      {
        withCredentials: true,
      }
    );

    const file = base64ToFileV2(
      `data:application/pdf;base64,${res.data}`,
      filename
    );

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the header for the remitions
 * @param {number} idPosition - Id of the position
 * @returns {Promise<{
 *  residue:number,
 * totalRemision:number,
 * totalSell:number
 * }>}
 */
export async function getHeaderRemitions(idPosition) {
  const queryParam = arrayToQueryParam([`idPosition=${idPosition}`]);

  const url = `${URL_BASE}posicion/remision/encabezado${queryParam}`;

  try {
    const res = await axios.get(url, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return {
      residue: 0,
      totalRemision: 0,
      totalSell: 0,
    };
  }
}

/**
 * Get the propousals generated on the system
 */
export async function getPropousals({
  page = 1,
  limit = 100,
  orderAsc = false,
  status = "Activa",
  idClient = null,
  idProyect = 0,
}) {
  const queryParams = arrayToQueryParam([
    `page=${page}`,
    `limit=${limit}`,
    `idProyect=${idProyect}`,
    `orderAsc=${orderAsc ? "ASC" : "DESC"}`,
    `status=${status}`,
    idClient === null ? null : `idClient=${idClient}`,
  ]);

  const url = `${URL_BASE}propuesta${queryParams}`;

  try {
    /**
     * @type {import("axios").AxiosResponse<import("../../../../../server/models/proyectProposal/types").TabulatedPropousals>}
     */
    const res = await axios.get(url, {
      withCredentials: true,
    });

    return res.data;
  } catch (error) {
    return {
      data: [],
      noRecordsFound: 0,
      pages: 1,
    };
  }
}

export async function addPropousal({
  idExecutive = 0,
  createdBy = "??",
  attn = "",
  expirationDate = "",
  paymentTerms = "",
  termsAndConditions = "",
  positions = [],
}) {
  try {
    await axios.post(
      `${URL_BASE}propuesta`,
      {
        data: {
          idExecutive,
          createdBy,
          attn,
          expirationDate,
          paymentTerms,
          termsAndConditions,
          positions,
        },
      },
      {
        withCredentials: true,
      }
    );
    Swal.fire({
      title: "Éxito",
      text: "Propuesta generada correctamente",
      icon: "success",
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Id of the propousal
 * @param {number} idPropousal - Id of the propousal
 */
export async function getPropousalPdf(idPropousal) {
  try {
    const queryParams = arrayToQueryParam([`idProposal=${idPropousal}`]);

    const res = await axios.get(`${URL_BASE}propuesta/pdf${queryParams}`, {
      withCredentials: true,
    });

    base64ToFile(
      "application/pdf",
      res.data,
      `Propuesta_${parseToFolio(idPropousal)}.pdf`,
      true
    );
  } catch (error) {
    return undefined;
  }
}

/**
 * Handle status after a invoice was created from a remition
 * @param {number} idPosition - Id of the position
 */
export async function handleRemitionAgainstSat(idPosition) {
  const queryParam = arrayToQueryParam([`idPosition=${idPosition}`]);

  try {
    await axios.patch(
      `${URL_BASE}posicion/remision/estatus${queryParam}`,
      {},
      { withCredentials: true }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Toggle the no assignment to a position
 * @param {number} idPosition - Id of the position
 * @returns {Promise<boolean>}
 */
export async function dontAssignPosition(idPosition) {
  const queryParam = arrayToQueryParam([`idPosition=${idPosition}`]);

  try {
    await axios.patch(
      `${URL_BASE}posicion/no-asignar${queryParam}`,
      {},
      { withCredentials: true }
    );

    return true;
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: error.response.data.message,
      icon: "error",
      confirmButtonText: "Entendido",
    });
    return false;
  }
}

/**
 * Get the excel for the proyects
 * @returns {Promise<Blob|null>}
 */
export async function excelProyects() {
  try {
    const res = await axios.get(`${URL_BASE}proyecto/excel`, {
      withCredentials: true,
      responseType: "blob", 
    });

    return res.data;
  } catch (e) {
    return null;
  }
}
