import { getQuoteCancelationMotives } from "helpers/Apis/Documents";
import React, { useState } from "react";
import { useEffect } from "react";

/**
 * @type {import("./types").StateQuoteCancelationMotives}
 */
const INITIAL_STATE = {
  isLoading: true,
  options: [],
};

/**
 * Render a combo for the quote cancelation motives
 * @param {import("./types").PropsQuoteCancelationMotives} props - Props
 * @returns {JSX.Element}
 */
export default function QuoteCancelationMotives({ onChange = () => {} }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiOptions = await getQuoteCancelationMotives();

      const parsedOptions = apiOptions.map((option) => ({
        ...option,
        label: option.description,
        value: option.id,
      }));

      setState((current) => ({
        ...current,
        isLoading: false,
        options: parsedOptions,
      }));
    })();
  }, []);

  /**
   * Handle the on change of the combo
   * @param {number} option - Option selected
   */
  const handleOnChange = (id) => {
    const option = state.options.find((option) => option.id === id);

    const parsedOption = { ...option };
    delete parsedOption.label;
    delete parsedOption.value;

    onChange(parsedOption);
  };

  if (state.isLoading)
    return <input type="text" placeholder="Cargando..." disabled={true} />;

  return (
    <select
      className="select-css px-1"
      onChange={(e) => handleOnChange(+e.target.value)}
    >
      <option value="" disabled selected>
        -- SELECCIONA --
      </option>
      {state.options.map((option) => (
        <option value={option.id}>{option.description}</option>
      ))}
    </select>
  );
}
