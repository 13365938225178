import React from "react";
import ui from "./styles.module.scss";

/**
 * Render a details tag
 * @param {import('./types').DetailsProps} props - Props
 * @returns {JSX.Element}
 */
export default function Details(props) {
  const unrefProps = { ...props };

  delete unrefProps.summary;

  return (
    <details {...unrefProps} className={ui.details}>
      <summary>{props.summary}</summary>
      <div className={ui.customContent} style={unrefProps.style} >{unrefProps.children}</div>
    </details>
  );
}
