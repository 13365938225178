import { getUser } from "helpers/Apis/usuariosApi";
import { useState } from "react";
import { useEffect } from "react";

/**
 * @type {import("./types").StateUseUser}
 */
const INITIAL_STATE = {
  isLoading: true,
  user: null,
};

export default function useUser({ isOpen = false, id = null }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (!isOpen || typeof(id)!=="number") return;

      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const user = await getUser(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        user,
      }));
    })();
  }, [isOpen, id]);

  return {
    ...state,
  };
}
