import { Spinner } from "components/individual/Spinner/Spinner";
import { TcpChange } from "components/individual/TcpChange";
import { getRecentTcps } from "helpers/Apis/Banks";
import React, { useEffect, useState } from "react";

const TcpEvaluation = ({ tcps, i }) => {
  return i + 1 < tcps.length ? (
    <TcpChange
      currentTcp={tcps[i].dof.number}
      previousTcp={[tcps[i + 1].dof.number]}
    />
  ) : (
    <span>❔</span>
  );
};

export const RecentTcps = () => {
  const [state, setState] = useState({
    isLoading: true,
    tcps: [],
  });

  useEffect(() => {
    (async function () {
      const apiTcps = await getRecentTcps();

      setState({
        ...state,
        isLoading: false,
        tcps: apiTcps,
      });
    })();
  }, []);

  return state.isLoading ? (
    <Spinner hidden={false} text="Cargando tcp..." />
  ) : (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>DOF</th>
          <th>Empresa</th>
          <th>Banco</th>
          <th>Pagos</th>
          <th>Compra</th>
        </tr>
      </thead>
      <tbody>
        {state.tcps.map((tcp, i) => (
          <tr>
            <td>
              {new Intl.DateTimeFormat("es-MX", {
                dateStyle: "full",
              }).format(new Date(tcp.date.yyyymmdd))}
            </td>
            <td>
              {tcp.dof.text} {<TcpEvaluation i={i} tcps={state.tcps} />}
            </td>
            <td>
              {tcp.enterprise.text} {<TcpEvaluation i={i} tcps={state.tcps} />}
            </td>
            <td>
              {tcp.sales.text} {<TcpEvaluation i={i} tcps={state.tcps} />}
            </td>
            <td>
              {tcp.pays.text} {<TcpEvaluation i={i} tcps={state.tcps} />}
            </td>
            <td>
              {tcp.purchase.text} {<TcpEvaluation i={i} tcps={state.tcps} />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
