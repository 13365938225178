import { RedirectButton } from "components/individual/buttons/Buttons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import EgressModal from "../EgressModal";
import { ContextOptionsEgress } from "./OptionsEgress";

export default function Associate() {
  const { idStatus, idBankAccount, idMovement, idCustomerAssociated } =
    useContext(ContextOptionsEgress);

  const canBeAssociated =
    idStatus === 4 || idStatus === 5 || idStatus === 3 ? false : true;
  const itsOnProcess = idStatus === 2 ? true : false;

  const linkRedirectAssociate =
    idCustomerAssociated !== null && idCustomerAssociated > 0
      ? `/bancos/movimientos/${idBankAccount}/asociar/egresos/${idMovement}`
      : `/bancos/movimientos/${idBankAccount}/asociar-gasto/${idMovement}`;

  return (
    <>
      <div className={`${!canBeAssociated ? "" : "mr-3"}`}>
        {!canBeAssociated ? null : itsOnProcess ? (
          <RedirectButton
            link={linkRedirectAssociate}
            id="editMovement"
            text="Asociar"
          />
        ) : (
          <EgressModal
            cxp={`/bancos/movimientos/${idBankAccount}/asociar/egresos/${idMovement}`}
            concept={`/bancos/movimientos/${idBankAccount}/asociar-gasto/${idMovement}`}
          />
        )}
      </div>
    </>
  );
}
