import React, { useContext, useEffect } from "react";

// HELPERS
import { mnCurrency, calculateIvaPrice } from "../../../../../helpers/money";

// LIBS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";

// HOOKS
import useAddDocument from "../../../../../customHooks/useAddDocuments";

function Game({
  value,
  id,
  description,
  quantity,
  unitPrice,
  discount,
  sellPrice,
  iva,
  totalImport,
  code,
  index,
  isNewItem,
  ivaPrice,
  puVenta,
  subTotal,
  beforeExchange,
  currencyCode,
}) {
  const { deleteGame, setInputsEdit, confirmDeleteItem } = useAddDocument();

  const { games, setShopItemCart, setIsNewGame, currencyName } =
    useContext(AddDocumentContext);

  const { action } = useParams();

  const iconDelete = (
    <FontAwesomeIcon icon={faTimesCircle} className="iconCard" size="2x" />
  );

  const iconEdit = (
    <FontAwesomeIcon icon={faPen} className="iconCard ml-3" size="2x" />
  );

  sellPrice = unitPrice - unitPrice * (discount / 100);

  const editItemProduct = (idItemProduct) => {
    setIsNewGame(false);

    document.getElementById("triggerModal").click();

    let shopItemCart = games.find((item) => item.idFront === idItemProduct);

    shopItemCart = {
      ...shopItemCart,
      index,
    };

    setShopItemCart(shopItemCart);
    setInputsEdit(shopItemCart);
  };

  return (
    <div id={id} key={id} className="shadowBackground py-4 mb-4">
      <div className="row mx-0">
        <div className="col-1">
          <b>Clave</b>
        </div>

        <div className="col-1">
          <b>Descripcion</b>
        </div>

        <div className="col-1">
          <b>Cantidad</b>
        </div>

        <div className="col-1">
          <b>P.U.</b>
        </div>

        <div className="col-1">
          <b>Descuento</b>
        </div>

        <div className="col-1">
          <b>P.U. Venta</b>
        </div>

        <div className="col-1">
          <b>% IVA</b>
        </div>

        <div className="col-1">
          <b>Importe</b>
        </div>

        <div className="col-1">
          <b>IVA</b>
        </div>

        <div className="col-1">
          <b>Sub-total</b>
        </div>

        <div className="col-1">
          <b>Moneda</b>
        </div>

        <div className="col-1 d-flex justify-content-center">
          <div
            onClick={() => editItemProduct(id)}
            className="cursorPointer iconCard"
          >
            {iconEdit}
          </div>
        </div>
      </div>

      <div className="row mx-0 mt-2">
        <div className="col-1">{code}</div>

        <div className="col-1">{description}</div>

        <div className="col-1">{quantity}</div>

        <div className="col-1">{mnCurrency(unitPrice)}</div>

        <div className="col-1">{discount} %</div>

        <div className="col-1">{mnCurrency(puVenta)}</div>

        <div className="col-1">{iva} %</div>

        <div className="col-1">{mnCurrency(subTotal)}</div>

        <div className="col-1">{mnCurrency(ivaPrice)}</div>

        <div className="col-1">{mnCurrency(totalImport)}</div>

        <div className="col-1">{currencyName}</div>

        <div className="col-1 d-flex justify-content-center">
          {action == "editar" && games.length <= 1 ? null : (
            <div
              onClick={() => {
                if (action == "editar") confirmDeleteItem(id, isNewItem);
                else deleteGame(id);
              }}
              className="cursorPointer iconCard"
            >
              {iconDelete}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Game;
