import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import Dialog from "structure/Dialog";
import { ExcelInput } from "components/individual/ExcelInput";
import Footer from "./Footer";

/**
 * Render an html tag to open a modal that will handle the excel files
 * @param {import("./types").PropsSetupExcel} props - Props setupt
 * @returns {JSX.Element}
 */
export default function ExcelTemplate({
  children,
  type,
  title = "Set up",
  isOnlyForUpload = false,
}) {
  const [file, setFile] = useState(undefined);

  const hook = useDialog();

  return (
    <>
      <div onClick={hook.open}>{children}</div>
      <Dialog
        title={title}
        forwardRef={hook.refDialog}
        closeCallback={hook.close}
        footer={
          <Footer onUploaded={() => hook.close()} file={file} type={type} />
        }
      >
        <div className="d-flex flex-column align-items-center">
          <h1 className="text-center">
            Por favor, carga la plantilla de excel
          </h1>

          <ExcelInput onChange={(e) => setFile(e)} />

          {isOnlyForUpload ? (
            <p className="my-2 text-warning font-weight-bold text-center">
              Esta plantilla solo es para cargar datos, no habra datos
              pre-cargados en las hojas de Excel
            </p>
          ) : null}
        </div>
      </Dialog>
    </>
  );
}
