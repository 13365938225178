import SocialReason from "components/general/Forms/Selects/SocialReason";
import React, { useContext } from "react";
import { ContextGestionOc } from "..";

export default function Provider() {
  const hook = useContext(ContextGestionOc);

  return (
    <div>
      <b>Proveedor</b>
      <SocialReason
        isClearable={true}
        type="proveedor"
        onChange={(customer) => hook.setCustomerProvider("provider", customer)}
      />
    </div>
  );
}
