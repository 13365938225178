import Select from "components/individual/SelectV4";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextDocuments } from "../..";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function ComboDocument() {
  const hook = useContext(ContextDocuments);

  if (hook.comboDocuments.length <= 0)
    return (
      <div>
        <Label>Documento</Label>
        <Spinner text={"Cargando"} hidden={false} />
      </div>
    );

  return (
    <div>
      <Label>Documento</Label>
      <Select
        labelToDisplay="label"
        onChange={hook.setDocument}
        options={async () => hook.comboDocuments}
      />
    </div>
  );
}
