import { getIvasAvailables } from "helpers/Apis/parameters";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Select from "../SelectV4";
import { Label } from "structure/Document";
import { Spinner } from "../Spinner/Spinner";

/**
 * @type {import("./types").StateIvasAvailable}
 */
const STATE = {
  isLoading: true,
  ivas: [],
  canUseIvaExempt: false,
  isExempt: false,
  iva: undefined,
};

/**
 * @type {import("react").Context<import("./types").IvaContext>}
 */
const ContextIvasAvailable = React.createContext({
  ...STATE,
  setExempt: () => {},
  setIva: () => {},
});

/**
 * Render the ivas avaialble to use for the items of the system
 * @param {import("./types").PropsIvasAvailable} props - Props
 * @returns {JSX.Element}
 */
export default function IvasAvailable({ children = <></> }) {
  const [state, setState] = useState(STATE);

  useEffect(() => {
    (async function () {
      const ivas = await getIvasAvailables();

      let ivasList = [16];

      if (ivas.canUseIva0) ivasList.push(0);

      if (ivas.canUseIva8) ivasList.push(8);

      setState((current) => ({
        ...current,
        ivas: ivasList,
        canUseIvaExempt: ivas.canUseExempt,
        isLoading: false,
      }));
    })();
  }, []);

  useEffect(() => {
    if (state.iva !== 0) {
      setState((current) => ({
        ...current,
        isExempt: false,
      }));
    }
  }, [state.iva]);

  const setIva = (iva) =>
    setState((current) => ({
      ...current,
      iva,
    }));

  const setExempt = (isExempt) =>
    setState((current) => ({ ...current, isExempt }));

  if (state.isLoading) return <Spinner text={"Cargando ivas"} hidden={false} />;

  return (
    <ContextIvasAvailable.Provider value={{ ...state, setIva, setExempt }}>
      {children}
    </ContextIvasAvailable.Provider>
  );
}

/**
 *
 * @param {import("./types").PropsIva} props
 */
export function Iva({ onChange = () => {}, value = 16 }) {
  const hook = useContext(ContextIvasAvailable);
  useEffect(() => {
    hook.setIva(value);
  }, [value]);

  
  useEffect(() => {
    onChange(hook.iva);
    }, [hook.iva]);
    
    if (hook.isLoading) return <></>;
  return (
    <Select
      defaultSelected={{
        value: hook.iva,
        keyToCompare: "value",
      }}
      labelToDisplay="label"
      onChange={(data) => {
        hook.setIva(data.value);
        onChange(data.value);
      }}
      options={async () => [
        { label: "-- SELECCIONA --", value: null },
        ...hook.ivas.map((iva) => ({
          label: `${iva}%`,
          value: iva,
        })),
      ]}
    />
  );
}

/**
 *
 * @param {import("./types").PropsExempt} props
 */
export function IvaExempt({ onChange = () => {}, value = false }) {
  const hook = useContext(ContextIvasAvailable);

  if (hook.iva !== 0) return <></>;

  return (
    <Fragment>
      <Label>IVA excento</Label>
      <Select
        defaultSelected={{
          value,
          keyToCompare: "value",
        }}
        labelToDisplay="label"
        onChange={(data) => onChange(data.value)}
        options={async () => [
          {
            label: "-- SELECCIONA --",
            value: null,
          },
          {
            label: "Si, excento",
            value: true,
          },
          {
            label: "No, no excento",
            value: false,
          },
        ]}
      />
    </Fragment>
  );
}
