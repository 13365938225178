import React, { useEffect, useState } from "react";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import { Label } from "structure/Document";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Money from "components/general/Forms/Money";
import { createRemition } from "helpers/Apis/proyects";
import { Spinner } from "components/individual/Spinner/Spinner";
import { UenSelect, UenV4 } from "components/general/Forms/Selects/UenV4";
import { mnCurrency, percentage } from "helpers/money";

const INITIAL_STATE = {
  state: "none",
  amount: 0,
  description: "",
  iva: 0,
  total: 0,
  uen: undefined,
};

export default function CreateRemition({
  children = <></>,
  idPosition = null,
  onCreated = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    if (isOpen) {
      dialog.open();
      return;
    }
    setState(INITIAL_STATE);
  }, [isOpen]);

  useEffect(() => {
    const iva = percentage(state.amount, 16);
    const total = +(state.amount + iva).toFixed(2);

    setState((current) => ({
      ...current,
      iva,
      total,
    }));
  }, [state.amount]);

  const setAmount = (amount) =>
    setState((current) => ({
      ...current,
      amount,
    }));

  const setDescription = (description) =>
    setState((current) => ({
      ...current,
      description,
    }));

  const attemptCreateRemition = async () => {
    setState((current) => ({
      ...current,
      state: "updating",
    }));

    const wasCreated = await createRemition(
      idPosition,
      state.uen,
      state.amount,
      state.description
    );

    if (wasCreated) {
      setIsOpen(false);
      onCreated();
      return;
    }

    setState((current) => ({
      ...current,
      state: "none",
    }));
  };

  /**
   * Set the uen to create the document `pedido`
   * @param {number} uen - Id of the uen
   * @returns {void}
   */
  const setUen = (uen) =>
    setState((current) => ({
      ...current,
      uen,
    }));

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>

      {isOpen ? (
        <Dialog
          title={"Remisión"}
          width={35}
          forwardRef={dialog.refDialog}
          closeCallback={() => setIsOpen(false)}
          footer={
            <div className="d-flex justify-content-end align-items-center">
              {state.state === "updating" ? (
                <Spinner text={"Remisionando"} hidden={false} />
              ) : (
                <ButtonV2
                  onClick={attemptCreateRemition}
                  disabled={
                    state.amount <= 0 ||
                    state.description.length <= 0 ||
                    state.uen === undefined
                  }
                >
                  <span>Generar remisión</span>
                </ButtonV2>
              )}
            </div>
          }
        >
          <div className="row">
            <UenV4>
              <div className="col-12 mb-3">
                <Label required={typeof state.uen !== "number"}>UEN</Label>
                <UenSelect onChange={setUen} />
              </div>
            </UenV4>

            <div className="col-12 mb-3">
              <Label required={state.description.length <= 0}>
                Descripción
              </Label>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Escribe aquí"
              ></textarea>
            </div>

            <div className="col-md-4 col-sm-12 mb-3">
              <Label required={state.amount <= 0}>Importe a remisionar</Label>
              <Money maxDecimals={2} placeholder="0.00" onChange={setAmount} />
            </div>

            <div className="col-md-4 col-sm-12 mb-3">
              <p className="m-0">IVA</p>
              <p>{mnCurrency(state.iva)}</p>
            </div>
            <div className="col-md-4 col-sm-12 mb-3">
              <p className="m-0">Total</p>
              <p>{mnCurrency(state.total)}</p>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  );
}
