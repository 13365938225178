import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";

/**
 * Update the uen table on the system with an excel file
 * @param {import("structure/Bug/Tickets/hooks/types").CreateTicket} ticket - Info of the ticket
 * @param {File[]} files - Update the excel file
 * @returns {Promise<import("../../../../../server/models/tickets/paginate/types").Ticket|null>}
 */
export async function createTicket(ticket, files = []) {
  const formData = new FormData();
  formData.append("json", JSON.stringify(ticket));

  files.forEach((file, i) => formData.append(`${i}`, file));

  try {
    const res = await fetch(`${URL_BASE}tickets`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    /**
     * Information of the server when tried to create the ticket
     * @type {import("../../../../../server/controllers/tickets/types").DtoResCreateTicket}
     */
    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return data.ticket;
    }

    Error(() => {}, data.message);
    return null;
  } catch (error) {
    return null;
  }
}

/**
 *
 * @param {any} tickets - Tickets
 * @param {number} page - Number of page requested
 * @param {any} status - Status of the tickets
 * @param {number} rowsPerPage - Rows per page
 * @returns {Promise<import("../../../../../server/models/tickets/paginate/types").DtoTickets>} Information of the tickets got
 */
export async function getTickets(
  tickets = "personals",
  page = 1,
  status = "open",
  rowsPerPage = 100
) {
  try {
    const res = await fetch(
      `${URL_BASE}tickets?page=${page}&filterBy=${tickets}&status=${status}&rowsPerPage=${rowsPerPage}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return { tickets: [], currentPage: 0, pages: 0 };
  } catch (error) {
    return { tickets: [], currentPage: 0, pages: 0 };
  }
}

/**
 * Get the users that can be responsable of the tickets
 * @returns {Promise<import("../../../../../server/models/tickets/admin/types").ComboTickets[]>}
 */
export async function ticketsCombo() {
  try {
    const res = await fetch(`${URL_BASE}tickets/responsables`, {
      credentials: "include",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the comments of a ticket
 * @param {number} ticket - Id of the ticket
 * @returns {Promise<import("../../../../../server/models/tickets/comments/types").TicketCommentI[]>} Comments
 */
export async function ticketsComments(ticket = 0) {
  try {
    const res = await fetch(`${URL_BASE}tickets/comentario/${ticket}`, {
      credentials: "include",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the comments of a ticket
 * @param {number} idTicket - Id of the ticket
 * @param {string} comment - Content of the comment
 */
export async function addTicketComment(idTicket, comment) {
  try {
    const res = await fetch(`${URL_BASE}tickets/comentario`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idTicket, comment }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Assign a user to handle a ticket
 * @param {number} idTicket - Id of the ticket
 * @param {number} assignTo - Id of the user that will handle the ticket
 * @returns {Promise<boolean>} True if was assigned correctly
 */
export async function assignUserTicket(idTicket, assignTo) {
  try {
    const res = await fetch(`${URL_BASE}tickets/asignar`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idTicket, assignTo }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Reject a created ticket
 * @param {number} idTicket - Id of the ticket
 * @returns {Promise<boolean>} True if the ticket was rejected
 */
export async function rejectTicket(idTicket) {
  try {
    const res = await fetch(`${URL_BASE}tickets/rechazar`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idTicket }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

export async function startTicket(idTicket) {
  try {
    const res = await fetch(`${URL_BASE}tickets/iniciar/${idTicket}`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

export async function closeTicket(idTicket) {
  try {
    const res = await fetch(`${URL_BASE}tickets/terminar/${idTicket}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

export async function scoreTicket(idTicket, rate, comment) {
  try {
    const res = await fetch(`${URL_BASE}tickets/calificar`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idTicket, rate, comment }),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get a ticket of the system
 * @param {number?} idTicket - Id of the ticket to search
 * @param {number?} folio - Folio of the ticket to search
 * @returns {Promise<import("../../../../../server/models/tickets/paginate/types").Ticket|null>}
 */
export async function getTicket(idTicket = 0, folio = 0) {
  let queryParam = "";

  if (idTicket !== 0 && idTicket !== null) {
    queryParam += `id=${idTicket}`;
  }

  if (folio !== 0 && folio !== null) {
    queryParam += `&folio=${folio}`;
  }

  try {
    const res = await fetch(`${URL_BASE}tickets/busqueda?${queryParam}`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Update the comments notifications
 * @param {number} idTicket - Id of the ticket
 * @returns {Promise<boolean>} True if was updated
 */
export async function toggleCommentNotifications(idTicket) {
  try {
    const res = await fetch(`${URL_BASE}tickets/comentario/notificaciones?idTicket=${idTicket}`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, "Notificaciones actualizadas");
      return true;
    }

    const data = await res.json();
    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}
