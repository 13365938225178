import { Spinner } from "components/individual/Spinner/Spinner";
import useDialog from "customHooks/useDialog";
import { getAdvertisementById } from "helpers/Apis/Advertisements";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import style from "./styles.module.scss";
import { getDateFromUtc, parseDateToText } from "helpers/dates";

/**
 * @type {import("./types").StateAdvertisementModal}
 */
const INITIAL_STATE = {
  isLoading: true,
  advertisement: null,
  isOpen: false,
};

/**
 * Render a component to display more information about and advertisement
 * @param {import("./types").PropsMoreInfoAdvertisement} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfoAdvertisement({
  children,
  id,
  forwardRef = undefined,
}) {
  const dialog = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      const advertisementApi = await getAdvertisementById(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        advertisement: advertisementApi,
      }));
    })();
  }, [state.isOpen, id]);

  const handleModal = (isOpen) => {
    setState((current) => ({
      ...current,
      isOpen,
    }));

    if (isOpen) {
      dialog.open();
    } else {
      setState(INITIAL_STATE);
      dialog.close();
    }
  };

  return (
    <>
      <div ref={forwardRef} onClick={() => handleModal(true)}>
        {children}
      </div>

      <Dialog
        title="Más información"
        closeCallback={() => handleModal(false)}
        forwardRef={dialog.refDialog}
      >
        {state.isLoading && <Spinner text={"Cargando aviso"} hidden={false} />}

        {state.advertisement !== null ? (
          <div className={style.containerMoreInfo}>
            <div>
              <Label>Periodo</Label>
              <p>
                {parseDateToText(new Date(`${state.advertisement.startDate}:`))}{" "}
                al{" "}
                {parseDateToText(new Date(`${state.advertisement.endDate}:`))}
              </p>
            </div>

            <div>
              <Label>Tipo</Label>
              <p>{state.advertisement.typeDescription}</p>
            </div>

            <div>
              <Label>Mensaje</Label>
              <p>{state.advertisement.message}</p>
            </div>

            <div>
              <Label>Registrado</Label>
              <p>
                Por {state.advertisement.createdBy} el{" "}
                {parseDateToText(
                  getDateFromUtc(state.advertisement.createdDate),"full"
                )}
              </p>
            </div>

            <div>
              <Label>Estatus</Label>
              <p>{state.advertisement.statusDescription}</p>
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  );
}
