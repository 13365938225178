import React, { createContext, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEye,
  faPen,
  faPercent,
  faCartPlus,
  faPlus,
  faSave,
  faXmark,
  faUserTag,


} from "@fortawesome/free-solid-svg-icons";
import {
  Icon,
  CardContainer,
  Description,
  PricesInfo,
  Footer,
  CurrencyAdvertisment,
} from "./styles";
import ModalV2 from "../Modals/ModalV2";
import { YesNoAlert } from "helpers/alerts";
import styles from "./CardV2.module.scss";
import { calculateMarginRate, mnCurrency } from "helpers/money";

import WarningMargin from "./WarningMargin";
import PercentWarning from "./PercentWarning";
import MarginRate from "components/general/MarginRate";
import UEN from "./Labels/UEN";
import SatCode from "./Labels/SatCode";
import UmSat from "./Labels/UmSat";
import Quantity from "./Labels/Quantity";
import TC from "./Labels/TC";
import CU from "./Labels/CU";
import CUSell from "./Labels/CUSell";
import Import from "./Labels/Import";
import Discount from "./Labels/Discount";
import { ContextItems } from "structure/Partidas";
import usePermissions from "customHooks/usePermissions";


export const Trash = <FontAwesomeIcon icon={faTrash} />;
export const Eye = <FontAwesomeIcon icon={faEye} />;
export const Pen = <FontAwesomeIcon icon={faPen} />;
export const Percent = <FontAwesomeIcon icon={faPercent} />;
export const AddCart = <FontAwesomeIcon icon={faCartPlus} />;
export const AddPlus = <FontAwesomeIcon icon={faPlus} />;
export const Save = <FontAwesomeIcon icon={faSave} />;
export const XMark = <FontAwesomeIcon icon={faXmark} />;
export const UserTag = <FontAwesomeIcon icon={faUserTag} />;

/**
 * @type {React.Context<{
 *  item:CalculatedDocItem,
 *  tc:number
 * }>}
 */
export const ViewItem = createContext();
const { Provider } = ViewItem;

/**
 * Render a card with the information of the item
 * @param {object} props - Props
 * @param {import("types/typedef/catalogue").CalculatedItemI} props.item - Item to render
 * @param {"USD"|"MXN"} props.currency - Currency that's being used for the document
 * @param {()=>void} props.onDelete - Callback executed when delete was requested
 * @param {"pu"|"cu"} props.calculationMode - Indicates if the Costs or Prices should be showed on the cards
 * @param {number} props.tc - TC used on the document
 * @param {(item:import("types/typedef/catalogue").CalculatedItemI)=>void} props.onChange - Item recalculated according the new parameters edited
 * @param {number} props.index - Position of the element on the list of the shop cart
 * @returns {JSX.Element} Card
 */
export default function Card({
  item,
  onDelete = () => {},
  calculationMode,
  tc = 0,
  currency,
  onEdit = () => {},
  index,
}) {
  const { informative, canEdit, canDelete } = useContext(ContextItems);

  const idOpenModal = window.crypto.randomUUID();

  const idOpenEdit = window.crypto.randomUUID();

  const handleDelete = () => YesNoAlert("¿Estas seguro?", onDelete);

  const marginRateGot = calculateMarginRate(
    item.utility,
    item.calculations.price.import
  );

  const isSameCurrency = item.currency.code === currency;

  const discountToUse =
    calculationMode === "pu"
      ? item.calculations.price.discount
      : item.calculations.cost.discount;

  const pricesToUse =
    calculationMode === "pu" ? item.calculations.price : item.calculations.cost;

  const { checkPermission } = usePermissions();

  return (
    <Provider
      value={{
        item,
        tc,
        currency,
      }}
    >
      <CardContainer>
        <ModalV2
          title={`${item.description} - ${item.sku}`}
          showCancelButton={false}
          idOpenModal={idOpenModal}
          footer={
            <Footer isSameCurrency={isSameCurrency}>
              {isSameCurrency ? null : (
                <CurrencyAdvertisment>
                  Moneda de compra {item.currency.code}
                </CurrencyAdvertisment>
              )}

              {checkPermission("1510fe05-1a69-4312-8237-039d75c39a4e") ? (
                <MarginRate
                  currency={currency}
                  margin={marginRateGot}
                  utility={item.utility}
                />
              ) : null}
            </Footer>
          }
        >
          <div className="container">
            <UEN />
            <SatCode />
            <UmSat />
            <Quantity />
            <TC />

            <div className={styles.informative}>
              <p>Moneda</p>
              <p>{currency}</p>
            </div>

            <h5 className={styles.divisor}>
              Información de la compra{" "}
              {isSameCurrency ? null : (
                <CurrencyAdvertisment></CurrencyAdvertisment>
              )}
            </h5>
            {/* <Currency /> */}
            <CU />
            <CUSell />
            <Discount />
            <Import />

            <div className={styles.informative}>
              <p>IVA</p>
              <p>{mnCurrency(item.calculations.cost.iva)}</p>
            </div>

            <div className={styles.informative}>
              <p>IVA Excento</p>
              <p>{item.iva.exempt ? "Si" : "No"}</p>
            </div>

            <h5 className={styles.divisor}>Información de la venta</h5>
            {/* <Currency /> */}

            <div className={styles.informative}>
              <p>Precio unitario</p>
              <p>{mnCurrency(item.calculations.price.unitary)}</p>
            </div>

            <div className={styles.informative}>
              <p>Precio unitario de venta</p>
              <p>{mnCurrency(item.calculations.price.sell)}</p>
            </div>

            <div className={styles.informative}>
              <p>Descuento</p>
              <p>{mnCurrency(item.calculations.price.discount)}</p>
            </div>

            <div className={styles.informative}>
              <p>Importe</p>
              <p>{mnCurrency(item.calculations.price.import)}</p>
            </div>

            <div className={styles.informative}>
              <p>IVA</p>
              <p>{mnCurrency(item.calculations.price.iva)}</p>
            </div>

            <div className={styles.informative}>
              <p>IVA Excento</p>
              <p>{item.iva.exempt ? "Si" : "No"}</p>
            </div>
          </div>
        </ModalV2>

        <div className="d-flex justify-content-between">
          <p>Clave: {item.sku}</p>
          <div className="d-flex align-items-center justify-content-evenly">
            {informative ? null : checkPermission(
                "be909d79-3f4d-422b-bfab-95b233b96ae6"
              ) ? (
              <Icon color="#c40000" onClick={handleDelete}>
                {Trash}
              </Icon>
            ) : null}

            {checkPermission("1510fe05-1a69-4312-8237-039d75c39a4e") ? (
              <Icon
                type="button"
                onClick={() => document.getElementById(idOpenModal).click()}
              >
                {Eye}
              </Icon>
            ) : null}

            {informative && !canEdit ? null : (
              <Icon type="button" onClick={() => onEdit(index)} color="#00809e">
                {Pen}
              </Icon>
            )}

            <WarningMargin utility={item.utility} />
            <PercentWarning
              minPercentage={item.uen.marginRate}
              percentage={marginRateGot}
            />
          </div>
        </div>

        <Description>{item.description}</Description>

        <PricesInfo>
          <div>
            Cantidad:
            <span>{item.quantity.quantity}</span>
          </div>

          <div>
            {calculationMode === "pu" ? <>P. unitario:</> : <>C. initario: </>}
            <span>{mnCurrency(pricesToUse.unitary)}</span>
          </div>

          <div>
            Descuento:
            <span>
              {mnCurrency(+(discountToUse / item.quantity.quantity).toFixed(2))}
            </span>
          </div>

          <div>
            {calculationMode === "pu" ? <>P. venta:</> : <>C. venta: </>}
            <span>{mnCurrency(pricesToUse.sell)}</span>
          </div>

          <div>
            Importe:
            <span>{mnCurrency(pricesToUse.import)}</span>
          </div>
        </PricesInfo>
      </CardContainer>
    </Provider>
  );
}
