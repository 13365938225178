import Select from "components/individual/SelectV4";
import React from "react";

/**
 * Render status accounting
 * @param {import("./types").PropsStatusAccounting} props - Props
 * @returns {JSX.Element}
 */
export default function StatusAccounting({
  onChange = () => {},
  value = null,
}) {
  return (
    <div>
      <b>Estatus</b>
      <Select
        labelToDisplay="label"
        onChange={data=>onChange(data.value)}
        defaultSelected={{
          keyToCompare: "value",
          value,
        }}
        options={async () => [
          {
            label: "Todos",
            value: null,
          },
          {
            label: "Contabilizados",
            value: true,
          },
          {
            label: "No contabilizados",
            value: false,
          },
        ]}
      />
    </div>
  );
}
