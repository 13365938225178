import { Modal } from "components/general/Modal";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { ContextPushNotification } from "..";
import Icon from "components/individual/HeaderIcon";

export default function TurnOn({ isOpen = false }) {
  const hook = useContext(ContextPushNotification);

  return (
    <Modal
      title="Reactivar notificaciones"
      displayButton={false}
      isOpen={isOpen}
      onCloseModal={hook.closeTurnNotifications}
      footerV2={() =>
        hook.isActivating ? (
          <Spinner hidden={false} text="Activando notificaciones" />
        ) : (
          <ButtonV2 onClick={hook.attemptResumeNotifications}>
            <span>Reactivar notificaciones</span>
            <Icon icon="powerOff"/>
          </ButtonV2>
        )
      }
    >
      {() => (
        <h2 className="text-center my-5">
          🔴 Las notificaciones estan desactivadas
        </h2>
      )}
    </Modal>
  );
}
