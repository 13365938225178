export const UPDATE_REGULAR_COMMENTS = "UPDATE_REGULAR_COMMENTS";
export const UPDATE_DOCUMENT_ITEMS_INFO = "UPDATE_DOCUMENT_ITEMS_INFO";
export const UPDATE_PROBABILITY = "UPDATE_PROBABILITY";
export const UPDATE_REMINDER_DATE = "UPDATE_REMINDER_DATE";
export const UPDATE_EXPIRATION_DATE = "UPDATE_EXPIRATION_DATE";
export const UPDATE_TC = "UPDATE_TC";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_NOTES_AND_CONSIDERATIONS =
  "UPDATE_NOTES_AND_CONSIDERATIONS";
export const UPDATE_PERIOCITY = "UPDATE_PERIOCITY";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_IS_PERFORMING_QUERY = "SET_IS_PERFORMING_QUERY";
export const SET_CONTACT = "SET_CONTACT";
export const APPEND_CONTACT_MEMORY = "APPEND_CONTACT_MEMORY";
export const GET_CONTACTS = "GET_CONTACTS";
