import { useContext, useEffect, useState } from "react";

// CONTEXT
import AddDocumentContext from "../context/AddDocument/AddDocumentContext";
import LoginContext from "../context/Login/LoginContext";

// HELPERS
import {
  mnCurrency,
  parseToFloat,
  calculateIvaPrice,
  calculateUnitPriceSell,
  calculateDiscount,
  convertPrice,
  truncateDecimals,
} from "../helpers/money";

import { Success, YesNoAlert } from "../helpers/alerts";
import {
  GetDocument,
  UpdateUnitCost,
  UpdateUnitPrice,
} from "../helpers/Apis/Documents";

// LIBS
import { v4 as uuidv4 } from "uuid";

// APIS
import { GetDocumentInfo } from "../helpers/Apis/Documents";

import { useParams } from "react-router";
import { trimDate } from "../helpers/dates";

export default function useAddDocument() {
  const {
    infoGame,
    ivas,
    setIvas,
    setInfoGame,
    setIsNewGame,
    setGames,
    currencyType,
    tcp,
    setTCP,
    uenList,
    games,
    setCatalogue,
    catalogue,
    setShopItemCart,
    shopItemCart,
    comments,
    deleteComments,
    commentsToDelete,
    setComments,
    setItemsToDelete,
    docItemsToDelete,
    setCurrency,
    exchangeTcp,
    currencyName,
    setCurrencyUsed,
  } = useContext(AddDocumentContext);

  const { documentId } = useParams();

  const { userInfo } = useContext(LoginContext);

  const description = document.getElementById("description");
  const price = document.getElementById("unitPrice");
  const discount = document.getElementById("discount");
  const uen = document.getElementById("uen");
  const satCode = document.getElementById("satCode");
  const satUm = document.getElementById("umSat");
  const iva = document.getElementById("iva");
  const quantity = document.getElementById("quantity");
  const code = document.getElementById("codeGame");
  const form = document.getElementById("formAddToCart");
  const unitCost = document.getElementById("unitCost");
  const currency = document.getElementById("moneda");

  const lblCode = document.getElementById("lbl_codeGame");
  const lblSatCode = document.getElementById("lbl_satCode");
  const lblSatUm = document.getElementById("lbl_umSat");
  const lblUen = document.getElementById("lbl_uen");
  const lblIva = document.getElementById("lbl_iva");

  const lblUnitPrice = document.getElementById("lblUnitPrice");
  const lblUnitCost = document.getElementById("lblUnitCost");

  function updateLabelsPrices() {
    const currencyText =
      infoGame.currencyCode === undefined ? "" : `(${infoGame.currencyCode})`;

    lblUnitPrice.textContent = `Precio unitario * ${currencyText}`;
    lblUnitCost.textContent = `Costo unitario * ${currencyText}`;
  }

  useEffect(() => {
    if (ivas.length > 0 && iva !== null) {
      iva.selectedIndex = 0;
    }

    // When the user select a product/service to add on document
  }, [ivas]);

  function setInputsGame() {
    form.reset();
    description?.setAttribute("value", infoGame.description);
    discount?.setAttribute("value", 0);
    uen?.setAttribute("value", infoGame.uenDescription);

    satCode?.setAttribute("value", infoGame.satCode);
    satCode?.setAttribute("readonly", true);

    satUm?.setAttribute("value", infoGame.satUm);
    satUm?.setAttribute("readonly", true);

    code?.setAttribute("value", infoGame.code);
    code?.setAttribute("readonly", true);

    iva?.setAttribute("disabled", true);

    price.value = infoGame.unitPrice;

    lblCode.innerText = "Clave";
    lblSatCode.innerText = "Clave SAT";
    lblSatUm.innerText = "UM SAT";
    lblUen.innerText = "UEN";
    lblIva.innerText = "IVA";

    setIva(infoGame.iva);
  }

  /**
   * Calculate the unit price sell,iva price and subtotal
   *
   * @returns {CalculatedDocItem} Info with the prices
   */
  function calculateInfoPrices() {
    const numberItem = truncateDecimals(+quantity.value, 4);
    const ivaItem = truncateDecimals(+iva.value, 4);

    // Unit price of the item
    let puVenta = truncateDecimals(
      calculateUnitPriceSell(parseToFloat(price.value), +discount.value),
      4
    );

    let ivaPrice = truncateDecimals(
      calculateIvaPrice(numberItem, puVenta, ivaItem),
      4
    );

    let subTotal = truncateDecimals(puVenta * numberItem, 4);

    return {
      puVenta,
      ivaPrice,
      subTotal,
    };
  }

  function setTipoCambioProtegido(id) {
    if (currencyType === 1) {
      document.getElementById(id).setAttribute("readonly", true);
      let tcpSaiko = tcp.saiko;

      setTCP({
        ...tcp,
        test: tcpSaiko,
      });
    } else {
      // document.getElementById(id).classList.remove('d-none');
      document.getElementById(id).value = mnCurrency(tcp.test);
      document.getElementById(id).removeAttribute("readonly");
    }
  }

  /**
   * Validate that the cost it's not greather than price
   *
   * @param {string} cost - Cost of the input like $34.93
   * @param {string} price - Price of the input like $12.3
   * @returns {boolean} True if validations are right
   */
  function isValidCost(cost, price) {
    const parsedCost = parseToFloat(cost);
    const parsedPrice = parseToFloat(price);

    if (parsedCost >= parsedPrice) {
      showError("unitCostError", "Costo incorrecto");
      return false;
    }

    hideError("unitCostError");
    return true;
  }

  /**
   * Display an error message
   *
   * @param {string} id - id attribute dom element
   * @param {string} message - message to display
   */
  function showError(id, message) {
    const htmlElement = document.getElementById(id);
    htmlElement.innerText = message;
  }

  /**
   *
   * @param {string} id - id attribute dom element
   */
  function hideError(id) {
    const htmlElement = document.getElementById(id);
    htmlElement.innerText = "";
  }

  /**
   * Set the IVA of the product as default on the select iva combo
   *
   * @param {number} iva - IVA of the game/product/service
   * @param {object[]} List of the current ivas to display on combo
   */
  function setIva(iva) {
    const copyIvas = [...ivas];

    // Insert it on first position, this will make it be the default option
    copyIvas.unshift({
      percentage: iva,
      percentage_text: `${iva}%`,
    });

    // Update the ivas
    setIvas(copyIvas);

    return copyIvas;
  }

  function setIvasNewGame(ivas) {
    setIvas(ivas);
  }

  /**
   * Calculate the prices for the card according to the selected game
   *
   * @returns {object} Calculated total import and sell price
   */
  function calculatePrices() {
    const discount = getDiscount();

    // Unit price of the item
    const newPrice = truncateDecimals(parseToFloat(price.value), 4);
    const newSellPrice = truncateDecimals(
      newPrice - (newPrice * discount) / 100,
      4
    );

    // Iva price
    const numberItem = +quantity.value;
    const ivaItem = truncateDecimals(+iva.value, 4);
    const ivaPrice = truncateDecimals(
      calculateIvaPrice(numberItem, newSellPrice, ivaItem),
      4
    );

    // CALCULATE TOTAL IMPORT
    const newImport = truncateDecimals(newSellPrice * numberItem + ivaPrice, 4);

    return {
      newSellPrice,
      newImport,
    };
  }

  /**
   * When adding or creating a product/service, update
   * the list of games on the current document
   */
  function updateActualGames(newGames) {
    let newCart = [...games];

    newCart.unshift(newGames);

    setGames(newCart);
  }

  /**
   * When confirm add a new item, reset the form
   */
  function resetModal() {
    quantity?.setAttribute("value", "");
    discount?.setAttribute("value", "");
  }

  /**
   * Get the discount from the input
   * @returns {number} Discount to use on product/service
   */
  function getDiscount() {
    if (isNaN(+discount.value)) {
      return 0;
    } else {
      return +discount.value;
    }
  }

  function addFromCatalogue() {
    const discount = truncateDecimals(getDiscount(), 4);

    //////////////////////// CALCULATE P.U. Venta
    let newPrice = truncateDecimals(parseToFloat(price.value), 4);
    let newSellPrice = truncateDecimals(
      newPrice - (newPrice * discount) / 100,
      4
    );

    /////////////////////// CALCULATE IMPORT
    let ivaCost = truncateDecimals(
      newSellPrice * +quantity.value * (+iva.value / 100),
      4
    );
    let newImport = truncateDecimals(
      newSellPrice * +quantity.value + ivaCost,
      4
    );

    let { ivaPrice, subTotal, puVenta } = calculateInfoPrices();

    let sellPrice = truncateDecimals(parseToFloat(unitCost.value), 4);

    let copyGame = {
      beforeExchange: {
        sellPrice,
        price: newPrice,
        ivaPrice,
        subTotal,
        puVenta,
        totalImport: subTotal + ivaPrice,
      },
    };

    if (currencyType !== infoGame.currency) {
      sellPrice = truncateDecimals(
        convertPrice(
          sellPrice,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        4
      );
      newPrice = truncateDecimals(
        convertPrice(
          newPrice,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        4
      );
      ivaPrice = truncateDecimals(
        convertPrice(
          ivaPrice,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        4
      );
      subTotal = truncateDecimals(
        convertPrice(
          subTotal,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        4
      );
      puVenta = truncateDecimals(
        convertPrice(puVenta, exchangeTcp, infoGame.currencyCode, currencyName),
        4
      );
      newImport = truncateDecimals(
        convertPrice(
          newImport,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        4
      );
    }

    copyGame = {
      ...infoGame,
      ...copyGame,
      quantity: +quantity.value,
      discount: truncateDecimals(discount, 4),
      uenDescription: uen.value,
      iva: truncateDecimals(+iva.value, 4),
      satCode: satCode.value,
      satUm: satUm.value,
      totalImport: newImport,
      idFront: uuidv4(),
      mustBeAdded: false,
      isNewItem: true,
      sellPrice,
      price: newPrice,
      ivaPrice,
      subTotal,
      puVenta,
    };

    updateActualGames(copyGame);

    return copyGame;
  }

  /**
   * Print the SAT information when select a UEN
   *
   * @param {string} satCode - SAT code to print on input
   * @param {string} satUm - SAT UM to print on input
   */
  function setInputsNewProduct(code, um) {
    satCode.value = code;
    satUm.value = um;
  }

  function findInfoUen(idToFind) {
    const uen = uenList.find((uen) => uen.UENID === idToFind);
    return uen;
  }

  function cancelAddProduct() {
    setInfoGame(null);
    setIsNewGame(false);
    quitIva();
    setShopItemCart(null);
    hideError("unitCostError");
  }

  /**
   * Update the info from an item of the shop cart that the user edited
   * @param {object} infoEdited - Info of the edition
   */
  function updateItem(infoEdited) {
    let item = games.find((item) => item.idFront === shopItemCart.idFront);

    item.quantity = infoEdited.quantity;
    item.price = truncateDecimals(parseToFloat(infoEdited.unitPrice), 4);
    item.discount = infoEdited.discount;

    let { newSellPrice, newImport } = calculatePrices();

    /**
     * @type {CalculatedDocItem}
     */
    let { puVenta, ivaPrice, subTotal } = calculateInfoPrices();

    let sellPrice = parseToFloat(infoEdited.unitCost);

    item = {
      ...item,
      totalImport: newImport,
      unitPrice: item.price,
      sellPrice,
      puVenta,
      ivaPrice,
      subTotal,
      beforeExchange: {
        price: item.price,
        sellPrice,
        puVenta,
        ivaPrice,
        subTotal,
        totalImport: newImport,
      },
    };

    if (currencyType !== infoGame.currency) {
      item = {
        ...item,
        unitPrice: convertPrice(
          item.price,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        price: convertPrice(
          item.price,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        sellPrice: convertPrice(
          sellPrice,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        puVenta: convertPrice(
          puVenta,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        ivaPrice: convertPrice(
          ivaPrice,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        subTotal: convertPrice(
          subTotal,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
        totalImport: convertPrice(
          newImport,
          exchangeTcp,
          infoGame.currencyCode,
          currencyName
        ),
      };
    }

    let copyGames = [...games];

    copyGames[shopItemCart.index] = item;

    setGames(copyGames);

    return item;
  }

  /**
   * Quit the IVA of the product/game/service selected from the
   * list of combos.
   */
  function quitIva() {
    // Un-referenced copy of the state
    const copyIvas = [...ivas];

    // Quit first element (default iva from the catalogue)
    copyIvas.shift();

    // Update state of ivas
    setIvas(copyIvas);
  }

  function setInputsAdd() {
    lblCode.innerText = "Clave *";
    lblSatCode.innerText = "Clave SAT";
    lblSatUm.innerText = "UM SAT";
    lblUen.innerText = "UEN *";
    lblIva.innerText = "IVA *";

    description.value = infoGame.value;
    description.disabled = false;

    code?.setAttribute("value", "");
    code?.removeAttribute("readonly");

    satCode?.setAttribute("readonly", "true");
    satUm?.setAttribute("readonly", "true");
    iva?.removeAttribute("disabled");
  }

  /**
   * Delete a game/product/service from the document
   *
   * @param {string|number} idToDelete - ID of the card to delete from the UI and state of context
   * @param {boolean} isNewItem - If true, the item came from the database
   */
  function deleteGame(idToDelete, isNewItem) {
    // Un-referenced copy
    let copyGames = [...games];

    // Quit the element clicked
    copyGames = copyGames.filter((game) => game.idFront !== idToDelete);

    // Update the stategit
    setGames(copyGames);

    // Item came from the database
    if (!isNewItem) {
      // Create array with the id doc items that will be delete on the query execution
      const copyToDelete = [...docItemsToDelete];

      copyToDelete.push(idToDelete);

      setItemsToDelete(copyToDelete);
    }
  }

  /**
   * Evaluate if the unit cost changed on the input
   * @param {string} costInput - Unit cost from input
   * @returns {boolean} True if the cost changed
   */
  function unitCostChanged(costInput, item) {
    let itemCatalogue = catalogue.filter(
      (catItem) => catItem.id === item.id || item.idCatalogue
    );

    const different = itemCatalogue[0]["sellPrice"] !== parseToFloat(costInput);
    return different;
  }

  /**
   * Evaluate if the unit price changed on the input
   * @param {string} priceInput - Unit price from input
   * @returns {boolean} True if the unit price changed by the executive
   */
  function unitPriceChanged(priceInput, item) {
    const itemCatalogue = catalogue.filter(
      (catItem) => catItem.id === item.id || item.idCatalogue
    );

    const different =
      itemCatalogue[0]["unitPrice"] !== parseToFloat(priceInput);

    return different;
  }

  function promptUpdatePrice(info) {
    const message = "¿ Deseas actualizar el precio unitario en el catalogo ?";

    // // Item was just created ON MEMORY
    if (info.id === undefined) return;

    // Append the info of the user who edited the cost
    const updatedInfo = {
      ...info,
      editedBy: userInfo[0].fullName,
    };

    // Item it's already on catalogue
    if (typeof info.id === "number") {
      YesNoAlert(message, () => {
        // // Update on the database
        // UpdateUnitCost(updatedInfo);
        // // Update on the memory catalogue
        // updatePrices(info,'unitCost');
      });
    } else {
      // It's on memory, just update the memory catalogue
      YesNoAlert(message, () => {
        // // Update on the memory catalogue
        // updatePrices(info,'unitCost');
        // // Prompt success update on memory catalogue
        // Success(()=>{},'Precio unitario actualizado');
      });
    }
  }

  /**
   * Update the unit cost of a document item on the catalogue
   * (memory and database)
   *
   * @param {UpdatedPrices} info - Info of the prices for the catalogue
   */

  function promptUpdateCost(info) {
    const message = "¿ Deseas actualizar el costo unitario en el catalogo ?";

    // Item was just created ON MEMORY
    if (info.id === undefined) return;

    // Append the info of the user who edited the cost
    const updatedInfo = {
      ...info,
      editedBy: userInfo[0].fullName,
    };

    // Item it's already on catalogue
    if (typeof info.id === "number") {
      YesNoAlert(message, () => {
        // Update on the database
        UpdateUnitCost(updatedInfo);

        // Update on the memory catalogue
        updatePrices(info, "unitCost");
      });
    } else {
      // It's on memory, just update the memory catalogue
      YesNoAlert(message, () => {
        // Update on the memory catalogue
        updatePrices(info, "unitCost");

        // Prompt success update on memory catalogue
        Success(() => {}, "Precio unitario actualizado");
      });
    }
  }

  /**
   * Update the unit cost on the catalogue of the item updated
   *
   * @param {InfoToUpdate} info - Information of the new price to use on catalogue
   * @param {boolean} isInMemory - If true, will just update on the client-side
   */
  function updateCost(info, isInMemory) {
    if (!isInMemory) {
      // Update on the database
      UpdateUnitCost(info);
    }

    // Update on the memory catalogue
    updatePrices(info, "unitCost");
  }

  function updatePrice(info, isInMemory) {
    if (!isInMemory) {
      // Update on the database
      UpdateUnitPrice(info);
    }
    updatePrices(info, "unitPrice");
  }

  /**
   * Update the info of the document item
   *
   * @param {InfoToUpdate} info - Info with the prices
   */
  function updatePricesDocItems(info) {
    // Item was just created, not need to update
    // because it's just being created
    if (typeof info.idFront != "string") return;

    // User clicked the edit button
    // Must update the prices ON THE CARD
    const copyDocItems = [...games];

    const updatedDocItem = copyDocItems.map((item) => {
      if (info.idFront === item.idFront) {
        return {
          ...item,
          unitPrice: info.unitPrice,
          sellPrice: info.unitCost,
        };
      } else {
        return item;
      }
    });

    setGames(updatedDocItem);
  }

  /**
   * Update the prices of the memory catalogue that has
   * the client
   *
   * @param {UpdatedPrices} data - Information with the data to update
   * @param {'unitCost'|'unitPrice'} toUpdate - Attribute to update on the catalogue
   */
  function updatePrices(data, toUpdate) {
    const copyCatalogue = [...catalogue];

    const updatedCatalogue = copyCatalogue.map((item) => {
      // Item with the id founded
      if (item.id === data.id) {
        // Update unit cost
        if (toUpdate === "unitCost") {
          item.sellPrice = data.unitCost;
        }

        // Update unit price
        else {
          item.unitPrice = data.unitPrice;
          item.price = data.unitPrice;
        }
      }

      return item;
    });

    // Update state of catalogue
    setCatalogue(updatedCatalogue);
  }

  /**
   * Show an alert to confirm delete the item from the document
   * @param {string|number} id - Id of the element that could be deleted
   * @param {boolean} isNewItem - If true, the item came from the database
   */
  function confirmDeleteItem(id, isNewItem) {
    YesNoAlert(
      "¿ Confirmas eliminar la partida ?",
      () => deleteGame(id, isNewItem),
      () => {}
    );
  }

  function confirmDeleteComment(id) {
    YesNoAlert(
      `¿ Confirmas borrar el comentario ?`,
      () => deleteComment(id),
      () => {}
    );
  }

  /**
   * Exchange the currency prices of the product/services if they have differente
   * currency from the body document
   * @param {number} tcp - TCP to use for the exchange
   */
  function changeCurrency(tcp) {
    const items = [...games];

    const itemsUpdated = items.map((item) => {
      if (currencyName === item.currencyCode) {
        return {
          ...item,
          sellPrice: truncateDecimals(item.beforeExchange.sellPrice, 4),
          price: truncateDecimals(item.beforeExchange.price, 4),
          ivaPrice: truncateDecimals(item.beforeExchange.ivaPrice, 4),
          subTotal: truncateDecimals(item.beforeExchange.subTotal, 4),
          puVenta: truncateDecimals(item.beforeExchange.puVenta, 4),
          totalImport: truncateDecimals(
            item.beforeExchange.subTotal + item.beforeExchange.ivaPrice,
            4
          ),
        };
      } else {
        const sellPrice = truncateDecimals(
          convertPrice(
            item.beforeExchange.sellPrice,
            tcp,
            item.currencyCode,
            currencyName
          ),
          4
        );

        const price = truncateDecimals(
          convertPrice(
            item.beforeExchange.price,
            tcp,
            item.currencyCode,
            currencyName
          ),
          4
        );

        const ivaPrice = truncateDecimals(
          convertPrice(
            item.beforeExchange.ivaPrice,
            tcp,
            item.currencyCode,
            currencyName
          ),
          4
        );

        const subTotal = truncateDecimals(
          convertPrice(
            item.beforeExchange.subTotal,
            tcp,
            item.currencyCode,
            currencyName
          ),
          4
        );

        const puVenta = truncateDecimals(
          convertPrice(
            item.beforeExchange.puVenta,
            tcp,
            item.currencyCode,
            currencyName
          ),
          4
        );

        return {
          ...item,
          sellPrice,
          totalImport: truncateDecimals(subTotal + ivaPrice, 4),
          price,
          ivaPrice,
          subTotal,
          puVenta,
        };
      }
    });

    setGames(itemsUpdated);
  }

  /**
   * Delete a comment when the user clicks the icon
   *
   * @param {string|number} id - ID of the comment, can be number (db) or string (memory id)
   * @param {boolean} isNewComment - If false, the comment source was from db
   */
  const deleteComment = (id, isNewComment) => {
    const newListComments = comments.filter((comment) => comment.id !== id);
    setComments(newListComments);

    if (!isNewComment) {
      updateToDeleteComments(id);
    }
  };

  /**
   * Create an array of numbers with the id comment that will be deleted
   * after execute the update query
   * @param {number} id - ID of the comment that the db associates that comment
   */
  function updateToDeleteComments(id) {
    const copyToDelete = [...commentsToDelete];

    copyToDelete.push(id);

    deleteComments(copyToDelete);
  }

  function updateCatalogue(newItem) {
    let copyCatalogue = [...catalogue];

    copyCatalogue.unshift(newItem);

    setCatalogue(copyCatalogue);
  }

  function createGame(value) {
    let discount = truncateDecimals(getDiscount(), 4);

    const codeCurrency = document.getElementById(
      `currency${value.currency}`
    ).textContent;

    let priceNewGame = truncateDecimals(parseToFloat(price.value), 4);
    let uenDesNewGame = document.getElementById(`uen${uen.value}`);
    let idNewGame = uuidv4();
    let sellPrice = truncateDecimals(parseToFloat(unitCost.value), 4);

    let { newSellPrice, newImport } = calculatePrices();

    let { ivaPrice, subTotal, puVenta } = calculateInfoPrices();

    let newGame = {
      beforeExchange: {
        sellPrice,
        price: priceNewGame,
        ivaPrice,
        subTotal,
        puVenta,
        totalImport: truncateDecimals(subTotal + ivaPrice, 4),
      },
    };

    newGame = {
      ...newGame,
      id: idNewGame,
      code: code.value,
      description: description.value,
      unitPrice: priceNewGame,
      satCode: satCode.value,
      iva: truncateDecimals(parseFloat(iva.value), 4),
      uen: +uen.value,
      sellPrice,
      satUm: satUm.value,
      idUen: +uen.value,
      uenDescription: uenDesNewGame.textContent,
      label: description.value,
      value: idNewGame,
      quantity: +quantity.value,
      price: priceNewGame,
      discount: discount,
      totalImport: newImport,
      idFront: idNewGame,
      mustBeAdded: true,
      subTotal,
      ivaPrice,
      puVenta,
      isNewItem: true,
      currency: value.currency,
      currencyID: value.currency,
      currencyCode: codeCurrency,
      currencyDocument: currencyType,
    };

    if (value.currency !== currencyType) {
      newGame = {
        ...newGame,
        sellPrice: truncateDecimals(
          convertPrice(sellPrice, exchangeTcp, codeCurrency, currencyName),
          4
        ),
        price: truncateDecimals(
          convertPrice(priceNewGame, exchangeTcp, codeCurrency, currencyName),
          4
        ),
        ivaPrice: truncateDecimals(
          convertPrice(ivaPrice, exchangeTcp, codeCurrency, currencyName),
          4
        ),
        subTotal: truncateDecimals(
          convertPrice(subTotal, exchangeTcp, codeCurrency, currencyName),
          4
        ),
        puVenta: truncateDecimals(
          convertPrice(puVenta, exchangeTcp, codeCurrency, currencyName),
          4
        ),
        totalImport: truncateDecimals(
          convertPrice(newImport, exchangeTcp, codeCurrency, currencyName),
          4
        ),
      };
    }

    updateActualGames(newGame);
    updateCatalogue(newGame);

    return newGame;
  }

  /**
   * When the user exchanges the TCP, recalculate in order
   * to get the new prices in that currency
   */
  function updatePriceGames() {
    const copyGames = [...games];

    copyGames.map((game) => {
      game.price = game.price / tcp.test;
      game.totalImport = game.totalImport / tcp.test;
    });
  }

  /**
   * Calculate the total import according of the product items of the document
   *
   * @param {object[]} productItems - Items that are gonna be saved for the document
   * @returns {number} Total import (sum) of the product items
   */
  function calculateTotalImport(productItems) {
    const total = productItems.reduce(
      (actualImport, { totalImport }) => actualImport + totalImport,
      0
    );

    return +Number(total).toFixed(2);
  }

  function parseGames() {
    // Order the games
    const orderedGames = addOrder();

    // Split the games
    const filteredGames = filterGames(orderedGames);

    // In case the user tried to use the added game on memory
    const gamesFiltered = filterMemoryGames(filteredGames.games);

    // In case the user it's editing a document
    const { toUpdateItems, toAddOnEdit } = itemsToUpdate(orderedGames);

    return {
      gamesToCreate: filteredGames.gamesToCreate,
      games: gamesFiltered.games,
      memoryGames: gamesFiltered.memoryGames,
      toUpdateItems,
      toAddOnEdit,
    };

    //////////////////////////////////////////////

    /**
     * Filter which item documents must be updated or added in case the user
     * its performing the action of edit document
     * @param {object[]} orderedGames - Item documents ordered
     * @returns {object} Document items that must be updated on the
     */
    function itemsToUpdate(orderedGames) {
      let toUpdate = [],
        toCreateOnEdit = [];

      orderedGames.forEach((item) => {
        if (item.isNewItem) toCreateOnEdit.push(item);
        else toUpdate.push(item);
      });

      return {
        toUpdateItems: toUpdate,
        toAddOnEdit: toCreateOnEdit,
      };
    }

    /**
     * Split into two arrays, one with the games that already are on the catalogue
     * and other that are not, yet
     * @param {object[]} orderedGames - Games to add ORDERED
     * @returns {object} Games that must be created and can be added without problem
     */
    function filterGames(orderedGames) {
      let games = [],
        gamesToCreate = [];

      orderedGames.map((game) =>
        game.mustBeAdded === true ? gamesToCreate.push(game) : games.push(game)
      );

      gamesToCreate = gamesToCreate.map((item) => {
        return {
          ...item,
          currencyDocument: currencyType,
        };
      });

      return {
        games,
        gamesToCreate,
      };
    }

    /**
     * Give the games an order to display on UI
     * @returns {object[]}
     */
    function addOrder() {
      // Copy of state
      let copy = [...games];

      // Append the order
      copy = copy.map((game, i) => {
        return (game = {
          ...game,
          order: i + 1,
        });
      });

      return copy;
    }

    function filterMemoryGames(orderedGames) {
      let games = [],
        memoryGames = [];

      // Value type string, its a game that must be referenced before save
      // If not, the game can be saved normally
      orderedGames.forEach((game) => {
        typeof game.value !== "number"
          ? memoryGames.push(game)
          : games.push(game);
      });

      return {
        games,
        memoryGames,
      };
    }
  }

  /**
   * Set the inputs when the user edits an item
   * @param {object} item - Info of the item product
   */
  function setInputsEdit(item) {
    price.value = item.price;

    discount?.setAttribute("value", `${item.discount}`);
    quantity?.setAttribute("value", item.quantity);
  }

  function parseComments() {
    const parsedComments = comments.map((comment, i) => {
      return (comment = {
        ...comment,
        order: i + 1,
      });
    });

    return parsedComments;
  }

  function discriminateNewComments(commentsEvaluate) {
    let commentsToAdd = [];
    let existingComments = [];

    commentsEvaluate.forEach((comment) => {
      if (typeof comment.id !== "number") {
        commentsToAdd.push(comment);
      } else {
        existingComments.push(comment);
      }
    });

    return {
      commentsToAdd,
      existingComments,
    };
  }

  /**
   * Check which items from the shop cart must be added before do an insertion
   * on sql server
   */
  function filterItemsToCreate() {
    const copyItems = [...games];

    const itemsToCreate = copyItems.filter((item) => item.isNewItem === true);

    return itemsToCreate;
  }

  const [docInfo, setDocInfo] = useState(null);

  const [prices, setPrices] = useState({
    iva: "NA",
    import: "NA",
    total: "NA",
  });

  const ResetState = () =>
    useEffect(() => {
      setCurrency({
        id: 1,
        label: "MXN",
      });
    }, []);

  const UpdatePricesDoc = () =>
    useEffect(() => {
      if (games.length === 0) {
        setPrices({
          iva: "NA",
          import: "NA",
          total: "NA",
        });
        return;
      }

      let importPrice = 0;
      let ivaPrice = 0;

      games.forEach((docItem) => {
        const { total } = calculateDiscount(docItem.price, docItem.discount);

        importPrice += total * docItem.quantity;
        ivaPrice += calculateIvaPrice(docItem.quantity, total, docItem.iva);
      });

      const total = importPrice + ivaPrice;

      setPrices({
        iva: mnCurrency(ivaPrice),
        import: mnCurrency(importPrice),
        total: mnCurrency(total),
      });
    }, [games]);

  const LoadDocument = () =>
    useEffect(() => {
      async function initialLoad() {
        const apiDocInfo = await GetDocumentInfo(+documentId);
        const v2DocInfo = await GetDocument(+documentId);

        const parseExp = trimDate(
          apiDocInfo["data"]["documentInfo"]["expirationDate"],
          "ymd",
          "-"
        );
        const parseRem = trimDate(
          apiDocInfo["data"]["documentInfo"]["reminderDate"],
          "ymd",
          "-"
        );

        const parsedDocInfo = {
          ...apiDocInfo["data"]["documentInfo"],
          parsedExpDate: new Date(
            parseExp.year,
            parseExp.month - 1,
            parseExp.day
          ),
          parsedRemDate: new Date(
            parseRem.year,
            parseRem.month - 1,
            parseRem.day
          ),
          document: v2DocInfo,
        };

        setCurrencyUsed("");

        setDocInfo(parsedDocInfo);
      }

      initialLoad();
    }, []);

  /**
   * Check if the document created/updated contains mixed currencys on the document items
   * @param {DocumentItem[]} items - Items to check
   * @returns {boolean} True if document contains mixed currencys
   */
  function checkMixedCurrencys(items) {
    const currencys = new Set();

    /**
     * Make a union of the currency item
     * @param {DocumentItem} item - Item to make a union
     */
    const unionItem = (item) => currencys.add(item.currencyCode);

    items.forEach(unionItem);

    console.log(currencys);

    if (currencys.size > 1) {
      console.log("Monedas mixtas");
      return true;
    } else {
      console.log("Monedas en un formato");
      return false;
    }
  }

  /**
   * Evaluate what prices changed in order to question
   * the maintenance
   *
   * @param {string} cost - Unit cost from the modal input
   * @param {string} price - Unit price from the modal input
   * @param {InfoToUpdate} info - Information of the item to update
   * @returns {DiscriminatedPrices|null} Options that the executive can trigger
   */
  function discriminatePricesChanged(cost, price, info, itemsParsed) {
    // Item was just created, no need to update somewhere
    if (info.id === undefined) return null;

    let isInMemory = true;

    if (typeof info.id === "number") {
      isInMemory = false;
    }

    let options = {};

    const costChanged = unitCostChanged(cost, itemsParsed);

    // Append the option in order to update unit cost
    if (costChanged) {
      options = {
        ...options,
        2: "Costo unitario",
      };
    }

    const priceChanged = unitPriceChanged(price, itemsParsed);

    // Append the option in order to update the price
    if (priceChanged) {
      options = {
        ...options,
        1: "Precio unitario",
      };
    }

    // Append the option in order to update both of them
    if (Object.keys(options).length >= 2) {
      options = {
        ...options,
        3: "Precio y costo unitario",
      };
    }

    // Any price was updated, prices keep the same
    if (Object.keys(options).length <= 0) {
      return null;
    }

    return {
      options,
      isInMemory,
    };
  }

  const promptUpdatePrices = async (
    options = {
      1: "Precio unitario",
      2: "Costo unitario",
      3: "Precio/Costo unitario",
    },
    info,
    isInMemory
  ) => {
    const cb = {
      1: () => {
        updatePrice(info, isInMemory);
      },
      2: () => {
        updateCost(info, isInMemory);
      },
      3: () => {
        // Update catalogue on database and client-side
        updateCost(info, isInMemory);
        updatePrice(info, isInMemory);

        // Update the price OF THE CARD
        updatePricesDocItems(info);

        console.log("me ejecute");

        Success(() => {}, "Catalogo actualizado");
      },
    };

    // const { value:query } = await sa2.fire({
    //     icon:'question',
    //     input:'select',
    //     title:'¿ Quieres actualizar en el catalogo ?',
    //     inputOptions:options,
    //     denyButtonText:'Cancelar',
    //     confirmButtonText:'Actualizar',
    //     showDenyButton:true
    // });

    YesNoAlert(
      "¿ Deseas actualizar en el catalogo ?",
      () => cb[3](),
      () => {}
    );

    // if(query){
    //     cb[query]()
    // }
  };

  return {
    setInputsGame,
    addFromCatalogue,
    cancelAddProduct,
    setInputsAdd,
    setTipoCambioProtegido,
    setInputsNewProduct,
    findInfoUen,
    setIva,
    quitIva,
    setIvasNewGame,
    createGame,
    deleteGame,
    updatePriceGames,
    parseGames,
    calculateTotalImport,
    resetModal,
    updateItem,
    setInputsEdit,
    parseComments,
    confirmDeleteItem,
    deleteComment,
    confirmDeleteComment,
    discriminateNewComments,
    unitCostChanged,
    promptUpdateCost,
    LoadDocument,
    docInfo,
    UpdatePricesDoc,
    prices,
    promptUpdatePrice,
    unitPriceChanged,
    discriminatePricesChanged,
    promptUpdatePrices,
    ResetState,
    updateLabelsPrices,
    changeCurrency,
    isValidCost,
    hideError,
    checkMixedCurrencys,
  };
}
