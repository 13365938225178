import { toast } from "react-toastify";

export function clickDomItem(idDom) {
  const domElement = document.getElementById(idDom);

  if (domElement) {
    domElement.click();
  }
}

/**
 * Copy on the clipboard of the user
 * @param {string} data - Copy any text on the clipboard of the user
 * @param {boolean?} notify - If true, a small notification will be displayed
 * @param {string?} message - Message to display in case to show alert
 */
export function copyOnClipboard(
  data,
  notify = false,
  message = "Texto copiado al portapapeles"
) {
  navigator.clipboard.writeText(data).then((e) =>
    notify
      ? toast(message, {
          autoClose: 5000,
          position: "bottom-right",
          theme: "colored",
          hideProgressBar: false,
        })
      : null
  );
}

export function openOnNewTab(url) {
  window.open(url, "_blank");
  // alert('En desarrollo')
}

/**
 * Open a file into a new tab, like a pdf, image, etc.
 * @param {Blob} file
 */
export function openFileOnNewTab(file) {
  const url = URL.createObjectURL(file);
  openOnNewTab(url);
}

/**
 * Get the clicked column of the row on react-data-table
 * @param {import("react").BaseSyntheticEvent<MouseEvent, EventTarget & Element, EventTarget>.target: EventTarget} targetElement
 * @returns {number}
 */
export function getClickedColumn(targetElement) {
  if (!targetElement.id) {
    // If the target element doesn't have an ID, get its parent two levels above
    const dom = targetElement.parentElement;

    return +dom.getAttribute("data-column-id");
  } else {
    // If the target element has an ID, get its parent one level above
    const dom = targetElement;
    return +dom.getAttribute("data-column-id");
  }
}

export function getColorScheme() {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  )
    return "dark";

  return "light";
}

/**
 * Open a json object in a new tab
 * @param {object} jsonData - Json data
 */
export function openJsonInNewTab(jsonData) {
  const formattedJson = JSON.stringify(jsonData, null, 2); // 2 is the number of spaces for indentation

  // Create HTML content dynamically
  const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Formatted JSON</title>
      <!-- Include Prism.js CSS for dark mode -->
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/themes/prism-okaidia.min.css">
      <style>
          /* Add custom styles for better visualization */
          body {
              font-family: Arial, sans-serif;
              background-color: #2d2d2d; /* Set background color for dark mode */
              color: #ffffff; /* Set text color for dark mode */
              padding: 20px;
          }
          pre {
              padding: 10px;
              background-color: #3a3a3a; /* Set background color for code blocks */
              border-radius: 5px;
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
              overflow-x: auto;
          }
      </style>
  </head>
  <body>
      <pre><code class="language-json">${formattedJson}</code></pre>
      <!-- Include Prism.js library -->
      <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/prism.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/components/prism-json.min.js"></script>
  </body>
  </html>
  `;

  // Convert the HTML content to a Blob
  const blob = new Blob([htmlContent], { type: "text/html" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Open the URL in a new tab
  const newTab = window.open(url, "_blank");

  // Release the URL object to free up resources
  URL.revokeObjectURL(url);

  // Focus on the new tab
  if (newTab) {
    newTab.focus();
  }
}

/**
 * Parse an object to string
 * @param {object} infoObject - Json to parse
 * @returns {string}
 */
export function toJsonString(infoObject) {
  try {
    return JSON.stringify(JSON.parse(infoObject), null, 2);
  } catch (error) {
    return "{}";
  }
}

/**
 * Object text to json
 * @param {string} jsonText - Json text to convert
 * @returns {object}
 */
export function toJson(jsonText){
  try {
    return JSON.parse(jsonText);
  } catch (error) {
    return {}
  }
}