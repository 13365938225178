import useDocument from "components/Sections/Documents/Hooks/useDocument";
import usePartilities from "customHooks/usePartialities";
import { base64ToFileV2, fileToBase64, xmlFileToJson } from "helpers/files";
import {
  calculateDiscountToApply,
  getTaxas,
  truncateDecimals,
} from "helpers/money";
import { useEffect, useRef, useState } from "react";
import { createInvoice } from "helpers/Apis/invoice";

/**
 * @type {File[]}
 */
const initialFiles = [];

export default function useHandleFeException() {
  const allowedExtensions = useRef(["xml", "pdf"]);

  const [files, setFiles] = useState(initialFiles);

  const [state, setState] = useState({
    isLoading: false,
    partialities: 1,
    tc: null,
    currency: null,
    total: 0,
    customer: null,
    creditDays: 1,
    cxc: undefined,
    idPedido: "",
    requireCurrencyExchange: false,
    dto: undefined,
    jsonPac: "",
  });

  const order = useDocument(state.customer, 2, "Editar", +state.idPedido);

  const { createGrid: gridImpuestos } = usePartilities(
    state.partialities,
    0,
    state.total,
    1
  );

  useEffect(() => {
    (async function () {
      const xml = files.find((file) => file.type === "text/xml");
      if (!xml) return;

      const cfdi = await xmlFileToJson(xml);

      const tcp = isNaN(+cfdi.$.TipoCambio)
        ? state.tc === null
          ? state.tc
          : state.tc
        : +cfdi.$.TipoCambio;
      const total = +cfdi.$.Total;
      const currency = cfdi.$.Moneda;

      const currencyToUse = currency.toLocaleLowerCase();

      setState({ ...state, tc: tcp, total, currency });

      const parsedItems = order.document.items.map((partida, i) => ({
        id: partida.id,
        description: partida.description,
        satUmDescription: partida.satUmDescription,
        satCodeDescription: partida.satCodeDescription,
        pu: {
          number: partida.pu.number,
          text: partida.pu.text,
          discount: truncateDecimals(
            (partida.pu.number * partida.clientDiscoount.number) / 100,
            2
          ),
        },
        cu: {
          number: partida.cu.number,
          text: partida.cu.text,
          discount: truncateDecimals(
            (partida.cu.number * partida.providerDiscount.number) / 100,
            2
          ),
        },
        satCode: partida.satCode,
        satUm: partida.satUm,
        iva: {
          number: partida.iva.number,
          text: partida.iva.text,
          exempt: partida.iva.exempt,
        },
        sku: partida.sku,
        uen: {
          id: partida.uen.id,
          description: partida.uen.description,
          family: partida.uen.family,
          marginRate: partida.uen.marginRate,
        },
        catalogue: {
          id: partida.catalogue.id,
          description: partida.catalogue.description,
        },
        currency: partida.currency,
        quantity: {
          isValid: true,
          quantity: partida.quantity,
        },
        discount: partida.clientDiscoount.number,
        calculations: {
          price: {
            unitary: partida[`${currencyToUse}`].pu.totalUnit.number,
            sell: partida[`${currencyToUse}`].pu.realUnit.number,
            iva: partida[`${currencyToUse}`].pu.totalIva.number,
            import: partida[`${currencyToUse}`].pu.total.number,
            subtotal: truncateDecimals(
              partida[`${currencyToUse}`].pu.total.number +
                partida[`${currencyToUse}`].pu.totalIva.number,
              2
            ),
            discount:
              calculateDiscountToApply(
                partida[`${currencyToUse}`].pu.totalUnit.number,
                partida.clientDiscoount.number
              ) * partida.quantity,
          },
          cost: {
            unitary: partida[`${currencyToUse}`].cu.totalUnit.number,
            sell: partida[`${currencyToUse}`].cu.realUnit.number,
            iva: partida[`${currencyToUse}`].cu.totalIva.number,
            import: partida[`${currencyToUse}`].cu.total.number,
            subtotal: truncateDecimals(
              partida[`${currencyToUse}`].cu.total.number +
                partida[`${currencyToUse}`].cu.totalIva.number,
              2
            ),
            discount:
              calculateDiscountToApply(
                partida[`${currencyToUse}`].cu.totalUnit.number,
                partida.providerDiscount.number
              ) * partida.quantity,
          },
        },
        utility: 0,
        uuid: partida.uuid,
        label: partida.catalogue.description,
        value: partida.catalogue.id,
        isNewItem: false,
        order: i + 1,
      }));

      const taxas = getTaxas(parsedItems);

      const impuestos16 = gridImpuestos(
        taxas.iva16.iva.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );
      const impuestos8 = gridImpuestos(
        taxas.iva8.iva.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );

      const impuestos0 = gridImpuestos(
        taxas.iva0.iva.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );

      const importe16 = gridImpuestos(
        taxas.iva16.importe.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );

      const importe8 = gridImpuestos(
        taxas.iva8.importe.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );

      const importe0 = gridImpuestos(
        taxas.iva0.importe.sell,
        state.partialities,
        1,
        state.tc,
        new Date()
      );

      /**
       * @type {import("types/typedef/customHooks/usePartialities").PartialitieV2I[]}
       */
      const parsedCxc = state.cxc.partialitiesInfo.map((cxc, i) => ({
        ...cxc,
        importe: {
          porcentaje16: importe16[i].price,
          porcentaje8: importe8[i].price,
          porcentaje0: importe0[i].price,
          cxc: +(
            importe16[i].price +
            importe8[i].price +
            importe0[i].price
          ).toFixed(2),
        },
        ivas: {
          iva16: impuestos16[i].price,
          iva8: impuestos8[i].price,
          iva0: impuestos0[i].price,
          cxc: +(
            impuestos16[i].price +
            impuestos8[i].price +
            impuestos0[i].price
          ).toFixed(2),
        },
        distribucion: {
          importe16: +(
            (100 * (importe16[i].price + impuestos16[i].price)) /
            cxc.price
          ).toFixed(2),
          importe8: +(
            (100 * (importe8[i].price + impuestos8[i].price)) /
            cxc.price
          ).toFixed(2),
          importe0: +(
            (100 * (importe0[i].price + impuestos0[i].price)) /
            cxc.price
          ).toFixed(2),
        },
        taxCode: {
          code16: "002",
          code0: "002",
          code8: "002",
        },
        description: {
          description0: "IVA",
          description16: "IVA",
          description8: "IVA",
        },
        isRetention: {
          isRetention0: false,
          isRetention16: false,
          isRetention8: false,
        },
        region: {
          region0: "Federal",
          region16: "Federal",
          region8: "Federal",
        },
        factorType: {
          factorType0: "Tasa",
          factorType16: "Tasa",
          factorType8: "Tasa",
        },
      }));

      const dto = {
        amounts: {
          import: +cfdi.$.SubTotal,
          iva: +(+cfdi.$.Total - +cfdi.$.SubTotal).toFixed(2),
          total: +cfdi.$.Total,
        },

        cfdi: cfdi["cfdi:Receptor"].$.UsoCFDI,

        // Recalcular
        cxc: {
          isValid: true,
          partialitiesInfo: parsedCxc,
        },
        // Usar un combo para indicar el pedido
        idOrder: state.idPedido,

        impuestos: taxas,

        invoice: {
          cfdi: cfdi["cfdi:Receptor"].$.UsoCFDI,
          payForm: cfdi.$.MetodoPago === "PUE" ? 2 : 1,
        },

        items: parsedItems,

        partialities: state.partialities,

        payForm: cfdi.$.MetodoPago,

        payMethod: cfdi.$.FormaPago,

        // Preguntar a quien se le facturó
        receiver: {
          id: state.customer,
          Rfc: cfdi["cfdi:Receptor"].$.Rfc,
          Name: cfdi["cfdi:Receptor"].$.Nombre,
          Cfdi: cfdi["cfdi:Receptor"].$.UsoCFDI,
          FiscalRegime: cfdi["cfdi:Receptor"].$.RegimenFiscalReceptor,
          TaxZipCode: cfdi["cfdi:Receptor"].$.DomicilioFiscalReceptor,
          value: state.customer,
          label: `${cfdi["cfdi:Receptor"].$.Rfc} - ${cfdi["cfdi:Receptor"].$.Nombre}`,
        },

        // Se puede consultar del log
        requireCurrencyExchange: state.requireCurrencyExchange,

        tcp,
      };

      setState((current) => ({
        ...current,
        dto,
      }));
    })();
  }, [
    files,
    state.cxc,
    state.idPedido,
    state.customer,
    state.requireCurrencyExchange,
  ]);

  /**
   * Id of the document
   * @param {string} value
   */
  const setIdPedido = (value) => {
    const id = value.split(".")[0];
    setState((current) => ({
      ...current,
      idPedido: id,
    }));
  };

  const setRequireCurrencyExchange = (requireCurrencyExchange) =>
    setState((current) => ({ ...current, requireCurrencyExchange }));

  const setTcp = (tcp) => setState((current) => ({ ...current, tc: tcp }));

  const handleCorrection = async () => {
    setState((current) => ({
      ...current,
      isLoading: true,
    }));

    const pdf = await fileToBase64(
      files.find((file) => file.type === "application/pdf")
    );
    const xml = await fileToBase64(
      files.find((file) => file.type === "text/xml")
    );

    const dtoForCorrection = {
      ...state.dto,
      correction: {
        pdf:pdf.split("base64,")[1],
        xml:xml.split("base64,")[1],
        pac: JSON.parse(state.jsonPac),
      },
    };

    const wasCreated = await createInvoice(dtoForCorrection);

    setState((current) => ({
      ...current,
      isLoading: false,
    }));

    if (wasCreated) window.location.reload();
  };

  const setJsonPac = (jsonPac) =>
    setState((current) => ({
      ...current,
      jsonPac,
    }));

  return {
    setJsonPac,
    allowedExtensions,
    files,
    setFiles,
    setState,
    handleCorrection,
    state,
    setIdPedido,
    setTcp,
    setRequireCurrencyExchange,
  };
}
