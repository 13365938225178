import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { createContext, useContext, useEffect, useState } from "react";
import DocumentContext from "../ContextDocument";
import Icon from "components/individual/HeaderIcon";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import Save from "./Save";
import MotiveEdition from "./MotiveEdition";
import ui from "./styles.module.scss";
import ConceptCombo from "./ConceptCombo";
import AttemptSave from "./AttemptSave";

const LABEL_BTN = {
  Ver: "Regresar",
  Editar: "Actualizar",
};

/**
 * @type {import("./types").StateConcept}
 */
const INITIAL_STATE = {
  loading: false,
  concept: undefined,
  isOpen: false,
  editionMotive: "",
};

/**
 * @type {import("react").Context<import("./types").ContextHandleModal>}
 */
export const ContextModalConcept = createContext(undefined);

export const ConceptsSection = () => {
  const hookDoc = useContext(DocumentContext);

  const [state, setState] = useState(INITIAL_STATE);

  const handleOpen = () =>
    setState((current) => ({ ...current, isOpen: true }));
  const handleClose = () =>
    setState((current) => ({ ...current, isOpen: false }));

  const setLoading = (loading) =>
    setState((current) => ({ ...current, loading }));

  const setConcept = (concept) => {
    hookDoc.handleOnConceptChange(concept);
    setState((current) => ({ ...current, concept }));
  };

  const setMotive = (editionMotive) =>
    setState((current) => ({
      ...current,
      editionMotive,
    }));

  useEffect(() => {
    if (state.isOpen) {
      dialog.open();
    } else {
      dialog.close();
      setState(INITIAL_STATE);
    }
  }, [state.isOpen]);

  const dialog = useDialog();

  if (hookDoc.actionType === "Ver")
    return (
      <ButtonV2 onClick={() => hookDoc.backToOrigin(hookDoc.documentType)}>
        <span>Regresar</span>
        <Icon icon="back" />
      </ButtonV2>
    );

  return (
    <ContextModalConcept.Provider
      value={{
        ...state,
        handleClose,
        handleOpen,
        setConcept,
        setLoading,
        setMotive,
      }}
    >
      <div className={ui.action}>
        {hookDoc.items.length <= 0 ||
        hookDoc.customer.id <= 0 ? null : hookDoc.actionType === "Editar" ? (
          <AttemptSave />
        ) : (
          <ButtonV2 type="button" onClick={handleOpen} disabled={hookDoc.moneyInfo.tc.number <= 0}>
            <span>{LABEL_BTN[hookDoc.actionType] || "Agregar"}</span>
          </ButtonV2>
        )}

        {hookDoc.items.length <= 0 ? (
          <p className="font-weight-bold text-danger">🔴 Almenos una partida</p>
        ) : null}
        {hookDoc.customer.id <= 0 ? (
          <p className="font-weight-bold text-danger">
            🔴 Razón social requerida
          </p>
        ) : null}
      </div>

      {state.isOpen ? (
        <Dialog
          forwardRef={dialog.refDialog}
          title="Concepto para el documento"
          width={40}
          footer={<Save />}
          closeCallback={handleClose}
        >
          <div className={ui.fields}>
            <ConceptCombo />
            <MotiveEdition />
          </div>
        </Dialog>
      ) : null}
    </ContextModalConcept.Provider>
  );
};
