import React from "react";
import {
  PillEmail,
  ContainerPill,
} from "components/individual/SendEmail/styles";

/**
 * Display a informative list of email who will receive the message, also, allows
 * delete the email from the list
 * @param {object} props - Props
 * @param {string} props.list - Email of list to render
 * @param {(indexEmail:number)=>void} props.onClickDelete - Get the index position of the element clicked
 * @returns {JSX.Element} React component
 */
export default function EmailList({ list = [], onClickDelete = () => {} }) {
  return (
    <ContainerPill>
      {list.map((email, i) => (
        <PillEmail key={window.crypto.randomUUID()}>
          {email}
          <button onClick={(e) => onClickDelete(i)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </PillEmail>
      ))}
    </ContainerPill>
  );
}
