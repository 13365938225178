import React from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { openFileOnNewTab } from "helpers/dom";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function PdfPreview({ pdf = null, ...args }) {
  if (pdf instanceof Blob || pdf instanceof File)
    return (
      <ButtonV2 onClick={() => openFileOnNewTab(pdf)}>
        <span>Ver pdf</span>
        <Icon icon="pdf" />
      </ButtonV2>
    );

  return <></>;
}
