import { Error, Success, Warning, YesNoAlert } from "../helpers/alerts";

import LoginContext from "../context/Login/LoginContext";
import {
  addCustomer as PostCustomer,
  updateCustomer as PutCustomer,
} from "../helpers/Apis/Directory";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AssociateCustomerWithMizar,
  AssociateMizarWithSystem,
} from "../helpers/Apis/mizar";
import {
  AddCustomerFacturama,
  EditCustomerFacturama,
  parseDirectoryToFacturama,
} from "../helpers/facturama/customers";
const validateRfc = require("validate-rfc");

export default function useAddCustomer(documentInfo) {
  const { userInfo } = useContext(LoginContext);

  const history = useHistory();

  const [isSaving, setIsSaving] = useState(false);

  /**
   * Generic rfc to use if executive dont' give a one
   * @param {string} country - Country selected by the user
   * @returns {string}
   */
  function rfcToUse(country) {
    if (country === "Mexico") {
      return "XAXX010101000";
    }

    return "XEXX010101000";
  }

  async function updateCustomer(formData) {
    if (!validateMovil()) return;
    if (!validatePhone()) return;

    setIsSaving(true);

    const { isValid } = validateRfc(documentInfo.rfc, {
      omitVerificationDigit: true,
    });

    let customerRFC =
      isValid === true ? documentInfo.rfc : rfcToUse(documentInfo.adress.country);

    formData = {
      ...formData,
      PK_Executive_Associated: documentInfo.attendedBy.id,
      createdBy: userInfo[0].fullName,
      idFacturama: documentInfo["idMizar"],
      customerRFC,
      customerID: documentInfo.id,
      value_statusCustomer: documentInfo.status.isActive === true ? 1 : 0,
      idCorporative: documentInfo.corporative.id,
      lada_movil: formData.number_movil === null ? null : formData.lada_movil,
      lada_phone: formData.number_phone === null ? null : formData.lada_phone,
      customerCountry:documentInfo.adress.country,
      generateCxp:documentInfo?.generateCxp||false
    };

    if (formData.mnBank !== null) {
      const validMn = isValidBank(
        formData.mnBank,
        formData.mnNoAgreement,
        formData.mnNumberAccount,
        "MXN - Ingresa número de cuenta o número de convenio"
      );
      if (!validMn) {
        setIsSaving(false);
        return;
      }
    }

    if (formData.usdBank !== null) {
      const validUsd = isValidBank(
        formData.usdBank,
        formData.usdNoAgreement,
        formData.usdNumberAccount,
        "USD - Ingresa número de cuenta o número de convenio"
      );
      setIsSaving(false);
      if (!validUsd) {
        setIsSaving(false);
        return;
      }
    }

    setIsSaving(true);

    if (!isValid) {
      YesNoAlert(
        `¿Deseas utilizar el RFC generico? (${rfcToUse(
          formData.customerCountry
        )})`,
        async () => {
          await UpdateCustomer();
          setIsSaving(false);
        },
        () => setIsSaving(false)
      );
    } else {
      (async function () {
        await UpdateCustomer();
        setIsSaving(false);
      })();
    }

    async function UpdateCustomer() {
      try {
        const { status, idCustomer, message } = await PutCustomer({
          ...formData,
          fiscalRegime: documentInfo.fiscalRegime,
          typeOfCustomer:documentInfo.typeOfCustomer

        });

        if (status === 200) {
          const customerInfo = parseDirectoryToFacturama(formData);

          let wasSync, resFacturama;

          if (formData.idFacturama === null) {
            // resFacturama = await AddCustomerFacturama(customerInfo);
            // wasSync = await AssociateMizarWithSystem(
            //   resFacturama.Id,
            //   idCustomer
            // );
          } else {
            resFacturama = {
              ...resFacturama,
              wasAdded: true,
            };

            // wasSync = await EditCustomerFacturama(
            //   customerInfo,
            //   formData.idFacturama
            // );
          }

          Success(() => history.push("/directorio"), "Actualizado");

          // if (wasSync && resFacturama.wasAdded === true) {
          //   Success(
          //     () => history.push("/directorio"),
          //     "Actualizado y sincronizado para facturar"
          //   );

          //   setIsSaving(false);
          // } else {
          //   Warning("Actualizado", () => history.push("/directorio"));
          // }
        } else {
          setIsSaving(false);
        }
      } catch (error) {
        console.log(error);

        setIsSaving(false);
        Error(() => {}, JSON.stringify(error));
      }
    }

    async function saveCustomer() {
      try {
        const { status, idCustomer, message } = await PutCustomer(formData);

        if (status === 200) {
          const wasSync = await AssociateCustomerWithMizar(
            formData,
            idCustomer
          );

          if (wasSync) {
            setIsSaving(false);
            Success(
              () => history.push("/directorio"),
              "Actualizado y sincronizado con Mizar"
            );
          }
        } else {
          Error(
            () => history.push("/directorio"),
            'Error al sincronizar con mizar. Intenta sincronizar en la pantalla de "Editar" mas tarde'
          );
          setIsSaving(false);
        }
      } catch (error) {
        setIsSaving(false);
        Error(() => history.push("/directorio"), error);
      }
    }
  }

  function addCustomer(formData) {
    if (!validateMovil()) return;
    if (!validatePhone()) return;

    setIsSaving(true);


    const { isValid } = validateRfc(documentInfo.customerRFC, {
      omitVerificationDigit: true,
    });


    let customerRFC =
      isValid === true ? documentInfo.customerRFC : rfcToUse(documentInfo.customerCountry);

    formData = {
      ...formData,
      ...documentInfo,
      createdBy: userInfo[0].fullName,
      customerRFC,
      iva: null,
      lada_movil: formData.number_movil === null ? null : formData.lada_movil,
      lada_phone: formData.number_phone === null ? null : formData.lada_phone,
      generateCxp:documentInfo?.generateCxp || false
    };

    if (formData.mnBank !== null) {
      const validMn = isValidBank(
        formData.mnBank,
        formData.mnNoAgreement,
        formData.mnNumberAccount,
        "MXN - Ingresa número de cuenta o número de convenio"
      );

      if (!validMn) {
        setIsSaving(false);
        return;
      }
    }

    if (formData.usdBank !== null) {
      const validUsd = isValidBank(
        formData.usdBank,
        formData.usdNoAgreement,
        formData.usdNumberAccount,
        "USD - Ingresa número de cuenta o número de convenio"
      );

      if (!validUsd) {
        setIsSaving(false);
        return;
      }
    }

    if (!isValid) {
      YesNoAlert(
        `¿Deseas utilizar el RFC generico? (${rfcToUse(
          formData.customerCountry
        )})`,
        async () => await saveCustomerV2(),
        () => setIsSaving(false)
      );
    } else {
      (async function () {
        await saveCustomerV2();
        setIsSaving(false);
      })();
    }

    async function saveCustomerV2() {
      setIsSaving(true);
      try {
        const { status, idCustomer, message } = await PostCustomer({
          ...formData,
          fiscalRegimen: documentInfo.fiscalRegime,
          typeOfCustomer:documentInfo.typeOfCustomer,
          manufacturers:documentInfo.manufacturers.map(manufacturer=>({
            idProvider:manufacturer.supplier.id,
            keyValue:manufacturer.key
          }))
        });

        if (status === 200) {
          const customerInfo = parseDirectoryToFacturama(formData);

          // const resFacturama = await AddCustomerFacturama(customerInfo);

          // const wasSync = await AssociateMizarWithSystem(
          //   resFacturama.Id,
          //   idCustomer
          // );

          Success(() => history.push("/directorio"), "Agregado");

          // if (wasSync && resFacturama.wasAdded === true) {
          //   Success(
          //     () => history.push("/directorio"),
          //     "Agregado y sincronizado para facturar"
          //   );
          // } else {
          //   Warning("Agregado", () => history.push("/directorio"));
          // }
        }
      } catch (error) {
        Error(() => {}, error.response.data.message);
      }
      setIsSaving(false);
    }
  }

  /**
   * Validate the bank information before save it
   *
   * @param {string} bankValue - Value of the bank (id or string)
   * @param {string} account - "Numero cuenta" for the bank account
   * @param {string} keycode - "Numero convenio" for the bank account
   * @param {string} message - Message error to display if not valid input
   * @returns {boolean}
   */
  function isValidBank(bankValue, account, keycode, message) {
    if (bankValue !== null && account === null && keycode === null) {
      Error(() => {}, message);
      return false;
    }
    return true;
  }

  /**
   * Validate the phone movil before save
   * @returns {boolean} True if the movil was provided correctly
   */
  function validateMovil() {
    const cellValid = +document.getElementById("isValid_movil").value;
    return cellValid === 1 ? true : false;
  }

  /**
   * Validate the phone number before save
   * @returns {boolean} True if the phone was provided correctly
   */
  function validatePhone() {
    const phoneValid = +document.getElementById("isValid_phone").value;
    return phoneValid === 1 ? true : false;
  }

  /**
   * Set the inputs if the cp location was clicked
   *
   * @param {CpInfo} cpInfo - Cp info
   */
  function setLocationInfo(cpInfo) {
    const colony = document.getElementById("customerColony");
    const city = document.getElementById("customerCity");
    const state = document.getElementById("customerState");

    colony.value = cpInfo.colonia;
    city.value = cpInfo.municipio;
    state.value = cpInfo.estado;
  }

  /**
   * Quit the options that not correspond to the user
   * @param {number|string} idTypeCustomer - Id type customer fetched
   */
  function quitOptions(idTypeCustomer) {
    const customerDom = document.getElementById("typeCustomerContainer-1");
    const providerDom = document.getElementById("typeCustomerContainer-2");

    if (idTypeCustomer === 1 && providerDom !== null) providerDom.remove();
    if (idTypeCustomer === 2 || (idTypeCustomer === 5 && customerDom !== null))
      customerDom.remove();
  }

  return {
    addCustomer,
    setLocationInfo,
    isSaving,
    quitOptions,
    updateCustomer,
    setIsSaving,
  };
}
