import { useContext, useEffect, useReducer } from "react";
import { initialState, reducer } from "./ContractReducer";
import { Actions } from "./actions";
import { getDefaultValuesDocument } from "helpers/Directory/Documents";
import { getDtoPeriocity } from "helpers/documents";
import { reorderComments } from "helpers/comments";
import { addContract, updateContract } from "helpers/Apis/contract";
import { Error, Success } from "helpers/alerts";
import { GetDocument } from "helpers/Apis/Documents";
import { useHistory } from "react-router-dom";
import { GetCustomer } from "helpers/Apis/Directory";
import { addOdc, updateOdc } from "helpers/Apis/odc";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";
import LoginContext from "context/Login/LoginContext";

/**
 * Render components and give them the props and methods withouts pass thorugh props
 * @param {object} props - Props
 * @param {number?} props.idContract - Id of the contract to fetch
 * @param {"ventas"|"documentos"} props.module - Indicates since which module came from in order to add a document
 * @param {number?} props.idCliente - Id of the customer which belongs the contract
 */
export default function useContract({
  idContract = null,
  idOdc = null,
  module = "na",
  idCliente = 0,
}) {
  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const redirectPathOdc =
    module === "administracion"
      ? "/administracion/ordenes-compra"
      : `/directorio/documentos/${idCliente}`;

  const redirectPath =
    module === "ventas"
      ? "/ventas/contratos"
      : `/directorio/documentos/${idCliente}`;

  /**
   * State of the component that handles the custom hook "useContract"
   * @type {[import("./types").InitialStateI,(object:import("./types").Actions)=>void]}
   */
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Information of the contract in order to add into system
   * @type {import("./types").DtoContract}
   */
  const dto = {
    document: {
      // startDateContract: dateToDbFormat(
      //   dateWithCeroHours(state.document.startDateContract)
      // ),
      generateCxp: state.document.generateCxp,
      id: idContract,
      code: state.document.code,
      contact: state.document.contact,
      createdBy: "dfsdfsdfsd",
      creditDays: 30,
      currency: state.document.currency,
      idCustomer: state.customer,
      importe: state.document.import,
      iva: state.document.iva,
      periocity: getDtoPeriocity(
        state.document.periocity.id,
        state.document.periocity.value,
        state.document.periocity.begin,
        state.document.periocity.end
      ),
      tcp: state.document.tc,
      total: state.document.total,
      expiration: state.document.expiration.db,
      reminder: state.document.reminder.db,
    },
    items: state.partidas.items.map((item, i) => ({
      ...item,
      order: i + 1,
    })),
    comments: reorderComments([
      ...state.comments?.regulars,
      ...state.comments?.notesAndConsiderations,
    ]),
  };

  /**
   * Indicates if the component is sending information to server
   * @param {boolean} value - Flag loading
   * @returns {void}
   */
  const setIsLoading = (value) =>
    dispatch({
      type: Actions.SET_IS_LOADING,
      payload: value,
    });

  useEffect(() => {
    (async function () {
      if (!idContract && !idOdc) {
        await loadDefaultValues();
      }

      if (idContract) {
        loadDocumentInfo();
        return;
      }

      if (idOdc) {
        loadOdcInfo();
        return;
      }
    })();

    async function loadDocumentInfo() {
      const apiContract = await GetDocument(idContract);

      dispatch({
        type: Actions.SET_EDITION_INFO,
        payload: apiContract,
      });
    }

    async function loadOdcInfo() {
      const apiOdc = await GetDocument(idOdc);

      dispatch({
        type: Actions.SET_ODC_EDITION_INFO,
        payload: apiOdc,
      });
    }

    async function loadDefaultValues() {
      const { tc, expiration, reminder } = await getDefaultValuesDocument();

      let customer = {
        customer: undefined,
        customerSocialReason: undefined,
      };

      if (module !== "ventas" && idCliente) {
        const apiCustomer = await GetCustomer(idCliente);
        customer = {
          customer: apiCustomer.id,
          customerSocialReason: apiCustomer.socialReason,
        };
      }

      dispatch({
        type: Actions.SET_INITIAL_STATE,
        payload: {
          tc,
          customer: customer.customer,
          customerSocialReason: customer.customerSocialReason,
          reminder,
          expiration,
          module,
        },
      });
    }
  }, []);

  const triggerAddContract = async () => {
    setIsLoading(true);

    const wasAdded = await addContract({
      ...dto,
      document: {
        ...dto.document,
        startDateContract: dateToDbFormat(
          dateWithCeroHours(state.document.startDateContract)
        ),
        createdBy: userInfo[0].fullName,
      },
    });

    if (wasAdded) {
      Success(() => {}, "Contrato agregado");
      history.push(redirectPath);
    } else {
      Error(() => {}, "No se pudo agregar el contrato");
    }

    setIsLoading(false);
  };

  const triggerUpdateContract = async () => {
    setIsLoading(true);

    const wasUpdated = await updateContract({
      ...dto,
      document: {
        ...dto.document,
        startDateContract: dateToDbFormat(
          dateWithCeroHours(state.document.startDateContract)
        ),
        createdBy: userInfo[0].fullName,
      },
    });

    if (wasUpdated) {
      Success(() => {}, "Contrato actualizado");
      history.push(redirectPath);
    } else {
      Error(() => {}, "No se pudo actualizar el contrato");
    }

    setIsLoading(false);
  };

  /**
   * Update the start date for the contract
   * @param {Date} date - Date to use for the start date on the contract
   * @returns {void}
   */
  const updateStartDate = (date) =>
    dispatch({
      type: Actions.SET_START_DATE_CONTRACT,
      payload: date,
    });

  const triggerAddOdc = async () => {
    setIsLoading(true);

    const wasAdded = await addOdc(dto);

    if (wasAdded) {
      Success(() => {}, "Orden de compra agregada");
      history.push(redirectPathOdc);
    } else {
      Error(() => {}, "No se pudo agregar la orden de compra");
    }

    setIsLoading(false);
  };

  const triggerUpdateOdc = async () => {
    setIsLoading(true);

    const wasUpdated = await updateOdc({
      ...dto,
      document: {
        ...dto.document,
        id: idOdc,
      },
    });

    if (wasUpdated) {
      Success(() => {}, "Orden de compra actualizada");
      history.push(`/administracion/ordenes-compra`);
    } else {
      Error(() => {}, "No se pudo actualizar la orden de compra");
    }

    setIsLoading(false);
  };

  /**
   * Update the currency to use for the contract
   * @param {import("./types").Currency} currency - Currency to use on the contract
   * @returns {void}
   */
  const updateCurrency = (currency) =>
    dispatch({
      type: Actions.SET_CURRENCY,
      payload: currency,
    });

  /**
   * Update the contact to use for the contract
   * @param {object} contact - Information of the contact
   * @returns {void}
   */
  const updateContact = (contact) =>
    dispatch({
      type: Actions.SET_CONTACT,
      payload: contact,
    });

  /**
   * Update the code for the contract
   * @param {string} code - Code to use
   * @returns {void}
   */
  const updateCode = (code) =>
    dispatch({
      type: Actions.SET_CODE,
      payload: code,
    });

  /**
   * Update the social reason to use on the contract
   * @param {import("./types").SocialReasonI} socialReason
   * @returns {void}
   */
  const updateSocialReason = (socialReason) =>
    dispatch({
      type: Actions.SET_SOCIAL_REASON,
      payload: socialReason,
    });

  /**
   * Update the items for the contract
   * @param {import("structure/Partidas/types").onChangeI} items - Information of the items
   * @returns {void}
   */
  const updateItems = (items) =>
    dispatch({
      type: Actions.SET_ITEMS,
      payload: items,
    });

  /**
   * Update the items for the contract
   * @param {import("structure/Partidas/types").onChangeI} items - Information of the items
   * @returns {void}
   */
  const updateItemsCosts = (items) =>
    dispatch({
      type: Actions.SET_COSTS_ODC,
      payload: items,
    });

  /**
   * Update the reminder date
   * @param {Date} js - Reminder date as date instance
   * @param {string} db - Reminder date as string
   * @returns {void}
   */
  const updateReminder = (js, db) =>
    dispatch({
      type: Actions.SET_REMINDER,
      payload: { js, db },
    });

  /**
   * Update the expiration date
   * @param {Date} js - Reminder date as date instance
   * @param {string} db - Reminder date as string
   * @returns {void}
   */
  const updateExpiration = (js, db) =>
    dispatch({
      type: Actions.SET_EXPIRATION,
      payload: { js, db },
    });

  /**
   * Update the tc to use on the document
   * @param {number} tc - TC to use
   * @returns {void}
   */
  const updateTc = (tc) =>
    dispatch({
      type: Actions.SET_TC,
      payload: tc,
    });

  /**
   * Update the flag the indicates if must generate the cxp
   * @param {boolean} generate - Flag that indicates if should generate cxp
   * @returns {void}
   */
  const updateGenerateCxp = (generate) =>
    dispatch({
      type: Actions.SET_GENERATE_CXP,
      payload: generate,
    });

  /**
   * Update the periocity for the contract
   * @param {import("structure/Document/Period/types").onChangePeriocityI} periocity
   * @returns {void}
   */
  const updatePeriocity = (periocity) =>
    dispatch({
      type: Actions.SET_PERIOCITY,
      payload: periocity,
    });

  /**
   * Update document comments to use on the contract
   * @param {import("types/typedef/customHooks/useCommentsDocument").CommentI[]} comments
   * @returns {void}
   */
  const updateComments = (comments) =>
    dispatch({
      type: Actions.SET_COMMENTS,
      payload: comments,
    });

  /**
   * Update document notes/considerations to use on the contract
   * @param {import("types/typedef/customHooks/useCommentsDocument").CommentI[]} comments
   * @returns {void}
   */
  const updateNotesAndConsiderations = (comments) =>
    dispatch({
      type: Actions.SET_NOTES_CONSIDERATIONS,
      payload: comments,
    });

  const lastBreadcrumItem = {
    route: "",
    text: `${idContract === null ? "Agregar" : "Editar"}`,
  };

  const lastBreadcrumOdcItem = {
    route: "",
    text: `${idOdc === null ? "Agregar" : "Editar"}`,
  };

  const canTriggerOdcQuery =
    state.partidas.items.length > 0 &&
    state.customer !== null &&
    state.customer !== undefined &&
    state.document.tc > 0
      ? true
      : false;

  const breadcrumPath =
    module !== "ventas"
      ? [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: `/directorio/documentos/${idCliente}`,
            text: "Documentos",
          },
          lastBreadcrumItem,
        ]
      : [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/ventas/contratos",
            text: "Ventas",
          },
          {
            route: "/ventas/contratos",
            text: "Contratos",
          },
          lastBreadcrumItem,
        ];

  return {
    currency: state.document.currency,
    updateCurrency,
    isDocumentFetched: state.isDocumentFetched,
    socialReason: state.customer,
    customerSocialReason: state.customerSocialReason,
    updateSocialReason,
    importe: state.document.import,
    iva: state.document.iva,
    total: state.document.total,
    updateItems,
    updateContact,
    tc: state.document.tc,
    updateExpiration,
    updateReminder,
    updateTc,
    periocity: state.document.periocity,
    updatePeriocity,
    uens: state.partidas.uens,
    updateStartDate,
    canTriggerOdcQuery,
    triggerUpdateOdc,
    canPerformQuery:
      state.document.startDateContract !== null &&
      state.partidas.items.length > 0 &&
      state.customer !== null &&
      state.document.expiration.db !== null &&
      state.document.tc > 0 &&
      state.customer !== undefined
        ? true
        : false,
    isPerformingQuery: state.isPerformingQuery,
    updateComments,
    updateNotesAndConsiderations,
    updateCode,
    triggerAddContract,
    comments: state.comments,
    code: state.document.code,
    docNumber: state.document.number,
    expirationDate: state.document.expiration.js,
    reminderDate: state.document.reminder.js,
    triggerUpdateContract,
    breadcrumPath,
    updateGenerateCxp,
    triggerAddOdc,
    updateItemsCosts,
    startDateContract: state.document.startDateContract,
    contact: state.document.contact.id,
    generateCxp: state.document.generateCxp,
    odcBreadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/administracion/ordenes-compra",
        text: "Administracion",
      },
      {
        route: "/administracion/ordenes-compra",
        text: "Ordenes de compra",
      },
      lastBreadcrumOdcItem,
    ],
  };
}
