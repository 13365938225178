import styled from "styled-components";

export const HederDocumentContainer = styled.div`
  border-radius: 10px;
  background: var(--cardBackground);
  width: 100%;
  color: var(--primaryColor);
  height: 5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  top: 3rem;
  z-index: 1;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0.75rem 0;
    padding:1rem;
    height: 100%;
    > * {
      width: 100% !important;
    }
  }
`;

export const ContactContainers = styled.div`
  width: 40%;
`;

export const CreditDaysContainer = styled.div`
  display: flex;
  width: 12%;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
`;

export const DateContainers = styled.div`
  width: 20%;
  display: flex;
  color: var(--primaryColor);
  justify-content: center;
  align-items: baseline;
  cursor: ${(props) => (props.disable ? "not-allowed" : "")};
`;

export const DateLabel = styled.p`
  text-align: end;
  color: var(--primaryColor);
  width: 50%;
  margin-right: 0.5rem;
  font-weight: bold;
`;
