import { RangeDate, From, To } from "components/general/RangeDate";
import React, { useContext } from "react";
import { ContextGestionOc } from "..";

export default function Dates() {
  const hook = useContext(ContextGestionOc);

  return (
    <RangeDate>
      <From selected={hook.from} onChange={date=>hook.setDate('from',date)}/>
      <To selected={hook.to} onChange={date=>hook.setDate('to',date)} maxDate={new Date()}/>
    </RangeDate>
  );
}
