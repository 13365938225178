import React, { useContext } from "react";
import uiManufacturer from "../styles.module.scss";
import Edit from "../Edit";
import Delete from "../Delete";
import { ContextManufacturer } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function ViewMode() {
  const { manufacturer, isSaving } = useContext(ContextManufacturer);

  return (
    <>
      <p>{manufacturer.keyValue}</p>

      <div className={uiManufacturer.actions}>
        {isSaving ? (
          <Spinner text={"Actualizando"} hidden={false} />
        ) : (
          <>
            <Edit />
            <Delete />
          </>
        )}
      </div>
    </>
  );
}
