import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextModalConcept } from "../ConceptsSection";
import DocumentContext from "../../ContextDocument";

export default function MotiveEdition() {
  const hook = useContext(ContextModalConcept);

  const hookDoc = useContext(DocumentContext);

  if (hookDoc.actionType !== "Editar") return <></>;

  return (
    <>
      <Label
        required={hook.editionMotive.length <= 0}
        maxLength={256}
        currentLength={hook.editionMotive.length}
      >
        Motivo de la edición
      </Label>

      <textarea
        onChange={(e) => {
          hook.setMotive(e.target.value);
          hookDoc.handleOnCommentChange(e.target.value)
          // hookDoc.handleOnCommentChangeMotive(e.target.value);
        }}
        maxLength={256}
        minLength={1}
        placeholder="Escribe aquí (Obligatorio)"
      ></textarea>
    </>
  );
}
