import React, { useState, useEffect } from "react";
import { Spinner } from "../Spinner/Spinner";
import LoadingText from "../TextLoading";
import ButtonV2 from "../ButtonsV2/Button";
import Icon from "../HeaderIcon";
import { updateAccounting } from "helpers/Apis/concilation";

const INITIAL_STATE = {
  isUpdating: false,
  canPerformUpdate: false,
};

/**
 * Render a button to update the accounting for the records
 * @param {import("./types").PropsUpdateAccounting} props - Props
 * @returns {JSX.Element}
 */
export default function UpdateAccounting({
  operationGrid = {},
  originalGrid = {},
  type,
  onUpdated = () => {}
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    // No data to evaluate
    if (
      Object.keys(operationGrid).length <= 0 ||
      Object.keys(originalGrid).length <= 0
    ) {
      setState((current) => ({
        ...current,
        canPerformUpdate: false,
      }));
      return;
    }

    let gridChanged = false;

    Object.entries(operationGrid).forEach(([key, value]) => {
      if (originalGrid[key] !== value) {
        gridChanged = true;
      }
    });

    setState((current) => ({
      ...current,
      canPerformUpdate: gridChanged,
    }));
  }, [operationGrid, originalGrid]);

  const attemptUpdate = async () => {
    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    let toAccount = [];
    let toDisaccount = [];

    Object.entries(operationGrid).forEach(([key, value]) => {
      // Value changed from it's original value
      if (originalGrid[key] !== value) {
        // Value is now to account
        if (value === true) {
          toAccount.push(+key);
          // Value is now to disaccount
        } else {
          toDisaccount.push(+key);
        }
      }
    });

    const wasUpdated = await updateAccounting(type, toAccount, toDisaccount);

    if(wasUpdated){
      onUpdated();
    }

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));
  };

  if (!state.canPerformUpdate) return <></>;

  if (state.isUpdating)
    return (
      <Spinner text={<LoadingText>Actualizando</LoadingText>} hidden={false} />
    );

  return (
    <ButtonV2 onClick={attemptUpdate}>
      <span>Actualizar</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
