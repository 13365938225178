import { InputCurrency } from "components/individual/inputs/Inputs";
import React from "react";
import { useContext } from "react";
import { ContextGridCxC } from "..";
import Money from "components/general/Forms/Money";

export default function InputDifferentCurrency() {
  const { element, currency, updateAmountApplied } = useContext(ContextGridCxC);

  if (element.import.number <= 0) return "-";

  if (element.currency !== currency)
    return (
      <Money
        onChange={(val) => updateAmountApplied(val, element.uuidFrontend)}
        placeholder="Escribe aquí"
        maxDecimals={2}
      />
      // <InputCurrency
      //   defaultValue={0}
      //   onChange={(val) => updateAmountApplied(val, element.uuidFrontend)}
      //   placeholder="Escribe aquí"
      // />
    );
}
