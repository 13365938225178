import {
  SET_SCREEN_ADD_DOC,
  SET_COMMENTS,
  SET_EDIT_COMMENT,
  SET_IS_SORTING,
  SET_IS_NEW_GAME,
  SET_GAME,
  SET_IVAS,
  SET_GAMES,
  SET_TCP,
  SET_CURRENCY_TYPE,
  SET_UEN_LIST,
  SET_NEW_IVAS_GAME,
  SET_DOCUMENT,
  SET_CATALOGUE,
  SET_CURRENCY_NAME,
  SET_SHOP_ITEM,
  DELETE_COMMENTS,
  DELETE_DOC_ITEM,
  SET_CUSTOMER_INFO,
  SET_DOC_INFO,
  SET_INDEXED_CURRENCIES,
  SET_CURRENCY_INFO,
  SET_EXCHANGE_TCP,
  RESET_ADD_DOCUMENT,
  SET_CURRENCY_BEING_USED,
  SET_ISFETCHING_DOCUMENT,
} from "../../types/index";

export default function (state, action) {
  switch (action.type) {
    case SET_ISFETCHING_DOCUMENT:
      return {
        ...state,
        isFetching: action.payload,
      };

    case SET_SCREEN_ADD_DOC:
      return {
        ...state,
        screen: action.payload,
      };

    case SET_CURRENCY_BEING_USED:
      return {
        ...state,
        currencyBeingUsed: action.payload,
      };

    case SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };

    case SET_EDIT_COMMENT:
      return {
        ...state,
        isEditingComment: action.payload,
      };

    case SET_IS_SORTING:
      return {
        ...state,
        isSorting: !state.isSorting,
      };

    case SET_GAME:
      return {
        ...state,
        infoGame: action.payload,
      };

    case SET_IS_NEW_GAME:
      return {
        ...state,
        isNewGame: action.payload,
      };

    case SET_IVAS:
      return {
        ...state,
        ivas: action.payload,
      };

    case SET_GAMES:
      return {
        ...state,
        games: action.payload,
      };
    case SET_TCP:
      return {
        ...state,
        tcp: action.payload,
        exchangeTcp: action.payload.test,
      };
    case SET_CURRENCY_TYPE:
      return {
        ...state,
        currencyType: action.payload,
      };
    case SET_DOCUMENT:
      return {
        ...state,
        documento: action.payload,
      };

    case SET_UEN_LIST:
      return {
        ...state,
        uenList: action.payload,
      };

    case SET_NEW_IVAS_GAME:
      return {
        ...state,
        ivasNewGame: action.payload,
      };

    case SET_CATALOGUE: {
      return {
        ...state,
        catalogue: action.payload,
      };
    }

    case SET_CURRENCY_NAME: {
      return {
        ...state,
        currencyName: action.payload,
      };
    }

    case SET_SHOP_ITEM: {
      return {
        ...state,
        shopItemCart: action.payload,
        infoGame: action.payload,
      };
    }

    case DELETE_COMMENTS: {
      return {
        ...state,
        commentsToDelete: action.payload,
      };
    }

    case DELETE_DOC_ITEM: {
      return {
        ...state,
        docItemsToDelete: action.payload,
      };
    }

    case SET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: action.payload,
      };
    }

    case SET_DOC_INFO: {
      return {
        ...state,
        docInfo: action.payload,
      };
    }

    case SET_INDEXED_CURRENCIES: {
      return {
        ...state,
        currencies: action.payload,
      };
    }

    case SET_CURRENCY_INFO: {
      return {
        ...state,
        currencyType: action.payload.id,
        currencyName: action.payload.label,
      };
    }

    case SET_EXCHANGE_TCP: {
      return {
        ...state,
        exchangeTcp: action.payload,
      };
    }

    case RESET_ADD_DOCUMENT: {
      return {
        screen: null,
        comments: [],
        isEditingComment: false,
        isSorting: false,
        infoGame: null,
        isNewGame: false,
        ivas: [],
        ivasNewGame: [],
        games: [],
        tcp: 0,
        currencyType: 1,
        currencyName: "MXN",
        documento: { vacio: true },
        uenList: [],
        catalogue: [],
        shopItemCart: null,
        commentsToDelete: [],
        docItemsToDelete: [],
        customerInfo: null,
        docInfo: null,
        currencies: null,
        exchangeTcp: 0,
        currencyBeingUsed: null,
      };
    }

    default:
      break;
  }
}
