import { CustomTutorial } from "components/general/CustomTutorial";
import { Modal } from "components/general/Modal";
import React from "react";
import { useContext } from "react";
import { Tutorial } from "structure/Tutorial";
import { ContextPushNotification } from "..";

export default function PromptAllow({ isOpen = true }) {
  const hook = useContext(ContextPushNotification);

  return (
    <Modal
      width={60}
      isOpen={isOpen}
      displayButton={false}
      title="Permitir notificaciones"
      onCloseModal={hook.closeTutorial}
    >
      {() => (
        <div>
          <CustomTutorial
            folder="images/notifications/"
            labels={[
              "Abrir configuracion del sitio",
              "Permitir notificaciones",
              "Cerrar configuracion del sitio",
              "Aplicar cambios (refrescar)",
            ]}
          />
        </div>
      )}
    </Modal>
  );
}
