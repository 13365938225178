import React, { createContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useInvoice from "customHooks/useAddInvoice";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import usePermissions from "customHooks/usePermissions";
import ui from 'structure/ODC/styles.module.scss';

/**
 * Context for the documents
 * @type {React.Context<import("customHooks/useAddInvoice/types").InvoiceContextI|undefined>}
 */
export const InvoiceContext = createContext(undefined);
const { Provider } = InvoiceContext;

export default function AddInvoice() {

  usePermissions("dcf3e4a1-40df-4c3f-84bd-9de21c2b5699");

  const {
    breadcrumPath,
    tc,
    updateTc,
    updateSocialReason,
    currency,
    updateCurrency,
    importe,
    iva,
    total,
    updateCfdi,
    updatePayForm,
    updatePayMethod,
    isDocumentFetched,
    socialReason,
    updateContact,
    cfdi,
    payForm,
    payMethod,
    updatePeriocity,
    periocity,
    canPerformQuery,
    isPerformingQuery,
    customerSocialReason,
    updateNotesAndConsiderations,
    performAddInvoice,
    
    expiration,
    reminder,
    updateExpirationDate,
    updateReminderDate,
    customer,
    docNumber,
    rfc,
  } = useInvoice({});

  return (
    <Provider
      value={{
        tc,
        updateTc,
        updateCurrency,
        currency,
        importe,
        performAddInvoice,
        iva,
        total,
        updateCfdi,
        updatePayForm,
        updatePayMethod,
        isDocumentFetched,
        updateSocialReason,
        socialReason,
        updateContact,
        cfdi,
        payForm,
        payMethod,
        updatePeriocity,
        periocity,
        canPerformQuery,
        isPerformingQuery,
        updateNotesAndConsiderations,
        expiration,
        reminder,
        updateExpirationDate,
        updateReminderDate,
        customerSocialReason,
        customer,
        docNumber,
        rfc,
        module: "1232",
      }}
    >
      <div className={ui.container}>
      <BreadCrumV2 path={breadcrumPath} />
      <AddDocument
        actionType="Agregar"
        customerId={customer}
        documentType={2}
      />
      </div>
    </Provider>
  );
}
