import React from "react";
import { Row, Amounts } from "../styles";

/**
 * Show the information of the egress
 * @param {object} props - Props component
 * @param {import("types/typedef/customHooks/useEgressOverview").InvoiceI} props.invoice - Information of the invoice
 * @param {import("types/typedef/customHooks/useEgressOverview").EgressI} props.concept - Information of the concept
 * @returns {JSX.Element}
 */
export default function RowDeductible({ invoice, concept }) {
  return (
    <Row>
      <p>{invoice.socialReason}</p>
      <p className="text-center">{invoice.folio}</p>
      <p>{concept.description}</p>
      {/* <p className="text-center">{invoice.currency}</p> */}
      <p className="text-right">
        {invoice.total.text} {invoice.currency}
      </p>
      <Amounts>
        <p>
          {invoice.applied.text} {invoice.currency}
        </p>
        {/* <p>{invoice.residue.text}</p> */}
      </Amounts>
      <Amounts>
        {/* <p className="text-right">{invoice.applied.text}</p> */}
        <p className="text-right">
          {invoice.paid.text} {concept.currency}
        </p>
      </Amounts>
    </Row>
  );
}
