import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./types";

/**
 * Create tabs in order to display different content
 * according to the active tab
 * @param {import("./types").PropsTabs} props - Props
 * @example
    <Tabs
        idContainerContent="documentInfo"
        idContainerTabs="tab"
        tabButtons={{
          document: {
            text: formEdition[typeDocument]["nameDocument"],
            selected: true,
            component: formEdition[typeDocument]["component"],
          },
          documentItems: {
            text: "Partidas",
            selected: false,
            component: <p>Partidas</p>,
          },
          documentComments: {
            text: "Comentarios",
            selected: false,
            component: <p>Comentarios</p>,
          },
        }}
    />
 */
function Tabs({ tabButtons, idContainerTabs = "", idContainerContent = "" }) {
  /**
   * Indexed object names
   *
   * @type {string[]}
   */
  const tabs = Object.keys(tabButtons);

  /**
   * Trigger the tab pressed to show the corresponding UI.
   *
   * @param {string} id - ID of the indexed tabButtons
   */
  const toggleTab = (id) => {
    removeAllActive();
    triggerTab();
    displayContainer();

    /**
     * Toggle the class on the tabs to display the 'active' tab on the UI
     */
    function triggerTab() {
      const tab = document.getElementById(`tab-${id}`);
      tab.classList.add("active");
    }

    /**
     * Toggle the class on the containers to display the 'active' content on the UI
     */
    function displayContainer() {
      const content = document.getElementById(`tabContent-${id}`);
      content?.classList.add("d-block");
    }
  };

  /**
   * Quit all the active class name from the container tabs
   */
  const removeAllActive = () => {
    document.querySelectorAll(`#${idContainerTabs} > div`).forEach((tab) => {
      tab.classList.remove("active");
    });

    document
      .querySelectorAll(`#${idContainerContent} > div`)
      .forEach((content) => {
        content.classList.remove("d-block");
      });
  };

  useEffect(() => {
    tabs.forEach((tab, i) => {
      const defaultSelect = tabButtons[tab]["selected"] | false;

      // If 1, toggle the tab to display the UI
      if (defaultSelect) {
        toggleTab(tabs[i]);
      }
    });
  }, []);

  return (
    <div>
      <div id={idContainerTabs} className="tab d-flex justify-content-center ">
        {tabs.map((tab, i) => {
          return (
            <div
              onClick={() => {
                toggleTab(tabs[i]);

                const callback = tabButtons[tabs[i]]["onClick"];

                if (callback !== undefined) {
                  callback(tabButtons[tab[i]]);
                }
              }}
              key={`tab-${tabs[i]}`}
              id={`tab-${tabs[i]}`}
              className="tablinks"
            >
              {tabButtons[tabs[i]]["text"]}
            </div>
          );
        })}
      </div>

      <div id={idContainerContent} className="sizeTab tabContent">
        {tabs.map((tab, i) => {
          return (
            <div
              key={`tabContent-${tabs[i]}`}
              id={`tabContent-${tabs[i]}`}
              className="tabcontent"
            >
              {tabButtons[tabs[i]]["component"]}
            </div>
          );
        })}
      </div>
    </div>
  );
}


export { Tabs };
