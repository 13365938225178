import * as yup from "yup";

const provider = yup
  .number()
  .positive("Proveedor invalido")
  .typeError("Proveedor requerido")
  .required("El proveedor es requerido")
  .transform((casted, uiValue) => {
    if (isNaN(casted)) {
      document.getElementById("tab-oc").click();
      document.getElementById("tab-dataOc").click();
    }

    return casted;
  });

const creditDaysProvider = yup
  .number()
  .default(null)
  .positive("Dias de credito invalidos")
  .typeError("Dias de credito obligatorios")
  .required("Dias de credito obligatorios");

const creditDaysInvoice = yup
  .number()
  .positive("Dias de credito invalidos")
  .typeError("Dias de credito obligatorios")
  // .required("Dias de credito obligatorios")
  .transform((casted, uiValue) => {
    const tab = document.getElementById("tab-invoiceData");

    // if (!tab) return;

    // console.log(tab);

    if (casted < 1) {
      if (tab) {
        tab.click();
      }
      return -1;
    }

    if (isNaN(casted)) {
      if (tab) {
        tab.click();
      }
      return -1;
    }

    return casted;
  });

export const cfdi = yup
  .number()
  .positive("CFDI invalido")
  .typeError("CFDI obligatorio");
// .required("CFDI obligatorio");

export const payForms = yup
  .number()
  .positive("Forma de pago invalido")
  .typeError("Forma de pago obligatorio");
// .required("Forma de pago obligatorio");

export const payMethods = yup
  .number()
  .positive("Metodo de pago invalido")
  .typeError("Metodo de pago obligatorio");
// .required("Metodo de pago obligatorio");

const generateContract = yup.boolean().nullable(true).default(false);

const contract = yup
  .string()
  .default(null)
  .nullable(true)
  .max(30, "Maximo 30 caracteres")
  .typeError("Numero de contrato invalido")
  .transform((casted, value) => {
    if (casted.length > 30) {
      document.getElementById("tab-contract").click();
      document.getElementById("tab-dataContract").click();
    }
    return casted;
  });

const customerPreinvoice = yup
  .number()
  .positive("Cliente incorrecto, informacion corrompida")
  .typeError("El valor del cliente no es valido");

const contactPreinvoice = yup
  .number()
  .positive("Contacto incorrecto, informacion corrompida")
  .nullable(true)
  .default(null)
  .typeError("Selecciona un contacto valido")
  .transform((casted, value) => {
    if (isNaN(casted)) return null;

    if (value === "-1") {
      return null;
    } else {
      return casted;
    }
  });

const generateCxp = yup
  .boolean()
  .nullable(false)
  .default(true)
  .typeError("Selecciona un contacto valido");

const ocContact = yup
  .number()
  .positive("Contacto incorrecto, informacion corrompida")
  .nullable(true)
  .default(null)
  .typeError("Selecciona un contacto valido")
  .transform((casted, value) => {
    if (isNaN(casted)) return null;

    if (value === "-1") {
      return null;
    } else {
      return casted;
    }
  });

const reminderContract = yup.string().nullable(true).default(null);

const expirationContract = yup.string().nullable(true).default(null);

const quoteExpiration = yup.string();

const quoteReminder = yup.string();

const schemaValidation = yup.object().shape({
  creditDaysProvider,
  provider,
  creditDaysInvoice,
  cfdi,
  payForms,
  payMethods,
  generateContract,
  contract,
  customerPreinvoice,
  contactPreinvoice,
  generateCxp,
  ocContact,
  expirationContract,
  reminderContract,
  quoteExpiration,
  quoteReminder,
});

export default schemaValidation;
