import Time from "components/individual/Time";
import { AsignApp } from "pages/Tickets/components/asign/asignApp";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { createContext } from "react";
import TicketsComments from "structure/Bug/Tickets/Comments";
import { Label } from "structure/Document";
import scss from "./styles.module.scss";
import LoginContext from "context/Login/LoginContext";
import { useContext } from "react";
import { StartTicket } from "pages/Tickets/components/startTicket/startTicket";
import { CloseTicket } from "pages/Tickets/components/closeTicket/closeTicket";
import { RateTicket } from "pages/Tickets/components/rate/rateTicket";
import ModalAssociatedFiles from "../ModalAssociatedFiles";
import { useState, useEffect } from "react";
import { getTicket } from "helpers/Apis/tickets";
import { RedirectButton } from "components/individual/buttons/Buttons";
import Icon from "components/individual/Icon";
import { copyOnClipboard, openOnNewTab } from "helpers/dom";
import NotificationsComments from "./NotificationsComments";
import MobileOptions from "./MobileOptions";
import ReturnTicket from "pages/Tickets/components/ReturnTicket";

/**
 * @type {import("react").Context<import("./types").ContextTicket>}
 */
export const TicketContext = createContext(undefined);

/**
 * @type {import("./types").StateTicket}
 */
const initial_state = {
  isLoading: false,
  ticket: undefined,
  refetchComments: false,
};

/**
 * Render a ticket
 * @param {import("./types").PropsTicketI} props - Props
 * @returns {JSX.Element}
 */
export default function Ticket({ id, ticket = null, onChange = () => {} }) {
  const { userInfo } = useContext(LoginContext);

  const [state, setState] = useState({ ...initial_state, ticket });

  useEffect(() => {
    setState((current) => ({ ...current, isLoading: false, ticket }));
  }, [ticket]);

  useEffect(() => {
    (async function () {
      if (ticket !== null) return;

      const apiTicket = await getTicket(null, id);

      if (apiTicket === null) {
        setState((current) => ({
          ...current,
          isLoading: false,
          ticket: null,
        }));
        return;
      }

      onChange(apiTicket);

      setState((current) => ({
        ...current,
        isLoading: false,
        ticket: apiTicket,
      }));
    })();
  }, []);

  /**
   * Update the ticket re-fetching the information from the database
   */
  const refreshTicketInfo = async () => {
    let ticketToUse = null;

    const apiTicket = await getTicket(id || ticket.id);
    ticketToUse = apiTicket;

    if (apiTicket === null) {
      const apiTicketByFolio = await getTicket(null, id);
      ticketToUse = apiTicketByFolio;
    }

    setState((current) => ({ ...current, ticket: ticketToUse }));
    onChange(ticketToUse);
  };

  const handleEvaluateTicket = async () => {
    await refreshTicketInfo();

    setState((currentState) => ({
      ...currentState,
      refetchComments: !currentState.refetchComments,
    }));
  };

  if (state.ticket === null && !state.isLoading) {
    return (
      <div className="d-flex justify-content-center flex-column align-items-center py-4">
        <div className="d-flex align-items-center">
          <h2 className="mr-2">El ticket no fue encontrado</h2>
          <RedirectButton
            link="/procesos-especiales/help-desk/tickets"
            text="Regresar"
          />
        </div>
        <img
          src="https://pixabay.com/get/g6bf38bbd65ef9342ab29726773cf2ca59b5ee531207143dd160d1a789f3ddb970501872c1e07d60e795ce8e16ffe4e60e9264b72085f6661d7867122c97d15933e628e23477306e068acf14d8259457e_640.jpg"
          alt=""
        />
      </div>
    );
  }

  if (state.ticket === null || userInfo.length <= 0)
    return <Spinner hidden={false} text="Cargando ticket" />;

  const messageDate = state.ticket.task.wasRejected ? "NA" : "Pendiente";

  return (
    <TicketContext.Provider value={{ ...state, refreshTicketInfo }}>
      <div className={scss.ticket}>
        <MobileOptions ticket={state.ticket} onChange={refreshTicketInfo} />

        <div className={scss.ticketOptions}>
          <div className="d-flex">
            <ModalAssociatedFiles
              idRegister={state.ticket.id || id}
              idTypeEntity={4}
              pathUpload={`tickets/${state.ticket.id || id}`}
            />

            <AsignApp
              idTicket={state.ticket.id || id}
              onAssigned={refreshTicketInfo}
              assignedTo={state.ticket.assignedTo.id}
            />

            <StartTicket
              startedAt={state.ticket.startedAt}
              idTicket={state.ticket.id || id}
              idEngineer={state.ticket.assignedTo.id}
              onStartedTicket={refreshTicketInfo}
            />

            <ReturnTicket ticket={state.ticket} onUpdated={refreshTicketInfo} />

            <CloseTicket
              isFinished={state.ticket.task.isFinished}
              idEngineer={state.ticket.assignedTo.id}
              idTicket={state.ticket.id || id}
              onClosedTicket={refreshTicketInfo}
              startedAt={state.ticket.startedAt}
            />

            <RateTicket
              idTicket={state.ticket.id || id}
              onRatedTicket={handleEvaluateTicket}
              status={state.ticket.status}
              createdBy={state.ticket.reported.by.id}
            />
          </div>

          <div className="d-flex">
            <NotificationsComments
              notificateTo={state.ticket.notificateToUsers.map(
                (id) => id.idUser
              )}
              idTicket={state.ticket.id}
            />

            <button
              onClick={() =>
                copyOnClipboard(
                  `${window.location.host}/procesos-especiales/help-desk/tickets/busqueda?ticket=${state.ticket.folio}`,
                  true,
                  `Ticket #${state.ticket.folio} copiado al portapapeles`
                )
              }
              className={scss.action}
            >
              <Icon nameIcon="link" />
            </button>

            <button
              className={scss.action}
              onClick={() =>
                openOnNewTab(
                  `${window.location.host}/procesos-especiales/help-desk/tickets/busqueda?ticket=${state.ticket.folio}`
                )
              }
            >
              <Icon nameIcon="eye" />
            </button>
          </div>
        </div>

        <article>
          <hr />
          <div className={scss.containerTitle}>
            <h1>{state.ticket.title}</h1>
            {state.ticket.jamLink === null ||
            state.ticket.jamLink === "" ? null : (
              <a href={state.ticket.jamLink} target="_blank" rel="noreferrer">
                <img
                  src="/images/jam.dev_.png"
                  alt={`Liga_jam_para_reporte_folio_${state.ticket.folio}`}
                />
              </a>
            )}
          </div>
          <hr />
          <h2>Fechas</h2>
          <div className={scss.dates}>
            <div className={scss.date}>
              <Label>Creacion</Label>
              <Time time={state.ticket.reported.date} />
            </div>
            <div className={scss.date}>
              <Label>Iniciado</Label>
              <Time time={state.ticket.assignedTo.date} label={messageDate} />
            </div>
            <div className={scss.date}>
              <Label>Por revisar</Label>
              <Time time={state.ticket.startedAt} label={messageDate} />
            </div>
            <div className={scss.date}>
              <Label>Cierre</Label>
              <Time time={state.ticket.finishedAt} label={messageDate} />
            </div>
            <div className={scss.date}>
              <Label>Evaluacion</Label>
              <Time time={state.ticket.score.date} label={messageDate} />
            </div>
          </div>
          <hr />
          <p>Creado por: {state.ticket.reported.by.name}</p>
          <hr />
          <p>Asignado a: {state.ticket.assignedTo.name || messageDate}</p>
          <hr />
          <h2>Descripcion</h2>
          <p>{state.ticket.description}</p>
          <hr />
          <h3>Comentarios</h3>
          <TicketsComments
            rate={state.ticket.score.rate}
            ticket={state.ticket.id}
            trigger={state.refetchComments}
          />
        </article>
      </div>
    </TicketContext.Provider>
  );
}
