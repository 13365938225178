import useDialog from "customHooks/useDialog";
import { useEffect, useState } from "react";
import {
  bankAccountReport,
  bankAccountReportExcel,
  getAccount,
} from "helpers/Apis/Banks/index";
import { MONTHS, MONTH_LIST } from "helpers/dates";
import { saveAs } from "file-saver";
import { getCards } from "helpers/Apis/Banks/index";

/**
 * @type {import("./types").StateBankAccountReportV2}
 */
const INITIAL_STATE = {
  isOpen: false,
  isLoading: true,
  account: null,
  accounts: [],
  periodDates: [],
  period: null,
  isDownloadingXlsx: false,
  isDownloadingPdf: false,
  year: null,
  month: null,
  availableDates: [],
  availableMonths: [],
};

/**
 *
 * @param {number|null} [id=null] - Id of the bank account to operate
 * @returns {import("./types").ReturnUseBankAccountReportV2}
 */
export default function useBankAccountReport(id) {
  const [state, setState] = useState(INITIAL_STATE);
  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      if (typeof id !== "number") return;

      const bankAccount = await getAccount(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        account: bankAccount,
      }));
    })();
  }, [state.isOpen]);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      if (typeof id === "number") return;

      const bankAccounts = await getCards();

      setState((current) => ({
        ...current,
        accounts: [
          { label: "-- SELECCIONA --", disabled: true, id: null },
          ...bankAccounts.map((bank) => ({
            ...bank,
            label: `${bank.bank.shortName} - ${bank.account}`,
          })),
        ],
        isLoading: false,
      }));
    })();
  }, [state.isOpen]);

  useEffect(() => {
    (async function () {
      if (state.account === null) return;

      const accountApi = await getAccount(state.account.id);

      setState((current) => ({
        ...current,
        availableDates: accountApi.datesWithMovements,
      }));

      /**
       * @type {import("./types").PeriodDate[]}
       */
      const options = state.account.monthsClosed.map((date) => {
        const option = new Date(`${date}:`);

        return {
          label: `[${option.getUTCFullYear()}] ${MONTHS[option.getUTCMonth()]}`,
          value: option,
        };
      });

      // There's just one month conciliated, don't show options
      if (options.length <= 1) return;

      const periodDates = options.reverse();
      periodDates.pop();

      setState((current) => ({
        ...current,
        periodDates,
        period: periodDates[0],
        month: null,
        year: null,
      }));
    })();
  }, [state.account]);

  const handleModal = (isOpen) => {
    setState((current) => ({
      ...current,
      isOpen,
    }));

    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
      setState(INITIAL_STATE);
    }
  };

  const setBankAccount = (account) =>
    setState((current) => ({
      ...current,
      account,
      year: null,
      month: null,
      availableDates: [],
      availableMonths: [],
    }));

  const setPeriod = (period) =>
    setState((current) => ({
      ...current,
      period,
    }));

  const handleDownloadPdf = async () => {
    setState((current) => ({
      ...current,
      isDownloadingPdf: true,
    }));
    const pdf = await bankAccountReport(
      state.account.id,
      state.month,
      state.year
    );

    if (pdf instanceof Blob) {
      saveAs(
        pdf,
        `Estado de cuenta ${state.account.account} - ${
          MONTH_LIST[state.month - 1].name
        } ${state.year}.pdf`
      );
    } else {
      dialog.close();
    }

    setState((current) => ({
      ...current,
      isDownloadingPdf: false,
    }));
  };

  const downloadExcelReport = async () => {
    setState((current) => ({
      ...current,
      isDownloadingXlsx: true,
    }));

    const excel = await bankAccountReportExcel(
      state.account.id,
      state.month,
      state.year
    );

    if (excel instanceof Blob) {
      saveAs(
        excel,
        `Estado de cuenta ${state.account.account} - ${
          MONTH_LIST[state.month - 1].name
        } ${state.year}.xlsx`
      );
    } else {
      dialog.close();
    }

    setState((current) => ({
      ...current,
      isDownloadingXlsx: false,
    }));
  };

  const setMonth = (month) =>
    setState((current) => ({
      ...current,
      month,
    }));

  const setYear = (year) => {
    /**
     * @type {number[]}
     */
    const filteredDates = state.availableDates
      .filter((date) => date.startsWith(year))
      .reduce((dates, date) => [...dates, +date.substring(5, 9)], []);

    const monthsToUse = MONTH_LIST.reduce((months, date) => {
      if (filteredDates.includes(date.value)) return [...months, date];

      return months;
    }, []);

    setState((current) => ({
      ...current,
      year,
      availableMonths: [
        { value: null, name: "-- SELECCIONA --", disabled: true },
        ...monthsToUse,
      ],
    }));
  };

  return {
    ...state,
    dialog,
    setMonth,
    setYear,
    handleModal,
    downloadExcelReport,
    setPeriod,
    handleDownloadPdf,
    setBankAccount,
  };
}
