import React, { createContext, useContext } from "react";
import { BreadcrumUsers } from "static/StaticBreadCrums";
import { SearchUsers } from "./SearchUsers";
import { AddUsers } from "./AddUsers";
import LoginContext from "context/Login/LoginContext";
import { EditUsers } from "./EditUser";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useUsers, { CONTEXT_INITIAL_STATE } from "./useUsers";
import TableUsers from "./TableUsers";
import usePermissions from "customHooks/usePermissions";
import style from "./Usuarios.module.scss";

/**
 * @type {import("react").Context<import("./types").ContextStateUseUsers>}
 */
export const UsersContext = createContext(CONTEXT_INITIAL_STATE);
const { Provider } = UsersContext;

export const UsuariosV2 = () => {
  const { userInfo } = useContext(LoginContext);

  const hook = useUsers();
  usePermissions("bb8b4c5a-ed0d-476c-9e36-b1b66b326105");

  return (
    <Provider value={{ ...hook, resetInfoRow: () => {} }}>
      <div className={style.usersContainer}>
        <div className={style.headerUsers}>
          <BreadCrumV2 path={BreadcrumUsers} />
          <div className={style.filterUsers}>
            <SearchUsers />
            <AddUsers userInfo={userInfo} />
          </div>
        </div>

        <div className={style.usersOptions}>
          {hook.user !== null ? (
            <EditUsers userInfo={userInfo} infoRow={hook.user} />
          ) : null}
        </div>

        <TableUsers />
      </div>
    </Provider>
  );
};