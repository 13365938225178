/**
 * Actions to dispatch the state
 * @type {import("./types").ActionsI}
 */
export const Actions = {
  SET_INITIAL_INFO: "SET_INITIAL_INFO",
  SET_OPEN_MODAL: "SET_OPEN_MODAL",
  SET_ITEM_SELECTED: "SET_ITEM_SELECTED",
  SET_CHECK_ALL: "SET_CHECK_ALL",
  SET_IS_FETCHING_DISASSOCIATE: "SET_IS_FETCHING_DISASSOCIATE",
  SET_BANK:"SET_BANK"
};
