import Select from "components/individual/SelectV4";
import { ContextBankCorrection } from "context/BankCorrection";
import React, { useContext } from "react";
import { Label } from "structure/Document";

export default function SelectBank() {
  const hook = useContext(ContextBankCorrection);

  return (
    <div>
      <Label required={hook.bankSelected===null||hook.bankSelected===undefined}>Cuenta</Label>
      <Select
        onChange={hook.setBank}
        labelToDisplay="label"
        defaultSelected={{
          keyToCompare: "id",
          value: hook.bankSelected?.id||null,
        }}
        options={async () => [
          { label: "-- SELECCIONA --", disabled: true, id: null },
          ...hook.banksAvailableToCorrect.filter(item=>item.isActive===true),
        ]}
      />
    </div>
  );
}
