import React from "react";
import { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";
import { useRef } from "react";
import style from "./styles.module.scss";

export default function CommentsOrder() {
  const hook = useContext(ContextCreateInvoiceV2);

  const { close, open, refDialog } = useDialog();

  const key = useRef(window.crypto.randomUUID);

  if (hook.comments.length <= 0)
    return (
      <span
        style={{
          color: "var(--primaryColor)",
        }}
      >
        No hay comentarios
      </span>
    );

  return (
    <>
      <span className={style.openComments} onClick={open}>
        Revisar comentarios
      </span>
      <Dialog forwardRef={refDialog} closeCallback={close} title="Comentarios">
        <ol className={style.list}>
          {hook.comments.map((comment, i) => (
            <li key={`${i}-${key.current}`}>{comment}</li>
          ))}
        </ol>
      </Dialog>
    </>
  );
}
