import React from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";

export default function AssociatedFilesRemition() {
  const { idPreinvoice } = useParams();

  return (
    <>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: `/proyectos`,
            text: "Proyectos",
          },
          {
            route: `/proyectos`,
            text: "Remisión",
          },
        ]}
      />
      <div className="container">
        <AssociateFiles
          idRegister={+idPreinvoice}
          idTypeEntity={1}
          pathUpload={`administracion/pedidos/remision-id-${idPreinvoice}`}
        />
      </div>
    </>
  );
}
