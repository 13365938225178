import { CustomTutorial } from "components/general/CustomTutorial";
import React from "react";
import ui from "./styles.module.scss";

export default function InstallPraxia() {
  return (
    <div className={ui.chromeInstallation}>
      <CustomTutorial
        labels={[
          "Configuración",
          "Instalar aplicación",
          "Permitir instalación",
          "Aplicación instalada",
        ]}
        folder="images/install_mobile_chrome/"
      />
      <CustomTutorial
        labels={[
          "Configuración",
          "Instalar aplicación",
          "Aplicación instalada",
        ]}
        folder="images/install_desktop_chrome/"
      />
    </div>
  );
}
