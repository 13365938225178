// @ts-check

import { getCxcConcilationsMovement } from "helpers/Apis/Banks";
import {
  cancelIncoming,
  geMovementConcepts,
  getIncomingComplements,
  cancelIncomingConcepts,
} from "helpers/Apis/Banks/index";
import { createIndexedList } from "helpers/lists";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateComplementIncoming}
 */
const INITIAL_STATE = {
  isLoading: true,
  concepts: [],
  complements: [],
  associations: [],
  customer: null,
  isOpen: true,
  complementsSelected: {},
  conceptsSelected: {},
  puesSelected: {},
  isWaitingServerResponse: false,
  conceptsWithInvoice:{},
  dummyConcepts:[]
};

/**
 * Hook to handle view information and cancel incoming
 * @param {number} idMovement - Id of the incoming
 * @param {()=>void} onSuccess - Callback executed when the incoming was cancelled
 * @returns {import("./types").ReturnComplementIncoming}
 */
export default function useComplementIncoming(
  idMovement,
  onSuccess = () => {}
) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      const [apiConcilations, apiConcepts, apiComplements] = await Promise.all([
        getCxcConcilationsMovement(idMovement),
        geMovementConcepts(idMovement),
        getIncomingComplements(idMovement),
      ]);

      const conceptsWithInvoice = getIndexedConceptsWithInvoice();

      const parsedConcilations = apiConcilations.filter(
        (cxc) => cxc.invoice.payMethod === "PUE"
      );

      const parsedConcepts = apiConcepts.filter(
        (concept) =>
          concept.isActive 
          // && concept.concept.id !== 7
          // && concept.concept.id !== 8
          // &&
          // concept.concept.id !== 7 &&
          // concept.concept.id !== 8
      );

      const customer =
        apiConcilations.length <= 0
          ? null
          : apiConcilations[0].invoice.socialReason;

      setState((current) => ({
        ...current,
        concepts: parsedConcepts,
        complements: apiComplements,
        associations: parsedConcilations,
        isLoading: false,
        customer,
        conceptsWithInvoice
      }));

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      /**
       * 
       * @returns {import("./types").ConceptsWithInvoice}
       */
      function getIndexedConceptsWithInvoice(){

        if(apiConcilations.length > 0){

          /**
           * @type {import("../../../../server/models/banks/movements/types").ConceptMovementI[]}
           */
          const dummyConcepts = apiConcilations.map(item=>({
            id:item.id,
            isActive:true,
            applied:item.applied.number,
            currency:item.invoice.currency,
            concept:item.invoice.concept,
            customer:{
              id:null,
              socialReason:item.invoice.socialReason
            }
          }));

          const indexedConciliations = createIndexedList(apiConcilations,'id');

          const conceptsWithInvoice = dummyConcepts.reduce((indexed,item)=>({
            ...indexed,
            [item.id]:indexedConciliations[item.id]
          }),{})


          setState(current=>({
            ...current,
            dummyConcepts,
            // conceptsWithInvoice
          }))
          return conceptsWithInvoice
        }

        /**
         * @type {import("./types").ConceptsWithInvoice}
         */
        let indexed = {}

        for(let i = 0;i<apiConcilations.length ; i++){
          for(let j = 0;j<apiConcepts.length;j++){
            if(apiConcilations[i].applied.number===apiConcepts[j].applied && apiConcilations[i].invoice.socialReason === apiConcepts[j].customer.socialReason){
              indexed = {
                ...indexed,
                [apiConcepts[j].id]:apiConcilations[i]
              }
            }
          }
        }

        return indexed
      }
    })();
  }, [state.isOpen]);

  const openModal = () =>
    setState((current) => ({
      ...current,
      isOpen: true,
    }));

  const closeModal = () =>
    setState((current) => ({
      ...current,
      isOpen: false,
    }));

  /**
   * Check/Uncheck the complements options when cancelling or disassociating
   * @param {import("../../../../server/models/banks/movements/types").MovementComplementI} complement - Complement information
   */
  function handleCheckComplement(complement) {
    const isItemOnCurrentList = Object.keys(state.complementsSelected).includes(
      complement.uuid
    );

    if (isItemOnCurrentList) {
      deleteFromList();
      return;
    }

    appendOnList();
    /////////////////////////////////////////////////////

    function deleteFromList() {
      const unrefItems = { ...state.complementsSelected };
      delete unrefItems[complement.uuid];

      setState((current) => ({
        ...current,
        complementsSelected: unrefItems,
      }));
    }

    function appendOnList() {
      setState((current) => ({
        ...current,
        complementsSelected: {
          ...current.complementsSelected,
          [complement.uuid]: complement,
        },
      }));
    }
  }

  /**
   * Check/Uncheck the concept options when cancelling or disassociating
   * @param {import("../../../../server/models/banks/movements/types").ConceptMovementI} concept - Complement information
   */
  function handleCheckConcept(concept) {
    const isItemOnCurrentList = Object.keys(state.conceptsSelected).includes(
      `${concept.id}`
    );

    if (isItemOnCurrentList) {
      deleteFromList();
      return;
    }

    appendOnList();
    /////////////////////////////////////////////////////

    function deleteFromList() {
      const unrefItems = { ...state.conceptsSelected };
      delete unrefItems[concept.id];

      setState((current) => ({
        ...current,
        conceptsSelected: unrefItems,
      }));
    }

    function appendOnList() {
      setState((current) => ({
        ...current,
        conceptsSelected: {
          ...current.conceptsSelected,
          [concept.id]: concept,
        },
      }));
    }
  }

  /**
   * TODO: Falta tipar...
   * Check/Uncheck the concept options when cancelling or disassociating
   * @param {any} pue - Complement information
   */
  function handleCheckPue(pue) {
    const isItemOnCurrentList = Object.keys(state.puesSelected).includes(
      `${pue.id}`
    );

    if (isItemOnCurrentList) {
      deleteFromList();
      return;
    }

    appendOnList();
    /////////////////////////////////////////////////////

    function deleteFromList() {
      const unrefItems = { ...state.puesSelected };
      delete unrefItems[pue.id];

      setState((current) => ({
        ...current,
        puesSelected: unrefItems,
      }));
    }

    function appendOnList() {
      setState((current) => ({
        ...current,
        puesSelected: {
          ...current.puesSelected,
          [pue.id]: pue,
        },
      }));
    }
  }

  /**
   * UUID of the complement selected
   * @param {string} uuid - UUID
   */
  const isComplementSelected = (uuid) =>
    Object.keys(state.complementsSelected).includes(uuid);

  const canDisplayDisassociate = () => {
    // No complements or PUE invoices, meaning that all the incoming was to concepts
    if (state.complements.length <= 0 && state.associations.length <= 0)
      return true;

    const complementsSelected = Object.keys(state.complementsSelected).length;
    const puesSelected = Object.keys(state.puesSelected).length;

    const checkboxsSelected = complementsSelected + puesSelected;

    // User haven't check any option to disassociate or cancel
    if (checkboxsSelected <= 0) return false;

    return true;
  };

  /**
   * Try to cancel the incoming
   */
  async function attemptCancelIncoming() {
    setState((current) => ({
      ...current,
      isWaitingServerResponse: true,
    }));

    const uuids = Object.keys(state.complementsSelected);
    const pues = Object.keys(state.puesSelected).map((key) => +key);

    const checkboxesSelected = uuids.length + pues.length;

    /**
     * @type {boolean}
     */
    let wasCancelled;

    // User could select PUE or PPD
    if (checkboxesSelected >= 1) {
      wasCancelled = await cancelIncoming(uuids, pues);
    } else {
      // Is a movement with just only concepts
      wasCancelled = await cancelIncomingConcepts(idMovement);
    }

    if (wasCancelled) onSuccess();

    setState((current) => ({
      ...current,
      isWaitingServerResponse: false,
    }));
  }

  function textReverse() {
    if (state.complements.length <= 0 && state.associations.length <= 0)
      return "Cancelar";

    const userSelectedAllComplements =
      state.complements.length ===
      Object.keys(state.complementsSelected).length;
    const userSelectedAllPues =
      state.associations.length === Object.keys(state.puesSelected).length;

    if (userSelectedAllComplements && userSelectedAllPues) return "Cancelar";

    return "Des asociar";
  }

  return {
    ...state,
    openModal,
    closeModal,
    handleCheckComplement,
    isComplementSelected,
    handleCheckConcept,
    attemptCancelIncoming,
    canDisAssociateOrCancel: canDisplayDisassociate(),
    textReverse: textReverse(),
    handleCheckPue,
  };
}
