import React from "react";
import Dialog from "structure/Dialog";
import { Context2fa } from "..";
import { useContext } from "react";
import style from "../styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import OtpInput from "react-otp-input";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ModalQr() {
  const hook = useContext(Context2fa);

  return (
    <Dialog
      forwardRef={hook.dialog.refDialog}
      closeCallback={hook.customCloseQr}
      title="2FA"
      footer={
        <div className={style.containerButton}>
          {!hook.isPerformingAbc ? (
            <ButtonV2
              onClick={hook.attemptAddDevice}
              disabled={hook.otp.length <= 5}
            >
              <span>Enviar código</span>
              <Icon icon="send" />
            </ButtonV2>
          ) : (
            <Spinner
              text={<LoadingText>Registrando 2FA</LoadingText>}
              hidden={false}
            />
          )}
        </div>
      }
    >
      <div className={style.qrCodeContainer}>
        <canvas ref={hook.canvas}></canvas>
      </div>

      <div className={style.otpContainer}>
        <p>Código de verificación</p>
        <OtpInput
          inputType="number"
          value={hook.otp}
          onChange={hook.setOtp}
          numInputs={6}
          inputStyle={{
            width: "2.5rem",
          }}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>

      <p className="font-weight-bold text-center my-3">
        ❗ Si ya existe una opción "Praxia" en el dispositivo, favor de elimianr
        la opción para dar de alta el nuevo QR y evitar errores/confusiones
      </p>

      <p className="font-weight-bold text-center my-3">
        ❗ Es importante que tu dispositivo móvil tenga configurada la hora
        sincronizada y no desfasada
      </p>

      <hr />

      <ol className={style.instructions2fa}>
        <li>
          Descargar "Microsoft Authenticator".
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.microsoft.com/es-mx/security/mobile-authenticator-app#overview"
          >
            Esta liga tiene la descarga de la app
          </a>
        </li>
        <li>Abrir la aplicación</li>
        <li>En la parte superior derecha, presionar el símbolo de ➕</li>
        <li>Presionar "Cuenta personal" en las opciones de sesión</li>
        <li>Presionar "Escanear código QR"</li>
        <li>Preionsar "Praxia", la opcion agregada en MS Authenticator</li>
        <li>Escribir los 6 dígitos mostrados.</li>
        <li>
          Presionar "Enviar código" para confirmar la cuenta y dispositivo con
          2FA
        </li>
      </ol>
    </Dialog>
  );
}
