import React, { useContext, useEffect, useState } from "react";

// COMPONENTS
import Modal from "../../individual/Modals/Modal";
import { ContainerUpload } from "./Styles";

// HELPERS
import { Success ,Error } from "../../../helpers/alerts";

// LIBS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import byteSize from "byte-size";
import { v4 as uuid } from "uuid";

import { useParams } from "react-router-dom";

// APIS
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

// CONTEXT
import LoginContext from "../../../context/Login/LoginContext";
import {
  AssociateFilesDb,
  GetBlobCred,
} from "../../../helpers/Apis/associateFiles";

/**
 * Upload files to azure blob storage (file names will be the same as the user goes uploading)
 * 
 * @param {object} props - Props component
 * @param {string} props.path - Path to upload the file on blob storage
 * @param {string[]?} props.validExtensions - File extensions that can be used to upload
 * @param {Function?} props.onUploaded - Function that will be executed after files were saved
 * @param {number} props.limitFile - Number of limit files user can upload
 * @param {number} props.maxLongName - Max number of characters that the file can have
 * @returns {JSX.Element} Modal to upload files on Azure Blob Storage
 * @example
 * 
 * <UploadFile 
        path = '/Documentos/564',
        validExtensions = ["pdf","xlsx","png"]
        onUploaded = {()=>{
            console.log("Upload finish, i can prompt an alert or something :)");
        }}
    />
 */
export default function UploadFile({
  path = null,
  validExtensions = ["pdf", "xml", "png", "jpg", "jpeg"],
  onUploaded = () => {},
  limitFile = 5,
  maxLongName = 50,
  idButton = 'uploadFiles'
}) {
  const addIcon = <FontAwesomeIcon icon={faPlusCircle} />;
  const deleteIcon = <FontAwesomeIcon icon={faTimesCircle} />;

  const { userInfo } = useContext(LoginContext);

  // State to manage the files that are being manipulated
  const [files, setFiles] = useState({
    fileInstances: [],
    informative: [],
  });

  /**
   * State textButton
   * @type {[string,Function]}
   */
  const [textButton, setTextButton] = useState(null);

  useEffect(() => {
    if (files.informative.length === 0) {
      setTextButton(null);
    } else {
      setTextButton("Subir");
    }
  }, [files]);

  const { idDocument } = useParams();

  /**
   * State to manage the spinner when it's uploading the files
   * @type {[boolean,Function]}
   */
  const [isUploading, setIsUploading] = useState(false);

  /**
   * Blob credentials
   * @type {[BlobCred,Function]}
   */
  const [blobCredentials, setBlobCredentials] = useState(null);

  // Load blob credentials
  useEffect(() => {
    (async function () {
      const apiBlobCred = await GetBlobCred();
      setBlobCredentials(apiBlobCred);
    })();
  }, []);

  const uploadFiles = async () => {
    try {
      setIsUploading(true);

      // Get sas link from azure
      const blobSasUrl = `${blobCredentials.urlStorage}?${blobCredentials.sasUrl}`;

      // Resources to learn: https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/javascript/basic.js
      const blobServiceClient = new BlobServiceClient(blobSasUrl);

      const containerName = `${blobCredentials.containerName}${path}`;

      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      // Create a queu of syncronus code to save on azure
      const blobsQueu = files.fileInstances.map((file, i) => {
        return (async function () {
          const blobClient = await containerClient.getBlockBlobClient(
            `${files.informative[i].uuidFileName}.${files.informative[i].extension}`
          );
          const response = await blobClient.upload(
            files.fileInstances[i],
            files.fileInstances[i].size
          );
        })();
      });

      // Was uploaded ?
      const uploaded = await Promise.all(blobsQueu).then(blobResults=>{
        return{
          query:true
        }
      }).catch(error=>{

        console.log(`%c${error}`,'color:red;')

        return{
          query:false,
          error
        }
      });

      // Not uploaded, finish 
      if(!uploaded.query){
        setIsUploading(false);
        Error(()=>{},uploaded.error);
        return;
      }

      // Uploaded, continue

      const uploadedOnDb = await AssociateFilesDb({
        uploadedBy: userInfo[0].fullName,
        idDocument: +idDocument,
        files: files.informative,
      })

      setIsUploading(false);

      if(uploadedOnDb){
        Success(() => {}, "Archivos subidos");
        resetFiles();
        onUploaded();
        return;
      }
      console.log(files.informative);
      Error(()=>{},'El archivo no pudo ser subido');


    } catch (error) {
      setIsUploading(false);
    }
  };

  /**
   *
   * @param {File[]} filesInput - Files of the input
   */
  const addToStore = (filesInput) => {

    // Limit files reached, stop execution
    if ((files.informative.length > limitFile) === true) return;

    const noItems = Object.keys(filesInput.files).length;

    let instanceFiles = [];
    let informativeFiles = [];

    console.log(instanceFiles);
    console.log(informativeFiles);
    // Add them to the state
    for (let i = 0; i < noItems; i++) {

      // Just add the "remain space"
      if ((files.informative.length+(i+1)) <= limitFile) {
        // Get the item of the iteration
        const item = filesInput.files.item(i);

        // Get the extension file
        const extPosition = item.name.lastIndexOf(".");
        const extension = item.name.substring(
          extPosition + 1,
          item.name.length
        );

        // Evaluate it's a valid format, true then add it
        if (validExtensions.includes(extension)) {
          instanceFiles.push(filesInput.files.item(i));

          const uuidFile = uuid();
          const fileName = item.name.substring(0, extPosition);

          informativeFiles.push({
            mimeType: item.type,
            size: byteSize(item.size),
            fileName:fileName.substring(0,maxLongName),
            uuidFileName: uuidFile,
            extension,
            urlFile: `${blobCredentials.urlStorage}/${blobCredentials.containerName}${path}/${uuidFile}.${extension}`,
          });
        }
      }
    }

    console.log(files.informative)
    
    // Update the state
    setFiles({
      fileInstances: [...instanceFiles, ...files.fileInstances],
      informative: [...informativeFiles, ...files.informative],
    });
  };

  /**
   * Empty the state of the files
   */
  const resetFiles = () => {
    setFiles({
      fileInstances: [],
      informative: [],
    });
  };

  /**
   * Delete the file from the state
   * @param {number} positionFile - Index position of the array element to delete
   */
  const deleteFile = (positionFile) => {
    // Copy without reference of state to be able of mutation
    const copyState = { ...files };

    // Delete object "File" instance and the informative info
    copyState.fileInstances.splice(positionFile, 1);
    copyState.informative.splice(positionFile, 1);
 

    // Update the state
    setFiles(copyState);
    
  };

  /////////////////////////////////////////////////////

  const [inputExtensions,setInputExtensions] = useState('.*');

  useEffect(()=>{
    const extensionsAttribute = validExtensions.reduce((acumulator,extension) => acumulator+=`.${extension} ,` ,'');

    setInputExtensions(extensionsAttribute);
  },[]);

  return (
    <Modal
      accpet={textButton}
      idOpenModal = 'addFiles'
      close="Cancelar"
      buttonText="Agregar"
      title="Carga de archivos"
      subtitle={`(max. ${limitFile})`}
      cbAccept={async () => uploadFiles()}
      cbReject={resetFiles}
      idFooter="footerUploadFiles"
      showSpinner={isUploading}
      textSpinner="Subiendo archivo(s)..."
      
    >
      <ContainerUpload
        limitReached = { files.informative.length === limitFile ? true : false }
        >
        {files.informative.length === limitFile ? null : (
          <div className="infoUpload">
            <div className="containerUpload">
              <label htmlFor="uploadFiles">{addIcon}</label>
            </div>
          </div>
        )}

        <div className="fileItems">
          {files.informative.map((file, i) => (
            <div className="fileItem">
              <button onClick={() => deleteFile(i)}>{deleteIcon}</button>

              <p>
                <span>{file.extension} | </span>
                <span>
                  {file.size.value}
                  {file.size.unit} |{" "}
                </span>
                <span>{file.fileName}</span>
              </p>

              <hr />
            </div>
          ))}
        </div>
      </ContainerUpload>

      <input
        type="file"
        multiple
        id="uploadFiles"
        className="d-none"
        accept = {inputExtensions}
        onChange={() => addToStore(document.getElementById("uploadFiles"))}
      />

      <hr />

      <span className="text-danger font-weight-bold">
        Extensiones validas:{" "}
      </span>
      {validExtensions.map((extension, i) => (
        <span>
          {extension}{i === validExtensions.length - 1 ? null : " | "}
        </span>
      ))}
    </Modal>
  );
}
