/**
 *
 * @param {Array<number>} ids Permissions to be evaluated to display or grant access
 * @param {Array<object>} permissions All permissions of the logged user
 * @returns {boolean} If true, means has access/permission
 */
export function evaluatePermission(ids, permissions) {
  return true;

  let numberPermissions = 0;

  ids.map((element) => {
    const result = permissions.find(
      (permission) => permission.sectionID === element
    );

    if (result !== undefined) {
      numberPermissions += result.permissionStatus;
    }
  });

  if (numberPermissions === 0) {
    return false;
  }

  return true;
}

/**
 *
 * @param {number} id Id of the permission to extract his description
 * @param {Array<object>} permissions List of the permissions
 * @returns {string} Text to be render on the UI
 */
export function textPermission(id, permissions) {
  const text = permissions.find((permission) => permission.sectionID === id);

  if (text) {
    return `${text.description}`;
  }

  return `?`;
}

export function displayDropMenu(idsChildren, permissions) {
  idsChildren.map((idButton) => {
    const queryLength = permissions.map(
      (permission) => permission.idButton === idButton
    );

    if (queryLength > 0) {
      return true;
    }
  });

  return false;
}
