import React, { useContext, useRef } from "react";
import ReactQuill from "react-quill";
import ui from "./styles.module.scss";
import { ContextCommentOc } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import AddA from "../AddA";
import AddB from "../AddB";

export default function TextBox() {
  const hook = useContext(ContextCommentOc);

  const windowWidth = useRef(
    window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  );

  if (hook.isCommenting)
    return (
      <div className="d-flex justify-content-end align-items-center">
        <Spinner text={"Comentando"} hidden={false} />
      </div>
    );

  return (
    <div className={ui.boxComment}>
      <input
        type="text"
        className={ui.mobileInput}
        placeholder="Escribe aquí"
        value={hook.mobileComment}
        onChange={(e) => {
          hook.setComment(e.target.value);
        }}
      />

      {windowWidth.current >= 768 ? (
        <ReactQuill
          preserveWhitespace
          className={ui.quill}
          placeholder="Escribe aquí"
          value={hook.comment}
          onChange={(content) => hook.setComment(content)}
        />
      ) : null}

      <div className={ui.commentOptions}>
        <AddA />
        <AddB />
      </div>
    </div>
  );
}
