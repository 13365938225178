import React from "react";
import { useContext } from "react";
import { ContextHistoricalContracts } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Warning } from "helpers/alerts";
import Icon from "components/individual/HeaderIcon";

export default function ExcelButton() {
  const hook = useContext(ContextHistoricalContracts);

  if (hook.isDownloadingExcel)
    return (
      <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
    );

  return (
    <ButtonV2 disabled={hook.isLoading} onClick={hook.attemptDownloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
