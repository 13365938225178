import { clabe } from "clabe-validator";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Parse the error to spanish
 * @param {import("./types").ErrorCLABE} error
 */
function translateErrorMessage(error) {
  switch (error) {
    case "invalid-length":
      return "Deben ser 18 dígitos exactos";

    case "invalid-bank":
      return "Código de banco inválido";

    case "invalid-characters":
      return "Solo se permiten números";

    case "invalid-checksum":
      return "Suma de comprobación inválido";

    case "invalid-city":
      return "Código de ciudad inválido";
    default:
      return error;
  }
}

/**
 * Validate the CLABE of a bank account
 * @param {string} CLABE - CLABE
 * @returns {import("clabe-validator").ClabeCheck}
 */
export default function useClabe(CLABE = "") {
  const [validations, setValidations] = useState(undefined);

  useEffect(() => {
    const tempValidations = clabe.validate(CLABE);

    setValidations({
      ...clabe.validate(CLABE),
      message: translateErrorMessage(tempValidations.error),
    });
  }, [CLABE]);

  return validations;
}
