import React from "react";
import { useContext } from "react";
import { ContextAttendInvoice } from "..";
import Partialities from "structure/Timbrar/Partialities";

export default function PartialitiesApproved() {
  const { isValid, approved, updatePartialitiesApproved } =
    useContext(ContextAttendInvoice);

  return !isValid.partialities ? (
    <div className="mr-2">
      <Partialities
        value={approved.partialities}
        onChange={updatePartialitiesApproved}
      />
    </div>
  ) : null;
}
