import React from "react";
import { Label } from "structure/Document";
import style from "../styles.module.scss";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { useContext } from "react";
import { ContextUenAdmin } from "../..";

/**
 *
 * @param {import('types/typedef/catalogue').UenI} uen
 * @returns
 */
export default function MobileUen(uen) {
  const hook = useContext(ContextUenAdmin);

  return (
    <div className={style.uenMobile}>
      <div className="d-flex justify-content-between">
        <div>
        <Label>Descripción</Label>
        <p>{uen.description}</p>
        </div>

        <ElipsisMobile onShow={() => hook.setUen(uen)}>
          <CustomMenuItem onClick={() => hook.handleEditModal(true)}>
            Editar
          </CustomMenuItem>
        </ElipsisMobile>
      </div>

      <Label>Familia/Sub-familia</Label>
      <p>
        {uen.family}
        <span className="mx-1">&#47;</span>
        {uen.subFamily}
      </p>

      <Label>Código SAT</Label>
      <p>
        {uen.SATcode} - {uen.satCodeDescription}
      </p>

      <Label>UM SAT</Label>
      <p>
        {uen.SATUM} - {uen.satUmDescription}
      </p>

      <div className="d-flex justify-content-between">
        <div>
          <Label>IVA</Label>
          <p>{uen.iva}%</p>
        </div>
        <div>
          <Label>Excento</Label>
          <p>{uen.iva !== 0 ? "NA" : uen.isExempt ? "Si" : "No"}</p>
        </div>
        <div>
          <Label>Márgen</Label>
          <p>{uen.marginRate}%</p>
        </div>

        <div>
          <Label>Estatus</Label>
          <p>{uen.status ? "Activo" : "Inactivo"}</p>
        </div>
      </div>
    </div>
  );
}
