import styled from "styled-components";

export const ContainerPartialities = styled.div`
  overflow-y: auto;
  margin:1rem 0 0 0;

  /////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

  @media (max-width: 767px) {
    max-height: 100%;
    overflow: auto;
  }
`;

export const MethodCalculate = styled.div`
  display: flex;

  .radioBtnElement {
    margin: 0 5px;
    display: flex;
    align-items: center;
  }
`;
