import React, { useContext } from "react";
import { RangeFilter } from "../../../components/individual/Calendar/Calendar";
import {
  Button,
  RedirectButton,
} from "../../../components/individual/buttons/Buttons";

import { Link } from "react-router-dom";
import { useParams } from "react-router";
import MovementsContext from "context/Movements/MovementsContext";

import TableContext from "../../../context/Table/TableContext";
import Options from "./Options";
import StatusMovement from "components/general/Forms/Selects/StatusMovement";
import OptionsEgress from "./EgressOptions/OptionsEgress";
import usePermissions from "customHooks/usePermissions";
import { DateRange } from "components/individual/inputs/DateRange";
import { Label } from "structure/Document";

/**
 * Filter search
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} props.movement - Information of the movement
 * @returns {JSX.Element}
 */
export default function Search({ begin, end, movement }) {
  const { refetch, setReload } = useContext(TableContext);
  const { filterMovements, setRangeDate } = useContext(MovementsContext);

  const { account } = useParams();
  const { checkPermission } = usePermissions(
    "30e4099b-5b86-4a1d-a725-fbb4bfbc3efa"
  );

  return (
    <>
      <form className="d-flex mb-4 justify-content-between" id="filterOptions">
        <div className="d-flex align-items-end ">
          {/* <RangeFilter
            idModal="rangeMovements"
            beginRange={filterMovements.beginDate}
            endRange={filterMovements.endDate}
            onChange={(range) =>
              setRangeDate({
                begin: range.dataBegin,
                end: range.dataEnd,
              })
            }
          /> */}

          <div className="d-flex flex-column">
            <Label>Periodo</Label>
            <DateRange
              startDate={filterMovements.beginDate}
              endDate={filterMovements.endDate}
              initialDate={filterMovements.beginDate}
              onChange={(dates) => {
                setRangeDate({
                  begin: dates[0],
                  end: dates[1],
                });
              }}
              labelText={null}
              monthsShown={2}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>

          <StatusMovement />

          <Button
            id="btnSearch"
            text="Buscar"
            aditionalClasses="ml-2"
            funcion={() => setReload(!refetch)}
          />
        </div>

        <div className="d-flex align-items-end">
          {checkPermission("30e4099b-5b86-4a1d-a725-fbb4bfbc3efa") ? (
            <RedirectButton
              text="Agregar ingreso"
              link={`${+account}/ingreso-rapido`}
              css="mr-3"
            />
          ) : null}

          {checkPermission("e68ea8ae-a287-42e7-ab9f-5aabab774bb5") ? (
            <RedirectButton
              text="Agregar egreso"
              link={`${+account}/egreso-rapido`}
            />
          ) : null}
        </div>
      </form>
      <div className="mb-4 d-flex justify-content-start spacingOptions">
        {movement === null ? null : movement.movementType === 1 ? (
          <Options movementInfo={movement} />
        ) : (
          <OptionsEgress infoRow={movement} />
        )}
      </div>
    </>
  );
}
