import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";

export const FilesCreditNoteReceived = () => {
  const { idDocument } = useParams();

  return (
    <div style={{
      height: "100%",
      overflow:"auto",
      padding:"0 0 1rem 0"
    }}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/compras/notas-de-credito",
            text: "Compras",
          },
          {
            route: `/ventas/notas-de-credito`,
            text: "Notas de crédito",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]}
      />

      <div className="container">
        <AssociateFiles
          idRegister={+idDocument}
          idTypeEntity={2}
          pathUpload={`notasCredito/${idDocument}`}

        />
      </div>
    </div>
  );
};
