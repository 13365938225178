import { cancelCreditNote } from "helpers/Apis/Administracion/InvoiceEmitted/InvoiceEmittedApi";
import { YesNoAlert } from "helpers/alerts";
import { useState } from "react";

/**
 * @type {import("./types").StateCancelCreditNote}
 */
const INITIAL_STTE = {
  isCancelling: false,
  isCancellable: false,
};

/**
 * Custom hook to handle the cancellation of the credit notes
 * @param {import("./types").ParamCancelCreditNote} param
 * @returns
 */
export default function useCancelCreditNote({
  idFacturamaCreditNote = null,
  uuidCreditNote = null,
  onCancelated = () => {},
  status = null,
}) {
  const [state, setState] = useState({
    ...INITIAL_STTE,
    isCancellable: status === null || status === 15 ? false : true,
  });

  const performCancellation = async () => {
    setState((current) => ({
      ...current,
      isCancelling: true,
    }));

    const wasCancelled = await cancelCreditNote(
      idFacturamaCreditNote,
      uuidCreditNote
    );

    if (wasCancelled) {
      onCancelated();
    }

    setState((current) => ({
      ...current,
      isCancelling: false,
    }));
  };

  const attemptCancelComplement = async () => {
    YesNoAlert("¿Deseas cancelar la nota de crédito?", performCancellation);
  };

  return {
    ...state,
    attemptCancelComplement,
  };
}
