import React, { useRef } from "react";
import style from "./styles.module.scss";

/**
 * Render a dialog html element
 * @param {import('./types').PropsDialog} props - Props
 * @returns {JSX.Element} Render a dialog html element
 * @example
 * import React from "react";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import ButtonV2 from "components/individual/ButtonsV2/Button";

function Inicio() {
  const dialog = useDialog();

  return (
    <div>
      <Dialog
        footer={<ButtonV2>Ok</ButtonV2>}
        closeCallback={dialog.close}
        forwardRef={dialog.refDialog}
      >
        <p>Hello world</p>
      </Dialog>

      <ButtonV2 onClick={dialog.open}>Open</ButtonV2>
    </div>
  );
}

export default Inicio;

 */
export default function Dialog({
  title = "",
  footer = null,
  children = <></>,
  width = 50,
  forwardRef,
  height = null,
  id = null,
  closeCallback = () => {},
}) {


  const idModal = useRef(typeof id !== 'string' ? window.crypto.randomUUID() : id);

  return (
    <dialog
      id={idModal.current}
      onKeyDown={(e) => {
        if (e.code === "Escape") closeCallback();
      }}
      className={style.dialog}
      ref={forwardRef}
      style={{
        width: `${width}vw`,
      }}
    >
      <div className={style.dialogTitle}>
        <p className="my-0">{title}</p>
        <button type="button" onClick={closeCallback}>
          <svg
            onClick={closeCallback}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className={style.dialogBody}>{children}</div>

      {footer === null ? null : (
        <div className={style.dialogFooter}>{footer}</div>
      )}
    </dialog>
  );
}
