import React from "react";
import { useContext } from "react";
import { ContextReceiveCreditNote } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { openOnNewTab } from "helpers/dom";

export default function Step1Footer() {
  const hook = useContext(ContextReceiveCreditNote);

  if (hook.xml === undefined) return <></>;

  if (!hook.xml.isValid) return <></>;

  if (!hook.canAddXml) return <></>;

  return (
    <div className="d-flex justify-content-end">
      {hook.xml.pdf.file !== null ? (
        <ButtonV2
          className="mr-3"
          onClick={(e) => openOnNewTab(URL.createObjectURL(hook.xml.pdf.file))}
        >
          Ver pdf
        </ButtonV2>
      ) : null}

      <ButtonV2 onClick={(e) => hook.setStep(1)}>Continuar</ButtonV2>
    </div>
  );
}
