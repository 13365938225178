import React, { useContext, FC, useState } from "react";
import { InputText } from "../../../../components/individual/inputs/Inputs";
import { Button, Submit } from "../../../../components/individual/buttons/Buttons";
import '../../../../types/typedef/documents';

// COMPONENTS
import Comment from "./Comment";

// CONTEXT
import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";

// LIBS
import { v4 as uuidv4 } from "uuid";
import { ReactSortable } from "react-sortablejs";
import { useParams } from 'react-router';

// HELPERS
import { updateComment } from '../../../../helpers/Directory/Documents/comments';

function Comments() {

  const { action } = useParams();

  const {
    comments, 
    setComments, 
    isEditingComment, 
    setEdit, 
    setIsSorting } = 
    useContext(AddDocumentContext);
  
  /**
   * Input to write/edit the comments
   * 
   * @type {HTMLElement}
   */
  const comment = document.getElementById("inputAddComment");
  
  const cancelEdit = () => {
    setEdit(false);
    comment.value = '';
  }

  const addComment = (e) => {
    e.preventDefault();

    if (comment.value.length === 0) return;

    /**
     * Memory id in order to operate the comment in the front-end
     * 
     * @type {string}
     */
    const id = uuidv4();

    // User editing the document and trys to add a new comment
    if(action=='editar' && !isEditingComment){
      /**
       * @type {Comments}
       */
      const newListComments = [...comments];
      newListComments.push({
        name: comment.value,
        id,
        isNewComment:true
      });

      setComments(newListComments);
      comment.value = "";

      return;
    }

    // USER IS ADDING A COMMENT
    if (isEditingComment === false) {

      /**
       * @type {Comments}
       */
      const newListComments = [...comments];
      newListComments.push({
        name: comment.value,
        id,
        isNewComment:false
      });

      setComments(newListComments);
    }

    // USER IS EDITING THE COMMENT
    else {
      const updatedComment = updateComment(isEditingComment, comments, comment.value);
      setComments(updatedComment);
      setEdit(false);
    }
    
    comment.value = '';
  };

  return (
    <>
      <div className="container">
        <form className="pt-4" onSubmit={(e) => addComment(e)}>
          <div className="row d-flex align-items-end">
            <InputText
              id="inputAddComment"
              htmlFor="inputAddComment"
              name="inputAddComment"
              labelTxt="Comentario"
              aditionalClasses="col-8"
            />

            <Submit
              id="addNewComment"
              aditionalClasses="fitBtn"
              text={typeof (isEditingComment) !== 'boolean' ? 'Actualizar' : 'Agregar'}
            />

            {typeof (isEditingComment) !== 'boolean' ?
              <Button
                id='cancelEdition'
                text='Cancelar'
                aditionalClasses='ml-4'
                funcion={cancelEdit}
              />
              :
              null
            }
          </div>
        </form>

        <div className="container queueContainer">
          <ReactSortable
            onChoose={() => setIsSorting()}
            onUnchoose = {()=>setIsSorting()}
            list={comments}
            setList={setComments}>
            {comments.map((item) => {
              return <Comment id={item.id} comment={item.name} isNewComment = {item} />;
            })}
          </ReactSortable>
        </div>
      </div>
    </>
  );
}

export default Comments;
