import { Select } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetStatusDocument } from "helpers/Apis/Documents";
import React, { useEffect, useState } from "react";

/**
 * Status to render for an specific document
 * @param {object} props - Props
 * @param {(id:number)=>void} [props.onChange=()=>{}] - Id of the status to fetch
 * @param {()=>void} [props.onLoaded=()=>{}] - Callback executed when status are loaded
 * @param {number} [props.idTypeDocument=null] - Id of the type document to render the status
 * @param {number} [props.value=null] - Default value when the combo renders
 * @returns {JSX.Element} Combo with the document status
 */
export default function StatusDocuments({
  idTypeDocument = null,
  onLoaded = () => {},
  onChange = () => {},
  value = null,
}) {
  const [status, setStatus] = useState({
    //Cotizacion
    1: [],

    //Prefactura
    2: [],

    //OC
    3: [],

    //CxP
    4: [],

    //CxC
    5: [],

    //Contrato
    6: [],

    //Ordenes de pago
    7: [],

    //Servicios recibidos
    8: [],
  });

  useEffect(() => {
    (async function () {
      if (idTypeDocument === null) return;

      if (status[idTypeDocument].lengt >= 1) return;

      const apiStatus = await GetStatusDocument(idTypeDocument);

      onLoaded(null);

      setStatus({
        ...status,
        [idTypeDocument]: [
          {
            state: null,
            document: null,
            description: "Todos",
            order: 0,
            logicalErase: 0,
          },
          ...apiStatus,
        ],
      });
    })();
  }, [idTypeDocument]);

  return (
    <>
      {idTypeDocument === null ? (
        <p>Error</p>
      ) : status[idTypeDocument].length <= 0 ? (
        <Spinner
          text="Cargando estatus..."
          hidden={false}
          idSpinner="loadingStatusDocument"
        />
      ) : (
        <Select
          idSelect="statusDocument"
          label="Estatus"
          addTodos={false}
          options={status[idTypeDocument]}
          valueJSON="state"
          textJSON="description"
          value={value}
          onChange={(id) => (isNaN(+id) ? onChange(null) : onChange(+id))}
        />
      )}
    </>
  );
}
