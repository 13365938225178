import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextEditRol } from "..";

export default function ContinueButton() {
  const { updateRol, isUpdatingRol } = useContext(ContextEditRol);
  return isUpdatingRol ? null : (
    <ButtonV2 onClick={updateRol}>Actualizar rol</ButtonV2>
  );
}
