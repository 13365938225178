import {
  addDocument,
  getDocumentData,
  handleFormItem,
  transformToNumberAndCurrency,
} from "components/Sections/Documents/helpers/documentHelper";
import { GetTCV2 } from "helpers/Apis/Banks";
import {
  GetDocument,
  getDocumentsExpensesConcepts,
  getDocumentsIncomeConcepts,
} from "helpers/Apis/Documents";
import { dateAtCeroHours, substractDays } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const initialAmount = transformToNumberAndCurrency(0, 2);

const indexed = {
  ["MXN"]: {
    id: 1,
    value: "MXN",
  },
  ["USD"]: {
    id: 2,
    value: "USD",
  },
};

const amounts = {
  importCost: initialAmount,
  importSell: initialAmount,
  ivaCost: initialAmount,
  ivaSell: initialAmount,
  margin: initialAmount,
  totalCost: initialAmount,
  totalSell: initialAmount,
};

/**
 * @type {import("./types").StateDocument}
 */
const INITIAL_STATE = {
  document: undefined,
  loadedDocument:false,
  documentConcepts: {
    concepts: [],
    currentConcept: {
      id: 7,
      description: "Ventas #Factura",
      defaultToDocument: false,
      value: 7,
      label: "Ventas #Factura",
    },
  },
  tc: null,
  contact: {
    id: 0,
    anniversary: "",
    email: "",
    isCollectionContact: false,
    isPaymentContact: false,
    lastName1: "",
    lastName2: "",
    middleName: "",
    name: "",
    workTitle: "",
  },
  interfaceControl: {
    disableTc: false,
    disableCurrency: false,
    disableTotals: false,
  },
  generateContract: documentInitialDate(null),
  itemsToEdit: [],
  newItems: [],
  comments: [],
  contrtactKey: "",
  concepts: [],
  items: [],
  isLoading: true,
  moneyInfo: {
    tc: {
      number: 0,
      text: "$0.00",
    },
    probability: {
      id: 1,
      value: "<50%",
    },
    currency: {
      id: 0,
      value: "",
    },
    mxn: amounts,
    usd: amounts,
  },
  isUpdating:false,
  customer: {
    comertialName: "",
    rfc: "",
    id: 0,
    isMexican: true,
    shortName: "",
    socialRazon: "",
  },
};

/**
 * Get the initial dates of the document
 * @param {number} documentType - Id of the document type in order to calculate the dates of the document
 */
function documentInitialDate(documentType) {
  if (typeof documentType !== "number")
    return {
      generateContract: false,
      beginMinDate: "",
      beginMaxDate: "",
      endMinDate: "",
      endMaxDate: "",
      reminderMinDate: "",
      reminderMaxDate: "",
      beginDate: "",
      endDate: "",
      reminderDate: "",
    };

  const today = dateAtCeroHours(new Date());
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const lastDayOfTheMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );

  switch (documentType) {
    case 1:
      return {
        generateContract: true,
        beginMinDate: tomorrow,
        beginMaxDate: tomorrow,
        endMinDate: today,
        endMaxDate: lastDayOfTheMonth,
        reminderMinDate: tomorrow,
        reminderMaxDate: lastDayOfTheMonth,
        beginDate: tomorrow,
        endDate: lastDayOfTheMonth,
        reminderDate: tomorrow,
      };
    case 2:
      return {
        generateContract: true,
        beginMinDate: dateAtCeroHours(substractDays(new Date(), 0)),
        beginMaxDate: today,
        endMinDate: dateAtCeroHours(substractDays(new Date(), 0)),
        endMaxDate: "",
        reminderMinDate: today,
        reminderMaxDate: today,
        beginDate: today,
        endDate: today,
        reminderDate: today,
      };
    case 3:
      return {
        generateContract: true,
        beginMinDate: today,
        beginMaxDate: today,
        endMinDate: today,
        endMaxDate: "",
        reminderMinDate: today,
        reminderMaxDate: "",
        beginDate: today,
        endDate: today,
        reminderDate: today,
      };
    case 6:
      return {
        generateContract: true,
        beginMinDate: "",
        beginMaxDate: "",
        endMinDate: today,
        endMaxDate: "",
        reminderMinDate: tomorrow,
        reminderMaxDate: "",
        beginDate: today,
        endDate: today,
        reminderDate: tomorrow,
      };

    default:
      return {
        generateContract: false,
        beginMinDate: "",
        beginMaxDate: "",
        endMinDate: "",
        endMaxDate: "",
        reminderMinDate: "",
        reminderMaxDate: "",
        beginDate: "",
        endDate: "",
        reminderDate: "",
      };
  }
}

/**
 * Handle the creation of documents on praxia (any kind of document)
 * @param {import("./types").ParamUseDocumentsPraxia} param0
 * @returns {import("./types").ReturnUseDocumentsPraxia}
 */
export default function useDocumentPraxia({
  idDocument = null,
  documentType,
  modulo = null,
  idCustomer = null,
}) {
  const [state, setState] = useState({
    ...INITIAL_STATE,
    generateContract: documentInitialDate(documentType),
  });

  /**
   *
   * @param {import("structure/FormDocumentItems/types").DocumentItemForm} item - Information of the item
   */
  const addItem = (item) => {
    const calculatedItem = handleFormItem(
      {
        cost: item.cost,
        currency: item.currency,
        description: item.description,
        discountCost: item.discountCost,
        discountSell: item.discountSell,
        idCatalogue: item.idCatalogue,
        iva: item.iva,
        ivaExempt: item.ivaExempt,
        quantity: item.quantity,
        satCode: item.satCode,
        satUm: item.satUm,
        sell: item.sell,
        sku: item.sku,
        uen: item.uen,
        satCodeDescription: `${item.satCodeDescription}`,
        satUmDescription: `${item.satUmDescription}`,
      },
      state.moneyInfo.tc.number,
      2,
      {
        rfc: state.customer.rfc,
        typeDocument: 1,
      }
    );

    const isNewItem = typeof item.idCatalogue === "number" ? false : true;
    const itemToAdd = {
      ...calculatedItem,
      uuid: window.crypto.randomUUID(),
    };

    setState((current) => ({
      ...current,
      moneyInfo: {
        ...current.moneyInfo,
        currency:
          current.items.length === 0
            ? indexed[item.currency]
            : current.moneyInfo.currency,
      },
      items: [...current.items, itemToAdd],
      newItems: isNewItem ? [...current.newItems, itemToAdd] : current.newItems,
    }));
  };

    /**
   *
   * @param {import("structure/FormDocumentItems/types").DocumentItemForm} item - Information of the item
   * @param {number} index - Index of the item to edit
   */
    const editItem = (item,index) => {

      const calculatedItem = handleFormItem(
        {
          cost: item.cost,
          currency: item.currency,
          description: item.description,
          discountCost: item.discountCost,
          discountSell: item.discountSell,
          idCatalogue: item.idCatalogue,
          iva: item.iva,
          ivaExempt: item.ivaExempt,
          quantity: item.quantity,
          satCode: item.satCode,
          satUm: item.satUm,
          sell: item.sell,
          sku: item.sku,
          uen: item.uen,
          satCodeDescription: `${item.satCodeDescription}`,
          satUmDescription: `${item.satUmDescription}`,
        },
        state.moneyInfo.tc.number,
        2,
        {
          rfc: state.customer.rfc,
          typeDocument: 1,
        }
      );

      let newItems = [...state.items];

      newItems[index] = calculatedItem;

      const { items , totals } = recalculate(newItems)
  
      setState((current) => ({
        ...current,
        items,
        moneyInfo:{
          ...current.moneyInfo,
          ...totals
        }
      }));
    };

  /**
   * Retrieve concepts of the document
   * @returns {Promise<import("../../../../types/documentActions").Concepts[]>}
   */
  async function loadDocumentConcepts() {
    try {
      if (documentType === 3) {
        const { status, data } = await getDocumentsExpensesConcepts();

        return status === 200 ? data : [];
      } else {
        const { status, data } = await getDocumentsIncomeConcepts();
        return status === 200 ? data : [];
      }
    } catch (error) {
      return [];
    }
  }

  /**
   * Handle the amounts of the document items, also, this can retrieve the totals of the document
   * @param {import("components/Sections/Documents/Items/types").CatalogueOperation[]} [items=[]] Items
   */
  const recalculate = (items = []) => {
    const currentItems = items.length <= 0 ? [...state.items] : items;

    const recalculatedAmounts = currentItems.map((item) =>
      handleFormItem(
        {
          cost: item.cu.number,
          currency: item.currency.code,
          description: item.description,
          discountCost: item.providerDiscount.number,
          discountSell: item.clientDiscoount.number,
          idCatalogue: item.catalogue.id,
          iva: item.iva.number,
          ivaExempt: item.iva.exempt,
          quantity: item.quantity,
          satCode: item.satCode,
          satUm: item.satUm,
          sell: item.pu.number,
          sku: item.sku,
          uen: item.uen.id,
          satCodeDescription: `${item.satCodeDescription}`,
          satUmDescription: `${item.satUmDescription}`,
        },
        state.moneyInfo.tc.number,
        2,
        {
          rfc: state.customer.rfc,
          typeDocument: documentType,
        }
      )
    );

    const initialTotals = {
      importCost: 0,
      importSell: 0,
      ivaCost: 0,
      ivaSell: 0,
      margin: 0,
      totalCost: 0,
      totalSell: 0,
    };

    const totals = recalculatedAmounts.reduce(
      (totals, item) => {
        return {
          usd: {
            importCost: totals.usd.importCost + item.usd.cu.total.number,
            importSell: totals.usd.importSell + item.usd.pu.total.number,
            ivaCost: totals.usd.ivaCost + item.usd.cu.totalIva.number,
            ivaSell: totals.usd.ivaSell + item.usd.pu.totalIva.number,
            margin: totals.usd.margin + (item.usd.pu.realUnit.number - item.usd.cu.realUnit.number),
            totalCost:
              totals.usd.totalCost +
              item.usd.cu.total.number +
              item.usd.cu.totalIva.number,
            totalSell:
              totals.usd.totalSell +
              item.usd.pu.total.number +
              item.usd.pu.totalIva.number,
          },
          mxn: {
            importCost: totals.usd.importCost + item.mxn.cu.total.number,
            importSell: totals.usd.importSell + item.mxn.pu.total.number,
            ivaCost: totals.usd.ivaCost + item.mxn.cu.totalIva.number,
            ivaSell: totals.usd.ivaSell + item.mxn.pu.totalIva.number,
            margin: totals.usd.margin + (item.mxn.pu.realUnit.number - item.mxn.cu.realUnit.number),
            totalCost:
              totals.usd.totalCost +
              item.mxn.cu.total.number +
              item.mxn.cu.totalIva.number,
            totalSell:
              totals.usd.totalSell +
              item.mxn.pu.total.number +
              item.mxn.pu.totalIva.number,
          },
        };
      },
      {
        usd: initialTotals,
        mxn: initialTotals,
      }
    );

    return {
      items: recalculatedAmounts,
      totals: {
        usd: {
          importCost: transformToNumberAndCurrency(
            +totals.usd.importCost.toFixed(2)
          ),
          importSell: transformToNumberAndCurrency(
            +totals.usd.importSell.toFixed(2)
          ),
          ivaCost: transformToNumberAndCurrency(+totals.usd.ivaCost.toFixed(2)),
          ivaSell: transformToNumberAndCurrency(+totals.usd.ivaSell.toFixed(2)),
          margin: transformToNumberAndCurrency(+totals.usd.margin.toFixed(2)),
          totalCost: transformToNumberAndCurrency(
            +totals.usd.totalCost.toFixed(2)
          ),
          totalSell: transformToNumberAndCurrency(
            +totals.usd.totalSell.toFixed(2)
          ),
        },
        mxn: {
          importCost: transformToNumberAndCurrency(
            +totals.mxn.importCost.toFixed(2)
          ),
          importSell: transformToNumberAndCurrency(
            +totals.mxn.importSell.toFixed(2)
          ),
          ivaCost: transformToNumberAndCurrency(+totals.mxn.ivaCost.toFixed(2)),
          ivaSell: transformToNumberAndCurrency(+totals.mxn.ivaSell.toFixed(2)),
          margin: transformToNumberAndCurrency(+totals.mxn.margin.toFixed(2)),
          totalCost: transformToNumberAndCurrency(
            +totals.mxn.totalCost.toFixed(2)
          ),
          totalSell: transformToNumberAndCurrency(
            +totals.mxn.totalSell.toFixed(2)
          ),
        },
      },
    };
  };

  /////////////////

  useEffect(() => {
    (async function () {
      const [[tc], concepts] = await Promise.all([
        GetTCV2(),
        loadDocumentConcepts(),
      ]);

      const formatedConcept = concepts.map((concept) => ({
        ...concept,
        value: concept.id,
        label: concept.description,
      }));

      const tcToUsE = idDocument === null ? tc.enterprise.number : 0;

      setState((current) => ({
        ...current,
        tc,
        concepts: formatedConcept,
        moneyInfo: {
          ...current.moneyInfo,
          tc: transformToNumberAndCurrency(tcToUsE, 2),
        },
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (typeof idDocument !== "number") {
        setState(current=>({
          ...current,
          loadedDocument:true
        }))
        return;
      }

      const document = await GetDocument(idDocument);

      const overview = await getDocumentData(idDocument);
      
      const { totals } = recalculate(overview.data.items);

      setState((current) => ({
        ...current,
        document,
        loadedDocument:true,
        items:overview.data.items,
        moneyInfo:{
          ...current.moneyInfo,
          tc:transformToNumberAndCurrency(overview.data.moneyInfo.tc.number, 2),
          currency:indexed[overview.data.moneyInfo.currency.value],
          ...totals
        },
        customer:{
          ...current.customer,
          socialRazon: document.customer.socialReason,
          rfc: document.customer.rfc,
          id: document.customer.id,
          isMexican:document.customer.rfc !== 'XEXX010101000'
        }
      }));
    })();
  }, [idDocument]);

  useEffect(() => {
    const { items, totals } = recalculate(state.items);

    setState((current) => ({
      ...current,
      moneyInfo: {
        ...current.moneyInfo,
        mxn: totals.mxn,
        usd: totals.usd,
      },
    }));
  }, [state.items, state.moneyInfo.tc, state.moneyInfo.currency]);

  const setTc = (tc) =>
    setState((current) => ({
      ...current,
      moneyInfo: {
        ...current.moneyInfo,
        tc: {
          number: tc,
          text: mnCurrency(tc),
        },
      },
    }));

  /**
   * Add a new comment into document
   * @param {import("./types").CommentDocumentPraxiaI} comment - Comment to add
   * @returns {void}
   */
  const addComment = (comment) =>
    setState((current) => ({
      ...current,
      comments: [comment, ...current.comments],
    }));

  /**
   * Edit an item of the document
   * @param {import("components/Sections/Documents/Items/types").CatalogueOperation} item - Item to update, data must be already re-calculated
   * @param {number} index
   */
  const handleEditItemForm = (item, index) => {
    let newArray = [...state.items];

    newArray[index] = item;

    setState((current) => ({
      ...current,
      items: newArray,
    }));
  };

  /**
   *
   * @param {number} index - Index of the comment to delete
   */
  const handleOnDeleteComment = async (index) => {
    const { isDenied } = await Swal.fire({
      title: "Borrar comentario",
      text: `¿Estás seguro de borrar el comentario ${
        index + 1
      }? El cambio se aplicara cuando guardes el documento`,
      icon: "question",
      showDenyButton: true,
      denyButtonText: "Si, borrar",
      confirmButtonText: "No, regresar",
    });

    if (isDenied) {
      setState((current) => ({
        ...current,
        comments: current.comments.filter((_, i) => i !== index),
      }));
    }
  };

  const handleCurrencyChange = (currency) => {
    setState((current) => ({
      ...current,
      moneyInfo: {
        ...current.moneyInfo,
        currency: indexed[currency],
      },
    }));
  };

  /**
   *
   * @param {import("./types").HandleOnChangeCustomer} customer
   */
  const handleOnChangeCustomer = (customer) => {
    setState((current) => ({
      ...current,
      customer: {
        ...current.customer,
        rfc: customer.rfc,
        id: customer.id,
        socialRazon: customer.socialReason,
      },
    }));
  };

  const handleOnDeleteItem = async (uuid) => {
    const { isConfirmed } = await Swal.fire({
      title: "Borrar partida",
      text: "¿Estás seguro de que deseas borrar esta partida? El cambio tendra efecto cuando actualices el documento",
      confirmButtonText: "Si, borrar",
      cancelButtonText: "No, regresar",
      icon: "question",
      showCancelButton: true,
    });

    if (isConfirmed) {
      setState((current) => ({
        ...current,
        items: current.items.filter((item, i) => item.uuid !== uuid),
      }));
    }
  };

  /**
   *
   * @param {import("./types").ContactDocumentPraxiaI} contact
   */
  const handleContactOnChange = (contact) =>
    setState((current) => ({
      ...current,
      contact,
    }));

  const handleOnAddDocument = async (documentType) => {
    const wasAdded = await addDocument(
      {
        data: {
          ...state,
          idToRepresent: 14,
        },
      },
      documentType
    );

    if (wasAdded) {
      Swal.fire({
        title: "Éxtio",
        text: "El documento fue creado",
      });
    }
  };

  /**
   * Handle the change of probability
   * @param {number|string} id 
   */
  const handleOnProbabilityChange = id => {

    const indexedProbs = {
      1: "<50%",
      2: "=50%",
      3: ">50%",
      4: ">70%",
      5: "=100%",
    }

    const value = indexedProbs[+id]||"<50%";

    const probability = {
      id:+id,
        value,
    }

    setState(current=>({
      ...current,
      moneyInfo:{
        ...current.moneyInfo,
        probability
      }
    }))
  }

  const functions = {
    addComment,
    handleEditItemForm,
    addItem,
    handleSubmitItemForm: () => "FN DUMMY",
    backToOrigin: () => "PENDIENTE DE PROGRAMAR",
    calculateExchangeTc: () => "FN DUMMY",
    cancelEditing: () => "FN DUMMY",
    datesByDocumentType: () => "PENDIENTE DE PROGRAMAR",
    doCloseModal: () => "FN DUMMY",
    doOpenModal: () => "FN DUMMY",
    editComment: () => "PENDIENTE DE PROGRAMAR",
    editItem,
    getDocumentNumbers: () => "PENDIENTE DE PROGRAMAR",
    getNumbers: () => "PENDIENTE DE PROGRAMAR",
    reorderDocumentItems: () => "PENDIENTE DE PROGRAMAR",
    handelOnLoadCurrentItem: () => "PENDIENTE DE PROGRAMAR",
    handleContactOnChange,
    handleEditingComment: () => "PENDIENTE DE PROGRAMAR",
    handleEditNewItemDescription: () => "PENDIENTE DE PROGRAMAR",
    handleEditNewItemSku: () => "PENDIENTE DE PROGRAMAR",
    handleItemDescriptionChange: () => "PENDIENTE DE PROGRAMAR",
    handleItemSkuChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnAddDocument,
    handleOnBeginDateChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnChangeContractKey: () => "PENDIENTE DE PROGRAMAR",
    handleOnChangeCustomer,
    handleOnChangeTc: () => "PENDIENTE DE PROGRAMAR",
    handleOnChangeToggle: () => "PENDIENTE DE PROGRAMAR",
    handleOnClientDiscountItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnCommentChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnConceptChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnCostItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnCraeteNewItem: () => "PENDIENTE DE PROGRAMAR",
    handleOnCurrencyItemChage: () => "PENDIENTE DE PROGRAMAR",
    handleOnDeleteComment,
    handleOnDeleteItem,
    handleOnDocumentCurrencyChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnEditDocument: () => "PENDIENTE DE PROGRAMAR",
    handleOnEndDateChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnIvaItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnKeyItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnPriceItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnProbabilityChange,
    handleOnProviderDiscountItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnQuantityItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnReminderDateChange: () => "PENDIENTE DE PROGRAMAR",
    handleOnUenItemChange: () => "PENDIENTE DE PROGRAMAR",
    handleValidateDisableContract: () => "PENDIENTE DE PROGRAMAR",
    onCloseCurrentItem: () => "PENDIENTE DE PROGRAMAR",
    onSuccesAdded: () => "PENDIENTE DE PROGRAMAR",
    placeHolderDate: () => "PENDIENTE DE PROGRAMAR",
    probabilityValueText: () => "PENDIENTE DE PROGRAMAR",
    validateCanAddDocument: () => "PENDIENTE DE PROGRAMAR",
    validateCommentEditing: () => "PENDIENTE DE PROGRAMAR",
    handleOnCommentChangeMotive: () => "PENDIENTE DE PROGRAMAR",
    tc: () => "PENDIENTE DE PROGRAMAR",
    setTc,
    handleCurrencyChange,
  };

  return {
    ...state,
    id: idDocument,
    functions,
  };
}
