import axios from "axios";
import { Error, HtmlAlert, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";

/**
 * Add quote into system
 * @param {import("types/typedef/customHooks/useQuote").DtoAddQuote2} dto - DTO to add a quote into system
 * @returns {boolean}
 */
export async function addQuote(dto) {
  console.log("dto", dto);

  try {
    await axios.post(
      `${URL_BASE}documentos/agregar/documento/cotizacion`,
      dto,
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    Error(() => {}, "No se pudo agregar la cotización");
    return false;
  }
}

export async function editQuote(dto) {
  console.log("dto", dto);

  try {
    await axios.put(`${URL_BASE}documentos/actualizar/cotizacion`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}
