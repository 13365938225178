import React, { useRef, useState } from "react";
import { ContextGestionOc } from "../..";
import { useContext } from "react";

const uncheckedState = {
  1: false,
  2: false,
};

/**
 * Render the checkboxes for the managment
 * @param {import('helpers/Apis/purchases/ocManagment/types').OcManagment} record - Information of the oc record
 * @returns {JSX.Element}
 */
export default function NotConfirmed(record) {
  const id = useRef(`${window.crypto.randomUUID()}`);
  const hook = useContext(ContextGestionOc);

  const [confirmed, setConfirmed] = useState(uncheckedState);

  /**
   * 
   * @param {1|2} keyOption 
   * @param {boolean} isChecked
   */
  const handleCheckbox = (keyOption,isChecked) => {
    const check = keyOption === 1 ? true : false;

    const valueToggleConfirmation = isChecked === false ? undefined : check

    setConfirmed((current) => ({
      ...current,
      ...uncheckedState,
      [keyOption]: isChecked,
    }));


    hook.toggleConfirmationOc(valueToggleConfirmation, record.idOdc);
  };

  if (hook.status !== "notConfirmed") return <></>;

  return (
    <>
      <label
        className="d-flex justify-content-center align-items-center"
        htmlFor={`${id.current}-A`}
      >
        <input
          type="checkbox"
          id={`${id.current}-A`}
          checked={confirmed[1]}
          onChange={(e) => handleCheckbox(1,e.target.checked)}
        />
      </label>

      <label
        className="d-flex justify-content-center align-items-center"
        htmlFor={`${id.current}-B`}
      >
        <input
          type="checkbox"
          checked={confirmed[2]}
          id={`${id.current}-B`}
          onChange={(e) => handleCheckbox(2,e.target.checked)}
        />
      </label>
      {confirmed[1] === true ? (
        <input
          type="text"
          minLength={1}
          pattern=".{1,}" 
          placeholder="Obligatorio (No. confirmación)"
          title="Clave obligatoria"
          required
          onChange={(e) =>
            hook.setConfirmationSku(e.target.value, record.idOdc)
          }
        />
      ) : (
        <p className="text-center">-</p>
      )}
    </>
  );
}
