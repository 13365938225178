import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextEmail } from "..";
import Icon from "components/individual/HeaderIcon";

export const SendEmailBtn = () => {
  const { to, isPerformingPreload, isSendingEmail, sendEmail } =
    useContext(ContextEmail);

  return (
    <>
      {isSendingEmail || isPerformingPreload ? (
        <Spinner
          hidden={false}
          text={isPerformingPreload ? "Cargando" : "Enviando correo"}
        />
      ) : (
        <ButtonV2 disabled={to.length <= 0} onClick={sendEmail}>
          <span>Enviar</span>
          <Icon icon="send" />
        </ButtonV2>
      )}
    </>
  );
};
