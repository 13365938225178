import TableContext from "context/Table/TableContext";
import { YesNoAlert } from "helpers/alerts";
import { disassociateEgress, setCancelStatus } from "helpers/Apis/Banks";
import { useContext, useState } from "react";

/**
 * State for the component
 * @type {import("types/typedef/customHooks/useEgressOptions").StateI}
 */
const initialState = {
  isCancelling: false,
  isDownloadingPdf: false,
  isDisasociating: false,
};

/**
 * Hook for the egress options
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} infoRow - Information of the register selected
 */
export default function useEgressOptions(infoRow) {
  const [state, setState] = useState(initialState);

  const { setReload, refetch } = useContext(TableContext);

  /**
   * Set the flag that indicates that the movement is being cancelled
   * @param {boolean} isCancelling - Flag for the spinner
   * @returns {void}
   */
  const setIsCancelling = (isCancelling) =>
    setState({
      ...state,
      isCancelling,
    });

  /**
   * Set the the flag to show a feedback the movement is being disassociated
   * @param {boolean} isDisasociating - Flag
   * @returns {void}
   */
  const setIsDisassociating = (isDisasociating) =>
    setState({
      ...state,
      isDisasociating,
    });

  const disassociate = async () => {
    setIsDisassociating(true);
    const wasDisassociated = await disassociateEgress(infoRow.Movimiento);
    setIsDisassociating(false);

    if (wasDisassociated) {
      setReload(!refetch);
    }
  };

  /**
   * Cancel the egress movement
   */
  const cancelMovement = async () => {
    setIsCancelling(true);
    const wasCancelled = await setCancelStatus(infoRow.Movimiento);

    if (wasCancelled) {
      setReload(!refetch);
    }

    setIsCancelling(false);
  };
  const promptConfirmCancell = () =>
    YesNoAlert("¿Estas seguro de cancelar el movimiento?", cancelMovement);

  const promptConfirmDisassociate = () =>
    YesNoAlert("¿Estas seguro de desasociar el movimiento?", disassociate);

  return {
    isCancelling: state.isCancelling,
    isDownloadingPdf: state.isDownloadingPdf,
    isDisasociating: state.isDisasociating,
    promptConfirmCancell,
    promptConfirmDisassociate,
  };
}
