import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";

export const AsociatedFiles = () => {
  const { idInvoice } = useParams();
  return (
    <div style={{
      height: "100%",
      overflow:"auto",
      padding:"0 0 1rem 0"
    }}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/inicio",
            text: "Administracion",
          },
          {
            route: "/administracion/facturas-emitidas",
            text: "Facturas emitidas",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]}
      />
      <div className="container">
        <AssociateFiles
          idRegister={idInvoice}
          idTypeEntity={2}
          pathUpload={`documentos_legales/factura_id_${idInvoice}`}
          status={-1}
        />
      </div>
    </div>
  );
};
