import React from "react";
import { useContext } from "react";
import { ContextResidueAndMovements } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ExcelDownload() {
  const hook = useContext(ContextResidueAndMovements);

  if (hook.isDownloadingExcel)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Descargando excel</LoadingText>}
      />
    );

  return (
    <ButtonV2
      disabled={hook.records.length <= 0}
      onClick={hook.attemptDownloadXlsx}
    >
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
