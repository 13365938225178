import ButtonV2 from "components/individual/ButtonsV2/Button";
import CommentBox from "components/individual/CommentBox";
import { Spinner } from "components/individual/Spinner/Spinner";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useAttendInvoice from "customHooks/useAttendInvoice";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { Label } from "structure/Document";
import { ContextAttendInvoice } from "structure/ToDo/CardsToDo/AttendInvoice";
import LimitTime from "structure/ToDo/CardsToDo/AttendInvoice/LimitTime";
import PartialitiesApproved from "structure/ToDo/CardsToDo/AttendInvoice/Partialities";
import TC from "structure/ToDo/CardsToDo/AttendInvoice/TC";

export default function Authorization() {
  const { idInvoice, idTodo } = useQueryParams();

  const history = useHistory();

  const redirectToToDo = () => {
    history.push(`/to-do`);
  };

  const hook = useAttendInvoice(idInvoice, idTodo, redirectToToDo);

  useEffect(() => {
    hook.openModal();
  }, []);

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        text="Cargando revision"
        idSpinner="loadingReview"
      />
    );

  return (
    <>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/to-do",
            text: "To Do",
          },
          {
            route: "/to-do",
            text: "Autorizaciones pendiente",
          },
        ]}
      />
      <div className="container mt-3">
        <ContextAttendInvoice.Provider
          value={{
            isValid: hook.isValid,
            approved: hook.approved,
            updatePartialitiesApproved: hook.updatePartialitiesApproved,
            updateLimitTime: hook.updateLimitTime,
            attemptAuthorizacion: hook.attemptAuthorizacion,
          }}
        >
          <div
            style={{
              overflow: "auto",
            }}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {hook.message}
            </ReactMarkdown>

            <Label maxLength={255} currentLength={hook.repply.length}>
              Mótivo
            </Label>
            <textarea
            maxLength={255}
              value={hook.repply}
              onChange={(e) => hook.updateRepply(e.target.value)}
            ></textarea>
          </div>
          <hr />
          <div className="d-flex justify-content-between w-100 align-items-end">
            <div className="d-flex">
              <PartialitiesApproved />
              <TC />
              <LimitTime />
            </div>

            {hook.isPerfomingQuery ? (
              <Spinner
                hidden={false}
                idSpinner="reviewSpinner"
                text="Actualizando revision"
              />
            ) : (
              <div>
                <ButtonV2
                  theme="danger"
                  onClick={() => hook.attemptAuthorizacion(false)}
                  disabled={hook.repply.length>=256}
                >
                  Rechazar
                </ButtonV2>

                {hook.approved.limitTime !== null ? (
                  <ButtonV2 onClick={() => hook.attemptAuthorizacion(true)} disabled={hook.repply.length>=256}>
                    Aceptar
                  </ButtonV2>
                ) : null}
              </div>
            )}
          </div>
        </ContextAttendInvoice.Provider>
      </div>
    </>
  );
}
