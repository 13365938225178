import React, {
  useContext,
  useEffect,
  useState,
  JSXElementConstructor,
  useRef,
  propTypes,
  forwardRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faKey,
  faUser,
  faEye,
  faSearch,
  faEnvelope,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { rowsPerPage } from "../../../static/TableConfig";
import UsersContext from "../../../context/Table/TableContext";
import axios from "axios";
import { URL_BASE } from "../../../routes/routes";
import PhoneInput from "react-phone-input-2";
import { v4 as uuidv4 } from "uuid";

import CurrencyInput from "react-currency-input-field";
import { setValue } from "../../../helpers/forms";

import NumberFormat from "react-number-format";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { dateToDbFormat } from "../../../helpers/dates";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { addDays, setHours, setMinutes } from "date-fns";
import { Controller } from "react-hook-form";
import { Label } from "structure/Document";
registerLocale("es", es);

/* Shows the passwords of the inputs of type password
[1 - Paramater]: An array of strings with the ids of the
inputs that are of type password
*/
export function showPasswords(inputs) {
  inputs.map((input) => {
    const type = document.getElementById(input).type;
    const inputHTML = document.getElementById(input);

    if (type == "password") {
      inputHTML.type = "text";
    } else {
      inputHTML.type = "password";
    }
  });
}
export function CurrencySelect(id) {
  return (
    <>
      <label htmlFor="" className="mb-2">
        Moneda
      </label>
      <select className="select-css inline-block" name="Ejecutivo" id={id}>
        <option key="mx" value="MXN">
          MXN
        </option>
        <option key="us" value="US">
          US
        </option>
      </select>
    </>
  );
}

/**
 * Informative label
 * @param {object} props - Props
 * @param {string} props.label - Label
 * @param {string} props.value - Value to show
 * @returns {JSX.Element}
 */
export function InformativeV2({ label = "Label", value = "Value" }) {
  return (
    <div>
      <b>{label}</b>
      <p>{value}</p>
    </div>
  );
}

export function StatusInput({ id }) {
  return (
    <>
      <label htmlFor="" className="mb-2">
        Estatus
      </label>
      <select name="Estauts" id={id} className="select-css inline-block">
        <option key="todos" value="Todos">
          Todos
        </option>
        <option key="activo" value="Activo">
          Activo
        </option>
        <option key="inactivo" value="Inactivo">
          Inactivo
        </option>
      </select>
    </>
  );
}
export function EjecutivosInput({ id }) {
  const [users, setUsers] = useState(null);
  const [fetchusers, setFetchUsers] = useState(false);
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const { data } = await axios.get(`${URL_BASE}usuarios/getusuarios`,{
          withCredentials:true
        });
        setUsers(data);
        setFetchUsers(true);
        // console.log(fetchusers);
        // console.log(users[0].fullName);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllUsers();
  }, []);
  let opciones;
  if (fetchusers) {
    opciones = users.map((usuarios) => (
      <option key={usuarios.rolID} value={usuarios.userID}>
        {usuarios.fullName}
      </option>
    ));
  }
  return (
    <>
      <label htmlFor="" className="mb-2">
        Ejecutivo
      </label>
      <select className="select-css inline-block" name="Ejecutivo" id={id}>
        {opciones}
      </select>
    </>
  );
}

/**
 *  Text area component
 * @param {object} props - Props
 * @param {(object:React.ChangeEvent<HTMLTextAreaElement>)=>void} props.cb - Event on change
 * @returns {JSX.Element}
 */
export function TextArea({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id,
  ref = {},
  cb,
}) {
  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor="">
        {labelTxt}
      </label>
      <div className="d-flex align-items-center">
        <textarea
          onChange={(e) => cb(e)}
          ref={ref}
          id={id}
          className=""
          name={name}
          type="textarea"
          placeholder={placeholderTxt}
        />
      </div>
    </div>
  );
}

/**
 *
 * @param {object} props - Props of the component
 * @param {string} props.labelTxt - Text of the label that appears on the input
 * @param {string} props.placeholderTxt - Placeholder of input
 * @param {string} props.aditionalClasses - CSS for the container input
 * @param {string} props.name - HTML name of the tag input
 * @param {string} props.idEmail - HTML id of the tag input
 * @param {string} props.value - Default value if input must have a email
 * @param {object} props.forwardRef - register for react-hook-form
 * @param {object} props.errors - errors for react-hook-form
 * @param {boolean} props.readonly - True if the input is not editable
 * @returns {JSXElementConstructor} JSX Element
 */
export function InputEmail({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  containerCss = "",
  name,
  value = null,
  forwardRef = {},
  idEmail = "",
  errors = {},
  readonly = false,
}) {
  useEffect(() => {
    if (value === null) return;

    const domElement = document.getElementById(idEmail);

    if (domElement !== null) domElement.value = value;
  }, [value]);

  return (
    <>
      <div className={`d-flex flex-column ${containerCss}`}>
        <div className={`${aditionalClasses}`}>
          <label className="labelInput" htmlFor="txtEmail">
            {labelTxt}
          </label>
          <div className="d-flex align-items-center">
            {/* <div className="leftSideInput d-flex align-items-center logtransparent">
              <FontAwesomeIcon icon={faEnvelope} />
            </div> */}

            <input
              id={idEmail}
              className={`rightSideInput ${
                readonly === true ? "inputBlock" : ""
              }`}
              name={name}
              type="email"
              placeholder={placeholderTxt}
              ref={forwardRef}
              readOnly={readonly}
            />
          </div>
        </div>
        <ErrorMessage message={errors[name]?.message} />
      </div>
    </>
  );
}

export function InputPassword({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id,
  showSeePassword,
}) {
  const [showEyePassword, setShowEyePassword] = useState(false);
  const showPassword = (e) => {
    const type = document.getElementById(id).type;
    const input = document.getElementById(id);
    console.log(type);
    if (type === "password") {
      console.log("showing pass");
      setShowEyePassword(true);
      input.type = "text";
    } else {
      console.log("hidding passw");
      setShowEyePassword(false);
      input.type = "password";
    }
  };

  return (
    <div className={aditionalClasses}>
      <label className="labelInput" htmlFor="txtPassword">
        {labelTxt}
      </label>
      <div className="d-flex align-items-center">
        <div className="leftSideInput d-flex align-items-center logtransparent">
          <FontAwesomeIcon className="align-middle" icon={faKey} />
        </div>

        <input
          id={id}
          className="rightSideInput"
          name={name}
          type="password"
          placeholder={placeholderTxt}
        />

        {showSeePassword ? (
          <div onClick={showPassword} className="showPassword">
            {showEyePassword ? (
              <FontAwesomeIcon className="align-middle" icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon className="align-middle" icon={faEye} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function InputUser({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id,
}) {
  const [value, setValue] = useState("");

  const maxLenght = 50;

  return (
    <div className='inputUser'>
      <div className="d-flex align-items-center mb-1">
      <Label required={value === ""} htmlFor="txtUser">
        {labelTxt}
      </Label>
      <span>{value.length}/{maxLenght}</span>
      </div>
      <input
        id={id}
        name={name}
        type="text"
        placeholder={placeholderTxt}
        minLength={1}
        maxLength={maxLenght}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

/**
 * Input text for the forms
 *
 * @param {object} props - Props of the component
 * @param {string} props.labelTxt - Text of the label
 * @param {string} props.placeholderTxt - Placeholder of the input
 * @param {string} props.aditionalClasses - CSS for the input
 * @param {import("react").CSSProperties} props.style - CSS styles for the input
 * @param {string} props.name - HTML name of the input
 * @param {string} props.id - HTML id of the input
 * @param {(e:import("react").KeyboardEvent<HTMLInputElement>)=>void} props.onEnter - Function to enter key
 * @param {string} props.htmlFor - HTML for of the label
 * @param {number} props.maxLength - MaxLength
 * @param {boolean} props.disabled - If true, the input can not be change
 * @param {boolean} props.readonly - If true, input can not be change
 * @param {object} props.forwardRef - object register validate with react-form-hook
 * @param {string|number} props.defaultValue - Value of the input
 * @param {boolean} props.isRequired - Value of the input
 * @param {Function} props.onChange - Callback when the value input changes
 * @returns {import("react").ReactComponentElement} JSX Element
 */
export function InputText({
  labelTxt,
  placeholderTxt,
  aditionalClasses = "",
  style = {},
  name,
  id = window.crypto.randomUUID(),
  forwardRef = {},
  disabled = false,
  maxLength = 200,
  onEnter = () => {},
  readonly = false,
  defaultValue = null,
  onChange = () => {},
  isRequired = false,
}) {
  useEffect(() => {
    setValue(id, defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div className={`${aditionalClasses}`}>
      <Label required={isRequired}>{labelTxt}</Label>

      <div className="d-flex align-items-center">
        {/* <div className="leftSideInput d-flex align-items-center"></div> */}

        <input
          readOnly={readonly}
          disabled={disabled}
          id={id}
          form={window.crypto.randomUUID()}
          className={`rightSideInput ${readonly === true ? "inputBlock" : ""}`}
          name={name}
          type="text"
          placeholder={placeholderTxt}
          minLength={1}
          maxLength={maxLength}
          style={style}
          onChange={(e) => onChange(e.target.value)}
          ref={forwardRef}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              onEnter(e);
              e.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export function InputPhone({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id,
  htmlfor,
  side,
  ref,
  disabled = false,
}) {
  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor={htmlfor}>
        {labelTxt}
      </label>
      <div className="d-flex align-items-center">
        <PhoneInput
          regions={["america"]}
          country={"mx"}
          enableAreaCodes={true}
          id={id}
          name={name}
          type="text"
          placeholder={placeholderTxt}
        />
      </div>
    </div>
  );
}

export function Phone({
  aditionalClasses,
  htmlFor,
  labelText,
  idInput,
  placeholderTxt,
  forwardRef,
  isEditing,
  value = "",
  valueLada = "",
  valueNumber = "",
  onChange = () => {},
}) {
  const [phoneNumber, setPhoneNumber] = useState(value);
  const [lada, setLada] = useState(valueLada);
  const [number, setNumber] = useState(valueNumber);

  useEffect(() => {
    setPhoneNumber(value);
  }, [value]);

  useEffect(() => {
    if (isEditing) {
      document.getElementById(`isValid_${idInput}`).value = 1;
    } else {
      document.getElementById(`isValid_${idInput}`).value = 0;
    }
  }, []);

  function validateNumber(actualPhone, mustContain) {
    if (actualPhone !== mustContain) {
      displayError(mustContain);
      invalidInputs();
      return false;
    } else {
      hideError();
      return true;
    }
  }

  function invalidInputs() {
    document.getElementById(`number_${idInput}`).value = "";
    document.getElementById(`isValid_${idInput}`).value = 0;
  }

  function displayError(charactersNumbers) {
    document.getElementById(
      `error_${idInput}`
    ).innerText = `${charactersNumbers} digitos requeridos`;
  }

  function hideError() {
    document.getElementById(`isValid_${idInput}`).value = 1;
    document.getElementById(`error_${idInput}`).innerText = "";
  }

  function writeLadaOnFakes(lada) {
    if (lada !== undefined) {
      document.getElementById(`lada_${idInput}`).value = lada;
    }
  }

  function isValidLadaAndFlag(input, dialCode, hasNumbers) {
    dialCode = dialCode?.substr(0, hasNumbers);

    if (input === "") {
      quitAlertLada();
      setValid();
      return false;
    }

    if (input !== dialCode) {
      showAlertLada();
      setInvalid();
      return false;
    } else {
      quitAlertLada();
      setValid();
    }

    return true;
  }

  function setInvalid() {
    document.getElementById(`isValid_${idInput}`).value = 0;
  }

  function setPhone(phone) {
    document.getElementById(`number_${idInput}`).value = phone;
  }

  function setInvalidPhone() {
    document.getElementById(`number_${idInput}`).value = "";
  }

  function getLadaLibrary(number) {
    // 1. Quit the + symbol (Leave as number)
    const notPlusSymbol = number?.replaceAll("+", "");

    let positionLada = notPlusSymbol.indexOf(" ");

    if (positionLada < 1) positionLada = notPlusSymbol.length;

    let lada = notPlusSymbol.substr(0, positionLada);

    if (isNaN(lada)) {
      lada = "";
    }

    return lada;
  }

  function showAlertLada() {
    document.getElementById(`error_${idInput}`).innerText = "Escribe la lada";
  }

  function quitAlertLada() {
    const input = document.getElementById(`error_${idInput}`);
    if (input !== null) {
      input.innerText = "";
    }
    // document.getElementById(`error_${idInput}`).innerText = "";
  }

  function setValid() {
    document.getElementById(`isValid_${idInput}`).value = 1;
  }

  function getLadaLength(format) {
    if (format === undefined) {
      return 10;
    }

    const size = format.substr(1, format.indexOf(" "));
    return parseInt(size.length, 10) - 1;
  }

  function getNumberPhone(format) {
    if (format === undefined) {
      return false;
    }

    const beginPhone = format.indexOf(" ");

    if (beginPhone === -1) {
      return false;
    }

    return format.substr(beginPhone, format.length);
  }

  function getNumberPhoneLength(format) {
    if (format === undefined) {
      return 0;
    }

    const begin = format.indexOf(" ");

    if (begin === -1) {
      return 0;
    }

    let unformatedNumber = format.substr(begin, format.length);

    unformatedNumber = unformatedNumber.replaceAll(" ", "");

    return unformatedNumber.length;
  }

  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor={htmlFor}>
        {labelText}
      </label>
      <div className="d-flex align-items-center">
        <PhoneInput
          inputProps={{
            id: `${idInput}-fake`,
          }}
          regions={["america", "europe"]}
          preferredCountries={["mx", "us", "ca", "es", "fr"]}
          enableAreaCodes={true}
          enableSearch={true}
          disableSearchIcon={true}
          inputClass="form-control"
          value={`${phoneNumber}`}
          containerClass="none"
          enableLongNumbers={true}
          placeholder={placeholderTxt}
          onChange={(value, country, e, formattedValue) => {
            const ladaLibrary = getLadaLibrary(formattedValue);

            const lengthLada = getLadaLength(country?.format);

            const numberPhone = getNumberPhone(formattedValue);

            writeLadaOnFakes(ladaLibrary);

            const isValidLada = isValidLadaAndFlag(
              ladaLibrary,
              country.dialCode,
              lengthLada
            );

            if (!isValidLada) {
              setPhoneNumber(value);
              setLada("");
              setNumber("");
              return;
            }

            setLada(ladaLibrary);

            const numberPhoneLength = getNumberPhoneLength(country?.format);

            if (numberPhone === false) {
              quitAlertLada();
              return;
            }

            const actualPhoneLength = numberPhone.replaceAll(" ", "").length;

            const isValidNumber = validateNumber(
              actualPhoneLength,
              numberPhoneLength
            );

            if (isValidNumber) {
              setPhone(numberPhone);
              setPhoneNumber(`${ladaLibrary}${numberPhone}`);
              setNumber(numberPhone);
              onChange({
                lada: ladaLibrary,
                number: numberPhone,
                isValid: true,
              });
            } else {
              setPhoneNumber(value);
              setNumber("");
              onChange({
                lada: null,
                number: null,
                isValid: false,
              });
            }
          }}
        />

        <input
          type="text"
          className="d-none"
          ref={forwardRef}
          name={`lada_${idInput}`}
          id={`lada_${idInput}`}
          value={lada}
        />

        <input
          type="text"
          className="d-none"
          ref={forwardRef}
          name={`number_${idInput}`}
          id={`number_${idInput}`}
          value={number}
        />

        <input type="number" className="d-none" id={`isValid_${idInput}`} />
      </div>
    </div>
  );
}

export function InputPage({
  placeholderTxt,
  aditionalClasses,
  id,
  fetchPagination,
  attributes,
}) {
  const { criterianOrder, setActualPage, orderBy } = useContext(UsersContext);

  const fetchPage = (e) => {
    try {
      let noPage = e.target.parentNode.childNodes[1].value;

      if (noPage === "") {
        e.target.parentNode.childNodes[3].classList.remove("d-none");
      }

      if (noPage < 1) {
        e.target.parentNode.childNodes[3].classList.remove("d-none");
      } else {
        noPage = parseInt(noPage, 10);

        const rowBegin = (noPage - 1) * rowsPerPage;

        setActualPage(noPage);

        e.target.parentNode.childNodes[3].classList.add("d-none");
        fetchPagination(
          rowBegin,
          rowsPerPage,
          attributes[orderBy],
          criterianOrder
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${aditionalClasses}`}>
      <div className="d-flex align-items-center">
        <div className="leftSideInput d-flex align-items-center">
          {/* <FontAwesomeIcon icon={faSearch} /> */}
        </div>

        <input
          id={id}
          className="rightSideInput no-spinners"
          min="1"
          type="number"
          placeholder={placeholderTxt}
        />

        <div
          onClick={(e) => fetchPage(e)}
          className="paginationSeachBtn cursorPointer"
        >
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <b
          className="d-none
        text-danger"
        >
          Pagina invalida
        </b>
      </div>
    </div>
  );
}

/**
 * Search input for the forms
 *
 * @param {object} props - Props of the component
 * @param {string} props.placeholderTxt - Placeholder of the input
 * @param {string} props.aditionalClasses - CSS for the input
 * @param {string} props.name - HTML name input
 * @param {string} props.id - HTML id input
 * @param {boolean} props.showLabel - If true, will show a label
 * @param {string?} props.labelText - Text of the label
 * @param {boolean} props.showHelp - If true, will show a small text down the input
 * @param {string?} props.showHelpText - Text down the input
 * @param {SearchInput} props.onChange - Callback to be executed when the input search change
 */
export function SearchInput({
  placeholderTxt,
  aditionalClasses,
  name,
  id,
  showLabel,
  labelText = "",
  showHelp,
  showHelpText = "",
  onChange,
  idLabel = null,
}) {
  return (
    <div className={`${aditionalClasses}`}>
      {showLabel ? (
        <label
          id={idLabel !== null ? idLabel : uuidv4()}
          className="mb-2"
          htmlFor={id}
        >
          {labelText}
        </label>
      ) : null}

      <div className="d-flex align-items-center">
        <div className="leftSideInput d-flex align-items-center">
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          id={id}
          className="rightSideInput"
          name={name}
          type="text"
          placeholder={placeholderTxt}
          minLength={1}
          maxLength={50}
          onChange={onChange}
        />
      </div>

      {showHelp ? <p className="mt-2 helpText">{showHelpText}</p> : null}
    </div>
  );
}

/**
 *
 * @param {import("./propsInput").PropsErrorMessage} - Props
 * @returns {JSX.Element} JSX Element
 */
export function ErrorMessage({ message, id = "" }) {
  if (id === "" || id === null || id === undefined) {
    id = uuidv4();
  }

  return (
    <p id={id} className="my-0 errorMessage">
      {message}
    </p>
  );
}

/**
 *
 * @param {object} props - Props that receives the component
 * @param {string} props.active - Text that appears when the toggle is "ON"
 * @param {string} props.inactive - Text that appears when the toggle is "OFF"
 * @param {string} props.idToggle - HTML id of the toggle
 * @param {string} props.idLabel - HTML id of the label
 * @param {function} props.cb - CB after toggle the button
 * @param {string} props.labelText - Text for the label toggle
 * @returns {JSXElementConstructor} JSX Element
 */

export function ToggleButton({
  active,
  inactive,
  idToggle = window.crypto.randomUUID(),
  idLabel = window.crypto.randomUUID(),
  idInput = window.crypto.randomUUID(),
  aditionalClasses = "",
  cb = () => {},
  forwardRef,
  labelText,
  isActive = false,
}) {
  const switchToggle = (e) => {
    const toggle = document.getElementById(idToggle).checked;
    let labelToggle = document.getElementById(idLabel);

    cb(e);

    if (toggle) {
      labelToggle.innerText = active;
      document.getElementById(idToggle).checked = true;
      document.getElementById(`${idInput}_value_${idToggle}`).value = 1;
      return toggle;
    } else {
      labelToggle.innerText = inactive;
      document.getElementById(idToggle).checked = false;
      document.getElementById(`${idInput}_value_${idToggle}`).value = 0;
      return toggle;
    }
  };

  useEffect(() => {
    if (isActive) {
      setActive();
    }
  }, []);

  function setActive() {
    let labelToggle = document.getElementById(idLabel);
    labelToggle.innerText = active;

    const toggleIcon = document.getElementById(idToggle);
    console.log(toggleIcon);
    toggleIcon.checked = true;

    const hiddenInput = document.getElementById(`value_${idToggle}`);
    hiddenInput.value = 1;
  }

  return (
    <>
      <label htmlFor={idToggle}>{labelText}</label>
      <div className="custom-control custom-switch custom-switch-lg labelInput">
        <input
          className="d-none"
          type="number"
          id={`${idInput}_value_${idToggle}`}
          name={`value_${idToggle}`}
          ref={forwardRef}
        />

        <input
          onClick={(e) => switchToggle(e)}
          type="checkbox"
          className={`custom-control-input custom-control-input-success ${aditionalClasses}`}
          name={idToggle}
          id={idToggle}
          value={0}
        />
        <label
          className="custom-control-label"
          onClick={() => switchToggle()}
          id={idLabel}
          htmlFor={idToggle}
        >
          {inactive}
        </label>
      </div>
    </>
  );
}

export function resetToggle(idToggle, idLabel, textLabel) {
  document.getElementById(idToggle).checked = false;
  document.getElementById(idLabel).innerText = textLabel;
}

/**
 * Callback to execute when clicks an option from the select
 *
 * @callback callbackSelect
 * @param {string} value - Value of the clicked option
 * @returns {string} Value of the selected option
 */

/**
 * @callback callbackOnChange
 * @param {string} value - Value of the clicked option
 * @returns {string} Value of the selected option
 */

/**
 * Select input for the forms
 *
 * @param {object} props - Props of the component
 * @param {Array<object>} props.options - Options that will contain the select
 * @param {string} props.idSelect - HTML id that will have the select
 * @param {string?} props.className - HTML class that will have the select (optional)
 * @param {string} props.nameSelect - HTML name that will have the select input
 * @param {string?} props.label - Label of the select
 * @param {string} props.initialOption - Text when initial option appears
 * @param {boolean?} props.addTodos - If true, will add an extra option for "All"
 * @param {string} props.valueJSON - Which attribute of the array use as value for the tag option
 * @param {string} props.textJSON - Which attribute of the array use as text to display for the tag option
 * @param {callbackSelect} props.cb - Callback triggered when select an option
 * @param {function} props.loadedOptions - Callback that returns the loaded options from the select
 * @param {string|number} props.value - Value if the select must charge an specific option
 * @param {callbackOnChange} props.onChange - Callback triggered when option select changes
 * @param {boolean?} props.readonly - True if the select it's disabled
 * @param {boolean?} props.disableInitlaOption - Disable the initial option
 */
export function Select({
  options = [],
  idSelect,
  disableInitlaOption = true,
  className = "",
  nameSelect,
  label,
  addTodos = true,
  initialOption = "Todos",
  valueJSON,
  textJSON,
  forwardRef,
  cb = () => {},
  onChange = () => {},
  value = null,
  readonly = false,
  disabledInitialOption = true,
}) {
  const optionDisabled = useRef();

  useEffect(() => {
    const option = document.getElementById(`${idSelect}${value}`);

    if (value !== null && option !== null && options.length > 0) {
      option.selected = true;
    }
  }, [value, options]);

  const disabledInitialOptions = () => {
    if (addTodos === true && disableInitlaOption === true) {
      optionDisabled.current.disabled = true;
    }
  };

  return (
    <div className={`${className} ${readonly ? "disabled" : ""}`}>
      {label !== null ? <label id={`lbl_${idSelect}`}>{label}</label> : null}

      <select
        id={idSelect}
        className={`px-3 select-css`}
        name={nameSelect}
        ref={forwardRef}
        value={value} //Eliminar
        onClick={(e) => {
          cb(e.target.value);

          if (disabledInitialOption === true) {
            disabledInitialOptions();
          }
        }}
        onChange={(e) => {
          onChange(e.target.value);

          if (disabledInitialOption === true) {
            disabledInitialOptions();
          }
        }}
      >
        {addTodos ? (
          <option
            onChange={disabledInitialOptions}
            ref={optionDisabled}
            id={`${idSelect}-1`}
            className={`eventDisabled`}
            // disabled={true }
            value="-1"
          >
            {initialOption}
          </option>
        ) : null}

        {options.map((option) => {
          return (
            <option
              key={`${idSelect}${option[valueJSON]}`}
              id={`${idSelect}${option[valueJSON]}`}
              value={option[valueJSON]}
            >
              {option[textJSON]}
            </option>
          );
        })}
      </select>
    </div>
  );
}

/**
 * Input for number
 *
 * @param {object} props - Props of the component
 * @param {string} props.labelTxt - Text of the label
 * @param {string} props.placeholderTxt - Placeholder of the input
 * @param {string} props.aditionalClasses - CSS for the input
 * @param {string} props.name - HTML name of the input
 * @param {(folio:number)=>void} [props.onChange=()=>{}] - Callback with the value typed on the field
 * @param {string} props.id - HTML id of the input
 * @param {object} props.forwardRef - Object register of react-hook-form
 * @param {string} props.htmlFor - HTML for of the label
 * @param {boolean?} props.disabled - If true, the input can not be change
 * @param {boolean?} props.decimal - If true, input will allow decimal values
 * @param {number?} props.defaultValue - Value if the input must be rendered with a default value
 * @param {function} props.keyUp - CB when keyup
 */
export function InputNumber({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id = window.crypto.randomUUID(),
  htmlfor,
  forwardRef,
  disabled = false,
  decimal = false,
  length = null,
  keyUp = (e) => {},
  onChange = () => {},
  defaultValue = null,
  readonly = false,
}) {
  let steps = "1";

  if (decimal === true) {
    steps = "0.01";
  }

  useEffect(() => {
    if (defaultValue !== null) setValue(id, defaultValue);
  }, [defaultValue]);

  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor={htmlfor}>
        {labelTxt}
      </label>
      <div className="d-flex align-items-center">
        {/* <div className="leftSideInput d-flex align-items-center"></div> */}

        <input
          onChange={(e) => onChange(+e.target.value)}
          onKeyUp={(e) => keyUp(e)}
          disabled={disabled}
          id={id}
          className={`rightSideInput ${readonly === true ? "inputBlock" : ""}`}
          name={name}
          type="number"
          placeholder={placeholderTxt}
          ref={forwardRef}
          step={steps}
          max={length}
          readOnly={readonly}
        />
      </div>
    </div>
  );
}

/**
 * Radio buttons
 * @param {object} props - Props of the component
 * @param {Array<object>} props.options - Options for each radio button
 * @param {string} props.name - Name to identify the set of buttons that are of the same context
 * @param {string} props.attributeText - Name of the attribute to print on the screen
 * @param {string} props.attributeID - id attribute will have the radio button from the props.options
 * @param {string} props.attributeValue - value attribute will have the radio button from the props.options
 * @param {function} props.cb - Callback when click an option
 * @param {boolean} props.sameLine - If true, option will be in line, if not, one down each other
 * @returns {JSXElementConstructor} JSX Element
 */
export function RadioButton({
  sameLine,
  options,
  name,
  attributeText,
  attributeID,
  attributeValue,
  cb = () => {},
  onChange = () => {},
  forwardRef,
}) {
  return (
    <div
      id={`container-${name}`}
      className={sameLine ? "d-flex align-items-center" : ""}
    >
      {options.map((option) => {
        return (
          <>
            <div
              className={`d-flex align-items-center ${
                sameLine ? "mr-4" : null
              }`}
            >
              <input
                type="radio"
                id={`${name}-${option[attributeID]}`}
                name={name}
                value={option[attributeValue]}
                onClick={(e) => cb(e)}
                onChange={(value) => onChange(value)}
                ref={forwardRef}
              />
              <label
                className="cursorPointer"
                htmlFor={`${name}-${option[attributeID]}`}
              >
                {option[attributeText]}
              </label>
            </div>
          </>
        );
      })}
    </div>
  );
}

export function InputPercentage({
  defaultValue = 0,
  id = uuidv4(),
  name = uuidv4(),
  label = "Descuento",
  forwardRef = {},
  css = "no-style",
}) {
  const [isAnError, setIsAnError] = useState(false);
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={css}>
      <label htmlFor={name}> {label} </label>
      <NumberFormat
        suffix="%"
        value={value}
        className="rounded px-2"
        id={id}
        name={name}
        allowNegative={false}
        decimalScale={2}
        ref={forwardRef}
        onValueChange={(value) => console.log("Si es valido")}
        isAllowed={({ floatValue }) => {
          setValue(floatValue);
          if (floatValue > 100) {
            setIsAnError(true);
            return false;
          }

          setIsAnError(false);
          return true;
        }}
      />

      {isAnError ? (
        <ErrorMessage message="Descuento invalido" id={`${id}-error`} />
      ) : null}
    </div>
  );
}

export function InputCurrency({
  aditionalClasses = "",
  htmlfor = "",
  labelTxt = "",
  labelId = "",
  id = "",
  placeholder = "",
  disabled = false,
  forwardRef = {},
  name = "",
  changeEvent = () => {},
  defaultValue = null,
  onEnter = () => {},
  onChange = () => {},
  maxLength = null,
  showError = false,
}) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (defaultValue !== null) setValue(defaultValue);
  }, [defaultValue]);

  const css = !showError
    ? {}
    : {
        border: "2px solid red",
        borderRadius: "5px",
      };

  return (
    <>
      <div className={`${aditionalClasses}`}>
        <label id={labelId} className="labelInput" htmlFor={htmlfor}>
          {labelTxt}
        </label>
        <div style={css} className="d-flex align-items-center">
          <CurrencyInput
            id={id}
            name={name}
            placeholder={placeholder}
            decimalsLimit={2}
            groupSeparator=","
            decimalSeparator="."
            prefix="$"
            disabled={disabled}
            className="pl-3 rounded "
            ref={forwardRef}
            maxLength={maxLength}
            // onChange={changeEvent}
            intlConfig={{ locale: "en-US", currency: "USD" }}
            onValueChange={(value) => {
              setValue(value);
              changeEvent(value);
              onChange(value === undefined ? 0 : +value);
            }}
            value={value === null ? "" : value}
            allowNegativeValue={false}
          />
        </div>
      </div>
    </>
  );
}

/**
 * Render an input date field
 * @param {import("./InputDate").DateProps} props - Props
 * @returns {JSX.Element}
 */
export function InputDate({
  id,
  name = "???",
  labelText,
  date = new Date(),
  readOnly,
  inputChange = () => {},
  forwardRef,
  minDate = null,
  maxDate = null,
}) {
  return (
    <>
      <label className="labelInput" htmlFor={`nameFake_${name}`}>
        {labelText}
      </label>

      <DatePicker
        readOnly={readOnly}
        placeholderText="Selecciona una fecha"
        id={`idFake_${id}`}
        name={`nameFake_${name}`}
        selected={date}
        dateFormat="dd/MMM/yyyy"
        className={`${readOnly === true ? "inputBlock" : ""}`}
        locale="es"
        // minDate={typeof minDate === "string" ? new Date(minDate) : minDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date, e) => {
          // setDate(date);
          inputChange(date, e);
        }}
        // onChangeRaw={input}
      />

      <input
        type="hidden"
        ref={forwardRef}
        name={name}
        id={id}
        onChange={(e) => {
          inputChange(e);
        }}
        // value={dateToDbFormat(new Date(date))}
        value={dateToDbFormat(new Date(date))}
      />
    </>
  );
}
export function InputDateMonth({
  id,
  name,
  labelText,
  startDate,
  endDate,
  dateSelected,
  aditionalClasses,
  inputChange = () => {},
}) {
  return (
    <div className={`d-flex flex-column ${aditionalClasses}`}>
      <label className="labelInput" htmlFor={`nameFake_${name}`}>
        {labelText}
      </label>

      <DatePicker
        id={id}
        selected={dateSelected}
        onChange={inputChange}
        startDate={startDate}
        endDate={endDate}
        dateFormat="MMM/yyyy"
        // dateFormat="MM/yyyy"
        locale="es"
        showMonthYearPicker
        selectsRange
        className="w-100"
      />
    </div>
  );
}
export function InputDateYearMonth({
  id,
  name,
  labelText,
  placeHolderText,
  date = null,
  control,
  readOnly,
  inputChange = () => {},
  onChange = () => {},
  forwardRef,
  minDate = null,
  maxDate,
  schema,
}) {
  const [selectDate, setSelectDate] = useState(date);
  useEffect(() => {
    setSelectDate(date);
  }, [date]);

  return (
    <>
      <label className="labelInput" htmlFor={name}>
        {labelText}
      </label>
      <DatePicker
        readOnly={readOnly}
        selected={selectDate}
        dateFormat="dd/MMM/yyyy"
        placeholderText={placeHolderText}
        className={`${readOnly === true ? "inputBlock" : ""}`}
        locale="es"
        withPortal
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date, e) => {
          setSelectDate(date);
          onChange({
            db: dateToDbFormat(date),
            js: date,
          });
        }}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />

      <input
        type="hidden"
        ref={forwardRef}
        name={name}
        onChange={(e) => {
          inputChange(e);
        }}
        value={!!selectDate ? dateToDbFormat(selectDate) : ""}
      />
    </>
  );
}
export function InputDateTime({
  id,
  name,
  labelText,
  date,
  readOnly,
  inputChange = () => {},
  forwardRef,
  minDate,
  maxDate,
  minTime = setHours(new Date(), 1),
  maxTime = setHours(setMinutes(new Date(), 30), 20),
}) {
  return (
    <>
      <label className="labelInput" htmlFor={`nameFake_${name}`}>
        {labelText}
      </label>

      <DatePicker
        readOnly={readOnly}
        id={`idFake_${id}`}
        name={`nameFake_${name}`}
        selected={date}
        dateFormat="dd/MMM/yyyy HH:mm "
        className={`${readOnly === true ? "inputBlock" : ""}`}
        locale="es"
        showTimeSelect
        showTimeSelectOnly
        minTime={minTime}
        maxTime={maxTime}
        timeIntervals={10}
        injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
        onChange={(date, e) => {
          // setDate(date);
          inputChange(date, e);
        }}
        // onChangeRaw={input}
      />

      <input
        type="hidden"
        ref={forwardRef}
        name={name}
        id={id}
        onChange={(e) => {
          inputChange(e);
        }}
        // value={dateToDbFormat(new Date(date))}
        value={dateToDbFormat(new Date(date))}
      />
    </>
  );
}
export function InputTextArea({
  labelTxt,
  placeholderTxt,
  aditionalClasses,
  name,
  id,
  forwardRef,
  disabled = false,
  maxLength = 200,
  onEnter = () => {},
  readonly = false,
  defaultValue = null,
  onChange = () => {},
  cols,
  value,
  rows,
  errors = {},
}) {
  useEffect(() => {
    setValue(id, defaultValue);
  }, [defaultValue]);

  return (
    <div className={`${aditionalClasses}`}>
      <label id={`lbl_${id}`} className="labelInput" htmlFor={name}>
        {labelTxt}
      </label>
      <div>
        {/* <div className="leftSideInput d-flex align-items-center"></div> */}

        <textarea
          readOnly={readonly}
          disabled={disabled}
          id={id}
          name={name}
          className={` ${readonly === true ? "inputBlock" : ""}`}
          placeholder={placeholderTxt}
          cols={cols}
          maxLength={maxLength}
          minLength={1}
          rows={rows}
          onChange={(e) => onChange(e.target.value)}
          ref={forwardRef}
          value={value}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              onEnter();
            }
          }}
        />

        <ErrorMessage
          id={`error-${id}`}
          message={errors?.[name]?.["message"]}
        />
      </div>
    </div>
  );
}

/**
 *
 * @param {object} props - Props
 * @param {string} [props.id=null] - Id input
 * @param {string} [props.name=null] - Name input
 * @param {string} [props.htmlFor=null] - For atrribute on the label
 * @param {boolean} [props.defaultChecked=null] - Value for the toggle
 * @param {string} [props.labelTrue=null] - Label when is active
 * @param {string} [props.labelFalse=null] - Label when is inactive
 * @param {object} [props.forwardRef={}] - Register for react-hook-form
 * @param {(value:boolean)=>void} props.onChange - Callback when the value changes
 */
export function InputToggle({
  id,
  name,
  htmlFor,
  defaultChecked = true,
  labelTrue,
  labelFalse,
  forwardRef,
  onChange = () => {},
  oc,
}) {
  const [checked, setChecked] = useState({
    check: defaultChecked,
    labelText: labelTrue,
  });
  useEffect(() => {
    onChange(checked.check);

    checked.check
      ? setChecked({
          ...checked,
          labelText: labelTrue,
        })
      : setChecked({
          ...checked,
          labelText: labelFalse,
        });
  }, [checked.check]);
  return (
    <div className="d-flex aling-items-center ">
      <Toggle
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        className="aling-sel-center"
        onChange={() => {
          setChecked({
            ...checked,
            check: !checked.check,
          });
        }}
        // oc()
      />
      <input
        type="hidden"
        ref={forwardRef}
        name={name}
        id={id}
        value={checked.check}
      />

      <label htmlFor={htmlFor} className="ml-2">
        {checked.labelText}
      </label>
    </div>
  );
}
