import React from "react";
import ui from "./styles.module.scss";

/**
 *
 * @param {import('./types').PropsTopModule} props - Props
 * @returns
 */
export default function TopModule({ children = <></> }) {
  return <div className={ui.header}>{children}</div>;
}
