import React, { useRef } from "react";
import MobileCard from "../MobileCard";
import style from "./styles.module.scss";

/**
 * Render the items for the mobile view, just need the items to render
 * @param {import('./types').PropsMobileItems} props - Props
 * @returns {JSX.Element}
 */
export default function MobileItems({ items = [] }) {
  const key = useRef(window.crypto.randomUUID());

  return (
    <div className={style.containerMobileBankCards}>
      {items.map((item, i) => (
        <MobileCard
          key={`${key.current}-${i}`}
          account={item.account}
          currency={item.currency}
          bank={item.bank.shortName}
          id={item.id}
          residue={item.currentBalance}
          bankKey={item.bank.clave}
        />
      ))}
    </div>
  );
}
