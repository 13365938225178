import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { fetchComments } from "helpers/Apis/Documents";

/**
 * @param {number} idDoc - Id of the document to fetch the comments
 */
export default function useComments(idDoc, onChange) {
  /**
   * State for the comments to handle
   * @type {[import("types/typedef/customHooks/useComments").useCommentsI,()=>void]}
   */
  const [comments, setComments] = useState([]);

  const formNode = useRef();

  const idInput = useRef(uuidv4()).current;

  const [isEditing, setIsEditing] = useState({
    editing: false,
    id: null,
    index: null,
  });

  /**
   * @type {[(string|number)[],()=>void]}
   */
  const [commentsToDelete, setCommentsToDelete] = useState([]);

  useEffect(() => {
    (async function () {
      const apiComments = await fetchComments(idDoc);
      setComments(apiComments);
    })();
  }, []);

  useEffect(() => {
    onChange({
      comments,
      commentsToDelete,
    });
  }, [comments]);

  /**
   * Delete a comment from the component
   * @param {number|string} id - Id of the comment to delete
   */
  const deleteComment = (id) => {
    if (typeof id === "number") {
      setCommentsToDelete([...commentsToDelete, id]);
    }

    const updatedComments = comments.filter((comment) => comment.id !== id);

    setComments(updatedComments);
  };

  const handleComment = ({ comment }) => {
    if (isEditing.editing) {
      updateComment();
    } else {
      addComment();
    }

    function updateComment() {
      const copyComments = [...comments];
      copyComments[isEditing.index] = {
        ...copyComments[isEditing.index],
        name: comment,
      };
      setComments(copyComments);

      setIsEditing({
        editing: false,
        id: null,
        index: null,
      });

      formNode.current.reset();
    }

    function addComment() {
      const newOrderComments = comments.map((comment, i) => ({
        ...comment,
        order: i + 2,
      }));

      setComments([
        {
          name: comment,
          id: uuidv4(),
          idDocument: idDoc,
          order: 1,
          isNewComment: true,
        },
        ...newOrderComments,
      ]);

      formNode.current.reset();
    }
  };

  const setValueInput = (value) => {
    const input = document.getElementById(idInput);
    input.value = value;
  };

  return {
    comments,
    setComments,
    isEditing,
    setIsEditing,
    handleComment,
    formNode,
    commentsToDelete,
    setCommentsToDelete,
    setValueInput,
    deleteComment,
    idInput,
  };
}
