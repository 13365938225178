import React, { useContext, useRef } from "react";
import { IvasContextReport } from "..";
import ui from "./styles.module.scss";
import { mnCurrency } from "helpers/money";

/**
 * Render the column for the totals
 * @param {import('./types').PropsTotal} props - Props
 * @returns {JSX.Element}
 */
export default function Totals({ type = "ingreso" }) {
  const hook = useContext(IvasContextReport);

  const indexedObject = useRef({
    ingreso: "totalIncomes",
    egreso: "totalOutcomes",
  });

  if (hook[indexedObject.current[type]] === undefined) return <></>;

  return (
    <div className={ui.containerTotals}>
      <p></p>
      <p>TOTALES</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].mxnImp)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].mxnIva)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].mxnTot)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].usdImp)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].usdIva)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].usdTot)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].tcImp)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].tcIva)}</p>
      <p>{mnCurrency(hook[indexedObject.current[type]].tcTot)}</p>
    </div>
  );
}
