import { mnCurrency } from "helpers/money";
import React from "react";

/**
 *
 * @param {import('./types').PropsAmount} param0
 * @returns
 */
export default function Amount({ amount = 0, label = "" }) {
  return (
    <div>
      <p className="text-left">
        <b>{label}</b>
      </p>
      <p>{mnCurrency(amount)}</p>
    </div>
  );
}
