import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import CreateInvoiceV2 from "structure/CreateInvoiceV2";
import { useHistory } from "react-router-dom";

export default function OrderAuth() {
  const { pedido, folio } = useQueryParams();

  const history = useHistory();

  const redirectToHome = () => {
    history.push("/inicio");
  };

  return (
    <>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/",
            text: `Timbrar pedido ${folio}`,
          },
        ]}
      />

      <div className="container my-5">
        <CreateInvoiceV2 id={+pedido} onCreated={redirectToHome} />
      </div>
    </>
  );
}
