import Select from "components/individual/SelectV4";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ConcilationContext } from "..";

export default function StatusAccounted() {

  const hook = useContext(ConcilationContext);

  return (
    <div>
      <Label>Contabilizacion</Label>
      <Select
        labelToDisplay="label"
        onChange={option=>hook.setAccountingState(option.value)}
        options={async () => [
          {
            label: "Todos",
            value: null,
          },
          {
            label: "Contabilizado",
            value: true,
          },
          {
            label: "No Contabilizado",
            value: false,
          },
        ]}
      />
    </div>
  );
}
