import ButtonV2 from "components/individual/ButtonsV2/Button";
import { InputText } from "components/individual/inputs/Inputs";
import { FilterContainer } from "pages/Administration/InvoiceReception/Filter/styles";
import React from "react";

export const SearchProviders = ({ refetchTable, updateQuerySearch }) => {
  return (
    <div className="mb-2">
      <FilterContainer
        id="providersFilter"
        onSubmit={(e) => {
          e.preventDefault();
          refetchTable();
        }}
      >
        <InputText
          id="searchProvider"
          labelTxt="Proveedor"
          placeholderTxt="Escribe aqui"
          aditionalClasses="mr-2"
          onChange={(value) =>
            updateQuerySearch(value.length <= 0 ? "-1" : value)
          }
        />
        <div className="mx-2">
          <ButtonV2 form="providersFilter">Buscar</ButtonV2>
        </div>
      </FilterContainer>
    </div>
  );
};
