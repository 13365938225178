import React, { useContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useHome, { HOME_CONTEXT_VALUE } from "./Hooks/useHome";
import { createContext } from "react";
import LoginContext from "context/Login/LoginContext";
import QuotesIndex from "./QuotesIndex";
import style from "./styles.module.scss";
import TicketsApp from "pages/Tickets";

/**
 * @type {import("react").Context<import("./Hooks/useHome/types").ReturnStateHome>}
 */
export const CONTEXT_HOME = createContext(HOME_CONTEXT_VALUE);

function Inicio() {
  const hook = useHome();

  const { userInfo } = useContext(LoginContext);

  if (!Array.isArray(userInfo)) return <></>;

  if (userInfo.length <= 0) return <></>;

  if (userInfo[0].ticketRol === "customer")
    return (
      <CONTEXT_HOME.Provider value={hook}>
        <div className={style.containerHomeQuotes}>
          <BreadCrumV2 path={hook.breadcrum} />
          <QuotesIndex />
        </div>
      </CONTEXT_HOME.Provider>
    );

  return (
    <>
      <BreadCrumV2 path={hook.breadcrum} />
      <TicketsApp renderBreadcrum={false} />
    </>
  );
}

export default Inicio;
