import React from "react";
import { RadioButton } from "../../../../../components/individual/inputs/Radio";
import { MethodCalculate } from "./Styles";
import style from './styles.module.scss';

export default function CalculateMode({ onChange = () => {}, value = 1 }) {
  return (
    <div className={style.container}>
      <p className="my-0">
        <b>Metodo de calculo de parcialidades: </b>
      </p>

      <MethodCalculate>
        <RadioButton
          optSelected={value}
          options={[
            { label: "Porcentaje", value: 1 },
            { label: "Importe", value: 2 },
          ]}
          onClick={({ value }) => onChange(value)}
          attributeId="value"
          attributeText="label"
          attributeValue="value"
          id="calculateMethod"
          name="calculateMethod"
          idElements="container-calculate-method"
        />
      </MethodCalculate>
    </div>
  );
}
