import useDialog from "customHooks/useDialog";
import React, { useEffect, useRef } from "react";
import { useIdle } from "react-use";
import Dialog from "structure/Dialog";
import TimeCounter from "../TimeCounter";
import ui from "./styles.module.scss";

/**
 * Render a component to trigger close session for iddle
 * @param {import("./types").PropsIdle} props - Props
 * @returns {JSX.Element}
 */
export function Idle({ minutes = 60 }) {
  const timeIdle = useRef(minutesToMilliseconds(minutes));

  const timeIdleModal = useRef(minutesToMilliseconds(calculatePercentage(minutes,80)));

  const isIddleForShowWarn = useIdle(timeIdleModal.current);

  const isIddleForSession = useIdle(timeIdle.current);

  const dialog = useDialog();

  /**
   * Percentage
   * @param {number} number - Number to calculate
   * @param {number} percentage - Percentage to calculate for that number
   * @returns {number}
   */
  function calculatePercentage(number, percentage) {
    return (number * percentage) / 100;
  }


  useEffect(() => {
    if (isIddleForShowWarn) {
      dialog.open();
    } else {
      dialog.close();
    }
  }, [isIddleForShowWarn]);

  useEffect(() => {
    if (isIddleForSession) {
      // Get the current URL
      const currentUrl = window.location.href;

      // Split the URL by '/'
      const urlParts = currentUrl.split("/");

      // Get the root domain (first two parts of the URL)
      const rootDomain = urlParts.slice(0, 3).join("/");

      // Navigate to the root domain
      window.location.href = rootDomain;

      // history.push(`/`);
    }
  }, [isIddleForSession]);

  /**
   * Convert minutes to ms unit
   * @param {number} minutes - Minutes
   * @returns {number}
   */
  function minutesToMilliseconds(minutes) {
    return minutes * 60 * 1000; // 60 seconds * 1000 milliseconds
  }

  if (isIddleForShowWarn)
    return (
      <Dialog
        title="Alerta de inactivdad"
        width={50}
        forwardRef={dialog.refDialog}
      >
        <p className={ui.iddleMessage}>
          ⚡ La sesión cerrara pronto si no hay actividad en el equipo en
          <TimeCounter initialTime={timeIdle.current - timeIdleModal.current} />
          ⚡
        </p>
      </Dialog>
    );
}
