import React from "react";
import Back from "structure/Menu/Back";
import RedirectButton from "structure/Menu/Redirect";

export default function Anticipos() {

  return (
    <>
      <Back menu="banks" text="Inicio > Bancos" />

      <RedirectButton
        permission="e728c668-64b0-48b7-ac78-7b3fd99c7908"
        text="Clientes"
        to={"/v2/bancos/anticipos/clientes"}
      />

      <RedirectButton
        permission="eca375ee-6514-4296-bce1-7ad873b8d040"
        text="Proveedores"
        to={"/v2/bancos/anticipos/proveedores"}
      />
    </>
  );
}
