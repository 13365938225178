import SocialReason from "components/general/Forms/Selects/SocialReasonInvoice";
import InformativeTooltip from "components/individual/InfoTooltip";
import ToggleComponent from "components/individual/inputs/Toggle";
import Select from "components/individual/SelectV4";
import useHandleFeException from "customHooks/useHandleFeException";
import { addDays } from "date-fns";
import GridPartialities from "pages/Directory/Documents/Invoice/GridPartialities/GridPartialities";
import React from "react";
import { Label } from "structure/Document";
import { Files, DropZone, FileList } from "structure/Files";
import ui from "./styles.module.scss";
import Money from "components/general/Forms/Money";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function FeCorreccion() {
  const hook = useHandleFeException();

  const { allowedExtensions, files, setFiles, setState, state } = hook;

  return (
    <div className={`${ui.containerFeCorrection} `}>
      <div>
        <b>
          Razón social{" "}
          <InformativeTooltip>
            <p>
              Seleccionar el cliente correcto a quien corresponde la factura
              para poder asociar con Praxia el id del directorio a quien
              corresponde
            </p>
          </InformativeTooltip>
        </b>
        <SocialReason
          creatable={false}
          type="cliente"
          includeNoActives={false}
          isClearable={false}
          onChange={(customer) =>
            setState((current) => ({
              ...current,
              creditDays: customer.creditDays,
              customer: customer.id,
            }))
          }
        />
      </div>

      <div>
        <b>
          ID del pedido{" "}
          <InformativeTooltip>
            <p>El id del pedido que se quedo a medias su timbrado</p>
            <p>
              Este pedido se marcara timbrado completado este proceso del
              formulario
            </p>
          </InformativeTooltip>
        </b>

        <input
          value={state.idPedido}
          placeholder="Escribe aquí (Obligatorio)"
          type="number"
          onKeyDown={(e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              e.preventDefault();
            }
          }}
          onChange={(e) => hook.setIdPedido(e.target.value)}
        />
      </div>

      <div>
        <Label>
          Cambio de moneda
          <InformativeTooltip>
            <p>
              Este dato se puede obtener consultando el log y viendo el TC que
              se intento usar para el timbre
            </p>
          </InformativeTooltip>
        </Label>
        <ToggleComponent
          onChange={(value) => hook.setRequireCurrencyExchange(value)}
          isChecked={state.requireCurrencyExchange}
          labelActive="Si, si requiere"
          labelInactive="No, no requiere"
        />
      </div>

      <div>
        <b>
          TC{" "}
          <InformativeTooltip>
            <p>
              Este dato viene del XML, sin embargo, puede ser cambiado para los
              pedidos MXN que no requieren cambio de moneda
            </p>
            <p>para mantener el TC del documento</p>
          </InformativeTooltip>
        </b>
        <Money
          placeholder="0.00"
          value={state.tc}
          onChange={(value) => hook.setTcp(value)}
          maxDecimals={2}
        />
      </div>

      <div>
        <b>
          Parcialidades{" "}
          <InformativeTooltip>
            <p>Este dato se toma del log de errores en el timbre</p>
          </InformativeTooltip>
        </b>
        <Select
          labelToDisplay="value"
          onChange={(value) =>
            setState({ ...state, partialities: value.value })
          }
          options={async () => [
            {
              value: 1,
            },
            {
              value: 2,
            },
            {
              value: 3,
            },
            {
              value: 4,
            },
            {
              value: 5,
            },
            {
              value: 6,
            },
            {
              value: 7,
            },
            {
              value: 8,
            },
            {
              value: 9,
            },
            {
              value: 10,
            },
            {
              value: 11,
            },
            {
              value: 12,
            },
          ]}
        />
      </div>

      {typeof state.customer === "number" && (
        <GridPartialities
          disableDatesRestriction={true}
          partialities={state.partialities}
          tc={state.tc}
          beginDate={addDays(new Date(), state.creditDays)}
          onChange={(cxc) => setState({ ...state, cxc })}
          currency={state.currency}
          totalAmountPreinvoice={state.total}
        />
      )}

      <Files
        maxFiles={2}
        allowedExtensions={allowedExtensions.current}
        files={files}
        onFilesSelected={setFiles}
      >
        <DropZone />
        <FileList />
      </Files>

      <div>
        <b>
          PAC respuesta JSON{" "}
          <InformativeTooltip>
            <p>
              Dentro del LOG de praxia, identificar el json de respuesta exitosa
              para el pedido que se quedó
            </p>
            <p>
              timbrado a medias en el sistema para sincronizar la informacion
              correctamente.
            </p>
            <p>La información debe coincidir con el XML subido</p>
          </InformativeTooltip>
        </b>
        <textarea
          value={state.jsonPac}
          onChange={(e) => hook.setJsonPac(e.target.value)}
          placeholder="Escribe aquí (Obligatorio)"
        ></textarea>
      </div>

      <div>
        <ButtonV2
          disabled={
            state.isLoading || files.length !== 2 || state.jsonPac === ""
          }
          onClick={hook.handleCorrection}
        >
          <span>
            {state.isLoading ? "Corrigiendo, espere..." : "Corregir pedido"}
          </span>
        </ButtonV2>
      </div>
    </div>
  );
}
