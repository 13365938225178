import GridImports from "components/general/GridImports";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import useStep2 from "../hooks/useStep2";
import { Step1Context } from "../Step1/Step1";
import { useParams } from "react-router-dom";
import { mnCurrency } from "helpers/money";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { CustomerInfo } from "components/individual/CustomerInfo";
import { Invoice } from "pages/Banks/Movements/Consolation/Styles";

export default function EgressStep2() {
  const { movement } = useParams();
  const { bankAccount, infoRow } = useContext(Step1Context);
  const {
    cxps,
    isLoading,
    setGridInfo,
    movementInfo,
    gridInfo,
    asociateConcilations,
    isAssociating,
  } = useStep2();

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-end justify-content-between">
          <div>
            <h4>Información del Egreso</h4>
            <p className="mb-3">
              <b>Egreso No:</b>
              {movement} |<b> Pagado a: </b>
              <CustomerInfo idCustomer={infoRow.id}>
                {infoRow.socialReason}
              </CustomerInfo>
              |
              {movementInfo !== null ? (
                <>
                  <b>Importe total: </b>
                  <span>{mnCurrency(movementInfo.amount)} | </span>

                  <b>Aplicado:</b>
                  <span>
                    {gridInfo === null
                      ? "$0.0"
                      : mnCurrency(gridInfo.totalApplied)}{" "}
                    |{" "}
                  </span>

                  <b>Saldo por asociar:</b>
                  {gridInfo === null ? (
                    <span>{mnCurrency(movementInfo.saldo)}</span>
                  ) : (
                    <span
                      className={
                        gridInfo.isNegativeResidue ? "text-danger" : ""
                      }
                    >
                      {mnCurrency(gridInfo.newResidue)}
                    </span>
                  )}
                </>
              ) : null}
            </p>
            <hr />
          </div>
        </div>

        {isLoading ? (
          <Spinner
            hidden={false}
            idSpinner={window.crypto.randomUUID()}
            text="Cargando CxP..."
          />
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <h4 className="mb-5">
                Facturas emitidas por el proveedor, no asociadas a conceptos de
                gastos.
              </h4>
              {gridInfo !== null &&
              gridInfo.totalApplied > 0 &&
              // gridInfo.isValidResidue &&
              !gridInfo.isNegativeResidue ? (
                isAssociating ? (
                  <Spinner
                    hidden={false}
                    idSpinner={window.crypto.randomUUID()}
                    text="Asociando CxP..."
                  />
                ) : (
                  <ButtonV2 onClick={async () => await asociateConcilations()}>
                    Asociar
                  </ButtonV2>
                )
              ) : null}
            </div>
            <GridImports
              currency={bankAccount.currencyCode}
              onChange={(grid) => setGridInfo(grid)}
              residue={movementInfo.saldo}
              amountsInfo={cxps}
              toleranceProp={0.99}
              headers={[
                "Factura",
                "Parcialidad",
                "Moneda",
                "Total",
                "Pagos previos",
                // "Saldo Actual",
                "Pago a aplicar",
                "TC",
                "Saldo CxP",
                // "Saldo Nuevo",
              ]}
            >
              {({ index }) => (
                <>
                  {/* <Invoice
                    target={"_blank"}
                    to={`../../../../../directorio/documentos/123/ver/${cxps[index].id}`}
                  > */}

                  <div className="d-flex justify-content-center align-items-center">
                    {cxps[index].folio}
                  </div>
                  {/* </Invoice> */}
                  <div className="d-flex justify-content-center align-items-center">
                    {cxps[index].partialitie}
                  </div>
                  <GridImports.Currency index={index} />
                  <GridImports.Total index={index} />

                  {/* ///// */}
                  {/* <div className="d-flex justify-content-center align-items-center"> */}
                  {/* {cxps[index]} */}
                  {/* </div> */}
                  {/* <GridImports.DocNumber index={index} /> */}
                  <div className="d-flex justify-content-center align-items-center">
                    {mnCurrency(
                      cxps[index].total.number - cxps[index].residue.number
                    )}
                  </div>

                  {/* <GridImports.Saldo index={index} /> */}
                  <GridImports.InputImport index={index} label="" />
                  {/* <GridImports.Applied index={index} /> */}
                  <GridImports.InputTC
                    index={index}
                    label=""
                    bankCode={bankAccount.currencyCode}
                  />
                  <GridImports.Saldo index={index} />
                  {/* <GridImports.NewResidue index={index} /> */}
                  {/* <GridImports.TC index={index} /> */}
                </>
              )}
            </GridImports>
          </>
        )}
      </div>
    </>
  );
}
