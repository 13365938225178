import React, { useRef, forwardRef } from "react";
import { Label } from "structure/Document";

/**
 * Select input for the proyect status that can be handled on a proyect or position
 * @type {import("./types").ForawrdedPropsProyectStatus}
 */
const ForwardedProyectStatus = forwardRef((props, ref) => {
  /**
   * @type {import("react").MutableRefObject<import("./types").ProyectStatusOptionI[]>}
   */
  const options = useRef([
    {
      label: "Todos",
      value: null,
    },
    {
      label: "Urgente",
      value: "SolicitudUrgente",
    },
    {
      label: "Solicitud",
      value: "SolicitudNormal",
    },
    {
      label: "Propuesta",
      value: "Propuesta",
    },
    {
      label: "Activo",
      value: "Activo",
    },
    {
      label: "Terminado",
      value: "Terminado",
    },
    {
      label: "No Asignado",
      value: "NoAsignado",
    },
    {
      label: "Cancelado",
      value: "Cancelar",
    },
  ]);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div>
      <Label>Estatus</Label>
      <select {...props} ref={ref} className={`select-css ${props.className}`}>
        {options.current.map((option, i) => (
          <option
            disabled={
              option.label === "Todos" && props?.disableTodos ? true : false
            }
            value={option.value}
            key={`${key.current}-${i}`}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default ForwardedProyectStatus;
