import { YesNoAlert } from "helpers/alerts";
import { cancelOdcDocument } from "pages/Administration/ODC/Options/helpers/odcHelper";
import { useState } from "react";

/**
 * Hook to cancel a odc
 * @param {number} idOdc - Id of the odc to cancel
 * @param {()=>void} [onCancelled=()=>{}] - Callback to execute once the odc is cancel with success     
 * @returns {import("./types").ReturnCancelOdc} Values of the hook
 */
export default function useCancelOdc(idOdc, onCancelled = () => {}) {
  const [state, setState] = useState({
    isCancelling: false,
  });

  const promptCancelOdc = async () =>
    YesNoAlert(
      "¿Estas seguro de cancelar la orden de compra?",
      performCancelOdc
    );

  const performCancelOdc = async () => {
    setState({
      ...state,
      isCancelling: true,
    });

    const wasCancelled = await cancelOdcDocument(idOdc); //!Eliminar

    if (wasCancelled) onCancelled();

    setState({
      ...state,
      isCancelling: false,
    });
  };

  return {
    ...state,
    promptCancelOdc,
  };
}
