import { CustomTutorial } from "components/general/CustomTutorial";
import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import { Tutorial } from "structure/Tutorial";
import style from "./styles.module.scss";
export default function JamTutorial() {
  const dialog = useDialog();

  return (
    <>
      <p onClick={dialog.open} className={style.tutorialAnchor}>
        Guía: Adjuntar "Liga de JAM"
      </p>

      <Dialog
        title='Guia: Capturar reporte y adjuntar "Liga de JAM"'
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
      width={60}
      >
        <p>
          <b>NOTA: </b>
          Esta herramienta solo esta disponible en los navegadores de Google
          Chrome y Microsoft Edge
        </p>

        <Tutorial
          chrome={
            <CustomTutorial
              folder="images/jam/chrome/"
              labels={[
                "Instalacion",
                "Instalacion",
                "Instalacion",
                "Configuracion",
                "Configuracion",
                "Capturar pantalla",
                "Capturar pantalla",
                "Copiar liga",
                "Llenar reporte",
                "Llenar reporte",
              ]}
            />
          }
          edge={
            <CustomTutorial
              folder="images/jam/chrome/"
              labels={[
                "Instalacion",
                "Instalacion",
                "Instalacion",
                "Configuracion",
                "Configuracion",
                "Capturar pantalla",
                "Capturar pantalla",
                "Copiar liga",
                "Llenar reporte",
                "Llenar reporte",
              ]}
            />
          }
        />
      </Dialog>
    </>
  );
}
