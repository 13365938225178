import React, { useState } from "react";
import { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export const WinQuoteButton = () => {
  const [loading, setLoading] = useState(false);
  const { handleWinQuote } = useContext(WinQuoteContext);
  return (
    <>
      {loading ? (
        <Spinner
          idSpinner="winDocument"
          hidden={false}
          text={<LoadingText>Ganando cotizacion</LoadingText>}
        />
      ) : (
        <ButtonV2
          onClick={async () => {
            setLoading(true);
            await handleWinQuote();
            setLoading(false);
          }}
        >
          <span>Ganar</span>
          <Icon icon="save"/>
        </ButtonV2>
      )}
    </>
  );
};
