import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import { DirectoryContext } from "..";

export default function CreateCustomer() {

  const hook = useContext(DirectoryContext);

  return (
    <ButtonV2 type="button" onClick={hook.redirectAdd}>
      <span>Agregar</span>
      <Icon icon="plus" />
    </ButtonV2>
  );
}
