import ButtonV2 from "components/individual/ButtonsV2/Button";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { ContextEmitedCreditNotes } from "../..";
import { saveAs } from "file-saver";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { openFileOnNewTab } from "helpers/dom";

export default function DownloadPdf() {
  const hook = useContext(ContextEmitedCreditNotes);

  const [state, setState] = useState(false);

  const handleDownloadPdf = async () => {
    setState(true);
    const pdf = await downloadFileFromBlobV3(hook.creditNote.pdf);

    saveAs(pdf, `Nota de crédito ${hook.creditNote.documentNumber}.pdf`);

    openFileOnNewTab(pdf);
    setState(false);
  };

  return (
    <div>
      {state ? (
        <Spinner
          hidden={false}
          idSpinner="downloadingPdf"
          text={<LoadingText>Descargando</LoadingText>}
        />
      ) : (
        <ButtonV2 onClick={handleDownloadPdf}>PDF</ButtonV2>
      )}
    </div>
  );
}
