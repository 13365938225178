import React from 'react'
import { SocialReason } from '../Inputs/General';


export default function AddService() {
    return (
        <div>
            <SocialReason/>
        </div>
    )
}
