import React from "react";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function License() {
  return (
    <div className={ui.licenseValidation}>
      <div className={ui.loadingMessage}>
        <Spinner hidden={false} text={"Validando licencia"} />
      </div>
    </div>
  );
}
