import "../types/typedef/money";
// import n2words from 'n2words/dist/n2words.js';

/**
 * Parse a number/float to mexican coin
 *
 * @param {number} money - Number to format into money
 * @param {number?} decimals - Decimals to show
 * @returns {string} Formated number as currency
 */
export function mnCurrency(money, decimals = 2) {

  const truncatedData = truncateDecimals(money,decimals);

  const precioCurrency = new Intl.NumberFormat("es-Mx", {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(truncatedData);

  if (money < 0) {
    return `${precioCurrency}`;
  }

  return precioCurrency;
}

/**
 *  Function to validate against yup resolver for the react component
 * @param {string} valueInput - Value input with currency text
 * @returns {number}
 * @example
 * resolverAmountMoney('') // => 0
 * resolverAmountMoney('$1.2') // => 1.2
 */
export function resolverAmountMoney(valueInput) {
  if (valueInput === "") return 0;

  const parsed = valueInput.replaceAll("$", "");
  const parsedB = parsed.replaceAll(",", "");

  if (parsedB === "$" || parsedB === "") return 0;

  return +parsedB;
}

/**
 * Parse input currency to number
 * @param {string} inputValue - Value
 * @returns {number}
 */
export function parseCurrencyInput(inputValue) {
  if (inputValue.length <= 0) return 0;

  const string = inputValue.replaceAll("$", "");

  return +string;
}

/**
 * Parse a dollar currency into float javascript
 *
 * @param {string} number - Number with strings and symbols
 * @returns {number} Parsed number to float
 */
export function parseToFloat(number) {
  let parsedNumber = number.replaceAll("$", "");
  parsedNumber = parsedNumber.replaceAll(",", "");

  return parseFloat(parsedNumber);
}

/**
 * Calculate the total price of an item applying a
 * percentage discount
 *
 * @param {number} price - Price of the item/product/service
 * @param {number} discount - Discount to apply
 * @param {number?} decimals - Number of decimals to calculate the price
 * @returns {Discount} Discount applied
 */
export function calculateDiscount(price, discount, decimals = 2) {
  const newSellPrice =
    +price.toFixed(decimals) - (+price.toFixed(decimals) * discount) / 100;

  const parsed = +newSellPrice.toFixed(decimals);

  return {
    total: parsed,
    totalString: mnCurrency(parsed),
  };
}

/**
 * Calculate the percentage
 * @param {number} amount - Amount to calculate the percentage
 * @param {number} percentage - Between 0 and 100
 * @param {number?} decimals - Number of decimals to use on the calculation
 * @returns {number}
 */
export function calculatePercentage(amount, percentage, decimals = 2) {
  return truncateDecimals(
    truncateDecimals(percentage / 100, decimals) * amount,
    decimals
  );
}

/**
 * Get the corresponding discount (on money terms) for an item
 * @param {number} unitaryPrice - P.U or C.U.
 * @param {number} discount - Between 0-100
 * @returns {number}
 */
export function calculateDiscountToApply(unitaryPrice, discount) {
  const sellPrice = +(unitaryPrice - (unitaryPrice * discount) / 100).toFixed(
    2
  );
  return +(unitaryPrice - sellPrice).toFixed(2);
}

/**
 * Calculate the iva price from determinated items
 *
 * @param {number} quantity - Number of items
 * @param {number} price - Price of the item, with discount applied
 * @param {number} ivaPercentage - Iva percentage to apply
 * @returns {number} IVA price to be applied on the item(s)
 */
export function calculateIvaPrice(quantity, price, ivaPercentage) {
  return quantity * price * (ivaPercentage / 100);
}

/**
 * Calculate the iva of the "importe" item
 * @param {number} importe - "Importe" of the item(s)
 * @param {number} ivaPercentage - Iva percentage to apply
 * @returns {number} Iva of the import
 */
export const calculateIva = (importe, ivaPercentage) =>
  percentage(importe, ivaPercentage);

/**
 * Calculate unit price sell of a document item
 *
 * @param {number} unitPrice - Unit price of item
 * @param {number=0} discount - Discount to apply
 * @returns {number} Price applied
 */
export function calculateUnitPriceSell(unitPrice, discount = 0) {
  return unitPrice - unitPrice * (discount / 100);
}

/**
 * Validate the discount it's a valid one
 *
 * @param {number} discount - Discount to apply
 * @returns {boolean} True if it's a valid discount
 */
export function validateDiscountRange(discount) {
  if (discount > 100 || discount < 0) return false;
  return true;
}

/**
 * Validate the discount of the costs types
 * @param {object[]} ocItems - List of costs to validate
 * @param {object[]} quoteItems - List of costs from the quote
 * @returns {{
 * isValidDiscount:boolean,
 * isValidCost:boolean
 * }} Validation flags made
 */
export function validateOcItems(ocItems, quoteItems) {
  let isValidDiscount = true;
  let isValidCost = true;

  ocItems.forEach((item, i) => {
    if (item.discount.number >= 100 || item.discount.number < 0) {
      isValidDiscount = false;
    }

    if (item.cu.number >= quoteItems[i].pu.number) {
      isValidCost = false;
    }
  });

  return { isValidDiscount, isValidCost };
}

/**
 * Validate a price it's not negative
 * @param {number} price - Price of the input
 * @returns {boolean} True if it's a valid price
 */
export const validatePrice = (price) => {
  if (price <= 0 || isNaN(price)) return false;
  return true;
};

/**
 * Parse string discount to number
 *
 * @param {string} value - Discount with the symbol of %
 * @returns {number} Discount parsed as number
 */
export function parseDiscount(value) {
  const parsed = +value.replaceAll("%", "");
  return parsed;
}

/**
 * Calculate the subtotal of the item
 * @param {number} importe - "Importe" of the item(s)
 * @param {number} iva - Iva (on money terms)
 * @returns {number}
 */
export const calculateSubtotal = (importe, iva) => importe + iva;

/**
 * Unit price * Quantity (Not includes the discount)
 * @param {number} unitPrice - Unit price of the item
 * @param {number} quantity - Items requested
 * @returns {number} Subtotal
 *
 * @example
 * // unitPrice * quantity
 * calculateSubtotalFacturama(12.69,4) //50.76
 */
export const calculateSubtotalFacturama = (unitPrice, quantity) =>
  unitPrice * quantity;

/**
 * Calculate the "precio unitario venta"
 * @param {number} pu - "Precio unitario" of the item
 * @param {number?} discount - Discount to apply on the item
 * @returns {number} "Precio unitario venta" of the item
 */
export const calculatePuVenta = (pu, discount = 0) =>
  calculateDiscount(pu, discount).total;

/**
 * Calculate the "costo unitario venta"
 * @param {number} cu - "Costo unitario" of the item
 * @param {number?} discount - Discount to apply on the item
 * @returns {number} "Costo unitario venta" of the item
 */
export const calculateCuVenta = (cu, discount = 0) =>
  calculateDiscount(cu, discount).total;

/**
 * Calculate the import of the item
 * @param {number} ventaCostoPrice - "Costo venta o precio venta" of the item (discount already must be applied)
 * @param {number} quantity - Quantity of items requested
 * @returns {number}
 */
export const calculateImport = (ventaCostoPrice, quantity) =>
  ventaCostoPrice * quantity;

/**
 * Exchange the currency money data to another
 *
 * @param {number} money - Value of the money source to convert
 * @param {number} equivalence - Value to use to convert. For instace, if trying to convert MXN - USD , must indicate that 1USD equals to 20.99MXN.
 * @param {CurrenciesCode} from - The currency that uses the value to convert
 * @param {CurrenciesCode} to - To which currency will be converted the money
 * @param {number} [decimals=2] - Decimals in case of convertion
 * @returns {number} Converted money
 */
export function convertPrice(money, equivalence, from, to,decimals=2) {
  
  if (
    isNaN(money) ||
    money === null ||
    from === undefined ||
    to === undefined ||
    equivalence === null
  ) {
    console.error(`Error, "money" argument wasn't provided correctly`);
    console.error({
      lessCero: money < 0,
      isNaN: isNaN(money),
      moneyData: money,
      equivalenceData: equivalence,
      fromData: from,
      toData: to,
    });
    return money;
  }

  // Same equivalence, don't need to convert
  if (
    from === to ||
    from === null ||
    from === null ||
    to === null ||
    to === undefined
  ) {
    return money;
  }

  const converter = {
    MXN: {
      USD: () => +(money / equivalence).toFixed(decimals),
    },

    USD: {
      MXN: () => +(money * equivalence).toFixed(decimals),
    },
  };

  const convertedMoney = converter[from][to]();

  console.log({convertedMoney,money,
    equivalence})

  return convertedMoney;
}

/**
 * Calculate the percentage of a number
 * @param {number} baseNumber - Number to apply the percentage
 * @param {number} percentage - Percentage to use on the number
 * @returns {number} Percentage of the number requested
 */
export const percentage = (baseNumber, percentage) =>
  (percentage / 100) * baseNumber;

/**
 * Get the total amount of the document items
 *
 * @param {object[]} items - Items
 * @returns {number} Total amount of the document
 */
export const calculateTotalDocument = (items) =>
  items.reduce((sumatory, item) => (sumatory += item.subtotal.number), 0);

/**
 * Update the exchange items of the document for the stamp invoice
 *
 * @param {object[]} items - Items of the preinvoice
 * @param {number} newTcp - Tcp requested by the executive
 * @param {('MXN'|'USD')} currencyCode - currencyCode of 3 digits
 * @returns {object[]} Items with the exchange applied
 */
export const recalculateTc = (items, newTcp, currencyCode) => {
  // debugger;

  const itemsUpdated = items.map((item) => {
    const itemHasDifferentCurrencyDoc = item.currency.code !== currencyCode;

    const puVenta = truncateDecimals(
      calculatePuVenta(item.beforeExchange.pu.number, item.discount.number),
      4
    );

    // const pu = truncateDecimals(

    // )

    // const pu = truncateDecimals(
    //   calculatePu(item.b)
    // )

    const cuVenta = truncateDecimals(
      calculateCuVenta(item.beforeExchange.cu.number, item.discount.number),
      4
    );

    const importe = truncateDecimals(
      calculateImport(puVenta, item.quantity),
      4
    );

    const iva = truncateDecimals(
      calculateIva(importe, item.iva.percentage.number),
      4
    );

    const subtotal = truncateDecimals(calculateSubtotal(importe, iva), 4);

    if (itemHasDifferentCurrencyDoc) {
      const puVentaConverted = truncateDecimals(
        convertPrice(puVenta, newTcp, item.currency.code, currencyCode),
        4
      );

      const puConverted = truncateDecimals(
        convertPrice(
          item.beforeExchange.pu.number,
          newTcp,
          item.currency.code,
          currencyCode
        ),
        4
      );

      const cuVentaConverted = truncateDecimals(
        convertPrice(cuVenta, newTcp, item.currency.code, currencyCode),
        4
      );

      const cuConverted = truncateDecimals(
        convertPrice(item.cu.number, newTcp, item.currency.code, currencyCode),
        4
      );

      const subtotalConverted = truncateDecimals(
        convertPrice(subtotal, newTcp, item.currency.code, currencyCode),
        4
      );

      const importeConverted = truncateDecimals(
        convertPrice(importe, newTcp, item.currency.code, currencyCode),
        4
      );

      const ivaConverted = truncateDecimals(
        calculateIva(importeConverted, item.iva.percentage.number),
        4
      );

      const discountMoney = truncateDecimals(
        truncateDecimals(puConverted - puVentaConverted, 4) * item.quantity,
        4
      );

      return {
        ...item,

        discount: {
          ...item.discount,
          money: {
            number: discountMoney,
            text: mnCurrency(discountMoney),
          },
        },

        currency: {
          code: currencyCode,
        },

        pu: {
          number: puConverted,
          text: mnCurrency(puConverted),
        },

        puVenta: {
          number: puVentaConverted,
          text: mnCurrency(puVentaConverted),
        },

        cu: {
          number: cuConverted,
          text: mnCurrency(cuConverted),
        },

        cuVenta: {
          number: cuVentaConverted,
          text: mnCurrency(cuVentaConverted),
        },

        importe: {
          number: importeConverted,
          text: mnCurrency(importeConverted),
        },

        iva: {
          percentage: { ...item.iva.percentage },
          money: {
            number: ivaConverted,
            text: mnCurrency(ivaConverted),
          },
        },

        subtotal: {
          number: subtotalConverted,
          text: mnCurrency(subtotalConverted),
        },
      };
    } else {
      // console.log(item.pu.number, puVenta, item.quantity);

      const discountMoney = truncateDecimals(
        (item.beforeExchange.pu.number - puVenta) * item.quantity,
        4
      );

      return {
        ...item,
        puVenta: {
          number: puVenta,
          text: mnCurrency(puVenta),
        },

        cuVenta: {
          number: cuVenta,
          text: mnCurrency(cuVenta),
        },

        pu: {
          number: item.beforeExchange.pu.number,
          text: mnCurrency(item.beforeExchange.pu.number),
        },

        subtotal: {
          number: subtotal,
          text: mnCurrency(subtotal),
        },

        discount: {
          ...item.discount,
          money: {
            number: discountMoney,
            text: mnCurrency(discountMoney),
          },
        },
      };
    }
  });

  // console.log("itemsUpdated", itemsUpdated);

  return itemsUpdated;
};

export const formateInvoiceStampToFail = (items) => {
  const providerItems = items.map((item) => {
    const Subtotal = truncateDecimals(
      calculateSubtotalFacturama(
        truncateDecimals(item.pu.number, 2),
        item.quantity
      ),
      2
    );

    const Discount = truncateDecimals(item.discount.money.number, 2);

    const Base = truncateDecimals(Subtotal * Discount, 2);

    const IvaTotal = truncateDecimals(
      calculateIva(Base, item.iva.percentage.number),
      2
    );

    // const totalPart1 = truncateDecimals(, 2);

    let Total = truncateDecimals(Subtotal - Discount + IvaTotal, 2);

    return {
      // Revisar con ernesto
      ProductCode: "10101504",

      IdentificationNumber: item.catalogue.sku,

      Description: item.catalogue.description,

      // Revisar con ernesto
      // Unidades de medida del SAT (Desc)
      Unit: "NO APLICA",

      // Revisar con ernesto
      // Unidades de medida del SAT
      UnitCode: "D14",

      // Unit price without discount
      UnitPrice: truncateDecimals(item.pu.number, 2),

      Quantity: item.quantity,

      // In terms of money
      Discount,

      Subtotal: Subtotal,

      Taxes: [
        {
          Total: IvaTotal,
          Name: "IVA",
          Base,
          Rate: item.iva.percentage.number / 100,
          IsRetention: false,
        },
      ],

      Total,
    };
  });

  return providerItems;
};

export const formatInvoiceStamp = (items) => {
  // debugger;

  const providerItems = items.map((item) => {
    console.log(item);

    const Subtotal = truncateDecimals(
      calculateSubtotalFacturama(
        truncateDecimals(item.pu.number, 2),
        item.quantity
      ),
      2
    );

    const Discount = truncateDecimals(item.discount.money.number, 2);

    const Base = truncateDecimals(Subtotal - Discount, 2);

    const IvaTotal = truncateDecimals(
      calculateIva(Base, item.iva.percentage.number),
      2
    );

    console.log(`${Subtotal} - ${Discount} + ${IvaTotal}`);

    // const totalPart1 = truncateDecimals(, 2);

    let Total = truncateDecimals(Subtotal - Discount + IvaTotal, 2);

    return {
      // Revisar con ernesto
      ProductCode: "10101504",

      IdentificationNumber: item.catalogue.sku,

      Description: item.catalogue.description,

      // Revisar con ernesto
      // Unidades de medida del SAT (Desc)
      Unit: "NO APLICA",

      // Revisar con ernesto
      // Unidades de medida del SAT
      UnitCode: "D14",

      // Unit price without discount
      UnitPrice: truncateDecimals(item.pu.number, 2),

      Quantity: item.quantity,

      // In terms of money
      Discount,

      Subtotal: Subtotal,

      Taxes: [
        {
          Total: IvaTotal,
          Name: "IVA",
          Base,
          Rate: item.iva.percentage.number / 100,
          IsRetention: false,
        },
      ],

      Total,
    };
  });

  return providerItems;
};

/**
 * Get the total amount to pay for the CxC
 *
 * @param {Partialitie[]} partialities
 * @returns {number} Total amount to pay for the partialities
 */
export const totalPartialities = (partialities) =>
  partialities.reduce(
    (sumatory, partialitie) => (sumatory += partialitie.price),
    0
  );

/**
 *
 * @param {number} number - Decimal number to truncate
 * @param {number} [positions=2] - Number of decimals to take after the point
 * @returns {number} Decimal number truncated
 */
export function truncateDecimals(number, positions=2) {

  try {
    return +number.toFixed(positions)
  } catch (error) {
    return number
  }

  const [entire,decimal='0'] = `${number}`.split('.');

  const numberToUse = +`${entire}.${decimal.substring(0,positions)}`;

  return +numberToUse.toFixed(positions)
}

/**
 * Check if the document has mixed currencies on his items
 * @param {object[]} items - List of items that has the document
 * @returns {boolean} True if the document has mixed currencies. Example, USD, MXN, YEN
 */
export function checkMixedCurrencys(items) {
  const currenciesItems = new Set();

  items.forEach((item) => currenciesItems.add(item.currency.code));

  if (currenciesItems.size > 1) {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param {object[]} items - Items recalculated in order to get the new prices of the document
 * @returns {{
 *  subtotal:number,
 *  iva:number,
 *  importe:number
 * }}
 */
export const recalculateImportsDocument = (items) =>
  items.reduce(
    (acumulator, curItem) => {
      const subtotal = (acumulator["subtotal"] += curItem.subtotal.number);
      const iva = (acumulator["iva"] += curItem.iva.money.number);
      const importe = (acumulator["importe"] += curItem.importe.number);

      return { subtotal, iva, importe };
    },
    {
      subtotal: 0,
      iva: 0,
      importe: 0,
    }
  );

/**
 * Get the list of cxc updated
 * @param {object} cxc - Cxc modified
 * @param {object[]} listCxc - List of cxc provided by the backend
 * @param {number} index - No item updated
 * @returns {{
 *  cxc:object[],
 * indexUpdated:number
 * }}
 */
export const updateAppliedImport = (listCxc, index, newImport) => {
  const copyList = [...listCxc];
  copyList[index].importeAplicado = newImport;

  return {
    cxc: copyList,
    indexUpdated: index,
  };
};

/**
 * Calculate the total import applied for the cxc
 * @param {object[]} cxc - List of cxc
 * @returns {number} Total import applied
 */
export const calculateAppliedImport = (cxc) =>
  cxc.reduce(
    (totalImport, currentCxc) => (totalImport += currentCxc.importeAplicado),
    0
  );

/**
 * Set the input with red color
 * @param {string} idDom - Id of the input to add/remove the error
 * @param {boolean} isValid - True if the input has correct amount
 */
export const setValidationUi = (idDom, isValid) => {
  const input = document.getElementById(idDom);
  isValid ? input.classList.remove("error") : input.classList.add("error");
};

/**
 * @param {object[]} cxc - Updated cxc
 * @param {'USD'|'MXN'} bankCurrency - Three digit code of the currency will be used on the movement
 * @returns {{
 * updatedCxc:object[],
 * totalImport:number,
 * isValidImportApplied:boolean
 * }}
 */
export const validateFlags = (cxc, bankCurrency) => {
  let totalImport = 0;
  let isValidImportApplied = true;
  let isValidAplicacion = true;

  const updatedCxc = cxc.map((currentCxc) => {
    /**
     * @param {number} aplicacion - Aplicacion
     * @returns {number} TC
     */
    function calculateTC(aplicacion) {
      if (bankCurrency === currentCxc.currency.code) {
        return 1;
      }

      if (bankCurrency === "USD") {
        return aplicacion / currentCxc.importeAplicado;
      }
      return currentCxc.importeAplicado / aplicacion;
    }

    function calculateAplicacion(typedImporteAplicado) {
      if (currentCxc.currency.code === bankCurrency)
        return {
          aplicacion: typedImporteAplicado,
          validAplicacion: true,
        };

      if (typedImporteAplicado === 0 && currentCxc.aplicacion === 0) {
        return {
          aplicacion: currentCxc.aplicacion,
          validAplicacion: true,
        };
      }

      if (
        currentCxc.aplicacion <= 0 ||
        currentCxc.aplicacion > currentCxc.saldoActual.number
      ) {
        isValidAplicacion = false;
        return {
          aplicacion: currentCxc.aplicacion,
          validAplicacion: false,
        };
      }

      return {
        aplicacion: currentCxc.aplicacion,
        validAplicacion: true,
      };
    }
    totalImport += currentCxc.importeAplicado;

    // Aqui esta el bug

    if (currentCxc.currency.code === bankCurrency) {
      setValidationUi(
        currentCxc.id,
        currentCxc.importeAplicado <= currentCxc.saldoActual.number
      );
      if (currentCxc.importeAplicado > currentCxc.saldoActual.number) {
        isValidImportApplied = false;
      }
    }

    const { aplicacion, validAplicacion } = calculateAplicacion(
      currentCxc.importeAplicado
    );

    const newAmountCxc = currentCxc.saldoActual.number - aplicacion;

    const tc = calculateTC(aplicacion);

    // setValidationUi(currentCxc.id,currentCxc.importeAplicado<=currentCxc.saldoActual.number);

    if (
      currentCxc.importeAplicado === 0 &&
      currentCxc.currency.code !== bankCurrency
    ) {
      return {
        ...currentCxc,
        TC: 0,
        isValidAplicacion: validAplicacion,
        aplicacion: 0,
        saldoNuevo: {
          text: mnCurrency(currentCxc.saldoActual.number),
          number: currentCxc.saldoActual.number,
        },
      };
    }

    return {
      ...currentCxc,
      aplicacion,
      isValidAplicacion: validAplicacion,
      saldoNuevo: {
        number: newAmountCxc,
        text: mnCurrency(newAmountCxc),
      },
      TC: tc,
    };
  });

  return {
    updatedCxc,
    totalImport,
    isValidImportApplied,
    isValidAplicacion,
  };
};

/**
 * @param {'USD'|'MXN'} aplicacion - Aplicacion
 * @param {object} currentCxc - CXC
 * @returns {number} TC
 */
export function calculateTC(bankCurrency, currentCxc) {
  if (bankCurrency === currentCxc.currency.code) {
    return 1;
  }

  if (bankCurrency === "USD") {
    return currentCxc.aplicacion / currentCxc.importeAplicado;
  }
  return currentCxc.importeAplicado / currentCxc.aplicacion;
}

/**
 * Calcualte the tc when are different currencies exchange
 * @param {import("../types/typedef/money").ParamExchangeI} exchange - Information to do the exchange
 * @param {number} importe - Importe applied
 * @param {number} application - Aplicacion hecha
 * @param {number} [decimals=null] - Decimals to use for the calculation
 * @returns {number} Calculated tc
 */
export function calculateTcV2(
  { fromCurrency, toCurrency },
  importe,
  application,
  decimals = null
) {

  let calculation = 0;

  // Mexican to dollar
  if (fromCurrency === "MXN" && toCurrency === "USD") {
    calculation = importe / application;
  }

  // Dollar to mexican
  else {
    calculation = application / importe;
  }

  if(typeof(decimals)==="number") return truncateDecimals(calculation,decimals);

  return calculation;
}

/**
 * Set the oc on the same currency originally on the catalogue
 * @param {object[]} items - Oc items calculated by the grid
 * @param {('USD'|'MXN')} currencyQuote - Currency which the quote was generated
 * @param {number} tc - TC that was used on the quote
 */
export function calculateOcConversion(items, currencyQuote, tc) {
  const itemsDiffCurrency = items.filter(
    (item) => item.currency.code !== currencyQuote
  );

  const itemsSameCurrency = items.filter(
    (item) => item.currency.code === currencyQuote
  );

  const recalculatedOcItems = itemsDiffCurrency.map((item) => {
    const currencyToUse = currencyQuote === "USD" ? "MXN" : "USD";

    const cu = +convertPrice(
      item.cu.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const cuVenta = +convertPrice(
      item.puVenta.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const discount = +convertPrice(
      item.discount.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const importe = +convertPrice(
      item.importe.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const iva = +convertPrice(
      item.iva.money.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const pu = +convertPrice(
      item.pu.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const puVenta = +convertPrice(
      item.puVenta.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    const subtotal = +convertPrice(
      item.subtotal.number,
      tc,
      currencyQuote,
      currencyToUse
    ).toFixed(2);

    return {
      ...item,
      cu: {
        number: cu,
        text: mnCurrency(cu),
      },
      cuVenta: {
        number: cuVenta,
        text: mnCurrency(cuVenta),
      },
      discount: {
        text: item.discount.text,
        number: item.discount.number,
        money: {
          number: discount,
          text: mnCurrency(discount),
        },
      },
      importe: {
        text: mnCurrency(importe),
        number: importe,
      },
      iva: {
        ...item.iva,
        money: {
          number: iva,
          text: mnCurrency(iva),
        },
      },
      pu: {
        number: pu,
        text: mnCurrency(pu),
      },
      puVenta: {
        number: puVenta,
        text: mnCurrency(puVenta),
      },
      subtotal: {
        number: subtotal,
        text: mnCurrency(subtotal),
      },
    };
  });

  return [...itemsSameCurrency, ...recalculatedOcItems];
}

/**
 * Calculate the total for the OC
 * @param {object[]} items - Items of the OC
 * @returns {OcTotals} Total calculated for the OC
 */
export function calculateTotalOc(items) {
  let mxTotal = 0;
  let usdTotal = 0;

  items.forEach((item) => {
    if (item.currency.code === "MXN") {
      mxTotal += item.subtotal.number;
    } else {
      usdTotal += item.subtotal.number;
    }
  });

  return {
    mx: {
      number: mxTotal,
      text: mnCurrency(mxTotal),
    },
    usd: {
      number: usdTotal,
      text: mnCurrency(usdTotal),
    },
  };
}

/**
 * Calculate by separate currencies the total of items (do not use on OC)
 * @param {object[]} items - Items of the document
 * @returns {OcTotals} Total calcualted for each currency
 */
export function calculateItemsDiffCurrencys(items) {
  let mxTotal = 0;
  let usdTotal = 0;

  items.forEach((item) => {
    const puVenta = calculatePuVenta(
      item.beforeExchange.pu.number,
      item.discount.number
    );
    const importe = calculateImport(puVenta, item.quantity);
    const iva = calculateIva(importe, item.iva.percentage.number);
    const subtotal = calculateSubtotal(importe, iva);

    if (item.currency.code === "MXN") {
      mxTotal += subtotal;
    } else {
      usdTotal += subtotal;
    }
  });

  return {
    mx: {
      number: mxTotal,
      text: mnCurrency(mxTotal),
    },
    usd: {
      number: usdTotal,
      text: mnCurrency(usdTotal),
    },
  };
}

/**
 * Validate tolerance import
 * @param {number} residue - New residue calculated
 * @param {number} tolerance - Tolerance
 * @param {number} importe - Import
 * @returns {boolean}
 */
export function validateInvoiceReceptionResidue(residue, tolerance, importe) {
  const parsedResidue = +residue.toFixed(2);

  if (importe === 0) return true;

  if (parsedResidue <= tolerance && parsedResidue >= -tolerance) {
    return true;
  } else {
    return false;
  }
}

/**
 * Check if the association of the odc is correct
 * @param {number} residue - Saldo
 * @param {number} tolerance - Tolerancia
 * @param {number} importe - Importe aplicado a la ODC para recibir la factura al 100%
 * @returns {boolean} True if association of the ODC on the invoice reception is correct
 * @example
 *
 * const isValid = isValidResidueOdc(1.32,5.0,1000) // True
 * const isValid = isValidResidueOdc(1.32,1.0,1000) // True
 * const isValid = isValidResidueOdc(-3.12,2,1000) // False
 * const isValid = isValidResidueOdc(-1.32,2,1000) // True
 */
export function isValidResidueOdc(residue, tolerance, importe) {
  if (importe === 0) return true;

  const minResidue = -tolerance;

  if (residue >= minResidue) return true;

  return false;
}

export function validateTolerance(residue, tolerance, importe) {
  const parsedResidue = +residue.toFixed(2);

  if (importe !== 0) {
    const positiveToleranceValid = parsedResidue <= tolerance;
    const negativeResidueValid = parsedResidue >= -tolerance;

    if (positiveToleranceValid === true && negativeResidueValid === true) {
      return true;
    }

    return false;
  }

  return true;
}

/**
 * Calculate the prices for the item
 * @param {import("types/typedef/catalogue").CatalogueItemI} item - Information of the item selected from the catalogue
 * @param {"USD"|"MXN"|null} predominantCurrency - Currency that's being used on the document
 * @param {number} tc - TC exchange to use in case the currencys are different
 * @param {"pu"|"cu"} calculateMode - Check if the calculation mode it's going to be applied for the prices or costs
 * @returns {import("customHooks/usePartidas/types").RecalculatedItemI} Calculated item
 */
export function calculateItemInfo(
  item,
  predominantCurrency,
  tc = 1,
  calculateMode = "pu"
) {
  const prices = [item].map((item) => {
    const puCalculated = item.pu.number;
    const puVentaCalculated = calculateUnitSellPrice(
      puCalculated,
      item.pu.discount
    );

    const cuVentaCalculated = calculateUnitSellPrice(
      item.cu.number,
      item.cu.discount
    );

    // IVA "unitario "
    const puIvaCalculated = calculateIva(
      puVentaCalculated,
      item.iva.number,
      item.quantity.quantity
    );

    const puImportCalculated = calculateImport(
      puVentaCalculated,
      item.quantity.quantity
    );
    const puSubtotalCalculated = calculateSubtotal(
      puVentaCalculated,
      puIvaCalculated,
      item.quantity.quantity
    );

    const cuIvaCalculated = calculateIva(
      cuVentaCalculated,
      item.iva.number,
      item.quantity.quantity
    );
    const cuImportCalculated = calculateImport(
      cuVentaCalculated,
      item.quantity.quantity
    );
    const cuSubtotalCalculated = calculateSubtotal(
      cuVentaCalculated,
      cuIvaCalculated,
      item.quantity.quantity
    );

    const utility = +(puImportCalculated - cuImportCalculated).toFixed(2);

    const discountPu = +(
      calculateDiscountToApply(item.pu.number, item.pu.discount) *
      item.quantity.quantity
    ).toFixed(2);
    const discountCu = +(
      calculateDiscountToApply(item.cu.number, item.cu.discount) *
      item.quantity.quantity
    ).toFixed(2);

    if (
      predominantCurrency === item.currency.code ||
      predominantCurrency === null
    ) {
      console.log(
        "No se hace conversion, predominante y partida en la misma moneda"
      );
      return {
        ...item,
        utility,
        calculations: {
          price: {
            unitary: puCalculated,
            sell: puVentaCalculated,
            // iva: +(puIvaCalculated * item.quantity.quantity).toFixed(2),
            iva: truncateDecimals(
              truncateDecimals(item.iva.number / 100, 2) * puImportCalculated,
              2
            ),
            import: puImportCalculated,
            subtotal: puSubtotalCalculated,
            discount: discountPu,
          },
          cost: {
            unitary: item.cu.number,
            sell: cuVentaCalculated,
            // iva: +(cuIvaCalculated * item.quantity.quantity).toFixed(2),
            iva: truncateDecimals(
              truncateDecimals(item.iva.number / 100, 2) * cuImportCalculated,
              2
            ),
            import: cuImportCalculated,
            subtotal: cuSubtotalCalculated,
            discount: discountCu,
          },
        },
      };
    } else {
      console.log(
        "Se hace conversion, partida y predominante en diferente moneda"
      );
      return {
        ...item,
        utility: +convertPrice(
          utility,
          tc,
          item.currency.code,
          predominantCurrency
        ).toFixed(2),
        calculations: {
          price: {
            unitary: +convertPrice(
              puCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            sell: +convertPrice(
              puVentaCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            // iva: +convertPrice(
            //   +(puIvaCalculated * item.quantity.quantity).toFixed(2),
            //   tc,
            //   item.currency.code,
            //   predominantCurrency
            // ).toFixed(2),
            iva: +convertPrice(
              truncateDecimals(
                truncateDecimals(item.iva.number / 100, 2) * puImportCalculated,
                2
              ),
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            import: +convertPrice(
              puImportCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            subtotal: +convertPrice(
              puSubtotalCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            discount: +convertPrice(
              discountPu,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
          },
          cost: {
            unitary: +convertPrice(
              item.cu.number,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            sell: +convertPrice(
              cuVentaCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            // iva: +convertPrice(
            //   +(cuIvaCalculated * item.quantity.quantity).toFixed(2),
            //   tc,
            //   item.currency.code,
            //   predominantCurrency
            // ).toFixed(2),
            iva: +convertPrice(
              truncateDecimals(
                truncateDecimals(item.iva.number / 100, 2) * cuImportCalculated,
                2
              ),
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            import: +convertPrice(
              cuImportCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            subtotal: +convertPrice(
              cuSubtotalCalculated,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
            discount: +convertPrice(
              discountCu,
              tc,
              item.currency.code,
              predominantCurrency
            ).toFixed(2),
          },
        },
      };
    }
  });

  const itemCalculated = prices[0];

  return itemCalculated;
  /**
   * Calculate P.U. Venta or C.U. Venta
   * @param {number} price - P.U. or C.U.
   * @param {number} discount - Between 0-100
   * @returns {number} P.U. Venta or C.U. Venta
   */
  function calculateUnitSellPrice(price, discount) {
    return truncateDecimals(price - discount, 2);

    const { total } = calculateDiscount(price, discount);
    return +(price - (price - (+total.toFixed(2)).toFixed(2)));
  }

  /**
   * Calculate the import for the item
   * @param {number} unitSellPrice - P.U. Venta or C.U. Venta
   * @param {number} quantity - Quantity
   * @returns {number} Import
   */
  function calculateImport(unitSellPrice, quantity) {
    return +(unitSellPrice * quantity).toFixed(2);
  }

  /**
   * Calculate subtotal
   * @param {number} unitSellPrice - P.U. Venta or C.U. Venta
   * @param {number} iva - IVA
   * @param {number} quantity - Number of items requested
   * @returns {number} Subtotal
   */
  function calculateSubtotal(unitSellPrice, iva, quantity) {
    const subtotal = +(unitSellPrice + iva).toFixed(2);
    return +(subtotal * quantity).toFixed(2);
  }

  /**
   * Get iva from product item
   * @param {number} unitSellPrice  - P.U. Venta or C.U. Venta
   * @param {number} iva - Between 0 and 100
   * @param {number} quantity - Number of items requested for that item
   * @returns {number} Iva
   */
  function calculateIva(unitSellPrice, iva, quantity) {
    console.log({
      unitSellPrice,
      iva,
    });

    const { total } = calculateDiscount(unitSellPrice, iva);

    return truncateDecimals(unitSellPrice - total, 2);

    const ivaCalculated = +(unitSellPrice - total).toFixed(2);
    return ivaCalculated;
  }
}

// const marginRateGot = (
//   (item.utility / item.calculations.price.import) *
//   100
// ).toFixed(2);

export function calculateMarginRate(utilityGot, importGot) {
  return +((utilityGot / importGot) * 100).toFixed(2);
}

/**
 * 
 * @param {number} costPrice - Cost
 * @param {number} sellingPrice - Sell
 * @returns {number}
 * @example
 * const costPrice = 15;
const sellingPrice = 20;
const margin = calculateMargin(costPrice, sellingPrice);

// Margin of Earnings: 33.33%
console.log("Margin of Earnings: " + margin.toFixed(2) + "%");
 */
export function calculateMargin(costPrice, sellingPrice) {
  // Calculate profit
  const profit = sellingPrice - costPrice;

  // Calculate margin
  const margin = (profit / costPrice) * 100;

  return +margin.toFixed(2);
}

export function parseDocumentItems(items) {
  return items.map((item) => ({
    ...item,
    label: item.description,
    value: item.catalogue.id,
    isNewItem: false,
    quantity: {
      isValid: true,
      quantity: item.quantity,
    },
  }));
}

/**
 * Check if there's an items with negative utility
 * @param {import("types/typedef/catalogue").CalculatedItemI[]} items - Items to evaluate
 * @returns {boolean} Check if there's a negative margin for the items calculated
 */
export function isNegativeUtility(items) {
  let isNegativeUtility = false;

  items.forEach((item) => {
    if (item.utility < 0) {
      isNegativeUtility = true;
    }
  });

  return isNegativeUtility;
}

/**
 * Check if there's an items with an invalid discount (more than 100)
 * @param {import("types/typedef/catalogue").CalculatedItemI[]} items - Items to evaluate
 * @returns {boolean} Evaluation expression
 */
export function checkIsValidDiscount(items) {
  let isValidDiscount = true;

  items.forEach((item) => {
    if (item.discount > 100) {
      isValidDiscount = false;
    }
  });

  return isValidDiscount;
}

/**
 * Calculate the total amounts of th items
 * @param {import("types/typedef/catalogue").CalculatedItemI[]} items - Items to evaluate
 * @param {"pu"|"cu"} calculateMode - Check if the calculation it's gonna be for cost or prices
 * @returns {{
 *  total:number,
 *  iva:number,
 *  importe:number
 * }} Evaluation expression
 */
export function calculateAmounts(items, calculateMode = "pu") {
  const initialValues = {
    total: 0,
    iva: 0,
    importe: 0,
  };

  if (calculateMode === "pu") {
    const { importe, iva } = items.reduce(
      (amounts, item) => ({
        importe: amounts.importe + item.calculations.price.import,
        iva: amounts.iva + item.calculations.price.iva,
      }),
      initialValues
    );

    return {
      importe,
      iva,
      total: +(importe + iva).toFixed(2),
    };
  } else {
    const { importe, iva } = items.reduce(
      (amounts, item) => ({
        importe: amounts.importe + item.calculations.cost.import,
        iva: amounts.iva + item.calculations.cost.iva,
      }),
      initialValues
    );

    return {
      importe,
      iva,
      total: +(importe + iva).toFixed(2),
    };
  }
}

/**
 * Calculate P.U. Venta or C.U. Venta
 * @param {number} price - P.U. or C.U.
 * @param {number} discount - Between 0-100
 * @returns {number} P.U. Venta or C.U. Venta
 */
export function calculateUnitSellPrice(price, discount) {
  const { total } = calculateDiscount(price, discount);
  return +(price - (price - (+total.toFixed(2)).toFixed(2)));
}

/**
 * Calculate the import for the item
 * @param {number} unitSellPrice - P.U. Venta or C.U. Venta
 * @param {number} quantity - Quantity
 * @returns {number} Import
 */
export function calculateImportV2(unitSellPrice, quantity) {
  return +(unitSellPrice * quantity).toFixed(2);
}

/**
 * Get the taxas that will have the items of the document
 * FIXME: De momento praxia solo maneja impuestos de IVA. Los demas impuestos no se pueden facturar
 * @param {import("./moneyTypes").Item[]} items - Items
 * @returns {import("./moneyTypes").TaxaItems}
 */
export function getTaxas(items) {
  return items.reduce(
    (ivas, item) => {
      if (item.iva.number === 0 && !item.iva.exempt) {
        return {
          ...ivas,
          iva0: {
            items: [item, ...ivas.iva0.items],
            importe: {
              cost: ivas.iva0.importe.cost + item.calculations.cost.import,
              sell: ivas.iva0.importe.sell + item.calculations.price.import,
            },
            iva: {
              cost: ivas.iva0.cost + item.calculations.cost.iva,
              sell: ivas.iva0.sell + item.calculations.price.iva,
            },
          },
        };
      }

      if (item.iva.number === 0 && item.iva.exempt) {
        return {
          ...ivas,
          iva0Exempt: {
            items: [item, ...ivas.iva0.items],
            importe: {
              cost: ivas.iva0.importe.cost + item.calculations.cost.import,
              sell: ivas.iva0.importe.sell + item.calculations.price.import,
            },
            iva: {
              cost: ivas.iva0.cost + item.calculations.cost.iva,
              sell: ivas.iva0.sell + item.calculations.price.iva,
            },
          },
        };
      }

      if (item.iva.number === 16) {
        return {
          ...ivas,
          iva16: {
            ...ivas.iva16,
            items: [item, ...ivas.iva16.items],
            importe: {
              cost: ivas.iva16.importe.cost + item.calculations.cost.import,
              sell: ivas.iva16.importe.sell + item.calculations.price.import,
            },
            iva: {
              cost: ivas.iva16.iva.cost + item.calculations.cost.iva,
              sell: ivas.iva16.iva.sell + item.calculations.price.iva,
            },
          },
        };
      }

      if (item.iva.number === 8) {
        return {
          ...ivas,
          iva8: {
            ...ivas.iva16,
            items: [item, ...ivas.iva8.items],
            importe: {
              cost: ivas.iva8.importe.cost + item.calculations.cost.import,
              sell: ivas.iva8.importe.sell + item.calculations.price.import,
            },
            iva: {
              cost: ivas.iva8.iva.cost + item.calculations.cost.iva,
              sell: ivas.iva8.iva.sell + item.calculations.price.iva,
            },
          },
        };
      }

      return ivas;
    },
    {
      iva16: {
        items: [],
        importe: {
          cost: 0,
          sell: 0,
        },
        iva: {
          cost: 0,
          sell: 0,
        },
        taxCode: "002",
        description: "IVA",
        isRetention: false,
        region: "Federal",
        factorType: "Tasa",
      },
      iva8: {
        items: [],
        importe: {
          cost: 0,
          sell: 0,
        },
        iva: {
          cost: 0,
          sell: 0,
        },
        taxCode: "002",
        description: "IVA",
        isRetention: false,
        region: "Federal",
        factorType: "Tasa",
      },
      iva0: {
        items: [],
        importe: {
          cost: 0,
          sell: 0,
        },
        iva: {
          cost: 0,
          sell: 0,
        },
        taxCode: "002",
        description: "IVA",
        isRetention: false,
        region: "Federal",
        factorType: "Tasa",
      },
      iva0Exempt: {
        items: [],
        importe: {
          cost: 0,
          sell: 0,
        },
        iva: {
          cost: 0,
          sell: 0,
        },
        taxCode: "002",
        description: "IVA",
        isRetention: false,
        region: "Federal",
        factorType: "Exento",
      },
    }
  );
}

/**
 * Cast a numer data without decimals
 * @param {number|any|string} data - Number to cast into without decimals
 * @returns {number}
 */
export function noDecimals(data) {
  if (typeof data === "number") return Math.floor(data);

  try {
    return Math.floor(data);
  } catch (error) {
    return 0;
  }
}

/**
 * Limit the number of decimals typed on an input
 * @param {number} numberToCast - Number to limit decimals
 * @param {number} [decimalsAllowed=2] - Number of decimals allowed
 * @returns {number|null}
 */
export function limitDecimals(numberToCast, decimalsAllowed = 2) {
 try {
  if (isNaN(numberToCast) || isNaN(decimalsAllowed) || decimalsAllowed < 0) {
    alert("Por favor, ingrese números válidos.");
    return null;
  }

  const [entireNumber,decimalPart='0'] =  `${numberToCast}`.split('.');

  const parsedNumber = +`${entireNumber}.${decimalPart.substring(0,decimalsAllowed)}`;

  return parsedNumber;
 } catch (error) {
  return numberToCast
 }

}

/**
 * Parse the input `number` from the dom
 * @param {string} valueInput - Value from the html input
 * @param {number} maxDecimals - Max decimals to type on input
 */
export function parseInputDecimals(valueInput,maxDecimals=2){
  const [value, decimals] = valueInput.split(".");
  const parsedDecimals = decimals?.slice(0, maxDecimals) || "";

  const data =
    `` + value + (parsedDecimals ? `.${parsedDecimals}` : "");

    return data;
}

// /**
//  * Parse a number into a readable text
//  * @param {string} amount - Amount to convert into text
//  * @param {string} [currency=null] - If provided, will concat the currency 
//  * @returns {string}
//  */
// export function numberToText(amount = '0', currency = null) {

//   try {
//     const amountParsed = `${amount.replaceAll('$','')}`.split(".");

//   const amountTexted = n2words(+amountParsed[0], { lang: "es" }).toUpperCase();

//   const decimals = handleDecimals(amountParsed[1]);

//   const currencyHandled = handleCurrency();

//   return `${amountTexted} ${decimals} ${currencyHandled}`;
//   } catch (error) {
//     return `${amount}`;
//   }

//   ///////////////////////////////////////////////////////////////////////////////////
//   function handleDecimals(decimals = undefined) {
//     if (decimals === undefined) return "0/100";

//     const numberToUse = String(decimals).padStart(2, "0")

//     return `${numberToUse}/100`;
//   }

//   function handleCurrency(){
//     if(currency===null) return '';

//     if(currency==='USD') return 'DOLARES';

//     if(currency==='MXN') return 'PESOS';
//   }
// }
