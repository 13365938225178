import React, { useEffect, useState } from "react";

import { Select, ErrorMessage } from "../../../individual/inputs/Inputs";

import { fetchPayMethods } from "../../../../helpers/Apis/Banks";
import { getInvoiceComboDefValue } from "helpers/Apis/parameters";
import { Label } from "structure/Document";
import InformativeTooltip from "components/individual/InfoTooltip";

/**
 * Render a combo to select pay methods available on the system
 * @param {object} props - Props
 * @param {object} [props.forwardRef={}] - ref for react-hook-form
 * @param {string} [props.label='Metodo de pago'] - Label for the combo
 * @param {string} [props.id='payMethods'] - id attribute for the input
 * @param {string} [props.name='payMethods'] - name attribute for the input
 * @param {object} [props.errors={}] - errors for react-hook-form
 * @param {boolean} [props.readonly=false] - indicates if the combo can be edited
 * @param {string} [props.css=''] - css for the container input
 * @param {(number|null)} [props.value=null] - value for load on the input
 * @param {string|null} [props.rfc=null] - RFC of the customer that will be created a posssible invoice
 * @param {(payMethod:import("./PayMethodsV2types").onChangePayMethod)=>void} [props.onChange=()=>{}] - value of the selected option when it changes
 * @returns {JSX.Element}
 */
function PayMethods({
  forwardRef = {},
  label = "Metodo de pago",
  id = "payMethods",
  name = "payMethods",
  errors = {},
  readonly = false,
  css = "",
  value = null,
  onChange = () => {},
  rfc = null,
}) {
  const [pay, setPayMethods] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      const [
        dataFetch,
        {
          value: { id },
        },
      ] = await Promise.all([
        fetchPayMethods(),
        getInvoiceComboDefValue("metodo-pago"),
      ]);

      // const dataFetch = await fetchPayMethods();
      // getInvoiceComboDefValue("metodo-pago");

      setPayMethods(dataFetch);

      if (value === null) {
        onChange(dataFetch.find((item) => item.code === +id));
      }
    }

    initialLoad();
  }, []);

  const canChangeOption =
    rfc === null || rfc !== "XAXX010101000" ? true : false;

  const payMethod = (id) => pay.find((item) => item.code === id);

  // const han

  return (
    <div className={`${css} itemDocument`}>
      <Label>
        {label}{" "}
        {!canChangeOption ? (
          <InformativeTooltip>
            <p>
              Para cambiar esta opcion el RFC del receptor debe ser diferente al
              genérico
            </p>
          </InformativeTooltip>
        ) : null}
      </Label>

      {canChangeOption ? (
        <Select
          options={pay}
          addTodos={false}
          idSelect={id}
          nameSelect={name}
          // label={label}
          textJSON="description"
          valueJSON="code"
          forwardRef={forwardRef}
          readonly={readonly}
          onChange={(value) => onChange(payMethod(+value))}
          value={value}
        />
      ) : (
        <>
          <p>Por definir</p>
          <input
            type="hidden"
            ref={forwardRef}
            value={value}
            id={id}
            name={name}
          />
        </>
      )}

      <ErrorMessage message={errors[id]?.message} />
    </div>
  );
}

export default PayMethods;
