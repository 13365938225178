import { RedirectButton } from "components/individual/buttons/Buttons";
import React, { useContext } from "react";
import { ContextInvoice } from "../..";

export default function Edit() {
  const { invoice, from } = useContext(ContextInvoice);

  return (
    <RedirectButton
      id="editInvoice"
      text="Editar"
      link={`../documentos/pedido/editar?idDocumento=${invoice.id}&modulo=${from}&idCliente=${invoice.customer.id}`}
    />
  );
}
