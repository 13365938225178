import React from "react";
import { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { BankAccountContext } from "..";
import rehypeRaw from "rehype-raw";
import { Label } from "structure/Document";

export default function ViewDescription() {
  const { account } = useContext(BankAccountContext);
  return (
    <div>
      <Label>Descripción</Label>
      <ReactMarkdown
        children={account.description}
        rehypePlugins={[rehypeRaw]}
      />
    </div>
  );
}
