import styled from "styled-components";

export const MenuItemContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  background-color: ${(props) => (props.active ? "var(--primaryBackground)" : "var(--secondaryBackground)")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background:var(--primaryBackground);
  }
`;

export const MenuItemContainerEnd = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:var(--primaryBackground);
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background:var(--primaryBackground);
  }
`;
export const MenuItemContainerStart = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background:var(--primaryBackground);
  }
`;

export const MenuItemsText = styled.p`
  margin: 0px;
`;

export const GenerateContractContainer = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    display:grid;
    grid-template-columns:50% 50%;

    >*{
      font-size:0.85rem !important;
      text-align:center;
    }
  } 
`;
