import { useState } from "react";
import { scoreTicket } from "helpers/Apis/tickets";
import useDialog from "customHooks/useDialog";

/**
 * @type {import("../components/rate/types").StateRateTicket}
 */
const INITIAL_STATE = {
  isLoading: true,
  rate: 0,
  comment: "",
  isOpen: false,
  isRating: false,
};

/**
 * @type {import("../components/rate/types").ReturnRateTicket}
 */
export const CONTEXT_RATE_TICKET = {
  attemptRatingTicket: async () => {},
  closeModal: () => {},
  dialog: undefined,
  openModal: () => {},
  grade: INITIAL_STATE,
  isOpen: false,
  isRating: false,
  onCommentChange: () => {},
  onRateChange: () => {},
};

/**
 * Handle the rate of a ticket on the system
 * @param {number} idTicket - Id of the ticket to rate
 * @param {()=>void} [onRatedTicket=()=>{}] - Callback executed once the ticket is rated with success on server
 * @returns {import("../components/rate/types").ReturnRateTicket}
 */
export const useRateTicket = (idTicket, onRatedTicket = () => {}) => {
  const [grade, setGrade] = useState(INITIAL_STATE);

  const dialog = useDialog();

  const onRateChange = (value) => {
    setGrade({
      ...grade,
      rate: value,
    });
  };

  const onCommentChange = (value) => {
    setGrade({
      ...grade,
      comment: value,
    });
  };

  const setRatingTicket = (isLoading, isRating) => {
    setGrade({
      ...grade,
      isLoading,
      isRating,
    });
  };

  const openModal = () => {
    setGrade((state) => ({
      ...state,
      isOpen: true,
    }));
    dialog.open();
  };

  const closeModal = () => {
    setGrade(INITIAL_STATE);
    dialog.close();
  };

  const attemptRatingTicket = async () => {
    setRatingTicket(true, true);

    const wasRated = await scoreTicket(idTicket, grade.rate, grade.comment);

    setRatingTicket(false, false);

    if (wasRated) {
      closeModal();
      onRatedTicket();
    }
  };

  return {
    onRateChange,
    onCommentChange,
    attemptRatingTicket,
    grade,
    isOpen: grade.isOpen,
    isRating: grade.isRating,
    openModal,
    closeModal,
    dialog,
  };
};
