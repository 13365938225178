import ButtonV2 from "components/individual/ButtonsV2/Button";
import ModalV2 from "components/individual/Modals/ModalV2";
import React, { useState } from "react";
import { RadioButton } from "components/individual/inputs/Inputs";
import { RedirectButton } from "components/individual/buttons/Buttons";
import { useParams } from "react-router-dom";

/**
 * Button to associate the movement
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} props.movement - Information of the row selected
 * @returns {JSX.Element}
 */
export default function Associate({ movement }) {
  const [options, setOptions] = useState([
    { label: "Cuentas por cobrar", value: 1 },
    { label: "Concepto", value: 2 },
  ]);

  const [optSelected, setOptSelected] = useState(null);

  const { account } = useParams();

  const isCustomerAssociated =
    movement.customerAssociated === null ? false : true;

  const redirectLinks = {
    2: `/bancos/movimientos/${account}/asociar-ingreso/${movement.Movimiento}`,
    1: `/bancos/movimientos/${account}/asociar/${movement.Movimiento}`,
  };

  return (
    <>
      {isCustomerAssociated ? (
        <RedirectButton css="mr-4" text="Asociar" link={redirectLinks[1]} />
      ) : movement.statusValue === 5 || movement.statusValue === 3 ? null : (
        <ModalV2
          title="Ingreso"
          aditionalClasses="mr-4"
          buttonText="Asociar"
          close="Cancelar"
          showCancelButton={false}
          footer={
            <>
              {!optSelected ? null : (
                <RedirectButton
                  text="Continuar"
                  link={redirectLinks[optSelected]}
                />
              )}
            </>
          }
        >
          <div className="d-flex justify-content-center flex-column align-items-center p-3">
            <h3>Selecciona el tipo de asociacion</h3>
            <RadioButton
              sameLine={false}
              options={options}
              attributeText="label"
              attributeValue="value"
              name="associationType"
              attributeID="value"
              onChange={(e) => setOptSelected(+e.target.value)}
            />
          </div>
        </ModalV2>
      )}
    </>
  );
}
