import React, { Fragment } from "react";
import Redirect from "structure/Menu/Redirect";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../index.module.scss";
import Back from "../Back";
import { ChangeMenuView } from "../ChangeMenuView";
import usePermissions from "customHooks/usePermissions";
import AddTc from "structure/AddTc";
import TcModal from "structure/TC";
import SendEmail from "structure/SendEmail";
import DistributionList from "structure/DistributionList";
import ModalExchangeDocuments from "structure/ModalExchangeDocuments";
import ContactsReport from "components/general/ContactsReport";

export default function SpecialProcessMenu() {
  const { checkPermission } = usePermissions();

  // TEST PUSH
  if (!checkPermission("06e83fd3-14d3-4ab3-89b5-5685d7aaf5f1")) return <></>;

  return (
    <Fragment>
      <Back menu="general" text="Inicio > Procesos especiales" />

      <Redirect
        permission="ae3302c7-be66-468a-89ee-33d8258852aa"
        text={<TextIcon icon="userTag" text="Cambio de ejecutivo" />}
        to={"/representante"}
      />

      <SendEmail>
        <p className={scss.item}>
          <TextIcon icon="email" text="Enviar correo" />
        </p>
      </SendEmail>

      <Redirect
        permission="73b4be43-5a6a-4bf1-ba50-fe7f4677640b"
        text={<TextIcon icon="support" text="Help Desk" />}
        to={"/procesos-especiales/help-desk/tickets"}
      />

      <Redirect
        permission="3c14b37b-9b40-4fe9-8feb-d9c02899a2af"
        text={<TextIcon icon="administration" text="UEN" />}
        to={"/uen"}
      />

      <Redirect
        permission="e456792d-b059-4988-8b78-618a0cfe8297"
        text={
          <TextIcon
            icon="administration"
            text="Catálogo productos y servicios"
          />
        }
        to={"/catalogo"}
      />

      <Redirect
        permission="5ba58008-56d7-4a9c-846c-825b070f9fa2"
        text={
          <TextIcon icon="administration" text="Bitacora de autorizaciones" />
        }
        to={"/autorizaciones"}
      />

      <Redirect
        to="/ivas"
        text={<TextIcon text="Análisis IVA" icon="excel" />}
      />

      <ContactsReport renderSpinner={false}>
        <p className={scss.item}>
          <TextIcon icon="users" text="Reporte contactos" />
        </p>
      </ContactsReport>

      <ChangeMenuView
        permission="2bf0d705-e9aa-4fbc-b9b4-4b581a1a42fc"
        icon="excel"
        menu="datosExcel"
        text="Plantillas"
      />

      <DistributionList>
        <p className={scss.item}>
          <TextIcon icon="noteCreditEmitted" text="Lista Distribución" />
        </p>
      </DistributionList>

      <TcModal>
        <p className={scss.item}>
          <TextIcon icon="chartBar" text="TC" />
        </p>
      </TcModal>

      <ModalExchangeDocuments>
        <p className={scss.item}>
          <TextIcon icon="user" text="Documentos ejecutivos" />
        </p>
      </ModalExchangeDocuments>

      <AddTc>
        <p className={scss.item}>
          <TextIcon icon="chartBar" text="Agregar TC" />
        </p>
      </AddTc>
    </Fragment>
  );
}
