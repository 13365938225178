import React, { useRef, useContext } from "react";
import style from "../../styles.module.scss";
import { ContextCashFlow } from "../..";
import { mnCurrency } from "helpers/money";
import TotalBanks from "../TotalBanks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export default function TableProjection() {
  const hook = useContext(ContextCashFlow);

  const key = useRef(window.crypto.randomUUID());

  return (
    <>
      <div className={style.tableCashFlow}>
        <div className={style.headerBanks}>
          <div>Bancos </div>
          <div>No. Cuenta</div>
          <div>Moneda - Descripción</div>
          <div className={style.headerDayBanks}>
            <div>Saldo proyectado en bancos</div>
            <div>{hook.yesterDayColumn}</div>
            <div>{hook.todayColumn}</div>
            <div>{hook.within7Days}</div>
            <div>{hook.within14Days}</div>
            <div>Más de 14 días</div>
          </div>
        </div>

        {hook.cashFlow.displayDetails
          ? hook.cashFlow.cashFlow.bank.bankAccounts.map((bank, i) => (
              <div className={style.row} key={`${key.current}-${i}`}>
                <p onClick={() => hook.setBankAccount(bank.id)}>
                  {bank.account}
                </p>

                <div className="d-flex">
                  <p>{bank.currency} - {" "}</p>
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={bank.description}
                  />
                </div>
                <p>-</p>
                <p>{mnCurrency(bank.yesterdayCxc)}</p>
                <p>-</p>
                <p>-</p>
                <p>-</p>
              </div>
            ))
          : null}

        <TotalBanks />
      </div>
    </>
  );
}
