import React from "react";
import { UenV4, UenSelect } from "components/general/Forms/Selects/UenV4";
import { useContext } from "react";
import { ContextHistoricalContracts } from "..";
import { Label } from "structure/Document";

export default function UenFilter() {
  const hook = useContext(ContextHistoricalContracts);

  return (
    <UenV4 addAllOption={true}>
      <div>
        <Label>UEN</Label>
        <UenSelect onChange={(id,uen) => hook.setUen(id,uen.description)} />
      </div>
    </UenV4>
  );
}
