import useDialog from "customHooks/useDialog";
import { searchClaveProductoServicio } from "helpers/Apis/invoice";
import { useEffect, useState } from "react";

/**
 * @type {import("structure/SatCode/types").StateSatCode}
 */
const INITIAL_STATE = {
  isLoading: true,
  search: "",
  results: [],
  itemSelected: undefined,
  isOpenDialog: false,
};

/**
 * Hook to handle sat code for items on documents
 * @returns {import("./types").ReturnUseSatCode}
 */
export default function useSatCode(name = "", description = "") {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    if (state.isOpenDialog) dialog.open();
  }, [state.isOpenDialog]);

  useEffect(() => {
    // if (name.length <= 0 || description.length <= 0) return;

    setState((current) => ({
      ...current,
      itemSelected: {
        Complement: "",
        Name: description,
        Value: name,
      },
    }));
  }, [name, description]);

  const handleOpenModal = (isOpenDialog) =>
    setState((current) => ({
      ...current,
      isOpenDialog,
    }));

  const attemptSearch = async () => {
    setState((current) => ({
      ...current,
      isLoading: true,
      results: [],
    }));

    const satCodes = await searchClaveProductoServicio(state.search);

    setState((current) => ({
      ...current,
      results: satCodes,
      isLoading: false,
    }));
  };

  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const setOptionSelected = (itemSelected) =>
    setState((current) => ({
      ...current,
      itemSelected,
    }));

  return {
    ...state,
    attemptSearch,
    dialog,
    handleOpenModal,
    setOptionSelected,
    setSearch,
  };
}
