
/**
 * @type{import("types/typedef/customer").CustomerI}
 */
export const multipleCustomer={
    id: -1,
    idMizar: -1,
    fiscalRegime: 'NA',
    creditDays: -1,
    type: {
      id: -1,
      description: 'NA',
    },
    contact: {
      email: 'NA',
      movil: 'NA',
      phone: 'NA',
    },
    mnBank: null,
    usdBank: null,
    adress: {
      city: null,
      colony: null,
      street: null,
      extNumber: null,
      intNumber: null,
      country: 'NA',
      state: null ,
      cp: null ,
    },
    iva: 16,
    name: {
      commercial: 'Multiples razones sociales',
      short: 'Multiples razones sociales',
    },
    socialReason: 'Multiples razones sociales',
    rfc: 'NA',
    status: {
      isActive: 1,
      parsed: 'NA',
    },
    created: {
      by: 'SISTEMA',
      date: {
        yyyymmdd: 'NA',
        parsed: 'NA',
      },
    },
    updated: {
      by: 'SISTEMA',
      date: {
        yyyymmdd: 'NA',
        parsed: 'NA',
      },
    },
    corporative: {
      id: -1,
    },
    attendedBy: {
      id: -1,
      customer: {
        id: -1,
      },
      executive: {
        id: -1,
        email: 'SISTEMA',
        firstName: 'SISTEMA',
        fullName: 'SISTEMA',
        middleName: 'SISTEMA',
        motherName: 'SISTEMA',
        parentName: 'SISTEMA',
      },
    },
  }