import * as yup from "yup";

const description = yup
  .string()
  .max(1000, "🟥 Máximo 1000 caracteres")
  .required("🟥 Obligatorio")
  .typeError("🟥 La descripción debe ser texto");

const unitPrice = yup
  .number()
  .min(1, "🟥 Mínimo $1.00")
  .moreThan(
    yup.ref("unitCost"),
    "El precio de venta debe de ser mayor al costo de venta"
  )
  .required("🟥 Obligatorio")
  .typeError("🟥 El precio unitario debe ser numérico");
const unitCost = yup
  .number()
  .min(1, "🟥 Mínimo $1.00")
  .required("🟥 Obligatorio")
  .typeError("🟥 El costo unitario debe ser numérico");
const satCode = yup.string().required("🟥 Obligatorio");
const satUM = yup.string().required("🟥 Obligatorio");
const uen = yup.number().required("🟥 Obligatorio");
const sku = yup
  .string()
  .max(256, "🟥 Máximo 256 caracteres")
  .required("🟥 Obligatorio");
const currency = yup
  .number()
  .min(1, "Selecciona una moneda válida")
  .max(2, "Selecciona una moneda válida")
  .typeError("Selecciona una moneda válida");

const status = yup
  .boolean()
  .required("🟥 Obligatorio")
  .typeError(`🟥 El estatus debe ser 'Activo' o 'Inactivo'`);

const excent = yup
  .boolean()
  .required("🟥 Obligatorio")
  .typeError(`🟥 El estatus de aplica iva excento debe ser 'Si' o 'No'`);

const iva = yup
  .number()
  .required("Obligatoior")
  .min(0, "Mínimo iva del 0%")
  .max(100, "Máximo iva del 100%")
  .typeError("El iva debe ser un dato numerico del 0% al 100%");

const idCatalogue = yup
  .number()
  .positive("El id del producto esta erroneo")
  .required("Id del producto obligatorio")
  .typeError("El id del producto esta erroneo");

export const schemaAdd = yup.object().shape({
  description,
  unitPrice,
  unitCost,
  satCode,
  satUM,
  uen,
  sku,
  currency,
  excent,
});

export const schemaUpdate = yup.object().shape({
  currency,
  description,
  sku,
  status,
  unitCost,
  unitPrice,
});
