import { addDays } from "date-fns";
import { saveAs } from "file-saver";
import { excelNc, getCreditNotesReceived } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import {
  dateToDbFormat,
  getYearRange,
  
} from "helpers/dates";
import { useEffect , useState } from "react";

/**
 * @type {import("./types").StateUseCreditNotesReceived}
 */
const INITIAL_STATE = {
  creditNotes: [],
  creditNote: undefined,
  search: "",
  beginDate: getYearRange().begin.js,
  endDate: addDays(new Date(),1),
  pages: 0,
  currentPage: 1,
  isSearching: true,
  providerId: undefined,
  status: undefined,
  triggerSerch: false,
  operation:{},
  original:{},
  contabilidad:null,
  isDownloadingExcel:false
};

/**
 * @type {import("./types").ContextCreditNotesReceived}
 */
export const INITIAL_STATE_CONTEXT = {
  ...INITIAL_STATE,
  breadcrum:[], 
  downloadExcel:async()=>{},
  updateBeginDate: () => {},
  updateEndDate: () => {},
  setCreditNote: () => {},
  updateSearch: () => {},
  updateStatus: () => {},
  triggerSerch:()=>{},
  setStatusAccounting:()=>{},
  refetchCurrentPage:()=>{},
  setPage:()=>{},
  toggleAccounting:()=>{},
  module: "facturas"
};

/**
 * Hook to handle
 * @param {import("../types").ModuleCreditNote} [module="facturas"] 
 * @returns {import("./types").ReturnUseCreditNotesReceived}
 */
export default function useCreditNotesReceived(module="facturas") {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isSearching: true,
        creditNote: undefined,
        creditNotes: [],
      }));

      const apiCreditNotesReceived = await getCreditNotesReceived({
        beginDate: dateToDbFormat(state.beginDate),
        endDate: dateToDbFormat(state.endDate),
        customerId: state.providerId,
        pageRequested: state.currentPage,
        search: state.search,
        statusId: module === "facturas" ? state.status : 13,
        contabilidad:state.contabilidad
      });

      const original = apiCreditNotesReceived.paginatedNcr.ncr.reduce((indexed,nc)=>({
      ...indexed,
      [nc.id]:nc?.isAccounted||false
      }),{})

      setState((current) => ({
        ...current,
        creditNotes: apiCreditNotesReceived.paginatedNcr.ncr,
        currentPage: apiCreditNotesReceived.paginatedNcr.pages.actualPage,
        pages: apiCreditNotesReceived.paginatedNcr.pages.pages,
        isSearching: false,
        operation:original,
        original:original
      }));
    })();
  }, [state.triggerSerch, state.currentPage]);

  /**
   * Update the search for the credit note received
   * @param {string} search - Search for the credit notes received
   * @returns {void}
   */
  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const downloadExcel = async() => {

    setState(current=>({
      ...current,
      isDownloadingExcel:true
    }))

    const file = await excelNc({
      beginDate:dateToDbFormat(state.beginDate),
      endDate:dateToDbFormat(state.endDate),
      pageRequested:null,
      type: module === "facturas" ? "recibidas" : "recibidasContabilidad",
      contabilidad:state.contabilidad,
      statusId:state.status,
      search:state.search,
      customerId:null      
    });

    if(file instanceof Blob){
      const fileName = module === "contabilidad" ? 'Contabilidad - Notas de crédito recibidas' : 'Compras - Notas de crédito recibidas';
      saveAs(file,`${fileName}.xlsx`);
    }

    setState(current=>({
      ...current,
      isDownloadingExcel:false
    }))
  }


  const updateBeginDate = (date) =>
    setState((current) => ({
      ...current,
      beginDate: date,
    }));

  const updateEndDate = (date) =>
    setState((current) => ({
      ...current,
      endDate: date,
    }));

  const updateStatus = (status) => {
    const statusToUse = status === "0" ? undefined : +status;

    setState((current) => ({
      ...current,
      status: statusToUse,
    }));
  };

  const setCreditNote = (creditNote) =>
    setState((current) => ({
      ...current,
      creditNote,
    }));

  const triggerSerch = () =>
    setState((current) => ({
      ...current,
      currentPage: 1,
      triggerSerch: !current.triggerSerch,
    }));

  const refetchCurrentPage = () => setState(current=>({
    ...current,
    triggerSerch:!current.triggerSerch
  }));

  const setPage = page => setState(current=>({
    ...current,
    currentPage:page
  }));

  const toggleAccounting = id => setState(current=>({
    ...current,
    operation:{
      ...current.operation,
      [id]:!current.operation[id]
    }
  }))

  /**
   * Set the accounting status for the filter
   * @param {boolean} contabilidad - Accounting status
   * @returns {void}
   */
  const setStatusAccounting = contabilidad => setState(current=>({
    ...current,
    contabilidad
  }));

  return {
    ...state,
    refetchCurrentPage,
    setStatusAccounting,
    toggleAccounting,
    triggerSerch,
    updateSearch,
    downloadExcel,
    updateBeginDate,
    updateEndDate,
    updateStatus,
    setPage,
    setCreditNote,
    breadcrum: [
      {
        route: "/",
        text: "Inicio",
      },
      {
        route: "/",
        text: module === "facturas" ? "Compras" : "Contabilidad",
      },
      {
        route: "/",
        text: module === "facturas" ? "Notas de crédito" : "Notas de crédito recibidas",
      },
    ],
  };
}
