import Select from "components/individual/SelectV4";
import React from "react";
import { Label } from "structure/Document";

/**
 * Render a select status for the filter
 * @param {import("./types").PropsAccountingStatus} props - Props
 * @returns {JSX.Element}
 */
export default function AccoutingStatus({
  onChange = () => {},
  required = false,
  value = null
}) {
  return (
    <div>
      <Label required={required}>Estatus</Label>
      <Select
        labelToDisplay="label"
        onChange={data=>onChange(data.value)}
        defaultSelected={{
          keyToCompare: "value",
          value: value,
        }}
        options={async () => [
          {
            label: "-- SELECCIONA --",
            value: undefined,
            disabled: true,
          },
          {
            label: "Todos",
            value: null,
          },
          {
            label: "Contabilizados",
            value: true,
          },
          {
            label: "No contabilizados",
            value: false,
          },
        ]}
      />
    </div>
  );
}
