import React from "react";
// import { TreePermissions, useTreePermissions } from "react-tree-permissions";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";

export default function ViewPermissions({ hook }) {
  // const { handleCollapse, setPermissionsChecked, permissions } =
    // useTreePermissions(hook.permissions);

  return (
    <Tree
      treeData={hook.schemaTreePermissions}
      checkable={true}
      showLine
      showIcon={false}
      defaultCheckedKeys={hook.permissions.active}
      
    />
  );

  // return (
  //   <TreePermissions
  //     permissionsSchema={hook.schemaTreePermissions}
  //     permissionsActive={permissions.active}
  //     initialPermissions={permissions.initialActive}
  //     collpasedIndexed={permissions.collapsedItems}
  //     setPermissions={setPermissionsChecked}
  //     handleCollapse={handleCollapse}
  //     showExpands
  //     onChange={hook.updateMemoryPermissions}
  //   />
  // );
}
