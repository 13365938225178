
import { QuoteFormData } from "./Data/QuoteData";


const {
  Cliente,
  Contacto,
  Moneda,
  FechaVencimiento,
  FechaRecordatorio,
  PercentAvance,
  PercentProb,
  DiaCredito,
  Proveedor,
} = QuoteFormData;


function emptyInputs(isAddCotizacion,isAddOrdenPago,isAddServicios){
    const inputs={
    intpCliente: isAddCotizacion?document.getElementById(Cliente.id):null,
    intpContacto: document.getElementById(Contacto.id),
    intpMoneda: document.getElementById(Moneda.id),
    intpFechaVencimiento: document.getElementById(FechaVencimiento.id),
    intpFechaRecordatorio: document.getElementById(FechaRecordatorio.id),
    intpPorcienAvance: isAddCotizacion||isAddOrdenPago?document.getElementById(PercentAvance.id):null,
    intpPorcienprob: isAddCotizacion?document.getElementById(PercentProb.id):null,
    intpDiascredito: document.getElementById(DiaCredito.id),
    intpProveedor: isAddOrdenPago||isAddServicios?document.getElementById(Proveedor.id):null
    }
    return inputs
}

export function SetContext(isAddCotizacion,isAddOrdenPago,isAddServicios) {

    const input= emptyInputs(isAddCotizacion,isAddOrdenPago,isAddServicios);

  const documentContext = {
    cliente: input.intpCliente?.value,
    proveedor: input.intpProveedor?.value,
    contacto: input.intpContacto?.value,
    moneda: +input.intpMoneda?.value,
    fechaVencimiento: input.intpFechaVencimiento?.value,
    fechaRecordatorio: input.intpFechaRecordatorio?.value,
    percentAvance: input.intpPorcienAvance?.value,
    percentProb: input.intpPorcienprob?.value,
    diaCredito: +input.intpDiascredito?.value,
  };
  return documentContext;
}

export function configFormQuote(documento, isAddCotizacion,isAddOrdenPago,isAddServicios){
  const input= emptyInputs(isAddCotizacion,isAddOrdenPago,isAddServicios);
  const {documentForm}= documento;
  console.log(documentForm);

    if(!documento.vacio){
    input.intpCliente.value= documentForm.cliente
    // input.intpProveedor.value=documentForm.proveedor
    input.intpContacto.value= +documentForm.contacto
    input.intpMoneda.value=documentForm.moneda
    input.intpFechaVencimiento.value=documentForm.fechaVencimiento
    input.intpFechaRecordatorio.value=documentForm.fechaRecordatorio
    input.intpPorcienAvance.value=documentForm.percentAvance
    input.intpPorcienprob.value=documentForm.percentProb
    input.intpDiascredito.value=documentForm.diaCredito
    }
}
