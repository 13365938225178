import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext, useRef } from "react";
import DocumentContext from "../../ContextDocument";
import { ContextModalConcept } from "../ConceptsSection";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function AttemptSave() {
  const hook = useContext(ContextModalConcept);

  const hookDoc = useContext(DocumentContext);

  const queryToExecute = useRef({
    Agregar: hookDoc.handleOnAddDocument,
    Editar: hookDoc.handleOnEditDocument,
    Ver: hookDoc.backToOrigin,
  });

  const handleSubmit = async () => {
    hook.setLoading(true);
    await queryToExecute.current[hookDoc.actionType](hookDoc.documentType);
    hook.setLoading(false);
  };

  if(hook.loading) return <Spinner hidden={false} text={'Actualizando'}/>

  return (
    <ButtonV2 type="button" onClick={handleSubmit}>
      <span>{hookDoc.actionType === "Agregar" ? "Agregar" : "Actualizar"}</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
