import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "..";
import style from "../styles.module.scss";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import FilterCurrency from "../FilterCurrency";
import SearchBar from "../SearchBar";
import SearchSku from "../SearchSku";
import SearchButton from "../SearchButton";

export default function MobileFilter() {
  const hook = useContext(ContextCatalogueAdmin);

  const dialog = useDialog();

  return (
    <>
      <Dialog
        title="Filtros catalogo"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
        footer={
          <div className="d-flex justify-content-end">
            <div onClick={dialog.close}>
              <SearchButton />
            </div>
          </div>
        }
      >
        <FilterCurrency />
        <SearchBar />
        <SearchSku />
      </Dialog>

      <div className={style.filterMobile}>
        <ButtonV2 onClick={dialog.open}>
          <Icon icon="filter" />
        </ButtonV2>

        <div className={style.pillFilter}>
          <Label>SKU</Label>
          <p>{hook.sku === "" ? "Todas" : hook.sku}</p>
        </div>

        <div className={style.pillFilter}>
          <Label>Moneda</Label>
          <p>{hook.currency === null ? "Todas" : hook.currency}</p>
        </div>
      </div>
    </>
  );
}
