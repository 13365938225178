import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { useEffect, useState } from "react";
import TreeMenu from "react-simple-tree-menu";
import "react-simple-tree-menu/dist/main.css";
import { CSDLoad } from "./CSD";
import EnterpriseInfo from "./EnterpriseInfo";
import InvoiceParameters from "./InvoiceParameters";
import style from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import BannerPraxia from "./BannerPraxia";
import CheckInSetup from "./CheckIn";
import EmailParameters from "./EmailParameters";
import EmailReminderPayments from "./EmailReminderPayments";
import EmailInvoiceEmited from "./EmailInvoiceEmited";
import useParameters from "./useParameters";
import EmailReminderContracts from "./EmailReminderContracts";
import Robots from "./Robots";

const indexedNavigationScreens = {
  "67269a8c-5ce0-4532-b49a-f4c4decf15c2": <InvoiceParameters />,
  "33b7fd0f-c15c-4aee-a9a8-01f18cb761df": <EmailParameters />,
  "fd32036d-44c8-493f-b713-8031c04dc107": <InvoiceParameters />,
  "f0c7fab8-3195-4042-909b-d2c81cf58ea8": <></>,
  '3be2d962-3aa7-440f-986e-1dc59528d5ae':<Robots/>,
  "0f629cdf-a5e3-4480-ae4e-078813cd4408": <EmailInvoiceEmited />,
  "9c72f237-d7a7-48aa-88b3-19488e896b55": <EmailReminderPayments type="recordatorioPagos1"/>,
  "7e4d11cd-c60b-4a59-a5df-319edd7010d5":<EmailReminderPayments type="recordatorioPagos2"/>,
  "09af7421-b98f-434b-b9d6-19ea5b652249":<EmailReminderPayments type="recordatorioPagos3"/>,
  "d856d355-7e85-4d68-9df1-cb38f83a6d76":<EmailReminderPayments type="recordatorioPagos4"/>,
  "1dfb7186-a728-4b9f-ad34-1b5bb7197c33":<EmailReminderContracts/>,
  "03d0c27d-a17b-4ad3-8524-f4585d9cf4f2": (
    <div className="d-flex justify-content-center">
      <CSDLoad />
    </div>
  ),
  informacionEmpresa: <EnterpriseInfo />,
  "6357329b-bc9e-42ff-9afe-cf7cc7b595cd": <BannerPraxia />,
};

export default function Parameters() {
  const [optSelected, setOptSelected] = useState([]);
  const [screens, setScreens] = useState();
  const [optionExists, setOptionExists] = useState(false);

  const hook = useParameters();

  useEffect(() => {
    const screensToDisplay = indexedNavigationScreens[optSelected] || (
      <div className="h-100">
        <CheckInSetup />
      </div>
    );
    setScreens(screensToDisplay);
  }, [optSelected]);

  const handleOptSelected = (navigation) => {
    const screens = navigation.key.split("/");

    const optionToUse = screens[screens.length - 1];

    setOptSelected(optionToUse);

    const isOnList =
      indexedNavigationScreens[optionToUse] === undefined ? false : true;

    setOptionExists(isOnList);
  };

  return (
    <div className={style.containerParameters}>
      <div>
        <BreadCrumV2 path={hook.breadcrum.current} />
        {optionExists ? (
          <div className={style.backOptionMobile}>
            <ButtonV2 onClick={() => setOptionExists(false)}>Regresar</ButtonV2>
          </div>
        ) : null}
      </div>

      <div className={style.mobileParameters}>
        {!optionExists ? (
          <TreeMenu
            onClickItem={handleOptSelected}
            hasSearch={false}
            data={hook.schemaNavigation.current}
          />
        ) : (
          <div className={style.mobileOptionSelected}>{screens}</div>
        )}
      </div>

      <div className={style.parameters}>
        <TreeMenu
          onClickItem={handleOptSelected}
          hasSearch={false}
          data={hook.schemaNavigation.current}
        />
        {screens}
      </div>
    </div>
  );
}
