import React, { useContext } from "react";
import { ContextModalConcept } from "../ConceptsSection";
import { Spinner } from "components/individual/Spinner/Spinner";
import DocumentContext from "../../ContextDocument";
import AttemptSave from "../AttemptSave";

export default function Save() {
  const hook = useContext(ContextModalConcept);

  const hookDoc = useContext(DocumentContext);

  if (hookDoc.items.length <= 0)
    return (
      <div className="text-danger font-weight-bold d-flex justify-content-end align-items-center">
        🔴 Agrega al menos 1 partida
      </div>
    );

  if (hookDoc.customer.id <= 0)
    return (
      <div className="text-danger font-weight-bold d-flex justify-content-end align-items-center">
        🔴 Selecciona una razón social
      </div>
    );

  if (hook.concept === undefined)
    return (
      <div className="text-danger font-weight-bold d-flex justify-content-end align-items-center">
        🔴 Selecciona un concepto
      </div>
    );

  if (hook.loading)
    return (
      <div className="d-flex justify-content-end align-items-center">
        <Spinner hidden={false} text="Actualizando" />
      </div>
    );

  if (hookDoc.actionType === "Editar" && hook.editionMotive.length <= 0)
    return (
      <div className="text-danger font-weight-bold d-flex justify-content-end align-items-center">
        🔴 Escribe un motivo de la edición
      </div>
    );

  return (
    <div className="d-flex justify-content-end align-items-center">
      <AttemptSave />
    </div>
  );
}
