import Table from "components/general/Table";
import { ContextProyects } from "pages/Proyects";
import React, { useContext, useEffect, useMemo, useState } from "react";
import useFormProyect from "structure/FormProyect/useFormProyect";

/**
 *
 * @param {import("react-data-table-component").TableProps<import("../../../../types/proyects").DtoPosition>} props
 * @returns
 */
function TablePositions(props) {
  return <Table {...props} />;
}

/**
 * Render positions table
 * @param {import("./types").PropsPositionsOverview} param0
 * @returns {JSX.Element}
 */
export default function PositionsOverview({
  idProyect = null,
  onChange = () => {},
}) {
  const proyect = useFormProyect(idProyect);

  const hook = useContext(ContextProyects);

  if (typeof idProyect !== "number" || proyect.proyect === undefined)
    return <></>;

  return (
    <TablePositions
      pagination={false}
      selectableRows
      selectableRowsSingle={false}
      onSelectedRowsChange={(data) => onChange(data.selectedRows)}
      onRowClicked={console.log}
      data={proyect.proyect.positions}
      selectableRowSelected={hook.isCheckedPosition}
      // selectableRowSelected={(data) =>
      //   hook.selectedPosition.map((item) => item.id).includes(data.id)
      // }
      columns={[
        {
          name: "Posición",
          selector: (data) => (data.pos === null ? "No disponible" : data.pos),
        },
        {
          name: "OC cliente",
          selector: (data) =>
            data.ocCustomer === null ? "No disponible" : data.ocCustomer,
        },
        {
          name: "Solped",
          // selector:data=>'???'
          selector: (data) =>
            proyect.proyect.solped === null
              ? "No disponible"
              : proyect.proyect.solped,
        },
        {
          name: "Propuesta",
          selector: (data) =>
            data.propousals === undefined
              ? "No disponible"
              : data.propousals.find(
                  (item) => item.proposalStatus === "Activa"
                ) === undefined
              ? "No disponible"
              : `Activa | ${
                  data.propousals.find(
                    (item) => item.proposalStatus === "Activa"
                  ).proposalNumber
                }`,
        },
        {
          name: "Estatus posición",
          selector: (data) => hook.indexedStatus.current[data.statusPosition],
        },
      ]}
    />
  );
}
