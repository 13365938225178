import React from "react";
import { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { QuoteCommentsSection } from "./QuoteCommentsSection";
import { OrderCommentSection } from "./OrderCommentSection";
import { OdcCommentSecton } from "./OdcCommentSecton";
import { ContractCommentSection } from "./ContractCommentSection";

export const CommentSection = () => {
  const { interfaceControl } = useContext(WinQuoteContext);
  const menu = interfaceControl.menu;
  const index = interfaceControl.indexActiveMenu;

  const commentSection = {
    1: <QuoteCommentsSection />,
    2: <OrderCommentSection />,
    3: <OdcCommentSecton />,
    6: <ContractCommentSection />,
  };

  return <div>{commentSection[menu[index].idType]}</div>;
};
