import React, { useState, useEffect } from "react";
import DatePartialitie from "./DatePartialitie";
import { InputCurrency } from "../../../../../components/individual/inputs/Inputs";
import DatePartialitieV2 from "./DatePartialitieV2";
import ui from './styles.module.scss';

export default function PartialitieImport({
  noPartialitie = "?",
  price = 0,
  percentage,
  date,
  onChange = () => {},
  resetDate,
  minDate = new Date(),
  maxDate = new Date(),
}) {
  const [currentDate, setDate] = useState(new Date());

  useEffect(() => {
    setDate(date);
  }, [date]);

  return (
    <div>
      {noPartialitie === 1 ? null : <hr />}

      <p>
        <b>Parcialidad {noPartialitie}</b>
      </p>

      <div className={ui.gridItem}>
        <InputCurrency
         
          labelTxt="Importe *"
          placeholder="Escribe aqui (Obligatorio)"
          defaultValue={price}
          onChange={(value) =>
            onChange({
              percentage: percentage,
              priceToUpdate: +value,
              elementIndex: noPartialitie - 1,
              date: new Date(currentDate),
            })
          }
        />

        <div >
          <p className="my-0">
            <b>Porcentaje</b>
          </p>
          <p className="my-0">{percentage.toFixed(2)}%</p>
        </div>

        {noPartialitie > 1 ? (
          <div>
            <DatePartialitieV2
              initialDate={date}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(value) => {
                const infoPartialitie = {
                  percentage,
                  priceToUpdate: +price,
                  elementIndex: noPartialitie - 1,
                  date: new Date(value),
                };
                setDate(new Date(value));
                onChange(infoPartialitie);
              }}
            />
          </div>
        ) : (
          <DatePartialitie
            maxDate={maxDate}
            firstPartiality={resetDate}
            beginDate={date}
          />
        )}
      </div>
    </div>
  );
}
