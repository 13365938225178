import React from "react";
import { MenuOptionsContainer } from "../Containers";
import { Menu } from "../Menu/Menu";
import { GenerateContractContainer } from "../Menu/Containers";
import ToggleComponent from "components/individual/inputs/Toggle";
import { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";

export const MenuComponent = () => {
  // const [checked, setChecked] = useState(false);

  const { interfaceControl, handleGenerateContract, idContractParent } =
    useContext(WinQuoteContext);
  return (
    <MenuOptionsContainer>
      <GenerateContractContainer>
        <span>
          <strong>Contrato</strong>
        </span>
        <ToggleComponent
          labelActive="Si"
          labelInactive="No"
          isChecked={interfaceControl?.needContract}
          disable={!!idContractParent}
          onChange={handleGenerateContract}
        />
      </GenerateContractContainer>
      <Menu />
    </MenuOptionsContainer>
  );
};
