import { disassociateEgressConcept } from "helpers/Apis/concilation";
import { useState } from "react";

/**
 *
 * @returns {import("./types").UseModalDisassociateEgressConcept}
 */
export default function useModalDisassociateEgressConcept({
  idMovement,
  onDisassociated = () => {},
}) {
  /**
   * @type {import("./types").StateUseModalDisassociateEgressConcept}
   */
  const INITIAL_STATE = {
    isDisassociating: false,
    disassociate: {
      deductible: [],
      noDeductible: [],
    },
  };

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * Function in order to attempt the dis-associate concept
   * @returns {Promise<void>}
   */
  const attemptDisassociate = async () => {
    setState({
      ...state,
      isDisassociating: true,
    });

    const idsDeductible = state.disassociate.deductible.map(
      (deductible) => deductible.id
    );
    const idsNoDeductible = state.disassociate.noDeductible.map(
      (noDeductible) => noDeductible.id
    );

    const wasDisassociated = await disassociateEgressConcept(
      idMovement,
      idsDeductible,
      idsNoDeductible
    );

    if (wasDisassociated) onDisassociated();

    setState({
      ...state,
      isDisassociating: false,
    });

    return wasDisassociated;
  };

  /**
   * Update the list in order to disassociate the egress concepts
   * @param {import("../../../../types/banks").Deductible[]} deductible - Deductible egress concepts
   * @param {import("../../../../types/banks").NoDeductible[]} noDeductible - No deductible egress concepts
   * @returns {void}
   */
  const updateListDisassociate = (deductible = [], noDeductible = []) =>
    setState({
      ...state,
      disassociate: {
        deductible,
        noDeductible,
      },
    });

  return {
    ...state,
    attemptDisassociate,
    updateListDisassociate,
  };
}
