import { InputText } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import useNoteConsideration from "customHooks/useNoteConsideration";
import React, { createContext, createRef, useEffect, useState } from "react";
import styles from "styles/Card.module.scss";
import PeriocityNote from "./Periocity";

/**
 * Context of the note/consideration
 * @type {import("react").Context<import("types/typedef/customHooks/useNoteConsideration").noteConsiderationContext>}
 */
export const NoteConsiderationContext = createContext();
const { Provider } = NoteConsiderationContext;

/**
 * Render the automatic notes and considerations according the parameters of the document
 * @param {object} props - Props
 * @param {"USD"|"MXN"} props.currency - Currency used on the document
 * @param {number[]} props.uen - Ids of the uen used on the document items
 * @param {number} props.idTypeDocument - Id of the type document it's being created/edited
 * @param {string?} props.periocity - Message to display for the periocity (in case applies)
 * @param {()=>void} props.onChange - Information of the notes and considerations to use on the document
 * @returns {JSX.Element} Structure to render notes and considerations of a document (for add or edit)
 */
export default function NoteConsideration({
  currency,
  uen = [],
  idTypeDocument,
  periocity = null,
  onChange = () => {},
}) {
  const {
    isLoading,
    notes,
    promptDeleteConfirmation,
    setEditing,
    updateEdition,
    notesToDisplay,
  } = useNoteConsideration({
    currency,
    uen,
    idTypeDocument,
    periocity,
    onChange,
  });

  return isLoading ? (
    <Spinner hidden={false} text="Cargando notas y consideraciones..." />
  ) : notes.length <= 0 ? (
    <div>
      <h1 className="py-4 text-center">No hay notas o consideraciones</h1>
      <iframe
        className={styles.tutorial}
        src="https://scribehow.com/embed/Crear_notas_y_consideraciones__V3bIhtT6SbK2i3YNgBTltw"
        width="640"
        height="640"
        allowfullscreen
        frameborder="0"
        title="How to create notes and considerations"
      ></iframe>
    </div>
  ) : (
    <Provider
      value={{
        periocity,
      }}
    >
      <div className="p-4">
        <PeriocityNote />

        {notes.map((note, index) => {
          const inputEdit = createRef(null);

          const canDisplayByCurrency =
            note.currency === "NA"
              ? true
              : note.currency === currency
              ? true
              : false;
          const canDisplayByUen =
            note.uen === null ? true : uen.includes(note.uen);

          if (canDisplayByCurrency && canDisplayByUen) {
            return (
              <div className={styles.card} key={window.crypto.randomUUID()}>
                <div>
                  {note.is.editing ? (
                    <InputText
                      id={`edition-note-${index}`}
                      forwardRef={inputEdit}
                      defaultValue={note.tempContent}
                    />
                  ) : (
                    note.content
                  )}
                </div>

                {note.is.delatable === true && !note.is.editing ? (
                  <button
                    onClick={() => promptDeleteConfirmation(index)}
                    className={styles.delete}
                  ></button>
                ) : null}

                {note.is.editable ? (
                  note.is.editing ? (
                    <>
                      <button
                        onClick={() =>
                          updateEdition(index, inputEdit.current.value)
                        }
                        className={styles.save}
                      ></button>

                      <button
                        onClick={(e) => setEditing(index, false)}
                        className={styles.xmark}
                      ></button>
                    </>
                  ) : (
                    <button
                      className={styles.edit}
                      onClick={(e) => setEditing(index, true)}
                    ></button>
                  )
                ) : null}
              </div>
            );
          }

          return null;
        })}
      </div>
    </Provider>
  );
}
