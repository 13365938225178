import TypeCustomerDirectory from "components/individual/TypeCustomerDirectory";
import React from "react";
import { useContext } from "react";
import { ContextHistoricalContracts } from "..";

export default function SectorFilter() {
  const hook = useContext(ContextHistoricalContracts);

  return (
    <div>
      <TypeCustomerDirectory
        isFilter={true}
        idCustomer={hook.sector}
        type="todos"
        value={hook.sector}
        onChange={(type) =>
          hook.setSector(type?.id || null, type?.description || "Todos")
        }
      />
    </div>
  );
}
