import useUen from "customHooks/useUen";
import {
  addFee,
  getFee,
  getFeeOverview,
  updateFee,
} from "helpers/Apis/Documents";
import { Success, Warning, YesNoAlert } from "helpers/alerts";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseCuotas}
 */
const INITIAL_STATE = {
  executive: undefined,
  year: undefined,
  isRequiredExecutive: true,
  canDisplayGrid: false,
  type: undefined,
  isSearchingFee: true,
  fee: undefined,
  feeOperation: {},
  currency: undefined,
  isPerformingAbc: false,
  power: undefined,
  overview: undefined,
  refetchFlag: false,
  years: [],
};

/**
 *
 * @returns {import("./types").ReturnUseCuotas}
 */
export default function useCuotas() {
  const uen = useUen(true);

  const [state, setState] = useState(INITIAL_STATE);

  function isExecutiveSelected() {
    if (state.executive === undefined || state.executive === null) return false;

    return true;
  }

  /**
   *
   * @param {import("./types").FeeGrid} [type='operation'] - Type of grid to convert
   * @returns
   */
  function parseFeeToGrid(type = "operation") {
    try {
      const objectToUse = type === "operation" ? state.feeOperation : state.fee;

      return Object.entries(objectToUse)
        .map(([uen, item]) =>
          Object.entries(item).map(([key, value]) => {
            return {
              year: state.year,
              month: isNaN(+key) ? null : +key,
              executive: state.executive.value,
              type:
                typeof state.type === "string"
                  ? state.type
                  : state.overview.type,
              currency:
                typeof state.currency === "string"
                  ? state.currency
                  : state.overview.currency,
              power:
                typeof state.power === "number"
                  ? state.power
                  : state.overview.power,
              quantity: +value,
              uen: +uen,
            };
          })
        )
        .reduce((list, dtoItem) => [...list, ...dtoItem], [])
        .filter((item) => item.month !== null);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  useEffect(() => {
    setState((current) => ({
      ...current,
      isRequiredExecutive: !isExecutiveSelected(),
    }));
  }, [state.executive]);

  useEffect(() => {
    setState((current) => ({
      ...current,
      canDisplayGrid: isExecutiveSelected() && typeof state.year === "number",
    }));
  }, [state.executive, state.year]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isSearchingFee: true,
        fee: undefined,
        feeOperation: {},
      }));

      if (!state.canDisplayGrid || uen.uens.length <= 0) {
        setState((current) => ({
          ...current,
          isSearchingFee: true,
          fee: undefined,
          feeOperation: {},
        }));
        return;
      }

      const emptyFee = uen.uens.reduce(
        (indexed, uen) => ({
          ...indexed,
          [uen.UENID]: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            total: "0",
          },
        }),
        {}
      );

      const fee = await getFee(state.year, state.executive.value);

      if (Array.isArray(fee)) {
        setState((current) => ({
          ...current,
          isSearchingFee: false,
          fee: undefined,
          feeOperation: emptyFee,
          overview: undefined,
        }));
        return;
      }

      const overview = await getFeeOverview(state.year);

      setState((current) => ({
        ...current,
        isSearchingFee: false,
        fee,
        feeOperation: fee,
        overview,
      }));
    })();
  }, [
    state.canDisplayGrid,
    state.executive,
    state.year,
    state.type,
    uen.uens,
    state.refetchFlag,
  ]);

  const refetchGrid = () =>
    setState((current) => ({
      ...current,
      refetchFlag: !current.refetchFlag,
    }));

  /**
   * Set the executive
   * @param {import("components/individual/Executives/types").ExecutiveItem} executive
   * @returns {void}
   */
  const setExecutive = (executive) => {
    if (typeof state.year !== "number" || state.executive === undefined) {
      setState((current) => ({
        ...current,
        executive,
      }));
      return;
    }

    if (isGridIntact()) {
      setState((current) => ({
        ...current,
        executive,
      }));
      return;
    }

    YesNoAlert(
      `¡Atención!\n 
    Tienes cambios no guardados en esta página\n
    ¿Deseas guardar los cambios?
    `,
      async () => {
        await attemptUpdateFee();
        setState((current) => ({
          ...current,
          executive,
        }));
      },
      () =>
        setState((current) => ({
          ...current,
          executive,
        }))
    );
  };

  /**
   * Set the year for the UI
   * @param {number} year - Year
   * @returns {void}
   */
  const setYear = (year) => {

    if (typeof state.year !== "number" || state.executive === undefined) {
      setState((current) => ({
        ...current,
        year,
      }));
      return;
    }

    if(isGridIntact()){
      setState((current) => ({
        ...current,
        year,
      }));
      return
    }

    YesNoAlert(
      `¡Atención!\n 
    Tienes cambios no guardados en esta página\n
    ¿Deseas guardar los cambios?
    `,
      async () => {
        await attemptUpdateFee();
        setState((current) => ({
          ...current,
          year,
        }));
      },
      () =>
        setState((current) => ({
          ...current,
          year,
        }))
    );

  };

  /**
   * Set the type of fee
   * @param {import("../../../../../../types/Sells/sells").FeeType} type
   * @returns {void}
   */
  const setFee = (type) =>
    setState((current) => ({
      ...current,
      type,
    }));

  const setUnit = (uen, quantity, month) => {
    const newTotal = Object.entries(state.feeOperation[uen]).reduce(
      (indexed, [key, value], i) => {
        if (i >= 12) return indexed;

        if (month === i + 1) {
          return (indexed += quantity);
        }

        return (indexed += state.feeOperation[uen][i + 1]);
      },
      0
    );

    setState((current) => ({
      ...current,
      feeOperation: {
        ...current.feeOperation,
        [uen]: {
          ...current.feeOperation[uen],
          [month]: quantity,
          total: newTotal,
        },
      },
    }));
  };

  const setCurrency = (currency) =>
    setState((current) => ({
      ...current,
      currency,
    }));

  const setPower = (power) =>
    setState((current) => ({
      ...current,
      power,
    }));

  const setFeeOperation = (feeOperation) =>
    setState((current) => ({
      ...current,
      feeOperation,
    }));

  const attemptAddFee = async () => {
    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const wasAdded = await addFee(
      state.year,
      state.type,
      state.power,
      state.currency
    );

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));

    return wasAdded;
  };

  const attemptUpdateFee = async () => {
    const originalFee = parseFeeToGrid("original");
    const newFee = parseFeeToGrid("operation");

    const feeToUpdate = originalFee.reduce((updates, fee, i) => {
      if (fee.quantity !== newFee[i].quantity) {
        return [...updates, newFee[i]];
      }

      return updates;
    }, []);

    if (feeToUpdate.length <= 0) {
      Success(() => {}, "Cuota actualizada");

      return;
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const dtoUpdate = feeToUpdate.map((fee) => ({
      year: fee.year,
      month: fee.month,
      quantity: fee.quantity,
      uen: fee.uen,
      executive: state.executive.value,
    }));

    const wasUpdated = await updateFee(dtoUpdate);

    if(wasUpdated){
      refetchGrid();
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));
  };

  const resetComponent = () => setState(INITIAL_STATE);

  const setYears = (years) => {
    setState((current) => ({
      ...current,
      years,
    }));
  };

  /**
   * Check if is there any change on the grid against the original data of the user
   * @returns {boolean}
   */
  function isGridIntact() {
    const originalFee = parseFeeToGrid("original");
    const newFee = parseFeeToGrid("operation");

    const feeToUpdate = originalFee.reduce((updates, fee, i) => {
      if (fee.quantity !== newFee[i].quantity) {
        return [...updates, newFee[i]];
      }

      return updates;
    }, []);

    if (feeToUpdate.length <= 0) return true;

    return false;
  }

  return {
    uen,
    resetComponent,
    setFeeOperation,
    setUnit,
    setFee,
    setYear,
    setExecutive,
    setYears,
    setCurrency,
    refetchGrid,
    attemptUpdateFee,
    attemptAddFee,
    setPower,
    ...state,
    canPerformAbc:
      typeof state.year === "number" &&
      state.year >= 1 &&
      typeof state.type === "string" &&
      typeof state.currency === "string" &&
      typeof state.power === "number",
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/ventas/cuotas",
        text: "Ventas",
      },
      {
        route: "/inicio",
        text: "Cuotas",
      },
    ],
  };
}
