import React from "react";
import style from "../../styles.module.scss";
import { ContextCashFlow } from "../..";
import { useContext } from "react";
import { mnCurrency } from "helpers/money";
import { useRef } from "react";

/**
 * Render the details of the `CxC` or `CxP`
 * @param {import("./types").DetailsCashFlow} props - Props
 * @returns {JSX.Element}
 */
export default function DetailsCxc({ type = "cxc" }) {
  const hook = useContext(ContextCashFlow);

  const key = useRef(window.crypto.randomUUID());

  if(hook.type==='flujo')return (
    <div className={style.tableCashFlow}>
    <div className={style.tableCashFlowV1}>
      <p><span className={style.typeOfDetails}>{type}</span>Razón social</p>
      <p>{hook.yesterDayColumn}</p>
      <p>{hook.todayColumn}</p>
      <p>1-7 días</p>
      <p>8-14 días</p>
      <p>Más de 14</p>
      <p>Total</p>
    </div>

    {hook.cashFlow.displayDetails
      ? hook.cashFlow.cashFlow[`${type}`].document.map((cxc, i) => (
          <div key={`${key.current}-${i}`} className={style.rowCashFlowV1}>
            <p>{cxc.socialReason}</p>
            <p>{mnCurrency(cxc.yesterdayCxc)}</p>
              <p>{mnCurrency(cxc.todayCxc)}</p>
              <p>{mnCurrency(cxc.nextDay)}</p>
              <p>{mnCurrency(cxc.next7Days)}</p>
              <p>{mnCurrency(cxc.next14Days)}</p>

            <p>{mnCurrency(cxc.total)}</p>
          </div>
        ))
      : null}

    <div className={style.totalCashFlow}>
      <p>Total {type}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalYesterday)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalToday)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNextDay)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNext7Days)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNext14Days)}</p>
      <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].total)}</p>
    </div>
  </div>
  )

  return (
    <div className={style.tableCashFlow}>
      <div className={style.detailsSocialReason}>
        <p><span className={style.typeOfDetails}>{type}</span>Razón social</p>
        <p>{hook.yesterDayColumn}</p>
        <p>{hook.todayColumn}</p>
        <p>1-7 días</p>
        <p>8-14 días</p>
        <p>Mas de 14 días</p>
      </div>

      {hook.cashFlow.displayDetails
        ? hook.cashFlow.cashFlow[`${type}`].document.map((cxc, i) => (
            <div key={`${key.current}-${i}`} className={style.details}>
              <p>{cxc.socialReason}</p>
              <p>{mnCurrency(cxc.yesterdayCxc)}</p>
              <p>{mnCurrency(cxc.todayCxc)}</p>
              <p>{mnCurrency(cxc.nextDay)}</p>
              <p>{mnCurrency(cxc.next7Days)}</p>
              <p>{mnCurrency(cxc.next14Days)}</p>
            </div>
          ))
        : null}

      <div className={style.totalDetails}>
        <p>Total {type}</p>
        <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalYesterday)}</p>
        <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalToday)}</p>
        <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNextDay)}</p>
        <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNext7Days)}</p>
        <p>{mnCurrency(hook.cashFlow.cashFlow[`${type}`].totalNext14Days)}</p>
      </div>
    </div>
  );
}
