import { Input } from "components/individual/Input";
import React, { useState } from "react";
import { Label } from "structure/Document";

/**
 * Render bank account
 * @param {import("components/individual/Input/types").PropsInput} props - Props
 * @returns {JSX.Element}
 */
export const BankAccount = (props) => {
  const [cuenta, setCuenta] = useState(props.value);

  const handleChange = (value) => {
    setCuenta(value);
    props?.onChange(value);
  };

  return (
    <>
      <Label required={props.required}>Cuenta</Label>
      <Input
        {...props}
        forwardRef={props?.forwardRef}
        type="number"

        // onChange={(e) => handleChange(e.target.value)}
      />
      {/* <input
        {...props}
        type="number"
        value={cuenta}
        onChange={(e) => handleChange(e.target.value)}
      /> */}
    </>
  );
};
