import {
  colorIcon,
  deleteIcon,
  dynamicIcons,
  noIdentifiedExtensionIcon,
} from "components/individual/SendEmail/FileInfo";
import { FileInfoCard, IconFile } from "components/individual/SendEmail/styles";
import React, { useContext } from "react";
import { ContextCloudFiles } from "..";

export const FilesAttached = () => {
  const { files, handleToggleCheck } = useContext(ContextCloudFiles);

  return (
    <>
      {files.map((file) => (
        <FileInfoCard key={window.crypto.randomUUID()}>
          <IconFile color={colorIcon[file.fileExtension]}>
            {dynamicIcons[file.fileExtension] || noIdentifiedExtensionIcon}
          </IconFile>
          <div>
            <p>{file.fileName}</p>
          </div>
          <div onClick={() => handleToggleCheck(false, file)}>{deleteIcon}</div>
        </FileInfoCard>
      ))}
    </>
  );
};
