import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextInvoiceReception } from "../..";
import { AddReceptionInvoiceContext } from "../../Filter";

export default function BtnContinueStep2({
  idCloseModal = window.crypto.randomUUID(),
}) {
  const {
    state: { isValidPartialitie, isAddingReception, concept },
    AddInvoiceReception,
    uploadEgress,
  } = useContext(AddReceptionInvoiceContext);

  const { refetchTable } = useContext(ContextInvoiceReception);

  const SpinnerLoading = <Spinner hidden={false} text="Agregando factura..." />;

  return (
    <>
      {concept !== null ? (
        !isAddingReception ? (
          <ButtonV2
            onClick={async (e) => {
              e.preventDefault(e);
              await uploadEgress(idCloseModal);
              // refetchTable();
            }}
          >
            Guardar
          </ButtonV2>
        ) : (
          SpinnerLoading
        )
      ) : isValidPartialitie ? (
        !isAddingReception ? (
          <ButtonV2
            onClick={async (e) => {
              e.preventDefault();
              await AddInvoiceReception();

              // refetchTable();
            }}
          >
            Guardar
          </ButtonV2>
        ) : (
          SpinnerLoading
        )
      ) : null}
    </>
  );
}
