import { Modal } from "components/general/Modal";
import {
  BankTransfer,
  BankTransferForm,
  SubmitTransfer,
} from "components/individual/BankAccountCombo";

import React from "react";
import { useState } from "react";
import PlainOption from "structure/Menu/PlainOption";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import usePermissions from "customHooks/usePermissions";

/**
 * Render t
 * @param {import("./types").PropsTransfer} props - Props
 * @returns {JSX.Element}
 */
export default function TransferMenu({
  children = null,
  forwardRef = undefined,
}) {

  const { checkPermission } = usePermissions();

  const [isOpen, setIsOpen] = useState(false);
  const dialog = useDialog();
  const open = () => {
    setIsOpen(true);
    dialog.open();
  };
  const close = () => {
    setIsOpen(false);
    dialog.close();
  };

  if(!checkPermission("dee3f4e3-ccd4-422a-96cb-74fc06083884")) return <></>

  return (
    <>
      <div onClick={open} ref={forwardRef}>
        {children === null ? (
          <PlainOption icon="banks" text="Transferencias" onClick={open} />
        ) : (
          children
        )}
      </div>

      <BankTransfer onSuccess={close}>
        {() => (
          <Dialog
            id="transferDialog"
            footer={
              <div className="d-flex justify-content-end align-items-center">
                <SubmitTransfer />
              </div>
            }
            closeCallback={close}
            title="Transferencia"
            forwardRef={dialog.refDialog}
          >
            <BankTransferForm />
          </Dialog>
        )}
      </BankTransfer>
    </>
  );
}
