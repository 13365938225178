import { PopUpDocumentsRelated, getInvoiceByUuid } from "helpers/Apis/Documents";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateUseInvoiceUuid}
 */
const INITIAL_STATE = {
  isLoading: true,
  invoice: undefined,
  hasRelatedOc:false,
  idProvider:null
};

export default function useInvoiceUuid(uuid = null) {
  const [state, setState] = useState(INITIAL_STATE);

  // getInvoiceByUuid
  useEffect(() => {
    (async function () {
      if (uuid === null) {
        setState(INITIAL_STATE);
        return
      }

      const apiInvoice = await getInvoiceByUuid(uuid);

      const idDocument = typeof(apiInvoice.idPreinvoice) === 'number' ? apiInvoice.idPreinvoice : apiInvoice.idOc

      const docsRelated = await PopUpDocumentsRelated(idDocument, false);

      const hasRelatedOc = Object.keys(docsRelated.data).includes('3') ? true : false;

      const concepts = apiInvoice.concepts.reduce((indexed,concept)=>({
        ...indexed,
        [concept.id]:concept
      }),{});

      const conceptsUnrepeated = Object.entries(concepts).map(([key,value])=>value);

      setState({
        ...state,
        invoice: {
          ...apiInvoice,
          concepts:conceptsUnrepeated
        },
        isLoading: false,
        hasRelatedOc,
        idProvider:hasRelatedOc ? docsRelated.data[3].customer.id : null
      });
    })();
  }, [uuid]);

  return {
    ...state,
  };
}
