import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { GetUEN } from "helpers/Apis/Documents";
import React, { createContext, useContext, useEffect, useState } from "react";
import style from "./styles.module.scss";
import { Label } from "structure/Document";

/**
 * @type {import("./types").StateUenSelectV4}
 */
const INITIAL_STATE = {
  isLoading: true,
  uen: null,
  uens: [],
};

/**
 * @type {import("./types").ContextUenV4}
 */
const ContextState = {
  ...INITIAL_STATE,
  setUen: () => {},
};

const ContextUenSelect = createContext(ContextState);

/**
 * Render a select to pick the UEN
 * @param {import("./types").PropsUenSelectV4} props - Props
 * @returns {JSX.Element}
 */
export function UenV4({ children, addAllOption = false , onlyActives = false }) {
  const [state, setState] = useState(INITIAL_STATE);

  const setUen = (uen) =>
    setState((current) => ({
      ...current,
      uen,
    }));

  useEffect(() => {
    (async function () {
      let apiUens = await GetUEN(true);

      if(onlyActives){
        apiUens = apiUens.filter(uen=>uen.status===true);
      }

      let apiUensParsed = [];

      if (addAllOption === false) {
        apiUensParsed = [
          { UENID: null, description: "--SELECCIONA--", disabled: true },
          ...apiUens,
        ];
      } else {
        apiUensParsed = [{ UENID: null, description: "Todos" }, ...apiUens];
      }

      setState((current) => ({
        ...current,
        isLoading: false,
        uens: apiUensParsed,
      }));
    })();
  }, []);

  if (state.isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando UEN(s)</LoadingText>}
      />
    );

  return (
    <ContextUenSelect.Provider value={{ ...state, setUen }}>
      {children}
    </ContextUenSelect.Provider>
  );
}

/**
 * Render the select input for the uen
 * @param {import("./types").UenSelectProps} props - Props
 * @returns {JSX.Element}
 */
export const UenSelect = ({ onChange = () => {}, defaultValue = null }) => {
  const hook = useContext(ContextUenSelect);

  if (hook.isLoading) return <></>;

  const handleOnChange = (uen) => {
    onChange(uen.UENID,uen);
    hook.setUen(uen);
  };

  return (
    <Select
      defaultSelected={{
        keyToCompare: "UENID",
        value: defaultValue,
      }}
      labelToDisplay="description"
      onChange={handleOnChange}
      options={async () => hook.uens}
    />
  );
};

/**
 * Sat code label
 * @param {import("./types").SatCodeProps} props - Props
 * @returns {JSX.Element}
 */
export const SatCode = ({ onChange = () => {} }) => {
  const hook = useContext(ContextUenSelect);
  useEffect(() => {
    if (hook.uen === null) return;

    onChange(hook.uen.SATcode);
  }, [hook.uen]);
  if (hook.uen === null) return <p className="m-0">Selecciona una UEN</p>;

  return (
    <p className="m-0">
      {hook.uen.SATcode} - {hook.uen.satCodeDescription}
    </p>
  );
};

/**
 * Sat code label
 * @param {import("./types").SatUmProps} props - Props
 * @returns {JSX.Element}
 */
export const SatUm = ({ onChange = () => {} }) => {
  const hook = useContext(ContextUenSelect);
  useEffect(() => {
    if (hook.uen === null) return;

    onChange(hook.uen.SATUM);
  }, [hook.uen]);
  if (hook.uen === null) return <p className="m-0">Selecciona una UEN</p>;

  return (
    <p className="m-0">
      {hook.uen.SATUM} - {hook.uen.satUmDescription}
    </p>
  );
};

/**
 * Sat code label
 * @param {import("./types").IvaProps} props - Props
 * @returns {JSX.Element}
 */
export const Iva = ({ onChange = () => {} }) => {
  const hook = useContext(ContextUenSelect);
  useEffect(() => {
    if (hook.uen === null) return;

    onChange(hook.uen.iva);
  }, [hook.uen]);
  if (hook.uen === null) return <p className="m-0">Selecciona una UEN</p>;

  return <p className={style.ivaUen}>{hook.uen.iva}</p>;
};

/**
 * Sat code label
 * @param {import("./types").IvaExcentProps} props - Props
 * @returns {JSX.Element}
 */
export const IvaExcent = ({ onChange = () => {} }) => {
  const hook = useContext(ContextUenSelect);
  useEffect(() => {
    if (hook.uen === null) return;

    onChange(hook.uen.isExempt);
  }, [hook.uen]);
  if (hook.uen === null)
    return (
      <div>
        <Label>IVA Excento</Label>
        <p className="m-0">Selecciona una UEN</p>;
      </div>
    );

  if (hook.uen.iva === 0) return <></>

  return (
    <div>
      <Label>IVA Excento</Label>
      <p className="m-0">{hook.uen.isExempt ? "Si" : "No"}</p>
    </div>
  );
};
