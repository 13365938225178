import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ContextHistoricalContracts } from "..";
import Icon from "components/individual/HeaderIcon";

export default function SearchFilter() {
  const hook = useContext(ContextHistoricalContracts);

  return (
    <ButtonV2 disabled={hook.isLoading} onClick={hook.attemptSearch}>
      <span>Buscar</span>
      <Icon icon="search" />
    </ButtonV2>
  );
}
