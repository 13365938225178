// @ts-check
import React, { useRef } from "react";
import { useContext } from "react";
import { ContextIncomingComplement } from "..";
import scssOverview from "../../../styles.module.scss";

export default function Complement() {
  const hook = useContext(ContextIncomingComplement);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (hook.complements.length <= 0)
    return (
      <div className={scssOverview.movementOverview}>
        <details>
        <summary>Complementos</summary>
        <h4 className="text-left">No hay complementos</h4>
      </details>
      </div>
    );

  return (
    <div className={scssOverview.movementOverview}>
      <details>
        <summary>Complementos</summary>

        {hook.complements.map((complement, i) => (
          <details key={`${key.current}-${i}`}>
            <summary>{complement.uuid}</summary>
            <div className={scssOverview.header}>
              <p>-</p>
              <p>Concepto</p>
              <p>Factura</p>
              <p>Parcialidad</p>
              <p>Moneda</p>
              <p>Total</p>
              <p>Asociado</p>
              <p>Importe ingreso</p>
              <p>TC</p>
            </div>

            {complement.associations.map((cxc,j) => (
              <label className={scssOverview.row} key={`${key.current}-${i}-${j}`}>
                {hook.displayCheckboxs ? (
                  <p className="d-flex justify-content-center align-content-center">
                    <input
                      checked={hook.isComplementSelected(complement.uuid)}
                      onClick={() => hook.handleCheckComplement(complement)}
                      type="checkbox"
                      name="complements"
                      id={`complement-${i}`}
                    />
                  </p>
                ) : (
                  <p>-</p>
                )}
                <p>{cxc.invoice.concept.description}</p>
                <p>{cxc.invoice.folio}</p>
                <p>
                  {cxc.cxc.partialitie.current}/
                  {cxc.cxc.partialitie.partialitie}
                </p>
                <p>{cxc.invoice.currency}</p>
                <p>
                  {cxc.invoice.total.text} {cxc.invoice.currency}
                </p>
                <p>
                  {cxc.paid.text} {cxc.invoice.currency}
                </p>
                <p>
                  {cxc.applied.text} {cxc.applied?.currency}
                </p>
                <p>{cxc.applied.tc.text} </p>
              </label>
            ))}
          </details>
        ))}
      </details>
    </div>
  );
}
