import {
  getBankAccountV2,
  getEgressOverview,
  getMovement,
} from "helpers/Apis/Banks";
import { getNonDeductibleAssociations } from "helpers/Apis/concilation";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Disassociate a expense of type concept (can has or not an invoice from the invoice reception)
 * @param {import("./types").ParamsUseDisassociateExpenseConcept} idMovement - Id of the movement to disassociate
 */
export default function useDisassociateExpenseConcept({
  idMovement,
  onChange = () => {},
}) {
  /**
   * State of the component
   * @type {import("./types").StateDisassociateNonDeductible}
   */
  const INITIAL_STATE = {
    isLoading: true,
    associations: [],
    movement: undefined,
    selecteds: {
      deductible: {},
      noDeductible: {},
    },
  };

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * NOTE: Must be a function for the deductibles and no deductibles since it can
   * be repeated the ids from the database records
   * Check the register in order to dis-associate
   * @param {boolean} check - Check value from the UI
   * @param {number} id - Id of the deductible association
   * @returns {void}
   */
  const checkDeductible = (check, id) => {
    // Add it to list
    if (check) {
      const item = state.associations.find(
        (register) => register.id === id && register.isDeductible
      );

      return setState({
        ...state,
        selecteds: {
          ...state.selecteds,
          deductible: {
            ...state.selecteds.deductible,
            [id]: item,
          },
        },
      });
    }

    // Delete from list
    const unref = { ...state.selecteds.deductible };
    delete unref[id];

    setState({
      ...state,
      selecteds: {
        ...state.selecteds,
        deductible: unref,
      },
    });
  };

  /**
   * Update the dis-associations to do
   * @param {boolean} check - Check value on the UI
   * @param {number} id - ID of the no deductible concept egress
   * @returns {void}
   */
  const checkNoDeductible = (check, id) => {
    if (check) {
      const item = state.associations.find(
        (register) => register.id === id && !register.isDeductible
      );

      return setState({
        ...state,
        selecteds: {
          ...state.selecteds,
          noDeductible: {
            ...state.selecteds.noDeductible,
            [id]: item,
          },
        },
      });
    }
    const unref = { ...state.selecteds.noDeductible };
    delete unref[id];

    setState({
      ...state,
      selecteds: {
        ...state.selecteds,
        noDeductible: unref,
      },
    });
  };

  const toggleAll = (check) => {
    if (check) {
      const newState = state.associations.reduce(
        (indexed, association) => {
          if (association.isDeductible)
            return {
              ...indexed,
              deductible: {
                ...indexed.deductible,
                [association.id]: association,
              },
            };

          return {
            ...indexed,
            noDeductible: {
              ...indexed.noDeductible,
              [association.id]: association,
            },
          };
        },
        {
          deductible: {},
          noDeductible: {},
        }
      );

      return setState({
        ...state,
        selecteds: newState,
      });
    }

    return setState({
      ...state,
      selecteds: {
        deductible: {},
        noDeductible: {},
      },
    });
  };

  /**
   * Check if the checkbox must be marked on the input UI
   * @param {number} id - ID of the association
   * @param {boolean} isDeductible - Is a deductible association
   * @returns {boolean}
   */
  const checkIfIsMarked = (id, isDeductible = true) => {
    const keys = Object.keys(
      isDeductible ? state.selecteds.deductible : state.selecteds.noDeductible
    );

    return keys.includes(`${id}`);
  };

  useEffect(() => {
    (async function () {
      const [apiAssociations, movement] = await Promise.all([
        getEgressOverview(idMovement),
        getMovement(idMovement),
      ]);

      const associations = apiAssociations.deductible.map((deductible) => ({
        ...deductible,
      }));
      const parsedAssociations = apiAssociations.nonDeductible.reduce(
        (list, current) => [...list, current],
        associations
      );

      setState({
        ...state,
        isLoading: false,
        associations: parsedAssociations,
        movement,
      });
    })();
  }, []);

  useEffect(() => {
    const deductible = Object.entries(state.selecteds.deductible).map(
      ([key, entry]) => entry
    );
    const noDeductible = Object.entries(state.selecteds.noDeductible).map(
      ([key, entry]) => entry
    );

    onChange({
      deductible,
      noDeductible,
    });
  }, [state.selecteds]);

  function masterCheckbox() {
    const deductibleCheckeds = Object.keys(state.selecteds.deductible).length;
    const noDeductibleCheckeds = Object.keys(
      state.selecteds.noDeductible
    ).length;

    return deductibleCheckeds + noDeductibleCheckeds > 0 ? true : false;
  }

  return {
    state,
    checkDeductible,
    checkIfIsMarked,
    checkNoDeductible,
    toggleAll,
    masterCheckbox,
  };
}
