import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextBankHeader } from "..";

export const Bank = () => {
  const hook = useContext(ContextBankHeader);

  return (
    <>
      <Label>Banco</Label>
      <p>
        {hook.bank.shortName} - {hook.bank.clave}
      </p>
    </>
  );
};
