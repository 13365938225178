export const Breadcrum = [
  {
    route: "/inicio",
    text: "Inicio",
  },
  {
    route: "/v2/bancos",
    text: "Bancos",
  },
  {
    route: "/",
    text: "Saldos iniciales",
  },
];
