import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextStep2 } from "./Step2V2";

export default function Create() {
  const { gridInfo, attemptCreate, isAssociating } = useContext(ContextStep2);

  return gridInfo === null ? null : gridInfo.isNegativeResidue === true ||
    gridInfo.isPayable === false ||
    gridInfo.isValidImport === false ||
    gridInfo.filteredGrid.array.length <= 0 ||
    gridInfo.isValidApplied === false ? null : (
    <div className="d-flex justify-content-end mb-3">
      {isAssociating ? (
        <Spinner hidden={false} text="Asociando CxC(s)..." />
      ) : (
        <ButtonV2 onClick={attemptCreate}>Asociar</ButtonV2>
      )}
    </div>
  );
}
