import { cancelPosition } from "helpers/Apis/proyects";
import { useState } from "react";
import Swal from "sweetalert2";

/**
 * @type {import("./types").StateCancelPosition}
 */
const STATE = {
  status: "default",
};

/**
 * Hook to handle the cancelation of positions on a proyect
 * @param {number} idPosition - Id of the position
 * @param {()=>void} onCancelated 
 
 */
export default function useCancelPosition(idPosition, onCancelated) {
  const [state, setState] = useState(STATE);

  const handleDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Cancelar posición",
      text: "¿Estás seguro de cancelar la posición? El cambio tendra efecto inmediato si confirmas la acción",
      showConfirmButton: true,
      confirmButtonText: "Si, cancelar posición",
      denyButtonText: "No, regresar",
      showDenyButton: true,
      icon: "warning",
    });

    if (isConfirmed) {
      const wasCancelated = await cancelPosition(idPosition);

      if (wasCancelated) {
        onCancelated();
        Swal.fire({
          title: "Posición cancelada",
          text: "La posición ha sido cancelada exitosamente",
          icon: "success",
        });
      }
    }
  };

  return {
    handleDelete,
    ...state,
  };
}
