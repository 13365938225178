import { add } from "date-fns";
import {
  getAccount,
  getMovementReport,
  getMovementsReport,
} from "helpers/Apis/Banks/index";
import { getBankMovementsExcel } from "helpers/Apis/Documents";
import {
  dateAtCeroHours,
  dateToDbFormat,
  monthRange,
  parseDateToText,
} from "helpers/dates";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";

/**
 * @type {import("./types").StateUseMovements}
 */
const INITIAL_STATE = {
  isLoading: true,
  movements: [],
  bankAccount: undefined,
  from: monthRange().firstDay,
  to: dateAtCeroHours(new Date()),
  minDate: null,
  refetch: false,
  isGeneratingXlsx: false,
  isGeneratingPdf: false,
};

/**
 * @param {number} [idBankAccount=null]
 * @returns {import("./types").ReturnUseMovements}
 */
export default function useMovements(idBankAccount = null) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const bankAccount = await getAccount(idBankAccount);

      setState((current) => ({
        ...current,
        isLoading: false,
        bankAccount,
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {

      if (state.bankAccount === undefined) return;

      setState((current) => ({
        ...current,
        movements: [],
        isLoading: true,
      }));

      // TODO: Cuando se haga merge con la rama de mobile usar la funcion de: getLowestDate
      // Convert date strings to Date objects
      const dates = state.bankAccount.monthsClosed.map(
        (dateString) => new Date(dateString)
      );

      // Find the lowest date using Array.reduce()
      const lowestDate = dates.reduce((minDate, currentDate) => {
        return currentDate < minDate ? currentDate : minDate;
      });

      // Convert the lowestDate back to the "yyyy-mm-dd" format
      const minDate = lowestDate.toISOString().slice(0, 10);
      const minDateToUse = add(new Date(`${minDate}:`), {
        days: 1,
      });

      if (
        state.from instanceof Date === false ||
        state.to instanceof Date === false
      ) {
        setState((current) => ({
          ...current,
          isLoading: false,
          movements: [],
          minDate: minDateToUse,
        }));
        return;
      }

      const movements = await getMovementsReport(
        state.bankAccount.id,
        dateToDbFormat(state.from),
        dateToDbFormat(state.to)
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        movements,
        minDate: minDateToUse,
      }));
    })();
  }, [state.bankAccount, state.from, state.to, state.refetch]);

  // useEffect(() => {
  //   setState((current) => ({
  //     ...current,
  //     to: null,
  //     from: null,
  //   }));
  // }, [state.bankAccount]);

  /**
   * Set the bank account for the movement report
   * @param {import("../../../../../../server/models/banks/types").BankAccountI} bankAccount
   * @returns
   */
  const setBankAccount = (bankAccount) =>
    setState((current) => ({
      ...current,
      bankAccount,
    }));

  /**
   * Set the from date
   * @param {Date} from - From date
   * @returns {void}
   */
  const setFrom = (from) =>
    setState((current) => ({
      ...current,
      from,
    }));

  /**
   * Set the to date
   * @param {Date} to - To date
   * @returns {void}
   */
  const setTo = (to) =>
    setState((current) => ({
      ...current,
      to,
    }));

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const downloadExcel = async () => {
    setState((current) => ({
      ...current,
      isGeneratingXlsx: true,
    }));

    const excel = await getBankMovementsExcel(
      dateToDbFormat(state.from),
      dateToDbFormat(state.to),
      state.bankAccount.id
    );

    if (excel instanceof Blob) {
      saveAs(
        excel,
        `${state.bankAccount.bank.shortName} ${
          state.bankAccount.account
        } - Movimientos ${parseDateToText(state.from)} al ${parseDateToText(
          state.to
        )}.xlsx`
      );
    }

    setState((current) => ({
      ...current,
      isGeneratingXlsx: false,
    }));
  };

  const downloadPdf = async () => {
    setState((current) => ({
      ...current,
      isGeneratingPdf: true,
    }));

    const pdfReport = await getMovementReport(
      state.bankAccount.id,
      state.from,
      state.to
    );

    if (pdfReport instanceof Blob) {
      saveAs(
        pdfReport,
        `${state.bankAccount.bank.shortName} ${
          state.bankAccount.account
        } - Movimientos ${parseDateToText(state.from)} al ${parseDateToText(
          state.to
        )}.pdf`
      );
    }

    setState((current) => ({
      ...current,
      isGeneratingPdf: false,
    }));
  };

  /**
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/v2/bancos",
      text: "Bancos",
    },
    {
      route: "/movimientos",
      text: "Movimientos",
    },
  ];

  return {
    ...state,
    breadcrum,
    setBankAccount,
    setFrom,
    attemptSearch,
    downloadExcel,
    downloadPdf,
    setTo,
    isValid:
      state.bankAccount !== undefined &&
      state.from instanceof Date &&
      state.to instanceof Date,
  };
}
