import { GetCustomer } from "helpers/Apis/Directory";
import { updateOdcStatusToSended } from "helpers/Apis/Documents";
import { getOcsProyect, getProyect } from "helpers/Apis/proyects";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateOcProyects}
 */
const INITIAL_STATE = {
  ocs: [],
  pages: 0,
  currentPage: 1,
  noRecordsFound: 0,
  rowsPerPage: 100,
  oc: undefined,
  proyect: undefined,
  customer: undefined,
  position: undefined,
  status: "loading",
  refetch:false
};

/**
 * Handle the creation of oc on the proyects module
 * @param {number} idProyect - Id of the proyect
 * @returns {import("./types").ReturnUseOcProyects}
 */
export default function useOcProyects(idProyect) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const proyect = await getProyect(idProyect, null);

      const customer = await GetCustomer(proyect.idClient);

      setState((current) => ({
        ...current,
        proyect,
        customer,
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        status: "loading",
        ocs: [],
        pages:0,
        noRecordsFound:0
      }));

      const ocs = await getOcsProyect({
        idProyect: idProyect,
        orderAsc: false,
        page: state.currentPage,
        rowsPerPage: state.rowsPerPage,
      });
      setState((current) => ({
        ...current,
        ocs: ocs.data,
        pages: ocs.pages,
        noRecordsFound: ocs.noRecordsFound,
        status: "none",
      }));
    })();
  }, [state.currentPage,state.refetch]);

  useEffect(() => {
    if (state.proyect === undefined || state.ocs.length <= 0) return;

    const position = state.proyect.positions.find(
      (pos) => pos.id === state.ocs[0].idPosition
    );
    setState((current) => ({
      ...current,
      position,
    }));
  }, [state.proyect, state.ocs]);

  /**
   * @param {import("helpers/Apis/proyects/types").Datum} oc
   */
  const setOc = (oc) =>
    setState((current) => ({
      ...current,
      oc,
    }));

  const refetchData = () => setState(current=>({
    ...current,
    refetch:!current.refetch
  }));

  const updateOc = async (idOc) => {
    await updateOdcStatusToSended(idOc);
  };

  return {
    state,
    setOc,
    refetchData,
    updateOc,
  };
}
