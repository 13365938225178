import React from "react";
import RedirectButton from "structure/Menu/Redirect";
import TextIcon from "structure/Menu/TextIcon";
import Back from "../Back";
import { ChangeMenuView } from "../ChangeMenuView";
import TransferMenu from "./Transfer";
import PlainOption from "../PlainOption";
import usePermissions from "customHooks/usePermissions";

export default function BanksMenu() {

  const { checkPermission } = usePermissions();

  if(!checkPermission("8e1659e5-740b-4cdb-a02d-373433d3e122")) return <></>

  return (
    <>
      <Back menu="general" text="Bancos" />

      <RedirectButton
        permission="8e1659e5-740b-4cdb-a02d-373433d3e122"
        text={<TextIcon icon="banks" text="Cuentas" />}
        to={"/v2/bancos"}
      />

      <RedirectButton
        text={<TextIcon icon="banks" text="Correción saldos" />}
        to={"/v2/bancos/correccion"}
      />

      <PlainOption
        text="Agregar"
        permission="78dc3e8a-6c74-45a1-b732-d598542f3439"
        icon="administration"
        onClick={(e) => document.getElementById("openAddBankAccount").click()}
      />

      <TransferMenu />

      <ChangeMenuView
        icon="banks"
        text="Anticipos"
        menu="anticipos"
        permission="e728c668-64b0-48b7-ac78-7b3fd99c7908"
      />

      <ChangeMenuView
        icon="banks"
        text="Reportes"
        menu="reportBanks"
        permission="8e1659e5-740b-4cdb-a02d-373433d3e122"
      />
    </>
  );
}
