import React, { useEffect , useState } from "react";

import { GetOverviewFileInfo } from "../../../../helpers/Apis/associateFiles";
import { useParams } from "react-router-dom";

function LabelAssociateFiles() {
  const { idCustomer, idDocument } = useParams();
  
  /**
   * @type {[OverviewFile,Function]}
   */
  const [overview, setOverview] = useState(null);

  useEffect(() => {
    (async function () {

      const apiOverview = await GetOverviewFileInfo(idDocument, idCustomer);

      setOverview(apiOverview);
      
    })();
  }, []);

  return (
    <div id="asociateFileInfo" className="my-4 d-flex">
      {overview !== null ? (
        <>
          <div className="mr-4">
            <b>Razon social: </b>
            <span>{overview.customer.socialReason}</span>
          </div>

          <div className="mr-4">
            <b>Tipo de documento: </b>
            <span>{overview.document.type.description}</span>
          </div>

          <div>
            <b>No. documento: </b>
            <span>{overview.document.number}</span>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default LabelAssociateFiles;
