import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { ImportArea } from "./Styles";

export default function Import({ value, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Importe"
            value={!value ? document.amount.subtotal.text : value}
          />
        </div>
      ) : (
        <ImportArea>
          <Informative
            readonly={true}
            label="Importe"
            value={!value ? document.amount.subtotal.text : value}
          />
        </ImportArea>
      )}
    </>
  );
}
