import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext, useRef } from "react";
import { ContextInvoice } from "..";
import { Header, Row } from "./styles";
import { parseDateToText } from "helpers/dates";
import doc from "./styles.module.scss";
import { Pagination } from "components/individual/Pagination";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import MobileNoAccounting from "./MobileNoAccounting";
import { parseToFolio } from "helpers/documents";

export default function InvoiceTable() {
  const hook = useContext(ContextInvoice);

  const key = useRef(window.crypto.randomUUID());

  const spinnerText = useRef(
    `Cargando ${hook.from === "pedidos" ? "pedidos" : "facturas"}`
  );

  if (hook.isFetchingData)
    return <Spinner hidden={false} text={spinnerText.current} />;

  if (!hook.recordsFounded)
    return (
      <h1 className="text-center">
        No existen datos con los filtros solicitados
      </h1>
    );

  return (
    <div className={doc.table}>
      <Header from={hook.from} renderAccounting={hook.renderAccounting}>
        <p>No</p>
        <p>{hook.from !== "pedidos" ? "Fecha de emisión" : "Facturar"}</p>
        {!hook.renderAccounting ? (
          <p>{hook.from === "pedidos" ? "Ejecutivo" : "Vencimiento"}</p>
        ) : null}
        {hook.from === "facturas_emitidas" ? <p>Ejecutivo</p> : null}
        <p>Razón Social</p>
        <p>Moneda</p>
        {hook.renderAccounting ? (
          <>
            <p>Importe</p>
            <p>IVA</p>
          </>
        ) : null}
        <p>Total</p>
        {!hook.renderAccounting ? <p>Saldo CxC</p> : null}
        {!hook.renderAccounting ? <p>Estatus</p> : null}
        {hook.renderAccounting ? <p>Contabilizado</p> : null}
      </Header>

      <div className={doc.containerDocuments}>
        {hook.rows.map((row, i) => (
          <div key={`${key.current}-${i}`}>
            <input
              id={`${key.current}-${i}-row`}
              type="radio"
              name={key.current}
              className="d-none"
              onChange={(e) => hook.setRowSelected(row)}
            />
            <Row
              from={hook.from}
              renderAccounting={hook.renderAccounting}
              htmlFor={`${key.current}-${i}-row`}
            >
              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={row.idPreinvoice}
              >
                <p className="m-0">
                  {parseToFolio(+row?.documentNumber || +row?.numeroDocumento)}
                </p>
              </ModalDocsRelatedV2>

              <p className="text-center">
                {hook.from === "pedidos"
                  ? row.facturar
                  : parseDateToText(`${row.registro.yyyymmdd}:`)}
              </p>
              {!hook.renderAccounting ? (
                <p>
                  {hook.from === "pedidos"
                    ? row.iniciales
                    : row.expirationDateFl}
                </p>
              ) : null}

              {hook.from === "facturas_emitidas" ? (
                <p>{row.iniciales}</p>
              ) : null}
              <p>{row.razonSocial}</p>
              <p className="text-center">{row.moneda}</p>
              {hook.renderAccounting ? (
                <>
                  <p className="text-right">{row.import}</p>
                  <p className="text-right">{row.iva}</p>
                </>
              ) : null}

              <p className="text-right">{row.total.texto}</p>
              {!hook.renderAccounting ? (
                <p className="text-right">{row.saldo}</p>
              ) : null}
              {!hook.renderAccounting ? <p>{row.estatus}</p> : null}
              {hook.renderAccounting ? (
                <label
                  htmlFor={`${key.current}-${i}-accounting`}
                  className="d-flex justify-content-center align-items-center"
                >
                  <input
                    onChange={(e) => hook.toggleAccountingCheck(row.id)}
                    type="checkbox"
                    id={`${key.current}-${i}-accounting`}
                    checked={
                      hook.state.accountingStatusGrid.operation?.[row.id] ||
                      false
                    }
                  />
                </label>
              ) : null}
            </Row>
            <MobileNoAccounting {...row} />
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.state.page}
        pages={hook.state.totalPages}
        onChange={hook.setPage}
      />
    </div>
  );
}
