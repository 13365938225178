import React, { useContext, useRef } from "react";
import style from "../styles.module.scss";
import TypeSelect from "../TypeSelect";
import StatusFilter from "../StatusFilter";
import SearchDirectory from "../SearchDirectory";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import CreateCustomer from "../CreateCustomer";
import MobileShortcut from "./MobileShortcut";
import { DirectoryContext } from "..";
import Icon from "components/individual/HeaderIcon";
import ExcelReport from "./ExcelReport";

export default function FilterDirectory() {
  const idForm = useRef(window.crypto.randomUUID());

  const hook = useContext(DirectoryContext);

  return (
    <div className={style.containerFilter}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          hook.attemptSearchRequested();
        }}
        className={style.filter}
        id={idForm.current}
      >
        <TypeSelect />
        <StatusFilter />
        <SearchDirectory />
        <ButtonV2 type="submit" form={idForm.current}>
          <span>Buscar</span>
          <Icon icon="search" />
        </ButtonV2>
        <ExcelReport/>
        <CreateCustomer />
      </form>

      <div className={style.filterMobile}>
        <MobileShortcut />
        <p>
          <b className="d-block">Estatus</b> {hook.status.label}
        </p>
        <p>
          <b className="d-block">Tipo</b> {hook.type.label}
        </p>
      </div>
    </div>
  );
}
