import { Spinner } from "components/individual/Spinner/Spinner";
import usePopupDocsRelated, {
  ContextDocsRelatedValue,
} from "customHooks/usePopupDocsRelated";
import React, { createContext, useEffect } from "react";
import Cotizacion from "./Cotizacion";
import style from "./styles.module.scss";
import Pedido from "./Pedido";
import FacturaEmitida from "./FacturaEmitida";
import Contrato from "./Contrato";
import FacturaRecibida from "./FacturaRecibida";
import ODC from "./ODC";
import Details from "components/general/Details";

export const ContextDocsRelated = createContext(ContextDocsRelatedValue);

/**
 * Render the information of docs related
 * @param {import('./types').PropsDocsRelatedV2} props - Props
 * @returns {JSX.Element}
 */
export default function DocsRelatedV2({ id, isLegalDocumentId = false }) {
  const hook = usePopupDocsRelated(id, isLegalDocumentId);

  useEffect(() => {
    hook.openModal();
  }, []);

  if (hook.popUpInfo.isLoading)
    return (
      <Spinner hidden={false} idSpinner="loadingDocsRelated" text="Cargando" />
    );

  return (
    <ContextDocsRelated.Provider value={hook}>
      <div className={style.containerDocsRelated}>
        <Details summary="Cliente" open={hook.popUpInfo.defaultOpenCustomer} style={{maxHeight:"100%"}}>
          <div className={style.documentsRelated}>
            <Cotizacion />
            <Pedido />
            <FacturaEmitida />
            <Contrato />
          </div>
        </Details>
      </div>

      <div className={style.containerDocsRelated}>
        <Details summary="Proveedor" open={hook.popUpInfo.defaultOpenProvider}>
          <div className={style.documentsRelated}>
            <ODC />
            <FacturaRecibida />
          </div>
        </Details>
      </div>
    </ContextDocsRelated.Provider>
  );
}
