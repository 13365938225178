import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { ModalDismissButton,Button } from '../buttons/Buttons';
import { monthRange,getNumberDate,dateToDbFormat } from "../../../helpers/dates";
import StatusCalendar from '../StatusCalendar/StatusCalendar';

export function RangeFilter({ 
  idModal,
  beginRange=null,
  endRange=null,
  aditionalClasses = '',
  onChange = () => {}
}) {
    const [range,setRange] = useState([]);

    const inputBeginRange = document.getElementById('')

    function setCustomRange(){
      const begin = getNumberDate(beginRange);
      const end = getNumberDate(endRange);

      printDateUI(`${begin.day}/${begin.monthAbrev}/${begin.year} - ${end.day}/${end.monthAbrev}/${end.year}`);
      setHiddenInputs(beginRange,endRange);
    }

    /**
     * Print the date on the UI
     * @param {string} date - Date to print
     */
    function printDateUI(date){
      const inputUI = document.getElementById('dateRange');
      inputUI.innerText = date;
    }

    /**
     * Set the hidden inputs with the format request of the db
     * 
     * @param {Date} start - Start date range
     * @param {Date} end - End date range
     */
    function setHiddenInputs(start,end){
      const inputStart = document.getElementById('filterStartRange');
      inputStart.value = dateToDbFormat(start);

      const inputEnd = document.getElementById('filterEndRange');
      inputEnd.value = dateToDbFormat(end);
    }

  function updateMonthInput() {
    const range = monthRange();
    document.getElementById('dateRange').innerText = range.rangeNumber;
  
    setHiddenInputs(range.firstDay,range.lastDay);
  }

  function showConfirmation(rangeFilter){
      document.getElementById(`confirmRange${idModal}`).classList.remove('d-none');
      document.getElementById(`idStatusValidation${idModal}`).classList.add('correct');
      setRange(rangeFilter);
  }

  function updateRange(){
    const rangeBegin = getNumberDate(range[0]);
    document.getElementById('filterStartRange').value = `${rangeBegin.year}-${rangeBegin.month}-${rangeBegin.day}`;

    const rangeEnd = getNumberDate(range[1]);
    document.getElementById('filterEndRange').value = `${rangeEnd.year}-${rangeEnd.month}-${rangeEnd.day}`;

    document.getElementById('closeRangeFilter').click();

    const dataBegin = getNumberDate(range[0]);
    const dataEnd = getNumberDate(range[1]);

    // const textBegin = `${dataBegin.day < 10 ? '0'+ dataBegin.day : dataBegin.day }/${dataBegin.month < 10 ? '0'+ dataBegin.month : dataBegin.month}/${dataBegin.year}`;
    // const textEnd = `${dataEnd.day < 10 ? '0'+ dataEnd.day : dataEnd.day}/${dataEnd.month < 10 ? '0'+ dataEnd.month : dataEnd.month}/${dataEnd.year}`;

    printDateUI(`${dataBegin.day}/${dataBegin.monthAbrev}/${dataBegin.year} - ${dataEnd.day}/${dataEnd.monthAbrev}/${dataEnd.year}`);

    onChange({
      dataBegin:new Date(dataBegin.year,dataBegin.month-1,dataBegin.day),
      dataEnd:new Date(dataEnd.year,dataEnd.month-1,dataEnd.day)
    });

  }

  useEffect(() => {

    if((beginRange && endRange) === null ){
      updateMonthInput();
    }else{
      setCustomRange();
    }

  }, [beginRange,endRange]);

  return (
    <>
      <div className={`containerRange ${aditionalClasses}`}>
        <label className="d-block mb-2">Desde</label>
        <div className="fakeCalendar" onClick={() => document.getElementById(`button${idModal}`).click()}>
          <p id="dateRange"></p>
        </div>
      </div>

      <input className="d-none" id="filterStartRange" type="text"/>
      <input className="d-none" id="filterEndRange" type="text"/>

      <button
        id={`button${idModal}`}
        className="d-none"
        onClick={(e) => e.preventDefault()}
        data-toggle="modal"
        data-target={`#${idModal}`}
      ></button>

      <div
        class="modal fade"
        id={idModal}
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Rango de busqueda
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mx-auto">
            
            <StatusCalendar
                idStatus = {`idStatusValidation${idModal}`}
                textCalendar = 'Rango de fechas valido'
            />

              <Calendar
                selectRange = {true}
                onChange = {(value)=>showConfirmation(value)}
                returnValue = 'range'
              />
            </div>
            <div class="modal-footer">

            <ModalDismissButton
                idBtn = 'closeRangeFilter'            
                text = 'Cerrar'
            />
            
            <Button
                id = {`confirmRange${idModal}`}
                aditionalClasses = 'd-none'
                text = 'Aplicar'
                funcion = {()=>updateRange()}
            />
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
