/**
 *
 * @template T
 * @param {T} initialData
 * @example
 * 
 *   const INITIAL_STATUS = {
        type: {
        value: -1,
        label: "Todos",
        },
        status: {
        value: -1,
        label: "Todos",
        },
        search: "",
    };

    const [state, setState] = useState({ ...INITIAL_STATE, ...retrieveData("directory") });

    useEffect(()=>{
        setMemoryData({
        search:state.search,
        status:{
            label:state.status.label,
            value:state.status.value
        },
        type:{
            label:state.type.label,
            value:state.type.value
        }
        },'directory')
    },[state.search,state.status,state.type])
 */
export default function useMemoryFilter(initialData) {
  /**
   * Set the information into session storage
   * @param {T} data
   * @param {string} key
   */
  const setMemoryData = (data, key) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  };

  /**
   * Get the information from the `browser history`
   * @param {string} key
   * @returns {T}
   */
  const retrieveData = (key) =>
    JSON.parse(sessionStorage.getItem(key)) || initialData;

  return {
    setMemoryData,
    retrieveData,
  };
}
