import Ticket from "components/general/Ticket";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import style from "./styles.module.scss";

export default function SearchedTicket() {
  const { ticket } = useQueryParams();

  return (
    <main className={style.ticketContent}>
      <BreadCrumV2
        path={[
          {
            route: `/inicio`,
            text: "Inicio",
          },
          {
            route: `/procesos-especiales/help-desk/tickets`,
            text: "Procesos Especiales",
          },
          {
            route: `/procesos-especiales/help-desk/tickets`,
            text: "Help Desk",
          },
          {
            route: `/procesos-especiales/help-desk/tickets`,
            text: `#${ticket}`,
          },
        ]}
      />
      <div className="container">
        <Ticket id={ticket} ticket={null} />
      </div>
    </main>
  );
}
