import { ContextMaterials } from "pages/Materials";
import React, { useContext } from "react";

/**
 *
 * @param {import('../../../../../../types/proyects/materials').DtoListMaterials} material
 */
export default function AmountValidationMessage(material) {
  const materials = useContext(ContextMaterials);

  if (
    typeof materials.grid[material.id]?.provider === "number" &&
    materials.grid[material.id]?.quantity === undefined
  )
    return <p className="text-danger font-weight-bold">Obligatorio</p>;

  if (materials.grid[material.id] === undefined) return <></>;

  if (+materials.grid[material.id].quantity === 0) return <></>;

  if (+materials.grid[material.id].quantity > material.residueQuantity)
    return (
      <p className="text-danger font-weight-bold">
        Máximo {material.residueQuantity}{" "}
        {material.residueQuantity === 1 ? "unidad" : "unidades"}
      </p>
    );

  return <></>;
}
