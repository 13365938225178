import React from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useMovement from "customHooks/useMovement";
import { useParams } from "react-router-dom";
import { BankAccountHeader } from "structure/BankAccountHeader";
import { CardMovementV2 } from "./CardMovementV2";
import { FormAddMovement } from "./FormV2";
import { createContext } from "react";
import { Container, ContainerCards } from "./Styles";

/**
 * @type {import("react").Context<import("./types").ContextAddMovement>}
 */
export const AddMovementContext = createContext();
const { Provider } = AddMovementContext;

export default function AddMovementsV2({ typeMovement = 1 }) {
  const { account } = useParams();

  const movementHook = useMovement(+account, typeMovement);

  return (
    <Provider
      value={{
        ...movementHook,
      }}
    >
      <BreadCrumV2 path={movementHook.breadcrum} />
      <Container>
        <BankAccountHeader idBankAccount={+account} useDefaultDesign />

        <FormAddMovement />
        <hr />

        <h3 className="mb-4">Informacion en cola</h3>
        <ContainerCards>
          {movementHook.movements.map((movement) => (
            <CardMovementV2
              key={movement.id}
              amount={movement.importConcept}
              concept={movement.concept}
              payMethod={movement.descriptionPayMethod}
              reference={movement.reference}
              date={movement.registerDate}
              onClickCross={() => movementHook.deleteMovement(movement.id)}
            />
          ))}
        </ContainerCards>
      </Container>
    </Provider>
  );
}
