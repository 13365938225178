import React, { useContext, useEffect, useState } from "react";
import { useBanks } from "components/individual/BankCard/hooks/useBanks";
import Select from "react-select";
import { Label } from "structure/Document";
import { BankAccountReportContext } from "structure/Menu/Banks/Reports/BankAccount";

/**
 * Render options to create the report
 * @param {import("./types").PropsBankAccountSelect} props - Props
 * @returns {JSX.Element}
 */
export default function BankAccountSelect({
  onChange = () => {},
  allowExchangeAccount = false,
  bankLabel = "",
}) {
  const hook = useBanks();

  /**
   * @type {[import("../../../../../server/models/banks/types").BankAccountI[],()=>void]}
   */
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (hook.infocards.length <= 0) return;

    const parsedAccounts = hook.infocards.map((account) => ({
      ...account,
      value: account.id,
      label: `${account.bank.shortName} - ${account.account}`,
    }));

    setAccounts(parsedAccounts);
  }, [hook.infocards]);

  return (
    <div>
      <Label>Cuenta de banco</Label>
      {allowExchangeAccount ? (
        <Select
          classNamePrefix="saiko-select"
          className="saiko-select w-100"
          placeholder={`Selecciona`}
          //   defaultValue={defaultValue}
          isClearable={false}
          isSearchable={true}
          options={accounts}
          isLoading={false}
          onChange={(account) => onChange(account)}
        />
      ) : (
        <p>{bankLabel}</p>
      )}
    </div>
  );
}
