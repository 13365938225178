import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  gap: 0 1rem;

  @media (max-width: 767px) {
    flex-direction:column;
    gap:0.15rem 0;
    width:100%;
  } 




`;

export const FooterInfoTex = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
`;

export const FooterDiferentCurrency = styled.span`
  display: flex;
  font-size: x-small;
  font-weight: bold;
  color: red;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
`;
