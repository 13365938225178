import React from "react";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import DownloadSatInvoices from "components/general/DownloadSatInvoices";

/**
 * Render the button to download the invoice pdf or the sat pdf
 * @param {import('./types').PropsPdfInvoice} props - Props
 * @returns {JSX.Element}
 */
export default function Pdf({
  id,
  type = null,
  documentNumber = "",
  idPdf = null,
  idXml = null,
}) {

  if (type === null || type === undefined) return <></>;

  if (type === "pedidos")
    return <DonwloadPdfV2 id={id} fileName={`Pedido-${documentNumber}`} />;

  return <DownloadSatInvoices id={id} idPdf={idPdf} idXml={idXml} />;


}
