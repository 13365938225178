import { useState } from "react";
import { schemaTc } from "../schemaValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useDialog from "customHooks/useDialog";
import { addTc } from "helpers/Apis/Banks";
import { useContext } from "react";
import { SocketContext } from "context/Socket";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";

const INITIAL_STATE = {
  isLoading: false,
  dof: 0,
  pays: 0,
  enterprise: 0,
  sells: 0,
  purchase: 0,
  fix: 0,
};

export default function useAddTc() {
  const form = useForm({
    resolver: yupResolver(schemaTc),
  });

  const socket = useContext(SocketContext);

  const dialog = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  const attemptCreateTc = async () => {
    setState((current) => ({
      ...current,
      isLoading: true,
    }));

    /**
     * @type {import("../../../../../server/controllers/typesBanks").DtoAddTc}
     */
    const dto = {
      dof: state.dof,
      fix: state.fix,
      pays: state.pays,
      sales: state.purchase,
      saiko: state.enterprise,
      purchase: state.sells,
    };

    const wasCreated = await addTc(dto);

    setState((current) => ({
      ...current,
      isLoading: false,
    }));

    if (wasCreated) {
      dialog.close();
      socket.notifyNewTcAdded(dateToDbFormat(dateWithCeroHours(new Date())));
    }
  };

  const displayError = (error) => console.log(error);

  /**
   * Update amount
   * @param {import("../types").KeyAmount} key - Key
   * @param {number} amount
   * @returns {void}
   */
  const updateAmount = (key, amount) =>
    setState((current) => ({
      ...current,
      [key]: amount,
    }));

  const isValidForm =
    state.dof >= 1 &&
    state.enterprise >= 1 &&
    state.pays >= 1 &&
    state.sells >= 1 &&
    state.fix >= 1 &&
    state.purchase >= 1;

  return {
    ...state,
    form,
    attemptCreateTc,
    displayError,
    dialog,
    updateAmount,
    isValidForm,
  };
}
