import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import Dialog from "structure/Dialog";
import MoreInfoConsecutiveOc from "structure/MoreInfoConsecutiveOc";

/**
 * Render the more information for the OC consecutive
 * @param {import('./types').PropsMoreInfo} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfo({ children = <></>, idOc = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const dialog = useDialog();

  const handleOpen = () => {
    setIsOpen(true);
    dialog.open();
  };

  const handleClose = () => {
    setIsOpen(false);
    dialog.close();
  };

  return (
    <>
      <div onClick={handleOpen}>{children}</div>

      <Dialog
        title="Más información"
        forwardRef={dialog.refDialog}
        closeCallback={handleClose}
      >
        {isOpen ? <MoreInfoConsecutiveOc idOc={idOc} /> : null}
      </Dialog>
    </>
  );
}
