import React, { useEffect, useState } from "react";

// LIBS
import PropTypes from "prop-types";

// COMPONENTS
import { Select } from "../../../individual/inputs/Inputs";

// APIS
import { GetUEN } from "../../../../helpers/Apis/Documents";

function UEN({ css, uenList = () => {}, onChange }) {
  const [uen, setUen] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      if (uen.length === 0) {
        const apiUen = await GetUEN();
        setUen(apiUen);
        uenList(apiUen);
      }
    }

    initialLoad();
  }, []);

  return (
    <Select
      addTodos={false}
      options={uen}
      label="UEN"
      nameSelect="uen"
      idSelect="uen"
      textJSON="description"
      valueJSON="UENID"
      className={css}
      onChange={onChange}
    />
  );
}

UEN.propTypes = {};

export default UEN;
