import { Search } from "components/individual/Search";
import React from "react";
import { Label } from "structure/Document";
import { ConcilationContext } from "..";
import { useContext } from "react";
import InformativeTooltip from "components/individual/InfoTooltip";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function SearchBarConcilation() {
  const hook = useContext(ConcilationContext);

  return (
    <div className="d-flex align-items-end">
      <div className="w-100">
        <Label>
          Búsqueda
          {hook.search.length >= 1 ? (
            <>
              <InformativeTooltip>
                <>
                  <p>La búsqueda ignorara los filtros de</p>

                  <ul>
                    <li>1. Desde</li>
                    <li>2. Hasta</li>
                    <li>3. Estatus</li>
                    <li>4. Tipo</li>
                  </ul>

                  <p>
                    <b>
                      Para encontrar el no. de mov. solicitado (si es que
                      existe)
                    </b>
                  </p>
                </>
              </InformativeTooltip>
              👈 ❗
            </>
          ) : null}
        </Label>
        <Search
          placeholder="No. mov."
          value={hook.search}
          hideSearchIcon={true}
          onChange={(value) => hook.setSearch(value)}
          onDelete={() => hook.setSearch("")}
          onSearch={() => hook.triggerSearch()}
        />
      </div>

      <ButtonV2
        style={{
          margin: "0 0 0 10px",
        }}
        onClick={() => hook.triggerSearch()}
      >
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>
    </div>
  );
}
