import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextEditRol } from "..";

export default function ContinueButton() {
  const {
    navigateToStep,
    description: { isValid },
  } = useContext(ContextEditRol);
  return isValid ? (
    <ButtonV2 onClick={() => navigateToStep(1)}>Continuar</ButtonV2>
  ) : null;
}
