import { useEffect, useState } from "react";
import {
  cancelCxpAssociation,
  cancelEgressExpense,
  getEgressOverview,
} from "helpers/Apis/Banks/index";

const AUTOCALCULATED_CONCEPTS = [30, 31, 32];

/**
 * @type {import("pages/BanksV2/Egresses/Table/MoreInfo/types").StateMoreInfoEgress}
 */
const INITIAL_STATE = {
  isOpen: true,
  associations: [],
  aditionalConcepts:[],
  canDisplayCheckbox: false,
  isValidCancellation: false,
  isCancelling: false,
  isLoading: true,
  selecteds: {},
};

/**
 *
 * @param {import("../../../../server/helpers/validations/bank/movements/types").BankAccountMovementI} movement - Information of the movement
 * @param {()=>void?} onCancelated - Callback executed when the cancelation was made
 */
export default function useEgressOverview(movement, onCancelated = () => {}) {
  const [state, setState] = useState(INITIAL_STATE);

  const handleModal = (isOpen) =>
    setState((current) => ({
      ...current,
      isOpen,
      isLoading: isOpen === true ? current.isLoading : true,
    }));

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      const associations = await getEgressOverview(movement.id);

      /**
       * @type {import("../../../../server/models/banks/egreses/types").EgressOverview[]}
       */
      const filtered = associations.reduce((list, item) => {
        if (item.type === "gasto") {
          if (AUTOCALCULATED_CONCEPTS.includes(item.concepts[0].id)) {
            return list;
          }
          return [...list, item];
        }

        return [...list, item];
      }, []);

      const isAtLeastOneCxp = checkIsAtLeastOneCxp();

      const aditionalConcepts = associations.filter(item=>item.concepts[0].id === 31 || item.concepts[0].id === 30);


      setState((current) => ({
        ...current,
        associations: filtered,
        canDisplayCheckbox: isAtLeastOneCxp,
        isValidCancellation: !isAtLeastOneCxp,
        isLoading: false,
        aditionalConcepts
      }));

      ////////////////////////////////////////////////////////////////////////////////////////////////////////

      function checkIsAtLeastOneCxp() {
        const cxpFounded = filtered.reduce(
          (counter, item) => (item.type === "cxp" ? (counter += 1) : counter),
          0
        );

        if (cxpFounded > 0) return true;

        return false;
      }
    })();
  }, [state.isOpen]);

  useEffect(() => {
    const numberOfItemsOnList = Object.keys(state.selecteds).length;


    const isValidCancellation = numberOfItemsOnList > 0 ? true : false;

    setState((current) => ({
      ...current,
      isValidCancellation,
    }));
  }, [state.selecteds]);

  const setIsCancelating = (isCancelling) =>
    setState((current) => ({
      ...current,
      isCancelling,
    }));

  const attemptCancel = async () => {

    const associations = Object.entries(state.selecteds).map(([key,value])=>value);

    const odc = associations.filter(cxp=>cxp.subtype==="odc" && cxp.type === "cxp").map(cxp=>cxp.id);
    const expenses = associations.filter(cxp=>cxp.subtype === "gasto" && cxp.type === "cxp").map(cxp=>cxp.id);

    /**
     * @type {import("../../../../server/helpers/validations/cxp/cancelCxp/types").DtoBodyCancelCxp}
     */
    const dtoCancelInvoiceAssociations = {
      cxp:odc,
      expenses
    }

    setIsCancelating(true);
    const queryToUse = state.canDisplayCheckbox ? cancelCxpAssociation : cancelEgressExpense;

    const dtoToUse = state.canDisplayCheckbox ? dtoCancelInvoiceAssociations : movement.id;

    const wasCancelated = await queryToUse(dtoToUse);

    if (wasCancelated) {
      onCancelated();
      handleModal(false);
    }

    setIsCancelating(false);
  };

  /**
   * Check/Uncheck an item on the grid for associations
   * @param {import("../../../../server/models/banks/egreses/types").EgressOverview} cxp - Information of the CxP selected
   */
  function handleCheckbox(cxp) {
    const isAssociationOnList = evaluateIsOnList();

    if (!isAssociationOnList) {
      appendOnList();
      return;
    }

    deleteFromList();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function evaluateIsOnList() {
      const idsList = Object.keys(state.selecteds);

      const isOnList = idsList.includes(`${cxp.id}`);

      return isOnList;
    }

    function appendOnList() {
      setState((current) => ({
        ...state,
        selecteds: {
          ...current.selecteds,
          [`${cxp.id}`]: cxp,
        },
      }));
    }

    function deleteFromList() {
      const unrefList = { ...state.selecteds };

      delete unrefList[`${cxp.id}`];

      setState((current) => ({
        ...current,
        selecteds: unrefList,
      }));
    }
  }

  return {
    state,
    attemptCancel,
    handleModal,
    handleCheckbox,
    gastos:state.associations.filter(item=>item.subtype==="gasto"),
    odc:state.associations.filter(item=>item.subtype==="odc")
  };
}
