import React , { useEffect , useState , useContext, useRef } from "react";
import {
  BankHeader,
  BankName,
  BankAccount,
  BankCurrency,
} from "components/general/BankHeader";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import BankContext from "context/Bank/BankContext";
import scss from "./styles.module.scss";
import useCreateEgress from "customHooks/useCreateEgress";
import { Tabs } from "components/general/Tabs/Tabs";
import ConceptsEgresses from "structure/ConceptsEgresses";
import Cxp from "structure/Cxp";
import DateMovement from "components/individual/DateMovement";
import { createContext } from "react";
import ProviderCombo from "./ProviderCombo";
import AttemptQuery from "./AttemptQuery";
import AssociatedLabel from "./AssociatedLabel";
import NotAssociatedLabel from "./NotAssociatedLabel";
import InputAmount from "./InputAmount";
import useQueryParams from "customHooks/useQueryParams";
import Beneficiary from "../Beneficiary";
import ToggleEgress from "./ToggleEgress";
import PayMethodEgress from "./PayMethodEgress";
import { mnCurrency } from "helpers/money";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("react").Context<import("./types").CreateEgressContext|undefined>}
 */
export const ContextCreateEgress = createContext(undefined);

export default function AddEgress() {
  const context = useContext(BankContext);

  usePermissions('e68ea8ae-a287-42e7-ab9f-5aabab774bb5');

  const query = useQueryParams();

  const idMovement =  useRef(query.movimiento || null);
  const idBankAccount = context.account?.id || 0;

  const hook = useCreateEgress(idMovement.current, 2, idBankAccount);

  const [breadcrum, setBreadcrum] = useState([]);

  useEffect(() => {
    if (query.modulo === undefined) {
      setBreadcrum([
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/v2/bancos",
          text: "Bancos",
        },
        {
          route: `/v2/bancos/egresos?cuenta=${query.cuenta}`,
          text: "Egresos",
        },
        {
          route: `/`,
          text: "Agregar",
        },
      ]);
      return;
    }

    setBreadcrum([
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/v2/bancos",
        text: "Bancos",
      },
      {
        route: `/v2/bancos/anticipos/proveedores`,
        text: "Anticipo",
      },
      {
        route: "/v2/bancos",
        text: `Proveedor`,
      },
    ]);
  }, []);

  if (context.isLoadingAccount) return <></>;

  return (
    <ContextCreateEgress.Provider value={{ ...hook, idMovement:idMovement.current }}>
      <div className={scss.containerAddEgressModule}>
        <div className={scss.gridAddEgress}>
          <div>
            <BreadCrumV2 path={breadcrum} />

            <div className={scss.containerOptions}>
              <BankHeader>
                {() => (
                  <div className="mb-3">
                    <BankName />
                    <BankAccount />
                    <BankCurrency />
                  </div>
                )}
              </BankHeader>

              <div className={scss.options}>
                <ProviderCombo />

                <Beneficiary />
                <DateMovement
                  idMovement={idMovement.current}
                  onChange={hook.updateDate}
                  label="Fecha"
                  idBankAccount={context.account.id}
                />

                <PayMethodEgress />

                <ToggleEgress />

                <AttemptQuery />

                <p className="font-weight-bold">💲 Tolerancia de {mnCurrency(hook.tolerance)}</p>
              </div>
            </div>
          </div>

          <div className={scss.body}>
            {!hook.canDisplayGridCxp ? (
              <div className="d-flex justify-content-center align-items-center">
                <h5>Completa los datos</h5>
              </div>
            ) : (
              <Tabs
                idContainerContent="egreses"
                idContainerTabs="tab"
                tabButtons={{
                  cxp: {
                    text: typeof(hook.provider)==="number" ? "CxP" : hook.includeExpenses ? 'Concepto' : 'CxP',
                    selected: true,
                    component: (
                      <Cxp
                        amount={hook.amount}
                        idProvider={hook.provider}
                        includeExpenses={hook.includeExpenses}
                        onChange={(grid) => hook.updateGrid(grid)}
                        payMethod={hook.payMethod}
                      />
                    ),
                  },
                  concepts: {
                    text: `${hook.concepts.isValid ? "" : "🔴 "}Conceptos`,
                    selected: false,
                    component: (
                      <ConceptsEgresses
                        decimals={4}
                        deductible={hook.deductible}
                        noDeductible={hook.noDeductible}
                        displayConcepts={
                          hook.amount > 0 && hook.payMethod !== null
                        }
                        onChange={(grid) => hook.updateConceptGrid(grid)}
                        idProvider={hook.provider}
                      />
                    ),
                  },
                }}
              />
            )}

            <div className={scss.amountsAssociation}>
              <InputAmount />
              <AssociatedLabel />
              <NotAssociatedLabel />
            </div>
          </div>
        </div>
      </div>
    </ContextCreateEgress.Provider>
  );
}
