import React, { useContext } from "react";
import {
  HeaderItemContainer,
  HeaderLong,
  HeaderShort,
  HeaderShortKey,
} from "./Containers";
import WinQuoteContext from "../ContextWinQuote";

export const ItemHeaderSection = () => {
  const { interfaceControl } = useContext(WinQuoteContext);
  return (
    <HeaderItemContainer>
      <HeaderShortKey>Clave</HeaderShortKey>
      <HeaderLong>Descripcion</HeaderLong>
      <HeaderShort>Cantidad</HeaderShort>
      <HeaderShort>{interfaceControl.itemHeaderLabel}</HeaderShort>
      <HeaderShort>Importe</HeaderShort>
    </HeaderItemContainer>
  );
};
