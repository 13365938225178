import React, { useContext } from "react";
import { RangeDate, From, To } from "components/general/RangeDate";
import { IncomingContext } from "..";
import YearSelect from "components/general/Forms/Selects/Year";
import MonthSelect from "components/general/Forms/Selects/Month";

export default function RangeFilter() {
  const hook = useContext(IncomingContext);

  if (!hook.isForAccounting)
    return (
      <RangeDate>
        <From
          selected={hook.startDate}
          onChange={(date) => hook.updateRangeDate([date, hook.endDate])}
        />
        <To
          maxDate={new Date()}
          selected={hook.endDate}
          onChange={(date) => hook.updateRangeDate([hook.startDate, date])}
        />
      </RangeDate>
    );

  return (
    <>
      <YearSelect
        from={new Date().getUTCFullYear() - 10}
        onChange={hook.setYear}
        value={hook.startDate.getUTCFullYear()}
      />

      <MonthSelect
        value={hook.startDate.getUTCMonth() + 1}
        onChange={hook.setMonth}
      />
    </>
  );
}
