import React from "react";
import styles from "./index.module.scss";
import Menu from "structure/Menu";
import buttonMenu from "components/individual/HeaderIcon/index.module.scss";
import PushNotifications from "./PushNotifications";
import ToDoQuickAccess from "./ToDo";
import Profile from "./Profile";
import CreateTicket from "pages/Tickets/components/CreateTicket";
import Icon from "components/individual/HeaderIcon";

/**
 * Render the menu of the system when user logs in
 * @returns {JSX.Element}
 */
export default function ProfileUser() {
  return (
    <div className={styles.menu}>
      <Profile />

      <div className={styles.quickOptions}>
        <CreateTicket>
          <button className={buttonMenu.btn}>
            <Icon icon="support" />
          </button>
        </CreateTicket>

        <PushNotifications />
        <ToDoQuickAccess />
        <Menu />
      </div>
    </div>
  );
}
