import React, { useContext } from "react";
import StatusDocuments from "components/general/Forms/Selects/StatusDocuments";
import { ContextContract } from "structure/Contract/List";

export default function StatusSelect() {
  const { updateStatus, status } = useContext(ContextContract);

  return (
    <StatusDocuments
      idTypeDocument={6}
      onChange={updateStatus}
      value={status}
    />
  );
}
