import React, { useRef } from "react";
import { BarLoader } from "react-spinners";
import style from "./styles.module.scss";

/**
 *
 * @param {string} id ID of the spinner to show
 */
export function showSpinner(id) {
  const dom = document.getElementById(id);

  if (dom) dom.classList.remove("d-none");
}

/**
 *
 * @param {string} id ID Attribute of the spinner to hide
 */
export function hideSpinner(id) {
  const dom = document.getElementById(id);

  if (dom) dom.classList.add("d-none");
}

/**
 * Spinner that can be display when do a fetch to an API
 * @param {object} props - Props of the component
 * @param {string|JSX.Element} props.text - Text that will be display the spinner appears
 * @param {string|null} [props.idSpinner=null] - HTML id of the spinner
 * @param {boolean} [props.hidden=true] - True if the elemento must be hidden
 * @returns {JSX.Element} JSX element
 */
export function Spinner({ text, idSpinner = null, hidden = true }) {
  const id = useRef(window.crypto.randomUUID());

  return (
    <>
      <div
        id={idSpinner === null ? id.current : idSpinner}
        className={style.containerSpinner}
        style={
          hidden === true
            ? { display: "none" }
            : {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        {/* <div className="spinner" style={{ margin: "0 0 1rem 0" }}></div> */}
        <p>{text}</p>
        <BarLoader color="var(--primaryColor)"/>
      </div>
    </>
  );
}

/**
 *
 * @param {string} id - id attribute of the html tag to apply
 * @param {string} action - 'show' or 'hide'
 */
export function display(id, action = "") {
  const element = document.getElementById(id);

  if (!element) return;

  if (action === "show" && element !== null) {
    element.classList.remove("d-none");
  }

  if (action === "hide" && element !== null) {
    element.classList.add("d-none");
  }
}

/**
 * Show the spinner when make a request to API
 * @param {string} show - id of the html tag to show
 * @param {string} hide -id of the html tag to hide
 */
export function triggerLoading(show, hide) {
  display(hide, "hide");
  display(show, "show");
}

/**
 * Hide the spinner when get a result of the API
 * @param {string} show - id of the html tag to show
 * @param {string} hide -id of the html tag to hide
 */
export function quitLoading(show, hide) {
  display(show, "show");
  display(hide, "hide");
}
