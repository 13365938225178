import React from "react";
import { Label } from "structure/Document";
import { getDateFromUtc } from "helpers/dates";

export default function Time({ time = null, label = null }) {
  if (time === null && label === null) return <></>;

  if (time === null && label !== null) return <span>{label}</span>;

  return (
    <time>
      {new Intl.DateTimeFormat("es-MX", {
        dateStyle: "medium",
        timeStyle: "short",
        hour12: true,
      }).format(getDateFromUtc(time))}
    </time>
  );
}
