import React from "react";
import RedirectButton from "../Redirect";
import TextIcon from "structure/Menu/TextIcon";
import { closeSession } from "helpers/apis";
import scss from "../index.module.scss";
import { Version } from "./Version";
import { ChangeMenuView } from "../ChangeMenuView";
import "./styles.css";
import DistributionList from "structure/DistributionList";

export default function GeneralMenu() {
  return (
    <>
      <RedirectButton
        text={<TextIcon icon="home" text="Inicio" />}
        to={"/inicio"}
      />

      <ChangeMenuView
        icon="administration"
        text="Administrador del sistema"
        menu="adminSistemas"
        permission="af1c056d-81ad-4c11-844c-707b5d7fd2e6"
      />

      <ChangeMenuView
        icon="banks"
        text="Bancos"
        menu="banks"
        permission="8e1659e5-740b-4cdb-a02d-373433d3e122"
      />

      <ChangeMenuView
        icon="sells"
        text="Compras"
        menu="purchase"
        permission="1f0a24b8-7e43-43d5-b620-b4b365e09570"
      />

      <ChangeMenuView
        icon="chartBar"
        text="Contabilidad"
        menu="conciliacion"
        permission="f2087873-0fd9-4d29-a963-a138a63da89d"
      />

      <RedirectButton
        permission="cd89a7a9-066d-4961-afc6-0d6d190ff97b"
        text={<TextIcon icon="directory" text="Directorio" />}
        to={"/directorio"}
      />
      <ChangeMenuView
        icon="specialProcess"
        text="Procesos especiales"
        menu="procesos_especiales"
        permission="06e83fd3-14d3-4ab3-89b5-5685d7aaf5f1"
      />
      {/* 
      <DistributionList>
        <p className={scss.item}>
          <TextIcon icon="noteCreditEmitted" text="Lista Distribución" />
        </p>
      </DistributionList> */}

      <RedirectButton
        text={<TextIcon icon="gear" text="Proyectos" />}
        to={"/proyectos"}
      />

      <ChangeMenuView
        permission="8d6a195f-4024-46c8-84fd-176c2946001b"
        icon="sells"
        text="Ventas"
        menu="ventas"
      />

      <p className={scss.item} onClick={closeSession}>
        <TextIcon icon="signtOut" text="Cerrar sesión" />
      </p>

      <Version />
    </>
  );
}
