import React from "react";
import useCatalogueDirectory from "./useCatalogueDirectory";
import Dialog from "structure/Dialog";
import Select from "components/individual/SelectV4";
import { Label } from "structure/Document";
import TypeCustomerDirectory from "components/individual/TypeCustomerDirectory";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import style from "./styles.module.scss";
import { Pagination } from "components/individual/Pagination";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

/**
 * Render a option
 * @param {import('./types').PropsCatalogueDirectory} props - Props
 * @returns {JSX.Element}
 */
export default function CatalogueDirectory({ children = <></>, type }) {
  const hook = useCatalogueDirectory(type);

  return (
    <>
      <div onClick={() => hook.dialog.open()}>{children}</div>
      <Dialog
        width={30}
        closeCallback={hook.dialog.close}
        forwardRef={hook.dialog.refDialog}
        title={`Catálogo ${type === "clients" ? "clientes" : "proveedores"}`}
        footer={
          <div className={style.containerExcel}>
            {hook.isDownloading ? (
              <Spinner
                hidden={false}
                text={<LoadingText>Descargando</LoadingText>}
              />
            ) : (
              <ButtonV2 onClick={hook.attemptDownloadExcel} type="button">
                <span>Excel</span>
                <Icon icon="excel" />
              </ButtonV2>
            )}
          </div>
        }
      >
        <div className={style.filter}>
          <div>
            <Label>Estatus</Label>
            <Select
              onChange={(option) => hook.setStatus(option.value)}
              labelToDisplay="label"
              options={hook.statusList}
            />
          </div>

          <TypeCustomerDirectory
            isFilter={true}
            type={type === "clients" ? "cliente" : "proveedor"}
            onChange={hook.updateTypeSector}
          />
        </div>
      </Dialog>
    </>
  );
}
