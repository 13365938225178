import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { AllExecutives } from "helpers/apis";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Label } from "structure/Document";

/**
 * @type {import('./types').StateExecutive}
 */
const STATE = {
  executives: [],
  isLoading: true,
  addAllOption: false,
  selected: undefined,
  includeDisabledUsers: false,
  addSelectOption: false,
  defaultSelected: {
    keyToCompare: "value",
    value: null,
  },
};

/**
 * Render executive select input
 * @param {import("./types").PropsExecutive} props - Props
 * @returns {JSX.Element}
 */
export default function Executives({
  addAllOption = false,
  includeDisabledUsers = false,
  addSelectOption = false,
  onChange = () => {},
  defaultValue = null,
}) {
  const [state, setState] = useState({
    ...STATE,
    addAllOption,
    includeDisabledUsers,
    addSelectOption,
  });

  useEffect(() => {
    (async function () {
      /**
       * @type {import("helpers/apisTypes").Users}
       */
      const AllOption = {
        firstName: "",
        initials: "",
        lastName1: "",
        lastName2: "",
        middleName: "",
        status: true,
        TEXT: "Todos",
        value: null,
      };

      /**
       * @type {import("helpers/apisTypes").Users}
       */
      const SelectOptions = {
        firstName: "",
        initials: "",
        lastName1: "",
        lastName2: "",
        middleName: "",
        status: true,
        TEXT: "--SELECCIONA--",
        value: null,
        disabled: true,
      };

      const apiExecutives = await AllExecutives();

      const toUse = state.includeDisabledUsers
        ? apiExecutives
        : apiExecutives.filter((item) => item.status === true);

      const toUse2 = state.addAllOption ? [AllOption, ...toUse] : toUse;

      const toUse3 = state.addSelectOption
        ? [SelectOptions, ...toUse2]
        : toUse2;

      /**
       * @type {import("components/individual/SelectV4/types").DefaultSelectedValue}
       */
      const valueToUse =
        typeof defaultValue === "number"
          ? { keyToCompare: "value", value: defaultValue }
          : state.defaultSelected;

      setState((current) => ({
        ...current,
        isLoading: false,
        executives: toUse3,
        defaultSelected: valueToUse,
      }));
    })();
  }, []);

  if (state.isLoading)
    return (
      <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
    );

  return (
    <div>
      <Label>Ejecutivo</Label>
      <Select
        labelToDisplay="TEXT"
        options={async () => state.executives}
        defaultSelected={state.defaultSelected}
        onChange={onChange}
      />
    </div>
  );
}
