import styled from "styled-components";

export const RowHeader = styled.div`
  display: grid;
  grid-template-columns: 30% 5% 16.25% 16.25% 16.25% 16.25%;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }
`;

export const Row = styled.div`
  display: grid;
  border-bottom: 1px solid var(--secondary);
  grid-template-columns: 30% 5% 16.25% 16.25% 16.25% 16.25%;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 0;
  }
  padding: 15px 0;
`;

export const Amounts = styled.div`
  display: flex;
  justify-content:end;
  p {
    width: 100%
    text-align: right;
  }
`;

export const Residue = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-evenly;
  grid-template-areas:
    "title title"
    "associated remain";
`;

export const Associated = styled.p`
  grid-area: associated;
`;

export const Remain = styled.p`
  grid-area: remain;
`;

export const ResidueTitle = styled.p`
  grid-area: title;
`;

export const EgressHeader = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-evenly;
  grid-template-areas:
    "import import"
    "invoice egress";
`;

export const Import = styled.p`
  grid-area: import;
`;

export const Invoice = styled.p`
  grid-area: invoice;
`;

export const Egress = styled.p`
  grid-area: egress;
`;
