import React, { useEffect, useRef, useState } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { AssociateFiles } from "structure/AssociateFiles";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";

/**
 * @param {import("structure/AssociateFiles/types").PropsAssociateFiles} props - Props
 */
export default function ModalAssociatedFiles(props) {
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  const open = () => {
    setIsOpen(true);
    dialog.open();
  };


  const close = () => {
    setIsOpen(false);
    dialog.close();
  };

  useEffect(()=>{
    if(isOpen) dialog.open()
  },[isOpen])

  const customId = useRef(window.crypto.randomUUID());

  const id = Object.keys(props).includes("id") ? props.id : customId.current;

  return (
    <>
      {Object.keys(props).includes("children") ? (
        <div id={id} onClick={open}>
          {props.children}
        </div>
      ) : (
        <ButtonV2 id={id} onClick={open}>
          <span>Archivos asociados</span>
        </ButtonV2>
      )}

      {isOpen ? (
        <Dialog
          closeCallback={close}
          title="Archivos asociados"
          forwardRef={dialog.refDialog}
        >
          <AssociateFiles
            idRegister={props.idRegister}
            idTypeEntity={props.idTypeEntity}
            pathUpload={props.pathUpload}
          />
        </Dialog>
      ) : null}
    </>
  );
}
