import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { io, Socket } from "socket.io-client";
import image from "./notificationIcon.png";
import React from 'react';

/**
 * @type {import("react").Context<import("./types").ContextValue>}
 */
export const SocketContext = createContext({
  socketState: undefined,
  notifyNewTcAdded: () => {},
});

/**
 * Context in order to use the socket functionality thourght the components inside this provider
 * @param {import("./types").SocketProviderProps} props - Props
 * @returns {JSX.Element}
 */
export function SocketProvider({ children }) {
  const [socketState, setSocketState] = useState(
    io(`${process.env.REACT_APP_URL_BACKEND}`)
  );

  useEffect(() => {
    socketState.on("newBug", (data) => {
      new Notification(`Bug #${data.folio} - ${data.title}`, {
        icon: image,
        body: data.description,
      });
    });
  }, []);

  /**
   * Notify of a new tc added into system for the advertisements
   * @param {string} date - Date of the TC when was added
   */
  const notifyNewTcAdded = (date) => {
    socketState.emit("newTc", date);
  };

  return (
    <SocketContext.Provider value={{ socketState, notifyNewTcAdded }}>
      {children}
    </SocketContext.Provider>
  );
}
