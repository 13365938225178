import React, { Fragment } from "react";
import Table from "components/general/Table";
import Details from "components/general/Details";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { copyOnClipboard, openJsonInNewTab, toJson, toJsonString } from "helpers/dom";

/**
 * Render the table for the logs
 * @param {import("./types").PropsTableLogs} props
 * @returns {JSX.Element}
 */
export default function TableLogs(props) {
  return <Table {...props} />;
}

export function DetailsLogs({ data }) {
  return (
    <div className={ui.containerOverviewLog}>
      <Details
        summary={
          <div className={ui.logSummary}>
            <ButtonV2
              onClick={(e) => openJsonInNewTab(toJson(data.request))}
            >
              <span>📂</span>
            </ButtonV2>
            <ButtonV2
              onClick={() =>
                copyOnClipboard(
                  data.request,
                  true,
                  "Log copiado al portapapeles"
                )
              }
            >
              <span>📄</span>
            </ButtonV2>
            Peticion enviada
          </div>
        }
        open
      >
        <pre className={ui.logOverview}>{toJsonString(data.request)}</pre>
      </Details>
      <Details
        open
        summary={
          <div className={ui.logSummary}>
            <ButtonV2
              onClick={(e) => openJsonInNewTab(toJson(data.response))}
            >
              <span>📂</span>
            </ButtonV2>
            <ButtonV2
              onClick={() =>
                copyOnClipboard(
                  data.response,
                  true,
                  "Log copiado al portapapeles"
                )
              }
            >
              <span>📄</span>
            </ButtonV2>
            Respuesta recibida
          </div>
        }
      >
        <pre className={ui.logOverview}>{toJsonString(data.response)}</pre>
      </Details>
    </div>
  );
}
