import React, { useRef } from "react";
import style from "./styles.module.scss";

/**
 * Render the concept
 * @param {import('./types').PropsConcept} props - Props
 * @returns {JSX.Element}
 */
export default function Concept({
  concepts = [],
  type,
  emited = [],
  received = [],
  beneficiary = null,
}) {
  const toUse = type === "egreso" ? received : emited;

  const key = useRef(window.crypto.randomUUID());

  return (
    <div className={style.containerConcepts}>
      <p>{typeof beneficiary === "string" ? beneficiary : "ND"}</p>

      <span className="mx-1">/</span>

      <p className={style.concepts}>
        {toUse.length <= 0
          ? concepts.map((concept, i) => (
            <span key={`socialReason-${key.current}-${i}`}>{concept}</span>
          ))
          : toUse.map((concept, i) => (
              <span key={`concept-${key.current}-${i}`}>{concept}</span>
            ))}
      </p>

      {/* <p className={style.concepts}>
        {concepts.length <= 0
          ? "NA"
          : concepts.map((concept, i) => (
              <span key={`socialReason-${key.current}-${i}`}>{concept}</span>
            ))}
      </p> */}

      
    </div>
  );
}
