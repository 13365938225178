import {
  AddCommentContainer,
  Comment,
  CommentItem,
} from "components/Sections/Documents/Comments/Containers";
import { AddPlus, Pen, Trash } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import { InputText } from "components/individual/inputs/Inputs";

import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { CommentButtons } from "./CommentButtons";

export const ContractCommentSection = () => {
  const {
    documents,
    handleOnCurrentContractCommentChange,
    handleOnAddContractComments,
    interfaceControl,
    defaultContractCommentValue,
    handleOnDeleteContractComment,
    validateCommentEditing,
    handleOnEditComment,
  } = useContext(WinQuoteContext);
  return (
    <>
      {interfaceControl.needContract ? (
        <AddCommentContainer>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <InputText
              placeholderTxt={
                documents.contract.isCommentEditing
                  ? "Deshabilitado"
                  : "Escribe aqui"
              }
              labelTxt="Comentario"
              // style={{ width: "95%;" }}
              aditionalClasses="w-100"
              onChange={handleOnCurrentContractCommentChange}
              defaultValue={defaultContractCommentValue()}
              onEnter={() => {
                handleOnAddContractComments();
              }}

              // forwardRef={inputRef}
            />
            {/* <Icon
              // onClick={addComment={}}
              // className="aling-self-end"
              onKeyUp={(value) => {
                console.log(value);
              }}
            >
              {AddPlus}
            </Icon> */}
          </div>
          {!!documents.contract.comments.length ? (
            documents.contract.comments.map((comment, index) => (
              <CommentItem>
                <Comment>
                  {validateCommentEditing(
                    comment,
                    documents.contract.currentComment
                  ) ? (
                    <InputText
                      placeholderTxt={
                        documents.contract.isCommentEditing
                          ? "Deshabilitado"
                          : "Escribe aqui"
                      }
                      labelTxt="Comentario"
                      aditionalClasses="commnetStyle"
                      onChange={handleOnCurrentContractCommentChange}
                      defaultValue={comment.comment}
                      onEnter={() => {
                        handleOnEditComment(
                          index,
                          6,
                          documents.contract.currentComment
                        );
                      }}
                    />
                  ) : (
                    <p>{comment.comment}</p>
                  )}
                </Comment>
                <CommentButtons
                  comment={comment}
                  idDocumentType={6}
                  index={index}
                  currentComment={documents.contract.currentComment}
                />
              </CommentItem>
            ))
          ) : (
            <h2>No hay comentarios para mostrar</h2>
          )}
        </AddCommentContainer>
      ) : (
        <div className="d-flex justify-content-center ">
          <h2 className="text-center m-4">No tiene contrato</h2>
        </div>
      )}
    </>
  );
};
