import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import useQueryParams from "customHooks/useQueryParams";
import { getInfoContact } from "helpers/Apis/contactInfoApi";
import { GetCustomer } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import ToDo from "structure/ToDo";
import ui from "structure/ToDo/CardsToDo/styles.module.scss";
import MoreInfoContact from "../V2/MoreInfoContact";
import MoreInfoDirectory from "structure/MoreInfoDirectory";

export default function ToDoContact() {
  const { contacto, cliente } = useQueryParams();

  /**
   * Information of contact
   * @type {[(InfoContact|undefined),()=>void]}
   */
  const [contact, setContact] = useState(undefined);

  /**
   * Information of the customer which belongs this contact
   * @type {[import("types/typedef/customer").CustomerI|undefined],()=>void}
   */
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    (async function () {
      const [apiContact, apiCustomer] = await Promise.all([
        getInfoContact(+contacto),
        GetCustomer(+cliente),
      ]);
      setContact(apiContact);
      setCustomer(apiCustomer);
    })();
  }, []);

  return (
    <div className={ui.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: `/directorio/contacto/${cliente}`,
            text: "Contactos",
          },
          {
            route: "/",
            text: "To Do",
          },
        ]}
      />

      <div>
        {contact === undefined || customer === undefined ? (
          <Spinner hidden={false} text="Cargando contacto..." />
        ) : (
          <div className={ui.margin}>
            <div
              style={{
                display: "flex",
                gap: "0 2rem",
              }}
            >
              <span>
                <b>{customer.type.description}</b>

                <MoreInfoDirectory idCustomer={customer.id}>
                  <span className="hyperlink">{customer.socialReason}</span>
                </MoreInfoDirectory>
              </span>

              <span>
                <b>Contacto</b>
                <MoreInfoContact id={contact.id}>
                  <span className="hyperlink">
                    {contact.firstName} {contact.middleName || ""}{" "}
                    {contact.lastName1} {contact.lastName2 || ""}
                  </span>
                </MoreInfoContact>
              </span>
            </div>
          </div>
        )}
      </div>

      <ToDo id={+contacto} typeToDo="contactos" idEntity={+contacto} />
    </div>
  );
}
