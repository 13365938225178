import React, { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { mnCurrency } from "helpers/money";
import LoginContext from "context/Login/LoginContext";
import style from "../styles.module.scss";
import Icon from "components/individual/HeaderIcon";

export default function Footer() {
  const hook = useContext(ContextCreateInvoiceV2);

  const { userInfo } = useContext(LoginContext);

  if (hook.isCreating)
    return (
      <div className="d-flex justify-content-end">
        <Spinner
          hidden={false}
          idSpinner="creatingInvoice"
          text={<LoadingText>Timbrando</LoadingText>}
        />
      </div>
    );

  if (hook.limitCreationTime !== null)
    return (
      <div className={style.containerCreateInvoice}>
        <div></div>
        <ButtonV2
          disabled={hook.payForm === null || hook.payForm === undefined}
          onClick={hook.attemptCreateInvoice}
        >
          <span>Timbrar</span>
          <Icon icon="save" />
        </ButtonV2>
      </div>
    );

  return (
    <div className={style.containerCreateInvoice}>
      {userInfo[0].isAdmin ? (
        <div></div>
      ) : (
        <div className={style.warning}>
          {hook.partialities > hook.maxPartialitiesAllows ? (
            <p className="text-danger font-weight-bold">
              Máximo {hook.maxPartialitiesAllows} parcialidades.
              El timbrado entrara en proceso de revisión
            </p>
          ) : null}

          {hook.tc < hook.minTc && hook.currencyExchange ? (
            <p className="text-danger font-weight-bold">
              TC mínimo {mnCurrency(hook.minTc)} El timbrado
              entrara en proceso de revisión
            </p>
          ) : null}
        </div>
      )}


      <ButtonV2
        disabled={hook.payForm === null || hook.payForm === undefined || hook.receiverData === undefined || hook.cfdi === null || hook.cfdi === undefined}
        onClick={hook.attemptCreateInvoice}
      >
        <span>Timbrar</span>
        <Icon icon="save" />
      </ButtonV2>
    </div>
  );
}
