// import "./facturama.api";
import "./facturama.api.multiemisor";
import "../../types/facturama";
import { base64ToFile } from "../files";

const Facturama = window.Facturama;

/**
 * Create an invoice
 * 
 * @param {Invoice} invoiceData 
 * @returns {boolean} True if invoice was created
 * @example
 * const invoiceJsonData = {
  "Serie": "R",
  "Currency": "MXN",
  "ExpeditionPlace": "78116",
  "PaymentConditions": "CREDITO A SIETE DIAS",
  "Folio": "100",
  "CfdiType": "I",
  "PaymentForm": "03",
  "PaymentMethod": "PUE",
  "Receiver": {
    "Rfc": "RSS2202108U5",
    "Name": "RADIAL SOFTWARE SOLUTIONS",
    "CfdiUse": "P01"
  },
  "Items": [
    {
      "ProductCode": "10101504",
      "IdentificationNumber": "EDL",
      "Description": "Estudios de viabilidad",
      "Unit": "NO APLICA",
      "UnitCode": "MTS",
      "UnitPrice": 50.0,
      "Quantity": 2.0,
      "Subtotal": 100.0,
      "Taxes": [
        {
          "Total": 16.0,
          "Name": "IVA",
          "Base": 100.0,
          "Rate": 0.16,
          "IsRetention": false
        }
      ],
      "Total": 116.0
    },
    {
      "ProductCode": "10101504",
      "IdentificationNumber": "001",
      "Description": "SERVICIO DE COLOCACION",
      "Unit": "NO APLICA",
      "UnitCode": "E49",
      "UnitPrice": 100.0,
      "Quantity": 15.0,
      "Subtotal": 1500.0,
      "Discount": 0.0,
      "Taxes": [
        {
          "Total": 240.0,
          "Name": "IVA",
          "Base": 1500.0,
          "Rate": 0.16,
          "IsRetention": false
        }
      ],
      "Total": 1740.0
    }
  ]
}
 */
export async function createInvoice(invoiceData) {
  return new Promise((resolve, reject) => {
    Facturama.Cfdi.Create(
      invoiceData,
      (value) => {
        console.log(value);

        resolve({
          cfdiCreated: true,
          invoiceInfo: value,
        });
      },
      (error) => {
        const errorCodeMessage = {
          400: "Los parámetros que enviaste están incompletos o son inválidos. Consulta al administrador",
          401: "No esta permitido facturar o la cuenta esta caduca",
          403: "No tienes permitido hacer la accion",
          404: "Intenta realizar una accion valida con la factura",
          500: "Intenta facturar mas tarde. Reporta este error con el administrador",
          503: "Portal de facturacion en mantenimiento. Intenta mas tarde",
        };

        console.log(error.status);

        reject({
          cfdiCreated: false,
          status: error.statusCode,
          codeError: error.status,
          message: errorCodeMessage[error.status],
          errorDetails: error,
          step: 1,
        });
      }
    );
  });
}

export async function payPartiality(invoiceData) {}

/**
 * Get the PDF or XML of the invoice
 *
 * @param {string} idInvoice - Id of the invoice that returned provider to identiy the invoice
 * @param {("pdf"|"xml")} file - Extension file
 * @param {("issued")} type - Type of invoice to get
 * @returns {File} File instance of the download file
 */
export async function getInvoiceFile(idInvoice, file, type = "issued") {
  return new Promise((resolve, reject) => {
    Facturama.Cfdi.Download(file, type, idInvoice, (base64File) => {
      const mimeType = file === "pdf" ? "application/pdf" : "application/xml";

      resolve(base64ToFile(mimeType, base64File.Content));
    });
  });
}

/**
 * Check the document has mixed currencies on the items
 * @param {import("structure/Partidas/types").ItemI[]} items - Items got from the server
 * @returns {boolean} True if the document contains mixed currencies
 */
export const checkAreMixedCurrenciesOnItems = (items) => {
  let currencies = new Set();
  items.forEach((item) => currencies.add(item.currency.code));

  const parsedCurrencies = [...currencies];

  return parsedCurrencies.length >= 2 ? true : false;
};

export async function multiEmitter() {
  return new Promise((resolve, reject) => {
    Facturama.Cfdi.Create(
      {
        Serie: "",
        Currency: "MXN",
        ExpeditionPlace: "88965",
        PaymentConditions: "Pago en una sola exhibicion",
        Folio: "109",
        CfdiType: "I",
        PaymentForm: "99",
        PaymentMethod: "PPD",
        Issuer: {
          FiscalRegime: "612",
          Rfc: "XOJI740919U48",
          Name: "INGRID XODAR JIMENEZ",
        },
        Receiver: {
          Rfc: "XAXX010101000",
          Name: "CLIENTE CON FACTURAS CANCELABLES",
          CfdiUse: "P01",
        },
        Items: [
          {
            ProductCode: "10101504",
            IdentificationNumber: "AAA-70480",
            Description: "Office 365 A3 for faculty Academic",
            Unit: "NO APLICA",
            UnitCode: "D14",
            UnitPrice: 71.97,
            Quantity: 10,
            Subtotal: 719.7,
            Discount: 0,
            TaxObject: "01",
            Taxes: [
              {
                Total: 115.15,
                Name: "IVA",
                Base: 719.7,
                Rate: 0.16,
                IsRetention: false,
              },
            ],
            Total: 834.85,
          },
          {
            ProductCode: "10101504",
            IdentificationNumber: "CSP-002",
            Description: "Microsoft 365 Apps for Business",
            Unit: "NO APLICA",
            UnitCode: "D14",
            UnitPrice: 181.02,
            Quantity: 10,
            Subtotal: 1810.2,
            Discount: 0,
            TaxObject: "01",
            Taxes: [
              {
                Total: 289.63,
                Name: "IVA",
                Base: 1810.2,
                Rate: 0.16,
                IsRetention: false,
              },
            ],
            Total: 2099.83,
          },
          {
            ProductCode: "10101504",
            IdentificationNumber: "PA2021",
            Description: "App Service",
            Unit: "NO APLICA",
            UnitCode: "D14",
            UnitPrice: 545.25,
            Quantity: 10,
            Subtotal: 5452.5,
            Discount: 0,
            TaxObject: "01",
            Taxes: [
              {
                Total: 872.4,
                Name: "IVA",
                Base: 5452.5,
                Rate: 0.16,
                IsRetention: false,
              },
            ],
            Total: 6324.9,
          },
        ],
      },
      (data) => console.log(data),
      (e) => console.log(e)
    );
  });
}
