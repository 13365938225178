import React from "react";
import {
  UenV4,
  UenSelect,
  SatCode,
  SatUm,
  Iva,
  IvaExcent,
} from "components/general/Forms/Selects/UenV4";
import { useContext } from "react";
import { ContextCatalogueAdmin } from "../..";
import { Label } from "structure/Document";
import { Controller } from "react-hook-form";

/**
 * Render the select uen
 * @param {import("../types").UenProps} props - Props
 * @returns {JSX.Element}
 */
export default function UEN(props) {
  const hook = useContext(ContextCatalogueAdmin);

  const reactHookForm =
    props.operation === "add" ? hook.formAdd : hook.formEdit;

  return (
    <UenV4>
      <div>
        <Label>UEN</Label>
        <Controller
          render={({ onChange }) => <UenSelect onChange={onChange} />}
          name="uen"
          control={reactHookForm.control}
        />
        <p className="text-danger font-weight-bold">
          {reactHookForm.errors?.uen?.message}
        </p>
      </div>
      <div>
        <Label>Código SAT</Label>
        <Controller
          render={({ onChange }) => <SatCode onChange={onChange} />}
          name="satCode"
          control={reactHookForm.control}
        />
        <p className="text-danger font-weight-bold">
          {reactHookForm.errors?.satCode?.message}
        </p>
      </div>

      <div>
        <Label>UM SAT</Label>
        <Controller
          render={({ onChange }) => <SatUm onChange={onChange} />}
          name="satUM"
          control={reactHookForm.control}
        />
        <p className="text-danger font-weight-bold">
          {reactHookForm.errors?.satUM?.message}
        </p>
      </div>

      <div>
        <Label>IVA</Label>
        <Controller
          render={({ onChange }) => <Iva onChange={onChange} />}
          name="iva"
          control={reactHookForm.control}
        />
        <p className="text-danger font-weight-bold">
          {reactHookForm.errors?.satUM?.message}
        </p>
      </div>

      <div>
        <Controller
          render={({ onChange }) => <IvaExcent onChange={onChange} />}
          name="excent"
          control={reactHookForm.control}
        />
        <p className="text-danger font-weight-bold">
          {reactHookForm.errors?.excent?.message}
        </p>
      </div>
    </UenV4>
  );
}
