import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import { ClosePeriod } from "pages/Banks/Concilation/ClosePeriod";

export default function ScreenAccounting() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "conciliacion") return <></>;

  return (
    <>
      <NavigationItem
        icon="home"
        label="Inicio > Contabilidad"
        subMenu="general"
      />
      <NavigationItem
        icon="banks"
        label="Cuentas bancarias"
        link="/v2/bancos"
      />
      <NavigationItem
        icon="banks"
        label="Facturas emitidas"
        link="/contabilidad/facturas-emitidas"
      />
      <NavigationItem
        icon="banks"
        label="Facturas recibidas"
        link="/contabilidad/facturas-recibidas"
      />
      <NavigationItem
        icon="banks"
        label="Ingresos"
        link="/contabilidad/ingresos"
      />
      <NavigationItem
        icon="banks"
        label="Egresos"
        link="/contabilidad/egresos"
      />
      <NavigationItem
        icon="banks"
        label="Provición de OC"
        link="/contabilidad/oc"
      />

      <ClosePeriod>
        <NavigationItem icon="banks" label="Cierre de periódo" />
      </ClosePeriod>
    </>
  );
}
