import React from "react";
import { Row, Amounts } from "../styles";

/**
 * Row for the non deductible expenses/egresses
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useEgressOverview").NonDeductibleI} props.nonDeductible - Information of the egress non deductible
 * @param {"USD"|"MXN"} props.currencyMovement - Currency of the movement
 * @returns {JSX.Element} Component
 */
export default function RowNonDeductible({
  nonDeductible,
  currencyMovement = "??",
}) {
  return (
    <Row>
      <p className="text-center">-</p>
      <p className="text-center">-</p>
      <p>{nonDeductible.expense.description}</p>
      {/* <p className="text-center">{nonDeductible.expense.currency}</p> */}
      {/* <p className="text-right">{nonDeductible.applied.text}</p> */}
      <p className="text-center">-</p>
      <Amounts>
        <p>
          {nonDeductible.applied.text} {nonDeductible.expense.currency}
        </p>
        {/* <p>$0.0</p> */}
      </Amounts>
      <Amounts>
        {/* <p>{nonDeductible.applied.text}</p> */}
        <p>
          {nonDeductible.paid.text} {currencyMovement}
        </p>
      </Amounts>
    </Row>
  );
}
