import {
    SET_RANGE_DATE_FILTER,
    SET_TYPE_DOCUMENT,
    SAVE_STATUS_DOCUMENT,
    SET_RENDER_SCREEN,
    SET_QUOTE
} from '../../types/index';

export default function(state,action){
    switch(action.type){
        case SET_RANGE_DATE_FILTER:
            return{
                ...state,
                rangeBegin:action.payload.rangeBegin,
                rangeEnd:action.payload.rangeEnd
            }

        case SET_TYPE_DOCUMENT:
            return{
                ...state,
                typeDocument:action.payload
            }

        case SAVE_STATUS_DOCUMENT:
            return{
                ...state,
                statusDocuments:{
                    ...state.statusDocuments,
                    [`${action.payload[0].document}`]:action.payload
                }
            }

        case SET_RENDER_SCREEN:
            return{
                ...state,
                renderScreen:action.payload
            }

        case SET_QUOTE:
            return{
                ...state,
                documentInfo:{
                    ...state.documentInfo,
                    quote:action.payload
                }
            }
    }
}