import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import React, { useContext, useRef } from "react";
import { ContextEmitedCreditNotes } from "../..";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import useCancelCreditNote from "structure/CancelCreditNoteEmited/useCancelCreditNote";

export default function OptionsCreditNoteEmited(creditNote) {
  const hook = useContext(ContextEmitedCreditNotes);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const dom = useRef();

  const cancel = useCancelCreditNote({
    idFacturamaCreditNote: null,
    uuidCreditNote: creditNote.uuid,
    onCancelated: hook.refetchTable,
    status: creditNote.status.id,
  });

  return (
    <div>
      <ElipsisMobile onShow={() => hook.setCreditNote(creditNote)}>
        <CustomMenuItem onClick={() => dom.current.click()}>
          Más información
        </CustomMenuItem>
        <CustomMenuItem onClick={hook.redirectToFiles}>
          Archivos asociados
        </CustomMenuItem>
        <CustomMenuItem onClick={hook.handleDownloadPdf}>PDF</CustomMenuItem>
        <CustomMenuItem onClick={hook.handleDownloadXml}>XML</CustomMenuItem>
        {cancel.isCancellable ? (
          <CustomMenuItem
            disabled={cancel.isCancelling}
            onClick={cancel.attemptCancelComplement}
          >
            {cancel.isCancelling ? "Cancelando..." : "Cancelar"}
          </CustomMenuItem>
        ) : null}
      </ElipsisMobile>

      <ModalInvoiceReception forwardRef={dom} uuid={creditNote.invoice.uuid} />
    </div>
  );
}
