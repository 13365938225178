import React from "react";
import useAddTc from "./useAddTc";
import { Label } from "structure/Document";
import { ErrorMessage } from "components/individual/inputs/Inputs";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import Money from "components/general/Forms/Money";
import style from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import usePermissions from "customHooks/usePermissions";
import { useRef } from "react";

/**
 * Render a modal to add a tc into the system
 * @param {import("./types").PropsAddTc} props - Props
 * @returns {JSX.Element}
 */
export default function AddTc({ children = null }) {
  const hook = useAddTc();

  const { checkPermission } = usePermissions();

  const idForm = useRef(window.crypto.randomUUID());

  if (!checkPermission("deed93af-287e-4da3-8360-cfe9d9eba39b"))
    return <></>;

  return (
    <>
      <div onClick={hook.dialog.open}>{children}</div>

      <Dialog
        title="Agregar TC"
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
        footer={
          <div className={style.footerContainer}>
            {hook.isLoading ? (
              <Spinner hidden={false} text="Agregando" />
            ) : (
              <ButtonV2
                form={idForm.current}
                onClick={hook.attemptCreateTc}
                disabled={!hook.isValidForm}
                type="submit"
              >
                Agregar
              </ButtonV2>
            )}
          </div>
        }
      >
        <div className="d-flex justify-content-center">
          <form
            id={idForm.current}
            className={style.form}
            noValidate
            onSubmit={hook.form.handleSubmit(
              hook.attemptCreateTc,
              hook.displayError
            )}
          >
            <div>
              <Label>DOF</Label>
              <Money
                onChange={(amount) => hook.updateAmount("dof", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.dof < 1 ? <ErrorMessage message="Mínimo $1.00" /> : null}

              <Label>Pagos</Label>
              <Money
                onChange={(amount) => hook.updateAmount("pays", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.pays < 1 ? <ErrorMessage message="Mínimo $1.00" /> : null}

              <Label>Empresa</Label>
              <Money
                onChange={(amount) => hook.updateAmount("enterprise", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.enterprise < 1 ? (
                <ErrorMessage message="Mínimo $1.00" />
              ) : null}

              <Label>Venta (Banamex)</Label>
              <Money
                onChange={(amount) => hook.updateAmount("purchase", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.purchase < 1 ? (
                <ErrorMessage message="Mínimo $1.00" />
              ) : null}

              <Label>Compra</Label>
              <Money
                onChange={(amount) => hook.updateAmount("sells", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.sells < 1 ? <ErrorMessage message="Mínimo $1.00" /> : null}

              <Label>FIX</Label>
              <Money
                onChange={(amount) => hook.updateAmount("fix", amount)}
                maxDecimals={4}
                min={1}
              />
              {hook.fix < 1 ? <ErrorMessage message="Mínimo $1.00" /> : null}
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}
