import { mnCurrency } from "../../money";

import { trimDate, MONTHS } from "../../dates";

/**
 * Strucuture of the response when get the movement of an account
 *
 * @typedef {Movements}
 * @param {string} Fecha - In format DD-MM-YYYY
 * @param {string} Referencia_deposito - Deposit reference
 * @param {string} Estatus - Activo or Inactivo
 * @param {string} Tipo_movimiento - Label movement type
 * @param {string} Egreso - Egress ammount
 * @param {string} Ingreso - Incomming amount
 * @param {string} Concepto - Concept movement
 * @param {string} Metodo - Paymenth method
 * @param {string} Movimiento - Movement id
 * @param {number} statusValue - Status movement id
 */

/**
 * Parse the data that SQL Server wasn't capable of
 *
 * @param {Movements} rows - Movements fetched from the source
 */
export function parseMovements(rows) {
  rows.map((movement) => {
    // Convert egress to money
    if (movement.Egreso !== "") {
      movement.Egreso = mnCurrency(movement.Egreso);
    }

    // Convert incoming to money
    if (movement.Ingreso !== "") {
      movement.Ingreso = mnCurrency(movement.Ingreso);
    }

    // const date = trimDate(movement.Fecha,'dmy','-');

    // movement.Fecha = `${date.day}/${MONTHS[date.month-1]}/${date.year}`;
  });

  return rows;
}

/**
 * Reset the form aster adding a new movement to que
 */
export function resetQue() {
  document.getElementById("reference").focus();
  document.getElementById("reference").value = "";
  document.getElementById("concept").value = "";
  document.getElementById("importConcept").value = "";
  document.getElementById("importConcept").textContent = "";
}

/**
 * Set the inputs when edit an egress
 *
 * @param {object} movement - Response of the fetched movement
 */
export function setInputsEgress(movement) {
  // GET INPUTS
  const reference = document.getElementById("reference");
  const movInput = document.getElementById("id_movement");
  const check = document.getElementById("check");
  const concept = document.getElementById("concept");
  const importConcept = document.getElementById("import");
  const payMethod = document?.getElementById(
    `payMethods${movement.paymentMethod}`
  );

  // SET INPUTS
  reference.value = movement.reference;
  movInput.value = movement.MovementID;
  check.value = movement.checkNumber;
  concept.value = movement.concept;
  payMethod?.setAttribute("selected", "true");

  // IF AN INCOMING
  if (movement.movementType === 1) {
    // Hide input
    check.parentNode.parentNode.classList.add("d-none");
  }
}
