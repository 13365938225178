import InformativeTooltip from "components/individual/InfoTooltip";
import { mnCurrency } from "helpers/money";
import React, { useContext } from "react";
import { ContextIncomingComplement } from "..";
import style from "./styles.module.scss";

export default function Concept() {
  const hook = useContext(ContextIncomingComplement);

  if (hook.concepts.length <= 0)
    return (
      <details>
        <summary className={style.summary}>Conceptos asociados</summary>
        <h4
          style={{
            padding: "0 20px",
          }}
          className="text-left"
        >
          No hay conceptos asociados
        </h4>
      </details>
    );

  return (
    <details>
      <summary className={style.summary}>Conceptos asociados</summary>
      <div className={style.contentAssociations}>
        <div className={style.header}>
          <p>-</p>
          <p>Concepto</p>
          <p>Factura</p>
          <p>Parcialidad</p>
          <p>Moneda</p>
          <p>Total</p>
          <p>Asociado</p>
          <p>Importe ingreso</p>
          <p>TC</p>
        </div>

        {[...hook.dummyConcepts,...hook.concepts.filter(item=>item.concept.id!== 7 && item.concept.id!==8)].map((concept, i) => (

          concept.customer.id === null && ['8','7'].includes(`${concept.id}`) ? null :

          <label className={style.row} key={`concepto-${i}`}>
            <p>
              {hook.displayCheckboxs ? (
                <InformativeTooltip>
                  <p>
                    Para cancelar este concepto, todo el ingreso debe ser
                    cancelado
                  </p>
                </InformativeTooltip>
              ) : (
                "-"
              )}
            </p>
            <p className="text-left">{concept.concept.description}</p>
            <p>
              {hook.conceptsWithInvoice[concept.id] === undefined
                ? "NA"
                : hook.conceptsWithInvoice[concept.id].invoice.folio}
            </p>
             <p>
              {hook.conceptsWithInvoice[concept.id] === undefined
                ? "NA"
                : hook.conceptsWithInvoice[concept.id].cxc.partialitie.ui}
            </p>
            <p>{concept.currency}</p>
            <p>
              {mnCurrency(concept.applied)} {concept.currency}
            </p>
            <p>
              {mnCurrency(concept.applied)} {concept.currency}
            </p>
            <p>
              {mnCurrency(concept.applied)} {concept.currency}
            </p>
            <p>$1.00</p>
          </label>
        ))}
      </div>
    </details>
  );
}
