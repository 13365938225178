import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import accountComboContext from "../accountComboContext";
import Icon from "components/individual/HeaderIcon";

export default function SubmitTransfer() {
  const context = useContext(accountComboContext);

  if (context.isPerformingTransfer)
    return <Spinner hidden={false} text="Transfiriendo" />;

  return (
    <ButtonV2 onClick={context.attemptTransfer} disabled={!context.isValidForm}>
      <span>Transferir</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
