import React, { useRef, useState } from "react";
import { FileUploadContainer, AddFileButton } from "./styles";
import { iconList } from "components/individual/ButtonsV2/Button";
import useFiles from "customHooks/useFiles";
import useReceiveFiles from "customHooks/useReceiveFiles";
import FileInfo from "components/individual/SendEmail/FileInfo";

const INDEXED_TITLE = {
  "I": "Factura recibida",
  "E":"Nota de crédito recibida"
}

/**
 *
 * @param {object} props - Props
 * @param {(object:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI)=>void} props.onChange - On change info from the component
 * @param {()=>void} props.onChangePdf - On change the pdf file
 * @param {import("pages/Administration/InvoiceReception/Add/types").TypeXml} props.type - Type of xml to be load
 * @returns {JSX.Element} Sat validation xml component
 */
export default function ReceiveSatFiles({
  onChange = () => {},
  onChangePdf = () => {},
  type = "I"
}) {
  const nodeInputFile = useRef();

  const { addFiles, file, informative, deleteFile } = useFiles(["pdf", "xml"]);
  const {
    isLoadedXml,
    areMaxTwoFiles,
    noXmlFilesUploaded,
    noPdfFilesUploaded,
    isValidXmlFormat,
  } = useReceiveFiles(informative, file, onChange, onChangePdf);

  return (
    <FileUploadContainer>
      <h4>{INDEXED_TITLE[type]||'Carga XML'}</h4>

      {informative.map((file, i) => (
        <FileInfo fileInfo={file} onDelete={(uuid) => deleteFile(uuid, i)} />
      ))}

      {informative.length >= 2 ? null : (
        <>
          <AddFileButton onClick={(e) => nodeInputFile.current.click()}>
            Agregar 
          </AddFileButton>
          <input
            onChange={(e) => addFiles(e.target.files)}
            type="file"
            multiple
            className="d-none"
            accept=".pdf , .xml"
            ref={nodeInputFile}
          />
        </>
      )}

      {!isLoadedXml ? <p className="text-danger">Subir el xml *</p> : null}
      {!areMaxTwoFiles || noXmlFilesUploaded >= 2 || noPdfFilesUploaded >= 2 ? (
        <p className="text-danger">
          Solo 2 archivos permitidos, un xml y/o un pdf *
        </p>
      ) : null}

      {noXmlFilesUploaded === 1 && !isValidXmlFormat ? (
        <p className="text-danger">
          Formato del xml invalido, carga un archivo valido
        </p>
      ) : null}
    </FileUploadContainer>
  );
}
