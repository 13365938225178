import styled from "styled-components";

export const ContainerFormContact = styled.div`
  margin: 0 0 20px 0;
  height: 100%;

  .form-control {
    width: 100%;
  }

  form {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 47%);
    grid-gap: 2%;
    grid-template-areas:
      "name middlename"
      "father mother"
      "phone movil"
      "email email"
      "position status";
  }

  /////////////////////////////////////////////// RESPONSIVE ///////////////////////////////////////////////

  @media (max-width: 767px) {
    form {
      grid-gap:1rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ContainerName = styled.div`
  grid-area: name;
`;

export const ContainerMiddleName = styled.div`
  grid-area: middlename;
`;

export const ContainerFather = styled.div`
  grid-area: father;
`;

export const ContainerMother = styled.div`
  grid-area: mother;
`;

export const ContainerPhone = styled.div`
  grid-area: phone;
`;
export const ContainerMovil = styled.div`
  grid-area: movil;
`;
export const ContainerEmail = styled.div`
  grid-area: email;
`;

export const ContainerPosition = styled.div`
  grid-area: position;
`;
// -----------------------------------------------------------------------------
export const ContainerFormUser = styled.div`
  margin: 0 0 20px 0;
  height: 100%;
  .form-control {
    width: 100%;
  }

  form {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 47%);
    grid-template-areas:
      "name middlename"
      "father mother"
      "email userName"
      "dateMothYear dateMothYear"
      "executive roles";
  }

  @media (max-width: 767px) {
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem 1rem !important;      
    }
  }
`;

export const ContainerDate = styled.div`
  grid-area: dateMothYear;
`;
export const ContainerUserName = styled.div`
  grid-area: userName;
`;
export const ContainerExecutive = styled.div`
  grid-area: executive;
`;
export const ContainerSelectRole = styled.div`
  grid-area: roles;
`;
