import { checkInParameters } from "helpers/Apis/setUp";
import { useState } from "react";

/**
 * @type {import("./types").StateCheckIn}
 */
const INITIAL_STATE = {
  isChecking: false,
  checkOverview: undefined,
};

export default function useCheckIn() {
  const [state, setState] = useState(INITIAL_STATE);

  async function attemptCheck() {
    setState((current) => ({
      ...current,
      isChecking: true,
    }));
    const data = await checkInParameters();

    setState((current) => ({
      ...current,
      isChecking: false,
      checkOverview: data,
    }));
  }

  /**
   *
   * @returns {import("../IconCheckIn/types").CheckInStatus}
   */
  function checkInvoiceIcon() {
    if (state.checkOverview === undefined) return "question";

    if (state.checkOverview.isInvoiceForProduction.production) return "correct";

    return "error";
  }

  /**
   *
   * @returns {import("../IconCheckIn/types").CheckInStatus}
   */
  function blobIcon() {
    if (state.checkOverview === undefined) return "question";

    if (state.checkOverview.blobStorage.isSet) return "correct";

    return "error";
  }

  /**
   *
   * @returns {import("../IconCheckIn/types").CheckInStatus}
   */
  function pdfDocumentsIcon() {
    if (state.checkOverview === undefined) return "question";

    if (state.checkOverview.isSetPdfDocuments.isSet) return "correct";

    return "error";
  }

  /**
   *
   * @returns {import("../IconCheckIn/types").CheckInStatus}
   */
  function csdIcon() {
    if (state.checkOverview === undefined) return "question";

    if (state.checkOverview.csd.isValidCsd) return "correct";

    return "error";
  }

    /**
   *
   * @returns {import("../IconCheckIn/types").CheckInStatus}
   */
    function odcIcon() {
      if (state.checkOverview === undefined) return "question";
  
      if (state.checkOverview.odc.valid) return "correct";
  
      return "error";
    }

  return {
    ...state,
    attemptCheck,
    invoiceIcon: checkInvoiceIcon(),
    blobIcon: blobIcon(),
    pdfDocumentsIcon: pdfDocumentsIcon(),
    csdIcon: csdIcon(),
    odcIcon:odcIcon()
  };
}
