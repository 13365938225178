import React, { useContext } from "react";
import { RangeDate, From, To } from "components/general/RangeDate";
import { ContextOdc } from "../..";

export default function Dates() {
  const {
    endDate,
    startDate,

    updateRangeDates,
  } = useContext(ContextOdc);

  return (
    <RangeDate>
      <From
        selected={startDate}
        onChange={(date) => updateRangeDates([date, endDate])}
      />
      <To
        selected={endDate}
        onChange={(date) => updateRangeDates([startDate, date])}
      />
    </RangeDate>
  );
}
