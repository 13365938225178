import React from "react";
import useQueryParams from "customHooks/useQueryParams";
import { ContractContext } from "customHooks/useContract/ContractContext";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import usePermissions from "customHooks/usePermissions";
import ui from '../styles.module.scss';

const { Provider } = ContractContext;

export default function AddOdc() {
  const { idCliente, modulo } = useQueryParams();

  usePermissions("f56602a3-be22-41c3-95a9-56bdcc4175bd");

  return (
    <Provider value={{ module: modulo }}>
      <div className={ui.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/administracion/ordenes-compra",
            text: "Administracion",
          },
          {
            route: "/administracion/ordenes-compra",
            text: "Ordenes de compra",
          },
          {
            route: "",
            text: "Agregar",
          },
        ]}
      />

      <AddDocument
        actionType="Agregar"
        customerId={idCliente}
        documentType={3}
      />
      </div>

      {/* <div className="container">
        <header className="d-flex align-items-center justify-content-between my-3">
          <HeaderDocument
            currency={useContractValues.currency}
            socialReason={useContractValues.customerSocialReason}
          />
          <AddOdcButton />
        </header>

        <Tabs
          idContainerContent={"documentInfo"}
          idContainerTabs={"tabs"}
          tabButtons={{
            document: {
              text: "O.C.",
              selected: true,
              component: <TabOdc />,
            },
            items: {
              text: "Partidas",
              selected: false,
              component: (
                <Partidas
                  currency={useContractValues.currency}
                  tc={useContractValues.tc}
                  typeDocument="odc"
                  calculationMode="cu"
                  onChange={(value) => {
                    useContractValues.updateItemsCosts(value);
                  }}
                />
              ),
            },
            comments: {
              text: "Notas y consideraciones",
              selected: false,
              component: (
                <Tabs
                  idContainerContent="notesAndConsiderations"
                  idContainerTabs="commentsNotesConsiderations"
                  tabButtons={{
                    commentAddInvoice: {
                      text: "Comentarios",
                      selected: false,
                      component: (
                        <CommentsDocument
                          includeCc={true}
                          idTypeDocument={3}
                          onChange={useContractValues.updateComments}
                        />
                      ),
                    },
                    notes: {
                      text: "Notas y consideraciones",
                      selected: true,
                      component: (
                        <NoteConsideration
                          currency={useContractValues.currency}
                          idTypeDocument={3}
                          uen={useContractValues.uens}
                          onChange={
                            useContractValues.updateNotesAndConsiderations
                          }
                        />
                      ),
                    },
                  }}
                />
              ),
            },
          }}
        />
      </div> */}
    </Provider>
  );
}
