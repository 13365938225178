import React, { useEffect, useReducer } from "react";
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { associateMove } from "../../../static/StaticBreadCrums";

import { useParams } from "react-router-dom";

import { getMovement } from "../../../helpers/Apis/Banks";
import BankInformation from "../BankInformation";
import { reducerConsolation, initialState } from "./reducerConsolation";
import { SET_MOVEMENT } from "./typesConsolation";

import Step1 from "./Consolation/Step1";
import Step2V2 from "./Consolation/Step2V2";
import { createContext } from "react";

export const ContextAssociateCxP = createContext();
const { Provider } = ContextAssociateCxP;

const loadInitialState = () => initialState;

function Associate() {
  const { movement, account } = useParams();

  associateMove.breadcrum[2].url = `/bancos/movimientos/${account}`;

  const [state, dispatch] = useReducer(
    reducerConsolation,
    {},
    loadInitialState
  );

  useEffect(() => {
    (async function () {
      const apiMovement = await getMovement(+movement);
      dispatch({
        type: SET_MOVEMENT,
        payload: apiMovement,
      });
    })();
  }, []);

  const stepConsolation = {
    1: <Step1 setState={dispatch} state={state} />,
    2: (
      <Step2V2
        customerId={state.client?.ID}
        bankAccountId={state.bankInfo?.bankAccountID}
        idMovement={+movement}
      />
    ),
  };

  return (
    <Provider value={{
      customer:state.client
    }}>
      <HomeHeader bredcrum={associateMove} mostrar={false} />

      <div className="container">
        <BankInformation idAccount={+account} text="" />
        {stepConsolation[state.step]}
      </div>
    </Provider>
  );
}

export default Associate;
