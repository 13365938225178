import { Spinner } from "components/individual/Spinner/Spinner";
import useConcept from "customHooks/useConcept";
import React from "react";
import { createContext } from "react";
import { Disassociate } from "./Disassociate";
import scss from "./styles.module.scss";

/**
 * Context for the disassociation
 * @type {import("react").Context<import("./types").ContextDisassociate>}
 */
export const ContextDisassociateConcept = createContext();
const { Provider } = ContextDisassociateConcept;

/**
 * Render a UI to dis-associate a movement related with a concept
 * @param {import("./types").PropsDisassociateConcept} props - Props
 * @returns {JSX.Element}
 */
export const DisassociateConcept = ({
  idMovement = 378,
  onDisassociated = () => {},
}) => {
  const { attemptDisassociate, concept } = useConcept({
    idMovement,
    onDisassociated,
  });

  if (concept.isLoading)
    return <Spinner hidden={false} text="Cargando asociacion de concepto..." />;

  return (
    <Provider
      value={{
        ...concept,
        idMovement,
        attemptDisassociate,
      }}
    >
      <div className={scss.header}>
        <p>Concepto</p>
        <p>Tipo</p>
        <p>Asociado</p>
      </div>
      <div className={scss.row}>
        <p>{concept.info.concept.description}</p>
        <p>{concept.info.concept.type.description}</p>
        <p>
          {concept.info.paid.text} {concept.info.concept.currency}
        </p>
      </div>

      <Disassociate />
    </Provider>
  );
};
