import LoginContext from "context/Login/LoginContext";
import {
  downloadPdfDocumentV3,
  getPendingQuotes,
} from "helpers/Apis/Documents";
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";

/**
 * State to handle the home screen
 * @type {import("./types").StateHome}
 */
const INITIAL_STATE = {
  isLoading: true,
  quote: null,
  quotes: [],
  pages: 0,
  currentPage: 1,
  refetch: false,
  isDownloadingPdf:false
};

export const HOME_CONTEXT_VALUE = {
  ...INITIAL_STATE,
  selectQuote: () => {},
  breadcrum: [],
  updatePage: () => {},
  refetchCurrentPage: () => {},
  viewQuote: () => {},
  redirectAssociatedFiles: () => {},
  editDocument:()=>{}, 
  downloadPdf:async()=>{}, 
  winDocument:()=>{}, 
  redirectToDo:()=>{}
};

/**
 * Hook to handle the home
 * @returns {import("./types").ReturnStateHome}
 */
export default function useHome() {
  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  const viewQuote = () =>
    history.push(
      `directorio/documentos/${state.quote.customer.id}/ver/${state.quote.idDocument}`
    );

  const redirectAssociatedFiles = () =>
    history.push(
      `directorio/${state.quote.customer.id}/documentos/documentos/archivos-asociados/${state.quote.idDocument}`
    );

  const editDocument = () =>
    history.push(
      `documentos/cotizacion/editar?idCliente=${state.quote.customer.id}&documento=${state.quote.idDocument}`
    );

    const winDocument = () =>
    history.push(
      `directorio/documentos/${state.quote.customer.id}/ganar2/${state.quote.idDocument}`
    );

    const redirectToDo = () =>
    history.push(
      `directorio/documentos/reminders/${state.quote.customer.id}/${state.quote.idDocument}`
    );

    // `directorio/documentos/reminders/${hook.quote.customer.id}/${hook.quote.idDocument}`

  const app = useContext(LoginContext);

  useEffect(() => {
    (async function () {
      if (typeof app.userToRepresentId !== "number") return;

      setState((current) => ({
        ...current,
        isLoading: true,
        quote: null,
        quotes: [],
      }));

      const apiQuotes = await getPendingQuotes(
        app.userToRepresentId,
        state.currentPage
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        quotes: apiQuotes.quotes,
        currentPage: apiQuotes.currentPage,
        pages: apiQuotes.totalPages,
      }));
    })();
  }, [app.userToRepresentId, state.refetch, state.currentPage]);

  const selectQuote = (quote) =>
    setState((current) => ({
      ...current,
      quote,
    }));

  /**
   * Function to execute in case it's need to refetch the current information with the same parameters
   * @returns {void}
   */
  const refetchCurrentPage = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Update the page to fetch
   * @param {number} page - Page to fetch
   * @returns {void}
   */
  const updatePage = (page) =>
    setState((current) => ({
      ...current,
      currentPage: page,
    }));
  
  /**
   * Funciton to handle to pdf download
   */
  const downloadPdf = async () => {

    setState(current=>({
      ...current,
      isDownloadingPdf:true
    }));

    const pdf2 = await downloadPdfDocumentV3(
      state.quote.idDocument,
      "intranet"
    );

    saveAs(pdf2["0"], `Cotizacion-${state.quote.documentNumber}.pdf`);

    setState(current=>({
      ...current,
      isDownloadingPdf:false
    }));
  };

  return {
    ...state,
    selectQuote,
    viewQuote,
    redirectAssociatedFiles,
    refetchCurrentPage,
    updatePage,
    winDocument,
    editDocument,
    downloadPdf,
    redirectToDo,
    breadcrum: [
      {
        text: "Inicio",
        route: "",
      },
    ],
  };
}
