const { default: styled } = require("styled-components");

export const HeaderItemContainer = styled.div`
  background-color: var(--cardBackground);
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  align-items: flex start;
  flex-direction: row;
  font-weight: bold;
  padding: 0.8rem;
  align-items: center;
  border: 1px solid var(--primaryColor);
  border-style: none none solid none;
  strong{
    color:var(--primaryColor);
  }

`;
export const HeaderShort = styled.div`
  width: 10%;
`;
export const HeaderShortKey = styled.div`
  width: 12%;
`;
export const HeaderLong = styled.div`
  width: 55%;
`;
export const HeaderShortButton = styled.div`
  
`;

export const ItemsElemntContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: flex start;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;

`;

export const ItemConteniner = styled.div`
  padding: 0.8rem;
  border: 0.1rem solid;
  color:var(--primaryColor);
  border-color: var(--primaryColor);
  border-style: none none solid none;

`;

export const ItemesContainer = styled.div`
  background-color: var(--cardBackground);
  width: 100%;
  color: var(--primaryColor);
  display: flex;
  align-items: flex start;
  flex-direction: column;

  @media (max-width: 767px) {
    width:1350px;
  }
`;

export const BodyShortKey = styled.div`
  width: 12%;
  display: flex;
  padding: 0.2rem;
  flex-direction: column;
`;
export const BodyShort = styled.div`
  width: 10%;
  display: flex;
  padding: 0.2rem;
`;

export const ItemText = styled.p`
  margin: 0px;
  padding: 0px;
  color:var(--primaryColor);
`;
export const SubDescriptionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  width: 35%;
  gap: 0.4rem;
`;

export const DescriptionContainer = styled.div`
  width: 55%;
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
  color:var(--primaryColor);
`;
export const DescriptionText = styled.p`
  margin: 0px;
  padding: 0px;
  color:var(--primaryColor);
  display: flex;
`;
export const DescriptionTextBold = styled.p`
  margin: 0px;
  padding: 0px;
  font-weight: bold;
`;

export const SubDescription = styled.div`
  width: 55%;
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  color:var(--primaryColor);
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
export const HeaderForm = styled.div`
  display: flex;
  flex-direction: row;
  color:var(--primaryColor);
  *:not(button){
    color:var(--primaryColor) !important;
  }
  justify-content: space-between;
  padding:1rem 1.5rem;
  border: 1px solid var(--primaryColor);
  border-style: none none solid none;
  align-items: center;
  position:sticky;
  background:var(--cardBackground);
  top:0;
  z-index:50;
  h4 {
    margin: 0;
  }
`;

export const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
color:var(--primaryColor);
  
`;

export const BodyFormPart1 = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
  justify-content: space-between;

  > *:nth-child(1) {
    width: 10%;
  }

  > *:nth-child(2) {
    width: 20%;
  }

  > *:nth-child(3) {
    width: 50%;
  }
  > *:nth-child(4) {
    width: 10%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap:1rem 0;
    > * {
      width: 100% !important;
    }
  }
`;
export const BodyFormPart2 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  > *:nth-child(1) {
    width: 20%;
  }
  > *:nth-child(2) {
    width: 37%;
  }

  > *:nth-child(3) {
    width: 37%;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap:1rem 0;
    > * {
      width: 100% !important;
    }
  }
`;
export const BodyFormPart3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  gap: 0 2rem;
  margin: 0 0 5rem 0;

  @media (max-width: 767px) {
    flex-direction: column;
    gap:1rem 0;
    > * {
      width: 100% !important;
    }
  }
`;
export const BodyFormPart4 = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 2rem;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    gap:1rem 0;
    > * {
      width: 100% !important;
    }
  }
`;
export const BodyFormPart5 = styled.div`
  display: flex;
  margin:1rem 0 0 0;
  border: 1px solid var(--primaryColor);
  border-style: solid none none none;
  align-items: center;
  justify-content: end;
  width: 100%;
  position:sticky;
  bottom:0;
  background:var(--cardBackground);
  padding:1rem 1.5rem;
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 5.5rem;
`;
