import React, { useContext } from "react";
import Base from "../Base";
import CustomCurrency from "../CustomCurrency";
import { ContextPareto } from "..";
import ui from "../styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import ExcelButton from "../ExcelButton";

export default function Filter() {
  const hook = useContext(ContextPareto);

  return (
    <form
      ref={hook.form}
      className={ui.filter}
      onSubmit={(e) => {
        e.preventDefault();
        hook.attemptSearch();
      }}
    >
      <CustomCurrency />
      <Base />

      <ButtonV2>
        <span>Buscar</span>
      </ButtonV2>
      <ExcelButton />
    </form>
  );
}
