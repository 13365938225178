import {
  InputCurrency,
  InputNumber,
  InputText,
} from "components/individual/inputs/Inputs";
import CurrencySelect from "components/individual/inputs/Currency";

import React, { useContext, useEffect, useRef } from "react";
import { Label } from "structure/Document";
import {
  NonFiscalContainerGrid,
  EmittedDate,
  Currency,
  CreditDays,
  Emitter,
  ExpirationDate,
  Folio,
  Receptor,
  SocialReason,
  Total,
} from "../../Filter/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { AddReceptionInvoiceContext } from "../../Filter";
import SocialReasonInvoice from "components/general/Forms/Selects/SocialReasonInvoice";
import { sub } from "date-fns";
import Rfc from "components/general/Forms/Text/Rfc";
import InformativeTooltip from "components/individual/InfoTooltip";
import { getParameters } from "helpers/Apis/parameters";
import { ContainerStep1 } from "../../Add/styles";
import {
  AddFileButton,
  FileUploadContainer,
} from "components/general/ReceiveSatFiles/styles";
import { iconList } from "components/individual/ButtonsV2/Button";
import useFiles from "customHooks/useFiles";
import FileInfo from "components/individual/SendEmail/FileInfo";
import useReceiveFiles from "customHooks/useReceiveFiles";
import { RFC, InputRfc, LabelRfc } from "components/forms/RFC";

registerLocale("es", es);

export default function NoFiscalInvoice() {
  const { addFiles, file, informative, deleteFile } = useFiles(["pdf"]);
  const {
    setEmittedDate,
    state,
    setSocialReason,
    setFolio,
    setCurrency,
    setCreditDays,
    setExpirationDate,
    setRfc,
    setRfcEnterprise,
    setPdfFile,
    setTotal,
    isValidRfc,
    isValidFolio,
    isValidTotal,
    isValidCurrency,
    isValidCreditDays,
    isValidExpirationDate,
  } = useContext(AddReceptionInvoiceContext);

  useReceiveFiles(informative, file, () => {}, setPdfFile);

  const nodeInputFile = useRef();

  useEffect(() => {
    (async function () {
      const parameter = await getParameters();
      setRfcEnterprise(parameter["9"]["value"]);
    })();
  }, []);

  return (
    <>
      <ContainerStep1>
        <FileUploadContainer>
          {informative.map((file, i) => (
            <FileInfo
              fileInfo={file}
              onDelete={(uuid) => deleteFile(uuid, i)}
            />
          ))}

          {informative.length === 1 ? null : (
            <AddFileButton>
              {iconList["add"]} Agregar PDF (Opcional)
              <input
                onChange={(e) => addFiles(e.target.files)}
                type="file"
                className="d-none"
                accept=".pdf , .xml"
                ref={nodeInputFile}
              />
            </AddFileButton>
          )}
        </FileUploadContainer>

        <NonFiscalContainerGrid>
          <EmittedDate>
            <Label>Fecha de emision</Label>
            <DatePicker
              selected={
                state.xmlInfo?.xmlInfo?.emitedDate?.jsDate || new Date()
              }
              onChange={setEmittedDate}
              dateFormat="dd/MMM/yyyy"
              locale="es"
              placeholderText="Selecciona una fecha"
              maxDate={sub(state.expirationDate.jsDate, {
                days: 1,
              })}
            />
          </EmittedDate>

          <SocialReason>
            <Label>
              Razón social
              <InformativeTooltip>
                <ul>
                  <li>La razón social no se crea en el directorio</li>
                  <li>Enter despues de teclear la razon social</li>
                </ul>
              </InformativeTooltip>
            </Label>
            <SocialReasonInvoice
              type="proveedor"
              onChange={({ socialReason, id, rfc, creditDays }) =>
                setSocialReason(socialReason, id, rfc===null?'':rfc, creditDays)
              }
            />
          </SocialReason>

          <Folio>
            <Label requried={!isValidFolio ? true : false}>Folio</Label>
            <InputText
              labelTxt=""
              defaultValue={state.xmlInfo?.xmlInfo?.folio}
              placeholderTxt="Escribe aquí"
              onChange={setFolio}
            />
          </Folio>

          <Emitter>
            <RFC value={state.xmlInfo.xmlInfo.rfcEmitter} onChange={(data) => setRfc(data.rfc, data.isValidRfc)}>
            {()=><>
            
              <span className="d-flex">
              {/* <Label required={!isValidRfc ? true : false}>RFC Emisor</Label> */}
              <LabelRfc/>
              {/* {state.xmlInfo?.xmlInfo?.rfcEmitter?.length || 0}/256 */}
            </span>
            {/* <RFC onChange={data=>setRfc(data.rfc,data.isValidRfc)}>
              {()=><InputRfc/>}
            </RFC> */}
            {!state.socialReasonExists ? (
                <InputRfc />
                ) : (
                  <p>{state.xmlInfo.xmlInfo.rfcEmitter}</p>
                  )}
            
            </>}
                  </RFC>
          </Emitter>

          <Receptor>
            <Label>RFC Receptor</Label>
            <p>{state?.xmlInfo?.xmlInfo?.rfcReceptor || "Cargando..."}</p>
          </Receptor>

          <Currency>
            <Label required={!isValidCurrency ? true : false}>Moneda</Label>
            <CurrencySelect
              selected={state.xmlInfo.xmlInfo.currency}
              onChange={setCurrency}
            />
          </Currency>

          <Total>
            <Label required={!isValidTotal ? true : false}>Total</Label>
            <InputCurrency
              labelTxt=""
              defaultValue={state.xmlInfo?.xmlInfo?.total?.number}
              placeholder="Escribe aquí"
              onChange={setTotal}
            />
          </Total>

          <CreditDays>
            <Label required={!isValidCreditDays ? true : false}>
              Días de crédito
            </Label>
            <InputNumber
              decimal={false}
              labelTxt=""
              placeholderTxt="Escribe aquí"
              defaultValue={state.creditDays}
              onChange={setCreditDays}
            />
          </CreditDays>

          <ExpirationDate>
            <Label required={!isValidExpirationDate ? true : false}>
              Fecha de vencimiento
            </Label>
            {state.creditDays === null ? (
              <p>Escribe los días de credito</p>
            ) : (
              <DatePicker
                selected={state.expirationDate.jsDate}
                onChange={setExpirationDate}
                dateFormat="dd/MMM/yyyy"
                locale="es"
                minDate={
                  state.xmlInfo?.xmlInfo?.emitedDate?.jsDate || new Date()
                }
                placeholderText="Selecciona una fecha"
              />
            )}
          </ExpirationDate>
        </NonFiscalContainerGrid>
      </ContainerStep1>
    </>
  );
}
