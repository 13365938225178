import React, { useContext } from "react";
import CheckboxesRols from "components/general/CheckboxesRols";
import { AddRolContext } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function Step2() {
  const {
    description: { content },
    updateRoles,
    isAddingRol,
  } = useContext(AddRolContext);

  return (
    <>
      {isAddingRol ? (
        <Spinner hidden={false} text={`Agregando ${content}...`} />
      ) : (
        <>
          <h4 className="text-center">
            Selecciona los roles que podra supervisar '{content}'
          </h4>
          <CheckboxesRols onChange={updateRoles} />
        </>
      )}
    </>
  );
}
