import React from "react";
import scss from "./styles.module.scss";
import { useHistory } from "react-router-dom";

export const Forbidden = () => {
  const history = useHistory();

  return (
    <main className={scss.container}>
      <div className={scss.banner}>
        <div>
          <h1> ✋ 403 - No tienes acceso por los permisos</h1>
          <p className="text-center font-weight-bold my-2">
            Ups, parece que no tienes acceso al modulo que intentaste ingresar,
            <span
              onClick={() => history.push("/inicio")}
              className={scss.redirect}
            >
              {" "}click aquí para regresar
            </span>
          </p>
        </div>
      </div>
    </main>
  );
};
