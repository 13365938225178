import { getBankAccountV2, getBanks } from "helpers/Apis/Banks";
import { useEffect, useState } from "react";

/**
 * @param {import("./types").ParamsUseBanks} params - Params
 * @returns {import("./types").ReturnUseBanks} Return values
 */
export default function useBanks({ clave, id }) {
  /**
   * @type {[import("./types").StateUseBanks,()=>void]}
   */
  const [state, setState] = useState({
    isLoading: true,
    banks: [],
    selected: null,
  });

  /**
   * Handle the change of the bank selected on the combo (new or existing one)
   * @param {import("./types").SelectedBankI} bank
   */
  const handleChangeBank = (bank) => {
    // Is not new bank
    if (bank["__isNew__"] === undefined) {
      return setState({
        ...state,
        selected: bank,
      });
    }

    const valueBank = window.crypto.randomUUID();

    // Is new bank
    return setState({
      ...state,
      selected: {
        claveSat: null,
        id: valueBank,
        label: bank.label,
        value: "",
        rfc: null,
        shortName: bank.label,
        socialReason: null,
      },
    });
  };

  useEffect(() => {
    (async function () {
      const apiBanks = await getBanks();

      setState({
        ...state,
        isLoading: false,
        banks: apiBanks,
      });
    })();
  }, []);

  useEffect(() => {
    if (state.banks.length === 0) return;

    if (id === null && clave === null) return;

    console.log(state.banks, id);

    // Not founded, search by id
    const bankSearchedById = state.banks.find((bank) => bank.id === id);

    // Bank founded
    if (bankSearchedById !== undefined && bankSearchedById !== null) {
      return setState({
        ...state,
        selected: {
          ...bankSearchedById,
          label: bankSearchedById.shortName,
          value: bankSearchedById.claveSat,
        },
      });
    }

    const bankSearchedByClave = state.banks.find(
      (bank) => bank.claveSat === clave
    );
    // Bank founded
    if (bankSearchedByClave !== undefined && bankSearchedByClave !== null) {
      return setState({
        ...state,
        selected: {
          ...bankSearchedByClave,
          label: bankSearchedByClave.shortName,
          value: bankSearchedByClave.claveSat,
        },
      });
    }
  }, [state.banks, id, clave]);

  return {
    isLoading: state.isLoading,
    banks: state.banks,
    selected: state.selected,
    handleChangeBank,
  };
}
