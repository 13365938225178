import React from "react";
import scss from "../index.module.scss";
import TextIcon from "../TextIcon";
import usePermissions from "customHooks/usePermissions";

/**
 * Render an option on the menu
 * @param {import('./types').PlainOptionProps} props - Props
 * @returns {JSX.Element}
 */
export default function PlainOption({
  onClick = () => {},
  icon,
  text,
  id = window.crypto.randomUUID(),
  permission = null

}) {

  const { checkPermission } = usePermissions();

  if(typeof(permission)==="string"){
    if(!checkPermission(permission)) return <></>
  }

  return (
    <p id={id} className={scss.item} onClick={onClick}>
      <TextIcon icon={icon} text={text} />
    </p>
  );
}
