import React from "react";
import useAssociateCorporative, {
  ContextCorporativeValue,
} from "./useAssociateCorporative";
import Dialog from "structure/Dialog";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { createContext } from "react";
import Table from "./Table";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Pagination } from "components/individual/Pagination";
import style from "./Table/styles.module.scss";
import styleModal from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";

export const ContextCorporative = createContext(ContextCorporativeValue);

/**
 * Render a button that associates a corporative to a customer or provider
 * @param {import('./types').PropsAssocaiteCorporative} props - Props
 * @returns {JSX.Element}
 */
export default function AssociateCorporative({
  children = <></>,
  idCorporative = null,
  onChange = () => {},
}) {
  const hook = useAssociateCorporative(idCorporative);

  const confirmSelection = () => {
    hook.closeModal();
    onChange(hook.corporative);
  };

  return (
    <ContextCorporative.Provider value={{ ...hook, confirmSelection }}>
      <div onClick={hook.openModal}>{children}</div>

      <Dialog
        width={75}
        title="Asociar corporativo"
        forwardRef={hook.dialog.refDialog}
        
        closeCallback={hook.closeModal}
        footer={
          <div className={styleModal.footerCorporative}>
            <ButtonV2
              onClick={confirmSelection}
              disabled={hook.corporative === null}
              type="button"
            >
              <span>Seleccionar</span>
              <Icon icon="save" />
            </ButtonV2>
          </div>
        }
      >
        <div>
          <Label>Búsqueda</Label>
          <Search
            onChange={hook.setSearch}
            onDelete={() => hook.setSearch("")}
            placeholder="Razón social, RFC o nombre corto"
            onSearch={hook.attemptSearch}
          />
        </div>

        <Table />
      </Dialog>
    </ContextCorporative.Provider>
  );
}
