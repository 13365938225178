import { retrieveLogs } from "helpers/Apis/logs";
import { dateToDbFormat, monthRange } from "helpers/dates";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").StateUseLogs}
 */
const INITIAL_STATE = {
  isLoading: false,
  logs: [],
  page: 1,
  pages: 1,
  rowsPerPage: 100,
  from: monthRange().firstDay,
  to: new Date(),
  proveedor: null,
  search: "",
  tipo: "todos",
  refetch: false,
  order: "id",
  orderAsc: false,
};

/**
 *
 * @returns {import("./types").ReturnUseLogs}
 */
export default function useLogs() {
  const [state, setState] = useState(INITIAL_STATE);
  /**
   * @type {import("react").MutableRefObject<import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]>}
   */
  const breadcrum = useRef([
    {
      text: "Inicio",
      route: "/inicio",
    },
    {
      text: "Logs",
      route: "/",
    },
  ]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const { logs, page, pages, recordsFound } = await retrieveLogs({
        busqueda: state.search,
        desde: dateToDbFormat(state.from),
        hasta: dateToDbFormat(state.to),
        pagina: state.page,
        registrosPorPagina: state.rowsPerPage,
        proveedor: state.proveedor,
        tipo: state.tipo,
        orden: state.order,
        ordenAsc: state.orderAsc,
      });

      setState((current) => ({
        ...current,
        isLoading: false,
        logs,
        page,
        pages,
      }));
    })();
  }, [
    state.page,
    state.rowsPerPage,
    state.refetch,
    state.orderAsc,
    state.order,
  ]);

  const refetchLog = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Update the page to fetch on the log
   * @param {number} page - Page requested
   * @returns {void}
   */
  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  /**
   * Set the rows to display on the UI of the logs when data is fetched
   * @param {number} rowsPerPage - Rows per page
   * @returns {void}
   */
  const setRowsPerPage = (rowsPerPage) =>
    setState((current) => ({
      ...current,
      rowsPerPage,
      page: 1,
    }));

  /**
   * Update the column order
   * @param {import("../../../../server/controllers/typesControllerLogs").OrderLogs|string} column - Column order
   * @returns {void}
   */
  const setColumnOrder = (column) => {
    setState((current) => ({
      ...current,
      order: column,
      page: 1,
      orderAsc: column === current.order ? !current.orderAsc : current.orderAsc,
    }));
  };

  /**
   * Update the range date for the filter
   * @param {Date} start - Start date
   * @param {Date} end - End date
   * @returns {void}
   */
  const setRangeDate = (start, end) =>
    setState((current) => ({
      ...current,
      from: start,
      to: end,
    }));

  /**
   * Set the text to search for query
   * @param {string} search - Search text
   * @returns {void}
   */
  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  //   proveedor
  // tipo

  /**
   * Set the provider in order to fetch the logs
   * @param {number|null} provider
   * @returns {void}
   */
  const setProvider = (provider) =>
    setState((current) => ({
      ...current,
      proveedor: provider,
    }));

  /**
   * Set the type of log to fetch
   * @param {import("../../../../server/controllers/typesControllerLogs").TypeLog} type
   * @returns {void}
   */
  const setType = (type) =>
    setState((current) => ({
      ...current,
      tipo: type,
    }));

  return {
    ...state,
    refetchLog,
    setProvider,
    setType,
    breadcrum: breadcrum.current,
    setPage,
    setRowsPerPage,
    setSearch,
    setColumnOrder,
    setRangeDate,
  };
}
