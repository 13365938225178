import React, { useContext } from "react";
import ui from "./styles.module.scss";
import { ContextReception } from "..";
import { mnCurrency } from "helpers/money";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import { parseToFolio } from "helpers/documents";

export default function Header() {
  const hook = useContext(ContextReception);

  if (
    hook.proyect === undefined ||
    hook.position === undefined ||
    hook.materials.length <= 0 ||
    hook.state === "loading"
  )
    return <></>;

  return (
    <div className={ui.headerContainer}>
      <div className={ui.header}>
        <div>
          <b>Solped</b>
          <p>{hook.proyect.solped}</p>
        </div>

        <div>
          <b>Cliente</b>
          <p>{hook.customer.socialReason}</p>
        </div>

        <div>
          <b>Pos</b>
          <p>{hook.position.pos}</p>
        </div>

        <div>
          <b>OC</b>
          <p>{parseToFolio(hook.ocNumber)}</p>
        </div>

        <div>
          <b>Total</b>
          <p>
            {mnCurrency(
              hook.materials.reduce(
                (total, material) =>
                  (total += material.cost * material.initialQuantity),
                0
              )
            )}
          </p>
        </div>
      </div>

      {hook.isValidGrid ? (
        hook.state !== "updating" ? (
          <ButtonV2 onClick={async() => await hook.attemptReceiveMaterials()}>
            <span>Recepcionar material</span>
            <Icon icon="plus" />
          </ButtonV2>
        ) : (
          <Spinner hidden={false} text={"Recibiendo materiales"} />
        )
      ) : (
        Object.keys(hook.grid).length > 0 && (
          <p className="font-weight-bold text-warning">🟡 Valida la grid</p>
        )
      )}
    </div>
  );
}
