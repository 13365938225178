import React from "react";
import { useContext } from "react";
import { ContextResidueBankAccouts } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";
import { useRef } from "react";
import { mnCurrency } from "helpers/money";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Label } from "structure/Document";

export default function TableResidueReport() {
  const hook = useContext(ContextResidueBankAccouts);

  const keyList = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingReportResidues"
        text={<LoadingText>Cargando reporte</LoadingText>}
      />
    );

  if (hook.report === undefined) return <></>;

  return (
    <div className={style.tableBankResidues}>
      <div className={style.header}>
        <p>Descripción</p>
        <p>Banco</p>
        <p>Cuenta</p>

        <p>MXN</p>
        <p>USD</p>
      </div>

      <div className={style.contentRow}>
        {hook.report.banckAccounts.map((item, i) => (
          <div key={`${i}-${keyList.current}`}>
            <div className={style.mobileItem}>
              <div>
                <Label>Banco</Label>
                <span>{item.account}</span>
              </div>

              <div>
                <Label>Cuenta</Label>
                <span>{item.account}</span>
              </div>

              <div className={style.mobileItemAmounts}>
                <div>
                  <Label>MXN</Label>
                  <span>
                    {item.currency === "MXN" ? mnCurrency(item.amount) : "-"}
                  </span>
                </div>

                <div>
                  <Label>USD</Label>
                  <span>
                    {item.currency === "USD" ? mnCurrency(item.amount) : "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className={style.row}>
              <p>
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={item.description}
                />
              </p>

              <p className="text-right">{item.account}</p>
              <p className="text-right">{item.account}</p>

              {item.currency === "MXN" ? (
                <p className="text-right">{mnCurrency(item.amount)}</p>
              ) : (
                <p className="text-center">-</p>
              )}
              {item.currency === "USD" ? (
                <p className="text-right">{mnCurrency(item.amount)}</p>
              ) : (
                <p className="text-center">-</p>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={style.totals}>
        <p></p>
        <p></p>

        <p className="font-weight-bold text-right">Total</p>
        <p className="text-right">{mnCurrency(hook.report.totalMxn)}</p>
        <p className="text-right">{mnCurrency(hook.report.totalUSD)}</p>
      </div>
    </div>
  );
}
