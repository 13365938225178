import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

/**
 * Render a modal
 * @param {import("./types").PropsModal} props - Props
 * @returns {JSX.Element}
 */
export const Modal = ({
  children,
  title = "[Titulo aquí]",
  footer = null,
  footerV2 = null,
  propsOpenModal = {},
  isOpen = false,
  width = null,
  onCloseModal = () => {},
  id = window.crypto.randomUUID(),
  displayButton = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    onCloseModal();
  };

  return (
    <>
      {displayButton ? (
        <ButtonV2 {...propsOpenModal} onClick={openModal}>
          {propsOpenModal.children}
        </ButtonV2>
      ) : null}

      {isModalOpen ? (
        <div>
          <div class="modal-backdrop fade show"></div>
          <div
            className="modal fade show d-block  "
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            role="dialog"
          >
            <div
              className="modal-dialog modal-lg  modal-dialog-scrollable  modal-dialog-centered"
              role="document"
            >
              <div
                className="modal-content"
                style={{
                  width: width === null ? "100%" : `${width}vw`,
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    {title}
                  </h5>
                  <button
                    id={id}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {children({ closeModal, openModal })}
                </div>
                {footer === null ? null : (
                  <div className="modal-footer">{footer}</div>
                )}

                {!footerV2 ? null : (
                  <div className="modal-footer">{footerV2({ closeModal })}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
