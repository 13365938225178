import { saveAs } from "file-saver";
import {
  downloadCatalogueTemplate,
  downloadDirectoryTemplate,
  downloadEgressConceptsExcel,
  downloadIncomingsExcel,
  downloadIvaExcelSetup,
  downloadMovementsExcelSetup,
  downloadTemplate,
  downloadTypeConcepts,
  downloadUenExcelSetup,
  updateIvaSetup,
  updateUenSetup,
  uploadExcelTemplate,
  uploadMovements,
  uploadTemplateCatalogue,
} from "helpers/Apis/setUp";
import { useState } from "react";

/**
 * Custom hook in order to get the
 * @param {import("./types").SetUpList} type - Type of setup tried to be configurated
 */
export default function useSetup(type = "ivas") {
  /**
   * @type {import("helpers/Apis/setUp/types").FileNameTemplate}
   */
  const nameTemplate = {
    "sectorMarket": "Sector de mercado.xlsx",
    "contacts":"Plantilla Contactos.xlsx"
  };

  const [state, setState] = useState({
    isLoading: false,
    isUpdating: false,
  });

  const setIsLoading = (isLoading) =>
    setState((current) => ({ ...current, isLoading }));
  const setIsUpdating = (isUpdating) =>
    setState((current) => ({ ...current, isUpdating }));

  const conceptosEgresosTemplate = async () => {
    setIsLoading(true);
    const file = await downloadEgressConceptsExcel();
    saveAs(file, "Plantilla conceptos de egreso");
    setIsLoading(false);
  };

  const directorioTemplate = async () => {
    setIsLoading(true);
    const file = await downloadDirectoryTemplate();
    saveAs(file, "Plantilla directorio");
    setIsLoading(false);
  };

  const tiposDeIngresoTemplate = async () => {
    setIsLoading(true);
    const file = await downloadTypeConcepts("ingresos");
    saveAs(file, "Plantilla tipos de ingreso");
    setIsLoading(false);
  };

  const tiposDeEgresoTemplate = async () => {
    setIsLoading(true);
    const file = await downloadTypeConcepts("egresos");
    saveAs(file, "Plantilla tipos de egreso");
    setIsLoading(false);
  };

  const conceptosIngresosTemplate = async () => {
    setIsLoading(true);
    const file = await downloadIncomingsExcel();
    saveAs(file, "Plantilla conceptos de ingreso");
    setIsLoading(false);
  };

  const catalogoTemplate = async () => {
    setIsLoading(true);
    const file = await downloadCatalogueTemplate();
    saveAs(file, "Catalogo");
    setIsLoading(false);
  };

  const catalogoTemplateUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadTemplateCatalogue(excel);
    setIsUpdating(false);
    return wasUploaded;
  };

  const conceptosEgresosUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "setup/conceptos/egresos",
      "conceptos"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  const conceptosTypeEgresosUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "setup/tipos/conceptos/egresos",
      "conceptos"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  const conceptosIngresoUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "setup/conceptos/ingresos",
      "conceptos"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  const conceptosTypeIngresosUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "setup/tipos/conceptos/ingresos",
      "conceptos"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  const plantillaDirectorioUpload = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "directorio/cliente/masivo",
      "directorio"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  /**
   * Function in order to download any template 
   * @param {import("helpers/Apis/setUp/types").TypeTemplate} templateName - Template to download
   */
  const attemptDownloadTemplate = async (templateName) => {
    setIsLoading(true);
    const file = await downloadTemplate(
      templateName
    );
    saveAs(file, nameTemplate[templateName]);
    setIsLoading(false);
  };

    /**
   * Function in order to download any template 
   * @param {File|Blob} excel - Template to download
   * @param {string} url - Url to upload the template
   * @param {string} fileName - Name of the file on http request
   */
    const attemptUploadTemplate = async (excel,url,fileName) => {
      setIsUpdating(true);
      const wasUploaded = await uploadExcelTemplate(
        excel,
        url,
        fileName
      );
      setIsUpdating(false);
      return wasUploaded;
    };

  const uploadTemplateSectorMarket = async (excel) => {
    setIsUpdating(true);
    const wasUploaded = await uploadExcelTemplate(
      excel,
      "setup/sector-mercado",
      "sector"
    );
    setIsUpdating(false);
    return wasUploaded;
  };

  /**
   * @type {import("./types").DownloadsSetups}
   */
  const TEMPLATES = {
    ivas: {
      template: conceptosEgresosTemplate,
      upload: () => {},
    },
    contactos:{
      template:async() => await attemptDownloadTemplate("contacts"), 
      upload:async(excel)=>await attemptUploadTemplate(excel,'contactos/plantilla/excel','contactos')
    },
    directorio: {
      template: directorioTemplate,
      upload: plantillaDirectorioUpload,
    },
    conceptosEgresos: {
      template: conceptosEgresosTemplate,
      upload: conceptosEgresosUpload,
    },
    sectorMercado: {
      template:async() => await attemptDownloadTemplate("sectorMarket"),
      upload:uploadTemplateSectorMarket
    },
    tiposDeEgreso: {
      template: tiposDeEgresoTemplate,
      upload: conceptosTypeEgresosUpload,
    },
    tiposDeIngreso: {
      template: tiposDeIngresoTemplate,
      upload: conceptosTypeIngresosUpload,
    },
    conceptosIngresos: {
      template: conceptosIngresosTemplate,
      upload: conceptosIngresoUpload,
    },
    catalogo: {
      template: catalogoTemplate,
      upload: catalogoTemplateUpload,
    },
  };

  const iva = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const file = await downloadIvaExcelSetup();
    saveAs(file, "Plantilla IVA");
    setState({
      ...state,
      isLoading: false,
    });
  };

  const uen = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const file = await downloadUenExcelSetup();
    saveAs(file, "Plantilla UEN");
    setState({
      ...state,
      isLoading: false,
    });
  };

  const movimientos = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const file = await downloadMovementsExcelSetup();
    saveAs(file, "Plantilla Movimientos");
    setState({
      ...state,
      isLoading: false,
    });
  };

  /**
   * Update the iva with an excel
   * @param {Blob|File} excel
   */
  const updateSetupIva = async (excel) => {
    setState({
      ...state,
      isUpdating: true,
    });

    await updateIvaSetup(excel);

    setState({
      ...state,
      isUpdating: false,
    });
  };

  /**
   * Update the uen table with an excel file
   * @param {Blob|File} excel - Excel file
   */
  const updateSetupUen = async (excel) => {
    setState({
      ...state,
      isUpdating: true,
    });

    await updateUenSetup(excel);

    setState({
      ...state,
      isUpdating: false,
    });
  };

  /**
   * Update the uen table with an excel file
   * @param {Blob|File} excel - Excel file
   */
  const attemmptUploadMovements = async (excel) => {
    setState({
      ...state,
      isUpdating: true,
    });

    await uploadMovements(excel);

    setState({
      ...state,
      isUpdating: false,
    });
  };

  return {
    downloadIva: iva,
    downloadUen: uen,
    downloadMovementLoad: movimientos,
    attemmptUploadMovements,
    updateSetupIva,
    updateSetupUen,
    download: TEMPLATES[type].template,
    upload: TEMPLATES[type].upload,
    ...state,
  };
}
