import {
  showSpinner,
  hideSpinner,
} from "../../components/individual/Spinner/Spinner";
import React, { useEffect, useReducer } from "react";

import { Error } from "../../helpers/alerts";

import sweeta2 from "sweetalert2";
import axios from "axios";

import LoginContext from "./LoginContext";
import LoginReducer from "./LoginReducer";
import { URL_BASE } from "../../routes/routes";

import {
  RESET_PASSWORD,
  SET_SCREEN_LOGIN,
  LOG_USER,
  SET_PERMISSIONS,
  SET_SESSION_COOKIE,
  SET_FILTER,
  UPDATE_PROFILE_PICTURE,
  SET_USER_TO_REPRESENT,
  SHOW_OTP,
  TOGGLE_SPINNER,
  SET_BANNER,
} from "../../types/index";
import { getAppBanners } from "helpers/Apis/associateFilesV2";

const LoginState = (props) => {


  const initialState = {
    isFetchingServer:false,
    banner:{
      darkMode:null,
      lightMode:null
    },
    user: "notUser",
    userId: 0,
    userToRepresentId: 0,
    token: "",
    rolId: 0,
    rolPermitions: {},
    profilePicture: {
      scale: 1,
      position: {
        x: 0.5,
        y: 0.5,
      },
      img: null,
    },
    permitionsSession: [],
    screen: "login",
    userInfo: [],
    filter: {
      directoryIndex: {
        type: -1,
        status: 1,
        executive: 0,
      },
      documentsIndex: {
        executive: 0,
        document: -1,
        status: 1,
        noDocument: 0,
        rangeBegin: null,
        rangeEnd: null,
      },
    },
  };

  /* REDUCER */
  const [state, dispatch] = useReducer(LoginReducer, initialState);

  /**
   * Updates the logo for praxia application
   * @param {File} lightMode - File for light mode
   * @param {File} darkMode - File for darkmode
   */
  const setBanner = (lightMode,darkMode) => {
    dispatch({
      type: SET_BANNER,
      payload: {
        lightMode,
        darkMode
      },
    });
  };

  useEffect(()=>{
    (async function(){
      const bannerApp = await getAppBanners();

      setBanner(bannerApp.lightMode,bannerApp.darkMode);
    })();
  },[]);

  const toggleSpinner = () => dispatch({
    type:TOGGLE_SPINNER
  });

  /* FUNCTION THAT UPDATES THE STATE OF REACT
  AND REDIRECTS TO THE SYSTEM */
  const redirectUpdatedPass = async (user, password) => {
    const response = await axios.get(
      `${URL_BASE}/login/
      ${user}/
      ${password}`,
      {
        withCredentials: true,
      }
    );

    const { token } = response.data;

    dispatch({
      type: LOG_USER,
      payload: {
        user: user,
        token: token,
      },
    });
  };

  /* ROUTINE TO LOG-IN */
  const login = async (e, inputUserId, inputPassId, idSpinner) => {
    try {
      /* [1] Prevent send the form to avoid page refresh */
      e.preventDefault();

      hideSpinner("btnLogin");
      showSpinner(idSpinner);

      /* [2] Get values from the input */
      const _user = document.getElementById(inputUserId).value;
      const _password = document.getElementById(inputPassId).value;

      /* VALIDATIONS */

      // Username must be at least 8 characteres
      if (_user.length < 8) {
        sweeta2.fire({
          title: "Usuario debe contener 8 o mas caracteres",
          icon: "error",
        });
        hideSpinner(idSpinner);
        showSpinner("btnLogin");
        return;
      }

      // Password must be at least 10 characters
      if (_password.length < 10) {
        sweeta2.fire({
          title: "Contraseña debe contener 10 o mas caracteres",
          icon: "error",
        });
        hideSpinner(idSpinner);
        showSpinner("btnLogin");
        return;
      }

      try {
        showSpinner(idSpinner);
        /* [3] Send data to backend
      That properties of the json must not be changed */

        /* const response = await axios.get(`${URL_BASE}/login/
      ${_user}/
      ${_password}`); */

        const response = await axios.get(
          `${URL_BASE}login/
      ${_user}/
      ${_password}`,
          {
            withCredentials: true,
          }
        );

        const { token, status, message, userInfo } = response.data;

        /* VALIDATIONS
      User put something bad or the server had an error
      */
        hideSpinner(idSpinner);
        showSpinner("btnLogin");

        if (status === 403 || status === 404) {
          sweeta2.fire({
            title: message,
            icon: "error",
          });
          return;
        }

        /* User requested a reset password
      Change the view to allow him update it */
        if (status === 1) {
          /* [4] Set the user and screen to display
        to restart the password */
          dispatch({
            type: RESET_PASSWORD,
            payload: {
              screen: "recover",
              user: _user,
            },
          });
          return;
        }

        /* Nothing of that happened
      That means that the validations were correct
      and the server responded with a 200
      Log the user into system */

        dispatch({
          type: LOG_USER,
          payload: {
            userInfo: userInfo,
            user: _user,
            token: token,
          },
        });
      } catch (error) {
        hideSpinner(idSpinner);
        showSpinner("btnLogin");

        Error(() => {}, error);
      }
    } catch (e) {
      console.log("error del iniciar sesions", e);
    }
  };
  const loginV2 = async (e, inputUserId, inputPassId, idSpinner) => {
    try {
      /* [1] Prevent send the form to avoid page refresh */
      e.preventDefault();
      // hideSpinner("btnLogin");
      // showSpinner(idSpinner);

      /* [2] Get values from the input */
      const _user = document.getElementById(inputUserId).value;
      const _password = document.getElementById(inputPassId).value;

      /* VALIDATIONS */

      // Username must be at least 8 characteres
      if (_user.length < 8) {
        sweeta2.fire({
          title: "Usuario debe contener 8 o mas caracteres",
          icon: "error",
        });
        return;
      }

      // Password must be at least 10 characters
      if (_password.length < 10) {
        sweeta2.fire({
          title: "Contraseña debe contener 10 o mas caracteres",
          icon: "error",
        });
        return;
      }

      try {
        toggleSpinner();        /* [3] Send data to backend
      That properties of the json must not be changed */

        /* const response = await axios.get(`${URL_BASE}/login/
      ${_user}/
      ${_password}`); */

        /**
         * @type {import("axios").AxiosResponse<import("types/typedef/context/LoginContext").ResponseLogin>}
         */
        const response = await axios.post(
          `${URL_BASE}login/`,
          {
            usuario: _user,
            pass: _password,
          },
          {
            withCredentials: true,
          }
        );

        const { token, status, message, userInfo } = response.data;
        

        if (status === 200) {
          toggleSpinner();
          dispatch({
            type: SHOW_OTP,
            payload: _user,
          });
          return;
        }

        /* VALIDATIONS
      User put something bad or the server had an error
      */
      toggleSpinner();

        if (status === 403 || status === 404) {
          sweeta2.fire({
            title: message,
            icon: "error",
          });
          return;
        }

        /* User requested a reset password
      Change the view to allow him update it */
        if (status === 1) {
          /* [4] Set the user and screen to display
        to restart the password */
          dispatch({
            type: RESET_PASSWORD,
            payload: {
              screen: "recover",
              user: _user,
            },
          });
          toggleSpinner();
          return;
        }

        /* Nothing of that happened
      That means that the validations were correct
      and the server responded with a 200
      Log the user into system */


        dispatch({
          type: LOG_USER,
          payload: {
            userInfo: userInfo,
            user: _user,
            token: token,
          },
        });
        toggleSpinner();
      } catch (error) {
        toggleSpinner();

        Error(() => {}, error.response.data.message);
      }
    } catch (e) {
      console.log("error del iniciar sesions", e);
    }
  };

  /* ROUTINE TO UPDATE THE USER PASSWORD
  AFTER HE RECEIVES BY EMAIL THE TEMPORAL PASSWORD */
  const recover = async (e) => {
    e.preventDefault();

    hideSpinner("btnUpdatePass");
    showSpinner("spinnerUpdatePass");
    // const password1 = `${e.target[0].value}`;
    // const password2 = `${e.target[1].value}`;

    const password1 = document.getElementById('confirmPass1').value;

    const password2 = document.getElementById('confirmPass2').value;

    if (password1.length < 10 || password2.length < 10) {
      sweeta2.fire({
        title: "Contraseña debe contener 10 o mas caracteres",
        icon: "error",
      });
      hideSpinner("spinnerUpdatePass");
      showSpinner("btnUpdatePass");
      return;
    }

    const isPasswordEquals = password2 === password1;

    if (isPasswordEquals === false) {
      sweeta2.fire({
        title: "Las contraseñas no coinciden",
        icon: "error",
      });
      hideSpinner("spinnerUpdatePass");
      showSpinner("btnUpdatePass");
      return;
    }

    try {
      const response = await axios.get(
        `${URL_BASE}usuarios/recuperarPass/${password1}/${state.user}`
      );

      showSpinner("spinnerUpdatePass");
      hideSpinner("btnUpdatePass");

      /* This means */
      sweeta2
        .fire({
          timer: 4000,
          timerProgressBar: true,
          title: response.data.message,
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        })
        .then(() => {
          redirectUpdatedPass(state.user, password1);
        });
    } catch (error) {
      sweeta2.fire({
        title: error,
        icon: "error",
      });
    }
  };

  /* ROUTINE WHEN THE USER REQUEST UPDATE HIS
  PASSWORD (EMAIL WITH TEMPORAL PASSWORD) */
  const forgotPassword = async (e, input) => {
    e.preventDefault();

    showSpinner("spinnerRecoverPass");
    hideSpinner("btnRecover");
    const valueInput = document.getElementById(input).value;

    /* 
    VALIDATIONS 

    [1] VALIDATE INPUT ITS MORE THAN 7 CHARACTERS */
    if (valueInput.length < 8) {
      sweeta2.fire({
        title: "Usuario debe contener 8 o mas caracteres",
        icon: "error",
      });
      hideSpinner("spinnerRecoverPass");
      showSpinner("btnRecover");
      return;
    }

    /* [2] SEND THE INFORMATION TO THE BACKEND */
    try {
      const result = await axios.get(
        `${URL_BASE}usuarios/olvide-password/${valueInput}`
      );

      const { status, message } = result.data;

      hideSpinner("spinnerRecoverPass");
      showSpinner("btnRecover");

      if (status !== 200) {
        sweeta2.fire({
          title: message,
          icon: "error",
        });

        return;
      }

      sweeta2
        .fire({
          title: message,
          icon: "success",
        })
        .then((promise) => {
          changeView("login");
        });
      return;
    } catch (error) {
      console.log(error);
    }
  };

  /* This function recives a string
  with an arbitrary string of the component that gonna be
  renderized
  For example: changeView('login')
  
  Also, you have to programm the component of that
  view thats gonna be renderized*/
  const changeView = (view) => {
    dispatch({
      type: SET_SCREEN_LOGIN,
      payload: view,
    });
  };

  const setPermissions = (permissions) => {
    dispatch({
      type: SET_PERMISSIONS,
      payload: permissions,
    });
  };

  const setSessionCookie = (info) => {
    dispatch({
      type: SET_SESSION_COOKIE,
      payload: info,
    });
  };

  /**
   * Update the profile picture of the account (just on memory, need to update on the database with another some code)
   * @param {import("structure/Profile/Picture/types").onSaveProfilePictureI} picture - Profile picture to use on the account
   * @returns {void}
   */
  const updateProfilePicture = (picture) =>
    dispatch({
      type: UPDATE_PROFILE_PICTURE,
      payload: picture,
    });

  const setFilter = (object) => {
    dispatch({
      type: SET_FILTER,
      payload: object,
    });
  };

  const handleOnRepresentativeExecutiveChange = (idExecutive) => {
    dispatch({
      type: SET_USER_TO_REPRESENT,
      payload: +idExecutive,
    });
  };

  const elementsFather = {
    isFetchingServer:state.isFetchingServer,
    user: state.user,
    token: state.token,
    screen: state.screen,
    userId: state.userId,
    userToRepresentId: state.userToRepresentId,
    userInfo: state.userInfo,
    rolPermitions: state.rolPermitions,
    rolId: state.rolId,
    filter: state.filter,
    dispatch,
    login,
    loginV2,
    recover,
    changeView,
    setPermissions,
    forgotPassword,
    setSessionCookie,
    setFilter,
    updateProfilePicture,
    profilePicture: state.profilePicture,
    handleOnRepresentativeExecutiveChange,
    banner:state.banner,
    setBanner
  };

  return (
    <LoginContext.Provider value={elementsFather}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginState;
