import styled from "styled-components";

export const NavToDoItem = styled.button`
  width: 100%;
  padding: 20px 0;
  color: var(--primaryColor);
  background: ${(props) =>
    props.isSelected ? "var(--primaryBackground)" : "var(--cardBackground)"};
  transition: all 0.25s ease;
  &:hover {
    background: var(--primaryBackground);
  }
`;

export const AddPersonal = styled.div`
  display: flex;
  justify-content: center;
`;

export const TypeTodo = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
