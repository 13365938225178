import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextDocuments } from "../..";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function Pdf() {
  const hook = useContext(ContextDocuments);

  if(!hook.canDownloadPdf) return <></>

  if (hook.isDownloadingPdf)
    return <Spinner text={"Descargando"} hidden={false} />;

  return (
    <ButtonV2 onClick={hook.downloadPdf}>
      <span>PDF</span>
      <Icon icon="pdf" />
    </ButtonV2>
  );
}
