import axios from "axios";
import { Error } from "helpers/alerts";
import { reorderComments } from "helpers/comments";
import { convertPrice, mnCurrency, truncateDecimals } from "helpers/money";
import { URL_BASE } from "routes/routes";
import { date } from "yup";

/**
 *
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} data
 */
export const transformData = (data) => {
  const dto = {
    autorizationFlag: 1,
    contact: data.contact,
    creditDays: 30,
    currency: data.moneyInfo.currency.value,
    expirationDate: data.generateContract.endDate,
    idCustomer: data.customer.id,
    // TODO
    // * Utilizar la funcion que se encargue de retornar la interface que pide el servidor
    // periocity: {
    //   end: state2.document.periocity.end,
    //   start: state2.document.periocity.begin,
    //   type:
    //     typeof state2.document.periocity.id === "string"
    //       ? null
    //       : state2.document.periocity.id,
    //   value: state2.document.periocity.value,
    // },
    // idPeriocityType:
    //   typeof state2.document.periocity.id === "string"
    //     ? null
    //     : state2.document.periocity.id,
    idProbability: data.moneyInfo.probability,
    iva:
      data.moneyInfo.currency.value === "MXN"
        ? data.moneyInfo.mxn.ivaSell.number
        : data.moneyInfo.usd.ivaSell.number,
    subtotal:
      data.moneyInfo.currency.value === "MXN"
        ? data.moneyInfo.mxn.importSell.number
        : data.moneyInfo.usd.importSell.number,
    totalAmount:
      data.moneyInfo.currency.value === "MXN"
        ? data.moneyInfo.mxn.totalSell.number
        : data.moneyInfo.usd.totalSell.number,
    // periocityValue: state2.document.periocity.value,
    tc: data.moneyInfo.tc.number,
    reminderDate: data.generateContract.reminderDate,
    items: data.items.map((item, i) => ({
      ...item,
      order: i + 1,
    })),
    // comments: reorderComments([
    //   ...state2.comments.regulars,
    //   ...state2.comments.notesAndConsiderations,
    // ]),
  };

  //   const wasAdded = await addQuote(dto);
};

/**
 * Adds a document
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} dto
 * @param {1|2|3|6} documentType Document type
 * @returns {Promise<number>} The document id
 */
export const addDocument = async (dto, documentType) => {
  try {
    const documentAdded = await axios.post(
      `${URL_BASE}documentos/agregar?documentType=${documentType}`,
      dto,
      {
        withCredentials: true,
      }
    );

    return documentAdded;
  } catch (error) {
    Error(() => {}, "No se pudo agregar el documento");
    return false;
  }
};

/**
 * Edit a document
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} dto
 * @param {1|2|3|6} documentType Document type
 * @returns {Promise<number>} The document id
 */
export const editDocument = async (dto, documentType) => {
  try {
    const documentAdded = await axios.put(
      `${URL_BASE}documentos/editar?documentType=${documentType}`,
      dto,
      {
        withCredentials: true,
      }
    );

    return documentAdded;
  } catch (error) {
    Error(() => {}, "No se pudo agregar el documento");
    return false;
  }
};

/**
 *
 * @param {number} idDocument Document id
 * @returns {Promise<import("axios").AxiosResponse<import("../../../../../../types/documentActions").GeneralDocumentI>>}
 */
export const getDocumentData = async (idDocument) => {
  try {
    const documentData = await axios.get(
      `${URL_BASE}documentos/data/${idDocument}`,
      {
        withCredentials: true,
      }
    );

    return documentData;
  } catch (error) {
    Error(() => {}, "No se pudo obtener el documento");
  }
};

/**
 * Transform the date to te format 01/ene/23
 * @param {string} date
 * @returns srting
 */
export const transformDate = (date) => {
  const dateHolder = new Date(date);
  const day =
    dateHolder.toLocaleDateString("en-US", { day: "numeric" }) < 10
      ? `0${dateHolder.toLocaleDateString("en-US", { day: "numeric" })}`
      : dateHolder.toLocaleDateString("en-US", { day: "numeric" });
  const month = dateHolder.toLocaleDateString("es-ES", { month: "short" });
  const year = dateHolder.toLocaleDateString("en-US", { year: "numeric" });
  const placeHolder = `${day}/${month}/${year}`;
  return placeHolder;
};

/**
 * obtiene el total
 * @param {import("../../../../../../types/documentActions").ItemSelectI} item
 * @param {'mxn'|'usd'} currency
 * @param {'pu'|'cu'} type
 * @returns {number}
 */
function calculateTotal(item, currency, type) {
  return item[currency][type].total.number;
}

/**
 * obtiene el total
 * @param {import("../../../../../../types/documentActions").ItemSelectI[]} items
 * @param {'mxn'|'usd'} currency
 * @param {'pu'|'cu'} type
 * @returns {number}
 */
export function sumTotal(items, currency, type) {
  if (items.length <= 0) return 0;

  return items
    .map((item) => calculateTotal(item, currency, type))
    .reduce((accumulator, item) => accumulator + item);
}

/**
 *
 * @param {any} document
 * @param {string} currency
 * @returns {number}
 */
export function calculateMargin(document, currency) {
  return document.items
    .map((item) => {
      const isCurrencyMatch = document.moneyInfo.currency.value === currency;
      const exchangeRate = document.moneyInfo.tc.number;
      return isCurrencyMatch
        ? item.realMargin
        : currency === "MXN"
        ? item.realMargin / exchangeRate
        : item.realMargin * exchangeRate;
    })
    .reduce((accumulator, item) => {
      return truncateDecimals(accumulator + item, 2);
    });
}

/**
 *
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} document
 * @param {"MXN"|"USD"} documentCurrency
 */
export function calculateDocumentMargin(document, documentCurrency) {
  const items = document.items;

  if (items.length <= 0) return 0;

  return items
    .map((item) => {
      const isMatchCurrency = item.catalogue.currency.code === documentCurrency;
      const exchangeRate = document.moneyInfo.tc.number;
      return currencyConvertion(
        documentCurrency,
        isMatchCurrency,
        exchangeRate,
        item
      );
    })
    .reduce((accumulator, item) => {
      return truncateDecimals(accumulator + item, 2);
    });
}

/**
 *
 * @param {"MXN"|"USD"} documentCurrency
 * @param {boolean} isMatchCurrency
 * @param {number} tc
 * @param {import("../../../../../../types/documentActions").ItemSelectI} item
 */
function currencyConvertion(documentCurrency, isMatchCurrency, tc, item) {
  /**
   *
   * @type{"mxn"|"usd"}
   */
  // @ts-ignore
  const currency = documentCurrency.toLowerCase();
  if (isMatchCurrency) {
    return (
      calculateTotal(item, currency, "pu") -
      calculateTotal(item, currency, "cu")
    );
  } else {
    if (documentCurrency === "MXN") {
      return (
        calculateTotal(item, "usd", "pu") * tc -
        calculateTotal(item, "usd", "cu") * tc
      );
    } else {
      return (
        calculateTotal(item, "mxn", "pu") / tc -
        calculateTotal(item, "mxn", "cu") / tc
      );
    }
  }
}

export const chanChangeCurrency = async (idDocument) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/poder/cambiar/moneda?idDocument=${idDocument}`,
      {
        withCredentials: true,
      }
    );

    return data.couldChange;
  } catch (error) {
    Error(() => {}, "No se pudo obtener la iformación.");
    return false;
  }
};

/**
 *  Transforma un valor a un objeto con el valor y el texto
 * @param {number} value
 * @param {number} [decimals=2] - Decimals to use in case it's needed to round for accounting purposes
 * @returns {{number: number, text: string}}
 */
export const transformToNumberAndCurrency = (value, decimals = 4) => {
  // Significa que no va a cambiar de moneda
  return {
    number: +value.toFixed(decimals),
    text: mnCurrency(value),
  };
};

/**
 *
 * @param {import("structure/FormDocumentItems/types").DocumentItemForm} formData - Information of the item from the form
 * @param {number} tc - Currency to change between items
 * @param {number} decimals - Number of decimals to use
 * @param {import("./types").DocumentCalculationParam} [document=null] - Document information
 */
export function handleFormItem(
  formData,
  tc = 18.8428,
  decimals = 2,
  document = null
) {
  const useIva0AsDefault =
    document === null
      ? false
      : document.rfc === "XEXX010101000" && document.typeDocument === 3;

  const ivaToUse = useIva0AsDefault ? 0 : formData.iva;

  const quantity = formData.quantity;

  const puPercentageDiscount = truncateDecimals(
    formData.discountSell / 100,
    decimals
  );

  const cuPercentageDiscount = truncateDecimals(
    formData.discountCost / 100,
    decimals
  );

  const puDiscount = truncateDecimals(
    puPercentageDiscount * formData.sell,
    decimals
  );
  const cuDiscount = truncateDecimals(
    cuPercentageDiscount * formData.cost,
    decimals
  );

  const puIVA = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * formData.sell,
    decimals
  );

  const cuIVA = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * formData.cost,
    decimals
  );

  const puSell = truncateDecimals(
    formData.sell * truncateDecimals(1 - puPercentageDiscount),
    decimals
  );

  const cuCost = truncateDecimals(
    formData.cost * truncateDecimals(1 - cuPercentageDiscount),
    decimals
  );

  const puTotal = truncateDecimals(puSell * quantity, decimals);
  const cuTotal = truncateDecimals(cuCost * quantity, decimals);

  const sellIvaTotal = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * puTotal,
    decimals
  );
  const costIvaTotal = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * cuTotal,
    decimals
  );

  //////////////////////////////////////////////////

  // Recalcular todo tomando como base el cambio de moneda del importe pedido [PU - MXN]
  const puMxn = convertPrice(
    formData.sell,
    tc,
    formData.currency,
    "MXN",
    decimals
  );
  const puIVAMxn = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * puMxn,
    decimals
  );
  const puTotalMxn = truncateDecimals(puMxn * quantity, decimals);
  const sellIvaTotalMxn = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * puTotalMxn,
    decimals
  );
  const puDiscountMxn = truncateDecimals(
    puPercentageDiscount * puMxn,
    decimals
  );
  const puSellMxn = truncateDecimals(
    puMxn * truncateDecimals(1 - puPercentageDiscount),
    decimals
  );

  // Recalcular todo tomando como base el cambio de moneda del importe pedido [CU - MXN]
  const cuMxn = convertPrice(
    formData.cost,
    tc,
    formData.currency,
    "MXN",
    decimals
  );
  const cuIVAMxn = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * cuMxn,
    decimals
  );
  const cuTotalMxn = truncateDecimals(cuMxn * quantity, decimals);
  const costIvaTotalMxn = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * cuTotalMxn,
    decimals
  );
  const cuDiscountMxn = truncateDecimals(
    cuPercentageDiscount * cuMxn,
    decimals
  );
  const cuSellMxn = truncateDecimals(
    cuMxn * truncateDecimals(1 - cuPercentageDiscount),
    decimals
  );

  // Recalcular todo tomando como base el cambio de moneda del importe pedido [PU - USD]
  const puUsd = convertPrice(
    formData.sell,
    tc,
    formData.currency,
    "USD",
    decimals
  );
  const puIVAUsd = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * puUsd,
    decimals
  );
  const puTotalUsd = truncateDecimals(puUsd * quantity, decimals);
  const sellIvaTotalUsd = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * puTotalUsd,
    decimals
  );
  const puDiscountUsd = truncateDecimals(
    puPercentageDiscount * puUsd,
    decimals
  );
  const puSellUsd = truncateDecimals(
    puUsd * truncateDecimals(1 - puPercentageDiscount),
    decimals
  );

  // Recalcular todo tomando como base el cambio de moneda del importe pedido [CU - MXN]
  const cuUsd = convertPrice(
    formData.cost,
    tc,
    formData.currency,
    "USD",
    decimals
  );
  const cuIVAUsd = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * cuUsd,
    decimals
  );
  const cuTotalUsd = truncateDecimals(cuUsd * quantity, decimals);
  const costIvaTotalUsd = truncateDecimals(
    truncateDecimals(ivaToUse / 100) * cuTotalUsd,
    decimals
  );
  const cuDiscountUsd = truncateDecimals(
    cuPercentageDiscount * cuUsd,
    decimals
  );
  const cuSellUsd = truncateDecimals(
    cuUsd * truncateDecimals(1 - cuPercentageDiscount),
    decimals
  );
  ////////////////////////////////////////////////

  /**
   * @type {import("../Items/types").CatalogueOperation}
   */
  const itemToUse = {
    quantity: formData.quantity,
    isBy: typeof formData.idCatalogue !== "number" ? "none" : "sku",
    providerDiscount: {
      number: formData.discountCost,
      text: `${formData.discountCost}%`,
    },
    clientDiscoount: {
      number: formData.discountSell,
      text: `${formData.discountSell}%`,
    },
    id: formData.idCatalogue,
    description: formData.description,
    pu: transformToNumberAndCurrency(formData.sell),
    cu: transformToNumberAndCurrency(formData.cost),
    satCode: formData.satCode,
    satCodeDescription: formData.satCodeDescription,
    satUm: formData.satUm,
    satUmDescription: formData.satUmDescription,
    iva: {
      number: formData.iva,
      text: `${formData.iva}%`,
      exempt: formData.ivaExempt,
    },
    sku: formData.sku,
    uen: {
      // description:formData.uen,
      description: "??",
      family: "??",
      id: formData.uen,
    },
    currency: {
      code: formData.currency,
      symbol: "$",
      description:
        formData.currency === "MXN" ? "Peso Mexicano" : "Dolar Americano",
    },
    label: formData.sku,
    value: formData.idCatalogue,
    catalogue: {
      id: formData.idCatalogue,
      description: formData.sku,
      cu: formData.cost,
      pu: formData.sell,
      iva: formData.iva,
      currency: {
        code: formData.currency,
        symbol: "$",
        description:
          formData.currency === "MXN" ? "Peso Mexicano" : "Dolar Americano",
      },
    },
    uuid:
      typeof formData.idCatalogue === "number"
        ? window.crypto.randomUUID()
        : formData.idCatalogue,
    mxn: {
      pu: {
        number: puMxn,
        text: mnCurrency(puMxn),
        iva: transformToNumberAndCurrency(puIVAMxn),
        totalIva: transformToNumberAndCurrency(sellIvaTotalMxn),
        discount: transformToNumberAndCurrency(puDiscountMxn),
        totalUnit: transformToNumberAndCurrency(puSellMxn),
        total: transformToNumberAndCurrency(puTotalMxn),
        realUnit: transformToNumberAndCurrency(puTotalMxn),
      },
      cu: {
        number: cuMxn,
        text: mnCurrency(cuMxn),
        iva: transformToNumberAndCurrency(cuIVAMxn),
        totalIva: transformToNumberAndCurrency(costIvaTotalMxn),
        discount: transformToNumberAndCurrency(cuDiscountMxn),
        totalUnit: transformToNumberAndCurrency(cuSellMxn),
        total: transformToNumberAndCurrency(cuTotalMxn),
        realUnit: transformToNumberAndCurrency(cuTotalMxn),
      },
    },
    usd: {
      pu: {
        number: puUsd,
        text: mnCurrency(puUsd),
        iva: transformToNumberAndCurrency(puIVAUsd),
        totalIva: transformToNumberAndCurrency(sellIvaTotalUsd),
        discount: transformToNumberAndCurrency(puDiscountUsd),
        totalUnit: transformToNumberAndCurrency(puSellUsd),
        total: transformToNumberAndCurrency(puTotalUsd),
        realUnit: transformToNumberAndCurrency(puTotalUsd),
      },
      cu: {
        number: cuUsd,
        text: mnCurrency(cuUsd),
        iva: transformToNumberAndCurrency(cuIVAUsd),
        totalIva: transformToNumberAndCurrency(costIvaTotalUsd),
        discount: transformToNumberAndCurrency(cuDiscountUsd),
        totalUnit: transformToNumberAndCurrency(cuSellUsd),
        total: transformToNumberAndCurrency(cuTotalUsd),
        realUnit: transformToNumberAndCurrency(cuTotalUsd),
      },
    },
  };

  return itemToUse;
}

// const documentDates=(action,documentType)=>{
//   if(action==='Ver'){
//     return {
//       initialDate: {
//         label: "Registro",
//         placeHolder: placeHolder,
//         disable: false,
//         date: today,
//         minDate: null,
//         maxDate: null,
//       },
//       endDate: {
//         label: "Vigencia",
//         placeHolder: endPlaceHolder,
//         disable: false,
//         date: today,
//         minDate: null,
//         maxDate: null,
//       },
//       reminderDate: {
//         label: "Recordatorio",
//         placeHolder: "",
//         disable: true,
//         date: new Date(today.setDate(today.getDate() + 1)),
//         minDate: new Date(today.setDate(today.getDate() + 1)),
//         maxDate: lastDayOfMonth,
//       }
//   }
// }else{
//   switch (key) {
//     case value:

//       break;

//     default:
//       break;
//   }
// }
// }
