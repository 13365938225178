import axios from "axios";
import { DisplayError, Error, HtmlAlert, Success } from "helpers/alerts";
import { URL_BASE } from "../../routes/routes";
import { isValidHttpResCode } from "./fetch";

/**
 * Get the list of ivas available for the system
 * @returns {Promise<import("../../../../server/types/Parameters").IvasAvailable>}
 */
export async function getIvasAvailables() {
  try {
    const { data } = await axios.get(`${URL_BASE}parametros/ivas`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    DisplayError(error);
    return {
      canUseExempt: false,
      canUseIva0: false,
      canUseIva8: false,
    };
  }
}

/**
 * Get the notes or considerations from the system
 * @param {"notas"|"consideraciones"|"todas"} type - Type of comments to get
 * @returns {import("types/typedef/customHooks/useNotesAndAclarations").NoteConsiderationI[]} Notes and considerations got
 */
export async function getNotesOrConsiderations(type = "notas") {
  try {
    /**
     * Notes and considerations got from the server
     * @type {{data:import("types/typedef/customHooks/useNotesAndAclarations").NoteConsiderationI[]}}
     */
    const { data } = await axios.get(
      `${URL_BASE}parametros/obtener/todas/notas`,
      {
        withCredentials: true,
      }
    );

    const activeData = data.filter(
      (note) => note.is.active === 1 && note.type >= 1 && note.type <= 2
    );

    if (type === "todas") {
      return activeData;
    }

    const typeToFilter = type === "notas" ? 1 : 2;
    const filteredElements = activeData.filter(
      (comment) => comment.type === typeToFilter
    );
    return filteredElements;
  } catch (error) {}
}

/**
 * Get the notes and considerations from a document type
 * @param {number} idDocument - Id of the document
 * @returns {import("types/typedef/customHooks/useNotesAndAclarations").NoteConsiderationI[]} Notes and considerations got
 */
export async function getNotesAndConsiderationsByDocument(idDocument = 1) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}parametros/obtener/notas-documento/${idDocument}`,
      {
        withCredentials: true,
      }
    );

    const visibleNotesAndConsiderations = data.filter(
      (note) => note.type !== 0
    );

    return visibleNotesAndConsiderations.map((note) => ({
      ...note,
      tempContent: note.content,
      is: {
        ...note.is,
        editing: false,
      },
    }));
  } catch (error) {}
}

/**
 * Add the note and condition into system
 * @param {import("types/typedef/customHooks/useNotesAndAclarations").DtoAddCondition} dto - Information to send to backend
 * @returns {import("types/typedef/customHooks/useNotesAndAclarations").NoteConsiderationResI} Information of the attempt request
 */
export async function addNoteConsideration(dto) {
  try {
    /**
     * Sucess info
     * @type {{data:import("types/typedef/customHooks/useNotesAndAclarations").ResAddConditionI}}
     */
    const { data } = await axios.post(
      `${URL_BASE}parametros/agregar/notas-considraciones`,
      {
        DtoNoteAndCondition: dto,
      },
      {
        withCredentials: true,
      }
    );

    return {
      wasSuccess: true,
      message: data.message,
    };
  } catch (error) {
    /**
     * Error
     * @type {import("types/typedef/customHooks/useNotesAndAclarations").ResAddConditionI}
     */
    const data = error.response.data;

    Error(() => {}, data.message);

    return {
      wasSuccess: false,
      message: data.message,
    };
  }
}

/**
 * Update the note/consideration
 * @param {import("types/typedef/customHooks/useNotesAndAclarations").DtoAddCondition} dto - Information to send to backend
 */
export async function updateNoteConsideration(dto) {
  try {
    /**
     * Sucess info
     * @type {{data:import("types/typedef/customHooks/useNotesAndAclarations").ResAddConditionI}}
     */
    const { data } = await axios.put(
      `${URL_BASE}parametros/actualizar/notas-considraciones`,
      {
        DtoNoteAndCondition: dto,
      },
      {
        withCredentials: true,
      }
    );

    return {
      wasSuccess: true,
      message: data.message,
    };
  } catch (error) {
    /**
     * Error
     * @type {import("types/typedef/customHooks/useNotesAndAclarations").ResAddConditionI}
     */
    const data = error.response.data;

    Error(() => {}, data.message);

    return {
      wasSuccess: false,
      message: data.message,
    };
  }
}

/**
 * Get the rfc of the enterprise
 * @returns {string|null} Rfc of the enterprise
 */
export async function getRfcEmitter() {
  try {
    const apiRfc = await fetch(`${URL_BASE}parametros/rfc-emisor`);

    const rfc = await apiRfc.json();

    return rfc["rfcEmitter"];
  } catch (error) {
    return null;
  }
}

/**
 * Get the tolerance for the movements association.
 * More information: {@link https://hopeful-austin-14de9a.netlify.app/#/Parameters/get_parametros_tolerancia_asociacion}
 * @async
 * @returns {Promise<number>} Tolerance allowed for the association
 *
 */
export async function getMovementAssociationTolerance() {
  try {
    const { data } = await axios.get(
      `${URL_BASE}parametros/tolerancia-asociacion`,
      {
        withCredentials: true,
      }
    );

    return data.tolerance;
  } catch (error) {
    return 0;
  }
}

/**
 * Get the tolerance when associate invoice reception
 * @returns {number}
 */
export async function getInvoiceReceptionTolerance() {
  try {
    const {
      data: { tolerance },
    } = await axios.get(`${URL_BASE}parametros/tolerancia-xml`, {
      withCredentials: true,
    });

    return tolerance;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
  }
}

/**
 *
 * Get the email of the enterprise
 * @returns {string|null}
 */
export async function getEmailEnterprise() {
  try {
    const apiEmail = await fetch(`${URL_BASE}parametros/email-empresa`);

    const email = await apiEmail.json();

    return email["emailEnterprise"];
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Get the postal code of the enterprise from the variables
 * @returns {number} Postal code of the enterprise
 */
export async function getCpEnterprise() {
  try {
    const apiCp = await fetch(`${URL_BASE}parametros/cp-empresa`);

    const cp = await apiCp.json();

    return cp["cp"];
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Get the reminder days for the documents
 * @returns {(number|8)} 8 in case the query wasn't success
 */
export async function getReminderDays() {
  try {
    const apiDays = await fetch(`${URL_BASE}parametros/dias-recordatorio`);

    const days = await apiDays.json();

    return days["reminderDays"];
  } catch (error) {
    console.log(error);
    return 8;
  }
}

/**
 * Get the validations to send revision a invoice request
 *
 * @returns {{
 *  partialities:number,
 *  variance:number
 * }}
 */
export async function getInvoiceValidations() {
  try {
    const apiValidations = await fetch(
      `${URL_BASE}parametros/facturacion/validaciones`
    );

    const { partialities, variance } = await apiValidations.json();

    return {
      partialities,
      variance,
    };
  } catch (error) {
    console.log(error);
    return 1;
  }
}

export async function getParameters() {
  try {
    const apiParameters = await fetch(`${URL_BASE}parametros`);

    const parameters = await apiParameters.json();

    return parameters;
  } catch (e) {
    Error(() => {},
    "No se pudo cargar el modulo de parametros. Intenta mas tarde");
  }
}

/**
 * Update the parameter from the database
 * @param {number} idParameter - Id of the parameter to update
 * @param {number|string|boolean} value - New value to use on the parameter
 * @returns {boolean} True if parameter was updated
 */
export async function updateParameter(idParameter, value) {
  try {
    const apiToExecute = {
      1: async () => await updateExpirationDays(value),
      9: async () => await updateRfcEnterprise(value),
    };

    const existFunction = Object.keys(apiToExecute).includes(idParameter);

    if (existFunction) {
      const wasUpdated = await apiToExecute[idParameter]();

      if (wasUpdated) {
        Success(() => {}, "Parametro actualizado");
      }

      return wasUpdated;
    }

    Error(() => {}, "En desarrollo");
    return false;
  } catch (error) {
    const { errors, htmlToRender } = error;

    HtmlAlert(
      {
        html: htmlToRender,
        title: "Error al actualizar parametro",
      },
      "error"
    );
    return false;
  }
}

/**
 * Update the expiration days from the parameters
 * @param {number} expirationDays - Expiration days
 * @returns {boolean} True if the item could be updated
 */
export async function updateExpirationDays(expirationDays) {
  try {
    await axios.put(`${URL_BASE}parametros/dias-vencimiento`, {
      expirationDays,
    });

    return true;
  } catch (error) {
    const { response } = error;
    throw {
      errors: response.data.errors,
      htmlToRender: response.data.htmlErrors,
    };
  }
}

/**
 * Update the rfc of the enterprise
 * @param {string} rfc - New rfc to use
 * @returns {boolean}
 */
export async function updateRfcEnterprise(rfc) {
  try {
    await axios.put(`${URL_BASE}parametros/rfc-empresa`, {
      rfc,
    });

    return true;
  } catch (error) {
    const { response } = error;
    throw {
      errors: response.data.errors,
      htmlToRender: response.data.htmlErrors,
    };
  }
}

/**
 * Get the default value on the combo when the document it's being created
 * @param {import("types/typedef/parameters").ComboOptions} type - Types of combo that can be fetched
 * @returns {import("types/typedef/parameters").DefaultValueInvoiceComboI} Information of the default value on the combo
 */
export async function getInvoiceComboDefValue(type) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}parametros/pedido/combo?tipo=${type}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return {
      value: {
        id: null,
        code: null,
      },
      description: null,
    };
  }
}

/**
 * Get the variations of the bank close month in order to allow close the month
 * @returns {Promise<import("../../../../server/models/parametersType").CloseMonthVariation>}
 */
export async function getCloseMonthVariations() {
  /**
   * @type {import("../../../../server/models/parametersType").CloseMonthVariation}
   */
  const ERROR_RESPONSE = {
    MXN: 0,
    USD: 0,
  };

  try {
    const res = await fetch(`${URL_BASE}parametros/cierre-mes`, {
      credentials: "include",
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return ERROR_RESPONSE;
  } catch (error) {
    Error(() => {}, error.message);
    return ERROR_RESPONSE;
  }
}

/**
 * Get the parametes for the invoice emition
 * @returns {Promise<import("../../../../server/models/parametersType").InvoiceParameters>}
 */
export async function getInvoiceParameters() {
  /**
   * @type {import("../../../../server/models/parametersType").InvoiceParameters}
   */
  const ERROR_RESPONSE = {
    partialitiesAllowed: 1,
    tcTolerance: 0,
  };

  try {
    const res = await fetch(`${URL_BASE}parametros/facturacion`, {
      credentials: "include",
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return ERROR_RESPONSE;
  } catch (error) {
    Error(() => {}, error.message);
    return ERROR_RESPONSE;
  }
}

/**
 * Get the parametes for the invoice emition
 * @param {Promise<import("../../../../server/models/parametersType").InvoiceParameters>}
 */
export async function updateInvoiceParameters(dto) {
  try {
    const res = await fetch(`${URL_BASE}parametros/facturacion`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dto }),
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      Success(()=>{},data.message)
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}
