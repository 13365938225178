import React, { createContext } from "react";
import usePaymentReminder from "./usePaymentReminder";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import Filter from "./Filter";
import ui from "./styles.module.scss";
import TableReminderPayments from "./TableReminderPayments";
import MoreInfo from "./MoreInfo";
import { getClickedColumn } from "helpers/dom";

/**
 * @type {import("react").Context<import("./usePaymentReminder/types").ReturnUsePaymentReminder|undefined>}
 */
export const ContextPaymentReminder = createContext(undefined);

export default function PaymentReminder() {
  const hook = usePaymentReminder();

  return (
    <ContextPaymentReminder.Provider value={hook}>
      <BreadCrumV2 path={hook.breadcrum} />

      <div className={ui.module}>
        <Filter />

        <MoreInfo />

        <TableReminderPayments
          data={hook.reminders}
          columns={hook.columns}
          paginationPerPage={hook.rowsPerPage}
          paginationTotalRows={hook.pages * hook.rowsPerPage}
          progressPending={hook.isLoading}
          selectableRowSelected={(invoice) => invoice === hook.reminderSelected}
          onRowClicked={(invoice, e) =>
            hook.setInvoice(invoice, getClickedColumn(e.target))
          }
          onChangeRowsPerPage={hook.setRowsPerPage}
          onChangePage={hook.setPage}
          sortServer
          onSort={sort=>hook.handleSort(+sort.id)}
          defaultSortAsc={false}
          defaultSortFieldId={1}
        />
      </div>
    </ContextPaymentReminder.Provider>
  );
}
