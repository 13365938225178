import { Spinner } from "components/individual/Spinner/Spinner";
import React, { createContext, useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import ui from "./styles.module.scss";
import { getTagsPaymentReminder, updateTag } from "helpers/Apis/Documents";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

/**
 * @type {import("react").Context<undefined|import("./types").ContextTagPaymentReminder>}
 */
const ContextComboPaymentReminder = createContext(undefined);

/**
 * @type {import("./types").StateTagsPaymentReminder}
 */
const INITIAL_STATE = {
  isLoading: true,
  tags: [],
  isUpdating: false,
  currentTag: null,
};

/**
 * Render a input for the tags of the payment reminders
 * @param {import("./types").PropsTagPaymentReminderSelect} props - Props
 * @returns {JSX.Element}
 */
export default function TagPaymentReminder({
  children = <></>,
  defaultTag = null,
  idInvoice = null,
  onUpdated = () => {}
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const tags = await getTagsPaymentReminder();

      const parsedTags = tags.map(tag=>({
        ...tag,
        value:tag.id,
        label:tag.description
      }))

      setState((current) => ({
        ...current,
        isLoading: false,
        tags:parsedTags,
      }));
    })();
  }, []);

  /**
   * Handle the change of the select combo information
   * @param {import("./types").OnChangeTag} option
   * @returns {import("../../../../../../../types/reminderPayments").TagReminderI}
   */
  const handleOnChange = (option) => {

    const optionToUse = option === null ? { id:null , description:"" , value: null , label:"" } : option;

    let parsed = { ...optionToUse };

    const isNewItem = Object.keys(parsed).includes("__isNew__");

    const idToUse = isNewItem ? null : +optionToUse.value === 0 ? null : +optionToUse.value

    setState((current) => ({
      ...current,
      currentTag: {
        ...optionToUse,
        id: idToUse,
        descripcion: optionToUse.label,
        value: idToUse,
      },
    }));

    /**
     * @type {import("../../../../../../../types/reminderPayments").TagReminderI}
     */
    const item = {
      descripcion: optionToUse.label,
      id: idToUse,
    };

    return item;
  };

  const attemptUpdateTag = async () => {
    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    const wasUpdated = await updateTag(
      idInvoice,
      state.currentTag.label,
      state.currentTag.value
    );

    if(wasUpdated) onUpdated()

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));
  };

  return (
    <ContextComboPaymentReminder.Provider
      value={{ ...state, handleOnChange, attemptUpdateTag }}
    >
      {children}
    </ContextComboPaymentReminder.Provider>
  );
}

/**
 * Render a select, with no creatable functionality
 * @param {import("./types").PropsNoCreatable} props - Props
 * @returns {JSX.Element}
 */
export function NoCreatable({ isClearable = false, onChange = () => {} }) {
  const hook = useContext(ContextComboPaymentReminder);

  return (
    <div className={ui.tag}>
      <b>Tag</b>
      <Select
        isClearable={isClearable}
        classNamePrefix="saiko-select"
        className="saiko-select"
        placeholder="Todos"
        onChange={(tag) => onChange(hook.handleOnChange(tag))}
        options={hook.tags}
      />
    </div>
  );
}

/**
 * Render a creatable select for the tags. Works for update or create new tags into system
 * @param {import("./types").PropsCreatable} prsop - Props
 * @returns {JSX.Element}
 */
export function Creatable({ isClearable = false, onChange = () => {} }) {
  const hook = useContext(ContextComboPaymentReminder);

  return (
    <div className={ui.tag}>
      <b>Tag</b>
      <CreatableSelect
        classNamePrefix="saiko-select"
        className="saiko-select"
        placeholder="Selecciona o escribe un tag"
        options={hook.tags}
        isClearable={isClearable}
        onChange={(tag) => onChange(hook.handleOnChange(tag))}
      />
    </div>
  );
}

/**
 * Button in order to udpate a tag
 * @returns {JSX.Element}
 */
export function UpdateTagReminder() {
  const hook = useContext(ContextComboPaymentReminder);

  if (hook.isUpdating) return <Spinner text={"Actualizando"} hidden={false} />;

  return (
    <ButtonV2
      onClick={hook.attemptUpdateTag}
      disabled={hook.currentTag === null || hook.currentTag === undefined}
    >
      <span>Actualizar</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
