import styled from "styled-components";

export const ContainerItem = styled.label`
  transition: background 0.25s ease-in-out;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e4e4e4;
  }
  margin: 0 0 10px 0;
  display: grid;
  align-items: center;
  grid-template-columns: 10% 65% 25%;
  p {
    margin: 0;
  }
  font-weight: normal;
`;

export const Header = styled.div`
  display: grid;
  padding: 10px;
  grid-template-columns: 10% 65% 25%;
  text-align: left;
`;
