import React, { useState, useContext, useEffect } from "react";

// LIBS
import { DefaultTable } from "js-smart-table/dist/pagination";

// CONTEXT
import TableContext from "../../../../context/Table/TableContext";

// COMPONENTS
import { CustomTable } from "../../../../components/general/Table/CustomTable";
import OCOptions from "./OCOptions";

// HELPERS
import ConfigTable from "../../../../static/StaticTables/OC";

// APIS
import { fetchDocuments } from "../../../../helpers/Apis/Documents";

import useDocuments from "../../../../customHooks/useDocuments";
import { CancelModal } from "../CancelModal";

export default function OC() {
  // let TableQuotes;

  // const { notifyDocumentsWithFiles, shortCutAssociateFiles, prepareExcelData } =
  //   useDocuments();

  // const [rowInfo, setRowInfo] = useState(null);

  // const { refetch, setLengthData } = useContext(TableContext);

  // let TableQuotesConfig = {
  //   ...ConfigTable,
  //   cbSelection: () => setRowInfo(TableQuotes.infoRow),
  // };

  // useEffect(() => {
  //   setRowInfo(null);

  //   async function initialLoad() {
  //     const { actualPage, documents, pages } = await fetchDocuments(
  //       1,
  //       "DESC",
  //       "idDocument"
  //     );

  //     TableQuotesConfig = {
  //       ...TableQuotesConfig,
  //       rows: documents,
  //       pages,
  //       actualPage,
  //       paginationFn: async function (
  //         page,
  //         order,
  //         columnOrdering,
  //         queryString
  //       ) {
  //         const documents = await fetchDocuments(
  //           page,
  //           order,
  //           columnOrdering,
  //           queryString
  //         );
  //         notifyDocumentsWithFiles(documents.documents);
  //         // shortCutAssociateFiles(documents);
  //         prepareExcelData(documents);
  //         return documents;
  //       },
  //     };

  //     TableQuotes = new DefaultTable(TableQuotesConfig);
  //     TableQuotes.printTable();
  //     TableQuotes.printPagination();
  //     setLengthData(documents.length);

  //     notifyDocumentsWithFiles(documents);
  //     // shortCutAssociateFiles(documents);
  //     prepareExcelData(documents);
  //   }

  //   initialLoad();
  // }, [refetch]);

  return (
    // <div>
    //   <div className="container spacingOptions">
    //   {rowInfo === null ? null : (
    //     <>
    //     <OCOptions rowInfo={rowInfo} />
    //       <CancelModal rowInfo={rowInfo} />
    //     </>
    //   )}

    //   </div>
    //   <>
    //     <CustomTable tableID="documents" paginationID="ocDocumentsPagination" />
    //   </>

    // </div>
    <>
      <CustomTable tableID="documents" paginationID="ocDocumentsPagination" />
    </>
  );
}
