import React from "react";
import {
  InputNumber,
  InputText,
  ErrorMessage
} from "../../../../components/individual/inputs/Inputs";

import AddSelectBank from '../../../../components/general/Forms/Selects/AddSelectBank';
 
export function BankInputs({
  forwardRef,
  errors,
  bank,
  usdAgreement = null,
  usdCode = null,
  usdAccount = null,
  mnAgreement = null,
  mnCode = null,
  mnAccount = null,
  mnId = null,
  mnName = null
}) {

  return (
    <div className="container" id="groupMN">
      <div className="row">
        <div className="col-12">
          <h4>Banco para pagos en MN</h4>
        </div>

        <div className="col-md-6 col-sm-12">

          <AddSelectBank
            id = 'mnBank'
            name = 'mnBank'
            forwardRef = {forwardRef}
            label = 'Banco'
            value = {mnId}
            valueLabel = {mnName}
          />

          <ErrorMessage message = {errors["mnBank"]?.message}/>
        </div>
        <div className="col-md-6 col-sm-12">
          <InputText
            htmlFor="mnNumberAccount"
            id="mnNumberAccount"
            labelTxt="Numero cuenta"
            placeholderTxt="Escribe aqui"
            name="mnNumberAccount"
            forwardRef = {forwardRef}
            defaultValue = { mnAccount }
          />
          <ErrorMessage message = {errors["mnNumberAccount"]?.message}/>
        </div>

        <div className="col-md-6 col-sm-12">
          <InputNumber
            htmlFor="mnNumberKeyCode"
            name="mnNumberKeyCode"
            id="mnNumberKeyCode"
            placeholderTxt="18 digitos"
            labelTxt="No. CLABE"
            forwardRef = {forwardRef}
            defaultValue = { mnCode }
          />
          <ErrorMessage message = {errors["mnNumberKeyCode"]?.message}/>
        </div>

        <div className="col-md-6 col-sm-12">
          <InputText
            id="mnNoAgreement"
            htmlFor="mnNoAgreement"
            labelTxt="Numero convenio"
            name="mnNoAgreement"
            placeholderTxt="Escribe aqui"
            forwardRef = {forwardRef}
            defaultValue = {mnAgreement}
          />
          <ErrorMessage message = {errors["mnNoAgreement"]?.message}/>
        </div>
      </div>


      <div className="row mt-4">
        <div className="col-12">
          <h4>Banco para pagos en USD</h4>
        </div>

        <div className="col-md-6 col-sm-12">

          <AddSelectBank
            id = 'usdBank'
            name = 'usdBank'
            forwardRef = {forwardRef}
            label = 'Banco'
          />

          <ErrorMessage message = {errors["usdBank"]?.message}/>
        </div>

        <div className="col-md-6 col-sm-12">
          <InputText
            htmlFor="usdNumberAccount"
            id="usdNumberAccount"
            labelTxt="Numero cuenta"
            placeholderTxt="Escribe aqui"
            name="usdNumberAccount"
            forwardRef = {forwardRef}
            defaultValue = {usdAccount}
          />
          <ErrorMessage message = {errors["usdNumberAccount"]?.message}/>
        </div>

        <div className="col-md-6 col-sm-12">
          <InputNumber
            htmlFor="usNumberKeyCode"
            name="usNumberKeyCode"
            id="usNumberKeyCode"
            placeholderTxt="18 digitos"
            labelTxt="No. CLABE"
            forwardRef = {forwardRef}
            defaultValue = {usdCode}
          />
          <ErrorMessage message = {errors["usNumberKeyCode"]?.message}/>
        </div>

        <div className="col-md-6 col-sm-12">
          <InputText
            id="usdNoAgreement"
            htmlFor="usdNoAgreement"
            labelTxt="Numero convenio"
            name="usdNoAgreement"
            placeholderTxt="Escribe aqui"
            forwardRef = {forwardRef}
            defaultValue = {usdAgreement}
          />
          <ErrorMessage message = {errors["usdNoAgreement"]?.message}/>
        </div>
      </div>
    </div>
  );
}
