import { Spinner } from "components/individual/Spinner/Spinner";
import { GetUEN } from "helpers/Apis/Documents";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { UenSelect } from "./UenSelect";
import { ModalClaveProd } from "./ModalClaveProd";
import { ModalUm } from "./ModalUm";
import { YesNoAlert } from "helpers/alerts";
import { updateSatCode, updateUmSat } from "helpers/Apis/invoice";
import LoadingText from "components/individual/TextLoading";

/**
 * @type {import("react").Context<import("./types").ContextUenV3>}
 */
export const UenContext = createContext();
const { Provider } = UenContext;

/**
 * Combo to select the information of the UEN, clave sat y clave um sat
 * @param {import("./types").PropsUenV3} props - Props
 * @returns
 * @example
*   import {
    UenV3,
    UenSelect,
    ModalClaveProd,
    ModalUm,
  } from "components/general/Forms/Selects/UenV3";
 * 
 *         <UenV3 onChange={(uen) => console.log("selected", uen)}>
          {() => (
            <>
              <Label>UEN</Label>
              <UenSelect />

              <Label>Clave SAT</Label>
              <ModalClaveProd />

              <Label>UM SAT</Label>
              <ModalUm/>
            </>
          )}
        </UenV3>
 */
export const UenV3 = ({ children, onChange = () => {}, id = null , promptUpdate = true , justActives = true }) => {
  /**
   * State of the component
   * @type {[import("./types").StateUenV3,()=>void]}
   */
  const [state, setState] = useState({
    isLoading: true,
    options: [],
    selected: undefined,
  });
    /**
   * @type{import("../../../../../../../types/documentActions").UseDocumentV2I}
   */
    // const {

    //   handleOnUenItemChange
    // } = useContext(DocumentContext);


  /**
   * Update the option saved on memory for the catalogue
   * @param {number} id - Id of the uen to update
   * @param {string} clave - New clave sat to user
   * @param {string} description - Description of that clave sat
   */
  const updateClaveSat = (id, clave, description) => {
    const itemsUpdated = state.options.map((option) => {
      if (option.UENID === id) {
        return {
          ...option,
          satCodeDescription: description,
          SATcode: clave,
        };
      }

      return option;
    });

    setState({
      ...state,
      options: itemsUpdated,
      selected: {
        ...state.selected,
        satCodeDescription: description,
        SATcode: clave,
      },
    });
  };

  /**
   * Update the item on the client memory side
   * @param {number} id - Id of the UEN to update
   * @param {string} um - UM sat
   * @param {string} description - Description of the UM
   */
  const updateUmSatMemory = (id, um, description) => {
    const itemsUpdated = state.options.map((option) => {
      if (option.UENID === id) {
        return {
          ...option,
          SATUM: um,
          satUmDescription: description,
        };
      }

      return option;
    });

    setState({
      ...state,
      options: itemsUpdated,
      selected: {
        ...state.selected,
        SATUM: um,
        satUmDescription: description,
      },
    });
  };

  /**
   * Handle the change of uen on the select combo
   * @param {number} id - Id of the uen selected
   * @returns {object}
   */
  const handleOnChange = (id) => {
    const uenSelected = state.options.find((item) => item.UENID === id) || {
      SATUM:null,
      SatCode:null,
      UENID:null,
      description:null,
      family:null,
      isExempt:null,
      iva:null,
      satCodeDescription:null,
      satUmDescription:null,
      subFamily:null
    };

    onChange(uenSelected);
    setState({
      ...state,
      selected: uenSelected.UENID === null ? undefined : uenSelected,
    });
  };

  useEffect(() => {
    if (id === null || state.options.length <= 0) return;

    handleOnChange(id);
  }, [state.options]);

  /**
   * Update the UM sat on the combo of UEN available on the system
   * @param {string} description - Description of the UM sat
   * @param {string} um - UM code by the SAT
   */
  const performUpdateUmSat = async (description, um) => {
    setState({
      ...state,
      selected: {
        ...state.selected,
        SATUM: um,
        satUmDescription: description,
      },
    });

    if (um !== state.selected.SATUM && promptUpdate) {
      YesNoAlert(
        "¿Deseas actualizar la unidad de medida de la UEN en el catálogo?",
        async () => {
          const wasUpdated = await updateUmSat(
            state.selected.UENID,
            description,
            um
          );
          if (wasUpdated)
            updateUmSatMemory(state.selected.UENID, um, description);
        }
      );
    }
  };

  /**
   * Update the clave and description of the UEN selected for that item on specific
   * @param {string} description - Description of the item
   * @param {string} clave - CLAVE sat
   * @returns {void}
   */
  const updateClaveProductoServicio = async (description, clave) => {
    setState({
      ...state,
      selected: {
        ...state.selected,
        description,
        satCodeDescription:description,
        SATcode: clave,
      },
    });

    try {
      if (clave !== state.selected.SATcode && promptUpdate) {
        YesNoAlert(
          "¿Deseas actualizar la clave de la UEN en el catálogo?",
          async () => {
            const wasUpdated = await updateSatCode(
              clave,
              description,
              state.selected.UENID
            );
  
            if (wasUpdated)
              updateClaveSat(state.selected.UENID, clave, description);
          }
        );
      }
    } catch (error) {
      
    }
  };

  /**
   * Update the UM used for that UEN
   * @param {string} description - Description of the UM
   * @param {string}} um - Code gave by the sat
   * @returns {void}
   */
  const updateUm = (description, um) =>
    setState({
      ...state,
      selected: {
        ...state.selected,
        SATUM: um,
        satUmDescription: description,
      },
    });

  useEffect(() => {
    (async function () {
      const apiUen = await GetUEN(justActives);
      setState({
        ...state,
        isLoading: false,
        options: apiUen,
      });
    })();
  }, []);

  useEffect(() => {
    if (state.selected === undefined) return;
    onChange(state.selected);
    // handleOnUenItemChange(state.selected);

  }, [state.selected]);

  if (state.isLoading)
    return <Spinner hidden={false} text={<LoadingText>Cargando UEN(s)</LoadingText>} />;

  return (
    <Provider
      value={{
        ...state,
        handleOnChange,
        updateClaveProductoServicio,
        updateUm,
        performUpdateUmSat,
      }}
    >
      {children({
        ...state,
      })}
    </Provider>
  );
};

export { UenSelect, ModalClaveProd, ModalUm };
