import { Spinner } from "components/individual/Spinner/Spinner";
import useEgressAssociation from "customHooks/useEgressAssociation";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { mnCurrency } from "helpers/money";
import ButtonV2 from "components/individual/ButtonsV2/Button";

import ModalNonDeductible from "./ModalAssociate";

import style from "./styles.module.css";
import BankInformation from "pages/Banks/BankInformation";

import { BreadcrumEgressAssociation } from "./BreadcrumEgressAssociation";
import { Step1 } from "./Step1";

/**
 * @type {React.Context<import("types/typedef/context/EgressAssociationContext").ContextI>}
 */
export const EgressAssociationContext = createContext();
const { Provider } = EgressAssociationContext;

export function EgressAssociation() {
  const { movement: movementId, account } = useParams();

  const hookEgress = useEgressAssociation(+movementId, +account);

  if (hookEgress.isLoading)
    return <Spinner hidden={false} text="Cargando gastos..." />;

  return (
    <>
      <BreadcrumEgressAssociation />

      <div className="px-5 my-3">
        <Provider
          value={{
            ...hookEgress,
            returnStep: hookEgress.returnStep,
            isAssociating: hookEgress.isAssociating,
            associate: hookEgress.associate,

            gridInvoice: {
              applied: mnCurrency(hookEgress.applied),
              residue: hookEgress.residue,
              total: mnCurrency(hookEgress.movement.amount),
              indexed: hookEgress.indexedGrid,
            },
          }}
        >
          {hookEgress.step === 1 ? (
            <Step1 />
          ) : (
            <>
              <div className="d-flex">
                <BankInformation idAccount={hookEgress.bank.bankAccountID} />
                {hookEgress.showConcepts ? (
                  <div className="mx-5">
                    <div className="w-100">
                      <h4>Informacion del egreso</h4>
                      <div className={style.associationsInfo}>
                        <p>
                          <b>Egreso No</b>: {hookEgress.movement.MovementID}
                        </p>

                        <p>
                          <b>Importe total:</b> {mnCurrency(hookEgress.residue)}
                        </p>
                        <p>
                          <b>Aplicado:</b>{" "}
                          {mnCurrency(hookEgress.nonDeductible.applied)}
                        </p>
                        <p>
                          <b>Saldo por asociar:</b>
                          {mnCurrency(
                            hookEgress.nonDeductible.remainingResidue
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {hookEgress.showConcepts ? (
                <>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-3">Concepto de egresos no deducibles.</h4>
                    <div className="d-flex">
                      {hookEgress.isAssociating ? (
                        <Spinner hidden={false} text="Asociando..." />
                      ) : (
                        <>
                          <div className="mr-4">
                            <ButtonV2
                              onClick={hookEgress.returnStep}
                              type="danger"
                            >
                              Regresar
                            </ButtonV2>
                          </div>

                          {hookEgress.nonDeductible.isValid ? (
                            <ButtonV2
                              onClick={async () => hookEgress.associate()}
                            >
                              Asociar
                            </ButtonV2>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              <ModalNonDeductible
                currency={hookEgress.bank.currencyCode}
                nonDeductible={hookEgress.residue}
                onChange={hookEgress.setNonDeductible}
              />
            </>
          )}
        </Provider>
      </div>
    </>
  );
}
