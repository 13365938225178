import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";

/**
 *
 * @param {object} props - Props
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} props.from - Indicates from where cames the user
 * @returns
 */
export default function BreadcrumInvoice({ from }) {

  if(from==='contabilidad') return <BreadCrumV2 path={[
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/contabilidad/facturas-emitidas",
      text: "Contabilidad",
    },
    {
      route: "/contabilidad/facturas-emitidas",
      text: "Facturas emitidas",
    },
  ]}/>

  return from === "pedidos" ? (
    <BreadCrumV2
      path={[
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/inicio",
          text: "Ventas",
        },
        {
          route: "/inicio",
          text: "Pedidos",
        },
      ]}
    />
  ) : (
    <BreadCrumV2
      path={[
        {
          route: `/inicio`,
          text: "Inicio",
        },

        {
          route: `/inicio`,
          text: "Ventas",
        },
        {
          route: `/inicio`,
          text: "Facturas emitidas",
        },
      ]}
    />
  );
}
