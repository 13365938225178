import React ,  { useContext , useRef } from "react";
import { ContextEmitedCreditNotes } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { Pagination } from "components/individual/Pagination";
import { parseDateToText } from "helpers/dates";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import MobileItem from "./MobileItem";
import OptionsCreditNoteEmited from "./OptionsCreditNoteEmited";

export default function TableCreditNotesEmited() {
  const hook = useContext(ContextEmitedCreditNotes);

  const formId = useRef(window.crypto.randomUUID());

  if (hook.isSearching)
    return (
      <Spinner
        idSpinner="loadingCreditNotesEmited"
        hidden={false}
        text={<LoadingText>Buscando notas de crédito</LoadingText>}
      />
    );

  if (hook.creditNotes.length <= 0)
    return <h1 className="my-4 text-center">No hay notas de crédito</h1>;

  return (
    <div className={style.creditNotesEmited}>
      <div className={style.header}>
        <p>Folio</p>
        <p>Razón social</p>
        <p>Registro</p>
        <p>Factura</p>
        <p>Moneda</p>
        <p>Total</p>
        <p>Estatus</p>
      </div>

      <div className={style.containerRows}>
        {hook.creditNotes.map((creditNote, i) => (
          <div className={style.containerRow} key={`${formId.current}-${i}`}>
            <input
              onChange={(e) => hook.setCreditNote(creditNote)}
              type="radio"
              name={formId.current}
              id={`${formId.current}-input-${i}`}
            />
            <label
              className={style.row}
              htmlFor={`${formId.current}-input-${i}`}
            >
              <ModalInvoiceReception uuid={creditNote.invoice.uuid}>
                {creditNote.documentNumber}
              </ModalInvoiceReception>
              <p>{creditNote.customer.socialReason}</p>
              <p>
                {parseDateToText(new Date(`${creditNote.registro.yyyymmdd}:`))}
              </p>
              <p>{creditNote.invoice.noDocument}</p>
              <p>{creditNote.currency}</p>
              <p>{creditNote.total.text}</p>
              <p>{creditNote.status.description || "Cancelada"}</p>
            </label>

            <MobileItem
              created={creditNote.registro.yyyymmdd}
              currency={creditNote.currency}
              folio={creditNote.documentNumber}
              invoice={creditNote.invoice.noDocument}
              socialReason={creditNote.customer.socialReason}
              status={creditNote.status.description || "Cancelada"}
              total={creditNote.total.text}
              options={<OptionsCreditNoteEmited {...creditNote} />}
            />
          </div>
        ))}
      </div>
      <Pagination
        onChange={(page) => hook.updatePage(page)}
        actualPage={hook.currentPage}
        pages={hook.pages}
      />
    </div>
  );
}
