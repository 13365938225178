import { Select } from "components/individual/inputs/Inputs";
import useFilterStatusFile from "customHooks/useFilterStatusFile";
import React from "react";

export default function FilterFileStatus({ onChange = () => {}, value }) {
  const { status, handleOnChange } = useFilterStatusFile(onChange);

  return (
    <Select
      addTodos={false}
      label="Estatus"
      options={status}
      textJSON="label"
      valueJSON="value"
      value={value}
      onChange={(value) => handleOnChange(+value)}
    />
  );
}
