import ButtonV2 from "components/individual/ButtonsV2/Button";
import { DirectoryContext } from "pages/Directory";
import React from "react";
import { useContext } from "react";
import style from "../../styles.module.scss";

export default function EditDirectory() {

  const hook = useContext(DirectoryContext);

  if (hook.selected === null) return <></>;

  return (
    <div className={style.optionMobile}>
      <ButtonV2 onClick={hook.redirectToEdition}>Editar</ButtonV2>
    </div>
  );
}
