import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import QRcode from "qrcode";
import {
  add2faDevice,
  get2faQrCode,
  getKey2faQrCode,
  revoke2fa,
} from "helpers/Apis/usuariosApi";
import { TOGGLE_2FA } from "types/index";

export default function use2fa() {
  const { userInfo, dispatch } = useContext(LoginContext);

  const [isActive2fa, setIsActive2fa] = useState(userInfo[0].has2faEnable);

  useEffect(() => {
    setIsActive2fa(userInfo[0].has2faEnable);
  }, [userInfo]);

  const keyReact = useRef(window.crypto.randomUUID());

  const [isPerformingAbc, setIsPerformingAbc] = useState(false);
  const [otp, setOtp] = useState("");
  const [key, setKey] = useState("");
  const [recovery, setRecovery] = useState([]);
  const [error,setError] = useState("");

  const dialog = useDialog();
  const dialogRecovery = useDialog();
  const dialogCancel = useDialog();
  const dialogError = useDialog();

  const canvas = useRef();

  const customCloseQr = () => {
    setIsActive2fa(!isActive2fa);
    dialog.close();
  };

  const handleOpen = async () => {
    setIsActive2fa(!isActive2fa);

    if (userInfo[0].has2faEnable) {
      dialogCancel.open();
      return;
    }

    dialog.open();
    const qrUriString = await get2faQrCode();
    if (qrUriString === undefined) return;
    QRcode.toCanvas(canvas.current, qrUriString);

    const key = getKey2faQrCode(qrUriString);
    setKey(key);
  };

  const attemptAddDevice = async () => {
    setIsPerformingAbc(true);

    const apiRecoveryKeys = await add2faDevice(otp, key);

    setIsPerformingAbc(false);

    if (typeof(apiRecoveryKeys) === "string") {
      setError(apiRecoveryKeys);
      dialogError.open();
      return;
    };

    setError("");
    setRecovery(apiRecoveryKeys);

    dispatch({
      type: TOGGLE_2FA,
    });

    dialog.close();
    dialogCancel.close();
    dialogRecovery.open();
    setIsPerformingAbc(false);
  };

  const attemptCancel2fa = async () => {
    setIsPerformingAbc(true);

    const wasRevoked = await revoke2fa();

    if (wasRevoked) {
      dialogCancel.close();
      dialog.close();
      dialogRecovery.close();
      setIsActive2fa(false);
      dispatch({
        type: TOGGLE_2FA,
      });
    }

    setIsPerformingAbc(false);
  };

  const customCloseCancel = () => {
    dialogCancel.close();
    setIsActive2fa(!isActive2fa);
  };

  return {
    keyReact,
    otp,
    setOtp,
    key,
    setKey,
    recovery,
    setRecovery,
    dialog,
    dialogRecovery,
    dialogCancel,
    canvas,
    handleOpen,
    attemptAddDevice,
    isPerformingAbc,
    attemptCancel2fa,
    isActive2fa,
    customCloseQr,
    customCloseCancel,
    dialogError,
    error
  };
}
