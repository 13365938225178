import LoginContext from "context/Login/LoginContext";
import { GetCustomer } from "helpers/Apis/Directory";
import {
  downloadPdfDocumentV3,
  fetchDocumentsV2,
  getOdcPdf,
} from "helpers/Apis/Documents";
import { dateToDbFormat, parseDateToText, rangeFullYear } from "helpers/dates";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { saveAs } from "file-saver";
import usePermissions from "customHooks/usePermissions";
import useMemoryFilter from "customHooks/useMemoryFilter";

/**
 * @type {import("./types").ComboStatus}
 */
const AllOption = {
  label: "Todos",
  value: null,
};

/**
 * @type {import("./types").IndexedStatus}
 */
const STATUS = {
  1: [
    { ...AllOption },
    {
      label: "Abierta",
      value: 1,
    },
    {
      label: "Ganada",
      value: 2,
    },
    {
      label: "Facturada",
      value: 14,
    },
    {
      label: "Cancelada",
      value: 3,
    },
  ],
  2: [
    { ...AllOption },
    {
      label: "No facturado",
      value: 4,
    },
    {
      label: "Facturado",
      value: 5,
    },
    {
      label: "Cancelado",
      value: 6,
    },
  ],
  6: [
    { ...AllOption },
    {
      label: "Vigente",
      value: 7,
    },
    {
      label: "En proceso",
      value: 8,
    },
    {
      label: "Terminado",
      value: 9,
    },
  ],
  3: [
    { ...AllOption },
    {
      label: "Activa",
      value: 10,
    },
    {
      label: "Enviada",
      value: 11,
    },
    {
      label: "Parcialmente recibida",
      value: 13,
    },
    {
      label: "Recibida",
      value: 17,
    },
    {
      label: "Cancelada",
      value: 12,
    },
  ],
};

/**
 * @type {import("./types").StateDocuments}
 */
const STATE = {
  isLoadingTable: true,
  isDownloadingPdf: false,
  customer: undefined,
  isLoadingCustomer: true,
  isLoadingStatus: true,
  comboDocuments: [],
  comboStatus: [],
  from: rangeFullYear().firstDay.jsDate,
  to: rangeFullYear().lastDay.jsDate,
  page: 1,
  pages: 0,
  status: undefined,
  uen: undefined,
  noDocument: null,
  documents: [],
  document: undefined,
  documentSelected: undefined,
  refetch: false,
};

const URL_EDIT = {
  1: "cotizacion",
  2: "pedido",
  6: "contrato",
};

/**
 * Handle the operation of the documents on the system
 * @param {number|null} [idCustomer=null] - Id of the customer
 * @returns {import("./types").ReturnUseDocuments}
 */
export default function useDocuments(idCustomer = null) {
  const { retrieveData, setMemoryData } = useMemoryFilter({
    status: {
      label: "Todos",
      value: null,
    },
    document: {
      label: "Cotización",
      value: 1,
    },
    noDocument: null,
    from: rangeFullYear().firstDay.jsDate,
    to: rangeFullYear().lastDay.jsDate,
    uen: null,
  });

  const [state, setState] = useState({
    ...STATE,
    ...retrieveData("documents"),
  });
  const { checkPermission } = usePermissions();

  const history = useHistory();

  const addQuote = () =>
    history.push(
      `/documentos/cotizacion/agregar?idCliente=${state.customer?.id}`
    );

  const viewDocument = () =>
    history.push(
      `/directorio/documentos/${state.customer?.id}/ver/${state.documentSelected?.idDocument}`
    );

  const winQuote = () =>
    history.push(
      `/directorio/documentos/${state.customer?.id}/ganar2/${state.documentSelected?.idDocument}`
    );

  const associateFiles = () =>
    history.push(
      `/directorio/${state.customer?.id}/documentos/documentos/archivos-asociados/${state.documentSelected?.idDocument}`
    );

  const viewToDo = () =>
    history.push(
      `/directorio/documentos/reminders/${state.customer?.id}/${state.documentSelected?.idDocument}`
    );

  const editDocument = () => {
    const urlToRedirect =
      state.document.value !== 2 ? "documento" : "idDocumento";

    history.push(
      `/documentos/${URL_EDIT[state.document.value]}/editar?${urlToRedirect}=${
        state.documentSelected?.idDocument
      }&idCliente=${state.customer?.id}`
    );
  };

  const idForm = useRef(`${window.crypto.randomUUID()}`);

  const session = useContext(LoginContext);
  /**
   * Retrieve the item that contains the value id on the combo
   * @param {import("./types").IndexedStatus} indexedStatus 
   * @param {number} targetValue 
   */
  function findComboStatus(indexedStatus, targetValue) {
    for (const key in indexedStatus) {
      if (indexedStatus.hasOwnProperty(key)) {
        const comboStatusArray = indexedStatus[key];
        if (
          comboStatusArray.some(
            (comboStatus) => comboStatus.value === targetValue
          )
        ) {
          return comboStatusArray;
        }
      }
    }
    return null; //
  }

  useEffect(() => {
    (async function () {
      if (typeof idCustomer !== "number") return;

      const apiCustomer = await GetCustomer(idCustomer);

      /**
       * @type {import("./types").ComboDocuments[]}
       */
      const comboDocuments =
        apiCustomer.type.id === 1
          ? [
              {
                label: "Cotización",
                value: 1,
              },
              {
                label: "Pedido",
                value: 2,
              },
              {
                label: "Contrato",
                value: 6,
              },
            ]
          : [
              {
                label: "Ordenes de compra",
                value: 3,
              },
            ];

      const memoryData = retrieveData("documents");

      const status = memoryData.status;

      const comboFound = findComboStatus(STATUS, status.value);            
      
      const comboStatus = apiCustomer.type.id !== 1 ? STATUS[3]  : comboFound;

      const comboStatusToUse = comboStatus.find(item=>item.value===11&&apiCustomer.type.id !== 1) || STATUS[1];
     
      const comboToUse = Array.isArray(comboStatusToUse) ? comboStatusToUse : comboStatus;

      const statusToUse = comboToUse.find(item=>item.value===status.value) || { label:'Todos', value:null };


      setState((current) => ({
        ...current,
        customer: apiCustomer,
        isLoadingCustomer: false,
        comboDocuments,
        comboStatus: comboToUse,
        isLoadingStatus: false,
        document: comboDocuments[0],
        status:statusToUse,
      }));
    })();
  }, [idCustomer]);

  useEffect(() => {
    (async function () {

      if(state.customer===undefined || state.customer===null) return

      setState((current) => ({
        ...current,
        isLoadingTable: true,
      }));

      if (session.userInfo.length <= 0 || state.document === undefined) return;

      const document = await fetchDocumentsV2(
        state.page,
        session.userToRepresentId,
        state.document.value,
        state.status.value,
        dateToDbFormat(state.from),
        dateToDbFormat(state.to),
        state.noDocument,
        state.customer.id,
        state.uen
      );

      setState((current) => ({
        ...current,
        pages: document.data.pages,
        documents: document.data.documents,
        isLoadingTable: false,
        isLoadingStatus: false,
      }));
    })();
  }, [session.userInfo, state.document, state.refetch, state.page,state.customer]);

  /**
   *
   * @param {import("./types").ComboDocuments} document - Document
   */
  const setDocument = (document) =>
    setState((current) => ({
      ...current,
      document,
      page: 1,
      status: STATUS[document.value][0],
      documentSelected: undefined,
      isLoadingStatus: true,
      comboStatus: STATUS[document.value],
    }));

  const setStatus = (status) =>
    setState((current) => ({
      ...current,
      page: 1,
      status,
    }));

  const searchData = () =>
    setState((current) => ({
      ...current,
      page: 1,
      refetch: !current.refetch,
      documentSelected: undefined,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  /**
   * Set the document selected
   * @param {import("types/typedef/documents").DocumentListInfoI} documentSelected - Document
   * @returns {void}
   */
  const setDocumentSelected = (documentSelected) =>
    setState((current) => ({
      ...current,
      documentSelected,
    }));

  /**
   * Set the number of document to search
   * @param {number} noDocument - Number of document
   * @returns {void}
   */
  const setNoDocument = (noDocument) => {
    if (typeof noDocument === "number") {
      setState((current) => ({
        ...current,
        noDocument: noDocument <= 0 ? null : noDocument,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      noDocument: null,
    }));
  };

  function isEditableDocument() {
    if (state.documentSelected === null || state.documentSelected === undefined)
      return false;

    if (state.document.value === 1) {
      return (
        state.documentSelected.idStatus === 1 &&
        checkPermission("3a505210-e0eb-4df8-b660-3a884b73460f")
      );
    }

    if (state.document.value === 2) {
      return (
        state.documentSelected.idStatus === 4 &&
        checkPermission("986a627e-00b2-41e4-aa4c-8a020995becc")
      );
    }

    if (state.document.value === 6) {
      return (
        state.documentSelected.idStatus === 7 &&
        checkPermission("f1b1aca9-1b14-4713-9536-c665f7168d17")
      );
    }

    return false;
  }

  const downloadPdf = async () => {
    setState((current) => ({
      ...current,
      isDownloadingPdf: true,
    }));

    /**
     * @type {FileList|Blob|null}
     */
    let pdf2 = null;

    try {
      if (
        state.document.value !== 3 &&
        state.documentSelected.Moneda !== "***"
      ) {
        pdf2 = await downloadPdfDocumentV3(
          state.documentSelected.idDocument,
          "intranet"
        );

        saveAs(
          pdf2["0"],
          `${state.document.label} ${String(
            state.documentSelected.documentNumber
          ).padStart(8, "0")}.pdf`
        );
      } else {
        pdf2 = await getOdcPdf(state.documentSelected.idDocument);
        saveAs(
          pdf2,
          `${state.document.label} ${String(
            state.documentSelected.documentNumber
          ).padStart(8, "0")}.pdf`
        );
      }
    } catch (error) {
    } finally {
      setState((current) => ({
        ...current,
        isDownloadingPdf: false,
      }));
    }
  };

  /**
   * Set the range of dates
   * @param {string} type - Type of range
   * @param {Date} date - Date
   */
  const setDate = (type = "from", date) => {
    const key = type === "from" ? "from" : "to";

    setState((current) => ({
      ...current,
      [key]: date,
    }));
  };

  function canViewDocument() {
    if (state.document === null || state.document === undefined) return false;

    if (state.document.value === 1)
      return checkPermission("d9390bfd-4f3b-4f0b-a3dc-f8efcf058205");

    if (state.document.value === 2)
      return checkPermission("b7227cc3-ad50-46b2-9ed3-2f9398c817a1");

    if (state.document.value === 6)
      return checkPermission("88c7d319-c06c-46fa-b0db-9919c5404567");

    if (state.document.value === 3)
      return checkPermission("89111e2a-c1b2-41ab-a81c-d85402360475");
  }

  function canAssociateFiles() {
    if (state.document === null || state.document === undefined) return false;

    if (state.document.value === 1)
      return checkPermission("384f293a-3371-42a3-beee-f7196010b4bf");

    if (state.document.value === 2)
      return checkPermission("7f83b2ed-d8b3-4182-b09d-97ed4ea8c3ba");

    if (state.document.value === 6)
      return checkPermission("55163480-2f23-41e0-865c-ebef9a0cd4f4");

    if (state.document.value === 3)
      return checkPermission("8a272a53-6fe8-4af2-9aeb-f8e012d35449");
  }

  function canSendEmail() {
    if (state.document === null || state.document === undefined) return false;

    if (state.document.value === 1)
      return checkPermission("94f0c2b1-03fd-4f8e-87eb-c46143385f9e");

    if (state.document.value === 2)
      return checkPermission("0ad73060-df01-4b18-8865-7a4a4743114a");

    if (state.document.value === 6)
      return checkPermission("fcf8a510-b7af-409d-9856-d541e1eb00ab");

    if (state.document.value === 3) {
      if (
        state.documentSelected === undefined ||
        state.documentSelected === null
      )
        return false;

      if (
        state.documentSelected.Moneda !== "***" &&
        checkPermission("0d632b90-613e-486c-8584-7c56fe63ab0b")
      )
        return true;

      return false;
    }
  }

  function canDownloadPdf() {
    if (state.document === null || state.document === undefined) return false;

    if (state.document.value === 1)
      return checkPermission("bca70338-63fa-400a-9c05-a70f4d012481");

    if (state.document.value === 2)
      return checkPermission("ff52ba48-7297-42d8-8a50-2d61e5b40bd8");

    if (state.document.value === 6)
      return checkPermission("fa7f06cf-03ff-4c1d-9b96-990c1ce3a3f1");

    if (state.document.value === 3)
      return checkPermission("28886c84-68d6-4923-9546-068114fe764b");
  }

  function canCreateInvoice() {
    if (state.document === null || state.document === undefined) return false;

    if (state.document.value !== 2) return false;

    if (state.documentSelected === null || state.documentSelected === undefined)
      return false;

    if (state.documentSelected.idStatus !== 4) return false;

    return checkPermission("368afea7-a30f-4078-a5b3-a9cf24404b49");
  }

  function isCancellableDocument() {
    if (
      state.document === null ||
      state.document === undefined ||
      state.documentSelected === null ||
      state.documentSelected === undefined
    )
      return false;

    if (state.document.value === 1) {
      return (
        state.documentSelected.idStatus === 1 &&
        checkPermission("dfa178da-d655-40ce-bfba-c36cfbadda3c")
      );
    }

    if (state.document.value === 6) {
      return (
        state.documentSelected.idStatus === 7 &&
        checkPermission("9a61f298-85bc-4178-945a-ab39a5ec3901")
      );
    }

    return false;
  }

  /**
   * @type {import("components/individual/PillFilterMobile/types").PropsPillFilterMobile[]}
   */
  const pills = [
    {
      label: "Razón social",
      value: state.customer?.socialReason || "Cargando",
    },
    {
      label: "Documento",
      value: state.document?.label || "Cargando",
    },
    {
      label: "Estatus",
      value: state.status?.label || "Cargando",
    },
    {
      label: "Desde",
      value: parseDateToText(state.from),
    },
    {
      label: "Hasta",
      value: parseDateToText(state.to),
    },
  ];

  useEffect(() => {
    setMemoryData(
      {
        from: state.from,
        to: state.to,
        noDocument: state.noDocument,
        uen: state.uen,
        status: {
          label: state.status?.label,
          value: state.status?.value,
        },
        document: {
          label: state.document?.label,
          value: state.document?.value,
        },
      },
      "documents"
    );
  }, [
    state.status,
    state.from,
    state.to,
    state.noDocument,
    state.uen,
    state.document,
  ]);

  return {
    ...state,
    viewDocument,
    setDocument,
    setDocumentSelected,
    setStatus,
    searchData,
    idForm: idForm.current,
    addQuote,
    viewToDo,
    downloadPdf,
    editDocument,
    setPage,
    setDate,
    associateFiles,
    setNoDocument,
    pills,
    winQuote,
    isCancellableDocument: isCancellableDocument(),
    isEditableDocument: isEditableDocument(),
    canViewDocument: canViewDocument(),
    canAssociateFiles: canAssociateFiles(),
    canSendEmail: canSendEmail(),
    canDownloadPdf: canDownloadPdf(),
    canCreateInvoice: canCreateInvoice(),
    canWinQuote:
      state.documentSelected?.idStatus !== 1 ||
      state.documentSelected === null ||
      state.documentSelected === undefined ||
      checkPermission("edb51d0b-b71b-4c96-b04a-2fded3017abf") === false
        ? false
        : true,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/directorio",
        text: "Directorio",
      },
      {
        route: "/",
        text: "Documentos",
      },
    ],
  };
}
