import usePermissions from "customHooks/usePermissions";
import { getOcAccounting } from "helpers/Apis/concilation";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * @type {import("./types").StateAccountingOc}
 */
const INITIAL_STATE = {
  isLoading: true,
  year: new Date().getUTCFullYear(),
  ocs: [],
  refetch: false,
  oc: null,
  grid: {
    original: {},
    operation: {},
  },
};

/**
 *
 * @returns {import("./types").UseAccountingOc}
 */
export default function useAccountingOc() {
  const [state, setState] = useState(INITIAL_STATE);

  usePermissions('baf397d1-041a-4783-88b7-769620c84356');

  const history = useHistory();

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        oc: null,
        ocs: [],
      }));

      /**
       * @type {import("../../../../../../types/accounted/accounted").GetOCNRRespI}
       */
      const emptyValue = {
        accounted: false,
        mxnTotal: 0,
        recordMonth: undefined,
        tc: 0,
        total: 0,
        id:undefined,
        usdTotal: 0,
      };

      /**
       * @type {import("./types").IndexedSchema}
       */
      let indexed = {
        1: emptyValue,
        2: emptyValue,
        3: emptyValue,
        4: emptyValue,
        5: emptyValue,
        6: emptyValue,
        7: emptyValue,
        8: emptyValue,
        9: emptyValue,
        10: emptyValue,
        11: emptyValue,
        12: emptyValue,
      };

      const ocs = await getOcAccounting(state.year);

      for(let i=0;i<ocs.length;i++){
        indexed[ocs[i].recordMonth] = ocs[i];
      }


      const grid = ocs.reduce(
        (indexed, oc) => ({
          ...indexed,
          [oc.id]: oc.accounted,
        }),
        {}
      );

      const ocsParsed = Object.entries(indexed).map(([key,value])=>({
        ...value,
        recordMonth:+key
      }))

      setState((current) => ({
        ...current,
        isLoading: false,
        ocs:ocsParsed,
        grid: {
          operation: grid,
          original: grid,
        },
      }));
    })();
  }, [state.year, state.refetch]);

  const goToFiles = () =>
    history.push(
      `/administracion/ordenes-compra/archivos-asociados/${state.oc.id}?modulo=contabilidad`
    );

  const setYear = (year) =>
    setState((current) => ({
      ...current,
      year,
    }));

  const search = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const setOc = (id) => {
    const item = state.ocs.find((oc) => oc.id === id);

    setState((current) => ({
      ...current,
      oc: item,
    }));
  };

  const toggleAccounting = (id) => {
    setState((current) => ({
      ...current,
      grid: {
        ...current.grid,
        operation: {
          ...current.grid.operation,
          [id]: !current.grid.operation[id],
        },
      },
    }));
  };

  return {
    ...state,
    toggleAccounting,
    setOc,
    goToFiles,
    search,
    setYear,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/contabilidad/oc",
        text: "Contabilidad",
      },
      {
        route: "/",
        text: "Provicion de OC",
      },
    ],
  };
}
