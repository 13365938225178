import React, { useContext } from "react";
import { ContextOdc } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function Excel() {
  const hook = useContext(ContextOdc);

  if (hook.isDownloadingExcel)
    return (
      <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
    );

  return (
    <ButtonV2 onClick={hook.downloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
