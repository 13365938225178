import React, { useContext } from "react";
import { ContextGestionOc } from "..";
import Comment from "../Comment";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import ui from "./styles.module.scss";
import Update from "./Update";
import SendEmailOc from "./SendEmailOc";

export default function OptionsContainer() {
  const hook = useContext(ContextGestionOc);

  if (hook.record === undefined)
    return (
      <div className={ui.containerOptions}>
        <Update />
      </div>
    );

  return (
    <div className={ui.containerOptions}>
      <SendEmailOc />
      <Comment
        id={hook.record.idOdc}
        documentNumber={hook.record.documentNumber}
      >
        <ButtonV2>
          <span>Comentario</span>
          <Icon icon="comments" />
        </ButtonV2>
      </Comment>
      <Update />
    </div>
  );
}
