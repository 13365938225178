import { getBankAccountV2 } from "helpers/Apis/Banks";
import { useEffect, useState } from "react";

export default function useBankForm(id = null) {
  /**
   * Information of the bank account in order to edit or add a new register
   * @type {[import("./types").StateUsaBankForm,()=>void]}
   */
  const [data, setData] = useState({
    isLoading: true,
    bank: {
      id: null,
      clave: null,
      info: null,
    },
    cuenta: "",
    CLABE: "",
    currency: null,
    residue: 0,
    typeAccount: null,
    isValid: true,
    active: true,
    canInactive: false,
    lastMonth: "",
    date: new Date(),
  });

  useEffect(() => {
    (async function () {
      if (id === null) {
        setData({
          ...data,
          isLoading: false,
        });
        return;
      }

      const apiBankAccount = await getBankAccountV2(id);

      const apiToState = {
        ...data,
        isLoading: false,
        bank: {
          id: apiBankAccount.bank.id,
          clave: apiBankAccount.bank.clave,
          info: apiBankAccount.bank,
        },
        cuenta: apiBankAccount.cuenta,
        CLABE: apiBankAccount.CLABE,
        currency: apiBankAccount.currency,
        residue: apiBankAccount.initialAmount.number,
        typeAccount: apiBankAccount.type.id,
        isValid: true,
        active: apiBankAccount.status.id,
        canInactive: apiBankAccount.status.canInactive,
        lastMonth: `${apiBankAccount.residue.text} - ${apiBankAccount.residue.key}`,
      };

      setData(apiToState);
    })();
  }, [id]);

  const updateResidue = (residue) =>
    setData({
      ...data,
      residue,
    });

  /**
   * Update the status of the bank account
   * @param {boolean} active - Indicate if the bank account is gonna be active
   * @returns {void}
   */
  const updateIsActive = (active) =>
    setData({
      ...data,
      active,
    });

  /**
   * Update the social reason of the bank selected on the combo
   * @param {number} id - Id
   * @param {string} clave - Clave SAT
   * @param {import("customHooks/useBanks/types").SelectedBankI} bank - Infomration of the bank in case user tries to create it
   */
  const updateBank = (id, clave, bank = null) => {
    setData({
      ...data,
      bank: {
        id,
        clave,
        info: bank,
      },
    });
  };
  /**
   * Update the cuenta to use on the bank
   * @param {string} cuenta - Cuenta
   * @returns {void}
   */
  const updateCuenta = (cuenta) =>
    setData({
      ...data,
      cuenta,
    });

  /**
   * Update the CLABE
   * @param {string} CLABE - CLABE
   * @param {boolean} isValid - Indicates if the CLABE typed is correct
   * @returns {void}
   */
  const updateCLABE = (CLABE, isValid) => {
    setData({
      ...data,
      CLABE,
      isValid,
    });
  };

  /**
   * Update the currency to use on the bank account
   * @param {"USD"|"MXN"} currency - Currency
   * @returns {void}
   */
  const updateCurrency = (currency) =>
    setData({
      ...data,
      currency,
    });

  /**
   * Update the type account for the bank
   * @param {number} typeAccount - Type account
   * @returns {void}
   */
  const updateTypeAccount = (typeAccount) => {
    setData({
      ...data,
      typeAccount,
    });
  };

  /**
   *
   * @param {Date} date - Date for the last close month
   * @returns {void}
   */
  const updateCloseDate = (date) =>
    setData({
      ...data,
      date,
    });

  return {
    isLoading: data.isLoading,
    isValid: data.isValid,
    bank: data.bank,
    cuenta: data.cuenta,
    CLABE: data.CLABE,
    currency: data.currency,
    residue: data.residue,
    typeAccount: data.typeAccount,
    updateCuenta,
    updateCLABE,
    updateCurrency,
    updateTypeAccount,
    updateResidue,
    updateBank,
    active: data.active,
    updateIsActive,
    canInactive: data.canInactive,
    lastMonth: data.lastMonth,
    date: data.date,
    updateCloseDate,
  };
}
