import useDocument from "components/Sections/Documents/Hooks/useDocument";
import { getDocumentData, handleFormItem } from "components/Sections/Documents/helpers/documentHelper";
import usePartilities from "customHooks/usePartialities";
import useQueryParams from "customHooks/useQueryParams";
import { GetDocumentInfo, GetTcp } from "helpers/Apis/Documents";
import { getInvoiceAttemptData } from "helpers/Apis/invoice";
import { getDateFromUtc } from "helpers/dates";
import {
  calculateDiscountToApply,
  getTaxas,
  truncateDecimals,
} from "helpers/money";
import { useState, useEffect } from "react";
import { createInvoice } from "helpers/Apis/invoice";
import { Error } from "helpers/alerts";

/**
 * @type {import("./types").StateInvoiceCreationI}
 */
const INITIAL_STATE = {
  isLoading: true,
  order: undefined,
  isCreating: false,
  minTc: 0,
  maxPartialitiesAllows: 1,
  variance: 0,
  isOnRevision: false,
  limitCreationTime: null,
  currencyExchange: false,
  tcOrder: 0,
  tc: 0,
  partialities: 1,
  customer: undefined,
  cfdi: undefined,
  payMethod: undefined,
  payForm: undefined,
  importe: 0,
  iva: 0,
  total: 0,
  currency: undefined,
  comments:[],
  creditDays: 1,
  messageAuthorization: "",
  isValidStatus: true,
  cxc: {
    isValid: false,
    partialitiesInfo: [],
  },
};

/**
 * @type {import("./types").ContextCreateInvoiceV2}
 */
export const CONTEXT_VALUE = {
  ...INITIAL_STATE,
  updateCurrencyToggle: () => {},
  updateTc: () => {},
  updatePartialities: () => {},
  updateCfdi: () => {},
  updatePayForm: () => {},
  updatePayMethod: () => {},
  updatePartialitiesCxc: () => {},
  attemptCreateInvoice: async () => {},
  customer:undefined
};

/**
 * Create an invoice against the SAT and mange the creation of the CxC(s)
 * @param {number} [id=null] - Id del pedido a timbrar
 * @param {()=>void} [onCreated=()=>{}] - Callback executed when invoice was created with success
 */
export default function useCreateInvoiceV2(id = null, onCreated = () => {},onError=()=>{}) {
  const { pedido } = useQueryParams();
  const idOrderToUse = typeof id !== "number" ? +pedido : id;

  const [state, setState] = useState(INITIAL_STATE);

  const order = useDocument(
    state.customer?.id,
    2,
    "Editar",
    idOrderToUse,
  );

  const { createGrid: gridImpuestos } = usePartilities(
    state.partialities,
    0,
    state.total,
    1
  );

    /**
   * Update if apply currency exchange on the order document
   * @param {boolean} currencyExchange - If true, user want's apply exchange the currency of the order/invoice
   */
    const updateCurrencyToggle = (currencyExchange) => {
      const currencyToUse = state.currency === "USD" ? "MXN" : "USD";
      const key = currencyToUse.toLocaleLowerCase();
      const importe = order.document.moneyInfo[`${key}`].importSell.number;
      const iva = order.document.moneyInfo[`${key}`].ivaSell.number;      

      setState((current) => ({
        ...current,
        currencyExchange,
        tc: current.tc,
        currency: currencyToUse,
        importe,
        iva,
        total: truncateDecimals(importe + iva, 2),
      }));
  
      order.functions.handleOnDocumentCurrencyChange({
        id: currencyToUse === "MXN" ? 1 : 2,
        value: currencyToUse,
      });
    };

  useEffect(() => {
    (async function () {
      const [apiOrder, paramsCreation, tc, order] = await Promise.all([
        getDocumentData(idOrderToUse),
        getInvoiceAttemptData(idOrderToUse),
        GetTcp(),
        GetDocumentInfo(idOrderToUse),
      ]);

      if (order.data.documentInfo.status.id !== 4) {
        setState((current) => ({
          ...current,
          isValidStatus: false,
          isLoading: false,
        }));
        return;
      }

      const minTc = truncateDecimals(
        tc.saiko - paramsCreation.validations.variance,
        2
      );

      const comments = Array.isArray(apiOrder.data.comments) ? apiOrder.data.comments.map(comment=>comment.comment) : [];

      const limitCreationTime = retrieveLimitCreationTime();

      const partialitiesToUse =
        limitCreationTime === null
          ? 1
          : paramsCreation.invoice.auth.partialitiesRequested;
      const tcToUse =
        limitCreationTime === null
          ? apiOrder.data.moneyInfo.tc.number
          : paramsCreation.invoice.auth.tcRequested.number;
      const currencyExchange =
        limitCreationTime === null
          ? false
          : paramsCreation.invoice.auth.requiresExchange;

      const currencyDocument =
        apiOrder.data.moneyInfo.currency.value.toLocaleLowerCase();

      const iva = apiOrder.data.moneyInfo[`${currencyDocument}`].ivaSell.number;
      const importe =
        apiOrder.data.moneyInfo[`${currencyDocument}`].importSell.number;

        const applyRevisionSettings = checkIfApplyCurrencyExchange(limitCreationTime);

      setState((current) => ({
        ...current,
        tc: tcToUse,
        currencyExchange,
        comments,
        partialities: partialitiesToUse,
        creditDays: paramsCreation?.invoice?.creditDays || 1,
        order: apiOrder.data,
        isLoading: false,
        maxPartialitiesAllows: paramsCreation.validations.partialities,
        variance: paramsCreation.validations.variance,
        isOnRevision: paramsCreation.isOnRevision,
        limitCreationTime,
        tcOrder:tcToUse,
        // tcOrder: !paramsCreation.isOnRevision
        //   ? paramsCreation.invoice.tcp.number
        //   : 0,
        minTc,
        customer: !paramsCreation.isOnRevision
          ? {
              id: paramsCreation.invoice.customer.id,
              rfc: paramsCreation.invoice.customer.rfc,
            }
          : undefined,
        cfdi: !paramsCreation.isOnRevision
          ? paramsCreation.invoice.cfdi.code
          : undefined,
        payForm: !paramsCreation.isOnRevision
          ? paramsCreation.invoice.payForm.code
          : undefined,
        payMethod: !paramsCreation.isOnRevision ? 99 : undefined,
        currency: !paramsCreation.isOnRevision
          ? currencyDocument.toLocaleUpperCase()
          : undefined,
        importe,
        iva,
        total: truncateDecimals(iva + importe, 2),
        messageAuthorization: paramsCreation.message,
      }));

      // if(applyRevisionSettings && paramsCreation.invoice.auth.requiresExchange){
      //   updateCurrencyToggle(true)
      // }

      ////////////////////////////////////////////////////////////////// 

      /**
       * If `null` , there's no need of validate the time creation or display the UI after authorization
       * @returns {null|Date}
       */
      function retrieveLimitCreationTime() {
        if (paramsCreation.isOnRevision) return null;

        if (paramsCreation.invoice.auth.limitCreationTime === null) return null;

        const dateFromDb = getDateFromUtc(
          paramsCreation.invoice.auth.limitCreationTime
        );

        if (new Date() < dateFromDb) return dateFromDb;

        return null;
      }

      /**
       * Check if the component should indicate a currency exchange on automatic.
       * 
       * If true, must apply the settings of the revision
       * If false, no revission attempted or revision time ended. Must follow the 'regular' flow work
       * @param {Date} limitCreationTime 
       * @returns {boolean}
       */
      function checkIfApplyCurrencyExchange(limitCreationTime){
        if(limitCreationTime instanceof Date){
          return true
        }

        return false
      }
    })();
  }, []);

  useEffect(()=>{
    if(state.isLoading) return

    if(state.currencyExchange){
      updateCurrencyToggle(true)
    }

  },[state.isLoading])

  useEffect(() => {
    if (state.isLoading || !state.isValidStatus) return;

    const currencyKey = state.currency.toLocaleLowerCase();

    const importe =
      order.document.moneyInfo[`${currencyKey}`].importSell.number;
    const iva = order.document.moneyInfo[`${currencyKey}`].ivaSell.number;
    const total = truncateDecimals(importe + iva, 2);

    setState((current) => ({
      ...current,
      importe,
      iva,
      total,
    }));
  }, [
    order.document.moneyInfo.mxn,
    order.document.moneyInfo.usd,
    state.currency,
  ]);

  // /**
  //  * Update if apply currency exchange on the order document
  //  * @param {boolean} currencyExchange - If true, user want's apply exchange the currency of the order/invoice
  //  */
  // const updateCurrencyToggle = (currencyExchange) => {
  //   const currencyToUse = state.currency === "USD" ? "MXN" : "USD";
  //   const key = currencyToUse.toLocaleLowerCase();
  //   const importe = order.document.moneyInfo[`${key}`].importSell.number;
  //   const iva = order.document.moneyInfo[`${key}`].ivaSell.number;

  //   setState((current) => ({
  //     ...current,
  //     currencyExchange,
  //     tc: current.tcOrder,
  //     currency: currencyToUse,
  //     importe,
  //     iva,
  //     total: truncateDecimals(importe + iva, 2),
  //   }));

  //   order.functions.handleOnDocumentCurrencyChange({
  //     id: currencyToUse === "MXN" ? 1 : 2,
  //     value: currencyToUse,
  //   });
  // };

  /**
   * Update the tc to use on the currency exchange
   * @param {number} tc - TC to use
   * @returns {void}
   */
  const updateTc = (tc) => {
    setState((current) => ({
      ...current,
      tc,
    }));
  };
  
  useEffect(()=>{
    console.log(state.tc);
    order.functions.handleOnChangeTc(state.tc);
  },[state.tc])

  /**
   * Update the partialities to use
   * @param {number} partialities - Partialities requested
   * @returns {void}
   */
  const updatePartialities = (partialities) =>
    setState((current) => ({
      ...current,
      partialities,
    }));

  /**
   * Update the cfdi to use on the invoice creation
   * @param {string} Value - CFDI information
   * @returns {void}
   */
  const updateCfdi = (Value) =>
    setState((current) => ({
      ...current,
      cfdi: Value,
    }));

  /**
   * Update the pay form to use on the creation of invoice
   * @param {string} payForm - Pay form of the invoice
   * @returns {void}
   */
  const updatePayForm = (payForm) =>
    setState((current) => ({
      ...current,
      payForm,
    }));

  /**
   * Update the pay method to use on the invoice
   * @param {number|string} payMethod - Id of the pay method
   * @returns {void}
   */
  const updatePayMethod = (payMethod) =>
    setState((current) => ({
      ...current,
      payMethod,
    }));

  const attemptCreateInvoice = async () => {
    setState((current) => ({
      ...current,
      isCreating: true,
    }));

    const currencyToUse = state.currency.toLocaleLowerCase();

    const recalculatedItems = order.document.items.map((partida) => handleFormItem({
      cost:partida.cu.number,
      currency:partida.currency.code,
      description:partida.description,
      discountCost:partida.providerDiscount.number,
      discountSell:partida.clientDiscoount.number,
      idCatalogue:partida.catalogue.id,
      iva:partida.iva.number,
      ivaExempt:partida.iva.exempt,
      quantity:partida.quantity,
      satCode:partida.satCode,
      satUm:partida.satUm,
      satCodeDescription:partida.satCodeDescription,
      satUmDescription:partida.satUmDescription,
      sell:partida.pu.number,
      sku:partida.sku,
      uen:partida.uen.id,
      id:partida.id,
    },state.tc,2,{
      rfc:'XX',
      typeDocument:2
    }));


    let parsedItems = [];

    try {
      /**
       * @type {import("./types").Item[]}
       */
      parsedItems = order.document.items.map((partida, i) => ({
        id: partida.id,
        description: partida.description,
        satUmDescription: partida.satUmDescription,
        satCodeDescription: partida.satCodeDescription,
        pu: {
          number: partida.pu.number,
          text: partida.pu.text,
          discount: truncateDecimals(
            (partida.pu.number * partida.clientDiscoount.number) / 100,
            2
          ),
        },
        cu: {
          number: partida.cu.number,
          text: partida.cu.text,
          discount: truncateDecimals(
            (partida.cu.number * partida.providerDiscount.number) / 100,
            2
          ),
        },
        satCode: partida.satCode,
        satUm: partida.satUm,
        iva: {
          number: partida.iva.number,
          text: partida.iva.text,
          exempt: partida.iva.exempt,
        },
        sku: partida.sku,
        uen: {
          id: partida.uen.id,
          description: partida.uen.description,
          family: partida.uen.family,
          marginRate: partida.uen.marginRate,
        },
        catalogue: {
          id: partida.catalogue.id,
          description: partida.catalogue.description,
        },
        currency: partida.currency,
        quantity: {
          isValid: true,
          quantity: partida.quantity,
        },
        discount: partida.clientDiscoount.number,
        calculations: {
          price: {
            unitary: partida[`${currencyToUse}`].pu.totalUnit.number,
            sell: partida[`${currencyToUse}`].pu.realUnit.number,
            iva: partida[`${currencyToUse}`].pu.totalIva.number,
            import: partida[`${currencyToUse}`].pu.total.number,
            subtotal: truncateDecimals(
              partida[`${currencyToUse}`].pu.total.number +
                partida[`${currencyToUse}`].pu.totalIva.number,
              2
            ),
            discount:
              calculateDiscountToApply(
                partida[`${currencyToUse}`].pu.totalUnit.number,
                partida.clientDiscoount.number
              ) * partida.quantity,
          },
          cost: {
            unitary: partida[`${currencyToUse}`].cu.totalUnit.number,
            sell: partida[`${currencyToUse}`].cu.realUnit.number,
            iva: partida[`${currencyToUse}`].cu.totalIva.number,
            import: partida[`${currencyToUse}`].cu.total.number,
            subtotal: truncateDecimals(
              partida[`${currencyToUse}`].cu.total.number +
                partida[`${currencyToUse}`].cu.totalIva.number,
              2
            ),
            discount:
              calculateDiscountToApply(
                partida[`${currencyToUse}`].cu.totalUnit.number,
                partida.providerDiscount.number
              ) * partida.quantity,
          },
        },
        utility: 0,
        uuid: partida.uuid,
        label: partida.catalogue.description,
        value: partida.catalogue.id,
        isNewItem: false,
        order: i + 1,
      }));
    } catch (e) {
      console.log(e);
      Error(() => {}, "Vuelve a presionar el boton de 'Timbrar'");
      setState((current) => ({
        ...current,
        isCreating: false,
      }));
      return;
    }

    const taxas = getTaxas(parsedItems);

    const impuestos16 = gridImpuestos(
      taxas.iva16.iva.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );
    const impuestos8 = gridImpuestos(
      taxas.iva8.iva.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );

    const impuestos0 = gridImpuestos(
      taxas.iva0.iva.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );

    const importe16 = gridImpuestos(
      taxas.iva16.importe.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );

    const importe8 = gridImpuestos(
      taxas.iva8.importe.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );

    const importe0 = gridImpuestos(
      taxas.iva0.importe.sell,
      state.partialities,
      1,
      state.tc,
      new Date()
    );

    /**
     * @type {import("types/typedef/customHooks/usePartialities").PartialitieV2I[]}
     */
    const parsedCxc = state.cxc.partialitiesInfo.map((cxc, i) => ({
      ...cxc,
      importe: {
        porcentaje16: importe16[i].price,
        porcentaje8: importe8[i].price,
        porcentaje0: importe0[i].price,
        cxc: +(
          importe16[i].price +
          importe8[i].price +
          importe0[i].price
        ).toFixed(2),
      },
      ivas: {
        iva16: impuestos16[i].price,
        iva8: impuestos8[i].price,
        iva0: impuestos0[i].price,
        cxc: +(
          impuestos16[i].price +
          impuestos8[i].price +
          impuestos0[i].price
        ).toFixed(2),
      },
      distribucion: {
        importe16: +(
          (100 * (importe16[i].price + impuestos16[i].price)) /
          cxc.price
        ).toFixed(2),
        importe8: +(
          (100 * (importe8[i].price + impuestos8[i].price)) /
          cxc.price
        ).toFixed(2),
        importe0: +(
          (100 * (importe0[i].price + impuestos0[i].price)) /
          cxc.price
        ).toFixed(2),
      },
      taxCode: {
        code16: "002",
        code0: "002",
        code8: "002",
      },
      description: {
        description0: "IVA",
        description16: "IVA",
        description8: "IVA",
      },
      isRetention: {
        isRetention0: false,
        isRetention16: false,
        isRetention8: false,
      },
      region: {
        region0: "Federal",
        region16: "Federal",
        region8: "Federal",
      },
      factorType: {
        factorType0: "Tasa",
        factorType16: "Tasa",
        factorType8: "Tasa",
      },
    }));

    /**
     * @type {import("./types").DtoCreateInvoice}
     */
    const dto = {
      receiver:state.receiverData,
      tcp: state.tc,
      amounts: {
        import: state.importe,
        iva: state.iva,
        total: state.total,
      },
      cxc: {
        isValid: true,
        partialitiesInfo: parsedCxc,
      },
      items: parsedItems,
      cfdi: state.cfdi,
      payMethod: +state.payMethod <= 9 ? `0${state.payMethod}` : `${state.payMethod}`,
      payForm: state.payForm,
      partialities: state.partialities,
      requireCurrencyExchange: state.currencyExchange,
      idOrder: id,
      invoice: {
        cfdi: state.cfdi,
        payForm: state.payForm === "PPD" ? 1 : 2,
      },
      impuestos: taxas,
    };

    const wasCreated = await createInvoice(dto);

    if (wasCreated) {
      onCreated();
    }else{
      onError()
    }

    setState((current) => ({
      ...current,
      isCreating: false,
    }));
  };

  /**
   * Update the cxc partialities
   * @param {import("types/typedef/customHooks/usePartialities").onChangePartialitieI} dto - Information of the partialities
   * @returns {void}
   */
  const updatePartialitiesCxc = (dto) =>
    setState((current) => ({
      ...current,
      cxc: dto,
    }));
  
  /**
   * Set the receiver for the invoice to create
   * @param {import("../../../../../server/types/facturama").CorporativeInvoiceItem} receiver - Information of the receiver, person who will have the invoice
   * @returns {void}
   */
  const setReceiver = receiver => setState(current=>({
    ...current,
    receiverData:receiver
  }))

  return {
    ...state,
    updateCurrencyToggle,
    updatePartialitiesCxc,
    updateTc,
    updateCfdi,
    updatePartialities,
    updatePayForm,
    attemptCreateInvoice,
    updatePayMethod,
    setReceiver
  };
}
