import React from "react";
import scss from "../styles.module.scss";

/**
 * @param {import('./types').PropsNoDeductible} props - Props
 * @returns {JSX.Element}
 */
export const NoDeductible = ({
  association,
  isCheck = false,
  onChange = () => {},
  currency = "??"
}) => {
  return (
    <label className={scss.row} htmlFor={`no-deducible-${association.id}`}>
      <div>
        <input
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
          id={`no-deducible-${association.id}`}
          name="expense-disassociation"
          checked={isCheck}
        />
      </div>
      <div>
        <p>-</p>
      </div>
      <div>
        <p>-</p>
      </div>

      <p>{association.expense.description}</p>
      <p>-</p>
      <p>
        {association.applied.text} {association.expense.currency}
      </p>
      <p>{association.paid.text} {currency}</p>
    </label>
  );
};
