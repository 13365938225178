import React, { useContext, useEffect } from "react";
import uiManufacturer from "../styles.module.scss";
import { ContextManufacturer } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function EditMode() {
  const hook = useContext(ContextManufacturer);

  useEffect(() => {
    hook.setKey(hook.manufacturer.keyValue);
  }, []);

  return (
    <>
      <input
        onChange={(e) => hook.setKey(e.target.value)}
        type="text"
        disabled={hook.isSaving}
        placeholder="Escribe aquí"
        value={hook.keyValue}
      />

      {hook.isSaving ? (
        <Spinner text={"Actualizando"} hidden={false} />
      ) : (
        <div className={uiManufacturer.actions}>
          <button
            disabled={hook.keyValue.length <= 0}
            type="button"
            onClick={hook.performUpdate}
            className={uiManufacturer.action}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <button
            className={uiManufacturer.action}
            type="button"
            onClick={(e) => hook.setEditMode(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
}
