import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import DistributionList from "structure/DistributionList";

export default function ScreensHome() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "general") return <></>;

  return (
    <>
      <NavigationItem icon="home" label="Inicio" link="/inicio" />
      <NavigationItem
        icon="administration"
        label="Adm. del sistema"
        subMenu="adminSistemas"
      />
      <NavigationItem icon="banks" label="Bancos" subMenu="banks" />
      <NavigationItem
        icon="user"
        label="Cambio de ejecutivo"
        link="/representante"
      />
      <NavigationItem icon="sells" label="Compras" subMenu="purchase" />
      <NavigationItem
        icon="sells"
        label="Contabilidad"
        subMenu="conciliacion"
      />
      <NavigationItem icon="directory" label="Directorio" link="/directorio" />
      <NavigationItem
        icon="specialProcess"
        label="Procesos especiales"
        subMenu="procesos_especiales"
      />

      <DistributionList>
        <NavigationItem icon="noteCreditEmitted" label="Lista Distribución" />
      </DistributionList>

      <NavigationItem icon="sells" label="Ventas" subMenu="ventas" />
    </>
  );
}
