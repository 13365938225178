import { Modal } from "components/general/Modal";
import React, { createContext } from "react";
import useEgressOverview from "customHooks/useEgressOverview";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import PerformCancel from "./PerformCancel";
import { mnCurrency } from "helpers/money";
import { Spinner } from "components/individual/Spinner/Spinner";
import Gastos from "../Gastos";
import { parseDateToText } from "helpers/dates";
import usePermissions from "customHooks/usePermissions";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import { useContext } from "react";

/**
 * @type {import("react").Context<import("customHooks/useEgressOverview/types").ReturnEgressOverview|undefined>}
 */
export const CancelEgressContext = createContext(undefined);

/**
 * @param {import('./types').PropsMoreInfo} props - Props
 * @returns {JSX.Element}
 */
export default function Cancel({
  movement,
  onSuccess = () => {},
  children = <></>,
}) {
  const hook = useEgressOverview(movement, onSuccess);

  const { checkPermission } = usePermissions(
    "5388c282-33e5-41b9-a429-f892025723a3"
  );

  if (movement === undefined || movement === null) return <></>;

  // Movement cancelled, can't display more information because no associations
  if (movement.status.id === 4) return <></>;

  if (!checkPermission("5388c282-33e5-41b9-a429-f892025723a3")) return <></>;

  return (
    <CancelEgressContext.Provider value={{ ...hook }}>
      {children}
    </CancelEgressContext.Provider>
  );
}

export function GridConcepts() {
  const hook = useContext(CancelEgressContext);

  return <Gastos items={hook.gastos} hook={hook} type="gasto" />;
}

export function GridInvoices() {
  const hook = useContext(CancelEgressContext);

  return <Gastos items={hook.odc} hook={hook} type="odc" />;
}
