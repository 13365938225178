import React, { useContext } from "react";
import RangeDate from "../RangeDate";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import ui from "./styles.module.scss";
import PillFilterMobile from "components/individual/PillFilterMobile";
import { ContextCxpReport } from "..";
import { parseDateToText } from "helpers/dates";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";
import Search from "../Search";

export default function FilterMobile() {
  const hook = useContext(ContextCxpReport);

  const dialog = useDialog();

  return (
    <div className={ui.mobileFilter}>
      <ButtonV2 onClick={dialog.open}>
        <Icon icon="filter" />
      </ButtonV2>

      <Dialog
        title="Filtros"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
        footer={
          <div className={ui.footer}>
            <ButtonV2
              disabled={!hook.isValidDate}
              onClick={(e) => {
                dialog.close();
                hook.refetchTable();
              }}
            >
              <Icon icon="search" />
            </ButtonV2>
          </div>
        }
      >
        <div className={ui.content}>
          <Search />
          <RangeDate />
        </div>
      </Dialog>

      <PillFilterMobile
        label="Desde"
        value={hook.from === null ? "NA" : parseDateToText(hook.from)}
      />
      <PillFilterMobile
        label="Hasta"
        value={hook.to === null ? "NA" : parseDateToText(hook.to)}
      />
    </div>
  );
}
