import useSetup from "customHooks/useSetup";
import React from "react";
import style from "../../UEN/styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

/**
 * Render the buttons to download and load the template excel
 * @param {import("./types").PropsFooter} props - Props
 * @returns {JSX.Element}
 */
export default function Footer({ file, type, onUploaded = () => {} }) {
  const hook = useSetup(type);

  return (
    <div className={style.footer}>
      {hook.isLoading ? (
        <Spinner
          idSpinner="tempalteLoading"
          hidden={false}
          text={<LoadingText>Descargando plantilla</LoadingText>}
        />
      ) : hook.isUpdating ? (
        <span></span>
      ) : (
        <span className="hyperlink" onClick={hook.download}>Descarga la plantilla aqui</span>
      )}

      {file === undefined ? (
        <p></p>
      ) : hook.isUpdating ? (
        <Spinner
          idSpinner="updatingTemplate"
          hidden={false}
          text={<LoadingText>Actualizando</LoadingText>}
        />
      ) : (
        <ButtonV2
          onClick={async () => {
            const wasUploaded = await hook.upload(file);
            onUploaded();
          }}
        >
          <span>Cargar excel</span>
          <Icon icon="save" />
        </ButtonV2>
      )}
    </div>
  );
}
