import React from "react";
import { useRef } from "react";
import { useState } from "react";
import ReceptionOverview from "structure/ReceptionOverview";
import { Modal } from "../Modal";
import scss from "./styles.module.scss";

/**
 * Render a modal for more information about an invoice
 * @param {import("./types").PropsModalInvoiceReception} props - Props
 * @returns {JSX.Element}
 */
export const ModalInvoiceReception = ({
  uuid = "f4097d8b-1f9d-46d9-8a0d-b235045cd97d",
  children = <></>,
  forwardRef = undefined
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useRef(window.crypto.randomUUID());
  const open = () => {
    document.getElementById(id.current)?.click();
    setIsOpen(true);
  };

  return (
    <>
      <div ref={forwardRef} className={scss.folio} onClick={open}>{children}</div>
      <Modal
        onCloseModal={() => setIsOpen(false)}
        width={85}
        title="Más informacion"
        propsOpenModal={{
          style: {
            display: "none",
          },
          id: id.current,
        }}
      >
        {({ closeModal }) => (
          <div className={scss.container}>
            {isOpen ? <ReceptionOverview uuid={uuid} /> : null}
          </div>
        )}
      </Modal>
    </>
  );
};
