import {
  validateXmlAgainstSat,
  validateXmlPraxia,
} from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { useEffect, useState } from "react";

/**
 *
 * @param {(object:import("types/typedef/customHooks/useAddXml").onChangeUseAddXmlI)=>void} onChange - Callback when file changes
 * @param {import("pages/Administration/InvoiceReception/Add/types").TypeXml} type - Type of xml
 * @returns
 */
export default function useAddXml(onChange = () => {}, type = "I") {
  /**
   * @type {[import("types/typedef/customHooks/useAddXml").useAddXmlI,()=>void]}
   */
  const [state, setState] = useState({
    isLoadedFile: false,
    isValidXml: false,
    errorMessages: {
      uuid: {
        isValid: false,
        message: null,
      },
      rfcReceptor: {
        isValid: false,
        message: null,
      },
      status: {
        isValid: false,
        message: null,
      },
    },
    xmlInfo: null,
    isValidatingXml: false,
  });

  /**
   * Set xml info on state
   * @param {import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI} xmlInfo - Info xml
   * @returns {void}
   */
  const setXmlInfo = (xmlInfo) =>
    setState({
      ...state,
      xmlInfo: xmlInfo,
    });

  useEffect(() => {
    (async function () {
      // debugger;
      if (
        !state.xmlInfo ||
        !state.xmlInfo.isValidFileUpload ||
        !state.xmlInfo.extractedXml
      ) {
        if (state.xmlInfo === null) return;

        onChange({
          isValid: false,
          itsOnlyExpenses: false,
          providers: [],
          xmlInfo: null,
          pdf: state.xmlInfo?.pdf === undefined ? null : state.xmlInfo.pdf,
          xml: null,
        });

        setState({
          ...state,
          isValidXml: false,
          isValidatingXml: true,
          xmlInfo: null,
          errorMessages: {
            uuid: {
              isValid: false,
              message: null,
            },
            rfcReceptor: {
              isValid: false,
              message: null,
            },
            status: {
              isValid: false,
              message: null,
            },
          },
        });

        return;
      }

      console.log({xmlInfo:state.xmlInfo});

      const tipoDeComprobante =
        state.xmlInfo.xmlJson["cfdi:Comprobante"]["$"]["TipoDeComprobante"];

      if (tipoDeComprobante !== type) {
        setState((current) => ({
          ...current,
          isValidatingXml:false,
          isValidXml:false,
          errorMessages: {
            ...current.errorMessage,
            status: {
              isValid: false,
              message: `No puedes subir esta factura porque es de tipo "${tipoDeComprobante}", solo puedes cargar xml de tipo "${type}"`,
            },
          },
        }));
        return
      }

      const { isValid, status } = await validateXmlAgainstSat(
        state.xmlInfo.extractedXml.rfcEmitter,
        state.xmlInfo.extractedXml.rfcReceptor,
        state.xmlInfo.extractedXml.total.number,
        state.xmlInfo.extractedXml.uuid
      );

      setState({
        ...state,
        errorMessages: {
          ...state.errorMessages,
          status: {
            isValid: isValid,
            message: `El estatus del documento es '${status}'`,
          },
        },
        isValidXml: isValid,
        isValidatingXml: false,
      });

      if (!isValid) {
        onChange({
          isValid: false,
          itsOnlyExpenses: false,
          providers: [],
          xmlInfo: null,
          pdf: state.xmlInfo.pdf,
          xml: null,
        });
        return;
      }

      const { errorMessage, itsOnlyExpenses, providers } =
        await validateXmlPraxia(
          state.xmlInfo.extractedXml.rfcReceptor,
          state.xmlInfo.extractedXml.rfcEmitter,
          state.xmlInfo.extractedXml.uuid
        );

      if (!!errorMessage) {
        setState({
          ...state,
          errorMessages: {
            ...state.errorMessages,
            status: {
              isValid: false,
              message: errorMessage,
            },
          },
          isValidXml: false,
          isValidatingXml: false,
        });

        onChange({
          isValid: false,
          itsOnlyExpenses: false,
          providers: [],
          xmlInfo: null,
          pdf: state.xmlInfo.pdf,
          xml: null,
        });
        return;
      }

      setState({
        ...state,
        errorMessages: {
          ...state.errorMessages,
          status: {
            isValid: true,
            message: null,
          },
        },
        isValidXml: true,
        isValidatingXml: false,
      });

      onChange({
        isValid: true,
        itsOnlyExpenses,
        providers,
        xmlInfo: state.xmlInfo.extractedXml,
        pdf: state.xmlInfo.pdf,
        xml: state.xmlInfo.xml,
      });
      
    })();
  }, [state.xmlInfo]);

  return {
    setXmlInfo,
    errorMessages: state.errorMessages,
    isValidXml: state.isValidXml,
    xmlInfo: state.xmlInfo,
    isValidFileUpload: !state.xmlInfo ? false : state.xmlInfo.isValidFileUpload,
    isValidatingXml: state.isValidatingXml,
  };
}
