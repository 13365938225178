import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { Success, Error } from "../alerts";
import "../../types/typedef/associateFiles";

import { BlobServiceClient } from "@azure/storage-blob";

/**
 *
 * Delete the file from the database
 *
 * @param {string|number} idFile - Id of the file to delete
 * @param {string} editedBy - Name of the person who performed the delete
 * @returns {boolean} True if file was deleted from database
 */
export async function DeleteFileFromDb(idFile, editedBy) {
  try {
    const { data } = await axios.delete(
      `${URL_BASE}archivos/${idFile}?editedBy=${editedBy}`
    );

    if (data.status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Delete the file from blob storage
 *
 * @param {string} urlPath - Url of the file to delete
 * @returns {boolean} True if was deleted from blob storage
 */
export async function DeleteFromBlobStorage(urlPath) {
  try {
    const credentials = await GetBlobCred();

    const blobParams = SplitUrlBlob(urlPath, credentials.urlStorage);

    // Resources to learn: https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/javascript/basic.js
    const blobServiceClient = new BlobServiceClient(
      `${credentials.urlStorage}?${credentials.sasUrl}`
    );

    let containerClient = blobServiceClient.getContainerClient(
      blobParams.containerPath
    );

    await containerClient.deleteBlob(blobParams.fileNameBlob);

    return true;
  } catch (error) {
    console.log(error);

    return false;
  }
}

/**
 *
 * Download media from the blob storage
 *
 * @param {object} BlobConfigs - Blob storage configuration and data
 * @param {string} BlobConfigs.sas - A shared access signature (SAS) enables you to grant limited access to containers and blobs in your storage account.
 * @param {string} BlobConfigs.path - Path of the folder (the path must be without the container name)
 * @param {string} BlobConfigs.fileName - File name with his extension (the one which it's on azure)
 * @param {string} BlobConfigs.url - URL of the storage (root path)
 * @param {string} BlobConfigs.extension - File extension that will be download (without the .)
 */
export async function DownloadFile({ fileName, extension, urlPath }) {
  try {
    const credentials = await GetBlobCred();

    const blobParams = SplitUrlBlob(urlPath, credentials.urlStorage);

    // Resources to learn: https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/javascript/basic.js
    const blobServiceClient = new BlobServiceClient(
      `${credentials.urlStorage}?${credentials.sasUrl}`
    );

    let containerClient = blobServiceClient.getContainerClient(
      blobParams.containerPath
    );

    let blobClient = containerClient.getBlobClient(blobParams.fileNameBlob);

    const downloadBlockBlobResponse = await blobClient.download();

    const downloaded = await downloadBlockBlobResponse.blobBody;

    DownloadBlob(downloaded, `${fileName}.${extension}`);
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 *
 * @param {string} urlBlob - Url of the blob (path where it's located to source in order to download it)
 * @param {string} urlBase - Root url of the container azure blob storage
 * @returns {PathInfo} Splitted information to download file
 * @example
 * // urlBlob -> https://saikostorage.blob.core.windows.net/saikocontainer/Documentos/483/58e0c2b4-53d7-4234-a4d4-d080c343aa6a.jpg
 *
 * // urlBase -> https://saikostorage.blob.core.windows
 *
 */
export function SplitUrlBlob(urlBlob, urlBase) {
  const baseUrlLength = urlBase.length;

  const posLastSlash = urlBlob.lastIndexOf("/");

  // Returns something like: /saikocontainer/Documentos/483
  const containerPath = urlBlob.substring(baseUrlLength + 1, posLastSlash);

  // Returns something like: /quotationMizar.pdf
  const fileName = urlBlob.substring(posLastSlash + 1, urlBlob.length);

  return {
    fileNameBlob: fileName,
    containerPath,
  };
}

/**
 * Download the blob fetched into local machine
 *
 * @param {Blob} blob - Blob of the fetched content (internet, azure blob storage, cloudinary , etc.)
 * @param {string} fileName - Name will have when it's downlaoded (must include the extension)
 */
export function DownloadBlob(blob, fileName) {
  // Resources: https://stackoverflow.com/questions/25547475/save-to-local-file-from-blob

  // Blob url string
  const blobUrl = URL.createObjectURL(blob);

  // Create a link
  const link = document.createElement("a");

  // Set the link with the url blob string
  link.href = blobUrl;

  // Set the name to file
  link.download = fileName;

  // Download the media
  link.click();
}

/**
 * Get the credentials in order to perform actions on blob storage (if you IP or cors block you, this credentials
 * wont be very useful)
 *
 * @returns {BlobCred|null} Credentials
 */
export async function GetBlobCred() {
  try {
    const { data } = await axios.get(`${URL_BASE}archivos/blob/credenciales`);

    if (data.status === 200) {
      return data.credentials;
    }

    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Associate the files uploaded to azure to database
 *
 * @param {object} info - Information to associate the files uploaded to azure
 * @returns {boolean} True if the insertion was success on DB
 */
export async function AssociateFilesDb(info) {
  try {
    const { data } = await axios.post(`${URL_BASE}archivos`, info);

    if (data.status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * Insert a new comment to an specific file
 * @param {object} commentData - Comment information
 * @returns {boolean} True if insertion was correct
 */
export async function AddComment(commentData) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}archivos/comentario`,
      commentData
    );

    if (data.status === 200) return data.commentInserted;

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the comments made to a file
 *
 * @param {number|string} idFile - If of the file
 * @param {number|string} registers - Since which range of the registers start fetching the comments
 * @returns {CommentFile[]} Comments of the file
 */
export async function GetFileComments(idFile, registers = 0) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}archivos/comentarios/ver?id=${idFile}&registros=${registers}`
    );

    if (data.status === 200) {
      return data.comments;
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Fetch the files on the system
 *
 * @param {number} page - Page requested
 * @param {'ASC'|'DESC'} order - ASC or DESC order
 * @param {string} columnOrdering - Column to use in order to sort the requested data
 * @param {string} aditionalQuery - Extra query params
 * @returns {FilesFetched} Files founded
 */
export async function GetFiles(page, order, columnOrdering, aditionalQuery) {
  const errrorResponse = {
    files: [],
    pages: 0,
    actualPage: 0,
  };

  const status = document.getElementById("filterFiles");
  const statusQuery =
    status === null ? "&estatus=1" : `&estatus=${status.value}`;

  try {
    const { data } = await axios.get(
      `${URL_BASE}archivos/${aditionalQuery}?pagina=${page}${statusQuery}`
    );

    if (data.status === 200) {
      return data.data;
    } else {
      return errrorResponse;
    }
  } catch (error) {
    return errrorResponse;
  }
}

/**
 * Get the overview info of the file
 *
 * @param {number|string} idDocument - Id of the document
 * @param {number|string} idCustomer - Id of the customer
 * @returns {OverviewFile | null} Information of the overview file
 */
export async function GetOverviewFileInfo(idDocument, idCustomer) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}archivos/overview?idCustomer=${idCustomer}&idDocument=${idDocument}`
    );

    if (data.status === 200) {
      return data.overviewInfo;
    } else {
      return null;
    }
  } catch (error) {
    Error(() => {}, error);

    return null;
  }
}

/**
 * Get all the associated files of a document
 * @param {number} idDocument - Id document
 * @returns {import("types/typedef/files").AssociatedFileI[]} Files
 */
export async function getAllAssociatedFiles(idDocument) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}archivos/documentos-asociados/${idDocument}`,
      { withCredentials: true }
    );

    return data.files;
  } catch (e) {
    const { response } = e;

    Error(() => {}, response.data.message);
  }
}
