import GridImports from "components/general/GridImports";
import React, { useContext, useEffect, useState } from "react";
import { AddReceptionInvoiceContext } from "../../Filter";
import { Step2Header } from "./styles";
import { getAllOcDocumentProvider } from "helpers/Apis/Documents";
import { mnCurrency } from "helpers/money";
import { InvoiceReceptionConcept } from "structure/InvoiceReceptionConcept";
import { OptionsToGird } from "../Components/OptionsToGird";

export default function Step2() {
  const {
    state,
    setGridInfo: setGrid,
    setShowGrid,
    setConcept,
    setItsOnlyExpenses,
  } = useContext(AddReceptionInvoiceContext);

  const [ocs, setOcs] = useState([]);

  /**
   * @type {[import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI,()=>void]}
   */
  const [gridInfo, setGridInfo] = useState(null);

  /**
   * Set grid info
   * @param {import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI} gridInfo - Info grid
   */
  const handleOnChange = (gridInfoParam) => {
    setGridInfo(gridInfoParam);
    setGrid(gridInfoParam);
  };

  useEffect(() => {
    (async function () {
      const ocsApi = await getAllOcDocumentProvider(
        state.xmlInfo.xmlInfo.rfcEmitter,
        state.providerId
      );
      setOcs(ocsApi);

      ocsApi.length >= 1 ? setItsOnlyExpenses(false) : setItsOnlyExpenses(true);
    })();
  }, []);

  return (
    <>
      {state.xmlInfo.itsOnlyExpenses === true ? null : state.gridInfo ===
        null ? (
        !state.xmlInfo.itsOnlyExpenses && (
          <OptionsToGird checked={state.showExpenses} onChange={setShowGrid} />
        )
      ) : state.gridInfo.newResidue === state.xmlInfo.xmlInfo.total.number ? (
        <OptionsToGird checked={state.showExpenses} onChange={setShowGrid} />
      ) : null}
      <Step2Header>
        <p>
          <b>Factura: </b> <span>{state.xmlInfo.xmlInfo.folio}</span>
        </p>
        <p>
          <b>Razon social: </b>
          <span>{state.xmlInfo.xmlInfo.socialReasonCustomer}</span>
        </p>

        <p>
          <b>RFC: </b>
          <span>{state.xmlInfo.xmlInfo.rfcEmitter}</span>
        </p>

        <p>
          <b>Moneda: </b>
          <span>{state.xmlInfo.xmlInfo.currency}</span>
        </p>

        <p>
          <b>Total: </b>
          <span>{state.xmlInfo.xmlInfo.total.formatted}</span>
        </p>

        <p>
          <b>Saldo por asociar: </b>
          <>
            {gridInfo === null ? (
              <span>{state.xmlInfo.xmlInfo.total.formatted}</span>
            ) : (
              <span className={gridInfo.newResidue < 0 ? "text-danger" : ""}>
                {mnCurrency(gridInfo.newResidue)}
              </span>
            )}
          </>
        </p>
      </Step2Header>

      {state.xmlInfo.itsOnlyExpenses === true ? (
        <div
          style={{
            height: "35vh",
            overflow: "auto",
            margin: "15px 0",
            padding: "0 15px",
          }}
        >
          <InvoiceReceptionConcept
            importe={state.xmlInfo.xmlInfo.total.number}
            currency={state.xmlInfo.xmlInfo.currency}
            onChange={(data) => setConcept(data)}
          />
        </div>
      ) : 
      state.showExpenses ? (

        <div
          style={{
            height: "35vh",
            overflow: "auto",
            margin: "15px 0",
            padding: "0 15px",
          }}
        >
          <InvoiceReceptionConcept
            importe={state.xmlInfo.xmlInfo.total.number}
            currency={state.xmlInfo.xmlInfo.currency}
            onChange={(data) => setConcept(data)}
          />
        </div>
      ) : (
        <GridImports
          residue={state.xmlInfo.xmlInfo.total.number}
          headers={[
            "ODC",
            "Cliente",
            "Moneda",
            "Total",
            "Importe de facturas previas",
            "Importe por esta factura",
            "TC aplicado",
            "Importe pendiente",
          ]}
          amountsInfo={ocs}
          // toleranceProp={5.99}
          onChange={handleOnChange}
          currency={state.xmlInfo.xmlInfo.currency}
        >
          {({ index }) => (
            <>
              <GridImports.DocNumber index={index} />

              <div className="d-flex justify-content-start align-items-center">
                {ocs[index].client.shortName}
              </div>

              <GridImports.Currency index={index} />

              <GridImports.Total index={index} />
              <div className="d-flex justify-content-center align-items-center">
                {mnCurrency(
                  ocs[index].total.number - ocs[index].residue.number
                )}
              </div>
              <GridImports.InputImport index={index} label="" />
              <GridImports.InputTC
                index={index}
                label=""
                bankCode={state.xmlInfo.xmlInfo.currency}
              />
              <GridImports.Saldo index={index} />
            </>
          )}
        </GridImports>
      )}
    </>
  );
}
