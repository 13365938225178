import React from "react";
import style from "./styles.module.scss";
import { mnCurrency } from "helpers/money";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import AbcBank from "structure/Menu/Banks/AbcBank";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import BankAccountReportV2 from "structure/BankAccountReportV2";
import MovementReportV2 from "structure/MovementReportV2";

/**
 * Render a card for the mobile view to render an item of bank account
 * @param {import('./types').PropsMobileCard} props - Props
 * @returns {JSX.Element}
 */
export default function MobileCard({
  account,
  bank,
  currency,
  id,
  residue,
  bankKey,
}) {
  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domAbc = useRef(null);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInformation = useRef(null);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domBankReport = useRef(null);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMovementReport = useRef(null);

  const history = useHistory();

  const redirectToIncoming = () =>
    history.push(`/v2/bancos/ingresos?cuenta=${id}`);
  const redirectToEgresses = () =>
    history.push(`/v2/bancos/egresos?cuenta=${id}`);
  const redirectToConcilation = () =>
    history.push(`/v2/bancos/conciliacion?cuenta=${id}`);

  return (
    <>
      <AbcBank forwardRef={domAbc} id={id} />
      <AbcBank
        isForMoreInformation={true}
        forwardRef={domMoreInformation}
        id={id}
      />

      <BankAccountReportV2 id={id} forwardRef={domBankReport} />
      <MovementReportV2 id={id} forwardRef={domMovementReport} />

      <div className={style.mobileBankAccount}>
        <span>{currency}</span>
        <span>
          {bankKey} - {bank}
          <ElipsisMobile>
            <CustomMenuItem onClick={() => domMoreInformation.current.click()}>
              Más información
            </CustomMenuItem>
            <CustomMenuItem onClick={() => domAbc.current.click()}>
              Editar
            </CustomMenuItem>
            <CustomMenuItem onClick={redirectToIncoming}>
              Ingresos
            </CustomMenuItem>
            <CustomMenuItem onClick={redirectToEgresses}>
              Egresos
            </CustomMenuItem>
            <CustomMenuItem onClick={redirectToConcilation}>
              Conciliación
            </CustomMenuItem>
            <CustomMenuItem onClick={() => domBankReport.current.click()}>
              Estado de cuenta
            </CustomMenuItem>
            <CustomMenuItem onClick={() => domMovementReport.current.click()}>
              Movimiento
            </CustomMenuItem>
          </ElipsisMobile>
        </span>
        <span>{account}</span>
        <p>
          <Label>Saldo</Label>
          <span>{mnCurrency(residue)}</span>
        </p>
      </div>
    </>
  );
}
