import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextAdvertisement } from "../Avisos";

export default function SearchAdvertisement() {
  const hook = useContext(ContextAdvertisement);

  if (hook === null) return <></>;

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        hideSearchIcon={false}
        placeholder="Descripción del aviso"
        max={255}
        value={hook.search}
        onChange={hook.setSearch}
        onDelete={hook.setSearch}
        onSearch={() => hook.triggerFetchSearch()}
      />
    </div>
  );
}
