import React, { useContext, useState } from "react";
import {
  ModalDismissButton,
  Submit,
} from "../../../components/individual/buttons/Buttons";
import {
  ErrorMessage,
  InputTextArea,
} from "../../../components/individual/inputs/Inputs";
import { useForm as useFormSchema } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cancelDocument } from "../../../helpers/Apis/Documents";
import TableContext from "../../../context/Table/TableContext";
import LoginContext from "../../../context/Login/LoginContext";
import { CancelDocumentErrorAlert, CancelDocumentSuccesAlert } from "../../../helpers/alerts";

export const CancelModal = ({ rowInfo }) => {
  console.log('Vamos a cancelar???')
  const [cancelText, setCancelText] = useState("");
  const { refetch, setReload } = useContext(TableContext);
  const { userInfo } = useContext(LoginContext);
  const validateCancel = yup.object().shape({
    cancelMotive: yup
      .string()
      // .min(1,'campo requerido')
      // .max(256, "Maximo 256 caracteres")
  });
  const { register, handleSubmit, errors } = useFormSchema({
    resolver: yupResolver(validateCancel),
  });
  function parsedID(rowInfo){
    let ID;
    switch (rowInfo.idTypeDocument) {
      case 1:
        ID={
          quoteId:rowInfo.idDocument,
          preInvoiceID:rowInfo.idInvoice,
          ocId:rowInfo.idOC,
          invoiceID:rowInfo.invoiceMizarNumber,
          contractId:rowInfo.idContract
        }
        break;
      case 2:
        ID={
          quoteId:rowInfo.idQuotation,
          preInvoiceID:rowInfo.idDocument,
          ocId:rowInfo.idOC,
          invoiceID:rowInfo.invoiceMizarNumber,
          contractId:rowInfo.idContract
        }
        break;
      case 3:
        console.log('caso 3');
        ID={
          quoteId:rowInfo.idQuotation,
          preInvoiceID:rowInfo.idInvoice,
          ocId:rowInfo.idDocument,
          invoiceID:rowInfo.invoiceMizarNumber,
          contractId:rowInfo.idContract
        }
        break;
      case 6:
        ID={
          quoteId:rowInfo.idQuotation,
          preInvoiceID:rowInfo.idInvoice,
          ocId:rowInfo.idOC,
          invoiceID:rowInfo.invoiceMizarNumber,
          contractId:rowInfo.idDocument
        }
        break;
    
      default:
        ID={
          quoteId:rowInfo.idDocument,
          preInvoiceID:rowInfo.idInvoice,
          ocId:rowInfo.idOC,
          invoiceID:rowInfo.invoiceMizarNumber,
          contractId:rowInfo.idContract
        }
        break;
    }
    return ID
  }

  const SubmitForm = async (data) => {
    console.log("Cancelando documento");
    // console.log(rowInfo)
    const ID=parsedID(rowInfo);
    console.log(ID);
    const cancelDocumet={
          quoteId:ID.quoteId,
          ocId:ID.ocId,
          preInvoiceID:ID.preInvoiceID,
          invoiceID:ID.invoiceID,
          contractId:ID.contractId,
          executiveId:rowInfo.idExecutive,
          motive:data.cancelMotive,
          docuemntType:rowInfo.idTypeDocument,
          modifyBy:userInfo[0]?.fullName
    }
    // const cancelQuote={
    //   documentId:rowInfo.idDocument,
    //   executiveId:rowInfo.idExecutive,
    //   motive:data.cancelMotive,
    //   docuemntType:rowInfo.idTypeDocument,
    //   modifyBy:userInfo[0]?.fullName
    // }
  //   console.log(cancelDocumet);
    
    const cancelar= await cancelDocument(cancelDocumet);

    if(cancelar){
      CancelDocumentSuccesAlert(cancelar.message,() => {
        document.getElementById("dismiss").click();
        setReload(!refetch);
      })
    }else{
      CancelDocumentErrorAlert(cancelar.message,() => {
        document.getElementById("dismiss").click();
        setReload(!refetch);
      })
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="cancelModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cancera/Terminar documento</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="cancelDocumentForm" onSubmit={handleSubmit(SubmitForm)}>
                <InputTextArea
                  id="cancelMotive"
                  name="cancelMotive"
                  labelTxt="Motivo de cancelación/terminacion"
                  placeholderTxt="Cancelar..."
                  cols={50}
                  rows={10}
                  value={cancelText}
                  forwardRef={register}
                  maxLength={256}
                  onChange={(e) => {
                    setCancelText(e);
                  }}
                />
                <ErrorMessage message={errors["cancelMotive"]?.message} />
              </form>
            </div>
            <div className="modal-footer">
              <div className="form-group">
                <ModalDismissButton idBtn="dismiss" text="Cerrar" callback={()=>{
                    setCancelText('')
                    
                }} />
              </div>
              <div className="form-group">
                <Submit
                  id="submitCancel"
                  text="Confirmar cancelacion"
                  idForm="cancelDocumentForm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
