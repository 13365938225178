import React from "react";
import { useContext, useRef } from "react";
import { Label } from "structure/Document";
import { AddMovementContext } from "../../AddMovementsV2";

export const Concept = () => {
  const { concept, updateConcept } = useContext(AddMovementContext);

  const maxLenght = useRef(256);

  return (
    <div>
      <div className="d-flex">
        <Label>Concepto</Label>
        <span>
          {concept.length}/{maxLenght.current}
        </span>
      </div>
      <input
        tabIndex={2}
        type="text"
        value={concept}
        maxLength={maxLenght.current}
        placeholder="Anticipo"
        onChange={(e) =>
          updateConcept(e.target.value.substring(0, maxLenght.current))
        }
      />
    </div>
  );
};
