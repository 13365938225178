import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ContextReceiveCreditNote } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export default function Step2Footer() {
  const hook = useContext(ContextReceiveCreditNote);

  if (hook.isReceivingCreditNote)
    return (
      <Spinner
        hidden={false}
        idSpinner="addingCreditNoteReceived"
        text={<LoadingText>Agregando recepcion </LoadingText>}
      />
    );

  return (
    <div className="d-flex justify-content-end">
      <ButtonV2 onClick={hook.attemptCreateCreditNote}>
        Recepcionar nota
      </ButtonV2>
    </div>
  );
}
