import React, { useContext, useRef } from "react";
import { ContextCommentOc } from "..";
import ui from "./styles.module.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  getDateFromUtc,
  parseDateToText,
  parseDateToTime,
} from "helpers/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
export default function CommentList() {
  const hook = useContext(ContextCommentOc);

  const key = useRef(window.crypto.randomUUID());

  if (hook.comments.length <= 0)
    return <h1 className="text-center my-5">No hay comentarios</h1>;

  return (
    <div className={ui.commentsOc}>
      {hook.comments.map((comment, i) => (
        <div className={ui.commentOc} key={`${key.current}-${i}`}>
          <div className={ui.commentedAt}>
            <time>
              {parseDateToText(getDateFromUtc(comment.createdDate))} -{" "}
              {parseDateToTime(getDateFromUtc(comment.createdDate))}
            </time>

            {comment.wasSend ? (
              <span className={ui.sentByEmail}>
                <FontAwesomeIcon icon={faPaperPlane} /> Enviado por correo
              </span>
            ) : null}
          </div>

          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {comment.comment}
          </ReactMarkdown>
        </div>
      ))}
    </div>
  );
}
