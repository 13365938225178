import { SocketContext } from "context/Socket";
import { getRecentTcps } from "helpers/Apis/Banks";
import { getEnterpriseInfo } from "helpers/Apis/setUp";
import { advertisementsToday } from "helpers/apis";
import { mnCurrency } from "helpers/money";
import { useContext, useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateBanner}
 */
const INITIAL_STATE = {
  isLoading: true,
  advertisements: [],
  recentTcp: undefined,
  items: [],
  refetch: false,
  enterprise: undefined,
};

export default function useBanner() {
  const [state, setState] = useState(INITIAL_STATE);

  const { socketState } = useContext(SocketContext);

  /**
   * Generate the banner advertisement for the exchange rate handled by the system
   * @param {string} message - Message to set for the TC to use on the system
   */
  function generateItemTc(message) {
    return {
      advertisementID: window.crypto.randomUUID(),
      advertisementTypeID: 4,
      message,
    };
  }

  useEffect(() => {
    (async function () {
      setState(INITIAL_STATE);

      const [advertisements, tcs, enterprise] = await Promise.all([
        advertisementsToday(),
        getRecentTcps(),
        getEnterpriseInfo(),
      ]);

      const recentTcp = tcs[0];

      /**
       * @type {import("./types").BannerItem[]}
       */
      const items = advertisements.map((adv) => ({
        advertisementID: adv.advertisementID,
        advertisementTypeID: adv.advertisementTypeID,
        message: adv.message,
      }));

      const institutional = `Institucional ${
        enterprise === undefined
          ? ""
          : `(${enterprise.enterpriseName})`
      } ${mnCurrency(recentTcp.enterprise.number, 4)}`;
  
      const dof = `Tipo de cambio vigente: DOF ${mnCurrency(recentTcp.dof.number, 4)}`;
  
      const banamex = `Banamex (venta) ${mnCurrency(recentTcp.sales.number, 4)}`;
  
      const message = `${dof} | ${banamex} | ${institutional}`;

      setState((current) => ({
        ...current,
        advertisements,
        isLoading: false,
        recentTcp,
        items: [generateItemTc(message), ...items],
      }));
    })();
  }, [state.refetch]);

  /**
   * Update the tc used on the system with the newest one
   * @param {string} tcString - Tc gave by the server socket
   */
  const updateWithNewestTc = (tcString) => {
    /**
     * @type {import("helpers/Apis/typesBanks").TcInformative}
     */
    const tc = JSON.parse(tcString);

    const institutional = `Institucional ${
      state.enterprise === undefined
        ? ""
        : `(${state.enterprise.enterpriseName})`
    } ${mnCurrency(tc.enterprise.number, 4)}`;

    const dof = `Tipo de cambio vigente: DOF ${mnCurrency(tc.dof.number, 4)}`;

    const banamex = `Banamex (venta) ${mnCurrency(tc.sales.number, 4)}`;

    const message = `${dof} | ${banamex} | ${institutional}`;

    setState((current) => ({
      ...current,
      recentTcp: tc,
      items: [generateItemTc(message), ...current.advertisements],
    }));
  };

  useEffect(() => {
    socketState.on("tcUpdate", updateWithNewestTc);

    socketState.on("newAds", () => {
      setState((current) => ({
        ...current,
        refetch: !current.refetch,
      }));
    });
  }, [socketState]);

  return {
    ...state,
  };
}
