import { ContextConfig } from "App";
import useDialog from "customHooks/useDialog";
import { add, lastDayOfMonth } from "date-fns";
import { getAccount } from "helpers/Apis/Banks/index";
import { closePeriod } from "helpers/Apis/concilation";
import { getCloseMonthVariations } from "helpers/Apis/parameters";
import { dateWithCeroHours } from "helpers/dates";
import { useContext, useEffect, useState } from "react";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isOpen: false,
  isValidating: true,
  isLoading: false,
  balance: 0,
  bankAccount:undefined,
  currentVariation: 0,
  isValidVariation: false,
  year:undefined,
  month:undefined,
  variations: {
    MXN: 0,
    USD: 0,
  },
  amounth: 0,
  lastDayOfMonth: dateWithCeroHours(new Date(1950, 0, 1)),
};

/**
 * Close one period of the concilations
 * @param {number} month - Month to close
 * @param {number} year - Year to close
 * @returns {import("./types").ReturnClosePeriod} Values of the hook
 */
export default function useClosePeriod(
  month,
  year,
  // idBankAccount,
  // balance,
  // currency
) {
  const { validateClosePeriodDate } = useContext(ContextConfig);

  const [state, setState] = useState({
    ...INITIAL_STATE,
    lastDayOfMonth: validateClosePeriodDate
      ? dateWithCeroHours(new Date(year, month, 0))
      : dateWithCeroHours(new Date(1950, 0, 1)),
  });

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      const variations = await getCloseMonthVariations();



      setState((current) => ({
        ...current,
        variations,
      }));
    })();
  }, [state.bankAccount]);

  useEffect(() => {
    (async function () {
      if (!state.isOpen || state.bankAccount === undefined) return;

      const bankAccount = await getAccount(state.bankAccount.id);

      setState((current) => ({
        ...current,
        balance: bankAccount.realBalance,
      }));
    })();
  }, [state.isOpen,state.bankAccount]);

  useEffect(()=>{
    if(state.bankAccount===undefined) return

    const nextMonth = dateWithCeroHours(add(new Date(`${state.bankAccount.closingBalanceDate}:`),{ days:1 }));

    const last = lastDayOfMonth(nextMonth);

    setState(current=>({
      ...current,
      lastDayOfMonth:last,
      year:last.getUTCFullYear(),
      month:last.getUTCMonth()
    }));

  },[state.bankAccount]);

  useEffect(() => {
    if (!state.isOpen || state.bankAccount === undefined) return;

    const currentVariation = state.amounth - state.balance;

    const variationToUse = state.variations[state.bankAccount.currency];

    const minVariation = -variationToUse;

    const isValidVariation =
      currentVariation >= minVariation && currentVariation <= variationToUse;

    setState((current) => ({
      ...current,
      isValidVariation,
      currentVariation,
    }));
  }, [state.amounth, state.variations, state.isOpen, state.balance,state.bankAccount]);

  /**
   * Amounth to validate against the movements to check if is the same as the bank account
   * @param {number} amounth - Amounth to validate
   * @returns {void}
   */
  const updateAmount = (amounth) =>
    setState({
      ...state,
      amounth,
    });

  /**
   * Attempt close the period
   * @returns {Promise<boolean>} If true, the period was closed
   */
  const attemptClosePeriod = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    const dateToClose = new Date(state.lastDayOfMonth.getUTCFullYear(), state.lastDayOfMonth.getUTCMonth(), 1);

    const wasClosed = await closePeriod(
      state.bankAccount.id,
      state.amounth,
      dateToClose
    );

    setState((current) => ({
      ...current,
      isLoading: false,
    }));

    closeModal();

    if (wasClosed) {
      window.location.reload();
    }

    return wasClosed;
  };

  const openModal = () => {
    setState((current) => ({
      ...current,
      isOpen: true,
    }));

    dialog.open();
  }
    

  const closeModal = () => {
    setState((current) => ({
      ...current,
      ...INITIAL_STATE,
      isOpen: false,

    }));

    dialog.close();
  }
    

  /**
   * 
   * @param {import("../../../../server/models/banks/types").BankAccountI} bankAccount 
   * @returns 
   */
  const setBankAccount = bankAccount => setState(current=>({
    ...current,
    bankAccount
  }));

  return {
    ...state,
    updateAmount,
    attemptClosePeriod,
    setBankAccount,
    openModal,
    closeModal,
    dialog
  };
}
