import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { validateInvoiceReceptionResidue } from "helpers/money";
import React, { useContext } from "react";
import { AddReceptionInvoiceContext } from "../../Filter";

export default function BtnContinueStep2() {
  const {
    state: { gridInfo, concept, isAddingReception, xmlInfo },
    setStep,
    uploadEgress,
  } = useContext(AddReceptionInvoiceContext);

  const isValidRemainingResidue =
    gridInfo === null
      ? false
      : validateInvoiceReceptionResidue(
          gridInfo.newResidue,
          gridInfo.tolerance,
          xmlInfo.xmlInfo.total.number
        );

  return (
    <>
      {gridInfo === null && concept === null ? null : gridInfo !== null ? (
        <>
          {!gridInfo.isValidApplied ||
          !isValidRemainingResidue ||
          !gridInfo.isValidTolerance ||
          !gridInfo.isValidImport ? null : (
            <ButtonV2 onClick={() => setStep(2)}>Continuar</ButtonV2>
          )}
        </>
      ) : !concept.isValid ? null : (
        <ButtonV2 onClick={() => setStep(2)}>Continuar</ButtonV2>
      )}
    </>
  );
}
