import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { TotalArea } from "./Styles";

export default function Total({ value, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Total"
            value={!value ? document.amount.total.text : value}
          />
        </div>
      ) : (
        <TotalArea>
          <Informative
            readonly={true}
            label="Total"
            value={!value ? document.amount.total.text : value}
          />
        </TotalArea>
      )}
    </>
  );
}
