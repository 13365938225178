import axios from "axios";
import { URL_BASE, ULR_MIZAR } from "../../routes/routes";
import { Success, Error } from "../alerts";
import { setBank } from "../forms";
import { parseSearchText } from "../search";
import { arrayToQueryParam, isValidHttpResCode, successRes } from "./fetch";
import { multipleCustomer } from "./DefaultValues/DefaultDirectory";
import { getErrorData } from "helpers/errors";
import { array } from "prop-types";

/**
 * Get all from the directory
 * @param {import("../../../../server/controllers/typesControllerDirectory").DirectoryComboTypeI} type - Type of customers to get from the database
 * @param {boolean} includeInactives - True if even inactive customers should appear
 * @returns {Promise<import("components/general/Forms/Selects/SocialReason/types").ComboDirectoryDtoI[]>} List of the directory
 */
export async function getDirectory(type = "todos", includeInactives = false) {
  try {
    const res = await fetch(
      `${URL_BASE}directorio/clientes?tipo=${type}&incluirInactivos=${+includeInactives}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (successRes(res)) {
      const data = await res.json();
      return data;
    }

    const error = await getErrorData(res);
    Error(() => {}, error.message);
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Validate a customer if sync with the provider to stamp invoices
 * @param {number} idCustomer - Id of the customer
 * @returns {{
 *  isSync:boolean,
 *  idFacturama:(string|null)
 * }} Information of the customer
 */
export async function validateCustomerIsSync(idCustomer) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/cliente/${idCustomer}/validar-sincronizacion`
    );

    return data.info;
  } catch (error) {
    console.log(error);

    return {
      isSync: false,
      idFacturama: null,
      error,
    };
  }
}

/**
 *
 * @param {string} id - id attribute of the html tag to apply
 * @param {string} action - 'show' or 'hide'
 */
function display(id, action = "") {
  if (action === "show") {
    document.getElementById(id).classList.remove("d-none");
  }

  if (action === "hide") {
    document.getElementById(id).classList.add("d-none");
  }
}

/**
 * Show the spinner when make a request to API
 */
function triggerLoading() {
  display("options", "hide");
  display("queryProgress", "show");
}

/**
 * Hide the spinner when get a result of the API
 */
function quitLoading() {
  display("options", "show");
  display("queryProgress", "hide");
}

/**
 *
 * @param {number} page - Number of page wanted for the info
 * @param {string} order - ASC or DESC order for the requested data
 * @param {string} columnOrdering - Which column will be ordered
 * @param {string?} aditionalQuery - URL params if it's needed
 * @returns {JSXElementConstructor} JSX Element
 */
export async function fetchDirectory(
  page,
  order,
  columnOrdering,
  aditionalQuery
) {
  try {
    let directory;

    const typeCustomer = document.getElementById("typeCustomer").value;
    const status = document.getElementById("status").value;
    let executive = document.getElementById("executive").value;
    const search = document.getElementById("searchClientInput");

    if (executive === "" || executive === undefined || executive === null)
      executive = aditionalQuery;

    let query = `&tipo=${typeCustomer}&estatus=${status}&ejecutivo=${executive}`;

    if (search.value === "") {
      const { data } = await axios.get(
        `${URL_BASE}directorio?columna=${columnOrdering}&orden=${order}&pagina=${page}${query}&busqueda=`
      );
      directory = data.data;
    } else {
      query = parseSearchText("searchClientInput");
      const { data } = await axios.get(
        `${URL_BASE}directorio?columna=${columnOrdering}&orden=${order}&pagina=${page}&busqueda=${query}&ejecutivo=${executive}&tipo=${typeCustomer}&estatus=${status}`
      );
      directory = data.data;
    }

    return directory;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Fetch the directory
 * @param {number} statusCustomer - Status of the customer
 * @param {number} typeCustomer - Type customer
 * @param {number} executiveId - Id of the executive
 * @param {string|null} searchQuery - Text to search of the bar
 * @returns {Promise<import("./typesDirectory").DirectoryI>} Information of the directory
 */
export async function fetchDirectoryV2(
  statusCustomer,
  typeCustomer,
  executiveId,
  page = 1,
  searchQuery = null
) {
  try {
    const search = searchQuery === null ? "" : searchQuery;
    const type = typeCustomer === null ? "-1" : typeCustomer;

    const { data } = await axios.get(
      `${URL_BASE}directorio?columna=customerID&orden=DESC&pagina=${page}&ejecutivo=${executiveId}&tipo=${type}&estatus=${statusCustomer}&busqueda=${search}`
    );

    return data.data;
  } catch (error) {
    console.log(error);

    Error(() => {},
    "No se pudo obtener el directorio. Soporte tecnico ya fue notificado, puedes esperar su respuesta personal o un aviso generico");
  }
}

/**
 * Download the excel of the directory
 * @returns {Promise<Blob|undefined>}
 */
export async function downloadDirectoryExcel() {
  try {
    const res = await fetch(`${URL_BASE}directorio/excel`, {
      credentials: "include",
    });

    if (successRes(res)) {
      const file = await res.blob();
      return file;
    }

    const error = await res.json();

    Error(() => {}, error.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

export async function paginationFn(
  page,
  order,
  columnOrdering,
  additionalQuery
) {
  try {
    const directory = await fetchDirectory(
      page,
      order,
      columnOrdering,
      additionalQuery
    );

    return directory.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the full information of an specific customer
 * @param {number|string} idCustomer - Id of the customer
 * @returns {Promise<import("types/typedef/customer").CustomerI>} Info of the customer/provider
 */
export async function GetCustomer(idCustomer) {
  try {
    if (idCustomer === "null") {
      return multipleCustomer;
    } else {
      const { data } = await axios.get(
        `${URL_BASE}directorio/v2/cliente/${idCustomer}`
      );

      if (data.status === 200) {
        return data.customer;
      } else {
        Error(() => {}, data.error);
        return null;
      }
    }
  } catch (error) {
    Error(() => {}, error);
  }
}

/**
 * Add a new customer into the system
 * @param {import("../../../../types/directory").DtoAddCustomer} formData - Property with the value of this inputs
 * @returns {object} Information of the customer added
 */
export async function addCustomer(formData) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}directorio/cliente`,
      formData
    );

    if (data.status === 200) {
      return data;
    } else {
      Error(() => {}, data.message);

      return {
        status: 400,
        idCustomer: null,
        message: data.message,
      };
    }
  } catch (error) {
    Error(() => {}, error.response.data.message);
    return {
      status: 500,
      message: error,
      idCustomer: null,
    };
  }
}

/**
 * Get the information of a customer with his id
 *
 * @param {number} id - ID of the customer info wanted
 * @returns {Promise<import("helpers/typesDirectory").ResponseCustomer>} customer - Info of the customer requested
 */
export async function getCustomer(id) {
  try {
    const { data } = await axios.get(`${URL_BASE}directorio/cliente/${id}`);

    if (data.status === 200) {
      return data;
    }

    Error(() => {}, "Hubo un error");
  } catch (error) {
    Error(() => {}, error);
  }
}

/**
 * Set the inputs when you're editing a customer
 * @param {object} customer - Information of the customer
 * @param {object} corporative - Information of the corporative which it's associated the customer
 */
export function setInputs(customer, corporative) {
  // Set executive associated
  document.getElementById(
    `executiveAttends${customer.Id_Ejecutivo}`
  ).selected = true;

  // Set PK of executive associated
  document.getElementById("PK_Executive_Associated").value =
    customer.FK_Customer_Executive_Customer;

  // Corporative
  if (corporative !== undefined) {
    document.getElementById("corporative").value =
      corporative.shortNameCorporative;
  } else {
    document.getElementById("disassociateCorporative").classList.add("d-none");
  }

  if (customer.status === 1) {
    document.getElementById("statusCustomer").checked = true;
    document.getElementById("status-customer").innerText = "Activo";
    document.getElementById("hiddenStatus_value_statusCustomer").value = 1;
  }

  if (customer.customerType === 2) {
    const radio = document.getElementById("typeCustomer-1").parentNode;
    radio.classList.add("d-none");
    radio.classList.remove("d-flex");
  } else {
    const radio = document.getElementById("typeCustomer-2").parentNode;
    radio.classList.add("d-none");
    radio.classList.remove("d-flex");
  }

  document.getElementById("customerID").value = customer.customerID;
  document.getElementById("socialReason").value = customer.socialReason;
  document.getElementById(
    `typeCustomer-${customer.customerType}`
  ).checked = true;
  document.getElementById("commercialName").value = customer.commercialName;
  document.getElementById("shortName").value = customer.shortName;
  document.getElementById("customerRFC").value = customer.rfc;
  document.getElementById("addressCustomer").value = customer.address;
  document.getElementById("streetCustomer").value = customer.street;
  document.getElementById("exteriorNumber").value = customer.exteriorNumber;
  document.getElementById("insideNumber").value = customer.interiorNumber;
  document.getElementById("postalCode").value = customer.cp;
  document.getElementById("emailCustomer").value = customer.email;
  document.getElementById("customerColony").value = customer.suburb;
  document.getElementById("customerCity").value = customer.city;
  document.getElementById("customerState").value = customer.polity;
  document.getElementById("creditDays").value = customer.creditDays;

  document.getElementById("lada_phone").value = customer.ladaPhone;
  document.getElementById("number_phone").value = customer.phone;

  document.getElementById("lada_movil").value = customer.ladaMovil;
  document.getElementById("number_movil").value = customer.movil;
  document.getElementById("idCorporative").value = customer.corporative;

  document.getElementById(`customerCountry${customer.country}`).selected = true;

  if (
    customer.customerType === 1 ||
    customer.customerType === 2 ||
    customer.customerType === 5
  ) {
    document.getElementById("referenceDeposit").value =
      customer.depositReference;
  }

  if (customer.customerType === 2 || customer.customerType === 5) {
    const inputs = document.getElementById("mnBank");

    if (inputs === null) return;

    setBank(customer.bankMN, customer.mn_nombre_corto, "mnBank");
    document.getElementById("mnBank").value = customer.bankMN;
    document.getElementById("mnNumberAccount").value = customer.accountMN;
    document.getElementById("mnNumberKeyCode").value = customer.keyCodeMN;
    document.getElementById("mnNoAgreement").value = customer.noAgreementMN;

    setBank(customer.bankUSD, customer.usd_nombre_corto, "usdBank");
    document.getElementById("usdBank").value = customer.bankUSD;
    document.getElementById("usdNumberAccount").value = customer.accountUS;
    document.getElementById("usNumberKeyCode").value = customer.keyCodeUS;
    document.getElementById("usdNoAgreement").value = customer.noAgreementUS;
  }
}

export function setIVA(iva) {
  if (iva === undefined) return;

  let htmlContent = `<option id="iva_db_customer" value="${iva}" selected="true">${iva}%</option>`;

  document.getElementById(`iva`).childNodes.forEach((option) => {
    const previousOptions = option.outerHTML;
    htmlContent += previousOptions;
  });

  document.getElementById("iva").innerHTML = htmlContent;
}

/**
 *
 * @param {number} page - Number of page wanted for the info
 * @param {string} order - ASC or DESC order for the requested data
 * @param {string} columnOrdering - Which column will be ordered
 * @param {string?} [aditionalQuery=undefined] - URL params if it's needed
 * @returns {Promise<import("./typesDirectory").CorporativeResponse>} corporatives - Information of the corporatives
 */
export async function getCorporatives(
  page,
  order,
  columnOrdering,
  aditionalQuery = undefined
) {
  try {
    if (aditionalQuery !== undefined || aditionalQuery !== null) {
      const { data } = await axios.get(
        `${URL_BASE}directorio/corporativos?pagina=${page}&orden=${order}&columna=${columnOrdering}${aditionalQuery}`
      );

      return data.data;
    } else {
      const { data } = await axios.get(
        `${URL_BASE}directorio/corporativos?pagina=${page}&orden=${order}&columna=${columnOrdering}`
      );
      return data.data;
    }
  } catch (error) {
    return {
      actualPage: 1,
      corporatives: [],
      pages: 0,
    };
  }
}

/**
 *
 * @param {number} page - Number of page requested
 * @param {string} order - ASC or DESC the data
 * @param {string} columnOrdering - Column which we wanna sorted the data
 * @param {string?} aditionalQuery - Aditional url params in case you need it
 */
export async function searchCorporative(
  page,
  order,
  columnOrdering,
  aditionalQuery
) {
  if (aditionalQuery === undefined || aditionalQuery === null) {
    aditionalQuery = "";
  }

  const urlParam = parseSearchText("searchDirectory");

  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/corporativos-busqueda?pagina=${page}&orden=${order}&columna=${columnOrdering}&busqueda=${urlParam}${aditionalQuery}`
    );
    return data.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Set the information of corporative in case accept the information of the modal
 *
 * @param {number} id - ID of the corporative
 * @param {string} shortName - Shortname of the corporative
 */
export function setCorporativeInput(id, shortName) {
  document.getElementById("idCorporative").value = id;
  document.getElementById("corporative").value = shortName;
}

/**
 * Update the information of a customer
 * @param {Customer} customer - Information to update on customer
 * @returns {object}
 */
export async function updateCustomer(customer) {
  try {
    const { data } = await axios.put(`${URL_BASE}directorio/cliente`, customer);

    if (data.status === 200) {
      return {
        status: 200,
        idCustomer: customer.customerID,
        message: "Cliente actualizado",
      };
    } else {
      Error(() => {}, data.message);

      return {
        status: 400,
        idCustomer: null,
        message: data.message,
      };
    }
  } catch (error) {
    console.log(error);

    return {
      status: 500,
      idCustomer: null,
      message: "Fallo en la conexion de internet o sistema en mantenimiento",
      error,
    };
  }
}

export function validatePhones() {
  const movil = parseInt(document.getElementById(`isValid_movil`).value, 10);
  const phone = parseInt(document.getElementById(`isValid_phone`).value, 10);

  if (movil === 0) {
    Error(() => {}, "Telefono celular invalido");
    return false;
  }

  if (phone === 0) {
    Error(() => {}, "Telefono fijo invalido");
    return false;
  }

  return true;
}

/**
 * Open a modal when click an specific column from the table
 *
 * @param {number} column - Which number column, left to right gonna implement the functionality
 * @param {string} id - id table that will be applied funcitonality
 */
export function functionalityPopUp(column, id) {
  document.querySelectorAll(`#${id} tbody tr`).forEach((row) => {
    if (row.childNodes[column] !== undefined) {
      row.childNodes[column].addEventListener("click", () => {
        document.getElementById("triggerViewInfo").click();
      });
    }
  });
}
/**
 * Open a modal when click an specific column from the table
 *
 * @param {number} column - Which number column, left to right gonna implement the functionality
 * @param {string} id - id table that will be applied funcitonality
 */
export function functionalityPopUpV2(column, id) {
  document.querySelectorAll(`#${id} tbody tr`).forEach((row) => {
    if (row.childNodes[column] !== undefined) {
      row.childNodes[column].addEventListener("click", () => {
        document.getElementById("openModal").click();
      });
    }
  });
}
/**
 * Open a modal when click an specific column from the table
 *
 * @param {number} column - Which number column, left to right gonna implement the functionality
 * @param {string} id - id table that will be applied funcitonality
 * @param {string} idOpenModal - id modal to be open
 */
export function functionalityPopUpV3(column, id, idOpenModal) {
  setTimeout(() => {
    document.querySelectorAll(`#${id} tbody tr`).forEach((row) => {
      if (row.childNodes[column] !== undefined) {
        row.childNodes[column].addEventListener("click", () => {
          document.getElementById(idOpenModal).click();
        });
      }
    });
  }, 100);
}

/**
 * Open a modal when click an specific column from the table
 *
 * @param {number} column - Which number column, left to right gonna implement the functionality
 * @param {string} ididTable - id table that will be applied funcitonality
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI[]} columnsInfo - Information of the movements fetched
 * @param {{
 * expenses:string,
 * oc:string,
 * invoices:string,
 * incomings:string
 * }}
 */
export function movementsPopUp(
  column,
  idTable,
  columnsInfo,
  { expenses = "???", oc = "???", invoices = "????", incomings = "?????" }
) {
  setTimeout(() => {
    document.querySelectorAll(`#${idTable} tbody tr`).forEach((row, i) => {
      // console.log(i);
      if (row.childNodes[column] !== undefined) {
        row.childNodes[column].addEventListener("click", () => {
          // console.log(columnsInfo[i]);
          // CxC
          // if (columnsInfo[i].typeAssociation.id === 1) {
          // }
          // // Concepts
          // if (columnsInfo[i].typeAssociation.id === 2) {
          // }

          // // CxP
          // if (columnsInfo[i].typeAssociation.id === 3) {
          // }

          // // Expenses
          if (columnsInfo[i].typeAssociation.id === 4) {
            document.getElementById(expenses).click();
          }
        });
      }
    });
  }, 100);
}

export async function setInfoModal(information) {
  document.getElementById("modalSocialReason").innerText =
    information.Razon_social;

  if (information.Movil.length > 2) {
    document.getElementById("modalMovil").innerText = `${information.Movil}`;
  } else {
    document.getElementById("modalMovil").innerText = ``;
  }

  if (information.Telefono.length > 2) {
    document.getElementById("modalPhone").innerText = `${information.Telefono}`;
  } else {
    document.getElementById("modalPhone").innerText = ``;
  }

  document.getElementById("modalEmail").innerText = information.Correo;
  document.getElementById("modalRFC").innerText = information.RFC;
  document.getElementById("modalCreditDays").innerText =
    information.Dias_credito;

  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/corporativo/${information.Corporativo}`
    );

    document.getElementById("modalCorporative").innerText =
      data.corporative.socialReason;
  } catch (error) {}
}
export async function setInfoModalReminder(information) {}

export async function getIVAS() {
  try {
    const { data } = await axios.get(`${URL_BASE}directorio/ivas`);

    return data.ivas;
  } catch (error) {
    console.log(error);
  }
}

export async function GetAllProviders() {
  try {
    let providers = await fetch(`${URL_BASE}directorio/proveedores`);

    providers = await providers.json();

    return providers.providers;
  } catch (error) {
    console.error(error);
  }
}

export async function saveWinQuote(docInfo) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/cotizacion/ganar`,
      docInfo
    );

    if (data.status === 200) {
      // Success(()=>{},data.message);
      return true;
    }
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Get all the customers from the directory with an specific id related
 *
 * @param {number|string} idCorporative - Id of the corporative to fetch
 * @returns {object[]} Corporatives
 */
export async function GetCorporativesRelated(idCorporative) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/v2/corporativos/${idCorporative}`
    );

    if (data.status === 200) {
      return data.corporatives;
    } else {
      Error(() => {}, data.error);
      return [];
    }
  } catch (error) {
    Error(() => {}, error);
    return [];
  }
}

/**
 * Fetch the customers that can be selected on the preinvoice when the document it's being win
 * @param {number} idQuoteCustomer - Id of the quote customer
 * @returns {import("types/typedef/preinvoice").CustomersPreinvoiceWinQuoteI[]} List of customers available for the preinvoice
 */
export async function GetInvoiceCustomersAvailable(idQuoteCustomer) {
  try {
    const {
      data: { customers },
    } = await axios.get(
      `${URL_BASE}documentos/prefactura/clientes/${idQuoteCustomer}`
    );

    return customers;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return [];
  }
}

export async function getMyReminders(
  page,
  order,
  columnordering,
  additionalQuery
) {
  console.log(additionalQuery);
  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/inicio/myreminders?pagina=${page}&orden=${order}&columna=${columnordering}${additionalQuery}`
    );

    console.log("Estatus de la consulta");
    console.log(data.data);

    if (data.status === 200) {
      return {
        status: true,
        tabla: data.data.remindersToAttent,
        pages: data.data.pages,
        actualPage: data.data.actualPage,
        mensaje: "funciono la consulta",
      };
    } else {
      Error(() => {}, data.error);
      return {
        status: false,
        tabla: [],
        mensaje: "fallo la consulta",
        error: data,
      };
    }
  } catch (error) {
    Error(() => {}, error);
    return {
      status: false,
      tabla: [],
      mensaje: "fallo la consulta catch",
      error: error,
    };
  }
}

export async function getReminderHeaderInfo(params) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/inicio/reminderheader/${params.ID}/${params.reminderFrom}`
    );
    // console.log('Data del info header');
    // console.log(data);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}
export async function UpdateReminder(reminderData) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}directorio/inicio/updatereminder`,
      reminderData
    );
    if (data.status) {
      return {
        status: true,
        mensaje: "Se agrego el recordatorio correctamente",
      };
    } else {
      return {
        status: false,
        mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
      };
    }
  } catch (error) {
    return {
      status: false,
      mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
    };
  }
}

export async function UpdateAuthorizationProces(authorizationData) {
  try {
    const result = await axios.put(
      `${URL_BASE}directorio/inicio/autorizacion/confirm-reject`,
      authorizationData
    );
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function SendAthorizationStatus(emailInfo) {
  try {
    const result = await axios.put(
      `${URL_BASE}directorio/inicio/autorizacion/eamil`,
      emailInfo
    );
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * Get the full list of the directory
 * @param {"cliente"|"proveedor"} type - Type of customers to fetch
 * @returns {Promise<import("./typesDirectory").DiretoryComboI[]>} Information of customers
 */
export async function getFullDirectory(type) {
  try {
    const res = await fetch(`${URL_BASE}directorio/completo?type=${type}`, {
      credentials: "include",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Validate a list of rfc against the SAT
 * @param {import("./directoryTypes").RfcSatItemValidation[]} rfcs - Rfcs to validate
 */
export async function validateRfcAgainstSat(rfcs = []) {
  try {
    const formData = new FormData();

    const fileWithRfcs = generateTextParam();

    console.log("RFCS", fileWithRfcs);

    formData.append("uploadFile", fileWithRfcs);

    const resSendedRfc = await axios.post(
      "https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/UploadFileServlet",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const resValidatedRfcs = await axios.get(
      "https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/ServletProcesaArchivoMasivo"
    );

    console.log(resSendedRfc);
    // console.log(resValidatedRfcs);
  } catch (error) {
    console.log(error);
  }

  function generateTextParam() {
    const stringOfTextFile = rfcs.reduce(
      (list, rfc, i) =>
        (list += `${i + 1}|${rfc.rfc}|${rfc.socialReason}|${rfc.postalCode}\n`),
      ""
    );

    const blob = new Blob([stringOfTextFile], { type: "text/plain" });
    return blob;
  }
}

/**
 * Get the types of customers that has the system
 * @returns {Promise<import("components/individual/TypeCustomerDirectory/types").TypeCustomerDirectoryData[]>}
 */
export async function getTypesCustomers() {
  try {
    const res = await fetch(`${URL_BASE}directorio/tipos-cliente`, {
      credentials: "include",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Send a new product/service to server in order to be created
 * @param {number} idSector - Id of the type of customer/provider to fetch
 * @param {number} idCustomerType - Status
 * @param {boolean} status - Status
 * @returns {Promise<Blob|undefined>}
 */
export async function getCatalogueExcel(idSector, idCustomerType, status) {
  const queryParamStatus =
    typeof idSector !== "number" ? "" : `&idSector=${idSector}`;
  const queryParamIdCustomerType = `?idCustomerType=${idCustomerType}`;
  const queryParamStatusItems = `&status=${+status}`;
  try {
    const res = await fetch(
      `${URL_BASE}directorio/reporte/excel${queryParamIdCustomerType}${queryParamStatus}${queryParamStatusItems}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

// idUen
// idSector

/**
 * Send a new product/service to server in order to be created
 * @param {number} idUen - Id of the type of customer/provider to fetch
 * @param {number} idSector - Status
 * @param {import("structure/DistributionList/useDistributionList/types").TypeDistributionList} type - Type of file to obtain
 * @returns {Promise<Blob|undefined>}
 */
export async function getDistributionList(idUen, idSector, type) {
  const idUenQueryParam = typeof idUen === "number" ? `idUen=${idUen}` : "";
  const idSectorQueryParam =
    typeof idSector === "number" ? `idSector=${idSector}` : "";

  const queryParamsUrl = generateQueryParams();

  const urlToUse =
    type === "report"
      ? "contactos/reporte/excel"
      : "contactos/reporte/excel-mailchimp";

  try {
    const res = await fetch(`${URL_BASE}${urlToUse}${queryParamsUrl}`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }

  function generateQueryParams() {
    if (typeof idUen !== "number" && typeof idSector !== "number") return "";

    if (idUenQueryParam !== "") {
      const paramSector =
        idSectorQueryParam === "" ? "" : `&${idSectorQueryParam}`;

      return `?${idUenQueryParam}${paramSector}`;
    }

    const paramUen = idUenQueryParam === "" ? "" : `&${idUenQueryParam}`;

    return `?${idSectorQueryParam}${paramUen}`;
  }
}

/**
 * Get the manufacturers
 * @param {number} id - Id of the customer
 * @returns {Promise<import("../../../../server/models/typesDirectoryModel").Manufacturer[]>}
 */
export async function getManufacturers(id) {
  try {
    const res = await fetch(`${URL_BASE}directorio/fabricantes/${id}`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) return data;
    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Add a new manufacturer into the customer
 * @param {number} idClient - Id of the client
 * @param {number} idProvider - Id of the provider
 * @param {string} key - Value key of the provider
 * @returns {Promise<boolean>}
 */
export async function addManufacturer(idClient, idProvider, key) {
  try {
    const body = {
      idCustomer: idClient,
      idProvider,
      key,
    };

    const res = await fetch(`${URL_BASE}directorio/fabricantes`, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ manufacturer: body }),
    });

    const resData = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update a manufacturer
 * @param {number} id - Id of the record to edit
 * @param {number} idProvider - Id of the provider to use on the update
 * @param {string} key - Key of the manufacturer
 * @param {boolean} [deleteRecord=false] - Indicates if the record must be deleted
 * @returns {Promise<boolean>}
 */
export async function updateManufacturer(
  id,
  idProvider,
  key,
  deleteRecord = false
) {
  try {
    const body = {
      key,
      idProvider,
      delete: deleteRecord,
      id,
    };

    const res = await fetch(`${URL_BASE}directorio/fabricantes`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ manufacturer: body }),
    });

    const resData = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the customers that an executive has associated
 * @param {number} id - Id
 * @returns {Promise<import("../../../../server/models/directoryTypes").ExchangeCustomerItem[]>}
 */
export async function getCustomersOfExecutive(id) {
  try {
    const res = await fetch(`${URL_BASE}directorio/ejecutivo/${id}`, {
      credentials: "include",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (successRes(res)) {
      const customers = await res.json();
      return customers;
    }

    return [];
  } catch (error) {
    return [];
  }
}

export async function getCustomerBankState(id) {
  const queryParams = arrayToQueryParam([`idCustomer=${id}`]);

  try {
    /**
     * @type {import("axios").AxiosResponse<Blob>}
     */
    const res = await axios.get(
      `${URL_BASE}documentos/estado-de-cuenta${queryParams}`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );

    return res.data;
  } catch (error) {
    return undefined;
  }
}
