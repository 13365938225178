import useDialog from "customHooks/useDialog";
import { getInvoiceToReplace } from "helpers/Apis/Administracion/InvoiceEmitted/InvoiceEmittedApi";
import { useContext, useEffect, useState } from "react";
import { cancelInvoice as apiCancel } from "helpers/Apis/Administracion/InvoiceEmitted/InvoiceEmittedApi";
import LoginContext from "context/Login/LoginContext";
import { Success, Warning } from "helpers/alerts";
import { isValidHttpResCode } from "helpers/Apis/fetch";
import { getInvoiceByUuid } from "helpers/Apis/Documents";

/**
 * @type {import("./types").CancellationMotivesList}
 */
const motivos = [
  {
    id: 1,
    facturamaId: "01",
    description: "01 - Comprobante emitido con errores con relación",
  },
  {
    id: 2,
    facturamaId: "02",
    description: "02 - Comprobante emitido con errores sin relación",
  },
  {
    id: 3,
    facturamaId: "03",
    description: "03 - No se llevó a cabo la operación",
  },
  {
    id: 4,
    facturamaId: "04",
    description: "04 - Operación normativa relacionada con una factura global",
  },
];

/**
 * @type {import("./types").StateCancelInvoice}
 */
const initialState = {
  motivos,
  isModalOpen: false,
  isLoading: true,
  uuid: "",
  motiveId: null,
  facturamaMotive: null,
  cfdiSustitute: "",
  isCfdiToApper: false,
  invoiceToReplace: undefined,
  search: 0,
  loadInvoiceToReplace: false,
  finishLoadInvoice: false,
  isCancelable: true,
  canceling: false,
  isCancelling: false,
  invoice: undefined,
};

/**
 *
 * @type {import("./types").useCancelInvoice}
 */
export const useCancelInvoice = (uuid, onCancelled = () => {}) => {
  const [state, setState] = useState(initialState);
  const { userInfo } = useContext(LoginContext);

  const dialog = useDialog();

  const setOpenModal = (isOpen) => {
    setState({
      ...state,
      isModalOpen: isOpen,
    });
  };

  const setNewCfdi = (value) => {
    setState({
      ...state,
      cfdiSustitute: value,
    });
  };

  const resetState = () => {
    setState(initialState);
  };

  const cancelInvoice = () => setState({ ...state, isLoading: true });

  useEffect(() => {
    if (state.invoiceToReplace === 0) return;
    setState({
      ...state,
      finishLoadInvoice: true,
    });
  }, [state.invoiceToReplace]);

  useEffect(() => {
    (async function () {
      if (!state.isModalOpen) {
        dialog.close();
        setState(initialState);
        return;
      }

      dialog.open();

      const invoice = await fetchInvoice();

      setState((current) => ({
        ...current,
        invoice,
      }));
    })();

    //////////////////////////////////////////////////////

    async function fetchInvoice() {
      const invoice = await getInvoiceByUuid(uuid);
      return invoice;
    }
  }, [state.isModalOpen]);

  const setCanceling = (value) => {
    setState({
      ...state,
      canceling: value,
      isCancelable: !value,
    });
  };

  const setLoadInvoiceToReplace = async () => {
    if (!state.isModalOpen || state.search === 0) return;

    const { data } = await getInvoiceToReplace(state.search, uuid);
    setState({
      ...state,
      isLoading: false,
      invoiceToReplace: data,
      loadInvoiceToReplace: true,
    });
  };

  /**
   * Change the motive cancelation
   * @param {string} value
   */
  const handleSelect = (value) => {
    if (value === "1") {
      setState({
        ...state,
        isCfdiToApper: false,
        invoiceToReplace: undefined,
        loadInvoiceToReplace: false,
        finishLoadInvoice: false,
        motiveId: +value,
        facturamaMotive: "01",
        cfdiSustitute: "",
        search: 0,
      });
    } else {
      /**
       * @type {import("./types").CancelationMotiveItem}
       */
      const facturamaMotive = `0${value}`;

      setState({
        ...state,
        motiveId: +value,
        facturamaMotive,
      });
    }
  };

  const handleSearchChange = (value) => {
    setState({
      ...state,
      search: value,
    });
  };

  useEffect(() => {
    if (+state.motiveId !== 1) {
      setState({
        ...state,
        isCfdiToApper: false,
        isCancelable: true,
      });

      return;
    }

    if(state.invoiceToReplace===undefined){
      setState({
        ...state,
        isCfdiToApper: true,
        isCancelable: false,
      });
      return
    }

    if(state.invoiceToReplace.receptor.rfc!==state.invoice.receptor.rfc || state.invoiceToReplace.folio === state.invoice.folio){
      setState({
        ...state,
        isCfdiToApper: true,
        isCancelable: false,
      });
      return
    }
    
    setState({
      ...state,
      isCfdiToApper: true,
      isCancelable: true,
    });

    return;
  }, [state.motiveId, state.cfdiSustitute,state.invoiceToReplace]);

  const attemptCancel = async () => {
    setState((current) => ({
      ...current,
      isCancelling: true,
    }));

    const uuidReplace = state.invoiceToReplace === undefined ? "" : state.invoiceToReplace.uuid

    try {
      const data = await apiCancel(
        userInfo[0].userID,
        uuid,
        state.facturamaMotive,
        "issued",
        uuidReplace
      );

      if (isValidHttpResCode(data.status)) {
        if (data.status === 200) {
          Success(() => {}, data.data.message);
        } else {
          Warning(data.data.message);
        }

        onCancelled();
      }
    } catch (error) {
    } finally {
      resetState();
      dialog.close();
    }
  };

  return {
    cancelInvoice,
    resetState,
    setOpenModal,
    state,
    handleSelect,
    handleSearchChange,
    setLoadInvoiceToReplace,
    setNewCfdi,
    dialog,
    attemptCancel,
    setCanceling,
  };
};
