import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../individual/buttons/Buttons";

import { DownloadFile as ApiDownloadFile } from "../../../helpers/Apis/associateFiles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * 
 * Download a file from azure blob storage
 * 
 * @param {object} props - Props component
 * @param {string} props.url - Url path where it's saved the file
 * @param {string} props.fileExtension - Extension of the file to be downloaded (without the dot)
 * @param {string} props.fileName - Name which gonna have the file once downloaded
 * @param {string?} props.id - id attribute of the button
 * @param {string?} props.text - Text to display on download button
 * @param {string?} props.key - key attribute for virtual dom
 * @returns {JSX.Element} React component to download file from azure blob storage
 */
function DownloadFile({
  url = null,
  fileExtension = null,
  fileName = null,
  id = "download",
  key = "download",
  text = "Descargar",
}) {
  return (
    <>
      <Button 
        id={id} 
        key={key} 
        text={text} 
        funcion = {async function(){

            toast.info('Descargando archivo...')

            await ApiDownloadFile({
                fileName,
                extension:fileExtension,
                urlPath:url
              });
        }}
    />

      <ToastContainer
        position="bottom-left"
        pauseOnHover={false}
        pauseOnFocusLoss={true}
      />
    </>
  );
}

DownloadFile.propTypes = {};

export default DownloadFile;