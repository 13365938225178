import useDialog from "customHooks/useDialog";
import { updateManufacturer } from "helpers/Apis/Directory";
import { useState } from "react";

/**
 * @type {import("./types").StateManufacturer}
 */
const INITIAL_STATE = {
  isDeleting: false,
  editMode: false,
  isSaving: false,
  keyValue: "",
};

/**
 * Handle the abc of the manufacturer related
 * @param {import("../types").PropsManufacturer} manufacturer - Id of the record for the relationshop
 * @param {import("./types").onChangeManufacturer} [onChange=()=>{}] - Callback executed when `ABC` is formed with success
 * @returns {import("./types").ReturnUseManufacturer}
 */
export default function useManufacturer(manufacturer, onChange = () => {}) {
  const [state, setState] = useState(INITIAL_STATE);

  const deleteModal = useDialog();

  const setIsDeleting = (isDeleting) =>
    setState((current) => ({
      ...current,
      isDeleting,
    }));

  const setEditMode = (editMode) =>
    setState((current) => ({
      ...current,
      editMode,
    }));

  const setIsSaving = (isSaving) =>
    setState((current) => ({
      ...current,
      isSaving,
    }));

  const setKey = (keyValue) =>
    setState((current) => ({
      ...current,
      keyValue,
    }));

  const promptCancellation = () => deleteModal.open();

  const performCancellation = async () => {
    let wasDelete = true;

    setIsSaving(true);

    deleteModal.close();

    if (typeof manufacturer.id === "number") {
      wasDelete = await updateManufacturer(
        manufacturer.id,
        manufacturer.supplier.id,
        manufacturer.keyValue,
        true
      );
    }

    if (wasDelete) {
      onChange({
        id: manufacturer.id,
        key: manufacturer.keyValue,
        status: true,
        supplier: manufacturer.supplier,
      },"delete");
    }

    setIsSaving(false);
  };

  const performUpdate = async () => {
    let wasUpdated = true;

    setIsSaving(true);

    if (typeof manufacturer.id === "number") {
      wasUpdated = await updateManufacturer(
        manufacturer.id,
        manufacturer.supplier.id,
        state.keyValue,
        false
      );
    }

    if (wasUpdated) {
      setEditMode(false);
      onChange({
        id: manufacturer.id,
        key: state.keyValue,
        status: true,
        supplier: manufacturer.supplier,
      },"update");
    }

    setIsSaving(false);
  };

  return {
    ...state,
    setIsDeleting,
    setEditMode,
    setIsSaving,
    setKey,
    promptCancellation,
    performCancellation,
    deleteModal,
    performUpdate,
  };
}
