import React from "react";
import { useContext } from "react";
import { ContextCatalogueAdmin } from "../..";
import { Label } from "structure/Document";
import { Controller } from "react-hook-form";
import ToggleComponent from "components/individual/inputs/Toggle";

export default function StatusProduct() {
  const hook = useContext(ContextCatalogueAdmin);

  return (
    <div>
      <Label>Estatus</Label>

      <Controller
        render={({ onChange, value }) => (
          <ToggleComponent isChecked={value} onChange={onChange} />
        )}
        name="status"
        control={hook.formEdit.control}
        
      />

      <p className="text-danger font-weight-bold">
        {hook.formEdit.errors?.status?.message}
      </p>
    </div>
  );
}
