import * as yup from "yup";

const partialitiesAllowed = yup
  .number()
  .min(1, "Mínimo una parcialidad")
  .typeError('Obligatorio')
  .required("Obligatorio")
const tcTolerance = yup
  .number()
  .min(0, "La tolerencia debe ser mínimo 0")
  .typeError('Obligatorio')
  .transform((value, input) => {
    if (input.length <= 0) return 0;

    const parsedInput = input.replaceAll("$", "");

    return +parsedInput;
  })

const invoiceParameters = yup.object().shape({
  partialitiesAllowed,
  tcTolerance,
});

export default invoiceParameters;
