import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { ContextModalDisassociateEgressConcept } from "..";

export const Disassociate = () => {
  const { isDisassociating, attemptDisassociate, disassociate, close } =
    useContext(ContextModalDisassociateEgressConcept);

  if (disassociate.deductible.length + disassociate.noDeductible.length <= 0)
    return <></>;

  if (isDisassociating)
    return <Spinner hidden={false} text="Des-asociando..." />;

  return (
    <ButtonV2
      onClick={async () => {
        const disassociated = await attemptDisassociate();

        if (disassociated) close();
      }}
      theme="danger"
    >
      Des-asociar
    </ButtonV2>
  );
};
