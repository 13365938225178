import LoginContext from "context/Login/LoginContext";
import { base64ToFileV2, fileToBase64 } from "helpers/files";
import { useContext, useEffect, useState } from "react";
import { URL_CLIENT } from "routes/routes";

/**
 * @type {import("./types").StateBanner}
 */
const INITIAL_STATE = {
  customLight: false,
  customDark: false,
  lightModeFile: [],
  darkModeFile: [],
  dataLoaded: false,
  lightMode: "",
  darkMode: "",
};

/**
 * Handle the banner of the app
 * @returns {import("./types").ReturnUseBanner}
 */
export default function useBannerPraxia() {
  const [state, setState] = useState(INITIAL_STATE);

  const session = useContext(LoginContext);

  // const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

  // useEffect(() => {
  //   setState((current) => ({
  //     ...current,
  //     lightModeFile:
  //       session.banner.lightMode !== null ? [session.banner.lightMode] : [],
  //     darkModeFile:
  //       session.banner.darkMode !== null ? [session.banner.darkMode] : [],
  //     dataLoaded: true,
  //   }));
  // }, []);

  useEffect(() => {
    (async function () {
      const lightMode = await retrieveBannerImage(false);
      const darkMode = await retrieveBannerImage(true);

      setState((current) => ({
        ...current,
        darkMode,
        lightMode,
        dataLoaded: true,
      }));
    })();
  }, [session.banner]);

  async function retrieveBannerImage(isDarkMode) {
    if (isDarkMode) {
      return session.banner.darkMode === null
        ? `${URL_CLIENT}images/praxia_clean.png`
        : await fileToBase64(session.banner.darkMode);
    }
    return session.banner.lightMode === null
      ? `${URL_CLIENT}images/praxia_light_mode.png`
      : await fileToBase64(session.banner.lightMode);
  }

  /**
   *
   * @param {File[]} file
   * @param {"lightModeFile"|"darkModeFile"} mode
   * @returns
   */
  const fileFile = (file, mode) =>
    setState((current) => ({
      ...current,
      [mode]: file,
    }));

  return {
    ...state,
    bannerImage: retrieveBannerImage(),
    fileFile,
  };
}
