import React, { useEffect, useState, useContext, createContext } from "react";
import Search from "./Search";

///////////////////////////////////////////////////////////////////

// COMPONENTS
// // HEADER
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { breadBankMovements } from "../../../static/StaticBreadCrums";

// // TABLE
import MovementTable from "../../../helpers/table-schema/Banks/Movements/MovementsTable";
import { DefaultTable } from "js-smart-table/dist/pagination";
import { CustomTable } from "../../../components/general/Table/CustomTable";

import BankInformation from "../BankInformation";

///////////////////////////////////////////////////////////////////

//APIS
import { getMovementsV2 } from "../../../helpers/Apis/Banks";

// LIBRARIES
import { useParams } from "react-router";

//HELPERS
import { rangeFullYear, dateToDbFormat } from "../../../helpers/dates";
import { parseMovements } from "../../../helpers/Banks/Movements/movements";

// CONTEXT
import TableContext from "../../../context/Table/TableContext";
import MovementsContext from "context/Movements/MovementsContext";
import { functionalityPopUpV3 } from "helpers/Apis/Directory";
import { MovementInformation } from "./MovementInformation";
import MovementOverview from "./Overview";
import { BankAccountHeader } from "structure/BankAccountHeader";

/**
 * Context for the movement
 * @type {React.Context<import("types/typedef/context/MovementContext").MovementContextI>}
 */
export const MovementContext = createContext();
const { Provider } = MovementContext;

export default function Movements() {
  const [movement, setMovement] = useState(null);
  const { account } = useParams();

  const { refetch } = useContext(TableContext);
  const { filterMovements } = useContext(MovementsContext);

  let MovementsTable;

  let configTable = {
    ...MovementTable,
    cbSelection,
    urlParams: {
      cuenta: +account,
    },
    paginationFn: async function (page, order, column, aditionalQuery) {
      const data = await getMovementsV2({
        account: +account,
        dateBegin: dateToDbFormat(filterMovements.beginDate),
        dateEnd: dateToDbFormat(filterMovements.endDate),
        page: page,
        status: filterMovements.status,
      });

      // const parsedMovements = await parseMovements(data.movements);
      setMovement(null);
      return data;
    },
  };

  const [range, setRange] = useState({
    begin: null,
    end: null,
  });

  function cbSelection(e) {
    setMovement(MovementsTable.infoRow);
  }

  /**
   * Set the info row as null in order to make it disappear from the UI
   * @returns {void}
   */
  const emptyInfoRow = () => setMovement(null);

  const [loadedComponents, setLoadedComponets] = useState(0);

  document.title = `Movimientos`;

  useEffect(() => {
    async function initialLoad() {
      let counter = 0;
      // const data = await fetchMovements(1,'DESC','MovementID');
      const range = rangeFullYear();
      setRange({
        begin: range.firstDay.jsDate,
        end: range.lastDay.jsDate,
      });

      counter = counter += 1;
      setLoadedComponets(counter);
    }

    initialLoad();
  }, []);

  useEffect(() => {
    if (loadedComponents !== 1) return;

    loadTable();

    async function loadTable() {
      const data = await getMovementsV2({
        account: +account,
        dateBegin: dateToDbFormat(filterMovements.beginDate),
        dateEnd: dateToDbFormat(filterMovements.endDate),
        page: 1,
        status: filterMovements.status,
      });

      configTable = {
        ...configTable,
        rows: data.movements,
        pages: data.pages,
        actualPage: data.actualPage,
      };

      MovementsTable = new DefaultTable(configTable);
      MovementsTable.printTable();
      MovementsTable.printPagination();
      functionalityPopUpV3(0, "list-movements", "overviewMovement");
      // functionalityPopUp(0, "list-movements");
    }
  }, [loadedComponents, refetch]);

  useEffect(() => {
    // functionalityPopUp(0, "list-movements");
    functionalityPopUpV3(0, "list-movements", "overviewMovement");
  }, [movement]);

  return (
    <>
      <HomeHeader bredcrum={breadBankMovements} mostrar={false} />

      <div className="container">
        {/* <BankInformation idAccount={+account} text="Movimientos" /> */}

        <BankAccountHeader idBankAccount={+account} useDefaultDesign />

        <Provider
          value={{
            emptyInfoRow,
          }}
        >
          <Search begin={range.begin} end={range.end} movement={movement} />
          <MovementOverview movement={movement} />
        </Provider>
        <CustomTable
          paginationID="pagination-list-movements"
          tableID="list-movements"
        />

        {/* <MovementInformation infoRow={movement} idAccount={+account} /> */}
      </div>
    </>
  );
}
