import React from "react";
import useIvasAccounting from "./useIvasAccounting";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { RangeDate , From , To } from "components/general/RangeDate";

export default function IvasAccounting() {
  const hook = useIvasAccounting();

  return (
    <div>
      <BreadCrumV2 path={hook.breadcrum} />

      <RangeDate>
        <From selected={hook.from} onChange={hook.setFrom}/>
        <To selected={hook.to} onChange={hook.setTo}/>
      </RangeDate>

      <ButtonV2 disabled={hook.isDownloading} onClick={hook.downloadExcel}>
        <span>Excel</span>
      </ButtonV2>
    </div>
  );
}
