import MovementsContext from "./MovementsContext";
import MovementsReducer from "./MovementsReducer";
import { MOVEMENTS } from "types";
import { useReducer } from "react";

import { monthRange, dateToDbFormat } from "helpers/dates";

const MovementsState = ({ children }) => {
  const { firstDay, lastDay } = monthRange();

  const initialState = {
    filterMovements: {
      beginDate: firstDay,
      endDate: lastDay,
      status: -1,
    },
  };

  const [state, dispatch] = useReducer(MovementsReducer, initialState);

  /**
   * Set the range date to search the filter of movements
   * @param {{
   * begin:string,
   * end:string
   * }} range
   * @returns
   */
  const setRangeDate = (range) =>
    dispatch({
      type: MOVEMENTS.SET_RANGE_DATE,
      payload: range,
    });

  const setStatus = (status) =>
    dispatch({
      type: MOVEMENTS.SET_STATUS,
      payload: status,
    });

  const globalValues = {
    filterMovements: state.filterMovements,
    setRangeDate,
    setStatus,
  };
  return (
    <MovementsContext.Provider value={globalValues}>
      {children}
    </MovementsContext.Provider>
  );
};

export default MovementsState;
