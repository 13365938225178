import { mnCurrency } from "helpers/money";
import { Actions } from "./actions";

export const INITIAL_STATE = {
  isModalOpen: false,
  associations: [],
  movement: undefined,
  isFetching: true,
  title: "Cargando...",
  itemsSelected: {},
  isPerformingQuery: false,
  bank:undefined
};

/**
 * Dispatch and update the state
 * @param {import("./types").StateCancelCxp} state - State of the component
 * @param {import("./types").Action} action - Actions
 */
export function reducer(state, action) {
  switch (action.type) {
    case Actions.SET_OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };

    case Actions.SET_ITEM_SELECTED:
      /**
       * Get the items to use for the cancelation
       * @returns {{[key:string]:import("../../../../../../../types/movements").DtoCxpMovement}}
       */
      function getItemsOnQueue() {
        let copyItems = { ...state.itemsSelected };

        if (action.payload.isChecked) {
          copyItems = {
            ...copyItems,
            [action.payload.association.id]: action.payload.association,
          };
        } else {
          delete copyItems[action.payload.association.id];
        }

        return copyItems;
      }

      const queueToCancel = getItemsOnQueue();

      return {
        ...state,
        itemsSelected: queueToCancel,
      };

    case Actions.SET_IS_FETCHING_DISASSOCIATE:
      return {
        ...state,
        isPerformingQuery: action.payload,
      };

    case Actions.SET_CHECK_ALL:
      function handleCheck() {
        if (action.payload === true) {
          return state.associations.reduce(
            (indexed, association) => ({
              ...indexed,
              [association.id]: association,
            }),
            {}
          );
        } else {
          return {};
        }
      }

      return {
        ...state,
        itemsSelected: handleCheck(),
      };

    case Actions.SET_BANK:
      return {
        ...state,
        bank:action.payload
      }

    case Actions.SET_INITIAL_INFO:
      return {
        ...state,
        associations: action.payload.associations,
        movement: action.payload.movement,
        isFetching: false,
        title: `Egreso ${action.payload.movement.MovementID} | ${mnCurrency(
          action.payload.movement.amount
        )} | ${mnCurrency(action.payload.movement.saldo)}`,
      };

    default:
      return state;
  }
}
