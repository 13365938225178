import React, { useContext } from "react";
import { ContextOcProyects } from "..";
import RedirectBtn from "components/individual/RedirectBtn";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import { parseToFolio } from "helpers/documents";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import LoginContext from "context/Login/LoginContext";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import ui from "./styles.module.scss";
import { CancelOdc } from "pages/Administration/ODC/Options/CancelOdc";

export default function Options() {
  const hook = useContext(ContextOcProyects);

  const session = useContext(LoginContext);

  if (hook.state.oc === undefined)
    return <div className={ui.containerOptions}></div>;

  return (
    <div className={ui.containerOptions}>
      {hook.state.oc.idStatus !== 12 && (
        <RedirectBtn
          link={`/proyectos/recepcion/${hook.state.oc.idDocument}?idPosition=${hook.state.oc.idPosition}`}
        >
          <span>Recepcionar material</span>
        </RedirectBtn>
      )}

      <CancelOdc
        idOdc={hook.state.oc.idDocument}
        onCancelled={hook.refetchData}
        status={hook.state.oc.idStatus}
      />

      <RedirectBtn
        link={`/directorio/documentos/${hook.state.oc.customerId}/ver/${hook.state.oc.idDocument}?modulo=odc`}
      >
        <span>Ver documento</span>
      </RedirectBtn>

      <DonwloadPdfV2
        id={hook.state.oc.idDocument}
        fileName={`Orden de compra ${parseToFolio(
          hook.state.oc.documentNumber
        )}`}
      />

      <RedirectBtn
        link={`/administracion/ordenes-compra/archivos-asociados/${hook.state.oc.idDocument}`}
      >
        <span>Archivos asociados</span>
      </RedirectBtn>

      <EmailDocumentsV2
        idDocument={hook.state.oc.idDocument}
        subject={`Praxia - Orden de compra ${parseToFolio(
          hook.state.oc.documentNumber
        )}`}
        message={`Se le adjunta la informacion de la orden de compra ${parseToFolio(
          hook.state.oc.documentNumber
        )} (pdf)`}
        cc={[session.userInfo[0].email]}
        to={[hook.state.oc.email]}
        onSendedEmail={async () => {
          await hook.odc.handleOcSent(hook.state.oc.idDocument);
          hook.refetchData();
        }}
      >
        <ButtonV2>Enviar correo</ButtonV2>
      </EmailDocumentsV2>
    </div>
  );
}
