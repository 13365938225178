// @ts-check
import React, { createContext, useState, useEffect } from "react";
import useConcepts from "customHooks/useConcepts/index";
import styles from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import Input from "./Input";
import { truncateDecimals } from "helpers/money";
import Autocalculated from "./Autocalculated";
import { NOT_ALLOWED_CONCEPTS } from "./Input";
import Table from "components/general/Table";
import { Search } from "components/individual/Search";
import Fuse from "fuse.js";

/**
 *
 * @param {import("react-data-table-component").TableProps<import("types/typedef/customHooks/useConcepts").ConceptI>} props
 */
function TableGrid(props) {
  return <Table {...props} />;
}

/**
 * @type {import("react").Context<import("customHooks/useConcepts/types").ContextEgressConcept|undefined>}
 */
export const ContextEgressConcept = createContext(undefined);

/**
 * Grid to associate egresses
 * @param {import("./types").PropsConceptsEgresses} props - Props
 * @returns {JSX.Element} Render the grid to associate concept egresses
 */
export default function ConceptsEgresses({
  deductible = [],
  noDeductible = [],
  onChange = () => {},
  decimals = 2,
  displayConcepts = true,
  idProvider = null,
}) {
  const [state, setState] = useState({
    noDeductible: {},
    deductible: {},
  });

  useEffect(() => {
    const proportionsFromDeductible = calculateProportions(deductible);
    const proportionsNoDeductible = calculateProportions(noDeductible);

    setState((current) => ({
      ...current,
      noDeductible: proportionsNoDeductible,
      deductible: proportionsFromDeductible,
    }));

    function calculateProportions(list) {
      return list.reduce((indexed, concept) => {
        if (!isIdOnCurrentList()) {
          const value = initializeKey();

          return value;
        }

        const value = addProportion();

        return value;

        ///////////////////////////////////////////////////////////////////////////////////////

        function addProportion() {
          const currentTotal = indexed[concept.id];
          const calculatedProportion = truncateDecimals(
            (concept.total * concept.percentage) / 100,
            decimals
          );

          const calculation = {
            ...indexed,
            [concept.id]: truncateDecimals(
              currentTotal + calculatedProportion,
              decimals
            ),
          };

          return calculation;
        }

        function isIdOnCurrentList() {
          return Object.keys(indexed).includes(`${concept.id}`);
        }

        // Id is on the list, add the item on list
        function initializeKey() {
          const calculation = {
            ...indexed,
            [concept.id]: truncateDecimals(
              (concept.total * concept.percentage) / 100,
              decimals
            ),
          };
          return calculation;
        }
      }, {});
    }
  }, [deductible, noDeductible]);

  /**
   *
   * @param {import("customHooks/useCreateEgress/types").PartialitieConcept[]} list
   */
  function excludeBusinessRules(list) {
    return list.filter((cxp) => !NOT_ALLOWED_CONCEPTS.includes(cxp.id));
  }

  const hook = useConcepts({
    decimals: 4,
    onChange,
    type: "egresos",
    idCustomer: idProvider,
    numberPartialitiesAssociated:
      excludeBusinessRules(deductible).length +
      excludeBusinessRules(noDeductible).length,
  });

  const [search,setSearch] = useState({
    search:'',
    items:hook.concepts
  });

  useEffect(()=>{
    setSearch(current=>({
      ...current,
      items:hook.concepts
    }));
  },[hook.concepts])

  const updateSearchField = search => setSearch(current=>({
    ...current,
    search  
  }));

  const attemptSearch = () => {

    if(search.search === ""){
      setSearch(current=>({
        ...current,
        items:hook.concepts
      }))
      return;
    }

    const fuse = new Fuse(hook.concepts, {
      keys: ["description"],      
      threshold:0.1,
      distance:200
    });

    const records = fuse.search(search.search).map(item=>item.item);

    setSearch(current=>({
      ...current,
      items:records
    }));
  }

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="expenses-concepts"
        text="Cargando conceptos"
      />
    );

  if (hook.concepts.length <= 0)
    return (
      <h1 className="text-center py-5">No hay conceptos cargados al sistema</h1>
    );

  return (
    <ContextEgressConcept.Provider value={{ ...hook, onChange }}>
      <div className={styles.containerConceptEgresses}>
      <div className="my-3">
        <b>Concepto</b>
        <Search
          placeholder="Escribe aquí (opcional)"
          onChange={updateSearchField}
          onDelete={updateSearchField}
          onSearch={attemptSearch}
        />
      </div>
        {/* <div className={styles.header}>
          <p>Concepto</p>
          <p>Deducible</p>

          <p>Comprobante</p>
          <p>Sin Comprobante</p>
        </div> */}

        {displayConcepts && (
          <TableGrid
            // data={hook.concepts}
            data={search.items}
            sortServer={false}
            pagination={false}
            columns={[
              {
                name: "Concepto",
                selector: (item) => item.description,
                sortable: true,
                width:"30%"
              },
              {
                name:'Tipo',
                selector:item=>item.type.description,
                sortable:true,
                width:"20%"
              },
              {
                name: "Deducible",
                selector: (item) => (
                  <Autocalculated amount={state.deductible[item.id]} />
                ),
                sortable: false,
                width:"10%"
              },
              {
                name: "Comprobante",
                selector: (item) => (
                  <Autocalculated amount={state.noDeductible[item.id]} />
                ),
                sortable: false,
                width:"20%"
              },
              {
                name: "Sin Comprobante",
                selector: (item) => <Input egress={item} />,
                sortable: false,
                width:"20%"
              },
            ]}
          />
        )}

        {/* {displayConcepts
          ? hook.concepts.map((concept, i) => (
              <div key={`concept-egress-${i}`} className={styles.row}>
                <p>{concept.description}</p>

                <Autocalculated amount={state.deductible[concept.id]} />

                <Autocalculated amount={state.noDeductible[concept.id]} />
                <Input egress={concept} />
              </div>
            ))
          : null} */}
      </div>
    </ContextEgressConcept.Provider>
  );
}
