import React, { Fragment, useContext, useRef } from "react";
import ForwardInputField from "components/individual/InputField";
import useFormPositionProyect from "./useFormPosition";
import { Label } from "structure/Document";
import CommentBox from "components/individual/CommentBox";
import { Controller } from "react-hook-form";
import UENV2 from "components/general/Forms/Selects/UENV2";
import SatCode from "structure/SatCode";
import SatUm from "structure/SatUm";
import IvasAvailable, {
  Iva,
  IvaExempt,
} from "components/individual/IvasAvailable";
import ForwardedProyectStatus from "components/individual/ProyectStatus";
import { mnCurrency, percentage } from "helpers/money";
import Money from "components/general/Forms/Money";
import { Spinner } from "components/individual/Spinner/Spinner";

/**
 * @type {import("./useFormPosition/types").ContextFormPosition}
 */
const ContextFormPosition = React.createContext(undefined);

/**
 * Render a form field to fill the positions for a proyect
 * @param {import('./types').PropsFormPosition} props - Props
 * @returns {JSX.Element}
 */
export default function FormPosition(props) {
  const hook = useFormPositionProyect({
    idPosition: props?.idPosition,
    idProyect: props?.idProyect,
    data: props?.data,
    onCorrectSubmit: props?.onCorrectSubmit,
    rfq: props.rfq,
    solped: props.solped,
  });

  let propsFormTag = { ...props };
  delete propsFormTag.idProyect;
  delete propsFormTag.idPosition;
  delete propsFormTag.data;
  delete propsFormTag.rfq;
  delete propsFormTag.solped;

  if (hook.isLoading)
    return <Spinner text={"Cargando posición"} hidden={false} />;

  return (
    <ContextFormPosition.Provider value={{ ...hook, ...props }}>
      <form
        {...propsFormTag}
        noValidate
        onSubmit={hook.form.handleSubmit(hook.handleSubmitForm, (e) =>
          console.log(e)
        )}
      >
        {props.children}
      </form>
    </ContextFormPosition.Provider>
  );
}

export function Id() {
  const hook = useContext(ContextFormPosition);

  return (
    <div className="d-none">
      <ForwardInputField ref={hook.form.register} type="number" name="id" />
    </div>
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function Pos(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        maxLength={256}
        label="Posición"
        placeholder="Escribe aquí"
        required={true}
        name="pos"
      />
      <p className="text-danger font-weight-bold my-1">
        {hook.form.errors.pos?.message}
      </p>
    </div>
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function Oc(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        maxLength={256}
        label="OC cliente"
        placeholder="Escribe aquí"
        name="ocCustomer"
      />
      <p className="text-danger font-weight-bold my-1">
        {hook.form.errors.ocCustomer?.message}
      </p>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function DescriptionPosition(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <Controller
      name="description"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label required={true}>Descripción</Label>
          <CommentBox onChange={onChange} value={value} />
          <p className="text-danger font-weight-bold">
            {hook.form.errors.description?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function Cost(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <Controller
      name="cost"
      control={hook.form.control}
      defaultValue={hook.cost}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label required={hook.cost === "" || hook.cost === undefined}>
            Costo estimado
          </Label>
          <Money
            maxDecimals={2}
            placeholder="0.00"
            onChange={(amount) => {
              onChange(amount);
              hook.setCost(amount);
            }}
            defaultValue={value}
            value={value}
          />

          <p className="text-danger font-weight-bold my-1">
            {hook.form.errors.cost?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function Sell(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <Controller
      name="sell"
      defaultValue={hook.sell}
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label required={hook.sell === "" || hook.sell === undefined}>
            Venta estimada
          </Label>

          <Money
            maxDecimals={2}
            placeholder="0.00"
            onChange={(amount) => {
              onChange(amount);
              hook.setSell(amount);
            }}
            value={value}
          />

          <p className="text-danger font-weight-bold my-1">
            {hook.form.errors.sell?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function UenField(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <div {...props}>
      <Controller
        name="uen"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <UENV2
            onChange={(data) => {
              onChange(data.UENID);
              hook.form.setValue(
                "satCode",
                `${data.SATcode} - ${data.satCodeDescription}`
              );
              hook.form.setValue(
                "satUm",
                `${data.SATUM} - ${data.satUmDescription}`
              );
              hook.form.setValue("iva", data.iva);
              hook.setIva(data.iva);
            }}
            value={value}
          />
        )}
      />

      <p className="text-danger font-weight-bold my-1">
        {hook.form.errors.uen?.message}
      </p>
    </div>
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function SatCodeField(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <div {...props}>
      <Controller
        name="satCode"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <Fragment>
            <SatCode
              description={value?.split(" - ")[1] || ""}
              name={value?.split(" - ")[0] || ""}
              onChange={(data) => onChange(`${data.Value} - ${data.Name}`)}
            />

            <p className="text-danger font-weight-bold my-1">
              {hook.form.errors.satCode?.message}
            </p>
          </Fragment>
        )}
      />
    </div>
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function SatUmField(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <div {...props}>
      <Controller
        name="satUm"
        control={hook.form.control}
        render={({ onChange, value }) => (
          <SatUm
            description={value?.split(" - ")[1] || ""}
            name={value?.split(" - ")[0] || ""}
            onChange={(data) => onChange(`${data.Value} - ${data.Name}`)}
          />
        )}
      />

      <p className="text-danger font-weight-bold my-1">
        {hook.form.errors.satUm?.message}
      </p>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function FieldProyectStatus(props) {
  const hook = useContext(ContextFormPosition);

  /**
   * @type {import("customHooks/useProyects/types").RefIndexedStatus}
   */
  const indexedStatus = useRef({
    SolicitudUrgente: "Solicitud urgente",
    SolicitudNormal: "Solicitud normal",
    Propuesta: "Propuesta",
    Activo: "Activo",
    Terminado: "Terminado",
    NoAsignado: "No Asignado",
    Cancelar: "Cancelado",
  });

  return (
    <>
      <div {...props}>
        <Label>Estatus</Label>

        {+hook.form.getValues("id") <= 0
          ? hook?.rfq === "" ||
            hook?.rfq === undefined ||
            hook?.solped === "" ||
            hook?.solped === undefined
            ? "Solicitud urgente"
            : "Solicitud normal"
          : indexedStatus.current[hook.form.getValues("status")] ||
            "No asignado"}

        <div className="d-none">
          <ForwardedProyectStatus
            ref={hook.form.register}
            name="status"
            disableTodos={true}
          />
        </div>
        <p className="text-danger font-weight-bold">
          {hook.form.errors.status?.message}
        </p>
      </div>
    </>
  );
}

/**
 * Field for the position
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 */
export function Percentage(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <Controller
      name="percentageOfCompletion"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <div
            className={
              hook.form.getValues("status") === ("Urgente" || "Normal")
                ? ""
                : "d-none"
            }
          >
            <Label>% Avance</Label>
            <p>0%</p>
          </div>

          <div
            className={
              hook.form.getValues("status") === "Activo" ? "" : "d-none"
            }
          >
            <Label>% Avance</Label>

            <input
              type="number"
              onChange={(e) => onChange(e.target.value)}
              value={value}
              placeholder="Escribe aquí"
            />

            <p className="text-danger font-weight-bold">
              {hook.form.errors.percentageOfCompletion?.message}
            </p>
          </div>
        </div>
      )}
    />
  );

  return (
    <div {...props}>
      <ForwardInputField
        ref={hook.form.register}
        label="% Avance"
        placeholder="Escribe aquí"
        type="number"
        maxLength={6}
        required={true}
        name="percentageOfCompletion"
      />
      <p className="text-danger font-weight-bold">
        {hook.form.errors.percentageOfCompletion?.message}
      </p>
    </div>
  );
}

/**
 * Render ivas
 * @param {import("components/individual/IvasAvailable/types").PropsIvasAvailable} props
 */
export function IvaContainer(props) {
  return <IvasAvailable {...props}></IvasAvailable>;
}

/**
 * Render iva field
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
export function IvaField(props) {
  const hook = useContext(ContextFormPosition);

  return (
    <Controller
      name="iva"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label>IVA</Label>
          <Iva
            value={value}
            onChange={(iva) => {
              onChange(iva);
              hook.setIva(iva);
            }}
          ></Iva>

          <p className="text-danger font-weight-bold my-1">
            {hook.form.errors.iva?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Render iva exempt field
 * @param {import("components/individual/IvasAvailable/types").PropsExempt} props 
 
 */
export function IvaExemptField(props) {
  return <IvaExempt {...props}></IvaExempt>;
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function TotalCost(props) {
  const hook = useContext(ContextFormPosition);
  return (
    <div {...props}>
      <Label>Costo total</Label>
      {hook.cost === undefined || hook.cost === "" ? (
        <p className="m-0">$0.00</p>
      ) : hook.iva === undefined ? (
        <p className="font-weight-bold text-danger m-0">Selecciona un IVA</p>
      ) : (
        <p>{mnCurrency(+hook.cost + percentage(+hook.cost, +hook.iva))}</p>
      )}
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function TotalSell(props) {
  const hook = useContext(ContextFormPosition);
  return (
    <div {...props}>
      <Label>Venta total</Label>
      {hook.sell === undefined || hook.sell === "" ? (
        <p className="m-0">$0.00</p>
      ) : hook.iva === undefined ? (
        <p className="font-weight-bold text-danger m-0">Selecciona un IVA</p>
      ) : (
        <p>{mnCurrency(+hook.sell + percentage(+hook.sell, +hook.iva))}</p>
      )}
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} props - Props
 * @returns {JSX.Element}
 */
export function TotalSellIva(props) {
  const hook = useContext(ContextFormPosition);

  if (hook.sell === undefined || hook.sell === "")
    return (
      <div>
        <Label>IVA</Label>
        <span>$0.00</span>
      </div>
    );

  if (hook.iva === undefined)
    return (
      <div>
        <Label>IVA</Label>
        <span>$0.00</span>
      </div>
    );

  return (
    <div>
      <Label>IVA</Label>
      <span {...props}>{mnCurrency(percentage(+hook.sell, +hook.iva))}</span>
    </div>
  );
}

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} props - Props
 * @returns {JSX.Element}
 */
export function TotalCostIva(props) {
  const hook = useContext(ContextFormPosition);

  if (hook.cost === undefined || hook.cost === "")
    return (
      <div>
        <Label>IVA</Label>
        <span>$0.00</span>
      </div>
    );

  if (hook.iva === undefined)
    return (
      <div>
        <Label>IVA</Label>
        <span>$0.00</span>
      </div>
    );

  return (
    <div>
      <Label>IVA</Label>
      <span {...props}>{mnCurrency(percentage(+hook.cost, +hook.iva))}</span>
    </div>
  );
}
