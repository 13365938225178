import { useEffect, useRef, useState } from "react";
import apiManagment from "helpers/Apis/purchases/ocManagment";
import { rangeFullYear } from "helpers/dates";
import { Success } from "helpers/alerts";
import { updateOdcStatusToSended } from "helpers/Apis/Documents";

/**
 * @type {import("./types").StateGestionOc}
 */
const INITIAL_STATE = {
  canDisplayCheckbox:false,
  canSendEmail:false,
  canUpdate: false,
  checkboxes: [],
  customer: undefined,
  from: rangeFullYear().firstDay.jsDate,
  isLoading: true,
  isUpdating: false,
  managmentRecords: [],
  provider: undefined,
  record: undefined,
  searchFlag: true,
  status: "notSent",
  to: rangeFullYear().lastDay.jsDate,
  totalRecords:0,
  notConfirmed: {
    confirmed: {},
    notConfirmed: [],
  },
};

export const indexedStatus = {
  1: "notSent",
  2: "notConfirmed",
  3: "cfNotConfirmed",
  4: "myContract",
  5: "noLicenses",
  6: "noInvoice",
  7: "all",
};

/**
 *
 * @returns {import("./types").ReturnGestionOc}
 */
export default function useGestionOc() {
  const [state, setState] = useState(INITIAL_STATE);

  const formId = useRef(`${window.crypto.randomUUID()}`);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        record: undefined,
        checkboxes: [],
        notConfirmed: {
          confirmed: {},
          notConfirmed: [],
        },
        managmentRecords: [],
      }));

      const records = await apiManagment.get({
        beginDate: state.from,
        endDate: state.to,
        idClient: state.customer?.id || null,
        idSupplier: state.provider?.id || null,
        notSended: state.status === "notSent",
        notLicences: state.status === "noLicenses",
        notInvoiced: state.status === "noInvoice",
        notConfirmed: state.status === "notConfirmed",
        notCfSingned: state.status === "cfNotConfirmed",
        notCotnractSigned: state.status === "myContract",
        all: state.status === "all",
      });

      const totalRecords = records.length > 0 ? records[0].total : 0;

      setState((current) => ({
        ...current,
        managmentRecords: records,
        isLoading: false,
        totalRecords
      }));
    })();
  }, [state.searchFlag, state.status]);

  useEffect(() => {
    if (state.status === "notConfirmed") {
      const hasNotConfirmedItems = state.notConfirmed.notConfirmed.length > 0;
      const hasConfirmedItems =
        Object.keys(state.notConfirmed.confirmed).length > 0;

      if (!hasConfirmedItems && !hasNotConfirmedItems) {
        setState((current) => ({
          ...current,
          canUpdate: false,
        }));
        return;
      }

      let canUpdate = true;

      if (hasConfirmedItems) {
        Object.entries(state.notConfirmed.confirmed).forEach(([key, value]) => {
          if (state.notConfirmed.confirmed[key].length <= 0) {
            canUpdate = false;
          }
        });
      }

      setState((current) => ({
        ...current,
        canUpdate,
      }));
      return;
    }

    if (state.checkboxes.length > 0) {
      setState((current) => ({
        ...current,
        canUpdate: true,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      canUpdate: false,
    }));
  }, [state.notConfirmed, state.status, state.checkboxes]);

  /**
   * Attempt the search of the records
   * @param {React.FormEvent<HTMLFormElement>} [e=undefined] - Form event
   */
  const attemptSearch = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }

    setState((current) => ({
      ...current,
      searchFlag: !current.searchFlag,
    }));
  };

  /**
   * Set the record selected
   * @param {import("helpers/Apis/purchases/ocManagment/types").OcManagment} record - Information of the oc
   * @returns {void}
   */
  const setRecord = (record) =>
    setState((current) => ({
      ...current,
      record,
      canSendEmail: record.odcCurrency !== "***" && state.status === 'notSent'
    }));

  /**
   * Update the status of the managment
   * @param {number} status - Number of step selected
   * @returns {void}
   */
  const setStatus = (status) => {
    /**
     * @type {import("./types").Status}
     */
    const statusString = indexedStatus[status];

    const notAllowedStatus = ["notConfirmed", "all", "noInvoice", "notSent"];

    setState((current) => ({
      ...current,
      notConfirmed: {
        confirmed: {},
        notConfirmed: [],
      },
      status: statusString,
      canDisplayCheckbox:!notAllowedStatus.includes(statusString),
      
      
    }))
  };

  /**
   *
   * @param {import("./types").Combo} type - Type of combo
   * @param {import("components/general/Forms/Selects/SocialReason/types").ComboDirectoryDtoI} data - Information of the combo
   */
  const setCustomerProvider = (type, data) => {
    const dataToSet = typeof data.id !== "number" ? undefined : data;
    const key = type === "customer" ? "customer" : "provider";

    setState((current) => ({
      ...current,
      [key]: dataToSet,
    }));
  };

  /**
   *
   * @param {import("./types").DateType} type - Type to set
   * @param {Date} date - Date to set
   */
  const setDate = (type, date) => {
    const keyToUse = type === "from" ? "from" : "to";

    setState((current) => ({
      ...current,
      [keyToUse]: date,
    }));
  };

  function headersTable() {
    const baseHeader = [
      "No. ODC",
      "Emisión",
      "Cliente",
      "Proveedor",
      "Factura",
      "Envio",
      "Confirmación",
      "C.F. firmadó",
      "Mi contrato firmado",
      "Licencias confirmadas",
    ];

    if (state.status === "notSent") return baseHeader;

    if (state.status === "notConfirmed")
      return [
        ...baseHeader,
        "Requiere confirmación",
        "No requiere confirmación",
        "No. confirmación",
      ];

    if (state.status === "cfNotConfirmed") return [...baseHeader, "C.F. firma"];

    if (state.status === "myContract") return [...baseHeader, "C.I. firma"];

    if (state.status === "noLicenses")
      return [...baseHeader, "Confirmación de licencia"];

    if (state.status === "noInvoice") return [...baseHeader];

    return baseHeader;
  }

  /**
   * Toggle the radio button for the oc
   * @param {boolean|undefined|null} requiresConfirmation - Value for the confirmation
   * @param {number} id - Id of the `ODC`
   */
  function toggleConfirmationOc(requiresConfirmation, id) {
    let data = { ...state.notConfirmed };

    if (typeof requiresConfirmation !== "boolean") {
      deleteFromConfirmed();
      deletefromNotRequiresConfirmation();
      setState((current) => ({
        ...current,
        notConfirmed: data,
      }));
      return;
    }

    if (!requiresConfirmation) {
      deleteFromConfirmed();
      data.notConfirmed.push(id);
    } else {
      addToRequireConfirmation();
      deletefromNotRequiresConfirmation();
    }

    setState((current) => ({
      ...current,
      notConfirmed: data,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////

    function deleteFromConfirmed() {
      delete data.confirmed[id];
    }

    function addToRequireConfirmation() {
      data = {
        ...data,
        confirmed: {
          ...data.confirmed,
          [id]: "",
        },
      };
    }

    function deletefromNotRequiresConfirmation() {
      data = {
        ...data,
        notConfirmed: data.notConfirmed.filter((idItems) => idItems !== id),
      };
    }
  }

  /**
   * Update the records
   * @param {string} sku - Sku for the odc
   * @param {number} id - Id of the ODC
   * @returns {void}
   */
  const setConfirmationSku = (sku, id) =>
    setState((current) => ({
      ...current,
      notConfirmed: {
        ...current.notConfirmed,
        confirmed: {
          ...current.notConfirmed.confirmed,
          [id]: sku,
        },
      },
    }));

  async function updateOcRecords() {
    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    let wasUpdated = false;

    if (state.status === "notConfirmed") {
      wasUpdated = await updateNotConfirmated();
    } else {
      wasUpdated = await apiManagment.update.record(
        state.checkboxes,
        state.status
      );
    }

    if (wasUpdated) {
      Success(() => {}, "Registros actualizados");
      attemptSearch();
    }

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////

    async function updateNotConfirmated() {
      return await apiManagment.update.ocNotConfirmated(
        state.notConfirmed.notConfirmed,
        Object.entries(state.notConfirmed.confirmed).map(([key, value]) => ({
          confirmationNumber: value,
          idOdc: +key,
        }))
      );
    }
  }

  /**
   * Handle the checkboxes toggle
   * @param {number} idRecord - Id of the record to check/uncheck
   * @param {boolean} isChecked - True to append it into list
   */
  const toggleCheckboxRecord = (idRecord, isChecked) => {
    setState((current) => ({
      ...current,
      checkboxes: isChecked
        ? [...current.checkboxes, idRecord]
        : current.checkboxes.filter((id) => id !== idRecord),
    }));
  };

  /**
   * Handle a oc sent on the system
   * @param {number} [id=null] 
   */
  const handleOcSent = async (id=null) => {

    const idToUse = typeof id === "number" ? id : state.record.idOdc;

    await updateOdcStatusToSended(idToUse);
    attemptSearch();
  };

  return {
    ...state,
    toggleCheckboxRecord,
    handleOcSent,
    attemptSearch,
    updateOcRecords,
    setDate,
    setConfirmationSku,
    setStatus,
    setCustomerProvider,
    formId: formId.current,
    headers: headersTable(),
    toggleConfirmationOc,
    setRecord,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/compras/gestion-oc",
        text: "Compras",
      },
      {
        route: "/compras/gestion-oc",
        text: "Gestión OC",
      },
    ],
  };
}
