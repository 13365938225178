import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { useAsignTicket } from "pages/Tickets/hooks/useAsignTicket";
import React , { useRef } from "react";
import "./styles.css";
import LoadingText from "components/individual/TextLoading";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";
import Dialog from "structure/Dialog";

/**
 * Render a modal button in order to assign tickets to users
 * @param {import("./types").PropsAsignTicket} props - Props
 * @returns {JSX.Element}
 */
export const AsignApp = ({
  idTicket,
  onAssigned = () => {},
  assignedTo = null,
  children = null,
  idButton = window.crypto.randomUUID()
}) => {
  const {
    attemptAsignTicket,
    combo,
    handleSelectUser,
    isSelectedUser,
    
    closeModal,
    openModal,
    dialog,
  } = useAsignTicket(idTicket, onAssigned,assignedTo);
  const { userInfo } = useContext(LoginContext);

  const key = useRef(window.crypto.randomUUID());

  if (userInfo[0].ticketRol === "administrator" && assignedTo === null)
    return (
      <>
        {children === null ? (
          <ButtonV2 id={idButton} onClick={openModal}>Iniciar</ButtonV2>
        ) : (
          <div id={idButton} onClick={openModal}>{children}</div>
        )}

        <Dialog
          footer={
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-danger m-0">
                {!isSelectedUser ? "Selecciona un usuario" : ""}
              </p>
              {combo.isAssigning ? (
                <Spinner
                  hidden={false}
                  text={<LoadingText>Iniciando ticket</LoadingText>}
                />
              ) : (
                <ButtonV2
                  disabled={!isSelectedUser}
                  type="button"
                  onClick={attemptAsignTicket}
                >
                  Iniciar
                </ButtonV2>
              )}
            </div>
          }
          title="Iniciar"
          forwardRef={dialog.refDialog}
          closeCallback={closeModal}
        >
          {combo.isLoading ? (
            <Spinner
              hidden={false}
              text={<LoadingText>Cargando ejecutivos</LoadingText>}
            />
          ) : (
            <div className="usersToAssign">
              {combo.users.map((item, i) => (
                <div
                  className="d-flex justify-content-between"
                  key={`${key.current}-${i}`}
                >
                  <input
                    className="d-none"
                    type="radio"
                    id={`${key.current}-${i}`}
                    name={`${key.current}`}
                    onChange={(e) => handleSelectUser(item.id)}
                  />
                  <label
                    htmlFor={`${key.current}-${i}`}
                    className="EngineerInfo"
                  >
                    {item.name}
                    <span>✔</span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </Dialog>
      </>
    );

  return <></>;
};
