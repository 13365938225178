import styled from "styled-components";

export const MoneyConteiner = styled.div`
  background-color: var(--cardBackground);
  border-radius: 10px;
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0.8rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  gap:1rem 0;
`;
export const MoneyTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width:100%;
  align-items: flex-end;
  padding-right: 0.2rem;
  justify-content: flex-end;
  gap:1rem 0;
  p {
    margin: 0;
    &:after {
      content: ":";
      margin: 0 10px;
    }
  }
`;
export const InputsSection = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;

  /* flex-wrap: wrap; */
  gap: 1rem 0;
`;

export const InputLabelSection = styled.div`
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width:100%;
  align-items: center;
  p {
    margin: 0;
    &:after {
      content: ":";
      margin: 0 10px;
    }
  }
`;
export const InputLabelPercentageSection = styled.div`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width:100%;
  align-items: center;
  p {
    margin: 0;
    &:after {
      content: ":";
      margin: 0 10px;
    }
  }
`;
