// @ts-check

import { useState } from "react";
import {
  bankAccountReport,
  bankAccountReportExcel,
} from "helpers/Apis/Banks/index";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { MONTHS, MONTH_LIST } from "helpers/dates";

/**
 * State
 * @type {import("structure/Menu/Banks/Reports/BankAccount/types").StateBankAccountReport}
 */
const INITIAL_STATE = {
  bankAccount: null,
  fileName: null,
  isDownloading: false,
  dateSelected: null,
  minDate: new Date(),
  maxDate: new Date(2023, 10, 10),
  options: [],
  year:null,
  month:null
};

/**
 * Handle the state to create the bank account reports
 * @param {import("../../../../server/models/banks/types").BankAccountI?} bankAccount - Information of the bank account in case the user already know which report is gonna create
 * @param {boolean?} allowExchangeAccount - If false, user won't be able to exchange the bank account provided
 * @returns {import("./types").ReturnUseBankAccountReport}
 */
export default function useBankAccountReport(
  bankAccount = null,
  allowExchangeAccount = true
) {
  const [state, setState] = useState(INITIAL_STATE);

  const [isOpen, setIsOpen] = useState(false);

  const resetState = () => setState(INITIAL_STATE);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  /**
   * Update the flag to indicate the client is generating the pdf file
   * @param {boolean} isDownloading - Flag
   * @returns {void}
   */
  const setIsDownloading = (isDownloading) =>
    setState((current) => ({
      ...current,
      isDownloading,
    }));

  const handleDownloadPdf = async () => {
    setIsDownloading(true);

    const pdf = await bankAccountReport(
      state.bankAccount.id,
      state.month,
      state.year
    );

    if (pdf !== null) {
      saveAs(
        pdf,
        `Estado de cuenta ${state.bankAccount.account} - ${
          MONTH_LIST[state.dateSelected.getMonth()].name
        } ${state.dateSelected.getFullYear()}.pdf`
      );
    }

    setIsDownloading(false);
  };

  /**
   * Update the bank account to use to create the report
   * @param {import("../../../../../../../server/models/banks/types").BankAccountI} bankAccount
   * @returns {void}
   */
  const updateBankAccount = (bankAccount) => {
    const allowdDates = bankAccount.monthsClosed.map((listDateItem) =>
      new Date(`${listDateItem}`).getTime()
    );

    setState((current) => ({
      ...current,
      bankAccount,
      maxDate: new Date(Math.max(...allowdDates)),
      minDate: new Date(Math.min(...allowdDates)),
    }));
  };

  const downloadExcelReport = async () => {
    setIsDownloading(true);
    const excel = await bankAccountReportExcel(
      state.bankAccount.id,
      state.month,
      state.year
    );

    if (excel !== null) {
      saveAs(
        excel,
        `Estado de cuenta ${state.bankAccount.account} - ${
          MONTH_LIST[state.dateSelected.getMonth()].name
        } ${state.dateSelected.getFullYear()}.xlsx`
      );
    }
    setIsDownloading(false);
  };

  /**
   * Update the date for the PDF report range date
   * @param {Date} date - Date selected
   * @returns {void}
   */
  const updateDateSelected = (date) =>
    setState((current) => ({
      ...current,
      dateSelected: date,
    }));

  useEffect(() => {
    setState((current) => ({
      ...current,
      dateSelected: null,
    }));
  }, [state.bankAccount]);

  useEffect(() => {
    if (bankAccount === null) return;

    const allowdDates = bankAccount.monthsClosed.map((listDateItem) =>
      new Date(`${listDateItem}:`).getTime()
    );

    const maxDate = new Date(Math.max(...allowdDates));
    const minDate = new Date(Math.min(...allowdDates));

    const date = allowdDates.length === 1 ? maxDate : null;

    const options = bankAccount.monthsClosed.map((date) => {
      const option = new Date(`${date}:`);

      return {
        label: `[${option.getUTCFullYear()}] ${MONTHS[option.getUTCMonth()]}`,
        value: option,
      };
    });

    const reversedOptions = options.reverse();

    setState((current) => ({
      ...current,
      bankAccount,
      maxDate,
      minDate,
      // dateSelected: date,
      options,
      dateSelected: reversedOptions[0].value,
    }));
  }, [bankAccount]);

  useEffect(() => {
    if (state.bankAccount === null) return;
    const options = state.bankAccount.monthsClosed.map((date) => {
      const option = new Date(`${date}:`);

      return {
        label: `[${option.getUTCFullYear()}] ${MONTHS[option.getUTCMonth()]}`,
        value: option,
      };
    });

    const reversedOptions = options.reverse();

    setState((current) => ({
      ...current,
      options,
      dateSelected: reversedOptions[0].value,
    }));
  }, [state.bankAccount?.monthsClosed]);

  const setYear = year => setState(current=>({
    ...current,
    year
  }));

  const setMonth = month => setState(current=>({
    ...current,
    month
  }));

  return {
    updateBankAccount,
    handleDownloadPdf,
    closeModal,
    openModal,
    updateDateSelected,
    isOpen,
    resetState,
    downloadExcelReport,
    setYear,
    setMonth,
    ...state,
  };
}
