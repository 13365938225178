import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextOdc } from "..";
import Status from "./Status";
import ui from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import CustomerSocialReason from "./SocialReason";
import Excel from "../Excel";
import Dates from "./Dates";
import Create from "./Create";
import SearchOc from "./SearchOc";

export default function Filter() {
  const { triggerSearch } = useContext(ContextOdc);

  return (
    <div className={ui.filter}>
      <CustomerSocialReason />

      <Status />

      <SearchOc />

      <Dates />

      <ButtonV2 onClick={triggerSearch}>
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>

      <Create />

      <Excel />
    </div>
  );
}
