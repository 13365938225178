import React, { createContext } from "react";
import usePropousal from "./usePropousal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BreadCrum from "components/individual/breadCrums/BreadCrumV2";
import Header, { Customer, Pos, Solped } from "pages/Proyects/Header";
import TablePropousal from "./TablePropousal";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

/**
 * @type {import("react").Context<import("./usePropousal/types").ReturnUsePropousal>}
 */
export const ContextPropousal = createContext(undefined);

export default function Propousal() {
  const { idProyect } = useParams();

  const hook = usePropousal(+idProyect);

  return (
    <div className={ui.container}>
      <ContextPropousal.Provider value={hook}>
        <BreadCrum path={hook.breadcrum.current} />

        <div className={ui.content}>
          <div className={ui.header}>
            <Header idProyect={+idProyect}>
              <div className={ui.overviewHeader}>
                <Solped />
                <Customer />
              </div>

              {hook.propousal === null ? null : (
                <ButtonV2
                  disabled={hook.statusPdf === "loading"}
                  onClick={hook.handlePdf}
                >
                  <span>PDF</span>
                  <Icon icon="pdf" />
                </ButtonV2>
              )}
            </Header>
          </div>

          <TablePropousal />
        </div>
      </ContextPropousal.Provider>
    </div>
  );
}
