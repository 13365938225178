export let ProvidersWithCxP = {
    idTable: "providerswithcxp",
    idPagination: "pagination-providerswithcxp",
    pages: 0,
    rows: [],
    actualPage: 0,
    headerConfig: [
      {
        text: "Número",
        columnNameDB: "id",
        idHeader: "idProvider",
        attributeToPrint: "id",
        sortable: false,
        sortThis: true,
      },
      {
        text: "Razón social",
        columnNameDB: "socialReason",
        idHeader: "socialReason",
        attributeToPrint: "socialReason",
        sortable: false,
      },
      {
        text: "Nombre comercial",
        columnNameDB: "commercialName",
        idHeader: "commercial-name-corporative",
        attributeToPrint: "comertialName",
        sortable: false,
      },
      {
        text: "Nombre corto",
        columnNameDB: "shortName",
        idHeader: "shortName",
        attributeToPrint: "shortName",
        sortable: false,
      },
      {
        text: "Teléfono",
        columnNameDB: "Telefono",
        idHeader: "Telefono",
        attributeToPrint: "Telefono",
        sortable: false,
      },
      {
        text: "Móvil",
        columnNameDB: "Movil",
        idHeader: "Movil",
        attributeToPrint: "Movil",
        sortable: false,
      }
    ],
    styleTable: "default",
    cbSelection: () => {},
    urlParams: "",
    idRows: "id",
    sort: {
      sqlSort: true,
      sortASC: false,
    },
    paginationFn: () => {},
    attributesResponse: {
      pages: "pages",
      actualPage: "actualPage",
      data: "data",
    },
  };
  