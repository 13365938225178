import MonthSelect from "components/general/Forms/Selects/Month";
import React, { useContext } from "react";
import { ContextCreditNoteAccounting } from "..";
import { lastDayOfMonth } from "date-fns";
import { getFirstDayOfMonth } from "helpers/dates";

export default function MonthFilter() {
  const hook = useContext(ContextCreditNoteAccounting);

  /**
   * Update the range
   * @param {number} month - Month
   */
  const handleMonthUpdate = (month) => {
    const dateToEvaluate = new Date(
      `${hook.beginDate.getUTCFullYear()}-${month}-3`
    );

    const lastDay = lastDayOfMonth(dateToEvaluate);
    const firstDay = getFirstDayOfMonth(dateToEvaluate);

    hook.updateBeginDate(firstDay);
    hook.updateEndDate(lastDay);
  };

  return (
    <MonthSelect
      value={hook.beginDate.getUTCMonth() + 1}
      onChange={handleMonthUpdate}
    />
  );
}
