import { msToMinutes } from "./dates";
import sa2 from "sweetalert2";
// const sa2 = require("sweetalert2");

/**
 * This alert will be shown when the backend respons with a success query
 * @param {function} cb Action to execute after click the confirm button, must be a
 * variable with the function (arrow function)
 * @param {string} message Text to display when this alert shows
 */

export const Warning = (message,text='', cb = () => {}) => {
  sa2
    .fire({
      icon: "warning",
      title: message,
      text:text
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
      return;
    });
};

export const WarningHtml = (
  message = {
    title: "",
    html: "",
  },
  cb = () => {}
) => {
  sa2
    .fire({
      icon: "warning",
      title: message.title,
      html: message.html,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
      return;
    });
};

/**
 * This alert will be shown when the backend respons with a success query
 * @param {function} cb Action to execute after click the confirm button, must be a
 * variable with the function (arrow function)
 * @param {string} message Text to display when this alert shows
 */
export const Success = (cb, message) => {
  sa2
    .fire({
      icon: "success",
      title: message,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
      return;
    });
};

/**
 *
 * @param {{
 * title:string,
 * html:string
 * }} message
 * @param {'warning'|'error'|'success'} icon - Icon to display on the alert
 * @param {()=>{}} cb - Callback when the "ok" button got pressed
 */
export const HtmlAlert = (
  message = {
    title: "",
    html: "",
  },
  icon = "warning",
  cb = () => {}
) => {
  sa2
    .fire({
      icon: icon,
      title: message.title,
      html: message.html,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
      return;
    });
};

/**
 * This alert will be show when the backend respons with an error
 * @param {function} cb Action to execute after click the confirm button, must be a
 * variable with the function (arrow function)
 * @param {string} message Text to display when this alert shows
 */
export const Error = (cb = () => {}, message) => {
  sa2
    .fire({
      icon: "error",
      title: message,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
    });
};
/**
 * This alert will be show when the backend respons with an error
 * @param {function} cb Action to execute after click the confirm button, must be a
 * variable with the function (arrow function)
 * @param {string} titel Text to display when this alert shows
 * @param {string} text Text to display when this alert shows
 * @param {string} html Text to display when this alert shows
 */
export const ErrorAlertText = (cb = () => {}, title, text, html) => {
  sa2
    .fire({
      icon: "error",
      title: title,
      text: text,
      allowEscapeKey: false,
      allowOutsideClick: false,
      html: html,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
    });
};

/**
 * Display a modal with the error
 * @param {import("axios").AxiosError} error
 * @returns {void}
 */
export const DisplayError = (error) =>
  Error(() => {}, error.response.data.message);

/**
 * Close the modal form after confirm the alert showed
 * @param {string} idBtn The html id attribute of the button that must be clicked
 * @param {string} [idForm] The form that must be reset after confirm the alert showed
 */
export const closeForm = (idBtn, idForm = "") => {
  document.getElementById(idBtn).click();

  if (idForm !== "") {
    document.getElementById(idForm).reset();
  }
};

/**
 * Alert to display after "x" minutes of inactivity
 * @param {function} cb - Expresive function to be execute after click the "OK" button
 * @param {function} cbCancel - Expressive function executed after canceling the inactivity action
 * @param {string} message - Message to display on alert
 * @param {string} title - Tittle of the warning
 * @param {number} counterLimit - Minutes until the modal executes the callback
 * @return {boolean} inactivityAction - If true, close the session
 */
export const Timer = (cb, cbCancel, message, title, counterLimit) => {
  sessionStorage.setItem("timeRemaining", counterLimit * 60_000);
  let timerInterval;
  sa2
    .fire({
      title: title,
      html: `${message}: <b>${counterLimit}:00</b>`,
      icon: "warning",
      timer: counterLimit * 60_000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Cerrar sesion",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      allowEscapeKey: false,
      didOpen: () => {
        timerInterval = setInterval(() => {
          const content = sa2.getHtmlContainer();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = msToMinutes(sa2.getTimerLeft());
            }
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
    .then((result) => {
      if (result.dismiss === sa2.DismissReason.timer) {
        cb();
        return true;
      }

      if (result.isConfirmed) {
        cb();
        return true;
      }

      if (!result.isConfirmed) {
        cbCancel();
        return false;
      }
    });
};

/**
 * Alert with 'Si' or 'No' options
 
 * @param {string} title - Text title
 * @param {string} text - Text to display on the modal
 * @param {function} cbConfirm - Callback if confirmed
 * @param {function} cbDeny - Callback if denied
 */
export const YesNoAlert = (
  text = "",
  cbConfirm = () => {},
  cbDeny = () => {}
) => {
  sa2
    .fire({
      text: text,
      confirmButtonText: "Si",
      denyButtonText: "No",
      showDenyButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: "question",
    })
    .then((result) => {
      if (result.isConfirmed) {
        cbConfirm();
      } else if (result.isDenied) {
        cbDeny();
      }
    });
};

export const MaintenancePrices = async (
  options = {
    1: "Precio unitario",
    2: "Costo unitario",
    3: "Precio/Costo unitario",
  },

  cb = {
    1: () => console.log("Actualizando precio unitario"),
    2: () => console.log("Actualizando costo unitario"),
    3: () => console.log("actualizando precio y costo unitario"),
  }
) => {
  const { value: query } = await sa2.fire({
    icon: "question",
    input: "select",
    title: "Selecciona lo que deseas actualiza",
    inputOptions: options,
    denyButtonText: "Ninguno",
    confirmButtonText: "Actualizar",
    showDenyButton: true,
  });

  if (query) {
    cb[query]();
  }
};
export const CancelDocumentAlert = (text) => {
  sa2.fire({
    icon: "error",
    title: "Error al cancelar",
    text: text,
  });
};
export const CancelDocumentErrorAlert = (text, cbConfirm = () => {}) => {
  sa2
    .fire({
      icon: "error",
      title: "Error al cancelar",
      text: text,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cbConfirm();
      }
      return;
    });
};
export const CancelDocumentSuccesAlert = (text, cbConfirm = () => {}) => {
  sa2
    .fire({
      icon: "success",
      title: "Documento cancelado/terminado",
      text: text,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cbConfirm();
      }
      return;
    });
};
export const notAllowSendDocument = (text) => {
  sa2.fire({
    icon: "error",
    title: "No se puede enviar un documento sin contacto",
    text: text,
  });
};

export const SuccessHtml = (
  message = {
    title: "",
    html: "",
  },
  cb = () => {}
) => {
  sa2
    .fire({
      icon: "success",
      title: message.title,
      html: message.html,
    })
    .then((result) => {
      if (result.isConfirmed) {
        cb();
      }
      return;
    });
};
