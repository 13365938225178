import Steps from "components/individual/Steps";
import React, { useContext } from "react";
import { ContextGestionOc } from "..";
import { indexedStatus } from "../useGestionOc";

export default function Status() {
  const hook = useContext(ContextGestionOc);

  return (
    <Steps
      onChange={hook.setStatus}
      currentStep={indexedStatus[hook.status]}
      steps={[
        "Ordenes de compra no enviadas",
        "Ordenes de compra no confirmadas",
        "Contrato fabricante no firmado",
        "Contrato institucional no firmado",
        "Licencia no confirmada",
        "Factura proveedor no recibidas",
        "Todas las ordenes de compra",
      ]}
    />
  );
}
