/**
 * Types in order to dispatch and update the state
 * @type {import("./types").ActionsI}
 */
export const Actions = {
  SET_IS_FETCHING_DATA: "SET_IS_FETCHING_DATA",
  SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
  SET_SOCIAL_REASON: "SET_SOCIAL_REASON",
  TRIGGER_SEARCH: "TRIGGER_SEARCH",
  SET_NO_DOCUMENT: "SET_NO_DOCUMENT",
  SET_DATES: "SET_DATES",
  SET_STATUS: "SET_STATUS",
  SET_ROW: "SET_ROW",
  SET_EXECUTIVE: "SET_EXECUTIVE",
  SET_UEN: "SET_UEN",
};
