import { PopUpDocumentsRelated } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";

/**
 * @type {import('types/typedef/customHooks/useDocPopUp').DocumentsPopUpI}
 */
const initialState = {
  isLoading: true,
  fe: null,
  fr:[],
  defaultOpenCustomer:false,
  defaultOpenProvider:false,

};

const dummyFunction = () => {};

export const ContextDocsRelatedValue = {
  popUpInfo: initialState,
  closeModal: dummyFunction,
  openModal: dummyFunction,
};

export default function usePopupDocsRelated(idDocument = null, fromLegal) {
  const [popUpInfo, setPopUpInfo] = useState(initialState);
  const [isOpen, setIsOpen] = useState(true);

  const openModal = () => setIsOpen(true);

  /**
   * Set the state to it's initial values in order to close the modal
   */
  const closeModal = () => {
    setPopUpInfo(initialState);
    setIsOpen(false);
  };

  useEffect(() => {
    (async function () {
      if (idDocument === null || !isOpen) return;

      const response = await PopUpDocumentsRelated(idDocument, fromLegal);

      const defaultOpenCustomer = ['1','2','6'].some(key=>response.data.hasOwnProperty(key)) || response.data.fe !==null;
      const defaultOpenProvider = response.data[3] !== undefined || response.data.fr.length > 0;

      setPopUpInfo({
        ...popUpInfo,
        ...response.data,
        isLoading: false,
        defaultOpenCustomer,
        defaultOpenProvider,
      });
    })();
  }, [isOpen, idDocument]);

  return {
    popUpInfo,
    closeModal,
    openModal,
  };
}
