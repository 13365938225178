import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faDollar,
  faPen,
  faCoins,
  faWallet,
  faLink,
  faFileInvoiceDollar,
  faEye,
  faToolbox,
  faCircleInfo,
  
} from "@fortawesome/free-solid-svg-icons";
import { faEdge , faChrome } from "@fortawesome/free-brands-svg-icons";

const dynamicIcons = {
  handHoldingDollar: faHandHoldingDollar,
  dollar: faDollar,
  pen: faPen,
  coins: faCoins,
  wallet: faWallet,
  fileInvoiceDollar: faFileInvoiceDollar,
  link:faLink,
  eye:faEye,
  toolbox:faToolbox,
  info:faCircleInfo,
  microsoftEdge:faEdge,
  googleChrome:faChrome
};

/**
 * Render an icon from font awesome
 * @param {import("./types").IconProps} props - Props
 * @returns {JSX.Element} Icon
 */
export default function Icon(props) {
  return <FontAwesomeIcon icon={dynamicIcons[props.nameIcon]} {...props} />;
}
