import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextReceiveCredtNotes } from "..";

export default function SearchCreditNoteReceived() {
  const hook = useContext(ContextReceiveCredtNotes);

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        placeholder="Folio, uuid, razon social"
        hideSearchIcon={true}
        onChange={(search) => hook.updateSearch(search)}
        onDelete={() => hook.updateSearch("")}
        onSearch={()=>hook.triggerSerch()}
      />
    </div>
  );
}
