import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import {
  Advertisement,
  FromInput,
  MessageInput,
  ToInput,
  SubmitAdvertisement,
  TypeSelect,
  FormAdvertisement,
} from "structure/Advertisement";
import Dialog from "structure/Dialog";
import style from "../Avisos.module.scss";

/**
 *
 * @param {import("./types").PropsCreateAdvertisement} props - Props
 * @returns {JSX.Element}
 */
export default function Create(props) {

  const { id = null , forwardRef = undefined , onCreated = () => {} } = props;

  const dialog = useDialog();

  const [isOpen, setIsOpen] = useState(false);

  const handleModal = (isOpen) => {
    setIsOpen(isOpen);
    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  };

  return (
    <Advertisement
      allowFetchAdv={isOpen}
      id={id}
      onSuccess={() => {
        onCreated();
        handleModal(false);
        console.log('cerrado')
      }}
    >
      <ButtonV2 forwardRef={forwardRef} onClick={() => handleModal(true)} {...props}>
        <span>{typeof id === "number" ? "Editar" : "Agregar"}</span>
        <Icon icon={typeof id === "number" ? "edit" : "plus"} />
      </ButtonV2>

      <Dialog
        footer={
          <div className={style.footerAbc}>
            <SubmitAdvertisement />
          </div>
        }
        forwardRef={dialog.refDialog}
        title={typeof id === 'number' ? 'Editar aviso' : 'Agregar aviso'}
        closeCallback={() => handleModal(false)}
      >
        {isOpen ? (
          <FormAdvertisement className={style.abcAdvertisement}>
            <FromInput />
            <ToInput />
            <TypeSelect />
            <MessageInput />
          </FormAdvertisement>
        ) : null}
      </Dialog>
    </Advertisement>
  );
}
