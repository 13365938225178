import { dateAtCeroHours, substractDays } from "helpers/dates";
import { ACTIONS } from "./Actions";
import { mnCurrency } from "helpers/money";
import { transformToNumberAndCurrency } from "../helpers/documentHelper";

/**
 * @type{import("../../../../../../types/documentActions").MoneyI}
 */
const initialImports = {
  number: 0,
  text: "$0.00",
};

const documentInitialDate = (documentType) => {
  const today = dateAtCeroHours(new Date());
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const lastDayOfTheMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );

  switch (documentType) {
    case 1:
      return {
        generateContract: true,
        beginMinDate: tomorrow,
        beginMaxDate: tomorrow,
        endMinDate: today,
        endMaxDate: lastDayOfTheMonth,
        reminderMinDate: tomorrow,
        reminderMaxDate: lastDayOfTheMonth,
        beginDate: tomorrow,
        endDate: lastDayOfTheMonth,
        reminderDate: tomorrow,
      };
    case 2:
      return {
        generateContract: true,
        beginMinDate: dateAtCeroHours(substractDays(new Date(), 0)),
        beginMaxDate: today,
        endMinDate: dateAtCeroHours(substractDays(new Date(), 0)),
        endMaxDate: "",
        reminderMinDate: today,
        reminderMaxDate: today,
        beginDate: today,
        endDate: today,
        reminderDate: today,
      };
    case 3:
      return {
        generateContract: true,
        beginMinDate: today,
        beginMaxDate: today,
        endMinDate: today,
        endMaxDate: "",
        reminderMinDate: today,
        reminderMaxDate: "",
        beginDate: today,
        endDate: today,
        reminderDate: today,
      };
    case 6:
      return {
        generateContract: true,
        beginMinDate: "",
        beginMaxDate: "",
        endMinDate: today,
        endMaxDate: "",
        reminderMinDate: tomorrow,
        reminderMaxDate: "",
        beginDate: today,
        endDate: today,
        reminderDate: tomorrow,
      };

    default:
      return {
        generateContract: false,
        beginMinDate: "",
        beginMaxDate: "",
        endMinDate: "",
        endMaxDate: "",
        reminderMinDate: "",
        reminderMaxDate: "",
        beginDate: "",
        endDate: "",
        reminderDate: "",
      };
  }
};

export const loadDocumentInitialState = (documentType) => {
  const initialDates = documentInitialDate(documentType);
  return {
    recalculate:false,
    id: null,
    isLoading: false,
    interfaceControl: {
      disableTc: false,
      disableCurrency: false,
      disableTotals: false,
    },
    contractKey: "",
    contact: {
      id: 0,
      anniversary: "",
      email: "",
      isCollectionContact: false,
      isPaymentContact: false,
      lastName1: "",
      lastName2: "",
      middleName: "",
      name: "",
      workTitle: "",
    },
    comments: [],
    currentComment: {},
    customer: {
      comertialName: "",
      id: 0,
      rfc: "",
      shortName: "",
      socialRazon: "Razon social del clienteV2",
      isMexican: true,
    },
    documentType: 0,
    generateContract: {
      ...initialDates,
    },
    items: [],
    newItems: [],
    itemsToEdit: [],
    currentItem: {
      quantity: 0,
      isBy: "none",
      providerDiscount: {
        number: 0,
        text: "0%",
      },
      clientDiscoount: {
        number: 0,
        text: "0%",
      },
    },
    moneyInfo: {
      currency: {
        id: 0,
        value: "",
      },
      mxn: {
        importCost: initialImports,
        importSell: initialImports,
        ivaCost: initialImports,
        ivaSell: initialImports,
        margin: initialImports,
        totalCost: initialImports,
        totalSell: initialImports,
      },
      usd: {
        importCost: initialImports,
        importSell: initialImports,
        ivaCost: initialImports,
        ivaSell: initialImports,
        margin: initialImports,
        totalCost: initialImports,
        totalSell: initialImports,
      },
      probability: {
        id: 1,
        value: "<50%",
      },
      tc: initialImports,
    },
    modal: {
      openModal: false,
      isEditing: false,
    },
    documentConcepts: {
      concepts: [],
      currentConcept: {
        id: 0,
        description: "",
        defaultToDocument: false,
      },
    },
  };
};

/**
 * @type{import("../../../../../../types/documentActions").GeneralDocumentI}
 */
export const documentInitialState = {
  id: null,
  isLoading: false,
  interfaceControl: {
    disableTc: false,
    disableCurrency: false,
    disableTotals: false,
  },
  contractKey: "",
  contact: {
    id: 0,
    anniversary: "",
    // cell:{
    //   extention:'',
    //   numberPhone:''
    // },
    email: "",
    isCollectionContact: false,
    isPaymentContact: false,
    lastName1: "",
    lastName2: "",
    middleName: "",
    name: "",
    // phone:{
    //   extention:'',
    //   numberPhone:''
    // }
    workTitle: "",
  },
  comments: [],
  currentComment: {},
  customer: {
    comertialName: "",
    id: 0,
    rfc: "",
    shortName: "",
    socialRazon: "Razon social del clienteV2",
    isMexican: true,
  },
  documentType: 0,
  generateContract: {
    ...documentInitialDate(),
  },
  items: [],
  newItems: [],
  itemsToEdit: [],
  currentItem: {
    quantity: 0,
    isBy: "none",
    providerDiscount: {
      number: 0,
      text: "0%",
    },
    clientDiscoount: {
      number: 0,
      text: "0%",
    },
  },
  moneyInfo: {
    currency: {
      id: 0,
      value: "",
    },
    mxn: {
      importCost: initialImports,
      importSell: initialImports,
      ivaCost: initialImports,
      ivaSell: initialImports,
      margin: initialImports,
      totalCost: initialImports,
      totalSell: initialImports,
    },
    usd: {
      importCost: initialImports,
      importSell: initialImports,
      ivaCost: initialImports,
      ivaSell: initialImports,
      margin: initialImports,
      totalCost: initialImports,
      totalSell: initialImports,
    },
    probability: {
      id: 1,
      value: "<50%",
    },
    tc: initialImports,
  },
  modal: {
    openModal: false,
    isEditing: false,
  },
  documentConcepts: {
    concepts: [],
    currentConcept: {
      id: 0,
      description: "",
      defaultToDocument: false,
    },
  },
};

/**
 *
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} state
 * @param {import("../../../../../../types/documentActions").ActionT} action
 * @returns {import("../../../../../../types/documentActions").GeneralDocumentI}
 */
export const documentReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INIT:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case ACTIONS.SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case ACTIONS.SET_GENERATE_CONTRACT:
      return {
        ...state,
        generateContract: action.payload,
      };
    case ACTIONS.SET_ITEMS:
      return {
        ...state,
        recalculate:!state.recalculate,
        items: action.payload,
      };
    case ACTIONS.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };
    case ACTIONS.SET_NEW_ITEM:
      return {
        ...state,
        recalculate:!state.recalculate,
        newItems: action.payload,
      };
    case ACTIONS.SET_MONEY_INFORMATION:
      return {
        ...state,
        // recalculate:!state.recalculate,
        moneyInfo: action.payload,
      };
    case ACTIONS.SET_CURRENCY_MONEY_DOCUMENT:
      return {
        ...state,
        // recalculate:!state.recalculate,
        moneyInfo: {
          ...state.moneyInfo,
          currency: action.payload,
        },
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case ACTIONS.SET_EDIT_ITEM:
      // const index= state.items.findIndex(item=>item.id!== action.payload.id)
      const newArray = [...state.items];

      newArray[+action.payload.index] = action.payload;

      return {
        ...state,
        recalculate:!state.recalculate,
        items: newArray,
      };
    case ACTIONS.SET_EDIT_NEW_ITEM:
      // const index= state.items.findIndex(item=>item.id!== action.payload.id)
      const newItemsArray = [...state.newItems];
      if (state.currentItem?.isNewItem) {
        console.log("El item a editar es nuevo");
        /**
         * @type{{item:import("../../../../../../types/documentActions").ItemSelectI,index:number}}
         */
        const [elementToEdit] = newItemsArray.map((item, index) => {
          if (item.uuid === action.payload?.uuid) {
            return {
              item: {
                ...item,
                ...state.currentItem,
              },
              index: index,
            };
          } else {
            return null;
          }
        });
        if (!!elementToEdit) {
          console.log("El elemento no es nulo");
          newItemsArray[elementToEdit.index] = elementToEdit.item;
          console.log(newItemsArray);
          return {
            ...state,
            newItems: newItemsArray,
          };
        } else {
          return {
            ...state,
            newItems: newItemsArray,
          };
        }
      } else {
        console.log("El item no es nuevo");
        return {
          ...state,
          newItems: newItemsArray,
        };
      }

    case ACTIONS.SET_EDIT_COMMENT:
      // const index= state.items.findIndex(item=>item.id!== action.payload.id)
      const newCommentArray = [...state.comments];
      newCommentArray[action.payload?.index] = action.payload;
      return {
        ...state,
        comments: newCommentArray,
      };
    case ACTIONS.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case ACTIONS.SET_CURRENT_COMMENT:
      return {
        ...state,
        currentComment: action.payload,
      };
    case ACTIONS.SET_DELETE_ITEM:
      const filterItems = state.items.filter(
        (item,index) => index !== action.payload
      );
      return {
        ...state,
        items: filterItems,
      };
    case ACTIONS.SET_DELETE_CATALOGUE_CHANGES:
      const filterCatalogue = state.itemsToEdit.filter(
        (item) => item.uuid !== action.payload
      );
      return {
        ...state,
        itemsToEdit: filterCatalogue,
      };
    case ACTIONS.SET_DELETE_COMMENT:
      const filterComments = state.comments.filter(
        (_,i) => i !== action.payload
      );
      return {
        ...state,
        comments: filterComments,
      };
    case ACTIONS.SET_RECALCULATE_ITEMS:

    const INDEXED_VALUES_CURRENCY = {
      ["MXN"]: {
        id: 1,
        value: "MXN",
      },
      ["USD"]: {
        id: 2,
        value: "USD",
      },
    };
    
    const currencyFirstItem = action.payload[0]?.currency?.code||'MXN';


    const currencyToUse = action.payload.length <1 ? INDEXED_VALUES_CURRENCY[currencyFirstItem] : state.moneyInfo.currency;

    const initialTotals = {
      importCost: 0,
      importSell: 0,
      ivaCost: 0,
      ivaSell: 0,
      margin: 0,
      totalCost: 0,
      totalSell: 0,
    };

    const totals = action.payload.reduce(
      (totals, item,i) => {
        return {
          usd: {
            importCost: totals.usd.importCost + item.usd.cu.total.number,
            importSell: totals.usd.importSell + item.usd.pu.total.number,
            ivaCost: totals.usd.ivaCost + item.usd.cu.totalIva.number,
            ivaSell: totals.usd.ivaSell + item.usd.pu.totalIva.number,
            margin: totals.usd.margin + (item.usd.pu.realUnit.number - item.usd.cu.realUnit.number),
            totalCost:
              totals.usd.totalCost +
              (item.usd.cu.total.number +
              item.usd.cu.totalIva.number),
            totalSell:
              totals.usd.totalSell +
              (item.usd.pu.total.number +
              item.usd.pu.totalIva.number),
          },
          mxn: {
            importCost: totals.mxn.importCost + item.mxn.cu.total.number,
            importSell: totals.mxn.importSell + item.mxn.pu.total.number,
            ivaCost: totals.mxn.ivaCost + item.mxn.cu.totalIva.number,
            ivaSell: totals.mxn.ivaSell + item.mxn.pu.totalIva.number,
            margin: totals.mxn.margin + (item.mxn.pu.realUnit.number - item.mxn.cu.realUnit.number),
            totalCost:
              totals.mxn.totalCost +
              (item.mxn.cu.total.number +
              item.mxn.cu.totalIva.number),
            totalSell:
              totals.mxn.totalSell +
              (item.mxn.pu.total.number +
              item.mxn.pu.totalIva.number),
          },
        };
      },
      {
        usd: initialTotals,
        mxn: initialTotals,
      }
    );
    
      return {
        ...state,
        items:action.payload,
        moneyInfo:{
          ...state.moneyInfo,
        currency:currencyToUse,
          usd: {
            importCost: transformToNumberAndCurrency(
              +totals.usd.importCost.toFixed(2)
            ),
            importSell: transformToNumberAndCurrency(
              +totals.usd.importSell.toFixed(2)
            ),
            ivaCost: transformToNumberAndCurrency(+totals.usd.ivaCost.toFixed(2)),
            ivaSell: transformToNumberAndCurrency(+totals.usd.ivaSell.toFixed(2)),
            margin: transformToNumberAndCurrency(+totals.usd.margin.toFixed(2)),
            totalCost: transformToNumberAndCurrency(
              +totals.usd.totalCost.toFixed(2)
            ),
            totalSell: transformToNumberAndCurrency(
              +totals.usd.totalSell.toFixed(2)
            ),
          },
          mxn: {
            importCost: transformToNumberAndCurrency(
              +totals.mxn.importCost.toFixed(2)
            ),
            importSell: transformToNumberAndCurrency(
              +totals.mxn.importSell.toFixed(2)
            ),
            ivaCost: transformToNumberAndCurrency(+totals.mxn.ivaCost.toFixed(2)),
            ivaSell: transformToNumberAndCurrency(+totals.mxn.ivaSell.toFixed(2)),
            margin: transformToNumberAndCurrency(+totals.mxn.margin.toFixed(2)),
            totalCost: transformToNumberAndCurrency(
              +totals.mxn.totalCost.toFixed(2)
            ),
            totalSell: transformToNumberAndCurrency(
              +totals.mxn.totalSell.toFixed(2)
            ),
          },        }
      }

      // const recalculatedArray = action.payload;
      // const recalculatedItems = state.items.map((item, index) => {
      //   if (item.uuid === recalculatedArray[index].uuid) {
      //     return { ...item, ...recalculatedArray[index] };
      //   } else {
      //     return item;
      //   }
      // });
      // return {
      //   ...state,
      //   items: recalculatedItems,
      // };

    case ACTIONS.SET_EDIT_CATALOGUE:
      return {
        ...state,
        itemsToEdit: action.payload,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTIONS.SET_CONTRACT_KEY:
      return {
        ...state,
        contractKey: action.payload,
      };
    case ACTIONS.SET_LOAD_DOCUMENT_CONCEPTS:
      return {
        ...state,
        documentConcepts: {
          ...state.documentConcepts,
          concepts: action.payload,
        },
      };
    case ACTIONS.SET_CURRENT_CONCEPT:
      return {
        ...state,
        documentConcepts: {
          ...state.documentConcepts,
          currentConcept: action.payload,
        },
      };
    case ACTIONS.SET_INTERFACE:
      return {
        ...state,
        interfaceControl: action.payload,
      };

    case ACTIONS.SET_TC:
      return {
        ...state,
        moneyInfo:{
          ...state.moneyInfo,
          tc:{
            number:action.payload,
            text:mnCurrency(action.payload)
          }
        }
      }

    default:
      return state;
  }
};
