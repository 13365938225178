import React, { useState } from "react";
import ModalV2 from "components/individual/Modals/ModalV2";
import { FormContact } from "pages/Directory/Contactos/FormContact";
import { ContainerFormContact } from "pages/Directory/Contactos/FormContactStyles";
import "types/typedef/customHooks/useContac";
import { SubmitTest } from "components/individual/buttons/Buttons";
import { Warning } from "helpers/alerts";
import { v4 as uuid } from "uuid";
import { Label } from "structure/Document";
import { DateInput } from "../../Dates";
import { dateToDbFormat } from "helpers/dates";
import { addDays } from "date-fns";

const INITIAL_STATE = {
  birthDay: new Date(),
  isPayment: false,
  isCollection: false,
};

/**
 * Modal of contact
 * @param {object} props - Props
 * @param {string} props.fullName - Name the user typed on the creatable select contact
 * @param {(object:NewContactInformationI)=>void} props.onSubmit - Information when the user clics on "Agregar"
 * @param {string} props.message - Message to show when user adds the contact
 * @returns {JSX.Element}
 */
export default function CreatableContact({
  fullName = "???",
  onSubmit = () => {},
  message = "El contacto sera creado si el documento es ganado",
}) {
  // const nodeBtnModal = "createContactBtn";
  const nodeBtnModal = "createContactBtn";

  const [state, setState] = useState(INITIAL_STATE);

  const updateBirthDay = (birthDay) => setState({ ...state, birthDay });
  const updatePayment = (isPayment) => setState({ ...state, isPayment });
  const updateCollection = (isCollection) =>
    setState({ ...state, isCollection });
  /**
   * Return the information of the contact and for what document will be used
   * @param {NewContactInformationI} contact - Information of the contact to create
   */

  const saveOnMemory = (contact) => {
    onSubmit({
      id: uuid(),
      ...contact,
      isActive: true,
      birthDay: state.birthDay !== null ? dateToDbFormat(state.birthDay) : null,
      isForPayment: state.isPayment,
      isForColletion: state.isCollection,
    });
    document.getElementById("closeModal").click();
    Warning(message);
    setState(INITIAL_STATE);
  };

  return (
    <div className="contact">
      <ModalV2
        title="Agregar informacion contacto"
        close="Cancelar"
        idOpenModal={nodeBtnModal}
        idCloseModal="closeModal"
        // showCancelButton={false}
        footer={
          <div className="form-group">
            <SubmitTest formID="newContact" text="Agregar contacto" />
          </div>
        }
      >
        <ContainerFormContact>
          <FormContact
            idForm="newContact"
            className="mb-5"
            onSubmit={saveOnMemory}
          >
            <FormContact.Name defValue={fullName} />
            <FormContact.MiddleName />
            <FormContact.Father />
            <FormContact.Mother />
            <FormContact.PhoneNumber />
            <FormContact.Cellphone />
            <FormContact.Email />
            <FormContact.Position />

            <div className="d-flex justify-content-between align-items-center">
              <FormContact.ContactPayments
                isChecked={state.isPayment}
                onChange={updatePayment}
              />
              <FormContact.CollectionContact
                isChecked={state.isCollection}
                onChange={updateCollection}
              />
            </div>

            <div>
              <Label>Aniversario</Label>
              <DateInput
                className="rightSideInput"
                value={state.birthDay}
                onChange={updateBirthDay}
                clearIcon={null}
                maxDate={new Date()}
              />
            </div>
          </FormContact>
        </ContainerFormContact>
      </ModalV2>
    </div>
  );
}
