import React, { useContext } from "react";
import { ContextResidueBankAccouts } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import style from "../Table/styles.module.scss";

export default function Excel() {
  const hook = useContext(ContextResidueBankAccouts);

  if (hook.isDownloadingExcel)
    return (
      <div className={style.marginModule}>
        <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <div className={style.marginModule}>
      <ButtonV2 onClick={hook.attemptExcel}>
        <span>Excel</span>
        <Icon icon="excel" />
      </ButtonV2>
    </div>
  );
}
