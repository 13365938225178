import LoginContext from "context/Login/LoginContext";
import { Success } from "helpers/alerts";
import { closeSession } from "helpers/apis";
import { UpdateProfile } from "helpers/Apis/usuariosApi";
import { useContext, useEffect, useRef, useState } from "react";

export default function useProfile() {
  const { profilePicture, updateProfilePicture } = useContext(LoginContext);

  const [state, setState] = useState({
    profilePicture,
    personalInformation: {
      isValid: true,
    },
    isPerformingQuery: false,
  });

  const closeModalId = useRef(window.crypto.randomUUID());

  useEffect(() => {
    setState({
      ...state,
      profilePicture,
    });
  }, [profilePicture]);

  /**
   * Update the personal information of the user
   * @param {import("structure/Profile/types").onChangePersonalInformation} personalInformation - Info
   * @returns {void}
   */
  const updatePersonalInformation = (personalInformation) =>
    setState({
      ...state,
      personalInformation,
    });

  const triggerUpdateProfile = async () => {
    setState({
      ...state,
      isPerformingQuery: true,
    });

    const dto = {
      ...state.personalInformation,
      email: state.personalInformation.email.value,
      profilePicture: {
        scale: state.profilePicture.scale,
        position: state.profilePicture.position,
      },
    };

    const { message, wasUpdated } = await UpdateProfile(
      dto,
      state.profilePicture.img
    );

    setState({
      ...state,
      isPerformingQuery: false,
    });

    if (wasUpdated) {
      Success(async () => {
        await closeSession();
      }, message);
    }
  };

  return {
    state,
    closeModalId,
    updateProfilePicture,
    updatePersonalInformation,
    setState,
    triggerUpdateProfile,
  };
}
