import CreatableContact from "components/general/Forms/Selects/CreatableContact";
import React, { useState } from "react";
import Form from "../Form/Form";
import { DocInfo, DocReleated, Imports, SAT } from "../Form/Styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { schemaValidation } from "Schemas/Directory/EditDocument/ocSchema";
import { updateContract, updateOc } from "helpers/Apis/Documents";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Success } from "helpers/alerts";
import { useParams, useHistory } from "react-router-dom";
import { SET_DOC, SET_IS_UPDATING, SET_CONTACT_INVOICE } from "../reducer";

export default function OC({ state, dispatch }) {
  const [isUpdating, setIsUpdating] = useState(false);

  const history = useHistory();

  const [contactsCreated, setContactsCreated] = useState([]);
  const { customerId } = useParams();

  const update = async (info) => {
    // setIsUpdating(true);
    dispatch({
      type: SET_IS_UPDATING,
      payload: true,
    });

    // console.log(info.document);
    const wasUpdated = await updateOc(
      state.document,
      state.comments,
      state.executive
    );
    // setIsUpdating(false);

    dispatch({
      type: SET_IS_UPDATING,
      payload: false,
    });

    if (wasUpdated) {
      Success(() => history.goBack(), "OC actualizada");
    }
  };

  /**
   * Add the contact to the list
   * @param {NewContactInformationI} contact - Info contact created
   */
  const createContact = (contact) =>
    setContactsCreated([
      {
        contactID: contact.id,
        customerID: +customerId,
        firstName: contact.nombre,
        middleName: contact.middleName,
        lastName1: contact.apellidoP,
        lastName2: contact.apellidoM,
        fullName: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        status: 1,
        label: `${contact.nombre} ${contact.middleName} ${contact.apellidoP} ${contact.apellidoM}`,
        email: contact.email,
        isActive: contact.isActive,
        lada_cellphone: contact.lada_cellphone,
        lada_phone: contact.lada_phone,
        number_cellphone: contact.number_cellphone,
        number_phone: contact.number_phone,
        puesto: contact.puesto,
      },
      ...contactsCreated,
    ]);
  return (
    <>
      <CreatableContact
        fullName={null}
        onSubmit={createContact}
        message="El contacto sera creado cuando se actualize el contrato"
      />
      <Form
        onSubmit={update}
        onLoaded={(document) =>
          dispatch({
            type: SET_DOC,
            payload: document,
          })
        }
        schema={schemaValidation}
      >
        {({ document }) => (
          <>
            {/* <div className="d-flex justify-content-end my-4">
              {isUpdating ? (
                <Spinner hidden={false} text="Actualizando Contrato..." />
              ) : (
                <ButtonV2 form="updateDocument">Actualizar</ButtonV2>
              )}
            </div> */}

            <DocInfo>
              <Form.Id />
              <Form.Type />
              <Form.No />
              <Form.Status />
              <Form.Executive />
              <Form.Client />
              <Form.Contact
                value={document.contact.id}
                onLoaded={(options) => setContactsCreated(options)}
                options={contactsCreated}
                onChange={(value) =>
                  dispatch({
                    type: SET_CONTACT_INVOICE,
                    payload: value,
                  })
                }
              />
            </DocInfo>

            <hr />
            <h3 className="my-2">Documentos relacionados</h3>
            <DocReleated>
              <Form.Origin />
              <Form.Quote />
              <Form.PreInvoice />
              <Form.Invoice />
              <Form.Contrato />
              <Form.OC />
              <Form.InvoiceProvider />

              <Form.NoContrato />
              <Form.CreationDate />
              <Form.ModifyDate />
              <Form.ExpirationDate />
              <Form.ReminderDate />
              {/* <Form.EditDates /> */}
              <Form.PercentProgress />
              <Form.PercentProb />
            </DocReleated>

            <hr />
            <h3 className="my-2">Importes</h3>
            <Imports>
              <Form.Currency />
              <Form.TCP />
              <Form.Import />
              <Form.IVA />
              <Form.Total />
              <Form.Acredited />
              <Form.Recidue />
            </Imports>

            <SAT>
              <Form.CFDI value="NA" />
              <Form.PaymentMethod value="NA" />
              <Form.PaymentType value="NA" />
              <Form.Partialities />
              <Form.Partiality />
              {/* <Form.CreditDays /> */}
              <Form.EditCreditDays />
              <Form.Complement />
            </SAT>
          </>
        )}
      </Form>
    </>
  );
}
