import React, { useContext, useRef } from "react";
import DocumentContext from "../../ContextDocument";
import { parseToFolio } from "helpers/documents";
import ui from "./styles.module.scss";

export default function DocNumber() {
  const hook = useContext(DocumentContext);

  const INDEXED_LABELS = useRef({
    1: "Cotización",
    2: "Pedido",
    3: "Orden de compra",
    6: "Contrato",
  });

  return (
    <div className={ui.containerDocNumber}>
      <p>No. {INDEXED_LABELS.current[hook.documentType]||' documento'}</p>
      <p>{parseToFolio(hook.folio)}</p>
    </div>
  );
}
