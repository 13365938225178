import { Spinner } from "components/individual/Spinner/Spinner";
import { getCxpOverview } from "helpers/Apis/Banks";

import React, { useEffect, useState } from "react";
import {
  Associated,
  Egress,
  EgressHeader,
  Remain,
  Residue,
  ResidueTitle,
  Import,
  RowHeader,
  Invoice,
  Row,
  Amounts,
} from "../Movements/EgressOverview/styles";

export default function CxpOverview({ idMovement = null, movementCurrency }) {
  /**
   * State of the component
   * @type {[import("types/typedef/customHooks/useCxpOverview").StateI,()=>void]}
   */
  const [state, setState] = useState({
    isLoading: true,
    cxps: [],
  });

  useEffect(() => {
    (async function () {
      if (idMovement === null) return;

      const apiCxps = await getCxpOverview(idMovement);

      const filteredCxps = apiCxps.filter(cxc=>cxc.status.isActive===true);
      
      setState({
        ...state,
        cxps: filteredCxps,
        isLoading: false,
      });
    })();
  }, [idMovement]);

  if (state.isLoading)
    return <Spinner hidden={false} text="Cargando CxP(s)..." />;

  if (state.cxps.length <= 0)
    return <h1 className="text-center py-5">No hay asociaciones</h1>;

  return (
    <>
      <RowHeader>
        <p>Proveedor</p>
        <p>Factura</p>
        <p>Parcialidad</p>
        {/* <p>Moneda</p> */}
        <p>Total</p>
        <Residue>
          {/* <ResidueTitle>Saldo</ResidueTitle> */}
          <Associated>Asociado</Associated>
          {/* <Remain>Por asociar</Remain> */}
        </Residue>
        <EgressHeader>
          <Import>Importe egreso</Import>
          {/* <Invoice>A la factura</Invoice> */}
          {/* <Egress>Al egreso</Egress> */}
        </EgressHeader>
      </RowHeader>

      {state.cxps.map((cxp) =>
        cxp.status.isActive ? (
          <Row>
            <p>{cxp.invoice.socialReason}</p>
            <p className="d-flex justify-content-center">{cxp.invoice.folio}</p>
            <p className="d-flex justify-content-center">
              {cxp.cxp.partialitie.ui}
            </p>
            {/* <p className="d-flex justify-content-center">
        {cxp.invoice.currency}
      </p> */}

            <p className="d-flex justify-content-end">
              {cxp.invoice.total.text} {cxp.invoice.currency}
            </p>
            <Amounts>
              <p>
                {cxp.applied.text} {cxp.invoice.currency}
              </p>

              {/* <p>{cxp.invoice.residue.text}</p> */}
            </Amounts>
            <Amounts>
              {/* <p>{cxp.applied.text}</p> */}
              <p>
                {cxp.paid.text} {movementCurrency}
              </p>
            </Amounts>
          </Row>
        ) : null
      )}
    </>
  );
}
