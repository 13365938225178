import React, { useContext } from "react";
import Add from "pages/Administration/InvoiceReception/Add";
import {
  ContainerXMLInfo,
  EmittedDate,
  Currency,
  CreditDays,
  Emitter,
  ExpirationDate,
  Folio,
  Importe,
  Receptor,
  SocialReason,
  Total,
  UUID,
  Discount,
  LblImporte,
} from "pages/Administration/InvoiceReception/Filter/styles";
import { ContextReceiveCreditNote } from "..";

export default function Step1() {
  const hook = useContext(ContextReceiveCreditNote);

  // updateXmlInfo

  return (
    <Add
      type="E"
      onChange={(info) => hook.updateXmlInfo(info)}
      onChangePdf={(pdf) => console.log(pdf)}
    >
      {!hook.canAddXml && hook.errorMessage.length > 0 ? (
        <h1 className="text-center m-2 text-danger font-weight-bold">{hook.errorMessage}</h1>
      ) : (
        <ContainerXMLInfo>
          <EmittedDate>
            <Add.LabelEmitedDate />
          </EmittedDate>

          <SocialReason>
            <Add.LabelSocialReason />
          </SocialReason>

          <Folio>
            <Add.LabelFolio />
          </Folio>

          <UUID>
            <Add.UUID />
          </UUID>

          <Emitter>
            <Add.LabelRFCEmitter />
          </Emitter>

          <Add.LabelsAmounts />

          <Add.LabelImpuestos />

          <Receptor>
            <Add.LabelRFCReceptor />
          </Receptor>

          <Currency>
            <Add.LabelCurrency />
          </Currency>

          <Importe>
            <Add.LabelImporte />
          </Importe>

          <Discount>
            <Add.LabelDiscount />
          </Discount>

          <Total>
            <Add.LabelTotal />
          </Total>
        </ContainerXMLInfo>
      )}
    </Add>
  );
}
