import React , { useContext,
  useRef } from "react";
import style from "../../styles.module.scss";
import { ContextCashFlow } from "../..";
import { mnCurrency } from "helpers/money";
import { parseDateToText } from "helpers/dates";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export default function TableFlow() {
  const key = useRef(window.crypto.randomUUID());

  const hook = useContext(ContextCashFlow);

  return (
    <>
      <div className={style.tableCashFlow}>
        <div className={style.headerTableCashFlowV1}>
          <p>No. cuenta</p>
          <p>Moneda</p>
          <p>Descripcion</p>
          <p>Saldo al día {parseDateToText(new Date(), "medium")}</p>
        </div>

        {hook.cashFlow.displayDetails
          ? hook.cashFlow.cashFlow.bank.bankAccounts.map((bank, i) => (
              <div
                className={style.rowTableCashFlowV1}
                key={`${key.current}-${i}`}
              >
                <p onClick={() => hook.setBankAccount(bank.id)}>
                  {bank.account}
                </p>
                <p>{bank.currency}</p>
                {/* <p>ND - {bank.currency}</p> */}
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={bank.description}
                />
                <p>{mnCurrency(bank.todayCxc)}</p>
                <p>{mnCurrency(bank.todayCxc)}</p>
                <p>{mnCurrency(bank.todayCxc)}</p>
                <p>{mnCurrency(bank.todayCxc)}</p>
                <p>{mnCurrency(bank.todayCxc)}</p>
                <p>{mnCurrency(bank.todayCxc)}</p>
              </div>
            ))
          : null}

        <div className={style.totalCashFlow}>
          <p>Total bancos</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
          <p>{mnCurrency(hook.cashFlow.cashFlow.bank.total)}</p>
        </div>
      </div>
    </>
  );
}
