import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import useReceiveCreditNote, {
  INITIAL_STATE_CONTEXT_RECEIVE_CREDIT_NOTE,
} from "./useReceiveCreditNote";
import Dialog from "structure/Dialog";
import Step1 from "./Step1";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import { createContext } from "react";
import Step1Footer from "./Step1Footer";
import Step2 from "./Step2";
import Step2Footer from "./Step2Footer";
import Icon from "components/individual/HeaderIcon";

export const ContextReceiveCreditNote = createContext(
  INITIAL_STATE_CONTEXT_RECEIVE_CREDIT_NOTE
);

const BODY_MODAL = {
  0: <Step1 />,
  1: <Step2 />,
};

const FOOTER = {
  0: <Step1Footer />,
  1: <Step2Footer />,
};

/**
 * Handle the receive of credit note
 * @param {import("./useReceiveCreditNote/types").PropsUseReceiveCreditNote} props - Props
 * @returns {JSX.Element}
 */
export default function ReceiveCreditNote({ onReceived = () => {} }) {
  const hook = useReceiveCreditNote({
    onReceived,
  }); 

  return (
    <ContextReceiveCreditNote.Provider value={hook}>
      <ButtonV2 onClick={hook.customOpen}>
        <span>Recibir</span>
        <Icon icon="plus" />
      </ButtonV2>

      <Dialog
        width={hook.step === 1 ? 80 : 50}
        title="Recepcion de nota de crédito"
        forwardRef={hook.dialog.refDialog}
        footer={FOOTER[hook.step]}
        closeCallback={hook.customClose}
      >
        {hook.isOpen ? (
          <div>
            <Steps className="my-4" current={hook.step} onChange={hook.setStep}>
              <Steps.Step title="Cargar nota de crédito" />
              <Steps.Step title="Confirmar" />
            </Steps>
            {BODY_MODAL[hook.step]}
          </div>
        ) : null}
      </Dialog>
    </ContextReceiveCreditNote.Provider>
  );
}
