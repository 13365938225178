import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import { useContext } from "react";
import { ContextUenAdmin } from "..";
import ButtonAdd from "./ButtonAdd";
import {
  ModalClaveProd,
  ModalUm,
  UenV3,
} from "components/general/Forms/Selects/UenV3";
import style from "./styles.module.scss";
import {
  IvasCatalogue,
  Options,
  Excento,
} from "components/general/Forms/Selects/IvasCatalogue";
import Icon from "components/individual/HeaderIcon";

export default function ModalAdd() {
  const hook = useContext(ContextUenAdmin);

  return (
    <>
      <ButtonV2 onClick={hook.customOpenAdd}>
        <span>Agregar</span>
        <Icon icon="plus" />
      </ButtonV2>

      <Dialog
        forwardRef={hook.dialogAdd.refDialog}
        title="Agregar UEN"
        width={35}
        footer={<ButtonAdd />}
        closeCallback={hook.dialogAdd.close}
      >
        <form
          noValidate={true}
          className={style.containerForm}
          onSubmit={hook.formAdd.handleSubmit(
            hook.attemptAddUen,
            hook.printError
          )}
          id={hook.idFormAdd}
        >
          <div>
            <Label
              required={Object.keys(hook.formAdd.formState.errors).includes(
                "description"
              )}
            >
              Descripción
            </Label>
            <input
              ref={hook.formAdd.register}
              type="text"
              name="description"
              placeholder="Escribe aquí"
            />
            <p className="text-danger font-weight-bold">
              {hook.formAdd.errors.description?.message}
            </p>
          </div>

          <div>
            <Label
              required={Object.keys(hook.formAdd.formState.errors).includes(
                "family"
              )}
            >
              Familia
            </Label>
            <input
              ref={hook.formAdd.register}
              type="text"
              placeholder="Escribe aquí"
              name="family"
            />
            <p className="text-danger font-weight-bold">
              {hook.formAdd.errors.family?.message}
            </p>
          </div>

          <div>
            <Label
              required={Object.keys(hook.formAdd.formState.errors).includes(
                "subFamily"
              )}
            >
              Sub-Familia
            </Label>
            <input
              ref={hook.formAdd.register}
              type="text"
              placeholder="Escribe aquí"
              name="subFamily"
            />
            <p className="text-danger font-weight-bold">
              {hook.formAdd.errors.subFamily?.message}
            </p>
          </div>

          <div>
            <Label
              required={Object.keys(hook.formAdd.formState.errors).includes(
                "marginRate"
              )}
            >
              Margen
            </Label>
            <input
              ref={hook.formAdd.register}
              type="number"
              placeholder="Escribe aquí"
              name="marginRate"
            />
            <p className="text-danger font-weight-bold">
              {hook.formAdd.errors.marginRate?.message}
            </p>
          </div>

          <IvasCatalogue
            onChange={(data) => hook.updateIva(data.iva, data.excento)}
          >
            {() => (
              <>
                <div>
                  <Label>IVA</Label>
                  <Options />
                </div>

                {hook.iva === 0 ? (
                  <div>
                    <Label>¿Es excento?</Label>
                    <Excento />
                  </div>
                ) : null}
              </>
            )}
          </IvasCatalogue>

          <UenV3 onChange={hook.updateUen} promptUpdate={false}>
            {() => (
              <>
                <div>
                  <Label required={hook.SATcode === ""}>Código SAT</Label>
                  <ModalClaveProd />
                </div>

                <div>
                  <Label required={hook.SATUM === ""}>UM SAT</Label>
                  <ModalUm />
                </div>
              </>
            )}
          </UenV3>
        </form>
      </Dialog>
    </>
  );
}
