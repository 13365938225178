import React from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";
import { useContext } from "react";
import { mnCurrency } from "helpers/money";

export default function Residue() {
  const hook = useContext(ContextEmitCreditNote);

  return (
    <div>
      <Label required={hook.invoice === undefined}>Saldo</Label>
      <p>
        {hook.invoice === undefined
          ? "Primero selecciona una factura"
          : `${mnCurrency(hook.invoice.residue)}`}
      </p>
    </div>
  );
}
