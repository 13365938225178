import { getCustomersAdvances } from "helpers/Apis/Banks/advancePayments";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * @type {import("./types").StateUseAdvance}
 */
const INITIAL_STATE = {
  customer: null,
  currentPage: 1,
  pages: 1,
  advances: [],
  advance: null,
  isLoading: true,
  type: "allOfThem",
};

const dummyFunction = () => {};

/**
 * @type {import("./types").ContextUseAdvance}
 */
export const INITIAL_STATE_CONTEXT_ADVANCE_PAYMENT = {
  ...INITIAL_STATE,
  breadcrum: [],
  selectAdvance: dummyFunction,
  updateFilter: dummyFunction,
  updatePage: dummyFunction,
  movementType: "clientes",
};

/**
 * Get the advances on the system
 * @param {import("./types").Type} type - Type of advances to fetch
 * @returns {import("./types").ReturnUseAdvance}
 */
export default function usePaymentAdvances(type = "clientes") {
  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        advance: null,
        isLoading: true,
      }));

      const data =
        type === "clientes"
          ? await getCustomersAdvances(
              state.currentPage,
              "ingreso",
              state.customer
            )
          : await getCustomersAdvances(
              state.currentPage,
              "egreso",
              state.customer
            );

      setState((current) => ({
        ...current,
        advances: data.advances,
        currentPage: data.currentPage,
        pages: data.totalPages,
        isLoading: false,
      }));
    })();
  }, [state.currentPage, state.customer]);

  /**
   * Update the page for the requested filters
   * @param {number} page - Page
   * @returns {void}
   */
  const updatePage = (page) =>
    setState((current) => ({
      ...current,
      currentPage: page,
    }));

  /**
   * Update the customer to fetch
   * @param {number|null} id - Id of the customer
   * @returns {void}
   */
  const updateFilter = (id) =>
    setState((current) => ({
      ...current,
      customer: id,
      currentPage: 1,
    }));

  /**
   * Update the advance selected
   * @param {import("./types").Advance} advance - Information of the advance
   * @returns {void}
   */
  const selectAdvance = (advance) =>
    setState((current) => ({
      ...current,
      advance,
    }));

  const redirectToEdit = () =>
    history.push(
      `/v2/bancos/${
        type === "clientes" ? "ingresos" : "egresos"
      }/agregar?cuenta=${state.advance.bankAccount.id}&movimiento=${
        state.advance.id
      }&modulo=anticipo`
    );

  return {
    ...state,
    updatePage,
    selectAdvance,
    updateFilter,
    redirectToEdit,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/v2/bancos",
        text: "Bancos",
      },
      {
        route: "/v2/bancos",
        text: `Anticipo`,
      },
    ],
  };
}
