import React, { createContext } from "react";
import useAssociateFiles from "customHooks/useAssociateFiles";
import { Spinner } from "components/individual/Spinner/Spinner";
import FilterFileStatus from "components/general/Forms/Selects/FilterFileStatus";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import AssociateFileModal from "components/general/AssociateFileModal";
import AssociateFilesOptions from "./Options";
import style from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import LoadingText from "components/individual/TextLoading";
import { useRef } from "react";
import { Pagination } from "components/individual/Pagination";

/**
 * @type {import("react").Context<import("./types").ContextAssociateFiles>}
 */
export const AssociateFilesContext = createContext({
  checked: {},
  downloadFileAssociated: () => {},
  idCloseModal: "",
  idRegister: 0,
  idTypeEntity: 1,
  infoRow: null,
  isDeleting: false,
  isDownloading: false,
  deleteFiles: async () => {},
  pathUpload: "/",
  isDeletable: false,
  promptDeleteFile: () => {},
  search: () => {},
  dialogDelete: undefined,
  numberOfFilesChecked: 0,
  isAllowDownload: false,
});
const { Provider } = AssociateFilesContext;

/**
 *  Display the associated files of an entity
 * @param {object} props - Props
 * @param {number} props.idRegister - Id of the register to search for
 * @param {import('types/typedef/files').idTypeEntity} props.idTypeEntity - Indicates what kind of associated file is
 * @param {string} props.pathUpload - Path root where the files will be uploaded on blob storage
 * @returns {JSX.Element} Component
 */
export function AssociateFiles({
  idRegister = null,
  idTypeEntity = 1,
  pathUpload = "/",
}) {
  const hook = useAssociateFiles({
    idRegister,
    idTypeEntity,
  });

  const key = useRef(window.crypto.randomUUID());

  const idCloseModal = "closeAssociatedFiles";

  return (
    <Provider
      value={{
        idRegister,
        numberOfFilesChecked: hook.numberOfFilesChecked,
        isDeletable: hook.isDeletable,
        idTypeEntity,
        isAllowDownload: hook.isAllowDownload,
        pathUpload,
        deleteFiles: hook.deleteFiles,
        idCloseModal,
        search: hook.search,
        isDeleting: hook.isDeleting,
        promptDeleteFile: hook.promptDeleteFile,
        isDownloading: hook.isDownloading,
        infoRow: hook.infoRow,
        downloadFileAssociated: hook.downloadFileAssociated,
        checked: hook.checked,
        dialogDelete: hook.dialogDelete,
      }}
    >
      <div className={style.container}>
        <form
          className={style.filterAssociateFiles}
          onSubmit={(e) => e.preventDefault()}
        >
          <FilterFileStatus
            value={hook.status}
            onChange={(filter) => hook.updateStatus(filter.value)}
          />

          <ButtonV2 onClick={hook.search}>
            <span>Buscar</span>
            <Icon icon="search" />
          </ButtonV2>

          <AssociateFileModal />
        </form>

        <AssociateFilesOptions />

        {hook.isLoading ? (
          <Spinner hidden={false} text={<LoadingText>Cargando</LoadingText>} />
        ) : hook.areFiles ? (
          <div className={style.tableFiles}>
            <div className={style.header}>
              <p>-</p>
              <p>Fecha carga</p>
              <p>Archivo</p>
              <p>Tipo</p>
              <p>Ejecutivo</p>
              <p>Eliminable</p>
            </div>

            <div className={style.containerRows}>
              {hook.files.map((file, i) => (
                <div key={`${key.current}-${i}`}>
                  <input
                    id={`${key.current}-${i}`}
                    disabled={hook.isDownloading || hook.isDeleting}
                    className="d-none"
                    checked={
                      hook.checked[`${file.idAssocaitedFile}`] !== undefined
                    }
                    type="checkbox"
                    onClick={(e) => hook.toggleFile(file)}
                  />

                  <label htmlFor={`${key.current}-${i}`} className={style.row}>
                    <div>
                      <input
                        disabled={hook.isDownloading || hook.isDeleting}
                        id={`${key.current}-${i}`}
                        checked={
                          hook.checked[`${file.idAssocaitedFile}`] !== undefined
                        }
                        type="checkbox"
                        onClick={(e) => hook.toggleFile(file)}
                      />
                    </div>
                    <p>{file.uploadedDate}</p>
                    <p>{file.fileName}</p>
                    <p>{file.fileExtension}</p>
                    <p>{file.createdBy}</p>
                    <p className="text-center">
                      {file.isFileRemovable && file.isAvailable ? "✔" : "✖"}
                    </p>
                  </label>
                </div>
              ))}
            </div>

            <Pagination
              actualPage={hook.state.actualPage}
              pages={hook.state.pages}
              onChange={hook.setPage}
            />
          </div>
        ) : (
          <h1 className="text-center">No hay archivos asociados</h1>
        )}
      </div>
    </Provider>
  );
}
