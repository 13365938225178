import { RFC, LabelRfc, InputRfc } from "components/forms/RFC";
import React from "react";
import { Label } from "structure/Document";
import useEnterpriseInfo from "./useEnterpriseInfo";
import { Spinner } from "components/individual/Spinner/Spinner";
import { createContext } from "react";
import Submit from "./Submit";

export const ContextEnterpriseInfo = createContext({});

export default function EnterpriseInfo() {
  const hook = useEnterpriseInfo();

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="enterpriseInfoLoading"
        text="Cargando"
      />
    );

  return (
    <ContextEnterpriseInfo.Provider value={hook}>
      <form
        onSubmit={hook.form.handleSubmit(
          (data) => hook.attemptUpdateEnterpriseInfo(data),
          (e) => hook.printError(e)
        )}
      >
        <div>
          <Label required={hook.enterpriseName.length <= 0}>
            Nombre de la empresa
          </Label>
          <input name="enterpriseName" id="enterpriseName" type="text" ref={hook.form.register}/>
          <p className="text-danger">
            {hook.form.errors?.enterpriseName?.message}
          </p>
        </div>

        <div>
          <Label required={hook.street.length <= 0}>Calle de la empresa</Label>
          <input type="text" ref={hook.form.register} name="street" />
          <p className="text-danger">{hook.form.errors?.street?.message}</p>
        </div>

        <div>
          <Label required={hook.noInterior.length <= 0}>
            No. interior de la empresa
          </Label>
          <input type="text" ref={hook.form.register} name="noInterior" />
          <p className="text-danger">{hook.form.errors?.noInterior?.message}</p>
        </div>

        <div>
          <Label>No. exterior de la empresa</Label>
          <input type="text" ref={hook.form.register} name="noExterior" />
          <p className="text-danger">{hook.form.errors?.noExterior?.message}</p>
        </div>

        <div>
          <Label required={hook.city.length <= 0}>Ciudad de la empresa</Label>
          <input type="text" ref={hook.form.register} name="city" />
          <p className="text-danger">{hook.form.errors?.city?.message}</p>
        </div>

        <div>
          <Label required={hook.estado.length <= 0}>Estado de la empresa</Label>
          <input type="text" ref={hook.form.register} name="estado" />
          <p className="text-danger">{hook.form.errors?.estado?.message}</p>
        </div>

        <div>
          <Label required={`${hook.postalCode}`.length <= 0}>
            Código postal de la empresa
          </Label>
          <input
            min={0}
            type="number"
            ref={hook.form.register}
            name="postalCode"
          />
          <p className="text-danger">{hook.form.errors?.postalCode?.message}</p>
        </div>

        <div>
          <Label required={hook.email.length <= 0}>Correo de la empresa</Label>
          <input type="email" ref={hook.form.register} name="email" />
          <p className="text-danger">{hook.form.errors?.email?.message}</p>
        </div>

        <RFC value={hook.rfc.rfc} onChange={(data) => hook.updateRfc(data)}>
          {() => (
            <div>
              <LabelRfc />
              <InputRfc />
            </div>
          )}
        </RFC>

        <Submit />
      </form>
    </ContextEnterpriseInfo.Provider>
  );
}
