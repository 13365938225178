import LoginContext from "context/Login/LoginContext";
import { useContext, useState, useEffect } from "react";

export default function usePersonalInformation({ onChange }) {
  const { userInfo } = useContext(LoginContext);

  /**
   * Information of the state
   * @type {[import("./types").StateI,()=>void]}
   */
  const [state, setState] = useState({
    firstName: userInfo[0].firstName,
    lastName: userInfo[0].middleName,
    parentName: userInfo[0].lastName1,
    motherName: userInfo[0].lastName2,
    email: {
      value: userInfo[0].email,
      isValid: true,
    },
    phone: {
      ...userInfo[0].phone,
      isValid: true,
    },
    reminder: {
      ...userInfo[0].reminder,
      isValid: true,
    },
    birthday: userInfo[0].birthday,
    userName: userInfo[0].userName,
  });

  useEffect(() => {
    const infoOnChange = {
      firstName: state.firstName,
      lastName: state.lastName,
      parentName: state.parentName,
      motherName: state.motherName,
      email: state.email,
      userName: state.userName,
      phone: {
        lada: state.phone.lada,
        number: state.phone.movil,
      },
      reminder: {
        whatsapp: state.reminder.whatsapp,
        email: state.reminder.email,
        sms: state.reminder.sms,
      },
      birthday: state.birthday,
    };

    // console.log("info", infoOnChange);

    if (
      (state.firstName && state.parentName && state.motherName) !== "" &&
      state.phone.isValid &&
      state.reminder.isValid &&
      state.email.isValid
    ) {
      onChange({ ...infoOnChange, isValid: true });
    } else {
      onChange({ ...infoOnChange, isValid: false });
    }
  }, [state]);

  /**
   * Update the information of the email according the value typed on the field
   * @param {import("components/individual/inputs/Email/types").onChangeEmailI} email - Email information
   * @returns {void}
   */
  const updateEmail = (email) => {
    setState({
      ...state,
      email: { value: email.email, isValid: email.isValid },
    });
  };

  /**
   * Update first name user
   * @param {string} firstName - First name
   * @returns {void}
   */
  const updateFirstName = (firstName) =>
    setState({
      ...state,
      firstName,
    });

  /**
   * Update the last name of the user
   * @param {string} lastName - Last name
   * @returns {void}
   */
  const updateLastname = (lastName) =>
    setState({
      ...state,
      lastName,
    });

  /**
   * Update parent name
   * @param {string} parentName - Parent name
   * @returns {void}
   */
  const updateParentname = (parentName) =>
    setState({
      ...state,
      parentName,
    });

  /**
   * Update mother name
   * @param {string} motherName - Mother name
   * @returns {void}
   */
  const updateMothername = (motherName) =>
    setState({
      ...state,
      motherName,
    });

  /**
   * Update the username of the current profile
   * @param {string} userName - Username
   * @returns {void}
   */
  const updateUserName = (userName) =>
    setState({
      ...state,
      userName,
    });

  /**
   * Update phone number
   * @param {import("components/individual/inputs/typesPhone").onChangePhoneI} phone - Phone
   * @returns {void}
   */
  const updatePhone = (phone) => {
    console.log(phone);
    setState({
      ...state,
      phone: {
        lada: phone.lada,
        movil: phone.number,
        number: phone.number === null ? null : `${phone.lada}${phone.number}`,
        isValid: phone.isValidNumber,
      },
    });
  };

  /**
   * Update the reminder will receive the user
   * @param {import("structure/Profile/PersonalInformation/Reminders/types").RemindersOnChange} reminders
   * @returns {void}
   */
  const updateReminder = (reminders) =>
    setState({
      ...state,
      reminder: {
        ...reminders,
        isValid: true,
      },
    });

  /**
   * Update the birthday that has the profile
   * @param {string} birthday - Birthday
   * @returns {void}
   */
  const updateBirthday = (birthday) =>
    setState({
      ...state,
      birthday,
    });

  return {
    firstName: state.firstName,
    lastName: state.lastName,
    parentName: state.parentName,
    motherName: state.motherName,
    email: state.email,
    phone: state.phone,
    reminder: state.reminder,
    birthday: state.birthday,
    userName: state.userName,
    updateEmail,
    updateFirstName,
    updateLastname,
    updateParentname,
    updateMothername,
    updateUserName,
    updatePhone,
    updateBirthday,
    updateReminder,
  };
}
