import { createContext } from "react";

/**
 * Information of the user logged
 * @type {React.Context<import('types/typedef/context/LoginContext').LoginContextI>}
 */
const LoginContext = createContext({
  userInfo: [],
  changeView: () => {},
});

export default LoginContext;
