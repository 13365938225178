import ButtonV2 from "components/individual/ButtonsV2/Button";
import PillFilterMobile from "components/individual/PillFilterMobile";
import { parseDateToText } from "helpers/dates";
import React, { useContext } from "react";
import { ContextEmitedCreditNotes } from "..";
import Icon from "components/individual/HeaderIcon";
import ui from "../../../Compras/NotasDeCreditoRecibidas/FilterMobile/styles.module.scss";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import StatusFilter from "../StatsuFilter";
import RangeDate from "../RangeDate";
import Search from "../Search";

const STATUS_LABEL = {
  13: "Emitidas",
  15: "Canceladas",
};

export default function FilterMobile() {
  const hook = useContext(ContextEmitedCreditNotes);

  const dialog = useDialog();

  return (
    <div className={ui.filter}>
      <ButtonV2 onClick={hook.dialog.open}>
        <Icon icon="filter" />
      </ButtonV2>

      <Dialog
        title="Facturas emitidas"
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
        footer={
          <div className={ui.footerFilter}>
            <ButtonV2 onClick={(e) => hook.attemptSearch()}>
              <span>Buscar</span>
              <Icon icon="search" />
            </ButtonV2>
          </div>
        }
      >
        <div className={ui.content}>
          <StatusFilter />
          <RangeDate />
          <Search />
        </div>
      </Dialog>

      <PillFilterMobile
        label="Estatus"
        value={STATUS_LABEL[hook.status] || "Todas"}
      />
      <PillFilterMobile
        label="Inicio"
        value={parseDateToText(hook.beginDate)}
      />
      <PillFilterMobile label="Fin" value={parseDateToText(hook.endDate)} />
    </div>
  );
}
