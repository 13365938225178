import React, { useEffect, useState, useContext } from "react";

import { useParams, useHistory } from "react-router";

// HEADER
import HomeHeader from "../../../../components/general/homeNavBar/HomeHeader";
import { editMovement } from "../../../../static/StaticBreadCrums";

// FORM
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editEgress } from "../../../../Schemas/Users/Banks/movementsSchema";

// COMPONENTS
import EditOptions from "./EditOptions";
import FormDefault from "../FormDefault";
import BankInformation from "../../BankInformation";

// APIS
import { getMovement, updateMovementV2 } from "../../../../helpers/Apis/Banks";

// HELPERS
import { setInputsEgress } from "../../../../helpers/Banks/Movements/movements";
import { trimDate } from "../../../../helpers/dates";

// CONTEXT
import TableContext from "../../../../context/Table/TableContext";
import { Spinner } from "components/individual/Spinner/Spinner";
import { createContext } from "react";
import { BankAccountHeader } from "structure/BankAccountHeader";

/**
 * @type {import("react").Context<import("./types").MovementContext>}
 */
export const EditContext = createContext();
const { Provider } = EditContext;

function EditMovement() {
  const { movement, account } = useParams();

  const [isUpdating, setIsUpdating] = useState(false);

  const history = useHistory();

  const { refetch } = useContext(TableContext);

  const [date, setDate] = useState(null);

  /**
   * Information of the movement
   * @type {[import("types/typedef/movement").MovementI,()=>void]}
   */
  const [movementInfo, setMovementInfo] = useState(null);

  /**
   * Update the amount of the movement
   * @param {number} amount - New amount
   * @returns {void}
   */
  const updateAmount = (amount) =>
    setMovementInfo({
      ...movementInfo,
      amount,
    });

  editMovement.breadcrum[2].url = `/bancos/movimientos/${account}`;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(editEgress),
  });

  // UPDATE BREADCRUM
  editMovement.breadcrum[3].contentText = "Editar";

  const executeQuery = async (data) => {
    setIsUpdating(true);

    const wasUpdated = await updateMovementV2({
      concept: data.concept,
      id: data["id_movement"],
      payMethods: data.payMethods,
      reference: data.reference,
      registerDate: data.registerDate,
      amount: movementInfo.amount,
    });

    setIsUpdating(false);

    if (wasUpdated) history.push(`/bancos/movimientos/${account}`);
  };

  useEffect(() => {
    async function initialLoad() {
      let data = await getMovement(+movement);

      // Set date
      const dateSeparated = trimDate(data.registerDate, "dmy", "-");

      setDate(
        new Date(dateSeparated.year, dateSeparated.month - 1, dateSeparated.day)
      );

      setMovementInfo(data);

      // Set inputs
      setInputsEgress(data);
    }

    initialLoad();
  }, []);

  useEffect(() => {
    if (movementInfo !== null) setInputsEgress(movementInfo);
  }, [refetch]);

  return (
    <Provider
      value={{
        movementInfo,
        updateAmount,
      }}
    >
      <HomeHeader bredcrum={editMovement} mostrar={false} />

      <div className="container">
        <BankAccountHeader idBankAccount={+account} useDefaultDesign/>
        {/* <BankInformation idAccount={+account} /> */}

        {movementInfo !== null ? (
          <form
            id="updateMovement"
            onSubmit={handleSubmit(executeQuery)}
            className="container shadowBackground w-50 mx-auto px-5"
          >
            <FormDefault errors={errors} forwardRef={register} date={date} />

            {isUpdating ? (
              <Spinner hidden={false} text="Actualizando..." />
            ) : movementInfo.amount > 0 ? (
              <EditOptions />
            ) : null}
          </form>
        ) : null}
      </div>
    </Provider>
  );
}

export default EditMovement;
