import { Spinner } from "components/individual/Spinner/Spinner";
import { GetCustomer } from "helpers/Apis/Directory";
import { GetDocument } from "helpers/Apis/Documents";
import React, { createContext, useEffect, useState } from "react";
import SocialReason from "./SocialReason";
import Label from "./Label";
import TC from "./TC";
import ExpirationDate from "./ExpirationDate";
import ReminderDate from "./ReminderDate";
import Probability from "./Probability";
import Month from "./Month";
import Amount from "./Amonut";
export { SocialReason };
export { Amount };
export { Month };
export { Probability };
export { ReminderDate };
export { Label };
export { TC };
export { ExpirationDate };

/**
 * @type {import("react").Context<import("types/typedef/context/DocumentContext").DocumentContextI>}
 */
export const DocumentContext = createContext();
const { Provider } = DocumentContext;

/**
 * Get the information of a document
 * @param {object} props - Props
 * @param {number?} props.idDocument - Id document
 * @param {number} props.idCustomer - Id of the customer will be that document to be created
 * @param {(object:import("types/typedef/context/DocumentContext").DocumentContextI)=>JSX.Element} props.children - Elements to render inside of the document info
 * @param {(object:{
 *  document:import("types/typedef/documents").DocumentI,
 *  customer:import("types/typedef/customer").CustomerI
 * })=>void} props.onLoaded - Information of the document when information it's loaded
 * @returns {JSX.Element}
 */
export function Document({
  idDocument,
  idCustomer,
  children,
  onLoaded = () => {},
}) {
  const [state, setState] = useState({
    customer: null,
    document: null,
    isLoaded: false,
  });

  useEffect(() => {
    (async function () {
      if (!idCustomer && !idDocument) {
        // alert("Proporciona el id del documento o un id del cliente");
        // return;
        setState({
          document: null,
          customer: null,
          isLoaded: true,
        });

        return;
      }

      const document = await GetDocument(idDocument);

      const idCustomerToUse =
        idCustomer === null ? document.customer.id : idCustomer;

      const customer = await GetCustomer(idCustomerToUse);

      onLoaded({
        document,
        customer,
      });

      setState({
        document,
        customer,
        isLoaded: true,
      });
    })();
  }, [idDocument]);

  return (
    <Provider
      value={{
        customer: state.customer,
        document: state.document,
      }}
    >
      {!state.isLoaded ? (
        <Spinner hidden={false} text="Cargando documento..." />
      ) : (
        children({
          customer: state.customer,
          document: state.document,
        })
      )}
    </Provider>
  );
}
