import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import useSetup from "customHooks/useSetup";
import React from "react";
import scss from "../styles.module.scss";

/**
 * Button in order to upload the set up excel for ivas
 * @param {import("./types").FooterProps} props - Props
 * @returns {JSX.Element}
 */
export const Footer = ({ file = undefined }) => {
  const { isLoading, isUpdating, downloadMovementLoad, attemmptUploadMovements } =
    useSetup("ivas");

  return (
    <div className={scss.footer}>
      {isLoading ? (
        <Spinner
          idSpinner="setup-movement"
          hidden={false}
          text="Descargando plantilla..."
        />
      ) : isUpdating ? (
        <span></span>
      ) : (
        <span onClick={downloadMovementLoad}>Descarga la plantilla aqui</span>
      )}

      {file === undefined ? (
        <p></p>
      ) : isUpdating ? (
        <Spinner
          idSpinner="setup-movement-file"
          hidden={false}
          text="Un momento..."
        />
      ) : (
        <ButtonV2 onClick={async () => await attemmptUploadMovements(file)}>
          Cargar excel
        </ButtonV2>
      )}
    </div>
  );
};
