import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCreateInvoiceV2 } from "..";
import Select from "components/individual/SelectV4";

export default function PayForm() {
  const hook = useContext(ContextCreateInvoiceV2);

  return (
    <div>
      <Label required={hook.payForm === undefined || hook.payForm === null}>
        Forma de pago
      </Label>
      <Select
        defaultSelected={{
          value: null,
          keyToCompare: "value",
        }}
        options={async () => [
          {
            label: "--SELECCIONA--",
            value: null,
            disabled: true,
          },
          {
            label: "PUE - Pago inmediato",
            value: "PUE",
          },
          {
            label: "PPD - Pago diferido",
            value: "PPD",
          },
        ]}
        labelToDisplay="label"
        onChange={(item) => hook.updatePayForm(item.value)}
      />
    </div>
  );
}
