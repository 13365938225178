import styled from "styled-components";
import { InputTextArea } from "../inputs/Inputs";

const hoverBg = "#f3f3f3";

export const IconFile = styled.div`
  svg {
    color: ${({ color }) => color} !important;
    background-color: transparent;
  }

`;

export const PillEmail = styled.p`
  border-radius: 10px;
  width: max-content;
  padding: 10px 20px;
  margin: 5px;
  transition: background 0.25s ease-in-out;
  display: flex;
  align-items: center;
  background:var(--primaryBackground);
  color:var(--primaryColor);
  svg {
    pointer-events: none;
  }

  button {
    border: none;
    background: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 10px;
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-property: color, background;
    &:hover {
      background-color: red;
      color: white;
    }
  }
`;

export const ContainerPill = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const FormEmail = styled.form`
  display: flex;
  justify-content: space-between;
  .emailTo {
    width: 92% !important;
  }
`;

export const FileInfoCard = styled.div`
  background-color: var(--primaryBackground);
  
  color:var(--primaryColor);
  svg {
    pointer-events: none;
    display:inline-block !important;
  }
  display: flex;
  gap:1rem;
  min-height: 50px;
  box-sizing: border-box;
  p {
    margin: 0;
  }
  div:nth-child(1) {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div:nth-child(2) {
    padding: 3px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  div:nth-child(3) {
    widht: 25px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    transition: background 0.25s ease-in-out;
    &:hover {
      background-color: #dcdcdc;
      cursor: pointer;
    }
    justify-content: center;
  }
`;

export const FileInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  gap:1rem;
  max-height: 115px;
  overflow: auto;
`;

export const ContainerEmailList = styled.div`
  max-height: 113px;
  overflow: auto;
`;

export const ValidExtension = styled.span`
  &::after {
    content: "${({ itemIndex, totalItems }) =>
      itemIndex < totalItems - 1 ? " , " : "."}";
  }
`;

export const Message = styled(InputTextArea)`
  textarea {
    max-height: 50px;
  }
`;
