import { getRolsPaginated } from "helpers/Apis/rolesApi";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateRols}
 */
const INITIAL_STATE = {
  currentPage: 1,
  pages: 0,
  rol: null,
  rols: [],
  search: "",
  isLoading: true,
  triggerSearch: false,
};

const dummyFunction = () => {};

/**
 * @type {import("./types").ReturnStateRols}
 */
export const ROLS_CONTEXT_VALUE = {
  ...INITIAL_STATE,
  setPage: dummyFunction,
  updateSearch: dummyFunction,
  setRol: dummyFunction,
  triggerSearch: dummyFunction,
  idOpenEdit:window.crypto.randomUUID()
};

export default function useRol() {
  const [state, setState] = useState(INITIAL_STATE);

  const idOpenEdit = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        rol:null
      }));

      const apiRols = await getRolsPaginated(state.currentPage, state.search);

      setState((current) => ({
        ...current,
        rols: apiRols.rols,
        pages: apiRols.pages,
        currentPage: apiRols.currentPage,
        isLoading: false,
      }));
    })();
  }, [state.currentPage, state.triggerSearch]);

  const triggerSearch = () =>
    setState((current) => ({
      ...current,
      currentPage: 1,
      triggerSearch: !current.triggerSearch,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      currentPage: page,
    }));

  /**
   * Update the search to use for roles module
   * @param {string} search - Search
   * @returns {void}
   */
  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
      triggerSearch:
        search === "" ? !current.triggerSearch : current.triggerSearch,
    }));

  const setRol = (rol) =>
    setState((current) => ({
      ...current,
      rol,
    }));

  return {
    ...state,
    setPage,
    updateSearch,
    setRol,
    triggerSearch,
    idOpenEdit:idOpenEdit.current,
    pathBreadcrum:[
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/roles",
        text: "Roles",
      },
    ]
  };
}
