import { Spinner } from "components/individual/Spinner/Spinner";
import { getTickets } from "helpers/Apis/helpDesk";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import scss from "./styles.module.scss";

export const Tickets = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    (async function () {
      const apiTickets = await getTickets();

      setTickets(apiTickets);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <Spinner hidden={false} text="Cargando tickets..." />;

  if (tickets.length <= 0)
    return <h1 className="text-center py-5">No hay tickets resportados</h1>;

  return (
    <div className={scss.containerTickets}>
      {tickets.map((ticket) => (
        <div key={window.crypto.randomUUID()} className={scss.ticket}>
          <p>
            <b>{ticket.title}</b>
            <time className={scss.creation}>
              {new Intl.DateTimeFormat("es-MX", {
                dateStyle: "full",
                hour12: true,
              }).format(new Date(ticket.created_at))}
            </time>
          </p>

          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={ticket.body} />
        </div>
      ))}
    </div>
  );
};
