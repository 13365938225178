import { getCustomersOfExecutive } from "helpers/Apis/Directory";
import { AllExecutives } from "helpers/apis";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseExchangeDirectory}
 */
const INITIAL_STATE = {
  isLoading: false,
  targetExecutive: undefined,
  customers: [],
  isUpdating: false,
  availableExecutives: [],
  grid: [],
};

/**
 * Handle the change of the customers that has a executive, to another
 * @param {number} [executive=null] - Id of the executive
 * @param {import("./types").OnChangeGridExecutiveChange} onChange - Callback to execute when grid changes on anything
 * @returns {import("./types").ReturnUseExchangeDirectory}
 */
export default function useExchangeDirectory(
  executive = null,
  onChange = () => {}
) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      if (typeof executive !== "number" || executive <= 0) {
        setState((current) => ({
          ...current,
          isLoading: false,
          customers: [],
        }));
        return;
      }

      const customers = await getCustomersOfExecutive(executive);

      setState((current) => ({
        ...current,
        isLoading: false,
        customers,
        availableExecutives: current.availableExecutives.filter(
          (item) => item.value !== executive
        ),
      }));
    })();
  }, [executive]);

  useEffect(() => {
    (async function () {

      const executives = await AllExecutives();
      const targetExecutive = executives.find(
        (item) => item.value === executive
      );

      if (executive <= 0) return;

      /**
       * @type {import("../../../../../server/models/directoryTypes").ExchangeCustomerItem}
       */
      const parsedInfo = {
        id: targetExecutive.value,
        isActive: targetExecutive.status,
        rfc: "",
        shortName: targetExecutive.TEXT,
        socialReason: targetExecutive.TEXT,
      };

      setState((current) => ({
        ...current,
        targetExecutive: parsedInfo,
        availableExecutives: executives
          .map((item) => ({
            ...item,
            label: item.TEXT,
            value: item.value,
          }))
          .filter((item) => item.value !== executive),
      }));
    })();
  }, [executive]);

  useEffect(() => {
    onChange(state.grid);
  }, [state.grid]);

  /**
   * Set the new executive for the client requested
   * @param {import("../../../../../server/models/directoryTypes").ExchangeCustomerItem} customer
   * @param {import("helpers/apisTypes").Users|null} executive
   * @param {number} index - Item of the grid checked
   */
  function setNewAssociation(customer, executive, index) {
    if (executive === null) {
      deleteFromGrid();
      return;
    }

    appendToGrid();

    //////////////////////////////////////////////////////

    function deleteFromGrid() {
      setState((current) => ({
        ...current,
        grid: current.grid.filter((item) => item.index !== index),
      }));
    }

    function appendToGrid() {
      setState((current) => ({
        ...current,
        grid: [
          ...current.grid,
          {
            customer,
            executive,
            index,
          },
        ],
      }));
    }
  }

  return {
    ...state,
    setNewAssociation,
  };
}
