import React, { useEffect, useContext } from "react";

// APIS
import { GetCatalogue } from "../../../../../helpers/Apis/Documents";

// HELPERS
import { parseCatalog } from "../../../../../helpers/Directory/Documents/addDocument";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";

// LIBS
import CreatableSelect from "react-select/creatable";

// COMPONENTS
import { ModalButton } from "../../../../../components/individual/buttons/Buttons";

function GameInput() {
  const {
    setInfoGame,
    setIvas,
    infoGame,
    catalogue,
    setCatalogue,
    currencyBeingUsed,
    setIvasNewGame,
  } = useContext(AddDocumentContext);

  const openModal = (value) => {
    setInfoGame(value);

    if (value === null) return;

    const button = document.getElementById("triggerModal");
    button.click();
  };

  useEffect(() => {
    async function initialLoad() {
      const loadedCatalog = await GetCatalogue();

      parseCatalog(loadedCatalog.catalogue);

      setIvas(loadedCatalog.ivas);
      setIvasNewGame(loadedCatalog.ivas);
      setCatalogue(loadedCatalog.catalogue);
    }

    initialLoad();
  }, []);

  return (
    <>
      {currencyBeingUsed === null ? (
        <h1>Primero selecciona la moneda.</h1>
      ) : (
        <>
          <button
            type="button"
            class="d-none"
            data-toggle="modal"
            id="triggerModal"
            data-target="#infoProduct"
          ></button>

          <div className="row pt-4">
            <div className="col-8">
              <b>Productos/Servicios</b>

              <CreatableSelect
                isClearable={true}
                options={catalogue}
                placeholder="Selecciona o crea"
                onChange={(value) => openModal(value)}
                value={infoGame}
              />
            </div>

            {infoGame !== null ? (
              <ModalButton
                id="openModal"
                text="Agregar"
                aditionalClasses="px-4"
                target="infoProduct"
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default GameInput;
