import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextReceiveCredtNotes } from "..";
import StatusAccounting from "components/general/StatusAccounting";

export default function StatusFilter() {
  const hook = useContext(ContextReceiveCredtNotes);

  if (hook.module === "contabilidad")
    return (
      <StatusAccounting
        onChange={hook.setStatusAccounting}
        value={hook.contabilidad}
      />
    );

  return (
    <div>
      <Label>Estatus</Label>
      <select
        className="select-css"
        onChange={(e) => hook.updateStatus(e.target.value)}
      >
        <option value="0">Todos</option>
        <option value="13">Recibidas</option>
        <option value="14">Canceladas</option>
      </select>
    </div>
  );
}
