import React, { useContext } from "react";
import { Label } from "structure/Document";
import InformativeTooltip from "components/individual/InfoTooltip";
import { ContextAddIncoming } from "..";

export default function Beneficiary() {
  const hook = useContext(ContextAddIncoming);

  return (
    <div>
      <Label>
        Origen{" "}
        {typeof hook.state.customer === "number" ? (
          <InformativeTooltip>
            <p>
              Para escribir un beneficiario debes de borrar el campo
              <b>Proveedor</b>
            </p>
          </InformativeTooltip>
        ) : null}
      </Label>
      {typeof hook.state.customer === "number" ? (
        <p>{hook.state.beneficiary.description}</p>
      ) : (
        <input
          type="text"
          disabled={typeof hook.state.customer === "number"}
          maxLength={256}
          value={hook.state.beneficiary.description}
          placeholder="Escribe aquí (Opcional)"
          onChange={(e) => hook.setBeneficiary(null,e.target.value)}
        />
      )}
    </div>
  );
}
