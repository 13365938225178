import { copyOnClipboard } from "helpers/dom";
import React, { useRef } from "react";

/**
 * @param {import("./types").PropsVariableButton} props - Props
 * @returns {JSX.Element}
 */
export default function VariableButton({ variableContent , name = "Variable" }) {
  const message = useRef(
    "Pega la variable con Ctrl + V o Click derecho > Pegar en el campo de texto"
  );

  return (
    <button
      type="button"
      onClick={() => copyOnClipboard(variableContent, true, message.current)}
    >
      {name}
    </button>
  );
}
