import { validatePhone } from "helpers/phone";
import React, { forwardRef, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ui from "./styles.module.scss";

/**
 * @type {import("helpers/typesPhone").ValidatePhone}
 */
const INITIAL_STATE = {
  isValid: true,
  lada: "",
  phone: "",
};

/**
 * @type {import("./types").PhoneField}
 */
const ForwardPhone = forwardRef((props, ref) => {
  const [state, setState] = useState(INITIAL_STATE);

  const htmlInputProps = {...props};
  delete htmlInputProps.onCustomOnChange;

  return (
    <div className={ui.containerPhone}>
      <PhoneInput
        onChange={(phone, data) => {
          const validation = validatePhone(data, phone);

          setState(validation);
          if (props.onCustomOnChange) {
            props.onCustomOnChange(`${validation.lada}${validation.phone}`);
          }
        }}
        inputProps={htmlInputProps}
        value={`${state.lada}${state.phone}`}
      />

      {!state.isValid ? (
        <p className="text-danger font-weight-bold my-2">
          Teléfono inválido, revisa que el lada y el número sean correctos
        </p>
      ) : null}

      <input
        {...htmlInputProps}
        ref={ref}
        name={`${window.crypto.randomUUID()}`}
        id={`${window.crypto.randomUUID()}`}
        type="text"
        className="d-none"
        value={`${state.lada}${state.phone}`}
      />
    </div>
  );
});

export default ForwardPhone;
