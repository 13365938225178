import React, { Fragment, useContext, useRef } from "react";
import { ContextSatCode } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import Table from "components/general/Table";

export default function Results() {
  const hook = useContext(ContextSatCode);

  if (hook.results.length <= 0)
    return <h1 className="text-center p-5">No hay resultados </h1>;

  if (hook.isLoading)
    return (
      <div className="p-5">
        <Spinner hidden={false} text={"Buscando"} />
      </div>
    );

  return (
    <Fragment>
      <Table
        data={hook.results}
        pagination={false}
        sortServer={false}
        selectableRowsSingle
        selectableRowSelected={(item) => item === hook.itemSelected}
        onRowClicked={(item, e) => {
          hook.setOptionSelected(item);
          hook.onChange(item || { Name: "", Value: "" })
        }}
        columns={[
          {
            name: "Clave",
            width: "25%",
            selector: (item) => item.Value,
            sortable: true,
          },
          {
            name: "Descripción",
            width: "75%",
            selector: (item) => item.Name,
            sortable: true,
          },
        ]}
      />
    </Fragment>
  );
}
