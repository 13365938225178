import { useReducer } from "react";
import UsersCheckBoxReducer from "./UsersCheckBoxReducer";
import UsersCheckBoxContext from "./UsersCheckBoxContext";

import { SET_TO_ADD,SET_TO_DELETE } from '../../types/index';

const UsersCheckBoxState = props => {
    const initialState = {
        addFilter : [],
        quitFilter : []
    }

    const [state,dispatch] = useReducer(UsersCheckBoxReducer,initialState);

    const setAddFilter = list => {
        dispatch({
            type:SET_TO_ADD,
            payload:list
        });
    }

    const setQuitFilter = list => {
        dispatch({
            type:SET_TO_DELETE,
            payload:list
        });
    }

    const values = {
        addFilter:state.addFilter,
        quitFilter:state.quitFilter,
        setAddFilter,
        setQuitFilter

    }

    return(
        <UsersCheckBoxContext.Provider value = {values}>
            {props.children}
        </UsersCheckBoxContext.Provider>
    )
}

export default UsersCheckBoxState;