import { From, RangeDate as Range, To } from "components/general/RangeDate";
import React, { useContext } from "react";
import { ContextAuthsInvoice } from "..";

export default function RangeDate() {
  const hook = useContext(ContextAuthsInvoice);

  return (
    <Range>
      <From
        selected={hook.from}
        onChange={(date) => hook.setRange(date, "from")}
      />
      <To
        selected={hook.to}
        maxDate={new Date()}
        onChange={(date) => hook.setRange(date, "to")}
      />
    </Range>
  );
}
