import React from "react";
import BankContext from "context/Bank/BankContext";
import { useContext } from "react";
import { dateToDbFormat, dateWithCeroHours, formateDate, getDateFromUtc } from "helpers/dates";
import { ContextAddIncoming } from "..";
import { Label } from "structure/Document";
import { useEffect } from "react";
import { useState } from "react";
import { InputDate } from "components/individual/inputs/Inputs";
import { add } from "date-fns";

export default function DateField() {
  const context = useContext(BankContext);
  const hook = useContext(ContextAddIncoming);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (context.account === null) return;

    const [minDate] = context.account.closingBalanceDate.split("T");

    const [year,month,day] = minDate.split('-')

    const minDateParsed = (new Date(+year,+month-1,+day))

    const nextMonth = add(minDateParsed,{
      days:1
    })

    // const nextMonth = new Date(
    //   `${minDateParsed.getFullYear()}-${minDateParsed.getMonth() + 1}-01`
    // );


    const minDateToUse = `${dateToDbFormat(nextMonth)}`;



    setDate(nextMonth);
  }, [context.account]);

  if (context.account === null || date === null) return <></>;

  if (hook.state.movement.id === null)
    return (
      <div>
        <Label>Fecha</Label>

        <InputDate
          minDate={dateWithCeroHours(new Date(date))}
          inputChange={hook.updateDate}
          maxDate={hook.state.maxDate}
          date={hook.state.date}
        />
      </div>
    );

  return (
    <div>
      <Label>Fecha</Label>
      <p>{formateDate(hook.state.movement.info.date)}</p>
    </div>
  );
}
