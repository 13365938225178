import { isValidHttpResCode } from "helpers/Apis/fetch";
import { dateToDbFormat, rangeFullYear } from "helpers/dates";
import { URL_BASE } from "routes/routes";

export const ConfirmationStatusOcManagment = {
  0: "No confirmado",
  1: "Confirmado",
  2: "No requiere confirmacion",
};

/**
 * Get the records for the managment of the oc
 * @param {import("./types").OcManagmentParams} params - Query params in order to get the records
 * @returns {Promise<import("./types").OcManagment[]>}
 */
async function get(
  params = {
    beginDate: rangeFullYear().firstDay.jsDate,
    endDate: rangeFullYear().lastDay.jsDate,
    idClient: null,
    idSupplier: null,
    notCfSingned: null,
    notConfirmed: null,
    notCotnractSigned: null,
    notInvoiced: null,
    notLicences: null,
    notSended: null,
    all:false
  }
) {
  try {
    const beginDateParsed =
      params.beginDate instanceof Date
        ? dateToDbFormat(params.beginDate)
        : params.beginDate;
    const queryBeginDate = `beginDate=${beginDateParsed}`;

    const idClientQuery =
      typeof params.idClient === "number" ? `&idClient=${params.idClient}` : "";

    const idProviderQuery =
      typeof params.idSupplier === "number"
        ? `&idSupplier=${params.idSupplier}`
        : "";
    const notCfSingnedQuery =
      typeof params.notCfSingned === "boolean" && params.all === false
        ? `&notCfSingned=${params.notCfSingned}`
        : "";
    const notConfirmedQuery =
      typeof params.notConfirmed === "boolean"&& params.all === false
        ? `&notConfirmed=${params.notConfirmed}`
        : "";
    const notCotnractSignedQuery =
      typeof params.notCotnractSigned === "boolean"&& params.all === false
        ? `&notCotnractSigned=${params.notCotnractSigned}`
        : "";
    const notInvoicedQuery =
      typeof params.notInvoiced === "boolean"&& params.all === false
        ? `&notInvoiced=${params.notInvoiced}`
        : "";
    const notLicencesQuery =
      typeof params.notLicences === "boolean"&& params.all === false
        ? `&notLicences=${params.notLicences}`
        : "";
    const notSendedQuery =
      typeof params.notSended === "boolean"&& params.all === false
        ? `&notSended=${params.notSended}`
        : "";

    const endDateParsed =
      params.endDate instanceof Date
        ? dateToDbFormat(params.endDate)
        : params.endDate;
    const queryEndDate = `&endDate=${endDateParsed}`;

    const res = await fetch(
      `${URL_BASE}controlodc?${queryBeginDate}${queryEndDate}${idClientQuery}${idProviderQuery}${notCfSingnedQuery}${notConfirmedQuery}${notCotnractSignedQuery}${notInvoicedQuery}${notLicencesQuery}${notSendedQuery}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    /**
     * @type {import("./types").OcManagment[]}
     */
    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status))
      return data.map((oc) => ({
        ...oc,
        client: oc.client === null ? "ND" : oc.client,
      }));

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Add a comment
 * @param {number} idOdc - Id of the ODC
 * @param {string} comment - Markdown comment
 * @param {boolean} [withEmail=false] - If true, an email will be send
 */
async function addComment(idOdc, comment, withEmail = false) {
  try {
    const DTO = {
      idOdc,
      comment,
      wasSend: withEmail,
    };

    const res = await fetch(`${URL_BASE}controlodc/comentario`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(DTO),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return true;

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the comment of an OC
 * @param {number} idOdc - Id of the odc
 * @returns {Promise<import("pages/Compras/GestionOc/Comment/useCommentOc/types").CommentOc[]>}
 */
async function getComments(idOdc) {
  try {
    const res = await fetch(`${URL_BASE}controlodc/comentario/${idOdc}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return data.reverse();

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Update the oc that requires confirmation
 * @param {number[]} notConfirmed - Ids of the `OCS` that don't require confirmation
 * @param {import("./types").ConfirmationOc[]} confirmed - Data for the records that requires confirmation and need the sku
 * @returns {Promise<boolean>}
 */
async function updateNotConfirmed(notConfirmed = [], confirmed = []) {
  try {
    const body = {
      odcConfirm: confirmed,
      odcNotRequiered: notConfirmed,
    };

    const res = await fetch(`${URL_BASE}controlodc/confirmacion`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return true;

    return false;
  } catch (error) {
    return false;
  }
}

/**
 *
 * @param {number[]} ids - Ids of the records to update
 * @param {import("pages/Compras/GestionOc/useGestionOc/types").Status} type
 */
async function update(ids = [], type) {
  const validUpdates = ["cfNotConfirmed", "myContract", "noLicenses"];

  if (!validUpdates.includes(type)) return;

  const urlToFetch = {
    cfNotConfirmed: "firmafabricante",
    myContract: "firmainstitucional",
    noLicenses: "confirmarlicencias",
  };

  try {
    const res = await fetch(`${URL_BASE}controlodc/${urlToFetch[type]}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ odcArray: ids }),
    });

    if (res.ok && isValidHttpResCode(res.status)) return true;

    return false;
  } catch (error) {
    return false;
  }
}

export default {
  get,
  comment: {
    add: addComment,
    get: getComments,
  },
  update: {
    ocNotConfirmated: updateNotConfirmed,
    cfNotSigned: update,
    myContract: update,
    noLicense: update,
    record:update
  },
};
