import useNotasDeCredito from "pages/Ventas/NotasDeCreditoEmitidas/useNotasDeCredito";
import React, { createContext } from "react";
import ui from "pages/Compras/NotasDeCreditoRecibidas/styles.module.scss";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import YearFilter from "./YearFilter";
import MonthFilter from "./MonthFilter";
import { Search } from "components/individual/Search";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import Table from "./Table";
import Options from "./Options";
import StatusAccounting from "components/general/StatusAccounting";

/**
 * @type {import("react").Context<import("pages/Ventas/NotasDeCreditoEmitidas/useNotasDeCredito/types").ReturnUseNotasDeCredito>}
 */
export const ContextCreditNoteAccounting = createContext(undefined);

export default function CreditNotesEmitedAccounting() {
  const hook = useNotasDeCredito("contabilidad");

  return (
    <ContextCreditNoteAccounting.Provider value={hook}>
      <div className={ui.container}>
        <div className={ui.header}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={ui.filter}>
            <StatusAccounting
              value={hook.contabilidad}
              onChange={hook.setAccountingStatus}
            />

            <YearFilter />
            <MonthFilter />
            <div>
              <b>Búsqueda</b>
              <Search placeholder="Razón social, uuid" hideSearchIcon={true} />
            </div>

            <div>
              <b>No. factura</b>
              <Search
                placeholder="Escribe aqui"
                type="number"
                hideSearchIcon={true}
                onChange={hook.updateSearch}
                onDelete={hook.updateSearch}
              />
            </div>

            <ButtonV2 onClick={(e) => hook.attemptSearch()} disabled={hook.isSearching}>
              <span>Buscar</span>
              <Icon icon="search" />
            </ButtonV2>
            <ButtonV2 onClick={(e) => hook.handleDownlaodExcel()} disabled={hook.isDownloadingExcel}>
              <span>Excel</span>
              <Icon icon="excel" />
            </ButtonV2>
          </div>
        </div>

        <Options />
        <Table />
      </div>
    </ContextCreditNoteAccounting.Provider>
  );
}
