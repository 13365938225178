// HOOKS
import React, { useContext, useEffect, useState } from "react";
import useAddDocument from "../../../../../customHooks/useAddDocuments";

// INPUTS
import {
  SocialReason,
  TCP,
  Probability,
  CreditDays,
  CustomerContacts,
  InformativeReminderDate,
  ImportPrice,
  IvaPrice,
  TotalPrice,
} from "../Inputs/General";

import { Informative } from "../../../Documents/Inputs/General/Index";
import { ContainerDocInfo } from "../../../Documents/InfoDocument/Styles";

import Currency from "../../../../../components/general/Forms/Selects/Currency";

import DocExpirationDate from "../../../../../components/general/Forms/Dates/DocExpirationDate";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";
import LoginContext from "../../../../../context/Login/LoginContext";

// LIBS
import { useParams } from "react-router";
import { FourthRow, SevenItems } from "../../Inputs/General/Styles";
import { getNumberDate } from "../../../../../helpers/dates";

export default function AddQuote({ forwardRef, errors }) {
  const { customerId } = useParams();

  const [typeCurrency, setTypeCurrency] = useState(1);
  const [expDate, setExpDate] = useState(null);

  const { ResetState } = useAddDocument();

  const { changeCurrency } = useAddDocument();

  ResetState();

  const { tcp, customerInfo, setCurrencyType, setCurrencyName, currencyName , exchangeTcp , setExchangeTcp , setCurrencyUsed } =
    useContext(AddDocumentContext);

  const { userInfo } = useContext(LoginContext);

  const [infoQuote, setInfoQuote] = useState({
    creationDate: "ND",
  });

  useEffect(() => {
    const actualDate = getNumberDate(new Date());

    setInfoQuote({
      creationDate: `${actualDate.day}/${actualDate.monthAbrev}/${actualDate.year}`,
    });

    setCurrencyUsed(null);
  }, []);

  useEffect(() => {
    changeCurrency(tcp.test);
  }, [currencyName]);

  return (
    <div className="container pb-4">
      <ContainerDocInfo>
        <Informative label="ID" value="ND" />
        <Informative label="Tipo" value="Cotizacion" />
        <Informative label="No." value="ND" />
        <Informative value="Abierta" label="Estatus" />
        <Informative value={userInfo[0]?.fullName} label="Ejecutivo" />
      </ContainerDocInfo>

      <div className="row">
        <div className="col-6">
          <SocialReason
            disabled={true}
            label="Cliente"
            customer={customerInfo?.commercialName}
          />
        </div>
        <div className="col-6">
          <CustomerContacts idCustomer={+customerId} forwardRef={forwardRef} />
        </div>
      </div>

      <h4 className="mt-3">Documentos relacionados</h4>
      <SevenItems>
        <Informative label="Origen" placeholder="ND" />

        <Informative value={document.id} label="Cotizacion" placeholder="ND" />

        <Informative label="Pre-factura" />

        <Informative label="Factura" />

        <Informative label="Contrato" />

        <Informative label="OC" />

        <Informative label="Fact. proveedor" />
      </SevenItems>

      <SevenItems>
        <Informative label="No. contrato" value="ND" />

        <Informative label="Creacion" value={infoQuote.creationDate} />

        <Informative
          label="Modificacion"
          value="Sin ediciones"
          placeholder="Sin editar"
        />

        <DocExpirationDate
          id="expirationDate"
          name="expirationDate"
          label="Vencimiento *"
          onLoad={(date) => setExpDate(date)}
          onChange={(date) => setExpDate(date)}
          forwardRef={forwardRef}
        />

        <InformativeReminderDate date={expDate} forwardRef={forwardRef} />

        <Informative label="% Avance" value="25%" />

        <Probability forwardRef={forwardRef} label="% Probabilidad *" />
      </SevenItems>

      <h4 className="mt-3">Importes</h4>
      <SevenItems>
        <Currency
          label="Moneda *"
          id="moneda"
          addTodos = {true}
          name="moneda"
          forwardRef={forwardRef}
          onChange={(value) => {

            
            const lblValue = document.getElementById(
              `moneda${value}`
              ).textContent;
            if(value!=='-1'){
              setCurrencyUsed(lblValue);
            }
            setTypeCurrency(+value);
            setExchangeTcp(tcp.test);
            setCurrencyType(+value);
            setCurrencyName(lblValue);
          }}
        />

          <TCP
            tcp={exchangeTcp}
            label="Tipo de cambio *"
            forwardRef={forwardRef}
          />

        <ImportPrice />

        <IvaPrice />

        <TotalPrice />

        <Informative label="Acreditado" value="$0.0" />

        <Informative label="Saldo" />
      </SevenItems>

      <FourthRow>
        <Informative label="Uso de CFDI" placeholder="NA" />

        <Informative id="payForm" label="Forma pago" placeholder="NA" />

        <Informative id="methodPay" label="Método pago" placeholder="NA" />

        <Informative id="partials" label="Parcialidades" placeholder="NA" />

        <Informative id="partiality" label="Parcialidad" placeholder="NA" />

        <CreditDays
          label="Dias de credito *"
          creditDays={customerInfo?.creditDays}
          forwardRef={forwardRef}
          errors={errors}
        />

        <Informative
          id="isComplementQuote"
          label="Complemento"
          placeholder="No"
        />
      </FourthRow>
    </div>
  );
}
