import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { saveAs } from "file-saver";
import { getCustomerBankState } from "helpers/Apis/Directory";
import { parseDateToTextAndTime } from "helpers/dates";
import React, { useState } from "react";

export default function EstadoDeCuenta({ idCustomer = 0 }) {
  const [state, setState] = useState("none");

  const handleDownload = async () => {
    setState("loading");

    const blobRetrieved = await getCustomerBankState(idCustomer);

    if (blobRetrieved instanceof Blob) {
      saveAs(
        blobRetrieved,
        `Estado de cuenta ${parseDateToTextAndTime(new Date(), "long")}.pdf`
      );
    }

    setState("none");
  };

  return (
    <ButtonV2 onClick={handleDownload} disabled={state === "loading"}>
      <span>Estado de cuenta</span>
      <Icon icon="pdf" />
    </ButtonV2>
  );
}
