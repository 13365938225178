import axios from "axios";
import {
  hideSpinner,
  showSpinner,
} from "../components/individual/Spinner/Spinner";
import { URL_BASE, URL_CLIENT, URL_GRUPO_AMPLUS } from "../routes/routes";
import { Success, Error, closeForm, DisplayError } from "./alerts";
import { isValidHttpResCode } from "./Apis/fetch";
import { getNumberDate } from "./dates";
import { getEnterpriseInfo } from "./Apis/setUp";

//////////////////////////// [VALIDATE SESSION OF USER] //////////////////
export const validateSession = async (dto) => {
  try {
    const response = await fetch(`${URL_BASE}validate-session`, {
      body: JSON.stringify(dto),
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    window.location = URL_CLIENT;
  } catch (error) {
    DisplayError(error);
    throw error;
  }
};

///////////////////////// [PERMISSIONS] ///////////////////////

/**
 * This will get the sections of the system (permissions)
 * @param {number} rolID The number rol of the logged user
 * @returns {Array<object>} Sets of permissions of the logged user
 */
export const userPermissions = async (rolID) => {
  try {
    const { data } = await axios.get(`${URL_BASE}roles/secciones/${rolID}`);
    return data.permissions;
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {number} rolID Rol ID of the selected data
 * @returns {Array<object>} All the permissions of that requested rol
 */
export const getPermissions = async (rolID) => {
  try {
    const { data } = await axios.get(`${URL_BASE}roles/secciones/${rolID}`);
    return data.permissions;
  } catch (error) {
    console.log(error);
  }
};

/* /////////////////////// [USERS] ////////////////////////////////// */

export const fetchUsers = async (
  begin,
  end,
  columnOrdering,
  orderFetch = `DESC`
) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}usuarios/${begin}/${end}/${orderFetch}/${columnOrdering}`
    );
    return data.data[0];
  } catch (error) {
    console.log(`There wan an error: ${error}`);
  }
};

/**
 * Get a list of all the executives of the system
 * @returns {Promise<import("./apisTypes").Users[]>} executives - JSON with the executives of the system
 */
export async function AllExecutives() {
  try {
    const { data } = await axios.get(`${URL_BASE}usuarios/getusuarios`,{ withCredentials:true });

    return data;
  } catch (error) {
    return []
  }
}
export async function AllRoles() {
  console.log("Intentando cargar roles....");
  try {
    console.log("-------Retriving Roles-----------");
    const { data } = await axios.get(`${URL_BASE}roles`);
    console.log(data);
    return data.rols;
  } catch (error) {
    console.log("-------Faild Retriving Roles-----------");
    console.log(error);
  }
}
export async function AddUser(data) {
  console.log("Agregando usuarios...");
  const {
    userName,
    nombre,
    middleName,
    apellidoP,
    apellidoM,
    email,
    roles,
    day,
    month,
    userCreated,
    year,
    jefe,
  } = data;
  try {
    console.log("-------AGREGANDO USUARIO-----------");
    const URL_ADD_USER = `${URL_BASE}usuarios/agregar/${userName}/${nombre}/${middleName}/${apellidoP}/${apellidoM}/${email}/${roles}/${day}/${month}/${userCreated}/${year}/${jefe}`;
    const { data } = await axios.get(URL_ADD_USER);
    return data;
  } catch (error) {
    console.log("-------FALLO AGREGAR USUARIO-----------");
    console.log(error);
    return error;
  }
}

/**
 * Update an user
 * @param {import("pages/ProcesosEspeciales/Usuarios/types").DtoUpdateUser} dto - Data to udpate the user
 * @returns {Promise<boolean>} True if was updated
 */
export async function EditUser(dto) {
  try {
    const res = await fetch(`${URL_BASE}usuarios`, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}

/* ////////////////////////// [ADVERTISEMENTS] //////////////////////////// */

// Fetch the types of warnings
export const fetchTypesNotices = async () => {
  try {
    const { data } = await axios.get(`${URL_BASE}avisos/tipoAvisos`);
    return data.advertisementTypes;
  } catch (error) {
    console.log(error);
  }
};

// fetch the warnings
export const addNotice = async (
  data,
  range,
  nameUser,
  idForm,
  btnClose,
  userId
) => {
  function cbSuccess() {
    document.getElementById(idForm).reset();
    document.getElementById(btnClose).click();
  }

  try {
    const { type, notice } = data;

    const startDate = getNumberDate(range[0]);
    const endDate = getNumberDate(range[1]);

    const formatedStartDate = `${startDate.year}-${startDate.month}-${startDate.day}`;
    const formatedEndDate = `${endDate.year}-${endDate.month}-${endDate.day}`;

    const result = await axios.get(
      `${URL_BASE}avisos/agregar/aviso/${formatedStartDate}/${formatedEndDate}/${notice}/${type}/${nameUser}/${userId}`
    );

    if (result.data.status === 200) {
      Success(cbSuccess, result.data.message);
      return;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Create an advertisement
 * @param {string} startDate - With YYYY-MM-DD format
 * @param {string} endDate - With YYYY-MM-DD format
 * @param {string} description - Message of the advertisement
 * @param {number} type - Type of advertisement
 * @param {number} userId - Id of the user who creates the advertisement
 * @param {string} userName - Name of the user who creates the advertisement
 * @returns {Promise<boolean>}
 */
export async function addAdvertisement(
  startDate,
  endDate,
  description,
  type,
  userId,
  userName
) {
  try {
    const res = await fetch(
      `${URL_BASE}avisos/agregar/aviso/${startDate}/${endDate}/${description}/${type}/${userName}/${userId}`
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update and advertisement
 * @param {number} idAdvertisement - Id of the record to
 * @param {string} startDate - YYYY-MM-DD format
 * @param {string} endDate - YYYY-MM-DD format
 * @param {number} userId - User id
 * @param {number} type - Type of advertisement
 * @param {string} notice - Content
 * @param {string} userName - Username
 * @returns {Promise<boolean>}
 */
export const editAdvertisement = async (
  idAdvertisement,
  startDate,
  endDate,
  userId,
  type,
  notice,
  userName
) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}avisos/editar/aviso/${idAdvertisement}/${startDate}/${endDate}/${userId}/${type}/${notice}/${userName}`
    );

    if (data.status === 200) {
      Success(() => {}, data.message);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Get the advertisments of the day
 * @returns {Promise<import("./apisTypes").Advertisments[]>}
 */
export const advertisementsToday = async () => {
  try {
    /**
     * @type {import("axios").AxiosResponse<import("./apisTypes").AdvertismentsResponse>}
     */
    const { data, status } = await axios.get(`${URL_BASE}avisos/dia`);

    if (status !== 200) {
      Error(() => {},
      "No se pudieron obtener los avisos del sistema, favor de reportar a soporte. Código de error: ca832c6c-20a4-4f66-b552-3eaa444dcaa8");
    }

    return data.advertisements;
  } catch (error) {
    Error(() => {},
    "No se pudieron obtener los avisos del sistema, favor de reportar a soporte. Código de error: ca832c6c-20a4-4f66-b552-3eaa444dcaa8");
  }
};

//////////////////////////// [PROFILE USER] //////////////////////////
export const closeSession = async () => {
  try {
    await axios.delete(`${URL_BASE}usuarios/cerrar/sesion`, {
      withCredentials: true,
    });
    window.location = `${URL_CLIENT}`;
  } catch (error) {}
};

///////////////////////////// [ROLES] /////////////////////////////////

/**
 * Get the skeleton to print
 *
 * @returns {Array<object>} Skeleton of the permissions
 */
export async function skeletonPermissions() {
  try {
    const { data } = await axios.get(`${URL_BASE}roles/secciones`);
    return data.skeleton;
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {Array<object>} permissions Inputs to update on the DB.
 * @returns {Promise<boolean>}
 */
export async function sendPermissions(
  permissions,
  idSpinner,
  idFooterModal,
  idContainerCheck
) {
  const successCallback = () => closeForm("closePermissionsBtn");

  showSpinner(idSpinner);
  hideSpinner(idFooterModal);
  hideSpinner(idContainerCheck);

  try {
    const { data } = await axios.put(`${URL_BASE}roles/permisos`, {
      permissions,
    });

    showSpinner(idFooterModal);
    showSpinner(idContainerCheck);
    hideSpinner(idSpinner);

    if (data.status === 200) {
      Success(successCallback, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    console.log(error);
    Error(() => {}, error);

    return false;
  }
}

/**
 * Save the info rol of the form
 * @param {*} formValues - Schema of the data validated
 * @param {number} userId - ID of the session user
 * @param {string} close - HTML id of the button that must be clicked to close the modal
 * @param {string} idForm - HTML id of the form that will be reseted
 * @param {string} idLbael - HTML id of the label toggle button
 * @returns {boolean} True in case the server respons was true
 */
export async function createRol(
  formValues,
  userId,
  close,
  idForm,
  userCreated,
  labelTxt
) {
  const successCallback = () => closeForm(close, idForm);

  try {
    let { inputDesc } = formValues;
    const swithcActivate = document.getElementById("addRolToggle").checked;
    const lbl = document.getElementById("lblAddRolToggle");

    const request = await axios.get(
      `${URL_BASE}roles/agregar/${inputDesc}/${
        swithcActivate ? 1 : 0
      }/${userId}/${userCreated}`
    );

    if (request.data.status === 200) {
      lbl.innerText = labelTxt;
      Success(successCallback, request.data.message);
      return true;
    }

    Error(() => {}, request.data.message);
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

////////////////////// [DIRECTORY]

export async function getDirectory(
  page,
  order,
  columnOrdering,
  additionalQuery
) {
  try {
    /* const urlFetch = `${URL_BASE}directorio?pagina=${page}&order=${order}`

        const { data } = await axios.get(`http://localhost:4000/api/directorio?tipo=2&estatus=0&columna=customerID&orden=ASC&rango=0&limite=25&ejecutivo=14`);

        return data; */
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the list of type of customers
 * @returns {Array<object>} type-customers - Type of customers on the system
 */
export async function typeCustomers() {
  try {
    const { data } = await axios.get(`${URL_BASE}directorio/tipo-cliente`);

    return data.typeCustomers;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get a list of all the countrys that exist
 * @returns {Array<object>} Countrys - JSON with the countrys
 */
export async function listCountrys() {
  try {
    const { data } = await axios.get(`https://restcountries.eu/rest/v2/all`);

    return data;
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {string} cp - Number of the postal code wanted
 * @returns {Array<object>} Location - JSON with the information of the CP
 */
export async function getCP(cp) {
  try {
    const { data } = await axios.get(
      `https://sepomex.razektheone.com/codigo_postal?cp=${cp}`,
      {
        headers: { Apikey: "456bac4bc7e1c16d13c9ae835f765ffc490606f2" },
      }
    );

    return data;
  } catch (error) {
    console.log(error);

    const { data } = error;

    console.log(data);
    return error;
  }
}

export async function getSession() {
  try {
    await axios.get(`${URL_BASE}usuarios/sesion`, {
      withCredentials: true,
    });
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Check if the instance of Praxia has an active license to use the system
 * @returns {Promise<import("../../../server/models/loginModelTypes").LicensePraxia>}
 */
export async function validateLicense() {
  /**
   * @type {import("../../../server/models/loginModelTypes").LicensePraxia}
   */
  const ERROR = {
    message: `<h2>Lo sentimos, el acceso al sistema está temporalmente bloqueado debido a la falta de pago.</h2>
    <h2>Por favor, póngase en contacto con el administrador del sistema.</h2>
    <h2>Disculpe los inconvenientes.</h2>
    `,
    validLicense: true,
  };

  try {
    const enterpriseInfo = await getEnterpriseInfo();

    if(enterpriseInfo.rfc==="") return ERROR;

    const res = await fetch(
      `${URL_GRUPO_AMPLUS}/no-cors/validar-licencia/${enterpriseInfo.rfc}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const licenseInfo = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return licenseInfo;

    return ERROR;
  } catch (error) {
    return ERROR;
  }
}
