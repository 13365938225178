import { emailRegexExpression } from "Schemas/Contact/contact";
import { ACTIONS } from "./actions";

/**
 * Add the email to the current list
 * @param {string[]} initialEmailList - List of email on the current list
 * @param {string} email - Email to add
 * @returns {string[]}
 */
function addEmailToList(initialEmailList, email) {
  let newEmailList = [...initialEmailList];
  newEmailList.push(email);
  let setEmail = new Set();
  newEmailList.forEach((emailItem) => setEmail.add(emailItem));

  return [...setEmail];
}

export const INITIAL_STATE = {
  to: [],
  cc: [],
  subject: null,
  message: null,
  isSendingEmail: false,
  isPerformingPreload: true,
  isPerformingPostreload: false,
};

/**
 * Update the email state
 * @param {import("./types").EmailStateI} state - State
 * @param {import("./types").Action} action - Action
 */
export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_TO:
      return {
        ...state,
        to: action.payload,
      };

    case ACTIONS.SET_CC:
      return {
        ...state,
        cc: action.payload,
      };

    case ACTIONS.SET_SUBJECT:
      return {
        ...state,
        subject: action.payload,
      };

    case ACTIONS.DELETE_TO:
      return {
        ...state,
        to: state.to.filter((email, i) => i !== action.payload),
      };

    case ACTIONS.SET_INITIAL_STATE :
      return {
        ...state,
        ...INITIAL_STATE
      }

    case ACTIONS.SET_LOADED_FILES:
      return {
        ...state,
        isPerformingPreload: false,
      };

    case ACTIONS.SET_SENDING_EMAIL:
      return {
        ...state,
        isSendingEmail: action.payload,
      };
    case ACTIONS.DELETE_CC:
      return {
        ...state,
        cc: state.cc.filter((email, i) => i !== action.payload),
      };
    case ACTIONS.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
}
