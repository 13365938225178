import React, { useContext } from "react";
import style from "../styles.module.scss";
import { ContextContact } from "..";
import { Search } from "components/individual/Search";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Select } from "components/individual/inputs/Inputs";
import { Label } from "structure/Document";
import Add from "../Add";
import Icon from "components/individual/HeaderIcon";
import ContactsReport from "components/general/ContactsReport";

export default function SearchContacts() {
  const hook = useContext(ContextContact);

  return (
    <div className={style.search}>
      <div>
        <Label>Estatus</Label>

        <Select
          onChange={hook.updateStatus}
          addTodos={false}
          valueJSON="valueJSON"
          textJSON="textJSON"
          options={[
            {
              valueJSON: null,
              textJSON: "Todos",
            },
            {
              valueJSON: false,
              textJSON: "Inactivos",
            },
            {
              valueJSON: true,
              textJSON: "Activos",
            },
          ]}
        />
      </div>

      <div>
        <Label>Búsqueda</Label>
        <Search
          onSearch={hook.refetchContacts}
          placeholder="Nombre contacto"
          value={hook.search}
          onChange={hook.updateSearchValue}
          hideSearchIcon={false}
          onDelete={() => hook.updateSearchValue("")}
        />
      </div>
      <ButtonV2 onClick={hook.refetchContacts}>
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>

      <ContactsReport>
        <ButtonV2>
          <span>Excel</span>
        </ButtonV2>
      </ContactsReport>

      <Add />
    </div>
  );
}
