import { getCurrency } from "helpers/Apis/Banks";
import { useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner";
import { Select } from "./Inputs";
import LoadingText from "../TextLoading";
import React from "react";

/**
 * Currency combo options
 * @param {import("./typesCurrency").PropsCurrency} props - Props
 * @returns {JSX.Element} Currency combo
 */
export default function Currency({
  selected = null,
  onChange = () => {},
  forwardRef = {},
  includeAll = false,
}) {
  const [state, setState] = useState({
    isLoading: true,
    currencys: [],
    selected: {},
  });

  useEffect(() => {
    (async function () {
      const currencys = await getCurrency();

      const parsedCurrencys = currencys.map((currency) => ({
        id: currency.currencyID,
        code: currency.code,
      }));

      let currencysToUse = [...parsedCurrencys];

      if (includeAll) {
        currencysToUse = [{ id: 0, code: "Todos" }, ...currencysToUse];
      }

      setState({
        ...state,
        isLoading: false,
        currencys: currencysToUse,
      });
    })();
  }, []);

  const handleSelection = (currency) => onChange(currency);

  return state.isLoading ? (
    <Spinner
      idSpinner="loadingCurrencys"
      hidden={false}
      text={<LoadingText>Cargando moneda</LoadingText>}
    />
  ) : (
    <Select
      idSelect={window.crypto.randomUUID()}
      options={state.currencys}
      forwardRef={forwardRef}
      nameSelect="currency"
      valueJSON="code"
      textJSON="code"
      addTodos={true}
      value={selected}
      onChange={handleSelection}
      initialOption="-- SELECCIONA --"
    />
  );
}
