import React from "react";
import { Label } from "structure/Document";
import useInvoiceParameters from "./useInvoiceParameters";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import style from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import { InputCurrency } from "components/individual/inputs/Inputs";
import InformativeTooltip from "components/individual/InfoTooltip";

export default function InvoiceParameters() {
  const hook = useInvoiceParameters();

  if (hook.isLoading)
    return (
      <div className={style.containerForm}>
        <form className={style.form}>
          <Spinner
            hidden={false}
            idSpinner="loadingInvoiceParameters"
            text="Cargando"
          />
        </form>
      </div>
    );

  return (
    <div className={style.containerForm}>
      <form
        className={style.form}
        onSubmit={hook.form.handleSubmit(hook.attemptUpdate, hook.printErrors)}
        noValidate={true}
      >
        <Label>
          Parcialidades autorizadas
          <InformativeTooltip>
            <p>
              Autorizaciones permitidas en el timbre para usuarios que tienen un
              jefe directo
            </p>
          </InformativeTooltip>
        </Label>
        <input
          type="number"
          min={1}
          name="partialitiesAllowed"
          ref={hook.form.register}
        />
        {hook.form.errors?.partialitiesAllowed?.message ? (
          <p className="text-danger font-weight-bold">
            {hook.form.errors.partialitiesAllowed.message}
          </p>
        ) : null}

        <Label>
          Tolerancia tipo de cambio{" "}
          <InformativeTooltip>
            <p>
              Tolerancia permitida en el TC del timbrado para usuarios que
              tienen un jefe directo
            </p>
          </InformativeTooltip>{" "}
        </Label>
        <InputCurrency
          defaultValue={hook.tcTolerance}
          placeholder={"Escribe aqui"}
          onChange={hook.updateTolerance}
        />

        {hook.form.errors?.tcTolerance?.message ? (
          <p className="text-danger font-weight-bold">
            {hook.form.errors.tcTolerance.message}
          </p>
        ) : null}

        {hook.isUpdating ? (
          <Spinner
            hidden={false}
            idSpinner="updatingParametersInvoice"
            text="Actualizando"
          />
        ) : (
          <ButtonV2 type="submit">Actualizar</ButtonV2>
        )}
      </form>
    </div>
  );
}
