import React, { useContext, useState } from "react";
import { Select } from "components/individual/inputs/Inputs";
import MovementsContext from "context/Movements/MovementsContext";

export default function StatusMovement() {
  const { filterMovements, setStatus } = useContext(MovementsContext);

  const [options, setOptions] = useState([
    {
      label: "Todos",
      id: -1,
    },
    {
      label: "No asociados",
      id: 1,
    },
    {
      label: "Asociados",
      id: 3,
    },
    {
      label: "Concialiados",
      id: 4,
    },
    {
      label: "Inactivos",
      id: 5,
    },
  ]);

  return (
    <Select
      addTodos={false}
      className="ml-2"
      initialOption="Todos"
      idSelect="statusFilter"
      nameSelect="statusFilter"
      label="Estatus"
      options={options}
      value={filterMovements.status}
      textJSON="label"
      valueJSON="id"
      onChange={(value) => setStatus(+value)}
    />
  );
}
