import { useEffect, useRef, useState } from "react";
import paretoApi from "helpers/Apis/purchases/pareto";
import { saveAs } from "file-saver";
import { parseDateToText, parseDateToTime } from "helpers/dates";

/**
 * @type {import("./types").StatePareto}
 */
const INITIAL_STATE = {
  isLoading: true,
  isDownloadingExcel: false,
  currency: "MXN",
  base: {
    label: "Margen",
    id: 2,
  },
  data: [],
  trigger: false,
};

/**
 * Hook to handle the pareto report
 * @returns {import("./types").ReturnUsePareto}
 */
export default function usePareto() {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("react").MutableRefObject<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>>}
   */
  const form = useRef(null);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        data: [],
      }));

      const data = await paretoApi.report({
        base: state.base.id,
        currency: state.currency,
      });

      const dataToUse = data instanceof Blob ? [] : data;

      setState((current) => ({
        ...current,
        isLoading: false,
        data: dataToUse,
      }));
    })();
  }, [state.trigger, state.currency, state.base]);

  function setBase(base) {
    setState((current) => ({
      ...current,
      base,
    }));
  }
  /**
   *
   * @param {import("customHooks/useContract/types").Currency} code - Currency code
   */
  function setCurrency(code) {
    setState((current) => ({
      ...current,
      currency: code,
    }));
  }

  function attemptSearch(e = null) {
    setState((current) => ({
      ...current,
      trigger: !current.trigger,
    }));
  }

  /**
   * @type {import("components/individual/PillFilterMobile/types").PropsPillFilterMobile[]}
   */
  const pills = [
    {
      label: "Moneda",
      value: state.currency,
    },
    {
      label: "Base",
      value: state.base.label,
    },
  ];

  async function attemptDownloadExcel() {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const excelFile = await paretoApi.report({
      base: state.base.id,
      currency: state.currency,
      getAsExcel: true,
    });

    if (excelFile instanceof Blob) {
      saveAs(
        excelFile,
        `Reporte Pareto ${state.base.label} ${
          state.currency
        } (${parseDateToText(new Date())} ${parseDateToTime(new Date())}).xlsx`
      );
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  }

  return {
    ...state,
    setBase,
    form,
    pills,
    attemptDownloadExcel,
    attemptSearch,
    setCurrency,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/",
        text: "Ventas",
      },
      {
        route: "/",
        text: "Reportes",
      },
      {
        route: "/",
        text: "Pareto",
      },
    ],
  };
}
