import React from "react";
import { HederContainer } from "../Header/Containers";
import { InfoSection } from "../Header/InfoSection";
import { ConceptsSection } from "../DocumentConcepts/ConceptsSection";
import ui from "./styles.module.scss";

export const HeaderComponent = () => {
  return (
    <div className={ui.customerHeader}>
      <HederContainer>
        <InfoSection />
      </HederContainer>
      <ConceptsSection />
    </div>
  );
};
