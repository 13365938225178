import {
  DescriptionContainer,
  DescriptionText,
} from "components/Sections/Documents/Items/Containers";
import React from "react";

export const ItemsDescription = ({ description }) => {
  return (
    <DescriptionContainer>
      <DescriptionText>{description}</DescriptionText>
    </DescriptionContainer>
  );
};
