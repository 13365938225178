import React, { createContext } from "react";
import ExecutivesV2 from "components/individual/ExecutivesV2";
import ExchangeDirectory, { GridExchange } from "structure/ExchangeDirectory";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Overview from "./Overview";
import useExchangeDocuments from "customHooks/useExchangeDocuments";
import { Spinner } from "components/individual/Spinner/Spinner";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("react").Context<import("./types").StateExchangeDocuments>}
 */
export const ContextModalExchangeDocuments = createContext({
  executive: {
    id: 0,
    name: "",
  },
  grid: [],
  isOpen: false,
  isUpdating: false,
});

/**
 * Render a clicklable item to open the modal
 * @param {import("./types").PropsModalExchangeDocuments} props - Props
 * @returns {JSX.Element}
 */
export default function ModalExchangeDocuments({ children = <></> }) {
  const {
    state,
    attemptExchangeCustomers,
    dialog,
    setExecutive,
    setGrid,
    setIsOpen,
  } = useExchangeDocuments();

  const { checkPermission } = usePermissions();

  if(!checkPermission('e4453f4e-affc-4c73-b345-478e359f9fe8',false)) return <></>

  return (
    <ContextModalExchangeDocuments.Provider value={state}>
      <div onClick={() => setIsOpen(true)}>{children}</div>

      {state.isOpen ? (
        <Dialog
          footer={
            <div className={ui.footer}>
              <Overview />
              {state.isUpdating ? (
                <Spinner text={"Actualizando cambios"} hidden={false} />
              ) : (
                <ButtonV2
                  onClick={attemptExchangeCustomers}
                  disabled={state.grid.length <= 0}
                >
                  <span>Aplicar cambios</span>
                </ButtonV2>
              )}
            </div>
          }
          title="Cambio de documentos"
          forwardRef={dialog.refDialog}
          closeCallback={() => setIsOpen(false)}
          width={state.executive.id === 0 ? 30 : 85}
        >
          <div className={ui.bodyModal}>
            <ExecutivesV2 onChange={(data) => setExecutive(data)} />
            <ExchangeDirectory id={state.executive.id} onChange={setGrid}>
              <GridExchange />
            </ExchangeDirectory>
          </div>
        </Dialog>
      ) : null}
    </ContextModalExchangeDocuments.Provider>
  );
}
