import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import AbcBank from "structure/Menu/Banks/AbcBank";
import BankAccountReport from "structure/Menu/Banks/Reports/BankAccountReport";
import TransferMenu from "structure/Menu/Banks/Transfer";

export default function ScreenSells() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "ventas") return <></>;

  return (
    <>
    <NavigationItem icon="home" label="Inicio > Ventas" subMenu="general"/>
      <NavigationItem icon="document" label="Contratos" link="/ventas/contratos"/>
      <NavigationItem icon="search" label="Cuotas" link="/ventas/cuotas"/>
      <NavigationItem icon="document" label="Facturas emitidas" link="/administracion/facturas-emitidas" />
      <NavigationItem icon="document" label="Notas de crédito emitidas" link="/ventas/notas-de-credito" />
      <NavigationItem icon="document" label="Pedidos" link="/administracion/pedidos?from=pedidos" />
      <NavigationItem icon="reports" label="Reportes" subMenu="ventasReportes"/>
    </>
  );
}
