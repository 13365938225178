import React, { useContext } from "react";
import { MobileMenuContext } from "..";
import style from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import Banner from "structure/Banner";
import { closeSession } from "helpers/apis";
import PushNotifications from "../../PushNotifications";
import Profile from "../../Profile";

export default function ProfileShortcut() {
  const hook = useContext(MobileMenuContext);

  return (
    <div className={style.profileInfo}>
      <Profile />

      <div>
        <Banner>
          <Icon icon="megaphone" className={style.actionProfile} />
        </Banner>

        <PushNotifications onClosed={() => hook.closeMenu()} />

        <div onClick={hook.navigateToDo}>
          <Icon icon="bell" className={style.actionProfile} />
        </div>

        <div onClick={closeSession}>
          <Icon className={style.actionProfile} icon="signtOut" />
        </div>
      </div>
    </div>
  );
}
