import React, { useContext } from "react";

/* CONTEXT */
import LoginContext from "../../../context/Login/LoginContext";

/* COMPONENTS */
import { InputUser } from "../../individual/inputs/Inputs";
import { Submit } from "../../individual/buttons/Buttons";
import { Spinner } from "../../individual/Spinner/Spinner";
import AvailableBrowsers from "pages/Login/AvailableBrowsers";
import LoadingText from "components/individual/TextLoading";
import PasswordV2 from "../PasswordV2";
import ui from "./styles.module.scss";

function CardLogin() {
  const { loginV2, changeView, isFetchingServer } = useContext(LoginContext);
  const spinnerLogin = "spinnerLogin";

  return (
    <div className="loginForm">
      <form
        onSubmit={(e) => loginV2(e, "inputUser", "inputPass", spinnerLogin)}
      >
        <InputUser
          labelTxt="Usuario"
          placeholderTxt="Correo o usuario"
          aditionalClasses=""
          name="user"
          id="inputUser"
        />

        <PasswordV2 placeholder="Escribe aquí" name="pass" id="inputPass" />

        <Spinner
          hidden={!isFetchingServer}
          text={<LoadingText>Iniciando sesión</LoadingText>}
        />

        <Submit
          text="Iniciar sesión"
          aditionalClasses="text-center"
          id="btnLogin"
        />

        <p
          className={ui.forgotPassword}
          onClick={() => changeView("recover-password")}
        >
          ¿Olvidaste tu contraseña?
        </p>

        <AvailableBrowsers />

        {/* {!isFetchingServer ? (
          <>
            <Submit
              text="Iniciar sesión"
              aditionalClasses="text-center"
              id="btnLogin"
            />

            <p className={ui.forgotPassword} onClick={() => changeView("recover-password")}>
              ¿Olvidaste tu contraseña?
            </p>

            <AvailableBrowsers />
          </>
        ) : null} */}
      </form>
    </div>
  );
}

export default CardLogin;
