import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import useDialog from "customHooks/useDialog";
import { getTodoThread } from "helpers/Apis/todo";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Dialog from "structure/Dialog";

/**
 * @type {import("../types").StateHistory}
 */
const initialState = {
  isLoading: true,
  list: [],
  isOpen: false,
};

/**
 * Open a modal in order to see the history of the of the main thread to dos
 * @param {object} props - Props
 * @param {import("customHooks/useToDo/types").ToDosI} props.todo - Information of the todo
 * @param {JSX.Element} [props.children=<></>] - HTML Element to render
 * @param {import("react").MutableRefObject<HTMLElement>|undefined} [props.forwardRef=undefined] - Dom ref
 * @returns {JSX.Element} Modal with the the history of the main todo thread
 */
export default function History({
  todo,
  children = <></>,
  forwardRef = undefined,
}) {
  const [thread, setThread] = useState(initialState);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (thread.isOpen === false) return;

      const apiHistory = await getTodoThread(todo.parent);
      setThread({
        ...thread,
        isLoading: false,
        list: apiHistory,
      });
    })();
  }, [thread.isOpen]);

  const open = () => {
    dialog.open();
    setThread((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const close = () => {
    dialog.close();
    setThread((current) => ({
      ...current,
      isOpen: false,
    }));
  };

  return (
    <div>
      <div ref={forwardRef} onClick={open}>
        {children}
      </div>
      <Dialog
        title={`${todo.title} | #${todo.tag.description}`}
        forwardRef={dialog.refDialog}
        closeCallback={close}
        width={50}
      >
        <div className="p-3">
          {thread.isLoading ? (
            <Spinner hidden={false} text="Cargando historial..." />
          ) : thread.list.length <= 0 ? (
            <h1 className="text-center">No hay historial</h1>
          ) : (
            thread.list.map((thread, i) => (
              <div key={`${key.current}-${i}`}>
                {i !== 0 ? <hr style={{borderColor:"var(--primaryColor)"}}/> : null}
                <time
                  style={{ color: "var(--primaryColor)" , fontWeight:"bold" }}
                  dateTime={thread.created.fullTime}
                >
                  {new Date(thread.created.fullTime).toLocaleString("es-MX", {
                    timeStyle: "short",
                    dateStyle: "long",
                    hour12: true,
                  })}
                </time>
                <ReactMarkdown
                  key={window.crypto.randomUUID()}
                  rehypePlugins={[rehypeRaw]}
                  children={thread.todoNote}
                />
              </div>
            ))
          )}
        </div>
      </Dialog>
    </div>
  );
}