import React, { useContext } from "react";
import styles from "./index.module.scss";

// COMPONENTS
import ProfileUser from "./ProfileUser";
import { LoadingComponent as SplashScreen } from "../../individual/Loader/LoadingComponent";
import SessionExpire from "../../individual/Modals/SessionExpire";

// CONTEXT
import UserContext from "../../../context/Login/LoginContext";
import useHeader from "customHooks/useHeader";
import Banner from "structure/Banner";
import Mobile from "./Mobile";
import useBannerPraxia from "pages/ProcesosEspeciales/Parameters/BannerPraxia/useBannerPraxia";

export default function Header() {
  const { userInfo } = useContext(UserContext);

  const banner = useBannerPraxia();

  const { showScreen } = useHeader();

  if (showScreen) return <SplashScreen />;

  if (userInfo === undefined || userInfo === null) return <></>;

  if (userInfo.length <= 0) return <></>;

  return (
    <>
      <Mobile />

      <header className={styles.headerContainer}>
        
        <picture className={styles.containerLogo}>
          <source
            media="(prefers-color-scheme: dark)"
            srcSet={banner.darkMode}
            className={styles.logo}
          />
          <img src={banner.lightMode} alt="logotipo_empresa" className={styles.logo}/>
        </picture>
        <div className={styles.containerBanner}>
          <Banner />
        </div>
        <ProfileUser />
      </header>
    </>
  );
}
