import React, { useEffect, useRef, useState } from "react";
import useConcepts from "customHooks/useConcepts/index";
import scss from "../styles.module.scss";
import scssConcepts from "./styles.module.scss";
import ConceptImport from "./ConceptDescription";
import ConceptInput from "./ConceptInput";
import Table from "components/general/Table";
import { Search } from "components/individual/Search";
import Fuse from "fuse.js";

/**
 * Render a table grid to associate the amounts for the concepts
 * @param {import("react-data-table-component").TableProps<import("types/typedef/customHooks/useConcepts").ConceptI>} props
 */
function TableGrid(props) {
  return <Table {...props} />;
}

export default function IncomingsConcepts() {
  const hookConcepts = useConcepts("ingresos");

  const key = useRef(`${window.crypto.randomUUID()}`);

  const [fuse, setFuse] = useState(
    new Fuse(hookConcepts.incomingsFiltered, {
      keys: ["description"],
      threshold:0.1,
      distance:200
    })
  );

  const [filteredConcepts, setFilteredConcepts] = useState({
    concept: hookConcepts.incomingsFiltered,
    search: "",
  });

  useEffect(() => {
    setFilteredConcepts((current) => ({
      ...current,
      concept: hookConcepts.incomingsFiltered,
    }));
    setFuse(
      new Fuse(hookConcepts.incomingsFiltered, {
        keys: ["description"],
        threshold:0.1,
        distance:200
      })
    );
  }, [hookConcepts.concepts]);

  const setSearch = (search) =>
    setFilteredConcepts((current) => ({
      ...current,
      search,
    }));

  const attemptFilter = () => {
    if (filteredConcepts.search === "") {
      setFilteredConcepts((current) => ({
        ...current,
        concept: hookConcepts.incomingsFiltered,
      }));
      return;
    }

    const concepts = fuse.search(filteredConcepts.search);

    const concept = concepts.map((data) => data.item);

    setFilteredConcepts((current) => ({
      ...current,
      concept,
    }));
  };

  return (
    <div>
      <div className="my-3">
        <b>Concepto</b>
        <Search
          placeholder="Escribe aquí (opcional)"
          onChange={setSearch}
          onDelete={setSearch}
          onSearch={attemptFilter}
        />
      </div>
      <TableGrid
        data={filteredConcepts.concept}
        pagination={false}
        sortServer={false}
        columns={[
          {
            name: "Concepto",
            selector: (item) => item.description,
            sortable: true,
            width:"30%"
          },
          {
            name:'Tipo',
            selector:item=>item.type.description,
            sortable:true,
            width:"30%"
          },
          {
            name: "Deducible",
            selector: (item) => <ConceptImport {...item} />,
            sortable: false,
            width:"20%"
          },
          {
            name: "Sin comprobante",
            selector: (item) => <ConceptInput {...item} />,
            sortable: false,
            width:"20%"
          },
        ]}
      />
    </div>
  );

  return (
    <div className={scssConcepts.containerConcepts}>
      <div className={scss.headerConcept}>
        <p>Concepto</p>
        <p>Deducible</p>
        <p>Sin comprobante</p>
      </div>
      {hookConcepts.incomingsFiltered.map((concept, i) => (
        <div className={scss.rowConcept} key={`${key.current}-${i}`}>
          <p>{concept.description}</p>
          <ConceptImport {...concept} />
          <ConceptInput {...concept} />
        </div>
      ))}
    </div>
  );
}
