import { useState, useEffect, useContext } from "react";
import { DefaultTable } from "js-smart-table/dist/pagination";
import { useParams } from "react-router-dom";

import { ProvidersWithCxP } from "static/StaticTables/Banks/ProvidersWithCxP";
import {
  getBankAccount,
  getMovement,
  getProvidersWithCXP,
} from "helpers/Apis/Banks";
import { GetCustomer } from "helpers/Apis/Directory";

/**
 * State for the invoice reception
 * @type {import("types/typedef/customHooks/useProvidersWhitCxP").ProvidersWithCxpI}
 */
const initialState = {
  search: "-1",
  status: "-1",
  isLoadingTable: true,
  Table: null,
  refetchTable: false,
  documents: [],
  step: 1,
  bankAccount: null,
};

export default function useProvidersWhitCxP(moventId) {
  const [state, setState] = useState(initialState);

  /**
   * Information of the bank account
   * @type {[import("types/typedef/banks").BankAccountI,()=>void]}
   */
  const [bankAccount, setBankAccount] = useState(null);

  const { account, movement } = useParams();

  /**
   * State of the info row selected
   * @type {[import("types/typedef/customHooks/useInvoiceReception").InfoRowI,()=>void]}
   */
  const [infoRow, setInfoRow] = useState(null);

  let TableConfig = { ...ProvidersWithCxP };
  let Table;

  /**
   * Set the step for the association
   * @param {number} step - Step to display for the grid association
   * @returns {void}
   */
  const setStep = (step) =>
    setState({
      ...state,
      step,
    });

  /**
   * Update the search query param to use on the fetch information
   * @param {string|null} search - Search input to search on the table
   * @returns {void}
   */
  const updateQuerySearch = (search) =>
    setState({
      ...state,
      search,
    });

  const refetchTable = () =>
    setState({
      ...state,
      refetchTable: !state.refetchTable,
    });

  const setPaginationState = (documents) =>
    setState({
      ...state,
      isLoadingTable: false,
      documents,
    });

  /**
   * Update the status of the filter
   * @param {number|"-1"} status - New status to use on the combo
   * @returns {void}
   */
  const updateFilterStatus = (status) => {
    setState({
      ...state,
      status,
    });
  };

  useEffect(() => {
    // Load the table
    (async function () {
      setState({
        ...state,
        documents: [],
        Table: null,
        isLoadingTable: true,
        step: 1,
      });
      setInfoRow(null);

      const { data, page, totalPages } = await getProvidersWithCXP(
        2,
        4,
        1,
        state.search
      );

      TableConfig = {
        ...TableConfig,
        rows: data,
        actualPage: page,
        pages: totalPages,

        paginationFn: async function (page) {
          setState({
            ...state,
            infoRow: null,
            isLoadingTable: true,
            documents: [],
          });
          setInfoRow(null);
          const {
            data,
            page: actualPage,
            totalPages,
          } = await getProvidersWithCXP(2, 4, page, state.search);

          setPaginationState(data);

          return {
            data,
            actualPage: actualPage,
            pages: totalPages,
          };
        },
        cbSelection: (e) => setInfoRow(Table.infoRow),
      };

      Table = new DefaultTable(TableConfig);

      setState({
        ...state,
        documents: data,
        Table: Table,
        isLoadingTable: false,
        infoRow: null,
      });
    })();
  }, [state.refetchTable]);

  useEffect(() => {
    (async function () {
      const [bankAccountApi, apiMovement] = await Promise.all([
        getBankAccount(+account),
        getMovement(+movement),
      ]);

      setBankAccount(bankAccountApi);

      if (apiMovement.customerAssociated !== null) {
        const customer = await GetCustomer(apiMovement.customerAssociated);

        setInfoRow({
          ...apiMovement,
          id: apiMovement.customerAssociated,
          comertialName: customer.name.commercial,
        });
        setStep(2);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      state.isLoadingTable ||
      state.Table === null ||
      state.documents.length <= 0 ||
      Table === null
    ) {
      return;
    }

    state.Table.printTable();
    state.Table.printPagination();
  }, [state.isLoadingTable, state.Table, state.refetchTable]);

  return {
    updateQuerySearch,
    refetchTable,
    state,
    updateFilterStatus,
    wasFounded: state.documents.length <= 0 ? false : true,
    infoRow,
    setStep,
    step: state.step,
    isCancelling: state.isCancelling,
    currencyAccount: state.bankAccount?.currencyCode,
    bankAccount: bankAccount,
    breadCrumPath: [
      {
        route: `/inicio`,
        text: "Inicio",
      },

      {
        route: `/bancos`,
        text: "Bancos",
      },
      {
        route: `/bancos/movimientos/${moventId}`,
        text: "Movimientos",
      },
      {
        route: ``,
        text: "Asociar",
      },
    ],
  };
}
