import BankSocialReason from "components/individual/BankSocialReason";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { BankAccountContext } from "..";

export default function SocialReason() {
  const { isValidAccount, updateSocialReason, id, account } =
    useContext(BankAccountContext);

  return (
    <>
      <Label required={!isValidAccount}>Razón social</Label>
      {id === null ? (
        <>
          <BankSocialReason
            onChange={(data) => {
              const isNew = Object.keys(data).includes("__isNew__");
              const socialReason = isNew ? data.label : data.socialReason;
              const id = isNew ? null : data.id;
              const shortName = isNew ? data.label : data.shortName;
              const clave = isNew ? "999" : data.claveSat;

              updateSocialReason(socialReason, id, shortName, clave);
            }}
          />
        </>
      ) : (
        <p>{account.bank.shortName}</p>
      )}
    </>
  );
}
