import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCancelInvoice } from "..";
import style from "./styles.module.scss";

export default function CancelationMotive() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.dtoCancellation === undefined) return <></>;

  return (
    <div>
      <Label required={hook.dtoCancellation.motive.length <= 0}>
        Comentarios
        <span className={style.charactes}>
          {hook.dtoCancellation.motive.length}/256
        </span>
      </Label>
      <textarea
        maxLength={256}
        value={hook.dtoCancellation.motive}
        onChange={(e) => hook.updateMotiveCancellation(e.target.value)}
      ></textarea>
    </div>
  );
}
