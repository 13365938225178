import React, { useContext, useRef } from "react";
import { ContextHistoricalContracts } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { parseDateToText, getDateFromUtc } from "helpers/dates";
import { mnCurrency } from "helpers/money";

export default function Table() {
  const hook = useContext(ContextHistoricalContracts);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
    );

  if (hook.historicContracts.length <= 0)
    return <h1 className="text-center m-5">No hay historico de contratos</h1>;

  return (
    <div style={{ overflow: "auto" }}>
      <div className={style.tableHistoricalContracts}>
        <div className={style.header}>
          <p>No. contrato</p>
          <p>Razón social</p>
          <p>UEN</p>
          <div className={style.dateColumn}>
            <p></p>
            <p>Inicio</p>
            <p>Recordatorio</p>
            <p>Fin</p>
          </div>
          <div className={style.compoundHeader}>
            <p>Importe sin IVA</p>
            <p>USD</p>
            <p>MXN</p>
          </div>
          <p>Ejecutivo</p>
          <p>Estatus</p>
          <p>Cantidad</p>
          <p>Producto</p>
        </div>

        <div className={style.contentRows}>
          {hook.historicContracts.map((contract, i) => (
            <div key={`${key.current}-${i}`}>
              <div className={style.row}>
                <p className="text-right">{contract.documentNumber}</p>
                <p>{contract.socialReason}</p>
                <p>
                  {contract.uen.map((itemUen, itItem) => (
                    <span
                      key={`${key.current}-uen-${itItem}-${contract.documentNumber}`}
                    >
                      {itemUen.description}
                    </span>
                  ))}
                </p>
                <div className={style.dateColumn}>
                  <time className="text-center">
                    {parseDateToText(getDateFromUtc(contract.beginDate))}
                  </time>
                  <time className="text-center">
                    {parseDateToText(getDateFromUtc(contract.reminderDate))}
                  </time>
                  <time className="text-center">
                    {parseDateToText(getDateFromUtc(contract.endDate))}
                  </time>
                </div>
                <div className={style.compoundCell}>
                  <p className="text-right">
                    {contract.currency === "USD"
                      ? mnCurrency(contract.subTotal)
                      : ""}
                  </p>
                  <p className="text-right">
                    {contract.currency === "MXN"
                      ? mnCurrency(contract.subTotal)
                      : ""}
                  </p>
                </div>
                <p>{contract.executive}</p>
                <p>{contract.status}</p>
                <p className="text-center">-</p>
                <p className="text-center">-</p>
              </div>

              {contract.products.map((product, j) => (
                <div
                  key={`${key.current}-product-with-id-${i}${j}`}
                  className={style.row}
                >
                  <p></p>
                  <p></p>
                  <p></p>
                  <div className={style.compoundCell}>
                    <p></p>
                    <p></p>
                  </div>
                  <div className={style.compoundCell}>
                    <p></p>
                    <p></p>
                  </div>
                  <p></p>
                  <p></p>
                  <p className="text-right">{product.quantity}</p>
                  <p>{product.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
