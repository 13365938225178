import React from "react";
import { MenuComponent } from "./Components/MenuComponent";
import { TabComponent } from "./Components/TabComponent";
import {
  BodyContrainer,
  BottomContainer,
  LeftContainer,
  RightContainer,
  TopContainer,
  WinQuoteContainer,
} from "./Containers";
import { HeaderComponent } from "./Components/HeaderComponent";
import { FooterComponent } from "./Components/FooterComponent";
import WinQuoteContext from "./ContextWinQuote";
import { useWinQuote } from "./Hooks/useWinQuote";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { WinQuoteButton } from "./Components/WinQuoteButton";

const { Provider } = WinQuoteContext;

/**
 * 
 * @param {{customerId:number,customerInfo:import("types/typedef/customer").CustomerI}} { customerId,customerInfo} 
 * @returns 
 */
export const WinQuoteV3 = ({ customerId, customerInfo}) => {
  /**
   * @type{import("../../../../../types/winQuote").WinQuoteReturnI}
   */
  const { winQuote, functions } = useWinQuote(customerId, customerInfo);

  return (
    <Provider
      value={{
        ...winQuote,
        ...functions,
      }}
    >
      <WinQuoteContainer>
        <TopContainer>
          <HeaderComponent />
          <WinQuoteButton />
        </TopContainer>
        <BodyContrainer>
          <LeftContainer>
            <MenuComponent />
          </LeftContainer>
          <RightContainer>
            <TabComponent />
          </RightContainer>
        </BodyContrainer>
        <BottomContainer>
          <FooterComponent />
        </BottomContainer>
      </WinQuoteContainer>
    </Provider>
  );
};
