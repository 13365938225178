import React, { useContext, useRef } from "react";
import { ContextCatalogueAdmin } from "..";
import style from "../styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { mnCurrency } from "helpers/money";
import { Pagination } from "components/individual/Pagination";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";

export default function TableCatalogue() {
  const hook = useContext(ContextCatalogueAdmin);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <div className={style.loading}>
        <Spinner
          text={<LoadingText>Cargando catalogo</LoadingText>}
          hidden={false}
        />
      </div>
    );

  if (hook.products.length <= 0)
    return (
      <h1 className="text-center">
        No hay productos con la búsqueda solicitada{" "}
      </h1>
    );

  return (
    <>
      <Dialog
        width={35}
        title="Más información"
        forwardRef={hook.moreInfoDialog.refDialog}
        closeCallback={hook.moreInfoDialog.close}
      >
        {hook.product !== null ? (
          <div>
            <Label>Descripción</Label>
            <p>{hook.product.description}</p>

            <Label>Costo unitario</Label>
            <p>{mnCurrency(hook.product.unitCost)}</p>

            <Label>Precio unitario</Label>
            <p>{mnCurrency(hook.product.unitPrice)}</p>

            <Label>SKU</Label>
            <p>{hook.product.sku}</p>

            <Label>Moneda</Label>
            <p>{hook.product.currency}</p>

            <Label>UEN</Label>
            <p>{hook.product.uenDescription}</p>

            <Label>Código SAT</Label>
            <p>
              {hook.product.satCode} - {hook.product.satCodeDescription}
            </p>

            <Label>UM SAT</Label>
            <p>
              {hook.product.satUm} - {hook.product.satUmDescription}
            </p>

            <Label>IVA</Label>
            <p>{hook.product.iva}%</p>

            <Label>IVA Excento</Label>
            <p>{hook.product.isIvaExempt ? 'Si' : 'No'}</p>
          </div>
        ) : null}
      </Dialog>

      {hook.products.map((product, i) => (
        <div key={`${key.current}-${i}-mobile`} className={style.mobileProduct}>
          <div className="d-flex justify-content-between">
            <div>
              <Label>Descripción</Label>
              <p>{product.description}</p>
            </div>

            <ElipsisMobile onShow={() => hook.setProduct(product)}>
              <CustomMenuItem onClick={() => hook.moreInfoDialog.open()}>
                Más información
              </CustomMenuItem>
              <CustomMenuItem onClick={() => hook.customShowEdit(true)}>
                Editar
              </CustomMenuItem>
            </ElipsisMobile>
          </div>

          <div className="d-flex justify-content-between">
            <div>
              <Label>Moneda</Label>
              <p>{product.currency}</p>
            </div>
            <div>
              <Label>Costo</Label>
              <p>{mnCurrency(product.unitCost)}</p>
            </div>
            <div>
              <Label>Precio</Label>
              <p>{mnCurrency(product.unitPrice)}</p>
            </div>
          </div>
        </div>
      ))}

      <div className={style.mobilePagination}>
        <Pagination
          actualPage={hook.currentPage}
          pages={hook.pages}
          onChange={hook.setPage}
        />
      </div>

      <div className={style.table}>
        <div className={style.header}>
          <p>ID</p>
          <p>Descripción</p>
          <p>Moneda</p>
          <p>Costo</p>
          <p>Precio</p>
        </div>

        <div className={style.containerRows}>
          {hook.products.map((product, i) => (
            <div key={`${key.current}-${i}`}>
              <input
                onChange={(e) => hook.setProduct(product)}
                className="d-none"
                type="radio"
                name={key.current}
                id={`${key.current}-${i}`}
              />
              <label className={style.row} htmlFor={`${key.current}-${i}`}>
                <p onClick={() => hook.moreInfoDialog.open()}>{product.id}</p>
                <p>{product.description}</p>
                <p>{product.currency}</p>
                <p>{mnCurrency(product.unitCost)}</p>
                <p>{mnCurrency(product.unitPrice)}</p>
              </label>
            </div>
          ))}
        </div>
        <Pagination
          actualPage={hook.currentPage}
          pages={hook.pages}
          onChange={hook.setPage}
        />
      </div>
    </>
  );
}
