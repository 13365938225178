import useDialog from "customHooks/useDialog";
import { saveAs } from "file-saver";
import { getCatalogueExcel } from "helpers/Apis/Directory";
import { useState } from "react";

/**
 *
 * @type {import("./types").StateUseCatalogueDirectory}
 */
const INITIAL_STATE = {
  status: true,
  sector: null,
  isDownloading: false,
};

/**
 * @param {import("../types").TypeCatalogueDirectory} type - Type of catalogue being used
 * @returns {import("./types").ReturnUseCatalogueDirectory}
 */
export default function useCatalogueDirectory(type) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  const statusList = async () => [
    {
      label: "Activos",
      value: true,
    },
    {
      label: "Inactivos",
      value: false,
    },
  ];

  /**
   * Update the type of sector to use
   * @param {import("components/individual/TypeCustomerDirectory/types").TypeCustomerDirectoryData} sector - Sector information
   * @returns {void}
   */
  const updateTypeSector = (sector) => {
    if (sector === null || sector === undefined) return;

    setState((current) => ({
      ...current,
      sector: typeof(sector.id)==='number' ? sector.id : null,
    }));
  };

  const attemptDownloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloading: true,
    }));

    const idType = type === 'clients' ? 1 : 2;

    const excel = await getCatalogueExcel(state.sector, idType,state.status);

    if (excel !== undefined) {
      saveAs(
        excel,
        `Catálogo ${type === "clients" ? "clientes" : "proveedores"}.xlsx`
      );
    }

    setState((current) => ({
      ...current,
      isDownloading: false,
    }));
  };

  /**
   * Set the status filter for the excel
   * @param {boolean|null} status - Status of the report
   * @returns {void}
   */
  const setStatus = status => setState(current=>({
    ...current,
    status
  }));

  return {
    ...state,
    dialog,
    statusList,
    attemptDownloadExcel,
    updateTypeSector,
    setStatus
  };
}
