import Currency from "components/individual/inputs/Currency";
import { useContext } from "react";
import { Label } from "structure/Document";
import { BankAccountContext } from "..";

export const BankCurrency = () => {
  const { updateCurrency, isValidCurrency, id, account, register } =
    useContext(BankAccountContext);

  return (
    <div>
      {id === null ? (
        <>
          <Label required={!isValidCurrency}>Moneda</Label>
          <Currency
            onChange={(value) => updateCurrency(value)}
            claseName="w-100"
            selected={account?.currency}
            forwardRef={register}
          />
        </>
      ) : (
        <>
          <Label>Moneda</Label>
          <p>{account.currency}</p>
        </>
      )}
    </div>
  );
};
