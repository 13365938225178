import Table from "components/general/Table";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useRemition from "customHooks/useRemition";
import { parseDateToText } from "helpers/dates";
import { parseToFolio } from "helpers/documents";
import { mnCurrency } from "helpers/money";
import React, { createContext } from "react";
import ui from "./styles.module.scss";
import Header, { Customer, Pos, Solped } from "pages/Proyects/Header";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import CreateRemition from "./CreateRemition";
import OptionsRemition from "./OptionsRemition";

/**
 *
 * @param {import("react-data-table-component").TableProps<import("../../../../../types/proyects/remisionProyect").DtoRemisionProyect>} props
 * @returns
 */
function TableRemitions(props) {
  return <Table {...props} />;
}

/**
 * @type {import("react").Context<import("customHooks/useRemition/types").ReturnUseRemition>}
 */
export const ContextRemitions = createContext(undefined);

export default function Remition() {
  const { idPosition } = useParams();
  const hook = useRemition(+idPosition);

  return (
    <ContextRemitions.Provider value={hook}>
      <BreadCrumV2 path={hook.breadcrum.current} />

      <div className={ui.container}>
        <Header idPosition={+idPosition} idProyect={null}>
          <div className={ui.header}>
            <div className={ui.overviewHeader}>
              <Solped />
              <Customer />
              <Pos />

              <div>
                <b>Venta total</b>
                <p>{mnCurrency(hook.totalSell)}</p>
              </div>

              <div>
                <b>Remisionado</b>
                <p>{mnCurrency(hook.totalRemision)}</p>
              </div>

              <div>
                <b>Saldo</b>
                <p>{mnCurrency(hook.residue)}</p>
              </div>
            </div>

            <div className={ui.optionsRemition}>
              <CreateRemition
                idPosition={+idPosition}
                onCreated={hook.refetchTable}
              >
                <ButtonV2>
                  <span>Agregar</span>
                </ButtonV2>
              </CreateRemition>

              <ButtonV2 onClick={hook.refetchTable}>
                <span>Buscar</span>
              </ButtonV2>
            </div>
          </div>
        </Header>

        <OptionsRemition />

        <TableRemitions
          progressPending={hook.status === "loading"}
          data={hook.remitions}
          onRowClicked={(row) => hook.setSelectedRemition(row)}
          selectableRowSelected={(row) =>
            hook.selectedRemition?.idDocument === row.idDocument
          }
          columns={[
            {
              name: "Remisión",
              sortable: false,
              selector: (item) => parseToFolio(item.documentNumber),
            },
            {
              name: "Registro",
              sortable: false,
              selector: (item) => parseDateToText(new Date()),
            },
            {
              name: "Importe",
              sortable: false,
              selector: (item) => mnCurrency(item.subTotal),
            },
            {
              name: "IVA",
              sortable: false,
              selector: (item) => mnCurrency(item.iva),
            },
            {
              name: "Total",
              sortable: false,
              selector: (item) => mnCurrency(item.total),
            },
            {
              name: "Estatus",
              sortable: false,
              selector: (item) => item.documentStatus,
            },
          ]}
        />
      </div>
    </ContextRemitions.Provider>
  );
}
