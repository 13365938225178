import {
  BankHeader,
  BankName,
  BankAccount,
  BankCurrency,
} from "components/general/BankHeader";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import BankContext from "context/Bank/BankContext";
import useMovements from "customHooks/useMovements/index";
import React, { useContext, useRef, useState } from "react";
import { createContext } from "react";
import Options from "./Options";
import scss from "./styles.module.scss";
import usePermissions from "customHooks/usePermissions";
import Table from "./Table";
import CreateMovement from "./CreateMovement";
import FilterForMobile from "./FilterForMobile";
import RangeFilter from "./RangeFilter";
import SwitchProcess from "./SwitchProcess";
import PillsMobile from "./PillsMobile";
import AccoutingStatus from "components/general/Forms/Selects/AccountingStatus";
import BankAcconuntSelect from "components/individual/BankAccount";
import { Label } from "structure/Document";
import SearchAccounting from "./SearchAccounting";
import useQueryParams from "customHooks/useQueryParams";

/**
 * @type {import("react").Context<import("customHooks/useMovements/types").ContextUseMovements|undefined>}
 */
export const IncomingContext = createContext(undefined);

/**
 * Render a module to display the movements for incomings or egresses
 * @param {import("./types").PropsMovementModule} props - Props
 * @returns {JSX.Element}
 */
export default function Incoming({
  type = "ingreso",
  isForAccounting = false,
}) {
  const context = useContext(BankContext);

  /**
   * @type {import("./types").PermissionsMovements}
   */
  const indexedPermissions = useRef({
    ingreso: [
      "23beca91-4544-43ff-8f54-08f492c69adc",
      "892796d5-bc32-4ad4-a224-bc371831f02c",
    ],
    egreso: [
      "c3ccc069-cb68-4af8-be59-a3563528be51",
      "63275914-042b-4e13-b4c1-8a89af71d8d0",
    ],
  });

  const { cuenta } = useQueryParams();

  const [account, setAccount] = useState(+cuenta || null);

  const hook = useMovements({
    bankAccount: account,
    type,
    isForAccounting,
  });

  const permissionToUse = useRef(
    isForAccounting
      ? indexedPermissions.current[type]
      : indexedPermissions.current[type]
  );

  usePermissions(permissionToUse.current);

  if (context.isLoadingAccount && context.account === null)
    return (
      <IncomingContext.Provider
        value={{ ...hook, type, isForAccounting: hook.isForAccounting }}
      >
        <BreadCrumV2 path={hook.breadcrum} />
        <div className={scss.noAccount}>
          <BankAcconuntSelect
            onChange={(account) => {
              context.setAccountToUse(account.id);
              hook.setForAccounting(true);
              setAccount(account.id);
            }}
          />

          <div>
            <Label>Año</Label>
            <p>Cuenta obligatoria</p>
          </div>

          <div>
            <Label>Mes</Label>
            <p>Cuenta obligatoria</p>
          </div>

          <div>
            <Label>Estatus</Label>
            <p>Cuenta obligatoria</p>
          </div>

          <SearchAccounting />
        </div>
      </IncomingContext.Provider>
    );

  return (
    <IncomingContext.Provider
      value={{ ...hook, type, isForAccounting: hook.isForAccounting }}
    >
      <div className={scss.containerMovements}>
        <div className={scss.movementsTop}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div
            style={{
              display: isForAccounting ? "none" : "block",
            }}
          >
            <BankHeader>
              {() => (
                <div>
                  <BankName />
                  <BankAccount />
                  <BankCurrency />
                </div>
              )}
            </BankHeader>
          </div>
          <div
            className={scss.filterIncomings}
            style={{ alignItems: hook.isForAccounting ? "flex-end" : "center" }}
          >
            <FilterForMobile />
            {hook.isForAccounting ? (
              <>
                <BankAcconuntSelect
                  value={account}
                  onChange={(account) => {
                    context.setAccountToUse(account.id);
                    hook.setForAccounting(true);
                    setAccount(account.id);
                  }}
                />

                <AccoutingStatus
                  value={hook.acconuntingStatus}
                  onChange={hook.setAccounting}
                />
              </>
            ) : null}
            <RangeFilter />

            <SwitchProcess />
            <SearchAccounting />
            <CreateMovement />

            <Options />
          </div>
          <PillsMobile />
        </div>

        <Table />
      </div>
    </IncomingContext.Provider>
  );
}
