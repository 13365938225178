import { Submit } from "components/individual/buttons/Buttons";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";

/**
 * Button to add the user into the system
 * @param {object} props - Props
 * @param {boolean?} props.isPerformingQueryToAdd
 * @param {boolean} props.isValid - Indicates if the button can be displayed
 * @returns {JSX.Element} Button in order to add the a user
 */
export default function AddUserBtn({
  isPerformingQueryToAdd = false,
  isValid,
}) {
  return isPerformingQueryToAdd ? (
    <Spinner hidden={false} text="Agregando usuario..." />
  ) : isValid ? (
    // <Submit text="Guardar" idForm="addUsuarioForm" />
    <ButtonV2 form="addUsuarioForm">
      <span>Agregar</span>
      <Icon icon="save" />
    </ButtonV2>
  ) : null;
}
