import React, { useContext, useRef } from "react";
import { ContextMoreInfo } from "..";
import GridLaoding from "components/individual/GridLoading";
import ui from "./styles.module.scss";
import useAdministrationOdc from "customHooks/useAdministrationOdc";
import {
  getFirstDayOfMonth,
  getLastDayOfDate,
  parseDateToText,
} from "helpers/dates";
import { add } from "date-fns";
import { Pagination } from "components/individual/Pagination";
import { mnCurrency } from "helpers/money";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";

const Header = () => (
  <div className={ui.header}>
    <p>Fecha</p>
    <p>No. OC</p>
    <p>Razón social</p>
    <p>Concepto</p>
    <p>Moneda</p>
    <p>Saldo x rec.</p>
    <p>Contabilizado</p>
  </div>
);

/**
 * Render the grid of the more information
 * @param {import("./types").PropsGrid} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfoTable({ onChange = () => {} }) {
  const hook = useContext(ContextMoreInfo);

  const key = useRef(window.crypto.randomUUID());

  const oc = useAdministrationOdc({
    from: getFirstDayOfMonth(new Date(`${hook.year}-${hook.month}-15`)),
    status: 100,
    to: getLastDayOfDate(new Date(`${hook.year}-${hook.month}-15`)),
    onChangeGrid: onChange,
  });

  if (oc.isFetchingData)
    return (
      <div className={ui.table}>
        <Header />
        <div className={ui.containerRows}>
          <GridLaoding justOneRow={false} />
        </div>
      </div>
    );

  return (
    <div className={ui.table}>
      <Header />

      <div className={ui.containerRows}>
        {oc.odcs
          .filter((item) => item.moneda !== "***")
          .map((ocItem, i) => (
            <div className={ui.row} key={`${key.current}-${i}`}>
              <p className="text-center">
                {parseDateToText(
                  add(new Date(ocItem.registro.yyyymmdd), {
                    days: 1,
                  })
                )}
              </p>
              <ModalDocsRelatedV2 idDocument={ocItem.id} fromLegal={false}>
                <p className={ui.moreInfo}>{ocItem.numeroDocumento}</p>
              </ModalDocsRelatedV2>
              <p>{ocItem.customer.socialReason}</p>
              <p>{ocItem.concept.description}</p>
              <p className="text-center">{ocItem.moneda}</p>
              <p className="text-right">{mnCurrency(ocItem.residue)}</p>
              <label
                className="d-flex justify-content-center align-items-center"
                htmlFor={`${key.current}-${i}`}
              >
                <input
                  onChange={() => oc.toggleAccounting(ocItem.id)}
                  type="checkbox"
                  id={`${key.current}-${i}`}
                  checked={oc.grid.operation[ocItem.id]}
                />
              </label>
            </div>
          ))}
      </div>

      <Pagination
        onChange={oc.setPage}
        actualPage={oc.page}
        pages={oc.totalPages}
      />
    </div>
  );
}
