import FileInfo from "components/individual/SendEmail/FileInfo";
import useFileInput from "customHooks/useFileInput";
import React, { Fragment, createContext, useContext, useRef } from "react";
import * as byte from "byte-size";
import { getExtensionFileName } from "helpers/files";
import { Spinner } from "components/individual/Spinner/Spinner";
import ui from "./styles.module.scss";

/**
 * @type {import("react").Context<import("./types").ContextFile|undefined>}
 */
const ContextFiles = createContext(undefined);

/**
 * Render the components UI to handle files
 * @param {import("./types").FileProps} props
 * @returns {JSX.Element}
 */
export function Files(props) {
  let parsedProps = { ...props };

  delete parsedProps.children;

  const ok = useFileInput(parsedProps);

  return (
    <ContextFiles.Provider value={{ ...ok, ...props }}>
      {props.children}
    </ContextFiles.Provider>
  );
}

export function DropZone() {
  const ok = useContext(ContextFiles);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div
      onClick={ok.openFilePicker}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={ok.handleDragEnter}
      onDragLeave={ok.handleDragLeave}
      onDrop={ok.handleDrop}
      style={{
        border: `2px dashed ${
          ok.isDragging ? "var(--linkColor)" : "var(--primaryColor)"
        }`,
        padding: "20px",
        textAlign: "center",
        height: "150px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--primaryColor)",
      }}
    >
      Arrastra y suelta archivos aquí o haz clic para seleccionar archivos.
      <br />
      <input type="file" className="d-none" ref={ok.inputRef} />
      {ok.allowedExtensions.map((ext, i) => (
        <Fragment key={`${key.current}-${i}`}>.{ext} </Fragment>
      ))}
    </div>
  );
}

export function FileList() {
  const files = useContext(ContextFiles);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div className={ui.fileList}>
      {files.selectedFiles.map((file, i) =>
        file instanceof File ? (
          <FileInfo
            key={`${key.current}-${i}`}
            blob={file}
            onDelete={() => files.deleteFile(i)}
            fileInfo={{
              extension: getExtensionFileName(file.name).extension,
              fileName: file.name,
              size: {
                long: byte.default(file.size, {
                  precision: 2,
                }).long,
                unit: byte.default(file.size, {
                  precision: 2,
                }).unit,
                value: byte.default(file.size, {
                  precision: 2,
                }).value,
              },
            }}
          />
        ) : null
      )}
    </div>
  );
}

/**
 * Render a JSX.Element clicklable to upload the current files to repository
 * @param {import("./types").UploadProps} props - Props
 * @returns {JSX.Element}
 */
export function Upload({
  entity,
  idRegister,
  path,
  children = <></>,
  onUploaded = () => {},
}) {
  const files = useContext(ContextFiles);

  const handleUpload = async () => {
    const wasUploaded = await files.attemptUpload(entity, idRegister, path);

    if (wasUploaded) onUploaded();
  };

  if (files.selectedFiles.length <= 0) return <></>;

  if (files.isUploading)
    return (
      <Spinner
        text={
          files.selectedFiles.length === 1
            ? "Subiendo archivo"
            : "Subiendo archivos"
        }
        hidden={false}
      />
    );

  return <div onClick={handleUpload}>{children}</div>;
}
