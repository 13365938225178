import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { AddRolContext } from "../..";

export default function ContinueButton() {
  const { setStep, permissions } = useContext(AddRolContext);

  return permissions.length === 0 ? (
    <p className="text-danger">Selecciona al menos un permiso</p>
  ) : (
    <ButtonV2 onClick={() => setStep(3)}>Continuar</ButtonV2>
  );
}
