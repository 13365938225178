import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import Dialog from "structure/Dialog";
import DocsRelatedV2 from "structure/DocsRelatedV2";

/**
 * Render a clicable html element that opens a modal with the documents related
 * @param {import("./types").PropsModalDocsRelatedV2} props - Props
 * @returns {JSX.Element} Modal with the related docs of a document
 */
export default function ModalDocsRelatedV2({
  children,
  fromLegal,
  idDocument,
  forwardRef = undefined
}) {
  const dialog = useDialog();

  const [isOpen,setIsOpen] = useState(false);

  const handleModal = (isOpen) => {
    setIsOpen(isOpen);

    if(isOpen){
      dialog.open()
    }else{
      dialog.close();
    }
  }

  return (
    <>
      <div ref={forwardRef} onClick={()=>handleModal(true)}>{children}</div>

      <Dialog
        forwardRef={dialog.refDialog}
        closeCallback={()=>handleModal(false)}
        title="Más información"
        width={85}
      >
        {isOpen ? <DocsRelatedV2 id={idDocument} isLegalDocumentId={fromLegal} /> : null}
        
      </Dialog>
    </>
  );
}
