import {
  cancelInvoiceReception,
  downloadInvoiceReceptionXlsx,
  fetchInvoiceReception,
} from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { useState, useEffect, useContext } from "react";

import { Success, YesNoAlert } from "helpers/alerts";
import LoginContext from "context/Login/LoginContext";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useBlobAzure from "customHooks/useBlobAzure";
import useDialog from "customHooks/useDialog";

/**
 * State for the invoice reception
 * @type {import('types/typedef/customHooks/useInvoiceReception').InvoiceReceptionI}
 */
const initialState = {
  gridAccounting: {
    original: {},
    operation: {},
  },
  year:null,
  month:null,
  canUpdateAccounting: false,
  search: "",
  status: 20,
  isLoading: true,
  isDownloadingXlsx: false,
  infoRow: null,
  isCancellableInvoice: false,
  documents: [],
  idOpenModal: "modalOpen",
  isCancelling: false,
  refetchTable: false,
  isLoadingTable:false,
  concilationStatus:null,
  pagination: {
    currentPage: 1,
    pages: 1,
  },
};

export default function useInvoiceReception(isForAccounting = false) {
  const [state, setState] = useState({
    ...initialState,
    status: isForAccounting ? 100 : 20,
    year:isForAccounting ? new Date().getUTCFullYear() : null,
    month:isForAccounting ? new Date().getUTCMonth()+1 : null
  });

  const dialog = useDialog();

  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const pdfBlob = useBlobAzure(
    state.infoRow?.pdf,
    `FR-${state.infoRow?.socialReason}.pdf`
  );
  const xmlBlob = useBlobAzure(
    state.infoRow?.xml,
    `FR-${state.infoRow?.socialReason}.xml`
  );

  useEffect(() => {
    if (state.documents.length <= 0) return;

    let gridChanged = false;

    Object.entries(state.gridAccounting.operation).forEach(([key, value]) => {
      if (state.gridAccounting.original[key] !== value) {
        gridChanged = true;
      }
    });

    setState((current) => ({
      ...current,
      canUpdateAccounting: gridChanged,
    }));
  }, [state.documents, state.gridAccounting]);

  /**
   * Fetch the page for the records
   * @param {number} page - Page to request
   */
  const fetchPage = async (page) => {
    setState({
      ...state,
      isLoading: true,
      canUpdateAccounting: false,
      infoRow: null,
      gridAccounting: {
        original: {},
        operation: {},
      },
    });

    const data = await fetchInvoiceReception({
      page,
      querySearch: state.search === "" ? "-1" : state.search,
      status: state.status,
      accounting:state.concilationStatus,
      month:state.month,
      year:state.year
    });

    const indexed = data.data.reduce(
      (indexed, inv) => ({
        ...indexed,
        [inv.id]: inv.isAccounted,
      }),
      {}
    );

    setState({
      ...state,
      infoRow: null,
      gridAccounting: {
        original: indexed,
        operation: indexed,
      },
      documents: data.data,
      isLoading: false,
      pagination: {
        currentPage: data.actualPage,
        pages: data.pages,
      },
    });
  };

  useEffect(() => {
    if (
      typeof state.infoRow !== "object" ||
      state.infoRow === null ||
      state.infoRow === undefined
    ) {
      setState((current) => ({
        ...current,
        isCancellableInvoice: false,
      }));

      return;
    }

    if (
      state.infoRow.isCancellable &&
      state.infoRow.idLegalDocumentStatus !== 5
    ) {
      setState((current) => ({
        ...current,
        isCancellableInvoice: true,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      isCancellableInvoice: false,
    }));
  }, [state.infoRow]);

  useEffect(() => {
    (async function () {
      await fetchPage(1);
    })();
  }, [state.refetchTable]);

  const refetchTable = () =>
    setState({
      ...state,
      refetchTable: !state.refetchTable,
    });

    /**
     * 
     * @param {boolean|null} status 
     * @returns 
     */
    const setAccountStatus = status => setState(current=>({
      ...current,
      concilationStatus:status
    }));

  /**
   * Perform the action of cancel a legal document
   */
  const performCancellation = async () => {
    setState({
      ...state,
      isCancelling: true,
    });

    const wasCancelled = await cancelInvoiceReception(
      state.infoRow.id,
      userInfo[0].fullName
    );

    setState({
      ...state,
      isCancelling: false,
      refetchTable: !state.refetchTable,
    });

    if (wasCancelled) {
      Success(() => {}, "La recepcion de factura fue cancelada");
    }
  };

  const promptCancelInvoiceReception = async () => {
    YesNoAlert("¿Estas seguro de cancelar?", performCancellation);
  };

  /**
   * Update the search query param to use on the fetch information
   * @param {string|null} search - Search input to search on the table
   * @returns {void}
   */
  const updateQuerySearch = (search) =>
    setState({
      ...state,
      search,
    });

  /**
   * Update the status of the filter
   * @param {number|"-1"} status - New status to use on the combo
   * @returns {void}
   */
  const updateFilterStatus = (status) => {
    setState({
      ...state,
      status,
    });
  };

  const setInfoRow = (invoice) =>
    setState({
      ...state,
      infoRow: invoice,
    });

  const attemptDownloadXlsx = async () => {
    setState((current) => ({
      ...current,
      isDownloadingXlsx: true,
    }));

    const excel = await downloadInvoiceReceptionXlsx({
      page:0,
      status:state.status,
      accounting:isForAccounting,
      querySearch:null,
      isForAccounting,
      month:state.month,
      year:state.year
    }
      
    );

    if (excel instanceof Blob) {

      const fileName = isForAccounting ? 'Contabilidad - ' : 'Compras - '

      saveAs(excel, `${fileName}Recepcion de facturas.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingXlsx: false,
    }));
  };

  const redirectAssociatedFiles = () =>
    history.push(`recepcion-facturas/archivos/${state.infoRow.id}`);

  /**
   * Toggle the value for the accounting
   * @param {number} id - Id of the invoice
   * @returns {void}
   */
  const toggleAccounting = (id) =>
    setState((current) => ({
      ...current,
      gridAccounting: {
        ...current.gridAccounting,
        operation: {
          ...current.gridAccounting.operation,
          [id]: !current.gridAccounting.operation[id],
        },
      },
    }));

    /**
     * Set the year for the filter  
     * @param {number} year - Year
     * @returns {void}
     */
    const setYear = year => setState(current=>({
      ...current,
      year
    }));

    /**
     * Set the month for the filter
     * @param {number} month - Month
     * @returns {void}
     */
    const setMonth = month => setState(current=>({
      ...current,
      month
    }));

  const breadcrum = !isForAccounting
    ? [
        {
          route: `/inicio`,
          text: "Inicio",
        },

        {
          route: `/administracion/recepcion-facturas`,
          text: "Compras",
        },
        {
          route: `/inicio`,
          text: "Facturas recibidas",
        },
      ]
    : [
        {
          route: `/inicio`,
          text: "Inicio",
        },

        {
          route: `/contabilidad/facturas-recibidas`,
          text: "Contabilidad",
        },
        {
          route: `/contabilidad/facturas-recibidas`,
          text: "Facturas recibidas",
        },
      ];

  return {
    updateQuerySearch,
    setAccountStatus,
    setYear,
    setMonth,
    refetchTable,
    toggleAccounting,
    attemptDownloadXlsx,
    redirectAssociatedFiles,
    state,
    ...state,
    updateFilterStatus,
    promptCancelInvoiceReception,
    wasFounded: state.documents.length <= 0 ? false : true,
    infoRow: state.infoRow,
    pdfBlob,
    xmlBlob,
    isCancelling: state.isCancelling,
    setInfoRow,
    fetchPage,
    dialog,
    breadCrumPath: breadcrum,
  };
}
