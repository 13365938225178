import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import AvatarEditor from "react-avatar-editor";
import { ProfilePictureContext } from "..";
import Icon from "components/individual/HeaderIcon";
import useFiles from "customHooks/useFiles";
import useProfilePicture from "customHooks/useProfilePicture";

/**
 * Render the UI in order to edit the current profile picture of the user
 * @param {import("../types").EditProfilePictureI} props - Props
 * @returns {JSX.Element} UI to edit the profile picture
 */
export default function EditProfilePicture({
  currentProfilePicture = null,
  scale = 1,
  position,
  onSave = () => {},
}) {
  const { img, showEdition, handleUpdateProfilePicture } = useContext(
    ProfilePictureContext
  );

  const {
    photoToUse,
    positionToUse,
    scaleToUse,
    setPhotoToUse,
    setPositionToUse,
    setScaleToUse,
  } = useProfilePicture({
    currentProfilePicture,
    scale,
    position,
  });

  const [extensions, setExtensiones] = useState(["png", "jpeg", "jpg", "gif"]);

  const { file, addFiles, resetFiles, attributeAcceptHtml } =
    useFiles(extensions);

  useEffect(() => {
    if (file.length <= 0) return;

    setPhotoToUse(file[0]);
  }, [file]);

  const idInputFile = useRef(window.crypto.randomUUID());

  /**
   * Function to cancel the edition of the profile picture
   */
  const cancelEdition = () => {
    setPhotoToUse(currentProfilePicture);
    showEdition(false);
  };

  /**
   * Execute the function that confirms the image profile configuration of the user
   */
  const onSaveProfilePicture = () => {
    handleUpdateProfilePicture({
      showEdition: false,
      img: photoToUse,
      position: positionToUse,
      scale: +scaleToUse,
    });
  };

  return (
    <div className={styles.updateProfilePicture}>
      <AvatarEditor
        image={photoToUse}
        width={250}
        height={250}
        border={50}
        position={positionToUse}
        scale={scaleToUse}
        rotate={0}
        borderRadius={150}
        onPositionChange={setPositionToUse}
      />

      <input
        type="range"
        max="2"
        min="1"
        className={styles.scale}
        step="0.01"
        defaultValue={img.scale}
        onChange={(e) => setScaleToUse(e.target.value)}
      />

      <div className={styles.buttonsEditionPicture}>
        <label htmlFor={idInputFile.current} className={styles.buttonEdition}>
          <Icon icon="image" />
          <input
            style={{ display: "none" }}
            type="file"
            accept={attributeAcceptHtml}
            id={idInputFile.current}
            onChange={(e) => {
              resetFiles();
              addFiles(e.target.files);
            }}
          />
        </label>

        <div onClick={onSaveProfilePicture} className={styles.buttonEdition}>
          <Icon icon="save" />
        </div>

        <div onClick={cancelEdition} className={styles.buttonEdition}>
          <Icon icon="cancel" />
        </div>
      </div>
    </div>
  );
}
