import { useContext, useReducer } from "react";
import { initialState, partidasReducer } from "./partidaReducer";
import { ACTIONS } from "./Actions";
import { recaltulateTotals } from "../helpers/helpers";
import { mnCurrency } from "helpers/money";
import { useEffect } from "react";
import WinQuoteContext from "components/Sections/WinQuote/ContextWinQuote";

export const usePartidas = (tc) => {
  const [partidas, dispatch] = useReducer(partidasReducer, initialState);

  const {
    documents: {
      odc: { documentItems },
    },
    handleEditItems,
  } = useContext(WinQuoteContext);

  useEffect(() => {
    console.log("Recalculado.....");
    const newItem = recaltulateTotals(partidas, tc);
    dispatch({
      type: ACTIONS.SET_LOAD_ITEM,
      payload: newItem,
    });
  }, [
    partidas.quantity,
    partidas.cu,
    partidas.currency.code,
    partidas.costDiscount.number,
    partidas.iva,
  ]);

  /**
   * Handle the change of the item description
   * @param {string} description
   */
  const handleChangeItemDescription = (description) => {
    dispatch({
      type: ACTIONS.SET_ITEM_DESCRIPTION,
      payload: description,
    });
  };

  /**
   * Handle the change of the cuantity
   * @param {number} quantity
   */
  const handleChangeQuantity = (quantity) => {
    dispatch({
      type: ACTIONS.SET_ITEM_QUANTITY,
      payload: quantity,
    });
  };

  /**
   * Handle the change of the cost
   * @param {number} cost
   */
  const handleChangeCost = (cost) => {
    const toCurrency = mnCurrency(cost);
    /**
     * @type{import("../../../../../../types/documentActions").Imports}
     */
    const newCost = {
      number: cost,
      text: toCurrency,
    };
    dispatch({
      type: ACTIONS.SET_ITEM_COST,
      payload: newCost,
    });
  };

  /**
   * Handle the cost discount change
   * @param {number} discount
   */
  const handleOnCostdiscountChange = (discount) => {
    /**
     * @type{import("../../../../../../types/documentActions").Imports}
     */
    const newDiscount = {
      text: `${discount}%`,
      number: discount,
    };
    dispatch({
      type: ACTIONS.SET_COST_DISCOUNT,
      payload: newDiscount,
    });
  };

  /**
   * Handle the currencyChange
   * @param {import("../../../../../../types/documentActions").Currency} newCurrency
   */
  const handleOnCurrecyChange = (newCurrency) => {
    dispatch({
      type: ACTIONS.SET_ITEM_CURRENCY,
      payload: {
        code: newCurrency.code,
        id: newCurrency.code === "MXN" ? 1 : 2,
      },
    });
  };

  /**
   * Do open modal
   */
  const doOpenModal = (index) => {
    const currentItem = documentItems[index];
    if (!!index) {
      console.log("Index de la partida", index);

      console.log(documentItems);
    }
    dispatch({
      type: ACTIONS.SET_LOAD_ITEM,
      payload: currentItem,
    });

    dispatch({
      type: ACTIONS.SET_OPEN_ITEM,
      payload: true,
    });
  };
  /**
   * Do close modal
   */
  const doCloseModal = () => {
    dispatch({
      type: ACTIONS.SET_OPEN_ITEM,
      payload: false,
    });
    dispatch({
      type: ACTIONS.SET_LOAD_ITEM,
      payload: initialState,
    });
  };

  /**
   * handle the change of the sku
   * @param {string} sku
   */

  const handleOnchangeSku = (sku) => {
    dispatch({
      type: ACTIONS.SET_ITEM_SKU,
      payload: sku,
    });
  };

  const handleUpdate = () => {
    handleEditItems(partidas);
    doCloseModal();
  };

  const handleOnIvaItemChange = (value) => {
    console.log(value);
    const ivaSlected = {
      number: value.iva,
      text: `${value.iva}%`,
      exempt: value.excento,
    };
    dispatch({
      type: ACTIONS.SET_ITEM_IVA,
      payload: ivaSlected,
    });
  };

  const functions = {
    handleChangeItemDescription,
    handleChangeQuantity,
    handleChangeCost,
    handleOnCostdiscountChange,
    handleOnCurrecyChange,
    handleOnchangeSku,
    doOpenModal,
    doCloseModal,
    handleUpdate,
    handleOnIvaItemChange,
  };

  return {
    partidas,
    functions,
  };

  // handleItemDescriptionChange,
  //   currentItem,
  //   handleOnCurrencyItemChage,
  //   handleOnIvaItemChange,
  //   handleOnCostItemChange,
  //   handleOnPriceItemChange,
  //   handleOnQuantityItemChange,
  //   handleOnProviderDiscountItemChange,
  //   handleOnClientDiscountItemChange,
  //   onCloseCurrentItem,
  //   addItem,
  //   editItem,
  //   doOpenModal,
  //   doCloseModal,
  //   handleItemSkuChange,
  //   handleEditNewItemSku,
  //   handleEditNewItemDescription,
  //   modal,
  //   handleOnUenItemChange
};
