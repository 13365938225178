import styled from "styled-components";

export const ContainerModal = styled.div`
  .modal-body {
    overflow-y: auto;
    max-height: 70vh;
  }
`;
export const BotonneraContainer = styled.div`
  display: flex;
  overflow-y: hidden;
  min-height: 3.125rem;
  justify-content: start;
  margin: 15px 0;
  align-items: center;
  gap: 0.625rem;

  @media (max-width: 767px) {
    height:auto;
    margin:1rem 0;
    > div{
      > button{
        display:block;
      }
    }

    gap: 5%;
    > div {
      width: 30%;
      > button {
        width: 100%;
      }
    }
  }
`;
