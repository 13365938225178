import * as yup from "yup";

// const nameRegexExpression = new RegExp("^[aA-zZ,ñ, ]+$");
const nameRegexExpression = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d1, ]+$");
const emailRegexExpression = new RegExp(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/);
const numbersRegexExpression = new RegExp(/^[0-9,\ ]+$/);

const userName = yup
  .string()
  .min(8, "Se requiere 8 caracteres")
  .max(30, "No mas de 30 caracteres")
  .required("Campo requerido");
const nombre = yup
  .string()
  .required("Campo requerido")
  .max(30, "No mas de 30 caracteres")
  .trim()
  .matches(nameRegexExpression, { message: "Nombre invalido, solo letras" });
const middleName = yup
  .string()
  .max(30, "No mas de 30 caracteres")
  .trim()
  .matches(nameRegexExpression, {
    message: "Nombre invalido, solo letras",
    excludeEmptyString: true,
  });

const apellidoP = yup
  .string()
  .required("Campo requerido")
  .max(30, "No mas de 30 caracteres")
  .trim()
  .matches(nameRegexExpression, { message: "Apellido invalido, solo letras" });

const apellidoM = yup
  .string()
  .max(30, "No mas de 30 caracteres")
  .trim()
  .nullable(true);
// .matches(nameRegexExpression, { message: "Apellido invalido, solo letras" });

const dateMothYear = yup
  .string()
  .required("Campo requerido")
  .typeError("Fecha invalida");

const email = yup
  .string()
  .required("Campo requerido")
  .max(50, "No mas de 50 caracteres")
  .trim()
  .matches(emailRegexExpression, { message: "Email invalido" });

const jefe = yup
  .number()
  .positive("Seleccione un ejecutivo")
  .nullable(true)
  .transform((value, original) => {
    if (value < 0) return null;
  })

const roles = yup.number().positive("Seleccione un rol");

const ticketRol = yup
  .string()
  .required("Obligatorio")
  .matches(/\b(customer|administrator|engineer)\b/, {
    message:
      "Solo se puede agregar el rol de Ingeniero, Administrador y Cliente",
  });

export const userValidationSchema = yup.object().shape({
  userName,
  nombre,
  middleName,
  apellidoP,
  apellidoM,
  dateMothYear,
  email,
  jefe,
  roles,
  ticketRol,
});
