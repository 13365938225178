import React, { useContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import CommentsDocument from "components/general/CommentsDocument";
import NoteConsideration from "components/general/NoteConsideration";
import HeaderDocument from "components/general/HeaderDocument";
import { Tabs } from "components/general/Tabs/Tabs";
import TabContract from "structure/Contract/TabContract";
import Partidas from "structure/Partidas";
import useContract from "customHooks/useContract/ContractState";
import { ContractContext } from "customHooks/useContract/ContractContext";
import AddContractButton from "./Button";
import useQueryParams from "customHooks/useQueryParams";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import usePermissions from "customHooks/usePermissions";
import ui from 'structure/ODC/styles.module.scss';

const { Provider } = ContractContext;

export default function AddContract() {
  const { idCliente, modulo } = useQueryParams();
  
  usePermissions("82457369-4d57-46fb-a468-49159aefb8bc");
  

  return (
    <Provider value={{ module: modulo }}>
      <div className={ui.container}>
      <BreadCrumV2 path={[ {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/ventas/contratos",
            text: "Ventas",
          },
          {
            route: "/ventas/contratos",
            text: "Contrato",
          },
          {
            route: "",
            text: 'Agregar',
          },]} />

      <AddDocument
        actionType="Agregar"
        customerId={+idCliente}
        documentType={6}
      />
      </div>

      {/* <div className="container">
        <header className="d-flex align-items-center justify-content-between my-3">
          <HeaderDocument
            currency={useContractValues.currency}
            socialReason={useContractValues.customerSocialReason}
          />
          <AddContractButton />
        </header>

        <Tabs
          idContainerContent={"documentInfo"}
          idContainerTabs={"tabs"}
          tabButtons={{
            document: {
              text: "Contrato",
              selected: true,
              component: <TabContract />,
            },
            items: {
              text: "Partidas",
              selected: false,
              component: (
                <Partidas
                  currency={useContractValues.currency}
                  tc={useContractValues.tc}
                  typeDocument="contrato"
                  calculationMode="pu"
                  onChange={useContractValues.updateItems}
                />
              ),
            },
            comments: {
              text: "Notas y consideraciones",
              selected: false,
              component: (
                <Tabs
                  idContainerContent="notesAndConsiderations"
                  idContainerTabs="commentsNotesConsiderations"
                  tabButtons={{
                    commentAddInvoice: {
                      text: "Comentarios",
                      selected: false,
                      component: (
                        <CommentsDocument
                          includeCc={true}
                          idTypeDocument={6}
                          onChange={useContractValues.updateComments}
                        />
                      ),
                    },
                    notes: {
                      text: "Notas y consideraciones",
                      selected: true,
                      component: (
                        <NoteConsideration
                          currency={useContractValues.currency}
                          idTypeDocument={6}
                          uen={useContractValues.uens}
                          onChange={
                            useContractValues.updateNotesAndConsiderations
                          }
                        />
                      ),
                    },
                  }}
                />
              ),
            },
          }}
        />
      </div> */}
    </Provider>
  );
}
