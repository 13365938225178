import { TextArea } from "components/individual/inputs/Inputs";
import React from "react";
import { useContext } from "react";
import ReactQuill from "react-quill";
import { Label } from "structure/Document";
import { BankAccountContext } from "..";

export default function Comment() {
  const context = useContext(BankAccountContext);

  return (
    <>
      <Label>Comentario</Label>
      <div className="ticketCommentBox">
        <div
          className="commentBox"
          style={{
            height: "200px",
          }}
        >
          <ReactQuill
            theme="snow"
            value={context.account?.comments || ""}
            onChange={context.setcomment}
            preserveWhitespace
          />
        </div>
      </div>
    </>
  );
}
