import React, { useContext } from "react";
import styled from "styled-components";
import DocumentContext from "../ContextDocument";
import { MoneyTextContainer } from "./Conteiners";

const LabelText = styled.div`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
`;
const MoneyText = styled.span`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  margin:0;
`;
const MoneyTextSpan = styled.span`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
`;

export const TextSection = () => {
  /**
   * @type{import("../../../../../../types/documentActions").UseDocumentV2I}
   */
  const { moneyInfo, getDocumentNumbers, documentType, interfaceControl } =
    useContext(DocumentContext);
  return (
    <MoneyTextContainer>
      <LabelText>
        <span>Importe</span>
        <MoneyTextSpan>
          {interfaceControl.disableTotals
            ? "------"
            : getDocumentNumbers(documentType).import}
        </MoneyTextSpan>
      </LabelText>
      <LabelText>
        <span>IVA</span>
        <MoneyTextSpan>
          {interfaceControl.disableTotals
            ? "------"
            : getDocumentNumbers(documentType).iva}
        </MoneyTextSpan>
      </LabelText>
      <LabelText>
        <span>Total</span>
        <MoneyText>
          {interfaceControl.disableTotals
            ? "------"
            : getDocumentNumbers(documentType).total}
        </MoneyText>
      </LabelText>
      <LabelText>
        <span>Margen</span>
        <MoneyText>
          {interfaceControl.disableTotals
            ? "------"
            : moneyInfo.currency.value === "MXN"
            ? moneyInfo.mxn.margin.text
            : moneyInfo.usd.margin.text}
        </MoneyText>
      </LabelText>
    </MoneyTextContainer>
  );
};
