import {
  SET_QUOTE_INFO,
  SET_QUOTE_COMMENTS,
  SET_QUOTE_DOC_ITEMS,
  SET_PROVIDER,
  SET_INVOICE_CREDIT_DAYS,
  SET_NO_CONTRACT,
  RESET_TEMP_IMPORT,
  RESET_TEMP_ITEMS,
  SET_OC_COMMENTS,
  SET_PREINVOICE_COMMENTS,
  RESET_COMMENTS_WINQUOTE,
  SET_IVA_INFO,
  SET_IMPORT_PRICE,
  SET_CONTRACT_COMMENTS,
  SET_LOAD_COSTS,
  SET_COST,
  SET_GENERATE_CONTRACT,
  SET_COST_ERRORS,
  RESET_STATE_WIN,
  SET_COSTS_WIN_QUOTE,
  SET_ARE_MIXED_CURRENCIES,
} from "../../types/index";

import { mnCurrency } from "../../helpers/money";

export default function (state, action) {
  switch (action.type) {
    case SET_ARE_MIXED_CURRENCIES:
      return {
        ...state,
        areMixedCurrencies: action.payload,
      };

    case SET_QUOTE_INFO:
      return {
        ...state,
        quoteInfo: {
          ...state.quoteInfo,
          authorizationFlag: action.payload.auth.id,
          noQuote: action.payload.idDocument,
          import: action.payload.totalAmount,
          idContract: action.payload.idContract,
          idContact: action.payload.quoteContactId,
          idCustomer: action.payload.idCustomer,
          idCurrency: action.payload.idCurrency,
          tcp: action.payload.protected,
          clientSocialReason: action.payload.socialReason,
          creditDays: action.payload.creditDays,
          currencyDesc: action.payload.currencyDesc,
          idContact: action.payload.idContact,
          contactSocialReason: action.payload.contactSocialReason,
          effectiveDate: action.payload.effectiveDate,
          expirationDate: action.payload.reminderDate,
          probability: action.payload.probability,
          progress: action.payload.progress,
          idDocument: action.payload.idDocument,
          documentNumber: action.payload.documentNumber,
          statusDescription: action.payload.statusDescription,
          importPrice: {
            number: action.payload.totalAmount,
            text: mnCurrency(action.payload.totalAmount),
          },
          subTotalPrice: {
            number: action.payload.subTotalAmount,
            text: mnCurrency(action.payload.subTotalAmount),
          },
          ivaPrice: {
            number: action.payload.ivaAmount,
            text: mnCurrency(action.payload.ivaAmount),
          },
        },
        invoiceCreditDays: action.payload.creditDays,
        tempImport: action.payload.totalAmount,
      };

    case SET_IMPORT_PRICE:
      return {
        ...state,
        quoteInfo: {
          ...state.quoteInfo,
          importPrice: action.payload,
        },
      };

    case SET_NO_CONTRACT:
      return {
        ...state,
        quoteInfo: {
          ...state["quoteInfo"],
          idContract: action.payload,
        },
      };

    case SET_COSTS_WIN_QUOTE:
      return {
        ...state,
        costs: action.payload,
      };

    case SET_QUOTE_COMMENTS:
      return {
        ...state,
        quoteComments: action.payload,
      };

    case SET_QUOTE_DOC_ITEMS:
      return {
        ...state,
        quoteDocItems: action.payload,
        tempDocItems: action.payload,
      };

    case SET_PROVIDER:
      return {
        ...state,
        provider: action.payload.customerID,
        providerCreditDays: action.payload.creditDays,
      };

    case SET_INVOICE_CREDIT_DAYS:
      return {
        ...state,
        invoiceCreditDays: action.payload,
      };

    case RESET_TEMP_IMPORT: {
      return {
        ...state,
        tempImport: null,
      };
    }

    case RESET_TEMP_ITEMS: {
      return {
        ...state,
        tempDocItems: null,
      };
    }

    case SET_OC_COMMENTS: {
      return {
        ...state,
        ocComments: action.payload,
      };
    }

    case SET_PREINVOICE_COMMENTS: {
      return {
        ...state,
        preInvoiceComments: action.payload,
      };
    }

    case RESET_COMMENTS_WINQUOTE: {
      return {
        ...state,
        preInvoiceComments: [],
        ocComments: [],
      };
    }

    case SET_IVA_INFO: {
      return {
        ...state,
        iva: action.payload,
      };
    }

    case SET_CONTRACT_COMMENTS: {
      return {
        ...state,
        contractComments: action.payload,
      };
    }

    case SET_LOAD_COSTS: {
      return {
        ...state,
        costsItems: action.payload,
        copyCostsItems: action.payload,
      };
    }

    case SET_COST: {
      return {
        ...state,
        costsItems: action.payload,
      };
    }

    case SET_GENERATE_CONTRACT: {
      return {
        ...state,
        generateContract: action.payload,
      };
    }

    case SET_COST_ERRORS: {
      return {
        ...state,
        areCostErrors: action.payload,
      };
    }

    case RESET_STATE_WIN: {
      return {
        quoteInfo: null,
        quoteComments: [],
        quoteDocItems: [],
        ocComments: [],
        contractComments: [],
        iva: {
          number: 0,
          text: "",
        },
        generateContract: false,
        preInvoiceComments: [],
        provider: null,
        providerCreditDays: null,
        invoiceCreditDays: null,
        tempImport: null,
        tempDocItems: null,
        costsItems: null,
        copyCostsItems: null,
        areCostErrors: false,
        costs: {
          iva: {
            number: 0,
            text: "$0.0",
          },
          import: {
            number: 0,
            text: "$0.0",
          },
          total: {
            number: 0,
            text: "$0.0",
          },
        },
      };
    }
  }
}
