import { SET_REDIRECT_STEP, SET_LOADING_PAGE,VALIDATE_FLAGS ,SET_APLICACION, SET_MOVEMENT , SET_STEP , SET_CLIENT , SET_INPUT_SEARCH_CUSTOMER, SET_CONCILATION, SET_BANK_INFO , SET_AMOUNT , UPDATE_CONCILATION } from './typesConsolation';


import { updateAppliedImport , calculateAppliedImport, validateFlags, mnCurrency , calculateTC } from '../../../helpers/money';
export const initialState = {
    movement:null,
    client:null,
    step:1,
    loadingPage:true,
    cxcUpdated:true,
    searchInputCustomer:'null',
    concilationCxc:[],
    bankInfo:null,
    isValidImportApplied:true,
    isValidTotalImportApplied:true,
    isUsingValidResidue:false,
    isValidAplicacion:true,
    importApplied:0,
    residue:0,
    aplicacion:0,
    initialResidue:0
}

export const reducerConsolation=(state,action)=>{

    switch(action.type){

        case SET_AMOUNT:
            return {...state,importApplied:action.payload.importApplied,residue:action.payload.residue,initialResidue:action.payload.residue}

        case UPDATE_CONCILATION:

        const { cxc , indexUpdated } = updateAppliedImport(state.concilationCxc,action.payload.index,action.payload.newValue);

            return{
                ...state,
                concilationCxc:cxc,
                cxcUpdated:!state.cxcUpdated
            }
        
        case VALIDATE_FLAGS:

            const importApplied = calculateAppliedImport(state.concilationCxc);
            // const residue = state.movement.amount - importApplied - (state.movement.amount - state.residue);
            const residue = state.movement.saldo - importApplied;
            
            const { updatedCxc , totalImport , isValidImportApplied , isValidAplicacion }=validateFlags(state.concilationCxc,state.bankInfo.currencyCode);



            return{
                ...state,
                residue,
                concilationCxc:updatedCxc,
                importApplied,
                // cxcUpdated:!state.cxcUpdated,
                isValidAplicacion,
                isValidImportApplied:isValidImportApplied,
                isValidTotalImportApplied:state.movement.amount>=importApplied,
                isUsingValidResidue:residue >= 0 && residue !== state.movement.saldo ? true : false,
            }

        case SET_REDIRECT_STEP:
            return{
                ...state,
                client:action.payload,
                step:2
            }

        case SET_MOVEMENT:

            return {...state,movement:action.payload}
        case SET_APLICACION:

            const copyCxc = [...state.concilationCxc];

            copyCxc[action.payload.index].aplicacion = action.payload.aplicacion;
            copyCxc[action.payload.index].TC = calculateTC(state.bankInfo.currencyCode,copyCxc[action.payload.index]);

            const aplicacion = copyCxc[action.payload.index].saldoActual.number -  copyCxc[action.payload.index].aplicacion;


            const { updatedCxc:cxcToUpdate ,  isValidAplicacion:isAplicacionValid } = validateFlags(copyCxc,state.bankInfo.currencyCode);

            // console.log(isAplicacionValid);

            copyCxc[action.payload.index].saldoNuevo = {
                number:aplicacion,
                text:mnCurrency(aplicacion)
            }
            
            if(copyCxc[action.payload.index].importeAplicado===0){
                copyCxc[action.payload.index].TC = 0;
                copyCxc[action.payload.index].importeAplicado = 0;
                copyCxc[action.payload.index].aplicacion = 0;
                copyCxc[action.payload.index].saldoNuevo = {
                    text:"$0.0",
                    number:0
                }
            }

            // console.log(copyCxc[action.payload.index]);

            return {...state,concilationCxc:cxcToUpdate,isValidAplicacion:isAplicacionValid}
        
        case SET_LOADING_PAGE:
            return {...state,loadingPage:action.payload}
            
        case SET_STEP:
            return {...state,step:action.payload}

        case SET_BANK_INFO:
            return {...state,bankInfo:action.payload}
            
        case SET_CONCILATION:
            return {...state,concilationCxc:action.payload , loadingPage:false}
        

        case SET_CLIENT:
            return {...state,client:action.payload}
        
        case SET_INPUT_SEARCH_CUSTOMER:

            const textField = !action.payload.length ? 'null' : action.payload;

            return {...state,searchInputCustomer:textField}

        default:
            return initialState    
        
    }
}