import {
  addInfoContact,
  editInfoContact,
  getContacts,
  getInfoContact,
} from "helpers/Apis/contactInfoApi";
import { useEffect, useRef } from "react";
import { useState } from "react";
import useDialog from "customHooks/useDialog";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";
import { Success } from "helpers/alerts";
import { GetCustomer } from "helpers/Apis/Directory";
import { getElementsNotInArray } from "helpers/lists";
import { useHistory } from 'react-router-dom';

/**
 * @type {import("./types").StateUseContacts}
 */
const INITIAL_STATE = {
  customer: undefined,
  isLoading: true,
  status: null,
  search: "",
  totalPages: 0,
  currentPage: 1,
  contact: undefined,
  detailsContact: undefined,
  contacts: [],
  research: false,
  isPerformingAbc: false,
  uensContact: [],
  hasEmail: false,
};

/**
 * @type {import("customHooks/useDialog/types").ReturnUseDialog}
 */
const dialog = {
  close: () => {},
  open: () => {},
  refDialog: undefined,
};

/**
 * @type {import("./types").ContextContacts}
 */
export const CONTEXT_CONTACTS = {
  ...INITIAL_STATE,
  breadcrum: [],
  dialogAdd: dialog,
  dialogEdit: dialog,
  attemptCreateContact: async () => {},
  attemptSendForm: () => {},
  idFormAdd: "",
  updateUensContact: () => {},
  setHasValidEmail: () => {},
  customerId: 0,
  refetchContacts: () => {},
  updateSearchValue: () => {},
  setContact: () => {},
  setContactDetails: async () => {},
  openEdit: () => {},
  closeEdit: () => {},
  attemptUpdateContact: async () => {},
  attemptUpdateForm: () => {},
  idFormEdit: "",
  setPage:()=>{},
  updateStatus:()=>{}
};

/**
 * Custom hook to handle
 * 1. Display contacts of a customer
 * 2. Add contacts to a customer
 * 3. Edit contacts of a customer
 * @param {number} customer - Id of the customer to fetch their contacts
 * @returns {import("./types").ReturnUseContacts}
 */
export default function useContacts(customer) {
  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const dialogAdd = useDialog();
  const dialogEdit = useDialog();

  const idFormAdd = useRef(window.crypto.randomUUID());
  const idFormEdit = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      if (typeof customer !== "number") return;

      setState((current) => ({
        ...current,
        isLoading: true,
        contact: undefined,
        contacts: [],
      }));

      const apiContacts = await getContacts(
        customer,
        state.currentPage,
        state.status,
        state.search
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        contact: undefined,
        ...apiContacts,
      }));
    })();
  }, [state.research, customer,state.currentPage,state.status]);

  useEffect(() => {
    (async function () {
      if (typeof customer !== "number") return;

      const customerApi = await GetCustomer(customer);

      setState((current) => ({
        ...current,
        customer: customerApi,
      }));
    })();
  }, [customer]);

  useEffect(() => {
    if (!state.hasEmail) {
      setState((current) => ({
        ...current,
        uensContact: [],
      }));
    }
  }, [state.hasEmail]);

  const redirectToDo = () => history.push(`/directorio/contactos/to-do?contacto=${state.contact.id}&cliente=${customer}`)

  const refetchContacts = () =>
    setState((current) => ({
      ...current,
      currentPage:1,
      research: !current.research,
    }));

  const attemptCreateContact = async (data) => {
    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const wasAdded = await addInfoContact({
      ...data,
      customerID: customer,
      modifyBy: userInfo[0].fullName,
      uens: state.uensContact.map((uen) => uen.idUen),
    });

    if (wasAdded) {
      Success(() => {}, "Contacto agregado");
      dialogAdd.close();
      refetchContacts();
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));
  };

  const attemptUpdateContact = async (data) => {
    const currentUens = state.uensContact.map(uen=>uen.idUen);

    // UENS that are marked as blocked
    const uensToDisbale = state.uensContact.filter(uen=>uen.email===true).map(uen=>uen.idUen);

    const uensBeforeUpdates = state.detailsContact.uens.map(uen=>uen.uenId);

    // UENS to add (news)
    const uensToAdd =  getElementsNotInArray(currentUens,uensBeforeUpdates);

    // UENS marked as not block
    const uensToAdd2 = state.uensContact.filter(uen=>uen.email===false).map(uen=>uen.idUen);

    const uensToDelete = [...new Set(getElementsNotInArray(uensBeforeUpdates,currentUens))];    

    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const wasUpdated = await editInfoContact({
      ...data,
      contactID: state.detailsContact.id,
      customerID: customer,
      modifyBy: userInfo[0].fullName,
      uensToActivate: [...new Set([...uensToAdd,...uensToAdd2])],
      uensToDisbale,
      uensToDelete,
    });

    if (wasUpdated) {
      Success(() => {}, "Contacto actualizado");
      dialogEdit.close();
      refetchContacts();
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));
  };

  /**
   * Update the status for the filter search of the contacts
   * @param {"Todos"|"true"|"false"} status - Status
   * @returns {void}
   */
  const updateStatus = (status) => {
    if (status === "Todos") {
      setState((current) => ({
        ...current,
        status: null,
      }));
      return;
    }

    setState((current) => ({
      ...current,
      status: status === "false" ? false : true,
    }));
  };

  const attemptSendForm = () => {
    /**
     * @type {HTMLFormElement}
     */
    const domForm = document.getElementById(idFormAdd.current);

    if (domForm) domForm.submit();
  };

  const attemptUpdateForm = () => {
    /**
     * @type {HTMLFormElement}
     */
    const domForm = document.getElementById(idFormEdit.current);

    if (domForm) domForm.submit();
  };

  const setContactDetails = async (id) => {
    const apiContact = await getInfoContact(id);
    setState((current) => ({
      ...current,
      detailsContact: apiContact,
      hasEmail: true,
    }));

    updateUensContact(
      apiContact.uens.map((uen) => ({
        email: uen.email,
        id: uen.id,
        idUen: uen.uenId,
        label: uen.label,
      }))
    );
  };

  /**
   * Update the uens contact that will use the record.
   * The params must be already set with the information to use, **THIS JUST UPDATES THE STATE**
   * @param {import("../UenContact/types").UenContact[]} uensContact - Uens contact
   * @returns {void}
   */
  const updateUensContact = (uensContact) =>
    setState((current) => ({
      ...current,
      uensContact,
    }));

  /**
   * Update the flag that indicate if the contact has a valid email
   * @param {boolean} hasEmail - Flag that indicate if email typed is correct
   * @returns {void}
   */
  const setHasValidEmail = (hasEmail) =>
    setState((current) => ({
      ...current,
      hasEmail,
    }));

  /**
   * Update the contact selected on the table
   * @param {import("./types").ContactTableI} contact - Contact selected
   * @returns {void}
   */
  const setContact = (contact) =>
    setState((current) => ({
      ...current,
      contact,
    }));

  /**
   * Update the search value for the filter text bar
   * @param {string} search - Search value
   * @returns {void}
   */
  const updateSearchValue = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const openEdit = () => {
    dialogEdit.open();
    setContactDetails(state.contact.id);
  };

  const closeEdit = () => {
    dialogEdit.close();
    setState((current) => ({
      ...current,
      hasEmail: false,
      detailsContact: undefined,
      uensContact: [],
    }));
  };

  /**
   * Update the page for the contacts
   * @param {number} page - Page
   * @returns {void}
   */
  const setPage = page => setState(current=>({
    ...current,
    currentPage:page
  }));

  return {
    ...state,
    setPage,
    dialogAdd,
    attemptCreateContact,
    dialogEdit,
    attemptSendForm,
    updateUensContact,
    idFormAdd: idFormAdd.current,
    idFormEdit: idFormEdit.current,
    attemptUpdateContact,
    attemptUpdateForm,
    setHasValidEmail,
    openEdit,
    updateSearchValue,
    setContact,
    setContactDetails,
    refetchContacts,
    closeEdit,
    redirectToDo,
    updateStatus,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/directorio",
        text: "Directorio",
      },
      {
        route: "/",
        text: "Contactos",
      },
    ],
  };
}
