import React, { useState, useContext, useEffect } from "react";

import { ModalDismissButton, Submit } from "../../individual/buttons/Buttons";

//COMPONENTS
import Password from "../../individual/inputs/Password";
import UpdatePassword from "../../general/Forms/User/UpdatePassword";
import {
  Spinner,
  triggerLoading,
  quitLoading,
} from "../../individual/Spinner/Spinner";

//LIBRARIES
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//API
import { confirmIdentity as apiConfirmIdentity
  ,updatePassword } from "../../../helpers/Apis/usuariosApi";

// SCHEMA
import { confirmIdentity, updatePass } from "../../../Schemas/Users/changePass";

//CONTEXT
import LoginContext from "../../../context/Login/LoginContext";

function ChangePassword() {
  const [sendPass, setSendPass] = useState("Enviar");
  const [title, setTitle] = useState("Verifica tu identidad");
  const [stepProcess, setStepProcess] = useState(1);
  const { userId } = useContext(LoginContext);
  const [validation, setValidation] = useState(confirmIdentity);
  const [textSpin,setTextSpin] = useState('Validando...');

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validation),
  });

  const resetForm = () => {
    document.getElementById('formUpdatePass').reset();
    setSendPass('Enviar');
    setTitle('Verifica tu identidad');
    setStepProcess(1)
    setValidation(confirmIdentity);
    setTextSpin('Validando');
  }

  const sendInfo = async (data) => {
    data = {
      ...data,
      userId,
    };

    switch (stepProcess) {
      case 1:
        triggerLoading('spinnerChangePass','buttonsChangePass');

        const isValid = await apiConfirmIdentity(data);

        if (isValid === true) {
          setSendPass("Actualizar");
          setValidation(updatePass);
          setStepProcess(2);
          setTitle("Ingresa tu nueva contraseña");
          setTextSpin('Actualizando...')
        }

        quitLoading('buttonsChangePass','spinnerChangePass');

        break;

      case 2:
        
        if(data.password1!==data.password2){
          document.getElementById('error_pass2').innerText = 'Las contraseñas no coinciden';
          return;
        }

        triggerLoading('spinnerChangePass','buttonsChangePass');

        const wasUpdated = await updatePassword(data);

        if(wasUpdated === true){
          document.getElementById('cancelUpdatePass').click();
        }

        quitLoading('buttonsChangePass','spinnerChangePass');
        break;
    }
  };

  const form = () => {
    switch (stepProcess) {
      case 1:
        return (
          <Password
            labelText="Ingresa tu contraseña actual"
            canSeePassword={true}
            id="confirmIdentity"
            name="password"
            forwardRef={register}
            errors={errors}
            autoFocus={true}
          />
        );

      case 2:
        return <UpdatePassword errors={errors} forwardRef={register} />;

      case 0:
        return <></>;
    }
  };

  return (
    <>
      <button
        type="button"
        className="d-none"
        data-toggle="modal"
        id = 'triggerChangePassword'
        data-target="#changePassword"
      >
      </button>

      <div
        className="modal fade"
        id="changePassword"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="changePassword"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => setStepProcess(1)}>
                  &times;
                </span>
              </button>
            </div>

            <form className="modal-body" id="formUpdatePass" onSubmit={handleSubmit(sendInfo)}>
              {form()}

              <Spinner
                idSpinner = 'spinnerChangePass'
                text = {textSpin}
              />
    
              <div className="modal-footer mt-4" id="buttonsChangePass">
                <ModalDismissButton
                  text="Cancelar"
                  idBtn="cancelUpdatePass"
                  callback={() => resetForm()}
                />

                <Submit aditionalClasses = 'w-25' id="sendPassword" text={sendPass} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
