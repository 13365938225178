import { GetFiles } from '../../helpers/Apis/associateFiles';

export let AssociateFilesConfig = {
  idTable: "list-files",
  idPagination: "pagination-list-files",
  pages: 0,
  rows: [],
  actualPage: 0,
  headerConfig: [
    {
      text: "Fecha carga",
      columnNameDB: "createdDate",
      idHeader: "uploaded-date",
      attributeToPrint: "createdDate",
      sortable: false
    },
    
    {
        text: "Archivo",
        columnNameDB: "fileName",
        idHeader: "uploaded-date",
        attributeToPrint: "fileName",
        sortable: false
    },

    {
        text: "Tipo",
        columnNameDB: "fileType",
        idHeader: "file-type",
        attributeToPrint: "extension",
        sortable: false
    },

    {
        text: "Ejecutivo",
        columnNameDB: "createdBy",
        idHeader: "created-by",
        attributeToPrint: "createdBy",
        sortable: false
    }
  ],
  styleTable: "default",
  cbSelection: () => {},
  urlParams: "",
  idRows: "id",
  sort: {
    sqlSort: true,
    sortASC: false,
  },
  paginationFn:GetFiles,
  attributesResponse: {
    pages: "pages",
    actualPage: "actualPage",
    data: "files",
  },
};