import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";

/**
 * @type {import("./types").MicrosoftBiUrls}
 */
const URLS_FRAMES = {
  ventas: process.env.REACT_APP_BI_VENTAS,
  compras: process.env.REACT_APP_BI_COMPRAS,
  recomendacionPagos:process.env.REACT_APP_BI_PROG_PAGOS
};

/**
 * Render the microsoft csp
 * @param {import("./types").PropsMicrosoftCsp} props - Props
 * @returns {JSX.Element}
 */
export default function MicrosoftCsp({ children = <></>, type = "ventas" }) {
  const dialog = useDialog();

  if (URLS_FRAMES[type] === undefined) return <></>;

  return (
    <div>
      <div onClick={dialog.open}>{children}</div>

      <Dialog
        title="Microsoft CSP"
        forwardRef={dialog.refDialog}
        width={90}
        closeCallback={dialog.close}
      >
        <div className={ui.powerBi}>
          <iframe src={URLS_FRAMES[type]} allowFullScreen={true}></iframe>
        </div>
      </Dialog>
    </div>
  );
}
