import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { getInvoiceStatus } from "helpers/Apis/Documents";
import React, { useState } from "react";
import { useEffect } from "react";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isLoading: true,
  options: [],
};

/**
 * Render the status of the invoice
 * @param {import('./types').StatusInvoiceProps} props - Props
 * @returns {JSX.Element}
 */
export default function StatusInvoice(props) {
  const { from, onChange = () => {}, value = -1 } = props;

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiStatus = await getInvoiceStatus(from);

      /**
       * @type {import("./types").StatusInvoice[]}
       */
      const options = [
        {
          description: "-- SELECCIONA --",
          document: null,
          logicalErase: 0,
          order: 1,
          state: undefined,
          disabled: true,
        },
        {
          description: "Todos",
          document: null,
          logicalErase: 0,
          order: 1,
          state: -1,
        },
      ];

      setState({
        ...state,
        isLoading: false,
        options: [...options, ...apiStatus],
      });
    })();
  }, []);

  if (state.isLoading)
    return (
      <Spinner
        idSpinner="loadingStatusInvoices"
        hidden={false}
        text={<LoadingText>Cargando</LoadingText>}
      />
    );

  return (
    <Select
      labelToDisplay="description"
      options={async () => state.options}
      onChange={onChange}
      defaultSelected={{
        keyToCompare: "state",
        value,
      }}
    />
  );
}
