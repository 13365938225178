import { addFilesFromPc } from "helpers/files";
import { useState } from "react";

/**
 * @type {import("types/typedef/customHooks/useFiles").useFileI}
 */
const initialState = {
  informative: [],
  file: [],
};

/**
 *
 * @param {string[]} validExtensions - Extensions that can be uploaded
 * @param {number?} limitFiles - Number of files allowed to upload
 * @returns
 */
export default function useFiles(validExtensions = [], limitFiles = null) {
  /**
   * @type {[import("types/typedef/customHooks/useFiles").useFileI,()=>void]}
   */
  const [{ file, informative }, setFiles] = useState(initialState);

  /**
   * Attach a new file
   * @param {FileList} filesPc - Files added
   * @returns
   */
  const addFiles = (filesPc) => {
    
    if(filesPc instanceof FileList){
      const { queueFiles, queueInformativeFiles } = addFilesFromPc(
        filesPc,
        validExtensions
      );
  
      setFiles({
        informative: [...queueInformativeFiles, ...informative],
        file: [...queueFiles, ...file],
      });
    }else{
      resetFiles();
    }

    
  };

  /**
   *  Delete file from the attached
   * @param {string} uuidFile - UUID assigned to upload the file
   * @param {number} i - Index of the list clicked
   * @returns {{
   *  attachedFiles:import("types/typedef/files").InformativeFileI[],
   *  filesByteInfo:(import("types/typedef/files").InformativeFileI|File)[]
   * }}
   */
  const deleteFile = (uuidFile, i) => {
    const attachedFiles = informative.filter((file, index) => index !== i);

    const filesByte = file.filter((file, index) => index !== i);

    setFiles({
      informative: attachedFiles,
      file: filesByte,
    });

    return {
      attachedFiles,
      filesByteInfo: filesByte,
    };
  };

  /**
   * Reset the files that the use have handled
   * @returns {void}
   */
  const resetFiles = () => setFiles({
    informative: [],
    file: [],
  });

  /**
   * Get the attribute "accept" for the html input in order to show on the file explorer just the allowed files
   * @type {string}
   */
  const attributeAcceptHtml = validExtensions.reduce(
    (extensions, extension) => (extensions += ` .${extension} ,`),
    ""
  );

  return {
    file: file,
    informative: informative,
    addFiles,
    deleteFile,
    resetFiles,
    attributeAcceptHtml,
  };
}
