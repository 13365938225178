import useFiles from "customHooks/useFiles";
import React from "react";
import { useEffect } from "react";
import scss from "./styles.module.scss";

export const ExcelInput = ({ onChange = () => {} }) => {
  const { attributeAcceptHtml, file, informative, addFiles } = useFiles(
    ["xlsx"],
    1
  );

  useEffect(() => {
    if (file.length <= 0) return;
    onChange(file[0]);
  }, [file]);

  return (
    <div className={scss.container}>
      <label className={scss.excel}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
          />
        </svg>

        <p>Selecciona un excel</p>
        <input
          className="d-none"
          multiple
          type={"file"}
          accept={attributeAcceptHtml}
          onChange={(e) => addFiles(e.target.files)}
        />
      </label>
      {informative.length > 0 ? (
        <p className={scss.fileName}>
          {informative[0].fileName}.{informative[0].extension}
        </p>
      ) : null}
    </div>
  );
};
