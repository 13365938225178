import React from "react";
import PermissionsContext from "./PermissionsContext";
import PermissionsReducer from "./PermissionsReducer";

import { specialProcess } from "../../static/StaticPermissionDefault";
import { useReducer } from "react";

const PermissionsState = (props) => {
  const initialState = {
    1: specialProcess,
  };

  const [state, dispatch] = useReducer(PermissionsReducer, initialState);

  const elementsFather = {
    specialProcess: state.specialProcess,
  };

  return (
    <PermissionsContext.Provider value={elementsFather}>
      {props.children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsState;
