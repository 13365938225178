import { fetchPayMethods } from "helpers/Apis/Banks";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "structure/Document";

/**
 *
 * @param {import("./types").PropsPayMethodsSearchable} props - Props
 * @returns {JSX.Element}
 */
export default function PayMethodsSearchable({
  onChange = () => {},
  required = false,
  errorMessage = "",
  label = "Método de pago",
}) {
  const [pay, setPayMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function initialLoad() {
      const dataFetch = await fetchPayMethods();
      setPayMethods(() =>
        dataFetch.map((item) => ({
          ...item,
          label: item.description,
          value: item.code,
        }))
      );
      setIsLoading(false);
    }

    initialLoad();
  }, []);

  return (
    <div>
      <Label required={label === "" ? false : required}>
        {label}
        <span className="text-danger font-weight-bold">{errorMessage}</span>
      </Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select w-100"
        isClearable={false}
        isSearchable={true}
        isLoading={isLoading}
        placeholder="Selecciona"
        options={pay}
        onChange={(item) => onChange(item)}
      />
    </div>
  );
}
