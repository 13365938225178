/**
 * Actions to execute for the reducer
 * @type {import("./types").ActionsI}
 */
export const Actions = {
  SET_IS_FETCHING_DATA: "SET_IS_FETCHING_DATA",
  SET_FIRST_PAGE_DATA: "SET_FIRST_PAGE_DATA",
  SET_STATUS: "SET_STATUS",
  SET_DOCUMENT: "SET_DOCUMENT",
  SET_ROW: "SET_ROW",
  SET_ROWS: "SET_ROWS",
  SET_DATES: "SET_DATES",
  SET_SOCIAL_REASON: "SET_SOCIAL_REASON",
  TRIGGER_SEARCH: "TRIGGER_SEARCH",
  SET_DOWNLOAD_XLSX:"SET_DOWNLOAD_XLSX",
  SET_PAGE:"SET_PAGE",
  SET_ACCOUNTING:"SET_ACCOUNTING",
  SET_MONTH:"SET_MONTH",
  SET_ACCOUNTING_CHECK : "SET_ACCOUNTING_CHECK",
  SET_CAN_UPDATE_ACCOUNTING:"SET_CAN_UPDATE_ACCOUNTING",
  SET_YEAR:"SET_YEAR",
  SET_DOWNLOAD_PDF:"SET_DOWNLOAD_PDF",
  SET_IS_CANCELLING_INVOICE:"SET_IS_CANCELLING_INVOICE"
};
