import React from "react";
import { useContext } from "react";
import Dialog from "structure/Dialog";
import { Context2fa } from "..";

export default function ModalError() {
  const hook = useContext(Context2fa);

  return (
    <Dialog
      forwardRef={hook.dialogError.refDialog}
      title="Error"
      width={30}
      closeCallback={hook.dialogError.close}
    >
      <p className="text-center my-5">🔴 {hook.error}</p>
    </Dialog>
  );
}
