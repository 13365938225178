import FilterFileStatus from "components/general/Forms/Selects/FilterFileStatus";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React, { useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";

/**
 * Display the associated files of the legal documents
 * @param {object} props - Props
 * @param {number?} props.idRegister - Id of the register to search for
 * @returns {JSX.Element} Component
 */
export default function InvoiceReceptionAssociatedFiles({ idRegister = null }) {
  const { idInvoice } = useParams();

  /**
   * @type {import("./types").QueryParamsFiles}
   */
  const query = useQueryParams();

  const breadcrum = useRef(
    query.modulo === "administracion"
      ? [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/inicio",
            text: "Administracion",
          },
          {
            route: "/administracion/recepcion-facturas",
            text: "Facturas recibidas",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]
      : [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/contabilidad/facturas-recibidas",
            text: "Contabilidad",
          },
          {
            route: "/contabilidad/facturas-recibidas",
            text: "Facturas recibidas",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]
  );

  const id = !idRegister ? idInvoice : idRegister;

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        padding: "0 0 1rem 0",
      }}
    >
      <BreadCrumV2 path={breadcrum.current} />
      <div className="container">
        <AssociateFiles
          idRegister={id}
          idTypeEntity={2}
          pathUpload={`documentos_legales/factura_id_${id}`}
        />
      </div>
    </div>
  );
}
