import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextOdc } from "../..";

export default function SearchOc() {
  const { updateSearch, search } = useContext(ContextOdc);

  return (
    <div>
      <Label>No. ODC</Label>
      <Search
        value={search}
        hideSearchIcon={true}
        type="number"
        placeholder="Ejemplo: 150"
        onChange={(value) => updateSearch(Math.floor(+value))}
        onDelete={()=>updateSearch(0)}
      />

    </div>
  );
}
