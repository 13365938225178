import { Spinner } from "components/individual/Spinner/Spinner";
import useConcept from "customHooks/useConcept";
import scss from "./styles.module.scss";
import React from "react";

export const ConceptOverview = ({ idMovement }) => {
  const { concept } = useConcept({
    idMovement,
  });

  if (concept.isLoading)
    return <Spinner hidden={false} text="Cargando concepto..." />;

  return (
    <>
      <div className={scss.header}>
        <p>Concepto</p>
        <p>Tipo</p>
        <p>Asociado</p>
      </div>
      <div className={scss.row}>
        <p>{concept.info.concept.description}</p>
        <p>{concept.info.concept.type.description}</p>
        <p>
          {concept.info.paid.text} {concept.info.concept.currency}
        </p>
      </div>
    </>
  );
};
