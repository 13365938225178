import React, { useEffect, useState, useContext } from "react";

// APIS
import { getCustomer } from "../../../../helpers/Apis/Directory";

// LIBS
import { useParams } from "react-router";

// HELPERS
import { mnCurrency } from "../../../../helpers/money";

// CONTEXT
import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";

import { ExchangeInformative } from "components/general/ExchangeInformative/ExchangeInformative";

function InfoDocument() {
  const { customerId } = useParams();

  const { tcp, currencyName, currencyBeingUsed } =
    useContext(AddDocumentContext);

  const [customer, setCustomer] = useState({
    socialReason: "",
  });

  useEffect(() => {
    async function initialLoad() {
      const { data } = await getCustomer(+customerId);
      setCustomer(data[0]);
    }

    initialLoad();
  }, []);

  return (
    <>
      <div className="container">
        <span>
          <b>Razon social:</b> {customer.socialReason} |{" "}
        </span>

        <span>
          <b>Moneda: </b>{" "}
          {currencyBeingUsed === null ? "Selecciona la moneda" : currencyName} |
        </span>

        <ExchangeInformative>
          <ExchangeInformative.Dof /> |
          <ExchangeInformative.Bank /> |
          <ExchangeInformative.Enterprise /> |
          <ExchangeInformative.Date />
        </ExchangeInformative>
      </div>
    </>
  );
}

export default InfoDocument;
