import React, { useState } from "react";
import OtpInput from "react-otp-input";
import style from "./styles.module.scss";

/**
 * Render an input for otp
 * @param {import("./types").PropsOtpForm} props - Props
 * @returns {JSX.Element}
 */
export default function OtpForm({ onChange = () => {} }) {
  const [otp, setOtp] = useState("");

  const handleOtpChange = (value) => {
    setOtp(value);
    onChange(value);
  };

  return (
    <div className={style.optContainer}>
      <OtpInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        inputStyle={{
          width: "2.5rem",
        }}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
      />
    </div>
  );
}
