import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import { useState } from "react";
import { saveAs } from "file-saver";

/**
 *
 * @param {number} [id=null] - Id of the file to download
 * @param {string} [fileName=""] - Filename to use on the download file
 * @returns {import("./types").ReturnUseBlobAzure}
 */
export default function useBlobAzure(id = null, fileName = "") {
  const [isDownloading, setIsDownloading] = useState(false);

  const attemptDownload = async () => {
    setIsDownloading(true);

    const file = await downloadFileFromBlobV3(id);

    setIsDownloading(false);

    if (file !== undefined)
      saveAs(file, fileName !== "" ? fileName : window.crypto.randomUUID());
  };

  return {
    isDownloading,
    attemptDownload,
  };
}
