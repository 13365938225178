import React from 'react'
import HomeHeader from '../../../components/general/homeNavBar/HomeHeader'
import { UserProfileNavigation } from '../../../static/StaticBreadCrums'
import { Form } from './Form'

export const UserProfile = () => {
    return (
        <>
            <HomeHeader mostrar={false} bredcrum={UserProfileNavigation} />
            <Form/>
        </>
    )
}
