// @ts-check
import React from "react";
import { Label } from "structure/Document";
import { useEffect } from "react";
import { useState } from "react";
import { useBankAccount } from "structure/BankAccount/hooks/useBankAccount";
import { add } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { getMovement } from "helpers/Apis/Banks/index";
import { parseDateToText } from "helpers/dates";

registerLocale("es", es);

/**
 * Render a input to select the date to add a movement into a bank account
 * @param {import("./types").PropsDateMovement} props - Props
 * @returns {JSX.Element} Render a input to select the date to add a movement into a bank account
 */
export default function DateMovement({
  idMovement = null,
  idBankAccount = null,
  label = "Fecha",
  onChange = () => {},
}) {
  const bankAccount = useBankAccount(idBankAccount);

  const [date, setDate] = useState(new Date());
  const [lastDate, setLastDate] = useState();

  useEffect(() => {

    if(idMovement!==null) return

    const nextDayLastMonthClosed = add(
      new Date(`${bankAccount.account.closingBalanceDate}:`),
      { days: 1 }
    );



    setLastDate(nextDayLastMonthClosed);
  }, [bankAccount, idMovement, idBankAccount]);

  useEffect(()=>{
    (async function(){
      if(idMovement===null) return

      const apiMovement = await getMovement(idMovement);

      setDate(new Date(apiMovement.date));
    })();
  },[idMovement]);

  const handleOnChange = (paramDate) => {
    setDate(paramDate);
    onChange(paramDate);
  };

  if (idMovement === null && idBankAccount === null) return <></>;

  if(idMovement!==null) return <div>
    <Label>{label}</Label>
    <p>{parseDateToText(date)}</p>
  </div>

  return (
    <div>
      <Label>{label}</Label>
      <DatePicker
        onChange={handleOnChange}
        selected={date}
        minDate={lastDate}
        maxDate={new Date()}
        dateFormat="dd/MMM/yyyy"
        locale="es"
      />
    </div>
  );
}
