import styled from "styled-components";

export const Row = styled.div`
  display: grid;
  grid-template-columns: 55% 15% 15% 15%;
  margin: 0 0 20px 0;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 55% 15% 15% 15%;
  font-weight: bold;
  text-align: center;
  position: sticky;
  background-color: white;
  top: 0;
  padding: 10px;
`;

export const ContainerConcepts = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;
