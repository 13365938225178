import React, { useContext } from "react";
import { ContextUenAdmin } from "..";
import style from "./styles.module.scss";
import ModalUpdate from "../ModalUpdate";

export default function OptionsUen() {
  const hook = useContext(ContextUenAdmin);

  if (hook.uen === undefined || hook.uen === null)
    return <div className={style.containerOptionsUen}></div>;

  return (
    <div className={style.containerOptionsUen}>
      <ModalUpdate />
    </div>
  );
}
