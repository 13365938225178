import Icon from "components/individual/HeaderIcon";
import React from "react";
import { useContext } from "react";
import { MenuContext } from "..";
import TextIcon from "../TextIcon";
import scss from "../index.module.scss";
import usePermissions from "customHooks/usePermissions";

/**
 * Change the menu view
 * @param {import("./typed").PropsChageMenuView} props - Props
 * @returns {JSX.Element}
 * @example       
 * <ChangeMenuView
    permission="8d6a195f-4024-46c8-84fd-176c2946001b"
    icon="sells"
    text="Ventas"
    menu="ventas"
/>
 */
export const ChangeMenuView = (props) => {
  const { setMenuToDisplay } = useContext(MenuContext);

  const { checkPermission } = usePermissions();

  if (Object.keys(props).includes("permission")) {
    if (!checkPermission(props.permission)) return <></>;
  }
  return (
    <p className={scss.item} onClick={() => setMenuToDisplay(props.menu)}>
      <TextIcon icon={props.icon} text={props.text} />
      <Icon icon="right" />
    </p>
  );
};
