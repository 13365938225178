import React, { useState } from "react";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import XMLViewer from "react-xml-viewer";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import { useContext } from "react";
import { ContextEmitedCreditNotes } from "../..";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

const INITIAL_STATE = {
  isDownloading: true,
  xmlContent: undefined,
  reader: new FileReader(),
};

/**
 * Download the xml for the credit note
 * @param {import("./types").PropsDownloadXml} props - Props
 * @returns {JSX.Element}
 */
export default function DownloadXml({ autoDownload = true }) {
  const hook = useContext(ContextEmitedCreditNotes);

  const dialog = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    state.reader.onload = (e) => {
      const xmlAsText = e.target.result;
      setState((current) => ({
        ...current,
        xmlContent: xmlAsText,
      }));
    };
  }, [state.reader]);

  const handleDownload = async () => {
    setState(INITIAL_STATE);
    try {
        dialog.open();
    } catch (error) {        
    }

    const file = await downloadFileFromBlobV3(hook.creditNote.xml);

    saveAs(file, `Nota de crédito ${hook.creditNote.documentNumber}.xml`);

    state.reader.readAsText(file);

    setState((current) => ({
      ...current,
      isDownloading: false,
    }));
  };

  const handleClose = () => {
    dialog.close();
    setState(INITIAL_STATE);
  };

  return (
    <>
      <ButtonV2 onClick={handleDownload}>XML</ButtonV2>

      <Dialog
        forwardRef={dialog.refDialog}
        title="Nota de crédito"
        closeCallback={handleClose}
        footer={
          state.isDownloading ? null : (
            <div className="d-flex justify-content-end">
              <ButtonV2 onClick={handleDownload}>Descargar</ButtonV2>
            </div>
          )
        }
      >
        {state.isDownloading ? (
          <Spinner
            text={<LoadingText>Descargando xml</LoadingText>}
            hidden={false}
            idSpinner="downloadingXml"
          />
        ) : state.xmlContent === undefined ? (
          <Spinner
            hidden={false}
            idSpinner="readingXml"
            text={<LoadingText>Leyendo xml</LoadingText>}
          />
        ) : (
          <XMLViewer xml={state.xmlContent} />
        )}
      </Dialog>
    </>
  );
}
