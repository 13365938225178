import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import SystemInside from "./SystemInside";
import ConfirmationTempPass from "./components/general/ConfirmationTempPass/ConfirmationTempPass";

/* STATES */
import TableState from "./context/Table/TableState";
import LoginState from "./context/Login/LoginState";
import ViewState from "./context/View/ViewState";

import "bootstrap";
import { createContext } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { SocketProvider } from "context/Socket";
import "./styles/Mobile.scss";
import useLicense from "customHooks/useLicense";
import License from "pages/License";
import InvalidLicense from "pages/InvalideLicense";

/**
 * @type {import("types").CONFIG}
 */
const DEFAULT_CONFIGURATION = {
  checkPermissions: true,
  idleSessionInMs: 300000,
  validateClosePeriodDate: true,
  mode: "unknown",
};

/**
 * @type {import("types").CONFIG}
 */
const configuration =
  process.env.REACT_APP_CONFIG === undefined
    ? DEFAULT_CONFIGURATION
    : JSON.parse(process.env.REACT_APP_CONFIG);
/**
 * @type {import("react").Context<import("types").CONFIG>}
 */
export const ContextConfig = createContext(DEFAULT_CONFIGURATION);
const { Provider } = ContextConfig;

/**
 * @type {import("react").Context<import("customHooks/useLicense/types").StateLicense|undefined>}
 */
export const LicenseContext = createContext(undefined);

function App() {
  const license = useLicense();

  if (license.isValidating) return <License />;

  if (!license.licenseInformation.validLicense) return <InvalidLicense message={license.licenseInformation.message}/>

    return (
      <Provider value={{ ...configuration }}>
        <LoginState>
          <TableState>
            <ViewState>
              <Router>
                {/* <Router basename={`${process.env.REACT_APP_FRONTEND}`}> */}
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route
                    exact
                    path={`/pass-temporal/:user`}
                    component={ConfirmationTempPass}
                  />
                  <SocketProvider>
                    <SystemInside />
                  </SocketProvider>
                </Switch>
                {/* <FooterPraxia /> */}
              </Router>
            </ViewState>
          </TableState>
        </LoginState>
      </Provider>
    );
}

export default App;
