import { Spinner } from "components/individual/Spinner/Spinner";
import useEgress from "customHooks/useEgress";
import React from "react";
import RowDeductible from "./RowDeductible";
import RowNonDeductible from "./RowNonDeductible";
import {
  RowHeader,
  Residue,
  Associated,
  ResidueTitle,
  Remain,
  EgressHeader,
  Import,
  Invoice,
  Egress,
} from "./styles";

export default function EgressOverview({
  idMovement,
  idBankAccount,
  onLoaded = () => {},
}) {
  const { state } = useEgress(idMovement, +idBankAccount, onLoaded);

  if (state.isLoading)
    return <Spinner hidden={false} text="Cargando gastos..." />;

  return (
    <>
      {state.egresses.deductible.length + state.egresses.nonDeductible.length <=
      0 ? (
        <h1 className="text-center my-5">No hay asociaciones</h1>
      ) : (
        <div className="p-5">
          <RowHeader>
            <p>Proveedor</p>
            <p>Factura</p>
            <p>Concepto</p>
            {/* <p>Moneda</p> */}
            <p>Total</p>
            <Residue>
              {/* <ResidueTitle>Saldo</ResidueTitle> */}
              <Associated>Asociado</Associated>
              {/* <Remain>Por asociar</Remain> */}
            </Residue>
            <EgressHeader>
              <Import>Importe egreso</Import>
              {/* <Invoice>A la factura</Invoice> */}
              {/* <Egress>Al egreso</Egress> */}
            </EgressHeader>
          </RowHeader>
          {state.egresses.deductible.map((egress) => (
            <RowDeductible
              key={window.crypto.randomUUID()}
              invoice={egress.invoice}
              concept={egress.expense}
            />
          ))}
          {state.egresses.nonDeductible.map((nonDeductible) => (
            <RowNonDeductible
              nonDeductible={nonDeductible}
              currencyMovement={state.bank.currencyCode}
            />
          ))}
        </div>
      )}
    </>
  );
}
