import axios from "axios";
import { URL_BASE } from "../../../routes/routes";
import "./styles.scss";
import { mnCurrency } from "../../../helpers/money";

const headerConfig = [
  {
    text: "ID",
    idHeader: "id",
    attributeToPrint: "bankAccountID",
    sortable: false,
  },
  {
    text: "Banco",
    idHeader: "banco",
    attributeToPrint: "shortName",
    sortable: false,
    // css: 'formatingBankColum'
  },
  {
    text: "Cuenta",
    idHeader: "cuenta",
    attributeToPrint: "accountNumber",
    sortable: false,
  },
  {
    text: "Clabe",
    idHeader: "clabe",
    attributeToPrint: "clabe",
    sortable: false,
  },
  {
    text: "Moneda",
    idHeader: "moneda",
    attributeToPrint: "currency",
    sortable: false,
  },
  {
    text: "Estatus",
    idHeader: "status",
    attributeToPrint: "statusDescription",
    sortable: false,
  },
  // {
  //     text: "SAT",
  //     idHeader: "sat",
  //     attributeToPrint: "SAT",
  //     sortable: false,
  // },

  // {
  //     text: "Saldo inicial",
  //     idHeader: "saldoinicial",
  //     attributeToPrint: "saldoInicial",
  //     sortable: false,
  // },
];

function cbSelection(e) {
  // alert('Seleccionaste una fila de la tabla');
  console.log(e);
}
const sort = {
  sqlSort: true,
  sortASC: true,
};

export async function getBankAccont(
  page,
  order,
  columnOrdering,
  additionalQuery = ""
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/getbankaccounts?pagina=${page}&orden=${order}&columna=${columnOrdering}${additionalQuery}`
    );
    if (data.status === 200) {
      // Success(() => {}, data.message);
      console.log("Cargando informacion de la data");
      console.log(data.data[0]);

      return data.data;
    }
  } catch (error) {
    Error(() => {}, error);
    return;
  }
}
const attributesResponse = {
  pages: "pages",
  actualPage: "actualPage",
  data: "banckAccount",
};
export let configurationTableBankAccounts = {
  idTable: "bankAccountId",
  idPagination: "bankAccountPagination",
  pages: 0,
  rows: [],
  actualPage: 0,
  headerConfig,
  cbSelection,
  idRows: "bankAccountID",
  sort,
  // paginationFn: getBankAccont,
  attributesResponse,
  // styleTable: 'd-block'
};

export function parseInitialAccount(rows) {
  rows.map((bankAccont) => {
    bankAccont.saldoInicial = mnCurrency(bankAccont.saldoInicial);
  });
  return rows;
}
