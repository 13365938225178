import Select from "components/individual/SelectV4";
import React from "react";

/**
 * Render a select input for the currency. This component can return the `ID` or the `CurrencyCode` accorindg the needs of the code
 * @param {import("./types").PropsCurrencySelect} props - Props
 * @returns {JSX.Element}
 */
export default function CurrencySelectV2(props) {
  /**
   * Handle the currency selected
   * @param {import("./types").CurrencyOption} currency - Currency option selected
   */
  const handleOnChange = (currency) => {
    if (Object.keys(props).includes("onChangeValue")) {
      const id = props.criteriaToUse === "code" ? currency.code : currency.id;

      props.onChangeValue(id);
    }
  };

  const options = props.displayAll
    ? [
        {
          id: null,
          code: null,
          label: "Todos",
        },
        {
          id: 1,
          code: "MXN",
          label: "MXN",
        },
        {
          id: 2,
          code: "USD",
          label: "USD",
        },
      ]
    : [
        {
          id: null,
          code: null,
          label: "--SELECCIONA--",
          disabled: true,
        },
        {
          id: 1,
          code: "MXN",
          label: "MXN",
        },
        {
          id: 2,
          code: "USD",
          label: "USD",
        },
      ];

  return (
    <Select
      defaultSelected={{
        keyToCompare: props.criteriaToUse === "code" ? "code" : "id",
        value: props.defaultValue,
      }}
      onChange={handleOnChange}
      labelToDisplay="label"
      options={async () => options}
    />
  );
}
