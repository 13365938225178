import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import "types/typedef/customHooks/useContac";
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  ErrorMessage,
  InputDateYearMonth,
  InputText,
} from "components/individual/inputs/Inputs";
import Phone from "components/individual/inputs/Phone";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { validationSchema } from "Schemas/Contact/contact";
import { v4 as uuid } from "uuid";
import {
  ContainerName,
  ContainerMiddleName,
  ContainerEmail,
  ContainerFather,
  ContainerMother,
  ContainerPosition,
  ContainerDate,
  ContainerUserName,
  ContainerExecutive,
  ContainerSelectRole,
} from "./FormContactStyles";
import Executives from "components/general/Forms/Selects/Executives";
import SelectRoles from "components/general/Forms/Selects/Roles";
import { Label } from "structure/Document";
import ToggleComponent from "components/individual/inputs/Toggle";

registerLocale("es", es);

const FormContactContext = createContext();
const { Provider } = FormContactContext;

/**
 * Name component
 * @param {object} props - Props component
 * @param {string} [props.name=null] - name attribute of the input
 * @param {string} [props.defValue=null] - Value to set on input when loads
 * @param {string} [props.placeholder=""] - Placeholder
 * @returns {JSX.Element} Component
 */
export const Name = ({
  name = "nombre",
  defValue = null,
  placeholder = "Nombre...",
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "nombre" : name;

  const id = useRef(uuid());

  return (
    <ContainerName>
      <Label required>Nombre</Label>
      <InputText
        labelTxt=""
        name={nameInput}
        id={id.current}
        placeholderTxt={placeholder}
        defaultValue={defValue}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerName>
  );
};

export const MiddleName = ({
  name = "middleName",
  defValue = null,
  placeholder = "Segundo nombre...",
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "middleName" : name;

  const id = useRef(uuid());

  return (
    <ContainerMiddleName>
      <InputText
        labelTxt="Segundo nombre"
        name={nameInput}
        id={id.current}
        defaultValue={defValue}
        forwardRef={register}
        placeholderTxt={placeholder}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
      {/* <ErrorMessage message={errors["nombre"]?.message} /> */}
    </ContainerMiddleName>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {string?} props.value - Value
 * @returns
 */
export const TicketRol = ({ value = null }) => {
  const { register, errors } = useContext(FormContactContext);

  const [selected, setSelected] = useState(null);
  const [initialValue, setInitialValue] = useState(undefined);

  const [options, setOptions] = useState([
    {
      label: "Cliente",
      value: "customer",
    },
    {
      label: "Administrador",
      value: "administrator",
    },
    {
      label: "Ingeniero",
      value: "engineer",
    },
  ]);

  useEffect(() => {
    if (value === null) {
      setInitialValue(null);
      return;
    }
    setSelected(value);

    const item = options.findIndex((item) => item.value === value);

    setInitialValue(item);
  }, [value]);

  if (initialValue === undefined) return <></>;

  return (
    <div>
      <Label>Rol tickets</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select w-100"
        placeholder={`Selecciona`}
        defaultValue={options[initialValue]}
        isClearable={false}
        isSearchable={true}
        options={options}
        onChange={(option) => setSelected(option.value)}
      />
      <p className="text-danger">{errors?.ticketRol?.message}</p>
      <input type="hidden" value={selected} ref={register} name="ticketRol" />
    </div>
  );
};

/**
 * Render the input for emails
 * @param {import("./types").InputEmail} props - Props
 * @returns {JSX.Element}
 */
export const Email = ({
  name = "email",
  defValue = null,
  placeholder = "email@mail.com",
  onChange = () => {}
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "email" : name;

  const id = useRef(uuid());

  /**
   * Evaluate email is correct
   * @param {string} email - Email typed
   */
  const handleOnChange = (email) => {
    const regexExpression = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isValidEmail = regexExpression.test(email);

    onChange(isValidEmail)
  }

  return (
    <ContainerEmail>
      <Label required>Email</Label>
      <InputText
        labelTxt=""
        name={nameInput}
        id={id.current}
        defaultValue={defValue}
        forwardRef={register}
        placeholderTxt={placeholder}
        onChange={handleOnChange}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerEmail>
  );
};

export const Father = ({
  name = "apellidoP",
  defValue = null,
  placeholder = "Apellido paterno...",
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "apellidoP" : name;

  const id = useRef(uuid());

  return (
    <ContainerFather>
      <Label required>Apellido paterno</Label>
      <InputText
        labelTxt=""
        placeholderTxt={placeholder}
        name={nameInput}
        aditionalClasses
        id={id.current}
        defaultValue={defValue}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerFather>
  );
};

/**
 * Render a cellphone input
 * @param {import("./types").CellPhoneField} props - Props
 * @returns {JSX.Element}
 */
export const Cellphone = ({ name = "cellphone", defValue = 52 , lada = "" , number = "" }) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "cellphone" : name;

  return (
    <div>
      <Phone
        htmlFor={nameInput}
        labelText="Celular"
        nameInput={nameInput}
        idInput={nameInput}
        placeholderTxt="Lada + Numero"
        forwardRef={register}
        value={defValue}
        lada={lada}
        number={number}
      />

      <ErrorMessage
        id={`error_${nameInput}`}
        message={errors?.[nameInput]?.message}
      />
    </div>
  );
};

/**
 * Render the contact form
 * @param {import("./types").CellPhoneField} props - Props
 * @returns {JSX.Element}
 */
export const PhoneNumber = ({ name = "phone", defValue = 52 , lada = "" , number = "" }) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "phone" : name;

  return (
    <div>
      <Phone
        htmlFor={nameInput}
        aditionalClasses="w-100"
        labelText="Telefono"
        nameInput={nameInput}
        idInput={nameInput}
        placeholderTxt="Lada + Numero"
        forwardRef={register}
        value={defValue}
        lada={lada}
        number={number}
      />

      <ErrorMessage
        id={`error_${nameInput}`}
        message={errors?.[nameInput]?.message}
      />
    </div>
  );
};

export const Mother = ({
  name = "apellidoM",
  defValue = null,
  placeholder = "Apellido materno...",
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "apellidoM" : name;

  const id = useRef(uuid());

  return (
    <ContainerMother>
      <Label>Apellido materno</Label>
      <InputText
        labelTxt=""
        placeholderTxt={placeholder}
        name={nameInput}
        id={id.current}
        defaultValue={defValue}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerMother>
  );
};

export const Position = ({
  name = "puesto",
  defValue = null,
  placeholder = "Puesto...",
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "puesto" : name;

  const id = useRef(uuid());

  return (
    <ContainerPosition>
      <InputText
        labelTxt="Puesto"
        placeholderTxt={placeholder}
        aditionalClasses="w-100"
        name={nameInput}
        id={id.current}
        defaultValue={defValue}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerPosition>
  );
};

export const Add = ({
  children,
  idForm = "addContact",
  validation,
  className = "",
  onSubmit = () => {},
}) => {
  const schema = !validation ? validationSchema : validation;

  const { register, errors, control , reset } = useForm({
    resolver: yupResolver(schema),
  });

  return <Provider value={{ register, errors, control , reset }}></Provider>;
};

/**
 * Render a toggle to mark if the contact is for payments
 * @returns {JSX.Element} Label with the toggle
 */
export const ContactPayments = ({ onChange = () => {}, isChecked = false , name = "isForPayment" }) => {
  const { reset , control } = useContext(FormContactContext);

  useEffect(()=>{
    reset({
      [name]:isChecked
    });
  },[isChecked])

  return (
    <div className="d-flex flex-column">
      <Label className="mb-2">Contacto de pagos</Label>


      <Controller
        control={control}
        name={name}
        defaultValue={isChecked}
        render={({ onChange, value }) => (
          <ToggleComponent
          isChecked={value}
          labelActive="Si"
          labelInactive="No"
          onChange={(check) => onChange(check)}
          
        />
        )}
      />


    </div>
  );
};

/**
 * Render a toggle to mark if the contact is for payments
 * @returns {JSX.Element} Label with the toggle
 */
export const IsActive = ({ isChecked = false , name = "isActive" }) => {
  const { reset , control } = useContext(FormContactContext);

  useEffect(()=>{
    reset({
      [name]:isChecked
    });  },[isChecked])

  return (
    <div className="d-flex flex-column">
      <Label className="mb-2">Estatus</Label>


      <Controller
        control={control}
        name={name}
        defaultValue={isChecked}
        render={({ onChange, value }) => (
          <ToggleComponent
          isChecked={value}
          labelActive="Activo"
          labelInactive="Inactivo"
          onChange={(check) => onChange(check)}
          
        />
        )}
      />


    </div>
  );
};

/**
 * Render a toggle to mark if the contact is for payments
 * @returns {JSX.Element} Label with the toggle
 */
export const CollectionContact = ({
  onChange = () => {},
  isChecked = false,
  name = "isForColletion"
}) => {

  const { control , reset } = useContext(FormContactContext);

  useEffect(()=>{
    reset({
      [name]:isChecked
    });
  },[isChecked]);


  return (
    <div className="d-flex flex-column">
      <Label className="mb-2">Contacto de cobranza</Label>

      <Controller
        control={control}
        name={name}
        defaultValue={isChecked}
        render={({ onChange, value }) => (
          <ToggleComponent
          isChecked={value}
          labelActive="Si"
          labelInactive="No"
          onChange={(check) => onChange(check)}
        />
        

        )}
      />

    </div>
  );
};

export const DateInput = ({ label = "Fecha" , name = "birthDay" , date = null }) => {
  const { reset , control } = useContext(FormContactContext);

  useEffect(()=>{

    if(typeof(date)!=="string") return;
    
    reset({
      [name]:new Date(`${date}:`)
    });
  },[date]);


  return (
    <div>
      <Label>{label}</Label>
      <Controller
        control={control}
        name={name}
        defaultValue={typeof(date)==="string" ? new Date(`${date}:`) : null}
        render={({ onChange, onBlur, value }) => (
          <ReactDatePicker  
            isClearable={true}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            dateFormat={"dd/MMM/yy"}
            locale="es"
            placeholderText="Selecciona una fecha"
          />
        )}
      />
    </div>
  );
};

// -----------------------USUARIOS--------------------------------------------------------------------------------->
export const DateMonthYear = ({
  name,
  defValue = null,
  placeholder = "Fecha...",
  onChange,
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "dateMothYear" : name;

  const id = useRef(uuid());

  return (
    <ContainerDate>
      <Label required>Fecha nacimiento</Label>
      <InputDateYearMonth
        id={id}
        name={nameInput}
        labelText=""
        placeHolderText="Seleccione una fecha"
        date={defValue}
        inputChange={onChange}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerDate>
  );
};
export const UserName = ({
  name,
  defValue = null,
  placeholder = "Nombre de usuario...",
  onChange,
}) => {
  const { register, errors } = useContext(FormContactContext);

  const nameInput = !name ? "userName" : name;

  const id = useRef(uuid());

  return (
    <ContainerUserName>
      <Label required>Nombre de usuario</Label>
      <InputText
        labelTxt=""
        name={nameInput}
        id={id.current}
        placeholderTxt={placeholder}
        defaultValue={defValue}
        forwardRef={register}
      />
      <ErrorMessage message={errors?.[nameInput]?.message} />
    </ContainerUserName>
  );
};
export const Executive = ({ name, defValue = null, onChange = () => {} }) => {
  const { register, errors } = useContext(FormContactContext);
  const [executive, setExecutive] = useState(defValue);

  const nameInput = !name ? "executive" : name;

  useEffect(() => {
    onChange(executive);
  }, [executive]);

  const id = useRef(uuid());

  return (
    <ContainerExecutive>
      <Executives
        id={id.current}
        name={`${nameInput}`}
        label="Jefe directo"
        addTodos={true}
        forwardRef={register}
        errors={errors}
        onChange={(e) => {
          setExecutive(e);
        }}
        value={executive}
      />
    </ContainerExecutive>
  );
};
export const RolesSelect = ({
  name,
  defValue = null,
  placeholder = "Roles...",
  onChange,
}) => {
  const { register, errors } = useContext(FormContactContext);
  const [role, setRole] = useState(defValue);

  const nameInput = !name ? "roles" : name;

  const id = useRef(uuid());

  return (
    <ContainerSelectRole>
      <Label required>Rol</Label>
      <SelectRoles
        id={id}
        name={name}
        label=""
        addTodos={true}
        forwardRef={register}
        errors={errors}
        onChange={(e) => {
          setRole(e.value);
        }}
        value={role}
      />
    </ContainerSelectRole>
  );
};

/**
 * Handle the phone number
 * @param {object} props - Props
 * @param {string?} props.name - Name attribute for the input
 * @param {()=>void} props.onChange - Information of the number when user starts typing
 * @param {string?} props.value - Default value to load on the input when it renders
 * @returns {JSX.Element} Number component for the user
 */
export const UserNumber = ({
  name = "phone",
  onChange = () => {},
  value = 52,
}) => {
  const id = useRef(window.crypto.randomUUID());

  const { register, errors } = useContext(FormContactContext);

  return (
    <div className="d-flex flex-column">
      <Label>Número de celular</Label>
      <Phone
        htmlFor={id.current}
        aditionalClasses="w-100"
        labelText=""
        nameInput={name}
        idInput={id.current}
        placeholderTxt="Lada + Numero"
        forwardRef={register}
        onChange={onChange}
        value={value}
      />

      <ErrorMessage
        id={`error_${id.current}`}
        message={errors?.[name]?.message}
      />
    </div>
  );
};

// <-----------------------USUARIOS---------------------------------------------------------------------------------

/**
 *  Render a form to add contacts or users
 * @param {import("./types").PropsFormContact} props - Props
 * @returns
 */
export function FormContact({
  children = <></>,
  idForm = "addContact",
  validation,
  className = "",
  onSubmit = () => {},
}) {
  const schema = !validation ? validationSchema : validation;

  const { register, handleSubmit, errors, control , reset } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const addContact = (data) => onSubmit(data);

  return (
    <Provider value={{ register, errors, control , reset }}>
      <form
        onSubmit={handleSubmit(addContact, (error, e) => console.log(error))}
        className={className}
        id={idForm}
      >
        {children}
      </form>
    </Provider>
  );
}
export function FormUser({
  children,
  idForm = "addUser",
  validation,
  className = "",
  onSubmit = () => {},
}) {
  const schema = !validation ? validationSchema : validation;

  const { register, handleSubmit, errors, control , reset } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const addUser = (data) => onSubmit(data);

  return (
    <Provider value={{ register, errors, control, schema , reset }}>
      <form
        onSubmit={handleSubmit(addUser, (error, e) => console.log(error))}
        className={className}
        id={idForm}
      >
        {children}
      </form>
    </Provider>
  );
}

FormContact.Add = Add;
FormContact.Name = Name;
FormContact.MiddleName = MiddleName;
FormContact.Email = Email;
FormContact.Mother = Mother;
FormContact.Father = Father;
FormContact.Position = Position;
FormContact.PhoneNumber = PhoneNumber;
FormContact.Cellphone = Cellphone;
FormContact.ContactPayments = ContactPayments;
FormContact.CollectionContact = CollectionContact;
FormContact.Aniversary = DateInput;
FormContact.Status = IsActive;

// --------------USUARIOS----------
FormUser.DateMonthYear = DateMonthYear;
FormUser.UserName = UserName;
FormUser.Executive = Executive;
FormUser.RolesSelect = RolesSelect;
FormUser.UserNumber = UserNumber;
FormUser.TicketRol = TicketRol;
