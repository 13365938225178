import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { createContext } from "react";
import useAuthInvoice from "./useAuthsInvoice";
import Filter from "./Filter";
import FilterMobile from "structure/FilterMobile";
import { parseDateToText } from "helpers/dates";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Table from "./Table";

/**
 * @type {import("react").Context<import("./types").ContextAuthsInvoice|undefined>}
 */
export const ContextAuthsInvoice = createContext(undefined);

export default function AuthsInvoice() {
  const hook = useAuthInvoice();

  return (
    <ContextAuthsInvoice.Provider value={hook}>
      <BreadCrumV2 path={hook.breadcrum} />

      <div className={ui.module}>
        <div className={ui.desktopFilter}>
          <Filter />
          <ButtonV2 type="submit" form={hook.formDom.current?.id}>
            Buscar
          </ButtonV2>
        </div>

        <FilterMobile
          pills={[
            {
              label: "Desde/Hasta",
              value: `${parseDateToText(hook.from)} - ${parseDateToText(
                hook.to
              )}`,
            },
            {
              label: "No. pedido",
              value: hook.noInvoice,
            },
            {
              label: "No. factura",
              value: hook.noLegalDocument,
            },
          ]}
        >
          <Filter />
        </FilterMobile>

        <Table />
      </div>
    </ContextAuthsInvoice.Provider>
  );
}
