import { RangeDate, From, To } from "components/general/RangeDate";
import { ContextLogs } from "pages/Log/LogEmail";
import React, { useContext } from "react";

export default function Dates() {
  const hook = useContext(ContextLogs);

  return (
    <RangeDate>
      <From
        selected={hook.from}
        onChange={(date) => hook.setRangeDate(date, hook.to)}
      />
      <To
        selected={hook.to}
        onChange={(date) => hook.setRangeDate(hook.from, date)}
        maxDate={new Date()}
      />
    </RangeDate>
  );
}
