import React, { forwardRef } from "react";

/**
 * @param {import('./types').PropsInput} props - Props
 */
export const Input = (props) => {
  return <input {...props} ref={props?.forwardRef} />;
};

/**
 * @param {import('./types').PropsInput} props - Props
 */
export const InputV2 = forwardRef((props, ref) => {
  return <input {...props} ref={ref}/>;
});
