import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Success } from "helpers/alerts";
import React, { useContext } from "react";
import { ContextModalDisassociateCxc } from "..";

export const DisassociateConcilationCxc = () => {
  const {
    closeModal,
    canDisplayDisassociateBtn,
    attemptDisassociate,
    onSuccess,
    state
  } = useContext(ContextModalDisassociateCxc);

  if(state.isDisassociating) return <Spinner hidden={false} text='Des-asociando conciliaciones...'/>

  return canDisplayDisassociateBtn ? (
    <>
      <hr />
      <div className="d-flex justify-content-end">
        <ButtonV2
          className="mt-2"
          onClick={async () => {
            const wasDone = await attemptDisassociate();
            if (wasDone) {
              onSuccess();
              closeModal();
              Success(()=>{},'Des-asociado')
            }
          }}
        >
          Des-asociar
        </ButtonV2>
      </div>
    </>
  ) : null;
};
