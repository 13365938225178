import InformativeTooltip from "components/individual/InfoTooltip";
import useClabe from "customHooks/useClabe";
import React, { useEffect, useState } from "react";
import { Label } from "structure/Document";

/**
 * Render a input to type the CLABE of a bank
 * @param {import("./types").PropsCLABE} props - Props
 * @returns {JSX.Element}
 */
export const CLABE = ({
  value = "",
  required = false,
  onChange = () => {},
  displayTooltip = true,
  claveSat = null,
}) => {
  const [clabe, setClabe] = useState(
    value === null || value === undefined ? "" : value
  );
  const [isValid, setIsValid] = useState(false);
  const [outsideClaveValidation, setOutsideClaveValidation] = useState(true);

  const validations = useClabe(clabe);

  const updateCLABE = (CLABE) => setClabe(CLABE);

  useEffect(() => {
    /**
     * Check if the clave from the outside equals with the CLABE typed on the input
     * @param {string} firstCharClabe - First 3 letters of the CLABE
     * @returns {boolean}
     */
    function validateOutsideClaveSat(firstCharClabe = "") {
      // User havent give an outside AND clave and is not required
      if (claveSat === null && !required) return true;

      const parsedCLABE = firstCharClabe.substring(0, 3);

      if (parsedCLABE.length === 0 && !required) return true;

      // CLABE (first 3 digits) is same as the code from the outside
      if (claveSat === parsedCLABE) return true;

      // Not valid
      return false;
    }

    function checkIsValidClabe() {
      // User typed clabe (incompleted yet)
      if (clabe.length > 0 && !validations?.ok) return false;

      // User didn't type CLABE but input is not required
      if (clabe.length === 0 && !required) return true;

      return validations.ok;
    }

    const isValidOutsideClaveSat = validateOutsideClaveSat(clabe);
    const isValidClabe = checkIsValidClabe();

    const validOkClabe = isValidOutsideClaveSat && isValidClabe;

    setIsValid(validOkClabe);
    setOutsideClaveValidation(isValidOutsideClaveSat);

    onChange({
      ...validations,
      ok: validOkClabe,
    });
  }, [validations, clabe, claveSat, value]);

  if (validations === undefined) return;

  function getIfRequiredInput() {
    // Campo no obligatorio y no hay texto escrito en el input
    if (required === false && clabe === "") {
      return false;
    }

    if (!isValid) {
      return true;
    }

    return false;
  }

  return (
    <>
      <div className="d-flex">
        <Label required={getIfRequiredInput()}>CLABE</Label>

        {validations.ok && displayTooltip ? (
          <InformativeTooltip>
            <ul>
              <li>Banco: {validations.bank}</li>
              <li>Código: {validations.code.bank}</li>
            </ul>

            <hr />

            <ul>
              <li>Ciudad: {validations.city}</li>
              <li>Código: {validations.code.city}</li>
            </ul>
          </InformativeTooltip>
        ) : null}
      </div>

      <input
        type="number"
        value={clabe}
        onChange={(e) => updateCLABE(e.target.value)}
        placeholder="Escribe aquí"
      />
      {getIfRequiredInput() ? (
        <p className="text-danger font-weight-bold">{validations.message}</p>
      ) : null}
      {!outsideClaveValidation ? (
        <p className="text-danger font-weight-bold">
          La razon social del banco no coincide con la CLABE escrita
        </p>
      ) : null}
    </>
  );
};
