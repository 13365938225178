import React, { createContext, useEffect, useRef, useState } from "react";
import { getMyTodos, getMyTodosBySection } from "helpers/Apis/todo";
import CardToDo from "./Card";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Pagination } from "components/individual/Pagination";
import ui from "./styles.module.scss";

/**
 * @type {import("./types").StateCardsToDo}
 */
const INITIAL_STATE = {
  todos: [],
  isFetching: true,
  pagination: {
    pages: 1,
    currentPage: 1,
  },
};

/**
 * @type {import("react").Context<import("./types").CardsToDoContext|undefined>}
 */
export const CardsToDoContext = createContext(undefined);
const { Provider } = CardsToDoContext;

/**
 * Render the todo's pending of the current user
 * @param {import("./types").CardToDoProps} props - Props
 * @returns {JSX.Element}
 */
export default function CardsToDo({
  type = "documentos",
  id = null,
  displayImportants = false,
  displayDones = false,
  refetchFlag = false,
  showGlobalImportants = false,
  fetchJustImportants = true,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const [flag, setFlag] = useState(false);

  const fetchPage = async (page = 1) => {
    setState({
      ...state,
      isFetching: true,
      todos: [],
    });

    let apiTodos = [];

    if (id === null || displayImportants === true) {
      apiTodos = await getMyTodos(type, showGlobalImportants, null, page);
    } else {
      apiTodos = await getMyTodosBySection(
        type,
        id,
        displayDones,
        fetchJustImportants,
        page
      );
    }

    setState({
      ...state,
      isFetching: false,
      pagination: {
        pages: apiTodos.page.pages,
        currentPage: apiTodos.page.actualPage,
      },
      todos: apiTodos.myTodos.map((todo) => ({
        ...todo,
        uuid: window.crypto.randomUUID(),
      })),
    });
  };

  useEffect(() => {
    (async function () {
      fetchPage(1);
    })();
  }, [
    type,
    displayImportants,
    displayDones,
    refetchFlag,
    fetchJustImportants,
    flag,
  ]);

  /**
   * Update the task to done
   * @param {number?} index - Index
   * @param {boolean?} status - Flag of done status
   */
  const updateDone = (index = null, status = null) => {
    if (index !== null && status !== null) {
      let unrefTodos = [...state.todos];
      unrefTodos[index].isOpen = status;

      setState({
        ...state,
        todos: state.todos.filter((todo, i) => i !== index),
      });
    }

    setFlag(!flag);
  };

  /**
   * Update the task content
   * @param {number} index - Index
   * @param {string} content - Content of the task
   * @returns {void}
   */
  const updateTaskContent = (index, content) => {
    let unrefTodos = [...state.todos];
    unrefTodos[index].todoNote = content;

    setState({
      ...state,
      todos: unrefTodos,
    });
  };

  if (state.isFetching)
    return <Spinner hidden={false} text={`Cargando to-do de ${type}`} />;

  if (state.todos.length <= 0)
    return <h1 className="text-center d-flex justify-content-center align-items-center">No hay tareas pendientes</h1>;

  return (
    <Provider
      value={{
        updateDone,
        updateTaskContent,
      }}
    >
      <div className={ui.container}>
        {state.todos.map((todo, i) => (
          <CardToDo
            key={`${key.current}=${i}`}
            todo={todo}
            type={type}
            index={i}
          />
        ))}
      </div>
      <Pagination
        actualPage={state.pagination.currentPage}
        pages={state.pagination.pages}
        onChange={fetchPage}
      />
    </Provider>
  );
}
