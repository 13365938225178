import React, { useEffect, useState } from "react";
import DatePartialitieV2 from "./DatePartialitieV2";
import { mnCurrency } from "../../../../../helpers/money";
import Percentage from "../../../../../components/individual/inputs/Percentage";
import DatePartialitie from "./DatePartialitie";
import ui from './styles.module.scss';

function Partialitie({
  noPartialitie = "?",
  price = 0,
  percentage,
  date,
  onChange = () => {},
  resetDate,
  minDate = new Date(),
  maxDate = new Date(),
  disableDatesRestriction = false
}) {
  const [currentDate, setDate] = useState(new Date());

  useEffect(() => {
    setDate(date);
  }, [date]);

  return (
    <div>
      {noPartialitie === 1 ? null : <hr style={{
        borderColor:"var(--primaryColor)"
      }}/>}
      <p>
        <b>Parcialidad {noPartialitie}</b>
      </p>

      <div className={ui.gridItem}>
        <div>
          <p className="my-0">
            <b>Importe</b>
          </p>
          <p className="my-0">{mnCurrency(price)}</p>
        </div>

        <Percentage
          
          id={`partialitie-percentage-${noPartialitie}`}
          label="Porcentaje *"
          name={`partialitie-percentage-${noPartialitie}`}
          value={percentage}
          onValueChange={({ floatValue }) => {
            const infoPartialitie = {
              percentage: floatValue,
              priceToUpdate: price,
              elementIndex: noPartialitie - 1,
              date: new Date(currentDate),
            };

            onChange(infoPartialitie);
          }}
        />

        {noPartialitie > 1 ? (
          <div>
            <DatePartialitieV2
              initialDate={date}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(value) => {
                const infoPartialitie = {
                  percentage,
                  priceToUpdate: price,
                  elementIndex: noPartialitie - 1,
                  date: new Date(value),
                };
                setDate(new Date(value));
                onChange(infoPartialitie);
              }}
            />
          </div>
        ) : (
          <DatePartialitie
            maxDate={maxDate}
            firstPartiality={resetDate}
            beginDate={date}
            disableDatesRestriction={disableDatesRestriction}
          />
        )}
      </div>
    </div>
  );
}

Partialitie.propTypes = {};

export default Partialitie;
