import React, { useContext, useEffect, useState } from "react";
import DocumentContext from "../../ContextDocument";
import ui from "../styles.module.scss";
import DynamicContactSocialReason from "components/general/DynamicContactSocialReason";
import { GetMyActivCustomersContacts } from "helpers/Apis/Documents";

/**
 * @type {import("./types").ContactDocumentI[]}
 */
const EMPTY_LIST = [];

export default function ContactField() {
  const hook = useContext(DocumentContext);

  const [loadContact, setLoadContact] = useState(EMPTY_LIST);
  useEffect(() => {
    (async () => {
      const apiContacts = await GetMyActivCustomersContacts(hook.customerId);

      /**
       * @type {import("./types").ContactDocumentI[]}
       */
      const contactsFormatted = apiContacts.map((contact) => ({
        ...contact,
        label: contact.fullName,
        id: contact.contactID,
        anniversary: "",
        cell: "",
        email: "",
        isCollectionContact: null,
        isPaymentContact: null,
        lastName1: contact.lastName1,
        lastName2: contact.lastName2,
        middleName: contact.middleName,
        name: contact.firstName,
        phone: "",
        workTitle: "",
      }));

      setLoadContact(contactsFormatted);
    })();
  }, [hook.customerId]);

  if (hook.actionType === "Ver")
    return (
      <div className={ui.comboHeader}>
        <p>Contacto</p>
        <p>
          {loadContact.find((item) => item.id === hook.contact.id) ||
            typeof(hook.contact.id) ==="number" ? `${hook.contact.name} ${hook.contact.middleName} ${hook.contact.lastName1} ${hook.contact.lastName2}` : "Sin contacto"}
        </p>
      </div>
    );

  if (typeof hook.customer?.id !== "number" || isNaN(hook.customer?.id))
    return (
      <div className={ui.comboHeader}>
        <p>Contacto</p>
        <p className="text-danger">Selecciona una Razón social</p>
      </div>
    );

  if (hook.customer.id <= 0)
    return (
      <div className={ui.comboHeader}>
        <p>Contacto</p>
        <p className="text-danger">Selecciona una Razón social</p>
      </div>
    );

  return (
    <div className={ui.comboHeader}>
      <p>Contacto</p>
      <DynamicContactSocialReason
        contact={hook.contact.id}
        customer={hook.customer?.id}
        onChange={(value) => {
          hook.handleContactOnChange(value);
        }}
        options={loadContact}
      />
    </div>
  );
}
