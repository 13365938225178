import React from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";

/**
 * Render a pill information for the filter of the mobile view
 * @param {import('./types').PropsPillFilterMobile} props - Props
 * @returns {JSX.Element}
 */
export default function PillFilterMobile({ label, value }) {
  return (
    <div className={ui.pill}>
      <Label>{label}</Label>
      <p>{value}</p>
    </div>
  );
}
