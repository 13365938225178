import React from "react";
import style from "./styles.module.scss";
import ToggleComponent from "../inputs/Toggle";
import { Label } from "structure/Document";
import MoreInfo from "../MoreInfo";
import { createContext } from "react";
import use2fa from "./use2fa";
import ModalQr from "./ModalQr";
import ModalRecoveryCodes from "./ModalRecoveryCodes";
import ModalCancel from "./ModalCancel";
import Checkbox from "../inputs/Checkbox";
import ModalError from "./ModalError";

/**
 * @type {import("react").Context<import("./use2fa/types").ReturnUse2fa|undefined>}
 */
export const Context2fa = createContext(undefined);

export default function DobleFactorAuthentication() {
  const hook = use2fa();

  return (
    <Context2fa.Provider value={hook}>
      <div className={style.container}>
        <div className={style.infoLabel}>
          <Label>2FA</Label>
          <MoreInfo title="Más información 2FA">
            <p>Lista de proveedores para la doble autenticación</p>
            <ol className={style.warnings2fa}>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.microsoft.com/es-mx/security/mobile-authenticator-app#overview"
                >
                  Microsoft Authenticator
                </a>
              </li>
            </ol>
          </MoreInfo>
        </div>

        <label>
          <Checkbox checked={hook.isActive2fa} onChange={hook.handleOpen} className="ml-0"/>

          {hook.isActive2fa ? "Activado" : "Desactivado"}
        </label>
      </div>

      <ModalQr />
      <ModalRecoveryCodes />

      <ModalCancel />
      <ModalError/>
    </Context2fa.Provider>
  );
}
