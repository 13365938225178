import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from '../../../individual/inputs/Inputs';

function InformativePrice({
    label = 'Precio',
    id = 'informativePrice',
    name = 'informativePrice',
    css = '',
    forwardRef = {},
    text = 'No editable'
}) {
    return (
        <div>
            <InputText
                htmlFor = 'informativePrice'
                id = { id }
                name = { name }
                labelTxt = { label }
                placeholderTxt = { text }
                aditionalClasses = {`inputBlock ${css}`}
                forwardRef = { forwardRef }
                readonly = { true }
            />
        </div>
    )
}

InformativePrice.propTypes = {

}

export default InformativePrice

