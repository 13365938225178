import React from "react";
import { useContext } from "react";
import { ContextCreateEgress } from "..";
import { mnCurrency } from "helpers/money";
import { Label } from "structure/Document";

export default function AssociatedLabel() {
  const hook = useContext(ContextCreateEgress);

  return (
    <div>
      <Label className="justify-content-end mx-0">Asociado</Label>
      <p className="text-right">{mnCurrency(hook.amountAssociated)}</p>
    </div>
  );
}
