import ButtonV2 from "components/individual/ButtonsV2/Button";
import { AddCommentContainer } from "components/Sections/Documents/Comments/Containers";
import {
  CONTEXT_RATE_TICKET,
  useRateTicket,
} from "pages/Tickets/hooks/useRateTicket";
import "./styles.css";
import React from "react";
import Dialog from "structure/Dialog";
import { createContext } from "react";
import ButtonRate from "./ButtonRate";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";

export const ContextRateTicket = createContext(CONTEXT_RATE_TICKET);

/**
 * Rate a Ticket
 * @param {import("./types").RateTicketI} props
 * @returns {JSX.Element}
 */
export const RateTicket = ({
  idTicket,
  onRatedTicket = () => {},
  status = null,
  children = null,
  id = null,
  createdBy = null,
}) => {
  const hook = useRateTicket(idTicket, onRatedTicket);

  const { userInfo } = useContext(LoginContext);

  // Ticket haven't been finished
  if (status !== "finished") return <></>;

  // Just user who created the ticket can rate it
  if (userInfo[0].userID !== createdBy) return <></>;

  return (
    <ContextRateTicket.Provider value={hook}>
      {children === null ? (
        <ButtonV2 id={id} onClick={hook.openModal}>
          Calificar
        </ButtonV2>
      ) : (
        <div id={id} onClick={hook.openModal}>
          {children}
        </div>
      )}

      <Dialog
        title="Calificar"
        closeCallback={hook.closeModal}
        forwardRef={hook.dialog.refDialog}
        footer={<ButtonRate />}
      >
        {hook.isOpen && (
          <div>
            <div className="RatingContainer">
              <div className="stars">
                <input
                  type="radio"
                  onClick={() => hook.onRateChange(1)}
                  name="rating"
                />
                <input
                  type="radio"
                  onClick={() => hook.onRateChange(2)}
                  name="rating"
                />
                <input
                  type="radio"
                  onClick={() => hook.onRateChange(3)}
                  name="rating"
                />
                <input
                  type="radio"
                  onClick={() => hook.onRateChange(4)}
                  name="rating"
                />
                <input
                  type="radio"
                  onClick={() => hook.onRateChange(5)}
                  name="rating"
                />
                <i></i>
              </div>
            </div>

            <div className="CommentSection px-4">
              <AddCommentContainer className="divcomment">
                <div className="d-flex flex-column commentrating">
                  <label htmlFor="Comentario" className="txtlabelcomment">
                    Comentarios
                  </label>
                  <input
                    placeholder="Escribe aquí, ejemplo: No se arregló del todo, sigue fallando las CxC"
                    id="Comentario"
                    name="Comentario"
                    style={{
                      width: "100%",
                      fontSize: "0.85rem",
                    }}
                    onChange={(e) => hook.onCommentChange(e.target.value)}
                  />
                </div>
              </AddCommentContainer>
            </div>
          </div>
        )}
      </Dialog>
    </ContextRateTicket.Provider>
  );
};
