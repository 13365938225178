import styled from 'styled-components';

export const ContainerComments = styled.div`

    min-height: 50vh;
    max-height: 50vh;
    overflow: auto;
    margin:0 0 10px 0;

`;

export const LoadMoreComments = styled.div`

    display: flex;
    justify-content: center;

    button{
        border:2px solid #E5E5E5;
        background-color: #F9F9F9;
        padding:10px;
        border-radius:5px;
        transition: all ease-in-out 0.5s;

        &:hover{
            padding:15px;
            transition: all ease-in-out 0.5s;
        }
    }

`;

export const ContainerAddComment = styled.form`

    #spinnerAddMessage{
        display: block !important;
    }

    display: flex;
    justify-content: space-around;
    height: 50px;
    
    div:first-child{
        width: 85%;
        div{
            width: 100%;
        }
    }
    
    button{
        border-radius: 50%;
        width: 45px;
        height: 45px;
        border:2px solid #E5E5E5;
        background-color: #F9F9F9;

    }

    textarea{
        overflow-y: hidden;
        height: 50px;
        resize: none;
    }
    
`;