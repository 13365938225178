import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addProyect, getProyect, updateProyect } from "helpers/Apis/proyects";
import { Success } from "helpers/alerts";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

/**
 * @type {import("./types").StateFormProyect}
 */
const INITIAL_STATE = {
  isLoadingData: true,
  isUpdatingData: false,
  proyect: undefined,
};

/**
 *
 * @param {number} [id=null] Id of the position
 * @param {import("structure/FormPosition/useFormPosition/types").PositionForm[]} positions - Positions to add or edit into the proyect
 * @returns {import("./types").ReturnUseFormProyect}
 */
export default function useFormProyect(id = null, positions = []) {
  const [state, setState] = useState(INITIAL_STATE);
  const history = useHistory();

  const proyectSchema = yup.object().shape({
    idClient: yup
      .number()
      .required("Cliente obligatorio")
      .typeError("Cliente inválido")
      .min(1, "Selecciona una opción válida"),
    buyer: yup
      .string()
      .nullable(true)
      .default(null)
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .max(1000, "Maximo 1000 caracteres"),

    // .required("Obligatorio"),
    buyerEmail: yup
      .string()
      .email("Ingresa un correo válido")
      .nullable(true)
      .default(null)
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      // .required("Obligatorio")
      .typeError("Email inválido"),

    comments: yup
      .string()
      .required("Obligatorio")
      .typeError("La descripción del proyecto es obligatoria"),
    link: yup
      .string()
      .matches(/^https:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}\/?.*$/, {
        message: "El link debe ser una dirección segura (con https)",
        excludeEmptyString: true,
      })
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .nullable(true)
      .default(null),
    noRFQ: yup
      .string()
      .max(256, "Maximo 256 caracteres")
      .nullable(true)
      .default(null)
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .typeError("Ingresa un alfanumérico válido"),
    solped: yup
      .string()
      .nullable(true)
      .default(null)
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .max(1000, "Maximo 1000 caracteres"),
    // .required("Obligatorio"),
    title: yup
      .string()
      .max(256, "Maximo 256 caracteres")
      .required("Obligatorio"),
    buyerPhone: yup
      .string()
      .max(15, "Maximo 15 caracteres")
      .nullable()
      .default(null),
    user: yup
      .string()
      .max(256, "Maximo 256 caracteres")
      .nullable()
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .default(null),
    userEmail: yup
      .string()
      .email("Ingresa un correo válido")
      .nullable()
      .default(null)
      .transform((value,original)=>{
        if(value==='') return null;
        return value
      })
      .typeError("Email inválido"),    
    userPhone: yup
      .string()
      .max(15, "Maximo 15 caracteres")
      .nullable()
      .default(null),
  });

  /**
   * @type {import("react-hook-form").UseFormMethods<import("../../../../../types/proyects").DtoCreateProyect>}
   */
  const hook = useForm({
    resolver: yupResolver(proyectSchema),
    criteriaMode: "all",
    mode: "all",
    defaultValues:{
      idClient:null,
      buyer:null,
      buyerEmail:null,
      comments:null,
      link:null,
      noRFQ:null,
      solped:null,
      title:null,
      buyerPhone:null,
      user:null,
      userEmail:null,
      userPhone:null,
    },
    shouldFocusError: true,
  });

  useEffect(() => {
    (async function () {

      if (id <= 0 || typeof id !== "number") {
        setState((current) => ({
          ...current,
          isLoadingData: false,
        }));
        return;
      }

      const proyect = await getProyect(id);

      setState((current) => ({
        ...current,
        proyect,
      }));

      // In order to work this fields must be rendered on the UI

      setState((current) => ({
        ...current,
        isLoadingData: false,
      }));

      hook.setValue("buyer", proyect.buyer);
      hook.setValue("buyerEmail", proyect.buyerEmail);
      hook.setValue("buyerPhone", proyect.buyerPhone);
      hook.setValue("comments", proyect.comments);
      hook.setValue("idClient", proyect.idClient);
      hook.setValue("link", proyect.link);
      hook.setValue("noRFQ", proyect.noRFQ);
      hook.setValue("solped", proyect.solped);
      hook.setValue("title", proyect.title);
      hook.setValue("user", proyect.user);
      hook.setValue("userEmail", proyect.userEmail);
      hook.setValue("userPhone", proyect.userPhone);
    })();
  }, [state.isLoadingData]);

  /**
   * Update the information of a proyect and it's position
   * @param {import("../../../../../types/proyects").DtoUpdateProyect} data
   */
  const update = async (data) => {
    setState((current) => ({
      ...current,
      isUpdatingData: true,
    }));

    const wasUpdated = updateProyect({
      ...data,
      id,
      // position: { ...data.position, id: state.proyect.positions[0].id },
    });

    if (wasUpdated) {
      Success(() => {}, "Proyecto actualizado correctamente");
      history.push("/proyectos");
    }

    setState((current) => ({
      ...current,
      isUpdatingData: false,
    }));
  };

  /**
   * Create proyect with it's positions
   * @param {import("../../../../../types/proyects").DtoCreateProyect} data - Data
   */
  const handleSubmit = async (data) => {

    /**
     * @type {import("../../../../../types/proyects").DtoCreatePosition[]}
     */
    const dtoPositions = positions.map((item) => ({
      cost: item.cost,
      description: item.description,
      idUen: item.uen,
      ocCustomer: item.ocCustomer,
      pos: item.pos,
      sell: item.sell,
      ivaCostRate: item.iva,
      ivaSellRate: item.iva,
      id:item?.id||null,
      percentageOfCompletion: item.percentageOfCompletion,
      status: item.status,
      um: item.satUm.split("-")[0].trim(),
      umDescription: item.satUm.split("-")[1].trim(),
      satDescription: item.satCode.split("-")[1].trim(),
      satKey: item.satCode.split("-")[0].trim(),
    }));

    const dto = { ...data, positions: dtoPositions }

    if (id > 0 && typeof id === "number") {
      await update(dto);
      return;
    }

    if(positions.length<=0) {
      Swal.fire({
        title:'Completa el formulario',
        icon:'error',
        text:'Al menos agrega 1 posicón al proyecto'
      });
      return
    }    

    setState((current) => ({
      ...current,
      isUpdatingData: true,
    }));

    const wasAdded = await addProyect(dto);

    if (wasAdded) {
      hook.reset();
      Success(() => {}, "Proyecto agregado correctamente");
      history.push("/proyectos");
    }

    setState((current) => ({
      ...current,
      isUpdatingData: false,
    }));
  };

  /**
   *
   * @param {import("./types").FieldsProyect} field
   * @deprecated
   */
  function isEditableField(field) {
    return true;
    // // If the field is number and id of proyect is more or less than 0 means is creating a proyect, so is editable
    // if (id <= 0 && typeof id === "number") return true;

    // // return true;

    // const headerEdition =
    //   state.proyect.positions[0]?.ocCustomer === undefined &&
    //   isToAddPosition === false
    //     ? true
    //     : false;
    // const positionEdition =
    //   state.proyect.positions[0]?.ocCustomer === undefined ? true : false;

    // /**
    //  * @type {import("./types").IndexedFieldsProyect}
    //  */
    // const editions = {
    //   title: headerEdition,
    //   noRFQ: headerEdition,
    //   closeDate: headerEdition,
    //   buyer: !isToAddPosition ? headerEdition : false,
    //   buyerEmail: isToAddPosition ? false : true,
    //   buyerPhone: isToAddPosition ? false : true,
    //   user: isToAddPosition ? false : true,
    //   userEmail: isToAddPosition ? false : true,
    //   userPhone: isToAddPosition ? false : true,
    //   comments: isToAddPosition ? false : true,
    //   link: headerEdition,
    //   solped: headerEdition,
    //   "position.pos": isToAddPosition ? true : positionEdition,
    //   "position.subPos": isToAddPosition ? true : positionEdition,
    //   "position.material": isToAddPosition ? true : positionEdition,
    //   "position.quantity": isToAddPosition ? true : positionEdition,
    //   "position.umSat": isToAddPosition ? true : positionEdition,
    //   "position.percentageOfCompletion": true,
    //   "position.status": true,
    //   "position.laborCost": true,
    //   "position.laborSell": true,
    //   "position.ocCustomer": true,
    // };
    // return editions[field] || false;
  }

  /**
   * Check if redender the field or not
   * @param {import("./types").FieldsProyect} field - Field to evaluate
   * @param {'informative'|'input'} type - Type of field to be evaulated
   */
  function displayField(field, type) {
    const editable = isEditableField(field);

    if (type === "informative" && editable) {
      return "d-none";
    }

    if (type === "informative" && !editable) {
      return "";
    }

    if (type === "input" && editable) {
      return "";
    }

    return "d-none";
  }

  return {
    form: hook,
    ...state,
    handleSubmit,
    id,
    isEditableField,
    displayField,
  };
}
