import { Link } from "react-router-dom";
import styled from "styled-components";

export const Table = styled.div`
  display: grid;
  grid-template-columns: 10% 10% 5% 10% 10% 10% 15% 10% 10% 10%;
  grid-template-rows: 1fr;
  text-align: center;
  align-items: center;

  p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4),
  p:nth-child(5),
  p:nth-child(6),
  p:nth-child(7),
  p:nth-child(8),
  p:nth-child(9),
  p:nth-child(10) {
    font-weight: bold;
    border: 1px solid gray;
    border-style: none none solid none;
    heigth: 50px;
  }
`;

export const Invoice = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
