import { setBank } from "../forms";

export const TYPE_MOVEMENT = {
  cxp: 4,
};

export function configEditInputs(infoRow) {
  setBank(infoRow.bankID, infoRow.socialReason, "edit_mnBank");
  let currencyType = infoRow.currency === "MXN" ? 1 : 2;
  const bankID = infoRow.bankID;
  let cuentaType =
    infoRow.tipoCuenta === "Chequera"
      ? 1
      : infoRow.tipoCuenta === "Inversion"
      ? 2
      : 3;
  document.getElementById("edit_mnBank").value = infoRow.socialReason;
  document.getElementById("edit_clabe").value = infoRow.clabe;
  document.getElementById("edit_currency").value = currencyType;
  document.getElementById("edit_cuenta").value = infoRow.accountNumber;
  document.getElementById("edit_SAT").value = infoRow.SAT;
  document.getElementById("edit_txtInitialAmount").value = infoRow.saldoInicial;
  document.getElementById("edit_typeAccount").value = cuentaType;
  document.getElementById("edit_noNextIngreso").value = infoRow.ingreso;
  document.getElementById("edit_noNextEgreso").value = infoRow.egreso;
  document.getElementById("edit_fake").value = infoRow.bankAccountID;
  document.getElementById("edit_fakeBankID").value = bankID;
}

export function setInfoModal(infoRow) {
  document.getElementById("info_rzBanco").innerText = infoRow.socialReason;
  document.getElementById("info_Cuenta").innerText = infoRow.accountNumber;
  document.getElementById("info_Clabe").innerText = infoRow.clabe;
  document.getElementById("info_SAT").innerText = infoRow.SAT;
  document.getElementById("info_Moneda").innerText = infoRow.currency;
  document.getElementById("info_InitialAmount").innerText =
    infoRow.saldoInicial;
  document.getElementById("info_AccountType").innerText = infoRow.tipoCuenta;
  document.getElementById("info_NoNextIngreso").innerText = infoRow.ingreso;
  document.getElementById("info_NoNextEgreso").innerText = infoRow.egreso;
}

export const getCxcsToAdd = (cxcs) => cxcs.filter((cxc) => cxc.aplicacion > 0);
