import React from "react";

/**
 * Render a checkbox
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} props - Props checkbox input
 * @returns {JSX.Element}
 */
export default function Checkbox(props) {
  return <input type="checkbox" {...props} />;
}
