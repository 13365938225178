import useDialog from "customHooks/useDialog";
import React, { Fragment, useContext, useRef } from "react";
import Dialog from "structure/Dialog";
import { ContextModalExchangeDocuments } from "..";
import ui from "../styles.module.scss";

export default function Overview() {
  const dialog = useDialog();

  const key = useRef(`${window.crypto.randomUUID()}`);

  const hook = useContext(ContextModalExchangeDocuments);

  return (
    <Fragment>
      {hook.grid.length > 0 ? (
        <p className={ui.overviewHint} onClick={() => dialog.open()}>
          Ver resúmen
        </p>
      ) : (
        <p></p>
      )}

      <Dialog
        forwardRef={dialog.refDialog}
        closeCallback={() => dialog.close()}
        title="Resúmen cambio de documentos"
      >
        <div className={ui.containerOverview}>
          {hook.grid.map((item, i) => (
            <div className={ui.overviewItem} key={`${key.current}-${i}`}>
              <div className="d-flex align-items-center">
                <p className="mr-2">
                  {item.index + 1}
                  {")"}
                </p>

                <div>
                  <p>
                    <b>Cliente:</b> {item.customer.socialReason}
                  </p>
                  <p>
                    <b>Ejecutivo:</b> {hook.executive.name}
                  </p>
                </div>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>

              <div>
                <p>
                  <b>Cliente:</b> {item.customer.socialReason}
                </p>
                <p>
                  <b>Ejecutivo:</b> {item.executive.TEXT}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    </Fragment>
  );
}
