import ButtonV2 from "components/individual/ButtonsV2/Button";
import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Success } from "helpers/alerts";
import { updateContentToDo } from "helpers/Apis/todo";
import React, { useContext, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { CardsToDoContext } from "..";
import { CardContext } from "../Card";
import Icon from "components/individual/HeaderIcon";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";

/**
 * Preview on full screen the content of the note
 * @param {object} props - Props
 * @param {import('customHooks/useToDo/types').ToDosI} props.todo - Information of the todo
 * @param {JSX.Element} [props.children=<></>] - HTML to render
 * @param {import("react").MutableRefObject<HTMLElement>|undefined} [props.forwardRef=undefined] - HTML dom reference
 * @returns {JSX.Element} Preview the todo in a modal
 */
export default function Edit({
  todo,
  children = <></>,
  forwardRef = undefined,
}) {
  const [note, setNote] = useState(todo.todoNote);

  const dialog = useDialog();

  const { setIsUpdating, isUpdating, index } = useContext(CardContext);
  const { updateTaskContent } = useContext(CardsToDoContext);

  const triggerUpdateContentToDo = async () => {
    setIsUpdating(true);

    const wasUpdated = await updateContentToDo({
      ...todo,
      todoId: todo.id,
      todoNote: note,
    });

    if (wasUpdated) {
      updateTaskContent(index, note);
      Success(() => {}, "To Do actualizado");
      dialog.close();
    }

    setIsUpdating(false);
  };

  return (
    <div>
      <div ref={forwardRef} onClick={dialog.open}>
        {children}
      </div>
      <Dialog
        title={todo.title}
        closeCallback={dialog.close}
        forwardRef={dialog.refDialog}
        footer={
          <div className="d-flex justify-content-end align-items-center">
            {isUpdating ? (
              <Spinner hidden={false} text="Actualizando" />
            ) : (
              <ButtonV2 onClick={triggerUpdateContentToDo}>
                <span>Actualizar</span>
                <Icon icon="save" />
              </ButtonV2>
            )}
          </div>
        }
      >
        <ReactQuill
          theme="snow"
          style={{
            height: "60vh",
          }}
          value={note}
          onChange={setNote}
          preserveWhitespace
        />
      </Dialog>
    </div>
  );
}
