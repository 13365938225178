import Select from "components/individual/SelectV4";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextPareto } from "..";

export default function Base() {
  const hook = useContext(ContextPareto);

  return (
    <div>
      <Label>Base</Label>
      <Select
        onChange={(data) => hook.setBase(data)}
        labelToDisplay="label"
        defaultSelected={{
          value: hook.base.id,
          keyToCompare: "id",
        }}
        options={async () => [
          {
            label: "Margen",
            id: 2,
          },
          {
            label: "Facturación",
            id: 1,
          },
        ]}
      />
    </div>
  );
}
