import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "structure/Document";
import { ContextAttendInvoice } from "..";

registerLocale("es", es);

export default function LimitTime() {
  const { approved , updateLimitTime} = useContext(ContextAttendInvoice);
  
  return (
    <div className="d-flex flex-column">
      <Label>Fecha límite de timbrado</Label>
      <DatePicker
        selected={approved.limitTime}
        onChange={updateLimitTime}
        timeInputLabel="Hora:"
        // timeFormat="HH:mm"
        dateFormat="dd/MMM/yyyy h:mm aa"
        minDate={new Date()}
        locale="es"
        showTimeSelect
        placeholderText="Selecciona una fecha"
      />
    </div>
  );
}
