import { saveAs } from "file-saver";
import { getOldResidues } from "helpers/Apis/Banks/index";
import { GetTcp } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";
/**
 * @type {import("./types").StateUseOldResidues}
 */
const INITIAL_STATE = {
  tc: 0,
  currencyToUse: "MXN",
  currencyToReport: "Todos",
  showDetails: false,
  isLoading: true,
  invoices: [],
  refetch: false,
  isDownloadingXlsx: false,
};

/**
 * Hook to handle the report for the old residues
 * @param {import("./types").OldResidueType} [type="cxc"] - Type of report to fetch and get
 * @returns {import("./types").ReturnUseOldResidues}
 */
export default function useOldResidues(type = "cxc") {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const tcPraxia = await GetTcp();

      setState((current) => ({
        ...current,
        tc: tcPraxia.saiko,
        isLoading: false,
        refetch: !current.refetch,
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (state.tc <= 0) return;

      setState((current) => ({
        ...current,
        invoices: [],
        isLoading: true,
      }));

      const oldResidues = await getOldResidues(
        type,
        state.currencyToUse,
        state.tc,
        state.currencyToReport,
        false
      );

      setState((current) => ({
        ...current,
        invoices: oldResidues instanceof Blob ? [] : oldResidues,
        isLoading: false,
      }));
    })();
  }, [state.refetch]);

  /**
   * Set tc
   * @param {number} tc - TC
   * @returns {void}
   */
  const setTc = (tc) =>
    setState((current) => ({
      ...current,
      tc,
    }));

  /**
   * Toggle display the details for the report of old residues
   * @param {boolean} showDetails - Show details flag
   * @returns {void}
   */
  const setShowDetails = (showDetails) =>
    setState((current) => ({
      ...current,
      showDetails,
    }));

  const triggerSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const setCurrency = (currency) =>
    setState((current) => ({
      ...current,
      currencyToReport: currency,
      currencyToUse:currency === "Todos" ? current.currencyToUse : currency
    }));

  const setCurrencyToUse = (currencyToUse) =>
    setState((current) => ({
      ...current,
      currencyToUse,
    }));

  const downloadXlsx = async () => {
    setState((current) => ({
      ...current,
      isDownloadingXlsx: true,
    }));

    triggerSearch();

    const oldResidues = await getOldResidues(
      type,
      state.currencyToUse,
      state.tc,
      state.currencyToReport,
      true,
      state.showDetails
    );

      const title = type === 'cxc' ? 'Ventas' : 'Compras';

    if (oldResidues instanceof Blob) {
      saveAs(oldResidues, `${title} - Antiguedad de saldos.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingXlsx: false,
    }));
  };

  return {
    ...state,
    triggerSearch,
    setShowDetails,
    setCurrency,
    setTc,
    downloadXlsx,
    setCurrencyToUse,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: `/v2/bancos/${type}/antiguedad-saldos`,
        text: type === "cxc" ? "Ventas" : "Compras",
      },
      {
        route: `/v2/bancos/${type}/antiguedad-saldos`,
        text: "Antiguedad de saldos",
      },
    ],
  };
}
