import React, { useContext } from "react";
import { RfcContext } from "..";

/**
 * Render an input to put the rfc
 * @param {import("./types").PropsInputRfc} props - Props
 * @returns {JSX.Element}
 */
export default function InputRfc(props) {
  const hook = useContext(RfcContext);

  return (
    <input
      {...props}
      value={hook.rfc}
      type="text"
      maxLength={13}
      minLength={12}
      onChange={(e) => hook.updateRfc(e.target.value)}
    />
  );
}
