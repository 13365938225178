import React, { useContext } from "react";
import { ConcilationContext } from "../..";

const ALLOWED_STATUS = [3, 2];

/**
 * Render the checkbox item for the row
 * @param {import('../../../../../../../types/conciliations').Movimiento} movement - Movement information
 * @returns {JSX.Element}
 */
export default function ConciliateCheckbox(movement) {
  const hook = useContext(ConcilationContext);

  if (hook.canClickCheckbox(movement.status.id))
    return (
      <label>
        <input
          checked={hook.operationStatus[movement.id].conciliate === 3}
          onClick={e=>hook.toggleConcilationV2(movement.id)}
          // onClick={(e) =>
          //   hook.handleOnClickConcilation(movement, e.target.checked)
          // }
          type="checkbox"
          id={movement.noMovement}
        />
      </label>
    );
  return <span>-</span>;
}
