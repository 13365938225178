import axios from "axios";
import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { GetCustomer } from "./Directory";
import { GetDocument } from "./Documents";
import { isValidHttpResCode } from "./fetch";

/**
 * Send a email of related docs with attached files
 * @param {import("types/typedef/files").RelatedDocumentEmailI} emailInfo - Information of the mail to be sended
 * @returns {Promise<boolean>} True if the email was sended successfully
 */
export async function sendEmailWithFiles(
  emailInfo = {
    files: [],
    to: [],
    cc: [],
    subjet: null,
    message: null,
    idDocument: 0,
    sendedBy: "Praxia",
  }
) {
  const { files, to, cc, subjet, message, idDocument } = emailInfo;

  const { blobFiles, filesPc } = filterBlobAndPcFiles();

  const fileKeys = Object.keys(filesPc);

  let formData = new FormData();

  formData.append(
    "data",
    JSON.stringify({
      to,
      cc,
      subjet,
      message,
      idDocument,
    })
  );

  formData.append("blobFiles", JSON.stringify(blobFiles));

  // console.log(fileKeys, filesPc);

  fileKeys.forEach((key) => formData.append(`${key}`, filesPc[key]));

  try {
    await axios.post(`${URL_BASE}email/archivos-relacionados`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return true;
  } catch (e) {
    const { response } = e;

    Error(() => {}, response.data.message);
    return false;
  }

  /**
   *
   * @returns {{
   *  filesPc:File[],
   *  blobFiles:import("types/typedef/files").InformativeFileI[]
   * }}
   */
  function filterBlobAndPcFiles() {
    let blobFiles = [];
    let filesPc = [];

    files.forEach((file) => {
      const keys = Object.keys(file);

      if (keys.length <= 0) {
        filesPc.push(file);
        return;
      }

      blobFiles.push(file);
    });

    return {
      blobFiles,
      filesPc,
    };
  }
}

/**
 * Get the email of the customer that has any document on the system. Works for the modal that sends email documents
 * @param {number} idDocument - Id of the document
 * @returns {Promise<string[]>} An array with the emails of the customer
 */
export async function retrieveEmailCustomerByDocument(idDocument) {
  try {
    const document = await GetDocument(idDocument);
    const customer = await GetCustomer(document.customer.id);

    const emailForModal = [customer.contact.email];
    return emailForModal;
  } catch (error) {
    return [];
  }
}

/**
 * Get the email that must be attached
 * @param {import("../../../../server/controllers/typesControllerEmail").EmailAttachmentType} type - Type to get
 * @param {import("../../../../server/controllers/typesControllerEmail").EmailAttachmentModule} module - Module to get
 * @returns {Promise<string[]>}
 */
export async function retrieveAttachedAdresses(type, module) {

  if(module===null) return []

  try {
    const response = await fetch(
      `${URL_BASE}email/atachados?type=${type}&module=${module}`,
      {
        credentials: "include",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      /**
       * @type {import("../../../../server/types/email").EmailAdressAttached[]}
       */
      const data = await response.json();

      return data.map((record) => record.email);
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the template to send emails on the client-side
 * @param {import("../../../../server/controllers/typesControllerEmail").EmailAttachmentModule} type - Type of template
 * @param {number} [id=null] - Id of the record to fetch in order to generate the filled template
 * @returns {Promise<import("./typesEmail").EmailTemplateI>}
 */
export async function retrieveEmailTemplate(type,id=null){

  const DUMMY_DATA = {
    body:"",
    cc:[],
    subject:"",
    to:[]
  }

  if(type===null) return DUMMY_DATA
  
  try {

    const queryId = typeof(id) === "number" ? `&id=${id}` : ''

    const response = await fetch(
      `${URL_BASE}email/plantilla?module=${type}${queryId}`,
      {
        credentials: "include",
      }
    );

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      

      return data;
    }

    Error(()=>{},data.message)

    return DUMMY_DATA
  } catch (error) {
    Error(()=>{},error.message);
    return DUMMY_DATA
  }
}

/**
 * Get the template to send emails on the client-side
 * @param {import("../../../../server/controllers/typesControllerEmail").EmailAttachmentModule} module - Type of template
 * @param {string} body - Content for the email template
 * @param {string[]} cc - Emails that will be attached on the `cc` field
 * @returns {Promise<boolean>}
 */
export async function updateEmailTemplate(module,body,cc=[]){
  
  try {
    const response = await fetch(
      `${URL_BASE}email/plantilla`,
      {
        credentials: "include",
        method:"PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body:JSON.stringify({
          body,
          module,
          cc
        })
      }
    );
    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(()=>{},data.message);
      return true
    }
    Error(()=>{},data.message);

    return false
  } catch (error) {
    Error(()=>{},error.message);
    return false
  }
}


export async function retrieveComplementVariable(){

}