import React, { useContext } from "react";
import { FormContext } from "pages/Directory/Documents/Edit/Form/Form";
import { InputText, ErrorMessage } from "components/individual/inputs/Inputs";
import { NoContractArea } from "./Styles";

export default function EditContract({
  value,
  className = "",
  name = "contractCode",
}) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document, register, setContract, errors } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <NoContractArea>
          <InputText
            id="NoContractID"
            name={name}
            labelTxt="Contrato"
            forwardRef={register}
            onChange={setContract}
            placeholderTxt="Contrato"
            defaultValue={!value ? document.documents.contract.code : value}
            value={!value ? document.documents.contract.code : value}
          />
          <ErrorMessage message={errors[name]?.message} />
        </NoContractArea>
      ) : (
        <NoContractArea>
          <InputText
            id="NoContractID"
            name={name}
            onChange={setContract}
            labelTxt="Contrato"
            forwardRef={register}
            placeholderTxt="Contrato"
            defaultValue={!value ? document.documents.contract.code : value}
            value={!value ? document.documents.contract.code : value}
          />
          <ErrorMessage message={errors[name]?.message} />
        </NoContractArea>
      )}
    </>
  );
}
