import React from "react";
import { useContext } from "react";
import { ContextCxpReport } from "..";
import { RangeDate as Range, From, To } from "components/general/RangeDate";

export default function RangeDate() {
  const hook = useContext(ContextCxpReport);

  return (
    <Range>
      <From
        required={!hook.isValidFrom()}
        selected={hook.from}
        isClearable={true}
        onChange={(date) => hook.updateBeginDate(date)}
      />
      <To
        required={!hook.isValidTo()}
        selected={hook.to}
        isClearable={true}
        maxDate={new Date()}
        onChange={(date) => hook.updateEndDate(date)}
      />
    </Range>
  );
}
