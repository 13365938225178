import React from "react";
import { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";
import { parseDateToText, parseDateToTime } from "helpers/dates";
import { addDays } from "date-fns";
import GridPartialities from "pages/Directory/Documents/Invoice/GridPartialities/GridPartialities";
import { CfdiUse } from "components/general/Forms/Selects/CfdiUse";
import PayMethods from "components/general/Forms/Selects/PayMethodsV2";
// import PayForm from "components/general/Forms/Selects/PayForm";
import style from "../styles.module.scss";
import Footer from "../Footer";
import CommentsOrder from "../Comments";
import { mnCurrency } from "helpers/money";
import PayForm from "../PayForm";
import Receiver from "../Receiver";
import CustomCfdi from "../CustomCfdi";
import CustomPayMethod from "../CustomPayMethod";

export default function AuthorizationScreen() {
  const hook = useContext(ContextCreateInvoiceV2);

  return (
    <div>
      <p>
        ✅ Autorización aprobada, fecha límite para timbrar:{" "}
        <time dateTime={`${hook.limitCreationTime}`}>
          {parseDateToText(hook.limitCreationTime, "full")}{" "}
          {parseDateToTime(hook.limitCreationTime, "short")}
        </time>
        <br />
        <br />
        <p>
          <b>TC</b>: {mnCurrency(hook.tc)} | <b>Cambio de moneda: </b>{" "}
          {hook.currencyExchange ? "Si" : "No"}
          {hook.currencyExchange && hook.currency === "USD"
            ? " (Pesos a dolar)"
            : " (Dolar a pesos)"}
        </p>
      </p>

      <Receiver/>

      <CommentsOrder />

      <div className={style.comboOptions}>
        <div>
          <CustomCfdi/>
        </div>

        <PayForm />

        <CustomPayMethod/>
      </div>

      <div className={style.containerPartialities}>
        <GridPartialities
          partialities={hook.partialities}
          tc={hook.tc}
          beginDate={addDays(new Date(), hook.creditDays)}
          onChange={(cxcs) => hook.updatePartialitiesCxc(cxcs)}
          currency={hook.currency}
          totalAmountPreinvoice={hook.total}
        />
      </div>

      <Footer />
    </div>
  );
}
