import ModalV2 from "components/individual/Modals/ModalV2";
import React, { useContext } from "react";
import PersonalInformation from "./PersonalInformation";
import ProfilePicture from "./Picture";
import styles from "./styles.module.scss";
import UpdateButton from "./UpdateBtn";
import "./index.scss";
import useProfile from "customHooks/useProfile";
import LoginContext from "context/Login/LoginContext";

export default function Profile({ idOpenModal }) {
  const {
    state,
    closeModalId,
    updatePersonalInformation,
    setState,
    triggerUpdateProfile,
  } = useProfile();

  return (
    <div className={styles.containerModal}>
      <ModalV2
        idOpenModal={idOpenModal}
        showCancelButton={false}
        title="Perfil"
        idCloseIcon={closeModalId}
        footer={
          <UpdateButton
            cb={triggerUpdateProfile}
            isPerformingQuery={state.isPerformingQuery}
            isValid={state.personalInformation.isValid}
          />
        }
        width={75}
      >
        <div className="profile">
          <ProfilePicture
            onUpdate={(profilePicture) =>
              setState({
                ...state,
                profilePicture,
              })
            }
          />
          <PersonalInformation onChange={updatePersonalInformation} />
        </div>
      </ModalV2>
    </div>
  );
}
