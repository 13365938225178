import React from "react";
import { ModalDismissButton } from "../../../components/individual/buttons/Buttons";

export const ModalInfo = ({ idModal, titleModal }) => {
  const data = [
    {
      id: "info_rzBanco",
      text: "Razon social del Banco",
    },
    {
      id: "info_Cuenta",
      text: "Cuenta",
    },
    {
      id: "info_Clabe",
      text: "CLABE",
    },
    {
      id: "info_SAT",
      text: "SAT",
    },
    {
      id: "info_Moneda",
      text: "Moneda",
    },
    {
      id: "info_InitialAmount",
      text: "Saldo Inicial",
    },
    {
      id: "info_InitialAmount",
      text: "Saldo Inicial",
    },
    {
      id: "info_AccountType",
      text: "Tipo de Cuenta",
    },
    {
      id: "info_NoNextIngreso",
      text: "No. Siguiente de Ingreso",
    },
    {
      id: "info_NoNextEgreso",
      text: "No. Siguiente de Egreso",
    },
  ];
  return (
    <>
      <div className="modal fade" id={idModal} role="dialog">
        <div className="modal-dialog modaldialog-center modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center">{titleModal}</h4>
            </div>
            <div className="modal-body">
              <div className="container">
                {data.map((item) => {
                  return (
                    <p>
                      <b>{item.text}: </b>
                      <span id={item.id}></span>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <ModalDismissButton idBtn="closeView" text="Cerrar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
