import React, { useContext } from "react";
import Currency from "components/individual/inputs/Currency";
import { Label } from "structure/Document";
import { ContextOldResidues } from "..";

export default function CurrencyReport() {
  const hook = useContext(ContextOldResidues);

  return (
    <div>
      <Label>Moneda</Label>
      <Currency includeAll={true} selected={hook.currencyToReport} onChange={currency=>hook.setCurrency(currency)}/>
    </div>
  );
}
