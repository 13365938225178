import React, { useContext } from "react";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { ContextLogs } from "pages/Log/LogEmail";

export default function SearchBar() {
  const hook = useContext(ContextLogs);

  return (
    <div>
      <Label>Búsqueda</Label>
      <Search
        placeholder="Código de error ó inf. enviada/recibida"
        autoComplete="off"
        hideSearchIcon={true}
        onChange={(search) => hook.setSearch(search)}
        onDelete={() => hook.setSearch("")}
      />
    </div>
  );
}
