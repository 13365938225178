import React from "react";
import RedirectButton from "../Redirect";
import { ChangeMenuView } from "../ChangeMenuView";
import usePermissions from "customHooks/usePermissions";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../index.module.scss";

import MicrosoftCsp from "structure/MicrosoftCsp";
import Back from "../Back";

export const Purchase = () => {

  const { checkPermission } = usePermissions();

  return (
    <>
      <Back menu="general" text="Inicio > Compras" />

      <RedirectButton
        text={<TextIcon icon="document" text="Gestión OC" />}
        to={"/compras/gestion-oc"}
      />

      <RedirectButton
        permission="c082c7e9-441b-4368-9c56-3acb579a578f"
        text={<TextIcon icon="document" text="Facturas recibidas" />}
        to={"/administracion/recepcion-facturas"}
      />
      <RedirectButton
        permission="e567a726-63e8-4f77-bb2a-779bd073fd9f"
        text={<TextIcon icon="document" text="Notas de crédito recibidas" />}
        to={"/compras/notas-de-credito"}
      />

      <RedirectButton
        permission="f56602a3-be22-41c3-95a9-56bdcc4175bd"
        text={<TextIcon icon="document" text="Ordenes de compra" />}
        to={"/administracion/ordenes-compra"}
      />

      {checkPermission("c4071abb-00ad-44b5-a2e1-d79b0d78caa4") ? (
        <MicrosoftCsp type="recomendacionPagos">
          <p className={scss.item}>
            <TextIcon icon="administration" text="Programación de pagos" />
          </p>
        </MicrosoftCsp>
      ) : null}

      <ChangeMenuView
        permission="8d6a195f-4024-46c8-84fd-176c2946001b"
        icon="reports"
        text="Reportes"
        menu="purchaseReports"
      />
    </>
  );
};
