import React from "react";
import Select from "components/individual/SelectV4";

/**
 * Render a estatus combo
 * @param {import("./types").PropsSelectStatus} props - Props
 * @returns {JSX.Element}
 */
export default function SelectStatus({ onChange = () => {} , value = true }) {
  return (
    <Select
      labelToDisplay="label"
      onChange={(data)=>onChange(data.value)}
      defaultSelected={{
        keyToCompare:"value",
        value
      }}
      options={async () => [
        {
          label: "Activo",
          value: true,
        },
        {
          label: "Inactivo",
          value: false,
        },
      ]}
    />
  );
}
