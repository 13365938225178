import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import MovementReportV2 from "structure/MovementReportV2";
import BankAccountReportV2 from "structure/BankAccountReportV2";

export default function ScreenBankReports() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "reportBanks") return <></>;

  return (
    <>
      <NavigationItem icon="banks" label="Inicio > Bancos > Reportes" subMenu="banks" />

      <BankAccountReportV2 id={null}>
        <NavigationItem icon="banks" label="Estado de cuenta" />
      </BankAccountReportV2>

      <MovementReportV2>
        <NavigationItem icon="banks" label="Movimientos" />
      </MovementReportV2>
    </>
  );
}
