import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import DocumentsLog from "structure/DocumentsLog";

export default function AdmSystem() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "adminSistemas") return <></>;

  return (
    <>
      <NavigationItem icon="home" label="Inicio > Adm. del sistema" subMenu="general" />
      <NavigationItem icon="news" label="Avisos" link="/avisos" />
      <NavigationItem
        icon="gear"
        label="Parámetros"
        link="/procesos-especiales/parametros"
      />
      <NavigationItem icon="users" label="Roles" link="/roles" />
      <NavigationItem icon="users" label="Usuarios" link="/usuarios" />
      <DocumentsLog>
        <NavigationItem icon="chartBar" label="Log Sistema" />
      </DocumentsLog>
    </>
  );
}
