import styled from "styled-components";

export const FileUploadContainer = styled.div`
  border: 1px solid black;
  border-style: dotted;
  display: flex;
  gap:1rem 0;
  padding:0 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > *{
    width:100%;
  }
`;

export const AddFileButton = styled.label`
  display: flex;
  align-items: center;
  justify-content:center;
  svg {
    width: 3rem;
  }

  cursor: pointer;

  font-weight: normal;
  background-color: var(--primaryBackground);
  transition: all 0.25s ease-in-out;

  border: 0.5px solid var(--primaryBackground);

  &:hover {
    border-color:var(--primaryColor);
    background-color: transparent;
  }
  padding: 10px;
`;
