import React from "react";

import { RedirectButton } from "components/individual/buttons/Buttons";

/**
 * Render the buttton to check
 * @param {import('./types').PropsAssociatedFile} props - Props
 * @returns {JSX.Element}
 */
export default function AssociatedFiles({ from, idDocument , text = 'Asociar archivos' }) {
  const url = from === "pedidos" ? "pedidos" : "facturas-emitidas";

  const textToUse = from === 'contabilidad' ? 'Ver archivos asociados' : 'Asociar archivos'

  return (
    <RedirectButton
      id="viewInvoice"
      text={textToUse}
      link={`../administracion/archivos-asociados/${url}/${idDocument}`}
    />
  );
}
