import { Modal } from "components/general/Modal";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import InformativeTooltip from "components/individual/InfoTooltip";
import { InputText, SearchInput } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import { searchClaveProductoServicio } from "helpers/Apis/invoice";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { UenContext } from "..";
import scss from "../styles.module.scss";
import LoadingText from "components/individual/TextLoading";

const INITIAL_STATE = {
  isLoading: false,
  search: "",
  results: [],
  itemSelected: undefined,
  description: "",
};

export const ModalClaveProd = () => {
  const idModalOpen = useRef(window.crypto.randomUUID());

  /**
   * State of the modal in order to change the clave producto servicio
   * @type {[import("../types").StateModalClaveProductoServicio,()=>void]}
   */
  const [state, setState] = useState(INITIAL_STATE);

  const { selected, updateClaveProductoServicio } = useContext(UenContext);

  const openModal = () => document.getElementById(idModalOpen.current).click();

  /**
   * Update the search to use for the catalogue productos y servicios
   * @param {string} search - Search to use on the query for catalogue
   * @returns {void}
   */
  const updateSearchCriteria = (search) =>
    setState({
      ...state,
      search,
    });

  const resetState = () => setState(INITIAL_STATE);

  /**
   * Update the clave/producto for the item
   * @param {import("types/typedef/catalogue").UenI} itemSelected - Information of the UEN selected
   * @returns {void}
   */
  const setItemSelected = (itemSelected) =>
    setState({
      ...state,
      itemSelected,
      description: "",
    });

  const triggerSearch = async () => {
    setState({
      ...state,
      isLoading: true,
      results: [],
      itemSelected: undefined,
      description: "",
    });

    const optionsFounded = await searchClaveProductoServicio(state.search);

    setState({
      ...state,
      isLoading: false,
      results: optionsFounded,
    });
  };

  /**
   * Update the description of the item in order to add a custom
   * @param {string} description - Description of the item to add
   * @returns {void}
   */
  const updateDescriptionItem = (description) =>
    setState({
      ...state,
      description,
      selected: undefined,
    });

  // if (selected === undefined || selected === null)
  //   return <p>Selecciona una UEN</p>;

  return (
    <>
      <div className="d-flex">
        <p className={scss.containerClaveProd}>
          {selected?.SATcode} - {selected?.satCodeDescription || selected?.description }
        </p>
        <button type={"button"} onClick={openModal} className={scss.editClave}>
          ✏
        </button>
      </div>

      <Modal
        title="Seleccionar clave de producto o servicio"
        
        onCloseModal={resetState}
        propsOpenModal={{
          id: idModalOpen.current,
          className: "d-none",
          type:"button"
        }}
      >
        {({ closeModal }) => (
          <>
            <div className={scss.searchClave}>
              <div className={scss.searchBar}>
                <Label>
                  Buscar
                  <InformativeTooltip>
                    Tambien puedes
                    <a
                      href="http://pys.sat.gob.mx/PyS/catPyS.aspx"
                      target={"_blank"}
                      className="mx-1"
                    >
                      ir al catalogo del SAT
                    </a>
                    para encontrar esta informacion
                  </InformativeTooltip>
                </Label>
                <SearchInput
                  labelText=""
                  placeholderTxt="Escribe la clave o descripción, por ejemplo: 42203605, Software de adquisiciones "
                  showLabel
                  onChange={(e) => updateSearchCriteria(e.target.value)}
                />
              </div>
              <ButtonV2
                 type="button"
                onClick={triggerSearch}
                disabled={state.search === "" ? true : false}
              >
                Buscar
              </ButtonV2>
            </div>

            {state.isLoading ? (
              <Spinner hidden={false} text={<LoadingText>Buscando</LoadingText>} />
            ) : state.results.length === 0 ? (
              <>
                {/* <h3 className="text-center my-5">
                  No hay resultados con la busqueda solicitada
                </h3>
                <h5 className="text-center">
                  Si no existe la clave, puedes describir el producto para
                  agregarlo a tu partida
                </h5>

                <div className={scss.newClaveProductoServicio}>
                  <div>
                    <Label>
                      Clave
                      <InformativeTooltip>
                        Esta clave no puede ser cambiada.{" "}
                        <a
                          href="https://quickbooks.intuit.com/mx/recursos/controla-tu-negocio/que-es-el-catalogo-de-claves-del-sat-y-como-se-usa/"
                          target={"_blank"}
                          className="mx-1"
                        >
                          Para más información visita esta liga
                        </a>
                      </InformativeTooltip>
                    </Label>
                    <p>01010101</p>
                  </div>
                  <InputText
                    aditionalClasses="w-100"
                    labelTxt="Descripcion"
                    onChange={updateDescriptionItem}
                    placeholderTxt="Automóviles eléctricos con IA"
                  />
                </div> */}
              </>
            ) : (
              <div className={scss.containerSearchFounded}>
                {state.results.map((result) => (
                  <label
                    className={scss.rowSearchFounded}
                    htmlFor={`${result.Value}`}
                  >
                    <input
                      type="radio"
                      name="claveSatList"
                      id={`${result.Value}`}
                      onChange={() => setItemSelected(result)}
                    />
                    <p>
                      {result.Value} - {result.Name}
                    </p>
                  </label>
                ))}
              </div>
            )}

            {state.itemSelected !== undefined || state.description !== "" ? (
              <>
                <hr />
                <div className="d-flex justify-content-end">
                  <ButtonV2
                    type="button"
                    onClick={async () => {
                      await updateClaveProductoServicio(
                        state.itemSelected === undefined
                          ? state.description
                          : state.itemSelected.Name,
                        state.itemSelected === undefined
                          ? "01010101"
                          : state.itemSelected.Value
                      );
                      closeModal();
                    }}
                  >
                    Actualizar
                  </ButtonV2>
                </div>
              </>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
};
