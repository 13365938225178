import React, { useContext } from "react";
import DocumentContext from "../../ContextDocument";
import ui from "../styles.module.scss";
import { Label } from "structure/Document";
import SocialReason from "components/general/Forms/Selects/SocialReasonInvoice";

export default function SocialReasonField() {
  /**
   * @type{import('../../../../../../../types/documentActions').UseDocumentV2I}
   */
  const {
    customer,
    actionType,
    customerId,
    documentType,
    handleOnChangeCustomer,
  } = useContext(DocumentContext);

  if(actionType ==='Ver')<div className={ui.comboHeader}>
  <Label>Razón social</Label>
  <p>{customer?.socialReason}</p>
</div>

  if (documentType === 1)
    return (
      <div className={ui.comboHeader}>
        <Label>Razón social</Label>
        <p>{customer?.socialReason}</p>
      </div>
    );

  if (documentType === 3)
    return (
      <div className={ui.comboHeader}>
        <Label>Razón social</Label>
        <SocialReason
          type={"proveedor"}
          creatable={false}
          includeNoActives={false}
          value={+customerId || customer.id}
          onChange={({ id, socialReason, rfc }) => {
            handleOnChangeCustomer({ id, socialReason, rfc });
          }}
        />
      </div>
    );

  if (actionType === "Agregar")
    return (
      <div className={ui.comboHeader}>
        <Label>Razón social</Label>
        <SocialReason
        creatable={false}
          type={"cliente"}
          includeNoActives={false}
          value={customer?.id||null}
          onChange={({ id, socialReason, rfc }) => {
            handleOnChangeCustomer({ id, socialReason, rfc });
          }}
        />
      </div>
    );

  return (
    <div className={ui.comboHeader}>
      <Label>Razón social</Label>
      <p>{customer?.socialReason}</p>
    </div>
  );
}
