import React, { useEffect, useState } from "react";
import { GetFileComments } from "../../../../helpers/Apis/associateFiles";

import { format } from "date-fns";

import {
  ContainerComments,
  ContainerAddComment
} from "./StylesComments";
import { InputTextArea } from "../../../../components/individual/inputs/Inputs";

import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useAssociatedFile from "../../../../customHooks/useAssociatedFiles";
import { Spinner } from "../../../../components/individual/Spinner/Spinner";

/**
 * Comments made to a file
 *
 * @param {object} props - Props component
 * @param {number} props.idFile - Id of the file
 * @returns {JSX.Element} Comments
 */
function Comments({ idFile = null }) {
  const { addNewComment , blurNewComments } = useAssociatedFile();

  const sendIcon = <FontAwesomeIcon icon={faPaperPlane} />;

  /**
   * Comments of the file
   * @type {[CommentFile[],Function]}
   */
  const [comments, setComments] = useState([]);

  const [isLoading, setIsLoading] = useState({
    comments: true,
    newComment: false,
  });

  useEffect(() => {
    (async function () {
      const apiComments = await GetFileComments(idFile, 0);
      setComments(apiComments);

      setIsLoading({
        ...isLoading,
        comments: false,
      });

    })();
  }, []);

  const schema = yup.object().shape({
    comment: yup
      .string()
      .min(1, "Escribe un comentario")
      .max(300, "Maximo 300 letras")
      .required("Escribe un comentario")
      .typeError("Escribe un comentario valido"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const addComment = async (data) => {
    setIsLoading({
      ...isLoading,
      newComment: true,
    });



    const comment = await addNewComment(data, idFile);

    // Update the comments list
    const updatedComments = [...comments];
    updatedComments.unshift(comment);
    setComments(updatedComments);

    //
    setIsLoading({
      ...isLoading,
      newComment: false,
    });

    document.getElementById("formAddComment").reset();
    // blurNewComments(idFile);

  };

  return (
    <>
      <ContainerComments>
        {isLoading.comments === true ? (
          <h3>Cargando comentarios...</h3>
        ) : (
          <>
            {comments.length > 0 ? (
              <>
                {comments.map((comment, i) => (
                  <>
                    <span>
                      {comment.commentedBy} -{" "}
                      <b>{format(new Date(comment.commentedDate), "PPpp")}</b>
                    </span>
                    <p>{comment.comment}</p>

                    {i === comments.length - 1 ? null : <hr></hr>}
                  </>
                ))}
              </>
            ) : (
              <h3>No hay comentarios</h3>
            )}
          </>
        )}
      </ContainerComments>

      {isLoading.newComment ? (
        <Spinner
          hidden={false}
          idSpinner="loadNewComment"
          text="Agregando comentario..."
        />
      ) : (
        <ContainerAddComment
          id="formAddComment"
          onSubmit={handleSubmit(addComment)}
        >
          <InputTextArea
            name="comment"
            id="comment"
            maxLength={300}
            forwardRef={register}
            errors={errors}
          />
          <button type="submit" formTarget="formAddComment">
            {sendIcon}
          </button>
        </ContainerAddComment>
      )}
    </>
  );
}

export default Comments;
