import React, { useContext } from "react";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { RfcContext } from "..";

export default function CountryRfc() {
  const hook = useContext(RfcContext);

  if (hook.rfc === "") return <p>Escribe un RFC para escoger un país</p>;

  if (!hook.isValid) return <p>Escribe un RFC válido para escoger un país</p>;

  if (!hook.isRfcForForeigners) return <p>{hook.country}</p>;

  return (
    <CountryDropdown
      blacklist={["MX"]}
      classes="select-css px-2"
      defaultOptionLabel="Selecciona"
      value={hook.country}
      onChange={(value) => hook.updateCountry(value)}
    />
  );
}
