import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextEditRol } from "..";

export default function ContinueButton() {
  const { isValidPermissions, navigateToStep } = useContext(ContextEditRol);

  return isValidPermissions ? (
    <ButtonV2 onClick={() => navigateToStep(2)}>Continuar</ButtonV2>
  ) : (
    <p className="text-danger">Selecciona al menos un permiso</p>
  );
}
