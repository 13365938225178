import { InputCurrency } from "components/individual/inputs/Inputs";
import React from "react";
import { useContext } from "react";
import { ContextGridCxC } from "..";
import Money from "components/general/Forms/Money";

export const InputBalance = () => {
  const { element, updateAppliedBalance } = useContext(ContextGridCxC);

  const showError = element.import.number > element.residue.number;

  return (
    <>
      <Money
        onChange={(val) => updateAppliedBalance(val, element.uuidFrontend)}
        placeholder="Escribe aqui"
        value={element.import.number}
        maxDecimals={2}
      />

      {/* <InputCurrency
        showError={showError}
        onChange={(val) => updateAppliedBalance(val, element.uuidFrontend)}
        placeholder="Escribe aqui"
        defaultValue={element.import.number}
      /> */}

      {showError ? (
        <p className="text-center text-danger">Máximo {element.residue.text}</p>
      ) : null}
    </>
  );
};
