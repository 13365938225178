import React, { useContext } from "react";
import { ContextSatCode } from "..";
import Dialog from "structure/Dialog";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import Results from "../Results";
import Footer from "../Footer";

export default function DialogSatCode() {
  const hook = useContext(ContextSatCode);

  if (hook.isOpenDialog === false) return <></>;

  return (
    <Dialog
      width={75}
      title="Unidades de medida SAT"
      forwardRef={hook.dialog.refDialog}
      closeCallback={() => hook.handleOpenModal(false)}
      footer={<Footer />}
    >
      <div className="mb-3">
        <Label>Búsqueda</Label>
        <Search
          placeholder="Escribe aquí"
          value={hook.search}
          onChange={hook.setSearch}
          onDelete={hook.setSearch}
          onSearch={hook.attemptSearch}
        />
      </div>

      <Results />
    </Dialog>
  );
}
