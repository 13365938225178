import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import { EditUser } from "helpers/apis";
import { FormContact, FormUser } from "pages/Directory/Contactos/FormContact";
import { ContainerFormUser } from "pages/Directory/Contactos/FormContactStyles";
import React, { useState } from "react";
import { userValidationSchema } from "./Schema";
import { useContext } from "react";
import { UsersContext } from "./UsuariosV2";
import usePermissions from "customHooks/usePermissions";
import Icon from "components/individual/HeaderIcon";
import ButtonV2 from "components/individual/ButtonsV2/Button";

/**
 *
 * @param {object} props - Props
 * @param {import("./types").InfoRow} props.infoRow - Information of the row selected on the table
 * @returns {JSX.Element}
 */
export const EditUsers = ({ infoRow }) => {

  const initialState = {
    isValidForm: true,
    phone: {
      lada: infoRow.ladaMovil || "52",
      number: infoRow.movil,
    },
    chief: infoRow.chiefId,
    isActive:infoRow.status==='Activo'
  }

  const [loading, setLoading] = useState(false);

  const hook = useContext(UsersContext);

  const [state, setState] = useState(initialState);

  const updateChief = (chief) =>
    setState((current) => ({
      ...current,
      chief,
    }));

  /**
   * Update the phone number
   * @param {import("components/individual/inputs/typesPhone").onChangePhoneI} number
   * @returns {void}
   */
  const updatePhoneNumber = (number) => {
    setState({
      ...state,
      isValidForm: number.isValidNumber,
      phone: {
        lada: number.lada,
        number: number.number,
      },
    });
  };

  const { birthDay, birthMonth, birthYear } = infoRow;

  /**
   * Update the user
   * @param {import("./types").ReactHookFormUpdateUser} data
   */
  const handleSubmit = async (data) => {
    setLoading(true);

    /**
     * DTO to update the user
     * @type {import("./types").DtoUpdateUser}1
     */
    const DTO = {
      birthday: data.dateMothYear,
      chief: data.jefe,
      emailToUpdate: data.email,
      lada: state.phone.lada,
      lastname: data.apellidoP,
      lastname2: data.apellidoM,
      middlename: data.middleName,
      movil: state.phone.number,
      name: data.nombre,
      rol: data.roles,
      rolTicket: data.ticketRol,
      username: data.userName,
      chief: state.chief,
      userId: infoRow.userID,
      isActive:data.isActive ? 1 : 0
    };

    const wasUpdated = await EditUser(DTO);
    if (wasUpdated) {
      hook.refetchTable();

      const close = document.getElementById("closeAddContact");

      if (close) close.click();
    }

    setLoading(false);
  };

  const dayofBirth = () => {
    const dateOfBirth = new Date(+birthYear, birthMonth - 1, birthDay);
    return dateOfBirth;
  };

  const { checkPermission } = usePermissions();

  if (!checkPermission("bb8b4c5a-ed0d-476c-9e36-b1b66b326105")) return <></>;

  return (
    <div>
      <ModalV2
        footer={
          <div className="form-group">
            <ButtonV2 disabled={!state.isValidForm} form="editUsuarioForm">
              <span>Actualizar</span>
              <Icon icon="save" />
            </ButtonV2>
          </div>
        }
        buttonText="Editar"
        close="Cancelar"
        aditionalClasses=""
        idCloseModal="closeAddContact"
        title="Actualizar usuario"
        idOpenModal={hook.idEditBtn}
        showCancelButton={false}
      >
        <ContainerFormUser>
          {loading ? (
            <Spinner
              text="Actualizando usuario"
              idSpinner="userLoading"
              hidden={!loading}
            />
          ) : (
            <FormUser
              idForm="editUsuarioForm"
              validation={userValidationSchema}
              className="mb-5"
              onSubmit={async (data) => {
                handleSubmit(data);
              }}
            >
              <FormContact.Name defValue={infoRow.firstName} />
              <FormContact.MiddleName defValue={infoRow.middleName} />
              <FormContact.Father defValue={infoRow.lastName1} />
              <FormContact.Mother defValue={infoRow.lastName2} />
              <FormContact.Email defValue={infoRow.email} />
              <FormUser.UserName defValue={infoRow.userName} />
              <FormUser.DateMonthYear defValue={dayofBirth()} />
              <FormUser.Executive
                onChange={(id) => updateChief(id)}
                name={"jefe"}
                defValue={infoRow.chiefId}
              />
              <FormUser.RolesSelect name={"roles"} defValue={infoRow.rolID} />
              <FormUser.UserNumber
                onChange={updatePhoneNumber}
                value={`${state.phone.lada}${state.phone.number}`}
              />
              <FormUser.TicketRol value={infoRow.ticketRol} />
              
              <FormContact.Status isChecked={state.isActive} />
            </FormUser>
          )}
        </ContainerFormUser>
      </ModalV2>
    </div>
  );
};
