import { Spinner } from "components/individual/Spinner/Spinner";
import { GetDocument } from "helpers/Apis/Documents";
import React, { useEffect, useState } from "react";
import ToDo from "structure/ToDo";

/**
 * Render the ToDo
 * @param {import("./types").PropsToDo} props - Props
 * @returns {JSX.Element}
 */
export const ToDoDocuments = ({ documento, cliente }) => {
  /**
   * Information of the document selected for the todo
   * @type {[import("types/typedef/documents").DocumentI,()=>void]}
   */
  const [document, setDocument] = useState(undefined);

  useEffect(() => {
    (async function () {
      const apiDocument = await GetDocument(documento);
      setDocument(apiDocument);
    })();
  }, []);

  return (
    <>
      {document === undefined ? (
        <Spinner hidden={false} text="Cargando información..." />
      ) : (
        <div className="container">
          <p>
            <b>Razón social:</b>
            <span>{document.customer.socialReason}</span> |<b>Tipo: </b>
            <span>{document.type.description}</span>
          </p>
        </div>
      )}

      <ToDo id={+documento} typeToDo="documentos" idEntity={+cliente} />
    </>
  );
};
