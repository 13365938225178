import React, { useContext } from "react";
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { CompleateOptions } from "../../../components/general/SearchOptions/CompleateOptions";
import { ExportExcelButton } from "../../../components/individual/buttons/Buttons";
import PermissionsContext from "../../../context/Login/LoginContext";
import {
  evaluatePermission,
  textPermission,
} from "../../../helpers/permissions";
import { OportunidadesNavigation } from "../../../static/StaticBreadCrums";

export const Oportunidades = () => {
  const { rolPermitions } = useContext(PermissionsContext);
  function search(e) {
    e.preventDefault();
    console.log("Funcion para hacer el filtro");
  }
  return (
    <>
      <HomeHeader mostrar={false} bredcrum={OportunidadesNavigation} />
      <div className="container">
        <div className="row d-flex">
          <CompleateOptions
            permitionID={85}
            search={search}
            inputEjecutivoID="oportunidadesEjecutivo"
            inputCurrencyID="oportunidadesCurrency"
            inputRangeID="oportunidadesRange"
            buttonSearchID="oportunidadesBtnSearch"
          />
          {/* Aqui debe de ir la tabla */}

          {evaluatePermission([86], rolPermitions) ? (
            <ExportExcelButton text={textPermission(86, rolPermitions)} />
          ) : null}
        </div>
      </div>
    </>
  );
};
