import React, { useRef } from "react";
import style from "./styles.module.scss";
import {
  ContextMenu,
  ContextMenuTrigger,
  showMenu,
  MenuItem,
} from "react-contextmenu";
import './styles.scss';

/**
 * Render the elipsis option menu
 * @param {import("./types").PropsElipsisMobile} props - Props
 * @returns {JSX.Element}
 */
export default function ElipsisMobile({ children = <></> , onShow = () => {} }) {
  const idMenu = useRef(`${window.crypto.randomUUID()}`);

  /**
   *
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
   */
  const handleInsideClick = (e) => {
    showMenu({
      position: { x: e.clientX, y: e.clientY },
      id: idMenu.current,
    });
  };

  return (
    <>
      <ContextMenu hideOnLeave={true} onShow={onShow} animation="fade" id={idMenu.current} className={style.ok}>
        {children}
      </ContextMenu>

      <ContextMenuTrigger  id={idMenu.current}>
        <button type="button" onClick={handleInsideClick} className={style.elipsisMobile}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
        </button>
      </ContextMenuTrigger>
    </>
  );
}

/**
 * Menu item for elipsis menu
 * @param {import("./types").PropsMenuItem} props - Props
 * @returns {JSX.Element}
 */
export function CustomMenuItem(props) {
  return <MenuItem {...props}>{props.children}</MenuItem>;
}
