import { Pagination } from "components/individual/Pagination";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useRef, useContext } from "react";
import { ConcilationContext } from "..";
import scss from "./styles.module.scss";
import LoadingText from "components/individual/TextLoading";
import MoreInfoMovement from "structure/MoreInfoMovement";
import Concept from "./Concept";
import ConciliateCheckbox from "./ConciliateCheckbox";
import AccountingCheckbox from "./AccountingCheckbox";

const STATUS_CONCILATION_LABEL = {
  2:"Activo",
  3:"Conciliados"
}


export const TabulatedMovements = () => {
  const { isLoading, movements, pagination, fetchPage , operationStatus } =
    useContext(ConcilationContext);

  const key = useRef(window.crypto.randomUUID());

  if (isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando movimientos</LoadingText>}
      />
    );

  if (movements.length <= 0 && pagination.currentPage === 1)
    return (
      <h1 className="text-center">
        No hay movimientos con los filtros solicitados
      </h1>
    );

  return (
    <div className={scss.container}>
      <div className={scss.header}>
        <div>No. mov.</div>
        <div>Fecha</div>
        <div>Beneficiario / Concepto</div>
        <div>Tipo</div>
        <div>Abono</div>
        <div>Cargo</div>
        <div>Estatus</div>
        <div>Conciliar</div>
        {/* <div>Contabilizar</div> */}
      </div>
      <div className={scss.containerRows}>
        {movements.map((movement, i) => (
          <div className={scss.row} key={`${key.current}-${i}`}>
            <div className={scss.anchor}>
              <MoreInfoMovement id={movement.id}>
                {movement.noMovement}
              </MoreInfoMovement>
            </div>
            <div className="text-center">{movement.movementDate}</div>
            <div className="text-center">
              <Concept
                type={movement.egress.number === null ? "ingreso" : "egreso"}
                beneficiary={movement.beneficiary}
                concepts={movement.concepts.map(
                  (concept) => concept.description
                )}
                emited={movement.invoiceEmited.map((invoice) => invoice.folio)}
                received={movement.invoiceRecived.map(
                  (invoice) => invoice.folio
                )}
              />
            </div>
            <div className="text-center">{movement.concept}</div>
            <div className="text-right">{movement.ingress.text}</div>
            <div className="text-right">{movement.egress.text}</div>
            <div className="text-center">
              <span>{STATUS_CONCILATION_LABEL[operationStatus[movement.id].conciliate]||"??"}</span> <span className="mx-1">-</span>
              <span>{movement.accounted ? 'Contabilizado' : 'No contabilizado'}</span>
            </div>

            <ConciliateCheckbox
              key={`${key.current}-concilation-${i}`}
              {...movement}
            />
          </div>
        ))}
      </div>
      <Pagination
        actualPage={pagination.currentPage}
        pages={pagination.pages}
        onChange={fetchPage}
        displayRowsPerPage={true}
        defaultRowsPerPage={{
          keyToCompare: "value",
          value: 100,
        }}
      />
    </div>
  );
};