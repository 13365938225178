import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Select } from '../../../individual/inputs/Inputs';

// APIS
import { getCurrency } from '../../../../helpers/Apis/Banks';

function Currency({
    id = 'currency',
    name = 'currency',
    label = 'Moneda',
    forwardRef = {},
    css = '-',
    onChange = () => {},
    addTodos = false,
    initialOption = 'Seleccione'

}) {

    const [currencys,setCurrencys] = useState([]);

    useEffect(()=>{
        async function initialLoad(){
            const apiCurrency = await getCurrency();
            setCurrencys(apiCurrency)
        }

        initialLoad();
    },[]);

    return (
        <div className = {`${css} itemDocument`}>
            <Select
                addTodos = { addTodos }
                initialOption = {initialOption}
                idSelect = {id}
                nameSelect = {name}
                label = {label}
                textJSON = 'code'
                options = {currencys}
                valueJSON = 'currencyID'
                forwardRef = {forwardRef}
                onChange = {value => onChange(value)}
            />
        </div>
    )
}

Currency.propTypes = {

}

export default Currency

