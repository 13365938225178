import {
  DescriptionText,
  DescriptionTextBold,
  FooterContainer,
  SubDescription,
  SubDescriptionButtons,
} from "components/Sections/Documents/Items/Containers";
import { PartidasComponet } from "components/Sections/Partidas/PartidasComponet";
import { Trash } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { MoreInfoSection } from "./MoreInfoSection";

export const ItemsFooterSection = ({
  unitImport,
  discount,
  totalImport,
  ivaPercentage,
  ivaNumber,
  tc,
  index,
  action = "Editar",
  apperEyeButton = true,
  apperTrashButton = true,
  apperEditButton = true,
}) => {
  const actionType = "Ganar";
  const idItem = "sjshskj";
  const { handleDeleteOdcItem, documents, interfaceControl,customer } =
    useContext(WinQuoteContext);
  const menuIndex = interfaceControl.indexActiveMenu;
  const idActiveMenu = interfaceControl.menu[menuIndex].idType;
  const isForeignSupplier= customer?.provider?.rfc==='XEXX010101000';
  return (
    <FooterContainer>
      <SubDescription>
        <DescriptionTextBold>
          {/* {documentType === 3 ? "C.U" : "P.U"}
           */}
          Unitario
        </DescriptionTextBold>
        <DescriptionText>{unitImport}</DescriptionText>
        <DescriptionTextBold>Descuento</DescriptionTextBold>
        <DescriptionText>{discount}</DescriptionText>
        <DescriptionTextBold>
          {/* {documentType === 3 ? "C.U.Compra" : "P.U.Venta"}{" "}
           */}
          Importe
        </DescriptionTextBold>
        <DescriptionText>{totalImport}</DescriptionText>
        <DescriptionTextBold>IVA </DescriptionTextBold>
        <DescriptionText>{ivaPercentage}</DescriptionText>
        <DescriptionText>{ivaNumber}</DescriptionText>
      </SubDescription>
      <SubDescriptionButtons>
        {apperEyeButton ? (
          idActiveMenu === 3 ? (
            <MoreInfoSection item={documents.odc.documentItems[index]} isForeignSupplier={isForeignSupplier} />
          ) : (
            <MoreInfoSection item={documents.quote.documentItems[index]} />
          )
        ) : null}
        {apperTrashButton ? (
          <Icon
            id={idItem}
            onClick={() => {
              handleDeleteOdcItem(index);
            }}
          >
            {Trash}
          </Icon>
        ) : null}
        {apperEditButton ? (
          <PartidasComponet action={action} tc={tc} index={index} />
        ) : null}
      </SubDescriptionButtons>
    </FooterContainer>
  );
};
