import { addDays } from "date-fns";
import { dateWithCeroHours } from "helpers/dates";
import { Actions } from "./actions";

/**
 * Initial state of the component to handle the custom hook to create/edit a contract
 * @type {import("./types").InitialStateI}
 */
export const initialState = {
  document: {
    startDateContract: null,
    docNumber: null,
    generateCxp: true,
    code: null,
    currency: null,
    contact: {
      id: null,
      data: null,
    },
    expiration: {
      db: null,
      js: null,
    },
    import: undefined,
    iva: undefined,
    periocity: {
      begin: null,
      end: null,
      id: "-1",
      message: null,
      value: null,
    },
    reminder: {
      db: null,
      js: null,
    },
    tc: undefined,
    total: undefined,
  },
  partidas: {
    items: [],
    uens: [],
  },
  comments: {
    regulars: [],
    notesAndConsiderations: [],
  },
  customer: undefined,

  /**
   * Social reason the id selected on the combo
   */
  customerSocialReason: undefined,
  /**
   * Indicates if the document has been fetched
   */
  isDocumentFetched: false,

  contactsOnMemory: [],
  /**
   * Indicates if it's saving or updating the document
   */
  isPerformingQuery: false,
};

/**
 * Update the state of the component "useContract"
 * @param {import("./types").InitialStateI} state - State of the component
 * @param {import("./types").Actions} action - Dispatch information
 */
export function reducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case Actions.SET_CURRENCY: {
      return {
        ...state,
        document: {
          ...state.document,
          currency: action.payload,
        },
      };
    }

    case Actions.SET_INITIAL_STATE: {
      /**
       *
       * @param {"administracion"|"ventas"} module - Module which is the current
       * @param {import("./types").DateI} date - Information of the dates loaded by default
       */
      function evaluateDateToUse(module, date) {
        if (module === "administracion") {
          return {
            js: date.date,
            db: date.db,
          };
        } else {
          return {
            js: null,
            db: null,
          };
        }
      }

      return {
        ...state,
        document: {
          ...state.document,

          number: null,
          tc: action.payload.tc,
          expiration: evaluateDateToUse(
            action.payload.module,
            action.payload.expiration
          ),
          reminder: evaluateDateToUse(
            action.payload.module,
            action.payload.reminder
          ),
        },
        customer: action.payload.customer,
        customerSocialReason: action.payload.customerSocialReason,
        isDocumentFetched: true,
      };
    }

    case Actions.SET_START_DATE_CONTRACT: {
      return {
        ...state,
        document: {
          ...state.document,
          startDateContract: action.payload,
        },
      };
    }

    case Actions.SET_EDITION_INFO: {
      const idPeriocity = action.payload.periocity.type.id;

      return {
        ...state,
        document: {
          // TODO: Todavía no recibo la fecha inicio del contrato
          startDateContract:
            action.payload.startDate.yyyymmdd !== null
              ? addDays(new Date(action.payload.startDate.yyyymmdd), 1)
              : null,
          number: action.payload.docNumber,
          code: action.payload.documents.contract.code,
          currency: action.payload.currency.code,
          contact: {
            id: action.payload.contact.id,
            data: {
              id: action.payload.contact.id,
            },
          },
          expiration: {
            db: action.payload.expiration.yyyymmdd,
            js: dateWithCeroHours(
              addDays(new Date(action.payload.expiration.yyyymmdd), 1)
            ),
          },
          import: action.payload.amount.subtotal.number,
          iva: action.payload.amount.iva.number,
          periocity: {
            begin:
              idPeriocity === null
                ? null
                : new Date(action.payload.periocity.start),
            end:
              idPeriocity === null
                ? null
                : new Date(action.payload.periocity.end),
            id: idPeriocity === null ? "-1" : idPeriocity,
            message: "ND",
            value: idPeriocity === null ? null : action.payload.periocity.value,
          },
          reminder: {
            db: action.payload.reminder.yyyymmdd,
            js: dateWithCeroHours(
              addDays(new Date(action.payload.reminder.yyyymmdd), 1)
            ),
          },
          tc: action.payload.tcp.number,
          total: action.payload.amount.total.number,
        },
        customer: action.payload.customer.id,
        customerSocialReason: action.payload.customer.socialReason,
        isDocumentFetched: true,
      };
    }

    case Actions.SET_ODC_EDITION_INFO: {
      const idPeriocity = action.payload.periocity.type.id;

      return {
        ...state,
        document: {
          ...state.document,
          number: action.payload.docNumber,
          generateCxp: action.payload.generateCxp,
          currency: action.payload.currency.code,
          contact: {
            id: action.payload.contact.id,
            data: {
              id: action.payload.contact.id,
            },
          },
          expiration: {
            db: action.payload.expiration.yyyymmdd,
            js: dateWithCeroHours(
              addDays(new Date(action.payload.expiration.yyyymmdd), 1)
            ),
          },
          import: action.payload.amount.subtotal.number,
          iva: action.payload.amount.iva.number,
          periocity: {
            begin:
              idPeriocity === null
                ? null
                : new Date(action.payload.periocity.start),
            end:
              idPeriocity === null
                ? null
                : new Date(action.payload.periocity.end),
            id: idPeriocity === null ? "-1" : idPeriocity,
            message: "ND",
            value: idPeriocity === null ? null : action.payload.periocity.value,
          },
          reminder: {
            db: action.payload.reminder.yyyymmdd,
            js: dateWithCeroHours(
              addDays(new Date(action.payload.reminder.yyyymmdd), 1)
            ),
          },
          tc: action.payload.tcp.number,
          total: action.payload.amount.total.number,
        },
        customer: action.payload.customer.id,
        customerSocialReason: action.payload.customer.socialReason,
        isDocumentFetched: true,
      };
    }

    case Actions.SET_GENERATE_CXP: {
      return {
        ...state,
        document: {
          ...state.document,
          generateCxp: action.payload,
        },
      };
    }

    case Actions.SET_CODE: {
      return {
        ...state,
        document: {
          ...state.document,
          code: action.payload.length <= 0 ? null : action.payload,
        },
      };
    }

    case Actions.SET_IS_LOADING: {
      return {
        ...state,
        isPerformingQuery: action.payload,
      };
    }

    case Actions.SET_REMINDER: {
      return {
        ...state,
        document: {
          ...state.document,
          reminder: {
            db: action.payload.db,
            js: action.payload.js,
          },
        },
      };
    }

    case Actions.SET_EXPIRATION: {
      return {
        ...state,
        document: {
          ...state.document,
          expiration: {
            db: action.payload.db,
            js: action.payload.js,
          },
        },
      };
    }

    case Actions.SET_SOCIAL_REASON: {
      return {
        ...state,
        customer: action.payload.id,
        customerSocialReason: action.payload.socialReason,
      };
    }

    case Actions.SET_ITEMS: {
      return {
        ...state,
        document: {
          ...state.document,
          import: action.payload.import,
          iva: action.payload.iva,
          total: action.payload.total,
          currency: action.payload.currency,
        },
        partidas: {
          items: action.payload.items,
          uens: action.payload.uens,
        },
      };
    }

    case Actions.SET_COSTS_ODC: {
      return {
        ...state,
        document: {
          ...state.document,
          import: action.payload.costs.import,
          iva: action.payload.costs.iva,
          total: action.payload.costs.total,
          currency: action.payload.currency,
        },
        partidas: {
          items: action.payload.items,
          uens: action.payload.uens,
        },
      };
    }

    case Actions.SET_TC: {
      return {
        ...state,
        document: {
          ...state.document,
          tc: action.payload,
        },
      };
    }

    case Actions.SET_PERIOCITY: {
      return {
        ...state,
        document: {
          ...state.document,
          periocity: action.payload,
        },
      };
    }

    case Actions.SET_CONTACT: {
      return {
        ...state,
        document: {
          ...state.document,
          contact: action.payload,
        },
      };
    }

    case Actions.SET_NOTES_CONSIDERATIONS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          notesAndConsiderations: action.payload,
        },
      };
    }

    case Actions.SET_COMMENTS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          regulars: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
