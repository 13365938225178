import React, { useContext } from "react";
import { ContextReceiveCredtNotes } from "..";
import style from "pages/Ventas/NotasDeCreditoEmitidas/styles.module.scss";
import LinkAssociatedFiles from "./LinkAssociatedFiles";
import XmlDownload from "components/general/XmlDownload";
import PdfDownload from "components/general/PdfDownload";
import CancelCreditNoteReceived from "structure/CancelCreditNoteReceived";
import UpdateAccounting from "components/individual/UpdateAccounting";

const Container = ({ children }) => (
  <div className={style.containerOptionsCreditNote}>{children}</div>
);

export default function CreditNoteReceivedOptions() {
  const hook = useContext(ContextReceiveCredtNotes);

  if (hook.creditNote === undefined)
    return (
      <Container>
        <UpdateAccounting
          type="NCR"
          operationGrid={hook.operation}
          originalGrid={hook.original}
          onUpdated={() => hook.triggerSerch()}
        />
      </Container>
    );

  return (
    <Container>
      <LinkAssociatedFiles />
      <XmlDownload
        filename="Nota_de_crédito_recibida"
        idBlobFile={hook.creditNote.xml}
      />
      <PdfDownload
        filename="Nota_de_crédito_recibida"
        idBlobStorage={hook.creditNote.pdf}
      />

      <UpdateAccounting
        type="NCR"
        operationGrid={hook.operation}
        originalGrid={hook.original}
        onUpdated={() => hook.triggerSerch()}
      />

      {hook.module === "facturas" ? (
        <CancelCreditNoteReceived
          idCreditNoteReceived={hook.creditNote.id}
          isCancelated={hook.creditNote.status.id === 14}
          onCancelated={hook.refetchCurrentPage}
        />
      ) : null}
    </Container>
  );
}
