/**
 * Information of the dispatchs that can be executed
 * @type {import("./types").ActionsI}
 */
export const Actions = {
  SET_CURRENCY: "SET_CURRENCY",
  SET_INITIAL_STATE: "SET_INITIAL_STATE",
  UPDATE_SOCIAL_REASON: "UPDATE_SOCIAL_REASON",
  SET_SOCIAL_REASON: "SET_SOCIAL_REASON",
  SET_ITEMS: "SET_ITEMS",
  SET_TC: "SET_TC",
  SET_CONTACT: "SET_CONTACT",
  SET_REMINDER: "SET_REMINDER",
  SET_EXPIRATION: "SET_EXPIRATION",
  SET_PERIOCITY: "SET_PERIOCITY",
  SET_NOTES_CONSIDERATIONS: "SET_NOTES_CONSIDERATIONS",
  SET_COMMENTS: "SET_COMMENTS",
  SET_CODE: "SET_CODE",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_EDITION_INFO: "SET_EDITION_INFO",
  SET_GENERATE_CXP: "SET_GENERATE_CXP",
  SET_COSTS_ODC: "SET_COSTS_ODC",
  SET_START_DATE_CONTRACT: "SET_START_DATE_CONTRACT",
  SET_ODC_EDITION_INFO: "SET_ODC_EDITION_INFO",
};
