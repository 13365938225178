import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import { GetDocument, cancelDocument } from "helpers/Apis/Documents";
import { dateToDbFormat } from "helpers/dates";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MOTIVO_OTROS } from "..";

/**
 * State in order to cancel an invoice
 * @type {import("./types").StateUseCancelInvoice}
 */
const INITIAL_STATE = {
  isLoading: true,
  isCancelling: false,
  isOpen: false,
  endDate: undefined,
  idContractOrigin: undefined,
  idContractParent: undefined,
  hasContract: undefined,
  reminder: undefined,
  startDate: undefined,
  dtoCancellation: undefined,
  alreadyCancelled: true,
  cancelInvoice: false,
  isWon: true,
  idMotive: null,
};

const dummyFunction = () => {};

/**
 * @type {import("./types").ContextCancelInvoice}
 */
export const CONTEXT_INITIAL_STATE = {
  ...INITIAL_STATE,
  close: dummyFunction,
  open: dummyFunction,
  dialog: {
    close: dummyFunction,
    open: dummyFunction,
    refDialog: undefined,
  },
  updateEndDate: dummyFunction,
  updateReminderDate: dummyFunction,
  updateStartDate: dummyFunction,
  attemptCancelation: async () => {},
  updateMotiveCancellation: dummyFunction,
  updateSwitchCancelation: dummyFunction,
  updateMotive: dummyFunction,
};

export default function useCancelInvoice(id, onCancelated = () => {}) {
  const [state, setState] = useState(INITIAL_STATE);

  const user = useContext(LoginContext);

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (id === null || !state.isOpen) {
        setState(INITIAL_STATE);
        return;
      }

      const quoteApi = await GetDocument(id);

      const idContractParent =
        quoteApi.documents.origin.id === null
          ? undefined
          : quoteApi.documents.origin.id;
      const idContractOrigin =
        quoteApi.documents.contract.id === null
          ? undefined
          : quoteApi.documents.contract.id;

      const idOfContract =
        idContractOrigin === null ? idContractParent : idContractOrigin;

      const hasContract =
        idContractParent === undefined && idContractOrigin === undefined
          ? false
          : true;

      const alreadyCancelled = quoteApi.status.id === 3 ? true : false;

      /**
       * @type {import("helpers/Apis/typesDocuments").CancelQuote}
       */
      const dtoCancellation = {
        contractId: idOfContract,
        docuemntType: 1,
        executiveId: user.userInfo[0].userID,
        modifyBy: user.userInfo[0].fullName,
        beginDate: undefined,
        endDate: undefined,
        reminderDate: undefined,
        quoteId: quoteApi.id,
        ocId: "",
        preInvoiceID: "",
        invoiceID: "",
        motive: "",
      };

      setState((current) => ({
        ...current,
        isLoading: false,
        idContractParent,
        hasContract,
        idContractOrigin,
        dtoCancellation,
        alreadyCancelled,
        isWon: quoteApi.status.id === 2 ? true : false,
      }));
    })();
  }, [state.isOpen, id]);

  useEffect(() => {
    if (state.reminder === undefined) return;

    if (state.startDate >= state.reminder) {
      setState((current) => ({
        ...current,
        reminder: undefined,
        endDate: undefined,
      }));
    }
  }, [state.startDate]);

  useEffect(() => {
    if (!state.cancelInvoice) {
      setState((current) => ({
        ...current,
        startDate: undefined,
        endDate: undefined,
        reminder: undefined,
      }));
    }
  }, [state.cancelInvoice]);

  useEffect(() => {
    setState((current) => ({
      ...current,
      dtoCancellation: {
        ...current.dtoCancellation,
        motive: "",
      },
    }));
  }, [state.idMotive]);

  const open = () => {
    dialog.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const close = () => {
    dialog.close();
    setState((current) => ({
      ...current,
      ...INITIAL_STATE,
    }));
  };

  /**
   * Update the cancelation motive
   * @param {number} id - Id of the cancelation motives
   * @returns {void}
   */
  const updateMotive = (id) =>
    setState((current) => ({
      ...current,
      idMotive: id,
    }));

  const updateStartDate = (date) =>
    setState((current) => ({
      ...current,
      startDate: date,
    }));

  const updateEndDate = (date) =>
    setState((current) => ({
      ...current,
      endDate: date,
    }));

  const updateReminderDate = (date) =>
    setState((current) => ({
      ...current,
      reminder: date,
    }));

  const attemptCancelation = async () => {
    setState((current) => ({
      ...current,
      isCancelling: true,
    }));

    const sendDateParameters = state.hasContract && !state.cancelInvoice;

    /**
     * @type {import("helpers/Apis/typesDocuments").CancelQuote}
     */
    let dto = {
      ...state.dtoCancellation,
      beginDate: sendDateParameters ? dateToDbFormat(state.startDate) : null,
      endDate: sendDateParameters ? dateToDbFormat(state.endDate) : null,
      reminderDate: sendDateParameters ? dateToDbFormat(state.reminder) : null,
      contractId:
        state.dtoCancellation.contractId === undefined
          ? ""
          : `${state.dtoCancellation.contractId}`,
    };

    if (!sendDateParameters) {
      delete dto["beginDate"];
      delete dto["endDate"];
      delete dto["reminderDate"];
    }

    const wasCancelled = await cancelDocument(dto);

    setState((current) => ({
      ...current,
      isCancelling: false,
    }));
    if (wasCancelled) {
      close();
      onCancelated();
    }
  };

  const updateMotiveCancellation = (motive) =>
    setState((current) => ({
      ...current,
      dtoCancellation: {
        ...current.dtoCancellation,
        motive,
      },
    }));

  const updateSwitchCancelation = (cancel) =>
    setState((current) => ({
      ...current,
      cancelInvoice: cancel,
    }));

  return {
    ...state,
    dialog,
    close,
    open,
    updateStartDate,
    updateEndDate,
    updateReminderDate,
    attemptCancelation,
    updateMotiveCancellation,
    updateSwitchCancelation,
    updateMotive,
  };
}
