import React, { useContext, useState } from "react";
import Creatable from "react-select/creatable";
import { ContextBanks } from "..";

/**
 * Render the list of social reason banks loaded on the system
 * @param {import("./types").PropsList} props - Props
 * @returns {JSX.Element}
 */
export const List = ({ onChange = () => {} }) => {
  const { banks, handleChangeBank, selected } = useContext(ContextBanks);

  const [options, setOptions] = useState(
    banks.map((bank) => ({
      ...bank,
      value: bank.claveSat,
      label: bank.shortName,
    }))
  );

  /**
   * Handle the select of an item on the combo
   * @param {import("customHooks/useBanks/types").SelectedBankI} bank - Bank information
   */
  const handleChange = (bank) => {
    handleChangeBank(bank);
    onChange(bank);
  };

  return (
    <Creatable
      placeholder="Selecciona o escribe para crear"
      isClearable={false}
      classNamePrefix="saiko-select"
      className="saiko-select"
      options={options}
      onChange={handleChange}
      value={selected}
    />
  );
};
