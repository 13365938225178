import React, { useContext } from "react";
import { ContextInvoice } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function ExcelReport() {
  const hook = useContext(ContextInvoice);

  if (hook.state.isDownloadingXlsx)
    return <Spinner hidden={false} text="Descargando" />;

  return (
    <ButtonV2 onClick={hook.attemptDownloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
