import React, { useContext } from "react";
import { BankAccountContext } from "..";
import { Label } from "structure/Document";

export const ViewCLABE = () => {
  const { account } = useContext(BankAccountContext);

  return (
    <div>
      <Label>CLABE</Label>
      <p>{account.CLABE === null ? "No hay CLABE" : account.CLABE}</p>
    </div>
  );
};
