import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import Dialog from "structure/Dialog";
import RangeDate from "../RangeDate";
import StatusFilter from "../StatusFilter";
import StatusAccounted from "../StatusAccounted";
import TypeMovement from "../Type";
import SearchBarConcilation from "../SearchBarConcilation";
import { ConcilationContext } from "..";
import style from "./styles.module.scss";
import { Label } from "structure/Document";
import { parseDateToText } from "helpers/dates";

const ConcilationDescriptionStatus = {
  2: "Activos",
  3: "Conciliados",
};

const AccountingDescription = {
  false: "No contabilizado",
  true: "Contabilizado",
};

const MovementTypeDescription = {
  1: "Ingresos",
  2: "Egresos",
};

export default function FilterMobile() {
  const hook = useContext(ConcilationContext);

  return (
    <>
      <div className={style.containerPills}>
        <ButtonV2 onClick={hook.dialog.open}>
          <Icon icon="filter" />
        </ButtonV2>

        <div className={style.pillFilter}>
          <Label>Desde</Label>
          <span>
            {hook.from === null ? "NA" : parseDateToText(hook.from, "short")}
          </span>
        </div>

        <div className={style.pillFilter}>
          <Label>Hasta</Label>
          <span>{parseDateToText(hook.to, "short")}</span>
        </div>

        <div className={style.pillFilter}>
          <Label>Conciliacion</Label>
          <span>{ConcilationDescriptionStatus[hook.status] || "Todos"}</span>
        </div>

        <div className={style.pillFilter}>
          <Label>Contabilizacion</Label>
          <span>{AccountingDescription[hook.accountingState] || "Todos"}</span>
        </div>

        <div className={style.pillFilter}>
          <Label>Tipo</Label>
          <span>{MovementTypeDescription[hook.type] || "Todos"}</span>
        </div>
      </div>

      <Dialog
        title="Filtros"
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
      >
        <div className={style.mobileFilter}>
          <RangeDate />
          <StatusFilter />
          <StatusAccounted />
          <TypeMovement />
          <SearchBarConcilation />
        </div>
      </Dialog>
    </>
  );
}
