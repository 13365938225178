import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { createContext } from "react";
import useCancelCxp from "./useCancelCxp";
import scss from "./styles.module.scss";
import { CancelCxps } from "./CancelButton";
import { useRef } from "react";

/**
 * @type {import("react").Context<import("./types").ContextCancelCxp>}
 */
export const ContextCancelCxp = createContext();
const { Provider } = ContextCancelCxp;

/**
 * Render a modal in order to dis-associate the movements of an specific movement from the bank account
 * @param {import('./types').PropsCancelCxp} props - Props
 * @returns {JSX.Element} Component
 */
export const CancelCxp = ({ idMovement, onDisassociated = () => {} , idBank }) => {
  const movement = useCancelCxp(idMovement, onDisassociated,idBank);

  return (
    <Provider value={movement}>
      <ModalV2
        buttonText="Des-asociar"
        aditionalClasses="mr-3"
        showCancelButton={false}
        cbOpen={movement.openModal}
        title={movement.title}
        width={75}
        idCloseIcon={movement.id.current}
        footer={<CancelCxps />}
      >
        {movement.isFetching ? (
          <Spinner hidden={false} text="Cargando asociaciones del egreso..." />
        ) : movement.isPerformingQuery ? (
          <h1 className="text-center py-5">
            Un momento, no cierres esta ventana...
          </h1>
        ) : (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={movement.isCheckedParenToggle}
                    onChange={(e) => movement.handleToggleAll(e.target.checked)}
                  />
                </th>
                <th>Proveedor</th>
                <th>Factura</th>
                <th>Parcialidad</th>
                <th>Total</th>
                <th>Asociado</th>
                <th>Importe egreso</th>
              </tr>
            </thead>

            <tbody className={scss.tableBody}>
              {movement.associations.map((association) => (
                <tr key={association.uuidFrontend}>
                  <td>
                    <label htmlFor={association.uuidFrontend}>
                      <input
                        checked={movement.isCheckedItem(association.id)}
                        type="checkbox"
                        id={association.uuidFrontend}
                        onChange={(e) =>
                          movement.handleOnClickAssociation(
                            e.target.checked,
                            association
                          )
                        }
                      />
                    </label>
                  </td>

                  <td>
                    <label htmlFor={association.uuidFrontend}>
                      {association.invoice.socialReason}
                    </label>
                  </td>

                  <td>
                    <label htmlFor={association.uuidFrontend}>
                      {association.invoice.folio}
                    </label>
                  </td>

                  <td>
                    <label htmlFor={association.uuidFrontend}>
                      {association.cxp.partialitie.formated}
                    </label>
                  </td>

                  <td className="text-right">
                    <label htmlFor={association.uuidFrontend}>
                      {association.invoice.total.text}{" "}
                      {association.invoice.currency}
                    </label>
                  </td>

                  <td className="text-right">
                    <label htmlFor={association.uuidFrontend}>
                      {association.applied.text}
                      {association.invoice.currency}
                    </label>
                  </td>

                  <td className="text-right">
                    <label htmlFor={association.uuidFrontend}>
                      {association.invoice.paid.text} {movement.bank.currency}
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ModalV2>
    </Provider>
  );
};
