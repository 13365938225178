import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

/**
 * This will insert an input of type phone.
 * This will set on real time a hidden input that indicates if the number
 * it's correct or not. So, the validation at the moment of send it's up to you
 * of read that input. 0 invalid and 1 valid.
 * @component Phone
 * @tutorial phone
 *
 * @param {object} props - Props
 * @param {string?} props.aditionalClasses - CSS class for the container of input
 * @param {string} props.htmlFor - for attribute of the input
 * @param {string} props.labelText - Label that gonna be display for the input
 * @param {string} props.idInput - id that gonna have the input phone
 * @param {string} props.placeholderTxt - placeholder of the input
 * @param {string} [props.lada=""] - Lada
 * @param {string} [props.number=""] - Number
 * @param {object?} props.forwardRef - object 'register' of react-hook-form
 * @param {number|string} [props.value=""] - Number phone in case must be a default bringed from an external source
 * @param {(data:import("./typesPhone").onChangePhoneI)=>void} props.onChange - Information of the number when user starts typing
 * @returns {JSX.Element} Number phone input
 */
function Phone({
  aditionalClasses = "",
  htmlFor,
  labelText,
  idInput,
  placeholderTxt = "Lada + numero",
  forwardRef,
  value = "",
  lada = "",
  number = "",
  onChange = () => {},
}) {
  useEffect(() => {
    document.getElementById(`isValid_${idInput}`).value = 1;
  }, []);

  const [state,setState] = useState({
    isValid:true,
    lada,
    number
  });

  useEffect(()=>{
    setState(current=>({
      ...current,
      lada,
      number
    }))
  },[lada,number])

  function validateNumber(actualPhone, mustContain) {
    if (actualPhone !== mustContain) {
      displayError(mustContain);
      invalidInputs();
      return false;
    } else {
      hideError();
      return true;
    }
  }

  function invalidInputs() {
    document.getElementById(`number_${idInput}`).value = "";
    document.getElementById(`isValid_${idInput}`).value = 0;
    document.getElementById(`lada_${idInput}`).value = "";
  }

  function displayError(charactersNumbers) {
    const domError = document.getElementById(`error_${idInput}`);

    if (domError) {
      domError.innerText = `${charactersNumbers} digitos requeridos`;
    }
  }

  function hideError() {
    document.getElementById(`isValid_${idInput}`).value = 1;
    document.getElementById(`error_${idInput}`).innerText = "";
  }

  function writeLadaOnFakes(lada) {
    if (lada !== undefined) {
      lada = lada.replaceAll("+", "");
      document.getElementById(`lada_${idInput}`).value = lada;
    }
  }

  function isValidLadaAndFlag(input, dialCode, hasNumbers) {
    dialCode = dialCode?.substr(0, hasNumbers);

    if (input === "") {
      quitAlertLada();
      setValid();
      return false;
    }

    if (input !== dialCode) {
      showAlertLada();
      setInvalid();
      return false;
    } else {
      quitAlertLada();
      setValid();
    }

    return true;
  }

  function setInvalid() {
    document.getElementById(`isValid_${idInput}`).value = 0;
  }

  function setPhone(phone) {
    document.getElementById(`number_${idInput}`).value = phone;
  }

  function getLadaLibrary(number) {
    // 1. Quit the + symbol (Leave as number)
    const notPlusSymbol = number?.replaceAll("+", "");

    // 2. Search where finish the lada code
    let positionLada = notPlusSymbol.indexOf(" ");

    // To avoid get -1
    if (positionLada < 1) positionLada = notPlusSymbol.length;

    // 3. Extract the lada with the known range
    let lada = notPlusSymbol.substr(0, positionLada);

    // 4. If there's not lada wrote on the input
    if (isNaN(lada)) {
      lada = "";
    }

    return lada;
  }

  function showAlertLada() {
    document.getElementById(`error_${idInput}`).innerText = "Escribe la lada";
  }

  function quitAlertLada() {
    document.getElementById(`error_${idInput}`).innerText = "";
  }

  function setValid() {
    document.getElementById(`isValid_${idInput}`).value = 1;
  }

  function getLadaLength(format) {
    if (format === undefined) {
      return 10;
    }

    const size = format.substr(1, format.indexOf(" "));
    return parseInt(size.length, 10) - 1;
  }

  function getNumberPhone(format) {
    if (format === undefined) {
      return false;
    }

    const beginPhone = format.indexOf(" ");

    if (beginPhone === -1) {
      return false;
    }

    return format.substr(beginPhone, format.length);
  }

  function getNumberPhoneLength(format) {
    if (format === undefined) {
      return 0;
    }

    const begin = format.indexOf(" ");

    if (begin === -1) {
      return 0;
    }

    let unformatedNumber = format.substr(begin, format.length);

    unformatedNumber = unformatedNumber.replaceAll(" ", "");

    return unformatedNumber.length;
  }

  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor={htmlFor}>
        {labelText}
      </label>
      <div className="d-flex align-items-center">
        <PhoneInput
          inputProps={{
            id: `${idInput}-fake`,
          }}
          inputStyle={{
            width: "100%",
          }}
          regions={["america", "europe"]}
          preferredCountries={["mx", "us", "ca", "es", "fr"]}
          enableAreaCodes={true}
          enableSearch={true}
          disableSearchIcon={true}
          inputClass="form-control"
          value={`${value}`}
          containerClass="none"
          enableLongNumbers={true}
          placeholder={placeholderTxt}

          onChange={(value, country, e, formattedValue) => {
            // 1. Lada that provides the library as 52,1,599,etc.
            const ladaLibrary = getLadaLibrary(formattedValue);

            // 2. Get how many digits it's the lada
            const lengthLada = getLadaLength(country?.format);

            // 3. Get the number phone format like **-****-****
            const numberPhone = getNumberPhone(formattedValue);

            // 4. Check the user input againts the lada of library
            const isValidLada = isValidLadaAndFlag(
              ladaLibrary,
              country.dialCode,
              lengthLada
            );

            // 5. Invalid lada, warning error
            if (!isValidLada) {
              onChange({
                isValidNumber: ladaLibrary === "" ? true : false,
                lada: ladaLibrary === "" ? null : ladaLibrary,
                number: null,
              });


              setState(current=>({
                ...current,
                isValid:ladaLibrary === "" ? true : false,
                lada:ladaLibrary === "" ? "" : ladaLibrary,
                number:"",
              }))
              return;
            }

            // 6. Check how many digits must contain this country (number)
            const numberPhoneLength = getNumberPhoneLength(country?.format);

            // User erased the input, valid phone input
            if (numberPhone === false) {
              quitAlertLada();
              onChange({
                isValidNumber: false,
                lada: ladaLibrary,
                number: null,
              });

              setState(current=>({
                ...current,
                isValid:false,
                lada:ladaLibrary,
                number:""
              }))
              return;
            }

            // 7. Check how many digits has the input of user
            const actualPhoneLength = numberPhone.replaceAll(" ", "").length;

            // 8. Validate input user against number digits of library
            const isValidNumber = validateNumber(
              actualPhoneLength,
              numberPhoneLength
            );

            if (isValidNumber) {
              // 9. All validations correct, set data on hidden inputs
              setPhone(numberPhone);
              writeLadaOnFakes(ladaLibrary);
            }


            onChange({
              isValidNumber,
              lada: ladaLibrary,
              number: numberPhone,
            });

            setState(current=>({
              ...current,
              isValid:isValidNumber,
              lada:ladaLibrary,
              number:numberPhone
            }))
          }}
        />

        <input
          type="text"
          className="d-none"
          ref={forwardRef}
          name={`lada_${idInput}`}
          id={`lada_${idInput}`}
          value={state.lada}
        />

        <input
          type="text"
          className="d-none"
          ref={forwardRef}
          name={`number_${idInput}`}
          id={`number_${idInput}`}
          value={state.number}
        />

        <input type="number" className="d-none" id={`isValid_${idInput}`} value={state.isValid ? 1 : 0}/>
      </div>
    </div>
  );
}

export default Phone;
