import TypeCustomerDirectory from "components/individual/TypeCustomerDirectory";
import React, { useContext } from "react";
import { AddContext } from "../Addv2";

export default function AddTypeCustomer() {
  const hook = useContext(AddContext);

  return (
    <div className="type">
      <TypeCustomerDirectory
        type={hook.customer.type === 1 ? "cliente" : "proveedor"}
        onChange={hook.updateTypeOfCustomer}        
      />
    </div>
  );
}
