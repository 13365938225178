import React, { useContext } from "react";
import { ContextGestionOc } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function Update() {
  const hook = useContext(ContextGestionOc);

  if (!hook.canUpdate) return <></>;

  if (hook.isUpdating) return <Spinner text={"Actualizando"} hidden={false} />;

  return <ButtonV2 onClick={hook.updateOcRecords}>Actualizar</ButtonV2>;
}
