import React, { createContext } from "react";
import useGestionOc from "./useGestionOc";
import TopModule from "structure/TopModule";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import Table from "./Table";
import Filter from "./Filter";
import ui from "./styles.module.scss";
import OptionsContainer from "./OptionsContainer";
import ManagmentOcMobileFilter from "./ManagmentOcMobileFilter";

/**
 * @type {import("react").Context<import("./useGestionOc/types").ReturnGestionOc|undefined>}
 */
export const ContextGestionOc = createContext(undefined);

export default function GestionOc() {
  const hook = useGestionOc();

  return (
    <ContextGestionOc.Provider value={hook}>
      <div className={ui.ocManagment}>
        <TopModule>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={ui.topModule}>
            <Filter />
            <OptionsContainer />
            <ManagmentOcMobileFilter />
          </div>
        </TopModule>

        <div className={ui.tableContainer}>
          <Table />
        </div>
      </div>
    </ContextGestionOc.Provider>
  );
}
