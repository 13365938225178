import useDialog from "customHooks/useDialog";
import { addManufacturer, getManufacturers } from "helpers/Apis/Directory";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseManufacturers}
 */
const INITIAL_STATE = {
  isLoading: true,
  manufacturers: [],
  manufacturer: null,
  key: "",
  isAdding: false,
  refetch: false,
};

/**
 * Handle the manufacturers of a customer
 * @param {number|null} id - Id of the customer
 * @param {(manufacturers:import("../../../../../server/models/typesDirectoryModel").Manufacturer[])=>void} [onChange=()=>{}] - Manufacturers that is using the customer
 * @returns {import("./types").ReturnUseManufacturers}
 */
export default function useManufacturers(id, onChange = () => {}) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  const setLoading = (isLoading) =>
    setState((current) => ({ ...current, isLoading }));

  const setManufacturers = (manufacturers) =>
    setState((current) => ({ ...current, manufacturers }));

  useEffect(() => {
    onChange(state.manufacturers);
  }, [state.manufacturers]);

  useEffect(() => {
    (async function () {
      setLoading(true);

      if (typeof id !== "number") {
        setLoading(false);
        return;
      }

      const manufacturers = await getManufacturers(id);

      setManufacturers(manufacturers);
      setLoading(false);
    })();
  }, [id, state.refetch]);

  const setManufacturer = (manufacturer) =>
    setState((current) => ({
      ...current,
      manufacturer,
    }));

  const setKey = (key) =>
    setState((current) => ({
      ...current,
      key,
    }));

  const attemptAdd = async () => {
    if (typeof id !== "number") {
      setState((current) => ({
        ...current,
        isAdding: true,
        manufacturers: [
          {
            id: `${window.crypto.randomUUID()}`,
            key: state.key,
            status: true,
            supplier: {
              id: state.manufacturer.id,
              socialReason: state.manufacturer.socialReason,
            },
          },
          ...current.manufacturers,
        ],
      }));

      setState((current) => ({
        ...current,
        isAdding: false,
        key: "",
      }));

      return;
    }

    setState((current) => ({
      ...current,
      isAdding: true,
    }));

    const wasAdded = await addManufacturer(
      id,
      state.manufacturer.id,
      state.key
    );

    setState((current) => ({
      ...current,
      isAdding: false,
    }));

    if (wasAdded) {
      setState((current) => ({
        ...current,
        manufacturer: null,
        key: "",
        refetch: !current.refetch,
      }));
    }
  };

  const refetchData = () =>
    setState((current) => ({ ...current, refetch: !current.refetch }));

  /**
   * Information of the manufacturer edited
   * @param {import("../../../../../server/models/typesDirectoryModel").Manufacturer} manufacturer - Information of the item operated
   * @param {import("../Manufacturer/types").OperationManufacturer} operation - Operation of the items
   */
  const handleOnChange = (manufacturer, operation) => {
    if (typeof id === "number") {
      refetchData();
      return;
    }

    setLoading(true);

    const index = state.manufacturers.findIndex(
      (manu) => manu.id === manufacturer.id
    );

    if (operation === "delete") {
      setState((current) => ({
        ...current,
        manufacturers: state.manufacturers.filter((manu, i) => i !== index),
      }));
    } else {
      setState((current) => ({
        ...current,
        manufacturers: state.manufacturers.map((manu, i) => {
          if (i === index) return manufacturer;
          return manu;
        }),
      }));
    }

    setLoading(false);
  };

  return {
    ...state,
    dialog,
    setManufacturer,
    refetchData,
    setKey,
    attemptAdd,
    handleOnChange,
  };
}
