import { dateToDbFormat } from "helpers/dates";
import * as yup from "yup";

// const nameRegexExpression = new RegExp("^[aA-zZ,ñ, ]+$");
const nameRegexExpression = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d1, ]+$");
export const emailRegexExpression = new RegExp(
  /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/
);
const numbersRegexExpression = new RegExp(/^[0-9,\ ]+$/);
const phoneNumberRegexExpression = new RegExp(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/)

export const nombre = yup
  .string()
  // .matches(/^[A-Z,ñ]+$/,{excludeEmptyString:true,message:'Nombre invalido, solo letras'})
  .matches(nameRegexExpression, { message: "Nombre invalido, solo letras" })
  .max(30, "No mas de 30 caracteres")
  .trim()
  .required("El nombre es requerido");

export const middleName = yup
  .string()
  .max(30, "No mas de 30 caracteres")
  .trim()
  .matches(nameRegexExpression, {
    excludeEmptyString: true,
    message: "Nombre invalido, solo letras",
  });

export const apellidoP = yup
  .string()
  .matches(nameRegexExpression, {
    message: "Apellido paterno invalido, solo letras",
  })
  .max(30, "No mas de 30 caracteres")
  .trim()
  .required("Campo requerido");

export const apellidoM = yup
  .string()
  // .matches(nameRegexExpression, {
  //   message: "Apellido materno invalido, solo letras",
  // })
  .max(30, "No mas de 30 caracteres")
  .trim()
  .nullable(true);

export const email = yup
  .string()
  .matches(emailRegexExpression, { message: "Email invalido" })
  .max(50, "No mas de 50 caracteres")
  .trim()
  .required("Campo requerido");

export const puesto = yup
  .string()
  .matches(nameRegexExpression, {
    message: "El puesto solo debe de contener letras",
    excludeEmptyString: true,
  })
  .trim()
  .max(100, "No mas de 100 caracteres");

export const lada_phone = yup
  .string()
  .max(3, "Lada incorrecta, maximo 3 caracteres")
  .matches(numbersRegexExpression, {
    message: "Lada incorrecta",
    excludeEmptyString: true,
  });

export const number_phone = yup
  .string()
  .max(20, "Lada incorrecta, maximo 20 caracteres")
  .matches(phoneNumberRegexExpression, {
    message: "Numero incorrecto",
    excludeEmptyString: true,
  });

export const lada_cellphone = yup
  .string()
  .max(3, "Lada incorrecta, maximo 3 caracteres")
  .matches(numbersRegexExpression, {
    message: "Lada incorrecta",
    excludeEmptyString: true,
  });

export const number_cellphone = yup
  .string()
  .max(20, "Lada incorrecta, maximo 20 caracteres")
  .matches(phoneNumberRegexExpression, {
    message: "Numero incorrecto",
    excludeEmptyString: true,
  });

export const birthDay = yup
  .string()
  .typeError("La fecha debe tener formato YYYY-MM-DD")
  .nullable(true)
  .default(null)
  .transform((input, value) => {
    try {

      if(typeof(input)==="string") return dateToDbFormat(new Date(input));

      return null
    } catch (e) {
      return null
    }
  });

export const estatus = yup.boolean().required('Obligatorio').default(true).typeError('El estatus debe ser activo/inactivo');

export const isForColletion = yup.boolean().required('Obligatorio').default(false).typeError('El estatus debe de ser activo/inactivo');

export const isForPayment = yup.boolean().required('Obligatorio').default(false).typeError('El estatus debe de ser activo/inactivo');

export const profileUpdateSchema = yup.object().shape({
  firstName:nombre,
  middleName,
  lastName1:apellidoP,
  lastName2:apellidoM,
  email,
});

export const validationSchema = yup.object().shape({
  apellidoM,
  apellidoP,
  birthDay,
  email,
  estatus,
  isForColletion,
  isForPayment,
  lada_cellphone,
  lada_phone,
  middleName,
  nombre,
  number_cellphone,
  number_phone,
  puesto,
});
