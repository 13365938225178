import axios from "axios";
import { DisplayError, Error, Success } from "helpers/alerts";
import { getDateFromUtc } from "helpers/dates";
import {
  base64ToFile,
  base64ToFileV2,
  base64toBlob,
  fileArrayToFileList,
  getExtensionFileName,
  xmlFileToJson,
} from "helpers/files";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "./fetch";
import * as byte from "byte-size";
import { parseToFolio } from "helpers/documents";

/**
 * Fetch the associated files of an entity
 * @param {import("types/typedef/files").idTypeEntity} idTypeEntity - Id of the type entity to fetch
 * @param {number} idRegister - Id of the register to search
 * @param {number} page - Page to request on the associated files
 * @param {1|0|"-1"} status - Status of the file
 * @returns {Promise<import("types/typedef/files").AssociatedFilesI}> Information of the associated files fetched
 */
export async function getAssociatedFilesV2(
  idTypeEntity,
  idRegister,
  page,
  status = "-1"
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}archivos/v2/asociados?tipoAsociacion=${idTypeEntity}&idRegistro=${idRegister}&pagina=${page}&estatus=${status}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return {
      pages: 0,
      actualPage: 0,
      noRegisters: 0,
      files: [],
    };
  }
}

export async function addFileBlobStorage(
  files = [],
  informativeFiles,
  executive,
  idRegister,
  idTypeEntity,
  pathUpload
) {
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      files: informativeFiles,
      executive,
      idRegister,
      idTypeEntity,
      pathUpload,
    })
  );

  files.forEach((file, i) => formData.append(`${i}`, file));

  try {
    await axios.post(`${URL_BASE}archivos/v2/asociados`, formData, {
      withCredentials: true,
    });

    Success(() => {}, "Archivos subidos correctamente");

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Delete files from blob storage
 * @param {number[]} ids - Ids of the register to delete from the table
 * @param {object} executive - Information of the executive who deleted the information
 * @returns {boolean} True if file(s) were deleted
 */
export async function deleteFilesBlobStorage(ids = [], executive) {
  try {
    await axios.delete(`${URL_BASE}archivos/v2/asociados`, {
      data: {
        ids,
        executive,
      },
      withCredentials: true,
    });

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Download ONE FILE from the blob storage
 * @param {number} idFile - Id of the file to download
 * @param {string} fileName - Name will have the file once its downloaded
 * @returns {Promise<File>} File downloaded
 */
export async function downloadFile(
  idFile,
  fileName = window.crypto.randomUUID(),
  downloadOnFinish = true
) {
  try {
    /**
     * Information of the file requested to download
     * @type {{data:import("types/typedef/customHooks/useAssociateFiles").DtoDownloadFile}}
     */
    const {
      data: { base64, mime },
    } = await axios.get(
      `${URL_BASE}archivos/v2/asociados/descargar/${idFile}`,
      {
        withCredentials: true,
      }
    );

    const file = base64ToFile(mime, base64, fileName, downloadOnFinish);

    return file;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
  }
}

/**
 * Get the file comments
 * @param {number} id - Id of the associated file
 * @returns {Promise<import("../../../../types/files").ParsedFileCommentI[]>}
 */
export async function getFileComments(id) {
  try {
    /**
     * Files got from the server
     * @type {{data:import("../../../../types/files").FileCommentI[]}}
     */
    const { data } = await axios.get(
      `${URL_BASE}archivos/v2/asociados/comentarios/${id}`,
      { withCredentials: true }
    );

    const formatedComment = data.map((comment) => ({
      ...comment,
      createdDate: {
        utc: comment.createdDate,
        formated: new Intl.DateTimeFormat("es-MX", {
          dateStyle: "long",
          timeStyle: "medium",
          hour12: true,
        }).format(getDateFromUtc(comment.createdDate)),
      },
    }));

    return formatedComment;

    return data;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
  }
}

/**
 * Add a comment to an associated file
 * @param {string} comment - Comment typed on input
 * @param {number} idFile - Id of the associated file
 * @param {string} fullNameExecutive - Name of the executive who created the comment
 */
export async function addFileComment(comment, idFile, fullNameExecutive) {
  try {
    await axios.post(
      `${URL_BASE}archivos/v2/asociados/comentario`,
      {
        comment,
        idFile,
        fullNameExecutive,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Download a file from the blob storage of azure
 * @param {number} id - Id of the file to download
 * @param {string?} contentType - Content type in case the server returns an unreconized extension
 * @returns {Promise<Blob|undefined>} File downloaded from the server
 */
export async function downloadFileFromBlobV3(id, contentType = null) {
  try {
    const response = await fetch(
      `${URL_BASE}archivos/v3/asociados/descargar/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (response.ok && isValidHttpResCode(response.status)) {
      const blob = await response.blob();

      return blob;
    }
    return undefined;
  } catch (error) {
    DisplayError(error);
    return undefined;
  }
}

/**
 * Download the `xml` and `pdf` file of the FE with the id of the blob storage record
 * @param {number} idBlobXml - Id of the blob file on MS Blob Storage
 * @param {number} idBlobPdf - Id of the blob file on MS Blob Storage
 * @returns {Promise<File[]>}
 */
export async function downloadFeFiles(idBlobXml,idBlobPdf){
  try {

    const [xml,pdf] = await Promise.all([
      downloadFileFromBlobV3(idBlobXml),
      downloadFileFromBlobV3(idBlobPdf)
    ]);

    const xmlContent = await xmlFileToJson(xml);

    const pdfFile = new File(
      [pdf],
      `FE-${parseToFolio(+xmlContent.$.Folio)}.pdf`,
      { type: "application/pdf" }
    );
    const xmlFile = new File(
      [xml],
      `FE-${parseToFolio(+xmlContent.$.Folio)}.xml`,
      { type: "application/xml" }
    );

    return [pdfFile,xmlFile]

  } catch (error) {
    
  }
}

/**
 * Convert a blob to file instance
 * @param {Blob} blob - Blob
 * @param {string?} filename - Filename
 * @param {FilePropertyBag} opts - Options
 * @returns {File}
 */
export const blobToFile = (
  blob,
  filename = window.crypto.randomUUID(),
  opts = {}
) => new File([blob], filename, opts);

/**
 *
 * @param {File[]} files - Array of files to be uploaded
 * @param {import("./typesAssociateFilesV2").Executive} executive - Information of the user who is uploding the files
 * @param {number} idRegister - Id of the record that belongs those files
 * @param {import("./typesAssociateFilesV2").AssociateFileEntity} entity - Id of the object which belongs the files
 * @param {string} pathUpload - Url/Folder where the files are gonna be saved on the repository
 * @returns {Promise<boolean>} True if the files were uploaded
 */
export async function uploadFilesToRepository(
  files = [],
  executive,
  idRegister,
  entity,
  pathUpload
) {
  /**
   * @type {import("./typesAssociateFilesV2").AssociateFileEntityId}
   */
  const entityIds = {
    documentos: 1,
    facturas: 2,
    tickets: 4,
    complementos: 5,
    notasDeCredito: 9,
    bannerClaro: 10,
    bannerOscuro: 11,
  };

  const formData = new FormData();

  /**
   * @type {import("./typesAssociateFilesV2").AssociateFilesParam}
   */
  const informationUpload = {
    executive: executive,
    idRegister,
    pathUpload,
    idTypeEntity: entityIds[entity],
    files: files.map((file) => getFileOverview(file)),
  };

  formData.append("data", JSON.stringify(informationUpload));

  files.forEach((file, i) => formData.append(`${i}`, file));

  try {
    await axios.post(`${URL_BASE}archivos/v2/asociados`, formData, {
      withCredentials: true,
    });

    Success(() => {}, "Archivos subidos correctamente");

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Get the banners of the app
 * @returns {Promise<import("./typesAssociateFilesV2").BannerApp>}
 */
export async function getAppBanners() {
  try {
    const res = await fetch(`${URL_BASE}setup/banner`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    /**
     * @type {import("../../../../server/types/azureBlobStorage").BannerAppClient}
     */
    const dataBanners = await res.json();

    const lightMode =
      typeof dataBanners.lightMode === "string"
        ? base64ToFileV2(dataBanners.lightMode,'Logo modo claro')
        : null;
    const darkMode =
      typeof dataBanners.darkMode === "string"
        ? base64ToFileV2(dataBanners.darkMode,'Logo modo oscuro')
        : null;

    return {
      darkMode,
      lightMode,
    };
  } catch (error) {
    return {
      darkMode: null,
      lightMode: null,
    };
  }
}

/**
 * Generate the overview information of the file instance to have more details about it
 * @param {File} file - File instance readed
 * @returns {import("./typesAssociateFilesV2").FileOverview}
 */
export function getFileOverview(file) {
  return {
    extension: getExtensionFileName(file.name).extension,
    mimeType: file.type,
    fileName: file.name,
    urlFile: null,
    uuidFileName: window.crypto.randomUUID(),
    size: {
      long: byte.default(file.size, {
        precision: 2,
      }).long,
      unit: byte.default(file.size, {
        precision: 2,
      }).unit,
      value: byte.default(file.size, {
        precision: 2,
      }).value,
    },
  };
}
