import React, { useRef } from "react";
import { useContext } from "react";
import { DirectoryContext } from "..";
import style from "../styles.module.scss";
import MoreInfoDirectory from "structure/MoreInfoDirectory";
import Table from "components/general/Table";

/**
 * Render table for the directory
 * @param {import("react-data-table-component").TableProps<import("helpers/Apis/typesDirectory").Directory>} props
 */
function TableDirectoryParsed(props) {
  return <Table {...props} />;
}

export default function TableDirectory() {
  const hook = useContext(DirectoryContext);

  const key = useRef(window.crypto.randomUUID());

  return (
    <div className={style.tableDirectory}>
      {/* {hook.directory.map((item, i) => (
        <CardItemMobile {...item} key={`${key.current}-${i}-mobile`} />
      ))} */}
      <TableDirectoryParsed
        progressPending={hook.isLoading}
        paginationServer={true}
        customStyles={{
          head: {
            style: { textAlign: "center", fontWeight: "bold" },
          },
        }}
        data={hook.directory}
        columns={[
          {
            name: "ID",
            selector:(data)=><MoreInfoDirectory idCustomer={data.customerID}>{data.customerID}</MoreInfoDirectory>,
            maxWidth: "5%",
          },
          {
            name: "Razón social",
            selector: (data) => data.Razon_social,
          },
          {
            name: "Nombre comercial",
            selector: (data) => data.Nombre_comercial,
          },
          {
            name: "Nombre corto",
            selector: (data) => data.Nombre_corto,
          },
          {
            name: "Ejecutivo",
            selector: (data) => data.Ejecutivo_Abreviado,
          },
          {
            name: "Tipo",
            selector: (data) => data.Tipo_cliente,
          },
          {
            name: "RFC",
            selector: (data) => data.RFC,
          },
          {
            name: "Estatus",
            selector: (data) => data.Estatus_Descripcion,
          },
        ]}
        onChangePage={hook.setPage}
        selectableRowsSingle
        onRowClicked={(data) => hook.setDirectoryItem(data)}
        selectableRowSelected={(ticket) =>
          ticket.customerID === hook.selected?.customerID
        }
        paginationTotalRows={hook.pages * 10}
      />
    </div>
  );
}
