import React, { createContext, useContext, useRef } from "react";
import ui from "./styles.module.scss";
import ReactQuill from "react-quill";
import VariableButton from "./VariableButton";
import VariablesPaymentReminders from "./VariablesPaymentReminders";

/**
 * @type {import("react").Context<import("./types").ContextCommentBox|undefined>}
 */
const ContextCommentBox = createContext(undefined);

/**
 * Render a markdown text box
 * @param {import('./types').PropsCommetBox} props - Props
 * @returns {JSX.Element}
 */
export default function CommentBox({
  onChange = () => {},
  value = "",
  id = null,
  type = null,
}) {
  if (type === null && id === null)
    return (
      <>
        <div className={ui.boxComment}>
          <ReactQuill
            preserveWhitespace
            className={ui.quill}
            placeholder="Escribe aquí"
            value={value}
            onChange={onChange}
          />
        </div>
      </>
    );

  return (
    <ContextCommentBox.Provider value={{ type }}>
      <div className={ui.boxComment}>
        <EditToolbar />
        <ReactQuill
          preserveWhitespace
          className={ui.quill}
          placeholder="Escribe aquí"
          value={value}
          onChange={onChange}
        />
      </div>
    </ContextCommentBox.Provider>
  );
}

function EditToolbar() {
  const hook = useContext(ContextCommentBox);

  if (hook.type === "recordatorioContratos")
    return (
      <div className={ui.variables}>
        <VariableButton variableContent='{{var:folio:""}}' name="Folio" />
        <VariableButton variableContent='{{var:clave:""}}' name="Clave contrato" />
        <VariableButton variableContent='{{var:fechaVencimiento:""}}' name="Fecha de vencimiento" />
        <VariableButton variableContent='{{var:fechaInicio:""}}' name="Fecha de inicio" />
        <VariableButton variableContent='{{var:ejecutivoNombre:""}}' name="Nombre ejecutivo" />
        <VariableButton variableContent='{{var:partidas:""}}' name="Partidas" />
        <VariableButton variableContent='{{var:duracionMeses:""}}' name="Duración (meses)" />
      </div>
    );

  if (hook.type === "facturaEmitida")
    return (
      <div className={ui.variables}>
        <VariableButton
          variableContent='{{var:facturaEmitida:""}}'
          name="Factura emitida"
        />
      </div>
    );

  if (hook.type === "recordatorioPagos1" || hook.type === "recordatorioPagos2")
    return (
      <div className={ui.variables}>
        <VariablesPaymentReminders />
      </div>
    );

  if (hook.type === "recordatorioPagos3" || hook.type === "recordatorioPagos4")
    return (
      <div className={ui.variables}>
        <VariablesPaymentReminders />
        <VariableButton
          name="Días de venc."
          variableContent='{{var:diasVencimiento:""}}'
        />
      </div>
    );

  if (hook.type === "complemento")
    return (
      <div className={ui.variables}>
        <VariableButton variableContent='{{var:contacto:""}}' name="Contacto" />
        <VariableButton
          variableContent='{{var:movimientoDeposito:""}}'
          name="Deposito"
        />
        <VariableButton
          variableContent='{{var:complementoAsociaciones:""}}'
          name="Complemento"
        />
      </div>
    );

  return <></>;
}
