import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ContextInvoiceReception } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ExcelInvoiceReception() {
  const hook = useContext(ContextInvoiceReception);

  if (hook.isDownloadingXlsx)
    return (
      <div className="mx-2">
        <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <ButtonV2 type="button" onClick={hook.attemptDownloadXlsx}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
