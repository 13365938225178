import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import useStartTicket from "./useStartTicket";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import ui from "./styles.module.scss";

/**
 * Start a Ticket
 * @param {import("./types").propsStartTicket} props
 * @returns {JSX.Element}
 */
export const StartTicket = ({
  idTicket,
  idEngineer = null,
  startedAt = null,
  onStartedTicket = () => {},
  children = <></>,
}) => {
  const { canStartTicket, isLoading, attemptStartTicket } = useStartTicket(
    idTicket,
    idEngineer,
    startedAt,
    onStartedTicket
  );

  const dialog = useDialog();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) dialog.open();
  }, [isOpen]);

  if (!canStartTicket) return <></>;

  if (isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Iniciando ticket</LoadingText>}
      />
    );

  return (
    <Fragment>
      <ButtonV2 onClick={() => setIsOpen(true)}>
        <span>Por revisar</span>
      </ButtonV2>

      {isOpen ? (
        <Dialog
          closeCallback={() => setIsOpen(false)}
          forwardRef={dialog.refDialog}
          width={30}
          title="Revisión ticket"
          footer={
            <div className={ui.options}>
              <ButtonV2 onClick={attemptStartTicket}>
                <span>Si</span>
              </ButtonV2>

              <ButtonV2 onClick={() => setIsOpen(false)} theme="danger">
                <span>No</span>
              </ButtonV2>
            </div>
          }
        >
          <Fragment>
            <h2 className="my-5 text-center">¿Deseas mandar a revisión?</h2>
          </Fragment>
        </Dialog>
      ) : null}
    </Fragment>
  );
};
