import React, { useRef } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { useContext } from "react";
import { AssociateFilesContext } from "structure/AssociateFiles";
import LoadingText from "components/individual/TextLoading";
import Dialog from "structure/Dialog";
import style from "../../styles.module.scss";
import Icon from "components/individual/HeaderIcon";

export default function DeleteFile() {
  const keyReact = useRef(window.crypto.randomUUID());

  const {
    isDeleting,
    deleteFiles,
    isDownloading,
    checked,
    numberOfFilesChecked,
    isDeletable,

    dialogDelete,
  } = useContext(AssociateFilesContext);

  if (isDeleting)
    return (
      <div>
        <Spinner hidden={false} text={<LoadingText>Borrando</LoadingText>} />
      </div>
    );

  if (numberOfFilesChecked <= 0) return <></>;

  return (
    <>
      <div>
        <ButtonV2
          disabled={!isDeletable || isDownloading}
          onClick={dialogDelete.open}
        >
          <span>Eliminar</span>
          <Icon icon="trash" />
        </ButtonV2>
      </div>

      <Dialog
        width={30}
        closeCallback={dialogDelete.close}
        forwardRef={dialogDelete.refDialog}
        title="Eliminar archivos"
        footer={
          <div className={style.optionsDelete}>
            {isDeleting ? (
              <Spinner text={<LoadingText>Eliminando</LoadingText>} />
            ) : (
              <>
                <ButtonV2 type="button" onClick={deleteFiles}>
                  Si, eliminar
                </ButtonV2>
                <ButtonV2 onClick={dialogDelete.close} theme="danger">
                  No, cancelar
                </ButtonV2>
              </>
            )}
          </div>
        }
      >
        <>
          <p className="text-center my-3">
            ¿Estas seguro de eliminar los siguientes archivos?
          </p>
          {Object.entries(checked).map(([key, file], i) => (
            <p key={`${keyReact.current}-${i}`}>
              {i + 1} - {file.fileName}
            </p>
          ))}
        </>
      </Dialog>
    </>
  );
}
