import React, { createContext } from "react";
import AddRol from "./AddRol";
import EditRol from "./EditRol";
import SearchRol from "./Search";
import useRol, { ROLS_CONTEXT_VALUE } from "customHooks/useRols";
import "./Roles.scss";
import Table from "./Table";
import usePermissions from "customHooks/usePermissions";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";

export const ContextRols = createContext(ROLS_CONTEXT_VALUE);

export default function Roles() {
  const hook = useRol();

  usePermissions("9b9d9d4c-e87c-4516-a971-f5251493c62d");

  return (
    <div className="containerRols">
      <ContextRols.Provider value={hook}>
        <div className="headerRols">
          <BreadCrumV2 path={hook.pathBreadcrum} />

          <div className="contentRols">
            <div className="searchRol">
              <SearchRol />
              <AddRol onAdded={hook.triggerSearch} />
            </div>
          </div>
        </div>

        <div className="contentRols">
          <div id="tableOptions" className="spacingOptions my-3">
            {hook.rol === null ? null : (
              <EditRol onEdited={hook.triggerSearch} idRol={hook.rol.rolID} />
            )}
          </div>

          <Table />
        </div>
      </ContextRols.Provider>
    </div>
  );
}