import React, { useContext } from "react";
import { ContextContact } from "..";
import { CustomerOverviewModal } from "components/general/CustomerOverviewModal";
import style from "../styles.module.scss";

export default function MoreInfoCustomer() {
  const hook = useContext(ContextContact);

  if (hook.customer === undefined) return <></>;

  return (
    <p className="my-3 d-flex">
      <b>{hook.customer.type.description}</b>:{" "}
      <CustomerOverviewModal idCustomer={hook.customer.id}>
        <span className={style.moreInfoCustomer}>
          {hook.customer.socialReason}
        </span>
      </CustomerOverviewModal>
    </p>
  );
}
