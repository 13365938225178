import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextDocuments } from "../..";
import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function ComboStatus() {
  const hook = useContext(ContextDocuments);

  if (hook.isLoadingStatus)
    return (
      <div>
        <Label>Estatus</Label>
        <Spinner text={"Cargando"} hidden={false} />
      </div>
    );

  return (
    <div>
      <Label>Estatus</Label>
      <Select
        labelToDisplay="label"
        defaultSelected={{
          keyToCompare: "value",
          value: hook.status?.value||null,
        }}
        options={async () => hook.comboStatus}
        onChange={hook.setStatus}
      />
    </div>
  );
}
