import { Spinner } from "components/individual/Spinner/Spinner";
import { getDirectory } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import Select from "react-select";

/**
 * Select with all the customers registered on the directory
 * @param {import("./types").PropsSocialReason} props - Props component
 * @returns {JSX.Element} Select with all the customers registered on the directory
 */
export default function SocialReason({
  aditionalOptions = [],
  type,
  includeNoActives = false,
  onChange = () => {},
  value = null,
  isClearable = false
}) {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    (async function () {
      const apiCustomers = await getDirectory(type, includeNoActives);

      const parsedApiCustomers = [
        ...aditionalOptions,
        ...apiCustomers.map((customer) => ({
          ...customer,
          value: customer.id,
          label: type !== "recepcionFactura" ? customer.socialReason : `${customer.rfc} - ${customer.socialReason}`,
        })),
      ];

      const customerToUse = parsedApiCustomers.find(
        (customer) => customer.id === value
      );

      setCustomers(parsedApiCustomers);
      setCustomer(customerToUse);
      setIsLoading(false);
    })();
  }, []);

  /**
   * Handle when the user changes the social reason of the document
   * @param {import("./types").ComboDirectoryDtoI} customer - Information of the customer selected
   
   */
  const handleOnChange = (customer) => {
    setCustomer(customer);

    if (customer !== null) {
      onChange({
        id: customer.id,
        isActive: customer.isActive,
        socialReason: customer.socialReason,
        rfc: customer.rfc,
        creditDays:customer.creditDays
      });
    } else {
      onChange({
        id: null,
        isActive: null,
        socialReason: null,
        rfc: null,
        creditDays:null
      });
    }
  };

  return isLoading ? (
    <Spinner hidden={false} text="Cargando" />
  ) : (
    <Select
      className="saiko-select"
      classNamePrefix="saiko-select"
      placeholder="Selecciona una razon social"
      isClearable={isClearable}
      isSearchable={true}
      onChange={handleOnChange}
      options={customers}
      value={customer}
    />
  );
}
