import { getPropousalPdf, getPropousals } from "helpers/Apis/proyects";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").StateUsePropousal}
 */
const INITIAL_STATE = {
  state: "loading",
  refetch: false,
  data: [],
  noRecordsFound: 0,
  pages: 0,
  page: 1,
  orderAsc: false,
  rowsPerPage: 100,
  propousal: null,
  statusPdf: "none",
};

/**
 * Handle the propousals of a proyect
 * @param {number} idProyect - Id of proyect in order to handle the propusals related
 * @returns {import("./types").ReturnUsePropousal}
 */
export default function usePropousal(idProyect) {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("./types").BreadcrumType}
   */
  const breadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/proyectos",
      text: "Proyectos",
    },
    {
      route: "/proyectos",
      text: "Propuesta",
    },
  ]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        state: "loading",
        propousal: null,
      }));

      const data = await getPropousals({
        page: state.page,
        limit: state.rowsPerPage,
        orderAsc: state.orderAsc,
        idProyect
      });

      setState((current) => ({
        ...current,
        state: "none",
        ...data,
      }));
    })();
  }, [state.refetch, state.page]);

  const handlePdf = async () => {
    setState((current) => ({ ...current, statusPdf: "loading" }));
    await getPropousalPdf(state.propousal.id);
    setState((current) => ({ ...current, statusPdf: "none" }));
  };

  const refetchTable = () =>
    setState((current) => ({
      ...current,
      page: 1,
      refetch: !current.refetch,
    }));

  const setPropousal = (propousal) =>
    setState((current) => ({ ...current, propousal }));

  return {
    ...state,
    refetchTable,
    breadcrum,
    setPropousal,
    handlePdf,
  };
}
