import MoreInfo from "components/individual/MoreInfo";
import Select from "components/individual/SelectV4";
import React, { useRef , useState } from "react";
import { Label } from "structure/Document";

/**
 * Render a unit select combo
 * @param {import("./types").PropsPowerOfTen} props - Props
 * @returns {JSX.Element}
 */
export default function PowerOfTen({
  required = false,
  onChange = () => {},
  currency = undefined,
  value = null
}) {
  const [option, setOption] = useState(undefined);

  const options = useRef([
    {
      label: "-- SELECCIONA --",
      power: null,
      disabled: true,
    },
    {
      label: "Unitario",
      power: 0,
    },
    {
      label: "Cientos",
      power: 2,
    },
    {
      label: "Miles",
      power: 3,
    },
    {
      label: "Millones",
      power: 6,
    },
  ]);

  return (
    <div>
      <div className="d-flex aling-items-center">
        <Label required={required}>Unidad</Label>
        {option !== undefined ? (
          <MoreInfo title="Unidades" width={20}>
            <p className="m-0 text-center">
              1 {option?.label} ={" "}
              {Math.pow(10, option?.power || 0).toLocaleString()}{" "}
              {typeof currency !== "string" ? "unidades" : currency}
            </p>
          </MoreInfo>
        ) : null}
      </div>
      <Select
        labelToDisplay="label"
        options={async () => options.current}
        onChange={(value) => {
          onChange(value.power);
          setOption(value);
        }}
        defaultSelected={{
          keyToCompare: "power",
          value: value,
        }}
      />
    </div>
  );
}
