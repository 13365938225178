import React from "react";
import { useContext } from "react";
import { ContextUenContact } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { useRef } from "react";

export default function GridUen() {
  const hook = useContext(ContextUenContact);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoadingUens)
    return (
      <Spinner text={<LoadingText>Cargando UENs</LoadingText>} hidden={false} />
    );

  if (hook.isLoadingUensContact)
    return (
      <Spinner
        text={<LoadingText>Cargando contacto</LoadingText>}
        hidden={false}
      />
    );

  return (
    <div>
      <div className={style.header}>
        <p>Familia</p>
        <p>Sub-familia</p>
        <p>Descripción</p>
        <p>Asociado</p>
        <p>{hook.displayBlockColumn ? "Bloqueada" : "-"}</p>
      </div>

      <div className={style.containerRows}>
        {hook.uens.map((uen, i) => (
          <div key={`${key.current}-${i}`} className={style.row}>
            <p>{uen.family}</p>
            <p>{uen.subFamily}</p>
            <p>{uen.description}</p>
            <label htmlFor={`${key.current}-${i}-contact`}>
              <input
                checked={hook.uensContact[uen.UENID] !== undefined}
                onChange={(e) => hook.handleContactCheckbox(uen)}
                type="checkbox"
                id={`${key.current}-${i}-contact`}
              />
            </label>

            <label htmlFor={`email-${uen.UENID}`}>
              {hook.uensContact[uen.UENID] === undefined ? (
                <span>-</span>
              ) : hook.displayBlockColumn ? (
                <input
                  checked={hook.uensContact[uen.UENID].email}
                  onChange={(e) => hook.handleEmailCheckbox(uen.UENID)}
                  type="checkbox"
                  id={`email-${uen.UENID}`}
                />
              ) : (
                <span>-</span>
              )}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
