import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import useFeeYears from "customHooks/useFeeYears";
import React, { useContext, useEffect } from "react";
import { Label } from "structure/Document";
import { ContextAbcFee } from "..";

export default function Year() {
  const hook = useFeeYears({ onChange: () => {}, refetch: false });

  const fee = useContext(ContextAbcFee);

  useEffect(() => {
    fee.setYears(
      hook.years
        .map((year) => year.value)
        .filter((value) => typeof value === "number")
    );
  }, [hook.years]);

  if (hook.isLoading)
    return (
      <div className="d-flex flex-column">
        <Label>Año</Label>
        <Spinner hidden={false} text={<LoadingText>Cargando</LoadingText>} />
      </div>
    );

  return (
    <div className="d-flex flex-column">
      <Label>Año</Label>
      <input
        type="number"
        placeholder="Escribe aquí"
        min={0}
        value={fee.year >= 1 ? fee.year : ""}
        onChange={(e) => {
          fee.setYear(Math.floor(+e.target.value));
        }}
      />
    </div>
  );
}
