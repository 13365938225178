import { getAdvertisements } from "helpers/Apis/Advertisements";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateAdvertisements}
 */
const INITIAL_STATE = {
  isLoading: true,
  advertisements: [],
  advertisement: null,
  page: 1,
  pages: 0,
  search: "",
  refetchFlag: false,
};

/**
 * Hook to handle the advertisements of the system
 * @param {string} [search=""] Search for the text box
 * @returns {import("./types").ReturnUseAdvertisement}
 */
export default function useAdvertisements(search = "") {
  const [state, setState] = useState(INITIAL_STATE);

  // const dialo

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        advertisement: null,
        advertisements: [],
      }));

      const advertisements = await getAdvertisements(state.page, state.search);

      setState((current) => ({
        ...current,
        isLoading: false,
        advertisements: advertisements.advertisements,
        page: advertisements.page,
        pages: advertisements.pages,
      }));
    })();
  }, [state.refetchFlag, state.page]);

  const setAdvertisement = (advertisement) =>
    setState((current) => ({
      ...current,
      advertisement,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const triggerFetchSearch = () => setState(current=>({
    ...current,
    refetchFlag:!current.refetchFlag,
    page:1
  }));

  /**
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/avisos",
      text: "Admn. del Sistema",
    },
    {
      route: "/avisos",
      text: "Avisos",
    },
  ];

  return {
    ...state,
    setAdvertisement,
    setPage,
    setSearch,
    breadcrum,
    triggerFetchSearch
  };
}
