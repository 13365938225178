import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { AddReceptionInvoiceContext } from "../../Filter";

export default function BtnContinue() {
  const { setStep, canContinueNonFiscalInvoice } = useContext(
    AddReceptionInvoiceContext
  );

  return !canContinueNonFiscalInvoice ? null : (
    <ButtonV2 onClick={() => setStep(1)}>Continuar</ButtonV2>
  );
}
