import LoginContext from "context/Login/LoginContext";
import { YesNoAlert } from "helpers/alerts";
import { useEffect, useState, useContext } from "react";

import {
  disableNotifications,
  getSubscription,
  resumeNotifications,
  test,
} from "sw-register";

export default function usePushNotification() {
  const { userInfo } = useContext(LoginContext);

  const [areActive, setAreActive] = useState(false);
  const [displayTutorial, setDisplayTutorial] = useState(false);
  const [displayTurnOff, setDisplayTurnOff] = useState(false);
  const [displayActive, setDisplayActive] = useState(false);
  const [isDisablingNotifications, setIsDisablingNotifications] =
    useState(false);
  const [isActivating, setIsActivating] = useState(false);

  const closeTutorial = () => setDisplayTutorial(false);
  const closeTurnNotifications = () => setDisplayActive(false);
  const closeTurnOff = () => setDisplayTurnOff(false);

  useEffect(() => {
    (async function () {
      await enableNotificationsFlow();
    })();
  }, [userInfo]);

  const handleToggleNotification = async () => {
    if (!areActive) {
      const areNotificationAllowed = checkNotificationBrowserPermissions();

      // User has notifications allowed, but he disabled the notifications alerts
      if (areNotificationAllowed) {
        setDisplayActive(true);
        return;
      }

      // Browser notifications are not allowed
      if (!areNotificationAllowed) {
        setDisplayTutorial(true);
        return;
      }

      const wasSuccess = await activateNotifications();
      setAreActive(wasSuccess);

      return;
    }

    setDisplayTurnOff(true);
  };

  async function enableNotificationsFlow() {
    // User session haven't load yet
    if (userInfo.length <= 0) return;

    // User disabled push notifications
    if (!userInfo[0].pushNotificationsAllowed) return;

    const areNotificationAllowed = checkNotificationBrowserPermissions();

    // Browser notifications are not allowed
    if (!areNotificationAllowed) {
      // setDisplayTutorial(true);
      return;
    }

    // User cleaned cookies or cachce, create new subscription

    const pushNotificationFlag = localStorage.getItem("pushNotifications");

    if (pushNotificationFlag === null) {
      const wasSubscribed = await getSubscription();
      setAreActive(wasSubscribed);
      return;
    }

    setAreActive(true);
  }

  /**
   * Activate the notifications of the app
   * @returns {Promise<boolean>} True if the user triggered the notifications with success
   */
  async function activateNotifications() {
    const wasSubscribed = await getSubscription();

    if (!wasSubscribed) return false;

    const wasTestSuccess = await test();

    if (!wasTestSuccess) return false;

    return true;
  }

  /**
   * Check if the notifications of the browser are allowed for the app
   * @returns {boolean}
   */
  function checkNotificationBrowserPermissions() {
    const statePermission = Notification.permission;

    if (statePermission === "granted") return true;

    return false;
  }

  /**
   * If true, user clicked to allow notifications
   * @returns {Promise<boolean>}
   */
  async function requestAllowNotifications() {
    const response = await Notification.requestPermission();

    if (response === "granted") {
      return true;
    }

    return false;
  }

  async function attemptDisableNotifications() {
    setIsDisablingNotifications(true);

    const wasDisabled = await disableNotifications();

    if (wasDisabled) {
      setDisplayTurnOff(false);
      setAreActive(false);
    }

    setIsDisablingNotifications(false);
  }

  async function attemptResumeNotifications() {
    setIsActivating(true);

    const wasResumed = await resumeNotifications();

    if (wasResumed) {
      setAreActive(true);
      setDisplayActive(false);
    }

    setIsActivating(false);
  }

  return {
    handleToggleNotification,
    areActive,
    displayTutorial,
    displayTurnOff,
    displayActive,
    closeTutorial,
    closeTurnNotifications,
    closeTurnOff,
    attemptDisableNotifications,
    attemptResumeNotifications,
    isDisablingNotifications,
    isActivating,
  };
}
