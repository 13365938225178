import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";
import { calculatePercentage, mnCurrency } from "helpers/money";
import style from '../styles.module.scss';

export default function Total() {
  const hook = useContext(ContextEmitCreditNote);

  return (
    <div>
      <Label required={hook.importe <= 0}>Total</Label>
      {hook.importe <= 0 ? (
        <p>Primero escribe un importe</p>
      ) : (
        <div>
          <p>{mnCurrency(hook.total)}</p>

            <Label>Impuestos</Label>
            {hook.invoice.invoiceTaxas.map((taxa, i) => (
              <p key={`taxa-${i + 1}`}>
                {i+1}. {taxa.description} {taxa.percentage}% ={" "}
                {mnCurrency(
                  calculatePercentage(hook.importe, taxa.percentage)
                )}
              </p>
            ))}
          
        </div>
      )}
    </div>
  );
}
