import React, { useContext, useEffect, useState } from "react";

// COMPONENTS
import {
  InputNumber,
  InputText,
  InputCurrency,
  Select,
  ErrorMessage,
} from "../../../../../components/individual/inputs/Inputs";
import UEN from "../../../../../components/general/Forms/Selects/UEN";
import Currency from "../../../../../components/general/Forms/Selects/Currency";

import {
  ModalDismissButton,
  Submit,
} from "../../../../../components/individual/buttons/Buttons";

// FORM
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addGameValidations } from "../../../../../Schemas/Directory/AddDocument/AddGame";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";
import LoginContext from "../../../../../context/Login/LoginContext";

// CUSTOM HOOKS
import useAddDocument from "../../../../../customHooks/useAddDocuments";

// HELPERS
import { parseToFloat, truncateDecimals } from "../../../../../helpers/money";
// import { MaintenancePrices } from '../../../../../helpers/alerts';

function Modal() {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addGameValidations),
  });

  const {
    infoGame,
    setIsNewGame,
    ivas,
    isNewGame,
    setUenList,
    ivasNewGame,
    shopItemCart,
    setShopItemCart,
    exchangeCurrency,
    games,
  } = useContext(AddDocumentContext);

  const {
    setInputsGame,
    addFromCatalogue,
    cancelAddProduct,
    setInputsAdd,
    setInputsNewProduct,
    findInfoUen,
    setIva,
    setIvasNewGame,
    createGame,
    updateItem,
    unitCostChanged,
    setInputsEdit,
    unitPriceChanged,
    promptUpdateCost,
    promptUpdatePrice,
    discriminatePricesChanged,
    promptUpdatePrices,
    updateLabelsPrices,
    changeCurrency,
    isValidCost,
  } = useAddDocument();

  const { userInfo } = useContext(LoginContext);

  const [unitPrice, setUnitPrice] = useState(0);
  const [unitCost, setUnitCost] = useState(0);

  const addToCart = (value) => {
    if (!isValidCost(value.unitCost, value.unitPrice)) return;

    const btnClose = document.getElementById("cancelAddProduct");
    let itemsParsed;

    // User it's editing an item from the shop cart
    if (shopItemCart !== null) {
      itemsParsed = updateItem(value);
      setShopItemCart(null);
    }

    // User it's adding to shop cart
    else {
      // Add item to shop cart from catalogue
      if (!isNewGame) {
        itemsParsed = addFromCatalogue();
      }

      // Create item to catalogue and add it to catalogue
      else {
        itemsParsed = createGame(value);
      }
    }

    // Close modal
    btnClose.click();
    setIsNewGame(false);

    /**
     * @type {InfoToUpdate}
     */
    let infoToUpdate = {
      id: infoGame?.idCatalogue || infoGame.id,
      idDocItem: infoGame?.idFront || false,
      unitCost: truncateDecimals(parseToFloat(value.unitCost), 4),
      unitPrice: truncateDecimals(parseToFloat(value.unitPrice), 4),
      editedBy: userInfo[0].fullName,
    };

    /**
     * @type {DiscriminatedPrices|null}
     */

    const options = discriminatePricesChanged(
      value.unitCost,
      value.unitPrice,
      infoToUpdate,
      itemsParsed
    );

    if (options !== null)
      promptUpdatePrices(options.options, infoToUpdate, options.isInMemory);

    setUnitCost(0);
    setUnitPrice(0);
  };

  useEffect(() => {
    // User canceled the insertion
    if (infoGame === null) return;

    updateLabelsPrices();

    // Evaluate if user attempts to create a new product/service on catalogue
    const mustAdd = Object.keys(infoGame).includes("__isNew__");
    setIsNewGame(mustAdd);

    if (!mustAdd) {
      // Set the information on inputs
      setInputsGame();
      setUnitPrice(infoGame.unitPrice);
      setUnitCost(infoGame.sellPrice);
    } else {
      // Must be added to the catalogue
      setInputsAdd();
      setUnitPrice(0);
      setUnitCost(0);
    }

    if (shopItemCart !== null) {
      setInputsEdit(shopItemCart);
      setUnitPrice(infoGame.beforeExchange.price);
      setUnitCost(infoGame.beforeExchange.sellPrice);
    }

    // Executes when user select or add a product/service on the input
  }, [infoGame]);

  return (
    <>
      <div
        className="modal fade"
        id="infoProduct"
        tabIndex="-1"
        aria-labelledby="infoProduct"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal50">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Catalogo
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              className="modal-body"
              id="formAddToCart"
              onSubmit={handleSubmit(addToCart)}
            >
              <div className="d-flex justify-content-between">
                <div className="w-20">
                  <InputText
                    htmlFor="codeGame"
                    id="codeGame"
                    name="codeGame"
                    placeholderTxt="Escribe aqui"
                    labelTxt="Clave"
                    forwardRef={register}
                    aditionalClasses="d-inline-block mr-4"
                  />
                  <ErrorMessage message={errors["codeGame"]?.message} />
                </div>

                <InputText
                  htmlFor="description"
                  id="description"
                  name="description"
                  placeholderTxt="Descripcion del articulo/servicio"
                  labelTxt="Descripcion"
                  disabled={true}
                  forwardRef={{}}
                  maxLength={100}
                  aditionalClasses="w-90 d-inline-block"
                />
              </div>

              <div className="d-flex justify-content-between mt-4">
                <div className="w-20">
                  <InputNumber
                    decimal={false}
                    htmlFor="quantity"
                    id="quantity"
                    name="quantity"
                    labelTxt="Cantidad *"
                    placeholderTxt="Escribe aqui"
                    forwardRef={register}
                  />
                  <ErrorMessage message={errors["quantity"]?.message} />
                </div>

                <div className="w-20">
                  <InputCurrency
                    forwardRef={register}
                    htmlfor="unitPrice"
                    id="unitPrice"
                    name="unitPrice"
                    labelTxt="Precio unitario *"
                    labelId="lblUnitPrice"
                    placeholder="Escribe aqui"
                    defaultValue={unitPrice}
                  />
                  <ErrorMessage message={errors["unitPrice"]?.message} />
                </div>

                <div className="w-20">
                  <InputNumber
                    decimal={true}
                    htmlFor="discount"
                    id="discount"
                    name="discount"
                    placeholderTxt="Escribe aqui"
                    disabled={false}
                    labelTxt="Descuento *"
                    placeholder="Escribe aqui"
                    forwardRef={register}
                  />

                  <ErrorMessage message={errors["discount"]?.message} />
                </div>

                <div className="w-20">
                  <InputCurrency
                    forwardRef={register}
                    id="unitCost"
                    name="unitCost"
                    labelTxt="Costo unitario *"
                    labelId="lblUnitCost"
                    placeholder="Escribe aqui"
                    defaultValue={unitCost}
                  />
                  <ErrorMessage
                    id="unitCostError"
                    message={errors["unitCost"]?.message}
                  />
                </div>

                {isNewGame ? (
                  <div className="w-15">
                    <Currency
                      id="currency"
                      name="currency"
                      forwardRef={register}
                      label="Moneda *"
                    />

                    <ErrorMessage message={errors["currency"]?.message} />
                  </div>
                ) : null}
              </div>

              <div className="d-flex mt-4">
                {isNewGame ? (
                  <UEN
                    css="w-75"
                    uenList={(list) => {
                      setUenList(list);
                      setInputsNewProduct(list[0].SATcode, list[0].SATUM);
                      setIva(list[0].iva);
                    }}
                    onChange={(id) => {
                      const { SATcode, SATUM, iva } = findInfoUen(+id);
                      setInputsNewProduct(SATcode, SATUM);
                      const baseIvas = [...ivasNewGame];

                      const uenIva = {
                        percentage: iva,
                        percentage_text: `${iva}%`,
                      };

                      baseIvas.unshift(uenIva);

                      setIvasNewGame(baseIvas);
                    }}
                  />
                ) : (
                  <InputText
                    forwardRef={{}}
                    htmlFor="uen"
                    id="uen"
                    name="uen"
                    labelTxt="UEN"
                    placeholderTxt="No editable"
                    disabled={true}
                    aditionalClasses="w-75 mx-2"
                  />
                )}

                <Select
                  addTodos={false}
                  idSelect="iva"
                  nameSelect="iva"
                  label="IVA"
                  options={ivas}
                  textJSON="percentage_text"
                  valueJSON="percentage"
                  className="w-25 mx-2"
                />
              </div>

              <div className="mt-4 d-flex justify-content-around">
                <div>
                  <InputText
                    forwardRef={register}
                    id="satCode"
                    htmlFor="satCode"
                    name="satCode"
                    labelTxt="Clave SAT"
                    placeholderTxt="Escribe aqui"
                    disabled={false}
                    aditionalClasses="w-100 mx-2"
                  />
                  <ErrorMessage message={errors["satCode"]?.message} />
                </div>

                <div>
                  <InputText
                    forwardRef={register}
                    htmlFor="umSat"
                    id="umSat"
                    name="umSat"
                    labelTxt="UM SAT"
                    placeholderTxt="Escribe aqui"
                    aditionalClasses="w-100 mx-2"
                  />
                  <ErrorMessage message={errors["umSat"]?.message} />
                </div>
              </div>

              <div className="modal-footer mt-3 d-flex justify-content-around">
                <ModalDismissButton
                  idBtn="cancelAddProduct"
                  text="Cancelar"
                  aditionalClasses="w-40"
                  callback={() => cancelAddProduct()}
                />

                <Submit
                  text="Agregar"
                  aditionalClasses="w-40"
                  id="addProduct"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
