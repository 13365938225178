import React from "react";
import { Label } from "structure/Document";

/**
 * Render a informative label from which email will be sended the media
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 * @returns {JSX.Element}
 */
export const From = (props) => {
  return (
    <div>
      <Label>From</Label>
      <p>{process.env.REACT_APP_EMAIL_FROM}</p>
    </div>
  );
};
