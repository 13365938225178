import React from "react";
import { RedirectButton } from "components/individual/buttons/Buttons";
import { useContext } from "react";
import { ContextAdvancePayment } from "..";
import Icon from "components/individual/HeaderIcon";

export default function EditAdvance() {
  const hook = useContext(ContextAdvancePayment);

  if (hook.advance === null) return <></>;

  if (hook.movementType === "clientes")
    return (
      <RedirectButton
        text={
          <>
            <span>Editar</span>
            <Icon icon="edit" />
          </>
        }
        link={`/v2/bancos/ingresos/agregar?cuenta=${hook.advance.bankAccount.id}&movimiento=${hook.advance.id}&modulo=anticipo`}
      />
    );

  return (
    <RedirectButton
      text={
        <>
          <span>Editar</span>
          <Icon icon="edit" />
        </>
      }
      link={`/v2/bancos/egresos/agregar?cuenta=${hook.advance.bankAccount.id}&movimiento=${hook.advance.id}&modulo=anticipo`}
    />
  );
}
