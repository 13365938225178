import React, { createContext } from "react";
import Catalogue from "components/general/Forms/Selects/Catalogue";
import Card from "components/individual/CardV2";
import { ReactSortable } from "react-sortablejs";
import styles from "./styles.module.scss";
import usePartidas from "customHooks/usePartidas/usePartidas";
import { Icon } from "components/individual/CardV2/styles";
import { Eye } from "components/individual/CardV2";
import GlobalUtilities from "./GlobalUtilities";
import EditItem from "components/general/Forms/Selects/Catalogue/ModalEditItem";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("react").Context<import("./types").ContextItemsI>}
 */
export const ContextItems = createContext();
const { Provider } = ContextItems;

/**
 * Render the screen to handle the items of a document
 * @param {import("./types").ItemsProps} props - Props
 * @returns {JSX.Element} Structure to render the creation/addition of items into a document
 */
export default function Items({
  currency = null,
  onChange = () => {},
  onChangeOrder = () => {},
  idDocument = null,
  tc,
  calculationMode = "pu",
  typeDocument = "cotizacion",

  canEdit = false,
  canDelete = false,
  informative = false,
}) {
  const {
    items,
    appendIntoShopCart,
    deleteItemFromCart,
    idOpenGlobalUtilities,
    openGlobalUtilities,
    reorderItems,
    itemSelected,
    setItemSelected,
    updateOnShoptCard,
    updateItem,
    idModalEdit,
  } = usePartidas({
    onChange: onChange,
    currency,
    tc,
    idDocument,
    onChangeOrder,
  });

  const { checkPermission } = usePermissions();

  return (
    <Provider
      value={{
        informative,
        canEdit,
        canDelete,
        idModalEdit,
      }}
    >
      {checkPermission("1510fe05-1a69-4312-8237-039d75c39a4e") ? (
        <GlobalUtilities
          idModal={idOpenGlobalUtilities}
          items={items}
          currency={currency}
          tc={tc}
        />
      ) : null}

      {informative === true ? null : (
        <div className="row d-flex align-items-center my-3 position-sticky sticky-top py-2">
          <div className="col-4">
            <Catalogue
              onSelect={appendIntoShopCart}
              predominantCurrency={currency}
              tc={tc}
              typeDocument={typeDocument}
            />
          </div>
          <div className="col-8 d-flex justify-content-end align-items-center">
            {items.length > 0 &&
            checkPermission("1510fe05-1a69-4312-8237-039d75c39a4e") ? (
              <div className="mr-2">
                <Icon onClick={() => openGlobalUtilities()}>{Eye}</Icon>
              </div>
            ) : null}
          </div>
        </div>
      )}

      <EditItem
        idOpenModal={idModalEdit}
        item={itemSelected}
        predominantCurrency={currency}
        onChange={updateItem}
        typeDocument={typeDocument}
      />

      <ReactSortable
        className={styles.containerCards}
        list={items}
        setList={(items) => reorderItems(items)}
      >
        {items.map((item, i) => (
          <Card
            key={`item-${i}`}
            currency={currency}
            item={item}
            onDelete={() => deleteItemFromCart(i)}
            predominantCurrency={currency}
            calculationMode={calculationMode}
            index={i}
            onEdit={() => setItemSelected(i)}
            tc={tc}
            onChange={(itemRecalculated) =>
              updateOnShoptCard(itemRecalculated, i)
            }
          />
        ))}
      </ReactSortable>
    </Provider>
  );
}
