import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { CreditDaysArea } from "./Styles";
import { InputNumber, InputText } from "components/individual/inputs/Inputs";

export default function EditCreditDays({
  value,
  className = "",
  name = "creditDays",
}) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document, register, setCreditDays } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <InputNumber
            id="creditDaysID"
            name={name}
            labelTxt="Dias de credito*"
            forwardRef={register}
            onChange={(creditDays)=>setCreditDays(+creditDays)}
            placeholderTxt="Dias de credito"
            defaultValue={!value ? document.creditDays : value}
            value={!value ? document.creditDays : value}
          />
        </div>
      ) : (
        <CreditDaysArea>
          <InputNumber
            id="creditDaysID"
            name={name}
            labelTxt="Dias de credito*"
            forwardRef={register}
            onChange={(creditDays)=>setCreditDays(+creditDays)}
            placeholderTxt="Dias de credito"

            defaultValue={!value ? document.creditDays : value}
            value={!value ? document.creditDays : value}
          />
        </CreditDaysArea>
      )}
    </>
  );
}
