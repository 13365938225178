import React, { useContext, useRef } from "react";
import { ContextGestionOc } from "../..";
import ui from "./styles.module.scss";
import { parseToFolio } from "helpers/documents";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { parseUtcToText } from "helpers/dates";
import Checkbox from "../Checkbox";
import NotConfirmed from "../NotConfirmed";
import Comment from "../../Comment";
import MoreInfo from "../../MoreInfo";
import SendEmailOc from "../../OptionsContainer/SendEmailOc";

/**
 * Render a oc item for the oc managment
 * @param {import('helpers/Apis/purchases/ocManagment/types').OcManagment} record - Information of the OC
 * @returns {JSX.Element}
 */
export default function Mobile(record) {
  const hook = useContext(ContextGestionOc);

  const key = useRef(`${window.crypto.randomUUID()}`);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const commentDom = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const moreInfoDom = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const emailDom = useRef();

  return (
    <>
      <Comment
        forwaredRef={commentDom}
        id={record.idOdc}
        documentNumber={record.documentNumber}
      >
        <></>
      </Comment>

      <MoreInfo oc={record} forwardRef={moreInfoDom}>
        <></>
      </MoreInfo>

      <SendEmailOc forwardRef={emailDom}>
        <></>
      </SendEmailOc>

      <div className={ui.managmentOc}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <b>No. ODC</b>
            <p>{parseToFolio(record.documentNumber)}</p>
          </div>

          <div className={ui.options}>
            {record.hasComment ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
                  clipRule="evenodd"
                />
              </svg>
            ) : null}

            <ElipsisMobile onShow={() => hook.setRecord(record)}>
              <CustomMenuItem onClick={() => moreInfoDom.current.click()}>
                Más información
              </CustomMenuItem>

              {hook.canSendEmail ? (
                <CustomMenuItem onClick={() => emailDom.current.click()}>
                  Enviar OC
                </CustomMenuItem>
              ) : null}

              <CustomMenuItem onClick={() => commentDom.current.click()}>
                Comentarios
              </CustomMenuItem>
              {hook.canUpdate ? (
                <CustomMenuItem
                  disabled={hook.isUpdating}
                  onClick={hook.updateOcRecords}
                >
                  {hook.isUpdating ? "Actualizando..." : "Actualizar"}
                </CustomMenuItem>
              ) : null}
            </ElipsisMobile>
          </div>
        </div>

        <div>
          <b>Emisión</b>
          <p> {parseUtcToText(record.emitedDate)}</p>
        </div>

        <div>
          <b>Cliente</b>
          <p>{record.client}</p>
        </div>

        <div>
          <b>Proveedor</b>
          <p>{record.supplier}</p>
        </div>

        <div>
          <b>Factura</b>
          <p>{parseUtcToText(record.invoiceDate)}</p>
        </div>

        <div>
          <b>Envío</b>
          <p>{parseUtcToText(record.sendDate)}</p>
        </div>

        <div>
          <b>Confirmación</b>
          <p>{parseUtcToText(record.confirmationDate)}</p>
        </div>

        {hook.headers.slice(7, 10).map((header, i) => (
          <div key={`dynamicHeaderMobile-${key.current}-${i}`}>
            <b>{header}</b>
            {i === 0 ? (
              <p>{parseUtcToText(record.licencesConfirmedDate)}</p>
            ) : null}
            {i === 1 ? (
              <p>{parseUtcToText(record.myContractSingedDate)}</p>
            ) : null}
            {i === 2 ? (
              <p>{parseUtcToText(record.licencesConfirmedDate)}</p>
            ) : null}
          </div>
        ))}

        {hook.canDisplayCheckbox ? (
          <div className={ui.checkBox}>
            <b>{hook.headers.slice(10, 11)[0]}</b>
            <Checkbox {...record} />
          </div>
        ) : null}

        {hook.status !== "notConfirmed" ? null : (
          <div className={ui.confirmation}>
            <p>Require confirmación</p>
            <p>No requiere confirmación</p>
            <NotConfirmed {...record} />
          </div>
        )}
      </div>
    </>
  );
}
