import React from "react";
import { ItemsDescription } from "components/Elements/ItemsDescription";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import {
  BodyShort,
  BodyShortKey,
  ItemsElemntContainer,
  ItemText,
} from "./Containers";

export const BodySection = ({
  sku,
  quantity,
  pu,
  subTotal,
  description,
  currency,
}) => {
  return (
    <ItemsElemntContainer>
      <BodyShortKey>
        <ItemText>{sku}</ItemText>
        <ItemText>
          <strong>{currency}</strong>
        </ItemText>
      </BodyShortKey>
      <ItemsDescription description={<ReactMarkdown rehypePlugins={[rehypeRaw]} children={description} />}>
      

      </ItemsDescription>
      <BodyShort>
        <p>{quantity}</p>
      </BodyShort>
      <BodyShort>
        <p>{pu}</p>
      </BodyShort>
      <BodyShort>
        <p>{subTotal}</p>
      </BodyShort>
    </ItemsElemntContainer>
  );
};
