import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Warning } from "helpers/alerts";
import React from "react";

export default function Pdf() {
  return (
    <div className="mr-3">
      <ButtonV2 onClick={() => Warning("En desarrollo")}>PDF</ButtonV2>
    </div>
  );
}
