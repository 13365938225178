import InformativeTooltip from "components/individual/InfoTooltip";
import React from "react";
import { ContextEgressConcept } from "..";
import { useContext } from "react";

/**
 * Render the error when is an error for "No identificado"
 * @param {import("./types").PropsNoIdentifiedError} props - Props
 * @returns {JSX.Element}
 */
export default function NoIdentifiedError({ id }) {
  const hook = useContext(ContextEgressConcept);

  if (id !== hook.idNoIndentified) return <></>;

  if (!hook.isValidNoIdentified)
    return (
      <div className="d-flex">
        <InformativeTooltip>
          <p className="font-weight-bold">
            Elimina el monto del concepto "No identificado" o el monto de los
            demas conceptos
          </p>
        </InformativeTooltip>
        <p className="text-danger font-weight-bold"> 👈 Inválido</p>
      </div>
    );
}
