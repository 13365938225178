import React from "react";
import PropTypes from "prop-types";

// TYPES
import "../../../types/typedef/components/card";

// LIBS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPen } from "@fortawesome/free-solid-svg-icons";

/**
 * Insert a SINGLE CARD to display some information
 *
 * @component Card
 * @property {CardItem[]} data - Information to display on the card
 * @property {boolean} canDelete - Indicate if render the trash can icon to delete the item
 * @property {string|number} id - ID of the element to retrieve it
 * @property {cbDelete} cbDelete - ID of the card retrieved
 * @example
 *
  <Card
    data={[
      {
        col: 6,
        header: "Mitad izquierda de mi tarjeta",
        data: "Un texto cualquiera, tengo tamaño de 6-cols de 12-cols",
      },
      {
        col: 6,
        header: "Mitad derecha de mi tarjeta",
        data: "Un texto cualquiera, tengo tamaño de 6-cols de 12-cols",
      },
    ]}
  />
 *
 *
 */
function Card({
  data = [
    {
      col: 12,
      header: "[Default label]",
      data: "Just a value",
    },
  ],
  canDelete = false,
  cbDelete = () => {
    return {
      infoCard: [
        {
          col: 0,
          header: "",
          data: "",
        },
      ],
      idCard: "",
    };
  },
  canEdit = false,
  cbEdit = () => {
    return {
      infoCard: [
        {
          col: 0,
          header: "",
          data: "",
        },
      ],
      idCard: "",
    };
  },
  id = "",
}) {
  const iconDelete = (
    <FontAwesomeIcon icon={faTimesCircle} className="iconCard" size="2x" />
  );

  const iconEdit = (
    <FontAwesomeIcon icon={faPen} className="iconCard ml-3" size="2x" />
  );

  return (
    <div className="shadowBackground py-4 mb-4">
      <div className="row mx-0">
        {data.map((info) => {
          return (
            <div className={`col-${info.col}`}>
              <b>{info.header}</b>
            </div>
          );
        })}
      </div>

      <div className="row mx-0 mt-2">
        {data.map((info, i) => {
          return <div className={`col-${info.col}`}>{info.data}</div>;
        })}

        <div className="col-1 d-flex justify-content-between">
          {canDelete ? (
            <div
              onClick={() =>
                cbDelete({
                  infoCard: data,
                  idCard: id,
                })
              }
              className="iconCard cursorPointer"
            >
              {iconDelete}
            </div>
          ) : null}

          {canEdit ? (
            <div
              onClick={() =>
                cbEdit({
                  infoCard: data,
                  idCard: id,
                })
              }
              className="iconCard cursorPointer"
            >
              {iconEdit}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  /**
   * Id of the card
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Indicate if render the trash can icon to delete the item
   */
  canDelete: PropTypes.bool,

  data: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Number of cols to use on the item
       */
      col: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),

      /**
       * Text to display as header on the card item column
       */
      header: PropTypes.string,

      /**
       * Value to display down the header
       */
      data: PropTypes.string,
    })
  ).isRequired,
};

export default Card;
