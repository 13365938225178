import React, { useContext } from "react";
import { FooterContainer, FooterDiferentCurrency } from "../Footer/Containers";
import { FooterTextComponent } from "./FooterTextComponent";
import WinQuoteContext from "../ContextWinQuote";
import { FooterLabelComponent } from "./FooterLabelComponent";

export const FooterComponent = () => {
  const { footer, itemsWithDiferentCurrency, interfaceControl } =
    useContext(WinQuoteContext);
  const number = footer.sell.number - footer.pursharse.number;
  const margin = number.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  });
  return (
    <FooterContainer>
      <FooterTextComponent label="TC" text={footer.tc.text} />
      <FooterLabelComponent label="Venta" />
      <FooterTextComponent label="Moneda" text={footer.currency.code} />
      <FooterTextComponent label="Importe" text={footer.sell.text} />
      <FooterLabelComponent label="Compra" />
      <FooterTextComponent
        label="Moneda"
        text={interfaceControl.disableCurrency ? "***" : footer.currency.code}
      />
      <FooterTextComponent
        label="Compra"
        text={interfaceControl.disableCost ? "------" : footer.pursharse.text}
        // info={`${
        //   itemsWithDiferentCurrency() !== 0
        //     ? `(${itemsWithDiferentCurrency()})`
        //     : ""
        // }`}
      />

      <FooterLabelComponent label="Margen" />

      <FooterTextComponent label="Moneda" text={footer.currency.code} />
      {/* <FooterTextComponent label="Importe" text={ footer.margin.text} /> */}
      <FooterTextComponent label="Importe" text={margin} />
    </FooterContainer>
  );
};
