import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";

/**
 * Get the excel in order to make the client fill the information
 * @returns {Promise<Blob>}
 */
export async function downloadIvaExcelSetup() {
  try {
    const res = await fetch(`${URL_BASE}setup/iva`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 *
 * @returns {Promise<import("pages/ProcesosEspeciales/Parameters/EnterpriseInfo/types").DtoEnterpriseInfo>}
 */
export async function getEnterpriseInfo() {

  /**
   * @type {import("pages/ProcesosEspeciales/Parameters/EnterpriseInfo/types").DtoEnterpriseInfo}
   */
  const errorData = {
    enterpriseName: "",
    street: "",
    noInterior: "",
    city: "",
    estado: "",
    postalCode: 0,
    email: "",
    noExterior:"",
    rfc:""
  };

  try {
    const res = await fetch(`${URL_BASE}parametros/datos-generales`, {
      method: "GET",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    return errorData;
  } catch (error) {
    return errorData;
  }
}

/**
 *
 * @param {import("pages/ProcesosEspeciales/Parameters/EnterpriseInfo/types").DtoEnterpriseInfo} dto
 * @returns {Promise<boolean>} True if information was updated
 */
export async function updateEnterpriseInfo(dto) {
  try {
    const res = await fetch(`${URL_BASE}parametros/datos-generales`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dto }),
    });

    const data = await res.json();

    if (isValidHttpResCode(res.status) && res.ok) {
      Success(()=>{},"Información actualizada");
      return true;
    }

    Error(()=>{},data.message);

    return false;
  } catch (error) {
    return false;
  }
}

export async function downloadUenExcelSetup() {
  try {
    const res = await fetch(`${URL_BASE}setup/uen`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 *
 * Download the excel file in order to upload movements to a bank account
 * @returns {Promise<Blob>}
 */
export async function downloadMovementsExcelSetup() {
  try {
    const res = await fetch(`${URL_BASE}setup/movimientos`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Download the template for concept egresses 
 * @returns {Promise<Blob>}
 */
export async function downloadEgressConceptsExcel(){
  try {
    const res = await fetch(`${URL_BASE}setup/conceptos/egresos`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Download the template for concept egresses 
 * @param {boolean} [isTemplate=true] Flag in order to download the template of the directory
 * @returns {Promise<Blob>}
 */
export async function downloadDirectoryTemplate(isTemplate=true){
  try {
    const res = await fetch(`${URL_BASE}setup/directorio?plantilla=${isTemplate}`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}


/**
 * Download the template for concept egresses 
 * @returns {Promise<Blob>}
 */
export async function downloadTypeConcepts(type="egresos"){
  try {
    const res = await fetch(`${URL_BASE}setup/conceptos/tipos/${type}`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Download the template for concept egresses 
 * @returns {Promise<Blob|undefined>}
 */
export async function downloadIncomingsExcel(){
  try {
    const res = await fetch(`${URL_BASE}setup/conceptos/ingresos`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Download the template for catalogue products/service
 * @returns {Promise<Blob|undefined>}
 */
export async function downloadCatalogueTemplate(){
  try {
    const res = await fetch(`${URL_BASE}setup/catalogo`, {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * Upload excel template 
 * @param {Blob|File} excel - Excel file template
 * @returns {Promise<boolean>}
 */
export async function uploadTemplateCatalogue(excel){
  const formData = new FormData();
  formData.append("catalogue", excel);

  try {
    const res = await fetch(`${URL_BASE}setup/catalogo`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Upload excel template. The `HTTP` request will always be `POST`
 * @param {Blob|File} excel - Excel file template
 * @param {string} url - Url of the endpoint
 * @param {string} name - Name of the attribute for the file on the http request
 * @returns {Promise<boolean>}
 */
export async function uploadExcelTemplate(excel,url="",name){
  const formData = new FormData();
  formData.append(name, excel);

  try {
    const res = await fetch(`${URL_BASE}${url}`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update the iva catalogue of the system with an excel
 * @param {Blob|File} excel - File to use for the update
 * @returns {Promise<boolean>} True if was updated
 */
export async function updateIvaSetup(excel) {
  const formData = new FormData();
  formData.append("ivaExcel", excel);

  try {
    const res = await fetch(`${URL_BASE}setup/iva`, {
      credentials: "include",
      method: "PUT",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update the uen table on the system with an excel file
 * @param {Blob|File} excel - Update the excel file
 * @returns {Promise<boolean>}
 */
export async function updateUenSetup(excel) {
  const formData = new FormData();
  formData.append("uen", excel);

  try {
    const res = await fetch(`${URL_BASE}setup/uen`, {
      credentials: "include",
      method: "PUT",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update the uen table on the system with an excel file
 * @param {Blob|File} excel - Update the excel file
 * @returns {Promise<boolean>}
 */
export async function uploadMovements(excel) {
  const formData = new FormData();
  formData.append("movements", excel);

  try {
    const res = await fetch(`${URL_BASE}setup/movimientos`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Update the CSD of the system
 * @param {string} rfc - RFC of the user
 * @param {string} password - Password sat
 * @param {File} cer - Cer file
 * @param {File} key - Key file
 * @returns {Promise<boolean>}
 */
export async function updateCsdUser(rfc, password, cer, key) {
  const formData = new FormData();
  const DTO = { rfc, password };
  formData.append("data", JSON.stringify(DTO));
  formData.append("cer", cer);
  formData.append("key", key);

  try {
    const res = await fetch(`${URL_BASE}facturacion/4.0/csd`, {
      credentials: "include",
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Download the template  
 * @param {import("./types").TypeTemplate} type - Type of template to download
 * @returns {Promise<Blob|undefined>}
 */
export async function downloadTemplate(type){

  const URLS = {
    'sectorMarket':`${URL_BASE}setup/sector-mercado`,
    'contacts':`${URL_BASE}contactos/plantilla/excel`
  }

  try {
    const res = await fetch(URLS[type], {
      credentials: "include",
      method: "GET",
    });

    const file = await res.blob();

    return file;
  } catch (error) {
    return undefined;
  }
}

/**
 * 
 * @returns {Promise<import("./types").CheckInParamaters>}
 */
export async function checkInParameters(){
  try {
    const res = await fetch(`${URL_BASE}setup/check-in`, {
      credentials: "include",
      method: "GET",
      
    });

    const data = await res.json();

    return data;
  } catch (error) {
    
  }
}