import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import Icon from "../Icon";
import style from "./styles.module.scss";

/**
 *
 * @param {import("./types").MoreInfoProps} props
 * @returns
 */
export default function MoreInfo(props) {
  const dialog = useDialog();

  return (
    <>
      <div className={style.container} onClick={dialog.open}>
        {/* <p className="m-0" onClick={dialog.open}>❕</p> */}
        <Icon nameIcon="info"  />
      </div>
      <Dialog
        title={props.title}
        forwardRef={dialog.refDialog}
        closeCallback={()=>dialog.close()}
        width={props.width}
        footer={props.footer}
      >
        {props.children}
      </Dialog>
    </>
  );
}
