import { validateLicense } from "helpers/apis";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateLicense}
 */
const STATE = {
  isValidating: true,
  licenseInformation: undefined,
};

export default function useLicense() {
  const [state, setState] = useState(STATE);

  useEffect(() => {
    (async function () {
      const data = await validateLicense();

      setState((current) => ({
        ...current,
        isValidating: false,
        licenseInformation: data,
      }));
    })();
  }, []);

  return {
    ...state,
  };
}
