import { Success, Error } from "./alerts";

/**
 *
 * @param {string} lada - Lada of the number
 * @param {string} number - Number of the phone
 * @returns {string} formatedNumber - Number with just numebers and not spaces
 */
export function parseNumber(lada, number) {
  return `${lada.replaceAll("+", "")}${number.replaceAll(" ", "")}`;
}

/**
 * Convert string number phone to number data type
 * @param {string} number - Number with the number char format
 * @returns {number} parsedNumber - Formated number as int
 */
export function parseNumberPhone(number) {
  let parsedNumber = number.replaceAll("+", "");
  parsedNumber = parsedNumber.replaceAll(" ", "");
  return parseInt(parsedNumber, 10);
}

/**
 *
 * @param {number} lada - Lada to validate before insert into DB
 * @param {string} message - Text in case the lada it's incorrect
 * @returns {boolean} True if the lada was correct (founded)
 */
export async function validatedLada(lada, message) {
  try {
    //1. Search lada on API
    let data = await fetch(
      `https://restcountries.eu/rest/v2/callingcode/${lada}`
    );
    data = await data.json();

    //2. Not founded lada
    if (data[0] === undefined) {
      //2.1 Return false
      Error(() => {}, message);
      return false;
    }

    //3. Founded lada, return true
    return true;
  } catch (error) {
    console.log(error);
    Error(() => {}, error);
    return false;
  }
}

export function validatedNumber(id, message) {
  const isValid = parseInt(document.getElementById(id).value, 10);

  console.log("isvalid from input", isValid);

  if (isValid === 1) {
    return true;
  }

  Error(() => {}, message);
  return false;
}

/**
 * Validate if a phone number is valid
 * @param {import("react-phone-input-2").CountryData|{}} data - Data of the field
 * @param {string|number} phone - Phone typed
 * @returns {import("./typesPhone").ValidatePhone}
 */
export function validatePhone(data, phone) {

  if (Object.keys(data).length === 0) return {
    isValid: false,
    lada: `${phone}`,
    phone: "",
  };

  if (`${phone}`.length <= 0) return {
    isValid: false,
    lada: `${phone}`,
    phone: "",
  };

  const INVALID = {
    isValid: false,
    lada: data?.dialCode || "",
    phone: "",
  };

  const totalNumberDigits = data.format
    .replaceAll(" ", "")
    .replaceAll("+", "")
    .replaceAll("(", "")
    .replaceAll("-", "")
    .replaceAll(")", "").length;

  const lengthLada = data.dialCode.length;

  const phoneParsed =  lengthLada < data.dialCode.length ? '' : `${phone}`.substring(lengthLada);

  const ladaToUse = phoneParsed === '' ? phone : data.dialCode;

  if (`${phone}`.length !== totalNumberDigits) {
    return { ...INVALID, lada: ladaToUse, phone: phoneParsed };
  }

  const ladaTyped = `${phone}`.substring(0, lengthLada);

  if (ladaTyped !== data.dialCode) {
    return { ...INVALID, lada: ladaToUse, phone: phoneParsed };
  }

  return {
    isValid: true,
    lada: ladaTyped,
    phone: `${phone}`.substring(lengthLada),
  };
}
