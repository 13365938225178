import { ticketsComments } from "helpers/Apis/tickets";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import rehypeRaw from "rehype-raw";
import TicketCommentBox from "./CommentBox";
import ReactMarkdown from "react-markdown";
import Time from "components/individual/Time";
import scss from "./styles.module.scss";
import Rate from "components/individual/Rate";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isLoading: true,
  comments: [],
  refetch: false,
};

/**
 * Render the view to comment a ticket
 * @param {import('./types').PropsTicketsComments} props - Props
 * @returns {JSX.Element}
 */
export default function TicketsComments({
  ticket,
  trigger = false,
  rate = null,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  const key = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      const apiComments = await ticketsComments(ticket);

      setState((current) => ({
        ...current,
        isLoading: false,
        comments: apiComments,
      }));
    })();
  }, [ticket, state.refetch, trigger]);

  const refetch = () =>
    setState((state) => ({ ...state, refetch: !state.refetch }));

  if (state.isLoading) return <p>Cargando comentarios...</p>;

  return (
    <div>
      <section className={scss.commentsContainer}>
        {state.comments.map((comment, i) => (
          <article className="mb-3" key={`${key.current}-${i}`}>
            <span className={scss.commentedBy}>
              {comment.status}{" "}
              {comment.status === "Evaluación" && rate !== null ? (
                <Rate rate={rate} />
              ) : null}{" "}
              - {comment.commented.name}
            </span>
            <Time time={comment.createdDate} />
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={comment.comment}
            />
          </article>
        ))}
      </section>
      <TicketCommentBox ticket={ticket} onChange={refetch} />
    </div>
  );
}
