import { RECEPTION_INVOICE } from "./reducer";

/**
 * Set the step of the add reception invoice
 * @param {number} step - Step screen to render
 * @returns {void}
 */
export const doSetStep = (step) => ({
  type: RECEPTION_INVOICE.SET_STEP,
  payload: step,
});

export const doSetResetState = (initialState) => ({
  type: RECEPTION_INVOICE.SET_INITIAL_STATE,
  payload: initialState,
});

/**
 * Update the pdf file used for the invoice reception
 * @param {import("types/typedef/customHooks/useReceiveFiles").FileInfoSatI} pdf - Information of the pdf file uploaded
 * @returns {void}
 */
export const doSetPdfFile = (pdf) => ({
  type: RECEPTION_INVOICE.SET_PDF_FILE,
  payload: pdf,
});

export const doSetProvider = (provider) => ({
  type: RECEPTION_INVOICE.SET_PROVIDER,
  payload: provider,
});
export const doSetXmlInfo = (xmlInfo) => ({
  type: RECEPTION_INVOICE.SET_XMLINFO,
  payload: xmlInfo,
});
export const doSetAmounts = (amounts) => ({
  type: RECEPTION_INVOICE.SET_AMOUNTS,
  payload: amounts,
});
export const doShowGrid = (showGrid) => ({
  type: RECEPTION_INVOICE.SHOW_GRID,
  payload: showGrid,
});
export const doSetConcept = (concept) => ({
  type: RECEPTION_INVOICE.SET_CONCEPT,
  payload: concept,
});

export const doSetStep2 = () => ({
  type: RECEPTION_INVOICE.SET_STEP_2,
});

/**
 *  Set only expenses object reducer
 * @param {boolean} itsOnlyExpenses - Indicates its for only expenses
 * @returns {void}
 */
export const doSetItsOnlyExpenses = (itsOnlyExpenses) => ({
  type: RECEPTION_INVOICE.SET_ONLY_EXPENSES,
  payload: itsOnlyExpenses,
});

/**
 * *  Set only expenses object reducer
 * @param {object} partialities - Indicates its for only expenses
 * @returns {void}
 */
export const doSetPartialities = (partialities) => ({
  type: RECEPTION_INVOICE.SET_PARTIALITIES,
  payload: partialities,
});

export const doSetIsAddingReception = (isAddingReception) => ({
  type: RECEPTION_INVOICE.SET_IS_ADDING_RECEPCION,
  payload: isAddingReception,
});

/**
 * 
 * @param {number} iva 
 * @returns {void}
 */
export const doSetIva = iva => ({
  type:RECEPTION_INVOICE.SET_IVA,
  payload:iva
});

/**
 * Update the emited date for the non fiscal invoice
 * @param {Date} date - Emitted date
 * @returns {void}
 */
export const doSetEmittedDate = date => ({
  type:RECEPTION_INVOICE.SET_EMITED_DATE,
  payload:date
});

/**
 * Update the rfc for the no legal invoice
 * @param {string} rfc - RFC
 * @param {boolean} isValid - Indicates if the rfc typed is valid
 * @returns {void}
 */
export const doSetRfc = (rfc,isValid) => ({
  type:RECEPTION_INVOICE.SET_RFC,
  payload:{rfc,isValid}
})

export const doSetExpirationDate = expirationDate => ({
  type:RECEPTION_INVOICE.SET_EXPIRATION_DATE,
  payload:expirationDate
});

/**
 * Update the rfc of the enterprise for the no fiscal invoice
 * @param {string} rfc - RFC
 * @returns {void}
 */
export const doSetRfcEnterprise = rfc => ({
  type:RECEPTION_INVOICE.SET_RFC_ENTERPRISE,
  payload:rfc
});

/**
 * Set the currency to use on the no fiscal invoice
 * @param {"USD"|"MXN"} currency 
 * @returns {void}
 */
export const doSetCurrency = currency => ({
  type:RECEPTION_INVOICE.SET_CURRENCY,
  payload:currency
});

/**
 * Update the total amount of the no fiscal invoice
 * @param {number} total - Total
 * @returns {void}
 */
export const doSetTotal = total => ({
  type:RECEPTION_INVOICE.SET_TOTAL,
  payload:total
});

/**
 * 
 * @param {string} folio 
 * @returns {void}
 */
export const doSetFolio = folio => ({
  type:RECEPTION_INVOICE.SET_FOLIO,
  payload:folio
});

/**
 * 
 * @param {number} creditDays - Credit days
 * @returns {void}
 */
export const doSetCreditDays = creditDays => ({
  type:RECEPTION_INVOICE.SET_CREDIT_DAYS,
  payload:creditDays
});

/**
 * 
 * @param {string} socialReason - Social reason 
 * @param {number|null|undefined} id - Id of the social reason selected (in case exists)
 * @param {string|null|undefined} rfc - RFC of the social reason selected
 * @param {number} creditDays - Credit days
 * @returns {void}
 */
export const doSetSocialReason = (socialReason,id,rfc,creditDays) => ({
  type:RECEPTION_INVOICE.SET_SOCIAL_REASON,
  payload:{socialReason,id,rfc,creditDays}
});