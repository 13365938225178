import React, { useState } from "react";
import { ErrorMessage, InputText } from "../../../individual/inputs/Inputs";
const validateRfc = require("validate-rfc");

/**
 * Render an rfc component
 * @param {import("./types").RfcPropsI} props - Props
 * @returns {JSX.Element} RFC Component
 */
function Rfc({
  label = "RFC",
  value = null,
  id = "rfc",
  name = "rfc",
  css = "rfc",
  placeholder = "Escribe aqui",
  forwardRef = {},
  onChange = () => {},
  required = false,
}) {
  const [validationRfc, setValidationRfc] = useState("");
  const [rfc, setRfc] = useState(value);

  const evaluateRfc = (rfc) => {
    setRfc(rfc);

    const { isValid } = validateRfc(rfc, {
      omitVerificationDigit: true,
    });

    if (isValid) {
      onChange({
        rfc: rfc,
        isValid: true,
      });
      setValidationRfc("");

      return;
    } else {
      onChange({
        rfc: rfc,
        isValid: false,
      });
      setValidationRfc("RFC invalido");
      return;
    }
  };

  return (
    <div className={css}>
      <InputText
        isRequired={validationRfc === "" && required ? true : false}
        labelTxt={label}
        defaultValue={rfc}
        placeholderTxt={placeholder}
        onChange={(value) => evaluateRfc(value)}
        id={`fake-${id}`}
      />

      <ErrorMessage message={validationRfc} />

      <input
        type="hidden"
        id={id}
        name={name}
        ref={forwardRef}
        readOnly={true}
        value={rfc}
      />
    </div>
  );
}

export default Rfc;
