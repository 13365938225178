import React, { useRef, useContext } from "react";
import { ContextAdvancePayment } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "../styles.module.scss";
import { parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { Pagination } from "components/individual/Pagination";
import MoreInfoMovement from "structure/MoreInfoMovement";
import MobileItem from "./MobileItem";

export default function Table() {
  const hook = useContext(ContextAdvancePayment);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading) return <Spinner text={"Cargando"} hidden={false} />;

  if (hook.advances.length <= 0)
    return <h2 className="text-center my-5">No hay anticipos</h2>;

  return (
    <div className={style.tableContainer}>
      <div className={style.header}>
        <p>{hook.movementType === "clientes" ? "Ingreso" : "Egreso"}</p>
        <p>Fecha</p>
        <p>Banco</p>
        <p>Cuenta</p>
        <p>{hook.movementType === "clientes" ? "Cliente" : "Proveedor"}</p>
        <p>Moneda</p>
        <p>Importe</p>
      </div>

      <div className={style.containerAdvances}>
        {hook.advances.map((advance, i) => (
          <div key={`${key.current}-${i}`}>
            <MobileItem {...advance}/>

            <input
              onChange={(e) => hook.selectAdvance(advance)}
              type="radio"
              name="advance"
              id={`advance-${advance.id}`}
            />
            <label className={style.row} htmlFor={`advance-${advance.id}`}>
              <MoreInfoMovement id={+advance.id} isForCancel={false}>
                {advance.id}
              </MoreInfoMovement>
              <p>{parseDateToText(new Date(`${advance.date}:`))}</p>
              <p>{advance.bankAccount.bank.shortName}</p>
              <p>{advance.bankAccount.account}</p>
              <p>{advance.customer.socialReason}</p>
              <p>{advance.bankAccount.currency}</p>
              <p>{mnCurrency(advance.residue)}</p>
            </label>
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.currentPage}
        pages={hook.pages}
        onChange={hook.updatePage}
      />
    </div>
  );
}
