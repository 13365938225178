import React, { useContext, useEffect, useState } from "react";
import Add from "pages/Administration/InvoiceReception/Add";
import XmlProviders from "components/general/XmlProviders";
import {
  ContainerXMLInfo,
  EmittedDate,
  Currency,
  CreditDays,
  Emitter,
  ExpirationDate,
  Folio,
  Importe,
  Receptor,
  SocialReason,
  Total,
  UUID,
  Discount,
  LblImporte,
} from "../../Filter/styles";
import { AddReceptionInvoiceContext } from "../../Filter";
import { InputNumber } from "components/individual/inputs/Inputs";

export default function Step1() {
  const { setProvider, state, setXmlInfo, setItsOnlyExpenses, setPdfFile } =
    useContext(AddReceptionInvoiceContext);
  const [state1, setState1] = useState({
    isValid: false,
    itsOnlyExpenses: false,
    pdf: null,
    xml: null,
    xmlInfo: null,
    providers: [],
  });

  const idOpenModal = "btnProviderList";

  useEffect(() => {
    // User already loaded a xml file, not repeat the routine
    // if (state.xmlWasLoaded) return;

    // Open modal when more than 1 provider for credit days
    if (state1.providers.length > 1) {
      document.getElementById(idOpenModal).click();
      setItsOnlyExpenses(false);
      return;
    }

    // Set credit days of the only provider founded
    if (
      state1.providers.length === 1 &&
      state1.providers[0].creditDays !== null
    ) {
      setProvider(state1.providers[0].creditDays, state1.providers[0].id);
      setItsOnlyExpenses(false);
      return;
    }

    // No providers founded, just for expenses
    if (
      state1.providers.length === 1 &&
      state1.providers[0].creditDays === null
    ) {
      setProvider(0, 0);
      setItsOnlyExpenses(true);
      return;
    }
  }, [state1.providers]);

  return (
    <>
      <XmlProviders
        providers={state1.providers}
        idOpenModal={idOpenModal}
        onConfirm={({ creditDays, idProvider }) =>
          setProvider(creditDays, idProvider)
        }
      />

      <Add
        onChange={(validations) => {
          setState1(validations);
          setXmlInfo(validations);
        }}
        onChangePdf={setPdfFile}
      >
        <ContainerXMLInfo>
          <EmittedDate>
            <Add.LabelEmitedDate />
          </EmittedDate>

          <SocialReason>
            <Add.LabelSocialReason />
          </SocialReason>

          <Folio>
            <Add.LabelFolio />
          </Folio>

          <UUID>
            <Add.UUID />
          </UUID>

          <Emitter>
            <Add.LabelRFCEmitter />
          </Emitter>

          <Add.LabelsAmounts />

          <Add.LabelImpuestos />

          {/* <Impuestos>
            <p>Impuestos</p>
            <div className="d-flex">
              <div>
                <b>Trasladados</b>
                <p></p>
              </div>

              <div>
                <b>Retenidos</b>
              </div>
            </div>
          </Impuestos> */}

          <Receptor>
            <Add.LabelRFCReceptor />
          </Receptor>

          <Currency>
            <Add.LabelCurrency />
          </Currency>

          {/* <ImpuestosTrasladados>
            <Add.LabelImpuestosTrasladados />
          </ImpuestosTrasladados> */}

          <Importe>
            <Add.LabelImporte />
          </Importe>

          <Discount>
            <Add.LabelDiscount />
          </Discount>

          {/* <Iva>
            <Add.LabelIVA />
          </Iva> */}
          <Total>
            <Add.LabelTotal />
          </Total>

          <CreditDays>
            <InputNumber
              decimal={false}
              labelTxt="Dias credito *"
              placeholderTxt="Escribe aqui"
              defaultValue={state.creditDays}
              id="creditDays"
              onChange={(val) =>
                val.length <= 0
                  ? setProvider(0, state1.providers[0].id)
                  : setProvider(+val, state1.providers[0].id)
              }
            />
            {state.creditDays <= -1 ? (
              <p className="text-danger">Invalido</p>
            ) : null}
          </CreditDays>

          <ExpirationDate>
            <label>Fecha vencimiento</label>
            <p>{state.expirationDate.ui}</p>
          </ExpirationDate>
        </ContainerXMLInfo>
      </Add>
    </>
  );
}
