import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { ContextEmitedCreditNotes } from "..";
import { useContext } from "react";
import { Label } from "structure/Document";
import style from "../styles.module.scss";
registerLocale("es", es);

export default function RangeDate() {
  const hook = useContext(ContextEmitedCreditNotes);

  return (
    <>
      <div>
        <Label>Inicio</Label>
        <ReactDatePicker
          selected={hook.beginDate}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona"
          maxDate={hook.endDate}
          onChange={(date) => hook.updateBeginDate(date)}
          isClearable={false}
        />
      </div>

      <div>
        <Label>Fin</Label>
        <ReactDatePicker
          selected={hook.endDate}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          minDate={hook.beginDate}
          placeholderText="Selecciona"
          onChange={(date) => hook.updateEndDate(date)}
          maxDate={new Date()}
          isClearable={false}
        />
      </div>
    </>
  );
}
