import { RedirectButton } from "components/individual/buttons/Buttons";
import usePermissions from "customHooks/usePermissions";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import React from "react";
import { useContext } from "react";

export default function AssociateFiles() {
  const hook = useContext(CONTEXT_HOME);

  const { checkPermission } = usePermissions();

  if (!checkPermission("384f293a-3371-42a3-beee-f7196010b4bf")) return <></>;

  return (
    <RedirectButton
      id="associatedFilesInvoiceReception"
      text="Asociar archivos"
      link={`directorio/${hook.quote.customer.id}/documentos/documentos/archivos-asociados/${hook.quote.idDocument}`}
    />
  );
}
