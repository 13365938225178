import LoginContext from "context/Login/LoginContext";
import { Success } from "helpers/alerts";
import {
  associateEgresses,
  getBankAccount,
  getMovement,
  getPendingExpenses,
} from "helpers/Apis/Banks";
import { getMovementAssociationTolerance } from "helpers/Apis/parameters";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Fuse from "fuse.js";

/**
 * State of the custom hook
 * @type {import("types/typedef/customHooks/useEgressAssociation").StateI}
 */
const initialState = {
  movement: null,
  isLoading: true,
  pendingExpenses: [],
  bank: null,
  isValid: true,
  isAssociating: false,
  step: 1,
  tolerance: 0,
  gridInfo: {
    filteredGrid: {
      array: [],
      indexed: {},
    },
    isNegativeResidue: false,
    isUsedAllResidue: false,
    isValidApplied: true,
    isValidImport: true,
    isValidResidue: true,
    newResidue: 0,
    totalApplied: 0,
  },
  elements: {
    array: [],
    indexed: {},
  },
  nonDeductible: {
    isValid: false,
    nonDeductible: {},
    applied: 0,
    remainingResidue: 0,
  },
  isValidGrid: true,
  showConcepts: false,
};

/**
 * Custom hook to handle the association of the egresses
 * @param {number} idMovement - Id of the movement that will be used to associate the egresses
 * @param {number} idBankAccount - Id of the bank account that has that movement
 * @returns {import("types/typedef/customHooks/useEgressAssociation").StateI}
 */
export default function useEgressAssociation(idMovement, idBankAccount) {
  const [state, setState] = useState(initialState);

  const [searched, setSearched] = useState([]);

  /**
   * Update the list of elements to show on the grid in order to associate the elements
   * @param {string} search - Search typed
   */
  const handleSearchExpense = (search) => {
    if (search === "") {
    }

    /**
     * @type {Fuse.IFuseOptions<T>}
     */
    const options = {
      keys: [
        "invoice.socialReason",
        "invoice.folio",
        "invoice.uuid",
        "egress.description",
      ],
    };

    const pendingExpenses = getNewPending(state.gridInfo.filteredGrid.indexed);

    const fuse = new Fuse(pendingExpenses, options);

    const result = fuse.search(search);

    const items = result.map((item) => item.item);

    setSearched(items);
  };

  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  /**
   *
   * @param {boolean} showConcepts - Flag in order to display the concepts
   * @returns
   */
  const toggleShowConcepts = (showConcepts) =>
    setState((state) => ({
      ...state,
      showConcepts,
    }));

  useEffect(() => {
    (async function () {
      const [apiPendingExpenses, apiMovement, apiBankAccount, apiTolerance] =
        await Promise.all([
          getPendingExpenses(),
          getMovement(idMovement),
          getBankAccount(+idBankAccount),
          getMovementAssociationTolerance(),
        ]);

      const parsedApiPendingExpenses = apiPendingExpenses.map((expense) => ({
        ...expense,
        currency: expense.invoice.currency,
        isValidImport: true,
        isValidResidueOdc: true,
        import: {
          number: 0,
          text: "$0.00",
        },
        applied: {
          number: 0,
          text: "$0.00",
        },
        newResidue: {
          number: 0,
          text: "$0.00",
        },
        tc: {
          number: 1,
          text: "$1.00",
        },
      }));

      setState({
        ...state,
        tolerance: apiTolerance,
        movement: apiMovement,
        isLoading: false,
        pendingExpenses: parsedApiPendingExpenses,
        bank: apiBankAccount,
        gridInfo: {
          ...state.gridInfo,
          newResidue: apiMovement.saldo,
        },
      });
    })();
  }, []);

  const pendingToIndexed = () =>
    state.pendingExpenses.reduce(
      (indexed, item) => ({
        ...indexed,
        [item.id]: item,
      }),
      {}
    );

  const getNewPending = (newValues) => {
    const currentPending = pendingToIndexed();
    const updated = {
      ...currentPending,
      ...newValues,
    };

    const pendingUpdated = Object.entries(updated).map(([key, value]) => value);

    return pendingUpdated;
  };
  /**
   * Update the grind info and the validation flags
   * @param {import("types/typedef/customHooks/useAddReceptionInvoice").GridAmountsI} gridInfo - Information of the component
   */
  const updateInfo = (gridInfo) => {
    let isValidGrid = true;

    // if (!gridInfo.isValidApplied) isValidGrid = false;

    if (!gridInfo.isValidImport) isValidGrid = false;

    if (!gridInfo.isPayable) isValidGrid = false;

    // if (!gridInfo.isValidResidue) isValidGrid = false;

    // if (gridInfo.isNegativeResidue) isValidGrid = false;
    // const indexedPending = pendingToIndexed();

    // const pendingExpenses = getNewPending(gridInfo.filteredGrid.indexed);

    setState({
      ...state,
      gridInfo,
      isValidGrid,
      // pendingExpenses,
    });
  };

  /**
   * Set the step to render a different screen
   * @param {number} step - Step to render
   * @returns {void}
   */
  const setStep = (step) =>
    setState({
      ...state,
      step,
    });

  /**
   * Set the 1st step of the association progress
   * @returns {void}
   */
  const returnStep = () =>
    setState({
      ...state,
      step: 1,
      showConcepts: false,
      gridInfo: {
        ...state.gridInfo,
        newResidue: state.movement.amount,
        totalApplied: 0,
      },
    });

  /**
   * Set the state to display the progress association
   * @param {boolean} isAssociating - Boolean to display the spinner
   * @returns {void}
   */
  const setIsAssociating = (isAssociating) =>
    setState({
      ...state,
      isAssociating,
    });

  const setNonDeductible = (nonDeductible) =>
    setState({
      ...state,
      nonDeductible,
    });

  /**
   * Associate the incoming/expenses
   */
  const associate = async () => {
    setIsAssociating(true);

    /**
     * DTO to be sended on the backend
     * @type {import("types/typedef/customHooks/useEgressAssociation").AssociateDto}
     */
    const dto = {
      grid: state.gridInfo.filteredGrid,
      executive: {
        fullName: userInfo[0].fullName,
        id: userInfo[0].userID,
      },
      movement: {
        id: state.movement.MovementID,
        currentResidue: state.movement.saldo,
      },
      nonDeductible: state.nonDeductible.nonDeductible,
    };

    const wasAssociated = await associateEgresses(dto);

    setIsAssociating(false);

    if (wasAssociated) {
      Success(() => {}, "Egreso(s) asociado(s)");
      history.push(
        `../../../../bancos/movimientos/${state.bank.bankAccountID}`
      );
    }
  };

  return {
    movement: state.movement,
    isLoading: state.isLoading,
    pendingExpenses: state.pendingExpenses,
    bank: state.bank,
    applied: state.gridInfo.totalApplied,
    residue: state.gridInfo.newResidue,
    updateInfo,
    step: state.step,
    setStep,
    returnStep,
    indexedGrid: state.gridInfo.filteredGrid.indexed,
    isValid: state.isValidGrid,
    setNonDeductible,
    nonDeductible: state.nonDeductible,
    associate,
    isAssociating: state.isAssociating,
    tolerance: state.tolerance,
    handleSearchExpense,
    searched,
    gridAssociations: state.gridInfo.filteredGrid.array,
    toggleShowConcepts,
    showConcepts: state.showConcepts,
  };
}
