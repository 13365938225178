import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import MicrosoftCsp from "structure/MicrosoftCsp";

export default function Purchases() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "purchase") return <></>;

  return (
    <>
      <NavigationItem icon="home" label="Inicio > Compras" subMenu="general" />

      <NavigationItem
        icon="document"
        label="Gestión OC"
        link="/compras/gestion-oc"
      />

      <NavigationItem
        icon="document"
        label="Facturas recibidas"
        link="/administracion/recepcion-facturas"
      />
      <NavigationItem
        icon="document"
        label="Notas de crédito recibidas"
        link="/compras/notas-de-credito"
      />
      <NavigationItem
        icon="document"
        label="Ordenes de compra"
        link="/administracion/ordenes-compra"
      />

      <MicrosoftCsp type="recomendacionPagos">
        <NavigationItem icon="document" label="Programación de pagos" />
      </MicrosoftCsp>

      <NavigationItem
        icon="document"
        label="Reportes"
        subMenu="purchaseReports"
      />
    </>
  );
}
