import React from "react";
import TCRequest from "components/general/TC/Index";
import { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";

export default function TcOptions() {
  const hook = useContext(ContextCreateInvoiceV2);

  if (!hook.currencyExchange) return <></>;

  return <TCRequest tcDocument={hook.tcOrder} onChange={hook.updateTc} />;
}
