import Table from 'components/general/Table'
import React from 'react'

/**
 * Render the table for proyects
 * @param {import('./types').PropsTableProyects} props - Props
 * @returns {JSX.Element}
 */
export default function TableProyects(props) {
  return (
    <Table {...props}/>
  )
}
