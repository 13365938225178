import React, { useRef } from "react";
import style from "./styles.module.scss";
import { useContext } from "react";
import { ContextMovements } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import Concepts from "./Concepts";

export default function Table() {
  const hook = useContext(ContextMovements);

  const key = useRef(window.crypto.randomUUID());

  if (hook.bankAccount === undefined)
    return (
      <h2 className="text-center my-4 text-danger">
        ⚡ Selecciona una cuenta de banco
      </h2>
    );

  if (hook.from instanceof Date === false)
    return (
      <h2 className="text-center my-4 text-danger">
        ⚡ Selecciona una fecha de inicio
      </h2>
    );

  if (hook.to instanceof Date === false)
    return (
      <h2 className="text-center my-4 text-danger">
        ⚡ Selecciona una fecha de fin
      </h2>
    );

  if (hook.isLoading)
    return <Spinner text={"Buscando movimientos"} hidden={false} />;

  if (hook.movements.length <= 0)
    return (
      <h2 className="text-center my-4 text-danger">⚡ No hay movimientos</h2>
    );

  return (
    <div className={style.table}>
      <div className={style.header}>
        <p>Fecha</p>
        <p>Razón social/Concepto</p>
        <p>Cargos</p>
        <p>Abonos</p>
        <p>Estatus</p>
      </div>

      <div className={style.containerRows}>
        {hook.movements.map((movement, i) => (
          <div className={style.row} key={`${key.current}-${i}`}>
            <p>{movement.date}</p>
            <p className={style.itemConcepts}>
              {movement.socialReasonReference} /
              <Concepts
                concepts={movement.concept}
                invoice={movement.invoiceEmited}
              />
            </p>
            <p className="text-right">
              {movement.type.id === 2 ? movement.amount.text : "-"}
            </p>
            <p className="text-right">
              {movement.type.id === 1 ? movement.amount.text : "-"}
            </p>
            <p>{movement.statusDescription}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
