import { getUsersInfo } from "helpers/Apis/usuariosApi";
import { useRef, useState } from "react";
import { useEffect } from "react";

const dummyFunction = () => {};

/**
 * @type {import("../types").StateUseUsers}
 */
const INITIAL_STATE = {
  isLoading: true,
  users: [],
  actualPage: 1,
  pages: 0,
  search: "",
  user: null,
  isOpenMoreInformation: false,
  refetch: false,
};

/**
 * @type {import("../types").ContextStateUseUsers}
 */
export const CONTEXT_INITIAL_STATE = {
  ...INITIAL_STATE,
  setSearch: dummyFunction,
  resetInfoRow: dummyFunction,
  closeMoreInformation: dummyFunction,
  openMoreInformation: dummyFunction,
  setUserInfoRow: dummyFunction,
  refetchTable: dummyFunction,
  updatePage:dummyFunction
};

export default function useUsers() {
  const [state, setState] = useState(INITIAL_STATE);

  const idEditBtn = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        user: null,
      }));

      const { actualPage, pages, tabla, status } = await getUsersInfo(
        state.actualPage,
        "DESC",
        "1",
        state.search
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        actualPage: actualPage,
        pages,
        users: tabla,
      }));
    })();
  }, [state.actualPage, state.refetch]);

  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const openMoreInformation = () =>
    setState((current) => ({
      ...current,
      isOpenMoreInformation: true,
    }));

  const closeMoreInformation = () =>
    setState((current) => ({
      ...current,
      isOpenMoreInformation: false,
    }));

  const setUserInfoRow = (user) =>
    setState((current) => ({
      ...current,
      user,
    }));

  const refetchTable = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  const updatePage = page => setState(current=>({
    ...current,
    actualPage:page,
  }));

  return {
    ...state,
    setSearch,
    openMoreInformation,
    closeMoreInformation,
    setUserInfoRow,
    refetchTable,
    updatePage,
    idEditBtn:idEditBtn.current
  };
}
