import { isValidHttpResCode } from "helpers/Apis/fetch";
import { Success,Error } from "helpers/alerts";
import { URL_BASE } from "routes/routes";

/**
 * Cancel a preinvoice
 * @param {number} id - Id of the document
 * @returns {Promise<boolean>}
 */
export async function cancelOdcDocument(id) {
    try {
      const res = await fetch(`${URL_BASE}documentos/odc/${id}`, {
        credentials: "include",
        method: "DELETE",
      });

      console.log('Respuesta de la cancelacion de la ODC');
      
      const data = await res.json();
      console.log(data)
  
      if (res.ok && isValidHttpResCode(res.status)) {
        Success(() => {}, data.message);
        return true;
      }else{
        Error(() => {}, data.message);
        return false;

      }
  
    } catch (error) {
      console.log('No se pudo cancelar la odc');
      console.log(error);
      // Error(error.message);
      return false;
    }
  }