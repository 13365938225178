import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { saveAs } from "file-saver";
import { getCxpReportExcel } from "helpers/Apis/Documents";
import { dateToDbFormat, parseDateToText } from "helpers/dates";
import React, { useState } from "react";

/**
 *
 * @param {import('./types').PropsCxcAndCxpExcel} param0
 * @returns
 */
export default function CxcAndCxpExcel({
  from,
  to,
  type,
  socialReason = null,
}) {
  const [state, setState] = useState({
    isDownloading: false,
  });

  const handleDownload = async () => {
    setState((current) => ({
      ...current,
      isDownloading: true,
    }));

    const file = await getCxpReportExcel(
      from === null ? null : dateToDbFormat(from),
      to === null ? null : dateToDbFormat(to),
      type,
      socialReason
    );

    const socialReasonName =
      socialReason === null || socialReason === "" ? "" : `(${socialReason})`;

    const filename =
      from === null || to === null
        ? `Reporte ${type} ${socialReasonName}.xlsx`
        : `Reporte ${type} ${parseDateToText(
            from,
            "medium"
          )} al ${parseDateToText(to, "medium")} ${socialReasonName}.xlsx`;

    if (file !== undefined) saveAs(file, filename);

    setState((current) => ({
      ...current,
      isDownloading: false,
    }));
  };

  if (state.isDownloading)
    return (
      <Spinner
        hidden={false}
        idSpinner="downloadingExcelCxpReport"
        text={<LoadingText>Descargando reporte</LoadingText>}
      />
    );

  return (
    <ButtonV2 onClick={handleDownload}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
