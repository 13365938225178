import { getMoreInfoConsecutiveOc } from "helpers/Apis/Documents";
import React, { useEffect, useRef, useState } from "react";
import style from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { oc } from "date-fns/locale";
import Date from "./Date";
import Amount from "./Amount";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isLoading: true,
  oc: undefined,
};

/**
 * Render
 * @param {import('./types').PropsMoreInfoConsecutiveOc} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfoConsecutiveOc({ idOc = null }) {
  const [state, setState] = useState(INITIAL_STATE);

  const key = useRef(window.crypto.randomUUID());

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        oc: undefined,
      }));

      const apiOc = await getMoreInfoConsecutiveOc(idOc);

      setState((current) => ({
        ...current,
        isLoading: false,
        oc: apiOc,
      }));
    })();
  }, [idOc]);

  if (typeof idOc !== "number")
    return (
      <p className="text-center m-5">Proporciona el id de la Orden de Compra</p>
    );

  if (state.isLoading)
    return (
      <Spinner
        text={<LoadingText>Cargando más información</LoadingText>}
        hidden={false}
      />
    );

  if (state.oc === undefined)
    return (
      <p className="text-center m-5">No se pudo obtener más información</p>
    );

  return (
    <div className={style.cards}>
      <div className={style.ocOverview}>
        <div>
          <p>ODC</p>
          <p>{state.oc.documentNumber}</p>
        </div>
        <p>{state.oc.status}</p>
        <p>{state.oc.currency}</p>

        <p>{state.oc.socialReason}</p>
        <p>{state.oc.rfc}</p>

        <Date label="Emisión" date={state.oc.emitedDate} />
        <Date label="Envío" date={state.oc.sendDate} />
        <Amount amount={state.oc.subtotal} label="Importe" />
        <Amount amount={state.oc.iva} label="IVA" />
        <Amount amount={state.oc.total} label="Total" />
      </div>

      <p className={style.header}>
        {state.oc.invoice.length <= 0 ? "No hay facturas" : "Facturas y pagos"}
      </p>

      {state.oc.invoice.map((invoice) => (
        <div
          className={style.invoiceAndMovements}
          key={`${key.current}-${invoice.documentNumber}`}
        >
          <div className={style.invoiceRowHeader}>
            <p>Factura</p>
            <p>Recepción</p>
            <p>Vencimiento</p>
            <p>Moneda</p>
            <p>Total</p>
            <p>TC</p>
            <p>Saldo</p>
          </div>

          <div className={style.invoiceRow}>
            <p>{invoice.documentNumber}</p>
            <p>{parseDateToText(getDateFromUtc(invoice.receptionDate))}</p>
            <p>{parseDateToText(getDateFromUtc(invoice.expirationDate))}</p>
            <p>{invoice.currency}</p>
            <p>{mnCurrency(invoice.total)}</p>
            <p>{mnCurrency(invoice.tc)}</p>
            <p>{mnCurrency(invoice.recidue)}</p>
          </div>

          <div className={style.movementsRowHeader}>
            <p className="text-center">-</p>
            <p className="text-center">Pago</p>
            <p className="text-center">Emisión</p>
            <p className="text-center">Moneda</p>
            <p className="text-center">Total</p>
            <p className="text-center">TC</p>
            <p className="text-center">Aplicado</p>
          </div>

          {invoice.movements.map((movement) => (
            <div
              key={`${key.current}-movement-${movement.idMovement}`}
              className={style.invoiceRow}
            >
              <p></p>
              <p>{movement.id}</p>
              <p>{parseDateToText(getDateFromUtc(movement.emitedDate))}</p>
              <p>{movement.currency}</p>
              <p>{mnCurrency(movement.total)}</p>
              <p>{mnCurrency(movement.tc)}</p>
              <p>{mnCurrency(movement.applied)}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
