import LoginContext from "context/Login/LoginContext";
import { startTicket } from "helpers/Apis/tickets";
import { YesNoAlert } from "helpers/alerts";
import { useContext } from "react";
import { useState } from "react";

/**
 *
 * @param {number} idTicket - Id of the ticket
 * @param {number|null} [idEngineer=null]
 * @param {string|null} [startedAt=null]
 * @param {()=>void} [onStartedTicket=()=>{}]
 */
export default function useStartTicket(
  idTicket,
  idEngineer,
  startedAt,
  onStartedTicket = () => {}
) {
  const [isLoading, setIsLoading] = useState(false);

  const { userInfo } = useContext(LoginContext);

  const canStartTicket =
    userInfo[0].userID !== idEngineer ||
    idEngineer === null ||
    startedAt !== null
      ? false
      : true;

  const attemptStartTicket = async () => {
    setIsLoading(true);

    const wasStarted = await startTicket(idTicket);

    if (wasStarted) {
      onStartedTicket();
    }

    setIsLoading(false);
  };

  const showModal = async () =>
    await YesNoAlert("¿Deseas mandar a revisión?", attemptStartTicket);

  return {
    showModal,
    attemptStartTicket,
    isLoading,
    canStartTicket,
  };
}
