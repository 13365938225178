import React from "react";
import { useContext } from "react";
import { ContextCancelInvoice } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import Icon from "components/individual/HeaderIcon";

const Container = ({ children }) => (
  <div className="d-flex justify-content-end align-items-center">
    {children}
  </div>
);

export default function BtnConfirmCancel() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.isLoading) return <></>;

  if (hook.isCancelling)
    return (
      <Container>
        <Spinner
          hidden={false}
          idSpinner="cancellatingQuote"
          text="Cancelando"
        />
      </Container>
    );

  // No tiene contrato y escogio un motivo de cancelacion diferente a "Otros"
  if (
    !hook.hasContract &&
    hook.idMotive !== null &&
    hook.idMotive !== hook.MOTIVO_OTROS
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );

  // No tiene contrato , escogio el motivo de "Otros" y ya escribio el comentario
  if (
    !hook.hasContract &&
    hook.idMotive !== null &&
    hook.idMotive === hook.MOTIVO_OTROS &&
    hook.dtoCancellation.motive.length >= 1
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );

  /**
   * 1. Tiene contrato
   * 2. No quiere renovar contrato
   * 3. Ya selecciono un motivo de cancelacion
   * 4. La cancelacion no es otros
   */
  if (
    hook.cancelInvoice === true &&
    hook.idMotive !== null &&
    hook.idMotive !== hook.MOTIVO_OTROS
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );

  /**
   * 1. Tiene contrato
   * 2. No quiere renovar contrato
   * 3. Ya selecciono un motivo de cancelacion
   * 4. Esecribio un comentario porque el motivo de cancelacion es **Otros**
   */
  if (
    hook.cancelInvoice === true &&
    hook.idMotive !== null &&
    hook.idMotive === hook.MOTIVO_OTROS &&
    hook.dtoCancellation.motive.length >= 1
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );

  /**
   * Ya selecciono el motivo de cancelacion junto con los demas datos, y ademas no es por el motivo de Otros
   */
  if (
    hook.startDate !== undefined &&
    hook.reminder !== undefined &&
    hook.endDate !== undefined &&
    // hook.dtoCancellation.motive.length >= 1 &&
    hook.idMotive !== null &&
    hook.idMotive !== hook.MOTIVO_OTROS
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );

  /**
   * Ya selecciono el motivo de cancelacion junto con los demas datos, y ademas no es por el motivo de Otros
   */
  if (
    hook.startDate !== undefined &&
    hook.reminder !== undefined &&
    hook.endDate !== undefined &&
    hook.dtoCancellation.motive.length >= 1 &&
    hook.idMotive !== null &&
    hook.idMotive === hook.MOTIVO_OTROS
  )
    return (
      <Container>
        <ButtonV2 onClick={hook.attemptCancelation} theme="danger">
          <span>Cancelar cotizacion</span>
          <Icon icon="save" />
        </ButtonV2>
      </Container>
    );
}
