import { Spinner } from "components/individual/Spinner/Spinner";
import useDisassociateExpenseConcept from "customHooks/useDisassociateExpenseConcept";
import React from "react";
import { Deductible } from "./Deductible";
import { NoDeductible } from "./NoDeductible";

import scss from "./styles.module.scss";

/**
 * Render a grid to check/uncheck the non deductible expenses to dis-associate
 * @param {import('./types').PropsDisassociateEgressConcept} props - Props
 * @returns {JSX.Element} Grid of the associated non deductibles expenses to the movement
 */
export const DisassociateExpenseConcept = ({
  idMovement = null,
  onChange = () => {},
}) => {
  const hook = useDisassociateExpenseConcept({ idMovement, onChange });

  if (hook.state.isLoading)
    return <Spinner hidden={false} text="Cargando conceptos de gasto..." />;

  return (
    <>
      <p className={scss.header}>
        <label htmlFor="all-egresses">
          <input
            checked={hook.masterCheckbox()}
            type="checkbox"
            id="all-egresses"
            onChange={(e) => hook.toggleAll(e.target.checked)}
          />
        </label>
        <b>Proveedor</b>
        <b>Factura</b>
        <b>Concepto</b>
        <b>Total</b>
        <b>Aplicado</b>
        <b>Importe egreso</b>
      </p>

      {hook.state.associations.map((association) =>
        association.isDeductible ? (
          <Deductible
            key={window.crypto.randomUUID()}
            association={association}
            onChange={(value) => hook.checkDeductible(value, association.id)}
            isCheck={hook.checkIfIsMarked(association.id, true)}
            currency={hook.state.movement.currency}
          />
        ) : (
          <NoDeductible
            key={window.crypto.randomUUID()}
            association={association}
            onChange={(value) => hook.checkNoDeductible(value, association.id)}
            isCheck={hook.checkIfIsMarked(association.id, false)}
            currency={hook.state.movement.currency}
          />
        )
      )}
    </>
  );
};
