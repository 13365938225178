import PayMethodsSearchable from "components/general/Forms/Selects/PayMethodsSearchable";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCreateEgress } from "..";

export default function PayMethodEgress() {
  const hook = useContext(ContextCreateEgress);

  return (
    <div>
      <Label >Método de pago</Label>

      {hook.idMovement === null ? (
        <PayMethodsSearchable label="" onChange={hook.updatePayMethod} />
      ) : (
        <p>{hook.payMethod?.description}</p>
      )}
    </div>
  );
}
