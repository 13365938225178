import useDialog from "customHooks/useDialog";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Dialog from "structure/Dialog";
import {
  Email,
  From,
  EmailAppend,
  Subject,
  Files,
  TextBoxEmail,
  EmailExtensions,
  FileFromPc,
  SendEmailBtn,
  CloudFileBtn,
} from "structure/Email";
import style from "structure/SendEmail/styles.module.scss";
import { Success } from "helpers/alerts";

import {
  GetDocument,
  downloadPdfDocumentV3,
  getDocumentEmailTemplate,
} from "helpers/Apis/Documents";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import { parseToFolio } from "helpers/documents";
import { fileArrayToFileList, xmlFileToJson } from "helpers/files";
import { retrieveAttachedAdresses } from "helpers/Apis/email";
import LoginContext from "context/Login/LoginContext";
import { GetCustomer } from "helpers/Apis/Directory";

const INITIAL_STATE = {
  subject: "",
  body: "",
  title: "",
  to: [],
  files: async () => {},
  cc: [],
};

/**
 *
 * @param {import("components/general/GenericEmailModal/types").PropsEmailDocument} props - Props
 * @returns
 */
export default function EmailDocumentsV2({
  forwardRef,
  children = null,
  validExtensions = ["png", "jpg", "jpeg", "pdf", "xml"],
  onSendedEmail = async () => {},
  idDocument = null,
  useInvoiceTemplate = false,
}) {
  const dialog = useDialog();

  const { userInfo } = useContext(LoginContext);

  const [document, setDocument] = useState(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const [emailConfig, setEmailConfig] = useState(INITIAL_STATE);

  const handleOpen = (isOpen) => {
    setIsOpen(isOpen);
    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
      setDocument(undefined);
      setEmailConfig(INITIAL_STATE);
    }
  };

  useEffect(() => {
    (async function () {
      if (!isOpen) {
        setEmailConfig(INITIAL_STATE);
        return;
      } else {
        dialog.open();
      }

      const [document, apiCc] = await Promise.all([
        GetDocument(idDocument),
        retrieveAttachedAdresses("cc", "facturaEmitida"),
      ]);

      const { body, subject } = await getDocumentEmailTemplate(
        document,
        useInvoiceTemplate
      );

      const dynamicTitle = useInvoiceTemplate
        ? "Enviar factura emitida"
        : `Enviar ${document.type.description.toLowerCase()}`;

      let title = `${dynamicTitle}`;

      const folio = await getDocNumber();

      title += ` ${folio}`

      const to =
        typeof document.contact.id === "number" ? [document.contact.email] : [document.customer.email];

      const functionToAttachInvoice = async () => {
        const [pdf, xml] = await Promise.all([
          downloadFileFromBlobV3(document.legal.pdf.id),
          downloadFileFromBlobV3(document.legal.xml.id),
        ]);

        const xmlContent = await xmlFileToJson(xml);

        const pdfFile = new File(
          [pdf],
          `FE-${parseToFolio(+xmlContent.$.Folio)}.pdf`,
          { type: "application/pdf" }
        );
        const xmlFile = new File(
          [xml],
          `FE-${parseToFolio(+xmlContent.$.Folio)}.xml`,
          { type: "application/xml" }
        );

        return fileArrayToFileList([pdfFile, xmlFile]);
      };

      const functionToAttachFiles = async () =>
        await downloadPdfDocumentV3(
          idDocument,
          `${document.type.description} ${parseToFolio(
            document.documentNumber
          )}`
        );

      const cbToAttachFile =
        document.type.id === 2 && useInvoiceTemplate
          ? functionToAttachInvoice
          : functionToAttachFiles;

      const dynamicCc = useInvoiceTemplate
        ? [...apiCc, userInfo[0].email,document.executive.email]
        : [userInfo[0].email,document.executive.email];

      setEmailConfig((current) => ({
        ...current,
        subject,
        body,
        title,
        to,
        files: cbToAttachFile,
        cc: dynamicCc,
      }));

      setDocument(document);

      //////////////////////////////////////////////////////////////////////////////

      async function getDocNumber(){
        if(document.type.id === 2 && useInvoiceTemplate){
          const xml = await downloadFileFromBlobV3(document.legal.xml.id);

          const sat = await xmlFileToJson(xml);

          return parseToFolio(+sat.$.Folio)
        }

        return document.docNumber
      }
    })();
  }, [isOpen, idDocument]);

  return (
    <>
      <div ref={forwardRef} onClick={() => handleOpen(true)}>
        {children}
      </div>

      {isOpen ? (
        <Email
          idDocument={idDocument}
          refetch={emailConfig}
          validExtensions={validExtensions}
          files={emailConfig.files}
          onSendedEmail={async () => {
            await onSendedEmail();
            handleOpen(false);
            Success(() => {}, "Correo enviado");
          }}
          cc={emailConfig.cc}
          message={emailConfig.body}
          subject={emailConfig.subject}
          to={emailConfig.to}
        >
          {({ addFiles }) => (
            <Dialog
              forwardRef={dialog.refDialog}
              title={emailConfig.title}
              width={40}
              closeCallback={() => handleOpen(false)}
              footer={
                <div className={style.footerEmail}>
                  <EmailExtensions />
                  <SendEmailBtn />
                </div>
              }
            >
              <div className="d-flex align-items-center">
                <From className="mr-3" />
                <FileFromPc className="mx-3" />
                <CloudFileBtn
                  idRegister={idDocument}
                  idTypeEntity={1}
                  onChange={({ fileList }) => {
                    addFiles(fileList);
                  }}
                />
              </div>

              {document !== undefined ? (
                <>
                  <EmailAppend aditionalEmails={emailConfig.to} type="to" idCustomer={document.customer.id} />
                  <EmailAppend
                    type="cc"
                    idCustomer={document.customer.id}
                    aditionalEmails={emailConfig.cc}
                  />
                </>
              ) : null}

              <Subject />
              <Files />
              <TextBoxEmail />
            </Dialog>
          )}
        </Email>
      ) : null}
    </>
  );
}
