import Select from "react-select";
import { getCfdiUse } from "helpers/Apis/Documents";
import React, { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Label } from "structure/Document";
import InformativeTooltip from "components/individual/InfoTooltip";
import { getParameters } from "helpers/Apis/parameters";

/**
 * Render a select list to chose the cfdi use
 * @param {import("./types").PropsCfdiUse} props - Props
 * @returns {JSX.Element}
 */
export const CfdiUse = ({
  cfdi = null,
  rfc = null,
  required = true,
  onChange = () => {},
  showTooltip = true,
  idCustomer = null,
}) => {
  const [state, setState] = useState({
    isLoading: true,
    cfdis: [],
    selected: null,
  });

  const handleChange = (cfdi) => {
    setState({
      ...state,
      selected: cfdi,
    });

    onChange({
      Moral: cfdi.Moral,
      Name: cfdi.Name,
      Natural: cfdi.Natural,
      Value: cfdi.Value,
    });
  };

  useEffect(() => {
    (async function () {
      const [apiCfdi, parameters] = await Promise.all([
        getCfdiUse(),
        getParameters(),
      ]);

      const parsedCfdi = apiCfdi.map((cfdiItem) => ({
        ...cfdiItem,
        value: cfdiItem.Value,
        label: cfdiItem.Name,
      }));

      const cfdiToSearch = rfc === "XAXX010101000" ? "S01" : cfdi;

      const selected =
        cfdi === null && rfc === null
          ? parsedCfdi.find(
              (cfdiItem) => cfdiItem.Value === parameters["31"].value
            )
          : parsedCfdi.find((cfdiItem) => cfdiItem.Value === cfdiToSearch) ||
            parsedCfdi.find(
              (cfdiItem) => cfdiItem.Value === parameters["31"].value
            );

      const groupedCfdis = parsedCfdi.reduce(
        (indexed, cfdi) => {
          if (cfdi.Moral) {
            return {
              ...indexed,
              moral: [cfdi, ...indexed.moral],
            };
          }

          return {
            ...indexed,
            fisica: [cfdi, ...indexed.fisica],
          };
        },
        {
          fisica: [],
          moral: [],
        }
      );

      setState({
        ...state,
        isLoading: false,
        selected,
        cfdis: [
          {
            label: "Persona moral",
            options: groupedCfdis.moral,
          },
          {
            label: "Persona física",
            options: groupedCfdis.fisica,
          },
        ],
      });

      if (selected === null) {
        onChange({
          Moral: null,
          Name: null,
          Natural: null,
          Value: null,
        });
      } else {
        onChange({
          Moral: selected?.Moral || null,
          Name: selected?.Name || null,
          Natural: selected?.Natural || null,
          Value: selected?.Value || null,
        });
      }
    })();
  }, []);

  if (state.isLoading)
    return <Spinner hidden={false} text="Cargando CFDI..." />;

  return (
    <>
      <Label required={required ? state.selected === null : false}>
        Uso de CFDI
        {rfc === "XAXX010101000" && showTooltip ? (
          <InformativeTooltip>
            <p>
              Para cambiar el uso de CFDI <b>TIENES</b> que usar un RFC
              diferente al genérico. Para más información visita:{" "}
            </p>
            <a
              target={"_blank"}
              href="https://facturama.mx/blog/que-significa/rfc-generico/#Como_registrar_el_RFC_Generico_en_el_CFDI_40"
            >
              ¿Cómo registrar el RFC Genérico en el CFDI 4.0?
            </a>

            {idCustomer !== null ? (
              <>
                <hr />
                <a
                  target={"_blank"}
                  href={`${process.env.REACT_APP_FRONTEND}directorio/editar/${idCustomer}`}
                >
                  IR A EDITAR EL RFC DEL CLIENTE,
                </a>
                <span>recarga esta página para reflejar los cambios</span>
              </>
            ) : null}
          </InformativeTooltip>
        ) : null}
      </Label>

      {rfc === "XAXX010101000" ? (
        <p>Sin efectos fiscales</p>
      ) : (
        <Select
          className="saiko-select"
          classNamePrefix="saiko-select"
          isClearable={false}
          isSearchable={true}
          options={state.cfdis}
          value={state.selected}
          placeholder="Selecciona"
          onChange={handleChange}
        />
      )}
    </>
  );
};
