import React, { useContext } from "react";
import { Step1Context } from ".";
import { Impuestos, LblImporte } from "../Filter/styles";

export const LabelUuuid = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>UUID</label>
      <p>{xmlInfo.extractedXml.uuid}</p>
    </div>
  );
};

export const LabelImpuestos = () => {
  const { xmlInfo } = useContext(Step1Context);

  return (
    <Impuestos>
      <div className="d-flex justify-content-around">
        <div className="d-flex flex-column">
          <span>{xmlInfo.extractedXml.impuestosTrasladados.formatted}</span>
        </div>

        <div className="d-flex flex-column">
          <span>{xmlInfo.extractedXml.impuestosRetenidos.formatted}</span>
        </div>
      </div>
    </Impuestos>
  );
};

export const LabelImpuestosTrasladados = () => {
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>Trasladados</label>
      <p>{xmlInfo.extractedXml.impuestosTrasladados.formatted}</p>
    </div>
  );
};

export const LabelDiscount = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <p>{xmlInfo.extractedXml.discount.formatted}</p>
    </div>
  );
};

export const LabelEmitedDate = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>Fecha de emisión</label>
      <p>{xmlInfo.extractedXml.emitedDate.ui}</p>
    </div>
  );
};
export const LabelCurrency = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>Moneda</label>
      <p>{xmlInfo.extractedXml.currency}</p>
    </div>
  );
};

export const LabelFolio = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>Folio</label>
      <p>{xmlInfo.extractedXml.folio}</p>
    </div>
  );
};

export const LabelSocialReason = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>Razon social</label>
      <p>{xmlInfo.extractedXml.socialReasonCustomer}</p>
    </div>
  );
};

export const LabelsAmounts = () => (
  <LblImporte>
    <div>
      <b>Importe</b>
    </div>
    <div>
      <b>Descuento</b>
    </div>
    <div className="d-flex flex-column">
      <p className="text-center">
        <b>Impuestos</b>
      </p>
      <div className="d-flex justify-content-around w-100">
        <p>
          <b>Trasladados</b>
        </p>
        <p>
          <b>Retenidos</b>
        </p>
      </div>
    </div>
    <div>
      <b>Total</b>
    </div>
  </LblImporte>
);

export const LabelRFCEmitter = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>RFC Emisor</label>
      <p>{xmlInfo.extractedXml.rfcEmitter}</p>
    </div>
  );
};
export const LabelRFCReceptor = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>RFC Receptor</label>
      <p>{xmlInfo.extractedXml.rfcReceptor}</p>
    </div>
  );
};

// -----------------------------------

export const LabelTotal = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <p>{xmlInfo.extractedXml.total.formatted}</p>
    </div>
  );
};

export const LabelIVA = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <p>{xmlInfo.extractedXml.iva.formatted}</p>
    </div>
  );
};

export const LabelImporte = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <p>{xmlInfo.extractedXml.importe.formatted}</p>
    </div>
  );
};

export const LabeRfcReceptor = () => {
  /**
   * @type {{
   *  xmlInfo:import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI
   * }}
   */
  const { xmlInfo } = useContext(Step1Context);
  return (
    <div>
      <label>RFC Receptor</label>
      <p>{xmlInfo.extractedXml.rfcReceptor}</p>
    </div>
  );
};
