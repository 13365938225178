import React from "react";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DocumentsButtons.scss";
import { Link } from "react-router-dom";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export const ReminderButton = ({
  funcion = () => {},
  id = "",
  customerId,
  idDocument,
}) => {
  return (
    <Link to={`/directorio/documentos/reminders/${customerId}/${idDocument}`} >
      <ButtonV2 id={id} className="w-100" >
        To Do
      </ButtonV2>
    </Link>
  );
};
