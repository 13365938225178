import React, { useState, useEffect } from "react";

// HEADER
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { breadAddEdit } from "../../../static/StaticBreadCrums";

// FORM
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fastAddSchema } from "./SchemaMovements";

// LIBRARIES
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// COMPONENTS
import QueueMovements from "./QueueMovements";
import {
  Spinner,
  triggerLoading,
  quitLoading,
} from "../../../components/individual/Spinner/Spinner";
import { InputsFastAdd } from "./Forms";
import { Submit, Button } from "../../../components/individual/buttons/Buttons";
import ErrorsAdd from "./ErrosAdd";

// CUSTOM HOOKS
import useMovements from "../../../customHooks/useMovements";

import { resetQue } from "../../../helpers/Banks/Movements/movements";

// API
import { saveFastAdd } from "../../../helpers/Apis/Banks";

// SCSS
import "./Movements.scss";

export function AddFastMove() {
  const { account } = useParams();

  const { generateQue } = useMovements();

  breadAddEdit.breadcrum[2].url = `/bancos/movimientos/${account}`;

  const [movements, setMovements] = useState([]);
  const [bankId, setBankId] = useState(+account);

  const redirect = useHistory();

  document.title = `Agregar movimiento`;

  useEffect(() => {
    document.getElementById("reference").focus();
  }, []);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(fastAddSchema),
  });

  const saveQueue = async () => {
    triggerLoading("spinnerSending", "btnSaveList");

    const querySuccess = await saveFastAdd(movements);

    quitLoading("btnSaveList", "spinnerSending");

    if (!querySuccess) {
      return;
    }

    redirect.push(`/bancos/movimientos/${account}`);
  };

  const addToQueue = (data) => {
    const reversedMovements = generateQue(data, bankId, movements, 1);

    resetQue();

    setMovements(reversedMovements);
  };

  return (
    <>
      <HomeHeader bredcrum={breadAddEdit} mostrar={false} />

      <div className="container">
        <h3>Formulario movimientos</h3>
        <form
          className="row d-flex align-items-end mb-2"
          onSubmit={handleSubmit(addToQueue)}
          id="fastAdd"
        >
          <InputsFastAdd register={register} errors={errors} />
          <Submit
            id="btnAddList"
            text="Agregar"
            aditionalClasses="fitBtn"
            cb={() => {
              document.getElementById("importConcept").innerText = "";
            }}
          />
        </form>

        <div className="row">
          <ErrorsAdd errors={errors} />
        </div>

        <hr></hr>

        <div className="row mt-4">
          <div className="col-12 queueContainer">
            <h3>Informacion en cola</h3>

            {movements.length > 0 ? (
              <QueueMovements
                movements={movements}
                setMovements={setMovements}
              />
            ) : null}
          </div>
        </div>

        <div className="row d-flex justify-content-end mt-5">
          {movements.length > 0 ? (
            <Button
              id="btnSaveList"
              text="Guardar"
              funcion={saveQueue}
              aditionalClasses=""
            />
          ) : null}

          <Spinner idSpinner="spinnerSending" text="Guardando movimientos..." />
        </div>
      </div>
    </>
  );
}
