import { CardContainer } from "components/individual/CardV2/styles";
import Icon from "components/individual/HeaderIcon";
import React, { createContext, useContext, useRef, useState } from "react";
import {
  InformationToDo,
  HeaderToDo,
  Action,
  ContainerNote,
  Title,
  Tag,
  SocialReason,
} from "../styles";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import History from "../History";
import Edit from "../Edit";
import Add from "structure/ToDo/Add";
import MoreInfo from "../MoreInfo";
import { IDS_TYPE_SECTION, updateStatusTodo } from "helpers/Apis/todo";
import { CardsToDoContext } from "..";
import DoneToggle from "../DoneToggle";
import AttendInvoice from "../AttendInvoice";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import MoreInfoDirectory from "structure/MoreInfoDirectory";
import MoreInfoContact from "pages/Directory/Contactos/V2/MoreInfoContact";

/**
 * Context of the card
 * @type {import("react").Context<import("../types").CardContextI|undefined>}
 */
export const CardContext = createContext(undefined);
const { Provider } = CardContext;

/**
 * Render a card with all the logic that has a card todo
 * @param {object} props - Props
 * @param {import("customHooks/useToDo/types").ToDosI} props.todo - Information of the To Do
 * @param {import("structure/ToDo/types").TypeToDo} props.type - Type of to do's gonna be fetched
 * @param {number} props.index - Index of the card on the list
 * @returns {JSX.Element} Card the todo information
 */
export default function CardToDo({ todo, type = "documentos", index }) {
  const idOpenAddChild = window.crypto.randomUUID();
  const idMoreInfo = window.crypto.randomUUID();
  const idAuthorizationModal = window.crypto.randomUUID();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInfoDocs = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInfoDirectory = useRef();
  const isGlobaType = !Object.keys(IDS_TYPE_SECTION).includes(type);

  const { updateDone } = useContext(CardsToDoContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const handleDirectDone = async () => {
    setIsUpdating(true);

    const wasUpdated = await updateStatusTodo(
      todo.id,
      todo.isOpen === true ? "done" : "undone"
    );

    if (wasUpdated) updateDone(index, !todo.isOpen);

    setIsUpdating(false);
  };

  const openHistory = () => {
    const dom = document.getElementById(idOpenAddChild);
    if (dom) dom.click();
  };

  return (
    <Provider
      value={{
        setIsUpdating,
        isUpdating,
        index,
        todo,
        idAuthorizationModal,
        handleDirectDone,
        openHistory,
      }}
    >
      <MoreInfo
        idOpenModal={idMoreInfo}
        idEntity={todo.fromId}
        type={isGlobaType === true ? todo.sectionId : type}
      />
      <Add
        id={todo.fromId}
        idEntity={todo.customerId || todo.socialReson || todo.fromId}
        parent={todo.parent}
        type={type}
        showButton={false}
        idOpenModal={idOpenAddChild}
        idToDo={todo.id}
        onAdd={() => updateDone()}
      />

      {todo.tag.id === -100 ? (
        <AttendInvoice
          idInvoice={todo.fromId}
          idTodo={todo.id}
          idOpenModal={idAuthorizationModal}
        />
      ) : null}

      <CardContainer>
        <HeaderToDo>
          <InformationToDo>
            <DoneToggle />

            <span>
              <Title>{todo.title}</Title>
              <SocialReason>{todo.socialReason}</SocialReason>
              <Tag>{todo.tag.description}</Tag>
            </span>

            <span>
              Creado:
              <time className="ml-2" dateTime={todo.created.fullTime}>
                {todo.created.format}
              </time>
            </span>

            <span>
              Atender para:
              <time className="ml-2" dateTime={todo.atentionDate}>
                {todo.atentionDate}
              </time>
            </span>
          </InformationToDo>

          <div className="d-flex justify-content-end">
            {todo.sectionId === 4 ? null : (
              <>
                {type === "documentos" || type === "autorizaciones" ? (
                  <ModalDocsRelatedV2
                    fromLegal={false}
                    idDocument={todo.fromId}
                    forwardRef={domMoreInfoDocs}
                  >
                    <Action onClick={() => domMoreInfoDocs.current.click()}>
                      <Icon icon="info" />
                    </Action>
                  </ModalDocsRelatedV2>
                ) : null}

                {type === "directorio" ? (
                  <MoreInfoDirectory
                    idCustomer={todo.fromId}
                    forwardRef={domMoreInfoDirectory}
                  >
                    <Action
                      onClick={() => domMoreInfoDirectory.current.click()}
                    >
                      <Icon icon="info" />
                    </Action>
                  </MoreInfoDirectory>
                ) : null}

                {type === "contactos" ? (
                  <MoreInfoContact id={todo.fromId}>
                    <Action>
                      <Icon icon="info" />
                    </Action>
                  </MoreInfoContact>
                ) : null}
              </>
            )}

            <History todo={todo}>
              <Action>
                <Icon icon="eye" />
              </Action>
            </History>

            {+todo.tag.id < 0 ? null : (
              <Edit todo={todo}>
                <Action>
                  <Icon icon="edit" />
                </Action>
              </Edit>
            )}
          </div>
        </HeaderToDo>
        <ContainerNote>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={todo.todoNote} />
        </ContainerNote>
      </CardContainer>
    </Provider>
  );
}
