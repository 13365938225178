export const ACTIONS = {
  SET_TO: "SET_TO",
  SET_CC: "SET_CC",
  SET_MESSAGE: "SET_MESSAGE",
  SET_FILES: "SET_FILES",
  SET_SUBJECT: "SET_SUBJECT",
  DELETE_TO: "DELETE_TO",
  DELETE_CC: "DELETE_CC",
  SET_LOADED_FILES: "SET_LOADED_FILES",
  SET_SENDING_EMAIL: "SET_SENDING_EMAIL",
  SET_INITIAL_STATE:"SET_INITIAL_STATE"
};
