import useDialog from "customHooks/useDialog";
import React, { useRef } from "react";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import { parseUtcToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { parseToFolio } from "helpers/documents";
import { ConfirmationStatusOcManagment } from "helpers/Apis/purchases/ocManagment";

/**
 * Render the more information about the oc managment
 * @param {import('./types').PropsMoreInfo} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfo({
  children = <></>,
  oc,
  forwardRef = undefined,
}) {
  const dialog = useDialog();

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div>
      <div onClick={(e) => dialog.open()} ref={forwardRef}>
        {children}
      </div>

      <Dialog
        title="Más información"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
      >
        <div>
          <h3>Proveedor</h3>
          <p>{oc.supplier}</p>
        </div>

        <div>
          <h3>Cliente</h3>
          <p>{oc.client}</p>
        </div>


        <hr />
        <h3 className="mt-3">Orden de compra</h3>
        <div className={ui.information}>
          <div>
            <b>No. ODC</b>
            <p>{parseToFolio(oc.documentNumber)}</p>
          </div>

          <div>
            <b>Fecha emisión</b>
            <p>{parseUtcToText(oc.emitedDate)}</p>
          </div>

          <div>
            <b>Fecha envío</b>
            <p>{parseUtcToText(oc.sendDate)}</p>
          </div>

          <div>
            <b>Fecha confirmación</b>
            <p>{parseUtcToText(oc.confirmationDate)}</p>
          </div>

          <div>
            <b>No. confirmación</b>
            <p>{oc.confirmationNumber||"ND"}</p>
          </div>

          <div>
            <b>Confirmación</b>
            <p>{ConfirmationStatusOcManagment[oc.confirmed]}</p>
          </div>

          <div>
            <b>Moneda</b>
            <p>{oc.odcCurrency}</p>
          </div>

          <div>
            <b>Importe</b>
            <p>{mnCurrency(oc.odcImport)}</p>
          </div>

          <div>
            <b>IVA</b>
            <p>{mnCurrency(oc.odcIva)}</p>
          </div>

          <div>
            <b>Total</b>
            <p>{mnCurrency(oc.odcTotal)}</p>
          </div>
        </div>

        <hr />
        <h3 className="mt-3">Factura</h3>
        <div className={ui.information}>
          <div>
            <b>No. factura</b>
            <p>{oc.invoiceNumber}</p>
          </div>

          <div>
            <b>Fecha recibida</b>
            <p>{parseUtcToText(oc.invoiceDate)}</p>
          </div>

          <div>
            <b>Moneda</b>
            <p>{oc.invoiceCurrency}</p>
          </div>

          <div>
            <b>Importe</b>
            <p>{mnCurrency(oc.invoiceImport)}</p>
          </div>

          <div>
            <b>IVA</b>
            <p>{mnCurrency(oc.invoiceIva)}</p>
          </div>

          <div>
            <b>Total</b>
            <p>{mnCurrency(oc.invoiceTotal)}</p>
          </div>
        </div>
        <hr />
        <h3 className=" mt-3">Contrato</h3>
        <div className={`${ui.information}`}>
          {/* <div>
            <b>Contrato fabricante</b>
            <p>{parseUtcToText(oc.cfSingDate)}</p>
          </div> */}

          <div>
            <b>Fecha firmada</b>
            <p>{parseUtcToText(oc.cfSingedDate)}</p>
          </div>

          <div>
            <b>Contrato institucional</b>
            <p>{parseUtcToText(oc.myContractSingedDate)}</p>
          </div>

          <div>
            <b>Fecha contrato fabricante</b>
            <p>{parseUtcToText(oc.cfSingDate)}</p>
          </div>

          {/* <div>
            <b>Cliente</b>
            <p>{oc.client}</p>
          </div> */}

          <div>
            <b>UEN</b>
            <p>
              {oc.uen.map((item, i) => (
                <span key={`${key}-${i}-${oc.documentNumber}`}>{item}</span>
              ))}
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
