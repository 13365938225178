import { Spinner } from "components/individual/Spinner/Spinner";
import { GetCustomer } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

/**
 * @type {import("types/typedef/customer").CustomerI}
 */
const INITIAL_DATA = undefined;

/**
 * @type {import("types/typedef/customer").CustomerI|null|undefined}
 */
const CORPORATIVE = undefined;

/**
 * Render the information of a customer
 * @param {object} props - Props
 * @param {number} props.idCustomer - Id of the customer to fetch his information
 * @param {(customer:import("types/typedef/customer").CustomerI)=>void} [props.onChange=()=>{}] - Information of the customer when the fetch request is done
 * @returns {JSX.Element} Render information of a customer
 */
export default function CustomerInfo({
  idCustomer = null,
  onChange = () => {},
}) {
  const [customer, setCustomer] = useState(INITIAL_DATA);
  const [corporative, setCorporative] = useState(CORPORATIVE);

  useEffect(() => {
    (async function () {
      if (idCustomer === null || idCustomer === undefined) return;

      const apiCustomer = await GetCustomer(idCustomer);
      setCustomer(apiCustomer);
      onChange(apiCustomer);

      if (apiCustomer.corporative.id !== null) {
        const apiCorporative = await GetCustomer(apiCustomer.corporative.id);
        setCorporative(apiCorporative);
      } else {
        setCorporative(null);
      }
    })();
  }, [idCustomer]);

  if (customer === undefined)
    return <Spinner hidden={false} text="Cargando cliente" />;

  return (
    <div className={styles.containerCard}>
      <div className={styles.infoCustomerContainer}>
        <div className="d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faAddressCard} size="6x" />
        </div>
        <div className={styles.infoCustomer}>
          <p className={styles.sr}>
            <b>Razon social</b>
            <span>{customer.socialReason}</span>
          </p>

          <p className={styles.type}>
            <b>Tipo</b>
            <span>{customer.type.description}</span>
          </p>

          <p className={styles.cn}>
            <b>Nombre comercial</b>
            <span>{customer.name.commercial}</span>
          </p>

          <p className={styles.sn}>
            <b>Nombre corto</b>
            <span>{customer.name.short}</span>
          </p>

          <p className={styles.id}>
            <b>ID {customer.type.description}</b>
            <span>{customer.id}</span>
          </p>

          <p className={styles.ex}>
            <b>Ejecutivo que lo atiende</b>
            <p>{customer.attendedBy.executive.fullName}</p>
            <p>{customer.attendedBy.executive.email}</p>
          </p>
        </div>
      </div>

      <hr />

      <h4>Datos fiscales</h4>
      <div className={styles.adressInfo}>
        <p className={styles.rfc}>
          <b>RFC</b>
          <span>{customer.rfc}</span>
        </p>

        <p className={styles.street}>
          <b>Calle</b>
          <span>{customer.adress.street}</span>
        </p>

        <p className={styles.noExt}>
          <b>No. exterior</b>
          <span>
            {customer.adress.extNumber === null
              ? "ND"
              : customer.adress.extNumber}
          </span>
        </p>

        <p className={styles.noInt}>
          <b>No. interior</b>
          <span>{customer.adress.intNumber}</span>
        </p>

        <p className={styles.cp}>
          <b>Código postal</b>
          <span>{customer.adress.cp}</span>
        </p>
        <p className={styles.colony}>
          <b>Colonia</b>
          <span>{customer.adress.colony}</span>
        </p>
        <p className={styles.city}>
          <b>Ciudad</b>
          <span>{customer.adress.city}</span>
        </p>

        <p className={styles.state}>
          <b>Estado</b>
          <span>{customer.adress.state}</span>
        </p>

        <p className={styles.country}>
          <b>País</b>
          <span>{customer.adress.country}</span>
        </p>

        <p className={styles.tel}>
          <b>Telefono fijo</b>
          <span>
            {customer.contact.phone.fullNumber === ""
              ? "ND"
              : customer.contact.phone.parsed}
          </span>
        </p>

        <p className={styles.cel}>
          <b>Celular</b>
          <span>
            {customer.contact.movil.fullNumber === ""
              ? "ND"
              : customer.contact.movil.parsed}
          </span>
        </p>

        <p className={styles.em}>
          <b>Email facturacion</b>
          <span>{customer.contact.email}</span>
        </p>

        {/* <p className={styles.country}>
      <b>Días de crédito</b>
      <span>{customer.adress.country}</span>
    </p> */}
      </div>

      {corporative === undefined ? (
        <Spinner hidden={false} text="Cargando corporativo..." />
      ) : corporative === null ? null : (
        <>
          <hr />
          <h4>Corporativo</h4>

          <div className={styles.infoCustomer}>
            <p className={styles.sr}>
              <b>Razon social</b>
              <span>{corporative.socialReason}</span>
            </p>

            <p className={styles.type}>
              <b>Tipo</b>
              <span>{corporative.type.description}</span>
            </p>

            <p className={styles.cn}>
              <b>Nombre comercial</b>
              <span>{corporative.name.commercial}</span>
            </p>

            <p className={styles.sn}>
              <b>Nombre corto</b>
              <span>{corporative.name.short}</span>
            </p>

            <p className={styles.id}>
              <b>ID {corporative.type.description}</b>
              <span>{corporative.id}</span>
            </p>

            <p className={styles.ex}>
              <b>Ejecutivo que lo atiende</b>
              <span>{corporative.attendedBy.executive.fullName}</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
}
