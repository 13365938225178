import * as yup from "yup";

// FOR CLIENT, SUPPLIER AND CLIENT-SUPPLIER
// IF THE SCHEMA HAS SOMETHING THAT IT'S NOT HERE
// ITS BECAUSE THAT 'CASE' HAS HIS OWN INPUTS
const socialReason = yup
  .string()
  .required("Campo obligatorio")
  .max(100, "Excedes los caracteres permitidos");
const commercialName = yup
  .string()
  .max(100, "Excedes los caracteres permitidos")
  .required("Campo obligatorio");
const shortName = yup
  .string()
  .max(50, "Excedes los caracteres permitidos")
  .required("Campo requerido");
const typeCustomer = yup
  .number("Escoge un tipo")
  .integer("Escoge un tipo")
  .required()
  .typeError("Escoge un tipo");

const executiveAttends = yup
  .number()
  .integer("Selecciona un ejecutivo")
  .positive()
  .required();

const customerRFC = yup.string().max(14, "Maximo 13 caracteres");

const streetCustomer = yup
  .string()
  .max(50, "Excedes los caraceteres permitidos")
  .nullable(true)
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .required("Campo obligatorio");

const exteriorNumber = yup
  .string()
  .max(10, "Excedes los caracteres permitidos")
  .nullable(false)
  .required("Campo obligatorio");

const insideNumber = yup
  .string()
  .max(10, "Excedes los caracteres permitidos")
  .nullable(true)
  .transform((casted, input) => (casted.length === 0 ? null : input));
// .required("Campo obligatorio");

const postalCode = yup
  .string()
  .trim()
  .matches(/^[0-9]*$/g, {
    message: "Solo números",
  })
  .typeError("Solo números")

  .required("Campo obligatorio");

const emailCustomer = yup
  .string()
  .max(256, "Excedes los caracteres permitidos")
  .email("Email invalido")
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(false)
  .required("Campo obligatorio")
  .typeError("Campo obligatorio");

const customerColony = yup
  .string()
  .max(256, "Excedes los caracteres permitidos")
  .nullable(true)
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .required("Campo obligatorio");

const customerCity = yup
  .string()
  .max(25, "Excedes los caracteres permitidos")
  .nullable(true)
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .required("Campo obligatorio");

const customerState = yup
  .string()
  .max(30, "Excedes los caracteres permitidos")
  .nullable(true)
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .required("Campo obligatorio");

const customerCountry = yup.string().max(100, "Maximo 100 digitos");
// .required("Campo obligatorio");

const creditDays = yup
  .number()
  .positive("Dias invalidos")
  .moreThan(0, "Debes otorgar mas de 0 dias")
  .required("Campo obligatorio")
  .typeError("Campo obligatorio")
  .integer("Dias invalidos");

const iva = yup
  .number()
  .positive("IVA Invalido")
  .required("Campo obligatorio")
  .typeError("Campo obligatorio");

const lada_phone = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const number_phone = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const lada_movil = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const number_movil = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

//SUPPLIER

/////////////////////// [MN BANKS] //////////////////////////////////////
const mnBank = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const mnNumberAccount = yup
  .string()
  .max(10, "Maximo 10 digitos")
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const mnNumberKeyCode = yup
  .string()
  .max(18, "La clave no es de 18 digitos")
  .typeError("Campo invalido")
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const mnNoAgreement = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

/////////////////////// [US BANKS] //////////////////////////////////////
const usdBank = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const usdNumberAccount = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const usNumberKeyCode = yup
  .string()
  .max(18, "La clave no es de 18 digitos")
  .typeError("Campo invalido")
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const usdNoAgreement = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

/////////////

const select_list_banks = yup
  .number()
  .positive()
  .typeError("Dato invalido")
  .nullable(true)
  .transform((castedByLibrary, inputValue) =>
    isNaN(castedByLibrary) ? null : +inputValue
  );

const referenceDeposit = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const idCorporative = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const value_statusCustomer = yup
  .string()
  .transform((casted, input) => (casted.length === 0 ? null : input))
  .nullable(true);

const customerID = yup
  .number()
  .positive()
  .typeError("Dato invalido")
  .nullable(true)
  .transform((castedByLibrary, inputValue) =>
    isNaN(castedByLibrary) ? null : +inputValue
  );

const PK_Executive_Associated = yup
  .number()
  .positive()
  .nullable(true)
  .typeError("Dato invalido")
  .default(null)
  .transform((castedByLibrary, inputValue) =>
    isNaN(castedByLibrary) ? null : +inputValue
  );

const idRelationship = yup
  .string()
  .max(256, "Máximo 256 caracteres")
  .min(1, "Mínimo 1 caracter")
  .nullable()
  .default(null)
  .typeError("La clave debe de ser texto");

const idSupplier = yup
  .number()
  .positive("El id del proveedor es inválido")
  .nullable()
  .default(null)
  .typeError("El proveedor es inválido")
  .transform((e, a) => {
    if (isNaN(e)) return null;
    return e;
  });

///////////////////////    [SCHEMAS]    /////////////////////////////

//////// CUSTOMERS

export const schemaAddClient = yup.object().shape({
  socialReason,
  commercialName,
  shortName,
  typeCustomer,
  executiveAttends,
  customerRFC,
  streetCustomer,
  exteriorNumber,
  insideNumber,
  postalCode,
  emailCustomer,
  customerColony,
  customerCity,
  customerState,
  customerCountry,
  creditDays,
  //iva,
  // referenceDeposit,
  lada_phone,
  number_phone,
  lada_movil,
  number_movil,
  idCorporative,

  mnBank,
  mnNumberAccount,
  mnNumberKeyCode,
  mnNoAgreement,

  usdBank,
  usdNumberAccount,
  usNumberKeyCode,
  usdNoAgreement,

  idRelationship,
  idSupplier,

  // customerID,
  // value_statusCustomer,
  // select_list_banks,
  // PK_Executive_Associated
});

///// SUPPLIER

export const schemeSupplier = yup.object().shape({
  socialReason,
  commercialName,
  shortName,
  typeCustomer,
  executiveAttends,
  customerRFC,
  streetCustomer,
  exteriorNumber,
  insideNumber,
  postalCode,
  emailCustomer,
  customerColony,
  customerCity,
  customerState,
  customerCountry,
  creditDays,
  // iva,

  lada_phone,
  number_phone,

  lada_movil,
  number_movil,

  idCorporative,

  mnBank,
  mnNumberAccount,
  mnNumberKeyCode,
  mnNoAgreement,

  usdBank,
  usdNumberAccount,
  usNumberKeyCode,
  usdNoAgreement,

  customerID,
  value_statusCustomer,

  PK_Executive_Associated,

  
  idRelationship,
  idSupplier,
});

export const schemeSupplierCustomer = yup.object().shape({
  socialReason,
  commercialName,
  shortName,
  typeCustomer,
  executiveAttends,
  customerRFC,
  streetCustomer,
  exteriorNumber,
  insideNumber,
  postalCode,
  emailCustomer,
  customerColony,
  customerCity,
  customerState,
  customerCountry,
  creditDays,
  // iva,
  referenceDeposit,
  lada_phone,
  number_phone,
  lada_movil,
  number_movil,
  idCorporative,

  customerID,
  value_statusCustomer,

  mnBank,
  mnNumberAccount,
  mnNumberKeyCode,
  mnNoAgreement,

  usdBank,
  usdNumberAccount,
  usNumberKeyCode,
  usdNoAgreement,
  PK_Executive_Associated,
});
