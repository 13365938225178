import React, { createContext } from "react";
import styles from "../index.module.scss";
import FooterPraxia from "components/general/Footer";
import style from "./styles.module.scss";
import ProfileShortcut from "./ProfileShortcut";
import useMenuNavigation, {
  INITIAL_VALUE_CONTEXT,
} from "customHooks/useMenuNavigation";
import ScreensHome from "./Screens/Home";
import SpecialProcess from "./Screens/SpecialProcess";
import AdmSystem from "./Screens/AdmSystem";
import ScreenBanks from "./Screens/Banks";
import ScreenBankReports from "./Screens/BankReports";
import Anticipated from "./Screens/Anticipated";
import Purchases from "./Screens/Purchases";
import ScreenSells from "./Screens/Sells";
import ScreenAccounting from "./Screens/Accounting";
import PurchaseReportMobile from './Screens/PurchasesReports'
import SellsReports from "./Screens/SellsReports";
import Setup from "./Screens/Setup";
import useBannerPraxia from "pages/ProcesosEspeciales/Parameters/BannerPraxia/useBannerPraxia";

export const MobileMenuContext = createContext(INITIAL_VALUE_CONTEXT);

export default function Mobile() {
  const hook = useMenuNavigation();
  const banner = useBannerPraxia();

  return (
    <MobileMenuContext.Provider value={hook}>
      <header className={styles.mobileMenu}>
        <img src={banner.bannerImage} alt="logo-system" />
        <p></p>
        <button onClick={hook.dialog.open} className={styles.menuMobileIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </header>

      <dialog ref={hook.dialog.refDialog}>
        <div className={style.menuContent}>
          <div className={styles.mobileMenu}>
          <img src={banner.bannerImage} alt="logo-system" />
            <p></p>
            <button
              onClick={hook.closeMenu}
              className={styles.menuMobileIcon}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className={style.containerMenuContent}>
            <ProfileShortcut />
            
            <div className={styles.menuMobileNavigation}>
              <AdmSystem/>
              <Anticipated/>
              <PurchaseReportMobile/>
              <Purchases/>
              <ScreenAccounting/>
              <ScreenBankReports/>
              <ScreenBanks/>
              <ScreenSells/>
              <ScreensHome/>
              <SellsReports/>
              <SpecialProcess/>
              <Setup/>
            </div>
          </div>
          <FooterPraxia />
        </div>
      </dialog>
    </MobileMenuContext.Provider>
  );
}
