import React, { useContext } from "react";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import { ContextGestionOc } from "../..";
import { parseToFolio } from "helpers/documents";
import LoginContext from "context/Login/LoginContext";
import ButtonV2 from "components/individual/ButtonsV2/Button";

/**
 * Render the email button
 * @param {import("./types").SendEmailOcProps} props - Props
 * @returns {JSX.Element}
 */
export default function SendEmailOc({
  children = null,
  forwardRef = undefined,
}) {
  const hook = useContext(ContextGestionOc);
  const { userInfo } = useContext(LoginContext);

  if (!hook.canSendEmail || hook.record===undefined || hook.record === null) return <></>;

  return (
    <EmailDocumentsV2
      forwardRef={forwardRef}
      idDocument={hook.record.idOdc}
      subject={`Praxia - Orden de compra ${parseToFolio(
        hook.record.documentNumber
      )}`}
      message={`Se le adjunta la informacion de la orden de compra ${parseToFolio(
        hook.record.documentNumber
      )} (pdf)`}
      cc={[userInfo[0].email]}
      onSendedEmail={hook.handleOcSent}
    >
      {children === null ? (
        <ButtonV2>
          <span>Enviar correo</span>          
        </ButtonV2>
      ) : (
        children
      )}
    </EmailDocumentsV2>
  );
}
