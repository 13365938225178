import axios from "axios";
import { Error } from "helpers/alerts";
import { URL_BASE } from "routes/routes";

/**
 * Get all the services/products that offers the enterprise
 * @returns {import("types/typedef/catalogue").CatalogueItemI[]} Services and products
 */
export async function getCatalogueEnterprise() {
  try {

    const { data } = await axios.get(`${URL_BASE}catalogo`);

    return data.catalogue;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return [];
  }
}
