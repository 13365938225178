import React, { useContext, useEffect, useState } from "react";

import { Select, ErrorMessage } from "../../../individual/inputs/Inputs";

import { fetchPayMethods } from "../../../../helpers/Apis/Banks";
import TableContext from "../../../../context/Table/TableContext";

function PayMethods({
  forwardRef = {},
  label = "Metodo de pago",
  id = "payMethods",
  name = "payMethods",
  errors = {},
  readonly = false,
  css = "",
}) {
  const { refetch, setReload } = useContext(TableContext);

  const [pay, setPayMethods] = useState([]);

  useEffect(() => {
    async function initialLoad() {
      const dataFetch = await fetchPayMethods();
      setPayMethods(dataFetch);
      setReload(!refetch);
    }

    initialLoad();
  }, []);

  return (
    <div className={`${css} itemDocument`}>
      <Select
        options={pay}
        addTodos={false}
        idSelect={id}
        nameSelect={name}
        label={label}
        textJSON="description"
        valueJSON="code"
        forwardRef={forwardRef}
        readonly={readonly}
      />

      <ErrorMessage message={errors[id]?.message} />
    </div>
  );
}

export default PayMethods;
