import { Modal } from "components/general/Modal";
import useModalDisassociateEgressConcept from "customHooks/useModalDisassociateEgressConcept";
import React from "react";
import { useRef } from "react";
import { createContext } from "react";
import { DisassociateExpenseConcept } from "structure/DisassociateExpenseConcept";
import { Disassociate } from "./ButtonDisassociate";

/**
 * @type {import("react").Context<import("./types").ContextDisassociateEgressConcept>}
 */
export const ContextModalDisassociateEgressConcept = createContext();
const { Provider } = ContextModalDisassociateEgressConcept;

/**
 * Render a modal in order to dis-associate egress with concepts
 * @param {import("./types").PropsOnDisassociateEgressConcept} props - Props
 * @returns {JSX.Element}
 */
export const ModalDisassociateEgressConcept = ({
  idMovement = 399,
  onDisassociated = () => {},
}) => {
  const hook = useModalDisassociateEgressConcept({
    idMovement,
    onDisassociated,
  });

  const idCloseModal = useRef(window.crypto.randomUUID());

  const close = () => {
    const dom = document.getElementById(idCloseModal);
    if (dom) dom.click();
  };

  return (
    <Provider value={{ ...hook, close }}>
      <Modal
        title="Des-asociar egreso"
        width={75}
        id={idCloseModal.current}
        propsOpenModal={{
          children: "Des-asociar",
        }}
        footer={<Disassociate />}
      >
        {({ closeModal }) => (
          <>
            <DisassociateExpenseConcept
              idMovement={idMovement}
              onChange={(value) =>
                hook.updateListDisassociate(
                  value.deductible,
                  value.noDeductible
                )
              }
            />
          </>
        )}
      </Modal>
    </Provider>
  );
};
