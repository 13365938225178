import { InputCurrency } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import useConcepts from "customHooks/useConcepts";
import { indexedToArray } from "helpers/lists";
import { calculateTcV2, mnCurrency } from "helpers/money";
import React, { createContext, useEffect, useState } from "react";
import { ContainerConcepts, Header, Row } from "./styles";
import TC from "./TC";

/**
 * Context to handle the row
 * @type {import("react").Context<import("types/typedef/components/NonDeductible").ContextI>}
 */
export const NonDeductibleContext = createContext();
const { Provider } = NonDeductibleContext;

/**
 * Render a grid to associate the non deductible amounts
 * @param {import("types/typedef/components/NonDeductible").ParamsI} params - Params of the component
 * @returns {JSX.Element} Component to associate the residue remaining as non deductible
 */
export default function NonDeductible({
  residue = 0,
  currency = "MXN",
  onChange = () => {},
  typeConcept = 1,
}) {
  const [state, setState] = useState({
    nonDeductible: {},
  });

  /**
   * Handle when a user types on the input of application
   * @param {import("types/typedef/components/NonDeductible").HandleApplicationI} application - Information of the application
   */
  // const handleApplication = ({ application, element }) => {
  //   const importe =
  //     element.currency.code === currency
  //       ? application
  //       : state.nonDeductible[element.id] === undefined
  //       ? 0
  //       : state.nonDeductible[element.id].importe.number;

  //   const tc = calculateTcV2(
  //     {
  //       fromCurrency: element.currency.code,
  //       toCurrency: currency,
  //     },
  //     importe,
  //     application
  //   );

  //   if (application > 0) {
  //     setState({
  //       ...state,
  //       nonDeductible: {
  //         ...state.nonDeductible,
  //         [element.id]: {
  //           ...element,
  //           tc: {
  //             text: mnCurrency(tc),
  //             number: tc,
  //           },
  //           importe: {
  //             number: importe,
  //             text: mnCurrency(importe),
  //           },
  //           applied: {
  //             number: application,
  //             text: mnCurrency(application),
  //           },
  //         },
  //       },
  //     });
  //     return;
  //   }

  //   let unreferenced = { ...state.nonDeductible };

  //   delete unreferenced[element.id];

  //   setState({
  //     ...state,
  //     nonDeductible: unreferenced,
  //   });
  // };

  /**
   * Delete the element from the list that must be sended to associate
   * @param {number} idElement - Id of the element
   */
  const deleteElement = (idElement) => {
    let unreferenced = { ...state.nonDeductible };

    delete unreferenced[idElement];

    setState({
      ...state,
      nonDeductible: unreferenced,
    });
  };

  /**
   * Calculate the import for the element
   * @param {number} application - "Pago por aplicar"
   * @param {number} tc - TC to use for the calculation
   * @param {"MXN"|"USD"} elementCurrency - Currency of the element
   * @returns {number} Importe calculated
   */
  const calculateImporte = (application, tc, elementCurrency) => {
    if (application === 0) return 0;

    if (elementCurrency === currency) {
      return application;
    }

    if (elementCurrency === "MXN" && currency === "USD") {
      return +(tc * application).toFixed(2);
    } else {
      return +(application / tc).toFixed(2);
    }
  };

  /**
   * Update the element to apply to that element on the grid
   * @param {number} application - Amount to apply
   * @param {import("types/typedef/customHooks/useConcepts").ConceptI} element - Information of the concept
   * @returns {void}
   */
  const updateToApply = (application, element, tc) => {
    if (application <= 0) {
      deleteElement(element.id);
      return;
    }

    const importe = calculateImporte(application, tc, element.currency.code);

    setState({
      ...state,
      nonDeductible: {
        ...state.nonDeductible,
        [element.id]: {
          ...element,
          applied: {
            number: application,
            text: mnCurrency(application),
          },
          importe: {
            number: importe,
            text: mnCurrency(importe),
          },
          tc: {
            number: tc,
            text: mnCurrency(tc),
          },
        },
      },
    });
  };

  /**
   * Update the element to apply to that element on the grid
   * @param {number} tc - Tc used
   * @param {import("types/typedef/customHooks/useConcepts").ConceptI} element - Information of the concept
   * @returns {void}
   */
  const updateTc = (tc, element) => {
    const importe = calculateImporte(
      element.applied.number,
      tc,
      element.currency.code
    );
    setState({
      ...state,
      nonDeductible: {
        ...state.nonDeductible,
        [element.id]: {
          ...element,
          importe: {
            number: importe,
            text: mnCurrency(importe),
          },
          tc: {
            number: tc,
            text: mnCurrency(tc),
          },
        },
      },
    });
  };

  /**
   * Update the importe of the concept/expense
   * @param {number} importe - New import to use for the concept
   * @param {number} idConcept - Id of the concept that will be updated
   */
  const updateImporte = (importe, idConcept) => {
    let unreferenced = { ...state.nonDeductible };

    const tc = calculateTcV2(
      {
        fromCurrency: state.nonDeductible[idConcept].currency.code,
        toCurrency: currency,
      },
      importe,
      state.nonDeductible[idConcept].applied.number
    );

    unreferenced[idConcept] = {
      ...unreferenced[idConcept],
      importe: {
        number: importe,
        text: mnCurrency(importe),
      },
      tc: {
        number: tc,
        text: mnCurrency(tc),
      },
    };

    setState({
      ...state,
      nonDeductible: unreferenced,
    });
  };

  useEffect(() => {
    /**
     * Information of the concept
     * @type {import("types/typedef/customHooks/useConcepts").ConceptI[]}
     */
    const nonDeductible = indexedToArray(state.nonDeductible);

    let total = 0;
    let remainingResidue = residue;
    let isValid = true;

    nonDeductible.forEach((deductible) => {
      total += deductible.importe.number;
      remainingResidue -= deductible.importe.number;

      if (deductible.importe.number <= 0) {
        isValid = false;
      }
    });

    if (total > residue || remainingResidue > 0) {
      isValid = false;
    }

    onChange({
      isValid,
      nonDeductible: state.nonDeductible,
      remainingResidue,
      applied: residue - remainingResidue,
    });
  }, [state.nonDeductible]);

  useEffect(() => {
    onChange({
      isValid: false,
      nonDeductible: {},
      remainingResidue: residue,
      applied: 0,
    });
  }, []);

  const { concepts, isLoading } = useConcepts(
    residue,
    currency,
    () => {},
    typeConcept
  );
  return (
    <>
      {isLoading ? (
        <Spinner
          hidden={false}
          text={`Cargando ${typeConcept === 1 ? "gastos" : "conceptos"}`}
        />
      ) : (
        <ContainerConcepts>
          <Header>
            <p>Concepto</p>
            <p>Moneda</p>
            <p>Pago a aplicar</p>
            {/* <p>Importe aplicado</p> */}
            <p>TC</p>
          </Header>
          {concepts.map((concept) => (
            <Provider
              value={{
                concept,
                currency,
                updateTc,
                updateImporte,
                nonDeductibleList: state.nonDeductible,
                isQuantityTyped: Object.keys(state.nonDeductible).includes(
                  `${concept.id}`
                ),
                // isQuantityTyped: false,
                isSameCurrency:
                  currency === concept.currency.code ? true : false,
              }}
            >
              <Row>
                <p>{concept.description}</p>
                <p className="text-center">{concept.currency.code}</p>
                <InputCurrency
                  labelTxt=""
                  aditionalClasses="mx-4"
                  placeholder="Escribe aqui"
                  // onChange={(value) => handleApplicationV2(value, concept)}
                  onChange={(value) =>
                    updateToApply(
                      value,
                      concept,
                      state.nonDeductible[concept.id] === undefined
                        ? concept.tc.number
                        : state.nonDeductible[concept.id].tc.number
                    )
                  }
                />
                {/* <Importe /> */}
                {/* <p>Aqui va el importe [desarrollo]</p> */}
                <TC />
                {/* <p>Aqui va el TC</p> */}
              </Row>
            </Provider>
          ))}
        </ContainerConcepts>
      )}
    </>
  );
}
