import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import React, { useContext, useRef } from "react";
import style from "./styles.module.scss";
import ModalAssociatedFiles from "../../ModalAssociatedFiles";
import useStartTicket from "pages/Tickets/components/startTicket/useStartTicket";
import { AsignApp } from "pages/Tickets/components/asign/asignApp";
import { useAsignTicket } from "pages/Tickets/hooks/useAsignTicket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { copyOnClipboard } from "helpers/dom";
import { URL_CLIENT } from "routes/routes";
import { CloseTicket } from "pages/Tickets/components/closeTicket/closeTicket";
import { RateTicket } from "pages/Tickets/components/rate/rateTicket";
import LoginContext from "context/Login/LoginContext";

/**
 * Render the options to show on the modal when mobile size
 * @param {import("./types").PropsMobileOptions} props - Props
 * @returns {JSX.Element}
 */
export default function MobileOptions({ ticket, onChange = () => {} }) {
  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const idAssing = useRef(window.crypto.randomUUID());
  const idAssociateFile = useRef(window.crypto.randomUUID());
  const idRate = useRef(window.crypto.randomUUID());

  const start = useStartTicket(
    ticket.id,
    ticket.assignedTo.id,
    ticket.startedAt
  );

  const openOption = (id) => document.getElementById(id)?.click();

  const assing = useAsignTicket(ticket.id, () => {}, ticket.assignedTo.id);

  return (
    <div className={style.titleMobile}>
      <h2>#{ticket.folio}</h2>

      <AsignApp
        idButton={idAssing.current}
        idTicket={ticket.id}
        assignedTo={ticket.assignedTo.id}
        onAssigned={onChange}
      />

      <ModalAssociatedFiles
        idRegister={ticket.id}
        idTypeEntity={4}
        pathUpload={`tickets/${ticket.id}`}
        id={idAssociateFile.current}
      />

      <RateTicket
        idTicket={ticket.id}
        onRatedTicket={onChange}
        status={ticket.status}
        id={idRate.current}
        children={''}
        createdBy={ticket.reported.by.id}
      />

      <ElipsisMobile>
        <CustomMenuItem>Salir</CustomMenuItem>

        {ticket.status === "inProgress" && (
          <CustomMenuItem>
            <CloseTicket
              isFinished={ticket.task.isFinished}
              idEngineer={ticket.assignedTo.id}
              idTicket={ticket.id || null}
              onClosedTicket={onChange}
              startedAt={ticket.startedAt}
            >
              Cerrar
            </CloseTicket>
          </CustomMenuItem>
        )}

        <CustomMenuItem
          onClick={() =>
            history.push(
              `/procesos-especiales/help-desk/tickets/busqueda?ticket=${ticket.folio}`
            )
          }
        >
          Ver
        </CustomMenuItem>

        <CustomMenuItem
          onClick={() =>
            copyOnClipboard(
              `${URL_CLIENT}procesos-especiales/help-desk/tickets/busqueda?ticket=${ticket.folio}`,
              true,
              `Ticket #${ticket.folio} copiado al portapapeles`
            )
          }
        >
          Copiar liga
        </CustomMenuItem>

        {start.canStartTicket ? (
          <CustomMenuItem disabled={start.isLoading} onClick={start.showModal}>
            Iniciar
          </CustomMenuItem>
        ) : null}

        {assing.canAssignTicket ? (
          <CustomMenuItem onClick={() => openOption(idAssing.current)}>
            Asignar
          </CustomMenuItem>
        ) : null}

        {ticket.status === "finished" && ticket.reported.by.id === userInfo[0].userID ? (
          <CustomMenuItem onClick={() => openOption(idRate.current)}>
            Calificar
          </CustomMenuItem>
        ) : null}

        <CustomMenuItem onClick={() => openOption(idAssociateFile.current)}>
          Asociar archivos
        </CustomMenuItem>
      </ElipsisMobile>
    </div>
  );
}
