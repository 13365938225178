import { InputToggle } from "components/individual/inputs/Inputs";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCreateEgress } from "..";

export default function ToggleEgress() {
  const hook = useContext(ContextCreateEgress);

  if (typeof hook.provider === "number") return <div></div>;

  if (hook.beneficiary.length <= 0) return <div></div>;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        <input type="radio" name="comprobante" checked={hook.includeExpenses} onClick={e=>hook.updateToggle(true)}/>
        <p className="m-0">Sin comprobante</p>
      </div>

      <div className="d-flex align-items-center">
        <input type="radio" name="comprobante" checked={!hook.includeExpenses} onClick={e=>hook.updateToggle(false)}/>
        <p className="m-0">Con comprobante</p>
      </div>
    </div>
  );

  return (
    <div>
      <Label>Con comprobante</Label>
      <InputToggle
        defaultChecked={hook.includeExpenses}
        labelTrue="Si"
        labelFalse="No"
        onChange={(value) => hook.updateToggle(value)}
      />
    </div>
  );
}
