import styled from "styled-components";

export const DatesContainer = styled.div`
  background-color: var(--cardBackground);
  border-radius: 10px;
  margin-bottom: 0.4rem;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0.8rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const DatesInputContainer = styled.div`
  font-size: 1rem;
  font-weight: bold;
  p{
    
    margin:0 5px 0 0;
    &:after{
      content:":";
    }
  }
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  gap:1rem 0;
`;

export const SwitchContract = styled.p`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ContractKeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items:center;
  width:100%;
`;

export const ClaveContainer = styled.p`
  width: 100%;
  text-align: end;
  margin: auto;
`;

export const LabelDate = styled.p`
  text-align: end;
  padding-right: 10px;
`;
