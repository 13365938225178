import React from "react";
import { HeaderComponent } from "./Components/HeaderComponent";
import { TabComponent } from "./Components/TabComponent";
import { DocumentContainer } from "./Containers";
import DocumentContext from "./ContextDocument";
import useDocument from "./Hooks/useDocument";
import useDocumentPraxia from "customHooks/useDocumentsPraxia";

const { Provider } = DocumentContext;

/**
 * Document Component
 * @param {import("../../../../../types/documentActions").DocumentComponent} props
 */
export const AddDocument = ({
  actionType,
  customerId,
  documentType,
  documentId,
  socialReason,
}) => {
  
  const { document, functions , domInfo } = useDocument(
    customerId,
    documentType,
    actionType,
    documentId
  );


  return (
    <Provider
      value={{
        ...document,
        ...functions,
        actionType,
        customerId,
        documentType,
        documentId,
        socialReason,
      }}
    >
      <pre className="d-none">
        <code ref={domInfo}>{JSON.stringify(document, null, 4)}</code>
      </pre>

      <DocumentContainer>
        <HeaderComponent />
        <TabComponent />
      </DocumentContainer>
    </Provider>
  );
};
