import React, { useContext } from "react";

import { RedirectButton } from "../../../../components/individual/buttons/Buttons";
import { useParams } from "react-router";

import { WinQuoteButton } from "./Buttons/WinQuoteButton";

import { ReminderButton } from "../../../../components/general/Documents/Buttons/ReminderButton";

import LoginContext from "context/Login/LoginContext";

import { CancelModal } from "../CancelModal";

import { EmailDocuments } from "components/individual/EmailDocuments";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import usePermissions from "customHooks/usePermissions";

import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import CancelInvoice from "structure/CancelInvoice";
import TableContext from "context/Table/TableContext";

/**
 * Quote options
 * @param {{rowInfo:object,socialReason:string,rfc:string}} props - Props
 * @param {import("types/typedef/documents").DocumentListInfoI} props.rowInfo - Information of the document

 * @returns {JSX.Element}
 */
function QuotesOptions({ rowInfo = null, socialReason, rfc }) {
  const { customer } = useParams();

  const { refetch, setReload } = useContext(TableContext);

  const { userInfo } = useContext(LoginContext);

  const { checkPermission } = usePermissions();

  if (!checkPermission("1633f3b8-953d-4bc5-8db4-9afa6e3e7e61")) return <></>;

  return (
    <>
      {checkPermission("dfa178da-d655-40ce-bfba-c36cfbadda3c") ? (
        <CancelModal rowInfo={rowInfo} />
      ) : null}

      <div
        style={{
          display: "flex",
          gap: "0.5rem",
        }}
      >
        {checkPermission("d9390bfd-4f3b-4f0b-a3dc-f8efcf058205") ? (
          <RedirectButton
            id="visualizeQuote"
            text="Ver documento"
            link={`./${customer}/ver/${rowInfo.idDocument}`}
          />
        ) : null}

        {userInfo.length > 0 &&
        checkPermission("94f0c2b1-03fd-4f8e-87eb-c46143385f9e") ? (
          <EmailDocuments
            idDocument={rowInfo.idDocument}
            subject={`Praxia - Cotizacion ${rowInfo.noQuote}`}
            message={`Se le adjunta la informacion de la cotizacíon ${rowInfo.noQuote} (pdf)`}
            cc={[userInfo[0].email]}
            files={async () =>
              downloadPdfDocumentV3(
                rowInfo.idDocument,
                `Cotizacion-${rowInfo.documentNumber}`
              )
            }
            // files={async () =>
            //   downloadPdfDocumentV2(rowInfo.idDocument, "extranet")
            // }
            to={[rowInfo.contactEmail]}
            title={`Enviar cotizacion ${rowInfo.noQuote}`}
            propsOpenModal={{
              children: "Enviar correo",
              // className: "mr-3",
            }}
          />
        ) : null}

        {checkPermission("edb51d0b-b71b-4c96-b04a-2fded3017abf") ? (
          <WinQuoteButton
            customer={customer}
            rowInfo={rowInfo}
            socialReason={socialReason}
            rfc={rfc}
          />
        ) : null}

        {checkPermission("384f293a-3371-42a3-beee-f7196010b4bf") ? (
          <RedirectButton
            id="associatedFilesInvoiceReception"
            text="Asociar archivos"
            link={`../../directorio/${rowInfo.idCustomer}/documentos/documentos/archivos-asociados/${rowInfo.idDocument}`}
          />
        ) : null}

        <ReminderButton
          id="reminderQuote"
          customerId={rowInfo.idCustomer}
          idDocument={rowInfo.idDocument}
        />

        {checkPermission("bca70338-63fa-400a-9c05-a70f4d012481") ? (
          <DonwloadPdfV2
            btnProps={{
              className: "mr-3",
            }}
            id={rowInfo.idDocument}
            fileName={`Cotizacion-${rowInfo.documentNumber}`}
          />
        ) : null}

        {rowInfo.idStatus === 1 &&
        checkPermission("3a505210-e0eb-4df8-b660-3a884b73460f") ? (
          <RedirectButton
            text="Editar"
            link={`/documentos/cotizacion/editar?idCliente=${customer}&documento=${rowInfo.idDocument}`}
          />
        ) : null}

        <CancelInvoice
          id={rowInfo.idDocument}
          status={rowInfo.DocumentStatus_id}
          onCancelated={() => setReload(!refetch)}
        />
      </div>
    </>
  );
}

export default QuotesOptions;
