import LoginContext from "context/Login/LoginContext";
import { getUserInfo } from "helpers/Apis/usuariosApi";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";

export const useBredCrum = () => {
  const { userToRepresentId } = useContext(LoginContext);
  const [represetedExecutive, setRepresetedExecutive] = useState(null);

  useEffect(() => {
    if (!!userToRepresentId) {
      (async () => {
        const { initials } = await getUserInfo(userToRepresentId);
        setRepresetedExecutive(initials);
      })();
    }
  }, [userToRepresentId]);

  useEffect(() => {
    if (!!represetedExecutive) {
      // console.log("Iniciales");
      // console.log(represetedExecutive);
    }
  }, [represetedExecutive]);

  return {
    represetedExecutive,
  };
};
