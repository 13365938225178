import React, { useContext, useEffect } from "react";

// COMPONENTS
import GameInput from "./Games/GameInput";
import Modal from "./Games/Modal";
import Game from "./Games/Game";

// LIBS
import { ReactSortable } from "react-sortablejs";
import { useParams } from "react-router";

import { trimDate } from "../../../../helpers/dates";

// CONTEXT
import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";

// APIS
import {
  fetchComments,
  fetchDocItems,
} from "../../../../helpers/Apis/Documents";

function Games() {
  const { games, setGames, setComments } = useContext(AddDocumentContext);
  const { action, documentId } = useParams();

  useEffect(() => {
    setComments([]);
    setGames([]);

    if (action == "editar") {
      initialLoad();
    }

    async function initialLoad() {
      const apiComments = await fetchComments(+documentId);
      setComments(apiComments);

      const apiDocItems = await fetchDocItems(+documentId);

      let parsed = apiDocItems.map((item) => ({
        ...item,
        totalImport: item.subTotal,
        subTotal: item.totalImport,
      }));
      setGames(parsed);
    }
  }, []);

  return (
    <div className="container">
      <GameInput />

      <Modal />

      <div className="queueContainer px-5 mt-4">
        <ReactSortable list={games} setList={setGames}>
          {games.map(
            (
              {
                currencyCode,
                value,
                code,
                idFront,
                description,
                quantity,
                price,
                discount,
                sellPrice,
                iva,
                totalImport,
                uenDescription,
                isNewItem,
                ivaPrice,
                subTotal,
                puVenta,
                beforeExchange,
              },
              i
            ) => {
              return (
                <Game
                  description={description}
                  quantity={quantity}
                  unitPrice={price}
                  discount={discount}
                  sellPrice={sellPrice}
                  iva={iva}
                  totalImport={totalImport}
                  uen={uenDescription}
                  id={idFront}
                  code={code}
                  value={value}
                  index={i}
                  isNewItem={isNewItem}
                  ivaPrice={ivaPrice}
                  subTotal={subTotal}
                  puVenta={puVenta}
                  beforeExchange={beforeExchange}
                  currencyCode={currencyCode}
                />
              );
            }
          )}
        </ReactSortable>
      </div>
    </div>
  );
}

export default Games;
