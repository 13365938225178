import ToggleComponent from "components/individual/inputs/Toggle";
import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCancelInvoice } from "..";
import style from './styles.module.scss';

export default function SwitchCancel() {
  const hook = useContext(ContextCancelInvoice);

  return (
    <div className={style.switchContainer}>
      <Label>¿Cancelar contrato?</Label>
      <ToggleComponent
        forwardRef={{}}
        disable={false}
        isChecked={hook.cancelInvoice}
        labelActive="Si, cancelar (El contrato quedará como terminado)"
        labelInactive="No, actualizar (Favor de llenar las nuevas fechas de renovacion para el contrato)"
        onChange={(value) => hook.updateSwitchCancelation(value)}
      />
    </div>
  );
}
