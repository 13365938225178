import { DefaultTable } from "js-smart-table/dist/pagination";
import React, { useEffect, useState } from "react";
import { CustomTable } from "../../../../components/general/Table/CustomTable";
import { Button } from "../../../../components/individual/buttons/Buttons";
import { Spinner } from "../../../../components/individual/Spinner/Spinner";
import {
  getBankAccount,
  GetConsolation,
  getListAssociate,
} from "../../../../helpers/Apis/Banks";
import TableConfig from "../../../../helpers/table-schema/Banks/Movements/AssociateCustomer";
import {
  SET_CLIENT,
  SET_STEP,
  SET_LOADING_PAGE,
  SET_INPUT_SEARCH_CUSTOMER,
  SET_BANK_INFO,
  SET_REDIRECT_STEP,
} from "../typesConsolation";
import { InputText } from "../../../../components/individual/inputs/Inputs";

// LIBRARIES
import { useParams } from "react-router";
import { GetCustomer } from "../../../../helpers/Apis/Directory";

export default function Step1({ setState, state }) {
  const [refetch, setRefetch] = useState(false);

  const { account } = useParams();

  let Customer;
  let config = {
    ...TableConfig,
    cbSelection,
    paginationFn: async (page, order, columnOrdering, aditionalQuery) => {
      setState({
        type: SET_LOADING_PAGE,
        payload: true,
      });

      setState({
        type: SET_CLIENT,
        payload: null,
      });

      aditionalQuery += Customer.tableConfiguration.stringQuery;

      const data = await getListAssociate(
        page,
        order,
        columnOrdering,
        `${aditionalQuery}&busqueda=${state.searchInputCustomer}`
      );
      setState({
        type: SET_CLIENT,
        payload: null,
      });

      setState({
        type: SET_LOADING_PAGE,
        payload: false,
      });
      return data;
    },
  };

  function cbSelection() {
    setState({
      type: SET_CLIENT,
      payload: Customer.infoRow,
    });
  }

  useEffect(() => {
    async function initialLoad() {
      if (state.movement === null || state.bankInfo === null) return;

      setState({
        type: SET_CLIENT,
        payload: null,
      });

      setState({
        type: SET_LOADING_PAGE,
        payload: true,
      });

      if (state.movement.customerAssociated !== null) {
        const customer = await GetCustomer(state.movement.customerAssociated);

        setState({
          type: SET_REDIRECT_STEP,
          payload: {
            ID: customer.id,
            Razon_social: customer.socialReason,
            Nombre_comercial: customer.name.commercial,
            Nombre_corto: customer.name.short,
          },
        });

        return;
      }

      const typeClient = state.movement.movementType === (1 || 5) ? 1 : 2;

      const data = await getListAssociate(
        1,
        "DESC",
        "customerID",
        `&tipoCliente=${typeClient}&busqueda=${state.searchInputCustomer}`
      );

      config = {
        ...config,
        pages: data.pages,
        actualPage: data.actualPage,
        rows: data.listAssociate,
        urlParams: {
          tipoCliente: typeClient,
        },
      };

      setState({
        type: SET_LOADING_PAGE,
        payload: false,
      });

      console.log(config);
      Customer = new DefaultTable(config);
      Customer.printTable();
      Customer.printPagination();
    }
    initialLoad();
  }, [state.movement, refetch, state.bankInfo]);

  useEffect(() => {
    (async function () {
      const apiBank = await getBankAccount(account);

      setState({
        type: SET_BANK_INFO,
        payload: apiBank,
      });
    })();
  }, []);

  if (!state.movement)
    return (
      <div className="d-flex align-items-center justify-content-center queueContainer">
        <Spinner hidden={false} text="Cargando clientes..." />
      </div>
    );

  if (state.loadingPage)
    return (
      <div className="d-flex align-items-center justify-content-center queueContainer">
        <Spinner hidden={false} text="Cargando clientes..." />
      </div>
    );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-3">
        <div className="d-flex align-items-end">
          <InputText
            id="customerSearchField"
            labelTxt={
              state.movement.movementType === 1 ? "Cliente" : "Proveedor"
            }
            defaultValue={
              state.searchInputCustomer === "null"
                ? ""
                : state.searchInputCustomer
            }
            placeholderTxt="Escribe aqui"
            aditionalClasses="mr-4"
            onChange={(value) =>
              setState({
                type: SET_INPUT_SEARCH_CUSTOMER,
                payload: value,
              })
            }
            readonly={false}
          />

          <Button
            funcion={() => setRefetch(!refetch)}
            text="Buscar"
            id="searchByInput"
          />
        </div>

        {state.client === null ? null : (
          <Button
            funcion={() =>
              setState({
                type: SET_STEP,
                payload: 2,
              })
            }
            id="toStep2"
            text="Continuar"
          />
        )}
      </div>
      <CustomTable
        paginationID="pagination-associate-customer"
        tableID="associate-customer"
      />
    </>
  );
}
