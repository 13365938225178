import React, { useContext } from "react";
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { CompleateOptions } from "../../../components/general/SearchOptions/CompleateOptions";
import {
  ExportExcelButton,
} from "../../../components/individual/buttons/Buttons";
import PermissionsContext from "../../../context/Login/LoginContext";
import {
  evaluatePermission,
  textPermission,
} from "../../../helpers/permissions";
import { FacturasNavigation } from "../../../static/StaticBreadCrums";

export const Facturas = () => {
  const { rolPermitions } = useContext(PermissionsContext);

  function search(e) {
    e.preventDefault();
    console.log("Funcion para hacer el filtro");
  }

  return (
    <>
      <HomeHeader mostrar={false} bredcrum={FacturasNavigation} />
      <div className="container">
        <div className="row d-flex">
          <CompleateOptions
            permitionID={85}
            search={search}
            inputEjecutivoID="facturasEjecutivo"
            inputCurrencyID="facturasCurrency"
            inputRangeID="facturasRange"
            buttonSearchID="facturasBtnSearch"
          />
          {/* Aqui debe de ir la tabla */}

          {evaluatePermission([84], rolPermitions) ? (
            <ExportExcelButton text={textPermission(84, rolPermitions)} />
          ) : null}
        </div>
      </div>
    </>
  );
};
