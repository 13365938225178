import { Select } from "components/individual/inputs/Inputs";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Label } from "structure/Document";
import { ContextCreateInvoiceV2 } from "..";

export default function Partialities() {
  const [optionsState, setOptions] = useState([]);
  const hook = useContext(ContextCreateInvoiceV2);

  useEffect(() => {
    const options = [];

    for (let i = 0; i < 12; i++) {
      options.push({
        value: i + 1,
        label: i + 1,
      });
    }

    setOptions(options);
  }, []);

  return (
    <div>
      <Label>Parcialidades</Label>
      <Select
        addTodos={false}
        options={optionsState}
        textJSON="label"
        valueJSON="value"
        value={hook.partialities}
        onChange={(value) => hook.updatePartialities(+value)}
      />
    </div>
  );
}
