import React, { useContext } from "react";
import Executives from "components/individual/Executives";
import filter from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import { CuotasContext } from "..";
import Abc from "../Abc";
import ButtonUpdate from "./ButtonUpdate";
import AvailableYears from "../AvailableYears";
import TypeFee from "../TypeFee";
import TypeUnit from "../TypeUnit";
import FeeCurrency from "../FeeCurrency";
import { useState } from "react";

export default function FilterCuotas() {
  const hook = useContext(CuotasContext);

  const [refetchYear, setRefetchYear] = useState(false);

  return (
    <div className={filter.filter}>
      <form className={filter.container} onSubmit={(e) => e.preventDefault()}>
        <AvailableYears
          onChange={hook.setYear}
          refetch={refetchYear}
          value={typeof hook.year === "number" ? hook.year : null}
        />

        <Executives
          required={hook.isRequiredExecutive}
          onChange={hook.setExecutive}
          onlyActives={true}
          value={hook.executive?.value || null}
        />

        <TypeFee />

        <FeeCurrency />

        <TypeUnit />

        {typeof hook.year === "number" && hook.executive !== undefined ? (
          <button className={filter.cancel} onClick={hook.resetComponent}>
            <Icon icon="cancel" />
          </button>
        ) : (
          <Abc onSuccess={() => setRefetchYear(!refetchYear)} />
        )}
      </form>
      <ButtonUpdate />
    </div>
  );
}
