import styled from "styled-components";

const bgType = {
  default: "#000",
  danger: "#C30000",
};

const colorType = {
  default: "#fff",
  danger: "#fff",
};

const padding = `

@media only screen and (min-width: 768px) {
  padding: 0 1.25rem;

  
}

@media only screen and (min-width: 1200px) {
  padding: 0 1.5rem;
  
}

@media only screen and (min-width: 1600px) {
  padding: 0 2rem;
}


`;

export const FileButton = styled.label`
  border-radius: 0.6rem !important;
  height: 3.125rem;
  padding: 0 2rem;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  transition: ease-out 0.3s;
  outline: none;
  background-color: ${({ theme }) => bgType[theme]};
  color: ${({ theme }) => colorType[theme]};
  border: 2px solid ${({ theme }) => bgType[theme]};
  &:hover {
    background-color: white;
    color: ${({ theme }) => bgType[theme]};
  }
  display: flex;
  align-items: center;
  justify-content: center;

  ${padding}

  @media (prefers-color-scheme: light) {

    &:hover{
      border:2px solid var(--primaryColor);
    }

    svg{
      color:white !important;
    }
  }

  @media (prefers-color-scheme: dark) {
    border: 0.15rem solid var(--primaryBackground);
    background: var(--primaryBackground);
    svg{
      color:var(--primaryColor) !important;
    }
    &:hover {
      color: var(--primaryColor);
      border-color: var(--primaryColor);
      background: transparent;
    }

    &:disabled {
      opacity: 0.65;
    }
  }
`;

export const Button = styled.button`
  border-radius: 0.6rem !important;
  height: 3.125rem;
  padding: 0 2rem;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  transition: ease-out 0.3s;
  outline: none;
  border:2px solid transparent;
  &:disabled {
    cursor: not-allowed;
    opacity:0.65;
  }
  background-color: ${({ theme }) => theme !== "default" ? bgType[theme] : `var(--primaryColor)` };
  color: ${({ theme }) => colorType[theme]};

  &:hover {
    background-color: white;
    color: ${({ theme }) => bgType[theme]};
  }

  svg{
    color:var(--primaryColor);
    display:none;
  }

  ${padding};

  @media (prefers-color-scheme: light) {

    &:hover{
      border-color:${({ theme }) => theme !== "default" ? bgType[theme] : `var(--primaryColor)` }
    }

    svg{
      color:white !important;
    }


  }

  @media (prefers-color-scheme: dark) {
    border: 0.15rem solid var(--primaryBackground);
    background: var(--primaryBackground);
    svg{
      color:var(--primaryColor) !important;
    }
    &:hover {
      color: var(--primaryColor);
      border-color: var(--primaryColor);
      background: transparent;
    }

    &:disabled {
      opacity: 0.65;
    }
  }

  @media (max-width: 767px) {
    span{
      display:none;
    }

    svg{
      display:inline-block;
    }
  }
  
`;

export const ButtonIcon = styled.button`
  width: 50px;
  background: none;
  border: none;
  border-radius: 0.6rem !important;
  transition: background 0.5s ease-in;
  &:hover {
    background-color: ${({ theme }) => colorType[theme]};
    color: ${({ theme }) => bgType[theme]};
  }

  &:hover {
    background-color: #e5e5e5;
  }

  ${padding}
`;
