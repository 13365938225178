import { getContactsReport } from "helpers/Apis/contactInfoApi";
import { parseDateToText } from "helpers/dates";
import { useState } from "react";
import { utils, writeFile } from "xlsx";

/**
 * @type {import("./types").StateContactsReport}
 */
const INITIAL_STATE = {
  socialReason: null,
  customerType: null,
  contactName: null,
  status: null,
  isLoading: false,
};

/**
 *
 * @param {import("./types").ParamUseContactReport} param
 */
export default function useContactsReport({}) {
  const [state, setState] = useState(INITIAL_STATE);

  async function fetchReport() {
    setState((current) => ({
      ...current,
      isLoading: true,
    }));

    const report = await getContactsReport({
      contactName: state.contactName,
      customerType: state.customerType,
      socialReason: state.socialReason,
      status: state.status,
    });

    setState((current) => ({
      ...current,
      isLoading: false,
    }));

    return report;
  }

  async function downloadAsExcel() {
    const report = await fetchReport();

    const parsedJson = report.map((customer) => ({
      Tipo: customer.type.description,
      "Razón social": customer.socialReason,
      "Nombre comercial": customer.commercialName,
      "Sector de mercado": customer.sectorMarket.description,
      Ejecutivo: customer.executives[0].fullname,
      Teléfono: customer.phone.fullNumber,
      Móvil: customer.movil.fullNumber,
      Email: {
        t: "s",
        v: customer.email,
        l: { Target: `mailto:${customer.email}` },
      },
      Estatus: customer.isActive ? "Activo" : "Inactivo",
      "Nombre contacto": customer.contact.fullname,
      "Teléfono contacto": customer.contact.phone.fullNumber,
      "Móvil contacto": customer.contact.movil.fullNumber,
      "Email contacto": {
        t: "s",
        v: customer.contact.email,
        l: { Target: `mailto:${customer.contact.email}` },
      },
      Puesto: customer.contact.position,
      "Estatus contacto": customer.contact.isActive ? "Activo" : "Inactivo",
    }));

    const headers = Object.keys(parsedJson[0]);

    const worksheet = utils.json_to_sheet(parsedJson, {
      header: headers,
    });

    // Apply header styles
    headers.forEach((header, index) => {
      const cellAddress = String.fromCharCode(65 + index) + "1"; // Get address of each cell in the first row
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
          alignment: { horizontal: "center" },
        };
      }
    });

    // Apply data row styles
    const range = utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = utils.encode_cell({ r: R, c: C });
        if (worksheet[address]) {
          worksheet[address].s = {
            font: { color: { rgb: "000000" } },
            alignment: { horizontal: "center" },
          };
        }
      }
    }

    // Create a new workbook
    const workbook = utils.book_new();

    // Append the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, "Contactos");

    // Set autofilter
    worksheet["!autofilter"] = { ref: utils.encode_range(range) };

    // Write the workbook to a file
    writeFile(
      workbook,
      `Reporte Contactos ${parseDateToText(new Date())}.xlsx`
    );
  }

  return {
    ...state,
    downloadAsExcel,
  };
}
