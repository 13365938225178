import React, { useContext } from "react";
import SearchButton from "./SearchButton";
import Dates from "./Dates";
import ui from "./styles.module.scss";
import SearchBar from "./SearchBar";
import ProviderSelect from "./ProviderSelect";
import TypeFilter from "./TypeFilter";
import { ContextLogs } from "../LogEmail";

export default function Filter() {

  const hook = useContext(ContextLogs);

  return (
    <form className={ui.filter} onSubmit={e=>{
      e.preventDefault();
      hook.refetchLog()
    }}>
      <ProviderSelect />
      <SearchBar />
      <TypeFilter />
      <Dates />
      <SearchButton />
    </form>
  );
}
