import React from "react";
import { DateContainers, DateLabel } from "../Containers";
import { InputDatePicker } from "components/Elements/InputDatePicker";
import { useContext } from "react";
import WinQuoteContext from "../../ContextWinQuote";
import { formateDate } from "helpers/dates";

import "./styles.css";

export const QuoteDateSection = () => {
  const isDisable = true;
  const { documents } = useContext(WinQuoteContext);

  return (
    <>
      <DateContainers>
        <DateLabel>Creación</DateLabel>
        <input
          type="text"
          className="disable"
          value={formateDate(documents.quote.dates.createdDate)}
          disabled
        />
      </DateContainers>

      <DateContainers>
        <DateLabel>Vigencia</DateLabel>
        <input
          type="text"
          className="disable"
          value={formateDate(documents.quote.dates.expirationDate)}
          disabled
        />
      </DateContainers>
      <DateContainers>
        <DateLabel>Recordatorio</DateLabel>
        <input
          type="text"
          className="disable"
          value={formateDate(documents.quote.dates.reminderDate)}
          disabled
        />
      </DateContainers>
    </>
  );
};
