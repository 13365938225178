import { yupResolver } from "@hookform/resolvers/yup";
import { getEnterpriseInfo, updateEnterpriseInfo } from "helpers/Apis/setUp";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import enterpriseValidation from "./validations";

/**
 * @type {import("./types").StateUseEnterpriseInfo}
 */
export const INITIAL_STATE = {
  enterpriseName: "ND",
  street: "ND",
  noInterior: "ND",
  noExterior:"",
  city: "ND",
  estado: "ND",
  postalCode: "99999",
  email: "nd@correo.com",
  isLoading: true,
  isUpdating: false,
  rfc:{
    country:"Mexico",
    isValid:false,
    isValidCountry:false,
    isValidRfc:false,
    rfc:""
  },
  robot1:true,
  robot2:true,
  robot3:true,
  robot4:true,
  robot5:true,
  robot6:true,
  robot7:true,
  availableFolios:0,
  fiscalRegimen:""
};

export default function useEnterpriseInfo() {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("./types").HookFormData}
   */
  const form = useForm({
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode:'all',
    resolver: yupResolver(enterpriseValidation),
  });

  useEffect(() => {
    (async function () {
      const enterpriseInfo = await getEnterpriseInfo();

      form.reset({
        enterpriseName: enterpriseInfo.enterpriseName,
        street: enterpriseInfo.street,
        noInterior: enterpriseInfo.noInterior,
        city: enterpriseInfo.city,
        estado: enterpriseInfo.estado,
        postalCode: enterpriseInfo.postalCode,
        email: enterpriseInfo.email,
        noExterior:enterpriseInfo.noExterior
      });

      setState((current) => ({
        ...current,
        ...enterpriseInfo,
        isLoading: false,
        rfc:{
          country:"",
          isValid:true,
          isValidCountry:false,
          isValidRfc:true,
          rfc:enterpriseInfo.rfc
        }
      }));
    })();
  }, []);

  const setUpdating = (isUpdating) =>
    setState((current) => ({
      ...current,
      isUpdating,
    }));

  /**
   * 
   * @param {number} robot - Number of robot to update
   * @param {boolean} value 
   * @returns 
   */
  const setRobot = (robot,value) => setState(current=>({
    ...current,
    [`robot${robot}`]:value
  }));

  /**
   * Update the rfc for the enterprise
   * @param {import("customHooks/useRfc/types").OnChangeRfc} rfc - Information of the rfc typed on the input
   * @returns {void}
   */
  const updateRfc = rfc => setState(current=>({
    ...current,
    rfc
  }));

    /**
     * Update the information
     * @param {import("./types").SubmitHandlerEnterprise} data - Information in order to update
     */
  async function attemptUpdateEnterpriseInfo(data) {
    setUpdating(true);

    await updateEnterpriseInfo({...data,rfc:state.rfc.rfc});

    setUpdating(false);
  }

  function printError(error) {
    console.log(error);
  }

  return {
    ...state,
    attemptUpdateEnterpriseInfo,
    printError,
    form,
    updateRfc,
    setRobot,
    state
  };
}
