import React, { Fragment, useContext, useRef } from "react";
import { ContextCreditNoteAccounting } from "..";
import ui from "./styles.module.scss";
import { parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Pagination } from "components/individual/Pagination";

export default function Table() {
  const hook = useContext(ContextCreditNoteAccounting);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (hook.isSearching)
    return (
      <div className="my-5">
        <Spinner hidden={false} text={"Buscando"} />
      </div>
    );

  if (hook.creditNotes.length <= 0)
    return <h1 className="text-center">No hay notas de crédito</h1>;

  return (
    <div className={ui.table}>
      <div className={ui.header}>
        <p>No</p>
        <p>Fecha de emisión</p>
        <p>Razón social</p>
        <p>Moneda</p>
        <p>Importe</p>
        <p>IVA</p>
        <p>Total</p>
        <p>Contabilizado</p>
      </div>

      {hook.creditNotes.map((cn, i) => (
        <Fragment key={`${key.current}-${i}`}>
          <input
            onChange={(e) => hook.setCreditNote(cn)}
            type="radio"
            name={key.current}
            className="d-none"
            id={`${key.current}-${i}`}
          />
          <label className={ui.row} htmlFor={`${key.current}-${i}`}>
            <p>{cn.documentNumber}</p>
            <p>{parseDateToText(cn.emited)}</p>
            <p>{cn.customer.socialReason}</p>
            <p>{cn.currency}</p>
            <p>{mnCurrency(cn.import)}</p>
            <p>{mnCurrency(cn.iva)}</p>
            <p>{cn.total.text}</p>
            <label>
              <input
                type="checkbox"
                checked={hook.gridAccounting.operation[cn.id]}
                onChange={(e) => hook.toggleAccounting(cn.id, e.target.checked)}
              />
            </label>
          </label>
        </Fragment>
      ))}

      <Pagination
        actualPage={hook.currentPage}
        pages={hook.pages}
        onChange={(page) => hook.updatePage(page)}
      />
    </div>
  );
}
