import React, { useContext } from "react";
import { ContextTickets } from "..";
import ui from "./styles.module.scss";
import ModalAssociatedFiles from "components/general/ModalAssociatedFiles";
import { AsignApp } from "../components/asign/asignApp";
import { StartTicket } from "../components/startTicket/startTicket";
import ReturnTicket from "../components/ReturnTicket";
import { CloseTicket } from "../components/closeTicket/closeTicket";
import { RateTicket } from "../components/rate/rateTicket";

export default function Options() {
  const hook = useContext(ContextTickets);

  if (hook.state.ticket === null || hook.state.ticket === undefined)
    return <div className={ui.containerOptions}></div>;

  return (
    <div className={ui.containerOptions}>
      <ModalAssociatedFiles
        idRegister={hook.state.ticket.id}
        idTypeEntity={4}
        pathUpload={`tickets/${hook.state.ticket.id}`}
      />

      <AsignApp
        idTicket={hook.state.ticket.id}
        onAssigned={hook.refetchTickets}
        assignedTo={hook.state.ticket.assignedTo.id}
      />

      <StartTicket
        startedAt={hook.state.ticket.startedAt}
        idTicket={hook.state.ticket.id}
        idEngineer={hook.state.ticket.assignedTo.id}
        onStartedTicket={() => hook.refetchTickets()}
      />

      <ReturnTicket
        ticket={hook.state.ticket}
        onUpdated={hook.refetchTickets}
      />

      <CloseTicket
        isFinished={hook.state.ticket.task.isFinished}
        idEngineer={hook.state.ticket.assignedTo.id}
        idTicket={hook.state.ticket.id}
        onClosedTicket={hook.refetchTickets}
        startedAt={hook.state.ticket.startedAt}
      />

      <RateTicket
        idTicket={hook.state.ticket.id}
        onRatedTicket={hook.refetchTickets}
        status={hook.state.ticket.status}
        createdBy={hook.state.ticket.reported.by.id}
      />
    </div>
  );
}
