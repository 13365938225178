import React, { useContext } from "react";
import { ContextRols } from "../Roles";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Pagination } from "components/individual/Pagination";
import MoreInformation from "./MoreInformation";
import { useRef } from "react";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";

export default function Table() {
  const hook = useContext(ContextRols);

  const key = useRef(window.crypto.randomUUID());

  const openEdit = () => {
    const domEdit = document.getElementById(hook.idOpenEdit);

    if(domEdit) domEdit.click();
  }

  if (hook.isLoading)
    return (
      <Spinner hidden={false} idSpinner="loadingRolsSpinner" text="Cargando" />
    );

  if (hook.search !== "" && hook.rols.length <= 0)
    return (
      <h1 className="text-center">
        No hay resultados con la búsqueda "{hook.search}"
      </h1>
    );

  if (hook.rols.length <= 0)
    return <h1 className="text-center">Agrega un rol, no hay</h1>;

  return (
    <div className="rolTable">
      <div className="header">
        <p>ID</p>
        <p>Rol</p>
      </div>

      <div className="rolBody">
        {hook.rols.map((rol, i) => (
          <div key={`${key.current}-${i}`}>
            <div className="rolMobile">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Label>Rol</Label>
                  <p>{rol.description}</p>
                </div>

                <ElipsisMobile onShow={() => hook.setRol(rol)}>
                  <CustomMenuItem onClick={openEdit}>Editar</CustomMenuItem>
                </ElipsisMobile>
              </div>
            </div>

            <input
              type="radio"
              className="d-none"
              id={`idRol-${rol.rolID}`}
              name="rols"
              onChange={(e) => hook.setRol(rol)}
            />
            <label htmlFor={`idRol-${rol.rolID}`} className="row">
              <MoreInformation id={rol.rolID} />
              <p>{rol.description}</p>
            </label>
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.currentPage}
        pages={hook.pages}
        onChange={(page) => hook.setPage(page)}
      />
    </div>
  );
}
