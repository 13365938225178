import Table from "components/general/Table";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { parseDateToText } from "helpers/dates";
import { parseToFolio } from "helpers/documents";
import { mnCurrency } from "helpers/money";
import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ui from "./styles.module.scss";
import useOcProyects from "customHooks/useOcProyects";
import Header, { Customer, Pos, Solped } from "pages/Proyects/Header";
import Options from "./Options";
import useGestionOc from "pages/Compras/GestionOc/useGestionOc";

/**
 *
 * @param {import("react-data-table-component").TableProps<import("helpers/Apis/proyects/types").Datum>} props
 */
function TableOcs(props) {
  return <Table {...props} />;
}

/**
 * Context for the oc proyects
 * @type {import("customHooks/useOcProyects/types").ContextOcProyects}
 */
export const ContextOcProyects = createContext(undefined);

export default function OcProyects() {
  const { idProyect } = useParams();

  const hook = useOcProyects(+idProyect);

  const odc = useGestionOc();

  const { state } = hook;

  return (
    <ContextOcProyects.Provider value={{...hook,odc}}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/proyectos",
            text: "Proyectos",
          },
          {
            route: "/proyectos",
            text: "OC",
          },
        ]}
      />

      <div className={ui.container}>
        <div className={ui.headerContainer}>
          {hook.state.proyect !== undefined ? (
            <Header idPosition={null} idProyect={hook.state.proyect.id}>
              <div className={ui.elementsHeader}>
                <Solped />
                <Customer />
              </div>
            </Header>
          ) : null}
        </div>

        <Options />

        <TableOcs
          progressPending={state.status === "loading"}
          noDataComponent={
            <div className="py-5 m-0">
              <p className="m-0">
                No hay registros, para generar ordenes de compra debes comprar
                el material de las <b>posiciones</b> para generar los documentos
              </p>
            </div>
          }
          data={state.ocs}
          onRowClicked={(oc) => hook.setOc(oc)}
          selectableRowSelected={(innerOc) =>
            innerOc.idDocument === state.oc?.idDocument
          }
          columns={[
            {
              name: "No. OC",
              sortable: true,
              selector: (oc) => parseToFolio(oc.documentNumber),
            },
            {
              name: "Pos",
              sortable: true,
              selector: (oc) =>
                state.position?.pos || parseToFolio(oc.idPosition),
            },
            {
              name: "Proveedor",
              sortable: true,
              selector: (oc) => oc.socialReason,
            },
            {
              name: "Moneda",
              sortable: true,
              selector: (oc) => oc.currency,
            },
            {
              name: "Total",
              sortable: true,
              selector: (oc) => mnCurrency(oc.total),
            },
            {
              name: "Enviada",
              sortable: true,
              selector: (oc) =>
                oc.sendDate === null ? "-" : parseDateToText(oc.sendDate),
            },
            {
              name: "Estatus",
              sortable: true,
              selector: (oc) => oc.documentStatus,
            },
            {
              name: "Materiales",
              sortable: true,
              selector: (oc) => oc.materialStatus,
            },
          ]}
        />
      </div>
    </ContextOcProyects.Provider>
  );
}
