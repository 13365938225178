import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import ui from "./styles.module.scss";
import PillFilterMobile from "components/individual/PillFilterMobile";
import { ContextResidueAndMovements } from "..";
import { parseDateToText } from "helpers/dates";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import ComboSocialReason from "../ComboSocialReason";
import Dates from "../Dates";

export default function FilterMobile() {
  const hook = useContext(ContextResidueAndMovements);

  const dialog = useDialog();

  return (
    <div className={ui.mobileFilter}>
      <div>
        <ButtonV2 onClick={dialog.open}>
          <Icon icon="filter" />
        </ButtonV2>

        <Dialog
          title="Filtros"
          forwardRef={dialog.refDialog}
          closeCallback={dialog.close}
          footer={
            <div className={ui.footer} onClick={() => dialog.close()}>
              <ButtonV2>
                <Icon icon="search" />
              </ButtonV2>
            </div>
          }
        >
          <div className={ui.content}>
            <ComboSocialReason />
            <Dates />
          </div>
        </Dialog>
      </div>

      <PillFilterMobile label="Desde" value={parseDateToText(hook.from)} />
      <PillFilterMobile label="Hasta" value={parseDateToText(hook.to)} />
    </div>
  );
}
