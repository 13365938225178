import React, { Fragment, useRef } from "react";

/**
 * Render the concepts
 * @param {import('./types').PropsConcepts} props - Props
 * @returns {JSX.Element}
 */
export default function Concepts({ concepts = [], invoice = [] }) {
  const key = useRef(window.crypto.randomUUID());

  if (invoice.length >= 1)
    return (
      <Fragment>
        {invoice.map((item) => (
          <span key={`${key.current}-${item.folio}`}>{item.folio}</span>
        ))}
      </Fragment>
    );

  if (!Array.isArray(concepts)) return <></>;

  return (
    <Fragment>
      {concepts.map((concept) => (
        <span key={`${key.current}-${concept.concept}`}>{concept.concept}</span>
      ))}
    </Fragment>
  );
}
