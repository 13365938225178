import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import { DownloadBlob } from "helpers/Apis/associateFiles";
import React from "react";
import { useState } from "react";
import { saveAs } from "file-saver";
import Icon from "components/individual/HeaderIcon";

/**
 * Render a pdf button to download the document
 * @param {import("./types").PropsPdfDownload} props - Props
 * @returns {JSX.Element}
 */
export const DonwloadPdfV2 = ({
  id = null,
  fileName = window.crypto.randomUUID(),
  btnProps = {},
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const pdf2 = await downloadPdfDocumentV3(id, "intranet");

      saveAs(pdf2["0"], `${fileName}.pdf`);
    } catch (error) {
    } finally {
      setIsDownloading(false);
    }
  };

  return isDownloading ? (
    <Spinner
      idSpinner="downloadInvoicePdf"
      hidden={false}
      text="Descargando pdf"
    />
  ) : (
    <ButtonV2 onClick={handleDownload} {...btnProps}>
      <span>PDF</span>
      <Icon icon="pdf" />
    </ButtonV2>
  );
};
