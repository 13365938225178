import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useToDo from "customHooks/useToDo";
import React, { createContext } from "react";
import CardsToDo from "structure/ToDo/CardsToDo";
import ToDoNavigation from "./Navigation";
import styles from "./styles.module.scss";

/**
 * Context of to do
 * @type {import("react").Context<import("customHooks/useToDo/types").ContextToDo|undefined>}
 */
export const ContextToDo = createContext(undefined);
const { Provider } = ContextToDo;

export default function ToDoHome() {
  const {
    navSelected,
    changeNavigation,
    refetchPersonalAdded,
    refetch,
    breadcrum,
  } = useToDo();

  return (
    <Provider
      value={{
        navSelected,
        changeNavigation,
        refetchPersonalAdded,
        refetch,
      }}
    >
      <div className={styles.module}>
        <BreadCrumV2 path={breadcrum} />

        <div className={styles.containerToDos}>
          <div>
            <ToDoNavigation />
          </div>
          <div className={styles.containerCards}>
            <CardsToDo
              type={navSelected}
              showGlobalImportants={
                navSelected === "urgenteGloabl" ? true : false
              }
              refetchFlag={refetch}
            />
          </div>
        </div>
      </div>
    </Provider>
  );
}
