import React, { Fragment, useRef } from "react";
import { useContext } from "react";
import { ContextResidueAndMovements } from "..";
import style from "../styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";

export default function Table() {
  const hook = useContext(ContextResidueAndMovements);

  const key = useRef(window.crypto.randomUUID());

  if (typeof hook.idCustomerProvider !== "number" && typeof hook.idCustomerProvider !== "string")
    return (
      <h1 className="text-center m-5">
        Selecciona un {hook.type === "compras" ? "proveedor" : "cliente"}
      </h1>
    );

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        text={<LoadingText>Cargando reporte</LoadingText>}
      />
    );

  if (hook.records.length <= 0)
    return (
      <h1 className="text-center m-5">
        No hay movimientos con los filtros solicitados
      </h1>
    );

  return (
    <div className={style.table}>
      <div className={style.containerRows}>
        <div className={style.header}>
          <p>
            <span>Factura</span>
            <span>{hook.type === "compras" ? "Egreso" : "Ingreso"}</span>
          </p>
          <p>
            <span>Razón social</span>
            <span>Descripción</span>
          </p>
          <p>
            <span>Emisión</span>
            <span>Depósito</span>
          </p>
          <p>
            <span>Moneda </span>
            <span>Moneda </span>
          </p>
          <p>
            <span>TC </span>
            <span>TC </span>
          </p>
          <p>
            <span>Total</span>
            <span>Total</span>
          </p>
          <p>
            <span>Saldo</span>
            <span>Final</span>
          </p>
        </div>

        {hook.records.map((invoice, i) => (
          <Fragment key={`${key.current}-${i}`}>
            <div className={style.row}>
              <p>Factura {invoice.invoiceNo}</p>
              <p>Razón social: {hook.socialReason}</p>
              <p className="justify-content-center">
                {parseDateToText(getDateFromUtc(invoice.emitedDate), "medium")}
              </p>
              <p className="justify-content-center">{invoice.currency}</p>
              <p className="justify-content-end">{mnCurrency(invoice.tc)}</p>
              <p className="justify-content-end">{mnCurrency(invoice.total)}</p>
              <p className="justify-content-end">
                {mnCurrency(invoice.residue)}
              </p>
            </div>

            {Array.isArray(invoice.movements)
              ? invoice.movements.map((movement, j) => (
                  <div
                    className={style.subrow}
                    key={`${key.current}-${invoice.invoiceNo}-${j}`}
                  >
                    <p>
                      {hook.type === "compras" ? "Egreso" : "Ingreso"}
                      {movement.movementNo}
                    </p>
                    <p>{movement.movementMessage}</p>
                    <p className="justify-content-center">
                      {parseDateToText(
                        new Date(`${movement.depositDate}`),
                        "medium"
                      )}
                    </p>
                    <p className="justify-content-center">
                      {movement.currency}
                    </p>
                    <p className="justify-content-end">
                      {mnCurrency(movement.tc)}
                    </p>
                    <p className="justify-content-end">
                      {mnCurrency(movement.amountPaid)}
                    </p>
                    <p
                      className="justify-content-end"
                      style={{ background: "var(--mainHeaderColor)" }}
                    >
                      -
                    </p>
                  </div>
                ))
              : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
