import React, { Fragment } from "react";
import VariableButton from "../VariableButton";

export default function VariablesPaymentReminders() {
  return (
    <Fragment>
      <VariableButton
        variableContent='{{var:facturasPendientes:""}}'
        name="Facturas pendientes"
      />
      <VariableButton variableContent='{{var:ejecutivo:""}}' name="Ejecutivo" />

      <VariableButton
        variableContent='{{var:ejecutivoCorreo:""}}'
        name="Correo (Ejecutivo)"
      />
    </Fragment>
  );
}
