import React, { useContext } from "react";
import Currency from "components/individual/inputs/Currency";
import { Label } from "structure/Document";
import { ContextOldResidues } from "..";

export default function CurrencyModule() {
  const hook = useContext(ContextOldResidues);

  if (hook.currencyToReport !== "Todos") return <div></div>;

  return (
    <div>
      <Label>Moneda (reporte)</Label>
      <Currency
        includeAll={false}
        selected={hook.currencyToUse}
        onChange={(currency) => hook.setCurrencyToUse(currency)}
      />
    </div>
  );
}
