import { saveAs } from "file-saver";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import { parseToFolio } from "helpers/documents";
import { xmlFileToJson } from "helpers/files";
import { useState } from "react";

// TODO: Consultar la informacion del documento legal para no tener que pasar como argumento el id del pdf y xml

/**
 * @type {import("./types").StateUseInvoiceSatPdf}
 */
const INITIAL_STATE = {
  isDownloading: false,
  pdf: undefined,
  xml: undefined,
};

/**
 * Download the pdf and xml file for the invoice sat requested
 * @param {import("./types").ParamUseInvoiceSatPdf} id - Id of the legal document
 */
export default function useInvoiceSatPdf({
  id = null,
  pdf = null,
  xml = null,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  async function attemptDownload() {
    setState((current) => ({
      ...current,
      isDownloading: true,
      pdf: undefined,
      xml: undefined,
    }));

    const idsToUse = await getIds();

    let queryPdf = null;
    let queryXml = null;

    if (idsToUse.pdf !== null) {
      queryPdf = downloadFileFromBlobV3(idsToUse.pdf);
    }

    if (idsToUse.xml !== null) {
      queryXml = downloadFileFromBlobV3(idsToUse.xml);
    }

    const [blobPdf, blobXml] = await Promise.all([queryPdf, queryXml]);

    const invoicePdf = new File([blobPdf], "Factura.pdf");
    const invoiceXml = new File([blobXml], "Factura.xml");

    const xmlData = await xmlFileToJson(invoiceXml);

    saveAs(invoicePdf,`FE-${parseToFolio(+xmlData.$.Folio)}.pdf`);

    setState((current) => ({
      ...current,
      isDownloading: false,
      pdf: invoicePdf,
      xml: invoiceXml,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get the ids to use for download the files
     * @returns {Promise<import("./types").IdsFiles>}
     */
    async function getIds() {
      if (id !== null) {
        const ids = await getLegalDocument();
        return ids;
      }

      return {
        pdf,
        xml,
      };
    }

    /**
     * TODO: Consultar la informacion del documento legal para obtener el id de los archivos
     * @returns {Promise<import("./types").IdsConsulted>}
     */
    async function getLegalDocument() {
      return {
        pdf,
        xml,
      };
    }
  }

  return {
    ...state,
    attemptDownload
  };
}
