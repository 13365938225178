import React, { useContext } from "react";
import { ContextContact } from "../..";
import { Submit } from "components/individual/buttons/Buttons";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from '../../styles.module.scss';
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function ButtonAddContact() {
  const hook = useContext(ContextContact);

  if (hook.isPerformingAbc)
    return (
      <div className={style.footerContainer}>
        <Spinner text={<LoadingText>Agregando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <div className={style.footerContainer}>
      <ButtonV2 form={hook.idFormAdd}>
        <span>Crear contacto</span>
        <Icon icon="save"/>
      </ButtonV2>
    </div>
  );
}
