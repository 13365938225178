import styled from "styled-components";

export const HederContainer = styled.div`
  background-color: var(--cardBackground);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color:var(--primaryColor);
  margin-bottom: 0.4rem;
  width: 85rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding: 0.8rem;
`;
export const SocialRazon = styled.p`
  width: 70%;
`;

export const ContactContrainer = styled.div`

  /* z-index: 2; */
`;

export const SocialRazonContainer = styled.div`

  /* z-index: 2; */
`;
