import styled from "styled-components";

const colorAdvertisment = "#0058f2";

export const CardContainer = styled.div`
  box-shadow: 0px 0.33em 0.33em rgba(0, 0, 0, 0.25);
  background-color: var(--cardBackground);
  color:var(--primaryColor);
  border-radius: 1.06em;
  padding: 1em;
  margin: 0 0 10px 0;
  max-height:120px;

  @media (max-width: 767px) {
    display:flex;
    flex-direction:column;
    gap:1rem 0;
    max-height:100%;
  }
`;

export const Icon = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 10px 0 0;
  svg {
    pointer-events: none;
    color:white;
  }
  border: none;
  background-color: #191919;
  transition: background 0.25s ease-in;
  &:hover {
    background-color: #e3e3e3;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.isSameCurrency ? "end" : "space-between"};
`;

export const CurrencyAdvertisment = styled.span`
  color: ${colorAdvertisment};
  &:before {
    content: " * ";
  }
`;
export const Description = styled.p`
  border-bottom: 1px solid black;
  padding: 0 0 20px 0;
`;

export const Margin = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  span:first-child {
    &::after {
      content: ": ";
    }
  }
  span:last-child {
    margin: 0 0 0 5px;
    color: ${({ utility }) => (utility <= 0 ? "#bd0000" : "#159d18")};
  }
`;

export const PricesInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    padding: 10px;
    span {
      margin: 0 0 0 5px;
    }
  }
`;
