import { Error } from "helpers/alerts";
import { getErrorData } from "helpers/errors";

const { isValidHttpResCode } = require("helpers/Apis/fetch");
const { URL_BASE } = require("routes/routes");

/**
 * Get the `Pareto` report
 * @param {import("./types").ParamPareto} param - Params in order to fetch the report
 * @returns {Promise<import("./types").ParetoI[]|Blob>}
 */
async function report({ base, currency, getAsExcel = false }) {
  try {
    const reportCurrency = `?reportCurrency=${currency}`;
    const reportType = `&reportType=${base}`;

    const urlExcel = getAsExcel ? "/excel" : "";

    const res = await fetch(
      `${URL_BASE}documentos/reporte/pareto${urlExcel}${reportCurrency}${reportType}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      if (getAsExcel) {
        const file = await res.blob();
        return file
      } else {
        /**
         * @type {import("./types").ParetoI[]}
         */
        const data = await res.json();
        return data;
      }
    }

    const error = await getErrorData(res);
    Error(() => {}, error.message);

    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

export default {
  report,
};
