import React, { useContext } from "react";
import { Label } from "structure/Document";
import SocialReason from "components/general/Forms/Selects/SocialReason";
import { ContextOdc } from "../..";

export default function CustomerSocialReason() {
  const { socialReason, updateSocialReason } = useContext(ContextOdc);

  return (
    <div>
      <Label>Razon social</Label>
      <SocialReason
        aditionalOptions={[
          {
            id: null,
            isActive: true,
            label: "Todos",
            socialReason: "Todos",
            value: null,
          },
        ]}
        includeNoActives={false}
        type="proveedor"
        onChange={({ id , socialReason }) => updateSocialReason(id,socialReason)}
        value={socialReason}
      />
    </div>
  );
}
