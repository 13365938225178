import InputField from "components/individual/InputField";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useFormProyect from "./useFormProyect";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import CommentBox from "components/individual/CommentBox";
import { Label } from "structure/Document";
import { Controller } from "react-hook-form";
import Phone from "components/individual/Phone";
import ProyectStatus from "components/individual/ProyectStatus";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Customer from "components/general/Customer";
import { GetCustomer } from "helpers/Apis/Directory";

/**
 * @type {import("react").Context<import("./useFormProyect/types").ContextFormProyect|undefined>}
 */
const ContextFormProyect = React.createContext(undefined);

/**
 * Render the form to handle the edition or add of proyects
 * @param {import('./types').PropsFormProyect} props - Props of the component
 * @returns {JSX.Element}
 */
export default function FormProyect(props) {
  const { isToAddPosition = false } = props;
  const hook = useFormProyect(props.idProyect, props.positions);

  useEffect(() => {
    props?.onLoadedProyect(hook.proyect);
  }, [hook.proyect]);

  const labelInformative = useRef(props.idProyect <= 0 ? "d-none" : "");
  const labelInput = useRef(props.idProyect > 0 ? "d-none" : "");

  /**
   * Check if the field is informative or input in order to display or hide it from UI
   * @param {boolean} informative
   * @param {'informative'|'input'} type
   */
  function cssField(informative, type) {
    if (informative && type === "input") {
      return "d-none";
    }

    if (informative && type === "informative") {
      return "";
    }

    if (!informative && type === "input") {
      return "";
    }

    if (!informative && type === "informative") {
      return "d-none";
    }
  }

  if (hook.isLoadingData)
    return (
      <div className="d-flex justify-content-center align-items-center m-5">
        <Spinner text={"Cargando proyecto"} hidden={false} />
      </div>
    );

  return (
    <ContextFormProyect.Provider
      value={{
        ...hook,
        labelInformative,
        labelInput,
        isToAddPosition,
        cssField,
      }}
    >
      <form
        {...props}
        noValidate
        onSubmit={hook.form.handleSubmit(hook.handleSubmit, console.log)}
      >
        {props.children}
      </form>
    </ContextFormProyect.Provider>
  );
}

/**
 * Render a container to update the proyect (or add the proyect)
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 * @returns {JSX.Element}
 */
export function ContainerUpdateProyect(props) {
  const hook = useContext(ContextFormProyect);

  if (hook.isUpdatingData)
    return <Spinner text={"Guardando proyecto"} hidden={false} />;

  return <div {...props}>{props?.children}</div>;
}

/**
 * Render element
 * @param {import("./types").OnChangeField} props
 * @returns {JSX.Element}
 */
export function Solped(props) {
  const hook = useContext(ContextFormProyect);
  let cumstomProps = { ...props };
  delete cumstomProps.onChange;
  return (
    <div {...cumstomProps}>
      <div className={hook.displayField("solped", "informative")}>
        <b>Solped</b>
        <p>{hook.form.getValues("solped")}</p>
      </div>

      <div className={hook.displayField("solped", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          label="Solped"
          placeholder="Escribe aquí"
          name="solped"
          onChange={(e) => props?.onChange(e.target.value)}
        />
        <p className="text-danger font-weight-bold">
          {hook.form.errors.solped?.message}
        </p>
      </div>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function CustomersField(props) {
  const hook = useContext(ContextFormProyect);

  const [customer, setCustomer] = useState(undefined);

  return (
    <Controller
      name="idClient"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <Label required>Cliente</Label>

          <div
            className={
              typeof hook.proyect?.idClient === "number" ? "d-none" : ""
            }
          >
            <Customer
              type="cliente"
              onChange={(data) => {
                onChange(data);
              }}
              onLoaded={async () => {
                hook.form.setValue("idClient", hook.proyect?.idClient||null);

                if (typeof hook.proyect?.idClient === "number") {
                  const api = await GetCustomer(hook.proyect.idClient);
                  setCustomer(api);
                }
              }}
              defaultValue={value}
            />
          </div>

          <div
            className={
              typeof !hook.proyect?.idClient === "number" ? "d-none" : ""
            }
          >
            <p>{customer?.socialReason}</p>
          </div>
          <p className="text-danger font-weight-bold">
            {hook.form.errors.idClient?.message}
          </p>
        </div>
      )}
    />
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function BuyerPhone(props) {
  const hook = useContext(ContextFormProyect);

  const buyerPhone = hook.form.getValues("buyerPhone");

  return (
    <Controller
      name="buyerPhone"
      control={hook.form.control}
      render={({ value, onChange }) => (
        <div {...props}>
          <div className={hook.displayField("buyerPhone", "informative")}>
            <b>Teléfono</b>
            <p>{buyerPhone === "" ? "No disponible" : buyerPhone}</p>
          </div>

          <div className={hook.displayField("buyerPhone", "input")}>
            <Label>Teléfono</Label>
            <Phone
              ref={hook.form.register}
              placeholder="Escribe aquí (opcional)"
              name="buyerPhone"
              value={value}
              onCustomOnChange={onChange}
            />
          </div>
        </div>
      )}
    />
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function UserPhone(props) {
  const hook = useContext(ContextFormProyect);

  const userPhone = hook.form.getValues("userPhone");

  return (
    <Controller
      name="userPhone"
      control={hook.form.control}
      render={({ value, onChange }) => (
        <div {...props}>
          <div className={hook.displayField("userPhone", "informative")}>
            <b>Teléfono</b>
            <p>{userPhone === "" ? "No disponible" : userPhone}</p>
          </div>

          <div className={hook.displayField("userPhone", "input")}>
            <Label>Teléfono</Label>
            <Phone
              ref={hook.form.register}
              placeholder="Escribe aquí (opcional)"
              name="userPhone"
              value={value}
              onCustomOnChange={onChange}
            />
          </div>
        </div>
      )}
    />
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function UserEmail(props) {
  const hook = useContext(ContextFormProyect);

  const userEmail = hook.form.getValues("userEmail");

  return (
    <div {...props}>
      <div className={hook.displayField("userEmail", "informative")}>
        <b>Correo</b>
        <p>{userEmail === "" ? "No disponible" : userEmail}</p>
      </div>

      <div className={hook.displayField("userEmail", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          label="Correo"
          placeholder="Escribe aquí (opcional)"
          name="userEmail"
        />

        <p className="text-danger font-weight-bold">
          {hook.form.errors.userEmail?.message}
        </p>
      </div>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function Position(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.pos"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div className={hook.displayField("position.pos", "informative")}>
  //           <b>Posición</b>
  //           <p>{hook.form.getValues("position.pos")}</p>
  //         </div>

  //         <div className={hook.displayField("position.pos", "input")}>
  //           <InputField
  //             maxLength={256}
  //             label="Posición"
  //             value={value}
  //             onChange={onChange}
  //             placeholder="Escribe aquí"
  //             required={true}
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.pos?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function Subposition(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.subPos"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div className={hook.displayField("position.subPos", "informative")}>
  //           <b>Sub posición</b>
  //           <p>{hook.form.getValues("position.subPos")}</p>
  //         </div>

  //         <div className={hook.displayField("position.subPos", "input")}>
  //           <InputField
  //             maxLength={256}
  //             label="Sub posición"
  //             value={value}
  //             onChange={onChange}
  //             placeholder="Escribe aquí"
  //             required={true}
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.subPos?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function Material(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.material"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div
  //           className={hook.displayField("position.material", "informative")}
  //         >
  //           <b>Material</b>
  //           <p>{hook.form.getValues("position.material")}</p>
  //         </div>

  //         <div className={hook.displayField("position.material", "input")}>
  //           <InputField
  //             maxLength={256}
  //             label="Material"
  //             value={value}
  //             onChange={onChange}
  //             required={true}
  //             placeholder="Escribe aquí"
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.material?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function Quantity(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.quantity"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div
  //           className={hook.displayField("position.quantity", "informative")}
  //         >
  //           <b>Cantidad</b>
  //           <p>{hook.form.getValues("position.quantity")}</p>
  //         </div>

  //         <div className={hook.displayField("position.quantity", "input")}>
  //           <InputField
  //             maxLength={256}
  //             label="Cantidad"
  //             value={value}
  //             onChange={onChange}
  //             placeholder="Escribe aquí"
  //             required={true}
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.quantity?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function UM(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.umSat"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div className={hook.displayField("position.umSat", "informative")}>
  //           <b>UM</b>
  //           <p>{hook.form.getValues("position.umSat")}</p>
  //         </div>

  //         <div className={hook.displayField("position.umSat", "input")}>
  //           <InputField
  //             maxLength={256}
  //             label="UM"
  //             value={value}
  //             onChange={onChange}
  //             placeholder="Escribe aquí"
  //             required={true}
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.umSat?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function LaborCost(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.laborCost"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <InputField
  //           maxLength={256}
  //           label="Mano de obra costo"
  //           value={value}
  //           onChange={onChange}
  //           placeholder="Escribe aquí"
  //           type="number"
  //           required={true}
  //         />
  //         <p className="text-danger font-weight-bold">
  //           {hook.form.errors.position?.laborCost?.message}
  //         </p>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function OcCustomer(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.ocCustomer"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <InputField
  //           maxLength={256}
  //           label="OC cliente"
  //           value={value}
  //           onChange={onChange}
  //           placeholder="Escribe aquí"
  //           type="text"
  //           required={false}
  //         />
  //         <p className="text-danger font-weight-bold">
  //           {hook.form.errors.position?.ocCustomer?.message}
  //         </p>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function FieldProyectStatus(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <Controller
      name="status"
      control={hook.form.control}
      render={({ value, onChange }) => (
        <div {...props}>
          <ProyectStatus
            onChange={(data) => onChange(data.value)}
            value={value}
          />
          <p className="text-danger font-weight-bold">
            {hook.form.errors?.status?.message}
          </p>
        </div>
      )}
    />
  );
}
/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function PercentageOfCompletion(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.percentageOfCompletion"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <InputField
  //           maxLength={256}
  //           label="% Avance"
  //           value={value}
  //           onChange={onChange}
  //           placeholder="Escribe aquí"
  //           type="number"
  //           required={true}
  //         />
  //         <p className="text-danger font-weight-bold">
  //           {hook.form.errors.position?.percentageOfCompletion?.message}
  //         </p>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function LaborSell(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.laborSell"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <InputField
  //           type="number"
  //           maxLength={256}
  //           label="Mano de obra venta"
  //           value={value}
  //           onChange={onChange}
  //           required={true}
  //           placeholder="Escribe aquí"
  //         />
  //         <p className="text-danger font-weight-bold">
  //           {hook.form.errors.position?.laborSell?.message}
  //         </p>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function DescriptionPosition(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // return (
  //   <Controller
  //     name="position.description"
  //     control={hook.form.control}
  //     render={({ onChange, value }) => (
  //       <div {...props}>
  //         <div
  //           className={hook.displayField("position.description", "informative")}
  //         >
  //           <b>Descripción</b>

  //           <ReactMarkdown
  //             rehypePlugins={[rehypeRaw]}
  //             children={hook.form.getValues("position.description")}
  //           />
  //         </div>

  //         <div className={hook.displayField("position.description", "input")}>
  //           <Label required={true}>Descripción</Label>
  //           <CommentBox onChange={onChange} value={value} />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.position?.description?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function Link(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <div {...props}>
      <div className={hook.displayField("link", "informative")}>
        <b>Link</b>
        <a
          className="d-block pb-2 pr-2"
          href={hook.form.getValues("link")}
          target="_blank"
        >
          {hook.form.getValues("link")}
        </a>
      </div>

      <div className={hook.displayField("link", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          label="Link"
          placeholder="Escribe aquí"
          name="link"
        />
        <p className="text-danger font-weight-bold">
          {hook.form.errors.link?.message}
        </p>
      </div>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function Comments(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <Controller
      name="comments"
      control={hook.form.control}
      render={({ onChange, value }) => (
        <div {...props}>
          <div className={hook.displayField("comments", "informative")}>
            <b>Descripción</b>

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={hook.form.getValues("comments")}
            />
          </div>

          <div className={hook.displayField("comments", "input")}>
            <Label required={true}>Descripción</Label>
            <CommentBox onChange={onChange} value={value} />
            <p className="text-danger font-weight-bold">
              {hook.form.errors.comments?.message}
            </p>
          </div>
        </div>
      )}
    />
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function BuyerEmail(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <div {...props}>
      <div className={hook.displayField("buyerEmail", "informative")}>
        <b>Correo</b>
        <p>{hook.form.getValues("buyerEmail")}</p>
      </div>

      <div className={hook.displayField("buyerEmail", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          // required={true}
          label="Correo"
          placeholder="Escribe aquí"
          name="buyerEmail"
        />

        <p className="text-danger font-weight-bold">
          {hook.form.errors.buyerEmail?.message}
        </p>
      </div>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function User(props) {
  const hook = useContext(ContextFormProyect);

  const user = hook.form.getValues("user");

  return (
    <div {...props}>
      <div className={hook.displayField("user", "informative")}>
        <b>Usuario</b>
        <p>{user === "" ? "No disponible" : user}</p>
      </div>

      <div className={hook.displayField("user", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          label="Usuario"
          placeholder="Escribe aquí (opcional)"
          name="user"
        />

        <p className="text-danger font-weight-bold">
          {hook.form.errors.user?.message}
        </p>
      </div>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 * @deprecated
 */
export function DateField(props) {
  return <></>;
  // const hook = useContext(ContextFormProyect);

  // useEffect(() => {
  //   registerLocale("es", es);
  // }, []);

  // return (
  //   <Controller
  //     name="closeDate"
  //     control={hook.form.control}
  //     render={({ value, onChange }) => (
  //       <div {...props}>
  //         <div className={hook.displayField("closeDate", "informative")}>
  //           <b>Fecha de cierre</b>
  //           <p>{parseDateToText(hook.form.getValues("closeDate"))}</p>
  //         </div>

  //         <div className={hook.displayField("closeDate", "input")}>
  //           <Label required={true}>Fecha de cierre</Label>
  //           <DatePicker
  //             withPortal={true}
  //             selected={value}
  //             locale="es"
  //             minDate={new Date()}
  //             value={value}
  //             dateFormat="dd/MMM/yyyy"
  //             onChange={onChange}
  //             placeholderText="Selecciona una fecha"
  //           />
  //           <p className="text-danger font-weight-bold">
  //             {hook.form.errors.closeDate?.message}
  //           </p>
  //         </div>
  //       </div>
  //     )}
  //   />
  // );
}

/**
 * Render element
 * @param {import("./types").OnChangeField} props
 * @returns {JSX.Element}
 */
export function NoRfq(props) {
  const hook = useContext(ContextFormProyect);

  const noRFQValue = hook.form.getValues("noRFQ");

  let cumstomProps = { ...props };
  delete cumstomProps.onChange;

  return (
    <div {...cumstomProps}>
      <div className={hook.displayField("noRFQ", "informative")}>
        <Fragment>
          <b>No. RFQ</b>
          <p>{noRFQValue}</p>
        </Fragment>
      </div>

      <div className={hook.displayField("noRFQ", "input")}>
        <InputField
          ref={hook.form.register}
          maxLength={256}
          label="No. RFQ"
          required={false}
          onChange={(e) => props?.onChange(e.target.value)}
          placeholder="Escribe aquí"
          name="noRFQ"
        />
      </div>

      <p className="text-danger font-weight-bold">
        {hook.form.errors.noRFQ?.message}
      </p>
    </div>
  );
}

/**
 * Render element
 * @param {import("./useFormProyect/types").FieldProyect} props
 * @returns {JSX.Element}
 */
export function Title(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <div {...props}>
      <div className={hook.displayField("title", "informative")}>
        <b>Título</b>
        <p>{hook.form.getValues("title")}</p>
      </div>

      <div className={hook.displayField("title", "input")}>
        <InputField
          required={true}
          ref={hook.form.register}
          maxLength={256}
          label="Título"
          placeholder="Escribe aquí"
          name="title"
        />
      </div>

      <p className="text-danger font-weight-bold">
        {hook.form.errors.title?.message}
      </p>
    </div>
  );
}

/**
 * Render element
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns {JSX.Element}
 */
export function Buyer(props) {
  const hook = useContext(ContextFormProyect);

  return (
    <div {...props}>
      <div className={hook.displayField("buyer", "informative")}>
        <b>Comprador</b>
        <p>{hook.form.getValues("buyer")}</p>
      </div>

      <div className={hook.displayField("buyer", "input")}>
        <InputField
          ref={hook.form.register}
          // required={true}
          maxLength={256}
          label="Comprador"
          placeholder="Escribe aquí"
          name="buyer"
        />

        <p className="text-danger font-weight-bold">
          {hook.form.errors.buyer?.message}
        </p>
      </div>
    </div>
  );
}

export function SubmitProyect() {
  const hook = useContext(ContextFormProyect);

  if (hook.isToAddPosition)
    return (
      <ButtonV2>
        <span>Agregar posición</span>
        <Icon icon="plus" />
      </ButtonV2>
    );

  return (
    <ButtonV2>
      <span>{hook.id !== 0 ? "Actualizar proyecto" : "Agregar proyecto"}</span>
      <Icon icon={hook.id !== 0 ? "save" : "plus"} />
    </ButtonV2>
  );
}
