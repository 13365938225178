import useRegimenFiscal from "customHooks/useRegimenFiscal";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";

/**
 * Get the regimen fiscal valid to use against SAT
 * @param {import("./types").PropsRegimenFiscal} props - Props
 * @returns {JSX.Element}
 */
export default function RegimenFiscal({ value = null, onChange = () => {} }) {
  const { reactSelectOptions, regimens } = useRegimenFiscal();

  const [regimen, setRegimen] = useState(null);

  useEffect(() => {

    if(typeof(value)!=="string") return

    const valueSelected = regimens.find((regimen) => regimen.Value === value.trim());
    if (valueSelected !== undefined) setRegimen(valueSelected);
  }, [value]);

  /**
   * Information of the regimen selected
   * @param {import("./types").RegimenI} regimen - Regimen
   */
  const handleChange = (regimen) => {
    setRegimen(regimen);
    onChange({
      label: regimen.Name,
      value: regimen.Value,
    });
  };

  return (
    <Select
      className="saiko-select"
      classNamePrefix="saiko-select"
      isClearable={false}
      isSearchable={true}
      options={reactSelectOptions}
      value={regimen}
      placeholder="Selecciona"
      onChange={handleChange}
    />
  );
}
