import React, { useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Label } from "structure/Document";
import { Spinner } from "../Spinner/Spinner";
import LoadingText from "../TextLoading";
import { getTypesCustomers } from "helpers/Apis/Directory";
import Select from "react-select";

/**
 * @type {import("./types").StateTypeCustomerDirectory}
 */
const INITIAL_STATE = {
  selected: null,
  options: [],
  optionsToUse: [],
  isLoading: true,
  valueLoaded: false,
};

/**
 * Render a select option to choose the type of customer that's being operated on the `Directory`
 * @param {import('./types').PropsTypeCustomerDirectory} props - Props to handle the component
 * @returns {JSX.Element} Select input to pick the type of customer and **create** the type of customer in case doesn't exist
 */
export default function TypeCustomerDirectory({
  idCustomer = null,
  type = null,
  onChange = () => {},
  value = null,
  isFilter = false,

}) {
  const keySpinner = useRef(window.crypto.randomUUID());

  const [state, setState] = useState(INITIAL_STATE);

  /**
   * Handle the callback when an option is selected
   * @param {import("./types").onChangeTypeCustomerDirectory} item - Item
   */
  const handleOnChange = (item) => {


    if (item === null) {
      setState((current) => ({
        ...current,
        selected: null,
      }));

      return;
    }

    const idToUse = item.__isNew__ ? window.crypto.randomUUID() : item.value;

    const itemSearched = state.options.find(
      (option) => option.value === idToUse
    );

    /**
     * @type {import("./types").TypeCustomerDirectoryData}
     */
    const callbackDto = {
      description: item.label,
      id: idToUse,
      type,
    };

    /**
     * @type {import("./types").OptionTypeCustomerDirectory}
     */
    const stateOption = {
      ...callbackDto,
      label: item.label,
      value: idToUse,
    };

    if (itemSearched === undefined) {
      setState((current) => ({
        ...current,
        selected: stateOption,
        options: isFilter ? current.options : [stateOption, ...current.options],
      }));
      return;
    }

    setState((current) => ({
      ...current,
      selected: stateOption,
    }));
  };

  useEffect(() => {
    (async function () {
      const optionsApi = await getTypesCustomers();

      /**
       * @type {import("./types").OptionTypeCustomerDirectory[]}
       */
      const options = optionsApi.map((item) => ({
        ...item,
        label: item.description,
        value: item.id,
      }));

      setState((current) => ({
        ...current,
        isLoading: false,
        options,
      }));
    })();
  }, []);

  useEffect(() => {
    if (state.options.length <= 0) return;

    /**
     * @type {import("./types").OptionTypeCustomerDirectory[]}
     */
    let options = [];

    if (isFilter) {
      options = [
        {
          description: "Todos",
          id: null,
          label: "Todos",
          type: "cliente",
          value: null,
        },
      ];
    }

    // Filter by the type
    if(type!=="todos"){
      options = [
        ...options,
        ...state.options.filter((item) => item.type === type),
      ];
    }
    
    // Get all the types
    else{
      options = [
        ...options,
        ...state.options
      ]
    }


    setState((current) => ({
      ...current,
      optionsToUse: options,
      valueLoaded: true,
    }));
  }, [state.options, type]);

  useEffect(() => {
    if (!state.valueLoaded) return;

    const selected =
      state.optionsToUse.find((item) => item.id === value) || state.selected;

    setState((current) => ({
      ...current,
      selected,
    }));
  }, [state.optionsToUse]);

  useEffect(() => {
    if (typeof value !== "number" || state.options.length <= 0) return;

    const optionFinded = state.options.find((item) => item.id === value);

    if (optionFinded !== null && optionFinded !== undefined) {
      setState((current) => ({
        ...current,
        selected: optionFinded,
      }));
      return;
    }
    setState((current) => ({
      ...current,
      selected: null,
    }));
  }, [value, state.options]);

  useEffect(() => {
    onChange(state.selected);
  }, [state.selected,state.optionsToUse]);

  if (type === null)
    return (
      <div>
        <Label required={true}>Tipo</Label>
        <p>Selecciona el tipo</p>
      </div>
    );

  if (state.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner={keySpinner.current}
        text={<LoadingText>Cargando tipos de cliente</LoadingText>}
      />
    );

  return (
    <div>
      <Label required={state.selected === null}>Sector de mercado</Label>

      {!isFilter ? (
        <CreatableSelect
          isClearable={false}
          options={state.optionsToUse}
          placeholder="Selecciona o crea"
          onChange={handleOnChange}
          value={state.selected}
          defaultValue={state.selected}
          className="saiko-select"
          classNamePrefix="saiko-select"
        />
      ) : (
        <Select
          className="saiko-select"
          classNamePrefix="saiko-select"
          isDisabled={false}
          isClearable={false}
          placeholder="Selecciona"
          isSearchable={true}
          value={state.selected}
          defaultValue={state.selected}
          options={state.optionsToUse}
          onChange={handleOnChange}
        />
      )}
    </div>
  );
}
