import { InputCurrency } from "components/individual/inputs/Inputs";

import React, { useContext } from "react";
import { Label } from "structure/Document";
import accountComboContext from "../accountComboContext";

export default function DestinyImport() {
  const context = useContext(accountComboContext);

  if (context.destinyAccount.information === null) return <></>;

  if (
    context.originAccount.information.currency ===
    context.destinyAccount.information.currency
  )
    return <></>;

  return (
    <>
      <Label>Importe</Label>
      <InputCurrency onChange={(value) => context.updateDestinyImport(value)} placeholder="Escribe aquí"/>
    </>
  );
}
