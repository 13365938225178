import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";
import { AddContext } from "../Addv2";
import AssociateCorporative from "structure/AssociateCorporative";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function Corporative() {
  const { customer, updateCorporative } =
    useContext(AddContext);

  const { checkPermission } = usePermissions();

  return customer.corporative === null ? (
    checkPermission("7a7418f1-0904-4e9f-984f-ea086799779c") ? (
      <AssociateCorporative onChange={(info) => updateCorporative(info)}>
        <ButtonV2 type="button">
          <span>Asociar corporativo</span>
          <Icon icon="building"/>
        </ButtonV2>
      </AssociateCorporative>
    ) : null
  ) : checkPermission("0bab85b8-2b1f-4cbb-bd51-9a699a0f1994") ? (
    <ButtonV2 type="button" onClick={(e) => updateCorporative(null)}>
      <span>Des-asociar</span>
      <Icon icon="building"/>
    </ButtonV2>
  ) : null;
}
