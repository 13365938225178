import { useState } from "react";

/**
 * Handle the profile picture in order to edit or preview it
 * @param {import("structure/Profile/Picture/types").ProfilePictureI} props - Props
 */
export default function useProfilePicture({
  currentProfilePicture,
  scale,
  position,
}) {
  const [photoToUse, setPhotoToUse] = useState(currentProfilePicture);
  const [scaleToUse, setScaleToUse] = useState(scale);
  const [positionToUse, setPositionToUse] = useState(position);

  return {
    photoToUse,
    setPhotoToUse,
    scaleToUse,
    setScaleToUse,
    positionToUse,
    setPositionToUse,
  };
}
