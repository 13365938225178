import React, { useContext } from "react";
import { ContextContact } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import { ContainerFormContact } from "pages/Directory/Contactos/FormContactStyles";
import { FormContact } from "pages/Directory/Contactos/FormContact";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import UenContact from "../UenContact";
import ButtonUpdateContact from "./ButtonUpdateContact";

export default function Edit() {
  const hook = useContext(ContextContact);

  return (
    <>
      <ButtonV2 onClick={hook.openEdit}>Editar</ButtonV2>

      <Dialog
        title={`Editar contacto | ${hook.customer.type.description} - ${
          hook.customer.socialReason
        }  ${
          hook.detailsContact === undefined
            ? ""
            : `| Contacto - ${hook.detailsContact.name}`
        }`}
        forwardRef={hook.dialogEdit.refDialog}
        closeCallback={hook.closeEdit}
        footer={
          hook.detailsContact === undefined ? null : <ButtonUpdateContact />
        }
      >
        {hook.detailsContact === undefined || hook.detailsContact === null ? (
          <Spinner
            hidden={false}
            text={<LoadingText>Cargando contacto</LoadingText>}
          />
        ) : (
          <ContainerFormContact>
            <FormContact
              idForm={hook.idFormEdit}
              onSubmit={hook.attemptUpdateContact}
            >
              <FormContact.Name
                placeholder="Escribe aquí"
                defValue={hook.detailsContact.firstName}
              />
              <FormContact.MiddleName
                defValue={hook.detailsContact.middleName}
                placeholder="Escribe aquí"
              />
              <FormContact.Father
                defValue={hook.detailsContact.lastName1}
                placeholder="Escribe aquí"
              />
              <FormContact.Mother
                defValue={hook.detailsContact.lastName2}
                placeholder="Escribe aquí"
              />
              <FormContact.PhoneNumber
                defValue={hook.detailsContact.phone.fullNumber}
                lada={hook.detailsContact.phone.lada} 
                number={hook.detailsContact.phone.number} 
              />
              <FormContact.Cellphone
                defValue={hook.detailsContact.movil.fullNumber}
                lada={hook.detailsContact.movil.lada} 
                number={hook.detailsContact.movil.number} 
              />
              <FormContact.Email
                defValue={hook.detailsContact.email}
                placeholder="Escribe aquí"
              />
              <FormContact.Position
                defValue={hook.detailsContact.position}
                placeholder="Escribe aquí"
              />

              <div className="d-flex justify-content-around align-items-center">
                <FormContact.CollectionContact
                  isChecked={hook.detailsContact.isForColletion}
                />
                <FormContact.ContactPayments
                  isChecked={hook.detailsContact.isForPayments}
                />
                <FormContact.Status
                  isChecked={hook.detailsContact.isActive}
                  name="estatus"
                />
              </div>

              <FormContact.Aniversary
                label="Aniversario"
                date={hook.detailsContact.birthDay}
              />

              <UenContact
                title={`${hook.customer.type.description} - ${hook.customer.socialReason} | Contacto - ${hook.detailsContact?.name} | Unidades de negocio asociados`}
                onChange={(items) => hook.updateUensContact(items)}
                uens={hook.uensContact}
                hasEmail={hook.hasEmail}
                displayBlockColumn={true}
              />
            </FormContact>
          </ContainerFormContact>
        )}
      </Dialog>
    </>
  );
}
