import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { dateToDbFormat } from "helpers/dates";

// import { GetParameters } from "../../../../helpers/Apis/Documents";
import { GetParameters } from "helpers/Apis/Documents";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { reminderDate } from "pages/Directory/Documents/Reminders/Inputs/InputsInfo";
import { addDays } from "date-fns";
registerLocale("es", es);

/**
 * Expiration date input
 * @param {object} props - Props
 * @param {Date?} props.selected - Date selected
 * @param {(date:Date)=>void} props.onChange - Date selected
 * @param {Date?} props.reminderDate - Reminder date selected
 * @param {boolean?} props.allowSameReminder - If true, this calendar can select the same date as the reminder (not previous date, just same date)
 * @returns {JSX.Element}
 */
function ExpirationDate({
  selected = null,
  onChange = () => {},
  onLoad = () => {},
  name = "expiration",
  reminderDate = new Date(),
  forwardRef = {},
  allowSameReminder = false
}) {
  const [date, setDate] = useState(selected);
  registerLocale("es", es);

  useEffect(() => {
    async function loadDefaultValue() {
      const { parameters } = await GetParameters();

      const { value } = parameters.find(
        (parameter) => parameter.parameter === 1
      );

      let defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() + +value);

      setDate(defaultDate);
      onLoad(defaultDate);
    }

    if (selected === null) loadDefaultValue();
  }, []);

  useEffect(() => {
    setDate(selected);
  }, [selected]);
  return (
    <>
      <DatePicker
        id={window.crypto.randomUUID()}
        selected={date}
        dateFormat="dd/MMM/yyyy"
        locale="es"
        minDate={!allowSameReminder ? addDays(reminderDate, 1) : reminderDate}
        onChange={(date) => {
          setDate(date);
          onChange(date);
        }}
      />

      <input
        type="text"
        hidden
        value={date === null ? "" : dateToDbFormat(date)}
        ref={forwardRef}
        name={name}
      />
    </>
  );
}

export default ExpirationDate;
