import Select from "components/individual/SelectV4";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { DirectoryContext } from "..";

export default function StatusFilter() {
  const hook = useContext(DirectoryContext);

  return (
    <div>
      <Label>Estatus</Label>
      <Select
        onChange={hook.setStatus}
        labelToDisplay="label"
        defaultSelected={{
          keyToCompare: "value",
          value: hook.status.value,
        }}
        options={async () => [
          {
            label: "Todos",
            value: -1,
          },
          {
            label: "Activo",
            value: 1,
          },
          {
            label: "Inactivo",
            value: 0,
          },
        ]}
      />
    </div>
  );
}
