import React from "react";
import BankContext from "context/Bank/BankContext";
import { useContext } from "react";
import style from './styles.module.scss';

/**
 * Render header for the bank account
 * @param {import("./types").PropsBankHeader} props - Props
 * @returns {JSX.Element}
 */
export function BankHeader({ children }) {
  const context = useContext(BankContext);

  if (context.isLoadingAccount === true) return;

  return <div className={style.containerBankInfo}>{children(context)}</div>;
}

export function BankName() {
  const context = useContext(BankContext);

  return (
    <div className="d-inline-flex mr-3">
      <b className="mr-1">Banco</b>
      <span>{context.account.bank.shortName}</span>
    </div>
  );
}

export function BankAccount() {
  const context = useContext(BankContext);

  return (
    <div className="d-inline-flex mr-3">
      <b className="mr-1">Cuenta</b>
      <span>{context.account.account}</span>
    </div>
  );
}

export function BankCurrency() {
  const context = useContext(BankContext);

  return (
    <div className="d-inline-flex mr-3">
      <b className="mr-1">Moneda</b>
      <span>{context.account.currency}</span>
    </div>
  );
}
