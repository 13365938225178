import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";

export default function UpdateButton({ isValid, isPerformingQuery, cb }) {
  return !isValid ? null : isPerformingQuery ? (
    <Spinner hidden={false} text="Actualizando perfil..." />
  ) : (
    <ButtonV2 onClick={cb}>Actualizar</ButtonV2>
  );
}
