import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextHistoricalContracts } from "..";

export default function SocialReasonFilter() {
  const hook = useContext(ContextHistoricalContracts);

  return (
    <div>
      <Label>Razón social</Label>
      <Search
        value={hook.socialReason}
        onChange={hook.setSocialReason}
        onDelete={() => hook.setSocialReason("")}
        hideSearchIcon={true}
        placeholder="Escribe aquí"
      />
    </div>
  );
}
