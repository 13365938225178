import Checkbox from "components/individual/inputs/Checkbox";
import { Spinner } from "components/individual/Spinner/Spinner";
import { getRols } from "helpers/Apis/rolesApi";
import React, { useEffect, useState } from "react";
import { ContainerCheckbox } from "./styles";

/**
 * Render a checkbox with the rols
 * @param {import("./types").PropsI} props
 * @returns {JSX.Element}
 */
export default function CheckboxesRols({ onChange = () => {}, checkeds = [] }) {
  /**
   * State
   * @type {[import("./types").StateI,()=>void]}
   */
  const [state, setState] = useState({
    rols: [],
    isLoading: true,
    checkeds: {},
  });

  /**
   * Update the rols that are checked on the UI
   * @param {import("types/typedef/roles").RolI} rol - Id of the rol
   * @param {boolean} isMarked - True if the checkbox is marked with the check symbol
   */
  const handleClick = (rol, isMarked) => {
    let unreferencedCheckeds = { ...state.checkeds };

    if (isMarked) {
      addToList();
    } else {
      quitFromList();
    }

    setState({
      ...state,
      checkeds: unreferencedCheckeds,
    });

    const keyValueCheckeds = Object.entries(unreferencedCheckeds);
    const checkeds = keyValueCheckeds.map(([id, object]) => object);

    onChange(checkeds);

    function quitFromList() {
      delete unreferencedCheckeds[rol.id];
    }

    function addToList() {
      unreferencedCheckeds = {
        ...unreferencedCheckeds,
        [rol.id]: rol,
      };
    }
  };

  useEffect(() => {
    (async function () {
      const apiRoles = await getRols();

      const elementsAlreadyChecked = checkeds.reduce(
        (indexed, rol) => ({
          ...indexed,
          [rol.id]: rol,
        }),
        {}
      );

      setState({
        ...state,
        isLoading: false,
        rols: apiRoles,
        checkeds: elementsAlreadyChecked,
      });
    })();
  }, []);

  return state.isLoading ? (
    <Spinner hidden={false} text="Cargando roles" />
  ) : state.rols.length === 0 ? (
    <h1 className="text-center">No hay roles</h1>
  ) : (
    state.rols.map((rol) => {
      const id = window.crypto.randomUUID();

      return (
        <ContainerCheckbox key={id} htmlFor={id}>
          <Checkbox
            id={id}
            checked={state.checkeds[rol.id] === undefined ? false : true}
            onChange={(e) => handleClick(rol, e.target.checked)}
          />
          {rol.name}
        </ContainerCheckbox>
      );
    })
  );
}
