import { useContext } from "react";
import { useEffect, useReducer } from "react";
import { initialState, winReducer } from "./winReducer";
import { ACTIONS } from "./Actions";
import { useHistory, useParams } from "react-router-dom";
import {
  getDocumentData,
  handleFormItem,
} from "components/Sections/Documents/helpers/documentHelper";
import { useState } from "react";
import {
  ImportDocument,
  IvaDocument,
  MarginDocument,
  TotalDocument,
  createCurrencyObject,
  formatDocumentData,
  moneyInfoForOdc,
  validateData,
  winDocument,
} from "../helpers/helper";

import { v4 as uuidv4 } from "uuid";
import { ErrorAlertText, Success } from "helpers/alerts";
import LoginContext from "context/Login/LoginContext";

/**
 * Use win quote Reducer
 * @param {number} customerId The customer id
 * @param {import("types/typedef/customer").CustomerI} socialReason Customer social reson
 * @returns {import("../../../../../../types/winQuote").FunctionsI}
 */
export const useWinQuote = (customerId, customerInfo) => {
  /**
   * @type{import("../../../../../../types/winQuote").ReducerType} reducer
   */
  const [winQuote, dispatch] = useReducer(winReducer, initialState);
  const { userToRepresentId } = useContext(LoginContext);
  const [loadCustomer, setLoadCustomer] = useState(null);
  const history = useHistory();

  const [loadDocument, setLoadDocument] = useState(null);

  /**
   * @type{import("../../../../../../types/winQuote").WinQuoteParams}
   */
  const { idQuote } = useParams();
  // const { customerId, idQuote } = useParams();

  const pathBreadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${customerId}`,
      text: "Documentos",
    },
    {
      route: "/",
      text: "Ganar cotización",
    },
  ];

  useEffect(() => {
    (async () => {
      if (!!idQuote) {
        const { data } = await getDocumentData(idQuote);
        console.log("🚀 ~ data:", data);

        setLoadDocument(data);
      }
    })();
  }, [idQuote]);

  useEffect(() => {
    if (!!loadDocument) {
      loadDocumentData(loadDocument);
      setLoadDocument(null);
    }
  }, [loadDocument]);

  useEffect(() => {
    if (!!winQuote.items.length) {
      handleDisableOdc();
    }
  }, [winQuote.items]);

  useEffect(() => {
    const menu = winQuote.interfaceControl.menu;
    const index = winQuote.interfaceControl.indexActiveMenu;
    if (menu[index].idType === 3) {
      console.log("Es odc");
      dispatch({
        type: ACTIONS.SET_ITEM_HEADER,
        payload: "Costo",
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ITEM_HEADER,
        payload: "Precio",
      });
    }
  }, [
    winQuote.interfaceControl.indexActiveMenu,
    winQuote.interfaceControl.menu,
  ]);

  /**
   * Handles the change of the order expiration date
   * @param {string} date
   */
  const handleOrderExpirationDate = (date) => {
    dispatch({
      type: ACTIONS.SET_ORDER_DATE2,
      payload: date,
    });
  };
  /**
   * Handles the change of the order reminder date
   * @param {import("../../../../../../types/winQuote").WinDatesI} date
   */
  const handleOrderReminderDate = (date) => {
    dispatch({
      type: ACTIONS.SET_ORDER_DATE3,
      payload: date,
    });
  };
  /**
   * Handles the change of the contract initial date
   * @param {string} date
   */
  const handleContractInitialDate = (date) => {
    dispatch({
      type: ACTIONS.SET_CONTRACT_DATE1,
      payload: date,
    });
  };
  /**
   * Handles the change of the contract end date
   * @param {string} date
   */
  const handleContractEndDate = (date) => {
    dispatch({
      type: ACTIONS.SET_CONTRACT_DATE2,
      payload: date,
    });
  };
  /**
   * Handles the change of the contract reminder date
   * @param {string} date
   */
  const handleContractRemainderDate = (date) => {
    dispatch({
      type: ACTIONS.SET_CONTRACT_DATE3,
      payload: date,
    });
  };
  /**
   * Handles the change of the odc send date
   * @param {string} date
   */
  const handleOdcSendDate = (date) => {
    dispatch({
      type: ACTIONS.SET_ODC_DATE2,
      payload: date,
    });
  };
  /**
   * Handles the change of the odc reminder date
   * @param {string} date
   */
  const handleOdcReminderDate = (date) => {
    dispatch({
      type: ACTIONS.SET_ODC_DATE3,
      payload: date,
    });
  };
  /**
   * Handles the change of generate contract switch
   * @param {boolean} value
   */
  const handleGenerateContract = (value) => {
    if (!!winQuote.idContractParent) {
      dispatch({
        type: ACTIONS.SET_GENERATE_CONTRACT,
        payload: true,
      });
    } else {
      dispatch({
        type: ACTIONS.SET_GENERATE_CONTRACT,
        payload: value,
      });
    }

    const menuArray = winQuote.interfaceControl.menu;
    const indexQuote = menuArray.findIndex((item) => item.idType === 1);
    if (value) {
      const indexContract = menuArray.findIndex((item) => item.idType === 6);
      const isContracActive = menuArray[indexContract].active;
      if (!isContracActive) {
        handleMenuChange(6);
      }
    } else {
      if (!menuArray[indexQuote].active) {
        handleMenuChange(1);
      }
    }
  };

  /**
   * Handles the change of the menu
   * @param {number} idTypeDocument
   */
  const handleMenuChange = (idTypeDocument) => {
    const indexToActive = winQuote.interfaceControl.menu.findIndex(
      (item) => item.idType === idTypeDocument
    );
    const indexToDisactive = winQuote.interfaceControl.menu.findIndex(
      (item) => item.active === true
    );

    if (indexToActive === indexToDisactive) return;

    const newMenu = [...winQuote.interfaceControl.menu];

    newMenu[indexToActive].active = true;
    newMenu[indexToDisactive].active = false;

    if (indexToActive !== indexToDisactive) {
      dispatch({
        type: ACTIONS.SET_ACTIVE_MENU_OPTION,
        payload: newMenu,
      });
      handleDateLabelsChange(idTypeDocument);
      handleIndexActiveMenuChange(indexToActive);
    }
  };

  /**
   * Handles the change lables depending on document type id
   * @param {import("../../../../../../types/winQuote").DpcumentTypeIdT} idDocumentType
   */
  const handleDateLabelsChange = (idDocumentType) => {
    const datesLables = winQuote.interfaceControl.datesLabels;
    switch (idDocumentType) {
      case 1:
        datesLables.date1 = "Creacón";
        datesLables.date2 = "Vigencia";
        datesLables.date3 = "Recordatorio";
        break;
      case 2:
        datesLables.date1 = "Creacón";
        datesLables.date2 = "Facturar";
        datesLables.date3 = "Recordatorio";
        break;
      case 3:
        datesLables.date1 = "Creacón";
        datesLables.date2 = "Enviar";
        datesLables.date3 = "Recordatorio";
        break;
      case 6:
        datesLables.date1 = "Inicio";
        datesLables.date2 = "Fin";
        datesLables.date3 = "Recordatorio";
        break;
      default:
        datesLables.date1 = "Creacón";
        datesLables.date2 = "Vigencia";
        datesLables.date3 = "Recordatorio";
        break;
    }
    dispatch({
      type: ACTIONS.SET_DATE_LABLES,
      payload: datesLables,
    });
  };

  /**
   * Handle the credit days change
   * @param {number} days
   */
  const handleCreditDaysChange = (days) => {
    dispatch({
      type: ACTIONS.SET_CREDIT_DAYS,
      payload: days,
    });
  };

  /**
   * Handle the provider change
   * @param {import("../../../../../../types/winQuote").CustomerInfoI} provider
   */
  const handleOnProviderChange = (provider) => {
    dispatch({
      type: ACTIONS.SET_PROVIDER,
      payload: provider,
    });
  };

  /**
   * Handle the change of the index active menu
   * @param {number} index
   */
  const handleIndexActiveMenuChange = (index) => {
    dispatch({
      type: ACTIONS.SET_INDEX_ACTIVE_MENU,
      payload: index,
    });
  };

  /**
   * Handle the contact chaange of every document
   * @param {{data:CustomerInfoI}} {data}
   */
  const handleDocumentsContactChange = ({ data }) => {
    const activeMenu = winQuote.interfaceControl.indexActiveMenu;
    const menu = winQuote.interfaceControl.menu;

    switch (menu[activeMenu].idType) {
      case 1:
        dispatch({
          type: ACTIONS.SET_QUOTE_CONTACT,
          payload: data,
        });
        break;
      case 2:
        dispatch({
          type: ACTIONS.SET_ORDER_CONTACT,
          payload: data,
        });
        break;
      case 3:
        dispatch({
          type: ACTIONS.SET_ODC_CONTACT,
          payload: data,
        });
        break;
      case 6:
        dispatch({
          type: ACTIONS.SET_CONTRACT_CONTACT,
          payload: data,
        });
        break;
      default:
        dispatch({
          type: ACTIONS.SET_QUOTE_CONTACT,
          payload: data,
        });
        break;
    }
  };

  const handleCurrentContactByDocument = () => {
    const activeMenu = winQuote.interfaceControl.indexActiveMenu;
    const menu = winQuote.interfaceControl.menu;

    const nullContact = {
      anniversary: null,
      email: null,
      id: null,
      isCollectionContact: null,
      isPaymentContact: null,
      lastName1: null,
      name: null,
      workTitle: null,
      cell: null,
      lastName2: null,
      middleName: null,
      phone: null,
    };
    switch (menu[activeMenu].idType) {
      case 1:
        const idQuoteContact = !!winQuote.documents.quote.contact?.id
          ? winQuote.documents.quote.contact?.id
          : nullContact;
        return idQuoteContact;
      case 2:
        const idOrderContact = !!winQuote.documents.quote.contact?.id
          ? winQuote.documents.order.contact?.id
          : nullContact;
        return idOrderContact;
      case 3:
        const idOdcContact = !!winQuote.documents.quote.contact?.id
          ? winQuote.documents.odc.contact?.id
          : nullContact;
        return idOdcContact;
      case 6:
        const idContractContact = !!winQuote.documents.quote.contact?.id
          ? winQuote.documents.contract.contact?.id
          : nullContact;
        return idContractContact;
      default:
        return null;
    }
  };

  const loadDocumentData = (document) => {
    const fixDocumentData = formatDocumentData(document);

    dispatch({
      type: ACTIONS.SET_LOAD_INITIAL_DATA,
      payload: fixDocumentData,
    });
  };

  /**
   *
   * @param {import("../../../../../../types/documentActions").ItemSelectI} item
   * @returns {import("../../../../../../types/documentActions").getNumberI}
   */
  const getNumbers = (item) => {
    const currency = winQuote.moneyInfo.currency.value;

    const documentType =
      winQuote.interfaceControl.menu[winQuote.interfaceControl.indexActiveMenu]
        .idType;

    const isDocumentTypeThree = documentType === 3;
    const currencyCode = item.catalogue.currency.code;
    const isCurrencyMXN = isDocumentTypeThree
      ? currencyCode === "MXN"
      : currencyCode === "MXN" || currency === "MXN";
    const currencyType = isCurrencyMXN ? "mxn" : "usd";

    const priceType = isDocumentTypeThree ? "cu" : "pu";
    const discountType = isDocumentTypeThree
      ? "providerDiscount"
      : "clientDiscount";

    console.log(
      "🚀 ~ getNumbers ~ item.catalogue.currency.code===MXN:",
      item.catalogue.currency.code === "MXN"
    );

    console.log("🚀 ~ getNumbers ~ currency === MXN:", currency === "MXN");

    return {
      subTotal: item[currencyType]?.[priceType]?.total?.text,
      unitary: item[currencyType]?.[priceType]?.totalUnit?.text,
      discount: item[discountType]?.number,
      iva: item[currencyType]?.[priceType]?.totalIva?.text,
      ivaPercentage: item.iva?.number,
      realUnitary: item[currencyType]?.[priceType]?.text,
    };
  };

  /**
   * Handle the comments to use
   * @returns {import("../../../../../../types/winQuote").CommentsI[]}
   */
  const commentsToUse = () => {
    const menu = winQuote.interfaceControl.menu;
    const index = winQuote.interfaceControl.indexActiveMenu;
    switch (menu[index].idType) {
      case 1:
        return winQuote.documents.quote.comments;
      case 2:
        return winQuote.documents.order.comments;
      case 3:
        return winQuote.documents.odc.comments;
      case 6:
        return winQuote.documents.contract.comments;
      default:
        return [];
    }
  };

  /**
   * Handle the order current comment change.
   * @param {string} comment
   * @param {boolean} editing
   */
  const handleOnCurrentOrderCommentChange = (comment) => {
    const commentIndex = winQuote.documents.order.comments.length;
    const uuid = uuidv4();

    /**
     * @type{import("../../../../../../types/winQuote").CommentsI}
     */
    const currentComment = {
      comment: comment,
      isEditable: true,
      isRemovable: true,
      order: !!commentIndex ? commentIndex : 1,
      id: null,
      type: 1,
      index: null,
      isEditing: false,
      uuid: uuid,
    };
    dispatch({
      type: ACTIONS.SET_ORDER_CURRENT_COMMENT,
      payload: currentComment,
    });
  };

  /**
   * Add the comment to the order document
   */
  const handleOnAddOrderComments = () => {
    const comments = winQuote.documents.order.comments;
    const currentComment = winQuote.documents.order.currentComment;
    if (!!currentComment) {
      dispatch({
        type: ACTIONS.SET_ORDER_COMMENTS,
        payload: [...comments, currentComment],
      });
      dispatch({
        type: ACTIONS.SET_ORDER_CURRENT_COMMENT,
        payload: null,
      });
    }
  };
  /**
   * Handle the odc current comment change.
   * @param {string} comment
   */
  const handleOnCurrentOdcCommentChange = (comment) => {
    const commentIndex = winQuote.documents.odc.comments.length;
    const uuid = uuidv4();

    /**
     * @type{import("../../../../../../types/winQuote").CommentsI}
     */
    const currentComment = {
      comment: comment,
      isEditable: true,
      isRemovable: true,
      order: !!commentIndex ? commentIndex : 1,
      id: null,
      type: 1,
      index: null,
      isEditing: false,
      uuid: uuid,
    };
    dispatch({
      type: ACTIONS.SET_ODC_CURRENT_COMMENT,
      payload: currentComment,
    });
  };
  /**
   * Add the comment to the odc document
   */
  const handleOnAddOdcComments = () => {
    const comments = winQuote.documents.odc.comments;
    const currentComment = winQuote.documents.odc.currentComment;
    dispatch({
      type: ACTIONS.SET_ODC_COMMENTS,
      payload: [...comments, currentComment],
    });
    dispatch({
      type: ACTIONS.SET_ODC_CURRENT_COMMENT,
      payload: null,
    });
  };
  /**
   * Handle the contract current comment change.
   * @param {string} comment
   */
  const handleOnCurrentContractCommentChange = (comment) => {
    const commentIndex = winQuote.documents.contract.comments.length;
    const uuid = uuidv4();

    /**
     * @type{import("../../../../../../types/winQuote").CommentsI}
     */
    const currentComment = {
      comment: comment,
      isEditable: true,
      isRemovable: true,
      order: !!commentIndex ? commentIndex : 1,
      id: null,
      type: 1,
      index: null,
      isEditing: false,
      uuid: uuid,
    };
    dispatch({
      type: ACTIONS.SET_CONTRACT_CURRENT_COMMENT,
      payload: currentComment,
    });
  };
  /**
   * Add the comment to the odc document
   */
  const handleOnAddContractComments = () => {
    const comments = winQuote.documents.contract.comments;
    const currentComment = winQuote.documents.contract.currentComment;
    dispatch({
      type: ACTIONS.SET_CONTRACT_COMMENTS,
      payload: [...comments, currentComment],
    });
    dispatch({
      type: ACTIONS.SET_CONTRACT_CURRENT_COMMENT,
      payload: null,
    });
  };

  /**
   * Returns the default value for the current order comment
   * @returns {import("../../../../../../types/winQuote").CommentsI | ''}
   */
  const defaultOrderCommentValue = () => {
    const comments = winQuote.documents.order.comments;
    const currentComment = !!winQuote.documents.order.currentComment
      ? winQuote.documents.order.currentComment.comment
      : "";
    let defaultValue;
    if (!!comments.length) {
      if (winQuote.documents.order.isCommentEditing) {
        defaultValue = "";
      } else {
        const indexEdting = comments.findIndex((element) => element.isEditing);
        defaultValue =
          indexEdting !== -1 ? comments[indexEdting].comment : currentComment;
      }
    } else {
      defaultValue = currentComment;
    }

    return defaultValue;
  };

  /**
   * Returns the default value for the current odc comment
   * @returns {import("../../../../../../types/winQuote").CommentsI | ''}
   */
  const defaultOdcCommentValue = () => {
    const comments = winQuote.documents.odc.comments;
    const currentComment = !!winQuote.documents.odc.currentComment
      ? winQuote.documents.odc.currentComment.comment
      : "";
    let defaultValue;
    if (!!comments.length) {
      if (winQuote.documents.odc.isCommentEditing) {
        defaultValue = "";
      } else {
        const indexEdting = comments.findIndex((element) => element.isEditing);
        defaultValue =
          indexEdting !== -1 ? comments[indexEdting].comment : currentComment;
      }
    } else {
      defaultValue = currentComment;
    }

    return defaultValue;
  };

  /**
   * Returns the default value for the current contract comment
   * @returns {import("../../../../../../types/winQuote").CommentsI | ''}
   */
  const defaultContractCommentValue = () => {
    const comments = winQuote.documents.contract.comments;
    const currentComment = !!winQuote.documents.contract.currentComment
      ? winQuote.documents.contract.currentComment.comment
      : "";
    let defaultValue;
    if (!!comments.length) {
      if (winQuote.documents.contract.isCommentEditing) {
        defaultValue = "";
      } else {
        const indexEdting = comments.findIndex((element) => element.isEditing);
        defaultValue =
          indexEdting !== -1 ? comments[indexEdting].comment : currentComment;
      }
    } else {
      defaultValue = currentComment;
    }

    return defaultValue;
  };

  /**
   * Deletes the Order comment selected
   * @param {number} index Array index
   */
  const handleOnDeleteOrderComment = (index) => {
    const comments = winQuote.documents.order.comments;
    const commentToDelete = comments[index];
    const newComments = comments.filter(
      (comment) => comment.uuid !== commentToDelete.uuid
    );

    dispatch({
      type: ACTIONS.SET_DELETE_ORDER_COMMENT,
      payload: newComments,
    });
  };

  /**
   * Deletes the Odc comment selected
   * @param {number} index Array index
   */
  const handleOnDeleteOdcComment = (index) => {
    const comments = winQuote.documents.odc.comments;
    const commentToDelete = comments[index];
    const newComments = comments.filter(
      (comment) => comment.uuid !== commentToDelete.uuid
    );

    dispatch({
      type: ACTIONS.SET_DELETE_ODC_COMMENT,
      payload: newComments,
    });
  };

  /**
   * Deletes the Contract comment selected
   * @param {number} index Array index
   */
  const handleOnDeleteContractComment = (index) => {
    const comments = winQuote.documents.contract.comments;
    const commentToDelete = comments[index];
    const newComments = comments.filter(
      (comment) => comment.uuid !== commentToDelete.uuid
    );

    dispatch({
      type: ACTIONS.SET_DELETE_CONTRACT_COMMENT,
      payload: newComments,
    });
  };

  /**
   * Open or close the Order current comment to edit
   * @param {number} index
   * @param {'open' | 'close'} action
   */
  const handleOpenToEditOrderComment = (index, action) => {
    const comments = winQuote.documents.order.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        if (action === "open") {
          comment.isEditing = true;
        } else {
          comment.isEditing = false;
        }
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_ORDER_COMMENT,
      payload: {
        comment: newComments,
        isEditing: action === "open" ? true : false,
      },
    });
    handleOnCurrentOrderCommentChange(commentToEdit.comment);
  };
  /**
   * Open or close the Odc current comment to edit
   * @param {number} index
   * @param {'open' | 'close'} action
   */
  const handleOpenToEditOdcComment = (index, action) => {
    const comments = winQuote.documents.odc.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        if (action === "open") {
          comment.isEditing = true;
        } else {
          comment.isEditing = false;
        }
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_ODC_COMMENT,
      payload: {
        comment: newComments,
        isEditing: action === "open" ? true : false,
      },
    });
    handleOnCurrentOdcCommentChange(commentToEdit.comment);
  };

  /**
   * Open or close the Contract current comment to edit
   * @param {number} index
   * @param {'open' | 'close'} action
   */
  const handleOpenToEditContractComment = (index, action) => {
    const comments = winQuote.documents.contract.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        if (action === "open") {
          comment.isEditing = true;
        } else {
          comment.isEditing = false;
        }
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_CONTRACT_COMMENT,
      payload: {
        comment: newComments,
        isEditing: action === "open" ? true : false,
      },
    });
    handleOnCurrentContractCommentChange(commentToEdit.comment);
  };

  /**
   * Handle to edit te order comment
   * @param {number} index
   * @param {import("../../../../../../types/winQuote").CommentsI} comentario
   */
  const handleToEditOrderComment = (index, comentario) => {
    const comments = winQuote.documents.order.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        comment.comment = comentario.comment;
        comment.isEditing = false;
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_EDIT_ORDER_COMMENT,
      payload: newComments,
    });
    dispatch({
      type: ACTIONS.SET_ORDER_CURRENT_COMMENT,
      payload: null,
    });
    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_ORDER_COMMENT,
      payload: {
        comment: [...winQuote.documents.order.comments],
        isEditing: false,
      },
    });
  };

  /**
   * Handle to edit te odc comment
   * @param {number} index
   * @param {import("../../../../../../types/winQuote").CommentsI} comentario

   */
  const handleToEditOdcComment = (index, comentario) => {
    const comments = winQuote.documents.odc.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        comment.comment = comentario.comment;
        comment.isEditing = false;
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_EDIT_ODC_COMMENT,
      payload: newComments,
    });
    dispatch({
      type: ACTIONS.SET_ODC_CURRENT_COMMENT,
      payload: null,
    });
    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_ODC_COMMENT,
      payload: {
        comment: [...winQuote.documents.odc.comments],
        isEditing: false,
      },
    });
  };

  /**
   * Handle to edit te contract comment
   * @param {number} index
   * @param {import("../../../../../../types/winQuote").CommentsI} comentario
   */
  const handleToEditContractComment = (index, comentario) => {
    const comments = winQuote.documents.contract.comments;
    const commentToEdit = comments[index];

    const newComments = comments.map((comment) => {
      if (comment.uuid === commentToEdit.uuid) {
        comment.comment = comentario.comment;
        comment.isEditing = false;
      }
      return comment;
    });

    dispatch({
      type: ACTIONS.SET_EDIT_CONTRACT_COMMENT,
      payload: newComments,
    });
    dispatch({
      type: ACTIONS.SET_CONTRACT_CURRENT_COMMENT,
      payload: null,
    });
    dispatch({
      type: ACTIONS.SET_OPEN_TO_EDIT_CONTRACT_COMMENT,
      payload: {
        comment: [...winQuote.documents.contract.comments],
        isEditing: false,
      },
    });
  };

  /**
   * Handle to open the comment to edit or close it
   * @param {number} index
   * @param {1|2|3|6} idTypeDocument
   * @param {'open'| 'close'} action
   */
  const handleOpenCommentToEdit = (index, idTypeDocument, action) => {
    switch (idTypeDocument) {
      case 2:
        handleOpenToEditOrderComment(index, action);

        break;
      case 3:
        handleOpenToEditOdcComment(index, action);

        break;
      case 6:
        handleOpenToEditContractComment(index, action);
        break;

      default:
        console.log("No hace nada");
        break;
    }
  };

  /**
   * Handle to delete the comment
   * @param {number} index
   * @param {1|2|3|6} idTypeDocument
   */
  const handleOnDeleteComment = (index, idTypeDocument) => {
    switch (idTypeDocument) {
      case 2:
        handleOnDeleteOrderComment(index);

        break;
      case 3:
        handleOnDeleteOdcComment(index);

        break;
      case 6:
        handleOnDeleteContractComment(index);
        break;

      default:
        console.log("No hace nada");
        break;
    }
  };
  /**
   * Handle to edit the comment
   * @param {number} index
   * @param {1|2|3|6} idTypeDocument
   * @param {import("../../../../../../types/winQuote").CommentsI} comentario
   */
  const handleOnEditComment = (index, idTypeDocument, comentario) => {
    switch (idTypeDocument) {
      case 2:
        handleToEditOrderComment(index, comentario);

        break;
      case 3:
        handleToEditOdcComment(index, comentario);

        break;
      case 6:
        handleToEditContractComment(index, comentario);
        break;

      default:
        console.log("No hace nada");
        break;
    }
  };

  /**
   * Validate if the comment can be edited or not
   * @param {import("../../../../../../types/winQuote").CommentsI} elementFromTheArray
   * @param {import("../../../../../../types/winQuote").CommentsI} comment
   * @returns {boolean} If the comment i for editing
   */
  const validateCommentEditing = (elementFromTheArray, comment) => {
    console.log("Valor del comentario");
    console.log(comment);
    console.log("Valor del array");
    console.log(elementFromTheArray.isEditing);

    if (!!comment) {
      if (elementFromTheArray.isEditing) {
        console.log("Si esta editando");
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * Handle the edit of the current item
   * @param {import("../../../../../../types/Partidas/partidas.interfaces").ItemsI} currentItem
   */

  const handleEditItems = (currentItem) => {
    const items = winQuote.items;
    const odcItems = winQuote.documents.odc.documentItems;
    console.log("Items actuales");
    console.log(items);
    console.log("items a editar");
    console.log(currentItem);
    console.log("item de la odc");
    console.log(odcItems);
    const formatedItem = {
      ...currentItem,
      providerDiscount: currentItem.costDiscount,
      clientDiscoount: currentItem.priceDiscount,
      quantity: +currentItem.quantity,
    };
    const index = odcItems.findIndex(
      (odcItem) => odcItem.uuid === currentItem.uuid
    );
    // odcItems[index].cu = currentItem.cu;
    // odcItems[index].quantity = +currentItem.quantity;
    // odcItems[index].providerDiscount = currentItem.costDiscount;
    // odcItems[index].description = currentItem.description;
    // odcItems[index].mxn = currentItem.mxn;
    // odcItems[index].usd = currentItem.usd;
    const newArray = odcItems.filter((item) => item.uuid !== currentItem.uuid);
    newArray.push(formatedItem);
    // console.log(newArray);
    arrayAddItem({
      array: newArray,
      index: index - 1,
      // position:'before',
      itemValue: formatedItem,
    });

    console.log("Nuevo arreglo");
    console.log(newArray);

    dispatch({
      type: ACTIONS.SET_EDIT_ODC_ITEMS,
      payload: newArray,
    });
  };
  const arrayAddItem = ({ array, itemValue, index, position = "after" }) => {
    const increasingCoeff = position === "after" ? 1 : 0;
    return [
      ...array.slice(0, index + increasingCoeff),
      itemValue,
      ...array.slice(index + increasingCoeff),
    ];
  };

  const handleWinQuote = async () => {
    try {
      console.log('Informacion de la moneda: ',winQuote.moneyInfo);
      const hasProvider = winQuote.customer.provider;
      const isForeignSupplier =
        winQuote.customer?.provider?.rfc === "XEXX010101000";
      if (!!hasProvider?.id || !!hasProvider) {
        const { order, odc, quote, contract } = winQuote.documents;

        const orderItems = order.documentItems.map((item) =>
          handleFormItem(
            {
              cost: item.cu.number,
              currency: item.currency.code,
              description: item.description,
              discountCost: item.providerDiscount.number,
              discountSell: item.clientDiscoount.number,
              idCatalogue: item.catalogue.id,
              iva: item.iva.number,
              ivaExempt: item.iva.exempt,
              quantity: item.quantity,
              satCode: item.satCode,
              satUm: item.satUm,
              sell: item.pu.number,
              sku: item.sku,
              uen: item.uen.id,
              satCodeDescription: `${item.satCodeDescription}`,
              satUmDescription: `${item.satUmDescription}`,
            },
            winQuote.moneyInfo.tc.number,
            2,
            {
              rfc: winQuote.customer?.provider?.rfc,
              typeDocument: 3,
            }
          )
        );

        const odcItems = order.documentItems.map((item) =>{
          return handleFormItem(
            {
              cost: item.cu.number,
              currency: item.currency.code,
              description: item.description,
              discountCost: item.providerDiscount.number,
              discountSell: item.clientDiscoount.number,
              idCatalogue: item.catalogue.id,
              iva: item.iva.number,
              ivaExempt: item.iva.exempt,
              quantity: item.quantity,
              satCode: item.satCode,
              satUm: item.satUm,
              sell: item.pu.number,
              sku: item.sku,
              uen: item.uen.id,
              satCodeDescription: `${item.satCodeDescription}`,
              satUmDescription: `${item.satUmDescription}`,
            },
            winQuote.moneyInfo.tc.number,
            2,
            {
              rfc: winQuote.customer?.provider?.rfc,
              typeDocument: 3,
            }
          )
        }
        );


        /**
         * @type{import("../../../../../../types/documentActions").GeneralDocumentI}
         */
        const orderData = {
          comments: order.comments,
          contact: order.contact,
          customer: winQuote.customer.client,
          items: orderItems,
          documentType: 2,
          userToRepresentId: userToRepresentId,
          moneyInfo: winQuote.moneyInfo,
          generateContract: {
            beginDate: winQuote.documents.order.dates.createdDate,
            endDate: winQuote.documents.order.dates.invoiceDate,
            reminderDate: winQuote.documents.order.dates.reminderDate,
          },
        };
        /**
         * @type{import("../../../../../../types/documentActions").GeneralDocumentI}
         */
        const odcData = {
          creditDays: winQuote.creditDays,
          comments: odc.comments,
          contact: odc.contact,
          customer: winQuote.customer.provider,
          items: odcItems,
          documentType: 3,
          userToRepresentId: userToRepresentId,
          moneyInfo: moneyInfoForOdc(winQuote.moneyInfo, isForeignSupplier,odcItems),
          generateContract: {
            beginDate: winQuote.documents.odc.dates.createdDate,
            endDate: winQuote.documents.odc.dates.sendDate,
            reminderDate: winQuote.documents.odc.dates.reminderDate,
          },
        };
        /**
         * @type{import("../../../../../../types/documentActions").GeneralDocumentI | null}
         */
        let contractData = null;
        if (winQuote.interfaceControl.needContract) {
          contractData = {
            comments: contract.comments,
            contact: contract.contact,
            customer: winQuote.customer.client,
            items: contract.documentItems,
            documentType: 6,
            userToRepresentId: userToRepresentId,
            moneyInfo: winQuote.moneyInfo,
            generateContract: {
              beginDate: winQuote.documents.contract.dates.initialDate,
              endDate: winQuote.documents.contract.dates.endDate,
              reminderDate: winQuote.documents.contract.dates.reminderDate,
            },
          };
        } else {
          contractData = null;
        }

        const dataToSubmit = {
          contractData,
          odcData,
          orderData,
          needContract: winQuote.interfaceControl.needContract,
        };

        const doc = {
          ...dataToSubmit,
          idToRepresent: userToRepresentId,
          idQuote: idQuote,
          needContract: winQuote.interfaceControl.needContract,
          moneyInfo: winQuote.moneyInfo,
        };
        // const documentAdded = await addDocument({ data: doc }, documentType);
        // console.log("La data que se manda al ganar");
        // console.log(doc);
        const { data, status } = await winDocument(doc);
        console.log("Se termino de ganar...");
        console.log(data.status);
        onSuccesAdded(data.status, 1);
      } else {
        ErrorAlertText(
          () => {},
          "Error ganar cotizacion",
          "Necesita seleccionar el proveedor"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Handle the succes of the add a document
   * @param {boolean} wasAdded
   * @param {1|2|3|6} documentType
   * @returns {void}
   */
  const onSuccesAdded = (wasAdded, documentType) => {
    if (wasAdded) {
      history.push(`/directorio/documentos/${winQuote.customer.client.id}`);

      Success(() => {}, "Cotización ganada");
      return;
    } else {
      ErrorAlertText(
        () => {},
        "Error ganar cotizacion",
        "Hubo un problema para ganar la cotización"
      );
    }

    return;
  };

  useEffect(() => {
    if (winQuote.documents.odc.documentItems.length > 0) {
      const items = winQuote.documents.odc.documentItems;
      handleCalculateDocumentTotal(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winQuote.documents.odc.documentItems]);

  /**
   * Store the sum of the imports, ivas and margins
   */
  const handleCalculateDocumentTotal = (items) => {
    const { totalCuMxn, totalCuUsd, totalPuMxn, totalPuUsd } =
      ImportDocument(items);
    const { ivaCuMxn, ivaCuUsd, ivaPuMxn, ivaPuUsd } = IvaDocument(items);
    const { marginUSD, marignMXN } = MarginDocument();
    const { TotalCuMXN, TotalCuUSD, TotalPuMXN, TotalPuUSD } =
      TotalDocument(items);

    const isForeignSupplier =
      winQuote.customer?.provider?.rfc === "XEXX010101000";
    /**
     * @type{import("../../../../../../types/documentActions").MoneyInformationI}
     */
    const moneyInfo = {
      ...winQuote.moneyInfo,
      currency: {
        value: winQuote.footer.currency.code,
        id: winQuote.footer.currency.id,
      },
      mxn: createCurrencyObject(
        totalCuMxn,
        totalPuMxn,
        ivaCuMxn,
        ivaPuMxn,
        TotalCuMXN,
        TotalPuMXN,
        marignMXN,
        isForeignSupplier
      ),
      usd: createCurrencyObject(
        totalCuUsd,
        totalPuUsd,
        ivaCuUsd,
        ivaPuUsd,
        TotalCuUSD,
        TotalPuUSD,
        marginUSD,
        isForeignSupplier
      ),
    };

    const currenCurrency = winQuote.footer.currency.code;
    const mxnPurchase = moneyInfo.mxn.importCost;
    const usdPurchase = moneyInfo.usd.importCost;
    const newPurchase = currenCurrency === "MXN" ? mxnPurchase : usdPurchase;

    /**
     * @type {import("../../../../../../types/winQuote").FooterInfoI}
     */
    const newFooter = {
      ...winQuote.footer,
      pursharse: newPurchase,
    };

    dispatch({
      type: ACTIONS.SET_FOOTER,
      payload: newFooter,
    });

    dispatch({
      type: ACTIONS.SET_MONEY_INFORMATION,
      payload: moneyInfo,
    });
  };

  const handleDeleteOdcItem = (index) => {
    const odcItems = winQuote.documents.odc.documentItems;
    const itemToDeleteUuid = odcItems[index].uuid;
    const filterItems = odcItems.filter(
      (item) => item.uuid !== itemToDeleteUuid
    );
    dispatch({
      type: ACTIONS.SET_DELETE_ODC_ITEM,
      payload: filterItems,
    });
  };

  /**
   *
   * @param {string[]} messageError
   * @returns {string} html string
   */
  const htmlMessageError = (messageError) => {
    const liElemt = messageError.map((element) => {
      return `
     <li>
       ${element}
     </li>
     `;
    });

    // console.log(liElemt);

    const htmlError = `
    <ul>
      ${liElemt}
    </ul>
    `;
    return htmlError;
  };

  /**
   * Edit an item
   * @returns {boolean}
   */
  const editItem = () => {
    const messageError = validateData();
    if (messageError.length > 0) {
      const htmlError = htmlMessageError(messageError);
      ErrorAlertText(
        () => {},
        "Ups! No se puede actualizar el articulo",
        "Faltan las siguientes porpiedades",
        htmlError
      );
      return false;
    } else {
      dispatch({
        type: ACTIONS.SET_EDIT_ITEM,
        payload: winQuote.currentItem,
      });
    }
  };

  /**
   * Calculate the number of items diferent from the original document.
   * @return{number} totalItems Number of items with diferent currency from the original document
   */
  const itemsWithDiferentCurrency = () => {
    const documentCurrency = winQuote.moneyInfo?.currency.value;
    const totalItems = winQuote.items.reduce((acumulator, item) => {
      if (item.catalogue.currency !== documentCurrency) {
        acumulator += 1;
      }
      return acumulator;
    }, 0);
    return totalItems;
  };

  /**
   * Handle the multiples provider
   * @param {boolean} value
   */
  const handleMultipleProviders = (value) => {
    if (value) {
      dispatch({
        type: ACTIONS.SET_PROVIDER,
        payload: {
          id: -1,
          isActive: true,
          rfc: "NA",
          socialRazon: "NA",
        },
      });
    } else {
      dispatch({
        type: ACTIONS.SET_PROVIDER,
        payload: null,
      });
    }
    dispatch({
      type: ACTIONS.SET_MULTIPLE_PROVIDERS,
      payload: value,
    });
  };

  const handleDisableOdc = () => {
    let countMXN = 0;
    let countUSD = 0;

    winQuote.items.forEach((element) => {
      if (element.catalogue.currency.code === "MXN") {
        countMXN += 1;
      } else {
        countUSD += 1;
      }
    });

    if (countMXN > 0 && countUSD > 0) {
      dispatch({
        type: ACTIONS.SET_BLOCK_ODC,
        payload: {
          disableCost: true,
          disableCurrency: true,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.SET_BLOCK_ODC,
        payload: {
          disableCost: false,
          disableCurrency: false,
        },
      });
    }

    // SET_BLOCK_ODC
  };

  /**
   * Store the item to edit in the currentItem propertie
   * @param {import("../../../../../../types/documentActions").ItemSelectI} item
   */
  // const handleOnSetcurrentItem=(item)=>{
  //   dispatch({
  //     type:ACTIONS.SET_CURRENT_ITEM,
  //     payload:item
  //   })

  // }

  const functions = {
    commentsToUse,
    defaultContractCommentValue,
    defaultOdcCommentValue,
    defaultOrderCommentValue,
    getNumbers,
    handleContractEndDate,
    handleContractInitialDate,
    handleContractRemainderDate,
    handleCreditDaysChange,
    handleCurrentContactByDocument,
    handleDateLabelsChange,
    handleDocumentsContactChange,
    handleEditItems,
    handleGenerateContract,
    handleIndexActiveMenuChange,
    handleMenuChange,
    handleOdcReminderDate,
    handleOdcSendDate,
    handleOnAddContractComments,
    handleOnAddOdcComments,
    handleOnAddOrderComments,
    handleOnCurrentContractCommentChange,
    handleOnCurrentOdcCommentChange,
    handleOnCurrentOrderCommentChange,
    handleOnDeleteComment,
    handleOnDeleteContractComment,
    handleOnDeleteOdcComment,
    handleOnDeleteOrderComment,
    handleOnEditComment,
    handleOnProviderChange,
    handleOpenCommentToEdit,
    handleOpenToEditContractComment,
    handleOpenToEditOdcComment,
    handleOpenToEditOrderComment,
    handleOrderExpirationDate,
    handleOrderReminderDate,
    handleToEditContractComment,
    handleToEditOdcComment,
    handleToEditOrderComment,
    handleWinQuote,
    pathBreadcrum,
    validateCommentEditing,
    handleDeleteOdcItem,
    itemsWithDiferentCurrency,
    handleMultipleProviders,
    // handleOnSetcurrentItem
  };

  return {
    winQuote,
    functions,
  };
};
