import { Success } from "helpers/alerts";
import { repplyInvoiceAuthorization } from "helpers/Apis/invoice";
import { getAuthorizationInvoice } from "helpers/Apis/todo";
import { useEffect, useState } from "react";

const lastHoursDay = () => {
  const today = new Date();
  today.setHours(23,59,59);
  return today;
}

const INITIAL_STATE = {
  isOpen: false,
  isPerfomingQuery:false,
  isLoading:true,
  repply: "",
  message: "",
  approved: {
    tc: undefined,
    partialities: undefined,
    limitTime: lastHoursDay(),
  },
  isValid: {
    partialities: true,
    tc: true,
  },
}

/**
 * Functions to handle the invoice authorization request
 * @param {number} idInvoice - Id of the invoice
 * @param {number} idTodo - Id of the todo that belongs that invoice authorization
 * @param {()=>void} onChange - Callback executed when the authorization was done (rejected or accepted)
 */
export default function useAttendInvoice(idInvoice,idTodo,onChange=()=>{}) {
  /**
   * State of the component in order to handle an authorization
   * @type {[import("./types").StateUseAttendInvoice,()=>void]}
   */
  const [state, setState] = useState(INITIAL_STATE);

  const idCloseModal = window.crypto.randomUUID()

  useEffect(() => {
    (async function () {

      if(!state.isOpen) return

      const invoice = await getAuthorizationInvoice(idInvoice);
      setState({
        ...state,
        message: invoice.message,
        isValid: {
          partialities: invoice.isValid.partialities,
          tc: invoice.isValid.tc,
        },
        isLoading:false,
        approved:{
          ...state.approved,
          partialities:invoice.partialities,
          tc:invoice.tc
        }
      });
    })();
  }, [state.isOpen]);

  const openModal = () => setState({
    ...state,
    isOpen:true
  });

  /**
   * Close the modal
   */
  const closeModal = () => {
    setState(INITIAL_STATE);
    const dom = document.getElementById(idCloseModal);
    if(dom) dom.click();
  }

  /**
   * Update the limit time to create the invoice
   * @param {Date} limitTime - Limit time to create the invoice
   * @returns {void}
   */
  const updateLimitTime = limitTime => setState({
    ...state,
    approved:{
      ...state.approved,
      limitTime
    }
  })

  /**
   * Update the message repply for the executive who requested the invoice
   * @param {string} repply - Message
   * @returns {void}
   */
  const updateRepply = repply => setState({
    ...state,
    repply
  })

  /**
   * @param {boolean} isPerfomingQuery
   */
  const setIsPerformingQuery = (isPerfomingQuery) => setState({
    ...state,
    isPerfomingQuery
  });

  /**
   * Update the partialities allowed for the requested invoice
   * @param {number} partialities - Partialities
   * @returns {void}
   */
  const updatePartialitiesApproved = partialities => setState({
    ...state,
    approved:{
      ...state.approved,
      partialities
    }
  });

  /**
   * Trigger the attempt authorization in order to accept or reject the auth
   * @param {boolean?} auth - True if the executive attempt to accpet the invoice request
   * @returns {Promise<boolean>} True if the attempt was done
   */
  const attemptAuthorizacion = async(auth = false) => {

    if(state.repply.length>=256) return

    setIsPerformingQuery(true);

    /**
     * @type {import("../../../../types/invoiceAuthorizations").DtoUpdateAuthorizationI}
     */
    const dto = {
      idTodo,
      isAuthorized:auth,
      limitTime:state.approved.limitTime.toISOString(),
      partialities:state.approved.partialities,
      repply:state.repply,
      tc:state.approved.tc
    }

    const wasRepplied = await repplyInvoiceAuthorization(dto);

    if(wasRepplied){
      Success(()=>{},"La autorizacion fue enviada");
      onChange();
      closeModal();
      return true;
    }

    setIsPerformingQuery(false);



    return false
  }

  return {
    repply:state.repply,
    message:state.message,
    isValid: {
      partialities: state.isValid.partialities,
      tc: state.isValid.tc,
    },
    approved: {
      tc: state.approved.tc,
      partialities: state.approved.partialities,
      limitTime: state.approved.limitTime,
    },
    updateRepply,
    updateLimitTime,
    updatePartialitiesApproved,
    isPerfomingQuery:state.isPerfomingQuery,
    isLoading:state.isLoading,
    openModal,
    closeModal,
    attemptAuthorizacion,
    idCloseModal
  };
}
