import React, { useEffect, useState } from "react";
// import Executives from "../../../../../components/general/Forms/Selects/Executives";
import {
  ErrorMessage,
  InputDate,
  InputNumber,
  InputText,
  InputTextArea,
  Select,
} from "../../../../../components/individual/inputs/Inputs";
import { getReminderTags } from "../../../../../helpers/Apis/Documents";
import {
  attentedExecutive,
  attentedStatus,
  comments,
  documentID,
  documentNumber,
  documentType,
  registerDate,
  reminderDate,
  attentionDate,
  userRegistered,
  reminderType,
} from "./InputsInfo";
import CreatableSelect from "react-select/creatable";

export function DocumentTypeInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="d-flex justify-content-center form-group">
      <InputText
        id={documentType.id}
        name={documentType.name}
        htmlFor={documentType.htmlFor}
        labelTxt={documentType.lableText}
        placeholderTxt={documentType.placeholder}
        readonly={documentType.readOnly}
        defaultValue={defaultValue}
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[documentType.name]?.message} />
    </div>
  );
}

export function DocumentIDInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="d-flex justify-content-center form-group">
      <InputNumber
        id={documentID.id}
        name={documentID.name}
        htmlFor={documentID.htmlFor}
        labelTxt={documentID.lableText}
        placeholderTxt={documentID.placeholder}
        readonly={documentID.readOnly}
        defaultValue={defaultValue}
        aditionalClasses="w-75"
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[documentID.name]?.message} />
    </div>
  );
}
export function DocumentNumberInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="d-flex justify-content-center form-group">
      <InputText
        id={documentNumber.id}
        name={documentNumber.name}
        htmlFor={documentNumber.htmlFor}
        labelTxt={documentNumber.lableText}
        placeholderTxt={documentNumber.placeholder}
        readonly={documentNumber.readOnly}
        defaultValue={defaultValue}
        aditionalClasses="w-75"
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[documentNumber.name]?.message} />
    </div>
  );
}
export function RegisterDateInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="d-flex flex-column form-group">
      <InputDate
        id={registerDate.id}
        name={registerDate.name}
        labelText={registerDate.lableText}
        readOnly={registerDate.readOnly}
        dateProp={defaultValue}
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[registerDate.name]?.message} />
    </div>
  );
}
export function UserRegisterInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="d-flex justify-content-center form-group ml-3">
      <InputText
        id={userRegistered.id}
        name={userRegistered.name}
        labelTxt={userRegistered.lableText}
        placeholderTxt={userRegistered.placeholder}
        readonly={userRegistered.readOnly}
        defaultValue={defaultValue}
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[userRegistered.name]?.message} />
    </div>
  );
}

export function ReminderDateInput({ defaultValue, forwardRef, errors }) {
  const [date, setDate] = useState(Date.now());

  return (
    <div className="form-group w-100">
      <InputDate
        id={reminderDate.id}
        name={reminderDate.name}
        labelText={reminderDate.lableText}
        dateProp={defaultValue}
        forwardRef={forwardRef}
        inputChange={(date) => {
          setDate(date);
          console.log(date);
        }}
        date={date}
      />
      <ErrorMessage message={errors[reminderDate.name]?.message} />
    </div>
  );
}

export function RequiredDateInput({ defaultValue, forwardRef, errors }) {
  const [date, setDate] = useState(Date.now());
  return (
    <>
      <div className="form-group w-100 ml-2">
        <InputDate
          id={attentionDate.id}
          name={attentionDate.name}
          labelText={attentionDate.lableText}
          dateProp={defaultValue}
          forwardRef={forwardRef}
          inputChange={(date) => {
            setDate(date);
            console.log(date);
          }}
          date={date}
        />
        <ErrorMessage message={errors[attentionDate.name]?.message} />
      </div>
    </>
  );
}
export function DateSectionInputs({
  remidnerDefaultValue,
  defaultDate,
  reloadDates,
  forwardRef,
  errors,
}) {
  const todayDate = new Date(Date.now());
  const tomorroDate = todayDate.setDate(todayDate.getDate() + 1);
  const [dateRemainder, setDateReminder] = useState(tomorroDate);
  const [dateAttention, setAttentionDate] = useState(tomorroDate);
  const [minDate, setMinDate] = useState(Date.now());

  useEffect(() => {
    console.log("Dibujando fechas");
    console.log("Escuchando reloadDates");
    setDateReminder(tomorroDate);
    setAttentionDate(tomorroDate);
  }, [reloadDates]);
  return (
    <>
      <div className="form-group w-100">
        <InputDate
          id={reminderDate.id}
          name={reminderDate.name}
          labelText={reminderDate.lableText}
          forwardRef={forwardRef}
          inputChange={(date) => {
            setDateReminder(date);
            console.log("Tiene valor por defecot?");
            console.log(remidnerDefaultValue);
            const remind = new Date(date);
            const attention = new Date(dateAttention);
            console.log(
              "fecha de atencion: ",
              attention,
              "Atencion mayor a recordatorio: ",
              attention.getTime() <= remind.getTime()
            );
            if (attention.getTime() <= remind.getTime()) {
              setAttentionDate(date);
              setMinDate(date);
            } else {
              setMinDate(remind);
            }
            // console.log(date);
          }}
          date={dateRemainder}
        />
        <ErrorMessage message={errors[reminderDate.name]?.message} />
      </div>
      <div className="form-group w-100 ml-2">
        <InputDate
          id={attentionDate.id}
          name={attentionDate.name}
          labelText={attentionDate.lableText}
          forwardRef={forwardRef}
          inputChange={(date) => {
            const attention = new Date(date);
            const remind = new Date(dateRemainder);
            if (attention.getTime() >= remind.getTime()) {
              setAttentionDate(date);
            } else {
              setAttentionDate(dateRemainder);
            }
          }}
          date={dateAttention}
          minDate={minDate}
        />
        <ErrorMessage message={errors[attentionDate.name]?.message} />
      </div>
    </>
  );
}

// ReminderExpirationI {object:}

/**
 *  Range date
 * @param {object:import("types/typedef/customHooks/useEditDocument").ReminderExpirationI} props - Props
 * @returns {JSX.Element}
 */
export function DateSectionInputsV2({
  forwardRef,
  onReminderChange = () => {},
  onAttenChange = () => {},
  dateRemainder,
  dateAttention,
  minDate = new Date(),
  errors = {},
  reminderName = reminderDate.name,
  attentionName = attentionDate.name,
  attentionLabel = attentionDate.lableText,
}) {
  const todayDate = new Date(Date.now());
  const tomorroDate = todayDate.setDate(todayDate.getDate() + 1);

  return (
    <>
      <div className="form-group w-100">
        <InputDate
          id={attentionDate.id}
          name={attentionName}
          labelText={attentionLabel}
          forwardRef={forwardRef}
          inputChange={(date) => {
            onAttenChange(date);
          }}
          date={dateAttention}
          minDate={minDate}
        />
        <ErrorMessage message={errors[attentionName]?.message} />
      </div>
      <div className="form-group w-100">
        <InputDate
          id={reminderDate.id}
          name={reminderName}
          labelText={reminderDate.lableText}
          forwardRef={forwardRef}
          inputChange={(date) => onReminderChange(date)}
          date={dateRemainder}
          maxDate={dateAttention}
        />
        <ErrorMessage message={errors[reminderName]?.message} />
      </div>
    </>
  );
}

export function CommnetsInpputs({ forwardRef, value, errors, onChange }) {
  return (
    <div className="form-group">
      <InputTextArea
        id={comments.id}
        name={comments.name}
        labelTxt={comments.lableText}
        placeholderTxt={comments.placeholder}
        cols={50}
        rows={10}
        readonly={comments.readOnly}
        defaultValue={value}
        forwardRef={forwardRef}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <ErrorMessage message={errors[comments.name]?.message} />
    </div>
  );
}

export function ReminderEstatusInput({ defaultValue, forwardRef, errors }) {
  return (
    <div className="form-group w-100 ml-2">
      <InputText
        id={attentedStatus.id}
        name={attentedStatus.name}
        labelTxt={attentedStatus.lableText}
        placeholderTxt={attentedStatus.placeholder}
        readonly={attentedStatus.readOnly}
        defaultValue={!defaultValue ? "No atendido" : "Atendido"}
        forwardRef={forwardRef}
      />
      <ErrorMessage message={errors[attentedStatus.name]?.message} />
    </div>
  );
}

export function ReminderTypeInput({ forwardRef, errors }) {
  const [typeReminder, setTypeReminder] = useState([]);
  const [valueType, setValueType] = useState(-1);

  async function getTags() {
    const type = await getReminderTags();
    // console.log("Tipos:");
    // console.log(type.data[0]);
    // console.log(type?.data)
    if (type.status === 200) {
      setTypeReminder(type.data[0]);
    }
  }
  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="form-group w-100 ml-2 ">
      <Select
        options={typeReminder}
        addTodos={true}
        nameSelect={reminderType.name}
        idSelect={reminderType.id}
        label={reminderType.lableText}
        value={valueType}
        textJSON="descriptionType"
        valueJSON="commentsTypesId"
        forwardRef={forwardRef}
        initialOption="Seleccione"
        onChange={(value) => {
          setValueType(value);
        }}
      />

      <ErrorMessage message={errors[reminderType.name]?.message} />
    </div>
  );
}
export function ReminderTagInput({
  forwardRef = {},
  errors = {},
  params,
  handleOnchange,
  label,
  value,
}) {
  const [tagReminder, setTagReminder] = useState([]);

  async function getTags() {
    const type = await getReminderTags(params);
    if (type.status === 200) {
      setTagReminder(type.data[0]);
    }
  }
  useEffect(() => {
    getTags();
  }, [params]);

  return (
    <div className="form-group w-100  ml-2 ">
      {label !== null ? (
        <label id={`lbl_${reminderType.id}`}>{label}</label>
      ) : null}
      <CreatableSelect
        id={reminderType.id}
        name={reminderType.name}
        classNamePrefix="saiko-select"
        className="saiko-select"
        isClearable={true}
        options={tagReminder}
        placeholder="Selecciona o crea"
        ref={forwardRef}
        value={value}
        tabIndex={8}
        onChange={(e) => {
          handleOnchange(e);
        }}
      />

      <ErrorMessage message={errors[reminderType.name]?.message} />
    </div>
  );
}
