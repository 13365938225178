import React, { useRef, useState, useEffect } from "react";
import grid from "./styles.module.scss";
import GridLaoding from "components/individual/GridLoading";
import { MONTHS } from "helpers/dates";
import { limitDecimals } from "helpers/money";

const INITIAL_STATE = {
  feeOperation: {},
};

const Header = () => (
  <div className={grid.header}>
    <div>UEN</div>
    <div>Total</div>
    <div>Ene</div>
    <div>Feb</div>
    <div>Mar</div>
    <div>Abr</div>
    <div>May</div>
    <div>Jun</div>
    <div>Jul</div>
    <div>Ago</div>
    <div>Sep</div>
    <div>Oct</div>
    <div>Nov</div>
    <div>Dic</div>
  </div>
);

/**
 * Render the uen grid for fee
 * @param {import("./types").PropsGridUen} props - Props
 * @returns {JSX.Element}
 */
export default function GridUen({
  uens = [],
  canDisplayGrid = false,
  isLoading = true,
  fee = undefined,
  onChange = () => {},
  decimals = 2
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (!canDisplayGrid || uens.length <= 0) {
      setState((current) => ({
        ...current,
        feeOperation: {},
      }));
      return;
    }

    const emptyFee = uens.reduce(
      (indexed, uen) => ({
        ...indexed,
        [uen.UENID]: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
          total: "0",
        },
      }),
      {}
    );

    setState((current) => ({
      ...current,
      feeOperation: fee === undefined ? emptyFee : fee,
    }));
  }, [fee, uens, canDisplayGrid]);

  useEffect(() => {
    onChange(state.feeOperation);
  }, [state.feeOperation]);

  /**
   * Set the value for the grid column item
   * @param {number} uen - Id of the uen
   * @param {number} quantity - Quantity
   * @param {number} month - Month to update
   */
  const setUnit = (uen, quantity, month) => {
    const newTotal = Object.entries(state.feeOperation[uen]).reduce(
      (indexed, [key, value], i) => {
        if (i >= 12) return indexed;

        if (month === i + 1) {
          return +(indexed += quantity).toFixed(decimals);
        }

        return +(indexed += state.feeOperation[uen][i + 1]).toFixed(decimals);
      },
      0
    );

    setState((current) => ({
      ...current,
      feeOperation: {
        ...current.feeOperation,
        [`${uen}`]: {
          ...current.feeOperation[`${uen}`],
          [month]: quantity,
          total: newTotal,
        },
      },
    }));
  };

  const key = useRef(window.crypto.randomUUID());

  if (!canDisplayGrid)
    return <p className={grid.fillData}></p>;

  if (isLoading)
    return (
      <div className={grid.container}>
        <Header />
        <div className={grid.containerRows}>
          <GridLaoding />
        </div>
      </div>
    );

  if (uens.length > 0)
    return (
      <div className={grid.container}>
        <Header />
        <div className={grid.containerRows}>
          {uens.map((uen, i) => (
            <div key={`${key.current}-${i}`}>
              <div className={grid.row}>
                <p>{uen.description}</p>
                <p className="justify-content-end">
                  {state.feeOperation[uen.UENID]?.total}
                </p>

                {MONTHS.map((month, i) => (
                  <input
                    key={`${key.current}-${uen.UENID}-${i}`}
                    type="number"
                    min={0}
                    required
                    step={0.01}
                    className="text-right"
                    value={state.feeOperation[uen.UENID]?.[`${i + 1}`]}
                    onChange={(e) =>
                      setUnit(uen.UENID, limitDecimals(+e.target.value,decimals), i + 1)
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}
