import useDialog from "customHooks/useDialog";
import { getCreditNotesEmited } from "helpers/Apis/Administracion/InvoiceEmitted/InvoiceEmittedApi";
import { downloadFileFromBlobV3 } from "helpers/Apis/associateFilesV2";
import { dateToDbFormat, monthRange } from "helpers/dates";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import { openFileOnNewTab } from "helpers/dom";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("./types").StateUseNotasDeCredito}
 */
const INITIAL_STATE = {
  creditNote: undefined,
  creditNotes: [],
  isSearching: true,
  currentPage: 1,
  isDownloadingExcel:false,
  pages: 0,
  search: "",
  beginDate: monthRange().firstDay,
  endDate: monthRange().lastDay,
  customer: null,
  status: null,
  refetchFlag: true,
  isDownloadingPdf: false,
  isDownloadingXml: false,
  contabilidad:null,
  gridAccounting: {
    original: {},
    operation: {},
  },
};

/**
 * @type {import("./types").ReturnUseNotasDeCredito}
 */
export const CONTEXT_USE_CREDIT_NOTES = {
  ...INITIAL_STATE,
  handleDownlaodExcel:async()=>{},
  breadcrum: [],
  setCreditNote: () => {},
  updateBeginDate: () => {},
  updateEndDate: () => {},
  updateSearch: () => {},
  setAccountingStatus:()=>{},
  attemptSearch: () => {},
  updateStatus: () => {},
  updatePage: () => {},
  refetchTable: () => {},
  dialog: undefined,
  toggleAccounting:()=>{},
  redirectToFiles: () => {},
  handleDownloadPdf: async () => {},
  handleDownloadXml: async () => {},
  gridAccounting:{
    operation:{},
    original:{}
  }
};

/**
 * Hook to handle create credit notes
 * @param {import("./types").ModuleCreditNotes} [module="ventas"] - Module for the fetching data
 * @returns {import("./types").ReturnUseNotasDeCredito}
 */
export default function useNotasDeCredito(module = "ventas") {
  const [state, setState] = useState(INITIAL_STATE);

  usePermissions(module==="ventas" ? "d91b10ef-8586-4073-857a-9829f8f0512d" : "103e2d9f-a663-4fab-a396-66b4923d3aa5");

  const dialog = useDialog();

  const history = useHistory();

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isSearching: true,
        creditNote: undefined,
      }));

      const response = await getCreditNotesEmited({
        beginDate: dateToDbFormat(state.beginDate),
        endDate: dateToDbFormat(state.endDate),
        customerId: state.customer,
        page: state.currentPage,
        search: state.search,
        statusId: state.status,
        contabilidad:state.contabilidad,
        asExcel:false
      });

      if(response instanceof Blob) return;

      const { nce , pages } = response;

      const indexed = nce.reduce((indexed,nc)=>({...indexed,[nc.id]:nc.isAccounted}),{})

      setState((current) => ({
        ...current,
        isSearching: false,
        creditNotes: nce,
        pages: pages.pages,
        currentPage: pages.actualPage,
        gridAccounting:{
          operation:indexed,
          original:indexed
        }
      }));
    })();
  }, [state.refetchFlag, state.currentPage]);

  /**
   * Update the credit note to operate
   * @param {import("../../../../../../types/NC/nce").GetNceI} creditNote - Credit note information
   * @returns {void}
   */
  const setCreditNote = (creditNote) =>
    setState((current) => ({
      ...current,
      creditNote,
    }));

  const handleDownlaodExcel = async() => {

    setState(current=>({
      ...current,
      isDownloadingExcel:true
    }));

    const response = await getCreditNotesEmited({
      beginDate: dateToDbFormat(state.beginDate),
      endDate: dateToDbFormat(state.endDate),
      customerId: state.customer,
      page: state.currentPage,
      search: state.search,
      statusId: state.status,
      contabilidad:state.contabilidad,
      asExcel:true
    });

    if(response instanceof Blob){
      saveAs(response,`Contabilidad - Notas de crédito.xlsx`);
    }

    setState(current=>({
      ...current,
      isDownloadingExcel:false
    }));


  }

  const handleDownloadPdf = async () => {
    setState((current) => ({
      ...current,
      isDownloadingPdf: true,
    }));

    const pdf = await downloadFileFromBlobV3(state.creditNote.pdf);

    saveAs(pdf, `Nota de crédito ${state.creditNote.documentNumber}.pdf`);

    openFileOnNewTab(pdf);

    setState((current) => ({
      ...current,
      isDownloadingPdf: false,
    }));
  };

  /**
   * 
   * @param {number} id - Id  
   * @param {boolean} checked - Check
   */
  const toggleAccounting = (id,checked) => {
    setState(current=>({
      ...current,
      gridAccounting:{
        original:current.gridAccounting.original,
        operation:{
          ...current.gridAccounting.operation,
          [id]:checked
        },
      }
    }))
  }

  const handleDownloadXml = async () => {
    setState((current) => ({
      ...current,
      isDownloadingXml: true,
    }));

    const file = await downloadFileFromBlobV3(state.creditNote.xml);

    saveAs(file, `Nota de crédito ${state.creditNote.documentNumber}.xml`);

    setState((current) => ({
      ...current,
      isDownloadingXml: false,
    }));
  };

  const updateBeginDate = (beginDate) =>
    setState((current) => ({
      ...current,
      beginDate,
    }));

  const updateEndDate = (endDate) =>
    setState((current) => ({
      ...current,
      endDate,
    }));

  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const attemptSearch = () => {
    setState((current) => ({
      ...current,
      currentPage: 1,
      refetchFlag: !current.refetchFlag,
    }));

    dialog.close();
  };

  const redirectToFiles = () =>
    history.push(
      `/ventas/notas-de-credito/archivos-asociados/${state.creditNote.id}`
    );

  const refetchTable = () =>
    setState((current) => ({
      ...current,
      refetchFlag: !current.refetchFlag,
    }));

  /**
   * Update
   * @param {number|null} status - Status for the credit notes emited
   */
  const updateStatus = (status) => {
    setState((current) => ({
      ...current,
      status,
    }));
  };

  const updatePage = (page) =>
    setState((current) => ({
      ...current,
      currentPage: page,
    }));

  /**
   * Set the status to fetch the accounted records
   * @param {boolean|null} contabilidad - Status
   * @returns {void}
   */
  const setAccountingStatus = contabilidad => setState(current=>({
    ...current,
    contabilidad
  }));

  return {
    ...state,
    setAccountingStatus,
    handleDownlaodExcel,
    handleDownloadXml,
    updateStatus,
    updatePage,
    setCreditNote,
    updateBeginDate,
    updateEndDate,
    updateSearch,
    redirectToFiles,
    toggleAccounting,
    refetchTable,
    dialog,
    handleDownloadPdf,
    attemptSearch,
    breadcrum: [
      {
        route: "/",
        text: "Inicio",
      },
      {
        route: "/",
        text: module === "ventas" ? "Ventas" : "Contabilidad",
      },
      {
        route: "/",
        text: "Notas de crédito emitidas",
      },
    ],
  };
}
