import useDialog from "customHooks/useDialog";
import { createCreditNote } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { calculateDiscount, truncateDecimals } from "helpers/money";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateUseCreateCreditNotes}
 */
const INITIAL_STATE = {
  isOpen: false,
  isCreatingCreditNote: false,
  invoice: undefined,
  concept: "",
  importe: 0,
  total: 0,
};

const dummyFunction = () => {};
const promiseDummyFunction = async() => {};

/**
 * @type {import("./types").ReturnUseCreateCreditNotes}
 */
export const CONTEXT_INITIAL_STATE = {
  ...INITIAL_STATE,
  handleOpen: dummyFunction,
  handleClose: dummyFunction,
  updateInvoice: dummyFunction,
  updateConcept: dummyFunction,
  updateAmount: dummyFunction,
  attemptCreateCreditNote:promiseDummyFunction,
  dialog: {
    close: dummyFunction,
    open: dummyFunction,
    refDialog: undefined,
  },
};

/**
 *  Custom hook to handle the creation of a credit note
 * @param {()=>void?} onCreated - Function to executed when credit note is created with success
 * @returns {import("./types").ReturnUseCreateCreditNotes}
 */
export default function useCreateCreditNotes(onCreated = () => {}) {
  const hook = useDialog();

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (state.invoice === undefined) return;

    const taxasCalculation = state.invoice.invoiceTaxas.reduce(
      (taxasTotal, percentageTaxa) => {
        const taxaCalculated = truncateDecimals(
          truncateDecimals(
            (percentageTaxa.percentage / 100) * state.importe,
            2
          ),
          2
        );

        return truncateDecimals(taxasTotal + taxaCalculated, 2);
      },
      0
    );

    setState((current) => ({
      ...current,
      total: truncateDecimals(current.importe + taxasCalculation, 2),
    }));
  }, [state.importe]);

  const handleOpen = () => {
    hook.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    hook.close();
    setState(INITIAL_STATE);
  };

  /**
   * Update the invoice to use for the credit note
   * @param {import("../../../../../types/NC/nce").InvoiceComboI} invoice - Information of the invoice selected
   * @returns {void}
   */
  const updateInvoice = (invoice) =>
    setState((current) => ({
      ...current,
      invoice,
      concept: "",
      importe: 0,
    }));

  /**
   * Update the concept to use for the credit note
   * @param {string} concept - Concept
   * @returns {void}
   */
  const updateConcept = (concept) =>
    setState((current) => ({
      ...current,
      concept,
    }));

  const updateAmount = (importe) =>
    setState((current) => ({
      ...current,
      importe,
    }));

  const attemptCreateCreditNote = async () => {
    setState((current) => ({
      ...current,
      isCreatingCreditNote: true,
    }));

    const wasCreated = await createCreditNote(
      state.importe,
      state.concept,
      state.invoice.uuid
    );

    if(wasCreated){
      onCreated()
    }

    handleClose();

    setState((current) => ({
      ...current,
      isCreatingCreditNote: false,
    }));
  };

  return {
    ...state,
    dialog: hook,
    handleOpen,
    handleClose,
    updateInvoice,
    updateAmount,
    updateConcept,
    attemptCreateCreditNote,
  };
}
