import Select from "components/individual/SelectV4";
import { getServicesLogs } from "helpers/Apis/logs";
import { ContextLogs } from "pages/Log/LogEmail";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "structure/Document";

export default function ProviderSelect() {
  const hook = useContext(ContextLogs);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async function () {
      const services = await getServicesLogs();

      setOptions(services);
    })();
  }, []);

  if (options.length <= 0) return <div></div>;

  return (
    <div>
      <Label>Servicio</Label>
      <Select
        onChange={(data) => hook.setProvider(data.id)}
        labelToDisplay="name"
        defaultSelected={{
          keyToCompare: "id",
          value: hook.proveedor,
        }}
        options={async () => options}
      />
    </div>
  );
}
