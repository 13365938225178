import {
  SET_DATA,
  SET_LOADING_DATA,
  SET_FOUNDED_ROWS,
  SET_PAGES,
  SET_INFO_ROW,
  SET_ORDER_BY,
  SET_CRYTERIAN_ORDER,
  SET_ICON_ORDERING,
  SET_ACTUAL_PAGE,
  SET_RENDER,
  SET_RELOAD,
  SET_CALL_API,
  SET_LENGTH_DATA,
} from "../../types/index";

import { rowsPerPage } from "../../static/TableConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case SET_CALL_API:
      return {
        ...state,
        callAPI: action.payload,
      };

    case SET_LOADING_DATA:
      return {
        ...state,
        isFetching: action.payload,
      };

    case SET_FOUNDED_ROWS:
      return {
        ...state,
        rows: action.payload,
      };

    case SET_PAGES:
      const pages = Math.ceil(state.rows / rowsPerPage);
      return {
        ...state,
        pages: pages,
      };

    case SET_INFO_ROW:
      return {
        ...state,
        infoRow: action.payload,
      };

    case SET_RELOAD:
      return {
        ...state,
        refetch: action.payload,
      };

    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      };

    case SET_CRYTERIAN_ORDER:
      return {
        ...state,
        criterianOrder: action.payload,
      };

    case SET_RENDER:
      return {
        ...state,
        render: action.payload,
      };

    case SET_ICON_ORDERING:
      return {
        ...state,
        iconOrdering: action.payload,
      };

    case SET_ACTUAL_PAGE:
      return {
        ...state,
        actualPage: action.payload,
      };
    case SET_LENGTH_DATA:
      return {
        ...state,
        lengthData: action.payload,
      };
    default:
      break;
  }
}
