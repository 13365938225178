import React, { useRef, useContext } from "react";
import { CONTEXT_HOME } from "../Inicio";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";
import { Pagination } from "components/individual/Pagination";
import { mnCurrency } from "helpers/money";
import { parseDateToText } from "helpers/dates";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import MobileItemHome from "./MobileItem";

export default function TableQuotes() {
  const hook = useContext(CONTEXT_HOME);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingPendingQuotes"
        text={<LoadingText>Cargando cotizaciones abiertas</LoadingText>}
      />
    );

  return (
    <div className={style.table}>
      <div className={style.header}>
        <p>Emisión</p>
        <p>Cotización</p>
        <p>Cliente</p>
        <p>Moneda</p>
        <p>Total</p>
        <p>Vigencia</p>
        <p>No. contrato</p>
        <p>UEN</p>
        <p>Credor por</p>
      </div>

      <div className={style.bodyTable}>
        {hook.quotes.map((quote, i) => (
          <div key={`pendingQuote-${key.current}-${i}`}>
            <input
              type="radio"
              id={`pendingQuote-${key.current}-${i}`}
              name={`pendingQuotes${key.current}`}
              onChange={(e) => hook.selectQuote(quote)}
            />
            <label
              className={style.row}
              htmlFor={`pendingQuote-${key.current}-${i}`}
            >
              <p>{parseDateToText(new Date(quote.createdDate))}</p>

              <ModalDocsRelatedV2
                fromLegal={false}
                idDocument={+quote.idDocument}
              >
                <p >{quote.documentNumber}</p>
              </ModalDocsRelatedV2>

              <p>{quote.customer.socialReason}</p>
              <p>{quote.currency}</p>

              <p className="text-right">{mnCurrency(quote.totalAmount)}</p>
              <p>{parseDateToText(quote.expirationDate)}</p>
              <p className="text-right">
                {typeof quote.contract.folio === "string"
                  ? quote.contract.folio
                  : "NA"}
              </p>
              <p className={style.uenContainer}>
                {quote.uen.map((item, j) => (
                  <span key={window.crypto.randomUUID()}>
                    {item.description}
                  </span>
                ))}
              </p>
              <p>
                {quote.wasGeneratedByRobot
                  ? "Sistema (Renovacion)"
                  : quote.createdBy}
              </p>
            </label>

            <MobileItemHome {...quote} />
          </div>
        ))}
      </div>
      <Pagination
        actualPage={hook.currentPage}
        pages={hook.pages}
        onChange={(page) => hook.updatePage(page)}
      />
    </div>
  );
}
