import React, { Fragment, createContext, useContext } from "react";
import Dialog from "structure/Dialog";
import { ContextPaymentReminder } from "..";
import Content from "./Content";
import Footer from "./Footer";

/**
 * Context to handle the more information
 * @type {import("react").Context<import("./useMoreInfoPaymentReminder/types").ReturnUseMoreInfoPaymentReminder|undefined>}
 */
export const ContextMoreInfoPaymentReminder = createContext(undefined);

export default function MoreInfo() {
  const hook = useContext(ContextPaymentReminder);

  if (!hook.moreInfo.isOpen) return <></>;

  return (
    <ContextMoreInfoPaymentReminder.Provider
      value={{
        ...hook.moreInfo,
      }}
    >
      <Dialog
        forwardRef={hook.moreInfo.dialog.refDialog}
        width={hook.moreInfo.contacts.length <= 0 ? 50 : 75}
        title="Más información"
        footer={<Footer socialReason={hook.reminderSelected.customer.socialReason}/>}
        closeCallback={() => hook.moreInfo.handleModal(false)}
      >
        <Fragment>
          <Content />
        </Fragment>
      </Dialog>
    </ContextMoreInfoPaymentReminder.Provider>
  );
}
