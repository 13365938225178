import * as yup from "yup";

const schema = yup.object({
  title: yup
    .string()
    .required("Obligatorio")
    .max(256, "Solo debe contener 256 caracteres"),
  description: yup.string().required("Obligatorio"),
  jamLink: yup.string().matches("^https://jam.dev/c/.*", {
    message: "Debe ser una liga de jam o dejar el campo vacío",
    excludeEmptyString: true,
  }),
});

export default schema;
