import { Spinner } from "components/individual/Spinner/Spinner";
import { addTicketComment } from "helpers/Apis/tickets";
import React from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "./styles.scss";

/**
 *
 * @param {import("./types").PropsCommentBox} props - Props
 * @returns
 */
export default function TicketCommentBox({ ticket, onChange = () => {} }) {
  const [comment, setComment] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  const attemptAddComment = async () => {
    setIsAdding(true);

    const wasAdded = await addTicketComment(ticket, comment);

    setIsAdding(false);

    if (wasAdded) {
      setComment("");
      onChange();
    }
  };

  if (isAdding) <Spinner hidden={false} text="Agregando comentario" />;

  return (
    <>
      <div className="ticketCommentBox">
        <ReactQuill
          theme="snow"
          value={comment}
          onChange={setComment}
          preserveWhitespace
        />
      </div>
      <button
        className="attemptCommentTicket"
        onClick={attemptAddComment}
        disabled={comment.length <= 0}
      >
        Comentar
      </button>
    </>
  );
}
