import React, { useContext } from "react";
import Executives from "components/general/Forms/Selects/Executives/index";
import { ContextHistoricalContracts } from "..";

export default function HistoricalContractsExecutive() {
  const hook = useContext(ContextHistoricalContracts);

  if (!hook.queryParamsLoaded) return <></>;

  return (
    <Executives
      onChange={(executive) =>
        hook.setExecutive(executive.value, executive.TEXT)
      }
      addAllOption={true}
      defaultValue={hook.executive}
    />
  );
}
