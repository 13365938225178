import { Select } from "components/individual/inputs/Inputs";
import React, { useEffect } from "react";
import { useContext } from "react";
import { UenContext } from "..";

export const UenSelect = ({
  disableFirstOption = true,
  firstOption = "Selecciona una UEN",
  value = null,
  onChange= ()=>{}
}) => {
  useEffect(() => {
    if (!!value) {
      handleOnChange(value);
    }else{
      handleOnChange(null);
    }
  }, [value]);

  const { selected, options, handleOnChange } = useContext(UenContext);

  return (
    <Select
      initialOption={firstOption}
      value={selected?.UENID || value}
      textJSON="description"
      valueJSON="UENID"
      options={options}
      onChange={(id) => handleOnChange(+id)}
      disableInitlaOption={disableFirstOption}
      cb={onChange}
    />
  );
};
