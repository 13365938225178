import React, { createContext, useContext, useRef } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { FilterContainer } from "./styles";
import { ContextInvoiceReception } from "../index";
import ModalV2 from "components/individual/Modals/ModalV2";
import useAddReceptionInvoice from "../Hooks/useAddReceptionInvoice/useAddReceptionInvoice";
import Step1 from "../Steps/Step1";
import Step2 from "../Steps/Step2";
import Step3 from "../Steps/Step3";
import ButtonContinueStep1 from "../Steps/Step1/BtnContinue";
import ButtonContinueStep2 from "../Steps/Step2/BtnContinue";
import ButtonContinueStep3 from "../Steps/Step3/BtnContinue";
import Step1NoFiscalInvoiceBtn from "../Steps/NoFislcaInvoice/BtnContinue";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import PdfPreview from "components/general/PdfPreview";
import NoFiscalInvoice from "../Steps/NoFislcaInvoice";
import { Modal } from "components/general/Modal";
import ExcelInvoiceReception from "../Excel";
import Icon from "components/individual/HeaderIcon";
import DateFilter from "./DateFilter";
import SearchBar from "./SearchBar";
import StatusCombo from "./StatusCombo";
import filter from "./styles.module.scss";

/**
 * Context for the application
 * @type {React.Context<import("types/typedef/context/AddReceptionInvoiceContext").AddReceptionInvoiceContextI|undefined>}
 */
export const AddReceptionInvoiceContext = createContext(undefined);
const { Provider } = AddReceptionInvoiceContext;

export default function FilterSearch() {
  const idFiscalModalClose = useRef("modalFiscal");
  const idNonFiscalModalClose = useRef("modalNoFiscal");

  const {
    isForAccounting,
    fetchPage,
    state: stateInvoiceRecepcion,
  } = useContext(ContextInvoiceReception);

  const ButtonContinue = {
    0: <ButtonContinueStep1 />,
    1: <ButtonContinueStep2 />,
    2: <ButtonContinueStep3 idCloseModal={idFiscalModalClose.current} />,
  };

  const CurrentStep = {
    0: <Step1 />,
    1: <Step2 />,
    2: <Step3 />,
  };

  const ForeignInvoice = {
    0: <NoFiscalInvoice />,
    1: <Step2 />,
    2: <Step3 />,
  };

  const ButtonContinueForeignInvoice = {
    0: <Step1NoFiscalInvoiceBtn />,
    1: <ButtonContinueStep2 />,
    2: <ButtonContinueStep3 idCloseModal={idNonFiscalModalClose.current} />,
  };

  const {
    setStep,
    state,
    setXmlInfo,
    setGridInfo,
    setShowGrid,
    setConcept,
    setProvider,
    setPartialities,
    setItsOnlyExpenses,
    AddInvoiceReception,
    showPdfView,
    setPdfFile,
    uploadEgress,
    resetProgress,
    setEmittedDate,
    setSocialReason,
    setFolio,
    setIva,
    setCreditDays,
    setExpirationDate,
    setRfc,
    setRfcEnterprise,
    setTotal,
    setCurrency,
    isValidRfc,
    isValidFolio,
    isValidTotal,
    isValidCurrency,
    isValidCreditDays,
    isValidExpirationDate,
    canContinueNonFiscalInvoice,
    idFiscalOpen,
    idNoFiscalOpen,
    openModalInvoiceLoads,
    openFiscalLoad,
    openNoFiscalLoad,
  } = useAddReceptionInvoice();

  return (
    <FilterContainer
      id="invoiceReceptionFilter"
      onSubmit={(e) => {
        e.preventDefault();
        fetchPage(1);
      }}
    >
      <SearchBar />

      <div className={filter.status}>
        <StatusCombo />
      </div>

      <div className={filter.date}>
        <DateFilter />
      </div>

      <ButtonV2 form="invoiceReceptionFilter">
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>
      <Provider
        value={{
          setStep,
          state,
          setProvider,
          setXmlInfo,
          setGridInfo,
          setShowGrid,
          setConcept,
          setPartialities,
          setItsOnlyExpenses,
          AddInvoiceReception,
          showPdfView,
          setPdfFile,
          uploadEgress,
          setEmittedDate,
          setSocialReason,
          setFolio,
          setIva,
          setCreditDays,
          setExpirationDate,
          setRfc,
          setRfcEnterprise,
          setTotal,
          setCurrency,
          isValidRfc,
          isValidFolio,
          isValidTotal,
          isValidCurrency,
          isValidCreditDays,
          isValidExpirationDate,
          canContinueNonFiscalInvoice,
        }}
      >
        {isForAccounting ? null : (
          <Modal
            title="Agregar factura"
            propsOpenModal={{
              type: "button",
              children: (
                <>
                  <span>Agregar</span>
                  <Icon icon="plus" />
                </>
              ),
            }}
          >
            {({ closeModal }) => (
              <>
                <h4 className="text-center mb-3">
                  Seleccciona el tipo de factura
                </h4>

                <div className="d-flex justify-content-center">
                  <ButtonV2
                    type="button"
                    onClick={() => {
                      openFiscalLoad();
                      closeModal();
                    }}
                    className="mr-3"
                  >
                    Fiscal
                  </ButtonV2>
                  <ButtonV2
                    type="button"
                    onClick={() => {
                      openNoFiscalLoad();
                      closeModal();
                    }}
                  >
                    No fiscal
                  </ButtonV2>
                </div>
              </>
            )}
          </Modal>
        )}

        <ModalV2
          width={state.step === 1 ? 85 : 55}
          idOpenModal={idFiscalOpen.current}
          buttonText="Cargar"
          // aditionalClasses="mr-2"
          idCloseIcon={idFiscalModalClose.current}
          showCancelButton={false}
          close="Cerrar"
          title="Recepcion de factura"
          cbReject={resetProgress}
          footer={
            <>
              <PdfPreview pdf={state.xmlInfo?.pdf?.file} />
              {ButtonContinueForeignInvoice[state.step]}
            </>
          }
        >
          <Steps current={state.step | 0} className="my-4" onChange={setStep}>
            <Steps.Step title="Cargar factura" />
            <Steps.Step title="Acreditar ODC" disabled={state.step <= 1} />
            <Steps.Step title="CxP" disabled={state.step <= 2} />
          </Steps>

          {CurrentStep[state.step]}
        </ModalV2>

        <ModalV2
          width={state.step === 1 ? 85 : 55}
          buttonText="Agregar"
          idOpenModal={idNoFiscalOpen.current}
          showCancelButton={false}
          // aditionalClasses=""
          idCloseIcon={idNonFiscalModalClose.current}
          close="Cerrar"
          title="Recepcion de factura no fiscal"
          cbReject={resetProgress}
          footer={
            <>
              <PdfPreview pdf={state.xmlInfo?.pdf?.file} />
              {ButtonContinueForeignInvoice[state.step]}
            </>
          }
        >
          <Steps current={state.step | 0} className="my-4" onChange={setStep}>
            <Steps.Step title="Llenar datos" />
            <Steps.Step title="Acreditar ODC" disabled={state.step <= 1} />
            <Steps.Step title="CxP" disabled={state.step <= 2} />
          </Steps>

          {ForeignInvoice[state.step]}
        </ModalV2>

        <ExcelInvoiceReception />
      </Provider>
    </FilterContainer>
  );
}
