import ExecutiveFilterSearchContext from "context/ExecutiveFilterSearch/ExecutiveFilterSearchContext";
import useDialog from "customHooks/useDialog";
import useMemoryFilter from "customHooks/useMemoryFilter";
import { saveAs } from "file-saver";
import {
  downloadDirectoryExcel,
  fetchDirectoryV2,
} from "helpers/Apis/Directory";
import { parseDateToTextAndTime } from "helpers/dates";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * @type {import("./types").StateDirectory}
 */
const INITIAL_STATE = {
  executive: null,
  page: 1,
  pages: 0,
  type: {
    value: -1,
    label: "Todos",
  },
  status: {
    value: -1,
    label: "Todos",
  },
  search: "",
  directory: [],
  isSearching: true,
  selected: null,
  isLoading: true,
  triggerSearch: false,
  isDownloadingExcel: false,
};

/**
 * @type {import("./types").ContextDirectory}
 */
export const CONTEXT_DIRECTORYV2 = {
  ...INITIAL_STATE,
  breadcrum: [],
  filterMobile: undefined,
  downloadExcel: async () => {},
  setTypeCustomer: () => {},
  redirectToEdition: () => {},
  setStatus: () => {},
  setSearch: () => {},
  setDirectoryItem: () => {},
  redirectToDocuments: () => {},
  redirectToDo: () => {},
  setPage: () => {},
  redirectToContacts: () => {},
  attemptSearchRequested: () => {},
  redirectAdd: () => {},
};
/**
 *
 * @returns {import("./types").ReturnUseDirectory}
 */
export default function useDirectoryV2() {
  const INITIAL_STATUS = {
    type: {
      value: -1,
      label: "Todos",
    },
    status: {
      value: -1,
      label: "Todos",
    },
    search: "",
  };

  const { retrieveData, setMemoryData } = useMemoryFilter(INITIAL_STATUS);
  const [state, setState] = useState({ ...INITIAL_STATE, ...retrieveData("directory") });
  const history = useHistory();  

  useEffect(()=>{
    setMemoryData({
      search:state.search,
      status:{
        label:state.status.label,
        value:state.status.value
      },
      type:{
        label:state.type.label,
        value:state.type.value
      }
    },'directory')
  },[state.search,state.status,state.type])

  const redirectToEdition = () =>
    history.push(`/directorio/editar/${state.selected.customerID}`);

  const redirectToDocuments = () =>
    history.push(`/directorio/documentos/${state.selected.customerID}`);

  const redirectToContacts = () =>
    history.push(`/directorio/contacto/${state.selected.customerID}`);

  const redirectToDo = () =>
    history.push(`/directorio/to-do?cliente=${state.selected.Cliente_id}`);

  const redirectAdd = () => history.push(`/directorio/agregar`);

  const { executive } = useContext(ExecutiveFilterSearchContext);

  const filterMobile = useDialog();

  useEffect(() => {
    (async function () {
      if (executive === null) return;

      setState((current) => ({
        ...current,
        isLoading: true,
        directory: [],
        selected: null,
      }));

      const apiDirectoryResult = await fetchDirectoryV2(
        state.status.value,
        state.type.value,
        executive.id,
        state.page,
        state.search
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        directory: apiDirectoryResult.directory,
        selected: null,
        page: apiDirectoryResult.actualPage,
        pages: apiDirectoryResult.pages,
      }));
    })();
  }, [state.page, state.triggerSearch, executive]);

  const downloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const excelFile = await downloadDirectoryExcel();

    const filename = parseDateToTextAndTime(new Date(), "medium");

    if (excelFile instanceof Blob) {
      saveAs(excelFile, `Directorio_${filename}.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  };

  /**
   * Set the item selected on the directory
   * @param {import("helpers/Apis/typesDirectory").Directory|null} item - Information of the directory item selected on the UI
   * @returns {void}
   */
  const setDirectoryItem = (item) =>
    setState((current) => ({
      ...current,
      selected: item,
    }));

  /**
   * Set the status for the directory filter
   * @param {import("./types").StatusDirectoryOption} status - Status
   * @returns {void}
   */
  const setStatus = (status) =>
    setState((current) => ({
      ...current,
      status,
    }));

  const attemptSearchRequested = () => {
    setState((current) => ({
      ...current,
      triggerSearch: !current.triggerSearch,
      page: 1,
      pages: 0,
    }));
    filterMobile.close();
  };

  /**
   * Update the search for the filter
   * @param {string} search - Search requested
   * @returns {void}
   */
  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  /**
   * Update the type of customer to fetch
   * @param {import("./types").TypeDirectoryOption} type - Type of customer to filter
   * @returns {void}
   */
  const setTypeCustomer = (type) =>
    setState((current) => ({
      ...current,
      type: {
        label: type.label,
        value: type.value,
      },
    }));

  /**
   * Set the page for the records
   * @param {number} page - Page
   * @returns {void}
   */
  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  return {
    ...state,
    setPage,
    attemptSearchRequested,
    redirectToEdition,
    setStatus,
    setDirectoryItem,
    redirectToContacts,
    redirectToDo,
    redirectToDocuments,
    setSearch,
    setTypeCustomer,
    filterMobile,
    downloadExcel,
    redirectAdd,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/directorio",
        text: "Directorio",
      },
    ],
  };
}
