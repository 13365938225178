import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import useQueryParams from "customHooks/useQueryParams";
import { GetCustomer } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import MoreInfoDirectory from "structure/MoreInfoDirectory";
import ToDo from "structure/ToDo";
import ui from "structure/ToDo/CardsToDo/styles.module.scss";

export default function ToDoDirectory() {
  const { cliente } = useQueryParams();

  /**
   * Information of the customer
   * @type {[(import("types/typedef/customer").CustomerI|undefined),()=>void]}
   */
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    (async function () {
      const apiCustomer = await GetCustomer(+cliente);
      setCustomer(apiCustomer);
    })();
  }, []);

  return (
    <div className={ui.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/directorio",
            text: "Directorio",
          },
          {
            route: "/",
            text: "To Do",
          },
        ]}
      />

      {customer === undefined ? (
        <Spinner hidden={false} text="Cargando" />
      ) : (
        <div className={ui.margin}>
          <p className="d-flex flex-column">
            <b>Razón social</b>
            <MoreInfoDirectory idCustomer={customer.id}>
              <span className="hyperlink">
                {customer.socialReason} ({customer.type.description})
              </span>
            </MoreInfoDirectory>
          </p>
        </div>
      )}

      <ToDo
        id={+cliente}
        typeToDo="directorio"
        idEntity={+cliente}
        showGlobalImportants={false}
        fetchJustImportants={false}
      />
    </div>
  );
}
