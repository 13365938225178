import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { AddMovementContext } from "../../AddMovementsV2";

export const Save = () => {
  const { saveMovementOnDb, movements, isSaving } =
    useContext(AddMovementContext);

  if (isSaving) return <Spinner hidden={false} text="Guardando..." />;

  return movements.length <= 0 ? null : (
    <ButtonV2 type="button" onClick={saveMovementOnDb}>
      Guardar
    </ButtonV2>
  );
};
