import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./breadCrums.scss";
import "components/general/homeNavBar/homeNavBar.scss";
import { useBredCrum } from "./Hooks/useBredCrum";
import ui from "./styles.module.scss";

/**
 *
 * @typedef Breadcrum
 * @type {Object}
 * @property {string} route - Path to redirect when click item
 * @property {string} text - Text to show on item
 */

/**
 * Breadcrum for navigation
 * @param {object} props - Props
 * @param {import("./types").BreadcrumI[]} props.path - Paths to use on breadcrum
 * @returns {JSX.Element} Breadcrum
 * @example 
 * 
 * <BreadCrumV2
 *  path={
 *    [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/directorio",
          text: "Directorio",
        },
        {
          route: `/directorio/documentos/${idCustomer}`,
          text: "Documentos",
        },
        {
          route: "/",
          text: existIdQuote ? "Editar cotizacion" : "Agregar cotizacion",
        },
      ]
 *  }
 * />
 */
export default function BreadCrumV2({ path = [] }) {
  const { represetedExecutive } = useBredCrum();

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div className={ui.containerBreadcrum}>
      <div></div>
      <div>
        <ul>
          {path.map(({ route, text }, i) => {
            if (i + 1 >= path.length) {
              return <li key={i}>{text}</li>;
            }

            return (
              <li key={`${key.current}-${i}`}>
                <Link to={`${route}`}>{text}</Link>
              </li>
            );
          })}
          <li key={`${key.current}-last-item-navigation`}>
            <Link to={""}>{represetedExecutive}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
