import styled from "styled-components";

export const ContainerCheckbox = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 10px;
  font-weight: normal;

  padding: 10px 0;
  transition: background 0.25 ease-in;
  &:hover {
    background-color: var(--primaryBackground);
    font-weight: bold;
  }
`;
