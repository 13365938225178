import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextAddIncoming } from "..";
import usePermissions from "customHooks/usePermissions";
import Icon from "components/individual/HeaderIcon";

export default function AddIncomingSubmit() {
  const hook = useContext(ContextAddIncoming);

  if(!hook.isValidAssociation) return <></>

  if (hook.state.isCreating)
    return <Spinner idSpinner="spinner" hidden={false} text="Agregando" />;

  return (
    <ButtonV2
      disabled={!hook.isValidAssociation}
      onClick={hook.attemptCreateAssociation}
    >
      <span>Agregar</span>
      <Icon icon="plus"/>
    </ButtonV2>
  );
}
