import Add from "structure/ToDo/Add";
import React, { useContext } from "react";
import { ContextToDo } from "..";
import { NavToDoItem, AddPersonal, TypeTodo } from "./styles";
import styles from "../styles.module.scss";
import Select from "components/individual/SelectV4";
import { Label } from "structure/Document";

export default function ToDoNavigation() {
  const { navSelected, changeNavigation, refetchPersonalAdded } =
    useContext(ContextToDo);

  const idOpenAddPersonal = window.crypto.randomUUID();

  return (
    <>
      <AddPersonal>
        <Add
          parent={null}
          type="personales"
          id={-1}
          idEntity={null}
          idOpenModal={idOpenAddPersonal}
          onAdd={refetchPersonalAdded}
        />
        {/* {navSelected === "personales" ? (
          <Add
            parent={null}
            type="personales"
            title={null}
            id={-1}
            idEntity={null}
            idOpenModal={idOpenAddPersonal}
            onAdd={refetchPersonalAdded}
          />
        ) : null} */}
      </AddPersonal>

      <TypeTodo>
        <Label>To Do</Label>
        <Select
          labelToDisplay="label"
          onChange={(data) => changeNavigation(data.value)}
          defaultSelected={{
            keyToCompare: "value",
            value: navSelected,
          }}
          options={async () => [
            {
              label: "Documentos",
              value: "documentos",
            },
            {
              label: "Directorio",
              value: "directorio",
            },
            {
              label: "Contactos",
              value: "contactos",
            },
            {
              label: "Personal",
              value: "personales",
            },
            {
              label: "Autorizaciones pendientes",
              value: "autorizaciones",
            },
            {
              label: "Urgente",
              value: "urgenteGloabl",
            },
          ]}
        />
      </TypeTodo>

      <div className={styles.navigationToDos}>
        <NavToDoItem
          onClick={(e) => changeNavigation("documentos")}
          isSelected={navSelected === "documentos" ? true : false}
        >
          Documentos
        </NavToDoItem>
        <NavToDoItem
          onClick={(e) => changeNavigation("directorio")}
          isSelected={navSelected === "directorio" ? true : false}
        >
          Directorio
        </NavToDoItem>
        <NavToDoItem
          onClick={(e) => changeNavigation("contactos")}
          isSelected={navSelected === "contactos" ? true : false}
        >
          Contactos
        </NavToDoItem>
        <NavToDoItem
          onClick={(e) => changeNavigation("personales")}
          isSelected={navSelected === "personales" ? true : false}
        >
          Personal
        </NavToDoItem>

        <NavToDoItem
          onClick={(e) => changeNavigation("autorizaciones")}
          isSelected={navSelected === "autorizaciones" ? true : false}
        >
          Autorizaciones pendientes
        </NavToDoItem>

        <NavToDoItem
          onClick={(e) => changeNavigation("urgenteGloabl")}
          isSelected={navSelected === "urgenteGloabl" ? true : false}
        >
          Urgentes
        </NavToDoItem>
      </div>
    </>
  );
}
