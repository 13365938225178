import ModalV2 from "components/individual/Modals/ModalV2";
import React, { createContext, useState } from "react";
import CustomerInfo from "structure/Customer";
import DocsRelated from "structure/DocsRelated";
import MoreInfoContact from "./Contact";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";

/**
 * Context for the more information modal
 * @type {import("react-markdown/lib/ast-to-react").Context<import("../types").MoreInfoContext>}
 */
export const MoreInfoContext = createContext();
const { Provider } = MoreInfoContext;

const CONTENT = {
  contactos: (id, onTitle = () => {}) => (
    <MoreInfoContact
      id={id}
      onChange={(contact, customer) => onTitle(`${customer.type.description}`)}
    />
  ),
  documentos: (id, onTitle = () => {}) => (
    <DocsRelated
      idDocument={id}
      onChange={() => onTitle("Documentos relacionados")}
    />
  ),
  directorio: (id, onTitle = () => {}) => (
    <CustomerInfo
      idCustomer={id}
      onChange={(customer) => onTitle(`${customer.type.description}`)}
    />
  ),
  personales: () => <p>Informacion no disponible</p>,
  autorizaciones: (id, onTitle = () => {}) => (
    <DocsRelated
      idDocument={id}
      onChange={() => onTitle("Documentos relacionados")}
    />
  ),
};

const CONTENT_DB = {
  1: (id, onTitle = () => {}) => (
    <DocsRelated
      idDocument={id}
      onChange={() => onTitle("Documentos relacionados")}
    />
  ),
  2: (id, onTitle = () => {}) => (
    <CustomerInfo
      idCustomer={id}
      onChange={(customer) => onTitle(`${customer.type.description}`)}
    />
  ),
  3: (id, onTitle = () => {}) => (
    <MoreInfoContact
      id={id}
      onChange={(contact, customer) => onTitle(`${customer.type.description}`)}
    />
  ),
  4: () => <p>Informacion no disponible</p>,
  5: (id, onTitle = () => {}) => (
    <DocsRelated
      idDocument={id}
      onChange={() => onTitle("Documentos relacionados")}
    />
  ),
};

/**
 * Render more information of the do created
 * @param {object} props - Props
 * @param {import("structure/ToDo/types").TypeToDo|import("structure/ToDo/types").TypeToDoDb} props.type - Type of to do in order to see more information about that entity
 * @param {number} props.idEntity - Id of the document/contact/customer
 * @param {string} props.idOpenModal - Id in order to open the modal
 * @returns {void} {JSX.Element} Information of the entity selected
 */
export default function MoreInfo({ type, idEntity, idOpenModal }) {
  const [title, setTitle] = useState("Cargando...");

  return (
    <Provider
      value={{
        title,
        setTitle,
      }}
    >
      <ModalV2
        showCancelButton={false}
        idOpenModal={idOpenModal}
        title={title}
        width={
          type === "documentos" ||
          type === 1 ||
          type === "contactos" ||
          type === 3
            ? 90
            : 50
        }
      >
        {typeof type === "number"
          ? CONTENT_DB[type](idEntity, setTitle)
          : CONTENT[type](idEntity, setTitle)}
      </ModalV2>
    </Provider>
  );
}
