import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useConcilation, {
  CONTEXT_CONCILATION,
} from "customHooks/useConcilation";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import { createContext } from "react";

import { TabulatedMovements } from "./TabulatedMovements";
import {
  BankHeader,
  BankName,
  BankAccount,
  BankCurrency,
} from "components/general/BankHeader";
import scss from "./styles.module.scss";
import { FilterV2 } from "./FilterV2";
import { ClosePeriod } from "./ClosePeriod";
import { UpdateConcilations } from "./UpdateConcilations";
import usePermissions from "customHooks/usePermissions";
import { Forbidden } from "pages/403";
import RangeDate from "./RangeDate";
import StatusFilter from "./StatusFilter";
import SearchBarConcilation from "./SearchBarConcilation";
import Type from "./Type";
import ExcelConciliation from "../Excel";
import StatusAccounted from "./StatusAccounted";
import FilterMobile from "./FilterMobile";

/**
 * Context of the concilation
 * @type {import("react").Context<import("customHooks/useConcilation/types").ContextConcilation>}
 */
export const ConcilationContext = createContext(CONTEXT_CONCILATION);
const { Provider } = ConcilationContext;

export const Concilation = () => {
  const { cuenta } = useQueryParams();
  const concilation = useConcilation(+cuenta);

  usePermissions("1ccbafd1-86fe-4a1c-9cb0-bad57fd18e9a");

  return (
    <>
      <Provider value={{ ...concilation, bank: undefined }}>
        <div className={scss.containerConcilations}>
          <div className={scss.topConcilations}>
            <BreadCrumV2 path={concilation.breadcrum} />

            <div className={scss.containerFilter}>
              <BankHeader>
                {() => (
                  <>
                    <BankName />
                    <BankAccount />
                    <BankCurrency />
                    <FilterV2 />

                    <div className={scss.filterConcilation}>
                      <RangeDate />
                      <StatusFilter />
                      <Type />
                      <SearchBarConcilation />
                    </div>

                    <div className={scss.mobileOptions}>
                      <FilterMobile />
                      <ExcelConciliation />

                      <UpdateConcilations />
                    </div>

                    <div className={scss.optionsRow}>
                      <ExcelConciliation />

                      <UpdateConcilations />
                    </div>
                  </>
                )}
              </BankHeader>
            </div>
          </div>
          <TabulatedMovements />
        </div>
      </Provider>
    </>
  );
};
