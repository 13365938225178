import usePermissions from "customHooks/usePermissions";
import React from "react";
import RedirectButton from "../Redirect";
import TextIcon from "../TextIcon";
import scss from "../index.module.scss";
import Back from "../Back";
import { ChangeMenuView } from "../ChangeMenuView";

export default function Administration() {

  const { checkPermission } = usePermissions();

  return (
    <>
      <Back menu="general" text="Inicio > Administración" />

      <RedirectButton
        permission="299d8d69-5525-4b2a-b824-47947008853e"
        text={<TextIcon icon="document" text="Facturas emitidas" />}
        to={"/administracion/facturas-emitidas"}
      />

      {checkPermission("4cd31938-b58a-4a85-b50b-845e214af1de") ? (
        <p className={scss.item}>
          <TextIcon icon="noteCreditEmitted" text="Notas de crédito emitidas" />
        </p>
      ) : null}
      <RedirectButton
        permission="e36aa3b0-e77f-471c-81be-0a054e72e693"
        text={<TextIcon icon="document" text="Pedidos" />}
        to={"/administracion/pedidos?from=pedidos"}
      />

      <ChangeMenuView
        icon="reports"
        text="CxC"
        menu="administracionReportes"
      />
    </>
  );
}
