import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import useDialog from "customHooks/useDialog";
import { rejectTicket } from "helpers/Apis/tickets";
import { YesNoAlert } from "helpers/alerts";
import React, { Fragment, useEffect, useState } from "react";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";

/**
 * Handle the rejection of a ticket
 * @param {import("./types").PropsRejectTicket} props - Props
 * @returns {JSX.Element}
 */
export default function ReturnTicket({ ticket, onUpdated = () => {} }) {
  const [state, setState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  const reject = async () => {
    setState(true);

    const wasRejected = await rejectTicket(ticket.id);

    onUpdated();

    setState(false);
  };

  useEffect(() => {
    if (isOpen) dialog.open()
  }, [isOpen]);

  const promptRejection = () =>
    YesNoAlert(
      "¿Deseas retornar la resolución al departamento de soporte?",
      reject
    );

  if (ticket.status !== "inProgress") return <></>;

  if (state === true) return <Spinner text={"Rechazando"} hidden={false}/>;

  return (
    <Fragment>
      <ButtonV2 onClick={() => setIsOpen(true)}>
        <span>Rechazar resolución</span>
        <Icon icon="cancel" />
      </ButtonV2>

      {isOpen ? (
        <Dialog
          forwardRef={dialog.refDialog}
          title="Rechazar ticket"
          width={30}
          closeCallback={() => setIsOpen(false)}
          footer={
            <div className={ui.options}>
              <ButtonV2 onClick={reject}>
                <span>Si</span>
              </ButtonV2>

              <ButtonV2 theme="danger" onClick={() => setIsOpen(false)}>
                <span>No</span>
              </ButtonV2>
            </div>
          }
        >
          <Fragment>
            <h2 className="text-center my-5">
              ¿Deseas retornar la resolución al departamento de soporte?
            </h2>
          </Fragment>
        </Dialog>
      ) : null}
    </Fragment>
  );
}
