import { getAuthsInvoice } from "helpers/Apis/Documents";
import { dateToDbFormat, rangeFullYear } from "helpers/dates";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").State}
 */
const INITIAL_STATE = {
  from: rangeFullYear().firstDay.jsDate,
  to: new Date(),
  noInvoice: "",
  noLegalDocument: "",
  isLoading: true,
  page: 1,
  pages: 0,
  auths: [],
  triggerSearch:false
};

/**
 * Hook to handle auths invoices
 * @returns {import("./types").ReturnUseAuthsInvoice}
 */
export default function useAuthInvoice() {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * @type {import("react").MutableRefObject<HTMLFormElement>}
   */
  const formDom = useRef(null);

  useEffect(()=>{
    formDom.current.id = `${window.crypto.randomUUID()}`;
  },[])

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const data = await getAuthsInvoice({
        ordernarPor: "id",
        pagina: state.page,
        registros: 100,
        desde:dateToDbFormat(state.from),
        hasta:dateToDbFormat(state.to),
        pedido:state.noInvoice,
        factura:state.noLegalDocument
      });

      setState((current) => ({
        ...current,
        auths:data.auths,
        isLoading:false,
        pages:data.pages
        
      }));
    })();
  }, [state.page,state.triggerSearch]);

  const setFolio = (folio,type) => setState(current=>({
    ...current,
    [type]:folio
  }))

  /**
   * Set the page for the logs auth
   * @param {number} page - Current page
   * @returns {void}
   */
  const setPage = page => setState(current=>({
    ...current,
    page
  }));

  const triggerSearch = e=> {
    setState(current=>({
      ...current,
      triggerSearch:!state.triggerSearch,
      page:1
    }));
  }

  /**
   * Set range for the filter
   * @param {Date} date - Date
   * @param {"from"|"to"} type - Type of range
   * @returns {void}
   */
  const setRange = (date,type) => setState(current=>({
    ...current,
    [type]:date
  }));

  return {
    ...state,
    setRange,
    setFolio,
    setPage,
    triggerSearch,
    formDom,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/inicio",
        text: "Procesos especiales",
      },
      {
        route: "/",
        text: "Bitacora de autorizaciones",
      },
    ],
  };
}
