import '../../../types/typedef/documents';

/**
 * Set the dynamic breadcrum when the page of ADD DOCUMENT renders
 * @param {NavBreadcrum} bread - Breadcrum
 * @param {string} customerId - ID of the customer selected to add this document
 * @param {string} typeDocument - Name of the document that its being add or edit
 * @param {string} action - Editar or Agregar deppending on the type documento
 */
export function setDybamicBreadCrum(bread,customerId,typeDocument,action){
    bread.breadcrum[2] = {
        ...bread.breadcrum[2],
        url:`/directorio/documentos/${customerId}`
    }

    bread.breadcrum[3] = {
        ...bread.breadcrum[3],
        contentText:`${action==='agregar' ? 'Agregar' : 'Editar'} ${typeDocument}`
    }
}

/**
 * Add the propertys needed to display on the react-select library
 * 
 * @param {Catalogue[]} catalog - List of products/services of the catalogue
 */
export function parseCatalog(catalog){
    catalog.map((product,i)=>{
        product = {
            ...product,
            label:product.description,
            value:product.id
        }
        catalog[i] = product;
    });
}

/**
 * Remove duplicated information on JSON
 * @param {object[]} list - List of information
 * @returns {object[]} List without duplicated information
 */
export function removeDuplicated(list){
    const json = list.map(JSON.stringify);

    const setList = new Set(json);

    const parsedList = Array.from(setList).map(JSON.parse);

    return [...parsedList];
}

/**
 * Indexed name with the type of document that corresponds
 */
export const indexedTypeDoc = {
    "cotizacion":1
}