import InformativeTooltip from "components/individual/InfoTooltip";
import { InputCurrency } from "components/individual/inputs/Inputs";
import React from "react";
import { useContext } from "react";
import { ContextAddIncoming } from "../..";
import Money from "components/general/Forms/Money";

/**
 * Render the input for the concepts
 * @param {import('../../../../../../../../server/models/banks/movements/types').ConceptI} concept - Concept
 * @returns {JSX.Element}
 */
export default function ConceptInput(concept) {
  const context = useContext(ContextAddIncoming);

  /**
   * If true, must display the error on the input of the current item
   * @type {boolean}
   */
  const displayErrorConceptInput = context.isValidConceptCustomer
    ? false
    : (context.state.concepts.others[concept.id] || 0) > 0
    ? true
    : false;

  // Concepto de activo o venta, es calculado, no se puede editar
  if (concept.id === 8 || concept.id === 7)
    return <p className="text-center">-</p>;

  // No puede asociar conceptos, falta llenar datos para la operacion
  if (!context.canAssociateConcepts) return <p className="text-center">-</p>;

  if (concept.id === 10)
    return (
      <p>
        <Money
          value={context.state.concepts.others[concept.id]}
          onChange={(ammount) =>
            context.updateConceptAmmount(concept.id, ammount)
          }
        />

        {!context.isValidNoIdentified ? (
          <p className="text-danger font-weight-bold">
            <InformativeTooltip>
              <ol>
                <li>
                  No puedes asociar a concepto "No identificado" si ya tienes un
                  cliente asociado al ingreso
                </li>
                <li>
                  Debes asociar el 100% del importe a "No identificado" y no
                  asociar un cliente al ingreso
                </li>
              </ol>
            </InformativeTooltip>
            👈 Inválido
          </p>
        ) : null}
      </p>
    );

  if (concept.id === 12)
    return (
      <p>
        <Money
          value={context.state.concepts.others[concept.id]}
          onChange={(ammount) =>
            context.updateConceptAmmount(concept.id, ammount)
          }
        />
        {!context.isValidAdance ? (
          <p className="text-danger font-weight-bold">
            <InformativeTooltip>
              <ol>
                <li>
                  Para asociar a concepto de anticipo debes seleccionar un
                  cliente
                </li>
              </ol>
            </InformativeTooltip>
            👈 Inválido
          </p>
        ) : null}
      </p>
    );

  return (
    <p>
      <Money
        placeholder="0.00"
        maxDecimals={2}
        value={context.state.concepts.others[concept.id]}
        onChange={(ammount) =>
          context.updateConceptAmmount(concept.id, ammount)
        }
      />

      {displayErrorConceptInput && concept.id !== 20 ? (
        <p className="text-danger font-weight-bold">
          <InformativeTooltip>
            <p>Si hay cliente, solo puedes asociar a</p>
            <ul>
              <li>Venta</li>
              <li>Activo</li>
              <li>Anticipo clientes</li>
              <li>Ajuste por redondeo</li>
            </ul>
          </InformativeTooltip>
          👈 Inválido
        </p>
      ) : null}
    </p>
  );
}
