import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import useMovements from "customHooks/useMovements";
import { YesNoAlert } from "helpers/alerts";
import React from "react";
import { useState } from "react";

const STATUS = {
  1: "ACTIVE",
  2: "ASSOCIATED",
  3: "CONCILIATED",
  4: "CANCELETED",
  5: "ONPROCESS",
};

/**
 * Button in order to cancel a movement
 * @param {import("./types").PropsCancelMovement} props - Props
 * @returns {JSX.Element}
 */
export const CancelMovement = ({ id, currentStatus, type }) => {
  const [isCancelling, setIsCancelling] = useState(false);

  const { cancel } = useMovements();

  const promptConfirmationIncoming = () =>
    YesNoAlert("¿Estas seguro de cancelar el ingreso?", async () => {
      setIsCancelling(true);
      await cancel(id);
      setIsCancelling(false);
    });

  const promptConfirmation = () =>
    YesNoAlert("¿Estas seguro de cancelar el egreso?", async () => {
      setIsCancelling(true);
      await cancel(id);
      setIsCancelling(false);
    });

  const modalToPrompt =
    type === 1 ? promptConfirmationIncoming : promptConfirmation;
  if (
    STATUS[currentStatus] === "CANCELETED" ||
    STATUS[currentStatus] === "CONCILIATED" ||
    STATUS[currentStatus] === "ONPROCESS"
  )
    return;

  if (isCancelling)
    return (
      <Spinner
        hidden={false}
        text={`Cancelando ${type === 1 ? "ingreso" : "egreso"}`}
      />
    );

  return <ButtonV2 onClick={modalToPrompt}>Cancelar</ButtonV2>;
};
