import React from "react";

import { Tabs } from "components/general/Tabs/Tabs";
import { CommentSection } from "../Comments/CommentSection";
import { BodySection, LeftSection, RigthSection } from "../Containers";
import { ItemSection } from "../Items/ItemSection";
import { DateComponent } from "./DateComponent";
import { MoneyComponent } from "./MoneyComponent";

export const TabComponent = () => {
  return (
    <BodySection>
      <LeftSection>
        <Tabs
          idContainerContent={"documentInfo"}
          idContainerTabs={"tabs"}
          tabButtons={{
            items: {
              text: "Partidas",
              selected: true,
              component: <ItemSection />,
            },
            comments: {
              text: "Comentarios",
              selected: false,
              component: <CommentSection />,
              // component:<CommentsDocumentPraxia/>
            },
          }}
        />
      </LeftSection>
      <RigthSection>
        <DateComponent />
        <MoneyComponent />
      </RigthSection>
    </BodySection>
  );
};
