import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { Success, Error, closeForm, DisplayError } from "../alerts";

import "types/typedef/movements";

import "../../types/typedef/banks";

import { mnCurrency } from "helpers/money";
import { dateToDbFormat } from "helpers/dates";
import { isValidHttpResCode } from "./fetch";
import { blobToFile } from "./associateFilesV2";
import { saveAs } from "file-saver";
import { base64ToFile, base64toBlob } from "helpers/files";

/**
 * Get the overview of the CxC's
 * @param {number} idCustomer - Id of the customer
 * @param {number} idBankAccount - Id of the bank account
 * @returns {object[]}
 */
export async function getCxcOverview(idCustomer, idBankAccount) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientos/informacion/${idCustomer}/${idBankAccount}/cxc`
    );

    return data;
  } catch (error) {}
}

/**
 * Get the month and year of the last close month for that bank account
 * @param {number} idBankAccount - Id bank account
 * @returns {Promise<import("../../../../types/banks").BankAccountLastCloseMonth>}
 */
export async function getLastCloseMonth(idBankAccount) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/ultimo-cierre/${idBankAccount}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    DisplayError(error);
  }
}

export async function addConcilation(cxcs) {
  try {
    // const cxc = getCxcsToAdd(cxcs);

    await axios.post(
      `${URL_BASE}bancos/conciliacion`,
      {
        ...cxcs,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    console.log(error);

    const { message, errorCode } = error.response.data;
    Error(() => {}, `Codigo de error: [${errorCode}]. ${message}`);

    return false;
  }
}

/**
 * Associate the CxPs
 * @param {import("types/typedef/customHooks/useProvidersWhitCxP").DtoAssociateCxpI} - Information to add into db
 * @returns {boolean} True if concilation was made successfully
 */
export async function addConcilationCxp({
  cxp,
  executive,
  idProvider,
  idMovement,
  residue,
}) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}bancos/conciliacion/cxp`,
      {
        cxp,
        executive,
        idProvider,
        idMovement,
        residue,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the information overview of the cxp associated to that movement
 * @param {number} idMovement - Id of the movement
 */
export async function getCxpOverview(idMovement) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimiento/cxp/informacion/${idMovement}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Get the id of the 3 code currency text
 *
 * @param {('MXN'|'USD')} code - Currency code of 3 digits
 * @returns {number} Id of the currency code
 */
export async function getIdCurrencyCode(code) {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/moneda/codigo/${code}`);
    return data.id;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * Save movements on the db
 * @param {object[]} queue - Movements to add
 * @returns {boolean} True if the fast movements were added
 */
export async function saveFastAdd(queue) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}bancos/movimiento/ingreso-rapido`,
      queue
    );

    if (data.status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    // Error(() => {}, error);
    return false;
  }
}

/**
 * Add a new bank account
 * @param {import("../../../../types/banks").DtoAddBank} dto - Dto add bank
 * @returns {boolean}
 */
export async function addBankAccountV2(dto) {
  try {
    await axios.post(`${URL_BASE}bancos/cuenta`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Update a bank account on the system
 * @param {import("../../../../types/banks").DtoUpdateBankAccount} dto - Information in order to update the bank account
 * @returns {boolean} True if was updated correctly
 */
export async function updateBankAccount(dto) {
  try {
    await axios.put(`${URL_BASE}bancos/cuenta`, dto, { withCredentials: true });

    Success(() => {}, "Cuenta actualizada");

    return true;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Get the information of a bank account
 * @param {number} id - Id of the bank account to fetch
 * @returns {Promise<import("../../../../server/models/banks/types").BankAccountI|undefined>}
 */
export async function getBankAccountV2(id) {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/cuenta/${id}`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    DisplayError(error);
    return undefined;
  }
}

/**
 * Get the type of bank accounts available on the system
 * @returns {import("../../../../types/banks").TypeBankAccount[]}
 */
export async function getTypeBankAccounts() {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/tipos-cuenta`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    DisplayError(error);
    return [];
  }
}

export async function addBanckAccount(bankAccount, formID) {
  const successCallback = () => closeForm("btnDismiss", formID);
  try {
    const { data } = await axios.post(
      `${URL_BASE}bancos/addbanckaccount`,
      bankAccount
    );
    if (data.status === 200) {
      Success(successCallback, data.message);
      return true;
    }
    Error(() => {}, "Ha ocurrido un error");
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}
export async function editBankAccount(bankAccount, formID) {
  const successCallback = () => closeForm("edit_btnDismiss", formID);
  console.log("ID del formulario: ", formID);
  try {
    const { data } = await axios.put(
      `${URL_BASE}bancos/updatebankaccount`,
      bankAccount
    );
    if (data.status === 200) {
      Success(successCallback, data.message);
      return true;
    }
    Error(() => {}, "Ha ocurrido un error");
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Only use is it to mantain legacy code
 * @returns {Promise<import("./typesBanks").CurrencyV1[]>}
 * @deprecated
 */
export async function getCurrency() {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/currecnytype`);
    if (data.status === 200) {
      return data.data[0];
    }
    Error(() => {}, "Ha sucedido un error");
    return;
  } catch (error) {
    Error(() => {}, error);
    return;
  }
}

/**
 * Fetch the 'x' page from the movements of an account
 *
 * @param {number} page - Page requested of the movements
 * @param {string} order - DESC or ASC
 * @param {string} column - Column which must be ordered the data
 * @param {string} aditionalQuery - Filters and extra params
 * @returns {object} Data requested
 */
export async function fetchMovements(page, order, column, aditionalQuery = "") {
  try {
    const beginDate = document.getElementById("filterStartRange").value;
    const endDate = document.getElementById("filterEndRange").value;

    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientos?pagina=${page}&orden=${order}&columna=${column}&fechaInicio=${beginDate}&fechaFin=${endDate}${aditionalQuery}`
    );

    console.log(data.data);

    if (data.status === 200) {
      return data.data;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Validate the number account exists
 *
 * @param {number} bankAccount - PK of the bank account to search
 * @returns {boolean} True if the account exists
 */
export async function validateAccount(bankAccount) {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/cuenta/:bankAccount`);

    if (!data.isFounded) {
      return false;
    }

    return true;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Get the information of an specific movement
 *
 * @param {number} id - ID of the movement requested
 * @returns {import("types/typedef/movement").MovementI} Information of the movement
 */
export async function getMovement(id) {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/movimiento/${id}`);

    if (data.status === 200) {
      return data.movement;
    }

    Error(() => {}, data.error);
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Fetch the pay methods available
 *
 * @returns {Array<object>} List of payment methods available
 */
export async function fetchPayMethods() {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/metodos-pago`);

    if (data.status === 200) {
      return data.payMethods;
    }

    Error(() => {}, data.error);
    return false;
  } catch (error) {
    console.log(error);

    Error(() => {}, error);
    return false;
  }
}

export async function GetPayForms() {
  try {
    let payForms = await fetch(`${URL_BASE}bancos/formas-pago`);

    payForms = await payForms.json();

    return payForms.payForms;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the information of a bank account
 *
 * @param {number} accountId - ID of the Bank account requested
 * @returns {import("../../types/typedef/banks").BankAccountI} Information of the bank account
 */
export async function getBankAccount(accountId) {
  try {
    console.log(accountId);
    const { data } = await axios.get(
      `${URL_BASE}bancos/cuenta/${accountId}/informacion`
    );

    if (data.status === 200) {
      return data.bankAccount;
    }

    Error(() => {}, data.error);
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Get the list of allowed customers to associate when editing an egress or incoming
 *
 * @param {number} page
 * @param {string} order
 * @param {string} column
 * @param {string} aditionalQuery
 * @returns {object}
 */
export async function getAllowedCustomersAssociate(
  page,
  order,
  column,
  aditionalQuery = ""
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}directorio/lista?pagina=${page}&orden=${order}&columna=${column}${aditionalQuery}`
    );

    if ((data.status = 200)) {
      return data.data;
    }

    Error(() => {}, data.error);
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

export async function updateMovement(formData) {
  try {
    const { data } = await axios.put(`${URL_BASE}bancos/cuenta`, formData, {
      withCredentials: true,
    });

    if (data.status === 200) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Update the status of a movement to conciliated or associated
 * @param {number[]} movements - Ids of the movements to conciliate/desconciliate
 * @param {boolean?} conciliate - If true, will conciliate, otherwise will update to associated
 * @returns {Promise<void>}
 */

export async function updateConcilationStatus(
  movements = [],
  conciliate = true
) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}bancos/movimiento/consiliar`,
      {
        idMovement: movements,
        itIsToConciliate: conciliate,
      },
      { withCredentials: true }
    );

    Success(() => {}, data.message);
  } catch (error) {
    DisplayError(error);
  }
}

/**
 * Update the information of a movement
 * @param {import("../../../../types/banks").DtoUpdateMovement} dto
 * @returns {boolean}
 */
export async function updateMovementV2(dto) {
  try {
    const { data } = await axios.put(`${URL_BASE}bancos/movimiento`, dto, {
      withCredentials: true,
    });

    Success(() => {}, data.message);

    return true;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Get the movements information filtered by some params
 * @param {ReqMovements} filterOptions - Information filter to get the movements
 * @returns {object} Information fetched from the server
 */
export async function getMovementsV2({
  account,
  page,
  dateBegin,
  dateEnd,
  status,
}) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientosV2?cuentaBanco=${account}&pagina=${page}&fechaInicio=${dateBegin}&fechaFin=${dateEnd}&estatus=${status}`,
      {
        withCredentials: true,
      }
    );

    if (data.data.movements.length === 0) {
      return {
        actualPage: 0,
        pages: 0,
        movements: [],
      };
    }

    const parsedData = data.data.movements.map((movement) => ({
      ...movement,
      Fecha: new Intl.DateTimeFormat("es-MX", {
        dateStyle: "medium",
      }).format(new Date(movement.Fecha)),
    }));

    return {
      ...data.data,
      movements: parsedData,
    };
  } catch (error) {
    console.log(error);

    return {
      actualPage: 0,
      pages: 0,
      movements: [],
    };
  }
}

/**
 * Fetch the list of customer allowed to associate according to the type of movement
 *
 * @param {number} page - Page requested of the movements
 * @param {string} order - DESC or ASC
 * @param {string} column - Column which must be ordered the data
 * @param {string} aditionalQuery - Filters and extra params
 * @returns {object} Data requested
 */
export async function getListAssociate(
  page,
  order,
  column,
  aditionalQuery = ""
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/asociar?pagina=${page}&orden=${order}&columna=${column}${aditionalQuery}`
    );

    return data.data;
  } catch (error) {
    const { message } = error.response.data;

    console.log(error.response);

    Error(() => {}, message);

    return {
      actualPage: 0,
      pages: 0,
      listAssociate: [],
    };
  }
}

/**
 * Fetch the information of an specific id currency
 * @param {number} id - PK of the currency to fetch
 * @returns {CurrencyInfo} Currency info
 */
export async function GetCurrencyInfo(id) {
  try {
    let apiInfo = await fetch(`${URL_BASE}bancos/moneda/${id}`);

    apiInfo = await apiInfo.json();

    return apiInfo.currencyInfo;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the list of cxc pending
 * @param {string|number} customerId - Id customer
 * @param {number} idBankCurrency - Id of the currency will be used on the movements
 * @param {"USD"|"MXN"} predominantCurrency - Currency predominant for the grid
 * @returns {object[]}
 */
export async function GetConsolation(
  customerId,
  idBankCurrency,
  predominantCurrency
) {
  try {
    const {
      data: { cxc },
    } = await axios.get(
      `${URL_BASE}bancos/consiliacion/${customerId}?bankCurrency=${idBankCurrency}`
    );

    return cxc.map((cxcElement) => {
      const tc = predominantCurrency === cxcElement.currency ? 1 : 0;
      return {
        ...cxcElement,
        tc: {
          number: tc,
          text: mnCurrency(tc),
        },
        applied: {
          number: 0,
          text: "$0.0",
        },
        newResidue: {
          number: 0,
          text: "$0.00",
        },
      };
    });
  } catch (error) {
    console.log(error);

    Error(() => {}, error.response.data.message);
  }
}

export async function getCxcConciliation(customerId, idBank) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/testconciliaciones/${customerId}/${idBank}`,
      {
        withCredentials: true,
      }
    );
    return data.conciliations;
  } catch (error) {
    console.log(error);
    Error(() => {}, error.response.data.message);
  }
}

/**
 * Cancel the movement
 * @param {number} idMovement - Id of the movement to cancel
 * @returns {boolean}
 */
export async function cancelMovement(idMovement) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}bancos/movimiento/cancelar`,
      {
        idMovement,
      },
      {
        withCredentials: true,
      }
    );

    Success(() => {}, "Desasociacion realizada");
    return true;
  } catch (error) {
    const { message, errorCode } = error.response.data;

    Error(() => {}, message);
    return false;
  }
}

/**
 * Dis-associate the concept made to the ingress (but don't cancel)
 * @param {number} idMovement - Id movement
 * @returns {boolean} True if was dis-associated
 */
export async function disassociateConceptIngress(idMovement) {
  try {
    const {
      data: { message },
    } = await axios.put(
      `${URL_BASE}bancos/desasociar/concepto`,
      { idMovement },
      {
        withCredentials: true,
      }
    );

    Success(() => {}, message);

    return true;
  } catch (error) {
    /**
     * @type {import("axios").AxiosError}
     */
    const {
      response: { data },
    } = error;
    Error(() => {}, data.message);
    return false;
  }
}

/**
 * Dis-associate the CxP of a movement (Invoice)
 * @param {number[]} idsCxp - Ids of the cxp to dis-associate
 * @param {number} idMovement - Id of the movement to dis-associate his cxps
 * @returns {boolean} True if dis-association was success
 */
export async function disassociateCxp(idsCxp = [], idMovement = null) {
  if (idsCxp.length <= 0) {
    Error(() => {}, "Selecciona al menos una asociacion para cancelar");
    return false;
  }

  if (idMovement === null || idMovement === undefined) {
    Error(() => {},
    "Selecciona el egreso que se necesita cancelar/des-asociar");
    return false;
  }

  try {
    // const { data } = await axios.delete(
    //   `${URL_BASE}bancos/movimientos/cxp?associations=${idsCxp}&idMovement=${idMovement}`,
    //   {
    //     withCredentials: true,
    //   }
    // );

    const response = await fetch(
      `${URL_BASE}bancos/movimientos/cxp?associations=${idsCxp}&idMovement=${idMovement}`,
      {
        credentials: "include",
        method: "DELETE",
        headers: {
          "Contenty-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return true;
    }

    return false;
  } catch (error) {
    DisplayError(error);
    return false;
  }
}

/**
 * Get the overview of the egress selected
 * @param {number} idMovement - Id of the movement to get the overview egress
 * @returns {import("../../../../types/banks").Egress} Information of the egress
 */
export async function getEgressOverview(idMovement) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientos/informacion/${idMovement}`,
      { withCredentials: true }
    );

    return data;
  } catch (error) {
    Error(() => {}, "Por programar el error");
    return [];
  }
}

/**
 * Get the information of the CxP's associated to a ODC of a determinated movement
 * @param {number} idMovement - Id movement
 * @returns {import("../../../../types/movements").DtoCxpMovement[]}
 */
export async function getCxpsMovement(idMovement) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientos/${idMovement}/cxp`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Disassociate (cancel) multiple items from a movement of type egress
 * @param {import("types/typedef/customHooks/useEgressDisassociate").DtoMultipleEgressDisassociateI} dto - Information needed to cancel on the server
 * @returns {boolean} True if the elements were disassociated correctly
 */
export async function disassociateMultipleEgresses(dto) {
  try {
    await axios.put(`${URL_BASE}bancos/desasociar/egreso-multiple`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    Error(() => {}, "Por programar el error");

    return false;
  }
}

/**
 * Disassociate an egres
 * @param {number} idMovement - Id of the movement to disassociate
 * @returns {boolean} True if movement was disassociated
 */
export async function disassociateEgress(idMovement) {
  try {
    await axios.put(`${URL_BASE}bancos/desasociar/egreso/${idMovement}`, null, {
      withCredentials: true,
    });

    Success(() => {}, "Movimiento desasociado");

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Set the movement to cancel status
 * @param {number} idMovement - id of the movemetn
 * @returns {boolean} True if was cancelled
 */
export async function setCancelStatus(idMovement) {
  try {
    await axios.put(
      `${URL_BASE}bancos/movimientos/v2/cancelar/${idMovement}`,
      null,
      {
        withCredentials: true,
      }
    );
    Success(() => {}, "Movimiento cancelado");
    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return false;
  }
}

/**
 * Get the tc list or last tc list used on the system
 * @param {string[]?} dates - String of dates as yyyy-mm-dd
 * @returns {import("types/typedef/customHooks/useExchangeInformative").Exchange[]} TC list requested
 */
export async function GetTCV2(dates = []) {
  let query = "";

  if (dates.length >= 1) {
    query = "?fechas=";
    dates.forEach((date) => (query += `${date},`));
    query.substring(0, query.length - 1);
  }

  try {
    const { data } = await axios.get(`${URL_BASE}bancos/tipo-cambio${query}`);

    return data.tcs;
  } catch (error) {
    return [];
  }
}

/**
 *
 * @param {number} customerType - The customer type id
 * @param {number} documentType - The document type id
 * @param {number} pageRequested -The number of page requested
 * @param {string?} search - The input search filter.
 */
export async function getProvidersWithCXP(
  customerType,
  documentType,
  pageRequested,
  search
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimientos/providers/withcxp?customerType=${customerType}&documentType=${documentType}&search=${search}&pageRequested=${pageRequested}&`,
      {
        withCredentials: true,
      }
    );
    return {
      page: data.actualPage,
      totalPages: data.pages,
      data: data.data,
    };
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get the pending expenses to associate to an egress
 * @returns {import("../../types/typedef/banks").PendingExpensesI[]} Pending expenses to associate into system
 */
export async function getPendingExpenses() {
  try {
    const pendingExpenses = await axios.get(
      `${URL_BASE}bancos/gastos/pendientes`,
      {
        withCredentials: true,
      }
    );

    return pendingExpenses.data.map((item) => ({
      ...item,
      currency: item.invoice.currency,
    }));
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return [];
  }
}

/**
 * Associate the egresses
 * @param {import("types/typedef/customHooks/useEgressAssociation").AssociateDto} dto - Information to be sended to server
 * @returns {Promise<boolean>} True if the association was made
 */
export async function associateEgresses(dto) {
  try {
    await axios.post(`${URL_BASE}bancos/egresos/asociar/v2`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);

    return false;
  }
}

/**
 * Get the list of conceptos for the incomings
 * @returns {Promise<import("../../../../types/banks").IncomesI[]>}
 */
export async function getInformativeIncomes() {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimiento/incomes/conceptos`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Get the list of concepts for egresses
 * @returns {Promise<import("../../../../types/banks").ConceptI[]>}
 */
export async function getInformativeExpenses() {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/movimiento/expenses/conceptos`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
}

export async function addIncomesAsociation(dataAsociation) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}bancos/movimiento/incomes/conceptos/asociar`,
      dataAsociation,
      {
        withCredentials: true,
      }
    );
    console.log("Se agrego correctamente la asociación");
    console.log(data);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the recent tcps of the system
 * @returns {Promise<import("./typesBanks").TcInformative[]>}
 */
export async function getRecentTcps() {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos/tcp-recientes`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    /**
     * @type {import("axios").AxiosError}
     */
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return [];
  }
}

/**
 * Add a new tc into system
 * @param {import("../../../../server/controllers/typesBanks").DtoAddTc} dto - Information in order to add a new tc into system
 * @returns {Promise<boolean>}
 */
export async function addTc(dto) {
  try {
    const res = await fetch(`${URL_BASE}bancos/agregar/tc`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

// http://localhost:4000/api/bancos/v2/tc/busqueda?date=2023-06-01
/**
 * Search tc in the system
 * @param {string} from - From date
 * @param {string} to - To date
 * @returns {Promise<import("./typesBanks").TcInformative[]>}
 */
export async function searchTc(from,to) {
  try { 
    /**
     * @type {import("axios").AxiosResponse<import("./typesBanks").TcInformative[]>}
     */
    const res = await axios.get(
      `${URL_BASE}bancos/v2/tc/busqueda?from=${from}&to=${to}`,
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    /**
     * @type {import("axios").AxiosError}
     */
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return [];
  }
}

/**
 * Get the concilations made to an incoming for the CxC
 * @param {number} idMovement - Id of the movement
 * @returns {Promise<import("../../../../types/banks").ConcilationIncomingI[]>} Concilations made to an incoming
 */
export async function getCxcConcilationsMovement(idMovement) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}bancos/conciliaciones/cxc/${idMovement}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Dis-associate the concilations from the CxC
 * @param {number} idMovement - Id of the movement tried to be dis-associated
 * @param {number[]} idsConcilations - Ids of the associations
 * @returns {Promise<boolean>} True if was dis-assocaited
 */
export async function disassociateCxcIncoming(idMovement, idsConcilations) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}bancos/conciliaciones/cxc/des-asociar`,
      {
        concilations: idsConcilations,
        idMovement,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the bank loaded on the system
 * @returns {Promise<import("../../../../types/banks").BankI[]>}
 */
export async function getBanks() {
  try {
    const { data } = await axios.get(`${URL_BASE}bancos`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Load the CSD of the rfc
 * @param {string} rfc - RFC
 * @returns {Promise<import("../../../../types/banks").DtoCsd>}
 */
export async function getCsd(rfc) {
  try {
    const { data } = await axios.get(`${URL_BASE}facturacion/4.0/csd/${rfc}`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    return {
      Certificate: null,
      PrivateKey: null,
      Rfc: rfc,
      UploadDate: null,
    };
  }
}

/**
 * Get the information of the movements pending to close on the month
 * @param {import("./typesBanks").ParamsGetBankAccountPendingConcilations} params - Params in order to fetch the pending concilations of the bank accounts
 * @returns {Promise<import("../../../../types/conciliations").MovementsToConciliateI>}
 */
export async function getBankAccountPendingConcilations({
  page = 1,
  idBankAccount,
  date = dateToDbFormat(new Date()),
  orderAsc = true,
  from = null,
  to = null,
  type = null,
  rowsPerPage = 100,
  status = null,
  noMovement = "",
  getFullReport,
  posted = null
}) {
  /**
   * @type {import("../../../../types/conciliations").MovementsToConciliateI}
   */
  const dummyResponse = {
    movimientos: [],
    pages: {
      actualPage: 1,
      noRegisters: 0,
      pages: 0,
    },
  };

  try {
    const queryParamTo = to === null ? "" : `&to=${to}`;
    const queryParamType = type === null ? "" : `&type=${type}`;
    const queryParamStatus = status === null ? "" : `&status=${status}`;
    const queryParamNoMovement = noMovement === "" ? "" : `&search=${noMovement}`;
    const queryParamFullReport = `&getFullReport=${getFullReport}`;
    const quertyPosted = posted === null ? '' : `&posted=${+posted}`;
    const queryFrom = from === null ? '' : `&from=${from}`;

    /**
     * @type {import("axios").AxiosResponse<import("../../../../types/conciliations").MovementsToConciliateI>}
     */
    const res = await axios.get(
      `${URL_BASE}bancos/conciliacion/movimientos?idAccount=${idBankAccount}&date=${date}&pageRequested=${page}${queryParamTo}${queryParamNoMovement}${queryParamFullReport}${quertyPosted}${queryParamStatus}${queryParamType}&orderAsc=${orderAsc}&rowsPerPage=${rowsPerPage}${queryFrom}`,
      {
        withCredentials: true,
      }
    );

    return res.data;
  } catch (error) {
    DisplayError(error);
    return dummyResponse;
  }
}

// from
// to
// type

/**
 * Get the excel for the tc query 
 * @param {import("../../../../server/helpers/excel/types").ExcelTcType} type - Type of excel report to fetch
 * @param {string|null} [from=null] - **REQUIRED** if you pick the excel type 'rangeDate' 
 * @param {string|null} [to=null] - **REQUIRED** if you pick the excel type 'rangeDate' 
 * @returns {Promise<Blob|undefined>} Excel file downloaded
 */
export async function getTcExcel(type,from=null,to=null){
  try {

    const typeQuery = `?type=${type}`;
    const fromQuery = from === null ? '' : `&from=${from}`;
    const toQuery = to === null ? '' : `&to=${to}`;

    const response = await fetch(`${URL_BASE}bancos/v2/tc/excel${typeQuery}${fromQuery}${toQuery}`,{
      credentials:"include"
    });

    if(response.ok && isValidHttpResCode(response.status)){
      const excel = await response.blob();
      return excel
    }

    const data = await response.json();

    Error(()=>{},data.message);
    return undefined
  } catch (error) {
    return undefined
  }
}

/**
 * Get the complement information
 * @param {"json"|"pdf"|"xml"} type - Type of response to retrieve
 * @param {number} [idMovement=null] - Id of the movement that may contain the complement(s)
 * @param {string} [uuid=null] - UUID of the complement to retrive
 * @returns {Promise<File[]|any>}
 */
export async function getComplement(type,idMovement=null,uuid=null){

  const ContentType = {
    pdf:"application/octet-stream",
    xml:"application/octet-stream",
    json:"application/json"
  }

  try { 
    const queryIdMovement = typeof(idMovement) === "number" ? `&idMovement=${idMovement}` : '';
    const queryUuid = typeof(uuid) === "string" ? `&uuid=${idMovement}` : ''

    const response = await fetch(`${URL_BASE}bancos/complemento/informacion?typeOfResponse=${type}${queryIdMovement}${queryUuid}`,{
      credentials:"include",
      method:"GET",
      headers:{
        Accept: ContentType[type],
        "Content-Type": ContentType[type],
      }
    });

    if(!response.ok || !isValidHttpResCode(response.status)) return [];

    const data = await response.json();
    if(type==="json")return data;

    
    const blobs = data.map(blob=>base64toBlob(blob));

    const files = blobs.map(blob=>blobToFile(blob,`Complemento.${type}`))
      
return files;


  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get the complement information
 * @param {number} [idMovement=null] - Id of the movement that may contain the complement(s)
 * @param {string} [uuid=null] - UUID of the complement to retrive
 * @returns {Promise<File[]|any>}
 */
export async function emailComplementFiles(idMovement=null,uuid=null){
const [pdf,xml] = await Promise.all([
  getComplement("pdf",idMovement,uuid),
  getComplement("xml",idMovement,uuid)
]);

return [...pdf,...xml]
}