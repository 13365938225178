import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ContextProyects } from "pages/Proyects";
import React, { useContext, useEffect, useRef, useState } from "react";
import Dialog from "structure/Dialog";
import * as yup from "yup";
import useDialog from "customHooks/useDialog";
import { Label } from "structure/Document";
import { Controller, useForm } from "react-hook-form";
import { DateInput } from "components/general/Forms/Dates";
import { yupResolver } from "@hookform/resolvers/yup";
import Icon from "components/individual/HeaderIcon";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import { InputDate } from "components/individual/inputs/Inputs";
import { addPropousal } from "helpers/Apis/proyects";
import LoginContext from "context/Login/LoginContext";
import { dateToDbFormat } from "helpers/dates";

export default function GeneratePropusal() {
  const hook = useContext(ContextProyects);

  const { userInfo } = useContext(LoginContext);

  const dialog = useDialog();

  const [state, setState] = useState({
    canGenerate: false,
    errors: ["No puedes generar la propuesta"],
    state: "none",
  });

  const schema = useRef(
    yup.object().shape({
      attn: yup
        .string()
        .max(1000, "Máximo 1000 caracteres")
        .min(1, "Persona que atiende requerida")
        .typeError("Persona que atiende requerida")
        .required("Persona que atiende requerida"),
      expirationDate: yup.date().required("Fecha de expiración requerida"),
      paymentTerms: yup
        .string()
        .max(1000, "Máximo 1000 caracteres")
        .min(1, "Términos de pago requeridos")
        .typeError("Términos de pago requeridos")
        .required("Términos de pago requeridos"),
      termsAndConditions: yup
        .string()
        .max(1000, "Máximo 1000 caracteres")
        .min(1, "Términos y condiciones requeridos")
        .typeError("Términos y condiciones requeridos")
        .required("Términos y condiciones requeridos"),
    })
  );

  const formHook = useForm({
    resolver: yupResolver(schema.current),
    criteriaMode: "all",
    defaultValues:{
      expirationDate: new Date(),
    }
  });

  const handleAddPropousal = async (dto) => {
    setState((current) => ({
      ...current,
      state: "loading",
    }));

    const wasCreated = await addPropousal({
      attn: dto.attn,
      paymentTerms: dto.paymentTerms,
      termsAndConditions: dto.termsAndConditions,
      idExecutive: userInfo[0].userID,
      createdBy: userInfo[0].fullName,
      expirationDate: dateToDbFormat(dto.expirationDate),
      positions: hook.selectedPosition.map((item) => ({
        idPosition: item.id,
        positionDescription: item.description,
      })),
    });

    if (wasCreated) {
      hook.refetchTable();
      dialog.close();
      formHook.reset();
    }

    setState((current) => ({
      ...current,
      state: "none",
    }));
  };

  /////////////////////////////////////////////////////

  useEffect(() => {
    if (hook.selectedPosition === undefined) return;

    const recordsToEvaluate = hook.selectedPosition.map((item) => ({
      ocCustomer: item.ocCustomer,
      solped: hook.selectedProyect?.solped || null,
      noRFQ: null,
    }));

    const querysValidations = recordsToEvaluate.map((record) =>
      schema.current.validate(record)
    );

    Promise.allSettled(querysValidations).then((results) => {
      const errors = results
        .filter((result) => result.status === "rejected")
        .map((result) => `${result.reason.message}`);

      setState((current) => ({
        ...current,
        canGenerate: errors.length <= 0,
        errors: ["No puedes generar la propuesta", ...errors],
      }));
    });
  }, [hook.selectedPosition, hook.selectedProyect]);

  if (hook.selectedPosition.length <= 0) return <></>;

  return (
    <>
      <ButtonV2
        onClick={(e) => (!state.canGenerate ? dialog.open() : () => {})}
      >
        <span>Generar propuesta</span>
      </ButtonV2>

      {!state.canGenerate && (
        <Dialog
          width={35}
          forwardRef={dialog.refDialog}
          title={"Generar propuesta"}
          closeCallback={() => dialog.close()}
        >
          <form
            className={ui.formPropousal}
            noValidate
            onSubmit={formHook.handleSubmit(handleAddPropousal, console.log)}
          >
            <div>
              <Label>Persona que atiende</Label>
              <textarea
                name="attn"
                id="attn"
                ref={formHook.register}
                placeholder="Escribe aquí (Obligatorio)"
              ></textarea>
              <p className="text-danger font-weight-bold">
                {formHook.errors.attn?.message}
              </p>
            </div>

            <div>
              <Label>Fecha de expiración</Label>
              <Controller
                control={formHook.control}
                name="expirationDate"
                render={({ onChange, value }) => (
                  <InputDate
                    inputChange={(date) => {
                      onChange(date);
                    }}
                    minDate={new Date()}
                    date={value}
                  />
                )}
              />
              <p className="text-danger font-weight-bold">
                {formHook.errors.expirationDate?.message}
              </p>
            </div>

            <div>
              <Label>Términos de pago</Label>
              <textarea
                name="paymentTerms"
                ref={formHook.register}
                placeholder="Escribe aquí (Obligatorio)"
              ></textarea>
              <p className="text-danger font-weight-bold">
                {formHook.errors.paymentTerms?.message}
              </p>
            </div>

            <div>
              <Label>Términos y condiciones</Label>
              <textarea
                name="termsAndConditions"
                ref={formHook.register}
                placeholder="Escribe aquí (Obligatorio)"
              ></textarea>
              <p className="text-danger font-weight-bold">
                {formHook.errors.termsAndConditions?.message}
              </p>
            </div>

            {state.state === "none" ? (
              <ButtonV2>
                <span>Crear propuesta</span>
                <Icon icon="save" />
              </ButtonV2>
            ) : (
              <Spinner text={"Generando propuesta"} hidden={false} />
            )}
          </form>
        </Dialog>
      )}
    </>
  );
}
