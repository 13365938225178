import "./facturama.api";
import "../../types/facturama";
import { GetCustomer, validateCustomerIsSync } from "../Apis/Directory";
import { AssociateMizarWithSystem } from "../Apis/mizar";

const Facturama = window.Facturama;

/**
 * Insert a new customer into facturama
 * @param {CustomerFact} customer - Information of the customer
 * @returns {CustomerFactRes} Customer response
 */
export async function AddCustomerFacturama(customer = null) {
  console.log(customer);

  return new Promise((resolve, reject) => {
    if (customer === null) {
      reject("Provide a customer before insert information");
    }

    Facturama.Clients.Create(
      customer,
      (client) => {
        resolve({
          ...client,
          wasAdded: true,
        });
      },
      (error) => {
        console.log(error);

        resolve({
          wasAdded: false,
        });
      }
    );
  });
}

/**
 *
 * @param {CustomerFact} customer - New information of the customer
 * @param {string} id - Id of the customer
 * @returns {CustomerFactRes} New information of the customer updated
 */
export async function EditCustomerFacturama(customer = null, id) {
  return new Promise((resolve, reject) => {
    if (customer === null) {
      reject("Provide provide the id of the customer in order to update");
    }
    Facturama.Clients.Update(
      id,
      customer,
      (data) => resolve(true),
      (error) => {
        console.log(error);
        reject(false);
      }
    );
  });
}

////////////////////////////////////////////

const parseAdress = (info = null) => (info === null ? "ND" : info);

/**
 * Parse the form of the system like Facturama request it
 * @param {Customer} customerInfo
 * @returns {CustomerFact} Facturama info needed to add a customer
 */
export const parseDirectoryToFacturama = (customerInfo) => ({
  Id: customerInfo.idFacturama,
  Email: customerInfo.emailCustomer,
  EmailOp1: "",
  EmailOp2: "",
  Address: {
    Street: parseAdress(customerInfo.streetCustomer),
    ExteriorNumber: parseAdress(customerInfo.exteriorNumber),
    InteriorNumber: parseAdress(customerInfo.insideNumber),
    Neighborhood: parseAdress(customerInfo.customerColony),
    ZipCode: parseAdress(customerInfo.postalCode),
    Locality: "ND",
    Municipality: parseAdress(customerInfo.customerCity),
    State: parseAdress(customerInfo.customerState),
    Country: customerInfo.customerCountry,
  },
  Rfc: customerInfo.customerRFC,
  Name: customerInfo.socialReason,
  CfdiUse: "P01",
  TaxResidence: "",
  NumRegIdTrib: "",
});

/**
 * Check if the customer it's sync with the provider, if not, try sync it and return the id
 *
 * @param {number} idCustomer - Id of the customer
 * @returns {{
 *  isSync:boolean,
 *  idFacturama:(string|null)
 * }}
 */
export const reSyncCreatedCustomer = async (idCustomer) => {
  try {
    const { isSync, idFacturama } = await validateCustomerIsSync(idCustomer);

    if (isSync) {
      return {
        isSync,
        idFacturama,
      };
    }

    const apiCustomer = await GetCustomer(idCustomer);

    /**
     * @type CustomerFact
     */
    const formatSync = {
      Email: apiCustomer.contact.email,
      EmailOp1: "",
      EmailOp2: "",
      Address: {
        Country: apiCustomer.adress.country,
        ExteriorNumber: apiCustomer.adress.extNumber,
        InteriorNumber: apiCustomer.adress.intNumber,
        Street: apiCustomer.adress.street,
        Locality: "ND",
        Municipality: apiCustomer.adress.city,
        Neighborhood: apiCustomer.adress.colony,
        State: apiCustomer.adress.state,
        ZipCode: apiCustomer.adress.cp,
      },
      Name: apiCustomer.socialReason,
      Rfc: apiCustomer.rfc,
      CfdiUse: "P01",
      TaxResidence: "",
      NumRegIdTrib: "",
    };

    const { Id: idFacturamaSync } = await AddCustomerFacturama(formatSync);

    const wasSync = await AssociateMizarWithSystem(idFacturama, idCustomer);

    if (wasSync) {
      return {
        isSync: true,
        idFacturama: idFacturamaSync,
      };
    } else {
      return {
        isSync: false,
        idFacturama: null,
      };
    }
  } catch (error) {
    return {
      isSync: false,
      idFacturama: null,
    };
  }
};
