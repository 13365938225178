import InformativeTooltip from "components/individual/InfoTooltip";
import { InputCurrency } from "components/individual/inputs/Inputs";
import useClosePeriod from "customHooks/useClosePeriod";
import { MONTH_LIST, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import React, { createContext } from "react";
import { Label } from "structure/Document";
import Dialog from "structure/Dialog";
import BankAcconuntSelect from "components/individual/BankAccount";
import Close from "./Close";
import MoreInfo from "components/individual/MoreInfo";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("react").Context<import("./types").ContextClosePeriod|undefined>}
 */
export const ContextCloseMonth = createContext(undefined);

/**
 * Close the period of a month
 * @param {import('./types').PropsClosePeriod} props - Props
 * @returns {JSX.Element}
 */
export const ClosePeriod = ({
  month = null,
  year = null,
  onChange = () => {},
  children = <></>,
  forwardRef = undefined,
}) => {
  const hook = useClosePeriod(month, year);

  const { checkPermission } = usePermissions();

  if (!checkPermission("46686ae3-513f-4934-ae7c-c6f14b8720c8")) return <></>;

  return (
    <ContextCloseMonth.Provider value={{ ...hook, onChange }}>
      <div ref={forwardRef} onClick={hook.openModal}>
        {children}
      </div>

      <Dialog
        width={25}
        title={
          hook.bankAccount === undefined
            ? "Cerrar periodo"
            : `Cerrar periódo de ${MONTH_LIST[hook.month || 0].name} ${
                hook.year || 0
              }`
        }
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.closeModal}
        footer={<Close />}
      >
        <>
          {hook.isOpen ? (
            <div className="mb-2">
              <BankAcconuntSelect
                onChange={(data) => hook.setBankAccount(data)}
              />
            </div>
          ) : null}

          {hook.bankAccount !== undefined ? (
            <>
              <Label required={hook.amounth === 0}>
                Saldo en banco
                <MoreInfo>
                  <p>
                    Se validaran las sumas de ingresos contra egresos para
                    revisar que esta sea igual a la del estado de banco que
                    tengas en tu portal para poder cerrar el mes
                  </p>
                </MoreInfo>
              </Label>
              <InputCurrency
                labelTxt=""
                defaultValue={hook.amounth}
                placeholder={`Ejemplo: $4,999.99`}
                onChange={(money) => hook.updateAmount(money)}
              />
              <div className="my-2">
                <Label>
                  Saldo en el sistema al {parseDateToText(hook.lastDayOfMonth)}
                </Label>
                <p>{mnCurrency(hook.balance)}</p>
              </div>
              <div className="my-2">
                <Label className="my-2">Variacion</Label>
                <b
                  className={
                    !hook.isValidVariation ? "text-danger" : "text-success"
                  }
                >
                  {mnCurrency(hook.currentVariation)}
                </b>
              </div>
            </>
          ) : null}
        </>
      </Dialog>
    </ContextCloseMonth.Provider>
  );
};
