import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { ContextTickets } from "pages/Tickets";
import React, { useContext } from "react";

export default function SearchButton() {
  const hook = useContext(ContextTickets);

  return (
    <ButtonV2
      disabled={hook.state.isCreatingTicket}
      onClick={hook.refetchTickets}
    >
      <span>Buscar</span>
      <Icon icon="search" />
    </ButtonV2>
  );
}
