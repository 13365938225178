import React, { useContext } from "react";
import { ContextMaterials } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ui from "./styles.module.scss";
import { mnCurrency, percentage } from "helpers/money";
import ModalDocumentItems from "structure/ModalDocumentItems";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import InformativeTooltip from "components/individual/InfoTooltip";

export default function Header() {
  const materials = useContext(ContextMaterials);

  if (materials.status === "loading")
    return <Spinner hidden={false} text={"Cargando posición"} />;

  return (
    <div className={ui.header}>
      <div>
        <div>
          <b>Solped</b>
          <p>{materials.proyect.solped}</p>
        </div>

        <div>
          <b>Cliente</b>
          <p>{materials.customer.socialReason}</p>
        </div>

        <div>
          <b>Pos</b>
          <p>{materials.position.pos}</p>
        </div>

        <div>
          <b>
            Costo estimado{" "}
            <InformativeTooltip>
              <p>Costo de la posición (No incluye IVA)</p>
            </InformativeTooltip>
          </b>
          <p>{mnCurrency(materials.position.cost)}</p>
        </div>

        <div>
          {/* costo total = la suma del costo de materiales */}
          <b>
            Costo total{" "}
            <InformativeTooltip>
              <p>Suma de los costos del material presupuestado (No incluye IVA)</p>
            </InformativeTooltip>
          </b>
          <p>{mnCurrency(materials.purchases)}</p>
        </div>

        <div>
          {/* compras = la suma de los materiales ya comprados */}
          <b>
            Compras{" "}
            <InformativeTooltip>
              <p>Suma de los costos de las OC ya generadas</p>
            </InformativeTooltip>
          </b>
          <p>{mnCurrency(materials.purchasesBought)}</p>
        </div>

        <div>
          {/* costos del grid */}
          <b>
            Costo evento{" "}
            <InformativeTooltip>
              <p>Cantidad de la grid por el costo del material operado</p>
            </InformativeTooltip>
          </b>
          <p>{mnCurrency(materials.costEvent)}</p>
        </div>

        <div>
          <b>
            Saldo{" "}
            <InformativeTooltip>
              <p>Costo total - Compras - Costo evento</p>
            </InformativeTooltip>
          </b>
          <p>{mnCurrency(materials.residue)}</p>
        </div>
      </div>

      <div>
        <ModalDocumentItems
          onChange={async (item) => {
            await materials.attemptAddMaterial(
              {
                cost: item.cost,
                idCatalogue: item?.idCatalogue,
                idPosition: materials.position.id,
                idProyect: materials.proyect.id,
                initialQuantity: item.quantity,
                sell: item.sell,
                description: item.description,
              },
              item
            );
          }}
        >
          {materials.residue < 0 ? null : (
            <ButtonV2>
              <span>Agregar</span>
              <Icon icon="plus" />
            </ButtonV2>
          )}
        </ModalDocumentItems>

        {!materials.isValidGrid || materials.status === "updating" ? null : (
          <ButtonV2 onClick={async () => await materials.attemptCreateOc()}>
            <span>Generar OC</span>
            <Icon icon="plus" />
          </ButtonV2>
        )}

        {materials.status === "updating" ? (
          <Spinner text={"Generando OC"} hidden={false} />
        ) : null}

        {materials.residue < 0 ? (
          <p className="text-danger font-weight-bold m-0">
            El saldo no puede ser menor a $0.00
          </p>
        ) : null}
      </div>
    </div>
  );
}
