import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { Error, Success } from "../alerts";
import { isValidHttpResCode } from "./fetch";

/**
 * Get the permissions that has a user on the system
 * @param {number} idUser - Id user
 * @returns {import("types/typedef/roles").UserPermissionsI} Permissions of the user
 * @throws
 */
export const getUserPermissions = async (idUser) => {
  try {
    const response = await axios.get(
      `${URL_BASE}usuarios/obtener/permisos/${idUser}`,
      {
        withCredentials: true,
      }
    );

    /**
     * Permissions of the user
     * @type {import("types/typedef/users").UserPermissionsDto}
     */
    const data = response.data;

    return {
      permissions: data.arrayPermissions,
      isModified: +data.IsMod,
    };
  } catch (error) {
    const { resquest, response } = error;
  }
};

/**
 *
 * @param {number} idUser - Id of the user to fetch his permissions
 * @returns
 */
export const getUserPermissionsWithoutToken = async (idUser) => {
  try {
    const { data } = await axios.get(`${URL_BASE}usuarios/permisos/${idUser}`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {}
};

export const skeletonUsuarios = async () => {
  try {
    const { data } = await axios.get(`${URL_BASE}usuarios/getusuarios`,{
      withCredentials:true
    });

    if (data.status === 200) {
      return data;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

export async function confirmIdentity(form) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}usuarios/confirmar-identidad`,
      form
    );

    if (data.status === 200 && data.isValid === true) {
      return true;
    } else {
      Error(() => {}, "Contraseña incorrecta");
      return false;
    }
  } catch (error) {
    Error(() => {}, "Intenta de nuevo");
    return false;
  }
}

/**
 * @typedef UpdatePass
 * @type {object}
 * @property {string} password1 - New password of the user
 * @property {string} password2 - Confirmation password
 * @property {number} userId - Id of the user will be updated the password
 */
/**
 * @typedef AddUser
 * @type {object}
 * @property {string} apellidoM;
 * @property {string} apellidoP;
 * @property {string} dateMonthYear;
 * @property {string} email;
 * @property {number} jefe;
 * @property {string} middleName;
 * @property {string} nombre;
 * @property {number} roles;
 * @property {string} userName;
/**
 * @typedef ResponseAddUser
 * @type {object}
 * CodeNumber: 200
 * @property {number} ErrorOccurred
 * @property {string} message
 * @property {number} status
 */

/**
 * Request the API update the password of the user 'x' with the argument passed
 *
 * @param {UpdatePass} form - Object to update the password of user
 * @returns {boolean} If true, update was success
 */

export async function updatePassword(form) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}usuarios/actualizar-password`,
      form
    );

    if (data.status === 200) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.error);
    return false;
  } catch (error) {
    Error(() => {}, error);
  }
}

//------------------------------ VERSION 2 --------------------------------------//
export async function getUsersPagination(
  page,
  order,
  columnordering,
  additionalQuery
) {
  console.log(additionalQuery);
  try {
    const { data } = await axios.get(
      `${URL_BASE}usuarios/getUsuarios?pagina=${page}&orden=${order}&columna=${columnordering}${additionalQuery}`
    );

    console.log("Estatus de la consulta");
    console.log(data.data);

    if (data.status === 200) {
      return {
        status: true,
        tabla: data.data.remindersToAttent,
        pages: data.data.pages,
        actualPage: data.data.actualPage,
        mensaje: "funciono la consulta",
      };
    } else {
      Error(() => {}, data.error);
      return {
        status: false,
        tabla: [],
        mensaje: "fallo la consulta",
        error: data,
      };
    }
  } catch (error) {
    Error(() => {}, error);
    return {
      status: false,
      tabla: [],
      mensaje: "fallo la consulta catch",
      error: error,
    };
  }
}

/**
 * Revoke and provide permissions to the user
 * @param {import("types/typedef/users").DtoUserPermissions} dto - Information to update/add permissions to the user
 * @returns {boolean} True if query was made success
 */
export async function updateUserPermissions(dto) {
  try {
    await axios.post(`${URL_BASE}usuarios/agregar/permisos`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.Message);

    return false;
  }
}

/**
 * Reset the user permission to its rol
 * @param {number} userId - Id of the user to reset his permissions
 * @returns {import("types/typedef/users").ResResetUserPermissionsI} Response server
 */
export async function resetUserPermissions(userId) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}usuarios/reiniciar/permisos/${userId}`,
      null,
      {
        withCredentials: true,
      }
    );

    return {
      updated: true,
      message: data.message,
    };
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);

    return {
      updated: false,
      message: data.message,
    };
  }
}

/**
 * Get the information of the users paginated
 * @param {number} page - Page requested
 * @param {"ASC"|"DESC"} order - Desc or Asc ordering
 * @param {string} columnordering - Column info will be sorted
 * @param {string?} search - Search criteria
 * @returns {object}
 */
export async function getUsersInfo(page, order, columnordering, search = "") {
  try {
    const { data } = await axios.get(
      `${URL_BASE}usuarios/info/allUsers?pagina=${page}&orden=${order}&columna=${columnordering}&search=${search}`
    );

    if (data.status === 200) {
      return {
        status: true,
        tabla: data.data.remindersToAttent,
        pages: data.data.pages,
        actualPage: data.data.actualPage,
        mensaje: "funciono la consulta",
      };
    } else {
      Error(() => {}, data.error);
      return {
        status: false,
        tabla: [],
        mensaje: "fallo la consulta",
        error: data,
      };
    }
  } catch (error) {
    Error(() => {}, error);
    return {
      status: false,
      tabla: [],
      mensaje: "fallo la consulta catch",
      error: error,
    };
  }
}
/**
 * Add a new user into the system
 * @param {import("pages/ProcesosEspeciales/Usuarios/types").DtoAddUser} user - DTO user
 * @returns {ResponseAddUser}
 */
export const addUser = async (user) => {
  try {
    const res = await fetch(`${URL_BASE}usuarios/agregar/usuario`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
};

/**
 * Update the profile information
 * @param {object} dto - ??
 * @param {any} profilePicture - ??
 * @returns {{
 *  wasUpdated:boolean,
 *  message:string
 * }}
 */
export const UpdateProfile = async (dto, profilePicture) => {
  try {
    const data = new FormData();
    data.append("data", JSON.stringify(dto));
    data.append("profilePicture", profilePicture);

    const { data: res } = await axios.put(`${URL_BASE}usuarios/perfil`, data, {
      withCredentials: true,
    });

    return {
      wasUpdated: true,
      message: res.message,
    };
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.message);
    return {
      wasUpdated: false,
      message: data.message,
    };
  }
};

export const getUserInfo = async (idExecutive) => {
  try {
    const { data } = await axios.get(
      `${URL_BASE}usuarios/info/representar?idUserRepresented=${idExecutive}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    console.log("Hubo un error");
    console.log(error);
    return error;
  }
};

/**
 * Get information of the user requested
 * @param {number} id - Id user
 * @returns {Promise<import("../../../../server/models/user/types").UserParsed>}
 */
export async function getUser(id) {
  try {
    const response = await fetch(`${URL_BASE}usuarios/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Get information of the user requested
 * @returns {Promise<string|undefined>}
 */
export async function get2faQrCode() {
  try {
    const response = await fetch(`${URL_BASE}usuarios/2FA`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      const data = await response.json();

      return data.qrCode;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the key of the 1fa process on the qr
 * @param {string} uriQrCode - Uri code of the 2FA
 * @returns {string}
 */
export const getKey2faQrCode = (uriQrCode) =>
  uriQrCode.split("?")[1].split("&")[0].split("=")[1];

/**
 * Get information of the user requested
 * @param {string} otp - One Time Password
 * @param {string} key - Key of the qr code provided
 * @returns {Promise<string[]|string>}
 */
export async function add2faDevice(otp, key) {
  try {
    const response = await fetch(`${URL_BASE}usuarios/2FA/${otp}/${key}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (response.ok && isValidHttpResCode(response.status)) {

      return data.recoveryKeys;
    }
    return data.message;
  } catch (error) {
    return error.message;
  }
}

/**
 * Validate the login with the 2FA
 * @param {string} otp - One Time Password
 * @param {string} user - Email of username in order to check the OTP verification
 * @returns {Promise<undefined|object>}
 */
export async function loginWith2fa(otp, user) {
  try {
    const response = await fetch(`${URL_BASE}login2fa`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp, usuario: user }),
    });

    if (response.ok && isValidHttpResCode(response.status)) {
      return {
        token: undefined,
        userInfo: undefined,
        user,
      };
    }

    const data = await response.json();
    Error(() => {}, data.message);

    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}

/**
 * Revoke the 2FA of the logged account
 * @returns {Promise<boolean>}
 */
export async function revoke2fa() {
  try {
    const response = await fetch(`${URL_BASE}usuarios/2FA/desactivar`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);

    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}
