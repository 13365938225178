import { createContext } from "react";

const WinQuoteContext = createContext({
  quoteInfo: null,
  quoteComments: [],
  quoteDocItems: [],
  ocComments: [],
  contractComments: [],
  iva: {
    number: 0,
    text: "",
  },
  generateContract: false,
  preInvoiceComments: [],
  provider: null,
  providerCreditDays: null,
  invoiceCreditDays: null,
  tempImport: null,
  tempDocItems: null,
  costsItems: null,
  copyCostsItems: null,
  areCostErrors: false,
  costs: {
    iva: {
      number: 0,
      text: "$0.0",
    },
    import: {
      number: 0,
      text: "$0.0",
    },
    total: {
      number: 0,
      text: "$0.0",
    },
  },
});
export default WinQuoteContext;
