import React, { useContext } from "react";
import { Label } from "structure/Document";
import { IncomingContext } from "..";
import { parseDateToText } from "helpers/dates";
import style from './styles.module.scss';

export default function PillsMobile() {
  const hook = useContext(IncomingContext);

  return (
    <div className={style.pillsContainer}>
      <div>
        <Label>Desde - Hasta</Label>
        <p>{parseDateToText(hook.startDate,"short")} - {parseDateToText(hook.endDate,"short")}</p>
      </div>

      <div>
        <Label>En proceso</Label>
        <p>{hook.justOnProcess ? "Si" : "No"}</p>
      </div>
    </div>
  );
}
