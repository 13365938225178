import React, { useRef } from "react";
import useManufacturers from "./useManufacturers";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import Manufacturer from "./Manufacturer";
import SocialReasonV2 from "components/general/Forms/Selects/SocialReasonV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

/**
 * Render a manufacturers screen modal
 * @param {import('./types').PropsManufacturers} props - Props
 * @returns {JSX.Element}
 */
export default function Manufacturers({
  children = <></>,
  forwardRef = undefined,
  id = null,
  onChange = () => {},
}) {
  const hook = useManufacturers(id, onChange);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <div>
      <div onClick={hook.dialog.open} ref={forwardRef}>
        {children}
      </div>

      <Dialog
        closeCallback={hook.dialog.close}
        title="Fabricantes"
        forwardRef={hook.dialog.refDialog}
      >
        <>
          <div className={ui.header}>
            {hook.isAdding ? (
              <Spinner text={"Agregando"} hidden={false} />
            ) : (
              <div>
                <b>Proveedor</b>
                <SocialReasonV2
                  type="proveedor"
                  includeInactives={false}
                  onChange={(socialReason) =>
                    hook.setManufacturer(socialReason)
                  }
                />
              </div>
            )}

            <div>
              <b>Clave</b>
              <input
                disabled={hook.isAdding}
                type="text"
                placeholder="Escribe aqui para agregar"
                value={hook.key}
                onChange={(e) => hook.setKey(e.target.value)}
              />
            </div>

            <div className={ui.add}>
              <b>Acciones</b>
              <ButtonV2
                type="button"
                disabled={
                  hook.manufacturer === null || hook.key === "" || hook.isAdding
                }
                onClick={hook.attemptAdd}
              >
                <span>+</span>
                <Icon icon="plus" />
              </ButtonV2>
            </div>
          </div>

          {hook.manufacturers
            .filter((manufacturer) => manufacturer.status === true)
            .map((manufacturer, i) => (
              <Manufacturer
                {...manufacturer}
                keyValue={manufacturer.key}
                key={`${key.current}-${i}`}
                onChange={(data, operation) =>
                  hook.handleOnChange(data, operation)
                }
              />
            ))}
        </>
      </Dialog>
    </div>
  );
}
