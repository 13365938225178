import React, { useContext } from "react";
import { ContextBankAccountReport } from "..";
import Select from "components/individual/SelectV4";
import { Label } from "structure/Document";
import YearSelect from "components/general/Forms/Selects/Year";
import { getLowestDate } from "helpers/dates";
import ui from "./styles.module.scss";

export default function PeriodCombo() {
  const hook = useContext(ContextBankAccountReport);

  //   No bank account selected yet
  if (hook.account === null)
    return (
      <div>
        <Label required={true}>Periodo</Label>
        <p>Selecciona una cuenta de banco</p>
      </div>
    );

  if (hook.availableDates.length <= 0)
    return (
      <div className={ui.period}>
        <div>
          <Label>Año</Label>
          <p>ND</p>
        </div>

        <div>
          <Label>Mes</Label>
          <p>ND</p>
        </div>
      </div>
    );

  return (
    <div className={ui.period}>
      <YearSelect
        from={new Date(
          getLowestDate(hook.account.monthsClosed)
        ).getUTCFullYear()}
        onChange={(year) => hook.setYear(year)}
      />

      {typeof hook.year !== "number" ? (
        <div>
          <Label>Mes</Label>
          <p>Selecciona un año</p>
        </div>
      ) : (
        <div>
          <Label>Mes</Label>
          <Select
            labelToDisplay="name"
            options={async () => hook.availableMonths}
            defaultSelected={{
              keyToCompare: "value",
              value: hook.month,
            }}
            onChange={(month) => hook.setMonth(month.value)}
          />
        </div>
      )}
    </div>
  );
}
