import React, { useContext } from "react";
import AddInvoice from "../AddInvoice";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ContextInvoice } from "..";
import scss from "./styles.module.scss";
import ExcelReport from "../ExcelReport";
import DateFilter from "./DateFilter";
import Icon from "components/individual/HeaderIcon";
import SocialReasonSelect from "./SocialReasonSelect";
import StatusSelect from "./StatusSelect";
import AccountingStatusSelect from "./AccountingStatusSelect";
import DocumentNumber from "./DocumentNumber";

export default function InvoiceFilter() {
  const { searchWithFilters, from } = useContext(ContextInvoice);

  return (
    <div className={from === 'contabilidad' ? scss.filterAccounting : scss.filterNoAccounting}>
      <SocialReasonSelect />
      <DateFilter />
      <StatusSelect />
      <AccountingStatusSelect />
      <DocumentNumber />
      
      <ButtonV2 onClick={searchWithFilters}>
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>
      {from === "contabilidad" ? null : <AddInvoice />}
      <ExcelReport />
    </div>
  );
}
