import { InputText } from "components/individual/inputs/Inputs";
import React from "react";
import { AddCommentContainer } from "./Containers";
import { useContext } from "react";
import DocumentContext from "../ContextDocument";
import "./styles.css";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export const AddCommentSection = () => {
  /**
   * @type{import("../../../../../../types/documentActions").UseDocumentV2I}
   */
  const { handleOnCommentChange, addComment, currentComment, actionType } =
    useContext(DocumentContext);
  return (
    <AddCommentContainer>
      {actionType === "Ver" ? null : (
        <>
          <InputText
            placeholderTxt={
              currentComment.isEditing ? "Deshabilitado" : "Escribe aquí"
            }
            labelTxt="Comentario"
            onChange={handleOnCommentChange}
            defaultValue={
              !!currentComment.comment && !currentComment.isEditing
                ? currentComment.comment
                : ""
            }
            disabled={currentComment.isEditing}
            onEnter={() => {
              addComment();
            }}
          />

          <ButtonV2
            disabled={Object.keys(currentComment).length <= 0}
            type="button"
            onClick={addComment}
          >
            <span>+</span>
            <Icon icon="plus" />
          </ButtonV2>
        </>
      )}
    </AddCommentContainer>
  );
};
