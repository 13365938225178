import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const InfoIcon = <FontAwesomeIcon icon={faInfoCircle} width="35px" />;

/**
 * Informative tooltip to explain "something"
 * @param {object} props - Props
 * @param {JSX.Element|JSX.Element[]|string|string[]} props.children - Content to render inside the tooltip info
 * @returns {JSX.Element}
 */
export default function InformativeTooltip({
  children = <p>Más informacion no disponible...</p>,
}) {
  return (
    <Tooltip placement="top" trigger={["hover"]} overlay={children}>
      <span className="mx-2">{InfoIcon}</span>
    </Tooltip>
  );
}
