import React, { useRef } from "react";
import style from "../../styles.module.scss";
import { Label } from "structure/Document";
import { useContext } from "react";
import { ContextAdvancePayment } from "../..";
import MoreInfoMovement from "structure/MoreInfoMovement";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";

/**
 * Render the elements to display for mobile view
 * @param {import("customHooks/usePaymentAdvances/types").Advance} advance
 * @returns {JSX.Element}
 */
export default function MobileItem(advance) {
  const hook = useContext(ContextAdvancePayment);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInfo = useRef();

  return (
    <div className={style.mobileAdvance}>
      <div className="d-flex justify-content-between">
        <div>
          <Label>
            {hook.movementType === "clientes" ? "Ingreso" : "Egreso"}
          </Label>
          <MoreInfoMovement
            forwardRef={domMoreInfo}
            id={+advance.id}
            isForCancel={false}
          >
            {advance.id}
          </MoreInfoMovement>
        </div>

        <ElipsisMobile onShow={() => hook.selectAdvance(advance)}>
          <CustomMenuItem onClick={() => domMoreInfo.current.click()}>
            Más información
          </CustomMenuItem>
          <CustomMenuItem onClick={() => hook.redirectToEdit()}>
            Editar
          </CustomMenuItem>
        </ElipsisMobile>
      </div>

      <div>
        <Label>Fecha</Label>
        <span>{parseDateToText(new Date(`${advance.date}:`))}</span>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <Label>Banco</Label>
          <span>{advance.bankAccount.bank.shortName}</span>
        </div>

        <div>
          <Label>Cuenta</Label>
          <span>{advance.bankAccount.account}</span>
        </div>
      </div>

      <div>
        <Label>
          {hook.movementType === "clientes" ? "Cliente" : "Proveedor"}
        </Label>
        <span>{advance.customer.socialReason}</span>
      </div>

      <div className={style.amounts}>
        <div>
          <Label>Moneda</Label>
          <span>{advance.bankAccount.currency}</span>
        </div>
        <div>
          <Label>Importe</Label>
          <span>{mnCurrency(advance.residue)}</span>
        </div>
      </div>
    </div>
  );
}
