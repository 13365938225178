import { dateToDbFormat } from "./dates";

/**
 * Parse the periocity as the server request it for save the periocity
 * @param {1|2} type - Type of periocity
 * @param {number} value - Duration of the range or number of the month
 * @param {Date} start - Start date
 * @param {Date} end - End date
 * @returns {import("customHooks/useContract/types").PeriocityDto} Dto in order to save the periocity of the document
 */
export function getDtoPeriocity(type, value, start, end) {
  return {
    end: end === null ? null : dateToDbFormat(end),
    start: start === null ? null : dateToDbFormat(start),
    type: typeof type === "string" ? null : type,
    value,
  };
}

/**
 * Parse a number to folio format
 * @param {number} [docNumber=0] - Number
 * @returns {string}
 * @example
 * 32 -> '00000032'
 */
export function parseToFolio(docNumber) {
  if (typeof docNumber !== "number") return "ND";

  return String(docNumber).padStart(8, "0");
}

/**
 *
 * @param {string|null} currency
 */
export function currencyDto(currency) {
  const indexed = {
    USD: "Moneda mexicana",
    MXN: "Moneda estadounidense",
  };

  return {
    code: currency,
    description: indexed[currency] || "ND",
    symbol: "$",
  };
}
