import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFileImage,
  faFile,
  faFileCode,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FileInfoCard, IconFile } from "components/individual/SendEmail/styles";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { saveAs } from "file-saver";
import ui from './styles.module.scss'

export const deleteIcon = <FontAwesomeIcon icon={faTimes} size="1x" />;
export const noIdentifiedExtensionIcon = (
  <FontAwesomeIcon icon={faFile} size="2x" />
);
const sizeIconFile = "2x";

export const dynamicIcons = {
  pdf: <FontAwesomeIcon icon={faFilePdf} size={sizeIconFile} />,
  jpg: <FontAwesomeIcon icon={faFileImage} size={sizeIconFile} />,
  jpeg: <FontAwesomeIcon icon={faFileImage} size={sizeIconFile} />,
  png: <FontAwesomeIcon icon={faFileImage} size={sizeIconFile} />,
  xml: <FontAwesomeIcon icon={faFileCode} size={sizeIconFile} />,
  doc: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  docm: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  docx: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  dot: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  dotm: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  dotx: <FontAwesomeIcon icon={faFileWord} size={sizeIconFile} />,
  pptx: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  pptm: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  ppt: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  potx: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  potm: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  pot: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  ppsx: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  ppsm: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  pps: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  ppam: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  ppa: <FontAwesomeIcon icon={faFilePowerpoint} size={sizeIconFile} />,
  xlsx: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xlsm: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xlsb: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xltx: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xltm: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xls: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xlt: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xla: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xlw: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
  xlr: <FontAwesomeIcon icon={faFileExcel} size={sizeIconFile} />,
};

export const colorIcon = {
  pdf: "#960000",
  jpg: "#26B99A",
  jpeg: "#26B99A",
  png: "#26B99A",
  xml: "#2B79C3",
  doc: "#08a9eb",
  docm: "#08a9eb",
  docx: "#08a9eb",
  dot: "#08a9eb",
  dotm: "#08a9eb",
  dotx: "#08a9eb",
  pptm: "#dc4c4c",
  ppt: "#dc4c4c",
  potx: "#dc4c4c",
  potm: "#dc4c4c",
  pot: "#dc4c4c",
  ppsx: "#dc4c4c",
  ppsm: "#dc4c4c",
  pptx: "#dc4c4c",
  pps: "#dc4c4c",
  ppam: "#dc4c4c",
  ppa: "#dc4c4c",
  xlsx: "#1c6c40",
  xlsm: "#1c6c40",
  xlsb: "#1c6c40",
  xltx: "#1c6c40",
  xltm: "#1c6c40",
  xls: "#1c6c40",
  xlt: "#1c6c40",
  xla: "#1c6c40",
  xlw: "#1c6c40",
  xlr: "#1c6c40",
};

/**
 * Render a card with the info of the card
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useEmail").FileInfoI} props.fileInfo - Informative info to render the card
 * @param {string} [props.uuidFileName=undefined] - uuid assinged to that file
 * @param {(string)=>void} props.onDelete - uuid filename to delete from the list
 * @param {Blob|File|null}  [props.blob=null] - File to download
 * @returns {JSX.Element} Component
 */
export default function FileInfo({
  fileInfo,
  onDelete = () => {},
  uuidFileName,
  blob = null,
}) {
  const { fileName, extension, size } = fileInfo;

  return (
    <FileInfoCard>
      {blob === null ? (
        <IconFile onClick={() => saveAs(blob)} color={colorIcon[extension]}>
          {dynamicIcons[extension] || noIdentifiedExtensionIcon}
        </IconFile>
      ) : (
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={<p className="my-0">Descargar archivo</p>}
        >
          <IconFile onClick={() => saveAs(blob)} color={colorIcon[extension]}>
            {dynamicIcons[extension] || noIdentifiedExtensionIcon}
          </IconFile>
        </Tooltip>
      )}


      <div >
        <p className={ui.overview}>{fileName}</p>
        {size !== null ? (
          <p>
            {size.value} {size.unit}
          </p>
        ) : null}
      </div>
      <div onClick={(e) => onDelete(uuidFileName)}>❌</div>
    </FileInfoCard>
  );
}
