import useDialog from "customHooks/useDialog";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Dialog from "structure/Dialog";
import FormDocumentItems, {
  Currency,
  Quantity,
  Cost,
  Sell,
  DiscountCost,
  DiscountSell,
  CatalogueCombo,
  Description,
  IvaContainer,
  IvaField,
  IvaExemptField,
  UenField,
  SatCodeField,
  SatUmField,
  CostOperation,
  SellOperation,
  MarginErrorMsg,
  SubmitItemCatalogue,
} from "structure/FormDocumentItems";

/**
 * Render a modal to add or edit document items
 * @param {import("./types").PropsModalDocumentItems} props - Props
 */
export default function ModalDocumentItems({
  children,
  onChange = () => {},
  initialData = undefined,
  title = "Materiales",
}) {
  const [state, setState] = useState({
    isOpen: false,
  });

  const id = useRef(`${window.crypto.randomUUID()}`);

  const dialog = useDialog();

  useEffect(() => {
    if (state.isOpen) dialog.open();
  }, [state.isOpen]);

  const handleOpen = (isOpen) =>
    setState((current) => ({
      ...current,
      isOpen,
    }));

  if (!state.isOpen)
    return <div onClick={(e) => handleOpen(true)}>{children}</div>;

  return (
    <Fragment>
      {children}
      <FormDocumentItems
        initialData={initialData}
        id={id.current}
        onCorrectSubmit={(data) => {
          handleOpen(false);
          onChange(data);
        }}
      >
        <Dialog
          closeCallback={() => handleOpen(false)}
          title={title}
          forwardRef={dialog.refDialog}
          width={60}
          footer={
            <div className="d-flex justify-content-between align-items-center">
              <MarginErrorMsg />
              <SubmitItemCatalogue form={id.current} type="submit">
                {initialData === undefined ? 'Agregar' : 'Actualizar'}
              </SubmitItemCatalogue>
            </div>
          }
        >
          <div className="row d-flex justify-content-around mb-2">
            <CatalogueCombo className="col-sm-12 col-md-5" />
            <Description className="col-sm-12 col-md-5" />
            <Currency className="col-sm-12 col-md-2" />
          </div>

          <div className="row mb-2">
            <UenField className="col-sm-12 col-md-4" />
            <SatCodeField className="col-4" />
            <SatUmField className="col-4" />
          </div>

          <div className="row d-flex justify-content-center mb-2">
            <IvaContainer>
              <IvaExemptField className="col-sm-12 col-md-2" />
              <IvaField className="col-sm-12 col-md-2" />
            </IvaContainer>
            <Cost className="col-sm-12 col-md-2" />
            <Sell className="col-sm-12 col-md-2" />
          </div>

          <div className="row mt-4 mb-2">
            <div className="col-12">
              <hr />
            </div>

            <div className="col-12">
              <h3 className="text-center">Aplicable al documento</h3>
            </div>
          </div>

          <div className="row d-flex justify-content-around mb-2">
            <Quantity className="col-sm-12 col-md-2" />
            <DiscountCost className="col-sm-12 col-md-2" />
            <DiscountSell className="col-sm-12 col-md-2" />

            <CostOperation className="col-sm-12 col-md-2" />
            <SellOperation className="col-sm-12 col-md-2" />
          </div>
        </Dialog>
      </FormDocumentItems>
    </Fragment>
  );
}
