import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";

export default function Currency() {
  const hook = useContext(ContextEmitCreditNote);

  return (
    <div>
      <Label required={hook.invoice === undefined}>Moneda</Label>
      <p>
        {hook.invoice === undefined
          ? "Primero selecciona una factura"
          : hook.invoice.currency}
      </p>
    </div>
  );
}
