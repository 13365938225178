import EgressAssociations from "components/general/EgressAssociation";
import { GridCxC } from "components/general/GridCxC";
import ButtonV2 from "components/individual/ButtonsV2/Button";

import { Search } from "components/individual/Search";
import { Spinner } from "components/individual/Spinner/Spinner";
import { getPendingExpenses } from "helpers/Apis/Banks";
import { mnCurrency } from "helpers/money";
import BankInformation from "pages/Banks/BankInformation";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { OverviewConceptEgressInvoice } from "structure/OverviewConceptEgressInvoice";
import { EgressAssociationContext } from "..";
import InvoiceAmounts from "../InvoiceAmounts";
import style from "./test.module.scss";

export const Step1 = () => {
  const hookEgress = useContext(EgressAssociationContext);

  const [search, setSearch] = useState("");

  return (
    <>
      <div className="d-flex align-items-start">
        <BankInformation idAccount={hookEgress.bank.bankAccountID} />
        <div>
          <div className="w-100 px-4">
            <h4>Informacion del egreso</h4>
            <div className="d-flex">
              <p className="m-0 mr-4">
                <b>Egreso No</b>: {hookEgress.movement.MovementID}
              </p>
              <InvoiceAmounts />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <Label className="d-flex align-items-center">
        Búsqueda - Facturas recibidas por concepto de gastos
        <OverviewConceptEgressInvoice grid={hookEgress.gridAssociations} />
      </Label>

      <div className="mb-3 d-flex justify-content-between">
        <div className="mr-3 w-100">
          <Search
            placeholder="Proveedor, factura, concepto o UUID"
            onSearch={setSearch}
            onDelete={() => setSearch("")}
          />
        </div>

        <div className="d-flex justify-content-end">
          {hookEgress.isValid ? (
            <div className="d-flex align-items-end">
              {hookEgress.residue === 0 ? (
                hookEgress.isAssociating ? (
                  <Spinner hidden={false} text="Asociando..." />
                ) : (
                  <ButtonV2 onClick={async () => hookEgress.associate()}>
                    Asociar
                  </ButtonV2>
                )
              ) : hookEgress.residue < 0 ? (
                <p className="m-0 font-weight-bold text-danger">
                  El saldo no puede ser negativo
                </p>
              ) : (
                <ButtonV2 onClick={() => hookEgress.setStep(2)}>
                  Continuar
                </ButtonV2>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div className={style.container}>
        <div className={style.header}>
          <p>Proveedor</p>
          <p>Factura</p>
          <p>Concepto</p>
          <p>Parcialidad</p>
          <p>Moneda</p>
          <p>Total</p>
          <p>Pagos previos</p>
          <p>Pago a aplicar</p>
          <p>TC</p>
          <p>Saldo CxP</p>
        </div>

        <GridCxC
          currency={hookEgress.bank.currencyCode}
          availableBalance={hookEgress.movement.saldo}
          inyectionGrids={() => getPendingExpenses().then((data) => data)}
          onChange={hookEgress.updateInfo}
          tolerance={0}
          search={{
            keys: [
              "egress.description",
              "invoice.folio",
              "invoice.socialReason",
              "invoice.uuid",
            ],
          }}
          searchValue={search}
        >
          {({ element, InputBalance, Tc, BalancePending }) => (
            <div className={style.row} key={`element-${element.id}`}>
              <p>{element.invoice.socialReason}</p>

              <p>{element.invoice.folio}</p>

              <p>{element.egress.description}</p>

              <p className="text-center">1/1</p>

              <p className="text-center">{element.invoice.currency}</p>

              <p className="text-right">{element.total.text}</p>

              <p className="text-right">
                {mnCurrency(element.total.number - element.residue.number)}
              </p>

              <div className="text-right">
                <InputBalance />
              </div>

              <div className="text-center">
                <Tc />
              </div>

              <div
                className={`text-right ${
                  element.balancePendingToPay < 0 ? "text-danger" : ""
                }`}
              >
                <BalancePending />
              </div>
            </div>
          )}
        </GridCxC>
      </div>

      {/* <EgressAssociations
        currencyMovement={hookEgress.bank.currencyCode}
        total={hookEgress.movement.saldo}
        onChange={hookEgress.updateInfo}
        tolerance={hookEgress.tolerance}
        elements={
          hookEgress.searched.length <= 0
            ? hookEgress.pendingExpenses
            : hookEgress.searched
        }
      /> */}
    </>
  );
};
