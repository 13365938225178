import { AllExecutives } from "helpers/apis";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "../Spinner/Spinner";
// import Select from "react-select";
import { Label } from "structure/Document";
import Select from "../SelectV4";

/**
 * Render an executive combo
 * @param {import("./types").PropsExecutive} props - Props
 * @returns {JSX.Element}
 */
export default function Executives({
  required = false,
  onChange = () => {},
  onlyActives = false,
  value = null,
  includeAllOption = false,
}) {
  const [executives, setExecutives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const apiExecutives = await AllExecutives();

      const emptyOption = {
        value: null,
        status: true,
        firstName: "",
        middleName: "",
        lastName1: "",
        lastName2: "",
        initials: "",
        TEXT: "-- SELECCIONA --",
        disabled: true,
      };

      const options = [
        emptyOption,
        includeAllOption
          ? {
            status: true,
            value: -1,
              firstName: "",
              middleName: "",
              lastName1: "",
              lastName2: "",
              initials: "",
              TEXT: "Todos",
            }
          : {},
        ...apiExecutives.map((executive) => ({
          ...executive,
          label: executive.TEXT,
        })),
      ];

      const optionsToUse =
        onlyActives === false
          ? options
          : options.filter((item) => item.status === true);

      setExecutives(optionsToUse);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <Spinner hidden={false} text="Cargando ejecutivos" />;

  return (
    <div>
      <Label required={required}>Ejecutivo</Label>
      <Select
        onChange={onChange}
        labelToDisplay="TEXT"
        options={async () => executives}
        defaultSelected={{
          keyToCompare: "value",
          value,
        }}
      />
    </div>
  );
}
