import React from "react";
import useMoreInfoMovement from "./useMoreInfoMovement";
import Dialog from "structure/Dialog";
import { createContext } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import Incoming from "./Incoming";
import Egress from "./Egress";

/**
 * @type {import("react").Context<import("./types").ContextMoreInfoMovementV2|undefined>}
 */
export const ContextMoreInfoMovementV2 = createContext(undefined);

/**
 * Render the more information about a movement
 * @param {import("./types").PropsMoveInfoMovement} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInfoMovement({
  forwardRef = undefined,
  id = null,
  isForCancel = false,
  children = <></>,
  onCancelated = () => {},
}) {
  const hook = useMoreInfoMovement(id);

  return (
    <ContextMoreInfoMovementV2.Provider
      value={{
        ...hook,
        isForCancel,
        onCancelated
      }}
    >
      <div onClick={() => hook.handleDisplayModal(true)} ref={forwardRef}>
        {children}
      </div>

      <Dialog
        forwardRef={hook.dialog.refDialog}
        closeCallback={() => hook.handleDisplayModal(false)}
        width={75}
        title={hook.title}
      >
        {hook.isLoading ? (
          <Spinner hidden={false} text={"Cargando más información"} />
        ) : (
          <>
            <Incoming />
            <Egress/>
          </>
        )}
      </Dialog>
    </ContextMoreInfoMovementV2.Provider>
  );
}
