import React from "react";
import Table from "components/general/Table";

/**
 *
 * @param {import('react-data-table-component').TableProps<import('./types').DetailsMaterialI>} props
 * @returns
 */
function TableDetailsMaterial(props) {
  return <Table {...props} />;
}

/**
 * Render the details of the material requested for the position
 * @param {import("../../../../../../types/proyects/materials").DtoListMaterials} material - Information of the material
 * @returns {JSX.Element}
 */
export default function DetailsMaterial(material) {
  return (
    <TableDetailsMaterial
      sortServer={false}
      pagination={false}
      data={[material]}
      columns={[
        {
          name: "Unidad",
          selector: (item) => item.unit,
        },
        {
          name: "Recibido",
          selector: (item) => item.receivedMaterials,
        },
        {
          name: "Pendiente por recibir",
          selector: (item) => item.initialQuantity - item.receivedMaterials,
        },
        {
          name: "Por comprar",
          selector: (item) => item.residueQuantity,
        },
      ]}
    />
  );
}
