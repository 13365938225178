import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import Dialog from "structure/Dialog";
import Concept from "./Concept/index";
import useCreateCreditNotes, {
  CONTEXT_INITIAL_STATE,
} from "./useCreateCreditNotes";
import Importe from "./Import/index";
import AvailableInvoices from "./AvailableInvoices/index";
import { createContext } from "react";
import style from "./styles.module.scss";
import CreateButton from "./CreateButton";
import Total from "./Total/index";
import InvoiceOverview from "./InvoiceOverview";
import Icon from "components/individual/HeaderIcon";

export const ContextEmitCreditNote = createContext(CONTEXT_INITIAL_STATE);

/**
 * Render a button that open a dialog window to create a credit note
 * @param {import("./types").PropsEmitCreditNote} props - Props
 * @returns {JSX.Element}
 */
export default function EmitCreditNote({ onCreated = () => {} }) {
  const hook = useCreateCreditNotes(onCreated);

  return (
    <ContextEmitCreditNote.Provider value={hook}>
      <ButtonV2 onClick={hook.handleOpen}>
        <span>Agregar</span>
        <Icon icon="plus"/>
      </ButtonV2>

      <Dialog
        footer={<CreateButton />}
        width={30}
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.handleClose}
        title="Emitir nota de crédito"
      >
        {hook.isOpen ? (
          <div className={style.containerCreateCreditNote}>
            <AvailableInvoices />
            <InvoiceOverview />
            <Concept />
            <Importe />
            <Total />
          </div>
        ) : null}
      </Dialog>
    </ContextEmitCreditNote.Provider>
  );
}
