import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";

import { dateToDbFormat } from '../../../../helpers/dates';
import { GetParameters } from '../../../../helpers/Apis/Documents';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
function DocExpirationDate({
    id,
    forwardRef = {},
    name,
    label = 'Fecha',
    selected = null,
    onChange = () => { },
    onLoad = () => {},
    aditionalClasses = '',
}) {

    const [date, setDate] = useState(selected);
    registerLocale('es', es)


    useEffect(() => {
        async function loadDefaultValue() {
            const { parameters } = await GetParameters();

            const { value } = parameters.find(parameter => parameter.parameter === 1);

            let defaultDate = new Date();
            defaultDate.setDate(defaultDate.getDate() + (+value));

            setDate(defaultDate);
            onLoad(defaultDate);
        }

        if (selected === null) loadDefaultValue();

    }, []);

    useEffect(()=>{
        setDate(selected);
    },[selected])

    return (

        <div className={`${aditionalClasses} itemDocument`}>
            <label className="labelInput mt-4" htmlFor={`nameFake_${name}`}>
                {label}
            </label>

            <DatePicker
                id={`idFake_${id}`}
                name={`nameFake_${name}`}
                selected={date}
                dateFormat="dd/MMM/yyyy"
                locale="es"
                minDate={new Date()}
                onChange={(date) => {
                    setDate(date);
                    onChange(date)
                }}
            />

            <input
                type="hidden"
                ref={forwardRef}
                name={name}
                id={id}
                value={dateToDbFormat(new Date(date))}

            />
        </div>

    )
}

DocExpirationDate.propTypes = {

}

export default DocExpirationDate

