import React, { useEffect } from "react";
import { useState } from "react";
import scss from "./index.module.scss";
import Icon from "../HeaderIcon";

/**
 * Render a input search
 * @param {import("./types").PropsSearch} props - Props
 * @returns {JSX.Element}
 */
export const Search = (props) => {
  const [value, setValue] = useState(props?.value || "");

  useEffect(() => {
    setValue(props?.value || "");
  }, [props?.value]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event
   */
  const handleOnChange = (e) => {
    setValue(e.target.value);

    if (props.onChange) props.onChange(e.target.value);
  };

  const handleDelete = () => {
    setValue("");
    if (props.onDelete) props.onDelete("");
  };

  const handleSearch = () => {
    if (props.onSearch) props.onSearch(value);
  };

  return (
    <div className={scss.search}>
      <input
        type="text"
        {...props}
        onChange={(e) => handleOnChange(e)}
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.code === "Enter") handleSearch();
        }}
        value={props?.value || value}
      />
      {value.length === 0 ? null : (
        <button type="button" onClick={handleDelete} className={scss.clear}>
          <Icon icon="cancel" />
        </button>
      )}

      {props?.hideSearchIcon ? null : (
        <button type="button" onClick={handleSearch}>
          <Icon icon="search" />
        </button>
      )}
    </div>
  );
};
