import { RedirectButton } from "components/individual/buttons/Buttons";
import usePermissions from "customHooks/usePermissions";
import { CONTEXT_HOME } from "pages/Inicio/Inicio";
import React from "react";
import { useContext } from "react";

export default function ViewDocument() {
  const hook = useContext(CONTEXT_HOME);

  const { checkPermission } = usePermissions();

  if (!checkPermission("d9390bfd-4f3b-4f0b-a3dc-f8efcf058205")) return <></>;

  return (
    <RedirectButton
      id="visualizeQuote"
      text="Ver documento"
      link={`directorio/documentos/${hook.quote.customer.id}/ver/${hook.quote.idDocument}`}
    />
  );
}
