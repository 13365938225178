import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ui from "./styles.module.scss";
import FormPositionStructured from "structure/FormPositionStructured";
import uiForm from "structure/FormProyectsStructured/styles.module.scss";
import useFormProyect from "structure/FormProyect/useFormProyect";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function AddPosition() {
  const { id } = useParams();

  const hook = useFormProyect(+id);

  return (
    <div className={ui.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/proyectos",
            text: "Proyectos",
          },
          {
            route: "/proyectos",
            text: +id > 0 ? "Editar posición" : "Agregar Posición",
          },
        ]}
      />

      {hook.isLoadingData ? (
        <Spinner text={"Cargando proyecto"} hidden={false} />
      ) : (
        <div className={uiForm.formProyect}>
          <div className="row">
            <div className="col-sm-12">{hook.proyect.idClient}</div>
          </div>

          <FormPositionStructured
            idProyect={+id}
            idPosition={0}
            rfq={hook.proyect.noRFQ}
            solped={hook.proyect.solped}
            onCorrectSubmit={(data) => console.log(data)}
          />
        </div>
      )}
    </div>
  );
}
