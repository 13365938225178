import { Success } from "helpers/alerts";
import { useHistory } from "react-router-dom";
import { saveFastAdd } from "helpers/Apis/Banks";
import { useState } from "react";
import { dateToDbFormat } from "helpers/dates";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";
import { useRef } from "react";

const RESET_VALUES = {
  reference: "",
  concept: "",
  amount: 0,
};

/**
 *
 * @param {string|number} idBank - Id of the bank account
 */
export default function useMovement(idBank, typeMovement = 0) {
  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const referecenDom = useRef();

  /**
   * @type {[import("types/typedef/customHooks/useMovement").MovementI[],()=>void]}
   */
  const [movements, setMovements] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  // const addMovement = (data) => setMovements([data, ...movements]);

  const [form, setForm] = useState({
    date: new Date(),
    payMethod: "99",
    payMethodDescription: "Por definir",
    reference: "",
    concept: "",
    amount: 0,
  });

  const updateReference = (reference) => setForm({ ...form, reference });
  const updateConcept = (concept) => setForm({ ...form, concept });

  const updateAmount = (amount) => setForm({ ...form, amount });

  /**
   * Delete the movement from the list
   * @param {string} id - Id of the movement on memory to delete
   * @returns {object[]} List of movements with the item removed
   */
  const deleteMovement = (id) => {
    const updatedMovements = movements.filter((movement) => movement.id !== id);

    setMovements(updatedMovements);
    return updatedMovements;
  };

  const saveMovementOnDb = async () => {
    setIsSaving(true);

    const wasAdded = await saveFastAdd(movements);

    if (wasAdded) {
      Success(() => {}, `Movimiento(s) agregado(s)`);
      history.push(`/bancos/movimientos/${idBank}`);
    }

    setIsSaving(false);
    setMovements([]);
  };

  const addMovement = () => {
    if (form.amount <= 0 || form.date === null) return;

    referecenDom.current.focus();

    setMovements([
      {
        bankId: idBank,
        concept: form.concept === "" ? "No identificado" : form.concept,
        date: dateToDbFormat(form.date),
        descriptionPayMethod: form.payMethodDescription,
        id: window.crypto.randomUUID(),
        idPayMethod: form.payMethod,
        importConcept: form.amount,
        movementType: typeMovement,
        movementTypeNumber: typeMovement,
        payMethod: form.payMethod,
        reference: form.reference,
        registerDate: dateToDbFormat(form.date),
        userName: userInfo[0].fullName,
      },
      ...movements,
    ]);

    setForm({
      ...form,
      ...RESET_VALUES,
    });
  };

  const updateDateMovement = (date) => setForm({ ...form, date });

  /**
   * Update the pay method
   * @param {import("components/general/Forms/Selects/PayMethodsV2types").onChangePayMethod} payMethod - Pay method info
   * @returns {void}
   */
  const updatePaymethod = (payMethod) =>
    setForm({
      ...form,
      payMethod: payMethod.code,
      payMethodDescription: payMethod.description,
    });

  return {
    movements,
    addMovement,
    deleteMovement,
    saveMovementOnDb,
    isSaving,
    ...form,
    updateReference,
    updateConcept,
    updateAmount,
    referecenDom,
    updateDateMovement,
    updatePaymethod,
    idBankAccount: idBank,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/bancos",
        text: "Bancos",
      },
      {
        route: `/bancos/movimientos/${idBank}`,
        text: "Movimientos",
      },
      {
        route: "/",
        text: `${typeMovement === 1 ? "Ingreso" : "Egreso"}`,
      },
    ],
  };
}
