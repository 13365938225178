import { Spinner } from "components/individual/Spinner/Spinner";
import useConcilationCxC from "customHooks/useConcilationCxc";
import scss from "./styles.module.scss";
import React from "react";

/**
 * Render a table where the user can reverse the associations (cxc) made to an incoming
 * @param {import("./types").PropsDisassociateCxc} props - Props
 * @returns {JSX.Element} Table
 */
export const DisassociateCxc = ({
  idMovement = 177,
  onChange = () => {},
  bank,
}) => {
  const {
    concilations,
    isLoading,
    isCheckedItem,
    handleToggleSelect,
    handleMasterToggle,
    isParentCheckboxActive,
    bank: bankApi,
  } = useConcilationCxC(idMovement, onChange, bank);

  if (isLoading)
    return <Spinner hidden={false} text="Cargando conciliaciones..." />;

  return (
    <div>
      <div className={scss.header}>
        <span>
          <input
            type="checkbox"
            checked={isParentCheckboxActive}
            onClick={(e) => handleMasterToggle(e.target.checked)}
          />
        </span>
        <span>Cliente</span>
        <span>Factura</span>
        <span>Parcialidad</span>

        <span>Total</span>
        <span className={scss.headerAmount}>
          <span>Asociado</span>
        </span>
        <span className={scss.headerAmount}>
          <span>Importe origen</span>
        </span>
      </div>

      {concilations.map((cxc) => (
        <label className={scss.row} key={window.crypto.randomUUID()}>
          <span>
            <input
              checked={isCheckedItem(cxc.id)}
              type="checkbox"
              onClick={(e) => handleToggleSelect(cxc.id, e.target.checked)}
            />
          </span>
          <span>{cxc.invoice.socialReason}</span>
          <span>{cxc.invoice.folio}</span>
          <span>{cxc.cxc.partialitie.ui}</span>

          <span className="d-flex justify-content-end">{`${cxc.invoice.total.text} ${cxc.invoice.currency}`}</span>
          <span>{`${cxc.paid.text} ${cxc.invoice.currency}`}</span>
          <span className="d-flex justify-content-end">
            {cxc.applied.text} {bankApi.currency}
          </span>
        </label>
      ))}
    </div>
  );
};
