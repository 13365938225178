import axios from "axios";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "./fetch.js";
import { Error, Success } from "helpers/alerts.js";
import { getErrorData } from "helpers/errors.js";

export async function addOdc(dto) {
  try {
    await axios.post(`${URL_BASE}documentos/agregar/documento/odc`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function updateOdc(dto) {
  try {
    await axios.put(`${URL_BASE}documentos/actualizar/odc`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the odcs for administration
 * @param {import("types/typedef/odc").GetOdcsParamsI} queryParams - Params in order to search the odcs
 * @returns {Promise<import("../../../../server/types/Odc/index.d.ts").OcResults|Blob>} Information of the invoices got from the api
 */
export async function getOcsPaginated(queryParams) {
  const {
    customer,
    status,
    startDate,
    endDate,
    search,
    page,
    isExcelReport = false,
  } = queryParams;

  const customerQueryParam = customer === null ? "" : `&customerId=${customer}`;

  const statusQueryParam = status === null ? "" : `&statusId=${status}`;

  const searchQueryParam = search === null ? "" : `&search=${search}`;

  const queryUrl = isExcelReport ? `/excel` : "";

  try {
    /**
     * @type {import("axios").AxiosResponse<import("../../../../server/types/Odc/index.d.ts").OcResults|Blob>}
     */
    const response = await axios.get(
      `${URL_BASE}documentos/obtener/tabla/odc${queryUrl}?beginDate=${startDate}${customerQueryParam}${statusQueryParam}&endDate=${endDate}&pageRequested=${page}${searchQueryParam}`,
      {
        withCredentials: true,
        responseType: isExcelReport ? "blob" : "json",
      }
    );

    if (!isValidHttpResCode(response.status)) {
      Error(() => {}, response.data.message);
      return {
        documents: [],
        actualPage: 1,
        pages: 0,
      };
    }

    if (isExcelReport) return response.data;

    const documentsParsed = response.data.data.map((odc) => ({
      ...odc,
      sended: odc.envio.formated,
    }));

    return {
      documents: documentsParsed,
      actualPage: response.data.pagination.actualPage,
      pages: response.data.pagination.pages,
    };
  } catch (error) {
    /**
     * @type {import("axios").AxiosError}
     */
    let e = error;
    Error(() => {}, e.response.data.message);

    return {
      documents: [],
      actualPage: 1,
      pages: 0,
    };
  }
}

/**
 *
 * @param {import("../../../../types/Sells/sells.js").GenerateCxp} dto - Information in order to update an `OC`
 * @returns {Promise<boolean>}
 */
export async function updateGenerateCxp(dto) {
  try {
    const response = await fetch(`${URL_BASE}documentos/generar-cxp`, {
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dto }),
    });

    const data = await response.json();

    if (isValidHttpResCode(response.status) && response.ok) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    Error(() => {}, error.message);
    return false;
  }
}

/**
 * Get the list
 * @param {object[]} contracts - List of contracts got from the api or any source
 * @returns {object[]}
 */
export const parseOdcs = (odcs) =>
  odcs.map((odc) => ({
    ...odc,
    total: odc.total.texto,
    registro: odc.registro.formated,
    facturar: odc.facturar.formated,
  }));

/**
 * Get more information about the invoice reception
 * @param {number} idInvoiceReception - Id of the invoice reception
 * @returns {Promise<import("../../../../server/models/administration/types.js").LegalDocumentAssociationsOc>}
 */
export async function getInvoiceReceptionInfo(idInvoiceReception) {

  const ERROR = {
    associationsWithOc:[],
      associationsWithoutOc:[]
  }

  try {
    const response = await fetch(
      `${URL_BASE}administracion/recepcion-facturas/oc?idInvoiceReception=${idInvoiceReception}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (isValidHttpResCode(response.status) && response.ok) return data;

    return ERROR
  } catch (error) {
    return ERROR;
  }
}
