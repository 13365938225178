import { useState, useEffect } from "react";
import { ticketsCombo, assignUserTicket } from "helpers/Apis/tickets";
import useDialog from "customHooks/useDialog";
import LoginContext from "context/Login/LoginContext";
import { useContext } from "react";

/**
 * @type {import("./types").StateAssignTicket}
 */
const INITIAL_STATE = {
  isLoading: true,
  users: [],
  userselected: null,
  isAssigning: false,
  isOpen: false,
};

/**
 * Handle assign tickets
 * @param {number} idTicket - Id of the ticket
 * @param {()=>void} onAssigned - Callback executed when the ticket was assigned correctly
 * @param {number|null} assignedTo - Id of the user who has assigned the ticket
 * @returns
 */
export const useAsignTicket = (idTicket, onAssigned = () => {},assignedTo=null) => {
  const [combo, setCombo] = useState(INITIAL_STATE);
  const { userInfo } = useContext(LoginContext);


  const dialog = useDialog();

  const openModal = () => {
    dialog.open();
    setCombo((state) => ({
      ...state,
      isOpen: true,
    }));
  };

  const closeModal = () => {
    dialog.close();
    setCombo((state) => INITIAL_STATE);
  };

  useEffect(() => {
    (async function () {
      if (!combo.isOpen) return;

      const apiCombo = await ticketsCombo();
      setCombo((state) => ({ ...state, isLoading: false, users: apiCombo }));
    })();
  }, [combo.isOpen]);

  /**
   * Update the selected user to assign the ticket
   * @param {number} id - Id of the user
   * @returns {void}
   */
  const handleSelectUser = (id) =>
    setCombo((state) => ({
      ...state,
      userselected: id,
    }));

  const setAssigningTicket = (isAssigning) =>
    setCombo((state) => ({
      ...state,
      isAssigning,
    }));

  const attemptAsignTicket = async () => {
    setAssigningTicket(true);

    const wasAsigned = await assignUserTicket(idTicket, combo.userselected);

    setAssigningTicket(false);

    if (wasAsigned) {
      closeModal();
      onAssigned();
    }
  };

  return {
    combo,
    handleSelectUser,
    attemptAsignTicket,
    openModal,
    closeModal,
    isOpen: combo.isOpen,
    isSelectedUser: combo.userselected === null ? false : true,
    dialog,
    canAssignTicket:userInfo[0].ticketRol === "administrator" && assignedTo === null ? true : false
  };
};
