import styled from "styled-components";
import { Button } from "components/individual/ButtonsV2/Styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import ModalV2 from "components/individual/Modals/ModalV2";

export const CloudFile = styled.div`
  padding: 10px 0;
  svg {
    color: #39bee7;
  }
`;

export const AttachFile = styled.div`
  padding: 10px 0;
  svg {
    color: #707070;
  }
`;

export const HeaderEmail = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 45% 7% 7% 7%;
  /* align-items: end; */
`;

export const ButtonEmail = styled(Button)`
  svg {
    font-size: 1.45rem;
  }
  margin: 0 20px 0 0;
  span {
    display: none;
    margin: 0 0 0 5px;
  }
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease-in-out;
    span {
      display: inline-block;
    }
  }
`;

export const Modal = styled(ModalV2)`
   {
    background-color: red;
  }
`;
