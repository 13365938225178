import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import { YesNoAlert } from "helpers/alerts";
import { closeTicket } from "helpers/Apis/tickets";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import ui from "./styles.module.scss";

/**
 * Close a Ticket
 * @param {import("./types").propsCloseTicketsI} props
 * @returns {JSX.Element}
 */
export const CloseTicket = ({
  idTicket,
  isFinished = null,
  onClosedTicket = () => {},
  children = null,
  startedAt = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  useEffect(() => {
    if (isOpen) dialog.open();
  }, [isOpen]);

  const attemptStartTicket = async () => {
    setIsLoading(true);

    const wasClosed = await closeTicket(idTicket);

    if (wasClosed) {
      onClosedTicket();
    }

    setIsLoading(false);
  };

  const showModal = async () =>
    await YesNoAlert("¿Deseas cerrar el ticket?", attemptStartTicket);

  if (isFinished !== false || startedAt === null) return <></>;

  if (isLoading) return <Spinner hidden={false} text="Cerrando ticket" />;

  if (children === null)
    return (
      <Fragment>
        <ButtonV2 onClick={() => setIsOpen(true)}>
          <span>Cerrar</span>
        </ButtonV2>

        {isOpen ? (
          <Dialog
            width={30}
            forwardRef={dialog.refDialog}
            closeCallback={() => setIsOpen(false)}
            title="Cerrar ticket"
            footer={
              <div className={ui.options}>
                <ButtonV2 onClick={attemptStartTicket}>
                  <span>Si</span>
                </ButtonV2>

                <ButtonV2 theme="danger" onClick={() => setIsOpen(false)}>
                  <span>No</span>
                </ButtonV2>
              </div>
            }
          >
            <Fragment>
              <h2 className="text-center my-5">¿Deseas cerrar el ticket?</h2>
            </Fragment>
          </Dialog>
        ) : null}
      </Fragment>
    );

  return <div onClick={showModal}>{children}</div>;
};
