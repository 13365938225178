import React, { createContext, useContext, useRef } from "react";
import { Files, DropZone, FileList, Upload } from "structure/Files";
import ui from "./styles.module.scss";
import useBannerPraxia from "./useBannerPraxia";
import BannerPreview from "./BannerPreview";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import LoginContext from "context/Login/LoginContext";
import Icon from "components/individual/HeaderIcon";

export const ContextBannerPraxia = createContext(undefined);

export default function BannerPraxia() {
  const hook = useBannerPraxia();

  const session = useContext(LoginContext);

  const allowedExtensions = useRef(["png", "jpg", "jpeg", "webp"]);

  return (
    <div>
      <div className={ui.overview}>
        <b>Banner Praxia</b>
        <p>
          Imagen visible en la parte superior izquierda. Se recomienda una
          relación de aspecto 16:8
        </p>
      </div>

      <div className={ui.formBanner}>
        <div className={ui.inputForm}>
          <b>Modo claro</b>
          <Files
            maxFiles={1}
            allowedExtensions={allowedExtensions.current}
            files={hook.lightModeFile}
            onFilesSelected={(file) => {
              hook.fileFile(file, "lightModeFile");
            }}
          >
            <DropZone />
            <BannerPreview mode="light" custom={hook.lightModeFile[0]} />
            <FileList />

            <Upload
              entity="bannerClaro"
              idRegister={0}
              path="banner"
              onUploaded={() =>
                session.setBanner(hook.lightModeFile[0], hook.darkModeFile[0])
              }
            >
              <ButtonV2>
                <span>Subir</span>
                <Icon icon="save" />
              </ButtonV2>
            </Upload>
          </Files>
        </div>
        <div className={ui.inputForm}>
          <b>Modo oscuro</b>
          <Files
            maxFiles={1}
            allowedExtensions={allowedExtensions.current}
            files={hook.darkModeFile}
            onFilesSelected={(file) => hook.fileFile(file, "darkModeFile")}
          >
            <DropZone />
            <BannerPreview mode="dark" custom={session.banner.darkMode} />
            <FileList />

            <Upload
              entity="bannerOscuro"
              idRegister={0}
              path="banner"
              onUploaded={() =>
                session.setBanner(hook.lightModeFile[0], hook.darkModeFile[0])
              }
            >
              <ButtonV2>
                <span>Subir</span>
                <Icon icon="save" />
              </ButtonV2>
            </Upload>
          </Files>
        </div>
      </div>
    </div>
  );
}
