import React, { useContext } from "react";
import { ContextPaymentReminder } from "../..";
import Select from "react-select";
import { parseDateToText } from "helpers/dates";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function DateInput() {
  const hook = useContext(ContextPaymentReminder);

  if (hook.availableDates.length <= 0)
    return <Spinner text={"Cargando"} hidden={false} />;

  return (
    <div className={ui.datesCombo}>
      <b>Fecha</b>
      <Select
        isClearable={false}
        classNamePrefix="saiko-select"
        defaultValue={{
          label: parseDateToText(hook.availableDates[0]),
          value: hook.availableDates[0],
        }}
        className="saiko-select"
        onChange={(data) => hook.setDate(data.value)}
        placeholder="Selecciona una fecha"
        options={hook.availableDates.map((date) => ({
          label: parseDateToText(date),
          value: date,
        }))}
      />
    </div>
  );
}
