import {
  fetchDocItems,
  GetDocument,
  getDocumentComments,
} from "../helpers/Apis/Documents";
import useQueryParams from "./useQueryParams";

/**
 * Custom hook to visualize the documents information
 * @param {object} props - Props
 * @param {"odc"|"pedidos"|"contratos"} props.module - Module which came the user in order to see the document information
 */
export default function useViewDoc({ module = undefined }) {
  /**
   * Set the default tabs visualization when page loads
   *
   * @param {number} idTypeDoc - ID of the type document fetched
   */
  function showCorrespondingTab(idTypeDoc) {
    const indexedDocs = {
      1: "tab-quote",
      2: "tab-preinvoice",
      3: "tab-oc",
      6: "tab-contract",
      9: "tab-origin",
    };

    const indexedTab = {
      1: "tab-quoteDocument",
      2: "tab-preinvoiceDocument",
      3: "tab-ocDocument",
      6: "tab-contractDocument",
      9: "tab-originDocument",
    };

    const htmlElement = document.getElementById(indexedDocs[idTypeDoc]);
    if (htmlElement !== null) htmlElement.click();

    const tabElement = document.getElementById(indexedTab[idTypeDoc]);
    if (tabElement !== null) tabElement.click();
  }

  const { cuentaBanco } = useQueryParams();

  /**
   * Fetch the info of the document tab clicked
   *
   * @param {object} actualInfo - Current information loaded on component
   * @param {number} typeDocument - ID type document to request
   * @param {Function} setState - setState function of useState hook
   * @param {object} state - state value of useState hook
   */
  async function loadTabData(actualInfo, typeDocument, setState, state) {
    const isEmpty = actualInfo[typeDocument] === null ? true : false;

    if (!isEmpty) return;

    const referenceDocs = retrieveUnemptyDoc();

    const idDocument = searchIdDocument(referenceDocs);

    if (idDocument === null) return;

    const documentInfo = await Promise.all([
      fetchDocItems(idDocument),
      getDocumentComments(idDocument),
      GetDocument(idDocument),
    ]);

    // const infoDoc = await GetDocument(idDocument);

    setState({
      ...state,
      [typeDocument]: {
        items: documentInfo[0],
        comments: documentInfo[1],
        info: documentInfo[2],
      },
    });

    function retrieveUnemptyDoc() {
      const items = Object.keys(actualInfo);

      let referenceDoc;

      items.forEach((item) => {
        const isEmpty = actualInfo[item] === null ? true : false;

        if (!isEmpty) {
          referenceDoc = actualInfo[item];
        }
      });

      return referenceDoc.info;
    }

    /**
     * Checkout which "typeDocumentId" tab was pressed in order
     * to retrieve the id of the corresponding document
     *
     * @param {object} docs - Info of the document as reference
     * @returns {number} Id of the document to fetch
     */
    function searchIdDocument(docs) {
      const items = Object.keys(docs["documents"]);

      if (typeDocument === 6) {
        return docs["documents"]["contract"]["id"];
      }

      let idDocument = null;

      items.forEach((item) => {
        const idDocumentReference =
          docs["documents"][item]?.["typeDocument"]?.["id"];

        if (idDocumentReference === typeDocument) {
          idDocument = docs["documents"][item]["id"];
        }
      });

      return idDocument;
    }
  }

  function getBreadcrumPath() {
    /**
     * @type {{
     *  odc:import("components/individual/breadCrums/BreadCrumV2").Breadcrum[],
     *  pedidos:import("components/individual/breadCrums/BreadCrumV2").Breadcrum[],
     *   contratos:import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]
     * }}
     */
    const breadcrum = {
      odc: [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/administracion/ordenes-compra",
          text: "Administracion",
        },
        {
          route: "/administracion/ordenes-compra",
          text: "Ordenes de compra",
        },
      ],
      pedidos: [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/administracion/pedidos",
          text: "Administracion",
        },
        {
          route: "/administracion/pedidos",
          text: "Pedidos",
        },
      ],
      contratos: [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/ventas/contratos",
          text: "Ventas",
        },
        {
          route: "/ventas/contratos",
          text: "Contratos",
        },
      ],
      facturas_emitidas: [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/administracion/facturas-emitidas",
          text: "Administracion",
        },
        {
          route: "/administracion/facturas-emitidas",
          text: "Facturas emitidas",
        },
      ],
      movimientos: [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/bancos",
          text: "Bancos",
        },
        {
          route: `/bancos/movimientos/${cuentaBanco}`,
          text: "Movimientos",
        },
        {
          route: `/`,
          text: cuentaBanco,
        },
      ],
    };

    /**
     * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
     */
    const quoteBreadcrum = [
      {
        route:'/inicio',
        text:'Inicio'
      },
      {
        route:"/directorio",
        text:'Directorio'
      },
      {
        route:'/directorio/documentos',
        text:'Documentos'
      },
      {
        route:'/directorio/documentos',
        text:'Cotización'
      }
    ]

    return breadcrum[module]||quoteBreadcrum;
  }

  return {
    showCorrespondingTab,
    loadTabData,
    breadcrumPath: getBreadcrumPath(),
  };
}
