import { mnCurrency } from "helpers/money";
import React, { useContext } from "react";
import { EgressAssociationContext } from "..";

export default function InvoiceAmounts() {
  const {
    gridInvoice: { applied, total, residue },
  } = useContext(EgressAssociationContext);

  return (
    <>
      <p className="m-0 mr-4">
        <b>Importe total:</b> {total}
      </p>
      <p className="m-0 mr-4">
        <b>Aplicado:</b> {applied}
      </p>
      <p
        className={
          residue < 0 ? "text-danger font-weight-bold m-0 mr-4" : "m-0 mr-4"
        }
      >
        <b>Saldo:</b> {mnCurrency(residue)}
      </p>
    </>
  );
}
