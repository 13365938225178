import React from "react";
import { InputCurrency } from "components/individual/inputs/Inputs";
import { useContext } from "react";
import { ContextEgressConcept } from "..";
import NoIdentifiedError from "../NoIdentifiedError";
import AdvanceError from "../AdvanceError";
import Money from "components/general/Forms/Money";

export const NOT_ALLOWED_CONCEPTS = [30, 31, 32];

/**
 *
 * @param {import('./types').PropsInput} props - Props
 * @returns {JSX.Element} Input element to update the concept egress
 */
export default function Input({ egress }) {
  const hook = useContext(ContextEgressConcept);

  if (NOT_ALLOWED_CONCEPTS.includes(egress.id))
    return <p className="d-flex justify-content-center">-</p>;

  return (
    <div className="my-3">
      <Money
        maxDecimals={2}
        placeholder="Escribe aquí"
        onChange={(amount) =>
          hook.updateConceptAmount({
            ammount: amount,
            type: egress.type.id,
            id: egress.id,
          })
        }
      />
      {/* <InputCurrency
        onChange={(amount) =>
          hook.updateConceptAmount({
            ammount: amount,
            type: egress.type.id,
            id: egress.id,
          })
        }
        
        labelTxt=""
        placeholder="Escribe aquí"
      /> */}
      <NoIdentifiedError id={egress.id} />
      <AdvanceError id={egress.id} />
    </div>
  );
}
