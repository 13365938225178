import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { createContext } from "react";
import usePermissions from "customHooks/usePermissions";
import useTickets from "structure/Bug/Tickets/hooks/useTickets";
import DataTable from "react-data-table-component";
import SearchButton from "./components/SearchButton";
import ui from "./styles.module.scss";
import "./styles.css";
import Options from "./Options";
import { Spinner } from "components/individual/Spinner/Spinner";
import TypeTicket from "components/individual/TypeTicket";
import TicketStatus from "components/individual/TicketStatus";
import { getClickedColumn, getColorScheme } from "helpers/dom";
import MoreInfo from "./MoreInfo";

/**
 * @type {import("react").Context<import("structure/Bug/Tickets/hooks/types").ReturnUseTickets|undefined>}
 */
export const ContextTickets = createContext(undefined);

export default function TicketsApp({ renderBreadcrum = true }) {
  usePermissions("73b4be43-5a6a-4bf1-ba50-fe7f4677640b");

  const hook = useTickets();

  return (
    <ContextTickets.Provider value={hook}>
      {renderBreadcrum ? <BreadCrumV2 path={hook.breadcrum} /> : null}

      <MoreInfo />

      <div className={ui.module}>
        <div className={ui.filter}>
          <TicketStatus
            selected={hook.state.status}
            onChange={hook.changeStatusType}
          />

          <TypeTicket
            selected={hook.state.filter}
            onChange={(type) => hook.changeFilterType(type)}
          />
          <SearchButton />
        </div>

        <Options />

        <div>
          <DataTable
            selectableRowsHighlight
            pointerOnHover
            highlightOnHover
            responsive
            pagination
            onChangeRowsPerPage={(rowsPerPage) =>
              hook.setRowsPerPage(rowsPerPage)
            }
            progressPending={hook.state.isSearchingTickets}
            progressComponent={
              <Spinner text={"Buscando tickets"} hidden={false} />
            }
            selectableRowsSingle
            fixedHeader
            fixedHeaderScrollHeight="45vh"
            columns={hook.columns}
            data={hook.state.tickets}
            onChangePage={(page) => hook.fetchPage(page)}
            selectableRowSelected={(ticket) => ticket === hook.state.ticket}
            onRowClicked={(ticket, e) => {
              hook.selectTicket(ticket, getClickedColumn(e.target));
            }}
            paginationTotalRows={hook.state.pages * hook.state.rowsPerPage}
            paginationRowsPerPageOptions={[
              10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
            ]}
            theme={getColorScheme()}
            paginationServer
            paginationServerOptions={{
              persistSelectedOnPageChange: false,
              persistSelectedOnSort: false,
            }}
            paginationPerPage={hook.state.rowsPerPage}
            paginationComponentOptions={{
              rowsPerPageText: "Filas por página",
              rangeSeparatorText: "de",
            }}
          />
        </div>
      </div>
    </ContextTickets.Provider>
  );
}
