import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import { useParams } from "react-router-dom";

export const BreadcrumEgressAssociation = () => {
  const { account } = useParams();

  return (
    <BreadCrumV2
      path={[
        {
          route: `/inicio`,
          text: "Inicio",
        },

        {
          route: `/bancos`,
          text: "Bancos",
        },
        {
          route: `/bancos/movimientos/${account}`,
          text: "Movimientos",
        },
        {
          route: ``,
          text: "Asociar",
        },
      ]}
    />
  );
};
