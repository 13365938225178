import React from "react";
import style from "./styles.module.scss";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { ContextInvoiceReception } from "../..";
import { useContext } from "react";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import { useRef } from "react";
import { parseDateToText } from "helpers/dates";

/**
 * Render the item for invoice reception on mobile view
 * @param {import('types/typedef/customHooks/useInvoiceReception').InfoRowI} invoice - Information of the invoice receptionated
 * @returns {JSX.Element}
 */
export default function MobileInvoiceReception(invoice) {
  const hook = useContext(ContextInvoiceReception);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const domMoreInfo = useRef();

  return (
    <div className={style.mobileItem}>
      <ModalInvoiceReception uuid={invoice.uuid} forwardRef={domMoreInfo} />

      <div className="d-flex justify-content-between">
        <div>
          <Label>No. factura</Label>
          <span>{invoice.noDocument}</span>
        </div>

        <ElipsisMobile onShow={(e) => hook.setInfoRow(invoice)}>
          <CustomMenuItem onClick={() => domMoreInfo.current?.click()}>
            Más información
          </CustomMenuItem>
          <CustomMenuItem onClick={() => hook.redirectAssociatedFiles()}>
            Asociar archivos
          </CustomMenuItem>

          {hook.isCancellableInvoice && !hook.isForAccounting ? (
            <CustomMenuItem
              disabled={hook.isCancelling ? true : false}
              onClick={hook.promptCancelInvoiceReception}
            >
              {hook.isCancelling ? "Cancelando..." : "Cancelar"}
            </CustomMenuItem>
          ) : null}

          {typeof invoice.pdf === "number" ? (
            <CustomMenuItem onClick={hook.pdfBlob.attemptDownload}>
              PDF
            </CustomMenuItem>
          ) : null}
          {typeof invoice.xml === "number" && invoice.xml !== 0 ? (
            <CustomMenuItem onClick={hook.xmlBlob.attemptDownload}>
              XML
            </CustomMenuItem>
          ) : null}
        </ElipsisMobile>
      </div>

      <div className={style.dates}>
        <div>
          <Label>Emisión</Label>
          <span>{parseDateToText(new Date(invoice.emitedDate))}</span>
        </div>
        {hook.isForAccounting ? null : (
          <div>
            <Label>Expiración</Label>
            <span>{parseDateToText(new Date(invoice.expirationDate))}</span>
          </div>
        )}
      </div>

      <div>
        <Label>Razón social</Label>
        <span>{invoice.socialReason}</span>
      </div>

      <div className={style.amounts}>
        <div>
          <Label>Moneda</Label>
          <span className="text-right">{invoice.currency}</span>
        </div>

        <div>
          <Label>Total</Label>
          <span className="text-right">{invoice.total}</span>
        </div>

        {hook.isForAccounting ? null : (
          <div>
            <Label>Saldo</Label>
            <span className="text-right">{invoice.saldo}</span>
          </div>
        )}

        {hook.isForAccounting ? null : (
          <div>
            <Label>Cobrado</Label>
            <span className="text-right">{invoice.cobrado}</span>
          </div>
        )}

        {hook.isForAccounting ? null : (
          <div>
            <Label>Estatus</Label>
            <span className="text-center">{invoice.description}</span>
          </div>
        )}
      </div>

      {hook.isForAccounting ? (
        <div>
          <Label>Contabilizado</Label>
          <label className="d-flex-column justify-content-start align-items-start">
            <input
              type="checkbox"
              checked={hook.gridAccounting.operation[invoice.id]}
              onChange={(e) => hook.toggleAccounting(invoice.id)}
            />
          </label>
        </div>
      ) : null}
    </div>
  );
}
