import { CLABE } from "components/general/CLABE";
import { Label } from "structure/Document";
import React from "react";
import { useContext } from "react";
import { BankAccountContext } from "..";

export default function BankCLABE() {
  const { updateCLABE, id, account } = useContext(BankAccountContext);

  return (
    <div>
      <CLABE
        value={account?.CLABE}
        claveSat={account?.bank?.clave}
        onChange={(data) =>
          updateCLABE(data?.clabe, data?.ok, data?.code?.bank)
        }
      />
    </div>
  );
}
