import React from "react";

// COMPONENTS
import { CustomTable } from "../../../../components/general/Table/CustomTable";

function Quotes() {
  return (
    <div>
      <CustomTable paginationID="pagination-quotes" tableID="documents" />
    </div>
  );
}

Quotes.propTypes = {};

export default Quotes;
