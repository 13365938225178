import React, { useRef } from "react";
import { useContext } from "react";
import { ContextConsecutivePurchaseOrder } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import MoreInfo from "../MoreInfo";

export default function TableConsecutivePurchaseOrder() {
  const hook = useContext(ContextConsecutivePurchaseOrder);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingConsecutivePurchaseOrder"
        text={<LoadingText>Cargando reporte</LoadingText>}
      />
    );

  return (
    <div style={{overflow:"auto"}}>
      <div className={style.tableConsecutivePurchaseOrder}>
        <div className={style.header}>
          <p>OC</p>
          <p>Emisión</p>
          <p>Envío</p>
          <p>Estatus</p>
          <p>Total MXN</p>
          <p>Total USD</p>
          <p>Proveedor</p>
          <p>Cliente</p>
        </div>

        <div className={style.containerRows}>
          {hook.oc.map((item, j) => (
            <div key={`${key.current}-${j}`} className={style.row}>
              <MoreInfo idOc={item.id}>{`${item.id}`}</MoreInfo>

              <p className="text-left">
                {item.emitedDate !== null
                  ? parseDateToText(getDateFromUtc(item.emitedDate), "medium")
                  : ""}
              </p>
              <p className="text-left">
                {item.sendDate !== null
                  ? parseDateToText(getDateFromUtc(item.sendDate), "medium")
                  : ""}
              </p>
              <p className="text-left">{item.status}</p>
              <p className="text-right">
                {item.currency === "MXN" ? mnCurrency(item.total) : "-"}
              </p>
              <p className="text-right">
                {item.currency === "USD" ? mnCurrency(item.total) : "-"}
              </p>
              <p className="text-left">{item.providerSocialReason}</p>
              <p className="text-left">{item.clientSocialReason}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
