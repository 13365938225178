import React, { useEffect, useState } from "react";
import Provider from "components/general/Customer";
import { useContext } from "react";
import { ContextCreateEgress } from "..";
import { getCustomer } from "helpers/Apis/Directory";
import { Label } from "structure/Document";
import InformativeTooltip from "components/individual/InfoTooltip";

export default function ProviderCombo() {
  const hook = useContext(ContextCreateEgress);

  const [label, setLabel] = useState("");

  useEffect(() => {
    (async function () {
      if (!hook.canSelectProvider && typeof hook.provider === "number") {
        const provider = await getCustomer(hook.provider);
        setLabel(provider.data[0].socialReason);
      }
    })();
  }, [hook.canSelectProvider, hook.provider]);

  if (hook.beneficiary.length >= 1)
    return (
      <div>
        <Label>
          Proveedor{" "}
          <InformativeTooltip>
            <p>
              Para seleccionar un proveedor tienes que borrar los datos del
              campo <b>Beneficiario</b>
            </p>
          </InformativeTooltip>
        </Label>
        <p>NA</p>
      </div>
    );

  if (hook.idMovement !== null)
    return (
      <div>
        <Label>Proveedor</Label>
        <p className="m-0">{label}</p>
      </div>
    );

  return (
    <div>
      <Label>Proveedor</Label>
      <Provider
        type="proveedor"
        onChange={(provider, socialReason) => {
          hook.updateProvider(provider);
          hook.setProviderSocialReason(socialReason);
        }}
      />
    </div>
  );
}
