import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { PaymentTypeArea } from "./Styles";

export default function PaymentType({ value, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Forma de pago"
            value={!value ? document.payForm.description : value}
          />
        </div>
      ) : (
        <PaymentTypeArea>
          <Informative
            readonly={true}
            label="Forma de pago"
            value={!value ? document.payForm.description : value}
          />
        </PaymentTypeArea>
      )}
    </>
  );
}
