import EmailList from "components/individual/SendEmail/EmailList";
import { ContainerEmailList } from "components/individual/SendEmail/styles";
import React, { useContext } from "react";
import { ContextEmail } from "..";

/**
 * Render the email list
 * @param {import("./types").PropsEmailItem} props - Props
 * @returns {JSX.Element}
 */
export const EmailItem = ({ type = "to" }) => {
  const { to, cc, deleteCc, deleteTo } = useContext(ContextEmail);

  const listToUse = type === "to" ? to : cc;
  const functionToUse = type === "to" ? deleteTo : deleteCc;

  return (
    <ContainerEmailList>
      <EmailList list={listToUse} onClickDelete={functionToUse} />
    </ContainerEmailList>
  );
};
