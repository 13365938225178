import ToggleComponent from "components/individual/inputs/Toggle";
import React from "react";
import { useContext } from "react";
import { IvasContext } from "..";

export const Excento = () => {
  const { excento, updateExcento, iva, canUseExempt } = useContext(IvasContext);

  if (iva !== 0) return <></>;

  if (!canUseExempt) return <p>No</p>;

  return (
    <ToggleComponent
      labelActive="Si"
      labelInactive="No"
      isChecked={excento}
      onChange={updateExcento}
    />
  );
};
