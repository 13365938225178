import React, { useEffect, useState } from "react";

import { Button, ModalDismissButton } from "../buttons/Buttons";
import { Spinner } from "../Spinner/Spinner";
import { v4 as uuid } from "uuid";
import "./modal.scss";
import ButtonV2 from "../ButtonsV2/Button";

/**
 * Modal
 * @param {object} props - Props
 * @param {number?} props.width - Width of the modal when it renders
 * @param {string?} props.idOpenModal - Id the button to open the modal
 * @param {string?} props.idCloseIcon - Id of the button to close the modal
 * @param {JSX.Element} props.children - Elements to render inside the modal
 * @param {string?} props.title - Title of the modal
 * @param {string} props.close - Text to show on the button to close the modal
 * @param {string?} props.accept - Text of the button to accept
 * @param {string|JSX.Element|JSX.Element[]} [props.buttonText=""] - Text to display to open the modal
 * @param {()=>void} props.cbReject - Callback to execute when modal is closed
 * @param {string?} props.idCloseModal - Id of the button to close the modal
 * @param {string?} props.idAccetModal - Id of the button for the accept button
 * @param {string?} props.aditionalClasses - Class name for the component
 * @param {()=>void} props.cbOpen - Callback to execute when the modal is open
 * @param {JSX.Element} props.footer - Component to render on the footer of the modal
 * @param {boolean} props.showCancelButton - If false, cancel button will no be display
 * @returns {JSX.Element}
 */
function ModalV2({
  idOpenModal = "openModal",
  idCloseIcon = uuid(),
  children,
  width = null,
  title = "[Insert a title here]",
  close = "Cancelar",
  accpet = null,
  buttonText = "Abrir modal",
  cbAccept = () => {},
  cbReject = () => {},
  idCloseModal = "closeModal",
  idAccetModal = "acceptModal",
  idFooter = "footerModal",
  showSpinner = false,
  textSpinner = "Cargando...",
  aditionalClasses = "d-none",
  subtitle = "",
  cbOpen = () => {},
  footer,
  showCancelButton = true,
}) {
  /**
   * True if the modal must be show on the screen
   * @type {[boolean,Function]}
   */
  const [show, setShow] = useState(false);
  const [stateShowSpinner, stateSetShowSpiner] = useState(showSpinner);

  useEffect(() => {
    stateSetShowSpiner(showSpinner);
  }, [showSpinner]);

  const acceptModal = async () => {
    await cbAccept();
    setShow(false);
  };

  const closeModal = async () => {
    await cbReject();
    setShow(false);
  };

  const openModal = () => {
    cbOpen();
    setShow(true);
  };

  return (
    <>
      {/* <Button
        id={idOpenModal}
        key={idOpenModal}
        text={buttonText}
        funcion={openModal}
        aditionalClasses={aditionalClasses}
      /> */}

      <ButtonV2
        className={aditionalClasses}
        onClick={openModal}
        key={idOpenModal}
        id={idOpenModal}
      >
        {buttonText}
      </ButtonV2>

      {show === true ? (
        <>
          <div
            className="modal fade show d-block  "
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex={-1}
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            role="dialog"
          >
            <div
              className="modal-dialog modal-lg  modal-dialog-scrollable  modal-dialog-centered"
              role="document"
            >
              <div
                className="modal-content "
                style={{
                  width: width === null ? "100%" : `${width}vw`,
                }}
              >
                <div className="modal-header">
                  <h5
                    className="modal-title font-weight-bold"
                    id="staticBackdropLabel"
                  >
                    {title}
                  </h5>
                  <h5 className="my-0">{subtitle}</h5>
                  {stateShowSpinner === true ? null : (
                    <>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id={idCloseIcon}
                        onClick={closeModal}
                      >
                        <span onClick={closeModal} aria-hidden="true">
                          &times;
                        </span>
                      </button>
                    </>
                  )}
                </div>
                <div className="modal-body p-0 m-2">{children}</div>

                {footer === null ? null : (
                  <div className="modal-footer" id={idFooter}>
                    {stateShowSpinner === true ? (
                      <Spinner
                        hidden={false}
                        idSpinner="loading"
                        text={textSpinner}
                      />
                    ) : (
                      <>
                        {showCancelButton ? (
                          <div className="form-group">
                            <ModalDismissButton
                              idBtn={idCloseModal}
                              text={close}
                              key={idCloseModal}
                              callback={closeModal}
                            />
                          </div>
                        ) : null}

                        {footer}

                        {accpet === null ? null : (
                          <Button
                            id={idAccetModal}
                            key={idAccetModal}
                            text={accpet}
                            funcion={acceptModal}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
    </>
  );
}

ModalV2.propTypes = {};

export default ModalV2;
