import Select from "components/individual/SelectV4";
import React from "react";
import { Label } from "structure/Document";
import { ConcilationContext } from "..";
import { useContext } from "react";

export default function StatusFilter() {
  const hook = useContext(ConcilationContext);

  return (
    <div>
      <Label>Conciliacion</Label>
      <Select
        labelToDisplay="status"
        defaultSelected={{
          value:hook.status,
          keyToCompare:'id'
        }}
        options={async () => [
          {
            id: null,
            status: "Todos",
          },
          {
            id: 2,
            status: "No conciliados",
          },

          {
            id: 3,
            status: "Conciliados",
          },
        ]}
        onChange={(option) => hook.setStatus(option.id)}
      />
    </div>
  );
}
