import axios from "axios";
import { URL_BASE, ULR_MIZAR } from "routes/routes";
import { Success, Error } from "helpers/alerts";
import { arrayToQueryParam, isValidHttpResCode } from "./fetch";
import { getDateFromUtc, parseDateToText } from "helpers/dates";

/**
 * Add a new contract into system
 * @param {import("customHooks/useContract/types").DtoContract} dto - Information in order to add a contract
 * @returns {Promise<boolean>} True if contract was added
 */
export async function addContract(dto) {
  try {
    await axios.post(`${URL_BASE}documentos/agregar/documento/contrato`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Add a new contract into system
 * @param {import("customHooks/useContract/types").DtoContract} dto - Information in order to add a contract
 * @returns {Promise<boolean>} True if contract was added
 */
export async function updateContract(dto) {
  try {
    await axios.put(`${URL_BASE}documentos/actualizar/contrato`, dto, {
      withCredentials: true,
    });
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the contracts added into the system
 * @param {import("types/typedef/contract").GetContractParamsI} queryParams - Query params in order to get the contracts paginated
 * @returns {Promise<import("types/typedef/contract").ContractsI>} List of contracts founded
 */
export async function getContractsPaginated(queryParams) {
  const { customer, status, startDate, endDate, search, page, executive, uen } =
    queryParams;

  const customerQueryParam = customer === null ? "" : `&customerId=${customer}`;

  const executiveQueryParam =
    executive === null ? "" : `&executiveId=${executive}`;

  const statusQueryParam = status === null ? "" : `&statusId=${status}`;

  const searchQueryParam = search === null ? "" : `&search=${search}`;

  const uenQueryParam = uen === null ? "" : `&idUEN=${uen}`;

  try {
    /**
     * @type {import("axios").AxiosResponse<import("types/typedef/contract").ContractResponse>}
     */
    const { data } = await axios.get(
      `${URL_BASE}documentos/obtener/tabla/contratos?beginDate=${startDate}${customerQueryParam}${statusQueryParam}${uenQueryParam}${executiveQueryParam}&endDate=${endDate}&pageRequested=${page}${searchQueryParam}`,
      {
        withCredentials: true,
      }
    );

    return {
      documents: data.data.map((contract) => ({
        ...contract,
        parsedReminder: parseDateToText(getDateFromUtc(contract.reminder)),
      })),
      actualPage: data.pagination.actualPage,
      pages: data.pagination.pages,
    };
  } catch (error) {
    return {
      documents: [],
      actualPage: 0,
      pages: 0,
    };
  }
}

/**
 * Get the contracts added into the system
 * @param {string} from - From date
 * @param {string} to - To date
 * @returns {Promise<Blob>} List of contracts founded
 */
export async function downloadExcelContracts(from, to) {
  const queryParam = arrayToQueryParam([`beginDate=${from}`, `endDate=${to}`]);

  try {
    const res = await axios.get(
      `${URL_BASE}documentos/obtener/tabla/contratos/excel${queryParam}`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );

    return res.data;
  } catch (error) {
    return null;
  }
}

/**
 * Get the list
 * @param {object[]} contracts - List of contracts got from the api or any source
 * @returns {object[]}
 */
export const parseContractsPagination = (contracts) =>
  contracts.map((contract) => ({
    ...contract,
    total: contract.total.texto,
    registro: contract.registro.formated,
    facturar: contract.facturar?.formated,
  }));
