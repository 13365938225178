import { useState, useEffect } from "react";
import {
  addConcilation,
  getBankAccount,
  GetConsolation,
  getMovement,
} from "helpers/Apis/Banks";
import { getCxcs } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { mnCurrency } from "helpers/money";
import { useHistory } from "react-router-dom";
import { Success } from "helpers/alerts";

export default function useStep2({
  customerId = null,
  bankAccountId = null,
  idMovement = null,
  currencyAccount = null,
}) {
  const history = useHistory();

  /**
   * State of the component
   * @type {[import("./types").Step2,()=>void]}
   */
  const [state, setState] = useState({
    cxc: [],
    isLoading: true,
    isAssociating: false,
    gridInfo: null,
    bank: null,
    movement: null,
    associations: [],
  });

  useEffect(() => {
    (async function () {
      if (customerId === null || idMovement === null) return;

      let bank = null;
      if (bankAccountId !== null) {
        bank = await getBankAccount(bankAccountId);
      }

      let currencyToUse =
        bankAccountId === null ? currencyAccount : bank.currencyCode;
      let idBankCurrency = currencyToUse === "USD" ? 2 : 1;

      const [apiConsolation, movement, pollo] = await Promise.all([
        getCxcs(customerId),
        getMovement(idMovement),
        GetConsolation(customerId, idBankCurrency, currencyToUse),
      ]);

      const cxcParsed = apiConsolation.map((cxc, i) => ({
        ...cxc,
        invoice: {
          ...pollo[i].invoice,
          id: cxc.idOfTheInvoice,
          folio: cxc.invoice.folio,
        },
      }));

      setState({
        ...state,
        cxc: cxcParsed,
        isLoading: false,
        movement,
        bank,
      });
    })();
  }, [customerId, bankAccountId, idMovement, currencyAccount]);

  useEffect(() => {
    if (state.gridInfo === null) return;

    const cxcDto = state.gridInfo.filteredGrid.array.map((cxc, i) => ({
      ...state.cxc.find((element) => element.id === cxc.id),
      id: cxc.id,
      importeAplicado: cxc.import.number,
      importe: {
        number: cxc.total.number,
      },
      saldoActual: {
        number: cxc.residue.number,
      },
      saldoNuevo: {
        number: cxc.newResidue.number,
      },
      aplicacion: cxc.applied.number,
      documentNumber: cxc.invoice.folio,
      acumulated: {
        number: cxc.total.number - cxc.residue.number,
        text: cxc.total.number - cxc.residue.text,
      },
      total: {
        number: cxc.total.number,
        text: cxc.total.text,
      },
      currency: cxc.currency,
      TC: cxc.tc.number,
      isValidAplicacion: true,
      tc: {
        number: cxc.tc.number,
        text: cxc.tc.text,
      },
      applied: {
        number: cxc.applied.number,
        text: cxc.applied.text,
      },
    }));

    setState({
      ...state,
      associations: cxcDto,
    });
  }, [state.gridInfo]);

  const updateGridInfo = (gridInfo) =>
    setState({
      ...state,
      gridInfo,
    });

  const applied =
    state.gridInfo === null
      ? "$0.0"
      : mnCurrency(state.gridInfo?.totalApplied || 0);

  const residue =
    state.gridInfo === null
      ? mnCurrency(state.movement?.saldo || 0)
      : mnCurrency(state.gridInfo?.newResidue || 0);

  const attemptCreate = async () => {
    setState({
      ...state,
      isAssociating: true,
    });

    const dtoAddCxc = {
      cxc: state.associations,
      executiveName: "Prueba",
      idMovement,
      idClient: customerId,
      residue: state.gridInfo.newResidue,
      initialResidue: state.movement.saldo,
    };

    const wasAdded = await addConcilation(dtoAddCxc);

    setState({
      ...state,
      isAssociating: false,
    });

    if (wasAdded) {
      Success(() => {}, "Asociacion exitosa");
      history.push(`/bancos/movimientos/${bankAccountId}`);
    }

    // alert(wasAdded);
  };

  return {
    residue,
    applied,
    updateGridInfo,
    state,
    attemptCreate,
  };
}
