import { InputCurrency } from "components/individual/inputs/Inputs";
import React from "react";
import { Label } from "structure/Document";
import { useContext } from "react";
import { BankAccountContext } from "..";

export default function BankAmount() {
  const { updateAmount, id, register, errors, account } =
    useContext(BankAccountContext);

  return (
    <div>
      <Label>Saldo al cierre</Label>
      {id === null ? (
        <>
          <InputCurrency
            forwardRef={register}
            name="lastCloseBalanceConcilation"
            placeholder="Escribe aquí"
            onChange={updateAmount}
          />
          <p className="text-danger">
            {errors?.lastCloseBalanceConcilation?.message}
          </p>
        </>
      ) : (
        <>
          <p>
            {new Intl.NumberFormat("es-MX", {
              currency: "MXN",
              style: "currency",
            }).format(account.closingBalance)}
          </p>
        </>
      )}
    </div>
  );
}
