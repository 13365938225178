import React from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext } from "react";
import { parseDateToText } from "helpers/dates";

export default function ODC() {
  const hook = useContext(ContextDocsRelated);

  const oc = hook.popUpInfo[3];

  if (oc === undefined || oc === null)
    return <EmptyCardMoreInfo label="orden de compra" />;

  return (
    <CardDocRelated
      id={oc.id}
      folio={oc.docNumber}
      endDate={parseDateToText(new Date(`${oc.expiration.yyyymmdd}:`))}
      customer={oc.customer.socialReason}
      startDate={parseDateToText(new Date(`${oc.startDate.yyyymmdd}:`))}
      email={oc.customer.email}
      phone={oc.customer.phone}
      importe={oc.amount.subtotal.text}
      iva={oc.amount.iva.text}
      total={oc.amount.total.text}
      currency={oc.currency.code}
      label="oc"
      concept={oc.concept?.description}
    />
  );
}
