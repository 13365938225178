import React from "react";
import ViewDocument from "./ViewDocument";
import { useContext } from "react";
import { CONTEXT_HOME } from "../Inicio";
import style from "./styles.module.scss";
import Win from "./Win";
import AssociateFiles from "./AssociateFiles";
import Todo from "./ToDo";
import CancelInvoice from "structure/CancelInvoice";
import Edit from "./Edit";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import SendEmail from "./SendEmail";
import { ModalDocsRelated } from "components/general/Documents/ModalDocsRelated";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function Options() {
  const hook = useContext(CONTEXT_HOME);

  if (hook.quote === null || hook.quote === undefined)
    return <div className={style.options}></div>;

  return (
    <div className={style.options}>
      <ViewDocument />
      <Win />
      <AssociateFiles />
      <Todo />

      <EmailDocumentsV2 idDocument={hook.quote.idDocument}>
        <ButtonV2>
          Enviar correo
        </ButtonV2>
      </EmailDocumentsV2>

      <DonwloadPdfV2
        id={+hook.quote.idDocument}
        fileName={`Cotizacion-${hook.quote.documentNumber}`}
      />

      <Edit />

      <CancelInvoice
        id={+hook.quote.idDocument}
        status={1}
        onCancelated={hook.refetchCurrentPage}
      />

      {/* <ModalDocsRelated idDocument={+hook.quote.idDocument} fromLegal={false} /> */}
    </div>
  );
}
