import React, { useContext } from "react";
import { ContextRemitions } from "..";
import ui from "./styles.module.scss";
import RedirectBtn from "components/individual/RedirectBtn";
import { DonwloadPdfV2 } from "components/general/Documents/DownloadPdf/DonwloadPdfV2";
import { parseToFolio } from "helpers/documents";
import ModalCreateInvoiceV2 from "structure/ModalCreateInvoiceV2";
import { CancelInvoiceButton } from "components/general/Documents/Buttons/CancelInvoiceButton";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { CancelPreInvoice } from "components/general/CancelPreInvoice";
import { Button } from "components/individual/buttons/Buttons";
import Icon from "components/individual/HeaderIcon";

export default function OptionsRemition() {
  const hook = useContext(ContextRemitions);

  if (hook.selectedRemition === undefined)
    return <div className={ui.containerOptions}></div>;

  return (
    <div className={ui.containerOptions}>
      <RedirectBtn
        link={`/directorio/documentos/${hook.selectedRemition.idCustomer}/ver/${hook.selectedRemition.idDocument}?modulo=pedidos`}
      >
        <span>Ver remisión</span>
      </RedirectBtn>

      <RedirectBtn
        link={`/proyectos/remision/archivos-asociados/${hook.selectedRemition.idDocument}`}
      >
        <span>Asociar archivos</span>
      </RedirectBtn>

      <ButtonV2 onClick={hook.handleDownloadPdf} disabled={hook.statusPdf==='loading'}>
        <span>PDF</span>
        <Icon icon="pdf"/>
      </ButtonV2>

      {/* <DonwloadPdfV2
        id={hook.selectedRemition.idDocument}
        fileName={`Remision-${parseToFolio(
          hook.selectedRemition.documentNumber
        )}`}
      /> */}

      <ModalCreateInvoiceV2
        idOrder={hook.selectedRemition.idDocument}
        idStatus={hook.selectedRemition.idStatus}
        evaluatePermission={false}
        onCreated={hook.handleRemitionCreatedOnSat}
      />

      {hook.selectedRemition.idStatus === 5 ? (
        <CancelInvoiceButton
          uuid={hook.selectedRemition?.uuid || null}
          onCancelled={hook.refetchTable}
        >
          <ButtonV2>
            <span>Cancelar factura</span>
          </ButtonV2>
        </CancelInvoiceButton>
      ) : null}

      {hook.selectedRemition.idStatus === 4 && (
        <CancelPreInvoice
          idPreinvoice={hook.selectedRemition.idDocument}
          onCancelled={hook.refetchTable}
          labelBtn="Cancelar remisión"
        />
      )}
    </div>
  );
}
