import React, { useContext } from "react";
import { Label } from "structure/Document";
import { Search as SearchInput } from "components/individual/Search";
import { ContextEmitedCreditNotes } from "..";

export default function Search() {
  const hook = useContext(ContextEmitedCreditNotes);

  return (
    <>
      <div>
        <Label>Búsqueda</Label>
        <SearchInput
          placeholder="Por folio, uuid, razon social, factura"
          onChange={hook.updateSearch}
          hideSearchIcon={true}
          onSearch={hook.attemptSearch}
          onDelete={(e) => hook.updateSearch("")}
        />
      </div>

    </>
  );
}
