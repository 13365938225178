import React, { createContext, useContext, useEffect, useState } from "react";
import { StateExchange } from "types/typedef/customHooks/useExchangeInformative";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetTCV2 } from "helpers/Apis/Banks";

const ContextExchange = createContext();
const { Provider } = ContextExchange;

export const Bank = () => {
  const { sales } = useContext(ContextExchange);

  return (
    <span>
      <b>Banco: </b> {sales.text}
    </span>
  );
};

export const Dof = () => {
  const { dof } = useContext(ContextExchange);

  return (
    <span>
      <b>Diario oficial: </b> {dof.text}
    </span>
  );
};

/**
 * Render the date of the TC when it's outdate
 * @param {object} props - Component props
 * @param {boolean?} props.renderDate - True if the date must be rendered in case it's outdated
 * @returns {JSX.Element}
 */
export const Date = ({ renderDate }) => {
  const {
    renderDate: renderDateContext,
    isOfToday,
    date,
  } = useContext(ContextExchange);

  const mustBeRendered =
    renderDate === undefined ? renderDateContext : renderDate;

  return (
    <>
      {!isOfToday && mustBeRendered ? (
        <span>
          <b>Fecha TC: </b>
          {`${date.formated}`}
        </span>
      ) : null}
    </>
  );
};

/**
 * Render the TC of the enterprise
 * @param {object} props - Props of component
 * @param {string} props.enterpriseName - Name of the enterprise to use on span
 * @returns {JSX.Element} React component
 */
export const Enterprise = ({ enterpriseName }) => {
  const { enterprise, enterpriseName: envEnterpriseName } =
    useContext(ContextExchange);

  return (
    <span>
      <b>
        {enterpriseName === undefined ? envEnterpriseName : enterpriseName}:{" "}
      </b>{" "}
      {enterprise.text}
    </span>
  );
};

/**
 *
 * @param {object} props - Props component
 * @param {JSX.Element} props.children - Components to render inside component
 * @param {boolean} props.renderDate - If true, will render the date if it's outdate the TC
 * @returns {JSX.Element} React components
 * @example
  <ExchangeInformative>
    <ExchangeInformative.Enterprise /> | <ExchangeInformative.Dof /> | <ExchangeInformative.Bank /> | <ExchangeInformative.Date />
  </ExchangeInformative>
 */
export function ExchangeInformative({ children, renderDate = true }) {
  /**
   * State of the TC used on the enterprise
   * @type {[StateExchange,()=>StateExchange]}
   */
  const [exchange, setExchange] = useState({
    isLoadingTc: true,
    enterpriseName: process.env.REACT_APP_ENTERPRISE_NAME,
  });

  useEffect(() => {
    (async function () {
      const lastTcOnSystem = await GetTCV2();

      setExchange({
        ...exchange,
        exchange: lastTcOnSystem[0],
        isLoadingTc: false,
      });
    })();
  }, []);

  return (
    <>
      {exchange.isLoadingTc ? (
        <Spinner text="Cargando tc..." hidden={false} idSpinner="spinnerTc" />
      ) : (
        <Provider
          value={{
            date: exchange.exchange.date,
            dof: exchange.exchange.dof,
            enterprise: exchange.exchange.enterprise,
            fix: exchange.exchange.fix,
            pays: exchange.exchange.pays,
            purchase: exchange.exchange.purchase,
            sales: exchange.exchange.sales,
            isOfToday: exchange.exchange.isOfToday,
            enterpriseName: exchange.enterpriseName,
            renderDate,
          }}
        >
          {children}
        </Provider>
      )}
    </>
  );
}

ExchangeInformative.Dof = Dof;
ExchangeInformative.Bank = Bank;
ExchangeInformative.Enterprise = Enterprise;
ExchangeInformative.Date = Date;
