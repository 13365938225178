import { DateInput } from "components/general/Forms/Dates";
import { Spinner } from "components/individual/Spinner/Spinner";
import { getLastCloseMonth } from "helpers/Apis/Banks";
import { dateWithCeroHours } from "helpers/dates";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";

import { Label } from "structure/Document";
import { AddMovementContext } from "../../AddMovementsV2";

export const DateMovement = () => {
  const { date, updateDateMovement, idBankAccount } =
    useContext(AddMovementContext);

  const [minDate, setMinDate] = useState({
    isLoading: true,
    date: null,
  });

  useEffect(() => {
    (async function () {
      const date = await getLastCloseMonth(idBankAccount);

      const firstDayOfNextMonth = new Date(date.year, date.month.number, 1);

      setMinDate({
        isLoading: false,
        date: firstDayOfNextMonth,
      });

      updateDateMovement(new Date());
    })();
  }, []);

  if (minDate.isLoading) return <Spinner hidden={false} text="Cargando..." />;

  return (
    <div>
      <Label required={date === null}>Fecha</Label>
      <DateInput
        value={date}
        minDate={minDate.date}
        onChange={(date) => updateDateMovement(dateWithCeroHours(date))}
        clearIcon={false}
        locale="es-MX"
      />
    </div>
  );
};
