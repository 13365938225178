import styled from "styled-components";

export const ValidExtension = styled.p`
  margin: 1rem 0 !important;
  color: var(--red);
  font-weight: bold;
  span {
    display:inline-block !important; 
    font-weight: normal;
    color: var(--primaryColor);
    &:after {
      content: " , ";
    }
  }
  span:first-child {
    margin: 0 0 0 1rem;
  }

  span:last-child {
    &:after {
      content: "";
    }
  }
`;
