import React, { useRef } from "react";
import ui from "./styles.module.scss";
import FormPersonalData, {
  Name,
  Email,
  Birthday,
} from "structure/FormPersonalData";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { profileUpdateSchema } from "Schemas/Contact/contact";

export default function Test() {
  return (
    <FormPersonalData
      onSuccessSubmit={(data) => console.log(data)}
      resolver={profileUpdateSchema}
    >
      <Name name="firstName" maxLength={30} required />
      <Name
        name="middleName"
        maxLength={30}
        required
        placeholder="Segundo nombre"
      />
      <Name
        name="lastName1"
        maxLength={30}
        required
        placeholder="Apellido paterno"
      />
      <Name
        name="lastName2"
        maxLength={30}
        required
        placeholder="Apellido materno"
      />
      <Email name="email" maxLength={50} required />

      <Birthday name="birthday" required />

      <ButtonV2>
        <span>Guardar</span>
        <Icon icon="save" />
      </ButtonV2>
    </FormPersonalData>
  );
}
