import React from "react";
import OtpForm from "../OtpForm";
import { useState } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { loginWith2fa } from "helpers/Apis/usuariosApi";
import { Success } from "helpers/alerts";
import { LOG_USER } from "types/index";
const INITIAL_STATE = {
  otp: "",
  isValidating: false,
};

export default function CardOtp() {
  const [state, setState] = useState(INITIAL_STATE);

  const { changeView, user, dispatch } = useContext(LoginContext);

  const setOtp = (otp) =>
    setState((current) => ({
      ...current,
      otp,
    }));

  const attemptLogin2fa = async () => {
    setState((current) => ({
      ...current,
      isValidating: true,
    }));

    const responseApi = await loginWith2fa(state.otp, user);

    if (responseApi !== undefined) {
      dispatch({
        type: LOG_USER,
        payload: responseApi,
      });
    }

    setState((current) => ({
      ...current,
      isValidating: false,
    }));
  };

  return (
    <div className="loginForm">
      <form action="" className="px-5 otpContainer">
        {/* <div>
          <p onClick={() => changeView("login")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            Regresar
          </p>
        </div> */}

        <p>Esta cuenta tiene 2FA</p>
        <OtpForm onChange={setOtp} />
        {state.isValidating ? (
          <Spinner
            hidden={false}
            text={<LoadingText>Validando OTP</LoadingText>}
          />
        ) : (
          <ButtonV2
            onClick={attemptLogin2fa}
            disabled={state.otp.length <= 5}
            type="button"
          >
            Enviar código
          </ButtonV2>
        )}

        <span className="hyperlink" onClick={() => changeView("login")}>Usar otra cuenta / Regresar</span>
      </form>
    </div>
  );
}
