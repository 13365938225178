import ButtonV2 from "components/individual/ButtonsV2/Button";

import { mnCurrency } from "helpers/money";
import React, { useState } from "react";
import { Label } from "structure/Document";
import scss from "./styles.module.scss";

export const CardMovementV2 = ({
  date = "1900-01-01",
  reference = "Sin referencia",
  concept = "No identificado",
  amount,
  payMethod,
  onClickCross = () => {},
}) => {
  const [dateValues, setDateValues] = useState(date.split("-"));

  return (
    <div className={scss.card}>
      <div className={scss.infoCard}>
        <div>
          <Label>Fecha</Label>
          <span>
            {new Intl.DateTimeFormat("es-MX", {
              dateStyle: "medium",
            }).format(
              new Date(dateValues[0], dateValues[1] - 1, dateValues[2])
            )}
          </span>
        </div>

        <div>
          <Label>Referencia</Label>
          <span>{reference}</span>
        </div>

        <div>
          <Label>Concepto</Label>
          <span>{concept}</span>
        </div>

        <div>
          <Label>Importe</Label>
          <span>{mnCurrency(amount)}</span>
        </div>

        <div>
          <Label>Método de pago</Label>
          <span>{payMethod}</span>
        </div>
      </div>
      <ButtonV2 className={scss.delete} onClick={onClickCross}>
        ✖
      </ButtonV2>
    </div>
  );
};
