import React, { createContext, useContext, useEffect } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useEditDocument from "customHooks/useEditDocument";

import Id from "./Id";
import Type from "./Type";
import No from "./No";
import Status from "./Status";
import Executive from "./Executive";
import Client from "./Client";
//------------------------------------------------
import Origin from "./Origin";
import Quote from "./Quote";
import PreInvoice from "./PreInvoice";
import Invoice from "./Invoice";
import Contrato from "./Contrato";
import OC from "./OC";
import InvoiceProvider from "./InvoiceProvider";
//---->
import NoContrato from "./NoContrato";
import CreationDate from "./CreationDate";
import ModifyDate from "./ModifyDate";
import ExpirationDate from "./ExpireDate";
import ReminderDate from "./ReminderDate";
import PercentProgress from "./PercentProgress";
import PercentProb from "./PercentProb";
//-----------------------------------------------
import Currency from "./Currency";
import TCP from "./TCP";
import Import from "./Import";
import IVA from "./IVA";
import Total from "./Total";
import Acredited from "./Acredited";
import Recidue from "./Recidue";

import CFDI from "./CFDI";
import PaymentType from "./PaymentType";
import PaymentMethod from "./PaymentMethod";
import Partialities from "./Partialities";
import Partiality from "./Partiality";
import CreditDays from "./CreditDays";
import Complement from "./Complement";
import CFDIEditableComp from "components/general/Forms/Selects/CFDI";

//-----------------------------------------------
import Contact from "./Contact";
import { CFDIArea } from "./Styles";

import EditContract from "./EditNoContract";
import EditDates from "./EditDates";
import EditCreditDays from "./EditCreditDays";

export const FormContext = createContext();
export const { Provider } = FormContext;

export const CFDIEditable = () => {
  const { register, document, errors } = useContext(FormContext);

  return (
    <CFDIArea>
      <CFDIEditableComp
        forwardRef={register}
        css="w-100"
        errors={errors}
        value={document.cfdi.id}
      />
    </CFDIArea>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {({
 *  editedData:any,
 *  document:DocumentI
 * })=>void} props.onSubmit - Callback executed once the submit button is clicked
 * @param {object} props.schema - Yup schema validation
 * @param {(object:import("types/typedef/customHooks/useEditDocument").EditDocChildrenI)=>FC[]} props.children - FC components to render
 * @returns
 */
export default function Form({
  children,
  onSubmit = () => {},
  onLoaded = () => {},
  schema = {},
  idForm = "updateDocument",
}) {
  const {
    document,
    contact,
    setDocument,
    setExpirationDate,
    setReminderDate,
    setContract,
    setCreditDays
  } = useEditDocument();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    onLoaded(document);
  }, [document]);

  return (
    <>
      {document === null ? (
        <Spinner hidden={false} text="Cargando documento..." />
      ) : (
        <Provider
          value={{
            document,
            contact,
            setDocument,
            register,
            errors,
            setExpirationDate,
            setContract,
            setReminderDate,
            setCreditDays
          }}
        >
          <form
            id={idForm}
            className="documentSize"
            onSubmit={handleSubmit(
              (data) =>
                onSubmit({
                  editedData: data,
                  document,
                }),
              (error) => console.log(errors)
            )}
          >
            {children({ document, register, errors, setDocument })}
          </form>
        </Provider>
      )}
    </>
  );
}

Form.Id = Id;
Form.Type = Type;
Form.No = No;
Form.Status = Status;
Form.Executive = Executive;
Form.Client = Client;
//-------------------------
Form.Origin = Origin;
Form.Quote = Quote;
Form.PreInvoice = PreInvoice;
Form.Invoice = Invoice;
Form.Contrato = Contrato;
Form.OC = OC;
Form.InvoiceProvider = InvoiceProvider;
//
Form.NoContrato = NoContrato;
Form.CreationDate = CreationDate;
Form.ModifyDate = ModifyDate;
Form.ExpirationDate = ExpirationDate;
Form.ReminderDate = ReminderDate;
Form.PercentProgress = PercentProgress;
Form.PercentProb = PercentProb;
//-------------------------------
Form.Currency = Currency;
Form.TCP = TCP;
Form.Import = Import;
Form.IVA = IVA;
Form.Total = Total;
Form.Acredited = Acredited;
Form.Recidue = Recidue;
//

Form.CFDI = CFDI;
Form.CFDIEditable = CFDIEditable;
Form.PaymentType = PaymentType;
Form.PaymentMethod = PaymentMethod;
Form.Partialities = Partialities;
Form.Partiality = Partiality;
Form.CreditDays = CreditDays;
Form.Complement = Complement;

Form.Contact = Contact;
Form.Contract = EditContract;
Form.EditContract = EditContract;
Form.EditDates = EditDates;
Form.EditCreditDays = EditCreditDays;
