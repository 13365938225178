import {
  InputCurrency,
  InputNumber,
} from "components/individual/inputs/Inputs";
import React, { useContext, useEffect, useState } from "react";
import { GridImportsContext } from ".";

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const IdRegister = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {amountsInfo[index].id}
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const Currency = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {amountsInfo[index].currency}
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const DocNumber = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {amountsInfo[index].noDocument}
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const Total = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {amountsInfo[index].total.text}
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const Saldo = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <p
        className={`mb-0 ${
          amountsInfo[index].newResidue.number < 0 ? "text-danger" : ""
        }`}
      >
        {amountsInfo[index].newResidue.text}
      </p>
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const Import = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {amountsInfo[index].import.text}
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @param {boolean} props.showIsUsedAllResidue - If true, will show the error that the element havent been aplied 100% of import
 * @returns {JSX.Element} React component
 */
export const NewResidue = ({ index, showIsUsedAllResidue = true }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <p
        className={`mb-0 ${
          amountsInfo[index].newResidue.number < 0 ? "text-danger" : ""
        }`}
      >
        {amountsInfo[index].newResidue.text}
      </p>

      {/* {!amountsInfo[index].isValidResidueOdc && showIsUsedAllResidue ? (
        <p className="text-center text-danger">Residuo invalido</p>
      ) : null} */}

      {!amountsInfo[index].isValidResidueOdc ? (
        <p className="text-center text-danger">Residuo invalido</p>
      ) : null}
    </div>
  );
};
export const AppliedImport = ({ index }) => {
  const { amountsInfo } = useContext(GridImportsContext);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <p
        className={`mb-0 ${
          amountsInfo[index].applied.number < 0 ? "text-danger" : ""
        }`}
      >
        {amountsInfo[index].applied.text}
      </p>
    </div>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const TC = ({ index }) => {
  const { amountsInfo, predominantCurrency } = useContext(GridImportsContext);

  return (
    <>
      {predominantCurrency === amountsInfo[index].currency ? (
        <div className="d-flex justify-content-center align-items-center">
          $1.0
        </div>
      ) : (
        <>
          {amountsInfo[index].applied.number <= 0 ? (
            <p className="text-center d-flex align-items-center justify-content-center">
              -
            </p>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              {amountsInfo[index].tc.text}
            </div>
          )}
        </>
      )}
    </>
  );
};

/**
 *
 * @param {object} props - Props
 * @param {number} props.index - Index to render on the array of amounts
 * @returns {JSX.Element}
 */
export const Applied = ({ index }) => {
  const { amountsInfo, predominantCurrency, setApplied } =
    useContext(GridImportsContext);

  return (
    <>
      {predominantCurrency === amountsInfo[index].currency ? (
        <div className="d-flex justify-content-center align-items-center">
          {amountsInfo[index].applied.text}
        </div>
      ) : (
        <>
          {amountsInfo[index].import.number <= 0 ? (
            <p className="text-center d-flex align-items-center justify-content-center">
              -
            </p>
          ) : (
            <InputCurrency
              id={`aplicado-${index}`}
              labelTxt={""}
              aditionalClasses="d-flex align-items-center justify-content-center"
              placeholder="Escribe aqui"
              onChange={(value) => setApplied(index, value)}
            />
          )}
        </>
      )}
    </>
  );
};

export const InputImport = ({ index, label = "Importe" }) => {
  const { setImport } = useContext(GridImportsContext);

  useEffect(() => {}, []);

  return (
    <div className="d-flex flex-column justify-content-center mx-2">
      <InputCurrency
        id={`importe-${index}`}
        labelTxt={label}
        placeholder="Escribe aqui"
        defaultValue={0}
        onChange={(importe) => {
          setImport(index, importe);
        }}
      />
    </div>
  );
};
export const InputTC = ({ index, label = "TC", bankCode }) => {
  const { setTC, amountsInfo } = useContext(GridImportsContext);

  return (
    <>
      {!amountsInfo[index].import.number ? (
        <p className="text-center d-flex align-items-center justify-content-center">
          -
        </p>
      ) : amountsInfo[index].currency === bankCode ? (
        <p className="text-center d-flex align-items-center justify-content-center">
          $1.00
        </p>
      ) : (
        <div className="d-flex flex-column justify-content-center mx-2">
          <InputCurrency
            showError={amountsInfo[index].tc.number === 0}
            id={`tc-${index}`}
            labelTxt={label}
            placeholder="Escribe aqui"
            defaultValue={0}
            onChange={(tc) => setTC(index, tc)}
          />
        </div>
      )}
    </>
  );
};
