import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";
import { DocRelatedCard } from "components/individual/Cards/DocRelatedCard";
import { Spinner } from "components/individual/Spinner/Spinner";
import usePopupDocsRelated from "customHooks/usePopupDocsRelated";
import React, { useEffect, useRef } from "react";

/**
 * Display text content with the information of the related documents
 * @param {import("./types").PropsDocsRelatedI} props - Props
 * @returns {JSX.Element} Information of the related documents
 */
export default function DocsRelated({
  idDocument,
  onChange = () => {},
  fromLegal,
}) {
  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const dom = useRef();

  useEffect(() => {
    dom.current.click();
  }, []);

  return (
    <ModalDocsRelatedV2
      fromLegal={false}
      idDocument={idDocument}
      forwardRef={dom}
    />
  );

  // const {
  //   popUpInfo: { client, provider, isLoading },
  // } = usePopupDocsRelated(idDocument, fromLegal);

  // useEffect(() => {
  //   if (isLoading) return;

  //   onChange(client, provider);
  // }, [isLoading]);

  // return (
  //   <>
  //     {isLoading ? (
  //       <Spinner hidden={false} text="Cargando documentos..." />
  //     ) : (
  //       <>
  //         {!!client || !!provider ? (
  //           <>
  //             <DocRelatedCard infoRelated={client} type={"Cliente"} />
  //             <DocRelatedCard infoRelated={provider} type={"Proveedor"} />
  //           </>
  //         ) : (
  //           <DocRelatedCard infoRelated={provider} type={"Proveedor"} />
  //         )}
  //       </>
  //     )}
  //   </>
  // );
}
