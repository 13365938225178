import React from "react";

export const GenericRadioButtonList = ({
  attributeID,
  attributeValue,
  forwardRef,
  headers = [],
  name,
  onChange = () => {},
  options = [],
}) => {
  return (
    <div
      className="container p-0 mt-3"
      style={{
        maxHeight: "45vh",
        overflow: "auto",
      }}
    >
      <div
        className="row my-1 mx-0 px-0 py-2 w-100"
        style={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 10,
        }}
      >
        <div className="col-1"></div>
        {headers.map((header) => (
          <div key={window.crypto.randomUUID()} className="col-1">
            <b>{header}</b>
          </div>
        ))}
      </div>
      {options.map((option) => (
        <div
          key={`${name}-${option[attributeID]}`}
          className="row my-1 mx-0 p-0 w-100"
        >
          <div className="col-1 align-self-start">
            <input
              id={`${name}-${option[attributeID]}`}
              className="mt-2"
              key={option[attributeID]}
              name={name}
              onChange={(value) => onChange(value)}
              ref={forwardRef}
              type="radio"
              value={option[attributeID]}
            />
          </div>
          <div className="col align-self-center">{option[attributeValue]}</div>
        </div>
      ))}
    </div>
  );
};
