import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";

import React, { useState } from "react";
import { substractDays } from "helpers/dates";

export default function DatePartialitie({
  firstPartiality = () => new Date(),
  maxDate = new Date(),
  beginDate = new Date(),
  disableDatesRestriction = false,
}) {
  const [date, setDate] = useState(new Date(disableDatesRestriction ? '2024-01-01': beginDate));

  registerLocale("es", es);

  const updateDate = (date) => {
    setDate(new Date(date));
    firstPartiality(date);
  };

  return (
    <div >
      <label>Fecha vencimiento</label>
      <DatePicker
        id="firstPartialitie"
        withPortal={true}
        name="firstPartialitie"
        selected={date}
        locale="es"
        minDate={disableDatesRestriction ? null : substractDays(new Date(),1)}
        maxDate={disableDatesRestriction ? null : maxDate}
        dateFormat="dd/MMM/yyyy"
        onChange={updateDate}
      />
    </div>
  );
}
