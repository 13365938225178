import React, { useContext } from "react";
import { ContextDocuments } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import EditDocumentButton from "components/individual/buttons/EditButtonDocument";

export default function EditDocument() {
  const hook = useContext(ContextDocuments);

  if (!hook.isEditableDocument) return <></>;
  return (
    <EditDocumentButton
      goEdit={hook.editDocument}
      idDocument={hook.documentSelected.idDocument}
    />
    // <ButtonV2 onClick={hook.editDocument}>
    //   <span>Editar123</span>
    // </ButtonV2>
  );
}
