import { mnCurrency } from "helpers/money";
import { initialState } from "../Hooks/winReducer";
import { URL_BASE } from "routes/routes";
import axios from "axios";

/**
 * Format the Contact DB info into the Contact react component
 * @param {import("../../../../../../types/winQuote").ContacsFromDbI[]} contactInfo
 * @return {import("../../../../../../types/winQuote").ContactI[]}
 */
export const formatedContact = (contactInfo) => {
  if (!!contactInfo) {
    /**
     * @type{import("../../../../../../types/winQuote").ContactI}
     */
    const contactsFormatted = contactInfo.map((contact) => ({
      label: contact.fullName,
      id: contact.contactID,
      anniversary: contact.anniversary,
      cell: {
        extention: contact.cellCode,
        numberPhone: contact.cellNumber,
      },
      email: contact.email,
      isCollectionContact: contact.isCollectionContact,
      isPaymentContact: contact.isPaymentContact,
      lastName1: contact.lastName1,
      lastName2: contact.lastName2,
      middleName: contact.middleName,
      name: contact.firstName,
      phone: {
        extention: contact.phoneCode,
        numberPhone: contact.phoneNumber,
      },
      workTitle: contact.workTitle,
    }));
    return contactsFormatted;
  } else {
    return [];
  }
};

/**
 * Format the document data to win data
 * @param {import("../../../../../../types/documentActions").GeneralDocumentI} document
 * @return{import("../../../../../../types/winQuote").WinQuoteStateI}
 */
export const formatDocumentData = (document) => {
  // console.log('Esta vacio el documento',!!document)

  // let formatedDocument=initialState;
  if (!!document) {
    /**
     * @type{import("../../../../../../types/winQuote").WinQuoteStateI}
     */
    const documentFormated = {
      ...initialState,
      items: document.items,
      idContractParent: document.idContractParent,
      moneyInfo: {
        mensaje: "Hola mundo",

        ...document.moneyInfo,
      },
      tc: {
        number: document.moneyInfo.tc,
        text: mnCurrency(document.moneyInfo.tc),
      },
      catalogue: [],
      creditDays: null,
      customer: {
        client: {
          id: document.customer?.id,
          isActive: true,
          rfc: document.customer?.rfc,
          socialRazon: document.customer?.socialReason,
        },
        provider: null,
      },
      documents: {
        quote: {
          comments: !!document.comments ? document.comments : [],
          documentItems: document.items,
          currentComment: null,
          contact: document.contact,
          dates: {
            createdDate: document.generateContract.beginDate,
            expirationDate: document.generateContract.endDate,
            reminderDate: document.generateContract.reminderDate,
          },
        },
        contract: {
          // comments: !!document.comments ? document.comments : [],
          comments: [],
          documentItems: document.items,
          currentComment: null,
          contact: document.contact,
          dates: {
            endDate: new Date(),
            initialDate: new Date(),
            reminderDate: new Date(),
          },
        },
        odc: {
          // comments: !!document.comments ? document.comments : [],
          comments: [],
          documentItems: document.items,
          currentComment: null,
          contact: initialState.documents.odc.contact,
          dates: {
            createdDate: new Date(),
            reminderDate: new Date(),
            sendDate: new Date(),
          },
        },
        order: {
          // comments: !!document.comments ? document.comments : [],
          comments: [],
          documentItems: document.items,
          currentComment: null,
          contact: document.contact,
          dates: {
            createdDate: new Date(),
            invoiceDate: new Date(),
            reminderDate: new Date(),
          },
        },
      },
      interfaceControl: {
        ...initialState.interfaceControl,
        needContract: !!document.idContractParent ? true : false,
      },
      footer: {
        currency: {
          code: document.moneyInfo.currency.value,
          id: document.moneyInfo.currency.id,
        },
        tc: document.moneyInfo.tc,
        sell:
          document.moneyInfo.currency.value === "MXN"
            ? document.moneyInfo.mxn.importSell
            : document.moneyInfo.usd.importSell,
        pursharse:
          document.moneyInfo.currency.value === "MXN"
            ? document.moneyInfo.mxn.importCost
            : document.moneyInfo.usd.importCost,
        margin:
          document.moneyInfo.currency.value === "MXN"
            ? document.moneyInfo.mxn.margin
            : document.moneyInfo.usd.margin,
      },
    };

    return documentFormated;
  } else {
    return initialState;
  }
};

/**
 * @param {number} importe
 * @return {number}
 */
export const calculateMxnToUsd = (importe) => {
  if (document.moneyInfo.tc.number !== undefined) {
    return importe / document.moneyInfo.tc.number;
  }
};

/**
 * @param {number} importe
 * @return {number}
 */
export const calculateUsdToMxn = (importe) => {
  if (document.moneyInfo.tc.number !== undefined) {
    return importe * document.moneyInfo.tc.number;
  }
};

/**
 * @param {import("../../../../../../types/documentActions").ItemSelectI}
 * @returns {string[]}
 */
export const validateData = (currentItem) => {
  const currency = currentItem?.currency?.code;

  const puMxn = currentItem?.mxn?.pu?.total.number;
  const puUSD = currentItem?.usd?.pu?.total.number;

  const cuMxn = currentItem?.mxn?.cu?.total.number;
  const cuUSD = currentItem?.usd?.cu?.total.number;

  let messageError = [];
  if (!currentItem.sku) {
    messageError.push("Falta la clave");
  }
  if (!currentItem.description) {
    messageError.push("Falta la descripcion");
  }
  if (!currentItem.currency) {
    messageError.push("Falta la moneda");
  }
  if (!currentItem.uen) {
    messageError.push("Falta la UEN");
  }
  if (!currentItem.cu?.number) {
    messageError.push("Falta costo unitario");
  }
  if (!currentItem.pu?.number) {
    messageError.push("Falta precio unitario");
  }
  if (!currentItem.quantity) {
    messageError.push("Falta cantidad");
  }

  if (currency === "MXN") {
    if (puMxn - cuMxn <= 0) {
      messageError.push(
        `El margen no es correcto ${mnCurrency(puMxn - cuMxn)}`
      );
    }
  } else {
    if (puUSD - cuUSD <= 0) {
      messageError.push(
        `El margen no es correcto ${mnCurrency(puUSD - cuUSD)}`
      );
    }
  }
  return messageError;
};

/**
 * Validates if the product in the catalog was edited the price, cost and / or IVA
 * @param {import("../../../../../../types/documentActions").ItemSelectI} item
 */
export const validateUpdateItem = (item) => {
  if (!!item) {
    if (
      item.catalogue?.cu !== item.cu.number ||
      item.catalogue?.pu !== item.pu.number ||
      item.catalogue?.iva !== item.iva.number
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const winDocument = async (dto) => {
  try {
    const documentAdded = await axios.post(`${URL_BASE}documentos/ganar`, dto, {
      withCredentials: true,
    });

    return documentAdded;
  } catch (error) {
    Error(() => {}, "No se pudo ganar el documento");
    return false;
  }
};

/**
 *Returns the sum of all the subtotals
 * @params {ItemSelectI} items
 * @returns {{
 * totalPuMxn: number,totalPuUsd: number,totalCuMxn: number,totalCuUsd:number
 * }}
 */
export const ImportDocument = (items) => {
  const totalPuMxn = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.mxn.pu.total.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });

  const totalPuUsd = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.usd.pu.total.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  const totalCuMxn = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.mxn.cu.total.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  const totalCuUsd = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.usd.cu.total.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  return {
    totalPuMxn,
    totalPuUsd,
    totalCuMxn,
    totalCuUsd,
  };
};

/**
 *Return the sum of all the ivas
 * @returns {{ivaPuMxn:number;ivaPuUsd:number;ivaCuMxn:number;ivaCuUsd:number;}}
 * @params {ItemsSelectedI}
 */
export const IvaDocument = (items) => {
  const ivaPuMxn = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.mxn.pu.totalIva.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  const ivaPuUsd = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.usd.pu.totalIva.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  const ivaCuMxn = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.mxn.cu.totalIva.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });
  const ivaCuUsd = items
    ?.map(
      /**
       * @param {import("../../../../../../types/documentActions").ItemSelectI} item
       */
      (item) => item.usd.cu.totalIva.number
    )
    .reduce((acumulator, item) => {
      return acumulator + item;
    });

  return {
    ivaPuMxn,
    ivaPuUsd,
    ivaCuMxn,
    ivaCuUsd,
  };
};

/**
 * Return de margin of the document
 * @returns {{marignMXN:number;marginUSD:number;}}
 */
export const MarginDocument = () => {
  const { totalCuMxn, totalCuUsd, totalPuMxn, totalPuUsd } = ImportDocument();

  const marignMXN = totalPuMxn - totalCuMxn;
  const marginUSD = totalPuUsd - totalCuUsd;
  return {
    marignMXN,
    marginUSD,
  };
};

/**
 * Returns the total of the document (import + iva)
 * @returns {{TotalCuMXN:number;TotalPuMXN:number;TotalCuUSD:number;TotalPuUSD:number;}}
 */
export const TotalDocument = (items) => {
  const { totalCuMxn, totalCuUsd, totalPuMxn, totalPuUsd } =
    ImportDocument(items);
  const { ivaCuMxn, ivaCuUsd, ivaPuMxn, ivaPuUsd } = IvaDocument(items);

  const TotalCuMXN = totalCuMxn + ivaCuMxn;
  const TotalPuMXN = totalPuMxn + ivaPuMxn;
  const TotalCuUSD = totalCuUsd + ivaCuUsd;
  const TotalPuUSD = totalPuUsd + ivaPuUsd;
  console.log("Revision de los totales");
  console.log(ivaCuMxn);
  console.log(ivaCuUsd);
  console.log(ivaPuMxn);
  console.log(ivaPuUsd);

  return {
    TotalCuMXN,
    TotalPuMXN,
    TotalCuUSD,
    TotalPuUSD,
  };
};

/**
 *
 * @param {boolean} isForeignSupplier
 * @param {number} cost
 * @returns {{number:number;text:string}}
 */
export function costTouse(isForeignSupplier, cost) {
  const number = isForeignSupplier ? 0 : cost;
  return {
    number: number,
    text: mnCurrency(number),
  };
}
/**
 *
 * @param {number} amount
 * @returns {{number:number;text:string}}
 */
export function amountsInFormat(amount) {
  return {
    number: amount,
    text: mnCurrency(amount),
  };
}

/**
 *
 * @param {number} importCost Importe del costo
 * @param {number} importSell Importe del precio
 * @param {number} ivaCost IVA del costo
 * @param {number} ivaSell IVA del precio
 * @param {number} totalCost Total de costo
 * @param {number} totalSell Total de precio
 * @param {number} margin Margen
 * @param {boolean} isForeignSupplier Indica si es extranjero el proveedor
 * @returns {import("../../../../../../types/documentActions").currencyObjectI}
 */
export function createCurrencyObject(
  importCost,
  importSell,
  ivaCost,
  ivaSell,
  totalCost,
  totalSell,
  margin,
  isForeignSupplier = false
) {
  return {
    importCost: amountsInFormat(importCost),
    importSell: amountsInFormat(importSell),
    ivaCost: costTouse(isForeignSupplier, ivaCost),
    ivaSell: amountsInFormat(ivaSell),
    margin: amountsInFormat(margin),
    totalCost: isForeignSupplier
      ? amountsInFormat(totalCost)
      : amountsInFormat(totalCost),
    totalSell: amountsInFormat(totalSell),
  };
}

/**
 *
 * @param {*} item
 * @param {'MXN'|'USD'} currency
 * @param {number} documentType
 * @returns
 */
const getNumbers = (item, currency, documentType) => {
  const isDocumentTypeThree = documentType === 3;
  const isCurrencyMXN =
    item.catalogue.currency.code === "MXN" || currency === "MXN";
  const currencyType = isCurrencyMXN ? "mxn" : "usd";
  const priceType = isDocumentTypeThree ? "cu" : "pu";
  const discountType = isDocumentTypeThree
    ? "providerDiscount"
    : "clientDiscount";

  return {
    subTotal: item[currencyType]?.[priceType]?.total?.text,
    unitary: item[currencyType]?.[priceType]?.totalUnit?.text,
    discount: item[discountType]?.number,
    iva: item[currencyType]?.[priceType]?.totalIva?.text,
    ivaPercentage: item.iva?.number,
    realUnitary: item[currencyType]?.[priceType]?.text,
  };
};

/**
 *
 * @param {import("../../../../../../types/documentActions").MoneyInformationI} moneyInfo
 * @param {boolean} isForeignSupplier Indica si es proveedor extranjero
 * @param {import("../../../../../../types/documentActions").ItemSelectI[]} items
 * @returns {import("../../../../../../types/documentActions").MoneyInformationI} moneyInfo
 */
export function moneyInfoForOdc(moneyInfo, isForeignSupplier, items) {
  if (isForeignSupplier) {
    return {
      ...moneyInfo,
      mxn: {
        ...moneyInfo.mxn,
        totalCost: moneyInfo.mxn.importCost,
      },
      usd: {
        ...moneyInfo.usd,
        totalCost: moneyInfo.usd.importCost,
      },
    };
  }

  return { ...moneyInfo, currency: retrieveCurrencyType() };

  function retrieveCurrencyType() {
    if (isForeignSupplier) {
      return { id: 2, value: "USD" };
    }

    const currencyCount = items.reduce(
      (acc, item) => {
        // Si la moneda ya existe en el acumulador, incrementa el contador
        if (acc[item.catalogue.currency.code]) {
          acc[item.catalogue.currency.code]++;
        } else {
          // Si no existe, inicializa el contador en 1
          acc[item.catalogue.currency.code] = 1;
        }
        return acc;
      },
      {
        MXN: 0,
        USD: 0,
      }
    );

    if (currencyCount["MXN"] >= 1 && currencyCount["USD"] >= 1) {
      return { id: 5, value: "***" };
    }
    if (currencyCount["MXN"] >= 1) {
      return { id: 1, value: "MXN" };
    }
    return { id: 2, value: "USD" };
  }
}
