import { useContext, useEffect, useState } from "react";
import { Step1Context } from "pages/Banks/Movements/CXP-Consilations/Step1/Step1";
import { getCxps } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { addConcilationCxp, getMovement } from "helpers/Apis/Banks";
import { useParams, useHistory } from "react-router-dom";
import LoginContext from "context/Login/LoginContext";
import { Success } from "helpers/alerts";

/**
 * State of the component
 * @type {import("types/typedef/customHooks/useCxpStep2").StateI}
 */
const initialState = {
  cxps: [],
  isLoading: true,
  gridInfo: null,
  movementInfo: null,
  applied: 0,
  isAssociating: false,
};

export default function useStep2() {
  const { infoRow, setStep,bankAccount } = useContext(Step1Context);

  const history = useHistory();

  const { userInfo } = useContext(LoginContext);

  const { movement, account } = useParams();

  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      const [apiCxps, movementInfo] = await Promise.all([
        getCxps(infoRow.id),
        getMovement(+movement),
      ]);

      console.log(apiCxps);

      setState({
        ...state,
        isLoading: false,
        cxps: apiCxps,
        movementInfo,
        bankCurrency:bankAccount.currencyCode
      });
    })();
  }, []);

  const setGridInfo = (gridInfo) =>
    setState({
      ...state,
      gridInfo,
    });

  const setIsAssociating = (isAssociating) =>
    setState({
      ...state,
      isAssociating,
    });

  const asociateConcilations = async () => {
    setIsAssociating(true);

    const wasAdded = await addConcilationCxp({
      cxp: state.gridInfo.filteredGrid.array,
      executive: {
        fullName: userInfo[0].fullName,
        id: userInfo[0].userID,
      },
      idMovement: +movement,
      idProvider: infoRow.id,
      residue: {
        initial: state.movementInfo.saldo,
        new: state.gridInfo.newResidue,
      },
    });

    setIsAssociating(false);

    if (wasAdded) {
      setStep(1);
      Success(() => {}, "Montos asociados");
      history.push(`/bancos/movimientos/${account}`);
    }
  };

  return {
    cxps: state.cxps,
    isLoading: state.isLoading,
    setGridInfo,
    movementInfo: state.movementInfo,
    gridInfo: state.gridInfo,
    asociateConcilations,
    isAssociating: state.isAssociating,
  };
}
