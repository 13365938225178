import React from "react";
import { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";
import { Label } from "structure/Document";
import ToggleComponent from "components/individual/inputs/Toggle";

export default function CurrencyExchange() {
  const hook = useContext(ContextCreateInvoiceV2);

  return (
    <div style={{
      color:"var(--primaryColor)"
    }}>
      <Label>Cambio de moneda</Label>
      <ToggleComponent
        labelActive="Si"
        labelInactive="No"
        isChecked={hook.currencyExchange}
        onChange={hook.updateCurrencyToggle}
      />
    </div>
  );
}
