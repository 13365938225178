import { Pen, Trash } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import React, { useContext } from "react";
import {
  DescriptionText,
  DescriptionTextBold,
  FooterContainer,
  SubDescription,
  SubDescriptionButtons,
} from "./Containers";
import DocumentContext from "../ContextDocument";
import ModalInfoSection from "./ModalInfoSection/index";
import DrangAndDrop from "./DragAndDrop";
import ModalDocumentItems from "structure/ModalDocumentItems";
import { handleFormItem } from "../helpers/documentHelper";
import { truncateDecimals } from "helpers/money";
import Swal from "sweetalert2";

/**
 * Footer for the document item requested
 * @param {import("./types").Footer} props - Props to handle the footer of the item
 */
export const FooterSection = ({
  pu,
  discount,
  puventa,
  ivaPercentage = "??",
  iva = 0,
  idItem,
  itemOnMemory,
  index = 0,
}) => {
  const {
    handleOnDeleteItem,
    actionType,
    handleEditItemForm,
    documentType,
    customer,
    moneyInfo,
  } = useContext(DocumentContext);

  const mustUseIva0 = customer?.rfc === "XEXX010101000" && documentType === 3;

  return (
    <FooterContainer>
      <SubDescription>
        <DescriptionTextBold>Unitario</DescriptionTextBold>
        <DescriptionText>{pu}</DescriptionText>
        <DescriptionTextBold>Descuento</DescriptionTextBold>
        <DescriptionText>{discount}</DescriptionText>
        <DescriptionTextBold>Importe</DescriptionTextBold>
        <DescriptionText>{puventa}</DescriptionText>
        <DescriptionTextBold>IVA </DescriptionTextBold>
        <DescriptionText>
          {mustUseIva0 ? "0.00%" : ivaPercentage}
          {" - "}
        </DescriptionText>
        <DescriptionText>{mustUseIva0 ? "$0.00" : iva}</DescriptionText>
      </SubDescription>
      <SubDescriptionButtons>
        {actionType === "Ver" ? (
          <>{/* <ModalInfoSection id={idItem} /> */}</>
        ) : (
          <>
            {/* <ModalInfoSection id={idItem} /> */}
            <Icon
              id={idItem}
              onClick={async () => {
                const { isConfirmed } = await Swal.fire({
                  title: "Borrar partida",
                  text: "¿Estás seguro de que deseas borrar esta partida? El cambio tendra efecto cuando actualices el documento",
                  confirmButtonText: "Si, borrar",
                  cancelButtonText: "No, regresar",
                  icon: "question",
                  showCancelButton: true,
                });

                if (isConfirmed) handleOnDeleteItem(index);
              }}
            >
              {Trash}
            </Icon>

            <ModalDocumentItems
              title="Catálogo de productos y servicios"
              onChange={(item) => {
                const calculation = handleFormItem(
                  item,
                  truncateDecimals(moneyInfo.tc.number, 2)
                );              

                handleEditItemForm(calculation, index);
              }}
              initialData={{
                cost: itemOnMemory.cu.number,
                currency: itemOnMemory.currency.code,
                description: itemOnMemory.description,
                discountCost: itemOnMemory.providerDiscount.number,
                discountSell: itemOnMemory.clientDiscoount.number,
                idCatalogue: itemOnMemory.catalogue.id,
                iva: itemOnMemory.iva.number,
                ivaExempt: itemOnMemory.iva.exempt,
                quantity: itemOnMemory.quantity,
                satCode: itemOnMemory.satCode,
                satUm: itemOnMemory.satUm,
                sell: itemOnMemory.pu.number,
                sku: itemOnMemory.sku,
                uen: itemOnMemory.uen.id,
                satCodeDescription: `${itemOnMemory.satCodeDescription}`,
                satUmDescription: `${itemOnMemory.satUmDescription}`,
              }}
            >
              <Icon>{Pen}</Icon>
            </ModalDocumentItems>
            <DrangAndDrop />
          </>
        )}
      </SubDescriptionButtons>
    </FooterContainer>
  );
};
