import React from 'react';
import PropTypes from 'prop-types';
import HomeHeader from '../../../../components/general/homeNavBar/HomeHeader';
import { useParams } from 'react-router-dom';

function BreadCrum(props) {

    const { idCustomer } = useParams();

    return (
        <>
        
            <HomeHeader
                mostrar = {false}
                bredcrum = {{
                    breadcrum: [{
                            contentText: "Inicio",
                            className: "breadcrumActivado",
                            url: "/inicio",
                            click: true,
                        },
                        {
                            contentText: "Directorio",
                            url: "/directorio",
                            className: "breadcrumActivado",
                            click: true,
                        },
                        {
                            contentText: "Documentos",
                            url: `/directorio/documentos/${idCustomer}`,
                            className: "breadcrumActivado",
                            click: true,
                        },
                        {
                            contentText: "Asociar archivos",
                            className: "breadcrumDesactivo",
                            click: false,
                        }
                    ]
                }}
            />

        </>
    )
}

BreadCrum.propTypes = {

}

export default BreadCrum

