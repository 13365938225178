import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import React, { useContext } from "react";
import { AssociateFilesContext } from "structure/AssociateFiles";
import Icon from "components/individual/HeaderIcon";

export default function DownloadFile() {
  const { downloadFileAssociated, isDownloading, isDeleting, isAllowDownload } =
    useContext(AssociateFilesContext);

  if (!isAllowDownload) return <></>;

  if (isDownloading)
    return (
      <div>
        <Spinner hidden={false} text={<LoadingText>Descargando</LoadingText>} />
      </div>
    );

  return (
    <div>
      <ButtonV2 disabled={isDeleting} onClick={downloadFileAssociated}>
        <span>Descargar</span>
        <Icon icon="download" />
      </ButtonV2>
    </div>
  );
}
