import useDialog from "customHooks/useDialog";
import { updateDocumentsExchange } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";

/**
 * @type {import("structure/ModalExchangeDocuments/types").StateExchangeDocuments}
 */
const INITIAL_STATE = {
  executive: {
    id: 0,
    name: "",
  },
  isOpen: false,
  grid: [],
  isUpdating: false,
};

export default function useExchangeDocuments() {
  const [state, setState] = useState(INITIAL_STATE);

  const setIsOpen = (isOpen) =>
    setState((current) => ({
      ...current,
      isOpen,
    }));

  const setGrid = (grid) =>
    setState((current) => ({
      ...current,
      grid,
    }));

  const setExecutive = (executive) =>
    setState((current) => ({
      ...current,
      executive,
      grid: [],
    }));

  const dialog = useDialog();

  const attemptExchangeCustomers = async () => {
    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    const wasUpdated = await updateDocumentsExchange(
      state.grid.map((item) => ({
        idCustomer: item.customer.id,
        idExecutive: item.executive.value,
      }))
    );

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));

    if (wasUpdated) setState(INITIAL_STATE);
  };

  useEffect(() => {
    if (state.isOpen) {
      dialog.open();
    } else {
      dialog.close();
      setState(INITIAL_STATE);
    }
  }, [state.isOpen]);

  return {
    state,
    setIsOpen,
    setGrid,
    setExecutive,
    dialog,
    attemptExchangeCustomers,
  };
}
