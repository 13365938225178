import React, { useContext } from "react";
import ui from "./styles.module.scss";
import { ContextAccountingOc } from "..";
import UpdateAccounting from "components/individual/UpdateAccounting";
import style from "../styles.module.scss";

export default function Options() {
  const hook = useContext(ContextAccountingOc);

  return (
    <div className={style.content}>
      <div className={ui.container}>
        <UpdateAccounting
          operationGrid={hook.grid.operation}
          originalGrid={hook.grid.original}
          type="OC"
          onUpdated={() => hook.search()}
        />
      </div>
    </div>
  );
}
