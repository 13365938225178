import React, { createContext } from "react";
import ui from "../styles.module.scss";
import Delete from "./Delete";
import Edit from "./Edit";
import uiManufacturer from "./styles.module.scss";
import useManufacturer from "./useManufacturer";
import ViewMode from "./ViewMode";
import EditMode from "./EditMode";

/**
 * @type {import("react").Context<undefined|import("./types").ContextManufacturer>}
 */
export const ContextManufacturer = createContext(undefined);

/**
 * Render the item of the manufacturer
 * @param {import("./types").PropsManufacturer} manufacturer - Infomration of the manufacturer
 * @returns {JSX.Element}
 */
export default function Manufacturer(manufacturer) {
  const hook = useManufacturer(manufacturer,manufacturer.onChange);

  return (
    <ContextManufacturer.Provider value={{ ...hook, manufacturer }}>
      <div className={ui.manufacturer}>
        <p>{manufacturer.supplier.socialReason}</p>

        {hook.editMode ? <EditMode /> : <ViewMode />}
      </div>
    </ContextManufacturer.Provider>
  );
}
