import { mnCurrency } from "helpers/money";
import React from "react";
import { useContext } from "react";
import { ContextAddIncoming } from "../..";

/**
 *
 * @param {import('../../../../../../../../server/models/banks/movements/types').ConceptI} concept
 * @returns {JSX.Element}
 */
export default function ConceptImport(concept) {
  const context = useContext(ContextAddIncoming);

  // Es concepto por ventas o activos
  if (concept.id === 8 || concept.id === 7) {
    return (
      <p className="text-right px-3">
        {concept.id === 8
          ? mnCurrency(context.state.concepts.egresoPorVenta)
          : mnCurrency(context.state.concepts.ventaActivos)}
      </p>
    );
  }

  // No es concepto por ventas o activos, retorna un guion
  return <p className="text-center">-</p>;
}
