import React, { useContext } from "react";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { ContextConsecutivePurchaseOrder } from "..";

export default function SearchConsecutivePurchaseOrder() {
  const hook = useContext(ContextConsecutivePurchaseOrder);

  return (
    <div>
      <Label>Búsqueda</Label>

      <Search
        value={hook.search}
        onDelete={() => hook.updateSearch("")}
        onChange={value=>hook.updateSearch(value)}
        placeholder="Razon social"
        onSearch={()=>hook.researchReport()}
        hideSearchIcon={true}

      />
    </div>
  );
}
