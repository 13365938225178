import { RedirectButton } from "components/individual/buttons/Buttons";
import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";
import { IncomingContext } from "../..";
import BankContext from "context/Bank/BankContext";

export default function AssociateFiles() {
  const hook = useContext(IncomingContext);
  const context = useContext(BankContext);

  const { checkPermission } = usePermissions();

  if (
    checkPermission("2f9beeab-d15f-476a-8fe6-4d1756e66f73") &&
    hook.movement !== null
  )
    return (
      <RedirectButton
        text={
          hook.isForAccounting ? "Ver archivos asociados" : "Archivos asociados"
        }
        link={`/bancos/movimientos/${
          context.account.id
        }/archivos-asociados?movimiento=${hook.movement.id}&cuenta=${
          context.account.id
        }&tipo=${hook.type === "ingreso" ? "ingreso" : "egreso"}&modulo=${
          hook.isForAccounting ? "contabilidad" : "movimientos"
        }`}
      />
    );

  return <></>;
}
