import React, { useContext, useReducer, useEffect } from "react";
import { QuoteForm } from "./Forms/QuoteForm";
import "../Documents.scss";
import { SubmitTest } from "../../../../components/individual/buttons/Buttons";
import Comments from "./Comments";
import Games from "./Games";
import { useParams } from "react-router-dom";
import { SetContext } from "./ConfigureContest";
import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";
import { Spinner } from "components/individual/Spinner/Spinner";
import Contract from "pages/Directory/Documents/Edit/Contract";
import {
  initialState,
  reducer,
} from "pages/Directory/Documents/Edit/reducer.js";
import LoginContext from "context/Login/LoginContext";

import InvoiceForm from "pages/Directory/Documents/AddDocument/Forms/InvoiceForm";

function FormQuote() {
  const agregar = "agregar";

  const { userInfo } = useContext(LoginContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  let { action, typeDocument } = useParams();

  const isAddCotizacion = action === agregar && typeDocument === "cotizacion";
  const isAddOrdenPago = action === agregar && typeDocument === "pago";
  const isAddServicios = action === agregar && typeDocument === "servicios";
  const {
    documento,
    setDocument,
    games,
    setExchangeTcp,
    isFetching,
    setCurrencyUsed,
    setCurrencyName,
    comments,
    commentsToDelete,
    docItemsToDelete,
    setCurrencyType,
  } = useContext(AddDocumentContext);

  useEffect(() => {
    if (typeDocument === "contrato" && state.document !== null) {
      setCurrencyUsed("");
      // setExpDate(new Date(docInfo.parsedExpDate));
      setCurrencyType(state.document.currency.id);
      setCurrencyName(state.document.currency.code);
      setExchangeTcp(state.document.tcp.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.document]);

  function handleSubmitErros() {
    document.getElementById("form").classList.add("d-block");
    document.getElementById("form").classList.remove("d-none");

    document.getElementById("partidas").classList.add("d-none");
    document.getElementById("partidas").classList.remove("d-block");

    document.getElementById("comentarios").classList.add("d-none");
    document.getElementById("comentarios").classList.remove("d-block");

    document.getElementById("btnComentarios").classList.remove("active");
    document.getElementById("btnPartidas").classList.remove("active");
    document.getElementById("btnFormulario").classList.add("active");
  }

  function openTab(evt, tabNaem) {
    // Declare all variables
    let i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.add("d-none");
      tabcontent[i].classList.remove("d-block");
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(tabNaem).classList.add("d-block");
    document.getElementById(tabNaem).classList.remove("d-none");
    evt.currentTarget.className += " active";
  }

  const documentComponent = {
    cotizacion: <QuoteForm />,
    prefactura: <InvoiceForm />,
    // contrato: (
    //   <>
    //     <p>Hi</p>
    //   </>
    // ),
  };

  const documentComponentV2 = {
    cotizacion: {
      form: <QuoteForm />,
      games: <Games />,
      comments: <Comments />,
    },
    prefactura: {
      form: <InvoiceForm />,
      games: <Games />,
      comments: <Comments />,
    },
    contrato: {
      form: (
        <Contract
          dispatch={dispatch}
          state={state}
          comments={comments}
          commentsToDelete={commentsToDelete}
          itemsToDelete={docItemsToDelete}
          items={games}
          fullNameExecutive={userInfo[0]?.fullName}
          idExecutive={userInfo[0]?.userID}
          total={state.document?.amount.total.number}
          importe={state.document?.amount.subtotal.number}
          iva={state.document?.amount.iva.number}
          residue={state.document?.amount.residue.number}
          acumulated={state.document?.amount.acumulated.number}
        />
      ),
      games: <Games />,
      comments: <Comments />,
    },
  };
  return (
    <>
      <div className="container mt-4">
        <div className="container tab col-12 d-flex justify-content-center p-0 mb-0">
          <button
            id="btnFormulario"
            className="tablinks active"
            onClick={(e) => {
              openTab(e, "form");
            }}
          >
            {/* Cotizacion */}
            {typeDocument[0].toUpperCase() + typeDocument.slice(1)}
          </button>
          <button
            id="btnPartidas"
            className="tablinks"
            onClick={(e) => {
              openTab(e, "partidas");
              const documentForm = SetContext(
                isAddCotizacion,
                isAddOrdenPago,
                isAddServicios
              );
              setDocument({
                ...documento,
                vacio: false,
                documentForm,
              });
            }}
          >
            Partidas
          </button>
          <button
            id="btnComentarios"
            className="tablinks"
            onClick={(e) => {
              openTab(e, "comentarios");
              const documentForm = SetContext(
                isAddCotizacion,
                isAddOrdenPago,
                isAddServicios
              );
              setDocument({
                ...documento,
                vacio: false,
                documentForm,
              });
            }}
          >
            Comentarios
          </button>
        </div>
      </div>
      {/* <OptionsDocuments /> */}
      <div className="container flotante spacingOptions">
        {typeDocument === "contrato" ? null : games.length <= 0 ? null : (
          <>
            {isFetching ? (
              <Spinner
                hidden={false}
                text={action == "editar" ? "Actualizando..." : "Guardando..."}
              />
            ) : (
              <SubmitTest
                text={action == "editar" ? "Actualizar" : "Guardar"}
                aditionalClasses="boton-flotante"
                formID="document"
                onClickEvent={handleSubmitErros}
              />
            )}
          </>
        )}
      </div>
      <div className="container">
        <div id="form" className="tabcontent d-block">
          {documentComponentV2[typeDocument].form}
          {/* <QuoteForm /> */}
        </div>
        <div id="partidas" className="tabcontent">
          {/* {typeDocument=='cotizacion' ? <Games /> : null } */}
          {documentComponentV2[typeDocument].games}
        </div>
        <div id="comentarios" className="tabcontent">
          {/* <Comments /> */}
          {documentComponentV2[typeDocument].comments}
        </div>
      </div>
    </>
  );
}

export default FormQuote;
