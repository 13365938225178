import { URL_BASE } from "routes/routes";
import axios from "axios";
import { isValidHttpResCode } from "helpers/Apis/fetch";
import { Error, Success } from "helpers/alerts";

export const fetchInvoiceEmittedTable = async (
  page,
  statusId,
  search,
  startDate,
  endDate
) => {
  try {
    const {
      data: { result },
    } = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/facturas?pageRequested=${page}&statusId=${statusId}&search=${search}&startDate=${startDate}&endDate=${endDate}`,
      {
        withCredentials: true,
      }
    );
    console.log("Cargando tabla...");
    console.log(result);
    return {
      page: result.pagination.actualPage,
      totalPages: result.pagination.pages,
      data: result.data,
    };
  } catch (error) {
    console.log(error);

    return {
      page: 0,
      totalPages: 0,
      data: [],
    };
  }
};
export const fetchInvoiceEmittedTableV2 = async (
  page,
  statusId,
  search,
  startDate,
  endDate
) => {
  try {
    const {
      data: { result },
    } = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/obtener/tabla?pageRequested=${page}&status=${statusId}&search=${search}&beginDate=${startDate}&endDate=${endDate}`,
      {
        withCredentials: true,
      }
    );
    console.log("Cargando tabla...");
    console.log(result);
    return {
      page: result.pagination.actualPage,
      totalPages: result.pagination.pages,
      data: result.data,
    };
  } catch (error) {
    console.log(error);

    return {
      page: 0,
      totalPages: 0,
      data: [],
    };
  }
};

export const getInvoiceStatus = async () => {
  try {
    const {
      data: { result },
    } = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/facturas/estatus`,
      {
        withCredentials: true,
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

/**
 * Get the information to cancel an invoice with motive `01`
 * @param {number} search - Search of the folio
 * @param {string} uuid - UUID of the invoice to be replaced
 * @returns {Promise<import("axios").AxiosResponse<import("../../../../../../server/models/documentsV2Types").InvoiceByUUID>>}
 */
export const getInvoiceToReplace = async (search,uuid) => {
  try {

    const queryFolio = `?folio=${search}`;
    const queryUuid = `&invoiceToCancel=${uuid}`;

    const data = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/facturas/replace${queryFolio}${queryUuid}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {number} executiveId - Id of the executive who cancels the invoice
 * @param {string} idLegalDocument - `UUID` of legal documents (invoice to cancel)
 * @param {"01"|"02"|"03"|"04"|string} motive - Cancelation motive
 * @param {string} type - ??
 * @param {string} uuidReplacement - `UUID` for the replacement
 * @returns
 */
export const cancelInvoice = async (
  executiveId,
  idLegalDocument,
  motive,
  type = "issued",
  uuidReplacement = ""
) => {
  try {
    /**
     * @type {import("axios").AxiosResponse<import("./types").ResInvoiceCancelated>}
     */
    let data;
    if (uuidReplacement === "") {
      data = await axios.delete(
        `${URL_BASE}facturacion/cancel-invoice/${idLegalDocument}/${executiveId}?type=${type}&motive=${motive}`,
        {
          withCredentials: true,
        }
      );
    } else {
      data = await axios.delete(
        `${URL_BASE}facturacion/cancel-invoice/${idLegalDocument}/${executiveId}?type=${type}&motive=${motive}&uuidReplacement=${uuidReplacement}`,
        {
          withCredentials: true,
        }
      );
    }
    return data;
  } catch (error) {
    /**
     * @type {import("axios").AxiosError<import("./types").ErrorServer>}
     */
    const axiosError = error;

    Error(() => {}, axiosError.response.data.message);
  }
};

/**
 * Get the credit notes emited by praxia
 * @param {import("./types").DtoGetCreditNotesEmited} dto - Information to fetch the credit notes
 * @returns {Promise<import("./types").CreditNotesEmited|Blob>}
 */
export async function getCreditNotesEmited({
  beginDate,
  customerId,
  endDate,
  page,
  search = "",
  statusId = null,
  contabilidad = null,
  asExcel = false,
}) {
  /**
   * @type {import("./types").CreditNotesEmited}
   */
  const ERROR_DATA = {
    nce: [],
    pages: {
      actualPage: 1,
      noRegisters: 0,
      pages: 1,
    },
  };

  const searchQuery = search === "" ? null : search;
  const contabilidadEstatus =
    contabilidad === null ? "" : `&contabilidad=${contabilidad}`;

  const url = asExcel ? "excel" : "tabla";

  try {
    const res = await fetch(
      `${URL_BASE}nce/${url}?endDate=${endDate}&beginDate=${beginDate}&customerId=${customerId}&pageRequested=${page}&search=${searchQuery}&statusId=${statusId}${contabilidadEstatus}`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      if (!asExcel) {
        /**
         * @type {import("./types").ResponseCreditNotesEmited}
         */
        const data = await res.json();
        return data.paginatedNce;
      }

      const excel = await res.blob();
      return excel;
    }

    /**
     * @type {import("./types").ResponseCreditNotesEmited}
     */
    const data = await res.json();
    Error(() => {}, data.message);

    return ERROR_DATA;
  } catch (error) {
    return ERROR_DATA;
  }
}

/**
 * Cancel a credit note against praxia and sat
 * @param {string} idFacturama - Id facturama
 * @param {string} uuid - UUID credit note
 * @returns {Promise<boolean>}
 */
export async function cancelCreditNote(idFacturama = null, uuid = null) {
  const queryIdFacturama =
    idFacturama === null ? "" : `idFacturama=${idFacturama}`;
  const queryUuid = uuid === null ? "" : `uuid=${uuid}`;

  try {
    const res = await fetch(`${URL_BASE}nce?${queryUuid}${queryIdFacturama}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}
