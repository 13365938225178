import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useLogs from "customHooks/useLogs";
import React from "react";
import TableLogs, { DetailsLogs } from "./TableLogs";
import Filter from "./Filter";
import ui from "./styles.module.scss";
import { getDateFromUtc, parseDateToTextAndTime } from "helpers/dates";
import { createContext } from "react";

/**
 * @type {import("react").Context<import("customHooks/useLogs/types").ReturnUseLogs>}
 */
export const ContextLogs = createContext(undefined);

export default function LogViewer() {
  const hook = useLogs();

  return (
    <ContextLogs.Provider value={hook}>
      <div className={ui.logsContainer}>
        <BreadCrumV2 path={hook.breadcrum} />

        <Filter />

        <TableLogs
          
          data={hook.logs}
          expandableRows={true}
          onChangePage={(page) => hook.setPage(page)}
          onChangeRowsPerPage={(rowsPerPage) =>
            hook.setRowsPerPage(rowsPerPage)
          }
          paginationTotalRows={hook.pages * hook.rowsPerPage}
          paginationRowsPerPageOptions={[100, 75, 50, 25]}
          defaultSortAsc={false}
          defaultSortFieldId={hook.order}
          sortServer={true}
          onColumnOrderChange={(data) => console.log(data)}
          onSort={(sort) => {
            hook.setColumnOrder(sort.sortField);
          }}
          progressPending={hook.isLoading}
          expandableRowsComponent={(data) => <DetailsLogs {...data} />}
          columns={[
            {
              name: "Servicio",
              center: true,
              selector: (data) => data.provider.description,
            },
            {
              name: "Inicio",
              id: "creationTime",
              sortable: true,
              center: true,
              sortField: "creationTime",
              selector: (data) =>
                parseDateToTextAndTime(
                  getDateFromUtc(data.createdDate),
                  "medium"
                ),
            },
            {
              name: "Fin",
              id: "endTime",
              sortField: "endTime",
              selector: () => "NA",
            },
            {
              name: "Rutina",
              id: "routine",
              selector: (data) => data.modulo || 'ND',
            },
            {
              name: "Error",
              center: true,
              selector: (data) =>
                data.error.wasAnError ? data.error.code : "NA",
            },
          ]}
        />
      </div>
    </ContextLogs.Provider>
  );
}
