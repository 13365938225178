import { Spinner } from "components/individual/Spinner/Spinner";
import useGridCxC from "customHooks/useGridCxC";
import React, { useRef , createContext } from "react";
import { InputBalance } from "./InputBalance";
import { Tc } from "./TC";
import { BalancePending } from "./BalancePending";
import InputDifferentCurrency from "./InputDifferentCurrency";
import LoadingText from "components/individual/TextLoading";

/**
 * @type {import("react").Context<import("./types").ContextGridCxC|undefined>}
 */
export const ContextGridCxC = createContext(undefined);
const { Provider } = ContextGridCxC;


/**
 * Render a grid to associate amounts of any kind
 * @param {import("./types").GridCxCProps} props - Props
 * @returns {JSX.Element}
 */
export const GridCxC = (props) => {
  const hook = useGridCxC({
    currency: props.currency,
    inyectionInfo: props.inyectionGrids,
    availableBalance: props.availableBalance,
    onChange: props.onChange,
    tolerance: props.tolerance,
    search: props.search,
    searchValue: props.searchValue,
    decimals: 2,
    refetch: props.refetch,
  });

  const keyMap = useRef(`${window.crypto.randomUUID()}`);


  if (hook.isLoading)
    return (
      <Spinner
        idSpinner="loadingGridPartialities"
        hidden={false}
        text={<LoadingText>Cargando grid</LoadingText>}
      />
    );

  return (
    <>
      {Object.entries(hook.listToUse).map(([key, element],i) => (
        <Provider
          key={`${keyMap.current}-${i}`}
          value={{
            element,
            currency: props.currency,
            updateAppliedBalance: hook.updateAppliedBalance,
            updateTcApplied: hook.updateTcApplied,
            updateAmountApplied: hook.updateAmountApplied,
          }}
        >
          {props.children({
            element,
            InputBalance,
            InputDifferentCurrency,
            BalancePending,
            Tc,
            i
          })}
        </Provider>
      ))}
    </>
  );
};
