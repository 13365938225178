import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ContextConsecutivePurchaseOrder } from "..";
import Icon from "components/individual/HeaderIcon";

export default function SearchConsecutivePurchaseOrder() {
  const hook = useContext(ContextConsecutivePurchaseOrder);

  return (
    <ButtonV2 onClick={hook.researchReport} disabled={hook.isLoading}>
      <span>Buscar</span>
      <Icon icon="search" />
    </ButtonV2>
  );
}
