import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { LocalServiceWorkerRegister } from "./sw-register";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// serviceWorkerRegistration.register();
LocalServiceWorkerRegister();
