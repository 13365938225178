import React, { useState, useEffect } from "react";

// LIBRARIES
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faKey } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { ErrorMessage } from "./Inputs";

//STYLES
import "../inputs/Inputs.scss";

/**
 * Insert an input of type password
 *
 * @param {string} labelText - Label to display on input
 * @param {string?} placeholderText - Placeholder to display on input
 * @param {string} id - id attribute of the input tag
 * @param {string} name - name and for attribute for the input tag
 * @param {string?} aditionalClasses - CSS classes for the container
 * @param {object?} forwardRef - register object of react-hook-form (yup)
 * @param {boolean?} canSeePassword - If true, a button to display the password will be render
 * @param {object?} errors - error object of react-hookr-form (yup)
 * @param {boolean?} autoFocus - focus inmediatly the input
 */
function Password({
  labelText,
  placeholderText = "",
  id,
  name,
  aditionalClasses = "",
  forwardRef,
  canSeePassword = false,
  errors,
  autoFocus = false,
}) {
  const [icon, setIcon] = useState(faEye);

  const showPassword = () => {
    const type = document.getElementById(id).type;
    const input = document.getElementById(id);

    if (type === "password") {
      input.type = "text";
      setIcon(faEyeSlash);
    } else {
      input.type = "password";
      setIcon(faEye);
    }
  };

  useEffect(() => {
    if (autoFocus === true) document.getElementById(id).focus();
  }, []);

  return (
    <div className={`${aditionalClasses}`}>
      <label className="labelInput" htmlFor={name}>
        {labelText}
      </label>
      <div className="d-flex align-items-center">
        <div className="leftSideInput d-flex align-items-center">
          <FontAwesomeIcon className="align-middle" icon={faKey} />
        </div>

        <input
          id={id}
          className="rightSideInput"
          name={name}
          type="password"
          ref={forwardRef}
          placeholder={placeholderText}
        />

        {canSeePassword ? (
          <div onClick={showPassword} className="showPassword">
            <FontAwesomeIcon className="align-middle" icon={icon} />
          </div>
        ) : null}
      </div>

      <ErrorMessage message={errors?.[name]?.message} id={`error_${id}`} />
    </div>
  );
}

export default Password;
