import Select from "components/individual/SelectV4";
import { MONTHS } from "helpers/dates";
import React from "react";
import { Label } from "structure/Document";

/**
 * Render a month select
 * @param {import("./types").PropsMonthSelect} props - Props
 * @returns {JSX.Element}
 */
export default function MonthSelect({ value = null , onChange = () => {} , required = false }) {
  return (
    <div>
      <Label required={required}>Mes</Label>
      <Select
        labelToDisplay="description"
        defaultSelected={{
          keyToCompare: "value",
          value,
        }}
        onChange={(month) => onChange(month.value)}
        options={async () => [
          { value: null, description: "-- SELECCIONA --", disabled: true },
          ...MONTHS.map((month, i) => ({
            value: i + 1,
            description: month,
          })),
        ]}
      />
    </div>
  );
}
