import React, { useContext } from "react";
import Toggle from "react-toggle";
import { AddRolContext } from "..";
import Description from "../../Description";

export default function Step1() {
  const {
    updateDescription,
    description: { isValid },
    updateIsActive,
    isActive,
  } = useContext(AddRolContext);

  return (
    <div className="mx-4">
      <Description isValid={isValid} updateDescription={updateDescription} />
      <label
        htmlFor=""
        className="d-flex align-items-center cursorPointer my-3"
      >
        <Toggle defaultChecked={isActive} onChange={updateIsActive} />
        <span className="ml-3">{isActive ? "Activo" : "Inactivo"}</span>
      </label>
    </div>
  );
}
