import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import BankContext from "context/Bank/BankContext";
import { IncomingContext } from "..";

export default function CreateMovement() {
  const { checkPermission } = usePermissions(
    ["23beca91-4544-43ff-8f54-08f492c69adc", "892796d5-bc32-4ad4-a224-bc371831f02c","63275914-042b-4e13-b4c1-8a89af71d8d0"]
  );

  const history = useHistory();

  const hook = useContext(IncomingContext);

  const context = useContext(BankContext);

  if(hook.isForAccounting) return <></>

  if (!checkPermission("30e4099b-5b86-4a1d-a725-fbb4bfbc3efa")) return <></>;

  return (
    <ButtonV2
      onClick={() =>
        history.push(
          `/v2/bancos/${
            hook.type === "ingreso" ? "ingresos" : "egresos"
          }/agregar?cuenta=${context.id}`
        )
      }
    >
      <span>Agregar</span>
      <Icon icon="plus" />
    </ButtonV2>
  );
}
