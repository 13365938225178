import React, { useContext } from "react";
import { Label } from "structure/Document";
import QuoteCancelationMotives from "components/general/Forms/Selects/QuoteCancelationMotives";
import { ContextCancelInvoice } from "..";

export default function ComboCancelation() {
  const hook = useContext(ContextCancelInvoice);

  return (
    <div className="my-3">
      <Label required={hook.idMotive === null}>Motivo de cancelacion</Label>
      <QuoteCancelationMotives
        onChange={(data) => hook.updateMotive(data.id)}
      />
    </div>
  );
}
