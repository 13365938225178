import React, { useContext } from "react";
import Table from "components/general/Table";
import { mnCurrency } from "helpers/money";
import { ContextReception } from "..";
import FieldReception from "./FieldReception";
import { Spinner } from "components/individual/Spinner/Spinner";

/**
 *
 * @param {import('react-data-table-component').TableProps<import("../../../../../../types/proyects/materials").DtoListMaterials>} props
 */
function TableGrid(props) {
  return <Table {...props} />;
}

export default function Grid() {
  const hook = useContext(ContextReception);

  if (hook.state === "loading")
    return <Spinner text={"Cargando materiales"} hidden={false} />;

  return (
    <TableGrid
      data={hook.materials}
      sortServer={false}
      pagination={false}
      columns={[
        {
          name: "Descripción",
          sortable: true,
          selector: (item) => item.description,
        },
        {
          name: "Unidad",
          sortable: true,
          selector: (item) => item.unit,
        },
        {
          name: "Costo",
          sortable: true,
          selector: (item) => mnCurrency(item.cost),
        },
        {
          name: "Cantidad",
          sortable: true,
          selector: (item) => item.initialQuantity,
        },
        {
          name: "Recibidos",
          sortable: true,
          selector: (item) => item.receivedMaterials,
        },
        {
          name: "Recepción",
          sortable: true,
          selector: (item) => <FieldReception {...item} />,
        },
      ]}
    />
  );
}
