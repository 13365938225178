import { MOVEMENTS } from "types";

export default function (state, action) {
  switch (action.type) {
    case MOVEMENTS.SET_STATUS:
      return {
        ...state,
        filterMovements: {
          ...state.filterMovements,
          status: action.payload,
        },
      };

    case MOVEMENTS.SET_RANGE_DATE:
      return {
        ...state,
        filterMovements: {
          ...state.filterMovements,
          beginDate: action.payload.begin,
          endDate: action.payload.end,
        },
      };
  }
}
