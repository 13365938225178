import React, { createContext } from "react";
import useOldResidues from "./useOldResidues";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import CurrencyReport from "./CurrencyReport";
import TcReport from "./TcReport";
import style from "./styles.module.scss";
import CurrencyModule from "./CurrencyModule";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import ShowDetails from "./ShowDetails";
import TableOldResidues from "./Table";
import Excel from "./Excel";
import FilterMobile from "structure/FilterMobile";
import Icon from "components/individual/HeaderIcon";
import { mnCurrency } from "helpers/money";

/**
 * @type {import("react").Context<import("./types").OldResidues>}
 */
export const ContextOldResidues = createContext(undefined);

/**
 * Render
 * @param {import("./types").PropsOldResidue} props - Props
 * @returns {JSX.Element} Render a structure to display the report
 */
export default function OldResidues({ type = "cxc" }) {
  const hook = useOldResidues(type);

  return (
    <div className={style.module}>
      <ContextOldResidues.Provider value={hook}>
        <div className={style.headerModule}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={style.margin}>
            <form
              className={style.filter}
              onSubmit={(e) => {
                e.preventDefault();
                hook.triggerSearch();
              }}
            >
              <CurrencyReport />
              <TcReport />
              <CurrencyModule />
              <ShowDetails />
              <ButtonV2>
                <span>Buscar</span>
                <Icon icon="search" />
              </ButtonV2>

              <Excel />
            </form>
          </div>

          <div className={style.margin}>
            <FilterMobile
              pills={
                hook.currencyToReport !== "Todos"
                  ? []
                  : [
                      {
                        label: "TC",
                        value: `${mnCurrency(hook.tc, 4)}`,
                      },
                      {
                        label: "Moneda (Reporte)",
                        value: `${hook.currencyToUse}`,
                      },
                    ]
              }
            />
          </div>
        </div>

        <div className={style.margin}>
          <TableOldResidues />
        </div>
      </ContextOldResidues.Provider>
    </div>
  );
}
