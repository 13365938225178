import React from "react";
import { useContext } from "react";
import Dialog from "structure/Dialog";
import { ContextUenAdmin } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Label } from "structure/Document";
import style from "../ModalAdd/styles.module.scss";
import {
  Excento,
  IvasCatalogue,
  Options,
} from "components/general/Forms/Selects/IvasCatalogue";
import {
  ModalClaveProd,
  ModalUm,
  UenV3,
} from "components/general/Forms/Selects/UenV3";
import ButtonUpdate from "./ButtonUpdate";
import ToggleComponent from "components/individual/inputs/Toggle";
import { Controller } from "react-hook-form";

export default function ModalUpdate() {
  const hook = useContext(ContextUenAdmin);

  return (
    <>
      <ButtonV2 onClick={() => hook.handleEditModal(true)}>Editar</ButtonV2>

      <Dialog
        width={35}
        forwardRef={hook.dialogEdit.refDialog}
        title={`Editar UEN | ${hook.uen.description} | ${hook.uen.family} | ${hook.uen.subFamily}`}
        closeCallback={() => hook.handleEditModal(false)}
        footer={<ButtonUpdate />}
      >
        {hook.isEditing === true && hook.uen !== undefined && (
          <form
            noValidate={true}
            className={style.containerForm}
            onSubmit={hook.formUpdate.handleSubmit(
              hook.attemptUpdateUen,
              hook.printError
            )}
            id={hook.idFormUpdate}
          >
            <div>
              <Label
                required={Object.keys(hook.formUpdate.formState.errors).includes(
                  "description"
                )}
              >
                Descripción
              </Label>
              <input
                ref={hook.formUpdate.register}
                type="text"
                name="description"
                placeholder="Escribe aquí"
              />
              <p className="text-danger font-weight-bold">
                {hook.formUpdate.errors.description?.message}
              </p>
            </div>

            <div>
              <Label
                required={Object.keys(hook.formUpdate.formState.errors).includes(
                  "family"
                )}
              >
                Familia
              </Label>
              <input
                ref={hook.formUpdate.register}
                type="text"
                placeholder="Escribe aquí"
                name="family"
              />
              <p className="text-danger font-weight-bold">
                {hook.formUpdate.errors.family?.message}
              </p>
            </div>

            <div>
              <Label
                required={Object.keys(hook.formUpdate.formState.errors).includes(
                  "subFamily"
                )}
              >
                Sub-Familia
              </Label>
              <input
                ref={hook.formUpdate.register}
                type="text"
                placeholder="Escribe aquí"
                name="subFamily"
              />
              <p className="text-danger font-weight-bold">
                {hook.formUpdate.errors.subFamily?.message}
              </p>
            </div>

            <div>
              <Label
                required={Object.keys(hook.formUpdate.formState.errors).includes(
                  "marginRate"
                )}
              >
                Margen
              </Label>
              <input
                ref={hook.formUpdate.register}
                type="number"
                placeholder="Escribe aquí"
                name="marginRate"
              />
              <p className="text-danger font-weight-bold">
                {hook.formUpdate.errors.marginRate?.message}
              </p>
            </div>

            <IvasCatalogue
              value={hook.uen.iva}
              exempt={hook.uen.isExempt}
              onChange={(data) => hook.updateIva(data.iva, data.excento)}
            >
              {() => (
                <>
                  <div>
                    <Label>IVA</Label>
                    <Options />
                  </div>

                  {hook.iva === 0 ? (
                    <div>
                      <Label>¿Es excento?</Label>
                      <Excento />
                    </div>
                  ) : null}
                </>
              )}
            </IvasCatalogue>

            <UenV3
              id={hook.uen.UENID}
              onChange={hook.updateUen}
              promptUpdate={false}
              justActives={false}
            >
              {() => (
                <>
                  <div>
                    <Label required={hook.SATcode === ""}>Código SAT</Label>
                    <ModalClaveProd />
                  </div>

                  <div>
                    <Label required={hook.SATUM === ""}>UM SAT</Label>
                    <ModalUm />
                  </div>
                </>
              )}
            </UenV3>

            <div>
              <Label>Estatus</Label>
              <Controller
                control={hook.formUpdate.control}
                name="status"
                render={({ onChange, onBlur, value }) => (
                  <ToggleComponent
                    isChecked={value}
                    onChange={onChange}
                    labelActive="Activo"
                    labelInactive="Inactivo"
                  />
                )}
              />
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
}
