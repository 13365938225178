import React from "react";
import { useEffect, useState } from "react";
import scss from "./styles.module.scss";
import PasswordV2 from "../PasswordV2";

/**
 * Render a input to confirm a password
 * @param {import("./types").PropsConfirmPassword} props - Props
 * @returns {JSX.Element}
 */
export const ConfirmPassword = ({ onChange = () => {} }) => {
  const [password, setPassword] = useState({
    inputA: "",
    inputB: "",
    showA: false,
    showB: false,
  });

  useEffect(() => {
    if (password.inputA !== password.inputB) {
      onChange("");
      return;
    }

    onChange(password.inputA);
  }, [password.inputA, password.inputB]);

  /**
   * Toggle the visibility of the password
   * @param {"A"|"B"} input - Input to toggle password
   * @returns {void}
   */
  const togglePassword = (input) =>
    setPassword({
      ...password,
      [`show${input}`]: !password[`show${input}`],
    });

  /**
   * Update the value of the input password
   * @param {"A"|"B"} input - Input to handle
   * @param {string} value - Password typed
   * @returns {void}
   */
  const updatePassword = (input, value) =>
    setPassword({
      ...password,
      [`input${input}`]: value,
    });

  /**
   * Toggle the input password
   * @param {boolean} check - Check value
   * @returns {void}
   */
  const toggleAllPasswords = (check) =>
    setPassword({
      ...password,
      showA: check,
      showB: check,
    });

  return (
    <>
      <PasswordV2
        value={password.inputA}
        onChange={(e) => updatePassword("A", e.target.value)}
        placeholder="Escribe aquí"
      />

      <PasswordV2
        label="Confirmar contraseña"
        value={password.inputB}
        onChange={(e) => updatePassword("B", e.target.value)}
        placeholder="Escribe aquí"
      />

      {password.inputA !== password.inputB ? (
        <p className="text-danger my-1 text-center font-weight-bold">
          Las contraseñas no coinciden
        </p>
      ) : null}
    </>
  );
};
