import React from "react";
import useBankResidue, { CONTEXT_INITIAL_STATE } from "./useBankResidues";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { createContext } from "react";
import TableResidueReport from "./Table";
import Excel from "./Excel";
import style from "./Table/styles.module.scss";

export const ContextResidueBankAccouts = createContext(CONTEXT_INITIAL_STATE);

export default function ResidueBankAccounts() {
  const hook = useBankResidue();

  return (
    <ContextResidueBankAccouts.Provider value={hook}>
      <div className={style.containerModule}>
        <div className={style.topModule}>
          <BreadCrumV2
            path={[
              {
                text: "Inicio",
                route: "/inicio",
              },
              {
                text: "Saldo cuentas bancarias",
                route: "/v2/bancos/saldos",
              },
            ]}
          />

          <Excel />
        </div>

        <TableResidueReport />
      </div>
    </ContextResidueBankAccouts.Provider>
  );
}
