import useDialog from "customHooks/useDialog";
import { getAccount } from "helpers/Apis/Banks/index";
import { getMovement } from "helpers/Apis/Banks/index";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateUseMoreInfoMovement}
 */
const INITIAL_STATE = {
  isOpen: false,
  movement: undefined,
  isLoading: true,
  title: "Cargando...",
};

/**
 * Handle the more information about a movement
 * @param {number} id - Id of the movement to fetch its more information
 * @returns {import("./types").ReturnUseMoreInfoMovement}
 */
export default function useMoreInfoMovement(id) {
  const [state, setState] = useState(INITIAL_STATE);
  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      setState((current) => ({
        ...current,
        isLoading: true,
        movement: undefined,
      }));

      const movement = await getMovement(id);
      const bankAccount = await getAccount(movement.bankAccount.id);

      setState((current) => ({
        ...current,
        isLoading: false,
        movement,
        title: `Más informacion del ${movement.type.description} ${
          movement.folio
        } del ${parseDateToText(
          movement.date
        )} por ${mnCurrency(movement.import)} ${bankAccount.currency}`,
      }));
    })();
  }, [state.isOpen]);

  /**
   * Handle the displaying of the modal
   * @param {boolean} isOpen - Flag in order to open/close the modal
   */
  const handleDisplayModal = (isOpen) => {
    setState((current) => ({
      ...current,
      isOpen,
    }));

    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  };

  return {
    ...state,
    dialog,
    handleDisplayModal,
  };
}
