import React, { useContext } from "react";
import { CuotasContext } from "..";
import { Label } from "structure/Document";

const LABEL_POWER = {
  0: "Unitario",
  2: "Cientos",
  3: "Miles",
  6: "Millones",
};

export default function TypeUnit() {
  const hook = useContext(CuotasContext);

  return (
    <div>
      <Label>Unidad</Label>
      <p>{LABEL_POWER[hook.power] || "ND"}</p>
    </div>
  );
}
