import React, { createContext } from "react";
import usePareto from "./usePareto";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import Table from "./Table";
import TopModule from "structure/TopModule";
import FilterMobile from "structure/FilterMobile";
import ui from "./styles.module.scss";
import Filter from "./Filter";

/**
 * @type {import("react").Context<import("./usePareto/types").ReturnUsePareto|undefined>}
 */
export const ContextPareto = createContext(undefined);

export default function Pareto() {
  const hook = usePareto();

  return (
    <ContextPareto.Provider value={hook}>
      <div className={ui.module}>
        <TopModule>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={ui.desktopFilter}>
            <Filter />
          </div>

          <FilterMobile
            props={{
              style: {
                margin: "0 20px",
              },
            }}
            pills={hook.pills}
            renderSearchBtn={true}
            onSearch={() => hook.form.current.submit()}
          >
            <Filter />
          </FilterMobile>
        </TopModule>

        <Table />
      </div>
    </ContextPareto.Provider>
  );
}
