import React, { useContext, useEffect } from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload , faUpload , faTrash , faComment } from "@fortawesome/free-solid-svg-icons";
import DownloadFile from '../../../../components/general/Files/DownloadFile';
import DeleteFile from '../../../../components/general/Files/DeleteFile';
import Comments from './Comments';
import Modal from '../../../../components/individual/Modals/Modal';

import LoginContext from '../../../../context/Login/LoginContext';
import TableContext from '../../../../context/Table/TableContext';

export function Actions({file,fullName}) {

    const commentIcon = <FontAwesomeIcon icon = {faComment} />

    useEffect(()=>{
        const button = document.getElementById(`comment-${file.id}`);

        button.classList.remove('button');


        if(file.hasComments){
            button.classList.add('notEmpty');
        }
    },[]);

    return (
        <>  
            {file.isActive ? <DownloadFile
                fileExtension={file.extension}
                fileName={file.fileName}
                url={file.source}
            /> : null }
            
            {file.isActive ? <DeleteFile
                pathFile = {file.source}
                idFile = {file.id}
                deletedBy = {fullName}
                onDeleted = {()=>{
                    document.getElementById('searchFiles').click();
                }}
            /> : null }

            <Modal 
                idOpenModal = {`comment-${file.id}`}
                buttonText={commentIcon} 
                title="Comentarios"
            >
                <Comments  idFile = {file.id}/>
            </Modal>
        </>
    )
}
