import Card from "components/general/Card/Card";
import { getDocumentItems } from "helpers/Apis/Documents";
import React, { useEffect, useState } from "react";

/**
 * Fetch the document items of a document
 * @param {object} props - Props
 * @param {number} props.idDocument - Id of the doc to fetch the items
 * @param {boolean} props.isOc - True if the document is of type "Orden de compra"
 * @param {('MXN'|'USD'|'***')} props.currencyDoc - 3 digit currency code of the document
 * @returns {JSX.Element}
 */
export default function DocItems({ idDocument, isOc = false, currencyDoc }) {
  const [docItems, setDocItems] = useState([]);

  useEffect(() => {
    (async function () {
      if (!idDocument || idDocument <= 0) return;

      const apiItems = await getDocumentItems(idDocument);
      setDocItems(apiItems);
    })();
  }, []);

  return (
    <div className="py-4">
      {docItems.map((item) => (
        <Card
          data={[
            {
              col: 1,
              data: item.catalogue.sku,
              header: "Clave",
            },
            {
              col: 2,
              data: item.catalogue.description,
              header: "Descripcion",
            },
            {
              col: 1,
              data: item.pu.text,
              header: isOc ? "CU" : "PU",
            },
            {
              col: 1,
              data: item.discount.text,
              header: "Descuento",
            },
            {
              col: 1,
              data: item.puVenta.text,
              header: isOc ? "CU" : "PU",
            },
            {
              col: 1,
              data: item.iva.percentage.text,
              header: "IVA %",
            },
            {
              col: 1,
              data: item.importe.text,
              header: "Importe",
            },
            {
              col: 1,
              data: item.iva.money.text,
              header: "IVA",
            },
            {
              col: 1,
              data: item.subtotal.text,
              header: "Subtotal",
            },
            {
              col: 1,
              data: isOc ? item.currency.code : currencyDoc,
              // data: item.currency.code,
              header: "Moneda",
            },
          ]}
        />
      ))}
    </div>
  );

  //<div>{docItems.length > 0 ? JSON.stringify(docItems) : null}</div>;
}
