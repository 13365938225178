import ModalV2 from "components/individual/Modals/ModalV2";
import useDialog from "customHooks/useDialog";
import React, { useState } from "react";
import CustomerInfo from "structure/Customer";
import Dialog from "structure/Dialog";

const initialState = {
  isOpen: false,
};

export const CustomerOverviewModal = ({
  idCustomer,
  idModalOpen = window.crypto.randomUUID(),
  children = <></>,
  forwardRef = undefined,
}) => {
  const [modal, setModal] = useState(initialState);

  const dialog = useDialog();

  const openModal = () => {
    setModal({
      ...modal,
      isOpen: true,
    });
    dialog.open();
  };

  const closeModal = () => {
    setModal(initialState);
    dialog.close();
  };

  return (
    <div>
      <div ref={forwardRef} onClick={openModal}>
        {children}
      </div>

      <Dialog
        title="Más información"
        forwardRef={dialog.refDialog}
        closeCallback={closeModal}
      >
        {!modal.isOpen ? null : <CustomerInfo idCustomer={idCustomer} />}
      </Dialog>
    </div>
  );

  // return (
  //   <ModalV2
  //     title="Más información"
  //     idOpenModal={idModalOpen}
  //     cbOpen={openModal}
  //     showCancelButton={false}
  //     cbReject={closeModal}
  //   >
  //     {!modal.isOpen ? null : <CustomerInfo idCustomer={idCustomer} />}
  //   </ModalV2>
  // );
};
