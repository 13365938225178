import React, { useEffect } from "react";
import sweeta2 from 'sweetalert2';
import axios from 'axios';
import {URL_BASE,URL_CLIENT} from '../../../routes/routes';

import "./ConfirmationTempPass.scss";

function ConfirmationTempPass() {

    const url = window.location.href;
    const user = url.split("/");

    useEffect(async()=>{
        
        try {
            const response = await axios.get(`${URL_BASE}usuarios/olvide-password/temporal-pass/${user[4]}`);

            if(response.data.status===200){
                sweeta2.fire({
                    title:response.data.message,
                    icon:'success',
                    allowOutsideClick:false,
                    allowEscapeKey:false,
                    allowEnterKey:false
                }).then(button=>{
                    if(button.isConfirmed){
                        window.location.href = `${URL_CLIENT}`;
                    }
                });
                return;
            }

            sweeta2.fire({
                title:'Vuelve a presionar "Si" en el correo recibido',
                icon:'error'
            });

          } catch (error) {
            console.log(error);
          }
    },[])

  return (
    <div id="referenceURL" className="temp-password">
    </div>
  );
}

export default ConfirmationTempPass;
