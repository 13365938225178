import { useLocation } from "react-router-dom";

export default function useQueryParams() {
  const query = useLocation();

  const urlQueryParams = query.search.substring(1, query.search.length);

  const splitedParams = urlQueryParams.split("&");

  const paramsParsed = splitedParams.reduce((indexed, param) => {
    const [key, value] = param.split("=");

    return {
      ...indexed,
      [key]: value,
    };
  }, {});

  return paramsParsed;
}
