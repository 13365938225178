import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useDirectoryV2, {
  CONTEXT_DIRECTORYV2,
} from "customHooks/useDirectoryV2";
import React, { createContext } from "react";
import style from "./styles.module.scss";
import FilterDirectory from "./FilterDirectory";
import TableDirectory from "./TableDirectory";
import OptionsDirectory from "./OptionsDirectory";

/**
 * @type {import("react").Context<import("customHooks/useDirectoryV2/types").ContextDirectory>}
 */
export const DirectoryContext = createContext(CONTEXT_DIRECTORYV2);

export default function Directory() {
  const hook = useDirectoryV2();

  return (
    <div className={style.directory}>
      <DirectoryContext.Provider value={hook}>
        <div className={style.headerDirectory}>
          <BreadCrumV2 path={hook.breadcrum} />
          <FilterDirectory />
        </div>

        <OptionsDirectory />
        <TableDirectory />
      </DirectoryContext.Provider>
    </div>
  );
}
