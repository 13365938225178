import { Select } from "components/individual/inputs/Inputs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { IvasContext } from "..";

export const Options = () => {
  const { iva, updateIva, canUseIva0, canUseIva8, isLoading } =
    useContext(IvasContext);

  const [ivas, setIvas] = useState([
    {
      label: "16%",
      value: 16,
    },
  ]);

  useEffect(() => {
    if (isLoading) return;

    const options = [];

    if (canUseIva0)
      options.push({
        label: "0%",
        value: 0,
      });

    if (canUseIva8)
      options.push({
        label: "8%",
        value: 8,
      });

    setIvas([...ivas, ...options]);
  }, [isLoading]);

  if (ivas.length <= 1)
    return (
      <>
        <p>16%</p>
      </>
    );

  return (
    <Select
      value={iva}
      options={ivas}
      label=""
      textJSON="label"
      valueJSON="value"
      initialOption="Selecciona un IVA"
      disableInitlaOption
      onChange={(iva) => updateIva(+iva)}
    />
  );
};
