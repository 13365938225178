import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextOldResidues } from "..";
import Money from "components/general/Forms/Money";

export default function TcReport() {
  const hook = useContext(ContextOldResidues);

  return (
    <div className={hook.currencyToReport==="Todos" ? 'd-block' : 'd-none'}>
      <Label required={hook.tc <= 0}>TC</Label>
      <Money maxDecimals={4} value={hook.tc} onChange={hook.setTc} />
    </div>
  );
}
