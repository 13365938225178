import React, { useContext } from "react";
import { Label } from "structure/Document";
import ToggleComponent from "components/individual/inputs/Toggle";
import { IncomingContext } from "..";

export default function SwitchProcess() {
  const hook = useContext(IncomingContext);

  if(!hook.isForAccounting) return (
    <div>
      <Label>Solo en proceso</Label>
      <ToggleComponent
        isChecked={hook.justOnProcess}
        labelActive="Si"
        onChange={(check) => hook.updateJustOnProcess(check)}
        labelInactive="No"
      />
    </div>
  );
}
