import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { mnCurrency } from '../../../helpers/money';

export default function QueueMovements({ movements, setMovements }) {

    const deleteMove = (id) => {

        let copyMovements = [...movements];

        const newMoves = copyMovements.filter(move => move.id !== id);

        setMovements(newMoves);
        document.getElementById('importConcept').value = '';
    }

    const iconDelete = <FontAwesomeIcon
        icon={faTimesCircle}
        className='cursorPointer'

        size="2x" />

    return (
        <div className="container">
            {movements.map(move => {
                return (
                    <div key={move.id} id={move.id} className="row shadowBackground py-3 mb-4">
                        <div className="col-2">
                            <b>Fecha</b>
                            <p>{move.uiDate}</p>
                        </div>

                        <div className="col-2">
                            <b>Referencia</b>
                            <p>{move.reference}</p>
                        </div>

                        <div className="col-4">
                            <b>Concepto</b>
                            <p>{move.concept}</p>
                        </div>

                        <div className="col-2">
                            <b>Importe</b>
                            <p>{mnCurrency(move.importConcept)}</p>
                        </div>

                        <div className="col-2 d-flex justify-content-end">
                            <div className="cursorPointer d-inline-block" onClick={(e) => deleteMove(move.id)}>
                                {iconDelete}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}