import Icon from "components/individual/HeaderIcon";
import React from "react";

/**
 * Render a text with an icon
 * @param {import("../types").TextWithIconPropsI} props - Props
 * @returns {JSX.Element}
 */
export default function TextIcon(props) {
  return (
    <span>
      <Icon {...props} style={{ margin: "0 0.3rem 0 0" }} />
      <span>{props.text}</span>
    </span>
  );
}
