import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { Icon } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { mnCurrency } from "helpers/money";

export const Info = <FontAwesomeIcon icon={faInfoCircle} />;

export default function WarningMargin({ utility = 1 }) {
  return utility > 0 ? null : (
    <Tooltip
      placement="top"
      trigger={["hover"]}
      overlay={<span>Márgen de {mnCurrency(utility)}</span>}
    >
      <Icon color="#c48a00">{Info}</Icon>
    </Tooltip>
  );
}
