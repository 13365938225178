import { useReducer } from "react";
import WinQuoteReducer from "./WinQuoteReducer";
import WinQuoteContext from "./WinQuoteContext";

import {
  SET_QUOTE_INFO,
  SET_QUOTE_COMMENTS,
  SET_QUOTE_DOC_ITEMS,
  SET_PROVIDER,
  SET_INVOICE_CREDIT_DAYS,
  SET_NO_CONTRACT,
  RESET_TEMP_IMPORT,
  RESET_TEMP_ITEMS,
  SET_OC_COMMENTS,
  SET_PREINVOICE_COMMENTS,
  RESET_COMMENTS_WINQUOTE,
  SET_IVA_INFO,
  SET_IMPORT_PRICE,
  SET_CONTRACT_COMMENTS,
  SET_LOAD_COSTS,
  SET_COST,
  SET_GENERATE_CONTRACT,
  SET_COST_ERRORS,
  RESET_STATE_WIN,
  SET_COSTS_WIN_QUOTE,
  SET_ARE_MIXED_CURRENCIES,
} from "../../types/index";

// DOCUMENTATION
import "../../types/typedef/documents";
import "../../types/typedef/winQuote";

const WinQuoteState = (props) => {
  /**
   * @type {WinQuote}
   */
  const initialState = {
    quoteInfo: null,
    quoteComments: [],
    quoteDocItems: [],
    ocComments: [],
    contractComments: [],
    iva: {
      number: 0,
      text: "",
    },
    generateContract: false,
    preInvoiceComments: [],
    provider: null,
    providerCreditDays: null,
    invoiceCreditDays: null,
    tempImport: null,
    tempDocItems: null,
    costsItems: null,
    copyCostsItems: null,
    areMixedCurrencys: false,
    areCostErrors: false,
    costs: {
      iva: {
        number: 0,
        text: "$0.0",
      },
      import: {
        number: 0,
        text: "$0.0",
      },
      total: {
        number: 0,
        text: "$0.0",
      },
    },
  };

  const [state, dispatch] = useReducer(WinQuoteReducer, initialState);

  /**
   * Set state - costs
   * @param {object} costs - Set the total costs calculated on OC > Costos
   */
  const setCosts = (costs) => {
    dispatch({
      type: SET_COSTS_WIN_QUOTE,
      payload: costs,
    });
  };

  /**
   * Set the state context of the quote information
   *
   * @param {WinQuoteInfo} quote
   */
  const setQuoteInfo = (quote) => {
    dispatch({
      type: SET_QUOTE_INFO,
      payload: quote,
    });
  };

  /**
   * Set the state context of the quote comments
   *
   * @param {Comments[]} comments - Comments to display on UI
   */
  const setQuoteComments = (comments) => {
    dispatch({
      type: SET_QUOTE_COMMENTS,
      payload: comments,
    });
  };

  /**
   * Set the state context of the quote document items
   *
   * @param {apiDocItems} items - Items to display on UI
   */
  const setQuoteDocItems = (items) => {
    dispatch({
      type: SET_QUOTE_DOC_ITEMS,
      payload: items,
    });
  };

  /**
   * Set the state context of the provider
   *
   * @param {object} provider - ID of the provider
   */
  const setProvider = (provider) => {
    dispatch({
      type: SET_PROVIDER,
      payload: provider,
    });
  };

  /**
   * Set the state context of the invoiceCreditDays
   *
   * @param {number} days - Credit days
   */
  const setInvoiceCreditDays = (days) => {
    dispatch({
      type: SET_INVOICE_CREDIT_DAYS,
      payload: days,
    });
  };

  /**
   * Set the state context of the quoteinfo['idContract']
   * @param {number} contract - ID of the contract to use
   */
  const setNoContract = (contract) => {
    dispatch({
      type: SET_NO_CONTRACT,
      payload: contract,
    });
  };

  /**
   * Reset the temporal state to show the error of quote
   * with a total import of $0
   */
  const resetTempImport = () => {
    dispatch({
      type: RESET_TEMP_IMPORT,
    });
  };

  /**
   * Reset the temporal state to evaluate if exists
   * an item with an import of $0
   */
  const resetTempItems = () => {
    dispatch({
      type: RESET_TEMP_ITEMS,
    });
  };

  /**
   * Set the state context of the state ocComments
   *
   * @param {Comments[]} comments - New comments
   */
  const setOcComments = (comments) => {
    dispatch({
      type: SET_OC_COMMENTS,
      payload: comments,
    });
  };

  /**
   * Set the state context of the state preInvoiceComments
   *
   * @param {Comments[]} comments - New comments
   */
  const setPreInvoiceComments = (comments) => {
    dispatch({
      type: SET_PREINVOICE_COMMENTS,
      payload: comments,
    });
  };

  /**
   * Set the state context of the state preInvoiceComments
   * and ocComments to empty
   */
  const setResetComments = () => {
    dispatch({
      type: RESET_COMMENTS_WINQUOTE,
    });
  };

  /**
   * Set the state context of the state iva
   * @param {*} ivaInfo - Iva information
   */
  const setIva = (ivaInfo) => {
    dispatch({
      type: SET_IVA_INFO,
      payload: ivaInfo,
    });
  };

  /**
   * Set the state context of the state quoteInfo.importPrice
   * @param {object} importPrice
   */
  const setImportPrice = (importPrice) => {
    dispatch({
      type: SET_IMPORT_PRICE,
      payload: importPrice,
    });
  };

  /**
   * Set the state context of the state contractComments
   *
   * @param {Comments[]} comments - New comments
   */
  const setContractComments = (comments) => {
    dispatch({
      type: SET_CONTRACT_COMMENTS,
      payload: comments,
    });
  };

  /**
   * When the page loads, set the state of the indexed costs
   *
   * @param {object} costs - Indexed costs
   */
  const setLoadCost = (costs) => {
    dispatch({
      type: SET_LOAD_COSTS,
      payload: costs,
    });
  };

  /**
   * Update the indexed value when the cost of a row changes
   *
   * @param {DocumentItem} cost
   */
  const setCostItems = (costs) => {
    dispatch({
      type: SET_COST,
      payload: costs,
    });
  };

  /**
   * Update the property generateContract
   *
   * @param {boolean} boolean - True if it's necesary generate a contract
   */
  const setGenerateContract = (boolean) => {
    dispatch({
      type: SET_GENERATE_CONTRACT,
      payload: boolean,
    });
  };

  /**
   * Update the property areCostErrors
   * @param {boolean} boolean - True if a cost it's greater than unit price sell
   */
  const setCostErrors = (boolean) => {
    dispatch({
      type: SET_COST_ERRORS,
      payload: boolean,
    });
  };

  /**
   * Reset the state
   */
  const setResetState = () => {
    dispatch({
      type: RESET_STATE_WIN,
    });
  };

  /**
   * Set the state of areMixedCurrencies
   * @param {boolean} boolean - True if items contain mixed currencies
   */
  const setAreMixedCurrencies = (boolean) => {
    dispatch({
      type: SET_ARE_MIXED_CURRENCIES,
      payload: boolean,
    });
  };

  /**
   * @type {WinQuote}
   */
  const values = {
    quoteInfo: state.quoteInfo,
    quoteComments: state.quoteComments,
    quoteDocItems: state.quoteDocItems,
    provider: state.provider,
    providerCreditDays: state.providerCreditDays,
    invoiceCreditDays: state.invoiceCreditDays,
    tempImport: state.tempImport,
    tempDocItems: state.tempDocItems,
    ocComments: state.ocComments,
    preInvoiceComments: state.preInvoiceComments,
    iva: state.iva,
    contractComments: state.contractComments,
    costsItems: state.costsItems,
    copyCostsItems: state.copyCostsItems,
    generateContract: state.generateContract,
    areCostErrors: state.areCostErrors,
    areMixedCurrencys: state.areMixedCurrencys,
    costs: state.costs,
    setResetState,
    setCostErrors,
    setQuoteInfo,
    setQuoteComments,
    setQuoteDocItems,
    setProvider,
    setInvoiceCreditDays,
    setNoContract,
    resetTempImport,
    resetTempItems,
    setOcComments,
    setPreInvoiceComments,
    setResetComments,
    setIva,
    setImportPrice,
    setContractComments,
    setLoadCost,
    setCostItems,
    setGenerateContract,
    setCosts,
    setAreMixedCurrencies,
  };

  return (
    <WinQuoteContext.Provider value={values}>
      {props.children}
    </WinQuoteContext.Provider>
  );
};

export default WinQuoteState;
