import React, { useContext } from "react";
import { IncomingContext } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import BankContext from "context/Bank/BankContext";

export default function SearchAccounting() {
  const hook = useContext(IncomingContext);

  const bank = useContext(BankContext);

  return (
    <ButtonV2 onClick={hook.refetchData} disabled={bank.account === null}>
      <span>Buscar</span>
      <Icon icon="search" />
    </ButtonV2>
  );
}
