import { useEffect, useState, useRef } from "react";
import {
  addNoteConsideration,
  updateNoteConsideration,
} from "helpers/Apis/parameters";
import { Success } from "helpers/alerts";

/**
 * State of custom hook
 * @type {import("types/typedef/customHooks/useNotesAndAclarations").StateI}
 */
const initialState = {
  document: [],
  currency: null,
  uen: null,
  content: null,
  is: {
    deletable: false,
    editable: false,
    active: false,
  },
  isLoading: true,
  isPerformingQuery: false,
};

/**
 * Handle the aclarations
 * @param {number} idAclaration - Id of the aclaration to edit
 */
export default function useNotesAndAclarations(
  idAclaration = null,
  notes = [],
  onSuccessQuery = () => {},
  initialType = 1
) {
  const [state, setState] = useState(initialState);

  const idModalCloseIcon = "closeNotesAndConsiderationsModal";

  const textAreaDom = useRef(null);

  useEffect(() => {
    (async function () {
      if (!idAclaration) {
        setState({
          ...initialState,
          isLoading: false,
          type: initialType,
        });
        return;
      }

      if (notes.length <= 0) return;

      const note = notes.find((note) => note.id === idAclaration);
      setState({
        ...initialState,
        isLoading: false,
        type: note.type,
        document: note.docType,
        currency: note.currency === null ? "NA" : note.currency,
        uen: note.uen,
        content: note.content,
        is: {
          deletable: !!note.is.deletable,
          editable: !!note.is.editable,
          active: true,
        },
      });
    })();
  }, [idAclaration, initialType]);

  const setPerformingQuery = () =>
    setState({
      ...state,
      isPerformingQuery: true,
    });

  const updateNoteAclaration = async () => {
    setPerformingQuery();

    /**
     * @type {import("types/typedef/customHooks/useNotesAndAclarations").DtoAddCondition}
     */
    const dto = {
      type: state.type,
      content: state.content,
      id: idAclaration,
      document: state.document,
      moneda: state.currency === "NA" ? null : state.currency,
      is: {
        active: true,
        deletable: state.is.deletable,
        editable: state.is.editable,
      },
      uen: state.uen,
    };

    const { message, wasSuccess } = await updateNoteConsideration(dto);

    if (wasSuccess) {
      onSuccessQuery();
      Success(() => {}, message);
      closeModal();
      setState({
        ...state,
        isLoading: false,
      });
    } else {
      setState({
        ...state,
        isPerformingQuery: false,
      });
    }
  };

  const closeModal = () => {
    const domIcon = document.getElementById(idModalCloseIcon);

    if (domIcon) {
      domIcon.click();
    }
  };

  const addNoteAclaration = async () => {
    setPerformingQuery();

    /**
     * @type {import("types/typedef/customHooks/useNotesAndAclarations").DtoAddCondition}
     */
    const dto = {
      type: state.type,
      content: state.content,
      document: state.document,
      moneda: state.currency === "NA" ? null : state.currency,
      is: {
        active: true,
        deletable: state.is.deletable,
        editable: state.is.editable,
      },
      uen: state.uen,
    };

    const { message, wasSuccess } = await addNoteConsideration(dto);

    if (wasSuccess) {
      onSuccessQuery();
      Success(() => {}, message);
      setState({
        ...initialState,
        isLoading: false,
      });
      closeModal();
    } else {
      setState({
        ...state,
        isPerformingQuery: false,
      });
    }
  };

  /**
   * Update the value of the toggle check status
   * @param {boolean} isActive - Boolean that indicates if toggle is active
   * @returns {void}
   */
  const handleDeletable = (isActive) =>
    setState({
      ...state,
      is: {
        ...state.is,
        deletable: isActive,
      },
    });

  /**
   * Update the type of document to use
   * @param {number} type - Id of the type document
   * @returns {void}
   */
  const handleTypeNote = (type) =>
    setState({
      ...state,
      type,
    });

  /**
   * Update the value of the toggle check status
   * @param {boolean} isActive - Boolean that indicates if toggle is active
   * @returns {void}
   */
  const handleEditable = (isActive) =>
    setState({
      ...state,
      is: {
        ...state.is,
        editable: isActive,
      },
    });

  /**
   * Update the content for the note/aclaration
   * @param {string} content - Content of text area
   * @returns {void}
   */
  const handleContent = (content) =>
    setState({
      ...state,
      content: content.length > 0 ? content : null,
    });

  /**
   * Set the uen used for this case
   * @param {undefined|import("types/typedef/catalogue").UenI} uen - Id of the uen to apply this note
   * @returns {void}
   */
  const handleUen = (uen) =>
    setState({
      ...state,
      uen: uen === undefined ? null : uen.UENID,
    });

  /**
   * Update the documents that will appear the note/aclaration
   * @param {number[]} documents - Id of the documents that will appear
   * @returns {void}
   */
  const handleCheckbox = (documents) =>
    setState({
      ...state,
      document: documents,
    });

  /**
   * Update the currency to use for the aclaration
   * @param {string|null} currency - Currency used for the note/aclaration
   * @returns {void}
   */
  const handleCurrency = (currency) => {
    setState({
      ...state,
      currency,
    });
  };

  return {
    handleContent,
    handleDeletable,
    handleEditable,
    handleUen,
    type: state.type,
    document: state.document,
    currency: state.currency,
    uen: state.uen,
    is: state.is,
    isLoading: state.isLoading,
    addNoteAclaration,
    handleTypeNote,
    handleCheckbox,
    idModalCloseIcon,
    isPerformingQuery: state.isPerformingQuery,
    handleCurrency,
    textAreaDom,
    content: state.content,
    updateNoteAclaration,
    documentJsx:
      state.document.length <= 0 ? (
        <p className="text-danger">🔴 Al menos un documento</p>
      ) : null,
    contentJsx:
      state.content === null ? (
        <p className="text-danger">🔴 Descripcion necesaria</p>
      ) : null,
    currencyJsx:
      state.currency === null ? (
        <p className="text-danger">🔴 Moneda requerida</p>
      ) : null,
    isValidForm:
      state.currency === null ||
      state.content === null ||
      state.document.length <= 0
        ? false
        : true,
  };
}
