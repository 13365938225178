import React, { useRef, useContext, Fragment } from "react";
import { IncomingContext } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import MovementRow from "../MovementRow";
import { Pagination } from "components/individual/Pagination";
import scss from "../styles.module.scss";
import LoadingText from "components/individual/TextLoading";
import accounting from "./styles.module.scss";
import MoreInfoMovement from "structure/MoreInfoMovement";
import { mnCurrency } from "helpers/money";
import { parseDateToText } from "helpers/dates";
import { BankHeader } from "components/general/BankHeader";
import Concepts from "./Concepts";
import ModalOverviewMovement from "structure/ModalOverviewMovement";

export default function Table() {
  const hook = useContext(IncomingContext);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isFetchingData)
    return (
      <Spinner hidden={false} text={<LoadingText>Cargando</LoadingText>} />
    );

  if (!hook.isFetchingData && hook.movements.length <= 0)
    return (
      <h1 className="text-center my-3">
        No hay movimientos con los filtros solicitados
      </h1>
    );

  if (hook.isForAccounting)
    return (
      <div className={accounting.table}>
        <div className={accounting.header}>
          <p>Fecha</p>
          <p>No. {hook.type === "egreso" ? "egreso" : "ingreso"}</p>
          <p>Razón social</p>
          <p>Concepto</p>
          <p>Moneda</p>
          <p>Total</p>
          <p>Contabilizado</p>
        </div>

        <BankHeader>
          {(bank) => (
            <div className={scss.containerRows}>
              {hook.movements.map((movement, i) => (
                <Fragment key={`${key.current}-container-${i}`}>
                  <input
                    type="radio"
                    name="incomings"
                    className="d-none"
                    id={`incoming-${movement.id}`}
                    onChange={() => hook.selectMovement(movement)}
                  />
                  <label
                    htmlFor={`incoming-${movement.id}`}
                    className={accounting.row}
                    key={`${key.current}-${i}`}
                  >
                    <p className="justify-content-center">
                      {parseDateToText(new Date(`${movement.date}:`))}
                    </p>

                    {/* <ModalOverviewMovement idMovement={movement.id}>
                    <p>{movement.folio}</p>
                    </ModalOverviewMovement> */}

                    <MoreInfoMovement id={movement.id} isForCancel={false}>
                      <p
                        style={{ height: "100%", textDecoration: "underline" }}
                        className="d-flex align-items-center justify-content-center m-0"
                      >
                        {movement.folio}
                      </p>
                    </MoreInfoMovement>
                    <p>{movement.customer.socialReason || "NA"}</p>
                    <Concepts concepts={movement.concepts} />
                    <p className="justify-content-center">
                      {bank?.account?.currency}
                    </p>
                    <p className="justify-content-end">
                      {mnCurrency(movement.import)}
                    </p>
                    <label className="d-flex justify-content-center align-items-center">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          hook.toggleAccountingCheck(movement.id)
                        }
                        checked={hook.accountingGrid.operation[movement.id]}
                      />
                    </label>
                  </label>
                </Fragment>
              ))}
            </div>
          )}
        </BankHeader>

        <Pagination
          actualPage={hook.pagination.currentPage}
          pages={hook.pagination.pages}
          onChange={hook.updatePage}
        />
      </div>
    );

  return (
    <div className={scss.containerTable}>
      <div className={scss.header}>
        <p>ID</p>
        <p>Fecha</p>
        <p>Importe</p>
        <p>
          {hook.type === "ingreso"
            ? "Cliente / Beneficiario"
            : "Proveedor / Beneficiario"}
        </p>

        <p>Estatus</p>
      </div>
      <div className={scss.containerRows}>
        {hook.movements.map((movement, i) => (
          <MovementRow key={`${key.current}-${i}`} {...movement} />
        ))}
      </div>
      <Pagination
        actualPage={hook.pagination.currentPage}
        pages={hook.pagination.pages}
        onChange={hook.updatePage}
      />
    </div>
  );
}
