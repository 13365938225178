import React, { createContext, useEffect } from "react";
import { Label } from "structure/Document";
import ui from "structure/SatCode/styles.module.scss";
import DialogSatCode from "./DialogSatCode";
import useSatUm from "customHooks/useSatUm";

/**
 * @type {import("react").Context<import("customHooks/useSatUm/types").ReturnUseSatUm>}
 */
export const ContextSatCode = createContext(undefined);

/**
 * Render a input field to fill the sat code for the item product
 * @param {import("./types").PropsSatCode} props
 */
export default function SatUm({
  description = "",
  name = "",
  onChange = () => {},
}) {
  const hook = useSatUm(description, name);

  // useEffect(() => {
  //   onChange(hook.itemSelected || { Name: "", Value: "", ShortName: "" });
  // }, [hook.itemSelected]);

  return (
    <ContextSatCode.Provider value={{...hook,onChange}}>
      <div className={ui.satCodeContainer}>
        <Label required={hook.itemSelected === undefined}>UM SAT</Label>
        <div className={ui.containerField}>
          <p className="m-0">
            {hook.itemSelected === undefined
              ? "Selecciona una UEN o presiona el botón"
              : `${hook.itemSelected.Value} - ${hook.itemSelected.Name}`}
          </p>

          <button
            onClick={() => hook.handleOpenModal(true)}
            type="button"
            className={ui.modalButton}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </div>
      </div>

      <DialogSatCode />
    </ContextSatCode.Provider>
  );
}
