import React, { useEffect, useState } from "react";

import style from "../styles.module.scss";

// APIS
import { GetCustomer, getIVAS } from "../../../../helpers/Apis/Directory";
import TypeCustomerDirectory from "components/individual/TypeCustomerDirectory";

// LIBS
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemeSupplier } from "../Form/Schemas";
import styled from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {
  InputRfc,
  RFC,
  LabelRfc,
  CountryRfc,
  LabelCountryRfc,
} from "components/forms/RFC";

// COMPONENTS
import {
  ContainerContact,
  ContainerCorporative,
  ContainerFiscal,
  ContainerIds,
  ContainerNames,
  ContainerTypeCustomers,
} from "../Inputs/Styles";

import {
  AdressNumber,
  CellNumber,
  City,
  Colony,
  CommercialName,
  Corporative,
  CreditDays,
  Email,
  GenerateCxp,
  PhoneNumber,
  ShortName,
  SocialReason,
  State,
  Street,
  TypeCustomer,
} from "../Inputs/Inputs";
import Executives from "../../../../components/general/Forms/Selects/Executives";
import ModalCP from "../Add/ModalCP";
import { BankInputs } from "../Add/BankInputs";

import useAddCustomer from "../../../../customHooks/useAddCustomer";
import { Spinner } from "../../../../components/individual/Spinner/Spinner";
import { Informative } from "../../Documents/Inputs/General/Index";
import { createIndexedList } from "../../../../helpers/lists";
import RegimenFiscal from "components/individual/RegimenFiscal";
import { Label } from "structure/Document";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import AssociateCorporative from "structure/AssociateCorporative";
import Manufacturers from "structure/Manufacturers";
import Icon from "components/individual/HeaderIcon";

const ContainerSpinner = styled.div`
  #spinnerCustomer {
    display: block !important;
  }
`;

export function EditCustomer() {
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schemeSupplier),
  });

  const { customerId } = useParams();

  const [ivas, setIvas] = useState([]);

  const [customer, setCustomer] = useState(null);

  const [isValidRfc, setIsValidRfc] = useState(false);

  const [customerTypes, setCustomerTypes] = useState(null);

  const updateCustomerTypes = (customers) => {
    const indexedCustomers = createIndexedList(customers, "value");
    setCustomerTypes(indexedCustomers);
  };

  const [copyCustomer, setCopyCustomer] = useState(null);

  const { updateCustomer, isSaving, setLocationInfo } =
    useAddCustomer(copyCustomer);

  useEffect(() => {
    (async function () {
      const apiCustomer = await GetCustomer(customerId);
      const apiIvas = await getIVAS();

      apiIvas.unshift({
        percentage: apiCustomer.iva,
        percentage_text: `${apiCustomer.iva}%`,
      });

      setIvas(apiIvas);

      setCustomer(apiCustomer);
      setCopyCustomer(apiCustomer);
      setIsValidRfc(true);

      setValue("idRelationship", apiCustomer.provider.key);
    })();
  }, []);

  const disasociateCorporative = () => {
    setCopyCustomer((current) => ({
      ...current,
      corporative: {
        id: null,
      },
    }));
  };

  /**
   * Update the rfc typed
   * @param {boolean} isValid - Is valid
   * @param {string} rfc - RFC
   * @param {string} country - Country
   */
  const updateRfc = (isValid, rfc, country) => {
    setCopyCustomer((current) => ({
      ...current,
      adress: {
        ...current.adress,
        country,
      },
      rfc,
    }));

    setIsValidRfc(isValid);
  };

  /**
   * Update if the provider generates CxP
   * @param {boolean} generateCxp 
   * @returns {void}
   */
  const setGenerateCxp = generateCxp => setCopyCustomer(current=>({
    ...current,
    generateCxp
  }));

  const updateCorporative = (corporative) => {
    setCopyCustomer((current) => ({
      ...current,
      corporative: {
        id: corporative["ID_cliente"],
        shortName: corporative["Nombre_corto"],
      },
    }));
  };

  /**
   * Update the fiscal regime
   * @param {string} fiscalRegime - Update the fiscal regime
   * @returns {void}
   */
  const updateFiscalRegime = (fiscalRegime) =>
    setCopyCustomer((current) => ({
      ...current,
      fiscalRegime,
    }));

  const updateStatusCustomer = () => {
    const newStatus = !copyCustomer.status.isActive;

    setCopyCustomer((current) => ({
      ...current,
      status: {
        isActive: newStatus,
        parsed: newStatus === true ? "Activo" : "Inactivo",
      },
    }));
  };

  const updateType = (type) => {
    setCopyCustomer((current) => ({
      ...current,
      type: {
        id: type.value,
        description: type.text,
      },
    }));
  };

  const setTypeOfCustomer = (typeOfCustomer) => {
    setCopyCustomer((current) => ({
      ...current,
      typeOfCustomer,
    }));
  };

  if (customer === null || copyCustomer === null) return <></>;

  return (
    <>
      <div className={style.abcDirectory}>
        {/* <AssociateCorporative onChange={(info) => updateCorporative(info)} /> */}

        <RFC
          value={copyCustomer.rfc}
          country={copyCustomer.adress.country}
          onChange={(value) =>
            updateRfc(value.isValid, value.rfc, value.country)
          }
        >
          {() => (
            <form
              id="updateCustomer"
              onSubmit={handleSubmit(updateCustomer, (e) => console.log(e))}
            >
              <>
                <div className={style.abcOptionsDirectory}>
                  {isSaving === true ? (
                    <ContainerSpinner>
                      <Spinner
                        idSpinner="spinnerCustomer"
                        text="Actualizando"
                      />
                    </ContainerSpinner>
                  ) : (
                    <>
                      {copyCustomer.type.id === 1 ? (
                        <Manufacturers id={copyCustomer.id}>
                          <ButtonV2 type="button">
                            <span>Fabricante</span>
                            <Icon icon="tools"/>
                            </ButtonV2>
                          
                        </Manufacturers>
                      ) : null}

                      {copyCustomer.corporative.id !== null ? (
                        <ButtonV2 onClick={() => disasociateCorporative()}>
                          <span>Des-asociar</span>
                          <Icon icon="building"/>

                        </ButtonV2>
                      ) : (
                        <AssociateCorporative
                          onChange={(info) => updateCorporative(info)}
                        >
                          <ButtonV2 type="button">
                            <span>Asociar corporativo</span>
                            <Icon icon="building"/>
                          </ButtonV2>
                        </AssociateCorporative>
                      )}

                      {copyCustomer.fiscalRegime === "" ||
                      !isValidRfc ? null : (
                        <ButtonV2 type="submit" form="updateCustomer">
                          <span>Actualizar</span>
                          <Icon icon="save"/>
                        </ButtonV2>
                      )}
                    </>
                  )}
                </div>

                <ContainerTypeCustomers>
                  <TypeCustomer
                    forwardRef={register}
                    optSelected={copyCustomer.type.id}
                    onClick={(value) => updateType(value)}
                    disabled={true}
                    onLoaded={(options) => {
                      updateCustomerTypes(options);
                    }}
                  />
                </ContainerTypeCustomers>

                <ContainerNames>
                  <SocialReason
                    errors={errors}
                    forwardRef={register}
                    value={customer.socialReason}
                  />

                  <CommercialName
                    errors={errors}
                    forwardRef={register}
                    value={customer.name.commercial}
                  />
                  <ShortName
                    errors={errors}
                    forwardRef={register}
                    value={customer.name.short}
                  />

                  <div className="type">
                    <TypeCustomerDirectory
                      type={
                        copyCustomer.type.id === 1 ? "cliente" : "proveedor"
                      }
                      onChange={setTypeOfCustomer}
                      value={customer.typeOfCustomer.id}
                    />
                  </div>
                </ContainerNames>

                <ContainerIds>
                  {customerTypes !== null ? (
                    <>
                      <Informative
                        id="idCustomer"
                        name="idCustomer"
                        readonly={true}
                        css='d-none'
                        value={copyCustomer.id}
                        label={`ID ${
                          customerTypes[copyCustomer.type.id]["text"]
                        }`}
                      />

                      <div className="d-flex flex-column">
                        <Label>Régimen fiscal</Label>
                        <RegimenFiscal
                          value={customer.fiscalRegime}
                          onChange={(fiscal) =>
                            updateFiscalRegime(fiscal.value)
                          }
                        />
                      </div>

                      <Executives
                        errors={errors}
                        forwardRef={register}
                        onChange={(id) =>
                          setCustomer({
                            ...customer,
                            attendedBy: {
                              ...customer.attendedBy,
                              executive: {
                                ...customer.attendedBy.executive,
                                id: +id,
                              },
                            },
                          })
                        }
                        value={customer.attendedBy.executive.id}
                      />

                      <GenerateCxp
                        idTypeCustomer={customerTypes[copyCustomer.type.id]}
                        value={copyCustomer.generateCxp}
                        onChange={setGenerateCxp}
                      />

                    </>
                  ) : null}
                </ContainerIds>

                <h4 className="mt-3">Datos fiscales</h4>

                <ContainerFiscal>
                  {/* <Rfc forwardRef={register} value={customer.rfc} /> */}
                  <div className="rfc">
                    <LabelRfc />
                    <InputRfc placeholder="Escribe aquí" />
                  </div>
                  <Street
                    errors={errors}
                    forwardRef={register}
                    value={customer.adress.street}
                  />

                  <AdressNumber
                    errors={errors}
                    forwardRef={register}
                    value={customer.adress.extNumber}
                    css="noExt"
                    label="Número exterior *"
                    id="exteriorNumber"
                    name="exteriorNumber"
                  />

                  <AdressNumber
                    errors={errors}
                    forwardRef={register}
                    value={customer.adress.intNumber}
                    css="noInt"
                    label="Número interior"
                    id="insideNumber"
                    name="insideNumber"
                  />

                  <div className="cp">
                    <ModalCP
                      forwardRef={register}
                      errors={errors}
                      onClick={(colony) => setLocationInfo(colony)}
                      value={customer.adress.cp}
                      onChange={(cp) =>
                        setCustomer({
                          ...customer,
                          adress: {
                            ...customer.adress,
                            cp,
                          },
                        })
                      }
                      // onChange={cp=>}
                    />
                  </div>

                  <Colony
                    forwardRef={register}
                    errors={errors}
                    value={customer.adress.colony}
                  />

                  <City
                    forwardRef={register}
                    value={customer.adress.city}
                    errors={errors}
                  />

                  <State
                    forwardRef={register}
                    value={customer.adress.state}
                    errors={errors}
                  />

                  <div className="country">
                    <LabelCountryRfc />
                    <CountryRfc />
                  </div>

                  {/* <Country
                    labelCountry="Pais *"
                    idCountry="customerCountry"
                    countryValue={customer.adress.country}
                    nameCountry="customerCountry"
                    cssCountry="country"
                    forwardRef={register}
                    error={{}}
                  /> */}
                </ContainerFiscal>

                <ContainerContact>
                  <PhoneNumber
                    forwardRef={register}
                    value={
                      customer.contact.phone.lada === null
                        ? 52
                        : customer.contact.phone.parsed
                    }
                    lada={customer.contact.phone.lada || 52}
                    number={customer.contact.phone.number}
                  />

                  <CellNumber
                    forwardRef={register}
                    value={
                      customer.contact.movil.lada === null
                        ? 52
                        : customer.contact.movil.parsed
                    }
                    lada={customer.contact.movil.lada}
                    number={customer.contact.movil.number}
                  />

                  <Email
                    errors={errors}
                    forwardRef={register}
                    value={customer.contact.email}
                  />
                </ContainerContact>

                {copyCustomer.type.id === 2 || copyCustomer.type.id === 5 ? (
                  <div className="my-4">
                    <BankInputs
                      mnAccount={customer.mnBank.account}
                      mnAgreement={customer.mnBank.agreement}
                      mnCode={customer.mnBank.code}
                      mnId={customer.mnBank.id}
                      mnName={customer.mnBank.socialReason}
                      usdAccount={customer.usdBank.account}
                      usdCode={customer.usdBank.code}
                      usdAgreement={customer.usdBank.agreement}
                      errors={errors}
                      forwardRef={register}
                    />
                  </div>
                ) : null}

                <ContainerCorporative>
                  <CreditDays
                    forwardRef={register}
                    errors={errors}
                    value={customer.creditDays}
                  />

                  {/* <Select
                idSelect={"iva"}
                label="IVA *"
                nameSelect={"iva"}
                forwardRef={register}
                options={ivas}
                valueJSON="percentage"
                textJSON="percentage_text"
                addTodos={false}
              /> */}

                  <Corporative
                    forwardRef={register}
                    idCorporative={copyCustomer.corporative.id}
                    value={
                      copyCustomer?.corporative?.["Nombre_corto"] ||
                      copyCustomer?.corporative?.shortName ||
                      null
                    }
                  />
                </ContainerCorporative>

                <div className="d-flex align-items-end">
                  <div className="mr-5">
                    <p className=" mt-4">
                      <b>Estatus</b>
                    </p>
                    <label htmlFor="" className="d-flex align-items-center">
                      <Toggle
                        checked={copyCustomer.status.isActive}
                        onChange={updateStatusCustomer}
                      />
                      <span className="ml-2">{copyCustomer.status.parsed}</span>
                    </label>
                  </div>
                </div>
              </>
            </form>
          )}
        </RFC>
      </div>
    </>
  );
}
