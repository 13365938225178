import React, { useContext } from "react";
import Table from "components/general/Table";
import { ContextPropousal } from "..";
import { mnCurrency } from "helpers/money";

/**
 *
 * @param {import("react-data-table-component").TableProps<import("../../../../../../server/models/proyectProposal/types").PropousalRecordI>} props
 * @returns
 */
function TableParsed(props) {
  return <Table {...props} />;
}

export default function TablePropousal() {
  const hook = useContext(ContextPropousal);

  return (
    <TableParsed
      progressPending={hook.state === "loading"}
      onRowClicked={item=>hook.setPropousal(item)}
      selectableRowSelected={(item) => item.id === hook.propousal?.id}

      data={hook.data}
      columns={[
        {
          name: "No. propuesta",
          sortable: true,
          selector: (item) => item.proposalNumber,
        },
        {
          name: "Moneda",
          sortable: true,
          selector: (item) => 'ND',
        },
        {
          name: "Importe",
          sortable: true,
          selector: (item) => mnCurrency(item.subTotal),
        },
        {
          name: "IVA",
          sortable: true,
          selector: (item) => mnCurrency(item.iva),
        },
        {
          name: "Total",
          sortable: true,
          selector: (item) => mnCurrency(item.total),
        },
        {
          name: "Estatus",
          sortable: true,
          selector: (item) => 'ND',
        },
      ]}
    />
  );
}
