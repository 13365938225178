import { lastDayOfMonth } from "date-fns";
import { saveAs } from "file-saver";
import { ivaAccounting } from "helpers/Apis/concilation";
import { getFirstDayOfMonth } from "helpers/dates";
import { useState } from "react";

/**
 * @type {import("./types").StateUseIvasAccounting}
 */
const INITIAL_STATE = {
  from: getFirstDayOfMonth(new Date()),
  to: lastDayOfMonth(new Date()),
  isDownloading:false
};

/**
 * Hook to handle movements accounting
 * @returns {import("./types").ReturnUseIvasAccounting}
 */
export default function useIvasAccounting() {
  const [state, setState] = useState(INITIAL_STATE);

  const downloadExcel = async() => {
    setState(current=>({
        ...current,
        isDownloading:true
    }))

    const excel = await ivaAccounting({
        from:state.from,
        to:state.to
    });

    if(excel instanceof Blob){
        saveAs(excel,`Analisis de IVA.xlsx`);
    }

    setState(current=>({
        ...current,
        isDownloading:false
    }))
  }

  const setTo = to=> setState(current=>({
    ...current,
    to
  }));

  const setFrom = from=> setState(current=>({
    ...current,
    from
  }));

  return {
    ...state,
    setTo,
setFrom,
    downloadExcel,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/inicio",
        text: "Contablidad",
      },
      {
        route: "/inicio",
        text: "Reportes",
      },
      {
        route: "/inicio",
        text: "IVA",
      },
    ],
  };
}
