import React, { Fragment, useContext } from "react";
import { ContextReceiveCredtNotes } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "pages/Ventas/NotasDeCreditoEmitidas/styles.module.scss";
import { useRef } from "react";
import { parseDateToText } from "helpers/dates";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import { Pagination } from "components/individual/Pagination";
import { mnCurrency } from "helpers/money";

export default function Table() {
  const hook = useContext(ContextReceiveCredtNotes);
  const formId = useRef(window.crypto.randomUUID());

  if (hook.isSearching)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingCreditNotesReceived"
        text={<LoadingText>Buscando notas de crédito</LoadingText>}
      />
    );

  if (hook.creditNotes.length <= 0 && hook.search === "")
    return <h1 className="text-center my-3">No hay notas de crédito</h1>;

  if (hook.module === "contabilidad")
    return (
      <div className={style.tableAccounting}>
        <div className={style.accountingHeader}>
          <p>No</p>
          <p>Fecha de emisión</p>
          <p>Razón social</p>
          <p>Moneda</p>
          <p>Importe</p>
          <p>IVA</p>
          <p>Total</p>
          <p>Contabilizado</p>
        </div>

        {hook.creditNotes.map((cn, i) => (
          <Fragment key={`${formId.current}-${i}`}>
            <input
              type="radio"
              className="d-none"
              id={`${formId.current}-${i}`}
              onChange={(e) => hook.setCreditNote(cn)}
              name={formId.current}
            />
            <label
              className={style.accountingRow}
              htmlFor={`${formId.current}-${i}`}
            >
              <p>{cn.documentNumber}</p>
              <p className="text-center">{parseDateToText(cn.emited)}</p>
              <p>{cn.customer.socialReason}</p>
              <p className="text-center">{cn.currency}</p>
              <p className="text-right">{mnCurrency(cn.import)}</p>
              <p className="text-right">{mnCurrency(cn.iva)}</p>
              <p className="text-right">{cn.total.text}</p>
              <label
                htmlFor=""
                onChange={()=>hook.toggleAccounting(cn.id)}
                className="d-flex align-items-center justify-content-center"
              >
                <input type="checkbox" checked={hook.operation[cn.id]}/>
              </label>
            </label>
          </Fragment>
        ))}

        <Pagination
          actualPage={hook.currentPage}
          pages={hook.pages}
          onChange={hook.setPage}
        />
      </div>
    );

  return (
    <div className={style.creditNotesEmited}>
      <div className={style.header}>
        <p>Folio</p>

        <p>Razón social</p>
        <p>Registro</p>
        <p>Factura</p>
        <p>Moneda</p>
        <p>Total</p>
        <p>Estatus</p>
      </div>

      <div className={style.containerRows}>
        {hook.creditNotes.map((creditNote, i) => (
          <div className={style.containerRow} key={`${formId.current}-${i}`}>
            <input
              onChange={(e) => hook.setCreditNote(creditNote)}
              type="radio"
              name={formId.current}
              id={`${formId.current}-input-${i}`}
            />
            <label
              className={style.row}
              htmlFor={`${formId.current}-input-${i}`}
            >
              <ModalInvoiceReception uuid={creditNote.uuid}>
                {creditNote.documentNumber}
              </ModalInvoiceReception>
              <p>{creditNote.customer.socialReason}</p>
              <p>
                {parseDateToText(
                  new Date(`${creditNote.registro.yyyymmdd}:`),
                  "medium"
                )}
              </p>
              <p>{creditNote.documentNumber}</p>
              <p>{creditNote.currency}</p>
              <p>{creditNote.total.text}</p>
              <p>{creditNote.status.description}</p>
            </label>
          </div>
        ))}
      </div>

      <Pagination
        actualPage={hook.currentPage}
        pages={hook.pages}
        onChange={hook.setPage}
      />
    </div>
  );
}
