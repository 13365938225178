import React from "react";
import ui from "./styles.module.css";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

export default function InvalidLicense({ message = "" }) {
  return (
    <div className={ui.invalidLicense}>
      <div className="container p-5">
        <h1 className="text-center mb-5 text-justify">No se logró la conexión</h1>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{message}</ReactMarkdown>
      </div>
    </div>
  );
}
