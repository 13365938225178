import React, { Fragment, forwardRef, useRef } from "react";
import Dialog from "structure/Dialog";
import useMoreInfoInvoiceReception from "./useMoreInfoInvoiceReception";
import Details from "../Details";
import { Spinner } from "components/individual/Spinner/Spinner";
import scss from "./styles.module.scss";
import CardDocRelated from "structure/DocsRelatedV2/CardDocRelated";
import CustomerInfo from "structure/Customer";
import { getDateFromUtc, parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";

/**
 * @type {React.ForwardRefRenderFunction<HTMLInputElement,import("./types").PropsMoreInfoInvoiceReception>}
 */
const MoreInfoInvoiceReception = forwardRef((props, ref) => {
  const hook = useMoreInfoInvoiceReception(props.idLegalDocument, props.uuid);

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <Fragment>
      <div ref={ref} onClick={hook.openModal}>
        {props.children}
      </div>

      {hook.isOpen ? (
        <Dialog
          forwardRef={hook.dialog.refDialog}
          title={
            hook.invoice.isLoading
              ? "Cargando"
              : `Más informacion | Factura recibida ${hook.invoice.invoice.folio}`
          }
          closeCallback={hook.closeModal}
          footer={
            hook.ocs.associationsWithOc.length <= 0 && !hook.invoice.isLoading ? (
              <p className="font-weight-bold m-0 text-warning">
                🟡 Esta recepcion de factura no esta relacionada a ordenes de
                compra
              </p>
            ) : null
          }
        >
          <Fragment>
            {hook.isLoading || hook.invoice.isLoading ? (
              <Spinner text={"Cargando más información"} hidden={false} />
            ) : (
              <Fragment>
                <div className={scss.moreInfo}>
                  <Details
                    summary={`Factura recibida ${hook.invoice.invoice.folio}`}
                  >
                    <div>
                      <h2>{hook.invoice.invoice.association.description}</h2>

                      <div className={scss.info}>
                        <span>Recepcionado por</span>
                        <span>{hook.invoice.invoice.uploadedBy}</span>
                      </div>

                      <div className={scss.info}>
                        <span>Recepcionado el</span>
                        <span>
                          {new Intl.DateTimeFormat("es-MX", {
                            dateStyle: "long",
                            timeStyle: "medium",
                            hour12: true,
                          }).format(
                            getDateFromUtc(hook.invoice.invoice.uploadedDate)
                          )}
                        </span>
                      </div>

                      <div className={scss.info}>
                        <span>Razon social</span>

                        <span>{hook.invoice.invoice.emiter.socialReason}</span>
                      </div>
                      <div className={scss.info}>
                        <span>Folio</span>
                        <span>{hook.invoice.invoice.folio}</span>
                      </div>
                      <div className={scss.info}>
                        <span>Importe</span>
                        <span>
                          {hook.invoice.invoice.importe.text}
                          {hook.invoice.invoice.currency}
                        </span>
                      </div>
                      <div className={scss.info}>
                        <span>Descuento</span>
                        <span>
                          {hook.invoice.invoice.discount.text}
                          {hook.invoice.invoice.currency}
                        </span>
                      </div>
                      <div className={scss.info}>
                        <span>IVA</span>
                        <span>
                          {hook.invoice.invoice.iva.text}{" "}
                          {hook.invoice.invoice.currency}
                        </span>
                      </div>
                      <div className={scss.info}>
                        <span>Total</span>
                        <span>
                          {hook.invoice.invoice.total.text}{" "}
                          {hook.invoice.invoice.currency}
                        </span>
                      </div>

                      <div className={scss.info}>
                        <span>Saldo</span>
                        <span>
                          {mnCurrency(hook.invoice.invoice.saldo)}{" "}
                          {hook.invoice.invoice.currency}
                        </span>
                      </div>

                      <div className={scss.info}>
                        <span>Concepto(s)</span>
                        <span>
                          {hook.invoice.invoice.concepts.map(
                            (concept) =>
                              `[${concept.type.description}] ${concept.description}`
                          )}
                        </span>
                      </div>
                    </div>
                  </Details>

                  <Details summary="Proveedor">
                    <div className={scss.containerCustomer}>
                      {hook.invoice.invoice.emiter.id !== null &&
                      hook.invoice.invoice.emiter.id >= 1 ? (
                        <CustomerInfo
                          idCustomer={hook.invoice.invoice.emiter.id}
                        />
                      ) : (
                        <h2 className="text-center">
                          El proveedor no esta registrado en el directorio
                        </h2>
                      )}
                    </div>
                  </Details>

                  {hook.ocs.associationsWithOc.map((odc, i) => (
                    <Fragment key={`mas-informacion-oc-${key.current}-${i}`}>
                      <Details summary={`Orden de compra #${odc.docNumber}`}>
                        <CardDocRelated
                          id={odc.id}
                          folio={odc.docNumber}
                          endDate={odc.expiration.parsed}
                          customer={odc.customer.socialReason}
                          startDate={`${parseDateToText(
                            new Date(`${odc.startDate.yyyymmdd}:`)
                          )}`}
                          email={odc.customer.email}
                          phone={odc.customer.phone}
                          importe={odc.amount.subtotal.text}
                          iva={odc.amount.iva.text}
                          total={odc.amount.total.text}
                          currency={odc.currency.code}
                          label="Orden de compra"
                          concept={odc.concept.description}
                        />
                        <hr />
                      </Details>
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            )}
          </Fragment>
        </Dialog>
      ) : null}
    </Fragment>
  );
});

export default MoreInfoInvoiceReception;
