import { getBankAccountV2 } from "helpers/Apis/Banks";
import { useEffect } from "react";
import { useState } from "react";

export default function useBankAccountInfo(id) {
  /**
   * Information of the bank account
   * @type {[import("../../../../types/banks").BankAccount|undefined],()=>void}
   */
  const [state, setState] = useState(undefined);

  useEffect(() => {
    (async function () {
      const bankInfo = await getBankAccountV2(id);

      setState(bankInfo);
    })();
  }, []);

  return state;
}
