import React from "react";
import Table from "components/general/Table";

/**
 *
 * @param {import("./types").PropsTableIva} props
 * @returns
 */
export default function TableIvas(props) {
  return <Table {...props} />;
}
