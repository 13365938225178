import React, { useRef, useContext, useState, useEffect } from "react";
import { ItemConteniner, ItemesContainer } from "./Containers";
import { HeaderSection } from "./HeaderSection";
import { BodySection } from "./BodySection";
import { FooterSection } from "./FooterSection";
import DocumentContext from "../ContextDocument";
import { ReactSortable } from "react-sortablejs";

export const ItemSection = () => {
  const { items, documentType, moneyInfo } = useContext(DocumentContext);

  const key = useRef(window.crypto.randomUUID());

  const currencyToUse = !moneyInfo.currency.value
    ? "mxn"
    : moneyInfo.currency.value.toLowerCase();

  const [displayCosts,setDisplayCosts] = useState(documentType === 3);

  useEffect(()=>{  
    setDisplayCosts(documentType === 3);
  },[documentType]);  

  return (
    <ItemesContainer>
      <HeaderSection />
      {/* <ReactSortable
        list={items}
        setList={reorderDocumentItems}
        ghostClass={ui.currentItem}
      > */}
      {items.map((element, index) => (
        <ItemConteniner key={`${key.current}-${index}`}>
          <BodySection
            subTotal={
              displayCosts
                ? element[currencyToUse].cu.total.text
                : element[currencyToUse].pu.total.text
            }
            pu={
              displayCosts
                ? element[currencyToUse].cu.text
                : element[currencyToUse].pu.text
            }
            quantity={element.quantity}
            sku={element.sku}
            description={element.description}
            currency={element.catalogue?.currency?.code}
          />
          <FooterSection
            itemOnMemory={element}
            index={index}
            discount={
              displayCosts
                ? element.providerDiscount.text
                : element.clientDiscoount.text
            }
            iva={
              displayCosts
                ? element[currencyToUse].cu.totalIva.text
                : element[currencyToUse].pu.totalIva.text
            }
            ivaPercentage={element.iva.text}
            pu={
              displayCosts
                ? element[currencyToUse].cu.text
                : element[currencyToUse].pu.text
            }
            puventa={
              displayCosts
                ? element[currencyToUse].cu.total.text
                : element[currencyToUse].pu.total.text
            }
            idItem={element?.id || null}
          />
        </ItemConteniner>
      ))}
      {/* </ReactSortable> */}
    </ItemesContainer>
  );
};
