import React, { useContext } from "react";
import { ContextEmail } from "..";
import "./styles.scss";
import "structure/Bug/Tickets/Comments/CommentBox/styles.scss";
import CommentBox from "components/individual/CommentBox";

export const TextBoxEmail = () => {
  const { message, updateMessage } = useContext(ContextEmail);

  return (
    <CommentBox value={message} onChange={updateMessage} />
  );
};
