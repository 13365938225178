import Email from "components/individual/inputs/Email";
import {
  ErrorMessage,
  InputDateYearMonth,
} from "components/individual/inputs/Inputs";
import Phone from "components/individual/inputs/Phone";
import usePersonalInformation from "customHooks/usePersonalInformation";
import React, { createContext } from "react";
import { Label } from "structure/Document";
import Reminders from "./Reminders";
import styles from "../styles.module.scss";
import Name from "./Name";
import ReminderWhatsapp from "./Reminders/Whatsapp";
import ReminderEmail from "./Reminders/Email";
import ReminderSms from "./Reminders/Sms";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { useContext } from "react";
import LoginContext from "context/Login/LoginContext";

const PersonalInformationContext = createContext();
const { Provider } = PersonalInformationContext;

/**
 * Render a form in order to update the personal information of the user
 * @param {import("../types").PersonalInformationProps} props - Props
 * @returns {JSX.Element}
 */
export default function PersonalInformation({ onChange = () => {} }) {
  const personalInformation = usePersonalInformation({
    onChange,
  });

  const { userInfo } = useContext(LoginContext);

  return (
    <Provider value={personalInformation}>
      <div className={styles.nameContainer}>
        <div className="mb-2">
          <Label>Rol</Label>
          <p>{userInfo[0].description}</p>
        </div>

        <Name
          label="Primer nombre"
          required={true}
          value={personalInformation.firstName}
          max={50}
          onChange={personalInformation.updateFirstName}
        />

        <Name
          label="Segundo nombre"
          value={personalInformation.lastName}
          max={30}
          onChange={personalInformation.updateLastname}
        />

        <Name
          label="Apellido paterno"
          value={personalInformation.parentName}
          max={30}
          onChange={personalInformation.updateParentname}
        />

        <Name
          label="Apellido materno"
          value={personalInformation.motherName}
          max={30}
          onChange={personalInformation.updateMothername}
        />

        {userInfo[0].isAdmin ? (
          <div className="mb-3">
            <Label>Correo</Label>
            <p>{personalInformation.email.value}</p>
          </div>
        ) : (
          <Email
            required={true}
            maxLength={50}
            label="Correo"
            value={personalInformation.email.value}
            renderCleanButton={false}
            onChange={(value) => personalInformation.updateEmail(value)}
          />
        )}

        <Name
          label="Usuario"
          value={personalInformation.userName}
          max={50}
          required={true}
          onChange={personalInformation.updateUserName}
        />

        {userInfo[0].isAdmin ? (
          <div className="mb-3">
            <Label>Teléfono</Label>
            <p>
              +{personalInformation.phone.lada}{" "}
              {personalInformation.phone.movil}
            </p>
          </div>
        ) : (
          <div>
            <Label>Teléfono</Label>
            <Phone
              idInput="userPhone"
              labelText=""
              value={`${personalInformation.phone.lada}${personalInformation.phone.movil}`}
              onChange={personalInformation.updatePhone}
            />
            <ErrorMessage id="error_userPhone" message="" />
          </div>
        )}

        {userInfo[0].isAdmin ? (
          <div className="mb-3">
            <Label>Fecha de nacimiento</Label>
            <p>
              {new Intl.DateTimeFormat("es-MX", {
                dateStyle: "full",
              }).format(new Date(personalInformation.birthday))}
            </p>
          </div>
        ) : (
          <div>
            <Label>Fecha de nacimiento</Label>
            <InputDateYearMonth
              labelText=""
              placeHolderText="Seleccione una fecha"
              maxDate={new Date()}
              date={new Date(personalInformation.birthday)}
              onChange={({ db }) => personalInformation.updateBirthday(db)}
            />
          </div>
        )}
      </div>

      <div className={styles.configuration}>
        <div>
          <h1>Método aviso de alertas</h1>

          <Reminders
            cellphone={
              personalInformation.phone.isValid === true &&
              (personalInformation.phone.number !== null ||
                typeof personalInformation.phone.number === "string")
            }
            email={personalInformation.reminder.email}
            sms={personalInformation.reminder.sms}
            whatsapp={personalInformation.reminder.whatsapp}
            onChange={personalInformation.updateReminder}
          >
            {() => (
              <div className="d-flex">
                <div className="mr-3">
                  <ReminderWhatsapp />
                </div>

                <div className="mr-3">
                  <ReminderSms />
                </div>

                <div>
                  <ReminderEmail />
                </div>
              </div>
            )}
          </Reminders>
        </div>

        <ButtonV2
          className="mt-3"
          onClick={() =>
            document.getElementById("triggerChangePassword").click()
          }
        >
          Cambiar contraseña
        </ButtonV2>
      </div>
    </Provider>
  );
}
