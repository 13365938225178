import { Select } from "components/individual/inputs/Inputs";
import { getStatusInvoiceReception } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import React, { useEffect, useState } from "react";

/**
 * Status for the reception invoice
 * @param {object} props - Props
 * @param {(object:import("types/typedef/customHooks/useInvoiceReception").InvoiceReceptionStatusI)=>void} props.onChange - Value when onChange happens on select
 * @param {number|"-1"} props.value - Default value status to load on combo
 * @returns {JSX.Element}
 */
export default function InvoiceReceptionStatus({
  onChange = () => {},
  value = "20",
}) {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    (async function () {
      const apiStatus = await getStatusInvoiceReception();

      /**
       * @type {import("types/typedef/customHooks/useInvoiceReception").InvoiceReceptionStatusI}
       */
      const importantInvoices = {
        description:'CxP/Parcial',
        id:20
      }

      const statusList = [...apiStatus,importantInvoices];

      setStatus([{ id: "-1", description: "Todos" }, ...statusList]);
    })();
  }, []);

  /**
   * Handle the on change
   * @param {number|null} idStatus - Id of the status
   */
  const handleOnChange = (idStatus) => {
    /**
     * @type {import("types/typedef/customHooks/useInvoiceReception").InvoiceReceptionStatusI}
     */
    const statusFinded = status.find((status) => status.id === idStatus) || {
      id: "-1",
      description: "Todos",
    };

    onChange(statusFinded);
  };

  return (
    <Select
      addTodos={false}
      idSelect={window.crypto.randomUUID()}
      label="Estatus"
      options={status}
      textJSON="description"
      valueJSON="id"
      onChange={(value) => handleOnChange(+value)}
      value={value}
    />
  );
}
