import {
  ItemText,
  ItemsElemntContainer,
} from "components/Sections/Documents/Items/Containers";
import React from "react";
import { BodyShort, BodyShortKey } from "./Containers";
import { ItemsDescription } from "components/Elements/ItemsDescription";

export const ItemsBodySection = ({
  sku,
  description,
  quantity,
  unitImport,
  totalImport,
  currency
}) => {
  return (
    <ItemsElemntContainer>
      <BodyShortKey>
        <ItemText>{sku}</ItemText>
        <ItemText>
          <strong>{currency}</strong>
        </ItemText>
      </BodyShortKey>
      <ItemsDescription description={description} />
      <BodyShort>
        <p>{quantity}</p>
      </BodyShort>
      <BodyShort>
        <p>{unitImport}</p>
      </BodyShort>
      <BodyShort>
        <p>{totalImport}</p>
      </BodyShort>
    </ItemsElemntContainer>
  );
};
