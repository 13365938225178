import { getBankAccountPendingConcilations } from "helpers/Apis/Banks";
import { conciliate, getConcilationExcel } from "helpers/Apis/concilation";
import { useEffect } from "react";
import { useReducer } from "react";
import { ACTIONS } from "./actions";
import { getAccount } from "helpers/Apis/Banks/index";
import { INITIAL_STATE, reducer } from "./reducer";
import { MONTHS, dateToDbFormat } from "helpers/dates";
import { saveAs } from "file-saver";
import useDialog from "customHooks/useDialog";
// import { TYPES } from "./types";

/**
 * @type {import("./types").ContextConcilation}
 */
export const CONTEXT_CONCILATION = {
  ...INITIAL_STATE,
  setAccountingState: () => {},
  breadcrum: [],
  setMonth: () => {},
  setYear: () => {},
  handleOnClickConcilation: () => {},
  fetchPage: async () => {},
  canDisplayUpdateButton: false,
  setMaxDate: () => {},
  attemptConcilationUpdate: async () => false,
  setMinDate: () => {},
  triggerSearch: () => {},
  canClickCheckbox: () => false,
  idBankAccount: 0,
  bank: undefined,
  setStatus: () => {},
  setType: () => {},
  setSearch: () => {},
  setRowsPerPage: () => {},
  attemptDownloadExcel: async () => {},
  setTo: () => {},
  toggleAccounting: () => {},
  originalStatus: {},
  operationStatus: {},
  dialog: undefined,
  setFrom: () => {},
  toggleConcilationV2:()=>{}, 
  toggleAccountingV2:()=>{}
};

export default function useConcilation(idBankAccount = null) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const dialog = useDialog();

  useEffect(() => {
    if (Object.keys(state.originalStatus).length <= 0 || Object.keys(state.operationStatus).length <= 0) return;

    let gridChanged = false;


    Object.entries(state.operationStatus).reduce((indexed,[key,value])=>{
      if(state.originalStatus[key].accounting!==value.accounting || state.originalStatus[key].conciliate !== value.conciliate){
        gridChanged = true
      }
      return {}
    },{})

    dispatch({
      payload:gridChanged,
      type:ACTIONS.SET_GRID_CHANGED
    })

  }, [state.operationStatus, state.originalStatus]);

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_TO,
      payload: state.maxDate,
    });
  }, [state.maxDate]);

  /**
   * Update the rows per page to display on the concilation
   * @param {number} rowsPerPage - Rows per page
   * @returns {void}
   */
  const setRowsPerPage = (rowsPerPage) =>
    dispatch({
      type: ACTIONS.SET_ROW_PER_PAGE,
      payload: rowsPerPage,
    });

  /**
   * Set the flag in order to know is client is fetching data from server
   * @param {boolean} isLoading - Indicate if the user is fetching data from the server
   * @returns {void}
   */
  const setIsLoading = (isLoading) =>
    dispatch({
      type: ACTIONS.SET_IS_LOADING,
      payload: isLoading,
    });

  /**
   * Get the max date that can be selected in order to chose a month on the filter
   * @param {Date} maxDate - Max date in order to filter the concilation
   */
  const setMaxDate = (maxDate) =>
    dispatch({
      type: ACTIONS.SET_MAX_DATE,
      payload: maxDate,
    });

  const triggerSearch = () => {
    dispatch({
      type: ACTIONS.EMPTY_SEARCH,
      payload: null,
    });
    fetchPage(1);
  };

  /**
   * Set the status for the filter search
   * @param {number|null|2|3} status
   * @returns {void}
   */
  const setStatus = (status) =>
    dispatch({
      payload: status,
      type: ACTIONS.SET_STATUS,
    });

  /**
   * Update the min date that can selected on the combo filter search
   * @param {Date} minDate - Min date
   * @returns {void}
   */
  const setMinDate = (minDate) =>
    dispatch({
      payload: minDate,
      type: ACTIONS.SET_MIN_DATE,
    });

  /**
   * Fetch the page requested by the user
   * @param {number} page - Number of page to fetch
   */
  const fetchPage = async (page) => {
    setIsLoading(true);

    const apiMovements = await getBankAccountPendingConcilations({
      idBankAccount,
      page,
      date: dateToDbFormat(new Date(`${state.year}-${state.month}-1`)),
      orderAsc: true,
      rowsPerPage: state.rowsPerPage,
      from: state.from === null ? null : dateToDbFormat(state.from),
      to: state.to === null ? null : dateToDbFormat(state.to),
      type: state.type,
      status: state.status,
      noMovement: state.search,
      getFullReport: false,
      posted: state.accountingState,
    });

    dispatch({
      type: ACTIONS.SET_DATA_FETCHED,
      payload: apiMovements,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    (async function () {
      if (idBankAccount === null) return;

      const bankAccountApi = await getAccount(idBankAccount);

      dispatch({
        type: ACTIONS.SET_BANK_ACCOUNT_CONCILATION,
        payload: bankAccountApi,
      });

      fetchPage(1);
    })();
  }, [idBankAccount, state.month, state.year]);

  /**
   * Update the month in order to search
   * @param {number} month - Month
   * @returns {void}
   */
  const setMonth = (month) =>
    dispatch({
      type: ACTIONS.SET_MONTH,
      payload: month,
    });

  /**
   * Update the year of the select combo
   * @param {number} year - Year requested
   * @param {Date} date - Date of the min date available to select
   * @returns {void}
   */
  const setYear = (year, date) =>
    dispatch({
      type: ACTIONS.SET_YEAR,
      payload: { year, date },
    });

  /**
   * Append the movement in order to conciliate it
   * @param {import("../../../../types/conciliations").Movimiento} movement - Movement to append for the concilation
   * @param {boolean} isCheck - Indicates if the register click make the checkbox to be active or inactive
   * @returns {void}
   */
  const handleOnClickConcilation = (movement, isCheck) => {
    dispatch({
      type: ACTIONS.HANDLE_CLICK_CONCILATION,
      payload: {
        isChecked: isCheck,
        movement,
      },
    });
  };

  const attemptConcilationUpdate = async () => {
    // const idsToConciliate = Object.keys(state.toConciliate).map((key) => +key);
    // const idsToDesconciliate = Object.keys(state.toDesconciliate).map(
    //   (key) => +key
    // );
    // const idsToAccount = Object.keys(state.toAccount).map((key) => +key);
    // const idsDisaccount = Object.keys(state.toDisaccount).map((key) => +key);


    dispatch({
      type: ACTIONS.SET_UPDATING,
      payload: true,
    });

    let idsToConciliate = [];
    let idsToDesconciliate = [];
    let idsToAccount = [];
    let idsDisaccount = [];


    Object.entries(state.operationStatus).reduce((indexed,[key,value])=>{
      // Nothing changed
      if(state.originalStatus[key].accounting===value.accounting && state.originalStatus[key].conciliate === value.conciliate) return indexed;


      const concilationChanged = state.originalStatus[key].conciliate !== value.conciliate ? true : false;
      const accountingChanged = state.originalStatus[key].accounting !== value.accounting ? true : false;

      if(concilationChanged && value.conciliate === 2){
        idsToDesconciliate.push(+key)
        
      }
      
      if(concilationChanged && value.conciliate===3){
        idsToConciliate.push(+key);
      }

      if(accountingChanged && value.accounting === true){
        idsToAccount.push(+key)
      }

      if(accountingChanged && value.accounting===false){
        idsDisaccount.push(+key);
      }

      return {}
    },{})

    const wasUpdated = await conciliate(
      idsToConciliate,
      idsToDesconciliate,
      idsToAccount,
      idsDisaccount
    );

    dispatch({
      type: ACTIONS.SET_UPDATING,
      payload: false,
    });

    if (wasUpdated) {
      triggerSearch();
    }

    return wasUpdated;
  };

  /**
   * If more than 0, means wants to conciliate or des-conciliate something
   */
  const canDisplayUpdateButton =
    Object.keys(state.toAccount).length +
      Object.keys(state.toDisaccount).length +
      Object.keys(state.toConciliate).length +
      Object.keys(state.toDesconciliate).length >
    0;

  /**
   * Check if the checkbox can be clicked on the UI
   * @param {number} status - Status of the movement
   * @returns {boolean}
   */
  const canClickCheckbox = (status) => {
    if (status !== 5 && status !== 4) return true;

    return false;
  };

  /**
   * Update the type of movement to fetch on the registers
   * @param {number|null|1|2} type - Id of the type of movement
   * @returns {void}
   */
  const setType = (type) =>
    dispatch({
      type: ACTIONS.SET_TYPE,
      payload: type,
    });

  /**
   * Update the search to use on the filter
   * @param {string} search - Search
   * @returns {void}
   */
  const setSearch = (search) =>
    dispatch({
      type: ACTIONS.SET_SEARCH,
      payload: search,
    });

  /**
   * Update the range to date
   * @param {Date|null} to - Date to range filter
   * @returns {void}
   */
  const setTo = (to) =>
    dispatch({
      type: ACTIONS.SET_TO,
      payload: to,
    });

  /**
   * Update the range to date
   * @param {Date|null} from - Date to range filter
   * @returns {void}
   */
  const setFrom = (from) =>
    dispatch({
      type: ACTIONS.SET_FROM,
      payload: from,
    });

  /**
   * Update the flag to display the spinner on downloading excel file
   * @param {boolean} isDownloadingExcel - Set spinner when downloadin excel file
   * @returns {void}
   */
  const setDownloadExcel = (isDownloadingExcel) =>
    dispatch({
      type: ACTIONS.SET_DOWNLOADING_EXCEL,
      payload: isDownloadingExcel,
    });

  /**
   * Download the excel file report for the conciliation
   * @returns {Promise<void>}
   */
  const attemptDownloadExcel = async () => {
    setDownloadExcel(true);

    const file = await getConcilationExcel(
      idBankAccount,
      dateToDbFormat(state.dateToConciliate)
    );

    setDownloadExcel(false);

    if (file === undefined) return;

    const monthName = MONTHS[state.dateToConciliate.getUTCMonth()];

    saveAs(
      file,
      `Conciliacion ${
        state.bankAccount.account
      } ${monthName} ${state.dateToConciliate.getUTCFullYear()}.xlsx`
    );
  };

  /**
   * Toggle checkbox for accounting input
   * @param {number} id - Id of the movement
   * @returns {void}
   */
  const toggleAccountingV2 = (id) =>
    dispatch({
      type: ACTIONS.TOGGLE_ACCOUNTING,
      payload: id,
    });

  const toggleConcilationV2 = (id) =>
    dispatch({
      type: ACTIONS.TOGGLE_CONCILATION,
      payload: id,
    });

  /**
   * Breadcrum
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/",
      text: "Inicio",
    },
    {
      route: "/v2/bancos",
      text: "Bancos",
    },
    {
      route: `/v2/bancos/conciliacion`,
      text: "Conciliacion",
    },
  ];

  /**
   * Handle the toggle checkbox for the accouting
   * @param {number} id - Id of the movement clicked in order to account or disaccount
   */
  const toggleAccounting = (id) =>
    dispatch({
      type: ACTIONS.SET_ACCOUNTING_ITEM,
      payload: id,
    });

  /**
   *
   * @param {boolean|null} status
   * @returns
   */
  const setAccountingState = (status) =>
    dispatch({
      type: ACTIONS.SET_STATUS_ACCOUNTING,
      payload: status,
    });

  return {
    dialog,
    toggleAccountingV2,
    toggleConcilationV2,
    breadcrum,
    attemptDownloadExcel,
    setStatus,
    setAccountingState,
    ...state,
    setMonth,
    toggleAccounting,
    setType,
    setYear,
    setSearch,
    handleOnClickConcilation,
    fetchPage,
    canDisplayUpdateButton,
    setMaxDate,
    attemptConcilationUpdate,
    setMinDate,
    triggerSearch,
    canClickCheckbox,
    idBankAccount,
    setFrom,
    setRowsPerPage,
    setTo,
  };
}
