import React from "react";
import { AddCommentSection } from "./AddCommentSection";
import { CommentItemSection } from "./CommentItemSection";
import { CommentsContainer } from "./Containers";

export const CommentSection = () => {
  return (
    <CommentsContainer>
      <AddCommentSection />
      <CommentItemSection />
    </CommentsContainer>
  );
};
