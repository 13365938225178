import React from "react";
import { Label } from "structure/Document";

/**
 *
 * @param {import('./types').PropsInformativeAmount} props - Props
 * @returns
 */
export default function InformativeAmount({ amount, label }) {
  return (
    <div className="text-right">
      <Label className="justify-content-end m-0">{label}</Label>
      <span className={amount < 0 ? "text-danger font-weight-bold" : ""}>
        {new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(amount)}
      </span>
    </div>
  );
}
