import React, { useEffect, useState } from "react";
import style from "./styles.module.scss";

/**
 * @type {string | number | readonly string[]}
 */
const initialValue = "";

/**
 * Render a input for money
 * @param {import("./types").PropsMoney} props - Props
 * @returns {JSX.Element}
 */
export default function Money(props) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (Object.keys(props).includes("value")) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleOnChange = (value) => {
    const [numeric, decimal = "0"] = value.split(".");

    const parsedValue = Object.keys(props).includes("maxDecimals")
      ? +`${numeric}.${decimal.substring(0, props.maxDecimals)}`
      : value;

    const casted = typeof parsedValue === "number" ? parsedValue : +parsedValue;

    setValue(casted);

    if (Object.keys(props).includes("onChange")) {
      props?.onChange(casted);
    }
  };

  return (
    <div className={style.money}>
      <span>$</span>
      <input
        onKeyDown={(e) => {
          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
          }
        }}
        type="number"
        ref={props.forwardRef}
        {...props}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
        value={value === 0 ? "" : value}
      />
    </div>
  );
}
