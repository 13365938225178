import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext, useRef } from "react";
import filter from "./styles.module.scss";
import { ContextAccountingOc } from "..";
import YearOcAccounting from "components/general/Forms/Selects/YearOcAccounting";
import style from '../styles.module.scss';

export default function Filters() {
  const idForm = useRef(window.crypto.randomUUID());

  const hook = useContext(ContextAccountingOc);

  return (
    <div className={style.content}>
    <form
      id={idForm.current}
      className={filter.container}
      onSubmit={(e) => {
        e.preventDefault();
        hook.search();
      }}
    >
      <YearOcAccounting onChange={hook.setYear}/>

      <ButtonV2 type="submit" form={idForm.current}>
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>
    </form>
    </div>
  );
}
