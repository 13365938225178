import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";
import { ContextOdc } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import EditDocumentButton from "components/individual/buttons/EditButtonDocument";

export default function EditOc() {
  const { odc, goEdit } = useContext(ContextOdc);

  const { checkPermission } = usePermissions();

  if (odc === null) return <></>;

  if (
    odc.status.id === 11 &&
    checkPermission("de8999f5-18f7-41b5-a96e-279b8f3daf30")
  ) {
    return <ButtonV2 onClick={goEdit}>Editar</ButtonV2>;
  }

  if (odc.status.id === 10)
    return (
      <EditDocumentButton goEdit={goEdit} idDocument={odc.id} />
      // <ButtonV2
      //   onClick={async () => {
      //     try {
      //       const itCanChange = await chanChangeCurrency(odc.id);
      //       console.log("🚀 ~ onClick={ ~ itCanChange:", itCanChange)
      //       if (itCanChange) {
      //         console.log("si puede cambiar");
      //         Warning(
      //           "Posible cambio de moneda",
      //           "La edición de una orden de compra puede cambiar automáticamente su moneda debido a su contenido. Por favor, revísalo antes de actualizar",
      //           goEdit
      //         );
      //       } else {
      //         console.log("No cambia la moneda");
      //         goEdit();
      //       }
      //     } catch (error) {
      //       ErrorAlertText(
      //         () => {},
      //         "No se pudo validar",
      //         "No se pudo obtener la validacion de las partidas para mandar mensaje",
      //         ""
      //       );
      //     }
      //   }}
      // >
      //   Editar
      // </ButtonV2>
    );
}
