import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";

export default function SearchButton() {

  return (
    <ButtonV2 type="submit">
      <span>Buscar</span>
      <Icon icon="search" />
    </ButtonV2>
  );
}
