import React from "react";
import style from "./styles.module.scss";

/**
 * Render a loading bar
 * @param {import("./types").PropsGridLoading} props - Props
 * @returns {JSX.Element}
 */
export default function GridLaoding({ justOneRow = false }) {
  return (
    <div className={style["skeleton-grid"]}>
      <div className={style["skeleton-row"]}></div>
      {justOneRow ? null : (
        <>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
          <div className={style["skeleton-row"]}></div>
        </>
      )}
    </div>
  );
}
