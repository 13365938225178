import React from "react";
import {
  InputNumber,
  InputText,
  InputCurrency,
  ErrorMessage,
} from "../../../components/individual/inputs/Inputs";

import { trimDate } from '../../../helpers/dates';

import { Date as DateInput } from "../../../components/individual/inputs/Date";

export function InputsFastAdd({ register, errors }) {

  // GET INPUT FROM DOM
  const date = document?.getElementById('registerDate');

  // SEPARATE STRING DATE TIME
  let parsedDate = trimDate(date?.value,'dmy','-');

  // CONVERT TO JS DATE
  parsedDate = new Date(parsedDate?.day,parsedDate?.month-1,parsedDate?.year);

  // VALIDATE THE CONVERTION WAS CORRECT
  const isValidDate = !isNaN(parsedDate.valueOf());

  const pressEnter = (e) => {
    document.getElementById('btnAddList').click();
  }

  return (
    <>
      <div className="col-2">
        <DateInput
          forwardRef={register}
          id="registerDate"
          name="registerDate"
          placeholder="Selecciona una fecha"
          defaultDate={isValidDate ? parsedDate : new Date()}
          maxDate = {new Date()}
        />
      </div>

      <div className="col-2">
        <InputText
          id="reference"
          htmlFor="reference"
          name="reference"
          labelTxt="Referencia"
          placeholderTxt="Escribe aqui"
          forwardRef={register}
          onEnter = {pressEnter}
        />
        {/* <ErrorMessage
          message={errors["reference"]?.message}
          id="error_reference"
        /> */}
      </div>

      <div className=" col-4">
        <InputText
          htmlFor="concept"
          id="concept"
          name="concept"
          labelTxt="Concepto"
          placeholderTxt="Escribe aqui"
          forwardRef={register}
          onEnter = {pressEnter}
        />
        {/* <ErrorMessage message={errors["concept"]?.message} id="error_concept" /> */}
      </div>

      <div className="col-2">
        <InputCurrency
          htmlfor="importConcept"
          name = 'importConcept'
          id="importConcept"
          forwardRef={register}
          labelTxt="Importe"
          placeholder="Escribe aqui"
          onEnter = {pressEnter}
        />
      </div>
    </>
  );
}
