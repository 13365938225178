import useDialog from "customHooks/useDialog";
import { saveAs } from "file-saver";
import { getDistributionList } from "helpers/Apis/Directory";
import { useState } from "react";

/**
 * State for the filter
 * @type {import("./types").StateDistributionList}
 */
const INITIAL_STATE = {
  uen: null,
  sector: null,
  type: "report",
  isDownloading: false,
};

/**
 * Custom hook
 * @returns {import("./types").ReturnUseDistributionList}
 */
export default function useDistributionList() {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  /**
   * Update the type of sector
   * @param {import("components/individual/TypeCustomerDirectory/types").TypeCustomerDirectoryData} sector - Sector selected
   */
  const updateSectorType = (sector) =>
    setState((current) => ({
      ...current,
      sector: typeof sector?.id === "number" ? sector.id : null,
    }));

  /**
   * Update the type of report to display
   * @param {import("./types").TypeDistributionList} type - Type distribution
   * @returns {void}
   */
  const updateTypeQuery = (type) =>
    setState((current) => ({
      ...current,
      type,
    }));

  const attemptDownload = async () => {
    setState((current) => ({
      ...current,
      isDownloading: true,
    }));

    const file = await getDistributionList(state.uen, state.sector,state.type);

    setState((current) => ({
      ...current,
      isDownloading: false,
    }));

    if (file === undefined) return;

    if(state.type==='report'){
      saveAs(file,`Lista de distribucion.xlsx`);
      return
    }

    saveAs(file,`Lista de distribucion Mailchimp.xlsx`)
  };

  /**
   * Set uen for report
   * @param {number|null} uen 	- Uen
   * @returns 
   */
  const setUen = uen => setState(current=>({
    ...current,
    uen
  }));

  return {
    ...state,
    dialog,
    updateSectorType,
    updateTypeQuery,
    attemptDownload,
    setUen
  };
}
