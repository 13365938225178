import BankInformation from "pages/Banks/BankInformation";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useConcepts from "customHooks/useConcepts";
import {
  addIncomesAsociation,
  getBankAccount,
  getMovement,
} from "helpers/Apis/Banks";
import { GenericRadioButtonList } from "components/general/ListConcepts/GenericRadioButtonList";
import { mnCurrency } from "helpers/money";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoginContext from "context/Login/LoginContext";
import { Error, Success } from "helpers/alerts";
import { useHistory } from "react-router-dom";

export default function AssociateIngress() {
  const { account, idMovement } = useParams();
  const { userInfo } = useContext(LoginContext);

  const history = useHistory();

  const [info, setInfo] = useState({
    bank: null,
    movement: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      const [bank, movement] = await Promise.all([
        getBankAccount(+account),
        getMovement(idMovement),
      ]);

      setInfo({
        bank,
        movement,
      });
    })();
  }, []);
  const { concepts } = useConcepts(1, info.bank?.currencyCode, () => {}, 2);
  const [conceptSelected, setConceptSelected] = useState(null);

  const submit = async () => {
    setIsLoading(true);
    // setDataToSubmit();
    try {
      const wasAdded = await addIncomesAsociation({
        applied: info.movement?.amount,
        creadeBy: userInfo[0].fullName,
        idIncomes: conceptSelected,
        idMovement: +info.movement?.MovementID,
        importe: info.movement?.amount,
        lastUpdatedBy: userInfo[0].fullName,
        tc: 22.15,
      });
      if (wasAdded) {
        Success(() => {}, "Se agrego correctamente");

        history.push(`/bancos/movimientos/${account}`);
      } else {
        Error(() => {},
        "Hubo un erro al intentar asociar el ingreso, intente de nuevo mas tarde");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <BreadCrumV2
        path={[
          {
            route: `/inicio`,
            text: "Inicio",
          },

          {
            route: `/bancos`,
            text: "Bancos",
          },
          {
            route: `/bancos/movimientos/${account}`,
            text: "Movimientos",
          },
          {
            route: ``,
            text: "Asociar",
          },
        ]}
      />

      <div className="container mt-5">
        <BankInformation idAccount={+account} />

        <div>
          {!!info ? (
            <>
              <h4>Información del Ingreso</h4>
              <p className="mb-3">
                <b>Ingreso No:</b>
                {info.movement?.MovementID} | <b>Importe total: </b>
                <span>{mnCurrency(info.movement?.amount)} </span>
              </p>
              <hr />
            </>
          ) : null}
        </div>
        <div className="d-flex justify-content-between">
          {isLoading ? (
            <Spinner
              hidden={false}
              idSpinner={window.crypto.randomUUID()}
              text="Asociando CxP..."
            />
          ) : !!conceptSelected ? (
            <ButtonV2 onClick={async () => await submit()}>Asociar</ButtonV2>
          ) : null}
        </div>

        <GenericRadioButtonList
          name={"incomesConcepts"}
          headers={["Concepto"]}
          options={concepts}
          attributeID={"id"}
          attributeValue={"description"}
          onChange={({ target: { value } }) => {
            setConceptSelected(+value);
          }}
        />
      </div>
    </>
  );
}
