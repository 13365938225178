import styled from 'styled-components';

const ContainerDocInfo = styled.div`

    .itemDocument{
        margin:0 1% 0 0;
        display: inline-block;
    }

    .itemDocument:nth-child(1){
        width: 12%;
               
    }

    .itemDocument:nth-child(2){
        width: 12%;
    }

    .itemDocument:nth-child(3){
        width: 12%;
               
    }

    .itemDocument:nth-child(4){
        width: 12%;
    }

    .itemDocument:nth-child(5){
        width: 45%;
    }

    .itemDocument:nth-child(6), .itemDocument:nth-child(7){
        width: 48%;
    }

`;

const ContainerBetween = styled.div`

    .itemDocument{
        margin:0 1% 0 0;
        display: inline-block;
        width: 13%;
    }

`;

export {
    ContainerDocInfo,
    ContainerBetween
}