import ModalV2 from "components/individual/Modals/ModalV2";
import { Error, Success } from "helpers/alerts";
import { addUser } from "helpers/Apis/usuariosApi";
import { FormContact, FormUser } from "pages/Directory/Contactos/FormContact";
import { ContainerFormUser } from "pages/Directory/Contactos/FormContactStyles";
import React, { useState } from "react";
import AddUserBtn from "./AddUserBtn";
import { userValidationSchema } from "./Schema";
import { useContext } from "react";
import { UsersContext } from "./UsuariosV2";
import usePermissions from "customHooks/usePermissions";
import Icon from "components/individual/HeaderIcon";

/**
 *
 * @param {object} props - Props
 * @param {import("./types").InfoRow} props.userInfo - Information of the row selected on the table
 * @returns {JSX.Element}
 */
export const AddUsers = ({ userInfo }) => {
  const [loading, setLoading] = useState(false);

  const hook = useContext(UsersContext);

  /**
   * State of the component
   * @type {[import("./types").StateAddUserI,()=>void]}
   */
  const [state, setState] = useState({
    isValidForm: true,
    phone: {
      lada: 52,
      number: null,
    },
    chief: userInfo.chiefId,
  });

  /**
   * Update the phone number
   * @param {import("components/individual/inputs/typesPhone").onChangePhoneI} number
   * @returns {void}
   */
  const updatePhoneNumber = (number) => {
    setState({
      ...state,
      isValidForm: number.isValidNumber,
      phone: {
        lada: number.lada,
        number: number.number,
      },
    });
  };

  const updateChief = (chief) =>
    setState((current) => ({
      ...current,
      chief,
    }));

  /**
   *
   * @param {(import("types/typedef/users").DataToSubmitUserI)} data
   */
  const handleSubmit = async (data) => {
    setLoading(true);
    const submitData = {
      birthDayN: data.dateMothYear,
      createdByN: userInfo[0].userID,
      directChiefIdN: state.chief,
      emailN: data.email,
      lastName1N: data.apellidoP,
      lastName2N: data.apellidoM,
      middleNameN: !!data.middleName ? data.middleName : null,
      nameN: data.nombre,
      rolIdN: data.roles,
      userNameN: data.userName,
      phoneN: {
        lada: state.phone.number === null ? null : state.phone.lada,
        number: state.phone.number,
      },
      rolTicket: data.ticketRol,
    };
    const wasAdded = await addUser(submitData);

    setLoading(false);
    if (wasAdded) {
      hook.refetchTable();

      const close = document.getElementById("closeAddContact");

      if (close) close.click();
    }
  };

  const { checkPermission } = usePermissions();

  if (!checkPermission("bb8b4c5a-ed0d-476c-9e36-b1b66b326105")) return <></>;

  return (
    <div>
      <ModalV2
        footer={
          <AddUserBtn
            isPerformingQueryToAdd={loading}
            isValid={state.isValidForm}
          />
        }
        idCloseIcon="closeAddContact"
        buttonText={
          <>
            <span>Agregar</span>
            <Icon icon="plus" />
          </>
        }
        aditionalClasses=""
        title="Agregar usuario"
        showCancelButton={false}
      >
        <ContainerFormUser>
          <FormUser
            idForm="addUsuarioForm"
            validation={userValidationSchema}
            className="mb-5"
            onSubmit={handleSubmit}
          >
            <FormContact.Name />
            <FormContact.MiddleName />
            <FormContact.Father />
            <FormContact.Mother />
            <FormContact.Email />
            <FormUser.UserName />
            <FormUser.UserNumber onChange={updatePhoneNumber} />
            <FormUser.DateMonthYear />
            <FormUser.Executive
              name={"jefe"}
              onChange={(id) => updateChief(id)}
            />
            <FormUser.RolesSelect name={"roles"} />
            <FormUser.TicketRol />
          </FormUser>
        </ContainerFormUser>
      </ModalV2>
    </div>
  );
};
