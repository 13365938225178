/**
 * @type {import("./types").ACTIONS}
 */
export const ACTIONS = {
  SET_MONTH: "SET_MONTH",
  SET_YEAR: "SET_YEAR",
  SET_DATA_FETCHED: "SET_DATA_FETCHED",
  SET_IS_LOADING: "SET_IS_LOADING",
  HANDLE_CLICK_CONCILATION: "HANDLE_CLICK_CONCILATION",
  SET_MAX_DATE: "SET_MAX_DATE",
  SET_MIN_DATE: "SET_MIN_DATE",
  EMPTY_SEARCH: "EMPTY_SEARCH",
  SET_BANK_ACCOUNT_CONCILATION: "SET_BANK_ACCOUNT_CONCILATION",
  SET_UPDATING:"SET_UPDATING",
  SET_STATUS:"SET_STATUS",
  SET_FROM:"SET_FROM",
  SET_TO:"SET_TO",
  SET_TYPE:"SET_TYPE",
  SET_ROW_PER_PAGE:"SET_ROW_PER_PAGE",
  SET_DOWNLOADING_EXCEL:"SET_DOWNLOADING_EXCEL",
  SET_ACCOUNTING_ITEM:"SET_ACCOUNTING_ITEM",
  SET_STATUS_ACCOUNTING:"SET_STATUS_ACCOUNTING",
  TOGGLE_ACCOUNTING:"TOGGLE_ACCOUNTING",
  TOGGLE_CONCILATION:"TOGGLE_CONCILATION",
  SET_GRID_CHANGED:"SET_GRID_CHANGED"
};
