import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import CatalogueDirectory from "structure/CatalogueDirectory";
import MenuItem from "components/individual/MenuItem";
import { getControlReport } from "helpers/Apis/Documents";

export default function PurchaseReportMobile() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "purchaseReports") return <></>;

  return (
    <>
      <NavigationItem
        icon="home"
        label="Inicio > Compras > Reportes"
        subMenu="purchase"
      />

      <NavigationItem icon="document" label="Historico de pagos" />
      <NavigationItem
        icon="document"
        label="Cuentas por pagar"
        link="/compras/cxp"
      />

      <CatalogueDirectory type="providers">
        <NavigationItem icon="document" label="Catalogo proveedores" />
      </CatalogueDirectory>
      <NavigationItem
        icon="document"
        label="Saldos y movimientos"
        link="/compras/saldos-y-movimientos"
      />

      <NavigationItem
        icon="document"
        label="Consecutivo de Ordenes de Compra"
        link="/administracion/consecutivo-oc"
      />

      <NavigationItem
        icon="document"
        label="Antigüedad de saldos"
        link="/v2/bancos/cxp/antiguedad-saldos"
      />

      <MenuItem
        icon="reports"
        children="Reporte de control"
        onClick={() => {
          getControlReport();
          hook.closeMenu()
        }}
      />
    </>
  );
}
