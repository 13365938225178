import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import AbcBank from "structure/Menu/Banks/AbcBank";
import BankAccountReport from "structure/Menu/Banks/Reports/BankAccountReport";

export default function Anticipated() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "anticipos") return <></>;

  return (
    <>
          <NavigationItem icon="home" label="Inicio > Bancos > Anticipos" subMenu="banks" />

      
      <NavigationItem icon="banks" label="Proveedores" link="/v2/bancos/anticipos/proveedores"/>
      <NavigationItem icon="banks" label="Clientes" link="/v2/bancos/anticipos/clientes"/>
    </>
  );
}
