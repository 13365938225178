import { yupResolver } from "@hookform/resolvers/yup";
import useDialog from "customHooks/useDialog";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { schemaAdd, schemaUpdate } from "./schema";
import { createProduct, getCatalogue, updateProduct } from "helpers/Apis/uen";
import { useEffect } from "react";

/**
 * @type {import("./types").StateUseCatalogueAdmin}
 */
const INITIAL_STATE = {
  isLoading: true,
  product: null,
  products: [],
  currentPage: 1,
  pages: 0,
  isPerformingAbc: false,
  currency: null,
  refetch: false,
  isEditing: false,
  description: "",
  sku: "",
  edition: {
    uen: null,
    satCode: "",
    satUM: "",
    iva: null,
    excent: null,
  },
};

/**
 * @type {import("./types").ContextCatalogueAdmin}
 */
export const InitialStateContextCatalogueAdmin = {
  ...INITIAL_STATE,
  addDialog: undefined,
  breadcrum: [],
  formAdd: undefined,
  formEdit: undefined,
  attemptCreateProduct: async () => {},
  idFormAdd: window.crypto.randomUUID(),
  setProduct: () => {},
  idFormEdit: window.crypto.randomUUID(),
  setPage: () => {},
  attemptSearch: () => {},
  setCurrency: () => {},
  moreInfoDialog: undefined,
  editDialog: undefined,
  handleAttemptUpdate: async () => {},
  customShowEdit: () => {},
  updateSatData: () => {},
  updateIvaData: () => {},
  setDescription: () => {},
  setSku: () => {},
};

/**
 * Hook to handle the administration of the catalogue
 * @returns {import("./types").ReturnUseCatalogueAdmin}
 */
export default function useCatalogueAdmin() {
  const [state, setState] = useState(INITIAL_STATE);

  const idFormAdd = useRef(window.crypto.randomUUID());
  const idFormEdit = useRef(window.crypto.randomUUID());

  /**
   * Update the sat data
   * @param {string} um - Um
   * @param {string} code - Code
   * @param {number} uen - UEN
   * @returns {void}
   */
  const updateSatData = (um, code, uen) =>
    setState((current) => ({
      ...current,
      edition: {
        ...current.edition,
        satCode: code,
        satUM: um,
        uen,
      },
    }));

  /**
   * Update the iva data of the product
   * @param {boolean} exempt - Is iva exempt
   * @param {number} iva - Iva
   * @returns {void}
   */
  const updateIvaData = (exempt, iva) =>
    setState((current) => ({
      ...current,
      edition: {
        ...current.edition,
        excent: exempt,
        iva,
      },
    }));

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./types").SchemaAddProduct>}
   */
  const formAdd = useForm({
    criteriaMode: "firstError",
    mode: "all",
    resolver: yupResolver(schemaAdd),
  });

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./types").SchemaUpdateProduct>}
   */
  const formEdit = useForm({
    criteriaMode: "firstError",
    mode: "all",
    resolver: yupResolver(schemaUpdate),
  });

  /**
   * Function to show/hide the modal of edit
   * @param {boolean} isEditing - Is editing the modal
   */
  const customShowEdit = (isEditing) => {
    if (isEditing) {
      editDialog.open();
      setState((current) => ({
        ...current,
        isEditing,
      }));
    } else {
      editDialog.close();
      setState((current) => ({
        ...current,
        isEditing,
      }));

      updateSatData("", "", null);
      updateIvaData(null, null);
    }
  };
  useEffect(() => {
    if (state.product === null || state.product === undefined) {
      setState((current) => ({
        ...current,
        edition: {
          uen: null,
          excent: null,
          iva: null,
          satCode: "",
          satUM: "",
        },
      }));
      return;
    }

    formEdit.reset({
      currency: state.product.currency === "MXN" ? 1 : 2,
      description: state.product.description,
      idCatalogue: state.product.id,
      satCode: state.product.satCode,
      satUM: state.product.satUm,
      sku: state.product.sku,
      uen: state.product.idUen,
      unitCost: state.product.unitCost,
      unitPrice: state.product.unitPrice,
      status: state.product.status,
    });

    setState((current) => ({
      ...current,
      edition: {
        uen: state.product.idUen,
        excent: state.product.isIvaExempt,
        iva: state.product.iva,
        satCode: state.product.satCode,
        satUM: state.product.satUm,
      },
    }));
  }, [state.product, state.isEditing]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        product: null,
      }));

      const catalogue = await getCatalogue(
        state.currentPage,
        state.currency,
        state.description,
        state.sku
      );

      setState((current) => ({
        ...current,
        isLoading: false,
        pages: catalogue.pages.pages,
        product: null,
        products: catalogue.products,
      }));
    })();
  }, [state.currentPage, state.refetch]);

  const addDialog = useDialog();
  const editDialog = useDialog();
  const moreInfoDialog = useDialog();

  /**
   * Attempt create the product into system
   * @param {import("./types").SchemaAddProduct} data - Infomration of the product
   */
  const attemptCreateProduct = async (data) => {
    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const wasCreated = await createProduct(data);

    if (wasCreated) {
      addDialog.close();
      formAdd.reset({});
      setState((current) => ({
        ...current,
        refetch: !current.refetch,
      }));
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));
  };

  /**
   * Set the product
   * @param {import("../../../../../../server/types/Catalogue/catalogue").GetProductI} product - Product
   * @returns {void}
   */
  const setProduct = (product) =>
    setState((current) => ({
      ...current,
      product,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      currentPage: page,
    }));

  const attemptSearch = () =>
    setState((current) => ({
      ...current,
      currentPage: 1,
      refetch: !current.refetch,
    }));

  /**
   * Set the currency for the filter search
   * @param {string} currency - Currency
   * @returns {void}
   */
  const setCurrency = (currency) =>
    setState((current) => ({
      ...current,
      currency,
    }));

  /**
   * Update the product of the system
   * @param {import("./types").SchemaUpdateProduct} data - Update the product on the system
   */
  const handleAttemptUpdate = async (data) => {
    setState((current) => ({
      ...current,
      isPerformingAbc: true,
    }));

    const dataRemaining = {
      excent: state.edition.excent,
      iva: state.edition.iva,
      satCode: state.edition.satCode,
      satUM: state.edition.satUM,
      idCatalogue: state.product.id,
      uen: state.edition.uen,
    };

    const wasUpdated = await updateProduct({ ...data, ...dataRemaining });

    if (wasUpdated) {
      customShowEdit(false);
      formEdit.reset({});
      setState((current) => ({
        ...current,
        refetch: !current.refetch,
      }));
    }

    setState((current) => ({
      ...current,
      isPerformingAbc: false,
    }));
  };

  /**
   * Set description query search
   * @param {string} description - Description of the product being searched
   * @returns {void}
   */
  const setDescription = (description) =>
    setState((current) => ({
      ...current,
      description,
    }));

  /**
   * Set sku query search
   * @param {string} sku - SKU of the product being searched
   * @returns {void}
   */
  const setSku = (sku) =>
    setState((current) => ({
      ...current,
      sku,
    }));

  /**
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/catalogo",
      text: "Procesos especiales",
    },
    {
      route: "/catalogo",
      text: "Catalogo productos y servicios",
    },
  ];

  return {
    ...state,
    customShowEdit,
    addDialog,
    breadcrum,
    formAdd,
    formEdit,
    attemptCreateProduct,
    idFormAdd: idFormAdd.current,
    idFormEdit: idFormEdit.current,
    setProduct,
    setPage,
    attemptSearch,
    updateSatData,
    setCurrency,
    editDialog,
    moreInfoDialog,
    handleAttemptUpdate,
    updateIvaData,
    setDescription,
    setSku,
  };
}
