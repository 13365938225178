import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import { ContextDocuments } from "..";

export default function AddQuote() {
  const hook = useContext(ContextDocuments);

  if (hook.document?.value !== 1) return <></>;

  return (
    <ButtonV2 onClick={hook.addQuote}>
      <span>Agregar</span>
      <Icon icon="plus" />
    </ButtonV2>
  );
}
