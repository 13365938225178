import React from "react";
import { LabelComponent } from "./styles";
import ui from "./styles.module.scss";

/**
 * Render a label tag
 * @param {import("./types").Label} props
 * @returns {JSX.Element} Label tag
 */
export default function Label(props) {
  let parsedProps = { ...props };
  // delete parsedProps.required;
  delete parsedProps.currentLength;
  delete parsedProps.maxLength;

  return (
    <LabelComponent {...parsedProps}>
      {props.children}

      {typeof props.currentLength === "number" &&
      typeof props.maxLength === "number" ? (
        <div className={ui.lengthInput}>
          <span>{props.currentLength}</span>
          <span>{props.maxLength}</span>
        </div>
      ) : null}
    </LabelComponent>
  );
}
