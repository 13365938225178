import {
  retrieveAttachedAdresses,
  retrieveEmailTemplate,
  updateEmailTemplate,
} from "helpers/Apis/email";
import { useEffect, useState } from "react";

const emailParameter = {
  subject: "",
  body: "",
  cc: [],
  automaticSend: false,
  to:[]
};

/**
 * @type {import("./types").StateUseEmailParameters}
 */
const INITIAL_STATE = {
  ...emailParameter,
  isLoading: true,
  isUpdating:false,
  loaded:false
};

/**
 * Hook to handle the parametrization of the emails on the system
 * @param {import("./types").ParamUseEmailParameters} param - Params to handle the hook
 * @returns {import("./types").ReturnUseEmailParameters}
 */
export default function useEmailParameters({ module = "complemento", id = null }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {

      setState(current=>({
        ...current,
        isLoading:true,
        loaded:false
      }));

      const [cc, template] = await Promise.all([
        retrieveAttachedAdresses("cc", module),
        retrieveEmailTemplate(module,id),
      ]);

      const ccToUse = [...new Set([...cc,...template.cc])];
      
      setState((current) => ({
        ...current,
        isLoading: false,
        cc:ccToUse,
        body:template.body,
        subject:template.subject,
        loaded:true,
        to:template.to
      }));
    })();
  }, [module]);

  async function update(){
    setState(current=>({
      ...current,
      isUpdating:true
    }));

    await updateEmailTemplate(module,state.body,state.cc);

    setState(current=>({
      ...current,
      isUpdating:false
    }));
  }

  function setBody(body){
    setState(current=>({
      ...current,
      body
    }));
  }

  function setCc(cc){
    setState(current=>({
      ...current,
      cc
    }));
  }

  return {
    ...state,
    update,
    setCc,
    setBody
  };
}
