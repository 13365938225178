import {
  AddCommentContainer,
  Comment,
  CommentItem,
} from "components/Sections/Documents/Comments/Containers";
import { AddPlus, Pen, Trash } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import { InputText } from "components/individual/inputs/Inputs";

import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";

export const QuoteCommentsSection = () => {
  const { documents } = useContext(WinQuoteContext);
  return (
    <>
      <AddCommentContainer>
        <div className="d-flex w-100">
          <InputText
            placeholderTxt="Escribe aqui"
            labelTxt="Comentario"
            // style={{ width: "95%;" }}
            aditionalClasses="commnetStyle"
            // onChange={handleOnCommentChange}
            // defaultValue={
            //   !!currentComment.comment && !currentComment.isEditing
            //     ? currentComment.comment
            //     : ""
            // }
            // disabled={currentComment.isEditing}
            onEnter={() => {
              //   addComment();
            }}

            // forwardRef={inputRef}
          />
          {/* <Icon
            // onClick={addComment={}}
            // className="aling-self-end"
            onKeyUp={(value) => {
              console.log(value);
            }}
          >
            {AddPlus}
          </Icon> */}
        </div>
        {!!documents.quote.comments.length ? (
          documents.quote.comments.map((comment) => (
            <CommentItem>
              <Comment>
                <p>{comment.comment}</p>
              </Comment>
              <Icon className="disable">{Trash}</Icon>

              <Icon className="disable">{Pen}</Icon>
            </CommentItem>
          ))
        ) : (
          <h2 className="text-center m-4">No hay comentarios para mostrar</h2>
        )}
      </AddCommentContainer>
    </>
  );
};
