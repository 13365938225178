import Select from "components/individual/SelectV4";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextOdc } from "../..";

export default function Status() {
  const { updateStatus } = useContext(ContextOdc);

  return (
    <div>
      <Label>Estatus</Label>
      <Select
        defaultSelected={{
          keyToCompare: "value",
          value: null,
        }}
        onChange={(data) => updateStatus(data.value)}
        labelToDisplay="label"
        options={async () => [
          {
            label: "Todos",
            value: null,
          },
          {
            label: "Activa",
            value: 10,
          },
          {
            label: "Enviada",
            value: 11,
          },
          {
            label: "Parcialmente recibida",
            value: 13,
          },
          {
            label: "Recibida",
            value: 17,
          },
          {
            label: "Cancelada",
            value: 12,
          },
          {
            label:"Cerrada",
            value:18
          }
        ]}
      />
    </div>
  );
}
