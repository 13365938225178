import React from "react";
import IconCheckIn from "./IconCheckIn";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import useCheckIn from "./useCheckIn";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function CheckInSetup() {
  const hook = useCheckIn();

  if (hook.isChecking)
    return (
      <div>
        <h2 className="text-center my-5">Revisando parametros</h2>
        <Spinner text={"Cargando"} hidden={false} />
      </div>
    );

  return (
    <div className={ui.checkIn}>
      <div>
        <div className={ui.itemCheckIn}>
          <IconCheckIn type={hook.invoiceIcon} /> <p>Entorno de facturacion</p>
        </div>

        <p>
          Este parametro indica si las llamadas de facturación (timbres,
          complementos, notas de crédito) son de prueba o de producción
        </p>
        <b className="my-3">{hook.checkOverview?.isInvoiceForProduction.message}</b>
      </div>

      <div>
        <div className={ui.itemCheckIn}>
          <IconCheckIn type={hook.csdIcon} /> <p>CSD</p>
        </div>
        <p>
          Indica si el sistema tiene cargado los archivos e información
          correspondientes para hacer timbres
        </p>
        <b className="my-3"> {hook.checkOverview?.csd.message}</b>

      </div>

      <div>
        <div className={ui.itemCheckIn}>
          <IconCheckIn type={hook.pdfDocumentsIcon} /> <p>PDF documentos</p>
        </div>
        <p>
          Sirve para generar los archivos PDF de los documentos creados en el
          sistema (Cotización, Pedidos, Contratos)
        </p>
      </div>

      <div>
        <div className={ui.itemCheckIn}>
          <IconCheckIn type={hook.odcIcon} /> <p>PDF orden de compra</p>
        </div>
        <p>
          Web service para generar los documentos pdf orden de comrpa
        </p>
      </div>


      <div>
        <div className={ui.itemCheckIn}>
          <IconCheckIn type={hook.blobIcon} /> <p>Repositorio de archivos</p>
        </div>

        <p>Para gestionar el guardado de archivos en la nube</p>
      </div>
      <ButtonV2 onClick={hook.attemptCheck}>
        <span>Revisar parametros</span>
        <Icon icon="check" />
      </ButtonV2>
    </div>
  );
}
