import LoginContext from "context/Login/LoginContext";
import { addFileBlobStorage } from "helpers/Apis/associateFilesV2";
import { useContext, useState } from "react";
import useDialog from "customHooks/useDialog";

export default function useAssociateFileModal(
  idRegister,
  idTypeEntity,
  pathUpload,
  resetFiles = () => {},
  idCloseModal,
  search
) {
  const [isLoading, setIsLoading] = useState(false);
  const dialog = useDialog();
  const { userInfo } = useContext(LoginContext);

  const addFiles = async (files, informativeFiles) => {
    setIsLoading(true);

    const wasUploaded = await addFileBlobStorage(
      files,
      informativeFiles,
      {
        fullName: userInfo[0].fullName,
        id: userInfo[0].userID,
      },
      idRegister,
      idTypeEntity,
      pathUpload
    );

    if (wasUploaded) search();

    setIsLoading(false);
    return wasUploaded;
  };

  return {
    isLoading,
    addFiles,
    dialog,
  };
}
