import { mnCurrency } from "helpers/money";
import React from "react";

/**
 * Render an autocalculated field according the CxP
 * @param {import('./types').PropsAutocalculated} props - Props
 * @returns {JSX.Element}
 */
export default function Autocalculated({ amount = undefined }) {
  if (amount === undefined) return <p className="d-flex justify-content-center my-2">-</p>;

  return <p className="d-flex justify-content-end my-2">{mnCurrency(amount)}</p>;
}
