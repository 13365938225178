import React from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ui from "./styles.module.scss";
import { useParams } from "react-router-dom";
import FormProyectsStructured from "structure/FormProyectsStructured";

export default function ProyectAdd() {
  const { id } = useParams();

  return (
    <div className={ui.container}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/proyectos",
            text: "Proyectos",
          },
          {
            route: "/proyectos/formulario",
            text: +id !== 0 ? "Editar Proyecto" : "Agregar Proyecto",
          },
        ]}
      />

      <FormProyectsStructured idProyect={+id} />
    </div>
  );
}
