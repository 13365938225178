import { Spinner } from "components/individual/Spinner/Spinner";
import { getSchemaPermissions } from "helpers/Apis/rolesApi";
import React, { useContext, useEffect, useState } from "react";
// import { useTreePermissions, TreePermissions } from "react-tree-permissions";
// import "react-tree-permissions/dist/styles.css";
import { AddRolContext } from "..";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import LoadingText from "components/individual/TextLoading";

export default function Step3() {
  const [schemaTree, setSchemaTree] = useState(null);

  useEffect(() => {
    (async function () {
      const apiAchemaTree = await getSchemaPermissions();
      setSchemaTree(apiAchemaTree);
    })();
  }, []);

  const { updatePermissions } = useContext(AddRolContext);

  // const { handleCollapse, setPermissionsChecked, permissions } =
  //   useTreePermissions([]);

  // useEffect(() => {
  //   updatePermissions(permissions.active);
  // }, [permissions.active]);

  if (!schemaTree)
    return (
      <Spinner
        idSpinner="loadingSchemaTree"
        hidden={false}
        text={<LoadingText>Cargando grid de permisos</LoadingText>}
      />
    );

  return (
    <Tree
      treeData={schemaTree}
      checkable
      showLine
      showIcon={false}
      onCheck={updatePermissions}
    />
  );
}
