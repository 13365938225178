import React from "react";
import { useContext } from "react";
import { ContextRateTicket } from "../rateTicket";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function ButtonRate() {
  const hook = useContext(ContextRateTicket);

  if (hook.grade.isRating)
    return (
      <div className="d-flex justify-content-between">
        <p></p>
        <Spinner hidden={false} text="Calificando" />
      </div>
    );

  return (
    <div className="d-flex justify-content-between align-items-center">
      <p className="text-danger">
        {hook.grade.rate <= 0 ? "Califica en un rango del 1 al 5" : ""}
      </p>
      <ButtonV2
        type="button"
        onClick={hook.attemptRatingTicket}
        disabled={hook.grade.rate <= 0}
      >
        Calificar
      </ButtonV2>
    </div>
  );
}
