import LoginContext from "context/Login/LoginContext";
import { executivesFilter } from "helpers/Apis/Executives";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";

/**
 * @type {import("helpers/Apis/typesExecutives").ExecutiveI[]}
 */
const emptyExecutives = [];
const defaultExecutive = 0;

export const useExecutiveToRepresent = () => {
  const [executives, setExecutives] = useState(emptyExecutives);
  const [executive, setExecutive] = useState(null);
  const [active, setActive] = useState(defaultExecutive);

  const {
    rolId,
    userId,
    handleOnRepresentativeExecutiveChange,
    userToRepresentId,
  } = useContext(LoginContext);

  useEffect(() => {
    (async function () {
      if (typeof rolId !== "number") return;

      const apiExecutives = await executivesFilter(rolId);

      setExecutives(apiExecutives);
    })();
  }, [rolId]);

  useEffect(() => {
    if (!!userToRepresentId) {
      setActive(userToRepresentId);
    }
  }, [userToRepresentId]);

  const executiveMemo = useMemo(() => {
    const fixExecutives = executives.map((item) => {
      item.checked = userId === item.idUser ? true : false;
      return item;
    });
    return fixExecutives;
  }, [executives, userId]);

  const handleOnChangeExecutive = (value) => {
    setExecutive(value);
  };

  const handleOnChangeExecutiveV2 = useCallback((value) => {
    setExecutive(value);
  }, []);

  return {
    executives,
    executiveMemo,
    executive,
    userId,
    handleOnChangeExecutive,
    handleOnChangeExecutiveV2,
    handleOnRepresentativeExecutiveChange,
    userToRepresentId,
    active,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/representante",
        text: "Representante",
      },
    ],
  };
};
