import React, { useContext } from "react";
import { ContextEditRol } from "..";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import style from './styles.module.scss';

export default function Step2() {
  const {
    permissions: permissionsChecked,
    schemaTreePermissions,
    updateMemoryPermissions,
  } = useContext(ContextEditRol);

  return (
    <Tree
      treeData={schemaTreePermissions}
      className={style.treePermissions}
      checkable
      showLine
      showIcon={false}
      defaultCheckedKeys={permissionsChecked}
      onCheck={(data,detailedData) => {
        updateMemoryPermissions({
          revokedPermissions: [],
          grantedPermissions: [...data,...detailedData.halfCheckedKeys],
          checkedPermissions: [],
        });
      }}
    />
  );
}
