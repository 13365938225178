import {
  disassociateConcept,
  getConceptAssociatedToIncoming,
} from "helpers/Apis/concilation";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Concept association overview
 * @param {import("./types").ParamsUseConcept} param - Params in order to make the custom hook work
 */
export default function useConcept({
  idMovement = 378,
  onDisassociated = () => {},
}) {
  /**
   * @type {import("./types").StateDisassociate}
   */
  const INITIAL_STATE = {
    isLoading: true,
    info: undefined,
    isDisassociating: false,
  };

  const [concept, setConcept] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiConcept = await getConceptAssociatedToIncoming(idMovement);

      setConcept({
        isLoading: false,
        info: apiConcept,
      });
    })();
  }, [idMovement]);

  const attemptDisassociate = async () => {
    setConcept({
      ...concept,
      isDisassociating: true,
    });

    const disassociated = await disassociateConcept(idMovement);
    if (disassociated) onDisassociated();

    setConcept({
      ...concept,
      isDisassociating: false,
      onDisassociated,
    });
  };

  return {
    concept,
    attemptDisassociate,
  };
}
