import { mnCurrency } from "helpers/money";

export const SET_ITEMS = "SET_ITEMS";
export const SET_DOC = "SET_DOC";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_EXECUTIVE = "SET_EXECUTIVE";
export const SET_CONTRACT_PRICES = "SET_CONTRACT_PRICES";
export const SET_CUSTOMER_INVOICE = "SET_CUSTOMER_INVOICE";
export const SET_CONTACT_INVOICE = "SET_CONTACT_INVOICE";
export const SET_IS_UPDATING = "SET_IS_UPDATING";

export const initialState = {
  document: null,
  comments: {
    toAdd: [],
    toUpdate: [],
    toDelete: [],
  },
  items: [],
  executive: {
    id: null,
    fullName: null,
  },
  isUpdating: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case SET_IS_UPDATING:
      return {
        ...state,
        isUpdating: action.payload,
      };

    case SET_CONTRACT_PRICES:
      console.log(action.payload);
      //       importe
      // total
      // iva

      console.log({
        ...state,
        document: {
          ...state.document,
          amount: {
            ...state.document.amount,
            total: {
              number: action.payload.total,
              text: mnCurrency(action.payload.total),
            },
            acumulated: {
              number: action.payload.acumulated,
              text: mnCurrency(action.payload.acumulated),
            },
            residue: {
              number: action.payload.residue,
              text: mnCurrency(action.payload.residue),
            },
            iva: {
              number: action.payload.iva,
              text: mnCurrency(action.payload.iva),
            },
            subtotal: {
              number: action.payload.importe,
              text: mnCurrency(action.payload.importe),
            },
          },
        },
      });

      return {
        ...state,
        document: {
          ...state.document,
          amount: {
            ...state.document.amount,
            total: {
              number: action.payload.total,
              text: mnCurrency(action.payload.total),
            },
            acumulated: {
              number: action.payload.acumulated,
              text: mnCurrency(action.payload.acumulated),
            },
            residue: {
              number: action.payload.residue,
              text: mnCurrency(action.payload.residue),
            },
            iva: {
              number: action.payload.iva,
              text: mnCurrency(action.payload.iva),
            },
            subtotal: {
              number: action.payload.importe,
              text: mnCurrency(action.payload.importe),
            },
          },
        },
      };

    case SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    case SET_DOC:
      return {
        ...state,
        document: action.payload,
      };

    case SET_EXECUTIVE:
      return {
        ...state,
        executive: {
          id: action.payload.id,
          fullName: action.payload.fullName,
        },
      };

    case SET_COMMENTS:
      const toAdd = action.payload.comments.filter(
        (comment) => typeof comment.id === "string"
      );
      const toUpdate = action.payload.comments.filter(
        (comment) => typeof comment.id !== "string"
      );
      const toDelete = action.payload.commentsToDelete;

      return {
        ...state,
        comments: {
          toAdd,
          toUpdate,
          toDelete,
        },
      };

    case SET_CUSTOMER_INVOICE:
      return {
        ...state,
        document: {
          ...state.document,
          customer: {
            ...state.document.customer,
            id: action.payload,
          },
          contact: {
            ...state.document.contact,
            id: null,
          },
        },
      };

    case SET_CONTACT_INVOICE:
      return {
        ...state,
        document: {
          ...state.document,
          contact: {
            ...state.document.contact,
            ...action.payload,
            id: action.payload?.contactID || null,
          },
        },
      };
    default:
      return state;
  }
}
