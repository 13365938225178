import { Spinner } from "components/individual/Spinner/Spinner";
import React, { Fragment, useRef } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { useExecutiveToRepresent } from "./hooks/useExecutiveToRepresent";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { useHistory } from "react-router-dom";
import ui from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import usePermissions from "customHooks/usePermissions";

export const RepresentativeExecutive = () => {
  const key = useRef(`${window.crypto.randomUUID()}`);

  usePermissions("ae3302c7-be66-468a-89ee-33d8258852aa");

  const { executives, handleOnRepresentativeExecutiveChange, breadcrum } =
    useExecutiveToRepresent();
  const history = useHistory();

  return (
    <main className={ui.executivesModule}>
      <BreadCrumV2 path={breadcrum} />

      <div className="p-4">
        <h3>Representar a</h3>
        <form
          className={ui.formExecutives}
          id="representante"
          onSubmit={(e) => {
            e.preventDefault();
            handleOnRepresentativeExecutiveChange(
              +e.target.elements.executives.value
            );
            history.push(`/inicio`);
          }}
        >
          {executives.length === 0 ? (
            <Spinner hidden={false} text="Cargando ejecutivos" />
          ) : (
            <div className={ui.items}>
              {executives.map((item, i) => (
                <Fragment key={`${key.current}-${i}`}>
                  <input
                    type="radio"
                    name="executives"
                    value={item.idUser}
                    className="d-none"
                    id={`${key.current}-${i}`}
                  />

                  <label
                    className={ui.itemUser}
                    htmlFor={`${key.current}-${i}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {item.fullName}
                  </label>
                </Fragment>
              ))}
            </div>
          )}

          <div>
            <ButtonV2>
              <span>Confirmar</span>
              <Icon icon="save" />
            </ButtonV2>
          </div>
        </form>
      </div>
    </main>
  );
};
