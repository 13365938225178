import { useEffect, useState } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { mnCurrency, truncateDecimals } from "helpers/money";
import { groupTaxes } from "helpers/Apis/invoice";
const { Parser } = require("xml2js");

const initialFiles = {
  xml: {
    informative: null,
    file: null,
  },
  pdf: {
    informative: null,
    file: null,
  },
};

/**
 * Handle the sat file validations
 * @param {import("types/typedef/files").InformativeFileI[]?} paramInformative - Info to display on the UI
 * @param {File[]?} paramFiles - File instances
 * @param {(object:(import("types/typedef/customHooks/useReceiveFiles").OnChangeUseReceiveFilesI))=>void} onChange - Callback when info changes
 * @returns
 */
function useReceiveFiles(
  paramInformative = [],
  paramFiles = [],
  onChange = () => {},
  onChangePdf = () => {}
) {
  /**
   * @type {[import("types/typedef/customHooks/useReceiveFiles").useFileReceiveI,()=>void]}
   */
  const [
    {
      xml,
      pdf,
      noFilesUploaded,
      noXmlFilesUploaded,
      noPdfFilesUploaded,
      isValidXmlFormat,
    },
    setState,
  ] = useState({
    ...initialFiles,
    noFilesUploaded: 0,
    noXmlFilesUploaded: 0,
    noPdfFilesUploaded: 0,
    isValidFormatXml: false,
    xmlJson: null,
    rfcEmitter: null,
    rfcReceptor: null,
    total: null,
    uuid: null,
  });

  useEffect(() => {
    const noFilesUploaded = paramInformative.length;

    if (noFilesUploaded > 2) {
      onChangePdf({
        informative: null,
        file: null,
      });
      // return;
    }

    setState({ ...initialFiles, noFilesUploaded });
    const {
      fileInfo: { pdf, xml },
      noXmlFilesUploaded: computedNoXmlFilesUploaded,
      noPdfFilesUploaded: computedNoPdfFilesUploaded,
    } = discriminateFiles();

    setState({
      xml,
      pdf,
      noFilesUploaded,
      noXmlFilesUploaded: computedNoXmlFilesUploaded,
      noPdfFilesUploaded: computedNoPdfFilesUploaded,
    });

    if (xml.informative === null) {
      onChange({
        isValidFileUpload: false,
        xmlJson: null,
        extractedXml: null,
        xml: null,
        pdf,
        rfcEmitter: null,
        rfcReceptor: null,
        total: null,
        uuid: null,
      });
      // onChange({
      //   xmlJson: null,
      //   xml,
      //   pdf,
      //   extractedXml: null,
      //   isValidFileUpload: false,
      // });
    }

    onChangePdf(pdf);
    // onChange({
    //   xmlJson: null,
    //   xml,
    //   pdf,
    //   extractedXml: null,
    //   isValidFileUpload: false,
    // });
  }, [paramFiles, paramInformative]);

  useEffect(() => {
    (async function () {
      if (noXmlFilesUploaded >= 2 || xml.file === null) {
        onChange({
          isValidFileUpload: false,
          xmlJson: null,
          extractedXml: null,
          xml: null,
          pdf,
          rfcEmitter: null,
          rfcReceptor: null,
          total: null,
          uuid: null,
        });
        return;
      }

      const xmlReader = new Parser({
        explicitArray: false,
      });

      const textXml = await xml.file.text();

      const jsonXml = await new Promise((resolve, reject) => {
        xmlReader.parseString(textXml, (error, result) => {
          resolve(result);
        });
      });

      console.log(jsonXml);

      const rfcEmitter =
        jsonXml["cfdi:Comprobante"]?.["cfdi:Emisor"]["$"]["Rfc"];

      const rfcReceptor =
        jsonXml["cfdi:Comprobante"]?.["cfdi:Receptor"]["$"]["Rfc"];

      const total = +jsonXml["cfdi:Comprobante"]?.["$"]["Total"];

      /**
       * UUID of the invoice
       * @type {string}
       */
      const uuid =
        jsonXml["cfdi:Comprobante"]?.["cfdi:Complemento"][
          "tfd:TimbreFiscalDigital"
        ]["$"]["UUID"];

      const emitedDate = jsonXml["cfdi:Comprobante"]?.["$"]["Fecha"];

      const currency = jsonXml["cfdi:Comprobante"]?.["$"]["Moneda"];

      const importe = +jsonXml["cfdi:Comprobante"]["$"]["SubTotal"];

      const discount = +jsonXml["cfdi:Comprobante"]["$"]?.["Descuento"] || 0;

      const impuestosRetenidos =
        +jsonXml["cfdi:Comprobante"]["cfdi:Impuestos"]["$"]?.[
          "TotalImpuestosRetenidos"
        ] || 0;
      const impuestosTrasladados =
        +jsonXml["cfdi:Comprobante"]["cfdi:Impuestos"]["$"]?.[
          "TotalImpuestosTrasladados"
        ] || 0;

      const folio =
        jsonXml["cfdi:Comprobante"]["$"]["Folio"] ||
        uuid.substring(uuid.length - 6, uuid.length);

      const socialReasonCustomer =
        jsonXml["cfdi:Comprobante"]?.["cfdi:Emisor"]["$"]["Nombre"];

      const isValidXmlFormat =
        !rfcEmitter || !rfcReceptor || !total || !uuid ? false : true;

      const typeOfImpuestoTraslado =
        typeof jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
          "cfdi:Traslados"
        ]?.["cfdi:Traslado"];

      const impuestosTrasladadosLista =
        typeOfImpuestoTraslado === "object"
          ? [
              {
                Impuesto:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Traslados"
                  ]?.["cfdi:Traslado"]?.["$"]?.["Impuesto"] || null,
                TipoFactor:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Traslados"
                  ]?.["cfdi:Traslado"]?.["$"]?.["TipoFactor"] || null,
                TasaOCuota:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Traslados"
                  ]?.["cfdi:Traslado"]?.["$"]?.["TasaOCuota"] || null,
                Importe:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Traslados"
                  ]?.["cfdi:Traslado"]?.["$"]?.["Importe"] || null,
                Base:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Traslados"
                  ]?.["cfdi:Traslado"]?.["$"]?.["Base"] || null,
              },
            ]
          : jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
              "cfdi:Traslados"
            ]?.["cfdi:Traslado"].map((impuesto) => {
              return {
                Impuesto: impuesto["$"]?.Impuesto || null,
                TipoFactor: impuesto["$"]?.TipoFactor || null,
                TasaOCuota: impuesto["$"]?.TasaOCuota || null,
                Importe: impuesto["$"]?.Importe || null,
                Base: impuesto["$"]?.Base || null,
              };
            }) || [];

      const typeofimpuestosRetenidosLista =
        typeof jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
          "cfdi:Retenciones"
        ]?.["cfdi:Retencion"];

      /**
       * @type {import("types/typedef/customHooks/useReceiveFiles").Impuesto[]}
       */
      const impuestosRetenidosLista =
        typeofimpuestosRetenidosLista === "object"
          ? [
              {
                Impuesto:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Retenciones"
                  ]?.["cfdi:Retencion"].Impuesto || 0,
                Importe:
                  jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
                    "cfdi:Retenciones"
                  ]?.["cfdi:Retencion"].Importe || 0,
                TipoFactor: "Tasa",
                TasaOCuota: 0,
                Base: 0,
              },
            ]
          : jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.[
              "cfdi:Retenciones"
            ]?.["cfdi:Retencion"].map((impuesto) => {
              return {
                Impuesto: impuesto["$"]?.Impuesto || null,
                Importe: impuesto["$"]?.Importe || null,
                TipoFactor: null,
                TasaOCuota: null,
                Base: null,
              };
            }) || [];

      // /**
      //  * @type {import("types/typedef/customHooks/useReceiveFiles").Impuesto[]}
      //  */
      // const impuestosRetenidosLista =
      //   jsonXml["cfdi:Comprobante"]?.["cfdi:Impuestos"]?.["cfdi:Retenciones"]?.[
      //     "cfdi:Retencion"
      //   ].map((impuesto) => {
      //     return {
      //       Impuesto: impuesto["$"]?.Impuesto || null,
      //       Importe: impuesto["$"]?.Importe || null,
      //       TipoFactor: null,
      //       TasaOCuota: null,
      //       Base: null,
      //     };
      //   }) || [];

      /**
       * @type {import("types/typedef/customHooks/useReceiveFiles").Impuesto[]}
       */
      const impuestosRetenidoIva = impuestosTrasladadosLista.reduce(
        (total, impuesto) => {
          if (impuesto.Impuesto === "002") {
            return (total += impuesto.Importe);
          }

          return total;
        },
        0
      );
      const impuestosTrasladadosIva = impuestosRetenidosLista.reduce(
        (total, impuesto) => {
          if (impuesto.Impuesto === "002") {
            return (total += impuesto.Importe);
          }
          return total;
        },
        0
      );

      const iva = impuestosRetenidoIva - impuestosTrasladadosIva;

      const taxesTransferred = groupTaxes(impuestosTrasladadosLista, true);
      const taxesWithheld = groupTaxes(impuestosRetenidosLista, false);

      const trasladosIvaTotal = taxesTransferred.iva.reduce((total, tax) => {
        const newTotal = +(
          +total.toFixed(2) + +(+tax.Importe).toFixed(2)
        ).toFixed(2);
        return newTotal;
      }, 0);

      const trasladosIepsTotal = taxesTransferred.ieps.reduce((total, tax) => {
        const newTotal = +(
          +total.toFixed(2) + +(+tax.Importe).toFixed(2)
        ).toFixed(2);
        return newTotal;
      }, 0);

      const retenidosIvaTotal = taxesWithheld.iva.reduce((total, tax) => {
        const newTotal = +(
          +total.toFixed(2) + +(+tax.Importe).toFixed(2)
        ).toFixed(2);
        return newTotal;
      }, 0);

      const retenidosIsrTotal = taxesWithheld.isr.reduce((total, tax) => {
        const newTotal = +(
          +total.toFixed(2) + +(+tax.Importe).toFixed(2)
        ).toFixed(2);
        return newTotal;
      }, 0);

      const retenidosIepsTotal = taxesWithheld.ieps.reduce((total, tax) => {
        const newTotal = +(
          +total.toFixed(2) + +(+tax.Importe).toFixed(2)
        ).toFixed(2);
        return newTotal;
      }, 0);

      setState({
        ...{
          xml,
          pdf,
          noFilesUploaded,
          noXmlFilesUploaded,
          noPdfFilesUploaded,
        },
        isValidXmlFormat,
        xmlJson: jsonXml,
      });

      if (!isValidXmlFormat) return;

      console.log(jsonXml);

      onChange({
        xmlJson: jsonXml,
        xml,
        pdf,
        extractedXml: {
          rfcEmitter,
          rfcReceptor,
          total: {
            formatted: mnCurrency(total),
            number: total,
          },
          importe: {
            formatted: mnCurrency(importe),
            number: importe,
          },
          iva: {
            number: isNaN(iva) ? null : iva,
            formatted: isNaN(iva) ? "ND" : mnCurrency(iva),
          },
          cfdiRelacionados:
            jsonXml["cfdi:Comprobante"]?.["cfdi:CfdiRelacionados"]?.[
              "cfdi:CfdiRelacionado"
            ]?.["$"]?.["UUID"] || [],
          impuestos: {
            trasladados: impuestosTrasladadosLista,
            retenidos: impuestosRetenidosLista,
            agrupados: {
              trasladados: taxesTransferred,
              retenidos: taxesWithheld,
              total: {
                traslados: {
                  iva: trasladosIvaTotal,
                  ieps: trasladosIepsTotal,
                },
                retenidos: {
                  isr: retenidosIsrTotal,
                  iva: retenidosIvaTotal,
                  ieps: retenidosIepsTotal,
                },
              },
            },
          },
          impuestosTrasladados: {
            number: isNaN(impuestosTrasladados) ? null : impuestosTrasladados,
            formatted: isNaN(impuestosTrasladados)
              ? "$0.0"
              : mnCurrency(impuestosTrasladados),
          },
          impuestosRetenidos: {
            number: isNaN(impuestosRetenidos) ? null : impuestosRetenidos,
            formatted: isNaN(impuestosRetenidos)
              ? "$0.0"
              : mnCurrency(impuestosRetenidos),
          },
          discount: {
            number: discount,
            formatted: mnCurrency(discount),
          },
          uuid,
          currency,
          socialReasonCustomer,
          emitedDate: {
            jsDate: new Date(emitedDate),
            db: emitedDate,
            ui: format(new Date(emitedDate), "dd/MMM/yy", {
              locale: es,
            }),
          },
          folio,
        },
        isValidFileUpload: isValidXmlFormat,
      });
    })();
  }, [xml, pdf]);

  /**
   * Parse the files from the arguments
   * @returns {import("types/typedef/customHooks/useReceiveFiles").ParsedFilesArgsI}
   */
  function discriminateFiles() {
    const filesSelected = [...paramInformative].splice(0, 2);

    let assginedSelection = {
      xml: {
        informative: null,
        file: null,
      },
      pdf: {
        informative: null,
        file: null,
      },
    };

    if (filesSelected.length <= 0) {
      return {
        fileInfo: assginedSelection,
        noXmlFilesUploaded: 0,
        noPdfFilesUploaded: 0,
      };
    }

    let pdfLoaded = 0;
    let xmlLoaded = 0;

    filesSelected.forEach((element, i) => {
      if (element.extension === "pdf") {
        pdfLoaded++;
        assginedSelection = {
          ...assginedSelection,
          pdf: {
            informative: element,
            file: paramFiles[i],
          },
        };
      }

      if (element.extension === "xml") {
        xmlLoaded++;
        assginedSelection = {
          ...assginedSelection,
          xml: {
            informative: element,
            file: paramFiles[i],
          },
        };
      }
    });

    return {
      fileInfo: assginedSelection,
      noXmlFilesUploaded: xmlLoaded,
      noPdfFilesUploaded: pdfLoaded,
    };
  }

  return {
    isLoadedXml: xml.file === null ? false : true,
    areMaxTwoFiles: noFilesUploaded <= 2 ? true : false,
    isOnePdfFile: noPdfFilesUploaded <= 1 ? true : false,
    noXmlFilesUploaded,
    noPdfFilesUploaded,
    isValidXmlFormat,
  };
}

export default useReceiveFiles;
