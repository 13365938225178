import Currency from "components/individual/inputs/Currency";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCashFlow } from "..";

export default function CurrencyCashFlow() {
  const hook = useContext(ContextCashFlow);

  return (
    <div>
      <Label required={typeof hook.cashFlow.currency !== "string"}>
        Moneda
      </Label>
      <Currency
        includeAll={true}
        onChange={hook.updateCurrency}
        selected={hook.cashFlow.currency}
      />
    </div>
  );
}
