import { getCatalogueEnterprise } from "helpers/Apis/catalogue";
import { useEffect, useState } from "react";

export default function useItemSku(defaultValue = null) {
  const [state, setState] = useState({
    catalogue: [],
    isLoading: true,
    item: null,
    ungroupedCatalogue: [],
  });

  const [catalogue, setCatalogue] = useState([]);

  const idBtnOpenNewItemCatalogue = window.crypto.randomUUID();

  useEffect(()=>{
    if(state.ungroupedCatalogue.length<=0 || typeof defaultValue !== "number") return;

    const item = state.ungroupedCatalogue.find(itemCatalogue=>itemCatalogue.id===defaultValue);

    setState(current=>({
      ...current,
      item:{
        ...item,
        value:item.id,
        label:item.sku.trim(),
      }
    }));
  },[defaultValue,state.ungroupedCatalogue])

  /**
   * Update the item that  has been selected on the select combo
   * @param {import("types/typedef/catalogue").CatalogueItemI|null} item - Information of the item to use
   * @returns {void}
   */
  const setItem = (item) =>
    setState({
      ...state,
      item,
    });
  /**
   * Set the item information and open the modal
   * @param {object} item - Informatio typed on the combo
   */
  const handleNewItem = (item) => {
    const domBtn = document.getElementById(idBtnOpenNewItemCatalogue);
    setItem(item);
    if (domBtn) {
      domBtn.click();
    } else {
      alert("No se pudo abrir el modal");
    }
  };
  /**
   * Set the item information and open the modal
   * @param {object} item - Informatio typed on the combo
   */
  const handleNewItemV2 = (item) => {
    setItem(item);
  };

  const addToCatalogue = (item) => {
    const uenToSearch = item.uen.description;

    let indexUen = state.catalogue.findIndex(
      (item) => item.label === uenToSearch
    );

    if (indexUen === -1) {
      indexUen = 0;
    }

    let updatedFamily = [...(state.catalogue[indexUen]?.options || [])];

    updatedFamily.unshift(item);

    let catalogue =
      state.catalogue.length <= 0
        ? [
            {
              label: item.sku,
              options: [item],
            },
          ]
        : [...state.catalogue];

    catalogue[state.catalogue.length <= 0 ? 0 : indexUen].options = [
      ...updatedFamily,
    ];

    const newState = {
      isLoading: false,
      catalogue,
      item: null,
    };

    setCatalogue(catalogue);
  };

  useEffect(() => {
    // console.log("effect", state.catalogue);
  }, [state]);

  useEffect(() => {
    (async function () {
      const apiCatalogue = await getCatalogueEnterprise();
      // const apiCatalogue = [];

      const groupedCatalogue = apiCatalogue.reduce((indexed, item) => {
        const optionsAppended = !indexed[item.uen.id]
          ? []
          : indexed[item.uen.id].options;

        return {
          ...indexed,
          [item.uen.id]: {
            // label: item.uen.description,
            label: item.sku,
            options: [
              ...optionsAppended,
              {
                ...item,
                // label: item.description,
                label: item.sku,
                value: item.id,
                catalogue: {
                  id: item.id,
                  //   description: item.description,
                  description: item.sku,
                  cu: item.cu.number,
                  pu: item.pu.number,
                  iva: item.iva.number,
                  currency: item.currency,
                },
              },
            ],
          },
        };
      }, {});

      const groupedCatalogueParsed = Object.entries(groupedCatalogue).map(
        ([key, value]) => ({
          label: value.label,
          options: value.options,
        })
      );

      setCatalogue(groupedCatalogueParsed);

      setState({
        isLoading: false,
        catalogue: groupedCatalogueParsed,
        ungroupedCatalogue:apiCatalogue
      });
    })();
  }, []);

  return {
    catalogue,
    isLoading: state.isLoading,
    addToCatalogue,
    idBtnOpenNewItemCatalogue,
    handleNewItem,
    setItem,
    item: state.item,
    handleNewItemV2,
  };
}
