import AutocompleteEmail from "components/individual/AutocompleteEmail";
import InformativeTooltip from "components/individual/InfoTooltip";
import React, { Fragment, useRef } from "react";
import useEmailParameters from "./useEmailParameters";
import { Spinner } from "components/individual/Spinner/Spinner";
import CommentBox from "components/individual/CommentBox";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function EmailParameters() {
  const complement = useEmailParameters({ module: "complemento" });

  return (
    <div className={ui.emailContainer}>
      <details open>
        <summary>Complementos</summary>

        {complement.loaded ? (
          <Fragment>
            <br />
            <b>TO</b>
            <p>
              1. Contactos de la razón social marcados como "Contacto de pagos"
              2. Si no existe algun contacto, por default al correo del cliente
            </p>

            <b>CC</b>
            <p>1. Ejecutivo que timbró el complemento</p>
            <p>2. Contacto relacionado a la(s) factura(s) emitida(s)</p>
            <p>3. Correos atachados del campo "Correos con copia"</p>

            <b>Nota</b>
            <p>Si la razon social del complemento timbrado no tiene contacto relacionado en sus facturas ni contactos marcados como "Contacto de pagos" se envia al correo de facturación que tiene registrado la razón social</p>

            <InformativeTooltip>
              Estos correos se iran adjuntados de forma automatica en el campo
              CC siempre y cuando se este enviando una factura timbrada
            </InformativeTooltip>
            
            <AutocompleteEmail
              aditionalEmails={complement.cc}
              onChange={(emails) => complement.setCc(emails)}
              label="Correos con copia"
            />

            <br />
            <b>Cuerpo del correo</b>
            <CommentBox
              value={complement.body}
              type="complemento"
              onChange={(content) => complement.setBody(content)}
            />
            
            <div className={ui.updateFooter}>
            {complement.isUpdating ? (
              <Spinner text={"Actualizando"} hidden={false} />
            ) : (
              <ButtonV2 onClick={async () => await complement.update()}>
                <span>Guardar</span>
                <Icon icon="save" />
              </ButtonV2>
            )}
            </div>
          </Fragment>
        ) : null}
      </details>
    </div>
  );
}
