import React, { useContext, useRef } from "react";
import { Label } from "structure/Document";
import { ContextInvoice } from "../..";
import ui from "./styles.module.scss";
import { CancelInvoiceButton } from "components/general/Documents/Buttons/CancelInvoiceButton";
import { parseDateToText } from "helpers/dates";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import ModalCreateInvoiceV2 from "structure/ModalCreateInvoiceV2";

/**
 *
 * @param {import("customHooks/useAdministrationInvoice/types").InvoiceI} invoice
 * @returns
 */
export default function MobileNoAccounting(invoice) {
  const hook = useContext(ContextInvoice);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const modalCreateInvoice = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const modalCancelFe = useRef();

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <>
      <ModalCreateInvoiceV2
        idOrder={invoice.idPreinvoice}
        idStatus={invoice.status.id}
        evaluatePermission={true}
        forwardRef={modalCreateInvoice}
        onCreated={hook.searchWithFilters}
      >
        <></>
      </ModalCreateInvoiceV2>

      <CancelInvoiceButton
        uuid={invoice.uuid}
        forwardRef={modalCancelFe}
        onCancelled={hook.searchWithFilters}
      >
        <></>
      </CancelInvoiceButton>

      <div className={ui.mobileItem}>
        <div className={ui.options}>
          <div>
            <Label>No.</Label>
            <p>{invoice.numeroDocumento}</p>
          </div>

          <ElipsisMobile onShow={() => hook.setRowSelected(invoice)}>
            <CustomMenuItem onClick={hook.viewDocument}>
              Ver documento
            </CustomMenuItem>

            {invoice.status.id === 4 ? (
              <CustomMenuItem
                onClick={() => modalCreateInvoice.current.click()}
              >
                Timbrar
              </CustomMenuItem>
            ) : null}

            <CustomMenuItem onClick={hook.redirectAssociatedFiles}>
              Archivos asociados
            </CustomMenuItem>

            {invoice.isEditable && hook.from !== "contabilidad" ? (
              <CustomMenuItem onClick={hook.editDocument}>
                Editar
              </CustomMenuItem>
            ) : null}

            <CustomMenuItem onClick={hook.redirectToDo}>To Do</CustomMenuItem>

            <CustomMenuItem
              disabled={hook.state.isDownloadingPdf}
              onClick={hook.downloadPdf}
            >
              {hook.state.isDownloadingPdf ? "Descargando PDF..." : "PDF"}
            </CustomMenuItem>

            {invoice.status.id === 7 && hook.from === "facturas_emitidas" ? (
              <CustomMenuItem onClick={() => modalCancelFe.current.click()}>
                Cancelar
              </CustomMenuItem>
            ) : null}

            {invoice.status.id === 4 && hook.from === "pedidos" ? (
              <CustomMenuItem
                onClick={hook.promptCancelPreinvoice}
                disabled={hook.state.isCancelling}
              >
                {hook.state.isCancelling ? "Cancelando..." : "Cancelar"}
              </CustomMenuItem>
            ) : null}
          </ElipsisMobile>
        </div>

        <div className={ui.items}>
          <div>
            <Label>Fecha de emisión</Label>
            <p>
              {parseDateToText(
                new Date(invoice.emited || `${invoice.registro.yyyymmdd}:`)
              )}
            </p>
          </div>

          {hook.from !== "contabilidad" ? (
            <div>
              <Label>Fecha de vencimiento</Label>
              <p>{invoice.expirationDateFl}</p>
            </div>
          ) : null}
        </div>

        <div>
          <Label>Razón social</Label>
          <p>{invoice.razonSocial}</p>
        </div>

        <div className={ui.items}>
          <div>
            <Label>Moneda</Label>
            <p>{invoice.moneda}</p>
          </div>

          <div>
            <Label>Importe</Label>
            <p className="text-right">{invoice.import}</p>
          </div>

          <div>
            <Label>IVA</Label>
            <p className="text-right">{invoice.iva}</p>
          </div>

          <div>
            <Label>Total</Label>
            <p className="text-right">{invoice.total.texto}</p>
          </div>

          {hook.from !== "contabilidad" ? (
            <div>
              <Label>Saldo CxC</Label>
              <p className="text-right">{invoice.saldo}</p>
            </div>
          ) : null}
        </div>

        {hook.from !== "contabilidad" ? (
          <div>
            <Label>Estatus</Label>
            <p>{invoice.status.description}</p>
          </div>
        ) : (
          <div className="d-flex">
            <label
              htmlFor={`${key.current}-${invoice.id}-accounting`}
              className="d-flex justify-content-center align-items-center"
            >
              <input
                onChange={(e) => hook.toggleAccountingCheck(invoice.id)}
                type="checkbox"
                id={`${key.current}-${invoice.id}-accounting`}
                checked={
                  hook.state.accountingStatusGrid.operation?.[invoice.id] ||
                  false
                }
              />
            </label>
            Contabilizado
          </div>
        )}
      </div>
    </>
  );
}
