import React, { useContext, useState } from "react";
import { AddReceptionInvoiceContext } from "../../Filter";
import { Step2Header } from "../Step2/styles";
import GridPartialities from "pages/Directory/Documents/Invoice/GridPartialities/GridPartialities";
import { Select } from "components/individual/inputs/Inputs";
import { parseDateToText } from "helpers/dates";

export default function Step3() {
  const { state, setPartialities } = useContext(AddReceptionInvoiceContext);

  const [step3, setStep3] = useState({
    partialitiesList: [
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
      {
        value: 8,
      },
      {
        value: 9,
      },
      {
        value: 10,
      },
      {
        value: 11,
      },
      {
        value: 12,
      },
    ],
  });

  return (
    <>
      <Step2Header>
        <p>
          <b>Factura: </b> <span>{state.xmlInfo.xmlInfo.folio}</span>
        </p>
        <p>
          <b>Razon social: </b>
          <span>{state.xmlInfo.xmlInfo.socialReasonCustomer}</span>
        </p>

        <p>
          <b>RFC: </b>
          <span>{state.xmlInfo.xmlInfo.rfcEmitter}</span>
        </p>

        <p>
          <b>Moneda: </b>
          <span>{state.xmlInfo.xmlInfo.currency}</span>
        </p>
      </Step2Header>

      {state.concept === null ? (
        <>
          <Select
            addTodos={false}
            label="Parcialidades"
            options={step3.partialitiesList}
            className="w-25"
            textJSON="value"
            valueJSON="value"
            onChange={(value) =>
              setPartialities(
                +value,
                state.partialitiesInfo,
                state.isValidPartialitie
              )
            }
          />
          <GridPartialities
            tc={1}
            partialities={state.partialities}
            totalAmountPreinvoice={state.xmlInfo.xmlInfo.total.number}
            beginDate={state.expirationDate.jsDate}
            onChange={(value) => {
              setPartialities(
                state.partialities,
                value.partialitiesInfo,
                value.isValid
              );
            }}
          />
        </>
      ) : (
        <div style={{
          color:"var(--primaryColor)"
        }}>
          <div className="w-25 d-flex">
            <b>Parcialidades: </b>
            <p> 1</p>
          </div>

          <div className="d-flex">
            <b>Importe total: </b>
            <p> {state.xmlInfo.xmlInfo.total.formatted}</p>
          </div>

          <hr />

          <p>
            <b>Parcialidad 1</b>
          </p>
          <div className="d-flex">
            <div className="mr-4">
              <p>
                <b>Importe</b>
              </p>
              <p>{state.xmlInfo.xmlInfo.total.formatted}</p>
            </div>

            <div className="mr-4">
              <p>
                <b>Porcentaje</b>
              </p>
              <p>100%</p>
            </div>

            <div>
              <p>
                <b>Fecha vencimiento</b>
              </p>
              <p>{parseDateToText(state.expirationDate.jsDate)}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
