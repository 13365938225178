import useProfileV2 from "components/general/Header/Profile/useProfile";
import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * @type {import("./types").StateMenuNavigation}
 */
const INITIAL_STATE = {
  screen: "general",
};

/**
 * @type {import("./types").ReturnUseMenuNavigation}
 */
export const INITIAL_VALUE_CONTEXT = {
  ...INITIAL_STATE,
  dialog: undefined,
  navigateHome: () => console.log("navigationHome no programada"),
  profile: undefined,
  userInfo: [],
  navigateMenu: () => {},
  closeMenu:()=>{}
};

/**
 * Hook to handle the menu of the system
 * @returns {import("./types").ReturnUseMenuNavigation}
 */
export default function useMenuNavigation() {
  const dialog = useDialog();
  const profile = useProfileV2();
  const { userInfo } = useContext(LoginContext);

  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  const navigateHome = () => {
    setState((current) => ({
      ...current,
      screen: "general",
    }));
    dialog.close();
    history.push("/inicio");
  };

  /**
   * Set the screen to show on the menu
   * @param {import("structure/Menu/types").Menus} screen - Menu screen available to display
   * @returns {void}
   */
  const navigateMenu = (screen) =>
    setState((current) => ({
      ...current,
      screen,
    }));

  const closeMenu = () => {
    setState(current=>({
      ...current,
      screen:'general'
    }));
    dialog.close();
  }

  const navigateToDo = () => {
    setState((current) => ({
      ...current,
      screen: "general",
    }));
    dialog.close();
    history.push("/to-do")
  }

  return {
    dialog,
    navigateToDo,
    profile,
    ...state,
    navigateHome,
    userInfo,
    navigateMenu,
    closeMenu
  };
}
