import React, { useContext, useEffect } from "react";

// HEADER
import HomeHeader from "../../../../components/general/homeNavBar/HomeHeader";
import { navAddQuote } from "../../../../static/StaticBreadCrums";

// LIBS
import { useParams } from "react-router";

// COMPONENTS
import FormQuote from "./FormQuote";
import { setDybamicBreadCrum } from "../../../../helpers/Directory/Documents/addDocument";
import InfoDocument from "./InfoDocument";

// CONTEXT
import AddDocumentContext from "../../../../context/AddDocument/AddDocumentContext";

// APIS
import { getCurrency } from '../../../../helpers/Apis/Banks';

// HELPERS
import { createIndexedList } from '../../../../helpers/lists';

const Index = () => {
  const { customerId, typeDocument, action, documentId } = useParams();
  const { screen, setScreen , setCurrencies } = useContext(AddDocumentContext);

  useEffect(() => {
    setScreen(<FormQuote />);
    setDybamicBreadCrum(navAddQuote, customerId, typeDocument, action);

    async function initialLoad(){
      const currencies = await getCurrency();
      const indexedCurrencies = createIndexedList(currencies,'currencyID');
      
      setCurrencies(indexedCurrencies);
    }

    initialLoad();
  }, []);

  return (
    <>
      <HomeHeader bredcrum={navAddQuote} mostrar={false} />

      <InfoDocument />

      {action == "editar" ? (
        <div className="container">
          <span>
            <b>Numero cotizacion: </b>
            {documentId}
          </span>
        </div>
      ) : null}

      {screen}
    </>
  );
};

export const Component = {
  Index,
};
