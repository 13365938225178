import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextAddIncoming } from "..";
import Amount from "../Amount";
import Money from "components/general/Forms/Money";

export default function AmmountInput() {
  const hook = useContext(ContextAddIncoming);

  if (hook.state.movement.info !== null)
    return (
      <div className="d-flex flex-column align-items-end">
        <Label className="mx-0">Importe</Label>
        {new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(hook.state.movement.info.residue)}
      </div>
    );

  return (
    <div>
      <Label>Importe</Label>
      <Money
        maxDecimals={2}
        value={hook.state.ammount}
        onChange={hook.updateAmount}
      />
    </div>
  );

  return (
    <Amount
      required={hook.state.ammount <= 0}
      onChange={hook.updateAmount}
      value={hook.state.ammount}
    />
  );
}
