import React, { createContext } from "react";
import useCreditNotesReceived, {
  INITIAL_STATE_CONTEXT,
} from "./useCreditNotesReceived";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import SearchCreditNoteReceived from "./Search";
import ReceiveCreditNote from "structure/ReceiveCreditNote";
import Table from "./Table";
import StatusFilter from "./StatusFilter";
import RangeDate from "./RangeDate";
import CreditNoteReceivedOptions from "./Options";
import usePermissions from "customHooks/usePermissions";
import ui from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import FilterMobile from "./FilterMobile";

export const ContextReceiveCredtNotes = createContext(INITIAL_STATE_CONTEXT);

/**
 * Render credit notes received
 * @param {import("./types").PropsCreditNotesReceived} props - Props
 * @returns {JSX.Element}
 */
export default function CreditNotesReceived({ module = "facturas" }) {
  const hook = useCreditNotesReceived(module);

  usePermissions(
    module === "facturas"
      ? "e567a726-63e8-4f77-bb2a-779bd073fd9f"
      : "f7182e08-a90e-42c4-aa33-89c60a877091"
  );

  return (
    <ContextReceiveCredtNotes.Provider value={{ ...hook, module }}>
      <div className={ui.container}>
        <div className={ui.header}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={ui.filter}>
            <StatusFilter />
            <RangeDate />
            <SearchCreditNoteReceived />
            <ButtonV2 onClick={(e) => hook.triggerSerch()}>
              <span>Buscar</span>
              <Icon icon="search" />
            </ButtonV2>

            <ButtonV2
              onClick={(e) => hook.downloadExcel()}
              disabled={hook.isDownloadingExcel}
            >
              <span>Excel</span>
              <Icon icon="excel" />
            </ButtonV2>

            {module === "facturas" ? (
              <ReceiveCreditNote onReceived={hook.triggerSerch} />
            ) : null}
          </div>
          <FilterMobile />
        </div>

        <CreditNoteReceivedOptions />
        <Table />
      </div>
    </ContextReceiveCredtNotes.Provider>
  );
}
