import LoginContext from "context/Login/LoginContext";
import TableContext from "context/Table/TableContext";
import { Success } from "helpers/alerts";
import { addRol } from "helpers/Apis/rolesApi";
import { useContext, useEffect, useState } from "react";

const initialState = {
  step: 1,
  permissions: [],
  rols: [],
  description: {
    content: "",
    isValid: false,
  },
  isActive: true,
  isAddingRol: false,
};

export default function useAddRol(onAdded = () => {}) {
  /**
   * Close the modal to add a new rol
   * @returns {void}
   */
  const handleCloseModal = () => setState(initialState);

  const idCloseModalIcon = "closeModalAddRol";

  const [state, setState] = useState(initialState);

  const { refetch, setReload } = useContext(TableContext);
  const { userInfo } = useContext(LoginContext);

  useEffect(() => {
    if (state.step === 1) {
      setState(initialState);
    }
  }, [state.step]);

  /**
   * Update the flag that indicates if the permissions is active on the system
   * @returns {void}
   */
  const updateIsActive = () =>
    setState({
      ...state,
      isActive: !state.isActive,
    });

  /**
   * Go forward or backward to advanced on the rol creation
   * @param {number} step - Number of the step
   * @returns {void}
   */
  const setStep = (step) =>
    setState({
      ...state,
      step,
    });

  /**
   * Update the description to use on the rol
   * @param {string} value - Value of the description typed
   */
  const updateDescription = (value) => {
    const regex = new RegExp(/^[A-Za-z ]*$/);
    const isValid = regex.test(value);
    const isEmpty = value.length === 0 ? true : false;

    setState({
      ...state,
      description: {
        content: value,
        isValid: isValid && !isEmpty,
      },
    });
  };

  /**
   * Update the permissions will use the rol
   * @param {string[]} permissions - Permissions
   * @returns {void}
   */
  const updatePermissions = (permissions) =>
    setState({
      ...state,
      permissions,
    });

  /**
   * Update the rols that were checked on the UI
   * @param {import("types/typedef/roles").RolI[]} rols - List of rols
   * @returns {void}
   */
  const updateRoles = (rols) =>
    setState({
      ...state,
      rols,
    });

  /**
   * Attempt add the rol into database
   */
  const handleAddRol = async () => {
    setState({
      ...state,
      isAddingRol: true,
    });

    const wasSuccessQuery = await addRol({
      arrayIdChileRoles: state.rols.map((rol) => `${rol.id}`),
      arrayUuid: state.permissions,
      description: state.description.content,
      status: +state.isActive,
      createdBy: userInfo[0].fullName,
    });

    if (wasSuccessQuery) {
      Success(() => {}, `${state.description.content} fue agregado`);
      setState(initialState);
      closeModal();
      setReload(!refetch);
      onAdded()
    } else {
      Error(() => {}, "Intenta mas tarde");
    }

    setState({
      ...state,
      isAddingRol: false,
    });

    function closeModal() {
      const closeButton = document.getElementById(idCloseModalIcon);
      if (closeButton) {
        closeButton.click();
      }
    }
  };

  return {
    step: state.step,
    permissions: state.permissions,
    rols: state.rols,
    description: state.description,
    isActive: state.isActive,
    updateDescription,
    updatePermissions,
    setStep,
    updateIsActive,
    updateRoles,
    handleAddRol,
    isAddingRol: state.isAddingRol,
    idCloseModalIcon,
    handleCloseModal,
  };
}
