import { useEffect, useState } from "react";

export default function useFilterStatusFile(
  onChange = () => {},
  addTodos = false
) {
  const [state, setState] = useState({
    currentStatus: {
      label: "Disponible",
      value: 1,
    },
    status: [
      {
        label: "Disponible",
        value: 1,
      },
      {
        label: "Eliminado",
        value: 0,
      },
    ],
  });

  /**
   * Handle the on change of the combo
   * @param {string|number} value - Value from the attribute value on the html option tag
   */
  const handleOnChange = (value) => {
    const status = state["status"].find((status) => status.value === value);
    setState({
      ...state,
      currentStatus: status,
    });
    onChange(status);
  };

  useEffect(() => {
    if (addTodos) {
      setState({
        currentStatus: {
          label: "Todos",
          value: -1,
        },
        status: [
          {
            label: "Todos",
            value: -1,
          },
          ...state.status,
        ],
      });
    }
  }, []);

  return {
    currentStatus: state.currentStatus,
    status: state.status,
    handleOnChange,
  };
}
