import { useReducer } from "react";

// TYPES
import {
  SET_RANGE_DATE_FILTER,
  SET_TYPE_DOCUMENT,
  SAVE_STATUS_DOCUMENT,
  SET_RENDER_SCREEN,
  SET_QUOTE,
} from "../../types/index";

import DocumentsContext from "./DocumentsContext";
import DocumentsReducer from "./DocumentsReducer";

// HELPERS
import { rangeFullYear } from "../../helpers/dates";

// COMPONENTS - JUST FOR DEBUGGIN
import Quotes from "../../pages/Directory/Documents/Quotes/Quotes";
import QuotesOptions from "../../pages/Directory/Documents/Quotes/QuotesOptions";
import OC from "../../pages/Directory/Documents/OC/OC";
import Contract from "../../pages/Directory/Documents/Contract/Contract";
import Invoice from "../../pages/Directory/Documents/Invoice/Invoice";
import CXC from "../../pages/Directory/Documents/CXC/CXC";
import CxP from "pages/Directory/Documents/CxP";

const DocumentsState = (props) => {
  const range = rangeFullYear();

  const initialState = {
    rangeBegin: range.firstDay.jsDate,
    rangeEnd: range.lastDay.jsDate,
    typeDocument: 0,
    renderScreen: 0,
    document: {
      quote: null,
      origin: null,
      preInvoice: null,
      oc: null,
      contract: null,
    },
    statusDocuments: {},
    documentScreen: {
      0: {
        table: <></>,
        options: <></>,
      },

      1: {
        table: <Quotes />,
        options: <QuotesOptions />,
      },

      2: {
        table: <Invoice />,
        options: <></>,
      },

      3: {
        table: <OC />,
        options: <></>,
      },

      4: {
        table: <CxP />,
        options: <></>,
      },

      5: {
        table: <CXC />,
        options: <></>,
      },

      6: {
        table: <Contract />,
        options: <></>,
      },
    },
  };

  const [state, dispatch] = useReducer(DocumentsReducer, initialState);

  const setRange = (range) => {
    dispatch({
      type: SET_RANGE_DATE_FILTER,
      payload: range,
    });
  };

  /**
   * Set the type of document that it's being manage on the UI
   *
   * @param {number} value - ID of the document that it's on SQL Server
   */
  const setTypeDocument = (value) => {
    dispatch({
      type: SET_TYPE_DOCUMENT,
      payload: value,
    });
  };

  /**
   * Save in state the select status in order to avoid a 2nd refetch to API
   *
   * @param {object[]} status - Status fetched from the API
   */
  const saveStatusDocument = (status) => {
    dispatch({
      type: SAVE_STATUS_DOCUMENT,
      payload: status,
    });
  };

  /**
   * Set the render screen when the user clicks on Buscar button
   */
  const setRenderScreen = () => {
    dispatch({
      type: SET_RENDER_SCREEN,
      payload: state.typeDocument,
    });
  };

  /**
   * Set the information of a document
   *
   * @param {object} document - Information of the document
   */
  const setQuote = (document) => {
    dispatch({
      type: SET_QUOTE,
      payload: document,
    });
  };

  const values = {
    rangeBegin: state.rangeBegin,
    rangeEnd: state.rangeEnd,
    typeDocument: state.typeDocument,
    statusDocuments: state.statusDocuments,
    documentScreen: state.documentScreen,
    renderScreen: state.renderScreen,
    documentInfo: state.documentInfo,
    setRenderScreen,
    setRange,
    saveStatusDocument,
    setTypeDocument,
    setQuote,
  };

  return (
    <DocumentsContext.Provider value={values}>
      {props.children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsState;
