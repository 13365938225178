import React, { useContext } from "react";
import CancelInvoice from "structure/CancelInvoice";
import { ContextDocuments } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import TerminateContract from "structure/TerminateContract";

/**
 * Render the option to cancel the current document (**if applys**)
 * @param {import("./types").CancelDocumentProps} props - Props
 * @returns {JSX.Element}
 */
export default function CancelDocument({
  children = null,
  forwardRef = undefined,
}) {
  const hook = useContext(ContextDocuments);

  if (
    hook.document === undefined ||
    hook.document === null ||
    hook.documentSelected === null ||
    hook.documentSelected === undefined
  )
    return <></>;

  if (hook.document.value === 1)
    return (
      <CancelInvoice
        id={hook.documentSelected.idDocument}
        status={hook.documentSelected.DocumentStatus_id}
        onCancelated={hook.searchData}
        forwardRef={forwardRef}
        children={children}
      />
    );

  if (hook.document.value === 6)
    return (
      <TerminateContract
        idContract={hook.documentSelected.idDocument}
        idStatus={hook.documentSelected.idStatus}
        onCancelled={hook.searchData}
        children={children}
        forwardRef={forwardRef}
      />

    );

  return <></>;
}
