import React from "react";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);

/**
 *
 * @param {import("../../../../types/documentActions").DatePickerI} props - Props
 * @returns {JSX.Element}
 */

export const InputDatePicker = ({
  selectedDate = new Date(),
  handleDateChange,
  disable,
  minDate = "",
  maxDate = "",
  placeholderText = "",
  customInput,
}) => {
  return (
    <DatePicker
      selected={!disable ? "" : selectedDate}
      onChange={handleDateChange}
      dateFormat="dd/MMM/yyyy"
      locale="es"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      withPortal
      dropdownMode="select"
      disabled={!disable}
      placeholderText={placeholderText}
      minDate={minDate}
      maxDate={maxDate}
      customInput={customInput}
    />
  );
};
