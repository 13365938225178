import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetDocument } from "helpers/Apis/Documents";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import MoreInfoDirectory from "structure/MoreInfoDirectory";
import ToDo from "structure/ToDo";
import ui from "structure/ToDo/CardsToDo/styles.module.scss";

export default function ToDoDocuments() {
  const { customerId, idDocument } = useParams();

  /**
   * Information of the document selected for the todo
   * @type {[import("types/typedef/documents").DocumentI,()=>void]}
   */
  const [document, setDocument] = useState(undefined);

  useEffect(() => {
    (async function () {
      const apiDocument = await GetDocument(+idDocument);
      setDocument(apiDocument);
    })();
  }, []);

  const breadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${customerId}`,
      text: "Documentos",
    },
    {
      route: "/",
      text: "To Do",
    },
  ];

  return (
    <div className={ui.container}>
      <BreadCrumV2 path={breadcrum} />

      {document === undefined ? (
        <Spinner hidden={false} text="Cargando " />
      ) : (
        <div className={ui.margin}>
          <p>
            <b>Razón social</b>

            <MoreInfoDirectory idCustomer={document.customer.id}>
              <span className="hyperlink">
                {document.customer.socialReason} ({document.type.description})
              </span>
            </MoreInfoDirectory>
            {/* |<b>Tipo: </b>
            <span>{document.type.description}</span> */}
          </p>
        </div>
      )}

      <ToDo id={+idDocument} typeToDo="documentos" idEntity={+customerId} />
    </div>
  );
}
