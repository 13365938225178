import React from "react";
import SocialReason from "./SocialReason";
import BankAccountCuenta from "./Cuenta";
import BankCard from "./Card";

import { useBankAccount } from "./hooks/useBankAccount";

import "./styles.css";
import { createContext } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addBankAccountSchema, updateBankAccountSchema } from "./validations";

import { BankCurrency } from "./Currency";
import { BankDate } from "./Date";
import BankAmount from "./Amount";
import { NextTransfer } from "./NextTrans";
import { NextCheck } from "./NextCheck";
import Description from "./Description";
import Comment from "./Comment";
import BankCLABE from "./BankCLABE";
import SubmitAddBankAccount from "./Submit";

import { Spinner } from "components/individual/Spinner/Spinner";
import { ViewCLABE } from "./ViewCLABE";
import ViewDescription from "./ViewDescription";
import ViewComment from "./ViewComment";
import Status from "./Status";

/**
 * @type {import("react").Context<import("./hooks/types").ContextBankAccount>}
 */
export const BankAccountContext = createContext();
const { Provider } = BankAccountContext;

/**
 *
 * @param {import("./types").PropsBankAccount} props - Props
 * @returns
 */
export function BankAccount({
  id = null,
  children = () => {},
  onSuccess = () => {},
}) {
  const hook = useBankAccount(id, onSuccess);

  const schemaToUse =
    id === null ? addBankAccountSchema : updateBankAccountSchema;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaToUse),
  });

  const handleBankAccountSubmit =
    id === null ? hook.attemptAddBankAccount : hook.attemptUpdateBankAccount;

  if (hook.isLoading) return <Spinner hidden={false} text="Cargando cuenta" />;

  return (
    <Provider
      value={{
        ...hook,
        register,
        errors,
        id,
      }}
    >
      <form
        onSubmit={handleSubmit(handleBankAccountSubmit, (e) => console.log(e))}
      >
        {children()}
      </form>
    </Provider>
  );
}

export {
  SocialReason,
  BankAccountCuenta,
  BankCard,
  BankCurrency,
  BankDate,
  BankAmount,
  NextTransfer,
  NextCheck,
  Description,
  Comment,
  SubmitAddBankAccount,
  ViewDescription,
  ViewCLABE,
  ViewComment,
  Status,
  BankCLABE,
};
