import { ClientJS as Client } from "clientjs";
import { useEffect, useState } from "react";

export default function useDeviceInfo() {
  /**
   * @type {[ClientJS,()=>void]}
   */
  const [client, setClient] = useState({
    os: undefined,
    isMobile: undefined,
    browser: undefined,
  });

  useEffect(() => {
    const clientJs = new Client();

    // console.log("es movil", clientJs.isMobile());
    setClient({
      os: clientJs.getOS(),
      isMobile: clientJs.isMobile(),
      browser: clientJs.getBrowser(),
    });
  }, []);

  return {
    os: client.os,
    isMobile: client.isMobile,
    browser: client.browser,
    isLoadedData:
      client.os === undefined ||
      client.isMobile === undefined ||
      client.browser === undefined
        ? false
        : true,
  };
}
