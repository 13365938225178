import useBankAccountInfo from "customHooks/useBankAccountInfo";
import React from "react";
import { createContext } from "react";
import { Label } from "structure/Document";
import { Bank } from "./Bank";
import { Account } from './Account';
import { Currency } from "./Currency";

/**
 * @type {import("react").Context<import("../../../../types/banks").BankAccount>}
 */
export const ContextBankHeader = createContext();
const { Provider } = ContextBankHeader;

export const BankAccountHeader = ({
  idBankAccount = null,
  children,
  useDefaultDesign = false,
}) => {
  const hook = useBankAccountInfo(idBankAccount);

  if (idBankAccount === null||hook===undefined) return;

  if (useDefaultDesign)
    return (
      <div className="d-inline-flex">
        <div className="mr-5">
          <Label>Banco</Label>
          <p>
            {hook.bank.shortName} - {hook.bank.clave}
          </p>
        </div>

        <div className="mr-5">
          <Label>Cuenta</Label>
          <p>{hook.cuenta}</p>
        </div>

        <div className="mr-5">
          <Label>Moneda</Label>
          <p>{hook.currency}</p>
        </div>

        
      </div>
    );

  return <Provider value={hook}>{children(hook)}</Provider>;
};

export {
  Bank,
  Account,
  Currency
}
