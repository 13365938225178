import { InputText } from "components/individual/inputs/Inputs";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextEmail } from "..";

export const Subject = () => {
  const { updateSubject, subject } = useContext(ContextEmail);

  return (
    <>
      <Label>Asunto</Label>
      <InputText
        labelTxt=""
        defaultValue={subject}
        placeholderTxt="Escribe aquí"
        onChange={(subject) => updateSubject(subject)}
      />
    </>
  );
};
