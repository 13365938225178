import React, { createContext, useContext, useRef } from "react";
import { faTimesCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactSortable } from "react-sortablejs";
import useComments from "customHooks/useComments";
import { commentSchema } from "Schemas/Directory/Comments";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ErrorMessage, InputText } from "components/individual/inputs/Inputs";
import { SubmitTest } from "components/individual/buttons/Buttons";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { v4 as uuidv4 } from "uuid";
import { YesNoAlert } from "helpers/alerts";

const CommentContext = createContext();
const { Provider } = CommentContext;

const iconDelete = (
  <FontAwesomeIcon icon={faTimesCircle} className="iconCard" size="2x" />
);

const iconEdit = (
  <FontAwesomeIcon icon={faPen} className="iconCard ml-3" size="2x" />
);

export const Comment = ({ id, isSorting, comment, index }) => {
  const {
    deleteComment,
    setIsEditing,
    setValueInput,
    promptOnDelete,
    idInput,
  } = useContext(CommentContext);

  return (
    <div
      id={id}
      className={`shadowBackground 
    py-4 
    d-flex 
    justify-content-between 
    align-items-center
    px-3
    mt-4  ${isSorting ? "cursorDragging" : "cursorMove"}`}
    >
      <div>{comment}</div>
      <div className="d-flex justify-content-center">
        <div
          onClick={() => {
            if (promptOnDelete === true) {
              YesNoAlert(
                "¿Confirmas borrar el comentario?",
                () => deleteComment(id),
                () => {}
              );
            } else {
              deleteComment(id);
            }
          }}
          className="cursorPointer iconCard"
        >
          {iconDelete}
        </div>
        <div
          onClick={() => {
            setValueInput(comment);
            document.getElementById(idInput).focus();
            setIsEditing({
              editing: true,
              id,
              index,
            });
          }}
          className="cursorPointer iconCard"
        >
          {iconEdit}
        </div>
      </div>
    </div>
  );
};

/**
 * Comments component
 * @param {object} props - Props
 * @param {number} props.idDocument - Id of the document to fetch the comments
 * @param {boolean} props.promptOnDelete - Prompt confirmation on delete before execute
 * @param {(object:CommentI)=>void} props.onChange - Comments when somehting changed on the items
 * @returns
 */
export function Comments({
  idDocument = null,
  promptOnDelete = true,
  onChange = () => {},
}) {
  const idComment = useRef(uuidv4()).current;
  const {
    comments,
    setComments,
    isEditing,
    handleComment,
    formNode,
    deleteComment,
    setValueInput,
    setIsEditing,
    idInput,
  } = useComments(idDocument, onChange);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(commentSchema),
  });

  return (
    <Provider
      value={{
        setComments,
        deleteComment,
        setIsEditing,
        formNode,
        register,
        promptOnDelete,
        setValueInput,
        idComment,
        idInput,
      }}
    >
      <form
        className="queueContainer my-4"
        ref={formNode}
        onSubmit={handleSubmit(handleComment)}
      >
        <div className="d-flex align-items-center">
          <div className="w-100 mr-4">
            <InputText
              forwardRef={register}
              aditionalClasses="w-100"
              labelTxt="Comentario"
              placeholderTxt="Escribe aqui el comentario"
              name="comment"
              id={idInput}
              onEnter={() => document.getElementById(idComment).click()}
            />
            <ErrorMessage message={errors["comment"]?.message} />
          </div>

          {isEditing.editing ? (
            <ButtonV2
              type="danger"
              onClick={() => {
                setIsEditing({
                  editing: false,
                  id: null,
                  index: null,
                });
                formNode.current.reset();
              }}
            >
              Cancelar
            </ButtonV2>
          ) : null}

          <SubmitTest
            id={idComment}
            text={!isEditing.editing ? "Agregar" : "Actualizar"}
          />
        </div>

        <ReactSortable list={comments} setList={setComments}>
          {comments.map((comment, i) => {
            return (
              <Comment
                id={comment.id}
                comment={comment.name}
                isNewComment={false}
                index={i}
              />
            );
          })}
        </ReactSortable>
      </form>
    </Provider>
  );
}
