import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { AddRolContext } from "../..";

export default function ContinueButton() {
  const {
    description: { isValid },
    setStep,
  } = useContext(AddRolContext);

  return !isValid ? null : (
    <ButtonV2 onClick={() => setStep(2)}>Continuar</ButtonV2>
  );
}
