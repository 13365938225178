import { disassociateCxcIncoming, getMovement } from "helpers/Apis/Banks";
import { useEffect, useState } from "react";

export default function useDisassociateCxc(idMovement) {
  const [state, setState] = useState({
    isDisassociating: false,
    concilations: [],
    movement: undefined,
    isOpen: false,
    isLoading:true,
  });

  const updateConcilations = (concilations) =>
    setState({
      ...state,
      concilations,
    });

  useEffect(() => {
    (async function () {
      if (idMovement === null || idMovement === undefined) return;

      const apiMovement = await getMovement(idMovement);

      console.log(apiMovement,'api')

      setState({
        ...state,
        movement: apiMovement,
        isLoading:false,
      });
    })();
  }, [idMovement]);

  /**
   * @returns {boolean} True if was dis-associated
   */
  const attemptDisassociate = async () => {
    setState({
      ...state,
      isDisassociating: true,
    });

    const wasDone = await disassociateCxcIncoming(
      idMovement,
      state.concilations.map((concilation) => concilation.id)
    );

    setState({
      ...state,
      isDisassociating: false,
    });

    return wasDone;
  };

  return {
    state,
    updateConcilations,
    attemptDisassociate,
    canDisplayDisassociateBtn: state.concilations.length > 0,
  };
}
