import Tooltip from "rc-tooltip";
import React, { useRef } from "react";
import { useState } from "react";
import scss from "./styles.module.scss";

/**
 * Render icons with the tutorial according the browser. You must use another component
 * to display the tutorial for each browser
 * 
 * **NOTE**: Pictures must be `.png` and must be named as 1.png , 2.png , 3.png , etc
 * @param {import("./types").PropsTutorial} props - Props
 * @returns {JSX.Element}
 * @example 
*  <Tutorial
    chrome={
      <CustomTutorial
        folder="images/cookies/chrome/"
        labels={[
          "Configuracion del navegador",
          "Privacidad y seguridad",
          "Datos de navegacion",
        ]}
      />
    }
    firefox={
      <CustomTutorial
        folder="images/cookies/firefox/"
        labels={[
          "Configuracion",
          "Limpiar historial reciente",
          "Borrar datos de exploracion",
        ]}
      />
    }
    edge={
      <CustomTutorial
        folder="images/cookies/edge/"
        labels={[
          "Configuracion",
          "Datos de exploracion",
          "Borrar datos de exploracion",
        ]}
      />
    }
  />
 */
export const Tutorial = (props) => {
  const [selected, setSelected] = useState(undefined);
  const [tutorials, setTutorial] = useState(Object.keys(props));

  const key = useRef(`${window.crypto.randomUUID()}`);

  const setChrome = () => setSelected("chrome");
  const setFirefox = () => setSelected("firefox");
  const setEdge = () => setSelected("edge");

  return (
    <div className={scss.tutorial}>
      <div className={scss.browsers}>
        {tutorials.includes("chrome") ? (
          <Tooltip
            placement="top"
            trigger={["hover"]}
            overlay={<span>Google Chrome</span>}
          >
            <>
            <input type="radio" name={key.current} id={`${key.current}-chrome`}/>
            <label className={scss.btnTutorial} onClick={setChrome} htmlFor={`${key.current}-chrome`}>
              <img
                className={scss.icon}
                src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                alt="google_chrome_browser"
              />
            </label>
            </>
          </Tooltip>
        ) : null}

        {tutorials.includes("firefox") ? (
          <Tooltip
            placement="top"
            trigger={["hover"]}
            overlay={<span>Firefox</span>}
          >
            <>
            <input type="radio" name={key.current} id={`${key.current}-firefox`}/>
            <label htmlFor={`${key.current}-firefox`} className={scss.btnTutorial} onClick={setFirefox}>
              <img
                className={scss.icon}
                src="https://upload.wikimedia.org/wikipedia/commons/a/a0/Firefox_logo%2C_2019.svg"
                alt="firefox_browser"
              />
            </label>
            </>
          </Tooltip>
        ) : null}

        {tutorials.includes("edge") ? (
          <Tooltip
            placement="top"
            trigger={["hover"]}
            overlay={<span>Microsoft Edge</span>}
          >
            <>
            <input type="radio" name={key.current} id={`${key.current}-edge`}/>
            <label className={scss.btnTutorial} onClick={setEdge} htmlFor={`${key.current}-edge`}>
              <img
                className={scss.icon}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Microsoft_Edge_logo_%282019%29.png/600px-Microsoft_Edge_logo_%282019%29.png"
                alt="microsoft_edge_browser"
              />
            </label>
            </>
          </Tooltip>
        ) : null}
      </div>

      {selected === undefined ? (
        <div>
          <p className="text-center">
            Selecciona un navegador para mostrar una guía
          </p>
        </div>
      ) : (
        props[selected]
      )}
    </div>
  );
};
