import { ACTIONS } from "./Actions";

/**
 * @type{import("../../../../../../types/documentActions").Imports}
 */
const initialImports = {
  number: 0,
  text: "$0.00",
};

/**
 * @type{import("../../../../../../types/Partidas/partidas.interfaces").ItemsI}
 */
export const initialState = {
  catalogue: {
    cu: 0,
    description: "",
    id: 0,
    iva: 0,
    pu: 0,
  },
  costDiscount: initialImports,
  cu: initialImports,
  currency: {
    id: 1,
    code: "MXN",
  },
  description: "",
  id: 0,
  isBy: "sku",
  isNewItem: false,
  iva: {
    ...initialImports,
    exempt: false,
    withIva: {
      cu: initialImports,
      pu: initialImports,
    },
  },
  label: "",
  priceDiscount: initialImports,
  pu: initialImports,
  quantity: 0,
  satCode: "",
  satCodeDescription: "",
  satUm: "",
  satUmDescription: "",
  sku: "",
  uen: {
    description: "",
    family: "",
    id: 0,
    marginRate: 0,
  },
  uuid: "",
  value: 0,
  index: -1,
  mxn: {
    cu: {
      ...initialImports,
      discount: initialImports,
      iva: initialImports,
      total: initialImports,
      totalIva: initialImports,
      totalUnit: initialImports,
    },
    pu: {
      ...initialImports,
      discount: initialImports,
      iva: initialImports,
      total: initialImports,
      totalIva: initialImports,
      totalUnit: initialImports,
    },
  },
  usd: {
    cu: {
      ...initialImports,
      discount: initialImports,
      iva: initialImports,
      total: initialImports,
      totalIva: initialImports,
      totalUnit: initialImports,
    },
    pu: {
      ...initialImports,
      discount: initialImports,
      iva: initialImports,
      total: initialImports,
      totalIva: initialImports,
      totalUnit: initialImports,
    },
  },
  openModal: false,
};

/**
 * Win reducer
 * @param {import("../../../../../../types/Partidas/partidas.interfaces").ItemsI} state
 * @param {import("../../../../../../types/Partidas/partidas.types").PartidasActionsTypes} action
 * @returns {import("../../../../../../types/Partidas/partidas.interfaces").ItemsI}
 */
export const partidasReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOAD_ITEM:
      return {
        ...state,
        ...action.payload,
        catalogue: {
          id: action.payload.id,
          description: action.payload.description,
          cu: action.payload.cu,
          pu: action.payload.pu,
          iva: action.payload.iva,
        },
      };
    case ACTIONS.SET_ITEM_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case ACTIONS.SET_ITEM_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case ACTIONS.SET_ITEM_IVA:
      return {
        ...state,
        iva: action.payload,
      };
    case ACTIONS.SET_ITEM_COST:
      return {
        ...state,
        cu: action.payload,
      };
    case ACTIONS.SET_ITEM_PRICE:
      return {
        ...state,
        pu: action.payload
      };
    case ACTIONS.SET_ITEM_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      };
    case ACTIONS.SET_COST_DISCOUNT:
      return {
        ...state,
        costDiscount: action.payload,
      };
    case ACTIONS.SET_PRICE_DISCOUNT:
      return {
        ...state,
        priceDiscount: action.payload,
      };
    case ACTIONS.SET_ITEM_UEN:
      return {
        ...state,
        uen: action.payload,
      };
    case ACTIONS.SET_OPEN_ITEM:
      return {
        ...state,
        openModal: action.payload,
      };
    case ACTIONS.SET_ITEM_SKU:
      return {
        ...state,
        sku: action.payload,
      };
    // case ACTIONS.SET_NEW_ITEM_SKU:
    //   return {};
    // case ACTIONS.SET_NEW_ITEM_DESCRIPTION:
    //   return {};

    default:
      return state;
  }
};
