import axios from "axios";
import { URL_BASE, ULR_MIZAR } from "../../routes/routes";
import { Success, Error } from "../alerts";
import XmlReader from "xml-reader";
import "../../types/typedef/components/directory";
import "../../types/mizar";

import { getEmailEnterprise, getRfcEmitter } from "./parameters";

const reader = XmlReader.create();

/**
 * Add the property "rfcEmitter" to the array of objects
 *
 * @param {any[]} array - Array of objects
 * @param {string} rfc - RFC of the enterprise
 * @returns {any[]} "rfcEmitter" property appended to array objects
 */
const addRfcEnterprise = (array = [], rfc = "ND") =>
  array.map((element) => ({ ...element, rfcEmitter: rfc }));

/**
 * Quite the last character from string
 * @param {string} text - Text to evaluate
 * @returns {string} Text without the last character
 */
const quitLastCharacter = (text = "No text provided!.") => text.slice(0, -1);

/**
 * Generate the json as the string mizar requests
 *
 * @param {DocumentItem[]} products - List of products to parse
 * @param {string} rfc - RFC of the enterprise
 * @returns {string} JSON formated as string
 */
const parseListProducts = (products, rfc) =>
  quitLastCharacter(products.reduce(generateMizarProductFormat, ""));

/**
 * Get the currency id that mizar use on his system
 *
 * @param {"MXN"|"USD"} threeCodeCurrency - 3 digit code of the currency to evaluate
 * @returns {number} Id that mizar uses for that currency
 */
const getMizarIdCurrency = (threeCodeCurrency) => {
  const mizarCurrencys = {
    MXN: 1,
    USD: 2,
  };

  return mizarCurrencys[threeCodeCurrency];
};

/**
 * Generate the JSON format to add on mizar list products
 *
 * @param {string} currentProducts - Current object string format of products
 * @param {DocumentItem} product - Product generated by the system
 * @returns {string} JSON as string format to insert products on mizar
 */
const generateMizarProductFormat = (currentProducts, product) =>
  (currentProducts += `
    {
        NumeroIdentificacion: "${product.id}",
        Descripcion: "${product.description}",
        EsImportado: "0",
        IdUnidadMedida: "PENDIENTE (CLAVE UM SAT)",
        UnidadMedida: "PENDIENTE (DESCRIPCION UM SAT)",
        IdMoneda: "${getMizarIdCurrency(product.currencyCode)}",
        PrecioLista1: "${product.sellPrice}",
        PrecioLista2: "0",
        PrecioLista3: "0",
        PrecioLista4: "0",
        PrecioLista5: "0",
        TrasladarIva: "0",
        TrasladarIeps:"0",
        TasaIva:"0",
        TasaIeps:"0",
        RetenerIva : "0",
        RetenerIsr: "0",
        TasaIvaRet:"",
        TasaIsrRet:"",
        ConstanteIeps:"1",
        ClaveProdServ: "PENDIENTE (CLAVE SAT)",
        FactorTrasIva: "${product.iva}",
        FactorTrasIeps:"0",
        FactorRetIva:"",
        FactorRetIsr:"",
        rfc_emisor: "${product.rfcEmitter}"
    },
`);

/*

{
    "id": 232,
    "description": "Producto 10",
    "unitPrice": 50,
    "satCode": "Clave SAT 001",
    "iva": 16,
    "uen": 1,
    "sellPrice": 815.6,
    "satUm": "UM SAT 010",
    "code": "SKU 0010",
    "currency": 2,
    "idUen": 1,
    "uenDescription": "Microsoft Office 365",
    "currencyID": 2,
    "currencyCode": "USD",
    "symbol": "$",
    "currencyDescription": "Moneda estadounidense",
    "label": "Producto 10",
    "value": 232,
    "beforeExchange": {
        "sellPrice": 40,
        "price": 50,
        "ivaPrice": 8,
        "subTotal": 50,
        "puVenta": 50,
        "totalImport": 58
    },
    "quantity": 1,
    "discount": 0,
    "totalImport": 1182.6200000000001,
    "idFront": "fa416243-dd85-43c6-badb-77894f063256",
    "mustBeAdded": false,
    "isNewItem": true,
    "price": 1019.5,
    "ivaPrice": 163.12,
    "subTotal": 1019.5,
    "puVenta": 1019.5,
    "order": 1
}

*/

/**
 * Get the rfc of the enterprise
 *
 * @returns {string} RFC of the enterprise
 */
async function getRfcSaiko() {
  try {
    const { data } = await axios.get(`${URL_BASE}documentos/rfc/empresa`);

    return data.rfc;
  } catch (error) {
    console.log(error);
    return;
  }
}

/**
 * Add items to mizar
 *
 * @param {DocumentItem[]} products - Products to add on mizar
 * @returns {boolean} True if item was added successfully
 */
export async function addAdmonProductos(
  products = [
    {
      id: 232,
      description: "Producto 10",
      unitPrice: 50,
      satCode: "Clave SAT 001",
      iva: 16,
      uen: 1,
      sellPrice: 815.6,
      satUm: "UM SAT 010",
      code: "SKU 0010",
      currency: 2,
      idUen: 1,
      uenDescription: "Microsoft Office 365",
      currencyID: 2,
      currencyCode: "USD",
      symbol: "$",
      currencyDescription: "Moneda estadounidense",
      label: "Producto 10",
      value: 232,
      beforeExchange: {
        sellPrice: 40,
        price: 50,
        ivaPrice: 8,
        subTotal: 50,
        puVenta: 50,
        totalImport: 58,
      },
      quantity: 1,
      discount: 0,
      totalImport: 1182.6200000000001,
      idFront: "fa416243-dd85-43c6-badb-77894f063256",
      mustBeAdded: false,
      isNewItem: true,
      price: 1019.5,
      ivaPrice: 163.12,
      subTotal: 1019.5,
      puVenta: 1019.5,
      order: 1,
    },
  ]
) {
  try {
    const rfcSaiko = await getRfcSaiko();

    const parsedProducts = addRfcEnterprise(products, rfcSaiko);

    let jsonProducts = quitLastCharacter(
      parseListProducts(parsedProducts, rfcSaiko)
    );

    console.log(jsonProducts);

    // const { data } = await axios.post(
    //   ULR_MIZAR,
    //   `
    //     <Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">
    //         <Body>
    //             <AdmonProductos xmlns="http://tempuri.org/">
    //                 <dProductos>
    //                     {"dProductos":[${jsonProducts}]}
    //                 </dProductos>
    //             </AdmonProductos>
    //         </Body>
    //     </Envelope>
    //     `,
    //   {
    //     headers: {
    //       "Content-Type": "text/xml",
    //     },
    //   }
    // );
  } catch (error) {
    console.log(error);
    return false;
  }
}

////////////////////////////////

/**
 * Set the email for the customer on mizar web service
 * @param {string|null} info - Email of the customer
 * @param {string} dummyEmail - Dummy email to put on mizar information
 * @returns {string} Email to use on mizar web service for the customer
 */
const mizarParseEmail = (info = null, dummyEmail) =>
  info === null ? dummyEmail : info;

/**
 * Set the cp for the customer on mizar web service
 * @param {number|null} info - CP direction of the customer
 * @returns {number} CP direction to use on mizar web service
 */
const mizarParseCp = (info = null) => (info === null ? "00000" : info);

/**
 * Set the customer adress for the customer on mizar web service
 * @param {string} info - Adress information of the customer to use on mizar
 * @returns {string|'ND'} Text to use on mizar customer adress
 */
const mizarParseAdress = (info = null) => (info === null ? "ND" : info);

/**
 * Get the id mizar number in order to create/update  a customer
 *
 * @param {number|null|undefined} id - Id of mizar
 * @returns {number|""} Id mizar to use to update/create customer on mizar
 */
const parseMizarId = (id = null) => (id === null || id === undefined ? "" : id);

/**
 * Parse the number phone to use on the customer
 * @param {string} lada - Lada of the customer
 * @param {string} phone - Number of the customer
 * @returns {string|'0000000000'} Mizar number to use on mizar system
 */
const mizarParsePhone = (lada, phone) =>
  lada === null ? "0000000000" : `${lada}${phone}`;

/**
 * Add the customer to mizar system
 *
 * @param {Customer} customer - Information of customer
 * @returns {AdmonClientesRes} False if customer wasn't added correctly
 */
export async function addMizarCustomer(customer = null) {
  console.log(customer);

  if (customer === null) {
    return {
      added: false,
      idCustomer: null,
      message: "",
      error:
        "Formato incorrecto. Sincroniza manualmente en la edicion del cliente.",
    };
  }

  const dataEnterprise = await Promise.all([
    getRfcEmitter(),
    getEmailEnterprise(),
  ]);
  // const rfcEmitter = await getRfcEmitter();
  // const emailEnterprise = await getEmailEnterprise();

  if (dataEnterprise[0] === null) {
    return {
      added: false,
      idCustomer: null,
      message: "",
      error:
        "Proporciona el rfc del emisor. Sincroniza manualmente en la edicion del cliente.",
    };
  }

  const xmlString = parseJson(dataEnterprise[0], dataEnterprise[1]);

  console.log(xmlString);

  try {
    const { data } = await axios.post(
      ULR_MIZAR,
      `<Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">
      <Body>
          <AdmonClientes xmlns="http://tempuri.org/">
              <dClientes>
                  {
                  "dCliente":
                      [${xmlString}] 
                  }
              </dClientes>
          </AdmonClientes>
      </Body>
  </Envelope>`,
      {
        headers: {
          "Content-Type": "text/xml",
        },
      }
    );

    const response = getMizarResponse(data);

    return response;
  } catch (error) {
    console.log(error);

    return {
      added: false,
      idCustomer: null,
      message:
        "Formato incorrecto o no hay conexion a internet. ¿Sincronizar nuevamente?",
      error,
    };
  }

  /**
   * Get the json response of the xml that returns mizar
   * @param {string} xml - XML but as string format
   * @returns {object} Response of mizar
   */
  function getMizarResponse(xml) {
    const parsedXml = XmlReader.parseSync(xml);
    const jsonXml = JSON.parse(
      parsedXml["children"][0]["children"][0]["children"][0]["children"][0][
        "value"
      ]
    );
    const jsonPropertys = jsonXml["Table1"][0];

    const jsonResponse = {
      added: jsonPropertys["Estatus"],
      idCustomer: jsonPropertys["Identificador"],
      message:
        jsonPropertys["Estatus"] === true
          ? `${customer.customerRFC} sincronizado correctamente`
          : `No fue posible sincronizar, intenta de nuevo`,
    };
    return jsonResponse;
  }

  /**
   * Parse the json as mizar request it
   * @param {string} rfcEmitter - Rfc emitter of the enterprise
   * @param {string} emailEnterprise - Email of the enterprise
   * @returns {string} Object with string format
   */
  function parseJson(rfcEmitter, emailEnterprise) {
    //generic: XAXX010101000

    //   return `{
    // numCliente: "",
    // razonSocial: "Michelé luigi Tramer Guerrero",
    // rfc: "TAGM640703DQ5",
    // calle: "Antonio Maura",
    // colonia: "Moderna",
    // entreCalles: "Juan Ignacio y Tepeyac",
    // numeroInterior: "987",
    // numeroExterior: "187",
    // estado: "Ciudad de Mexico",
    // localidad: "delegacion Benito Juarez",
    // municipio: "Ciudad de Mexico",
    // referencia: "",
    // pais: "Mexico",
    // codigoPostal: "64720",
    // email: "sofiatramer94@gmail.com",
    // telefono: "",
    // rfc_emisor: "URE180429TM6"
    // }`

    return `{
      numCliente:"${parseMizarId(customer?.idMizar)}",
      razonSocial:"${customer.socialReason}",
      rfc:"${customer.customerRFC}",
      calle:"${mizarParseAdress(customer.streetCustomer)}",
      colonia:"${mizarParseAdress(customer.customerColony)}",
      entreCalles:"${mizarParseAdress(customer.streetCustomer)}",
      numeroInterior: "${mizarParseAdress(customer.insideNumber)}",
      numeroExterior: "${mizarParseAdress(customer.exteriorNumber)}",
      estado:"${mizarParseAdress(customer.customerState)}",
      localidad: "ND",
      municipio: "${mizarParseAdress(customer.customerCity)}",
      referencia: "",
      pais:"${mizarParseAdress(customer.customerCity)}",
      codigoPostal:"${mizarParseCp(customer.postalCode)}",
      email:"${mizarParseEmail(customer.emailCustomer, emailEnterprise)}",
      telefono:"${mizarParsePhone(customer.lada_phone, customer.number_phone)}",
      rfc_emisor:"URE180429TM6"

    }`;
  }
}

/**
 * Sync the customer with mizar
 *
 * @param {Customer} customer - Information of the customer
 * @param {number} idCustomer - Id of the customer that has enterprise for the client
 * @returns {boolean} True if customer was added with mizar
 */
export async function AssociateCustomerWithMizar(
  customer = null,
  idCustomer = null
) {
  if (idCustomer === null) {
    return false;
  }

  try {
    const {
      added,
      error,
      message,
      idCustomer: idMizar,
    } = await addMizarCustomer(customer);

    if (added) {
      const wasSync = await AssociateMizarWithSystem(idMizar, idCustomer);

      if (wasSync) {
        console.log({
          idMizar,
          idCustomer,
        });

        return true;
      }
    }

    Error(() => {}, "Sincronizacion fallida. Intenta nuevamente en la edicion");
    return false;
  } catch (error) {
    Error(() => {}, "Sincronizacion fallida. Intenta nuevamente en la edicion");
    return false;
  }
}

/**
 * Sync the ids of mizar the enterprise data
 * @param {number} idMizar - Id that mizar manage on his system
 * @param {number} idCustomer - Id of the customer on the enterprise system
 * @returns {boolean}
 */
export async function AssociateMizarWithSystem(idMizar, idCustomer) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}directorio/cliente/${idCustomer}/mizar`,
      { idMizar }
    );

    if (data.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);

    Error(() => {}, "Sincronizacion fallida. Intenta nuevamente en la edicion");
    return false;
  }
}

// {
//   numCliente: "",
//   razonSocial: "Michelé luigi Tramer Guerrero",
//   rfc: "TAGM640703DQ5",
//   calle: "Antonio Maura",
//   colonia: "Moderna",
//   entreCalles: "Juan Ignacio y Tepeyac",
//   numeroInterior: "987",
//   numeroExterior: "187",
//   estado: "Ciudad de Mexico",
//   localidad: "delegacion Benito Juarez",
//   municipio: "Ciudad de Mexico",
//   referencia: "",
//   pais: "Mexico",
//   codigoPostal: "64720",
//   email: "sofiatramer94@gmail.com",
//   telefono: "",
//   rfc_emisor: "URE180429TM6"
// }
