import React, { useContext } from "react";
import { ContextCloseMonth } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { mnCurrency } from "helpers/money";

export default function Close() {
  const hook = useContext(ContextCloseMonth);

  if (hook.bankAccount === undefined || !hook.isOpen) return null;

  if (hook.amounth <= 0) return null;

  if (hook.isLoading)
    return (
      <div className="d-flex justify-content-end align-items-center">
        <Spinner
          hidden={false}
          text={<LoadingText>Cerrando periodo</LoadingText>}
        />
      </div>
    );

  if (!hook.isValidVariation)
    return (
      <p className="text-danger text-center font-weight-bold">
        La variacion para poder cerrar el mes es de &plusmn;{" "}
        {mnCurrency(hook.variations[hook.bankAccount.currency])}{" "}
        {hook.bankAccount.currency}
      </p>
    );

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ButtonV2
        disabled={!hook.isValidVariation}
        onClick={async () => {
          const wasClosed = await hook.attemptClosePeriod();
          if (wasClosed) {
            hook.onChange();
          }
        }}
      >
        <span>Cerrar periodo</span>
        <Icon icon="save" />
      </ButtonV2>
    </div>
  );
}
