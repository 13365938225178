import * as yup from "yup";

const enterpriseName = yup.string().max(200,'Máximo 200 caracteres').required('Obligatorio');
const street = yup.string().max(100,'Máximo 100 caracteres').required('Obligatorio');
const noInterior = yup.string().max(15,'Máximo 15 caracteres').required('Obligatorio');
const city = yup.string().max(75,'Máximo 75 caracteres').required('Obligatorio');
const estado = yup.string().max(100,'Máximo 100 caracteres').required('Obligatorio');
const postalCode = yup.string().min(5,'5 caracteres').max(5,'5 caracteres').required('Obligatorio');
const email = yup.string().email('Correo inválido').required('Obligatorio');
const noExterior = yup.string().max(15,'Máximo 15 caracteres');

const enterpriseValidation = yup.object().shape({
    enterpriseName,
    street,
    noInterior,
    city,
    estado,
    postalCode,
    email,
    noExterior
});

export default enterpriseValidation