import React from "react";
import style from "./styles.module.scss";
import Icon from "components/individual/Icon";
import { Tutorial } from "structure/Tutorial";
import InstallPraxia from "./InstallPraxia";
import useDialog from "customHooks/useDialog";
import Dialog from "structure/Dialog";

export default function AvailableBrowsers() {
  const dialog = useDialog();

  return (
    <div>
      <div className={style.containerAvailableBrowsers}>
        <hr />
        <div className={style.availableBrowsers}>
          <p>Navegadores compatibles</p>
          <div>
            <Icon nameIcon="microsoftEdge" />
            <Icon nameIcon="googleChrome" />
          </div>
        </div>
        <hr />
      </div>

      <p className={style.appInstallation} onClick={dialog.open}>
        Instala la aplicación <span className={style.deviceType}></span>
      </p>

      <Dialog
        title="Guía: Instalar Praxia"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
      >
        <Tutorial chrome={<InstallPraxia />} edge={<InstallPraxia />} />
      </Dialog>
    </div>
  );
}
