import { Error, Success } from "helpers/alerts";
import { isValidHttpResCode } from "helpers/Apis/fetch";

/**
 * @type {ServiceWorkerRegistration}
 */
export let sw = undefined;

let url = typeof(process.env.REACT_APP_CUSTOM_DOMAIN) !== "string" ? process.env.PUBLIC_URL : process.env.REACT_APP_CUSTOM_DOMAIN;

let urls = url.split(',');

url = urls[urls.length-1];

export function LocalServiceWorkerRegister() {
  const swPath = `${url}/sw.js`;
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker
        .register(swPath)
        .then((registration) => {
          sw = registration;
        })
        .then(sw);
    });
  }
}

/**
 * Create a new subscription to receive notifications, this writes on memory the flag to know the device is receiving subscriptions
 * @returns {Promise<boolean>} If true, user can receive notifications
 */
export async function getSubscription() {
  const response = await new Promise((resolve, reject) => {
    sw.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BCpyIZSJivq9daKJlur_TF9Wsd99Y0AXJ2hjfK5VG7pJdvrE-maYXXGtibospHqKh90HGmX7bwnnfFqZ-6wUXzo",
      })
      .then((res) => res.toJSON())
      .then((subscription) => {
        const body = {
          pushManagerPayload: subscription,
        };

        fetch(
          `${process.env.REACT_APP_URL_BACKEND}/api/tickets/notificaciones`,
          {
            body: JSON.stringify(body),
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            localStorage.setItem("pushNotifications", 1);
            return res.json();
          })
          .then((message) => resolve(true))
          .catch((e) => reject(false));
      });
  });

  return response;
}

/**
 * Disable the notifications on all the devices of the user
 * @returns {Promise<boolean>}
 */
export async function disableNotifications() {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_URL_BACKEND}/api/tickets/notificaciones`,
      {
        credentials: "include",
        method: "DELETE",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, "Notificaciones desactivadas");
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Disable the notifications on all the devices of the user
 * @returns {Promise<boolean>}
 */
export async function resumeNotifications() {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_URL_BACKEND}/api/tickets/notificaciones`,
      {
        credentials: "include",
        method: "PUT",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, "Notificaciones reactivadas");
      await test();
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Test if user is receiving notifications
 * @returns {Promise<boolean>} If true, the test was ok
 */
export async function test() {
  return fetch(`${process.env.REACT_APP_URL_BACKEND}/api/tickets/test`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((message) => true)
    .catch((e) => false);
}
