import { getProyect } from "helpers/Apis/proyects";
import React, { createContext, useContext, useEffect, useState } from "react";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetCustomer } from "helpers/Apis/Directory";
import { parseToFolio } from "helpers/documents";
import MoreInfoDirectory from "structure/MoreInfoDirectory";

/**
 * @type {import("react").Context<import("./types").StateHeaderProyect|undefined>}
 */
const ContextHeaderProyect = createContext(undefined);

/**
 * @type {import("./types").StateHeaderProyect}
 */
const INITIAL_STATE = {
  status: "loading",
  proyect: undefined,
  position: undefined,
  customer: undefined,
};

/**
 * Render the header for the proyect or position requested
 * @param {import('./types').PropsHeaderProyect} param0
 */
export default function HeaderProyect({
  children,
  idPosition = null,
  idProyect = null,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const data = await getProyect(idProyect, idPosition);

      let proyectUnref = { ...data };
      delete proyectUnref.positions;

      const position = data.positions.find((item) => item.id === idPosition);

      const customer = await GetCustomer(data.idClient);

      setState((current) => ({
        ...current,
        position,
        proyect: proyectUnref,
        customer,
        status: "none",
      }));
    })();
  }, []);

  if (state.status === "loading") {
    return (
      <div className={ui.containerHeader}>
        <Spinner text={"Cargando header"} hidden={false} />
      </div>
    );
  }

  return (
    <ContextHeaderProyect.Provider value={state}>
      {children}
    </ContextHeaderProyect.Provider>
  );
}

export function Solped() {
  const hook = useContext(ContextHeaderProyect);

  return (
    <div className={ui.overviewHeader}>
      <b>Solped</b>
      <p>
        {typeof hook.proyect.solped === "string" ? hook.proyect.solped : "ND"}
      </p>
    </div>
  );
}

export function Customer() {
  const hook = useContext(ContextHeaderProyect);

  return (
    <div className={ui.overviewHeader}>
      <b>Cliente</b>
      <MoreInfoDirectory idCustomer={hook.customer.id}>
        <p className={`${ui.linkCustomer} link`}>
          {hook.customer.name.commercial}
        </p>
      </MoreInfoDirectory>
    </div>
  );
}

export function Pos() {
  const hook = useContext(ContextHeaderProyect);

  return (
    <div className={ui.overviewHeader}>
      <b>Pos</b>
      <p>{parseToFolio(hook.position.id)}</p>
    </div>
  );
}
