import { Spinner } from "components/individual/Spinner/Spinner";
import useDialog from "customHooks/useDialog";
import { getInfoContact } from "helpers/Apis/contactInfoApi";
import { parseDateToText } from "helpers/dates";
import React, { useEffect, useState } from "react";
import Dialog from "structure/Dialog";

export default function MoreInfoContact({
  children = <></>,
  id = null,
  forwardRef = undefined,
}) {
  /**
   * @type {import("./types").StateContactOverview}
   */
  const INITIAL_STATE = {
    isLoading: true,
    isOpen: false,
    contact: undefined,
  };

  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (typeof id !== "number" || !state.isOpen) {
        setState(INITIAL_STATE);
        return;
      }

      const apiContact = await getInfoContact(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        contact: apiContact,
      }));
    })();
  }, [id, state.isOpen]);

  const open = () => {
    dialog.open();
    setState((current) => ({
      ...current,
      isOpen: true,
    }));
  };

  const close = () => {
    dialog.close();
    setState(INITIAL_STATE);
  };

  return (
    <div>
      <div ref={forwardRef} onClick={open}>
        {children}
      </div>

      <Dialog
        title={"Más información"}
        closeCallback={close}
        forwardRef={dialog.refDialog}
        width={30}
      >
        {state.isLoading ? (
          <Spinner text={"Cargando contacto"} hidden={false} />
        ) : (
          <>
            <b>Nombre</b>
            <p>
              {state.contact.firstName} {state.contact.middleName || ""}{" "}
              {state.contact.lastName1} {state.contact.lastName2 || ""}
            </p>

            <b>Teléfono</b>
            <p>{state.contact.phone.fullNumber || "ND"}</p>

            <b>Celular</b>
            <p>{state.contact.movil.fullNumber || "ND"}</p>

            <b>Email</b>
            <p>{state.contact.email}</p>

            <b>Puesto</b>
            <p>{state.contact.position || "ND"}</p>

            <b>Aniversario</b>
            <p>
              {state.contact.birthDay
                ? parseDateToText(state.contact.birthDay)
                : "ND"}
            </p>
          </>
        )}
      </Dialog>
    </div>
  );
}
