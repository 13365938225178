import React, { useContext } from "react";
import { HeaderDocumentsComponent } from "../Components/HeaderDocumentsComponent";
import { ItemHeaderSection } from "./ItemHeaderSection";
import WinQuoteContext from "../ContextWinQuote";
import { PriceItems } from "./DocumentItems/PriceItems";
import { CostItems } from "./DocumentItems/CostItems";
import ui from "./styles.module.scss";

export const ItemsSection = () => {
  const { interfaceControl } = useContext(WinQuoteContext);

  const itemsToShow = {
    1: <PriceItems />,
    2: <PriceItems />,
    3: <CostItems />,
    6: <PriceItems />,
  };
  return (
    <div>
      <HeaderDocumentsComponent />
      <div className={ui.module}>
        <ItemHeaderSection />
        <div className={ui.containerItems}>
          {
            itemsToShow[
              interfaceControl.menu[interfaceControl.indexActiveMenu].idType
            ]
          }
        </div>
      </div>
      {/* {menu[index].idType !== 6 ? (
        handleItemsToShow()?.map((item, index) => (
          <ItemConteniner>
            <ItemsBodySection
              sku={item.sku}
              description={item.description}
              quantity={item.quantity}
              unitImport={getNumbers(item).unitary}
              totalImport={getNumbers(item).subTotal}
            />
            <ItemsFooterSection
              discount={getNumbers(item).discount}
              ivaNumber={getNumbers(item).iva}
              ivaPercentage={getNumbers(item).ivaPercentage}
              totalImport={getNumbers(item).subTotal}
              unitImport={getNumbers(item).unitary}
              tc={tc.number}
              index={index}
            />
          </ItemConteniner>
        ))
      ) : interfaceControl.needContract ? (
        items?.map((item) => (
          <ItemConteniner>
            <ItemsBodySection
              sku={item.sku}
              description={item.description}
              quantity={item.quantity}
              unitImport={getNumbers(item).unitary}
              totalImport={getNumbers(item).subTotal}
            />
            <ItemsFooterSection
              discount={getNumbers(item).discount}
              ivaNumber={getNumbers(item).iva}
              ivaPercentage={getNumbers(item).ivaPercentage}
              totalImport={getNumbers(item).subTotal}
              unitImport={getNumbers(item).unitary}
              index={index}
            />
          </ItemConteniner>
        ))
      ) : (
        <div className="d-flex justify-content-center ">
          <h2>No tiene contrato</h2>
        </div>
      )} */}
    </div>
  );
};
