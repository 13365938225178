import React from "react";
import { useContext } from "react";
import Dialog from "structure/Dialog";
import { Context2fa } from "..";
import style from "../styles.module.scss";

export default function ModalRecoveryCodes() {
  const hook = useContext(Context2fa);

  return (
    <Dialog
      forwardRef={hook.dialogRecovery.refDialog}
      // title="2FA Activado - Códigos de recuperación"
      title="2FA Activado"
      width={30}
      closeCallback={hook.dialogRecovery.close}
    >
      <p className="text-center my-5">Doble autenticación activada</p>
      {/* <p className={style.success2fa}>
        Doble autenticación confirmada, antes de cerrar la ventana, guarda las
        llaves de recuperación en un lugar seguro (USB, papel impreso, etc).
        Estas llaves ya no se mostraran una vez cerrada la ventana
      </p>
      <ol className={style.recoveryItems}>
        {hook.recovery.map((recoveryItem, i) => (
          <li key={`${hook.keyReact}-${i}`}>{recoveryItem}</li>
        ))}
      </ol> */}
    </Dialog>
  );
}
