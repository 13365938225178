import React from 'react'
// import { userBredCrums } from '../../../static/StaticBreadCrums';
import { Link } from "react-router-dom";
import './breadCrums.scss';
import { useBredCrum } from './Hooks/useBredCrum';

export const BreadCrums = ({ myBredCrum }) => {
    const { represetedExecutive } = useBredCrum();
    return (
        <ul className="mybreadcrumb">
            {/* <li className="mybreadcrumb-item"><p className={className}>{contentText}</p></li> */}
            {myBredCrum.breadcrum.map(({ className, click, contentText, url }, key) => {

                

                return (
                    <li className="mybreadcrumb-item" key={key}>
                        <Link to={url}>
                            <p className={className} onClick={activo}>{contentText}</p>
                        </Link>
                    </li>
                )
            })}
                    <li className="mybreadcrumb-item" key={'asdfñlkjñ'}>
                        <Link >
                            <p className='breadcrumDesactivo' >| {represetedExecutive}</p>
                        </Link>
                    </li>
        </ul>

    )
}
function activo(e) {
    e.preventDefault();
    
}
