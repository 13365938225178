import React,{JSXElementConstructor} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * Status that updates acording to the selection of the calendar
 * @param {object} props - Props of the component
 * @param {string} props.textCalendar - Text that appears on the right side of the icon
 * @param {string} props.color - CSS color of the validation calendar
 * @param {string} props.idStatus - HTML id of the icon 
 * @returns {JSXElementConstructor} JSX Element
 */
export default function StatusCalendar({textCalendar,idStatus,color}){
    
    const checkIcon = <FontAwesomeIcon className={color} id={idStatus} icon={faCheckCircle} size="lg"/>

    return(
        <div className="mb-2">
            {checkIcon}<label className='ml-2 d-inline'>{textCalendar}</label> 
        </div>


    )
}