import React, { useEffect, useState, useContext } from "react";
import InvoiceOptions from "./InvoiceOptions";

// LIBS
import { DefaultTable } from "js-smart-table/dist/pagination";

// CONTEXT
import TableContext from "../../../../context/Table/TableContext";

// COMPONENTS
import { CustomTable } from "../../../../components/general/Table/CustomTable";

// HELPERS
import ConfigTable from "../../../../static/StaticTables/Invoice";

// APIS
import { fetchDocuments } from "../../../../helpers/Apis/Documents";

import useDocuments from "../../../../customHooks/useDocuments";
import { CancelModal } from "../CancelModal";

import LoginContext from "../../../../context/Login/LoginContext";
import { BotonneraContainer } from "../../../Inicio/Modals/Styles";

export default function Invoice() {
  // let TableInvoice;

  // const { userInfo } = useContext(LoginContext);

  // const [rowInfo, setRowInfo] = useState(null);

  // const { refetch, setLengthData } = useContext(TableContext);

  // const { notifyDocumentsWithFiles, shortCutAssociateFiles, prepareExcelData } =
  //   useDocuments();

  // let TableQuotesConfig = {
  //   ...ConfigTable,
  //   cbSelection: () => setRowInfo(TableInvoice.infoRow),
  // };

  // useEffect(() => {
  //   setRowInfo(null);

  //   async function initialLoad() {
  //     const { actualPage, documents, pages } = await fetchDocuments(
  //       1,
  //       "DESC",
  //       "idDocument"
  //     );

  //     TableQuotesConfig = {
  //       ...TableQuotesConfig,
  //       rows: documents,
  //       pages,
  //       actualPage,
  //       paginationFn: async function (
  //         page,
  //         order,
  //         columnOrdering,
  //         queryString
  //       ) {
  //         const documents = await fetchDocuments(
  //           page,
  //           order,
  //           columnOrdering,
  //           queryString
  //         );
  //         notifyDocumentsWithFiles(documents.documents);
  //         // shortCutAssociateFiles(documents.documents);
  //         prepareExcelData(documents.documents);
  //         return documents;
  //       },
  //     };

  //     TableInvoice = new DefaultTable(TableQuotesConfig);
  //     TableInvoice.printTable();
  //     TableInvoice.printPagination();
  //     setLengthData(documents.length);

  //     notifyDocumentsWithFiles(documents);
  //     // shortCutAssociateFiles(documents);
  //     prepareExcelData(documents);
  //   }

  //   initialLoad();
  // }, [refetch]);

  return (
    <>
      <CustomTable paginationID="invoicePagination" tableID="documents" />
    </>
    // <div>
    //   <BotonneraContainer className="container d-flex justify-content-start aling-items-start my-2 px-0 w-100">
    //   {rowInfo === null ? null : (
    //     <>
    //       <InvoiceOptions rowInfo={rowInfo} />
    //       <CancelModal rowInfo={rowInfo} />
    //     </>
    //   )}
    //   </BotonneraContainer>

    // </div>
  );
}
