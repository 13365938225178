import useConcilationCxC from "customHooks/useConcilationCxc";
import { useState } from "react";
import { useEffect } from "react";

/**
 * Check if the user can perform the concilation of the incoming
 * @param {number} idMovement - Id of the movement tried to be conciliated
 * @returns {boolean}
 */
export default function useValidateConcilationCxc(
  idMovement,
  newResidue = 1,
  newPayMethods = []
) {
  const [state, setState] = useState({
    canConciliate: false,
    previousPayMethods: [],
    currentPayMethods: [],
  });

  const { isLoading, concilations } = useConcilationCxC(idMovement);

  /**
   * Get a new array with the paymethods that has associated the incoming movemen
   * @param {string[]} payMethods - Pay methods
   * @returns {string[]} Set with the pay methods founded
   */
  function createSetOfPayMethods(payMethods = []) {
    let payMethodsSet = new Set();

    payMethods.forEach((payMethod) => payMethodsSet.add(payMethod));

    return [...payMethodsSet];
  }

  const setCanConciliate = (canConciliate) =>
    setState({ ...state, canConciliate });

  useEffect(() => {
    if (concilations.length === 0) return;

    const payMethods = createSetOfPayMethods(
      concilations.map((concilation) => concilation.invoice.payMethod)
    );

    setState({
      ...state,
      previousPayMethods: payMethods,
      currentPayMethods: payMethods,
    });
  }, [concilations]);

  useEffect(() => {
    if (isLoading) return;

    // Complement won't be created, yet...
    if (newResidue > 0) {
      setCanConciliate(true);
      return;
    }

    // At this point, residue equals to 0, meaning that the complement will be created, validate...

    // Only PUE, that wont generate complement
    const { isOnlyPue, calculatedPayMethods } = checkIfOnlyPue();

    if (isOnlyPue) {
      setCanConciliate(true);
      return;
    }

    //////////////////////////////////////////////////////////////

    function checkIfOnlyPue() {
      const calculatedPayMethods = createSetOfPayMethods([
        ...state.previousPayMethods,
        ...newPayMethods,
      ]);

      if (calculatedPayMethods.length === 1) {
        if (calculatedPayMethods[0] === "PUE") {
          return {
            isOnlyPue: true,
            calculatedPayMethods,
          };
        }
      }

      return {
        isOnlyPue: false,
        calculatedPayMethods,
      };
    }
  }, [newResidue, newPayMethods]);

  return state.canConciliate;
}
