import React, { useContext } from "react";
import { UsersContext } from "./UsuariosV2";
import { Label } from "structure/Document";
import { Search } from "components/individual/Search";

export const SearchUsers = () => {
  const { search, setSearch, refetchTable } = useContext(UsersContext);

  return (
    <div

    >
      <Label>Búsqueda</Label>
      <Search
        value={search}
        onDelete={() => {
          setSearch("");
          refetchTable();
        }}
        onSearch={refetchTable}
        placeholder="Usuario , correo"
        onChange={setSearch}
      />
    </div>
  );
};
