import styled from "styled-components";

export const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "84%",
      padding: "2rem",
    },
  };
  
  export const customStyleDiv = {
    width: "25%",
  };
  