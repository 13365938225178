import React from "react";
import { DateContainers, DateLabel } from "../Containers";
import { InputDatePicker } from "components/Elements/InputDatePicker";
import { useContext } from "react";
import WinQuoteContext from "../../ContextWinQuote";
import { formateDate } from "helpers/dates";

export const OrderDateSection = () => {
  const isDisable = true;
  const { documents, handleOrderExpirationDate, handleOrderReminderDate } =
    useContext(WinQuoteContext);
  return (
    <>
      <DateContainers>
        <DateLabel>Creación</DateLabel>
        <input
          type="text"
          className="disable"
          value={formateDate(new Date())}
          disabled
        />
      </DateContainers>

      <DateContainers>
        <DateLabel>Facturar</DateLabel>
        <InputDatePicker
          placeholderText="31/ene/2023"
          selectedDate={documents.order.dates?.invoiceDate}
          disable={isDisable}
          handleDateChange={handleOrderExpirationDate}
          minDate={new Date()}
        />
      </DateContainers>
      <DateContainers>
        <DateLabel>Recordatorio</DateLabel>
        <InputDatePicker
          placeholderText="31/ene/2023"
          selectedDate={documents.order.dates?.reminderDate}
          handleDateChange={handleOrderReminderDate}
          disable={isDisable}
          minDate={new Date()}
          maxDate={documents.order.dates?.invoiceDate}
        />
      </DateContainers>
    </>
  );
};
