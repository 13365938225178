import React, { useContext } from "react";
import ui from "./styles.module.scss";
import Date from "./Date";
import Search from "./Search";
import Excel from "./Excel";
import Tag from "./Tag";
import TagPaymentReminder, {
  NoCreatable,
} from "components/general/Forms/Selects/TagPaymentReminder";
import { ContextPaymentReminder } from "..";

export default function Filter() {
  const hook = useContext(ContextPaymentReminder);

  return (
    <div className={ui.filter}>
      <TagPaymentReminder>
        <NoCreatable isClearable={true} onChange={hook.setTag} />
      </TagPaymentReminder>
      <Date />
      <Search />
      {/* <Excel /> */}
      <Tag />
    </div>
  );
}
