let TableConfig = {
  idTable: "associate-customer",
  idPagination: "pagination-associate-customer",
  pages: 0,
  rows: [],
  actualPage: 0,
  cbSelection: () => {},
  headerConfig: [
    {
      text: "Número",
      columnNameDB: "customerID",
      idHeader: "customer-id",
      attributeToPrint: "ID",
      sortable: true,
      sortThis: true,
    },

    {
      text: "Razón social",
      columnNameDB: "socialReason",
      idHeader: "customer-social-reason",
      attributeToPrint: "Razon_social",
      sortable: true,
    },

    {
      text: "Nombre comercial",
      columnNameDB: "commercialName",
      idHeader: "customer-commercial-name",
      attributeToPrint: "Nombre_comercial",
      sortable: true,
    },

    {
      text: "Nombre corto",
      columnNameDB: "shortName",
      idHeader: "customer-short-name",
      attributeToPrint: "Nombre_corto",
      sortable: true,
    },

    /* { text: 'Ejecutivo', columnNameDB: 'MovementID', idHeader: 'movement-id', attributeToPrint: 'Movimiento', sortable: true,css:'text-right' }, */

    {
      text: "Teléfono",
      columnNameDB: "phone",
      idHeader: "customer-phone",
      attributeToPrint: "Telefono",
      sortable: false,
      css: "text-right",
    },

    {
      text: "Móvil",
      columnNameDB: "movil",
      idHeader: "customer-movil",
      attributeToPrint: "Movil",
      sortable: false,
      css: "text-right",
    },
  ],
  // styleTable: "default",
  idRows: "ID",
  sort: {
    sqlSort: true,
    sortASC: false,
  },
  attributesResponse: {
    pages: "pages",
    actualPage: "actualPage",
    data: "listAssociate",
  },
};

export default TableConfig;
