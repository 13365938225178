import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import useInvoice from "customHooks/useAddInvoice";

import { InvoiceContext } from "../AddInvoice";
import useQueryParams from "customHooks/useQueryParams";
// import HeaderDocument from "components/general/HeaderDocument";
// import InvoiceTab from "../AddInvoice/InvoiceTab";
// import CommentsDocument from "components/general/CommentsDocument";
// import Partidas from "structure/Partidas";
// import { Tabs } from "components/general/Tabs/Tabs";
// import UpdateInvoiceButton from "./UpdateButton";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import ui from "structure/ODC/styles.module.scss";
import usePermissions from "customHooks/usePermissions";

/**
 * Render the view in order to update an invoice
 * @param {object} props - Props
 * @param {"administracion"|"documentos"} props.redirectPath - When the update it's perform, this indicates (also for the bradcrum) to which part of the system must be redirected the user when query it success
 * @param {number?} props.idCustomer - Id of the customer where came the user (in case he navigated from the documentos module)
 * @returns {JSX.Element} Edit invoice component
 */
export default function EditInvoice({
  redirectPath = "administracion",
  idCustomer = null,
}) {
  const { modulo } = useQueryParams();

  const { idDocumento } = useQueryParams();


  const {
    breadcrumPath,
    updateItems,
    tc,
    updateTc,
    updateSocialReason,
    currency,
    updateCurrency,
    importe,
    iva,
    total,
    updateCfdi,
    updatePayForm,
    updatePayMethod,
    isDocumentFetched,
    socialReason,
    updateContact,
    cfdi,
    payForm,
    payMethod,
    updatePeriocity,
    periocity,
    canPerformQuery,
    isPerformingQuery,
    customerSocialReason,
    updateNotesAndConsiderations,
    performAddInvoice,
    updateComments,
    customer,
    contact,
    performUpdateInvoice,
    updateExpirationDate,
    updateReminderDate,
    expiration,
    reminder,
    docNumber,
  } = useInvoice({
    idInvoice: idDocumento,
    redirectPath: modulo !== undefined ? "administracion" : "documentos",
    idCustomer,
  });

  return (
    <InvoiceContext.Provider
      value={{
        modulo: modulo !== undefined ? "administracion" : "documentos",
        tc,
        updateTc,
        updateCurrency,
        currency,
        importe,
        performAddInvoice,
        iva,
        total,
        updateCfdi,
        updatePayForm,
        updatePayMethod,
        isDocumentFetched,
        updateSocialReason,
        socialReason,
        updateContact,
        cfdi,
        payForm,
        payMethod,
        updatePeriocity,
        periocity,
        canPerformQuery,
        isPerformingQuery,
        performAddInvoice,
        docNumber,
        updateNotesAndConsiderations,
        customer,
        contact,
        performUpdateInvoice,
        updateExpirationDate,
        updateReminderDate,
        expiration,
        reminder,
        module: modulo,
        customerSocialReason,
      }}
    >
      <div className={ui.container}>
      <BreadCrumV2 path={breadcrumPath} />

      <AddDocument
        actionType="Editar"
        customerId={idCustomer}
        documentType={2}
        documentId={idDocumento}
      />
      </div>

      {/* <div className="container">
        <header className="d-flex align-items-center justify-content-between my-3">
          <HeaderDocument
            currency={currency}
            socialReason={customerSocialReason}
          />
          <UpdateInvoiceButton />
        </header>

        <Tabs
          idContainerContent={"documentInfo"}
          idContainerTabs={"tabs"}
          tabButtons={{
            document: {
              text: "Pedido",
              selected: true,
              component: <InvoiceTab />,
            },
            items: {
              text: "Partidas",
              selected: false,
              component: (
                <Partidas
                  calculationMode="pu"
                  currency={currency}
                  tc={tc}
                  typeDocument="pedido"
                  onChange={updateItems}
                  idDocument={+idDocumento}
                />
              ),
            },
            comments: {
              text: "Notas y consideraciones",
              selected: false,
              component: (
                <CommentsDocument
                  includeCc={false}
                  idDocument={+idDocumento}
                  idTypeDocument={2}
                  onChange={updateComments}
                />
              ),
            },
          }}
        />
      </div> */}
    </InvoiceContext.Provider>
  );
}
