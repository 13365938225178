import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import AbcBank from "structure/Menu/Banks/AbcBank";
import BankAccountReport from "structure/Menu/Banks/Reports/BankAccountReport";
import TransferMenu from "structure/Menu/Banks/Transfer";

export default function ScreenBanks() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "banks") return <></>;

  return (
    <>
      <NavigationItem icon="home" label="Inicio > Bancos" subMenu="general" />
      <NavigationItem icon="banks" label="Cuentas" link="/v2/bancos" />

      <AbcBank id={null}>
        <NavigationItem icon="user" label="Agregar" />
      </AbcBank>

      <TransferMenu>
        <NavigationItem icon="banks" label="Transferencias" />
      </TransferMenu>
      <NavigationItem icon="banks" label="Anticipos" subMenu="anticipos" />
      <NavigationItem icon="banks" label="Reportes" subMenu="reportBanks" />
    </>
  );
}
