import { ErrorAlertText, Warning } from "helpers/alerts";
import ButtonV2 from "../ButtonsV2/Button";
import { chanChangeCurrency } from "components/Sections/Documents/helpers/documentHelper";
import React from "react";

/**
 *
 * @param {{goEdit:()=>void;idDocument:number}} props
 * @returns {JSX.Element}
 */
export default function EditDocumentButton({ goEdit, idDocument }) {
  return (
    <ButtonV2
      onClick={async () => {
        try {
          const itCanChange = await chanChangeCurrency(idDocument);
          console.log("🚀 ~ onClick={ ~ itCanChange:", itCanChange);
          if (itCanChange) {
            console.log("si puede cambiar");
            Warning(
              "Aviso Importante: Cambio Automático de Moneda",
              "Si decides editar el documento, ten en cuenta que la moneda se ajustará automáticamente a la moneda de compra de los productos incluidos en las partidas del mismo. Por favor, verifica cuidadosamente antes de proceder con la actualización.",
              goEdit
            );
          } else {
            console.log("No cambia la moneda");
            goEdit();
          }
        } catch (error) {
          ErrorAlertText(
            () => {},
            "No se pudo validar",
            "No se pudo obtener la validacion de las partidas para mandar mensaje",
            ""
          );
        }
      }}
    >
      Editar
    </ButtonV2>
  );
}
