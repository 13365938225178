import React from "react";
import { useContext } from "react";
import { ContextContact } from "..";
import { RedirectButton } from "components/individual/buttons/Buttons";
import style from "../styles.module.scss";
import Edit from "../Edit";

export default function OptionsContacts() {
  const hook = useContext(ContextContact);

  if (hook.contact === undefined || hook.contact === null)
    return <div className={style.containerOptions}></div>;

  return (
    <div className={style.containerOptions}>
      <RedirectButton
        text="To Do"
        link={`/directorio/contactos/to-do?contacto=${hook.contact.id}&cliente=${hook.customerId}`}
      />

      <Edit/>
    </div>
  );
}
