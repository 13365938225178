import { GetConsolation } from "helpers/Apis/Banks";
import {
  UPDATE_REGULAR_COMMENTS,
  UPDATE_DOCUMENT_ITEMS_INFO,
  UPDATE_PROBABILITY,
  UPDATE_REMINDER_DATE,
  UPDATE_EXPIRATION_DATE,
  UPDATE_TC,
  UPDATE_CURRENCY,
  UPDATE_NOTES_AND_CONSIDERATIONS,
  UPDATE_PERIOCITY,
  SET_INITIAL_STATE,
  SET_IS_PERFORMING_QUERY,
  SET_CONTACT,
  APPEND_CONTACT_MEMORY,
  GET_CONTACTS,
} from "./types";

/**
 * Get the dates parsed from the component
 * @param {string} date - Date as string
 * @returns {[day,month,year]} Dates parsed
 */
const parseDateFromComponent = (date) =>{

  console.log('Fecha en el reducer');
  console.log(date);
  console.log(new Date(date).toISOString());
  return new Date(date).toISOString();


}
  // new Date(date).toLocaleDateString().replaceAll("/", "-").split("-");

export default function reducer(state, action) {
  switch (action.type) {
    case UPDATE_REGULAR_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          regulars: action.payload,
        },
      };

    case GET_CONTACTS: {
      return {
        ...state,
        contactsOnMemory: action.payload,
      };
    }

    case APPEND_CONTACT_MEMORY: {
      const contactInfo = {
        ...action.payload,
        contactID: action.payload.id,
        value: action.payload.id,
        label: `${action.payload.nombre} ${action.payload.middleName} ${action.payload.apellidoP} ${action.payload.apellidoM}`,
      };

      return {
        ...state,
        document: {
          ...state.document,
          contact: {
            id: action.payload.id,
            data: { ...contactInfo },
          },
        },
        contactsOnMemory: [contactInfo, ...state.contactsOnMemory],
      };
    }

    case SET_CONTACT: {
      if (action.payload === null) {
        return {
          ...state,
          document: {
            ...state.document,
            contact: {
              id: null,
              data: {
                id: null,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          document: {
            ...state.document,
            contact: {
              id: action.payload.contactID,
              data: {
                ...action.payload,
                id: action.payload.contactID,
              },
            },
          },
        };
      }
    }

    case UPDATE_DOCUMENT_ITEMS_INFO: {
      return {
        ...action.payload,
      };
    }

    case UPDATE_PROBABILITY: {
      return {
        ...state,
        document: {
          ...state.document,
          probability: action.payload,
        },
      };
    }

    case UPDATE_REMINDER_DATE: {
      // const [day, month, year] = parseDateFromComponent(action.payload);
      const isoDate = parseDateFromComponent(action.payload);

      return {
        ...state,
        document: {
          ...state.document,
          reminder: {
            db: isoDate,
            js: action.payload,
          },
        },
      };
    }

    case UPDATE_EXPIRATION_DATE: {
      // const [day, month, year] = parseDateFromComponent(action.payload);
      const isoDate = parseDateFromComponent(action.payload);

      return {
        ...state,
        document: {
          ...state.document,
          expiration: {
            db: isoDate,
            js: action.payload,
          },
        },
      };
    }

    case UPDATE_TC: {
      return {
        ...state,
        document: {
          ...state.document,
          tc: action.payload,
        },
      };
    }

    case UPDATE_CURRENCY: {
      return {
        ...state,
        document: {
          ...state.document,
          currency: action.payload,
        },
      };
    }

    case UPDATE_NOTES_AND_CONSIDERATIONS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          notesAndConsiderations: action.payload,
        },
      };
    }

    case UPDATE_PERIOCITY: {
      return {
        ...state,
        document: {
          ...state.document,
          periocity: action.payload,
        },
      };
    }

    case SET_INITIAL_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_IS_PERFORMING_QUERY: {
      return {
        ...state,
        isPerformingQuery: action.payload,
      };
    }

    default:
      return { ...state };
  }
}
