import React from "react";
import { FooterDiferentCurrency, FooterInfoTex } from "../Footer/Containers";

/**
 * Fotter text component
 * @param {import("../../../../../../types/winQuote").FooterTextI} { label,
  text,info}
 * @returns {import("react").JSXElementConstructor}
 */
export const FooterTextComponent = ({ label, text, info = null }) => {
  return (
    <FooterInfoTex>
      <p className="m-0 mr-2 text-center">
        <strong>{label}: </strong>
      </p>
      <p className="m-0 text-center">{text}</p>
      {!!info ? <FooterDiferentCurrency>{info}</FooterDiferentCurrency> : ""}
    </FooterInfoTex>
  );
};
