import React from "react";
import useCreateInvoiceV2, { CONTEXT_VALUE } from "./useCreateInvoiceV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { createContext } from "react";
import AuthorizationScreen from "./AuthorizationScreen";
import CurrencyExchange from "./CurrencyExchange";
import TcOptions from "./TcOptions";
import Partialities from "./Partialities";
import style from "./styles.module.scss";
import GridPartialities from "pages/Directory/Documents/Invoice/GridPartialities/GridPartialities";
import { addDays } from "date-fns";
import Footer from "./Footer";
import CommentsOrder from "./Comments";
import PayForm from "./PayForm";
import Receiver from "./Receiver";
import CustomCfdi from "./CustomCfdi";
import CustomPayMethod from "./CustomPayMethod";

export const ContextCreateInvoiceV2 = createContext(CONTEXT_VALUE);

/**
 * Render a button
 * @param {import('./types').PropsCreateInvoiceV2} props - Props
 * @returns {JSX.Element}
 */
export default function CreateInvoiceV2({
  id = null,
  onCreated = () => {},
  onError = () => {},
}) {
  const hook = useCreateInvoiceV2(id, onCreated, onError);

  //   Loading information in order to create the invoice
  if (hook.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingOrderInformation"
        text={<LoadingText>Cargando pedido</LoadingText>}
      />
    );

  if (!hook.isValidStatus)
    return (
      <div>
        <h5 className="text-center py-5">
          Este pedido no se puede timbrar, los errores pueden ser los siguientes
          🤔
        </h5>
        <p>1. Pedido cancelado por otro ejecutivo</p>
        <p>2. Pedido timbrado por otro ejecutivo</p>
      </div>
    );

  // Order in revision, can't create invoice
  if (hook.isOnRevision)
    return <h5 className="text-center py-5">{hook.messageAuthorization}</h5>;

  //   Authorization requested acepted, allow alter only the expiration date
  if (hook.limitCreationTime !== null)
    return (
      <ContextCreateInvoiceV2.Provider value={hook}>
        <AuthorizationScreen />
      </ContextCreateInvoiceV2.Provider>
    );

  // Display form to create the invoice
  return (
    <ContextCreateInvoiceV2.Provider value={hook}>
      <div className={style.optionsToCreateInvoice}>
        <CurrencyExchange />

        <Receiver/>
        <TcOptions />
        <CommentsOrder />
      </div>

      <div className={style.comboOptions}>
        <Partialities />
        <div>
          <CustomCfdi/>
          
        </div>

        <PayForm />

        <CustomPayMethod/>
      </div>

      <div className={style.containerPartialities}>
        <GridPartialities
          partialities={hook.partialities}
          tc={hook.tc}
          beginDate={addDays(new Date(), hook.creditDays)}
          onChange={(cxcs) => hook.updatePartialitiesCxc(cxcs)}
          currency={hook.currency}
          totalAmountPreinvoice={hook.total}
        />
      </div>

      <Footer />
    </ContextCreateInvoiceV2.Provider>
  );
}
