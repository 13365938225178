import ButtonV2 from "components/individual/ButtonsV2/Button";
import useDialog from "customHooks/useDialog";
import usePermissions from "customHooks/usePermissions";
import React from "react";
import { useState } from "react";
import CreateInvoiceV2 from "structure/CreateInvoiceV2";
import Dialog from "structure/Dialog";

/**
 * Render a button to create an invoice against the SAT
 * @param {import('./types').PropsModalCreateInvoiceV2} props - Props
 * @returns {JSX.Element}
 */
export default function ModalCreateInvoiceV2({
  idOrder = null,
  idStatus = 4,
  evaluatePermission = false,
  onCreated = () => {},
  children = null,
  forwardRef = undefined,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useDialog();

  const handleOnSuccess = () => {
    setIsOpen(false);
    onCreated();
    dialog.close();
  };

  const openModal = () => {
    setIsOpen(true);
    dialog.open();
  };

  const closeModal = () => {
    setIsOpen(false);
    dialog.close();
  };

  const { checkPermission } = usePermissions();

  // Evaluate permissions of current rol in order to render the button
  if (
    evaluatePermission &&
    !checkPermission("368afea7-a30f-4078-a5b3-a9cf24404b49")
  )
    return <></>;

  // Invoice has not status `Abierta`
  if (idStatus !== 4) return <></>;

  // Render button
  return (
    <div>
      {children === null ? (
        <ButtonV2 onClick={openModal}>Timbrar</ButtonV2>
      ) : (
        <div ref={forwardRef} onClick={openModal}>{children}</div>
      )}

      <Dialog
        title="Timbrar pedido"
        forwardRef={dialog.refDialog}
        width={50}
        closeCallback={closeModal}
      >
        {isOpen ? (
          <CreateInvoiceV2 id={idOrder} onCreated={handleOnSuccess} onError={()=>{
            closeModal();

          }}/>
        ) : null}
      </Dialog>
    </div>
  );
}
