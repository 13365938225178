import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextCashFlow } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Icon from "components/individual/HeaderIcon";

export default function ExcelCashFlow() {
  const hook = useContext(ContextCashFlow);

  if (!hook.areValidFilters) return <></>;

  if (hook.cashFlow.cashFlow === undefined) return <></>;

  if (hook.cashFlow.isDownloadingExcel)
    return <Spinner hidden={false} text={<LoadingText>Descargando</LoadingText>} />;

  return <ButtonV2 onClick={hook.downloadExcelReport}>
    <span>Excel</span>
    <Icon icon="excel"/>
  </ButtonV2>;
}
