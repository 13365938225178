import { Spinner } from "components/individual/Spinner/Spinner";
import useCancelPosition from "customHooks/useCancelPosition";
import React from "react";

/**
 * @param {import("./types").PropsCancelPosition} props
 */
export default function CancelPosition(props) {
  const hook = useCancelPosition(props.idPosition, props.onCancelated);

  let unrefProps = { ...props };
  delete unrefProps.idPosition;
  delete unrefProps.onCancelated;

  if (hook.status === "canceling")
    return <Spinner text={"Cancelando posición"} hidden={false} />;

  return (
    <div {...unrefProps} onClick={hook.handleDelete}>
      {props?.children}
    </div>
  );
}
