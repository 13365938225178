import React, { useContext, useEffect, useState } from "react";
import HomeHeader from "../../../components/general/homeNavBar/HomeHeader";
import { ForecastNavigation } from "../../../static/StaticBreadCrums";
import {
  evaluatePermission,
  textPermission,
} from "../../../helpers/permissions";
import PermissionsContext from "../../../context/Login/LoginContext";
import {
  ExportExcelButton,
  GoBack,
  ModalButton,
  SearchButton,
} from "../../../components/individual/buttons/Buttons";
import { EjecutivosInput, SearchInput } from "../../../components/individual/inputs/Inputs";
import axios from "axios";
import TableContext from "../../../context/Table/TableContext";
import ViewContext from "../../../context/View/ViewContext";
import { rowsPerPage } from "../../../static/TableConfig";
import { URL_BASE } from "../../../routes/routes";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { RangeFilter } from "../../../components/individual/Calendar/Calendar";

export const Forecast = () => {
  const { rolPermitions } = useContext(PermissionsContext);
  const { view, setView } = useContext(ViewContext);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //DESTRUCTORING de los datos en TableContext
  const {
    isFetching,
    setIsFetching,
    setData,
    setFoundedRows,
    setPages,
    data,
    pages,
    actualPage,
    setCriterianOrder,
    infoRow,
    setInfoRow,
    refetch,
    setOrderBy,
  } = useContext(TableContext);

  //SEARCH funion para filtrar la tabla, por el momento esta incompleta
  const search = async (e) => {
    /* AVOID SEND FORM */
    e.preventDefault();

    /* GET CONTENT FROM THE SEARCH BAR */
    // const input = document.getElementById("searchRolInput").value;

    // /* VALIDATE ACTUALLY IT'S NOT EMPTY THE SEARCH BAR */
    // if (input.length <= 0) {
    //   document.getElementById("errorSearch").classList.remove("d-none");
    //   setTimeout(() => {
    //     document.getElementById("errorSearch").classList.add("d-none");
    //   }, 2000);
    //   return;
    // }

    // /* VALIDATIONS CORRECT, CHANGE VIEW OF TABLE */
    // await searchForecast(input, 0, rowsPerPage, "rolID");
    // document.getElementById('tableOptions').classList.add('d-none');
    // setView("searchForecast");
  };

  //BUSCARFORECAST Es la funcion asincrona que hace el fetch para filtrar la tabla
  const searchForecast = async (
    searchText,
    begin,
    end,
    columnOrdering,
    orderFetch = `DESC`
  ) => {
    try {
      setIsFetching(true);

      const { data } = await axios.get(
        `${URL_BASE}forecast/${searchText}/${begin}/${end}/${orderFetch}/${columnOrdering}`
      );

      setData(data.data[0].rols);

      /* SET FOUNDED ROWS TO CALCULATE TOTAL PAGES */
      setFoundedRows(data.data[0].rows);
      setPages();
      setOrderBy(0);
      setCriterianOrder("DESC");

      setIsFetching(false);
      return data.data[0];
    } catch (error) {
      console.log(error);
    }
  };
  const fetchForecast = async (
    begin,
    end,
    columnOrdering,
    orderFetch = `DESC`
  ) => {
    try {
      setIsFetching(true);

      const { data } = await axios.get(
        `${URL_BASE}forecast/${columnOrdering}/${orderFetch}/${begin}/${end}`
      );

      /* SET THE ROWS FETCHED FROM THE DB TO USERS STATE */
      setData(data.data[0].rols);

      /* FETCH ENDED */
      setIsFetching(false);

      /* SET FOUNDED ROWS TO CALCULATE TOTAL PAGES */
      setFoundedRows(data.data[0].rows);
      setPages();
      setOrderBy(0);
      setCriterianOrder("DESC");
      return data.data[0];
    } catch (error) {
      console.log(`Hubo un error - ${error}`);
    }
  };
  //GOBACK Funcion para volver a la tabla por defecto.
  const goBack = async () => {
    document.getElementById("searchRolInput").value = "";
    setView("default");
    document.getElementById("tableOptions").classList.add("d-none");
    fetchForecast(0, rowsPerPage, "forecastID"); //REVISION
  };

  return (
    <>
      <HomeHeader mostrar={false} bredcrum={ForecastNavigation} />
      {/* SEARCH OPTIONS */}

      <div className="container">
        <div className="row d-flex ">
          {evaluatePermission([83], rolPermitions) ? (
            <>
              <form
                className="col-9 flex-row "
                id="searchForm"
                onSubmit={(e) => search(e)}
              >
                <div className="form-row">
                  <div className="col">
                    <EjecutivosInput id='forecastEjecutivos'/>
                  </div>
                  <div className="col">
                    <RangeFilter idModal='forecastRange'/>
                  </div>
                  <div className="col d-flex aling-items-center p-0 m-0">
                    <SearchButton text="Buscar" idForm="searchForm" aditionalClasses="mt-4"/>
                  </div>
                </div>
              </form>
            </>
          ) : null}
          {evaluatePermission([82],rolPermitions)? (
            <ExportExcelButton text={textPermission(82,rolPermitions)}/>
          ): null
          }
        </div>
        {view === "default" ? null : (
          <GoBack text="Forecast" cb={() => goBack()} />
        )}
      </div>
    </>
  );
};
