import React from "react";
import scss from "./styles.module.scss";

export const EmailExtensions = () => {
  return (
    <div className={scss.emailExtensions}>
      <p>
        <b>Extensiones validas</b>
        Todas
      </p>
    </div>
  );
};
