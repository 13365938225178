import React, { useState } from "react";
import DatePicker from "react-date-picker";
import "./styles.scss";

/**
 * Render a input of type date
 * @param {import('./types').PropsDateInput} props - Props
 * @returns {JSX.Element} Render an input of type date
 */
export const DateInput = (props) => {
  const [date, setDate] = useState(props?.value || null);

  const handleOnChange = (date) => {
    setDate(date);

    if (props.onChange !== undefined) props.onChange(date);
  };

  return (
    <DatePicker
      format="dd/MM/yy"
      {...props}
      value={date}
      onChange={handleOnChange}
      className="containerDate"
    />
  );
};
