import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { ContextAuthsInvoice } from "..";

export default function NoInvoice() {
  const hook = useContext(ContextAuthsInvoice);

  return (
    <div>
      <b>No. pedido</b>
      <Search
        onDelete={()=>hook.setFolio("", "noInvoice")}
        hideSearchIcon={true}
        type="number"
        placeholder="Escribe aquí"
        onChange={(data) => hook.setFolio(data, "noInvoice")}
      />
    </div>
  );
}
