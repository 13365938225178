import React from "react";
import concept from "./styles.module.scss";
import { useRef } from "react";

/**
 * Render the concepts
 * @param {import('./types').PropsConcepts} props - Props
 * @returns {JSX.Element}
 */
export default function Concepts({ concepts = [] }) {
  const key = useRef(window.crypto.randomUUID());

  return (
    <div className={concept.list}>
      {concepts.map((concept, i) => (
        <span key={`${key.current}-${i}`}>{concept}</span>
      ))}
    </div>
  );
}
