import React from "react";
import { useContext } from "react";
import { MobileMenuContext } from "../..";
import NavigationItem from "../../NavigationItem";
import CatalogueDirectory from "structure/CatalogueDirectory";
import MicrosoftCsp from "structure/MicrosoftCsp";

export default function SellsReports() {
  const hook = useContext(MobileMenuContext);

  if (hook.screen !== "ventasReportes") return <></>;

  return (
    <>
      <NavigationItem
        icon="home"
        label="Inicio > Ventas > Reportes"
        subMenu="ventas"
      />
      <NavigationItem
        icon="document"
        label="Cuentas por cobrar"
        link="/ventas/cxc"
      />

      <CatalogueDirectory type="clients">
        <NavigationItem icon="document" label="Catálogo clientes" />
      </CatalogueDirectory>

      {/* <NavigationItem icon="search" label="Cuotas" link="/ventas/cuotas"/> */}
      <NavigationItem
        icon="document"
        label="Histórico de contratos"
        link="/ventas/historico-contratos"
      />
      <NavigationItem
        icon="document"
        label="Saldos y movimientos"
        link="/saldos-y-movimientos"
      />
      <NavigationItem
        icon="document"
        label="Antigüedad de saldos"
        link="/v2/bancos/cxc/antiguedad-saldos"
      />

      <MicrosoftCsp>
        <NavigationItem icon="document" label="BI" />
      </MicrosoftCsp>
    </>
  );
}
