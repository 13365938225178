import InformativeTooltip from "components/individual/InfoTooltip";
import ToggleComponent from "components/individual/inputs/Toggle";
import React, { useContext } from "react";
import Toggle from "react-toggle";
import { Label } from "structure/Document";
import { RemindersContext } from "..";

export default function ReminderWhatsapp() {
  const { cellphone, whatsapp, updateWhatsapp, numberOfOptionsActive } =
    useContext(RemindersContext);

  const blockToggle = numberOfOptionsActive <= 1 && whatsapp ? true : false;

  return cellphone === true ? (
    <>
      <Label>
        Whatsapp
        {numberOfOptionsActive <= 1 ? (
          <InformativeTooltip>
            No puede deseleccionar esta opción. Al menos un metodo de
            recordatorio es necesario
          </InformativeTooltip>
        ) : null}
      </Label>
      <div className="d-flex align-items-center">
        <Toggle
          style={{
            cursor: "not-allowed",
          }}
          disabled={blockToggle}
          checked={whatsapp}
          onChange={(e) => updateWhatsapp(e.target.checked)}
        />
        <span style={{ margin: "0 0 0 10px" }}>
          <b>{whatsapp ? "Si" : "No"}</b>
        </span>
      </div>
    </>
  ) : (
    <>
      <Label>
        Whatsapp
        <InformativeTooltip>
          Debes registrar un número telefonico valido para esta opcion
        </InformativeTooltip>
      </Label>
      <div className="d-flex align-items-center">
        <Toggle
          style={{
            cursor: "not-allowed",
          }}
          disabled={true}
          checked={false}
        />
        <span style={{ margin: "0 0 0 10px" }}>
          <b>No</b>
        </span>
      </div>
    </>
  );
}
