import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import useDialog from "customHooks/useDialog";
import React from "react";
import Dialog from "structure/Dialog";
import RangeFilter from "../RangeFilter";
import SwitchProcess from "../SwitchProcess";
import style from "./styles.module.scss";

export default function FilterForMobile() {
  const dialog = useDialog();

  return (
    <div>
      <ButtonV2 onClick={(e) => dialog.open()}>
        <Icon icon="filter" />
      </ButtonV2>

      <Dialog
        forwardRef={dialog.refDialog}
        title="Filtros - Ingresos"
        closeCallback={dialog.close}
      >
        <div className={style.filterMobile}>
          <RangeFilter />
          <SwitchProcess />
        </div>
      </Dialog>
    </div>
  );
}
