import React from "react";
import { useContext } from "react";
import { ContextMoreInfoMovementV2 } from "..";
import IncomingComplements from "pages/BanksV2/Incomings/Complements";
import Complement from "pages/BanksV2/Incomings/Complements/Invoice";
import Concept from "pages/BanksV2/Incomings/Complements/Concept";
import PueAssociations from "pages/BanksV2/Incomings/Complements/PUE";
import ButtonDisassociate from "pages/BanksV2/Incomings/Complements/ButtonDisassociate";
import style from "./styles.module.scss";

export default function Incoming() {
  const hook = useContext(ContextMoreInfoMovementV2);

  if (hook.movement === undefined || hook.movement === null) return <></>;

  if (hook.movement.type.description !== "Ingreso") return <></>;

  return (
    <IncomingComplements
      idMovement={hook.movement.id}
      displayCheckboxs={hook.isForCancel}
      onSuccess={()=>{
        hook.onCancelated();
        hook.handleDisplayModal(false);
      }}
    >
      <div
        className={hook.isForCancel ? style.containerIncomingOverview : null}
      >
        <div>
          <Complement />
          <hr />
          <Concept />
          <hr />
          <PueAssociations />
        </div>
        <div>
          <ButtonDisassociate />
        </div>
      </div>
    </IncomingComplements>
  );
}
