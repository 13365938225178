import CheckboxesRols from "components/general/CheckboxesRols";
import { Spinner } from "components/individual/Spinner/Spinner";
import React, { useContext } from "react";
import { ContextEditRol } from "..";
import LoadingText from "components/individual/TextLoading";

export default function Step3() {
  const {
    description: { content },
    rols,
    isUpdatingRol,
    handleUpdateCheckboxes,
  } = useContext(ContextEditRol);

  return (
    <>
      {isUpdatingRol ? (
        <Spinner idSpinner="updatingRol" hidden={false} text={<LoadingText>Actualizando rol</LoadingText>} />
      ) : (
        <>
          <h4 className="text-center">
            Selecciona los roles que podra supervisar '{content}'
          </h4>
          <CheckboxesRols checkeds={rols} onChange={handleUpdateCheckboxes} />
        </>
      )}
    </>
  );
}
