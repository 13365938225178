import React, { useRef , useContext } from "react";
import table from "./styles.module.scss";
import { ContextAccountingOc } from "..";
import GridLaoding from "components/individual/GridLoading";
import { MONTH_LIST } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import MoreInfo from "../MoreInfo";
import style from '../styles.module.scss';

const Header = () => (
  <div className={table.header}>
    <p>Mes</p>
    <p>Subtotal MXN</p>
    <p>Subtotal USD</p>
    <p>TC</p>
    <p>Total MXN</p>
    <p>Contabilizado</p>
  </div>
);

export default function Table() {
  const hook = useContext(ContextAccountingOc);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <div className="d-flex justify-content-center">
        <div className={table.table}>
          <Header />
          <div className={table.content}>
            <GridLaoding justOneRow={false} />;
          </div>
        </div>
      </div>
    );

  if (!hook.isLoading && hook.ocs.length <= 0)
    return (
      <h1 className="text-center my-5">
        No hay ordenes de compras con los filtros solicitados
      </h1>
    );

  return (
    <div className={style.content}>
      <div className={table.table}>
        <Header />

        <div className={table.content}>
          {hook.ocs.map((oc, i) => (
            <div key={`${key.current}-${i}`}>
              <div>
                <input
                  className="d-none"
                  type="radio"
                  name={`${key.current}`}
                  id={`${key.current}-${i}`}
                  onChange={(e) => hook.setOc(oc.id)}
                  disabled={typeof oc.id !== "number"}
                />

                <label htmlFor={`${key.current}-${i}`} className={table.row}>
                  {typeof oc.id === "number" ? (
                    <MoreInfo
                      onUpdated={() => hook.search()}
                      onClick={() => hook.setOc(oc.id)}
                      month={oc.recordMonth}
                      year={hook.year}
                    >
                      <p className={table.moreInfo}>
                        {MONTH_LIST[oc.recordMonth - 1].name}
                      </p>
                    </MoreInfo>
                  ) : (
                    <p className="text-center m-0 text-left d-flex justify-content-start align-items-center">
                      {MONTH_LIST[oc.recordMonth - 1].name}
                    </p>
                  )}

                  <p className="text-right m-0">{mnCurrency(oc.mxnTotal)}</p>
                  <p className="text-right m-0">{mnCurrency(oc.usdTotal)}</p>
                  <p className="text-right m-0">{mnCurrency(oc.tc)}</p>
                  <p className="text-right m-0">{mnCurrency(oc.total)}</p>
                  {typeof oc.id === "number" ? (
                    <label
                      className="d-flex justify-content-center align-items-center"
                      htmlFor={`${key.current}-${i}-checkbox`}
                    >
                      <input
                        checked={hook.grid.operation[oc.id]}
                        onChange={(e) => hook.toggleAccounting(oc.id)}
                        type="checkbox"
                        id={`${key.current}-${i}-checkbox`}
                        disabled={typeof oc.id !== "number"}
                      />
                    </label>
                  ) : (
                    <p className="text-center m-0">-</p>
                  )}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
