import React, { useContext } from "react";
import { ContextSatCode } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function Footer() {
  const hook = useContext(ContextSatCode);

  if (hook.itemSelected === undefined) return null;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <p className="m-0">
        {hook.itemSelected.Value} - {hook.itemSelected.Name}
      </p>
      <ButtonV2
        type="button"
        onClick={() => {
          hook.handleOpenModal(false);
        }}
      >
        <span>Confirmar seleccion</span>
        <Icon icon="save" />
      </ButtonV2>
    </div>
  );
}
