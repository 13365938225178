import ModalV2 from "components/individual/Modals/ModalV2";
import { Spinner } from "components/individual/Spinner/Spinner";
import { getBankAccount } from "helpers/Apis/Banks";
import { mnCurrency } from "helpers/money";
import CxpOverview from "pages/Banks/CxpOverview";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConceptOverview } from "structure/ConceptOverview";
import { ConcilationCxC } from "structure/Concilation/Cxc";
import EgressOverview from "../EgressOverview";
import { MovementInformation } from "../MovementInformation";

const initialState = {
  isOpen: false,
  bank: null,
  isLoading: true,
};

/**
 * Render the overview of the movement
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} props.movement - Movement information
 * @returns {JSX.Element}
 */
export default function MovementOverview({ movement }) {
  const [state, setState] = useState(initialState);

  const { account } = useParams();

  useEffect(() => {
    (async function () {
      if (state.isOpen === false) return;

      const [bank] = await Promise.all([getBankAccount(+account)]);

      setState({
        ...state,
        bank,
        isLoading: false,
      });
    })();
  }, [state.isOpen]);

  const Overview = {
    // Para las asociaciones de CxC (facturas emitidas)
    // 1: <MovementInformation infoRow={movement} idBankAccount={+account} />,
    1: (
      <ConcilationCxC
        idMovement={movement?.Movimiento}
        idBankAccount={+account}
      />
    ),

    // Para los conceptos
    2: <ConceptOverview idMovement={movement?.Movimiento} />,

    // Para las CxP (Ordenes de compra)
    3: (
      <CxpOverview
        idMovement={movement?.Movimiento}
        movementCurrency={state.bank?.currencyCode}
      />
    ),

    // Para los egresos (Deducibles y no deducibles)
    4: (
      <EgressOverview
        idMovement={movement?.Movimiento}
        idBankAccount={+account}
      />
    ),
  };

  // if (state.isLoading) return <Spinner hidden={false} text="Cargando..." />;

  return (
    <ModalV2
      aditionalClasses="d-none"
      idOpenModal="overviewMovement"
      showCancelButton={false}
      width={90}
      title={
        state.isLoading
          ? "Cargando..."
          : `${movement["Tipo_Movimiento"]} ${movement.Movimiento} | ${
              state.bank.currencyCode
            } | Importe ${movement.importe.text} | Saldo ${mnCurrency(
              movement.saldo
            )}`
      }
      cbReject={() => setState(initialState)}
      cbOpen={() =>
        setState({
          ...state,
          isOpen: true,
        })
      }
    >
      {state.isOpen && movement !== null ? (
        movement.typeAssociation.id === 0 ? (
          <>
            <h1 className="py-5 text-center">
              No se ha asociado el {movement["Tipo_Movimiento"].toLowerCase()}
            </h1>
          </>
        ) : (
          Overview[movement.typeAssociation.id] || <h1 className="text-center py-5">No hay asociaciones</h1>
        )
      ) : null}
    </ModalV2>
  );
}
