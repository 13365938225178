import CommentsDocument from "components/general/CommentsDocument";
import HeaderDocument from "components/general/HeaderDocument";
import { Tabs } from "components/general/Tabs/Tabs";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { ContractContext } from "customHooks/useContract/ContractContext";
import useContract from "customHooks/useContract/ContractState";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import TabContract from "structure/Contract/TabContract";
import Partidas from "structure/Partidas";
import ButtonUpdateContract from "./Button";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import ui from "structure/ODC/styles.module.scss";

const { Provider } = ContractContext;

export default function UpdateContract() {
  /**
   * Query params of the component
   * @type {import("customHooks/useContract/types").QueryParamsI}
   */
  const { documento, idCliente, modulo } = useQueryParams();
  const useContractValues = useContract({
    idContract: +documento,
    module: modulo,
    idCliente: +idCliente,
  });

  return (
    <Provider value={{ ...useContractValues, module: modulo }}>
      <div className={ui.container}>
        <BreadCrumV2 path={useContractValues.breadcrumPath} />

        <AddDocument
          actionType="Editar"
          customerId={+idCliente}
          documentType={6}
          documentId={+documento}
        />
      </div>
      {/* <div className="container">
        <header className="d-flex align-items-center justify-content-between my-3">
          <HeaderDocument
            currency={useContractValues.currency}
            socialReason={useContractValues.customerSocialReason}
          />
          <ButtonUpdateContract />
        </header>

        <Tabs
          idContainerContent={"documentInfo"}
          idContainerTabs={"tabs"}
          tabButtons={{
            document: {
              text: "Contrato",
              selected: true,
              component: <TabContract isEditing={true}/>,
            },
            items: {
              text: "Partidas",
              selected: false,
              component: (
                <Partidas
                  currency={useContractValues.currency}
                  tc={useContractValues.tc}
                  typeDocument="contrato"
                  onChange={useContractValues.updateItems}
                  idDocument={+documento}
                />
              ),
            },
            comments: {
              text: "Notas y consideraciones",
              selected: false,
              component: (
                <CommentsDocument
                  includeCc={false}
                  idTypeDocument={6}
                  onChange={useContractValues.updateComments}
                  idDocument={+documento}
                />
              ),
            },
          }}
        />
      </div> */}
    </Provider>
  );
}
