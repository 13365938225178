import React from "react";
import ContactField from "./ContactField";
import SocialReasonField from "./SocialReasonField";
import DocNumber from "./DocNumber";

export const InfoSection = () => {
  return (
    <>
      <DocNumber/>
      <SocialReasonField />
      <ContactField />
    </>
  );
};
