import React from "react";
import useMovements from "./useMovements";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import BankAccountSelect from "structure/BankAccountReport/Select";
import { RangeDate, From, To } from "components/general/RangeDate";
import style from "./styles.module.scss";
import { createContext } from "react";
import Table from "./Table";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import useQueryParams from "customHooks/useQueryParams";
import EmailComplements from "components/individual/EmailComplement";

/**
 * @type {import("react").Context<undefined|import("./useMovements/types").ReturnUseMovements>}
 */
export const ContextMovements = createContext(undefined);

export default function Movements() {

  const { cuenta } = useQueryParams();

  const hook = useMovements(+cuenta);

  return (
    <ContextMovements.Provider value={hook}>
      <BreadCrumV2 path={hook.breadcrum} />

      <div className="container">
        <div className={style.filter}>
          <BankAccountSelect
            allowExchangeAccount={false}
            bankLabel={`${hook.bankAccount?.account} - ${hook.bankAccount?.bank.shortName}`}
            onChange={hook.setBankAccount}
          />

          <RangeDate>
            <From
              onChange={(date) => hook.setFrom(date)}
              minDate={hook.minDate}
              selected={hook.from}
            />
            <To
              onChange={(date) => hook.setTo(date)}
              selected={hook.to}
              maxDate={new Date()}
            />
          </RangeDate>

          <ButtonV2 disabled={!hook.isValid} onClick={hook.attemptSearch}>
            Buscar
          </ButtonV2>

          {hook.isGeneratingXlsx ? (
            <Spinner text={"Descargando"} hidden={false} />
          ) : (
            <ButtonV2
              onClick={hook.downloadExcel}
            >
              Excel
            </ButtonV2>
          )}

          {hook.isGeneratingPdf ? (
            <Spinner text={"Descargando"} hidden={false} />
          ) : (
            <ButtonV2
              onClick={hook.downloadPdf}
            >
              PDF
            </ButtonV2>
          )}

          

        </div>

        <Table />
      </div>
    </ContextMovements.Provider>
  );
}
