import React from "react";
import { createContext } from "react";
import TableReportCxp from "./Table";
import useCxpReport, { CONTEXT_CXC_CXP_REPORT } from "./useCxpReport";
import Search from "./Search";
import style from "./styles.module.scss";
import RangeDate from "./RangeDate";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import CxcAndCxpExcel from "structure/CxcAndCxpExcel";
import FilterMobile from "./FilterMobile";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

/**
 * @type {import("react").Context<import("./useCxpReport/types").ContextReportCxcAndCxp>}
 */
export const ContextCxpReport = createContext(CONTEXT_CXC_CXP_REPORT);

/**
 * @param {import("./types").PropsReportCxpCxp} props - Props
 * @returns {JSX.Element}
 */
export default function CxP({ type = "cxp" }) {
  const hook = useCxpReport(type);

  return (
    <ContextCxpReport.Provider value={{ ...hook, type }}>
      <div className={style.header}>
        <BreadCrumV2 path={hook.breadcrum} />
        <div className={style.filterSearch}>
          <RangeDate />
          <Search />
          <ButtonV2
            disabled={!hook.isValidDate}
            onClick={() => hook.refetchTable()}
          >
            <span>Buscar</span>
            <Icon icon="search" />
          </ButtonV2>
          <CxcAndCxpExcel
            from={hook.from}
            to={hook.to}
            type={type}
            socialReason={hook.search}
          />
        </div>

        <div className={style.content}>
          <FilterMobile />
        </div>
      </div>

      <div className={style.content}>
        <TableReportCxp />
      </div>
    </ContextCxpReport.Provider>
  );
}
