import { getYearRange } from "helpers/dates";
import { Actions } from "./actions";

/**
 * Information in order to render the table of odc's
 * @type {import("customHooks/useContract/types").InitialStateI}
 */
export const initialState = {
  page: 1,
  isUpdatingCxp:false,
  isSendableEmail:false,
  totalPages: undefined,
  grid: {
    original: {},
    operation: {},
  },
  search: null,
  customerLabel:"Todos",
  customer: null,
  date: {
    start: getYearRange().begin.js,
    end: getYearRange().end.js,
  },
  status: null,
  rows: [],
  isFetchingData: true,
  rowSelected: null,
  isEditable:false,
  refetch: false,
  isDownloadingExcel:false
};

/**
 *
 * @param {import("./types").StateI} state - State of the component that renders the invoices
 * @param {import("./types").Action} action - Action that can be executed on the reducer
 */
export function reducer(state, action) {
  switch (action.type) {
    case Actions.SET_IS_FETCHING_DATA: {
      return {
        ...state,
        isFetchingData: action.payload,
      };
    }

    case Actions.SET_IS_UPDATING_CXP:{
      return{
        ...state,
        isUpdatingCxp:action.payload
      }
    }

    case Actions.SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case Actions.SET_EXCEL_DOWNLOADING:{
      return {
        ...state,
        isDownloadingExcel:action.payload
      }
    }

    case Actions.TRIGGER_SEARCH: {
      return {
        ...state,
        refetch: !state.refetch,
        page:1
      };
    }

    case Actions.SET_NO_DOCUMENT: {

      return {
        ...state,
        search: action.payload,
      };
    }

    case Actions.SET_ROW: {

      const isSendableEmail = action.payload === null ? false : (action.payload.status.id === 10||action.payload.status.id===11) && action.payload.generateCXP === 1 ? true : false;

      return {
        ...state,
        rowSelected: action.payload,
        isEditable:action.payload?.status?.id === 10 || action.payload?.status?.id === 11 ? true : false,
        isSendableEmail
      };
    }

    case Actions.SET_DATES: {
      return {
        ...state,
        date: {
          start: action.payload[0],
          end: action.payload[1],
        },
      };
    }

    case Actions.TOGGLE_ACCOUNTING:{
      return {
        ...state,
        grid:{
          ...state.grid,
          operation:{
            ...state.grid.operation,
            [action.payload]:!state.grid.operation[action.payload]
          }
        }
      }
    }

    case Actions.SET_SEARCH_RESULTS: {

      const indexed = action.payload.rows.reduce((indexed,oc)=>({
        ...indexed,
        [oc.id]:oc.isAccounted
      }),{});

      return {
        ...state,
        rows: action.payload.rows,
        page: action.payload.actualPage,
        totalPages: action.payload.totalPages,
        grid:{
          original:indexed,
          operation:indexed
        }
      };
    }

    case Actions.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case Actions.SET_SOCIAL_REASON: {
      return {
        ...state,
        customer: action.payload.id,
        customerLabel:action.payload.socialReason
      };
    }

    default:
      return state;
  }
}
