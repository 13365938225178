import React from "react";
import { useContext } from "react";
import { ContextMoreInfoMovementV2 } from "..";
import MoreInfo from "pages/BanksV2/Egresses/Table/MoreInfo";
import Cancel, {
  GridConcepts,
  GridInvoices,
} from "pages/BanksV2/Egresses/Cancel";
import PerformCancel from "pages/BanksV2/Egresses/Cancel/PerformCancel";
import style from "../Incoming/styles.module.scss";

export default function Egress() {
  const hook = useContext(ContextMoreInfoMovementV2);

  if (hook.movement === undefined || hook.movement === null) return <></>;

  if (hook.movement.type.description !== "Egreso") return <></>;

  if (hook.isForCancel)
    return (
      <Cancel movement={hook.movement} onSuccess={hook.onCancelated}>
        <div className={style.containerIncomingOverview}>
          <GridConcepts />
          <GridInvoices />
          <PerformCancel />
        </div>
      </Cancel>
    );

  return <MoreInfo movement={hook.movement} />;
}
