/**
 * Get the information of the contact when it's selected from the combo
 * @param {object} contact - Information of the contact selected on the combo
 * @returns {object}
 */
export function getInfoContact(contact) {
  if (contact === null) {
    return {
      id: null,
      data: {
        id: null,
      },
    };
  } else {
    return {
      id: contact.contactID,
      data: {
        ...contact,
        id: contact.contactID,
      },
    };
  }
}
