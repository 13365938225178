import React from "react";
import { useContext } from "react";
import { ContextEmitCreditNote } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import { mnCurrency } from "helpers/money";

const Container = ({ children }) => (
  <div className={style.footerContainer}>{children}</div>
);

export default function CreateButton() {
  const hook = useContext(ContextEmitCreditNote);

  if (hook.invoice === undefined)
    return (
      <p className="my-0 text-danger font-weight-bold">
        🔴 Selecciona una factura
      </p>
    );
  if (hook.importe <= 0)
    return (
      <p className="my-0 text-danger font-weight-bold">
        🔴 El importe no puede ser menor o igual $0.00
      </p>
    );

  if (hook.concept === "")
    return (
      <p className="my-0 text-danger font-weight-bold">
        🔴 Escribe un concepto
      </p>
    );

  if (hook.total > hook.invoice.residue)
    return (
      <p className="my-0 text-danger font-weight-bold">
        🔴 El total de la nota de crédito no puede ser mayor al saldo de la
        factura el cual es de {mnCurrency(hook.invoice.residue)}{" "}
        {hook.invoice.currency}
      </p>
    );

  if (hook.isCreatingCreditNote)
    return (
      <Container>
        <Spinner
          idSpinner="creatingCreditNote"
          hidden={false}
          text={<LoadingText>Creando nota de crédito</LoadingText>}
        />
      </Container>
    );

  return (
    <Container>
      <ButtonV2 onClick={(e) => hook.attemptCreateCreditNote()} type="button">
        Crear nota de crédito
      </ButtonV2>

      {hook.total === hook.invoice.residue ? (
        <p className={style.warningCreditNote}>
          No se recomienda una nota de crédito por el 100% de la factura
        </p>
      ) : null}
    </Container>
  );
}
