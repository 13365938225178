import {
  addBankAccount,
  getAccount,
  updateBankAccount,
} from "helpers/Apis/Banks/index";
import { dateToDbFormat, getLastDayOfPreviousMonth } from "helpers/dates";
import { useState, useEffect } from "react";

/**
 * @type {import("./types").StateBankAccount}
 */
const INITIAL_STATE = {
  account: {
    closingBalanceDate: dateToDbFormat(getLastDayOfPreviousMonth()),
  },
  isLoading: true,
  isValidCLABE: true,
  isFetchingServer: false,
};

/**
 * Handle the creation, edit and query of a bank account
 * @param {number?} id - Id of the bank account
 * @param {()=>void} onSuccess - Callback executed when an update or create was made
 * @returns {import("./types").ReturnBankAccountI}
 */
export const useBankAccount = (id = null, onSuccess = () => {}) => {
  const [bank, setBank] = useState(INITIAL_STATE);

  /**
   * Update cuenta
   * @param {string} account - Account
   * @returns {void}
   */
  const updateCuenta = (account) =>
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        account,
      },
    }));

  const keys = bank.account === null ? [] : Object.keys(bank.account);

  const isValidAccount = keys.includes("bank");

  /**
   * Update the CLABE
   * @param {string} CLABE - CLABE
   * @param {boolean} isValidCLABE - Global validation
   * @param {string} bankCode - Bank code
   */
  const updateCLABE = (CLABE, isValidCLABE, bankCode) => {
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        CLABE,
      },
      isValidCLABE: isValidCLABE,
    }));
  };

  const updateCurrency = (currency) =>
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        currency,
      },
    }));

  const updateImport = (closingBalance) =>
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        closingBalance,
        currentBalance: closingBalance,
      },
    }));

  const updateSocialReason = (socialReason, id, shortName, clave) =>
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        bank: {
          id,
          clave,
          socialReason,
          shortName,
        },
      },
    }));

  /**
   * Update the closing date for the bank account
   * @param {Date} date
   */
  const updateDate = (date) => {
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        closingBalanceDate: dateToDbFormat(date),
      },
    }));
  };

  const updateAmount = (balance) =>
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        closingBalance: balance,
        currentBalance: balance,
      },
    }));

  const setdescription = (description) => {
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        description: description,
      },
    }));
  };

  const setcomment = (comment) => {
    setBank((state) => ({
      ...state,
      account: {
        ...state.account,
        comments: comment,
      },
    }));
  };

  const isValidCurrency = keys.includes("currency");

  const isValidclosingBalanceDate = keys.includes("closingBalanceDate");

  const isValidclosingBalance = keys.includes("closingBalance");

  const isValidDate = keys.includes("closingBalanceDate");

  const isValidCuenta = () => {
    const isKey = keys.includes("account");

    if (!isKey) return false;

    if (bank.account.account.length === 10) return true;

    return false;
  };

  /**
   * Show a spinner
   * @param {boolean} isFetchingServer - Flag to know if the modal is performing an update or create
   * @returns {void}
   */
  const setIsFetchingServer = (isFetchingServer) =>
    setBank((state) => ({
      ...state,
      isFetchingServer,
    }));

  const isValidForm =
    bank.account === null
      ? false
      : isValidAccount &&
        bank.isValidCLABE &&
        isValidCurrency &&
        // isValidclosingBalance &&
        isValidclosingBalanceDate &&
        isValidDate;

  /**
   *
   * @param {import("./types").ValidationsAddBankAccount} data - Data validated by react-hook-form
   *
   * ---
   *
   * Check the schema validation here: client\src\structure\BankAccount\validations.js
   */
  const attemptAddBankAccount = async (data) => {
    const DTO = {
      idBank: bank.account.bank.id,
      socialReason: bank.account.bank.socialReason,
      account: data.account,
      CLABE: bank.account.CLABE,
      currency: bank.account.currency,
      lastCloseConcilation: bank.account.closingBalanceDate,
      lastCloseBalanceConcilation: data.lastCloseBalanceConcilation,
      description: bank.account.description,
      comment: bank.account.comments,
      nextBankCheckNumber: data.nextBankCheckNumber,
      nextTransferenceNumber: data.nextTransferenceNumber,
    };

    setIsFetchingServer(true);

    const bankAccountCreated = await addBankAccount(DTO);

    setIsFetchingServer(false);

    if (bankAccountCreated !== null) {
      onSuccess();
    }
  };

  const setIsLoading = (isLoading = false) =>
    setBank((state) => ({
      ...state,
      isLoading,
    }));

  useEffect(() => {
    (async function () {
      if (id === null) {
        setIsLoading(false);
        return;
      }

      const apiAccount = await getAccount(id);
      setBank((state) => ({ ...state, isLoading: false, account: apiAccount }));
    })();
  }, [id]);

  const attemptUpdateBankAccount = async (data) => {
    setIsFetchingServer(true);

    const bankInfo = await updateBankAccount({
      CLABE: bank.account.CLABE,
      comment: bank.account.comments,
      description: bank.account.description,
      id,
      status: data.status,
    });

    if (bankInfo !== null) {
      onSuccess();
    }

    setIsFetchingServer(false);
  };

  return {
    ...bank,
    updateCLABE,
    updateCurrency,
    updateSocialReason,
    updateImport,
    updateDate,
    updateAmount,
    setdescription,
    setcomment,
    isValidAccount,
    isValidCLABE: bank.isValidCLABE,
    isValidCurrency,
    isValidclosingBalance,
    isValidclosingBalanceDate,
    isValidForm,
    isValidDate,
    attemptAddBankAccount,
    updateCuenta,
    isValidCuenta: isValidCuenta(),
    attemptUpdateBankAccount,
  };
};
