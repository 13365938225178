import { RedirectButton } from "components/individual/buttons/Buttons";
import React from "react";
import { ContextReceiveCredtNotes } from "../..";
import { useContext } from "react";

export default function LinkAssociatedFiles() {
  const hook = useContext(ContextReceiveCredtNotes);

  return (
    <RedirectButton
      text="Archivos asociados"
      link={`/compras/notas-de-credito/archivos-asociados/${hook.creditNote.id}`}
    />
  );
}
