import React, { useContext } from "react";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ContextContract } from "../List";
import scss from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import ExecutiveSelect from "./ExecutiveSelect";
import SocialReasonSelect from "./SocialReasonSelect";
import ContractInput from "./ContractInput";
import RangeInput from "./RangeInput";
import UenSelect from "./UenSelect";
import StatusSelect from "./StatusSelect";

export default function Filter() {
  const {
    triggerSearch,
    addContract,
    attemptDownloadExcel,
    isDownloadingExcel,
  } = useContext(ContextContract);

  return (
    <div className={scss.filter}>
      <ExecutiveSelect />
      <SocialReasonSelect />
      <StatusSelect />
      <ContractInput />
      <UenSelect />
      <RangeInput />
      <ButtonV2 onClick={triggerSearch}>
        <span>Buscar</span>
        <Icon icon="search" />
      </ButtonV2>

      <ButtonV2 disabled={isDownloadingExcel} onClick={attemptDownloadExcel}>
        <span>{isDownloadingExcel ? "Descargando" : "Excel"}</span>
        <Icon icon="excel" />
      </ButtonV2>

      <ButtonV2 onClick={addContract}>
        <span>Agregar</span>
        <Icon icon="plus" />
      </ButtonV2>
    </div>
  );
}
