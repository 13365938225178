import { InputText } from "components/individual/inputs/Inputs";
import LoginContext from "context/Login/LoginContext";
import React, { useContext } from "react";
import { Label } from "structure/Document";

export default function Name({
  value,
  required = false,
  onChange = () => {},
  label,
  max = null,
}) {
  const isValidInput = !required ? false : value.length <= 0 ? true : false;

  const { userInfo } = useContext(LoginContext);

  if(userInfo[0].isAdmin) return <div className="mb-3">
    <Label>{label}</Label>
    <p>{value}</p>
  </div>

  return (
    <div>
      <div className="d-flex align-items-center">
        <Label required={isValidInput}>{label}</Label>
        {max === null ? null : (
          <span>
            {value.length}/{max}
          </span>
        )}
      </div>
      <InputText
        maxLength = { max }
        defaultValue={max === null ? value : value.substring(0,max)}
        onChange={value=>onChange(max === null ? value : value.substring(0,max))}
        placeholderTxt="Escribe aquí"
      />
      {isValidInput ? <p className="text-danger">Obligatorio</p> : null}
    </div>
  );
}
