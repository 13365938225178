import ButtonV2 from "components/individual/ButtonsV2/Button";
import InformativeTooltip from "components/individual/InfoTooltip";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { ContextDisassociateConcept } from "..";

export const Disassociate = () => {
  const { isDisassociating, attemptDisassociate } = useContext(
    ContextDisassociateConcept
  );

  if (isDisassociating)
    return <Spinner hidden={false} text="Des-asociando concepto..." />;

  return (
    <>
      <hr />
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <InformativeTooltip>
            <p>
              Debido a que solo se puede asociar un concepto por ingreso, la
              des-asociacion solo muestra un registro en la pantalla y devuelve
              el monto completo al movimiento.
            </p>
          </InformativeTooltip>
          <a
            target={"_blank"}
            href="https://scribehow.com/shared/Des-asociar_un_concepto_de_ingreso__72T2Jw2dQfKd9qbJQ9pDqA"
          >
            ¿Cómo des-asociar un concepto?
          </a>
        </div>

        <ButtonV2 onClick={attemptDisassociate}>Des-asociar</ButtonV2>
      </div>
    </>
  );
};
