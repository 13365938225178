import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import style from "../../styles.module.scss";
import { Context2fa } from "../..";
import { useContext } from "react";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export default function Footer() {
  const hook = useContext(Context2fa);

  if (hook.isPerformingAbc)
    return (
      <div className={style.cancelFooter}>
        <p></p>
        <Spinner
          text={<LoadingText>Des-activando 2FA</LoadingText>}
          hidden={false}
        />
      </div>
    );

  return (
    <div className={style.cancelFooter}>
      <p>❗ No se recomienda des-activar 2FA</p>
      <ButtonV2 onClick={hook.attemptCancel2fa}>Des-activar 2FA</ButtonV2>
    </div>
  );
}
