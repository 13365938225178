import React from "react";

import { useContext } from "react";
import { ConcilationContext } from "..";
import { Label } from "structure/Document";
import { MONTHS, parseDateToText } from "helpers/dates";

export const FilterV2 = () => {
  const concilation = useContext(ConcilationContext);

  return (
    <p className="d-inline-flex align-items-center">
      <Label>Mes</Label>
      <span>
        {MONTHS[concilation.dateToConciliate.getMonth()]}/
        {concilation.dateToConciliate.getFullYear()}
      </span>
    </p>
  );
};
