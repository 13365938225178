import React, { useContext } from "react";
import { ContextOldResidues } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function Excel() {
  const hook = useContext(ContextOldResidues);

  return hook.isDownloadingXlsx ? (
    <Spinner text={"Descargando"} hidden={false} />
  ) : (
    <ButtonV2 onClick={hook.downloadXlsx} type="submit">
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
