import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Wifi() {
  const [isNetworkConnection, setIsNetworkConnection] = useState(true);
  const [counter, setCounter] = useState(0);

  window.addEventListener("offline", () => setIsNetworkConnection(false));
  window.addEventListener("online", () => setIsNetworkConnection(true));

  useEffect(() => {
    const isWifiConnection = window.navigator.onLine;

    if (!isWifiConnection) {
      toast.error("Asegurate de tener conexion a internet", {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        draggable: true,
      });
    } else {
      if (counter === 0) {
        return;
      }

      toast.success("Conexion a internet recuperada", {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        draggable: true,
      });
    }

    setCounter(counter + 1);
  }, [isNetworkConnection]);

  return (
    <ToastContainer position="top-center" autoClose={false} closeOnClick />
  );
}
