import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useViewDoc from "../../../../customHooks/useViewDoc";
import { GetDocument } from "../../../../helpers/Apis/Documents";
import ui from "structure/ODC/styles.module.scss";
import useQueryParams from "customHooks/useQueryParams";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { AddDocument } from "components/Sections/Documents/AddDocument";

export function ViewDoc() {
  const { idDocument, idCustomer } = useParams();
  /**
   * @type {{modulo:(undefined|"odc"|"pedidos"|"contratos"|"movimientos")}}
   */
  const { modulo } = useQueryParams();

  const [documents, setDocuments] = useState({
    1: null,
    2: null,
    3: null,
    6: null,
    9: null,
  });

  const { breadcrumPath } = useViewDoc({
    module: modulo,
  });

  const quoteBreadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${idCustomer}`,
      text: "Documentos",
    },
    {
      route: "/directorio/documentos",
      text: "Cotización",
    },
  ]);

  useEffect(() => {
    (async function () {
      const apiData = await Promise.all([GetDocument(idDocument)]);

      setDocuments({
        ...documents,
        idTypeDocument: apiData[0].type.id,
        [apiData[0].type.id]: {
          info: apiData[0],
        },
      });
    })();
  }, []);

  return (
    <div className={ui.container}>
      {modulo !== undefined ? (
        <BreadCrumV2 path={breadcrumPath} />
      ) : (
        <BreadCrumV2 path={quoteBreadcrum.current} />
      )}

      {document === null ? null : (
        <AddDocument
          actionType="Ver"
          customerId={idCustomer}
          documentType={documents.idTypeDocument}
          documentId={idDocument}
        />
      )}
    </div>
  );
}
