import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { ContextCashFlow } from "..";
import { useContext } from "react";
import Icon from "components/individual/HeaderIcon";

export default function SearchCashFlow() {
  const hook = useContext(ContextCashFlow);

  return (
    <ButtonV2
      onClick={hook.research}
      disabled={!hook.areValidFilters}
    >
      <span>Buscar</span>
      <Icon icon="search"/>
    </ButtonV2>
  );
}
