import { Select } from "components/individual/inputs/Inputs";
import React, { useState } from "react";
import { LabelPartialitie } from "./styles";

/**
 * Render the partialities combo
 * @param {object} props - Props
 * @param {string} props.label - Label for the combo
 * @param {number?} props.value - Value selected when combo renders
 * @param {()=>void} props.onChange - Callback executed with the partialities selected on the combo
 * @returns {JSX.Element} Render the partialities combo
 */
export default function Partialities({
  label = "Parcialidades",
  value = 1,
  onChange = () => {},
}) {
  const [partialities, setPartialities] = useState(value);

  const [options, setOptions] = useState([
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
  ]);

  /**
   * Update the partialities to use on the invoice
   * @param {number} partialities - Partialities
   * @returns {void}
   */
  const handleOnChange = (partialities) => {
    setPartialities(partialities);
    onChange(partialities);
  };

  return (
    <div>
      <LabelPartialitie htmlFor="">{label}</LabelPartialitie>

      <Select
        addTodos={false}
        options={options}
        valueJSON="value"
        nameSelect="partialities"
        idSelect="partialities"
        textJSON="label"
        value={partialities}
        onChange={(partialitie) => handleOnChange(+partialitie)}
      />
    </div>
  );
}
