import React, { useContext, JSXElementConstructor } from "react";
import { Button as ButtonStyled } from "../ButtonsV2/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import TableContext from "../../../context/Table/TableContext";
import { rowsPerPage } from "../../../static/TableConfig";
import { v4 as uuidv4 } from "uuid";
import ButtonV2 from "../ButtonsV2/Button";

/**
 * Generic button
 *
 * @param {object} props - Props of the component
 * @param {string} props.text - Text of the button
 * @param {string} props.aditionalClasses - Extra CSS of the button
 * @param {function} props.funcion - Callback after clicking the button
 * @param {string} [props.id=""] - HTML id of the button
 * @param {any} [props.icon=null] - HTML icon
 * @returns {JSX.Element} JSX Element
 */
export function Button({
  text,
  aditionalClasses,
  funcion = () => {},
  id = "",
  icon = null,
}) {
  return (
    <ButtonStyled
      theme="default"
      id={id}
      className={`${aditionalClasses}`}
      onClick={async (e) => {
        e.preventDefault();
        funcion(e);
      }}
    >
      {icon}
      {text}
    </ButtonStyled>
  );
}

/**
 *
 * @param {object} props - Props
 * @param {()=>void} [props.onClick=()=>{}] - When link is clicked
 * @param {string|JSX.Element} [props.text="????"] - Text to display
 * @param {string} [props.css="na"] - Class name
 * @param {string} [props.id=null] - id attribute
 * @param {string} [props.link="/"] - url to redirrect
 * @returns {JSX.Element}
 */
export function RedirectButton({
  text = "????",
  css = "na",
  // id = uuidv4(),
  id = null,
  link = "/",
  onClick = () => {},
}) {
  return (
    <Link onClick={() => onClick()} to={link}>
      <ButtonV2 className={`${css}`}>
        {text}
      </ButtonV2>
    </Link>
  );
}

export function GoBack({ aditionalClasses = "", text, cb }) {
  return (
    <div onClick={cb} className={`cursorPointer go-back ${aditionalClasses}`}>
      <div
        className="d-flex align-items-center 
    font-weight-bold text-center"
      >
        <FontAwesomeIcon
          className="mr-2"
          size="2x"
          icon={faArrowAltCircleLeft}
        />
        {text}
      </div>
    </div>
  );
}

/**
 *
 * @param {object} props - Props of the component
 * @param {string} [props.aditionalClasses=''] - CSS classes to add to the button submit
 * @param {string} [props.text="Guardar"] - Text of the button submit
 * @param {string} [props.id=""] - HTML id of the submit
 * @param {string} [props.idForm=null] -The Form target id to submit
 * @param {()=>void} [props.cb=()=>{}] - Function to execute `onClick`
 * @returns {JSX.Element} JSX Element
 */
export function Submit({
  aditionalClasses = "",
  text = "Guardar",
  id = "",
  cb = () => {},
  idForm = null,
}) {
  return (
    <ButtonV2
      onClick={cb}
      type="submit"
      form={idForm}
      id={id !== "" ? `${id}` : null}
    >
      {text}
    </ButtonV2>

  );
}

/**
 *
 * @param {object} props - Props of the component
 * @param {string} props.aditionalClasses - CSS of the button element
 * @param {string} props.text - Text of the button
 * @param {string} props.idForm - HTML id of the button
 * @param {function} props.search - Callback to be executed after clicking the button
 */
export function SearchButton({
  aditionalClasses,
  text,
  search,
  idForm,
  id = "",
}) {
  return (
    <button
      className={`button ${aditionalClasses}`}
      type="submit"
      onClick={search}
      form={idForm}
      id={id}
    >
      {text}
    </button>
  );
}
export function ExportExcelButton({ aditionalClasses, text, search, idForm }) {
  return (
    <button
      className={`button ${aditionalClasses}`}
      type="submit"
      onClick={search}
      form={idForm}
    >
      {text}
    </button>
  );
}
export function SubmitTest({
  aditionalClasses,
  text,
  formID,
  onClickEvent,
  id = uuidv4(),
}) {
  return (
    <button
      className={`${aditionalClasses} button`}
      type="submit"
      form={formID}
      id={id}
      onClick={onClickEvent}
    >
      {text}
    </button>
  );
}

/**
 *
 * @param {object} props - Props of the component
 * @param {string} props.aditionalClasses - Classes of the button on the html
 * @param {string} props.target - Modal to be referenced
 * @param {string} props.text - Text to display on the button
 * @param {string} props.id - HTML id of the button
 * @param {function} props.callback - Expressive function to be executed after clicking the button
 * @returns {JSXElementConstructor} JSX Element
 */
export function ModalButton({
  aditionalClasses = "",
  target,
  text,
  id,
  callback = () => {},
}) {
  return (
    <ButtonV2
      id={id}
      className={`button ${aditionalClasses}`}
      data-toggle="modal"
      data-target={`#${target}`}
      type="button"
      onClick={callback}
    >
      {text}
    </ButtonV2>
  );
}

/**
 * Button to close a modal of bootstrap
 *
 * @param {object} props - Props of the component
 * @param {string} props.aditionalClasses - CSS of the button
 * @param {string} props.text - Text of the button
 * @param {string} props.idBtn - HTML id of the button
 * @param {funcion} props.callback - Callback after close the modal
 * @returns {JSXElementConstructor} JSX Element
 */
export function ModalDismissButton({
  aditionalClasses,
  text,
  idBtn,
  modalTarget = "modal",
  callback = () => {},
}) {
  return (
    <button
      id={idBtn}
      className={`dismissModalButton ${aditionalClasses}`}
      data-dismiss={modalTarget}
      type="button"
      onClick={callback}
    >
      {text}
    </button>
  );
}

export function NavNormalButton({ text, direction }) {
  return (
    <li className="nav-item">
      <a href={direction} className="nav-link header-menu-link">
        {text}
      </a>
    </li>
  );
}
export function NavHeaderButtom({ text }) {
  return (
    <a href=" " className="nav-link hoveringLink header-menu-link">
      {text}
    </a>
  );
}

export function NavItemButton({ text, direction }) {
  return (
    <Link to={direction}>
      <p className="nav-link header-menu-link">{text}</p>
    </Link>
  );
}

export function MenuItemButton({ icon, text, direction, cb = () => {} }) {
  return (
    <div onClick={() => cb()}>
      <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      <Link to={direction}>
        <p className="menuItemButton">
          <span> </span>
          {text}
        </p>
      </Link>
    </div>
    // <a href={direction} className="menuItemButton">
    //   <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
    //   <span> </span>
    //   {text}
    // </a>
  );
}

export function PageTable({
  actualPage,
  totalPages,
  attributes,
  fetchFunction,
  extraFunctionPagination,
}) {
  const { setActualPage, orderBy, criterianOrder } = useContext(TableContext);

  const nextPage = () => {
    // console.log(`- Avanzando pagina`);

    // console.log(actualPage+1);

    setActualPage(actualPage + 1);

    // console.log(actualPage);
    /* This will fetch something like... 
    fetchUsers(3,3)

    1. The function will fetch startgin the register number 3 from DB
    2. The function will fetch the first "X" registers starting from the first parameter
    */
    fetchFunction(
      actualPage * rowsPerPage,
      rowsPerPage,
      attributes[orderBy],
      criterianOrder
    );
    // fetchFunction();
    extraFunctionPagination();
  };

  const backPage = () => {
    setActualPage(actualPage - 1);
    const page = actualPage - 1;

    /* This will fetch something like... 
    fetchUsers(1,3)

    1. The function will fetch startgin the register number 1 from DB
    2. The function will fetch the first "X" registers starting from the first parameter
    */
    extraFunctionPagination();
    {
      page === 1
        ? fetchFunction(0, rowsPerPage, attributes[orderBy], criterianOrder)
        : fetchFunction(page, rowsPerPage, attributes[orderBy], criterianOrder);
    }
  };

  return (
    <div className="containerPageTable d-flex align-items-center">
      {actualPage === 1 ? null : (
        <span
          onClick={() => backPage()}
          className="pageTableSize ml-2 paginationBtnLeft"
        >
          <FontAwesomeIcon size="lg" className="mr-2" icon={faAngleLeft} />
        </span>
      )}

      <span className="pageTableSize px-4">
        {actualPage} de {totalPages}
      </span>

      {actualPage === totalPages ? null : (
        <span
          onClick={() => nextPage()}
          className="pageTableSize paginationBtnRight"
        >
          <FontAwesomeIcon size="lg" className="ml-2" icon={faAngleRight} />
        </span>
      )}
    </div>
  );
}
