import styled from "styled-components";

export const CommentsContainer = styled.div`
  background: transparent;
  width: 100%;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  flex-direction: column;
  gap: 1rem 0;
  padding:1rem;
`;

export const AddCommentContainer = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction:column;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content:center;
  position: sticky;

  > * {
    width:100%;
  }
`;

export const CommentItem = styled.div`
  background-color: var(--secondaryBackground);
  width: 100%;
  max-height: 131.12px;
  display: flex;
  flex-direction: row;
  max-height: 131.2px;
  padding: 1rem;
  border-radius: 10px;
  overflow: auto;
  align-items: center;
  p {
    margin: 0;
  }
`;
export const Comment = styled.div`
  width: 94%;
  display: flex;
  padding: 0.2rem;
`;

export const ButtonsOptions = styled.div`
  display: flex;
  gap: 0 5px;
`;
