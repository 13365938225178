///////// [PROCESOS ESPECIALES] /////////////

export let specialProcess =
{
    "id_section": 1,

    "description": "menu_procesos_especiales_y_pantallas_acceso",

    "permisions": [
        {
            "permissionID": 0,
            "description": "Avisos",
            "rolID": 0,
            "acceso": false,
            "idButton": 10
        },
        {
            "permissionID": 0,
            "description": "Cuotas Ventas",
            "rolID": 0,
            "acceso": false,
            "idButton": 11
        },

        {
            "permissionID": 0,
            "description": "Facturacion",
            "rolID": 0,
            "acceso": false,
            "idButton": 12
        },

        {
            "permissionID": 0,
            "description": "Lista de distribucion",
            "rolID": 0,
            "acceso": false,
            "idButton": 13
        },
        {
            "permissionID": 0,
            "description": "Alertas",
            "rolID": 0,
            "acceso": false,
            "idButton": 14
        },
        {
            "permissionID": 0,
            "description": "Roles",
            "rolID": 0,
            "acceso": false,
            "idButton": 15
        },
        {
            "permissionID": 16,
            "description": "Usuarios",
            "rolID": "x" /* Acorde al usuario firmado */,
            "acceso": true,
            "idButton": 16
        }
    ]
}