import Select from "components/individual/SelectV4";
import { ContextLogs } from "pages/Log/LogEmail";
import React, { useContext } from "react";
import { Label } from "structure/Document";

export default function TypeFilter() {
  const hook = useContext(ContextLogs);

  return (
    <div>
      <Label>Tipo</Label>
      <Select
        defaultSelected={{
          keyToCompare:"id",
          value:hook.tipo
        }}
        onChange={(data) => hook.setType(data.id)}
        labelToDisplay="label"
        options={async () => [
          {
            id: "todos",
            label: "Todos",
          },
          {
            id: "errores",
            label: "Con errores",
          },
          {
            id: "sinErrores",
            label: "Sin errores",
          },
        ]}
      />
    </div>
  );
}
