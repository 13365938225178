import React, { useContext } from "react";
import { ContextDocuments } from "..";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import WinQuote from "./WinQuote";
import EditDocument from "./EditDocument";
import CancelDocument from "./CancelDocument";
import Pdf from "./Pdf";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import ModalCreateInvoiceV2 from "structure/ModalCreateInvoiceV2";

export default function Options() {
  const hook = useContext(ContextDocuments);

  if (hook.documentSelected === undefined || hook.documentSelected === null)
    return <div className={ui.options}></div>;

  return (
    <div className={ui.options}>
      {hook.canViewDocument ? (
        <ButtonV2 onClick={hook.viewDocument}>Ver documento</ButtonV2>
      ) : null}

      {hook.canAssociateFiles ? (
        <ButtonV2 onClick={hook.associateFiles}>Asociar archivos</ButtonV2>
      ) : null}

      <ButtonV2 onClick={hook.viewToDo}>To Do</ButtonV2>

      <Pdf />

      {hook.canSendEmail ? (
        <EmailDocumentsV2 idDocument={hook.documentSelected.idDocument}>
          <ButtonV2>
            <span>Enviar correo</span>
          </ButtonV2>
        </EmailDocumentsV2>
      ) : null}

      <WinQuote />
      <EditDocument />
      <CancelDocument>
        <ButtonV2>
          {hook.documentSelected.idTypeDocument === 1 ? "Cancelar" : "Terminar"}
        </ButtonV2>
      </CancelDocument>
      <ModalCreateInvoiceV2
        evaluatePermission={true}
        idOrder={hook.documentSelected.idDocument}
        idStatus={hook.documentSelected.idStatus}
      >
        <ButtonV2>
          <span>Timbrar</span>
        </ButtonV2>
      </ModalCreateInvoiceV2>
    </div>
  );
}
