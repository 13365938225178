import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import {
  receiveCreditNote,
  validateCreditNoteReception,
} from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

/**
 *
 * @type {import("./types").StateUseReceiveCreditNote}
 */
const INITIAL_STATE = {
  isOpen: false,
  xml: undefined,
  step: 0,
  canAddXml: false,
  errorMessage: "",
  isReceivingCreditNote: false,
};



/**
 * @type {import("./types").ReturnUseReceiveCreditNote}
 */
export const INITIAL_STATE_CONTEXT_RECEIVE_CREDIT_NOTE = {
  ...INITIAL_STATE,
  attemptCreateCreditNote:async()=>{},
  customClose:()=>{},
  customOpen:()=>{},
  dialog:{
    close:()=>{},
    open:()=>{},
    refDialog:null
  },
  setStep:()=>{},
  updateXmlInfo:()=>{},
  
}

/**
 * @param {import("./types").PropsUseReceiveCreditNote} param - Param to handle the credit note reception
 * @returns {import("./types").ReturnUseReceiveCreditNote}
 */
export default function useReceiveCreditNote({ onReceived = () => {} }) {
  const [state, setState] = useState(INITIAL_STATE);

  const { userInfo } = useContext(LoginContext);

  useEffect(() => {
    (async function () {
      if (state.xml === undefined) return;

      if (!state.xml.isValid) return;

      const { canAdd, message } = await validateCreditNoteReception(
        state.xml.xmlInfo.cfdiRelacionados
      );

      setState((current) => ({
        ...current,
        canAddXml: canAdd,
        errorMessage: message,
      }));
    })();
  }, [state.xml]);

  const dialog = useDialog();

  const customOpen = () => {
    setState((current) => ({
      ...current,
      isOpen: true,
    }));

    dialog.open();
  };

  const customClose = () => {
    setState((current) => ({
      ...current,
      isOpen: false,
    }));

    dialog.close();
  };

  const updateXmlInfo = (xml) =>
    setState((current) => ({
      ...current,
      xml,
    }));

  const setStep = (step) =>
    setState((current) => ({
      ...current,
      step,
    }));

  const attemptCreateCreditNote = async () => {
    setState((current) => ({
      ...current,
      isReceivingCreditNote: true,
    }));

    const wasReceived = await receiveCreditNote(
      {
        acumulated: 0,
        idConcept: null,
        isConcept: false,
        createdAndModifyBy: userInfo[0].fullName,
        creditDays: 0,
        currencyCode: state.xml.xmlInfo.currency,
        discount: 0,
        emitedDate: state.xml.xmlInfo.emitedDate.db,
        expirationDate: state.xml.xmlInfo.emitedDate.db,
        idLegalDocumentProvider: null,
        idLegalDocumentReference: null,
        idLegalDocumentStatus: 16,
        idTypeLegalDocument: 3,
        idFacturama: null,
        importe: state.xml.xmlInfo.importe.number,
        iva: state.xml.xmlInfo.iva.number,
        ivaRetenidos: state.xml.xmlInfo.impuestos.agrupados.retenidos.iva,
        ivaTraslados: state.xml.xmlInfo.impuestos.agrupados.trasladados.iva,
        noDocument: state.xml.xmlInfo.folio,
        pdf: 0,
        residue: 0,
        rfcEmiter: state.xml.xmlInfo.rfcEmitter,
        rfcReceptor: state.xml.xmlInfo.rfcReceptor,
        socialReason: state.xml.xmlInfo.socialReasonCustomer,
        total: state.xml.xmlInfo.total.number,
        uuid: state.xml.xmlInfo.uuid,
        uuidReference: state.xml.xmlInfo.cfdiRelacionados,
        xml: 0,
        isrRetenido: state.xml.xmlInfo.impuestos.agrupados.total.retenidos.isr,
        ivaRetenido: state.xml.xmlInfo.impuestos.agrupados.total.retenidos.iva,
        iepsRetenido: state.xml.xmlInfo.impuestos.agrupados.total.retenidos.ieps,
        ivaTraslado: state.xml.xmlInfo.impuestos.agrupados.total.traslados.iva,
        iepsTraslado: state.xml.xmlInfo.impuestos.agrupados.total.traslados.ieps
      },
      state.xml.xml.file,
      state.xml.pdf.file
    );

    setState((current) => ({
      ...current,
      isReceivingCreditNote: false,
    }));

    if(wasReceived){
      dialog.close();
      setState(INITIAL_STATE);
      onReceived();
    }
  };

  return {
    ...state,
    dialog,
    customOpen,
    customClose,
    updateXmlInfo,
    setStep,
    attemptCreateCreditNote
  };
}
