import { useBanks } from "components/individual/BankCard/hooks/useBanks";
import { transferToBankAccount } from "helpers/Apis/Banks/index";
import { dateToDbFormat } from "helpers/dates";
import { calculateTcV2, truncateDecimals } from "helpers/money";
import React, { useEffect } from "react";
import { useState } from "react";

export default function useBankTransfers({ onSuccess = () => {} }) {
  const { infocards } = useBanks();

  /**
   * @type {[import('./types').StateBankTransfer,(state:import("./types").StateBankTransfer)=>void]}
   */
  const [state, setState] = useState({
    date: null,
    originAccount: {
      information: null,
      ammount: null,
      newAmount: null,
    },
    destinyAccount: {
      information: null,
      ammount: null,
      newAmount: null,
      tc: null,
    },
    newdestinyAccounts: [],
    isPerformingTransfer: false,
  });

  /**
   * Update the state in order to display the spinner
   * @param {boolean} isPerformingTransfer - Flag
   * @returns {void}
   */
  const setIsPerformingTransfer = (isPerformingTransfer) =>
    setState((state) => ({
      ...state,
      isPerformingTransfer,
    }));

  /**
   * Update the date to use on the movement for the transference
   * @param {Date} date - Date of the transference
   * @returns {void}
   */
  const updateDateTransference = (date) =>
    setState((current) => ({
      ...current,
      date,
    }));

  const selectaccount = (account) => {
    setState({
      ...state,
      originAccount: {
        information: account,
        ammount: null,
        newAmount: null,
      },
    });
  };

  const attemptTransfer = async () => {
    setIsPerformingTransfer(true);
    const wasDone = await transferToBankAccount(
      state.originAccount.information.id,
      state.destinyAccount.information.id,
      state.originAccount.ammount,
      state.destinyAccount.ammount,
      dateToDbFormat(state.date)
    );
    setIsPerformingTransfer(false);

    if (wasDone) onSuccess();
  };

  const selectaccountdestiny = (account) => {
    setState({
      ...state,
      destinyAccount: {
        information: account,
        ammount: null,
        newAmount: null,
      },
    });
  };

  /**
   * Update the amount of the origin account
   * @param {number} ammount - Amount to send
   * @returns {void}
   */
  const updateOriginAmount = (ammount) =>
    setState({
      ...state,
      originAccount: {
        ...state.originAccount,
        ammount,
        newAmount: state.originAccount.information.currentBalance - ammount,
      },
    });

  useEffect(() => {
    setState((currentState) => ({
      ...currentState,
      newdestinyAccounts: infocards.map((bank) => ({
        ...bank,
        value: bank.id,
        label: `${bank.account} (${bank.currency}) - ${bank.bank.shortName}`,
        isDisabled:
          bank.id === currentState.originAccount.information.id ? true : false,
      })),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.originAccount.information]);

  useEffect(() => {
    if (
      state.originAccount.ammount === null ||
      state.destinyAccount.information === null ||
      state.originAccount.information === null
    )
      return;

    const isSameCurrency =
      state.destinyAccount.information.currency ===
      state.originAccount.information.currency;

    if (isSameCurrency) {
      handleSameCurrency();
      return;
    }

    handleDifferentCurrency();

    //////////////////////////

    function handleDifferentCurrency() {
      if (
        state.destinyAccount.ammount === null ||
        state.destinyAccount.ammount === 0
      )
        return;

      const tcCalculated = calculateTcV2(
        {
          fromCurrency: state.originAccount.information.currency,
          toCurrency: state.destinyAccount.information.currency,
        },
        state.originAccount.ammount,
        state.destinyAccount.ammount
      );

      setState((state) => ({
        ...state,
        destinyAccount: {
          ...state.destinyAccount,
          ammount: truncateDecimals(state.destinyAccount.ammount, 2),
          tc: truncateDecimals(tcCalculated, 2),
          newAmount: truncateDecimals(
            truncateDecimals(
              state.destinyAccount.information.currentBalance,
              2
            ) +
              truncateDecimals(
                truncateDecimals(state.destinyAccount.ammount, 2),
                2
              ),
            2
          ),
        },
      }));
    }

    function handleSameCurrency() {
      setState((state) => ({
        ...state,
        destinyAccount: {
          ...state.destinyAccount,
          ammount: truncateDecimals(state.originAccount.ammount, 2),
          newAmount: truncateDecimals(
            truncateDecimals(state.originAccount.ammount, 2) +
              truncateDecimals(
                state.destinyAccount.information.currentBalance,
                2
              ),
            2
          ),
          tc: 1,
        },
      }));
    }
  }, [
    state.originAccount.ammount,
    state.originAccount.information,
    state.destinyAccount.information,
    state.destinyAccount.ammount,
  ]);

  function checkIsValidForm() {
    if (
      state.originAccount.information === null ||
      state.destinyAccount.information === null
    )
      return false;

    if (
      state.originAccount.ammount === null ||
      state.originAccount.ammount === 0
    )
      return false;

    if (state.originAccount.newAmount < 0) return false;

    return true;
  }

  function updateDestinyImport(value) {
    setState((state) => ({
      ...state,
      destinyAccount: {
        ...state.destinyAccount,
        ammount: value,
      },
    }));
  }

  return {
    ...state,
    updateOriginAmount,
    accounts: infocards.map((bank) => {
      return {
        ...bank,
        value: bank.id,
        label: `${bank.account} (${bank.currency}) - ${bank.bank.shortName}`,
      };
    }),
    selectaccount,
    selectaccountdestiny,
    setIsPerformingTransfer,
    isValidForm: checkIsValidForm(),
    attemptTransfer,
    updateDestinyImport,
    updateDateTransference
  };
}
