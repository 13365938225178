import React from "react";
import { Label } from "structure/Document";
import Customer from "components/general/Customer";

/**
 * Combo/Provider customer
 * @param {import("components/general/Customer/types").PropsCustomerCombo} props
 * @returns {JSX.Element}
 */
export default function CustomerCombo(props) {
  return (
    <div>
      <Label>Cliente</Label>
      <Customer {...props} />
    </div>
  );
}
