import LoginContext from "context/Login/LoginContext";
import { add } from "date-fns";
import { addFileComment, getFileComments } from "helpers/Apis/associateFilesV2";
import { useContext, useEffect, useState } from "react";
import useDialog from "./useDialog";

/**
 * Information of the state
 * @type {import("types/typedef/customHooks/useAssociateFiles").StateAssociateFilesI}
 */
const initialState = {
  comments: [],
  isLoadingComments: true,
  isAddingComment: false,
  isModalOpen: false,
  comment: "",
};

/**
 *
 * @param {number} idFile - Id of the file
 */
export default function useFileComments(idFile) {
  const [state, setState] = useState(initialState);

  const dialog = useDialog();

  const { userInfo } = useContext(LoginContext);

  useEffect(() => {
    (async function () {
      if (!state.isModalOpen) {
        return;
      }

      const apiComments = await getFileComments(idFile);

      setState({
        ...state,
        isLoadingComments: false,
        comments: apiComments,
      });
    })();
  }, [state.isModalOpen]);

  /**
   * Set the comment to add
   * @param {string} comment - Comment typed by the executive
   * @returns {void}
   */
  const setComment = (comment) =>
    setState({
      ...state,
      comment,
    });

  /**
   * Set the flag to display to spinner when adding a comment
   * @param {boolean} isAddingComment - Flag for the spinner
   * @returns {void}
   */
  const setIsAddingComment = (isAddingComment) =>
    setState({
      ...state,
      isAddingComment,
    });

  const addNewFileComment = async () => {
    setIsAddingComment(true);

    const wasAdded = await addFileComment(
      state.comment,
      idFile,
      userInfo[0].fullName
    );

    if (wasAdded) {
      setState({
        ...state,
        comment: "",
        comments: [
          {
            id: window.crypto.randomUUID(),
            createdDate: {
              utc: new Date().toISOString(),
              formated: new Intl.DateTimeFormat("es-MX", {
                dateStyle: "long",
                timeStyle: "medium",
                hour12: true,
              }).format(new Date()),
            },
            comment: state.comment,
            createdBy: userInfo[0].fullName,
          },
          ...state.comments,
        ],
        isAddingComment: false,
      });
      return;
    }

    setState({
      ...state,
      isAddingComment: false,
    });
  };

  /**
   * Set the state if the modal it's open
   * @param {boolean} isModalOpen - Flag
   * @returns {void}
   */
  const setModalOpen = (isModalOpen) => {
    setState({
      ...state,
      isModalOpen,
      comment:""
    });

    if (isModalOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  };

  return {
    comments: state.comments,
    isLoadingComments: state.isLoadingComments,
    isAddingComment: state.isAddingComment,
    isModalOpen: state.isModalOpen,
    setComment,
    comment: state.comment,
    addNewFileComment,
    setModalOpen,
    dialog,
  };
}
