import { saveAs } from "file-saver";
import {
  getConsecutivePurchaseOrder,
  getOcConsecutiveExcel,
} from "helpers/Apis/Documents";
import { dateToDbFormat, getYearRange, monthRange } from "helpers/dates";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseConsecutivePurchaseOrder}
 */
const INITIAL_STATE = {
  from: getYearRange().begin.js,
  to: new Date(),
  status: null,
  search: "",
  oc: [],
  isLoading: true,
  refetch: true,
  isDownloadingExcel: false,
};

/**
 * @type {import("./types").ReturnUseConsecutivePurchaseOrder}
 */
export const CONTEXT_CONSECUTIVE_PURCHASE_ORDER = {
  ...INITIAL_STATE,
  breadcrum: [],
  updateStartRange: () => {},
  updateEndRange: () => {},
  researchReport: () => {},
  updateSearch: () => {},
  attemptDownloadExcel:async()=>{},
  setStatus: () => {},
};

/**
 * Hook to handle the consecutive purchase order
 * @returns {import("./types").ReturnUseConsecutivePurchaseOrder}
 */
export default function useConsecutivePurchaseOrder() {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        oc: [],
      }));

      const apiData = await getConsecutivePurchaseOrder(
        dateToDbFormat(state.from),
        dateToDbFormat(state.to),
        state.status,
        state.search
      );

      setState((current) => ({
        ...current,
        oc: apiData,
        isLoading: false,
      }));
    })();
  }, [state.refetch]);

  /**
   * Update the range for the start date
   * @param {Date} from - Date
   * @returns {void}
   */
  const updateStartRange = (from) =>
    setState((current) => ({
      ...current,
      from,
    }));
  
  /**
   * Download the excel file of the consecutive odc
   */
  const attemptDownloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const excel = await getOcConsecutiveExcel(
      dateToDbFormat(state.from),
      dateToDbFormat(state.to),
      state.status,
      state.search
    );

    if (excel !== undefined) {
      saveAs(excel, `Consecutivo ODC.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  };

  /**
   * Update the range for the end date
   * @param {Date} to - Date
   * @returns {void}
   */
  const updateEndRange = (to) =>
    setState((current) => ({
      ...current,
      to,
    }));

  /**
   * Update the search to use on the query for the report
   * @param {string} search - Search
   * @returns {void}
   */
  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const researchReport = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Update the status for the report filter search
   * @param {number} status - Status
   * @returns {void}
   */
  const setStatus = (status) =>
    setState((current) => ({
      ...current,
      status,
    }));

  return {
    ...state,
    updateStartRange,
    updateEndRange,
    researchReport,
    updateSearch,
    attemptDownloadExcel,
    setStatus,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/administracion/consecutivo-oc",
        text: "Consecutivo de Ordenes de Compra",
      },
    ],
  };
}
