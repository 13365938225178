/**
 * Check the status code is between 200 and 400
 * @param {number} httpCode - HTTP response code
 * @returns {boolean}
 */
export const isValidHttpResCode = (httpCode) =>
  httpCode >= 200 && httpCode < 400;

/**
 * Check if the response to server was success to return the data requested
 * @param {Response} response
 * @returns {boolean}
 */
export const successRes = (response) =>
  isValidHttpResCode(response.status) && response.ok;

/**
 *
 * @param {Response} response
 * @returns {Promise<import("helpers/apisTypes").ErrorI>}
 */
export const parseHttpError = (response) => response.json();

/**
 * 
 * @param {string[]} array - Array of string with the key value
 * @returns {string}
 * @example
 *  const array = ['param1=value1', 'param2=value2', 'param3=value3'];
    const queryString = arrayToQueryParam(array);
    console.log(queryString); // Output: "?param1=value1&param2=value2&param3=value3"

 */
export function arrayToQueryParam(array) {
  const queryParam = array.reduce((query, item) => {
    if (typeof item !== "string") return query;

    return (query += `${item}&`);
  }, "");

  const queryString = `?${queryParam.slice(0, queryParam.length - 1)}`;

  return queryString;
}


/**
 * @template T
 * @param {T[]} items - Items expected to be returned if success
 * @returns {{
 * success:T[],
 * errors:any[]
 * }}
 */
export function handleAllSeatledPromise(items) {
  let errors = [];
  let success = [];

  items.forEach((item) => {
    if (item.status === "rejected") {
      errors.push(item.reason);
    } else {
      success.push(item.value);
    }
  });

  return {
    errors,
    success
  }
}
