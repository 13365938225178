import React, { createContext } from "react";
import Modal from "components/individual/Modals/ModalV2";
import TypeNote from "./Type";
import Currencies from "./Currencies";
import CheckboxesDocuments from "./DocumentsCheckboxes";
import UENV2 from "components/general/Forms/Selects/UENV2";
import ReactToggle from "react-toggle";
import useNotesAndAclarations from "customHooks/useNotesAndAclarations";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";

/**
 * Context
 * @type {import("react").Context<import("types/typedef/customHooks/useNotesAndAclarations").ContextI>}
 */
export const NotesAndAclarationsContext = createContext();
const { Provider } = NotesAndAclarationsContext;

/**
 * Get a button that opens a modal in order to edit/add a note or aclaration
 * @param {object} props - Props
 * @param {number?} props.id - Id of the note or consideration in case of edition
 * @param {string?} props.aditionalClasses - Class name for the container of the button that opens the modal
 * @param {string?} props.idOpenModal - Id of the button that can be get of the dom in order to click it and open it
 * @param {()=>void} props.onSuccessQuery - Callback to execute when add or edit operation is success
 * @param {"nota"|"consideracion"} props.initialType - Initial type to render on the combo
 * @param {import("types/typedef/customHooks/useNotesAndAclarations").NoteConsiderationI[]} props.notes - In case of edition, the notes that are saved on the server
 * @returns {JSX.Element} Modal and button to open the modal
 * @example
 *
 * // In case of add a new note or aclaration
 *
 * import NotesAndAclarations from "pages/ProcesosEspeciales/Parameters/NotesAndAclarations";
 *
 * function MyComponentParent(){
 *  returns (
 *    <>
 *      <NotesAndAclarations />
 *    </>
 *  )
 * }
 * 
 * // In case of eddit a note or aclaration
 * 
 * import NotesAndAclarations from "pages/ProcesosEspeciales/Parameters/NotesAndAclarations";
 * 
 * function MyComponentParent(){
 *  const [notes,setNotes] = useState([]);
 * 
 *  useEffect(()=>{
 *    const notes = await getNotesOrConsiderations("notas");
 *    setNotes(notes);
 *  },[]);
 * 
 *  returns(
 *    {cards.map((card) => (
        <NotesAndAclarations
          id={card.id}
          notes={cards}
          aditionalClasses="d-none"
          idOpenModal={`editComment-${card.id}`}
        />
      ))}
 *  )
 * }
 */
export default function NotesAndAclarations({
  id = null,
  notes = [],
  aditionalClasses = "",
  idOpenModal = window.crypto.randomUUID(),
  onSuccessQuery = () => {},
  initialType = "notas",
}) {
  const initialTypeId = initialType === "notas" ? 1 : 2;

  const {
    handleContent,
    handleDeletable,
    handleEditable,
    handleUen,
    addNoteAclaration,
    is,
    currency,
    type,
    isLoading,
    handleTypeNote,
    handleCheckbox,
    document,
    handleCurrency,
    isValidForm,
    contentJsx,
    currencyJsx,
    documentJsx,
    isPerformingQuery,
    idModalCloseIcon,
    content,
    textAreaDom,
    uen,
    updateNoteAclaration,
  } = useNotesAndAclarations(id, notes, onSuccessQuery, initialTypeId);

  const typeMessage = type === 1 ? "nota" : "consideración";

  return (
    <Provider
      value={{
        type,
        handleTypeNote,
        handleCheckbox,
        document,
        handleCurrency,
        currency,
      }}
    >
      <Modal
        aditionalClasses={aditionalClasses}
        idOpenModal={idOpenModal}
        buttonText="Agregar"
        idCloseIcon={idModalCloseIcon}
        title="Notas y consideraciones automáticas"
        showCancelButton={false}
        footer={
          <>
            {isValidForm ? (
              isPerformingQuery ? (
                <Spinner hidden={false} text="Agregando" />
              ) : id === null ? (
                <ButtonV2 onClick={addNoteAclaration}>
                  Agregar {typeMessage}
                </ButtonV2>
              ) : (
                <ButtonV2 onClick={updateNoteAclaration}>
                  Actualizar {typeMessage}
                </ButtonV2>
              )
            ) : (
              <>
                {contentJsx}
                {currencyJsx}
                {documentJsx}
              </>
            )}
          </>
        }
      >
        {isLoading ? (
          <Spinner hidden={false} text="Cargando..." />
        ) : (
          <>
            <div className="d-flex align-items-center mb-3">
              <b className="mr-2">Tipo:</b>

              <TypeNote />
            </div>

            <div className="mb-3">
              <b className="required">Documento</b>
              <div className="d-flex align-items-center">
                <CheckboxesDocuments
                  document={document}
                  onChange={handleCheckbox}
                />
              </div>
            </div>

            <div className="mb-3">
              <b className="required">Moneda</b>
              <div className="d-flex align-items-center">
                <Currencies />
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <b className="mr-2">UEN:</b>
              <UENV2
                addTodos={true}
                initialOption={"NA"}
                value={uen}
                disableInitlaOption={false}
                label=""
                onChange={handleUen}
              />
            </div>

            <b className="required">Contenido</b>
            <textarea
              ref={textAreaDom}
              value={content}
              placeholder="Escribe aquí"
              onChange={(e) => handleContent(e.target.value)}
            ></textarea>

            <label
              htmlFor="deletable"
              className="d-inline-block cursorPointer mr-4"
            >
              Eliminable
              <div className="d-flex align-items-center">
                <ReactToggle
                  defaultChecked={is.deletable}
                  id="deletable"
                  onChange={(e) => handleDeletable(e.target.checked)}
                />
                <span className="ml-3">{is.deletable ? "Si" : "No"}</span>
              </div>
            </label>

            <label htmlFor="editable" className="d-inline-block cursorPointer">
              Editable
              <div className="d-flex align-items-center">
                <ReactToggle
                  defaultChecked={is.editable}
                  id="editable"
                  onChange={(e) => handleEditable(e.target.checked)}
                />
                <span className="ml-3">{is.editable ? "Si" : "No"}</span>
              </div>
            </label>
          </>
        )}
      </Modal>
    </Provider>
  );
}
