import React, { Fragment, useContext, useRef, useState } from "react";
import { ContextMoreInfoPaymentReminder } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import Table from "components/general/Table";
import CustomerInfo from "structure/Customer";
import MoreInfoContact from "pages/Directory/Contactos/V2/MoreInfoContact";
import { getClickedColumn } from "helpers/dom";
import { ContextPaymentReminder } from "../..";
import Details from "components/general/Details";
import ui from "./styles.module.scss";

export default function Content() {
  const hook = useContext(ContextMoreInfoPaymentReminder);

  const payment = useContext(ContextPaymentReminder);

  /**
   * @type {[import("../../../../../../../types/contacts").ContactComboI,React.Dispatch<import("../../../../../../../types/contacts").ContactComboI>]}
   */
  const [contact, setContact] = useState(null);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const dom = useRef(null);

  if (hook.isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center m-5">
        <Spinner text={"Cargando más información"} />
      </div>
    );

  return (
    <div className={ui.moreInfo}>
      <MoreInfoContact id={contact?.contactID} forwardRef={dom} />

      <Details summary="Contactos" open>
        <Table
          pagination={false}
          columns={[
            {
              name: "Nombre",
              sortable: true,
              selector: (contact) => contact.fullName,
            },
            {
              name: "Teléfono",
              sortable: true,
              selector: (contact) =>
                typeof contact.phoneCode !== "string"
                  ? "ND"
                  : `+${contact.phoneCode} ${contact.phoneNumber}`,
            },
            {
              name: "Celular",
              sortable: true,
              selector: (contact) =>
                typeof contact.cellCode !== "string"
                  ? "ND"
                  : `+${contact.cellCode} ${contact.cellNumber}`,
            },
            {
              name: "Email",
              sortable: true,
              selector: (contact) => contact.email,
            },
          ]}
          data={hook.contacts}
          selectableRowSelected={(callback) => callback === contact}
          onRowClicked={(value, e) => {
            setContact(value);
            if (getClickedColumn(e.target) === 1) dom.current.click();
          }}
        />
      </Details>

      <Details
        summary={`Cliente - ${payment.reminderSelected.customer.shortName}`}
      >
        <CustomerInfo idCustomer={payment.reminderSelected.customer.id} />
      </Details>
    </div>
  );
}
