import { saveAs } from "file-saver";
import {
  downloadPdfRemition,
  getHeaderRemitions,
  getRemitions,
  handleRemitionAgainstSat,
} from "helpers/Apis/proyects";
import { parseToFolio } from "helpers/documents";
import { useEffect, useRef, useState } from "react";

/**
 * @type {import("./types").StateRemition}
 */
const STATE = {
  status: "loading",
  page: 1,
  recordsFound: 0,
  remitions: [],
  rowsPerPage: 100,
  selectedRemition: undefined,
  pages: 1,
  refetch: false,
  residue: 0,
  totalRemision: 0,
  totalSell: 0,
  statusPdf:"none"
};

/**
 * Hook to handle the remitions table
 * @param {number} idPosition - Id of the position
 * @returns {import("./types").ReturnUseRemition}
 */
export default function useRemition(idPosition) {
  const [state, setState] = useState(STATE);

  useEffect(() => {
    (async function () {
      const header = await getHeaderRemitions(idPosition);

      setState((current) => ({
        ...current,
        ...header,
      }));
    })();
  }, []);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        status: "loading",
        selectedRemition: undefined,
      }));

      const remitions = await getRemitions({
        idPosition,
        limit: 100,
        orderBy: "",
        page: state.page,
      });

      setState((current) => ({
        ...current,
        remitions: remitions.data,
        recordsFound: remitions.noRecordsFound,
        pages: remitions.pages,
        status: "none",
      }));
    })();
  }, [state.page, state.refetch]);

  /**
   * @type {import("react").MutableRefObject<import("components/individual/breadCrums/types").BreadcrumI[]>}
   */
  const breadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/proyectos",
      text: "Proyectos",
    },
    {
      route: "/proyectos",
      text: "Remisión",
    },
  ]);

  const handleDownloadPdf = async() => {
    setState(current=>({
      ...current,
      statusPdf:"loading"
    }));

    const filename = `Remisión_${parseToFolio(state.selectedRemition.documentNumber)}`;

    const pdf = await downloadPdfRemition(state.selectedRemition.idDocument,filename);

    if(pdf instanceof File){
      saveAs(pdf, `${filename}.pdf`);
    }

    setState(current=>({
      ...current,
      statusPdf:"none"
    }))
  }

  const refetchTable = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
      page: 1,
    }));

  const handleRemitionCreatedOnSat = async () => {
    await handleRemitionAgainstSat(idPosition);
    refetchTable();
  };

  /**
   *
   * @param {import("../../../../types/proyects/remisionProyect").DtoRemisionProyect} remition
   */
  const setSelectedRemition = (remition) =>
    setState((current) => ({
      ...current,
      selectedRemition: remition,
    }));

  return {
    ...state,
    breadcrum,
    setSelectedRemition,
    refetchTable,
    handleRemitionCreatedOnSat,
    handleDownloadPdf
  };
}
