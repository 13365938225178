import React, { Fragment, useContext, useRef } from "react";
import { ContextGestionOc } from "..";
import ui from "./styles.module.scss";
import { parseToFolio } from "helpers/documents";
import { parseUtcToText } from "helpers/dates";
import NotConfirmed from "./NotConfirmed";
import { Spinner } from "components/individual/Spinner/Spinner";
import MoreInfo from "../MoreInfo";
import Mobile from "./Mobile";
import Checkbox from "./Checkbox";

export default function Table() {
  const hook = useContext(ContextGestionOc);

  const key = useRef(`${window.crypto.randomUUID()}`);

  const styles = {
    display: "grid",
    gridTemplateColumns: `repeat(${hook.headers.length},${
      100 / hook.headers.length
    }%)`,
    alignItems: "center",
    margin: "0 10px",
  };

  if (hook.isLoading) return <Spinner text={"Cargando"} hidden={false} />;

  if (hook.managmentRecords.length <= 0)
    return <h1 className="text-center my-5">No hay resultados</h1>;

  return (
    <div className={ui.table}>
      <div className={ui.contentTable}>
        <div className={`${ui.header} py-2`} style={styles}>
          {hook.headers.map((label, i) => (
            <Fragment key={`${key.current}-${i}`}>
              <p>{label}</p>
            </Fragment>
          ))}
        </div>

        {hook.managmentRecords.map((record, i) => (
          <Fragment key={`${key.current}-record-${i}`}>
            <Mobile {...record} />

            <input
              id={`${key.current}-record-${i}`}
              type="radio"
              className="d-none"
              name={key.current}
              checked={hook.record === undefined ? false : hook.record.idOdc === record.idOdc}
              onChange={(e) => hook.setRecord(record)}
            />
            <label style={styles} htmlFor={`${key.current}-record-${i}`}>
              <MoreInfo oc={record}>
                <div className="d-flex justify-content-center align-items-center hyperlink">
                  <p className="m-0 hyperlink">
                    {parseToFolio(record.documentNumber)}{" "}
                    {record.hasComment ? "*" : ""}
                  </p>
                </div>
              </MoreInfo>

              <p className="text-center justify-content-center">
                {parseUtcToText(record.emitedDate)}
              </p>
              <p>{record.client}</p>
              <p>{record.supplier}</p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.invoiceDate)}
              </p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.sendDate)}
              </p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.confirmationDate)}
              </p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.licencesConfirmedDate)}
              </p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.myContractSingedDate)}
              </p>
              <p className="text-center  justify-content-center">
                {parseUtcToText(record.licencesConfirmedDate)}
              </p>

              <Checkbox {...record}/>
              <NotConfirmed {...record} />
            </label>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
