// import { Select } from "components/individual/inputs/Inputs";
import { Spinner } from "components/individual/Spinner/Spinner";
import { GetUEN } from "helpers/Apis/Documents";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "structure/Document";

/**
 * @type {import("types/typedef/catalogue").UenI[]}
 */
const INITIAL_STATE = [];

/**
 * UEN combo
 * @param {object} props - Props
 * @param {(object:import("types/typedef/catalogue").UenI)=>void} [props.onChange=()=>{}] - Information of the UEN
 * @param {(object:import("types/typedef/catalogue").UenI)=>void} [props.onLoad=()=>{}] - Default option when combo its loaded
 * @param {boolean} [props.addTodos=false] - Indicate if show the initial option
 * @param {string} [props.initialOption='Selecciona'] - Text to show on the initial option
 * @param {boolean} [props.disableInitlaOption=true] - True if the initial option can not be selected again
 * @param {string} [props.label="UEN"]
 * @param {string} [props.className=""]
 * @param {number} [props.value=null]
 * @param {boolean} [props.isClearable=false]
 *
 * @returns {JSX.Element} Component
 */
export default function UENV2({
  onChange = () => {},
  onLoad = () => {},
  value = null,
  isClearable = false,
}) {
  const [uen, setUen] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiUen = await GetUEN();

      /**
       * @type {import("types/typedef/catalogue").UenI[]}
       */
      const valuesToUse = apiUen.map((item) => ({
        ...item,
        label: `${item.description}`,
        value: item.UENID,
      }));

      setUen(valuesToUse);
      onLoad(valuesToUse[0]);
    })();
  }, []);

  const handleOnChange = (id) => {
    const item = uen.find((item) => item.UENID === id);

    if (!item) return;
    onChange(item);
  };

  if (uen.length <= 0) return <Spinner hidden={false} text="Cargando UEN(s)" />;

  return (
    <div>
      <Label>UEN</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select"
        placeholder="Selecciona una UEN"
        isClearable={isClearable}
        onChange={(item) => onChange(item)}
        value={uen.find((item) => item.UENID === value) || null}
        options={uen}
      />
    </div>
  );

  // return (
  //   <Select
  //     addTodos={addTodos}
  //     initialOption={initialOption}
  //     className={className}
  //     idSelect="uenList"
  //     label={label}
  //     nameSelect="uen"
  //     value={value}
  //     textJSON="description"
  //     valueJSON="UENID"
  //     options={uen}
  //     onChange={(id) => handleOnChange(+id)}
  //     disableInitlaOption={disableInitlaOption}
  //   />
  // );
}
