import { ContextBankCorrection } from "context/BankCorrection";
import React, { useContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { Breadcrum } from "./breadcrum";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Label } from "structure/Document";
import Money from "components/general/Forms/Money";
import SelectBank from "./Select";
import ui from "./styles.module.scss";
import { BankCard } from "components/individual/BankCard/BankCard";
import MonthSelect from "components/general/Forms/Selects/Month";
import YearSelect from "components/general/Forms/Selects/Year";
import SubmitCorrection from "./SubmitCorrection";

export default function Corrections() {
  const hook = useContext(ContextBankCorrection);

  if (!hook.apiLoaded)
    return (
      <div>
        <BreadCrumV2 path={Breadcrum} />
        <div className="m-5">
          <Spinner text={"Cargando cuentas"} hidden={false} />
        </div>
      </div>
    );

  if (hook.banksAvailableToCorrect.length<=0)
    return (
      <div>
        <BreadCrumV2 path={Breadcrum} />
        <p>No hay ningun banco al cual corregir el saldo inicial</p>
      </div>
    );

  if (hook.bankSelected === undefined || hook.bankSelected === null)
    return (
      <>
        <BreadCrumV2 path={Breadcrum} />

        <div className={ui.containerModule}>
          <div className={ui.containerBank}>
            <SelectBank />
            <p className="text-danger font-weight-bold">
              Primero selecciona una cuenta de banco
            </p>
          </div>
        </div>
      </>
    );

  return (
    <>
      <BreadCrumV2 path={Breadcrum} />

      <div className={ui.containerModule}>
        <div className={ui.containerBank}>
          <SelectBank />

          <BankCard
            account={hook.bankSelected.account}
            clave={hook.bankSelected.bank.clave}
            shortname={hook.bankSelected.bank.shortName}
            currency={hook.bankSelected.currency}
            id={hook.bankSelected.id}
            currentBalance={hook.bankSelected.currentBalance}
            doFlip={false}
            bankAccountInfo={hook.bankSelected}
            key={`active-accounts-${hook.bankSelected.id}`}
          />

          <div>
            <Label>Saldo inicial</Label>
            <Money
              maxDecimals={2}
              onChange={hook.setInitialAmount}
              value={hook.initialAmount}
            />
          </div>

          <div className={ui.date}>
            <div>
              <YearSelect
                onChange={hook.setYear}
                required={hook.year === undefined}
                from={new Date().getUTCFullYear() - 100}
              />
            </div>
            <MonthSelect
              required={hook.month === undefined}
              onChange={hook.setMonth}
            />
          </div>

          <SubmitCorrection />
        </div>
      </div>
    </>
  );
}
