import { CLABE } from "components/general/CLABE";
import { DateInput } from "components/general/Forms/Dates";
import { Banks, List, ClaveBank } from "components/general/Forms/Selects/Banks";
import { TypeBankAccount } from "components/general/Forms/Selects/TypeBankAccount";
import { BankAccount } from "components/general/Forms/Text/BankAccount";
import InformativeTooltip from "components/individual/InfoTooltip";
import Currency from "components/individual/inputs/Currency";
import { InputCurrency } from "components/individual/inputs/Inputs";
import ToggleComponent from "components/individual/inputs/Toggle";
import { Spinner } from "components/individual/Spinner/Spinner";
import useBankForm from "customHooks/useBankForm";
import { dateToDbFormat, dateWithCeroHours, MONTHS } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import React, { useEffect } from "react";
import { Label } from "structure/Document";

/**
 * Render a form to add a new bank account into system
 * @param {import("./types").PropsAddBank} props - Props
 * @returns {JSX.Element}
 */
export const BankForm = ({ onChange = () => {}, id = null }) => {
  const hook = useBankForm(id);

  useEffect(() => {
    const isValid =
      hook.typeAccount !== null &&
      hook.bank.id !== null &&
      hook.cuenta !== null &&
      hook.cuenta !== "" &&
      hook.currency !== null &&
      hook.residue >= 0 &&
      hook.isValid
        ? true
        : false;

    onChange({
      bank: hook.bank,
      CLABE: hook.CLABE,
      cuenta: hook.cuenta,
      currency: hook.currency,
      residue: hook.residue,
      typeAccount: hook.typeAccount,
      isValid,
      active: hook.active,
      date: hook.date,
    });
  }, [
    hook.typeAccount,
    hook.bank.id,
    hook.cuenta,
    hook.cuenta,
    hook.currency,
    hook.residue,
    hook.isValid,
    hook.active,
  ]);

  if (hook.isLoading)
    return <Spinner hidden={false} text="Cargando cuenta de banco..." />;

  return (
    <Banks clave={hook.bank.clave} id={hook.bank.id}>
      {() => (
        <div>
          <div className="row mb-2">
            <div className="col-md-6">
              <Label required={hook.bank.id === null}>
                Razon social del banco
              </Label>
              {id === null ? (
                <List
                  onChange={(value) =>
                    hook.updateBank(value.id, value.claveSat, value)
                  }
                />
              ) : (
                <p>{hook.bank.info.shortName}</p>
              )}
            </div>

            <div className="col-md-6">
              <BankAccount
                placeholder="Escribe aquí"
                value={hook.cuenta}
                onChange={hook.updateCuenta}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6">
              <CLABE
                claveSat={hook.bank.clave}
                value={hook.CLABE}
                onChange={(data) => hook.updateCLABE(data?.clabe, data?.ok)}
              />
            </div>
            <div className="col-md-6">
              <ClaveBank />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-6">
              <Label required={hook.currency === null}>Moneda</Label>
              {id !== null ? (
                <p>{hook.currency}</p>
              ) : (
                <Currency
                  selected={hook.currency}
                  onChange={hook.updateCurrency}
                />
              )}
            </div>

            {id !== null ? null : (
              <div className="col-md-6">
                <Label required={hook.residue < 0}>Saldo apertura</Label>
                <InputCurrency
                  defaultValue={hook.residue}
                  onChange={hook.updateResidue}
                />
              </div>
            )}

            <div className="col-md-6">
              {id !== null ? (
                <>
                  <Label>Último cierre de mes</Label>
                  <p>{hook.lastMonth}</p>
                </>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Label required={hook.typeAccount === null}>Tipo de cuenta</Label>
              <TypeBankAccount
                value={hook.typeAccount}
                onChange={hook.updateTypeAccount}
              />
            </div>

            {id === null ? (
              <div className="col-md-6">
                <Label>
                  Fecha cierre de mes{" "}
                  <InformativeTooltip>
                    <p>
                      Esta cuenta de banco tendra <b>cierre de mes</b> con la
                      fecha de{" "}
                      <b>
                        {MONTHS[hook.date.getMonth()]} de{" "}
                        {hook.date.getFullYear()}{" "}
                      </b>
                      con un{" "}
                      <b>
                        saldo de {mnCurrency(hook.residue)} {hook.currency}
                      </b>
                    </p>

                    <p>
                      <b>NOTA:</b> solo se podran agregar movimientos
                      posteriores al mes de {MONTHS[hook.date.getMonth()]}
                    </p>
                  </InformativeTooltip>{" "}
                </Label>
                <DateInput
                  allowPartialRange={false}
                  defaultValue={hook.date}
                  value={hook.date}
                  onChange={(date) => hook.updateCloseDate(date)}
                />
              </div>
            ) : hook.canInactive ? (
              <div className="col-md-6">
                <Label>Estatus</Label>
                <ToggleComponent
                  isChecked={hook.active}
                  labelActive="Activo - Si"
                  labelInactive="Activo - No"
                  onChange={(active) => hook.updateIsActive(active)}
                />
              </div>
            ) : (
              <div className="col-md-6">
                <Label>Estatus - Activo</Label>
                <p>
                  No se puede inactivar, primero debes cerrar los meses
                  pendientes de esta cuenta
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </Banks>
  );
};
