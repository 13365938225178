import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { ContactArea } from "./Styles";
import CreatableSimpleContact from "components/general/Forms/Selects/CreatableSimpleContact";

/**
 *
 * @param {object} props - Props
 * @param {number} props.value - Id of the contact to use on the combo
 * @param {number} props.idCustomer - Id of the customer to use on the combo
 * @returns {JSX.Element}
 */
export default function ContactComp({
  value = null,
  className = "",
  onChange = () => {},
  onLoaded = () => {},
  idCustomer = null,
  onNewContact = () => {},
  options = [],
  executeOnChange = true,
}) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI,
   *    contact:import("types/typedef/customHooks/useEditDocument").DocumentEditContactI
   * }}
   */
  const { document, setDocument } = useContext(FormContext);

  const [render, setRender] = useState(0);

  const idCustomerParsed =
    idCustomer === null ? document.customer.id : idCustomer;

  const openModal = () => {
    const button = window.document.getElementById("createContactBtn");
    button?.click();
  };

  useEffect(() => {
    if (options.length <= 0) return;

    if (typeof options[0].contactID !== "string") return;

    const contactCreated = options[0];

    setDocument({
      ...document,
      contact: {
        ...document.contact,
        ...contactCreated,
        firstName: contactCreated.firstName,
        fullName: contactCreated.fullName,
        id: contactCreated.contactID,
        customer: contactCreated.customerID,
        middleName: contactCreated.middleName,
        parentName: contactCreated.lastName1,
        motherName: contactCreated.lastName2,
      },
    });
  }, [options]);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Cliente"
            value={!value ? document.customer.socialReason : value}
          />
        </div>
      ) : (
        <ContactArea>
          <label htmlFor="">Contacto</label>
          <div className="d-flex">
            <CreatableSimpleContact
              idContact={value === null ? document.contact.id : value}
              onChange={(contact) => {
                onChange(contact);

                if (contact === null) {
                  setDocument({
                    ...document,
                    contact: {
                      ...document.contact,
                      firstName: null,
                      fullName: null,
                      id: null,
                      middleName: null,
                      parentName: null,
                      motherName: null,
                    },
                  });
                  return;
                }

                if (!executeOnChange) {
                  return;
                }

                setDocument({
                  ...document,
                  contact: {
                    ...document.contact,
                    firstName: contact.firstName,
                    fullName: contact.fullName,
                    id: contact.contactID,
                    middleName: contact.middleName,
                    parentName: contact.lastName1,
                    motherName: contact.lastName2,
                  },
                });
              }}
              onLoaded={(contacts) => onLoaded(contacts)}
              options={options}
              onNewContact={(e) => {
                onNewContact(e);
                openModal();
              }}
              idCustomer={idCustomerParsed}
            />
          </div>
        </ContactArea>
      )}
    </>
  );
}
