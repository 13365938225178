import { Select } from "components/individual/inputs/Inputs";
import React, { useContext } from "react";
import { NotesAndAclarationsContext } from "..";

export default function TypeNote() {
  const { type, handleTypeNote } = useContext(NotesAndAclarationsContext);

  return (
    <Select
      options={[
        {
          id: 1,
          label: "Nota",
        },
        {
          id: 2,
          label: "Consideración",
        },
      ]}
      textJSON="label"
      valueJSON="id"
      value={type}
      addTodos={false}
      onChange={(value) => handleTypeNote(+value)}
    />
  );
}
