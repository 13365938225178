import React, { useContext } from "react";
import { RfcContext } from ".";
import { Label } from "structure/Document";

export default function LabelCountryRfc() {
  const hook = useContext(RfcContext);

  return (
    <div className="d-inline-flex align-items-center">
      <Label required={hook.country === ""}>País</Label>
    </div>
  );
}
