import React , {useEffect,useState} from 'react';
import { AllExecutives } from '../../../../helpers/apis';
import { ErrorMessage, Select } from '../../../individual/inputs/Inputs';

/**
 * Combo with all the executives registered on the system
 * @param {object} props - Props
 * @param {string?} props.id - Id of the select
 * @param {string?} props.name - Name attribute of the select
 * @param {string?} props.label - Label for the select combo
 * @param {(executive:string)=>void} props.onChange - Id of the executive selected
 * @param {()=>void} props.onLoaded - ??
 * @param {number?} props.value - Value of the option selected when combo renders
 * @param {object} props.forwardRef - ref for react-hook-form
 * @param {object} props.errors - errors for react-hook-form
 * @param {boolean?} props.addTodos - Add option "Todos"
 * @param {string?} props.initialOption - Initial option to display
 * @returns {JSX.Element} Combo with all the executives registered on the system
 */
export default function Executives({
    id = 'executiveAttends',
    name = 'executiveAttends',
    label = 'Ejecutivo *',
    onChange = () => {},
    onLoaded = () => {},
    value = null,
    forwardRef = {},
    errors = {},
    addTodos=false,
    initialOption = 'Sin jefe directo'
}){

    const [executives,setExecutives] = useState([]);

    useEffect(()=>{
        (async function(){
            const apiExecutives = await AllExecutives();

            const parsedExecutives = apiExecutives.filter(user=>user.status===true);

            setExecutives(parsedExecutives);
        })();
    },[]);

    useEffect(()=>{
        if(executives.length===0) return;
        onLoaded(executives);
    },[executives])

    return(

        <div>
        <Select
            addTodos = { addTodos }
            idSelect = {id}
            nameSelect = {name}
            options = {executives}
            textJSON = 'TEXT'
            forwardRef = {forwardRef}
            
            valueJSON = 'value'
            disableInitlaOption = {false}
            label = {label}
            initialOption = { initialOption }
            onChange = {value => onChange(value)}
            value = {value}
        />

        <ErrorMessage
            message = {errors[name]?.message}
        />

        </div>
    )
}