import { CustomTable } from "components/general/Table/CustomTable";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { BotonneraContainer } from "pages/Inicio/Modals/Styles";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import Step2 from "../Step2/Step2";
import useProvidersWhitCxP from "../hooks/useProvidersWhitCxP";
import { SearchProviders } from "./Search";

/**
 * @type {import("react").Context<import("types/typedef/customHooks/useProvidersWhitCxP").ContextStep1>}
 */
export const Step1Context = createContext();
const { Provider } = Step1Context;

export const AssociateProviders = () => {
  const { account } = useParams();
  const {
    breadCrumPath,
    refetchTable,
    updateQuerySearch,
    state,
    wasFounded,
    step,
    setStep,
    infoRow,
    currencyAccount,
    bankAccount,
  } = useProvidersWhitCxP(account);
  return (
    <>
      <BreadCrumV2 path={breadCrumPath} />

      {bankAccount !== null ? (
        <div className="container mt-3">
          <h4>Información de la cuenta bancaria</h4>
          <p>
            {bankAccount.shortName} | {bankAccount.accountNumber} |{" "}
            {bankAccount.currencyCode}
          </p>
          <hr />
        </div>
      ) : null}

      {step === 1 ? (
        <div className="container">
          <SearchProviders
            refetchTable={refetchTable}
            updateQuerySearch={updateQuerySearch}
          />
          <BotonneraContainer>
            {!!infoRow ? (
              <ButtonV2 onClick={() => setStep(2)}>Continuar</ButtonV2>
            ) : null}
          </BotonneraContainer>

          {state.isLoadingTable ? (
            <Spinner hidden={false} text="Cargando proveedores..." />
          ) : !wasFounded ? (
            <h1>No se encontraron proveedores con CXP disponibles.</h1>
          ) : (
            <>
              <CustomTable
                paginationID="pagination-providerswithcxp"
                tableID="providerswithcxp"
              />
            </>
          )}
        </div>
      ) : (
        <Provider
          value={{
            infoRow,
            bankAccount,
            currencyAccount,
            setStep,
          }}
        >
          <Step2 />
        </Provider>
      )}
    </>
  );
};
