import styled from "styled-components";

export const LabelComponent = styled.label`
  display: flex;
  align-items:center;

  &::after {
    content: " ${({ required }) => (required ? "*" : "")} ";

    color: var(--red);
    margin: ${({ required }) => (required ? "0 0 0 5px" : "0px")};
  }
  margin: 0 0.5rem 0 0;
  * {
    font-weight: normal;
  }
`;
