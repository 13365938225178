import { useEffect, useState, useContext } from "react";
import ExecutiveFilterSearchContext from "./ExecutiveFilterSearchContext";
import LoginContext from "context/Login/LoginContext";

const { Provider } = ExecutiveFilterSearchContext;

const ExecutiveFilterSearchState = ({ children }) => {
  const { userInfo, userToRepresentId } = useContext(LoginContext);

  const [executive, setExecutive] = useState(null);

  useEffect(() => {
    if (userInfo === undefined || userInfo.length === 0) return;

    setExecutive({
      fullName: userInfo[0].fullName,
      id: userToRepresentId,
      // id: userInfo[0].userID,
    });
  }, [userInfo, userToRepresentId]);

  return <Provider value={{ executive, setExecutive }}>{children}</Provider>;
};

export default ExecutiveFilterSearchState;
