import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import Dialog from "structure/Dialog";
import useDocumentsLogs from "./useDocumentsLog";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import usePermissions from "customHooks/usePermissions";
import Icon from "components/individual/HeaderIcon";

export default function DocumentsLog({ children = <></> }) {
  const hook = useDocumentsLogs();

  const { checkPermission } = usePermissions();

  if(!checkPermission('c7884b35-6180-4fac-ae60-82d1f61bf22a')) return <></>

  return (
    <>
      <div onClick={hook.dialog.open}>{children}</div>

      <Dialog
        title="Log documentos"
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
        footer={
          <div className="d-flex justify-content-end">
            {hook.isDownloading ? (
              <Spinner
                idSpinner="downloadingLogDocuments"
                hidden={false}
                text={<LoadingText>Descargando</LoadingText>}
              />
            ) : (
              <ButtonV2 onClick={hook.attemptDownload}>
                <span>Descargar</span>
                <Icon icon="download"/>
              </ButtonV2>
            )}
          </div>
        }
      >
        <h1 className="my-5 text-center">Log de documentos en formato excel</h1>
      </Dialog>
    </>
  );
}
