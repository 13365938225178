import { setCancelStatus } from "helpers/Apis/Banks";
import { cancelIncoming, getMovements } from "helpers/Apis/Banks/index";
import {
  dateAtCeroHours,
  dateToDbFormat,
  dateWithCeroHours,
  getFirstDayOfMonth,
  getLastDayOfDate,
  monthRange,
} from "helpers/dates";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BankContext from "context/Bank/BankContext";
import usePermissions from "customHooks/usePermissions";

/**
 * @type {import("./types").StateMovements}
 */
const INITIAL_STATE = {
  movements: [],
  pagination: {
    currentPage: 1,
    pages: 1,
  },
  isFetchingData: true,
  startDate: dateAtCeroHours(monthRange().firstDay),
  endDate: dateAtCeroHours(new Date()),
  movement: null,
  justOnProcess: false,
  isCancelling: false,
  includeCancelatedMovements:true,
  isCancelModalOpen: false,
  refetch: false,
  canAssociateFiles: false,
  canEdit: false,
  isForAccounting: false,
  canCancel: false,
  acconuntingStatus: null,
  accountingGrid: {
    original: {},
    operation: {},
  },
};

export default function useMovements({
  type = "ingreso",
  bankAccount = null,
  isForAccounting = false,
}) {
  const [state, setState] = useState({...INITIAL_STATE,isForAccounting});

  const history = useHistory();

  const { checkPermission } = usePermissions();

  const context = useContext(BankContext);

  useEffect(() => {
    setState((current) => ({
      ...current,
      canAssociateFiles: checkPermission(
        "2f9beeab-d15f-476a-8fe6-4d1756e66f73"
      ),
    }));
  }, []);

  useEffect(() => {
    (async function () {
      if (state.endDate === null || state.startDate === null) return;

      setState((current) => ({
        ...current,
        isFetchingData: true,
        movement: null,
      }));

      if (bankAccount === null) {
        setState((current) => ({
          ...current,
          isFetchingData: false,
          movement: null,
        }));
        return;
      }


      const apiMovements = await getMovements({
        bankAccount,
        endDate: dateToDbFormat(state.endDate),
        filterBy: type,
        page: state.pagination.currentPage,
        startDate: dateToDbFormat(state.startDate),
        justOnProcess: state.justOnProcess,
        accounted: state.acconuntingStatus,
        includeCancelatedMovements:isForAccounting ? false : true
      });

      const gridAccounting = apiMovements.movements.reduce(
        (indexed, mov) => ({
          ...indexed,
          [mov.id]: mov.isAccounted,
        }),
        {}
      );

      setState((current) => ({
        ...current,
        isFetchingData: false,
        accountingGrid: {
          operation: gridAccounting,
          original: gridAccounting,
        },
        movements: apiMovements.movements,
        movement: null,
        pagination: {
          ...current.pagination,
          pages: apiMovements.pages,
        },
      }));
    })();
  }, [
    type,
    // bankAccount,
    state.pagination.currentPage,
    // state.endDate,
    // state.startDate,
    // state.justOnProcess,
    state.refetch,
    // state.acconuntingStatus

  ]);

  const setForAccounting = (isForAccounting) =>
    setState((current) => ({
      ...current,
      isForAccounting,
    }));

  /**
   * Triggering function in order to refetch the data with the current parameters of the user
   * @returns {void}
   */
  const refetchData = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
    }));

  /**
   * Update the movement that's selected
   * @param {import("../../../../server/helpers/validations/bank/movements/types").BankAccountMovementI} movement - Movement
   * @returns {void}
   */
  const selectMovement = (movement) => {
    setState((current) => ({
      ...current,
      movement,
      canEdit: movement.status.id === 5 ? true : false,
      canCancel: movement.status.id === 4 ? false : true,
    }));
  };

  /**
   * Set the range for the report
   * @param {[Date,Date]} range - Update the range for the report
   */
  const updateRangeDate = ([start, end]) => {
    setState((current) => ({
      ...current,
      endDate: end,
      startDate: start,
      pagination: {
        currentPage: 1,
        pages: 1,
      },
    }));
  };

  /**
   * Fetch the page requested by the user
   * @param {number} page - Number of page to request
   * @returns {void}
   */
  const updatePage = (page) =>
    setState((current) => ({
      ...current,
      pagination: {
        ...current.pagination,
        currentPage: page,
      },
    }));

  /**
   * Update the switch filter to display just on process
   * @param {boolean} justOnProcess - Flag
   * @returns {void}
   */
  const updateJustOnProcess = (justOnProcess) =>
    setState((current) => ({
      ...current,
      justOnProcess,
    }));

  function setIsCancelling(isCancelling) {
    setState((current) => ({
      ...current,
      isCancelling,
    }));
  }
  /**
   * Update the state to display or hide the cancel modal option
   * @param {boolean} isOpen - Flag to open or close modal
   * @returns {void}
   */
  const setCancelModal = (isOpen) =>
    setState((current) => ({
      ...current,
      isCancelModalOpen: isOpen,
    }));

  /**
   * @deprecated
   */
  async function attemptCancelMovement() {
    setIsCancelling(true);
    const wasCancelled = await cancelIncoming(state.movement.id);

    if (wasCancelled) {
      setCancelModal(false);
      setState((current) => ({
        ...current,
        refetch: !current.refetch,
      }));
    }

    setIsCancelling(false);
  }

  const redirectToAssociatedFiles = () => {
    history.push(
      `/bancos/movimientos/${
        bankAccount || context.account.id
      }/archivos-asociados?movimiento=${state.movement.id}&cuenta=${
        bankAccount || context.account.id
      }&tipo=ingreso`
    );
  };

  const redirectToEdit = () => {
    history.push(
      `/v2/bancos/${
        type === "ingreso" ? "ingresos" : "egresos"
      }/agregar?cuenta=${bankAccount || context.id}&movimiento=${
        state.movement.id
      }`
    );
  };

  /**
   * Set the year for the accounting filter
   * @param {number} year - Year
   */
  const setYear = (year) => {
    const startDate = dateWithCeroHours(
      getFirstDayOfMonth(
        new Date(`${year}-${state.startDate.getUTCMonth() + 1}-10`)
      )
    );
    const endDate = dateWithCeroHours(
      getLastDayOfDate(
        new Date(`${year}-${state.endDate.getUTCMonth() + 1}-10`)
      )
    );

    setState((current) => ({
      ...current,
      startDate,
      endDate,
    }));
  };

  const setMonth = (month) => {
    const startDate = dateWithCeroHours(
      getFirstDayOfMonth(
        new Date(`${state.startDate.getUTCFullYear()}-${month}-10`)
      )
    );
    const endDate = dateWithCeroHours(
      getLastDayOfDate(
        new Date(`${state.endDate.getUTCFullYear()}-${month}-10`)
      )
    );

    setState((current) => ({
      ...current,
      startDate,
      endDate,
    }));
  };

  /**
   * Update the accounting status for the movements
   * @param {boolean|null} status - Status
   * @returns {void}
   */
  const setAccounting = (status) =>
    setState((current) => ({
      ...current,
      acconuntingStatus: status,
    }));

  /**
   * Update the checkbox accounting for the movement
   * @param {number} id - Id of the movement
   * @returns {void}
   */
  const toggleAccountingCheck = (id) =>
    setState((current) => ({
      ...current,
      accountingGrid: {
        ...state.accountingGrid,
        operation: {
          ...current.accountingGrid.operation,
          [id]: !current.accountingGrid.operation[id],
        },
      },
    }));

  function getBreadcrum() {
    if (!state.isForAccounting) {
      return [
        {
          route: "/inicio",
          text: "Inicio",
        },
        {
          route: "/v2/bancos",
          text: "Bancos",
        },
        {
          route: "/",
          text: type === "ingreso" ? "Ingresos" : "Egresos",
        },
      ];
    }

    return [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: `/contabilidad/${type === "ingreso" ? "ingresos" : "egresos"}`,
        text: "Contabilidad",
      },
      {
        route: "/",
        text: type === "ingreso" ? "Ingresos" : "Egresos",
      },
    ];
  }

  return {
    ...state,
    selectMovement,
    redirectToAssociatedFiles,
    setAccounting,
    redirectToEdit,
    setMonth,
    updateRangeDate,
    updateJustOnProcess,
    setYear,
    attemptCancelMovement,
    updatePage,
    toggleAccountingCheck,
    setCancelModal,
    isForAccounting: state.isForAccounting,
    setForAccounting,
    refetchData,
    breadcrum: getBreadcrum(),
  };
}
