import React from "react";
import {
  BankHeader,
  BankName,
  BankAccount,
  BankCurrency,
} from "components/general/BankHeader";
import BankContext from "context/Bank/BankContext";
import { useContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import InformativeAmount from "./InformativeAmount";
import { GridCxC } from "components/general/GridCxC";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import useCreateIncoming from "customHooks/useCreateIncoming";
import scss from "./styles.module.scss";
import { createContext } from "react";
import AddIncomingSubmit from "./Add";
import IncomingsConcepts from "./Concepts";
import { Tabs } from "components/general/Tabs/Tabs";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";
import useQueryParams from "customHooks/useQueryParams";
import CustomerComboSelect from "./CustomerComboSelect";
import AmmountInput from "./AmmountInput";
import DateField from "./DateField";
import usePermissions from "customHooks/usePermissions";
import { useRef } from "react";
import PayMethodIngress from "./PayMethodIngress";
import Beneficiary from "./Beneficiary";
import scss2 from "pages/BanksV2/Egresses/Add/styles.module.scss";
import { mnCurrency } from "helpers/money";
import Factoraje from "./Factoraje";

/**
 * @type {import("react").Context<import("customHooks/useCreateIncoming/types").ReturnUseCreateIncoming|undefined>}
 */
export const ContextAddIncoming = createContext(undefined);

export default function AddIncoming() {
  const query = useQueryParams();

  const key = useRef(window.crypto.randomUUID());

  usePermissions('30e4099b-5b86-4a1d-a725-fbb4bfbc3efa');

  const context = useContext(BankContext);
  const hook = useCreateIncoming(
    context.account,
    context.id,
    +query.movimiento,
    query.modulo
  );

  return (
    <ContextAddIncoming.Provider value={hook}>
      <div className={scss.containerAddEgress}>
        <div className={scss2.gridAddEgress}>
          <div>
            <BreadCrumV2 path={hook.breadcrum} />

            <div className={scss2.containerOptions}>
              <BankHeader>
                {() => (
                  <div className={scss2.headerAssociation}>
                    <div className="mb-2">
                      <BankName />
                      <BankAccount />
                      <BankCurrency />
                    </div>
                  </div>
                )}
              </BankHeader>
              <div className={scss2.options}>
                <CustomerComboSelect />

                <Beneficiary />

                <DateField />

                <PayMethodIngress />

                <AddIncomingSubmit />

                <div>
                  {hook.willGenerateComplement ? <p className="text-warning font-weight-bold">⚡ Esta operacion genera complemento</p> : null}
                  <p className="font-weight-bold">💲 Tolerancia de {mnCurrency(hook.state.tolerance)}</p>

                </div>

              </div>
            </div>
          </div>
          <div className={scss2.body}>
            {hook.canDisplayGrid ? (
              <div className={scss.containerTabs}>
                <Tabs
                  idContainerContent="incomings"
                  idContainerTabs="tab"
                  tabButtons={{
                    cxcs: {
                      text: hook.cxcLabelTab,
                      selected: true,
                      component: (
                        <div className={scss.containerCxc}>
                          <div>
                            <Label>Búsqueda</Label>
                            <Search
                              onSearch={hook.updateSearch}
                              onDelete={() => hook.updateSearch("")}
                              placeholder="Folio , uuid"
                            />
                          </div>
                          <div className={scss.gridCxc}>
                            <div className={scss.header}>
                              <p>Folio</p>
                              <p>Parcialidad</p>
                              <div className={scss.doubleHeader}>
                                <p>Saldo de la CxC</p>
                                <div>
                                  <p>MXN</p>
                                  <p>USD</p>
                                </div>
                              </div>
                              <div className={scss.doubleHeader}>
                                <p>Importe a aplicar a la CxC</p>
                                <div>
                                  <p>MXN</p>
                                  <p>USD</p>
                                </div>
                              </div>
                              <div>
                                Aplicar a ingreso{" "}
                                {context.account === null
                                  ? null
                                  : `en ${context.account.currency}`}
                              </div>
                              <div>TC</div>
                            </div>

                            {context.account === null ||
                            hook.state.customer === undefined ? null : (
                              <GridCxC
                                decimals={4}
                                search={{
                                  keys: [
                                    "invoice.folio",
                                    "invoice.socialReason",
                                    "invoice.uuid",
                                    "invoice.documnetNumber",
                                  ],
                                }}
                                refetch={hook.state.cxcs}
                                searchValue={hook.state.search}
                                onChange={hook.updateGridAssociations}
                                availableBalance={hook.state.ammount}
                                tolerance={0}
                                currency={context.account.currency}
                                inyectionGrids={async () => hook.state.cxcs}
                              >
                                {({
                                  InputBalance,
                                  i,
                                  element,
                                  InputDifferentCurrency,
                                }) => (
                                  <div
                                    className={scss.row}
                                    key={`${key.current}-${i}`}
                                  >
                                    <ModalInvoiceReception uuid={element.uuid}>
                                      {element.invoice.folio} - ({element.payForm})
                                    </ModalInvoiceReception>
                                    <div className="d-flex align-items-center justify-content-center">
                                      {element.partialitie}
                                    </div>
                                    <div className={scss.doubleInfo}>
                                      {element.currency === "MXN" ? (
                                        <p className="d-flex align-items-center justify-content-end">
                                          {element.newResidue.text}
                                        </p>
                                      ) : (
                                        <p className="d-flex align-items-center justify-content-center">
                                          -
                                        </p>
                                      )}

                                      {element.currency !== "USD" ? (
                                        <p className="d-flex align-items-center justify-content-center">
                                          -
                                        </p>
                                      ) : (
                                        <p className="d-flex align-items-center justify-content-end">
                                          {element.newResidue.text}
                                        </p>
                                      )}
                                    </div>
                                    <div className={scss.doubleInfo}>
                                      {element.currency === "MXN" ? (
                                        <p>
                                          <InputBalance />
                                        </p>
                                      ) : (
                                        <p className="d-flex justify-content-center align-items-center">
                                          -
                                        </p>
                                      )}
                                      {element.currency !== "USD" ? (
                                        <p className="d-flex justify-content-center align-items-center">
                                          -
                                        </p>
                                      ) : (
                                        <p>
                                          <InputBalance />
                                        </p>
                                      )}
                                    </div>
                                    {element.currency ===
                                    context.account.currency ? (
                                      element.applied.number > 0 ? (
                                        <div className="d-flex justify-content-end align-items-center px-2">
                                          {element.applied.text}
                                        </div>
                                      ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                          -
                                        </div>
                                      )
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center">
                                        <InputDifferentCurrency />
                                      </div>
                                    )}
                                    {element.tc.number <= 0 ? (
                                      <div className="d-flex justify-content-center align-items-center">
                                        -
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-end align-items-center px-2">
                                        {element.tc.text}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </GridCxC>
                            )}
                          </div>
                        </div>
                      ),
                    },
                    concepts: {
                      text: hook.isValidConceptsTab
                        ? "Conceptos"
                        : "Conceptos 🔴",
                      selected: false,
                      component: hook.canDisplayConcepts ? (
                        <IncomingsConcepts />
                      ) : (
                        <div>
                          <div className={scss.headerConcept}>
                            <p>Concepto</p>
                            <p>Deducible</p>
                            <p>Sin comprobante</p>
                          </div>
                        </div>
                      ),
                    },
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  color: "var(--primaryColor)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Completa los campos
              </div>
            )}

            <div className={scss.amountsInfo}>
              <AmmountInput />

              <InformativeAmount
                amount={hook.state.amounts.associated}
                label="Asociado"
              />
              <InformativeAmount
                amount={hook.state.amounts.toAssociate}
                label="No asociado"
              />

              <Factoraje/>
            </div>
          </div>
        </div>
      </div>
    </ContextAddIncoming.Provider>
  );
}
