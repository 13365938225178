import React, { useContext } from "react";
import AutocompleteEmail from "components/individual/AutocompleteEmail";
import { ContextEmail } from "..";

/**
 * Handle add an email to the email list
 * @param {import("./types").EmailAppendProps} props - Props
 * @returns {JSX.Element}
 */
export const EmailAppend = ({ type = "to", idCustomer = null , aditionalEmails = [] }) => {
  const hook = useContext(ContextEmail);

  const updateEmails = (emails) => {
    if (type === "to") {
      hook.appendTo(emails);
    } else {
      hook.appendCc(emails);
    }
  };

  if (type === "cc")
    return (
      <AutocompleteEmail
        idCustomer={idCustomer}
        label={"CC"}
        aditionalEmails={aditionalEmails}
        onChange={updateEmails}
      />
    );

  return (
    <AutocompleteEmail
      idCustomer={idCustomer}
      label={"TO"}
      onChange={updateEmails}
      aditionalEmails={aditionalEmails}
    />
  );

  // return (
  //   <ContainerTo>
  //     <Label required={isValidRequired}>{type.toLocaleUpperCase()}</Label>
  //     <ContainerInputEmail>
  //       <input
  //         ref={refEmailInput}
  //         type="email"
  //         onChange={(e) => handleEmail(e.target.value)}
  //         onKeyDownCapture={appendKeyEnter}
  //         placeholder={
  //           isValidRequired ? "Escribe aquí (Obligatorio)" : "Escribe aquí"
  //         }
  //       />
  //       <ButtonV2
  //         disabled={state.isValid === false ? true : false}
  //         onClick={appendOnClickBtn}
  //       >
  //         <span>+</span>
  //         <Icon icon="plus" />
  //       </ButtonV2>
  //     </ContainerInputEmail>
  //   </ContainerTo>
  // );
};
