import * as yup from "yup";

const creditDays = yup
.number()
.required('Campo requerido')
.positive('Los dias de credito deben ser 0 o mas')
.integer('Solo numeros enteros');

export const schemaValidation = yup.object().shape({
    creditDays
});