import React, { createContext } from "react";
import usePermissions from "customHooks/usePermissions";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useAdvertisements from "customHooks/useAdvertisements";
import SearchAdvertisement from "./SearchAdvertisement";
import Create from "./Create";
import style from "./Avisos.module.scss";
import Table from "./Table";
import Options from "./Options";

/**
 * @type {import("react").Context<import("customHooks/useAdvertisements/types").ReturnUseAdvertisement|null>}
 */
export const ContextAdvertisement = createContext(null);

export default function Avisos() {
  const hook = useAdvertisements();

  usePermissions("2e1d85de-44e6-4b14-800f-0452f090681d");

  return (
    <div className={style.containerAdvertisements}>
      <ContextAdvertisement.Provider value={hook}>
        <div className={style.headerAdvertisements}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={style.filter}>
            <SearchAdvertisement />
            <Create onCreated={hook.triggerFetchSearch} />
          </div>
        </div>

        <Options />

        <Table />
      </ContextAdvertisement.Provider>
    </div>
  );
}
