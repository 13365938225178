import { ContextReception } from "pages/Proyects/Reception";
import React, { useContext } from "react";

export default function ErrorFieldReception({ id = 0, received = 0 , total = 0 }) {
  const hook = useContext(ContextReception);

  if (id === 0) return <></>;

  if (hook.grid[id] === undefined) return <></>;

  if (hook.grid[id] > (total-received))
    return <p className="text-danger font-weight-bold mt-2">🔴 Máximo {total-received}</p>;

  return <></>;
}
