import React, { useContext } from "react";
import { ContextEmitedCreditNotes } from "..";
import DownloadXml from "./DownloadXml";
import style from "../styles.module.scss";
import DownloadPdf from "./DownloadPdf";
import CancelCreditNoteEmited from "structure/CancelCreditNoteEmited";
import LinkCreditNoteAssociatedFiles from "./AssociatedFiles";

const Container = ({ children }) => (
  <div className={style.containerOptionsCreditNote}>{children}</div>
);

export default function CreditNoteOptions() {
  const hook = useContext(ContextEmitedCreditNotes);

  if (hook.creditNote === undefined) return <Container><></></Container>;

  return (
    <Container>
      <LinkCreditNoteAssociatedFiles />
      <DownloadXml />
      <DownloadPdf />
      <CancelCreditNoteEmited
        uuidCreditNote={hook.creditNote.uuid}
        isCancelled={
          hook.creditNote.status.id === 15 || hook.creditNote.status.id === null
        }
        onCancelated={() => hook.refetchTable()}
      />
    </Container>
  );
}
