import { Modal } from "components/general/Modal";
import { mnCurrency } from "helpers/money";
import React from "react";
import { useState } from "react";
import scss from "./styles.module.scss";

export const OverviewConceptEgressInvoice = ({ grid = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <span onClick={() => setIsOpen(true)} className={scss.anchor}>
        Ver resumen de asociaciones
      </span>

      <Modal
        title="Resumen - Asociacion de facturas recibidas por concepto de gastos"
        displayButton={false}
        isOpen={isOpen}
        width={85}
        onCloseModal={() => setIsOpen(false)}
      >
        {() =>
          grid.length === 0 ? (
            <h1 className="text-center py-5">
              No has realizado ninguna asociacion
            </h1>
          ) : (
            <>
              <div className={scss.header}>
                <p>Proveedor</p>
                <p>Factura</p>
                <p>Concepto</p>
                <p>Parcialidad</p>
                <p>Moneda</p>
                <p>Total</p>
                <p>Pagos previos</p>
                <p>Pago a aplicar</p>
                <p>TC</p>
                <p>Saldo CxP</p>
              </div>
              {grid.map((item) => (
                <div className={scss.row} key={`element-${item.id}`}>
                  <p>{item.invoice.socialReason}</p>

                  <p>{item.invoice.folio}</p>

                  <p>{item.egress.description}</p>

                  <p className="text-center">??</p>

                  <p className="text-center">{item.invoice.currency}</p>

                  <p className="text-right">{item.total.text}</p>

                  <p className="text-right">
                    {mnCurrency(item.total.number - item.residue.number)}
                  </p>

                  <div className="text-right">{item.import.text}</div>

                  <div className="text-right">{item.tc.text}</div>

                  <div className="text-right">{item.newResidue.text}</div>
                </div>
              ))}
            </>
          )
        }
      </Modal>
    </>
  );
};
