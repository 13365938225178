import React from "react";
import CardDocRelated, { EmptyCardMoreInfo } from "../CardDocRelated";
import { ContextDocsRelated } from "..";
import { useContext } from "react";
import { parseDateToText } from "helpers/dates";

export default function Contrato() {
  const hook = useContext(ContextDocsRelated);

  const contract = hook.popUpInfo[6];

  if (contract === undefined || contract === null)
    return <EmptyCardMoreInfo label="contrato" />;

  return (
    <CardDocRelated
      id={contract.id}
      folio={contract.docNumber}
      endDate={parseDateToText(new Date(`${contract.expiration.yyyymmdd}:`)) }
      customer={contract.customer.commercialName}
      startDate={parseDateToText(new Date(`${contract.startDate.yyyymmdd}:`))}
      email={contract.customer.email}
      phone={contract.customer.phone}
      importe={contract.amount.subtotal.text}
      iva={contract.amount.iva.text}
      total={contract.amount.total.text}
      currency={contract.currency.code}
      label="Contrato"
      concept={contract.concept?.description||"ND"}
      clave={contract.contract}
      uen={contract.items}
    />
  );
}
