import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useMaterials from "customHooks/useMaterials";
import React, { createContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./Header";
import Grid from "./Grid";
import ui from './styles.module.scss';

/**
 * @type {import("./types").ContextMaterial}
 */
export const ContextMaterials = createContext(undefined);

export default function Materials() {
  const { idPosition } = useParams();

  const materials = useMaterials(+idPosition);

  return (
    <ContextMaterials.Provider value={materials}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/proyectos",
            text: "Proyectos",
          },
          {
            route: "/proyectos",
            text: "Posición",
          },
          {
            route: "/",
            text: "Materiales",
          },
        ]}
      />

      <div className={ui.container}>
        <Header />
        <Grid/>
      </div>
    </ContextMaterials.Provider>
  );
}
