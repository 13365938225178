import React, { useContext } from "react";

import { BankAccountContext } from "..";
import { Label } from "structure/Document";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { add } from "date-fns";

import {
  getFirstDayOfMonth,
  getLastDayOfDate,
  getLastDayOfPreviousMonth,
  parseDateToText,
} from "helpers/dates";
import InformativeTooltip from "components/individual/InfoTooltip";

registerLocale("es", es);

export const BankDate = () => {
  const { isValidDate, updateDate, id, account } =
    useContext(BankAccountContext);

  return (
    <div>
      <div className="d-flex">
        <Label required={!isValidDate}>Fecha último cierre</Label>
        <InformativeTooltip>
          Los ingresos/egresos de esta cuenta pueden ser agregados con fecha
          mayor o igual{" "}
          <b>
            {parseDateToText(
              getFirstDayOfMonth(
                add(new Date(account.closingBalanceDate), {
                  months: 1,
                })
              )
            )}
          </b>
        </InformativeTooltip>
      </div>
      {id === null ? (
        <>
          <ReactDatePicker
            onChange={(date) => updateDate(getLastDayOfDate(date))}
            locale="es"
            selected={new Date(`${account.closingBalanceDate}:`)}
            dateFormat="MMM/yyyy"
            showMonthYearPicker
            maxDate={getLastDayOfPreviousMonth()}
          />
        </>
      ) : (
        <p>{parseDateToText(new Date(`${account.closingBalanceDate}:`))}</p>
      )}
    </div>
  );
};
