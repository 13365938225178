import React, { useContext } from "react";
import { Label } from "structure/Document";
import { RfcContext } from "..";
import InformativeTooltip from "components/individual/InfoTooltip";
import { copyOnClipboard } from "helpers/dom";

export default function LabelRfc() {
  const hook = useContext(RfcContext);

  return (
    <div className="d-flex align-align-items-center">
      <Label required={!hook.isValid}>RFC</Label>
      <span>{hook.rfc.length}/13</span>
      {/* <InformativeTooltip>
        <ul>
          <li>Copiar RFC genérico</li>
          <li>Copiar RFC extranjero</li>
        </ul>
      </InformativeTooltip> */}
    </div>
  );
}
