import useDialog from "customHooks/useDialog";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

/**
 * @type {import("./types").StateProfile}
 */
const STATE = {
  canRenderProfilePicture: false,
  profilePicture: null,
  isOpenEdit: false,
};

/**
 * @type {import("./types").ContextProfile}
 */
export const STATE_CONTEXT_PROFILE = {
  ...STATE,
  setCanRenderProfilePicture: () => {},
  profilePicRef: undefined,
  dialogEdit: undefined,
  close:()=>{},
  open:()=>{},
  canRenderProfilePicture:false,
  isOpenEdit:false,
  profilePicture:null
};

/**
 * Hook to handle the profile of the user
 * @returns {import("./types").ReturnUseProfile}
 */
export default function useProfileV2() {
  const dialogEdit = useDialog();
  const [state, setState] = useState(STATE);
  const profilePicRef = useRef(null);
  useEffect(() => {
    if (!state.canRenderProfilePicture) return;

    setState((current) => ({
      ...current,
      profilePicture: profilePicRef.current.props.image,
    }));
  }, [state.canRenderProfilePicture]);

  useEffect(() => {
    if (state.isOpenEdit) dialogEdit.open();
  }, [state.isOpenEdit]);

  /**
   * Set the state in order to display the profile picture
   * @param {boolean} canRenderProfilePicture - boolean
   * @returns {void}
   */
  const setCanRenderProfilePicture = (canRenderProfilePicture) =>
    setState((current) => ({
      ...current,
      canRenderProfilePicture,
    }));

  const open = () =>
    setState((current) => ({
      ...current,
      isOpenEdit: true,
    }));

  const close = () =>
    setState((current) => ({
      ...current,
      isOpenEdit: false,
    }));

  return {
    profilePicRef,
    open,
    close,
    ...state,
    setCanRenderProfilePicture,
    dialogEdit,
  };
}
