import React, { useRef } from "react";
import { useContext } from "react";
import { ContextTc } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "../styles.module.scss";
import {
  getDateFromUtc,
  parseDateToText,
  parseDateToTime,
} from "helpers/dates";
import { mnCurrency } from "helpers/money";
import { Label } from "structure/Document";

export default function TcTable() {
  const hook = useContext(ContextTc);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return <Spinner idSpinner="loadingTc" hidden={false} text="Cargando" />;

  if (hook.tcs.length <= 0 && hook.searched === false)
    return (
      <h1 className="text-center my-5">No hay tipos de cambio en el sistema</h1>
    );

  if (hook.tcs.length <= 0 && hook.searched === true)
    return (
      <h1 className="text-center my-5">No hay resultados con la búsqueda</h1>
    );

  return (
    <>
      {hook.tcs.map((tc, i) => (
        <div key={`${key.current}-item-${i}-mobile`} className={style.tcMobile}>
          <Label>Creación</Label>
          <p className={style.tcCreatedBy}>
            <span>{tc.createdBy} el</span>
            <span>
              {parseDateToText(getDateFromUtc(tc.date.full), "medium")}
            </span>
            <span>
              {parseDateToTime(getDateFromUtc(tc.date.full), "short")}
            </span>
          </p>

          <div className={style.ammounts}>
            <div>
              <Label>DOF</Label>
              <p>{mnCurrency(tc.dof.number, 4)}</p>
            </div>

            <div>
              <Label>Pagos</Label>
              <p>{mnCurrency(tc.pays.number, 4)}</p>
            </div>

            <div>
              <Label>Empresa</Label>
              <p>{mnCurrency(tc.enterprise.number, 4)}</p>
            </div>

            <div>
              <Label>Venta (Banamex)</Label>
              <p>{mnCurrency(tc.sales.number, 4)}</p>
            </div>

            <div>
              <Label>Compra</Label>
              <p>{mnCurrency(tc.purchase.number, 4)}</p>
            </div>
          </div>
        </div>
      ))}

      <table className={style.table}>
        <thead>
          <tr>
            <th>Creado por</th>
            <th>Fecha</th>
            <th>DOF</th>
            <th>Pagos</th>
            <th>Empresa</th>
            <th>Venta (Banamex)</th>
            <th>Compra</th>
          </tr>
        </thead>
        <tbody>
          {hook.tcs.map((tc, i) => (
            <tr key={`${key.current}-item-${i}`}>
              <td>{tc.createdBy}</td>
              <td>
                {parseDateToText(getDateFromUtc(tc.date.full), "medium")}{" "}
                {parseDateToTime(getDateFromUtc(tc.date.full), "short")}
              </td>
              <td>{mnCurrency(tc.dof.number, 4)}</td>
              <td>{mnCurrency(tc.pays.number, 4)}</td>
              <td>{mnCurrency(tc.enterprise.number, 4)}</td>
              <td>{mnCurrency(tc.sales.number, 4)}</td>
              <td>{mnCurrency(tc.purchase.number, 4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
