import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React from "react";
import useAccountingOc from "./useAccountingOc";
import { createContext } from "react";
import Table from "./Table";
import ui from "./styles.module.scss";
import Filters from "./Filters";
import Options from "./Options";

/**
 * @type {import("react").Context<import("./types").ContextAccountingOc|undefined>}
 */
export const ContextAccountingOc = createContext(undefined);

export default function AccountingOc() {
  const hook = useAccountingOc();

  return (
    <ContextAccountingOc.Provider value={hook}>
      <div className={ui.container}>
        <div className={ui.header}>
          <BreadCrumV2 path={hook.breadcrum} />

          <Filters />
        </div>

        <Options />
        <Table />
      </div>
    </ContextAccountingOc.Provider>
  );
}
