import { Select } from "components/individual/inputs/Inputs";
import React, { createContext } from "react";
import { useCancelInvoice } from "../Hooks/useCancelInvoice";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import ui from "./styles.module.scss";
import Dialog from "structure/Dialog";
import { parseToFolio } from "helpers/documents";
import OverviewInvoiceReplace from "./OverviewInvoiceReplace";
import CancelButton from "./CancelButton";
import CancelationWarn from "./CancelationWarn";

/**
 * @type {import("react").Context<import("../Hooks/types").ReturnUseCancelation|undefined>}
 */
export const ContextCancelInvoice = createContext(undefined);

/**
 * Cancel invoice button
 * @param {import("./types").PropsCancelInvoice} props - Props
 * @returns {JSX.Element}
 */
export const CancelInvoiceButton = ({
  uuid,
  onCancelled = () => {},
  children = null,
  forwardRef = undefined,
}) => {
  const hook = useCancelInvoice(uuid, onCancelled);

  const { dialog, setOpenModal, state, handleSelect } = hook;

  return (
    <ContextCancelInvoice.Provider value={hook}>
      <div>
        {children === null ? (
          <ButtonV2 onClick={() => setOpenModal(true)}>
            <span>Cancelar</span>
          </ButtonV2>
        ) : (
          <div ref={forwardRef} onClick={() => setOpenModal(true)}>
            {children}
          </div>
        )}

        {state.isModalOpen ? (
          <Dialog
            title={`¿Seguro que quieres cancelar la factura emitida ${
              state.invoice === undefined
                ? ""
                : parseToFolio(+state.invoice.folio)
            } ?`}
            forwardRef={dialog.refDialog}
            width={
              hook.state.invoiceToReplace !== null &&
              hook.state.invoiceToReplace !== undefined
                ? 75
                : 50
            }
            footer={
              <div className="d-flex justify-content-between align-items-center">
                <CancelationWarn />
                <CancelButton />
              </div>
            }
            closeCallback={() => setOpenModal(false)}
          >
            <div className={ui.cancelContainer}>
              <Select
                addTodos={true}
                initialOption="-- SELECCIONA --"
                disableInitlaOption={true}
                idSelect={window.crypto.randomUUID()}
                label="Motivo de cancelación"                
                options={state.motivos}
                textJSON="description"
                valueJSON="id"
                onChange={handleSelect}
                value={state.motiveId}
              />
              <OverviewInvoiceReplace />
            </div>
          </Dialog>
        ) : null}
      </div>
    </ContextCancelInvoice.Provider>
  );
};
