import useDialog from "customHooks/useDialog";
import ocManagment from "helpers/Apis/purchases/ocManagment";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateCommentOc}
 */
const INITIAL_STATE = {
  isOpen: false,
  isLoading: true,
  comments: [],
  isCommenting: false,
  comment: "",
  mobileComment:"",
  refetch: false,
};

/**
 * Handle the comments of the OC document
 * @param {number} id - Id of the oc to fetch his comments
 * @returns {import("./types").ReturnCommentOc}
 */
export default function useCommentOc(id) {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  /**
   * Set the comment
   * @param {string} comment - Set the comment attempting to add
   * @returns {void}
   */
  const setComment = (comment) =>{
    setState((current) => ({
      ...current,
      comment,
      mobileComment:comment
    }));
  }
    

  useEffect(() => {
    (async function () {
      if (!state.isOpen) return;

      const api = await ocManagment.comment.get(id);

      setState((current) => ({
        ...current,
        isLoading: false,
        comments: api,
      }));
    })();
  }, [state.isOpen, id, state.refetch]);

  const open = () => {
    setState((current) => ({
      ...current,
      isOpen: true,
    }));

    dialog.open();
  };

  const close = () => {
    setState(INITIAL_STATE);

    dialog.close();
  };

  /**
   * Put a comment into the record of the `OC`
   * @param {boolean} [withEmail=false]
   * @returns {Promise<void>}
   */
  const addComment = async (withEmail = false) => {
    setState((current) => ({
      ...current,
      isCommenting: true,
    }));

    const wasCommented = await ocManagment.comment.add(
      id,
      state.comment,
      withEmail
    );

    setState((current) => ({
      ...current,
      isCommenting: false,
      comment: wasCommented ? "" : current.comment,
      mobileComment: wasCommented ? "" : current.comment,
      refetch: wasCommented ? !current.refetch : current.refetch,
    }));
  };

  return {
    ...state,
    open,
    dialog,
    close,
    setComment,
    addComment,
  };
}
