import ButtonV2 from "components/individual/ButtonsV2/Button";
import { DirectoryContext } from "pages/Directory";
import React from "react";
import { useContext } from "react";

export default function DocumentsDirectory() {

  const hook = useContext(DirectoryContext);

  if (hook.selected === null) return <></>;

  return <ButtonV2 onClick={hook.redirectToDocuments}>Documentos</ButtonV2>;
}
