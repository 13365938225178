import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import PillFilterMobile from "components/individual/PillFilterMobile";
import React, { useContext } from "react";
import ui from "./styles.module.scss";
import { ContextReceiveCredtNotes } from "..";
import { parseDateToText } from "helpers/dates";

const STATUS_LABEL = {
  17: "Canceladas",
  16: "Recibidas",
};

export default function FilterMobile() {
  const hook = useContext(ContextReceiveCredtNotes);

  return (
    <div className={ui.filter}>
      <ButtonV2>
        <Icon icon="filter" />
      </ButtonV2>

      <PillFilterMobile
        label="Estatus"
        value={STATUS_LABEL[hook.status] || "Todas"}
      />
      <PillFilterMobile
        label="Inicio"
        value={parseDateToText(hook.beginDate)}
      />
      <PillFilterMobile label="Fin" value={parseDateToText(hook.endDate)} />
    </div>
  );
}
