import React from "react";
import { useContext } from "react";
import WinQuoteContext from "../../ContextWinQuote";
import { DateContainers, DateLabel } from "../Containers";
import { InputDatePicker } from "components/Elements/InputDatePicker";
import "./styles.css";
import { formateDate } from "helpers/dates";

export const OdcDateSection = () => {
  const isDisable = true;
  const { documents, handleOdcSendDate, handleOdcReminderDate } =
    useContext(WinQuoteContext);

  return (
    <>
      <DateContainers disable={true}>
        <DateLabel>Creación</DateLabel>
        <input
          type="text"
          className="disable"
          value={formateDate(new Date())}
          disabled
        />
      </DateContainers>

      <DateContainers>
        <DateLabel>Enviar</DateLabel>
        <InputDatePicker
          placeholderText="31/ene/2023"
          selectedDate={documents.odc.dates?.sendDate}
          handleDateChange={handleOdcSendDate}
          disable={isDisable}
          minDate={new Date()}
        />
      </DateContainers>
      <DateContainers>
        <DateLabel>Recordatorio</DateLabel>
        <InputDatePicker
          placeholderText="31/ene/2023"
          selectedDate={documents.odc.dates?.reminderDate}
          handleDateChange={handleOdcReminderDate}
          disable={isDisable}
          minDate={new Date()}
          maxDate={documents.odc.dates?.sendDate}
        />
      </DateContainers>
    </>
  );
};
