import React from "react";
import { InputText } from "components/individual/inputs/Inputs";

export default function Description({
  isValid,
  updateDescription,
  description,
}) {
  return (
    <>
      <InputText
        labelTxt="Descripcion *"
        placeholderTxt="Escribe el nombre del rol"
        aditionalClasses=""
        name="rol"
        id="rol"
        onChange={updateDescription}
        defaultValue={description}
      />
      {!isValid ? <p className="text-danger">Solo letras</p> : null}
    </>
  );
}
