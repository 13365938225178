import React, { useEffect, useState , useRef} from "react";
import CalculateMode from "./CalculateMode";
import Partialitie from "./Partialitie";
import PartialitieImport from "./PartialitieImport";
import { Button } from "../../../../../components/individual/buttons/Buttons";
import usePartilities from "../../../../../customHooks/usePartialities";
import { ContainerPartialities } from "./Styles";
import { mnCurrency } from "../../../../../helpers/money";
import useReminderDays from "../../../../../customHooks/useReminderDays";
import { addDays, sub } from "date-fns";
import { substractDays } from "helpers/dates";
import ui from './styles.module.scss';

/**
 * Create the partialities info to use on the invoice
 *
 * @component GridPartialities
 * @param {object} props - Props component
 * @param {string} [props.textBtn='Facturar'] - Text
 * @param {number} [props.partialities=1] - Number of partialities to use on the invoice
 * @param {number} props.totalAmountPreinvoice - Total amount of the preinvoice (includes iva and discounts applied)
 * @param {Function} [props.onConfirm=()=>{}] - Executes when the user confirms the partialities info
 * @param {number} [props.tc=null] - TC used on the calculation mode
 * @param {Date} [props.beginDate=new Date()] - Date to use when partialitie
 * @param {string} [props.currency=null] - Informative currency that's being used for the grid
 * @param {boolean} [props.disableDatesRestriction=false] - Disable the dates restriction
 * @param {(object:import("types/typedef/customHooks/usePartialities").onChangePartialitieI)=>void} [props.onChange=()=>{}] - Information when the grid changes
 * @returns {JSX.Element} React component
 */
function GridPartialities({
  textBtn = "Facturar",
  partialities = 1,
  totalAmountPreinvoice,
  onConfirm = () => {},
  onChange = () => {},
  beginDate = new Date(),
  tc = null,
  currency = null,
  disableDatesRestriction = false
}) {
  const [partialitiesInfo, setPartialitiesInfo] = useState([]);

  const key = useRef(window.crypto.randomUUID());

  const [reminderDays] = useReminderDays();

  const [calculateMode, setCalculateMode] = useState(1);

  const [importsMatch, setImportsMatch] = useState(true);

  const {
    updatePartialities,
    updatePartialitiesDate,
    validateCorrectPercentages,
    createGrid,
    updatePartialitiesImport,
  } = usePartilities(
    partialitiesInfo,
    partialities,
    totalAmountPreinvoice,
    reminderDays
  );

  useEffect(() => {
    if (reminderDays === null) {
      return;
    }

    const grid = createGrid(
      totalAmountPreinvoice,
      partialities,
      calculateMode,
      tc,
      beginDate
    );

    setPartialitiesInfo(grid);
  }, [partialities, calculateMode, reminderDays, totalAmountPreinvoice]);

  useEffect(() => {
    if (partialitiesInfo.length > 0) {
      const { importsMatch } = validateCorrectPercentages(partialitiesInfo);
      setImportsMatch(importsMatch);
      onChange({
        partialitiesInfo,
        isValid: importsMatch,
      });
    }
  }, [partialitiesInfo]);

  const resetDate = (date) => {
    const partialities = updatePartialitiesDate(date);
    setPartialitiesInfo(partialities);
  };

  return (
    <div className="containerGridPartialities">
      <div className={ui.overviewGrid}>
        <p className="m-0">
          <b>Importe total:</b> {mnCurrency(totalAmountPreinvoice)}{" "}
          {currency !== null ? currency : null}
        </p>

        <CalculateMode
          value={calculateMode}
          onChange={(value) => setCalculateMode(value)}
        />
      </div>

      {!importsMatch ? (
        <p className="text-danger font-weight-bold my-2">
          Asegurate de poner parcialidades coherentes/validas
        </p>
      ) : null}

      {calculateMode === 1 ? (
        <ContainerPartialities>
          {partialitiesInfo.map((partialitie, i) => (
            <div key={`calculoPorImporte-${key.current}-${i}`} className={i === 0 ? "" : "na"}>
              <Partialitie
              disableDatesRestriction={disableDatesRestriction}
                maxDate={
                  disableDatesRestriction ? new Date('2200-01-01'):

                  i + 1 < partialitiesInfo.length
                    ? sub(new Date(partialitiesInfo[i + 1].date), { days: 1 })
                    : addDays(new Date(partialitiesInfo[i].date), 30)
                }
                minDate={
                  disableDatesRestriction ? new Date('2000-01-01'):
                  i - 1 > 0
                    ? addDays(new Date(partialitiesInfo[i - 1].date), 1)
                    : i - 1 !== -1
                    ? addDays(new Date(partialitiesInfo[i - 1].date), 1)
                    : substractDays(new Date,1)
                }
                resetDate={resetDate}
                noPartialitie={partialitie.noPartialitie}
                price={partialitie.price}
                percentage={partialitie.percentage}
                date={partialitie.date}
                onChange={(partialitie) => {
                  const partialities = updatePartialities(partialitie);
                  setPartialitiesInfo(partialities);
                }}
              />
            </div>
          ))}
        </ContainerPartialities>
      ) : (
        <ContainerPartialities>
          {partialitiesInfo.map((partialitie, i) => (
            <div key={`calculoPorPorcentaje-${key.current}-${i}`} className={i === 0 ? "" : "na"}>
              <PartialitieImport
              disableDatesRestriction={disableDatesRestriction}
                maxDate={

                  disableDatesRestriction ? new Date('2200-01-01'):

                  i + 1 < partialitiesInfo.length
                    ? sub(new Date(partialitiesInfo[i + 1].date), { days: 1 })
                    : addDays(new Date(partialitiesInfo[i].date), 30)
                }
                minDate={

                  disableDatesRestriction ? new Date('2000-01-01'):


                  i - 1 > 0
                    ? addDays(new Date(partialitiesInfo[i - 1].date), 1)
                    : i - 1 !== -1
                    ? addDays(new Date(partialitiesInfo[i - 1].date), 1)
                    : substractDays(new Date(),1)
                }
                resetDate={resetDate}
                noPartialitie={partialitie.noPartialitie}
                price={partialitie.price}
                percentage={partialitie.percentage}
                date={partialitie.date}
                onChange={(partialitie) => {
                  const partialities = updatePartialitiesImport(partialitie);
                  setPartialitiesInfo(partialities);
                }}
              />
            </div>
          ))}
        </ContainerPartialities>
      )}

      <div className="d-flex justify-content-end mt-3 mb-0">
        {importsMatch ? (
          <Button
            funcion={() => onConfirm(partialitiesInfo)}
            text={textBtn}
            aditionalClasses="d-none"
            id="confirmPartialities"
          />
        ) : null}
      </div>
    </div>
  );
}

GridPartialities.propTypes = {};

export default GridPartialities;
