import React, { useContext } from "react";
import SocialReason from "components/general/Forms/Selects/SocialReason";
import { ContextResidueAndMovements } from "..";
import { Label } from "structure/Document";

export default function ComboSocialReason() {
  const hook = useContext(ContextResidueAndMovements);

  return (
    <div>
      <Label required={typeof hook.idCustomerProvider !== "number"}>
        {hook.type === "compras" ? "Proveedor" : "Cliente"}
      </Label>
      <SocialReason
        type={hook.type === "ventas" ? "cliente" : "recepcionFactura"}
        includeNoActives={false}
        onChange={(customer) => hook.setCustomerProvider(customer.id,customer.socialReason)}
      />
    </div>
  );
}
