import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";

import { registerLocale } from "react-datepicker";

import { addDays } from "date-fns";

import es from "date-fns/locale/es";

export default function DatePartialitieV2({
  initialDate = new Date(),
  onChange = () => {},
  maxDate,
  minDate,
}) {
  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  const updateDate = (date) => {
    setDate(date);
    onChange(date);
  };

  registerLocale("es", es);

  return (
    <div>
      <label>Fecha vencimiento</label>

      <DatePicker
        id="firstPartialitie"
        name="firstPartialitie"
        selected={new Date(date)}
        locale="es"
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        dateFormat="dd/MMM/yyyy"
        onChange={updateDate}
        withPortal={true}
      />
    </div>
  );
}
