import React from "react";
import { useContext } from "react";
import { registerLocale } from "react-datepicker";
import { Label } from "structure/Document";
import { BankAccountReportContext } from "..";
import es from "date-fns/locale/es";
import Select from "react-select";
import YearSelect from "components/general/Forms/Selects/Year";
import MonthSelect from "components/general/Forms/Selects/Month";
registerLocale("es", es);

export default function RangeDate() {
  const hookContext = useContext(BankAccountReportContext);

  return (
    <>
      <YearSelect onChange={hookContext.setYear} from={hookContext.minDate.getFullYear()} />
      <MonthSelect onChange={hookContext.setMonth}/>
    </>
  );

  return (
    <div>
      {/* <Label required={hookContext.bankAccount === null}>Periodo</Label> */}
      {hookContext.bankAccount === null ? (
        <p>Selecciona una cuenta de banco</p>
      ) : (
        <div className="d-flex">
          <YearSelect from={hookContext.minDate.getFullYear()} />
          <MonthSelect />
        </div>

        // <Select
        //   classNamePrefix="saiko-select"
        //   className="saiko-select"
        //   placeholder="Selecciona"
        //   isSearchable={true}
        //   onChange={(data) => {
        //     hookContext.updateDateSelected(data.value);
        //     console.log(data.value)
        //   }}
        //   defaultValue={hookContext.options[0]}
        //   options={hookContext.options}
        // />
      )}
    </div>
  );
}
