import React from "react";
import style from "./styles.module.scss";

export default function LoadingText(props) {
  return (
    <div className={style.loading} {...props}>
      {props.children}
    </div>
  );
}
