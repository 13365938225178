import LoginContext from "context/Login/LoginContext";
import React, { createContext, useContext, useEffect, useState } from "react";
import EditProfilePicture from "./Edition";
import PreviewPhoto from "./Preview";

/**
 * Context to handle the image updated
 * @type {import("react").Context<import("./types").ProfilePictureContextI>}
 */
export const ProfilePictureContext = createContext();
const { Provider } = ProfilePictureContext;

/**
 * Handle the preview photo of the user in order preview it and edit it
 * @param {object} props - Props
 * @param {(picture:import("./types").onSaveProfilePictureI)=>void} props.onUpdate - Information of the image updated/selected in the case user edited it
 * @returns {JSX.Element}
 */
export default function ProfilePicture({ onUpdate = () => {} }) {
  const { profilePicture } = useContext(LoginContext);

  /**
   * State of the component
   * @type {[import("./types").StateI,()=>void]}
   */
  const [img, setImg] = useState({
    showEdition: false,
    scale: profilePicture.scale,
    position: profilePicture.position,
    img: profilePicture.img,
  });

  useEffect(() => {
    onUpdate({
      scale: img.scale,
      position: img.position,
      img: img.img,
    });
  }, [img]);

  /**
   * Update the profile picture (just on client side)
   * @param {import("./types").onSaveProfilePictureI} picture
   */
  const handleUpdateProfilePicture = (picture) => {
    setImg({
      showEdition: false,
      ...picture,
    });
  };

  /**
   * Update the position to cut the image
   * @param {Position} position - Position of the image to cut
   */
  const handlePositionChange = (position) => {
    setImg({ ...img, position });
  };

  /**
   * Set the flag in order to display the image or the ui for the image edition
   * @param {boolean} showEdition - Flag in order to show edition/preview
   * @returns {void}
   */
  const showEdition = (showEdition) =>
    setImg({
      ...img,
      showEdition,
    });

  /**
   * Event when the scale bar is changed
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event
   */
  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setImg({
      ...img,
      scale,
    });
  };

  return (
    <Provider
      value={{
        img,
        showEdition,
        handleScale,
        handlePositionChange,
        handleUpdateProfilePicture,
      }}
    >
      {img.showEdition === true ? (
        <EditProfilePicture
          currentProfilePicture={img.img}
          position={img.position}
          scale={+img.scale}
          onSave={handleUpdateProfilePicture}
        />
      ) : (
        <PreviewPhoto currentProfilePicture={img} />
      )}
    </Provider>
  );
}
