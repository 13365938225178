import React, { useEffect, useState } from "react";

import { getBankAccount } from "../../helpers/Apis/Banks";

import PropTypes from "prop-types";
import { Spinner } from "components/individual/Spinner/Spinner";

/**
 * Print the information of the bank account
 *
 * @param {number} idAccount - ID of requested bank
 * @param {string} text - Put some text before the account info
 */
function BankInformation({ idAccount = null, text = "", infoRow }) {
  const [info, setInfo] = useState({
    shortName: "",
    noAccount: "",
    currency: "",
  });

  const isLoading = info.shortName === "" ? true : false;

  useEffect(() => {
    async function initialLoad() {
      if (idAccount === null) return;

      const fetched = await getBankAccount(idAccount);

      const parsedInfo = {
        ...fetched,
        shortName: fetched.shortName,
        noAccount: fetched.accountNumber,
        currency: fetched.currencyCode,
      };

      setInfo(parsedInfo);
    }

    initialLoad();
  }, [idAccount]);

  if (idAccount === null) return <></>;

  return (
    <div className="containerInfoBank">
      {isLoading ? (
        <Spinner hidden={false} text="Cargando informacion del banco..." />
      ) : (
        <>
          <h4>Información de la cuenta bancaria</h4>
          <p className="bank">
            <span>{info.shortName} | </span>
            <span>{info.noAccount} | </span>
            <span>{info.currency} </span>
          </p>
        </>
      )}
    </div>
  );
}

export default BankInformation;

BankInformation.propTypes = {
  /**
   * ID of requested bank
   */
  idAccount: PropTypes.number.isRequired,

  /**
   * Put some text before the account info
   */
  text: PropTypes.string,
};
