import React, { useContext, useRef } from "react";
import { ContextGestionOc } from "../..";

/**
 * Handle the checkboxes for the records that requires it
 * @param {import("helpers/Apis/purchases/ocManagment/types").OcManagment} record - Information of the oc
 * @returns {JSX.Element}
 */
export default function Checkbox(record) {
  const hook = useContext(ContextGestionOc);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if(!hook.canDisplayCheckbox) return <></>

  return (
    <label
      className="d-flex align-items-center justify-content-center"
      htmlFor={`${key.current}-record-cfSigned-${record.idOdc}`}
    >
      <input
        onChange={(e) =>
          hook.toggleCheckboxRecord(record.idOdc, e.target.checked)
        }
        type="checkbox"
        id={`${key.current}-record-cfSigned-${record.idOdc}`}
      />
    </label>
  );
}
