import React, { createContext } from "react";
import Dialog from "structure/Dialog";
import useCommentOc from "./useCommentOc";
import { Spinner } from "components/individual/Spinner/Spinner";
import CommentList from "./CommentList";
import ui from "./styles.module.scss";
import { parseToFolio } from "helpers/documents";
import TextBox from "./TextBox";

/**
 * @type {import("react").Context<undefined|import("./useCommentOc/types").ContextCommentOc>}
 */
export const ContextCommentOc = createContext(undefined);

/**
 * Render a dialog to put comments on the OC
 * @param {import("./types").PropsCommentOc} props - Props
 * @returns {JSX.Element}
 */
export default function Comment({
  children = <></>,
  documentNumber = "???",
  id = 0,
  forwaredRef = undefined
}) {
  const hook = useCommentOc(id);

  return (
    <ContextCommentOc.Provider value={{ ...hook, documentNumber }}>
      <div ref={forwaredRef} onClick={hook.open}>{children}</div>

      <Dialog
        title={`Comentario | OC #${
          typeof documentNumber === "number"
            ? parseToFolio(documentNumber)
            : documentNumber
        }`}
        forwardRef={hook.dialog.refDialog}
        width={65}
        closeCallback={hook.close}
        footer={<TextBox />}
      >
        {hook.isLoading ? (
          <Spinner text={"Cargando comentarios"} hidden={false} />
        ) : (
          <div className={ui.contentModal}>
            <CommentList />
          </div>
        )}
      </Dialog>
    </ContextCommentOc.Provider>
  );
}
