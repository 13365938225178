import React from "react";
import card from "./styles.module.scss";
import { Label } from "structure/Document";
import { parseDateToText } from "helpers/dates";

/**
 * Render the items for the mobile
 * @param {import("./types").PropsMobileInvoiceItem} props - Props
 * @returns {JSX.Element}
 */
export default function MobileItem({
  created,
  currency,
  folio,
  invoice,
  options,
  socialReason,
  status,
  total,
}) {
  return (
    <div className={card.item}>
      <div className={card.header}>
        <div>
          <Label>Folio</Label>
          <p>{folio}</p>
        </div>

        {options}
      </div>

      <div>
        <Label>Razón social</Label>
        <p>{socialReason}</p>
      </div>

      <div>
        <Label>Registro</Label>
        <p>{parseDateToText(new Date(`${created}:`))}</p>
      </div>

      <div>
        <Label>Factura</Label>
        <p>{invoice}</p>
      </div>

      <div className="d-flex">
        <div className="mr-4">
          <Label>Moneda</Label>
          <p>{currency}</p>
        </div>

        <div>
          <Label>Total</Label>
          <p>{total}</p>
        </div>
      </div>

      <div>
        <Label>Estatus</Label>
        <p>{status}</p>
      </div>
    </div>
  );
}
