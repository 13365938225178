import React, { createContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import Options from "./Options";
import Filter from "../Filter";
import TableContract from "./Table";
import useContractList from "customHooks/useContractList";
import usePermissions from "customHooks/usePermissions";
import ui from "./styles.module.scss";
import FilterMobile from "structure/FilterMobile";
import RangeInput from "../Filter/RangeInput";
import UenSelect from "../Filter/UenSelect";
import ContractInput from "../Filter/ContractInput";
import StatusSelect from "../Filter/StatusSelect";
import SocialReasonSelect from "../Filter/SocialReasonSelect";
import ExecutiveSelect from "../Filter/ExecutiveSelect";
import { parseDateToText } from "helpers/dates";

const STATUS_LABEL = {
  7: "Vigente",
  8: "En proceso",
  9: "Terminado",
};

/**
 * Context in order to render the ui of the contracts from the system
 * @type {import("react").Context<import("customHooks/useContractList/types").useContractListValuesI|undefined>}
 */
export const ContextContract = createContext(undefined);
const { Provider } = ContextContract;

export default function ContractList() {
  const useContractListValues = useContractList();

  usePermissions("82457369-4d57-46fb-a468-49159aefb8bc");

  return (
    <div className={ui.content}>
      <Provider value={useContractListValues}>
        <div className={ui.header}>
          <BreadCrumV2 path={useContractListValues.breadcrumPath} />

          <Filter />

          <div className={ui.margin}>
            <FilterMobile
              onSearch={useContractListValues.triggerSearch}
              pills={[
                {
                  label: "Ejecutivo",
                  value: useContractListValues.executiveName,
                },
                {
                  label: "Razón social",
                  value: useContractListValues.customerName,
                },
                {
                  label: "Estatus",
                  value: STATUS_LABEL[useContractListValues.status] || "Todos",
                },
                {
                  label: "UEN",
                  value: useContractListValues.uenName,
                },
                {
                  label: "Desde",
                  value: parseDateToText(useContractListValues.startDate),
                },
                {
                  label: "Hasta",
                  value: parseDateToText(useContractListValues.endDate),
                },
              ]}
            >
              <ExecutiveSelect />
              <SocialReasonSelect />
              <StatusSelect />
              <ContractInput />
              <UenSelect />
              <RangeInput />
            </FilterMobile>
          </div>
        </div>

        <div className={ui.margin}>
          <Options idCustomer={useContractListValues.socialReason} />
          <TableContract />
        </div>
      </Provider>
    </div>
  );
}
