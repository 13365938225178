import * as yup from "yup";

export const startDate = yup
  .date()
  .required("Fecha obligatoria")
  .typeError("La fecha debe ser en formato YYYY-MM-DD");

export const endDate = yup
  .date()
  .required("Fecha obligatoria")
  .typeError("La fecha debe ser en formato YYYY-MM-DD");

export const message = yup
  .string()
  .required("Mensaje requerido")
  .typeError("El mensaje debe de ser texto")
  .max(255, "Máximo 255 caracteres");

export const type = yup
  .number()
  .required("Tipo de aviso requerido")
  .typeError("El tipo de aviso debe de ser numerico");

export const schemaAddAdvertisement = yup.object({
  startDate,
  endDate,
  message,
  type,
});
