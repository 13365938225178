import { RedirectButton } from "components/individual/buttons/Buttons";
import usePermissions from "customHooks/usePermissions";
import React, { useContext } from "react";

import { ContextOptionsEgress } from "./OptionsEgress";

export default function EditEgress() {
  const { idBankAccount, idMovement, idStatus } =
    useContext(ContextOptionsEgress);

  const canBeEditted = idStatus !== 5 ? true : false;

  const { checkPermission } = usePermissions();

  return (
    <>
      {!canBeEditted ? null : checkPermission(
          "233c2899-191c-4d3e-aa0e-b9db0dff8e91"
        ) ? (
        <RedirectButton
          link={`/bancos/movimientos/${idBankAccount}/editar/${idMovement}`}
          text="Editar egreso"
          css="ml-3"
        />
      ) : null}
    </>
  );
}
