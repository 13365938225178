import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";
import { Error } from "helpers/alerts";

/**
 *
 * @param {import("./types").ParamApiResidueMovements} params - Params in order to fetch the data of the movements and residue of the social reason requested
 * @returns {Promise<import("../../../../../types/Sells/sells").MovementsBalanceI>}
 */
export async function getResidueAndMovementsReport({
  beginDate,
  endDate,
  idSocialReason,
  type,
  socialReason
}) {
  const queryParamBeginDate = beginDate === null ? '':`&beginDate=${beginDate}`;
  const queryParamEndDate = endDate === null ? '': `&endDate=${endDate}`;
  const queryParamId = typeof idSocialReason !=="number" ? '' : `&idSocialReason=${idSocialReason}`;
  const querySocialReason = `?socialReason=${socialReason}`

  /**
   * @type {import("../../../../../types/Sells/sells").MovementsBalanceI}
   */
  const DUMMY_RESPONSE = {
    idCustomer: idSocialReason,
    invoice: [],
    socialReason: "",
  };

  const url = type === 'compras' ? 'compras' : 'ventas';

  try {
    const res = await fetch(
      `${URL_BASE}documentos/${url}/saldos-movimientos${querySocialReason}${queryParamId}${queryParamBeginDate}${queryParamEndDate}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {import("../../../../../types/Sells/sells").MovementsBalanceI[]}
       */
      const data = await res.json();

      if (data[0] === undefined) return DUMMY_RESPONSE;

      return data[0];
      
    }

    /**
     * @type {import("./types").ResponseApiResidueMovementsError}
     */
    const data = await res.json();

    Error(() => {}, data.message);
    return DUMMY_RESPONSE;
  } catch (error) {
    Error(() => {}, error.message);
    return DUMMY_RESPONSE;
  }
}

// getResidueAndMovementsReport
/**
 *
 * @param {import("./types").ParamApiResidueMovements} params - Params in order to fetch the data of the movements and residue of the social reason requested
 * @returns {Promise<Blob|undefined>}
 */
export async function getResidueAndMovementsXlsx({
  beginDate,
  endDate,
  idSocialReason,
  socialReason
}) {
  const queryParamBeginDate = beginDate === null ? '':`&beginDate=${beginDate}`;
  const queryParamEndDate = endDate === null ? '': `&endDate=${endDate}`;
  const querySocialReason = `&socialReason=${socialReason}`
  const queryParamId = `?idSocialReason=${idSocialReason}`;

  try {
    const res = await fetch(
      `${URL_BASE}documentos/saldos-movimientos/excel${queryParamId}${queryParamBeginDate}${queryParamEndDate}${querySocialReason}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      
      const data = await res.blob();
      return data;
    }

    /**
     * @type {import("./types").ResponseApiResidueMovementsError}
     */
    const data = await res.json();

    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    Error(() => {}, error.message);
    return undefined;
  }
}