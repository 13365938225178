import React, { useContext, useEffect } from "react";
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";

// APIS
import { getCustomer } from "../../../../../helpers/Apis/Directory";
import {
  GetTCP,
  PostDocumnet,
  UpdateDocument,
} from "../../../../../helpers/Apis/Documents";

// HOOKS
import useAddDocument from "../../../../../customHooks/useAddDocuments";

// CONTEXT
import UserContext from "../../../../../context/Login/LoginContext";

// LIBS
import { useHistory, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./QuoteForm.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// HELPERS
import { Success } from "../../../../../helpers/alerts";
import { parseToFloat } from "../../../../../helpers/money";

// COMPONENTES
import AddQuote from "./AddQuote";
import AddService from "./AddService";
import EditQuote from "./EditQuote";

// SCHEMAS
import schemaValidations from "../../../../../Schemas/Directory/AddDocument/Documents";

// HELPERS
import { indexedTypeDoc } from "../../../../../helpers/Directory/Documents/addDocument";

export const QuoteForm = () => {
  const history = useHistory();

  const { userInfo, userId } = useContext(UserContext);

  const {
    setTipoCambioProtegido,
    discriminateNewComments,
    parseGames,
    calculateTotalImport,
    parseComments,
    UpdatePricesDoc,
    prices,
    checkMixedCurrencys,
  } = useAddDocument();

  const {
    tcp,
    setTCP,
    setCurrencyName,
    comments,
    commentsToDelete,
    commentsToAdd,
    docItemsToDelete,
    setCustomerInfo,
    games,
    setIsFetching,
    exchangeTcp,
  } = useContext(AddDocumentContext);

  UpdatePricesDoc();

  let { customerId, action, typeDocument, documentId } = useParams();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaValidations[typeDocument][action]),
  });

  const SubmitForm = async (data) => {
    setIsFetching(true);

    if (typeDocument == "cotizacion") {
      const { games, gamesToCreate, memoryGames, toUpdateItems, toAddOnEdit } =
        parseGames();

      const productItems = [...games, ...gamesToCreate, ...memoryGames];

      // Import document
      const totalImport = calculateTotalImport(productItems);

      // Parse the comments
      const parsedComments = parseComments();

      // Discriminate the comments
      const { commentsToAdd, existingComments } =
        discriminateNewComments(parsedComments);

      data = {
        ...data,
        games,
        gamesToCreate,
        memoryGames,
        comments: existingComments,
        commentsToDelete,
        commentsToAdd,
        totalImport: parseToFloat(prices.total),
        subTotal: parseToFloat(prices["import"]),
        ivaTotal: parseToFloat(prices.iva),
        formID: indexedTypeDoc[typeDocument],
        modifyBy: userInfo[0]["fullName"],
        customerId: +customerId,
        userID: userId,
        idDocument: +documentId,
        docItemsToDelete,
        toUpdateItems,
        toAddOnEdit,
        idExecutive: userId,

        // Mixed currencys or tcp < enterprise ? Requires auth : Not requires auth
        authorizationFlag:
          checkMixedCurrencys(games) || exchangeTcp < tcp.test ? 2 : 1,
      };
    }

    let wasSuccessQuery;

    // formID:number- tipo de documento [DocumentTypes]
    // idDocument:number
    console.log(data);

    // If user attempts to edit
    if (action == "editar") {
      // Update the document
      wasSuccessQuery = await UpdateDocument(data, 1);
    } else {
      // Create the document
      wasSuccessQuery = await PostDocumnet(data);
    }

    // If query success, prompt an alert of that
    if (wasSuccessQuery) {
      Success(
        () => history.push(`/directorio/documentos/${customerId}`),
        `Documento ${action == "editar" ? "actualizado" : "agregado"}`
      );
    }

    setIsFetching(false);
  };

  useEffect(() => {
    async function initialLoad() {
      const apiCustomer = await getCustomer(+customerId);
      const apiTcp = await GetTCP();

      setCustomerInfo(apiCustomer["data"][0]);
      setTCP(apiTcp);
    }

    initialLoad();
  }, []);

  const documentBody = {
    cotizacion: {
      agregar: <AddQuote forwardRef={register} errors={errors} />,
      editar: <EditQuote forwardRef={register} errors={errors} />,
    },
    prefactura: {
      editar: <EditQuote forwardRef={register} errors={errors} />,
    },
    servicios: {
      agregar: <AddService />,
    },
  };

  return (
    <div className="container py-3 pt-4">
      <form
        id="document"
        className="formContainer queueContainer w-100"
        onSubmit={handleSubmit(SubmitForm)}
      >
        {documentBody[typeDocument][action]}
      </form>
    </div>
  );
};
