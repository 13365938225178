import React, { useContext } from "react";
import { ContextReminderPayments } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import ui from "./styles.module.scss";

export default function Update() {
  const hook = useContext(ContextReminderPayments);

  if (hook.isUpdating)
    return <Spinner text={"Actualizando plantilla"} hidden={false} />;

  return (
    <div className={ui.buttonContainer}>
      <ButtonV2 onClick={hook.update} disabled={hook.isUpdating}>
        <span>Actualizar</span>
      </ButtonV2>
    </div>
  );
}
