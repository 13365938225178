import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { ExecutiveArea } from "./Styles";

export default function Executive({ value, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Ejecutivo"
            value={!value ? document.executive.fullName : value}
          />
        </div>
      ) : (
        <ExecutiveArea>
          <Informative
            readonly={true}
            label="Ejecutivo"
            value={!value ? document.executive.fullName : value}
          />
        </ExecutiveArea>
      )}
    </>
  );
}
