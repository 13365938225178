import React from "react";
import { MoneyConteiner } from "../Money/Conteiners";
import { InputSections } from "../Money/InputSections";
import { TextSection } from "../Money/TextSection";

export const MoneyComponent = () => {
  return (
    <MoneyConteiner>
      <InputSections  />
      <TextSection />
    </MoneyConteiner>
  );
};
