import React, { createContext } from "react";
import style from "./styles.module.scss";
import useResidueAndMovements, {
  CONTEXT_RESIDUE_AND_MOVS,
} from "./useResidueAndMovements";
import Table from "./Table";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ExcelDownload from "./ExcelDownload";
import Dates from "./Dates";
import ComboSocialReason from "./ComboSocialReason";
import FilterMobile from "./FilterMobile";

export const ContextResidueAndMovements = createContext(
  CONTEXT_RESIDUE_AND_MOVS
);

/**
 * Render the report for residue and movements
 * @param {import("./types").PropsReisdueAndMovements} props - Props
 * @returns {JSX.Element}
 */
export default function ResiduesAndMovements({ type = "ventas" }) {
  const hook = useResidueAndMovements(type);

  return (
    <ContextResidueAndMovements.Provider value={{ ...hook, type }}>
      <div className={style.module}>
        <div className={style.headerContent}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={style.content}>
            <div className={style.filter}>
              <ComboSocialReason />
              <Dates />
              <ExcelDownload />
            </div>
            <FilterMobile/>
          </div>
        </div>

        <div className={style.content} style={{ overflow: "auto" }}>
          <Table />
        </div>
      </div>
    </ContextResidueAndMovements.Provider>
  );
}
