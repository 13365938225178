import Select from "components/individual/SelectV4";
import React, { useRef } from "react";
import { Label } from "structure/Document";

/**
 * Render a combo for the Fee type
 * @param {import("./types").PropsFee} props - Props
 * @returns {JSX.Element} Render a combo for the Fee type
 */
export default function Fee({ onChange = () => {}, required = false , value = null }) {
  const feeOptions = useRef([
    {
      label: "-- SELECCIONA --",
      value: null,
      disabled: true,
    },
    {
      label: "Facturación",
      value: "invoice",
    },
    {
      label: "Contribución",
      value: "contribution",
    },
  ]);

  return (
    <div>
      <Label required={required}>Tipo de cuota</Label>
      <Select
        options={async () => feeOptions.current}
        labelToDisplay="label"
        onChange={(value) => onChange(value.value)}
        defaultSelected={{
          keyToCompare: "value",
          value,
        }}
      />
    </div>
  );
}
