import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { schemaAddAdvertisement } from "./schema";
import { RangeDate, From, To } from "components/general/RangeDate";
import { Label } from "structure/Document";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import { addNotice, editAdvertisement } from "helpers/apis";
import {
  addAdvertisement,
  getAdvertisementById,
} from "helpers/Apis/Advertisements";
import LoginContext from "context/Login/LoginContext";
import { dateAtCeroHours, dateToDbFormat, getDateFromUtc } from "helpers/dates";
import { add } from "date-fns";

/**
 * @type {import("react").Context<import("./types").ContextAdvertisementForm|undefined>}
 */
const ContextAdvertisement = createContext(undefined);

/**
 * Form to handle the advertisements (ABC)
 * @param {import('./types').PropsAdvertisementForm} props - Props
 * @returns
 */
export function Advertisement({
  id = null,
  children = <></>,
  onSuccess = () => {},
  allowFetchAdv = false,
}) {
  const [isPerformingQuery, setIsPerformingQuery] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./types").AdvertisementForm>}
   */
  const hookForm = useForm({
    resolver: yupResolver(schemaAddAdvertisement),
    mode: "all",
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const idForm = useRef(`${window.crypto.randomUUID()}`);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      if (!allowFetchAdv || typeof id !== "number") {
        setIsLoading(false);
        return;
      }

      const advertisement = await getAdvertisementById(id);

      const startDate = new Date(`${advertisement.startDate}:`);
      const endDate = new Date(`${advertisement.endDate}:`)

      hookForm.reset({
        startDate,
        endDate,
        message: advertisement.message,
        type: advertisement.type,
      });

      setIsLoading(false);
    })();
  }, [allowFetchAdv, id]);

  return (
    <RangeDate>
      <ContextAdvertisement.Provider
        value={{
          hookForm,
          idForm: idForm.current,
          id,
          isPerformingQuery,
          setIsPerformingQuery,
          onSuccess,
          isLoading,
        }}
      >
        <div>{children}</div>
      </ContextAdvertisement.Provider>
    </RangeDate>
  );
}

/**
 * Render the form container for the HOC
 * @param {React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>} props - Props
 * @returns {JSX.Element}
 */
export function FormAdvertisement(props) {
  const hook = useContext(ContextAdvertisement);

  const { userInfo } = useContext(LoginContext);

  if (hook === undefined) return <></>;

  /**
   * Information of the form
   * @param {import("./types").AdvertisementForm} data - Data of the form
   */
  const handleSubmitForm = async (data) => {
    hook.setIsPerformingQuery(true);

    const wasSuccess =
      typeof hook.id === "number"
        ? await editAdvertisement(
            hook.id,
            typeof data.startDate === "string" ? data.startDate : dateToDbFormat(data.startDate),
            typeof data.endDate === "string" ? data.endDate : dateToDbFormat(data.endDate),
            userInfo[0].userID,
            data.type,
            data.message,
            userInfo[0].fullName
          )
        : await addAdvertisement({
            endDate: typeof data.endDate === "string" ? data.endDate : dateToDbFormat(data.endDate),
            message: data.message,
            startDate: typeof data.startDate === "string" ? data.startDate : dateToDbFormat(data.startDate),
            type: data.type,
          });

    hook.setIsPerformingQuery(false);

    if (wasSuccess) {
      hook.onSuccess();
      hook.hookForm.reset({});
    }
  };

  if (hook.isLoading) return <Spinner hidden={false} text={"Cargando aviso"} />;

  return (
    <form
      {...props}
      id={hook.idForm}
      onSubmit={hook.hookForm.handleSubmit(handleSubmitForm, (e) =>
        console.log(e)
      )}
    >
      {props?.children}
    </form>
  );
}

export function FromInput() {
  const hook = useContext(ContextAdvertisement);
  if (hook === undefined) return <></>;

  return (
    <div>
      <Controller
        control={hook.hookForm.control}
        name="startDate"
        render={({ onChange, value }) => (
          <From
            minDate={new Date()}
            selected={value}
            name="startDate"
            onChange={(check) => onChange(check)}
          />
        )}
      />
      <p className="text-danger m-0 font-weight-bold">
        {hook.hookForm.errors?.startDate?.message}
      </p>
    </div>
  );
}

export function ToInput() {
  const hook = useContext(ContextAdvertisement);
  if (hook === undefined) return <></>;

  return (
    <div>
      <Controller
        control={hook.hookForm.control}
        name="endDate"
        render={({ onChange, value }) => (
          <To
            selected={value}
            name="endDate"
            onChange={(check) => onChange(check)}
          />
        )}
      />
      <p className="text-danger m-0 font-weight-bold">
        {hook.hookForm.errors?.endDate?.message}
      </p>
    </div>
  );
}

export function MessageInput() {
  const hook = useContext(ContextAdvertisement);

  if (hook === undefined) return <></>;

  return (
    <div>
      <Label>Mensaje</Label>
      <textarea
        ref={hook.hookForm.register}
        name="message"
        placeholder="Escribe aquí"
      ></textarea>

      <p className="text-danger m-0 font-weight-bold">
        {hook.hookForm.errors?.message?.message}
      </p>
    </div>
  );
}

export function SubmitAdvertisement() {
  const hook = useContext(ContextAdvertisement);
  if (hook === undefined) return <></>;

  if (hook.isLoading) return <></>;

  if (hook.isPerformingQuery)
    return (
      <Spinner
        text={typeof hook.id === "number" ? "Actualizando" : "Agregando"}
        hidden={false}
      />
    );

  return (
    <ButtonV2 type="submit" form={hook.idForm}>
      <span>{typeof hook.id === "number" ? "Editar" : "Agregar"}</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}

export function TypeSelect() {
  const hook = useContext(ContextAdvertisement);
  if (hook === undefined) return <></>;

  return (
    <div>
      <Label>Tipo de aviso</Label>
      <select ref={hook.hookForm.register} name="type" className="select-css">
        <option selected value="" disabled>
          -- SELECCIONA --
        </option>
        <option value="1">Mantenimiento</option>
        <option value="2">Cumpleaños</option>
        <option value="3">Alerta</option>
        <option value="4">Aviso</option>
        <option value="5">Receso</option>
      </select>
      <p className="text-danger m-0 font-weight-bold">{hook.hookForm.errors?.type?.message}</p>
    </div>
  );
}
