import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import { ContextInvoiceReception } from "../..";
import Dialog from "structure/Dialog";
import SearchBar from "../../Filter/SearchBar";
import StatusCombo from "../../Filter/StatusCombo";
import filter from "./styles.module.scss";
import DateFilter from "../../Filter/DateFilter";

export default function FilterMobile() {
  const hook = useContext(ContextInvoiceReception);

  return (
    <>
      <ButtonV2 onClick={hook.dialog.open}>
        <Icon icon="filter" />
      </ButtonV2>

      <Dialog
        title="Filtros"
        footer={
          <div className={filter.footer}>
            <ButtonV2
              onClick={() => hook.dialog.close()}
              form="invoiceReceptionFilter"
            >
              <span>Buscar</span>
              <Icon icon="search" />
            </ButtonV2>
          </div>
        }
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.dialog.close}
      >
        <div className={filter.items}>
          <SearchBar />
          <StatusCombo />

          <DateFilter />
        </div>
      </Dialog>
    </>
  );
}
