import React, { useContext } from "react";
import { ContextInvoice } from "../..";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";

export default function SocialReasonSelect() {
  const { updateSocialReason } = useContext(ContextInvoice);

  return (
    <div>
      <Label>Razon social</Label>
      <Search
        hideSearchIcon={true}
        onChange={(value) => updateSocialReason(value)}
        placeholder="Razón social, nombre corto"
        onDelete={() => updateSocialReason("")}
      />
    </div>
  );
}
