import { Tabs } from "components/general/Tabs/Tabs";
import React from "react";
import { ItemsSection } from "../Items/ItemsSection";
import { CommentSection } from "../Comment/CommentSection";
import ui from './styles.module.scss';

export const TabComponent = () => {
  return (
    <div className={ui.tab}>
      <Tabs
      idContainerContent={"documentInfo"}
      idContainerTabs={"tabs"}
      tabButtons={{
        items: {
          text: "Partidas",
          selected: true,
          //   component: <ItemSection />,
          component: <ItemsSection />,
        },
        comments: {
          text: "Comentarios",
          selected: false,
          //   component: <CommentSection />,
          component: <CommentSection />,
        },
      }}
    />
    </div>
  );
};
