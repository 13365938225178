import { SET_VIEW, SET_LOADING_USER } from "../../types/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state, action) {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };

    case SET_LOADING_USER:
      return {
        ...state,
        loadingUser: false,
      };

    default:
      break;
  }
}
