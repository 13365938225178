import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { AllExecutives } from "helpers/apis";
import { GetMyActivCustomersContacts } from "helpers/Apis/Documents";
import { GetCustomer } from "helpers/Apis/Directory";

/**
 * @type {import("./types").StateAutocompleteEmail}
 */
const INITIAL_STATE = {
  availableList: [],
  isLoading: false,
  search: "",
  fuse: undefined,
  itemsFound: [],
  currentList: [],
  isFocus: false,
};

/**
 * Handle the autocomplete for the emails
 * @returns {import("./types").ReturnAutocompleteEmail}
 */
export default function useAutocompleteEmail({
  aditionalEmails = [],
  idCustomer = null,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  /**
   * Get the emails that can be autocompleted
   * @returns {Promise<import("./types").EmailAutocompleteI[]>}
   */
  async function parseContactAndCustomer() {
    if (typeof idCustomer !== "number") return [];

    const contacts = await GetMyActivCustomersContacts(idCustomer);

    return contacts.map((contact) => ({
      email: contact.email,
      name: `${contact.firstName} (Contacto)`,
      id: contact.contactID,
    }));
  }

  /**
   *
   * @returns {Promise<import("./types").EmailAutocompleteI>}
   */
  async function parseCustomerEmail() {
    if (typeof idCustomer !== "number") return;

    const customer = await GetCustomer(idCustomer);

    /**
     * @type {import("./types").EmailAutocompleteI}
     */
    const customerData = {
      email: customer.contact.email,
      name: `${customer.name.short} (${customer.type.description})`,
      id: customer.id,
    };

    return customerData;
  }

  useEffect(() => {
    (async function () {
      const [executives, contacts, customer] = await Promise.all([
        AllExecutives(),
        parseContactAndCustomer(),
        parseCustomerEmail(),
      ]);

      /**
       * @type {import("./types").EmailAutocompleteI[]}
       */
      const parsedData = executives
        .filter((executive) => executive.status === true)
        .map((executive) => ({
          email: executive.email,
          name: executive.TEXT,
          id: executive.value,
        }));

      /**
       * @type {import("./types").EmailAutocompleteI[]}
       */
      let aditionalCustomEmails = [...aditionalEmails];

      const listToUse = [
        customer,
        ...contacts,
        ...aditionalCustomEmails,
        ...parsedData,
      ].filter((item) => typeof item === "object");

      const defaultEmails = await handleCustomEmails();

      console.log({ defaultEmails });

      const fuse = new Fuse(listToUse, {
        keys: ["email", "name"],
      });

      setState((current) => ({
        ...current,
        availableList: listToUse,
        fuse,
        currentList: defaultEmails,
      }));

      ///////////////////////////////////////////////////////////////////////////////////////////////

      async function handleCustomEmails() {
        /**
         * @type {string[]}
         */
        let emailsToCompare = [];

        if (Array.isArray(aditionalEmails)) {
          emailsToCompare = [...aditionalEmails];
        } else {
        }

        /**
         * @type {import("./types").EmailAutocompleteI[]}
         */
        let matchedEmailsFound = [];
        if (emailsToCompare.length <= 0 || listToUse.length <= 0) return [];

        emailsToCompare.forEach((email, j) => {
          listToUse.forEach((items, i) => {
            if (email === items.email) {
              matchedEmailsFound.push(items);
            }
          });
        });

        const unrepeatedEmails = new Set();

        matchedEmailsFound.forEach((email) => unrepeatedEmails.add(email));

        const unrepeatedArray = [...unrepeatedEmails];

        emailsToCompare.forEach((email) => {
          // Check if there's a user with the same email in the first array
          const matchedUser = unrepeatedArray.find(
            (user) => user.email === email
          );
          // If no user is found, create a new object with id set to null and push it to users array
          if (!matchedUser) {
            unrepeatedArray.push({
              email: email,
              name: "ND",
              id: null,
            });
          }
        });

        return unrepeatedArray;
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof state.search !== "string" || state.fuse === undefined) return;

    const itemsFound = state.fuse.search(state.search);
    setState((current) => ({
      ...current,
      itemsFound: itemsFound,
    }));
  }, [state.search]);

  const updateSearch = (text) => {
    if (state.availableList.length <= 0) return;

    setState((current) => ({
      ...current,
      search: text,
    }));
  };

  /**
   * Add an email into the input field
   * @param {import("./types").EmailAutocompleteI} item - Email item
   * @returns {void}
   */
  const appendEmail = (item) => {
    const isOnList = state.currentList.find(
      (bucket) => bucket.email === item.email
    );

    if (isOnList !== undefined) {
      setState((current) => ({ ...current, search: "" }));
      return;
    }

    setState((current) => ({
      ...current,
      search: "",
      currentList: [...current.currentList, item],
    }));
  };

  /**
   * Delete an email from the list
   * @param {number} indexToDelete - Position of the email to delete
   * @returns {void}
   */
  const deleteEmail = (indexToDelete) =>
    setState((current) => ({
      ...current,
      currentList: current.currentList.filter(
        (_, index) => indexToDelete !== index
      ),
    }));

  /**
   * Update the focus state of the input email
   * @param {boolean} isFocus - Set if the input email is focus to display the tooltip emails
   * @returns {void}
   */
  const setFocus = (isFocus) => {
    setState((current) => ({
      ...current,
      isFocus,
    }));
  };

  /**
   * Handle try to add a email that doesn't exist on Praxia
   * @param {React.KeyboardEvent<HTMLInputElement>} e - Event of the typing
   */
  const handleNewEmail = (e) => {
    if (e.code !== "Enter") return;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isValidEmail = emailRegex.test(state.search);

    if (!isValidEmail) return;

    appendEmail({
      email: state.search,
      name: "ND",
      id: null,
    });
  };

  return {
    updateSearch,
    handleNewEmail,
    ...state,
    appendEmail,
    setFocus,
    deleteEmail,
  };
}
