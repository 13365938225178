import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextInvoiceReception } from "../..";
import { Search } from "components/individual/Search";

export default function SearchBar() {
  const { updateQuerySearch, state } = useContext(ContextInvoiceReception);

  return (
    <div>
      <Label>Busqueda</Label>
      <Search
        hideSearchIcon={true}
        placeholder="Folio, razón social y nombre corto"
        id="searchInvoiceReception"
        value={state.search}
        onDelete={(e) => updateQuerySearch("")}
        onChange={(value) => updateQuerySearch(value)}
      />
    </div>
  );
}
