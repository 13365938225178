import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { ContextCorporative } from "structure/AssociateCorporative";
import { Label } from "structure/Document";
import style from "../styles.module.scss";
import MoreInfoDirectory from "structure/MoreInfoDirectory";

/**
 *
 * @param {import("helpers/Apis/typesDirectory").Corporative} corporative
 * @returns
 */
export default function MobileCard(corporative) {
  const hook = useContext(ContextCorporative);

  /**
   * @type {React.MutableRefObject<HTMLElement>}
   */
  const key = useRef();

  const openMoreInfo = () => key.current.click();

  return (
    <>
      <MoreInfoDirectory forwardRef={key} idCustomer={corporative.ID_cliente} />

      <div className={style.cardCorporative}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>Razón social</Label>
            <p>{corporative.Razon_social}</p>
          </div>

          <ElipsisMobile onShow={() => hook.setCorporative(corporative)}>
            <CustomMenuItem onClick={openMoreInfo}>
              Más información
            </CustomMenuItem>
            <CustomMenuItem onClick={hook.confirmSelection}>
              Seleccionar
            </CustomMenuItem>
          </ElipsisMobile>
        </div>

        <Label>RFC</Label>
        <p>{corporative.RFC}</p>

        <Label>Nombre comercial</Label>
        <p>{corporative.Nombre_comercial}</p>

        <Label>Nombre corto</Label>
        <p>{corporative.Nombre_corto}</p>
      </div>
    </>
  );
}
