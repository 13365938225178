import { Spinner } from "components/individual/Spinner/Spinner";
import { getInfoContact } from "helpers/Apis/contactInfoApi";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import CustomerInfo from "structure/Customer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

/**
 * Render the information of the contact
 * @param {object} props - Props
 * @param {number} props.id - Id of the contact to fetch
 * @param {(customer:InfoContact,customer:import("types/typedef/customer").CustomerI)=>void} props.onChange - Information of the contact and customer when this changes
 * @returns {JSX.Element} Render the information of the contact
 */
export default function MoreInfoContact({ id, onChange = () => {} }) {
  /**
   * Information of the contact
   * @type {[InfoContact,()=>void]}
   */
  const [contact, setContact] = useState(undefined);

  /**
   * Information of the customer
   * @type {[import("types/typedef/customer").CustomerI,()=>void]}
   */
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    (async function () {
      const apiContact = await getInfoContact(id);
      setContact(apiContact);
    })();
  }, []);

  useEffect(() => {
    if ((customer && contact) !== undefined) {
      onChange(contact, customer);
    }
  }, [customer, contact]);

  return (
    <>
      {contact === undefined ? (
        <Spinner hidden={false} text="Cargando contacto..." />
      ) : (
        <div className={styles.containerContactGlobal}>
          <div className={styles.containerContact}>
            <div className="d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faAddressCard} size="6x" />
            </div>

            <div className="mx-3">
              <p>
                <b>Nombre</b>
                <span>{contact.fullName}</span>
              </p>

              <p>
                <b>Correo </b>
                <span>{contact.email}</span>
              </p>

              <div className={styles.contactMedia}>
                <p>
                  <b>Teléfono </b>
                  <span>
                    {contact.phone === "" ? "ND" : contact.phoneNumber}
                  </span>
                </p>

                <p>
                  <b>Celular</b>
                  <span>
                    {contact.movil === "" ? "ND" : contact.cellNumber}
                  </span>
                </p>

                <p>
                  <b>Puesto</b>
                  <span>{contact.position}</span>
                </p>
              </div>
            </div>
          </div>

          <CustomerInfo
            idCustomer={contact.customerID}
            onChange={setCustomer}
          />
        </div>
      )}
    </>
  );
}
