import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { YesNoAlert } from "helpers/alerts";
import { cancelPreinvoice } from "helpers/Apis/invoice";
import React from "react";
import { useState } from "react";

/**
 * Render a button to cancel a preinvoice
 * @param {object} props - Props
 * @param {number} props.idPreinvoice - Id of the document
 * @param {()=>void} props.onCancelled - Callback to execute when invoice is cancelled
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} [props.props={}] - Props for the button html tag
 * @param {string} [props.labelBtn='Cancelar'] - Label for the button rendered
 * @returns {JSX.Element}
 */
export const CancelPreInvoice = ({
  idPreinvoice = null,
  onCancelled = () => {},
  props = {},
  labelBtn = "Cancelar",
}) => {
  const [state, setState] = useState({
    isCancelling: false,
  });

  const promptCancelPreinvoice = async () =>
    YesNoAlert("¿Estas seguro de cancelar?", performCancelPreinvoice);

  const performCancelPreinvoice = async () => {
    setState({
      ...state,
      isCancelling: true,
    });

    const wasCancelled = await cancelPreinvoice(idPreinvoice);

    if (wasCancelled) onCancelled();

    setState({
      ...state,
      isCancelling: false,
    });
  };

  if (state.isCancelling) return <Spinner hidden={false} text="Cancelar" />;

  return (
    <ButtonV2 onClick={promptCancelPreinvoice} {...props}>
      <span>{labelBtn}</span>
    </ButtonV2>
  );
};
