import { Modal } from "components/general/Modal";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Error, Warning } from "helpers/alerts";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { test } from "sw-register";
import { ContextPushNotification } from "..";
import Icon from "components/individual/HeaderIcon";

export default function TurnOff({ isOpen = false }) {
  const hook = useContext(ContextPushNotification);

  const [isSending, setIsSending] = useState(false);

  const handleOnTest = async () => {
    setIsSending(true);

    const wasSended = await test();

    if (!wasSended) {
      Error(() => {},
      "No se pudo enviar la notificacion, favor de reportar a soporte 😓");
    }

    Warning(
      `Si no recibiste una notificacion, recarga la página o borra las cookies e intenta activar las notificaciones nuevamente`
    );

    setIsSending(false);
    hook.closeTurnOff()
  };

  return (
    <Modal
      title="Notificaciones"
      isOpen={isOpen}
      displayButton={false}
      onCloseModal={hook.closeTurnOff}
      footerV2={() =>
        isSending || hook.isDisablingNotifications ? (
          <Spinner hidden={false} text="Cargando" />
        ) : (
          <>
            <ButtonV2 onClick={hook.attemptDisableNotifications} theme="danger">
              <span>Apagar notificaciones</span>
              <Icon icon="powerOff"/>
            </ButtonV2>
            <ButtonV2 onClick={handleOnTest}>
              <span>Probar notificaciones</span>
              <Icon icon="comments"/>
            </ButtonV2>
          </>
        )
      }
    >
      {() => (
        <div>
          <h1
            style={{
              fontSize: "2rem",
            }}
            className="text-center py-5"
          >
            🟢 Recibiendo notificaciones
          </h1>
        </div>
      )}
    </Modal>
  );
}
