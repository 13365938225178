import React, { useContext } from "react";
import {
  HeaderItemContainer,
  HeaderLong,
  HeaderShort,
  HeaderShortButton,
  HeaderShortKey,
} from "./Containers";
import DocumentContext from "../ContextDocument";
import ModalDocumentItems from "structure/ModalDocumentItems";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import {
  truncateDecimals,
} from "helpers/money";
import {
  handleFormItem,
} from "../helpers/documentHelper";
import Swal from "sweetalert2";

export const HeaderSection = () => {
  const { handleSubmitItemForm, moneyInfo, customer , documentType , actionType } =
    useContext(DocumentContext);

  /**
   * Set the information in order to make it work on the hook document items
   * @param {import("structure/FormDocumentItems/types").DocumentItemForm} formData
   * @param {number} tc - Exchange rate to calculate currency between items
   */
  const handleOnChange = (formData, tc = 18.8428) => {
    const itemToUse = handleFormItem(formData, tc,2,{
      rfc: customer.rfc,
      typeDocument:documentType
    });
    handleSubmitItemForm(itemToUse);
  };


  return (
    <HeaderItemContainer>
      <HeaderShortKey>Clave</HeaderShortKey>
      <HeaderLong>Descripcion</HeaderLong>
      <HeaderShort>Cantidad</HeaderShort>
      <HeaderShort>{documentType === 3 ? "Costo" : "Precio"}</HeaderShort>
      <HeaderShort>Importe</HeaderShort>
      <HeaderShortButton>
        {actionType === "Ver" ? null : customer.id <= 0 ? (
          <ButtonV2 onClick={()=>Swal.fire({
            title:'Importante',
            text:'Primero selecciona una razón social',
            icon:"warning"
          })}>
            <span>+</span>
          </ButtonV2>
        ) : (
          <ModalDocumentItems
            title="Catálogo de productos y servicios"
            onChange={(item) =>
              handleOnChange(item, truncateDecimals(moneyInfo.tc.number, 2))
            }
          >
            <ButtonV2>
              <span>+</span>
              <Icon icon="plus" />
            </ButtonV2>
          </ModalDocumentItems>
        )}
        {/* {actionType === "Ver" ? null : <FormSection />} */}
      </HeaderShortButton>
    </HeaderItemContainer>
  );
};
