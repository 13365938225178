import React, { useContext } from "react";
import { ContextInvoiceReception } from "../..";
import AccoutingStatus from "components/general/Forms/Selects/AccountingStatus";
import InvoiceReceptionStatus from "components/general/Forms/Selects/InvoiceReceptionStatus";

export default function StatusCombo() {
  const {
    setAccountStatus,
    isForAccounting,
    state: stateInvoiceRecepcion,
    updateFilterStatus,
  } = useContext(ContextInvoiceReception);

  return isForAccounting ? (
    <AccoutingStatus onChange={setAccountStatus} />
  ) : (
    <InvoiceReceptionStatus
      value={stateInvoiceRecepcion.status}
      onChange={({ id }) => updateFilterStatus(id)}
    />
  );
}
