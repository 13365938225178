export const SET_MOVEMENT = 'SET_MOVEMENT';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_STEP= 'SET_STEP';
export const SET_LOADING_PAGE='SET_LOADING_PAGE';
export const SET_INPUT_SEARCH_CUSTOMER='SET_INPUT_SEARCH_CUSTOMER';
export const SET_CONCILATION='SET_CONCILATION';
export const SET_BANK_INFO='SET_BANK_INFO';
export const SET_AMOUNT = 'SET_AMOUNT';
export const UPDATE_CONCILATION = 'UPDATE_CONCILATION';
export const SET_APLICACION = 'SET_APLICACION';
export const VALIDATE_FLAGS = 'VALIDATE_FLAGS';
export const SET_REDIRECT_STEP = 'SET_REDIRECT_STEP'