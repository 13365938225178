import { AttachFile } from "components/general/SendRelatedDocs/styles";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { useContext } from "react";
import { ContextEmail } from "..";
import Icon from "components/individual/HeaderIcon";

/**
 * Render the button to attach files from the pc
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props - Props
 * @returns {JSX.Element}
 */
export const FileFromPc = (props) => {
  const { openFilesComputer, domFilesPc, addFiles } = useContext(ContextEmail);

  return (
    <>
      <AttachFile {...props}>
        <ButtonV2 onClick={openFilesComputer}>
          <span>PC</span>
          <Icon icon="clip"/>
        </ButtonV2>
      </AttachFile>

      <input
        onChange={(e) => addFiles(e.target.files)}
        ref={domFilesPc}
        type="file"
        multiple
        hidden
      />
    </>
  );
};
