import { useEffect, useState } from "react";
import Swal from "sweetalert2";

/**
 * Initial value for the staets
 * @type {import("./types").StateFormProyectsStructured}
 */
const INITIAL_STATE = {
  positions: [],
  rfq: "",
  solped: "",
  loaded:false
};

/**
 * Handle the creation of proyects
 * @param {number} idProyect - Id of the proyect
 * @returns {import("./types").ReturnFormProyectsStructured}
 */
export default function useFormProyectsStructured(idProyect) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(()=>{
    const positions = state.positions.map(item=>{
      if(typeof item.id === 'number') return item

      if(state.rfq===""||state.solped==="") return {
        ...item,
        status:'SolicitudUrgente'
      }

      return {
        ...item,
        status:'SolicitudNormal'
      }
    });

    setState(current=>({
      ...current,
      positions
    }));
  },[state.rfq,state.solped])

  const addPositionIntoMemory = (position) => {
    setState((current) => ({
      ...current,
      positions: [...current.positions,position],
    }));
  };

  const deletePositionFromMemory = (index) =>
    setState((current) => ({
      ...current,
      positions: current.positions.filter((_, i) => i !== index),
    }));

  const bulkInsert = (positions) =>
    setState((current) => ({
      ...current,
      positions,
    }));

  const setSolped = (solped) =>
    setState((current) => ({
      ...current,
      solped,
    }));

  const setRfq = (rfq) =>
    setState((current) => ({
      ...current,
      rfq,
    }));

  const updatePosition = (pos, index) => {
    Swal.fire({
      title: "Actualización de posición",
      text: 'La posición se actualizará con los datos proporcionados si presiona el botón de "Actualizar proyecto"',
      icon: "warning",
      confirmButtonText: "Ok, entendido",
    });

    let unrefPositions = [...state.positions];

    unrefPositions[index] = pos;

    setState((current) => ({
      ...current,
      positions: unrefPositions,
    }));
  };

  const setLoaded = loaded => setState(current => ({...current, loaded}))

  return {
    ...state,
    setLoaded,
    updatePosition,
    addPositionIntoMemory,
    deletePositionFromMemory,
    bulkInsert,
    setSolped,
    setRfq,
  };
}
