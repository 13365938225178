// @ts-check
import { addEgressConcepts, addEgressCxp } from "helpers/Apis/Banks/index";
import { dateToDbFormat } from "helpers/dates";
import { truncateDecimals } from "helpers/money";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getMovement } from "helpers/Apis/Banks/index";
import { getMovementAssociationTolerance } from "helpers/Apis/parameters";

/**
 * @type {import("./types").EgressState}
 */
const INITIAL_STATE = {
  tolerance:0,
  provider: null,
  providerSocialReason:"",
  date: new Date(),
  payMethod: null,
  amount: 0,
  beneficiary: "",
  amountAssociated: 0,
  amountNotAssociated: 0,
  canSelectProvider: false,
  concepts: {
    concepts: [],
    total: 0,
    isValid: true,
  },
  includeExpenses: true,
  deductible: [],
  noDeductible: [],
  grid: null,
  canPerformQuery: false,
  isWaitingServer: false,
  idBankAccount: 0,
};

/**
 * Hook to handle the creation and edition of an egress
 * @param {number} idMovement - Id of the movement in case the executive is resuming the operation
 * @param {number?} decimals - Number of decimals for the math operations
 * @returns {import("./types").ReturnUseCreateEgress}
 */
export default function useCreateEgress(
  idMovement = null,
  decimals = 4,
  idBankAccount = 0
) {
  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  useEffect(() => {
    (async function () {
      if (idMovement === null || idMovement === undefined || isNaN(idMovement))
        return;

      const apiMovement = await getMovement(idMovement);

      const payMethod = {
        description: apiMovement.payMethod.description,
        code: apiMovement.payMethod.id,
        status: 1,
        label: apiMovement.payMethod.description,
        value: apiMovement.payMethod.id,
      };

      setState((current) => ({
        ...current,
        amount: apiMovement.residue,
        payMethod,
        provider: apiMovement.customer.id,
        canSelectProvider: false,
        date: new Date(`${apiMovement.date}`),
      }));
    })();
  }, [idMovement]);

  useEffect(() => {
    setState((current) => ({
      ...current,
      idBankAccount,
    }));
  }, [idBankAccount]);

  useEffect(() => {
    const dom = document.getElementById("tab-concepts");

    if (dom === undefined || dom === null) return;

    if (state.beneficiary.length >= 1) {
      dom?.classList.add("d-none");
    } else {
      dom.classList.remove("d-none");
    }

    const domCxp = document.getElementById('tab-cxp');

    if(typeof(state.provider)==="number"){
      domCxp?.click();
      return
    }
    
    if(state.includeExpenses){
      dom?.click();
    }else{
      domCxp?.click();
    }

  }, [state.beneficiary, state.includeExpenses,state.amount,state.payMethod,state.provider]);

  useEffect(() => {
    if (idMovement !== null) {
      setState((current) => ({
        ...current,
        canSelectProvider: false,
      }));
      return;
    }

    const canSelectProvider =
      state.amount <= 0 || state.payMethod === null ? false : true;

    setState((current) => ({
      ...current,
      canSelectProvider,
    }));
  }, [state.amount, state.payMethod]);

  useEffect(() => {

    const conceptOverview = checkConceptsFound();
    const isAssocaitedToFr = checkIsAssociatedToFr();

    if(conceptOverview.advanceOrAdjustmentFound && conceptOverview.othersConceptFound){
      setState(current=>({
        ...current,
        canPerformQuery:false
      }));
      return;
    }

    if(isAssocaitedToFr && conceptOverview.othersConceptFound){
      setState(current=>({
        ...current,
        canPerformQuery:false
      }));
      return;
    }
    
    if (state.amount <= 0) {
      setState((current) => ({
        ...current,
        canPerformQuery: false,
      }));

      return;
    }

    if (state.amountNotAssociated > 0 || state.amountNotAssociated < 0) {
      setState((current) => ({
        ...current,
        canPerformQuery: false,
      }));

      return;
    }

    setState((current) => ({
      ...current,
      canPerformQuery: true && state.concepts.isValid,
    }));


    return;

    //////////////////////////////////////////////////////////////////////////////

    function checkConceptsFound(){
      const ids = state.concepts.concepts.map(concept=>concept.id);

      const ID_ADVANCE_SUPPLIER = 62;
      const ID_ADJUSTMENT = 73;

      const search = ids.find(item=>item===ID_ADVANCE_SUPPLIER||item===ID_ADJUSTMENT);

      const others = ids.find(item=>item!==ID_ADVANCE_SUPPLIER&&item!==ID_ADJUSTMENT);

      const advanceOrAdjustmentFound =  typeof(search) === "number" ? true : false;
      const othersConceptFound =  typeof(others) === "number" ? true : false;

      return {
        advanceOrAdjustmentFound,
        othersConceptFound
      }
    }

    function checkIsAssociatedToFr(){
      if(state.grid===null) return false;

      return true
    }

  }, [state.amount, state.amountNotAssociated, state.concepts,state.grid]);

  useEffect(() => {
    let amountToUseFromTheEgress = 0;

    if (state.grid !== null) {
      amountToUseFromTheEgress = state.grid.filteredGrid.array.reduce(
        (total, cxp) => {
          const newTotal =
            total + truncateDecimals(cxp.applied.number, decimals);

          return truncateDecimals(newTotal, decimals);
        },
        0
      );
    }

    const [deductible, noDeductible] = getConceptProportion();

    const amountAssociated = truncateDecimals(
      amountToUseFromTheEgress + state.concepts.total,
      decimals
    );
    const amountNotAssociated = truncateDecimals(
      state.amount - amountAssociated,
      decimals
    );

    setState((current) => ({
      ...current,
      amountAssociated,
      amountNotAssociated,
      deductible,
      noDeductible,
    }));

    ////////////////////////////////////////////////////////////////////////////////////

    function getConceptProportion() {
      // debugger;

      if (state.grid !== null) {
        const deductible = state.grid.filteredGrid.array.filter(
          (cxp) => cxp.hasXml
        );
        const noDeductible = state.grid.filteredGrid.array.filter(
          (cxp) => !cxp.hasXml
        );

        const proportionsDeductible = deductible.map((cxp) => {
          const concepts = cxp.concept.map((concept) => ({
            id: concept.id,
            total: cxp.applied.number,
            percentage: concept.percentage,
          }));

          return concepts;
        });

        const proportionsNoDeductible = noDeductible.map((cxp) => {
          const concepts = cxp.concept.map((concept) => ({
            id: concept.id,
            total: cxp.applied.number,
            percentage: concept.percentage,
          }));

          return concepts;
        });

        const parsedProportionsNoDeductible =
          proportionsNoDeductible.flat(Infinity);
        const parsedProportionsDeductible =
          proportionsDeductible.flat(Infinity);

        return [parsedProportionsDeductible, parsedProportionsNoDeductible];
      }

      return [[], []];
    }
  }, [state.grid, state.concepts, state.payMethod, state.amount]);

  useEffect(()=>{
    setState(current=>({
      ...current,
      amountAssociated:0,
      amountNotAssociated:state.amount,
      concepts:{
        concepts:[],
        total:0,
        isValid:true
      },
      grid:null
    }))
  },[state.includeExpenses]);

  useEffect(()=>{
    (async function(){
      const toleranceApi = await getMovementAssociationTolerance();

      setState(current=>({
        ...current,
        tolerance:toleranceApi
      }))

    })()
  },[]);

  /**
   * Update the id of the provider to use on the creation of the egress
   * @param {number} idProvider - Id of the provider
   * @returns {void}
   */
  const updateProvider = (idProvider) =>
    setState((current) => ({
      ...current,
      provider: idProvider,
      includeExpenses:
        typeof idProvider === "number" ? true : current.includeExpenses,
    }));

  /**
   * Update the pay method to create an egress
   * @param {import("components/general/Forms/Selects/PayMethodsSearchable/types").PayMethod} payMethod
   * @returns {void}
   */
  const updatePayMethod = (payMethod) =>
    setState((current) => ({
      ...current,
      payMethod,
    }));

  const updateAmount = (amount) =>
    setState((current) => ({
      ...current,
      amount,
    }));

  /**
   * Update the toggle to display expenses on the CxP grid
   * @param {boolean} includeExpenses - Flag
   * @returns {void}
   */
  const updateToggle = (includeExpenses) =>
    setState((current) => ({
      ...current,
      includeExpenses,
    }));

  /**
   * Update the date for the egress
   * @param {Date} date - Date to use for egress
   * @returns {void}
   */
  const updateDate = (date) =>
    setState((current) => ({
      ...current,
      date,
    }));

  /**
   * Update the grid associations of the CxP
   * @param {import("components/general/GridCxC/types").OnChangrGrid} grid - Grid information
   * @returns {void}
   */
  const updateGrid = (grid) => {
    const valueToUse = grid.filteredGrid.array.length <= 0 ? null : grid;

    setState((current) => ({
      ...current,
      grid: valueToUse,
    }));
  };

  /**
   * Update the grid concepts association
   * @param {import("customHooks/useConcepts/types").onChangeConcepts} grid - Concept grid
   * @returns {void}
   */
  const updateConceptGrid = (grid) =>
    setState((current) => ({
      ...current,
      concepts: grid,
    }));

  const attemptCreateEgress = async () => {
    /**
     * @type {import("../../../../server/controllers/banks/types").ConceptEgressAssociation[]}
     */
    const conceptsDto = state.concepts.concepts.map((concept) => ({
      amount: concept.ammount,
      id: concept.id,
    }));

    /**
     * @type {import("helpers/Apis/Banks/types").DtoEgressConcept}
     */
    const dtoConcept = {
      bankAccount: state.idBankAccount,
      concepts: conceptsDto,
      payMethod: state.payMethod.code,
      date: dateToDbFormat(state.date), 
      beneficiary:{
        id:null,
        description:state.beneficiary
      }
    };

    const conceptsForEgress = [...state.deductible, ...state.noDeductible];

    const concepts = conceptsForEgress.map((concept) => ({
      amount: concept.total,
      id: concept.id,
    }));

    const conceptsExpenses = state.concepts.concepts.map((concept) => ({
      id: concept.id,
      amount: concept.ammount,
    }));

    const dtoCxp =
      state.grid !== null
        ? state.grid.filteredGrid.array.map((cxp) => ({
            id: cxp.id,
            movement: cxp.applied.number,
            cxp: cxp.import.number,
          }))
        : [];

    const conceptsList = [...concepts, ...conceptsExpenses];

    const dtoConcepts =
      dtoCxp.length <= 0
        ? conceptsList
        : conceptsList.filter((concept) => concept.id === 62||concept.id === 73);

    /**
     * @type {import("../../../../server/controllers/banks/types").DtoAddEgressInvoice}
     */
    const dtoEgressInvoice = {
      payMethod: state.payMethod.code,
      date: dateToDbFormat(state.date),
      idProvider: state.provider,
      idBankAccount: state.idBankAccount,
      cxp: dtoCxp,
      residue: state.amount,
      idMovement,
      concepts: dtoConcepts,
      beneficiary:{
        id:null,
        description:state.beneficiary
      }
    };

    const queryToExecute =
      state.grid === null && typeof state.provider !== "number"
        ? addEgressConcepts
        : addEgressCxp;

    const dtoToUse =
      state.grid === null && typeof state.provider !== "number"
        ? dtoConcept
        : dtoEgressInvoice;

    setState((current) => ({
      ...current,
      isWaitingServer: true,
    }));

    const wasSuccess = await queryToExecute(dtoToUse);

    if (wasSuccess) {
      history.goBack();
    }

    setState((current) => ({
      ...current,
      isWaitingServer: false,
    }));
  };

  /**
   * Get the number of associations made on the current grid of CxP
   * @returns {number}
   */
  function cxpsAssociated() {
    if (state.grid === null) return 0;

    return state.grid.filteredGrid.array.length;
  }

  const setBeneficiary = (beneficiary) =>
    setState((current) => ({
      ...current,
      beneficiary,
      provider: beneficiary === "" ? null : current.provider,
      includeExpenses: beneficiary === "" ? false : current.includeExpenses,
    }));

    const setProviderSocialReason = providerSocialReason => setState(current=>({
      ...current,
      providerSocialReason
    }));

  return {
    ...state,
    setProviderSocialReason,
    updateProvider,
    setBeneficiary,
    updatePayMethod,
    updateAmount,
    updateToggle,
    updateGrid,
    updateConceptGrid,
    attemptCreateEgress,
    updateDate,
    numberCxpAssociated: cxpsAssociated(),
    canDisplayGridCxp:
      (typeof state.provider === "number"
        ? true
        : state.beneficiary.length > 0
        ? true
        : false) &&
      state.amount > 0 &&
      (state.payMethod !== null || state.payMethod !== undefined),
  };
}
