import { Pen, Trash, Save, XMark } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import React from "react";
import { ButtonsOptions, Comment, CommentItem } from "./Containers";
import { useContext } from "react";
import DocumentContext from "../ContextDocument";
import { useRef } from "react";

export const CommentItemSection = () => {
  /**
   * @type{import("../../../../../../types/documentActions").UseDocumentV2I}
   */
  const {
    comments,
    currentComment,
    handleEditingComment,
    validateCommentEditing,
    handleOnCommentChange,
    editComment,
    cancelEditing,
    handleOnDeleteComment,
  } = useContext(DocumentContext);

  const key = useRef(window.crypto.randomUUID());

  return (
    <>
      {/* <CommentItem> */}
      {comments.length > 0
        ? comments.map((comment, index) => {
            return (
              <CommentItem key={`${key.current}-${index}`} id={index}>
                <Comment>
                  {validateCommentEditing(comment, currentComment) ? (
                    <input
                      type="text-area"
                      value={currentComment.comment}
                      maxLength={256}
                      minLength={1}
                      onChange={({ target: { value } }) =>
                        handleOnCommentChange(value)
                      }
                    />
                  ) : (
                    <p>{comment.comment}</p>
                  )}
                </Comment>
                <ButtonsOptions>
                  {comment.isRemovable ? (
                    validateCommentEditing(comment, currentComment) ? (
                      <Icon
                        onClick={() => {
                          cancelEditing();
                        }}
                      >
                        {XMark}
                      </Icon>
                    ) : (
                      <Icon
                        onClick={() => {
                          handleOnDeleteComment(index);
                        }}
                      >
                        {Trash}
                      </Icon>
                    )
                  ) : null}
                  {comment.isEditable ? (
                    validateCommentEditing(comment, currentComment) ? (
                      <Icon
                        onClick={() => {
                          editComment(index);
                        }}
                      >
                        {Save}
                      </Icon>
                    ) : (
                      <Icon
                        onClick={() => {
                          handleEditingComment(index);
                        }}
                      >
                        {Pen}
                      </Icon>
                    )
                  ) : null}
                </ButtonsOptions>
              </CommentItem>
            );
          })
        : null}
      {/* </CommentItem> */}
    </>
  );
};
