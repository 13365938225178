import { trimDate } from '../helpers/dates';
import { URL_BASE } from '../routes/routes';

export const clearForm = idForm => {
    document.getElementById(idForm).reset();
}

///////////////////// [NOTICES] //////////////////////

export const cbSuccessAddNotice = (idForm,btnClose) => {
    clearForm(idForm);
    document.getElementById(btnClose).click();
}

export const setValuesNotices = data => {
    const {
        startDate,
        endDate,
        message,
        advertisementTypeID
    } = data;

    const startDateFormated = trimDate(startDate,'dmy','/');
    const endDateFormated = trimDate(endDate,'dmy','/');

    document.getElementById('validationMessageEdit').value = message;
    document.getElementById('editMessage').value = message;
    document.getElementById(`edition-${advertisementTypeID}`).selected = true;
}

export const getBanksList = async () =>{
    let banks = await fetch(`${URL_BASE}bancos/select-bancos`);
    banks = await banks.json();

    if(banks){
        return banks.banks;
    }
}

/**
 * 
 * @param {string} value - Value that gonna have the hidden (uncontrolled) input
 * @param {string} label - Text that gonna appear on the select
 * @param {string} id - Id of the of the input
 */
export function setBank(value,label,id){
    const dom = document.querySelector(`#user_select_${id} .saiko-select__single-value`);
    const hiddenInput = document.getElementById(id);

    dom.innerText = label;
    hiddenInput.value = value;
}

/**
 * Set the toggle of the component toggle
 * 
 * @param {number} value - 1 for active, 0 inactive
 * @param {string} checkbox - id of the checkbox html element
 * @param {string} label - id of the label html element
 * @param {string} hiddenInputId - id of the hidden input html element
 */
export function setToggle(value,checkbox,label,hiddenInputId){
    if(value===1||value===true){
        document.getElementById(checkbox).checked = true;
        document.getElementById(label).innerText = 'Activo';
        document.getElementById(hiddenInputId).value = 1;
    }else{
        document.getElementById(checkbox).checked = false;
        document.getElementById(label).innerText = 'Inactivo';
        document.getElementById(hiddenInputId).value = 0; 
    }
}

/**
 * Set the input 
 * 
 * @param {string} id - ID of the dom element
 * @param {*} value 
 */
export function setValue(id,value){
    const input = document.getElementById(id);

    if(input===null && value ===null) return;

    input.value = value;
    // input.placeholder = value;
}