import { ModalEditBankAccount } from "components/general/ModalEditBankAccount";
import TableContext from "context/Table/TableContext";
import usePermissions from "customHooks/usePermissions";
import React from "react";
import { useContext } from "react";
import { RedirectButton } from "../../../components/individual/buttons/Buttons";

function Options({ row }) {
  const { refetch, setReload } = useContext(TableContext);
  const { checkPermission } = usePermissions();

  return (
    <div className="d-flex justify-content-end my-2">
      <RedirectButton
        text="Movimientos"
        css="mr-2"
        link={`bancos/movimientos/${row.bankAccountID}`}
      />

      <RedirectButton
        text="Conciliación"
        css="mr-2"
        link={`bancos/conciliacion?cuenta_banco=${row.bankAccountID}`}
      />
      <RedirectButton
        text="Estado de cuenta"
        css="mr-2"
        link={`bancos/estado-de-cuenta?cuenta=${row.bankAccountID}`}
      />

      {checkPermission("802701fc-c731-40d0-ba4f-50e65dfdb8a8") ? (
        <ModalEditBankAccount
          idBankAccount={row.bankAccountID}
          onUpdated={() => setReload(!refetch)}
        />
      ) : null}
    </div>
  );
}

export default Options;
