import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextBanks } from "..";

export const ClaveBank = () => {
  const { selected } = useContext(ContextBanks);

  if (selected === null)
    return (
      <>
        <Label required>Clave SAT</Label>
        <p>Selecciona la razon social del banco</p>
      </>
    );

  return (
    <>
      <Label>Clave SAT</Label>
      <p>{selected.claveSat === null ? "ND" : selected.claveSat}</p>
      
    </>
  );
};
