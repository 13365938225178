import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";

export const OdcAssociatedFiles = () => {
  const { idDocument } = useParams();

  const { modulo } = useQueryParams();

  const breadcrum = useRef(
    modulo === "contabilidad"
      ? [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/contabilidad/oc",
            text: "Contabilidad",
          },
          {
            route: `/contabilidad/oc`,
            text: "Provición de OC",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]
      : [
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: "/administracion/ordenes-compra",
            text: "Administracion",
          },
          {
            route: `/administracion/ordenes-compra`,
            text: "Ordenes de compra",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]
  );

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        padding: "0 0 1rem 0",
      }}
    >
      <BreadCrumV2 path={breadcrum.current} />

      <div className="container">
        <AssociateFiles
          idRegister={+idDocument}
          idTypeEntity={1}
          pathUpload={`documentos/orden-compra/orden-compra-id-${idDocument}`}
        />
      </div>
    </div>
  );
};
