import InformativeTooltip from "components/individual/InfoTooltip";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import Checkbox from "components/individual/inputs/Checkbox";
import LoginContext from "context/Login/LoginContext";
import { toggleCommentNotifications } from "helpers/Apis/tickets";
import React from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Label } from "structure/Document";
import style from '../styles.module.scss'

/**
 * Render a toggle to able/disable notification comments
 * @param {import('../types').PropsNotificationComments} props - Props
 * @returns {JSX.Element}
 */
export default function NotificationsComments({ notificateTo = [], idTicket }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { userInfo } = useContext(LoginContext);

  useEffect(() => {
    const isOnList = notificateTo.includes(userInfo[0].userID);

    if (isOnList) setIsChecked(true);
  }, [notificateTo, userInfo]);

  const handleToggle = async () => {
    setIsUpdating(true);
    const wasUpdated = await toggleCommentNotifications(idTicket);

    if (wasUpdated) setIsChecked(!isChecked);

    setIsUpdating(false);
  };

  if (isUpdating)
    return (
      <Spinner text={<LoadingText>Actualizando</LoadingText>} hidden={false} />
    );

  if (userInfo.length <= 0) return;

  return (
    <div>
      <Label>
        Notificaciones
        <InformativeTooltip>
          <p>
            <ol>
              <li>
                Activo: Se reciben notificaciones cuando alguien realiza un
                comentario
              </li>
              <li>
                Inactivo: No se reciben notificaciones cuando alguien realiza un
                comentario
              </li>
            </ol>
          </p>
        </InformativeTooltip>
      </Label>

      <label className={style.notificationsToggle}>
        <Checkbox
          checked={isChecked}
          className="mx-0"
          onChange={handleToggle}
        />
        {isChecked ? "Activas" : "Desactivadas"}
      </label>

      {/* <ToggleComponent isChecked={isChecked} onChange={handleToggle} /> */}
    </div>
  );
}
