import React, { useContext } from "react";
import style from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";
import { MobileMenuContext } from "..";
import { useHistory } from "react-router-dom";

/**
 * Render a navigation item for the menu on mobile
 * @param {import('./types').PropsNavigationItem} props - Props
 * @returns {JSX.Element}
 */
export default function NavigationItem(props) {
  const hook = useContext(MobileMenuContext);

  const history = useHistory();

  const handleOnClick = () => {
    const linkCallback = props?.link || null;

    if (linkCallback !== null) {
      history.push(props.link);

      hook.closeMenu();

      return;
    }

    const callback = props?.onClick || null;

    if (props.subMenu) {
      hook.navigateMenu(props.subMenu);
      return;
    }

    if (callback !== null) {
      props.onClick();
    }
  };

  return (
    <nav onClick={handleOnClick} className={style.navItem}>
      <div>
        <Icon {...props} />

        <span>{props.label}</span>
      </div>

      {props.subMenu ? <Icon icon="right" /> : null}
    </nav>
  );
}
