import { GetCustomer } from "helpers/Apis/Directory";
import { handleAllSeatledPromise } from "helpers/Apis/fetch";
import {
  addMaterial,
  generateOcMaterial,
  getMaterials,
  getProyect,
} from "helpers/Apis/proyects";
import { parseToFolio } from "helpers/documents";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";

/**
 * @type {import("./types").StateMaterials}
 */
const INITIAL_STATE = {
  status: "loading",
  materials: [],
  customer: undefined,
  proyect: undefined,
  position: undefined,
  purchases: 0,
  costEvent: 0,
  purchasesBought: 0,
  residue: 0,
  grid: {},
  atLeastOneItem: false,
  isValidGrid: false,
  refetch: false,
};

/**
 * Handle the materials of a position
 * @param {number} idPosition - Id of the position
 * @returns {import("./types").ReturnUseMaterials}
 */
export default function useMaterials(idPosition) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {

      setState(INITIAL_STATE);

      const proyect = await getProyect(null, idPosition);

      const materials = await getMaterials(idPosition, proyect.id);

      const customer = await GetCustomer(proyect.idClient);

      let proyectParsed = { ...proyect };
      delete proyectParsed.positions;

      const position = proyect.positions.find(
        (position) => position.id === idPosition
      );

      const purchases = materials.materials.reduce(
        (sum, item) => (sum += item.cost * item.initialQuantity),
        0
      );

      const purchasesBought = materials.materials.reduce(
        (sum, item) =>
          (sum += item.cost * (item.initialQuantity - item.residueQuantity)),
        0
      );

      setState((current) => ({
        ...current,
        position,
        proyect: proyectParsed,
        purchasesBought,
        materials: materials.materials,
        status: "none",
        customer,
        purchases,
      }));
    })();
  }, [state.refetch]);

  useEffect(() => {
    calculateResidueAndValidate();

    /////////////////////////////////////////////////
    function calculateResidueAndValidate() {
      const residue =
        state.purchases - (state.purchasesBought + state.costEvent);

      let allGridItemsHasData = true;

      Object.entries(state.grid).forEach(([id, item]) => {
        const keysFound = Object.keys(item).length;
        if (keysFound < 2) {
          allGridItemsHasData = false;
        }
      });

      setState((current) => ({
        ...current,
        residue,
        isValidGrid:
          residue >= 0 &&
          allGridItemsHasData &&
          Object.keys(current.grid).length > 0,
      }));
    }
  }, [state.costEvent, state.purchases, state.purchasesBought, state.grid]);

  useEffect(() => {
    let unrefState = { ...state.grid };

    Object.entries(state.grid).forEach(([id, item]) => {
      if (Object.keys(item).length === 0) {
        delete unrefState[id];
      }
    });

    const atLeastOneItem = Object.keys(unrefState).length > 0;

    let costEvent = 0;

    Object.entries(state.grid).forEach(([id, item]) => {
      const material = state.materials.find((record) => record.id === +id);

      const itemEvent = material.cost * +item?.quantity || 0;

      costEvent += itemEvent;
    });

    setState((current) => ({
      ...current,
      atLeastOneItem,
      costEvent,
    }));
  }, [state.grid]);

  /**
   * Create material into position
   * @param {import("../../../../types/proyects/materials").DtoCreateMaterial} dto - Information to create the proyect
   * @param {import("structure/FormDocumentItems/types").DocumentItemForm|null} [productOverview=null] - Overview of the product from the form
   */
  async function attemptAddMaterial(dto, productOverview) {
    setState((current) => ({
      ...current,
      status: "adding",
    }));

    const wasCreated = await addMaterial(dto, productOverview);

    if (!wasCreated) {
      setState((current) => ({
        ...current,
        status: "none",
      }));
      return;
    }

    const updatedMaterials = await getMaterials(
      state.position.id,
      state.proyect.id
    );

    setState((current) => ({
      ...current,
      status: "none",
      materials: updatedMaterials.materials,
      refetch: !current.refetch,
    }));
  }

  /**
   * Set the material grid items to set on
   * @param {number} supplier - Id of the supplier
   * @param {number} idMaterial - Id of the material
   */
  const setSupplier = (supplier, idMaterial) => {
    if (typeof supplier !== "number") {
      setState((current) => {
        const itemUnrefed = { ...current.grid[idMaterial] };
        delete itemUnrefed.provider;
        return {
          ...current,
          grid: {
            ...current.grid,
            [idMaterial]: itemUnrefed,
          },
        };
      });
      return;
    }

    setState((current) => ({
      ...current,
      grid: {
        ...current.grid,
        [idMaterial]: {
          ...current.grid[idMaterial],
          provider: supplier,
        },
      },
    }));
  };

  /**
   *
   * @param {string} quantity - Quantity
   * @param {number} idMaterial
   */
  const setQuantity = (quantity, idMaterial) => {
    if (quantity === "") {
      setState((current) => {
        const itemUnrefed = { ...current.grid[idMaterial] };
        delete itemUnrefed.quantity;
        return {
          ...current,
          grid: {
            ...current.grid,
            [idMaterial]: itemUnrefed,
          },
        };
      });
      return;
    }

    setState((current) => ({
      ...current,
      grid: {
        ...current.grid,
        [idMaterial]: {
          ...current.grid[idMaterial],
          quantity,
        },
      },
    }));
  };

  async function validateGrid() {
    const querys = Object.entries(state.grid).map(async ([id, item]) => {
      const materialFound = state.materials.find(
        (item) => item.idCatalogue === +id
      );

      const folio = parseToFolio(materialFound.idCatalogue);

      const schemaValidation = yup.object().shape({
        provider: yup
          .number()
          .required(`Proveedor obligatorio para el material ${folio}`)
          .typeError(`Proveedor inválido para el material${folio}`)
          .min(1, `Proveedor inválido para el material ${folio}`),
        quantity: yup
          .number()
          .min(1, `Mínimo 1 unidad para el material ${folio}`)
          .required(`Cantidad obligatoria para el material ${folio}`)
          .typeError(`Cantidad inválida para el material ${folio}`)
          .max(
            materialFound.residueQuantity || 0,
            `Máximo ${materialFound.residueQuantity} unidades para el material ${folio}`
          ),
      });

      try {
        const result = await schemaValidation.validate({
          provider: item.provider,
          quantity: +item.quantity,
        });

        return result;
      } catch (e) {
        throw e;
      }
    });

    const result = await Promise.allSettled(querys);

    const data = handleAllSeatledPromise(result);

    if (data.errors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `<p>Corrigue los siguientes errores de la grid</p>
        <ol>
        ${data.errors.reduce(
          (html, error, i) => (html += `<li>${i + 1}) ${error.message}</li>`),
          ""
        )}
        </ol>
        `,
      });
      return false;
    }

    return true;
  }

  async function attemptCreateOc() {
    if (state.materials.length <= 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Agrega al menos un material a la posición",
      });
      return;
    }

    if (!state.atLeastOneItem) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Agrega al menos una cantidad y su proveedor al grid",
      });
      return;
    }

    const isValidGrid = await validateGrid();

    if (!isValidGrid) return;
  }

  async function attemptCreateOc() {
    const grid = Object.entries(state.grid).map(([id, item]) => ({
      id: +id,
      idSupplier: item.provider,
      currentQuantity: +item.quantity,
    }));

    setState((current) => ({
      ...current,
      status: "updating",
    }));

    const wasGenerated = await generateOcMaterial(grid);

    setState((current) => ({
      ...current,
      refetch: wasGenerated ? !current.refetch : current.refetch,
      status: "none",
    }));
  }

  return {
    ...state,
    attemptAddMaterial,
    setSupplier,
    setQuantity,
    attemptCreateOc,
  };
}
