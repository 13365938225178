import * as yup from "yup";

const reference = yup.string()
    .max(30,'Maximo 30 caracteres')
    .nullable(true)
    .transform((casted,input)=>casted.length===0 ? null : input)

const concept = yup.string()
    .max(256,'Maximo 256 caracteres');

const importConcept = yup.string()
    .required('Campo obligatorio')
    .typeError('Dato invalido');

const registerDate = yup.string()
    .required('Fecha obligatoria')
    .typeError('Dato invalido')

/**
 * Schema when adding movements on the fast way
 */
export const fastAddSchema = yup.object().shape({
    reference,
    concept,
    importConcept,
    registerDate
});