import SelectStatus from "components/individual/SelectStatus";
import React, { Fragment } from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import useEnterpriseInfo from "../EnterpriseInfo/useEnterpriseInfo";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function Robots() {
  const hook = useEnterpriseInfo();

  if (hook.isLoading) return <></>;

  return (
    <div className={ui.container}>
      <Fragment>
        <div>
          <Label>Robot 1</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(1, value)}
            value={hook.robot1}
          />
        </div>

        <div>
          <Label>Robot 2</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(2, value)}
            value={hook.robot2}
          />
        </div>

        <div>
          <Label>Robot 3</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(3, value)}
            value={hook.robot3}
          />
        </div>

        <div>
          <Label>Robot 4</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(4, value)}
            value={hook.robot4}
          />
        </div>

        <div>
          <Label>Robot 5</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(5, value)}
            value={hook.robot5}
          />
        </div>

        <div>
          <Label>Robot 6</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(6, value)}
            value={hook.robot6}
          />
        </div>

        <div>
          <Label>Robot 7</Label>
          <SelectStatus
            onChange={(value) => hook.setRobot(7, value)}
            value={hook.robot7}
          />
        </div>

        <div className={ui.containerSave}>
          {hook.isUpdating ? (
            <Spinner text={"Actualizando"} hidden={false} />
          ) : (
            <ButtonV2
              onClick={() => hook.attemptUpdateEnterpriseInfo(hook.state)}
            >
              <span>Actualizar</span>
              <Icon icon="save" />
            </ButtonV2>
          )}
        </div>
      </Fragment>
    </div>
  );
}
