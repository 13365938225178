import LoginContext from "context/Login/LoginContext";
import { uploadFilesToRepository } from "helpers/Apis/associateFilesV2";
import { useState, useEffect, useRef, useContext } from "react";


/**
 * Handle the operation of files without rendering a file input on the dom
 * @param {import("./types").UseFileInput} param - Params
 * @returns {import("./types").ReturnUseFileInput}
 */
const useFileInput = ({
  onFilesSelected = () => {},
  allowedExtensions,
  maxFiles = null,
  files = []
}) => {
  const session = useContext(LoginContext);

  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(files);
  const inputRef = useRef(null);

  useEffect(()=>{
    setSelectedFiles(files)
  },[files]);

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    const updatedFiles = updateFiles(newFiles)
    setSelectedFiles(updatedFiles);
    onFilesSelected(updatedFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const newFiles = Array.from(event.dataTransfer.items)
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile());

    const updatedFiles = updateFiles(newFiles);
    setSelectedFiles(updatedFiles);
    onFilesSelected(updatedFiles);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const updateFiles = (newFiles) => {

    if (allowedExtensions && allowedExtensions.length > 0) {
      const allowedExtensionsSet = new Set(
        allowedExtensions.map((ext) => ext.toLowerCase())
      );
      newFiles = Array.from(newFiles).filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return allowedExtensionsSet.has(extension);
      });
    }

    const currentFilesCount = selectedFiles.length;

    if (maxFiles !== null) {
      const remainingCapacity = maxFiles - currentFilesCount;

      if (remainingCapacity <= 0) {
        console.warn(
          `Maximum ${maxFiles} file(s) reached. Ignoring additional files.`
        );
        return selectedFiles;
      }

      const filesToAdd = newFiles.slice(0, remainingCapacity);
      return selectedFiles.concat(filesToAdd);
    }

    return selectedFiles.concat(Array.from(newFiles));
  };

  const deleteFile = (index) => {
    const updatedFiles = selectedFiles.filter((file,i)=>index!==i);

    onFilesSelected(updatedFiles);
    setSelectedFiles(updatedFiles);
  };

  useEffect(() => {
    const inputElement = inputRef.current
    inputElement.type = "file";
    inputElement.style.display = "none";

    // Set the 'accept' attribute based on allowedExtensions
    if (allowedExtensions && allowedExtensions.length > 0) {
      const acceptTypes = allowedExtensions.map((ext) => `.${ext}`).join(",");
      inputElement.accept = acceptTypes;
    }

    // Set the 'multiple' attribute if maxFiles is not provided or if it's greater than 1
    if (maxFiles === null || maxFiles > 1) {
      inputElement.multiple = true;
    }

    // Append the input element to the DOM before attaching the event listener
    document.body.appendChild(inputElement);

    // Attach the event listeners to the hidden input element
    inputElement.addEventListener("change", handleFileChange);
    inputElement.addEventListener("drop", handleDrop);

    // Store the input element reference in the ref for later use
    inputRef.current = inputElement;

    // Clean up the input element on component unmount
    return () => {
      inputElement.removeEventListener("change", handleFileChange);
      inputElement.removeEventListener("drop", handleDrop);
      document.body.removeChild(inputElement); // Remove the input element from the DOM
    };
  }, [onFilesSelected, allowedExtensions, maxFiles, selectedFiles]);

  const openFilePicker = () => {
    // Trigger a click on the hidden input element
    inputRef.current.click();
  };

  /**
   *
   * @param {import("helpers/Apis/typesAssociateFilesV2").AssociateFileEntity} entity - Entity
   * @param {number} idRegister - Id of the group where belongs the file
   * @param {string} path - Folder to upload on the repository
   * @returns {Promise<boolean>}
   */
  const attemptUpload = async (entity, idRegister, path) => {

    setIsUploading(true)

    const wasUploaded = await uploadFilesToRepository(
      selectedFiles,
      {
        fullName: session.userInfo[0].fullName,
        id: session.userInfo[0].userID,
      },
      idRegister,
      entity,
      path
    );

    setIsUploading(false);

    return wasUploaded
  };

  return {
    selectedFiles,
    isDragging,
    deleteFile,
    openFilePicker,
    handleDrop,
    handleDragEnter,
    handleDragLeave,
    isUploading,
    attemptUpload,
    inputRef
  };
};

export default useFileInput;
