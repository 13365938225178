import useDialog from "customHooks/useDialog";
import React from "react";
import { useState } from "react";
import Dialog from "structure/Dialog";
import {
  Email,
  From,
  EmailAppend,
  Subject,
  Files,
  TextBoxEmail,
  EmailExtensions,
  FileFromPc,
  EmailItem,
  SendEmailBtn,
} from "structure/Email";
import style from './styles.module.scss';
import { Success } from "helpers/alerts";
import usePermissions from "customHooks/usePermissions";

/**
 *
 * @param {import("components/general/GenericEmailModal/types").PropsGenericEmailModal} props - Props
 * @returns
 */
export default function SendEmail({
  forwardRef,
  cc = [],
  to = [],
  message = null,
  children = null,
  subject = null,
  validExtensions = ["png", "jpg", "jpeg", "pdf", "xml"],
  onSendedEmail = async () => {},
  files = async () => {},
}) {
  const dialog = useDialog();

  const [isOpen, setIsOpen] = useState(false);

  const { checkPermission } = usePermissions();

  const handleOpen = (isOpen) => {
    setIsOpen(isOpen);
    if (isOpen) {
      dialog.open();
    } else {
      dialog.close();
    }
  };

  if(!checkPermission('bddb488c-8fdb-48e4-b546-0fffc2b19ed8'))return <></>


  return (
    <>
      <div ref={forwardRef} onClick={() => handleOpen(true)}>
        {children}
      </div>

      <Email
        validExtensions={validExtensions}
        files={files}
        onSendedEmail={async () => {
          await onSendedEmail();
          dialog.close();
          Success(()=>{},'Correo enviado');
        }}
        cc={cc}
        message={message}
        subject={subject}
        to={to}
      >
        {() => (
          <Dialog
            forwardRef={dialog.refDialog}
            title="Enviar correo"
            width={40}
            closeCallback={() => handleOpen(false)}
            footer={
              <div className={style.footerEmail}>
                <EmailExtensions />
                <SendEmailBtn />
              </div>
            }
          >
            <div className="d-flex">
              <From className="mr-3" />

              <FileFromPc className="mx-3" />
            </div>
            <EmailAppend type="to" atLeastOneEmail={true} />
            <EmailItem type="to" />
            <EmailAppend type="cc" />
            <EmailItem type="cc" />
            <Subject />
            <Files />
            <TextBoxEmail />
          </Dialog>
        )}
      </Email>
    </>
  );
}
