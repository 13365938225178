import { calculateTcV2, mnCurrency, truncateDecimals } from "helpers/money";
import { useState } from "react";
import { useEffect } from "react";
import Fuse from "fuse.js";

/**
 * Custom hook in order to handle the grid for associations
 * @param {import("./types").UseGridCxcProps} param - Param
 * @returns
 */
export default function useGridCxC({
  availableBalance = 0,
  currency,
  tolerance = 0,
  inyectionInfo = async () => [],
  onChange = () => {},
  search = null,
  searchValue = "",
  decimals = 2,
  refetch = false,
}) {
  const [state, setState] = useState({
    isLoading: true,
    grid: {},
    refetch: false,
    search: {},
  });

  useEffect(() => {
    if (searchValue === "") {
      setState((state) => ({
        ...state,
        search: {},
      }));
      return;
    }

    if (
      search === null ||
      Object.keys(state.grid).length <= 0 ||
      searchValue === ""
    )
      return;

    const list = Object.entries(state.grid).map(([key, item]) => item);

    const fuse = new Fuse(list, search);

    const result = fuse.search(searchValue);

    const itemsParsed = result.map((item) => item.item);

    let indexed = itemsParsed.reduce((indexed, item) => {
      return {
        ...indexed,
        [item.uuidFrontend]: state.grid[item.uuidFrontend],
      };
    }, {});

    setState((state) => ({
      ...state,
      search: indexed,
    }));
  }, [search]);

  useEffect(() => {
    inyectionInfo().then((data) => {
      const parsedData = data.reduce((indexed, item) => {
        const uuidFrontend = window.crypto.randomUUID();

        return {
          ...indexed,
          [uuidFrontend]: {
            ...item,
            tc: {
              number: 0,
              text: "$0.00",
            },

            applied: {
              number: 0,
              text: "$0.00",
            },
            newResidue: {
              number: item.residue.number,
              text: mnCurrency(item.residue.number),
            },
            import: {
              number: 0,
              text: "$0.00",
            },
            isValidImport: true,
            uuidFrontend,
          },
        };
      }, {});

      setState((state) => ({
        ...state,
        isLoading: false,
        grid: parsedData,
      }));
    });
  }, [refetch]);

  const recalculateGridInfo = (grid) => {
    const activeElements = Object.entries(grid).map(([key, item]) => item);

    const calculations = activeElements.map((item) => {
      // Same currency, no need the calculate exchange rate
      if (item.currency === currency) {
        return {
          ...item,
          applied: {
            number: truncateDecimals(item.import.number, decimals),
            text: item.import.text,
          },
          newResidue: {
            number: truncateDecimals(
              item.residue.number -
                item.import.number,
              decimals
            ),
            text: mnCurrency(item.residue.number - item.import.number),
          },
        };
      }

      if (item.currency === "MXN") {
        if (item.tc.number <= 0) return item;

        const applied = truncateDecimals(
          item.import.number / item.tc.number,
          decimals
        );
        return {
          ...item,
            // applied: {
            //   number: applied,
            //   text: mnCurrency(applied),
            // },
          newResidue: {
            number: truncateDecimals(
              item.residue.number -
                item.import.number,
              decimals
            ),
            text: mnCurrency(item.residue.number - item.import.number),
          },
        };
      }

      const applied = truncateDecimals(
        item.import.number * item.tc.number,
        decimals
      );

      return {
        ...item,
        // applied: {
        //   number: applied,
        //   text: mnCurrency(applied),
        // },
        newResidue: {
          number: truncateDecimals(
            item.residue.number -
              item.import.number,
            decimals
          ),
          text: mnCurrency(item.residue.number - item.import.number),
        },
      };
    });

    // For instance: Can't pay 200USD for a service of 100USD, that means you're over paying! (at least one element of the grid)
    const isValidImport =
      calculations.filter((item) => item.import.number > item.residue.number)
        .length > 0
        ? false
        : true;

    const newResidue =
      availableBalance -
      calculations.reduce(
        (residue, item) =>
          (residue += truncateDecimals(item.applied.number, decimals)),
        0
      );

    const indexed = calculations.reduce(
      (index, item) => ({
        ...index,
        [item.uuidFrontend]: item,
      }),
      {}
    );

    const elementsWithAssociation = calculations.filter(
      (item) => item.import.number > 0
    );

    const isValidApplied =
      elementsWithAssociation.filter(
        (item) => item.import.number > 0 && item.applied.number <= 0
      ).length >= 1
        ? false
        : true;

    const totalApplied = elementsWithAssociation.reduce(
      (total, item) =>
        (total += truncateDecimals(item.applied.number, decimals)),
      0
    );

    setState((state) => ({
      ...state,
      grid: {
        ...state.grid,
        ...indexed,
      },
    }));

    const DTO = {
      isValidImport,
      isUsedAllResidue: newResidue === 0 ? true : false,
      isNegativeResidue: newResidue < 0,
      newResidue,
      isValidResidue: newResidue >= 0,
      filteredGrid: {
        array: elementsWithAssociation,
        indexed: elementsWithAssociation.reduce(
          (indexed, item) => ({
            ...indexed,
            [item.uuidFrontend]: item,
          }),
          {}
        ),
      },
      isValidApplied,
      totalApplied,
      // TODO: Agregar la validacion de tolerancia
      isValidTolerance: true,
      isPayable: availableBalance >= totalApplied,

      // TODO: Tolerancia
      tolerance: 0,
    };
    onChange(DTO);
  };

  /**
   * Update the amount to apply to a one element of the grid
   * @param {number} balance - Balance to apply
   * @param {string} uuid - UUID of the front-end in order to know which element will have the effect of the grid
   */
  const updateAppliedBalance = (balance, uuid) => {
    const parsedBalance = truncateDecimals(balance, decimals);

    const unref = { ...state.grid };

    const tc =
      parsedBalance === 0
        ? 0
        : unref[uuid].currency === currency
        ? 1
        : unref[uuid].tc.number;

    unref[uuid].import.number = parsedBalance;
    unref[uuid].import.text = mnCurrency(parsedBalance);
    unref[uuid].tc.number = tc;
    unref[uuid].tc.text = mnCurrency(tc, 4);

    if (parsedBalance <= 0) {
      unref[uuid].newResidue.number = truncateDecimals(unref[uuid].residue.number,2);
      unref[uuid].newResidue.text = mnCurrency(unref[uuid].residue.number);
      unref[uuid].applied.number = 0;
      unref[uuid].applied.text = "$0.00";
    }

    unref[uuid].isValidImport = parsedBalance <= unref[uuid].residue.number;

    setState((state) => ({
      ...state,
      grid: unref,
    }));

    recalculateGridInfo(unref);
  };

  const updateTcApplied = (tc, uuid) => {
    const parsedTc = truncateDecimals(tc, decimals);

    const unref = { ...state.grid };

    unref[uuid].tc.number = parsedTc;
    unref[uuid].tc.text = mnCurrency(parsedTc, 4);

    setState((state) => ({
      ...state,
      grid: unref,
    }));

    recalculateGridInfo(unref);
  };

  /**
   * Update the amount applied to that CxC or CxP
   * @param {number} applied - Amount to pay to the CxC/CxP in case different currencies
   */
  const updateAmountApplied = (applied, uuid) => {
    // debugger;
    const element = state.grid[uuid];

    const unrefList = { ...state.grid };

    const tc = calculateTcV2(
      {
        fromCurrency: element.currency,
        toCurrency: currency,
      },
      element.import.number,
      applied,
      4
    );

    unrefList[uuid].tc.number = tc;
    unrefList[uuid].tc.text = mnCurrency(tc, 4);
    unrefList[uuid].applied.number = applied;
    unrefList[uuid].applied.text = applied;

    setState((state) => ({
      ...state,
      grid: unrefList,
    }));

    recalculateGridInfo(unrefList);
  };

  useEffect(() => {
    recalculateGridInfo(state.grid);
  }, [availableBalance]);

  return {
    ...state,
    updateAppliedBalance,
    updateAmountApplied,
    updateTcApplied,
    listToUse:
      Object.keys(state.search).length <= 0 ? state.grid : state.search,
  };
}
