import React, { useContext } from "react";
import { ContextCancelInvoice } from "../CancelInvoiceButton";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

export default function CancelButton() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.state.isCancelling)
    return <Spinner text={"Cancelando factura"} hidden={false} />;

  return (
    <ButtonV2
      onClick={hook.attemptCancel}
      disabled={
        !hook.state.isCancelable ||
        hook.state.motiveId === null ||
        hook.state.facturamaMotive === null
      }
    >
      <span>Cancelar factura</span>
      <Icon icon="save" />
    </ButtonV2>
  );
}
