import React, { useEffect } from "react";

export function RadioButton({
  options,
  attributeId,
  attributeValue,
  attributeText,
  onClick = () => {},
  optSelected = null,
  name,
  id,
  idElements = "",
  forwardRef = {},
  errors = {},
  disabled = false
}) {
  useEffect(() => {
    const radioButton = document.getElementById(`${id}-${optSelected}`);

    if (optSelected !== null && radioButton !== null) {
      radioButton.checked = true;
    }
  }, [optSelected, options]);

  return (
    <>
      {options.map((option) => {
        return (
          <div
            className="radioBtnElement"
            id={`${idElements}-${option[attributeId]}`}
            // onClick={() => onClick(option)}
          >
            <input
              type="radio"
              disabled={disabled}
              id={`${id}-${option[attributeId]}`}
              name={name}
              value={option[attributeValue]}
              ref={forwardRef}
              onClick={() => onClick(option)}
            />
            <label
              className="cursorPointer"
              htmlFor={`${id}-${option[attributeId]}`}
            >
              {option[attributeText]}
            </label>
          </div>
        );
      })}
    </>
  );
}
