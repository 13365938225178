import React, { useRef, useContext } from "react";
import WinQuoteContext from "../../ContextWinQuote";
import { ItemConteniner } from "../Containers";
import { ItemsBodySection } from "../ItemsBodySection";
import { ItemsFooterSection } from "../ItemsFooterSection";

export const PriceItems = () => {
  const { items, getNumbers, tc, interfaceControl, moneyInfo } =
    useContext(WinQuoteContext);
  const menu = interfaceControl.menu;
  const index = interfaceControl.indexActiveMenu;

  const key = useRef(`${window.crypto.randomUUID()}`);

  return (
    <>
      {menu[index].idType !== 6 ? (
        items?.map((item, j) => (
          <ItemConteniner key={`${key.current}-${j}`}>
            <ItemsBodySection
              sku={item.sku}
              description={item.description}
              quantity={item.quantity}
              unitImport={getNumbers(item).unitary}
              totalImport={getNumbers(item).subTotal}
              currency={moneyInfo.currency.value}
            />
            <ItemsFooterSection
              discount={getNumbers(item).discount}
              ivaNumber={getNumbers(item).iva}
              ivaPercentage={getNumbers(item).ivaPercentage}
              totalImport={getNumbers(item).subTotal}
              unitImport={getNumbers(item).unitary}
              tc={tc.number}
              index={index}
              apperEyeButton={true}
              apperEditButton={false}
              apperTrashButton={false}
            />
          </ItemConteniner>
        ))
      ) : interfaceControl.needContract ? (
        items?.map((item, j) => (
          <ItemConteniner key={`${key.current}-${j}`}>
            <ItemsBodySection
              sku={item.sku}
              description={item.description}
              quantity={item.quantity}
              unitImport={getNumbers(item).unitary}
              totalImport={getNumbers(item).subTotal}
            />
            <ItemsFooterSection
              discount={getNumbers(item).discount}
              ivaNumber={getNumbers(item).iva}
              ivaPercentage={getNumbers(item).ivaPercentage}
              totalImport={getNumbers(item).subTotal}
              unitImport={getNumbers(item).unitary}
              index={index}
              action="Ganar"
            />
          </ItemConteniner>
        ))
      ) : (
        <div className="d-flex justify-content-center ">
          <h2>No tiene contrato</h2>
        </div>
      )}
    </>
  );
};
