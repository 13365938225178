import React, { useContext } from "react";
import { FormContext } from "./Form";
import { Informative } from "../../Inputs/General/Index";
import { CFDIArea } from "./Styles";

export default function CFDI({ value, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="Uso de CFDI"
            value={!value ? document.cfdi.description : value}
          />
        </div>
      ) : (
        <CFDIArea>
          <Informative
            readonly={true}
            label="Uso de CFDI"
            value={!value ? document.cfdi.description : value}
          />
        </CFDIArea>
      )}
    </>
  );
}
