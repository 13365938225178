import { ContextConfig } from "App";
import LoginContext from "context/Login/LoginContext";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Evalua los permisos del usuario
 * @param {string|string[]} modulePermission - UUID del permiso. Por ejemplo cd89a7a9-066d-4961-afc6-0d6d190ff97b (Directorio) si el usuario no tiene acceso a ese id, lo retorna a otra pagina para que no tenga acceso a la pantalla del directorio
 * @returns {import("./types").ReturnUsePermissions}
 */
export default function usePermissions(modulePermission = null) {
  const { rolPermitions, isAdmin } = useContext(LoginContext);

  const { checkPermissions } = useContext(ContextConfig);

  const history = useHistory();

  /**
   * Check if the user can have access to a determinated module of the system (button, modal,etc...)
   * @param {string} uuid - UUID of the permission to check for the access
   * @returns {boolean} If true, user has access to the module of the system
   */
  const checkPermission = (uuid,debug=false) => {
    // 0 No checar
    // 1 Si checar
    if (!checkPermissions || isAdmin) return true;

    // If is empty, means data haven't beeen load, let pass until useEffect executes again
    if (Object.keys(rolPermitions).length <= 0) return true;

    if(debug) console.log({
      permisoToken:rolPermitions[uuid],
      permiso:uuid,
      
    })

    if (rolPermitions[uuid] === undefined) return false;

    return true;
  };

  /**
   * Check the access OF THE SCREEN (module)
   */
  useEffect(() => {
    if (modulePermission === null) return;

    const permissionList = Array.isArray(modulePermission) ? modulePermission : [modulePermission];

    const querys = permissionList.map(permission=>checkPermission(permission));

    const atLeastOneWithAccess = querys.some(element => element === true);

    if (!atLeastOneWithAccess && !isAdmin) {
      history.push({
        pathname: "/403",
      });
      return;
    }
  }, [modulePermission, rolPermitions]);

  return {
    checkPermission,
  };
}
