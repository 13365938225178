import React, { Fragment, useContext, useRef } from "react";
import { ContextAuthsInvoice } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ui from "./styles.module.scss";
import MoreInformation from "../MoreInformation";
import { Pagination } from "components/individual/Pagination";

export default function Table() {
  const hook = useContext(ContextAuthsInvoice);

  const key = useRef(`${window.crypto.randomUUID()}`);

  if (hook.isLoading) return <Spinner text={"Buscando"} hidden={false} />;

  if (hook.auths.length <= 0) return <h1 className="text-center my-5">No hay resultados con los filtros</h1>;

  return (
    <div className={ui.table}>
      <div className={ui.header}>
        <p>No. pedido</p>
        <p>No. factura</p>
        <p>Razón social</p>
        <p>Ejecutivo</p>
        <p>Responsable</p>
        <p>Estatus</p>
      </div>

      {hook.auths.map((auth, i) => (
        <Fragment key={`${key.current}-${i}`}>
          <input
            className="d-none"
            type="radio"
            name={`${key.current}`}
            id={`${key.current}-${i}`}
          />
          <MoreInformation {...auth} />
        </Fragment>
      ))}

      <Pagination
        actualPage={hook.page}
        pages={hook.pages}
        onChange={hook.setPage}
      />
    </div>
  );
}
