import { ToDoDocuments } from "components/general/ToDo";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";

export const ToDoOdc = () => {
  /**
   * @type {import('types/typedef/customHooks/useQueryParams').ToDoQueryParams}
   */
  const { cliente, documento } = useQueryParams();

  return (
    <>
      <BreadCrumV2
        path={[
          {
            text: "Inicio",
            route: "/inicio",
          },
          {
            text: "Administracion",
            route: "/administracion",
          },
          {
            text: "Ordenes de compra",
            route: "/administracion/ordenes-compra",
          },
          {
            text: "To Do",
            route: "",
          },
        ]}
      />
      <ToDoDocuments cliente={cliente} documento={documento} />
    </>
  );
};
