import { resolverAmountMoney } from "helpers/money";
import * as yup from "yup";

const dof = yup
  .number()
  .min(1, "Mínimo $1.0")
  .required("Obligatorio")
  .transform((casted, input) => resolverAmountMoney(input));
const pays = yup
  .number()
  .min(1, "Mínimo $1.0")
  .required("Obligatorio")
  .transform((casted, input) => resolverAmountMoney(input));
const enterprise = yup
  .number()
  .min(1, "Mínimo $1.0")
  .required("Obligatorio")
  .transform((casted, input) => resolverAmountMoney(input));
const sells = yup
  .number()
  .min(1, "Mínimo $1.0")
  .required("Obligatorio")
  .transform((casted, input) => resolverAmountMoney(input));
const purchase = yup
  .number()
  .min(1, "Mínimo $1.0")
  .required("Obligatorio")
  .transform((casted, input) => resolverAmountMoney(input));

/**
 * Schema to add a new tc into system
 */
export const schemaTc = yup.object().shape({
  dof,
  pays,
  enterprise,
  sells,
  purchase,
});
