import React from "react";
import Dialog from "structure/Dialog";
import { Label } from "structure/Document";
import useUenContact, { CONTEXT_UEN_CONTACT } from "./useUenContact";
import { createContext } from "react";
import GridUen from "./GridUen";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import style from "./styles.module.scss";
import { useRef } from "react";
import useDialog from "customHooks/useDialog";

export const ContextUenContact = createContext(CONTEXT_UEN_CONTACT);

/**
 * Render a input to select the uens that will have a contact
 * @param {import("./types").PropsUenContact} props - Props
 * @returns {JSX.Element}
 */
export default function UenContact({
  idContact = null,
  onChange = () => {},
  uens = [],
  hasEmail = true,
  displayBlockColumn = true,
  title = 'UEN'
}) {
  const hook = useUenContact({ idContact, onChange, uens });

  const dialogView = useDialog();

  const key = useRef(window.crypto.randomUUID());

  return (
    <ContextUenContact.Provider value={{...hook,displayBlockColumn}}>
      <div>
        <Label>UEN</Label>

        {!hasEmail ? (
          <p>Proporciona un correo para la UEN</p>
        ) : (
          <div className={style.containerChips}>
            <div>
              {uens.length >= 1 ? (
                uens.map((uen, i) => (
                  <span key={`${key.current}-${i}`}>{uen.label}</span>
                ))
              ) : (
                <p>Selecciona una UEN</p>
              )}
            </div>
            <div>
            <ButtonV2
              type="button"
              disabled={uens.length <= 0}
              // className={style.chipAction}
              onClick={dialogView.open}
            >
              👁‍🗨
            </ButtonV2>
            <ButtonV2
              // className={style.chipAction}
              onClick={hook.customOpen}
              type="button"
            >
              ➕
            </ButtonV2>
            </div>
          </div>
        )}
      </div>

      <Dialog
        forwardRef={hook.dialog.refDialog}
        closeCallback={hook.customClose}
        title={title}
        width={75}
        footer={
          <div className="d-flex justify-content-end">
            <ButtonV2 type="button" onClick={hook.handleOnConfirm}>
              Actualizar
            </ButtonV2>
          </div>
        }
      >
        <GridUen />
      </Dialog>

      <Dialog
        forwardRef={dialogView.refDialog}
        closeCallback={dialogView.close}
        title="UEN(s) del contacto"
      >
        <ol className={style.styleViewChips}>
          {uens.map((uen, i) => (
            <li key={`view-${key}-${i}`}>
              📨 {uen.email ? "✅" : "❌"} {uen.label}
            </li>
          ))}
        </ol>
      </Dialog>
    </ContextUenContact.Provider>
  );
}
