import React, { createContext } from "react";
import useConsecutivePurchaseOrder, {
  CONTEXT_CONSECUTIVE_PURCHASE_ORDER,
} from "./useConsecutivePurchaseOrder";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import RangeConsecutivePurchaseOrder from "./Range";
import style from "./styles.module.scss";
import SearchConsecutivePurchaseOrder from "./SearchConsecutivePurchaseOrder";
import TableConsecutivePurchaseOrder from "./Table";
import SearchButton from "./Search";
import ExcelConsecutivePurchaseOrder from "./ExcelConsecutivePurchaseOrder";
import StatusDocuments from "components/general/Forms/Selects/StatusDocuments";
import FilterMobile from "structure/FilterMobile";
import { parseDateToText } from "helpers/dates";

export const ContextConsecutivePurchaseOrder = createContext(
  CONTEXT_CONSECUTIVE_PURCHASE_ORDER
);

const STATUS_LABEL = {
  10: "Activa",
  11: "Enviada",
  13: "Parcialmente recibida",
  17: "Recibida",
  12: "Cancelada",
};

export default function ConsecutivePurchaseOrder() {
  const hook = useConsecutivePurchaseOrder();

  return (
    <div className={style.contentModule}>
      <ContextConsecutivePurchaseOrder.Provider value={hook}>
        <div className={style.headerContent}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={style.contentItem}>
            <div className={style.filterConsecutivePurchaseOrder}>
              <StatusDocuments
                idTypeDocument={3}
                value={hook.status}
                onChange={(status) => hook.setStatus(status)}
              />
              <RangeConsecutivePurchaseOrder />
              <SearchConsecutivePurchaseOrder />
              <SearchButton />
              <ExcelConsecutivePurchaseOrder />
            </div>
          </div>

          <div className={style.contentItem}>
            <FilterMobile
              pills={[
                {
                  label: "Estatus",
                  value: STATUS_LABEL[hook.status] || "Todos",
                },
                {
                  label: "Desde",
                  value: parseDateToText(hook.from),
                },
                {
                  label: "Hasta",
                  value: parseDateToText(hook.to),
                },
              ]}
            >
              <StatusDocuments
                idTypeDocument={3}
                value={hook.status}
                onChange={(status) => hook.setStatus(status)}
              />
              <RangeConsecutivePurchaseOrder />
              <SearchConsecutivePurchaseOrder />
            </FilterMobile>
          </div>
        </div>

        <div className={style.contentItem}>
          <TableConsecutivePurchaseOrder />
        </div>
      </ContextConsecutivePurchaseOrder.Provider>
    </div>
  );
}
