import { DownloadBlob, GetBlobCred } from "./Apis/associateFiles";
import mime from "mime-types";
// var mime = require('mime-types')

import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import jsPDF from "jspdf";

// Trigger push code
import { v4 as uuid } from "uuid";
import "../types/azureblob";
import { createLog } from "./errors";
import byteSize from "byte-size";
import { loadPdfData } from "./Apis/Documents";
import { Error } from "./alerts";
import { pdfEmailTemplateV2 } from "pages/Directory/Documents/PDF-Templates/SendpdfDocumentV2";
const { Parser } = require("xml2js");

/**
 * Create the base64 url to File instance
 * @param {string} mimeType - Type of file
 * @param {string} base64 - Base64 string
 * @param {string} fileName - File name when it's converted
 * @returns {Promise<File>} File instace of the base64
 */
export async function base64ToFile(
  mimeType,
  base64,
  fileName = "base64Converted",
  downloadOnFinish = false
) {
  const localUrl = `data:${mimeType};base64,${base64}`;

  const apiFile = await fetch(localUrl);

  const blob = await apiFile.blob();

  const file = new File([blob], fileName, { type: mimeType });

  if (downloadOnFinish) {
    DownloadBlob(blob, fileName);
  }

  return file;
}

/**
 * Get a base64 to file instance
 * @param {string} base64Text - Text with base64 format
 * @param {string} filename - Name for the file to be converted
 * @returns {File}
 * @example
 * 
 * const base64String = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA...'; // Your base64 string
   const filename = 'profilepicture_user_12';
   const file = base64ToFile(base64String, filename);
 */
export function base64ToFileV2(base64Text,filename){
  const [mimeType, base64Data] = base64Text.split(';base64,');

  const [first,second] = mimeType.split(':')
    
  // Decode the base64 string
  const byteCharacters = atob(base64Data);
  
  // Convert each character to its Unicode value
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  
  // Convert the byte numbers array to a byte array
  const byteArray = new Uint8Array(byteNumbers);
  
  // Create a Blob from the byte array
  const blob = new Blob([byteArray], { type: mimeType.split(':')[1] });

  const fileExtension = mime.extension(second);
  
  // Create a File from the Blob
  const file = new File([blob], `${filename}.${fileExtension}`, { type: mimeType.split(':')[1] });
  
  return file;
}

/**
 * Convert to base64 a file
 * @param {File} file - File to convert
 * @returns {Promise<string>} Base64 string
 */
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};


/**
 *
 * @param {File} file - File instance
 * @param {string} pathSave - Path which where will be saved the file on Azure
 * @param {string} fileName - File name when it's saved
 * @param {string} extension - Extension of the file
 * @param {Function} onError - Code to execute when occurs an error
 * @example uploadFileToAzure(file, "/Documentos/484", "invoice-juan-maria", "pdf");
 * @returns {Promise<AzureFileUploaded|ErrorInformation>} Information of the file on azure
 */
export async function uploadFileToAzure(
  file,
  pathSave,
  fileName,
  extension,
  onError = () => {}
) {
  try {
    const uuidFileName = uuid();

    // Blob credentials
    const apiBlobCred = await GetBlobCred();

    // SaS url to upload
    const blobSasUrl = `${apiBlobCred.urlStorage}?${apiBlobCred.sasUrl}`;

    // Descomment this line code in order to make it fail
    // const blobSasUrl = `${apiBlobCred.urlStorage}`;

    // Resources to learn: https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/javascript/basic.js
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    // Container name (folder)
    const containerName = `${apiBlobCred.containerName}${pathSave}`;

    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobClient = await containerClient.getBlockBlobClient(
      `${uuidFileName}.${extension}`
    );

    await blobClient.upload(file, file.size);

    return {
      urlDownload: `${apiBlobCred.urlStorage}/${apiBlobCred.containerName}${pathSave}/${uuidFileName}.${extension}`,
      fileName: fileName,
      uuidFileName: uuidFileName,
      ext: extension,
    };
  } catch (error) {
    const { name, code, statusCode, request, response, details } = error;

    (async function () {
      const listErrors = {
        403: `El servicio 'Blob' no esta permitiendo subir archivos. Contacte a soporte tecnico`,
      };

      const errorIsProgrammed = Object.keys(listErrors).includes(
        `${error.statusCode}`
      );

      await createLog({
        infoSended: {
          url: request.url,
          method: request.method,
          headers: request.headers,
          requestId: request.requestId,
          body: request.body,
        },
        logCreatedBy: "Microsoft Blob Storage",
        mustBeSync: false,
        provider: 2,
        responseReceived: {
          url: request.url,
          method: request.method,
          headers: request.headers,
          requestId: request.requestId,
          body: request.body,
        },
        wasAnError: true,
        error: 2,
      });

      onError(error);

      if (!errorIsProgrammed) {
        throw {
          message: details.message,
          codeError: statusCode,
          errorDetails: error,
        };
      } else {
        throw {
          message: listErrors[statusCode],
          codeError: statusCode,
          errorDetails: error,
        };
      }
    })();
  }
}

/**
 * Get the extension of a file
 * @param {string} fileNameExt - File name with the extension
 * @returns {{
 *  extension:string,
 *  fileName:string
 * }} Information of the file
 * @example
 * const fileInfo = getExtensionFileName('chanchito.troll.png');
 * // returns
 * // {
 * //  fileName:'chanchito.troll'
 * //  extension:'png'
 * // }
 */
export function getExtensionFileName(fileNameExt) {
  // Get the extension file
  const extPosition = fileNameExt.lastIndexOf(".");
  const fileName = fileNameExt.substring(0, extPosition);
  const extension = fileNameExt.substring(extPosition + 1, fileNameExt.length);

  return {
    extension,
    fileName,
  };
}

/**
 * Get the filename
 * @param {string} paramFileName - filename
 * @param {string} paramExtension - filename
 * @returns {string}
 */
export function generateFileName(paramFileName, paramExtension) {
  const { fileName } = getExtensionFileName(paramFileName);

  if (fileName === "") {
    return `${paramFileName}.${paramExtension}`;
  }

  return fileName;
}

/**
 * Check if the file it's valid to be uploaded
 * @param {string} extension - Extension to validate
 * @param {string[]} extensions - Extensions valid when a file it's requested to be uploaded
 * @returns {boolean} True if the file has a valid extension
 * @example
 * const canBeUpload = isValidExtension('png',['png','pdf','xml']);
 * // return true
 */
export function isValidFileExtension(extension, extensions = []) {
  if (extensions.length <= 0) {
    return true;
  }

  if (extensions.includes(extension)) {
    return true;
  }

  return false;
}

/**
 * Get the information of the file as an object in order to be "accesable" for the code
 * @param {File} file - File object from the input
 * @param {import("types/typedef/files").InformativeFilePathI?} blobInfoUpload - Information in case the file will be uploaded to blob storage
 * @returns {import("types/typedef/files").InformativeFileI} Informative info of the file
 */
export function parseFileToInformativeInfo(file, blobInfoUpload = null) {
  // const { fileName } = getExtensionFileName(file.name);
  const extension = getFileExtension(file.type);
  const fileName = generateFileName(file.name, extension);

  const uuidGenereated = uuid();

  const urlFile =
    blobInfoUpload === null
      ? null
      : `${blobInfoUpload.urlStorage}/${blobInfoUpload.containerName}${blobInfoUpload.path}/${uuidGenereated}.${extension}`;

  console.log({
    mimeType: file.type,
    size: byteSize(file.size),
    fileName,
    uuidFileName: uuidGenereated,
    extension,
    urlFile,
  });

  return {
    mimeType: file.type,
    size: byteSize(file.size),
    fileName,
    uuidFileName: uuidGenereated,
    extension,
    urlFile,
  };
}

/**
 * Create the container blob in order to be able to perform actions on the azure blob storage (list, delete, add, edit,etc...)
 * @returns {{
 *  containerName:string,
 *  sasUrl:string,
 *  urlStorage:string,
 *  containerClient:ContainerClient,
 *  blobServiceClient:BlobServiceClient
 * }} BlobServiceClient
 */
export async function createContainerBlobClient() {
  try {
    const { containerName, sasUrl, urlStorage } = await GetBlobCred();

    // SaS url to upload
    const blobSasUrl = `${urlStorage}?${sasUrl}`;

    // Descomment this line code in order to make it fail
    // const blobSasUrl = `${apiBlobCred.urlStorage}`;

    // Resources to learn: https://github.com/Azure/azure-sdk-for-js/blob/main/sdk/storage/storage-blob/samples/javascript/basic.js
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    const containerClient = await blobServiceClient.getContainerClient(
      containerName
    );
    return {
      containerName,
      sasUrl,
      urlStorage,
      containerClient,
      blobServiceClient,
    };
  } catch (e) {
    console.log(e);
    throw {
      error: e,
    };
  }
}

/**
 * List the files that are on a current path of azure blob storage
 */
export async function listFilesBlob() {
  try {
    const { containerClient, containerName, blobServiceClient } =
      await createContainerBlobClient();

    const test = containerClient.listBlobsFlat();
    console.log(test);

    for await (const blob of test) {
      console.log(blob);
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Generate the PDF file, aditionally, allows to be downloaded on the PC of the user after generation
 * @param {import("types/typedef/documents").DocumentListInfoI} infoDocument - Information of the invoice in order to created the PDF
 * @param {string} fileNameParam - Filename of the pdf generated
 * @param {boolean?} downloadAfterGenerated - Default true. Indicates if the file should be downloaded on the pc user after generating
 * @returns {Promise<import("types/typedef/files").GeneratedDocumentI>} PDF generated
 */
export async function generatePdfDocument(
  infoDocument,
  fileNameParam = uuid(),
  downloadAfterGenerated = false
) {
  try {
    const { PDF: pdfInformation, status: httpStatus } = await loadPdfData(
      infoDocument.idDocument
    );

    if (httpStatus === 200) {
      /**
       * Html with inline styles as string
       * @type {string}
       */
      const templatePDF = pdfEmailTemplateV2({
        comentarios: pdfInformation.comentarios,
        headerPDF: pdfInformation.headerPDF,
        parsedPartidas: pdfInformation.parsedPartidas,
      });

      /**
       * Instance to manipulate the pdf
       * @type {jsPDF}
       */
      let jsDocument = new jsPDF({
        format: "a4",
        unit: "px",
        hotfixes: ["px_scaling"],
      });
      /**
       * PDF file generated as Blob instance
       * @type {Blob}
       */
      const pdfFileBlob = await new Promise((resolve, reject) => {
        jsDocument.html(templatePDF, {
          x: 10,
          y: 10,
          callback: (doc) => {
            const outpuFile = doc.output("blob", {
              filename: fileNameParam,
            });

            if (downloadAfterGenerated) {
              doc.save();
            }

            resolve(outpuFile);
          },
        });
      });

      /**
       * Blob generated extended to File instance
       * @type {File}
       */
      const filePdfInstace = new File([pdfFileBlob], `${fileNameParam}.pdf`);

      const { extension, fileName, size, uuidFileName } =
        parseFileToInformativeInfo(filePdfInstace);

      return {
        file: filePdfInstace,
        blob: pdfFileBlob,
        extension,
        blobPath: null,
        fileName,
        mimeType: "application/pdf",
        size,
        uuidFileName,
      };
    }

    Error(() => {}, "No se pudo generar el PDF");
    return;
  } catch (error) {
    console.log(error);
    Error(() => {}, "No se pudo generar el PDF");
  }
}

/**
 * Valid extensions to upload on the modal to send an email
 * @type {string[]}
 */
export const validExtensionsSendEmail = [
  "pdf",
  "xml",
  "docx",
  "pptx",
  "xlsx",
  "png",
  "jpeg",
  "jpg",
];

/**
 * Get the file extension according the mime type of the File object
 * @param {string|File} mimeType - File
 * @returns {string|boolean} File extension
 */
export const getFileExtension = (mimeType) => {
  // User gave a string as param (mime type string)
  if (typeof mimeType === "string") {
    return mime.extension(mimeType);
  }

  // User gave a File instance, that contains on his propertys the mime type
  return mime.extension(mimeType.type);
};

/**
 * Add files from the computer of the user
 * @param {File[]} files - Files the user uploaded from the PC
 * @return {{
 *  queueFiles:File[],
 *  queueInformativeFiles:import("types/typedef/files").InformativeFileI[]
 * }}
 */
export const addFilesFromPc = (files = [], validExtensions = []) => {
  let queueInformativeFiles = [];
  let queueFiles = [];

  const numberOfFiles = Object.keys(files).length;

  for (let i = 0; i < numberOfFiles; i++) {
    /**
     * File item on the item list
     * @type {File} file - File
     */
    const item = files.item(i);

    const tempExtension = getFileExtension(item.type);

    const extension =
      tempExtension === false
        ? item.name.substring(item.name.lastIndexOf(".") + 1, item.name.length)
        : tempExtension;

    const canBeUploaded = isValidFileExtension(extension, validExtensions);

    if (canBeUploaded) {
      const informativeFileInfo = parseFileToInformativeInfo(item);

      queueFiles.push(item);
      queueInformativeFiles.push(informativeFileInfo);
    } else {
      console.log("ERROR: Extension invalida", extension);
    }
  }

  return {
    queueFiles,
    queueInformativeFiles,
  };
};

/**
 * @param {File[]} files Array of files to add to the FileList
 * @return {FileList}
 */
export function fileArrayToFileList(files = []) {
  var b = new ClipboardEvent("").clipboardData || new DataTransfer();
  for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
  return b.files;
}

/**
 * Convert base64 to blob
 * @param {string} base64String - Base 64
 * @returns {Blob}
 */
export function base64toBlob(base64String) {
  const base64WithoutPrefix = base64String.split(",")[1];
  const byteCharacters = atob(base64WithoutPrefix);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  // Try to infer the MIME type
  let mimeType = "application/octet-stream"; // default to binary

  const matches = base64String.match(/^data:(.*);base64,/);
  if (matches && matches[1]) {
    mimeType = matches[1];
  }

  return new Blob([byteArray], { type: mimeType });
}

/**
 * Convert a xml file to json to be readable
 * @param {File|Blob} xmlFile - File of the xml download to be parsed to json
 * @returns {Promise<import("./typesFiles").CfdiSat>}
 */
export async function xmlFileToJson(xmlFile) {
  const xmlReader = new Parser({
    explicitArray: false,
  });

  const textXml = await xmlFile.text();

  const jsonXml = await new Promise((resolve, reject) => {
    xmlReader.parseString(textXml, (error, result) => {
      resolve(result);
    });
  });

  return jsonXml["cfdi:Comprobante"];
}
