import { geMovementConcepts } from "helpers/Apis/Banks/index";
import {
  getBankAccount,
  getBankAccountV2,
  getCxcConcilationsMovement,
  getMovement,
} from "helpers/Apis/Banks";
import { useEffect, useState } from "react";

/**
 * Handle the overview of the associations (cxc) made for an incoming
 * @param {number} idMovement - Id of the movement to fetch his associations cxc
 * @param {*} onChange
 * @returns
 */
export default function useConcilationCxC(
  idMovement,
  onChange = () => {},
  bank = null
) {
  /**
   * State component
   * @type {[import("./types").StateConcilationCxC,()=>void]}
   */
  const [state, setState] = useState({
    isLoading: true,
    selected: {},
    concilations: [],
    concepts: [],
    bank: null,
  });

  useEffect(() => {
    (async function () {
      const [apiConcilations, apiConcepts] = await Promise.all([
        getCxcConcilationsMovement(idMovement),
        geMovementConcepts(idMovement),
      ]);

      let bankInfo = null;

      if (bank !== null) {
        bankInfo = await getBankAccountV2(bank);
      }

      setState({
        ...state,
        isLoading: false,
        concilations: apiConcilations,
        concepts: apiConcepts,
        bank: bankInfo,
      });
    })();
  }, []);

  useEffect(() => {
    const listConcilations = Object.entries(state.selected).map(
      ([key, concilation]) => concilation
    );
    onChange(listConcilations);
  }, [state.selected]);

  /**
   *
   * @param {number} idConcilation - Id of the association selected
   * @param {boolean} isChecked - Indicates of the user turned on/off the checkbox
   */
  function handleToggleSelect(idConcilation, isChecked) {
    const newSelectedList = isChecked
      ? appendAssociation()
      : eraseAssociation();

    setState({
      ...state,
      selected: newSelectedList,
    });

    function appendAssociation() {
      const association = state.concilations.find(
        (concilation) => concilation.id === idConcilation
      );

      let unrefSelected = { ...state.selected };

      return {
        ...unrefSelected,
        [association.id]: association,
      };
    }

    function eraseAssociation() {
      const association = state.concilations.find(
        (concilation) => concilation.id === idConcilation
      );
      let unrefSelected = { ...state.selected };

      delete unrefSelected[association.id];

      return unrefSelected;
    }
  }

  const isParentCheckboxActive = Object.keys(state.selected).length > 0;

  /**
   * Check if the box must be marked as checked or not
   * @param {number} idConcilation - Id of the concilation
   */
  const isCheckedItem = (idConcilation) =>
    state.selected[idConcilation] === undefined ? false : true;

  const handleMasterToggle = (isChecked) => {
    if (!isChecked) {
      return setState({
        ...state,
        selected: {},
      });
    }

    const indexedSelected = state.concilations.reduce(
      (indexed, concilation) => ({
        ...indexed,
        [concilation.id]: concilation,
      }),
      {}
    );

    setState({
      ...state,
      selected: indexedSelected,
    });
  };

  return {
    isLoading: state.isLoading,
    selected: state.selected,
    concilations: state.concilations,
    bank: state.bank,
    handleToggleSelect,
    isParentCheckboxActive,
    isCheckedItem,
    handleMasterToggle,
    concepts: state.concepts,
  };
}
