import React from "react";
import { useContext } from "react";
import { ContextUenAdmin } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";
import { useRef } from "react";
import MobileUen from "./Mobile";

export default function TableUen() {
  const hook = useContext(ContextUenAdmin);

  const key = useRef(window.crypto.randomUUID());

  const itemsToShow = hook.search === "" ? hook.uens : hook.uensSearched;

  if (hook.isLoading)
    return (
      <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
    );

  return (
    <>
      {itemsToShow.map((uen,i) => (
        <MobileUen {...uen} key={`${key.current}-${i}-mobile`}/>
      ))}

      <div className={style.tableUens}>
        <div className={style.header}>
          <p>Descripción</p>
          <p>Familia</p>
          <p>Sub familia</p>
          <p>Código SAT</p>
          <p>UM SAT</p>

          <p>IVA</p>
          <p>Margen</p>
          <p>Excento</p>
          <p>Estatus</p>
        </div>

        <div className={style.containerRows}>
          {itemsToShow.map((uen, i) => (
            <div key={`${key.current}-${i}`}>
              <input
                onChange={(e) => hook.setUen(uen)}
                type="radio"
                className="d-none"
                name={key.current}
                id={`${key.current}-${i}`}
              />
              <label htmlFor={`${key.current}-${i}`} className={style.row}>
                <p>{uen.description}</p>
                <p>{uen.family}</p>
                <p>{uen.subFamily}</p>
                <p>
                  {uen.SATcode} - {uen.satCodeDescription}
                </p>

                <p>
                  {uen.SATUM} - {uen.satUmDescription}
                </p>

                <p className={style.percentage}>{uen.iva}</p>
                <p className={style.percentage}>{uen.marginRate}</p>

                <p>{uen.iva !== 0 ? "NA" : uen.isExempt ? "Si" : "No"}</p>
                <p>{uen.status ? "Activo" : "Inactivo"}</p>
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
