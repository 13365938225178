import { getFeeComboYears } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";

/**
 * @type {import("pages/Ventas/Cuotas/AvailableYears/types").StateUseFeeYears}
 */
const INITIAL_STATE = {
  isLoading: true,
  years: [],
  year: null,
};

/**
 *
 * @param {import("pages/Ventas/Cuotas/AvailableYears/types").PropsAvailableYears} param
 */
export default function useFeeYears({ onChange = () => {}, refetch = false }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const yearsApi = await getFeeComboYears();

      const options = yearsApi.map((year) => ({
        label: year,
        value: year,
      }));

      setState((current) => ({
        ...current,
        isLoading: false,
        years: [
          {
            label: "-- SELECCIONA --",
            value: null,
            disabled: true,
            selected: true,
          },
          ...options,
        ],
      }));
    })();
  }, [refetch]);

  return {
    ...state,
    setState
  };
}
