import React, { useRef } from "react";
import { Label } from "structure/Document";

/**
 * Render proyect status combo
 * @param {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} props - Props
 */
export default function ProyectStatus(props) {
  const key = useRef(`${window.crypto.randomUUID()}`);

  const proyectStatus = useRef([
    {
      label: "No activo",
      value: 'NoActivo',
    },
    
    {
      label: "Operando",
      value: 'ActivoOperando'
    },
    {
      label: "Propuesta pendiente",
      value: 'ActivoPropuestaPendiente'
    },
    {
      label: "Terminado",
      value: 'Terminado'
    },
    {
      label: "Cancelado",
      value: 'Cancelado'
    },
  ]);

  return (
    <div>
      <Label htmlFor={`${key.current}`}>Estatus</Label>
      <select
        {...props}
        id={`${key.current}`}
        className={`select-css ${props?.className}`}
      >
        {props?.children}
        {proyectStatus.current.map((status, index) => (
          <option value={status.value} key={`${key.current}-${index}`}>
            {status.label}
          </option>
        ))}
      </select>
    </div>
  );
}
