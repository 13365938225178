import React from "react";
import { useContext } from "react";
import { ContextEnterpriseInfo } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";

const Container = ({ children }) => (
  <div
    className="position-sticky sticky-bottom pt-4"
    style={{ bottom: 0, background: "var(--secondaryBackground)" }}
  >
    {children}
  </div>
);

export default function Submit() {
  const hook = useContext(ContextEnterpriseInfo);

  if (hook.isUpdating)
    return (
      <Container>
        <Spinner
          hidden={false}
          idSpinner="updatingEnterpriseInfo"
          text="Actualizando"
        />
      </Container>
    );

  if (!hook.rfc.isValidRfc)
    return (
      <Container>
        <p className="text-danger text-center">RFC inválido</p>
      </Container>
    );

  return (
    <Container>
      <ButtonV2 style={{ width: "100%" }} type="submit">
        <span>Actualizar</span>
        <Icon icon="save" />
      </ButtonV2>
    </Container>
  );
}
