import styled from "styled-components";

const gridGapSpace = "0px";

export const DocInfo = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(8, 12%);
  grid-gap: ${gridGapSpace};
  grid-template-areas:
    "id type no status exec exec exec exec"
    "client client client client customer customer customer customer";
`;

export const IdArea = styled.div`
  grid-area: id;
`;

export const TypeArea = styled.div`
  grid-area: type;
`;

export const NoArea = styled.div`
  grid-area: no;
`;

export const StatusArea = styled.div`
  grid-area: status;
`;

export const ExecutiveArea = styled.div`
  grid-area: exec;
`;

export const ClientArea = styled.div`
  grid-area: client;
`;

export const ContactArea = styled.div`
  grid-area: customer;
`;

//------------------------------------------------------------------------------------------------------------
export const DocReleated = styled.div`
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(7, 14%);
  grid-gap: ${gridGapSpace};
  grid-template-areas:
    "OrigenNumber QuoteNumber PreInvoiceNumber InvoiceNumber ContractNumber OcNumber InvoiceProviderNumber"
    "NoContract Creation Modify Expiration Reminder PercentAvance PercenProb";
`;
export const OriginNumberArea = styled.div`
  grid-area: OrigenNumber;
`;
export const QuoteNumberArea = styled.div`
  grid-area: QuoteNumber;
`;
export const PreInvoiceNumberArea = styled.div`
  grid-area: PreInvoiceNumber;
`;
export const InvoiceNumberArea = styled.div`
  grid-area: InvoiceNumber;
`;
export const ContractNumberArea = styled.div`
  grid-area: ContractNumber;
`;
export const OcNumberArea = styled.div`
  grid-area: OcNumber;
`;
export const InvoiceProviderNumberArea = styled.div`
  grid-area: InvoiceProviderNumber;
`;

export const NoContractArea = styled.div`
  grid-area: NoContract;
`;
export const CreationArea = styled.div`
  grid-area: Creation;
`;
export const ModifyArea = styled.div`
  grid-area: Modify;
`;
export const ExpirationArea = styled.div`
  grid-area: Expiration;
`;
export const ReminderArea = styled.div`
  grid-area: Reminder;
`;
export const PercentAvanceArea = styled.div`
  grid-area: PercentAvance;
`;
export const PercenProbArea = styled.div`
  grid-area: PercenProb;
`;
//------------------------------------------------------------------------------------------------------------

export const Imports = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(7, 14%);
  grid-gap: ${gridGapSpace};
  grid-template-areas: "Currency TCP Import IVA Total Acredited Recidue";
`;
export const CurrencyArea = styled.div`
  grid-area: Currency;
`;
export const TCPArea = styled.div`
  grid-area: TCP;
`;
export const ImportArea = styled.div`
  grid-area: Import;
`;
export const IVAArea = styled.div`
  grid-area: IVA;
`;
export const TotalArea = styled.div`
  grid-area: Total;
`;
export const AcreditedArea = styled.div`
  grid-area: Acredited;
`;
export const RecidueArea = styled.div`
  grid-area: Recidue;
`;

export const SAT = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(12, 8%);
  grid-gap: ${gridGapSpace};
  grid-template-areas:
    "CFDI CFDI CFDI CFDI PaymentType PaymentType PaymentType PaymentType PaymentMethod PaymentMethod PaymentMethod PaymentMethod"
    "Partialities Partialities Partialities Partiality Partiality Partiality CreditDays CreditDays CreditDays Complement Complement Complement";
`;
export const CFDIArea = styled.div`
  grid-area: CFDI;
`;
export const PaymentTypeArea = styled.div`
  grid-area: PaymentType;
`;
export const PaymentMethodArea = styled.div`
  grid-area: PaymentMethod;
`;
//---------
export const PartialitiesArea = styled.div`
  grid-area: Partialities;
`;
export const PartialityArea = styled.div`
  grid-area: Partiality;
`;
export const CreditDaysArea = styled.div`
  grid-area: CreditDays;
`;
export const ComplementArea = styled.div`
  grid-area: Complement;
`;

export const ClaveArea = styled.div`
  grid-area: Clave;
`;
export const DatesArea = styled.div`
  grid-area: EditDates;
  display:flex;
`;

export const DocReleatedV2 = styled.div`
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(7, 14%);
  grid-gap: ${gridGapSpace};
  grid-template-areas:
    "OrigenNumber QuoteNumber PreInvoiceNumber InvoiceNumber ContractNumber OcNumber InvoiceProviderNumber"
    "NoContract Creation Modify EditDates EditDates PercentAvance PercenProb";
`;
