import useBankCorrecction from "customHooks/useBankCorrection";
import React, { createContext } from "react";

/**
 * @type {import("react").Context<import("customHooks/useBankCorrection/types").ReturnUseBankCorrection>}
 */
export const ContextBankCorrection = createContext(undefined);

/**
 *
 * @param {import("./types").BankCorrection} param0
 * @returns
 */
export default function BankCorrection({ children = <></> }) {
  const hook = useBankCorrecction();

  return (
    <ContextBankCorrection.Provider value={hook}>
      {children}
    </ContextBankCorrection.Provider>
  );
}
