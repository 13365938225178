import React, { useContext } from "react";
import {
  evaluatePermission,
  textPermission,
} from "../../../helpers/permissions";
import PermissionsContext from "../../../context/Login/LoginContext";
import {
  CurrencySelect,
  EjecutivosInput,
  SearchInput,
} from "../../individual/inputs/Inputs";
import { RangeFilter } from "../../individual/Calendar/Calendar";
import { SearchButton } from "../../individual/buttons/Buttons";

export const CompleateOptions = ({
  permitionID,
  search,
  inputEjecutivoID,
  inputCurrencyID,
  inputRangeID,
  buttonSearchID,
}) => {
  const { rolPermitions } = useContext(PermissionsContext);
  return (
    <>
      {evaluatePermission([permitionID], rolPermitions) ? (
        <>
          <form
            className="col-12 flex-row"
            id="searchForm"
            onSubmit={(e) => search(e)}
          >
            <div className="form-row">
              <div className="col">
                <EjecutivosInput id={inputEjecutivoID} />
              </div>
              <div className="col-1">
                <CurrencySelect id={inputCurrencyID} />
              </div>
              <div className="col">
                <RangeFilter idModal={inputRangeID} />
              </div>
              <div className="col">
                <SearchInput
                  //   aditionalClasses="w-25"
                  showLabel={true}
                  labelText="Buscar"
                  placeholderTxt="Escribe aquí"
                  id={buttonSearchID}
                />
              </div>
              <div className="col pt-4">
                <SearchButton
                  text={textPermission(permitionID, rolPermitions)}
                  idForm="searchForm"
                  aditionalClasses="ml-4"
                />
              </div>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
};
