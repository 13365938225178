import React from "react";
import { Tabs } from "components/general/Tabs/Tabs";
import Partidas from "structure/Partidas";
import CommentsDocument from "components/general/CommentsDocument";
import QuoteTab from "../QuoteTab";
import useQueryParams from "customHooks/useQueryParams";
import useQuote from "customHooks/useQuote";
import { Provider } from "..";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ExchangeInformative } from "components/general/ExchangeInformative/ExchangeInformative";
import styles from "../styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import { AddDocument } from "components/Sections/Documents/AddDocument";
import ui from 'structure/ODC/styles.module.scss';
import usePermissions from "customHooks/usePermissions";

export default function EditQuote() {
  const params = useQueryParams();

  usePermissions('3a505210-e0eb-4df8-b660-3a884b73460f'); 

  const {
    openModalCreateContact,
    document,
    updateReminderDate,
    updateTc,
    updateCurrency,
    updatePeriocity,
    updateDocumentItemsInfo,
    updateProbability,
    triggerAddQuote,
    updateExistingContact,
    updateRegularComments,
    pathBreadcrum,
    partidas,
    isPerformingQuery,
    isDocumentFetched,
    updateExpirationDate,
    updateContact,
    setContacts,
    appendContactOnMemory,
    contactsOnMemory,
    canPerformQuery,
    customer,
    triggerEditQuote,
  } = useQuote({
    idCustomer: +params.idCliente,
    idQuote: +params.documento,
  });

  return (
    <Provider
      value={{
        idCustomer: params.idCliente,
        isPerformingQuery,
        openModalCreateContact,
        updateReminderDate,
        document,
        updateTc,
        updateRegularComments,
        appendContactOnMemory,
        updateCurrency,
        updateExpirationDate,
        updatePeriocity,
        updateProbability,
        updateExistingContact,
        partidas,
        isDocumentFetched,
        updateContact,
        triggerAddQuote,
        contactsOnMemory,
        canPerformQuery,
        setContacts,
      }}
    >
      <div className={ui.container}>
      <BreadCrumV2 path={pathBreadcrum} />
      <AddDocument
        actionType="Editar"
        customerId={+params.idCliente}
        documentType={1}
        documentId={+params.documento}
      />

      </div>

      {/* <div className="container">
        <header className="my-3 d-flex align-items-center justify-content-between">
          <div>
            <ExchangeInformative>
              <ExchangeInformative.Dof /> |
              <ExchangeInformative.Bank /> |
              <ExchangeInformative.Enterprise /> |
              <span className={styles.informativePipe}>
                <span>Razón social</span>
                <span>{customer?.socialReason}</span>
              </span>
              {document.currency !== null ? (
                <span className={styles.informativePipe}>
                  <span>Moneda</span>
                  <span>{document.currency}</span>
                </span>
              ) : null}
              <ExchangeInformative.Date />
            </ExchangeInformative>
          </div>
          {canPerformQuery ? (
            isPerformingQuery ? (
              <Spinner hidden={false} text="Actualizando cotizacion..." />
            ) : (
              <ButtonV2 onClick={triggerEditQuote}>Actualizar</ButtonV2>
            )
          ) : null}
        </header>

        <Tabs
          idContainerContent="document"
          idContainerTabs="tab"
          tabButtons={{
            document: {
              text: "Cotización",
              selected: true,
              component: <QuoteTab />,
            },
            documentItems: {
              text: "Partidas",
              selected: false,
              component: (
                <Partidas
                  onChange={updateDocumentItemsInfo}
                  onChangeOrder={(a) => console.log("cb", a)}
                  idDocument={+params.documento}
                  currency={document.currency}
                  display="pu"
                  tc={document.tc}
                />
              ),
            },
            documentComments: {
              text: "Notas y consideraciones",
              selected: false,
              component: (
                <CommentsDocument
                  idDocument={+params.documento}
                  onChange={updateRegularComments}
                  idTypeDocument={1}
                />
              ),
            },
          }}
        />
      </div> */}
    </Provider>
  );
}
