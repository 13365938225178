import { useReducer } from "react";
import AddDocumentContext from "./AddDocumentContext";
import AddDocumentReducer from "./AddDocumentReducer";

import FormQuote from "../../pages/Directory/Documents/AddDocument/FormQuote";
import "../../types/typedef/documents";

import {
  SET_SCREEN_ADD_DOC,
  SET_COMMENTS,
  SET_EDIT_COMMENT,
  SET_IS_SORTING,
  SET_GAME,
  SET_IVAS,
  SET_IS_NEW_GAME,
  SET_GAMES,
  SET_TCP,
  SET_CURRENCY_TYPE,
  SET_UEN_LIST,
  SET_NEW_IVAS_GAME,
  SET_DOCUMENT,
  SET_CATALOGUE,
  SET_CURRENCY_NAME,
  SET_SHOP_ITEM,
  DELETE_COMMENTS,
  DELETE_DOC_ITEM,
  SET_CUSTOMER_INFO,
  SET_DOC_INFO,
  SET_INDEXED_CURRENCIES,
  SET_CURRENCY_INFO,
  SET_EXCHANGE_TCP,
  RESET_ADD_DOCUMENT,
  SET_CURRENCY_BEING_USED,
  SET_ISFETCHING_DOCUMENT,
} from "../../types/index";

const AddDocumentState = (props) => {
  /**
   * @type {useAddDocumentState}
   */
  const initialState = {
    screen: <FormQuote />,
    comments: [],
    isEditingComment: false,
    isSorting: false,
    infoGame: null,
    isNewGame: false,
    ivas: [],
    ivasNewGame: [],
    games: [],
    tcp: 0,
    currencyType: 1,
    currencyName: "MXN",
    documento: { vacio: true },
    uenList: [],
    catalogue: [],
    shopItemCart: null,
    commentsToDelete: [],
    docItemsToDelete: [],
    customerInfo: null,
    docInfo: null,
    currencies: null,
    exchangeTcp: 0,
    currencyBeingUsed: null,
    isFetching: false,
  };

  const [state, dispatch] = useReducer(AddDocumentReducer, initialState);

  /**
   *
   * @param {object[]} listUen - List of the UEN that are on the system
   */
  const setUenList = (listUen) => {
    dispatch({
      payload: listUen,
      type: SET_UEN_LIST,
    });
  };

  /**
   * Update the catalogue list when a user attempts to add a new
   * product to the system
   * @param {object[]} catalogue - New catalogue list to use
   */
  const setCatalogue = (catalogue) => {
    dispatch({
      payload: catalogue,
      type: SET_CATALOGUE,
    });
  };

  /**
   * Set the information of a gamer
   *
   * @param {object} game - Information of the game that it's manipulating
   */
  const setInfoGame = (game) => {
    dispatch({
      payload: game,
      type: SET_GAME,
    });
  };
  const setTCP = (qoute) => {
    dispatch({
      payload: qoute,
      type: SET_TCP,
    });
  };
  const setDocument = (document) => {
    dispatch({
      payload: document,
      type: SET_DOCUMENT,
    });
  };
  const setCurrencyType = (qoute) => {
    dispatch({
      payload: qoute,
      type: SET_CURRENCY_TYPE,
    });
  };

  /**
   * Set the games that are gonna be added for the quotation
   * when user confirms the document
   *
   * @param {object[]} game - New set of games to add on quotation
   */
  const setGames = (game) => {
    console.log(game);

    dispatch({
      payload: game,
      type: SET_GAMES,
    });
  };

  /**
   * Set if the user it's attemping to create a new
   * item for the catalogue
   *
   * @param {boolean} boolean - If true, the user attemps to create the item
   */
  const setIsNewGame = (boolean) => {
    dispatch({
      payload: boolean,
      type: SET_IS_NEW_GAME,
    });
  };

  const setScreen = (component) => {
    dispatch({
      payload: component,
      type: SET_SCREEN_ADD_DOC,
    });
  };

  /**
   * Update the state. True if the user it's editing the card of a comment
   *
   * @param {boolean} boolean - Boolean
   */
  const setEdit = (boolean) => {
    dispatch({
      payload: boolean,
      type: SET_EDIT_COMMENT,
    });
  };

  /**
   * Set the comments that will be displayed on UI and manipulated after send the query
   * @param {object[]} comments - Comments
   */
  const setComments = (comments) => {
    dispatch({
      payload: comments,
      type: SET_COMMENTS,
    });
  };

  const setIsSorting = () => {
    dispatch({
      type: SET_IS_SORTING,
    });
  };

  /**
   * Set the available ivas when adding a product/service
   *
   * @param {object[]} ivas - IVAS available on the system
   */
  const setIvas = (ivas) => {
    dispatch({
      payload: ivas,
      type: SET_IVAS,
    });
  };

  /**
   * When you're creating a new product/service. Use the iva
   * for that UEN selected and the available ones
   *
   * @param {object[]} ivas - Ivas to use on modal
   */
  const setIvasNewGame = (ivas) => {
    dispatch({
      payload: ivas,
      type: SET_NEW_IVAS_GAME,
    });
  };

  /**
   * Set the text to display of the currency using to add/edit
   * the document
   * @param {string} description - Name of the currency
   */
  const setCurrencyName = (description) => {
    dispatch({
      payload: description,
      type: SET_CURRENCY_NAME,
    });
  };

  const setShopItemCart = (product) => {
    dispatch({
      payload: product,
      type: SET_SHOP_ITEM,
    });
  };

  /**
   * Make a list of the id comments that will be deleted from the document
   * @param {number} id - ID comments from DB
   */
  const deleteComments = (id) => {
    dispatch({
      payload: id,
      type: DELETE_COMMENTS,
    });
  };

  /**
   * Make a list of the id doc items what will be deleted from the document
   * @param {number} id - ID document from DB
   */
  const setItemsToDelete = (id) => {
    dispatch({
      payload: id,
      type: DELETE_DOC_ITEM,
    });
  };

  /**
   * Set the state of customerInfo
   * @param {object} customer - Customer info
   */
  const setCustomerInfo = (customer) => {
    dispatch({
      payload: customer,
      type: SET_CUSTOMER_INFO,
    });
  };

  /**
   * Set the state of docInfo
   * @param {object} doc - Document information
   */
  const setDocInfo = (doc) => {
    dispatch({
      payload: doc,
      type: SET_DOC_INFO,
    });
  };

  /**
   * Set the currency that's being used to manipulate the curren of items
   * @param {('USD'|'MXN')} currency
   */
  const setCurrencyUsed = (currency) => {
    dispatch({
      payload: currency,
      type: SET_CURRENCY_BEING_USED,
    });
  };

  /**
   * Set "currencies" state
   * @param {object} currencies - Indexed currencies
   */
  const setCurrencies = (currencies) => {
    dispatch({
      payload: currencies,
      type: SET_INDEXED_CURRENCIES,
    });
  };

  /**
   * Set currencyType and currencyName
   *
   * @param {SetCurrencieInfo} info - Info to set the TCP
   */
  const setCurrency = (info) => {
    dispatch({
      payload: info,
      type: SET_CURRENCY_INFO,
    });
  };

  /**
   * TCP used for the convertion foreign exchange
   *
   * @param {number} tcp - TCP to use for the convertion
   */
  const setExchangeTcp = (tcp) => {
    dispatch({
      payload: tcp,
      type: SET_EXCHANGE_TCP,
    });
  };

  /**
   * Reset the state as the initial value
   */
  const resetAddDocumentState = () => {
    dispatch({
      type: RESET_ADD_DOCUMENT,
    });
  };

  /**
   * Set visible or not a spinner
   * @param {boolean} boolean - True if the system it's making a query to update or add
   */
  const setIsFetching = (boolean) => {
    dispatch({
      type: SET_ISFETCHING_DOCUMENT,
      payload: boolean,
    });
  };

  /**
   * @type {useAddDocumentState}
   */
  const values = {
    screen: state.screen,
    comments: state.comments,
    isEditingComment: state.isEditingComment,
    isSorting: state.isSorting,
    infoGame: state.infoGame,
    isNewGame: state.isNewGame,
    documento: state.documento,
    ivas: state.ivas,
    games: state.games,
    tcp: state.tcp,
    currencyType: state.currencyType,
    uenList: state.uenList,
    ivasNewGame: state.ivasNewGame,
    catalogue: state.catalogue,
    currencyName: state.currencyName,
    shopItemCart: state.shopItemCart,
    commentsToDelete: state.commentsToDelete,
    docItemsToDelete: state.docItemsToDelete,
    docInfo: state.docInfo,
    customerInfo: state.customerInfo,
    currencies: state.currencies,
    exchangeTcp: state.exchangeTcp,
    currencyBeingUsed: state.currencyBeingUsed,
    isFetching: state.isFetching,
    setIsSorting,
    setScreen,
    setComments,
    setEdit,
    setInfoGame,
    setIsNewGame,
    setIvas,
    setGames,
    setTCP,
    setCurrencyType,
    setUenList,
    setIvasNewGame,
    setDocument,
    setCatalogue,
    setCurrencyName,
    setShopItemCart,
    deleteComments,
    setItemsToDelete,
    setDocInfo,
    setCustomerInfo,
    setCurrencies,
    setCurrency,
    setCurrencyUsed,
    setExchangeTcp,
    resetAddDocumentState,
    setCurrencyUsed,
    setIsFetching,
  };

  return (
    <AddDocumentContext.Provider value={values}>
      {props.children}
    </AddDocumentContext.Provider>
  );
};

export default AddDocumentState;
