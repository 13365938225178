import { parseToFloat } from '../../../helpers/money';

import * as yup from "yup";

const quantity = yup.number()
    .required()
    .min(1,"Cantidad minima 1")
    .typeError('Ingresa una cantidad');

const discount = yup.number()
    .min(0,'Descuento invalido')
    .max(100,'Descuento invalido')
    .default(0)
    .transform((casted,uiValue)=>+(uiValue.replaceAll('%','')));

const unitPrice = yup.string()
    .required('Precio requerido')
    .nullable(false)
    .typeError('Ingresa un precio valido')

    .transform((castedValue, inputValue) => {

            // User didn't typed a price
            if(castedValue.length === 0) return null

            const valueParsed = parseToFloat(inputValue);

            // User typed a wrong data (not a number)
            if(isNaN(valueParsed)) return null

            // User typed a price of 0 or negative value
            if(valueParsed<=0) return null;

            // Validations were correct
            return inputValue;
        }
    );

const satCode = yup.string()
    .required('Campo obligatorio')
    .nullable(false)
    .typeError('Campo invalido');

const umSat = yup.string()
    .required('Campo obligatorio')
    .nullable(false)
    .typeError('Campo invalido');

const codeGame = yup.string()
    .required('Campo obligatorio')
    .nullable(false)
    .typeError('Campo invalido');

const currency = yup.number()
    .required('Moneda obligatoria')
    .nullable(false)
    .default(1)
    .typeError('Moneda invalida')

/**
 * Unit cost
 */
const unitCost = yup.string()
        .typeError('Ingresa un costo valido')
        .transform((castedValue, inputValue) => {

                // User didn't typed a price, by default will be 0
                if(inputValue.length===0) return "$0";

                const valueParsed = parseToFloat(inputValue);

                // User typed a wrong data (not a number)
                if(isNaN(valueParsed)) return "$0"

                // User typed a price of 0 or negative value
                if(valueParsed<0) return "$0";

                // Validations were correct

                return inputValue;
            }
        );

export const addGameValidations = yup.object().shape({
    quantity,
    discount,
    unitPrice,
    satCode,
    umSat,
    codeGame,
    unitCost,
    currency
});