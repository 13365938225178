import { ContextMaterials } from "pages/Materials";
import React, { useContext } from "react";
import Customer from "components/general/Customer";
import ValidationMessage from "./ValidationMessage";

/**
 * Render the combo of social reason to generate the OC documents
 * @param {import('../../../../../../types/proyects/materials').DtoListMaterials} item
 * @returns {JSX.Element}
 */
export default function SocialReason(item) {
  const materials = useContext(ContextMaterials);

  if (item.residueQuantity <= 0) return <p>-</p>;

  return (
    <div>
      <Customer
        onChange={(supplier) => materials.setSupplier(supplier, item.id)}
        type="proveedor"
      />

      <ValidationMessage {...item} />
    </div>
  );
}
