import { ConfirmPassword } from "components/general/ConfirmPassword";
import Rfc from "components/general/Forms/Text/Rfc";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { FileButton } from "components/individual/ButtonsV2/Styles";
import InformativeTooltip from "components/individual/InfoTooltip";
import { Spinner } from "components/individual/Spinner/Spinner";
import useFiles from "customHooks/useFiles";
import { getCsd } from "helpers/Apis/Banks";
import { GetParameters } from "helpers/Apis/Documents";
import { getParameters } from "helpers/Apis/parameters";
import { updateCsdUser } from "helpers/Apis/setUp";
import { getDateFromUtc } from "helpers/dates";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import scss from "./styles.module.scss";

/**
 * @type {import("./types").StateCsd}
 */
const INITIAL_STATE = {
  isLoading: true,
  rfc: null,
  certificate: null,
  privateKey: null,
  uploadDate: null,
  fileCertificate: null,
  filePrivateKey: null,
  password: "",
  isUpdating: false,
}

/**
 * Render a card where you can update/upload the CSD in order to start creating invoices against the SAT
 * @param {import("./types").PropsCsd} props - Props
 * @returns {JSX.Element}
 */
export const CSDLoad = ({ div = {} }) => {
  const keyDom = useRef(window.crypto.randomUUID());
  const cerDom = useRef(window.crypto.randomUUID());

  const key = useFiles(["key"], 1);
  const cer = useFiles(["cer", "der"], 1);

  const [state, setState] = useState(INITIAL_STATE);

  const updatePassword = (password) =>
    setState({
      ...state,
      password,
    });

  const updateRfc = (rfc) =>
    setState({
      ...state,
      rfc,
    });

  const attemptUpdate = async () => {
    setState({
      ...state,
      isUpdating: true,
    });

    await updateCsdUser(state.rfc, state.password, cer.file[0], key.file[0]);

    setState({
      ...state,
      isUpdating: false,
    });
  };

  useEffect(() => {
    getParameters().then((parameters) =>
      setState({
        ...state,
        rfc: parameters["9"].value,
        isLoading: false,
      })
    );
  }, []);

  if (state.isLoading) return <Spinner hidden={false} text="Cargando CSD..." />;

  return (
    <div className="d-inline-flex" {...div}>
      <div className={scss.containerCsd}>
        <h5 className="text-center">Facturando con RFC {state.rfc}</h5>
        {state.certificate !== null ? (
          <p className="text-center">
            Cargado el{" "}
            {new Intl.DateTimeFormat("es-MX", {
              dateStyle: "full",
            }).format(getDateFromUtc(state.uploadDate))}
          </p>
        ) : null}

        <div>
          <div className={scss.containerFile}>
            <div className={scss.fileCsd}>
              <p>
                Certificado (.cer){" "}
                {state.certificate !== null ? (
                  <InformativeTooltip>
                    Ya estas facturando con el RFC {state.rfc}
                  </InformativeTooltip>
                ) : null}{" "}
              </p>

              {cer.informative.length === 0 ? (
                <p>❗ Carga un certificado</p>
              ) : (
                <p className={scss.wrap}>✅ {cer.informative[0].fileName}</p>
              )}

              <FileButton theme="default" htmlFor={cerDom.current}>
                Cargar certificado
              </FileButton>

              <input
                name={cerDom.current}
                id={cerDom.current}
                type="file"
                hidden
                accept=".cer"
                multiple
                onChange={(e) => cer.addFiles(e.target.files)}
              />
            </div>
          </div>

          <div className={scss.containerFile}>
            <div className={scss.fileCsd}>
              <p>
                Llave (.key){" "}
                {state.privateKey !== null ? (
                  <InformativeTooltip>
                    Ya estas facturando con el RFC {state.rfc}
                  </InformativeTooltip>
                ) : null}{" "}
              </p>

              {key.informative.length === 0 ? (
                <p>❗ Carga una llave</p>
              ) : (
                <p className={scss.wrap}>✅ {key.informative[0].fileName}</p>
              )}

              <FileButton theme="default" htmlFor={keyDom.current}>
                Cargar llave
              </FileButton>

              <input
                name={keyDom.current}
                id={keyDom.current}
                type="file"
                hidden
                multiple
                accept=".key"
                onChange={(e) => key.addFiles(e.target.files)}
              />
            </div>
          </div>
        </div>

        <div className="my-2">
          <div className="mb-4">
            <Rfc
              onChange={({ rfc, isValid }) =>
                isValid ? updateRfc(rfc) : updateRfc(null)
              }
              value={state.rfc}
            />
          </div>
          <ConfirmPassword onChange={(password) => updatePassword(password)} />
        </div>

        {key.informative.length > 0 &&
        cer.informative.length > 0 &&
        state.password.length > 0 &&
        state.rfc !== null ? (
          state.isUpdating ? (
            <Spinner hidden={false} text="Actualizando CSD" />
          ) : (
            <ButtonV2 onClick={attemptUpdate} className="my-2">
              Actualizar CSD
            </ButtonV2>
          )
        ) : null}
      </div>
    </div>
  );
};
