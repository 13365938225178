import { GetUEN } from "helpers/Apis/Documents";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseUen}
 */
const INITIAL_STATE = {
  isLoading: true,
  uens: [],
};

/**
 * @param {boolean} [justActives=false] - If true, only with status `active` will be displayed
 * @returns {import("./types").ReturnUseUen}
 */
export default function useUen(justActives=false) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const uensApi = await GetUEN();

      const items = justActives ? uensApi.filter(uen=>uen.status===true) : uensApi;

      setState((current) => ({
        ...current,
        isLoading: false,
        uens: items,
      }));
    })();
  }, []);

  return {
    ...state,
  };
}
