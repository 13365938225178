import { Spinner } from "components/individual/Spinner/Spinner";
import ToggleComponent from "components/individual/inputs/Toggle";
import { getCorporativeComboInvoice } from "helpers/Apis/invoice";
import React, { useEffect, useState } from "react";
import { Label } from "structure/Document";
import ui from "./styles.module.scss";
import Select from "react-select";

/**
 * @type {import('./types').StateCorporativeCreateInvoice}
 */
const INITIAL_VALUE = {
  isLoading: true,
  options: [],
  selected: null,
  exchangeSocialReason: false,
};

/**
 * Render the combo for social reason
 * @param {import("./types").PropsCorporativeCreateInvoice} props - Props
 * @returns {JSX.Element}
 */
export default function CorporativeCreateInvoice({
  onChange = () => {},
  value = 525,
}) {
  const [state, setState] = useState(INITIAL_VALUE);

  useEffect(() => {
    (async function () {
      const options = await getCorporativeComboInvoice(value);

      const defaultSocialReason = options.find((sr) => sr.id === value);

      onChange(defaultSocialReason);

      setState((current) => ({
        ...current,
        isLoading: false,
        selected: {
          ...defaultSocialReason,
          value: defaultSocialReason.id,
          label: `${defaultSocialReason.Rfc} - ${defaultSocialReason.Name}`,
        },
        options: [
          ...options.map((opt) => ({
            ...opt,
            value: opt.id,
            label: `${opt.Rfc} - ${opt.Name}`,
          })),
        ],
      }));
    })();
  }, []);

  // If user disabled exchange social reason, set the original social reason. The customer that original belongs to that document
  useEffect(() => {
    if (state.options.length <= 0) return;

    if (!state.exchangeSocialReason) {
      const defaultOption = state.options.find((sr) => sr.id === value);

      const parsedOption = {
        ...defaultOption,
        value: defaultOption.id,
        label: `${defaultOption.Rfc} - ${defaultOption.Name}`,
      };

      onChange(parsedOption);

      setState((current) => ({
        ...current,
        selected: parsedOption,
      }));
    }
  }, [state.exchangeSocialReason, state.options]);

  const handleChange = (item) => {
    onChange(item);
    setState((current) => ({
      ...current,
      selected: item,
    }));
  };

  /**
   * Update the toggle to exchange the social reason
   * @param {boolean} exchangeSocialReason - Exchange the social reason
   * @returns {void}
   */
  const toggleExchangeSocialReason = (exchangeSocialReason) =>
    setState((current) => ({
      ...current,
      exchangeSocialReason,
    }));

  if (state.isLoading) return <Spinner text={"Cargando"} hidden={false} />;

  if (!state.exchangeSocialReason)
    return (
      <div>
        <p className="font-weight-bold text-warning">
          Facturando a nombre de {state.selected.Name} - {state.selected.Rfc}
        </p>

        <Label>Cambiar razón social</Label>
        <ToggleComponent
          labelActive="Si, cambiar"
          labelInactive="No, conservar razón social del documento"
          isChecked={state.exchangeSocialReason}
          onChange={toggleExchangeSocialReason}
        />
      </div>
    );

  return (
    <div className={ui.options}>
      <p className="font-weight-bold text-warning">
        Facturando a nombre de {state.selected.Name} - {state.selected.Rfc}
      </p>

      <div>
        <Label>Cambiar razón social</Label>

        <ToggleComponent
          labelActive="Si, cambiar"
          labelInactive="No, conservar razón social"
          isChecked={state.exchangeSocialReason}
          onChange={toggleExchangeSocialReason}
        />
      </div>

      <div>
        <Label required={state.selected === null}>Razón social</Label>

        <Select
          className="saiko-select"
          classNamePrefix="saiko-select"
          isClearable={false}
          isSearchable={true}
          options={state.options}
          value={state.selected}
          placeholder="Selecciona"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
