import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import { DirectoryContext } from "pages/Directory";
import React, { useContext } from "react";

export default function ExcelReport() {
  const hook = useContext(DirectoryContext);

  if (hook.isDownloadingExcel) return <Spinner text={"Descargando"} hidden={false}/>;

  return (
    <ButtonV2 type="button" onClick={hook.downloadExcel}>
      <span>Excel</span>
      <Icon icon="excel" />
    </ButtonV2>
  );
}
