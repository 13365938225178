import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import useQueryParams from "customHooks/useQueryParams";
import React from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";
import {
  BankHeader,
  BankName,
  BankAccount,
  BankCurrency,
} from "components/general/BankHeader";

export const AssociatedFilesMovement = () => {
  const { idBankAccount } = useParams();
  const { movimiento, tipo , modulo } = useQueryParams();

  const url = tipo === "ingreso" ? "ingresos" : "egresos";


  return (
    <div style={{
      height: "100%",
      overflow:"auto",
      padding:"0 0 1rem 0"
    }}>
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: modulo === "movimientos" ? "/v2/bancos" : `/contabilidad/${url}`,
            text: modulo === "movimientos" ? "Bancos" : "Contabilidad",
          },
          {
            route: modulo === "movimientos" ? `/v2/bancos/${url}?cuenta=${idBankAccount}` : `/contabilidad/${url}`,
            text: tipo === "ingreso" ? "Ingresos" : "Egresos",
          },
          {
            route: "/",
            text: "Archivos asociados",
          },
        ]}
      />

      <div className="container">
        <BankHeader>
          {() => (
            <div className="my-3">
              <BankName />
              <BankAccount />
              <BankCurrency />
            </div>
          )}
        </BankHeader>

        <AssociateFiles
          idRegister={+movimiento}
          idTypeEntity={5}
          pathUpload={`complementos`}
        />
      </div>
    </div>
  );
};
