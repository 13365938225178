import React, { useContext } from "react";
import AvatarEditor from "react-avatar-editor";
import Icon from "components/individual/HeaderIcon";
import { ProfilePictureContext } from "..";
import styles from "../styles.module.scss";

/**
 * Preview of the current profile picture that has the user
 * @param {object} props - Props
 * @param {File|string} props.currentProfilePicture - Current profile picture that has the user
 * @returns {JSX.Element} Preview UI of the profile picture
 */
export default function PreviewPhoto({ currentProfilePicture = null }) {
  const { showEdition } = useContext(ProfilePictureContext);

  return (
    <div className={styles.previewPicture}>
      <AvatarEditor
        image={currentProfilePicture.img}
        width={250}
        height={250}
        position={currentProfilePicture.position}
        scale={currentProfilePicture.scale}
        color={[256, 256, 256, 1]}
        rotate={0}
        borderRadius={150}
      />

      <div className={styles.editProfilePicture}>
        <button
          onClick={() => showEdition(true)}
          style={{
            height: "35px",
            width: "35px",
            border: "1px solid black",
            borderRadius: "50%",
          }}
        >
          <Icon icon="edit" />
        </button>
      </div>
    </div>
  );
}
