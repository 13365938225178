import { Search } from "components/individual/Search";
import { parseInputDecimals } from "helpers/money";
import React, { useContext } from "react";
import { ContextContract } from "structure/Contract/List";
import { Label } from "structure/Document";

export default function ContractInput() {
  const { updateSearch, search } = useContext(ContextContract);

  return (
    <div>
      <Label>Contrato</Label>
      <Search
        hideSearchIcon={true}
        onDelete={() => updateSearch("")}
        placeholder="Escribe aquí"
        type="number"
        value={search}
        onChange={(value) => {
          const withoutDecimals = parseInputDecimals(value,0);
          updateSearch(withoutDecimals);
        }}
        min={1}
      />
    </div>
  );
}
