import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { createContext } from "react";
import Dialog from "structure/Dialog";
import useCancelInvoice, { CONTEXT_INITIAL_STATE } from "./useCancelInvoice";
import BodyModal from "./BodyModal";
import BtnConfirmCancel from "./ButtonCancel";
import usePermissions from "customHooks/usePermissions";

export const MOTIVO_OTROS = 7;

/**
 * @type {import("react").Context<import("./useCancelInvoice/types").ContextCancelInvoice>}
 */
export const ContextCancelInvoice = createContext(CONTEXT_INITIAL_STATE);

/**
 * Render a button to cancel the invoice
 * @param {import('./types').PropsCancelInvoice} props - Props
 * @returns {JSX.Element}
 */
export default function CancelInvoice({
  id = null,
  status = null,
  onCancelated = () => {},
  forwardRef = undefined,
  children = null,
}) {
  const hook = useCancelInvoice(id, onCancelated);

  const { checkPermission } = usePermissions();

  if (!checkPermission("dfa178da-d655-40ce-bfba-c36cfbadda3c")) return <></>;

  if (id === null || status === 3 || status === 2) return <></>;

  return (
    <ContextCancelInvoice.Provider value={{ ...hook, MOTIVO_OTROS }}>
      {children === null ? (
        <ButtonV2 forwardRef={forwardRef} onClick={hook.open}>
          <span>Cancelar</span>
        </ButtonV2>
      ) : (
        <div ref={forwardRef} onClick={hook.open}>
          {children}
        </div>
      )}

      <Dialog
        forwardRef={hook.dialog.refDialog}
        title="Cotizacion"
        closeCallback={hook.close}
        footer={<BtnConfirmCancel />}
      >
        <BodyModal />
      </Dialog>
    </ContextCancelInvoice.Provider>
  );
}
