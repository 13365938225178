import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Toggle from "react-toggle";

const ContainerToggle = styled.label`
  display: inline-flex;
  align-items: center;
  span {
    padding: 0 0 0 10px;
  }
`;

const ContainerToggleComponent = styled.div`
  display: inline-block;
  width: auto;
`;

/**
 * Render a toggle button
 * @param {object} props - Props
 * @param {boolean} [props.isChecked=false] - Indicates if it's checked
 * @param {string} [props.labelActive='Activo'] - Label text to show when toggle is active
 * @param {string} [props.labelInactive='Inactivo'] - Label text to show when toggle is inactive
 * @param {boolean} [props.disable=false] - If it is disable
 * @param {(value:boolean)=>void} [props.onChange=()=>{}] - Value of the toggle when it's clicked
 * @param {object} [props.forwardRef={}] - For react-hook-form
 * @returns {JSX.Element} Toggle component
 */
export default function ToggleComponent({
  isChecked = false,
  labelActive = "Activo",
  labelInactive = "Inactivo",
  disable = false,
  onChange = () => {},
  forwardRef = {},

}) {
  const [active, setActive] = useState(isChecked);

  useEffect(() => {
    setActive(isChecked);
  }, [isChecked]);

  const toggle = (value) => {
    setActive(value);
    onChange(value);
  };


  return (
    <ContainerToggleComponent>
      <ContainerToggle>
        <Toggle
          defaultChecked={active}
          value={+active}
          onChange={(event) => toggle(event.target.checked)}
          disabled={disable}
        />
        <span style={{
          fontWeight:'normal'
        }}>{active ? labelActive : labelInactive}</span>
      </ContainerToggle>
    </ContainerToggleComponent>
  );
}
