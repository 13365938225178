import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import invoiceParameters from "../validations";
import { useEffect } from "react";
import {
  getInvoiceParameters,
  updateInvoiceParameters,
} from "helpers/Apis/parameters";
import { useState } from "react";

/**
 * @type {import("./types").StateI}
 */
const INITIAL_STATE = {
  isLoading: true,
  tcTolerance: 0,
  isUpdating: false,
};

export default function useInvoiceParameters() {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const parameters = await getInvoiceParameters();

      form.reset({
        partialitiesAllowed: parameters.partialitiesAllowed,
        tcTolerance: `$${parameters.tcTolerance}`,
      });

      setState((current) => ({
        ...current,
        tcTolerance: parameters.tcTolerance,
        isLoading: false,
      }));
    })();
  }, []);

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(invoiceParameters),
  });

  const updateTolerance = (tcTolerance) =>
    setState((current) => ({
      ...current,
      tcTolerance,
    }));

  /**
   * Attempt update the invoice parameters data
   * @param {import("./types").FormSubmitData} data
   */
  async function attemptUpdate(data) {
    const dto = {
      tcTolerance: state.tcTolerance,
      partialitiesAllowed: data.partialitiesAllowed,
    };

    setState((current) => ({
      ...current,
      isUpdating: true,
    }));

    await updateInvoiceParameters(dto);

    setState((current) => ({
      ...current,
      isUpdating: false,
    }));
  }

  const printErrors = (errors) => console.log(errors);

  return {
    form,
    attemptUpdate,
    printErrors,
    updateTolerance,
    ...state,
  };
}
