import {
  AddCommentContainer,
  Comment,
  CommentItem,
} from "components/Sections/Documents/Comments/Containers";
import { AddPlus, Pen, Trash } from "components/individual/CardV2";
import { Icon } from "components/individual/CardV2/styles";
import { InputText } from "components/individual/inputs/Inputs";

import React, { useContext } from "react";
import WinQuoteContext from "../ContextWinQuote";
import { CommentButtons } from "./CommentButtons";

export const OdcCommentSecton = () => {
  const {
    documents,
    handleOnCurrentOdcCommentChange,
    handleOnAddOdcComments,
    defaultOdcCommentValue,
    handleOnDeleteOdcComment,
    handleOnEditComment,
    validateCommentEditing,
  } = useContext(WinQuoteContext);
  return (
    <>
      <AddCommentContainer>
        <div className="d-flex w-100 justify-content-center align-items-center">
        <InputText
            placeholderTxt={
              documents.order.isCommentEditing
                ? "Deshabilitado"
                : "Escribe aqui"
            }
            labelTxt="Comentario"
            aditionalClasses="w-100"
            onChange={handleOnCurrentOdcCommentChange}
            defaultValue={defaultOdcCommentValue()}
            onEnter={() => {
              handleOnAddOdcComments();
            }}
          />
          {/* <Icon
            // onClick={addComment={}}
            // className="aling-self-end"
            onKeyUp={(value) => {
              console.log(value);
            }}
          >
            {AddPlus}
          </Icon> */}
        </div>
        {!!documents.odc.comments.length ? (
          documents.odc.comments.map((comment, index) => (
            <CommentItem>
              <Comment>
                {validateCommentEditing(
                  comment,
                  documents.odc.currentComment
                ) ? (
                  <InputText
                    placeholderTxt={
                      documents.odc.isCommentEditing
                        ? "Deshabilitado"
                        : "Escribe aqui"
                    }
                    labelTxt="Comentario"
                    aditionalClasses="commnetStyle"
                    onChange={handleOnCurrentOdcCommentChange}
                    defaultValue={comment.comment}
                    onEnter={() => {
                      handleOnEditComment(
                        index,
                        3,
                        documents.odc.currentComment
                      );
                    }}
                  />
                ) : (
                  <p>{comment.comment}</p>
                )}
              </Comment>
              <CommentButtons
                comment={comment}
                idDocumentType={3}
                index={index}
                currentComment={documents.odc.currentComment}
              />
            </CommentItem>
          ))
        ) : (
          <h2 className="text-center m-4">No hay comentarios para mostrar</h2>
        )}
      </AddCommentContainer>
    </>
  );
};
