import React, { useContext, useEffect, useState } from "react";
import {
  InputEmail,
  InputText,
} from "../../../components/individual/inputs/Inputs";

import "react-datepicker/dist/react-datepicker.css";
import "./estilos.scss";
import LoginContext from "../../../context/Login/LoginContext";

export const Form = () => {
  const { userInfo } = useContext(LoginContext);
  const [startDate, setStartDate] = useState(null);
  function preparateInputs() {
    document.getElementById("nombre").value = userInfo[0].firstName;
    document.getElementById("midlename").value =
      userInfo[0].middleName === " " ? ' ' : userInfo[0].middleName;
    document.getElementById("apellidoP").value = userInfo[0].lastName1;
    document.getElementById("apellidoM").value = userInfo[0].lastName2;
    document.getElementById("email").value = userInfo[0].email;
    document.getElementById("username").value = userInfo[0].userName;
    const year = parseInt(userInfo[0].birthYear);
    const month = userInfo[0].birthMonth - 1;
    const day = userInfo[0].birthDay;
    const currentDate=new Date(year, month, day);
    const formatDate= currentDate.getDate() + '/'+(currentDate.getMonth()+1) + '/'+currentDate.getFullYear();
    document.getElementById("datePicker").value = formatDate;
  }
  useEffect(() => {
    if (userInfo.length !== 0) {
      preparateInputs();

      document.getElementById("nombre").disabled = true;
      document.getElementById("midlename").disabled = true;
      document.getElementById("apellidoP").disabled = true;
      document.getElementById("apellidoM").disabled = true;
      document.getElementById("email").disabled = true;
      document.getElementById("username").disabled = true;
      document.getElementById("datePicker").disabled = true;
    }
  }, [userInfo]);

  const submitForm = () => {
    console.log("Enviando formulario");
  };
  return (
    <>
      <div className="container">
        <form
          className="formConainer w-100"
          id="addCustomerInfoContact"
        >
          <div className=" d-flex flex-row justify-content-center">
            <InputText
              id="nombre"
              htmlFor="nombre"
              name="nombre"
              placeholderTxt="Escribe aquí"
              labelTxt="Nombre"
              aditionalClasses="mt-4 col"

            />
            <InputText
              id="midlename"
              htmlFor="midlename"
              name="midlename"
              placeholderTxt="Escribe aquí"
              labelTxt="Segundo nombre"
              aditionalClasses="mt-4 col"
            />
            <InputText
              id="apellidoP"
              htmlFor="apellidoP"
              name="apellidoP"
              placeholderTxt="Escribe aquí"
              labelTxt="Apellido Paterno"
              aditionalClasses="mt-4 col"
            />
            <InputText
              id="apellidoM"
              htmlFor="apellidoM"
              name="apellidoM"
              placeholderTxt="Escribe aquí"
              labelTxt="Apellido Materno"
              aditionalClasses="mt-4 col"
            />
          </div>
          <div className=" d-flex flex-row justify-content-center">
          <InputText
              id="datePicker"
              htmlFor="datePicker"
              name="datePicker"
              placeholderTxt="Escribe aquí"
              labelTxt="Fecha de nacimiento"
              aditionalClasses="mt-4 col"
            />
            <InputEmail
              idEmail="email"
              name="emial"
              placeholderTxt="Escribe aquí"
              labelTxt="Correo electronico"
              aditionalClasses="mt-4 col"
            />
            <InputText
              id="username"
              htmlFor="username"
              name="username"
              placeholderTxt="Escribe aquí"
              labelTxt="Nombre de usuario"
              aditionalClasses="mt-4 col"
            />
          </div>
        </form>
      </div>
    </>
  );
};
