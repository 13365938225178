import React, { useContext } from "react";
import { ContextCreateInvoiceV2 } from "..";
import { CfdiUse } from "components/general/Forms/Selects/CfdiUse";

export default function CustomCfdi() {
  const hook = useContext(ContextCreateInvoiceV2);

  if (hook.receiverData === null || hook.receiverData === undefined)
    return <></>;

  return (
    <CfdiUse
      idCustomer={hook.receiverData.id}
      cfdi={hook.cfdi}
      rfc={hook.receiverData.Rfc}
      onChange={(cfdi) => hook.updateCfdi(cfdi.Value)}
    />
  );
}
