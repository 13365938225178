import React from "react";
import { Modal } from "components/general/Modal";
import {
  BankAccount,
  SocialReason,
  BankAccountCuenta,
  BankCard,
  BankCurrency,
  BankDate,
  BankAmount,
  NextTransfer,
  NextCheck,
  Description,
  Comment,
  SubmitAddBankAccount,
  BankCLABE,
  Status,
} from "structure/BankAccount";
import scss from "../AddBankAccount/styles.module.scss";

export default function EditBankAccount({
  id,
  onClose = () => {},
  onSuccess = () => {},
}) {
  return (
    <>
      <BankAccount id={id} onSuccess={onSuccess}>
        {() => (
          <Modal
            isOpen={true}
            footerV2={() => <SubmitAddBankAccount />}
            displayButton={false}
            onCloseModal={onClose}
            title="Editar cuenta de banco"
          >
            {() => (
              <div className={scss.PositionForm}>
                <div className={scss.LefttSideModal}>
                  <SocialReason />
                  <BankAccountCuenta />
                  <BankCLABE />
                  <BankCurrency />
                  <BankDate />
                  <BankAmount />
                  <NextTransfer />
                  <NextCheck />
                  <Status />
                </div>
                <div className={scss.RightSideModal}>
                  <BankCard />
                  <Description />
                  <Comment />
                </div>
              </div>
            )}
          </Modal>
        )}
      </BankAccount>
    </>
  );
}
