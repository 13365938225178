import styled from "styled-components";

export const Header = styled.div`
  display: grid;
  grid-template-columns: 5% 14% 13% 13% 8% 13% 15.5% 15.5%;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }
`;

export const Toggle = styled.label`
  transition: background 0.5s ease;
  width: 100%;
  height: 100%;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  input {
    z-index: 0;
  }
  &:hover {
    background-color: #eeeeee;
  }
`;

export const Row = styled.label`
  display: grid;
  grid-template-columns: 5% 14% 13% 13% 8% 13% 15.5% 15.5%;
  min-height: 50px;
  justify-content: space-evenly;
  transition: background 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: #eeeeee;
  }
  p {
    margin: 0;
    font-weight: normal;
  }
  padding: 10px 0;
`;
