import Select from "components/individual/SelectV4";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import { getOcAccountingYears } from "helpers/Apis/concilation";
import React, { useEffect, useState } from "react";
import { Label } from "structure/Document";

/**
 * @type {import("./types").StateYearOcAccounting}
 */
const INITIAL_STATE = {
  isLoading: true,
  years: [],
};

/**
 * Render a combo for the years
 * @param {import("./types").PropsYearOcAccounting} props - Props
 * @returns {JSX.Element}
 */
export default function YearOcAccounting({ onChange = () => {} }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const years = await getOcAccountingYears();

      setState((current) => ({
        ...current,
        years: [
          { label: " -- SELECCIONA -- ", value: null, disabled: true },
          ...years.map((year) => ({
            label: year,
            value: year,
          })),
        ],
        isLoading: false,
      }));
    })();
  }, []);

  if (state.isLoading)
    return (
      <div>
        <Label>Año</Label>
        <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <div>
      <Label>Año</Label>
      <Select
        labelToDisplay="label"
        defaultSelected={{
          keyToCompare: "value",
          value: new Date().getUTCFullYear(),
        }}
        onChange={(item) => onChange(item.value)}
        options={async () => state.years}
      />
    </div>
  );
}
