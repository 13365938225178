import '../../../types/typedef/documents';

/**
 * Update a comment from the list
 * @param {string|number} id - ID of the comment to edit
 * @param {Comments[]} data - Actual comments on the state
 * @param {string} value - New content of the comment
 * @returns {object[]} List of comments updated
 */
export function updateComment(id,data,value){
    // Copy of state

    /**
     * @type {Comments}
     */
    let comments = [...data];

    // Find index of the comment
    const indexComment = comments.findIndex(comment=>comment.id === id);

    // Update the comment
    comments[indexComment].name = value;
    
    return comments;
}