import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { Success, Error } from "../alerts";
import { isValidHttpResCode } from "./fetch";

/**
 * Ge the schema permissions
 * @returns {Promise<import("react-tree-permissions/dist/types/hooks/usePermissions").PermissionI[]>}
 * @throws {[]} Empty schema permissions
 */
export async function getSchemaPermissions() {
  try {
    const { data } = await axios.get(`${URL_BASE}login/esquema-permisos`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get the information of the rols loaded into the system
 * @returns {Promise<import("customHooks/useRols/types").DtoRols>}
 */
export async function getRolsPaginated(page, search = null) {
  /**
   * @type {import("customHooks/useRols/types").DtoRols}
   */
  const errorData = {
    currentPage: 1,
    pages: 1,
    rols: [],
  };

  const searchQuery = search === '' ? "" : `&search=${search}`;

  try {
    const res = await fetch(
      `${URL_BASE}roles/tabla/roles?pageRequested=${page}${searchQuery}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    return errorData;
  } catch (error) {
    return errorData;
  }
}

/**
 * Get the rols of the system
 * @returns {import("types/typedef/roles").RolI[]} List of roles
 * @throws {[]} Empty list of roles
 */
export async function getRols() {
  try {
    const { data } = await axios.get(`${URL_BASE}roles/obtener-roles-activos`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get information of the rol
 * @param {number} idRol - Id of the rol
 * @returns {import("types/typedef/roles").RolInfoI} List of permissions that has that rol
 */
export async function getRol(idRol) {
  try {
    const { data } = await axios.get(`${URL_BASE}roles/${idRol}`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    return {};
  }
}

/**
 * Add the new rol into system
 * @param {import("types/typedef/customHooks/useRol").DtoAddRolI} dto
 * @returns {boolean} True if rol was added
 */
export async function addRol(dto) {
  try {
    await axios.post(`${URL_BASE}roles/agregar-rol`, dto, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const addRoles = async (infoData) => {
  try {
    const { data } = await axios.post(`${URL_BASE}roles/addroles`, infoData);

    if (data.status === 200) {
      Success(() => {}, data.message);
      return true;
    }
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
};

/**
 * Get the ids that the rol can filter on the system
 *
 * @returns {Array<number>} Users id that the rol can filter on the system
 */
export async function obtainUsersAllowed(rol) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}roles/filtro-usuarios/rol/${rol}`
    );

    if (data.status === 200) return data.allowedFilter;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Fetch to update the information of rol
 *
 * @param {number} idRol - id of the rol to update
 * @param {Array<number>} toAdd - ids of executives to add for this rol
 * @param {Array<object>} toDelete - Object with the information of the executives
 * that can not be filtered by this rol anymore
 * @returns {boolean} true if the update was success
 */
export async function updateRol(idRol, toAdd, toDelete, data, userName) {
  const form = data;

  try {
    const { data } = await axios.put(`${URL_BASE}roles/${idRol}`, {
      toAdd,
      toDelete,
      form,
      userName,
    });

    if (data.status === 200) {
      Success(() => {}, "Rol actualizado");
      return true;
    }

    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

//! ====== REFACTORIZACIÓN ==================

/**
 *
 * @param {import("types/typedef/roles").RoleDataI} bodyData - Information to needed to create the record
 * @returns {import("types/typedef/roles").AddRoleResponseI}
 */
export const AddRoles = async (bodyData) => {
  try {
    const result = axios.post(`${URL_BASE}/roles/agregar-rol`, bodyData, {
      withCredentials: true,
    });
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *
 * @returns {import("types/typedef/roles").AvtiveRolesResponseI} The list of active roles
 */
export const GetAllActiveRoles = async () => {
  try {
    const roles = axios.get(`${URL_BASE}roles/obtener-roles-activos`, {
      withCredentials: true,
    });
    return roles;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Update the rol information
 * @param {import("types/typedef/roles").UpdateRolDtoI} dto - Information to update
 * @returns {boolean} True if was updated
 */
export const UpdateRol = async (dto) => {
  try {
    await axios.put(
      `${URL_BASE}roles/actualizar-rol`,
      {
        UpdateRolDto: dto,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    const {
      response: { data },
    } = error;

    Error(() => {}, data.Message);

    return false;
  }
};

/**
 * Get the permissions that has the logged user
 * @returns {import("../../../../types/roles").DtoPermissions} Permissions that has the current user
 */
export async function getUserPermissions() {
  try {
    const { data } = await axios.get(`${URL_BASE}usuarios/obtener/permisos`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {}
}
