import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Render a select combo
 * @param {import('./types').PropsSelect} props - Props
 * @returns {JSX.Element}
 */
export default function Select(props) {

  const {
    defaultSelected = null,
    onChange = () => {},
    options = async () => [],
    labelToDisplay = "",
  } = props;

  const key = useRef(window.crypto.randomUUID());

  const [state, setState] = useState({
    optionsToUse: [],
    defaultSelected,
    defaultSelectedToUse: null,
  });

  useEffect(() => {
    (async function () {
      const optionsConsulted = await options();
      const parsedOptions = optionsConsulted.map((opt, i) => ({
        ...opt,
        keyComponent: `${key.current}-${i}`,
      }));

      const defaultSelectedToUse =
        defaultSelected === null
          ? null
          : parsedOptions.find(
              (item) =>
                item[defaultSelected.keyToCompare] === defaultSelected.value
            ) || null;


      setState((current) => ({
        ...current,
        optionsToUse: parsedOptions,
        defaultSelectedToUse,
      }));
    })();
  }, [defaultSelected]);

  const handleOnChange = (keyComponent) => {
    const item = state.optionsToUse.find(
      (option) => option.keyComponent === keyComponent
    );
    onChange(item)
  };

  if (state.optionsToUse.length <= 0) return <></>;

  return (
    <select
      className={`px-3 select-css`}
      {...props}
      onChange={(e) => handleOnChange(e.target.value)}
    >
      {state.optionsToUse.map((option, i) => (
        <option
          selected={
            state.defaultSelectedToUse === null
              ? false
              : option.keyComponent === state.defaultSelectedToUse.keyComponent
          }
          disabled={Object.keys(option).includes('disabled')}
          value={option.keyComponent}
          key={option.keyComponent}
        >
          {option[labelToDisplay]}
        </option>
      ))}
    </select>
  );
}
