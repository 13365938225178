import useDialog from "customHooks/useDialog";
import useInvoiceUuid from "customHooks/useInvoiceUuid";
import { getInvoiceReceptionInfo } from "helpers/Apis/odc";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateMoreInfoInvoiceReception}
 */
const INITIAL_STATE = {
  isLoading: false,
  ocs: {
    associationsWithOc: [],
    associationsWithoutOc: [],
  },
  isOpen: false,
};

export default function useMoreInfoInvoiceReception(idLegalDocumen,uuid) {
  const [state, setState] = useState(INITIAL_STATE);

  const invoice = useInvoiceUuid(state.isOpen ? uuid : null) ;

  const dialog = useDialog();

  const openModal = () =>
    setState((current) => ({
      ...current,
      isOpen: true,
    }));

  const closeModal = () =>
    setState((current) => ({
      ...current,
      isOpen: false,
    }));

  useEffect(() => {
    (async function () {
      if (state.isOpen) {
        dialog.open();

        setState((current) => ({
          ...current,
          isLoading: true,
        }));

        const ocs = await getInvoiceReceptionInfo(idLegalDocumen);

        setState((current) => ({
          ...current,
          isLoading: false,
          ocs,
        }));
      }

      if (!state.isOpen) {
        setState(INITIAL_STATE);
        return;
      }
    })();
  }, [state.isOpen]);

  return {
    ...state,
    dialog,
    closeModal,
    openModal,
    invoice
  };
}
