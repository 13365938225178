import React, { useContext } from "react";
import { ContextOdc } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function GenerateCxP() {
  const hook = useContext(ContextOdc);

  //   Do not render if the status are the alloweds
  if (
    hook.STATUS[hook.rowSelected.status.id] !== "active" &&
    hook.STATUS[hook.rowSelected.status.id] !== "closed"
  )
    return <></>;

  if (hook.isUpdatingOc)
    return <Spinner text={"Actualizando"} hidden={false} />;

  return (
    <ButtonV2 onClick={hook.attemptGenerateCxp}>
      <span>
        {hook.STATUS[hook.rowSelected.status.id] === "active"
          ? "No genera CxP"
          : "Genera CxP"}
      </span>
    </ButtonV2>
  );
}
