import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import React from "react";
import { useContext } from "react";
import { BankAccountContext } from "..";
import Icon from "components/individual/HeaderIcon";

export default function SubmitAddBankAccount() {
  const context = useContext(BankAccountContext);

  if (context.isFetchingServer)
    return (
      <Spinner
        hidden={false}
        text={context.id === null ? "Agregando cuenta" : "Editando cuenta"}
      />
    );

  return (
    <ButtonV2 disabled={!context.isValidForm}>
      {context.id === null ? (
        <>
          <span>Agregar</span>
          <Icon icon="save" />
        </>
      ) : (
        <>
          <span>Actualizar</span>
          <Icon icon="save" />
        </>
      )}
    </ButtonV2>
  );
}
