import React, { useContext } from "react";
import { CuotasContext } from "..";
import { Label } from "structure/Document";

const LABEL_TYPE = {
  invoice: "Facturación",
  contribution: "Contribución",
};

export default function TypeFee() {
  const hook = useContext(CuotasContext);

  return (
    <div>
      <Label>Tipo de cuota</Label>
      <p>
        {LABEL_TYPE[hook.type] || "ND"}
      </p>
    </div>
  );
}
