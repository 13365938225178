import { Modal } from "components/general/Modal";
import { ExcelInput } from "components/individual/ExcelInput";
import React, { useRef } from "react";
import { useState } from "react";
import TextIcon from "structure/Menu/TextIcon";
import scss from "../../../structure/Menu/index.module.scss";
import { Footer } from "./Footer";
import modalScss from "./styles.module.scss";

/**
 * Render the menu item for uen load
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>} props - Props
 * @returns {JSX.Element}
 */
export const UENSetup = (props) => {
  const [file, setFile] = useState(undefined);

  const idButton = useRef(window.crypto.randomUUID());

  const openModal = () => {
    const dom = document.getElementById(idButton.current);
    if (dom) {
      dom.click();
    }
  };

  return (
    <>
      <div className={modalScss.container}>
        <Modal
          title="UEN"
          footerV2={({ closeModal }) => <Footer file={file} />}
          propsOpenModal={{
            style: {
              display: "none",
            },
            id: idButton.current,
          }}
        >
          {() => (
            <div className="d-flex flex-column align-items-center">
              <h1 className="text-center">
                Por favor, carga la plantilla de excel
              </h1>

              <ExcelInput onChange={(e) => setFile(e)} />
            </div>
          )}
        </Modal>
      </div>

      <p
        {...props}
        className={scss.item}
        onClick={() => {
          openModal();
        }}
      >
        <TextIcon icon="excel" text="UEN" />
      </p>
    </>
  );
};
