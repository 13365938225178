import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { schemaAddUen, schemaUpdateUen } from "./schema";
import { useRef } from "react";
import { addUen, updateUen as apiUpdateUen } from "helpers/Apis/invoice";
import LoginContext from "context/Login/LoginContext";
import useDialog from "customHooks/useDialog";
import { useEffect } from "react";
import { GetUEN } from "helpers/Apis/Documents";
import Fuse from "fuse.js";

/**
 * @type {import("./types").StateUseUen}
 */
const INITIAL_STATE = {
  isLoading: true,
  uen: undefined,
  uens: [],
  uensSearched: [],
  currentPage: 1,
  pages: 0,
  isAddingUen: false,
  isUpdatingUen: false,
  iva: 16,
  isExempt: false,
  SATcode: "",
  SATUM: "",
  satUmDescription: "",
  satCodeDescription: "",
  search: "",
  isEditing: false,
  refetch: false,
  isValidForm: false,
  isValidFormUpdate:false
};

/**
 * @type {import("./types").ContextAdminUne}
 */
export const ContextUseUen = {
  ...INITIAL_STATE,
  breadcrum: [],
  formAdd: undefined,
  formUpdate: undefined,
  attemptAddUen: () => {},
  idFormAdd: window.crypto.randomUUID(),
  idFormUpdate: window.crypto.randomUUID(),
  printError: () => {},
  updateIva: () => {},
  updateUen: () => {},
  updateSearch: () => {},
  dialogAdd: undefined,
  dialogEdit: undefined,
  setUen: () => {},
  attemptUpdateUen: async () => {},
  handleEditModal: () => {},
  customOpenAdd: () => {},
};

/**
 *
 * @returns {import("./types").ReturnUseUen}
 */
export default function useUen() {
  const [state, setState] = useState(INITIAL_STATE);

  const dialogAdd = useDialog();
  const dialogEdit = useDialog();

  const { userInfo } = useContext(LoginContext);

  const idFormAdd = useRef(window.crypto.randomUUID());
  const idFormUpdate = useRef(window.crypto.randomUUID());

  /**
   * Set the state in order to allow submit the form
   * @param {boolean} isValidForm
   * @returns {void}
   */
  const setIsValidForm = (isValidForm) =>
    setState((current) => ({
      ...current,
      isValidForm,
    }));

  /**
   * Set the state in order to allow submit the form
   * @param {boolean} isValidFormUpdate
   * @returns {void}
   */
  const setIsValidFormUpdate = (isValidFormUpdate) =>
    setState((current) => ({
      ...current,
      isValidFormUpdate,
    }));

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./types").UenUpdateReactHookForm>}
   */
  const formUpdate = useForm({
    criteriaMode: "firstError",
    mode: "all",
    resolver: yupResolver(schemaUpdateUen),
  });

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
      }));

      const apiUens = await GetUEN(false);

      setState((current) => ({
        ...current,
        uens: apiUens,
        isLoading: false,
      }));
    })();
  }, [state.refetch]);

  useEffect(() => {
    if (state.uen === undefined || state.uen === null) return;

    formUpdate.reset({
      description: state.uen.description,
      family: state.uen.family,
      marginRate: state.uen.marginRate,
      status: state.uen.status,
      subFamily: state.uen.subFamily,
    });
  }, [state.uen]);

  useEffect(() => {
    if (state.search === "") {
      setState((current) => ({
        ...current,
        uensSearched: [],
      }));
      return;
    }

    /**
     * @type {Fuse.IFuseOptions<import("types/typedef/catalogue").UenI>}
     */
    const options = {
      keys: ["SATUM", "SATcode", "family", "satCodeDescription", "subFamily"],
      isCaseSensitive: true,
    };

    const fuse = new Fuse(state.uens, options);

    const result = fuse.search(state.search);

    const items = result.map((item) => item.item);

    setState((current) => ({
      ...current,
      uensSearched: items,
      uen: null,
    }));
  }, [state.search]);

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./types").UenAddReactHookForm>}
   */
  const formAdd = useForm({
    criteriaMode: "firstError",
    mode: "all",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(schemaAddUen),
  });

  useEffect(() => {
    if (!formAdd.formState.isValid) {
      setIsValidForm(false);
      return;
    }

    const isValid =
      state.SATUM === "" ||
      state.SATcode === "" ||
      state.satCodeDescription === "" ||
      state.satUmDescription === "" ||
      state.satCodeDescription === undefined ||
      state.satUmDescription === undefined
        ? false
        : true;

    setIsValidForm(isValid);
  }, [formAdd.formState]);

  useEffect(() => {
    if (!formUpdate.formState.isValid) {
      setIsValidFormUpdate(false);
      return;
    }

    const isValid =
      state.SATUM === "" ||
      state.SATcode === "" ||
      state.satCodeDescription === "" ||
      state.satUmDescription === "" ||
      state.satCodeDescription === undefined ||
      state.satUmDescription === undefined
        ? false
        : true;

        setIsValidFormUpdate(isValid);
  }, [formUpdate.formState]);


  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  /**
   * Handle the modal edit UEN
   * @param {boolean} isEditing - **true** for opening and  **false** for closing
   */
  const handleEditModal = (isEditing) => {
    if (isEditing === true) {
      dialogEdit.open();
    } else {
      dialogEdit.close();
    }

    setState((current) => ({
      ...current,
      isEditing,
    }));
  };

  /**
   * Add the UEN into system
   * @param {import("./types").UenAddReactHookForm} data - Information of the form
   * @returns {Promise<void>}
   */
  const attemptAddUen = async (data) => {
    setState((current) => ({
      ...current,
      isAddingUen: true,
    }));

    const wasAdded = await addUen({
      createdBy: userInfo[0].fullName,
      description: data.description,
      excent: state.isExempt || false,
      family: data.family,
      iva: state.iva,
      marginRate: data.marginRate,
      satCode: state.SATcode,
      satCodeDescription: state.satCodeDescription,
      satUm: state.SATUM,
      satUmDescription: state.satUmDescription,
      subFamily: data.subFamily,
    });
    setState((current) => ({
      ...current,
      isAddingUen: false,
    }));

    if (wasAdded) {
      dialogAdd.close();
      setState((current) => ({
        ...current,
        refetch: !current.refetch,
      }));
      formAdd.reset({
        description: "",
        family: "",
        marginRate: 0,
        subFamily: "",
      });
    }
  };

  /**
   * Update the uen on the system
   * @param {import("./types").UenUpdateReactHookForm} data - Information of the form
   */
  const attemptUpdateUen = async (data) => {
    setState((current) => ({
      ...current,
      isUpdatingUen: true,
    }));

    const wasUpdated = await apiUpdateUen({
      createdBy: userInfo[0].fullName,
      description: data.description,
      excent: state.isExempt,
      family: data.family,
      iva: state.iva,
      marginRate: data.marginRate,
      satCode: state.SATcode,
      satCodeDescription: state.satCodeDescription,
      satUm: state.SATUM,
      satUmDescription: state.satUmDescription,
      subFamily: data.subFamily,
      idUen: state.uen.UENID,
      status: data.status,
    });

    if (wasUpdated) {
      handleEditModal(false);
      setState((current) => ({
        ...current,
        uen: undefined,
        refetch: !current.refetch,
      }));
    }

    setState((current) => ({
      ...current,
      isUpdatingUen: false,
    }));
  };

  const printError = (errors) => {
    console.log(errors);
  };

  /**
   * @type {import("components/individual/breadCrums/BreadCrumV2").Breadcrum[]}
   */
  const breadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/uen",
      text: "Procesos especiales",
    },
    {
      route: "/uen",
      text: "UEN",
    },
  ];

  /**
   * Update the iva to usen on the uen
   * @param {import("components/general/Forms/Selects/IvasCatalogue/types").Ivas} iva
   * @param {boolean} isExcent - Is excent
   * @returns {void}
   */
  const updateIva = (iva, isExcent) =>
    setState((current) => ({
      ...current,
      isExempt: isExcent,
      iva,
    }));

  /**
   * Update the information for the uen and um SAT
   * @param {import("./types").OnChangeUen} uen - Information of the uen
   * @returns {void}
   */
  const updateUen = (uen) =>
    setState((current) => ({
      ...current,
      satUmDescription: uen.satUmDescription,
      SATcode: uen.SATcode,
      SATUM: uen.SATUM,
      satCodeDescription: uen.satCodeDescription,
      isExempt: uen.isExempt,
    }));

  /**
   * Set the uen to use on the table
   * @param {import("types/typedef/catalogue").UenI} uen - Uen set
   */
  const setUen = (uen) =>
    setState((current) => ({
      ...current,
      uen,
    }));

  const customOpenAdd = () => {
    dialogAdd.open();
    formAdd.trigger(["description", "family", "marginRate", "subFamily"]);
  };

  return {
    ...state,
    breadcrum,
    formAdd,
    formUpdate,
    attemptAddUen,
    idFormAdd: idFormAdd.current,
    printError,
    updateIva,
    updateUen,
    dialogAdd,
    dialogEdit,
    setUen,
    updateSearch,
    idFormUpdate: idFormUpdate.current,
    attemptUpdateUen,
    handleEditModal,
    customOpenAdd,
  };
}
