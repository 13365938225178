import React from "react";
import useUen, { ContextUseUen } from "./useUen";
import { createContext } from "react";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import ModalAdd from "./ModalAdd";
import TableUen from "./TableUen/index";
import SearchUen from "./SearchUen/index";
import style from "./styles.module.scss";
import OptionsUen from "./Options/index";
import usePermissions from "customHooks/usePermissions";

export const ContextUenAdmin = createContext(ContextUseUen);

export default function UenAdmin() {
  const hook = useUen();

  usePermissions('3c14b37b-9b40-4fe9-8feb-d9c02899a2af');

  return (
    <div className={style.uenContainer}>
      <ContextUenAdmin.Provider value={hook}>
        <div className={style.uenHeader}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className={style.searchFilter}>
            <SearchUen />
            <ModalAdd />
          </div>
        </div>

        <div className="px-5">
          <OptionsUen />
          <TableUen />
        </div>
      </ContextUenAdmin.Provider>
    </div>
  );
}
