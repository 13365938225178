import { fetchDocuments } from "../../helpers/Apis/Documents";

let OcTable = {
  idTable: "documents",
  idPagination: "ocDocumentsPagination",
  pages: 0,
  rows: [],
  actualPage: 0,
  headerConfig: [
    {
      text: "No. doc.",
      columnNameDB: "idDocument",
      idHeader: "invoice-number",
      attributeToPrint: "noOc",
      sortable: false,
    },
    {
      text: "Moneda",
      columnNameDB: "idCurrency",
      idHeader: "oc-currency",
      attributeToPrint: "Moneda",
      sortable: false,
    },
    {
      text: "Importe",
      columnNameDB: "totalAmount",
      idHeader: "oc-import",
      attributeToPrint: "totalAmount",
      sortable: false,
    },
    {
      text: "IVA",
      columnNameDB: "idDocument",
      idHeader: "iva-invoice",
      attributeToPrint: "ivaAmount",
      sortable: false,
      css: "text-right",
    },
    {
      text: "Total",
      columnNameDB: "totalAmount",
      idHeader: "invoice-import",
      attributeToPrint: "totalAmount",
      sortable: false,
      css: "text-right",
    },
    {
      text: "Registro",
      columnNameDB: "createdDate",
      idHeader: "oc-created-date",
      attributeToPrint: "createdDate",
      sortable: false,
    },
    {
      text: "Vigencia",
      columnNameDB: "expirationDate",
      idHeader: "oc-expiration-date",
      attributeToPrint: "expirationDate",
      sortable: false,
    },
    {
      text: "Ejecutivo",
      columnNameDB: "idExecutive",
      idHeader: "executive-created",
      attributeToPrint: "Ejecutivo",
      sortable: false,
      sortThis: true,
    },
    {
      text: "Estatus",
      columnNameDB: "expirationDate",
      idHeader: "quote-expiration-date",
      attributeToPrint: "Estatus",
      sortable: false,
    },
  ],
  styleTable: "default",
  cbSelection: () => {},
  urlParams: "",
  idRows: "idDocument",
  sort: {
    sqlSort: true,
    sortASC: false,
  },
  paginationFn: fetchDocuments,
  attributesResponse: {
    pages: "pages",
    actualPage: "actualPage",
    data: "documents",
  },
};

export default OcTable;
