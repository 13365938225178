import { saveAs } from "file-saver";
import { getResidueBankAccounts, getResidueBankAccountsExcel } from "helpers/Apis/Banks/index";
import { useState } from "react";
import { useEffect } from "react";

/**
 * @type {import("./types").StateBankResidues}
 */
const INITIAL_STATE = {
  isLoading: true,
  report: undefined,
  isDownloadingExcel:false
};

export const CONTEXT_INITIAL_STATE = {
  ...INITIAL_STATE,
};

export default function useBankResidue() {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiResidues = await getResidueBankAccounts();

      setState((current) => ({
        ...current,
        isLoading: false,
        report: apiResidues,
      }));
    })();
  }, []);

  const attemptExcel = async() =>{
    setState(current=>({
      ...current,
      isDownloadingExcel:true
    }));

    const excel = await getResidueBankAccountsExcel();

    if(excel!==undefined){
      saveAs(excel,'Saldo cuentas bancarias.xlsx');
    }

    setState(current=>({
      ...current,
      isDownloadingExcel:false
    }));
  }

  return {
    ...state,
    attemptExcel
  };
}
