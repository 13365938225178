import ButtonV2 from "components/individual/ButtonsV2/Button";
import { mnCurrency } from "helpers/money";
import React, { useContext, useEffect, useState } from "react";
import { EgressAssociationContext } from "..";
import NonDeductible from "components/general/NonDeductible";
import { Spinner } from "components/individual/Spinner/Spinner";

/**
 * Get the assocaition of multiple concept with the quantity non deductible
 * @param {object} props - Props
 * @param {number} props.nonDeductible - Quantity that will be non deductible
 * @param {string} props.currency - Currency of the quantity non deductible
 * @param {(object:import("types/typedef/components/NonDeductible").onChangeI)=>void} props.onChange - Information when the grid changes
 * @returns {JSX.Element} Component
 */
export default function ModalNonDeductible({
  nonDeductible,
  currency,
  onChange = () => {},
}) {
  const [state, setState] = useState({
    showConcepts: false,
    loadingConcepts: true,
    concepts: [],
    conceptsAssociation: {},
  });

  const {
    returnStep,
    gridInvoice: { total },
    isAssociating,
    associate,
    toggleShowConcepts,
  } = useContext(EgressAssociationContext);

  const showConcepts = () => {
    toggleShowConcepts(true);
    setState({
      ...state,
      showConcepts: true,
    });
  };

  useEffect(() => {
    if (total === nonDeductible) {
      showConcepts();
    }
  }, []);

  return (
    <>
      {!state.showConcepts ? (
        <>
          {isAssociating ? (
            <Spinner hidden={false} text="Asociando..." />
          ) : (
            <>
              <hr />
              <h4 className="text-center">
                Deseas ¿asociar y dejar en proceso? o ¿asociar{" "}
                {mnCurrency(nonDeductible)} {currency} a concepto ?
                {/* {mnCurrency(nonDeductible)} {currency} se iran a no deducibles.
            Deseas continuar */}
              </h4>

              <div className="d-flex justify-content-center my-4">
                <div className="mr-4">
                  <ButtonV2 onClick={showConcepts}>
                    Asociar a un concepto
                  </ButtonV2>
                </div>
                <div className="mr-4">
                  <ButtonV2 onClick={async () => await associate()}>
                    Asociar y dejar en proceso
                  </ButtonV2>
                </div>
                <ButtonV2 type="danger" onClick={returnStep}>
                  Regresar
                </ButtonV2>
              </div>
            </>
          )}
        </>
      ) : (
        <NonDeductible
          currency={currency}
          residue={nonDeductible}
          typeConcept={1}
          onChange={onChange}
        />
      )}
    </>
  );
}
