export const breadBankMovements = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Bancos",
      className: "breadcrumActivado",
      url: "/bancos",
      click: true,
    },
    {
      contentText: "Movimientos",
      url: "/bancos",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const winQuote = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      className: "breadcrumActivado",
      url: "/directorio",
      click: true,
    },
    {
      contentText: "Documentos",
      url: "/",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Ganar",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

/**
 * Breadcrum path
 * @param {number} idTypeDoc - Id of the type document
 * @param {number|string} customerId - Id of the customer belongs that document
 * @returns {{
 *  route:string,
 *  text:string
 * }[]}
 */
export const breadEditDoc = (idTypeDoc, customerId) => {
  const commonPath = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${customerId}`,
      text: "Documentos",
    },
  ];

  const typeDocs = {
    2: {
      route: "",
      text: "Pre-factura",
    },
    3: {
      route: "",
      text: "Orden de compra",
    },
    6: {
      route: "",
      text: "Contrato",
    },
  };

  const unfoundedPath = {
    route: "",
    text: "[Crear el path]",
  };

  return [...commonPath, typeDocs[idTypeDoc] || unfoundedPath];
};

export const navAddQuote = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      className: "breadcrumActivado",
      url: "/directorio",
      click: true,
    },
    {
      contentText: "Documentos",
      url: "/directorio/documentos",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Agregar cotizacion",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const associateMove = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Bancos",
      className: "breadcrumActivado",
      url: "/bancos",
      click: true,
    },
    {
      contentText: "Movimientos",
      url: "/bancos/movimientos",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Asociar",
      url: "",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export let breadAddEdit = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Bancos",
      className: "breadcrumActivado",
      url: "/bancos",
      click: true,
    },
    {
      contentText: "Movimientos",
      url: "/bancos/movimientos",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Agregar ingreso rapido",
      url: "",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export let editMovement = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Bancos",
      className: "breadcrumActivado",
      url: "/bancos",
      click: true,
    },
    {
      contentText: "Movimientos",
      url: "/bancos/movimientos",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "[Edit this]",
      url: "",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const BreadcrumIndexDirectory = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      url: "/directorio",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const breadAddCustomer = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      url: "/directorio",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Agregar",
      url: "/directorio/agregar",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const breadEditCustomer = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      url: "/directorio",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Editar",
      url: "/directorio",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const BreadcrumDocuments = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      url: "/directorio",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Documentos",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const userBredCrums = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "User",
      url: "/usuarios",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};

export const RolsNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Procesos Especiales",
      className: "breadcrumActivado",
      url: "/inicio",
      click: false,
    },
    {
      contentText: "Roles",
      className: "breadcrumDesactivo",
      url: "/roles",
      click: false,
    },
  ],
};

export const UsersNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Procesos Especiales",
      className: "breadcrumActivado",
      url: "/inicio",
      click: false,
    },
    {
      contentText: "Usuarios",
      className: "breadcrumDesactivo",
      url: "/usuarios",
      click: false,
    },
  ],
};

export const BreadcrumUsers = [
  {
    route: "/inicio",
    text: "Inicio",
  },
  {
    route: "/usuarios",
    text: "Procesos especiales",
  },
  {
    route: "",
    text: "Usuarios",
  },
];

export const NoticeNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Procesos Especiales",
      className: "breadcrumActivado",
      url: "/inicio",
      click: false,
    },
    {
      contentText: "Avisos",
      className: "breadcrumDesactivo",
      url: "/avisos",
      click: false,
    },
  ],
};
export const ForecastNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Reporte Ventas",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Forecast",
      className: "breadcrumActivado",
      url: "/forecast",
      click: false,
    },
  ],
};
export const FacturasNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Reporte Ventas",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Facturas",
      className: "breadcrumActivado",
      url: "/facturas",
      click: false,
    },
  ],
};
export const OportunidadesNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Reporte Ventas",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Oportunidades",
      className: "breadcrumActivado",
      url: "/oportunidades",
      click: false,
    },
  ],
};
export const ContactosNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      className: "breadcrumActivado",
      url: "/directorio",
      click: true,
    },
    {
      contentText: "Contactos",
      className: "breadcrumDesactivo",
      url: "/contactos",
      click: false,
    },
  ],
};

export const BanksNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Bancos",
      className: "breadcrumDesactivo",
      url: "/bancos",
      click: false,
    },
  ],
};
export const UserProfileNavigation = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Mi Perfil",
      className: "breadcrumDesactivo",
      url: "/userprofile",
      click: false,
    },
  ],
};

export const BreadcrumReminders = {
  breadcrum: [
    {
      contentText: "Inicio",
      className: "breadcrumActivado",
      url: "/inicio",
      click: true,
    },
    {
      contentText: "Directorio",
      url: "/directorio",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Documentos",
      url: "/directorio/documentos",
      className: "breadcrumActivado",
      click: true,
    },
    {
      contentText: "Recordatorio",
      url: "",
      className: "breadcrumDesactivo",
      click: false,
    },
  ],
};
