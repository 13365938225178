import { Search } from "components/individual/Search";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextDocuments } from "../..";
import { parseInputDecimals } from "helpers/money";

export default function NoDoc() {
  const hook = useContext(ContextDocuments);

  return (
    <div>
      <Label>No. doc.</Label>
      <Search
        hideSearchIcon={true}
        type="number"
        placeholder="Escribe aquí"
        value={parseInputDecimals(`${hook.noDocument}`,0) }
        onChange={(data) => hook.setNoDocument(+data)}
        onDelete={e=>hook.setNoDocument(null)}
        min={1}
      />
    </div>
  );
}
