import React, { useContext } from "react";
import buttonMenu from "components/individual/HeaderIcon/index.module.scss";
import Icon from "components/individual/HeaderIcon";
import LoginContext from "context/Login/LoginContext";
import usePushNotification from "customHooks/usePushNotification";
import PromptAllow from "./PromptAllow";
import TurnOff from "./TurnOff";
import TurnOn from "./TurnOn";
import { createContext } from "react";
import { useEffect } from "react";

/**
 * Context to handle the push notifications of the app
 * @type {import("react").Context<import("customHooks/usePushNotification/types").ReturnUsePushNotification|undefined>}
 */
export const ContextPushNotification = createContext(undefined);

export default function PushNotifications({ onClosed = () => {} }) {
  const { userInfo } = useContext(LoginContext);

  const hook = usePushNotification();

  useEffect(()=>{
    // Modal closed
    	if(hook.displayActive === false && hook.displayTurnOff === false && hook.displayTutorial === false){
        onClosed();
      }
  },[hook.displayTutorial,hook.displayTurnOff,hook.displayActive])

  if (userInfo.length <= 0) return <></>;

  return (
    <ContextPushNotification.Provider value={hook}>
      <PromptAllow isOpen={hook.displayTutorial} />
      <TurnOff isOpen={hook.displayTurnOff} />
      <TurnOn isOpen={hook.displayActive} />
      <button
        onClick={async () => await hook.handleToggleNotification()}
        className={
          hook.areActive
            ? buttonMenu.activeNotifications
            : buttonMenu.disactiveNotifications
        }
      >
        <Icon icon="comments" />
      </button>

      
    </ContextPushNotification.Provider>
  );
}
