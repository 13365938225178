import React, { useContext } from "react";
import { ContextInvoice } from "../..";
import StatusInvoice from "components/general/Forms/Selects/StatusInvoice";
import { Label } from "structure/Document";

export default function StatusSelect() {
  const { updateStatus, status, from } = useContext(ContextInvoice);

  if (from === "contabilidad") return <></>;

  return (
    <div className="d-flex flex-column">
      <Label>Estatus</Label>
      <StatusInvoice
        from={from}
        onChange={(option) => updateStatus(option.state)}
        includeTodos={true}
        value={status}
        includeNotCreatedAgainstSat={from === "pedidos" ? true : false}
      />
    </div>
  );
}
