import axios from "axios";
import { data } from "jquery";
import { URL_BASE } from "routes/routes";
import { getInfoContact } from "./contactInfoApi";
import { GetCustomer, getCustomer } from "./Directory";
import { GetDocument } from "./Documents";

export const IDS_TYPE_SECTION = {
  documentos: 1,
  directorio: 2,
  contactos: 3,
  personales: 4,
  autorizaciones: 5,
};

/**
 * Get the default title to use when creating a new to do
 * @param {import("structure/ToDo/types").TypeToDo} type - Type of to do to create
 * @param {number} id - Id of the register to search
 * @returns {string} Default title when trying to add a new todo
 */
export async function getTitleToDo(type, id) {
  try {
    switch (type) {
      case "documentos":
        const apiDocument = await GetDocument(id);
        return `${apiDocument.type.description} ${apiDocument.documentNumber}`;

      case "directorio":
        const apiCustomer = await GetCustomer(id);
        return `${apiCustomer.type.description} ${apiCustomer.id}`;

      case "contactos":
        const apiContact = await getInfoContact(id);
        return `${apiContact.firstName} ${apiContact.middleName}`;

      default:
        return "";
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
}

/**
 * Get the tags that can be used for the to do
 * @param {import("structure/ToDo/types").TypeToDo} typeSection
 * @return {import("components/general/Forms/Selects/Tags/types").ApiTag[]} List of tags
 */
export async function getTags(typeSection = "documentos") {
  try {
    const { data } = await axios.get(
      `${URL_BASE}todo/tag/usuario/${IDS_TYPE_SECTION[typeSection]}`,
      { withCredentials: true }
    );

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Create a task into the system
 * @param {import("structure/ToDo/Add/types").AddToDoI} dto - Information in order to create a to do
 * @returns {boolean} If true, was created, otherwise not, was an error
 */
export async function createToDo(dto) {
  try {
    await axios.post(
      `${URL_BASE}todo/agregar`,
      { ToDo: dto },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the social reason to know exactly which customer or contact belongs that reminder
 * @param {number} idEntity - Id of the entity (id social reason, id contact)
 * @param {import("structure/ToDo/types").TypeToDo} type - Type of todo to add
 */
export async function getSocialReasonEntity(idEntity, type) {
  const fetchCustomer = async () => {
    const customer = await GetCustomer(idEntity);
    return {
      id: customer.id,
      socialReason: customer.socialReason,
    };
  };

  const indexFetch = {
    documentos: fetchCustomer,
    directorio: fetchCustomer,
    contactos: async function () {
      const contact = await getInfoContact(idEntity);
      return {
        id: contact.id,
        socialReason: `${contact.lastName1} ${contact.lastName2}`,
      };
    },
  };

  try {
    const socialReason = await indexFetch[type]();
    return socialReason;
  } catch (error) {
    return undefined;
  }
}

/**
 * Obtain the to do of the user signed in. This will fetch ALL BUT IGNORING THE ENTITY
 * For instance, type documents will bring all the documents, no matter if is invoice, oc, etc or if belongs to a document with id 'x'
 * @param {import("structure/ToDo/types").TypeToDo} type - Type of todo's needed to fetch
 * @param {boolean} isUrgent - If true, will bring ALL BUT IGNORING THE ENTITY, for instace, will fetch all from documents, contacts, directory, etc...
 * @param {number?} id - Id of the entity to fetch, for example, the documents where invoice id = 381
 * @param {number} page - Page requested
 * @returns {import("../../../../types/todo").DtoToDo} - List of to dos
 */
export async function getMyTodos(
  type = "documentos",
  isUrgent = false,
  id = null,
  page = 1
) {
  const queryParamId = id === null ? "" : `&fromId=${id}`;

  try {
    const { data } = await axios.get(
      `${URL_BASE}todo/misToDos?tipo=${
        IDS_TYPE_SECTION[type] || 0
      }&isUrgent=${isUrgent}${queryParamId}&pageRequested=${page}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return {
      myTodos: [],
      page: {
        actualPage: 1,
        noRegisters: 0,
        pages: 1,
      },
    };
  }
}

/**
 * @param {import("structure/ToDo/types").TypeToDo} type - Type of todo's needed to fetch
 * @param {number} id - Id of the register to fetch
 * @param {boolean?} dones - If true, will bring all the tasks already done/checked
 * @param {fetchJustImportants?} fetchJustImportants - If true, will fetch only those ones who need to be attempt as soon as possible, ignoring those who are open or close
 * @param {number} page - Page requested
 * @returns {Promise<import("../../../../types/todo").DtoToDo>} - List of to dos
 */
export async function getMyTodosBySection(
  type = undefined,
  id = undefined,
  dones = false,
  fetchJustImportants = true,
  page = 1
) {
  const ERROR_DATA = {
    myTodos: [],
    page: {
      actualPage: 1,
      noRegisters: 0,
      pages: 1,
    },
  };

  if (type === undefined || id === undefined) {
    return ERROR_DATA;
  }

  try {
    const { data } = await axios.get(
      `${URL_BASE}todo/seccion/item?fromId=${id}&type=${IDS_TYPE_SECTION[type]}&isOpen=${dones}&isUrgent=${fetchJustImportants}&pageRequested=${page}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return ERROR_DATA;
  }
}

/**
 * Get the history of a todo
 * @param {string} uuid - UUID of the thread
 * @returns {import("customHooks/useToDo/types").ToDosI[]} - List of to dos
 */
export async function getTodoThread(uuid) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}todo/historial?parent=${uuid}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Update a task in order to mark it as done or undone
 * @param {number} id - ID of the ToDo
 * @param {"done"|"undone"} status - Estatus to set the task
 * @returns {boolean} Indicates if the query was success
 */
export async function updateStatusTodo(id, status = "done") {
  try {
    await axios.put(`${URL_BASE}todo/terminar/${id}`, null, {
      withCredentials: true,
    });

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Update the content of the todo
 * @param {import("customHooks/useToDo/types").ToDosI} todo - To do information
 * @returns {boolean} True if update was success
 */
export async function updateContentToDo(todo) {
  try {
    await axios.put(
      `${URL_BASE}todo/actualizar`,
      {
        ToDo: todo,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Information of the thread when creating the continuation of the todo
 * @param {string} id - Id of the thread todo
 * @returns {import("structure/ToDo/Add/types").OverviewThreadI} Information of the thread
 */
export async function threadOverview(id) {
  if (id === null)
    return {
      lastUpdateDate: null,
      reminderDate: null,
      section: {
        description: null,
        id: null,
      },
      tag: {
        description: null,
        id: null,
      },
      title: "",
    };

  try {
    const { data } = await axios.get(
      `${URL_BASE}todo/informacion-historial/${id}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return {
      lastUpdateDate: null,
      reminderDate: null,
      section: {
        description: null,
        id: null,
      },
      tag: {
        description: null,
        id: null,
      },
      title: "",
    };
  }
}

/**
 * Get the overview of the invoice requested in order to create it
 * @param {number} idInvoice - Id of the invoice
 * @returns {Promise<import("../../../../types/invoiceAuthorizations").DtoGetInvoiceAuthorizationsI>}
 */
export async function getAuthorizationInvoice(idInvoice) {
  try {
    /**
     * @type {{data:DtoGetInvoiceAuthorizationsI}}
     */
    const { data } = await axios.get(
      `${URL_BASE}documentos/obtener/autorizacion/${idInvoice}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    return error;
  }
}
