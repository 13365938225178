import { useContext } from "react";
import TableContext from "../context/Table/TableContext";

export const useReloadTable = () => {
  const { refetch, setReload } = useContext(TableContext);
  const reloadTable = () => {
    setReload(!refetch);
  };
  return [reloadTable];
};
