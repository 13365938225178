import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "../..";

/**
 * Render SKU input
 * @param {import("../types").DescriptionProps} props - Props
 * @returns {JSX.Element}
 */
export default function SKU(props) {
  const hook = useContext(ContextCatalogueAdmin);

  const reactHookForm =
    props.operation === "add" ? hook.formAdd : hook.formEdit;

  return (
    <div>
      <Label>SKU</Label>
      <input
        ref={reactHookForm.register}
        type="text"
        name="sku"
        placeholder="Escribe aquí"
        {...props}
      />
      <p className="text-danger font-weight-bold">
        {reactHookForm.errors?.sku?.message}
      </p>
    </div>
  );
}
