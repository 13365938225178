import Money from "components/general/Forms/Money";
import React, { Fragment, useContext } from "react";
import { Label } from "structure/Document";
import { ContextAddIncoming } from "..";
import Select from "components/individual/SelectV4";
import ui from "./styles.module.scss";
import { mnCurrency } from "helpers/money";

export default function Factoraje() {
  const hook = useContext(ContextAddIncoming);

  return (
    <div className={ui.factorajeContainer}>
      <div>
        <Label>¿Aplica factoraje?</Label>

        <Select
          options={async () => [
            {
              label: "Si",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          defaultSelected={{
            keyToCompare: "value",
            value: hook.state.applyFactoraje,
          }}
          onChange={(data) => hook.setApplyFactoraje(data.value)}
          labelToDisplay="label"
        />
      </div>

      {hook.state.applyFactoraje ? (
        <Fragment>
          <div>
            <Label>Factoraje</Label>
            <Money
              placeholder="Escribe aqui (Opcional)"
              defaultValue={hook.state.factoraje}
              maxDecimals={2}
              value={hook.state.factoraje}
              onChange={hook.setFactoraje}
            />
          </div>

          {hook.state.factoraje <= 0 ? (
            <p className="text-danger font-weight-bold">
              Un monto mayor a $0.00
            </p>
          ) : null}

          {hook.state.factoraje > 0 ? (
            <p className="font-weight-bold text-warning">
              ⚡ Ingreso al banco de{" "}
              {mnCurrency(hook.state.ammount - hook.state.factoraje)}
            </p>
          ) : null}

          {hook.state.factoraje > hook.state.ammount ? (
            <p className="font-weight-bold text-danger">
              El factoraje no puede ser mayor al importe
            </p>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}
