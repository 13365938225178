import ButtonV2 from "components/individual/ButtonsV2/Button";
import React, { Fragment, useContext } from "react";
import { ContextPaymentReminder } from "../..";
import Dialog from "structure/Dialog";
import ui from "./styles.module.scss";
import TagPaymentReminder, {
  Creatable,
  UpdateTagReminder,
} from "components/general/Forms/Selects/TagPaymentReminder";

export default function Tag() {
  const hook = useContext(ContextPaymentReminder);

  if (hook.reminderSelected === null || hook.reminderSelected === undefined)
    return <></>;

  return (
    <Fragment>
      <ButtonV2 onClick={() => hook.handleTagOpen(true)}>
        <span>Tag</span>
      </ButtonV2>

      {hook.isOpenTag ? (
        <TagPaymentReminder
          defaultTag={hook.reminderSelected.idTag}
          idInvoice={hook.reminderSelected.id}
          onUpdated={() => {
            hook.refetchReminders();
            hook.handleTagOpen(false);
          }}
        >
          <Dialog
            width={40}
            forwardRef={hook.dialogTag.refDialog}
            title={`Factura ${hook.reminderSelected.folio}`}
            closeCallback={() => hook.handleTagOpen(false)}
            footer={
              <div className="d-flex justify-content-end align-items-center">
                <UpdateTagReminder />
              </div>
            }
          >
            <div className={ui.moreInfoTag}>
              <Creatable isClearable={false} />
              <iframe src="https://scribehow.com/embed/Actualizar_tags__akBT_1ivQkyrjDBS_0K2QA" width="100%" height="640" allowFullScreen frameborder="0"></iframe>
            </div>
          </Dialog>
        </TagPaymentReminder>
      ) : null}
    </Fragment>
  );
}
