import styled from "styled-components";

export const ContainerStep1 = styled.div`
  display: flex;
  gap: 1rem 0;
  
  justify-content: space-evenly;

  > *:nth-child(2) {
    width: 80%;
  }

  @media (max-width: 767px) {
    flex-direction:column;
    gap:1rem;
    > *:nth-child(2) {
      width: 100%;
    }
  }
`;
