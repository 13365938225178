import React, { useContext } from "react";
import ui from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { ContextProyects } from "..";
import Icon from "components/individual/HeaderIcon";
import RedirectBtn from "components/individual/RedirectBtn";
import CancelPosition from "components/general/CancelPosition";
import GeneratePropusal from "./GeneratePropousal";

export default function OptionsProyects() {
  const hook = useContext(ContextProyects);

  if (hook.selectedProyect === undefined && hook.selectedPosition.length <= 0)
    return <div className={ui.options}></div>;

  return (
    <div className={ui.options}>
      {hook.selectedProyect !== undefined &&
      hook.selectedPosition.length <= 0 ? (
        <>
          <RedirectBtn
            link={`/proyectos/formulario/${hook.selectedProyect.id}#posicion`}
          >
            <span>Agregar posición</span>
            <Icon icon="plus" />
          </RedirectBtn>

          <RedirectBtn link={`/proyectos/oc/${hook.selectedProyect.id}`}>
            <span>OC</span>
          </RedirectBtn>

          <RedirectBtn
            link={`/proyectos/formulario/${hook.selectedProyect.id}`}
          >
            <span>Editar proyecto</span>
            <Icon icon="edit" />
          </RedirectBtn>

          <RedirectBtn link={`/proyectos/propuesta/${hook.selectedProyect.id}`}>
            <span>Propuesta</span>
            <Icon icon="specialProcess" />
          </RedirectBtn>
        </>
      ) : null}

      {hook.selectedPosition.length === 1 && (
        <>
          <ButtonV2
            onClick={hook.handleNoAssignPosition}
            disabled={hook.positionAssign === "loading"}
          >
            <span>No asignado</span>
          </ButtonV2>

          <RedirectBtn
            link={`/proyectos/remision/${hook.selectedPosition[0].id}`}
          >
            <span>Remisión</span>
          </RedirectBtn>

          <RedirectBtn
            link={`/proyectos/materiales/${hook.selectedPosition[0].id}`}
          >
            <span>Material</span>
            <Icon icon="gear" />
          </RedirectBtn>

          <CancelPosition
            idPosition={hook.selectedPosition[0].id}
            onCancelated={() => hook.refetchTable()}
          >
            <ButtonV2>
              <span>Cancelar posición</span>
            </ButtonV2>
          </CancelPosition>
        </>
      )}

      <GeneratePropusal />
    </div>
  );
}
