import styled from "styled-components";

export const ContainerSteps = styled.div`
  padding: 1.25rem;
  position: sticky;
  top: 0;
  z-index: 100;
  background:var(--secondaryBackground);

  @media (max-width: 767px) {
    display: none;
  }
`;
