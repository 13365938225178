import AdvanceCombo from "components/individual/AdvanceCombo";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import usePaymentAdvances, {
  INITIAL_STATE_CONTEXT_ADVANCE_PAYMENT,
} from "customHooks/usePaymentAdvances";
import React from "react";
import scss from "./styles.module.scss";
import { createContext } from "react";
import EditAdvance from "./Edit";
import usePermissions from "customHooks/usePermissions";
import Table from "./Table";

/**
 * @type {import("react").Context<import("customHooks/usePaymentAdvances/types").ContextUseAdvance>}
 */
export const ContextAdvancePayment = createContext(
  INITIAL_STATE_CONTEXT_ADVANCE_PAYMENT
);

/**
 * Render a screen to display the payment advances on the system
 * @param {import("./types").PaymentAdvancesCustomers} props - Props
 * @returns {JSX.Element}
 */
export default function PaymentAdvancesCustomers({ type = "clientes" }) {
  const hook = usePaymentAdvances(type);

  const idToEvaluate =
    type === "clientes"
      ? "fd727541-174e-415c-9e47-9ca8c428e3bf"
      : "eca375ee-6514-4296-bce1-7ad873b8d040";

  usePermissions(idToEvaluate);

  return (
    <ContextAdvancePayment.Provider value={{ ...hook, movementType: type }}>
      <div className={scss.containerAdvancesModule}>
        <div className={scss.topAdvance}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={scss.filter}>
            <AdvanceCombo
              type={type}
              onChange={(customer) => hook.updateFilter(customer.id)}
            />

            <EditAdvance />
          </div>
        </div>

        <Table />
      </div>
    </ContextAdvancePayment.Provider>
  );
}
