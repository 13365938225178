import CurrencySelectV2 from "components/general/Forms/Selects/CurrencySelectV2";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCatalogueAdmin } from "..";

export default function FilterCurrency() {
  const hook = useContext(ContextCatalogueAdmin);

  return (
    <div>
      <Label>Moneda</Label>
      <CurrencySelectV2
        criteriaToUse="code"
        displayAll={true}
        onChangeValue={(currency) => hook.setCurrency(currency)}
      />
    </div>
  );
}
