import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import React, { createContext } from "react";
import useContacts, { CONTEXT_CONTACTS } from "./useContacts";
import SearchContacts from "./Search";
import TableContacts from "./Table";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import OptionsContacts from "./OptionsContacts";
import MoreInfoCustomer from "./MoreInfoCustomer";
import style from "./styles.module.scss";

export const ContextContact = createContext(CONTEXT_CONTACTS);

export default function ContactsModule() {
  const { customerId } = useParams();

  const hook = useContacts(+customerId);

  return (
    <ContextContact.Provider value={{ ...hook, customerId: +customerId }}>
      <div className={style.containerContacts}>
        <div className={style.topContacts}>
          <BreadCrumV2 path={hook.breadcrum} />
          <div className="container">
            <MoreInfoCustomer />
            <SearchContacts />
          </div>
        </div>
        <div className="container">
          <OptionsContacts />
          <TableContacts />
        </div>
      </div>
    </ContextContact.Provider>
  );
}
