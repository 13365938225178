import { WinQuoteV3 } from "components/Sections/WinQuote/WinQuote";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import { GetCustomer } from "helpers/Apis/Directory";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ui from './styles.module.scss';

export const WinQuote = () => {
  const { customerId } = useParams();

  const [loadClient, setLoadClient] = useState(null);
  const [loadCustomer, setLoadCustomer] = useState(null);

  useEffect(() => {
    if(!!customerId){
      (async()=>{
        const customer = await GetCustomer(customerId);
        setLoadClient(customer);
      })()
    }
  }, [customerId])
  useEffect(() => {
    if(!!loadClient){
      setLoadCustomer(loadClient)
    }
  }, [loadClient])
  

  const pathBreadcrum = [
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/directorio",
      text: "Directorio",
    },
    {
      route: `/directorio/documentos/${customerId}`,
      text: "Documentos",
    },
    {
      route: "/",
      text: "Ganar cotización",
    },
  ];
  return (
    <div className={ui.moduleWin}>
      <BreadCrumV2 path={pathBreadcrum} />

      <WinQuoteV3
        customerId={customerId}
        customerInfo={loadClient}
        
      />
    </div>
  );
};
