import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { ContextUenAdmin } from "../..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";
import Icon from "components/individual/HeaderIcon";

export default function ButtonAdd() {
  const hook = useContext(ContextUenAdmin);

  if (hook.isAddingUen)
    return (
      <div className={style.containerAddUen}>
        <p></p>
        <Spinner text={<LoadingText>Agregando</LoadingText>} hidden={false} />
      </div>
    );

  return (
    <div className={style.containerAddUen}>
      <p className="text-danger">{!hook.isValidForm ? '🟥 Faltan campos por llenar' : null}</p>
      <ButtonV2 form={hook.idFormAdd} type="submit" disabled={!hook.isValidForm}>
        <span>Agregar</span>
        <Icon icon="save"/>
      </ButtonV2>
    </div>
  );
}
