import * as yup from "yup";

const id_movement = yup.number()
    .positive('Numero invalido')
    .required('Campo obligatorio')
    .typeError('Numero invalido');

const reference = yup.string()
    .nullable(true)
    .transform((casted,input)=>casted.length===0 ? null : input)

const check = yup.string()
    .nullable(true)
    .transform((casted,input)=>casted.length===0 ? null : input)

const concept = yup.string()
    .required('Campo obligatorio')

const registerDate = yup.string().required('Campo obligatorio')

const payMethods = yup.number()
    .required('Selecciona un metodo de pago')
    .positive('Opcion invalida')
    .transform((castedByLibrary, inputValue) => isNaN(castedByLibrary) ? null : +inputValue);

export const editEgress = yup.object().shape({
    id_movement,
    reference,
    check,
    concept,
    registerDate,
    payMethods
});