import React, { useContext, useEffect, useState } from "react";

// COMPONENTS
import Label from "./Label";
import OptionsAssociateFile from "./OptionsAssociateFile";
import BreadCrum from "./BreadCrum";
import UploadFile from "../../../../components/general/UploadFile/Index";
import Filter from "./Filter";


// LIBS
import { CustomTable } from "../../../../components/general/Table/CustomTable";
import { AssociateFilesConfig as FilesConfigTable } from "../../../../static/StaticTables/AssociateFiles";
import { DefaultTable } from "js-smart-table/dist/pagination";
import { useParams } from "react-router-dom";

// APIS
import { GetFiles } from "../../../../helpers/Apis/associateFiles";

// CONTEXT
import TableContext from "../../../../context/Table/TableContext";

// TYPES
import "../../../../types/typedef/associateFiles";

// HOOKS
import useAssociatedFiles from "../../../../customHooks/useAssociatedFiles";

function AssociateFiles() {
  const { refetch, setReload } = useContext(TableContext);
  const { notifyFilesWithComments , shortcutOpenComments } = useAssociatedFiles();

  document.title = 'Asociar archivos';

  let Table;
  let ConfigTable = { ...FilesConfigTable };
  const { idDocument } = useParams();

  /**
   * @type {[FileInfo[],Function]}
   */
  const [row, setRow] = useState(null);

  useEffect(() => {
    (async function () {
      setRow(null);

      const { actualPage, files, pages } = await GetFiles(1,"","",idDocument);

      ConfigTable = {
        ...ConfigTable,
        rows: files,
        pages,
        actualPage,
        cbSelection: () => setRow(Table.infoRow),
        paginationFn:async function(page,order,columnOrdering,aditionalQuery){
            const files = await GetFiles(page,order,columnOrdering,idDocument);
            notifyFilesWithComments(files.files);
            shortcutOpenComments(files.files,row)
            setRow(null);
            return files;
        }
      };

      Table = new DefaultTable(ConfigTable);
      Table.printTable();
      Table.printPagination();

      notifyFilesWithComments(files);
      shortcutOpenComments(files,row)
    })();
  }, [refetch]);

  const refreshTable = () => {
    setRow(null);
    setReload(!refetch);
  };

  return (
    <>
      <BreadCrum />

      <div className="container">
        <div className="d-flex justify-content-between my-3 align-items-center">
          <Label />

          <div className="d-flex align-items-end">
            <Filter />
            <UploadFile
              maxLongName = {50}
              onUploaded={refreshTable}
              path={`/Documentos/${idDocument}`}
              limitFile={5}
            />
          </div>
        </div>

        <CustomTable
          paginationID="pagination-list-files"
          tableID="list-files"
          key="list-files"
        />

        {row === null ? null : (
          <OptionsAssociateFile fileInfo={row} setRow={setRow} />
        )}
      </div>
    </>
  );
}

export default AssociateFiles;