import React from "react";
import { useContext } from "react";
import { Label } from "structure/Document";
import { ContextEmitCreditNote } from "..";

export default function Concept() {
  const hook = useContext(ContextEmitCreditNote);

  return (
    <div>
      <Label required={hook.concept.length <= 0}>Concepto</Label>
      {hook.invoice === undefined ? (
        <p>Primero selecciona una factura</p>
      ) : (
        <input
          type="text"
          onChange={(e) => hook.updateConcept(e.target.value)}
          placeholder="Escribe aquí, por ejemplo: Devolucion cargo extra servicio soporte"
        />
      )}
    </div>
  );
}
