import useDialog from "customHooks/useDialog";
import { searchClaveProductoServicio, searchUmSat } from "helpers/Apis/invoice";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUmSat}
 */
const INITIAL_STATE = {
  isLoading: true,
  search: "",
  results: [],
  itemSelected: undefined,
  isOpenDialog: false,
};

/**
 * Hook to handle sat code for items on documents
 * @param {string} [description=""] - Description for the default value
 * @param {string} [name=""] - Name for the default value
 * @returns {import("./types").ReturnUseSatUm}
 */
export default function useSatUm(description = "", name = "") {
  const [state, setState] = useState(INITIAL_STATE);

  const dialog = useDialog();

  useEffect(() => {
    // if (description.length <= 0 || name.length <= 0) return;

    setState((current) => ({
      ...current,
      itemSelected: { Name: description, Value: name, ShortName: description },
    }));
  }, [description, name]);

  useEffect(() => {
    if (state.isOpenDialog) dialog.open();
  }, [state.isOpenDialog]);

  const handleOpenModal = (isOpenDialog) =>
    setState((current) => ({
      ...current,
      isOpenDialog,
    }));

  const attemptSearch = async () => {
    setState((current) => ({
      ...current,
      isLoading: true,
      results: [],
    }));

    const umSat = await searchUmSat(state.search);

    // const satCodes = await searchClaveProductoServicio(state.search);

    setState((current) => ({
      ...current,
      results: umSat,
      isLoading: false,
    }));
  };

  const setSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const setOptionSelected = (itemSelected) =>
    setState((current) => ({
      ...current,
      itemSelected,
    }));

  return {
    ...state,
    attemptSearch,
    dialog,
    handleOpenModal,
    setOptionSelected,
    setSearch,
  };
}
