import NavigationItem from "components/general/Header/Mobile/NavigationItem";
import React, { useContext } from "react";
import { ChangeMenuView } from "structure/Menu/ChangeMenuView";
import RedirectButton from "structure/Menu/Redirect";
import TextIcon from "structure/Menu/TextIcon";
import ui from "./styles.module.scss";
import scss from "structure/Menu/index.module.scss";
import { MenuContext } from "structure/Menu";

/**
 * Render the menu item for the menu (mobile and desktop)
 * @param {import('./types').PropsMenuItem} props - Props
 * @returns {JSX.Element}
 */
export default function MenuItem({
  icon,
  link = null,
  menu = null,
  children = "",
  onClick = () => {},
  custom = null,
}) {
  const { closeMenu } = useContext(MenuContext);

  if (custom !== null)
    return (
      <>
        <div className={ui.mobile}></div>
      </>
    );

  return (
    <>
      <div className={ui.desktop}>
        {link !== null ? (
          <RedirectButton
            text={<TextIcon icon={icon} text={children} />}
            to={link}
          />
        ) : null}

        {menu !== null ? (
          <ChangeMenuView icon={icon} menu={menu} text={children} />
        ) : null}

        {link === null && menu === null ? (
          <p
            className={scss.item}
            onClick={() => {
              onClick();
              closeMenu();
            }}
          >
            <TextIcon icon={icon} text={children} />
          </p>
        ) : null}
      </div>

      <div className={ui.mobile}>
        <NavigationItem
          icon={icon}
          label={children}
          link={link}
          subMenu={menu}
          onClick={() => {
            closeMenu();
            onClick();
          }}
        />
      </div>
    </>
  );
}
