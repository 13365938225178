import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

// import { GetParameters } from "../../../../helpers/Apis/Documents";
import { GetParameters } from "helpers/Apis/Documents";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { dateToDbFormat, substractDays } from "helpers/dates";
import { addDays } from "date-fns";
registerLocale("es", es);

/**
 * Render reminder date input for the creation of a document
 * @param {object} props - Props
 * @param {Date} props.selected - Date that must be selected when the component renders (as example, the edition)
 * @param {(date:string)=>void} props.onLoad - Default reminder date when it's loads
 * @param {(date:string)=>void} props.onChange - Date selected
 * @param {Date} props.expiration - Expiration date selected
 * @param {string?} props.name - name attribute for the input
 * @param {object} props.forwardRef - react-hook-form ref
 * @param {boolean?} props.allowToday - If true, reminder can select the current date on the calendar
 * @param {boolean?} props.allowSameExpiration - If true, can select the same date that has the expiration (but not following dates, just the same as expiration)
 * @returns {JSX.Element} Reminder date input
 */
function ReminderDate({
  selected = null,
  onChange = () => {},
  onLoad = () => {},
  expiration = new Date(),
  name = "reminder",
  forwardRef = {},
  allowToday = false,
  allowSameExpiration = false
}) {
  const [date, setDate] = useState(selected);
  registerLocale("es", es);

  useEffect(() => {
    async function loadDefaultValue() {
      const { parameters } = await GetParameters();

      const { value } = parameters.find(
        (parameter) => parameter.parameter === 30
      );

      let defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() + +value);

      setDate(defaultDate);
      onLoad(defaultDate);
    }

    if (selected === null) {
      loadDefaultValue();
    }
  }, []);

  useEffect(() => {
    setDate(selected);
  }, [selected]);

  return (
    <>
      <DatePicker
        id={window.crypto.randomUUID()}
        selected={date}
        dateFormat="dd/MMM/yyyy"
        locale="es"
        minDate={!allowToday ? addDays(new Date(), 1) : new Date()} 
        maxDate={!allowSameExpiration ? substractDays(expiration, 1) : expiration}
        onChange={(date) => {
          setDate(date);
          onChange(date);
        }}
      />

      <input
        type="text"
        name={name}
        ref={forwardRef}
        hidden
        value={date === null ? "" : dateToDbFormat(date)}
      />
    </>
  );
}

export default ReminderDate;
