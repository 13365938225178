import React from "react";
import { useContext } from "react";
import { AssociateFilesContext } from "structure/AssociateFiles";
import AssociateFilesComments from "structure/AssociateFilesComments";

export default function CommentsFile() {
  const { infoRow } = useContext(AssociateFilesContext);

  if (infoRow === null) return <></>;

  return (
    <div>
      <AssociateFilesComments idFile={infoRow.idAssocaitedFile} />
    </div>
  );
}
