const { default: styled } = require("styled-components");

export const HeaderItemContainer = styled.div`
  width: 100%;
  display: flex;
  background: var(--cardBackground);
  align-items: flex start;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0;
  border: 1px solid var(--primaryColor);
  position: sticky;
  top: 0;
  border-style: none none solid none;

  @media (max-width: 767px) {
    width: 1500px;
  }
`;

export const HeaderShort = styled.div`
  width: 10%;
`;
export const HeaderShortKey = styled.div`
  width: 12%;
`;
export const HeaderLong = styled.div`
  width: 55%;
`;

export const ItemConteniner = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: var(--secondaryBackground);
  padding: 15px 20px;
  margin: 10px;
  border-radius: 15px;
`;

export const ItemsContainerOverFlow = styled.div`
  overflow-y: scroll;
`;

export const BodyShortKey = styled.div`
  width: 12%;
  display: flex;
  padding: 0.2rem;
  flex-direction: column;
  /* color: ${(props) => (props.className === "MXN" ? "green" : "blue")}; */
`;
export const BodyShort = styled.div`
  width: 10%;
  display: flex;
  padding: 0.2rem;
`;
