import { Modal } from "components/general/Modal";
import { Spinner } from "components/individual/Spinner/Spinner";
import useDisassociateCxc from "customHooks/useDisassociateCxc";
import React, { createContext } from "react";
import { DisassociateConcept } from "structure/DisassociateConcept";
import { DisassociateCxc } from "structure/DisassociateCxc";
import { DisassociateConcilationCxc } from "./DisassociateButton";

export const ContextModalDisassociateCxc = createContext();
const { Provider } = ContextModalDisassociateCxc;

/**
 * Modal in order to dis-associate the incoming from a movement
 * @param {import("./types").ModalDisassociateCxc} props - Props
 * @returns {JSX.Element}
 */
export const ModalDisassociateCxc = (props) => {
  const custHookUseDisassociateCxc = useDisassociateCxc(props.idMovement);

  if (custHookUseDisassociateCxc.state.isLoading)
    return <Spinner hidden={false} text="Cargando..." />;

  return (
    <Modal title="Des-asociar conciliaciones" width={90} {...props}>
      {({ closeModal }) =>
        custHookUseDisassociateCxc.state.movement.movementTypeNumber === 2 ? (
          <DisassociateConcept
            idMovement={props.idMovement}
            onDisassociated={props.onSuccess}
            bank={props.bankAccount}
          />
        ) : (
          <Provider
            value={{
              ...custHookUseDisassociateCxc,
              closeModal,
              onSuccess: props.onSuccess,
            }}
          >
            <DisassociateCxc
              idMovement={props.idMovement}
              onChange={custHookUseDisassociateCxc.updateConcilations}
              bank={props.bankAccount}
            />
            <DisassociateConcilationCxc />
          </Provider>
        )
      }
    </Modal>
  );
};
