import { BotonneraContainer } from "pages/Inicio/Modals/Styles";
import React from "react";

import DeleteFile from "./DeleteFile";

import CommentsFile from "./CommentsFile";
import DownloadFile from "./DownloadFile";

export default function AssociateFilesOptions() {
  return (
    <BotonneraContainer>
      <DownloadFile />

      <DeleteFile />

      <CommentsFile />
    </BotonneraContainer>
  );
}
