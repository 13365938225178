import React from "react";
import { DateContainers, DateLabel } from "../Containers";
import { InputDatePicker } from "components/Elements/InputDatePicker";
import { useContext } from "react";
import WinQuoteContext from "../../ContextWinQuote";

export const ContractDateSection = () => {
  const isDisable = true;
  const {
    documents,
    interfaceControl,
    handleContractInitialDate,
    handleContractEndDate,
    handleContractRemainderDate,
  } = useContext(WinQuoteContext);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate()+1); 
  return (
    <>
      <DateContainers>
        {interfaceControl.needContract ? (
          <>
            <DateLabel>Inicio</DateLabel>
            <InputDatePicker
              placeholderText="31/ene/2023"
              selectedDate={documents.contract.dates?.initialDate}
              handleDateChange={handleContractInitialDate}
              // minDate={new Date()}
              disable={isDisable}
            />
          </>
        ) : (
          <>
            <DateLabel disable={true}>Inicio</DateLabel>
            <input
              type="text"
              className="disable"
              placeholder="No contrato"
              // value={formateDate(new Date())}
              disabled
            />
          </>
        )}
      </DateContainers>

      <DateContainers>
        {interfaceControl.needContract ? (
          <>
            <DateLabel>Fin</DateLabel>
            <InputDatePicker
              
              placeholderText="31/ene/2023"
              selectedDate={documents.contract.dates?.endDate}
              handleDateChange={handleContractEndDate}
              minDate={documents.contract.dates?.initialDate}
              disable={isDisable}
            />
          </>
        ) : (
          <>
            <DateLabel disable={true}>Fin</DateLabel>

            <input
              type="text"
              className="disable"
              placeholder="No contrato"
              // value={formateDate(new Date())}
              disabled
            />
          </>
        )}
      </DateContainers>
      <DateContainers>
        {interfaceControl.needContract ? (
          <>
            <DateLabel>Recordatorio</DateLabel>

            <InputDatePicker
              placeholderText="31/ene/2023"
              selectedDate={documents.contract.dates?.reminderDate}
              handleDateChange={handleContractRemainderDate}
              minDate={tomorrow}
              maxDate={documents.contract.dates?.endDate}
              disable={isDisable}
            />
          </>
        ) : (
          <>
            <DateLabel disable={true}>Recordatorio</DateLabel>

            <input
              type="text"
              className="disable"
              placeholder="No contrato"
              // value={formateDate(new Date())}
              disabled
            />
          </>
        )}
      </DateContainers>
    </>
  );
};
