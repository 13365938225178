import {
  getInformativeExpenses,
  getInformativeIncomes,
} from "helpers/Apis/Banks";
import { truncateDecimals } from "helpers/money";
import { useEffect, useState } from "react";

/**
 * @type {import("./types").StateUseConcepts}
 */
const INITIAL_STATE = {
  isLoading: true,
  concepts: [],
  total: 0,
  associations: {},
  isValidNoIdentified: true,
  isValidAdvance: true,
  idNoIndentified: 0,
  idAdvance: 0,
};

/**
 * Hook to fetch the concepts of the system
 * @param {import("./types").ParamUseConcepts} param - Type of concepts
 * @returns {import("./types").ReturnUseConcepts} Return
 */
export default function useConcepts({
  type = "ingresos",
  decimals = 4,
  onChange = () => {},
  numberPartialitiesAssociated = 0,
  idCustomer = null
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    (async function () {
      const apiToFetch =
        type === "ingresos" ? getInformativeIncomes : getInformativeExpenses;

      const apiConcepts = await apiToFetch();

      setState((current) => ({
        ...current,
        isLoading: false,
        concepts: apiConcepts,
      }));
    })();
  }, []);

  useEffect(() => {
    const associations = Object.entries(state.associations).map(
      ([key, value]) => value
    );

    if (associations.length <= 0) {
      onChange({
        concepts: [],
        total: 0,
        isValid: true,
      });
      return;
    }

    const total = associations.reduce((total, concept) => {
      const newTotal = total + truncateDecimals(concept.ammount, decimals);

      return truncateDecimals(newTotal, decimals);
    }, 0);

    const dto = {
      total,
      concepts: associations,
      isValid: state.isValidAdvance && state.isValidNoIdentified,
    };

    onChange(dto);
  }, [state.associations, state.isValidNoIdentified, state.isValidAdvance]);

  useEffect(() => {
    const idNoIndentified = type === "egresos" ? 63 : 0;
    const idAdvance = type === "egresos" ? 62 : 0;

    setState((current) => ({
      ...current,
      idNoIndentified,
      idAdvance,
    }));
  }, [type]);

  useEffect(() => {


    const isValidNoIdentified = validateNoIdentified();
    const isValidAdvance = validateAdvance();

    setState((current) => ({
      ...current,
      isValidNoIdentified,
      isValidAdvance,
    }));

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Check that the user is not mixing "No identificado" with multiple concepts
     * @returns {boolean}
     */
    function validateNoIdentified() {


      // 1. Check the id that needs to be evaluated in order to check "No identificado"
      const idToEvaluate = state.idNoIndentified;

      // 2. Evaluate if the concept is on the list
      const isNoIdentifiedOnList = checkNoIndentifiedIsOnList();

      /**
       * Customer selected, this can't be possible
       */
      if(idCustomer!==null && isNoIdentifiedOnList) return false;



      /**
       * 3. Check if the user have associated more than one concept
       *
       * **Excluding**
       * 1. Compras extranjeras
       * 2. Compras nacionales
       * 3. Descuento compras
       *
       * @type {boolean}
       */
      const isMoreThanOneConcept = checkMoreThanOneConcept();

      // If is just one concept, OK
      if (!isMoreThanOneConcept) return true;

      // More than one concept and "No identificado is on list", NOT OK
      if (isNoIdentifiedOnList) return false;

      // Conditions above not fullfilled, OK
      return true;

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      function checkNoIndentifiedIsOnList() {
        const ids = Object.keys(state.associations);

        return ids.includes(`${idToEvaluate}`);
      }

      function checkMoreThanOneConcept() {
        const numberOfAssociatinos = Object.keys(state.associations).length + numberPartialitiesAssociated;

        return numberOfAssociatinos > 1 ? true : false;
      }
    }

    function validateAdvance() {


      // 1. Check which id is gonna be used to evaluate the concept
      const idToEvaluate = state.idAdvance;

      // 2. Check if the advance is on the current association
      const isAdvanceOnList = checkAdvanceOnList();

      // 3. Check if the user have tried to associate more than one concept on the grid
      const isMoreThanOneConcept = checkIsMoreThanOneConceptOnList();

      // No advance on grid, OK
      if (!isAdvanceOnList) return true;

      // Advance on grid AND more concepts on the grid, NOT OK
      if (isMoreThanOneConcept) return false;

      // Conditions above not fullfilled, OK
      return true;

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      function checkAdvanceOnList() {
        return Object.keys(state.associations).includes(`${idToEvaluate}`);
      }

      function checkIsMoreThanOneConceptOnList() {
        const numberOfConceptsOnList = Object.keys(state.associations).length + numberPartialitiesAssociated;
        return numberOfConceptsOnList > 1 ? true : false;
      }
    }
  }, [state.associations, state.idNoIndentified, state.idAdvance,idCustomer]);

  /**
   * Update the amount on the grid list
   * @param {import("./types").ConceptAssociation} concept - Concept information
   */
  function updateConceptAmount(concept) {
    if (concept.ammount > 0) {
      updateAmount();
      return;
    }

    deleteFromList();

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function deleteFromList() {
      const unrefAssociations = { ...state.associations };
      delete unrefAssociations[concept.id];

      setState((current) => ({
        ...current,
        associations: unrefAssociations,
      }));
    }

    function updateAmount() {
      setState((current) => ({
        ...current,
        associations: {
          ...current.associations,
          [concept.id]: concept,
        },
      }));
    }
  }

  // function calculate

  return {
    ...state,
    updateConceptAmount,
    incomingsFiltered: state.concepts.filter((concept) => concept.id !== 11),
  };
}
