import React, { useContext } from "react";
import { ContextCatalogueAdmin } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import Description from "../Form/Description";
import UnitCost from "../Form/UnitCost";
import UnitPrice from "../Form/UnitPrice";
import SubmitFormButton from "../SubmitFormButton";
import style from "../Form/styles.module.scss";
import SKU from "../Form/SKU";
import Currency from "../Form/Currency";
import UEN from "../Form/UEN";
import Icon from "components/individual/HeaderIcon";

export default function ModalAdd() {
  const hook = useContext(ContextCatalogueAdmin);

  return (
    <>
      <ButtonV2 onClick={hook.addDialog.open}>
        <span>Agregar</span>
        <Icon icon="plus"/>
      </ButtonV2>

      <Dialog
        width={35}
        title="Agregar producto o servicio"
        forwardRef={hook.addDialog.refDialog}
        closeCallback={hook.addDialog.close}
        footer={<SubmitFormButton type="add" />}
      >
        <form
          id={hook.idFormAdd}
          noValidate={true}
          className={style.formContainer}
          onSubmit={hook.formAdd.handleSubmit(hook.attemptCreateProduct, (e) =>
            console.log(e)
          )}
        >
          <Description operation="add" />
          <UnitCost operation="add" />
          <UnitPrice operation="add" />
          <SKU operation="add" />
          <Currency operation="add" />
          <UEN operation="add" />
        </form>
      </Dialog>
    </>
  );
}
