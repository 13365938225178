import React, { useContext } from "react";

/* CONTEXT */
import LoginContext from "../../../context/Login/LoginContext";

/* COMPONENTS */
import { InputUser } from "../../individual/inputs/Inputs";
import { Submit } from "../../individual/buttons/Buttons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

import { Spinner } from "../../individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "./styles.module.scss";

function ForgotPassword() {
  const { forgotPassword, changeView } = useContext(LoginContext);

  return (
    <div className="loginForm">
      <form
        className={style.formForgotPassword}
        onSubmit={(e) => forgotPassword(e, "inputRecoverPassword")}
      >
        {/* <div className="cursorHand" onClick={() => changeView("login")}>
          <p
            className="d-flex align-items-center 
              font-weight-bold text-center"
          >
            <FontAwesomeIcon
              key="forgotPasswordIcon"
              className="mr-2"
              size="2x"
              icon={faArrowAltCircleLeft}
            />
            Regresar
          </p>
        </div> */}

        <p className="my-4 text-center font-weight-bold">
          ¿Olvidaste tu contraseña?
        </p>

        <InputUser
          labelTxt="Ingresa tu usuario"
          placeholderTxt="Escribe aquí"
          aditionalClasses=""
          name="user"
          id="inputRecoverPassword"
        />

        <Spinner
          text={<LoadingText>Validando información</LoadingText>}
          idSpinner="spinnerRecoverPass"
        />

        <div className="mt-5 d-flex justify-content-center">
          <Submit
            text="Recuperar"
            id="btnRecover"
            aditionalClasses="text-center"
          />
        </div>

        <span onClick={() => changeView('login')} className="hyperlink text-center py-2 w-100 mt-4 d-block cursorHand">Iniciar sesión / Regresar</span>
      </form>
    </div>
  );
}

export default ForgotPassword;
