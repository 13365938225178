import React , { useContext } from "react";
import { ContextCashFlow } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import style from "../styles.module.scss";
import DetailsCxc from "./DetailsCxc";
import Residue from "./Residue";
import WarningIcon from "components/individual/WarningIcon";
import TableProjection from "./TableProyection";
import TableFlow from "./TableFlow";

export default function TableCashFlow() {
  const hook = useContext(ContextCashFlow);

  if (typeof hook.cashFlow.currency !== "string")
    return (
      <h1 className={style.error}>
        <WarningIcon /> Selecciona una moneda
      </h1>
    );

  if (hook.cashFlow.tc <= 0 && hook.cashFlow.currency === "Todos")
    return (
      <h1 className={style.error}>
        <WarningIcon /> Porfavor, escribe un TC mayor a $0.00
      </h1>
    );

  if (
    hook.cashFlow.currency === "Todos" &&
    hook.cashFlow.currencyReports === undefined
  )
    return (
      <h1 className={style.error}>
        <WarningIcon /> Selecciona la moneda para los reportes
      </h1>
    );

  if (hook.cashFlow.isLoading)
    return (
      <Spinner
        hidden={false}
        idSpinner="loadingCashFlowReport"
        text={<LoadingText>Cargando reporte</LoadingText>}
      />
    );

  if (hook.cashFlow.cashFlow === undefined) return <></>;

  return (
    <>
      <div className={style.contentTable}>
        {hook.type === "proyeccion" ? <TableProjection /> : <TableFlow/>}

        <DetailsCxc type="cxc" />
        <DetailsCxc type="cxp" />
        <Residue />
      </div>
    </>
  );
}
