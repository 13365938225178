import { Modal } from "components/general/Modal";
import React from "react";
import {
  BankAccount,
  SocialReason,
  BankAccountCuenta,
  BankCLABE,
  BankCurrency,
  BankDate,
  BankAmount,
  NextCheck,
  NextTransfer,
  BankCard,
  Description,
  SubmitAddBankAccount,
  Comment,
} from "structure/BankAccount";
import scss from "structure/AddBankAccount/styles.module.scss";
import { useState } from "react";

export default function Add() {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // To avoid create the context more levels above (lazy)
  // better click a hidden button to refetch the cards
  // Check the file: client\src\pages\BanksV2\index.js
  const handleClose = () => {
    close();

    const idButton = "triggerRefetchAccounts";
    const domButton = document.getElementById(idButton);

    if (domButton) domButton.click();
  };

  return (
    <>
      <button
        id="openAddBankAccount"
        onClick={open}
        className="d-none"
      ></button>

      <BankAccount id={null} onSuccess={handleClose}>
        {() => (
          <Modal
            isOpen={isOpen}
            footerV2={() => <SubmitAddBankAccount />}
            displayButton={false}
            onCloseModal={close}
            title="Agregar cuenta de banco"
          >
            {() => (
              <div className={scss.PositionForm}>
                <div className={scss.LefttSideModal}>
                  <SocialReason />
                  <BankAccountCuenta />
                  <BankCLABE />
                  <BankCurrency />
                  <BankDate />
                  <BankAmount />
                  <NextTransfer />
                  <NextCheck />
                </div>
                <div className={scss.RightSideModal}>
                  <BankCard />
                  <Description />
                  <Comment />
                </div>
              </div>
            )}
          </Modal>
        )}
      </BankAccount>
    </>
  );
}
