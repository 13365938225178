import React, { useContext } from "react";
import { ContextAddIncoming } from "..";
import PayMethodsSearchable from "components/general/Forms/Selects/PayMethodsSearchable";
import { Label } from "structure/Document";

export default function PayMethodIngress() {
  const hook = useContext(ContextAddIncoming);

  return hook.state.movement.info === null ? (
    <PayMethodsSearchable
      errorMessage={hook.state.payMethod === 99 ? " (Inválido) " : ""}
      onChange={(method) => hook.updatePayMethod(method.code)}
    />
  ) : (
    <div>
      <Label>Método de pago</Label>
      <p>{hook.state.movement.info.payMethod.description}</p>
    </div>
  );
}
