import { getCards } from "helpers/Apis/Banks/index";
import { useEffect } from "react";
import { useState } from "react";

/**
 * @type {import("./types").StateUseCards}
 */
const INITIAL_STATE = {
  infocards: [],
  refetch: false,
  isLoading: true,
  actives:[],
  inactives:[]
};

/**
 * 
 * @returns {import("./types").ReturnUseBanks}
 */
export const useBanks = () => {
  const [cards, setCards] = useState(INITIAL_STATE);

  useEffect(() => {
    getCards().then((apiCards) =>
      setCards((currentState) => ({
        ...currentState,
        infocards: apiCards,
        isLoading: false,
        actives:apiCards.filter(account=>account.isActive===true),
        inactives:apiCards.filter(account=>account.isActive===false)
      }))
    );
  }, [cards.refetch]);

  const triggerRefetch = () =>
    setCards((state) => ({
      ...state,
      refetch: !state.refetch,
    }));

  return {
    ...cards,
    triggerRefetch,
    breadcrum: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/bancos",
        text: "Bancos",
      },
    ],
  };
};
