import { getComboAdvance } from "helpers/Apis/Banks/advancePayments";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "structure/Document";

/**
 * Advance payment combo
 * @param {import("./types").PropsAdvanceCombo} props - Props
 * @returns {JSX.Element} Render a combo to display which customers has advance payments
 */
export default function AdvanceCombo({
  onChange = () => {},
  type = "clientes",
}) {
  const [options, setOptions] = useState([
    {
      label: "Todos",
      value: null,
    },
  ]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const optionsApi = await getComboAdvance(type);

      const parsedOptions = optionsApi.map((option) => ({
        value: option.id,
        label: option.socialReason,
        ...option,
      }));

      setOptions((current) => [...current, ...parsedOptions]);
      setIsLoading(false);
    })();
  }, []);

  const defaultValue = options[0];

  return (
    <div>
      <Label>{type === "clientes" ? "Clientes" : "Proveedores"}</Label>
      <Select
        classNamePrefix="saiko-select"
        className="saiko-select w-100"
        placeholder={`Selecciona`}
        defaultValue={defaultValue}
        isClearable={false}
        isSearchable={true}
        options={options}
        isLoading={isLoading}
        onChange={onChange}
      />
    </div>
  );
}
