/**
 * Create an indexed object if you have to manipulate,find or join
 * multiple data cross the system
 *
 * @param {Object[]} elements - JSON, the object only can have one level of depth
 * @param {string} indexProperty - Property name to use as index on the array
 * @returns {object} Indexed object
 */
export function createIndexedList(elements, indexProperty) {
  const indexed = elements.reduce(
    (actualValue, element) => ({
      ...actualValue,
      [element[indexProperty]]: element,
    }),
    {}
  );

  return indexed;
}

/**
 * Indexed list to array
 * @param {{[key:string]:any}} indexed - Object indexed that will be converted to array
 * @returns {any[]} Indexed to array
 */
export function indexedToArray(indexed) {
  const keys = Object.keys(indexed);

  let array = [];

  keys.forEach((key) => array.push(indexed[key]));

  return array;
}

/**
 * Parse an array without repeated items
 * @param {object[]} list - List with repeated items
 * @param {string} property - Property to use as id to avoid repeated elements
 * @returns {object[]}
 */
export function arrayUnrepeated(list = [], property) {
  if (list.length <= 0) {
    return [];
  }

  let listUnrepeated = {};

  list.forEach((item) => {
    listUnrepeated = {
      ...listUnrepeated,
      [item[property]]: item,
    };
  });

  const unrepeatedKeys = Object.keys(listUnrepeated);

  return unrepeatedKeys.map((key) => listUnrepeated[key]);
}

/**
 * Sets with javascripts
 * @param {Array<string|number>} array - Ids of the array
 * @param {Array<string|number>} comparitionElements - Ids of the elements to compare
 * @returns {Array<string|number>}
 * @example
    const originalArray = [1, 2, 3, 4, 5];
    const elementsToExclude = [2, 4];
    const result = getElementsNotInArray(originalArray, elementsToExclude);
    console.log(result); // Output: [1, 3, 5]
 */
export function getElementsNotInArray(array = [], comparitionElements = []) {
  const exclusionSet = new Set(comparitionElements);
  return array.filter((item) => !exclusionSet.has(item));
}

/**
 * Append/Unappend the item requested from an indexed list
 * @param {string|number} valueKey - Value to evaluate in order to check if the object is in the indexed list
 * @param {object} objectTarget - Object to be evaluated
 * @param {{[key:string|number]:any}} objectIndexed
 * @returns {[key:string|number]|any}
 */
export function toggleIndexedObject(valueKey, objectTarget, objectIndexed) {

  console.log({
    valueKey, objectTarget, objectIndexed
  })

  const isOnList = Object.keys(objectIndexed).includes(`${valueKey}`);

  // Item is not on list, append into the indexed object
  if (!isOnList) {
    return {
      ...objectIndexed,
      [valueKey]: objectTarget,
    };
  }

  // Item is on list, delete from indexed object
  let unrefObjectIndexed = {...objectIndexed};

  delete unrefObjectIndexed[valueKey];

  return unrefObjectIndexed;
}
