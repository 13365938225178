import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import usePermissions from "customHooks/usePermissions";
import React from "react";
import { useParams } from "react-router-dom";
import { AssociateFiles } from "structure/AssociateFiles";

export default function AssociateFilesAdministration() {
  const { nameDocument, idDocument } = useParams();

  const idToUse =
    nameDocument === "facturas-emitidas"
      ? "7d769fc2-aaee-4a22-a4e6-3b509a6ce4b6"
      : "dcf3e4a1-40df-4c3f-84bd-9de21c2b5699";

  usePermissions(idToUse);

  const nameToDisplay = nameDocument.replaceAll("-", " ");

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        padding: "0 0 1rem 0",
      }}
    >
      <BreadCrumV2
        path={[
          {
            route: "/inicio",
            text: "Inicio",
          },
          {
            route: `/administracion/${nameDocument}`,
            text: "Ventas",
          },
          {
            route: `/administracion/${nameDocument}`,
            text: nameToDisplay,
          },
        ]}
      />

      <div className="container">
        <AssociateFiles
          idRegister={+idDocument}
          idTypeEntity={1}
          pathUpload={`administracion/${nameDocument}/${nameDocument}-id-${idDocument}`}
        />
      </div>
    </div>
  );
}
