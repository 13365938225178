import ModalV2 from "components/individual/Modals/ModalV2";
import React, { createContext } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LimitTime from "./LimitTime";
import ReactQuill from "react-quill";
import useAttendInvoice from "customHooks/useAttendInvoice";
import PartialitiesApproved from "./Partialities";
import TC from "./TC";
import Buttons from "./Buttons";
import { Spinner } from "components/individual/Spinner/Spinner";
import CommentBox from "components/individual/CommentBox";
import Details from "components/general/Details";
import CustomerInfo from "structure/Customer";
import { Label } from "structure/Document";

/**
 * Context to attend an invoice
 * @type {import("react").Context<import("customHooks/useAttendInvoice/types").ContextAttendInvoice|undefined>}
 */
export const ContextAttendInvoice = createContext(undefined);
const { Provider } = ContextAttendInvoice;

/**
 * Modal in order to approve/reject an invoice authorization
 * @param {import("./types").AttendInvoiceProps} props - Props
 * @returns {JSX.Element}
 */
export default function AttendInvoice({ idInvoice, idOpenModal, idTodo }) {
  const {
    approved,
    isValid,
    message,
    repply,
    updateRepply,
    updatePartialitiesApproved,
    updateLimitTime,
    isLoading,
    openModal,
    closeModal,
    idCloseModal,
    attemptAuthorizacion,
    isPerfomingQuery,
  } = useAttendInvoice(idInvoice, idTodo);

  return (
    <Provider
      value={{
        isValid,
        approved,
        updatePartialitiesApproved,
        updateLimitTime,
        attemptAuthorizacion,
        repply,
      }}
    >
      <ModalV2
        title="Autorizacion de timbrado"
        showCancelButton={false}
        idCloseIcon={idCloseModal}
        idOpenModal={idOpenModal}
        cbOpen={openModal}
        cbReject={closeModal}
        footer={
          isLoading ? null : isPerfomingQuery ? (
            <Spinner hidden={false} text="Respondiendo autorizacion" />
          ) : (
            <div className="d-flex justify-content-between w-100 align-items-end">
              <div className="d-flex">
                <PartialitiesApproved />
                <TC />
                <LimitTime />
              </div>

              <Buttons />
            </div>
          )
        }
      >
        {isLoading ? (
          <Spinner hidden={false} text="Cargando pedido" />
        ) : (
          <>
            <Details summary="Detalles" open>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {message}
              </ReactMarkdown>
            </Details>



            <Label maxLength={255} currentLength={repply.length}>
              Mótivo
            </Label>

            <textarea
              maxLength={255}
              placeholder="Escribe aquí"
              value={repply}
              onChange={(e) => updateRepply(e.target.value)}
            ></textarea>
            {/* <CommentBox value={repply} onChange={updateRepply} /> */}
          </>
        )}
      </ModalV2>
    </Provider>
  );
}
