// @ts-check
import { Spinner } from "components/individual/Spinner/Spinner";
import { getCxps } from "helpers/Apis/Administracion/InvoiceReception/InvoiceReceptionApi";
import React, { useEffect, useState } from "react";
import scss from "pages/BanksV2/Incomings/Add/styles.module.scss";
import BankContext from "context/Bank/BankContext";
import { useContext } from "react";
import { GridCxC } from "components/general/GridCxC";
import { createContext } from "react";
import SearchCxp from "./Search";
import { ModalInvoiceReception } from "components/general/ModalInvoiceReception";

/**
 * @type {import("./types").StateCxp}
 */
const INITIAL_STATE = {
  cxp: [],
  isLoading: true,
  search: "",
};

const dummyFunction = () => {};

const STATE_CONTEXT = {
  ...INITIAL_STATE,
  updateSearch: dummyFunction,
};

export const ContextGridCxp = createContext(STATE_CONTEXT);

/**
 * **This must be used inside the provider of BankContext**
 * @param {import("./types").PropsCxp} props - Props
 * @returns {JSX.Element}
 */
export default function Cxp({
  idProvider = null,
  includeExpenses = false,
  amount = 0,
  onChange = () => {},
  payMethod,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  const bankAccount = useContext(BankContext);

  useEffect(() => {
    (async function () {
      if (amount === 0 || payMethod === null) {
        setState((current) => ({
          ...INITIAL_STATE,
          isLoading: false,
        }));
        return;
      }

      setState(INITIAL_STATE);

      const apiCxp = await getCxps(idProvider, includeExpenses);

      setState((current) => ({
        ...current,
        isLoading: false,
        cxp: apiCxp,
      }));
    })();
  }, [idProvider, includeExpenses, amount, payMethod]);

  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  if (state.isLoading)
    return (
      <Spinner hidden={false} idSpinner="cxp-loading" text="Cargando CxP" />
    );

  if (state.cxp.length <= 0)
    return (
      <ContextGridCxp.Provider value={{ ...state, updateSearch }}>
        <div className={scss.containerCxc}>
          <SearchCxp />
          <div className={scss.header}>
            <p>Folio</p>
            <p>Parcialidad</p>
            <div className={scss.doubleHeader}>
              <p>Saldo de la CxP</p>
              <div>
                <p>MXN</p>
                <p>USD</p>
              </div>
            </div>
            <div className={scss.doubleHeader}>
              <p>Importe a aplicar a la CxP</p>
              <div>
                <p>MXN</p>
                <p>USD</p>
              </div>
            </div>
            <div>Aplicar a egreso en {bankAccount.account.currency}</div>
            <div>TC</div>
          </div>
        </div>
      </ContextGridCxp.Provider>
    );

  return (
    <ContextGridCxp.Provider value={{ ...state, updateSearch }}>
      <div className={scss.containerCxc}>
        <SearchCxp />
        <div className={scss.header}>
          <p>Razon Social / Folio</p>
          <p>Parcialidad</p>
          <div className={scss.doubleHeader}>
            <p>Saldo de la CxP</p>
            <div>
              <p>MXN</p>
              <p>USD</p>
            </div>
          </div>
          <div className={scss.doubleHeader}>
            <p>Importe a aplicar a la CxP</p>
            <div>
              <p>MXN</p>
              <p>USD</p>
            </div>
          </div>
          <div>Aplicar a egreso en {bankAccount.account.currency}</div>
          <div>TC</div>
        </div>

        <GridCxC
          search={{
            keys: ["folio", "noDocument", "provider.socialReason", "uuid"],
          }}
          searchValue={state.search}
          availableBalance={amount}
          onChange={onChange}
          decimals={2}
          tolerance={0}
          currency={bankAccount.account.currency}
          refetch={idProvider}
          inyectionGrids={async () => state.cxp}
        >
          {({
            InputBalance,

            element,
            InputDifferentCurrency,
          }) => (
            <div className={scss.row}>
              <ModalInvoiceReception uuid={element.uuid}>
                {element.provider.socialReason} - {element.folio}
              </ModalInvoiceReception>

              <div className="d-flex align-items-center justify-content-center">
                {element.partialitie}
              </div>
              <div className={scss.doubleInfo}>
                {element.currency === "MXN" ? (
                  <p className="d-flex align-items-center justify-content-end">
                    {element.residue.text}
                  </p>
                ) : (
                  <p className="d-flex align-items-center justify-content-center">
                    -
                  </p>
                )}

                {element.currency !== "USD" ? (
                  <p className="d-flex align-items-center justify-content-center">
                    -
                  </p>
                ) : (
                  <p className="d-flex align-items-center justify-content-end">
                    {element.newResidue.text}
                  </p>
                )}
              </div>
              <div className={scss.doubleInfo}>
                {element.currency === "MXN" ? (
                  <p>
                    <InputBalance />
                  </p>
                ) : (
                  <p className="d-flex justify-content-center align-items-center">
                    -
                  </p>
                )}
                {element.currency !== "USD" ? (
                  <p className="d-flex justify-content-center align-items-center">
                    -
                  </p>
                ) : (
                  <p>
                    <InputBalance />
                  </p>
                )}
              </div>
              {element.currency === bankAccount.account.currency ? (
                element.applied.number > 0 ? (
                  <div className="d-flex justify-content-end align-items-center px-2">
                    {element.applied.text}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    -
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <InputDifferentCurrency />
                </div>
              )}
              {element.tc.number <= 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  -
                </div>
              ) : (
                <div className="d-flex justify-content-end align-items-center px-2">
                  {element.tc.text}
                </div>
              )}
            </div>
          )}
        </GridCxC>
      </div>
    </ContextGridCxp.Provider>
  );
}
