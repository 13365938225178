import * as yup from "yup";

// HELPERS
import { parseToFloat } from "../../../helpers/money";
import { trimDate } from '../../../helpers/dates';

const obligatorio = "Campo obligatorio";

////////////////////////////////// [ QUOTES ] /////////////////////////////////

//////////// [ ADD QUOTE ]

const cliente = yup.string()
    .required(obligatorio);

const provedor = yup.string()
    .required(obligatorio);

const contacto = yup.number()
    .integer()
    .nullable()
    .transform((inputValue) => inputValue === -1 ? null : inputValue);

const moneda = yup.number()
    .positive()
    .required(obligatorio);

const tipoCambio = yup.number()
    .positive('TCP negativo invalido')
    .min(0,'TCP invalido')
    .transform((inputValue,uiValue)=> {

        if(uiValue==='') return 0;

        return parseToFloat(uiValue)
    })
    .required(obligatorio);

const expirationDate = yup.string().required(obligatorio);

const reminderDate = yup.string()
    .required(obligatorio)
    .transform((inputValue,uiValue)=>{
        
        const date = trimDate(uiValue,'ymd','-');

        return `${date.year}-${date.month}-${date.day}`;
    });

const percentProb = yup.number()
    .integer()
    .positive('Seleccione un contacto valido');

const diasCredito = yup.number()
    .integer()
    .min(1, "Dias de credito invalido")
    .typeError('Debe ser un numero valido');


const addQuote = yup.object().shape({
    // cliente,
    contacto,
    moneda,
    tipoCambio,
    expirationDate,
    reminderDate,
    percentProb,
    diasCredito,
});

//////////// [ EDIT QUOTE ]

const percentAvance = yup.number()
    .integer()
    .positive('Porcentaje de avanze invalido')
    .typeError('Porcentaje de avanze invalido')

const editQuote = yup.object().shape({
    contacto,
    tipoCambio,
    expirationDate,
    reminderDate,
    percentAvance,
    percentProb,
    diasCredito
});


const schemaValidations = {
    'cotizacion':{
        'agregar':addQuote,
        'editar':editQuote
    }
}

export default schemaValidations;