import {
  cancelPreinvoice,
  getInvoicesPaginated,
  getReportInvoiceExcel,
} from "helpers/Apis/invoice";
import { useEffect, useReducer, useRef } from "react";
import { reducer, initialState } from "./reducer";
import { Actions } from "./actions";
import { dateToDbFormat, monthRange } from "helpers/dates";
import { saveAs } from "file-saver";
import { Warning, YesNoAlert } from "helpers/alerts";
import { useHistory } from "react-router-dom";
import useInvoiceSatPdf from "customHooks/useInvoiceSatPdf";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import useMemoryFilter from "customHooks/useMemoryFilter";

/**
 * Custom hook to handle the invoices to display
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} from - Indicates
 * @returns {import("./types").useAdministrationInvoiceValuesI}
 */
export default function useAdministrationInvoice(from) {

  function initialStatus() {
    if (from === "contabilidad") return -1;

    if (from === "pedidos") return 4;

    return 20;
  }

  // const memory = useMemoryFilter({
  //   status:initialStatus(),
  //   search:null,
  //   date:from !== "contabilidad"
  //   ? initialState.date
  //   : {
  //       end: monthRange().lastDay,
  //       start: monthRange().firstDay,
  //     },
  // });

  /**
   * Handle the state in order to display the invoices of the system
   * @type {import("./types").StateI}
   */
  const fomratInitialState = {
    ...initialState,
    // ...memory.retrieveData('admnInvoices')
    status: initialStatus(),
    date:
      from !== "contabilidad"
        ? initialState.date
        : {
            end: monthRange().lastDay,
            start: monthRange().firstDay,
          },
  };
  /**
   * Handle the state in order to display the invoices of the system
   * @type {[import("./types").StateI,(object:import("./types").Action)=>void]}
   */
  const [state, dispatch] = useReducer(reducer, fomratInitialState);

  const idModalRelatedDocs = useRef(window.crypto.randomUUID());

  const legalFiles = useInvoiceSatPdf({
    id: state.rowSelected?.id,
    pdf: state.rowSelected?.pdf,
    xml: state.rowSelected?.xml,
  });



  /**
   * Set the flag of downloading the pdf
   * @param {boolean} isDownloadingPdf - Flag
   * @returns {void}
   */
  const setDownloadingPdf = (isDownloadingPdf) =>
    dispatch({
      type: Actions.SET_DOWNLOAD_PDF,
      payload: isDownloadingPdf,
    });

  const history = useHistory();

  const addDocument = () => history.push(`/administracion/pedido/agregar?modulo=${from}`);

  const viewDocument = () =>
    history.push(
      `../directorio/documentos/${state.rowSelected.customer.id}/ver/${state.rowSelected.idPreinvoice}?modulo=${from}`
    );

  const redirectAssociatedFiles = () => {
    const url = from === "pedidos" ? "pedidos" : "facturas-emitidas";
    history.push(
      `../administracion/archivos-asociados/${url}/${state.rowSelected.idPreinvoice}`
    );
  };

  const editDocument = () =>
    state.rowSelected.status.id !== 4
      ? Warning(`No se puede editar el documento`)
      : history.push(
          `../documentos/pedido/editar?idDocumento=${state.rowSelected.id}&modulo=administracion&idCliente=${state.rowSelected.customer.id}`
        );

  const redirectToDo = () => {
    if (from === "pedidos") {
      history.push(
        `../administracion/pedidos/todo?documento=${state.rowSelected.id}&cliente=${state.rowSelected.customer.id}`
      );
      return;
    }

    if (from === "facturas_emitidas") {
      history.push(
        `../administracion/facturas-emitidas/todo?documento=${state.rowSelected.id}&cliente=${state.rowSelected.customer.id}`
      );
      return;
    }

    Warning(`En desarrollo`);
    return;
  };

  const downloadPdf = async () => {
    setDownloadingPdf(true);

    if (from === "facturas_emitidas") {
      await legalFiles.attemptDownload();
      setDownloadingPdf(false);
      return;
    }

    try {
      const pdf2 = await downloadPdfDocumentV3(
        state.rowSelected.idPreinvoice,
        "intranet"
      );

      saveAs(pdf2["0"], `Pedido ${state.rowSelected.numeroDocumento}.pdf`);
    } catch (error) {
    } finally {
      setDownloadingPdf(false);
    }
  };

  /**
   * Search the data with the current filters on the state
   * @returns {void}
   */
  const searchWithFilters = () =>
    dispatch({
      type: Actions.TRIGGER_SEARCH,
      payload: null,
    });

  /**
   * Set the flag for cancelling
   * @param {boolean} isCancelling - Flag
   * @returns {void}
   */
  const setIsCancelling = (isCancelling) =>
    dispatch({
      type: Actions.SET_IS_CANCELLING_INVOICE,
      payload: isCancelling,
    });

  const promptCancelPreinvoice = async () =>
    YesNoAlert("¿Estas seguro de cancelar el pedido?", attemptCancelPreinvoice);

  const attemptCancelPreinvoice = async () => {
    setIsCancelling(true);

    const wasCancelled = await cancelPreinvoice(state.rowSelected.id);

    setIsCancelling(true);

    if (wasCancelled) searchWithFilters();
  };

  useEffect(() => {
    if (state.rows.length <= 0) return;

    let hasChanges = false;

    Object.entries(state.accountingStatusGrid.operation).forEach(
      ([key, value]) => {
        if (state.accountingStatusGrid.original[key] !== value) {
          hasChanges = true;
        }
      }
    );

    dispatch({
      type: Actions.SET_CAN_UPDATE_ACCOUNTING,
      payload: hasChanges,
    });
  }, [state.accountingStatusGrid, state.rows]);

  /**
   * Set is fetching data to server
   * @param {boolean} isFetching - Indicates if the user is fetching the data to server
   * @returns {void}
   */
  const setIsFetchingData = (isFetching) =>
    dispatch({
      type: Actions.SET_IS_FETCHING_DATA,
      payload: isFetching,
    });

  /**
   * Update the status to filter the invoice
   * @param {number|null} status - Status
   * @returns {number}
   */
  const updateStatus = (status) => {
    const statusToUse = state.status === null ? initialStatus() : status;

    dispatch({
      type: Actions.SET_STATUS,
      payload: statusToUse,
    });

    return statusToUse;
  };

  /**
   * Update the row selected in order to show the options
   * @param {object|null} row - Information of the row selected
   * @returns {void}
   */
  const setRowSelected = (row) =>
    dispatch({
      type: Actions.SET_ROW,
      payload: row,
    });

  /**
   * Update the number of document to search on the invoices
   * @param {string|null} no - Number of document
   * @returns {void}
   */
  const updateNoDocument = (no) =>
    dispatch({
      type: Actions.SET_DOCUMENT,
      payload: no,
    });

  /**
   * Update the social reason to filter
   * @param {number|null} socialReason - Social reason selected
   * @returns {void}
   */
  const updateSocialReason = (socialReason) =>
    dispatch({
      type: Actions.SET_SOCIAL_REASON,
      payload: socialReason,
    });

  const attemptDownloadExcel = async () => {
    if (from === "pedidos") {
      Warning("Excel en desarrollo");
      return;
    }

    dispatch({
      type: Actions.SET_DOWNLOAD_XLSX,
      payload: true,
    });

    const excel = await getReportInvoiceExcel(from, {
      customer: state.customer,
      endDate: dateToDbFormat(state.date.end),
      page: state.page,
      search: state.search,
      startDate: dateToDbFormat(state.date.start),
      status: state.status,
    });

    if (excel instanceof Blob) {
      saveAs(
        excel,
        `${
          from === "facturas_emitidas" || from === "contabilidad"
            ? "Facturas emitidas"
            : "Pedidos"
        }.xlsx`
      );
    }

    dispatch({
      type: Actions.SET_DOWNLOAD_XLSX,
      payload: false,
    });
  };

  useEffect(() => {
    (async function () {
      setIsFetchingData(true);
      setRowSelected(null);
      const status = updateStatus(state.status);

      const { actualPage, documents, pages } = await getInvoicesPaginated(
        {
          page: state.page,
          status,
          customer: state.customer,
          search: state.search,
          startDate: dateToDbFormat(state.date.start),
          endDate: dateToDbFormat(state.date.end),
          accounting: state.accountingStatus,
        },
        from
      );
      dispatch({
        type: Actions.SET_FIRST_PAGE_DATA,
        payload: {
          customer: state.customer,
          endDate: state.date.end,
          startDate: state.date.start,
          search: state.search,
          totalPages: pages,
          rows: documents,
          page: actualPage,
        },
      });
    })();
  }, [state.refetch, state.page]);

  /**
   * Information of the additional option
   * @type {import("./types").AditionalOptionI}
   */
  const aditionalOptions = [
    {
      id: null,
      isActive: true,
      label: "Todos",
      socialReason: "Todos",
      value: null,
    },
  ];

  /**
   * Update the range dates
   * @param {[Date,Date]} dates - Range dates
   * @returns {void}
   */
  const updateRangeDates = (dates) =>
    dispatch({
      type: Actions.SET_DATES,
      payload: dates,
    });

  /**
   * Set the page for the pagination
   * @param {number} page - Number of page to fetch
   * @returns {void}
   */
  const setPage = (page) =>
    dispatch({
      type: Actions.SET_PAGE,
      payload: page,
    });

  /**
   * Set the status for the accounting
   * @param {boolean|null} accounting - Status for filter
   * @returns {void}
   */
  const setAccounting = (accounting) =>
    dispatch({
      type: Actions.SET_ACCOUNTING,
      payload: accounting,
    });

  /**
   * Update the month for the range dates
   * @param {number} month - Month to pick
   * @returns {void}
   */
  const setMonth = (month) =>
    dispatch({
      type: Actions.SET_MONTH,
      payload: month,
    });

  /**
   *
   * @param {number} id - Id of the invoice
   * @returns {void}
   */
  const toggleAccountingCheck = (id) =>
    dispatch({
      type: Actions.SET_ACCOUNTING_CHECK,
      payload: id,
    });

  const setYear = (year) =>
    dispatch({
      type: Actions.SET_YEAR,
      payload: year,
    });

    // useEffect(()=>{
    //   updateSocialReason(state.search);
    //   updateStatus(state.status);
    //   updateRangeDates([state.date.start,state.date.end]);

    //   memory.setMemoryData({
    //     status:state.status,
    //     date:{
    //       start:state.date.start,
    //       end:state.date.end
    //     },
    //     search:state.search
    //   },'admnInvoices')

    // },[state.search,state.status,state.date])

  return {
    rows: state.rows,
    setMonth,
    setAccounting,
    setYear,
    toggleAccountingCheck,
    isFetchingData: state.isFetchingData,
    updateStatus,
    updateNoDocument,
    aditionalOptions,
    redirectToDo,
    viewDocument,
    editDocument,
    redirectAssociatedFiles,
    setPage,
    socialReason: state.customer,
    startDate: state.date.start,
    endDate: state.date.end,
    updateRangeDates,
    updateSocialReason,
    searchWithFilters,
    recordsFounded: state.rows.length > 0 ? true : false,
    invoice: state.rowSelected,
    status: state.status,
    idModalRelatedDocs: idModalRelatedDocs.current,
    attemptDownloadExcel,
    state,
    setRowSelected,
    addDocument,
    downloadPdf,
    promptCancelPreinvoice,
  };
}
