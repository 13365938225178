import React from "react";
import { useContext } from "react";
import { ContextCreateEgress } from "..";
import { Label } from "structure/Document";
import { InputCurrency } from "components/individual/inputs/Inputs";
import { mnCurrency } from "helpers/money";
import Money from "components/general/Forms/Money";

export default function InputAmount() {
  const hook = useContext(ContextCreateEgress);

  // <InputCurrency
  //       aditionalClasses = "mb-4"
  //       onChange={(value) => hook.updateAmount(value)}
  //       defaultValue={hook.amount === 0 ? "" : hook.amount}
  //       placeholder={'Escribe aquí'}
  //       labelTxt=""

  return (
    <div>
      <Label className="justify-content-end mx-0" >
        Importe
      </Label>
      {hook.idMovement === null || hook.idMovement === undefined ? (
        <Money
          maxDecimals={2}
          onChange={hook.updateAmount}
          placeholder="Escribe aquí"
        />
      ) : (
        <p className="text-right">{mnCurrency(hook.amount)}</p>
      )}
    </div>
  );
}
