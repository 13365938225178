import React, { useEffect, useState } from "react";
import Checkbox from "components/individual/inputs/Checkbox";

/**
 * Handle the documents that should appear on the checkboxes
 * @param {object} props - Props
 * @param {number[]?} props.document - Ids of the documents that sholud be checked
 * @param {(object)=>void?} props.onChange - Ids of the documents checked
 * @returns {JSX.Element} Checkboxes of the documents that must be selected
 */
export default function CheckboxesDocuments({ document = [], onChange }) {
  const [selecteds, setSelecteds] = useState({});

  useEffect(() => {
    if (document.length === 0) return;

    const indexed = document.reduce(
      (indexed, id) => ({
        ...indexed,
        [id]: true,
      }),
      {}
    );

    setSelecteds(indexed);
  }, [document]);

  /**
   * Handle the toggle of one checkbox
   * @param {number} id - Id of the document selected
   * @param {boolean} isChecked - Check if the elements it's chcked
   */
  const handleToggle = (id, isChecked) => {
    let unreferenced = { ...selecteds };

    if (isChecked) {
      unreferenced = {
        ...unreferenced,
        [id]: true,
      };
    } else {
      delete unreferenced[id];
    }

    const ids = Object.keys(unreferenced).map((id) => +id);

    setSelecteds(unreferenced);
    onChange(ids);
  };

  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label: "Cotización",
    },
    {
      id: 3,
      label: "Orden de compra",
    },
    {
      id: 2,
      label: "Pedido",
    },
    {
      id: 6,
      label: "Contrato",
    },
  ]);

  return (
    <>
      {checkboxes.map((checkbox, i) => (
        <>
          <label
            className="d-inline-block cursorPointer"
            htmlFor={`document-${i}`}
          >
            {checkbox.label}
          </label>
          <Checkbox
            checked={selecteds[checkbox.id] ? true : false}
            className="mr-5"
            id={`document-${i}`}
            onClick={(e) => handleToggle(checkbox.id, e.target.checked)}
          />
        </>
      ))}
    </>
  );
}
