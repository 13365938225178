import axios from "axios";
import { URL_BASE } from "../../routes/routes";

/**
 * Fetch the list of partners that the executive can filter
 * 
 * @param {number} rol - Rol of the signed user on the system
 * @returns {Promise<import("./typesExecutives").ExecutiveI[]>} List of the users can filter by
 */
export async function executivesFilter(rol){

    try{
        const { data } = await axios.get(`${URL_BASE}roles/filtro-usuarios/rol/`,{
            withCredentials:true
        });

        return data;
    }
    catch(error){
        return []
    }
}