import PillFilterMobile from "components/individual/PillFilterMobile";
import React, { useContext } from "react";
import ui from "./styles.module.scss";
import { ContextOdc } from "..";
import { parseDateToText } from "helpers/dates";
import Icon from "components/individual/HeaderIcon";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";
import CustomerSocialReason from "../Filter/SocialReason";
import Status from "../Filter/Status";
import Dates from "../Filter/Dates";
import SearchOc from "../Filter/SearchOc";

const STATUS_LABEL = {
  10: "Activa",
  11: "Enviada",
  13: "Parcialmente recibida",
  17: "Recibida",
  12: "Cancelada",
};

export default function MobileFilter() {
  const { customerLabel, status, date, triggerSearch } = useContext(ContextOdc);

  const dialog = useDialog();

  return (
    <div className={ui.filter}>
      <div>
        <ButtonV2 onClick={dialog.open}>
          <Icon icon="filter" />
        </ButtonV2>
      </div>

      <Dialog
        title="Filtros ordenes de compra"
        forwardRef={dialog.refDialog}
        closeCallback={dialog.close}
        footer={
          <div className={ui.containerFooter}>
            <ButtonV2
              onClick={() => {
                triggerSearch();
                dialog.close();
              }}
            >
              <Icon icon="search" />
            </ButtonV2>
          </div>
        }
      >
        <div className={ui.contentFilter}>
          <CustomerSocialReason />
          <Status />
          <Dates />
          <SearchOc />
        </div>
      </Dialog>

      <PillFilterMobile
        label="Estatus"
        value={STATUS_LABEL[status] || "Todos"}
      />
      <PillFilterMobile label="Desde" value={parseDateToText(date.start)} />
      <PillFilterMobile label="Hasta" value={parseDateToText(date.end)} />
      <PillFilterMobile label="Razón social" value={customerLabel} />
    </div>
  );
}
