import React, { useContext } from "react";
import { ContextBankAccountReport } from "..";
import style from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";

export default function FooterBankAccountReport() {
  const hook = useContext(ContextBankAccountReport);

  if (hook.isLoading) return <></>;

  if (hook.account === null)
    return (
      <div className={style.error}>
        <p>Selecciona una cuenta </p>
      </div>
    );

  if (typeof hook.year !== "number")
    return (
      <div className={style.error}>
        <p>Selecciona un año </p>
      </div>
    );

  if (typeof hook.month !== "number")
    return (
      <div className={style.error}>
        <p>Selecciona un mes </p>
      </div>
    );

  // if (hook.periodDates.length <= 0)
  //   return (
  //     <div
  //       style={{ height: "100%" }}
  //       className="d-flex justify-content-center align-items-center"
  //     >
  //       <p className="m-0 text-danger font-weight-bold text-center">
  //         🔴 Para generar el estado de cuenta, primero debes de cerrar al menos
  //         un mes
  //       </p>
  //     </div>
  //   );

  return (
    <div className={style.bankReportFooter}>
      {hook.isDownloadingPdf ? (
        <Spinner
          text={<LoadingText>Descargando pdf</LoadingText>}
          hidden={false}
        />
      ) : (
        <ButtonV2 onClick={hook.handleDownloadPdf}>
          <span>PDF</span>
          <Icon icon="pdf" />
        </ButtonV2>
      )}

      {hook.isDownloadingXlsx ? (
        <Spinner
          text={<LoadingText>Descargando excel</LoadingText>}
          hidden={false}
        />
      ) : (
        <ButtonV2 onClick={hook.downloadExcelReport}>
          <span>Excel</span>
          <Icon icon="excel" />
        </ButtonV2>
      )}
    </div>
  );
}
