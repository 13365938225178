import { parseDateToText } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import style from "./styles.module.scss";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { IncomingContext } from "../Incomings";
import MoreInfoMovement from "structure/MoreInfoMovement";
import { useRef } from "react";

/**
 * Render the item to display for the mobile view
 * @param {import("../../../../../server/helpers/validations/bank/movements/types").BankAccountMovementI} movement - Props
 * @returns {JSX.Element}
 */
export default function MobileMovement(movement) {
  const hook = useContext(IncomingContext);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const moreInfoDom = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const cancelDom = useRef();

  const openMoreInfo = () => moreInfoDom.current.click();
  const openCancelMovement = () => cancelDom.current.click();

  return (
    <>
      <MoreInfoMovement
        id={movement.id}
        isForCancel={false}
        forwardRef={moreInfoDom}
        
      />

      <MoreInfoMovement
        id={movement.id}
        isForCancel={true}
        forwardRef={cancelDom}
        onCancelated={hook.refetchData}
      />

      <div className={style.mobileMovement}>
        <div className="d-flex justify-content-between aling-items-center">
          <div>
            <Label>{hook.type==="ingreso" ? "Cliente" : "Proveedor"}</Label>
            <p>{movement.customer.socialReason || "NA"}</p>
          </div>

          <ElipsisMobile onShow={() => hook.selectMovement(movement)}>
            <CustomMenuItem onClick={openMoreInfo}>
              Más información
            </CustomMenuItem>

            {hook.canCancel ? (
              <CustomMenuItem onClick={openCancelMovement}>
                Cancelar
              </CustomMenuItem>
            ) : null}

            {hook.canAssociateFiles ? (
              <CustomMenuItem onClick={hook.redirectToAssociatedFiles}>
                Archivos asociados
              </CustomMenuItem>
            ) : null}

            {hook.canEdit ? (
              <CustomMenuItem onClick={hook.redirectToEdit}>
                Editar
              </CustomMenuItem>
            ) : null}
          </ElipsisMobile>
        </div>

        <div className={style.dateAndStatus}>
          <div>
            <Label>Fecha</Label>
            <p>{parseDateToText(`${movement.date}:`)}</p>
          </div>

          <div>
            <Label>Estatus</Label>
            <p>{movement.status.description}</p>
          </div>
        </div>

        <Label>Importe</Label>
        <p>{mnCurrency(movement.import)}</p>
      </div>
    </>
  );
}
