import Icon from "components/individual/HeaderIcon";
import React, { useContext } from "react";
import { MenuContext } from "..";
import TextIcon from "../TextIcon";
import scss from "../index.module.scss";
import SendEmail from "structure/SendEmail";

export const Tools = () => {
  const { setMenuToDisplay, openEmailModal, openTcpModal } =
    useContext(MenuContext);

  return (
    <>
      <p className={scss.item} onClick={() => setMenuToDisplay("general")}>
        <b>
          <TextIcon icon="back" text="Inicio > Herramientas" />
        </b>
      </p>
      <SendEmail>
        <p className={scss.item}>
          <TextIcon icon="email" text="Enviar correo" />
        </p>
      </SendEmail>

      <p className={scss.item} onClick={openTcpModal}>
        <TextIcon icon="chartBar" text="TC" />
      </p>

      <p className={scss.item} onClick={() => setMenuToDisplay("helpDesk")}>
        <TextIcon icon="support" text="Help Desk" />
        <Icon icon="right" />
      </p>

      <p className={scss.item} onClick={() => setMenuToDisplay("datosExcel")}>
        <TextIcon icon="excel" text="Carga de datos" />
        <Icon icon="right" />
      </p>
    </>
  );
};
