import { addDays } from "date-fns";
import { GetTCV2 } from "helpers/Apis/Banks";
import { GetParameters } from "helpers/Apis/Documents";
import { dateToDbFormat, substractDays } from "helpers/dates";

/**
 * Check if the user clicked that document from the select previously,
 * in order the avoid re-fetch the API. Instead, use the data that it's on memory
 *
 * @param {object} status - Object with the status fetched previously
 * @param {number} toFind - ID of the document that the user clicked
 * @returns {boolean} True if the user fetched previously that document on the select
 */
export function isInMemory(status, toFind) {
  // GET AN ARRAY WITH THE ID OF THE DOCUMENTS FETCHED PREVIOUSLY
  const fetchedDocuments = Object.keys(status);

  // FIND THE DOCUMENT USER IS LOOKING FOR
  const search = fetchedDocuments.find((document) => +document === toFind);

  return search === undefined ? false : true;
}

/**
 * Get the "basic" information when user create any kind of document into the system
 * @returns {{
 *  expiration:{
 *    date:Date,
 *     db:string
 *  },
 *  reminder:{
 *    date:Date,
 *    db:string
 *  },
 *  tc:number
 * }} Information by default that all documents have when they're created
 */
export async function getDefaultValuesDocument() {
  try {
    // Fill the data with the default parameters to add a new quote
    const [{ enterprise }] = await GetTCV2();

    const { parameters } = await GetParameters();

    const { value: expirationDays } = parameters.find(
      (parameter) => parameter.parameter === 1
    );

    const { value: reminderDays } = parameters.find(
      (parameter) => parameter.parameter === 30
    );

    const expirationDate = addDays(new Date(), +expirationDays);
    const reminderDate = substractDays(expirationDate, reminderDays);

    const dbExpiration = dateToDbFormat(expirationDate);
    const dbReminder = dateToDbFormat(reminderDate);

    return {
      expiration: {
        date: expirationDate,
        db: dbExpiration,
      },
      reminder: {
        date: reminderDate,
        db: dbReminder,
      },
      tc: enterprise.number,
    };
  } catch (error) {}
}
