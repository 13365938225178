import axios from "axios";
import { Error, Success } from "helpers/alerts";
import { URL_BASE } from "routes/routes";
import { isValidHttpResCode } from "../fetch";

/**
 * Add an advertisement into the system
 * @param {import("./types").DtoAddAdvertisement} dto - Information in order to add an advertisement
 * @returns {Promise<boolean>} True if was added correctly
 */
export async function addAdvertisement(dto) {
  try {
    await axios.post(
      `${URL_BASE}avisos`,
      {
        advertisement: dto,
      },
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the advertisements of the system
 * @param {number} page - Page requested for the search
 * @param {string} [search=""] Search requested for the advertisements
 * @returns {Promise<import("./types").ResponseAdvertisement>}
 */
export async function getAdvertisements(page, search) {
  /**
   * @type {import("./types").ResponseAdvertisement}
   */
  const dummyResponse = {
    advertisements: [],
    page: 1,
    pages: 0,
  };

  const searchQueryParam = search === "" ? '' : `&busqueda=${search}`;

  try {
    const response = await fetch(`${URL_BASE}avisos?pagina=${page}${searchQueryParam}`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if(response.ok && isValidHttpResCode(response.status)){
      /**
       * @type {import("./types").ResponseAdvertisement}
       */
      const advertisements = await response.json();

      return advertisements;
    }

    return dummyResponse;
  } catch (error) {
    return dummyResponse;
  }
}

/**
 * Get the advertisements of the system
 * @param {number} id - Id of the record
 * @returns {Promise<import("./types").AdvertisementByID|undefined>}
 */
export async function getAdvertisementById(id) {

  try {
    const response = await fetch(`${URL_BASE}avisos/${id}`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if(response.ok && isValidHttpResCode(response.status)){
      /**
       * @type {import("./types").AdvertisementByID}
       */
      const advertisements = await response.json();

      return advertisements;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}
