import React, { useContext } from "react";
import { RangeDate, From, To } from "components/general/RangeDate";
import { ContextResidueAndMovements } from "..";

export default function Dates() {
  const hook = useContext(ContextResidueAndMovements);

  return (
    <RangeDate>
      <From
        selected={hook.from}
        required={!hook.isValidFrom}
        onChange={(date) => hook.setRange(date, hook.to)}
      />
      <To
        selected={hook.to}
        required={!hook.isValidTo}
        maxDate={new Date()}
        onChange={(date) => hook.setRange(hook.from, date)}
      />
    </RangeDate>
  );
}
