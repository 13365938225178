import axios from "axios";
import { URL_BASE } from "../routes/routes";
import { Error } from "./alerts";
import "../types/typedef/logs";
import { prettyPrintJson } from "pretty-print-json";

const idDom = "logEmailContainer";
const idBodyLog = "bodyLog";

/**
 * Get the html string to be sended throught the email
 * @param {object} objectData - Object to convert into pretty json on ui
 * @returns {string} HTML string created of pretty JSON to send throught email
 */
export function createPrettyJsonHtml(objectData) {
  const elementDom = document.getElementById(idDom);
  elementDom.innerHTML = prettyPrintJson.toHtml(objectData);
  const stringHtmlGenerated = document.getElementById(idDom).innerHTML;

  const bodyLog = document.getElementById(idBodyLog).innerHTML;

  return bodyLog;
}

export async function handleCreateInvoiceStepTwo(errorInfo) {
  const isWifiConnection = window.navigator.onLine;

  if (!isWifiConnection) {
    saveErrorOnMemory(errorInfo);

    Error(() => {},
    "Error A1. Factura creada pero no fue posible sincronizar la informacion, paso 2/2 fallido. Po Contacte a soporte tecnico");

    return;
  } else {
    await createLog({
      ...errorInfo,
      htmlError: createPrettyJsonHtml(errorInfo),
    });

    Error(() => {},
    "Error A1. Factura creada pero no fue posible sincronizar la informacion, paso 2/2 fallido. Contacte a soporte tecnico");
  }

  return;
}

/**
 * Insert a log register on the system
 * @param {LogInsertion} logInfo - Information of the log to insert
 * @returns {void|ErrorInformation}
 */
export async function createLog(logInfo) {
  try {
    await axios.post(`${URL_BASE}logs`, logInfo);
  } catch (error) {
    Error(() => {}, `Error: ${error.codeError}. ${error.message}`);

    throw {
      message: error.message,
      codeError: error.codeError,
      errorDetails: error.errorDetails,
    };
  }
}

/**
 * Save the log error on the machine of the user
 * @param {object} errorLog - Information of the log to insert
 */
export function saveErrorOnMemory(errorLog) {
  const localStorageErrors = localStorage.getItem("logErrors");

  if (localStorageErrors === null) {
    const errors = {
      errors: [errorLog],
    };

    localStorage.setItem("logErrors", JSON.stringify(errors));
  } else {
    const parsedLocalStorage = JSON.parse(localStorageErrors);

    const listOfErrors = Object.keys(parsedLocalStorage["errors"]);

    const newErrors = listOfErrors.map(
      (error, i) => parsedLocalStorage["errors"][i]
    );

    newErrors.push(errorLog);

    localStorage.setItem(
      "logErrors",
      JSON.stringify({
        errors: newErrors,
      })
    );
  }
}

/**
 * Download the log error on the computer of the user for the technical support
 * @param {object} objectValue - The object (json) will be downloaded on the computer of the user
 */
export function saveLogError(objectValue) {}

export function createPrettyLogJson() {}

/**
 * Parse the error
 * @param {Response} res - Response
 * @returns {Promise<import("./apisTypes").ErrorI>}
 */
export async function getErrorData(res){
  return await res.json();
}