import styled from "styled-components";

export const FilterContainer = styled.form`
  display: flex;
  align-items: end;
  gap: 0 1rem;

  > *:nth-child(1) {
    width: 20%;
  }

  @media (max-width: 767px) {
    > *:nth-child(1) {
      width: 70%;
    }

    > button {
      padding: 0 1rem;
    }

    > *:nth-child(2),
    > *:nth-child(7) {
      display: none;
    }

    > *:nth-child(4) {
      display: block;
    }
  }
`;

export const NonFiscalContainerGrid = styled.div`
  display: grid;
  color:var(--primaryColor);
  grid-template-columns: repeat(5, 19%);
  justify-content: space-between;
  grid-template-areas:
    "emitedDate emitedDate  socialReason socialReason socialReason"
    "folio folio folio folio folio"
    "emitter emitter . receptor receptor"
    "currency currency total total . "
    "creditDays creditDays expirationDate expirationDate expirationDate";

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap:0.5rem 0;
    }
`;

export const ContainerXMLInfo = styled.div`
  width: 100%;
  display: grid;
  color:var(--primaryColor);
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: 1fr;
  justify-content: space-between;
  padding: 15px;
  grid-template-areas:
    "emitedDate emitedDate socialReason socialReason socialReason"
    "folio uuid uuid uuid uuid"
    "emitter emitter receptor receptor currency"
    "lblAmounts lblAmounts lblAmounts lblAmounts lblAmounts"
    "importe discount impuestos  impuestos total"
    "creditDays . expirationDate expirationDate .";

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap:0.5rem 0;
  }
`;

export const LblImporte = styled.div`
  grid-area: lblAmounts;
  p {
    margin: 0;
  }
  display: flex;
  align-items: center;
  div:nth-child(1),
  div:nth-child(2),
  div:nth-child(4) {
    width: 20%;
  }
  div:nth-child(3) {
    width: 40%;
  }
`;

export const EmittedDate = styled.div`
  grid-area: emitedDate;
  margin: 3px;
`;

export const ImpuestosTrasladados = styled.div`
  grid-area: trasladados;
  margin: 3px;
`;

export const Discount = styled.div`
  grid-area: discount;
  margin: 3px;
`;

export const SocialReason = styled.div`
  grid-area: socialReason;
  margin: 3px;
`;

export const Folio = styled.div`
  grid-area: folio;
  margin: 3px;
`;

export const UUID = styled.div`
  grid-area: uuid;
  margin: 3px;
`;

export const Emitter = styled.div`
  grid-area: emitter;
  margin: 3px;
`;

export const Receptor = styled.div`
  grid-area: receptor;
  margin: 3px;
`;

// ----------------------------------
export const Currency = styled.div`
  grid-area: currency;
  margin: 3px;
`;
export const Importe = styled.div`
  grid-area: importe;
  margin: 3px;
`;

export const Impuestos = styled.div`
  grid-area: impuestos;
  margin: 3px;
  p {
    margin: 0;
    text-align: center;
    font-weight: bold;
  }
`;

export const Iva = styled.div`
  grid-area: retenidos;
  margin: 3px;
`;
export const Total = styled.div`
  grid-area: total;
  margin: 3px;
`;
export const CreditDays = styled.div`
  grid-area: creditDays;
  margin: 3px;
`;
export const ExpirationDate = styled.div`
  grid-area: expirationDate;
  margin: 3px;
`;
