import React, { useContext } from "react";
import { FormContext } from "./Form";
import { v4 as uuid } from "uuid";
import { Informative } from "../../Inputs/General/Index";
import { IdArea } from "./Styles";

export default function Id({ value = null, className = "" }) {
  /**
   * @type {{
   *    document:import("types/typedef/documents").DocumentI
   * }}
   */
  const { document } = useContext(FormContext);

  return (
    <>
      {className !== "" ? (
        <div>
          <Informative
            readonly={true}
            label="ID"
            css={className}
            value={!value ? `${document.id}` : value}
          />
        </div>
      ) : (
        <IdArea>
          <Informative
            readonly={true}
            label="ID"
            value={!value ? `${document.id}` : value}
          />
        </IdArea>
      )}
    </>
  );
}
