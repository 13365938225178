import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faBell,
  faAngleRight,
  faAddressBook,
  faWallet,
  faDollarSign,
  faCog,
  faSignOutAlt,
  faRandom,
  faUserShield,
  faHome,
  faArrowAltCircleLeft,
  faNewspaper,
  faUserTag,
  faUsers,
  faSearchDollar,
  faFileSignature,
  faFileInvoice,
  faFileInvoiceDollar,
  faFile,
  faCommentDollar,
  faPen,
  faSave,
  faTimes,
  faCheckCircle,
  faImage,
  faEye,
  faHistory,
  faCalendar,
  faInfoCircle,
  faEnvelope,
  faTools,
  faChartBar,
  faBug,
  faTicketAlt,
  faHeadset,
  faFileExcel,
  faCommentAlt,
  faSmile,
  faBullhorn,
  faSearch,
  faPlus,
  faTrash,
  faDownload,
  faPaperPlane,
  faFilter,
  faPaperclip,
  faFilePdf,
  faCloud,
  faPowerOff,
  faRedoAlt,
  faBuilding
  
} from "@fortawesome/free-solid-svg-icons";
import React from "react";


const LIST_ICONS = {
  user: faUserCircle,
  bell: faBell,
  menu: faBars,
  right: faAngleRight,
  directory: faAddressBook,
  banks: faWallet,
  sells: faDollarSign,
  gear: faCog,
  signtOut: faSignOutAlt,
  specialProcess: faRandom,
  administration: faUserShield,
  home: faHome,
  back: faArrowAltCircleLeft,
  news: faNewspaper,
  rols: faUserTag,
  users: faUsers,
  administrationMoney: faSearchDollar,
  contract: faFileSignature,
  reports: faFileInvoice,
  historyAmount: faFileInvoiceDollar,
  document: faFile,
  noteCreditEmitted: faCommentDollar,
  edit: faPen,
  save: faSave,
  cancel: faTimes,
  image: faImage,
  check: faCheckCircle,
  eye: faEye,
  history: faHistory,
  calendar: faCalendar,
  info: faInfoCircle,
  email: faEnvelope,
  tools: faTools,
  chartBar: faChartBar,
  bug: faBug,
  ticket: faTicketAlt,
  support: faHeadset,
  excel: faFileExcel,
  userTag: faUserTag,
  smile: faSmile,
  comments: faCommentAlt,
  megaphone:faBullhorn,
  search:faSearch,
  plus:faPlus,
  trash:faTrash,
  download:faDownload,
  send:faPaperPlane,
  filter:faFilter,
  clip:faPaperclip,
  pdf:faFilePdf,
  cloud:faCloud,
  powerOff:faPowerOff,
  redo:faRedoAlt,
  building:faBuilding
};

/**
 * Render icon for the menu of the header
 * @param {import("./types").PropsI} props - Props
 * @returns {JSX.Element}
 */
export default function Icon(props) {
  return <FontAwesomeIcon {...props} icon={LIST_ICONS[props.icon]} />;
}
