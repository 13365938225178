import React, { useContext } from "react";
import { useParams } from "react-router";
import {
  Button,
  RedirectButton,
} from "../../../components/individual/buttons/Buttons";
import { Warning } from "../../../helpers/alerts";
import Associate from "./IncomingOptions/Associate";
import { ModalDisassociateCxc } from "./ModalDisassociateCxc";
import TableContext from "context/Table/TableContext";
import { MovementContext } from "./Movements";
import { Conciliate } from "./Incoming/Conciliate";
import { CancelMovement } from "./CancelMovement";
import useMovements from "customHooks/useMovements";
import usePermissions from "customHooks/usePermissions";

/**
 * Options for the movements
 * @param {object} props - Props
 * @param {import("types/typedef/customHooks/useMovements").RowInfoI} props.movementInfo - Information of the row selected
 * @returns {JSX.Element} React component
 */
export default function Options({ movementInfo }) {
  const { account } = useParams();

  const { refetch, setReload } = useContext(TableContext);
  const { emptyInfoRow } = useContext(MovementContext);

  const refetchData = () => {
    setReload(!refetch);
    emptyInfoRow();
  };

  const { checkPermission } = usePermissions();

  return (
    <div className="d-flex">
      {checkPermission("2f9beeab-d15f-476a-8fe6-4d1756e66f73") ? (
        <Button
          aditionalClasses="mr-4"
          id="pdf"
          text="PDF"
          funcion={() =>
            Warning("De momento esta operacion esta en desarrollo")
          }
        />
      ) : null}

      {movementInfo.statusValue === 3 ? null : checkPermission(
          "6d07acb5-a9fb-40d3-92f0-8223e1a3d163"
        ) ? (
        <Associate movement={movementInfo} />
      ) : null}

      {movementInfo.statusValue !== 5 && movementInfo.statusValue !== 1 ? (
        <ModalDisassociateCxc
          bankAccount={account}
          idMovement={movementInfo.Movimiento}
          propsOpenModal={{
            children: "Des-asociar",
            className: "mr-3",
          }}
          onSuccess={refetchData}
        />
      ) : null}

      {movementInfo.statusValue === 5 ? null : checkPermission(
          "80e2c85e-c46e-4b13-850a-047ffb88cca7"
        ) ? (
        <RedirectButton
          css="mr-3"
          text={`Editar ${
            movementInfo.movementType === 1 ? "ingreso" : "egreso"
          }`}
          link={`/bancos/movimientos/${account}/editar/${movementInfo.Movimiento}`}
        />
      ) : null}

      <RedirectButton
        css="mr-3"
        text="Archivos asociados"
        link={`/bancos/movimientos/${account}/archivos-asociados?movimiento=${movementInfo.Movimiento}`}
      />

      {movementInfo.statusValue === 1 &&
      checkPermission("f6b3d381-7672-446d-b5a7-a3eeda7270f8") &&
      movementInfo.typeAssociation.id !== null ? (
        <CancelMovement
          currentStatus={movementInfo.statusValue}
          id={movementInfo.Movimiento}
          type={movementInfo.typeAssociation.id}
        />
      ) : null}
    </div>
  );
}
