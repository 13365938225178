import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import React from "react";
import Swal from "sweetalert2";
import ui from "./styles.module.scss";

/**
 *
 * @param {import("./types").PropsDeletePosition} props - Props
 * @returns
 */
export default function DeletePosition({ onConfirmed = () => {} }) {
  const handleDelete = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Eliminar posición",
      text: "¿Estás seguro de eliminar este registro de información?",
      showConfirmButton: true,
      confirmButtonText: "Si, eliminar",
      denyButtonText: "No, regresar",
      showDenyButton: true,
      icon: "question",
    });

    if (isConfirmed) onConfirmed();
  };

  return (
    <div className={ui.deleteContainer}>
      <ButtonV2 onClick={handleDelete}>
        <span>Borrar posición</span>
        <Icon icon="trash" />
      </ButtonV2>
    </div>
  );
}
