import Select from "components/individual/SelectV4";
import React, { useEffect, useState } from "react";
import { Label } from "structure/Document";

/**
 * @type {import("./types").YearOption[]}
 */
const OPTIONS = [
  {
    label: " -- SELECCIONA --",
    value: null,
    disabled: true,
  },
];

/**
 *
 * @param {Date} date - Date to start
 * @param {number} endYear - Year to finish the range
 * @returns
 */
function getYearsFromDateTo(date, endYear) {
  const startYear = date.getFullYear();
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
}

/**
 * Render a combo to select a year range
 * @param {import('./types').PropsYearSelect} props - Props
 * @returns {JSX.Element}
 */
export default function YearSelect({
  from = null,
  value = null,
  to = new Date().getFullYear(),
  required = false,
  onChange = () => {},
}) {
  const [options, setOptions] = useState(OPTIONS);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof from === "number") {
      const years = getYearsFromDateTo(
        new Date(`${from}-01-01:`),
        new Date(`${to}-01-01:`).getFullYear(),
      );

      /**
       * @type {import("./types").YearOption[]}
       */
      const newOptions = years.map((year) => ({
        label: `${year}`,
        value: year,
      }));

      setOptions([
        { label: "-- SELECCIONA --", value: null, disabled: true },
        ...newOptions.reverse(),
      ]);

      setLoaded(true);
    }
  }, [from]);

  return (
    <div>
      <Label required={required}>Año</Label>
      {loaded ? (
        <Select
          defaultSelected={{
            keyToCompare: "value",
            value,
          }}
          onChange={(option) => onChange(option.value)}
          labelToDisplay="label"
          options={async () => options}
        />
      ) : null}
    </div>
  );
}
