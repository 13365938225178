import React, { useContext } from "react";
import style from "./styles.module.scss";
import { ContextCatalogueAdmin } from "..";
import ModalEdit from '../ModalEdit';

export default function OptionsCatalogue() {
  const hook = useContext(ContextCatalogueAdmin);

  if (hook.product === null)
    return <div className={style.containerOptionsCatalogue}></div>;

  return (
    <div className={style.containerOptionsCatalogue}>
      <ModalEdit/>
    </div>
  );
}
