import useDialog from "customHooks/useDialog";
import { parseToFolio } from "helpers/documents";
import React, { useEffect, useState } from "react";
import Dialog from "structure/Dialog";
import ui from "../Table/styles.module.scss";
import { parseDateToText, parseDateToTime } from "helpers/dates";
import { mnCurrency } from "helpers/money";
import uiModal from "./styles.module.scss";
import { getAuthorizationInvoice } from "helpers/Apis/todo";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Details from "components/general/Details";
import CustomerInfo from "structure/Customer";

/**
 * Render more information about an invoice authorization
 * @param {import('./types').PropsMoreInfoAuth} props - Props
 * @returns {JSX.Element}
 */
export default function MoreInformation(props) {
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");

  const dialog = useDialog();

  useEffect(() => {
    (async function () {
      if (isOpen) {
        const data = await getAuthorizationInvoice(props.invoice.id);

        setMessage(data.message);

        dialog.open();
        return;
      }
      dialog.close();
    })();
  }, [isOpen]);

  if (!isOpen)
    return (
      <label className={ui.row} onClick={(e) => setIsOpen(true)}>
        <p>{parseToFolio(props.invoice?.folio)}</p>
        <p>
          {props.legalDocument === null
            ? "No facturado"
            : parseToFolio(props.legalDocument.folio)}{" "}
        </p>
        <p>
          {props.legalDocument === null
            ? props.invoice.customer?.socialReason
            : props.legalDocument.customer.socialReason}
        </p>
        <p>{props.createdBy.fullName}</p>
        <p>{props.revisionBy?.fullName || "Pendiente de revisar"}</p>
        <p>
          {props.response.wasAccepted === null
            ? "Pendiente"
            : props.response.wasAccepted
            ? "Aceptado"
            : "Rechazado"}
        </p>
      </label>
    );

  return (
    <Dialog
      title={`Más información no. pedido ${parseToFolio(props.invoice.folio)}`}
      forwardRef={dialog.refDialog}
      width={50}
      closeCallback={() => setIsOpen(false)}
      footer={
        <div className={uiModal.requested}>
          <p>
            <b>Parcialidades: </b> {props.partialities.requested}
          </p>
          <p>
            <b>TC:</b> {mnCurrency(props.tc.requested)}
          </p>
        </div>
      }
    >
      <div className={uiModal.content}>
        <Details name="auth" summary="Detalles" open>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={message} />
        </Details>

        <Details name="auth" summary="Cliente">
          <CustomerInfo
            idCustomer={
              props.legalDocument === null
                ? props.invoice.customer.id
                : props.legalDocument.customer.id
            }
          />
        </Details>
      </div>
    </Dialog>
  );
}
