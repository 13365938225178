import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import { Error, Success } from "helpers/alerts";
import { addBankAccountV2 } from "helpers/Apis/Banks";
import { dateToDbFormat, dateWithCeroHours } from "helpers/dates";
import React, { useContext } from "react";
import { ContextAddBank } from "..";

export const AddBank = () => {
  const {
    isPerformingQuery,
    isValid,
    info,
    closeModal,
    setIsPerformingQuery,
    onAdded,
  } = useContext(ContextAddBank);

  const attemptQuery = async () => {
    /**
     * @type {import("../../../../../../types/banks").DtoAddBank}
     */
    const dto = {
      CLABE: info.CLABE,
      claveSat: info.bank.clave === undefined ? null : info.bank.clave,
      cuenta: info.cuenta,
      currency: info.currency,
      socialReason: info.bank.info.label,
      typeAccount: info.typeAccount,
      initialResidue: info.residue,
      beginDate: dateToDbFormat(dateWithCeroHours(info.date)),
    };

    setIsPerformingQuery(true);

    try {
      const wasAdded = await addBankAccountV2(dto);
      if (wasAdded) {
        closeModal();
        Success(() => {}, "Cuenta agregada");
        onAdded();
      }
    } catch (error) {
      Error(() => {}, "No se pudo agregar la cuenta");
    }
    setIsPerformingQuery(false);
  };

  if (!isValid)
    return (
      <>
        <hr />
        <div className="d-flex justify-content-start mt-2">
          <a
            target={"_blank"}
            rel="noreferrer"
            href="https://scribehow.com/shared/_Como_agregar_una_cuenta_de_banco___BUnrZomLSoSug7ftGFoF5w"
          >
            ¿Como crear una cuenta de banco?
          </a>
        </div>
      </>
    );

  if (isPerformingQuery)
    return (
      <>
        <hr />
        <div className="d-flex justify-content-end">
          <Spinner hidden={false} text="Agregando cuenta..." />
        </div>
      </>
    );

  return (
    <>
      <hr />
      <div className="d-flex justify-content-between align-items-center mt-2">
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://scribehow.com/shared/_Como_agregar_una_cuenta_de_banco___BUnrZomLSoSug7ftGFoF5w"
        >
          ¿Como crear una cuenta de banco?
        </a>

        <ButtonV2 onClick={attemptQuery}>Agregar</ButtonV2>
      </div>
    </>
  );
};
