import { useEffect, useState } from "react";
import { initialState } from "./reducer";
import {
  downloadExcelContracts,
  getContractsPaginated,
} from "helpers/Apis/contract";
import { dateToDbFormat } from "helpers/dates";
import { useHistory } from "react-router-dom";
import { Warning } from "helpers/alerts";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import { saveAs } from "file-saver";
import useMemoryFilter from "customHooks/useMemoryFilter";

/**
 * Handle and get the data in order to display information on the screen
 * @returns {import("./types").useContractListValuesI}
 */
export default function useContractList() {
  const { retrieveData, setMemoryData } = useMemoryFilter(initialState);

  // const [state, setState] = useState(initialState);
  const [state, setState] = useState({
    ...initialState,
    ...retrieveData("contractList"),
    date: {
      start: new Date(retrieveData("contractList").date.start),
      end: new Date(retrieveData("contractList").date.end),
    },
  });

  const history = useHistory();

  const addContract = () =>
    history.push(`/ventas/contratos/agregar?modulo=ventas`);

  const viewDocument = () =>
    history.push(
      `../directorio/documentos/${state.rowSelected.idCustomer}/ver/${state.rowSelected.id}?modulo=contratos`
    );

  const editDocument = () =>
    state.rowSelected.estatusId !== 7
      ? Warning(
          `No se modificar el contrato, tiene que estar vigente para ser editable.`
        )
      : history.push(
          `../documentos/contrato/editar?modulo=ventas&documento=${state.rowSelected.id}&idCliente=${state.rowSelected.idCustomer}`
        );

  const attemptDownloadExcel = async () => {
    setState((current) => ({
      ...current,
      isDownloadingExcel: true,
    }));

    const excel = await downloadExcelContracts(
      dateToDbFormat(state.date.start),
      dateToDbFormat(state.date.end)
    );

    if (excel instanceof Blob) {
      saveAs(excel, `Contratos.xlsx`);
    }

    setState((current) => ({
      ...current,
      isDownloadingExcel: false,
    }));
  };

  useEffect(() => {
    setMemoryData({
      executive:state.executive,
      status:state.status,
      uen:state.uen,
      customer:state.customer,
      search:state.search,
      date:{
        start:new Date(state.date.start),
        end:new Date(state.date.end)
      }
    },'contractList');
  }, [
    state.executive,
    state.status,
    state.uen,
    state.date,
    state.customer,
    state.search
  ]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isFetchingData: true,
        rowSelected: null,
      }));

      const { documents, pages } = await getContractsPaginated({
        customer: state.customer,
        endDate: dateToDbFormat(state.date.end),
        page: state.page,
        startDate: dateToDbFormat(state.date.start),
        status: state.status,
        executive:
          typeof state.executive === "number" && state.executive > 0
            ? state.executive
            : null,
        search: state.search,
        uen: state.uen,
      });

      setState((current) => ({
        ...current,
        isFetchingData: false,
        totalPages: pages,
        rows: documents,
        isOperable: false,
      }));
    })();
  }, [state.refetch, state.page]);

  const updateSocialReason = (customer, name) =>
    setState((current) => ({
      ...current,
      customer,
      customerName: name,
    }));

  const updateStatus = (status) =>
    setState((current) => ({
      ...current,
      status,
    }));

  const triggerSearch = () =>
    setState((current) => ({
      ...current,
      refetch: !current.refetch,
      page: 1,
    }));

  const updateSearch = (search) =>
    setState((current) => ({
      ...current,
      search,
    }));

  const udpateExecutive = (executive, name) =>
    setState((current) => ({
      ...current,
      executive,
      executiveName: name,
    }));

  /**
   * Update the uen of the filter
   * @param {number} uen - Id of the uen to search
   * @param {string} [name=""] - Name of the uen selected
   * @returns {void}
   */
  const updateUen = (uen, name = "") =>
    setState((current) => ({
      ...current,
      uen,
      uenName: name,
    }));

  const updateRangeDates = (dates) =>
    setState((current) => ({
      ...current,
      date: {
        start: dates[0],
        end: dates[1],
      },
    }));

  /**
   * Set the contract selected
   * @param {import("types/typedef/contract").ContractI} contract - Information of the contract
   * @returns {void}
   */
  const setContract = (contract) =>
    setState((current) => ({
      ...current,
      rowSelected: contract,
      isOperable: contract.estatus === "Vigente" ? true : false,
    }));

  const setPage = (page) =>
    setState((current) => ({
      ...current,
      page,
    }));

  const downloadPdf = async () => {
    setState((current) => ({
      ...current,
      isDownloadingPdf: true,
    }));

    try {
      const pdf2 = await downloadPdfDocumentV3(
        state.rowSelected.id,
        "intranet"
      );
      saveAs(pdf2["0"], `Contrato - ${state.rowSelected.numeroDocumento}.pdf`);
    } catch (error) {
    } finally {
      setState((current) => ({
        ...current,
        isDownloadingPdf: false,
      }));
    }
  };

  return {
    isFetchingData: state.isFetchingData,
    setContract,
    setPage,
    downloadPdf,
    socialReason: state.customer,
    startDate: state.date.start,
    endDate: state.date.end,
    recordsWereFounded: state.rows.length > 0 ? true : false,
    updateSocialReason,
    editDocument,
    updateStatus,
    triggerSearch,
    updateSearch,
    updateRangeDates,
    contract: state.rowSelected,
    udpateExecutive,
    addContract,
    attemptDownloadExcel,
    viewDocument,
    updateUen,
    ...state,
    breadcrumPath: [
      {
        route: "/inicio",
        text: "Inicio",
      },
      {
        route: "/ventas/contratos",
        text: "Ventas",
      },
      {
        route: "",
        text: "Contratos",
      },
    ],
  };
}
