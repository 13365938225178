import React from "react";
import Dialog from "structure/Dialog";
import useMovementsReport from "./useMovementsReport";
import { Label } from "structure/Document";
import { Spinner } from "components/individual/Spinner/Spinner";
import Select from "components/individual/SelectV4";
import { createContext } from "react";
import FooterBankAccountReport from "./FooterBankAccountReport";
import PeriodCombo from "./PeriodCombo";
import style from "./styles.module.scss";


/**
 * Context to handle the bank account report
 * @type {import("react").Context<import("./types").ContextBankAccountReportV2>}
 */
export const ContextBankAccountReport = createContext(undefined);

/**
 * Render a html dom to create the bank account reports
 * * If id is provided, user can't change the bank account
 * * Id if isn't provided, user can pick the bank account
 * @param {import("./types").PropsBankAccountReportV2} props - Props
 * @returns {JSX.Element}
 */
export default function MovementReportV2({
  children = <></>,
  id = null,
  forwardRef = undefined,
}) {
  const hook = useMovementsReport(id);

  return (
    <ContextBankAccountReport.Provider value={hook}>
      <div ref={forwardRef} onClick={() => hook.handleModal(true)}>
        {children}
      </div>

      <Dialog
        width={40}
        title="Reporte de movimientos"
        forwardRef={hook.dialog.refDialog}
        footer={<FooterBankAccountReport />}
        closeCallback={() => hook.handleModal(false)}
      >
        {hook.isLoading ? (
          <Spinner text={"Cargando"} hidden={false} />
        ) : (
          <div className={style.bankAccountReport}>
            <div>
              <Label>Cuenta de banco</Label>
              {typeof id === "number" ? (
                <p>
                  {hook.account.bank.shortName} - {hook.account.account}
                </p>
              ) : (
                <Select
                  defaultSelected={{
                    keyToCompare: "id",
                    value: null,
                  }}
                  labelToDisplay="label"
                  onChange={(bank) => hook.setBankAccount(bank)}
                  options={async () => hook.accounts}
                />
              )}
            </div>

            <PeriodCombo />
          </div>
        )}
      </Dialog>
    </ContextBankAccountReport.Provider>
  );
}
