import React, { useEffect, useRef } from "react";
import { Modal } from "components/general/Modal";
import { useState } from "react";
import {
  FormTicket,
  FormTicketBody,
  FormTicketSubmit,
} from "structure/Bug/Tickets/formtickets";
import scss from "./styles.module.scss";
import useTicketsSocket from "customHooks/useTicketsSocket";
import Dialog from "structure/Dialog";
import useDialog from "customHooks/useDialog";

/**
 * Render a button to add a new ticket into system
 * @param {import("./types").PropsCreateTicket} props - Props
 * @returns {JSX.Element}
 */
export default function CreateTicket({
  onCreated = () => {},
  children = null,
}) {
  const dialog = useDialog();

  const idForm = useRef(window.crypto.randomUUID());

  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
    dialog.open();
  };

  useEffect(() => {
    if (isOpen) {
      dialog.open();
      return;
    }
    dialog.close();
  }, [isOpen]);

  const close = () => {
    setIsOpen(false);
  };

  const { notifyNewBug } = useTicketsSocket();

  /**
   * Handle when the ticket was added correctly
   * @param {import("../../../../../../server/models/tickets/paginate/types").Ticket} ticket - Ticket
   */
  const handleTicketCreated = (ticket) => {
    close();
    onCreated(ticket);
    notifyNewBug(
      ticket.title,
      ticket.description,
      ticket.reported.by.name,
      ticket.folio
    );
  };

  return (
    <>
      {children === null ? (
        <div className={scss.createTicket} onClick={open}>
          Crear ticket
        </div>
      ) : (
        <div onClick={open}>{children}</div>
      )}

      {isOpen ? (
        <FormTicket onCreated={handleTicketCreated} idForm={idForm.current}>
          <Dialog
            footer={<FormTicketSubmit />}
            forwardRef={dialog.refDialog}
            title="Registro de Reporte"
            closeCallback={close}
          >
            <FormTicketBody />
          </Dialog>
        </FormTicket>
      ) : null}
    </>
  );
}
