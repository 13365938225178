import { ActionType } from "./typesJs";

/**
 * State of the component in order to add documents
 * @type {import("./types").InvoiceStateI}
 */
export const initialState = {
  customer: null,
  customerSocialReason: null,
  comments: {
    notesAndConsiderations: [],
    regulars: [],
  },
  contactsOnMemory: [],
  isDocumentFetched: false,
  isPerformingQuery: false,
  partidas: {
    items: [],
    uens: [],
  },
  document: {
    number: null,
    cfdi: undefined,
    contact: {
      data: null,
      id: null,
    },
    reminder: {
      db: null,
      js: null,
    },
    currency: null,
    import: undefined,
    iva: undefined,
    payForm: undefined,
    payMethod: undefined,
    tc: 0,
    total: undefined,
    periocity: {
      id: "-1",
      begin: null,
      end: null,
      message: null,
      value: null,
    },
    expiration: {
      db: null,
      js: null,
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
/**
 *
 * @param {import("./types").InvoiceStateI} state - State of the component
 * @param {import("./types").ACTION} action - Action to perform
 */
export function reducerInvoice(state, action) {
  switch (action.type) {
    case ActionType.SET_CURRENCY: {
      return {
        ...state,
        document: {
          ...state.document,
          currency: action.payload,
        },
      };
    }

    case ActionType.SET_INITIAL_EDIT_DOCUMENT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ActionType.SET_IS_PERFORMING_QUERY: {
      return {
        ...state,
        isPerformingQuery: action.payload,
      };
    }

    case ActionType.SET_PERIOCITY: {
      return {
        ...state,
        document: {
          ...state.document,
          periocity: action.payload,
        },
      };
    }

    case ActionType.SET_ITEMS: {
      return {
        ...state,
        document: {
          ...state.document,
          import: action.payload.import,
          iva: action.payload.iva,
          total: action.payload.total,
          currency: action.payload.currency,
        },
        partidas: {
          items: action.payload.items,
          uens: action.payload.uens,
        },
      };
    }

    case ActionType.SET_CFDI: {
      return {
        ...state,
        document: {
          ...state.document,
          cfdi: action.payload,
        },
      };
    }

    case ActionType.SET_TC: {
      return {
        ...state,
        document: {
          ...state.document,
          tc: action.payload,
        },
      };
    }

    case ActionType.SET_PAY_FORM: {
      return {
        ...state,
        document: {
          ...state.document,
          payForm: action.payload,
        },
      };
    }

    case ActionType.SET_CONTACT: {
      return {
        ...state,
        document: {
          ...state.document,
          contact: action.payload,
        },
      };
    }

    case ActionType.SET_SOCIAL_REASON: {
      console.log(action.payload);

      return {
        ...state,
        customer: action.payload.id,
        customerSocialReason: action.payload.socialReason,
        customerRfc: action.payload.rfc,
      };
    }

    case ActionType.SET_NOTES_AND_CONSIDERATIONS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          notesAndConsiderations: action.payload,
        },
      };
    }

    case ActionType.SET_COMMENTS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          regulars: action.payload,
        },
      };
    }

    case ActionType.SET_PAY_METHOD: {
      return {
        ...state,
        document: {
          ...state.document,
          payMethod: action.payload,
        },
      };
    }

    case ActionType.SET_INITAL_CREATE_DOCUMENT: {
      return {
        ...state,
        document: {
          ...state.document,
          number: null,
          tc: action.payload.tc,
          reminder: {
            db: action.payload.reminder.db,
            js: action.payload.reminder.date,
          },
          expiration: {
            db: action.payload.expiration.db,
            js: action.payload.expiration.date,
          },
        },
        isDocumentFetched: true,
      };
    }

    case ActionType.SET_EXPIRATION: {
      return {
        ...state,
        document: {
          ...state.document,
          expiration: {
            db: action.payload.db,
            js: action.payload.js,
          },
        },
      };
    }

    case ActionType.SET_REMINDER: {
      return {
        ...state,
        document: {
          ...state.document,
          reminder: {
            db: action.payload.db,
            js: action.payload.js,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
