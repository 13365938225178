import React, { useContext } from "react";
import { MenuContext } from "..";
import TextIcon from "../TextIcon";
import scss from "../index.module.scss";
import RedirectButton from "../Redirect";

export const HelpDesk = () => {
  const { setMenuToDisplay, openTicketModal, openTickets } =
    useContext(MenuContext);

  return (
    <>
      <p onClick={() => setMenuToDisplay("general")}>
        <b>
          <TextIcon icon="back" text="Inicio > Herramientas" />
        </b>
      </p>

      <p className={scss.item} onClick={openTicketModal}>
        <TextIcon icon="support" text="Help Desk" />
      </p>

      <RedirectButton
        text={<TextIcon icon="ticket" text="Tickets" />}
        to={"/procesos-especiales/help-desk/tickets"}
      />

      {/* <p className={scss.item} onClick={openTickets}>
        <TextIcon icon="ticket" text="Tickets" />
      </p> */}
    </>
  );
};
