import ButtonV2 from "components/individual/ButtonsV2/Button";
import "../styles.css";
import { Label } from "structure/Document";
import useTickets from "./hooks/useTickets";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/individual/Input";
import schema from "./validations";
import { Spinner } from "components/individual/Spinner/Spinner";
import scss from "./styles.module.scss";
import JamTutorial from "structure/JamTutorial";
import LoadingText from "components/individual/TextLoading";
import React, { createContext, useRef } from "react";
import { useContext } from "react";
import Icon from "components/individual/HeaderIcon";
import { Files, DropZone, FileList } from "structure/Files";

/**
 * @type {import("./hooks/types").ContextTicketForm}
 */
const INITIAL_STATE = {
  attemptCreateTicket: () => {},
  updateFiles: () => {},
  state: undefined,
  idFormToUse: "",
  onSubmit: () => {},
  form: undefined,
};

const ContextCreateTicket = createContext(INITIAL_STATE);

/**
 *
 * @param {import("./hooks/types").PropsFormTicket} props - Props
 * @returns
 */
export const FormTicket = ({
  onCreated = () => {},
  idForm = null,
  children = <></>,
}) => {
  const { attemptCreateTicket, state, updateFiles } = useTickets(onCreated);
  const idFormHook = useRef(window.crypto.randomUUID());
  const idFormToUse = idForm === null ? idFormHook.current : idForm;

  /**
   * @type {import("react-hook-form").UseFormMethods<import("./hooks/types").TicketForm>}
   */
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async (data) => attemptCreateTicket(data);

  return (
    <ContextCreateTicket.Provider
      value={{
        attemptCreateTicket,
        state,
        updateFiles,
        idFormToUse,
        onSubmit,
        form,
      }}
    >
      {children}
    </ContextCreateTicket.Provider>
  );
};

export function FormTicketBody() {
  const hook = useContext(ContextCreateTicket);

  if (hook.form === undefined) return <></>;

  return (
    <form
      id={hook.idFormToUse}
      className={scss.createTicket}
      onSubmit={hook.form.handleSubmit(hook.onSubmit)}
    >
      <div>
        <Label>Título</Label>
        <Input
          name="title"
          forwardRef={hook.form.register}
          placeholder="Ejemplo: No timbra los complementos"
        />
        <p className="font-weight-bold text-danger">
          {hook.form.errors.title?.message}
        </p>

        <Label>Descripción</Label>
        <Input
          name="description"
          forwardRef={hook.form.register}
          placeholder="Ejemplo: Al hacer pagos cuando la cuenta de banco tiene moneda USD, pero si funciona en los bancos con moneda MXN"
        />
        <p className="font-weight-bold text-danger">
          {hook.form.errors.description?.message}
        </p>

        <Label>Liga de JAM (Recomendado)</Label>
        <Input
          name="jamLink"
          forwardRef={hook.form.register}
          placeholder="Ejemplo: https://jam.dev/c/72e850ee-7907-4afd-8476-56a76ae03757"
        />
        <p className="font-weight-bold text-danger">
          {hook.form.errors.jamLink?.message}
        </p>

        <div className={scss.associatedFiles}>
          <b>Archivos asociados</b>

          <Files
            files={hook.state.files}
            allowedExtensions={[
              "pdf",
              "xml",
              "png",
              "jpg",
              "jpeg",
              "msg",
              "eml",
              "webp",
            ]}
            onFilesSelected={(files) => hook.updateFiles(files)}
          >
            <DropZone />
            <FileList />
          </Files>
        </div>
      </div>
    </form>
  );
}

export function FormTicketSubmit() {
  const hook = useContext(ContextCreateTicket);

  return (
    <div className={scss.footer}>
      <JamTutorial />

      {hook.state.isCreatingTicket ? (
        <Spinner hidden={false} text={<LoadingText>Creando</LoadingText>} />
      ) : (
        <ButtonV2 form={hook.idFormToUse} type="submit">
          <span>Crear ticket</span>
          <Icon icon="save" />
        </ButtonV2>
      )}
    </div>
  );
}
