import React, { useContext, useRef } from "react";
import ui from "./styles.module.scss";
import { Label } from "structure/Document";
import ElipsisMobile, {
  CustomMenuItem,
} from "components/individual/ElipsisMobile";
import { parseDateToText } from "helpers/dates";
import { ContextContract } from "../..";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import LoginContext from "context/Login/LoginContext";
import { downloadPdfDocumentV3 } from "helpers/Apis/Documents";
import TerminateContract from "structure/TerminateContract";
import ModalDocsRelatedV2 from "components/general/Documents/ModalDocsRelatedV2";



/**
 * Render the item for mobile
 * @param {import('types/typedef/contract').ContractI} contract - Information of the contract
 * @returns {JSX.Element}
 */
export default function Mobile(contract) {
  const {
    setContract,
    viewDocument,
    isOperable,
    editDocument,
    downloadPdf,
    isDownloadingPdf,
    triggerSearch,
  } = useContext(ContextContract);

  const { userInfo } = useContext(LoginContext);

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const email = useRef();

  /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
  const terminate = useRef();

    /**
   * @type {import("react").MutableRefObject<HTMLElement>}
   */
    const moreInfo = useRef();

  const sendEmail = () => email.current.click();

  const openTerminate = () => terminate.current.click();

  const openMoreInfo = () => moreInfo.current.click();

  return (
    <>
      {userInfo.length >= 1 ? (
        <EmailDocumentsV2
          idDocument={contract.id}
          forwardRef={email}
          subject={`Praxia - Contrato ${contract.numeroDocumento}`}
          message={`Se le adjunta la informacion del contrato ${contract.numeroDocumento} (pdf)`}
          cc={[userInfo[0].email]}
          to={[contract.contact.email]}
          files={async () =>
            downloadPdfDocumentV3(
              contract.id,
              `Contrato-${contract.numeroDocumento}`
            )
          }
        />
      ) : null}

      <TerminateContract
        idContract={contract.id}
        idStatus={contract.estatusId}
        onCancelled={triggerSearch}
        forwardRef={terminate}
      />

      <ModalDocsRelatedV2 fromLegal={false} idDocument={contract.id} forwardRef={moreInfo}/>

      <div className={ui.card}>
        <div className="d-flex justify-content-between">
          <div>
            <Label>No. doc.</Label>
            <p>{contract.numeroDocumento}</p>
          </div>

          <ElipsisMobile onShow={() => setContract(contract)}>
            <CustomMenuItem onClick={viewDocument}>
              Ver documento
            </CustomMenuItem>
            <CustomMenuItem onClick={openMoreInfo}>Más información</CustomMenuItem>
            <CustomMenuItem onClick={sendEmail}>Enviar correo</CustomMenuItem>
            {isOperable ? (
              <CustomMenuItem onClick={openTerminate}>Terminar</CustomMenuItem>
            ) : null}
            <CustomMenuItem onClick={downloadPdf} disabled={isDownloadingPdf}>
              {isDownloadingPdf ? "Descargando..." : "PDF"}
            </CustomMenuItem>
            {isOperable ? (
              <CustomMenuItem onClick={editDocument}>Editar</CustomMenuItem>
            ) : null}
          </ElipsisMobile>
        </div>

        <div>
          <Label>Razón social</Label>
          <p>{contract.razonSocial}</p>
        </div>

        <div>
          <Label>Ejecutivo</Label>
          <p>{contract.iniciales}</p>
        </div>

        <div className={ui.related}>
          <div>
            <Label>Moneda</Label>
            <p>{contract.moneda}</p>
          </div>

          <div>
            <Label>Total</Label>
            <p>{contract.total.texto}</p>
          </div>
        </div>

        <div className={ui.related}>
          <div>
            <Label>Registro</Label>
            <p>{parseDateToText(contract.registro.yyyymmdd)}</p>
          </div>

          <div>
            <Label>Recordatorio</Label>
            <p>{contract.parsedReminder}</p>
          </div>

          <div>
            <Label>Vencimientos</Label>
            <p> {parseDateToText(`${contract.facturar.yyyymmdd}:`)}</p>
          </div>
        </div>

        <div>
          <Label>Estatus</Label>
          <p>{contract.estatus}</p>
        </div>
      </div>
    </>
  );
}
