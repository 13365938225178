import React, { createContext } from "react";
import useCashFlow, { INITIAL_STATE_CONTEXT } from "./useCashFlow";
import BreadCrumV2 from "components/individual/breadCrums/BreadCrumV2";
import CurrencyCashFlow from "./CurrencyCashFlow";
import TcCashFlow from "./TcCashFlow";
import style from "./styles.module.scss";
import ExcelCashFlow from "./ExcelCashFlow";
import TableCashFlow from "./TableCashFlow";
import SearchCashFlow from "./SearchCashFlow";
import CurrencyReportCashFlow from "./CurrencyReportCashFlow";
import DetailsCashFlow from "./DetailsCashFlow";
import MoreInfoBankAccount from "structure/MoreInformationBankAccount";
import { mnCurrency } from "helpers/money";

/**
 * @type {import("react").Context<import("./useCashFlow/types").ContextCashFlow>}
 */
export const ContextCashFlow = createContext({
  ...INITIAL_STATE_CONTEXT,
  type: "proyeccion",
});

/**
 * Render the cash flow for the reports of the system
 * @param {import("./types").PropsCashFlow} props - Props
 * @returns {JSX.Element} Render a component for the cash flow
 */
export default function CashFlow({ type = "proyeccion" }) {
  const hook = useCashFlow(type);

  return (
    <ContextCashFlow.Provider value={{ ...hook, type }}>
      <div className={style.containerCashFlow}>
        <div className={style.topCashFlow}>
          <BreadCrumV2 path={hook.breadcrum} />

          <div className={style.filtersContainer}>
          <h5 className="text-center mt-3 mb-2">
            {type === "proyeccion"
              ? "Proyección de flujo de efectivo"
              : "Flujo de efectivo"}
          </h5>
          <h6 className="text-center my-2">
            Moneda {hook.cashFlow.currency}, TC utilizado{" "}
            {mnCurrency(hook.cashFlow.tc)}
          </h6>

          {hook.cashFlow.bankAccountId === undefined ? null : (
            <MoreInfoBankAccount
              id={hook.cashFlow.bankAccountId}
              onClose={() => hook.setBankAccount(undefined)}
            />
          )}

          <div className={style.filters}>
            <CurrencyCashFlow />
            <TcCashFlow />
            <CurrencyReportCashFlow />
            <DetailsCashFlow />
            <SearchCashFlow />
            <ExcelCashFlow />
          </div>
          </div>
        </div>
        <TableCashFlow />
      </div>
    </ContextCashFlow.Provider>
  );
}
