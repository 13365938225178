import styled from "styled-components";

export const ContainerComments = styled.div`
  height: 40vh;
  overflow: auto;
  color: var(--primaryColor);
  padding: 20px;
  h1 {
    text-align: center;
  }

  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const NoComments = styled.div`
  height: 40vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
  }

  @media (max-width: 767px) {
    height: 100%;
  }
`;
