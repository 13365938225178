import React, { createContext, useContext, useState } from "react";
import { Label } from "structure/Document";
import { Controller, useForm } from "react-hook-form";
import Error from "components/individual/Error";

/**
 * @type {import("react").Context<import("react-hook-form").UseFormMethods<import("react-hook-form").FieldValues>>}
 */
const ContextFormPersonalData = createContext(undefined);

/**
 * Form to handle personal data about a person
 * @param {import("./types").PropsFormPersonalData} props - Props
 */
export default function FormPersonalData(props) {
  const form = useForm({
    shouldFocusError:true,
    resolver:props?.resolver
  });

  const correctSubmit = (data) => {
    props?.onSuccessSubmit(data);
  };

  return (
    <ContextFormPersonalData.Provider value={form}>
      <form
        noValidate={true}
        onSubmit={form.handleSubmit(correctSubmit)}
        {...props}
      >
        {props?.children}
      </form>
      ;
    </ContextFormPersonalData.Provider>
  );
}

/**
 * Render the name field input
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} props
 */
export function Name(props) {
  const hook = useContext(ContextFormPersonalData);

  const [length, setLength] = useState(0);

  return (
    <Controller
      name="firstName"
      control={hook.control}
      render={({ value, onChange }) => (
        <div>
          <Label
            required={props?.required || false}
            maxLength={props?.maxLength || 30}
            currentLength={length}
          >
            {props?.placeholder || "Nombre"}
          </Label>
          <input
            {...props}
            type="text"
            placeholder="Escribe aquí"
            maxLength={props?.maxLength || 30}
            onChange={(e) => {
              const valueToUse = e.target.value;
              onChange(valueToUse);
              setLength(valueToUse.length);
            }}
            value={value}
          />
          <p>{hook?.errors[props?.name]?.message}</p>
        </div>
      )}
    />
  );
}

/**
 * Render the name field input
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} props
 */
export function Email(props) {
  const hook = useContext(ContextFormPersonalData);

  const [length, setLength] = useState(0);

  return (
    <Controller
      name="email"
      control={hook.control}
      render={({ value, onChange }) => (
        <div>
          <Label
            required={props?.required || false}
            maxLength={props?.maxLength || 30}
            currentLength={length}
          >
            {props?.placeholder || "Correo electrónico"}
          </Label>
          <input
            {...props}
            type="email"
            placeholder="Escribe aquí"
            maxLength={props?.maxLength || 30}
            onChange={(e) => {
              const valueToUse = e.target.value;
              onChange(valueToUse);
              setLength(valueToUse.length);
            }}
            value={value}
          />
        </div>
      )}
    />
  );
}

export function Birthday(props) {
  const hook = useContext(ContextFormPersonalData);

  return (
    <Controller
      name="birthday"
      control={hook.control}
      render={({ value, onChange }) => (
        <div>
          <Label required={props?.required || false}>
            {props?.placeholder || "Fecha de nacimiento"}
          </Label>
          <input
            {...props}
            type="date"
            placeholder="Selecciona"
            onChange={(e) => {
              const valueToUse = e.target.value;
              onChange(valueToUse);
            }}
            value={value}
          />
        </div>
      )}
    />
  );
}
