import React, { useContext } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../components/individual/Modals/Modal";
import Comments from "./Comments";

import DownloadFile from "../../../../components/general/Files/DownloadFile";

import TableContext from "../../../../context/Table/TableContext";
import LoginContext from "../../../../context/Login/LoginContext";
import DeleteFile from "../../../../components/general/Files/DeleteFile";

/**
 *
 * @component OptionsAssociateFile
 * @param {object} props - Props of component
 * @param {FileInfo|null} props.fileInfo - Information of the selected file
 */
function OptionsAssociateFile({ fileInfo , setRow }) {

  const { setReload, refetch } = useContext(TableContext);
  const { userInfo } = useContext(LoginContext);

  const refreshTable = () => {
    setReload(!refetch);
    setRow(null);

  }

  return (
    <div className="spaceBetween">
        

      {fileInfo !== null ? (
        <>
        
          <div className="spaceBetween-item">
            <Modal buttonText="Comentarios" title="Comentarios">
              <Comments idFile={fileInfo.id} />
            </Modal>
          </div>

          {fileInfo.isActive ? (
            <>
              <div className="spaceBetween-item">
                <DownloadFile
                  fileExtension={fileInfo.extension}
                  fileName={fileInfo.fileName}
                  url={fileInfo.source}
                />
              </div>

              <div className="spaceBetween-item">
                <DeleteFile 
                  pathFile = {fileInfo.source}
                  idFile = {fileInfo.id}
                  deletedBy = {userInfo[0].fullName}
                  onDeleted = {refreshTable}
                />
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

OptionsAssociateFile.propTypes = {};

export default OptionsAssociateFile;