import { useContext, useState } from "react";
import LoginContext from "../context/Login/LoginContext";
import { parseToFloat } from "../helpers/money";
import { useParams } from "react-router";
import { trimDate, MONTHS } from "../helpers/dates";
import {
  cancelMovement,
  disassociateConceptIngress,
  setCancelStatus,
} from "helpers/Apis/Banks";
import TableContext from "context/Table/TableContext";
import { v4 as uuidv4 } from "uuid";
import { YesNoAlert } from "helpers/alerts";
import { MovementContext } from "pages/Banks/Movements/Movements";
// import { setCancelStatus }

export default function useMovements() {
  const { userInfo } = useContext(LoginContext);

  const { emptyInfoRow } = useContext(MovementContext);

  const { setReload, refetch } = useContext(TableContext);
  const { typeMovement } = useParams();

  const [isCancelling, setIsCancelling] = useState(false);
  const [isDisasociating, setIsDisasociating] = useState(false);

  /**
   * Cancel the movement
   * @param {number} idMovement - Id of the movement to cancell
   */
  const disasociate = async (idMovement) => {
    setIsDisasociating(true);
    setIsCancelling(true);
    const wasCanceled = await cancelMovement(idMovement);
    setIsDisasociating(false);
    setIsCancelling(false);
    setReload(!refetch);
    return wasCanceled;
  };

  /**
   * Disassociate a movement of type concept
   * @param {number} idMovement - Id of the movement
   * @returns {boolean}
   */
  const disassociateConcept = async (idMovement) => {
    setIsDisasociating(true);
    setIsCancelling(true);

    const wasCanceled = await disassociateConceptIngress(idMovement);

    setIsDisasociating(false);
    setIsCancelling(false);
    setReload(!refetch);
    return wasCanceled;
  };

  /**
   * Prompt the confirmation modal in order to dis-associate an ingress
   * @param {number} idMovement - Id movement
   * @param {number} typeMovement - Id of the type ingress was selected
   */
  const promptDisassociateConfirmation = async (idMovement, typeMovement) => {
    const functionToExecute =
      typeMovement === 1 ? disasociate : disassociateConcept;

    YesNoAlert("¿Estas seguro de desasociar el movimiento?", async () => {
      const wasDisassocaited = await functionToExecute(idMovement);
      if (wasDisassocaited) emptyInfoRow();

      return wasDisassocaited;
    });
  };

  /**
   * Cancel the movement
   * @param {number} idMovement - Id of the movement
   */
  const cancel = async (idMovement) => {
    setIsCancelling(true);
    const wasCancelled = await setCancelStatus(idMovement);
    setIsCancelling(false);

    if (wasCancelled) {
      emptyInfoRow();
      setReload(!refetch);
    }

    return wasCancelled;
  };

  function generateQue(data, bankId, movements, type) {
    const id = uuidv4();

    if (data.concept === "") {
      data.concept = "No identificado";
    }

    let movementType = null;

    if (typeMovement === "ingreso") movementType = 1;
    else movementType = 0;

    const parsedDate = trimDate(data.registerDate, "dmy", "-");
    const uiDate = `${parsedDate.year}/${MONTHS[parsedDate.month - 1]}/${
      parsedDate.day
    }`;

    data = {
      ...data,
      id,
      importConcept: parseToFloat(data.importConcept),
      movementType,
      movementTypeNumber: movementType,
      userName: `${userInfo[0].firstName} ${userInfo[0].middleName} ${userInfo[0].lastName1}`,
      bankId,
      userId: null,
      uiDate,
    };

    let reversedMovements = [...movements, data];

    return reversedMovements.reverse();
  }

  return {
    generateQue,

    disasociate,
    isDisasociating,

    cancel,
    isCancelling,

    promptDisassociateConfirmation,
  };
}
