import React from "react";
import ButtonV2 from "../ButtonsV2/Button";
import { Spinner } from "../Spinner/Spinner";
import LoadingText from "../TextLoading";
import useBlobAzure from "customHooks/useBlobAzure";

/**
 * Download a file from the server using the ID
 * @param {import('./types').PropsDownloadFile} props - Props
 * @returns {JSX.Element} Render a button to download a file from the server
 * @example 
 * // You can set the filename with the extension if the fetch return octect-stream
*     <DownloadFile
        idFile={infoRow.pdf}
        children={"PDF"}
        className="mr-2"
        filename={`FR-${infoRow.noDocument}.pdf`}
      />
 */
export const DownloadFile = (props) => {
  const blob = useBlobAzure(props.idFile, props.filename);

  if (typeof props.idFile !== "number") return <></>;

  if (props.idFile <= 0) return <></>;

  if (blob.isDownloading)
    return (
      <Spinner hidden={false} text={<LoadingText>Descargando</LoadingText>} />
    );

  return <ButtonV2 {...props} onClick={blob.attemptDownload} />;
};
