export const QuoteFormData = {
    Cliente: {
        id: "cliente",
        name: "cliente",
        labelTxt: "Cliente *",
        placeholderTxt: "Seleccione un cliente",
    },
    Proveedor: {
        id: "provedor",
        name: "provedor",
        labelTxt: "Provedor *",
        placeholderTxt: "Seleccione un proveedor",
    },
    UEN: {
        id: "uen",
        name: "uen",
        labelTxt: "UEN *",
        placeholderTxt: "Seleccione un UEN",
    },
    Contacto: {
        id: "contacto",
        name: "contacto",
        labelTxt: "Contacto *",
        placeholderTxt: "Seleccione un contacto",
        disable: true,
    },
    Moneda: {
        id: "moneda",
        name: "moneda",
        labelTxt: "Moneda *",
        placeholderTxt: "Seleccione una Moneda",
    },
    TipoCambio: {
        id: "tipoCambio",
        name: "tipoCambio",
        labelTxt: "Tipo de cambio *",
        placeholderTxt: "$0",
    },
    FechaVencimiento: {
        id: "expirationDate",
        name: "expirationDate",
        labelTxt: "Fehca de vencimiento *",
        placeholderTxt: "Seleccione una fecha",
    },
    FechaRecordatorio: {
        id: "reminderDate",
        name: "reminderDate",
        labelTxt: "Fecha de recordatorio *",
        placeholderTxt: "Seleccione una fecha",
        disable: true
    },
    PercentAvance: {
        id: "percentAvance",
        name: "percentAvance",
        labelTxt: "% Avance *",
        placeholderTxt: "0%",
    },
    PercentProb: {
        id: "percentProb",
        name: "percentProb",
        labelTxt: "% Probabilidad *",
        placeholderTxt: "0%",
    },
    UsoCFDI: {
        id: "cfdi",
        name: "cfdi",
        labelTxt: "Uso de CFDI *",
        placeholderTxt: "CFDI",
    },
    FormaPago: {
        id: "formaPago",
        name: "formaPago",
        labelTxt: "Forma de pago *",
        placeholderTxt: "Debito",
    },
    MetodoPago: {
        id: "metodoPago",
        name: "metodoPago",
        labelTxt: "Metodo de pago *",
        placeholderTxt: "Chequera",
    },
    DiaCredito: {
        id: "diasCredito",
        name: "diasCredito",
        labelTxt: "Dias de credito *",
        placeholderTxt: "Seleccione los dias de credito",
    },
};