// HOOKS
import React, { useContext, useEffect, useState } from "react";
import useAddDocument from "../../../../../customHooks/useAddDocuments";

// COMPONENTS
import {
  InputText,
  Select,
  InputNumber,
  ErrorMessage,
} from "../../../../../components/individual/inputs/Inputs";
import { InputCurrency } from "../../../../../components/individual/inputs/Inputs";
import InformativePrice from "../../../../../components/general/Forms/Text/InformativePrice";

// HELPERS
import { setValue } from "../../../../../helpers/forms";
import { mnCurrency } from "../../../../../helpers/money";
import { getNumberDate } from "../../../../../helpers/dates";
import { indexedTypeDoc } from "../../../../../helpers/Directory/Documents/addDocument";

// APIS
import {
  GetParameters,
  GetDocumentProgress,
  GetProbability,
  GetMyActivCustomersContacts,
} from "../../../../../helpers/Apis/Documents";

// LIBS
import { useParams } from "react-router";

// CONTEXT
import AddDocumentContext from "../../../../../context/AddDocument/AddDocumentContext";

export function SocialReason({
  label = "Razon social",
  placeholder = "Escribe aqui",
  disabled = false,
  customer,
}) {
  useEffect(() => {
    setValue("cliente", customer);
  }, [customer]);

  return (
    <InputText
      disabled={disabled}
      htmlFor="cliente"
      id="cliente"
      name="cliente"
      labelTxt={label}
      placeholderTxt="Escribe aqui"
    />
  );
}

export function PorConfirmar({ label }) {
  return <InputText disabled={true} labelTxt={label} placeholderTxt="---" />;
}

export function Progress({
  label = "% Avanze",
  id = "percentAvance",
  name = "percentAvance",
  selected = null,
  forwardRef = {},
}) {
  const { typeDocument } = useParams();

  const [progresses, setProgresses] = useState([]);

  useEffect(() => {
    async function loadProgresses() {
      const apiProgreses = await GetDocumentProgress(
        indexedTypeDoc[typeDocument]
      );

      setProgresses(apiProgreses);
    }

    loadProgresses();
  }, []);

  useEffect(() => {
    const option = document.getElementById(`percentAvance${selected}`);

    if (progresses !== null && option !== null) option.selected = true;
  }, [progresses, selected]);

  return (
    <div className="itemDocument">
      <Select
        addTodos={false}
        idSelect="percentAvance"
        nameSelect="percentAvance"
        label={label}
        textJSON="porcentaje"
        valueJSON="documentProgressID"
        options={progresses}
        forwardRef={forwardRef}
      />
    </div>
  );
}

export function Probability({
  selected = null,
  label = "% Probabilidad",
  forwardRef = {},
}) {
  const [probabilities, setProbabilities] = useState([]);

  useEffect(() => {
    async function loadOptions() {
      const apiProbabilities = await GetProbability();

      setProbabilities(apiProbabilities);
    }

    loadOptions();
  }, []);

  useEffect(() => {
    const option = document.getElementById(`percentProb${selected}`);

    if (selected !== null && option !== null) option.selected = true;
  }, [probabilities, selected]);

  return (
    <Select
      addTodos={false}
      label={label}
      idSelect="percentProb"
      nameSelect="percentProb"
      textJSON="prbability"
      valueJSON="probabilityID"
      className="itemDocument"
      options={probabilities}
      forwardRef={forwardRef}
    />
  );
}

export function TCP({ tcp, label = "Tipo de cambio", forwardRef = {} }) {
  const { setExchangeTcp } = useContext(AddDocumentContext);
  const { changeCurrency } = useAddDocument();

  return (
    <InputCurrency
      defaultValue={tcp}
      changeEvent={(tcp) => {
        setExchangeTcp(+tcp);
        changeCurrency(+tcp);
      }}
      htmlfor="tipoCambio"
      id="tipoCambio"
      name="tipoCambio"
      labelTxt={label}
      aditionalClasses="itemDocument"
      placeholder="Escribe aqui"
      forwardRef={forwardRef}
    />
  );
}

export function InformativeTCP({
  tcp,
  label = "Tipo de cambio",
  forwardRef = {},
}) {
  const { setExchangeTcp } = useContext(AddDocumentContext);
  const { changeCurrency } = useAddDocument();

  useEffect(() => {
    setValue("tipoCambio", mnCurrency(tcp));
    setExchangeTcp(tcp);
    changeCurrency(tcp);
  }, [tcp]);

  return (
    <InputText
      readonly={true}
      htmlFor="tipoCambio"
      id="tipoCambio"
      name="tipoCambio"
      labelTxt={label}
      placeholderTxt="No editable"
      forwardRef={forwardRef}
      aditionalClasses="inputBlock"
    />
  );
}

export function CreditDays({
  label = "Dias de credito",
  creditDays,
  forwardRef = {},
  errors = {},
}) {
  useEffect(() => {
    setValue("diasCredito", creditDays);
  }, [creditDays]);

  return (
    <div className="itemDocument">
      <InputNumber
        decimal={false}
        htmlFor="diasCredito"
        id="diasCredito"
        name="diasCredito"
        labelTxt={label}
        placeholderTxt="Escribe aqui"
        forwardRef={forwardRef}
      />

      <ErrorMessage message={errors["diasCredito"]?.message} />
    </div>
  );
}

export function ImportPrice() {
  const { prices, UpdatePricesDoc } = useAddDocument();

  UpdatePricesDoc();

  return (
    <div className="itemDocument">
      <InformativePrice
        id="importPrice"
        name="importPrice"
        label="Importe"
        text={prices.import}
      />
    </div>
  );
}

export function IvaPrice() {
  const { prices, UpdatePricesDoc } = useAddDocument();

  UpdatePricesDoc();

  return (
    <div className="itemDocument">
      <InformativePrice
        id="ivaPrice"
        name="ivaPrice"
        label="IVA"
        text={prices.iva}
      />
    </div>
  );
}

export function TotalPrice() {
  const { prices, UpdatePricesDoc } = useAddDocument();

  UpdatePricesDoc();

  return (
    <div className="itemDocument">
      <InformativePrice
        id="totalPrice"
        name="totalPrice"
        label="Total"
        text={prices.total}
      />
    </div>
  );
}

export function CustomerContacts({
  label = "Contactos *",
  idCustomer = null,
  onChange = () => {},
  forwardRef = {},
  selected = null,
  css = "na",
}) {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (idCustomer === null) return;

    async function loadCustomers() {
      const apiContacts = await GetMyActivCustomersContacts(idCustomer);
      setContacts(apiContacts);
    }

    loadCustomers();
  }, []);

  useEffect(() => {
    const option = document.getElementById(`contacto${selected}`);

    if (selected !== null && option !== null) option.selected = true;
  }, [contacts, selected]);

  return (
    <Select
      initialOption="Selecciona"
      idSelect="contacto"
      nameSelect="contacto"
      valueJSON="contactID"
      textJSON="fullName"
      className={css}
      label={label}
      onChange={(value) => onChange(value)}
      options={contacts}
      forwardRef={forwardRef}
    />
  );
}

export function InformativeReminderDate({
  date = new Date(),
  forwardRef = {},
  id = "reminderDate",
  name = "reminderDate",
}) {
  const [reminderDays, setReminderDays] = useState(0);

  useEffect(() => {
    async function loadDefaultValue() {
      const { parameters } = await GetParameters();

      const { value } = parameters.find(
        (parameter) => parameter.parameter === 2
      );

      setReminderDays(+value);
    }

    loadDefaultValue();
  }, []);

  useEffect(() => {
    // console.log(date);

    if (date === null) {
      return;
    }

    // console.log(date===null ? console.log(true) : console.log(false));

    let copyDate = date.toISOString();

    copyDate = new Date(copyDate);

    const reminder = copyDate.setDate(copyDate.getDate() - reminderDays);

    const textDate = getNumberDate(new Date(reminder));

    const monthName = `${textDate.monthAbrev}`.toLocaleLowerCase();

    console.log(
      `fakeReminderDate${id}`,
      `${textDate.day}/${monthName}/${textDate.year}`
    );
    console.log(`${textDate.year}-${textDate.month}-${textDate.day}`);

    setValue(
      `fakeReminderDate${id}`,
      `${textDate.day}/${monthName}/${textDate.year}`
    );
    setValue(id, `${textDate.year}-${textDate.month}-${textDate.day}`);
  }, [date, reminderDays]);

  return (
    <div className="itemDocument">
      <InputText
        id={`fakeReminderDate${id}`}
        labelTxt="Recordatorio"
        placeholderTxt="No editable"
        readonly={true}
        // forwardRef = {forwardRef}
      />

      <input
        id={id}
        name={name}
        type="hidden"
        ref={forwardRef}
        readOnly={true}
      />
    </div>
  );
}
