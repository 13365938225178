import * as yup from "yup";

const description = yup
  .string()
  .max(256, "Máximo 256 caracteres")
  .required("Obligatorio")
  .typeError("La descripción debe ser texto");
const family = yup
  .string()
  .max(100, "Máximo 100 caracteres")
  .required("Obligatorio")
  .typeError("La familia debe ser texto");
const subFamily = yup
  .string()
  .max(100, "Máximo 100 caracteres")
  .required("Obligatorio")
  .typeError("La sub-familia debe ser texto");

const marginRate = yup
  .number()
  .min(1, "El margen de ganancia debe ser mínimo 1%")
  .max(100, "El margen de ganancia debe ser máximo 100%")
  .required("Obligatorio")
  .typeError("El margen de ganancia debe de ser numérico");

const excent = yup.boolean().default(false);

const status = yup
  .boolean()
  .required("Obligatorio")
  .typeError(`El estatus debe ser 'Activo' o 'Inactivo'`);

export const schemaAddUen = yup.object().shape({
  description,
  family,
  subFamily,
  marginRate,
  
});

export const schemaUpdateUen = yup.object().shape({
  description,
  family,
  subFamily,
  marginRate,
  status,
});
