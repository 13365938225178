import React, { useContext, useRef } from "react";
import { ContextOldResidues } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";
import style from "../styles.module.scss";
import { mnCurrency } from "helpers/money";
import DetailRow from "./DetailRow";
import LoadingText from "components/individual/TextLoading";

const CURRENCY_LABEL = {
  USD: "Dolares",
  MXN: "Pesos",
};

export default function TableOldResidues() {
  const hook = useContext(ContextOldResidues);

  const key = useRef(window.crypto.randomUUID());

  if (hook.isLoading)
    return (
      <Spinner text={<LoadingText>Cargando</LoadingText>} hidden={false} />
    );

  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <div className={style.table}>
        <p className="font-weight-bold m-0 text-center">
          {hook.currencyToReport === "Todos"
            ? CURRENCY_LABEL[hook.currencyToUse]
            : CURRENCY_LABEL[hook.currencyToReport]}
        </p>
        <div
          className={
            !hook.showDetails ? style.withOutDetails : style.withDetails
          }
        >
          <p className="font-weight-bold d-flex flex-column">
            <span>Razón social</span>
            {hook.showDetails ? <span>No. Factura Parcialidad</span> : null}
          </p>

          {hook.showDetails ? (
            <p
              className="font-weight-bold d-flex align-items-end"
              style={{ height: "100%" }}
            >
              Fecha vencimiento
            </p>
          ) : null}

          <div className={style.headerResidues} style={hook.showDetails ? {alignItems:"start"} : {}}>
            <p className="font-weight-bold">Al corriente</p>
            <p className="font-weight-bold">1 a 7 días</p>
            <p className="font-weight-bold">8 a 14 días</p>
            <p className="font-weight-bold">Más de 14 días</p>
            <p className="font-weight-bold">Total</p>
          </div>
        </div>

        <div className={style.containerRowsOldResidues}>
          {hook.invoices.map((invoice, i) => (
            <div
              className={
                !hook.showDetails ? style.rowWithoutDetails : style.withDetails
              }
              key={`${key.current}-${i}`}
            >
              <p className="text-left">{invoice.socialReason}</p>
              {!hook.showDetails ? null : <p>-</p>}
              <div className={style.rowResiduesInvoice}>
                <p className="text-right">
                  {mnCurrency(invoice.inTime.residue)}
                </p>
                <p className="text-right">
                  {mnCurrency(invoice.expired1To7.residue)}
                </p>
                <p className="text-right">
                  {mnCurrency(invoice.expired7To14.residue)}
                </p>
                <p className="text-right">
                  {mnCurrency(invoice.expiredMore14.residue)}
                </p>
                <p className="text-right">{mnCurrency(invoice.total)}</p>
              </div>

              {hook.showDetails ? (
                <>
                  {invoice.inTime.invoice.map((item, i) => (
                    <DetailRow
                      key={`${key.current}-${i}-inTime`}
                      expiration={item.expirationDate}
                      noDocument={item.invoiceNumber}
                      partialitie={item.partiality}
                      residue={item.residue}
                      type="inTime"
                      total={item.residue}
                    />
                  ))}

                  {invoice.expired1To7.invoice.map((item) => (
                    <DetailRow
                      key={`${key.current}-${i}-1to7days`}
                      expiration={item.expirationDate}
                      noDocument={item.invoiceNumber}
                      partialitie={item.partiality}
                      residue={item.residue}
                      type="1to7days"
                      total={item.residue}
                    />
                  ))}

                  {invoice.expired7To14.invoice.map((item) => (
                    <DetailRow
                      key={`${key.current}-${i}-7to14days`}
                      expiration={item.expirationDate}
                      noDocument={item.invoiceNumber}
                      partialitie={item.partiality}
                      residue={item.residue}
                      type="8to14days"
                      total={item.residue}
                    />
                  ))}

                  {invoice.expiredMore14.invoice.map((item) => (
                    <DetailRow
                      key={`${key.current}-${i}-moreThan14`}
                      expiration={item.expirationDate}
                      noDocument={item.invoiceNumber}
                      partialitie={item.partiality}
                      residue={item.residue}
                      type="more14days"
                      total={item.residue}
                    />
                  ))}
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
