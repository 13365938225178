import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { DirectoryContext } from "pages/Directory";
import SearchDirectory from "pages/Directory/SearchDirectory";
import StatusFilter from "pages/Directory/StatusFilter";
import TypeSelect from "pages/Directory/TypeSelect";
import React, { useContext } from "react";
import Dialog from "structure/Dialog";

export default function MobileShortcut() {
  const hook = useContext(DirectoryContext);

  return (
    <>
      <ButtonV2 type="button" onClick={hook.filterMobile.open}>
        <Icon icon="filter" />
      </ButtonV2>

      <Dialog
        footer={<div className="d-flex justify-content-end">
          <ButtonV2 onClick={hook.attemptSearchRequested}>
          <span>Buscar</span>
          <Icon icon="search"/>
        </ButtonV2>
        </div>}
        forwardRef={hook.filterMobile.refDialog}
        title="Filtros Directorio"
        closeCallback={hook.filterMobile.close}
      >
        <TypeSelect />
        <StatusFilter />
        <SearchDirectory />
      </Dialog>
    </>
  );
}
