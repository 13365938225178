import { Select } from "components/individual/inputs/Inputs";
import { GetProbability } from "helpers/Apis/Documents";
import React, { useEffect, useState } from "react";

export default function Probability({
  selected = null,
  onChange = () => {},
  clasName = "",
}) {
  const [probabilities, setProbabilities] = useState([]);

  useEffect(() => {
    async function loadOptions() {
      const apiProbabilities = await GetProbability();

      setProbabilities(apiProbabilities);
    }

    loadOptions();
  }, []);

  useEffect(() => {
    const option = document.getElementById(`percentProb${selected}`);

    if (selected !== null && option !== null) option.selected = true;
  }, [probabilities, selected]);

  return (
    <Select
      addTodos={false}
      idSelect="percentProb"
      nameSelect="percentProb"
      textJSON="prbability"
      valueJSON="probabilityID"
      className='w-100'
      options={probabilities}
      onChange={(value) => onChange(+value)}
    />
  );
}
