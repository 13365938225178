import { CustomerOverviewModal } from "components/general/CustomerOverviewModal";
import React , { useRef } from "react";
import scss from "./styles.module.scss";

/**
 * Render an html tag which will open a modal with the overview of the customer
 * @param {import("./types").PropsCustomerInfo} props - Component props
 * @returns {JSX.Element} Modal
 */
export const CustomerInfo = ({
  idCustomer = null,
  children = <span>Nombre del cliente aqui</span>,
}) => {
  const idModal = useRef(window.crypto.randomUUID());

  const openModal = () => {
    const domBtnModal = document.getElementById(idModal.current);
    if (domBtnModal) domBtnModal.click();
  };

  return (
    <>
      <CustomerOverviewModal
        idCustomer={idCustomer}
        idModalOpen={idModal.current}
      />
      <div className={scss.overviewCustomer} onClick={openModal}>{children}</div>
    </>
  );
};
