import React from "react";
import scss from "../styles.module.scss";

/**
 * Render the row of a deductible for the dis-association
 * @param {import("./types").PropsDeductible} props - Props
 * @returns {JSX.Element}
 */
export const Deductible = ({
  isCheck = false,
  association = undefined,
  onChange = () => {},
  currency = "??"
}) => {
  if (association === undefined) return <></>;

  return (
    <label className={scss.row} htmlFor={`deducible-${association.id}`}>
      <div>
        <input
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
          id={`deducible-${association.id}`}
          name="expense-disassociation"
          checked={isCheck}
        />
      </div>
      <div>
        <p>{association.invoice.socialReason}</p>
      </div>
      <div>
        <p>{association.invoice.folio}</p>
      </div>

      <p>{association.expense.description}</p>
      <p>
        {association.invoice.total.text} {association.invoice.currency}
      </p>
      <p>
        {association.invoice.applied.text} {association.invoice.currency}
      </p>
      <p>{association.invoice.paid.text} {currency}</p>
    </label>
  );
};
