import { getDateFromUtc, parseDateToText } from "helpers/dates";
import React from "react";

/**
 * Render date label
 * @param {import('./types').PropsDate} props - Props
 * @returns {JSX.Element}
 */
export default function Date({ date = "" , label = "" }) {
  return (
    <div className="d-flex flex-column align-content-start justify-content-start">
      <b className="text-left">{label}</b>
      <time className="text-left">{ typeof(date)==="string" ? parseDateToText(getDateFromUtc(date)): "ND"}</time>
    </div>
  );
}
