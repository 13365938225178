import LoginContext from "context/Login/LoginContext";
import React, { useContext } from "react";
import { ContextOdc } from "..";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import { CancelOdc } from "./CancelOdc";
import ui from "./styles.module.scss";
import { Spinner } from "components/individual/Spinner/Spinner";
import LoadingText from "components/individual/TextLoading";
import EmailDocumentsV2 from "components/individual/EmailDocumentsV2";
import EditOc from "./EditOc";
import GenerateCxP from "./GenerateCxP";

export default function Options() {
  const {
    odc,
    triggerSearch,
    watchDocument,
    goFiles,
    goToDo,
    updateOc,
    pdf
  } = useContext(ContextOdc);

  const { userInfo } = useContext(LoginContext);

  if (odc === null) return <div className={ui.options}></div>;

  return (
    <div className={ui.options}>
      <ButtonV2 onClick={watchDocument}>Ver documento</ButtonV2>

      <CancelOdc
        idOdc={odc.id}
        onCancelled={triggerSearch}
        status={odc.status.id}
      />

      {pdf.isDownloading ? (
        <Spinner text={<LoadingText>Descargando</LoadingText>} hidden={false} />
      ) : (
        <ButtonV2 onClick={pdf.handleDownload}>PDF</ButtonV2>
      )}

      <ButtonV2 onClick={goFiles}>Archivos asociados</ButtonV2>

      <ButtonV2 onClick={goToDo}>To Do</ButtonV2>
        
      <EditOc/>

      <GenerateCxP/>

      {odc.status.id === 10 || odc.status.id === 11 ? (
        <EmailDocumentsV2
          idDocument={odc.id}
          subject={`Praxia - Orden de compra ${odc.numeroDocumento}`}
          message={`Se le adjunta la informacion de la orden de compra ${odc.numeroDocumento} (pdf)`}
          cc={[userInfo[0].email]}
          to={[odc.email]}
          onSendedEmail={updateOc}
        >
          <ButtonV2>Enviar correo</ButtonV2>
        </EmailDocumentsV2>
      ) : null}
    </div>
  );
}
