import styled from "styled-components";

/**
 * @type {import("styled-components").ThemedStyledFunction<'input',import("./types").PropsEmailI>}
 */
export const StyledEmail = (styled.input)`
    padding:0 10px;
    border-radius: ${props=>!props.renderCleanButton ? "5px" : "5px 0 0 5px"};
    border-right: ${props=>!props.renderCleanButton ? "0.1875em solid #E5E5E5" : "none"};

`;

export const ContainerEmail = styled.div`
    display:flex;
    align-items:center;
    svg{
        max-height:30px;
    }
    button {
    background-color: #F9F9F9;
    border: 0.1875em solid #E5E5E5;
    border-left:none;
    padding:0 10px;
    height: 3.125em;
    color: #737373;
    border-radius:0 5px 5px 0;
    transition:0.2s ease-in;
    &:hover{
        background-color:#F9F9F9;
        color:black;
    }
}
`