import React from "react";
import PlainOption from "../PlainOption";
import Back from "../Back";
import TextIcon from "structure/Menu/TextIcon";
import RedirectButton from "structure/Menu/Redirect";
import { ClosePeriod } from "pages/Banks/Concilation/ClosePeriod";
import usePermissions from "customHooks/usePermissions";

export default function ConcilationMenu() {

  const { checkPermission } = usePermissions();

  // if(!checkPermission("f2087873-0fd9-4d29-a963-a138a63da89d")) return <></>

  return (
    <>
      <Back menu="general" text="Inicio > Contabilidad" />

      <RedirectButton
        permission="23e03d36-ad09-4cb3-80b7-26f91e88ca81"
        text={<TextIcon icon="banks" text="Cuentas bancarias" />}
        to={"/v2/bancos"}
      />

      <RedirectButton
        permission="d76c49d3-ead2-4776-bdde-9668f665c45f"
        text={<TextIcon icon="banks" text="Facturas emitidas" />}
        to={"/contabilidad/facturas-emitidas"}
      />

      <RedirectButton
        permission="0703147b-0db4-4592-b806-80fa19248172"
        text={<TextIcon icon="banks" text="Facturas recibidas" />}
        to={"/contabilidad/facturas-recibidas"}
      />

      <RedirectButton
        permission="892796d5-bc32-4ad4-a224-bc371831f02c"
        text={<TextIcon icon="banks" text="Ingresos" />}
        to={"/contabilidad/ingresos"}
      />

      <RedirectButton
        permission="63275914-042b-4e13-b4c1-8a89af71d8d0"
        text={<TextIcon icon="banks" text="Egresos" />}
        to={"/contabilidad/egresos"}
      />

      <RedirectButton
        text={<TextIcon icon="banks" text="Notas de crédito emitidas" />}
        to={"/contabilidad/notas-de-credito/emitidas"}
        permission="103e2d9f-a663-4fab-a396-66b4923d3aa5"
      />

      <RedirectButton
        text={<TextIcon icon="banks" text="Notas de crédito recibidas" />}
        to={"/contabilidad/notas-de-credito/recibidas"}
        permission="f7182e08-a90e-42c4-aa33-89c60a877091"
      />

      <RedirectButton
        text={<TextIcon icon="banks" text="Provisión de OC" />}
        to={"/contabilidad/oc"}
        permission="baf397d1-041a-4783-88b7-769620c84356"
      />

      <ClosePeriod>
        <PlainOption text="Cerrar periodo" icon="administration" />
      </ClosePeriod>
    </>
  );
}
