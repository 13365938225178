import React from "react";
import { Search } from "components/individual/Search";
import { Label } from "structure/Document";
import { useContext } from "react";
import { ContextGridCxp } from "..";

export default function SearchCxp() {
  const hook = useContext(ContextGridCxp);

  return (
    <div className="my-2">
      <Label>Búsqueda</Label>
      <Search
        placeholder="Folio , uuid "
        onSearch={(value) => hook.updateSearch(value)}
        onDelete={() => hook.updateSearch("")}
      />
    </div>
  );
}
