import React, { useContext } from "react";
import ReactDatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { ContextContractDate } from "..";
import { addDays } from "date-fns";
import { dateToDbFormat } from "helpers/dates";

registerLocale("es", es);

/**
 * Render the expiration date for the contract
 * @param {object} props - Props
 * @param {(date:import("../types").onChangeI)=>void} props.onChange - Date selected
 * @returns {JSX.Element}
 */
export default function ExpirationContract({
  onChange = () => {},
  begin = new Date(),
}) {
  const { expiration, reminder, setExpiration } =
    useContext(ContextContractDate);

  /**
   * Update the date input for the expiration
   * @param {Date} date - Date to use for the input
   */
  const handleOnChangeDate = (date) => {
    setExpiration(date);

    const dbDate = dateToDbFormat(date);

    onChange({
      js: date,
      db: dbDate,
    });
  };

  return (
    <>
      <ReactDatePicker
        onChange={handleOnChangeDate}
        minDate={addDays(begin, 1)}
        selected={expiration}
        dateFormat="dd/MMM/yyyy"
        locale="es"
        placeholderText="Selecciona una fecha"
      />
      {/* {reminder === null ? null : (
        <ReactDatePicker
          onChange={handleOnChangeDate}
          minDate={addDays(reminder, 1)}
          selected={expiration}
          dateFormat="dd/MMM/yyyy"
          locale="es"
          placeholderText="Selecciona una fecha"
        />
      )} */}
    </>
  );
}
