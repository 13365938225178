import React from "react";
import ui from "./styles.module.scss";

const DEFAULT = {
  dark: "/images/praxia_clean.png",
  light: "/images/praxia_clean.png",
};

const previewColor = {
  dark:ui.previewDark,
  light:ui.previewLight
}

/**
 * Render a preview of the visualization of the banner
 * @param {import('./types').BannerPreview} props - Props
 * @returns {JSX.Element}
 */
export default function BannerPreview({ custom = null, mode }) {
  if (custom === null)
    return (
      <div className={`${ui.currentBanner} ${previewColor[mode]}`}>
        <p>No hay banner personalizdo, se usara por defecto el de Praxia</p>
        <img src={DEFAULT[mode]} alt="default_image_preview_banner" />
      </div>
    );

  return (
    <div className={`${ui.currentBanner} ${previewColor[mode]}`}>
      <p>Previa del banner</p>
      <img src={URL.createObjectURL(custom)} alt="custom_banner_lightMode" />
    </div>
  );
}
