import React from "react";
import { useContext } from "react";
import { ContextBankAccountReport } from "..";
import { Label } from "structure/Document";
import { RangeDate, From, To } from "components/general/RangeDate";
import { dateAtCeroHours } from "helpers/dates";

export default function PeriodCombo() {
  const hook = useContext(ContextBankAccountReport);

  //   No bank account selected yet
  if (hook.account === null)
    return (
      <div>
        <Label required={true}>Periodo</Label>
        <p>Selecciona una cuenta de banco</p>
      </div>
    );

  // Bank account has months to select and generate the report
  return (
    <RangeDate>
      <From
        selected={hook.from}
        minDate={hook.minDate}
        onChange={(range) => hook.setRangeDate(range, hook.to)}
      />
      <To
        selected={hook.to}
        maxDate={dateAtCeroHours(new Date())}
        onChange={(range) => hook.setRangeDate(hook.from, range)}
      />
    </RangeDate>
  );
}
