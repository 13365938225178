import React, { useContext } from "react";
import { ContextDocuments } from "../..";
import ButtonV2 from "components/individual/ButtonsV2/Button";

export default function WinQuote() {
  const hook = useContext(ContextDocuments);

  if (!hook.canWinQuote) return <></>;

  return (
    <ButtonV2 onClick={hook.winQuote}>
      <span>Ganar</span>
    </ButtonV2>
  );
}
