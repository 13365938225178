import Currency from "components/individual/inputs/Currency";
import React, { useContext } from "react";
import { Label } from "structure/Document";
import { ContextCashFlow } from "..";

export default function CurrencyReportCashFlow() {
  const hook = useContext(ContextCashFlow);

  if (hook.cashFlow.currency !== "Todos") return <></>;

  return (
    <div>
      <Label required={hook.cashFlow.currencyReports === undefined}>
        Moneda (reporte)
      </Label>
      <Currency
        onChange={hook.updateCurrencyReport}
        selected={hook.cashFlow.currencyReports}
      />
    </div>
  );
}
