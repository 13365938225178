import React, { useContext } from "react";
import { Input } from "components/individual/Input";
import { BankAccountContext } from "..";
import { Label } from "structure/Document";

export const NextTransfer = () => {
  const { register, id, errors, account } = useContext(BankAccountContext);

  return (
    <>
      <Label>No. siguiente transferencia</Label>
      {id === null ? (
        <>
          <div>
            <Input
              forwardRef={register}
              name="nextTransferenceNumber"
              placeholder="Escribe aquí"
              type="number"
            />
            <p className="text-danger">
              {errors?.nextTransferenceNumber?.message}
            </p>
          </div>
        </>
      ) : (
        <>
          <p>{account.currentTransferenceNumber + 1}</p>
        </>
      )}
    </>
  );
};
