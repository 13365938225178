import FileInfo from "components/individual/SendEmail/FileInfo";
import { FileInfoContainer } from "components/individual/SendEmail/styles";
import React, { useContext } from "react";
import { ContextEmail } from "..";
import { Spinner } from "components/individual/Spinner/Spinner";

export const Files = () => {
  const {
    uiFiles,
    deleteFile,
    isPerformingPreload,
    file: blobs,
  } = useContext(ContextEmail);

  return (
    <>
      {isPerformingPreload ? (
        <Spinner hidden={false} text={"Adjuntando archivos"} />
      ) : null}

      <FileInfoContainer>
        {uiFiles.map((file, i) => (
          <FileInfo
            blob={blobs[i]}
            key={window.crypto.randomUUID()}
            fileInfo={file}
            uuidFileName={file.uuidFileName}
            onDelete={(uuid) => deleteFile(uuid, i)}
          />
        ))}
      </FileInfoContainer>
    </>
  );
};
