import * as yup from "yup";

const currency = yup.string().required("Obligatorio");

const addBankAccountSchema = yup.object({
  currency,
  account: yup
    .string()
    .matches(/^[0-9]+$/, {
      message: "Solo caracteres del 0 al 9",
    })
    .length(10, "Son 10 digitos"),
  lastCloseBalanceConcilation: yup
    .number()
    .min(0, "Mínimo $0.00")
    .required("Obligatorio")
    .transform((value, originalValue) => {
      console.log(value, originalValue);

      if (originalValue === "") return 0;

      const parsed = originalValue.replaceAll("$", "");
      const parsedB = parsed.replaceAll(",", "");

      if (parsedB === "$" || parsedB === "") return 0;

      return +parsedB;
    }),
  nextBankCheckNumber: yup
    .number()
    .required("Obligatoria")
    .min(1, "Mínimo 1")
    .typeError("Mínimo 1"),
  nextTransferenceNumber: yup
    .number()
    .required("Obligatoria")
    .min(1, "Mínimo 1")
    .typeError("Mínimo 1"),
});

const updateBankAccountSchema = yup.object({
  status: yup
    .boolean()
    .typeError("Estatus invalido")
    .required("Estatus requerido")
    .transform((value, originalValue) => {
      return value;
    }),
});

export { addBankAccountSchema, updateBankAccountSchema };
