import React, { useEffect, useContext } from "react";

/* CONTEXT */
import LoginContext from "../../context/Login/LoginContext";

/* COMPONENTS */
import CardRecoverPassword from "../../components/general/CardRecoverPassword/RecoverPassword";
import CardLogin from "../../components/general/CardLogin/CardLogin";
import CardForgotPassword from "../../components/general/ForgotPassword/ForgotPassword";

/* STYLES */
import "../../styles/App.scss";
import { BrandEnterprise } from "./styles";
import CardOtp from "components/general/CardOtp";
import FooterPraxia from "components/general/Footer";
import EnterpriseLogo from "./EnterpriseLogo";

function Login(props) {
  const { screen, token } = useContext(LoginContext);

  /* WILL HELP TO RENDERIZE THE COMPONENT DEPENDING
  ON THE STATE "screen". It's a switch to */
  const card = () => {
    switch (screen) {
      case "recover-password":
        return <CardForgotPassword />;

      case "login":
        return <CardLogin />;

      case "recover":
        return <CardRecoverPassword />;

      case "otp":
        return <CardOtp />;

      default:
        break;
    }
  };

  useEffect(() => {
    if (token !== "") {
      props.history.push("/inicio");
    }
  }, [token]);

  return (
    <div className="loginContainer">
      <EnterpriseLogo />
      {card()}
      <div>
        <FooterPraxia />
      </div>
    </div>
  );

}

export default Login;
