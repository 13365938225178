import useMemoryFilter from "customHooks/useMemoryFilter";
import { saveAs } from "file-saver";
import {
  dontAssignPosition,
  excelProyects,
  getProyects,
} from "helpers/Apis/proyects";
import { parseDateToTextAndTime } from "helpers/dates";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

/**
 * @type {import("./types").InitialState}
 */
const STATE = {
  isLoading: true,
  page: 1,
  projects: {
    data: [],
    noRecordsFound: 0,
    pages: 0,
  },
  status: "ActivoOperando",
  selectedProyect: undefined,
  selectedPosition: [],
  refetch: false,
  solped: "",
  buyer: "",
  searchField: "",
  orderDesc: true,
  refetchPositions: false,
  positionAssign: "none",
  excelStatus: "none",
};

/**
 * Handle the hook for manage proyects
 * @returns {import("./types").ReturnUseProyects}
 */
export default function useProyects() {
  const memoryFilter = useMemoryFilter({
    searchField: "",
    status: "ActivoOperando",
  });

  const [state, setState] = useState({
    ...STATE,
    ...memoryFilter.retrieveData("proyects"),
  });

  /**
   * @type {import("./types").CallbackPositionSelected}
   */
  const isCheckedPosition = useCallback((position) => {
    if (state.selectedPosition.length <= 0) return false;
    const idsPositionsSelected = state.selectedPosition.map((item) => item.id);
    return idsPositionsSelected.includes(position.id);
  }, []);

  const breadcrum = useRef([
    {
      route: "/inicio",
      text: "Inicio",
    },
    {
      route: "/proyectos",
      text: "Proyectos",
    },
  ]);

  /**
   * @type {import("./types").RefIndexedStatus}
   */
  const indexedStatus = useRef({
    SolicitudUrgente: "Solicitud urgente",
    SolicitudNormal: "Solicitud normal",
    Propuesta: "Propuesta",
    Activo: "Activo",
    Terminado: "Terminado",
    NoAsignado: "No Asignado",
    Cancelar: "Cancelado",
  });

  /**
   * @type {import("./types").RefProyectHeader}
   */
  const indexedStatusProyect = useRef({
    NoActivo: "No activo",
    ActivoOperando: "Operando",
    ActivoPropuestaPendiente: "Propuesta pendiente",
    Terminado: "Terminado",
    Cancelado: "Cancelado",
  });

  const history = useHistory();

  useEffect(() => {
    memoryFilter.setMemoryData(
      {
        searchField: state.searchField,
        status: state.status,
      },
      "proyects"
    );
  }, [state.searchField, state.status]);

  useEffect(() => {
    (async function () {
      setState((current) => ({
        ...current,
        isLoading: true,
        selectedProyect: undefined,
        selectedPosition: [],
      }));

      const proyects = await getProyects({
        page: state.page,
        limit: 100,
        search: state.searchField,
        orderBy: state.orderDesc ? "DESC" : "ASC",
        status: state.status,
        // status:true
      });

      setState((current) => ({
        ...current,
        isLoading: false,
        selectedProyect: undefined,
        projects: {
          pages: proyects.pages,
          data: proyects.data,
          noRecordsFound: proyects.noRecordsFound,
        },
      }));
    })();
  }, [state.refetch, state.page]);

  const redirectToAddScreen = () => history.push(`/proyectos/formulario/0`);

  const redirectToEditScreen = () => {
    if (state.selectedProyect !== undefined)
      history.push(`/proyectos/formulario/${state.selectedProyect.id}`);
  };

  const redirectToAddPosition = () => {
    if (state.selectedProyect !== undefined)
      history.push(`/proyectos/posicion/agregar/${state.selectedProyect.id}`);
  };

  /**
   * Set the proyect selected
   * @param {import("../../../../types/proyects").DtoProjectTabulated} proyect - Proyect
   */
  const setProyect = (proyect) => {
    setState((current) => ({
      ...current,
      selectedProyect: proyect,
      selectedPosition: [],
      refetchPositions: !current.refetchPositions,
    }));
  };

  const refetchTable = () =>
    setState((current) => ({ ...current, refetch: !current.refetch }));

  const setSolped = (solped) => setState((current) => ({ ...current, solped }));

  const setBuyer = (buyer) => setState((current) => ({ ...current, buyer }));

  /**
   * Set the status for the proyect query
   * @param {string|null} status - Statu
   */
  const setStatus = (status) => setState((current) => ({ ...current, status }));

  const setSearchField = (searchField) =>
    setState((current) => ({ ...current, searchField }));

  /**
   * Set the position of the proyect
   * @param {import("../../../../types/proyects").DtoPosition[]} position - Position
   */
  const setPosition = (position) => {
    if (position.length === 0 || state.selectedPosition.length <= 0) {
      setState((current) => ({
        ...current,
        selectedPosition: position,
      }));
      return;
    }

    const idProyect = position[0].idProject;

    const areOfTheSameProyect =
      state.selectedProyect === undefined
        ? true
        : state.selectedProyect.id === position[0].idProject;

    const proyect = state.projects.data.find((item) => {
      return item.id === idProyect;
    });

    console.log({
      proyect,
      areOfTheSameProyect,
      position,
    });

    if (!areOfTheSameProyect) return;

    setState((current) => ({
      ...current,
      selectedPosition: position,
      selectedProyect: proyect,
    }));
  };

  /**
   * Toggle the select of a position on the table
   * @param {import("../../../../types/proyects").DtoPosition} position
   */
  const toggleSelectedRecord = (position) => {
    const searchedPosition = state.selectedPosition.find(
      (pos) => pos.id === position.id
    );

    const mustBeAdded =
      searchedPosition !== undefined && searchedPosition !== null;
  };

  const handleNoAssignPosition = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Confirmar acción",
      text: `¿Estas seguro de cambiar el estatus de la posicion ${state.selectedPosition[0].pos} a "No asignado"?`,
      confirmButtonText: "Si, cambiar",
      cancelButtonText: "No, regresar",
      showCancelButton: true,
      icon: "question",
    });

    if (isConfirmed) {
      setState((current) => ({
        ...current,
        positionAssign: "loading",
      }));

      const wasSuccess = await dontAssignPosition(state.selectedPosition[0].id);

      setState((current) => ({
        ...current,
        positionAssign: "none",
        refetchPositions: wasSuccess
          ? !current.refetchPositions
          : current.refetchPositions,
      }));
    }
  };

  const handleDownlaodExcel = async () => {
    setState((current) => ({
      ...current,
      excelStatus: "loading",
    }));

    const file = await excelProyects();

    if (file instanceof Blob) {
      saveAs(
        file,
        `Proyectos_${parseDateToTextAndTime(new Date(), "long")}.xlsx`
      );
    }

    setState((current) => ({
      ...current,
      excelStatus: "none",
    }));
  };

  return {
    ...state,
    indexedStatusProyect,
    refetchTable,
    handleDownlaodExcel,
    setPosition,
    setSolped,
    setBuyer,
    setProyect,
    setStatus,
    redirectToAddScreen,
    redirectToEditScreen,
    indexedStatus,
    setSearchField,
    breadcrum,
    redirectToAddPosition,
    handleNoAssignPosition,
    isCheckedPosition,
  };
}
