import PayMethods from "components/general/Forms/Selects/PayMethodsV2";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import React from "react";
import { useContext } from "react";
import { AddMovementContext } from "../AddMovementsV2";
import { Amount } from "./Amount";
import { Concept } from "./Concept";
import { DateMovement } from "./DateMovement";
import { Reference } from "./Reference";
import { Save } from "./Save";
import scss from "./styles.module.scss";

export const FormAddMovement = () => {
  const { addMovement, payMethod, updatePaymethod, isSaving } =
    useContext(AddMovementContext);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addMovement();
      }}
      className={scss.form}
    >
      <DateMovement />
      <PayMethods
        value={payMethod}
        onChange={(value) => {
          console.log(value);
          updatePaymethod(value);
        }}
      />
      <Reference />
      <Concept />
      <Amount />

      <div className="d-flex align-items-end">
        {!isSaving ? <ButtonV2 className="mr-3">Agregar</ButtonV2> : null}
        <Save />
      </div>
    </form>
  );
};
