import { v4 as uuidv4 } from "uuid";

/**
 * Delete a comment from a list of comments
 *
 * @param {Comments[]} comments - List of comments
 * @param {string|number} id - ID of the comment to delete
 * @returns {Comments[]} Comments with the specified comment deleted
 */
export function deleteComment(comments, id) {
  let copyComments = [...comments];

  const updatedComments = copyComments.filter((comment) => comment.id !== id);

  return updatedComments;
}

/**
 * Add a new comment from a list of comments
 *
 * @param {Comments[]} comments - List of comments
 * @param {string} content - Content of the comment
 * @returns {Comments[]}
 */
export function addComment(comments, content) {
  let copyComments = [...comments];

  copyComments.unshift({
    id: uuidv4(),
    name: content,
    isNewComment: true,
  });

  return copyComments;
}

/**
 * Update the content of a comment
 *
 * @param {Comments[]} comments - List of comment
 * @param {object} value - Values to exchange on update
 * @returns {Comments[]}
 */
export function updateComment(comments, value) {
  let copyComments = [...comments];

  const updatedComments = copyComments.map((comment) => {
    if (comment.id === value.idComment) {
      comment.name = value.content;
    }
    return comment;
  });

  return updatedComments;
}

/**
 * Give the order of the comments before save them on DB
 *
 * @param {Comments[]} comments - List of comments
 * @returns {Comments[]} Comments with an order property
 */
export function giveOrder(comments) {
  let copyComments = [...comments];

  const parsedComments = copyComments.map((comment, i) => {
    return {
      ...comment,
      order: i + 1,
    };
  });

  return parsedComments;
}

/**
 * Reorder the comments by document type
 * @param {import("types/typedef/customHooks/useCommentsDocument").CommentI[]} comments
 */
export function reorderComments(comments) {
  if (comments.length <= 0) {
    return [];
  }

  const filteredComments = filterCommentsByType();

  if (filteredComments === undefined || filteredComments === null) return [];

  const notes = filteredComments.notes.map((note, i) => ({
    ...note,
    order: i + 1,
  }));

  const regulars = filteredComments.regulars.map((regular, i) => ({
    ...regular,
    order: i + 1 + notes.length,
  }));

  const considerations = filteredComments.considerations.map(
    (consideration, i) => ({
      ...consideration,
      order: i + 1 + notes.length + regulars.length,
    })
  );

  const reorderedComments = [...notes, ...regulars, ...considerations];

  return reorderedComments;

  function filterCommentsByType() {
    return comments.reduce(
      (indexedFilter, comment) => {
        // eslint-disable-next-line default-case
        switch (comment.idTypeComment) {
          case 1:
            return {
              ...indexedFilter,
              notes: [...indexedFilter.notes, comment],
            };

          case 2:
            return {
              ...indexedFilter,
              considerations: [...indexedFilter.considerations, comment],
            };

          case 3:
            return {
              ...indexedFilter,
              regulars: [...indexedFilter.regulars, comment],
            };
        }
      },
      {
        notes: [],
        regulars: [],
        considerations: [],
      }
    );
  }
}
