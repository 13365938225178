import React from "react";

export default function EnterpriseLogo() {
  return (
    <img
      src="https://res.cloudinary.com/dx5becozb/image/upload/v1670885774/img/background_enakxr.jpg"
      alt="enterprise_logo_login"
    />
  );
}
