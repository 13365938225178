import React from "react";
import { useContext } from "react";
import { ContextBankAccountReport } from "..";
import style from "./styles.module.scss";
import ButtonV2 from "components/individual/ButtonsV2/Button";
import Icon from "components/individual/HeaderIcon";
import { Spinner } from "components/individual/Spinner/Spinner";

export default function FooterBankAccountReport() {
  const hook = useContext(ContextBankAccountReport);

  if (hook.isLoading || hook.account === null) return <></>;

  return (
    <div className={style.bankReportFooter}>
      {hook.isDownloadingPdf ? (
        <Spinner text="Descargando pdf" hidden={false} />
      ) : (
        <ButtonV2 onClick={hook.handleDownloadPdf}>
          <span>PDF</span>
          <Icon icon="pdf" />
        </ButtonV2>
      )}

      {hook.isDownloadingXlsx ? (
        <Spinner text="Descargando excel" hidden={false} />
      ) : (
        <ButtonV2 onClick={hook.downloadExcelReport}>
          <span>Excel</span>
          <Icon icon="excel" />
        </ButtonV2>
      )}
    </div>
  );
}
