import React, { Fragment, useContext } from "react";
import ReceptionOverview from "structure/ReceptionOverview";
import { ContextCancelInvoice } from "../CancelInvoiceButton";
import { Label } from "structure/Document";
import { Search } from "components/individual/Search";
import { parseToFolio } from "helpers/documents";

export default function OverviewInvoiceReplace() {
  const hook = useContext(ContextCancelInvoice);

  if (hook.state.motiveId !== 1) return <></>;

  return (
    <Fragment>
      <div>
        <b>CFDI que sustituye al cancelado</b>
        <p>
          {hook.state.invoiceToReplace === undefined
            ? "Selecciona una factura"
            : `${parseToFolio(+hook.state.invoiceToReplace.folio)} - ${
                hook.state.invoiceToReplace.uuid
              }`}
        </p>
      </div>

      <div>
        <Label>
          Número de folio relacionado a la factura{" "}
          {parseToFolio(+hook.state.invoice.folio)}
        </Label>
        <Search
          placeholder="Ejemplo: 150"
          onChange={(data) => hook.handleSearchChange(+data)}
          value={hook.state.search}
          onSearch={hook.setLoadInvoiceToReplace}
          onDelete={() => hook.handleSearchChange(0)}
          id="idsearc"
          name="idsearc"
          type="number"
          min={1}
          autoComplete="off"
        />
      </div>

      {hook.state.invoiceToReplace === undefined ? null : (
        <ReceptionOverview uuid={hook.state.invoiceToReplace.uuid} />
      )}
    </Fragment>
  );
}
