import ButtonV2 from "components/individual/ButtonsV2/Button";
import { Spinner } from "components/individual/Spinner/Spinner";
import useInvoiceSatPdf from "customHooks/useInvoiceSatPdf";
import React from "react";

export default function DownloadSatInvoices({ id, idXml, idPdf }) {
  const hook = useInvoiceSatPdf({
    id,
    pdf: idPdf,
    xml: idXml,
  });

  if (hook.isDownloading)
    return (
      <Spinner
        hidden={false}
        idSpinner="downloadingFileSat"
        text="Descargando"
      />
    );

  return (
    <ButtonV2 onClick={async () => await hook.attemptDownload()}>PDF</ButtonV2>
  );
}
