import React, { createContext, useEffect, useState } from "react";
import ExpirationContract from "components/general/Forms/Dates/ExpirationContract/Expiration";
import ReminderContract from "components/general/Forms/Dates/ExpirationContract/Reminder";
export { ExpirationContract, ReminderContract };

/**
 * Context of the component
 * @type {import("react").Context<import("./types").ContextContractDatesI>}
 */
export const ContextContractDate = createContext();
const { Provider } = ContextContractDate;

/**
 * Render the expiration and reminder date
 * @param {object} props - Props
 * @param {(object:import("./types").PropsParamsI)=>JSX.Element[]} props.children - Components to render
 * @param {Date?} props.exp - Expiration date to load by default
 * @param {Date?} props.rem - Reminder date to load by default
 * @returns {JSX.Element[]} Compound component pattern
 */
export function ContractDates({ children, exp = null, rem = null }) {
  const [expiration, setExpiration] = useState(null);
  const [reminder, setReminder] = useState(null);

  useEffect(() => {
    setReminder(rem);
    setExpiration(exp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider
      value={{
        expiration,
        reminder,
        setExpiration,
        setReminder,
      }}
    >
      {children({ expiration, reminder })}
    </Provider>
  );
}
